import React from 'react';
import _ from 'lodash';
import styles from './index.module.scss';

const Relationship = ({ index, tableData }) => {
  const data = tableData[index];
  return (
    <div>
      <p className={styles.title}>{_.capitalize(data?.affiliationType)}</p>
    </div>
  );
};

export default Relationship;
