import React from 'react';
import {
  ChatSectionSkeleton,
  ChatWrapperSkeleton,
  SendTextSkeleton
} from './styles';

const SkeletonLoader = () => (
  <ChatSectionSkeleton>
    <div>
      <ChatWrapperSkeleton variant="rect" height='50vh' width='100%' />
      <SendTextSkeleton variant="rect" height={50} width='100%' />
    </div>
  </ChatSectionSkeleton>
);

export default SkeletonLoader;
