import { useLocation } from 'react-router-dom';

const useIsPostJobPath = () => {
  const location = useLocation();
  const { pathname } = location;

  const postJobPaths = [
    '/jobs/edit-job-draft',
    '/jobs/add/new-job',
    '/jobs/add/edit',
    '/jobs/add/free-intern',
    '/jobs/add/free-senior',
    '/jobs/:id'
  ];

  // Check if the pathname matches any of the postJobPaths
  const isPostJobPath = postJobPaths.some(path => {
    if (path === '/jobs/:id') {
      // Match UUID-style IDs or numeric IDs
      const regex = /^\/jobs\/[a-fA-F0-9-]+(\?.*)?$/;
      return regex.test(pathname);
    }
    return pathname.startsWith(path);
  });

  return isPostJobPath;
};

export default useIsPostJobPath;
