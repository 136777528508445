import React, { useEffect, useRef, useState } from 'react';
import Link from '../../../components/RichTextEditor/Link';
import {
  EditorState,
  ContentState,
  convertToRaw,
  CompositeDecorator
} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import DefaultRichTextEditor from '../../../components/DefaultRichTextEditor';

export default function Description(props) {
  const { name, data, handleDescriptionChange } = props;

  function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    }, callback);
  }

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link
    }
  ]);

  // Use a ref to store the current HTML content for comparison
  const currentHtmlRef = useRef(data);
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(decorator)
  );

  useEffect(() => {
    // Only update the editor state if the external HTML content has changed
    if (data !== currentHtmlRef.current) {
      const blocksFromHtml = htmlToDraft(data || '');
      if (blocksFromHtml) {
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);

        currentHtmlRef.current = data;
      }
    }
  }, [data]);

  const onChange = newEditorState => {
    setEditorState(newEditorState);
    // Convert to HTML and update form state only if content has changed
    const newHtmlContent = draftToHtml(
      convertToRaw(newEditorState.getCurrentContent())
    );
    if (newHtmlContent !== currentHtmlRef.current) {
      handleDescriptionChange(null, name, newHtmlContent);

      currentHtmlRef.current = newHtmlContent;
    }
  };

  return (
    <DefaultRichTextEditor
      editorState={editorState}
      onChange={e => onChange(e)}
      placeholder="Type here"
    />
  );
}
