import { styled as styling } from '@mui/material/styles';
import { Grid, Tooltip, tooltipClasses } from '@mui/material';

export const Container = styling(Grid)(props => ({
  overflow: "hidden",
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: props?.transparent ? '' : '40px',
  width: props?.transparent ? '' : props?.discard ? '163px' : '40px',
  padding: props?.transparent ? '6px' : '8px',
  backgroundColor: props?.transparent
    ? 'transparent'
    : props?.discard
    ? `rgba(247, 84, 67, 0.11)`
    : '#EFEFEF',
  border: props?.transparent
    ? ''
    : props?.discard
    ? `1px solid #BE4242`
    : `1px solid #AEAEAE`,
  borderRadius: props?.transparent ? '4px' : '8px',
  cursor: 'pointer',
  transition: 'all 0.3s',
  color: props?.discard ? `#BE4242` : '',
  fontSize: "16px",

  ':hover': {
    backgroundColor: props?.disabled
      ? ''
      : props?.discard
      ? `rgba(247, 84, 67, 0.11)`
      : '#E5E5E5'
  }
}));

export const CustomTooltip = styling(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: '8px',
    padding: '4px, 8px, 4px, 8px',
    border: '2px solid black',
    backgroundColor: 'white',
    color: 'black',
    zIndex: 5,
    cursor: 'default',
    marginBottom: '4px !important'
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
    {
      marginTop: '4px !important'
    }
});
