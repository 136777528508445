import React, { useEffect, useRef, useState } from 'react';
import {
  PurpleContainer,
  ReviewContainer,
  SaveDeleteContainer,
  StatusContainer,
  StatusWrapper,
  TextFieldStyled,
  TrashIconStyled,
  WhiteContainer
} from '../../styles';
import StatusTag from '../../../components/StatusTag';
import SaveButton from '../../../components/SaveButton';
import { isEmpty, isEqual, isNumber } from 'lodash';
import IconButton from '../../../components/IconButton';
import ErrorText from '../../../components/ErrorText';
import { Grid } from '@mui/material';
import { isValidSocialMediaUrl } from '../../../helper/checkIsValidSocialMedia';
import { useDispatch } from 'react-redux';

function isValidUrl(url) {
  const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  return regex.test(url);
}

export default function InputUrl(props) {
  const {
    videoId,
    videos,
    video,
    videoIndex,
    contentUrl,
    saveConfirmation,
    deleteConfirmation,
    handleContentIndexChange,
    handleLinkChange,
    getManageType,
    hasUrlChanges
  } = props;

  const [errors, setError] = useState(false);
  const [invalidLink, setInvalidLink] = useState(false);
  const dispatch = useDispatch();
  const [link, setLink] = useState();
  const inputRef = useRef(null);
  const contentIndex = videos[videoIndex]?.contentIndex;
  const contentStatus = videos[videoIndex]?.content[contentIndex]?.status;
  const originalUrl = videos[videoIndex]?.content[contentIndex]?.url;
  const editingUrl = videos[videoIndex]?.content[contentIndex]?.inputUrl;
  const liveWithReview =
    videos[videoIndex]?.content?.length > 1 &&
    contentStatus === 'Live on Hiredly';

  useEffect(() => {
    setError(false);
    setInvalidLink(false);
    setLink(video[contentIndex]?.inputUrl);
  }, [video, contentIndex]);

  const onSave = index => {
    if (isValidUrl(link)) {
      if (!isValidSocialMediaUrl(link)) {
        setInvalidLink(true);
        return;
      } else {
        setInvalidLink(false);
      }

      if (isEmpty(link)) {
        return;
      }
      saveConfirmation(link, index);
    } else {
      setError(true);
    }
  };

  const onDelete = () => {
    if (isEmpty(contentStatus)) {
      setLink(null);
      return;
    }

    if (isEmpty(link)) {
      return;
    }

    deleteConfirmation();
  };

  return (
    <ReviewContainer errors={errors || invalidLink}>
      <PurpleContainer>
        <StatusWrapper>
          {video?.length > 0 ? (
            video?.map((content, index) => {
              return (
                <StatusContainer
                  errors={
                    videos[videoIndex]?.contentIndex === index &&
                    (errors || invalidLink)
                  }
                  key={index}
                  selected={videos[videoIndex]?.contentIndex === index}
                  onClick={() => {
                    if (isNumber(index)) {
                      handleContentIndexChange(videoIndex, index);
                    }
                  }}>
                  <StatusTag
                    index={index}
                    videoIndex={videoIndex}
                    contentIndex={videos?.contentIndex}
                    createdAt={content?.createdAt}
                    status={content?.status || 'Draft'}
                    handleStatusChange={() => {
                      handleContentIndexChange(videoIndex, index);
                    }}
                  />
                </StatusContainer>
              );
            })
          ) : (
            <StatusContainer selected errors={errors || invalidLink}>
              <StatusTag status={contentUrl || 'Draft'} />
            </StatusContainer>
          )}
        </StatusWrapper>
      </PurpleContainer>

      <WhiteContainer errors={errors || invalidLink}>
        <TextFieldStyled
          disabled={liveWithReview}
          inputRef={inputRef}
          onChange={e => {
            setError(false);
            setInvalidLink(false);
            setLink(e?.target?.value);
            handleLinkChange(
              e?.target?.value,
              videoIndex,
              contentIndex,
              getManageType(contentStatus, video)
            );
            hasUrlChanges();
          }}
          value={link || ''}
          variant="outlined"
          placeholder="Paste link here"
        />

        <SaveDeleteContainer>
          <SaveButton
            disabled={
              isEmpty(link) ||
              videos[videoIndex]?.loading ||
              isEqual(originalUrl, editingUrl) ||
              liveWithReview
            }
            onClick={() => onSave(videoIndex)}
            height="28px"
            fontSize="12px"
            padding="0px 16px"
            width="fit">
            Submit
          </SaveButton>

          <IconButton
            disabled={isEmpty(link) || videos[videoIndex]?.loading}
            transparent
            tooltipTitle={
              contentStatus === 'In Review'
                ? 'Cancel request'
                : 'Remove content'
            }
            onClick={onDelete}>
            <TrashIconStyled
              disabled={isEmpty(link) || videos[videoIndex]?.loading}
            />
          </IconButton>
        </SaveDeleteContainer>
      </WhiteContainer>
      {errors && (
        <Grid sx={{ width: '100%', marginTop: '4px' }}>
          <ErrorText>
            Please enter a valid URL. The link should begin with 'https://'.
          </ErrorText>
        </Grid>
      )}
      {invalidLink && (
        <Grid sx={{ width: '100%', marginTop: '4px' }}>
          <ErrorText>
            We currently only support video links from Instagram, Facebook,
            Tiktok and X (Twitter).
          </ErrorText>
        </Grid>
      )}
    </ReviewContainer>
  );
}
