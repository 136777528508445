import React from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultLayout from '../../layouts/DefaultLayout';
import MaintenanceImage from '../../assets/common/maintenance.png';
import {
  Container,
  Row,
  ButtonContainer,
  TitleText,
  NormalText,
  ContactText,
  ContactTextLink,
  TextBox,
  BackButton
} from './styles';
import { MUIButtonStyled } from '../../components/MUIButton';
import { CSEMAIL } from '../../utils/Constants';
import { getCountry } from '../../hooks/useRegionalisation';

export default function Maintenance() {
  const history = useNavigate();
  const isAuthenticated = localStorage.getItem('accessToken');

  const onClickContactUs = () => {
    window.open(`mailto:${CSEMAIL[getCountry()]}`);
  };

  return (
    <DefaultLayout>
      <Container>
        <img
          alt="maintenance"
          src={MaintenanceImage}
          style={{ height: 320, width: 320 }}
        />
        <TextBox>
          <Row>
            <TitleText>Site maintenance in progress.</TitleText>
          </Row>
          <Row>
            <NormalText>
              We&apos;re currently performing scheduled maintenance to enhance
              your experience. <br />
              Our website will be back shortly. Thank you for your patience.
            </NormalText>
          </Row>
          <Row>
            <ContactText>
              For urgent inquiries, please contact{' '}
              <ContactTextLink onClick={onClickContactUs}>
                {CSEMAIL[getCountry()]}.
              </ContactTextLink>
            </ContactText>
          </Row>
        </TextBox>

        {/* <ButtonContainer>
          <BackButton
            onClick={() =>
              history(`${isAuthenticated ? '/jobs' : '/login'}`)
            }>
            Back To Dashboard
          </BackButton>
        </ButtonContainer> */}
      </Container>
    </DefaultLayout>
  );
}
