import React from 'react';
import { Tooltip } from '@mui/material';
import { ReactComponent as AddIcon } from '../../../../../assets/icons/job-list/add-alt.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/icons/job-list/edit.svg';
import { ButtonStyled, Text } from './styles';
import { useNavigate } from 'react-router-dom';
import {
  updatePostJobPopup,
  updateProductTypeIndex
} from '../../../../../redux/actions/job_action';
import { useDispatch, useSelector } from 'react-redux';

const PostJobButton = ({ draftData, getJobLoading }) => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const onClickPostNewJob = () => {
    dispatch(updatePostJobPopup(true));
    dispatch(updateProductTypeIndex(''));
  };

  const fetchingJobs = useSelector(state => state.jobs.fetchingJobs);

  return (
    <div>
      {/* {draftData ? (
        <Tooltip
          title={
            <Text tooltip="true">
              Click here to continue editing your job posting.
            </Text>
          }>
          <ButtonStyled
            basic="true"
            id="edit-job-draft-button"
            onClick={() => history('/jobs/edit-job-draft')}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <EditIcon width={'55px'} />
              <span> Edit Job Draft</span>
            </div>
          </ButtonStyled>
        </Tooltip>
      ) : ( */}
      <ButtonStyled
        id="post-new-job-button"
        basic="true"
        disable_button={`${fetchingJobs ? true : false}`}
        disabled={fetchingJobs ? true : false}
        onClick={onClickPostNewJob}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AddIcon width={'55px'} />
          <span>Post New Job</span>
        </div>
      </ButtonStyled>
      {/* )} */}
    </div>
  );
};

export default PostJobButton;
