import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PopUpDialog, Spinner } from '../../../../components';
import {
  triggerBulkRejectPopup,
  updateApplicationSelectAllStatus
} from '../../../../redux/actions/application_action';
import store from '../../../../redux/stores/store';
import { TextArea } from '../../../ManageCandidates/Modals/ScheduleInterview/TextArea';
import {
  FirstContainer,
  SecondContainer,
  ButtonWrapper,
  ButtonContainer,
  Text,
  TextContainer,
  TextFieldStyled,
  ButtonStyled
} from './styles';

const BulkRejectPopUp = ({
  open,
  changeMultipleApplicationState,
  selectedApplications,
  updateSelectAllStatus,
  convertSelectAllStatus
}) => {
  const dispatch = useDispatch();
  const currentState = useSelector(state => state.applications.currentState);

  const selectedCounts = selectedApplications[currentState]
    ? Object.keys(selectedApplications[currentState]).length
    : 0;

  const isSelectAll = useSelector(
    state => state.applications.selectAllStatus[currentState.toLowerCase()]
  );

  const undecidedApplications = useSelector(
    state => state.applications.undecidedApplications
  );
  const kivApplications = useSelector(
    state => state.applications.kivApplications
  );
  const shortlistedApplications = useSelector(
    state => state.applications.shortlistedApplications
  );

  let applications;

  const handleBulkReject = () => {
    let applicationIds = [];
    let applications = [];

    if (currentState) {
      if (currentState == 'undecided') {
        applications = [...store.getState().applications.undecidedApplications];
      } else if (currentState == 'kiv') {
        applications = [...store.getState().applications.kivApplications];
      } else if (currentState == 'shortlisted') {
        applications = [
          ...store.getState().applications.shortlistedApplications
        ];
      }
    }

    if (store.getState().applications.selectAllStatus[currentState]) {
      // Is select all
      applicationIds = applications.map(item => item.id);
    } else {
      Object.keys(selectedApplications[currentState]).map(key => {
        applicationIds.push(key);
      });
    }

    updateApplicationSelectAllStatus(currentState, false);

    if (applicationIds.length > 0) {
      changeMultipleApplicationState(
        applicationIds,
        applications,
        currentState,
        'rejected'
      );
    }

    dispatch(triggerBulkRejectPopup(false));
  };

  const getApplicationsLength = () => {
    if (currentState) {
      if (currentState == 'undecided') {
        applications = undecidedApplications;
      } else if (currentState == 'kiv') {
        applications = kivApplications;
      } else if (currentState == 'shortlisted') {
        applications = shortlistedApplications;
      }
      return applications?.length;
    }
  };

  const onDismissDialog = () => {
    dispatch(triggerBulkRejectPopup(false));

    const convertedSelectAllStatus = convertSelectAllStatus(
      currentState,
      false
    );    

    updateSelectAllStatus(currentState, convertedSelectAllStatus);
  };

  return (
    <PopUpDialog
      open={open}
      handleClose={onDismissDialog}
      title={`Reject ${
        isSelectAll ? getApplicationsLength() : selectedCounts
      } Applicant(s)?`}>
      <FirstContainer>
        <TextContainer type="message">
          <Text type="basic">
            This action cannot be undone, and you can’t make changes to the
            user(s) anymore.
          </Text>
        </TextContainer>
      </FirstContainer>
      <SecondContainer>
        <ButtonWrapper>
          <ButtonContainer>
            <ButtonStyled type="cancel" onClick={onDismissDialog}>
              Cancel
            </ButtonStyled>
          </ButtonContainer>
          <ButtonContainer>
            <ButtonStyled type="confirm" onClick={() => handleBulkReject()}>
              Reject
            </ButtonStyled>
          </ButtonContainer>
        </ButtonWrapper>
      </SecondContainer>
    </PopUpDialog>
  );
};

export default BulkRejectPopUp;
