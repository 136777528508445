import React from 'react';
import { Grid } from '@mui/material';
import {
  Desc,
  StoryTitle,
  StoryWrapper,
  Title,
  TitleWrapper,
  ViewWrapper
} from './styles';
import IconButton from '../components/IconButton';
import { PencilIconStyled } from '../CoverImage/styles';
import { useSelector } from 'react-redux';

export default function ViewState(props) {
  const companyProfile = useSelector(state => state.companies.companyProfile);

  const handleClick = () => {
    props?.setView(false);
  };

  return (
    <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <TitleWrapper>
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Title>Company Story</Title>
          <Desc>
            Let jobseekers learn more about your company, what you do and your
            culture.
          </Desc>
        </Grid>

        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            alignItems: 'center'
          }}>
          <IconButton onClick={handleClick} tooltipTitle="Edit">
            <PencilIconStyled />
          </IconButton>
        </Grid>
      </TitleWrapper>

      <ViewWrapper>
        <StoryWrapper>
          <StoryTitle>{companyProfile?.descriptions?.at(0)?.title}</StoryTitle>
          <div>
            <style>
              {`
              ul {
                padding-left: 18px;
              }
            `}
            </style>
            <div
              style={{ lineHeight: '24px' }}
              dangerouslySetInnerHTML={{
                __html: companyProfile?.descriptions?.at(0)?.body
              }}
            />
          </div>
        </StoryWrapper>

        <StoryWrapper sx={{ overflowY: 'scroll', maxHeight: '400px' }}>
          <StoryTitle>{companyProfile?.descriptions?.at(1)?.title}</StoryTitle>
          <div>
            <style>
              {`
              ul {
                padding-left: 18px;
              }
            `}
            </style>
            <div
              style={{ lineHeight: '24px' }}
              dangerouslySetInnerHTML={{
                __html: companyProfile?.descriptions?.at(1)?.body
              }}
            />
          </div>
        </StoryWrapper>
      </ViewWrapper>
    </Grid>
  );
}
