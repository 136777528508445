import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';

const CheckboxTitleStyled = styled('p')({
    fontFamily: "Inter",
    fontSize: '12px',
    margin: 0,
});

const CheckboxStyled = styled(Checkbox)(({ theme }) => ({
    color: theme.palette.black.main,
}))

const SharedCheckbox = ({ shouldHide, ...props }) => {
    if (shouldHide) return null

    return (
        <CheckboxStyled
            size='small'
            color='default'
            sx={{
                marginTop: "-10px",
                '&.Mui-checked': {
                    color: '#222'
                }
            }}
            {...props}
        />
    );
};

const SharedFormCheckbox = ({ label, controlProps, ...props }) => {
    let labelComponent = label
    if (typeof label === 'string') {
        labelComponent = <CheckboxTitleStyled>{label}</CheckboxTitleStyled>
    }

    return (
        <FormControlLabel
            {...controlProps}
            sx={{ alignItems: 'flex-start', paddingTop: 1 }}
            control={<SharedCheckbox {...props} />}
            label={labelComponent}
        />
    );
};

export default SharedFormCheckbox;