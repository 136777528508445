import React from 'react';
import {
  EmptyContainer,
  SkeletonChipStyled,
  SkeletonTextStyled,
  LeftWrapper,
  RightWrapper,
  MainWrapper,
  SkeletonAvatarStyled
} from './styles';
import { Skeleton } from '@mui/material';

export const TalentCardSkeleton = () => {
  return (
    <MainWrapper container direction="row">
      <LeftSkeleton />
      {/* <RightSkeleton /> */}
    </MainWrapper>
  );
};

const LeftSkeleton = () => {
  return (
    <LeftWrapper container justifyContent="space-between">
      <EmptyContainer>
        <SkeletonTextStyled variant="text" width={210} />
        <SkeletonTextStyled variant="text" width={80} />
      </EmptyContainer>

      <EmptyContainer container direction="row" justifyContent="space-between">
        <SkeletonTextStyled variant="text" sx={{ width: '80%' }} />
        <SkeletonTextStyled variant="text" sx={{ width: '50%' }} />
        <SkeletonTextStyled variant="text" sx={{ width: '100%' }} />

        <EmptyContainer
          container
          direction="row"
          justifyContent="space-between">
          <SkeletonChipStyled variant="rounded" sx={{ width: '25%' }} />
          <SkeletonChipStyled variant="rounded" sx={{ width: '15%' }} />
          <SkeletonChipStyled variant="rounded" sx={{ width: '10%' }} />
          <SkeletonChipStyled variant="rounded" sx={{ width: '25%' }} />
          <SkeletonChipStyled variant="rounded" sx={{ width: '15%' }} />
        </EmptyContainer>
      </EmptyContainer>
    </LeftWrapper>
  );
};

const RightSkeleton = () => {
  return (
    <RightWrapper
      container
      direction="column"
      justifyContent="space-between"
      alignItems="flex-end"
      borderLeft="1px solid #EFEFEF">
      <SkeletonChipStyled variant="rounded" sx={{ width: '40%' }} />
      <EmptyContainer container direction="column" alignItems="flex-end">
        <SkeletonTextStyled variant="text" sx={{ width: '40%' }} />
        <SkeletonTextStyled variant="text" sx={{ width: '35%' }} />
      </EmptyContainer>

      <EmptyContainer container direction="column" alignItems="flex-end">
        <SkeletonTextStyled variant="text" sx={{ width: '45%' }} />
        <SkeletonTextStyled variant="text" sx={{ width: '35%' }} />
      </EmptyContainer>

      <EmptyContainer container direction="column" alignItems="flex-end">
        <SkeletonTextStyled variant="text" sx={{ width: '45%' }} />
        <SkeletonTextStyled variant="text" sx={{ width: '35%' }} />
      </EmptyContainer>

      <EmptyContainer
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <SkeletonAvatarStyled variant="circular" width={38} height={38} />
        <SkeletonChipStyled variant="rounded" sx={{ width: '70%' }} />
      </EmptyContainer>
    </RightWrapper>
  );
};
