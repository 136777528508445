import React, { useEffect, useState } from 'react';
import ContentCard from '../components/ContentCard';
import { Grid } from '@mui/material';
import {
  FieldTitleWrapper,
  FieldWrapper,
  TextFieldStyled,
  TextStyled
} from './styles';
import SaveButton from '../components/SaveButton';
import { useDispatch, useSelector } from 'react-redux';
import Description from './Description';
import {
  mutateCompanyProfile,
  updateCompanySidebar
} from '../../../redux/actions/company_actions';
import { ErrorToast, SuccessToast } from '../../../utils/ToastUtils';
import { cloneDeep, isEmpty } from 'lodash';
import ErrorText from '../components/ErrorText';

export default function EditState(props) {
  const dispatch = useDispatch();
  const companyProfile = useSelector(state => state.companies.companyProfile);
  const companyCompletion = useSelector(
    state => state.companies.companyCompletion
  );

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    companyDescriptionId: '',
    companyDescriptionTitle: '',
    companyDescriptionBody: '',
    companyMissionId: '',
    companyMissionTitle: '',
    companyMissionBody: '<p></p>'
  });

  const fetchData = () => {
    if (companyProfile?.descriptions) {
      const descriptions = cloneDeep(companyProfile.descriptions);
      setForm({
        companyDescriptionId: descriptions[0]?.id || '',
        companyDescriptionTitle: descriptions[0]?.title || '',
        companyDescriptionBody: descriptions[0]?.body || '',
        companyMissionId: descriptions[1]?.id || '',
        companyMissionTitle: descriptions[1]?.title || '',
        companyMissionBody: descriptions[1]?.body || '<p></p>'
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [companyProfile]);

  const handleChange = (e, name, value) => {
    if (e) {
      updateFormState(e?.target?.name, e?.target?.value);
    } else {
      updateFormState(name, value);
    }
  };

  const updateFormState = (name, value) => {
    if (name === 'companyDescriptionTitle' || name === 'companyMissionTitle') {
      if (value.length >= 77) {
        return;
      }
    }

    setForm(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    setLoading(true);
    dispatch(
      mutateCompanyProfile({
        input: {
          descriptions: [
            {
              id: form?.companyDescriptionId,
              title: form?.companyDescriptionTitle,
              body: form?.companyDescriptionBody
            },
            {
              id: form?.companyMissionId,
              title: form?.companyMissionTitle,
              body: form?.companyMissionBody
            }
          ]
        }
      })
    )
      .then(res => {
        if (res.type === 'FETCH_COMPANY_PROFILE_SUCCEED') {
          SuccessToast('Our Story Updated Successfully!');
          dispatch(
            updateCompanySidebar({
              ...companyCompletion,
              companyStory: true
            })
          );
          props?.setView(true);
        } else {
          ErrorToast(res.errorMessage);
        }
        window.dataLayer.push({
          event: 'save-cp-story'
        });
      })
      .catch(err => {
        ErrorToast(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const checkForError = () => {
    return (
      isEmpty(form?.companyDescriptionTitle) ||
      form?.companyDescriptionBody === '<p></p>\n' ||
      isEmpty(form?.companyMissionTitle) ||
      form?.companyMissionBody === '<p></p>\n'
    );
  };

  const hasChanges = () => {
    return (
      form?.companyDescriptionTitle !== companyProfile.descriptions[0]?.title ||
      form?.companyDescriptionBody !== companyProfile.descriptions[0]?.body ||
      form?.companyMissionTitle !== companyProfile.descriptions[1]?.title ||
      form?.companyMissionBody !== companyProfile.descriptions[1]?.body
    );
  };

  const textError =
    form?.companyDescriptionTitle?.length === 76 ||
    form?.companyMissionTitle?.length === 76;

  return (
    <ContentCard
      title="Company Story"
      subTitle="Let jobseekers learn more about your company, what you do and your culture."
      discardButton
      noChanges={!hasChanges()}
      cancelIcon={!isEmpty(companyProfile?.descriptions)}
      cancelOnClick={() => props?.setView(true)}>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          alignItems: 'center'
        }}>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px'
          }}>
          <FieldWrapper>
            <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <FieldTitleWrapper>
                <TextStyled>
                  Title{' '}
                  <span style={{ fontStyle: 'italic' }}>
                    (e.g. Our Mission)
                  </span>{' '}
                  <span style={{ color: '#BE4242' }}>*</span>
                </TextStyled>

                <TextStyled
                  error={form?.companyDescriptionTitle?.length === 76}>
                  {form?.companyDescriptionTitle?.length}/75
                </TextStyled>
              </FieldTitleWrapper>
              <TextFieldStyled
                name="companyDescriptionTitle"
                placeholder="Type here"
                value={form?.companyDescriptionTitle}
                onChange={handleChange}
                error={form?.companyDescriptionTitle?.length === 76}
              />
              {form?.companyDescriptionTitle?.length === 76 && (
                <ErrorText>You have exceeded the character limit.</ErrorText>
              )}
            </Grid>
            <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <TextStyled>
                Tell jobseekers about your company. What’s your origin story and
                what does your company do.
                <span style={{ color: '#BE4242' }}>*</span>
              </TextStyled>
              <Description
                name="companyDescriptionBody"
                data={form?.companyDescriptionBody}
                handleDescriptionChange={handleChange}
              />
            </Grid>
          </FieldWrapper>

          <FieldWrapper>
            <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <FieldTitleWrapper>
                <TextStyled>
                  Title{' '}
                  <span style={{ fontStyle: 'italic' }}>
                    (e.g. Our Mission)
                  </span>{' '}
                  <span style={{ color: '#BE4242' }}>*</span>
                </TextStyled>
                <TextStyled error={form?.companyMissionTitle?.length === 76}>
                  {form?.companyMissionTitle?.length}/75
                </TextStyled>
              </FieldTitleWrapper>

              <TextFieldStyled
                name="companyMissionTitle"
                placeholder="Type here"
                value={form?.companyMissionTitle}
                onChange={handleChange}
                error={form?.companyMissionTitle?.length === 76}
              />
              {form?.companyMissionTitle?.length === 76 && (
                <ErrorText>You have exceeded the character limit.</ErrorText>
              )}
            </Grid>

            <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <TextStyled>
                Let jobseekers know what’s your company culture like and what
                would a candidate expect when working at your company.
                <span style={{ color: '#BE4242' }}>*</span>
              </TextStyled>
              <Description
                name="companyMissionBody"
                data={form?.companyMissionBody}
                handleDescriptionChange={handleChange}
              />
            </Grid>
          </FieldWrapper>
        </Grid>
        <SaveButton
          onClick={handleSubmit}
          disabled={loading || textError || checkForError() || !hasChanges()}>
          Save
        </SaveButton>
      </Grid>
    </ContentCard>
  );
}
