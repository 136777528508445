import { styled as styling } from '@mui/material/styles';
import { Button } from '@mui/material';
import Colors from '../../../styles/colors.module.scss';

export const ButtonStyled = styling(Button)(props => ({
  color: props.filter ? 'black' : 'white',
  backgroundColor: props.filter ? 'transparent' : 'black',
  fontWeight: '700',
  fontSize: '12px',
  borderRadius: '8px',
  height: '30px',
  width: '112px',
  padding: '0px 16px 0px 16px',
  textTransform: 'none',

  ':hover': {
    backgroundColor: props.filter ? 'transparent' : 'black'
  }
}));
