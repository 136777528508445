import React from 'react';
import {
  CardContainer,
  DefaultContainer,
  Divider,
  ProductCardContainer,
  ProductItemContainer,
  TitleStyled
} from './styles';
import { useSelector } from 'react-redux';
import ProductCard from '../ProductCards';
import { ContactSalesDesc } from '../styles';

export default function AddOns() {
  const allActiveProducts = useSelector(
    state => state.products.allActiveProducts
  );
  const fetchingActiveProducts = useSelector(
    state => state.products.fetchingActiveProducts
  );

  const addOnsProduct = allActiveProducts.find(product => {
    return product.title === 'Visibility Boosters';
  });

  return (
    <DefaultContainer container sx={{ flexDirection: 'column', gap: '24px' }}>
      <CardContainer>
        {/* product title */}
        <DefaultContainer sx={{ padding: '8px 24px' }}>
          <TitleStyled>Add Ons (Job Visibility Boosters)</TitleStyled>
        </DefaultContainer>
        <Divider />
        {/* product item */}
        <ProductItemContainer>
          {/* description */}
          <ContactSalesDesc>
            {addOnsProduct?.categoryDescription}
          </ContactSalesDesc>
          {/* product card */}
          <DefaultContainer container sx={{ flexDirection: 'row' }}>
            <ProductCardContainer
              products={allActiveProducts}
              fetchingAllActiveProducts={fetchingActiveProducts}>
              {addOnsProduct?.products?.map((product, index) => {
                return (
                  <ProductCard product={product} addOns={true} />
                );
              })}
            </ProductCardContainer>
          </DefaultContainer>
        </ProductItemContainer>
      </CardContainer>
    </DefaultContainer>
  );
}
