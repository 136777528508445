import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  BookmarkBorderOutlined as BookmarkBorderOutlinedIcon,
  Copyright as CopyrightIcon
} from '@mui/icons-material';
import {
  EyeSlashIcon,
  BookmarkIcon as OutlinedBookmarkIcon
} from '@heroicons/react/24/outline';
import { BookmarkIcon as SolidBookmarkIcon } from '@heroicons/react/24/solid';
import { HeightSpacer, Spacer } from '../../../components/Spacer/styles';
import { MUIButtonStyled } from '../../../components/MUIButton';
import {
  ButtonText,
  CardContainer,
  CardSubContentText,
  CardSubContentTitle,
  CardSubtitle,
  CardTitle,
  ChipContainer,
  ChipText,
  DividerStyled,
  FirstContainer,
  IconButtonStyled,
  SecondContainer,
  SecondContainerSubtitle,
  SecondContainerTitle,
  BadgeStyled,
  OuterWrapper,
  EmptyContainer,
  UnstyledContainer,
  TypographyStyled,
  DotsIconButtonStyled,
  MoreVertIconStyled,
  SimpleDescription,
  LastRefreshedContainer,
  LastRefreshedText,
  LastRefreshedIconStyled,
  UnreadMessageCountButton,
  ChatBubbleLeftRightIconStyled,
  Bubble,
  ShortSummaryContainer,
  ShortSummaryTitle,
  ShortSummaryInfo,
  TopSection,
  BottomSection,
  TalentCardContainer
} from './styles';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  bookmarkTalent,
  getTalents,
  unlockResume,
  hideTalent,
  updateModelState,
  updateModelName,
  updateProfilePopUp,
  updateSearchTalentFilters,
  updateCurrentTalent,
  clearTalentsParams,
  unbookmarkTalent,
  updateInsufficientCoinsBanner,
  updateSearchResumeCurrentPage,
  updateChannelsUnreadMessageCount,
  updateErrorReportingStatus,
  updateTalentInfoTabValue
} from '../../../redux/actions/talent_action';
import moment from 'moment';
import {
  ButtonBase,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material';
import { ErrorToast, InfoToast } from '../../../utils/ToastUtils';
import { PopUpDialogs } from '../PopUpDialogs';
import { TalentCardSkeleton } from '../../../components/Skeletons/TalentCard';
import { SearchSkeleton } from '../../../components/Skeletons/Search';
import { NoResultsAlert } from '../../../components/NoResults';
import { SwitchStyled } from '../../Jobs/JobList/Switch/styles';
import CandidateProfilePopup from '../PopUpsWrapper/CandidateProfilePopup';
import { debounce, isEmpty } from 'lodash';
import { Spinner } from '../../../components';
import { sortWorkingExperiences } from '../../../helpers/data_management';
import TalentSearchCoinBlack from '../../../assets/icons/talent-search/talent_search_coin_black.svg';
import TalentSearchCoin from '../../../assets/icons/talent-search/talent_search_coin.svg';
import { SimpleTipsTooltip, TipsTooltip } from '../../../components/Tooltips';
import store from '../../../redux/stores/store';
import Sendbird from '../../../utils/Sendbird';
import { useNavigate } from 'react-router-dom';
import Colors from '../../../styles/colors.module.scss';
import { FlagIcon } from 'react-flag-kit';
import { getSendbirdID } from '../../../hooks/useENV';

var nationalities = require('i18n-nationality');

nationalities.registerLocale(require('i18n-nationality/langs/en.json'));

const getTalentsByType = (type, state) => {
  switch (type) {
    case 'search-resume': {
      return state.talents.talents;
    }
    case 'bookmarked': {
      return state.talents.bookmarkedTalents;
    }
    case 'unlocked': {
      return state.talents.unlockedTalents;
    }
    default: {
      return state.talents.talents;
    }
  }
};

const getLoadingTalentsByType = (type, state) => {
  switch (type) {
    case 'search-resume': {
      return state.talents.fetchingTalents;
    }
    case 'bookmarked': {
      return state.talents.fetchingBookmarkedTalents;
    }
    case 'unlocked': {
      return state.talents.fetchingUnlockedTalents;
    }
    default: {
      return state.talents.fetchingTalents;
    }
  }
};

const getDoneLoadingTalentsByType = (type, state) => {
  switch (type) {
    case 'search-resume': {
      return true;
    }
    case 'bookmarked': {
      return state.talents.doneFetchingBookmarkedTalents;
    }
    case 'unlocked': {
      return state.talents.doneFetchingUnlockedTalents;
    }
    default: {
      return true;
    }
  }
};

const sb = new Sendbird(getSendbirdID());

const TalentCard = props => {
  const { type, triggerLoadTalents } = props;
  const dispatch = useDispatch();

  const talents = useSelector(state => getTalentsByType(type, state));
  const loading = useSelector(state => getLoadingTalentsByType(type, state));
  const doneLoading = useSelector(state =>
    getDoneLoadingTalentsByType(type, state)
  );
  const filters = useSelector(state => state.talents.searchTalentFilters);
  const searchedKeyword = useSelector(state => state.talents.searchedKeyword);
  const talentsFilterNoResult = useSelector(
    state => state.talents.talentsFilterNoResult
  );
  //company account info
  const accountInfo = useSelector(state => state.companies.accountInfo);

  const onSwitch = e => {
    let cFilters = { ...filters };

    cFilters.showHiddenProfile = e.target.checked;

    dispatch(updateSearchTalentFilters(cFilters)).then(() => {
      dispatch(updateSearchResumeCurrentPage(1));
      if (talentsFilterNoResult) {
        triggerLoadTalents('search-resume', '', 1);
      } else {
        dispatch(getTalents(cFilters));
      }
    });
  };

  const getLastRefreshed = () => {
    return store.getState().talents.lastRefreshed;
  };

  const trackLastRefresh = () => {
    window.dataLayer.push({
      event: 'CE_click-refresh-talent-search'
    });
  };

  const onRefreshIconClicked = () => {
    trackLastRefresh();
    triggerLoadTalents('search-resume', '', 1);
  };

  const getSearchKeyword = by => {
    if (by == 'or') {
      return ' OR ';
    } else if (by == 'and') {
      return ' AND ';
    }
  };

  const getSearchedTerm = keyword => {
    const by = filters.filterMode;

    const search = keyword.trim().replace(/\s+/g, getSearchKeyword(by));

    const words = search.split(' ');

    let newArray = [];

    for (let i = 0; i < words.length; i++) {
      if (words[i] !== 'AND' && words[i] !== 'OR') {
        newArray.push(`"${words[i]}"`);
      } else {
        newArray.push(words[i]);
      }
    }

    const newString = newArray.join(' ');

    if (by == 'or' || by == 'and') {
      return newString;
    } else {
      return `"${keyword}"`;
    }
  };

  const getUnreadMessageCount = talent => {
    if (accountInfo && talent.unlocked && accountInfo?.companyId) {
      sb.connect(accountInfo?.companyId)
        .then(() => {
          sb.getChannelInfo(talent.companyResumeSendbirdChannelUrl)
            .then(channel => {
              if (channel.unreadMessageCount >= 0) {
                let content = {
                  talentId: talent.id,
                  unreadCount: channel.unreadMessageCount
                };

                dispatch(
                  updateChannelsUnreadMessageCount([
                    ...store.getState().talents.channelsUnreadMessageCount,
                    content
                  ])
                );
              }
            })
            .catch(error => {
              // no chat room / empty company resume url
              if (talent.companyResumeSendbirdChannelUrl == null) {
                let content = {
                  talentId: talent.id,
                  unreadCount: -1
                };

                dispatch(
                  updateChannelsUnreadMessageCount([
                    ...store.getState().talents.channelsUnreadMessageCount,
                    content
                  ])
                );
              }
            });
        })
        .catch(e => {
          // sendbird connection failed
          ErrorToast('Failed connection', e.message);
        });
    }
  };

  const onClickTalentCard = () => {
    if (store.getState().talents.tabValue != 'profile') {
      dispatch(updateTalentInfoTabValue('profile'));
    }
  };

  useEffect(() => {
    if (talentsFilterNoResult) {
      let params = {
        after: '',
        filterMode: 'or',
        keyword: '',
        wordMatch: 'word',
        advancedSearch: true,
        trackIds: [],
        specialisations: [],
        specialisationIds: [],
        industryIds: [],
        nationalities: [],
        withVideoCoverLetter: false,
        freshGrad: null,
        // minGraduationYear: moment().year() - 100,
        // maxGraduationYear: moment().year(),
        sort: null,
        educationLevels: [],
        languages: [],
        resumeLastUpdated: null,
        minYearExperience: 0,
        showHiddenProfile: store.getState().talents.searchTalentFilters[
          'showHiddenProfile'
        ]
          ? true
          : false
      };

      dispatch(getTalents(params));
    }
  }, [talentsFilterNoResult]);

  //need to check again before live (v2 TS)
  useEffect(() => {
    talents.map(talent => {
      getUnreadMessageCount(talent);
    });
  }, [talents]);

  return (
    <OuterWrapper>
      {type === 'search-resume' ? (
        <EmptyContainer
          container
          justifyContent="space-between"
          alignItems="center">
          {!isEmpty(talents) && (
            <TypographyStyled>
              {talentsFilterNoResult && !loading
                ? 'See instead:'
                : searchedKeyword.length > 0 && !loading
                ? `Search results for ${getSearchedTerm(searchedKeyword)}`
                : null}{' '}
            </TypographyStyled>
          )}

          {/* keep it for now, in case we reimplement this feature in future version - 6 Jul 2023 */}
          {/* <div>
            <UnstyledContainer
              container
              alignItems="center"
              justifyContent="space-between">
              <TypographyStyled>Show Hidden Profile</TypographyStyled>
              <Spacer width={'10px'} />
              <SwitchStyled
                switchOnText={''}
                switchOffText={''}
                $width={50}
                $height={28}
                $thumbWidth={18}
                $thumbHeight={18}
                checked={filters['showHiddenProfile']}
                onChange={onSwitch}
                trackTransform={'translateX(20)'}
              />
            </UnstyledContainer>
          </div> */}
        </EmptyContainer>
      ) : null}

      {/* Last refresh */}
      {type === 'search-resume' && (
        <SimpleTipsTooltip
          top={'25px'}
          left={'calc(50% - 70px)'}
          width={'150px'}
          padding={'4px 8px'}
          borderRadius={'8px'}
          icon={
            <LastRefreshedContainer onClick={onRefreshIconClicked}>
              <LastRefreshedText>
                Last refreshed: {getLastRefreshed()}
              </LastRefreshedText>
              <LastRefreshedIconStyled />
            </LastRefreshedContainer>
          }
          description={
            <Grid justifyContent={'center'} display={'flex'}>
              <SimpleDescription>Click to refresh the list</SimpleDescription>
            </Grid>
          }
        />
      )}

      {!doneLoading || loading
        ? [...Array(5)].map(i => {
            return <TalentCardSkeleton />;
          })
        : talents?.map(talent => {
            return (
              <>
                <CardContainer key={`${talent.id}`} onClick={onClickTalentCard}>
                  <TalentInfo talent={talent} type={type} />
                </CardContainer>
                <HeightSpacer height={'10px'} />
              </>
            );
          })}
    </OuterWrapper>
  );
};

export default TalentCard;

export const TalentInfo = ({ talent, type }) => {
  const dispatch = useDispatch();
  const reduxTalent = useSelector(state => state.talents.talent);

  const [isMouseEnterCard, setIsMouseEnterCard] = useState(false);

  const getWorkingExperiences = cTalent => {
    if (cTalent?.workingExperiences?.edges) {
      const experiences = cTalent.workingExperiences.edges.map(
        item => item.node
      );

      return sortWorkingExperiences(experiences);
    }

    return [];
  };

  const memoizedWorkingExperiences = useMemo(() => {
    return talent => getWorkingExperiences(talent);
  }, [talent?.workingExperiences?.edges]);

  const showNEW = talent => {
    if (talent) {
      const resumeDate = moment(talent.uploadResumeAt);

      const expiry = moment().subtract(30, 'days');

      const diff = moment(resumeDate).diff(moment(expiry), 'days');

      if (diff >= 0 && diff <= 30) {
        return true;
      }

      return false;
    }
  };

  const getCardTitle = talent => {
    let cardTitle = '';

    const experiences = memoizedWorkingExperiences(talent);

    const experience = experiences[0];

    if (talent.unlocked) {
      cardTitle = talent.name;
    } else {
      if (talent?.freshGrad) {
        cardTitle = `${talent.fieldOfStudy} Graduate`;
      } else if (experience?.jobTitle) {
        cardTitle = `${experience.jobTitle}`;
      } else {
        cardTitle = `${talent.fieldOfStudy} Graduate`;
      }
    }

    return cardTitle;
  };

  const getCardSubtitle = talent => {
    let cardSubtitle = '';

    const experiences = memoizedWorkingExperiences(talent);

    if (talent.unlocked) {
      if (talent.freshGrad) {
        cardSubtitle = `${talent.fieldOfStudy} Graduate`;
      } else if (experiences.length > 0) {
        const experience = experiences[0];

        if (experience.jobTitle) {
          cardSubtitle += `${experience.jobTitle}`;

          if (experience.companyName) {
            cardSubtitle += `, ${experience.companyName}`;
          }
        }
      } else {
        cardSubtitle = '';
      }
    } else {
      if (talent.freshGrad) {
        cardSubtitle = '';
        return;
      }

      cardSubtitle = experiences[0]?.companyName;
    }

    return cardSubtitle;
  };

  const cardTitle = getCardTitle(talent);
  const cardSubtitle = getCardSubtitle(talent);

  const getWorkExperienceValue = () => {
    let companyName = '';
    let jobTitle = '';

    if (memoizedWorkingExperiences(talent)[0]?.companyName?.length > 0) {
      companyName = ` at ${memoizedWorkingExperiences(talent)[0]?.companyName}`;
    }

    if (memoizedWorkingExperiences(talent)[0]?.jobTitle?.length > 0) {
      jobTitle = memoizedWorkingExperiences(talent)[0]?.jobTitle;
    }

    return jobTitle + companyName;
  };

  const onClickCard = () => {
    if (!store.getState().talents.isErrorReportingOpen) {
      dispatch(updateErrorReportingStatus(true));
    }

    dispatch(updateCurrentTalent(talent));
  };

  const getCardStatus = talent => {
    if (reduxTalent.id == talent.id) {
      return true;
    }

    return false;
  };

  const onMouseInCard = () => {
    setIsMouseEnterCard(true);
  };

  const onMouseOutCard = () => {
    setIsMouseEnterCard(false);
  };

  return (
    <TalentCardContainer
      onClick={onClickCard}
      $cardSelected={getCardStatus(talent)}
      onMouseEnter={onMouseInCard}
      onMouseLeave={onMouseOutCard}>
      <TopSection>
        <Grid
          display={'flex'}
          gap={'10px'}
          flexDirection={'column'}
          width={'70%'}>
          <CardTitle>{cardTitle}</CardTitle>
          {cardSubtitle && <CardSubtitle>{cardSubtitle}</CardSubtitle>}
        </Grid>

        <Grid
          display={'flex'}
          alignItems={'center'}
          gap={'10px'}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px'
          }}>
          <Bookmark talent={talent} type={type} />
          {talent.unlocked && <UnreadMessageButton talent={talent} />}
          {!talent.unlocked && (
            <PricingButton
              talent={talent}
              column={'talent-card'}
              isMouseEnterCard={isMouseEnterCard}
            />
          )}
        </Grid>

        {/* current company */}
        {/* {memoizedWorkingExperiences(talent)[0]?.companyName?.length > 0 &&
      talent.freshGrad != true ? (
        <>
          <HeightSpacer height={'10px'} />
          <SharedCardSubContent
            title={''}
            value={memoizedWorkingExperiences(talent)[0]?.companyName}
          />
        </>
      ) : null} */}

        <HeightSpacer height={'10px'} />
        {/* nationality */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Grid display={'flex'} alignItems={'center'} gap={'10px'}>
            <CountryFlag nationality={talent.nationality} />
            <CardSubtitle type={'nationality'}>
              {' '}
              {talent.nationality}
            </CardSubtitle>
          </Grid>

          {/* <Spacer width={'10px'} />
        <BadgeStyled type="in-demand">{'IN DEMAND'}</BadgeStyled> */}
        </div>

        <Grid
          marginTop={
            (memoizedWorkingExperiences(talent)[0]?.jobTitle?.length > 0 ||
              talent.highestEducationLevel?.length > 0) &&
            talent.freshGrad
              ? '10px'
              : '0'
          }>
          {/* Work Experience */}
          {memoizedWorkingExperiences(talent)[0]?.jobTitle?.length > 0 &&
          talent.freshGrad ? (
            <>
              <SharedCardSubContent
                title={''}
                value={getWorkExperienceValue()}
              />
            </>
          ) : null}

          {/* Highest Education Level */}
          {talent.highestEducationLevel?.length > 0 && talent.freshGrad && (
            <>
              <SharedCardSubContent
                title={''}
                value={talent.highestEducationLevel}
              />
            </>
          )}
        </Grid>

        {talent.skills.length > 0 && (
          <>
            {' '}
            <HeightSpacer height={'15px'} />
            <Skills skills={talent.skills} />
          </>
        )}
      </TopSection>

      <Divider />

      <BottomSection>
        <div>
          {/* last active */}
          <LastActive talent={talent} />
          <HeightSpacer height={'5px'} />
          {/* resume uploaded */}
          <ResumeUploaded talent={talent} />
        </div>
        {showNEW(talent) && <BadgeStyled type="new">{'NEW'}</BadgeStyled>}
      </BottomSection>
    </TalentCardContainer>
  );
};

const Specialisation = ({ specialisation }) => {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <CardSubContentTitle>Specialisation:</CardSubContentTitle>
      &nbsp;
      <CardSubContentText>{specialisation}</CardSubContentText>
    </div>
  );
};

export const SharedCardSubContent = ({ title, value }) => {
  return (
    <ul
      style={{
        listStylePosition: 'outside',
        padding: '0 20px',
        margin: '0'
      }}>
      <li>
        <CardSubContentTitle title={title?.toLowerCase()}>
          {title}
        </CardSubContentTitle>
        <CardSubContentText>{value}</CardSubContentText>
      </li>
    </ul>
  );
};

const Skills = ({ skills }) => {
  return (
    <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
      {skills.slice(0, 5).map(skill => {
        return (
          <ChipContainer>
            <ChipText>{skill?.name}</ChipText>
          </ChipContainer>
        );
      })}
      {skills?.length > 5 && (
        <ChipContainer $extraCount={true}>
          <ChipText>{skills?.length - 5}+</ChipText>
        </ChipContainer>
      )}
    </div>
  );
};

export const DotsAndMenu = ({ talent, triggerLoadTalents }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const channelsUnreadMessageCount = useSelector(
    state => state.talents.channelsUnreadMessageCount
  );

  const onClickChatButton = talent => {
    history({
      pathname: '/talent-search',
      search: `TStab=chat&user_id=${talent.id}`
    });

    dispatch(updateCurrentTalent(talent));
    dispatch(updateProfilePopUp(true));
  };

  const getUnreadCount = talent => {
    const index = channelsUnreadMessageCount.findIndex(item => {
      return talent.id == item.talentId;
    });

    return channelsUnreadMessageCount[index]?.unreadCount;
  };

  const handleClick = event => {
    dispatch(updateCurrentTalent(talent));
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const trackHideProfile = params => {
    window.dataLayer.push({
      event: 'CE_click-hide-profile',
      hide: params.hideProfile,
      'user-id': params.userId
    });
  };

  const onHideTalent = () => {
    const hideProfileConfirmation = localStorage.getItem(
      'hideProfileConfirmation'
    );

    const params = {
      userId: talent.id,
      hideProfile: talent?.hidden ? false : true
    };

    if (talent.hidden) {
      dispatch(hideTalent(params)).then(res => {
        if (res.type == 'HIDE_PROFILE_SUCCEED') {
          trackHideProfile(params);
          InfoToast('UnHide Successfully');

          setTimeout(() => {
            triggerLoadTalents('search-resume', '', 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }, 200);
        } else {
          ErrorToast('Failed to unhide this candidate');
        }
      });
    } else {
      if (hideProfileConfirmation) {
        dispatch(hideTalent(params)).then(res => {
          if (res.type == 'HIDE_PROFILE_SUCCEED') {
            trackHideProfile(params);
            InfoToast('Hide Successfully');

            setTimeout(() => {
              triggerLoadTalents('search-resume', '', 1);
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }, 200);
          } else {
            ErrorToast('Failed to hide this candidate');
          }
        });
      } else {
        dispatch(updateModelState(true));
        dispatch(updateModelName('Hide Profile Confirmation'));
      }
    }

    setAnchorEl(null);
  };

  return (
    <>
      {talent.unlocked ? (
        <UnreadMessageCountButton
          onClick={() => onClickChatButton(talent)}
          $count={getUnreadCount(talent)}>
          {getUnreadCount(talent) > 0 && <Bubble />}
          <ChatBubbleLeftRightIconStyled $count={getUnreadCount(talent)} />
        </UnreadMessageCountButton>
      ) : (
        <DotsIconButtonStyled
          onClick={handleClick}
          $backgroundPersist={open}
          disabled={talent.unlocked}>
          <MoreVertIconStyled />
        </DotsIconButtonStyled>
      )}

      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          '& .MuiMenu-list': {
            padding: '0'
          }
        }}
        PaperProps={{
          style: {
            borderRadius: '4px'
          }
        }}>
        <MenuItem
          onClick={onHideTalent}
          style={{ display: 'flex', justifyContent: 'space-between' }}
          sx={{
            '&:hover': {
              background: 'rgba(81, 42, 204, 0.1)'
            }
          }}>
          <EyeSlashIcon style={{ height: '20px', width: '20px' }} />
          <Spacer width={'10px'} />
          <span
            style={{
              fontWeight: '400',
              fontSize: '16px',
              letterSpacing: '0.15',
              fontFamily: 'Inter'
            }}>
            {talent.hidden ? 'Unhide profile' : 'Hide profile'}
          </span>
        </MenuItem>
      </Menu>
    </>
  );
};

export const UnreadMessageButton = ({ talent }) => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const channelsUnreadMessageCount = useSelector(
    state => state.talents.channelsUnreadMessageCount
  );

  const getUnreadCount = talent => {
    const index = channelsUnreadMessageCount.findIndex(item => {
      return talent.id == item.talentId;
    });

    return channelsUnreadMessageCount[index]?.unreadCount;
  };

  const onClickChatButton = talent => {
    history({
      pathname: '/talent-search',
      search: `TStab=chat&user_id=${talent.id}`
    });

    dispatch(updateCurrentTalent(talent));
    dispatch(updateProfilePopUp(true));
  };

  return (
    <UnreadMessageCountButton
      onClick={() => onClickChatButton(talent)}
      $count={getUnreadCount(talent)}>
      {getUnreadCount(talent) > 0 && <Bubble />}
      <ChatBubbleLeftRightIconStyled $count={getUnreadCount(talent)} />
    </UnreadMessageCountButton>
  );
};

export const Bookmark = ({ talent, type }) => {
  const dispatch = useDispatch();

  // bookmarking status
  const bookmarkingTalentIds = useSelector(
    state => state.talents.bookmarkingTalentIds
  );

  const bookmarking = bookmarkingTalentIds[talent.id];

  // unbookmarking status
  const unbookmarkingTalentIds = useSelector(
    state => state.talents.unbookmarkingTalentIds
  );
  const unbookmarking = unbookmarkingTalentIds[talent.id];

  const saveTalent = debounce(
    (talent, loading) => {
      if (loading) return;

      if (talent.bookmarked) {
        const params = {
          userId: talent.id
        };

        dispatch(unbookmarkTalent(params)).then(res => {
          if (res.type == 'UNBOOKMARK_TALENT_SUCCEED') {
            trackBookmark(talent, false);
            InfoToast('Successfully unbookmarked!');
          } else {
            ErrorToast('Failed to unbookmark');
          }
        });
      } else {
        const params = {
          userIds: [`${talent.id}`]
        };
        dispatch(bookmarkTalent(params)).then(res => {
          if (res.type == 'BOOKMARK_TALENT_SUCCEED') {
            trackBookmark(talent, true);
            InfoToast('Successfully bookmarked!');
          } else {
            ErrorToast('Failed to bookmark');
          }
        });
      }
    },
    1000,
    { leading: true, trailing: false }
  );

  const debouncedSaveTalent = useCallback(
    (talent, loading) => saveTalent(talent, loading),
    []
  );

  const trackBookmark = (talent, saved) => {
    if (type == 'unlocked') {
      let params = {
        event: 'CE_click-save-resume-unlock-page',
        save: saved,
        'user-id': talent.id
      };

      window.dataLayer.push({
        ...params
      });
    } else {
      let params = !talent.unlocked
        ? {
            event: 'CE_click-save-resume-talent-search-page',
            save: saved,
            'user-id': talent.id,
            value: talent.resumePrice
          }
        : {
            event: 'CE_click-save-resume-talent-search-page',
            save: saved,
            'user-id': talent.id
          };

      window.dataLayer.push({
        ...params
      });
    }
  };

  return (
    <>
      <SimpleTipsTooltip
        top={'30px'}
        left={'-43px'}
        width={'120px'}
        padding={'4px 8px'}
        borderRadius={'8px'}
        border={'1.5px solid #222'}
        shadow={'none'}
        icon={
          <IconButtonStyled
            onClick={() =>
              debouncedSaveTalent(talent, bookmarking || unbookmarking)
            }>
            {bookmarking || unbookmarking ? (
              <Spinner size="TINY" noPadding={true} />
            ) : talent.bookmarked ? (
              <SolidBookmarkIcon
                style={{ color: '#512acc', width: '16px', height: '16px' }}
              />
            ) : (
              <OutlinedBookmarkIcon
                style={{ color: '#512acc', width: '16px', height: '16px' }}
              />
            )}
          </IconButtonStyled>
        }
        description={
          <Grid justifyContent={'center'} display={'flex'}>
            <SimpleDescription>
              {talent.bookmarked ? 'Unbookmark Profile' : 'Bookmark Profile'}
            </SimpleDescription>
          </Grid>
        }
      />
    </>
  );
};

export const PricingButton = ({ talent, column, isMouseEnterCard }) => {
  const dispatch = useDispatch();

  const coins = useSelector(
    state => state.companies.myCompanyStatus.availableCoins
  );
  const reduxTalent = useSelector(state => state.talents.talent);

  // unlocking status
  const unlockingTalentIds = useSelector(
    state => state.talents.unlockingTalentIds
  );
  const unlocking = unlockingTalentIds[talent.id];

  const showInsufficientCoinsBanner = () => {
    dispatch(updateInsufficientCoinsBanner(true));
  };

  const [isMouseEnter, setIsMouseEnter] = useState(false);

  const onMouseIn = () => {
    setIsMouseEnter(true);
  };

  const onMouseOut = () => {
    setIsMouseEnter(false);
  };

  const trackPrice = (price, id) => {
    window.dataLayer.push({
      event: 'CE_click-unlock-resume',
      value: price,
      'user-id': id
    });
  };

  const triggerUnlockTalent = () => {
    dispatch(updateCurrentTalent(talent));

    if (coins < talent.resumePrice) {
      showInsufficientCoinsBanner();
      dispatch(updateModelState(true));
      dispatch(updateModelName('Insufficient Tokens'));
      return;
    }

    trackPrice(talent.resumePrice, talent.id);

    dispatch(updateModelState(true));
    dispatch(updateModelName('Unlock Profile Confirmation'));
  };

  const getCardStatus = talent => {
    if (reduxTalent.id == talent.id) {
      return true;
    }

    return false;
  };

  return (
    <MUIButtonStyled
      onMouseIn={onMouseIn}
      onMouseOut={onMouseOut}
      onClick={triggerUnlockTalent}
      $backgroundColor={'#fff'}
      $outline={getCardStatus(talent) ? 'none' : '1px solid #222'}
      label={
        <ButtonText>
          {unlocking ? (
            <Spinner size="TINY" color="white" noPadding={true} />
          ) : (
            <>
              {getCardStatus(talent) || isMouseEnter || isMouseEnterCard ? (
                <img
                  src={TalentSearchCoin}
                  style={{
                    width: column == 'talent-info' ? '25px' : '20px',
                    height: column == 'talent-info' ? '25px' : '20px'
                  }}
                />
              ) : (
                <img
                  src={TalentSearchCoinBlack}
                  style={{
                    width: column == 'talent-info' ? '25px' : '20px',
                    height: column == 'talent-info' ? '25px' : '20px'
                  }}
                />
              )}
              &nbsp;
              <span
                style={{
                  fontSize: column == 'talent-info' ? '15px' : '12px',
                  color:
                    getCardStatus(talent) || isMouseEnter || isMouseEnterCard
                      ? `${Colors.priWhite}`
                      : `${Colors.priBlack}`
                }}>
                {talent.resumePrice}
              </span>
            </>
          )}
        </ButtonText>
      }
      $padding={'0 8px'}
      $shadow={'none !important'}
      $width={column == 'talent-info' ? '75px' : '60px'}
      sx={{
        background:
          isMouseEnter && getCardStatus(talent)
            ? '#40249B !important'
            : isMouseEnter
            ? `${Colors.priPurple} !important`
            : isMouseEnterCard || getCardStatus(talent)
            ? `${Colors.priPurple}`
            : '#fff',
        height: column == 'talent-info' ? '30px' : '24px',
        minWidth: 'auto',

        ':hover': {
          backgroundColor: Colors.priPurple,
          color: 'white'
        }
      }}
    />
  );
};

export const LastActive = ({ talent }) => {
  return (
    <Grid container gap={'5px'}>
      <SecondContainerTitle>Last active on:</SecondContainerTitle>
      <SecondContainerSubtitle>
        {moment(talent.lastActiveAt).format('DD MMMM YYYY')}
      </SecondContainerSubtitle>
    </Grid>
  );
};

export const ResumeUploaded = ({ talent }) => {
  return (
    <Grid container gap={'5px'}>
      <SecondContainerTitle>Resume uploaded on:</SecondContainerTitle>
      <SecondContainerSubtitle>
        {moment(talent.lastActiveAt).format('DD MMMM YYYY')}
      </SecondContainerSubtitle>
    </Grid>
  );
};

export const CountryFlag = ({ nationality }) => {
  const countryCode = nationalities.getAlpha2Code(nationality, 'en');

  return (
    <FlagIcon
      size={15}
      code={countryCode}
      style={{ boxShadow: 'rgba(0, 0, 0, 0.25) 1px 1px 2px', width: '20px' }}
    />
  );
};
