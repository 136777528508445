import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import s from './index.module.scss';
import DefaultLayout from '../../layouts/DefaultLayout';
import { Button, PageContainer, Spinner } from '../../components';
import CollapsibleTable from './DataTable';
import {
  Text,
  Underline,
  TabContainer,
  UnpaidCountContainer,
  PageTitle,
  ButtonContainer,
  TabsContainer,
  Tabs,
  UnpaidCountWrapper,
  PaginationContainer,
  SpinnerContainer,
  TableWrapper
} from './styles';
import { SharedButton } from '../OrderHistory/SharedButton/index';
import { TextInputFunction } from '../../pages/OrderHistory/SharedTextInput/SharedTextInput';
import { debounce } from 'lodash';
import Pagination from '../OrderHistory/SharedPagination/Pagination';
import { cancelOrder, fetchMyOrders } from '../../redux/actions/order_action';
import { toast } from 'react-toastify';

let currentSearchText = '';

export default function OrderHistory() {
  const history = useNavigate();
  const pathname = history?.location?.pathname;
  const dispatch = useDispatch();

  const [sortKey, setSortKey] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('desc');
  const [tableData, setTableData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [orderSearch, setOrderSearch] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const [pendingTotal, setPendingTotal] = useState(0);

  const tabTitle = ['All Orders', 'Unpaid Orders'];

  const getOffset = () => {
    return (selectedPage - 1) * 20;
  };

  useEffect(() => {
    if (selectedIndex === 0) {
      dispatch(
        fetchMyOrders({
          invoice_number_or_created_by: orderSearch,
          sort_key: sortKey,
          sort_direction: sortDirection,
          offset: getOffset(),
          order_status: selectedIndex == 0 ? '' : 'all_orders',
          after: '',
          before: ''
        })
      ).then(res => {
        if (!pendingTotal) {
          const total = res?.myOrders.filter(item => {
            return item?.node?.orderStatus === 'pending-payment';
          }).length;
          setPendingTotal(total);
        }
      });
    } else if (selectedIndex === 1) {
      dispatch(
        fetchMyOrders({
          invoice_number_or_created_by: orderSearch,
          sort_key: sortKey,
          sort_direction: sortDirection,
          offset: getOffset(),
          order_status: selectedIndex == 0 ? '' : 'unpaid',
          after: '',
          before: ''
        })
      );
    }
  }, [selectedIndex]);

  const ordersList = useSelector(state => state.orders.myOrders);
  const fetchingMyOrders = useSelector(state => state.orders.fetchingMyOrders);
  const totalCount = useSelector(state => state.orders.myOrdersCount);

  const handleCancelOrder = value => {
    let cancelOrderParams = {
      orderId: value.id
    };

    dispatch(cancelOrder(cancelOrderParams)).then(item => {
      if (item.type == 'CANCEL_ORDER_SUCCEED') {
        try {
          window.dataLayer.push({
            event: 'cancel-order',
            order_id: value?.id
          });
        } catch {}

        let success = item?.payload?.status;

        if (success) {
          const invoiceIndex = tableData.findIndex(data => {
            return value?.invoiceNumber == data?.invoiceNumber;
          });

          const invoiceNumber = tableData[invoiceIndex]?.node?.invoiceNumber;

          toast.success(`Invoice ${invoiceNumber} is removed.`);
          dispatch(
            fetchMyOrders({
              invoice_number_or_created_by: orderSearch,
              sort_key: sortKey,
              sort_direction: sortDirection,
              offset: getOffset(),
              order_status: selectedIndex == 0 ? '' : 'unpaid',
              after: '',
              before: ''
            })
          );
        }
      } else if (item.type == 'CANCEL_ORDER_FAILED') {
        let errorMessage = item?.payload?.errorMessage;
        toast.error(errorMessage);
      }
    });
  };

  // update table data state based on orders list and tab value
  const updateTableData = () => {
    if (selectedIndex === 0) {
      setTableData(ordersList);
    } else if (selectedIndex === 1) {
      setTableData(ordersList);
    }
  };

  // set pendingOrdersList on ordersList change. also updates table data
  useEffect(() => {
    setTableData(ordersList);
  }, [ordersList]);

  const handleMakePayment = node => {
    try {
      window.dataLayer.push({
        event: 'make-payment-order-history',
        order_id: node?.id
      });
    } catch {}

    history(`/purchase/payment/${node.id}/${node.cart.id}?orderSummary=true`);

    if (pathname?.includes('/purchase/payment')) {
      try {
        window.dataLayer.push({
          event: 'make-payment-order-summary',
          order_id: node?.id
        });
      } catch {}
    }
  };

  // update table data on tab change
  useEffect(() => {
    updateTableData();
  }, [selectedIndex]);

  const handleSelectedIndex = index => {
    if (selectedIndex == index) return;

    try {
      if (index == 0) {
        window.dataLayer.push({
          event: 'view-all-orders'
        });
      } else {
        window.dataLayer.push({
          event: 'view-unpaid-orders'
        });
      }
    } catch {}

    setSelectedIndex(index);
  };

  const navigateToOrder = () => {
    history('/purchase/choose');
  };

  const performSearch = value => {
    window.dataLayer.push({
      event: 'search-invoice',
      search_term: value?.replace(/\s/g, '').toLowerCase()
    });
    setOrderSearch(value);
  };

  const debouncedSearch = debounce(performSearch, 700);

  const handleSearch = async event => {
    currentSearchText = event.target.value;
    debouncedSearch(currentSearchText);
  };

  const [titleIndex, setTitleIndex] = useState(null);

  const getTitleIndex = index => {
    setTitleIndex(index);
  };

  const onHeaderClick = (column, enableSort, index) => () => {
    const title = column?.title?.toLowerCase();

    if (!enableSort) return;

    let cSortKey, cSortDirection;

    switch (title) {
      case 'date created':
        cSortKey = 'created_at';
        cSortDirection = sortDirection == 'desc' ? 'asc' : 'desc';

        getTitleIndex(index);
        setSortDirection(cSortDirection);
        setSortKey(cSortKey);
        break;
    }

    dispatch(
      fetchMyOrders({
        invoice_number_or_created_by: orderSearch,
        sort_key: sortKey,
        sort_direction: sortDirection,
        offset: getOffset(),
        order_status: selectedIndex == 0 ? '' : 'unpaid',
        after: '',
        before: ''
      })
    );
  };

  useEffect(() => {
    if (selectedPage) {
      dispatch(
        fetchMyOrders({
          invoice_number_or_created_by: orderSearch,
          sort_key: sortKey,
          sort_direction: sortDirection,
          offset: getOffset(),
          order_status: selectedIndex == 0 ? '' : 'unpaid',
          after: '',
          before: ''
        })
      );
    }
  }, [selectedPage]);

  useEffect(() => {
    dispatch(
      fetchMyOrders({
        invoice_number_or_created_by: orderSearch,
        sort_key: sortKey,
        sort_direction: sortDirection,
        offset: getOffset(),
        order_status: selectedIndex == 0 ? '' : 'unpaid',
        after: '',
        before: ''
      })
    );
  }, [orderSearch]);

  return (
    <DefaultLayout>
      <PageContainer>
        <PageTitle is_banner={false}>
          <ButtonContainer
            button_title="create-new-order"
            id="create-new-order-button">
            <SharedButton type="basic" onClick={navigateToOrder}>
              Create New Order
            </SharedButton>
          </ButtonContainer>
          <Text page_title="true">Order History</Text>
        </PageTitle>

        <TabsContainer>
          <Tabs>
            {tabTitle.map((item, index) => {
              return (
                <TabContainer
                  key={`${item}--${index}`}
                  type={'all_orders'}
                  onClick={() => handleSelectedIndex(index)}>
                  <Text tab={'true'}>{item}</Text>

                  {index == 1 && pendingTotal > 0 && (
                    <UnpaidCountWrapper>
                      <UnpaidCountContainer>
                        <Text unpaid_count={'true'}>
                          {pendingTotal > 20 ? '20+' : pendingTotal}
                        </Text>
                      </UnpaidCountContainer>
                    </UnpaidCountWrapper>
                  )}

                  {selectedIndex == index && <Underline />}
                </TabContainer>
              );
            })}
          </Tabs>
        </TabsContainer>

        <div>
          <TextInputFunction
            type="text"
            label="order-history-search-bar"
            placeholder="Search invoice no. or created by"
            handleChange={handleSearch}
          />
        </div>

        <div className={s.actionsRow}>
          <div></div>

          {!fetchingMyOrders && (
            <PaginationContainer pagination_position={'top'}>
              {totalCount > 0 && (
                <Pagination
                  className="pagination-bar"
                  selectedPage={selectedPage}
                  totalCount={totalCount}
                  pageSize={20}
                  onPageChange={page => setSelectedPage(page)}
                />
              )}
            </PaginationContainer>
          )}
        </div>

        <TableWrapper>
          {fetchingMyOrders ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <CollapsibleTable
              tableData={tableData}
              handleMakePayment={handleMakePayment}
              onHeaderClick={onHeaderClick}
              sortDirection={sortDirection}
              sortKey={sortKey}
              titleIndex={titleIndex}
              handleCancelOrder={handleCancelOrder}
            />
          )}
        </TableWrapper>

        {!fetchingMyOrders && (
          <PaginationContainer pagination_position={'bottom'}>
            {totalCount > 8 && (
              <Pagination
                className="pagination-bar"
                selectedPage={selectedPage}
                totalCount={totalCount}
                pageSize={20}
                onPageChange={page => setSelectedPage(page)}
              />
            )}
          </PaginationContainer>
        )}
      </PageContainer>
    </DefaultLayout>
  );
}
