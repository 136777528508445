import React, { useEffect } from 'react';
import useInternLogic from '../../controller/useInternLogic';
import usePathKey from '../../helper/usePathKey';
import useSeniorLogic from '../../controller/useSeniorLogic';
import useDefaultLogic from '../../controller/useDefaultLogic';
import useEditLogic from '../../controller/useEditLogic';
import useDraftLogic from '../../controller/useDraftLogic';

export default function CustomLogic() {
  const pathKey = usePathKey();

  if (pathKey === 'intern') {
    useInternLogic();
  } else if (pathKey === 'senior') {
    useSeniorLogic();
  } else if (pathKey === 'new') {
    useDefaultLogic();
  } else if (pathKey === 'edit') {
    useEditLogic();
  } else if (pathKey === 'draft') {
    useDraftLogic();
  }

  return null;
}
