import React, { useState, useEffect } from 'react';
import { Accordion, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  MainModal,
  ModalHeader,
  ModalFooterInfo,
  CloseIconContainer,
  AccordionStyled,
  AccordionSummaryStyled,
  AccordionSummaryTitle,
  AccordionDetailsStyled,
  BorderModal
} from './styles';
import { Close as CloseIcon } from '@mui/icons-material';
import { ModalFooter } from '../../pages/Jobs/PopUps/JobTypePopUp/styles';
import { CSEMAIL, Hiredly_Phone_Number } from '../../utils/Constants';
import { getCountry } from '../../hooks/useRegionalisation';

<style>
  {`
    .hideScroll {
      max-height: 472px;
      overflow-y: auto;
      scrollbar-width: thin; /* Firefox */
    }

    .hideScroll::-webkit-scrollbar {
      width: 1px;
      display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
    }
  `}
</style>;

const PopUpInfo = ({ open, handleClose, content }) => {
  const [expandedIndex, setExpandedIndex] = React.useState(null);

  const handleChange = index => (event, isExpanded) => {
    setExpandedIndex(isExpanded ? index : null);
  };

  useEffect(() => {
    setExpandedIndex(0);
  }, []);

  return (
    <MainModal
      open={open}
      onClose={handleClose}
      sx={{
        '&.MuiModal-root': {
          height: 'fit-content',
          borderRadius: '16px !important',
          overflow: 'hidden'
        }
      }}>
      <BorderModal>
        <ModalHeader>
          {content?.modalTitle ?? content.title} FAQ
          <CloseIcon
            onClick={handleClose}
            style={{ color: '#222', fontSize: '24px' }}
          />
        </ModalHeader>

        <div
          style={{
            padding:
              content.title == 'Job Credit' ||
              content.title == 'Unlimited Job Token'
                ? '16px 24px'
                : '8px 24px 16px 24px',
            maxHeight: '472px',
            overflowY: 'auto'
          }}
          className="hideScroll">
          {content.title == 'Job Credit' ||
          content.title == 'Unlimited Job Token' ? (
            <>
              {content.content.map((item, index) => (
                <div>{item.content}</div>
              ))}
            </>
          ) : (
            <>
              {content.content.map((item, index) => (
                <AccordionStyled
                  key={index}
                  expanded={expandedIndex == index}
                  onChange={handleChange(index)}>
                  <AccordionSummaryStyled
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index + 1}a-content`}
                    id={`panel${index + 1}a-header`}>
                    <AccordionSummaryTitle expanded={expandedIndex == index}>
                      {item.questions}
                    </AccordionSummaryTitle>
                  </AccordionSummaryStyled>
                  <AccordionDetailsStyled>
                    <Typography
                      style={{
                        color: 'rgba(0, 0, 0, 0.60)',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.15px'
                      }}>
                      {item.answer}
                    </Typography>
                  </AccordionDetailsStyled>
                </AccordionStyled>
              ))}

              <ModalFooterInfo>
                For further enquiries, please email
                <a target="_blank" href={`mailto:${CSEMAIL[getCountry()]}`}>
                  {CSEMAIL[getCountry()]}
                </a>{' '}
                <>
                  or call
                  <a href={`tel: ${Hiredly_Phone_Number['MYWithCountryCode']}`}>
                    {Hiredly_Phone_Number['MYWithSpace']}
                  </a>
                </>
                .
              </ModalFooterInfo>
            </>
          )}
        </div>
      </BorderModal>
    </MainModal>
  );
};

export default PopUpInfo;
