import React from 'react';
import { Switch as MUISwitch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Colors from '../../styles/colors.module.scss';

const useStyles = ({ switchOnText, switchOffText, jobList, origin }) =>
  makeStyles(() => ({
    root: {
      width: 120,
      height: jobList ? 52 : 48,
      padding: '7px',
      transform: origin == 'job_column' && 'translateY(6px)'
    },
    switchBase: {
      padding: 12
    },
    thumb: {
      width: jobList ? 28 : 26,
      height: jobList ? 28 : 26,
      backgroundColor: Colors.priWhite
    },
    track: {
      background: Colors.terMediumGrey,
      opacity: '1 !important',
      borderRadius: 20,
      position: 'relative',
      '&:before, &:after': {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        width: '50%',
        transform: 'translateY(-50%)',
        color: Colors.priWhite,
        fontFamily: 'InterBold',
        textAlign: 'center'
      },
      '&:before': {
        content: `'${switchOnText}'`,
        left: 16,
        opacity: 0
      },
      '&:after': {
        content: `'${switchOffText}'`,
        right: 17
      }
    },
    checked: {
      '&$switchBase': {
        color: Colors.terLightGrey,
        transform: 'translateX(68px)',
        '&:hover': {
          backgroundColor: 'rgba(24,90,257,0.08)'
        }
      },
      '& $thumb': {
        backgroundColor: Colors.priWhite
      },
      '& + $track': {
        backgroundColor: `${Colors.priPurple} !important`,
        '&:before': {
          opacity: 1
        },
        '&:after': {
          opacity: 0
        }
      }
    }
  }));

const Switch = ({
  checked,
  onChange,
  switchOnText,
  switchOffText,
  jobList,
  origin
}) => {
  const classes = useStyles({ switchOnText, switchOffText, jobList, origin })();
  return (
    <MUISwitch
      id="active-button"
      classes={classes}
      checked={checked}
      onChange={onChange}
    />
  );
};

Switch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  switchOnText: PropTypes.string,
  switchOffText: PropTypes.string
};

Switch.defaultProps = {
  checked: true,
  onChange: () => {},
  switchOnText: 'Active',
  switchOffText: 'Inactive'
};

export default Switch;
