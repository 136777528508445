import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  Card,
  Grid
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BillingAddressCard from '../../CompanyAccount/CompanyDetails/BillingAddressCard';
import { Button } from '../../../components';
import styles from './index.module.scss';
import CompanyDetailsCard from '../../CompanyAccount/CompanyDetails/CompanyDetailsCard';
import { ErrorToast } from '../../../utils/ToastUtils';
import { MUIButtonStyled } from '../../../components/MUIButton';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyCompanySettings } from '../../../redux/actions/company_actions';

const useStyles = () => ({
  root: {
    margin: 0,
    padding: '40px 40px',
    paddingBottom: 0,
    border: 0
  }
});

const CompanyInfoPopUp = ({ open, handleClose, data }) => {
  const history = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const onBackToMain = () => {
    handleClose();
    history('/welcome');
  };

  const myCompanySettings = useSelector(
    state => state.companies.myCompanySettings
  );

  const onDone = async () => {
    dispatch(fetchMyCompanySettings()).then(res => {
      if (res?.companySettings?.onBoardComplete) {
        handleClose();
      } else {
        ErrorToast('Please Complete Your Company Details to Proceed');
      }
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: 20,
          minWidth: 1200,
          backgroundColor: '#F7F7F9'
        }
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0,0,0,0.5)',
          boxShadow: 'none'
        }
      }}>
      <MuiDialogTitle disableTypography className={classes.root}>
        <p className={styles.popupTitle}>
          Please Complete Your Company Details to Proceed
        </p>
      </MuiDialogTitle>
      {data && (
        <MuiDialogContent>
          <div className={styles.contentContainer}>
            <div className={styles.cardContainer}>
              <Card className={styles.cardStyle}>
                <BillingAddressCard
                  billingAddress={data?.billingAddress?.at(0)}
                />
              </Card>
              <Card className={styles.cardStyle}>
                <CompanyDetailsCard data={data} />
              </Card>
            </div>
            <div className={styles.buttonContainer}>
              <Grid sx={{ marginRight: '30px' }}>
                <MUIButtonStyled
                  label="Back to Main"
                  onClick={onBackToMain}
                  solidWhite
                  large
                />
              </Grid>
              <MUIButtonStyled label="Done" large onClick={onDone} />
            </div>
          </div>
        </MuiDialogContent>
      )}
    </Dialog>
  );
};

CompanyInfoPopUp.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.objectOf(Object)
};

CompanyInfoPopUp.defaultProps = {
  open: false,
  handleClose: () => {},
  data: null
};

export default CompanyInfoPopUp;
