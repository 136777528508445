import { styled as styling } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Check as CheckIcon, Add as AddIcon } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

export const MainContainer = styling('div')({
  background: '#f5f5f5',
  width: '100%',
  position: 'relative',
  padding: '20px 60px 25px 60px',
});

export const InterviewNoteContainer = styling('div')({
  width: 'calc(100% - 40px)',
  margin: '0 0 20px 0',
  display: 'flex',
  flexDirection: 'row',
});

/* Left Panel */
export const LeftPanel = styling('div')({
  margin: '0 15px 0 0',
  minWidth: '30px',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const Line = styling('div')(({ 
  notLast 
}) => ({
  width: '1px',
  height: '100%',
  background: 'black',
  position: 'absolute',
  left: 'calc(50% - 0.5px)',
  zIndex: 0,

  ...(notLast == 'true' && {
    height: 'calc(100% + 20px)',
  }),
}));

export const ProfileImage = styling('div')(({ 
  isAbsolute 
}) => ({
  width: '30px',
  height: '30px',
  borderRadius: '25px',
  background: 'gray',
  zIndex: '1',

  ...(isAbsolute == 'true' && {
    position: 'absolute',
    top: '22px',
  }),
}));

export const StatusIndicator = styling('div')(({ done, addNote }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '14px',
  height: '14px',
  borderRadius: '20px',
  border: '1px solid #512acc',
  background: '#f5f5f5',
  zIndex: 1,
  ...(done == 'true' && {
    background: '#512acc',
    border: 0,
  }),
  ...(addNote == 'true' && {
    cursor: 'pointer',
    background: '#000000',
    border: 0,
  }),
}));

export const CheckIconStyled = styling(CheckIcon)({
  fontSize: '9px',
  color: '#ffffff',
});

export const AddIconStyled = styling(AddIcon)({
  fontSize: '11px',
  color: '#ffffff',
});

/* End Left Panel */

/* Right Panel */
export const RightPanel = styling('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const InterviewNumberDateContainer = styling('div')({
  display: 'flex',
  flexDirection: 'row',
});

/* Add note container and it's child */
export const AddNoteContainer = styling('div')(({ withNote }) => ({
  width: '100%',
  margin: withNote == 'true' ? '0 0 5px 0' : '0 0 20px 0',
}));

export const AddNoteHeader = styling('div')({
  fontSize: '15px',
  fontWeight: 600,
  cursor: 'pointer',
});

export const AddNoteMessage = styling('div')({
  fontSize: '15px',
  color: 'rgba(0, 0, 0, 0.5)',
});

export const TextFieldStyled = styling(TextField)({
  width: '100%',
  marginTop: '12px',
  border: '1px solid #aeaeae',
  background: 'transparent',
  borderRadius: '8px',
  '& .MuiFilledInput-multiline': {
    padding: '27px 12px 10px',
  },
});

export const ButtonsContainer = styling('div')({
  display: 'flex',
  justifyContent: 'end',
  margin: '25px 0',
});

/* End Add note container and it's child */
export const NoteContentContainer = styling('div')({
  paddingTop: '10px',
  width: '100%',
});

export const NoteCommenterNameTimeContainer = styling('div')({
  display: 'flex',
  flexDirection: 'row',
});

export const NoteCommenterName = styling('div')({
  fontSize: '12px',
  fontWeight: 600,
});

export const NoteAddedTime = styling('div')({
  paddingLeft: '10px',
  fontSize: '12px',
});

export const InterviewRemark = styling('div')({
  padding: '5px 0 15px 0',
  fontSize: '13px',
});


export const InterviewNumber = styling('div')({
  fontSize: '12px',
  color: '#512acc',
  fontWeight: 600,
});

export const InterviewDate = styling('div')({
  paddingLeft: '10px',
  fontSize: '12px',
});

/* End Right Panel */
export const CircularProgressStyled = styling(CircularProgress)({
  width: '20px',
  height: '20px',
  color: '#fff',
});

export const ButtonContainer = styling('div')({
  padding: '0 20px 0 0',
});
