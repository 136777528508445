import { styled as styling } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';

export const MainModal = styling(Modal)({
    maxWidth: '770px',
    maxHeight: '528px',
    margin: 'auto',
    borderRadius: '16px',
});
  

export const ModalHeader = styling('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  justifyContent: 'space-between',
  background: 'white',
  padding: '16px 16px 16px 24px',
  borderBottom: '1px solid #D8D8D8',
  position: 'sticky',
  borderRadius: '16px 16px 0px 0px',

  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px'
});

export const ModalFooterInfo = styling('div')({
    display: 'flex',
    padding: ' 40px 0px 40px 0px',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',

    color: 'rgba(0, 0, 0, 0.60)',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',

    '& a':{
        textDecoration: 'none',
        color: '#512ACC',
        padding: '0px 4px'
    }
});

export const CloseIconContainer = styling('div')({
  height: '24px'
});

export const AccordionStyled = styling(Accordion)({
    '&.Mui-expanded:before': {
        opacity: 1
    },
    boxShadow: 'none'

});

export const AccordionSummaryStyled = styling(AccordionSummary)({
    color: 'rgba(0, 0, 0, 0.60)',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.1px', 
    padding: '0px',
});

export const AccordionSummaryTitle = styling(Typography)(({ expanded }) => ({
    color: expanded ? '#40249B' : 'rgba(0, 0, 0, 0.60)',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.1px',
  }));

export const AccordionDetailsStyled = styling(AccordionDetails)({
    color: 'rgba(0, 0, 0, 0.60) !important',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    padding: '8px 0px 16px'
});

export const BorderModal = styling('div')({
    background: 'white', 
    borderRadius: '16px !important'
});


