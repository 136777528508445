import React, { useEffect, useRef, useState } from 'react';
import { Chip, IconButton } from '@mui/material';
import { XMarkIcon as CloseIcon } from '@heroicons/react/24/outline';
import {
  ContactButton,
  CustomTooltip,
  InfoIcon,
  TooltipContainer,
  TooltipContentContainer,
  TooltipContentPurpleContainer,
  TooltipFooterContainer,
  TooltipTitleContainer,
  TypographyStyled
} from './styles';
import { updateContactSalesPopupStatus } from '../../../../redux/actions/job_action';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { updateCompanyVideoStatus } from '../../../../redux/actions/company_actions';

export default function StatusTag(props) {
  // Draft
  // review
  // live on hiredly
  // rejected

  // index is the order from mapping
  // selected is the selected status
  const {
    status,
    index,
    handleStatusChange,
    onVideo,
    companyVideo,
    createdAt,
    videoIndex,
    vimeoId
  } = props;

  const renderColour = status => {
    switch (status) {
      case 'Draft':
        return {
          border: `1px solid rgba(0, 0, 0, 0.38)`,
          backgroundColor: '#EFEFEF',
          color: `rgba(0, 0, 0, 0.38)`
        };

      case 'In Review':
        return {
          border: `1px solid #C0840C`,
          backgroundColor: '#FDF4E1',
          color: `#C0840C`
        };

      case 'Live on Hiredly':
        return {
          border: `1px solid #7A9D2F`,
          backgroundColor: '#E7F5E3',
          color: `#7A9D2F`
        };

      case 'Rejected':
        return {
          border: `1px solid #BE4242`,
          backgroundColor: `#F5E1E1`,
          color: `#BE4242`
        };

      default:
        return {
          border: `1px solid rgba(0, 0, 0, 0.38)`,
          backgroundColor: '#EFEFEF',
          color: `rgba(0, 0, 0, 0.38)`
        };
    }
  };

  const showIcon = status === 'In Review' || status === 'Rejected';
  const dispatch = useDispatch();
  const [openTooltip, setOpenTooltip] = useState(false);
  const storedVimeoId = useSelector(state => state?.companies?.storedVimeoId);

  useEffect(() => {
    if (companyVideo) {
      if (vimeoId !== storedVimeoId) {
        setOpenTooltip(false);
      }
    }
  }, [storedVimeoId]);

  return (
    <CustomTooltip
      open={openTooltip}
      placement="bottom"
      title={
        <TooltipPopUp
          status={status}
          createdAt={createdAt}
          setOpenTooltip={setOpenTooltip}
        />
      }
      disableHoverListener={!showIcon}>
      <Chip
        onDelete={
          showIcon && !onVideo
            ? e => {
                e.stopPropagation();
                if (companyVideo) {
                  dispatch(updateCompanyVideoStatus(vimeoId));
                  setOpenTooltip(true);
                } else {
                  handleStatusChange();
                  setOpenTooltip(true);
                }
              }
            : null
        }
        deleteIcon={showIcon && <InfoIcon status={status} />}
        label={status === 'Live on Hiredly' && onVideo ? 'Live' : status}
        sx={{
          ...renderColour(status),
          zIndex: 300,
          fontSize: onVideo ? '8px' : '10px',
          fontWeight: 'bold',
          height: onVideo ? '16px' : '21px',
          width: 'fit',
          padding: '0px 0px 0px 8px',

          '& .MuiChip-label': {
            padding: '0px 8px 0px 0px !important'
          }
        }}
      />
    </CustomTooltip>
  );
}

export const TooltipPopUp = ({ status, setOpenTooltip, createdAt }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const content = [
    {
      status: 'In Review',
      title: 'Content In Review',
      description:
        'Your content is currently in review to ensure it aligns with our community guidelines and resonates with our jobseekers. If your content is approved, it will automatically be updated for you. <br></br> Please check back within 1 - 3 business days on your content review status!',
      date: `Request submitted at ${moment(createdAt).format(
        'MM/DD/YYYY HH:mm'
      )}`
    },
    {
      status: 'Rejected',
      title: 'Content Rejected',
      description:
        'Your content has been rejected, please check your email for further details.',
      date: ''
    }
  ];

  const render = content.find(item => item?.status === status);

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenTooltip(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <TooltipContainer ref={ref}>
      <TooltipTitleContainer>
        <TypographyStyled
          sx={{
            fontWeight: 700,
            fontSize: '14px',
            letterSpacing: '0.1px',
            lineHeight: '24px'
          }}>
          {render?.title}
        </TypographyStyled>
        <IconButton
          sx={{ padding: '0px' }}
          onClick={e => {
            e.stopPropagation();
            setOpenTooltip(false);
          }}>
          <CloseIcon height={24} width={24} />
        </IconButton>
      </TooltipTitleContainer>

      <TooltipContentContainer>
        <TooltipContentPurpleContainer>
          <TypographyStyled
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              padding: '0px',
              letterSpacing: '0.15px',
              lineHeight: '24px'
            }}>
            <div
              style={{ margin: '0px !important', padding: '0px !important' }}
              dangerouslySetInnerHTML={{ __html: render?.description }}
            />
          </TypographyStyled>
          {render?.date && (
            <TypographyStyled
              sx={{
                fontWeight: 400,
                fontSize: '12px',
                fontStyle: 'italic',
                color: 'rgba(0, 0, 0, 0.38)',
                lineHeight: '20px'
              }}>
              {render?.date}
            </TypographyStyled>
          )}
        </TooltipContentPurpleContainer>
      </TooltipContentContainer>

      <TooltipFooterContainer>
        <TypographyStyled
          sx={{
            fontWeight: 400,
            fontSize: '14px'
          }}>
          Need help or more information?
        </TypographyStyled>
        <ContactButton
          onClick={e => {
            e.stopPropagation();
            setOpenTooltip(false);
            dispatch(updateContactSalesPopupStatus(true));
          }}>
          Contact us
        </ContactButton>
      </TooltipFooterContainer>
    </TooltipContainer>
  );
};
