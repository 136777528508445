import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  AlertStyled,
  ContainerStyled,
  CoverDesc,
  CoverTitle,
  ListText,
  OutlineInformationIcon,
  PlusIconStyled,
  SolidInformationIcon,
  TitleWrapper,
  UploadBrowse,
  UploadDesc,
  UploadImageContainer,
  UploadImageTextContainer,
  UploadTitle,
  List,
  PencilIconStyled,
  AdjustmentsHorizontalIconStyled,
  PlusCircleIconStyled,
  ImageInputContainer
} from './styles';
import { Grid, CircularProgress } from '@mui/material';
import IconButton from '../components/IconButton';
import InfoPopUp from './components/InfoPopup';
import { useDropzone } from 'react-dropzone';
import { ErrorToast, SuccessToast } from '../../../utils/ToastUtils';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  mutateCompanyProfile,
  readyFileUpload,
  updateCompanySidebar
} from '../../../redux/actions/company_actions';
import { RECORD_TYPE } from '../../../utils/Constants';
import { Spinner } from '../../../components';
import EditBannerPopUp from '../../Jobs/PopUps/EditBannerPopUp';

export default function CoverImage() {
  const dispatch = useDispatch();
  const companyProfile = useSelector(state => state.companies.companyProfile);
  const [open, setOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [SelectedFiles, setSelectedFiles] = useState(null);
  const [cropperFile, setCropperFiles] = useState(null);
  const [isEditBannerPopUpOpen, setIsEditBannerPopUpOpen] = useState(false);
  const companyCompletion = useSelector(
    state => state.companies.companyCompletion
  );

  useEffect(() => {
    if (companyProfile) {
      setSelectedFiles(companyProfile?.coverImage?.publicUrl);
      if (companyProfile?.coverImage?.publicUrl) {
        dispatch(
          updateCompanySidebar({
            ...companyCompletion,
            coverImage: true
          })
        );
      }
    }
  }, [companyProfile]);

  const constructFileName = file => {
    const cTimeStamp = moment.utc().valueOf().toString();
    return `company-malaysia-${
      companyProfile?.slug ? companyProfile?.slug.substring(0, 30) + '-' : ''
    }${cTimeStamp}-${
      file?.name?.toLowerCase()?.includes('png') ? 'png' : 'jpeg'
    }`;
  };

  const hiddenFileInput = useRef(null);
  const handleClick = () => {
    hiddenFileInput?.current?.focus();
    hiddenFileInput?.current?.click();
  };

  const uploadToAPI = file => {
    dispatch(
      mutateCompanyProfile({
        input: {
          coverImage: {
            id: companyProfile?.coverImage?.id,
            publicUrl: file,
            cover: true
          },
          imagePosition: ''
        }
      })
    )
      .then(res => {
        try {
          window.dataLayer.push({
            event: 'upload-cover-image'
          });
        } catch {}

        if (res.type === 'FETCH_COMPANY_PROFILE_SUCCEED') {
          SuccessToast('Company Profile Banner Uploaded Successfully!');
        } else {
          ErrorToast(res.errorMessage);
        }
      })
      .catch(err => {
        ErrorToast('Failed to upload. Please refresh your page and try again.');
      });
  };

  const onUploadMultipleJobBannerImage = async banner => {
    onDrop(banner, false);
    setIsEditBannerPopUpOpen(false);
  };

  const onDrop = useCallback(async (files, openEditBannerPopUp) => {
    try {
      const file = files[0] ? files[0] : files;
      // TODO: Remove provious s3 image link before uploading a new one
      const fileSize = file.size;
      const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
      const maxWidth = 2500;
      const maxHeight = 2500;

      if (fileSize > maxSizeInBytes) {
        ErrorToast(
          'File size too large. Please select a file smaller than 2MB.'
        );
        return; // Reject the image upload
      }

      setIsUploading(true);

      const constructedFileName = constructFileName(file);

      const response = await dispatch(
        readyFileUpload({
          input: {
            filename: constructedFileName,
            recordType: RECORD_TYPE.COMPANY_IMAGE
          }
        })
      );

      const data = response?.prepareFileUpload;

      if (data) {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', data?.presignedUrl, true);
        xhr.setRequestHeader('Content-Type', file?.type);
        xhr.onload = () => {
          if (xhr.status === 200) {
            setSelectedFiles(data?.publicUrl);
            uploadToAPI(data?.publicUrl);
            if (openEditBannerPopUp) {
              setIsEditBannerPopUpOpen(true);
            }
          }
        };
        xhr.send(file);
      }

      setIsUploading(false);
    } catch (err) {
      setIsUploading(false);
      ErrorToast(err.message);
    }
  }, []);

  const onDropRejected = error => {
    if (isFileTooLarge) {
      ErrorToast('File size exceeds 2MB limit');
    }
  };

  const onEditBannerClose = () => {
    setIsEditBannerPopUpOpen(false);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isFileTooLarge
  } = useDropzone({
    onDrop,
    onDropRejected,
    // maxSize: 2 * 1024 * 1024,
    maxFiles: 1,
    accept: 'image/*'
  });

  return (
    <ContainerStyled id="Cover Image">
      <TitleWrapper>
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <CoverTitle>Cover Image</CoverTitle>
          <CoverDesc>
            Add a cover image to attract jobseekers to view your company
            profile.
          </CoverDesc>
        </Grid>

        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            alignItems: 'center',
          
          }}>
          <IconButton
            onClick={() => setOpen(true)}
            tooltipTitle="More Information">
            <OutlineInformationIcon />
          </IconButton>

          {!SelectedFiles && (
            <IconButton
              onClick={handleClick}
              tooltipTitle="Upload Image"
              {...getRootProps()}>
              <PlusIconStyled />
            </IconButton>
          )}

          {SelectedFiles && (
            <IconButton
              onClick={handleClick}
              tooltipTitle="Change Image"
              {...getRootProps()}>
              <PencilIconStyled />
            </IconButton>
          )}

          {SelectedFiles && (
            <IconButton
              onClick={() => {
                setIsEditBannerPopUpOpen(true);
              }}
              tooltipTitle="Adjust Image">
              <AdjustmentsHorizontalIconStyled />
            </IconButton>
          )}
        </Grid>
      </TitleWrapper>

      <AlertStyled
        icon={<SolidInformationIcon />}
        onClick={() => setOpen(true)}>
        Click here to find out where this cover image is displayed.
      </AlertStyled>

      <UploadImageContainer {...getRootProps()}>
        <input
          type="file"
          id="file"
          ref={hiddenFileInput}
          accept="image/*"
          style={{ display: 'none' }}
          // disabled={SelectedFiles}
          {...getInputProps()}
        />
        {SelectedFiles ? (
          <Grid sx={{ position: 'relative' }}>
            <img
              src={SelectedFiles}
              height={206}
              width={309}
              style={{ objectFit: 'cover', borderRadius: '8px' }}
            />
            <IconButton
              onClick={() => {}}
              tooltipTitle="Adjust Image"
              style={{ position: 'absolute', right: '8px', bottom: '12px' }}>
              <AdjustmentsHorizontalIconStyled
                onClick={event => {
                  event.stopPropagation();
                  setIsEditBannerPopUpOpen(true);
                }}
              />
            </IconButton>
          </Grid>
        ) : (
          <ImageInputContainer>
            {isUploading ? <CircularProgress /> : <PlusCircleIconStyled />}
          </ImageInputContainer>
        )}
        <UploadImageTextContainer>
          <UploadTitle>
            {SelectedFiles ? 'Change Image' : 'Upload an Image'}
          </UploadTitle>
          <UploadDesc>
            Drag and drop your image file here or{' '}
            <UploadBrowse onClick={() => handleClick()}>
              Browse files
            </UploadBrowse>
          </UploadDesc>

          <Grid>
            <List>
              <ListText>Supported file type: .png, .jpg, .jpeg</ListText>
            </List>
            <List>
              <ListText>File size: less than 2 MB</ListText>
            </List>
            <List>
              <ListText>
                Recommended dimension: 3:2 aspect ratio (e.g. 900px by 600px)
              </ListText>
            </List>
          </Grid>
        </UploadImageTextContainer>
      </UploadImageContainer>

      {/* For info pop up */}
      <InfoPopUp open={open} handleClose={() => setOpen(false)} />

      {/* For adjusting image framing */}
      <EditBannerPopUp
        onlyDesktop={true}
        setSelectedBanner={setCropperFiles}
        desktopRatio={3 / 2}
        open={isEditBannerPopUpOpen}
        onUploadMultipleBannerImages={onUploadMultipleJobBannerImage}
        handleClose={onEditBannerClose}
        selectedDesktopBanner={SelectedFiles}
        selectedMobileBanner={SelectedFiles}
      />
    </ContainerStyled>
  );
}
