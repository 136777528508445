import { Dialog, DialogContent, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from './../index.module.scss';
import { ContactSalesButton } from '../styles';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import Colors from '../../../styles/colors.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateInfoDialogState,
  updateProductInfoDialog
} from '../../../redux/actions/order_action';
import {
  ButtonContainer,
  DefaultContainer,
  DialogBundleTypography,
  DialogContentTypography,
  DialogFooterTypography,
  DialogTitleTypography,
  TitleContainer
} from './styles';
import { BundleCard } from '../../../components/BundleCard';

export default function MoreInfoDialog() {
  const dispatch = useDispatch();
  const openInfoDialog = useSelector(state => state.orders.openInfoDialog);
  const infoDialog = useSelector(state => state.orders.infoDialog);

  const handleDialogClose = () => {
    dispatch(updateInfoDialogState(false));
    dispatch(updateProductInfoDialog(infoDialog));
  };

  const listWithCustomIcons = input => {
    // Split the string into parts for the <ul> tag and between <li> tags
    const parts = input
      ?.split(/<ul>|<\/ul>|<\/?li>/)
      ?.filter(part => part?.trim() !== '');

    return (
      <div>
        <style>
          {`
          .custom-ul {
            list-style: none; 
            padding: 0px;
          }
          .custom-li {
            display: flex; 
            align-items: center; 
            margin-bottom: 0px; 
          }
          .custom-li-icon {
            margin-right: 8px; 
            margin-top: 6px;
            
          }
        `}
        </style>
        <div dangerouslySetInnerHTML={{ __html: parts?.at(0) }} />
        <ul className="custom-ul">
          {parts?.slice(1)?.map((text, index) => (
            <li key={index} className="custom-li">
              <span>
                <CheckCircleIcon
                  className="custom-li-icon"
                  color="#90B938"
                  height="24px"
                  width="24px"
                />
              </span>
              {text}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const productPageInfo = infoDialog?.productPageInfo;
  const productBundleInfo = infoDialog?.productBundleInfo;

  const isSlot = infoDialog?.title?.includes('Slot');

  return (
    <Dialog
      className={styles.dialogOuter}
      onClose={handleDialogClose}
      open={openInfoDialog}
      PaperProps={{
        style: {
          minWidth: '700px',
          backgroundColor: `${Colors.priWhite}`,
          maxHeight: '691px'
        }
      }}>
      <TitleContainer>
        <DialogTitleTypography>{productPageInfo?.header}</DialogTitleTypography>
        <XMarkIcon
          height={24}
          width={24}
          className={styles.closeIcon}
          onClick={() => handleDialogClose()}
        />
      </TitleContainer>

      <DialogContent dividers>
        <DialogContentTypography>
          {/* description 1 */}
          <div
            dangerouslySetInnerHTML={{
              __html: productPageInfo?.description1
            }}
          />

          {/* gif */}
          {productPageInfo?.gifImageUrl && (
            <DefaultContainer sx={{ margin: '16px 0px 16px 0px' }}>
              <img
                src={productPageInfo?.gifImageUrl}
                style={{ width: '100%', height: '240px', objectFit: 'contain' }}
              />
            </DefaultContainer>
          )}

          {/* description 2 */}
          {listWithCustomIcons(productPageInfo?.description2)}

          {/* bundles */}

          {productBundleInfo?.length > 1 && (
            <DefaultContainer
              container
              sx={{
                flexDirection: 'column',
                gap: '24px',
                textAlign: 'center'
              }}>
              <DialogBundleTypography>Bundles:</DialogBundleTypography>

              <DefaultContainer
                container
                sx={{
                  flexDirection: 'row',
                  height: 'auto',
                  gap: '10px',
                  justifyContent: 'center'
                }}>
                {/* This for single token */}
                {productBundleInfo
                  ?.sort((a, b) => a?.token_amount - b?.token_amount)
                  ?.at(0)?.token_amount !== 1 && (
                  <BundleCard
                    bundle={{
                      id: infoDialog?.id,
                      title: infoDialog?.title,
                      amount: 1,
                      price: isSlot
                        ? `${Math.round(infoDialog?.price / 12)}`
                        : `${Math.round(infoDialog?.price)}`,
                      save: null,
                      annual: `${parseInt(infoDialog?.price).toLocaleString()}`,
                      productCode: infoDialog?.productCode,
                      infoDialog: true,
                      isSlot: isSlot
                    }}
                  />
                )}

                {productBundleInfo
                  .sort((a, b) => a?.token_amount - b?.token_amount)
                  .map(bundle => {
                    const price =
                      Number(bundle?.discounted_amount) /
                      Number(bundle?.token_amount);
                    const discountPrice = parseInt(bundle?.discounted_amount);

                    const saved =
                      (infoDialog?.price * Number(bundle?.token_amount)) /
                        discountPrice -
                      1;

                    const percentage = saved * 100;
                    const productCode = infoDialog?.productCode;

                    return (
                      <BundleCard
                        bundle={{
                          id: infoDialog?.id,
                          title: infoDialog?.title,
                          amount: bundle?.token_amount,
                          price: isSlot
                            ? `${Math.round(price / 12)}`
                            : `${Math.round(price)}`,
                          save:
                            percentage === 0
                              ? null
                              : `${Math.round(percentage)}`,
                          annual: `${parseInt(discountPrice).toLocaleString()}`,
                          productCode: productCode,
                          infoDialog: true,
                          isSlot: isSlot
                        }}
                      />
                    );
                  })}
              </DefaultContainer>
            </DefaultContainer>
          )}

          {/* footer */}
          <DialogFooterTypography>
            <div
              dangerouslySetInnerHTML={{
                __html: productPageInfo?.footer
              }}
            />
          </DialogFooterTypography>
        </DialogContentTypography>
      </DialogContent>

      {/* <ButtonContainer>
        <ContactSalesButton
          onClick={() => handleDialogClose()}
          large
          sx={{ fontWeight: 700 }}>
          Got it
        </ContactSalesButton>
      </ButtonContainer> */}
    </Dialog>
  );
}
