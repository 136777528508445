import React, { Fragment, useState } from 'react';
import {
  Text,
  HeightSpacer,
  BackButtonContainer,
  Spacer,
  RenewInactiveJobsContainer,
  CardStyled,
  ConfirmButtonContainer,
  InactiveJobsContainer,
  ApplicantCountContainer,
  CardContentContainer,
  JobCardDetails,
  TextContainer
} from './styles';
import Radio from '@mui/material/Radio';
import { ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material';
import { SharedButton } from '../SharedButton';
import { Skeleton } from '@mui/material';
import SharedBackButton from '../SharedBackButton';
import { useDispatch, useSelector } from 'react-redux';
import { updateExpiryDatesPopupContentIndex } from '../../../../../redux/actions/job_action';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const InactiveJobsPopup = ({ onCloseModal }) => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const fetchingAllInactiveJobs = useSelector(
    state => state.jobs.fetchingAllInactiveJobs
  );
  const allInactiveJobs = useSelector(state => state.jobs.allInactiveJobs);
  const expiryDatesContentIndex = useSelector(
    state => state.jobs.expiryDatesPopupContentIndex
  );

  const [selectedJobId, setSelectedJobId] = useState('');

  const navigateToEditJob = () => {
    history({ pathname: `/jobs/${selectedJobId}` });
    onCloseModal();
  };

  const onClickRadioButton = event => {
    setSelectedJobId(event.target.value);
  };

  const onClearSelection = () => {
    setSelectedJobId('');
  };

  const navigateToJobSlotPopover = () => {
    dispatch(updateExpiryDatesPopupContentIndex(0));
    setSelectedJobId('');
  };

  const disableButton = productType => {
    if (productType == 'inactive-jobs') {
      if (allInactiveJobs?.length <= 0 || selectedJobId == '') {
        return true;
      }
    }
  };

  return (
    <RenewInactiveJobsContainer
      expiry_dates_content_index={`${expiryDatesContentIndex}`}>
      <SharedBackButton onClick={navigateToJobSlotPopover} />

      <HeightSpacer height={'20px'} />

      <div style={{ textAlign: 'center' }}>
        <Text renew_inactive_jobs="true">Renew Inactive Jobs</Text>
      </div>

      <HeightSpacer height={'40px'} />

      <TextContainer notice="true">
        <Text subtitle="true">
          Select only <b>1 inactive job </b>to renew.
        </Text>
      </TextContainer>

      <HeightSpacer height={'5px'} />

      <InactiveJobsContainer>
        <HeightSpacer height={'5px'} />

        <TextContainer guidance="true">
          <Text style={{ margin: '0' }}>
            <b>
              {' '}
              Select the inactive job postings that you would like to renew
            </b>
          </Text>
          <Text clear="true" onClick={onClearSelection}>
            Clear
          </Text>
        </TextContainer>

        <HeightSpacer height={'15px'} />

        {fetchingAllInactiveJobs ? (
          <div>
            {Array.from(['0', '1', '2', '3', '4', '5', '6', '7']).map(
              (item, index) => {
                return (
                  <Fragment key={index}>
                    <Skeleton variant="text" width={'100%'} />
                    <HeightSpacer height={'10px'} />
                  </Fragment>
                );
              }
            )}
          </div>
        ) : null}

        {allInactiveJobs?.map((job, index) => {
          return (
            <Fragment key={job?.id}>
              <CardStyled key={index}>
                <Spacer width="8px" />
                <Radio
                  disableRipple
                  checked={selectedJobId === job?.id}
                  onChange={onClickRadioButton}
                  value={job?.id}
                  name="radio-button"
                  style={{
                    transform: 'scale(1.4)',
                    color: selectedJobId == job?.id ? '#512acc' : '#aeaeae',
                    backgroundColor: 'transparent'
                  }}
                />

                <Spacer width="20px" />

                <CardContentContainer>
                  <div>
                    <Text card_title="true">{job?.title}</Text>
                    <HeightSpacer height={'5px'} />
                    <JobCardDetails>
                      <Text card_desc="true">{job?.trackTitle} |</Text>
                      <Spacer width={'5px'} />
                      <Text card_desc="true">{job?.jobTypeTitle} |</Text>
                      <Spacer width={'5px'} />
                      <Text card_desc="true">
                        Expiry: {moment(job?.expiredAt).format('DD MMM YYYY')}
                      </Text>
                    </JobCardDetails>
                  </div>

                  <ApplicantCountContainer>
                    <Text applicant_count="true"> {job?.totalCount} </Text>
                    <HeightSpacer height={'5px'} />
                    <Text applicant_text="true">Applicants</Text>
                  </ApplicantCountContainer>
                </CardContentContainer>
              </CardStyled>
              <HeightSpacer height={'10px'} />
            </Fragment>
          );
        })}
      </InactiveJobsContainer>

      <HeightSpacer height={'40px'} />

      <ConfirmButtonContainer>
        <SharedButton
          type="basic"
          min_width={'240px'}
          font_size={'16px'}
          onClick={navigateToEditJob}
          disabled={disableButton('inactive-jobs')}>
          Confirm
        </SharedButton>
      </ConfirmButtonContainer>
    </RenewInactiveJobsContainer>
  );
};

export default InactiveJobsPopup;
