import { styled as styling } from '@mui/material/styles';
import { Grid, Typography, Button } from '@mui/material';
import {
  ExclamationCircleIcon,
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon as OutlinedCheck,
  CheckBadgeIcon
} from '@heroicons/react/24/outline';
import { CheckCircleIcon as SolidCheck } from '@heroicons/react/24/solid';

export const SidebarTitle = styling(Typography)(props => ({
  color: '#000000DE',
  fontFamily: 'Inter',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px'
}));

export const SiderbarContainer = styling(Grid)(props => ({
  width: '280px',
  position: 'sticky',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  top: '100px'
}));

export const BannerContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  padding: props?.completeRequired ? '8px' : '8px 8px 12px 8px',
  backgroundColor: props?.completeRequired
    ? `#E6F5C8`
    : `rgba(247, 84, 67, 0.11)`,
  borderRadius: '10px',
  border: `1px solid ${props?.completeRequired ? '#90B938' : '#F75443'}`
}));

export const BannerText = styling(Typography)(props => ({
  color: props?.completeRequired ? '#668A19' : '#BE4242',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px'
}));

export const ChecklistText = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.87)`,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  textDecorationLine: props?.complete ? 'line-through' : 'none'
}));

export const BannerListText = styling('li')(props => ({
  color: '#BE4242',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px',
  textDecorationLine: 'underline'
}));

export const BannerList = styling('ul')(props => ({
  margin: '0px',
  paddingLeft: '15px',
  cursor: "pointer"
}));

export const ExclamationCircleIconStyled = styling(ExclamationCircleIcon)(
  props => ({
    height: '24px',
    width: '24px',
    color: '#BE4242'
  })
);

export const CheckBadgeIconStyled = styling(CheckBadgeIcon)(props => ({
  height: '24px',
  width: '24px',
  color: '#668A19'
}));

export const ChecklistContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}));

export const CheckList = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  alignItems: 'center',
  textDecorationLine: 'strikethrough',
  cursor: "pointer"
}));

export const SolidCheckStyle = styling(SolidCheck)(props => ({
  height: '24px',
  width: '24px',
  color: '#222222'
}));

export const OutlineCheckStyle = styling(OutlinedCheck)(props => ({
  height: '24px',
  width: '24px',
  color: '#222222'
}));

export const ViewCompanyProfile = styling(Button)(props => ({
  height: '52px',
  borderRadius: '8px',
  backgroundColor: props?.disabled ? '#D8D8D8' : '#222',
  textTransform: 'none',
  color: '#FFFFFFDE',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  fontSize: "16px",
  fontWeight: 400,
  letterSpacing: "0.15px",
  padding: "6px",

  ":disabled": {
    color: `rgba(0, 0, 0, 0.38)`
  },

  ':hover': {
    backgroundColor: props?.disabled ? '#D8D8D8' : '#222222'
  }
}));

export const ArrowTopRightIconStyled = styling(ArrowTopRightOnSquareIcon)(
  props => ({
    height: '20px',
    width: '20px'
  })
);

export const DisclaimerText = styling(Typography)(props => ({
  color: `#00000099`,
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
  textAlign: 'center'
}));
