import { datadogRum } from '@datadog/browser-rum';

export const initDatadog = () => {
  if (process.env.REACT_APP_DATADOG_ENV != 'LOCAL') {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'hiredly-ed',
      env: process.env.REACT_APP_DATADOG_ENV,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 1,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [
        // NOTE: Don't use [/https:\/\/.*\.wobbjobs\.com/], it will auto inject Datadog Headers to Wordpress API call
        // as it also ends with "wobbjobs.com" also, which can cause CORS error
        // https:\/\/.*\.hiredly\.com/,
        process.env.REACT_APP_DATADOG_TRACK_URL,
        process.env.REACT_APP_DATADOG_TRACK_SG_URL,
        url =>
          url.startsWith(
            process.env.REACT_APP_DATADOG_TRACK_URL ||
              process.env.REACT_APP_DATADOG_TRACK_SG_URL
          ),
        process.env.REACT_APP_API_URL,
        process.env.REACT_APP_SG_API_URL
      ]
    });
  }
};

export const setUserToDatadog = (accountId, email, companyName) => {
  if (process.env.REACT_APP_DATADOG_ENV != 'LOCAL') {
    datadogRum.setUser({
      id: accountId,
      email: email,
      name: companyName
    });
  }
};
