import * as types from '../types/job_type';

const initialState = {
  isLoggingOut: false,
  preventReloadOrLeave: false,
  isExpiryDatesPopupOpen: false,
  availableResourcesCardIndex: -1,
  fetchingPackageSubscriptionDetails: false,
  subscribedPackages: [],
  subscribedJobSlotsAndPrepJobSlotsPackages: [],
  subscribedPrepJobSlotsPackage: {},
  subscribedJobSlotsPackage: {},
  subscribedCrossBorderTokensPackage: {},
  subscribedFeaturedTokensPackage: {},
  subscribedRefresherTokensPackage: {},
  subscribedFreeInternshipTokensPackage: {},
  subscribedFreeSuperSeniorTokensPackage: {},
  subscribedJobSlotPlusPackage: {},
  subscribedJobSlotMaxPackage: {},
  subscribedJobSlot6mPackage: {},
  subscribedJobSlotPlus6mPackage: {},
  subscribedJobSlotMax6mPackage: {},
  subscribedJobTokensPackage: {},
  subscribedJobCreditsPackage: {},
  subscribedSpotlightTokensPackage: {},
  subscribedKeywordHighlightTokensPackage: {},
  isAnnouncementPopupOpen: false,
  allSortedPackages: [],
  fetchingAllSortedPackages: false,
  fetchingSortedJobSlotsAndPrepJobSlotsPackages: false,
  sortedJobSlotsAndPrepJobSlotsPackages: [],
  isJobAccessOpen: false,
  isVirtualInterviewOpen: false,
  refreshingJob: false,
  job: {},
  isFeaturedJobOpen: false,
  featuringJob: false,
  isDeactivateJobOpen: false,
  isActivateJobOpen: false,
  isActivateJobOptionOpen: false,
  isJobOptionOpen: false,
  changingJobStatus: false,
  fetchingJobs: false,
  jobs: [],
  totalJobsCount: {},
  selectedFilter: true,
  selectedFilterValue: 'active',
  searchJobValue: '',
  jobsPageOffset: 0,
  jobsCurrentPage: 1,
  chipStatusIndex: 0,
  expiryDatesColumnIndex: -1,
  productTypeIndex: 'all types', // This is not a string as need to hide the item
  productActivationDateIndex: 0,
  productExpiryDateIndex: 0,
  productDaysRemainingIndex: 0,
  expiryDatesPopupContentIndex: -1,
  selectedProduct: '',
  // For contact us popup
  isContactSalesOpen: false,
  subjectType: '',
  //For all inactive jobs
  fetchingAllInactiveJobs: false,
  allInactiveJobs: [],
  // For featureable jobs
  fetchingFeaturableJobs: false,
  featurableJobs: [],
  // For refreshable jobs
  fethingRefreshableJobs: false,
  refreshableJobs: [],
  // For spotlightable jobs
  fetchingSpotlightableJobs: false,
  spotlightableJobs: [],
  // For bulk spotlight jobs
  bulkJobsSpotlighting: false,
  bulkSpotlightJobs: [],
  // For bulk feature jobs
  bulkJobsFeaturing: false,
  bulkFeatureJobs: [],
  // For bulk refresh job
  bulkJobsRefreshing: false,
  //For post job
  isPostJobPopup: false,
  // For job title suggestions dropdown
  fetchingJobTitleSuggestions: false,
  isJobTitleDropdownSuggestionsOpen: false,
  // For job skills in job posting
  selectedJobSkills: [],
  fetchingJobSkillsSuggestions: false,
  jobTitleSuggestions: [],
  jobSkillsSuggestions: [],
  jobSkillSearch: '',
  isJobSkillDropdownOpen: false,

  // For adding keywords in job
  selectedJobKeywords: [],
  tempJobDraft: [],

  //For post job tooltip
  tooltipStatus: {
    jobTitle: false,
    shortSummary: false,
    jobDescription: false,
    jobRequirement: false,
    skill: false
  },
  prevTooltip: '',
  isSpecialisationOpen: false,
  subcategories: [],

  //Visibility Booster
  isKeywordHighlightOpen: false,
  isSpotlightJobConfirmationOpen: false,
  isCrossBorderConfirmationOpen: false,
  isKeywordHighlightConfirmationOpen: false,
  spotlightingJob: false,
  keywordingJob: false,
  keywordHighlightTexts: [],

  //specialisation
  specialisation: [],

  //For ashley rewrite popup
  isAshleyRewritePopupOpen: false,
  fetchingAshelyRewrite: false,
  ashleyRewriteMessage: {
    shortSummary: '',
    jobDescription: '',
    jobRequirements: ''
  },
  ashleyRewritePopupOrigin: '',

  //Job data for ashley rewrite
  jobDataForAshleyRewrite: {
    shortSummary: '',
    jobDescription: '',
    jobRequirements: ''
  },

  //To hold new editor state
  tmpEditorState: {
    jobDescription: {},
    jobRequirements: {}
  },

  //To record old editor state
  oldEditorState: {
    jobDescription: {},
    jobRequirements: {}
  },

  //Fetch job
  fetchingJob: false,
  jobData: {},

  //Fetch all jobs
  fetchingAllJobs: false,
  allJobs: [],

  //ashley edit feedback
  ashleyEditFeedback: [],
  sendingAEFeedback: false,

  // FETCH DEACTIVATE REASONS
  deactivateReasons: [],
  fetchingDeactivateReasons: false,

  // FETCH PERSONALIZED QUESTIONS
  personalizedQuestions: [],
  fetchingPersonalizedQuestions: false,

  // FETCH JOB PERSONALIZED QUESTIONS
  jobPersonalizedQuestions: [],
  fetchingJobPersonalizedQuestions: false,

  // FETCH DRAFT JOB
  draftJob: [],
  fetchingDraftJob: false,

  // FETCH JOB TEAM MEMBERS
  jobTeamMembers: [],
  fetchingJobTeamMembers: false,

  // FETCH TEAM MEMBERS
  companyTeamMembers: [],
  fetchingCompanyTeamMembers: false,

  // MUTATE JOB PERSONALIZED QUESTIONS
  mutateQuestionSuccess: false,
  mutatingJobPersonalizedQuestions: false,

  mutateGlobalHireSuccess: false,
  mutatingGlobalHireOptions: false,

  mutateCrossBorderSuccess: false,
  mutatingCrossBorderOptions: false,

  // MUTATE JOB ACCESS
  mutateAccessSuccess: false,
  mutatingJobAccess: false,

  // MUTATE JOB APPLCIATION STATE
  jobApplication: {},
  mutatingJobApplication: false,

  // MUTATE VIEWED JOB APPLICATION
  mutatingViewedJobApplication: false,
  viewedJobApplication: {},

  // MUTATE UPDATE JOB
  updateJob: {},
  mutatingUpdateJob: false,

  // MUTATE CREATE JOB
  createJob: {},
  mutatingCreateJob: false,

  // MUTATE CREATE DRAFT JOB
  createDraftJob: {},
  mutatingCreateDraftJob: false,

  // UPDATE DRAFT JOB STATE
  clearDraftJob: true,

  // UPDATE JOB FORM DATA STATE
  jobDataForm: {},

  sendingPostJobFeedback: false,
  sendingTalentSearchFeedback: false
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // For job list
    case types.UPDATE_SELECTED_JOB: {
      return {
        ...state
      };
    }
    // For contact sales popup
    case types.UPDATE_CONTACT_SALES_POPUP: {
      return {
        ...state,
        isContactSalesOpen: action.status
      };
    }
    case types.UPDATE_SUBJECT_TYPE: {
      return {
        ...state,
        subjectType: action.subjectType
      };
    }
    // End for contact sales popup
    case types.UPDATE_EXPIRY_DATES_POPUP: {
      return {
        ...state,
        isExpiryDatesPopupOpen: action.status
      };
    }
    case types.UPDATE_AVAILABLE_RESOURCES_CARD_INDEX: {
      return {
        ...state,
        availableResourcesCardIndex: action.index
      };
    }
    case types.FETCH_PACKAGE_SUBSCRIPTION_DETAILS: {
      return {
        ...state,
        fetchingPackageSubscriptionDetails: true
      };
    }
    case types.FETCH_PACKAGE_SUBSCRIPTION_DETAILS_SUCCEED: {
      //to find the index of each package in the packages array
      const prepJobSlotsPackageIndex = action.packages.findIndex(
        currentPackage => {
          return currentPackage?.package == 'Prep Job Slots';
        }
      );

      const jobSlotsPackageIndex = action.packages.findIndex(currentPackage => {
        return currentPackage?.package == 'Job Slots';
      });

      const jobSlotPlusPackageIndex = action.packages.findIndex(
        currentPackage => {
          return currentPackage?.package == 'Job Slots +';
        }
      );

      const jobSlotMaxPackageIndex = action.packages.findIndex(
        currentPackage => {
          return currentPackage?.package == 'Job Slots Max';
        }
      );

      const jobSlot6mPackageIndex = action.packages.findIndex(
        currentPackage => {
          return currentPackage?.package == 'Job Slots 6 Months';
        }
      );

      const jobSlotPlus6mPackageIndex = action.packages.findIndex(
        currentPackage => {
          return currentPackage?.package == 'Job Slots + 6 Months';
        }
      );

      const jobSlotMax6mPackageIndex = action.packages.findIndex(
        currentPackage => {
          return currentPackage?.package == 'Job Slots Max 6 Months';
        }
      );

      const featuredTokensPackageIndex = action.packages.findIndex(
        currentPackage => {
          return currentPackage?.package == 'Featured Tokens';
        }
      );

      const crossBorderTokensPackageIndex = action.packages.findIndex(
        currentPackage => {
          return currentPackage?.package == 'Cross Border Tokens';
        }
      );

      const refresherTokensPackageIndex = action.packages.findIndex(
        currentPackage => {
          return currentPackage?.package == 'Refresher Tokens';
        }
      );

      const freeInternshipTokensPackageIndex = action.packages.findIndex(
        currentPackage => {
          return currentPackage?.package == 'Free Internship Tokens';
        }
      );

      const freeSuperSeniorTokensPackageIndex = action.packages.findIndex(
        currentPackage => {
          return currentPackage?.package == 'Free Super Senior Jobs';
        }
      );

      const jobTokensPackageIndex = action.packages.findIndex(
        currentPackage => {
          return currentPackage?.package == 'Job Tokens';
        }
      );

      const jobCreditsPackageIndex = action.packages.findIndex(
        currentPackage => {
          return currentPackage?.package == 'Job Credits';
        }
      );

      const spotlightTokensPackageIndex = action.packages.findIndex(
        currentPackage => {
          return currentPackage?.package == 'Spotlight Jobs';
          // return currentPackage?.package == 'Spotlight Tokens';
        }
      );

      const keywordHighlightTokensPackageIndex = action.packages.findIndex(
        currentPackage => {
          return currentPackage?.package == 'Key Highlights Tokens';
        }
      );

      return {
        ...state,
        fetchingPackageSubscriptionDetails: false,
        subscribedPackages: action.packages,
        subscribedPrepJobSlotsPackage:
          action.packages[prepJobSlotsPackageIndex],
        subscribedJobSlotsPackage: action.packages[jobSlotsPackageIndex],
        subscribedJobSlotPlusPackage: action.packages[jobSlotPlusPackageIndex],
        subscribedJobSlotMaxPackage: action.packages[jobSlotMaxPackageIndex],
        subscribedJobSlot6mPackage: action.packages[jobSlot6mPackageIndex],
        subscribedJobSlotPlus6mPackage:
          action.packages[jobSlotPlus6mPackageIndex],
        subscribedJobSlotMax6mPackage:
          action.packages[jobSlotMax6mPackageIndex],
        subscribedJobSlotsAndPrepJobSlotsPackages: [
          action.packages[jobSlotsPackageIndex]
        ].concat(action.packages[prepJobSlotsPackageIndex]),
        subscribedFeaturedTokensPackage:
          action.packages[featuredTokensPackageIndex],
        subscribedCrossBorderTokensPackage:
          action.packages[crossBorderTokensPackageIndex],
        subscribedRefresherTokensPackage:
          action.packages[refresherTokensPackageIndex],
        subscribedFreeInternshipTokensPackage:
          action.packages[freeInternshipTokensPackageIndex],
        subscribedFreeSuperSeniorTokensPackage:
          action.packages[freeSuperSeniorTokensPackageIndex],
        subscribedJobTokensPackage: action.packages[jobTokensPackageIndex],
        subscribedJobCreditsPackage: action.packages[jobCreditsPackageIndex],
        subscribedSpotlightTokensPackage:
          action.packages[spotlightTokensPackageIndex],
        subscribedKeywordHighlightTokensPackage:
          action.packages[keywordHighlightTokensPackageIndex]
      };
    }
    case types.FETCH_PACKAGE_SUBSCRIPTION_DETAILS_FAILED: {
      return {
        ...state,
        fetchingPackageSubscriptionDetails: false
      };
    }
    case types.FETCH_ALL_SORTED_PACKAGES: {
      return {
        ...state,
        fetchingAllSortedPackages: true
      };
    }
    case types.FETCH_ALL_SORTED_PACKAGES_SUCCEED: {
      return {
        ...state,
        fetchingAllSortedPackages: false,
        allSortedPackages: action.packages
      };
    }
    case types.FETCH_ALL_SORTED_PACKAGES_FAILED: {
      return {
        ...state,
        fetchingAllSortedPackages: false
      };
    }
    case types.FETCH_SORTED_JOB_SLOTS_AND_PREP_JOB_SLOTS_PACKAGES: {
      return {
        ...state,
        fetchingSortedJobSlotsAndPrepJobSlotsPackages: true
      };
    }
    case types.FETCH_SORTED_JOB_SLOTS_AND_PREP_JOB_SLOTS_PACKAGES_SUCCEED: {
      return {
        ...state,
        fetchingSortedJobSlotsAndPrepJobSlotsPackages: false,
        sortedJobSlotsAndPrepJobSlotsPackages: action.packages
      };
    }
    case types.FETCH_SORTED_JOB_SLOTS_AND_PREP_JOB_SLOTS_PACKAGES_FAILED: {
      return {
        ...state,
        fetchingSortedJobSlotsAndPrepJobSlotsPackages: false
      };
    }
    case types.UPDATE_ANNOUNCEMENT_POPUP: {
      return {
        ...state,
        isAnnouncementPopupOpen: action.status
      };
    }
    case types.UPDATE_ANNOUNCEMENT_BANNER: {
      return {
        ...state,
        isAnnouncementBannerOpen: action.status
      };
    }
    case types.UPDATE_JOB_ACCESS_POPUP: {
      return {
        ...state,
        isJobAccessOpen: action.status
      };
    }
    case types.UPDATE_VIRTUAL_INTERVIEW_POPUP: {
      return {
        ...state,
        isVirtualInterviewOpen: action.status
      };
    }
    case types.UPDATE_REFRESHER_JOB_POPUP: {
      return {
        ...state,
        isRefreshJobOpen: action.status
      };
    }
    case types.REFRESH_JOB: {
      return {
        ...state,
        refreshingJob: true
      };
    }
    case types.REFRESH_JOB_SUCCEED: {
      return {
        ...state,
        refreshingJob: false,
        job: action.job
      };
    }
    case types.REFRESH_JOB_FAILED: {
      return {
        ...state,
        refreshingJob: false
      };
    }
    case types.UPDATE_FEATURED_JOB_POPUP: {
      return {
        ...state,
        isFeaturedJobOpen: action.status
      };
    }
    case types.FEATURE_JOB: {
      return {
        ...state,
        featuringJob: true
      };
    }
    case types.FEATURE_JOB_SUCCEED: {
      return {
        ...state,
        featuringJob: false,
        job: action.job
      };
    }
    case types.FEATURE_JOB_FAILED: {
      return {
        ...state,
        featuringJob: false
      };
    }
    case types.UPDATE_DEACTIVATE_JOB_POPUP: {
      return {
        ...state,
        isDeactivateJobOpen: action.status
      };
    }
    case types.UPDATE_ACTIVATE_JOB_POPUP: {
      return {
        ...state,
        isActivateJobOpen: action.status
      };
    }

    case types.UPDATE_ACTIVATE_JOB_OPTION_POPUP: {
      return {
        ...state,
        isActivateJobOptionOpen: action.status
      };
    }

    case types.UPDATE_JOB_OPTION_POPUP: {
      return {
        ...state,
        isJobOptionOpen: action.status
      };
    }

    case types.CHANGE_JOB_STATUS: {
      return {
        ...state,
        changingJobStatus: true
      };
    }
    case types.CHANGE_JOB_STATUS_SUCCEED: {
      return {
        ...state,
        changingJobStatus: false,
        job: action.job
      };
    }
    case types.CHANGE_JOB_STATUS_FAILED: {
      return {
        ...state,
        changingJobStatus: false
      };
    }
    case types.FETCH_JOBS: {
      return {
        ...state,
        fetchingJobs: true
      };
    }
    case types.FETCH_JOBS_SUCCEED: {
      const currentJobs = action.jobs.edges.map(item => item?.node);

      let currentTotalJobsCount = { ...state.totalJobsCount };

      if (action.allCount != null) {
        currentTotalJobsCount['all'] = action.allCount;
      }

      if (action.activeCount != null) {
        currentTotalJobsCount['active'] = action.activeCount;
      }

      if (action.inactiveCount != null) {
        currentTotalJobsCount['inactive'] = action.inactiveCount;
      }

      return {
        ...state,
        fetchingJobs: false,
        jobs: [...currentJobs],
        totalJobsCount: currentTotalJobsCount
      };
    }
    case types.FETCH_JOBS_FAILED: {
      return {
        ...state,
        fetchingJobs: false
      };
    }
    case types.UPDATE_ALL_JOBS: {
      return {
        ...state,
        jobs: [...action.allJobs]
      };
    }
    case types.UPDATE_SELECTED_FILTER: {
      return {
        ...state,
        selectedFilter: action.selectedFilter
      };
    }
    case types.UPDATE_SELECTED_FILTER_VALUE: {
      return {
        ...state,
        selectedFilterValue: action.selectedFilterValue
      };
    }
    case types.UPDATE_SEARCH_JOB_VALUE: {
      return {
        ...state,
        searchJobValue: action.searchValue
      };
    }
    case types.UPDATE_JOBS_OFFSET: {
      return {
        ...state,
        jobsPageOffset: action.jobsPageOffset
      };
    }
    case types.UPDATE_JOBS_CURRENT_PAGE: {
      return {
        ...state,
        jobsCurrentPage: action.jobsCurrentPage
      };
    }
    case types.UPDATE_CHIP_STATUS_INDEX: {
      return {
        ...state,
        chipStatusIndex: action.chipStatusIndex
      };
    }
    case types.UPDATE_TOTAL_JOBS_COUNT: {
      return {
        ...state,
        totalJobsCount: action.totalJobsCount
      };
    }
    case types.UPDATE_EXPIRY_DATES_COLUMN_INDEX: {
      return {
        ...state,
        expiryDatesColumnIndex: action.expiryDatesColumnIndex
      };
    }
    case types.UPDATE_PRODUCT_TYPE_INDEX: {
      return {
        ...state,
        productTypeIndex: action.productTypeIndex
      };
    }
    case types.UPDATE_PRODUCT_ACTIVATION_DATE_INDEX: {
      return {
        ...state,
        productActivationDateIndex: action.productActivationDateIndex
      };
    }
    case types.UPDATE_PRODUCT_EXPIRY_DATE_INDEX: {
      return {
        ...state,
        productExpiryDateIndex: action.productExpiryDateIndex
      };
    }
    case types.UPDATE_PRODUCT_DAYS_REMAINING_INDEX: {
      return {
        ...state,
        productDaysRemainingIndex: action.productDaysRemainingIndex
      };
    }
    case types.UPDATE_EXPIRY_DATES_POPUP_CONTENT_INDEX: {
      return {
        ...state,
        expiryDatesPopupContentIndex: action.index
      };
    }
    case types.UPDATE_SELECTED_PRODUCT_NAME: {
      return {
        ...state,
        selectedProduct: action.productName
      };
    }
    case types.FETCH_ALL_INACTIVE_JOBS: {
      return {
        ...state,
        fetchingAllInactiveJobs: true
      };
    }
    case types.FETCH_ALL_INACTIVE_JOBS_SUCCEED: {
      return {
        ...state,
        fetchingAllInactiveJobs: false,
        allInactiveJobs: action.inactiveJobs
      };
    }
    case types.FETCH_ALL_INACTIVE_JOBS_FAILED: {
      return {
        ...state,
        fetchingAllInactiveJobs: false
      };
    }
    // For featureable jobs
    case types.FETCH_FEATURABLE_JOBS: {
      return {
        ...state,
        fetchingFeaturableJobs: true
      };
    }
    case types.FETCH_FEATURABLE_JOBS_SUCCEED: {
      return {
        ...state,
        fetchingFeaturableJobs: false,
        featurableJobs: action.featurableJobs
      };
    }
    case types.FETCH_FEATURABLE_JOBS_FAILED: {
      return {
        ...state,
        fetchingFeaturableJobs: false
      };
    }
    // For refreshable jobs
    case types.FETCH_REFRESHABLE_JOBS: {
      return {
        ...state,
        fethingRefreshableJobs: true
      };
    }
    case types.FETCH_REFRESHABLE_JOBS_SUCCEED: {
      return {
        ...state,
        fethingRefreshableJobs: false,
        refreshableJobs: action.refreshableJobs
      };
    }
    case types.FETCH_REFRESHABLE_JOBS_FAILED: {
      return {
        ...state,
        fethingRefreshableJobs: false
      };
    }

    // For spotlightable jobs
    case types.FETCH_SPOTLIGHTABLE_JOBS: {
      return {
        ...state,
        fetchingSpotlightableJobs: true
      };
    }
    case types.FETCH_SPOTLIGHTABLE_JOBS_SUCCEED: {
      return {
        ...state,
        fetchingSpotlightableJobs: false,
        spotlightableJobs: action.spotlightableJobs
      };
    }
    case types.FETCH_SPOTLIGHTABLE_JOBS_FAILED: {
      return {
        ...state,
        fetchingSpotlightableJobs: false
      };
    }

    //For bulk spotlight jobs
    case types.BULK_JOBS_SPOTLIGHTING: {
      return {
        ...state,
        bulkJobsSpotlighting: true
      };
    }
    case types.BULK_SPOTLIGHT_JOBS_SUCCEED: {
      return {
        ...state,
        bulkJobsSpotlighting: false,
        bulkSpotlightJobs: action.bulkSpotlightJobs
      };
    }
    case types.BULK_SPOTLIGHT_JOBS_FAILED: {
      return {
        ...state,
        bulkJobsSpotlighting: false
      };
    }

    //For bulk feature jobs
    case types.BULK_JOBS_FEATURING: {
      return {
        ...state,
        bulkJobsFeaturing: true
      };
    }
    case types.BULK_FEATURE_JOBS_SUCCEED: {
      return {
        ...state,
        bulkJobsFeaturing: false,
        bulkFeatureJobs: action.bulkFeatureJobs
      };
    }
    case types.BULK_FEATURE_JOBS_FAILED: {
      return {
        ...state,
        bulkJobsFeaturing: false
      };
    }
    // For bulk refresh jobs
    case types.BULK_JOBS_REFRESHING: {
      return {
        ...state,
        bulkJobsRefreshing: true
      };
    }
    case types.BULK_REFRESH_JOBS_SUCCEED: {
      return {
        ...state,
        bulkJobsRefreshing: false
      };
    }
    case types.BULK_REFRESH_JOBS_FAILED: {
      return {
        ...state,
        bulkJobsRefreshing: false
      };
    }
    case types.UPDATE_FEATURE_TOKENS_COUNT: {
      let cFeatureTokensPackage = { ...state.subscribedFeaturedTokensPackage };

      cFeatureTokensPackage.total_usable_quantity = action.count;

      return {
        ...state,
        subscribedFeaturedTokensPackage: cFeatureTokensPackage
      };
    }

    case types.UPDATE_CROSS_BORDER_TOKENS_COUNT: {
      let cCrossBorderTokensPackage = {
        ...state.subscribedCrossBorderTokensPackage
      };

      cCrossBorderTokensPackage.total_usable_quantity = action.count;

      return {
        ...state,
        subscribedCrossBorderTokensPackage: cCrossBorderTokensPackage
      };
    }

    case types.UPDATE_REFRESHER_TOKENS_COUNT: {
      let cRefresherTokensPackage = {
        ...state.subscribedRefresherTokensPackage
      };

      cRefresherTokensPackage.total_usable_quantity = action.count;

      return {
        ...state,
        subscribedRefresherTokensPackage: cRefresherTokensPackage
      };
    }
    case types.UPDATE_SPOTLIGHT_TOKENS_COUNT: {
      let cSpotlightTokensPackage = {
        ...state.subscribedSpotlightTokensPackage
      };

      cSpotlightTokensPackage.total_usable_quantity = action.count;

      return {
        ...state,
        subscribedSpotlightTokensPackage: cSpotlightTokensPackage
      };
    }
    case types.UPDATE_POST_JOB_POPUP: {
      return {
        ...state,
        isPostJobPopup: action.status
      };
    }
    case types.FETCH_JOB_TITLE_SUGGESTIONS: {
      return {
        ...state,
        fetchingJobTitleSuggestions: true
      };
    }
    case types.FETCH_JOB_TITLE_SUGGESTIONS_SUCCEED: {
      const limitSuggestionsToTen = action.jobTitleSuggestions.splice(0, 10);

      return {
        ...state,
        fetchingJobTitleSuggestions: false,
        jobTitleSuggestions: limitSuggestionsToTen
      };
    }
    case types.FETCH_JOB_TITLE_SUGGESTIONS_FAILED: {
      return {
        ...state,
        fetchingJobTitleSuggestions: false
      };
    }
    case types.UPDATE_JOB_TITLE_SUGGESTIONS_DROPDOWN_STATUS: {
      return {
        ...state,
        isJobTitleDropdownSuggestionsOpen:
          action.isJobTitleDropdownSuggestionsOpen
      };
    }
    case types.UPDATE_JOB_SKILLS: {
      return {
        ...state,
        selectedJobSkills: action.selectedJobSkills
      };
    }

    case types.UPDATE_JOB_KEYWORDS: {
      return {
        ...state,
        selectedJobKeywords: action.selectedJobKeywords
      };
    }

    case types.UPDATE_TEMP_JOB: {
      return {
        ...state,
        tempJobDraft: action.tempJobDraft
      };
    }

    case types.FETCH_JOB_SKILLS_SUGGESTIONS: {
      return {
        ...state,
        fetchingJobSkillsSuggestions: true
      };
    }
    case types.FETCH_JOB_SKILLS_SUGGESTIONS_SUCCEED: {
      const limitSuggestionsToTen = action.jobSkillsSuggestions.splice(0, 10);
      return {
        ...state,
        fetchingJobSkillsSuggestions: false,
        jobSkillsSuggestions: limitSuggestionsToTen
      };
    }
    case types.UPDATE_JOB_TITLE_SUGGESTIONS: {
      return {
        ...state,
        jobTitleSuggestions: action.jobTitleSuggestions
      };
    }
    case types.FETCH_JOB_SKILLS_SUGGESTIONS_FAILED: {
      return {
        ...state,
        fetchingJobSkillSuggestions: false
      };
    }
    case types.UPDATE_JOB_SKILL_SEARCH: {
      return {
        ...state,
        jobSkillSearch: action.jobSkillSearch
      };
    }
    case types.UPDATE_JOB_SKILL_DROPDOWN_STATUS: {
      return {
        ...state,
        isJobSkillDropdownOpen: action.status
      };
    }
    case types.UPDATE_POST_JOB_TOOLTIPS_STATUS: {
      return {
        ...state,
        tooltipStatus: { ...action.status }
      };
    }
    case types.UPDATE_POST_JOB_PREV_TOOLTIP: {
      return {
        ...state,
        prevTooltip: action.prevTooltip
      };
    }
    case types.UPDATE_KEYWORD_HIGHLIGHT_POPUP_STATUS: {
      return {
        ...state,
        isKeywordHighlightOpen: action.status
      };
    }
    case types.UPDATE_SPOTLIGHT_JOB_CONFIRMATION_POPUP: {
      return {
        ...state,
        isSpotlightJobConfirmationOpen: action.status
      };
    }

    case types.UPDATE_CROSS_BORDER_CONFIRMATION_POPUP: {
      return {
        ...state,
        isCrossBorderConfirmationOpen: action.status
      };
    }

    case types.UPDATE_KEYWORD_HIGHLIGHT_CONFIRMATION_POPUP: {
      return {
        ...state,
        isKeywordHighlightConfirmationOpen: action.status
      };
    }
    case types.SPOTLIGHT_JOB: {
      return {
        ...state,
        spotlightingJob: true
      };
    }
    case types.FEATURE_JOB_SUCCEED: {
      return {
        ...state,
        spotlightingJob: false,
        job: action.job
      };
    }
    case types.FEATURE_JOB_FAILED: {
      return {
        ...state,
        spotlightingJob: false
      };
    }
    case types.KEYWORD_HIGHLIGHT_JOB: {
      return {
        ...state,
        keywordingJob: true
      };
    }
    case types.KEYWORD_HIGHLIGHT_JOB_SUCCEED: {
      return {
        ...state,
        keywordingJob: false,
        job: action.job
      };
    }
    case types.KEYWORD_HIGHLIGHT_JOB_FAILED: {
      return {
        ...state,
        keywordingJob: false
      };
    }
    case types.UPDATE_KEYWORD_HIGHLIGHT_TEXTS: {
      return {
        ...state,
        keywordHighlightTexts: action.keywords
      };
    }
    case types.UPDATE_JOB: {
      return {
        ...state,
        job: action.job
      };
    }
    case types.UPDATE_SPECIALISATION_POPOVER_STATUS: {
      return {
        ...state,
        isSpecialisationOpen: action.status
      };
    }
    case types.UPDATE_SPECIALISATION_SUB_CATEGORIES: {
      return {
        ...state,
        subcategories: action.subcategories
      };
    }
    case types.FETCH_SPECIALISAION: {
      return {
        ...state,
        fetchingSpecialisation: true
      };
    }
    case types.FETCH_SPECIALISAION_SUCCEED: {
      return {
        ...state,
        fetchingSpecialisation: false,
        specialisation: action.specialisation
      };
    }
    case types.FETCH_SPECIALISAION_FAILED: {
      return {
        ...state,
        fetchingSpecialisation: false
      };
    }
    case types.UPDATE_ASHLEY_REWRITE_POPUP: {
      return {
        ...state,
        isAshleyRewritePopupOpen: action.status
      };
    }
    case types.FETCH_ASHLEY_TEXT_REWRITE: {
      return {
        ...state,
        fetchingAshelyRewrite: true
      };
    }
    case types.FETCH_ASHLEY_TEXT_REWRITE_SUCCEED: {
      let cAshleyMessage = { ...state.ashleyRewriteMessage };

      if (action.textType == 'short_description') {
        cAshleyMessage['shortSummary'] = action.texts;
      } else if (action.textType == 'job_description') {
        cAshleyMessage['jobDescription'] = action.texts;
      } else {
        cAshleyMessage['jobRequirements'] = action.texts;
      }

      return {
        ...state,
        fetchingAshelyRewrite: false,
        ashleyRewriteMessage: cAshleyMessage
      };
    }
    case types.FETCH_ASHLEY_TEXT_REWRITE_FAILED: {
      return {
        ...state,
        fetchingAshelyRewrite: false
      };
    }
    case types.UPDATE_ASHLEY_REWRITE_MESSAGE: {
      let cAshleyMessage = { ...state.ashleyRewriteMessage };

      if (Object.keys(action.content)?.length == 0) {
        cAshleyMessage = {
          shortSummary: '',
          jobDescription: '',
          jobRequirements: ''
        };
      } else if (action.content.origin == 'short_description') {
        cAshleyMessage['shortSummary'] = action.content.message;
      } else if (action.content.origin == 'job_description') {
        cAshleyMessage['jobDescription'] = action.content.message;
      } else if (action.content.origin == 'job_requirements') {
        cAshleyMessage['jobRequirements'] = action.content.message;
      }

      return {
        ...state,
        ashleyRewriteMessage: cAshleyMessage
      };
    }
    case types.UPDATE_ASHLEY_REWRITE_ORIGIN: {
      return {
        ...state,
        ashleyRewritePopupOrigin: action.origin
      };
    }
    case types.UPDATE_JOB_DATA_FOR_ASHLEY_REWRITE: {
      return {
        ...state,
        jobDataForAshleyRewrite: action.content
      };
    }
    case types.UPDATE_TMP_EDITOR_STATE: {
      let cTmpEditorState = { ...state.tmpEditorState };

      if (action.editorOrigin == 'job_description') {
        cTmpEditorState['jobDescription'] = action.state;
      } else if (action.editorOrigin == 'job_requirements') {
        cTmpEditorState['jobRequirements'] = action.state;
      }

      return {
        ...state,
        tmpEditorState: cTmpEditorState
      };
    }
    case types.UPDATE_OLD_EDITOR_STATE: {
      let cOldEditorState = { ...state.oldEditorState };

      if (action.editorOrigin == 'jobDescription') {
        cOldEditorState['jobDescription'] = action.state;
      } else if (action.editorOrigin == 'jobRequirements') {
        cOldEditorState['jobRequirements'] = action.state;
      }

      return {
        ...state,
        oldEditorState: cOldEditorState
      };
    }
    case types.UPDATE_JOB_DATA: {
      return {
        ...state,
        jobData: action.job
      };
    }
    // Fetching job
    case types.FETCH_JOB: {
      return {
        ...state,
        fetchingJob: true
      };
    }
    case types.FETCH_JOB_SUCCEED: {
      return {
        ...state,
        fetchingJob: false,
        jobData: action.job
      };
    }
    case types.FETCH_JOB_FAILED: {
      return {
        ...state,
        fetchingJob: false
      };
    }
    // Fetching all jobs
    case types.FETCH_ALL_JOBS: {
      return {
        ...state,
        fetchingAllJobs: true
      };
    }
    case types.FETCH_ALL_JOBS_SUCCEED: {
      const currentAllJobs = action.jobs.edges.map(item => item?.node);

      return {
        ...state,
        fetchingAllJobs: false,
        allJobs: [...currentAllJobs]
      };
    }
    case types.FETCH_ALL_JOBS_FAILED: {
      return {
        ...state,
        fetchingAllJobs: false
      };
    }
    case types.UPDATE_ASHLEY_EDIT_FEEDBACK: {
      return {
        ...state,
        ashleyEditFeedback: action.feedback
      };
    }
    //Ashley edit feedback
    case types.ASHLEY_EDIT_FEEDBACK: {
      return {
        ...state,
        sendingAEFeedback: true
      };
    }
    case types.ASHLEY_EDIT_FEEDBACK_SUCCEED: {
      return {
        ...state,
        sendingAEFeedback: false
      };
    }
    case types.ASHLEY_EDIT_FEEDBACK_FAILED: {
      return {
        ...state,
        sendingAEFeedback: false
      };
    }
    // FETCH DEACTIVATE REASONS
    case types.FETCH_DEACTIVATE_REASONS: {
      return {
        ...state,
        fetchingDeactivateReasons: true
      };
    }
    case types.FETCH_DEACTIVATE_REASONS_SUCCEED: {
      return {
        ...state,
        fetchingDeactivateReasons: false,
        deactivateReasons: action.deactivateReasons
      };
    }
    case types.FETCH_DEACTIVATE_REASONS_FAILED: {
      return {
        ...state,
        fetchingDeactivateReasons: false
      };
    }
    // FETCH PERSONALIZED QUESTIONS
    case types.FETCH_PERSONALIZED_QUESTIONS: {
      return {
        ...state,
        fetchingPersonalizedQuestions: true
      };
    }
    case types.FETCH_PERSONALIZED_QUESTIONS_SUCCEED: {
      return {
        ...state,
        fetchingPersonalizedQuestions: false,
        personalizedQuestions: action.personalizedQuestions
      };
    }
    case types.FETCH_PERSONALIZED_QUESTIONS_FAILED: {
      return {
        ...state,
        fetchingPersonalizedQuestions: false
      };
    }
    // FETCH JOB PERSONALIZED QUESTIONS
    case types.FETCH_JOB_PERSONALIZED_QUESTIONS: {
      return {
        ...state,
        fetchingJobPersonalizedQuestions: true
      };
    }
    case types.FETCH_JOB_PERSONALIZED_QUESTIONS_SUCCEED: {
      return {
        ...state,
        fetchingJobPersonalizedQuestions: false,
        jobPersonalizedQuestions: action.jobPersonalizedQuestions
      };
    }
    case types.FETCH_JOB_PERSONALIZED_QUESTIONS_FAILED: {
      return {
        ...state,
        fetchingJobPersonalizedQuestions: false
      };
    }
    // FETCH DRAFT JOB
    case types.FETCH_DRAFT_JOB: {
      return {
        ...state,
        fetchingDraftJob: true
      };
    }
    case types.FETCH_DRAFT_JOB_SUCCEED: {
      return {
        ...state,
        fetchingDraftJob: false,
        draftJob: action.draftJob
      };
    }
    case types.FETCH_DRAFT_JOB_FAILED: {
      return {
        ...state,
        fetchingDraftJob: false
      };
    }
    // FETCH JOB TEAM MEMBERS
    case types.FETCH_JOB_TEAM_MEMBERS: {
      return {
        ...state,
        fetchingJobTeamMembers: true
      };
    }
    case types.FETCH_JOB_TEAM_MEMBERS_SUCCEED: {
      return {
        ...state,
        fetchingJobTeamMembers: false,
        jobTeamMembers: action.jobTeamMembers
      };
    }
    case types.FETCH_JOB_TEAM_MEMBERS_FAILED: {
      return {
        ...state,
        fetchingJobTeamMembers: false
      };
    }
    // FETCH TEAM MEMBERS
    case types.FETCH_TEAM_MEMBERS: {
      return {
        ...state,
        fetchingCompanyTeamMembers: true
      };
    }
    case types.FETCH_TEAM_MEMBERS_SUCCEED: {
      return {
        ...state,
        fetchingCompanyTeamMembers: false,
        companyTeamMembers: action.companyTeamMembers
      };
    }
    case types.FETCH_TEAM_MEMBERS_FAILED: {
      return {
        ...state,
        fetchingCompanyTeamMembers: false
      };
    }

    // MUTATE VIRTUAL INTERVIEW QUESTIONS
    case types.MUTATE_VIRTUAL_INTERVIEW_QUESTIONS: {
      return {
        ...state,
        mutatingJobPersonalizedQuestions: true
      };
    }

    case types.MUTATE_VIRTUAL_INTERVIEW_QUESTIONS_SUCCEED: {
      return {
        ...state,
        mutateQuestionSuccess: action.success,
        mutatingJobPersonalizedQuestions: false
      };
    }

    case types.MUTATE_VIRTUAL_INTERVIEW_QUESTIONS_FAILED: {
      return {
        ...state,
        mutateQuestionSuccess: false,
        mutatingJobPersonalizedQuestions: false
      };
    }

    // MUTATE GLOBAL HIRE
    case types.MUTATE_GLOBAL_HIRE: {
      return {
        ...state,
        mutatingGlobalHireOptions: true
      };
    }

    case types.MUTATE_GLOBAL_HIRE_SUCCEED: {
      return {
        ...state,
        mutateGlobalHireSuccess: action.success,
        mutatingGlobalHireOptions: false
      };
    }

    case types.MUTATE_GLOBAL_HIRE_FAILED: {
      return {
        ...state,
        mutateGlobalHireSuccess: false,
        mutatingGlobalHireOptions: false
      };
    }

    // MUTATE CROSS BORDER
    case types.MUTATE_CROSS_BORDER: {
      return {
        ...state,
        mutatingCrossBorderOptions: true
      };
    }

    case types.MUTATE_CROSS_BORDER_SUCCEED: {
      return {
        ...state,
        mutateCrossBorderSuccess: action.success,
        mutatingCrossBorderOptions: false
      };
    }

    case types.MUTATE_CROSS_BORDER_FAILED: {
      return {
        ...state,
        mutateCrossBorderSuccess: false,
        mutatingCrossBorderOptions: false
      };
    }

    // MUTATE JOB ACCESS
    case types.MUTATE_JOB_ACCESS: {
      return {
        ...state,
        mutatingJobAccess: true
      };
    }

    case types.MUTATE_JOB_ACCESS_SUCCEED: {
      return {
        ...state,
        mutateAccessSuccess: action.success,
        mutatingJobAccess: false
      };
    }

    case types.MUTATE_JOB_ACCESS_FAILED: {
      return {
        ...state,
        mutateAccessSuccess: false,
        mutatingJobAccess: false
      };
    }

    // MUTATE JOB APPLICATION STATE
    case types.MUTATE_JOB_APPLICATION_STATE: {
      return {
        ...state,
        mutatingJobApplication: true
      };
    }

    case types.MUTATE_JOB_APPLICATION_STATE_SUCCEED: {
      return {
        ...state,
        jobApplication: action.jobApplication,
        mutatingJobApplication: false
      };
    }

    case types.MUTATE_JOB_APPLICATION_STATE_FAILED: {
      return {
        ...state,
        mutatingJobApplication: false
      };
    }

    // MUTATE VIEWED JOB APPLICATION
    case types.MUTATE_VIEWED_JOB_APPLICATION: {
      return {
        ...state,
        mutatingViewedJobApplication: true
      };
    }

    case types.MUTATE_VIEWED_JOB_APPLICATION_SUCCEED: {
      return {
        ...state,
        viewedJobApplication: action.jobApplication,
        mutatingViewedJobApplication: false
      };
    }

    case types.MUTATE_VIEWED_JOB_APPLICATION_FAILED: {
      return {
        ...state,
        mutatingViewedJobApplication: false
      };
    }

    // MUTATE UPDATE JOB
    case types.MUTATE_UPDATE_JOB: {
      return {
        ...state,
        mutatingUpdateJob: true
      };
    }

    case types.MUTATE_UPDATE_JOB_SUCCEED: {
      return {
        ...state,
        updateJob: action.updateJob,
        mutatingUpdateJob: false
      };
    }

    case types.MUTATE_UPDATE_JOB_FAILED: {
      return {
        ...state,
        mutatingUpdateJob: false
      };
    }

    // MUTATE CREATE JOB
    case types.MUTATE_CREATE_JOB: {
      return {
        ...state,
        mutatingCreateJob: true
      };
    }

    case types.MUTATE_CREATE_JOB_SUCCEED: {
      return {
        ...state,
        createJob: action.createJob,
        mutatingCreateJob: false
      };
    }

    case types.MUTATE_CREATE_JOB_FAILED: {
      return {
        ...state,
        mutatingCreateJob: false
      };
    }

    // MUTATE CREATE DRAFT JOB
    case types.MUTATE_CREATE_DRAFT_JOB: {
      return {
        ...state,
        mutatingCreateDraftJob: true
      };
    }

    case types.MUTATE_CREATE_DRAFT_JOB_SUCCEED: {
      return {
        ...state,
        createDraftJob: action.createDraftJob,
        mutatingCreateDraftJob: false
      };
    }

    case types.MUTATE_CREATE_DRAFT_JOB_FAILED: {
      return {
        ...state,
        mutatingCreateDraftJob: false
      };
    }

    case types.UPDATE_JOB_FORM_DATA_STATE: {
      return {
        ...state,
        jobDataForm: action.jobData
      };
    }

    case types.CLEAR_JOB_INFO: {
      return {};
    }

    //Post Job feedback
    case types.POST_JOB_FEEDBACK: {
      return {
        ...state,
        sendingPostJobFeedback: true
      };
    }
    case types.POST_JOB_FEEDBACK_SUCCEED: {
      return {
        ...state,
        sendingPostJobFeedback: false
      };
    }
    case types.POST_JOB_FEEDBACK_FAILED: {
      return {
        ...state,
        sendingPostJobFeedback: false
      };
    }

    //Talent Search feedback
    case types.TALENT_SEARCH_FEEDBACK: {
      return {
        ...state,
        sendingTalentSearchFeedback: true
      };
    }
    case types.TALENT_SEARCH_FEEDBACK_SUCCEED: {
      return {
        ...state,
        sendingTalentSearchFeedback: false
      };
    }
    case types.TALENT_SEARCH_FEEDBACK_FAILED: {
      return {
        ...state,
        sendingTalentSearchFeedback: false
      };
    }

    default: {
      return state;
    }
  }
};
