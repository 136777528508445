import React from 'react';
import { IconButton } from '@mui/material';
import { CloseIconStyled } from './styles';

const InfoBanner = ({ closeBanner }) => {
  return (
    <div
      style={{
        backgroundColor: '#512acc',
        color: '#fff',
        display: 'flex',
        width: '79%',
        maxWidth: '1010px',
        alignItems: 'center',
        padding: '10px 15px',
        borderRadius: '30px',
        margin: '10px 0 10px 20px',
        backgroundImage: 'linear-gradient(to left, #512acc, #6d32c5 ,#983eba)'
      }}>
      <h6
        style={{
          fontSize: '12px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          webkitLineClamp: '1',
          webkitBoxOrient: 'vertical',
          whiteSpace: 'break-spaces',
          padding: '0 5px'
        }}>
        Note that applicants will not be informed of their application status
        based on your decision, and their application status will only be shown
        as “Viewed” on their end.
      </h6>
      <IconButton
        style={{
          position: 'relative'
        }}
        size="small">
        <CloseIconStyled onClick={closeBanner} />
      </IconButton>
    </div>
  );
};

export default InfoBanner;
