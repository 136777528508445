import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {  useNavigate, useParams } from 'react-router-dom';
import {
  SwitchStyled,
  MainBox,
  Title,
  SubTitle,
  MainContainer,
  MainContainerBox,
  ExpandableBar,
  ExpandableBarSwitch,
  ExpandableBarTitle,
  ChevronDownIconStyled,
  ArrangementSection,
  ArrangementTitle,
  ArrangementText,
  CheckboxSection,
  CheckboxText,
  CheckboxStyled,
  BottomAnimation,
  BottomAnimationContainer,
  BottomSection,
  RightsSection
} from './styles';
import { useDispatch } from 'react-redux';
import { FormControlLabel } from '@mui/material';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const GlobalHirePopUp = ({
  open,
  handleClose,
  useAsPopUp,
  jobId,
  isInPopUp,
  fields,
  setFields,
  errors,
  setErrors,
  globalHireDefault,
  isOverlay
}) => {
  const history = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [jobQuestions, setJobQuestions] = useState([]);

  const [isExpanded, setIsExpanded] = useState(isInPopUp || false);

  useEffect(() => {
    if (globalHireDefault && !isInPopUp) {
      if (!isExpanded) {
        setIsExpanded(true);
      }
    }
  }, [globalHireDefault]);

  const originCountry = localStorage.getItem('originCountry');

  const countryName =
    originCountry == 'MY'
      ? 'Malaysia'
      : originCountry == 'SG'
      ? 'Singapore'
      : 'the country';

  // if is pop up disabled text should be 'false', if fields.enabled is 'true' , disabled text should be disabled
  const [disableText, setDisableText] = useState(!isInPopUp || !fields.enabled);

  useEffect(() => {
    if (isInPopUp) {
      setDisableText(false);
    } else if (fields?.enabled) {
      setDisableText(false);
    }
  }, [fields]);

  const triggerToggle = () => {
    if (fields?.enabled) {
      setIsExpanded(false);
    } else if (!fields?.enabled) {
      setIsExpanded(true);
    }
  };

  const isMasterAccount =
    localStorage.getItem('currentAccountType') === 'master';

  const enableScroll = () => {
    window.onscroll = function () {};
  };

  const disableScroll = () => {
    // Get the current page scroll position
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    // if any scroll is attempted, set this to the previous value
    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop);
    };
  };

  const onClose = () => {
    if (Boolean(open)) {
      disableScroll();
      setTimeout(() => {
        enableScroll();
      }, 500);
    }

    setFields({});
    handleClose();
  };

  const handleClosePopup = () => {
    if (useAsPopUp) {
      onClose();
    } else {
      if (isMasterAccount) {
        if (companyTeamMembers?.length > 0) {
          history(`/jobs/${jobId}/job-access-new`);
        } else {
          history('/jobs');
        }
      } else {
        history('/jobs');
      }
    }
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFF',
      background: '#FFF',
      maxWidth: 350,
      border: '1.5px solid #222',
      borderRadius: '8px',
      display: 'inline-flex',
      // height: '27px',
      // padding: '4px 8px',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }));

  const renderCheckBox = (name, value, title, description) => (
    <div style={{ display: 'flex', gap: '12px', alignItems: 'flex-start' }}>
      <CheckboxStyled
        name={name}
        color="default"
        disabled={disableText}
        inputProps={{
          'aria-label': 'checkbox with default color'
        }}
        checked={value}
        onChange={e => {
          setFields(prevFields => ({
            ...prevFields,
            [name]: e.target.checked
          }));

          setErrors(false);
          // handleErrorState(errorState.filter(error => error !== key));
        }}
      />
      <div>
        <CheckboxText type={'title'} disabled={disableText}>
          {title}
        </CheckboxText>

        <CheckboxText type={'desc'}>{description}</CheckboxText>
      </div>
    </div>
  );

  const renderContent = () => (
    <MainContainer isInPopUp={isInPopUp} isOverlay={isOverlay}>
      <MainContainerBox>
        {/* top title bar */}
        <MainBox isInPopUp={isInPopUp} isExpanded={isExpanded}>
          <ExpandableBar isExpanded={isExpanded}>
            <ExpandableBarTitle>
              <Title>Global Hire</Title>
              <SubTitle>
                {isInPopUp ? (
                  <>
                    Global Hire must be completed for Cross Border Tokens to be
                    applied. Update your Global Hire working arrangement so that
                    jobseekers know what you're looking for!
                  </>
                ) : (
                  <>
                    Turn on to indicate that you are open to hiring
                    international talents.
                  </>
                )}
              </SubTitle>
            </ExpandableBarTitle>

            {globalHireDefault ? (
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <p
                      style={{
                        color: '#222',
                        textAlign: 'center',
                        fontSize: '10px',
                        fontWeight: '500',
                        lineHeight: '14px',
                        margin: '0'
                      }}>
                      Cross Border Tokens require Global Hire to be enabled.
                    </p>
                  </React.Fragment>
                }
                placement="top">
                <ExpandableBarSwitch isInPopUp={isInPopUp}>
                  <SwitchStyled
                    name="active"
                    checked={fields?.enabled}
                    disabled={globalHireDefault}
                    onChange={() => {
                      setFields(prevFields => ({
                        ...prevFields,
                        enabled: !prevFields.enabled
                      }));
                      setErrors(false);
                      setDisableText(prevState => isInPopUp || !prevState);
                    }}
                    $width={46}
                    $height={24}
                    $thumbWidth={18}
                    $thumbHeight={18}
                  />

                  <ChevronDownIconStyled
                    onClick={() => setIsExpanded(prevFields => !prevFields)}
                    isExpanded={isExpanded}
                  />
                </ExpandableBarSwitch>
              </HtmlTooltip>
            ) : (
              <ExpandableBarSwitch isInPopUp={isInPopUp}>
                <SwitchStyled
                  name="active"
                  checked={fields?.enabled}
                  disabled={globalHireDefault}
                  onChange={() => {
                    setFields(prevFields => ({
                      ...prevFields,
                      enabled: !prevFields.enabled
                    }));
                    setErrors(false);
                    setDisableText(prevState => isInPopUp || !prevState);
                    triggerToggle();
                  }}
                  $width={46}
                  $height={24}
                  $thumbWidth={18}
                  $thumbHeight={18}
                />

                <ChevronDownIconStyled
                  onClick={() => setIsExpanded(prevFields => !prevFields)}
                  isExpanded={isExpanded}
                />
              </ExpandableBarSwitch>
            )}
          </ExpandableBar>

          <BottomAnimationContainer
          isExpanded={isExpanded}
          isError={errors}
          isInPopUp={isInPopUp}
          >
            <BottomAnimation
              isExpanded={isExpanded}
              isError={errors}
              isInPopUp={isInPopUp}>
              <BottomSection isError={errors} isInPopUp={isInPopUp}>
                <ArrangementSection>
                  <ArrangementTitle>
                    <ArrangementText row={'1'} disabled={disableText}>
                      Working Arrangement
                    </ArrangementText>

                    <ArrangementText row={'2'} disabled={disableText}>
                      Select one or more of the following options
                    </ArrangementText>
                  </ArrangementTitle>

                  <CheckboxSection>
                    {renderCheckBox(
                      'workingArrangementRemote',
                      fields?.workingArrangementRemote
                        ? fields?.workingArrangementRemote
                        : false,
                      'Remote',
                      'Employees will be fully remote with virtual collaborations'
                    )}

                    {renderCheckBox(
                      'workingArrangementOnSite',
                      fields?.workingArrangementOnSite
                        ? fields?.workingArrangementOnSite
                        : false,
                      'On site',
                      'Employees will work onsite at your company location'
                    )}

                    {renderCheckBox(
                      'workingArrangementHybrid',
                      fields?.workingArrangementHybrid
                        ? fields?.workingArrangementHybrid
                        : false,
                      'Hybrid',
                      'Employees will work both remotely and onsite in a hybrid arrangement'
                    )}
                  </CheckboxSection>
                </ArrangementSection>

                <RightsSection>
                  <ArrangementTitle>
                    <ArrangementText row={'1'} disabled={disableText}>
                      Candidate’s Right to Work
                    </ArrangementText>

                    <ArrangementText row={'2'} disabled={disableText}>
                      Select one or more of the following options
                    </ArrangementText>
                  </ArrangementTitle>

                  <CheckboxSection>
                    <div
                      style={{
                        display: 'flex',
                        gap: '12px',
                        alignItems: 'flex-start'
                      }}>
                      <CheckboxStyled
                        name={'rightToWorkOpenToSponsor'}
                        color="default"
                        inputProps={{
                          'aria-label': 'checkbox with default color'
                        }}
                        disabled={disableText}
                        checked={
                          fields?.rightToWorkOpenToSponsor
                            ? fields?.rightToWorkOpenToSponsor
                            : false
                        }
                        onChange={e => {
                          setFields(prevFields => ({
                            ...prevFields,
                            rightToWorkOpenToSponsor: e.target.checked
                          }));
                          setErrors(false);

                          // handleErrorState(
                          //   errorState.filter(error => error !== key)
                          // );
                        }}
                      />
                      <div>
                        <CheckboxText type={'title2'} disabled={disableText}>
                          I am open to sponsoring a working visa for qualified
                          candidates (subject to T&Cs)
                        </CheckboxText>
                      </div>
                    </div>

                    <div>
                      <FormControlLabel
                        style={{
                          display: 'flex',
                          gap: '12px',
                          alignItems: 'flex-start',
                          margin: '0px'
                        }}
                        control={
                          <>
                            <CheckboxStyled
                              checked={
                                fields?.rightToWorkLocalCitizen &&
                                fields?.rightToWorkPermanentCitizen &&
                                fields?.rightToWorkWorkVisaOrEmploymentPass
                              }
                              disabled={disableText}
                              indeterminate={
                                fields?.rightToWorkLocalCitizen !==
                                  fields?.rightToWorkPermanentCitizen ||
                                fields?.rightToWorkLocalCitizen !==
                                  fields?.rightToWorkWorkVisaOrEmploymentPass
                              }
                              onChange={e => {
                                setFields(prevFields => ({
                                  ...prevFields,
                                  rightToWorkLocalCitizen: e.target.checked,
                                  rightToWorkPermanentCitizen: e.target.checked,
                                  rightToWorkWorkVisaOrEmploymentPass:
                                    e.target.checked
                                }));

                                setErrors(false);
                              }}
                            />
                            <div>
                              <CheckboxText
                                type={'title2'}
                                disabled={disableText}>
                                I prefer candidates with an existing right to
                                work in {countryName}
                              </CheckboxText>
                            </div>
                          </>
                        }
                      />

                      {(fields?.rightToWorkLocalCitizen ||
                        fields?.rightToWorkPermanentCitizen ||
                        fields?.rightToWorkWorkVisaOrEmploymentPass) && (
                        <div
                          style={{
                            display: 'flex',
                            gap: '2px',
                            alignItems: 'flex-start',
                            margin: '8px 0px 0px 8px',
                            flexDirection: 'column',
                            padding: '0px 8px 0px 16px',
                            borderLeft: '1px solid #D8D8D8'
                          }}>
                          <FormControlLabel
                            style={{
                              display: 'flex',
                              gap: '12px',
                              alignItems: 'flex-start',
                              margin: '0px'
                            }}
                            control={
                              <>
                                <CheckboxStyled
                                  checked={
                                    fields?.rightToWorkLocalCitizen
                                      ? fields.rightToWorkLocalCitizen
                                      : false
                                  }
                                  disabled={disableText}
                                  onChange={e => {
                                    setFields(prevFields => ({
                                      ...prevFields,
                                      rightToWorkLocalCitizen: e.target.checked
                                    }));

                                    setErrors(false);

                                    // handleErrorState(
                                    //   errorState.filter(error => error !== key)
                                    // );
                                  }}
                                />
                                <div>
                                  <CheckboxText
                                    type={'title2'}
                                    disabled={disableText}>
                                    Local Citizen
                                  </CheckboxText>
                                </div>
                              </>
                            }
                          />

                          <FormControlLabel
                            style={{
                              display: 'flex',
                              gap: '12px',
                              alignItems: 'flex-start',
                              margin: '0px'
                            }}
                            control={
                              <>
                                <CheckboxStyled
                                  checked={
                                    fields?.rightToWorkPermanentCitizen
                                      ? fields.rightToWorkPermanentCitizen
                                      : false
                                  }
                                  disabled={disableText}
                                  onChange={e => {
                                    setFields(prevFields => ({
                                      ...prevFields,
                                      rightToWorkPermanentCitizen:
                                        e.target.checked
                                    }));

                                    setErrors(false);

                                    // handleErrorState(
                                    //   errorState.filter(error => error !== key)
                                    // );
                                  }}
                                />
                                <div>
                                  <CheckboxText
                                    type={'title2'}
                                    disabled={disableText}>
                                    Permanent Resident (PR)
                                  </CheckboxText>
                                </div>
                              </>
                            }
                          />

                          <FormControlLabel
                            style={{
                              display: 'flex',
                              gap: '12px',
                              alignItems: 'flex-start',
                              margin: '0px'
                            }}
                            control={
                              <>
                                <CheckboxStyled
                                  checked={
                                    fields?.rightToWorkWorkVisaOrEmploymentPass
                                      ? fields.rightToWorkWorkVisaOrEmploymentPass
                                      : false
                                  }
                                  disabled={disableText}
                                  onChange={e => {
                                    setFields(prevFields => ({
                                      ...prevFields,
                                      rightToWorkWorkVisaOrEmploymentPass:
                                        e.target.checked
                                    }));

                                    setErrors(false);

                                    // handleErrorState(
                                    //   errorState.filter(error => error !== key)
                                    // );
                                  }}
                                />
                                <div>
                                  <CheckboxText
                                    type={'title2'}
                                    disabled={disableText}>
                                    Work Visa / Employment Pass
                                  </CheckboxText>
                                </div>
                              </>
                            }
                          />
                        </div>
                      )}
                    </div>
                  </CheckboxSection>
                </RightsSection>
              </BottomSection>

              {!isInPopUp && errors && (
                <p
                  style={{
                    color: '#BE4242',
                    fontSize: '12px',
                    lineHeight: '20px',
                    display: 'flex',
                    padding: '0px 8px',
                    alignItems: 'center',
                    gap: '8px'
                  }}>
                  <ExclamationCircleIcon
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '#BE4242'
                    }}
                  />
                  Please complete the Global Hire details to proceed
                </p>
              )}
            </BottomAnimation>
          </BottomAnimationContainer>
        </MainBox>

        {isInPopUp && errors && (
          <p
            style={{
              color: '#BE4242',
              fontSize: '12px',
              lineHeight: '20px',
              display: 'flex',
              padding: '0px 8px',
              alignItems: 'center',
              gap: '8px'
            }}>
            <ExclamationCircleIcon
              style={{
                width: '20px',
                height: '20px',
                color: '#BE4242'
              }}
            />
            Please complete the Global Hire details to proceed
          </p>
        )}
      </MainContainerBox>
    </MainContainer>
  );

  return <div style={{ width: '100%' }}>{renderContent()}</div>;
};

GlobalHirePopUp.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  useAsPopUp: PropTypes.bool,
  jobId: PropTypes.string
};

GlobalHirePopUp.defaultProps = {
  open: false,
  handleClose: () => {},
  useAsPopUp: true,
  jobId: null
};

export default GlobalHirePopUp;
