import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  updateModelName,
  updateModelState,
  updateProfilePopUp
} from '../../../../redux/actions/talent_action';
import {
  AnimatedContainer,
  AnimatedBio,
  AnimatedOverlay,
  AnimatedResume,
  CloseIconContainer,
  CloseIconStyled
} from './styles';

const ProfilePopup = ({ resumeChild, bioChild }) => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const closeHandler = () => {
    localStorage.setItem('TSChatIdleTimestamp', moment().valueOf());

    history('/talent-search', { replace: true });

    dispatch(updateProfilePopUp(false));
  };

  return (
    <AnimatedOverlay>
      <AnimatedContainer>
        <AnimatedResume>{resumeChild}</AnimatedResume>
        <AnimatedBio>{bioChild}</AnimatedBio>
      </AnimatedContainer>
      {/* <CloseIconContainer>
        <CloseIconStyled onClick={closeHandler} />
      </CloseIconContainer> */}
    </AnimatedOverlay>
  );
};

export default ProfilePopup;
