import { styled as styling } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

export const BenefitsWrapper = styling(Grid)(props => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '16px',
  rowGap: '16px',
  marginBottom: '16px',
  width: '100%'
}));

export const EmptyBenefitContainer = styling(Grid)(props => ({
  backgroundColor: '#EFEFEF',
  width: 'auto',
  border: '1px dashed #707070',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: '8px',
  gap: '16px',
  cursor: "pointer",
  minHeight: "440px",
  transition: "0.3s all",
  
  ":hover":{
    backgroundColor: "#E5E5E5"
  }

}));

export const PlusCircleIconStyled = styling(PlusCircleIcon)(props => ({
  height: '40px',
  width: '40px',
  color: "#353535",
  strokeWidth: "1px"
}));


export const EmptyBenefitContainerView = styling(Grid)(props => ({
  backgroundColor: '#EFEFEF',
  width: 'auto',
  border: '1px dashed #707070',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: '8px',
  width: "296px",
  minHeight: "188px",
  gap: '16px',
  cursor: "pointer",
  transition: "0.3s all",
  
  ":hover":{
    backgroundColor: "#E5E5E5"
  }

}));
