import React from 'react';
import {
  CloseIconStyled,
  ContentContainer,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
  GreyContainerStyled,
  GreyContainerText,
  IconButtonStyled,
  ImageContainer,
  TitleContainer,
  TitleDescStyled,
  TitleStyled,
  TypographySpan,
  TypographyStyled
} from './styles';
import { ReactComponent as BannerImage } from '../../../../../assets/images/company/canvas-cover-info.svg';
import infoImage from '../../../../../assets/images/company/canvas-info.svg';
import blackSquare from '../../../../../assets/images/company/black-squares.svg';
import { Grid } from '@mui/material';

export default function InfoPopUp(props) {
  const { open, handleClose } = props;
  return (
    <DialogStyled open={open} onClose={handleClose}>
      <DialogTitleStyled>
        <TitleContainer>
          <TitleStyled>
            More Info: <TypographySpan>Cover Image</TypographySpan>
          </TitleStyled>
          <TitleDescStyled>
            Here’s where your cover image will be displayed.
          </TitleDescStyled>
        </TitleContainer>
        <IconButtonStyled
          aria-label="close"
          onClick={handleClose}
          sx={{ padding: '0px' }}>
          <CloseIconStyled />{' '}
        </IconButtonStyled>
      </DialogTitleStyled>

      <DialogContentStyled dividers>
        <ContentContainer>
          Your cover image will be displayed on the following few pages:
          <GreyContainerStyled>
            <Grid
              sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
              <GreyContainerText>
                1. Your Company Profile and Job Ad media section
              </GreyContainerText>
              <Grid>
                <img
                  src={blackSquare}
                  style={{ height: '144px', width: '481px', marginLeft: "18px" }}
                />
              </Grid>
            </Grid>

            <Grid
              sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
              <GreyContainerText>
                2. Company Listing Page and as a recommended Company on other
                profiles
              </GreyContainerText>
              <img
                src={infoImage}
                style={{ height: '150px', width: '150px', marginLeft: "18px" }}
              />
            </Grid>
          </GreyContainerStyled>
        </ContentContainer>
      </DialogContentStyled>
    </DialogStyled>
  );
}
