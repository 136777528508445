import { styled as styling } from '@mui/material/styles';
import { XMarkIcon as CloseIcon } from '@heroicons/react/24/outline';
import {
  Grid,
  Tooltip,
  Chip,
  Typography,
  tooltipClasses,
  Button,
  IconButton
} from '@mui/material';
import { InformationCircleIcon } from '@heroicons/react/24/solid';

export const CustomTooltip = styling(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    overflow: 'hidden',
    borderRadius: '16px',
    padding: '0px',
    border: '2px solid black',
    backgroundColor: 'white',
    color: 'black',
    zIndex: 100,
    width: '400px !important',
    maxWidth: 400
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
    {
      marginBottom: '4px'
    }
});

export const TooltipContainer = styling(Grid)(props => ({
  width: '400px',
  borderRadius: '12px'
}));

export const TooltipTitleContainer = styling(Grid)(props => ({
  padding: '12px 16px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
}));

export const TooltipContentContainer = styling(Grid)(props => ({
  padding: '0px 12px 16px 12px'
}));

export const TooltipContentPurpleContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  backgroundColor: '#EFEFFD',
  width: '100%',
  padding: '8px 10px',
  borderRadius: '8px'
}));

export const TooltipFooterContainer = styling(Grid)(props => ({
  borderTop: '1px solid #D8D8D8',
  padding: '12px 16px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  backgroundColor: '#F5F5F5'
}));

export const ContactButton = styling(Button)(props => ({
  backgroundColor: '#2E2E2E',
  fontSize: '12px',
  padding: '0px 24px',
  borderRadius: '6px',
  height: '24px',
  textTransform: 'none',
  fontWeight: 400,
  color: 'white !important',

  ':hover': {
    backgroundColor: '#2E2E2E'
  }
}));

export const TypographyStyled = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.60)`
}));

export const InfoIcon = styling(InformationCircleIcon)(props => ({
  height: '12px',
  width: '12px',
  color:
    props?.status === 'Rejected' ? '#BE4242 !important' : `#C0840C !important`,
  cursor: 'pointer !important'
}));
