import React from 'react';
import { countryOptions } from './constants';
import countries from 'i18n-iso-countries';
import { FlagIcon } from 'react-flag-kit';
import { CountryContainerSC } from './styles';
import { updateCountryDropdown } from '../../redux/actions/company_actions';
import { useDispatch, useSelector } from 'react-redux';
import { setLocalStorageItem } from '../../helpers/local_storage_utils';
import SharedSelectField from '../SharedSelectField';

const CountryOption = ({ code = 'MY' }) => {
  return (
    <CountryContainerSC>
      <FlagIcon
        size={15}
        code={code}
        style={{ boxShadow: 'rgba(0, 0, 0, 0.25) 1px 1px 2px', width: '20px' }}
      />
      <span>{countries.getName(code, 'en')}</span>
    </CountryContainerSC>
  );
};

const SharedSelectCountry = ({ name = 'country', hideSG = false }) => {
  const dispatch = useDispatch();
  const country = useSelector(state => state.companies.country);

  const onChangeCountry = e => {
    // change dropdown value
    dispatch(updateCountryDropdown(e.target.value));
    setLocalStorageItem('country', e.target.value);
    document.cookie = `gtmCountry=${e.target.value}; path=/; max-age=31540000`;
  };

  const options = hideSG
    ? countryOptions
        .filter(country => country === 'MY')
        .map(country => ({
          id: country,
          title: <CountryOption code={country} />
        }))
    : countryOptions.map(country => ({
        id: country,
        title: <CountryOption code={country} />
      }));

  return (
    <SharedSelectField
      menuItemMargin={11.5}
      name={name}
      options={options}
      onChange={onChangeCountry}
      value={country}
    />
  );
};

export default SharedSelectCountry;
