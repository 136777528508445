import React from 'react';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import {
  updateAshleyRewriteOrigin,
  updateAshleyRewritePopup
} from '../../../../../../redux/actions/job_action';
import { useDispatch } from 'react-redux';
import { useFormContext } from '../../../context';
import { isEmpty } from 'lodash';
import { ButtonBaseStyled, ButtonText } from './styles';

export default function AshleyButton({ origin }) {
  const dispatch = useDispatch();
  const { formState } = useFormContext();

  const InSection = formState?.gptUsage?.rewrites?.some(rewrite =>
    rewrite?.section?.includes(origin)
  );

  const getFlag = () => {
    if (formState[origin + 'Ashley'] || InSection) {
      return true;
    }

    return false;
  };

  const onOpenPopUp = () => {
    dispatch(updateAshleyRewritePopup(true));
    dispatch(updateAshleyRewriteOrigin(origin));
  };

  return (
    <ButtonBaseStyled onClick={() => onOpenPopUp()} $ashleyEdited={getFlag()}>
      <ButtonText>
        {getFlag() ? "Ashley's Edit" : 'Ask ashley to edit for me'}
      </ButtonText>
      <PencilSquareIcon
        style={{
          width: '14px',
          height: '14px'
        }}
      />
    </ButtonBaseStyled>
  );
}
