import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextField, InputAdornment, styled, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';
import { Skeleton } from '@mui/material';
import DefaultLayout from '../../../layouts/DefaultLayout';
import AddEditTeamMemberPopUp from './PopUps/AddEditTeamMemberPopUp';
import AddEditAccessLevelPopUp from './PopUps/AddEditAccessLevelPopUp';
import AddEditJobTrackPopUp from './PopUps/AddEditJobTrackPopUp';
import SkeletonLoader from './SkeletonLoader.js';
import Colors from '../../../styles/colors.module.scss';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSpecialisation,
  fetchTeamMembers
} from '../../../redux/actions/job_action';
import { fetchAccessLevels } from '../../../redux/actions/company_actions';
import Name from './TableColumns/Name/index.js';
import AccessLevel from './TableColumns/AccessLevel/index.js';
import JobTracks from './TableColumns/JobTracks/index.js';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';

const TextFieldStyled = styled(TextField)(props => ({
  boxShadow: 'none',

  '& .MuiInputBase-root': {
    borderRadius: '12px'
  },

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid #AEAEAE',
      borderRadius: '12px'
      // boxShadow: "15px"
    },
    '&:hover fieldset': {
      border: `1px solid ${Colors.priBlack}`
    },
    '&.Mui-focused fieldset': {
      border: `2px solid ${Colors.priPurple}`
    }
  }
}));

const ManageTeamMembers = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [tableData, setTableData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);

  const myCompany = useSelector(state => state.companies.myCompany);

  const fetchingMyCompany = useSelector(
    state => state.companies.fetchingMyCompany
  );

  const companyTeamMembers = useSelector(
    state => state.jobs.companyTeamMembers
  );

  const fetchingCompanyTeamMembers = useSelector(
    state => state.jobs.fetchingCompanyTeamMembers
  );

  const search = _.debounce(value => {
    setSearchText(value);
  }, 500);

  const deboucedSearch = useCallback(value => search(value), []);

  const onSearch = value => {
    deboucedSearch(value);
  };

  useEffect(() => {
    dispatch(fetchAccessLevels());
    dispatch(fetchSpecialisation());
  }, []);

  useEffect(() => {
    dispatch(fetchTeamMembers({ name: searchText }));
  }, [searchText]);

  useEffect(() => {
    setTableData(companyTeamMembers);
  }, [companyTeamMembers]);

  return (
    <DefaultLayout maxWidth={true}>
      <div style={{ padding: '4rem' }}>
        <AddEditTeamMemberPopUp
          open={step === 1}
          handleClose={() => setStep(null)}
          setStep={setStep}
        />
        <AddEditAccessLevelPopUp
          open={step === 2}
          accessLvlData={[]}
          handleClose={() => setStep(null)}
          setStep={setStep}
          tableData={tableData}
          setTableData={setTableData}
        />
        <AddEditJobTrackPopUp
          open={step === 3}
          jobSpecialisations={[]}
          handleClose={() => setStep(null)}
          tableData={tableData}
          setTableData={setTableData}
          editIndex={editIndex}
          setEditIndex={setEditIndex}
        />

        <Grid container>
          <Grid item xs={12}>
            <div style={{ paddingBottom: 25 }}>
              {fetchingMyCompany ? (
                <div>
                  <Skeleton variant="text" width={250} height={25} />
                </div>
              ) : (
                <h6
                  style={{
                    fontSize: '20px',
                    fontWeight: '700',
                    lineHeight: '24px',
                    fontFamily: 'Inter'
                  }}>{`${myCompany?.name}’s Team Members`}</h6>
              )}
            </div>
          </Grid>

          <Grid item xs={6}>
            <div>
              <TextFieldStyled
                onChange={event => onSearch(event.target.value)}
                id="input-with-icon-textfield"
                // variant="filled"
                fullWidth
                placeholder="Search by name"
                InputProps={{
                  style: {
                    backgroundColor: '#FFFFFF',
                    height: '48px',
                    padding: '12px 16px'
                  },
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                inputProps={{
                  style: {
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    letterSpacing: '0.15px',
                    fontFamily: 'Inter'
                  }
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={styles.addTeamMemberButton}>
              {/* <Button size="BIG" disabled={loading} onClick={() => setStep(1)}>
                Add Team Member
              </Button> */}

              <Button
                variant="contained"
                sx={{
                  borderRadius: '8px',
                  background: '#512ACC',
                  fontSize: '16px',
                  fontFamily: 'Inter',
                  fontWeight: 700,
                  textTransform: 'none',

                  '&:hover': {
                    background: '#40249b'
                  }
                }}
                disabled={fetchingCompanyTeamMembers}
                onClick={() => setStep(1)}>
                Add Team Member
              </Button>
            </div>
          </Grid>
        </Grid>

        <div className={styles.tableWrapper}>
          {fetchingCompanyTeamMembers ? (
            <SkeletonLoader />
          ) : (
            <div>
              <MyTable
                tableData={tableData}
                setTableData={setTableData}
                editIndex={editIndex}
                setEditIndex={setEditIndex}
              />
              <p className={styles.memberSeatsText}>
                {`${myCompany?.companyTeamMembersCount} / ${myCompany?.noOfCtm} member seats are occupied.`}
              </p>
            </div>
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};

const MyTable = ({ tableData, setTableData, editIndex, setEditIndex }) => {
  const columns = [
    {
      name: 'name',
      label: 'Name',
      component: Name,
      cellProps: { style: { maxWidth: '150px' } }
    },
    {
      name: 'accesslevel',
      label: 'Access Level',
      component: AccessLevel,
      cellProps: { style: { maxWidth: '200px' } }
    },
    {
      name: 'jobtracks',
      label: 'Job Tracks',
      component: JobTracks,
      cellProps: { style: { maxWidth: '200px' } }
    }
  ];

  return (
    <TableContainer component={Paper} className={styles.tableContainer}>
      <Table className={styles.table}>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell key={column.name} style={column.cellProps.style}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row, index) => (
            <TableRow key={index}>
              {columns.map(column => (
                <TableCell key={column.name} style={column.cellProps.style}>
                  <column.component
                    index={index}
                    tableData={tableData}
                    setTableData={setTableData}
                    editIndex={editIndex}
                    setEditIndex={setEditIndex}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ManageTeamMembers;
