let uniqueId = 0;
function getItems(count) {
  return Array.from({ length: count }, (v, k) => {
    const id = uniqueId++;
    return {
      id: `id:${id}`,
      text: `item ${id}`
    };
  });
}

const initial = {
  columns: {
    'undecided': {
      id: 'undecided',
      title: 'Undecided',
      items: getItems(250)
    },
    'kiv': {
      id: 'kiv',
      title: 'KIV',
      items: getItems(100)
    },
    'shortlisted': {
      id: 'shortlisted',
      title: 'Shortlisted',
      items: getItems(10)
    },
    'interview': {
      id: 'interview',
      title: 'Interview',
      items: getItems(10)
    },
    'offer': {
      id: 'offer',
      title: 'Offer',
      items: getItems(3)
    },
    'rejected': {
      id: 'rejected',
      title: 'Rejected',
      items: getItems(100)
    },
    // 'column-6': {
    //   id: 'column-6',
    //   title: 'Blacklisted',
    //   items: getItems(20)
    // }
  },
  columnOrder: [
    'undecided',
    'kiv',
    'shortlisted',
    'interview',
    'offer',
    'rejected',
    // 'column-6'
  ]
};

export default function getInitialData() {
  return initial;
}
