import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { TextFieldStyled, DropDownContainer } from './styles';
import Colors from '../../../../../styles/colors.module.scss';

export const PlacesAutocompleteComponent = ({
  addressAutoCompleteRef,
  address,
  handleAddressChange,
  onAddressBlur,
  onAddressFocus,
  label
}) => {
  const validationCheck = (address, label) => {
    if (label == 'location') {
      if (address == '') {
        return 'Location is required';
      }
    }
  };

  return (
    <PlacesAutocomplete
      ref={addressAutoCompleteRef}
      value={address}
      onChange={handleAddressChange}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <TextFieldStyled
            error={address == '' ? true : false}
            helperText={validationCheck(address, label)}
            onBlur={onAddressBlur}
            onFocus={onAddressFocus}
            exclude_text_area="true"
            hiddenLabel
            id={`filled-required`}
            variant="filled"
            InputProps={{
              disableUnderline: true,
              style: {
                borderRadius: '20px',
                width: '100%',
                background: 'transparent',
                border:
                  address == '' ? '0.5px solid #f44336' : '0.5px solid #aeaeae',
                padding: '10px 20px'
              }
            }}
            inputProps={{
              style: {
                fontSize: `14px`,
                letterSpacing: '0.03rem',
                width: '100%',
                padding: '0'
              }
            }}
            {...getInputProps()}
            placeholder="Add location*"
          />
          <DropDownContainer>
            {loading && <div>Loading...</div>}
            {suggestions.map(suggestion => {
              const style = {
                cursor: 'pointer',
                minHeight: 35,
                border: 'solid 1px',
                borderTop: 'none',
                borderColor: Colors.terLightGrey,
                padding: 6,
                backgroundColor: suggestion.active
                  ? Colors.terWhite
                  : Colors.priWhite
              };
              return (
                <div
                  style={{ height: 50 }}
                  {...getSuggestionItemProps(suggestion, {
                    style
                  })}>
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </DropDownContainer>
        </div>
      )}
    </PlacesAutocomplete>
  );
};
