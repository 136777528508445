import React, { useEffect, useState, useRef } from 'react';
import {
  Close as CloseIcon,
  LocalConvenienceStoreOutlined
} from '@mui/icons-material';
import { useSpring, animated } from 'react-spring';
import {
  TabIndicator,
  TextFieldStyled,
  ContentContainer,
  IconContainer,
  FontAwesomeIconStyled,
  Text,
  CloseIconContainer,
  ButtonContainer,
  TabStyled,
  TabsStyled,
  ProfilePicture,
  ButtonStyled,
  CommentContainer,
  Dot,
  Divider
} from './styles';
import {
  Tab,
  Tabs,
  Grid,
  Box,
  Modal,
  Backdrop,
  CircularProgress,
  Tooltip
} from '@mui/material';
import {
  faBars,
  faCalendarPlus,
  faClock,
  faCommentAlt,
  faMapMarkerAlt,
  faPen,
  faPhoneAlt,
  faPlusCircle,
  faUserAlt
} from '@fortawesome/free-solid-svg-icons';
import SharedContent from '../SharedContent';
import { Button, Spinner } from '../../../../../components';
import {
  ErrorToast,
  SuccessToast,
  WarningToast,
  InfoToast
} from '../../../../../utils/ToastUtils';
import {
  addApplicationNote,
  addInterviewNote,
  updateInterviewNote,
  updateFirstScheduleInterviewModalStatus,
  updateNextScheduleInterviewModalStatus,
  updateInterviewInfo,
  updateInterviewDetails,
  updateAllApplications,
  deleteInterview
} from '../../../../../../src/redux/actions/application_action';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as types from '../../../../../../src/redux/types/application_type';
import moment from 'moment';
import { TextInputFunction } from '../TextInput';
import { PlacesAutocompleteComponent } from '../PlacesAutocomplete';
import { TextArea } from '../TextArea';
import { TimeSelect } from '../TimeDropdown';
import DeleteInterviewPopUp from '../../../../Jobs/PopUps/DeleteInterviewPopUp';
import store from '../../../../../redux/stores/store';
import SpecialInput from '../PhoneInput';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && <div>{children}</div>}
    </Grid>
  );
}

const StyledTabs = props => (
  <TabsStyled
    {...props}
    TabIndicatorProps={{
      style: {
        backgroundColor: '#635ee7',
        height: '2px',
        position: 'absolute',
        zIndex: '999'
      }
    }}
  />
);

const StyledTab = props => (
  <TabStyled icon={props.icon} value="one" disableRipple {...props} />
);

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    }
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '459px',
  minHeight: '720px',
  backgroundColor: '#fff',
  boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.08)',
  borderRadius: '24px',
  padding: '20px 50px'
};

let submittingNote = false;

function ListScheduleInterview(props) {
  const {
    open,
    isAts,
    candidateInfo,
    candidates,
    setCandidates,
    setCurrentCandidates,
    loadingShortlistedApplications,
    loadingInterviews,
    jobData,
    setJobData
  } = props;
  const [accountType, setAccountType] = useState('');
  const [accountInfo, setAccountInfo] = useState(null);
  const [deleteInterviewModal, setDeleteInterviewModal] = useState(false);
  const [editInterview, setEditInterview] = useState(false);

  const today = moment().format('YYYY-MM-DD');
  const addressAutoCompleteRef = useRef();
  const dispatch = useDispatch();

  const handleModalClose = () => {
    setNote('');
    setValue(0);
    setEditNote(false);
    props.updateNextScheduleInterviewModalStatus(false);
    setEditInterview(false);
  };

  const [value, setValue] = React.useState(0);
  const [note, setNote] = React.useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const noteValueChange = event => {
    setNote(event.currentTarget.value);
  };

  const [editNote, setEditNote] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleEditNote = () => {
    if (!editNote) {
      setNote(getNote(getCandidate(props)));
    }

    setEditNote(!editNote);
  };

  const getCandidate = props => {
    if (!isAts) {
      return props.interviewData;
      // return candidateInfo;
    } else {
      if (props.allApplications[props.currentCandidateIndex]) {
        return props.allApplications[props.currentCandidateIndex];
      }

      return null;
    }
  };

  const getInterviewLength = candidate => {
    let application = candidate?.jobApplication
      ? candidate.jobApplication
      : candidate;
    if (application?.interviews?.length) {
      return application.interviews.length;
    }

    return 1;
  };

  const getInterviewDate = candidate => {
    let application = candidate?.jobApplication
      ? candidate.jobApplication
      : candidate;

    if (application?.interviews?.length > 0) {
      if (editInterview) {
        return moment(application.interviews[0].date).format('YYYY-MM-DD');
      } else {
        return moment
          .utc(application.interviews[0].date)
          .add(8, 'hours')
          .format('DD MMM YYYY');
      }
    }

    return '';
  };

  const getInterviewTime = candidate => {
    let application = candidate?.jobApplication
      ? candidate.jobApplication
      : candidate;

    if (
      application?.interviews?.length > 0 &&
      application.interviews[0].startTime
    ) {
      return moment('2015-01-01')
        .startOf('day')
        .seconds(application.interviews[0].startTime)
        .format('h:mm A');
    }

    return '';
  };

  const getInterviewDateAndTime = candidate => {
    let application = candidate?.jobApplication
      ? candidate.jobApplication
      : candidate;

    if (application?.interviews?.length > 0) {
      return moment(application?.interviews[0].date)
        .format('YYYY-MM-DD ')
        .concat(
          moment('2015-01-01')
            .startOf('day')
            .seconds(application.interviews[0].startTime)
            .format('h:mm A')
        );
    }

    return '';
  };

  const diffInterviewAndToday = () => {
    var interviewTime = getInterviewDateAndTime(getCandidate(props));
    var todayDate = today;

    return moment(interviewTime).diff(moment(todayDate), 'minute');
  };
  // const getInterviewStatus = candidate => {
  //   let application = candidate?.jobApplication
  //     ? candidate.jobApplication
  //     : candidate;

  //   if (application?.interviews?.length > 0) {
  //     return application.interviews[0].status;
  //   }

  //   return 'not null';
  // };

  const allowNextInterview = candidate => {
    let application = candidate?.jobApplication
      ? candidate.jobApplication
      : candidate;

    const interviewLength = application?.interviews
      ? application.interviews.length
      : 0;

    if (interviewLength > 0) {
      const lastInterview = application.interviews[0];

      // Disable next interview if last interview already rejected or offered
      if (
        lastInterview.status == 'rejected' ||
        lastInterview.status == 'offered' ||
        application?.state == 'rejected'
      ) {
        return false;
      }

      // const interviewDate = moment.utc(application.interviews[0].fullStartTime).add(8, "hours");

      // if (moment.utc().add(8, "hours").isBefore(interviewDate)) {
      //   // Not yet expired, cannot schedule next interview
      //   return false;
      // } else if (application.interviews[0].status == null) {
      //   // Expired and status == null
      //   return true;
      // }
    }

    return true;
  };

  const getInterviewVenue = candidate => {
    let application = candidate?.jobApplication
      ? candidate.jobApplication
      : candidate;

    if (application?.interviews?.length > 0) {
      return application.interviews[0].venue;
    }

    return '';
  };

  const getContactPerson = candidate => {
    let application = candidate?.jobApplication
      ? candidate.jobApplication
      : candidate;

    if (application?.interviews?.length > 0) {
      return application.interviews[0].contactPerson;
    }

    return '';
  };

  const getContactNumber = candidate => {
    let application = candidate?.jobApplication
      ? candidate.jobApplication
      : candidate;

    if (application?.interviews?.length > 0) {
      return application.interviews[0].contactNumber;
    }

    return '';
  };

  const getMessageToJobseeker = candidate => {
    let application = candidate?.jobApplication
      ? candidate.jobApplication
      : candidate;

    if (
      application?.interviews?.length > 0 &&
      application.interviews[0].messageToJobseeker
    ) {
      return application.interviews[0].messageToJobseeker;
    }

    return '-';
  };

  const getNote = candidate => {
    let application = candidate?.jobApplication ? candidate : candidate;

    if (application?.interviewNotes?.length > 0) {
      return application.interviewNotes[0].note;
    }

    if (
      application?.interviews?.length > 0 &&
      application?.interviews[0]?.interviewNotes?.length > 0
    ) {
      return application.interviews[0].interviewNotes[0].note;
    }

    return '';
  };

  const allowAddOrEditNote = candidate => {
    let application = candidate?.jobApplication
      ? candidate.jobApplication
      : candidate;

    if (
      application?.interviews?.length > 0 &&
      application?.interviews[0]?.interviewNotes?.length > 0
    ) {
      const currentNote = application.interviews[0].interviewNotes[0];

      if (
        !currentNote.companyTeamMember &&
        accountType.toLowerCase() != 'master'
      ) {
        //created by master but current account is CTM
        return false;
      } else if (
        currentNote.companyTeamMember &&
        currentNote.companyTeamMember.id != accountInfo?.accountId
      ) {
        //created by CTM and id not same
        return false;
      }
    }

    return true;
  };

  const updateLocalNote = interview => {
    if (!candidates) return;

    const index = candidates.findIndex(
      item => item?.node?.id === candidateInfo?.id
    );

    let cCandidates = [...candidates];

    if (index !== -1) {
      if (candidates[index].node?.jobApplication) {
        //is interview
        const interviewNote =
          interview &&
          interview.interviewNotes &&
          interview.interviewNotes.length > 0
            ? interview.interviewNotes[0]
            : {};

        const currentJobApplication = {
          ...cCandidates[index].node.jobApplication
        };

        const currentInterviews = [
          ...cCandidates[index].node.jobApplication.interviews
        ];

        const interviewIndex = currentInterviews.findIndex(
          currentInterview => currentInterview.id == interview?.id
        );

        if (interviewIndex != -1) {
          currentInterviews[interviewIndex] = { ...interview };
        } else {
          // Not found then replace the first one
          if (currentInterviews.length > 0) {
            currentInterviews[0] = { ...interview };
          }
        }

        currentJobApplication.interviews = [...currentInterviews];

        cCandidates[index] = {
          ...cCandidates[index],
          node: {
            ...cCandidates[index]?.node,
            interviewNotes: [{ ...interviewNote }],
            jobApplication: currentJobApplication
          }
        };
      } else {
        // is application - rare cases
        const currentInterviews = [...cCandidates[index].node.interviews];

        const interviewIndex = currentInterviews.findIndex(
          currentInterview => currentInterview.id == interview?.id
        );

        if (interviewIndex != -1) {
          currentInterviews[interviewIndex] = { ...interview };
        } else {
          // Not found then replace the first one
          if (currentInterviews.length > 0) {
            currentInterviews[0] = { ...interview };
          }
        }

        cCandidates[index] = {
          ...cCandidates[index],
          node: {
            ...cCandidates[index]?.node,
            interviews: [...currentInterviews]
          }
        };
      }

      setCandidates(cCandidates);
      if (setCurrentCandidates) {
        setCurrentCandidates(cCandidates);
      }
    }
  };

  const onSaveNoteButtonClicked = (candidate, latestNote) => event => {
    if (note === '') {
      WarningToast('Empty note is not allowed');
      return;
    }

    if (submittingNote) return;

    submittingNote = true;

    if (latestNote === '') {
      submitAddInterviewNote(candidate);
    } else {
      submitUpdateInterviewNote(candidate);
    }
  };

  const submitAddInterviewNote = candidate => {
    const application = candidate?.jobApplication
      ? candidate.jobApplication
      : candidate;

    if (application?.interviews?.length == 0) {
      submittingNote = false;
      return;
    }

    setLoading(true);

    let addNoteParams = {
      id: application.interviews[0].id,
      note: note
    };

    props.addInterviewNote(addNoteParams).then(res => {
      submittingNote = false;
      if (res.type === types.ADD_INTERVIEW_NOTE_SUCCEED) {
        setLoading(false);
        updateLocalNote(res.interview);
        SuccessToast('Added note successfully.');
      } else {
        setLoading(false);
      }

      setEditNote(false);
    });
  };

  const submitUpdateInterviewNote = candidate => {
    if (note === '') {
      WarningToast('Empty note is not allowed');
      return;
    }

    if (!candidate) return;

    const isInterview = candidate.jobApplication ? true : false;
    let noteId = '';

    if (isInterview) {
      if (!candidate.interviewNotes || candidate.interviewNotes.length == 0) {
        return;
      }

      // First note
      noteId = candidate.interviewNotes[0].id;
    } else {
      if (
        !candidate ||
        !candidate.interviews ||
        candidate.interviews.length == 0
      ) {
        submittingNote = false;
        return;
      }

      if (
        !candidate.interviews[0].interviewNotes ||
        candidate.interviews[0].interviewNotes.length == 0
      ) {
        submittingNote = false;
        return;
      }

      // First note of latest interview
      noteId = candidate.interviews[0].interviewNotes[0].id;
    }

    setLoading(true);

    let updateNoteParams = {
      id: noteId,
      note: note
    };

    props.updateInterviewNote(updateNoteParams).then(res => {
      submittingNote = false;
      if (res.type === types.UPDATE_INTERVIEW_NOTE_SUCCEED) {
        setLoading(false);
        updateLocalNote(res.interview);
        SuccessToast('Updated note successfully.');
      } else {
        if (res.message) {
          ErrorToast(res.message);
        }
        setLoading(false);
      }

      setEditNote(false);
    });
  };

  const submitAddApplicationNote = candidate => event => {
    setLoading(true);

    const application = candidate?.jobApplication
      ? candidate.jobApplication
      : candidate;
    let addNoteParams = {
      id: application.id,
      note: note
    };

    props.addApplicationNote(addNoteParams).then(res => {
      if (res.type === types.ADD_APPLICATION_NOTE_SUCCEED) {
        setLoading(false);
        updateLocalNote(res.jobApplication?.notes);
        SuccessToast('Added note successfully.');
      } else {
        setLoading(false);
      }

      setEditNote(false);
    });
  };

  const toggleEditInterview = () => {
    setEditInterview(!editInterview);
  };

  const handleDateChange = event => {
    const interviewInfo = {
      ...store.getState().applications.interviewInfo,
      date: moment(event.currentTarget.value).format()
    };

    props.updateInterviewInfo(interviewInfo);
  };

  const toggleDeleteInterviewModal = () => {
    setDeleteInterviewModal(!deleteInterviewModal);
  };

  const handleContactPerson = e => {
    const interviewInfo = {
      ...store.getState().applications.interviewInfo,
      contactPerson: e.target.value.replace(/[^a-zA-z\/@ '-]/g, '')
    };

    props.updateInterviewInfo(interviewInfo);
  };

  const handleTime = e => {
    const interviewInfo = {
      ...store.getState().applications.interviewInfo,
      startTime: e.target.value
    };

    props.updateInterviewInfo(interviewInfo);
  };

  const handleContactNumber = number => {
    if (number) {
      let interviewInfo;

      interviewInfo = {
        ...store.getState().applications.interviewInfo,
        contactNumber: number
      };
      props.updateInterviewInfo(interviewInfo);
    }
  };

  const handleMessageToJobseeker = e => {
    const length = e.target.value.length;
    if (length > 350) {
      e.target.value = e.target.value.substring(0, 350);
    }

    const interviewInfo = {
      ...store.getState().applications.interviewInfo,
      messageToJobseeker: e.target.value
    };

    props.updateInterviewInfo(interviewInfo);
  };

  const handleAddressChange = value => {
    const interviewInfo = {
      ...store.getState().applications.interviewInfo,
      venue: value
    };

    props.updateInterviewInfo(interviewInfo);
  };

  const onAddressBlur = () => {
    if (addressAutoCompleteRef?.current) {
      if (addressAutoCompleteRef.current.clearSuggestions) {
        addressAutoCompleteRef.current.clearSuggestions();
      }
    }
  };

  const onAddressFocus = () => {
    if (store.getState().applications.interviewInfo.address) {
      if (addressAutoCompleteRef?.current) {
        if (addressAutoCompleteRef.current.fetchPredictions) {
          addressAutoCompleteRef.current.fetchPredictions();
        }
      }
    }
  };

  const singleStatusMessage = () => {
    return (
      <div>
        <p
          style={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 'bold',
            margin: '4px 0'
          }}>
          Interview Updated
        </p>
        <p style={{ margin: '0', fontFamily: 'Inter', fontSize: '12px' }}>
          You have successfully updated the interview details and the applicant
          will be notified.
        </p>
      </div>
    );
  };

  const handleUpdatedInterviewInfo = async interviewInfo => {
    const checkFormat =
      moment(interviewInfo?.startTime, 'h:mm A').format('h:mm A') ===
      interviewInfo?.startTime;

    let updatedInterview;

    if (checkFormat) {
      updatedInterview = {
        ...store.getState().applications?.interviewInfo,
        startTime: interviewInfo?.startTime
      };
    } else {
      const formattedStartTime = moment('2015-01-01')
        .startOf('day')
        .seconds(interviewInfo.startTime)
        .format('h:mm A');

      updatedInterview = {
        ...store.getState().applications?.interviewInfo,
        startTime: formattedStartTime
      };
    }

    const updateStatus = await props.updateInterviewDetails(updatedInterview);

    if (updateStatus.type == 'UPDATE_INTERVIEW_DETAILS_SUCCEED') {
      if (!isAts) {
        const cCandidates = [...candidates];

        const index = cCandidates.findIndex(item => {
          return item?.node?.id == getCandidate(props)?.id;
        });
        if (index != -1) {
          cCandidates[index] = {
            ...cCandidates[index],
            node: {
              ...cCandidates[index].node,
              jobApplication: {
                ...cCandidates[index].node?.jobApplication,
                interviews: updateStatus?.interviews
              }
            }
          };

          setCandidates(cCandidates);
          setCurrentCandidates(cCandidates);
        }
      } else {
        let applications = props.allApplications;

        const index = applications.findIndex(item => {
          return item?.id == getCandidate(props)?.id;
        });

        if (index != -1) {
          applications[index] = {
            ...applications[index],
            jobApplication: {
              ...applications[index].jobApplication,
              interviews: updateStatus?.interviews
            }
          };

          dispatch(updateAllApplications(applications));
        }
      }

      InfoToast(singleStatusMessage());
    } else {
      ErrorToast('Failed to update interview details');
    }
    setEditInterview(false);
  };

  const getRecordedTime = () => {
    const checkFormat =
      moment(props.interviewInfo?.startTime, 'h:mm A').format('h:mm A') ===
      props.interviewInfo?.startTime;

    if (checkFormat) {
      return props.interviewInfo?.startTime;
    } else {
      return moment('2015-01-01')
        .startOf('day')
        .seconds(props.interviewInfo?.startTime)
        .format('h:mm A');
    }
  };

  const getIsExpired = candidate => {
    const application = candidate?.jobApplication
      ? candidate?.jobApplication
      : candidate;

    if (application?.interviews?.length > 0) {
      return application?.interviews[0]?.isExpired;
    }
  };

  const disabledSaveButtonValidation = interviewInfo => {
    if (interviewInfo?.contactPerson == '') {
      return true;
    } else if (
      interviewInfo?.contactNumber == '' ||
      !isValidPhoneNumber(interviewInfo?.contactNumber)
    ) {
      return true;
    } else if (interviewInfo?.date == '') {
      return true;
    } else if (interviewInfo?.venue == '') {
      return true;
    } else if (interviewInfo?.startTime == 'Select a time') {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (getCandidate(props)) {
      let application = getCandidate(props)?.jobApplication
        ? getCandidate(props).jobApplication
        : getCandidate(props);

      if (application?.interviews?.length) {
        props.updateInterviewInfo(application?.interviews[0]);
      }
    }
  }, [getCandidate(props)]);

  useEffect(() => {
    setNote(getNote(getCandidate(props)));

    try {
      let cAccountInfo = localStorage.getItem('accountInfo');
      let cAaccountType = localStorage.getItem('currentAccountType');

      setAccountType(cAaccountType);

      if (cAccountInfo) {
        cAccountInfo = JSON.parse(cAccountInfo);
        setAccountInfo(cAccountInfo);
      }
    } catch (error) {
      //
    }
  }, []);

  useEffect(() => {
    if (candidateInfo?.interviewNotes?.length > 0) {
      setNote(candidateInfo.interviewNotes[0].note);
    }
  }, [candidateInfo]);

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={open}>
          <Box sx={style}>
            <CloseIconContainer>
              <CloseIcon
                onClick={handleModalClose}
                style={{ color: '#222', fontSize: '22px' }}
              />
            </CloseIconContainer>

            <div>
              <StyledTabs
                value={value}
                onChange={handleChange}
                aria-label="tabs">
                <StyledTab
                  label={`Interview ${getInterviewLength(getCandidate(props))}`}
                />
                {allowNextInterview(getCandidate(props)) &&
                  getIsExpired(getCandidate(props)) && (
                    <StyledTab
                      label="Schedule Next Interview"
                      icon={
                        <FontAwesomeIconStyled
                          icon={faPlusCircle}
                          style={{ margin: '0 10px', fontSize: '16px' }}
                        />
                      }
                    />
                  )}
              </StyledTabs>
              {allowNextInterview(getCandidate(props)) &&
                getIsExpired(getCandidate(props)) && <TabIndicator />}

              <TabPanel value={value} index={0}>
                <div
                  style={
                    getIsExpired(getCandidate(props)) || editInterview
                      ? { padding: '20px 0' }
                      : {
                          padding: '20px 0',
                          maxHeight: '500px',
                          overflow: 'auto'
                        }
                  }>
                  <ContentContainer
                    type="two-column"
                    edit={editInterview && 'true'}>
                    <IconContainer type="basic">
                      <FontAwesomeIconStyled
                        icon={faCalendarPlus}
                        awesome_icon={'faCalendarPlus'}
                      />
                    </IconContainer>
                    {editInterview ? (
                      <TextInputFunction
                        type="date"
                        label={'date'}
                        value={moment(props.interviewInfo?.date).format(
                          'YYYY-MM-DD'
                        )}
                        minDate={today}
                        handleChange={handleDateChange}
                      />
                    ) : (
                      <Text type="basic">
                        {getInterviewDate(getCandidate(props))}
                      </Text>
                    )}
                  </ContentContainer>

                  <ContentContainer
                    type="two-column"
                    edit={editInterview && 'true'}>
                    <IconContainer type="basic">
                      <FontAwesomeIconStyled
                        icon={faClock}
                        awesome_icon={'faClock'}
                      />
                    </IconContainer>
                    {editInterview ? (
                      <>
                        <TimeSelect
                          recordedTime={getRecordedTime()}
                          onChange={handleTime}
                        />
                      </>
                    ) : (
                      <Text type="basic">
                        {getInterviewTime(getCandidate(props))}
                      </Text>
                    )}
                  </ContentContainer>

                  <ContentContainer
                    type="two-column"
                    edit={editInterview && 'true'}>
                    <IconContainer type="basic">
                      <FontAwesomeIconStyled
                        icon={faMapMarkerAlt}
                        awesome_icon={'faMapMarkerAlt'}
                      />
                    </IconContainer>
                    {editInterview ? (
                      <PlacesAutocompleteComponent
                        label={'location'}
                        addressAutoCompleteRef={addressAutoCompleteRef}
                        address={props.interviewInfo?.venue}
                        handleAddressChange={handleAddressChange}
                        onAddressBlur={onAddressBlur}
                        onAddressFocus={onAddressFocus}
                      />
                    ) : (
                      <Text type="basic">
                        {getInterviewVenue(getCandidate(props))}
                      </Text>
                    )}
                  </ContentContainer>

                  <ContentContainer
                    type="two-column"
                    edit={editInterview && 'true'}>
                    <IconContainer type="basic">
                      <FontAwesomeIconStyled
                        icon={faUserAlt}
                        awesome_icon={'faUserAlt'}
                      />
                    </IconContainer>
                    {editInterview ? (
                      <TextInputFunction
                        label="contactPerson"
                        type={'text'}
                        handleChange={handleContactPerson}
                        value={props.interviewInfo?.contactPerson}
                      />
                    ) : (
                      <Text type="basic">
                        {getContactPerson(getCandidate(props))}
                      </Text>
                    )}
                  </ContentContainer>

                  <ContentContainer
                    type="two-column"
                    edit={editInterview && 'true'}>
                    <IconContainer type="basic">
                      <FontAwesomeIconStyled
                        icon={faPhoneAlt}
                        awesome_icon={'faPhoneAlt'}
                      />
                    </IconContainer>
                    {editInterview ? (
                      // <TextInputFunction
                      //   label="mobileNumber"
                      //   type={'number'}
                      //   value={props.interviewInfo.contactNumber}
                      //   handleChange={handleContactNumber}
                      // />
                      <SpecialInput
                        mobileNumber={props.interviewInfo?.contactNumber}
                        handlePhoneNumber={handleContactNumber}
                      />
                    ) : (
                      <Text type="basic">
                        {getContactNumber(getCandidate(props))}
                      </Text>
                    )}
                  </ContentContainer>

                  <ContentContainer
                    type="two-column"
                    edit={editInterview && 'true'}>
                    <IconContainer type="fa-bars">
                      <FontAwesomeIconStyled
                        icon={faBars}
                        awesome_icon={'faBars'}
                      />
                    </IconContainer>
                    {editInterview ? (
                      <TextArea
                        value={props.interviewInfo?.messageToJobseeker}
                        handleChange={handleMessageToJobseeker}
                        placeholder={`(Optional) Message to candidate. \n\n(e.g. please bring along your relevant work samples and portfolio)`}
                      />
                    ) : (
                      <Text type="basic">
                        {getMessageToJobseeker(getCandidate(props))}
                      </Text>
                    )}
                  </ContentContainer>

                  {editInterview ? null : (
                    <>
                      <Divider />

                      {getNote(getCandidate(props)) === '' ? (
                        <ContentContainer
                          type="two-column-note"
                          onClick={handleEditNote}>
                          <IconContainer type="fa-plus-circle">
                            <FontAwesomeIconStyled
                              icon={faPlusCircle}
                              awesome_icon={'faPlusCircle'}
                            />
                          </IconContainer>
                          <div>
                            <Text type="add_note">Add Note</Text>
                            <Text type="note_remark">
                              Remarks on candidate/interview
                            </Text>
                          </div>
                        </ContentContainer>
                      ) : (
                        allowAddOrEditNote(getCandidate(props)) && (
                          <ContentContainer
                            type="two-column-note"
                            onClick={handleEditNote}>
                            <IconContainer type="fa-plus-circle">
                              <FontAwesomeIconStyled
                                icon={faPlusCircle}
                                awesome_icon={'faPlusCircle'}
                              />
                            </IconContainer>
                            <Text type="add_note">Edit Note</Text>
                          </ContentContainer>
                        )
                      )}

                      {!editNote && getNote(getCandidate(props)).length !== 0 && (
                        <div style={{ display: 'flex', margin: '15px 0 0' }}>
                          <div style={{ marginRight: '10px' }}>
                            {/* <ProfilePicture alt="avatar">
                          {accInfo?.name.charAt(0)}
                        </ProfilePicture> */}
                          </div>
                          <div>
                            {/* <CommentContainer>
                          <div>
                            <Text type="person">{accInfo.name} &nbsp;</Text>
                          </div>
                          <div>
                            <Text type="date">
                              {moment().format('DD MMM YYYY')}
                            </Text>
                          </div>
                          <Dot />
                          <Text type="timestamp">
                            {moment().format('h:mm a')}
                          </Text>
                        </CommentContainer> */}
                            <Text type="display_note">
                              {getNote(getCandidate(props))}
                            </Text>
                          </div>
                        </div>
                      )}

                      {editNote && (
                        <div>
                          <div style={{ display: 'flex', marginTop: '15px' }}>
                            <div style={{ marginRight: '10px' }}>
                              {/* <ProfilePicture alt="avatar">
                            {accInfo?.name.charAt(0)}
                          </ProfilePicture> */}
                            </div>
                            <TextFieldStyled
                              id="filled-multiline-flexible"
                              placeholder={`This candidate speaks fluent English.`}
                              multiline
                              rowsMax={4}
                              value={note}
                              type={'text'}
                              onChange={noteValueChange}
                              variant="filled"
                              inputProps={{
                                style: {
                                  borderRadius: '8px',
                                  width: '100%',
                                  height: '87px',
                                  overflow: 'auto',
                                  transform: 'translateY(-10px)'
                                }
                              }}
                              InputProps={{
                                style: {
                                  fontSize: '14px',
                                  letterSpacing: '0.03rem',
                                  borderRadius: '8px',
                                  width: '100%',
                                  height: '107px',
                                  background: 'transparent'
                                },
                                disableUnderline: true
                              }}
                            />
                          </div>
                          <ButtonContainer type="save">
                            <div
                              style={{ padding: '0 10px 0 0' }}
                              onClick={handleEditNote}>
                              <Button color="PURPLETEXT" size="SMALL">
                                Cancel
                              </Button>
                            </div>
                            <div>
                              <Button
                                color="PURPLE"
                                size="SMALL"
                                onClick={onSaveNoteButtonClicked(
                                  getCandidate(props),
                                  getNote(getCandidate(props))
                                )}>
                                {loading ? (
                                  <CircularProgress
                                    style={{
                                      width: '20px',
                                      height: '20px',
                                      color: '#fff'
                                    }}
                                  />
                                ) : (
                                  'Save'
                                )}
                              </Button>
                            </div>
                          </ButtonContainer>
                        </div>
                      )}
                    </>
                  )}

                  {editInterview && (
                    <ButtonContainer type="schedule_interview">
                      <ButtonStyled
                        type={'basic'}
                        onClick={() =>
                          handleUpdatedInterviewInfo(props.interviewInfo)
                        }>
                        {props.updatingInterviewDetails ? (
                          <Spinner size="XS" color="white" noPadding={true} />
                        ) : (
                          'Save'
                        )}
                      </ButtonStyled>
                      <ButtonStyled
                        type="outline"
                        onClick={toggleEditInterview}>
                        Cancel
                      </ButtonStyled>
                    </ButtonContainer>
                  )}

                  {allowNextInterview(getCandidate(props)) ? (
                    !getIsExpired(getCandidate(props)) ? (
                      editInterview ? (
                        <ButtonContainer type="schedule_interview">
                          <ButtonStyled
                            disabled={disabledSaveButtonValidation(
                              props.interviewInfo
                            )}
                            type={
                              disabledSaveButtonValidation(props.interviewInfo)
                                ? 'disabled'
                                : 'basic'
                            }
                            onClick={() =>
                              handleUpdatedInterviewInfo(props.interviewInfo)
                            }>
                            {props.updatingInterviewDetails ? (
                              <Spinner
                                size="XS"
                                color="white"
                                noPadding={true}
                              />
                            ) : (
                              'Save'
                            )}
                          </ButtonStyled>
                          <ButtonStyled
                            type="outline"
                            onClick={toggleEditInterview}>
                            Cancel
                          </ButtonStyled>
                        </ButtonContainer>
                      ) : (
                        <ButtonContainer type="schedule_interview">
                          <ButtonStyled
                            type="basic"
                            onClick={toggleEditInterview}>
                            Edit Interview Details
                          </ButtonStyled>
                          <ButtonStyled
                            type="outline"
                            onClick={toggleDeleteInterviewModal}>
                            Delete Interview
                          </ButtonStyled>
                        </ButtonContainer>
                      )
                    ) : (
                      <ButtonContainer type="schedule_interview">
                        <ButtonStyled type="basic" onClick={() => setValue(1)}>
                          Schedule Next Interview
                        </ButtonStyled>
                      </ButtonContainer>
                    )
                  ) : null}
                  <DeleteInterviewPopUp
                    open={deleteInterviewModal}
                    handleClose={toggleDeleteInterviewModal}
                    getCandidateProps={getCandidate(props)}
                    candidates={candidates}
                    setCandidates={setCandidates}
                    setCurrentCandidates={setCurrentCandidates}
                    isAts={isAts}
                    loadingShortlistedApplications={
                      loadingShortlistedApplications
                    }
                    loadingInterviews={loadingInterviews}
                    jobData={jobData}
                    setJobData={setJobData}
                  />
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div style={{ padding: '20px 0 0' }}>
                  <SharedContent
                    isNextInterviewModalOpened={props.open}
                    isAts={isAts}
                    candidateInfo={candidateInfo}
                    candidates={candidates}
                    setCandidates={setCandidates}
                    setCurrentCandidates={setCurrentCandidates}
                    handleModalClose={handleModalClose}
                  />
                </div>
              </TabPanel>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    currentCandidateIndex: state.applications.currentCandidateIndex,
    allApplications: state.applications.allApplications,
    interviewInfo: state.applications.interviewInfo,
    updatingInterviewDetails: state.applications.updatingInterviewDetails,
    interviewData: state.applications.interviewData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addApplicationNote: bindActionCreators(addApplicationNote, dispatch),
    addInterviewNote: bindActionCreators(addInterviewNote, dispatch),
    updateInterviewNote: bindActionCreators(updateInterviewNote, dispatch),
    updateFirstScheduleInterviewModalStatus: bindActionCreators(
      updateFirstScheduleInterviewModalStatus,
      dispatch
    ),
    updateNextScheduleInterviewModalStatus: bindActionCreators(
      updateNextScheduleInterviewModalStatus,
      dispatch
    ),
    updateInterviewInfo: bindActionCreators(updateInterviewInfo, dispatch),
    updateInterviewDetails: bindActionCreators(updateInterviewDetails, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(ListScheduleInterview);
