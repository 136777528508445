import moment from 'moment';
import { decode } from 'html-entities';

export const convertArrayToProperString = infos => {
  if (!infos) return;

  let convertedValue = '[';

  infos.map(info => {
    convertedValue += '{';

    Object.keys(info).map((key, index) => {
      const value = info[key];

      if (index > 0) {
        convertedValue += ',';
      }

      convertedValue += `${key} : `;

      if (typeof value === 'string') {
        convertedValue += `"${value}"`;
      } else {
        convertedValue += `${value}`;
      }
    });

    convertedValue += '}';
  });

  convertedValue += ']';

  return convertedValue;
};

export const sortWorkingExperiences = workingExperiences => {
  let cWorkingExperiences = [...workingExperiences];

  cWorkingExperiences.sort((a, b) => {
    const startDateYearA = a.startDateYear || a.start_date_year;
    const startDateYearB = b.startDateYear || b.start_date_year;
    const startDateMonthA = a.startDateMonth || a.start_date_month;
    const startDateMonthB = b.startDateMonth || b.state_date_month;
    const endDateYearA = a.endDateYear || a.end_date_year;
    const endDateYearB = b.endDateYear || b.end_date_year;
    const endDateMonthA = a.endDateMonth || a.end_date_month;
    const endDateMonthB = b.endDateMonth || b.end_date_month;

    const startValueA =
      (startDateYearA ?? 0) + (startDateMonthA ? startDateMonthA / 100 : 0);
    const startValueB =
      (startDateYearB ?? 0) + (startDateMonthB ? startDateMonthB / 100 : 0);
    const endValueA =
      (endDateYearA ?? 0) + (endDateMonthA ? endDateMonthA / 100 : 0);
    const endValueB =
      (endDateYearB ?? 0) + (endDateMonthB ? endDateMonthB / 100 : 0);

    const totalValueA =
      startValueA > 0 && endValueA === 0 ? 3000 + startValueA : endValueA;
    const totalValueB =
      startValueB > 0 && endValueB === 0 ? 3000 + startValueB : endValueB;

    if (totalValueB > totalValueA) {
      return 1;
    } else if (totalValueA > totalValueB) {
      return -1;
    }

    // both value A and B having same value then arrange based on createdAt
    if (moment(b.createdAt).isAfter(moment(a.createdAt))) {
      return 1;
    } else if (moment(b.createdAt).isBefore(moment(a.createdAt))) {
      return -1;
    }

    return 0;
  });

  return cWorkingExperiences;
};

export const formBookmarkedTalentSearchParams = filters => {
  return {};
};

export const convertHexAndEntitiesToString = item => {
  const regexHex = /&#x([0-9a-fA-F]+);/g;
  const regexEntity = /&([a-zA-Z]+|#x[0-9a-fA-F]+);/g;

  return item
    .replace(regexHex, hexValue => String.fromCharCode(parseInt(hexValue, 16)))
    .replace(regexEntity, entity => decode(entity));
};

export const resumeConversion = resume => {
  const byteCharacters = Buffer.from(resume, 'base64').toString('binary');

  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArray], { type: 'application/pdf' });

  return blob;
};

export const appendBulletPointAndBreakLines = description => {
  if (description?.length > 0) {
    const lines = description.split(/[\n]/);

    for (let i = 0; i < lines.length; i++) {
      const words = lines[i].trim().split(' ');
      const firstWord = words[0];

      if (/^[a-zA-Z]+$/.test(firstWord)) {
        lines[i] = <li>{lines[i].trim()}</li>;
      } else if (/^[•a-zA-Z]+$/.test(firstWord)) {
        lines[i] = <li>{lines[i].replace(/^•/, '').trim()}</li>;
      }
    }

    return lines;
  }
};
