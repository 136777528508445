import { useLocation, useNavigate } from 'react-router-dom';

const useUpdateQueryParam = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const updateQueryParam = (key, value) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value);
    navigate({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  };

  return updateQueryParam;
};

export default useUpdateQueryParam;
