import React from 'react';
import { Button, PopUpDialog, Spinner } from '../../../../components';
import styles from './index.module.scss';
import { ErrorToast, SuccessToast } from '../../../../utils/ToastUtils';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  changeJobStatus,
  fetchJobs,
  updateAllJobs,
  updateJobsCurrentPage,
  updateTotalJobsCount,
  viewPackageSubscriptionDetails,
  resetAvailableResources,
  updateSelectedFilterValue,
  updateSelectedFilter,
} from '../../../../redux/actions/job_action';
import store from '../../../../redux/stores/store';
import { mutateCart } from '../../../../redux/actions/order_action';


const ActivateJobPopUp = ({
  open,
  handleClose,
  jobId,
  jobData,
  productData,
  setSwitchStatus,
  getCompanyAccountInfo
}) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.jobs.changingJobStatus);
  const allJobs = store.getState().jobs.jobs;
  const jobsCurrentPage = useSelector(state => state.jobs.jobsCurrentPage);

  const onActivateJobStatus = async () => {
    const totalInactiveJobsCount =
          store.getState().jobs.totalJobsCount['inactive'];
    const searchJobValue = store.getState().jobs.searchJobValue;
    const selectedFilter = store.getState().jobs.selectedFilter;
    const selectedFilterValue = store.getState().jobs.selectedFilterValue;

    try {
      const params = {
        active: true,
        jobId: jobId,
        archiveChat: false,
        deactivateReason: '',
        otherReason: ''
      };
      dispatch(changeJobStatus(params)).then(res => {
        if (res.type == 'CHANGE_JOB_STATUS_SUCCEED') {
          const updatedCount = totalInactiveJobsCount - 1;
          const numOfJobsToCurrentPage = (jobsCurrentPage - 1) * 5;
          const nextCurrentPage =
            updatedCount > numOfJobsToCurrentPage
              ? jobsCurrentPage - 1
              : jobsCurrentPage;

          dispatch(updateJobsCurrentPage(nextCurrentPage));

          // const offset = nextCurrentPage > 1 ? (nextCurrentPage - 1) * 5 : 0;
          const offset = 0;

          const params = {
            first: 5,
            offset: offset,
            getCount: true,
            jobTitle: searchJobValue,
            isActive: true,
            ...{
              orders: null
            }
          };

          dispatch(fetchJobs(params));

          dispatch(resetAvailableResources()).then(() => {
            // dispatch(updateSelectedFilter(true));
            dispatch(viewPackageSubscriptionDetails());
            dispatch(updateSelectedFilterValue('active'));
          });

          if (getCompanyAccountInfo) {
            dispatch(getCompanyAccountInfo());
          }
          SuccessToast('Job has been activated!');
        } else {
          ErrorToast('Failed to update job!');
        }
        handleClose();
      });
    } catch (err) {
      ErrorToast(err.message);
    }
  };

  const getJobType = () => {
    if (
      jobData?.creationOrigin?.includes('free_internship') ||
      jobData?.jobTypeTitle == 'Internship'
    ) {
      return 'free_internship';
    } else if (jobData?.creationOrigin?.includes('paid_internship')) {
      return 'paid_internship';
    } else if (jobData?.creationOrigin?.includes('job_slot')) {
      return 'job_slot';
    } else if (jobData?.creationOrigin?.includes('job_slot_plus')) {
      return 'job_slot_plus';
    } else if (jobData?.creationOrigin?.includes('job_slot_max')) {
      return 'job_slot_max';
    } else if (jobData?.creationOrigin?.includes('job_token')) {
      return 'job_token';
    } else if (jobData?.creationOrigin?.includes('free_super_senior')) {
      return 'free_super_senior';
    } else if (jobData?.creationOrigin?.includes('unlimited')) {
      return 'unlimited';
    }

    return 'normal_job';
  };

  const getTitle = () => {
    const jobType = getJobType();

    if (productData?.unlimitedToken == true) {
      if (jobType == 'free_internship' || jobType == 'paid_internship') {
        return 'Activate Internship Job?';
      } else if (jobType == 'free_super_senior') {
        return 'Activate Senior Job?';
      } else {
        return 'Activate Job?';
      }
    }

    switch (jobType) {
      case 'free_internship':
      case 'paid_internship':
        {
          if (
            productData?.allowToPostFreeInternshipJob == false &&
            productData?.unlimitedToken == false
          ) {
            if (
              productData?.jobSlotsTotalUsableQuantity > 0 ||
              productData?.jobToken > 0
            ) {
              return 'No Internship Tokens Available';
            }
          } else if (
            productData?.allowToPostFreeInternshipJob == true
          ) {
            return 'Activate Internship Job?';
          }
        }
        break;
      case 'free_super_senior':
        {
          if (
            productData?.allowToPostSuperSeniorJob == false &&
            productData?.unlimitedToken == false
          ) {
            if (
              productData?.jobSlotsTotalUsableQuantity > 0 ||
              productData?.jobToken > 0
            ) {
              return 'No Free Senior Post Tokens Available';
            }
          } else if (productData?.allowToPostSuperSeniorJob == true) {
            return 'Activate Senior Job?';
          }
        }
        break;
    }

    if (
      productData?.unlimitedToken == false &&
      productData?.jobSlotsTotalUsableQuantity == 0 &&
      productData?.jobToken == 0 &&
      productData?.availableJobCreditCount == 0
    ) {
      // Safe to assume the employer no longer has any product to activate this job?
      // To be tested
      return 'No products available';
    }

    return 'Activate Job?';
  };

  const getMessage = () => {
    if (productData?.unlimitedToken == true) {
      return 'You will activate this job with Unlimited Job Token.';
    }

    switch (getJobType()) {
      case 'free_internship':
      case 'paid_internship':
        {
          if (
            productData?.allowToPostFreeInternshipJob == false &&
            productData?.unlimitedToken == false
          ) {
            if (productData?.jobSlotsTotalUsableQuantity > 0) {
              return 'Would you like to activate with a Job Slot instead?';
            } else if (productData?.availableJobCreditCount > 0) {
              return `Would you like to activate with a Job Credit instead?`;
            } else if (productData?.jobToken > 0) {
              return 'Would you like to activate with a Job Token instead?';
            }
          } else if (productData?.availableInternshipsTokenCount > 0) {
            return `Activating this internship job post will use up an unused Internship Token. You have ${productData.availableInternshipsTokenCount} unused Internship Token left, do you want to proceed?`;
          }
        }
        break;
      case 'free_super_senior':
        {
          if (
            productData?.allowToPostSuperSeniorJob == false &&
            productData?.unlimitedToken == false
          ) {
            if (productData?.jobSlotsTotalUsableQuantity > 0) {
              return 'Would you like to activate with a Job Slot instead?';
            } else if (productData?.availableJobCreditCount > 0) {
              return `Would you like to activate with a Job Credit instead?`;
            } else if (productData?.jobToken > 0) {
              return 'Would you like to activate with a Job Token instead?';
            }
          } else if (productData?.availableFreeSuperSeniorPostCount > 0) {
            return `Activating this senior job post will use up an unused Free Senior Post. You have ${productData.availableFreeSuperSeniorPostCount} unused Free Senior Post left, do you want to proceed?`;
          }
        }
        break;
    }

    if (
      productData?.unlimitedToken == false &&
      productData?.jobSlotsTotalUsableQuantity == 0 &&
      productData?.jobToken == 0 &&
      productData?.availableJobCreditCount == 0
    ) {
      // Safe to assume the employer no longer has any product to activate this job?
      // To be tested
      return 'Refine your recruitment journey by purchasing Job Slots!';
    }

    if (productData?.jobSlotsTotalUsableQuantity > 0) {
      return `Activating a job post will use up an unused Job Slot. You have ${productData?.jobSlotsTotalUsableQuantity} unused Job Slot left, do you want to proceed?`;
    } else if (productData?.availableJobCreditCount > 0) {
      return `Activating a job post will use up an unused Job Credit. You have ${productData?.availableJobCreditCount} unused Job Credit left, do you want to proceed`;
    } else if (productData?.jobToken > 0) {
      return `Activating a job post will use up an unused Job Token. You have ${productData?.jobToken} unused Job Token left, do you want to proceed?`;
    }

    return `-`;
  };

  const onPurchaseButtonClicked = async () => {
    await dispatch(
      mutateCart({
        input: {
          productId: '364',
          quantity: 1
        }
      })
    );
    history(`/purchase/choose`);
  };

  const showPurchaseButton = () => {
    if (
      getMessage() == 'Refine your recruitment journey by purchasing Job Slots!'
    ) {
      return true;
    }

    return false;
  };

  const renderPurchaseButton = () => {
    return (
      <div className={styles.purchaseButtonContainer}>
        <Button color="PURPLE" size="AUTO" onClick={onPurchaseButtonClicked}>
          Purchase Job Slots
        </Button>
      </div>
    );
  };

  // useEffect(() => {
  //   if (open) {
  //     getAccountStatus();
  //   }
  // }, [open]);

  return (
    <PopUpDialog open={open} handleClose={handleClose} title={getTitle()}>
      <div style={{ padding: '20px 20px' }}>
        <div className={styles.contentContainer}>
          <p className={styles.activateJobDescription}>{getMessage()}</p>
        </div>

        {loading ? (
          <div style={{ height: 97 }}>
            <Spinner />
          </div>
        ) : !showPurchaseButton() ? (
          <div className={styles.buttonContainer}>
            <Button color="TRANSPARENT" size="BIG" onClick={handleClose}>
              Cancel
            </Button>
            <Button color="PURPLE" size="BIG" onClick={onActivateJobStatus}>
              Activate
            </Button>
          </div>
        ) : (
          renderPurchaseButton()
        )}
      </div>
    </PopUpDialog>
  );
};

export default ActivateJobPopUp;
