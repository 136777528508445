import React from 'react';
import JobSlotsAnimation from '../components/JobSlotsAnimation';
import {
  BoosterBundles,
  JobCreditBundles,
  JobSlotBundles,
  RefresherBundles,
  TalentTokenBundles
} from '../components/Bundles';
import CheckList from '../components/CheckList';
import refresherTokenExplanation from '../assets/images/products/refresher_token_explanation.gif';
import featuredTokenExplanation from '../assets/images/products/featured_token_explanation.gif';
import spotlightTokenExplanation from '../assets/images/products/spotlight_token.gif';
import keywordHighlightExplanation from '../assets/images/products/keyword_highlight.gif';
import { HeightSpacer } from '../components/Spacer/styles';
import jobCreditExplanation from '../assets/images/products/job_credit_explanation.gif';
import talentTokenExplanation from '../assets/images/products/talent_token_explanation.gif';
import { ContentText, DisclamierContainer } from './styles';

const coinsDialogContent = (
  <>
    <h5 style={{ fontSize: '24px' }}>What are Talent Tokens?</h5>
    <p>Unlock job seeker’s resumes with a Talent Token.</p>
    <div style={{ margin: '12px 0 22px' }}>
      <img
        alt="talent-token-explanation"
        style={{ width: '100%' }}
        src={talentTokenExplanation}
      />
    </div>

    <HeightSpacer height={'12px'} />

    <span style={{ fontFamily: 'Inter', fontSize: '16px' }}>
      What is Talent Search great for?
    </span>

    <HeightSpacer height={'15px'} />

    <CheckList
      list={[
        'Streamline your candidate search using advanced filters that highlight relevant skills and specialisations.',
        'Boost efficiency with a bookmarking feature that lets you save resumes for later review.',
        'Leave comments and chat with potential hires right on their profiles once their resumes are unlocked.'
      ]}
    />

    <HeightSpacer height={'22px'} />

    <h6 style={{ fontSize: '20px' }}>
      <strong>Bundles:</strong>
    </h6>

    <HeightSpacer height={'20px'} />

    <TalentTokenBundles />

    <HeightSpacer height={'24px'} />

    <DisclamierContainer>
      <p style={{ fontSize: '14px' }}>
        Bundle discounts will be automatically applied based on how many tokens
        you added to your shopping cart.
      </p>
      <p style={{ fontSize: '14px' }}>
        Unused tokens will expire 1 year after purchase.
      </p>
    </DisclamierContainer>
  </>
);

export const hardcodedProductsMap = {
  job_slot: {
    productCode: 'job_slot',
    description:
      'Job Slots allow you to post, replace and re-post jobs as much as you want during your subscription period.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Job Slots?</h5>
        <ContentText>
          Job Slots allow you to post, replace and re-post jobs as much as you
          want during your subscription period.
        </ContentText>
        <ContentText>
          Think of them like reserved parking spots for your jobs!
        </ContentText>

        <HeightSpacer height={'12px'} />

        <JobSlotsAnimation size="small" />

        <HeightSpacer height={'22px'} />

        <p>
          <strong>Why are Job Slots more expensive than normal ads?</strong>
        </p>
        <p>
          Actually, it’s much cheaper! With most job portals, the price you pay
          is to advertise 1 job for 1 month.
        </p>
        <p>
          With Hiredly’s Job Slots, the price you pay is for 6-12 months,
          depending on the plan you choose.
        </p>
        <p>
          Within your subscription period, you’re allowed to flexibly change the
          job you choose to advertise in your Job Slot, any time, for any job.
        </p>
        <p>
          Assuming you change job postings in your Job Slot once a month, you’re
          essentially advertising jobs for <strong>only RM300 a month!</strong>
        </p>

        <HeightSpacer height={'16px'} />

        <h6 style={{ fontSize: '20px' }}>
          <strong>Bundles:</strong>
        </h6>

        <HeightSpacer height={'24px'} />

        <JobSlotBundles />

        <HeightSpacer height={'24px'} />
      </>
    )
  },

  job_slot_plus: {
    productCode: 'job_slot_plus',
    description:
      'Job Slot Plus allow you to post, replace and re-post jobs as much as you want during your subscription period.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Job Slot Plus?</h5>
        <ContentText>
        A Job Slot Plus comes with the features of a classic Job Slot where you can post, replace and re-post as much as you want during your subscription period, with enhanced upgrades!
        </ContentText>

        <HeightSpacer height={'16px'} />

        <img
          alt="explanation"
          style={{ width: '100%' }}
          src={jobCreditExplanation}
        />

        <HeightSpacer height={'16px'} />

        <ContentText>When you post a job with our Job Slot Plus, your job ad will enjoy the following:</ContentText>

        <HeightSpacer height={'16px'} />

        <CheckList
          list={[
            '1.5X job ad performance',
            'Prioritised and refreshed listing every 14 days',
            'Eye-catching spotlight effect'
          ]}
        />
      </>
    )
  },

  job_slot_max: {
    productCode: 'job_slot_max',
    description:
      'Job Slot Max allow you to post, replace and re-post jobs as much as you want during your subscription period.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Job Slot Max?</h5>
        <ContentText>
        A Job Slot Max comes with the features of a classic Job Slot where you can post, replace and re-post as much as you want during your subscription period, with exclusive upgrades!
        </ContentText>
        
        <HeightSpacer height={'16px'} />

        <img
          alt="explanation"
          style={{ width: '100%' }}
          src={jobCreditExplanation}
        />


        <HeightSpacer height={'16px'} />

        <ContentText>When you post a job with our Job Slot Max, your job ad will enjoy the following:</ContentText>

        <HeightSpacer height={'16px'} />

        <CheckList
          list={[
            '2.2x job ad performance',
            'Prioritised and refreshed listing every 7 days',
            'Eye-catching spotlight and key highlights effect',
            'Exclusive job ad design',
            'Dedicated customer support'
          ]}
        />

      </>
    )
  },

  job_slot_plus: {
    productCode: 'job_slot_plus',
    description:
      'Job Slot Plus allow you to post, replace and re-post jobs as much as you want during your subscription period.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Job Slot Plus?</h5>
        <ContentText>
        A Job Slot Plus comes with the features of a classic Job Slot where you can post, replace and re-post as much as you want during your subscription period, with enhanced upgrades!
        </ContentText>

        <HeightSpacer height={'16px'} />

        <img
          alt="explanation"
          style={{ width: '100%' }}
          src={jobCreditExplanation}
        />

        <HeightSpacer height={'16px'} />

        <ContentText>When you post a job with our Job Slot Plus, your job ad will enjoy the following:</ContentText>

        <HeightSpacer height={'16px'} />

        <CheckList
          list={[
            '1.5X job ad performance',
            'Prioritised and refreshed listing every 14 days',
            'Eye-catching spotlight effect'
          ]}
        />
      </>
    )
  },

  job_slot_max: {
    productCode: 'job_slot_max',
    description:
      'Job Slot Max allow you to post, replace and re-post jobs as much as you want during your subscription period.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Job Slot Max?</h5>
        <ContentText>
        A Job Slot Max comes with the features of a classic Job Slot where you can post, replace and re-post as much as you want during your subscription period, with exclusive upgrades!
        </ContentText>
        
        <HeightSpacer height={'16px'} />

        <img
          alt="explanation"
          style={{ width: '100%' }}
          src={jobCreditExplanation}
        />


        <HeightSpacer height={'16px'} />

        <ContentText>When you post a job with our Job Slot Max, your job ad will enjoy the following:</ContentText>

        <HeightSpacer height={'16px'} />

        <CheckList
          list={[
            '2.2x job ad performance',
            'Prioritised and refreshed listing every 7 days',
            'Eye-catching spotlight and key highlights effect',
            'Exclusive job ad design',
            'Dedicated customer support'
          ]}
        />

      </>
    )
  },

  limited_edition_package: {
    productCode: 'limited_edition_package',
    description:
      'Bundle deal with 1 job slot and full access to our visibility boosters. Available till 31st December 2023 only.',
    dialogContent: null
  },

  booster_token: {
    productCode: 'booster_token',
    description:
      'Once a Featured Token is applied to a job, it will appear first on the featured jobs list and mobile app for 7 days.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Featured Tokens?</h5>
        <ContentText>
          Applying a Featured Token to a job gives it a 7-day boost for
          increased job visibility and job applications!
        </ContentText>

        <HeightSpacer height={'16px'} />

        <img
          alt="explanation"
          style={{ alignSelf: 'center', maxHeight: '420px' }}
          src={featuredTokenExplanation}
        />

        <HeightSpacer height={'16px'} />

        <ContentText>A Featured Job will:</ContentText>

        <HeightSpacer height={'16px'} />

        <CheckList
          list={[
            'Appear at the top of job listings on our website.',
            'Appear first on our mobile app.',
            'Appear in weekly email blasts to relevant job seekers'
          ]}
        />

        <HeightSpacer height={'16px'} />

        <h6 style={{ fontSize: '20px' }}>
          <strong>Bundles:</strong>
        </h6>

        <HeightSpacer height={'24px'} />

        <BoosterBundles />

        <HeightSpacer height={'24px'} />

        <DisclamierContainer>
          <ContentText $disclamier>
            Bundle discounts will be automatically applied based on how many
            tokens you added to your shopping cart.
          </ContentText>
          <HeightSpacer height={'24px'} />
          <ContentText $disclamier>
            Unused tokens will expire 1 year after purchase.
          </ContentText>
        </DisclamierContainer>
      </>
    )
  },

  refresher_token: {
    productCode: 'refresher_token',
    description:
      'Once a Refresher Token is applied to a job, your job’s ranking will immediately be bumped to the top of the job list.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Refresher Tokens?</h5>
        <ContentText>
          Applying a Refresher Token to a job immediately bumps your job to the
          top of the jobs list!
        </ContentText>

        <HeightSpacer height={'16px'} />

        <img
          alt="explanation"
          style={{ alignSelf: 'center', maxHeight: '420px' }}
          src={refresherTokenExplanation}
        />

        <HeightSpacer height={'16px'} />

        <h6 style={{ fontSize: '20px' }}>
          <strong>Bundles:</strong>
        </h6>

        <HeightSpacer height={'24px'} />

        <RefresherBundles />

        <HeightSpacer height={'24px'} />

        <DisclamierContainer>
          <ContentText $disclamier>
            Bundle discounts will be automatically applied based on how many
            tokens you added to your shopping cart.
          </ContentText>
          <ContentText $disclamier>
            Unused tokens will expire 1 year after purchase.
          </ContentText>
        </DisclamierContainer>
      </>
    )
  },

  job_credit_single: {
    productCode: 'job_credit_single',
    description:
      'A Job Credit lets you post one job advertisement for a month.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Job Credit?</h5>

        <ContentText>
          A Job Credit lets you advertise one job posting for a month. Unlike
          Job Slots, you won’t be able to swap jobs, but it’s a great
          alternative for those who plan to hire quickly at a lower upfront
          cost.
        </ContentText>

        <HeightSpacer height={'16px'} />

        <img
          alt="explanation"
          style={{ width: '100%' }}
          src={jobCreditExplanation}
        />

        <HeightSpacer height={'16px'} />

        <ContentText>What are Job Credits great for?</ContentText>

        <HeightSpacer height={'16px'} />

        <CheckList
          list={[
            'Shorter hiring periods',
            'A more affordable way to hire multiple jobs at once',
            'A lower-priced, lower-commitment alternative to Job Slots',
            'Employers who want to try hiring on Hiredly for the first time'
          ]}
        />

        <HeightSpacer height={'16px'} />

        <h6 style={{ fontSize: '20px' }}>
          <strong>Bundles:</strong>
        </h6>

        <HeightSpacer height={'24px'} />

        <JobCreditBundles />

        <HeightSpacer height={'24px'} />

        <DisclamierContainer>
          <ContentText $disclamier>
            Bundle discounts will be automatically applied based on how many
            tokens you added to your shopping cart.
          </ContentText>
          <ContentText $disclamier>
            Unused tokens will expire 1 year after purchase.
          </ContentText>
        </DisclamierContainer>
      </>
    )
  },

  homepage_featured_company: {
    productCode: 'homepage_featured_company',
    description:
      'Drive traffic to your company profile and jobs by featuring your company logo on Hiredly’s homepage.',
    dialogContent: (
      <p>
        Bundle discounts will be automatically applied based on how many tokens
        you added to your shopping cart.
      </p>
    )
  },

  homepage_carousel_adspace: {
    productCode: 'homepage_carousel_adspace',
    description:
      'Advertise your jobs, products, events and more on Hiredly’s Homepage.',
    dialogContent: (
      <p>
        Bundle discounts will be automatically applied based on how many tokens
        you added to your shopping cart.
      </p>
    )
  },

  advice_page_ads: {
    productCode: 'advice_page_ads',
    description:
      'Advertise your jobs, products, services and more on Hiredly’s Career Advice Articles.',
    dialogContent: (
      <p>
        Bundle discounts will be automatically applied based on how many tokens
        you added to your shopping cart.
      </p>
    )
  },

  spotlight_token: {
    productCode: 'spotlight_token',
    description: 'Visually boost your job ad with eye-catching animations',
    dialogContent: (
      <>
        <p
          style={{
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '24px',
            letterSpacing: '0.1px'
          }}>
          What are Spotlight Jobs?
        </p>
        <ContentText>
          When applied to your job post, Spotlight Jobs add an eye-catching icon
          and visual effect to the job to increase visibility. Maximise your job
          post visibility by pairing the Spotlight Jobs with a Refresher Token.
        </ContentText>

        <HeightSpacer height={'16px'} />

        <img
          alt="spotlight jobs explanation"
          style={{ alignSelf: 'center', maxHeight: '420px' }}
          src={spotlightTokenExplanation}
        />

        <HeightSpacer height={'24px'} />

        <DisclamierContainer>
          <ContentText $disclamier>
            Applied Spotlight Jobs will be valid for 1 month.
          </ContentText>
          <ContentText $disclamier>
            Unused Spotlight Jobs will expire 1 year after purchase.
          </ContentText>
        </DisclamierContainer>
      </>
    )
  },

  keyword_highlight_token: {
    productCode: 'keyword_highlight_token',
    description:
      'Grab job seekers’ attention with key highlights about your job or company.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Key Highlights?</h5>
        <ContentText>
          Key Highlights lets you attract job seekers with attractive keywords
          that describe your job, company, work environment, benefits, etc. This
          feature allows job posts to include <b>up to five keywords</b>, each
          with a maximum of 20 characters.
        </ContentText>

        <HeightSpacer height={'16px'} />

        <img
          alt="explanation"
          style={{ width: '100%' }}
          src={keywordHighlightExplanation}
        />

        <HeightSpacer height={'24px'} />

        <DisclamierContainer>
          <ContentText $disclamier>
            Applied key highlights will be valid for 1 month.
          </ContentText>
          <ContentText $disclamier>
            Unused key highlights will expire 1 year after purchase.
          </ContentText>
        </DisclamierContainer>
      </>
    )
  },

  popular_package: {
    productCode: 'popular_package',
    description:
      'Bundle deal with 3 jobs slots, talent search tokens and full access to our visibility boosters.',
    dialogContent: null
  },

  enterprise_package: {
    productCode: 'enterprise_package',
    description:
      'Bundle deal with 5 job slots and full access to our visibility boosters.',
    dialogContent: null
  },

  coins_x5000: {
    productCode: 'coins_x5000',
    description:
      'Active CV Access just got better with our latest upgrade! Every set comes with 5,000 tokens to use.',
    dialogContent: coinsDialogContent
  },

  discounted_job_credit: {
    productCode: 'discounted_job_credit',
    description: '',
    dialogContent: null
  },

  job_credit: {
    productCode: 'job_credit_single',
    description:
      'A Job Credit lets you post one job advertisement for a month.',
    dialogContent: (
      <>
        <ContentText>
          A Job Credit lets you advertise one job posting for a month. Unlike
          Job Slots, you won’t be able to swap jobs, but it’s a great
          alternative for those who plan to hire quickly at a lower upfront
          cost.
        </ContentText>

        <HeightSpacer height={'16px'} />

        <img
          alt="explanation"
          style={{ width: '100%' }}
          src={jobCreditExplanation}
        />

        <HeightSpacer height={'16px'} />

        <ContentText>What are Job Credits great for?</ContentText>

        <HeightSpacer height={'16px'} />

        <CheckList
          list={[
            'Shorter hiring periods',
            'A more affordable way to hire multiple jobs at once',
            'A lower-priced, lower-commitment alternative to Job Slots',
            'Employers who want to try hiring on Hiredly for the first time'
          ]}
        />

        <HeightSpacer height={'16px'} />
      </>
    )
  },
};

export const hardcodedProducts = [
  {
    productCode: 'job_slot',
    description:
      'Job Slots allow you to post, replace and re-post jobs as much as you want during your subscription period.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Job Slots?</h5>
        <p>
          Job Slots allow you to post, replace and re-post jobs as much as you
          want during your subscription period.
        </p>
        <p>Think of them like reserved parking spots for your jobs!</p>

        <HeightSpacer height={'12px'} />

        <JobSlotsAnimation size="small" />

        <HeightSpacer height={'22px'} />

        <p>
          <strong>Why are Job Slots more expensive than normal ads?</strong>
        </p>
        <p>
          Actually, it’s much cheaper! With most job portals, the price you pay
          is to advertise 1 job for 1 month.
        </p>
        <p>
          With Hiredly’s Job Slots, the price you pay is for 6-12 months,
          depending on the plan you choose.
        </p>
        <p>
          Within your subscription period, you’re allowed to flexibly change the
          job you choose to advertise in your Job Slot, any time, for any job.
        </p>
        <p>
          Assuming you change job postings in your Job Slot once a month, you’re
          essentially advertising jobs for <strong>only RM300 a month!</strong>
        </p>

        <HeightSpacer height={'16px'} />

        <h6 style={{ fontSize: '20px' }}>
          <strong>Bundles:</strong>
        </h6>

        <HeightSpacer height={'24px'} />

        <JobSlotBundles />

        <HeightSpacer height={'24px'} />
      </>
    )
  },

  {
    productCode: 'job_slot_plus',
    description:
      'Job Slot Plus allow you to post, replace and re-post jobs as much as you want during your subscription period.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Job Slot Plus?</h5>
        <ContentText>
        A Job Slot Plus comes with the features of a classic Job Slot where you can post, replace and re-post as much as you want during your subscription period, with enhanced upgrades!
        </ContentText>

        <HeightSpacer height={'16px'} />

        <img
          alt="explanation"
          style={{ width: '100%' }}
          src={jobCreditExplanation}
        />

        <HeightSpacer height={'16px'} />

        <ContentText>When you post a job with our Job Slot Plus, your job ad will enjoy the following:</ContentText>

        <HeightSpacer height={'16px'} />

        <CheckList
          list={[
            '1.5X job ad performance',
            'Prioritised and refreshed listing every 14 days',
            'Eye-catching spotlight effect'
          ]}
        />
      </>
    )
  },

  {
    productCode: 'job_slot_max',
    description:
      'Job Slot Max allow you to post, replace and re-post jobs as much as you want during your subscription period.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Job Slot Max?</h5>
        <ContentText>
        A Job Slot Max comes with the features of a classic Job Slot where you can post, replace and re-post as much as you want during your subscription period, with exclusive upgrades!
        </ContentText>
        
        <HeightSpacer height={'16px'} />

        <img
          alt="explanation"
          style={{ width: '100%' }}
          src={jobCreditExplanation}
        />


        <HeightSpacer height={'16px'} />

        <ContentText>When you post a job with our Job Slot Max, your job ad will enjoy the following:</ContentText>

        <HeightSpacer height={'16px'} />

        <CheckList
          list={[
            '2.2x job ad performance',
            'Prioritised and refreshed listing every 7 days',
            'Eye-catching spotlight and key highlights effect',
            'Exclusive job ad design',
            'Dedicated customer support'
          ]}
        />

      </>
    )
  },

  {
    productCode: 'job_slot_plus',
    description:
      'Job Slot Plus allow you to post, replace and re-post jobs as much as you want during your subscription period.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Job Slot Plus?</h5>
        <ContentText>
        A Job Slot Plus comes with the features of a classic Job Slot where you can post, replace and re-post as much as you want during your subscription period, with enhanced upgrades!
        </ContentText>

        <HeightSpacer height={'16px'} />

        <img
          alt="explanation"
          style={{ width: '100%' }}
          src={jobCreditExplanation}
        />

        <HeightSpacer height={'16px'} />

        <ContentText>When you post a job with our Job Slot Plus, your job ad will enjoy the following:</ContentText>

        <HeightSpacer height={'16px'} />

        <CheckList
          list={[
            '1.5X job ad performance',
            'Prioritised and refreshed listing every 14 days',
            'Eye-catching spotlight effect'
          ]}
        />
      </>
    )
  },

  {
    productCode: 'job_slot_max',
    description:
      'Job Slot Max allow you to post, replace and re-post jobs as much as you want during your subscription period.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Job Slot Max?</h5>
        <ContentText>
        A Job Slot Max comes with the features of a classic Job Slot where you can post, replace and re-post as much as you want during your subscription period, with exclusive upgrades!
        </ContentText>
        
        <HeightSpacer height={'16px'} />

        <img
          alt="explanation"
          style={{ width: '100%' }}
          src={jobCreditExplanation}
        />


        <HeightSpacer height={'16px'} />

        <ContentText>When you post a job with our Job Slot Max, your job ad will enjoy the following:</ContentText>

        <HeightSpacer height={'16px'} />

        <CheckList
          list={[
            '2.2x job ad performance',
            'Prioritised and refreshed listing every 7 days',
            'Eye-catching spotlight and key highlights effect',
            'Exclusive job ad design',
            'Dedicated customer support'
          ]}
        />

      </>
    )
  },
  {
    productCode: 'limited_edition_package',
    description:
      'Bundle deal with 1 job slot and full access to our visibility boosters. Available till 31st December 2023 only.',
    dialogContent: null
  },
  {
    productCode: 'booster_token',
    description:
      'Once a Featured Token is applied to a job, it will appear first on the featured jobs list and mobile app for 7 days.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Featured Tokens?</h5>
        <p>
          Applying a Featured Token to a job gives it a 7-day boost for
          increased job visibility and job applications!
        </p>

        <HeightSpacer height={'12px'} />

        <img
          alt="explanation"
          style={{ alignSelf: 'center', maxHeight: '420px' }}
          src={featuredTokenExplanation}
        />

        <HeightSpacer height={'22px'} />

        <p>A Featured Job will:</p>
        <div style={{ margin: '12px 0 22px' }}>
          <CheckList
            list={[
              'Appear at the top of job listings on our website.',
              'Appear first on our mobile app.',
              'Appear in weekly email blasts to relevant job seekers'
            ]}
          />
        </div>

        <h6 style={{ fontSize: '20px' }}>
          <strong>Bundles:</strong>
        </h6>

        <HeightSpacer height={'12px'} />

        <BoosterBundles />

        <HeightSpacer height={'22px'} />

        <DisclamierContainer>
          <p style={{ fontSize: '14px' }}>
            Bundle discounts will be automatically applied based on how many
            tokens you added to your shopping cart.
          </p>
          <p style={{ fontSize: '14px' }}>
            Unused tokens will expire 1 year after purchase.
          </p>
        </DisclamierContainer>
      </>
    )
  },
  {
    productCode: 'refresher_token',
    description:
      'Once a Refresher Token is applied to a job, your job’s ranking will immediately be bumped to the top of the job list.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Refresher Tokens?</h5>
        <p>
          Applying a Refresher Token to a job immediately bumps your job to the
          top of the jobs list!
        </p>

        <img
          alt="explanation"
          style={{ alignSelf: 'center', maxHeight: '420px' }}
          src={refresherTokenExplanation}
        />

        <HeightSpacer height={'22px'} />

        <h6 style={{ fontSize: '20px' }}>
          <strong>Bundles:</strong>
        </h6>

        <HeightSpacer height={'12px'} />

        <RefresherBundles />

        <HeightSpacer height={'22px'} />

        <dDisclamierContainer>
          <p style={{ fontSize: '14px' }}>
            Bundle discounts will be automatically applied based on how many
            tokens you added to your shopping cart.
          </p>
          <p style={{ fontSize: '14px' }}>
            Unused tokens will expire 1 year after purchase.
          </p>
        </dDisclamierContainer>
      </>
    )
  },
  {
    productCode: 'job_credit_single',
    description:
      'A Job Credit lets you post one job advertisement for a month.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Job Credit?</h5>
        <p>
          A Job Credit lets you advertise one job posting for a month. Unlike
          Job Slots, you won’t be able to swap jobs, but it’s a great
          alternative for those who plan to hire quickly at a lower upfront
          cost.
        </p>
        <div style={{ margin: '12px 0 22px' }}>
          <img
            alt="explanation"
            style={{ width: '100%' }}
            src={jobCreditExplanation}
          />
        </div>

        <HeightSpacer height={'12px'} />

        <span style={{ fontFamily: 'Inter', fontSize: '16px' }}>
          What are Job Credits great for?
        </span>

        <HeightSpacer height={'15px'} />

        <CheckList
          list={[
            'Shorter hiring periods',
            'A more affordable way to hire multiple jobs at once',
            'A lower-priced, lower-commitment alternative to Job Slots',
            'Employers who want to try hiring on Hiredly for the first time'
          ]}
        />

        <HeightSpacer height={'22px'} />

        <h6 style={{ fontSize: '20px' }}>
          <strong>Bundles:</strong>
        </h6>

        <HeightSpacer height={'20px'} />

        <JobCreditBundles />

        <HeightSpacer height={'24px'} />

        <DisclamierContainer>
          <p style={{ fontSize: '14px' }}>
            Bundle discounts will be automatically applied based on how many
            tokens you added to your shopping cart.
          </p>
          <p style={{ fontSize: '14px' }}>
            Unused tokens will expire 1 year after purchase.
          </p>
        </DisclamierContainer>
      </>
    )
  },
  {
    productCode: 'homepage_featured_company',
    description:
      'Drive traffic to your company profile and jobs by featuring your company logo on Hiredly’s homepage.',
    dialogContent: (
      <p>
        Bundle discounts will be automatically applied based on how many tokens
        you added to your shopping cart.
      </p>
    )
  },
  {
    productCode: 'homepage_carousel_adspace',
    description:
      'Advertise your jobs, products, events and more on Hiredly’s Homepage.',
    dialogContent: (
      <p>
        Bundle discounts will be automatically applied based on how many tokens
        you added to your shopping cart.
      </p>
    )
  },
  {
    productCode: 'advice_page_ads',
    description:
      'Advertise your jobs, products, services and more on Hiredly’s Career Advice Articles.',
    dialogContent: (
      <p>
        Bundle discounts will be automatically applied based on how many tokens
        you added to your shopping cart.
      </p>
    )
  },
  {
    productCode: 'spotlight_token',
    description: 'Visually boost your job ad with eye-catching animations',
    dialogContent: (
      <>
        <p
          style={{
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '24px',
            letterSpacing: '0.1px'
          }}>
          What are Spotlight Jobs?
        </p>
        <ContentText>
          When applied to your job post, Spotlight Jobs add an eye-catching icon
          and visual effect to the job to increase visibility. Maximise your job
          post visibility by pairing the Spotlight Jobs with a Refresher Token.
        </ContentText>

        <HeightSpacer height={'16px'} />

        <img
          alt="spotlight jobs explanation"
          style={{ alignSelf: 'center', maxHeight: '420px' }}
          src={spotlightTokenExplanation}
        />

        <HeightSpacer height={'26px'} />

        <DisclamierContainer>
          <ContentText $disclamier>
            Applied Spotlight Jobs will be valid for 1 month.
          </ContentText>
          <ContentText $disclamier>
            Unused Spotlight Jobs will expire 1 year after purchase.
          </ContentText>
        </DisclamierContainer>
      </>
    )
  },
  {
    productCode: 'keyword_highlight_token',
    description:
      'Grab job seekers’ attention with key highlights about your job or company.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Key Highlights?</h5>
        <ContentText>
          Key Highlights lets you attract job seekers with attractive keywords
          that describe your job, company, work environment, benefits, etc. This
          feature allows job posts to include <b>up to five keywords</b>, each
          with a maximum of 20 characters.
        </ContentText>

        <HeightSpacer height={'16px'} />

        <img
          alt="explanation"
          style={{ width: '100%' }}
          src={keywordHighlightExplanation}
        />

        <HeightSpacer height={'16px'} />

        <DisclamierContainer>
          <ContentText $disclamier>
            Applied key highlights will be valid for 1 month.
          </ContentText>
          <ContentText $disclamier>
            Unused key highlights will expire 1 year after purchase.
          </ContentText>
        </DisclamierContainer>
      </>
    )
  },
  {
    productCode: 'popular_package',
    description:
      'Bundle deal with 3 jobs slots, talent search tokens and full access to our visibility boosters.',
    dialogContent: null
  },
  {
    productCode: 'enterprise_package',
    description:
      'Bundle deal with 5 job slots and full access to our visibility boosters.',
    dialogContent: null
  },
  {
    productCode: 'coins_x5000',
    description:
      'Active CV Access just got better with our latest upgrade! Every set comes with 5,000 tokens to use.',
    dialogContent: coinsDialogContent
  },
  {
    productCode: 'discounted_job_credit',
    description: '',
    dialogContent: null
  }
];

export const hardcodedAddons = [
  {
    productCode: 'job_slot',
    description:
      'Job Slots allow you to post, replace and re-post jobs as much as you want during your subscription period.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Job Slots?</h5>
        <p>
          Job Slots allow you to post, replace and re-post jobs as much as you
          want during your subscription period.
        </p>
        <p>Think of them like reserved parking spots for your jobs!</p>

        <HeightSpacer height={'12px'} />

        <JobSlotsAnimation size="small" />

        <HeightSpacer height={'22px'} />

        <p>
          <strong>Why are Job Slots more expensive than normal ads?</strong>
        </p>
        <p>
          Actually, it’s much cheaper! With most job portals, the price you pay
          is to advertise 1 job for 1 month.
        </p>
        <p>
          With Hiredly’s Job Slots, the price you pay is for 6-12 months,
          depending on the plan you choose.
        </p>
        <p>
          Within your subscription period, you’re allowed to flexibly change the
          job you choose to advertise in your Job Slot, any time, for any job.
        </p>
        <p>
          Assuming you change job postings in your Job Slot once a month, you’re
          essentially advertising jobs for <strong>only RM300 a month!</strong>
        </p>

        <HeightSpacer height={'16px'} />

        <h6 style={{ fontSize: '20px' }}>
          <strong>Bundles:</strong>
        </h6>

        <HeightSpacer height={'24px'} />

        <JobSlotBundles />

        <HeightSpacer height={'24px'} />
      </>
    )
  },

  {
    productCode: 'job_slot_plus',
    description:
      'Job Slot Plus allow you to post, replace and re-post jobs as much as you want during your subscription period.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Job Slot Plus?</h5>
        <ContentText>
        A Job Slot Plus comes with the features of a classic Job Slot where you can post, replace and re-post as much as you want during your subscription period, with enhanced upgrades!
        </ContentText>

        <HeightSpacer height={'16px'} />

        <img
          alt="explanation"
          style={{ width: '100%' }}
          src={jobCreditExplanation}
        />

        <HeightSpacer height={'16px'} />

        <ContentText>When you post a job with our Job Slot Plus, your job ad will enjoy the following:</ContentText>

        <HeightSpacer height={'16px'} />

        <CheckList
          list={[
            '1.5X job ad performance',
            'Prioritised and refreshed listing every 14 days',
            'Eye-catching spotlight effect'
          ]}
        />
      </>
    )
  },

  {
    productCode: 'job_slot_max',
    description:
      'Job Slot Max allow you to post, replace and re-post jobs as much as you want during your subscription period.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Job Slot Max?</h5>
        <ContentText>
        A Job Slot Max comes with the features of a classic Job Slot where you can post, replace and re-post as much as you want during your subscription period, with exclusive upgrades!
        </ContentText>
        
        <HeightSpacer height={'16px'} />

        <img
          alt="explanation"
          style={{ width: '100%' }}
          src={jobCreditExplanation}
        />


        <HeightSpacer height={'16px'} />

        <ContentText>When you post a job with our Job Slot Max, your job ad will enjoy the following:</ContentText>

        <HeightSpacer height={'16px'} />

        <CheckList
          list={[
            '2.2x job ad performance',
            'Prioritised and refreshed listing every 7 days',
            'Eye-catching spotlight and key highlights effect',
            'Exclusive job ad design',
            'Dedicated customer support'
          ]}
        />

      </>
    )
  },

  {
    productCode: 'job_slot_plus',
    description:
      'Job Slot Plus allow you to post, replace and re-post jobs as much as you want during your subscription period.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Job Slot Plus?</h5>
        <ContentText>
        A Job Slot Plus comes with the features of a classic Job Slot where you can post, replace and re-post as much as you want during your subscription period, with enhanced upgrades!
        </ContentText>

        <HeightSpacer height={'16px'} />

        <img
          alt="explanation"
          style={{ width: '100%' }}
          src={jobCreditExplanation}
        />

        <HeightSpacer height={'16px'} />

        <ContentText>When you post a job with our Job Slot Plus, your job ad will enjoy the following:</ContentText>

        <HeightSpacer height={'16px'} />

        <CheckList
          list={[
            '1.5X job ad performance',
            'Prioritised and refreshed listing every 14 days',
            'Eye-catching spotlight effect'
          ]}
        />
      </>
    )
  },

  {
    productCode: 'job_slot_max',
    description:
      'Job Slot Max allow you to post, replace and re-post jobs as much as you want during your subscription period.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Job Slot Max?</h5>
        <ContentText>
        A Job Slot Max comes with the features of a classic Job Slot where you can post, replace and re-post as much as you want during your subscription period, with exclusive upgrades!
        </ContentText>
        
        <HeightSpacer height={'16px'} />

        <img
          alt="explanation"
          style={{ width: '100%' }}
          src={jobCreditExplanation}
        />


        <HeightSpacer height={'16px'} />

        <ContentText>When you post a job with our Job Slot Max, your job ad will enjoy the following:</ContentText>

        <HeightSpacer height={'16px'} />

        <CheckList
          list={[
            '2.2x job ad performance',
            'Prioritised and refreshed listing every 7 days',
            'Eye-catching spotlight and key highlights effect',
            'Exclusive job ad design',
            'Dedicated customer support'
          ]}
        />

      </>
    )
  },
  {
    productCode: 'booster_token',
    description:
      'Once a Featured Token is applied to a job, it will appear first on the featured jobs list and mobile app for 7 days.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Featured Tokens?</h5>
        <p>
          Applying a Featured Token to a job gives it a 7-day boost for
          increased job visibility and job applications!
        </p>

        <HeightSpacer height={'12px'} />

        <img
          alt="explanation"
          style={{ alignSelf: 'center', maxHeight: '420px' }}
          src={featuredTokenExplanation}
        />

        <HeightSpacer height={'22px'} />

        <p>A Featured Job will:</p>
        <div style={{ margin: '12px 0 22px' }}>
          <CheckList
            list={[
              'Appear at the top of job listings on our website.',
              'Appear first on our mobile app.',
              'Appear in weekly email blasts to relevant job seekers'
            ]}
          />
        </div>

        <h6 style={{ fontSize: '20px' }}>
          <strong>Bundles:</strong>
        </h6>

        <HeightSpacer height={'12px'} />

        <BoosterBundles />

        <HeightSpacer height={'22px'} />

        <DisclamierContainer>
          <p style={{ fontSize: '14px' }}>
            Bundle discounts will be automatically applied based on how many
            tokens you added to your shopping cart.
          </p>
          <p style={{ fontSize: '14px' }}>
            Unused tokens will expire 1 year after purchase.
          </p>
        </DisclamierContainer>
      </>
    )
  },
  {
    productCode: 'refresher_token',
    description:
      'Once a Refresher Token is applied to a job, your job’s ranking will immediately be bumped to the top of the job list.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Refresher Tokens?</h5>
        <p>
          Applying a Refresher Token to a job immediately bumps your job to the
          top of the jobs list!
        </p>

        <img
          alt="explanation"
          style={{ alignSelf: 'center', maxHeight: '420px' }}
          src={refresherTokenExplanation}
        />

        <HeightSpacer height={'22px'} />

        <h6 style={{ fontSize: '20px' }}>
          <strong>Bundles:</strong>
        </h6>

        <HeightSpacer height={'12px'} />

        <RefresherBundles />

        <HeightSpacer height={'22px'} />

        <dDisclamierContainer>
          <p style={{ fontSize: '14px' }}>
            Bundle discounts will be automatically applied based on how many
            tokens you added to your shopping cart.
          </p>
          <p style={{ fontSize: '14px' }}>
            Unused tokens will expire 1 year after purchase.
          </p>
        </dDisclamierContainer>
      </>
    )
  },
  {
    productCode: 'job_credit_single',
    description:
      'A Job Credit lets you post one job advertisement for a month.',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Job Credit?</h5>
        <p>
          A Job Credit lets you advertise one job posting for a month. Unlike
          Job Slots, you won’t be able to swap jobs, but it’s a great
          alternative for those who plan to hire quickly at a lower upfront
          cost.
        </p>
        <div style={{ margin: '12px 0 22px' }}>
          <img
            alt="explanation"
            style={{ width: '100%' }}
            src={jobCreditExplanation}
          />
        </div>

        <HeightSpacer height={'12px'} />

        <span style={{ fontFamily: 'Inter', fontSize: '16px' }}>
          What are Job Credits great for?
        </span>

        <HeightSpacer height={'15px'} />

        <CheckList
          list={[
            'Shorter hiring periods',
            'A more affordable way to hire multiple jobs at once',
            'A lower-priced, lower-commitment alternative to Job Slots',
            'Employers who want to try hiring on Hiredly for the first time'
          ]}
        />

        <HeightSpacer height={'22px'} />

        <h6 style={{ fontSize: '20px' }}>
          <strong>Bundles:</strong>
        </h6>

        <HeightSpacer height={'20px'} />

        <JobCreditBundles />

        <HeightSpacer height={'24px'} />

        <DisclamierContainer>
          <p style={{ fontSize: '14px' }}>
            Bundle discounts will be automatically applied based on how many
            tokens you added to your shopping cart.
          </p>
          <p style={{ fontSize: '14px' }}>
            Unused tokens will expire 1 year after purchase.
          </p>
        </DisclamierContainer>
      </>
    )
  },
  {
    productCode: 'spotlight_token',
    description:
      '{{Once a Spotlight Jobs is applied to a job, the job ad will get a visual boost on the job list.}}',
    dialogContent: (
      <>
        <p
          style={{
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '24px',
            letterSpacing: '0.1px'
          }}>
          What are Spotlight Jobs?
        </p>
        <p
          style={{
            lineHeight: '24px',
            fontSize: '14px',
            letterSpacing: '0.15px',
            margin: '0px'
          }}>
          When applied to your job post, Spotlight Jobs add an eye-catching icon
          and visual effect to the job to increase visibility. Maximise your job
          post visibility by pairing the Spotlight Jobs with a Refresher Token.
        </p>

        <HeightSpacer height={'12px'} />

        <img
          alt="spotlight jobs explanation"
          style={{ alignSelf: 'center', maxHeight: '420px' }}
          src={spotlightTokenExplanation}
        />

        <HeightSpacer height={'22px'} />

        <DisclamierContainer>
          {/* <CheckList
            list={[
              'When applied to your job post, the Spotlight Token adds an eye-catching icon and visual effect to the job to increase visibility.',
              'Maximize your job post visibility by pairing the Spotlight Token with a Refresher Token.'
            ]}
          /> */}
          <p
            style={{
              lineHeight: '24px',
              fontSize: '14px',
              letterSpacing: '0.15px'
            }}>
            Applied Spotlight Jobs will be valid for 1 month.
          </p>
          <p
            style={{
              lineHeight: '24px',
              fontSize: '14px',
              letterSpacing: '0.15px',
              margin: '0px'
            }}>
            Unused Spotlight Jobs will expire 1 year after purchase.
          </p>
        </DisclamierContainer>
      </>
    )
  },
  {
    productCode: 'keyword_highlight_token',
    description:
      '{{Once Keyword Highlights is applied to a job, the company benefits will be displayed on the job ad.}}',
    dialogContent: (
      <>
        <h5 style={{ fontSize: '24px' }}>What are Key Highlights?</h5>
        <p style={{ lineHeight: '24px' }}>
          Key Highlights lets you attract job seekers with attractive keywords
          that describe your job, company, work environment, benefits, etc. This
          feature allows job posts to include <b>up to five keywords</b>, each
          with a maximum of 20 characters.
        </p>

        <HeightSpacer height={'12px'} />

        <img
          alt="explanation"
          style={{ width: '100%' }}
          src={keywordHighlightExplanation}
        />

        <HeightSpacer height={'22px'} />

        <DisclamierContainer>
          <p style={{ fontSize: '14px' }}>
            Applied key highlights will be valid for 1 month.
          </p>
          <p style={{ fontSize: '14px' }}>
            Unused key highlights will expire 1 year after purchase.
          </p>
        </DisclamierContainer>
      </>
    )
  }
  // {
  //   productCode: 'coins_x5000',
  //   description:
  //     'Active CV Access just got better with our latest upgrade! Every set comes with 5,000 tokens to use.'
  // }
];
