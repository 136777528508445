import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../styles/colors.module.scss';
import { Button, Grid, Typography } from '@mui/material';

export const ButtonsContainer = styling('div')({
  display: 'flex',
  justifyContent: 'center',
  gap: '24px',
  padding: '16px 24px',
  alignItems: 'center',
  position: 'sticky',
  bottom: '0',
  background: Colors.priWhite
});

export const ContentContainer = styling(Grid)({
  display: 'flex',
  flexDirection: 'column',
  padding: '32px 24px 0px 24px',
  alignItems: 'center',
  justifyContent: "flex-start",
  position: 'sticky',
  bottom: '0',
  gap: "16px",
  background: Colors.priWhite,
  height: "410px"
});

export const TextContainer = styling(Grid)({
  padding: '0px 24px',
  alignItems: 'center',
  background: Colors.priWhite,
  display: "flex",
  flexDirection: "column",
  justifyContent:"flex-start",
  alignItems: "flex-start"
});

export const TextStyled = styling(Typography)({
  color: `rgba(0, 0, 0, 0.60)`,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
});

export const TextStyledItalic = styling('a')({
  color: `rgba(0, 0, 0, 0.60)`,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'italic',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  textDecorationLine: 'underline'
});

export const AcceptButton = styling(Button)({
  borderRadius: '8px',
  background: Colors.priPurple,
  alignSelf: 'center',
  color: Colors.priWhite,
  fontWeight: 700,
  padding: '8px 38px',
  textTransform: 'capitalize',
  minWidth: '200px',
  fontSize: '16px',
  height: "44px",

  ':hover': {
    background: Colors.priPurple
  }
});

export const LinkButton = styling('a')({
  borderRadius: '8px',
  background: 'transparent',
  alignSelf: 'center',
  color: '#222',
  fontWeight: 700,
  textTransform: 'capitalize',
  minWidth: '200px',
  fontSize: '16px',
  textDecoration: 'underline',

  ':hover': {
    background: 'transparent',
    textDecoration: 'underline'
  }
});
