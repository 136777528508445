import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Slider,
  Typography,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DefaultContainer = styled(Grid)(props => ({}));

export const AccordionStyled = styled(Accordion)({
  boxShadow: 'none',
  borderBottom: '1px solid #EFEFEF',

  '&:before': {
    display: 'none'
  }
});

export const AccordionSummaryStyled = styled(AccordionSummary)(props => ({
  padding: '0px',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },

  '&:hover': {
    backgroundColor: '#EFEFEF'
  },

  '& .MuiAccordionSummary-content': {
    margin: '11px 0',
    minHeight: '24px',
    paddingLeft: '12px'
  },

  minHeight: '0',

  ...(props.type == 'currentLocation' && {
    margin: '6px 0',
    padding: '0 8px 0 12px',
    '& .MuiAccordionSummary-content': {
      margin: '8px 0'
    },

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)'
    },

    '&:hover': {
      backgroundColor: '#EFEFEF'
    },

    minHeight: '0'
  })
}));

export const AccordionDetailsStyled = styled(AccordionDetails)(props => ({
  paddingTop: '0px',

  ...(props.column == 'two' && {
    flexDirection: 'row',
    display: 'flex',
    padding: '0 0 0 24px'
  })
}));

export const AccordionDetailsColumnStyled = styled(Grid)(props => ({
  display: 'flex',
  flex: 0.5,
  flexDirection: 'column'
}));

export const MainCategoryTitle = styled(Typography)({
  fontSize: '12px',
  fontFamily: 'Inter',
  lineHeight: '20px'
});

export const PopoverContainerStyled = styled('div')(({ theme }) => ({
  background: theme.palette.white.main,
  width: '450px'
}));

export const TitleContainerStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid black',
  padding: '0 30px',

  '.title': {
    fontFamily: 'InterBold',
    fontSize: '16px',
    alignSelf: 'center'
  }
}));

export const ClearAllStyled = styled('p')(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: '0',
  fontSize: '12px',
  fontFamily: 'Inter',
  fontWeight: '700',
  alignSelf: 'center',
  cursor: 'pointer'
}));

export const DividerStyled = styled('div')({
  border: '1px solid #efefef',
  width: '100%',
  margin: '0'
});

export const FilterBodyStyled = styled('div')({
  // padding: '0 1.25rem'
  display: 'flex',
  width: '450px',
  padding: '20px 20px 0px 20px',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  flex: '1 0 0'
});

export const GridContainerStyled = styled('div')(({ $marginTop }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 50%)',
  margin: '0',
  padding: '0px 12px',

  ...($marginTop
    ? {
        marginTop: $marginTop
      }
    : {})
}));

export const GridItemStyled = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'auto 10%',
  alignItems: 'center',
  gap: '10px'
});

export const SectionTitleStyled = styled('h6')(props => ({
  fontFamily: 'Inter',
  fontWeight: 700,
  fontSize: '14px',
  padding: '0',

  ...(props.type == 'currentLocation' && {
    padding: '0',
    fontWeight: 400,
    fontSize: '12px',
    fontFamily: 'Inter'
  })
}));

export const SliderContainerStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '1.25rem',
  fontFamily: 'InterBold'
});

export const PrettoSliderStyled = styled(Slider)(({ theme }) => ({
  maxWidth: '350px',
  margin: '20px auto 10px',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none'
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit'
    },
    '&:before': {
      display: 'none'
    }
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    color: theme.palette.black.main,
    fontWeight: 'bold',
    fontSize: '15px',
    // borderRadius: '50% 50% 50% 0',
    backgroundColor: 'transparent',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, 110%) rotate(-45deg) scale(1)'
    },
    '& > *': {
      transform: 'rotate(45deg)'
    }
  }
}));

export const SubCategoryContainerStyled = styled('span')({
  fontSize: '14px',
  fontFamily: 'Inter',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  width: '24px',
  height: '24px',
  border: '1px solid #D8D8D8',
  borderRadius: '2px',
  background: '#F5F5F5',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

export const ButtonContainerStyled = styled('div')({
  position: 'sticky',
  background: '#fff',
  bottom: '0',
  display: 'flex',
  justifyContent: 'space-around',
  padding: '10px 100px',
  borderTop: '1px solid black'
});

export const DottedLine = styled(Box)(props => ({
  borderBottom: '1px dashed #D8D8D8',
  borderTop: 'none',
  width: 'auto',
  margin: '0px 16px 6px 16px',
  borderSpacing: '5px'
}));
