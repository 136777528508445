import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Button } from '../../../components';
import { MUIButtonStyled } from '../../../components/MUIButton';
import DefaultLayout from '../../../layouts/DefaultLayout';
import s from './index.module.scss';
import {
  ContainedButtonStyled,
  GridStyled,
  ResponseDesc,
  ResponseTitle,
  TextButtonStyled
} from './styles';

const txnStatusTitleMap = [
  '🎉 Thank You for Your Purchase!',
  'Payment In Review',
  'Payment Failed'
];

export default function PaymentResponse() {
  const history = useNavigate();
  const urlSearchParams = new URLSearchParams(useLocation().search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    window.dataLayer.push({
      event: 'CE_complete-purchase',
      'company-order-id': params?.order_number
    });
  }, []);

  return (
    <DefaultLayout>
      <div className={s.pageWrapper}>
        <GridStyled
          borderRadius={'16px'}
          border={'1px solid #D8D8D8'}
          gap={'32px'}
          container
          justifyContent={'center'}
          alignItems={'center'}
          padding={'40px 24px'}
          flexDirection={'column'}
          width={'550px'}>
          {params?.txn_status ? (
            <>
              <ResponseTitle>
                {txnStatusTitleMap[parseInt(params.txn_status, 10)]}
              </ResponseTitle>
              {params?.message && <ResponseDesc>{params.message}</ResponseDesc>}
            </>
          ) : (
            <>
              <ResponseDesc>
                Whoops, we lost track the status of your payment.
              </ResponseDesc>
              <ResponseDesc>
                We somehow got an empty response from the payment website.
                Please check the status of your payment on the{' '}
                <a href="/order-history">Order History</a> page. We&apos;re very
                sorry for the inconvenience.
              </ResponseDesc>
            </>
          )}

          <GridStyled
            gap={'16px'}
            container
            justifyContent={'center'}
            alignItems={'center'}>
            <TextButtonStyled
              variant="text"
              onClick={() => {
                history('/order-history');
              }}
              solidWhite
              label="">
              View Order History
            </TextButtonStyled>
            <ContainedButtonStyled
              variant="contained"
              onClick={() => {
                history('/jobs');
              }}
              label="Back to Dashboard">
              Back to Dashboard
            </ContainedButtonStyled>
          </GridStyled>
        </GridStyled>
      </div>
    </DefaultLayout>
  );
}
