import { useLocation } from 'react-router-dom';

const useGetQueryString = type => {
  const location = useLocation();
  const queryString = location.search;

  const queryParams = new URLSearchParams(queryString);
  const params = queryParams.get(type);

  return params;
};

export default useGetQueryString;
