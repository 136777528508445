import React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from 'moment';
import {
  Text,
  TableCellStyled,
  TableRowStyled,
  TableContainerStyled,
  HeadTitleContainer,
  BodyTitleContainer,
  BodyContentContainer,
  NoContentContainer
} from './styles';
import { SharedButton } from '../OrderHistory/SharedButton/index';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Spinner } from '../../components';
import { useSelector } from 'react-redux';
import { getCurrency } from '../../hooks/useRegionalisation';

function Row(props) {
  const { row, handleMakePayment, handleCancelOrder } = props;

  const cartItems = row?.cart?.cartLists?.nodes;

  const [open, setOpen] = React.useState(false);
  const cancelingOrder = useSelector(state => state.orders.cancelingOrder);

  const getPaidDate = paidAt => {
    if (paidAt) {
      return moment(paidAt).format('DD MMM YYYY');
    }
  };

  const getCreatedDate = createdAt => {
    if (createdAt) {
      return moment(createdAt).format('DD MMM YYYY');
    }
  };

  const renderOrderStatus = orderStatus => {
    if (orderStatus == 'pending-payment') {
      return 'Pending';
    } else if (orderStatus == 'completed-payment') {
      return 'Completed';
    } else if (orderStatus == 'cancelled') {
      return 'Cancelled';
    }
  };

  const handleInvoice = row => {
    try {
      window.dataLayer.push({
        event: 'view-invoice-order-history',
        order_id: row?.id
      });
    } catch {}

    window.open(row?.invoiceLink, '_blank');
  };

  const handleReceipt = row => {
    try {
      window.dataLayer.push({
        event: 'view-receipt',
        order_id: row?.id
      });
    } catch {}

    window.open(row?.receiptLink, '_blank');
  };

  return (
    <React.Fragment>
      <TableRowStyled
        row_type="body"
        sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row" align="center">
          {' '}
          <Text table_content={'true'}>{row?.invoiceNumber}</Text>
        </TableCell>
        <TableCell align="center">
          <Text table_content={'true'}>{getCreatedDate(row?.createdAt)}</Text>
        </TableCell>
        <TableCell align="center">
          <Text table_content={'true'}>{` ${getCurrency(
            row?.totalAmount?.toFixed(2)
          )}`}</Text>
        </TableCell>
        <TableCell align="center">
          <Text order_status={row?.orderStatus}>
            {renderOrderStatus(row?.orderStatus)}
          </Text>
        </TableCell>
        <TableCell align="center">
          {row?.orderStatus === 'cancelled' ? (
            '-'
          ) : getPaidDate(row?.paidAt) == null ? (
            <SharedButton type="basic" onClick={() => handleMakePayment(row)}>
              {' '}
              Make Payment{' '}
            </SharedButton>
          ) : (
            <Text table_content={'true'}>{getPaidDate(row?.paidAt)}</Text>
          )}
        </TableCell>

        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRowStyled>
      <TableRowStyled row_type="body">
        <TableCell style={{ padding: '0', borderRadius: '10px' }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small">
                <TableBody>
                  <>
                    <TableRowStyled row_type="body-title">
                      <TableCellStyled
                        cell_type={'items'}
                        component="th"
                        scope="row"
                        align="center">
                        <div>
                          <BodyTitleContainer body_title={'items'}>
                            <Text table_header={'true'}>Items</Text>
                          </BodyTitleContainer>
                          {cartItems?.map((item, index) => (
                            <BodyContentContainer
                              key={`${item?.title}--${index}`}
                              body_content={'product-title'}>
                              {!item?.product?.active &&
                              row?.orderStatus === 'cancelled' ? (
                                <Text inactive="true">
                                  {item?.product.title}{' '}
                                  {'(No longer available)'}
                                </Text>
                              ) : (
                                <Text table_content="true">
                                  {item?.product.title}
                                </Text>
                              )}
                            </BodyContentContainer>
                          ))}
                        </div>
                      </TableCellStyled>
                      <TableCellStyled cell_type={'quantity'} align="center">
                        <div>
                          <BodyTitleContainer body_title="quantity">
                            <Text table_header={'true'}>Quantity</Text>
                          </BodyTitleContainer>
                          {cartItems.map((item, index) => (
                            <BodyContentContainer
                              key={`${item?.title}--${index}`}
                              body_content={'product-quantity'}>
                              <Text table_content="true">{item?.quantity}</Text>
                            </BodyContentContainer>
                          ))}
                        </div>
                      </TableCellStyled>
                      <TableCellStyled cell_type={'created-by'} align="center">
                        {' '}
                        <div>
                          <BodyTitleContainer body_title={'created-by'}>
                            <Text table_header={'true'}>Created By</Text>
                          </BodyTitleContainer>

                          <BodyContentContainer
                            body_content={'product-created-by'}>
                            <Text table_content="true">
                              {row?.companyTeamMember
                                ? row?.companyTeamMember?.name
                                : row?.company?.userName}
                            </Text>
                          </BodyContentContainer>
                        </div>
                      </TableCellStyled>
                      <TableCellStyled
                        cell_type={'action-buttons'}
                        align="center">
                        <div>
                          <BodyTitleContainer body_title="none">
                            <Text table_header={'true'}></Text>
                          </BodyTitleContainer>
                          <BodyContentContainer body_content="buttons">
                            <SharedButton
                              type={'outlined_black'}
                              onClick={() => handleInvoice(row)}>
                              View Invoice
                            </SharedButton>
                          </BodyContentContainer>

                          <div>
                            {row?.orderStatus == 'completed-payment' ? (
                              <SharedButton
                                type={'outlined_black'}
                                onClick={() => handleReceipt(row)}>
                                View Receipt
                              </SharedButton>
                            ) : row?.orderStatus !== 'cancelled' ? (
                              <SharedButton
                                type={'outlined_black'}
                                onClick={() => handleCancelOrder(row)}>
                                {cancelingOrder ? (
                                  <Spinner size="XS" noPadding={true} />
                                ) : (
                                  'Cancel Order'
                                )}
                              </SharedButton>
                            ) : null}
                          </div>
                        </div>
                      </TableCellStyled>
                    </TableRowStyled>
                  </>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRowStyled>
    </React.Fragment>
  );
}

const CollapsibleTable = ({
  tableData,
  handleMakePayment,
  onHeaderClick,
  sortDirection,
  sortKey,
  titleIndex,
  handleCancelOrder
}) => {
  const header = [
    { title: 'Invoice No.', enableSort: false },
    { title: 'Date Created', enableSort: true },
    { title: 'Amount', enableSort: false },
    { title: 'Order Status', enableSort: false },
    { title: 'Paid On', enableSort: false }
  ];

  return (
    <TableContainerStyled component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRowStyled row_type={'head-title'}>
            {header.map((column, index) => {
              return (
                <TableCell
                  key={column.title}
                  style={
                    column?.title?.toLowerCase() == 'order status' ||
                    column?.title?.toLowerCase() == 'date created'
                      ? { position: 'relative', width: '220px' }
                      : { position: 'relative' }
                  }
                  align="center"
                  onClick={onHeaderClick(column, column.enableSort, index)}>
                  <Text table_header={'true'}> {column.title} </Text>
                  <HeadTitleContainer>
                    {column?.title?.toLowerCase() == 'date created' ? (
                      titleIndex == index && sortDirection == 'asc' ? (
                        <ArrowDropUpIcon fontSize="small" />
                      ) : (
                        <ArrowDropDownIcon fontSize="small" />
                      )
                    ) : null}
                  </HeadTitleContainer>
                </TableCell>
              );
            })}
            <TableCell />
          </TableRowStyled>
        </TableHead>
        <TableBody>
          {tableData &&
            tableData?.map(item => {
              return (
                <Row
                  key={item?.node?.id}
                  row={item?.node}
                  handleMakePayment={handleMakePayment}
                  handleCancelOrder={handleCancelOrder}
                />
              );
            })}
        </TableBody>
      </Table>
      {tableData?.length == 0 && (
        <NoContentContainer>
          <Text table_content="true">No order found</Text>
        </NoContentContainer>
      )}
    </TableContainerStyled>
  );
};

export default CollapsibleTable;
