import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MUIButtonStyled } from '../../components/MUIButton';
import { mutateAccountInfo } from '../../redux/actions/company_actions';
import { ErrorToast, SuccessToast } from '../../utils/ToastUtils';
import { ButtonContainer, CardContainer, RowLabel } from './styles';
import { clearAccountInfo } from '../../redux/actions/company_actions';
import {
  clearJobInfo,
  updateTempJobDraft
} from '../../redux/actions/job_action';
import { clearOrderInfo } from '../../redux/actions/order_action';
import store from '../../redux/stores/store';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import SharedTextField from '../../components/SharedTextField';

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mutatingCompanyDetails = useSelector(
    state => state.jobs.mutatingCompanyDetails
  );

  const INITIAL_STATE = {
    password: '',
    confirmPassword: ''
  };

  const onLogout = async () => {
    if (store.getState().jobs.preventReloadOrLeave) {
      store.getState().jobs.isLoggingOut = true;
      navigate('/jobs');
      return;
    }

    dispatch(clearAccountInfo());
    dispatch(clearJobInfo());
    dispatch(clearOrderInfo());

    try {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('accountInfo');
      localStorage.removeItem('currentAccountType');
      localStorage.removeItem('lightcast-token');
      localStorage.removeItem('lightcast-token-timeout');
      localStorage.removeItem('hideNewProductsInfo');
      localStorage.removeItem('country');

      var expiresInTime = 'expires=' + 'Sun, 27 Mar 2020 10:13:42 GMT';

      document.cookie = 'companyId' + '=' + ';' + expiresInTime + ';path=/'; // Delete cookie for user ID sent to GA when user logs out
      document.cookie = 'gtmCountry' + '=' + ';' + expiresInTime + ';path=/'; // Delete cookie for user ID sent to GA when user logs out

      window.dataLayer.push({ event: 'logout' });

      updateTempJobDraft([]);
      navigate('/login');
    } catch (error) {
      toast.error(`Logout Failed! ${error.message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  const validationSchema = yup.object().shape({
    password: yup.string().required('Password is required'),
    confirmPassword: yup
      .string()
      .required('Confirm Password is required')
      .oneOf([yup.ref('password'), null], 'Passwords must match')
  });

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: INITIAL_STATE,
    resolver: yupResolver(validationSchema)
  });

  const onSubmitAccountInfo = async values => {
    dispatch(
      mutateAccountInfo({
        input: {
          password: values?.password,
          passwordConfirmation: values?.confirmPassword
        }
      })
    )
      .then(res => {
        if (res.mutateCompanyDetailsSuccess) {
          SuccessToast('Account Password Updated!');
          setTimeout(() => {
            onLogout();
          }, 1500);
        } else {
          ErrorToast('Account Password Failed to Update');
        }
      })
      .catch(err => {
        ErrorToast(err.message);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmitAccountInfo)}>
      <CardContainer>
        <div>
          <RowLabel>Password</RowLabel>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <SharedTextField
                {...field}
                placeholder="Password"
                type="password"
                error={!!errors.password}
                helperText={errors.password?.message}
                showPasswordStrength
              />
            )}
          />
        </div>
        <div>
          <RowLabel>Confirm Password</RowLabel>
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <SharedTextField
                {...field}
                placeholder="Confirm Password"
                type="password"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
              />
            )}
          />
        </div>

        <ButtonContainer>
          <MUIButtonStyled
            disabled={mutatingCompanyDetails}
            type="submit"
            label="Save Changes"
          />
        </ButtonContainer>
      </CardContainer>
    </form>
  );
};

export default ChangePassword;
