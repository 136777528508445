import React, { useEffect } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import Sidebar from './components/Sidebar';
import CoverImage from './CoverImage';
import SocialMediaHighlights from './SocialMediaHighlights';
import CompanyImages from './CompanyImages';
import CompanyBenefits from './CompanyBenefits';
import { CanvasContent, CanvasWrapper } from './styles';
import {
  fetchCompanyProfile,
  fetchIcons
} from '../../redux/actions/company_actions';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import CompanyVideos from './CompanyVideos';
import CompanyAddress from './CompanyAddress';
import CompanyStory from './CompanyStory';
import {
  CompanyStorySkeleton,
  CoverImageSkeleton,
  SidebarSkeleton
} from './loader';

export default function CompanyCanvas() {
  const dispatch = useDispatch();
  const companyProfile = useSelector(state => state.companies.companyProfile);
  const fetchingCompanyProfile = useSelector(
    state => state.companies.fetchingCompanyProfile
  );
  useEffect(() => {
    dispatch(fetchCompanyProfile());
    dispatch(fetchIcons());
  }, []);

  useEffect(() => {
    if (fetchingCompanyProfile) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      window.scrollTo(0, 0);
    }
  }, [fetchingCompanyProfile]);

  return fetchingCompanyProfile ? (
    <DefaultLayout>
      <CanvasWrapper>
        <SidebarSkeleton />

        <CanvasContent>
          <CoverImageSkeleton />
          <CompanyStorySkeleton />
        </CanvasContent>
      </CanvasWrapper>
    </DefaultLayout>
  ) : (
    <DefaultLayout>
      <CanvasWrapper>
        <Sidebar />

        <CanvasContent>
          {!isEmpty(companyProfile) && (
            <>
              <CoverImage />
              <CompanyStory />
              <SocialMediaHighlights />
              <CompanyImages />
              <CompanyVideos />
              <CompanyBenefits />
              <CompanyAddress />
            </>
          )}
        </CanvasContent>
      </CanvasWrapper>
    </DefaultLayout>
  );
}
