import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../styles/colors.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CardContainer = styling('div')(() => ({
  background: Colors.priWhite,
  padding: '25px 20px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
}));

export const IconContainer = styling('div')(() => ({
  '@media (max-width: 1440px)': {
    background: Colors.terLightGrey,
    padding: '20px',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    position: 'relative',
  },
  background: Colors.terLightGrey,
  padding: '20px',
  borderRadius: '50%',
  position: 'relative',
  width: '60px',
  height: '60px',
}));

export const FontAwesomeIconStyled = styling(FontAwesomeIcon)(() => ({
  '@media (max-width: 1440px)': {
    color: Colors.priPurple,
    alignSelf: 'center',
    fontSize: '18px',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
  color: Colors.priPurple, 
  alignSelf: 'center',
  position: 'absolute',
  fontSize: '25px',
  transform: 'translate(-10%, -10%)',
}));

export const ContentContainer = styling('div')(() => ({
  marginTop: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const CardContentContainer = styling('div')(() => ({}));

export const Text = styling('span')((props) => ({
  ...(props.type == 'number' && {
    fontSize: '20px',
    fontWeight: 700,
    fontFamily: 'Inter',
    color: Colors.priBlack,
  }),
  ...(props.type == 'label' && {
    margin: '5px 0 0',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Inter',
    color: Colors.terDarkGrey,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'break-spaces',
  }),
  ...(props.tooltip == 'true' && {
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: 'Inter',
    padding: '5px 5px 0 5px',
  }),
}));

export const HeightSpacer = styling('div')((props) => ({
  height: props.height? props.height : '5px'
}));

export const MainContainer = styling('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));
