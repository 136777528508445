import { useParams, useNavigate } from 'react-router-dom';
import usePathKey from './usePathKey';
import { useSelector } from 'react-redux';

export default function useData() {
  const { id } = useParams();
  const pathKey = usePathKey();

  const draftJob = useSelector(state => state?.jobs?.draftJob);
  const jobFromApi = useSelector(state => state?.jobs?.jobData);

  let jobData;

  if (pathKey === 'draft') {
    jobData = draftJob;
  } else if (pathKey === 'new') {
    jobData = null;
  }

  if (id) {
    jobData = jobFromApi;
  }

  return jobData;
}
