import React, { useState } from 'react';
import { CardMedia } from '@mui/material';
import { Text, VCLContainer, StyledCardMedia } from './styles';

export default function VCL(props) {
  const user = props.application?.user;
  const videoCvOptimized = user?.videoCvOptimized;

  return (
    <VCLContainer>
      <Text type="normal">Video Cover Letter</Text>
      <div>
        <StyledCardMedia
          component="video"
          controls          
          src={videoCvOptimized}
        />
      </div>
    </VCLContainer>
  );
}
