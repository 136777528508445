import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HeightSpacer } from '../../../../components/Spacer/styles';
import {
  spotlightJob,
  updateAllJobs,
  updateSpotlightJobConfirmationPopup,
  updateSpotlightTokensCount
} from '../../../../redux/actions/job_action';
import TokensPopup from '../../SharedPopups/TokensPopup';
import { ContentDescription } from '../../SharedPopups/TokensPopup/styles';
import store from '../../../../redux/stores/store';
import { ErrorToast, SuccessToast } from '../../../../utils/ToastUtils';
import {
  ContentInfo,
  ExplainerContainer,
  ExplainerInfo,
  ExplainerInfoWrapper,
  ExplainerNote,
  ExplainerTitle,
  GifContainer
} from './styles';
import SpotlightTokenVisualExplainer from '../../../../assets/images/jobs/spotlight_token_visual_explainer.gif';
import { mutateCart } from '../../../../redux/actions/order_action';

const SpotlightJobConfirmationPopUp = ({ jobTitle, jobId, jobData }) => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const isSpotlightJobConfirmationOpen = useSelector(
    state => state.jobs.isSpotlightJobConfirmationOpen
  );
  const loading = useSelector(state => state.jobs.spotlightingJob);
  const allJobs = store.getState().jobs.jobs;
  const spotlightTokensUsableCount = useSelector(
    state => state.jobs?.subscribedSpotlightTokensPackage?.total_usable_quantity
  );

  const getPopupTitle = (count, jobData) => {
    if (count > 0) {
      if (extendDuration(jobData)) {
        return `Extend Spotlight Duration`;
      }
      return `Spotlight ${jobTitle}?`;
    } else {
      return `No Spotlight Job Available`;
    }
  };

  const getContentMessage = (count, jobData) => {
    if (count > 0) {
      if (extendDuration(jobData)) {
        return `By extending spotlight to this job, one Spotlight Job will be deducted from your account.`;
      }

      return 'By spotlighting this job, one Spotlight Job will be deducted from your account.';
    } else {
      return 'Refine your recruitment journey by purchasing Spotlight Jobs!';
    }
  };

  const getFeaturedTokenCount = (count, jobData) => {
    let tokenCount = spotlightTokensUsableCount;

    if (count > 0) {
      return (
        <span>
          You have {''}
          <b>{tokenCount} Spotlight Jobs</b> {''} left.
        </span>
      );
    } else {
      return ``;
    }
  };

  const extendDuration = job => {
    if (job?.spotlightJobThreshold > 0 && job?.spotlightJobThreshold <= 25) {
      return true;
    }

    return false;
  };

  const onSubmitSpotlightToken = async (count, jobId, jobData) => {
    if (count > 0) {
      if (extendDuration(jobData)) {
        onSpotlightJob(jobId);
        window.dataLayer.push({
          event: 'CE_extend-popup-spotlight-confirm'
        });
      } else {
        onSpotlightJob(jobId);
        window.dataLayer.push({
          event: 'CE_use-popup-spotlight-confirm'
        });
      }
    } else {
      await dispatch(
        mutateCart({
          input: {
            productId: '320',
            quantity: 1
          }
        })
      );
      history('/purchase/choose');
      window.dataLayer.push({
        event: 'CE_purchase-popup-spotlight-confirm'
      });
    }
  };

  const onSpotlightJob = jobId => {
    try {
      const params = {
        jobId: jobId
      };

      dispatch(spotlightJob(params)).then(res => {
        const currentJobIndex = allJobs.findIndex(job => {
          return job.id == jobId;
        });

        if (res.type == 'SPOTLIGHT_JOB_SUCCEED') {
          if (currentJobIndex > -1) {
            allJobs[currentJobIndex] = res.job;
          }

          dispatch(updateSpotlightTokensCount(spotlightTokensUsableCount - 1));

          dispatch(updateAllJobs(allJobs));
          SuccessToast('Spotlight Job Successful!');
        } else {
          ErrorToast('Spotlight Job Failed!');
        }

        onClosePopup();
      });
    } catch (err) {
      ErrorToast(err.message);
    }
  };

  const onClosePopup = (count, jobData = {}) => {
    try {
      if (count > 0) {
        if (extendDuration(jobData)) {
          window.dataLayer.push({
            event: 'CE_extend-popup-spotlight-cancel'
          });
        } else {
          window.dataLayer.push({
            event: 'CE_use-popup-spotlight-cancel'
          });
        }
      } else if (count == 0) {
        window.dataLayer.push({
          event: 'CE_purchase-popup-spotlight-cancel'
        });
      }

      dispatch(updateSpotlightJobConfirmationPopup(false));
    } catch {}
  };

  return (
    <TokensPopup
      title={getPopupTitle(spotlightTokensUsableCount, jobData)}
      firstButtonText={'Cancel'}
      secondButtonText={
        spotlightTokensUsableCount > 0 ? 'Confirm' : 'Purchase Now'
      }
      open={isSpotlightJobConfirmationOpen}
      onClose={onClosePopup}
      onClickFirstButton={() =>
        onClosePopup(spotlightTokensUsableCount, jobData)
      }
      onClickSecondButton={() =>
        onSubmitSpotlightToken(spotlightTokensUsableCount, jobId, jobData)
      }
      loadingSecondButton={loading}>
      <>
        <ContentInfo>
          <GifContainer>
            <img
              alt="spotlight-token-visual-explainer"
              style={{ width: '100%', maxHeight: '231px' }}
              src={SpotlightTokenVisualExplainer}
            />
          </GifContainer>
          <ExplainerContainer>
            <ExplainerTitle>How can Spotlight Jobs help you?</ExplainerTitle>
            <ExplainerInfoWrapper>
              <ExplainerInfo>
                When applied to your job post, Spotlight Jobs add an
                eye-catching icon and visual effect to the job to increase
                visibility.
              </ExplainerInfo>
              <ExplainerInfo margin_top="true">
                Employers using Spotlight Jobs get up to 2x job views* on
                average.
              </ExplainerInfo>
            </ExplainerInfoWrapper>
            <ExplainerNote>
              *Actual job views for your role may vary based on different
              factors such as industry and experience level.
            </ExplainerNote>
          </ExplainerContainer>
        </ContentInfo>
        <HeightSpacer height={'24px'} />
        <ContentDescription>
          {getContentMessage(spotlightTokensUsableCount, jobData)}
        </ContentDescription>
        {spotlightTokensUsableCount > 0 ? (
          <HeightSpacer height={'24px'} />
        ) : null}
        <ContentDescription>
          {getFeaturedTokenCount(spotlightTokensUsableCount, jobData)}
        </ContentDescription>
      </>
    </TokensPopup>
  );
};

export default SpotlightJobConfirmationPopUp;
