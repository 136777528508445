import React, { useRef, useEffect, useState } from 'react';
import { RichUtils, EditorState, Modifier } from 'draft-js';
import { TextField } from '@mui/material';
import { Button } from '../../../components';
import styles from './index.module.scss';

const LinkSource = ({
  entity,
  editorState,
  entityType,
  onComplete,
  onClose
}) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    const selection = editorState.getSelection();

    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);

      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

      let url = '';
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }

      setUrl(url);
    }
  }, []);

  const onConfirm = e => {
    e.preventDefault();

    if (url == '') {
      RichUtils.toggleLink(editorState, editorState.getSelection(), null);
    }

    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url: url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    let nextState;

    if (selection.isCollapsed()) {
      //No text is selected
      const text = url;
      const newContent = Modifier.replaceText(
        contentState,
        editorState.getSelection(),
        text,
        null,
        entityKey
      );

      nextState = EditorState.push(
        editorState,
        newContent,
        'insert-characters'
      );
    } else {
      // Text is selected
      nextState = RichUtils.toggleLink(
        editorState,
        editorState.getSelection(),
        entityKey
      );
    }

    onComplete(nextState);
  };

  const onRequestClose = e => {
    e.preventDefault();

    onClose();
  };

  const onChangeURL = e => {
    if (e.target) {
      const url = e.target.value;
      setUrl(url);
    }
  };

  const removeLink = e => {
    e.preventDefault();
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      onComplete(RichUtils.toggleLink(editorState, selection, null));
    }
  };

  return (
    <div className={styles.modal}>
      <div className={styles.titleAndInputContainer}>
        <label className={styles.labelText}>Link Url</label>

        <div>
          <TextField
            value={url}
            placeholder="https://www.hiredly.com"
            onChange={onChangeURL}
            className={styles.textFieldStyle}
          />

          {url != '' ? (
            <div className={styles.buttonContainer}>
              <Button
                color="WHITEBLACK"
                size="BIG"
                onMouseDown={removeLink}
                style={{
                  margin: '0 0.5rem',
                  color: '#222',
                  backgroundColor: '#fff',
                  outline: '2px solid #222'
                }}>
                Remove Link
              </Button>

              <Button
                color="PURPLE"
                size="BIG"
                onClick={onConfirm}
                style={{ margin: '0 0.5rem' }}>
                Add Link
              </Button>
            </div>
          ) : (
            <div className={styles.buttonContainer}>
              <Button
                color="PURPLE"
                size="BIG"
                onClick={onConfirm}
                style={{ margin: '0 0.5rem' }}>
                Close
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LinkSource;
