import { Grid } from '@mui/material';
import React from 'react';
import { HeightSpacer } from '../../components/Spacer/styles';
import {
  ChipContainer,
  ChipTag,
  Container,
  Notice,
  SparklesIconStyled,
  Title,
  TitleContainer
} from './styles';

const SharedAIPoweredCard = ({ title, content, notice, origin, tooltip }) => {
  return (
    <Container $origin={origin}>
      <Grid display={'flex'} alignItems={'center'} gap={'15px'}>
        <TitleContainer
          $origin={origin}
          display={'flex'}
          alignItems={'center'}
          justifyContent={origin == 'list' ? 'space-between' : 'center'}>
          <Grid display={'flex'} alignItems={'center'} gap="10px">
            <SparklesIconStyled />
            <Title>{title}</Title>
          </Grid>
          {origin == 'list' && (
            <ChipContainer $name="beta">
              <ChipTag>BETA</ChipTag>
            </ChipContainer>
          )}
        </TitleContainer>
        {tooltip}
      </Grid>
      <HeightSpacer height={'10px'} />
      <Notice>{notice}</Notice>
      <HeightSpacer height={'20px'} />
      {content}
    </Container>
  );
};

export default SharedAIPoweredCard;
