import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import DefaultLayout from '../../layouts/DefaultLayout';
import {
  Container,
  Row,
  ButtonContainer,
  TitleText,
  NormalText,
  TextBox,
  BackButton,
  ContainerStyled,
  RightContainer,
  TitleBox,
  OptionRow,
  OptionRowBox,
  OptionBox,
  OptionText,
  OptionImg,
  OptionContent,
  OptionImgContainer,
  TextFieldContainer,
  ErrorMessage,
  SubmitButton
} from './styles';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { SuccessToast, ErrorToast, WarningToast } from '../../utils/ToastUtils';
import { mutatePostJobFeedback } from '../../redux/actions/job_action';
import { CircularProgress, Skeleton } from '@mui/material';

export default function Feedback() {
  const history = useNavigate();
  const dispatch = useDispatch();

  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    rating: '',
    feedback: ''
  });

  const [selectedOption, setSelectedOption] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleFieldChange = e => {
    let { name, value } = e.target || e;

    if (!e.target) {
      ({ name, value } = e);
    }

    // Update form data
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleOptionClick = rating => {
    setSelectedOption(rating);
    setFormData(prev => ({
      ...prev,
      rating
    }));
    setError(false);
  };

  const options = [
    {
      emoji: '😠',
      text: 'Awful',
      value: 1
    },

    {
      emoji: '🙁',
      text: 'Bad',
      value: 2
    },

    {
      emoji: '🙂',
      text: 'Okay',
      value: 3
    },

    {
      emoji: '😃',
      text: 'Good',
      value: 4
    },

    {
      emoji: '😍',
      text: 'Great',
      value: 5
    }
  ];

  const submitForm = async () => {
    if (!formData.rating) {
      setError(true);
      return;
    }

    // form submission logic
    setIsLoading(true);
    try {
      const res = await dispatch(
        mutatePostJobFeedback({
          input: {
            rating: formData?.rating,
            feedback: formData?.feedback
          }
        })
      );

      if (res?.type == 'POST_JOB_FEEDBACK_FAILED') {
        ErrorToast(res?.error);
        setIsLoading(false);
        return;
      } else {
        SuccessToast('Feedback received successfully!');
        setTimeout(() => {
          history('/jobs');
        }, 2000);
      }
    } catch (err) {
      setIsLoading(false);
      ErrorToast(err.message);
    }
  };

  return (
    <DefaultLayout>
      <Container>
        <ContainerStyled>
          <img
            alt="404"
            src="https://assets.wobbjobs.com/images/feedback.png"
            style={{ height: 335, width: 337 }}
          />
          <RightContainer>
            <TextBox>
              <TitleBox>
                <TitleText>
                  How was your experience with the job posting process?
                </TitleText>
                <TitleText type="desc">
                  Your feedback is important and helps us to provide a better
                  user experience.
                </TitleText>
              </TitleBox>

              <OptionRowBox>
                {error && (
                  <ErrorMessage>
                    <ExclamationCircleIcon
                      style={{
                        width: '20px',
                        height: '20px',
                        color: '#BE4242'
                      }}
                    />
                    <p>Please select a rating to continue</p>
                  </ErrorMessage>
                )}

                <OptionRow>
                  {options?.map((options, index) => (
                    <OptionBox
                      onClick={() => handleOptionClick(options?.value)}
                      style={{
                        opacity:
                          selectedOption == ''
                            ? '1'
                            : selectedOption == options.value
                            ? '1'
                            : '0.35',
                        cursor: 'pointer',

                        ':hover': {
                          opacity: '1 !important'
                        }
                      }}>
                      <OptionContent>
                        <OptionImgContainer>
                          <OptionImg selected={selectedOption == options.value}>
                            {options?.emoji}
                          </OptionImg>
                        </OptionImgContainer>
                        <OptionText selected={selectedOption == options.value}>
                          {options?.text}
                        </OptionText>
                      </OptionContent>
                    </OptionBox>
                  ))}
                </OptionRow>
              </OptionRowBox>

              <TextFieldContainer>
                <NormalText>Share your feedback with us (optional)</NormalText>

                <FormControl sx={{ width: '100%' }}>
                  <OutlinedInput
                    placeholder="e.g. What’s working well? What could’ve gone better?"
                    multiline={true}
                    variant="outlined"
                    fullWidth
                    rows={4}
                    style={{
                      background: '#FFF',
                      padding: '10px 16px',
                      color: 'rgba(0, 0, 0, 0.87)',
                      fontSize: '14px',
                      lineHeight: '24px',
                      letterSpacing: '0.15px'
                    }}
                    name="feedback"
                    onChange={handleFieldChange}
                  />
                </FormControl>
              </TextFieldContainer>
            </TextBox>

            <ButtonContainer>
              <Row>
                <BackButton onClick={() => history('/jobs')}>
                  Back to Dashboard
                </BackButton>

                <SubmitButton
                  onClick={() => submitForm()}
                  disabled={isLoading}
                  loading={isLoading}
                  loadingIndicator={
                    <CircularProgress
                      sx={{ color: 'white !important' }}
                      size={16}
                    />
                  }>
                  Submit Feedback
                </SubmitButton>
              </Row>
            </ButtonContainer>
          </RightContainer>
        </ContainerStyled>
      </Container>
    </DefaultLayout>
  );
}
