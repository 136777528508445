import * as types from '../types/order_type';

const initialState = {
  cancelingOrder: false,
  success: false,
  errorMessage: '',

  // FETCH CART
  myCart: [],
  fetchingMyCart: false,

  // MUTATE CART
  mutatingCart: false,
  selectedItem: null,

  // FETCH MY ORDERS
  myOrders: [],
  fetchingMyOrders: false,
  myOrdersPageInfo: [],
  myOrdersCount: 0,

  // FETCH MY ORDER
  myOrder: [],
  fetchingMyOrder: false,

  // FETCH CART APPLICABLE VOUCHERS
  cartApplicableVouchers: [],
  fetchingCartApplicableVouchers: false,

  // MUTATE CART SUMMARY
  mutatingCartSummary: false,
  cartSummary: {},

  // MUTATE CHECKOUT ORDER
  mutatingCheckoutOrder: false,
  checkoutOrder: {},

  // MUTATE PAYMENT LINK
  mutatingEghlPaymentLink: false,
  eghlPaymentLink: {},

  // UPDATE SELECTED PRODUCT TAB
  selectedTab: '',

  // UDPATE PRODUCT INFO DIALOG
  infoDialog: '',

  // UPDATE INFO DIALOG STATE
  openInfoDialog: false
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // UPDATE INFO DIALOG STATE
    case types.UDPATE_INFO_DIALOG_STATE: {
      return {
        ...state,
        openInfoDialog: action.openInfoDialog
      };
    }

    // UDPATE PRODUCT INFO DIALOG
    case types.UDPATE_PRODUCT_INFO_DIALOG: {
      return {
        ...state,
        infoDialog: action.infoDialog
      };
    }

    // UPDATE SELECTED PRODUCT TAB
    case types.UPDATE_SELECTED_PRODUCT_TAB: {
      return {
        ...state,
        selectedTab: action.selectedTab
      };
    }

    case types.CANCELING_ORDER: {
      return {
        ...state,
        cancelingOrder: true
      };
    }
    case types.CANCEL_ORDER_SUCCEED: {
      return {
        ...state,
        cancelingOrder: false,
        success: action.payload.status
      };
    }
    case types.CANCEL_ORDER_FAILED: {
      return {
        ...state,
        cancelingOrder: false,
        errorMessage: action.payload.errorMessage
      };
    }

    // FETCH CART
    case types.FETCH_CART: {
      return {
        ...state,
        fetchingMyCart: true
      };
    }
    case types.FETCH_CART_SUCCEED: {
      return {
        ...state,
        fetchingMyCart: false,
        myCart: action.myCart
      };
    }
    case types.FETCH_CART_FAILED: {
      return {
        ...state,
        fetchingMyCart: false
      };
    }

    // FETCH MY ORDERS
    case types.FETCH_MY_ORDERS: {
      return {
        ...state,
        fetchingMyOrders: true
      };
    }
    case types.FETCH_MY_ORDERS_SUCCEED: {
      return {
        ...state,
        fetchingMyOrders: false,
        myOrders: action.myOrders,
        myOrdersPageInfo: action.pageInfo,
        myOrdersCount: action.totalCount
      };
    }
    case types.FETCH_MY_ORDERS_FAILED: {
      return {
        ...state,
        fetchingMyOrders: false
      };
    }

    // FETCH MY ORDER
    case types.FETCH_MY_ORDER: {
      return {
        ...state,
        fetchingMyOrder: true
      };
    }
    case types.FETCH_MY_ORDER_SUCCEED: {
      return {
        ...state,
        fetchingMyOrder: false,
        myOrder: action.myOrder
      };
    }
    case types.FETCH_MY_ORDER_FAILED: {
      return {
        ...state,
        fetchingMyOrder: false
      };
    }
    // FETCH CART APPLICABLE VOUCHERS
    case types.FETCH_CART_APPLICABLE_VOUCHERS: {
      return {
        ...state,
        fetchingCartApplicableVouchers: true
      };
    }
    case types.FETCH_CART_APPLICABLE_VOUCHERS_SUCCEED: {
      return {
        ...state,
        fetchingCartApplicableVouchers: false,
        cartApplicableVouchers: action.cartApplicableVouchers
      };
    }
    case types.FETCH_CART_APPLICABLE_VOUCHERS_FAILED: {
      return {
        ...state,
        fetchingCartApplicableVouchers: false
      };
    }

    // MUTATE CART
    case types.MUTATE_CART: {
      return {
        ...state,
        mutatingCart: true,
        selectedItem: null
      };
    }
    case types.MUTATE_CART_SUCCEED: {
      return {
        ...state,
        mutatingCart: false,
        selectedItem: state?.selectedItem || action.selectedItem
      };
    }
    case types.MUTATE_CART_FAILED: {
      return {
        ...state,
        mutatingCart: false
      };
    }

    // UPDATE SELECTED ITEM
    case types.UPDATE_SELECTED_ITEM: {
      return {
        ...state,
        selectedItem: action.selectedItem
      };
    }

    // MUTATE CART SUMMARY
    case types.MUTATE_CART_SUMMARY: {
      return {
        ...state,
        mutatingCartSummary: true
      };
    }
    case types.MUTATE_CART_SUMMARY_SUCCEED: {
      return {
        ...state,
        mutatingCartSummary: false,
        cartSummary: action.cartSummary
      };
    }
    case types.MUTATE_CART_SUMMARY_FAILED: {
      return {
        ...state,
        mutatingCartSummary: false
      };
    }

    // MUTATE CHECKOUT ORDER
    case types.MUTATE_CHECKOUT_ORDER: {
      return {
        ...state,
        mutatingCheckoutOrder: true
      };
    }
    case types.MUTATE_CHECKOUT_ORDER_SUCCEED: {
      return {
        ...state,
        mutatingCheckoutOrder: false,
        checkoutOrder: action.checkoutOrder
      };
    }
    case types.MUTATE_CHECKOUT_ORDER_FAILED: {
      return {
        ...state,
        mutatingCheckoutOrder: false
      };
    }

    // MUTATE PAYMENT LINK
    case types.MUTATE_PAYMENT_LINK: {
      return {
        ...state,
        mutatingEghlPaymentLink: true
      };
    }
    case types.MUTATE_PAYMENT_LINK_SUCCEED: {
      return {
        ...state,
        mutatingEghlPaymentLink: false,
        eghlPaymentLink: action.eghlPaymentLink
      };
    }
    case types.MUTATE_PAYMENT_LINK_FAILED: {
      return {
        ...state,
        mutatingEghlPaymentLink: false
      };
    }

    case types.CLEAR_ORDER_INFO: {
      return {};
    }

    default: {
      return state;
    }
  }
};
