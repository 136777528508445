const MAX_FILE_UPLOAD_SIZE = 2097152; // 2MB
const ONE_MB_SIZE = 1048576; // 1MB

export const checkFileSize = (size, type = 'normal') => {
  if (type == 'company_logo') {
    if (size > ONE_MB_SIZE) {
      throw Error('File Size Exceeds 1MB');
    }
  } else {
    if (size > MAX_FILE_UPLOAD_SIZE) {
      throw Error('File Size Exceeds 2MB');
    }
  }
};

export const checkFileExtension = file => {
  if (!file) return;

  const fileName = file?.name.toLowerCase();

  if (fileName) {
    if (
      !fileName.includes('.png') &&
      !fileName.includes('.jpg') &&
      !fileName.includes('.jpeg')
    ) {
      throw Error('Only PNG or JPG Files are Allowed');
    }
  }
};
