import React from 'react';
import {
  BenefitContainer,
  BenefitContent,
  BenefitDesc,
  BenefitTitle
} from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function BenefitCards(props) {
  const { benefit } = props;
  return (
    <BenefitContainer>
      <FontAwesomeIcon
        icon={benefit?.iconCode}
        style={{ height: '40px', width: '40px' }}
      />

      <BenefitContent>
        <BenefitTitle>{benefit?.title}</BenefitTitle>
        <BenefitDesc>{benefit?.description}</BenefitDesc>
      </BenefitContent>
    </BenefitContainer>
  );
}
