import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  Divider,
  IconButton,
  Radio,
  RadioGroup
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import Colors from '../../../styles/colors.module.scss';
import { Close } from '@mui/icons-material';
import {
  BulletPoint,
  ClearSelectionText,
  DialogTitleStyled,
  GridStyled,
  VoucherGrid
} from './styles';

const useStyles = makeStyles({
  cardAction: {
    height: 80,
    width: '100%',
    borderRadius: 12
  },
  tooltip: {
    fontSize: '15px',
    maxWidth: 400
  }
});

export const DiscountVouchersPopUp = ({
  open,
  handleClose,
  vouchers,
  setPromoCode,
  cart,
  setDiscountCode
}) => {
  const [chooseVoucher, setChooseVoucher] = useState();

  const onSelectVoucher = promoCode => {
    setPromoCode(promoCode);
    handleClose();
  };

  function handleUpdateVoucher(targetVoucher) {
    setChooseVoucher(targetVoucher);
  }

  const clearSelectionHandler = () => {
    if (chooseVoucher?.promoCode) {
      setDiscountCode('');
      setPromoCode('');
      setChooseVoucher(null);
    }
    handleClose();
  };

  const closePopUp = () => {
    if (chooseVoucher?.promoCode) {
      handleClose();
    } else {
      setChooseVoucher(null);
      handleClose();
    }
  };

  return (
    <Dialog
      onClose={() => closePopUp()}
      open={open}
      PaperProps={{
        style: {
          backgroundColor: `${Colors.priWhite}`,
          borderRadius: '16px',
          minWidth: '464px'
        }
      }}>
      <GridStyled>
        <GridStyled
          container
          padding={'16px 16px 16px 24px'}
          alignItems={'center'}
          justifyContent={'space-between'}
          borderBottom={'1px solid #D8D8D8'}>
          <DialogTitleStyled variant="h1">
            Select a Discount Voucher
          </DialogTitleStyled>
          <IconButton
            onClick={() => {
              setChooseVoucher(null);
              handleClose();
            }}>
            <Close color="black" width="24px" height="24px" />
          </IconButton>
        </GridStyled>
        <GridStyled
          padding={'24px'}
          container
          flexWrap={'nowrap'}
          flexDirection={'column'}
          rowGap={'16px'}>
          {vouchers.map(item => {
            const { discount, expiredAt, promoCode } = item || {};

            const disableVoucher = promoCode.includes('universum')
              ? cart.length > 1 ||
                (cart.length == 1 && !cart[0].productCode.includes('universum'))
                ? true
                : false
              : false;

            return (
              <VoucherGrid
                columnGap={'16px'}
                padding={'16px'}
                borderRadius={'8px'}
                container
                flexWrap={'nowrap'}
                selected={chooseVoucher?.promoCode === promoCode}
                onClick={() => !disableVoucher && handleUpdateVoucher(item)}>
                <RadioGroup
                  name="voucher-radio"
                  value={chooseVoucher?.promoCode}>
                  <Radio
                    sx={{
                      paddingTop: 0
                    }}
                    value={promoCode}
                    disableRipple
                    checked={chooseVoucher?.promoCode === promoCode}
                    onClick={() => !disableVoucher && handleUpdateVoucher(item)}
                  />
                </RadioGroup>
                <GridStyled container rowGap={'8px'} flexDirection={'column'}>
                  <DialogTitleStyled>{discount}% OFF</DialogTitleStyled>
                  <ul style={{ paddingInlineStart: '15px', margin: 0 }}>
                    <BulletPoint>No minimum spend</BulletPoint>
                    <BulletPoint>
                      Valid Until: {moment(expiredAt).format('DD MMMM YYYY')}
                    </BulletPoint>
                  </ul>
                </GridStyled>
              </VoucherGrid>
            );
          })}
        </GridStyled>
        <Divider />
        <GridStyled paddingY={'16px'}>
          <GridStyled
            container
            flexWrap={'nowrap'}
            columnGap={'16px'}
            justifyContent={'space-evenly'}
            alignItems={'center'}>
            <Button
              variant="text"
              sx={{
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: '700',
                width: '200px',
                height: '36px',
                borderRadius: '8px',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#00000038'
                },
                color: !chooseVoucher ? '#00000038' : 'black',
                boxShadow: 'none'
              }}
              disabled={!chooseVoucher}
              onClick={() => clearSelectionHandler()}>
              Clear Selection
            </Button>
            <Button
              variant="contained"
              sx={{
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: '700',
                width: '200px',
                height: '36px',
                borderRadius: '8px',
                textTransform: 'none',
                boxShadow: 'none',
                color: !chooseVoucher ? '#00000038' : 'white',
                ':hover': {
                  backgroundColor: '#40249B'
                }
              }}
              disabled={!chooseVoucher}
              onClick={() => onSelectVoucher(chooseVoucher?.promoCode)}>
              Apply Voucher
            </Button>
          </GridStyled>
        </GridStyled>
      </GridStyled>
    </Dialog>
  );
};

DiscountVouchersPopUp.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  vouchers: PropTypes.arrayOf(Object),
  setPromoCode: PropTypes.func,
  cart: PropTypes.arrayOf(Object)
};

DiscountVouchersPopUp.defaultProps = {
  open: false,
  handleClose: () => {},
  vouchers: [],
  setPromoCode: () => {},
  cart: []
};

export default DiscountVouchersPopUp;
