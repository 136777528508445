import { ButtonBase } from '@mui/material';
import { styled as styling } from '@mui/material/styles';
// import Colors from '../../../../../styles/colors.module.scss';

export const ButtonBaseStyled = styling(ButtonBase)((props) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '6px 8px',
  gap: '8px',
  background: 'rgba(33, 61, 181, 0.1)',
  borderRadius: '4px',
  transition: 'background 0.2s',

  '&:hover': {
    background: 'rgba(33, 61, 181, 0.2)',
  },

  ...(props.$ashleyEdited && {
    background: '#EFEFEF',

    '&:hover': {
      background: '#D8D8D8',
    },
  }),
}));

export const ButtonText = styling('span')({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '10px',
  letterSpacing: '1.5px',
  textTransform: 'uppercase',
});
