// UPDATE NEW PRODUCTS POPUP
export const UPDATE_NEW_INFO_POPUP = 'UPDATE_NEW_INFO_POPUP';

// UPDATE DISCOUNTED CREDITS POPUP STATUS
export const UPDATE_DISCOUNTED_CREDITS_POPUP_STATUS =
  'UPDATE_DISCOUNTED_CREDITS_POPUP_STATUS';

// GET ACTIVE PRODUCTS
export const FETCH_ACTIVE_PRODUCTS = 'FETCH_ACTIVE_PRODUCTS';
export const FETCH_ACTIVE_PRODUCTS_SUCCEED = 'FETCH_ACTIVE_PRODUCTS_SUCCEED';
export const FETCH_ACTIVE_PRODUCTS_FAILED = 'FETCH_ACTIVE_PRODUCTS_FAILED';

// GET PRODUCT BY CATEGORIES
export const FETCH_PRODUCT_BY_CATEGORIES = 'FETCH_PRODUCT_BY_CATEGORIES';
export const FETCH_PRODUCT_BY_CATEGORIES_SUCCEED =
  'FETCH_PRODUCT_BY_CATEGORIES_SUCCEED';
export const FETCH_PRODUCT_BY_CATEGORIES_FAILED =
  'FETCH_PRODUCT_BY_CATEGORIES_FAILED';

// GET PACKAGE PLAN DETAILS
export const FETCH_PACKAGE_PLAN_DETAILS = 'FETCH_PACKAGE_PLAN_DETAILS';
export const FETCH_PACKAGE_PLAN_DETAILS_SUCCEED =
  'FETCH_PACKAGE_PLAN_DETAILS_SUCCEED';
export const FETCH_PACKAGE_PLAN_DETAILS_FAILED =
  'FETCH_PACKAGE_PLAN_DETAILS_FAILED';

// UPDATE PRODUCT BUNDLE
export const UPDATE_PRODUCT_BUNDLE = 'UPDATE_PRODUCT_BUNDLE';

// GET PRODUCT CAROUSEL
export const FETCH_PRODUCT_CAROUSEL = 'FETCH_PRODUCT_CAROUSEL';
export const FETCH_PRODUCT_CAROUSEL_SUCCEED = 'FETCH_PRODUCT_CAROUSEL_SUCCEED';
export const FETCH_PRODUCT_CAROUSEL_FAILED = 'FETCH_PRODUCT_CAROUSEL_FAILED';

// GET PRODUCT TRUSTED LOGO
export const FETCH_PRODUCT_TRUSTED_LOGO = 'FETCH_PRODUCT_TRUSTED_LOGO';
export const FETCH_PRODUCT_TRUSTED_LOGO_SUCCEED =
  'FETCH_PRODUCT_TRUSTED_LOGO_SUCEED';
export const FETCH_PRODUCT_TRUSTED_LOGO_FAILED =
  'FETCH_PRODUCT_TRUSTED_LOGO_FAILED';

  // GET HOMEPAGE BANNER
export const FETCH_HOMEPAGE_BANNER = 'FETCH_HOMEPAGE_BANNER';
export const FETCH_HOMEPAGE_BANNER_SUCCEED =
  'FETCH_HOMEPAGE_BANNER_SUCCEED';
export const FETCH_HOMEPAGE_BANNER_FAILED =
  'FETCH_HOMEPAGE_BANNER_FAILED';

// GET MAX COUNT FOR LIMITED PRODUCT
export const UPDATE_LIMITED_PRODUCT = 'UPDATE_LIMITED_PRODUCT';

// CLEAR PRODUCT INFO
export const CLEAR_PRODUCT_INFO = 'CLEAR_PRODUCT_INFO';

// UPDATE PRODUCT INACTIVE POPUP
export const UPDATE_PRODUCT_INACTIVE_POPUP = 'UPDATE_PRODUCT_INACTIVE_POPUP'
