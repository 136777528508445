import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../styles/colors.module.scss';

export const FilterTitle = styling('span')({
  fontSize: '14px',
  fontWeight: '700',
});

export const Row = styling('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 15px',

  '&:hover': { 
    background: 'rgba(81, 42, 204, 0.1)',
  }
});

