import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ViewState from './ViewState';
import EditState from './EditState';
import InfoPopUp from './components/InfoPopup';
import VideoModal from './components/VideoModal';
import {
  updateCompanySidebar
} from '../../../redux/actions/company_actions';

export default function SocialMediaHighlights() {
  const [view, setView] = useState(true);
  const companyProfile = useSelector(state => state.companies.companyProfile);
  const [infoOpen, setInfoOpen] = useState(false);
  const dispatch = useDispatch();
  const companyCompletion = useSelector(
    state => state.companies.companyCompletion
  );

  useEffect(() => {
    if (companyProfile?.socialMediaLinks?.length > 0) {
      setView(true);
      dispatch(
        updateCompanySidebar({
          ...companyCompletion,
          socialMediaHighlights: true
        })
      );
    } else {
      setView(false);
      dispatch(
        updateCompanySidebar({
          ...companyCompletion,
          socialMediaHighlights: false
        })
      );
    }
  }, []);

  const selectVideo = video => {
    setSelected(video);
  };

  const onChangeVideo = id => {
    setVideos(prevVideos => {
      const updatedVideos = prevVideos.map((video, index) => {
        if (video.id === id) {
          return {
            ...video,
            content: [
              {
                url: link,
                status: 'In Review'
              }
            ],
            add: false
          };
        }
        return video;
      });

      const updatedSelectedVideo = updatedVideos.find(video => video.id === id);
      setSelected(updatedSelectedVideo);

      return updatedVideos;
    });
  };

  const onChangeLoading = (id, state) => {
    setVideos(prevVideos => {
      return prevVideos.map(video => {
        if (video.id === id) {
          return {
            ...video,
            loading: state
          };
        }
        return video;
      });
    });
  };

  const onChangeAdd = (id, state) => {
    setVideos(prevVideos => {
      return prevVideos.map(video => {
        if (video.id === id) {
          return {
            ...video,
            add: state
          };
        }
        return video;
      });
    });
  };

  const [progress, setProgress] = React.useState(0);
  const simulateProgress = () => {
    let currentProgress = 0;
    const intervalId = setInterval(() => {
      currentProgress += 10;
      if (currentProgress >= 100) {
        onChangeLoading(selected?.id, false);
        onChangeAdd(selected?.id + 1, true);
        clearInterval(intervalId);
      }
      setProgress(currentProgress);
    }, 300);
  };

  const saveUrl = () => {
    onChangeLoading(selected?.id, true);
    simulateProgress();
    onChangeVideo(selected?.id);
    setLink('');
  };

  const moveVideo = (dragIndex, hoverIndex) => {
    const newVideos = [...videos];
    const [draggedVideo] = newVideos.splice(dragIndex, 1);
    newVideos.splice(hoverIndex, 0, draggedVideo);
    setVideos(newVideos);
  };

  useEffect(() => {
    if (companyProfile?.socialMediaHighlights?.length > 0) {
      setView(true);
    } else {
      setView(false);
    }
  }, [companyProfile]);

  return (
    <div id="Social Media Highlights">
      {view ? (
        <ViewState setView={setView} setInfoOpen={setInfoOpen} />
      ) : (
        <EditState
          view={view}
          setView={setView}
          infoOpen={infoOpen}
          setInfoOpen={setInfoOpen}
        />
      )}
      <VideoModal />
      <InfoPopUp
        open={infoOpen}
        handleClose={() => {
          setInfoOpen(false);
        }}
      />
    </div>
  );
}
