import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanyName } from '../../redux/actions/company_actions';
import { MANAGE_CANDIDATE_MODE } from '../../utils/Constants';
import Board from './Board';
import List from './List';

const ManageCandidates = () => {
  const dispatch = useDispatch()
  const manageCandidateMode = localStorage.getItem(MANAGE_CANDIDATE_MODE);
  const [isListView, setIsListView] = useState(
    manageCandidateMode === 'List View' || manageCandidateMode == null
  );

  const toggleView = () => {
    setIsListView(!isListView);
  };

  useEffect(() => {
    dispatch(fetchCompanyName());
    const manageMode =
      manageCandidateMode == null || manageCandidateMode === 'List View'
        ? 'list-view'
        : 'ats-view';

    window.dataLayer.push({
      event: manageMode
    });
  }, []);

  const myCompany = useSelector(state => state.companies.myCompany);

  return (
    <>
      {isListView ? (
        <List
          toggleView={toggleView}
          isListView={isListView}
          companyData={myCompany}
        />
      ) : (
        <Board
          toggleView={toggleView}
          isListView={isListView}
          companyData={myCompany}
        />
      )}
    </>
  );
};

export default ManageCandidates;
