import { Button } from '@mui/material';
import { styled as styling } from '@mui/material/styles';

export const ButtonStyled = styling(Button)((props) => ({
  ...(props.type == 'basic' && {
    borderRadius: '26.5px',
    background: '#512acc',
    alignSelf: 'center',
    color: '#FFF',
    fontWeight: 700,
    padding: '8px 38px',
    textTransform: 'capitalize',

    '&:hover': {
      background: '#512acc',
    }
  }),

  ...(props.type == 'outlined_black' && {
    borderRadius: '26.5px',
    background: 'transparent',
    alignSelf: 'center',
    color: '#222',
    fontWeight: 700,
    padding: '4px 25px',
    border: '2px solid #222',
    textTransform: 'capitalize',
    fontSize: '14px',
    minWidth: '150px',
  }),
}));
