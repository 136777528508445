import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { updateProductExpiryDateIndex } from '../../../../../../redux/actions/job_action';
import { Container, TextContainer, Text } from './styles';

const ExpiryDatePopover = ({ applyFilter, types }) => {
  const dispatch = useDispatch();

  const productExpiryDateIndex = useSelector(
    state => state.jobs.productExpiryDateIndex
  );

  const onClickType = index => {
    dispatch(updateProductExpiryDateIndex(index)).then(() => {
      applyFilter();
    });
  };

  return (
    <Container>
      {types.map((item, index) => {
        return (
          <TextContainer
            highlight={`${productExpiryDateIndex == index}`}
            onClick={() => onClickType(index)}>
            <Text>{item}</Text>
          </TextContainer>
        );
      })}
    </Container>
  );
};

ExpiryDatePopover.propTypes = {
  applyFilter: PropTypes.func,
  types: PropTypes.array
};

ExpiryDatePopover.defaultProps = {
  applyFilter: () => {},
  types: []
};

export default ExpiryDatePopover;
