import { useState } from 'react';
import { useFormContext } from '../context';
import { useNavigate } from 'react-router-dom';
import {
  mutateUpdateJob,
  mutateCreateJob,
  updateJobDataForm,
  updateSelectedFilter,
  updateSelectedFilterValue
} from '../../../../redux/actions/job_action';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorToast, SuccessToast } from '../../../../utils/ToastUtils';
import { checkError } from '../helper/checkError';
import * as yup from 'yup';
import { validationSchema } from '../helper/validation';

export default function useEditLogic(props) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const jobDataForm = useSelector(state => state?.jobs?.jobDataForm);
  const [loading, setLoading] = useState(false);

  const [openTokenPopUp, setOpenTokenPopUp] = useState(false);

  if (props?.confirm) {
    const onSaveJobConfirm = async () => {
      setLoading(true);

      try {
        const res = await dispatch(mutateUpdateJob({ input: jobDataForm }));

        if (res?.type === 'MUTATE_UPDATE_JOB_SUCCEED') {
          history('/jobs');
          SuccessToast('Job updated!');
          dispatch(updateJobDataForm());

          dispatch(updateSelectedFilterValue('active'));
          dispatch(updateSelectedFilter(true));
        } else {
          ErrorToast(res?.error);
        }
      } catch (error) {
        ErrorToast('An error occurred while updating the job.');
      } finally {
        setLoading(false);
      }
    };

    return { onSaveJobConfirm, loading };
  }

  const { formState, showIntern, showSenior, handleErrorState } =
    useFormContext();

  const buttonText = formState?.active ? 'Update Job' : 'Reactive Job';

  let jobTitle;
  if (showIntern) {
    jobTitle = 'intern';
  } else if (showSenior) {
    jobTitle = 'senior';
  }

  const requiredFields = [
    'location',
    'stateRegion',
    'title',
    'description',
    'requirements',
    'careerLevel',
    'skills',
    'jobType',
    'tracks',
    'customEmail'
    // 'minSalaryRange',
    // 'maxSalaryRange'
  ];

  const saveJob = async () => {
    try {
      await validationSchema.validate(formState, { abortEarly: false });
      const error = checkError(formState, requiredFields);

      if (error?.length > 0) {
        handleErrorState(error);
        return;
      }

      dispatch(updateJobDataForm(formState));
      if (showIntern || showSenior) {
        setOpenTokenPopUp(true);
        return;
      }

      history(`/jobs/${formState?.id}/job-type`);
    } catch (err) {
      let errors;
      err.inner.forEach(error => {
        errors = error.message;
      });

      ErrorToast(errors);
    }
  };

  return { buttonText, saveJob, openTokenPopUp, setOpenTokenPopUp, jobTitle };
}
