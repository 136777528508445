import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Divider, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { MUIButtonStyled } from '../../../../components/MUIButton';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import {
  ButtonContainer,
  ContentContainer,
  ContentWrapper,
  CurrentPrice,
  CurrentPriceContainer,
  FontAwesomeIconStyled,
  PreviousPrice,
  PriceSaved,
  TermConditionInfo,
  TermConditionInfoWrapper,
  TermConditionTitle,
  Title,
  TitleContainer
} from './styles';
import { mutateCart } from '../../../../redux/actions/order_action';
import { useDispatch } from 'react-redux';
import { getCurrency } from '../../../../hooks/useRegionalisation';

const DiscountedJobCreditsPopUp = ({ open, handleClose }) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      PaperProps={{
        style: {
          maxWidth: '464px',
          borderRadius: '8px'
        }
      }}>
      <ContentWrapper>
        <TitleContainer>
          <Title>Discounted Job Credits</Title>
          <IconButton style={{ padding: 0 }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </TitleContainer>

        <Divider />

        <ContentContainer>
          <PreviousPrice>was {getCurrency(800)} </PreviousPrice>
          <CurrentPriceContainer>
            <CurrentPrice>Now {getCurrency(350)} </CurrentPrice>
            <PriceSaved>
              <FontAwesomeIconStyled icon={faBolt} />
              Saved {getCurrency(450)}
            </PriceSaved>
          </CurrentPriceContainer>
          <TermConditionTitle>T&C:</TermConditionTitle>
          <TermConditionInfoWrapper>
            <TermConditionInfo>
              Job posting will be advertised for 1 month.
            </TermConditionInfo>
            <TermConditionInfo margin_top="true">
              Unused credits will expire 30 days after purchase.
            </TermConditionInfo>
          </TermConditionInfoWrapper>
        </ContentContainer>
        <ButtonContainer>
          <MUIButtonStyled
            label="Purchase Now"
            onClick={async () => {
              if (handleClose) handleClose();
              // TO CHANGE:
              await dispatch(
                mutateCart({
                  input: {
                    productId: '373',
                    quantity: 1
                  }
                })
              );
              history(`/purchase/choose`);
            }}
            solidBlack
            large
          />
        </ButtonContainer>
      </ContentWrapper>
    </Dialog>
  );
};

export default DiscountedJobCreditsPopUp;
