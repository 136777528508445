import React from 'react';
import styles from './index.module.scss';

const Name = ({ index, tableData }) => {
  const data = tableData[index];
  return (
    <div>
      <p className={styles.title}>{data?.name}</p>
      <p className={styles.title}>{data?.position}</p>
      <p className={styles.title}>{data?.email}</p>
    </div>
  );
};
export default Name;
