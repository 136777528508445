export const UPDATE_TALENT_SEARCH_CHAT_STATUS =
  'UPDATE_TALENT_SEARCH_CHAT_STATUS';

// FOR FETCHING TALENT CHATS
export const FETCH_TALENT_CHATS = 'FETCH_TALENT_CHATS';
export const FETCH_TALENT_CHATS_SUCCEED = 'FETCH_TALENT_CHATS_SUCCEED';
export const FETCH_TALENT_CHATS_FAILED = 'FETCH_TALENT_CHATS_FAILED';

// FOR TALENT CHATS
export const UPDATE_TALENT_CHATS = 'UPDATE_TALENT_CHATS';

export const UPDATE_CHAT_READ_STATUS_FAIL = 'UPDATE_CHAT_READ_STATUS_FAIL';
export const UPDATE_CHAT_READ_STATUS_SUCCESS =
  'UPDATE_CHAT_READ_STATUS_SUCCESS';

export const UPDATE_COMPANY_CHAT_UNREAD_COUNT =
  'UPDATE_COMPANY_CHAT_UNREAD_COUNT';

// FETCH JOB CHATS
export const FETCH_JOB_CHATS = 'FETCH_JOB_CHATS_SUCCEED';
export const FETCH_JOB_CHATS_SUCCEED = 'FETCH_JOB_CHATS_SUCCEED';
export const FETCH_JOB_CHATS_FAILED = 'FETCH_JOB_CHATS_FAILED';

// MUTATE SENDBIRD CHANNEL URL
export const MUTATE_SENDBIRD_CHANNEL_URL = 'MUTATE_SENDBIRD_CHANNEL_URL';
export const MUTATE_SENDBIRD_CHANNEL_URL_SUCCEED =
  'MUTATE_SENDBIRD_CHANNEL_URL_SUCCEED';
export const MUTATE_SENDBIRD_CHANNEL_URL_FAILED =
  'MUTATE_SENDBIRD_CHANNEL_URL_FAILED';
