import React from 'react';
import { Grid, Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Skeleton } from '@mui/material';

const useStyles = makeStyles(() => ({
  header: { fontSize: 16, fontFamily: 'Inter' },
  chipWrapper: { display: 'flex', padding: 5 },
  chip: { height: 30, width: 100, borderRadius: 25 },
  skeletonText: { margin: '15px 30px 0px 0px' }
}));

const SkeletonLoader = () => {
  const classes = useStyles();

  const renderHeader = () => (
    <div style={{ display: 'flex' }}>
      <Grid container>
        <Grid item xs={7}>
          <h6 style={{ fontSize: '20px' }} className={classes.header}>
            Company
          </h6>
        </Grid>
        <Grid item xs={3}>
          <h6 style={{ fontSize: '20px' }} className={classes.header}>
            Relationship
          </h6>
        </Grid>
        <Grid item xs={2}>
          <h6 style={{ fontSize: '20px' }} className={classes.header}>
            Status
          </h6>
        </Grid>
      </Grid>
    </div>
  );

  const renderRow = () => (
    <div>
      <Skeleton height={3} style={{ marginTop: 20 }} />
      <div style={{ display: 'flex', padding: '20px 20px' }}>
        <Grid container>
          {/* Name */}
          <Grid item xs={7}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: -15
              }}>
              <Skeleton
                variant="circle"
                animation="wave"
                style={{ height: 80, width: 80, marginRight: 25 }}
              />
              <div>
                <Skeleton variant="text" animation="wave" width={150} />
                <Skeleton variant="text" animation="wave" width={200} />
              </div>
            </div>
          </Grid>

          {/* Relationship */}
          <Grid item xs={3}>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                height: '100%',
                flexDirection: 'column'
              }}>
              <Skeleton variant="text" animation="wave" width={100} />
              <Skeleton variant="text" animation="wave" width={150} />
            </div>
          </Grid>

          {/* Status */}
          <Grid item xs={2}>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                height: '100%',
                flexDirection: 'column'
              }}>
              <Skeleton variant="text" animation="wave" width={100} />
              <Skeleton variant="text" animation="wave" width={150} />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );

  return (
    <div>
      <Card style={{ padding: 30 }}>
        {renderHeader()}
        {renderRow()}
        {renderRow()}
        {renderRow()}
        {renderRow()}
        {renderRow()}
      </Card>
    </div>
  );
};

export default SkeletonLoader;
