import React, { useState } from 'react';
import { Select, MenuItem, Tooltip, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  FiberManualRecord as FiberManualRecordIcon,
  TrendingUpOutlined
} from '@mui/icons-material';
import { JOB_APPLICATION_STATUS } from '../../utils/Constants';
import { ButtonContainer } from './styles';
import Colors from '../../styles/colors.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import styles from './index.module.scss';
import {
  toggleRejectPopup,
  updateCandidateSnapshotStatus
} from '../../redux/actions/application_action';
import RejectPopUp from '../../pages/Jobs/PopUps/RejectPopUp';
import SplitButton from '../SplitButton';
import StatusSelect from './StatusSelect';
import { CustomButton } from './CustomButton';
import BlackListPopUp from '../../pages/ManageCandidates/List/CandidateInformation/BlackListPopUp';

const StatusDropdown = ({
  isCandidatesEmpty,
  applicationState,
  isSelected,
  loading,
  changeLoading,
  onChangeJobApplicationStatus,
  setIsBlacklistOpen,
  setIsOfferJobOpen,
  item,
  applicationStatus,
  candidateJobApplication,
  setApplicationState,
  isBlacklistOpen,
  setJobData,
  setCandidates,
  setCurrentCandidates,
  getCurrentCandidates
}) => {
  const dispatch = useDispatch();
  const application = item?.jobApplication ? item.jobApplication : item;
  const isHired = application?.employerHiredStatus ? true : false;

  const isOffered = () => {
    if (item?.state == 'offered') {
      return true;
    }

    if (item?.offered) {
      return true;
    }

    return false;
  };

  const isOfferedOrHired = () => {
    if (isOffered() || isHired) return true;

    return false;
  };

  const getOfferButtonMessage = () => {
    if (isHired) return 'Hired';
    if (isOffered()) return 'Offered';

    return 'Make Offer';
  };

  const onOfferButtonClicked = () => {
    setIsOfferJobOpen(true);
  };

  const rejectPopupStatus = useSelector(
    state => state.applications.rejectPopupStatus
  );

  const allowNextInterview = candidate => {
    let application = candidate?.jobApplication
      ? candidate.jobApplication
      : candidate;

    const interviewLength = application?.interviews
      ? application.interviews.length
      : 0;

    if (interviewLength > 0) {
      const lastInterview = application.interviews[0];

      // Disable next interview if last interview already rejected or offered
      if (
        lastInterview.status == 'rejected' ||
        lastInterview.status == 'offered'
      ) {
        return false;
      }
    }

    return true;
  };

  //Note:
  // applicationState = candidate.state
  // applicationStatus = selected arrow filter
  // candidateJobApplication = candidate.jobApplication

  return (
    <div className={styles.statusContainer}>
      {!applicationState ||
      isCandidatesEmpty ||
      (isSelected == null && loading) ? null : applicationState ==
          JOB_APPLICATION_STATUS.REJECTED ||
        applicationState == JOB_APPLICATION_STATUS.BLACKLISTED ? (
        <>
          <StatusSelect
            disabled={
              !allowNextInterview(candidateJobApplication) ||
              applicationState == JOB_APPLICATION_STATUS.REJECTED ||
              applicationState == JOB_APPLICATION_STATUS.BLACKLISTED
            }
            value={applicationState}
            changeLoading={changeLoading}
            onChange={async event => {
              if (event.target.value === JOB_APPLICATION_STATUS.BLACKLISTED) {
                setIsBlacklistOpen(true);
              } else {
                onChangeJobApplicationStatus({
                  value: event.target.value
                });
              }
            }}
          />
        </>
      ) : isOfferedOrHired() ? (
        <>
          <ButtonContainer>
            <CustomButton
              type={isOfferedOrHired() ? 'disabled' : 'basic'}
              disabled={isOfferedOrHired()}
              onClick={onOfferButtonClicked}
              child={getOfferButtonMessage()}
            />
          </ButtonContainer>
        </>
      ) : applicationState == '' ||
        applicationStatus == JOB_APPLICATION_STATUS.INTERVIEW ||
        candidateJobApplication?.interviews?.length > 0 ? (
        <>
          <ButtonContainer>
            <CustomButton
              type={isOfferedOrHired() ? 'disabled' : 'basic'}
              disabled={isOfferedOrHired()}
              onClick={onOfferButtonClicked}
              child={getOfferButtonMessage()}
            />
          </ButtonContainer>
          <SplitButton
            handleClick={() => dispatch(toggleRejectPopup(true))}
            firstButtonLabel={'Reject'}
            onChangeJobApplicationStatus={onChangeJobApplicationStatus}
            setIsBlacklistOpen={setIsBlacklistOpen}
          />
          <BlackListPopUp
            open={isBlacklistOpen}
            handleClose={() => setIsBlacklistOpen(false)}
            onChangeJobApplicationStatus={onChangeJobApplicationStatus}
            updateCandidateSnapshotStatus={() =>
              dispatch(updateCandidateSnapshotStatus())
            }
            candidateJobApplication={candidateJobApplication}
            setJobData={setJobData}
          />
        </>
      ) : (
        <>
          <StatusSelect
            changeLoading={changeLoading}
            dispatch={dispatch}
            disabled={changeLoading}
            value={applicationState}
            onChange={async event => {
              if (event.target.value === JOB_APPLICATION_STATUS.REJECTED) {
                dispatch(toggleRejectPopup(true));
              } else if (
                event.target.value === JOB_APPLICATION_STATUS.BLACKLISTED
              ) {
                setIsBlacklistOpen(true);
              } else {
                onChangeJobApplicationStatus({
                  value: event.target.value
                });
              }
            }}
          />
          <div style={{ width: '5px' }} />
          {(applicationState == JOB_APPLICATION_STATUS.SHORTLISTED ||
            applicationState == JOB_APPLICATION_STATUS.KIV) && (
            <CustomButton
              type={isOfferedOrHired() ? 'disabled' : 'basic'}
              disabled={isOfferedOrHired()}
              onClick={onOfferButtonClicked}
              child={getOfferButtonMessage()}
            />
          )}
        </>
      )}
      <RejectPopUp
        open={rejectPopupStatus}
        handleClose={() => dispatch(toggleRejectPopup(false))}
        onChangeJobApplicationStatus={onChangeJobApplicationStatus}
        interviewItem={
          candidateJobApplication?.interviews?.length > 0 &&
          candidateJobApplication?.interviews[0]
        }
        setApplicationState={setApplicationState}
        setJobData={setJobData}
        item={item}
        candidateJobApplication={candidateJobApplication}
        changeLoading={changeLoading}
        setCandidates={setCandidates}
        setCurrentCandidates={setCurrentCandidates}
        getCurrentCandidates={getCurrentCandidates}
      />
    </div>
  );
};

export default StatusDropdown;
