import { styled as styling } from '@mui/material/styles';
import { Skeleton } from '@mui/material';

//pipeline filter
export const FilterWrapper = styling('div')((props) => ({
  display: 'flex',

  ...(props.isAts == false && {
    '@media (max-width: 1024px)': {
      marginTop: '10px',
      flexWrap: 'wrap',
      marginLeft: '0px !important'
    },

    '@media (max-width: 1280px)': {
      flexWrap: 'nowrap',
      marginLeft: '0px !important'
    },

    '@media (max-width: 1600px)': {
      marginLeft: '20px',
      flexWrap: 'nowrap',
    },
    '@media (max-width: 3000px)': {
      marginLeft: '20px',
      flexWrap: 'nowrap',
    }
  }),

  ...(props.isAts == true && {
      flexWrap: 'nowrap',
  }),
}));

export const FirstArrowFilter = styling('div')((props) => ({
  position: 'relative',
  marginRight: '20px',
  width: '120px',
  height: '60px',
  backgroundColor: '#fff',
  filter: 'drop-shadow(0px 4px 17px rgba(0, 0, 0, 0.08))',
  borderRadius: '15px 0 0 15px',
  '&:before': {
    content: '""',
    position: 'absolute',
    borderTop: '0px solid transparent',
    borderRight: '100px solid transparent',
    borderBottom: '100px solid #fff',
    borderLeft: '100px solid transparent',
    transform: 'translateY(-20px) translateX(35px) rotate(90deg) scale(0.3)',
  },

  ...(props.isAts || props.count == 0
    ? {
        cursor: 'default',
      }
    : {
        cursor: 'pointer',
        '&:hover': {
          backgroundPosition: 'center',
          transition: 'background 0.3s',
          background: '#fff radial-gradient(circle, transparent 1%, #fff 1%) center/15000%',
        },
        '&:active': {
          backgroundPosition: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          backgroundSize: '100%',
          transition: 'background 0s',
        },
      }),
}));

export const ArrowFilter = styling('div')((props) => ({
  position: 'relative',
  margin: '0 20px',
  width: '85px',
  height: '60px',
  backgroundColor: '#fff',
  filter: 'drop-shadow(0px 4px 17px rgba(0, 0, 0, 0.08))',
  '&:before': {
    content: '""',
    position: 'absolute',
    borderTop: '0px solid transparent',
    borderRight: '100px solid transparent',
    borderBottom: '100px solid #fff',
    borderLeft: '100px solid transparent',
    transform: 'translateY(-20px) translateX(0px) rotate(90deg) scale(0.3)',
  },

  '&:after': {
    content: '""',
    position: 'absolute',
    borderTop: '100px solid transparent',
    borderRight: '100px solid transparent',
    borderBottom: '100px solid #fff',
    borderLeft: '0px solid transparent',
    transform: 'translateX(-80px) translateY(-55px) rotate(270deg) scale(0.3)',
  },

  ...(props.isAts == true || props.count == 0
    ? {
        cursor: 'default',
      }
    : {
        cursor: 'pointer',
        '&:hover': {
          backgroundPosition: 'center',
          transition: 'background 0.3s',
          background: '#fff radial-gradient(circle, transparent 1%, #fff 1%) center/15000%',
        },
        '&:active': {
          backgroundPosition: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          backgroundSize: '100%',
          transition: 'background 0s',
        },
      }),
}));

export const ArrowFilterChild = styling('span')({
  '&:before': {
    content: '""',
    position: 'absolute',
    borderTop: '100px solid transparent',
    borderRight: '100px solid transparent',
    borderBottom: '100px solid #fff',
    borderLeft: '0px solid transparent',
    transform: 'translateX(-65px) translateY(-70px) rotate(180deg) scale(0.3)',
  },
});

export const FilterTextContainer = styling('div')((props) => ({
  ...(props.type == 'count' && {
    position: 'absolute',
    top: '30%',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    maxWidth: '100px',
  }),
  ...(props.type == 'status' && {
    position: 'absolute',
    top: '70%',
    transform: 'translate(-50%, -50%)',
    left: '50%',
  }),
}));

export const FilterText = styling('p')((props) => ({
  ...(props.type == 'status' && {
    fontWeight: 400,
    fontFamily: 'Inter',
    fontSize: '11px',
    textTransform: 'capitalize',
  }),
  ...(props.type == 'count' && {
    fontWeight: 700,
    fontFamily: 'Inter',
    fontSize: '15px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),

  ...(props.selected == 'true' && {
    color: '#512acc',
  }),

  ...(props.count == 0 && {
    color: 'rgba(0, 0, 0, 0.26)',
  }),

}));

export const SkeletonContainer = styling('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const SkeletonStyled = styling(Skeleton)({
  borderRadius: '5px',
});
