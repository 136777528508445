import { createTheme } from '@mui/material/styles';

const palette = {
  primary: {
    main: '#512acc',
    light: '#efeffd',
    dark: '#40249b'
  },
  secondary: {
    main: '#983eba'
  },
  white: {
    main: '#ffffff',
    secondary: '#efefef'
  },
  black: {
    main: '#222222'
  },
  grey: {
    light: '#d8d8d8',
    medium: '#aeaeae',
    50: '#fafafa',
    100: '#f5f5f5', // $terLighterGrey
    200: '#00000060',
    300: '#d8d8d8', // $terLightGrey
    400: '#aeaeae', // $terMediumGrey
    500: '#9e9e9e',
    600: '#7a7a7a',
    700: '#666666', // $terDarkGrey
    800: '#424242',
    900: '#212121'
  },
  red: {
    primary: '#be4242',
    50: '#feeaec',
    100: '#fdccce',
    200: '#e99a95',
    300: '#dc736b',
    400: '#e35646',
    500: '#e6472c',
    600: '#d93e2b',
    700: '#c73426',
    800: '#ba2d20',
    900: '#ab2514'
  },
  blue: {
    secondary: '#213db5'
  },
  orange: {
    secondary: '#f2af29'
  },
  purple: {
    main: '#512acc',
    secondary: '#983eba',
    50: '#ede7fc',
    100: '#d0c2f7',
    200: '#b19af2',
    300: '#8e70ef',
    400: '#7050eb',
    500: '#4e2ee6',
    600: '#3e2be0',
    700: '#2222d8',
    800: '#001dd1',
    900: '#0010ca'
  },
  yellow: {
    50: '#fff7e1',
    100: '#ffebb4',
    200: '#ffdd82',
    300: '#ffd151',
    400: '#ffc72d',
    500: '#ffbc13',
    600: '#ffaf0f',
    700: '#ff9c12',
    800: '#fd8b12',
    900: '#fe6b12'
  },
  green: {
    primary: '#90B938',
    50: '#e3f7ec',
    100: '#bdece0',
    200: '#8fdeb2',
    300: '#58d292',
    400: '#13c77a',
    500: '#05bc62',
    600: '#00ad58',
    700: '#029a4c',
    800: '#028a3f',
    900: '#006929'
  }
};

const theme = createTheme({
  typography: {
    allVariants: {
      color: `rgba(0, 0, 0, 0.87)`,
      fontFamily: 'inter !important',
    }
  },

  palette: {
    primary: { ...palette.primary },
    grey: { ...palette.grey }, // Use the grey color scale
    red: { ...palette.red }, // Use the red color scale
    purple: { ...palette.purple }, // Use the purple color scale
    yellow: { ...palette.yellow }, // Use the yellow color scale
    green: { ...palette.green }, // Use the green color scale
    white: { ...palette.white },
    black: { ...palette.black }
  },
  breakpoints: {
    values: {
      xs: 0,
      mobile: 360,
      mobileL: 480,
      sm: 600,
      tablet: 768,
      tabletS: 780,
      md: 960,
      tabletL: 1200,
      lg: 1280,
      lgXl: 1600,
      desktop: 1440,
      xl: 1920,
      desktopL: 2560
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif'
    ].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700
  },

  MUIDataTableHeadCell: {
    fixedHeader: {
      // check does it affect the MUI DataTable
      // paddingLeft: 40, <-- ManageTeamMembers using
      paddingLeft: 30, // <-- ManageAffiliates using
      fontSize: 16,
      fontFamily: 'Inter'
    },
    sortAction: {
      fontSize: 16,
      fontFamily: 'Inter',
      alignItems: 'center'
    }
  },
  MuiChip: {
    deleteIcon: {
      color: 'black'
    }
  },
  MuiTableCell: {
    body: {
      padding: '10px 30px 10px 30px',
      fontSize: 16,
      fontFamily: 'Inter'
    }
  },
});

export default theme;
