import { styled as styling } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  MenuItem,
  IconButton,
  Tooltip,
  Select,
  Avatar,
  Button,
  CircularProgress
} from '@mui/material';
import {
  Email as EmailIcon,
  Chat as ChatIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  GetApp as GetAppIcon,
  FiberManualRecord as FiberManualRecordIcon
} from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

export const MainContainer = styling('div')({
  background: '#fff',
  padding: '25px 34px 25px 34px',
  borderRadius: '24px 24px 0 0',

  '@media (max-width: 480px)': {
    background: '#fff',
    width: '100%',
    padding: '25px 34px 25px 34px',
    borderRadius: '24px 24px 0 0'
  }
});

export const FirstContainer = styling('div')({
  display: 'grid',
  gridTemplateColumns: '30% auto',
  minHeight: '180px'
});

export const ImageContainer = styling('div')({
  display: 'flex',
  alignItems: 'center'
});

export const ProfilePicture = styling(Avatar)({
  width: '90px',
  height: '90px',
  borderRadius: '50%',
  background: 'pink'
});

export const InfoContainer = styling('div')({});

export const SubInfoContainer = styling('div')({
  margin: '0 5px 13px 0'
});

export const Name = styling('div')({
  fontWeight: '700',
  fontSize: '18px',
  fontFamily: 'Inter'
});

export const IdentityContainer = styling('div')({
  display: 'flex'
});

export const Age = styling(Typography)({
  color: '#666666',
  fontSize: '12px',
  fontWeight: 400,
  fontFamily: 'Inter',
  margin: '5px 0'
});

export const Dot = styling('div')({
  width: '3px',
  height: '3px',
  borderRadius: '50%',
  background: '#666666',
  display: 'flex',
  alignSelf: 'center',
  margin: '0 3px'
});

export const Nationality = styling(Typography)({
  color: '#666666',
  fontSize: '12px',
  fontWeight: 400,
  fontFamily: 'Inter',
  margin: '5px 0'
});

export const ShortSummaryContainer = styling('div')({
  minHeight: '90px',
  overflow: 'auto',
  maxHeight: '90px'
});

export const ShortSummary = styling(Typography)({
  fontFamily: 'Inter',
  fontSize: '13px',
  margin: '5px 0'
});

export const SelectStyled = styling(Select)(({ theme }) => ({
  borderRadius: '26.5px',
  boxShadow: `0px 4px 20px rgba(0, 0, 0, 0.15)`,
  background: '#fff',
  alignSelf: 'center',
  padding: '5px 0',

  '& .MuiSelect-selectMenu': {
    padding: '0'
  }
}));

export const MenuItemStyled = styling(MenuItem)({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  padding: '0'
});

export const FiberManualRecordIconStyled = styling(FiberManualRecordIcon)(
  ({ color }) => ({
    fontSize: '15px',
    alignSelf: 'center',
    marginRight: '5px',
    color:
      color == 'grey'
        ? '#d8d8d8'
        : color == 'green'
        ? '#0ea209'
        : color == 'yellow'
        ? '#ffa329'
        : color == 'red'
        ? '#ce343c'
        : '#222222'
  })
);

export const StatusContainer = styling('div')({
  display: 'flex',
  padding: '5px 30px',
  justifyContent: 'center',
  transform: 'translateX(-10px)'
});

export const StatusText = styling('div')({
  fontSize: '14px',
  fontWeight: 700,
  fontFamily: 'Inter'
});

export const IconsContainer = styling('div')(props => ({
  display: 'flex',
  alignSelf: 'center',
  justifyContent: 'space-between',
  flex: 1,
  padding: '0 10px',
  maxWidth: '120px',
  ...(props.$hideOfferInterviewHired && {
    transform: 'translateX(-30px)'
  })
}));

export const IconWrapper = styling('div')({
  position: 'relative'
});

export const IconButtonStyled = styling(IconButton)({
  padding: '0'
});

export const FontAwesomeIconStyled = styling(FontAwesomeIcon)(
  ({ awesome_icon, interview_length }) => ({
    ...(awesome_icon == 'faCalenderAlt' && {
      color: '#222',
      fontSize: '18px'
    }),

    ...(interview_length > 0 && {
      color: '#512acc',
      fontSize: '18px'
    })
  })
);

export const GetAppIconStyled = styling(GetAppIcon)({
  fontSize: '20px',
  color: '#222'
});

export const EmailIconStyled = styling(EmailIcon)({
  fontSize: '20px',
  color: '#222'
});

export const ArrowsContainer = styling('div')({
  display: 'flex',
  alignItems: 'center'
});

export const ArrowButtonWrapper = styling('div')({
  margin: '0 5px'
});

export const ArrowIconButton = styling(IconButton)(props => ({
  padding: '0',
  borderRadius: '26.5px',
  boxShadow: '0px 4px 17px rgba(0, 0, 0, 0.12)',
  background: '#fff',
  alignSelf: 'center',

  ...(props.disabled == true && {
    background: 'transparent',
    boxShadow: 'none'
  })
}));

export const ChevronRightIconStyled = styling(ChevronRightIcon)(props => ({
  fontSize: '22px',
  color: '#222',

  ...(props.disabled == true && {
    color: '#e1e1e1'
  })
}));

export const ChevronLeftIconStyled = styling(ChevronLeftIcon)(props => ({
  fontSize: '22px',
  color: '#222',

  ...(props.disabled == true && {
    color: '#e1e1e1'
  })
}));

export const SecondContainer = styling('div')({
  margin: '5px auto 0',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  '@media (max-width: 480px)': {
    flexWrap: 'wrap'
  }
});

export const ButtonStyled = styling(Button)(props => ({
  ...(props.type == 'basic' && {
    borderRadius: '26.5px',
    boxShadow: '0px 4px 20px rgb(0 0 0 / 15%)',
    background: '#512acc',
    alignSelf: 'center',
    color: '#FFF',
    fontWeight: 700,
    padding: '5px 20px',
    fontSize: '12px',
    fontFamily: 'Inter',

    '&:hover': {
      background: '#512acc'
    }
  }),

  ...(props.type == 'disabled' && {
    borderRadius: '26.5px',
    boxShadow: '0px 4px 20px rgb(0 0 0 / 15%)',
    alignSelf: 'center',
    fontWeight: 700,
    padding: '5px 20px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    fontSize: '12px',
    fontFamily: 'Inter',
    color: 'rgba(0, 0, 0, 0.26)',

    ':hover': {
      color: 'white'
    }
  })
}));

export const InterviewLengthContainer = styling('div')({
  position: 'absolute',
  top: '0',
  right: '0'
});

export const InterviewLength = styling('p')({
  fontSize: '11px',
  margin: '0',
  transform: 'translate(0px, -10px)',
  fontWeight: 'bold',
  color: '#512acc'
});

export const CircularProgressStyled = styling(CircularProgress)({
  color: '#512acc'
});

export const Text = styling('span')({
  fontSize: '12px',
  fontFamily: 'Inter'
});
