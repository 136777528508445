import React, { useRef, useState } from 'react';
import InfoPopup from '../../SharedPopups/InfoPopup';
import ExclusivePlusMax from '../../../../assets/images/products/exclusive+max.gif';
import Flexibly from '../../../../assets/images/products/flexibly.gif';
import TalentSearch from '../../../../assets/images/products/talent_search.gif';
import AshleyEdit from '../../../../assets/images/products/ashely_edit.gif';
import Cbt from '../../../../assets/images/products/cbt.gif';
import GlobalHire from '../../../../assets/images/products/global_hire.gif';
import Canvas1 from '../../../../assets/images/products/new_canvas_2.gif';
import Canvas2 from '../../../../assets/images/products/new_canvas_1.gif';
import { MUIButtonStyled } from '../../../../components/MUIButton';
import {
  ButtonsContainer,
  ContentContainer,
  ContentDescription,
  MoreInfo,
  NavigateToPricing,
  PageNumber
} from './styles';
import { HeightSpacer } from '../../../../components/Spacer/styles';
import { useDispatch, useSelector } from 'react-redux';
import { updateNewInfoPopupStatus } from '../../../../redux/actions/product_action';
import { openInNewTab } from '../../../../utils/WindowUtils';
import { ArrowSmallLeftIcon } from '@heroicons/react/24/solid';
import { Grid, IconButton } from '@mui/material';
import Colors from '../../../../styles/colors.module.scss';

// 0 - ashley's edit
// 1 - talent search
// 2 - job slot pro and max
// 3 - flexibly

const totalPages = 5;

const NewProductsPopup = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const isNewInfoPopupOpen = useSelector(
    state => state.products.isNewInfoPopupOpen
  );
  const contentRef = useRef(null);

  const onClosePopup = type => {
    try {
      if (!localStorage.getItem('hideNewProductsInfo')) {
        localStorage.setItem('hideNewProductsInfo', true);
      }

      if (type == 'close-button') {
        window.dataLayer.push({
          event: 'CE_click-whats-new-popup-close'
        });
      }

      dispatch(updateNewInfoPopupStatus(false));
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <InfoPopup
      content={<Content currentPage={currentPage} contentRef={contentRef} />}
      buttons={
        <Buttons
          contentRef={contentRef}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          onClosePopup={onClosePopup}
        />
      }
      $dialogMaxWidth={'800px'}
      open={isNewInfoPopupOpen}
      onClosePopup={onClosePopup}
      title={'What’s New?'}
      $dialogMaxHeight={'600px'}
    />
  );
};

export default NewProductsPopup;

export const Content = ({ currentPage, contentRef }) => {
  // const onNavigate = () => {
  //   try {
  //     openInNewTab('/purchase/choose#spotlight_token');
  //   } catch {
  //     console.log('error');
  //   }
  // };

  const description = () => {
    if (currentPage == 3) {
      return (
        <span
          style={{
            fontFamily: 'Inter',
            fontWeight: '400',
            fontSize: '14px',
            letterSpacing: '0.15px',
            color: 'rgba(0, 0, 0, 0.6)'
          }}>
          Enjoy the same features as our classic Job Slot, now with the
          convenience of automated visibility boosting and improved performance.
          Learn more{' '}
          <a
            style={{
              cursor: 'pointer',
              color: '#512acc',
              textDecoration: 'none',
              display: 'inlineBlock',
              borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
              paddingBottom: '1px'
            }}
            href="/welcome"
            target={'_blank'}>
            here
          </a>
          .
        </span>
      );
    } else if (currentPage == 4) {
      return (
        <span
          style={{
            fontFamily: 'Inter',
            fontWeight: '400',
            fontSize: '14px',
            letterSpacing: '0.15px',
            color: 'rgba(0, 0, 0, 0.6)'
          }}>
          We have made some adjustments to provide you full control over how
          your job ad will look like. After posting a job, simply choose the job
          ad type you would like to advertise with and we’ll take care of the
          rest.
        </span>
      );
    } else if (currentPage == 1) {
      return (
        <span
          style={{
            fontFamily: 'Inter',
            fontWeight: '400',
            fontSize: '14px',
            letterSpacing: '0.15px',
            color: 'rgba(0, 0, 0, 0.6)'
          }}>
          Advertise your job across other countries to attract international
          talents!
        </span>
      );
    } else if (currentPage == 2) {
      return (
        <span
          style={{
            fontFamily: 'Inter',
            fontWeight: '400',
            fontSize: '14px',
            letterSpacing: '0.15px',
            color: 'rgba(0, 0, 0, 0.6)'
          }}>
          Define your jobs' work arrangement with Global Hire to attract the
          right international talent!
        </span>
      );
    } else if (currentPage == 0) {
      return (
        <span
          style={{
            fontFamily: 'Inter',
            fontWeight: '400',
            fontSize: '14px',
            letterSpacing: '0.15px',
            color: 'rgba(0, 0, 0, 0.6)'
          }}>
          Seamlessly share your employer brand message in a consistent and
          impactful way across Hiredly’s portal. Feature your latest social
          media content
        </span>
      );
    }
  };

  const gifs = () => {
    if (currentPage == 3) {
      return ExclusivePlusMax;
    } else if (currentPage == 4) {
      return Flexibly;
    } else if (currentPage == 1) {
      return Cbt;
    } else if (currentPage == 2) {
      return GlobalHire;
    }
  };

  const getByTitle = () => {
    if (currentPage == 3) {
      return (
        <>
          <span
            style={{
              fontFamily: 'Inter',
              fontWeight: '400',
              fontSize: '16px',
              letterSpacing: '0.15px'
            }}>
            {currentPage + 1}. New <b>Job Slot +</b> and <b>Job Slot Max</b> now
            available!
          </span>
          <br />
          <HeightSpacer height={'12px'} />
        </>
      );
    }

    if (currentPage == 4) {
      return (
        <>
          <span
            style={{
              fontFamily: 'Inter',
              fontWeight: '400',
              fontSize: '16px',
              letterSpacing: '0.15px'
            }}>
            {currentPage + 1}. Flexibly choose your job ad when posting a job.
          </span>
          <br />
          <HeightSpacer height={'12px'} />
        </>
      );
    }

    if (currentPage == 1) {
      return (
        <>
          <span
            style={{
              fontFamily: 'Inter',
              fontWeight: '400',
              fontSize: '16px',
              letterSpacing: '0.15px'
            }}>
            {currentPage + 1}. New <b>Cross Border Tokens </b>now available!
          </span>
          <br />
          <HeightSpacer height={'12px'} />
        </>
      );
    }

    if (currentPage == 0) {
      return (
        <>
          <span
            style={{
              fontFamily: 'Inter',
              fontWeight: '400',
              fontSize: '16px',
              letterSpacing: '0.15px'
            }}>
            {currentPage + 1}. Share your brand story with <b>Company Canvas</b>
            !
          </span>
          <br />
          <HeightSpacer height={'12px'} />
        </>
      );
    }

    if (currentPage == 2) {
      return (
        <>
          <span
            style={{
              fontFamily: 'Inter',
              fontWeight: '400',
              fontSize: '16px',
              letterSpacing: '0.15px'
            }}>
            {currentPage + 1}. Hire international talents with{' '}
            <b>Global Hire!</b>
          </span>
          <br />
          <HeightSpacer height={'12px'} />
        </>
      );
    }
  };

  return (
    <>
      <ContentContainer $currentPage={currentPage} ref={contentRef}>
        <>
          <Grid paddingLeft={'5px'}>
            {getByTitle()}

            <Grid paddingLeft={'20px'}>
              <ContentDescription>{description()}</ContentDescription>
            </Grid>
          </Grid>

          <HeightSpacer height={'24px'} />

          {currentPage === 0 && (
            <Grid>
              <img
                alt={'canvas2'}
                style={{
                  width: '100%',
                  borderRadius: '8px'
                  // outline: `1px solid ${Colors.terLightGrey}`
                }}
                src={Canvas2}
              />
              <Grid paddingLeft={'20px'}>
                <p
                  style={{
                    fontFamily: 'Inter',
                    fontWeight: '400',
                    fontSize: '14px',
                    letterSpacing: '0.15px',
                    color: 'rgba(0, 0, 0, 0.6)'
                  }}>
                  Upload your own company videos
                </p>
              </Grid>
              <img
                alt={'canvas1'}
                style={{
                  width: '100%',
                  borderRadius: '8px'
                  // outline: `1px solid ${Colors.terLightGrey}`
                }}
                src={Canvas1}
              />

              <Grid paddingLeft={'20px'}>
                <p
                  style={{
                    fontFamily: 'Inter',
                    fontWeight: '400',
                    fontSize: '14px',
                    letterSpacing: '0.15px',
                    color: 'rgba(0, 0, 0, 0.6)'
                  }}>
                  Curious about how this helps your employer branding? Read more{' '}
                  <a
                    href="https://my.hiredly.com/advice/hiredly-company-canvas-employer-branding"
                    target="_blank">
                    here
                  </a>
                  .
                </p>
              </Grid>
            </Grid>
          )}

          {currentPage !== 0 && (
            <img
              alt={
                currentPage == 3
                  ? 'ashley'
                  : currentPage == 4
                  ? 'talent-search'
                  : currentPage == 1
                  ? 'exclusive+max'
                  : currentPage == 2
                  ? 'flexibly'
                  : ''
              }
              style={{
                width: '100%',
                borderRadius: '8px',
                outline: `1px solid ${Colors.terLightGrey}`
              }}
              src={gifs()}
            />
          )}
        </>
      </ContentContainer>
    </>
  );
};

export const Buttons = ({
  currentPage,
  setCurrentPage,
  onClosePopup,
  contentRef
}) => {
  const onClickButton = () => {
    try {
      if (currentPage == 4) {
        window.dataLayer.push({
          event: 'CE_click-whats-new-popup-got-it'
        });

        onClosePopup();
      } else {
        window.dataLayer.push({
          event: 'CE_click-whats-new-popup-next'
        });
        setCurrentPage(currentPage + 1);
        contentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } catch {}
  };

  const goBack = () => {
    setCurrentPage(currentPage - 1);
    contentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <ButtonsContainer>
      <PageNumber>
        {currentPage > 0 && currentPage < totalPages && (
          <IconButton onClick={goBack}>
            <ArrowSmallLeftIcon
              height={'24px'}
              width={'24px'}
              style={{ cursor: 'pointer' }}
            />
          </IconButton>
        )}
        Page {currentPage + 1}/{totalPages}
      </PageNumber>
      <MUIButtonStyled
        label={currentPage + 1 < totalPages ? 'Next' : 'Got It'}
        large
        onClick={onClickButton}
      />
    </ButtonsContainer>
  );
};
