import { styled as styling } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Avatar, MenuItem, Button } from '@mui/material';
import { Menu } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Typography Components //
export const StateTitle = styling(Typography)(props => ({
  fontWeight: 'bold',
  fontFamily: 'Inter',
  fontSize: '16px',
  textAlign: 'center',

  ...(props.type == 'kiv' && {
    color: '#ffa329'
  }),

  ...(props.type == 'shortlisted' && {
    color: '#20c472'
  }),

  ...(props.type == 'rejected' && {
    color: '#ce343c'
  })
}));

export const ApplicantName = styling(Typography)(props => ({
  marginleft: '8px',
  fontSize: '12px',
  lineHeight: '20px',
  textAlign: 'start',
  display: 'flex',
  alignItems: 'center'
}));

export const ApplicantNameTruncation = styling(Typography)(props => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  fontWeight: 'bold',
  fontFamily: 'Inter',
  marginLeft: '8px'
}));

export const ApplicantDescription = styling('div')(props => ({
  marginTop: '10px',
  height: '84px'
}));

export const ApplicantDescriptionTruncation = styling('p')(props => ({
  margin: '0px',
  fontFamily: 'Inter',
  fontSize: '12px',
  textAlign: 'start',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': '4',
  '-webkit-box-orient': 'vertical',
  whiteSpace: 'break-spaces',
  wordBreak: 'break-word'
}));

export const ApplicationDate = styling(Typography)(props => ({
  marginLeft: '8px',
  fontFamily: 'Inter',
  fontSize: '11px',
  textAlign: 'start'
}));

export const BoardContainer = styling('div')(props => ({
  marginTop: '20px',
  width: '100%',
  height: '100%',
  display: 'flex'
}));

export const ApplicantsContainer = styling('div')(props => ({
  padding: '15px 0px',
  width: `420px`,
  margin: '8px',
  borderRadius: '15px',
  boxShadow: '0px 0px 15px 1px #e8e8e8',
  '-webkit-box-shadow': '0px 0px 15px 1px #e8e8e8',
  '-moz-box-shadow': '0px 0px 15px 1px #e8e8e8',
  backgroundColor: 'white'
}));

export const ApplicantHeader = styling('div')(props => ({
  marginLeft: '18px',
  width: 'calc(100% - 36px)',
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '10px'
}));

export const ApplicantCountContainer = styling('div')(props => ({
  position: 'absolute',
  left: '0',
  padding: '4px 0',
  minWidth: '48px',
  backgroundColor: 'rgb(241, 241, 242)',
  borderRadius: '50px',
  fontSize: '14px',
  fontWeight: 'bold',
  textAlign: 'center',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '50px',

  ...(props.type == 'unview' && {
    backgroundColor: 'rgba(81, 42, 204, 0.2)',
    color: '#512acc'
  }),

  ...(props.type == 'kiv' && {
    background: 'rgba(255, 163, 41, 0.1)',
    color: '#ffa329'
  }),

  ...(props.type == 'shortlisted' && {
    background: 'rgba(32, 196, 114, 0.1)',
    color: '#20c472'
  }),

  ...(props.type == 'rejected' && {
    background: 'rgba(206, 52, 60, 0.1)',
    color: '#ce343c'
  })
}));

export const MoreHorizIconStyled = styling(MoreHorizIcon)(props => ({
  color: 'black',
  position: 'absolute',
  right: '0',
  cursor: 'pointer'
}));

export const ApplicantCardHeader = styling('div')(props => ({
  width: '100%',
  display: 'flex'
}));

export const ApplicantCardMoreHorizIcon = styling(MoreHorizIcon)(
  ({ theme }) => ({
    color: 'black',
    position: 'absolute',
    right: '10px',
    top: '15px',
    cursor: 'pointer',
    visibility: 'hidden'
  })
);

export const ApplicantCard = styling('div')(({ theme }) => ({
  padding: '12px',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  cursor: 'pointer',
  // '&:hover $ApplicantCardMoreHorizIcon': {
  '&:hover svg': {
    visibility: 'visible'
  }
}));

export const ApplicantCardNameContainer = styling('div')(props => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center'
}));

export const ApplicantAvatar = styling(Avatar)(props => ({
  width: '30px',
  height: '30px'
}));

export const ApplicantDateStatusContainer = styling('div')(props => ({
  // width: '100%',
  // display: 'flex',
  // alignItems: 'center',
  margin: '10px 5px 0 5px',
  position: 'relative',

  ...(props.type == 'viewed' && {
    maxWidth: '100%'
  })
}));

export const ClockIconStyled = styling(AccessTimeIcon)(props => ({
  color: 'black',
  fontSize: '14px'
}));

export const ApplicationStatus = styling('div')(props => ({
  padding: '5px 13px',
  borderRadius: '30px',
  position: 'absolute',
  right: '0',
  fontSize: '11px',
  fontWeight: 'bold',
  textTransform: 'capitalize',

  ...(props.type == 'unviewed' && {
    background: 'rgba(81, 42, 204, 0.2)',
    color: '#512acc'
  }),

  ...(props.type == 'kiv' && {
    background: 'rgba(255, 163, 41, 0.1)',
    color: '#ffa329'
  }),

  ...(props.type == 'shortlisted' && {
    background: 'rgba(32, 196, 114, 0.1)',
    color: '#20c472'
  }),

  ...(props.type == 'rejected' && {
    background: 'rgba(206, 52, 60, 0.1)',
    color: '#ce343c'
  }),

  ...(props.type == 'hired' && {
    background: 'rgba(33, 61, 181, 0.2)',
    color: '#213db5'
  })
}));

export const ApplicationUpdatingSpinnerContainer = styling('div')(props => ({
  position: 'absolute',
  right: '12px'
}));

export const MenuItemStyled = styling(MenuItem)(props => ({
  display: 'flex',
  justifyContent: 'left',
  padding: '5px auto',
  background: '#fff',

  ...(props.isSelected == 'true' && {
    background: 'rgba(0, 0, 0, 0.08)'
  })
}));

export const StatusText = styling('div')(props => ({
  fontSize: '14px',
  fontWeight: 400,
  fontFamily: 'Inter',

  ...(props.state == 'true' && {
    textTransform: 'capitalize'
  })
}));

export const MenuStyled = styling(Menu)(props => ({
  '& .MuiPaper-rounded': {
    borderRadius: '12px',
    padding: '0',
    minWidth: '160px',
    background: '#fff'
  },
  '& .MuiPopover-paper': {
    background: '#fff'
  }
}));

export const Divider = styling('div')(props => ({
  border: '1px solid #efefef',
  width: '80%',
  margin: 'auto',
  transform: 'translateY(-2px)'
}));

export const Tag = styling('div')(props => ({
  background: '#512acc',
  height: '20px',
  width: '85px',
  position: 'absolute',
  left: '0',
  top: '0',
  borderRadius: '16px 0 16px 0'
}));

export const TextContainer = styling('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%'
}));

export const Text = styling('p')(props => ({
  ...(props.type == 'unviewed' && {
    margin: '0',
    fontSize: '12px',
    color: '#fff',
    fontWeight: 'bold'
  }),

  ...(props.type == 'select_all_count' && {
    margin: '0',
    fontFamily: 'Inter',
    fontSize: '10px',
    fontWeight: '700',
    color: '#512acc'
  }),

  ...(props.type == 'interview_length' && {
    fontSize: '8px',
    margin: '0',
    fontWeight: 'bold',
    color: '#512acc'
  })
}));

export const SelectAllContainer = styling('div')(props => ({
  position: 'relative'
}));

export const SelectAllCountContainer = styling('div')(props => ({
  position: 'absolute',
  top: '0',
  left: '12%',
  transform: 'translateY(-5px)'
}));

export const ToastNewStatus = styling('b')(props => ({
  ...(props.status == 'undecided' && {
    textTransform: 'capitalize'
  }),

  ...(props.status == 'kiv' && {
    textTransform: 'uppercase',
    color: '#ffa329'
  }),

  ...(props.status == 'shortlisted' && {
    textTransform: 'capitalize',
    color: '#20c472'
  }),

  ...(props.status == 'rejected' && {
    textTransform: 'capitalize',
    color: '#ce343c'
  })
}));

export const ToastPreviousState = styling('b')(props => ({
  textTransform: 'capitalize',
  ...(props.status == 'kiv' && {
    textTransform: 'uppercase'
  })
}));

export const ButtonStyled = styling(Button)(props => ({
  ...(props.status == 'basic' && {
    borderRadius: '26.5px',
    boxShadow: '0px 4px 20px rgb(0 0 0 / 15%)',
    background: '#512acc',
    alignSelf: 'center',
    color: '#fff',
    fontWeight: '700',
    padding: '5px 20px',
    fontSize: '13px',
    fontFamily: 'Inter'
  }),

  ...(props.status == 'disabled' && {
    borderRadius: '26.5px',
    boxShadow: '0px 4px 20px rgb(0 0 0 / 15%)',
    alignSelf: 'center',
    fontWeight: '700',
    padding: '5px 20px',
    color: 'rgba(0, 0, 0, 0.26)',
    backgroundColor: 'rgba(0, 0, 0, 0.12)'
  })
}));

export const CloseIconStyled = styling(CloseIcon)(props => ({
  color: '#fff',
  fontSize: '22px',
  '&:hover': {
    cursor: 'pointer'
  }
}));

export const JobDetailsContainer = styling('div')(props => ({
  ...(props.show_banner
    ? {
        padding: '0 20px 0 10px',
        position: 'fixed',
        width: '100vw',
        display: 'flex',
        justifyContent: 'space-between',
        zIndex: '999'
      }
    : {
        padding: '0 1%',
        position: 'fixed',
        width: '100vw',
        display: 'flex',
        justifyContent: 'space-between',
        zIndex: '999'
      }),
  '@media (max-width: 1024px)': {
    ...(props.show_banner
      ? {
          padding: '0 20px 0 10px',
          position: 'fixed',
          zIndex: '999',
          width: 'auto'
        }
      : {
          padding: '0 1%',
          position: 'fixed',
          zIndex: '999',
          width: 'auto'
        })
  }
}));

export const FontAwesomeIconStyled = styling(FontAwesomeIcon)(props => ({
  ...(props.awesome_icon == 'faCalenderAlt' && {
    fontSize: '12px',
    color: '#222',
    marginLeft: '10px'
  })
}));

export const InterviewLengthContainer = styling('div')(props => ({
  position: 'absolute',
  top: '2px',
  right: '-8px'
}));
