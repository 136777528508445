import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../../styles/colors.module.scss';
import { Grid } from '@mui/material';

export const TipsDescription = styling('span')((props) => ({
  color: Colors.priBlack,
  opacity: '0.6',
  fontSize: '14px',
}));

export const UnorderlistStyled = styling('ul')((props) => ({
  padding: '0 20px',
  listStylePosition: 'outside',
  margin: '0',
}));

export const ListStyled = styling('li')((props) => ({
  opacity: '0.6',
  fontSize: '14px',
}));

export const TitleContainer = styling('div')((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '16px',
}));

export const Title = styling('h6')((props) => ({
  fontSize: '20px',
  fontWeight: '700',
  fontFamily: 'Inter',
}));

export const ErrorBorder = styling(Grid)((props) => ({
  border: props.isError? '1px solid #BE4242' : 'none',
  borderRadius: '5px'
}));
