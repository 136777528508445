import React from 'react';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Maintenance from './pages/Maintenance';
import Error500 from './pages/Error500';
import ApplicantSnapshotSummary from './pages/ApplicantSnapshotSummary';
import LoginInformation from './pages/EmployerRegistration/LoginInformation';
import CompanyRegisterDetails from './pages/EmployerRegistration/CompanyRegisterDetails';
import CompanyAccountDetails from './pages/CompanyAccount/CompanyDetails';
import ChooseProducts from './pages/PackageOrderFlow/ChooseProducts';
import OrderConfirm from './pages/PackageOrderFlow/OrderConfirm';
import ProductsPayment from './pages/PackageOrderFlow/ProductsPayment';
import PaymentResponse from './pages/PackageOrderFlow/PaymentResponse';
import JobList from './pages/Jobs/JobList';
import JobType from './pages/Jobs/AddEditJob/JobType';
import OrderHistory from './pages/OrderHistory';
import MyAccount from './pages/MyAccount';
import ManageTeamMembers from './pages/CompanyAccount/ManageTeamMembers';
import ManageCandidates from './pages/ManageCandidates';
import ManageAffiliates from './pages/CompanyAccount/ManageAffiliates';
import Chats from './pages/Chats';
import TalentSearch from './pages/TalentSearch';
import ActiveCVAccess from './pages/ActiveCVAccess';
import AllApplicants from './pages/AllApplicants';
import AshleyEditFeedback from './pages/Jobs/AddEditJob/AshleyEditFeedback';
import { ForgotPassword, ResetPassword } from './pages/Password';
import Waitlist from './pages/Waitlist';
import ProductBundles from './pages/ProductBundles';
import Promotions from './pages/Promotions';
import Updates from './pages/Updates';
import PostJob from './pages/Jobs/PostJob';
import AccountVerification from './pages/EmployerRegistration/AccountVerification';
import VirtualInterviewQuestionsNew from './pages/Jobs/AddEditJob/VirtualInterviewQuestionsNew';
import JobAccessNew from './pages/Jobs/AddEditJob/JobAccessNew';
import JobVisibility from './pages/Jobs/AddEditJob/JobVisibility';
import CompanyCanvas from './pages/CompanyCanva';
import { Outlet, createBrowserRouter } from 'react-router-dom';
import PrivateRoute from './navigation/PrivateRoute';
import RoutesWrapper from './navigation/Routes';
import Feedback from './pages/Feedback';

const AppLayout = () => {
  return (
    <RoutesWrapper>
      <Outlet />
    </RoutesWrapper>
  );
};

const protectedRoute = [
  // { path: '/all-applicants', myCompanyStatus: ['allApplicantsAccess'] },
  {
    path: '/chats',
    myCompanyStatus: ['hasCompletedOrdersOrPostedJobs']
  },
  { path: '/active-cv-access', myCompanyStatus: ['acvAccess'] },
  { path: '/talent-search', myCompanyStatus: ['talentSearchAccess'] },
  {
    path: '/manage-candidates/:id',
    myCompanyStatus: ['hasCompletedOrdersOrPostedJobs']
  },
  {
    path: '/company-account/company-details',
    permissions: ['editAccountDetail']
  },
  {
    path: '/company-account/manage-team-members',
    myCompanyStatus: [
      'isApproved',
      'hasCompletedOrdersOrPostedJobs',
      'ctmAccess'
    ]
  },
  {
    path: '/company-account/manage-affiliates',
    myCompanyStatus: ['isApproved', 'affiliatedCompanyAccess']
  },
  {
    path: '/company-account/company-canvas',
    myCompanyStatus: ['hasCompletedOrdersOrPostedJobs'],
    permissions: ['editCompanyProfile']
  },
  {
    path: '/purchase/choose',
    permissions: ['buyToken']
  },
  {
    path: '/purchase/confirm',
    permissions: ['buyToken']
  },
  {
    path: '/purchase/payment/:orderId/:cartId',
    permissions: ['buyToken']
  },
  {
    path: '/purchase/payment',
    permissions: ['buyToken']
  },
  { path: '/jobs/edit-job-draft', permissions: ['postJob'] },
  {
    path: '/jobs/add/new-job',
    unlimitedToken: ['unlimitedToken'],
    permissions: ['postJob']
  },
  {
    path: '/jobs/add/edit',
    permissions: ['postJob']
  },
  {
    path: '/jobs/add/free-intern',
    myCompanyStatus: [
      'hasCompletedOrdersOrPostedJobs',
      'allowToPostInternshipJob'
    ],
    permissions: ['postJob']
  },
  {
    path: '/jobs/add/free-senior',
    myCompanyStatus: [
      'hasCompletedOrdersOrPostedJobs',
      'allowToPostSuperSeniorJob'
    ],
    permissions: ['postJob']
  },
  {
    path: '/jobs/:id/job-access-new',
    permissions: ['postJob']
  },
  {
    path: '/jobs/:id/job-visibility',
    permissions: ['postJob']
  },
  {
    path: '/jobs/:id/virtual-interview-new',
    permissions: ['postJob']
  }
];

const PrivateLayout = () => {
  return (
    <PrivateRoute protectedRoute={protectedRoute}>
      <Outlet />
    </PrivateRoute>
  );
};

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        element: <PrivateLayout />,
        children: [
          { path: 'feedback', element: <Feedback /> },
          {
            path: 'my-account',
            element: <MyAccount />
          },
          {
            path: 'updates',
            element: <Updates />
          },
          {
            path: 'jobs',
            element: <JobList />
          },
          {
            path: 'verification',
            element: <AccountVerification />
          },
          {
            path: 'company-account/company-details',
            element: <CompanyAccountDetails />
          },
          {
            path: 'company-account/manage-team-members',
            element: <ManageTeamMembers />
          },
          {
            path: 'company-account/company-canvas',
            element: <CompanyCanvas />
          },
          {
            path: 'company-account/manage-affiliates',
            element: <ManageAffiliates />
          },
          {
            path: 'jobs/:id/ashley-edit-feedback',
            element: <AshleyEditFeedback />
          },
          {
            path: 'jobs/:id/virtual-interview-new',
            element: <VirtualInterviewQuestionsNew />
          },
          {
            path: 'jobs/:id/job-access-new',
            element: <JobAccessNew />
          },
          {
            path: 'jobs/:id/job-visibility',
            element: <JobVisibility />
          },
          {
            path: 'active-cv-access',
            element: <ActiveCVAccess />
          },
          {
            path: 'talent-search',
            element: <TalentSearch />
          },
          {
            path: 'manage-candidates/:id',
            element: <ManageCandidates />
          },
          {
            path: 'chats',
            element: <Chats />
          },
          {
            path: 'jobs/edit-job-draft',
            element: <PostJob />
          },
          {
            path: 'jobs/add/new-job',
            element: <PostJob />
          },
          {
            path: 'jobs/add/edit',
            element: <PostJob />
          },
          {
            path: 'jobs/add/free-intern',
            element: <PostJob />
          },
          {
            path: 'jobs/add/free-senior',
            element: <PostJob />
          },
          {
            path: 'jobs/:id',
            element: <PostJob />
          },
          {
            path: 'jobs/:id/job-type',
            element: <JobType />
          },
          {
            path: 'jobs/add/job-type',
            element: <JobType />
          },
          {
            path: 'welcome',
            element: <ProductBundles />
          },
          {
            path: 'purchase/choose',
            element: <ChooseProducts />
          },
          {
            path: 'purchase/confirm',
            element: <OrderConfirm />
          },
          {
            path: 'purchase/payment/:orderId/:cartId',
            element: <ProductsPayment />
          },
          {
            path: 'purchase/payment',
            element: <PaymentResponse />
          },
          {
            path: 'order-history',
            element: <OrderHistory />
          },
          {
            path: 'all-applicants',
            element: <AllApplicants />
          }
        ]
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'register/login-info',
        element: <LoginInformation showPasswordStrength={true} />
      },
      {
        path: 'register/verification',
        element: <AccountVerification />
      },
      {
        path: 'register/company-details',
        element: <CompanyRegisterDetails />
      },
      {
        path: 'promotions',
        element: <Promotions />
      },
      {
        path: 'applicant-snapshot-summary',
        element: <ApplicantSnapshotSummary />
      },
      {
        path: 'maintenance',
        element: <Maintenance />
      },
      {
        path: 'waitlist',
        element: <Waitlist />
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />
      },
      {
        path: 'reset-password',
        element: <ResetPassword />
      },
      {
        path: '500',
        element: <Error500 />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
]);
