import { useDispatch, useSelector } from 'react-redux';
import {
  DefaultContainer,
  Divider,
  PurpleDivider,
  SelectionButton,
  SelectionSubTitle,
  SelectionTitle
} from '../styles';
import { updateProductBundle } from '../../../../../redux/actions/product_action';

export const TypeSelection = props => {
  const { id, bundle, bundleDescription } = props;
  const dispatch = useDispatch();
  const selectedBundle = useSelector(
    state => state.products.selectedProductBundle
  );

  return (
    <DefaultContainer container sx={{ flexDirection: 'column' }}>
      <SelectionButton onClick={() => dispatch(updateProductBundle(id))}>
        <SelectionTitle selected={id === selectedBundle}>
          {bundle}
        </SelectionTitle>
        {bundle && (
          <SelectionSubTitle selected={id === selectedBundle}>
            {bundleDescription}
          </SelectionSubTitle>
        )}
      </SelectionButton>
      <Divider selected={id === selectedBundle}></Divider>
      <PurpleDivider selected={id === selectedBundle}></PurpleDivider>
    </DefaultContainer>
  );
};
