import React, { useRef, useState } from 'react';
import {
  CloseIconStyled,
  ContentContainer,
  DefaultText,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
  GreyContentContainer,
  GreyInnerContainer,
  GuidelineButton,
  IconButtonStyled,
  SocialMediaTitle,
  SocialMediaType,
  TitleContainer,
  TitleDescStyled,
  TitleStyled,
  TypographySpan
} from './styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ReactComponent as InstagramColour } from '../../../../../assets/icons/company-canvas/instagram-colour.svg';
import { ReactComponent as InstagramBlack } from '../../../../../assets/icons/company-canvas/instagram-black.svg';
import { ReactComponent as FacebookColour } from '../../../../../assets/icons/company-canvas/facebook-colour.svg';
import { ReactComponent as FacebookBlack } from '../../../../../assets/icons/company-canvas/facebook-black.svg';
import { ReactComponent as TiktokColour } from '../../../../../assets/icons/company-canvas/tiktok-colour.svg';
import { ReactComponent as TiktokBlack } from '../../../../../assets/icons/company-canvas/tiktok-black.svg';
import { ReactComponent as TwitterBlack } from '../../../../../assets/icons/company-canvas/twitter.svg';
import { SocialMediaWrapper } from './styles';
import { SelectionWrapper } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { JOBSEEKER_URL } from '../../../../../utils/Constants';
import { updateSocialMediaGuideline } from '../../../../../redux/actions/company_actions';

const socialMediaType = id => {
  if (id === 0) {
    return <Instagram />;
  }

  if (id === 1) {
    return <Facebook />;
  }

  if (id === 2) {
    return <Tiktok />;
  }

  if (id === 3) {
    return <Twitter />;
  }
};

export default function InfoPopUp(props) {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const socialMediaGuideline = useSelector(
    state => state.companies.socialMediaGuideline
  );

  const infos = [
    {
      id: 0,
      title: 'Instagram',
      active: <InstagramColour />,
      disabled: <InstagramBlack />
    },
    {
      id: 1,
      title: 'Facebook',
      active: <FacebookColour />,
      disabled: <FacebookBlack />
    },
    {
      id: 2,
      title: 'Tiktok',
      active: <TiktokColour />,
      disabled: <TiktokBlack />
    },
    {
      id: 3,
      title: '(Twitter)',
      active: <TwitterBlack />,
      disabled: <TwitterBlack />
    }
  ];

  const guideline = () => {
    dispatch(updateSocialMediaGuideline(4));
  };

  const contentRef = useRef(null);

  return (
    <DialogStyled open={open} onClose={handleClose}>
      <DialogTitleStyled>
        <TitleContainer>
          <TitleStyled>
            More Info: <TypographySpan>Social Media Highlights</TypographySpan>
          </TitleStyled>
          <TitleDescStyled>
            Find out how to get your social media video links.
          </TitleDescStyled>
        </TitleContainer>
        <IconButtonStyled aria-label="close" onClick={handleClose}>
          <CloseIconStyled />{' '}
        </IconButtonStyled>
      </DialogTitleStyled>

      <DialogContentStyled dividers>
        <GreyContentContainer>
          <GreyInnerContainer>
            <SelectionWrapper>
              {infos?.map(info => {
                return (
                  <SocialMediaType
                    selected={socialMediaGuideline === info?.id}
                    onClick={() => {
                      contentRef.current.scrollTo({
                        top: 0,
                        behavior: 'instant'
                      });
                      dispatch(updateSocialMediaGuideline(info.id));
                    }}>
                    <SocialMediaWrapper>
                      {socialMediaGuideline === info?.id
                        ? info?.active
                        : info?.disabled}
                      {socialMediaGuideline === info?.id && (
                        <SocialMediaTitle>{info.title}</SocialMediaTitle>
                      )}
                    </SocialMediaWrapper>
                  </SocialMediaType>
                );
              })}
            </SelectionWrapper>

            <GuidelineButton
              selected={socialMediaGuideline === 4}
              onClick={() => guideline()}>
              Community Guidelines
            </GuidelineButton>
          </GreyInnerContainer>
        </GreyContentContainer>

        <Grid sx={{ backgroundColor: 'white !important', padding: '24px 0px' }}>
          <ContentContainer ref={contentRef}>
            {socialMediaGuideline === 4 ? (
              <Guideline />
            ) : (
              socialMediaType(socialMediaGuideline)
            )}
          </ContentContainer>
        </Grid>
      </DialogContentStyled>
    </DialogStyled>
  );
}

export const TermsAndConditions = () => {
  const dropdownCountry = useSelector(state => state.companies.country);

  return (
    <Grid
      sx={{
        borderRadius: '8px',
        textAlign: 'center',
        padding: '16px 8px',
        backgroundColor: `rgba(33, 61, 181, 0.14)`,
        border: `1px solid rgba(0, 0, 0, 0.87)`
      }}>
      <Typography sx={{ fontSize: '12px', fontFamily: 'inter' }}>
        When you provide your social media link, you are acknowledging your
        commitment to follow our{' '}
        <a
          href={JOBSEEKER_URL[dropdownCountry] + '/community-guidelines'}
          target="_blank"
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            color: `rgba(0,0,0,0.87)`
          }}>
          community guidelines
        </a>{' '}
        and{' '}
        <a
          href={JOBSEEKER_URL[dropdownCountry] + '/terms-and-conditions'}
          target="_blank"
          style={{
            textDecoration: 'underline',
            color: `rgba(0,0,0,0.87)`,
            cursor: 'pointer'
          }}>
          terms of use.
        </a>
      </Typography>

      <br></br>
      <Typography
        sx={{ fontSize: '12px', fontStyle: 'italic', fontFamily: 'inter' }}>
        For further enquiries, please email us at{' '}
        <span style={{ textDecoration: 'underline' }}>
          {dropdownCountry === 'MY'
            ? 'employer@hiredly.com'
            : 'employer@sg.hiredly.com'}
        </span>{' '}
        or call us at +603 2771 0141
      </Typography>
    </Grid>
  );
};

const Instagram = () => {
  const instagramInfo = [
    {
      title: 'Using a desktop browser:',
      list: [
        'Go to the post you want to share.',
        'Copy the URL from the address bar (Example: https://www.instagram.com/p/C4zY1eRy s1p/)',
        'Paste the link in the provided field.'
      ]
    },
    {
      title: 'Using the mobile app:',
      list: [
        'Go to the post you want to share and tap on the paper plane icon.',
        'Select ‘Copy link’ from the options (Example: https://www.instagram.com/p /C5FyNRURSBT/?igsh=aG5tczF3YTAxeTY4)',
        'Paste the link in the provided field.'
      ]
    }
  ];

  return (
    <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
          <DefaultText
            sx={{
              marginBottom: '10px',
              lineHeight: '10px'
            }}>
            *Make sure your Instagram account is set to public for the best
            experience.
          </DefaultText>
          <DefaultText>
            **Tip: This experience works best for Instagram Posts and Reels,
            kindly avoid using Stories or Highlights.
          </DefaultText>
        </Grid>
        {instagramInfo?.map(info => {
          return (
            <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '700',
                  fontFamily: 'inter',
                  marginBottom: '5px'
                }}>
                {info?.title}
              </Typography>

              <ol style={{ margin: 0, padding: '0px 0px 0px 14px' }}>
                {info?.list?.map(list => {
                  return (
                    <DefaultText>
                      <li style={{ margin: 0, padding: 0 }}>{list}</li>
                    </DefaultText>
                  );
                })}
              </ol>
            </Grid>
          );
        })}
      </Grid>
      <TermsAndConditions />
    </Grid>
  );
};

const Facebook = () => {
  const facebookInfo = [
    {
      title: 'Using a desktop browser:',
      list: [
        'Go to the post you want to share',
        'Click on ‘...’ at the top right corner of the post.',
        'Select ‘Embed’ and then click on ‘Advanced settings’.',
        'Scroll down and copy the ‘URL of post’ (Example: https://www.facebook.com/hiredly/videos/975371773960680/)',
        'Paste the link in the provided field.'
      ]
    },
    {
      title: 'Using a mobile browser:',
      list: [
        'Go to the post you want to share.',
        'Tap on ‘Share’.',
        'Select ‘Copy link’ from the options (Example: https://www.facebook.com/hiredly/videos/975371773960680/)',
        'Your URL should be in this format: https://www.facebook.com/hiredly/videos/975371773960680/ ',
        'Paste the link in the provided field.'
      ]
    }
  ];

  return (
    <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
          <DefaultText>
            *Make sure your Facebook post is set to public.
          </DefaultText>
        </Grid>
        {facebookInfo?.map(info => {
          return (
            <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '700',
                  fontFamily: 'inter'
                }}>
                {info?.title}
              </Typography>
              <ol style={{ margin: 0, padding: '0px 0px 0px 14px' }}>
                {info?.list?.map(list => {
                  return (
                    <DefaultText>
                      <li style={{ margin: 0, padding: 0 }}>{list}</li>
                    </DefaultText>
                  );
                })}
              </ol>
            </Grid>
          );
        })}
      </Grid>
      <TermsAndConditions />
    </Grid>
  );
};

const Tiktok = () => {
  const tiktokInfo = [
    {
      title: 'Using a desktop browser:',
      list: [
        'Go to the post you want to share.',
        'Your URL should be in this format: https://www.tiktok.com/@growwithhiredly/video/7248943110774983937 ',
        'Paste the link in the provided field.'
      ]
    },
    {
      title: 'Using a mobile browser:',
      list: [
        'Go to the post you want to share.',
        'Tap on ‘Share’ (Hint: This is the curved arrow icon on the right side).',
        'Select ‘Copy link’ from the options (Example: https://www.tiktok.com/@growwithhiredly/video/7248943110774983937)',
        'Paste the link in the provided field.'
      ]
    }
  ];

  return (
    <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
          <DefaultText>
            *Make sure your TikTok account is set to public and your post can be
            viewed by everyone.
          </DefaultText>
        </Grid>
        {tiktokInfo?.map(info => {
          return (
            <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '700',
                  fontFamily: 'inter'
                }}>
                {info?.title}
              </Typography>
              <ol style={{ margin: 0, padding: '0px 0px 0px 14px' }}>
                {info?.list?.map(list => {
                  return (
                    <DefaultText>
                      <li style={{ margin: 0, padding: 0 }}>{list}</li>
                    </DefaultText>
                  );
                })}
              </ol>
            </Grid>
          );
        })}
      </Grid>
      <TermsAndConditions />
    </Grid>
  );
};

const Twitter = () => {
  const twitterInfo = [
    {
      title: 'Using a desktop browser or the mobile app:',
      list: [
        'Go to the post you want to share.',
        'Tap on ‘Share’ (Hint: This is the bottom right icon on your post).',
        'Select ‘Copy link’ from the options (Example: https://twitter.com/hiredly_my/status/1773676876025737339)',
        'Paste the link in the provided field.'
      ]
    }
  ];

  return (
    <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
          <DefaultText>
            *Make sure your X account and its posts are set to public.
          </DefaultText>
        </Grid>
        {twitterInfo?.map(info => {
          return (
            <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '700',
                  fontFamily: 'inter'
                }}>
                {info?.title}
              </Typography>

              <ol style={{ margin: 0, padding: '0px 0px 0px 14px' }}>
                {info?.list?.map(list => {
                  return (
                    <DefaultText>
                      <li style={{ margin: 0, padding: 0 }}>{list}</li>
                    </DefaultText>
                  );
                })}
              </ol>
            </Grid>
          );
        })}
      </Grid>
      <TermsAndConditions />
    </Grid>
  );
};

const Guideline = () => {
  const guidelinesInfo = [
    {
      title: 'Content Responsibility:',
      description:
        'Employers are solely responsible for content shared through embedded social media links on Hiredly, ensuring alignment with Hiredly’s Privacy Policy, Terms & Conditions, and Community Guidelines.'
    },
    {
      title: 'Privacy and Confidentiality:',
      description:
        'Respect individuals’ privacy rights and adhere to data protection regulations when embedding social media links, and avoid sharing sensitive or confidential information that violates privacy policies or legal requirements.'
    },
    {
      title: 'Content Authenticity:',
      description:
        'Share only authentic and accurate information in the embedded social media links. Do not use misleading or false information that could misrepresent your company or deceive potential candidates.'
    },
    {
      title: 'Inclusivity and Non-Discrimination:',
      description:
        "Uphold Hiredly's Equal Opportunity Policy in the content shared through social media links and refrain from posting discriminatory, offensive, or inappropriate content that goes against the principles of diversity and inclusivity. Hiredly will promptly address any reported violations and may take necessary actions, including content removal."
    },
    {
      title: 'Compliance with External Platform Policies:',
      description:
        "Ensure that the content shared through social media links complies with the respective terms and conditions of the external platforms. Any violation of external platform policies that negatively impacts Hiredly's community may result in appropriate action, including content removal and account restrictions."
    }
  ];
  return (
    <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      {guidelinesInfo?.map(info => {
        return (
          <Grid>
            <Typography
              sx={{ fontSize: '12px', fontWeight: '700', fontFamily: 'inter' }}>
              {info?.title}
            </Typography>
            <Typography
              sx={{ fontSize: '12px', fontWeight: '400', fontFamily: 'inter' }}>
              {info?.description}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};
