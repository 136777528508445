import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, PopUpDialog, Spinner } from '../../../../components';
import styles from './index.module.scss';
import Editor from './editor';
import PopUpDialogNew from '../../../../components/PopUpDialogNew';

const EditBannerPopUp = ({
  onlyDesktop,
  desktopRatio,
  setSelectedBanner,
  open,
  handleClose,
  onUploadMultipleBannerImages,
  selectedDesktopBanner,
  selectedMobileBanner,
  isEdit
}) => {

  const onSaveButtonClicked = (banner, squareBanner) => {
    if (setSelectedBanner) {
      if (onlyDesktop) {
        setSelectedBanner(banner);
      } else {
        setSelectedBanner(selectedDesktopBanner);
      }
    }
    onUploadMultipleBannerImages(banner, squareBanner);
  };

  if (onlyDesktop) {
    return (
      <PopUpDialogNew
        open={open}
        handleClose={handleClose}
        title={`Adjust Cover Image`}>
        <Editor
          onlyDesktop={onlyDesktop}
          desktopRatio={desktopRatio}
          handleClose={handleClose}
          onUploadMultipleBannerImages={onSaveButtonClicked}
          selectedDesktopBanner={selectedDesktopBanner}
          selectedMobileBanner={selectedMobileBanner}
        />
      </PopUpDialogNew>
    );
  }

  return (
    <PopUpDialog
      open={open}
      dialogWidth={'940px'}
      maxDialogWidth={1000}
      handleClose={handleClose}
      title={`${isEdit ? 'Readjust Cover Image' : 'Adjust Cover Image'}`}>
      <div style={{ padding: '20px 20px' }}>
        <Editor
          desktopRatio={desktopRatio}
          handleClose={handleClose}
          onUploadMultipleBannerImages={onSaveButtonClicked}
          selectedDesktopBanner={selectedDesktopBanner}
          selectedMobileBanner={selectedMobileBanner}
        />
      </div>
    </PopUpDialog>
  );
};

EditBannerPopUp.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  onUploadMultipleBannerImages: PropTypes.func,
  selectedDesktopBanner: PropTypes.string,
  selectedMobileBanner: PropTypes.string
};

EditBannerPopUp.defaultProps = {
  open: false,
  handleClose: () => {},
  onUploadMultipleBannerImages: () => {},
  selectedDesktopBanner: '',
  selectedMobileBanner: ''
};

export default EditBannerPopUp;
