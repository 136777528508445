import { styled as styling } from '@mui/material/styles';

export const HeightSpacer = styling('div')(({ 
  height 
}) => ({
  height: height ? height : '5px',
}));

export const Spacer = styling('div')(({ 
  width 
}) => ({
  width: width ? width : '5px',
}));


