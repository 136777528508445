import React, { useState } from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import './pagination.scss';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Menu, MenuItem } from '@mui/material';

const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    selectedPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    selectedPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (selectedPage == 0) return null;

  const onNext = () => {
    onPageChange(selectedPage + 1);
  };

  const onPrevious = () => {
    onPageChange(selectedPage - 1);
  };

  const getLastPage = () => {
    if (paginationRange) {
      return paginationRange[paginationRange?.length - 1];
    }
  };

  const [menu, setMenu] = useState(null);

  const handleDotsClick = event => {
    setMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenu(null);
  };

  return (
    <ul
      className={classnames('pagination-container', {
        [className]: className
      })}>
      <li
        className={classnames('pagination-item', {
          disabled: selectedPage === 1
        })}
        onClick={onPrevious}>
        <div className="arrow left" />
      </li>
      {paginationRange?.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <div>
              {' '}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <li className="pagination-item dots">&#8230;</li>{' '}
              </div>
            </div>
          );
        }

        return (
          <li
            key={`${pageNumber}--${index}`}
            className={classnames('pagination-item', {
              selected: pageNumber === selectedPage
            })}
            onClick={() => onPageChange(pageNumber)}>
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classnames('pagination-item', {
          disabled: selectedPage === getLastPage()
        })}
        onClick={onNext}>
        <div className="arrow right" />
      </li>
    </ul>
  );
};

export default Pagination;
