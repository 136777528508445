import { MenuItem, TextField } from '@mui/material';
import React from 'react';

// Custom fields
// selectHeight: Number - The height of overall select component
// menuItemMargin: Number - The margin of the items in menu

const SharedSelectField = props => {
  const { options = [], sx, ...otherProps } = props; // Destructure props to extract options and other props

  return (
    <TextField
      {...otherProps}
      select
      value={props?.value ?? ''}
      variant="outlined"
      sx={{
        width: '100%',
        padding: '0px !important',
        '& .MuiInputBase-root': {
          color: !props?.value ? '#bdbdbd' : 'black'
        },
        '& .MuiOutlinedInput-root': {
          height: props?.selectHeight * 0.95,
          '& fieldset': {
            borderRadius: '8px',
            height: props?.selectHeight
          },

          '&:hover fieldset': {
            border: '1px solid #AEAEAE'
          },
          '&.Mui-focused fieldset': {
            border: '1px solid #512ACC'
          }
        }
      }}
      SelectProps={{
        displayEmpty: true,
        MenuProps: {
          PaperProps: {
            style: {
              background: '#fff',
              boxShadow: '2',
              borderRadius: '13px',
              display: 'flex',
              width: '300px'
            }
          }
        }
      }}>
      <MenuItem value="" disabled>
        {props?.placeholder || 'Select an option'}
      </MenuItem>
      {options.length > 0 ? (
        options.map(item => (
          <MenuItem
            key={item.id}
            value={item.id}
            sx={{ minWidth: '800px', height: 'auto' }}>
            <p style={{ margin: props?.menuItemMargin || '0px' }}>
              {item.title || item.name}
            </p>
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>
          <div>No options available</div>
        </MenuItem>
      )}
    </TextField>
  );
};

export default SharedSelectField;
