import { styled as styling } from '@mui/material/styles';
import { CardMedia } from '@mui/material';

export const VCLContainer = styling('div')({
  minHeight: '350px',
});

export const Text = styling('p')(({ type }) => ({
  fontFamily: 'Inter',
  fontWeight: '700',
  fontSize: type == 'normal' ? '14px' : 'inherit',
}));

export const StyledCardMedia = styling(CardMedia)({
  maxHeight: '300px',
});
