import { useEffect, useRef } from 'react';

export function VimeoVideo({
  videoId,
  small,
  width,
  height,
  noAbsolute,
  border,
  setSeconds,
  completed,
  capturedThumbnail,
  extra
}) {
  const playerRef = useRef(null);
  const vimeoUrl = `https://player.vimeo.com/video/${videoId}${
    small || noAbsolute ? '?dnt=1&controls=0&autopause=1' : ''
  }`;

  useEffect(() => {
    //
  }, [completed, capturedThumbnail]);

  useEffect(() => {
    const initPlayer = () => {
      const player = new Vimeo.Player(playerRef.current);

      player.on('timeupdate', data => {
        setSeconds(data.seconds);
      });
    };

    if (window.Vimeo) {
      initPlayer();
    } else {
      const script = document.createElement('script');
      script.src = 'https://player.vimeo.com/api/player.js';
      script.onload = initPlayer;
      document.body.appendChild(script);
    }
  }, [setSeconds, completed]);

  return (
    <div
      style={{
        border: border ? '1px solid #D8D8D8' : '',
        // borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        width: width || (small ? '81px' : '100%'),
        height: height || (small ? '47px' : '100%')
      }}>
      <iframe
        ref={playerRef}
        src={vimeoUrl}
        style={{
          minHeight: '273px',
          width: extra ? '565px' : '100%',
          height: '100%'
        }}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
}
