import { styled as styling } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import Menu from '@mui/material/Menu';
import { Skeleton } from '@mui/material';

export const LabelStyled = styling(Typography)((props) => ({
  fontWeight: 'regular',
  fontSize: '16px',
  lineHeight: '1.5rem',
  letterSpacing: '1px',
  fontFamily: 'Inter',
  ...(props.capitalize === 'true' && {
    textTransform: 'capitalize',
  }),
  ...(props.chat_section_date === 'true' && {
    fontSize: '14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'break-spaces',
  }),
  ...(props.view_profile === 'true' && {
    color: '#512acc',
    fontWeight: 'bold',
    cursor: 'pointer',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'break-spaces',
  }),
  ...(props.job_status === 'true' && {
    fontSize: '14px',
    fontWeight: 'bold',
  }),
  ...(props.type === 'jobseeker' && {
    color: '#222222',
    fontSize: '14px',
    whiteSpace: 'break-spaces',
    wordBreak: 'break-word',
  }),
  ...(props.type === 'employer' && {
    color: '#fff',
    fontSize: '14px',
  }),
}));

export const Title = styling(Typography)((props) => ({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 'bold',
  letterSpacing: '1px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
  display: 'block',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  // -webkit-line-clamp: 2;
  // -webkit-box-orient: vertical;
  whiteSpace: 'break-spaces',
  ...(props.chat_jobseeker_name === 'true' && {
    WebkitLineClamp: 1,
  }),
}));

export const ChatInfo = styling(Grid)((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const TextIconContainer = styling(Grid)((props) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const CardStyled = styling(Card)((props) => ({
  borderRadius: '10px',
  padding: '8px 20px',
  border: '1px solid #512acc',
  cursor: 'pointer',
  ...(props.disabled === 'true' && {
    border: '1px solid lightgray',
    color: 'lightgray',
  }),
}));

export const MenuStyled = styling(Menu)((props) => ({
  '& .MuiPaper-root': {
    width: '20%',
    borderRadius: '20px',
  },
}));

export const TextStatusIndicatorContainer = styling(Grid)((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const StatusTextContainer = styling(Grid)((props) => ({
  display: 'flex',
  padding: '0 5%',
  alignItems: 'center',
}));

export const StatusIndicator = styling('div')((props) => ({
  width: '11px',
  height: '11px',
  borderRadius: '50%',
  marginRight: `${props.margin_right}px`,
  ...(props.status.toLowerCase() === 'shortlisted' && {
    background: 'green',
  }),
  ...(props.status.toLowerCase() === 'undecided' && {
    background: 'grey',
  }),
  ...(props.status.toLowerCase() === 'kiv' && {
    background: 'orange',
  }),
  ...(props.status.toLowerCase() === 'rejected' && {
    background: 'red',
  }),
  ...(props.status.toLowerCase() === 'blacklisted' && {
    background: '#000',
  }),
}));

export const ChatWrapper = styling(Grid)((props) => ({
  background: '#fff',
  height: '85%',
  width: '100%',
  margin: '4% 0 0',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
  justifyContent: 'space-between',
  padding: '30px 5%',
}));

export const ChatContainer = styling(Grid)((props) => ({
  position: 'relative',
  width: '100%',
  overflow: 'auto',
  height: '100%',
}));

export const MessageContainer = styling(Grid)((props) => ({
  display: 'flex',
  justifyContent: props.type === 'employer' ? 'flex-end' : 'flex-start',
}));

export const MessageContent = styling(Grid)((props) => ({
  maxWidth: '75%',
  background: props.type === 'jobseeker' ? '#f2f2f2' : props.type === 'employer' ? '#222' : '#000',
  borderRadius: '5px',
  padding: '8px 15px',
  margin: '5px 0',
  color: props.type === 'employer' ? 'white' : 'inherit',
}));

export const SendTextWrapper = styling(Grid)((props) => ({
  height: '40px',
  width: '100%',
  background: '#fff',
  borderRadius: '30px',
  border: '1px solid #aeaeae',
  margin: '20px 0 0',
}));

export const TextFieldIconContainer = styling(Grid)((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 3%',
  alignItems: 'center',
  height: '100%',
  width: '100%',
}));

export const NoContentWrapper = styling('div')(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const PoorConnectionWrapper = styling('div')(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

// SKELETON
export const ChatSectionSkeleton = styling('div')(() => ({
  padding: '0 4%',
}));

export const ChatWrapperSkeleton = styling(Skeleton)((props) => ({
  margin: '20px 0',
  borderRadius: '10px',
}));

export const SendTextSkeleton = styling(Skeleton)((props) => ({
  margin: '20px 0',
  borderRadius: '30px',
}));

export const TextFieldStyled = styling(TextField)({
  '& .MuiInput-underline:after': {
    borderBottomColor: 'transparent',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
});
