import { Switch } from '@mui/material';
import { styled as styling } from '@mui/material/styles';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import Button from '@mui/material/Button';

export const SwitchStyled = styling(Switch)(props => ({
  width: 46,
  height: 24,
  padding: 0,
  borderRadius: 30,
  '& .MuiSwitch-switchBase': {
    padding: 1,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(21px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        // When is checked
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: '#D3D3D3'
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#33cf4d'
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: '#D3D3D3'
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    opacity: 1,
    backgroundColor: '#D3D3D3',
    '&:before': {
      display: 'flex',
      opacity: props.checked ? 1 : 0,
      color: '#ffffff',
      fontWeight: 'bold',
      transform: 'translate(8px ,4px)'
    },
    '&:after': {
      display: 'flex',
      transform: 'translate(30px, -12px)',
      color: '#ffffff',
      fontWeight: 'bold',
      opacity: props.checked ? 0 : 1
    }
  }
}));

export const MainContainer = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  gap: '24px',
  minHeight: props.isPopUp? '400px' : '540px'
}));

export const MainContainerBox = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: '8px',
  border: props.isPopUp? 'none': '1px solid #D8D8D8'
}));

export const ExpandableBar = styling('div')(props => ({
  display: 'flex',
  padding: props.isPopUp? '0px 0px 16px 0px' :'16px',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  borderRadius: props.isExpanded ? '8px 8px 0px 0px' : '8px',
  borderBottom: props.isExpanded ? 'none' : 'none',
  transition: 'border-bottom 0.3s ease, borderRadius 0.3 ease',
  background: '#FFF',
  width: '100%'
}));

export const ExpandableBarTitle = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
  flexShrink: '0',
  maxWidth: '597px'
}));

export const Title = styling('div')(props => ({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px'
}));

export const SubTitle = styling('div')(props => ({
  color: props.$color == 'black' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.60)',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '20px'
}));

export const ExpandableBarSwitch = styling('div')(props => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '24px'
}));

export const MiddleMessage = styling('div')(props => ({
  display: 'flex',
  padding: '16px 16px 0px 16px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  alignSelf: 'stretch',
  background: '#FFF'
}));

export const MiddleMessageText = styling('span')(props => ({
  alignSelf: 'stretch',
  color: props.disabled ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.38)',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
}));

export const QuestionBox = styling('div')(props => ({
  display: 'flex',
  padding: props.isPopUp? '0px' :'0px 24px 24px 24px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px',
  alignSelf: 'stretch',
  borderRadius: '0px 0px 8px 8px',
  background: '#FFF',
  width: props.isPopUp? '636px' : '671px',
}));

export const BottomBox = styling('div')(props => ({
  display: 'flex',
  padding: props.isPopUp ? '0px' : '16px 0px',
  justifyContent: props.isPopUp ? 'center' : 'flex-end',
  alignItems: 'center',
  alignSelf: 'stretch'
}));

export const BottomActionBox = styling('div')(props => ({
  display: 'flex',
  width: props.isPopUp ? '100%' : '328px',
  justifyContent: props.isPopUp ? 'center' : 'flex-end',
  alignItems: 'center',
  gap: '8px'
}));

export const SkipButton = styling('div')(props => ({
  display: 'flex',
  width: props.isPopUp? '200px' : '120px',
  height: '42px',
  padding: '0px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: 'normal',
  cursor: 'pointer'
}));

export const SaveButton = styling(Button)(props => ({
  display: 'flex',
  width: '200px',
  height: '42px',
  padding: '0px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  background: props.disabled ? '#D8D8D8' : '#353535',

  gap: '10px',
  color: '#FFF',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: 'normal',
  cursor: 'pointer',
  textTransform: 'none',

  ':disabled': {
    cursor: 'not-allowed',
    color: '#FFF',
    ':hover': {
      background: '#D8D8D8'
    },
  },

  ':hover': {
    background: '#222222',
  }
}));

export const ChevronDownIconStyled = styling(ChevronDownIcon)(props => ({
  width: '20px',
  height: '20px',
  transition: 'transform 0.3s ease', 
  transform: props.isExpanded ? 'rotate(180deg)' : 'none' 
}));

export const ExpandedContent = styling('div')(props => ({
    maxHeight: props.isExpanded ? '1000px' : '0',
    overflow: 'hidden',
    transition: 'max-height 0.8s ease',
    maxWidth: '671px'
}));

export const DialogTitleStyled = styling(DialogTitle)(props => ({
  padding: '16px 16px 16px 24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px'
}));

export const IconButtonStyled = styling(IconButton)(props => ({
  padding: '0'
}));

export const DialogContentStyled = styling(DialogContent)(props => ({
  padding: '24px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  maxWidth: '685px'
}));

export const DialogNoteStyled = styling(DialogContent)(props => ({
  padding: '0px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));