import { Tabs, Tab, IconButton, Grid } from '@mui/material';
import { styled as styling } from '@mui/material/styles';
import Colors from '../../styles/colors.module.scss';

export const TabsStyled = styling(Tabs)(props => ({
  minHeight: '0',
  height: '40px'
}));

export const TabStyled = styling(Tab)(props => ({
  padding: 0,
  fontSize: '14px',
  fontWeight: 400,
  fontFamily: 'Inter',
  color: 'rgba(0, 0, 0, 0.6) !important',
  minHeight: 0,
  height: '40px',
  minWidth: '121px',
  letterSpacing: '0.1px',
  textTransform: 'capitalize',
  margin: '0 5px',
  position: 'relative',

  '&:after': {
    width: '100%',
    display: 'none',
    position: 'absolute',
    bottom: 0,
    content: '""',
    borderBottom: '2.5px solid rgba(0, 0, 0, 0.12)'
  },

  '&:hover': {
    '&:after': {
      display: 'block'
    }
  },

  ...(props.$bold && {
    fontWeight: 700
  })
}));

export const ButtonText = styling('span')(props => ({
  fontSize: '14px',
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
  color: Colors.priBlack
}));

export const InfoPlaceholder = styling(Grid)(props => ({
  display: 'flex',
  backgroundColor: Colors.terWhite,
  padding: '40px',
  borderRadius: '8px',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${Colors.terLightGrey}`
}));

export const TalentInfoContainer = styling('div')(props => ({
  width: '100%',
  position: 'sticky',
  top: '140px',
  height: 'calc(100vh - 200px)',
  overflow: 'auto',
  background: '#FFf',
  borderRadius: '8px',
  border: '1px solid #D8D8D8'
}));
