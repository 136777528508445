import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Dialog, Divider } from '@mui/material';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { viewPackageSubscriptionDetails } from '../../../../redux/actions/job_action';
import store from '../../../../redux/stores/store';
import {
  DialogActionsStyled,
  DialogContentStyled,
  DialogNoteStyled,
  DialogTitleStyled,
  ButtonContinueEdit,
  ButtonSkipEdit,
  DialogButton,
  IconButtonStyled,
  KeywordTokenToggle,
  SwitchStyled
} from './styles';
import { mutateCart } from '../../../../redux/actions/order_action';
import useSeniorLogic from '../../PostJob/controller/useSeniorLogic';
import useInternLogic from '../../PostJob/controller/useInternLogic';
import { updateJobDataForm } from '../../../../redux/actions/job_action';

const JobTokenPopUp = ({ open, handleClose, jobTitle }) => {
  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const fromPage = new URLSearchParams(location.search).get('page');
  const jobDataForm = useSelector(state => state?.jobs?.jobDataForm);
  const myCompanyStatus = useSelector(
    state => state?.companies?.myCompanyStatus
  );

  const keywordHighlightTokensUsableCount = useSelector(
    state =>
      state.jobs.subscribedKeywordHighlightTokensPackage?.total_usable_quantity
  );

  const enableKeywordState = jobDataForm?.keywordHighlight;

  const [switchValue, setSwitchValue] = useState(() => {
    if (jobDataForm?.keywordHighlight !== undefined) {
      return jobDataForm.keywordHighlight;
    } else {
      return (
        jobDataForm?.keywords?.length > 0 &&
        keywordHighlightTokensUsableCount > 0
      );
    }
  });

  const { onSaveJobConfirm: internSave, loading: internLoading } =
    useInternLogic({ confirm: true });
  const { onSaveJobConfirm: seniorSave, loading: seniorLoading } =
    useSeniorLogic({ confirm: true });
  const onConfirmButtonClicked = async jobTitle => {
    if (jobTitle == 'intern') {
      internSave();
    } else if (jobTitle == 'senior') {
      seniorSave();
    }
  };

  const handleNextPage = async () => {
    store.getState().jobs.preventReloadOrLeave = false;

    if (jobDataForm?.id) {
      if (jobDataForm?.id?.length < 6) {
        setTimeout(() => {
          history(`/jobs/draft-job/job-type`);
        }, 500);
      } else {
        setTimeout(() => {
          history(`/jobs/${jobDataForm?.id}/job-type`);
        }, 500);
      }
    } else {
      setTimeout(() => {
        if (
          location.pathname.includes('jobs/add/free-intern') ||
          location.pathname.includes('jobs/add/free-senior')
        ) {
          history(`/jobs/${jobTitle}-job/job-type`);
        } else {
          history(`/jobs/new-job/job-type`);
        }
      }, 500);
    }
  };

  const currentCountNumber = parseInt(
    myCompanyStatus?.availableInternshipsTokenCount,
    10
  );
  const incrementedCountNumber = currentCountNumber + 1;

  const tokenCountIntern = jobDataForm?.active
    ? incrementedCountNumber
    : myCompanyStatus?.availableInternshipsTokenCount;
  const tokenCountSenior = jobDataForm?.active
    ? myCompanyStatus?.availableFreeSuperSeniorPostCount + 1
    : myCompanyStatus?.availableFreeSuperSeniorPostCount;

  const isUpdate = jobDataForm?.active;

  const getJobTitle = () => {
    if (isUpdate) {
      if (jobTitle == 'intern') {
        return 'Update with Internship Token?';
      } else if (jobTitle == 'senior') {
        return 'Update with Senior Post?';
      } else {
        return 'Update with Job Slot?';
      }
    } else {
      if (jobTitle == 'intern') {
        return 'Post with Internship Token?';
      } else if (jobTitle == 'senior') {
        return 'Post with Senior Post?';
      } else {
        return 'Post with Job Slot?';
      }
    }
  };

  const getContent = () => {
    if (isUpdate) {
      if (
        jobDataForm?.resourceType == 'internship' ||
        jobDataForm?.resourceType == 'free_super_senior'
      ) {
        if (jobTitle == 'intern') {
          return (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px'
                }}>
                <div>This will update your current job posting.</div>
                <DialogNoteStyled>
                  Note: If you choose to post with other products, you will not
                  be able to retrieve your current Internship token.
                </DialogNoteStyled>
              </div>
            </>
          );
        } else if (jobTitle == 'senior') {
          return (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px'
                }}>
                <div>This will update your current job posting.</div>
                <DialogNoteStyled>
                  Note: If you choose to post with other products, you will not
                  be able to retrieve your current Senior Post token.
                </DialogNoteStyled>
              </div>
            </>
          );
        } else {
          return 'Update with Job Slot?';
        }
      } else {
        if (jobTitle == 'intern') {
          return (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px'
                }}>
                <div>
                  This job is eligible for an Internship Token.
                  <br />
                  <br />
                  You have{' '}
                  <b>
                    {myCompanyStatus?.availableInternshipsTokenCount} Internship
                    Token(s)
                  </b>{' '}
                  left.
                </div>
                <DialogNoteStyled>
                  {/* Note: You can also choose to post with other products. */}
                  Note: One (1) token will be deducted from your account if you
                  choose to use an Internship Token.
                </DialogNoteStyled>
              </div>
            </>
          );
        } else if (jobTitle == 'senior') {
          return (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px'
                }}>
                <div>
                  This job is eligible for a Free Senior Post.
                  <br />
                  <br />
                  You have{' '}
                  <b>
                    {myCompanyStatus?.availableFreeSuperSeniorPostCount} Free
                    Senior Post(s)
                  </b>{' '}
                  left.
                </div>
                <DialogNoteStyled>
                  {/* Note: You can also choose to post with other products. */}
                  Note: One (1) Free Senior Post will be deducted from your
                  account if you choose to use a Free Senior Post.
                </DialogNoteStyled>
              </div>
            </>
          );
        } else {
          return 'Post with Job Slot?';
        }
      }
    } else {
      if (jobTitle == 'intern') {
        return (
          <>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <div>
                This job is eligible for an Internship Token.
                <br />
                <br />
                You have <b>{tokenCountIntern} Internship Token(s)</b> left.
              </div>
              <DialogNoteStyled>
                {/* Note: You can also choose to post with other products. */}
                Note: One (1) token will be deducted from your account if you
                choose to use an Internship Token.
              </DialogNoteStyled>
            </div>
          </>
        );
      } else if (jobTitle == 'senior') {
        return (
          <>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <div>
                This job is eligible for a Free Senior Post.
                <br />
                <br />
                You have <b>{tokenCountSenior} Free Senior Post(s)</b> left.
              </div>
              <DialogNoteStyled>
                {/* Note: You can also choose to post with other products. */}
                Note: One (1) Free Senior Post will be deducted from your
                account if you choose to use a Free Senior Post.
              </DialogNoteStyled>
            </div>
          </>
        );
      } else {
        return 'Post with Job Slot?';
      }
    }
  };

  const onPurchaseButtonClicked = async () => {
    window.open('/purchase/choose/?products=keyword_highlight_token-1');
  };

  const handleReduxChange = (key, value) => {
    dispatch(
      updateJobDataForm({
        ...jobDataForm,
        [key]: value
      })
    );
  };

  useEffect(() => {
    dispatch(viewPackageSubscriptionDetails());
  }, []);

  useEffect(() => {
    if (
      jobDataForm?.keywords?.length > 0 &&
      keywordHighlightTokensUsableCount > 0
    ) {
      setSwitchValue(true);
    }
  }, [jobDataForm]);

  useEffect(() => {
    if(enableKeywordState){
      if(jobDataForm?.resourceType == 'job_slot_max'){
        handleReduxChange(
          'enableKeywordHighlight',
          false
        );
      }else{
        handleReduxChange(
          'enableKeywordHighlight',
          true
        );
      }
    }
  }, [enableKeywordState]);

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      sx={{ maxWidth: '600px', margin: 'auto' }}>
      <DialogTitleStyled>
        {getJobTitle()}
        <IconButtonStyled onClick={handleClose}>
          <XMarkIcon height={24} width={24} />
        </IconButtonStyled>
      </DialogTitleStyled>
      <Divider />

      {jobDataForm?.keywords?.length > 0 && enableKeywordState !== true && (
        <>
          <KeywordTokenToggle>
            {keywordHighlightTokensUsableCount > 0 ? (
              <>
                <div>Apply one (1) Key Highlights Token.</div>
                <SwitchStyled
                  name="active"
                  checked={jobDataForm?.enableKeywordHighlight === true}
                  onChange={() => {
                    setSwitchValue(!jobDataForm?.enableKeywordHighlight);
                    handleReduxChange(
                      'enableKeywordHighlight',
                      !jobDataForm?.enableKeywordHighlight
                    );
                  }}
                  $width={120}
                  $height={45}
                  $thumbWidth={32}
                  $thumbHeight={32}
                />
              </>
            ) : (
              <div>
                Purchase Key Highlights Token{' '}
                <a
                  onClick={() => onPurchaseButtonClicked()}
                  style={{
                    color: '#FFF',
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}>
                  here
                </a>{' '}
                to activate your keywords now!
                <br />
                <br />
                <p
                  style={{
                    color: 'rgba(255, 255, 255, 0.60)',
                    fontStyle: 'italic',
                    margin: '0',
                    fontSize: '12px',
                    fontWeight: '400'
                  }}>
                  Tip: You can activate this later with a token after the job is
                  posted.
                </p>
              </div>
            )}
          </KeywordTokenToggle>
        </>
      )}
      <DialogContentStyled>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          {getContent()}
          <DialogActionsStyled>
            <DialogButton>
              <ButtonContinueEdit
                disabled={internLoading || seniorLoading}
                onClick={() => handleNextPage()}>
                Use Other Products
              </ButtonContinueEdit>
              <ButtonSkipEdit
                disabled={internLoading || seniorLoading}
                onClick={() => onConfirmButtonClicked(jobTitle)}>
                {internLoading || seniorLoading ? (
                  <CircularProgress sx={{ color: 'white' }} size={18} />
                ) : (
                  'Confirm'
                )}
              </ButtonSkipEdit>
            </DialogButton>
          </DialogActionsStyled>
        </div>
      </DialogContentStyled>
    </Dialog>
  );
};

export default JobTokenPopUp;
