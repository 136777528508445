import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../styles/colors.module.scss';

export const PopupContainer = styling('div')((props) => ({
  height: '560px',
  width: '560px',
  background: Colors.priWhite,
  position: 'relative',
}));

export const TitleContainer = styling('div')((props) => ({
  padding: '24px 24px 16px 48px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const Title = styling('span')((props) => ({
  fontFamily: 'Inter',
  fontWeight: 700,
  fontSize: '25px',
}));

export const ContentContainer = styling('div')((props) => ({
  padding: '18px 48px',
}));

export const ContentDescription = styling('span')({
  fontFamily: 'Inter',
  fontSize: '16px',
  letterSpacing: '0.15px',
});

export const TextFieldsContainer = styling('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
});

export const TextFieldContainer = styling('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',
});

export const Content = styling('li')((props) => ({
  ...(props.type == 'light_bulb' && {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    padding: '8px 15px',
    borderRadius: '30px',
  }),
}));

export const CTAContainer = styling('div')((props) => ({
  position: 'absolute',
  bottom: '0',
  width: '100%',

  ...(props.$showExtraButton && {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '0 20px',
  }),
}));

export const CTAButtonsContainer = styling('div')({
  position: 'relative',
  padding: '24px 0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const KeywordsCount = styling('span')({
  fontWeight: 400,
  fontFamily: 'Inter',
  fontSize: '16px',
  letterSpacing: '0.15px',
  color: (props) => (props.$error ? '#f75443' : 'rgba(0, 0, 0, 0.87)'),
});
