import { Accordion, Grid, Typography, Box } from '@mui/material';
import { styled as styling } from '@mui/material/styles';
import Slider from 'react-slick';

export const GridStyled = styling(Grid)(props => ({
  height: '100%',
  width: '100%',
  padding: '50px 100px 50px 100px',
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  maxWidth: '1440px',
  margin: 'auto'
}));

export const LogoTitle = styling(Typography)(props => ({
  textAlign: 'center',
  color: `rgba(0, 0, 0, 0.87)`,
  fontSize: '45px',
  fontWeight: 700,
  lineHeight: '32px'
}));

export const AccordionCategoryTitle = styling(Typography)(props => ({
  textAlign: 'left',
  color: `rgba(0, 0, 0, 0.87)`,
  fontSize: '25px',
  fontWeight: 700,
  lineHeight: '32px',
   margin: '0px 16px',
}));

export const AccordionTitle = styling(Typography)(props => ({
  textAlign: 'left',
  color: `rgba(0, 0, 0, 0.87)`,
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '32px'
}));

export const LogoTitlePurple = styling('span')(props => ({
  color: '#512ACC',
  fontSize: '45px',
  fontWeight: 700,
  lineHeight: '32px'
}));

export const DottedLine = styling(Box)(props => ({
  borderBottom: '1px dashed #D8D8D8',
  borderTop: 'none',
  width: 'auto',
  margin: '0px 16px 6px 16px',
  borderSpacing: '5px'
}));

export const StyledAccordion = styling(Accordion)(props => ({
  boxShadow: 'none',
  borderTop: 'none',
  outline: 'none',
  borderRadius: '0px',
  overflow: 'hidden',
  backgroundColor: 'transparent'
}));

export const AccordionContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '14px',
}));

export const DefaultContainer = styling(Grid)(props => ({}));
