import React, { Fragment, useEffect, useState } from 'react';
import { faSignal } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { useFormContext } from '../../context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/styles';
import { Slider } from '@mui/material';
import Colors from '../../../../../styles/colors.module.scss';
import { useFormData } from '../../helper/useFormData';
import { TextFieldStyled } from '../styles';
import { StyledChip } from './styles';

export default function ExperienceLevel() {
  const {
    formState,
    handleSingleFieldChange,
    disableIntern,
    disableSenior,
    handleErrorState,
    errorState
  } = useFormContext();
  const [chipIndex, setChipIndex] = useState(1);
  const formData = useFormData();
  const [experienceMinMax, setExperienceMinMax] = React.useState([]);

  useEffect(() => {
    setExperienceMinMax([
      formState?.minYearExperience,
      formState?.maxYearExperience
    ]);
    handleExperienceChange(null, [
      formState?.minYearExperience,
      formState?.maxYearExperience
    ]);
  }, [formData, formState?.jobType, formState?.careerLevel]);

  const experiences = useSelector(state => state?.companies?.experiences);

  const handleExperienceChange = (event, newValue) => {
    let isIntern = false;

    if (newValue[0] == -1) {
      isIntern = true;
      newValue[0] = -1;
    }

    setExperienceMinMax(newValue);

    let isCustom = true;
    let nextIndex = -1;

    if (isIntern) {
      isCustom = false;
      nextIndex = 0;
    } else if (newValue[0] == 0 && newValue[1] == 0) {
      isCustom = false;
      nextIndex = 1;
    } else if (newValue[0] == 1 && newValue[1] == 3) {
      isCustom = false;
      nextIndex = 2;
    } else if (newValue[0] == 4 && newValue[1] == 7) {
      isCustom = false;
      nextIndex = 3;
    } else if (newValue[0] == 8 && newValue[1] == 10) {
      isCustom = false;
      nextIndex = 4;
    } else if (newValue[0] == 10 && newValue[1] == 20) {
      isCustom = false;
      nextIndex = 5;
    }

    if (isCustom) {
      setChipIndex(6);
    }

    if (nextIndex != -1) {
      setChipIndex(nextIndex);
    }

    if (handleSingleFieldChange) {
      handleSingleFieldChange('minYearExperience', newValue[0]);
      handleSingleFieldChange('maxYearExperience', newValue[1]);
    }
  };

  const populateOnSlider = index => {
    if (index == 0) {
      handleExperienceChange(null, [-1, 0]); // make intern start from -1 for distinction
      handleSingleFieldChange('careerLevel', { id: '8' });
      handleSingleFieldChange('jobType', { id: '4' });
      handleErrorState(
        errorState.filter(
          error => !error.includes('jobType') && !error.includes('careerLevel')
        )
      );
    } else if (index == 1) {
      handleExperienceChange(null, [0, 0]);
    } else if (index == 2) {
      handleExperienceChange(null, [1, 3]);
    } else if (index == 3) {
      handleExperienceChange(null, [4, 7]);
    } else if (index == 4) {
      handleExperienceChange(null, [8, 10]);
    } else if (index == 5) {
      handleExperienceChange(null, [10, 20]);
    } else if (index == 6) {
      setChipIndex(6);
      handleExperienceChange(null, [0, 20]);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: '0px 0px 20px 0'
      }}>
      <FontAwesomeIcon
        icon={faSignal}
        style={{
          marginRight: !disableIntern && !disableSenior ? '15px' : '8px'
        }}
      />

      {disableIntern && <TextFieldStyled disabled value={'Intern'} />}
      {disableSenior && (
        <TextFieldStyled disabled value={'Over 10 Years of Experience'} />
      )}

      {!disableIntern && !disableSenior && (
        <div style={{ width: '100%' }}>
          {experiences?.map((item, index) => {
            return (
              <Fragment key={item?.id}>
                <StyledChip
                  label={item?.title}
                  clickable
                  onClick={() => populateOnSlider(index)}
                  variant="outlined"
                  style={{ margin: '0 2px 10px 2px' }}
                  selected_chip={index == chipIndex ? 'true' : 'false'}
                />
              </Fragment>
            );
          })}
          <StyledChip
            label={'Custom'}
            clickable
            onClick={() => populateOnSlider(6)}
            variant="outlined"
            style={{ margin: '0 2px 10px 2px' }}
            selected_chip={chipIndex == 6 ? 'true' : 'false'}
          />

          <PrettoSlider
            value={experienceMinMax}
            onChange={handleExperienceChange}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            min={0}
            max={20}
            size={'medium'}
          />
        </div>
      )}
    </div>
  );
}

const PrettoSlider = styled(Slider)({
  color: '#D8D8D8',
  height: 3,
  '& .MuiSlider-track': {
    border: 'none',
    color: Colors.priPurple
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: `1px solid ${Colors.priPurple}`,
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit'
    },
    '&:before': {
      display: 'none'
    }
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    color: Colors.priBlack,
    fontWeight: 'bold',
    fontSize: '15px',
    // borderRadius: '50% 50% 50% 0',
    backgroundColor: 'transparent',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, 110%) rotate(-45deg) scale(1)'
    },
    '& > *': {
      transform: 'rotate(45deg)'
    }
  }
});
