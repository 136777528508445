import { styled as styling } from '@mui/material/styles';

import { Button } from '@mui/material';

export const ButtonStyled = styling(Button)((props) => ({
  ...(props.type == 'basic' && {
    borderRadius: '30px',
    boxShadow: '0px 4px 20px rgb(0 0 0 / 15%)',
    background: '#512acc',
    alignSelf: 'center',
    color: '#fff',
    fontWeight: 700,
    padding: '5px 20px',
    fontSize: '12px',
    fontFamily: 'Inter',

    '&:hover': {
      background: '#512acc',
    }
  }),
  ...(props.type == 'disabled' && {
    borderRadius: '30px',
    boxShadow: '0px 4px 20px rgb(0 0 0 / 15%)',
    alignSelf: 'center',
    fontWeight: 700,
    padding: '5px 20px',
    color: 'rgba(0, 0, 0, 0.26)',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    fontSize: '12px',
    fontFamily: 'Inter',
  }),
}));

export const ButtonContainer = styling('div')({
  marginRight: '10px',
  display: 'flex',
  alignItems: 'center',
});
