import { styled as styling } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

export const BenefitContainer = styling(Grid)(props => ({
  backgroundColor: 'white',
  width: '296px',
  border: '1px solid #D8D8D8',
  minHeight: '188px',
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  padding: '16px',
  borderRadius: '8px'
}));

export const BenefitContent = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column'
}));

export const BenefitTitle = styling(Typography)(props => ({
  color: '#000',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px'
}));

export const BenefitDesc = styling(Typography)(props => ({
  color: '#000',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));
