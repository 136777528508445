import React from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultLayout from '../../layouts/DefaultLayout';
import NotFoundImage from '../../assets/common/404.png';
import {
  Container,
  Row,
  ButtonContainer,
  TitleText,
  NormalText,
  ContactText,
  ContactTextLink,
  TextBox,
  BackButton
} from './styles';
import { MUIButtonStyled } from '../../components/MUIButton';
import { CSEMAIL } from '../../utils/Constants';
import { getCountry } from '../../hooks/useRegionalisation';

export default function NotFound() {
  const history = useNavigate();
  const isAuthenticated = localStorage.getItem('accessToken');

  const onClickContactUs = () => {
    window.open(`mailto:${CSEMAIL[getCountry()]}`);
  };

  return (
    <DefaultLayout>
      <Container>
        <img
          alt="404"
          src={NotFoundImage}
          style={{ height: 320, width: 320 }}
        />
        <TextBox>
          <Row>
            <TitleText>Oops, this page does not exist</TitleText>
          </Row>
          <Row>
            <NormalText>
              Sorry, the page you&apos;re looking for isn&apos;t here.
              <br />
              This can happen because of typos in the web address or outdated
              links.
            </NormalText>
          </Row>
          <Row>
            <ContactText>
              For urgent inquiries, please contact{' '}
              <ContactTextLink onClick={onClickContactUs}>
                {CSEMAIL[getCountry()]}.
              </ContactTextLink>
            </ContactText>
          </Row>
        </TextBox>

        <ButtonContainer>
          <BackButton
            onClick={() =>
              history(`${isAuthenticated ? '/jobs' : '/login'}`)
            }>
            Back To Dashboard
          </BackButton>
        </ButtonContainer>
      </Container>
    </DefaultLayout>
  );
}
