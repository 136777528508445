import React, { useEffect } from 'react';
import { Grid, Skeleton } from '@mui/material';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorToast, SuccessToast } from '../../utils/ToastUtils';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'; // Import default styles
import './BasicInformation.css';
import { MUIButtonStyled } from '../../components/MUIButton';
import { ArrowDropDownIconStyled, ButtonContainer, CardContainer, RowLabel } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { mutateAccountInfo } from '../../redux/actions/company_actions';
import SharedTextField from '../../components/SharedTextField';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .matches(
      /^[a-zA-Z@\/ '-]+$/g,
      "Name can only contain alphabets & special character @ / ' -"
    ),
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email format'),
  contactNumber: yup.string().required('Contact Number is required')
});

const BasicInformation = ({ isDataLoading, accInfo }) => {
  const mutatingCompanyDetails = useSelector(
    state => state.jobs.mutatingCompanyDetails
  );

  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: accInfo?.name || '',
      email: accInfo?.email || '',
      contactNumber: accInfo?.contactNumber || ''
    },
    resolver: yupResolver(validationSchema)
  });

  const onSubmitAccountInfo = async values => {
    dispatch(
      mutateAccountInfo({
        input: {
          name: values?.name,
          email: values?.email,
          contactNumber: values?.contactNumber
        }
      })
    )
      .then(res => {
        if (res.mutateCompanyDetailsSuccess) {
          SuccessToast('Account Info Updated!');
        } else {
          ErrorToast('Account Info Failed to Update');
        }
      })
      .catch(err => {
        ErrorToast(err.message);
      });
  };

  useEffect(() => {
    if (accInfo?.contactNumber) {
      const valid = isValidPhoneNumber(accInfo?.contactNumber);
      setValue('validPhoneNumber', valid);
    }
  }, [accInfo?.contactNumber, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmitAccountInfo)}>
      <CardContainer>
        <div>
          <RowLabel>Name</RowLabel>
          {isDataLoading ? (
            <div>
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
            </div>
          ) : (
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <SharedTextField
                  {...field}
                  placeholder="Name"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
          )}
        </div>
        <div>
          <RowLabel>Email</RowLabel>
          {isDataLoading ? (
            <div>
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
            </div>
          ) : (
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <SharedTextField
                  {...field}
                  placeholder="Email"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />
          )}
        </div>
        <div>
          <RowLabel>Contact Number</RowLabel>
          {isDataLoading ? (
            <div>
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
            </div>
          ) : (
            <Controller
              name="contactNumber"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Grid sx={{ position: 'relative' }}>
                  <PhoneInput
                    placeholder="+6012 3456789"
                    value={value}
                    defaultCountry={'MY'}
                    countryCallingCodeEditable={false}
                    international
                    onChange={val => {
                      onChange(val);
                      setValue('validPhoneNumber', isValidPhoneNumber(val));
                    }}
                  />
                  <Grid
                    sx={{
                      position: 'absolute',
                      top: '18px',
                      right: '280px',
                      height: '50px',
                      width: '50px'
                    }}>
                    <ArrowDropDownIconStyled />
                  </Grid>
                  {errors.contactNumber && (
                    <span
                      style={{
                        marginLeft: '14px',
                        marginRight: '14px',
                        color: '#f44336',
                        fontSize: '0.75rem',
                        marginTop: '3px'
                      }}>
                      {errors.contactNumber.message}
                    </span>
                  )}
                </Grid>
              )}
            />
          )}
        </div>

        <ButtonContainer>
          <MUIButtonStyled
            disabled={mutatingCompanyDetails}
            type="submit"
            label="Save Changes"
          />
        </ButtonContainer>
      </CardContainer>
    </form>
  );
};

export default BasicInformation;
