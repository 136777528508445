import { useState, useCallback, useEffect } from 'react';

export const useFormState = initialValues => {
  const [formState, setFormState] = useState(initialValues);
  const [errorState, setErrorState] = useState([]);

  useEffect(() => {
    setFormState(initialValues);
  }, [initialValues]);

  const handleFieldChange = useCallback(e => {
    let { name, value, type, checked } = e.target || e;

    if (!e.target) {
      ({ name, value } = e);
    }

    setFormState(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  }, []);

  const handleEditorChange = useCallback((editorState, name) => {
    setFormState(prev => ({
      ...prev,
      [name]: editorState
    }));
  }, []);

  const handleSingleFieldChange = useCallback((key, newData) => {
    setFormState(prev => ({
      ...prev,
      [key]: newData
    }));
  }, []);

  const handleErrorState = useCallback(error => {
    setErrorState(error);
  }, []);

  return {
    formState,
    handleFieldChange,
    handleEditorChange,
    handleSingleFieldChange,
    errorState,
    handleErrorState
  };
};
