import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { IconButton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import React from 'react';
import {
  IconButtonStyled,
  NumberContainer,
  PaginationContainer,
  PaginationNumbersContainer,
  TypographyStyled
} from './styles';

export const SharedPagination = ({
  onClickLeftArrow,
  onClickRightArrow,
  type,
  index,
  loading
}) => {
  const getPageInfoByType = (type, state) => {
    switch (type) {
      case 'search-resume': {
        return state.talents.talentsPageInfo;
      }
      case 'bookmarked': {
        return state.talents.bookmarkedTalentsPageInfo;
      }
      case 'unlocked': {
        return state.talents.unlockedTalentsPageInfo;
      }
      default: {
        return state.talents.talentsPageInfo;
      }
    }
  };

  const pageInfo = useSelector(state => getPageInfoByType(type, state));  

  return (
    !loading && (
      <PaginationContainer>
        {index > 1 && (
          <IconButtonStyled onClick={onClickLeftArrow}>
            <ChevronLeftIcon style={{ height: '16px', width: '16px' }} />
          </IconButtonStyled>
        )}

        <PaginationNumbersContainer>
          {index > 1 && (
            <NumberContainer>
              <TypographyStyled variant="body2">{index - 1}</TypographyStyled>
            </NumberContainer>
          )}

          <NumberContainer $selected={true}>
            <TypographyStyled variant="body2" $selected={true}>
              {index}
            </TypographyStyled>
          </NumberContainer>
          {pageInfo?.hasNextPage && (
            <NumberContainer>
              <TypographyStyled variant="body2">{index + 1}</TypographyStyled>
            </NumberContainer>
          )}
        </PaginationNumbersContainer>

        {pageInfo?.hasNextPage && (
          <IconButtonStyled onClick={onClickRightArrow}>
            <ChevronRightIcon style={{ height: '16px', width: '16px' }} />
          </IconButtonStyled>
        )}
      </PaginationContainer>
    )
  );
};
