import { styled as styling } from '@mui/material/styles';
import Colors from '../../styles/colors.module.scss';
import { Info as InfoIcon } from '@mui/icons-material';

// start of Tips tooltip
export const TooltipWrapper = styling('div')((props) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  zIndex: props.$wrapperZIndex ? props.$wrapperZIndex : '3',
}));

export const TipsTextContainer = styling('div')((props) => ({
  borderRadius: '6px',
  background: Colors.secLightPurple,
  gap: '6px',
  padding: '6px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    background: props.$isMouseEnter && props.$title == 'Job Title'
        ? '#fbe7bf'
        : 'rgba(242, 175, 41, 0.3)',
    boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.16)',
    cursor: 'pointer',
  },
}));

export const TipsText = styling('span')({
  fontSize: '10px',
});

export const CustomPopover = styling('div')((props) => ({
  borderRadius: props.$borderRadius ? props.$borderRadius : '24px',
  border: props.$border ? props.$border : `3px solid ${Colors.priBlack}`,
  boxShadow: props.$shadow ? props.$shadow : '4px 4px 20px rgba(0, 0, 0, 0.16)',
  position: props.position ? props.position : 'absolute',
  top: props.top ? props.top : '-50px',
  left: props.left ? props.left : '80px',
  background: Colors.priWhite,
  zIndex: props.$zIndex ? props.zIndex : '3',
  width: props.$width ? props.$width : '400px',
  padding: props.$padding ? props.$padding : '14px 16px',
  minHeight: props.$minHeight ? props.$minHeight : 'auto',
  fontSize: props.$fontSize ? props.$fontSize : '14px',
  fontFamily: 'Inter',
  animation: 'fadeInAnimation ease 1s',
  animationIterationCount: '1',
  animationFillMode: 'forwards',
  '@keyframes fadeInAnimation': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 100,
    },
  },
  '@keyframes fadeOutAnimation': {
    '0%': {
      opacity: '100%',
    },
    '100%': {
      opacity: 0,
    },
  },
}));

export const TitleContainer = styling('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const Title = styling('span')({
  fontSize: '14px',
  fontWeight: 700,
  color: Colors.priBlack,
  opacity: 0.6,
});

export const DescriptionContainer = styling('div')((props) => ({
  padding: '8px 10px',
  background: Colors.secLightPurple,
  borderRadius: '16px',
  minHeight: '80px',
  animation: props.$slide && props.$index == 0
      ? 'slideOutAnimation 1s'
      : 'fadeInAnimation ease 1s',
  animationIterationCount: '1',
  animationFillMode: 'forwards',
  '@keyframes slideOutAnimation': {
    '0%': {
      transform: 'translateX(0)',
      opacity: 1,
    },
    '100%': {
      transform: 'translateX(-20px)',
      opacity: 0,
    },
  },
  '@keyframes fadeInAnimation': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

export const PaginationContainer = styling('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const PaginationText = styling('span')({
  margin: '0 10px',
  fontSize: '12px',
});

export const IconPrevStyled = styling('img')((props) => ({
  ...(props.$type == 'icon-prev' && {
    transform: 'rotate(180deg)',

    '&:hover': {
      cursor: 'pointer',
    }
  }),
}));

export const IconNextStyled = styling('img')((props) => ({
  ...(props.$type == 'icon-next' && {
    '&:hover': {
      cursor: 'pointer',
    }
  }),
}));
// end of Tips tooltip

// start of simple tooltip
export const InfoIconStyled = styling(InfoIcon)({
  margin: '0 15px',
  color: Colors.terMediumGrey,
  fontSize: '20px',
});

// end of simple tooltip
