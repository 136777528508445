import { Drawer } from '@mui/material';
import {
  Grid,
  Typography,
  DialogTitle,
  DialogContent,
  Button,
  IconButton
} from '@mui/material';
import { styled as styling } from '@mui/material/styles';

export const DefaultContainer = styling(Grid)(props => ({}));

export const DefaultTypography = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.87)`,
  fontFamily: 'Inter',
  lineHeight: '24px'
}));

export const DefaultSpan = styling('span')(props => ({}));

export const GridStyled = styling(Grid)(props => ({}));

export const DrawerStyled = styling(Drawer)(props => ({
  '& .MuiDrawer-paper': {
    height: '720px',
    borderRadius: '16px 16px 0px 0px'
  }
}));

//Dialog title
export const DialogTitleStyled = styling(DialogTitle)(props => ({
  textAlign: 'center'
}));

export const CloseContainer = styling(Grid)(props => ({
  position: 'absolute',
  right: '0px'
}));

export const TitleStyled = styling(Typography)(props => ({
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '24px'
}));

export const IconButtonStyled = styling(IconButton)(props => ({
  position: 'absolute',
  right: 20,
  top: 8
}));

//Dialog Content
export const DialogContentStyled = styling(DialogContent)(props => ({
  padding: '60px 50px',
  height: '100%',
  background: `radial-gradient(62.66% 62.65% at 61.56% 37.35%, rgba(81, 42, 204, 0.05) 0%, rgba(255, 255, 255, 0.00) 100%)`
}));

export const BundleCardsContainer = styling(Grid)(props => ({
  gap: '10px',
  display: 'flex',
  flexDirection: 'row'
}));

export const BundleCards = styling(Grid)(props => ({
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  borderRadius: '10px',
  border: '1px solid var(--Grey-Grey, #D8D8D8)',
  width: '232px',
  height: '204px',
  position: 'relative'
}));

export const InfoCards = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '16px',
  borderRadius: '10px',
  border: '1px solid var(--Grey-Grey, #D8D8D8)',
  width: '232px',
  height: '204px',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'center',
  backgroundColor: "white"
}));

export const ButtonStyled = styling(Button)(props => ({
  width: '100%',
  height: '36px',
  backgroundColor: '#2E2E2E',
  color: 'white',
  fontSize: '16px',
  fontWeight: 700,
  borderRadius: '8px',
  textTransform: 'none',

  ':hover': {
    backgroundColor: '#2E2E2E',
    color: 'white'
  }
}));

export const DiscountBanner = styling(Grid)(props => ({
  backgroundColor: '#6EC197',
  color: 'white',
  padding: '2px 6px',
  position: 'absolute',
  fontSize: '12px',
  fontWeight: '700',
  borderRadius: '4px 0px 0px 4px',
  right: '0px',
  top: '16px'
}));

export const Content = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '100px',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '100%',
  height: '100%'
}));

export const BundlesContainer = styling(Grid)(props => ({
  flexDirection: 'column',
  gap: props?.personalized ? '30px' : '32px',
  display: props.selected ? 'flex' : 'none',
  opacity: props.selected ? 1 : 0, // 1 for visible, 0 for invisible
  visibility: props.selected ? 'visible' : 'hidden', // Make it visible or hidden
  transition: 'all 0.5s ease, visibility 0.5s' // Transition for both opacity and visibility
}));

// Bundle Details
export const MaxText = styling(Typography)(props => ({
  background: 'linear-gradient(90deg, #6EC7CD 18.57%, #B0CD72 96.74%)',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontSize: '12px',
  fontWeight: '700',
  lineHeight: '20px',
  marginLeft: '3px'
}));

export const PlusText = styling(Typography)(props => ({
  fontSize: '12px',
  fontWeight: '700',
  lineHeight: '20px',
  marginLeft: '3px',
  color: '#512ACC'
}));

export const JobTypeContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  width: '160px'
}));

export const DetailsContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '10px 10px 10px 10px',
  border: '1px dashed #D8D8D8',
  borderRadius: '0px 10px 10px 10px',
  position: 'relative'
}));

export const BundleInnerContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px'
}));

export const TickContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  width: '100%'
}));

export const DetailsButton = styling(Button)(props => ({
  width: '200px',
  height: '36px',
  backgroundColor: 'white',
  color: 'black',
  fontSize: '14px',
  fontWeight: 700,
  borderRadius: '8px',
  textTransform: 'none',
  border: '2px solid black',

  ':hover': {
    backgroundColor: '#EFEFEF',
    color: 'black'
  }
}));

export const PurpleTag = styling(Grid)(props => ({
  padding: '4px 16px',
  backgroundColor: '#EFEFFD',
  borderRadius: `8px 8px 0px 0px`,
  fontWeight: '700',
  fontSize: '14px',
  width: 'fit-content'
}));

// Type Selection
export const TypeContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px'
}));

export const SelectionButton = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px 8px',
  cursor: 'pointer',
  justifyContent: 'space-between',
  width: '175px'
}));

export const SelectionTitle = styling(Typography)(props => ({
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '24px',
  color: props?.selected ? `rgba(0, 0, 0, 0.87)` : 'rgba(0, 0, 0, 0.60)',
  fontFamily: 'Inter',
  letterSpacing: "0.15px"
}));

export const SelectionSubTitle = styling(Typography)(props => ({
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '20px',
  color: props?.selected ? `#512ACC` : 'rgba(0, 0, 0, 0.60)',
  fontStyle: 'italic',
  fontFamily: 'Inter'
}));

export const Divider = styling(Grid)(props => ({
  transition: 'all 0.3s',
  width: '160px',
  height: '1px',
  backgroundColor: props?.selected ? `#512ACC` : '#D8D8D8'
}));

export const PurpleDivider = styling(Grid)(props => ({
  transition: 'all 0.3s ease',
  width: props?.selected ? '160px' : '0px',
  height: '2px',
  backgroundColor: `#512ACC`,
  position: 'relative',
  left: '50%',
  transform: 'translateX(-50%)'
}));
