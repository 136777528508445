import { getCountry } from './useRegionalisation';

export const initializeGTM = () => {
  const environment =
    process.env.REACT_APP_SG_API_URL.includes(
      'staging-sg-api.hiredly.com/api'
    ) ||
    process.env.REACT_APP_API_URL.includes('staging-my-api.hiredly.com/api')
      ? 'staging'
      : 'prod';

  if (environment == 'prod') {
    return { id: 'GTM-PHRMVQD' };
  } else {
    return {
      id: 'GTM-PHRMVQD',
      environment: {
        gtm_auth: 'L0Lwmn9H-LtDdr57Zd69aQ',
        gtm_preview: 'env-410'
      }
    };
  }

  //   if (country == 'SG') {
  //     if (environment == 'prod') {
  //       return { id: 'GTM-KQSZSLRW' };
  //     } else {
  //       return {
  //         id: 'GTM-KQSZSLRW',
  //         environment: {
  //           gtm_auth: 'nlXKejVyHjTkwemW7MtVTA&gtm',
  //           gtm_preview: 'env-3'
  //         }
  //       };
  //     }
  //   } else if (country == 'MY') {
  //     if (environment == 'prod') {
  //       return { id: 'GTM-PHRMVQD' };
  //     } else {
  //       return {
  //         id: 'GTM-PHRMVQD',
  //         environment: {
  //           gtm_auth: 'L0Lwmn9H-LtDdr57Zd69aQ',
  //           gtm_preview: 'env-410'
  //         }
  //       };
  //     }
  //   }
};
