import React from 'react';
import { TextFieldStyled } from './styles';

export const TextArea = ({ value, handleChange, placeholder }) => {
  return (
    <TextFieldStyled
      id="filled-multiline-flexible"
      placeholder={placeholder}
      multiline
      rowsMax={4}
      value={value}
      type={'text'}
      onChange={handleChange}
      variant="filled"
      inputProps={{
        style: {
          borderRadius: '8px',
          width: '100%',
          height: '100px',
          overflow: 'auto',
          transform: 'translateY(-5px)'
        }
      }}
      InputProps={{
        style: {
          fontSize: '14px',
          letterSpacing: '0.03rem',
          borderRadius: '8px',
          width: '100%',
          height: '120px',
          background: 'transparent'
        },
        disableUnderline: true
      }}
    />
  );
};
