import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTalentSearchFAQPopup } from '../../../redux/actions/talent_action';
import InfoPopup from '../../Jobs/SharedPopups/InfoPopup';
import Content from './Content';

const FAQPopup = () => {
  const dispatch = useDispatch();
  const talentSearchFAQPopup = useSelector(
    state => state.talents.talentSearchFAQPopup
  );

  const onClosePopup = () => {
    dispatch(updateTalentSearchFAQPopup(false));
  };

  return (
    <InfoPopup
      open={talentSearchFAQPopup}
      onClosePopup={onClosePopup}
      $dialogMaxWidth={'771px'}
      $dialogMaxHeight={'calc(100% - 200px)'}
      title={'Talent Search FAQ'}
      content={<Content />}
    />
  );
};

export default FAQPopup;
