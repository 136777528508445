import { TextField } from '@mui/material';
import React from 'react';
import PasswordStrength from '../PasswordStrength';

const SharedTextField = props => {
  return (
    <>
      <TextField
        {...props}
        sx={{
          ...props?.sx,
          width: '100%',
          height: props?.sx?.height,
          '& .MuiOutlinedInput-root': {
            alignItems: props?.sx?.height && 'flex-start',
            height: props?.sx?.height,
            '& fieldset': {
              borderRadius: '8px'
            },
            '&:hover fieldset': {
              border: '1px solid #AEAEAE'
            },
            '&.Mui-focused fieldset': {
              border: '1px solid #512ACC'
            }
          }
        }}
      />
      {props?.showPasswordStrength && (
        <PasswordStrength password={props?.value} />
      )}
    </>
  );
};

export default SharedTextField;
