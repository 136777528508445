import React, { useState } from 'react';
import { Select, MenuItem, Tooltip, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FiberManualRecord as FiberManualRecordIcon } from '@mui/icons-material';
import { JOB_APPLICATION_STATUS } from '../../../utils/Constants';
import styles from './index.module.scss';
import { toggleRejectPopup } from '../../../redux/actions/application_action';
import Colors from '../../../styles/colors.module.scss';

const useStyles = makeStyles(() => ({
  root: {
    height: 34,
    width: 130,
    backgroundColor: Colors.priWhite,
    '& .MuiOutlinedInput-input': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontSize: 16,
      fontFamily: 'InterBold'
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent'
    }
  }
}));

const StatusSelect = ({
  changeLoading,
  dispatch,
  disabled,
  onChange,
  value,
  anchorOrigin
}) => {
  const classes = useStyles();

  return (
    <Select
      variant="outlined"
      className={classes.root}
      disabled={disabled}
      MenuProps={{
        PaperProps: {
          style: {
            background: '#fff'
          }
        },
        anchorOrigin: anchorOrigin
          ? anchorOrigin
          : {
              vertical: 'bottom',
              horizontal: 65
            },
        getContentAnchorEl: null
      }}
      value={value}
      onChange={onChange}>
      <MenuItem
        key={JOB_APPLICATION_STATUS.UNDECIDED}
        value={JOB_APPLICATION_STATUS.UNDECIDED}>
        <FiberManualRecordIcon className={styles.lightGreyIcon} />
        <p className={styles.iconText}>Undecided</p>
      </MenuItem>
      <MenuItem
        key={JOB_APPLICATION_STATUS.SHORTLISTED}
        value={JOB_APPLICATION_STATUS.SHORTLISTED}>
        <FiberManualRecordIcon className={styles.greenIcon} />
        <p className={styles.iconText}>Shortlisted</p>
      </MenuItem>
      <MenuItem
        key={JOB_APPLICATION_STATUS.KIV}
        value={JOB_APPLICATION_STATUS.KIV}>
        <FiberManualRecordIcon className={styles.orangeIcon} />
        <p className={styles.iconText}>KIV</p>
      </MenuItem>
      <MenuItem
        key={JOB_APPLICATION_STATUS.REJECTED}
        value={JOB_APPLICATION_STATUS.REJECTED}>
        <FiberManualRecordIcon className={styles.redIcon} />
        <p className={styles.iconText}>Rejected</p>
      </MenuItem>

      <MenuItem
        key={JOB_APPLICATION_STATUS.BLACKLISTED}
        value={JOB_APPLICATION_STATUS.BLACKLISTED}>
        <FiberManualRecordIcon className={styles.blackIcon} />
        <p className={styles.iconText}>Blacklisted</p>
      </MenuItem>
    </Select>
  );
};

export default StatusSelect;
