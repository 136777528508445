import * as types from '../types/talent_type';
import api from '../../utils/api';
import { updateCompanyAvailableCoins } from './company_actions';
const { REACT_APP_API_VERSION } = process.env;

const userAttributes = `
  id
  name
  shortSummary
  nationality
  gender
  email
  mobileNumber
  viewed
  currentCompany
  currentLocation
  workLocationPreference
  rightToWork
  fieldOfStudy
  major
  highestEducationLevel
  educationalInstitution
  graduationYear
  age
  profileImageSquare
  trackIds
  videoCvOptimized
  resume
  uploadResumeAt
  lastActiveAt
  languages
  skills
  bookmarked
  hidden 
  unlocked
  companyResumeId
  resumePrice
  freshGrad
  companyResumeSendbirdChannelUrl
  companyResumeChatStatus
  unlockedAt
  aiResumeInformationErrors
  workingExperiencesInformationErrors
  specialisations {
    id
    title
    parentCategory {
      id
      title
    }
  }
  workingExperiences {
    totalCount
    edges {
      node {
        id
        companyName
        jobTitle
        description
        specialization
        industry {
          id
          title
        }
        startDateMonth
        startDateYear
        endDateMonth
        endDateYear
        currentWork
        createdAt
        updatedAt
        description
      }
    }
  }
  educations {
    totalCount
    edges {
      node {
        id
        educationLevel
        educationalInstitution
        fieldOfStudy
        graduationYear
        description
        createdAt
        updatedAt
      }
    }
  }
  honorAndAwards {
    totalCount
    nodes {
      id
      title
      description
      completionYear
      createdAt
      updatedAt
    }
  }
  extracurriculars {
    totalCount
    nodes {
      id
      title
      description
      completionYear
      createdAt
      updatedAt
    }
  }
  projectAndPortfolios {
    totalCount
    nodes {
      id
      title
      description
      projectUrl
      completionYear
      createdAt
      updatedAt
    }
  }
  certificateAndTrainings {
    totalCount
    nodes {
      id
      title
      issuingOrganization
      credentialUrl
      completionYear
      createdAt
      updatedAt
    }
  }
`;

const updateTalentsColumnRef = ref => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_TALENTS_COLUMN_REF,
        talentsColumnRef: ref
      })
    );
  });
};

const updateUnlockProfileStatus = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_UNLOCK_PROFILE_STATUS,
        status: status
      })
    );
  });
};

const updateHideProfileStatus = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_HIDE_PROFILE_STATUS,
        status: status
      })
    );
  });
};

const getTalents =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.FETCH_TALENTS });

    const paginationQuery = params.before
      ? `before: ${JSON.stringify(params.before)}, after: "", last: 10,`
      : `after: ${JSON.stringify(params.after)}, first: 10,`;

    const experienceQuery =
      params.minYearExperience >= 0
        ? `yearsOfExperience: ${
            params.minYearExperience === 0 ? null : params.minYearExperience
          },`
        : `graduationYear: {min: ${params.minGraduationYear}, max: ${params.maxGraduationYear}},`;

    const queryInput =
      paginationQuery +
      experienceQuery +
      `  
    sort:  ${
      params.sort
        ? `{ by: "${params.sort.by}", direction: "${params.sort.direction}" }`
        : null
    },
    withVideoCoverLetter: ${params.withVideoCoverLetter},
    trackIds: ${JSON.stringify(params.trackIds)},
    specialisations: ${JSON.stringify(params.specialisations)},
    industryIds: ${JSON.stringify(params.industryIds)},
    languages: ${JSON.stringify(params.languages)},
    freshGrad: ${params.freshGrad},
    advancedSearch: ${params.advancedSearch} ,
    filterMode: ${JSON.stringify(params.filterMode)},
    keyword: ${JSON.stringify(params.keyword)},
    wordMatch: ${JSON.stringify(params.wordMatch)},
    nationalities: ${JSON.stringify(params.nationalities)},
    currentLocation: ${JSON.stringify(params.currentLocation)},
    relocate: ${params.relocate ? params.relocate : null},
    remote: ${params.remote ? params.remote : null},
    openToSponsor: ${params.openToSponsor ? params.openToSponsor : null},
    visaOrEmploymentPass: ${
      params.visaOrEmploymentPass ? params.visaOrEmploymentPass : null
    },
    localCitizen: ${params.localCitizen ? params.localCitizen : null},
    educationLevels: ${JSON.stringify(params.educationLevels)},    
    resumeLastUpdatedAt: ${JSON.stringify(Number(params.resumeLastUpdated))},
    showHiddenProfile: ${params.showHiddenProfile},
    showViewedProfile: ${false}
    `;

    const payload = {
      query: `{
        searchTalents(${queryInput}) {
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              ${userAttributes}
            }
            cursor
          }
        }
      }    
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data.data?.searchTalents) {
            if (
              params.showHiddenProfile ==
              getState().talents.searchTalentFilters?.showHiddenProfile
            ) {
              const talents = response.data.data.searchTalents;
              return dispatch({
                type: types.FETCH_TALENTS_SUCCEED,
                payload: {
                  talents: talents,
                  refresh: params.refresh,
                  keyword: params.keyword,
                  searchTalentsFilter: params
                }
              });
            } else {
              return dispatch({
                type: types.FETCH_TALENTS_FAILED
              });
            }
          }
        }
        return dispatch({
          type: types.FETCH_TALENTS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_TALENTS_FAILED
        });
      });
  };

const getBookmarkedTalents =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.FETCH_BOOKMARKED_TALENTS });

    const paginationQuery = params.after
      ? `, after: ${JSON.stringify(params.after)}`
      : params.before
      ? `, before: ${JSON.stringify(params.before)}, after: "", last: 10`
      : '';
    const dateQuery = params.bookmarkedDate
      ? `, bookmarkedDate: ${params.bookmarkedDate}`
      : '';
    const idQuery =
      params.bookmarkedByIds?.length > 0
        ? `, bookmarkedByIds: ${JSON.stringify(params.bookmarkedByIds)}`
        : '';
    const keywordQuery =
      params.keyword?.length > 0
        ? `, keywords: ${JSON.stringify(params.keyword)}`
        : '';

    let queryInput =
      `          
      sort:  ${
        params.sort
          ? `{ by: "${params.sort.by}", direction: "${params.sort.direction}" }`
          : null
      }    
      ` +
      paginationQuery +
      dateQuery +
      idQuery +
      keywordQuery;

    const payload = {
      query: `{
        companyBookmarkedResumes(${queryInput}) {
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              userId
              user {
                ${userAttributes}
              }
              companyId
              company {
                id
              }
              companyTeamMemberId
              companyTeamMember {
                id
              }
            }
          }
        }
      }    
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data.data?.companyBookmarkedResumes) {
            const bookmarkedTalents =
              response.data.data.companyBookmarkedResumes;
            return dispatch({
              type: types.FETCH_BOOKMARKED_TALENTS_SUCCEED,
              payload: {
                bookmarkedTalents: bookmarkedTalents,
                refresh: params.refresh,
                filterParams: params,
                prevEndCursor:
                  getState().talents.bookmarkedTalentsPageInfo?.endCursor
              }
            });
          }
        }
        return dispatch({
          type: types.FETCH_BOOKMARKED_TALENTS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_BOOKMARKED_TALENTS_FAILED
        });
      });
  };

const getUnlockedTalents =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.FETCH_UNLOCKED_TALENTS });

    const paginationQuery = params.after
      ? `, after: ${JSON.stringify(params.after)}`
      : params.before
      ? `, before: ${JSON.stringify(params.before)}, after: "", last: 10`
      : '';
    const dateQuery = params.unlockedDate
      ? `, unlockedDate: ${params.unlockedDate}`
      : '';
    const idQuery =
      params.unlockedByIds?.length > 0
        ? `, unlockedByIds: ${JSON.stringify(params.unlockedByIds)}`
        : '';

    const keywordQuery =
      params.keyword?.length > 0
        ? `, keywords: ${JSON.stringify(params.keyword)}`
        : '';

    let queryInput =
      `      
      sort:  ${
        params.sort
          ? `{ by: "${params.sort.by}", direction: "${params.sort.direction}" }`
          : null
      }    
      ` +
      paginationQuery +
      dateQuery +
      idQuery +
      keywordQuery;

    const payload = {
      query: `{
        companyUnlockedResumes(${queryInput}) {
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              bookmarkedResumeId
              userId
              user {
                ${userAttributes}
              }
              companyId
              company {
                id
              }
              companyTeamMemberId
              companyTeamMember {
                id
              }
            }
          }
        }
      }    
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data.data?.companyUnlockedResumes) {
            const unlockedTalents = response.data.data.companyUnlockedResumes;
            return dispatch({
              type: types.FETCH_UNLOCKED_TALENTS_SUCCEED,
              payload: {
                unlockedTalents: unlockedTalents,
                refresh: params.refresh,
                filterParams: params
              }
            });
          }
        }
        return dispatch({
          type: types.FETCH_UNLOCKED_TALENTS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_UNLOCKED_TALENTS_FAILED
        });
      });
  };

const bookmarkTalent =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.BOOKMARK_TALENT,
      ids: params.userIds
    });

    const mutationInput = `userIds: ${JSON.stringify(params.userIds)}`;

    const payload = {
      query: `mutation{
        bookmarkResumes(input: {${mutationInput}}) {
          bookmarkedResumes {
            id
            userId
            user {
              ${userAttributes}
            }
            companyId
            company {
              id
            }
            companyTeamMemberId
            companyTeamMember {
              id
            }
          }
          success
        }
      }`
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.bookmarkResumes) {
            const { bookmarkResumes } = response.data.data;

            return dispatch({
              type: types.BOOKMARK_TALENT_SUCCEED,
              bookmarkedResumes: bookmarkResumes.bookmarkedResumes
            });
          }
        }
        return dispatch({
          type: types.BOOKMARK_TALENT_FAILED,
          ids: params.userIds
        });
      })
      .catch(error => {
        return dispatch({
          type: types.BOOKMARK_TALENT_FAILED,
          ids: params.userIds
        });
      });
  };

const unbookmarkTalent =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.UNBOOKMARK_TALENT,
      // id: params.resumeId
      id: params.userId
    });

    const mutationInput = `userId: ${JSON.stringify(
      // params.resumeId
      params.userId
    )}`;

    const payload = {
      query: `mutation{
        unBookmarkResume(input: {${mutationInput}}) {
          unBookmarkResume {
            id
            userId
            user {
              ${userAttributes}
            }
            companyId
            company {
              id
            }
            companyTeamMemberId
            companyTeamMember {
              id
            }
          }
          success
        }
      }`
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.unBookmarkResume) {
            const { unBookmarkResume } = response.data.data;

            return dispatch({
              type: types.UNBOOKMARK_TALENT_SUCCEED,
              user: unBookmarkResume.unBookmarkResume?.user
            });
          }
        }
        return dispatch({
          type: types.UNBOOKMARK_TALENT_FAILED,
          id: params.userId
        });
      })
      .catch(error => {
        return dispatch({
          type: types.UNBOOKMARK_TALENT_FAILED,
          id: params.userId
        });
      });
  };

const updateDoneFetchingBookmarkedTalentStatus = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_FETCH_BOOKMARKED_TALENTS_STATUS,
        status: status
      })
    );
  });
};

const updateDoneFetchingUnlockedTalentStatus = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_FETCH_UNLOCKED_TALENTS_STATUS,
        status: status
      })
    );
  });
};

const updateSearchResumeCurrentPage = page => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_SEARCH_RESUME_CURRENT_PAGE,
        page: page
      })
    );
  });
};

const updateBookmarkedCurrentPage = page => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_BOOKMARKED_CURRENT_PAGE,
        page: page
      })
    );
  });
};

const updateUnlockedCurrentPage = page => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_UNLOCKED_CURRENT_PAGE,
        page: page
      })
    );
  });
};

const unlockResume =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.UNLOCK_RESUME,
      id: params.userId
    });

    const mutationInput = `userId: ${JSON.stringify(
      params.userId
    )}, currentPrice: ${params.currentPrice}`;

    const payload = {
      query: `mutation{
        unlockResume(input: {${mutationInput}}) {          
          user {
            ${userAttributes}
          }
          success
          availableCoins
          priceDifferent
          currentPrice
        }
      }`
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.unlockResume) {
            const { unlockResume } = response.data.data;
            let cUser = unlockResume?.user || params.user;
            if (unlockResume?.priceDifferent) {
              cUser.resumePrice = unlockResume.currentPrice;
            }

            dispatch(updateCompanyAvailableCoins(unlockResume.availableCoins));

            return dispatch({
              type: types.UNLOCK_RESUME_SUCCEED,
              user: {
                ...cUser
              },
              isPriceChanged: unlockResume.priceDifferent
            });
          }
        }
        return dispatch({
          type: types.UNLOCK_RESUME_FAILED,
          id: params.userId,
          message: response.data.errors[0]?.message
            ? response.data.errors[0]?.message
            : ''
        });
      })
      .catch(error => {
        return dispatch({
          type: types.UNLOCK_RESUME_FAILED,
          id: params.userId
        });
      });
  };

const hideTalent =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.HIDE_PROFILE
    });

    const mutationInput = `userId: ${JSON.stringify(
      params.userId
    )}, hideProfile: ${params.hideProfile}`;

    const payload = {
      query: `mutation{
        hideProfile(input: {${mutationInput}}) {
          success
        }
      }`
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.hideProfile) {
            return dispatch({
              type: types.HIDE_PROFILE_SUCCEED,
              userId: params.userId
            });
          }
        }
        return dispatch({
          type: types.HIDE_PROFILE_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.HIDE_PROFILE_FAILED
        });
      });
  };

const updateSearchTalentFilters = filters => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_SEARCH_TALENT_FILTERS,
        filters: filters
      })
    );
  });
};

const updateBookmarkedTalentsFilters = filters => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_BOOKMARKED_TALENTS_FILTER,
        filters: filters
      })
    );
  });
};

const updateUnlockedTalentsFilters = filters => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_UNLOCKED_TALENTS_FILTER,
        filters: filters
      })
    );
  });
};

const updateUnlockedCount = count => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_UNLOCKED_COUNT,
        count: count
      })
    );
  });
};

const updateBookmarkedCount = count => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_BOOKMARKED_COUNT,
        count: count
      })
    );
  });
};

// CLEAR FILTERS
const clearBookmarkedTalentsFilters = () => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.CLEAR_BOOKMARKED_TALENTS_FILTER
      })
    );
  });
};

const clearBookmarkedTalentsParams = () => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.CLEAR_BOOKMARKED_TALENTS_PARAMS
      })
    );
  });
};

const clearUnlockedTalentsFilters = () => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.CLEAR_UNLOCKED_TALENTS_FILTER
      })
    );
  });
};

const clearUnlockedTalentsParams = () => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.CLEAR_UNLOCKED_TALENTS_PARAMS
      })
    );
  });
};

const clearTalentsParams = () => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.CLEAR_TALENTS_PARAMS
      })
    );
  });
};

const updateModelState = state => dispatch => {
  dispatch({ type: types.UPDATE_MODAL_STATE, state: state });
};

const updateModelName = name => dispatch => {
  dispatch({ type: types.UPDATE_MODAL_NAME, name: name });
};

const updateProfilePopUp = state => dispatch => {
  dispatch({ type: types.UPDATE_PROFILE_POP_UP, state: state });
};

const updateCurrentTalent = talent => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_CURRENT_TALENT,
        talent: talent
      })
    );
  });
};

const updateCollapsibleFilterStatus = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_COLLAPSIBLE_FILTER_STATUS,
        expandFilters: status
      })
    );
  });
};

const updateKeywordSearchedStatus = (status, keyword) => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_KEYWORD_SEARCHED_STATUS,
        payload: { status, keyword }
      })
    );
  });
};

const updateHideNoResultFilterStatus = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_HIDE_NO_RESULT_FILTER_STATUS,
        status: status
      })
    );
  });
};

const addNewNotes =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.RETRIEVE_NOTES,
      state: true
    });

    const payload = {
      query: `mutation {
        saveResumeNote(
          input: {
            companyResumeId: ${params.companyResumeId}
            notes: "${params.notes}"
          }
        ) {
          companyResumeNotes {
            id
            notes
            companyResumeId
            companyTeamMemberId
            companyTeamMember {
              id
              name
            }
            createdAt
            updatedAt
          }
          success
        }
      }`
    };
    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          const { companyResumeNotes } = response?.data?.data?.saveResumeNote;

          dispatch({
            type: types.UPDATE_NOTES,
            companyResumeNotes: companyResumeNotes
          });
        }
        return dispatch({
          type: types.RETRIEVE_NOTES,
          state: false
        });
      });
  };

const updateExistingNotes =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.RETRIEVE_NOTES,
      state: true
    });

    const payload = {
      query: `mutation {
        saveResumeNote(
          input: {
            id: ${params.id}
            companyResumeId: ${params.companyResumeId}
            notes: "${params.notes}"
          }
        ) {
          companyResumeNotes {
            id
            notes
            companyResumeId
            companyTeamMemberId
            companyTeamMember {
              id
              name
            }
            createdAt
            updatedAt
          }
          success
        }
      }`
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          const { companyResumeNotes } = response?.data?.data?.saveResumeNote;

          dispatch({
            type: types.UPDATE_NOTES,
            companyResumeNotes: companyResumeNotes
          });
        }

        return dispatch({
          type: types.RETRIEVE_NOTES,
          state: false
        });
      });
  };

const sortNoteByNewest =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.RETRIEVE_NOTES,
      state: true
    });

    const payload = {
      query: `query {
        companyResumeNotes(
            companyResumeId: ${params.companyResumeId}
            sort: {
              by: "created_at", direction: "desc"
            }
          ) {
          edges {
            node {
              id
              notes
              companyResumeId
              companyTeamMemberId
              companyTeamMember {
                id
                name
              }
              createdAt
              updatedAt
            }
          }
        }
      }`
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          const { edges } = response?.data?.data?.companyResumeNotes;

          dispatch({
            type: types.UPDATE_NOTES,
            companyResumeNotes: edges
          });
        }

        return dispatch({
          type: types.RETRIEVE_NOTES,
          state: false
        });
      });
  };

const sortNoteByOldest =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.RETRIEVE_NOTES,
      state: true
    });

    const payload = {
      query: `query {
        companyResumeNotes(
            companyResumeId: ${params.companyResumeId}
            sort: {
              by: "created_at", direction: "asc"
            }
          ) {
          edges {
            node {
              id
              notes
              companyResumeId
              companyTeamMemberId
              companyTeamMember {
                id
              }
              createdAt
              updatedAt
            }
          }
        }
      }`
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          const { edges } = response?.data?.data?.companyResumeNotes;

          dispatch({
            type: types.UPDATE_NOTES,
            companyResumeNotes: edges
          });
        }
        return dispatch({
          type: types.RETRIEVE_NOTES,
          state: false
        });
      });
  };

const filterNotesByComments =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.RETRIEVE_NOTES,
      state: true
    });

    const payload = {
      query: `query {
        companyResumeNotes(
            companyResumeId: ${params.companyResumeId}
            commentById: "${params.commentById}"
            sort: {
              by: "created_at", direction: "asc"
            }
          ) {
          edges {
            node {
              id
              notes
              companyResumeId
              companyTeamMemberId
              companyTeamMember {
                id
                name
              }
              createdAt
              updatedAt
            }
          }
        }
      }`
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          const { edges } = response?.data?.data?.companyResumeNotes;

          dispatch({
            type: types.UPDATE_NOTES,
            companyResumeNotes: edges
          });
        }
        return dispatch({
          type: types.RETRIEVE_NOTES,
          state: false
        });
      });
  };

const downloadResume =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.DOWNLOAD_RESUME
    });

    const queryInput = `userId: ${JSON.stringify(params.userId)}`;

    const payload = {
      query: `{
      downloadResume(${queryInput})
    }`
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.downloadResume) {
            const { downloadResume } = response.data.data;

            return dispatch({
              type: types.DOWNLOAD_RESUME_SUCCEED,
              resume: downloadResume
            });
          }
        }
        return dispatch({
          type: types.DOWNLOAD_RESUME_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.DOWNLOAD_RESUME_FAILED
        });
      });
  };

const checkIsChatRoomInitiated =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.CHECK_INITIATED_CHAT_ROOM
    });

    const mutationInput = `userId: ${JSON.stringify(params.userId)}`;

    const payload = {
      query: `mutation{
        checkChatRoomInitiated(input: {${mutationInput}}) {
          success
        }
      }`
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          const { success } = response?.data?.data?.checkChatRoomInitiated;
          if (success) {
            return dispatch({
              type: types.CHECK_INITIATED_CHAT_ROOM_SUCCEED,
              status: success
            });
          } else {
            const { errors } = response?.data;

            return dispatch({
              type: types.CHECK_INITIATED_CHAT_ROOM_FAILED,
              errors: errors
            });
          }
        }
        return dispatch({
          type: types.CHECK_INITIATED_CHAT_ROOM_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.CHECK_INITIATED_CHAT_ROOM_FAILED
        });
      });
  };

const createCompanyResumeChat =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.CREATE_COMPANY_RESUME_CHAT
    });

    const mutationInput = `userId: ${JSON.stringify(
      params.userId
    )}, channelUrl: ${JSON.stringify(params.channelUrl)}`;

    const payload = {
      query: `mutation{
        createCompanyResumeChat(input: {${mutationInput}}) {
          user {
           ${userAttributes}
          }
          companyResume {
            id
            sendbirdChannelUrl
            chatStatus
          }
          success
        }
      }`
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          const { user, success } =
            response?.data?.data?.createCompanyResumeChat;
          if (success) {
            return dispatch({
              type: types.CREATE_COMPANY_RESUME_CHAT_SUCCEED,
              user: user,
              status: success
            });
          } else {
            const { errors } = response?.data;

            return dispatch({
              type: types.CHECK_INITIATED_CHAT_ROOM_FAILED,
              errors: errors
            });
          }
        }
        return dispatch({
          type: types.CREATE_COMPANY_RESUME_CHAT_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.CREATE_COMPANY_RESUME_CHAT_FAILED
        });
      });
  };

const updateInsufficientCoinsBanner = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_INSUFFICIENT_COINS_BANNER,
        status: status
      })
    );
  });
};

const updateSpecificTalentInList = talent => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_SPECIFIC_TALENT_IN_LIST,
        talent: talent
      })
    );
  });
};

const updateTalentSearchFAQPopup = status => dispatch => {
  dispatch({ type: types.UPDATE_TALENT_SEARCH_FAQ_POPUP, status: status });
};

const updateChannelsUnreadMessageCount = content => dispatch => {
  dispatch({
    type: types.UPDATE_TALENT_CHANNELS_UNREAD_MESSAGE_COUNT,
    content: content
  });
};

const reportingInformationErrors =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.REPORT_INFO_ERRORS
    });

    const mutationInput = `userId: ${JSON.stringify(
      params.userId
    )}, section: ${JSON.stringify(params.section)}`;

    const payload = {
      query: `mutation {
        reportingInformationErrors(input: {
        ${mutationInput}
        }) {
          success
          user {
            ${userAttributes}
          }
        }
      }`
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          const { success, user } =
            response?.data?.data?.reportingInformationErrors;
          if (success) {
            return dispatch({
              type: types.REPORT_INFO_ERRORS_SUCCEED,
              status: success,
              user: user
            });
          } else {
            const { errors } = response?.data;

            return dispatch({
              type: types.REPORT_INFO_ERRORS_FAILED,
              errors: errors
            });
          }
        }
        return dispatch({
          type: types.REPORT_INFO_ERRORS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.REPORT_INFO_ERRORS_FAILED
        });
      });
  };

const updateErrorReportingStatus = status => dispatch => {
  dispatch({
    type: types.UPDATE_ERROR_REPORTING_STATUS,
    status: status
  });
};

const updateTalentInfoTabValue = tab => dispatch => {
  dispatch({
    type: types.UPDATE_TALENT_INFO_TAB_VALUE,
    tabValue: tab
  });
};

const fetchSearchTalentsOld =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_TALENTS_OLD
    });

    const payload = {
      query: `{
        searchTalents(
          first: ${params.first}
          after: "${params.after}"
          advancedSearch: ${params.advancedSearch}
          filterMode: "${params.filterMode}"
          keyword: "${params.keyword}"
          wordMatch: "${params.wordMatch}"
          trackIds: ${params.trackIds}
          genders: ${params.genders}
          nationalities: ${params.nationalities}
          educationLevels: ${params.educationLevels}
          withVideoCoverLetter: ${params.withVideoCoverLetter}
          graduationYear: ${params.graduationYear}
          sort: ${params.sort}
        ) {
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              name
              viewed
              shortSummary
              nationality
              gender
              email
              currentCompany
              fieldOfStudy
              major
              highestEducationLevel
              educationalInstitution
              graduationYear
              age
              profileImageSquare
              trackIds
              videoCvOptimized
              resume
              uploadResumeAt
              lastActiveAt
            }
            cursor
          }
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.searchTalents) {
            const searchTalents = response?.data?.data?.searchTalents.edge;
            const pageInfo = response?.data?.data?.searchTalents?.pageInfo;
            const totalCount = response?.data?.data?.searchTalents?.totalCount;
            return dispatch({
              type: types.FETCH_TALENTS_OLD_SUCCEED,
              searchTalentsOld: searchTalents,
              pageInfo: pageInfo,
              totalCount: totalCount
            });
          }
        }
        return dispatch({
          type: types.FETCH_TALENTS_OLD_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_TALENTS_OLD_FAILED
        });
      });
  };

const mutateViewTalent =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_VIEW_TALENT
    });

    const data = params.input;

    const input = `
      ${data.userId ? `userId: "${data.userId}",` : ''}
  `;

    const payload = {
      query: `mutation {
        viewTalent(input: {${input}}) {
          user {
            id
            name
            viewed
            shortSummary
            nationality
            gender
            email
            currentCompany
            fieldOfStudy
            major
            highestEducationLevel
            educationalInstitution
            graduationYear
            age
            profileImageSquare
            trackIds
            videoCvOptimized
            resume
            uploadResumeAt
            lastActiveAt
            videoCv
            mobileNumber
          }
          success
        }
     }
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.viewTalent) {
            const viewTalent = response?.data?.data?.viewTalent;
            return dispatch({
              type: types.MUTATE_VIEW_TALENT_SUCCEED,
              viewTalent: viewTalent
            });
          }
        }
        return dispatch({
          type: types.MUTATE_VIEW_TALENT_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_VIEW_TALENT_FAILED,
          error: error
        });
      });
  };


  const updateFeedbackCardStatus = status => async dispatch => {
    return new Promise(resolve => {
      return resolve(
        dispatch({
          type: types.UPDATE_FEEDBACK_CARD_STATUS,
          status: status
        })
      );
    });
  };

  const updateNewSearch = status => async dispatch => {
    return new Promise(resolve => {
      return resolve(
        dispatch({
          type: types.UPDATE_NEW_SEARCH,
          status: status
        })
      );
    });
  };

  const updateTalentsList = list => async dispatch => {
    return new Promise(resolve => {
      return resolve(
        dispatch({
          type: types.UPDATE_TALENT_LIST,
          list: list
        })
      );
    });
  };

  const updateFeedbackForm = (formData) => async dispatch => {
    return new Promise(resolve => {
      return resolve(
        dispatch({
          type: types.UPDATE_FEEDBACK_FORM,
          payload: formData,
        })
      );
    });
  };


  const updateFeedbackPage = status => async dispatch => {
    return new Promise(resolve => {
      return resolve(
        dispatch({
          type: types.UPDATE_FEEDBACK_PAGE,
          status: status
        })
      );
    });
  };


export {
  updateFeedbackForm,
  updateFeedbackPage,
  updateNewSearch,
  updateTalentsList,
  updateTalentsColumnRef,
  updateUnlockProfileStatus,
  updateHideProfileStatus,
  getTalents,
  getBookmarkedTalents,
  getUnlockedTalents,
  bookmarkTalent,
  unbookmarkTalent,
  unlockResume,
  hideTalent,
  updateSpecificTalentInList,
  updateSearchTalentFilters,
  updateBookmarkedTalentsFilters,
  updateUnlockedTalentsFilters,
  updateUnlockedCount,
  updateBookmarkedCount,
  clearUnlockedTalentsParams,
  clearBookmarkedTalentsParams,
  clearBookmarkedTalentsFilters,
  clearUnlockedTalentsFilters,
  updateDoneFetchingBookmarkedTalentStatus,
  updateDoneFetchingUnlockedTalentStatus,
  updateSearchResumeCurrentPage,
  updateBookmarkedCurrentPage,
  updateUnlockedCurrentPage,
  updateHideNoResultFilterStatus,
  updateModelState,
  updateModelName,
  updateCurrentTalent,
  updateProfilePopUp,
  updateCollapsibleFilterStatus,
  clearTalentsParams,
  updateKeywordSearchedStatus,
  addNewNotes,
  updateExistingNotes,
  sortNoteByNewest,
  sortNoteByOldest,
  filterNotesByComments,
  downloadResume,
  checkIsChatRoomInitiated,
  createCompanyResumeChat,
  updateInsufficientCoinsBanner,
  updateTalentSearchFAQPopup,
  updateChannelsUnreadMessageCount,
  reportingInformationErrors,
  updateErrorReportingStatus,
  updateTalentInfoTabValue,
  fetchSearchTalentsOld,
  mutateViewTalent,
  updateFeedbackCardStatus
};
