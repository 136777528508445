import { styled as styling } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';

export const TitleStyled = styling(Typography)(props => ({
  fontWeight: '500px',
  width: 'auto'
}));

export const TabContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px'
}));

export const DefaultContainer = styling(Grid)(props => ({}));

export const SingleCardContainer = styling(Grid)(props => ({
  padding: '16px 22px 32px'
}));

export const DisclaimerTypography = styling(Typography)(props => ({
  color: '#00000099',
  fontSize: '12px'
}));

export const ProductCardContainer = styling(Grid)(props => ({
  display: props?.singleCard ? 'flex' : 'grid',
  flex: props?.singleCard && 1,
  height: "100%",
  gridTemplateColumns: props?.fetchingAllActiveProducts
    ? 'repeat(3, 1fr)'
    : Array.isArray(props.products) &&
      !props?.confirm &&
      props.products.length === 4
    ? 'repeat(4,1fr)'
    : 'repeat(3, 1fr)',

  gap: '16px',
  gridAutoRows: '1fr'
}));

export const ProductItemContainer = styling(Grid)(props => ({
  padding: `${props.singleProduct ? '24px' : '16px'} 24px 24px 24px`,
  display: 'flex',
  flexDirection: 'column',
  gap: '16px'
}));

export const CardContainer = styling(Grid)(props => ({
  borderRadius: '8px',
  border: `1px solid #D8D8D8`,
  background: 'white',
  padding: props.$noTitleAndDesc ? '24px' : '0px',
  marginBottom: '24px',
  width: '100%'
}));

export const Divider = styling(Grid)({
  height: '1px',
  backgroundColor: '#D8D8D8',
  width: '100%'
});
