import moment from 'moment';
import { SEARCH_TYPE } from '../../utils/Constants';
import * as types from '../types/application_type';

const initialState = {
  applications: [],
  fetchingApplications: false,
  applicationReachedEnd: false,
  applicationHasNext: false,
  applicationCurrentPage: 1,
  // Undecided applications
  undecidedApplications: [],
  fetchingUndecidedApplications: false,
  fetchingUndecidedTimeout: false,
  undecidedReachedEnd: false,
  // Kiv applications
  kivApplications: [],
  fetchingKivApplications: false,
  fetchingKivTimeout: false,
  kivReachedEnd: false,
  // Shortlisted applications
  shortlistedApplications: [],
  fetchingShortlistedApplications: false,
  fetchingShortlistedTimeout: false,
  shortlistedReachedEnd: false,
  // Rejected applications
  rejectedApplications: [],
  fetchingRejectedApplications: false,
  fetchingRejectedTimeout: false,
  rejectedReachedEnd: false,
  // Interviews
  interviews: [],
  fetchingInterviews: false,
  fetchingInterviewTimeout: false,
  interviewReachedEnd: false,
  interviewEndCursor: '',
  isDraggingInterview: false,
  // Offers
  offers: [],
  fetchingOffers: false,
  fetchingOfferTimeout: false,
  offerReachedEnd: false,
  offerEndCursor: '',
  // For dragging
  draggingState: '',
  // Updating application state
  updatingApplicationStateIds: {},
  updatingApplicationState: false,
  // For application counts
  applicationCount: {},
  // For joined applications of all states
  allApplications: [],
  // For candidate snapshot
  isCandidateSnapshotOpen: false,
  currentCandidateIndex: 0,
  // For application select all
  selectAllStatus: {},
  // For view application
  viewingApplication: false,
  // Schedule interview
  schedulingInterview: false,
  // Adding application note - temporary not using this as using note associated to interview
  addingApplicationNote: false,
  //For first schedule interview modal
  isFirstInterviewModalOpened: false,
  // For next schedule interview modal
  setIsNextInterviewModalOpened: false,
  changeStateFailedCount: {
    undecided: 0,
    kiv: 0,
    shortlisted: 0,
    rejected: 0
  },
  changeStateFailedIds: {},
  // For close the loop
  closingLoop: false,
  // For update interview status
  updatingInterviewStatus: false,
  // For adding interview note
  addingInterviewNote: false,
  updatingInterviewNote: false,
  // For public applicant snapshot summary navigation
  viewAllApllicantsPath: '',
  rejectPopupStatus: false,
  interviewInfo: {},
  updatingInterviewDetails: false,
  deletingInterview: false,
  interviewDeleted: false,
  interviewData: {},
  showBulkRejectPopup: false,
  currentState: '',
  closeLoopPopupStatus: false,
  blacklistPopupStatus: false,

  //all-applicants-page
  applicationParams: {
    jobId: '',
    first: 10,
    // search: searchText,
    search: '',
    searchType: SEARCH_TYPE.ANY,
    // sort: getSortBy(),
    sort: { by: 'appliedAt', direction: 'desc' },
    showState: null,
    // applicationStatus === JOB_APPLICATION_STATUS.ALL
    //   ? null
    //   : applicationStatus,
    yearsOfExperience: 0,
    // advancedFilter && advancedFilter.yearsOfExp
    //   ? advancedFilter.yearsOfExp / 10
    //   : null,
    // withVideoCoverLetter: advancedFilter?.videoCoverLetter,
    withVideoCoverLetter: false,
    // highestEducationLevels: advancedFilter?.highestEduLevel,
    highestEducationLevels: [],
    // malaysians: advancedFilter?.malaysians ? 'on' : null,
    nationalities: "",
    currentLocation: [],
    relocate: false,
    remote: false,
    openToSponsor: false,
    visaOrEmploymentPass: false,
    localCitizen: false,
    // trackIds: advancedFilter?.trackIds,
    trackIds: [],
    specialisations: [],
    // offset: refresh ? 0 : store.getState().applications.applications.length,
    offset: 0,
    // hideViewed:
    //   applicationStatus != 'undecided' ? false : advancedFilter?.hideViewed,
    hideViewed: false,
    // chatbotStatus: advancedFilter?.chatbotStatus,
    chatbotStatus: '',
    refresh: true,
    appliedFromDate: moment().subtract(1, 'year').unix(),
    appliedToDate: moment().unix()
  },
  applicationUserIds: [],
  fetchingResumes: false,
  bulkResumes: []
};

const removeDuplicates = items => {
  var seen = {};
  return items.filter(item => {
    if (seen[item.id]) return false;
    seen[item.id] = true;

    return true;
  });
};

const postAddOrUpdateNote = (action, state) => {
  const applicationState = action.jobApplication.state;
  const index = state.allApplications.findIndex(item => {
    const cApplication = item.jobApplication ? item.jobApplication : item;
    return cApplication.id === action.jobApplication.id;
  });

  let allApplications = [];

  if (index != -1) {
    allApplications = [...state.allApplications];

    if (allApplications[index]) {
      allApplications[index] = allApplications[index].jobApplication
        ? action.interview
        : action.jobApplication;
    }
  }

  // Update application note value if application exist in specific list
  let currentApplications = [];

  if (applicationState === 'undecided') {
    currentApplications = [...state.undecidedApplications];
  } else if (applicationState === 'kiv') {
    currentApplications = [...state.kivApplications];
  } else if (applicationState === 'shortlisted') {
    currentApplications = [...state.shortlistedApplications];
  } else if (applicationState === 'rejected') {
    currentApplications = [...state.rejectedApplications];
  }

  const applicationIndex = currentApplications.findIndex(
    item => item.id === action.jobApplication.id
  );

  if (applicationIndex != -1) {
    currentApplications[applicationIndex] = { ...action.jobApplication };
  }

  // Same application might appear in interview sections
  let currentInterviews;

  const interviewIndex = state.interviews.findIndex(
    item => item.jobApplication.id === action.jobApplication.id
  );

  if (interviewIndex != -1) {
    currentInterviews = [...state.interviews];
    let currentInterview = { ...currentInterviews[interviewIndex] };
    currentInterview.jobApplication = { ...action.jobApplication };
    currentInterviews[interviewIndex] = currentInterview;
  }

  return {
    index,
    applicationState,
    interviewIndex,
    allApplications,
    currentInterviews,
    applicationIndex,
    currentApplications
  };
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // For application - listview
    case types.FETCH_APPLICATIONS: {
      return {
        ...state,
        fetchingApplications: true
      };
    }
    case types.FETCH_APPLICATIONS_SUCCEED: {
      let currentApplications = action.payload.applications.edges.map(
        item => item.node
      );
      let reachedEnd = action.payload?.hasNext ? false : true;

      // Not refresh
      if (!action.payload?.refresh) {
        currentApplications = [...state.applications, ...currentApplications];
      }

      currentApplications = removeDuplicates(currentApplications);

      return {
        ...state,
        fetchingApplications: false,
        applications: [...currentApplications],
        applicationReachedEnd: reachedEnd,
        applicationHasNext: action.payload.hasNext
      };
    }
    case types.FETCH_APPLICATIONS_FAILED: {
      return {
        ...state,
        fetchingApplications: false
      };
    }
    case types.UPDATE_APPLICATION_CURRENT_PAGE: {
      return {
        ...state,
        applicationCurrentPage: action.page
      };
    }
    // For undecided applications
    case types.FETCH_UNDECIDED_APPLICATIONS: {
      return {
        ...state,
        fetchingUndecidedApplications: true,
        fetchingUndecidedTimeout: false
      };
    }
    case types.FETCH_UNDECIDED_APPLICATIONS_SUCCEED: {
      let currentApplications = action.payload.applications.edges.map(
        item => item.node
      );
      let reachedEnd = action.payload?.hasNext ? false : true;

      // Not refresh
      if (!action.payload?.refresh) {
        currentApplications = [
          ...state.undecidedApplications,
          ...currentApplications
        ];
      }

      currentApplications = removeDuplicates(currentApplications);

      return {
        ...state,
        fetchingUndecidedApplications: false,
        undecidedApplications: [...currentApplications],
        undecidedReachedEnd: reachedEnd
      };
    }
    case types.FETCH_UNDECIDED_APPLICATIONS_FAILED: {
      const isTimeout = action.isCancelled ? true : false;
      return {
        ...state,
        fetchingUndecidedApplications: false,
        fetchingUndecidedTimeout: isTimeout
      };
    }
    // For Kiv applications
    case types.FETCH_KIV_APPLICATIONS: {
      return {
        ...state,
        fetchingKivApplications: true,
        fetchingKivTimeout: false
      };
    }
    case types.FETCH_KIV_APPLICATIONS_SUCCEED: {
      let currentApplications = action.payload.applications.edges.map(
        item => item.node
      );
      let reachedEnd = action.payload?.hasNext ? false : true;

      // Not refresh
      if (!action.payload?.refresh) {
        currentApplications = [
          ...state.kivApplications,
          ...currentApplications
        ];
      }

      currentApplications = removeDuplicates(currentApplications);

      return {
        ...state,
        fetchingKivApplications: false,
        kivApplications: [...currentApplications],
        kivReachedEnd: reachedEnd
      };
    }
    case types.FETCH_KIV_APPLICATIONS_FAILED: {
      const isTimeout = action.isCancelled ? true : false;
      return {
        ...state,
        fetchingKivApplications: false,
        fetchingKivTimeout: isTimeout
      };
    }
    // For shortlisted applications
    case types.FETCH_SHORTLISTED_APPLICATIONS: {
      return {
        ...state,
        fetchingShortlistedApplications: true,
        fetchingShortlistedTimeout: false
      };
    }
    case types.FETCH_SHORTLISTED_APPLICATIONS_SUCCEED: {
      let currentApplications = action.payload.applications.edges.map(
        item => item.node
      );
      let reachedEnd = action.payload?.hasNext ? false : true;

      // Not refresh
      if (!action.payload?.refresh) {
        currentApplications = [
          ...state.shortlistedApplications,
          ...currentApplications
        ];
      }

      currentApplications = removeDuplicates(currentApplications);

      return {
        ...state,
        fetchingShortlistedApplications: false,
        shortlistedApplications: [...currentApplications],
        shortlistedReachedEnd: reachedEnd
      };
    }
    case types.FETCH_SHORTLISTED_APPLICATIONS_FAILED: {
      const isTimeout = action.isCancelled ? true : false;
      return {
        ...state,
        fetchingShortlistedApplications: false,
        fetchingShortlistedTimeout: isTimeout
      };
    }
    // For rejected applications
    case types.FETCH_REJECTED_APPLICATIONS: {
      return {
        ...state,
        fetchingRejectedApplications: true,
        fetchingRejectedTimeout: false
      };
    }
    case types.FETCH_REJECTED_APPLICATIONS_SUCCEED: {
      let currentApplications = action.payload.applications.edges.map(
        item => item.node
      );
      let reachedEnd = action.payload?.hasNext ? false : true;

      // Not refresh
      if (!action.payload?.refresh) {
        currentApplications = [
          ...state.rejectedApplications,
          ...currentApplications
        ];
      }

      currentApplications = removeDuplicates(currentApplications);

      return {
        ...state,
        fetchingRejectedApplications: false,
        rejectedApplications: [...currentApplications],
        rejectedReachedEnd: reachedEnd
      };
    }
    case types.FETCH_REJECTED_APPLICATIONS_FAILED: {
      const isTimeout = action.isCancelled ? true : false;
      return {
        ...state,
        fetchingRejectedApplications: false,
        fetchingRejectedTimeout: isTimeout
      };
    }
    // For interviews
    case types.FETCH_INTERVIEWS: {
      return {
        ...state,
        fetchingInterviews: true,
        fetchingInterviewTimeout: false
      };
    }
    case types.FETCH_INTERVIEWS_SUCCEED: {
      let currentInterviews = action.payload.interviews.edges.map(
        item => item.node
      );

      let reachedEnd = action.payload?.hasNext ? false : true;
      // const currentCount = action.payload.count
      //   ? action.payload.count
      //   : state.applicationCount['interview'];

      // const currentApplicationCount = { ...state.applicationCount };

      // currentApplicationCount['interview'] = currentCount;

      // Not refresh
      if (!action.payload?.refresh) {
        currentInterviews = [...state.interviews, ...currentInterviews];
      }

      return {
        ...state,
        fetchingInterviews: false,
        interviews: [...currentInterviews],
        interviewReachedEnd: reachedEnd,
        interviewEndCursor: action.payload.endCursor
        //applicationCount: currentApplicationCount
      };
    }
    case types.FETCH_INTERVIEWS_FAILED: {
      const isTimeout = action.isCancelled ? true : false;
      return {
        ...state,
        fetchingInterviews: false,
        fetchingInterviewTimeout: isTimeout
      };
    }
    // For offers
    case types.FETCH_OFFERS: {
      return {
        ...state,
        fetchingOffers: true,
        fetchingOfferTimeout: false
      };
    }
    case types.FETCH_OFFERS_SUCCEED: {
      let currentOffers = action.payload.offers.edges.map(item => item.node);

      let reachedEnd = action.payload?.hasNext ? false : true;
      // const currentCount = action.payload.count
      //   ? action.payload.count
      //   : state.applicationCount['offer'];

      // const currentApplicationCount = { ...state.applicationCount };

      // currentApplicationCount['offer'] = currentCount;

      // Not refresh
      if (!action.payload?.refresh) {
        currentOffers = [...state.offers, ...currentOffers];
      }

      return {
        ...state,
        fetchingOffers: false,
        offers: [...currentOffers],
        offerReachedEnd: reachedEnd,
        offerEndCursor: action.payload.endCursor
        //applicationCount: currentApplicationCount
      };
    }
    case types.FETCH_OFFERS_FAILED: {
      const isTimeout = action.isCancelled ? true : false;
      return {
        ...state,
        fetchingOffers: false,
        fetchingOfferTimeout: isTimeout
      };
    }
    // Update undecided applications
    case types.UPDATE_UNDECIDED_APPLICATIONS: {
      return {
        ...state,
        undecidedApplications: [...action.applications],
        ...(action.applications.length === 0
          ? { undecidedReachedEnd: false }
          : null)
      };
    }
    // Update kiv applications
    case types.UPDATE_KIV_APPLICATIONS: {
      return {
        ...state,
        kivApplications: [...action.applications],
        ...(action.applications.length === 0 ? { kivReachedEnd: false } : null)
      };
    }
    // Update shortlisted applications
    case types.UPDATE_SHORTLISTED_APPLICATIONS: {
      return {
        ...state,
        shortlistedApplications: [...action.applications],
        ...(action.applications.length === 0
          ? { shortlistedReachedEnd: false }
          : null)
      };
    }
    // Update rejected applications
    case types.UPDATE_REJECTED_APPLICATIONS: {
      return {
        ...state,
        rejectedApplications: [...action.applications],
        ...(action.applications.length === 0
          ? { rejectedReachedEnd: false }
          : null)
      };
    }
    // Update interviews
    case types.UPDATE_INTERVIEWS: {
      return {
        ...state,
        interviews: [...action.interviews],
        ...(action.interviews.length === 0
          ? { interviewReachedEnd: false }
          : null)
      };
    }
    // Update offers
    case types.UPDATE_OFFERS: {
      return {
        ...state,
        offers: [...action.offers],
        ...(action.offers.length === 0 ? { offerReachedEnd: false } : null)
      };
    }
    // Update application state
    case types.UPDATE_APPLICATION_STATE: {
      let currentIds = { ...state.updatingApplicationStateIds };

      // Current application is being updated
      currentIds[action.applicationId] = true;

      return {
        ...state,
        updatingApplicationState: true,
        updatingApplicationStateIds: currentIds
      };
    }
    case types.UPDATE_APPLICATION_STATE_SUCCEED: {
      let currentIds = { ...state.updatingApplicationStateIds };

      // This application is updated
      if (currentIds[action.applicationId]) {
        delete currentIds[action.applicationId];
      }

      return {
        ...state,
        updatingApplicationState: false,
        updatingApplicationStateIds: currentIds
      };
    }
    case types.UPDATE_APPLICATION_STATE_FAILED: {
      let currentIds = { ...state.updatingApplicationStateIds };

      // This application failed to be updated
      if (currentIds[action.applicationId]) {
        delete currentIds[action.applicationId];
      }

      return {
        ...state,
        updatingApplicationState: false,
        updatingApplicationStateIds: currentIds
      };
    }
    // Update Application state in bulk
    case types.UPDATE_MULTIPLE_APPLICATION_STATE: {
      let currentIds = { ...state.updatingApplicationStateIds };

      // These applications are being updated
      action.applicationIds.map(applicationId => {
        currentIds[applicationId] = true;
      });

      return {
        ...state,
        updatingApplicationState: true,
        updatingApplicationStateIds: currentIds
      };
    }
    case types.UPDATE_MULTIPLE_APPLICATION_STATE_SUCCEED: {
      let currentIds = { ...state.updatingApplicationStateIds };

      // These application is updated
      action.applicationIds.map(applicationId => {
        if (currentIds[applicationId]) {
          delete currentIds[applicationId];
        }
      });

      return {
        ...state,
        updatingApplicationState: false,
        updatingApplicationStateIds: currentIds
      };
    }
    case types.UPDATE_MULTIPLE_APPLICATION_STATE_FAILED: {
      let currentIds = { ...state.updatingApplicationStateIds };

      // These application failed to be updated
      action.applicationIds.map(applicationId => {
        if (currentIds[applicationId]) {
          delete currentIds[applicationId];
        }
      });

      return {
        ...state,
        updatingApplicationState: false,
        updatingApplicationStateIds: currentIds
      };
    }

    // Update application counts
    case types.UPDATE_APPLICATION_COUNTS: {
      return {
        ...state,
        applicationCount: { ...action.applicationCount }
      };
    }
    // Update all applications
    case types.UPDATE_ALL_APPLICATIONS: {
      return {
        ...state,
        allApplications: [...action.allApplications]
      };
    }
    // Update candidate snapshot status
    case types.UPDATE_CANDIDATE_SNAPSHOT_STATUS: {
      return {
        ...state,
        isCandidateSnapshotOpen: action.status
      };
    }
    // Update current candidate index
    case types.UPDATE_CURRENT_CANDIDATE_INDEX: {
      return {
        ...state,
        currentCandidateIndex: action.index
      };
    }
    // Update application select all status
    case types.UPDATE_APPLICATION_SELECT_ALL_STATUS: {
      let currentSelectAllStatus = { ...state.selectAllStatus };

      currentSelectAllStatus[action.state] = action.status;

      return {
        ...state,
        selectAllStatus: currentSelectAllStatus
      };
    }
    // View application
    case types.VIEW_APPLICATION: {
      return {
        ...state,
        viewingApplication: true
      };
    }
    case types.VIEW_APPLICATION_SUCCEED: {
      const currentState = action.state;
      let currentApplications;

      if (currentState === 'undecided') {
        currentApplications = [...state.undecidedApplications];
      } else if (currentState === 'kiv') {
        currentApplications = [...state.kivApplications];
      } else if (currentState === 'shortlisted') {
        currentApplications = [...state.shortlistedApplications];
      } else if (currentState === 'rejected') {
        currentApplications = [...state.rejectedApplications];
      }

      const index = currentApplications.findIndex(
        application => application.id === action.applicationId
      );

      if (index != -1) {
        // Update view status to true
        currentApplications[index].status = true;
      }

      return {
        ...state,
        viewingApplication: false,
        ...(currentState === 'undecided'
          ? { undecidedApplications: currentApplications }
          : null),
        ...(currentState === 'kiv'
          ? { kivApplications: currentApplications }
          : null),
        ...(currentState === 'shortlisted'
          ? { shortlistedApplications: currentApplications }
          : null),
        ...(currentState === 'rejected'
          ? { rejectedApplications: currentApplications }
          : null)
      };
    }
    case types.VIEW_APPLICATION_FAILED: {
      return {
        ...state,
        viewingApplication: false
      };
    }
    // For scheduling interview
    case types.SCHEDULE_INTERVIEW: {
      return {
        ...state,
        schedulingInterview: true
      };
    }
    case types.SCHEDULE_INTERVIEW_SUCCEED: {
      let numOfInterviewToAdd = 1;
      const currentState = action.state;
      const interview = action.interview;

      const index = state.allApplications.findIndex(item => {
        const cApplication = item.jobApplication ? item.jobApplication : item;
        return cApplication.id === action.applicationId;
      });
      let allApplications = [];

      if (index != -1) {
        allApplications = [...state.allApplications];
        let currentApplication = { ...allApplications[index] };

        currentApplication = { ...interview };
        // if (currentApplication.jobApplication) {
        //   currentApplication = { ...interview };
        // } else {
        //   currentApplication.interviews.splice(0, 0, interview);
        // }
        allApplications[index] = currentApplication;
      }

      // For ATS
      let currentApplications = [];
      if (currentState === 'undecided') {
        currentApplications = [...state.undecidedApplications];
      } else if (currentState === 'kiv') {
        currentApplications = [...state.kivApplications];
      } else if (currentState === 'shortlisted') {
        currentApplications = [...state.shortlistedApplications];
      } else if (currentState === 'rejected') {
        currentApplications = [...state.rejectedApplications];
      } else if (currentState === 'interview') {
        currentApplications = [...state.interviews];
      }

      let applicationIndex = -1;
      if (currentState !== 'interview') {
        // None interview
        applicationIndex = currentApplications.findIndex(
          item => item.id === action.applicationId
        );

        if (applicationIndex != -1) {
          currentApplications.splice(applicationIndex, 1);
        }
      }

      let interviews = [...state.interviews];
      const currentIndex = interviews.findIndex(
        item => item.jobApplication?.id === action.applicationId
      );

      if (currentIndex != -1) {
        numOfInterviewToAdd -= 1;
        // Remove previous interview
        interviews.splice(currentIndex, 1);
      }
      interviews.splice(0, 0, interview);

      let currentApplicationCount = { ...state.applicationCount };
      currentApplicationCount['interview'] += numOfInterviewToAdd;
      currentApplicationCount[currentState] -= 1;

      return {
        ...state,
        interviews: interviews,
        schedulingInterview: false,
        applicationCount: currentApplicationCount,
        ...(index != -1 ? { allApplications: allApplications } : null),
        ...(currentState === 'undecided' && applicationIndex != -1
          ? { undecidedApplications: currentApplications }
          : null),
        ...(currentState === 'kiv' && applicationIndex != -1
          ? { kivApplications: currentApplications }
          : null),
        ...(currentState === 'shortlisted' && applicationIndex != -1
          ? { shortlistedApplications: currentApplications }
          : null),
        ...(currentState === 'rejected' && applicationIndex != -1
          ? { rejectedApplications: currentApplications }
          : null)
      };
    }
    case types.SCHEDULE_INTERVIEW_FAILED: {
      return {
        ...state,
        schedulingInterview: false
      };
    }
    // Add application note
    case types.ADD_APPLICATION_NOTE: {
      return {
        ...state,
        addingApplicationNote: true
      };
    }
    case types.ADD_APPLICATION_NOTE_SUCCEED: {
      // Temporary not using this as using note associated to interview
      const {
        index,
        applicationState,
        allApplications,
        interviewIndex,
        currentInterviews,
        applicationIndex,
        currentApplications
      } = postAddOrUpdateNote(action, state);

      return {
        ...state,
        addingApplicationNote: false,
        ...(index != -1 ? { allApplications: allApplications } : null),
        ...(interviewIndex != -1 ? { interviews: currentInterviews } : null),
        ...(applicationIndex != -1 && applicationState === 'undecided'
          ? { undecidedApplications: currentApplications }
          : null),
        ...(applicationIndex != -1 && applicationState === 'kiv'
          ? { kivApplications: currentApplications }
          : null),
        ...(applicationIndex != -1 && applicationState === 'shortlisted'
          ? { shortlistedApplications: currentApplications }
          : null),
        ...(applicationIndex != -1 && applicationState === 'rejected'
          ? { rejectedApplications: currentApplications }
          : null)
      };
    }
    case types.ADD_APPLICATION_NOTE_FAILED: {
      return {
        ...state,
        addingApplicationNote: false
      };
    }
    //For schedule interview modal
    //Update first schedule interview modal status
    case types.UPDATE_FIRST_SCHEDULE_INTERVIEW_MODAL_STATUS: {
      return {
        ...state,
        isFirstInterviewModalOpened: action.status
      };
    }
    //Update next schedule interview modal status
    case types.UPDATE_NEXT_SCHEDULE_INTERVIEW_MODAL_STATUS: {
      return {
        ...state,
        isNextInterviewModalOpened: action.status
      };
    }
    // Update change state failed ids
    case types.UPDATE_CHANGE_STATE_FAILED_IDS: {
      return {
        ...state,
        changeStateFailedIds: action.ids
      };
    }
    // Closing loop
    case types.CLOSE_LOOP: {
      return {
        ...state,
        closingLoop: true
      };
    }
    case types.CLOSE_LOOP_SUCCEED: {
      const currentState = action.currentState;

      // For both
      let currentAllApplications = [];
      let currentAllApplicationIndex = -1;
      let currentApplicationCount = { ...state.applicationCount };

      // For interview or offer
      let currentInterviews = [];
      let currentOffers = [];
      let currentInterviewIndex = -1;
      let currentOfferIndex = -1;

      // For none interview and offer
      let currentApplications = [];
      let currentApplicationIndex = -1;

      // For ATS - Column data
      if (currentState != 'offer') {
        if (currentState == 'interview') {
          // Find and remove current interview
          currentInterviewIndex = state.interviews.findIndex(
            item => item.jobApplication.id === action.jobApplication.id
          );

          if (currentInterviewIndex != -1) {
            // Remove interview from ATS
            currentInterviews = [...state.interviews];
            currentInterviews.splice(currentInterviewIndex, 1);

            currentApplicationCount['interview'] -= 1;
          }
        } else {
          if (currentState === 'kiv') {
            currentApplicationIndex = state.kivApplications.findIndex(
              item => item.id === action.jobApplication.id
            );
          } else if (currentState === 'shortlisted') {
            currentApplicationIndex = state.shortlistedApplications.findIndex(
              item => item.id === action.jobApplication.id
            );
          }

          // Removing job application based on current state
          if (currentApplicationIndex != -1) {
            if (currentState === 'kiv') {
              currentApplications = [...state.kivApplications];
            } else if (currentState === 'shortlisted') {
              currentApplications = [...state.shortlistedApplications];
            }

            currentApplications.splice(currentApplicationIndex, 1);

            currentApplicationCount[currentState] -= 1;
          }
        }

        // Add newly created offer to offer column
        currentOfferIndex = 0;
        currentOffers = [...state.offers];
        currentOffers.splice(0, 0, action.interview);

        currentApplicationCount['offer'] += 1;
      } else {
        // Offer
        currentOfferIndex = state.offers.findIndex(
          item => item.jobApplication.id === action.jobApplication.id
        );

        if (currentOfferIndex != -1) {
          // Replace jobApplication of offer on ATS
          currentOffers = [...state.offers];
          const currentOffer = {
            ...currentOffers[currentOfferIndex]
          };
          currentOffer.jobApplication = { ...action.jobApplication };
          currentOffers[currentOfferIndex] = currentOffer;
        }
      }

      // For all application - candidate snapshot
      if (currentState === 'interview' || currentState === 'offer') {
        // Either interview or offer
        currentAllApplicationIndex = state.allApplications.findIndex(
          item => item.jobApplication.id === action.jobApplication.id
        );

        if (currentAllApplicationIndex != -1) {
          // Replace jobApplication of interview or offer on candidate snapshot
          currentAllApplications = [...state.allApplications];
          const currentInterview = {
            ...currentAllApplications[currentAllApplicationIndex]
          };
          currentInterview.jobApplication = { ...action.jobApplication };
          currentAllApplications[currentAllApplicationIndex] = currentInterview;
        }
      } else {
        // kiv/shortlisted
        currentAllApplicationIndex = state.allApplications.findIndex(
          item => item.id === action.jobApplication.id
        );

        if (currentAllApplicationIndex != -1) {
          // Replace job application with interview on candidate snapshot
          currentAllApplications = [...state.allApplications];
          currentAllApplications[currentAllApplicationIndex] = {
            ...action.interview
          };
        }
      }

      return {
        ...state,
        closingLoop: false,
        applicationCount: currentApplicationCount,
        ...(currentAllApplicationIndex != -1
          ? { allApplications: currentAllApplications }
          : null),
        ...(currentApplicationIndex != -1 && currentState === 'kiv'
          ? { kivApplications: currentApplications }
          : null),
        ...(currentApplicationIndex != -1 && currentState === 'shortlisted'
          ? { shortlistedApplications: currentApplications }
          : null),
        ...(currentInterviewIndex != -1
          ? { interviews: currentInterviews }
          : null),
        ...(currentOfferIndex != -1 ? { offers: currentOffers } : null)
      };
    }
    case types.CLOSE_LOOP_FAILED: {
      return {
        ...state,
        closingLoop: false
      };
    }
    // Update interview status
    case types.UPDATE_INTERVIEW_STATUS: {
      return {
        ...state,
        updatingInterviewStatus: true
      };
    }
    case types.UPDATE_INTERVIEW_STATUS_SUCCEED: {
      let currentAllApplications = [];
      let currentInterviews = [];
      let currentApplications = [];
      let currentOffers = [...state.offers];
      let currentRejectedApplications = [...state.rejectedApplications];
      let currentApplicationCount = { ...state.applicationCount };
      let interviewIndex = -1;
      let currentApplicationIndex = -1;
      let currentIndex = -1; // For snapshot applications

      // Making offer from interview
      if (action.isInterview) {
        // Remove interview from ATS
        interviewIndex = state.interviews.findIndex(
          item => item.id === action.previousApplicationId
        );

        if (interviewIndex != -1) {
          currentInterviews = [...state.interviews];
          currentInterviews.splice(interviewIndex, 1);
        }

        // Minus 1 from interview
        currentApplicationCount['interview'] -= 1;

        // Replacing snapshot interview with new interview info
        currentIndex = state.allApplications.findIndex(
          item => item.id === action.interview?.id
        );

        if (currentIndex != -1) {
          currentAllApplications = [...state.allApplications];
          currentAllApplications[currentIndex] = { ...action.interview };
        }
      } else {
        if (action.currentState === 'kiv') {
          // KIV
          currentApplications = [...state.kivApplications];
        } else {
          // Shortlisted
          currentApplications = [...state.shortlistedApplications];
        }

        // Minus 1 from kiv/shortlisted
        currentApplicationCount[action.currentState] -= 1;

        currentApplicationIndex = currentApplications.findIndex(
          item => item.id == action.previousApplicationId
        );

        if (currentApplicationIndex != -1) {
          // Remove application from previous application list
          currentApplications.splice(currentApplicationIndex, 1);
        }

        // Replacing snapshot application with new interview info
        currentIndex = state.allApplications.findIndex(
          item => item.id === action.previousApplicationId
        );

        if (currentIndex != -1) {
          currentAllApplications = [...state.allApplications];
          currentAllApplications[currentIndex] = { ...action.interview };
        }
      }

      // Not making offer
      if (action.status) {
        if (action.status == 'rejected') {
          // Update interview's job application state to rejected locally as backend will update to rejected via callback
          const rejectedApplication = action.interview?.jobApplication
            ? { ...action.interview.jobApplication, state: 'rejected' }
            : {};

          // Add to ATS rejected listing
          currentRejectedApplications.splice(0, 0, rejectedApplication);

          // add 1 to rejected
          currentApplicationCount['rejected'] += 1;
        } else {
          // Add to ATS offer listing
          currentOffers.splice(0, 0, action.interview);

          // add 1 to offer
          currentApplicationCount['offer'] += 1;
        }
      }

      return {
        ...state,
        applicationCount: currentApplicationCount,
        ...(action.status == 'rejected'
          ? { rejectedApplications: currentRejectedApplications }
          : null),
        ...(action.status != 'rejected' ? { offers: currentOffers } : null),
        ...(interviewIndex != -1 ? { interviews: currentInterviews } : null),
        ...(currentApplicationIndex != -1 && action.currentState == 'kiv'
          ? { kivApplications: currentApplications }
          : null),
        ...(currentApplicationIndex != -1 &&
        action.currentState == 'shortlisted'
          ? { shortlistedApplications: currentApplications }
          : null),
        ...(currentIndex != -1
          ? { allApplications: currentAllApplications }
          : null),
        updatingInterviewStatus: false
      };
    }
    case types.UPDATE_INTERVIEW_STATUS_FAILED: {
      return {
        ...state,
        updatingInterviewStatus: false
      };
    }
    // For dragging state
    case types.UPDATE_DRAGGING_STATE: {
      return {
        ...state,
        draggingState: action.state
      };
    }
    // For is dragging interview
    case types.UPDATE_IS_DRAGGING_INTERVIEW_STATUS: {
      return {
        ...state,
        isDraggingInterview: action.status
      };
    }
    // For adding interview note
    case types.ADD_INTERVIEW_NOTE: {
      return {
        ...state,
        addingInterviewNote: true
      };
    }
    case types.ADD_INTERVIEW_NOTE_SUCCEED: {
      const {
        index,
        applicationState,
        allApplications,
        interviewIndex,
        currentInterviews,
        applicationIndex,
        currentApplications
      } = postAddOrUpdateNote(action, state);

      return {
        ...state,
        addingInterviewNote: false,
        ...(index != -1 ? { allApplications: allApplications } : null),
        ...(interviewIndex != -1 ? { interviews: currentInterviews } : null),
        ...(applicationIndex != -1 && applicationState === 'undecided'
          ? { undecidedApplications: currentApplications }
          : null),
        ...(applicationIndex != -1 && applicationState === 'kiv'
          ? { kivApplications: currentApplications }
          : null),
        ...(applicationIndex != -1 && applicationState === 'shortlisted'
          ? { shortlistedApplications: currentApplications }
          : null),
        ...(applicationIndex != -1 && applicationState === 'rejected'
          ? { rejectedApplications: currentApplications }
          : null)
      };
    }
    case types.ADD_INTERVIEW_NOTE_FAILED: {
      return {
        ...state,
        addingInterviewNote: false
      };
    }
    // For updating interview note
    case types.UPDATE_INTERVIEW_NOTE: {
      return {
        ...state,
        updatingInterviewNote: true
      };
    }
    case types.UPDATE_INTERVIEW_NOTE_SUCCEED: {
      const {
        index,
        applicationState,
        allApplications,
        interviewIndex,
        currentInterviews,
        applicationIndex,
        currentApplications
      } = postAddOrUpdateNote(action, state);

      return {
        ...state,
        updatingInterviewNote: false,
        ...(index != -1 ? { allApplications: allApplications } : null),
        ...(interviewIndex != -1 ? { interviews: currentInterviews } : null),
        ...(applicationIndex != -1 && applicationState === 'undecided'
          ? { undecidedApplications: currentApplications }
          : null),
        ...(applicationIndex != -1 && applicationState === 'kiv'
          ? { kivApplications: currentApplications }
          : null),
        ...(applicationIndex != -1 && applicationState === 'shortlisted'
          ? { shortlistedApplications: currentApplications }
          : null),
        ...(applicationIndex != -1 && applicationState === 'rejected'
          ? { rejectedApplications: currentApplications }
          : null)
      };
    }
    case types.UPDATE_INTERVIEW_NOTE_FAILED: {
      return {
        ...state,
        updatingInterviewNote: false
      };
    }
    case types.UPDATE_INTERVIEW_INFO: {
      return {
        ...state,
        interviewInfo: action.interviewInfo
      };
    }
    case types.TOGGLE_REJECT_POPUP: {
      return {
        ...state,
        rejectPopupStatus: action.status
      };
    }
    case types.UPDATE_BLACKLIST_POPUP: {
      return {
        ...state,
        blacklistPopupStatus: action.status
      };
    }
    case types.UPDATE_INTERVIEW_DETAILS: {
      return {
        ...state,
        updatingInterviewDetails: true
      };
    }
    case types.UPDATE_INTERVIEW_DETAILS_SUCCEED: {
      return {
        ...state,
        updatingInterviewDetails: false,
        interviewInfo: action.interviewInfo
      };
    }
    case types.UPDATE_INTERVIEW_DETAILS_FAILED: {
      return {
        ...state,
        updatingInterviewDetails: false
      };
    }
    case types.DELETE_INTERVIEW: {
      return {
        ...state,
        deletingInterview: true
      };
    }
    case types.DELETE_INTERVIEW_SUCCEED: {
      return {
        ...state,
        deletingInterview: false,
        interviewDeleted: action.success
      };
    }
    case types.DELETE_INTERVIEW_FAILED: {
      return {
        ...state,
        deletingInterview: false
      };
    }
    case types.UPDATE_INTERVIEW_DATA: {
      return {
        ...state,
        interviewData: action.data
      };
    }
    case types.TRIGGER_BULK_REJECT_POPUP: {
      return {
        ...state,
        showBulkRejectPopup: action.status
      };
    }
    case types.GET_CURRENT_STATE: {
      return {
        ...state,
        currentState: action.currentState
      };
    }
    case types.TOGGLE_CLOSE_LOOP_POPUP: {
      return {
        ...state,
        closeLoopPopupStatus: action.status
      };
    }
    case types.UPDATE_APPLICATTION_PARAMS: {
      return {
        ...state,
        applicationParams: action.applicationParams
      };
    }
    case types.UPDATE_JOB_APPLICATIONS: {
      return {
        ...state,
        applications: action.applications
      };
    }
    case types.UPDATE_APPLICATION_USER_IDS: {
      return {
        ...state,
        applicationUserIds: action.userIds
      };
    }
    case types.BULK_DOWNLOAD_RESUME: {
      return {
        ...state,
        fetchingResumes: true
      };
    }
    case types.BULK_DOWNLOAD_RESUME_SUCCEED: {
      return {
        ...state,
        bulkResumes: action.resumes,
        fetchingResumes: false
      };
    }
    case types.BULK_DOWNLOAD_RESUME_FAILED: {
      return {
        ...state,
        fetchingResumes: false
      };
    }
    default: {
      return state;
    }
  }
};
