import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { updateProductActivationDateIndex } from '../../../../../../redux/actions/job_action';
import { Container, TextContainer, Text } from './styles';

const ActivationDatePopover = ({ applyFilter, types }) => {
  const dispatch = useDispatch();

  const productActivationDateIndex = useSelector(
    state => state.jobs.productActivationDateIndex
  );

  const onClickType = index => {
    dispatch(updateProductActivationDateIndex(index)).then(() => {
      applyFilter();
    });
  };

  return (
    <Container>
      {types.map((item, index) => {
        return (
          <TextContainer
            highlight={`${productActivationDateIndex == index}`}
            onClick={() => onClickType(index)}>
            <Text>{item}</Text>
          </TextContainer>
        );
      })}
    </Container>
  );
};

ActivationDatePopover.propTypes = {
  applyFilter: PropTypes.func,
  types: PropTypes.array
};

ActivationDatePopover.defaultProps = {
  applyFilter: () => {},
  types: []
};

export default ActivationDatePopover;
