import { toast } from 'react-toastify';

export const ErrorToast = message => {
  toast.error(message);
};

export const SuccessToast = message => {
  toast.success(message);
};

export const WarningToast = message => {
  toast.warn(message);
};

export const InfoToast = message => {
  toast.info(message, {
    position: toast.POSITION.BOTTOM_LEFT
    // autoClose: false,
  });
};
