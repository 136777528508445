import {
  Button,
  Grid,
  IconButton as MuiIconButton,
  Typography
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
  AccordianContainer,
  AccordianExpandContainer,
  AccordianFiller,
  AccordianOuterContainer,
  AccordianTabText,
  AccordianTabTimeText,
  BrowseButton,
  BrowseContainer,
  BrowseFileText,
  ChevronDownIconStyled,
  ChevronUpIconStyled,
  ContentGapContainer,
  ContentOuterContainer,
  ContentTextCountContainer,
  EditThumbnailContainer,
  EmptyVideoContainer,
  ExpandContainer,
  InnerContainer,
  InnerVideoContainer,
  OuterContainer,
  PencilSquareIconStyled,
  ProcessingContainer,
  RearrangeContainer,
  RearrangeText,
  SeqeunceContainer,
  SeqeunceRevertButton,
  TextFieldStyled,
  TrashIconStyled,
  VideoContainer
} from './styles';
import StatusTag from '../../../components/StatusTag';
import { VimeoVideo } from '../VimeoVideo';
import { ReactComponent as EditThumbnail } from '../../../../../assets/images/company/edit-photo-icon.svg';
import { ReactComponent as GreyEditThumbnail } from '../../../../../assets/images/company/grey-edit-photo-icon.svg';
import { useDropzone } from 'react-dropzone';
import { isEmpty } from 'lodash';
import SaveButton from '../../../components/SaveButton';
import IconButton from '../../../components/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ErrorText from '../../../components/ErrorText';
import { List, ListText } from '../../../CoverImage/styles';

export default function EditVideoCard(props) {
  const {
    video,
    videoLength,
    videoIndex,
    handleChange,
    handleChangeOrder,
    onDrop,
    onDropRejected,
    setSelected,
    setThumbnailOpen,
    deleteConfirmation,
    submitCofirmation,
    disabled,
    setInfo,
    capturedThumbnail,
    revertInputChanges,
    hasUrlChanges,
    expandTab,
    changeEditable,
    loading
  } = props;
  const [selectedVideoTab, setSelectedVideoTab] = useState(false);
  const MAX_FILE_SIZE = 200 * 1024 * 1024;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isFileTooLarge
  } = useDropzone({
    onDrop: (files, props) => onDrop(files, video.id, selectedVideoTab),
    onDropRejected,
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE,
    accept: 'video/*'
  });

  const hiddenFileInput = useRef(null);
  const handleClick = videoTab => {
    setSelectedVideoTab(videoTab);
    hiddenFileInput?.current?.focus();
    hiddenFileInput?.current?.click();
  };

  const getSubmitType = content => {
    if (content?.status === 'In Review' && video?.content?.length === 1) {
      return 'EDIT REVIEW';
    }

    if (content?.status === 'Rejected' && video?.content?.length === 1) {
      return 'EDIT REJECT';
    }

    if (content?.status === 'Live on Hiredly') {
      return 'EDIT LIVE';
    }

    if (content?.status == 'In Review' && video?.content?.length === 2) {
      return 'EDIT REVIEW WITH LIVE';
    }

    if (content?.status === 'Rejected' && video?.content?.length === 2) {
      return 'EDIT REJECT WITH LIVE';
    }

    return '';
  };

  return (
    <OuterContainer>
      {/* rearrange tab */}
      <RearrangeContainer>
        <RearrangeText>{video?.sortOrder}</RearrangeText>
        <IconButton
          disabled={video.sortOrder === 1}
          tooltipTitle="Move up"
          transparent
          onClick={() => handleChangeOrder(video?.sortOrder, 'up')}>
          <ChevronUpIconStyled disabled={video?.sortOrder === 1} />
        </IconButton>
        <IconButton
          disabled={videoLength === videoIndex + 1}
          tooltipTitle="Move down"
          transparent
          onClick={() => handleChangeOrder(video?.sortOrder, 'down')}>
          <ChevronDownIconStyled disabled={videoLength === videoIndex + 1} />
        </IconButton>
      </RearrangeContainer>

      {/* accordion tab */}
      {video?.content?.map((content, index) => {
        const position = content?.personPosition;
        const name = content?.personName;
        const quote = content?.quote;
        const vimeoId = content?.vimeoId;

        let disableSubmit;
        if (isEmpty(quote) || isEmpty(vimeoId)) {
          disableSubmit = true;
        } else {
          disableSubmit = false;
        }

        const quoteLimit = content?.quote?.length > 250;
        const nameLimit = content?.personName?.length > 50;
        const positionLimit = content?.personPosition?.length > 50;
        const exceedLimit = quoteLimit || nameLimit || positionLimit;

        return (
          <>
            <ExpandContainer
              onClick={() => {
                expandTab({
                  index: videoIndex,
                  tab: index,
                  expand: !content?.expand
                });
                handleClick(content?.id);
              }}
              moreThanOne={video?.content?.length > 1}
              live={content?.status === 'Live on Hiredly'}>
              <AccordionTabContent
                status={content?.status}
                id={content?.id}
                updatedAt={content?.updatedAt}
                vimeoId={content?.vimeoId}
                processing={content?.processing}
                videoTab={selectedVideoTab}
                video={video}
                setInfo={setInfo}
                capturedThumbnail={capturedThumbnail}
              />
              <Grid sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <IconButton
                  tooltipTitle={'Edit Content'}
                  transparent
                  disabled={disabled && content.id === video?.content[0]?.id}
                  onClick={e => {
                    e.stopPropagation();

                    setSelected({
                      id: video?.id,
                      videoTab: content?.id,
                      status: content?.status
                    });
                    if (disabled && content.id === video?.content[0]?.id) {
                      return;
                    }

                    if (!content?.expand) {
                      expandTab({
                        index: videoIndex,
                        tab: index,
                        expand: true
                      });
                    }

                    changeEditable({
                      index: videoIndex,
                      tab: index,
                      edit: !content?.editable
                    });
                  }}>
                  <PencilSquareIconStyled
                    disabled={disabled && content.id === video?.content[0]?.id}
                  />
                </IconButton>

                <IconButton
                  tooltipTitle={
                    content?.status === 'Live on Hiredly'
                      ? 'Remove Content'
                      : 'Retract Request'
                  }
                  transparent
                  onClick={e => {
                    e.stopPropagation();
                    setSelected({
                      id: video?.id,
                      videoTab: content?.id,
                      status: content?.status
                    });
                    deleteConfirmation({
                      id: video?.id,
                      videoTab: content?.id,
                      status: content?.status
                    });
                  }}>
                  <TrashIconStyled />
                </IconButton>

                <IconButton
                  transparent
                  onClick={e => {
                    e.stopPropagation();
                    setSelected({
                      id: video?.id,
                      videoTab: content?.id,
                      status: content?.status
                    });

                    expandTab({
                      index: videoIndex,
                      tab: index,
                      expand: !content?.expand
                    });
                  }}>
                  {content?.expand ? (
                    <ChevronUpIconStyled />
                  ) : (
                    <ChevronDownIconStyled />
                  )}
                </IconButton>
              </Grid>
            </ExpandContainer>

            <InnerContainer expand={content?.expand}>
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'row'
                }}>
                <Grid
                  sx={{
                    width: '507px',
                    height: '100%'
                  }}>
                  {!isEmpty(content?.vimeoId) && content?.editable && (
                    <div {...getRootProps()}>
                      <input
                        type="file"
                        id="file"
                        ref={hiddenFileInput}
                        accept="video/*"
                        style={{ display: 'none' }}
                        {...getInputProps()}
                      />
                    </div>
                  )}
                  <InnerVideoContainer>
                    {isEmpty(content?.vimeoId) && (
                      <EmptyVideoContainer {...getRootProps()}>
                        <Typography>
                          Drag and drop your video file here or{' '}
                          <BrowseFileText
                            onClick={() => handleClick(content?.id)}>
                            Browse files
                          </BrowseFileText>
                        </Typography>
                        <input
                          type="file"
                          id="file"
                          ref={hiddenFileInput}
                          accept="video/*"
                          style={{ display: 'none' }}
                          {...getInputProps()}
                        />
                      </EmptyVideoContainer>
                    )}

                    {!isEmpty(content?.vimeoId) && content?.processing && (
                      <ProcessingContainer>
                        <Typography
                          sx={{
                            color: `rgba(0, 0, 0, 0.87)`,
                            fontSize: '14px'
                          }}>
                          Processing Video...
                        </Typography>
                        <Typography
                          sx={{
                            color: `rgba(0, 0, 0, 0.60)`,
                            fontSize: '12px'
                          }}>
                          This may take about 5 minutes. Thanks for your
                          patience!
                        </Typography>
                      </ProcessingContainer>
                    )}

                    {!isEmpty(content?.vimeoId) && !content?.processing && (
                      <VideoContainer>
                        {content?.vimeoId && capturedThumbnail ? (
                          <VimeoVideo
                            videoId={content?.vimeoId}
                            capturedThumbnail={capturedThumbnail}
                          />
                        ) : (
                          <Grid
                            sx={{
                              height: '275px',
                              backgroundColor: 'transparent'
                            }}></Grid>
                        )}

                        <EditThumbnailContainer
                          sx={{
                            cursor: content?.editable && 'pointer',
                            backgroundColor: !content?.editable && '#EFEFEF'
                          }}
                          editable={content?.editable}
                          onClick={() => {
                            if (content?.editable) {
                              setSelected({
                                id: video?.id,
                                videoTab: content?.id
                              });
                              setThumbnailOpen(true);
                            }
                          }}>
                          {content?.editable ? (
                            <EditThumbnail />
                          ) : (
                            <GreyEditThumbnail />
                          )}
                          <Typography
                            sx={{
                              textDecoration: 'underline',
                              fontSize: '12px',
                              color: !content?.editable && '#00000038'
                            }}>
                            Edit Video Thumbnail
                          </Typography>
                        </EditThumbnailContainer>
                      </VideoContainer>
                    )}

                    <BrowseContainer>
                      <Grid
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '12px',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%'
                        }}>
                        <div {...getRootProps()}>
                          <BrowseButton
                            disabled={!content?.editable}
                            onClick={() => handleClick(content?.id)}>
                            Browse files
                          </BrowseButton>
                        </div>

                        <Grid
                          sx={{
                            height: '1px',
                            width: '100%',
                            backgroundColor: '#D8D8D8'
                          }}></Grid>
                      </Grid>
                      <Grid>
                        <List>
                          <ListText sx={{ fontSize: '12px !important' }}>
                            Supported file type: .mp4, .mpeg
                          </ListText>
                        </List>
                        <List>
                          <ListText sx={{ fontSize: '12px !important' }}>
                            File size: less than 200 MB
                          </ListText>
                        </List>
                        <List>
                          <ListText sx={{ fontSize: '12px !important' }}>
                            Dimensions: landscape 16:9 (640x360px) & portrait
                            9:16 (360x640px)
                          </ListText>
                        </List>
                      </Grid>
                    </BrowseContainer>
                  </InnerVideoContainer>
                </Grid>

                <ContentOuterContainer>
                  <ContentGapContainer>
                    <ContentTextCountContainer>
                      <Typography sx={{ fontSize: '14px' }}>
                        Quote<span style={{ color: '#BE4242' }}>*</span>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          color: content?.quote?.length > 250 && '#F75443'
                        }}>
                        {content?.quote?.length || 0}/250
                      </Typography>
                    </ContentTextCountContainer>
                    <TextFieldStyled
                      error={content?.quote?.length > 250}
                      disabled={!content?.editable}
                      value={content?.quote}
                      placeholder="Type here"
                      name="quote"
                      multiline
                      rows={12}
                      onChange={e =>
                        handleChange(
                          video?.id,
                          content?.id,
                          'quote',
                          e.target.value
                        )
                      }
                    />
                    {content?.quote?.length > 250 && (
                      <ErrorText>
                        You have exceeded the character limit.
                      </ErrorText>
                    )}
                  </ContentGapContainer>

                  <ContentGapContainer>
                    <ContentTextCountContainer>
                      <Typography sx={{ fontSize: '14px' }}>
                        Person Name
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          color: content?.personName?.length > 50 && '#F75443'
                        }}>
                        {content?.personName?.length || 0}/50
                      </Typography>
                    </ContentTextCountContainer>
                    <TextFieldStyled
                      error={content?.personName?.length > 50}
                      disabled={!content?.editable}
                      value={content?.personName}
                      placeholder="Type here"
                      name="personName"
                      onChange={e =>
                        handleChange(
                          video?.id,
                          content?.id,
                          'personName',
                          e.target.value
                        )
                      }
                    />
                    {content?.personName?.length > 50 && (
                      <ErrorText>
                        You have exceeded the character limit.
                      </ErrorText>
                    )}
                  </ContentGapContainer>

                  <ContentGapContainer>
                    <ContentTextCountContainer>
                      <Typography sx={{ fontSize: '14px' }}>
                        Person Position
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          color:
                            content?.personPosition?.length > 50 && '#F75443'
                        }}>
                        {content?.personPosition?.length || 0}/50
                      </Typography>
                    </ContentTextCountContainer>
                    <TextFieldStyled
                      error={content?.personPosition?.length > 50}
                      disabled={!content?.editable}
                      value={content?.personPosition}
                      placeholder="Type here"
                      name="personPosition"
                      onChange={e =>
                        handleChange(
                          video?.id,
                          content?.id,
                          'personPosition',
                          e.target.value
                        )
                      }
                    />
                    {content?.personPosition?.length > 50 && (
                      <ErrorText>
                        You have exceeded the character limit.
                      </ErrorText>
                    )}
                  </ContentGapContainer>
                </ContentOuterContainer>
              </Grid>
            </InnerContainer>
            {content?.expand && content?.editable && (
              <SeqeunceContainer>
                <SeqeunceRevertButton
                  disabled={
                    !hasUrlChanges({ index: videoIndex, tab: index }) || loading
                  }
                  onClick={() =>
                    revertInputChanges({ index: videoIndex, tab: index })
                  }
                  variant="text">
                  Revert Changes
                </SeqeunceRevertButton>
                <SaveButton
                  disabled={
                    !hasUrlChanges({ index: videoIndex, tab: index }) ||
                    disableSubmit ||
                    exceedLimit ||
                    loading
                  }
                  onClick={() => {
                    // setEditMode(video?.id);
                    submitCofirmation({
                      type: getSubmitType(content),
                      id: video?.id,
                      index: index
                    });
                  }}
                  height="40px">
                  Submit for review
                </SaveButton>
              </SeqeunceContainer>
            )}
          </>
        );
      })}
    </OuterContainer>
  );
}

const AccordionTabContent = ({
  status,
  updatedAt,
  vimeoId,
  processing,
  video,
  videoTab,
  setInfo,
  capturedThumbnail
}) => {
  const statusText = [
    {
      title: 'Draft',
      text: "Ready to submit? Click 'Submit for review' to start the approval process."
    },
    {
      title: 'In Review',
      text: 'Your recent changes is currently in review.'
    },
    {
      title: 'Rejected',
      text: 'Your content has been rejected, please check your email for further details.'
    },
    {
      title: 'Live on Hiredly',
      text: 'No further edit can be made until the new content is reviewed'
    },
    {
      title: 'Live on Hiredly',
      text: ''
    }
  ];

  const text =
    video?.content?.length === 1 && status === 'Live on Hiredly'
      ? statusText[statusText?.length - 1]
      : statusText?.find(text => {
          return text?.title === status;
        });

  return (
    <AccordianOuterContainer>
      {vimeoId && (
        <AccordianContainer>
          {capturedThumbnail && !processing ? (
            <VimeoVideo videoId={vimeoId} small={true} />
          ) : (
            <AccordianFiller></AccordianFiller>
          )}
        </AccordianContainer>
      )}

      <AccordianExpandContainer>
        <StatusTag
          vimeoId={vimeoId}
          status={status}
          companyVideo={true}
          videoIndex={videoTab}
        />
        <Typography sx={{ fontSize: '12px' }}>{text?.text}</Typography>
        {status === 'Draft' && (
          <AccordianTabText
            onClick={e => {
              e.stopPropagation();
              setInfo(true);
            }}>
            Learn more
          </AccordianTabText>
        )}
        {status === 'In Review' && (
          <AccordianTabTimeText>
            Request submitted at {moment(updatedAt).format('MM/DD/YYYY HH:mm')}
          </AccordianTabTimeText>
        )}
      </AccordianExpandContainer>
    </AccordianOuterContainer>
  );
};
