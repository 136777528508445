import { styled as styling } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Avatar, Button } from '@mui/material';

export const TitleContainer = styling('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-start'
});

export const FirstContainer = styling('div')({
  display: 'grid',
  gridTemplateColumns: '30% auto',
  minHeight: '180px',
  background: '#fff',
  padding: '20px 40px',
  borderRadius: '20px 20px 0 0',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)',
  '@media (max-width: 989px)': {
    padding: '5px 20px'
  }
});

export const ImageContainer = styling('div')({
  display: 'flex',
  alignItems: 'center'
});

export const ProfilePicture = styling(Avatar)({
  borderRadius: '50%',
  background: 'pink',
  maxWidth: '105px',
  maxHeight: '105px',
  '@media (min-width: 990px)': {
    width: '5.5vw',
    height: '5.5vw'
  },
  '@media (max-width: 989px)': {
    width: '80px',
    height: '80px'
  }
});

export const Name = styling('div')({
  fontWeight: '700',
  fontSize: '18px',
  fontFamily: 'Inter'
});

export const IdentityContainer = styling('div')({
  display: 'flex'
});

export const Age = styling(Typography)({
  color: '#666666',
  fontSize: '12px',
  fontWeight: '400',
  fontFamily: 'Inter',
  margin: '5px 0'
});

export const Dot = styling('div')({
  width: '3px',
  height: '3px',
  borderRadius: '50%',
  background: '#666666',
  display: 'flex',
  alignSelf: 'center',
  margin: '0 3px'
});

export const Nationality = styling(Typography)({
  color: '#666666',
  fontSize: '12px',
  fontWeight: '400',
  fontFamily: 'Inter',
  margin: '5px 0'
});

export const ShortSummary = styling(Typography)({
  fontFamily: 'Inter',
  fontSize: '13px',
  margin: '5px 0'
});

export const ButtonStyled = styling(Button)(({ type }) => ({
  borderRadius: '26.5px',
  boxShadow: type == 'basic' ? '0px 4px 20px rgb(0 0 0 / 15%)' : 'none',
  background: type == 'basic' ? '#512acc' : 'transparent',
  alignSelf: 'center',
  color: type == 'basic' ? '#fff' : 'rgba(0, 0, 0, 0.26)',
  fontWeight: '700',
  padding: '5px 20px',
  '@media (max-width: 989px)': {
    padding: '5px 20px'
  },
  backgroundColor: type == 'disabled' ? 'rgba(0, 0, 0, 0.12)' : 'transparent'
}));

export const SecondContainer = styling('div')({
  background: '#f5f5f5',
  padding: '20px 40px',
  borderRadius: '0 0 20px 20px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)'
});

export const InfoContainer = styling('div')({});

// export const SubInfoContainer = styling('div')(({ type }) => ({
//   ...(type == 'second-section' && {
//     display: 'grid',
//     gridTemplateColumns: 'repeat(2, 50%)',
//   }),

//   ...(type == 'first-section' && {
//     margin: '0 5px 13px 0',
//   }),
// }));

// export const BoldText = styling(Typography)({
//   fontWeight: 'bold',
//   fontSize: '13px',
//   fontFamily: 'Inter',
//   padding: '10px 0',
// });

// export const NormalText = styling(Typography)({
//   fontSize: '13px',
//   fontFamily: 'Inter',
//   padding: '10px 0',
// });

export const Text = styling('p')(({ type }) => ({
  fontFamily: 'Inter',
  fontWeight: '700',
  fontSize: type == 'normal' ? '14px' : 'inherit'
}));

export const SummaryContainer = styling('div')({
  minHeight: '90px',
  overflow: 'auto',
  maxHeight: '90px',
  wordBreak: 'break-word',
  '@media (max-width: 620px)': {
    maxWidth: '260px'
  }
});

export const MainContainer = styling('div')({
  margin: '0 0 20px',
  minWidth: '330px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  maxWidth: '500px',
  '@media (min-width: 990px)': {
    width: '100%',
    minWidth: '500px'
  },
  '@media (min-width: 989px)': {
    display: 'inline-block'
  }
});

export const SectionContainer = styling('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px',
  alignSelf: 'stretch'
});

export const LabelContainer = styling('div')({
  display: 'grid',
  width: '100%'
});

export const SectionLabel = styling(Typography)({
  color: '#40249B',
  fontWeight: '400',
  fontSize: '10px',
  fontFamily: 'Inter',
  lineHeight: '20px',
  letterSpacing: '1.5px',
  textTransform: 'uppercase',
  padding: '0'
});

export const SubContainer = styling('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  width: '100%'
});

export const SubInfoContainer = styling('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 50%)',
  width: '100%'
});

export const BoldText = styling(Typography)(
  ({ $smallPadding, $noPadding }) => ({
    fontWeight: '600',
    fontSize: '12px',
    fontFamily: 'Inter',
    padding: $noPadding ? '0' : $smallPadding ? '5px 0' : '10px 0'
  })
);

export const NormalText = styling(Typography)(
  ({ $smallPadding, $noPadding, $list }) => ({
    fontSize: '12px',
    fontFamily: 'Inter',
    padding: $noPadding ? '0' : $smallPadding ? '5px 0' : '10px 0',

    ...($list && {
      display: 'list-item',
      listStyleType: 'disc',
      listStylePosition: 'inside',
      padding: '1px 0'
    })
  })
);
