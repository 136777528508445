import React from 'react';
import { ButtonStyled } from './styles';

export const CustomButton = ({ type, disabled, onClick, child }) => {
  return (
    <ButtonStyled type={type} disabled={disabled} onClick={onClick}>
      {child}
    </ButtonStyled>
  );
};
