import { styled as styling } from '@mui/material/styles';

export const Container = styling('div')({
  margin: '0 auto 60px',
  minWidth: '330px',
  width: '100%',
});

export const Text = styling('p')(({ type }) => ({
  fontFamily: 'Inter',
  fontWeight: '700',
  fontSize: type == 'normal' ? '14px' : 'inherit',
}));
