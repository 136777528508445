import React from 'react';
import {
  JobCardWrapperSkeleton,
  JobTitleSkeleton,
  JobTrackSkeleton,
  JobTypeSkeleton
} from './styles';

const SkeletonLoader = () => (
  <JobCardWrapperSkeleton>
    <JobTitleSkeleton variant="text" width="150px" />

    <JobTrackSkeleton variant="text" width="100px" />
    <JobTypeSkeleton variant="text" width="100px" />
  </JobCardWrapperSkeleton>
);

export default SkeletonLoader;
