import { styled as styling } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Alert } from '@mui/material';

export const Container = styling(Grid)(props => ({
  color: '#90B938',
  width: '20px',
  height: '20px'
}));

export const TypographyStyled = styling(Typography)(props => ({
  fontSize: '14px'
}));

export const TimeStyled = styling('span')(props => ({
  fontStyle: 'italic',
  color: `rgba(0, 0, 0, 0.38)`
}));

export const AlertStyled = styling(Alert)(props => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '40px',
  padding: '8px 16px'
}));
