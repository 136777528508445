import CheckIcon from '@heroicons/react/24/outline/CheckIcon';
import FilmIcon from '@heroicons/react/24/solid/FilmIcon';
import HeartIcon from '@heroicons/react/24/solid/HeartIcon';
import RectangleGroupIcon from '@heroicons/react/24/solid/RectangleGroupIcon';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled as styling } from '@mui/material/styles';
import theme from '../../../assets/theme';
import { Grid } from '@mui/material';
import { SparklesIcon } from '@heroicons/react/24/outline';

export const SectionContainer = styling('div')({
  margin: '0 auto',
  maxWidth: '1440px',
  padding: '5rem 6.25rem 3.75rem',

  '@media (max-width: 1280px)': {
    padding: '2.5rem 1.5rem'
  },

  '@media (max-width: 640px)': {
    padding: '2.5rem 1rem'
  }
});

export const BoxContainer = styling(Box)({
  display: 'flex',
  width: '100%',

  transition: 'all 0.15s ease-in-out'
});

export const ColumnContainer = styling(Box)({
  display: 'flex',
  flexDirection: 'column',

  borderRadius: '0.625rem',
  overflow: 'clip',

  maxWidth: '190px',
  width: '100%'
});

export const InnerColumnContainer = styling(Box)({
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid rgba(81, 42, 204, 0.10)',
  height: '58px',
  width: '100%'
});

export const RowContainer = styling(Box)({
  display: 'flex',
  borderRadius: '0.625rem',
  overflow: 'clip',
  transition: 'all 0.15s ease-in-out'
});

export const TextStyled = styling(Typography)(props => ({
  color: props?.white ? `rgba(255, 255, 255, 0.87)` : `rgba(0, 0, 0, 0.87)`,
  fontFamily: 'Inter'
}));

export const GradientText = styling(Box)({});

export const PurpleStyled = styling('span')({
  color: theme.palette.primary.main
});

export const ButtonDivider = styling(Box)(props => ({
  width: props.isHovered ? '100%' : '0%',
  transition: 'all 0.3s ease',
  height: '1px',
  backgroundColor: `#512ACC`,
  position: 'relative',
  left: '50%',
  transform: 'translateX(-50%)',
  marginTop: '3px'
}));

export const ButtonStyled = styling('button')({
  background: 'none',
  border: 'none',
  color: theme.palette.primary.main,

  fontFamily: 'Inter',
  fontSize: '1rem',
  fontWeight: 700,
  whiteSpace: 'nowrap',

  padding: '0.6875rem 2rem',
  margin: '1rem 0 0',

  cursor: 'pointer',

  '@media (max-width: 640px)': {
    padding: '0.5625rem 1rem'
  }
});

export const OutlineButtonStyled = styling('button')({
  background: 'none',
  border: `2px solid black`,
  borderRadius: '25rem',
  color: `rgba(0, 0, 0, 0.87)`,

  fontFamily: 'Inter',
  fontSize: '1rem',
  fontWeight: 700,

  padding: '0.7rem 3.5rem',

  cursor: 'pointer',

  '@media (max-width: 640px)': {
    fontSize: '0.75rem',
    padding: '0.475rem 4.125rem'
  }
});

export const ImageStyled = styling('img')({
  objectFit: 'contain',
  height: '120px',
  width: '120px',
  margin: '0.5rem 0.75rem 0.875rem',

  '@media (max-width: 640px)': {
    height: '80px',
    width: '80px'
  }
});

export const CheckIconStyled = styling(CheckIcon)({
  height: '20px',
  width: '20px',
  color: theme.palette.primary.main,
  strokeWidth: '2px'
});

export const SparklesIconStyled = styling(SparklesIcon)({
  height: '20px',
  width: '20px',
  color: theme.palette.primary.main,
  strokeWidth: '2px'
});

export const EmptyIconStyled = styling('div')({
  height: '26px',
  width: '26px'
});

export const GroupIconStyled = styling(RectangleGroupIcon)({
  height: '20px',
  width: '20px'
});

export const HeartIconStyled = styling(HeartIcon)({
  height: '20px',
  width: '20px'
});

export const FilmIconStyled = styling(FilmIcon)({
  height: '20px',
  width: '20px'
});

export const PriceText = styling(Typography)({
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '20px',
  letterSpacing: '1.5px',
  textTransform: 'uppercase',
  color: `rgba(0, 0, 0, 0.87)`,
  fontFamily: 'Inter'
});

export const GreyText = styling(Typography)({
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  textTransform: 'none',
  color: `rgba(0, 0, 0, 0.60)`,
  fontFamily: 'Inter'
});
