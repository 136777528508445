export const convertStatusText = approvalStatus => {
  switch (approvalStatus) {
    case 'pending_review':
      return 'In Review';
    case 'approved':
      return 'Live on Hiredly';
    case 'reject':
      return 'Rejected';
    default:
      return approvalStatus;
  }
};
