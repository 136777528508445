import moment from 'moment';
import React, { useEffect, useState } from 'react';

export const TimeSelect = ({ onChange, recordedTime, interviewDate }) => {
  const currentTime = moment().format('HH:mm ');
  const currentDate = moment().format('YYYY-MM-DD');
  const [timeArray, setTimeArray] = useState([]);

  useEffect(() => {
    let timeArray = ['Select a time'];

    for (let i = 8; i <= 22; i++) {
      const limit = i === 22 ? 1 : 2;
      for (let x = 0; x < limit; x++) {
        let time = i + ':' + (x == 0 ? '00' : 30 * x);

        if (time == 'Select a time') {
          time = 'Select a time';
        } else {
          var columnIndex = time?.indexOf(':');

          var TwentyFourSystemHour = +time?.substr(0, columnIndex);

          var TwelveSystemHour = TwentyFourSystemHour % 12 || 12;

          var ampm =
            TwentyFourSystemHour < 12 || TwentyFourSystemHour === 24
              ? ' AM'
              : ' PM';
          time = TwelveSystemHour + time?.substr(columnIndex, 3) + ampm;
        }

        timeArray.push(time);
      }
    }

    setTimeArray(timeArray);
  }, []);

  return (
    <>
      <select
        value={recordedTime}
        style={
          recordedTime == '12:00 AM'
            ? {
                borderRadius: '20px',
                background: 'transparent',
                border: '1px solid red',
                cursor: 'pointer',
                padding: '10px 20px'
              }
            : {
                borderRadius: '20px',
                background: 'transparent',
                border: '1px solid #aeaeae',
                cursor: 'pointer',
                padding: '10px 20px'
              }
        }
        onChange={onChange}>
        {timeArray.map((time, index) => {
          const convertTo24hrs = moment(time, 'h:mm A').format('HH:mm');
          const disableTimeOption = currentTime >= convertTo24hrs;

          return (
            <option
              key={index}
              value={time}
              disabled={
                interviewDate == currentDate ? disableTimeOption : false
              }>
              {time}
            </option>
          );
        })}
      </select>
      {recordedTime == '12:00 AM' && (
        <span
          style={{
            fontSize: '0.75rem',
            color: '#f44336',
            marginLeft: '50px',
            marginRight: '14px',
            marginTop: '4px',
            width: '200px'
          }}>
          Please select a time
        </span>
      )}
    </>
  );
};
