import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from '../../context';
import { ReactComponent as InsightIcon } from '../../../../../assets/common/insight-icon.svg';
import {
  TextFieldStyled,
  IconTextContainer,
  SalaryBanner,
  SimpleDescription,
  TextContainer,
  Text
} from './styles';
import { SimpleTipsTooltip } from '../../../../../components/Tooltips';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

export default function Salary() {
  const keyMin = 'minSalaryRange';
  const keyMax = 'maxSalaryRange';
  const range = 'range';
  const {
    formState,
    handleFieldChange,
    handleSingleFieldChange,
    errorState,
    handleErrorState
  } = useFormContext();

  return (
    <Grid>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingBottom: '20px'
        }}>
        <FontAwesomeIcon icon={faDollarSign} style={{ marginRight: '15px' }} />
        <Grid style={{ paddingBottom: 0, height: 20 }}>
          <h6
            style={{
              fontSize: '16px',
              fontWeight: '700',
              fontFamily: 'Inter'
            }}>
            Salary Range
          </h6>
        </Grid>
      </div>

      <div style={{ position: 'relative' }}>
        <SalaryBanner>
          <IconTextContainer container="salary-banner-container">
            <InsightIcon width={20} style={{ marginRight: '14px' }} />
            <TextContainer>
              <Text type="salary-banner-title">BOOST YOUR JOB LISTING!</Text>
              <div style={{ display: 'inline-flex' }}>
                <Text type="salary-banner-description">
                  Displaying salary range can increase job application by
                </Text>
                &nbsp;
                <Text type="attention">x2</Text>
              </div>
            </TextContainer>
          </IconTextContainer>
        </SalaryBanner>
      </div>

      <div style={{ display: 'flex' }}>
        <Grid>
          <TextFieldStyled
            type="number"
            isError={
              errorState?.includes(keyMin) || errorState?.includes(range)
            }
            value={formState?.minSalaryRange}
            name="minSalaryRange"
            placeholder="Minimum"
            onChange={e => {
              handleFieldChange(e);
              handleErrorState(
                errorState.filter(error => error !== keyMin && error !== range)
              );
            }}
          />
        </Grid>
        <Grid
          style={{
            margin: '0 20px',
            display: 'flex'
          }}>
          <p style={{ margin: '10px 0' }}>to</p>
        </Grid>
        <Grid>
          <TextFieldStyled
            type="number"
            isError={
              errorState?.includes(keyMax) || errorState?.includes(range)
            }
            value={formState?.maxSalaryRange || null}
            name="maxSalaryRange"
            placeholder="Maximum"
            onChange={e => {
              handleFieldChange(e);
              handleErrorState(
                errorState.filter(error => error !== keyMax && error !== range)
              );
            }}
          />
        </Grid>

        <SimpleTipsTooltip
          top={'50px'}
          left={'-210px'}
          width={'300px'}
          padding={'10px 16px'}
          description={
            <SimpleDescription>
              Candidates are more likely to apply for jobs that offer salary
              information.
            </SimpleDescription>
          }
        />
      </div>

      {errorState?.includes(range) && (
        <p
          style={{
            color: '#BE4242',
            fontSize: '12px',
            lineHeight: '20px',
            display: 'flex',
            padding: '0px 8px',
            alignItems: 'center',
            gap: '8px'
          }}>
          <ExclamationCircleIcon
            style={{
              width: '20px',
              height: '20px',
              color: '#BE4242'
            }}
          />
          Minimum Salary Range must be less than Maximum Salary Range
        </p>
      )}

      {errorState?.includes(keyMin) && (
        <p
          style={{
            color: '#BE4242',
            fontSize: '12px',
            lineHeight: '20px',
            display: 'flex',
            padding: '0px 8px',
            alignItems: 'center',
            gap: '8px'
          }}>
          <ExclamationCircleIcon
            style={{
              width: '20px',
              height: '20px',
              color: '#BE4242'
            }}
          />
          Minimum salary are required
        </p>
      )}

      {errorState?.includes(keyMax) && (
        <p
          style={{
            color: '#BE4242',
            fontSize: '12px',
            lineHeight: '20px',
            display: 'flex',
            padding: '0px 8px',
            alignItems: 'center',
            gap: '8px'
          }}>
          <ExclamationCircleIcon
            style={{
              width: '20px',
              height: '20px',
              color: '#BE4242'
            }}
          />
          Maximum salary are required
        </p>
      )}

      <Grid style={{ paddingTop: 0 }}>
        <div style={{ display: 'flex' }}>
          <Checkbox
            name="hideSalary"
            color="default"
            inputProps={{
              'aria-label': 'checkbox with default color'
            }}
            style={{
              marginRight: 5,
              marginLeft: -10,
              color: '#512acc'
            }}
            onChange={e =>
              handleSingleFieldChange('hideSalary', !e.target.checked)
            }
            checked={!formState?.hideSalary || false}
          />
          <p
            style={{
              fontSize: '14px',
              fontFamily: 'Inter'
            }}>
            Display salary range
          </p>
        </div>
        <p
          style={{
            margin: '0',
            fontSize: '13px',
            fontFamily: 'Inter',
            color: 'rgb(174, 174, 174)'
          }}>
          If unchecked, salary will be shown as ‘undisclosed’.
        </p>
      </Grid>
    </Grid>
  );
}
