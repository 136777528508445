import {
  ChatBubbleOutline,
  ExpandLess,
  ExpandMore,
  Menu as MenuIcon,
  Person as PersonIcon
} from '@mui/icons-material';
import {
  AppBar,
  CardActionArea,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import HiredlyLogo from '../../assets/common/hiredly-logo-copyright.png';
import HiredlyLogoSG from '../../assets/common/hiredly-logo-sg.png';
import Colors from '../../styles/colors.module.scss';
import { JOBSEEKER_URL, WOBB_WEBSITE } from '../../utils/Constants';
import store from '../../redux/stores/store';
import {
  checkCompanyUnreadCount,
  updateTalentSearchChatStatus
} from '../../redux/actions/chat_action';
import {
  clearAccountInfo,
  fetchAccountInfo,
  fetchMyCompanyStatus
} from '../../redux/actions/company_actions';
import {
  clearJobInfo,
  updateJobDataForm,
  updatePostJobPopup,
  updateProductTypeIndex,
  updateTempJobDraft
} from '../../redux/actions/job_action';
import { clearOrderInfo } from '../../redux/actions/order_action';
import { countryCodeOptions } from '../SharedSelectCountry/constants';
import { openInNewTab } from '../../utils/WindowUtils';
import {
  ShieldExclamationIconStyled,
  VerificationBanner,
  VerificationLink,
  VerificationText
} from './styles';
import { isEmpty } from 'lodash';
import LeaveJobEditingPopUp from '../../pages/Jobs/PopUps/LeaveJobEditingPopUp';
import useUpdateQueryParam from '../../hooks/useUpdateQueryParam';
import useIsPostJobPath from '../../hooks/useIsPostJobPath';

const { REACT_APP_API_URL, REACT_APP_SG_API_URL } = process.env;

const drawerWidth = 250;
const isStaging =
  REACT_APP_API_URL && REACT_APP_SG_API_URL
    ? REACT_APP_API_URL.includes('staging-my-api.hiredly.com/api') ||
      REACT_APP_SG_API_URL.includes('staging-sg-api.hiredly.com/api')
    : false;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  appBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 0,

    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

    '&.MuiPaper-root': {
      flexDirection: 'row'
    }
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth
  },
  menuButton: {
    // marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    marginTop: 0,
    border: 'none',
    width: drawerWidth,

    '&.MuiPaper-root': {
      backgroundColor: '#454545'
    }
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    // padding: '20px 30px 20px 40px',
    justifyContent: 'flex-start',
    // padding: theme.spacing(0, 1),
    // ...theme.mixins.toolbar
    padding: '10px 0 10px 8px'
  },
  content: {
    width: '100%',
    flexGrow: 1,
    transition: `0.3s`,
    marginLeft: -drawerWidth
  },
  contentShift: {
    marginLeft: 0,
    transition: `0.3s`
    // transition: transitions.create(['margin', 'width'], {
    //   easing: transitions.easing.sharp,
    //   duration: transitions.duration.leavingScreen
    // })
  },
  // itemText: {
  //   fontFamily: 'Inter',
  //   color: 'white',
  //   fontSize: 16,
  //   margin: '5px 15px 5px 15px',

  //   '&.MuiListItem-root': {
  //     marginRight: '20px'
  //   }
  // },
  itemText: {
    fontFamily: 'Inter',
    color: 'white',
    fontSize: 16,
    margin: '0px 0px'
  },
  white: {
    color: Colors.priWhite
  },
  logoContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    // paddingLeft: 20,
    cursor: 'pointer'
    // width: '200px'
  },
  listItem: {
    // padding: '0px 25px'
    '-webkiy-tap-highlight-color': 'tranparent',
    backgroundColor: 'transparent',
    outline: '0px',
    border: '0px',
    margin: '0px',
    borderRadius: '0px',
    cursor: 'pointer',
    userSelect: 'none',
    verticalAlign: 'middle',
    appearance: 'none',
    color: 'inherit',
    display: 'flex',
    justifyContent: 'flex-start',
    '-webkit-box-align': 'center',
    alignItems: 'center',
    position: 'relative',
    textDecoration: 'none',
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'left',
    padding: '8px 16px',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    // '&.MuiListItem-root': {
    //   paddingRight: '10px'
    // }
  },
  nestedListItem: {
    // padding: '0px 50px',

    '&.MuiListItem-root': {
      paddingLeft: '50px'
    }
  },
  selected: {
    backgroundColor: `${Colors.priPurple} !important`
  },
  banner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '0px 30px',
    height: 50,
    marginTop: 64,
    backgroundColor: Colors.priPurple,
    cursor: 'pointer'
  },
  bannerText: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontSize: 18
  },
  textBeforeShift: {
    flexGrow: 1,

    marginLeft: 0
  },
  textAfterShift: {
    marginLeft: drawerWidth
  },
  comingSoonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 80,
    height: 20,
    borderRadius: 2,
    background:
      'linear-gradient(90deg, rgba(95,91,255,1) 0%, rgba(114,94,255,1) 35%, rgba(134,96,255,1) 70%, rgba(134,96,255,1) 100%)'
  },
  comingSoonText: {
    margin: 0,
    fontSize: 9,
    fontFamily: 'InterBold',
    color: 'white',
    textAlign: 'center'
  },
  activeChatIndicator: {
    width: 30,
    height: 16,
    backgroundColor: '#BE4242',
    borderRadius: 100,
    zIndex: 2,
    position: 'absolute',
    top: 0,
    right: '-10px',
    fontSize: '10px',
    fontWeight: '600',
    fontFamily: 'Inter',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  menuStyled: {
    transform: 'translate(-10px, 20px)',
    background: '#fff'
  },
  menu: {
    '& .MuiList-root': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    }
  },
  menuItem: {
    width: '100%',

    '&.MuiMenuItem-root': {
      padding: '5px 15px 5px 15px'
    },
    '&.MuiButtonBase-root': {
      justifyContent: 'flex-start'
    }
  }
}));

let accountInfo = localStorage.getItem('accountInfo');
accountInfo = accountInfo ? JSON.parse(accountInfo) : accountInfo;

export default function ResponsiveDrawer({
  children,
  showBanner,
  minus300,
  setIsDrawerOpened,
  origin
}) {
  const updateQueryParam = useUpdateQueryParam();
  const isPostJobPath = useIsPostJobPath();

  const location = useLocation();
  const pathname = location.pathname;
  const history = useNavigate();
  const state = history?.location?.state;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isNestedOpen, setIsNestedOpen] = useState(true);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasCompletedOrdersOrPostedJobs, setHasCompletedOrdersOrPostedJobs] =
    useState(false);
  const [ctmAccess, setCtmAccess] = useState(false);
  const [affiliatedCompanyAccess, setAffiliatedCompanyAccess] = useState(false);

  const myCompanyStatus = useSelector(
    state => state?.companies?.myCompanyStatus
  );
  const jobCreditUsableCount = useSelector(
    state => state.jobs.subscribedJobCreditsPackage?.total_usable_quantity
  );

  const jobSlotPlusUsableCount = useSelector(
    state => state.jobs.subscribedJobSlotPlusPackage?.total_usable_quantity
  );

  const jobSlotMaxUsableCount = useSelector(
    state => state.jobs.subscribedJobSlotMaxPackage?.total_usable_quantity
  );

  const unreadCompanyChatCount = useSelector(
    state => state.chats.companyUnread
  );

  const myAccountInfo = useSelector(state => state.companies.accountInfo);
  const draftJob = useSelector(state => state?.jobs?.draftJob);
  const permissions = myAccountInfo?.permissions;
  const isAuthenticated = localStorage.getItem('accessToken') !== null;
  const isMasterAccount =
    localStorage.getItem('currentAccountType') === 'master';

  const isCompanyApproved = myCompanyStatus?.isApproved;
  const jobSlotsLeft = myCompanyStatus?.jobSlotsTotalUsableQuantity >= 0;
  const jobToken = myCompanyStatus?.jobToken;
  const jobSlotsTotal = myCompanyStatus?.jobSlotsTotal > 0;
  const unlimitedToken = myCompanyStatus?.unlimitedToken;

  const dropdownCountry = useSelector(state => state.companies.country);

  const isWaitlistPage = pathname.includes('/waitlist');

  useEffect(() => {
    (async function getData() {
      if (isAuthenticated) {
        fetchAccountInfo();
        fetchMyCompanyStatus();

        if (accountInfo) {
          dispatch(checkCompanyUnreadCount());
        }
      }
    })();
  }, [isAuthenticated]);

  useEffect(() => {
    if (myAccountInfo) {
      const accountInfo = {
        ...(myAccountInfo?.id && {
          accountId: myAccountInfo?.id
        }),
        ...(myAccountInfo?.companyId && {
          companyId: myAccountInfo?.companyId
        }),
        ...(myAccountInfo?.companyName && {
          companyName: myAccountInfo?.companyName
        }),
        ...(myAccountInfo?.email && {
          email: myAccountInfo?.email
        }),
        ...(myAccountInfo?.currentAccountType && {
          accountType: myAccountInfo?.currentAccountType
        }),

        ...(myAccountInfo?.industryName && {
          industryName: myAccountInfo?.industryName
        }),

        ...(myAccountInfo?.resetPasswordAt && {
          resetPasswordAt: myAccountInfo?.resetPasswordAt
        }),

        ...(myAccountInfo?.feedbackRequired !== '' && {
          feedbackRequired: myAccountInfo?.feedbackRequired
        }),

        ...(myAccountInfo?.permissions && {
          permissions: myAccountInfo?.permissions
        }),
      };

      localStorage.setItem('accountInfo', JSON.stringify(accountInfo));
    }
  }, [myAccountInfo]);

  useEffect(() => {
    setHasCompletedOrdersOrPostedJobs(
      myCompanyStatus?.hasCompletedOrdersOrPostedJobs
    );
    setCtmAccess(myCompanyStatus?.ctmAccess);
    setAffiliatedCompanyAccess(myCompanyStatus?.affiliatedCompanyAccess);
  }, [myCompanyStatus]);

  const onLogout = async () => {
    if (store.getState().jobs.preventReloadOrLeave) {
      store.getState().jobs.isLoggingOut = true;
      history('/jobs');
      return;
    }

    dispatch(clearAccountInfo());
    dispatch(clearJobInfo());
    dispatch(clearOrderInfo());
    // dispatch(clearProductInfo());

    try {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('accountInfo');
      localStorage.removeItem('currentAccountType');
      localStorage.removeItem('lightcast-token');
      localStorage.removeItem('lightcast-token-timeout');
      localStorage.removeItem('hideNewProductsInfo');
      localStorage.removeItem('country');

      var expiresInTime = 'expires=' + 'Sun, 27 Mar 2020 10:13:42 GMT';

      document.cookie = 'companyId' + '=' + ';' + expiresInTime + ';path=/'; // Delete cookie for user ID sent to GA when user logs out

      window.dataLayer.push({ event: 'logout' });

      updateTempJobDraft([]);
      // await client.clearStore();
      history('/login');
    } catch (error) {
      toast.error(`Logout Failed! ${error.message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  const LoggedInMenu = () => (
    <Menu
      classes={{ paper: classes.menu }}
      id="menu-appbar"
      anchorEl={anchorEl}
      keepMounted
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      getContentAnchorEl={null}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}>
      <MenuItem
        onClick={() => history('/my-account')}
        className={classes.menuItem}>
        My Account
      </MenuItem>
      <MenuItem onClick={() => onLogout()} className={classes.menuItem}>
        Log Out
      </MenuItem>
    </Menu>
  );

  const onOpenDrawer = () => {
    setIsDrawerOpen(true);

    if (setIsDrawerOpened) {
      setIsDrawerOpened(true);
    }
  };

  const onCloseDrawer = () => {
    setIsDrawerOpen(false);

    if (setIsDrawerOpened) {
      setIsDrawerOpened(false);
    }
  };

  const showAllApplicantsOption = accData => {
    if (accData?.allApplicantsAccess) return true;
    return false;
  };

  const navBar = [
    { name: 'manage-job', route: 'jobs' },
    { name: 'chats', route: 'chats' },
    { name: 'all-applicants', route: 'all-applicants' },
    { name: 'talent-search', route: 'talent-search' },
    { name: 'products', route: 'purchase/choose' },
    { name: 'company-details', route: 'company-account/company-details' },
    { name: 'company-canvas', route: 'company-account/company-canvas' },
    {
      name: 'manage-team-members',
      route: 'company-account/manage-team-members'
    },
    { name: 'manage-affiliates', route: 'company-account/manage-affiliates' },
    { name: 'order-history', route: 'order-history' },
    { name: 'my-account', route: 'my-account' }
  ];

  const redirectFunction = ({ name }) => {
    const target = navBar?.find(item => item?.name === name);

    if (isPostJobPath) {
      updateQueryParam('redirect', target?.route);
      setOpenConfirmationModal(true);
    } else {
      history(`/${target?.route}`);
    }
  };

  const onClickChatIcon = () => {
    if (store.getState().chats.isTalentSearchChat) {
      dispatch(updateTalentSearchChatStatus(false));
    }

    history('/chats');
  };

  const logoRedirection = () => {
    if (isAuthenticated) {
      if (!hasCompletedOrdersOrPostedJobs) {
        history('/welcome');
      } else {
        history('/jobs');
      }
    } else {
      history('/login');
    }
  };

  const hideVerifyBanner =
    pathname.includes('/verification') ||
    pathname.includes('/login') ||
    pathname.includes('/register/login-info') ||
    pathname.includes('/register/verification') ||
    pathname.includes('/register/company-details') ||
    pathname.includes('/forgot-password');

  const verifyHandler = () => {
    history('/verification');
  };

  return (
    <div className={classes.root}>
      <ToastContainer />

      <LeaveJobEditingPopUp
        open={openConfirmationModal}
        handleClose={() => setOpenConfirmationModal(false)}
      />

      <AppBar
        position="fixed"
        style={{ background: isStaging ? '#48D1CC' : 'white' }}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: isDrawerOpen
        })}>
        <Toolbar>
          {isAuthenticated && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onOpenDrawer}
              edge="start"
              className={clsx(
                classes.menuButton,
                isDrawerOpen && classes.hide
              )}>
              <MenuIcon style={{ color: Colors.priBlack }} />
            </IconButton>
          )}
          {!isDrawerOpen && (
            <CardActionArea
              role="button"
              onKeyPress={() => {}}
              tabIndex={0}
              className={classes.logoContainer}
              onClick={logoRedirection}>
              <img
                src={
                  isWaitlistPage || countryCodeOptions[dropdownCountry] == 'SG'
                    ? HiredlyLogoSG
                    : HiredlyLogo
                }
                alt="logo"
                style={{
                  height: isWaitlistPage
                    ? 40
                    : countryCodeOptions[dropdownCountry] == 'SG'
                    ? 'auto'
                    : 20,
                  width: isWaitlistPage ? 130 : 130
                }}
              />

              {isStaging && (
                <span
                  style={{
                    width: 'auto',
                    paddingLeft: 20,
                    color: 'black',
                    fontWeight: 'bold'
                  }}>
                  ---STAGING---
                </span>
              )}
            </CardActionArea>
          )}
        </Toolbar>
        {isAuthenticated && (
          <div>
            {hasCompletedOrdersOrPostedJobs && (
              <IconButton
                color="inherit"
                style={{ marginRight: 40 }}
                onClick={onClickChatIcon}>
                <ChatBubbleOutline style={{ color: 'black' }} />
                {parseInt(unreadCompanyChatCount) > 0 && (
                  <div className={classes.activeChatIndicator}>
                    {unreadCompanyChatCount > 99
                      ? '99+'
                      : unreadCompanyChatCount}
                  </div>
                )}
              </IconButton>
            )}

            <IconButton
              color="inherit"
              style={{ marginRight: 50 }}
              onClick={event => setAnchorEl(event.currentTarget)}>
              <PersonIcon style={{ color: 'black' }} />
            </IconButton>
          </div>
        )}
        <LoggedInMenu />
      </AppBar>

      {!hideVerifyBanner &&
        !myAccountInfo?.isEmailVerified &&
        !isEmpty(myAccountInfo) && (
          <AppBar sx={{ top: 64, boxShadow: 'none' }}>
            <VerificationBanner>
              <ShieldExclamationIconStyled />
              <VerificationText>
                Please verify your email address to start your recruitment
                journey.
              </VerificationText>
              <VerificationLink onClick={verifyHandler}>
                Verify Now
              </VerificationLink>
            </VerificationBanner>
          </AppBar>
        )}

      {/* WOBB BANNER */}
      {showBanner && (
        <AppBar
          onClick={() => openInNewTab(WOBB_WEBSITE)}
          className={classes.banner}>
          <CardActionArea style={{ height: '100%' }}>
            <h6
              style={{ fontSize: '14px' }}
              className={clsx(classes.bannerText, classes.textBeforeShift, {
                [classes.textAfterShift]: isDrawerOpen
              })}>
              Welcome, you are experiencing our new dashboard. Click here to
              return to the old dashboard.
            </h6>
          </CardActionArea>
        </AppBar>
      )}

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={isDrawerOpen}
        classes={{
          paper: classes.drawerPaper
        }}>
        <div
          className={classes.drawerHeader}
          style={{ backgroundColor: Colors.priWhite }}>
          <IconButton
            className={clsx(classes.menuButton)}
            onClick={onCloseDrawer}>
            <MenuIcon />
          </IconButton>
          {isDrawerOpen && (
            <div
              role="button"
              tabIndex={0}
              onKeyPress={() => {}}
              className={classes.logoContainer}
              onClick={() => {
                dispatch(updateJobDataForm());
                hasCompletedOrdersOrPostedJobs
                  ? // &&
                    // (jobToken > 0 || unlimitedToken || hasJobSlotsTotal)
                    () => history('/jobs')
                  : () => history('/welcome');
              }}>
              <img
                src={
                  countryCodeOptions[dropdownCountry] == 'SG'
                    ? HiredlyLogoSG
                    : HiredlyLogo
                }
                alt="logo"
                style={{ height: 'auto', width: 130 }}
              />
            </div>
          )}
        </div>

        <List style={{ margin: '20px 0px' }}>
          {hasCompletedOrdersOrPostedJobs && (
            <ListItem
              button
              classes={{ root: classes.listItem, selected: classes.selected }}
              selected={pathname === '/jobs' || pathname === '/jobs/'}
              onClick={() => redirectFunction({ name: 'manage-job' })}>
              <ListItemText
                primary={<p className={classes.itemText}>Manage Jobs</p>}
              />
            </ListItem>
          )}

          {(jobSlotsLeft ||
            jobToken > 0 ||
            unlimitedToken ||
            jobSlotPlusUsableCount > 0 ||
            jobSlotMaxUsableCount > 0 ||
            jobCreditUsableCount > 0) &&
            hasCompletedOrdersOrPostedJobs &&
            permissions?.postJob && (
              <ListItem
                button
                classes={{ root: classes.listItem, selected: classes.selected }}
                selected={pathname.includes('/jobs/add') && !state}
                onClick={() => {
                  dispatch(updatePostJobPopup(true));
                  dispatch(updateProductTypeIndex(''));

                  // redirectToAddJob(
                  //   pathname.includes('/jobs/add') ||
                  //     (pathname.includes('/jobs/edit') && !state)
                  // );
                }}>
                <ListItemText
                  primary={<p className={classes.itemText}>Post New Job</p>}
                />
              </ListItem>
            )}

          {showAllApplicantsOption(myCompanyStatus) && (
            <ListItem
              button
              classes={{ root: classes.listItem, selected: classes.selected }}
              selected={pathname.includes('/all-applicants')}
              onClick={() => redirectFunction({ name: 'all-applicants' })}>
              <ListItemText
                primary={
                  <p className={classes.itemText}>Show All Applicants</p>
                }
              />
            </ListItem>
          )}

          {hasCompletedOrdersOrPostedJobs && (
            <ListItem
              button
              classes={{ root: classes.listItem, selected: classes.selected }}
              selected={pathname.includes('/chats')}
              onClick={() => redirectFunction({ name: 'chats' })}>
              <ListItemText
                primary={<p className={classes.itemText}>Chats</p>}
              />
            </ListItem>
          )}

          {myCompanyStatus?.talentSearchOrAcvAccess == 'both' ||
          myCompanyStatus?.talentSearchOrAcvAccess == 'acv_access' ? (
            <ListItem
              button
              classes={{
                root: classes.listItem,
                selected: classes.selected
              }}
              selected={pathname.includes('/active-cv-access')}
              onClick={() => redirectFunction({ name: 'active-cv-access' })}>
              <ListItemText
                primary={<p className={classes.itemText}>Active CV Access</p>}
              />
            </ListItem>
          ) : null}

          {myCompanyStatus?.talentSearchOrAcvAccess == 'both' ||
          myCompanyStatus?.talentSearchOrAcvAccess == 'talent_search_access' ? (
            <ListItem
              button
              classes={{
                root: classes.listItem,
                selected: classes.selected
              }}
              selected={pathname.includes('/talent-search')}
              onClick={() => {
                window.dataLayer.push({
                  event: 'CE_navigation-clicked--talent-search'
                });
                redirectFunction({ name: 'talent-search' });
              }}>
              <ListItemText
                primary={<p className={classes.itemText}>Talent Search</p>}
              />
            </ListItem>
          ) : null}

          {permissions?.buyToken && (
            <ListItem
              button
              classes={{ root: classes.listItem, selected: classes.selected }}
              selected={pathname.includes(
                jobSlotsTotal ? '/purchase' : '/welcome'
              )}
              onClick={() =>
                jobSlotsTotal
                  ? redirectFunction({ name: 'products' })
                  : history('/welcome')
              }>
              <ListItemText
                primary={<p className={classes.itemText}>Products</p>}
              />
            </ListItem>
          )}

          <ListItem
            button
            classes={{ root: classes.listItem, selected: classes.selected }}
            onClick={() => setIsNestedOpen(!isNestedOpen)}>
            <ListItemText
              primary={<p className={classes.itemText}>Company Account</p>}
            />
            {isNestedOpen ? (
              <ExpandLess className={classes.white} />
            ) : (
              <ExpandMore className={classes.white} />
            )}
          </ListItem>

          <Collapse in={isNestedOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {permissions?.editAccountDetail && (
                <ListItem
                  button
                  classes={{
                    root: classes.nestedListItem,
                    selected: classes.selected
                  }}
                  selected={pathname.includes(
                    '/company-account/company-details'
                  )}
                  onClick={() => redirectFunction({ name: 'company-details' })}>
                  <ListItemText
                    primary={
                      <p className={classes.itemText}>Company Details</p>
                    }
                  />
                </ListItem>
              )}

              {hasCompletedOrdersOrPostedJobs &&
                permissions?.editCompanyProfile && (
                  <ListItem
                    button
                    classes={{
                      root: classes.nestedListItem,
                      selected: classes.selected
                    }}
                    selected={pathname.includes(
                      '/company-account/company-canvas'
                    )}
                    onClick={() =>
                      redirectFunction({ name: 'company-canvas' })
                    }>
                    <ListItemText
                      primary={
                        <p className={classes.itemText}>Company Canvas</p>
                      }
                    />
                  </ListItem>
                )}
              {isCompanyApproved &&
                isMasterAccount &&
                hasCompletedOrdersOrPostedJobs &&
                ctmAccess && (
                  <ListItem
                    button
                    selected={pathname.includes(
                      '/company-account/manage-team-members'
                    )}
                    classes={{
                      root: classes.nestedListItem,
                      selected: classes.selected
                    }}
                    onClick={() =>
                      redirectFunction({ name: 'manage-team-members' })
                    }>
                    <ListItemText
                      primary={
                        <p className={classes.itemText}>Manage Team Members</p>
                      }
                    />
                  </ListItem>
                )}
              {isMasterAccount && affiliatedCompanyAccess && (
                <ListItem
                  button
                  classes={{
                    root: classes.nestedListItem,
                    selected: classes.selected
                  }}
                  selected={pathname.includes(
                    '/company-account/manage-affiliates'
                  )}
                  onClick={() =>
                    redirectFunction({ name: 'manage-affiliates' })
                  }>
                  <ListItemText
                    primary={
                      <p className={classes.itemText}>
                        Manage Company Affiliations
                      </p>
                    }
                  />
                </ListItem>
              )}

              <ListItem
                button
                classes={{
                  root: classes.nestedListItem,
                  selected: classes.selected
                }}
                selected={pathname.includes('/order-history')}
                onClick={() => redirectFunction({ name: 'order-history' })}>
                <ListItemText
                  primary={<p className={classes.itemText}>Order History</p>}
                />
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            button
            classes={{ root: classes.listItem, selected: classes.selected }}
            selected={pathname.includes('/my-account')}
            onClick={() => redirectFunction({ name: 'my-account' })}>
            <ListItemText
              primary={<p className={classes.itemText}>My Account</p>}
            />
          </ListItem>

          <ListItem
            classes={{ root: classes.listItem, selected: classes.selected }}
            button
            onClick={() => openInNewTab(JOBSEEKER_URL[dropdownCountry])}>
            <ListItemText
              primary={<p className={classes.itemText}>Go to Main Website</p>}
            />
          </ListItem>
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: isDrawerOpen
        })}
        style={{
          width: `${
            isDrawerOpen && minus300
              ? '100%'
              : origin == 'ats'
              ? 'auto'
              : `calc(100%  - 300px)`
          }`
        }}>
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  );
}
