import React from 'react';
import { StepContainer, StepperContainer } from './styles';

export default function Stepper({ className, list, currentStep }) {
  return (
    <StepperContainer className={className}>
      {list.map((step, index) => (
        <StepContainer key={step}>
          <div className={`stepCircle ${currentStep >= index ? 'active' : ''}`}>
            {index + 1}
          </div>
          {index > 0 && <div className="stepperLineBefore" />}
          {index < list.length - 1 && <div className="stepperLineAfter" />}
          <div className="stepLabel">
            {step === 'Company Details (Optional)' ? (
              <p style={{ margin: "0px"}}>
                Company Details{' '}
                <span style={{ fontStyle: 'italic' }}>(Optional)</span>{' '}
              </p>
            ) : (
              step
            )}
          </div>
        </StepContainer>
      ))}
    </StepperContainer>
  );
}
