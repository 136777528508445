//Please keep it here, Wai Hoo will come back and improve this

// import React from 'react';
// import TooltipEntity from '../TooltipEntity/index';

// const Link = ({
//   entityKey,
//   contentState,
//   children,
//   onEditEntity,
//   onRemove,
//   editorRef
// }) => {
//   const { url, linkType } = contentState.getEntity(entityKey).getData();
//   const isEmailLink = linkType === 'email' || url.startsWith('mailto:');
//   const icon = `#icon-${isEmailLink ? 'mail' : 'link'}`;
//   const label = url.replace(/(^\w+:|^)\/\//, '').split('/')[0];

//   console.log('blabla', editorRef);

//   return (
//     <TooltipEntity
//       entityKey={entityKey}
//       contentState={contentState}
//       onEdit={onEditEntity}
//       onRemove={onRemove}
//       icon={icon}
//       label={label}>
//       {children}
//     </TooltipEntity>
//   );
// };

// export default Link;

import React from 'react';
// import TooltipEntity from '../TooltipEntity/index';
import { Tooltip } from '@mui/material';
import styles from './index.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

const Link = ({ entityKey, contentState, children }) => {
  const { url } = contentState.getEntity(entityKey).getData();

  return (
    <a href={url} className={styles.linkDecoration}>
      <Tooltip
        title={<span className={styles.tooltipText}>Link</span>}
        placement="bottom">
        <span>
          <FontAwesomeIcon icon={faLink} className={styles.iconStyle} />
        </span>
      </Tooltip>
      {children}
    </a>
  );
};

export default Link;
