import { styled as styling } from '@mui/material/styles';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import Button from '@mui/material/Button';

export const MainContainer = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  gap: '24px',
  minHeight: props.isPopUp? '400px' : '540px'
}));

export const MainContainerBox = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: '8px',
  border: '1px solid #D8D8D8'
}));

export const BottomBox = styling('div')(props => ({
  display: 'flex',
  padding: props.isPopUp ? '0px' : '16px 0px',
  justifyContent: props.isPopUp ? 'center' : 'flex-end',
  alignItems: 'center',
  alignSelf: 'stretch'
}));

export const BottomActionBox = styling('div')(props => ({
  display: 'flex',
  width: props.isPopUp ? '100%' : '328px',
  justifyContent: props.isPopUp ? 'center' : 'flex-end',
  alignItems: 'center',
  gap: '8px'
}));

export const SkipButton = styling('div')(props => ({
  display: 'flex',
  width: props.isPopUp? '200px' : '120px',
  height: '42px',
  padding: '0px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: 'normal',
  cursor: 'pointer'
}));

export const SaveButton = styling(Button)(props => ({
  display: 'flex',
  width: '200px',
  height: '42px',
  padding: '0px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  background: props.disabled ? '#D8D8D8' : '#353535',

  gap: '10px',
  color: '#FFF',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: 'normal',
  cursor: 'pointer',
  textTransform: 'none',

  ':disabled': {
    cursor: 'not-allowed',
    color: '#FFF',
    ':hover': {
      background: '#D8D8D8'
    },
  },

  ':hover': {
    background: '#222222',
  }
}));

export const ChevronDownIconStyled = styling(ChevronDownIcon)(props => ({
  width: '20px',
  height: '20px',
  transition: 'transform 0.3s ease', 
  transform: props.isExpanded ? 'rotate(180deg)' : 'none' 
}));

export const ExpandedContent = styling('div')(props => ({
    maxHeight: props.isExpanded ? '1000px' : '0',
    overflow: 'hidden',
    transition: 'max-height 0.8s ease',
    maxWidth: '671px'
}));

export const FormSelectContainer = styling('div')(props => ({
    padding: '0px',
    width: '100%'
}));

export const DialogTitleStyled = styling(DialogTitle)(props => ({
  padding: '16px 16px 16px 24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px'
}));

export const IconButtonStyled = styling(IconButton)(props => ({
  padding: '0'
}));

export const DialogContentStyled = styling(DialogContent)(props => ({
  padding: '24px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  maxWidth: '685px'
}));

export const DialogNoteStyled = styling(DialogContent)(props => ({
  padding: '0px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));