import * as types from '../types/login_type';
import api from '../../utils/api';
const { REACT_APP_API_VERSION } = process.env;

const resetPassword =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.RESET_PASSWORD
    });

    const payload = {
      query: `mutation {
        resetPassword(
          input: {token: ${JSON.stringify(
            params.token
          )}, userType: ${JSON.stringify(
        params.userType
      )}, password: ${JSON.stringify(params.pass)}}
        ) {
          success
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          const success = response?.data?.data?.resetPassword.success;
          if (success) {
            return dispatch({
              type: types.RESET_PASSWORD_SUCCEED,
              success: success
            });
          } else {
            return dispatch({
              type: types.RESET_PASSWORD_FAILED
            });
          }
        }
        return dispatch({
          type: types.RESET_PASSWORD_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.RESET_PASSWORD_FAILED
        });
      });
  };

export { resetPassword };
