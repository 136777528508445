// @flow
import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { FixedSizeList, areEqual } from 'react-window';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import SearchIcon from '@mui/icons-material/Search';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import TuneIcon from '@mui/icons-material/Tune';
import './style.css';
import getInitialData from './get-initial-data';
import { useParams, useLocation } from 'react-router-dom';
import DefaultLayout from '../../../layouts/DefaultLayout';
import JobDetails from '../JobDetails/index';
import { reorderList } from './reorder';
import { InfoToast } from '../../../utils/ToastUtils';
import { debounce } from 'lodash';
import {
  BoardContainer,
  ApplicantsContainer,
  ApplicantHeader,
  StateTitle,
  ApplicantCountContainer,
  MoreHorizIconStyled,
  ApplicantCard,
  ApplicantCardHeader,
  ApplicantCardMoreHorizIcon,
  ApplicantCardNameContainer,
  ApplicantAvatar,
  ApplicantName,
  ApplicantDescription,
  ApplicantDateStatusContainer,
  ClockIconStyled,
  ApplicationDate,
  ApplicationStatus,
  ApplicationUpdatingSpinnerContainer,
  MenuItemStyled,
  StatusText,
  MenuStyled,
  Divider,
  ApplicantDescriptionTruncation,
  ApplicantNameTruncation,
  Tag,
  TextContainer,
  Text,
  SelectAllCountContainer,
  SelectAllContainer,
  ToastNewStatus,
  ToastPreviousState,
  ButtonStyled,
  CloseIconStyled,
  JobDetailsContainer,
  FontAwesomeIconStyled,
  InterviewLengthContainer
} from './styles.js';
import {
  getUndecidedApplications,
  getKivApplications,
  getShortlistedApplications,
  getRejectedApplications,
  getInterviews,
  getOffers,
  updateUndecidedApplications,
  updateKivApplications,
  updateShortlistedApplications,
  updateRejectedApplications,
  changeJobApplicationState,
  updateApplicationCount,
  updateAllApplications,
  updateCandidateSnapshotStatus,
  updateCurrentCandidateIndex,
  updateApplicationSelectAllStatus,
  changeMultipleJobApplicationState,
  viewApplication,
  updateInterviews,
  updateOffers,
  updateFirstScheduleInterviewModalStatus,
  updateNextScheduleInterviewModalStatus,
  updateDraggingState,
  toggleRejectPopup,
  triggerBulkRejectPopup,
  getCurrentState
} from '../../../redux/actions/application_action';
import { Spinner } from '../../../components';
import AutoSizer from 'react-virtualized-auto-sizer';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as types from '../../../redux/types/application_type';
import store from '../../../redux/stores/store';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircle,
  faChevronLeft,
  faLongArrowAltRight,
  faVideo,
  faTimes,
  faCalendarAlt
} from '@fortawesome/free-solid-svg-icons';
import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons';
import { Checkbox, Tooltip, IconButton, InputAdornment, Grid } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import CandidateSnapshot from '../../../components/CandidateSnapshot';
import AdvancedFilter from '../../ManageCandidates/List/ToolBar/AdvancedFilter';
import CandidateSort from '../List/CandidatesColumn/CandidateSort';
import { Skeleton } from '@mui/material';
import BlackListPopUp from './BlackListPopUp';
import OfferJobPopUp from '../../../pages/Jobs/PopUps/OfferJobPopUp';
import ScheduleInterviewModal from '../Modals/ScheduleInterview/Board';
import ScheduleNextInterviewModal from '../Modals/ScheduleInterview/List';
import BulkActionsPopUp from '../../Jobs/PopUps/BulkActionsPopUp';
import { ReactComponent as AiviIcon } from '../../../assets/common/aivi-icon.svg';
import Lottie from 'react-lottie';
import hiredlySpinner from '../../../assets/lottie/hiredly_spinner.json';
import StatusFilter from '../StatusFilter';
import Linkify from 'react-linkify';
import RejectPopUp from '../../Jobs/PopUps/RejectPopUp';
import BulkRejectPopUp from '../../Jobs/PopUps/BulkRejectPopUp';
import { useNavigate } from 'react-router-dom';
import { fetchJob } from '../../../redux/actions/job_action';
import { fetchStateRegions } from '../../../redux/actions/company_actions';
import {
  getAllCountryStates,
  getCountry,
  getNationality
} from '../../../hooks/useRegionalisation.js';
import { NATIONALITY_OPTIONS } from '../../../components/SharedAdvancedFilter/constants.js';
import SharedTextField from '../../../components/SharedTextField/index.js';

let useColumnSort = false; // to control which sorting to use, advance sort or column sort
let useColumnUnview = false; // to control which unview status to use, advance filter or column filter
let changingStateCount = false;
let draggingStart = false;
let selectedApplications = {
  undecided: {},
  kiv: {},
  shortlisted: {},
  rejected: {}
};
let loadingApplications = {
  undecided: false,
  kiv: false,
  shortlisted: false,
  rejected: false,
  interview: false,
  offer: false
};
const draggingStates = { kiv: 1, shortlisted: 1, interview: 1 };

const convertSelectAllStatus = (state, status) => {
  const currentSelectAllStatus =
    store.getState().applications.selectAllStatus[state];
  let convertedStatus = status;

  if (status == false) {
    if (currentSelectAllStatus == false) {
      convertedStatus = 'false';
    }
  } else if (status == true) {
    if (currentSelectAllStatus == true) {
      convertedStatus = 'true';
    }
  }

  return convertedStatus;
};

const getStateArray = (title, withBlacklist) => {
  let stateArray = ['undecided', 'kiv', 'shortlisted', 'rejected'];

  if (withBlacklist) {
    stateArray.push('blacklisted');
  }

  var index = stateArray.indexOf(title);

  if (index !== -1) {
    stateArray.splice(index, 1);
  }

  return stateArray;
};

/*
 * Board - main component
 */
const Board = props => {
  const minWidth = 255;
  const maxWitdh = 500;
  const cLocation = useLocation();
  const query = new URLSearchParams(cLocation.search);
  const isLocalOnly = cLocation.search.includes(
    `?${getNationality().toLowerCase()}=true`
  );
  const history = useNavigate();
  const {
    isCandidateSnapshotOpen,
    isFirstInterviewModalOpened,
    isNextInterviewModalOpened
  } = props;
  // const classes = useStyles();
  const { id } = useParams();
  const [state, setState] = useState(() => getInitialData());
  const [jobData, setJobData] = useState(null);
  const [blackListState, setBlackListState] = useState({
    open: false,
    loading: false
  });
  const [columnSortDirections, setColumnSortDirections] = useState({
    undecided: 'desc',
    kiv: 'desc',
    shortlisted: 'desc',
    rejected: 'desc',
    interview: 'desc',
    offer: 'desc',
    currentState: ''
  });
  const [showOnlyUnviewForUndecided, setShowOnlyUnviewForUndecided] =
    useState(null);
  const [columnWidth, setColumnWidth] = useState(minWidth + maxWitdh / 2);
  const [applicationStatus, setApplicationStatus] = useState('all');
  const [searchType, setSearchType] = useState('any');
  const [advancedFilter, setAdvancedFilter] = useState({
    yearsOfExp: 0,
    videoCoverLetter: false,
    nationalities: '',
    currentLocation: [],
    relocate: false,
    remote: false,
    openToSponsor: false,
    visaOrEmploymentPass: false,
    localCitizen: false,
    highestEduLevel: [],
    trackIds: [],
    hideViewed: false,
    sortDirection: 'desc',
    searchText: '',
    searchType: 'any',
    doneGettingJob: false,
    chatbotStatus: ''
  });
  const [showBanner, setShowBanner] = useState(true);

  const statusFilter = [
    {
      title: `applicants`,
      count: `${jobData?.totalCount}`
    },
    {
      title: `undecided`,
      count: `${jobData?.undecidedCount}`
    },
    {
      title: `shortlisted`,
      count: `${jobData?.shortlistedCount}`
    },
    { title: `kiv`, count: `${jobData?.kivCount}` },
    { title: `interview`, count: `${jobData?.interviewCount}` },
    { title: `offered`, count: `${jobData?.offerCount}` }
  ];

  const candidateSnapshotRef = useRef();

  const [isOfferJobOpen, setIsOfferJobOpen] = useState(false);
  const [isBulkActionsOpen, setIsBulkActionsOpen] = useState(false);

  const handleOfferJobClose = () => {
    setIsOfferJobOpen(false);
  };

  const handleBulkActionsPopUpClose = () => {
    setIsBulkActionsOpen(false);
  };

  useEffect(() => {
    dispatch(
      fetchJob({
        id: id
      })
    );
    dispatch(fetchStateRegions());
  }, []);

  const job = useSelector(state => state.jobs.jobData);
  const fetchingJob = useSelector(state => state.jobs.fetchingJob);
  const allCountryStateRegions = getAllCountryStates();

  const performSearch = event => {
    setAdvancedFilter({
      ...advancedFilter,
      searchText: event.target.value
    });
  };

  const debouncedSearch = debounce(performSearch, 700);

  const hasFilterApplied = () => {
    if (!advancedFilter.doneGettingJob) return false;

    if (advancedFilter.searchText != '') {
      return true;
    }

    if (advancedFilter.hideViewed) {
      return true;
    }

    if (
      advancedFilter.yearsOfExp !== 0 ||
      advancedFilter.videoCoverLetter !== false ||
      advancedFilter.highestEduLevel.length > 0 ||
      advancedFilter.trackIds.length > 0
    ) {
      return true;
    }

    if (
      jobData &&
      (advancedFilter.nationalities == getNationality()) !== jobData.localOnly
    ) {
      return true;
    }

    return false;
  };

  const changeMultipleApplicationState = (
    applicationIds,
    applications,
    currentState,
    nextState
  ) => {
    let nextApplications = [];
    let numOfApplications = applicationIds.length;

    if (nextState === 'undecided') {
      nextApplications = [
        ...store.getState().applications.undecidedApplications
      ];
    } else if (nextState === 'kiv') {
      nextApplications = [...store.getState().applications.kivApplications];
    } else if (nextState === 'shortlisted') {
      nextApplications = [
        ...store.getState().applications.shortlistedApplications
      ];
    } else if (nextState === 'rejected') {
      nextApplications = [
        ...store.getState().applications.rejectedApplications
      ];
    }

    // Position shift from current state to next state
    applicationIds.map(id => {
      const currentIndex = applications.findIndex(
        application => application.id == id
      );

      if (currentIndex != -1) {
        // Add to next state list
        let item = { ...applications[currentIndex] };
        item.state = nextState;
        nextApplications.splice(0, 0, item);

        // Remove from current List
        applications.splice(currentIndex, 1);
      }
    });

    // Update source redux param accordingly
    if (currentState === 'undecided') {
      props.updateUndecidedApplications(applications);
    } else if (currentState === 'kiv') {
      props.updateKivApplications(applications);
    } else if (currentState === 'shortlisted') {
      props.updateShortlistedApplications(applications);
    } else if (currentState === 'rejected') {
      props.updateRejectedApplications(applications);
    }

    // Minus applicationIds.length from current state
    if (
      store.getState().applications.applicationCount[currentState] != undefined
    ) {
      store.getState().applications.applicationCount[currentState] -=
        numOfApplications;
    }

    // Update destination redux param accordingly
    if (nextState === 'undecided') {
      props.updateUndecidedApplications(nextApplications);
    } else if (nextState === 'kiv') {
      props.updateKivApplications(nextApplications);
    } else if (nextState === 'shortlisted') {
      props.updateShortlistedApplications(nextApplications);
    } else if (nextState === 'rejected') {
      props.updateRejectedApplications(nextApplications);
    }

    // Add applicationIds.length to destination
    if (
      store.getState().applications.applicationCount[nextState] != undefined
    ) {
      store.getState().applications.applicationCount[nextState] +=
        numOfApplications;
    }

    // Trigger graphQL to update multiple applications state
    let params = {
      applicationIds: applicationIds,
      state: nextState
    };
    props.changeMultipleJobApplicationState(params).then(res => {
      if (res.type === types.UPDATE_MULTIPLE_APPLICATION_STATE_SUCCEED) {
        props.updateApplicationCount(
          store.getState().applications.applicationCount
        );

        if (applications.length <= 6) {
          if (currentState === 'undecided') {
            loadingUndecidedApplications(false, false);
          } else if (currentState === 'kiv') {
            loadingKivApplications(false, false);
          } else if (currentState === 'shortlisted') {
            loadingShortlistedApplications(false, false);
          } else if (currentState === 'rejected') {
            loadingRejectedApplications(false, false);
          }
        }

        InfoToast(
          // `${numOfApplications} candidates have been moved from ${currentState} board to ${nextState} board`
          multipleStatusMessage(numOfApplications, currentState, nextState)
        );
      }
    });
  };

  const multipleStatusMessage =
    (numOfApplications, currentState, nextState) => () => {
      return (
        <div>
          <p
            style={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 'bold',
              margin: '4px 0'
            }}>
            Bulk Actions
          </p>
          <p style={{ margin: '0', fontFamily: 'Inter', fontSize: '12px' }}>
            <b>{numOfApplications}</b> candidates have been moved from{' '}
            <ToastPreviousState status={currentState}>
              {currentState}
            </ToastPreviousState>{' '}
            to
            {'\n'}
            <ToastNewStatus status={nextState}>{nextState}</ToastNewStatus>
          </p>
        </div>
      );
    };

  const loadNextBatchApplications = () => {
    // Whenever change state done, check whether need to load more applications
    if (store.getState().applications.undecidedApplications.length <= 3) {
      loadingUndecidedApplications(false, false);
    }

    if (store.getState().applications.kivApplications.length <= 3) {
      loadingKivApplications(false, false);
    }

    if (store.getState().applications.shortlistedApplications.length <= 3) {
      loadingShortlistedApplications(false, false);
    }

    if (store.getState().applications.rejectedApplications.length <= 3) {
      loadingRejectedApplications(false, false);
    }
  };

  const getUpdatedApplicantCount = (currentState, newState, jobData) => {
    let cJobData = { ...jobData };

    switch (currentState) {
      case 'undecided':
        {
          cJobData.undecidedCount -= 1;
        }
        break;
      case 'shortlisted':
        {
          cJobData.shortlistedCount -= 1;
        }
        break;
      case 'kiv':
        {
          cJobData.kivCount -= 1;
        }
        break;
      case 'rejected':
        {
          cJobData.rejectedCount -= 1;
        }
        break;
    }

    switch (newState) {
      case 'undecided':
        {
          cJobData.undecidedCount += 1;
        }
        break;
      case 'shortlisted':
        {
          cJobData.shortlistedCount += 1;
        }
        break;
      case 'kiv':
        {
          cJobData.kivCount += 1;
        }
        break;
      case 'rejected':
        {
          cJobData.rejectedCount += 1;
        }
        break;
    }

    return cJobData;
  };

  const changeApplicationState = (
    currentState,
    nextState,
    sourceIndex,
    destinationIndex,
    reason,
    updateSnapshot,
    applicationId,
    rejectReason,
    preventReject = true
  ) => {
    changingStateCount += 1;

    if (nextState === 'blacklisted') {
      setBlackListState({ ...blackListState, loading: true, open: true });
    }

    let sourceColumn;
    let destinationColumn;

    if (currentState === 'undecided') {
      sourceColumn = store.getState().applications.undecidedApplications;
    } else if (currentState === 'kiv') {
      sourceColumn = store.getState().applications.kivApplications;
    } else if (currentState === 'shortlisted') {
      sourceColumn = store.getState().applications.shortlistedApplications;
    } else if (currentState === 'rejected') {
      sourceColumn = store.getState().applications.rejectedApplications;
    } else if (currentState === 'interview') {
      sourceColumn = store.getState().applications.interviews;
    }

    if (sourceIndex == -1 && applicationId) {
      sourceIndex = sourceColumn.findIndex(cItem => {
        const cApplication = cItem.jobApplication
          ? cItem.jobApplication
          : cItem;

        return cApplication.id == applicationId;
      });
    }

    // Drag and dropped to interview from other state (except offer)
    if (nextState === 'interview') {
      // Next state is interview
      // Open schedule interview modal
      // Update all applications param to simulate candidate snapshot but with only one item in the array
      // as popup modal is accessing allApplications param for candidate info
      const currentItem = sourceColumn[sourceIndex];
      let allApplications = [currentItem];
      props.updateAllApplications(allApplications).then(() => {
        props.updateCurrentCandidateIndex(0).then(() => {
          if (currentItem.interviews?.length === 0) {
            props.updateFirstScheduleInterviewModalStatus(true, null);
          } else if (currentItem.interviews?.length > 0) {
            props.updateNextScheduleInterviewModalStatus(true);
          }
        });
      });

      return;
    } else if (nextState === 'offer') {
      // Next state is offer
      // Open make offer modal
      // Update all applications param to simulate candidate snapshot but with only current item in the array
      // as popup modal is accessing allApplications param for candidate info
      const currentItem = sourceColumn[sourceIndex];

      let allApplications = [currentItem];
      props.updateAllApplications(allApplications).then(() => {
        props.updateCurrentCandidateIndex(0).then(() => {
          setIsOfferJobOpen(true);
        });
      });

      return;
    } else if (preventReject && nextState == 'rejected') {
      // Current state is interview
      // Next state is rejected
      // Open rejected modal
      // Update all applications param to simulate candidate snapshot but with only current item in the array
      // as popup modal is accessing allApplications param for candidate info
      const currentItem = sourceColumn[sourceIndex];

      let allApplications = [currentItem];

      props.updateAllApplications(allApplications).then(() => {
        props.updateCurrentCandidateIndex(0).then(() => {
          dispatch(toggleRejectPopup(true));
        });
      });

      if (!isCandidateSnapshotOpen) {
        return;
      }
    }

    if (nextState === 'undecided') {
      destinationColumn = store.getState().applications.undecidedApplications;
    } else if (nextState === 'kiv') {
      destinationColumn = store.getState().applications.kivApplications;
    } else if (nextState === 'shortlisted') {
      destinationColumn = store.getState().applications.shortlistedApplications;
    } else if (nextState === 'rejected') {
      destinationColumn = store.getState().applications.rejectedApplications;
    } else if (nextState === 'interview') {
      destinationColumn = store.getState().applications.interviews;
    }

    const item = sourceColumn[sourceIndex];

    const previousState = item.state;

    // Change application state based on destination (except next state is interview )
    item.state = nextState === 'interview' ? item.state : nextState;

    if (updateSnapshot) {
      // Update snapshot data
      let snapshotApplications = [
        ...store.getState().applications.allApplications
      ];

      const snapshotIndex = snapshotApplications.findIndex(
        snapshotApplication => snapshotApplication.id === item.id
      );

      if (snapshotIndex != -1) {
        snapshotApplications[snapshotIndex] = { ...item };

        props.updateAllApplications(snapshotApplications);
      }
    }

    // 1. remove item from source column
    sourceColumn.splice(sourceIndex, 1);

    // 2. insert into destination column
    if (nextState !== 'blacklisted') {
      let currentItem;

      if (nextState === 'interview') {
        // If interview then create different object structure
        currentItem = { jobApplication: { ...item } };
      } else {
        currentItem = { ...item };
      }
      destinationColumn.splice(destinationIndex, 0, currentItem);
    }

    // Update source redux param accordingly
    if (currentState === 'undecided') {
      props.updateUndecidedApplications(sourceColumn);
    } else if (currentState === 'kiv') {
      props.updateKivApplications(sourceColumn);
    } else if (currentState === 'shortlisted') {
      props.updateShortlistedApplications(sourceColumn);
    } else if (currentState === 'rejected') {
      props.updateRejectedApplications(sourceColumn);
    } else if (currentState === 'interview') {
      props.updateInterviews(sourceColumn);
    }

    // Minus 1 from source
    // Straight away access redux param due to concurrency issue
    if (
      store.getState().applications.applicationCount[currentState] != undefined
    ) {
      store.getState().applications.applicationCount[currentState] -= 1;
    }

    // Update destination redux param accordingly
    if (nextState === 'undecided') {
      props.updateUndecidedApplications(destinationColumn);
    } else if (nextState === 'kiv') {
      props.updateKivApplications(destinationColumn);
    } else if (nextState === 'shortlisted') {
      props.updateShortlistedApplications(destinationColumn);
    } else if (nextState === 'rejected') {
      props.updateRejectedApplications(destinationColumn);
    } else if (nextState === 'interview') {
      props.updateInterviews(destinationColumn);
    }

    // Add 1 to destination
    if (
      store.getState().applications.applicationCount[nextState] != undefined &&
      nextState !== 'blacklisted'
    ) {
      store.getState().applications.applicationCount[nextState] += 1;
    }

    // Call mutation to update job application state
    let changeStateParams = {
      applicationId: item?.jobApplication ? item?.jobApplication?.id : item.id,
      state: nextState,
      rejectReason: rejectReason ? rejectReason : null
    };

    if (nextState === 'blacklisted') {
      changeStateParams.reason = reason;
    }

    props.changeJobApplicationState(changeStateParams).then(res => {
      changingStateCount -= 1;

      loadNextBatchApplications();

      if (res.type === types.UPDATE_APPLICATION_STATE_SUCCEED) {
        props.updateApplicationCount(
          store.getState().applications.applicationCount
        );

        const cJobData = getUpdatedApplicantCount(
          currentState,
          nextState,
          jobData
        );

        setJobData(cJobData);

        InfoToast(
          // `${item.user?.name} has been moved from ${previousState} to ${changeStateParams.state}`
          singleStatusMessage(item, previousState, changeStateParams)
        );

        if (toggleRejectPopup) {
          dispatch(toggleRejectPopup(false));
        }
      }

      if (nextState === 'blacklisted') {
        setBlackListState({ open: false, loading: false });
      }
    });
  };

  const singleStatusMessage =
    (item, previousState, changeStateParams) => () => {
      return (
        <div>
          <p
            style={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 'bold',
              margin: '4px 0'
            }}>
            Success: Updated applicant status.
          </p>
          <p style={{ margin: '0', fontFamily: 'Inter', fontSize: '12px' }}>
            <b>{item.user?.name}</b> has been moved from{' '}
            <ToastPreviousState status={previousState}>
              {previousState}
            </ToastPreviousState>{' '}
            to
            {'\n'}
            <ToastNewStatus status={changeStateParams.state}>
              {changeStateParams.state}
            </ToastNewStatus>
          </p>
        </div>
      );
    };

  const movingBetweenList = result => {
    changeApplicationState(
      result.source.droppableId,
      result.destination.droppableId,
      result.source.index,
      result.destination.index,
      '',
      false,
      -1,
      true
    );
  };

  function onDragStart(result) {
    draggingStart = true;

    if (draggingStates[result.source.droppableId]) {
      props.updateDraggingState(result.source.droppableId);
    }
  }

  function onDragEnd(result) {
    draggingStart = false;

    if (draggingStates[result.source.droppableId]) {
      props.updateDraggingState('');
    }

    if (!result.destination) {
      return;
    }

    // reordering in same list
    if (result.source.droppableId === result.destination.droppableId) {
      if (result.source.index === result.destination.index) return; // Same ordering

      if (result.source.droppableId === 'undecided') {
        const itemList = store.getState().applications.undecidedApplications;
        const orderedItemList = reorderList(
          itemList,
          result.source.index,
          result.destination.index
        );

        props.updateUndecidedApplications(orderedItemList);
      } else if (result.source.droppableId === 'kiv') {
        const itemList = store.getState().applications.kivApplications;
        const orderedItemList = reorderList(
          itemList,
          result.source.index,
          result.destination.index
        );

        props.updateKivApplications(orderedItemList);
      } else if (result.source.droppableId === 'shortlisted') {
        const itemList = store.getState().applications.shortlistedApplications;
        const orderedItemList = reorderList(
          itemList,
          result.source.index,
          result.destination.index
        );

        props.updateShortlistedApplications(orderedItemList);
      } else if (result.source.droppableId === 'rejected') {
        const itemList = store.getState().applications.rejectedApplications;
        const orderedItemList = reorderList(
          itemList,
          result.source.index,
          result.destination.index
        );

        props.updateRejectedApplications(orderedItemList);
      }

      return;
    }

    movingBetweenList(result);
  }

  const loadingUndecidedApplications = (refresh, reformApplication) => {
    if (
      loadingApplications.undecided ||
      (!refresh && store.getState().applications.undecidedReachedEnd)
    )
      return;

    loadingApplications.undecided = true;

    const undecidedParams = {
      canCancel: true,
      apiTimeout: 9000,
      cancelTokenKey: 'undecided_application',
      jobId: id,
      first: 10,
      search: advancedFilter.searchText,
      searchType: 'any',
      sort: {
        by: 'createdAt',
        direction: useColumnSort
          ? columnSortDirections.undecided
          : advancedFilter.sortDirection
      },
      showState: 'undecided',
      yearsOfExperience: advancedFilter.yearsOfExp,
      withVideoCoverLetter: advancedFilter?.videoCoverLetter,
      highestEducationLevels: advancedFilter.highestEduLevel,
      currentLocation: advancedFilter.currentLocation,
      nationalities: advancedFilter.nationalities,
      relocate: advancedFilter.relocate,
      remote: advancedFilter.remote,
      openToSponsor: advancedFilter.openToSponsor,
      visaOrEmploymentPass: advancedFilter.visaOrEmploymentPass,
      localCitizen: advancedFilter.localCitizen,
      trackIds: advancedFilter.trackIds,
      offset: refresh
        ? 0
        : store.getState().applications.undecidedApplications.length,
      refresh: refresh,
      hideViewed: useColumnUnview
        ? showOnlyUnviewForUndecided
        : advancedFilter.hideViewed,
      chatbotStatus: advancedFilter?.chatbotStatus
    };

    props.getUndecidedApplications(undecidedParams).then(res => {
      loadingApplications.undecided = false;
      // if (reformApplication) {
      //   reformAllApplications(null);
      // }
    });
  };

  const loadingKivApplications = (refresh, reformApplication) => {
    if (
      loadingApplications.kiv ||
      (!refresh && store.getState().applications.kivReachedEnd)
    )
      return;

    loadingApplications.kiv = true;

    const kivParams = {
      canCancel: true,
      apiTimeout: 9000,
      jobId: id,
      first: 10,
      search: advancedFilter.searchText,
      searchType: 'any',
      sort: {
        by: 'createdAt',
        direction: useColumnSort
          ? columnSortDirections.kiv
          : advancedFilter.sortDirection
      },
      showState: 'kiv',
      yearsOfExperience: advancedFilter.yearsOfExp,
      withVideoCoverLetter: advancedFilter?.videoCoverLetter,
      highestEducationLevels: advancedFilter.highestEduLevel,
      currentLocation: advancedFilter.currentLocation,
      nationalities: advancedFilter.nationalities,
      relocate: advancedFilter.relocate,
      remote: advancedFilter.remote,
      openToSponsor: advancedFilter.openToSponsor,
      visaOrEmploymentPass: advancedFilter.visaOrEmploymentPass,
      localCitizen: advancedFilter.localCitizen,
      trackIds: advancedFilter.trackIds,
      offset: refresh
        ? 0
        : store.getState().applications.kivApplications.length,
      refresh: refresh,
      hideViewed: false,
      chatbotStatus: advancedFilter?.chatbotStatus
    };

    props.getKivApplications(kivParams).then(res => {
      loadingApplications.kiv = false;
      // if (reformApplication) {
      //   reformAllApplications(null);
      // }
    });
  };

  const loadingShortlistedApplications = (refresh, reformApplication) => {
    if (
      loadingApplications.shortlisted ||
      (!refresh && store.getState().applications.shortlistedReachedEnd)
    )
      return;

    loadingApplications.shortlisted = true;

    const shortliestedParams = {
      canCancel: true,
      apiTimeout: 9000,
      jobId: id,
      first: 10,
      search: advancedFilter.searchText,
      searchType: 'any',
      sort: {
        by: 'createdAt',
        direction: useColumnSort
          ? columnSortDirections.shortlisted
          : advancedFilter.sortDirection
      },
      showState: 'shortlisted',
      yearsOfExperience: advancedFilter.yearsOfExp,
      withVideoCoverLetter: advancedFilter?.videoCoverLetter,
      highestEducationLevels: advancedFilter.highestEduLevel,
      currentLocation: advancedFilter.currentLocation,
      nationalities: advancedFilter.nationalities,
      relocate: advancedFilter.relocate,
      remote: advancedFilter.remote,
      openToSponsor: advancedFilter.openToSponsor,
      visaOrEmploymentPass: advancedFilter.visaOrEmploymentPass,
      localCitizen: advancedFilter.localCitizen,
      trackIds: advancedFilter.trackIds,
      offset: refresh
        ? 0
        : store.getState().applications.shortlistedApplications.length,
      refresh: refresh,
      hideViewed: false,
      chatbotStatus: advancedFilter?.chatbotStatus
    };

    props.getShortlistedApplications(shortliestedParams).then(res => {
      loadingApplications.shortlisted = false;
      // if (reformApplication) {
      //   reformAllApplications(null);
      // }
    });
  };

  const loadingRejectedApplications = (refresh, reformApplication) => {
    if (
      loadingApplications.rejected ||
      (!refresh && store.getState().applications.rejectedReachedEnd)
    )
      return;

    loadingApplications.rejected = true;

    const rejectedParams = {
      canCancel: true,
      apiTimeout: 9000,
      jobId: id,
      first: 10,
      search: advancedFilter.searchText,
      searchType: 'any',
      sort: {
        by: 'createdAt',
        direction: useColumnSort
          ? columnSortDirections.rejected
          : advancedFilter.sortDirection
      },
      showState: 'rejected',
      yearsOfExperience: advancedFilter.yearsOfExp,
      withVideoCoverLetter: advancedFilter?.videoCoverLetter,
      highestEducationLevels: advancedFilter.highestEduLevel,
      currentLocation: advancedFilter.currentLocation,
      nationalities: advancedFilter.nationalities,
      relocate: advancedFilter.relocate,
      remote: advancedFilter.remote,
      openToSponsor: advancedFilter.openToSponsor,
      visaOrEmploymentPass: advancedFilter.visaOrEmploymentPass,
      localCitizen: advancedFilter.localCitizen,
      trackIds: advancedFilter.trackIds,
      offset: refresh
        ? 0
        : store.getState().applications.rejectedApplications.length,
      refresh: refresh,
      hideViewed: false,
      chatbotStatus: advancedFilter?.chatbotStatus
    };

    props.getRejectedApplications(rejectedParams).then(res => {
      loadingApplications.rejected = false;
      // if (reformApplication) {
      //   reformAllApplications(null);
      // }
    });
  };

  const loadingInterviews = refresh => {
    if (
      loadingApplications.interview ||
      (!refresh && store.getState().applications.interviewReachedEnd)
    )
      return;

    loadingApplications.interview = true;

    const interviewParams = {
      canCancel: true,
      apiTimeout: 9000,
      jobId: id,
      first: 10,
      limit: 11,
      status: null,
      sort: {
        by: 'created_at',
        direction: useColumnSort
          ? columnSortDirections.rejected
          : advancedFilter.sortDirection
      },
      offset: refresh ? 0 : store.getState().applications.interviews.length,
      search: advancedFilter.searchText,
      yearsOfExperience: advancedFilter.yearsOfExp,
      withVideoCoverLetter: advancedFilter?.videoCoverLetter,
      highestEducationLevels: advancedFilter.highestEduLevel,
      currentLocation: advancedFilter.currentLocation,
      nationalities: advancedFilter.nationalities,
      relocate: advancedFilter.relocate,
      remote: advancedFilter.remote,
      openToSponsor: advancedFilter.openToSponsor,
      visaOrEmploymentPass: advancedFilter.visaOrEmploymentPass,
      localCitizen: advancedFilter.localCitizen,
      trackIds: advancedFilter.trackIds,
      endCursor: refresh
        ? ''
        : store.getState().applications.interviewEndCursor,
      refresh: refresh,
      hideViewed: advancedFilter.hideViewed,
      chatbotStatus: advancedFilter?.chatbotStatus
    };

    props.getInterviews(interviewParams).then(res => {
      loadingApplications.interview = false;
    });
  };

  const loadingOffers = refresh => {
    if (
      loadingApplications.offer ||
      (!refresh && store.getState().applications.offerReachedEnd)
    )
      return;

    loadingApplications.offer = true;

    const offerParams = {
      canCancel: true,
      apiTimeout: 9000,
      jobId: id,
      first: 10,
      limit: 11,
      status: 'offered',
      sort: {
        by: 'created_at',
        direction: useColumnSort
          ? columnSortDirections.rejected
          : advancedFilter.sortDirection
      },
      offset: refresh ? 0 : store.getState().applications.interviews.length,
      search: advancedFilter.searchText,
      yearsOfExperience: advancedFilter.yearsOfExp,
      withVideoCoverLetter: advancedFilter?.videoCoverLetter,
      highestEducationLevels: advancedFilter.highestEduLevel,
      currentLocation: advancedFilter.currentLocation,
      nationalities: advancedFilter.nationalities,
      relocate: advancedFilter.relocate,
      remote: advancedFilter.remote,
      openToSponsor: advancedFilter.openToSponsor,
      visaOrEmploymentPass: advancedFilter.visaOrEmploymentPass,
      localCitizen: advancedFilter.localCitizen,
      trackIds: advancedFilter.trackIds,
      endCursor: refresh
        ? ''
        : store.getState().applications.interviewEndCursor,
      refresh: refresh,
      hideViewed: advancedFilter.hideViewed,
      chatbotStatus: advancedFilter?.chatbotStatus
    };

    props.getOffers(offerParams).then(res => {
      loadingApplications.offer = false;
    });
  };

  const reformSnapshotApplication = (applicationId, state) => {
    let snapshotApplications = [];

    if (state === 'undecided') {
      snapshotApplications = [
        ...store.getState().applications.undecidedApplications
      ];
    } else if (state === 'kiv') {
      snapshotApplications = [...store.getState().applications.kivApplications];
    } else if (state === 'shortlisted') {
      snapshotApplications = [
        ...store.getState().applications.shortlistedApplications
      ];
    } else if (state === 'rejected') {
      snapshotApplications = [
        ...store.getState().applications.rejectedApplications
      ];
    } else if (state === 'interview') {
      snapshotApplications = [...store.getState().applications.interviews];
    } else if (state === 'offer') {
      snapshotApplications = [...store.getState().applications.offers];
    }

    let currentIndex = snapshotApplications.findIndex(
      item => item.id === applicationId
    );

    props.updateAllApplications(snapshotApplications).then(() => {
      props.updateCurrentCandidateIndex(currentIndex).then(() => {
        props.updateCandidateSnapshotStatus(true);
      });
    });
  };

  // Not using at the moment
  // const reformAllApplications = applicationId => {
  //   let joinedApplications = [
  //     ...store.getState().applications.undecidedApplications,
  //     ...store.getState().applications.kivApplications,
  //     ...store.getState().applications.shortlistedApplications,
  //     ...store.getState().applications.interviews,
  //     ...store.getState().applications.offers,
  //     ...store.getState().applications.rejectedApplications
  //   ];

  //   if (applicationId != null) {
  //     let currentIndex = joinedApplications.findIndex(
  //       item => item.id === applicationId
  //     );

  //     props.updateAllApplications(joinedApplications).then(() => {
  //       props.updateCurrentCandidateIndex(currentIndex).then(() => {
  //         props.updateCandidateSnapshotStatus(true);
  //       });
  //     });
  //   } else {
  //     props.updateAllApplications(joinedApplications).then(() => {
  //       if (candidateSnapshotRef?.current) {
  //         candidateSnapshotRef.current.nextCandidate();
  //       }
  //     });
  //   }
  // };

  const onApplicationClicked = (applicationId, state) => {
    reformSnapshotApplication(applicationId, state);
    //reformAllApplications(applicationId);
  };

  const onViewApplication = item => {
    // Not interview and offer
    if (item && item.state && !item.status) {
      let viewApplicationParams = {
        applicationId: item.id,
        state: item.state
      };
      props.viewApplication(viewApplicationParams);
    }
  };

  const renderCandidateSnapshot = companyData => {
    const isCandidateSnapshotOpen = useSelector(
      state => state.applications.isCandidateSnapshotOpen
    );

    if (!isCandidateSnapshotOpen) return null;

    return (
      <CandidateSnapshot
        ref={candidateSnapshotRef}
        companyData={companyData}
        isAts={true}
        loadingUndecidedApplications={loadingUndecidedApplications}
        loadingKivApplications={loadingKivApplications}
        loadingShortlistedApplications={loadingShortlistedApplications}
        loadingRejectedApplications={loadingRejectedApplications}
        changeApplicationState={changeApplicationState}
        setBlackListState={setBlackListState}
        blackListState={blackListState}
        onViewApplication={onViewApplication}
      />
    );
  };

  const updateSelectAllStatus = (state, status) => {
    props.updateApplicationSelectAllStatus(state, status);
  };

  const [advancedFilterPopover, setAdvancedFilterPopover] = useState(null);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);

  const handleAdvancedFilterClick = event => {
    setAdvancedFilterPopover(event.currentTarget);
  };

  const handleCandidateSortClick = event => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleCandidateSort = (event, index) => {
    let sort = 'LATEST';
    let eventName = 'ats_sort_applicants_all';

    switch (index) {
      case 1:
        sort = 'LATEST';
        eventName += '_newest';
        break;
      case 2:
        sort = 'EARLIEST';
        eventName += '_oldest';
        break;
    }

    dataLayer.push({
      event: eventName
    });

    setAdvancedFilter({
      ...advancedFilter,
      sortDirection: sort === 'EARLIEST' ? 'asc' : 'desc'
    });

    setColumnSortDirections({
      undecided: sort === 'EARLIEST' ? 'asc' : 'desc',
      kiv: sort === 'EARLIEST' ? 'asc' : 'desc',
      shortlisted: sort === 'EARLIEST' ? 'asc' : 'desc',
      rejected: sort === 'EARLIEST' ? 'asc' : 'desc',
      interview: sort === 'EARLIEST' ? 'asc' : 'desc',
      offer: sort === 'EARLIEST' ? 'asc' : 'desc'
    });

    setSortAnchorEl(null);
  };

  const settingColumnWidth = width => {
    setColumnWidth(width);
  };

  const debouncedSetColumnWidth = debounce(settingColumnWidth, 500);

  const windowOnResizeListener = () => {
    let columnWidth = Math.min(Math.floor((window.innerWidth - 160) / 4));

    columnWidth = Math.min(Math.max(columnWidth, minWidth), maxWitdh);

    debouncedSetColumnWidth(columnWidth);
  };

  const dispatch = useDispatch();

  const rejectPopupStatus = useSelector(
    state => state.applications.rejectPopupStatus
  );

  const showBulkRejectPopup = useSelector(
    state => state.applications.showBulkRejectPopup
  );

  useEffect(() => {
    // Loading all applications of different states
    if (advancedFilter.doneGettingJob) {
      if (
        columnSortDirections.undecided != advancedFilter.sortDirection ||
        columnSortDirections.kiv != advancedFilter.sortDirection ||
        columnSortDirections.shortlisted != advancedFilter.sortDirection ||
        columnSortDirections.rejected != advancedFilter.sortDirection ||
        columnSortDirections.interview != advancedFilter.sortDirection ||
        columnSortDirections.offer != advancedFilter.sortDirection
      ) {
        // Advance filter sort direction changed, column sort need to follow advance filter sort
        setColumnSortDirections({
          undecided: advancedFilter.sortDirection,
          kiv: advancedFilter.sortDirection,
          shortlisted: advancedFilter.sortDirection,
          rejected: advancedFilter.sortDirection,
          interview: advancedFilter.sortDirection,
          offer: advancedFilter.sortDirection,
          currentState: ''
        });
      }

      if (showOnlyUnviewForUndecided !== advancedFilter.hideViewed) {
        // Advance hide view changed, column view filter need to follow advance filter sort
        setShowOnlyUnviewForUndecided(advancedFilter.hideViewed);
      }

      useColumnSort = false; // Advance filter changed, not using column sort
      useColumnUnview = false;

      props.updateUndecidedApplications([]);
      props.updateKivApplications([]);
      props.updateShortlistedApplications([]);
      props.updateRejectedApplications([]);
      props.updateInterviews([]);
      props.updateOffers([]);

      loadingUndecidedApplications(true, false);
      loadingKivApplications(true, false);
      loadingShortlistedApplications(true, false);
      loadingRejectedApplications(true, false);
      loadingInterviews(true);
      loadingOffers(true);

      if (cLocation) {
        if (advancedFilter?.malaysians) {
          history({
            pathname: cLocation.pathname,
            search: '?malaysian=true'
          });
        } else {
          history({
            pathname: cLocation.pathname,
            search: ''
          });
        }
      }
    }
  }, [advancedFilter]);

  useEffect(() => {
    const currentState = columnSortDirections.currentState;
    if (currentState !== '') {
      useColumnSort = true; // Column sort changed, use column sort
      if (currentState === 'undecided') {
        props.updateUndecidedApplications([]);
        loadingUndecidedApplications(true, false);
      } else if (currentState === 'kiv') {
        props.updateKivApplications([]);
        loadingKivApplications(true, false);
      } else if (currentState === 'shortlisted') {
        props.updateShortlistedApplications([]);
        loadingShortlistedApplications(true, false);
      } else if (currentState === 'rejected') {
        props.updateRejectedApplications([]);
        loadingRejectedApplications(true, false);
      } else if (currentState === 'interview') {
        props.updateInterviews([]);
        loadingInterviews(true);
      } else if (currentState === 'offer') {
        props.updateOffers([]);
        loadingOffers(true);
      }
    }
  }, [columnSortDirections]);

  useEffect(() => {
    if (showOnlyUnviewForUndecided !== null) {
      useColumnUnview = true; // Column unview status changed, use column unview status

      props.updateUndecidedApplications([]);
      loadingUndecidedApplications(true, false);
    }
  }, [showOnlyUnviewForUndecided]);

  useEffect(() => {
    const fetchData = async () => {
      if (job) {
        setJobData(job);

        let count = {
          ...store.getState().applications.applicationCount,
          undecided: job.undecidedCount,
          kiv: job.kivCount,
          shortlisted: job.shortlistedCount,
          rejected: job.rejectedCount,
          interview: job.interviewCount,
          offer: job.offerCount
        };

        props.updateApplicationCount(count);

        setAdvancedFilter({
          ...advancedFilter,
          nationalities: isLocalOnly || job?.localOnly ? getNationality() : '',
          doneGettingJob: true
        });
      }
    };

    fetchData();
  }, [job]);

  useEffect(() => {
    window.addEventListener('resize', windowOnResizeListener);

    if (cLocation.state === undefined) {
      windowOnResizeListener();
    }

    return () => {
      window.removeEventListener('resize', windowOnResizeListener);
    };
  }, []);

  const closeBanner = () => {
    setShowBanner(false);
  };

  return (
    <DefaultLayout
      minusBetaBanner={true}
      noBackgroundColor={true}
      origin={'ats'}
      maxWidth={true}>
      {showBanner && (
        <div
          style={{
            backgroundColor: '#512acc',
            color: '#fff',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '15px',
            position: 'fixed',
            width: '75%',
            minWidth: '1000px',
            maxWidth: '1885px',
            zIndex: '1000',
            borderRadius: '30px',
            margin: '65px 50px',
            backgroundImage:
              'linear-gradient(to left, #512acc, #6d32c5 ,#983eba)'
          }}>
          <h6
            style={{
              fontSize: '12px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%',
              display: '-webkit-box',
              webkitLineClamp: '1',
              webkitBoxOrient: 'vertical',
              whiteSpace: 'break-spaces',
              padding: '0 5px'
            }}>
            Note that applicants will not be informed of their application
            status based on your decision, and their application status will
            only be shown as “Viewed” on their end.
          </h6>
          <IconButton
            style={{
              position: 'absolute',
              top: '8px',
              right: '15px',
              zIndex: '10'
            }}
            size="small">
            <CloseIconStyled onClick={closeBanner} />
          </IconButton>
        </div>
      )}

      <div
        style={{
          height: 'calc(100vh - 80px)',
          width: '100%',
          display: 'flex',
          flexFlow: 'column'
        }}>
        <JobDetailsContainer show_banner={showBanner}>
          <JobDetails
            jobData={jobData}
            loading={fetchingJob}
            toggleView={props.toggleView}
            isListView={props.isListView}
            isAts={true}
          />
        </JobDetailsContainer>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: `${showBanner ? '130px' : '90px'} 50px 0`,
            position: 'fixed',
            alignItems: 'center',
            // width: '100vw',
            zIndex: '99'
          }}>
          <div>
            <div>
              <SharedTextField
                placeholder="Search by keywords..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Grid
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '4px'
                        }}>
                        <TuneIcon onClick={handleAdvancedFilterClick} />
                        <SwapVertIcon onClick={handleCandidateSortClick} />
                      </Grid>
                    </InputAdornment>
                  )
                }}
                onChange={debouncedSearch}
              />
            </div>

            {jobData && (
              <AdvancedFilter
                anchorEl={advancedFilterPopover}
                setAnchorEl={setAdvancedFilterPopover}
                handleAdvancedFilterClick={handleAdvancedFilterClick}
                advancedFilter={advancedFilter}
                setAdvancedFilter={setAdvancedFilter}
                searchType={searchType}
                setSearchType={setSearchType}
                isAts={true}
                allCountryStateRegions={allCountryStateRegions}
              />
            )}

            <CandidateSort
              // sortBy={sortBy}
              sortAnchorEl={sortAnchorEl}
              setSortAnchorEl={setSortAnchorEl}
              handleCandidateSort={handleCandidateSort}
              selectedIndex={advancedFilter.sortDirection === 'desc' ? 1 : 2}
            />
          </div>

          <div style={{ marginLeft: '20px', transform: 'translateY(-5px)' }}>
            <StatusFilter
              jobData={jobData}
              loading={fetchingJob}
              applicationStatus={applicationStatus}
              isAts={true}
              statusFilter={statusFilter}
            />
          </div>
        </div>

        <div
          style={{
            flex: '1 1 100%',
            paddingTop: '160px',
            overflow: isCandidateSnapshotOpen && 'hidden'
          }}>
          {showBanner && <div style={{ height: '40px', width: '100%' }}></div>}
          <AutoSizer>
            {({ height }) => {
              return (
                <DragDropContext
                  onDragStart={onDragStart}
                  onDragEnd={onDragEnd}>
                  <div className="app">
                    <BoardContainer>
                      <Column
                        key={'invisible1'}
                        index={5}
                        height={height}
                        isInvisible={true}
                      />
                      {state.columnOrder.map((columnId, index) => (
                        <Column
                          key={columnId}
                          height={height}
                          column={state.columns[columnId]}
                          index={index}
                          loadingUndecidedApplications={
                            loadingUndecidedApplications
                          }
                          loadingKivApplications={loadingKivApplications}
                          loadingShortlistedApplications={
                            loadingShortlistedApplications
                          }
                          loadingRejectedApplications={
                            loadingRejectedApplications
                          }
                          loadingInterviews={loadingInterviews}
                          loadingOffers={loadingOffers}
                          onApplicationClicked={onApplicationClicked}
                          changeApplicationState={changeApplicationState}
                          updateSelectAllStatus={updateSelectAllStatus}
                          changeMultipleApplicationState={
                            changeMultipleApplicationState
                          }
                          hasFilterApplied={hasFilterApplied}
                          columnSortDirections={columnSortDirections}
                          setColumnSortDirections={setColumnSortDirections}
                          advancedFilter={advancedFilter}
                          setBlackListState={setBlackListState}
                          viewApplication={props.viewApplication}
                          columnWidth={columnWidth}
                          jobData={jobData}
                          isBulkActionsOpen={isBulkActionsOpen}
                          setIsBulkActionsOpen={setIsBulkActionsOpen}
                          handleBulkActionsPopUpClose={
                            handleBulkActionsPopUpClose
                          }
                          showOnlyUnviewForUndecided={
                            showOnlyUnviewForUndecided
                          }
                          setShowOnlyUnviewForUndecided={
                            setShowOnlyUnviewForUndecided
                          }
                        />
                      ))}

                      <Column
                        key={'invisible2'}
                        index={5}
                        height={height}
                        isInvisible={true}
                      />
                    </BoardContainer>
                  </div>
                </DragDropContext>
              );
            }}
          </AutoSizer>
        </div>
      </div>

      {renderCandidateSnapshot(props.companyData)}
      <BlackListPopUp
        open={blackListState ? blackListState.open : false}
        handleClose={() => setBlackListState({ open: false, loading: false })}
        changeApplicationState={changeApplicationState}
        blackListState={blackListState}
      />
      <ScheduleInterviewModal
        open={isFirstInterviewModalOpened}
        isAts={true}
        setJobData={setJobData}
        jobData={jobData}
      />
      <ScheduleNextInterviewModal
        open={isNextInterviewModalOpened}
        isAts={true}
        loadingShortlistedApplications={loadingShortlistedApplications}
        loadingInterviews={loadingInterviews}
        jobData={jobData}
        setJobData={setJobData}
      />
      <OfferJobPopUp
        isOfferJobOpen={isOfferJobOpen}
        handleOfferJobClose={handleOfferJobClose}
      />
      <RejectPopUp
        isAts={true}
        open={rejectPopupStatus}
        handleClose={() => dispatch(toggleRejectPopup(false))}
        changeApplicationState={changeApplicationState}
      />
      <BulkRejectPopUp
        open={showBulkRejectPopup}
        changeMultipleApplicationState={changeMultipleApplicationState}
        selectedApplications={selectedApplications}
        updateSelectAllStatus={updateSelectAllStatus}
        convertSelectAllStatus={convertSelectAllStatus}
      />
    </DefaultLayout>
  );
};

/*
 * Item component
 */
function getStyle({
  draggableStyle,
  virtualStyle,
  isDragging,
  isSkeleton,
  isClone,
  columnWidth
}) {
  const combined = {
    ...virtualStyle,
    ...draggableStyle
  };

  const grid = 8;

  // when dragging we want to use the draggable style for placement, otherwise use the virtual style
  const result = {
    ...combined,
    height: isDragging ? combined.height : combined.height - grid,
    left: isDragging ? combined.left : combined.left + grid,
    width: isDragging
      ? draggableStyle.width
      : `calc(${combined.width} - ${grid * 2}px)`,
    marginBottom: grid
  };

  if (isSkeleton) {
    result.background = 'transparent';
  }

  return result;
}

function Item({
  provided,
  item,
  style,
  isDragging,
  updatingStatus,
  onApplicationClicked,
  hidden,
  index,
  changeApplicationState,
  isSelectAll,
  isClone,
  advancedFilter,
  isSkeleton,
  isEmpty,
  isTimeout,
  setBlackListState,
  viewApplication,
  title,
  columnWidth,
  setSelectedCounts,
  selectedCounts,
  onRetryButtonClicked
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [statusIndex, setStatusIndex] = useState(0);
  const [isSelected, setIsSelected] = useState(false);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleStatusIndex = index => event => {
    event.stopPropagation();
    setStatusIndex(index);
  };

  const onItemClicked = (item, title) => () => {
    if (item?.id && onApplicationClicked) {
      onApplicationClicked(item.id, title.toLowerCase());
    }

    // Not interview and offer
    if (item && item.state && !item.status) {
      let viewApplicationParams = {
        applicationId: item.id,
        state: item.state
      };
      viewApplication(viewApplicationParams);
    }
  };

  const onStateClicked = (item, state, index) => event => {
    event.stopPropagation();
    setAnchorEl(null);
    if (state !== 'blacklisted') {
      changeApplicationState(item.state, state, index, 0, '', false, -1, true);
    } else {
      if (setBlackListState) {
        setBlackListState({
          loading: false,
          open: true,
          application: item,
          currentState: item.state,
          nextState: state,
          currentIndex: index,
          updateSnapshot: false
        });
      }
    }
  };

  let stateArray = getStateArray(item?.state?.toLowerCase(), true);

  const onSingleSelectChange = (item, selectedCounts) => event => {
    setIsSelected(event.target.checked);

    const applicationState = item.state?.toLowerCase();
    if (event.target.checked) {
      if (selectedApplications[applicationState]) {
        selectedApplications[applicationState][item.id] = true;

        let currentSelectedCounts = { ...selectedCounts };
        currentSelectedCounts[applicationState] = Object.keys(
          selectedApplications[applicationState]
        ).length;
        setSelectedCounts(currentSelectedCounts);
      }
    } else {
      if (selectedApplications[applicationState]) {
        delete selectedApplications[applicationState][item.id];

        let currentSelectedCounts = { ...selectedCounts };
        currentSelectedCounts[applicationState] = Object.keys(
          selectedApplications[applicationState]
        ).length;
        setSelectedCounts(currentSelectedCounts);
      }
    }
  };

  const onCheckboxClicked = event => {
    event.stopPropagation();
  };

  useEffect(() => {
    const currentIsSelectAllStatus =
      isSelectAll == true || isSelectAll == 'true';

    setIsSelected(currentIsSelectAllStatus ? true : false);
  }, [isSelectAll]);

  const appliedAtFormatConversion = () => {
    const appliedAt = moment(item?.appliedAt || item?.createdAt);

    const today = moment().startOf('day');

    const days = Math.round(moment.duration(today - appliedAt).asDays());

    const fromNow = moment(appliedAt).fromNow();

    if (days > 0) {
      if (days >= 1 && days < 7) {
        return days + '\n days ago';
      } else {
        return appliedAt.format('DD MMM YYYY');
      }
    } else {
      return fromNow;
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: hiredlySpinner,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const isHired = () => {
    if (item?.jobApplication) {
      if (item.jobApplication.employerHiredStatus) {
        return true;
      }
    } else {
      if (item.employerHiredStatus) {
        return true;
      }
    }

    return false;
  };

  const getNoItemCopy = title => {
    switch (title) {
      case 'interview': {
        return 'No interview found';
      }
      case 'offer': {
        return 'No offer found';
      }
      default: {
        return 'No application found';
      }
    }
  };

  const getInterviewLength = interviews => {
    const remainder = interviews.length % 10;

    if (interviews.length > 0) {
      if (remainder == 1) {
        return interviews.length + `st`;
      } else if (remainder == 2) {
        return interviews.length + `nd`;
      } else if (remainder == 3) {
        return interviews.length + `rd`;
      } else {
        return interviews.length + `th`;
      }
    } else {
      return;
    }
  };

  const getInterviewIcon = candidate => {
    let application = candidate.jobApplication
      ? candidate.jobApplication
      : candidate;

    if (application?.interviews?.length > 0) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ position: 'relative', transform: 'translateY(-4px)' }}>
            {application?.interviews?.length > 0 && (
              <InterviewLengthContainer>
                <Text type="interview_length">
                  {getInterviewLength(application?.interviews)}
                </Text>
              </InterviewLengthContainer>
            )}

            <FontAwesomeIconStyled
              icon={faCalendarAlt}
              awesome_icon={'faCalenderAlt'}
              interview_length={application?.interviews?.length}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div
        onClick={onItemClicked(item, title)}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        style={getStyle({
          draggableStyle: provided?.draggableProps?.style,
          virtualStyle: style,
          isDragging,
          isSkeleton,
          isClone,
          columnWidth
        })}
        className={`item ${isDragging ? 'is-dragging' : ''}`}>
        {isEmpty || isSkeleton || isTimeout ? (
          // <Skeleton
          //   key={index}
          //   variant="rectangular"
          //   width={'100%'}
          //   height={'100%'}
          //   style={{ borderRadius: '8px' }}
          // />
          isEmpty ? (
            <div style={{ fontSize: '16px' }}>
              {getNoItemCopy(title.toLowerCase())}
            </div>
          ) : isTimeout ? (
            <div
              style={{
                fontSize: '16px',
                display: 'flex',
                flexDirection: 'column'
              }}>
              Network error, please try again.
              <div
                style={{
                  marginTop: '15px',
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                <ButtonStyled
                  type={'basic'}
                  disabled={false}
                  onClick={onRetryButtonClicked(title)}>
                  Retry
                </ButtonStyled>
              </div>
            </div>
          ) : (
            <Lottie options={defaultOptions} height={50} width={50} />
          )
        ) : (
          <ApplicantCard>
            {!hidden && !isClone && (
              <FormControlLabel
                onChange={onSingleSelectChange(item, selectedCounts)}
                value="select"
                style={{
                  position: 'absolute',
                  left: '0',
                  top: '0'
                }}
                control={
                  <Checkbox
                    onClick={onCheckboxClicked}
                    defaultChecked={false}
                    checked={isSelected}
                    size="small"
                    disableRipple
                    style={{ color: '#512acc', padding: '0 10px' }}
                    icon={<FontAwesomeIcon icon={farCircle} size="xs" />}
                    checkedIcon={<FontAwesomeIcon icon={faCircle} size="xs" />}
                  />
                }
              />
            )}

            {/* {item?.state === 'undecided' && item?.status === false && (
              <Tag>
                <TextContainer>
                  <Text origin="unviewed">unviewed</Text>
                </TextContainer>
              </Tag>
            )} */}

            <div style={{ display: 'flex' }}>
              <ApplicantCardHeader>
                {!item?.jobApplication && (
                  <ApplicantCardMoreHorizIcon onClick={handleClick} />
                )}

                <MenuStyled
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}>
                  {statusIndex == 0 ? (
                    <MenuItemStyled
                      key={'move to'}
                      onClick={handleStatusIndex(1)}>
                      <StatusText>Move to</StatusText>
                    </MenuItemStyled>
                  ) : (
                    <div>
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '30% auto',
                          padding: '10px 0'
                        }}>
                        <div
                          onClick={handleStatusIndex(0)}
                          style={{ display: 'flex', justifyContent: 'center' }}>
                          <FontAwesomeIcon
                            icon={faChevronLeft}
                            awesome_icon={'faChevronLeft'}
                            style={{
                              color: '#222',
                              alignSelf: 'center',
                              fontSize: '12px',
                              cursor: 'pointer'
                            }}
                          />
                        </div>
                        <StatusText style={{ fontWeight: '700' }}>
                          Move to
                        </StatusText>
                      </div>

                      <Divider />

                      {stateArray.map(state => {
                        return (
                          <MenuItemStyled
                            onClick={onStateClicked(item, state, index)}>
                            <StatusText state="true">{state}</StatusText>
                          </MenuItemStyled>
                        );
                      })}
                    </div>
                  )}
                </MenuStyled>
              </ApplicantCardHeader>
            </div>

            <ApplicantCardNameContainer>
              <ApplicantAvatar
                key={
                  item?.user?.profileImageSquare ||
                  item?.jobApplication?.user?.profileImageSquare
                }
                src={
                  item?.user?.profileImageSquare ||
                  item?.jobApplication?.user?.profileImageSquare
                }
              />
              <ApplicantName>
                <ApplicantNameTruncation>
                  {item?.user?.name || item?.jobApplication?.user?.name}
                </ApplicantNameTruncation>
              </ApplicantName>
            </ApplicantCardNameContainer>
            <ApplicantDescription>
              <ApplicantDescriptionTruncation>
                <Linkify>
                  {item?.user?.shortSummary ||
                    item?.jobApplication?.user?.shortSummary}
                </Linkify>
              </ApplicantDescriptionTruncation>
            </ApplicantDescription>
            <ApplicantDateStatusContainer
              type={
                item?.state === 'undecided' && item?.status == true
                  ? 'viewed'
                  : item?.state
              }>
              <div
                style={{
                  display: 'flex',
                  position: 'absolute',
                  bottom: '0',
                  padding: '5px 0'
                }}>
                <ClockIconStyled />
                <ApplicationDate>
                  {/* {moment(
                    item?.appliedAt || item?.jobApplication?.appliedAt
                  ).format('DD MMM YYYY')} */}
                  {appliedAtFormatConversion()}
                </ApplicationDate>
              </div>

              {updatingStatus ? (
                <ApplicationUpdatingSpinnerContainer>
                  <Spinner size="TINY" noPadding={true} />
                </ApplicationUpdatingSpinnerContainer>
              ) : isHired() ? (
                <ApplicationStatus type={'hired'}>{'Hired'}</ApplicationStatus>
              ) : item?.state != 'undecided' ||
                (item?.state === 'undecided' && item?.status === false) ? (
                <ApplicationStatus
                  type={item?.state === 'undecided' ? 'unviewed' : item?.state}>
                  {item?.state === 'undecided'
                    ? 'unviewed'
                    : item.state === 'kiv'
                    ? 'KIV'
                    : item?.state}
                </ApplicationStatus>
              ) : null}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <div
                  style={{
                    display: 'flex'
                  }}>
                  {getInterviewIcon(item)}

                  {item?.user?.videoCvOptimized && (
                    <div
                      style={{
                        display: 'flex',
                        alignSelf: 'center',
                        marginLeft: '10px',
                        transform: 'translateY(1px)'
                      }}>
                      <FontAwesomeIcon
                        icon={faVideo}
                        style={{ fontSize: '12px', color: '#222222' }}
                      />
                    </div>
                  )}
                  {item?.chatbotStatus == 'bot_completed' &&
                    !updatingStatus && (
                      <div
                        style={{
                          display: 'flex',
                          alignSelf: 'center',
                          marginLeft: '10px'
                        }}>
                        <AiviIcon width={15} />
                      </div>
                    )}
                </div>
              </div>
            </ApplicantDateStatusContainer>
          </ApplicantCard>
        )}
      </div>
    </>
  );
}

/*
 * Row Component
 * 1. Recommended react-window performance optimisation: memoize the row render function
 */
const getApplicationUpdatingStatus = id => state => {
  let updatingStatus;

  if (state.applications?.updatingApplicationStateIds[id]) {
    updatingStatus = true;
  }

  return updatingStatus;
};

const Row = React.memo(function Row(props) {
  const { data, index, style } = props;
  const item = data.items ? data.items[index] : null;

  let updatingStatus = useSelector(getApplicationUpdatingStatus(item?.id));

  if (!item && (data?.isListEmpty || data?.isTimeout)) {
    return (
      <Item
        index={index}
        provided={{}}
        item={item}
        style={style}
        updatingStatus={updatingStatus}
        hidden={data?.hidden}
        onApplicationClicked={data.onApplicationClicked}
        changeApplicationState={data.changeApplicationState}
        isSelectAll={data.isSelectAll}
        advancedFilter={data.advancedFilter}
        isEmpty={data?.isListEmpty ? true : false}
        isTimeout={data?.isTimeout ? true : false}
        title={data.title}
        columnWidth={data.columnWidth}
        onRetryButtonClicked={data.onRetryButtonClicked}
      />
    );
  }

  if (!item && data?.loading) {
    return (
      <Item
        index={index}
        provided={{}}
        item={item}
        style={style}
        updatingStatus={updatingStatus}
        hidden={data?.hidden}
        onApplicationClicked={data.onApplicationClicked}
        changeApplicationState={data.changeApplicationState}
        isSelectAll={data.isSelectAll}
        advancedFilter={data.advancedFilter}
        isSkeleton={true}
        title={data.title}
        columnWidth={data.columnWidth}
      />
    );
  }

  // We are rendering an extra item for the placeholder
  if (!item) {
    return null;
  }

  return (
    <Draggable
      isDragDisabled={
        data.title.toLowerCase() === 'offer' || data.loading
          ? true
          : false || data.title.toLowerCase() === 'rejected'
      }
      draggableId={item?.id || item?.jobApplication?.id}
      index={index}
      key={item.id}>
      {provided => (
        <Item
          index={index}
          provided={provided}
          item={item}
          style={style}
          updatingStatus={updatingStatus}
          hidden={data?.hidden}
          onApplicationClicked={data.onApplicationClicked}
          changeApplicationState={data.changeApplicationState}
          isSelectAll={data.isSelectAll}
          advancedFilter={data.advancedFilter}
          setBlackListState={data.setBlackListState}
          viewApplication={data.viewApplication}
          title={data.title}
          columnWidth={data.columnWidth}
          setSelectedCounts={data.setSelectedCounts}
          selectedCounts={data.selectedCounts}
        />
      )}
    </Draggable>
  );
}, areEqual);

/*
 * ItemList component
 */
const getLoadingByTitle = title => state => {
  let loading = false;
  if (title === 'undecided') {
    loading = state.applications.fetchingUndecidedApplications;
  } else if (title === 'kiv') {
    loading = state.applications.fetchingKivApplications;
  } else if (title === 'shortlisted') {
    loading = state.applications.fetchingShortlistedApplications;
  } else if (title === 'rejected') {
    loading = state.applications.fetchingRejectedApplications;
  } else if (title === 'interview') {
    loading = state.applications.fetchingInterviews;
  } else if (title === 'offer') {
    loading = state.applications.fetchingOffers;
  }

  return loading;
};

const getTimeoutByTitle = title => state => {
  let timeout = false;
  if (title === 'undecided') {
    timeout = state.applications.fetchingUndecidedTimeout;
  } else if (title === 'kiv') {
    timeout = state.applications.fetchingKivTimeout;
  } else if (title === 'shortlisted') {
    timeout = state.applications.fetchingShortlistedTimeout;
  } else if (title === 'rejected') {
    timeout = state.applications.fetchingRejectedTimeout;
  } else if (title === 'interview') {
    timeout = state.applications.fetchingInterviewTimeout;
  } else if (title === 'offer') {
    timeout = state.applications.fetchingOfferTimeout;
  }

  return timeout;
};

const getReachedEndByTitle = title => state => {
  let reachedEnd = false;
  if (title === 'undecided') {
    reachedEnd = state.applications.undecidedReachedEnd;
  } else if (title === 'kiv') {
    reachedEnd = state.applications.kivReachedEnd;
  } else if (title === 'shortlisted') {
    reachedEnd = state.applications.shortlistedReachedEnd;
  } else if (title === 'rejected') {
    reachedEnd = state.applications.rejectedReachedEnd;
  } else if (title === 'interview') {
    reachedEnd = state.applications.interviewReachedEnd;
  } else if (title === 'offer') {
    reachedEnd = state.applications.offerReachedEnd;
  }

  return reachedEnd;
};

const ItemList = React.memo(function ItemList({
  height,
  column,
  index,
  items,
  loadingUndecidedApplications,
  loadingKivApplications,
  loadingShortlistedApplications,
  loadingRejectedApplications,
  loadingInterviews,
  loadingOffers,
  onApplicationClicked,
  hidden,
  changeApplicationState,
  isSelectAll,
  advancedFilter,
  setBlackListState,
  viewApplication,
  columnWidth,
  setSelectedCounts,
  selectedCounts
}) {
  const loading = useSelector(getLoadingByTitle(column?.title?.toLowerCase()));
  const isTimeout = useSelector(
    getTimeoutByTitle(column?.title?.toLowerCase())
  );
  const draggingState = useSelector(state => state.applications.draggingState);
  const reachedEnd = useSelector(
    getReachedEndByTitle(column?.title.toLowerCase())
  );

  const listRef = useRef();
  useLayoutEffect(() => {
    const list = listRef.current;
    if (list) {
      list.scrollTo(0);
    }
  }, [index]);

  const onItemsRendered =
    title =>
    ({ visibleStopIndex }) => {
      if (draggingStart || changingStateCount > 0) return;
      // Use this visibleStopIndex for pagination, load more then last item is visible
      if (listRef?.current?.props?.itemCount - 1 === visibleStopIndex) {
        if (title.toLowerCase() === 'undecided') {
          loadingUndecidedApplications(false, false);
        } else if (title.toLowerCase() === 'kiv') {
          loadingKivApplications(false, false);
        } else if (title.toLowerCase() === 'shortlisted') {
          loadingShortlistedApplications(false, false);
        } else if (title.toLowerCase() === 'rejected') {
          loadingRejectedApplications(false, false);
        } else if (title.toLowerCase() === 'interview') {
          loadingInterviews(false);
        } else if (title.toLowerCase() === 'offer') {
          loadingOffers(false);
        }
      }
    };

  const isDropDisabled = (title, draggingState) => {
    if (
      draggingState == 'interview' &&
      title != 'offer' &&
      title != 'rejected'
    ) {
      return true;
    }

    if (!draggingStates[draggingState] && title == 'offer') {
      return true;
    }

    return false;
  };

  const onRetryButtonClicked = title => () => {
    const cTitle = title.toLowerCase();
    if (cTitle == 'undecided') {
      loadingUndecidedApplications(true, false);
    } else if (cTitle == 'kiv') {
      loadingKivApplications(true, false);
    } else if (cTitle == 'shortlisted') {
      loadingShortlistedApplications(true, false);
    } else if (cTitle == 'rejected') {
      loadingRejectedApplications(true, false);
    } else if (cTitle == 'interview') {
      loadingInterviews(true);
    } else if (cTitle == 'offer') {
      loadingOffers(true);
    }
  };

  return (
    <>
      <Droppable
        droppableId={column.id}
        isDropDisabled={
          isDropDisabled(column?.title.toLowerCase(), draggingState) || loading
            ? true
            : false
        }
        mode="virtual"
        renderClone={(provided, snapshot, rubric) => (
          <Item
            provided={provided}
            isDragging={snapshot.isDragging}
            item={items[rubric.source.index]}
            changeApplicationState={changeApplicationState}
            isClone={true}
            columnWidth={columnWidth}
          />
        )}>
        {(provided, snapshot) => {
          // Add an extra item to our list to make space for a dragging item
          // Usually the DroppableProvided.placeholder does this, but that won't
          // work in a virtual list
          let itemCount = snapshot.isUsingPlaceholder
            ? items
              ? items.length + 1
              : 1
            : items
            ? items.length
            : 0;

          const isListEmpty = !loading && reachedEnd && items.length === 0;

          if (isListEmpty) {
            itemCount += 1;
          } else {
            itemCount += loading || isTimeout ? 1 : 0;
          }

          return (
            <FixedSizeList
              onItemsRendered={onItemsRendered(column?.title)}
              height={height ? height - 34 - 46 - 55 : 550}
              itemCount={itemCount}
              itemSize={210}
              width={'400px'}
              outerRef={provided.innerRef}
              itemData={{
                items: items,
                title: column.title,
                onApplicationClicked: onApplicationClicked,
                changeApplicationState: changeApplicationState,
                isSelectAll: isSelectAll,
                hidden: hidden,
                advancedFilter: advancedFilter,
                loading: loading,
                isTimeout: isTimeout,
                setBlackListState: setBlackListState,
                viewApplication: viewApplication,
                columnWidth: columnWidth,
                setSelectedCounts: setSelectedCounts,
                selectedCounts: selectedCounts,
                isListEmpty: isListEmpty,
                onRetryButtonClicked: onRetryButtonClicked
              }}
              className="task-list"
              ref={listRef}>
              {Row}
            </FixedSizeList>
          );
        }}
      </Droppable>
    </>
  );
});

const getApplicationsByTitle = title => state => {
  let applications;
  if (title === 'undecided') {
    applications = state.applications.undecidedApplications;
  } else if (title === 'kiv') {
    applications = state.applications.kivApplications;
  } else if (title === 'shortlisted') {
    applications = state.applications.shortlistedApplications;
  } else if (title === 'rejected') {
    applications = state.applications.rejectedApplications;
  } else if (title === 'interview') {
    applications = state.applications.interviews;
  } else if (title === 'offer') {
    applications = state.applications.offers;
  }

  return applications;
};

const getSelectAllByTitle = title => state => {
  let selectAllStatus;
  if (
    state.applications.selectAllStatus[title] == true ||
    state.applications.selectAllStatus[title] == 'true' ||
    state.applications.selectAllStatus[title] == false ||
    state.applications.selectAllStatus[title] == 'false'
  ) {
    selectAllStatus = state.applications.selectAllStatus[title];
  }

  return selectAllStatus;
};

const getCountByTitle = title => state => {
  let count;

  if (state.applications.applicationCount[title]) {
    count = state.applications.applicationCount[title];
  }

  return count;
};

/*
 * Column component
 */
const Column = React.memo(function Column({
  height,
  column,
  index,
  isInvisible,
  loadingUndecidedApplications,
  loadingKivApplications,
  loadingShortlistedApplications,
  loadingRejectedApplications,
  loadingInterviews,
  loadingOffers,
  onApplicationClicked,
  changeApplicationState,
  updateSelectAllStatus,
  changeMultipleApplicationState,
  hasFilterApplied,
  columnSortDirections,
  setColumnSortDirections,
  advancedFilter,
  setBlackListState,
  viewApplication,
  columnWidth,
  jobData,
  isBulkActionsOpen,
  setIsBulkActionsOpen,
  handleBulkActionsPopUpClose,
  showOnlyUnviewForUndecided,
  setShowOnlyUnviewForUndecided
}) {
  const dispatch = useDispatch();

  const isSelectAll = useSelector(
    getSelectAllByTitle(column?.title.toLowerCase())
  );

  let applications = useSelector(
    getApplicationsByTitle(column?.title.toLowerCase())
  );

  let applicationCount = useSelector(
    getCountByTitle(column?.title.toLowerCase())
  );

  const isBulkRejectPopupOpen = useSelector(
    state => state.applications.showBulkRejectPopup
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [statusIndex, setStatusIndex] = useState(0);
  const [selectedCounts, setSelectedCounts] = useState({
    undecided: 0,
    kiv: 0,
    shortlisted: 0,
    rejected: 0
  });
  const [hidden, setHidden] = useState(true);
  const [moveToPopover, setMoveToPopover] = React.useState(null);
  const trigger = Boolean(moveToPopover);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const triggerMoveToPopover = event => {
    setMoveToPopover(event.currentTarget);
  };

  const handleMoveToPopoverClose = () => {
    setMoveToPopover(null);
  };

  const handleStatusIndex = index => event => {
    event.stopPropagation();
    setStatusIndex(index);
  };

  const toggleHiddenSelection = state => () => {
    if (anchorEl) {
      handleClose();
    }

    setHidden(!hidden);

    const convertedSelectAllStatus = convertSelectAllStatus(state, false);

    updateSelectAllStatus(state, convertedSelectAllStatus);
    setSelectedCounts({
      undecided: 0,
      kiv: 0,
      shortlisted: 0,
      rejected: 0
    });

    selectedApplications[state.toLowerCase()] = {};
  };

  const onSelectAll = state => event => {
    const convertedSelectAllStatus = convertSelectAllStatus(
      state,
      event.target.checked
    );

    updateSelectAllStatus(state, convertedSelectAllStatus);

    if (!event.target.checked) {
      setSelectedCounts({
        undecided: 0,
        kiv: 0,
        shortlisted: 0,
        rejected: 0
      });
    }
  };

  const onStateClicked = (currentState, state, close) => event => {
    if (state == 'rejected') {
      dispatch(triggerBulkRejectPopup(true));
      dispatch(getCurrentState(currentState));

      if (!isBulkRejectPopupOpen) {
        setHidden(true);
        close();
      }
      return;
    }

    //setIsBulkActionsOpen(true);
    let applicationIds = [];
    let applications = [];
    if (currentState == 'undecided') {
      applications = [...store.getState().applications.undecidedApplications];
    } else if (currentState == 'kiv') {
      applications = [...store.getState().applications.kivApplications];
    } else if (currentState == 'shortlisted') {
      applications = [...store.getState().applications.shortlistedApplications];
    } else if (currentState == 'rejected') {
      applications = [...store.getState().applications.rejectedApplications];
    }
    if (
      store.getState().applications.selectAllStatus[currentState] == true ||
      store.getState().applications.selectAllStatus[currentState] == 'true'
    ) {
      // Is select all
      applicationIds = applications.map(item => item.id);
    } else {
      Object.keys(selectedApplications[currentState]).map(key => {
        applicationIds.push(key);
      });
    }
    setHidden(true);
    close();

    const convertedSelectAllStatus = convertSelectAllStatus(
      currentState,
      false
    );
    updateSelectAllStatus(currentState, convertedSelectAllStatus);
    if (applicationIds.length > 0) {
      changeMultipleApplicationState(
        applicationIds,
        applications,
        currentState,
        state
      );
    }
  };

  const onSortClick = (direction, state) => event => {
    if (columnSortDirections[state] != direction) {
      setColumnSortDirections({
        undecided: direction,
        kiv: direction,
        shortlisted: direction,
        rejected: direction,
        interview: direction,
        offer: direction,
        currentState: state
      });
    }

    setStatusIndex(0);
    setAnchorEl(null);

    const sortType = direction == 'desc' ? '_newest' : '_oldest';
    let eventName = 'ats_sort_applicants_status' + sortType;

    dataLayer.push({
      event: eventName
    });
  };

  const handleShowUnviewClicked = () => {
    setShowOnlyUnviewForUndecided(!showOnlyUnviewForUndecided);
    setAnchorEl(null);
  };

  let stateArray = getStateArray(column?.title.toLowerCase(), false);

  const textRef = useRef();

  const checkTextLength = () => {
    const clientWidth =
      textRef.current?.clientWidth - 196 <
      textRef.current?.children[0]?.clientWidth;

    setTooltipHover(clientWidth);
  };

  const getBulkStateTitle = state => {
    if (state == 'kiv') {
      return state?.toUpperCase();
    } else {
      return state?.charAt(0).toUpperCase() + state?.substr(1).toLowerCase();
    }
  };

  useEffect(() => {
    if (!isInvisible) {
      checkTextLength();
    }
  }, []);

  const [tooltipHover, setTooltipHover] = useState(false);

  return isInvisible ? (
    <div>
      <div style={{ width: '3vw' }} />
    </div>
  ) : !jobData ? (
    <div style={{ padding: '15px 0px', margin: '8px' }}>
      <Skeleton
        variant="rectangular"
        width={
          window
            ? Math.max(Math.min((window.innerWidth - 160) / 4, 500), 255)
            : columnWidth
        }
        height={height}
        style={{ borderRadius: '16px' }}
      />
    </div>
  ) : (
    <ApplicantsContainer columnWidth={columnWidth}>
      <ApplicantHeader ref={textRef}>
        {!hasFilterApplied() &&
          ((column?.title.toLowerCase() === 'undecided' &&
            !showOnlyUnviewForUndecided) ||
            column?.title.toLowerCase() !== 'undecided') &&
          applicationCount && (
            <Tooltip
              title={
                <span style={{ fontSize: '12px', fontFamily: 'Inter' }}>
                  {applicationCount}
                </span>
              }
              interactive
              disableHoverListener={!tooltipHover}>
              <ApplicantCountContainer type={column?.title.toLowerCase()}>
                {applicationCount}
              </ApplicantCountContainer>
            </Tooltip>
          )}

        <StateTitle type={column?.title.toLowerCase()}>
          {column?.title == 'Offer' ? 'Offered' : column.title}
        </StateTitle>
        {!hidden ? (
          <div onClick={toggleHiddenSelection(column?.title?.toLowerCase())}>
            <FontAwesomeIcon
              icon={faTimes}
              awesome_icon={'faTimes'}
              style={{
                color: '#222',
                alignSelf: 'center',
                fontSize: '14px',
                cursor: 'pointer',
                position: 'absolute',
                right: '0',
                top: '5px'
              }}
            />
          </div>
        ) : (
          <MoreHorizIconStyled onClick={handleClick} />
        )}

        <MenuStyled
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}>
          {statusIndex == 0 ? (
            <div>
              <MenuItemStyled key={'move to'} onClick={handleStatusIndex(1)}>
                <StatusText>Sort by</StatusText>
              </MenuItemStyled>
              {column?.title.toLowerCase() !== 'interview' &&
                column?.title.toLowerCase() !== 'offer' && (
                  <>
                    <MenuItemStyled
                      key={'bulk-actions'}
                      onClick={toggleHiddenSelection(
                        column?.title?.toLowerCase()
                      )}>
                      <StatusText>Bulk actions</StatusText>
                    </MenuItemStyled>
                  </>
                )}

              {column?.title.toLowerCase() === 'undecided' && (
                <MenuItemStyled
                  selected={showOnlyUnviewForUndecided}
                  key={'show-unviewed-only'}
                  onClick={handleShowUnviewClicked}>
                  <StatusText>Show unviewed only</StatusText>
                </MenuItemStyled>
              )}
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '30% auto',
                  padding: '10px 0'
                }}>
                <div
                  onClick={handleStatusIndex(0)}
                  style={{ display: 'flex', justifyContent: 'center' }}>
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    awesome_icon={'faChevronLeft'}
                    style={{
                      color: '#222',
                      alignSelf: 'center',
                      fontSize: '12px',
                      cursor: 'pointer'
                    }}
                  />
                </div>
                <StatusText style={{ fontWeight: '700' }}>Sort list</StatusText>
              </div>

              <Divider />

              <MenuItemStyled
                onClick={onSortClick('desc', column?.title.toLowerCase())}
                selected={
                  columnSortDirections[column?.title.toLowerCase()] === 'desc'
                }
                isSelected={
                  columnSortDirections[column?.title.toLowerCase()] === 'desc'
                    ? 'true'
                    : 'false'
                }>
                <StatusText>Newest first</StatusText>
              </MenuItemStyled>

              <MenuItemStyled
                onClick={onSortClick('asc', column?.title.toLowerCase())}
                selected={
                  columnSortDirections[column?.title.toLowerCase()] === 'asc'
                }
                isSelected={
                  columnSortDirections[column?.title.toLowerCase()] === 'asc'
                    ? 'true'
                    : 'false'
                }>
                <StatusText>Oldest first</StatusText>
              </MenuItemStyled>
            </div>
          )}
        </MenuStyled>
      </ApplicantHeader>
      {!hidden && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '5px 15px'
          }}>
          <SelectAllContainer>
            <FormControlLabel
              value="select-all"
              onChange={onSelectAll(column?.title.toLowerCase())}
              control={
                <Checkbox
                  size="small"
                  style={{ color: '#512acc', padding: '5px' }}
                  icon={<FontAwesomeIcon icon={farCircle} size="xs" />}
                  checkedIcon={<FontAwesomeIcon icon={faCircle} size="xs" />}
                />
              }
              label={
                <span
                  style={{
                    fontSize: '13px',
                    fontFamily: 'Inter',
                    marginLeft: '4px'
                  }}>
                  Select All
                </span>
              }
            />
            <SelectAllCountContainer>
              <Text origin="select_all_count">
                {isSelectAll == true || isSelectAll == 'true'
                  ? `${applications.length == 0 ? '' : applications.length}`
                  : selectedCounts[column?.title.toLowerCase()] > 0
                  ? selectedCounts[column?.title.toLowerCase()]
                  : ''}
              </Text>
            </SelectAllCountContainer>
          </SelectAllContainer>

          <div
            onClick={triggerMoveToPopover}
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto auto',
              minWidth: '70px',
              cursor: 'pointer'
            }}>
            <div style={{ display: 'flex', alignSelf: 'center' }}>
              <p style={{ fontSize: '13px', fontFamily: 'Inter', margin: '0' }}>
                Move to
              </p>
            </div>
            <div style={{ display: 'flex' }}>
              <FontAwesomeIcon
                icon={faLongArrowAltRight}
                awesome_icon={'faLongArrowAltRight'}
                style={{
                  color: '#222',
                  alignSelf: 'center',
                  fontSize: '13px'
                }}
              />
            </div>
          </div>

          <MenuStyled
            open={trigger}
            anchorEl={moveToPopover}
            onClose={handleMoveToPopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}>
            {stateArray.map((state, index) => {
              return (
                <MenuItemStyled
                  key={index}
                  onClick={onStateClicked(
                    column?.title.toLowerCase(),
                    state,
                    handleMoveToPopoverClose
                  )}>
                  <StatusText>{getBulkStateTitle(state)}</StatusText>
                </MenuItemStyled>
              );
            })}
          </MenuStyled>

          <BulkActionsPopUp
            isBulkActionsOpen={isBulkActionsOpen}
            handleBulkActionsPopUpClose={handleBulkActionsPopUpClose}
          />
        </div>
      )}
      <ItemList
        column={column}
        index={index}
        height={height}
        items={applications}
        loadingUndecidedApplications={loadingUndecidedApplications}
        loadingKivApplications={loadingKivApplications}
        loadingShortlistedApplications={loadingShortlistedApplications}
        loadingRejectedApplications={loadingRejectedApplications}
        loadingInterviews={loadingInterviews}
        loadingOffers={loadingOffers}
        onApplicationClicked={onApplicationClicked}
        hidden={hidden}
        changeApplicationState={changeApplicationState}
        isSelectAll={isSelectAll}
        advancedFilter={advancedFilter}
        setBlackListState={setBlackListState}
        viewApplication={viewApplication}
        columnWidth={columnWidth}
        setSelectedCounts={setSelectedCounts}
        selectedCounts={selectedCounts}
      />
    </ApplicantsContainer>
  );
});

const mapStateToProps = state => {
  return {
    isCandidateSnapshotOpen: state.applications.isCandidateSnapshotOpen,
    isFirstInterviewModalOpened: state.applications.isFirstInterviewModalOpened,
    isNextInterviewModalOpened: state.applications.isNextInterviewModalOpened
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUndecidedApplications: bindActionCreators(
      getUndecidedApplications,
      dispatch
    ),
    getKivApplications: bindActionCreators(getKivApplications, dispatch),
    getShortlistedApplications: bindActionCreators(
      getShortlistedApplications,
      dispatch
    ),
    getRejectedApplications: bindActionCreators(
      getRejectedApplications,
      dispatch
    ),
    updateUndecidedApplications: bindActionCreators(
      updateUndecidedApplications,
      dispatch
    ),
    updateKivApplications: bindActionCreators(updateKivApplications, dispatch),
    updateShortlistedApplications: bindActionCreators(
      updateShortlistedApplications,
      dispatch
    ),
    updateRejectedApplications: bindActionCreators(
      updateRejectedApplications,
      dispatch
    ),
    getInterviews: bindActionCreators(getInterviews, dispatch),
    getOffers: bindActionCreators(getOffers, dispatch),
    changeJobApplicationState: bindActionCreators(
      changeJobApplicationState,
      dispatch
    ),
    updateApplicationCount: bindActionCreators(
      updateApplicationCount,
      dispatch
    ),
    updateAllApplications: bindActionCreators(updateAllApplications, dispatch),
    updateCandidateSnapshotStatus: bindActionCreators(
      updateCandidateSnapshotStatus,
      dispatch
    ),
    updateCurrentCandidateIndex: bindActionCreators(
      updateCurrentCandidateIndex,
      dispatch
    ),
    updateApplicationSelectAllStatus: bindActionCreators(
      updateApplicationSelectAllStatus,
      dispatch
    ),
    changeMultipleJobApplicationState: bindActionCreators(
      changeMultipleJobApplicationState,
      dispatch
    ),
    viewApplication: bindActionCreators(viewApplication, dispatch),
    updateInterviews: bindActionCreators(updateInterviews, dispatch),
    updateOffers: bindActionCreators(updateOffers, dispatch),
    updateFirstScheduleInterviewModalStatus: bindActionCreators(
      updateFirstScheduleInterviewModalStatus,
      dispatch
    ),
    updateNextScheduleInterviewModalStatus: bindActionCreators(
      updateNextScheduleInterviewModalStatus,
      dispatch
    ),
    updateDraggingState: bindActionCreators(updateDraggingState, dispatch),
    triggerBulkRejectPopup: bindActionCreators(triggerBulkRejectPopup, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Board);
