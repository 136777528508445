import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import DefaultLayout from '../../layouts/DefaultLayout';
import { ErrorToast } from '../../utils/ToastUtils';
import ManualPaymentPopUp from './ManualPaymentPopUp';
import { toMYR } from '../../utils/CurrencyUtils';
import styles from './index.module.scss';
import { Divider } from '@mui/material';
import {
  AddOnCard,
  ContactSalesButton,
  ContactSalesTitle,
  OrderSummaryTitle,
  OrderTableTitle,
  PackageGrid,
  PackageSectionTitle,
  ProductPageTitle,
  ProductTitle,
  ContactSalesDesc,
  LinkText,
  PackageSectionInactive
} from './styles';
import moment from 'moment';
import BillingAddressAndPayment from '../CompanyAccount/CompanyDetails/BillingAddressAndPayment';
import { NoWidthSkeleton, ReviewCartItemSkeleton } from './Skeletons/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchMyOrder,
  mutateOrderSummary,
  mutatePaymentLink
} from '../../redux/actions/order_action';
import { fetchMyCompanySettings } from '../../redux/actions/company_actions';
import {
  getCountry,
  getCurrency,
  getCurrenyCode,
  getTaxLabel
} from '../../hooks/useRegionalisation';
import { countryCodeOptions } from '../../components/SharedSelectCountry/constants';
import { CSEMAIL } from '../../utils/Constants';

export default function ProductsPayment() {
  const { orderId } = useParams();

  const location = useLocation();
  const dispatch = useDispatch();
  const orderSummaryBool = new URLSearchParams(location.search).get(
    'orderSummary'
  );

  const [orderSummaryData, setOrderSummaryData] = useState(null);

  useEffect(() => {
    dispatch(fetchMyOrder({ orderId: orderId }));
    dispatch(fetchMyCompanySettings());
    dispatch(
      mutateOrderSummary({
        input: {
          orderId: orderId
        }
      })
    ).then(res => {
      setOrderSummaryData(res.orderSummary);
    });
  }, []);

  const myOrder = useSelector(state => state.orders.myOrder);
  const companyData = useSelector(state => state.companies.myCompanySettings);
  const fetchingCompanySettings = useSelector(
    state => state.companies.fetchingCompanySettings
  );

  const [isManualPaymentOpen, setManualPaymentOpen] = useState(false);

  const summary = orderSummaryData;
  const taxAmount = summary?.taxAmount;

  const handleOnlinePayment = async () => {
    dispatch(
      mutatePaymentLink({
        input: {
          orderId
        }
      })
    )
      .then(res => {
        const paymentLink = res?.eghlPaymentLink;

        if (paymentLink) {
          window.dataLayer.push({
            event: 'make_payment_online'
          });
        }

        window.location.href = paymentLink;
      })
      .catch(err => {
        ErrorToast('Failed to generate eGHL payment link.');
      });
  };

  const handleConfirm = () => {
    try {
      window.dataLayer.push({
        event: 'view-invoice-order-summary',
        order_id: orderId
      });
    } catch {}

    window.open(myOrder?.invoiceLink, '_blank');
  };

  const triggerManualPayment = () => {
    try {
      window.dataLayer.push({
        event: 'make_payment_manual'
      });

      setManualPaymentOpen(true);
    } catch {}
  };

  const isPriceChanged = myOrder => {
    const index = myOrder?.cart.cartLists?.nodes.findIndex(item => {
      return item.product.productPriceChanged == true;
    });

    if (index > -1) return true;

    return false;
  };

  return (
    <DefaultLayout>
      <PackageGrid padding={'40px 100px'} marginX={'auto'} maxWidth={'1440px'}>
        <ProductPageTitle variant="h1" marginBottom={'32px'}>
          Payment / Send Invoice
        </ProductPageTitle>
        <PackageGrid
          columns={12}
          columnGap={'24px'}
          flexWrap={'nowrap'}
          container>
          {/* Left panel */}
          <PackageGrid xs={4}>
            <BillingAddressAndPayment
              disablePayment={myOrder?.orderStatus !== 'pending-payment'}
              billingAddress={companyData?.billingAddress}
              loading={fetchingCompanySettings}
              handleOnlinePayment={handleOnlinePayment}
              triggerManualPayment={triggerManualPayment}
            />
            <div className={styles.selectPaymentOption}>
              <ManualPaymentPopUp
                open={isManualPaymentOpen}
                handleClose={() => setManualPaymentOpen(false)}
                invoiceNumber={summary?.invoiceNumber}
              />
            </div>
          </PackageGrid>
          {/* Right panel */}
          <PackageGrid xs={8}>
            <AddOnCard>
              <PackageGrid padding={'16px'} borderBottom={'1px solid #D8D8D8'}>
                {fetchingCompanySettings ? (
                  <NoWidthSkeleton width={155} />
                ) : (
                  <OrderSummaryTitle variant="h3">
                    Order Summary
                  </OrderSummaryTitle>
                )}
              </PackageGrid>
              <PackageGrid
                container
                flexDirection={'column'}
                padding={'24px'}
                rowGap={'12px'}
                borderBottom={'1px solid #D8D8D8'}>
                {fetchingCompanySettings ? (
                  <PackageGrid minHeight={'200px'}>
                    <NoWidthSkeleton width={58} sx={{ marginBottom: '12px' }} />
                    <NoWidthSkeleton
                      width={300}
                      sx={{ marginBottom: '12px' }}
                    />
                    <NoWidthSkeleton width={225} />
                  </PackageGrid>
                ) : (
                  <Fragment>
                    <OrderAlert
                      fromOrderSummary={orderSummaryBool}
                      myOrder={myOrder}
                      isPriceChanged={isPriceChanged}
                    />
                    <PackageGrid
                      container
                      flexWrap={'nowrap'}
                      flexDirection={'row'}
                      columns={12}
                      columnGap={'45px'}>
                      <PackageGrid item xs={9}>
                        <OrderTableTitle variant="caption">
                          Item
                        </OrderTableTitle>
                      </PackageGrid>
                      <PackageGrid
                        container
                        justifyContent={'flex-end'}
                        item
                        xs={1}>
                        <OrderTableTitle variant="caption">
                          Quantity
                        </OrderTableTitle>
                      </PackageGrid>
                      <PackageGrid
                        container
                        justifyContent={'flex-end'}
                        item
                        xs={2}>
                        <OrderTableTitle variant="caption">
                          Amount ({getCurrenyCode()})
                        </OrderTableTitle>
                      </PackageGrid>
                    </PackageGrid>
                    {myOrder?.cart?.cartLists?.nodes?.map(e => {
                      const total = e?.totalPriceBeforeDiscount;

                      return (
                        <PackageGrid
                          container
                          flexWrap={'nowrap'}
                          flexDirection={'row'}
                          columns={12}
                          columnGap={'50px'}>
                          <PackageGrid item xs={9}>
                            {!e?.product?.active &&
                            myOrder?.orderStatus === 'cancelled' ? (
                              <PackageSectionInactive>
                                {e?.product.title} {'(No longer available)'}
                              </PackageSectionInactive>
                            ) : (
                              <PackageSectionTitle>
                                {e?.product.title}
                              </PackageSectionTitle>
                            )}
                          </PackageGrid>
                          <PackageGrid
                            container
                            justifyContent={'flex-end'}
                            item
                            xs={1}>
                            <PackageSectionTitle
                              variant="caption"
                              sx={{
                                color: '#000000de'
                              }}>
                              {e?.quantity}
                            </PackageSectionTitle>
                          </PackageGrid>
                          <PackageGrid
                            container
                            justifyContent={'flex-end'}
                            item
                            xs={2}>
                            <PackageSectionTitle
                              variant="caption"
                              sx={{
                                color: '#000000de'
                              }}>
                              {getCurrency(total)}
                            </PackageSectionTitle>
                          </PackageGrid>
                        </PackageGrid>
                      );
                    })}
                  </Fragment>
                )}
                {fetchingCompanySettings ? null : (
                  <Fragment>
                    <Divider />
                    <PackageGrid container justifyContent={'space-between'}>
                      <ContactSalesTitle variant="body1">
                        Subtotal (excl.tax)
                      </ContactSalesTitle>
                      <ContactSalesTitle variant="body1">
                        {getCurrency(summary?.totalAmount)}
                      </ContactSalesTitle>
                    </PackageGrid>
                    <PackageGrid container justifyContent={'space-between'}>
                      <PackageSectionTitle variant="body1">
                        Total savings
                      </PackageSectionTitle>
                      <PackageSectionTitle variant="body1">
                        -
                        {getCurrency(
                          parseFloat(summary?.bundleDiscountAmount ?? 0) +
                            parseFloat(summary?.voucherDiscountAmount ?? 0)
                        )}
                      </PackageSectionTitle>
                    </PackageGrid>
                    {/* <PackageGrid container justifyContent={'space-between'}>
                      <PackageSectionTitle variant="body1">
                        Subtotal Excluding Tax
                      </PackageSectionTitle>
                      <PackageSectionTitle variant="body1">
                        {getCurrency(summary?.totalExcludingTax)}
                      </PackageSectionTitle>
                    </PackageGrid> */}
                    <PackageGrid container justifyContent={'space-between'}>
                      <>
                        <PackageSectionTitle variant="body1">
                          {summary?.serviceTaxPercentage}%{' '}
                          {getTaxLabel('remove-incl')}
                        </PackageSectionTitle>
                        <PackageSectionTitle variant="body1">
                          {getCurrency(taxAmount)}
                        </PackageSectionTitle>
                      </>
                    </PackageGrid>
                  </Fragment>
                )}
              </PackageGrid>
              <PackageGrid container padding={'24px 24px 20px'} rowGap={'22px'}>
                <PackageGrid container justifyContent={'space-between'}>
                  {fetchingCompanySettings ? (
                    <Fragment>
                      <NoWidthSkeleton width={185} />
                      <NoWidthSkeleton width={96} />
                    </Fragment>
                  ) : (
                    <Fragment>
                      <ContactSalesTitle variant="body1">
                        Total Payable Including Tax
                      </ContactSalesTitle>
                      <ContactSalesTitle variant="body1">
                        {getCurrency(summary?.totalIncludingTax)}
                      </ContactSalesTitle>
                    </Fragment>
                  )}
                </PackageGrid>
                <PackageGrid
                  container
                  justifyContent={'center'}
                  alignItems={'center'}>
                  {fetchingCompanySettings ? (
                    <NoWidthSkeleton width={660} />
                  ) : (
                    <OrderTableTitle sx={{ color: 'black' }}>
                      Your products will be activated upon confirmation. To
                      change activation date, please email{' '}
                      {CSEMAIL[getCountry()]}
                    </OrderTableTitle>
                  )}
                </PackageGrid>
                <PackageGrid
                  container
                  justifyContent={'center'}
                  alignItems={'center'}>
                  {fetchingCompanySettings ? (
                    <ReviewCartItemSkeleton width={200} />
                  ) : (
                    <ContactSalesButton
                      variant="contained"
                      onClick={handleConfirm}>
                      View Invoice
                    </ContactSalesButton>
                  )}
                </PackageGrid>
              </PackageGrid>
            </AddOnCard>
          </PackageGrid>
        </PackageGrid>
      </PackageGrid>
    </DefaultLayout>
  );
}

export const OrderAlert = ({ fromOrderSummary, myOrder, isPriceChanged }) => {
  const orderStatus = myOrder?.orderStatus;

  const getAlertTitle = () => {
    if (orderStatus == 'pending-payment') {
      return 'Changes to Total Price';
    } else if (orderStatus == 'cancelled') {
      return 'Order Cancelled';
    } else if (orderStatus == 'completed-payment') {
      return 'Payment Received';
    }
    return '';
  };

  const getDate = () => {
    if (orderStatus == 'completed-payment' && myOrder?.paidAt) {
      return moment(myOrder.paidAt).format('DD/MM/YYYY');
    } else if (orderStatus == 'cancelled' && myOrder?.updatedAt) {
      return moment(myOrder?.updatedAt).format('DD/MM/YYYY');
    }

    return '';
  };

  const expired = false;
  const getAlertMessage = () => {
    if (orderStatus == 'cancelled') {
      return expired ? (
        <ContactSalesDesc variant="body1" as={'span'}>
          Did not cancel this order? Contact us at
          <LinkText>{CSEMAIL[getCountry()]}</LinkText> for assistance.
        </ContactSalesDesc>
      ) : (
        <ContactSalesDesc variant="body1" as={'span'}>
          Did not cancel this order? Contact us at{' '}
          <span>
            <a href={`mailto:${CSEMAIL[getCountry()]}`}>
              {CSEMAIL[getCountry()]}
            </a>
          </span>{' '}
          for assistance.
        </ContactSalesDesc>
      );
    } else if (orderStatus == 'pending-payment') {
      return (
        <ContactSalesDesc variant="body1" as={'span'}>
          Prices have been adjusted for item(s) in your order. Please review
          your new total before proceeding.
        </ContactSalesDesc>
      );
    } else if (orderStatus == 'completed-payment') {
      return (
        <ContactSalesDesc variant="body1" as={'span'}>
          Payment for this order had already been made on {getDate()}. Please
          proceed to our{' '}
          <span>
            <a href="/purchase/choose">Products Page</a>
          </span>{' '}
          to make a new purchase.
        </ContactSalesDesc>
      );
    }

    return '';
  };

  const getBackgroundColor = orderStatus => {
    if (orderStatus == 'pending-payment') {
      return ['rgba(242, 166, 71, 1)', 'rgba(242, 166, 71, 0.2)'];
    } else if (orderStatus == 'cancelled') {
      return ['rgba(190, 66, 66, 1)', 'rgba(190, 66, 66, 0.2)'];
    } else if (orderStatus == 'completed-payment') {
      return ['rgba(144, 185, 56, 1)', 'rgba(144, 185, 56, 0.2)'];
    } else {
      return ['black', 'grey'];
    }
  };

  return !fromOrderSummary && orderStatus === 'pending-payment' ? null : (
    <PackageGrid
      borderLeft={`10px solid ${getBackgroundColor(orderStatus)[0]}`}
      borderRadius={'8px'}
      sx={{ background: getBackgroundColor(orderStatus)[1] }}
      padding={'10px 16px'}
      gap={'4px'}
      container
      flexDirection={'column'}
      justifyContent={'start'}
      alignItems={'start'}>
      <ProductTitle
        variant="body1"
        textAlign={'left'}
        sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
        {getAlertTitle()}
      </ProductTitle>
      <ContactSalesDesc variant="body1" as={'span'}>
        {getAlertMessage()}
      </ContactSalesDesc>
    </PackageGrid>
  );
};
