import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../styles/colors.module.scss';

export const ContentContainer = styling('div')({
  padding: '16px 48px 43px',
  height: '400px',
  overflow: 'auto',
});

export const ButtonsContainer = styling('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px 48px',
  alignItems: 'center',
  position: 'sticky',
  bottom: '0',
  background: Colors.priWhite,
});

export const ContentDescription = styling('span')({
  fontWeight: 400,
  fontSize: '16px',
  fontFamily: 'Inter',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.87)',
});

export const MoreInfo = styling('span')({
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '14px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.87)',
  paddingLeft: '16px',
});

export const PageNumber = styling('span')({
  fontFamily: 'Inter',
  fontWeight: 700,
  fontSize: '16px',
  color: 'rgba(0, 0, 0, 0.6)',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  cursor: 'default',
});

export const NavigateToPricing = styling('span')({
  fontSize: '14px',
  fontFamily: 'Inter',
  color: Colors.priPurple,
  letterSpacing: '0.15px',
  textDecoration: 'underline',
  textUnderlineOffset: '4px',
  cursor: 'pointer',
});
