import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, Grid } from '@mui/material';
import { HeightSpacer } from '../../../../components/Spacer/styles';
import {
  CategoryPill,
  CategoryPillTitle,
  FilterTitle,
  GridStyled,
  IconButtonStyled,
  SelectStyled,
  SelectTextStyled,
  TextButtonStyled,
  TextFieldStyled,
  XMarkStyled
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { updateSearchTalentFilters } from '../../../../redux/actions/talent_action';
import { fetchStateRegions } from '../../../../redux/actions/company_actions';
import { fetchSpecialisation } from '../../../../redux/actions/job_action';
import {
  getAllCountryStates,
  getCountryStates
} from '../../../../hooks/useRegionalisation';
import {
  AccordionDetailsColumnStyled,
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryStyled,
  SectionTitleStyled,
  SubCategoryContainerStyled
} from '../../../../components/SharedAdvancedFilter/styles';
import SharedFormCheckbox from '../../../../components/SharedAdvancedFilter/SharedFormCheckbox';
import { LOCATION_ALL_LABEL } from '../../../../components/SharedAdvancedFilter/constants';
import { countryOptionsFullName } from '../../../../components/SharedSelectCountry/constants';
import { getLocalStorageItem } from '../../../../helpers/local_storage_utils';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import store from '../../../../redux/stores/store';

const country = getLocalStorageItem('country');

const CurrentLocation = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState(null);
  const [matchResult, setMatchResult] = useState({});
  const [selectedAccordionIndex, setSelectedAccordionIndex] = useState(null);

  const filters = useSelector(state => state.talents.searchTalentFilters);

  const allCountryStateRegions = getAllCountryStates();

  const onCurrentLocation = e => {
    const cFilters = { ...filters };

    const value = e.target.value;
    const isChecked = e.target.checked;

    const key = value == 'all-my' ? 'my' : value == 'all-sg' ? 'sg' : 'my';

    let titles = allCountryStateRegions[key].map(region => region.title);

    if (value == 'all-my' || value == 'all-sg') {
      if (isChecked) {
        const newLocations = titles?.filter(
          location => !cFilters.currentLocation?.includes(location)
        );

        cFilters.currentLocation?.push(...newLocations);
      } else {
        cFilters.currentLocation = cFilters.currentLocation?.filter(
          v => !titles?.includes(v)
        );
      }
    } else {
      if (isChecked) {
        cFilters.currentLocation?.push(value);
      } else {
        cFilters.currentLocation = cFilters.currentLocation?.filter(
          v => v !== value
        );
      }
    }

    dispatch(updateSearchTalentFilters(cFilters));
  };

  const onRemoveLocation = () => {
    const cFilters = { ...filters };

    cFilters.currentLocation.shift();
    dispatch(updateSearchTalentFilters(cFilters));
  };

  const handleSearchInput = event => {
    const { value } = event?.target || {};
    setSearchText(value);

    if (value?.length <= 0) {
      setMatchResult({});
    } else {
      const myMatchingState =
        allCountryStateRegions['my'].filter(regions =>
          regions.title.toLowerCase().includes(value.toLowerCase())
        ) || [];

      const sgMatchingState =
        allCountryStateRegions['sg'].filter(regions =>
          regions.title.toLowerCase().includes(value.toLowerCase())
        ) || [];

      const matchingState = { my: myMatchingState, sg: sgMatchingState };

      if (matchingState['my']?.length > 0) {
        setSelectedAccordionIndex(0);
      } else if (matchingState['sg']?.length > 0) {
        setSelectedAccordionIndex(1);
      }

      setMatchResult(matchingState);
    }
  };

  const handleSelectAll = () => {
    const reduxFilters = { ...filters };

    let titles = store
      .getState()
      .companies.allStateRegions?.map(region => region.title);

    if (reduxFilters.currentLocation?.length > 0) {
      reduxFilters['currentLocation'] = [];
    } else {
      const newLocations = titles?.filter(
        location => !reduxFilters.currentLocation?.includes(location)
      );

      reduxFilters.currentLocation?.push(...newLocations);
    }

    dispatch(updateSearchTalentFilters(reduxFilters));
  };

  const renderSharedFormCheckboxes = (
    stateRegions,
    start,
    end,
    applicationParams,
    onCurrentLocation
  ) => {
    return stateRegions.slice(start, end).map(stateRegion => {
      return (
        <SharedFormCheckbox
          key={stateRegion.id}
          value={stateRegion.title}
          checked={applicationParams.currentLocation?.includes(
            stateRegion.title
          )}
          onChange={onCurrentLocation}
          label={stateRegion.title}
        />
      );
    });
  };

  const getSelectedLocationCount = key => {
    const cFilters = {
      ...filters
    };

    let titles = allCountryStateRegions[key].map(region => region.title);

    const locations = titles?.filter(location =>
      cFilters.currentLocation?.includes(location)
    );

    return locations?.length;
  };

  useEffect(() => {
    dispatch(fetchStateRegions());
    dispatch(fetchSpecialisation());
  }, []);

  return (
    <div
      style={{
        padding: '0',
        width: '95%',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
        <FilterTitle>Current Location</FilterTitle>
      </div>

      <div>
        <FormControl style={{ width: '100%' }}>
          <SelectStyled
            onClose={() => {
              if (searchText?.length > 0) {
                setSearchText('');
                setMatchResult({});
                setSelectedAccordionIndex(null);
              }
            }}
            value={
              filters['currentLocation'].length > 0
                ? filters['currentLocation']
                : ['Select Location']
            }
            placeholder="Select Location"
            multiple
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            MenuProps={{
              PaperProps: {
                sx: {
                  boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.16)',
                  borderRadius: '4px',
                  width: '300px',
                  height: '300px'
                }
              }
            }}
            renderValue={value => {
              // IF THERE IS NO SELECTION
              return Array.isArray(value) && value[0] === 'Select Location' ? (
                <SelectTextStyled>{value}</SelectTextStyled>
              ) : // IF THERE IS MORE THAN 1 SELECTION
              Array.isArray(value) && value.length > 1 ? (
                <GridStyled
                  position={'relative'}
                  container
                  flexWrap={'nowrap'}
                  columnGap={'6px'}
                  height={'25px'}
                  width={'fit-content'}>
                  {value.map((selectedVal, index) =>
                    index === 0 ? (
                      <CategoryPill
                        onMouseDown={event => {
                          event.stopPropagation();
                          onRemoveLocation();
                        }}>
                        <CategoryPillTitle>{selectedVal}</CategoryPillTitle>
                        <IconButtonStyled sx={{ padding: 0 }}>
                          <XMarkStyled width={14} height={14} />
                        </IconButtonStyled>
                      </CategoryPill>
                    ) : null
                  )}
                  <CategoryPill
                    sx={{
                      minWidth: '35px',
                      display: 'flex',
                      justifyContent: 'center'
                    }}>
                    <CategoryPillTitle>{value.length - 1}+</CategoryPillTitle>
                  </CategoryPill>
                </GridStyled>
              ) : // IF THERE IS ONLY 1 SELECTION
              Array.isArray(value) && value.length === 1 ? (
                <CategoryPill
                  position={'relative'}
                  onMouseDown={event => {
                    event.stopPropagation();
                    onRemoveLocation();
                  }}>
                  <CategoryPillTitle>{value}</CategoryPillTitle>
                  <IconButtonStyled sx={{ padding: 0 }}>
                    <XMarkStyled width={14} height={14} />
                  </IconButtonStyled>
                </CategoryPill>
              ) : null;
            }}>
            <TextFieldStyled
              size="small"
              defaultValue={searchText}
              value={searchText}
              onChange={event => handleSearchInput(event)}
              placeholder="Search Location"
              sx={{
                margin: '16px 16px 8px 16px',
                display: 'flex'
              }}
              inputProps={{
                style: {
                  fontWeight: 400,
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  lineHeight: '20px'
                }
              }}
            />

            {/* SEARCH TEXT FIELD SECTION START */}
            <GridStyled
              container
              paddingX={'16px'}
              justifyContent={'space-between'}
              alignItems={'center'}
              paddingBottom={'8px'}
              borderBottom={'1px solid #D8D8D8'}>
              <SelectTextStyled variant="body1" sx={{ fontSize: '12px' }}>
                {filters?.currentLocation.length ?? 0} selected
              </SelectTextStyled>
              {matchResult.length > 0 ? null : (
                <TextButtonStyled
                  variant="text"
                  onClick={() => handleSelectAll()}>
                  {filters?.currentLocation?.length > 0
                    ? 'Deselect All'
                    : 'Select All'}
                </TextButtonStyled>
              )}
            </GridStyled>
            {/* SEARCH TEXT FIELD SECTION END */}

            {/* SELECTION OPTIONS */}
            {Object.keys(allCountryStateRegions)?.length > 0 &&
              Object.keys(allCountryStateRegions).map((key, index) => {
                let stateRegion =
                  Object.keys(matchResult)?.length > 0 && searchText?.length > 0
                    ? matchResult[key]
                    : searchText?.length <= 0 || searchText == null
                    ? allCountryStateRegions[key]
                    : [];

                return matchResult[key]?.length == 0 ? null : (
                  <Accordion
                    expanded={index == selectedAccordionIndex}
                    column={'two'}
                    type={'curentLocation'}
                    onClick={() => {
                      if (selectedAccordionIndex != null) {
                        setSelectedAccordionIndex(null);
                        return;
                      }

                      setSelectedAccordionIndex(index);
                    }}
                    title={
                      <Grid
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%'
                        }}>
                        <SectionTitleStyled type="currentLocation">
                          {key == 'my'
                            ? countryOptionsFullName['MY']
                            : key == 'sg'
                            ? countryOptionsFullName['SG']
                            : ''}
                        </SectionTitleStyled>
                        {getSelectedLocationCount(key) > 0 && (
                          <SubCategoryContainerStyled
                            sx={{ marginRight: '8px' }}>
                            {getSelectedLocationCount(key)}
                          </SubCategoryContainerStyled>
                        )}
                      </Grid>
                    }
                    description={
                      <>
                        <AccordionDetailsColumnStyled>
                          {searchText?.length <= 0 || searchText == null ? (
                            <SharedFormCheckbox
                              value={`all-${key}`}
                              checked={
                                filters.currentLocation &&
                                filters.currentLocation.length > 1 &&
                                stateRegion
                                  .map(r => r.title)
                                  .every(location =>
                                    filters.currentLocation?.includes(location)
                                  )
                              }
                              onChange={onCurrentLocation}
                              label={LOCATION_ALL_LABEL[country]}
                            />
                          ) : null}
                          {renderSharedFormCheckboxes(
                            stateRegion,
                            0,
                            9,
                            filters,
                            onCurrentLocation
                          )}
                        </AccordionDetailsColumnStyled>
                        <AccordionDetailsColumnStyled>
                          {renderSharedFormCheckboxes(
                            stateRegion,
                            9,
                            stateRegion?.length,
                            filters,
                            onCurrentLocation
                          )}
                        </AccordionDetailsColumnStyled>
                      </>
                    }
                  />
                );
              })}
          </SelectStyled>
        </FormControl>
      </div>
    </div>
  );
};

export default CurrentLocation;

export const CheckboxSelection = ({ item, checked, onClick }) => {
  return (
    <GridStyled
      sx={{
        cursor: 'pointer',
        padding: '8px 15px',
        '&:hover': {
          background: '#EFEFEF'
        }
      }}
      container
      flexDirection={'column'}>
      <FormControlLabel
        control={
          <Checkbox
            value={item.title}
            checked={checked}
            onClick={onClick}
            sx={{
              '&.Mui-checked': {
                color: '#222'
              },
              padding: '0px 9px 0px 0px',
              marginLeft: '1rem'
            }}
          />
        }
        label={item.title}
        sx={{
          '& .MuiTypography-root': {
            fontSize: 14,
            letterSpacing: '0.15px'
          },
          '& .MuiSvgIcon-root': {
            fontSize: 20
          },
          alignItems: 'flex-start'
        }}
      />
    </GridStyled>
  );
};

const Accordion = props => {
  const { title, description, column, type, expanded, onClick } = props;

  return (
    <AccordionStyled disableGutters expanded={expanded} onClick={onClick}>
      <AccordionSummaryStyled
        type={type}
        expandIcon={<ChevronRightIcon width={20} height={20} />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        {title}
      </AccordionSummaryStyled>
      <AccordionDetailsStyled column={column}>
        {description}
      </AccordionDetailsStyled>
    </AccordionStyled>
  );
};
