import {
  useLocation,
  useParams
} from 'react-router-dom';

function usePathKey() {
  const location = useLocation();
  const { id } = useParams();
  const { pathname } = location;

  let pathKey;
  if (pathname.includes('/jobs/add/new-job')) {
    pathKey = 'new';
  } else if (pathname.includes('/jobs/edit-job-draft')) {
    pathKey = 'draft';
  } else if (pathname.includes('/jobs/add/free-intern')) {
    pathKey = 'intern';
  } else if (pathname.includes('/jobs/add/free-senior')) {
    pathKey = 'senior';
  }

  if (id) {
    pathKey = 'edit';
  }

  return pathKey;
}

export default usePathKey;
