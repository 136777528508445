import styled from '@mui/material/styles/styled';
import { Card } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '30px 0px'
});

export const Logo = styled('img')({
  width: '200px'
});

export const WelcomeText = styled('p')({
  fontSize: '22px',
  fontFamily: 'InterBold',
  margin: '25px 0px'
});

export const CardContainer = styled(Card)({
  minWidth: '32vw',
  borderRadius: '38px',
  padding: '40px 50px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#fff'
});

export const TitleText = styled('p')({
  margin: '0px 10px 25px',
  fontSize: '16px',
  fontFamily: 'InterBold'
});

export const Row = styled('div')({
  marginBottom: '15px'
});

export const RowLabel = styled('p')({
  paddingLeft: '10px',
  margin: '5px 0px'
});

export const ButtonContainer = styled('div')({
  margin: '15px'
});

export const PurpleText = styled('p')(({ theme, $noMargin }) => ({
  margin: $noMargin ? '0' : '15px 0px 20px 0px',
  color: theme.palette.primary.main
}));

export const CreateAccContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-evenly'
});

export const ImageBanner = styled('div')(props => ({
  width: '100%',
  color: '#222',
  textAlign: 'center',
  cursor: 'pointer',
  backgroundImage: props.background ? `url(${props.background})` : 'none',
  // backgroundImage: 'linear-gradient(45deg, pink, red)',
  backgroundSize: '100% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // height: '150px',
  height: '200px',
  gap: '20px'
}));

export const ImageLeft = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  maxWidth: '350px',
  position: 'relative',
  marginTop: '15px'
});
