import React from 'react';
import { Container, CustomTooltip } from './styles';

export default function IconButton(props) {
  if (props?.disabled) {
    return <Container {...props}>{props?.children}</Container>;
  }

  return (
    <CustomTooltip placement="bottom" title={props?.tooltipTitle}>
      <Container {...props} 
      >{props?.children}</Container>
    </CustomTooltip>
  );
}
