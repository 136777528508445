import SendBird from 'sendbird';
import { getSendbirdID, getSendbirdToken } from '../hooks/useENV';

class Sendbird {
  constructor(appId) {
    this.token = getSendbirdID();
    this.api_token = getSendbirdToken();
    this.sb = new SendBird({ appId });
    this.channelListQuery = null;
    this.closedChannelListQuery = null;
    this.previousMessageQuery = null;
    this.currentChannelUrl = '';
  }

  getInstance() {
    return this.sb;
  }

  /* Connect to sendbird */
  connect(userId) {
    return new Promise((resolve, reject) => {
      this.sb.connect(userId, (userInfo, error) => {
        if (error) {
          reject(error);
        } else {
          resolve(true);
        }
      });
    });
  }

  /* Disconnect */

  disconnect() {
    return new Promise((resolve, reject) => {
      if (this.sb) {
        try {
          this.sb.disconnect(() => {
            resolve(true);
          });
        } catch (error) {
          reject(error);
        }
      } else {
        resolve(false);
      }
    });
  }

  getConnectionStatus() {
    if (this.sb) {
      return this.sb.getConnectionState();
    }
  }

  /* update user */
  updateUserinfo(user) {
    return new Promise((resolve, reject) => {
      const imgUrl = user.profile_url
        ? user.profile_url
        : '//assets.wobbjobs.com/images/shared/default-profile-picture.png';
      this.sb.updateCurrentUserInfo(
        user.name,
        `https:${imgUrl}`,
        (cUser, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(cUser);
          }
        }
      );
    });
  }

  // Check user
  checkUser(id) {
    const baselink = `https://api.sendbird.com/v3/users/${id}`;

    return new Promise((resolve, reject) => {
      fetch(baselink, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Api-Token': this.api_token
        }
      })
        .then(response => {
          const json = response.json();
          resolve(json);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  // Create user
  createUser(uid, name, profileUrl) {
    const baselink = `https://api.sendbird.com/v3/users/`;
    let cProfileUrl = profileUrl;

    if (!profileUrl.includes('https:')) {
      if (
        profileUrl === '' ||
        profileUrl === undefined ||
        profileUrl === null
      ) {
        cProfileUrl =
          'https://assets.wobbjobs.com/images/shared/default-profile-picture.png';
      } else {
        cProfileUrl = `https:${profileUrl}`;
      }
    }

    const data = {
      user_id: uid,
      nickname: name,
      profile_url: cProfileUrl
    };

    return new Promise((resolve, reject) => {
      fetch(baselink, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Api-Token': this.api_token
        },
        body: JSON.stringify(data)
      })
        .then(response => {
          const json = response.json();
          resolve(json);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  // Create User Metadata
  createUserMetadata(data, userId) {
    const baselink = `https://api-${this.token}.sendbird.com/v3/users/${userId}/metadata`;

    return new Promise((resolve, reject) => {
      fetch(baselink, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Api-Token': this.api_token
        },
        body: JSON.stringify(data)
      })
        .then(response => {
          const json = response.json();
          resolve(json);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  /*
  ----------========================== Message Function ==========================----------
  */

  /*
   * get message
   * params:
   * 1)channel
   * 2)refresh
   */
  getMessageList(params = {}) {
    if (params.refresh || this.previousMessageQuery === null) {
      this.previousMessageQuery =
        params.channel.createPreviousMessageListQuery();
    }
    return new Promise((resolve, reject) => {
      if (
        this.previousMessageQuery.hasMore &&
        !this.previousMessageQuery.isLoading
      ) {
        this.previousMessageQuery.load(200, false, (messageList, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(messageList);
          }
        });
      } else {
        resolve([]);
      }
    });
  }

  // send message
  sendMessage(channel, textMessage) {
    return new Promise((resolve, reject) => {
      channel.sendUserMessage(textMessage, (message, error) => {
        if (error) {
          reject(error);
        } else {
          // this.markAsRead({ channel });
          resolve(message);
        }
      });
    });
  }

  adminMessage(channelUrl, adminMsg) {
    const DEFAULT_BASE_URL = 'https://api.sendbird.com/v3/group_channels/';

    const data = {
      message_type: 'ADMM',
      message: adminMsg,
      custom_type: 'entrance_message',
      is_silent: true
    };

    return new Promise((resolve, reject) => {
      fetch(`${DEFAULT_BASE_URL}${channelUrl}/messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Api-Token': this.api_token
        },
        body: JSON.stringify(data)
      })
        .then(response => {
          const json = response.json();
          resolve(json);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  /*
  ----------========================== Group Channel Function ==========================----------
  */

  // create group channel
  createGroupChannel(userIds, name, coverUrl, data, customType) {
    return new Promise((resolve, reject) => {
      this.sb.GroupChannel.createChannelWithUserIds(
        userIds,
        false,
        name,
        coverUrl,
        data,
        customType,
        (createdChannel, error) => {
          if (error) {
            console.log(error);
            reject(error);
          }
          resolve(createdChannel);
        }
      );
    });
  }

  // create group channel metadata
  createGroupChannelMetaData(channel, metaData) {
    return new Promise((resolve, reject) => {
      channel.createMetaData(metaData, (data, error) => {
        if (error) {
          reject(error);
        } else {
          resolve(data);
        }
      });
    });
  }

  // get total unread channel count
  getTotalUnreadChannelCount() {
    return new Promise((resolve, reject) => {
      this.sb.getTotalUnreadChannelCount((count, error) => {
        if (error) {
          reject(error);
        }

        resolve(count);
      });
    });
  }

  // get detail of channel
  getChannelInfo(channelUrl) {
    return new Promise((resolve, reject) => {
      this.sb.GroupChannel.getChannel(channelUrl, (channel, error) => {
        if (error) {
          reject(error);
        } else {
          resolve(channel);
        }
      }).catch(error => {
        reject(error);
      });
    });
  }

  // Update channel meta data
  updateChannelMetaData(channel, metaData) {
    return new Promise((resolve, reject) => {
      channel.updateMetaData(metaData, (response, error) => {
        if (error) {
          reject(error);
        } else {
          resolve(response);
        }
      });
    });
  }

  // channel list handler
  /*
   * Params:
   * 1)uniqueId
   * 2 updateMessages
   */
  createChannelListHandler(params = {}) {
    const channelHandler = new this.sb.ChannelHandler();

    channelHandler.onMessageReceived = (channel, message) => {
      // console.log('Message receive:', channel.url, message);
      params.updateMessages(channel.url, message);
      this.markAsRead({ channel });
    };

    // channelHandler.onReadReceiptUpdated = channel => {
    //
    // };

    // on user left the channel
    channelHandler.onUserLeft = channel => {
      if (params.updateChannel) {
        params.updateChannel(channel, false);
      }
    };

    // on channel change
    // channelHandler.onChannelChanged = (channel) => {

    // };

    // on channel deleted
    // channelHandler.onChannelDeleted = (channelUrl, channelType) => {

    // };

    // on channel metadata changed
    // channelHandler.onMetaDataUpdated = (channel, metaData) => {

    // };

    this.sb.addChannelHandler(params.uniqueId, channelHandler);
  }

  getChannelMetaData = async channel => {
    const metaData = [
      'chat_status',
      'cb_session',
      'cb_status',
      'cb_last_context',
      'job_enable_chatbot',
      'company_name'
    ];

    return new Promise((resolve, reject) => {
      channel.getMetaData(metaData, (data, error) => {
        if (error) {
          console.log(error);
          reject(error);
        } else if (data.cb_session) {
          resolve(data);
        }
      });
    });
  };

  // Mark as Read channel
  markAsRead({ channelUrl, channel }) {
    if (channel) {
      try {
        channel.markAsRead(() => {
          //done mark as read
        });
      } catch (error) {
        //
      }
    } else {
      this.getChannelInfo(channelUrl).then(cChannel =>
        cChannel.markAsRead(() => {
          //done mark as read
        })
      );
    }
  }

  // remove channel handler
  removeChannelHandler(channelHandlerId) {
    this.sb.removeChannelHandler(channelHandlerId);
  }

  updateChannelUrl(url) {
    this.currentChannelUrl = url;
  }
}
export { Sendbird as default };
