import React, { useState, useEffect, useCallback } from 'react';
import { Grid, TextField, Chip, FormControl, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchJobSkillsSuggestions,
  updatePostJobPrevTooltip,
  updatePostJobTooltipStatus
} from '../../../../../redux/actions/job_action';
import {
  SkillFormContainer,
  TextFieldStyled,
  OptionStyled,
  OptionsContainerStyled,
  Spacer,
  ChipStyled,
  CloseIconStyled,
  Text,
  TextContainer,
  Description,
  HeightSpacer
} from './styles';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { TipsTooltip } from '../../../../../components/Tooltips';
import _, { isEmpty } from 'lodash'; // Assuming lodash is imported for debounce
import { useFormContext } from '../../context';
import { useFormData } from '../../helper/useFormData';

export default function Skills() {
  const key = 'skills';
  const dispatch = useDispatch();
  const { formState, handleSingleFieldChange, errorState, handleErrorState } =
    useFormContext();
  const jobDataForm = useSelector(state => state.jobs.jobDataForm);
  const formData = useFormData();
  const [chips, setChips] = useState([]);

  useEffect(() => {
    if (formState?.skills?.length > 0 && isEmpty(chips)) {
      const preloadedSkills = formState?.skills.map(skill => ({
        name: skill?.name
      }));

      setChips(preloadedSkills);
    }
  }, [formData?.skills, formState?.skills]);

  const [inputValue, setInputValue] = useState('');
  const [optionOpen, setOptionOpen] = useState(false);

  const jobSkillsSuggestions = useSelector(
    state => state.jobs.jobSkillsSuggestions
  );
  const tooltipStatus = useSelector(state => state.jobs.tooltipStatus);
  const prevTooltip = useSelector(state => state.jobs.prevTooltip);

  const fetchJobSkills = useCallback(
    _.debounce(value => {
      dispatch(fetchJobSkillsSuggestions(value))
        .then(response => {
          setOptionOpen(true);
        })
        .catch(error => {
          setOptionOpen(false);
        });
    }, 300),
    [dispatch]
  );

  const handleInputChange = event => {
    handleErrorState(errorState.filter(error => error !== key));
    const value = event.target.value;
    setInputValue(value);

    if (value.length >= 3) {
      fetchJobSkills(value);
    } else {
      setOptionOpen(false);
    }
  };

  const handleOptionSelect = newValue => {
    if (!chips?.some(element => element.name === newValue)) {
      setChips(prevChips => [...prevChips, { name: newValue }]);
      setInputValue('');
      setOptionOpen(false);
    }
  };

  const handleDelete = chipToDelete => () => {
    setChips(chips => chips.filter(chip => chip.name !== chipToDelete));
  };

  const options = jobSkillsSuggestions?.map(skills => {
    return {
      value: skills?.name
    };
  });

  const onOptionClick = value => {
    if (formState?.skills?.length > 9) {
      setOptionOpen(false);
      return;
    }
    handleOptionSelect(value);
  };

  useEffect(() => {
    handleSingleFieldChange('skills', chips);
  }, [chips]);

  const onClickTips = key => {
    let status = { ...tooltipStatus };

    if (status[key]) {
      status[key] = false;
      dispatch(updatePostJobTooltipStatus(status));
    } else {
      status[key] = true;
      dispatch(updatePostJobTooltipStatus(status));
    }

    if (prevTooltip.length > 0 && prevTooltip !== key) {
      status[prevTooltip] = false;
      dispatch(updatePostJobTooltipStatus(status));
    }

    dispatch(updatePostJobPrevTooltip(key));
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end'
        }}>
        <Grid container>
          <TextContainer $container={'title-container'}>
            <Text $type="skills-title" variant="h4">
              Skills
            </Text>
          </TextContainer>
          <Spacer width={'10px'} />
          <TipsTooltip
            title={'Skills'}
            descriptions={[
              <Description>
                Listing the skills needed for this position helps candidates
                with relevant skills to discover your job better. We suggest
                displaying hard/software skills.
              </Description>
            ]}
            top={'-120px'}
            left={'70px'}
            totalPages={1}
            onClick={() => onClickTips('skill')}
            cKey={'skill'}
          />
        </Grid>
      </div>
      <HeightSpacer height="5px" />
      <TextContainer $container={'description-container'}>
        <Text $type={'skills-description'} variant="h6">
          You can add a maximum of 10 skills.
        </Text>
        <Typography sx={{ fontSize: '13px' }}>
          {formState?.skills?.length}/10
        </Typography>
      </TextContainer>

      <HeightSpacer height="15px" />

      <SkillFormContainer isError={errorState.includes(key)}>
        <div style={{ position: 'relative' }}>
          <TextFieldStyled
            fullWidth
            // label="Start searching for relevant job skills…"
            placeholder={'Start searching for relevant job skills…'}
            variant="outlined"
            value={inputValue}
            onChange={handleInputChange}
          />

          {optionOpen ? (
            <>
              <OptionsContainerStyled>
                {optionOpen &&
                  options?.map(option => {
                    return (
                      <OptionStyled onClick={() => onOptionClick(option.value)}>
                        <span>{option.value}</span>
                      </OptionStyled>
                    );
                  })}
              </OptionsContainerStyled>
            </>
          ) : null}
        </div>

        {/* Render options logic here if needed */}
        <Grid container spacing={1} sx={{ marginTop: '8px' }}>
          {chips?.map((chip, index) => (
            <Grid item key={index} sx={{ paddingTop: '0 !important' }}>
              <ChipStyled
                label={chip.name}
                onDelete={handleDelete(chip.name)}
                color="primary"
                deleteIcon={<CloseIconStyled />}
              />
              {/* <Spacer width={'8px'} /> */}
            </Grid>
          ))}
        </Grid>
      </SkillFormContainer>

{errorState.includes(key) && (
        <p
        style={{
          color: '#BE4242',
          fontSize: '12px',
          lineHeight: '20px',
          display: 'flex',
          padding: '0px 8px',
          alignItems: 'center',
          gap: '8px'
        }}>
        <ExclamationCircleIcon
          style={{
            width: '20px',
            height: '20px',
            color: '#BE4242'
          }}
        />
        Skills is required
      </p>
      )}

      <HeightSpacer height="4px" />
    </div>
  );
}
