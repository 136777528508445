import clevertap from 'clevertap-web-sdk';
import React, { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { hotjar } from 'react-hotjar';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter, RouterProvider } from 'react-router-dom';
import store from '../src/redux/stores/store';
import { getCountry } from './hooks/useRegionalisation';
import { initializeGTM } from './hooks/useEventTracking';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { appendCountryToUrl } from './hooks/useLocalStorage';
import { loadFontAwesome } from './utils/FontAwesomeUtils';
import { initDatadog, setUserToDatadog } from './hooks/useDatadog';
import RoutesWrapper from './navigation/Routes';
import { router } from './routes';
// import TagManager from 'react-gtm-module';

let cleverTapInitRetryCount = 0;

export default function App() {
  loadFontAwesome();
  hotjar.initialize('2738290', '6');
  appendCountryToUrl();
  const gtmParams = initializeGTM();

  if (getCountry() == 'MY') {
    clevertap.init(process.env.REACT_APP_CLEVERTAP, 'sg1');
  }

  let accountInfo = localStorage.getItem('accountInfo');
  // const tagManagerArgs = {
  //   gtmId: 'GTM-PHRMVQD'
  // };

  // TagManager.initialize(tagManagerArgs);

  const triggerCleverTap = () => {
    setTimeout(() => {
      try {
        clevertap.onUserLogin.push({
          Site: {
            Name: accountInfo.companyName ?? '-', // String
            Identity: accountInfo.companyId ?? '-', // String or number
            Email: accountInfo.email ?? '-' // Email address of the user
            // Phone: 'null', // Phone (with the country code)
            // Gender: 'null', // Can be either M or F
            // DOB: 'null' // Date of Birth. Date object
          }
        });
      } catch (error) {
        console.error('clevertap_error:', error);

        if (error && cleverTapInitRetryCount < 6) {
          cleverTapInitRetryCount++;
          triggerCleverTap();
        }
      }
    }, 1000);
  };

  useEffect(() => {
    // Check UTM params when page initially loads to store into cookies for ads sign up tracking
    const params = new URL(location.href).searchParams;
    const utm_medium = params.get('utm_medium');
    const referral_token = params.get('referral_token');
    const URLparams = window?.location.search.substr(1);

    // if (utm_medium?.length > 0 && document.cookie.indexOf('params') == -1) {
    //   document.cookie = `params=${URLparams}; max-age=604800`;
    // } else if (document.cookie.indexOf('params') != -1) {
    // }

    if (!document.cookie.includes('gtmCountry')) {
      const country = localStorage.getItem('country');
      document.cookie = `gtmCountry=${country}; path=/; max-age=31540000`;
    }

    if (utm_medium?.length > 0 || referral_token?.length > 0) {
      // Always override cookie whenever there is utm param in the url
      document.cookie = `params=${URLparams}; max-age=604800`;
    }
  }, []);

  useEffect(() => {
    try {
      accountInfo =
        typeof accountInfo === 'string' ? JSON.parse(accountInfo) : accountInfo;
    } catch (error) {
      console.error('Failed to parse accountInfo:', error);
      accountInfo = null; // or handle the error as needed
    }

    if (getCountry() == 'MY') {
      if (accountInfo) {
        triggerCleverTap();
      }
    }
  }, [accountInfo]);

  useEffect(() => {
    const companyName = accountInfo?.companyName;
    const email = accountInfo?.email;
    const accountId = accountInfo?.companyId;

    // initialise datadog on staging / prod
    initDatadog();
    if (accountInfo) {
      setUserToDatadog(accountId, email, companyName);
    }
  }, []);

  return (
    <GTMProvider state={gtmParams}>
      <DndProvider backend={HTML5Backend}>
        {/* <BrowserRouter> */}
        <StoreProvider store={store}>
          {/* <RoutesWrapper /> */}
          <RouterProvider router={router} />
        </StoreProvider>
        {/* </BrowserRouter> */}
      </DndProvider>
    </GTMProvider>
  );
}
