import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import { PopUpDialog } from '../../../../../components';
import { ErrorToast, SuccessToast } from '../../../../../utils/ToastUtils';
import { AFFILIATION_TYPE } from '../../../../../utils/Constants';
import styles from './index.module.scss';
import { MUIButtonStyled } from '../../../../../components/MUIButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCompanyAffiliations,
  mutateCompanyAffiliation
} from '../../../../../redux/actions/company_actions';

const ChangeRelationshipPopUp = ({
  open,
  handleClose,
  id,
  name,
  affiliationType,
  affiliatedCompanyId
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(affiliationType);
  const mutatingCompanyAffiliate = useSelector(
    state => state.companies.mutatingCompanyAffiliate
  );

  const onSaveAffiliation = async () => {
    dispatch(
      mutateCompanyAffiliation({
        input: {
          id,
          affiliatedCompanyId,
          affiliationType: value
        }
      })
    )
      .then(async res => {
        if (res.type === 'MUTATE_COMPANY_AFFILIATION_SUCCEED') {
          SuccessToast('Company Affiliation Saved Successfully!');
          await dispatch(fetchCompanyAffiliations());
          handleClose();
        }
      })
      .catch(err => {
        ErrorToast(err.message);
      });
  };

  useEffect(() => {
    if (open) {
      setValue(affiliationType);
    }
  }, [affiliationType, open]);

  return (
    <PopUpDialog
      open={open}
      handleClose={handleClose}
      title={`Change ${name}'s Affiliation Type?`}>
      <div style={{ padding: '5px 20px 30px 20px' }}>
        <div className={styles.contentContainer}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="affiliateType"
              value={value}
              onChange={event => setValue(event.target.value)}>
              <FormControlLabel
                value={AFFILIATION_TYPE.SUBSIDIARY}
                control={
                  <Radio color="default" className={styles.radioButtonColor} />
                }
                label={<p className={styles.typeText}>Subsidiary</p>}
              />
              <FormControlLabel
                value={AFFILIATION_TYPE.SISTER}
                control={
                  <Radio color="default" className={styles.radioButtonColor} />
                }
                label={<p className={styles.typeText}>Sister</p>}
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div className={styles.buttonContainer}>
          <MUIButtonStyled
            large
            outlinedBlack
            label="Cancel"
            onClick={handleClose}
          />
          <MUIButtonStyled
            large
            label="Save"
            onClick={onSaveAffiliation}
            disabled={mutatingCompanyAffiliate}
          />
        </div>
      </div>
    </PopUpDialog>
  );
};

export default ChangeRelationshipPopUp;
