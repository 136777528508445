import { styled as styling } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Title = styling('div')(props => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const Text = styling(Typography)(props => ({
  ...(props.type == 'title' && {
    fontFamily: '20px',
    fontSize: '20px',
    margin: '0 0 5px',
    fontWeight: 700,
  }),

  ...(props.type == 'reminder' && {
    color: '#666666',
    fontFamily: 'Inter',
    fontSize: '13px',
    margin: '15px 0',
  }),

  ...(props.type == 'basic' && {
    fontFamily: 'Inter',
    fontSize: '15px',
    margin: '15px 0',
  }),
}));

export const ContentContainer = styling('div')(props => ({
  ...(props.type == 'two-column' && {
    display: 'grid',
    gridTemplateColumns: '10% auto',
    marginTop: '12px',
  }),

  ...(props.type == 'three-column' && {
    display: 'grid',
    gridTemplateColumns: '10% auto auto',
    marginTop: '12px',
  }),
}));

export const IconContainer = styling('div')(props => ({
  ...(props.type == 'basic' && {
    display: 'flex',
    alignSelf: 'center',
  }),

  ...(props.type == 'pointer' && {
    display: 'flex',
    alignSelf: 'center',
    cursor: 'pointer',
  }),
}));

export const FontAwesomeIconStyled = styling(FontAwesomeIcon)(props => ({
  color: '#222',
  margin: '0 1px',
}));

export const ButtonContainer = styling('div')(props => ({
  display: 'flex',
  justifyContent: 'center',
  margin: '25px 0',
}));
