import React from 'react';
import {
  CheckCircleIconStyled,
  CloseIconStyled,
  ContentContainer,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
  IconButtonStyled,
  ImageContainer,
  ListStyled,
  TitleContainer,
  TitleDescStyled,
  TitleStyled,
  TypographySpan,
  TypographyStyled,
  XCircleIconStyled
} from './styles';
import { Grid } from '@mui/material';
import var1 from '../../../../../assets/images/company/variety_1.png';
import var2 from '../../../../../assets/images/company/variety_2.png';
import var3 from '../../../../../assets/images/company/variety_3.png';
import var4 from '../../../../../assets/images/company/variety_4.png';
import rep1 from '../../../../../assets/images/company/repetitive_1.png';
import rep2 from '../../../../../assets/images/company/repetitive_2.png';
import rep3 from '../../../../../assets/images/company/repetitive_3.png';
import rep4 from '../../../../../assets/images/company/repetitive_4.png';
import { Typography } from '@mui/material';

export default function InfoPopUp(props) {
  const { open, handleClose } = props;
  return (
    <DialogStyled open={open} onClose={handleClose}>
      <DialogTitleStyled>
        <TitleContainer>
          <TitleStyled>
            More Info: <TypographySpan>Company Photos</TypographySpan>
          </TitleStyled>
          <TitleDescStyled>
            Here are some tips on the types of photos to present to our
            jobseekers!
          </TitleDescStyled>
        </TitleContainer>
        <IconButtonStyled
          aria-label="close"
          onClick={handleClose}
          sx={{ padding: '0px' }}>
          <CloseIconStyled />{' '}
        </IconButtonStyled>
      </DialogTitleStyled>

      <DialogContentStyled dividers>
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Grid
            sx={{
              fontSize: '14px',
              color: 'rgba(0, 0, 0, 0.87)',
              lineHeight: '24px',
              letterSpacing: '0.15px'
            }}>
            Give our jobseekers a sneak peek into your office environment with
            high-quality photos of:
            <ListStyled>
              <li>Lively office spaces with great lighting</li>
              <li>Employees interacting / enjoying activities together</li>
              <li>Company events or team photos</li>
            </ListStyled>
          </Grid>

          <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Typography
              sx={{
                fontSize: '14px',
                color: '#000',
                fontWeight: 700,
                fontFamily: 'inter'
              }}>
              Tips:
            </Typography>

            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}>
              <Grid
                sx={{
                  width: '268px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px'
                }}>
                <Grid
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 2fr)',
                    gap: '4px',
                    rowGap: '4px'
                  }}>
                  <img src={var1} width={132} />
                  <img src={var2} width={132} />
                  <img src={var4} width={132} />
                  <img src={var3} width={132} />
                </Grid>
                <Grid
                  sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                  <CheckCircleIconStyled />
                  <Typography
                    sx={{
                      fontSize: '14px',
                      color: '#000',
                      fontFamily: 'inter'
                    }}>
                    Variety photos
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                sx={{
                  width: '268px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px'
                }}>
                <Grid
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 2fr)',
                    gap: '4px',
                    rowGap: '4px'
                  }}>
                  <img src={rep2} width={132} />
                  <img src={rep4} width={132} />
                  <img src={rep1} width={132} />
                  <img src={rep3} width={132} />
                </Grid>
                <Grid
                  sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                  <XCircleIconStyled />
                  <Typography
                    sx={{
                      fontSize: '14px',
                      color: '#000',
                      fontFamily: 'inter'
                    }}>
                    Repetitive photos
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContentStyled>
    </DialogStyled>
  );
}
