// FETCH APPLICATIONS
export const FETCH_APPLICATIONS = 'FETCH_APPLICATIONS';
export const FETCH_APPLICATIONS_SUCCEED = 'FETCH_APPLICATIONS_SUCCEED';
export const FETCH_APPLICATIONS_FAILED = 'FETCH_APPLICATIONS_FAILED';
export const UPDATE_APPLICATION_CURRENT_PAGE =
  'UPDATE_APPLICATION_CURRENT_PAGE';

// FETCH UNDECIDED APPLICATIONS
export const FETCH_UNDECIDED_APPLICATIONS = 'FETCH_UNDECIDED_APPLICATIONS';
export const FETCH_UNDECIDED_APPLICATIONS_SUCCEED =
  'FETCH_UNDECIDED_APPLICATIONS_SUCCEED';
export const FETCH_UNDECIDED_APPLICATIONS_FAILED =
  'FETCH_UNDECIDED_APPLICATIONS_FAILED';

// UPDATE UNDECIDED APPLICATIONS
export const UPDATE_UNDECIDED_APPLICATIONS = 'UPDATE_UNDECIDED_APPLICATIONS';

// FETCH KIV APPLICATIONS
export const FETCH_KIV_APPLICATIONS = 'FETCH_KIV_APPLICATIONS';
export const FETCH_KIV_APPLICATIONS_SUCCEED = 'FETCH_KIV_APPLICATIONS_SUCCEED';
export const FETCH_KIV_APPLICATIONS_FAILED = 'FETCH_KIV_APPLICATIONS_FAILED';

// UPDATE KIV APPLICATIONS
export const UPDATE_KIV_APPLICATIONS = 'UPDATE_KIV_APPLICATIONS';

// FETCH SHORTLISTED APPLICATIONS
export const FETCH_SHORTLISTED_APPLICATIONS = 'FETCH_SHORTLISTED_APPLICATIONS';
export const FETCH_SHORTLISTED_APPLICATIONS_SUCCEED =
  'FETCH_SHORTLISTED_APPLICATIONS_SUCCEED';
export const FETCH_SHORTLISTED_APPLICATIONS_FAILED =
  'FETCH_SHORTLISTED_APPLICATIONS_FAILED';

// UPDATE SHORTLISTED APPLIATIONS
export const UPDATE_SHORTLISTED_APPLICATIONS =
  'UPDATE_SHORTLISTED_APPLICATIONS';

// FETCH REJECTED APPLICATIONS
export const FETCH_REJECTED_APPLICATIONS = 'FETCH_REJECTED_APPLICATIONS';
export const FETCH_REJECTED_APPLICATIONS_SUCCEED =
  'FETCH_REJECTED_APPLICATIONS_SUCCEED';
export const FETCH_REJECTED_APPLICATIONS_FAILED =
  'FETCH_REJECTED_APPLICATIONS_FAILED';

// FETCH INTERVIEWS
export const FETCH_INTERVIEWS = 'FETCH_INTERVIEWS';
export const FETCH_INTERVIEWS_SUCCEED = 'FETCH_INTERVIEWS_SUCCEED';
export const FETCH_INTERVIEWS_FAILED = 'FETCH_INTERVIEWS_FAILED';

// FETCH OFFERS
export const FETCH_OFFERS = 'FETCH_OFFERS';
export const FETCH_OFFERS_SUCCEED = 'FETCH_OFFERS_SUCCEED';
export const FETCH_OFFERS_FAILED = 'FETCH_OFFERS_FAILED';

// VIEW APPLICATION
export const VIEW_APPLICATION = 'VIEW_APPLICATION';
export const VIEW_APPLICATION_SUCCEED = 'VIEW_APPLICATION_SUCCEED';
export const VIEW_APPLICATION_FAILED = 'VIEW_APPLICATION_FAILED';

// UPDATE REJECTED APPLIATIONS
export const UPDATE_REJECTED_APPLICATIONS = 'UPDATE_REJECTED_APPLICATIONS';

// UPDATE APPLICATION STATE
export const UPDATE_APPLICATION_STATE = 'UPDATE_APPLICATION_STATE';
export const UPDATE_APPLICATION_STATE_SUCCEED =
  'UPDATE_APPLICATION_STATE_SUCCEED';
export const UPDATE_APPLICATION_STATE_FAILED =
  'UPDATE_APPLICATION_STATE_FAILED';

// UPDATE MULTIPLE APPLICATION STATE
export const UPDATE_MULTIPLE_APPLICATION_STATE =
  'UPDATE_MULTIPLE_APPLICATION_STATE';
export const UPDATE_MULTIPLE_APPLICATION_STATE_SUCCEED =
  'UPDATE_MULTIPLE_APPLICATION_STATE_SUCCEED';
export const UPDATE_MULTIPLE_APPLICATION_STATE_FAILED =
  'UPDATE_MULTIPLE_APPLICATION_STATE_FAILED';

// UPDATE APPLICATION COUNTS
export const UPDATE_APPLICATION_COUNTS = 'UPDATE_APPLICATION_COUNTS';

// UPDATE ALL APPLICATIONS
export const UPDATE_ALL_APPLICATIONS = 'UPDATE_ALL_APPLICATIONS';

// UPDATE CANDIDATE SNAPSHOT STATUS
export const UPDATE_CANDIDATE_SNAPSHOT_STATUS =
  'UPDATE_CANDIDATE_SNAPSHOT_STATUS';

// UPDATE CURRENT CANDIDATE INDEX
export const UPDATE_CURRENT_CANDIDATE_INDEX = 'UPDATE_CURRENT_CANDIDATE_INDEX';

// UPDATE APPLICATION SELECT ALL STATUS
export const UPDATE_APPLICATION_SELECT_ALL_STATUS =
  'UPDATE_APPLICATION_SELECT_ALL_STATUS';

// UPDATE INTERVIEWS
export const UPDATE_INTERVIEWS = 'UPDATE_INTERVIEWS';

// UPDATE OFFERS
export const UPDATE_OFFERS = 'UPDATE_OFFERS';

// SCHEDULE INTERVIEW
export const SCHEDULE_INTERVIEW = 'SCHEDULE_INTERVIEW';
export const SCHEDULE_INTERVIEW_SUCCEED = 'SCHEDULE_INTERVIEW_SUCCEED';
export const SCHEDULE_INTERVIEW_FAILED = 'SCHEDULE_INTERVIEW_FAILED';

// ADD APPLICATION NOTE
export const ADD_APPLICATION_NOTE = 'ADD_APPLICATION_NOTE';
export const ADD_APPLICATION_NOTE_SUCCEED = 'ADD_APPLICATION_NOTE_SUCCEED';
export const ADD_APPLICATION_NOTE_FAILED = 'ADD_APPLICATION_NOTE_FAILED';

// CLOSE THE LOOP
export const CLOSE_LOOP = 'CLOSE_LOOP';
export const CLOSE_LOOP_SUCCEED = 'CLOSE_LOOP_SUCCEED';
export const CLOSE_LOOP_FAILED = 'CLOSE_LOOP_FAILED';

// UPDATE FIRST SCHEDULE INTERVIEW MODAL
export const UPDATE_FIRST_SCHEDULE_INTERVIEW_MODAL_STATUS =
  'UPDATE_FIRST_SCHEDULE_INTERVIEW_MODAL_STATUS';

// UPDATE NEXT SCHEDULE INTERVIEW MODAL
export const UPDATE_NEXT_SCHEDULE_INTERVIEW_MODAL_STATUS =
  'UPDATE_NEXT_SCHEDULE_INTERVIEW_MODAL_STATUS';

// UPDATE CHANGE STATE FAILED IDS
export const UPDATE_CHANGE_STATE_FAILED_IDS = 'UPDATE_CHANGE_STATE_FAILED_IDS';

// UPDATE INTERVIEW STATUS
export const UPDATE_INTERVIEW_STATUS = 'UPDATE_INTERVIEW_STATUS';
export const UPDATE_INTERVIEW_STATUS_SUCCEED =
  'UPDATE_INTERVIEW_STATUS_SUCCEED';
export const UPDATE_INTERVIEW_STATUS_FAILED = 'UPDATE_INTERVIEW_STATUS_FAILED';

// UPDATE DRAGGING STATE
export const UPDATE_DRAGGING_STATE = 'UPDATE_DRAGGING_STATE';

// UPDATE IS DRAGGING INTERVIEW STATUS
export const UPDATE_IS_DRAGGING_INTERVIEW_STATUS =
  'UPDATE_IS_DRAGGING_INTERVIEW_STATUS';

// ADD INTERVIEW NOTE
export const ADD_INTERVIEW_NOTE = 'ADD_INTERVIEW_NOTE';
export const ADD_INTERVIEW_NOTE_SUCCEED = 'ADD_INTERVIEW_NOTE_SUCCEED';
export const ADD_INTERVIEW_NOTE_FAILED = 'ADD_INTERVIEW_NOTE_FAILED';

// UPDATE INTERVIEW NOTE
export const UPDATE_INTERVIEW_NOTE = 'UPDATE_INTERVIEW_NOTE';
export const UPDATE_INTERVIEW_NOTE_SUCCEED = 'UPDATE_INTERVIEW_NOTE_SUCCEED';
export const UPDATE_INTERVIEW_NOTE_FAILED = 'UPDATE_INTERVIEW_NOTE_FAILED';

//UPDATE INTERVIEW INFO
export const UPDATE_INTERVIEW_INFO = 'UPDATE_INTERVIEW_INFO';

//TOGGLE REJECT POPUP
export const TOGGLE_REJECT_POPUP = 'TOGGLE_REJECT_POPUP';

//UPDATE INTERVIEW DETAILS
export const UPDATE_INTERVIEW_DETAILS = 'UPDATE_INTERVIEW_DETAILS';
export const UPDATE_INTERVIEW_DETAILS_SUCCEED =
  'UPDATE_INTERVIEW_DETAILS_SUCCEED';
export const UPDATE_INTERVIEW_DETAILS_FAILED =
  'UPDATE_INTERVIEW_DETAILS_FAILED';

//DELETE INTERVIEW
export const DELETE_INTERVIEW = 'DELETE_INTERVIEW';
export const DELETE_INTERVIEW_SUCCEED = 'DELETE_INTERVIEW_SUCCEED';
export const DELETE_INTERVIEW_FAILED = 'DELETE_INTERVIEW_FAILED';

//UPDATE_INTERVIEW_DATA
export const UPDATE_INTERVIEW_DATA = 'UPDATE_INTERVIEW_DATA';

//TRIGGER BULK REJECTION POPUP
export const TRIGGER_BULK_REJECT_POPUP = 'TRIGGER_BULK_REJECT_POPUP';

// GET ATS COLUMN CURRENT STATE
export const GET_CURRENT_STATE = 'GET_CURRENT_STATE';

// GET PUBLIC JOB APPLICATION
// export const FETCH_PUBLIC_JOB_APPLICATION = 'FETCH_PUBLIC_JOB_APPLICATION';
// export const FETCH_PUBLIC_JOB_APPLICATION_SUCCEED =
//   'FETCH_PUBLIC_JOB_APPLICATION_SUCCEED';
// export const FETCH_PUBLIC_JOB_APPLICATION_FAILED =
//   'FETCH_PUBLIC_JOB_APPLICATION_FAILED';

//TOGGLE_CLOSE_LOOP_POPUP
export const TOGGLE_CLOSE_LOOP_POPUP = 'TOGGLE_CLOSE_LOOP_POPUP';

//UPDATE_APPLICATTION_PARAMS
export const UPDATE_APPLICATTION_PARAMS = 'UPDATE_APPLICATTION_PARAMS';

//UPDATE_JOB_APPLICATIONS
export const UPDATE_JOB_APPLICATIONS = 'UPDATE_JOB_APPLICATIONS';

//UPDATE_BLACKLIST_POPUP
export const UPDATE_BLACKLIST_POPUP = 'UPDATE_BLACKLIST_POPUP';

//UPDATE_APPLICATION_USER_IDS
export const UPDATE_APPLICATION_USER_IDS = 'UPDATE_APPLICATION_USER_IDS';

//BULK_DOWNLOAD_RESUME
export const BULK_DOWNLOAD_RESUME = 'BULK_DOWNLOAD_RESUME';
export const BULK_DOWNLOAD_RESUME_SUCCEED = 'BULK_DOWNLOAD_RESUME_SUCCEED';
export const BULK_DOWNLOAD_RESUME_FAILED = 'BULK_DOWNLOAD_RESUME_FAILED';
