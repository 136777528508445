export async function useDeleteVimeoVideo({ id }) {
  const accessToken = process.env.REACT_APP_VIMEO_TOKEN;

  const url = `https://api.vimeo.com/videos/${id}`;
  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      Accept: 'application/vnd.vimeo.*+json;version=3.4'
    }
  };

  try {
    const response = await fetch(url, options);
    const data = await response.json();

    if (data?.error) {
      ErrorToast(data?.error);
    }
  } catch (error) {
    return error;
  }
}
