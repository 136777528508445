import {
    Button,
    DialogContent,
    IconButton,
    Typography,
    Grid,
    FormGroup,
    Checkbox
  } from '@mui/material';
  import { Dialog, DialogTitle, TextField } from '@mui/material';
  import { styled as styling } from '@mui/material/styles';
  import LoadingButton from '@mui/lab/LoadingButton';
  
  export const DialogStyled = styling(Dialog)(props => ({
    borderRadius: '16px',
    '& .MuiPaper-root': {
      minWidth: '738px',
      borderRadius: '16px'
    }
  }));
  
  export const DialogTitleStyled = styling(DialogTitle)(props => ({
    padding: '16px 16px 16px 24px',
    borderBottom: '1px solid #D8D8D8'
  }));
  
  export const DialogContentStyled = styling(DialogContent)(props => ({
    padding: '32px 32px 24px 32px'
  }));
  
  export const DialogCloseButton = styling(IconButton)(props => ({
    position: 'absolute',
    top: 8,
    right: 8
  }));
  
  export const DialogTitleText = styling(Typography)(props => ({
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: '0.1px'
  }));
  
  export const SubmitButton = styling(Button)(props => ({
    borderRadius: '8px',
    backgroundColor: '#2E2E2E',
    width: '200px',
    height: '40px',
    padding: '0 16px',
    color: 'white',
    fontWeight: '700',
    textTransform: 'capitalize',
    fontSize: '14px',
    fontFamily: 'Inter'
  }));
  
  export const TitleContainer = styling(Grid)(props => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '30px'
  }));
  
  export const DefaultGrid = styling(Grid)(props => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '50%'
  }));
  
  export const OuterContainer = styling(Grid)(props => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '35px'
  }));
  
  export const LabelStyled = styling(Typography)(props => ({
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px'
  }));
  
  export const TextFieldStyled = styling(TextField)(props => ({
    width: '325px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
  
      '& fieldset': {
        border: '2px solid #AEAEAE'
      },
      '&:hover fieldset': {
        border: '2px solid #512acc'
      }
    },
  
    '& .MuiInputBase-input': {
      padding: '16px 24px'
    }
  }));
  
  export const TextFieldContainer = styling(Grid)(props => ({
    display: 'flex',
    rowGap: '28px',
    justifyContent: 'space-between'
  }));
  
  export const CheckboxContainer = styling(Grid)(props => ({
    display: 'flex',
    gap: '10px',
    flexDirection: 'column'
  }));
  
  export const FormGroupStyled = styling(FormGroup)(props => ({
    display: 'flex',
    gap: '56px',
    flexDirection: 'row',
    marginLeft: '2%'
  }));
  
  export const CheckboxStyled = styling(Checkbox)(props => ({
    color: 'transparent',
    overflow: 'hidden',
    '&.MuiCheckbox-root': {
      borderRadius: '8px',
      border: '1px solid #AEAEAE',
      width: 32,
      height: 32,
      marginRight: 26
    },
    '& .MuiSvgIcon-root': {
      fontSize: '32px'
    },
  
    '&.Mui-checked .MuiSvgIcon-root': {
      transform: 'scale(1.7)'
    },
    '&.Mui-checked': {
      color: '#512acc',
      width: 32,
      height: 32
    },
    '&.MuiCheckbox-root:hover': {
      backgroundColor: 'transparent'
    },
    '& .MuiSvgIcon-root': {
      borderRadius: '24px'
    }
  }));
  
  export const TextAreaContainer = styling(Grid)(props => ({
    display: 'flex',
    gap: '14px',
    flexDirection: 'column'
  }));
  
  export const TextAreaStyled = styling(TextField)(props => ({
    width: '100%',
    '& .MuiInputBase-input': {
      height: '140px !important'
    },
    '& .MuiOutlinedInput-root': {
      alignItems: 'flex-start',
      height: '180px !important',
      '& .MuiInputBase-multiline': {
        height: '180px' // Removed !important to avoid specificity issues
      },
      '& fieldset': {
        border: '2px solid #AEAEAE',
        height: '180px',
        borderRadius: '8px'
      },
      '&:hover fieldset': {
        border: '2px solid #512acc' // Hover effect for the border
      }
    },
    '& .MuiInputBase-inputMultiline': {
      height: '140px !important',
      padding: '2px 4px',
      height: '100%', // Use 100% to fill the parent's height
      overflow: 'auto'
    }
  }));
  
  export const ButtonContainer = styling(Grid)(props => ({
    display: 'flex',
    justifyContent: 'center'
  }));
  
  export const ButtonStyled = styling(LoadingButton)(props => ({
    fontSize: '16px',
    fontWeight: 700,
    borderRadius: '100px',
    background: '#2E2E2E',
    color: 'white',
    textTransform: 'none',
    width: '200px',
    height: '48px',
  
    '&:hover': {
      background: '#2E2E2E'
    },
  
    '& .MuiLoadingButton-loadingIndicator': {
      color: '#512acc',
      fontSize: '32px !important'
    }
  }));