import React from 'react';
import {
  CloseIconStyled,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
  IconButtonStyled,
  TitleContainer,
  TitleStyled
} from './styles';
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import {
  ButtonSkipEdit,
  ButtonText
} from '../../../../../components/VerifyPopUp/styles';

export default function RemovePopUp(props) {
  const { open, handleClose, selected, handleRemoveVideo } = props;

  const removeList = [
    {
      status: 'Live on Hiredly',
      title: 'Remove content?',
      description:
        'By continuing, this video will be removed and will no longer appear your company profile shown to jobseekers. Would you like to continue?'
    },
    {
      status: 'In Review',
      title: 'Cancel Review Request?',
      description:
        'This will cancel your review request for this video. Would you like to continue?'
    },
    {
      status: 'Rejected',
      title: 'Remove Content?',
      description:
        'By continuing, this video will be removed and this action cannot be undone. Would you like to continue?'
    }
  ];

  const type = removeList?.find(type => type?.status === selected?.status);

  return (
    <DialogStyled open={open} onClose={handleClose}>
      <DialogTitleStyled>
        <TitleContainer>
          <TitleStyled>{type?.title}</TitleStyled>
        </TitleContainer>
        <IconButtonStyled
          aria-label="close"
          onClick={handleClose}
          sx={{ padding: '0px' }}>
          <CloseIconStyled />{' '}
        </IconButtonStyled>
      </DialogTitleStyled>

      <DialogContentStyled dividers>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px'
            // padding: '16px 24px'
          }}>
          <Typography sx={{ fontSize: '16px', lineHeight: '24px' }}>
            {type?.description}
          </Typography>
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}>
            <ButtonText sx={{ height: '42px' }} onClick={() => handleClose()}>
              No Thanks
            </ButtonText>
            <ButtonSkipEdit
              sx={{ height: '42px' }}
              onClick={() => {
                handleClose();
                handleRemoveVideo({
                  id: selected?.id,
                  videoTab: selected?.videoTab,
                  status: selected?.status
                });
              }}>
              Confirm
            </ButtonSkipEdit>
          </Grid>
        </Grid>
      </DialogContentStyled>
    </DialogStyled>
  );
}
