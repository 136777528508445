import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../styles/colors.module.scss';

export const TitleContainer = styling('div')((props) => ({
  padding: props.$titleContainerPadding ? '12px 24px' : '12px 48px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const Title = styling('span')({
  fontFamily: 'Inter',
  fontWeight: 700,
  fontSize: '20px',
});
