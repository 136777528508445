import { styled as styling } from '@mui/material/styles';
import { BoltIcon } from '@heroicons/react/24/solid';
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import { ReceiptPercentIcon } from '@heroicons/react/24/solid';
import { ChevronUpIcon } from '@heroicons/react/24/outline';

export const BundleInnerContainer = styling(Grid)(props => ({
  backgroundColor: '#E9ECF8',
  padding: '8px',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px'
}));

export const BundleContainer = styling(Grid)(props => ({
  border: '1px solid #213DB5',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  overflow: 'hidden',
  marginBottom: "16px",
}));

export const ReceiptPercentIconStyled = styling(ReceiptPercentIcon)(props => ({
  color: '#213DB5',
  height: '20px',
  width: '20px'
}));

export const ChevronUpIconStyled = styling(ChevronUpIcon)(props => ({
  color: '#00000099',
  height: '20px',
  width: '20px',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  transform: props?.open ? `rotate(0deg)` : `rotate(180deg)`
}));

export const DefaultContainer = styling(Grid)(props => ({}));

export const BundleTitle = styling(Typography)(props => ({
  color: '#213DB5',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '20px'
}));

export const BundleText = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.60)`,
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  flexWrap: 'wrap'
}));

export const BundleDiscountText = styling(Typography)(props => ({
  color: `#BE4242`,
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px'
}));


export const BulletPoints = styling('ul')(props => ({
  margin: "0px"
  }));