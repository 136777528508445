import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import Grid from '@mui/material/Grid';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import moment from 'moment';
import { debounce } from 'lodash';
import { TextInputFunction } from '../SharedTextInput/SharedTextInput';
import { useDispatch, useSelector } from 'react-redux';
import {
  Title,
  LabelStyled,
  SortByContainer,
  CardsContainer,
  CardStyled,
  CardContentStyled,
  TextContainer,
  ApplicantCardSection,
  ChipStyled
} from './styles';
import SkeletonLoader from './SkeletonLoader';
import Linkify from 'react-linkify';
import {
  fetchTalentChats,
  updateTalentChats
} from '../../../redux/actions/chat_action';
import { sortWorkingExperiences } from '../../../helpers/data_management';
import store from '../../../redux/stores/store';

const sortingConstant = {
  UNREAD_DESC: {
    key: 'UNREAD_DESC',
    label: 'Unread Chat First',
    sortBy: 'unread_chat',
    order: 'desc'
  },

  CREATED_AT_DESC: {
    key: 'CREATED_AT_DESC',
    label: 'Most Recent',
    sortBy: 'created_at',
    order: 'desc'
  },

  CREATED_AT_ASC: {
    key: 'CREATED_AT_ASC',
    label: 'Earliest',
    sortBy: 'created_at',
    order: 'asc'
  }
};

let loadingTalentChats = false;
let currentSearchText = '';
let cSearchText = '';
let cOrderDirection = 'desc';
const fetchTalentChatToken = 'fetch-talent-chat-token';

const TalentChatSectionComponent = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [currentApplicationId, setCurrentApplicationId] = useState('');
  const [orderDirection, setOrderDirection] = useState(
    sortingConstant.UNREAD_DESC.order
  );
  const [currentSort, setCurrentSort] = useState(sortingConstant.UNREAD_DESC);

  const [searchApplicantsArrowDrop, setSearchApplicantsArrowDrop] =
    useState(null);
  const { updateChatChannel, updateApplication } = props;

  // Selectors
  const isFetchingTalentChats = useSelector(
    state => state.chats.isFetchingTalentChats
  );
  const talentChatsReachedEnd = useSelector(
    state => state.chats.talentChatsReachedEnd
  );
  const talentChatsPageInfo = useSelector(
    state => state.chats.talentChatsPageInfo
  );
  const talentChats = useSelector(state => state.chats.talentSearchChats);

  const loadTalentChats = async (refresh, isPerformSearch) => {
    if (
      (loadingTalentChats ||
        isFetchingTalentChats ||
        (talentChatsReachedEnd && !refresh)) &&
      !isPerformSearch
    )
      return;

    loadingTalentChats = true;

    if (isPerformSearch) {
      if (store.getState().axios.cancelTokens[fetchTalentChatToken]) {
        store
          .getState()
          .axios.cancelTokens[fetchTalentChatToken].cancel('fetch-talent-chat');
      }
    }

    if (refresh) {
      dispatch(updateTalentChats([]));
      updateChatChannel('');
      setCurrentApplicationId('');
    }

    const params = {
      keyword: cSearchText,
      after: refresh ? '' : talentChatsPageInfo?.endCursor || '',
      sort: { by: currentSort.sortBy, direction: currentSort.order },
      refresh: refresh,
      cancelTokenKey: fetchTalentChatToken
    };

    dispatch(fetchTalentChats(params)).then(() => {
      loadingTalentChats = false;

      if (cSearchText) {
        window.dataLayer.push({
          event: 'CE_search-talent-search-chats',
          'search-term': cSearchText,
          'sort-by': `${currentSort.sortBy}_${currentSort.order}`
        });
      }
    });
  };

  const getCurrentSearchText = () => currentSearchText;
  const getOrderDirection = () => orderDirection;

  // Application list management
  const handleApplicationClicked = talentChat => {
    if (currentApplicationId === talentChat.id) return;

    setCurrentApplicationId(talentChat.id); // active indicator
    updateChatChannel(
      talentChat.sendbirdChannelUrl
        ? talentChat.sendbirdChannelUrl
        : 'no-channel'
    );
    updateApplication(talentChat);
  };

  //  To allow parent to call this function
  useImperativeHandle(ref, () => ({
    getCurrentSearchText,
    getOrderDirection
  }));

  const performSearch = async text => {
    if (text === currentSearchText) {
      cSearchText = text;
      loadTalentChats(true, true);
    }
  };

  const debouncedSearch = debounce(performSearch, 700);

  const handleScroll = e => {
    //  Reference: https://stackoverflow.com/questions/45585542/detecting-when-user-scrolls-to-bottom-of-div-with-react-js/49573628
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      loadTalentChats(false, false);
    }
  };

  const handleChangeSearchApplicants = async event => {
    currentSearchText = event.target.value;
    debouncedSearch(event.target.value);
  };

  const handleSearchApplicantArrowDropClick = event => {
    setSearchApplicantsArrowDrop(event.currentTarget);
  };

  const handleCloseOrderMenu = value => {
    setSearchApplicantsArrowDrop(null);

    if (value !== '' && value !== orderDirection) {
      cOrderDirection = value;
      setOrderDirection(value);

      setCurrentSort(sortingConstant[value] ?? {});

      // Reload list on order direction changed
      // loadTalentChats(true, false);
    }
  };

  // act as a callback for when currentsort state is changed
  useEffect(() => {
    loadTalentChats(true, false);
  }, [currentSort]);

  // Extract application information
  const getApplicantName = talentChat => {
    if (talentChat.user?.name) {
      return talentChat.user.name;
    }

    return '';
  };

  const getShortSummary = talentChat => {
    if (talentChat.user?.shortSummary) {
      return talentChat.user.shortSummary;
    }
    return '';
  };

  const getWorkingExperiences = cTalent => {
    if (cTalent?.workingExperiences?.edges) {
      const experiences = cTalent.workingExperiences.edges.map(
        item => item.node
      );

      return sortWorkingExperiences(experiences);
    }

    return [];
  };

  const getLatestExperience = talentChat => {
    if (!talentChat.user) return '-';
    const talent = talentChat.user;
    let latestExperience = '';

    const experiences = getWorkingExperiences(talent);

    if (talent?.freshGrad) {
      latestExperience = `${talent.fieldOfStudy} Graduate`;
    } else if (experiences.length > 0) {
      const experience = experiences[0];

      if (experience?.jobTitle) {
        latestExperience = `${experience.jobTitle}${
          experience.companyName && ` at ${experience.companyName}`
        }`;
      }
    }

    // none of the above
    // something else - temporary trying to show field of study graduate
    if (!latestExperience) {
      if (talent?.fieldOfStudy) {
        latestExperience = `${talent.fieldOfStudy} Graduate`;
      } else {
        latestExperience = '-';
      }
    }

    return latestExperience;
  };

  const getUnlockedDate = talentChat => {
    if (talentChat.user?.unlockedAt) {
      return moment(talentChat.user.unlockedAt).format('Do MMM YYYY');
    }

    return '';
  };

  // POTENTIAL BUG
  useEffect(() => {
    return () => {
      loadingTalentChats = false;
      currentSearchText = '';
      cSearchText = '';
      cOrderDirection = 'desc';
    };
  }, []);

  return (
    <ApplicantCardSection onScroll={handleScroll}>
      <>
        <TextInputFunction
          origin={'talent_chat_section'}
          type="text"
          label="applicant-search-bar"
          placeholder="Search talents by keyword"
          handleChange={handleChangeSearchApplicants}
        />
      </>

      <SortByContainer>
        <LabelStyled sort_by="true">
          Sort by <b>{currentSort.label}</b>
        </LabelStyled>
        <ArrowDropDownIcon
          fontSize="small"
          style={{ marginLeft: '5px', cursor: 'pointer' }}
          onClick={handleSearchApplicantArrowDropClick}
        />
        <Menu
          id="date-created-menu"
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          anchorEl={searchApplicantsArrowDrop}
          keepMounted
          MenuProps={{
            PaperProps: {
              style: {
                background: '#fff'
              }
            }
          }}
          open={Boolean(searchApplicantsArrowDrop)}
          onClose={() => handleCloseOrderMenu('')}
          getContentAnchorEl={null}>
          <MenuItem
            onClick={() =>
              handleCloseOrderMenu(sortingConstant.UNREAD_DESC.key)
            }
            selected={orderDirection === sortingConstant.UNREAD_DESC.key}>
            {sortingConstant.UNREAD_DESC.label}
          </MenuItem>
          <MenuItem
            onClick={() =>
              handleCloseOrderMenu(sortingConstant.CREATED_AT_DESC.key)
            }
            selected={orderDirection === sortingConstant.CREATED_AT_DESC.key}>
            {sortingConstant.CREATED_AT_DESC.label}
          </MenuItem>
          <MenuItem
            onClick={() =>
              handleCloseOrderMenu(sortingConstant.CREATED_AT_ASC.key)
            }
            selected={orderDirection === sortingConstant.CREATED_AT_ASC.key}>
            {sortingConstant.CREATED_AT_ASC.label}
          </MenuItem>
        </Menu>
      </SortByContainer>

      <CardsContainer>
        {talentChats &&
          talentChats.map(talentChat => {
            return (
              <CardStyled
                key={talentChat.id}
                elevation={0}
                active={
                  currentApplicationId === talentChat.id ? 'true' : 'false'
                }
                onClick={() => handleApplicationClicked(talentChat)}>
                <CardContentStyled applicant_card_height="false">
                  <TextContainer>
                    <Title>{getApplicantName(talentChat)}</Title>
                    <Grid style={{ margin: '10px 0' }}>
                      <LabelStyled applicant_card_info="true">
                        <Linkify>{getLatestExperience(talentChat)}</Linkify>
                      </LabelStyled>
                    </Grid>
                    <Grid
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}>
                      <Grid
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                        <WatchLaterIcon
                          fontSize="small"
                          style={{ marginRight: '10px', color: '#aeaeae' }}
                        />
                        <LabelStyled applicant_card_info_time="true">
                          {getUnlockedDate(talentChat)}
                        </LabelStyled>
                      </Grid>
                    </Grid>
                  </TextContainer>
                </CardContentStyled>
              </CardStyled>
            );
          })}
      </CardsContainer>

      {!isFetchingTalentChats &&
        talentChatsReachedEnd &&
        talentChats.length === 0 && (
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            No active chats.
          </div>
        )}
      {!isFetchingTalentChats &&
        talentChatsReachedEnd &&
        talentChats.length !== 0 && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            End of list.
          </div>
        )}
      {isFetchingTalentChats &&
        Array.from(['0', '1', '2', '3', '4', '5']).map(index => (
          <SkeletonLoader key={index} />
        ))}
    </ApplicantCardSection>
  );
});

export default TalentChatSectionComponent;
