import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {  useNavigate, useParams } from 'react-router-dom';
import {
  SwitchStyled,
  BottomBox,
  BottomActionBox,
  SkipButton,
  SaveButton,
  MainBox,
  Title,
  SubTitle,
  CountryDisplay,
  CountryChip,
  QuestionTitle,
  AvailableRow,
  RefreshButton,
  TokenAvailable,
  MainContainer,
  MainContainerBox,
  ExpandableBar,
  ExpandableBarSwitch,
  ExpandableBarTitle,
  MiddleMessage,
  MiddleMessageText,
  QuestionBox,
  ChevronDownIconStyled,
  CircleStackIconStyled,
  ArrowPathIconStyled,
  ExpandedContent,
  PurchaseBox,
  ModalTextPurple,
  SelectionSection,
  OptionBox,
  ArrangementTitle,
  ArrangementText,
  CheckboxSection,
  CheckboxText,
  CheckboxStyled
} from './styles';
import { FlagIcon } from 'react-flag-kit';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

const CrossBorderPopUp = ({
  open,
  handleClose,
  useAsPopUp,
  jobId,
  isInPopUp,
  crossBorder,
  setCrossBorder,
  onRefresh,
  tokenCount,
  globalHireDefault,
  setGlobalHireDefault,
  fields,
  setFields,
  setOverlay,
  setErrorsCrossBorder,
  errorsCrossBorder
}) => {
  const history = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [jobQuestions, setJobQuestions] = useState([]);

  const [isExpanded, setIsExpanded] = useState(isInPopUp);

  const isMasterAccount =
    localStorage.getItem('currentAccountType') === 'master';

  const [crossBorderToggle, setCrossBorderToggle] = useState(
    isInPopUp || crossBorder.length > 0
  );

  const originCountry = localStorage.getItem('originCountry');

  useEffect(() => {
    if (!crossBorderToggle) {
      setCrossBorder({ crossBorderDestinations: [] });
      setGlobalHireDefault(false);
      setOverlay(false);
      setIsExpanded(false);
    } else {
      setFields(prevFields => ({
        ...prevFields,
        enabled: true
      }));
      setIsExpanded(true);
      setGlobalHireDefault(true);

      let destinations = '';
      if (originCountry == 'MY') {
        destinations = 'SG';
      } else if (originCountry == 'SG') {
        destinations = 'MY';
      }
      setCrossBorder({ crossBorderDestinations: [destinations] });

      if (!fields?.enabled) {
        setTimeout(() => {
          setOverlay(true);
        }, 1800);
      }
    }
  }, [crossBorderToggle]);

  useEffect(() => {
    if (crossBorderToggle) {
      if (crossBorder?.crossBorderDestinations?.length == 0) {
        setErrorsCrossBorder(true);
      } else {
        setErrorsCrossBorder(false);
      }
    } else {
      setErrorsCrossBorder(false);
    }
  }, [crossBorder]);

  const enableScroll = () => {
    window.onscroll = function () {};
  };

  const disableScroll = () => {
    // Get the current page scroll position
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    // if any scroll is attempted, set this to the previous value
    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop);
    };
  };

  const onClose = () => {
    if (Boolean(open)) {
      disableScroll();
      setTimeout(() => {
        enableScroll();
      }, 500);
    }

    setFields({});
    handleClose();
  };

  const handleRefresh = async () => {
    await onRefresh();
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFF',
      background: '#FFF',
      maxWidth: 350,
      border: '1.5px solid #222',
      borderRadius: '8px',
      display: 'inline-flex',
      // height: '27px',
      // padding: '4px 8px',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }));

  const renderCheckbox = (destination, label, isDefault) => (
    <div style={{ display: 'flex', gap: '12px', alignItems: 'flex-start' }}>
      {isDefault ? (
        <HtmlTooltip
          title={
            <React.Fragment>
              <p
                style={{
                  color: '#222',
                  textAlign: 'center',
                  fontSize: '10px',
                  fontWeight: '500',
                  lineHeight: '14px',
                  margin: '0'
                }}>
                You cannot deselect where this job was originally posted.
              </p>
            </React.Fragment>
          }
          placement="top">
          <div>
            <CheckboxStyled
              name={destination}
              color="default"
              disabled={isDefault || (!isDefault && !crossBorderToggle)}
              inputProps={{
                'aria-label': `checkbox with default color for ${label}`
              }}
              checked={
                crossBorder?.crossBorderDestinations?.includes(destination) ||
                isDefault
              }
              onChange={e => {
                const isChecked = e.target.checked;

                if (isChecked) {
                  // Add the destination to the array
                  setCrossBorder(prevFields => ({
                    ...prevFields,
                    crossBorderDestinations: [
                      ...prevFields.crossBorderDestinations,
                      destination
                    ]
                  }));
                } else {
                  // Remove the destination from the array
                  setCrossBorder(prevFields => ({
                    ...prevFields,
                    crossBorderDestinations:
                      prevFields.crossBorderDestinations.filter(
                        dest => dest !== destination
                      )
                  }));
                }
              }}
            />
          </div>
        </HtmlTooltip>
      ) : (
        <div>
          <CheckboxStyled
            name={destination}
            color="default"
            disabled={isDefault || (!isDefault && !crossBorderToggle)}
            inputProps={{
              'aria-label': `checkbox with default color for ${label}`
            }}
            checked={
              crossBorder?.crossBorderDestinations?.includes(destination) ||
              isDefault
            }
            onChange={e => {
              const isChecked = e.target.checked;

              if (isChecked) {
                // Add the destination to the array
                setCrossBorder(prevFields => ({
                  ...prevFields,
                  crossBorderDestinations: [
                    ...prevFields.crossBorderDestinations,
                    destination
                  ]
                }));
              } else {
                // Remove the destination from the array
                setCrossBorder(prevFields => ({
                  ...prevFields,
                  crossBorderDestinations:
                    prevFields.crossBorderDestinations.filter(
                      dest => dest !== destination
                    )
                }));
              }
            }}
          />
        </div>
      )}
      <div>
        <CheckboxText
          type={
            isDefault ? 'disabled' : !crossBorderToggle ? 'disabled' : 'title2'
          }>
          {label} {isDefault && <i>(default)</i>}
        </CheckboxText>
      </div>
    </div>
  );

  const renderContent = () => (
    <MainContainer isInPopUp={isInPopUp}>
      <MainContainerBox>
        {/* top title bar */}
        <MainBox isInPopUp={isInPopUp} isExpanded={isExpanded}>
          <ExpandableBar isExpanded={isExpanded}>
            <ExpandableBarTitle>
              <Title isDisabled={tokenCount == 0}>
                Apply Cross Border Tokens
              </Title>
              {isInPopUp ? (
                <SubTitle isInPopUp={isInPopUp}>
                  Each token applied activates your job listing in the selected
                  additional countries for 30 days.
                </SubTitle>
              ) : (
                <SubTitle isDisabled={tokenCount == 0}>
                  Each token applied activates your job listing in the selected
                  countries for 30 days.
                </SubTitle>
              )}
            </ExpandableBarTitle>

            {tokenCount == 0 ? (
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <p
                      style={{
                        color: '#222',
                        textAlign: 'center',
                        fontSize: '10px',
                        fontWeight: '500',
                        lineHeight: '14px',
                        margin: '0'
                      }}>
                      Uh oh, you do not have sufficient Cross Border Tokens.
                    </p>
                  </React.Fragment>
                }
                placement="top">
                <ExpandableBarSwitch isInPopUp={isInPopUp}>
                  <SwitchStyled
                    name="active"
                    checked={crossBorderToggle}
                    onChange={() =>
                      setCrossBorderToggle(prevState => !prevState)
                    }
                    disabled={tokenCount == 0}
                    $width={46}
                    $height={24}
                    $thumbWidth={18}
                    $thumbHeight={18}
                  />
                  <ChevronDownIconStyled
                    onClick={() => setIsExpanded(prevFields => !prevFields)}
                    isExpanded={isExpanded}
                  />
                </ExpandableBarSwitch>
              </HtmlTooltip>
            ) : (
              <ExpandableBarSwitch isInPopUp={isInPopUp}>
                <SwitchStyled
                  name="active"
                  checked={crossBorderToggle}
                  onChange={() => setCrossBorderToggle(prevState => !prevState)}
                  disabled={tokenCount == 0}
                  $width={46}
                  $height={24}
                  $thumbWidth={18}
                  $thumbHeight={18}
                />
                <ChevronDownIconStyled
                  onClick={() => setIsExpanded(prevFields => !prevFields)}
                  isExpanded={isExpanded}
                />
              </ExpandableBarSwitch>
            )}
          </ExpandableBar>

          <CountryDisplay>
            <SubTitle
              $color={'black'}
              isDisabled={tokenCount == 0}
              isInPopUp={isInPopUp}>
              Currently advertised in:
            </SubTitle>

            {originCountry == 'MY' ? (
              <CountryChip>
                <FlagIcon
                  size={10}
                  code={'MY'}
                  style={{
                    boxShadow: 'rgba(0, 0, 0, 0.25) 1px 1px 2px',
                    width: '14px',
                    opacity: '0.4'
                  }}
                />
                Malaysia
              </CountryChip>
            ) : (
              <CountryChip>
                <FlagIcon
                  size={10}
                  code={'SG'}
                  style={{
                    boxShadow: 'rgba(0, 0, 0, 0.25) 1px 1px 2px',
                    width: '14px',
                    opacity: '0.4'
                  }}
                />
                Singapore
              </CountryChip>
            )}
          </CountryDisplay>

          <ExpandedContent isExpanded={isExpanded}>
            <QuestionBox isError={errorsCrossBorder}>
              <QuestionTitle isInPopUp={isInPopUp}>
                <AvailableRow>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <p
                          style={{
                            color: '#222',
                            textAlign: 'center',
                            fontSize: '10px',
                            fontWeight: '500',
                            lineHeight: '14px',
                            margin: '0'
                          }}>
                          Bought new tokens? Refresh to see your latest
                          purchase.
                        </p>
                      </React.Fragment>
                    }
                    placement="top">
                    <RefreshButton onClick={handleRefresh}>
                      <ArrowPathIconStyled />
                    </RefreshButton>
                  </HtmlTooltip>
                  <TokenAvailable>
                    <CircleStackIconStyled />
                    {tokenCount > 0 ? (
                      <>{tokenCount} Tokens Available</>
                    ) : (
                      <>No Tokens Available</>
                    )}
                  </TokenAvailable>
                </AvailableRow>

                <PurchaseBox
                  onClick={() =>
                    window.open(
                      '/purchase/choose/?tab=visibility_boosters&products=cross_border_token-1'
                    )
                  }>
                  <ModalTextPurple>Purchase Now</ModalTextPurple>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none">
                    <path
                      d="M9 4.5H3.5C2.67157 4.5 2 5.17157 2 6V13C2 13.8284 2.67157 14.5 3.5 14.5H10.5C11.3284 14.5 12 13.8284 12 13V7.5M5 11.5L14 2.5M14 2.5L10.5 2.5M14 2.5V6"
                      stroke="#512ACC"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </PurchaseBox>
              </QuestionTitle>

              <SelectionSection>
                <OptionBox>
                  <ArrangementTitle>
                    <ArrangementText row={'1'} disabled={!crossBorderToggle}>
                      Where would you like to advertise your job to?
                    </ArrangementText>

                    <ArrangementText row={'2'} disabled={!crossBorderToggle}>
                      Each selection will take up 1 Cross Border Token
                    </ArrangementText>
                  </ArrangementTitle>

                  {originCountry == 'MY' ? (
                    <>
                      {renderCheckbox('MY', 'Malaysia', true)}
                      {renderCheckbox('SG', 'Singapore', false)}
                    </>
                  ) : (
                    <>
                      {renderCheckbox('SG', 'Singapore', true)}
                      {renderCheckbox('MY', 'Malaysia', false)}
                    </>
                  )}
                </OptionBox>
              </SelectionSection>
            </QuestionBox>
          </ExpandedContent>

          {errorsCrossBorder && (
            <p
              style={{
                color: '#BE4242',
                fontSize: '12px',
                lineHeight: '20px',
                display: 'flex',
                padding: '0px 8px',
                alignItems: 'center',
                gap: '8px',
                margin: '0px'
              }}>
              <ExclamationCircleIcon
                style={{
                  width: '20px',
                  height: '20px',
                  color: '#BE4242'
                }}
              />
              Please select the country where you wish to advertise this job
              post
            </p>
          )}
        </MainBox>
      </MainContainerBox>
    </MainContainer>
  );

  return <div style={{ width: '100%' }}>{renderContent()}</div>;
};

CrossBorderPopUp.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  useAsPopUp: PropTypes.bool,
  jobId: PropTypes.string
};

CrossBorderPopUp.defaultProps = {
  open: false,
  handleClose: () => {},
  useAsPopUp: true,
  jobId: null
};

export default CrossBorderPopUp;
