import React from 'react';
import { CheckCircle, MessageContainer, XCircle } from './styles';

const Message = ({ danger = false, children, ...props }) => {
  return (
    <MessageContainer $danger={danger} {...props}>
      <span>
        {danger ? <XCircle /> : <CheckCircle />}
      </span>
      <p>
        {children}
      </p>
    </MessageContainer>
  );
};

export default Message;
