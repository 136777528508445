import * as types from '../types/order_type';
import api from '../../utils/api';
import { useId } from 'react';
const { REACT_APP_API_VERSION } = process.env;

const orderAttributes = `
 success
`;

const clearOrderInfo = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.CLEAR_ORDER_INFO
      })
    );
  });
};

const updateSelectedItem = id => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_SELECTED_ITEM,
        selectedItem: id
      })
    );
  });
};

const cancelOrder =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.CANCELING_ORDER });

    const mutationInput = `orderId: "${params.orderId}"`;

    const payload = {
      query: `mutation {
          cancelOrder(input: {${mutationInput}}) {
              ${orderAttributes}          
          }
         }
        `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data.data?.cancelOrder.success) {
            const status = response.data.data.cancelOrder.success;
            return dispatch({
              type: types.CANCEL_ORDER_SUCCEED,
              payload: {
                status: status
              }
            });
          } else {
            return dispatch({
              type: types.CANCEL_ORDER_FAILED,
              payload: {
                errorMessage: response.data.errors[0].message
              }
            });
          }
        }
        return dispatch({
          type: types.CANCEL_ORDER_FAILED,
          payload: {
            errorMessage: response.data.errors[0].message
          }
        });
      })
      .catch(error => {
        return dispatch({
          type: types.CANCEL_ORDER_FAILED,
          payload: {
            errorMessage: response.data.errors[0].message
          }
        });
      });
  };

const fetchCart =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_CART
    });

    const payload = {
      query: `{
        myCart {
          id
          status
          cartLists {
            pageInfo {
              startCursor
              endCursor
            }
            edges {
              cursor
              node {
                id
                quantity
                totalPriceBeforeDiscount
                totalPriceAfterDiscount
                product {
                  id
                  productCode
                  packageType
                  title
                  originalPrice
                  price
                  priceWithoutTax
                  productCode
                  productImage
                  productsInBundle
                }
              }
            }
          }
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.myCart) {
            const myCart = response?.data?.data?.myCart;
            return dispatch({
              type: types.FETCH_CART_SUCCEED,
              myCart: myCart,
              unavailable: response?.data?.errors?.(0)?.message
            });
          }
        }
        return dispatch({
          type: types.FETCH_CART_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_CART_FAILED
        });
      });
  };

const fetchMyOrders =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_MY_ORDERS
    });

    const payload = {
      query: `{
        myOrders(
          invoiceNumberOrCreatedBy: "${params?.invoice_number_or_created_by}"
          orderStatus: "${params?.order_status}"
          sort: { by: "${params?.sort_key}", direction: "${params?.sort_direction}" }
          offset: ${params?.offset}
          after: "${params?.after}"
          before: "${params?.before}"
        ) {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
          edges {
            node {
              id
              cart {
                id
                status
                cartLists {
                  nodes {
                    product {
                      title
                      productPriceChanged
                      active
                      productCode
                    }
                    quantity
                    
                  }
                }
              }
              company {
                userName
              }
              totalAmount
              invoiceNumber
              gstAmount
              orderStatus
              createdAt
              updatedAt
              paidAt
              invoiceLink
              receiptLink
              companyTeamMember {
                id
                name
              }
            }
          }
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.myOrders) {
            const myOrders = response?.data?.data?.myOrders?.edges;
            const pageInfo = response?.data?.data?.myOrders?.pageInfo;
            const totalCount = response?.data?.data?.myOrders?.totalCount;

            return dispatch({
              type: types.FETCH_MY_ORDERS_SUCCEED,
              myOrders: myOrders,
              pageInfo: pageInfo,
              totalCount: totalCount
            });
          }
        }
        return dispatch({
          type: types.FETCH_MY_ORDERS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_MY_ORDERS_FAILED
        });
      });
  };

const fetchMyOrder =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_MY_ORDER
    });

    const payload = {
      query: `{
        myOrder(orderId: "${params?.orderId}") {
          id
          cart {
            cartLists {
              nodes {
                totalPriceBeforeDiscount
                totalPriceAfterDiscount
                product {
                  title
                  price
                  productPriceChanged
                }
                quantity
              }
            }
          }
          totalAmount
          gstAmount
          orderStatus
          createdAt
          updatedAt
          paidAt
          invoiceLink
          companyTeamMember {
            id
            name
          }
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.myOrder) {
            const myOrder = response?.data?.data?.myOrder;
            return dispatch({
              type: types.FETCH_MY_ORDER_SUCCEED,
              myOrder: myOrder
            });
          }
        }
        return dispatch({
          type: types.FETCH_MY_ORDER_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_MY_ORDER_FAILED
        });
      });
  };

const fetchCartApplicableVouchers =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_CART_APPLICABLE_VOUCHERS
    });

    const payload = {
      query: `{
          cartApplicableVouchers {
          id
          promoCode
          discount
          discountImage
          active
          expiredAt
          createdAt
          updatedAt
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.cartApplicableVouchers) {
            const cartApplicableVouchers =
              response?.data?.data?.cartApplicableVouchers;
            return dispatch({
              type: types.FETCH_CART_APPLICABLE_VOUCHERS_SUCCEED,
              cartApplicableVouchers: cartApplicableVouchers
            });
          }
        }
        return dispatch({
          type: types.FETCH_CART_APPLICABLE_VOUCHERS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_CART_APPLICABLE_VOUCHERS_FAILED
        });
      });
  };

const mutateCart =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_CART
    });

    const data = params.input;

    const input = `
      ${data.productId ? `productId: ${data.productId},` : ''}
      ${data.quantity ? `quantity: ${data.quantity},` : ''}
      ${data.productCode ? `productCode: ${data.productCode},` : ''}
  `;

    const payload = {
      query: `mutation {
        addToCart(input: {${input}}) {
          success
          message
          promoCode
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.addToCart) {
            const addToCart = response?.data?.data?.addToCart;

            const id = new Date();

            async function pauseExecution() {
              await new Promise(resolve => setTimeout(resolve, 2000));
            }

            await pauseExecution();

            return dispatch({
              type: types.MUTATE_CART_SUCCEED,
              addToCart: addToCart,
              selectedItem: data.productId,
              unavailable: response?.data?.errors?.(0)?.message,
              id: id
            });
          }
        }
        return dispatch({
          type: types.MUTATE_CART_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_CART_FAILED,
          error: error
        });
      });
  };

const mutateCartRemove =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_CART
    });

    const data = params.input;
    const input = `
      ${data.cartListId ? `cartListId: ${data.cartListId}` : ''}
  `;

    const payload = {
      query: `mutation {
        removeFromCart(input: {${input}}) {
          success
        }
       }
      `
    };
    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.removeFromCart) {
            const removeFromCart = response?.data?.data?.removeFromCart;

            return dispatch({
              type: types.MUTATE_CART_SUCCEED,
              removeFromCart: removeFromCart
            });
          }
        }
        return dispatch({
          type: types.MUTATE_CART_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_CART_FAILED,
          error: error
        });
      });
  };

const mutateCartQuantity =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_CART
    });

    const data = params.input;

    const input = `
      ${data.cartListId ? `cartListId: ${data.cartListId},` : ''}
      ${data.quantity ? `quantity: ${data.quantity},` : ''}
  `;

    const payload = {
      query: `mutation {
        updateCartListQuantity(input: {${input}}) {
          success
        }
       }
      `
    };
    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.updateCartListQuantity) {
            const updateCartListQuantity =
              response?.data?.data?.updateCartListQuantity;

            async function pauseExecution() {
              await new Promise(resolve => setTimeout(resolve, 2000));
            }

            await pauseExecution();

            return dispatch({
              type: types.MUTATE_CART_SUCCEED,
              updateCartListQuantity: updateCartListQuantity
            });
          }
        }
        return dispatch({
          type: types.MUTATE_CART_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_CART_FAILED,
          error: error
        });
      });
  };

const mutateCartSummary =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_CART_SUMMARY
    });

    const data = params.input;
    const input = `
      ${data.cartId ? `cartId: ${data.cartId},` : ''}
      ${
        data.promoCodeString
          ? `promoCodeString: "${data.promoCodeString}",`
          : ''
      }
  `;

    const payload = {
      query: `mutation {
        cartSummary(input: {${input}}) {
          cartSummary {
            id
            totalAmount
            packageDiscountAmount
            discountVoucherAmount
            discountVoucherPercentage
            totalExcludingTax
            serviceTaxPercentage
            totalIncludingTax
            taxAmount
          }
        }
       }
      `
    };
    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.cartSummary) {
            const cartSummary = response?.data?.data?.cartSummary.cartSummary;
            return dispatch({
              type: types.MUTATE_CART_SUMMARY_SUCCEED,
              cartSummary: cartSummary
            });
          }
        }
        return dispatch({
          type: types.MUTATE_CART_SUMMARY_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_CART_SUMMARY_FAILED,
          error: error
        });
      });
  };

const mutateCheckoutOrder =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_CART_SUMMARY
    });

    const data = params.input;

    const input = `
      ${
        data.promoCodeString ? `promoCodeString: "${data.promoCodeString}"` : ''
      }
  `;

    const payload = {
      query: `mutation {
        checkoutOrder(input: {${input}}) {
          order {
            id
            gstAmount
            totalAmount
            orderStatus
            transactionId
            orderDescription
            adminUser
            orderNotes
            paymentId
            paidAt
            transactionId
            cart {
              id
            }
          }
        }
       }
      `
    };
    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.checkoutOrder) {
            const checkoutOrder = response?.data?.data?.checkoutOrder.order;
            return dispatch({
              type: types.MUTATE_CART_SUMMARY_SUCCEED,
              checkoutOrder: checkoutOrder
            });
          }
        }
        return dispatch({
          type: types.MUTATE_CART_SUMMARY_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_CART_SUMMARY_FAILED,
          error: error
        });
      });
  };

const mutateOrderSummary =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_CART_SUMMARY
    });

    const data = params.input;
    const input = `
      ${data.orderId ? `orderId: ${data.orderId}` : ''}
  `;

    const payload = {
      query: `mutation {
        orderSummary(input: {${input}}) {
          orderSummary {
            id
            totalAmount
            totalExcludingTax
            totalIncludingTax
            serviceTaxPercentage
            discountVoucherPercentage
            voucherCodeApplied
            invoiceNumber
            invoiceLink
            bundleDiscountAmount
            voucherDiscountAmount
            taxAmount
          }
        }
       }
      `
    };
    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.orderSummary) {
            const orderSummary =
              response?.data?.data?.orderSummary.orderSummary;
            return dispatch({
              type: types.MUTATE_CART_SUMMARY_SUCCEED,
              orderSummary: orderSummary
            });
          }
        }
        return dispatch({
          type: types.MUTATE_CART_SUMMARY_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_CART_SUMMARY_FAILED,
          error: error
        });
      });
  };

const mutatePaymentLink =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_PAYMENT_LINK
    });

    const data = params.input;
    const input = `
      ${data.orderId ? `orderId: ${data.orderId}` : ''}
  `;

    const payload = {
      query: `mutation {
        generateEghlPaymentLink(input: {${input}}) {
          paymentLink
        }
       }
      `
    };
    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.generateEghlPaymentLink) {
            const generateEghlPaymentLink =
              response?.data?.data?.generateEghlPaymentLink.paymentLink;
            return dispatch({
              type: types.MUTATE_PAYMENT_LINK_SUCCEED,
              eghlPaymentLink: generateEghlPaymentLink
            });
          }
        }
        return dispatch({
          type: types.MUTATE_PAYMENT_LINK_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_PAYMENT_LINK_FAILED,
          error: error
        });
      });
  };

const updateSelectedProduct = index => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_SELECTED_PRODUCT_TAB,
        selectedTab: index
      })
    );
  });
};

const updateProductInfoDialog = input => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UDPATE_PRODUCT_INFO_DIALOG,
        infoDialog: input
      })
    );
  });
};

const updateInfoDialogState = input => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UDPATE_INFO_DIALOG_STATE,
        openInfoDialog: input
      })
    );
  });
};

export {
  cancelOrder,
  fetchCart,
  fetchMyOrders,
  fetchMyOrder,
  fetchCartApplicableVouchers,
  mutateCart,
  mutateCartRemove,
  mutateCartQuantity,
  mutateCartSummary,
  mutateCheckoutOrder,
  mutateOrderSummary,
  mutatePaymentLink,
  updateSelectedProduct,
  updateProductInfoDialog,
  updateInfoDialogState,
  clearOrderInfo,
  updateSelectedItem
};
