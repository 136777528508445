import { useState } from 'react';
import { useFormContext } from '../context';
import { useNavigate } from 'react-router-dom';
import {
  fetchDraftJob,
  mutateCreateDraftJob,
  updateJobDataForm
} from '../../../../redux/actions/job_action';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorToast, SuccessToast } from '../../../../utils/ToastUtils';
import { checkError } from '../helper/checkError';
import * as yup from 'yup';
import { validationSchema } from '../helper/validation';

export default function useDraftLogic(props) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const jobDataForm = useSelector(state => state?.jobs?.jobDataForm);
  const [draftLoading, setLoading] = useState(false);
  const [openTokenPopUp, setOpenTokenPopUp] = useState(false);

  if (props?.confirm) {
    const onSaveDraftJobConfirm = async () => {
      try {
        await validationSchema.validate(props?.formState, {
          abortEarly: false
        });

        setLoading(true);
        const res = await dispatch(
          mutateCreateDraftJob({
            input: props?.formState ? props?.formState : jobDataForm
          })
        );

        if (res?.type === 'MUTATE_CREATE_DRAFT_JOB_SUCCEED') {
          history('/jobs');
          SuccessToast('Job draft saved!');
          dispatch(fetchDraftJob());
          dispatch(updateJobDataForm());
        } else {
          ErrorToast(res?.error);
        }
      } catch (err) {
        let errors;
        err?.inner?.forEach(error => {
          errors = error.message;
        });

        ErrorToast(errors);
        if (!errors) {
          ErrorToast('An error occurred while saving the job draft.');
        }
      } finally {
        setLoading(false);
      }
    };

    return { onSaveDraftJobConfirm, draftLoading };
  }

  const { formState, handleErrorState, showIntern, showSenior } =
    useFormContext();
  const buttonText = 'Next';

  const requiredFields = [
    'location',
    'stateRegion',
    'title',
    'description',
    'requirements',
    'careerLevel',
    'skills',
    'jobType',
    'tracks',
    'customEmail'
    // 'minSalaryRange',
    // 'maxSalaryRange'
  ];

  let jobTitle;
  if (showIntern) {
    jobTitle = 'intern';
  } else if (showSenior) {
    jobTitle = 'senior';
  }

  const saveJob = async () => {
    try {
      await validationSchema.validate(formState, { abortEarly: false });

      const error = checkError(formState, requiredFields);

      if (error?.length > 0) {
        handleErrorState(error);
        return;
      }

      dispatch(updateJobDataForm(formState));
      if (showIntern || showSenior) {
        setOpenTokenPopUp(true);
        return;
      }

      history(`/jobs/draft-job/job-type`);
    } catch (err) {
      let errors;
      err.inner.forEach(error => {
        errors = error.message;
      });

      ErrorToast(errors);
    }
  };

  return { buttonText, saveJob, openTokenPopUp, setOpenTokenPopUp, jobTitle };
}
