import { SparklesIcon } from '@heroicons/react/24/outline';
import { Grid } from '@mui/material';
import { styled as styling } from '@mui/material/styles';
import Colors from '../../styles/colors.module.scss';

export const Container = styling('div')((props) => ({
  background: Colors.priWhite,
  outline: `2px solid ${Colors.terLightGrey}`,
  borderRadius: '8px',
  padding: '20px',

  ...(props.$origin == 'ats' && {
    margin: '20px 0',
    padding: '10px',
  }),
}));

export const ChipContainer = styling('div')({
  background: Colors.priBlack,
  padding: '2px 8px',
  height: '20px',
  width: '46px',
  borderRadius: '6px',
  display: 'flex',
  alignItems: 'center',
});

export const ChipTag = styling('div')({
  fontFamily: 'Inter',
  fontWeight: '400',
  fontSize: '10px',
  letterSpacing: '1.5px',
  textTransform: 'uppercase',
  color: Colors.priWhite,
});

export const SparklesIconStyled = styling(SparklesIcon)({
  height: '20px',
  width: '20px',
});

export const TitleContainer = styling(Grid)((props) => ({
  background: Colors.secLightPurple,
  border: `2px solid ${Colors.priBlack}`,
  boxShadow: `2px 2px 0px ${Colors.priBlack}`,
  borderRadius: '10px',
  padding: '6px 8px',
  width: '50%',
  maxWidth: '480px',

  ...(props.$origin == 'ats' && {
    width: '85%',
  }),
}));

export const Title = styling('span')({
  fontFamily: 'Inter',
  fontWeight: '700',
  fontSize: '14px',
  letterSpacing: '0.1px',
  color: 'rgba(0, 0, 0, 0.87)',
});

export const Notice = styling('span')({
  fontFamily: 'Inter',
  fontStyle: 'italic',
  fontWeight: '300',
  fontSize: '12px',
});
