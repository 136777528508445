import React from 'react';
import { pdfjs } from 'react-pdf';
import SkeletonLoader from './SkeletonLoader';
import { FILE_EXTENSION_TYPE } from '../../../../../utils/Constants';
import styles from './index.module.scss';
import { ResumeIframe } from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const CandidateResume = ({
  candidate,
  loading,
  isSelected,
  isCandidatesEmpty
}) => {
  const fileExtType = candidate?.user?.resume.slice(-5).split('.')[1];
  const renderResume = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ResumeIframe
          title="Resume"
          src={
            fileExtType === FILE_EXTENSION_TYPE.PDF
              ? candidate?.user?.resume
              : `https://view.officeapps.live.com/op/view.aspx?src=${candidate?.user?.resume}`
          }
          width="100%"
          height="850"
        />
      </div>
    );
  };

  return (
    <div className={styles.pdfContainer}>
      <div style={{ margin: '30px auto 20px' }}>
        <p className={styles.titleCV}>Resume</p>
      </div>
      {isCandidatesEmpty
        ? isSelected === null && loading && <SkeletonLoader />
        : renderResume()}
    </div>
  );
};

export default CandidateResume;
