import React, { useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Close as CloseIcon } from '@mui/icons-material';
import { useSpring, animated } from 'react-spring';
import { CloseIconContainer } from './styles';
import SharedContent from '../SharedContent';
import { updateFirstScheduleInterviewModalStatus } from '../../../../../../src/redux/actions/application_action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    }
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '459px',
  backgroundColor: '#fff',
  boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.08)',
  borderRadius: '24px',
  padding: '20px 50px'
};

function ScheduleInterview(props) {
  const {
    open,
    candidateInfo,
    isAts,
    candidates,
    setCandidates,
    setCurrentCandidates,
    setJobData,
    jobData
  } = props;
  const handleModalClose = () => {
    props.updateFirstScheduleInterviewModalStatus(false, null);
  };

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={open}>
          <Box sx={style}>
            <CloseIconContainer>
              <CloseIcon
                onClick={handleModalClose}
                style={{ color: '#222', fontSize: '22px' }}
              />
            </CloseIconContainer>
            <SharedContent
              isFirstInterviewModalOpened={props.open}
              candidateInfo={candidateInfo}
              isAts={isAts}
              candidates={candidates}
              setCandidates={setCandidates}
              setCurrentCandidates={setCurrentCandidates}
              setJobData={setJobData}
              jobData={jobData}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    updateFirstScheduleInterviewModalStatus: bindActionCreators(
      updateFirstScheduleInterviewModalStatus,
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleInterview);
