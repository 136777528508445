import React, { useCallback, useEffect, useState } from 'react';
import ContentCard from '../components/ContentCard';
import EditVideoCard from './components/EditVideoCard';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import ThumbnailPopUp from './components/ThumbnailPopUp';
import UploadingPopUp from './components/UploadingPopUp';
import { PlusCircleIconStyled } from '../CompanyBenefits/styles';
import { CancelButton, EmptyVideoContainer, SequenceContainer } from './styles';
import { ErrorToast, SuccessToast } from '../../../utils/ToastUtils';
import { useUploadToVimeo } from './hooks/useUploadToVimeo';
import { useCheckReadyStatus } from './hooks/useCheckReadyStatus';
import RemovePopUp from './components/RemovePopup';
import { isEmpty, isEqual } from 'lodash';
import SubmitPopUp from './components/SubmitPopUp';
import {
  mutateCompanyProfile,
  updateCompanySidebar,
  updateUploadedVimeoVideo
} from '../../../redux/actions/company_actions';
import SaveButton from '../components/SaveButton';
import { ExclamationTriangleIconStyled } from '../SocialMediaHighlights/styles';
import { convertStatusText } from '../helper/convertStatusText';
import { useDeleteVimeoVideo } from './hooks/useDeleteVimeoVideo';
import { useCreateFolder } from './hooks/useCreateFolder';
import { useUploadToFolder } from './hooks/useUploadToFolder';

export default function EditState(props) {
  const { setView, setInfo } = props;
  const dispatch = useDispatch();
  const [pending, setPending] = useState([]);
  const [completeUpload, setCompleteUpload] = useState(false);
  const [thumbnailOpen, setThumbnailOpen] = useState(false);
  const [removeOpen, setRemoveOpen] = useState(false);
  const [submitOpen, setSubmitOpen] = useState(false);
  const [submitType, setSubmitType] = useState(false);
  const [submitContentIndex, setSubmitContentIndex] = useState(0);
  const [selected, setSelected] = useState();
  const [uploadOpen, setUploadOpen] = useState(false);
  const companyProfile = useSelector(state => state.companies.companyProfile);
  const accountInfo = useSelector(state => state.companies.accountInfo);
  const uploadedVimeo = useSelector(state => state.companies.uploadedVimeo);
  const companyCompletion = useSelector(
    state => state?.companies.companyCompletion
  );
  const [videos, setVideos] = useState();
  const [videosOri, setVideosOri] = useState([]);
  const [capturedThumbnail, setCapturedThumbnail] = useState(true);
  const [timing, setTiming] = useState(2000);
  const [loading, setLoading] = useState(false);

  async function processArrayWithCondition(arr, callback) {
    if (!Array.isArray(arr) || arr.length === 0) {
      return;
    }

    for (let i = 0; i < arr.length; i++) {
      const item = arr[i];
      await callback(item);
    }
  }

  async function deleteVideos({ list }) {
    await processArrayWithCondition(list, async item => {
      if (item) {
        await useDeleteVimeoVideo({ id: item });
      }
    });
  }

  useEffect(() => {
    // if (completed || isEmpty(videos)) {
    const companyVideos =
      companyProfile?.videos?.map((companyVideo, index) => {
        const videoContentWithVimeoIds =
          companyVideo.content?.flatMap(item => {
            if (item.vimeoId) {
              return {
                vimeoId: item.vimeoId,
                id: companyVideo?.id,
                contentId: item?.id
              };
            }
            return [];
          }) || [];

        return {
          ...companyVideo,
          id: companyVideo.id || `${index}${Date.now()}`,
          contentIndex: 0,
          sortOrder: index + 1,
          add: false,
          content:
            companyVideo.content?.map((item, index) => {
              return {
                ...item,
                oriId: item?.id,
                expand: false,
                editable: false,
                processing: true,
                status: convertStatusText(item.approvalStatus)
              };
            }) || [],
          videoContentWithVimeoIds
        };
      }) || [];

    const checkForPending = companyVideos?.map(video => {
      return { ...video?.videoContentWithVimeoIds };
    });
    const flattenedArray = checkForPending.flatMap(Object.values);

    setPending(flattenedArray);
    setVideos(companyVideos);
    setVideosOri(JSON.parse(JSON.stringify(companyVideos)));
    //   setTiming(2000);
    // }
  }, []);

  const saveToAPI = async (videos, updatedIndex, index) => {
    try {
      const res = await dispatch(
        mutateCompanyProfile({
          input: {
            videos: videos
          }
        })
      );

      if (res.type === 'FETCH_COMPANY_PROFILE_SUCCEED') {
        if (uploadedVimeo?.length > 1) {
          deleteVideos({ list: uploadedVimeo.slice(0, -1) });
        }

        dispatch(updateUploadedVimeoVideo([]));

        // Save data individually
        const updatedContent = res?.companyProfile?.videos?.[updatedIndex];
        const attributes = {
          contentIndex: 0,
          sortOrder: updatedIndex + 1,
          add: false,
          loading: false
        };

        const videoContentWithVimeoIds =
          updatedContent.content?.flatMap(item => {
            if (item.vimeoId) {
              return {
                vimeoId: item.vimeoId,
                id: updatedContent?.id,
                contentId: item?.id
              };
            }
            return [];
          }) || [];

        setTiming(2000);
        setPending([...videoContentWithVimeoIds, ...pending]);

        const oldVimeoVideo = videosOri[updatedIndex]?.content[index]?.vimeoId;
        if (oldVimeoVideo && uploadedVimeo?.length > 0) {
          useDeleteVimeoVideo({ id: oldVimeoVideo });
        }

        setVideos(prev => {
          const newArray = [...prev];
          newArray[updatedIndex] = {
            ...updatedContent,
            ...attributes,
            content:
              updatedContent?.content?.map((item, idx) => ({
                ...item,
                oriId: item?.id,
                editable: false,
                expand: updatedContent?.content?.length > 1 ? idx !== 0 : true,
                status: convertStatusText(item.approvalStatus),
                processing: true
              })) || []
          };

          return newArray;
        });

        setVideosOri(res?.companyProfile?.videos);
        dispatch(
          updateCompanySidebar({
            ...companyCompletion,
            companyVideos: true
          })
        );
        SuccessToast('Company videos updated Successfully!');
        return res;
      } else {
        ErrorToast(res.errorMessage);
        throw res;
      }
    } catch (err) {
      ErrorToast(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const removeFromList = async (id, videoTab) => {
    setVideos(prev => {
      const updatedVideos = prev.map(video => {
        if (video.id === id) {
          const updatedContent = video.content.filter(
            item => item.id !== videoTab
          );
          return { ...video, content: updatedContent };
        }
        return video;
      });

      const removeTab = updatedVideos?.filter(video => {
        return !isEmpty(video?.content);
      });

      const resetOrder = removeTab?.map((video, index) => {
        return { ...video, sortOrder: index + 1 };
      });

      return resetOrder;
    });
  };

  const addItem = () => {
    setVideos(prev => {
      const newOrder = prev.length + 1;
      const newItem = {
        id: `${Date.now()}`,
        sortOrder: newOrder,
        content: [
          {
            id: `${Date.now()}-new`,
            status: 'Draft',
            expand: true,
            editable: true
          }
        ]
      };

      return [...prev, newItem];
    });
  };

  const handleChange = (videoId, contentId, field, value) => {
    // if (field === 'quote') {
    //   if (value.length >= 252) {
    //     return;
    //   }
    // }
    // if (field === 'personName') {
    //   if (value.length >= 52) {
    //     return;
    //   }
    // }
    // if (field === 'personPosition') {
    //   if (value.length >= 52) {
    //     return;
    //   }
    // }

    setVideos(prev => {
      const videoIndex = prev.findIndex(item => item.id === videoId);
      if (videoIndex !== -1) {
        const updatedList = [...prev];
        const contentIndex = updatedList[videoIndex].content.findIndex(
          item => item.id === contentId
        );
        if (contentIndex !== -1) {
          updatedList[videoIndex].content[contentIndex] = {
            ...updatedList[videoIndex].content[contentIndex],
            // id: 'testng',
            [field]: value
          };
        }
        return updatedList;
      }
      return prev;
    });
  };

  const handleChangeOrder = (sortOrder, action) => {
    setVideos(prev => {
      const updatedList = [...prev];
      const itemIndex = updatedList.findIndex(
        item => item.sortOrder === sortOrder
      );

      if (itemIndex !== -1) {
        const swapIndex = action === 'up' ? itemIndex - 1 : itemIndex + 1;

        if (
          (action === 'up' && swapIndex >= 0) ||
          (action === 'down' && swapIndex < updatedList.length)
        ) {
          const temp = updatedList[itemIndex];
          updatedList[itemIndex] = updatedList[swapIndex];
          updatedList[swapIndex] = temp;

          return updatedList.map((item, index) => ({
            ...item,
            sortOrder: index + 1
          }));
        }
      }

      return updatedList;
    });
  };

  const handleUploadVideo = ({
    id,
    tabId,
    vimeoId,
    processing,
    disableDelete
  }) => {
    if (!disableDelete) {
      dispatch(updateUploadedVimeoVideo([...uploadedVimeo, vimeoId]));
    }

    setVideos(prev => {
      return prev.map(video => {
        if (video.id === id) {
          let contentUpdated = false;

          const updatedContent = video.content.map((content, index) => {
            if (tabId === false && index === 0) {
              contentUpdated = true;
              return {
                ...content,
                vimeoId: vimeoId,
                processing: true
              };
            }

            if (content.id === tabId) {
              contentUpdated = true;
              return {
                ...content,
                vimeoId: vimeoId,
                processing: true
              };
            }
            return content;
          });

          if (tabId === false && !contentUpdated && video.content.length > 0) {
            updatedContent[0] = {
              ...updatedContent[0],
              vimeoId: vimeoId,
              processing: true
            };
          }

          const updatedVideo = {
            ...video,
            content: updatedContent
          };

          return updatedVideo;
        }
        return video;
      });
    });

    setVideosOri(prev => {
      return prev.map(video => {
        if (video.id === id) {
          const updatedVideo = {
            ...video,
            content: video.content.map(content => {
              if (content.id === tabId) {
                return {
                  ...content,
                  processing: processing
                };
              }
              return content;
            })
          };
          return updatedVideo;
        }

        return video;
      });
    });
  };

  const handleProcessing = ({ id, tabId }) => {
    setVideos(prev => {
      const videoIndex = prev.findIndex(video => video.id === id);

      if (videoIndex === -1) return prev;

      const contentIndex = prev[videoIndex].content.findIndex(
        content => content.id === tabId
      );

      if (contentIndex === -1) return prev;

      const newVideos = [...prev];
      const newContent = [...newVideos[videoIndex].content];

      newContent[contentIndex] = {
        ...newContent[contentIndex],
        processing: false
      };

      newVideos[videoIndex] = {
        ...newVideos[videoIndex],
        content: newContent
      };

      return newVideos;
    });
  };

  const handleRemoveVideo = async ({ id, videoTab }) => {
    const deleteItem = videos?.find(video => {
      return video?.id === id;
    }).content;

    const deleteContent = deleteItem?.find(video => {
      return video?.id === videoTab;
    });

    if (deleteContent?.status === 'Draft') {
      // Delete if multiple uploads
      deleteVideos({ list: uploadedVimeo });
      removeFromList(id, videoTab);

      return;
    }

    setPending(prev => {
      const updatedPending = prev.filter(
        item => item.vimeoId !== deleteContent.vimeoId
      );
      return updatedPending;
    });

    const removedContent = [
      { id: videoTab, _destroy: true, activeBanner: false }
    ];
    dispatch(
      mutateCompanyProfile({
        input: {
          videos: removedContent
        }
      })
    ).then(res => {
      if (res.type === 'FETCH_COMPANY_PROFILE_SUCCEED') {
        // Delete if already submitted
        deleteVideos({ list: uploadedVimeo });
        useDeleteVimeoVideo({ id: deleteContent?.vimeoId });

        const updatedIndex = videos?.findIndex(video => video?.id === id);
        const updatedTabIndex = videos[updatedIndex]?.content.findIndex(
          video => video?.id === videoTab
        );
        const updatedRes = res?.companyProfile?.videos[updatedIndex];
        const updatedContent = updatedRes?.content;

        useDeleteVimeoVideo({
          id: videosOri[updatedIndex]?.content[updatedTabIndex]?.vimeoId
        });

        const originalContent = videosOri[updatedIndex]?.content;

        const attributes = {
          contentIndex: 0,
          sortOrder: updatedIndex + 1,
          add: false,
          loading: false
        };

        if (originalContent?.length > 1) {
          setVideos((prev, count) => {
            const newArray = [...prev];
            newArray[updatedIndex] = {
              ...updatedContent,
              ...attributes,
              content:
                updatedContent?.map(item => ({
                  ...item,
                  oriId: item?.id,
                  status: convertStatusText(item.approvalStatus),
                  processing: false
                })) || []
            };

            return newArray;
          });
        } else {
          setVideos(prevVideos =>
            prevVideos
              .filter((_, index) => index !== updatedIndex)
              .map((video, index) => ({ ...video, sortOrder: index + 1 }))
          );
        }

        setVideosOri(res?.companyProfile?.videos);
        SuccessToast('Video Deleted Successfully!');
      } else {
        ErrorToast('Video Deleted Failed!');
      }
    });
  };

  const handleSubmitVideo = async ({ type, id, index }) => {
    const updatedIndex = videos.findIndex(
      video => parseInt(video?.id) === parseInt(id)
    );

    if (updatedIndex !== -1) {
      const contentArray = videos[updatedIndex]?.content?.map(
        (content, count) => {
          let id;
          if (content.id.includes('new')) {
            id = false;
          } else if (content.id.includes('edit')) {
            id = content.id;
          } else if (content.id.includes('live')) {
            id = content.oriId;
          } else {
            id = content.id;
          }

          return {
            ...content,
            sortOrder: updatedIndex + 1,
            activeBanner: true,
            id
          };
        }
      );

      setLoading(true);
      await saveToAPI(contentArray, updatedIndex, index);
    }
  };

  const deleteConfirmation = ({ id, videoTab, status }) => {
    if (status !== 'Draft') {
      setRemoveOpen(true);
    } else {
      handleRemoveVideo({ id, videoTab, status });
    }
  };

  const submitCofirmation = ({ type, id, index }) => {
    if (type !== '') {
      setSubmitContentIndex(index);
      setSubmitType(type);
      setSubmitOpen(true);
    } else {
      handleSubmitVideo({ type, id, index });
    }
  };

  const onDrop = useCallback(
    async (files, id, tabId) => {
      try {
        setCompleteUpload(false);
        const [file] = files;
        if (!file) {
          // ErrorToast('No file selected');
          return;
        }

        setUploadOpen(true);

        const vimeoId = await useUploadToVimeo({ file, id: accountInfo.id });

        if (vimeoId) {
          await useUploadToFolder({ vimeoId });
          setCompleteUpload(true);
          handleUploadVideo({
            id: id,
            tabId: tabId,
            vimeoId: vimeoId,
            processing: true,
            disableDelete: false
          });
        }

        setPending(prev => {
          return [...prev, { id, contentId: tabId, vimeoId }];
        });
      } catch (err) {
        ErrorToast(err.message);
        setUploadOpen(false);
      }
    },
    [accountInfo, handleUploadVideo, useUploadToVimeo]
  );

  const onDropRejected = error => {
    ErrorToast('File size exceeds 200MB limit');
  };

  const expandTab = ({ index, tab, expand }) => {
    setVideos(prev => {
      const newArray = [...prev];
      const videoIndex = newArray.findIndex(
        video => video.sortOrder === index + 1
      );

      if (videoIndex !== -1) {
        const contentIndex = newArray[videoIndex].content.findIndex(
          (content, contentIdx) => contentIdx === tab
        );

        if (contentIndex !== -1) {
          newArray[videoIndex].content[contentIndex].expand = expand;
        }
      }

      return newArray;
    });
  };

  const changeEditable = ({ index, tab, edit }) => {
    setVideos(prev => {
      const newArray = [...prev];
      const videoIndex = newArray.findIndex(
        video => video.sortOrder === index + 1
      );

      if (videoIndex !== -1) {
        const contentIndex = newArray[videoIndex].content.findIndex(
          (content, contentIdx) => contentIdx === tab
        );

        if (contentIndex !== -1) {
          newArray[videoIndex].content[contentIndex].editable = edit;
        }
      }

      return newArray;
    });
  };

  const hasUrlChanges = ({ index, tab }) => {
    const selectedVideo = videos[index]?.content[tab];
    const selectedVideoOri = videosOri[index]?.content[tab];

    if (
      selectedVideo?.personPosition !== selectedVideoOri?.personPosition ||
      selectedVideo?.quote !== selectedVideoOri?.quote ||
      selectedVideo?.personName !== selectedVideoOri?.personName ||
      selectedVideo?.vimeoId !== selectedVideoOri?.vimeoId
    ) {
      return true;
    }

    return false;
  };

  const hasChangesInVideos = () => {
    const changes = videos?.some((video, index) => {
      const videoOri = videosOri[index];
      if (!videoOri) return true;

      return video.content.some((content, tab) => {
        const selectedVideo = videos[index]?.content[tab];
        const selectedVideoOri = videoOri?.content[tab];

        return (
          selectedVideo?.personPosition !== selectedVideoOri?.personPosition ||
          selectedVideo?.quote !== selectedVideoOri?.quote ||
          selectedVideo?.personName !== selectedVideoOri?.personName ||
          selectedVideo?.vimeoId !== selectedVideoOri?.vimeoId
        );
      });
    });

    return changes;
  };

  useEffect(() => {
    hasChangesInVideos();
  }, [videos]);

  const revertInputChanges = ({ index, tab }) => {
    const selectedVideo = videos[index]?.content[tab];
    const selectedVideoOri = videosOri[index]?.content[tab];

    if (selectedVideo?.status === 'Draft') {
      setVideos(prev => {
        const newArray = [...prev];
        const updatedVideo = { ...newArray[index] };
        updatedVideo.content[tab] = {
          ...selectedVideo,
          personName: '',
          personPosition: '',
          quote: '',
          vimeoId: '',
          expand: true
        };
        newArray[index] = updatedVideo;
        return newArray;
      });
    } else {
      setVideos(prev => {
        const newArray = [...prev];
        const updatedVideo = { ...newArray[index] };
        updatedVideo.content[tab] = {
          ...selectedVideoOri,
          expand: true,
          editable: true,
          processing: false,
          status: convertStatusText(selectedVideoOri.approvalStatus)
        };
        newArray[index] = updatedVideo;
        return newArray;
      });
      SuccessToast('Your changes have been reverted');
    }
  };

  useEffect(() => {
    if (pending?.length === 0) {
      return;
    }

    const intervalId = setInterval(async () => {
      const updatedPending = await Promise.all(
        pending.map(async item => {
          const ready = await useCheckReadyStatus(item.vimeoId);
          if (ready || ready === null) {
            handleProcessing({
              id: item?.id,
              tabId: item?.contentId
            });
            return null;
          }

          return item;
        })
      );

      const filteredPending = updatedPending.filter(item => item !== null);

      // Update pending array
      setPending(filteredPending);

      if (timing !== 60000) {
        setTiming(60000);
      }

      if (filteredPending.length === 0) {
        clearInterval(intervalId);
      }
    }, timing);

    return () => clearInterval(intervalId);
  }, [pending, timing, handleProcessing, setPending]);

  const selectedItem = videos?.find(video => video?.id === selected?.id);
  const selectedTab = selectedItem?.content?.find(
    content => content?.id === selected?.videoTab
  );

  const onResetSortOrder = () => {
    setVideos(prev => {
      const sorted = prev?.map((video, index) => {
        return { ...video, sortOrder: index + 1 };
      });

      return sorted;
    });
  };

  const hasSequenceChanges = () => {
    const editChanges = videos
      ?.filter(item => {
        return item?.id?.length < 5;
      })
      ?.map((video, index) => {
        const original = videosOri[index];
        return isEqual(video?.id, original?.id);
      });

    return editChanges?.includes(false);
  };

  const onRevertSequence = () => {
    const sortVideosOri = (videosOri, videos) => {
      const sortedVideosOri = [...videosOri];
      const idOrder = videos.map(video => video.id);

      sortedVideosOri.sort((a, b) => {
        const aIndex = idOrder.indexOf(a.id);
        const bIndex = idOrder.indexOf(b.id);

        const bothLongIds = a.id.length > 6 && b.id.length > 6;

        if (bothLongIds) {
          return a.id.localeCompare(b.id);
        }

        if (a.id.length > 6) {
          return 1;
        }
        if (b.id.length > 6) {
          return -1;
        }

        return aIndex - bIndex;
      });

      return sortedVideosOri;
    };

    const sortedVideosOri = sortVideosOri(videos, videosOri);

    setVideos(sortedVideosOri);
    onResetSortOrder();
  };

  const onSaveSequence = async () => {
    try {
      const contentArray = videos
        .flatMap(item => item.content)
        .map((content, index) => ({ ...content, sortOrder: index + 1 }));

      const res = await dispatch(
        mutateCompanyProfile({ input: { videos: contentArray } })
      );

      if (res.type === 'FETCH_COMPANY_PROFILE_SUCCEED') {
        setVideosOri(res?.companyProfile?.videos);
        SuccessToast('Company videos sequence update successfully');
      } else {
        ErrorToast(res.errorMessage);
      }
    } catch (error) {
      ErrorToast(error.message);
    }
  };

  const deleteVideoOnDiscard = () => {
    deleteVideos({ list: uploadedVimeo });
  };

  return (
    <ContentCard
      id="Company Videos"
      title="Company Video"
      subTitle="Upload up to 3 videos to give jobseekers more insights to your company culture."
      infoIcon
      cancelIcon={!isEmpty(companyProfile?.videos)}
      discardButton
      discardAction={() => deleteVideoOnDiscard()}
      noChanges={!hasSequenceChanges() && !hasChangesInVideos()}
      infoOnClick={() => {
        setInfo(true);
      }}
      cancelOnClick={() => setView(true)}
      padding="0px !important">
      <Grid
        sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
            gap: '24px'
          }}>
          {videos?.map((video, index) => {
            return (
              <EditVideoCard
                key={video?.id}
                videoLength={videos?.length}
                videoIndex={index}
                video={video}
                handleChange={handleChange}
                handleChangeOrder={handleChangeOrder}
                onDrop={onDrop}
                onDropRejected={onDropRejected}
                setThumbnailOpen={setThumbnailOpen}
                setRemoveOpen={setRemoveOpen}
                setSelected={setSelected}
                deleteConfirmation={deleteConfirmation}
                submitCofirmation={submitCofirmation}
                setSubmitType={setSubmitType}
                setInfo={setInfo}
                capturedThumbnail={capturedThumbnail}
                revertInputChanges={revertInputChanges}
                hasUrlChanges={hasUrlChanges}
                expandTab={expandTab}
                changeEditable={changeEditable}
                loading={loading}
                disabled={
                  video?.content?.length === 2 &&
                  video?.content[0]?.status === 'Live on Hiredly'
                }
              />
            );
          })}
          {videos?.length !== 3 && (
            <EmptyVideoContainer onClick={addItem}>
              <PlusCircleIconStyled /> Add a company video
            </EmptyVideoContainer>
          )}
        </Grid>
        {hasSequenceChanges() && (
          <SequenceContainer>
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                alignItems: 'center'
              }}>
              <ExclamationTriangleIconStyled />{' '}
              <Typography>
                You’ve made changes to the video sequence, would you like to
                save them?
              </Typography>
            </Grid>
            <Grid sx={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
              <CancelButton onClick={() => onRevertSequence()}>
                Revert
              </CancelButton>
              <SaveButton height={'40px'} onClick={() => onSaveSequence()}>
                Save Sequence
              </SaveButton>
            </Grid>
          </SequenceContainer>
        )}
      </Grid>
      <ThumbnailPopUp
        open={thumbnailOpen}
        handleClose={() => setThumbnailOpen(false)}
        setCapturedThumbnail={setCapturedThumbnail}
        vimeoId={selectedTab?.vimeoId}
      />
      <UploadingPopUp
        open={uploadOpen}
        completed={completeUpload}
        setUploadOpen={() => setUploadOpen(false)}
        handleClose={() => setUploadOpen(false)}
      />
      <SubmitPopUp
        open={submitOpen}
        handleClose={() => setSubmitOpen(false)}
        selected={selected}
        submitType={submitType}
        submitContentIndex={submitContentIndex}
        handleSubmitVideo={handleSubmitVideo}
      />
      <RemovePopUp
        open={removeOpen}
        handleClose={() => {
          setRemoveOpen(false);
        }}
        selected={selected}
        handleRemoveVideo={handleRemoveVideo}
      />
    </ContentCard>
  );
}

// Draft items does not trigger sequence saving
// When submiting, if vimeo id never change, dont delete vimeo, use videosOri to cross check

// Delete video when
// 1. delete video tab (Unreviwed)
// 2. delete video tab (live/review/reject)
// 3. delete video if user replace in review
