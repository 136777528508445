import { styled as styling } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

export const Wrapper = styling(Grid)(props => ({
  width: props.content ? 'auto' : '100%',
  height: props.content ? 'auto' : 'calc(100% - 24px)'
}));

export const TitleStyled = styling(Typography)({
  fontSize: '25px',
  fontWeight: 'bold'
});

export const TextStyled = styling(Typography)({
  fontSize: '14px'
});
