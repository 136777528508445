import { styled as styling } from '@mui/material/styles';
import { Grid, Skeleton } from '@mui/material';

export const MainWrapper = styling(Grid)({
  marginBottom: '20px',
  width: '100%'
});

export const LeftWrapper = styling(Grid)({
  backgroundColor: 'white',
  width: '100%',
  height: '240px',
  borderTopLeftRadius: '8px',
  borderBottomLeftRadius: '8px',
  padding: '22px 24px 18px 24px'
});

export const RightWrapper = styling(Grid)({
  backgroundColor: 'white',
  width: '30%',
  height: '312px',
  padding: '22px 24px 18px 24px',
  borderTopRightRadius: '8px',
  borderBottomRightRadius: '8px'
});

export const SkeletonTextStyled = styling(Skeleton)({
  borderRadius: '8px'
});

export const SkeletonChipStyled = styling(Skeleton)({
  borderRadius: '100px',
  height: '28px'
});

export const SkeletonAvatarStyled = styling(Skeleton)({});

export const EmptyContainer = styling(Grid)({
  width: '100%'
});
