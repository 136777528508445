import { styled as styling } from '@mui/material/styles';
import { Button } from '@mui/material';
import Colors from '../../../../../styles/colors.module.scss';

export const ButtonStyled = styling(Button)((props) => ({
  ...(props.basic == 'true' && {
    borderRadius: '30px',
    background: Colors.priPurple,
    color: Colors.priWhite,
    padding: '9px 50px 9px 30px',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    letterSpacing: '1px',
    textTransform: 'capitalize',

    '&:hover': {
      background: Colors.priPurple,
    },
  }),

  ...(props.disable_button == 'true' && {
    borderRadius: '30px',
    background: Colors.terMediumGrey,
    color: Colors.priWhite,
    padding: '9px 50px 9px 30px',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    letterSpacing: '1px',
    textTransform: 'capitalize',
  }),
}));

export const Text = styling('p')((props) => ({
  ...(props.basic == 'true' && {
    margin: '0 0 0 15px',
    fontSize: '14px',
    fontFamily: 'Inter',
    letterSpacing: '0.03rem',
  }),

  ...(props.tooltip == 'true' && {
    fontSize: '14px',
    fontFamily: 'Inter',
    padding: '4px 0',
    margin: '2px 5px',
  }),
}));
