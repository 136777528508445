import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCurrentTalent,
  updateSearchResumeCurrentPage
} from '../../../redux/actions/talent_action';
import { SharedPagination } from '../SharedPagination';

const ResumeSearchPagination = ({ type, triggerLoadTalents }) => {
  const dispatch = useDispatch();
  const index = useSelector(
    state => state.talents.searchResumeTalentsCurrentPage
  );
  const loading = useSelector(state => state.talents.fetchingTalents);
  const hasRecord = useSelector(state => state.talents.talents?.length > 0);

  const onClickLeftArrow = () => {
    if (triggerLoadTalents) {
      triggerLoadTalents(type, 'prev', index - 1);
      dispatch(updateSearchResumeCurrentPage(index - 1));
      dispatch(updateCurrentTalent({}));
    }
  };

  const onClickRightArrow = () => {
    if (triggerLoadTalents) {
      triggerLoadTalents(type, 'next', index + 1);
      dispatch(updateSearchResumeCurrentPage(index + 1));
      dispatch(updateCurrentTalent({}));
    }
  };

  return (
    hasRecord && (
      <SharedPagination
        onClickLeftArrow={onClickLeftArrow}
        onClickRightArrow={onClickRightArrow}
        type={type}
        index={index}
        loading={loading}
      />
    )
  );
};

export default ResumeSearchPagination;
