import React, { useState } from 'react';
import * as yup from 'yup';
import { Button, PopUpDialog, Spinner } from '../../../../components';
import styles from './index.module.scss';
import SharedTextField from '../../../../components/SharedTextField';

const BlackListPopUp = ({
  open,
  handleClose,
  updateJobApplicationStatus,
  loading
}) => {
  const [formState, setFormState] = useState({ reason: '' });
  const [errors, setErrors] = useState({});
  const [sending, setSending] = useState(false);

  const validationSchema = yup.object().shape({
    reason: yup.string().required('Blacklist Reason is required')
  });

  const validate = async () => {
    try {
      await validationSchema.validate(formState, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      const newErrors = {};
      err.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const onBlacklist = async () => {
    const isValid = await validate();
    if (!isValid) return;

    setSending(true);
    await updateJobApplicationStatus('blacklisted', formState.reason);
    handleClose();
    InfoToast(singleStatusMessage());
    setSending(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const singleStatusMessage = () => {
    return (
      <div>
        <p
          style={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 'bold',
            margin: '4px 0'
          }}>
          User Blacklisted
        </p>
        <p style={{ margin: '0', fontFamily: 'Inter', fontSize: '12px' }}>
          You have successfully blacklisted this user.
        </p>
      </div>
    );
  };

  return (
    <PopUpDialog
      open={open}
      handleClose={handleClose}
      title="Blacklist This User?"
      paperStyle={{ minWidth: 700 }}>
      <div style={{ padding: '0px 20px 20px' }}>
        <div className={styles.contentContainer}>
          <p className={styles.blacklistDescription}>
            Please state a reason to blacklist this user:
          </p>
          <p className={styles.warningText}>
            This action cannot be undone, and this user will not be shown to you
            anymore.
          </p>
          <p className={styles.warningText}>
            This user will not be notified that they have been blacklisted.
          </p>
          <div className={styles.reasonContainer}>
            <SharedTextField
              name="reason"
              rows={6}
              placeholder="e.g. The candidate failed to respond to any form of communication and did not turn up for an interview as invited."
              value={formState.reason}
              onChange={handleChange}
              error={!!errors.reason}
              helperText={errors.reason}
            />
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button color="TRANSPARENT" size="BIG" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="PURPLE"
            size="BIG"
            onClick={onBlacklist}
            disabled={loading || sending}
          >
            {sending ? (
              <Spinner size="XS" color="white" noPadding={true} />
            ) : (
              'Blacklist'
            )}
          </Button>
        </div>
      </div>
    </PopUpDialog>
  );
};

export default BlackListPopUp;
