import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Grid } from '@mui/material';
import { styled as styling } from '@mui/material/styles';

export const FontAwesomeIconStart = styling(FontAwesomeIcon)({
  fontSize: '20px',
  alignSelf: 'flex-start',
  marginBottom: '-1.125rem',
  fontSize: '20px',
  maxWidth: '20px',
  minWidth: '20px',
  width: '20px'
});

export const FontAwesomeIconEnd = styling(FontAwesomeIcon)({
  fontSize: '20px',
  alignSelf: 'flex-end',
  marginBottom: '-1.125rem',
  fontSize: '20px',
  maxWidth: '20px',
  minWidth: '20px',
  width: '20px'
});

export const StackContainer = styling(Stack)({
  transition: 'all 0.15s ease-in-out'
});

export const VideoCardContainer = styling(Grid)({
  minWidth: '560px',
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '8px',
  backgroundColor: '#353535'
});

export const VideoNumberTag = styling(Grid)({
  zIndex: 100,
  position: 'absolute',
  left: '0px',
  top: '0px',
  backgroundColor: 'black !important',
  display: 'flex',
  color: 'white !important',
  fontWeight: 700,
  fontSize: '16px',
  padding: '8px',
  height: '40px',
  width: '40px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '0px 0px 8px 0px'
});

export const VideoContentContainer = styling(Grid)({
  width: '100%',
  border: '1px solid #AEAEAE',
  borderRadius: '16px',
  padding: '16px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  color: 'rgba(0, 0, 0, 0.60)',
});

export const VideoProcessingContainer = styling(Grid)({
  backgroundColor: '#EFEFEF',
  height: '100%',
  width: '100%',
  borderRadius: '8px',
  border: '1px solid #AEAEAE',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
});
