import { styled as styling } from '@mui/material/styles';
import {
  Grid,
  Tooltip,
  Typography,
  tooltipClasses,
  Button
} from '@mui/material';
import { ClockIcon } from '@heroicons/react/24/solid';

export const ProductCardContainer = styling(Grid)(props => ({
  backgroundColor: "#F7F7F7",
  display: "flex",
  flexDirection: "column",
  borderRadius: '8px',
  width: props?.singleCard ? '300px' : '100%',
  position: 'relative',
  border:
    !props?.confirm &&
    `0.5px solid ${
      props?.zeroCount ? '#AEAEAE' : props?.purpleBorder ? '#512ACC' : '#D8D8D8'
    }`,
  height: '100%'
  // overflow: 'hidden'
}));

export const ProductInnerContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: "space-between",
  height: "100%"
}));

export const DefaultContainer = styling(Grid)(props => ({}));

export const TypographyStyled = styling(Typography)(props => ({}));

export const YearTypography = styling('span')(props => ({
  fontSize: '10px',
  color: `rgba(0, 0, 0, 0.60)`,
  fontWeight: '400'
}));

export const QuantityContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
  alignItems: 'center',
  // marginBottom: '8px',
  width: props?.singleCard ? '50%' : '90px',
  justifyContent: 'space-between'
}));

export const PurpleChip = styling(Grid)(props => ({
  backgroundColor: props?.zeroCount ? '#D8D8D8' : '#512acc1a',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '10px',
  padding: '2px 8px',
  lineHeight: '20px',
  color: `rgba(0, 0, 0, 0.87)`,
  opacity: props?.zeroCount ? 0.4 : 1
}));

export const LightPurpleTag = styling(Grid)(props => ({
  backgroundColor: props?.zeroCount ? '#D8D8D8' : `rgba(81, 42, 204, 0.12)`,
  height: '24px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '7px 7px 0px 0px'
}));

export const LightPurpleTagText = styling(Typography)(props => ({
  color: props?.zeroCount ? `rgba(0, 0, 0, 0.38)` : '#40249B',
  fontWeight: '500',
  letterSpacing: '1.5px',
  textTransform: 'uppercase',
  textAlign: 'center',
  fontSize: '10px',
  lineHeight: '0px'
}));

export const DarkPurpleTag = styling(Grid)(props => ({
  backgroundColor: props?.zeroCount ? '#D8D8D8' : `#512ACC`,
  height: '24px',
  // padding: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  gap: '8px',
  width: '100%',
  borderRadius: '7px 7px 0px 0px'
}));

export const DarkPurpleTagText = styling(Typography)(props => ({
  color: 'white',
  fontWeight: '600',
  letterSpacing: '1.5px',
  textTransform: 'uppercase',
  textAlign: 'center',
  fontSize: '10px',
  lineHeight: '0px'
}));

export const AmountTag = styling(Typography)(props => ({
  color: 'rgba(0, 0, 0, 0.60)',
  fontSize: '10px',
  letterSpacing: '1.5px',
  textTransform: 'uppercase',
  backgroundColor: props?.soldOut ? '#D8D8D8' : 'rgba(241, 201, 60, 0.16)',
  position: 'absolute',
  border: `1px solid ${props?.soldOut ? '#AEAEAE' : '#F1C93C'}`,
  display: 'flex',
  padding: '2px 8px 2px 4px',
  alignItems: 'center',
  gap: '4px',
  borderRadius: '4px',
  top: props?.confirm ? '8px' : '32px',
  left: '8px'
}));

export const ClockIconStyled = styling(ClockIcon)(props => ({
  color: props?.soldOut ? '#AEAEAE' : '#E4BD32',
  fontSize: '16px',
  width: '16px',
  height: '16px'
}));

export const CustomTooltip = styling(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: '8px',
    padding: '4px, 8px, 4px, 8px',
    border: '2px solid black',
    backgroundColor: 'white',
    color: 'black',
    zIndex: 5,
    cursor: 'none'
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
    {
      marginBottom: '4px'
    }
});

export const ContactButton = styling(Button)(props => ({
  color: `rgba(0, 0, 0, 0.87)`,
  fontSize: '10px',
  lineHeight: '20px',
  height: '24px',
  padding: '0x 16px',
  backgroundColor: 'white',
  textTransform: 'none',
  border: '1px solid #222',
  borderRadius: '6px',
  width: '84px',

  ':hover': {
    backgroundColor: 'white'
  }
}));
