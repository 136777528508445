import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Spinner } from '../../../../../components';
import {
  faCamera,
  faCheckCircle,
  faLightbulb,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
// import { ButtonStyled } from '../../../PopUps/EditBannerPopUp/styles';
import CustomTooltip from '../../../../../components/CustomTooltip';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchJobTitleSuggestions,
  updateJobTitleSuggestionsDropdownStatus,
  updatePostJobPrevTooltip,
  updatePostJobTooltipStatus
} from '../../../../../redux/actions/job_action';
import { Spacer } from '../../../JobList/JobListTable/styles';
import { TipsTooltip } from '../../../../../components/Tooltips';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HeightSpacer } from '../../../JobList/styles';
import { MenuItem, Skeleton, Typography } from '@mui/material';
import {
  BannerContentContainer,
  BannerContentWrapper,
  BannerWrapper,
  CompanyNameContainer,
  DropdownItem,
  DropdownListContainer,
  EditLocationContainer,
  EditOptionContainer,
  FontAwesomeIconStyled,
  FormSelectFieldContainer,
  FreeJobMessageBoxContainer,
  FreeJobMessageDesc,
  GuideText,
  IconContainer,
  ImageContainer,
  ImageStyled,
  JobTitleContainer,
  LocationContainer,
  LocationWrapper,
  OfficeStyled,
  SpinnerContainer,
  TipsTooltipContainer,
  Wrapper,
  UploadContainer,
  UploadBannerImg,
  ButtonStyled,
  TextFieldStyled,
  ErrorStateText,
  Description,
  Text
} from './styles';
import { debounce, isEmpty } from 'lodash';
import usePathKey from '../../helper/usePathKey';
import { useFormContext } from '../../context';
import { ErrorToast } from '../../../../../utils/ToastUtils';
import EditBannerPopUp from '../../../PopUps/EditBannerPopUp';
import { readyFileUpload } from '../../../../../redux/actions/company_actions';
import moment from 'moment';
import { RECORD_TYPE } from '../../../../../utils/Constants';
import { getCountryStates } from '../../../../../hooks/useRegionalisation';

export default function Banner(props) {
  const { formState, handleSingleFieldChange } = useFormContext();
  const pathKey = usePathKey();
  const dispatch = useDispatch();

  const myCompanySettings = useSelector(
    state => state?.companies?.myCompanySettings
  );
  const fetchingCompanySettings = useSelector(
    state => state?.companies?.fetchingCompanySettings
  );

  // For image positioning editor
  const [isEditBannerPopUpOpen, setIsEditBannerPopUpOpen] = useState(false);
  const [showEditOption, setShowEditOption] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  // Temp variable
  const defaultImage =
    'https://s3.ap-southeast-1.amazonaws.com/assets.wobbjobs.com/images/backgrounds/hiredly-jobs-malaysia-wobb-background-v1.png';
  const defaultSquareImage =
    'https://assets.wobbjobs.com/images/backgrounds/hiredly-jobs-malaysia-wobb-background-v1-show.png';
  const type = 'job';
  const title = 'Attract candidates to your job posting with a cover image.';
  const subTitle = 'This will be shown on our website and mobile app.';

  // Identify from which url
  const intern = pathKey === 'intern';

  const onUploadCoverImageClick = () => {
    // setIsEditBannerPopUpOpen(true);
    fileInputRef.current.click();
  };

  const onRepositionClick = () => {
    setIsEditBannerPopUpOpen(true);
  };

  const [selectedImage, setSelectedImage] = useState(defaultImage);
  const [selectedDefaultImage, setSelectedDefaultImage] =
    useState(defaultImage);

  useEffect(() => {
    if (formState?.jobBannerUrl) {
      setSelectedImage(formState?.jobBannerUrl);
      setSelectedDefaultImage(formState?.jobBannerUrl);
    } else {
      setSelectedImage(defaultImage);
      setSelectedImage(defaultImage);
    }
  }, [formState?.jobBannerUrl]);

  const [selectedSquareImage, setSelectedSquareImage] = useState(
    isEmpty(formState?.bannerImageUrlSquare)
      ? defaultSquareImage
      : formState?.bannerImageUrlSquare
  );
  const fileInputRef = useRef(null);

  const handleImageChange = event => {
    const file = event?.target?.files?.[0];

    // Check if a file was selected
    if (file) {
      const fileSize = file.size;
      const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
      const maxWidth = 2500;
      const maxHeight = 2500;

      if (fileSize > maxSizeInBytes) {
        ErrorToast(
          'File size too large. Please select a file smaller than 2MB.'
        );
        event.target.value = null;
        return; // Reject the image upload
      }

      // Create an image element to check dimensions
      const img = new Image();
      img.onload = function () {
        const width = this.width;
        const height = this.height;

        if (width > maxWidth || height > maxHeight) {
          ErrorToast(
            'Image dimensions are too large. Please select an image with dimensions less than 2500x2500 pixels.'
          );
          event.target.value = null;
          return; // Reject the image upload
        }

        // If the file size and dimensions are within the limits, proceed with reading the file
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setSelectedImage(reader.result);
        };
        setIsEditBannerPopUpOpen(true);
      };

      // Set the source of the image element to the selected file
      img.src = URL.createObjectURL(file);
    }
  };

  const handleButtonClick = () => {
    if (showEditOption) return;

    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const constructFileName = (file, type) => {
    const cTimeStamp = moment.utc().valueOf().toString();

    return `jobs-malaysia-${formState ? formState.id + '-' : ''}${
      myCompanySettings ? myCompanySettings.slug.substring(0, 30) + '-' : ''
    }${cTimeStamp}${type == 'square' ? '-square' : ''}.${
      file?.name.toLowerCase().includes('png') ? 'png' : 'jpeg'
    }`;
  };

  const uploadBanner = async (file, type) => {
    return new Promise(async resolve => {
      try {
        if (file.name) {
          const constructedFileName = constructFileName(file, type);

          const res = await dispatch(
            readyFileUpload({
              input: {
                filename: constructedFileName,
                recordType: RECORD_TYPE.JOB_IMAGE
              }
            })
          );

          const data = res?.prepareFileUpload;

          if (data) {
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', data?.presignedUrl, true);
            xhr.setRequestHeader('Content-Type', file.type);
            xhr.onload = () => {
              if (xhr.status === 200) {
                if (type == 'square') {
                  setSelectedSquareImage(data?.publicUrl);
                  handleSingleFieldChange(
                    'jobBannerUrlSquare',
                    data?.publicUrl
                  );
                } else {
                  setSelectedDefaultImage(data?.publicUrl);
                  handleSingleFieldChange('jobBannerUrl', data?.publicUrl);
                }

                setShowEditOption(true);

                return resolve(true);
              }
            };
            xhr.send(file);
          }
        } else {
          return resolve(false);
        }
      } catch (err) {
        setIsUploading(false);
        setSelectedDefaultImage('');
        setSelectedSquareImage('');
        ErrorToast(err.message);
        return resolve(false);
      }
    });
  };

  const onUploadMultipleJobBannerImage = async (banner, squareBanner) => {
    if (!banner || !squareBanner) return;

    setIsEditBannerPopUpOpen(false);

    setIsUploading(true);

    Promise.all([
      uploadBanner(banner, 'landscape'),
      uploadBanner(squareBanner, 'square')
    ])
      .then(values => {
        setIsUploading(false);
      })
      .catch(error => {
        setIsUploading(false);
      });
  };

  const desktopRatio = 8 / 2;

  const onEditBannerClose = () => {
    // setSelectedSquareImage('');
    // setSelectedDefaultImage('');
    setIsEditBannerPopUpOpen(false);
  };

  return (
    <BannerWrapper>
      <EditBannerPopUp
        setSelectedBanner={setSelectedImage}
        desktopRatio={desktopRatio}
        open={isEditBannerPopUpOpen}
        handleClose={onEditBannerClose}
        onUploadMultipleBannerImages={onUploadMultipleJobBannerImage}
        selectedDesktopBanner={selectedImage}
        selectedMobileBanner={selectedImage}
        isEdit={defaultImage}
      />
      <UploadContainer>
        <UploadBannerImg
          src={selectedDefaultImage ?? selectedImage}
          alt="banner"
        />
      </UploadContainer>

      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={event => {
          if (
            !event?.target?.files ||
            !event?.target?.files[0]?.type?.startsWith('image/')
          ) {
            // Reject non-image files
            ErrorToast('Only image files are allowed.');
            event.target.value = null; // Clear the file input value
            return;
          }
          handleImageChange(event);
        }}
      />
      {isUploading ? (
        <SpinnerContainer>
          <Spinner color={'white'} />
          <Text tag="spinner">Please wait ...</Text>
        </SpinnerContainer>
      ) : (
        <>
          <div>
            <BannerContentWrapper>
              {type == 'job' && (
                <BannerContentContainer>
                  <ImageContainer is_internship={intern ? 'true' : 'false'}>
                    {fetchingCompanySettings ? (
                      <Skeleton variant="rect" width={70} height={70} />
                    ) : (
                      <ImageStyled
                        src={myCompanySettings?.publicLogoUrl}
                        alt="logo"
                      />
                    )}
                  </ImageContainer>

                  {intern && (
                    <FreeJobMessageBoxContainer>
                      <FontAwesomeIconStyled
                        icon={faLightbulb}
                        awesome_icon={'faLightbulb'}
                      />
                      <FreeJobMessageDesc>
                        We suggest to include the word "Intern" or "Internship"
                        into your job title to attract more relevant applicants.
                      </FreeJobMessageDesc>
                    </FreeJobMessageBoxContainer>
                  )}

                  <JobTitle />

                  <CompanyNameContainer>
                    {fetchingCompanySettings ? (
                      <Skeleton variant="text" width={100} height={25} />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: '18px',
                          color: 'white',
                          lineHeight: '1',
                          color: 'rgba(255, 255, 255, 0.8)'
                        }}>
                        {myCompanySettings?.name}
                      </Typography>
                    )}
                  </CompanyNameContainer>

                  <CompanyLocation />
                </BannerContentContainer>
              )}
            </BannerContentWrapper>
          </div>

          <Wrapper>
            <ButtonStyled
              onClick={handleButtonClick}
              sx={{ backgroundColor: 'white' }}>
              <IconContainer awesome_icon={'faCamera'}>
                <FontAwesomeIconStyled
                  icon={faCamera}
                  awesome_icon={'faCamera'}
                />
              </IconContainer>
              <Text tag="button-title">
                {showEditOption ? 'Edit Cover Image' : 'Upload a Cover Image'}
              </Text>
            </ButtonStyled>

            {showEditOption && (
              <EditOptionContainer>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text tag="edit-option" onClick={onUploadCoverImageClick}>
                    Upload Cover Image
                  </Text>
                  <Text tag="edit-option" onClick={onRepositionClick}>
                    Readjust
                  </Text>
                </div>
              </EditOptionContainer>
            )}

            {!showEditOption && (
              <CustomTooltip
                width={type == 'job' ? '385px' : '365px'}
                height={'50px'}
                tooltip_transform={
                  showEditOption
                    ? 'translateY(170px) translateX(-34%)'
                    : 'translateY(110px) translateX(-34%)'
                }
                front_transform={'translateY(-60px) translateX(250px)'}
                back_transform={'translateY(-95px) translateX(246px)'}
                border_color={'#fff'}
                thumb_color={'#fff'}
                origin={'banner'}
                top={'auto'}
                right={'auto'}
                content={
                  <>
                    <p
                      style={{
                        fontSize: '12px',
                        fontFamily: 'Inter',
                        margin: '0'
                      }}>
                      {title}
                    </p>
                    {subTitle ? (
                      <p
                        style={{
                          fontSize: '12px',
                          fontFamily: 'Inter',
                          margin: '0'
                        }}>
                        {subTitle}
                      </p>
                    ) : null}
                  </>
                }
              />
            )}
          </Wrapper>
        </>
      )}
    </BannerWrapper>
  );
}

export const JobTitle = ({}) => {
  const key = 'title';
  const dispatch = useDispatch();
  const {
    formState,
    handleFieldChange,
    handleSingleFieldChange,
    errorState,
    handleErrorState
  } = useFormContext();

  const jobTitleSuggestionsRef = useRef();
  const container = useRef();

  const jobTitleGuideData = [
    { icon: faCheckCircle, text: 'Receptionist' },
    {
      icon: faTimesCircle,
      text: 'Director of First Impressions'
    },
    {
      icon: faCheckCircle,
      text: 'Customer Service specialist'
    },
    {
      icon: faTimesCircle,
      text: 'Phone Answering Superstar'
    }
  ];

  const jobTitleSuggestions = useSelector(
    state => state.jobs.jobTitleSuggestions
  );
  const fetchingJobTitleSuggestions = useSelector(
    state => state.jobs.fetchingJobTitleSuggestions
  );
  const isJobTitleDropdownSuggestionsOpen = useSelector(
    state => state.jobs.isJobTitleDropdownSuggestionsOpen
  );
  const tooltipStatus = useSelector(state => state.jobs.tooltipStatus);
  const prevTooltip = useSelector(state => state.jobs.prevTooltip);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const onSelectJobTitle = suggestedJobTitle => {
    //update job title after selecting from suggestion
    handleSingleFieldChange('title', suggestedJobTitle);
    //close job title suggestion dropdown
    dispatch(updateJobTitleSuggestionsDropdownStatus(false));
  };

  const scrollJobTitleSuggestionsDropdown = () => {
    jobTitleSuggestionsRef?.current?.scrollTo(
      0,
      jobTitleSuggestionsRef?.current?.children[selectedIndex]?.offsetTop - 100
    );
  };

  const onKeyToSelectJobTitleSuggestion = event => {
    if (!isJobTitleDropdownSuggestionsOpen && formState?.title?.length >= 3) {
      dispatch(updateJobTitleSuggestionsDropdownStatus(true));
    }

    if (formState?.title?.length == 0) {
      setSelectedIndex(0);
    }

    if (jobTitleSuggestionsRef) {
      if (event.keyCode == 40) {
        if (selectedIndex < jobTitleSuggestions?.length - 1) {
          setSelectedIndex(prevState => prevState + 1);
          handleSingleFieldChange(
            'title',
            jobTitleSuggestionsRef?.current?.children[selectedIndex + 1]
              ?.innerText
          );
          scrollJobTitleSuggestionsDropdown();
        } else {
          return;
        }
      } else if (event.keyCode == 38) {
        if (selectedIndex > 0) {
          setSelectedIndex(prevState => prevState - 1);
          handleSingleFieldChange(
            'title',
            jobTitleSuggestionsRef?.current?.children[selectedIndex - 1]
              ?.innerText
          );
          scrollJobTitleSuggestionsDropdown();
        } else {
          return;
        }
      } else if (event.keyCode == '13') {
        if (selectedIndex == 0) {
          handleSingleFieldChange(
            'title',
            jobTitleSuggestionsRef?.current?.children[0]?.innerText
          );
        }

        dispatch(updateJobTitleSuggestionsDropdownStatus(false));
      }
    }
  };

  const onClickTips = key => {
    let status = { ...tooltipStatus };

    if (status[key]) {
      status[key] = false;
      dispatch(updatePostJobTooltipStatus(status));
    } else {
      status[key] = true;
      dispatch(updatePostJobTooltipStatus(status));
    }

    if (prevTooltip.length > 0 && prevTooltip !== key) {
      status[prevTooltip] = false;
      dispatch(updatePostJobTooltipStatus(status));
    }

    dispatch(updatePostJobPrevTooltip(key));
  };

  useEffect(() => {
    const handler = event => {
      // check if text input is in focus. If no, job title dropdown will be closed
      if (!container?.current?.contains(event.target)) {
        dispatch(updateJobTitleSuggestionsDropdownStatus(false));
      }
    };
    document.addEventListener('click', handler);
    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  // Initialize the debounced function using useCallback to ensure it doesn't get recreated on each render
  const debouncedDispatch = useCallback(
    debounce(inputValue => {
      dispatch(fetchJobTitleSuggestions(inputValue));
    }, 300),
    []
  ); // Dependencies array is empty, indicating it will not be recreated after initial render

  const onChangeHandler = e => {
    handleFieldChange(e);
    debouncedDispatch(e.target.value);
  };

  return (
    <>
      <JobTitleContainer>
        <div style={{ position: 'relative' }} ref={container}>
          <TextFieldStyled
            style={{ padding: '18.5px 14px !important' }}
            white
            value={formState.title}
            fullWidth
            name="title"
            placeholder="Please fill in your job title"
            onChange={e => {
              onChangeHandler(e);
              handleErrorState(errorState.filter(error => error !== key));
            }}
            onKeyDown={event => onKeyToSelectJobTitleSuggestion(event)}
          />

          {isJobTitleDropdownSuggestionsOpen &&
            jobTitleSuggestions?.length > 0 && (
              <DropdownListContainer ref={jobTitleSuggestionsRef}>
                {jobTitleSuggestions?.map((item, index) => {
                  return (
                    <DropdownItem
                      $highlight={selectedIndex == index}
                      key={`${item?.name}-${index}`}
                      onClick={() => onSelectJobTitle(item?.name)}>
                      {fetchingJobTitleSuggestions ? (
                        <Text tag="dropdown">Loading...</Text>
                      ) : (
                        <Text tag="dropdown">{item?.name}</Text>
                      )}
                    </DropdownItem>
                  );
                })}
              </DropdownListContainer>
            )}
        </div>
        <TipsTooltipContainer>
          <Spacer width={'10px'} />
          <TipsTooltip
            title={'Job Title'}
            descriptions={[
              <Description>
                Jobseekers are more familiar with industry-standard terms &
                straightforward job titles. Using English & avoiding special
                characters ensures a wider reach as its easier to search.
              </Description>,
              <>
                {jobTitleGuideData.map(item => {
                  return (
                    <>
                      <FontAwesomeIcon icon={item.icon} />
                      <GuideText>{item.text}</GuideText>
                      <HeightSpacer height={'5px'} />
                    </>
                  );
                })}
              </>
            ]}
            top={'-10px'}
            left={'80px'}
            totalPages={2}
            minHeight={'200px'}
            onClick={() => onClickTips('jobTitle')}
            cKey={'jobTitle'}
          />
        </TipsTooltipContainer>
        {errorState.includes(key) && (
          <p
            style={{
              color: '#BE4242',
              fontSize: '0.9rem',
              fontWeight: '400',
              minWidth: '200px',
              position: 'absolute',
              right: '-32%',
              top: '10%'
            }}>
            Job title is required
          </p>
        )}
      </JobTitleContainer>
    </>
  );
};

export const CompanyLocation = () => {
  const keyOne = 'stateRegion';
  const keyTwo = 'location';
  const stateRegions = useSelector(state => getCountryStates(state));
  const {
    formState,
    handleFieldChange,
    handleSingleFieldChange,
    errorState,
    handleErrorState
  } = useFormContext();

  return (
    <LocationWrapper>
      <OfficeStyled edit_location={`true`}>
        <LocationContainer>
          {
            <EditLocationContainer>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%'
                }}>
                <FormSelectFieldContainer>
                  <TextFieldStyled
                    white
                    onChange={e => {
                      handleSingleFieldChange(e.target.name, {
                        id: e.target.value
                      });
                      handleErrorState(
                        errorState.filter(error => error !== keyOne)
                      );
                    }}
                    value={
                      formState?.stateRegion
                        ? formState?.stateRegion?.id
                        : 'placeholder'
                    }
                    name="stateRegion"
                    fullWidth
                    select
                    small
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem disabled value="placeholder" key={'placeholder'}>
                      {'Select Region/Country'}
                    </MenuItem>
                    {stateRegions.map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.title}
                      </MenuItem>
                    ))}
                  </TextFieldStyled>
                </FormSelectFieldContainer>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%'
                }}>
                <TextFieldStyled
                  white
                  value={formState.location}
                  onChange={e => {
                    handleErrorState(
                      errorState.filter(error => error !== keyTwo)
                    );
                    handleFieldChange(e);
                  }}
                  name="location"
                  fullWidth
                  small
                  placeholder="Fill in your office location"
                />{' '}
                <ErrorStateText
                  isTwoError={
                    errorState.includes(keyOne) && errorState.includes(keyTwo)
                  }>
                  {errorState.includes(keyOne) && (
                    <p>State and region is required</p>
                  )}

                  {errorState.includes(keyTwo) && <p>Location is required</p>}
                </ErrorStateText>
              </div>
            </EditLocationContainer>
          }
        </LocationContainer>
      </OfficeStyled>
    </LocationWrapper>
  );
};
