import styled from '@mui/material/styles/styled';
import { Grid } from '@mui/material';

export const Container = styled(Grid)({});

export const ContentContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  maxWidth: '1280px',
  margin: 'auto',
  height: '100%',
  width: '100%',
  gap: '88px',
  padding: '4rem 2rem',
  justifyContent: 'space-between'
  // gridTemplateColumns: '52% 40%'
});

export const WhiteCardContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: 'rgb(255, 255, 255)',
  boxShadow: 'rgba(0, 0, 0, 0.08) 0px 4px 17px',
  padding: '40px 32px',
  borderRadius: '26px'
  // gap: '15px',
});

export const LeftContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  width: '52%',
  gap: '32px'
});

export const RightContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  width: '40%',
  gap: '20px'
});

export const OuterContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  height: '100%',
  width: '100%',
  // marginBottom: '150px',
  gap: '50px',
  background: 'white'
});

export const Description = styled('span')(() => ({
  color: Colors.priBlack,
  opacity: 0.6,
  fontSize: '14px'
}));

export const Text = styled('span')(props => ({
  ...(props.type == 'salary-banner-title' && {
    fontFamily: 'Inter',
    fontSize: '13px',
    fontWeight: 700,
    color: Colors.priWhite
  }),
  ...(props.type == 'salary-banner-description' && {
    fontFamily: 'Inter',
    fontSize: '12px',
    color: Colors.priWhite,
    marginTop: '4px'
  }),
  ...(props.type == 'attention' && {
    fontFamily: 'Inter',
    fontSize: '12px',
    color: Colors.priWhite,
    fontWeight: 700,
    marginTop: '4px'
  }),
  ...(props.type == 'custom-tooltip' && {
    fontSize: '12px',
    fontFamily: 'Inter',
    margin: '0'
  }),
  ...(props.tag == 'spinner' && {
    color: '#FFF'
  }),
  ...(props.tag == 'job-title' && {
    color: '#fff',
    fontWeight: 'bold',
    fontFamily: 'Inter',
    fontSize: '27px',
    margin: '0 20px 0 0'
  }),
  ...(props.tag == 'company-name' && {
    color: 'rgba(255, 255, 255, 0.8)',
    fontFamily: 'Inter',
    fontSize: '18px',
    margin: '0'
  }),
  ...(props.tag == 'location' && {
    color: '#fff',
    fontFamily: 'Inter',
    fontSize: '18px',
    margin: '10px 20px 10px 0'
  }),
  ...(props.tag == 'button-title' && {
    margin: '0',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'Inter',
    transform: 'translateY(1px)'
  }),
  ...(props.tag == 'edit-option' && {
    padding: '10px 0 10px 0',
    margin: '0',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'Inter',
    cursor: 'pointer',
    width: '100%'
  }),
  ...(props.tag == 'dropdown' && {
    margin: '0',
    color: Colors.priBlack,
    fontSize: '16px'
  })
}));
