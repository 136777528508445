import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PopUpDialog } from '../../../../components';
import { HeightSpacer } from '../../../../components/Spacer/styles';
import {
  fetchDraftJob,
  updateJob,
  updateJobDataForm,
  updatePostJobPopup,
  updateProductTypeIndex
} from '../../../../redux/actions/job_action';
import { FREE_JOB_POST_POSITION } from '../../../../utils/Constants';
import { SharedButton } from '../../PopUps/PostJobPopup/SharedButton';
import Placeholder from '../ExpiryDatesPopUp/Placeholder';
import {
  faBriefcase,
  faCubes,
  faGraduationCap
} from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as EditIcon } from '../../../../assets/icons/job-list/edit.svg';
import {
  FirstContainer,
  SecondContainer,
  ButtonWrapper,
  ButtonContainer,
  Text,
  TextContainer,
  ContainerWrapper,
  ActionContainer,
  CardTitle,
  CardDescription,
  CardContainer,
  FontAwesomeIconStyled,
  ModalContainer,
  ModalTitle,
  ModalHeader,
  ModalContent,
  ModalText
} from './styles';
import Colors from '../../../../styles/colors.module.scss';
import { Grid, Skeleton } from '@mui/material';
// import { ModalContainer } from '../JobTypePopUp/styles';

const PostJobPopup = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const [cardSelected, setCardSelected] = useState('edit-job-draft');
  const fetchingDraftJob = useSelector(state => state?.jobs?.fetchingDraftJob);

  const isPostJobPopup = useSelector(state => state.jobs.isPostJobPopup);

  const [draft, setDraft] = useState();

  useEffect(() => {
    dispatch(fetchDraftJob()).then(res => {
      if (res.type === 'FETCH_DRAFT_JOB_SUCCEED') {
        setDraft(res?.draftJob);
        setCardSelected('edit-job-draft');
        // setLoaded(true);
      } else {
        setCardSelected('full-time-jobs');
        // setLoaded(true);
      }
    });
  }, [isPostJobPopup]);

  const [jobTypes, setJobTypes] = useState([]);

  useEffect(() => {
    const newJobTypes = [
      draft?.id
        ? {
            type: 'edit-job-draft',
            icon: EditIcon,
            title: 'Edit Draft Job',
            description:
              'You have one saved draft job. Click here to continue editing it.'
          }
        : {
            type: 'full-time-jobs',
            icon: faBriefcase,
            title: 'Full Time Jobs',
            description: 'Recruit full-time / part-time / freelance candidates.'
          },
      // {
      //   type: 'senior-jobs',
      //   icon: faCubes,
      //   title: 'Senior Jobs',
      //   description:
      //     'Recruit full-time candidates with more than 10 years of experience.'
      // },
      {
        type: 'internships',
        icon: faGraduationCap,
        title: 'Internships',
        description:
          'Recruit internship candidates without any years of experience.'
      }
    ];

    setJobTypes(newJobTypes);
  }, [isPostJobPopup]); // Only recalculate jobTypes when draftJob changes

  const productType = useSelector(state => state.jobs.productTypeIndex);
  const remainingUsableJobSlots = useSelector(
    state => state.companies.myCompanyStatus.jobSlotsTotalUsableQuantity
  );
  const seniorTokensUsableCount = useSelector(
    state => state.companies.myCompanyStatus.availableFreeSuperSeniorPostCount
  );
  const jobTokensUsableCount = useSelector(
    state => state.companies.myCompanyStatus.jobToken
  );
  const unlimitedJobToken = useSelector(
    state => state.companies.myCompanyStatus.unlimitedToken
  );
  const internshipTokensUsableCount = useSelector(
    state => state.companies.myCompanyStatus.availableInternshipsTokenCount
  );

  const availableJobCreditCount = useSelector(
    state => state.companies.myCompanyStatus.availableJobCreditCount
  );

  const remainingUsableJobSlotPlus = useSelector(
    state => state.companies.myCompanyStatus.jobSlotPlusTotalUsableQuantity
  );

  const remainingUsableJobSlotMax = useSelector(
    state => state.companies.myCompanyStatus.jobSlotMaxTotalUsableQuantity
  );

  const onClosePopup = () => {
    dispatch(updatePostJobPopup(false));
    dispatch(updateProductTypeIndex(''));
  };

  const onNavigate = type => {
    dispatch(updateJobDataForm({}));
    dispatch(updateJob({}));

    switch (type) {
      case 'edit-job-draft':
        {
          history('/jobs/edit-job-draft');
          onClosePopup();
        }
        break;
      case 'full-time-jobs':
        if (
          remainingUsableJobSlots > 0 ||
          jobTokensUsableCount > 0 ||
          unlimitedJobToken ||
          availableJobCreditCount > 0 ||
          remainingUsableJobSlotPlus > 0 ||
          remainingUsableJobSlotMax > 0 ||
          seniorTokensUsableCount > 0 ||
          internshipTokensUsableCount > 0 ||
          internshipTokensUsableCount == 'Unlimited'
        ) {
          history('/jobs/add/new-job');
          onClosePopup();
        } else {
          dispatch(updateProductTypeIndex('Job Slots'));
        }
        break;
      case 'senior-jobs':
        if (seniorTokensUsableCount > 0) {
          history({
            pathname: '/jobs/add/free-senior',
            state: {
              freeJobPostPosition: FREE_JOB_POST_POSITION.SENIOR
            }
          });
          onClosePopup();
        } else {
          dispatch(updateProductTypeIndex('Senior Posts'));
        }
        break;
      case 'internships':
        if (
          internshipTokensUsableCount > 0 ||
          internshipTokensUsableCount == 'Unlimited'
        ) {
          history({
            pathname: '/jobs/add/free-intern',
            state: {
              freeJobPostPosition: FREE_JOB_POST_POSITION.INTERN
            }
          });
          onClosePopup();
        } else {
          dispatch(updateProductTypeIndex('Internships'));
        }
        break;
    }
  };

  const onSelectCard = type => {
    setCardSelected(type);
  };

  return (
    <PopUpDialog
      open={isPostJobPopup}
      handleClose={onClosePopup}
      title={''}
      paperStyle={{ minHeight: 400 }}
      maxDialogWidth={675}
      dialogWidth={'auto'}
      removeContentPadding={true}
      hideTitle={true}>
      <ModalContainer>
        {productType?.length > 0 ? (
          <Placeholder />
        ) : (
          <>
            <ModalTitle>
              <ModalHeader>
                What type of job would you like to post?
              </ModalHeader>
              <ModalText>
                Please select how you would like to proceed.
              </ModalText>
            </ModalTitle>

            <SecondContainer>
              {jobTypes.map(jobType => {
                return fetchingDraftJob ? (
                  <Skeleton variant="rounded" width={305} height={200} />
                ) : (
                  <CardContainer
                    key={jobType.type}
                    onClick={() => onSelectCard(jobType.type)}
                    $selected={
                      cardSelected === jobType.type ? 'selected' : 'deselected'
                    }>
                    {jobType.type === 'edit-job-draft' ? (
                      <EditIcon
                        width={'40px'}
                        height={'40px'}
                        fill={Colors.priPurple}
                      />
                    ) : (
                      <FontAwesomeIconStyled
                        icon={jobType.icon}
                        $name={jobType.icon}
                      />
                    )}
                    <HeightSpacer height={'16px'} />
                    <CardTitle>{jobType.title}</CardTitle>
                    <HeightSpacer height={'16px'} />
                    <CardDescription>{jobType.description}</CardDescription>
                  </CardContainer>
                );
              })}
            </SecondContainer>

            <ActionContainer>
              <ButtonWrapper>
                <ButtonContainer>
                  {fetchingDraftJob ? (
                    <Skeleton variant="rounded" width={200} height={44} />
                  ) : (
                    <SharedButton
                      type="black"
                      min_width={'200px'}
                      font_size={'16px'}
                      onClick={() => onNavigate(cardSelected)}>
                      Proceed
                    </SharedButton>
                  )}
                </ButtonContainer>
              </ButtonWrapper>
            </ActionContainer>
          </>
        )}
      </ModalContainer>
    </PopUpDialog>
  );
};

export default PostJobPopup;
