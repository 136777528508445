import { Checkbox, TextField, Typography, Button, Grid } from '@mui/material';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { styled as styling } from '@mui/material/styles';

export const TermsText = styling(Typography)(({ theme }) => ({
  color: `rgba(0, 0, 0, 0.87)`,
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'italic',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: ' 0.15px',
  width: 'auto'
}));

export const TermsLink = styling('a')(({ theme }) => ({
  color: `#512ACC`,
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'italic',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: ' 0.15px',
  textDecoration: 'none',

  ':hover': {
    textDecoration: 'underline'
  }
}));

export const ErrorText = styling('span')(({ theme }) => ({
  color: `#F75443`,
  fontFamily: 'Inter',
  fontSize: '11px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: ' 0.15px'
  // textDecoration: 'underline'
}));

export const TermsError = styling('span')(({ theme }) => ({
  color: `#F75443`,
  fontFamily: 'Inter',
  fontSize: '11px',
  fontStyle: 'italic',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: ' 0.15px'
  // textDecoration: 'underline'
}));

export const CheckboxStyled = styling(Checkbox)(props => ({
  padding: '0px',
  margin: '0px 8px 0px 8px',
  color: 'transparent',
  overflow: 'hidden',
  '&.MuiCheckbox-root': {
    borderRadius: '4px',
    border: `1px solid ${props?.isError ? '#F75443' : '#AEAEAE'}`,
    width: 18,
    height: 18
  },
  '& .MuiSvgIcon-root': {
    fontSize: '18px'
  },

  '&.Mui-checked': {
    color: 'black',
    width: 18,
    height: 18
  },
  '&.MuiCheckbox-root:hover': {
    backgroundColor: 'transparent'
  },
  '& .MuiSvgIcon-root': {
    borderRadius: '4px'
  }
}));

export const TextFieldStyled = styling(TextField)(
  ({ theme, small, value, select, bold, white, name }) => ({
    width: '100%',
    // Adjust the padding of the input field to change its height
    '& .MuiInputBase-root': {
      fontSize: name == 'title' ? '1rem' : '0.85rem',
      color:
        value === 'placeholder' && select
          ? '#d8d8d8'
          : white
          ? 'white'
          : 'black', // Text color
      fontWeight: name == 'title' && value ? 'bold' : bold && 'bold',
      ...(small && {
        height: '45px',
        '& .MuiOutlinedInput-input': {
          '&::placeholder': {
            color: '#d8d8d8 !important', // Change to desired placeholder color
            opacity: 1 // Ensure the placeholder is fully visible
          },
          paddingTop: '10px', // Reduced top padding
          paddingBottom: '5px', // Increased bottom padding to move text down
          paddingLeft: '14px', // Maintain side paddings
          paddingRight: '14px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
          top: 0
        }
      })
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        borderColor: '#AEAEAE'
      },
      '&:hover fieldset': {
        borderColor: white ? 'white' : 'black' // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: white ? 'white' : 'black' // Border color on focus
      }
    },
    '& .MuiSvgIcon-root': {
      fill: '#d8d8d8',
      top: 'calc(50% - 0.4em)'
    },
    ...(small && {
      height: '45px' // Adjust the height of the entire component if small is true
    })
  })
);

export const CardContainer = styling(Grid)({
  padding: '40px 24px',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  textAlign: 'center',
  borderRadius: '24px'
});

export const CardTitle = styling(Typography)({
  color: `rgba(0, 0, 0, 0.87)`,
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: ' 20px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px'
});

export const CardText = styling(Typography)({
  color: `rgba(0, 0, 0, 0.60)`,
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: ' 16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.024px',
  maxWidth: '467px'
});

export const CardTextBold = styling('span')({
  color: `rgba(0, 0, 0, 0.60)`,
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: ' 16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px'
});

export const CardTextLink = styling('span')({
  color: `#512ACC`,
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: ' 16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.024px',
  cursor: 'pointer'
});

export const CardTextSmall = styling(Typography)({
  color: `rgba(0, 0, 0, 0.60)`,
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: ' 12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
  maxWidth: '316px',
  margin: 'auto'
});

export const ErrorContainer = styling(Grid)({
  display: 'flex',
  flexDirection: 'row',
  gap: '6px',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%'
});

export const CardTextError = styling(Typography)({
  color: `#F75443`,
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: ' 12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
  width: 'auto'
});

export const ExclamationCircleIconStyled = styling(ExclamationCircleIcon)({
  color: '#F75443',
  fontSize: '16px',
  height: 24,
  width: 24
});

export const ButtonContainer = styling(Grid)({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '16px',
  alignItems: 'center'
});

export const ProceedButton = styling(Button)({
  width: '250px',
  height: '42px',
  fontSize: '16px',
  fontWeight: 700,
  color: 'white',
  borderRadius: '100px',
  boxShadow: 'none !important',
  textTransform: 'none',

  ':disabled': {
    backgroundColor: '#D8D8D8',
    color: `rgba(0, 0, 0, 0.38)`
  }
});

export const SkipButton = styling(Button)({
  fontWeight: 700,
  fontSize: '16px',
  textTransform: 'none'
});

export const DigitContainer = styling(Grid)({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  justifyContent: 'center'
});

export const DigitErrorContainer = styling(Grid)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
});

export const TextFieldDigit = styling(TextField)({
  '& .MuiInputBase-input': {
    textAlign: 'center',
  },

  '& .MuiInputBase-root': {
    height: '48px',
    width: '46px',
    fontSize: '20px',
    fontWeight: 'bold',
    borderRadius: '8px',
    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
      {
        '-webkit-appearance': 'none',
        margin: 0
      }
  }
});
