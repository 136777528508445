import React, { useEffect, useState } from 'react';
import { Grid, InputAdornment } from '@mui/material';
import DefaultLayout from '../../../layouts/DefaultLayout';
import HiredlyLogo from '../../../assets/common/hiredly-logo-copyright.png';
import { MUIButtonStyled } from '../../../components/MUIButton';
import { CardContainer, Container, Logo, Title } from './styles';
import SharedTextField from '../../../components/SharedTextField';
import { HeightSpacer } from '../../../components/Spacer/styles';
import { XMarkIcon } from '@heroicons/react/24/solid';
import Colors from '../../../styles/colors.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../../redux/actions/login_action';
import { ErrorToast, SuccessToast } from '../../../utils/ToastUtils';
import { Spinner } from '../../../components';

export default function ResetPassword() {
  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const userType = queryParams.get('user');
  const token = queryParams.get('token');
  const expiry = queryParams.get('expiry');

  const resettingPassword = useSelector(
    state => state.logins.resettingPassword
  );
  const success = useSelector(state => state.logins.success);

  const [newPassword, setNewPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [typedNew, setTypedNew] = useState(false);
  const [typedRePass, setTypedRePass] = useState(false);

  const onChangeNewPass = e => {
    setNewPassword(e.target.value);
    setTypedNew(true);
  };

  const onRetypeNewPass = e => {
    setRetypePassword(e.target.value);
    setTypedRePass(true);
  };

  const onRemoveText = type => {
    if (type == 'new-password') {
      setNewPassword('');
    } else {
      setRetypePassword('');
    }
  };

  const onUpdatePassword = async () => {
    if (newPassword !== retypePassword) return;
    if (newPassword?.length <= 0 || retypePassword?.length <= 0) return;

    const params = {
      userType: userType,
      token: token,
      pass: retypePassword
    };

    let response = await dispatch(resetPassword(params));

    if (response.type == 'RESET_PASSWORD_SUCCEED') {
      SuccessToast('Your password has been updated successfully.');
      history('/login?passwordChanged=true')
    } else {
      ErrorToast('Failed to reset password');
      history('/forgot-password?error=invalid')
    }
  };

  const isError = origin => {
    if (origin == 'new-password' && newPassword?.length <= 0) return true;
    if (origin == 'retype-new-password' && retypePassword?.length <= 0)
      return true;
    if (newPassword?.length > 0 && retypePassword?.length > 0) {
      if (newPassword !== retypePassword) return true;
    }
  };

  const getHelperText = origin => {
    if (typedNew && origin == 'new-password' && newPassword?.length <= 0)
      return 'password needed';
    if (
      typedRePass &&
      origin == 'retype-new-password' &&
      retypePassword?.length <= 0
    )
      return 'retype password needed';
    if (newPassword?.length > 0 && retypePassword?.length > 0) {
      if (newPassword !== retypePassword) return 'check password';
    }
  };

  const disableButton = () => {
    if (resettingPassword) return true;

    if (newPassword?.length <= 0 || retypePassword?.length <= 0) return true;

    if (newPassword?.length > 0 && retypePassword?.length > 0) {
      if (newPassword !== retypePassword) return true;
    }
  };

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('accessToken');
    if (isAuthenticated) {
      return history('/my-account?tab=password',  { replace: true });
    }

    const currentUnixTimestamp = Math.floor(new Date().getTime() / 1000);
    const isExpired = currentUnixTimestamp > expiry;
    if (!isExpired) return;

    history('/forgot-password?error=invalid',  { replace: true });
  }, []);

  return (
    <DefaultLayout>
      <Container>
        <Logo alt="logo" src={HiredlyLogo} />
        <HeightSpacer height={'25px'} />
        <CardContainer>
          <Grid display={'flex'} justifyContent={'center'}>
            <Title>Create a new password</Title>
          </Grid>

          <HeightSpacer height={'30px'} />

          <Grid display={'flex'} flexDirection={'column'} gap="20px">
            <div>
              <span>New Password</span>
              <HeightSpacer height={'5px'} />
              <SharedTextField
                InputProps={textFieldProps(
                  'new-password',
                  onRemoveText,
                  newPassword
                )}
                value={newPassword}
                type={'password'}
                sx={{
                  width: '100%',
                  '.MuiInputBase-root': {
                    borderRadius: '8px'
                  },
                  '.MuiInputBase-input': {
                    padding: '16.5px 14px'
                  }
                }}
                onChange={onChangeNewPass}
                error={isError('new-password') && typedNew}
                helperText={getHelperText('new-password')}
                showPasswordStrength
              />
            </div>

            <div>
              <span>Confirm New Password</span>
              <HeightSpacer height={'5px'} />
              <SharedTextField
                InputProps={textFieldProps(
                  'retype-new-password',
                  onRemoveText,
                  retypePassword
                )}
                value={retypePassword}
                type={'password'}
                sx={{
                  width: '100%',
                  '.MuiInputBase-root': {
                    borderRadius: '8px'
                  },
                  '.MuiInputBase-input': {
                    padding: '16.5px 14px'
                  }
                }}
                onChange={onRetypeNewPass}
                error={isError('retype-new-password') && typedRePass}
                helperText={getHelperText('retype-new-password')}
              />
            </div>
          </Grid>

          <HeightSpacer height={'30px'} />

          <Grid display={'flex'} justifyContent={'center'}>
            <MUIButtonStyled
              style={{ cursor: 'pointer' }}
              onClick={onUpdatePassword}
              label={
                resettingPassword ? (
                  <Spinner size={'XS'} noPadding={true} />
                ) : (
                  'Update Password'
                )
              }
              disabled={disableButton()}
              $backgroundColor={disableButton() && Colors.terLightGrey}
              $width={'200px'}
            />
          </Grid>

          <HeightSpacer height={'20px'} />
          <Grid display={'flex'} justifyContent={'center'}>
            <a
              href="/login"
              style={{
                fontSize: '14px',
                fontFamily: 'Inter',
                textDecoration: 'none'
              }}>
              {success
                ? 'Back to Home'
                : 'Remembered your password? Click here to log in!'}
            </a>
          </Grid>
        </CardContainer>
      </Container>
    </DefaultLayout>
  );
}

const textFieldProps = (origin, onRemoveText, pass) => {
  return {
    placeholder:
      origin == 'new-password'
        ? 'Enter a new password'
        : 'Confirm your new password',
    style: {
      backgroundColor: '#fff',
      letterSpacing: '0.15px',
      '& .MuiInputBase-root': {
        color: '#222222'
      },
      '& .MuiFormLabel-root': {
        top: 0
      }
    },
    endAdornment:
      pass?.length > 0 &&
      (origin == 'new-password' || origin == 'retype-new-password') ? (
        <>
          <InputAdornment
            position="end"
            onClick={() => onRemoveText(origin)}
            sx={{
              '& :hover': {
                cursor: 'pointer'
              }
            }}>
            <XMarkIcon width="18px" height="18px" />
          </InputAdornment>
        </>
      ) : null
  };
};
