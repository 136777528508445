import { FormControlLabel, Grid, MenuItem } from '@mui/material';
import clevertap from 'clevertap-web-sdk';
import React, { useEffect, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Card, Stepper } from '../../components';
import { MUIButtonStyled } from '../../components/MUIButton';
import PasswordStrength from '../../components/PasswordStrength';
import SharedSelectCountry from '../../components/SharedSelectCountry';
import { setLocalStorageItem } from '../../helpers/local_storage_utils';
import DefaultLayout from '../../layouts/DefaultLayout';
import {
  fetchCompanySizes,
  mutateEmployerCompanyResgistration,
  mutateEmployerLogin,
  updateCountryDropdown
} from '../../redux/actions/company_actions';
import store from '../../redux/stores/store';
import { JOBSEEKER_URL } from '../../utils/Constants';
import { ErrorToast } from '../../utils/ToastUtils';
import styles from './EmployerRegistration.module.scss';
import './LoginInformation.css';
import {
  CheckboxStyled,
  ErrorText,
  TermsError,
  TermsLink,
  TermsText,
  TextFieldStyled
} from './styles';
import { getCountry } from '../../hooks/useRegionalisation';
import { setUserToDatadog } from '../../hooks/useDatadog';
import SharedTextField from '../../components/SharedTextField';
import { ArrowDropDownIconStyled } from '../MyAccount/styles';

const validationSchema = yup.object().shape({
  userName: yup.string().required('Your Name is required'),
  userEmail: yup.string().email('Invalid email').required('Email is required'),
  agreeToTerms: yup
    .boolean()
    .oneOf([true], 'You must accept the terms and conditions'),
  name: yup.string().required('Company name is required'),
  sizeId: yup.string().required('Number of Employees is required'),
  password: yup.string().min(8).required('Please enter your password'),
  passwordConfirmation: yup.string().required('Password confirm is required'),
  phoneNumber: yup.string().required('Contact number is required')
});

const SpecialInput = ({ mobileNumber, handlePhoneNumber, errorText }) => {
  let defaultCountry = store.getState().companies.country ?? 'MY';

  return (
    <Grid sx={{ position: 'relative' }}>
      <div className={styles.PhoneInput}>
        <PhoneInput
          error={errorText}
          placeholder="+6012 3456789"
          name="phoneNumber"
          value={mobileNumber}
          defaultCountry={defaultCountry}
          countryCallingCodeEditable={false}
          international
          onChange={e => handlePhoneNumber(e)}
        />
        <Grid
          sx={{
            zIndex: 999,
            position: 'absolute',
            top: '18px',
            right: '330px'
          }}>
          <ArrowDropDownIconStyled />
        </Grid>
        {errorText && <ErrorText>{errorText}</ErrorText>}
      </div>
    </Grid>
  );
};

export default function LoginInformation({ showPasswordStrength }) {
  const location = useLocation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const referralToken = query.get('referral_token');

  // regionalisation
  const dropdownCountry = useSelector(state => state.companies.country);
  const queryCountry = query.get('country')?.toUpperCase();
  const queryFromJSW = query.get('origin');

  const changeCountry = queryCountry => {
    dispatch(updateCountryDropdown(queryCountry));
    setLocalStorageItem('country', queryCountry);
  };

  useEffect(() => {
    dispatch(fetchCompanySizes());

    // set default country value if sign up page is opened using link
    if (queryFromJSW != 'jobseeker' && dropdownCountry?.length == 0) {
      changeCountry('MY');
      return;
    }

    // use country from link if coming from jsw web
    if (queryFromJSW == 'jobseeker') {
      changeCountry(queryCountry);
      return;
    }
  }, []);

  // redux
  const companySizes = useSelector(state => state.companies.companySizes);
  const formattedCompanySize = companySizes?.map(size => {
    return {
      label: size?.title,
      value: size?.id
    };
  });

  const mutatingRegisterCompany = useSelector(
    state => state.companies.mutatingRegisterCompany
  );
  const mutatingEmployerLogin = useSelector(
    state => state.companies.mutatingEmployerLogin
  );
  const country = useSelector(state => state.companies.country);

  const [errors, setErrors] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState({
    userName: '',
    userEmail: '',
    name: '',
    sizeId: '',
    phoneNumber: '',
    password: '',
    passwordConfirmation: '',
    trackingParameters: null,
    agreeToTerms: isChecked
  });

  const handleFieldChange = e => {
    let { name, value } = e.target || e;

    if (!e.target) {
      ({ name, value } = e);
    }

    // Update form data
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    if (name === 'passwordConfirmation') {
      const passwordsMatch =
        formData.passwordConfirmation === formData.password;
      if (!passwordsMatch) {
        setErrors(prev => ({
          ...prev,
          passwordConfirmation: 'Password does not match'
        }));
      } else {
        setErrors(prev => ({ ...prev, passwordConfirmation: undefined }));
      }
    }

    if (name === 'passwordConfirmation' && name === 'phoneNumber') {
      return;
    } else {
      validationSchema
        .validateAt(name, { [name]: value }) // Validate only the field that changed
        .then(() => {
          // If validation succeeds, clear error for this field
          setErrors(prev => ({ ...prev, [name]: undefined }));
        })
        .catch(error => {
          // If validation fails, set error for this field
          setErrors(prev => ({ ...prev, [name]: error.message }));
        });
    }
  };

  // Check passwords after state update
  useEffect(() => {
    // Check if passwords match
    const passwordsMatch = formData.passwordConfirmation === formData.password;

    if (!passwordsMatch) {
      setErrors(prev => ({
        ...prev,
        passwordConfirmation: 'Password does not match'
      }));
    } else {
      setErrors(prev => ({ ...prev, passwordConfirmation: undefined }));
    }
  }, [formData]);

  const handleChange = event => {
    setIsChecked(event.target.checked);

    setErrors(prev => ({ ...prev, ['agreeToTerms']: undefined }));

    setFormData(prev => ({
      ...prev,
      ['agreeToTerms']: event.target.checked
    }));
  };

  const handlePhoneNumber = number => {
    setFormData(prev => ({
      ...prev,
      ['phoneNumber']: number
    }));

    if (number) {
      let validPhoneNumber = isValidPhoneNumber(number);
      if (!validPhoneNumber) {
        setErrors(prev => ({
          ...prev,
          ['phoneNumber']: 'Please enter valid phone number'
        }));
      } else {
        setErrors(prev => ({
          ...prev,
          ['phoneNumber']: null
        }));
      }
    } else {
      setErrors(prev => ({ ...prev, [name]: 'Contact number is required' }));
    }
  };

  const onSubmit = async values => {
    event.preventDefault(); // Prevent default form submission

    try {
      // Perform form validation
      await validationSchema.validate(formData, { abortEarly: false });

      if (formData?.userEmail?.includes('@hiredly.com')) {
        ErrorToast('Cannot register with hiredly email');
        return;
      }

      // To track users who sign up from an advertisement
      let currentReferralToken = referralToken;
      let trackingParameters = document.cookie
        .split('; ')
        .find(row => row.startsWith('params='))
        ?.toString()
        .replace('params=', '');

      // Check whether referral token is inside the cookie
      if (trackingParameters?.includes('referral_token')) {
        trackingParameters = trackingParameters.split('&'); // Split param by '&'
        // Find index of referral token
        const referralTokenIndex = trackingParameters.findIndex(param =>
          param.includes('referral_token')
        );

        let referral_token = '';

        if (referralTokenIndex != -1) {
          // Retrieve the token value
          referral_token = trackingParameters[referralTokenIndex].replace(
            'referral_token=',
            ''
          );

          // If current url does not consist of any referral token
          if (!currentReferralToken) {
            currentReferralToken = referral_token; // assign token value from cookie
          }

          trackingParameters.splice(referralTokenIndex, 1); // Remove referral token from params
          trackingParameters = trackingParameters.join('&'); // Form tracking params string
        }
      }

      document.cookie = `params=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`; // Delete cookie for params

      let referralTokenParam = {};

      if (currentReferralToken) {
        referralTokenParam = { referralToken: currentReferralToken };
      }

      let res = null;

      try {
        // Register the company first
        const registerResponse = await dispatch(
          mutateEmployerCompanyResgistration({
            input: {
              userName: formData?.userName,
              userEmail: formData?.userEmail,
              name: formData?.name,
              sizeId: Number(formData?.sizeId),
              phoneNumber: formData?.phoneNumber,
              password: formData?.password,
              passwordConfirmation: formData?.passwordConfirmation,
              trackingParameters: trackingParameters,
              leadSource: `signed_up ${
                queryFromJSW && `origin:employer_landing`
              }`,
              agreeToTerms: isChecked,
              ...referralTokenParam
            }
          })
        );

        // If there's error message, show the toast then stop the function
        if (registerResponse?.error && registerResponse.error.length > 0) {
          registerResponse.error.forEach(error => {
            ErrorToast(error?.message);
          });
          return;
        }

        // If success then proceed with login, which will resolve the invalid credential error
        res = await dispatch(
          mutateEmployerLogin({
            input: {
              email: formData?.userEmail,
              password: formData?.password
            }
          })
        );
      } catch (e) {
        ErrorToast(e.message);
      }

      const loginData = res?.loginEmployer;

      const accessToken = loginData?.token;
      const accountId = loginData?.user?.id;
      const companyId = loginData?.user?.companyId;
      const email = loginData?.user?.email;
      const companyName = loginData?.user?.companyName;
      const accountType = loginData?.user?.currentAccountType;

      if (loginData != null && process.env.REACT_APP_DATADOG_ENV !== 'LOCAL') {
        setUserToDatadog(accountId, email, companyName);
      }

      if (companyId) {
        try {
          if (getCountry() == 'MY') {
            clevertap.onUserLogin.push({
              Site: {
                Name: companyName ?? '-', // String
                Identity: companyId ?? '-', // String or number
                Email: values?.userEmail ?? '-' // Email address of the user
                // Phone: null, // Phone (with the country code)
                // Gender: null, // Can be either M or F
                // DOB: null // Date of Birth. Date object
              }
            });
          }

          document.cookie = `companyId=${companyId}; path=/; max-age=31540000`;
          document.cookie = `gtmCountry=${country}; path=/; max-age=31540000`;

          window.dataLayer.push({
            event: 'sign_up'
          });

          window.dataLayer.push({
            event: 'login'
          });
        } catch {}
      }

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('currentAccountType', accountType);

      const accountInfo = {
        ...(accountId && { accountId }),
        ...(companyId && { companyId }),
        ...(companyName && { companyName }),
        ...(accountType && { accountType })
      };

      localStorage.setItem('accountInfo', JSON.stringify(accountInfo));

      history('/register/verification');
    } catch (err) {
      // If validation fails, update the errors state to display error messages
      const newErrors = {};
      err.inner.forEach(error => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);

      if (formData?.phoneNumber === '') {
        setErrors(prev => ({
          ...prev,
          ['phoneNumber']: 'Contact number is required'
        }));
      } else {
        setErrors(prev => ({
          ...prev,
          ['phoneNumber']: null
        }));
      }

      if (err.graphQLErrors) {
        err.graphQLErrors
          .filter(item => {
            return (
              item.message != 'User email has already been taken; ' &&
              item.message != 'Name has already been taken; '
            );
          })
          .map(error => {
            ErrorToast(error.message.replace('.', '').replace(';', '.'));
          });
      }
    }
  };

  const goLogin = () => {
    history('/login');
  };

  return (
    <DefaultLayout>
      <div className={styles.pageWrapper}>
        <div className={styles.topSection}>
          <h4 className={styles.title} style={{ fontSize: '30px' }}>
            Create your employer account in 2 minutes!
          </h4>
          <div className={styles.flowSection}>
            <Stepper
              list={[
                'Login Information',
                'Email Verification',
                'Company Details (Optional)'
              ]}
              currentStep={2}
            />
          </div>
        </div>

        <Card className={styles.card}>
          <h5 className={styles.title} style={{ fontSize: '24px' }}>
            Login Information
          </h5>

          <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            <SharedSelectCountry hideSG={true} />

            <div>
              <SharedTextField
                placeholder="Your name"
                name="userName"
                onChange={handleFieldChange}
                error={errors['userName']}
              />
              <ErrorText>{errors['userName']}</ErrorText>
            </div>

            <div>
              <SharedTextField
                placeholder="E-mail"
                name="userEmail"
                onChange={handleFieldChange}
                error={errors['userEmail']}
              />
              <ErrorText>{errors['userEmail']}</ErrorText>
            </div>

            <div>
              <SharedTextField
                name="name"
                placeholder="Registered Company Name"
                fieldType="text"
                onChange={handleFieldChange}
                error={errors['name']}
              />
              <ErrorText>{errors['name']}</ErrorText>
            </div>

            <div>
              <TextFieldStyled
                select
                name="sizeId"
                value={formData?.sizeId || 'placeholder'}
                placeholder="Number of Employees"
                onChange={handleFieldChange}
                error={errors['sizeId']}>
                <MenuItem disabled value="placeholder" key={'placeholder'}>
                  {'Number of Employees'}
                </MenuItem>
                {formattedCompanySize?.map(size => {
                  return (
                    <MenuItem key={size?.value} value={size?.value}>
                      {size?.label}
                    </MenuItem>
                  );
                })}
              </TextFieldStyled>
              <ErrorText>{errors['sizeId']}</ErrorText>
            </div>
            <div className={[styles.row, styles.phoneRow].join(' ')}>
              <SpecialInput
                errorText={errors['phoneNumber']}
                mobileNumber={formData?.phoneNumber}
                handlePhoneNumber={handlePhoneNumber}
              />
            </div>

            <div>
              <SharedTextField
                name="password"
                placeholder="Password"
                type="password"
                onChange={handleFieldChange}
                // showPasswordStrength={showPasswordStrength}
                error={errors['password']}
              />
              <ErrorText>{errors['password']}</ErrorText>
            </div>

            <PasswordStrength password={formData?.password} />
            <div>
              <SharedTextField
                name="passwordConfirmation"
                placeholder="Confirm Password"
                type="password"
                onChange={handleFieldChange}
                error={errors['passwordConfirmation']}
              />
              <ErrorText>{errors['passwordConfirmation']}</ErrorText>
            </div>

            <div style={{ marginBottom: '24px' }}>
              <Grid sx={{ marginLeft: '4px' }}>
                <FormControlLabel
                  control={
                    <CheckboxStyled
                      isError={errors['agreeToTerms']}
                      name="agreeToTerms"
                      checked={isChecked}
                      onChange={handleChange}
                      sx={{
                        padding: '0px',
                        strokeWidth: '1px',
                        margin: '0px 8px 0px 8px'
                      }}
                    />
                  }
                  label={
                    <TermsText>
                      I accept Hiredly’s{' '}
                      <TermsLink
                        target="_blank"
                        href={
                          JOBSEEKER_URL[dropdownCountry] + '/privacy-policy'
                        }>
                        Privacy Policy
                      </TermsLink>{' '}
                      and the{' '}
                      <TermsLink
                        href={
                          JOBSEEKER_URL[dropdownCountry] +
                          '/terms-and-conditions'
                        }
                        target="_blank">
                        Terms of Conditions
                      </TermsLink>
                    </TermsText>
                  }
                  sx={{ width: 'auto' }}
                />
              </Grid>

              {errors['agreeToTerms'] && (
                <TermsError>
                  You must accept the terms and conditions.
                </TermsError>
              )}
            </div>

            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: 'auto',
                alignItems: 'center'
              }}>
              <MUIButtonStyled
                label="Register as Employer"
                onClick={onSubmit}
                disabled={mutatingRegisterCompany || mutatingEmployerLogin}
              />

              <div style={{ margin: '15px 0 0' }}>
                <a className={styles.loginLink} onClick={goLogin}>
                  Have an account? Log in
                </a>
              </div>
            </Grid>
          </Grid>
        </Card>

        <div className={styles.disclaimerContainer}>
          <p className={styles.texts}>
            By clicking “Register as Employer” above, you acknowledge that you
            have read and agreed to Hiredly’s
          </p>

          <a
            className={styles.link}
            href={JOBSEEKER_URL[dropdownCountry] + '/terms-and-conditions'}
            target="_blank">
            Terms & Conditions{' '}
          </a>
          <p className={styles.texts} styles={{ marginLeft: '3px' }}>
            and
          </p>
          <a
            href={JOBSEEKER_URL[dropdownCountry] + '/privacy-policy'}
            target="_blank"
            className={styles.link}>
            Privacy Policy.{' '}
          </a>
        </div>
      </div>
    </DefaultLayout>
  );
}
