import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../../styles/colors.module.scss';

export const Title = styling('span')({
  fontFamily: 'Inter',
  fontWeight: 700,
  fontSize: '16px',
  letterSpacing: '0.1px',
  color: Colors.priPurple,
});

export const Subtitle = styling('span')({
  fontFamily: 'Inter',
  fontWeight: 700,
  fontSize: '16px',
  letterSpacing: '0.1px',
  color: Colors.priBlack,
});

export const Description = styling('span')({
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '14px',
  color: Colors.priBlack,
});
