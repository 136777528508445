import React, { Fragment, useState } from 'react';
import {
  DateWrapper,
  OuterWrapper,
  TitleStyled,
  InputAdornmentStyled,
  IconButtonStyled,
  CalenderIcon
} from './styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { updateUnlockedTalentsFilters } from '../../../../redux/actions/talent_action';
import moment from 'moment';

export const DateFilter = () => {
  const dispatch = useDispatch();

  const unlockedTalentFilters = useSelector(
    state => state.talents.unlockedTalentFilters
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const getEvent = event => {
    setAnchorEl(event.currentTarget);
  };

  const dateHandler = e => {
    if (!unlockedTalentFilters) return;

    let talentFilters = { ...unlockedTalentFilters };
    talentFilters.unlockedDate = e;
    dispatch(updateUnlockedTalentsFilters(talentFilters));
  };

  return (
    <Fragment>
      <OuterWrapper>
        <TitleStyled>Unlocked Date</TitleStyled>

        <DateWrapper>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={unlockedTalentFilters.unlockedDate}
              inputFormat="DD/MM/YYYY"
              onChange={dateHandler}
              PopperProps={{
                placement: 'bottom-end',
                anchorEl: anchorEl
              }}
              maxDate={moment()}
              renderInput={params => (
                <TextField
                  {...params}
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    width: '100%',
                    '.MuiInputBase-root': {
                      borderRadius: '4px'
                    },
                    '.MuiInputBase-input': {
                      padding: '10px 4px 10px 16px'
                    }
                  }}
                  onClick={getEvent}
                />
              )}
            />
          </LocalizationProvider>
        </DateWrapper>
      </OuterWrapper>
    </Fragment>
  );
};
