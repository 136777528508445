import React from 'react';
import { TextFieldWrapper, OuterWrapper, TitleStyled } from './styles';
import { InputAdornment } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import SharedTextField from '../../../../components/SharedTextField';
import {
  updateCurrentTalent,
  updateUnlockedTalentsFilters
} from '../../../../redux/actions/talent_action';
import store from '../../../../redux/stores/store';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import Colors from '../../../../styles/colors.module.scss';

const textFieldProps = onSearchKeyword => {
  return {
    placeholder: 'Search by Keywords',

    style: {
      //fontSize: '14px',
      backgroundColor: Colors.priWhite,
      // fontFamily: 'Inter',
      letterSpacing: '0.15px',
      '& .MuiInputBase-root': {
        color: '#222222'
      },
      '& .MuiFormLabel-root': {
        top: 0
      }
    },
    endAdornment: (
      <>
        <InputAdornment
          position="end"
          onClick={onSearchKeyword}
          sx={{
            '& :hover': {
              cursor: 'pointer'
            }
          }}>
          <MagnifyingGlassIcon style={{ width: '18px', height: '18px' }} />
        </InputAdornment>
      </>
    )
  };
};

export const SearchFilter = ({ triggerLoadTalents }) => {
  const dispatch = useDispatch();

  const unlockedTalentFilters = useSelector(
    state => state.talents.unlockedTalentFilters
  );

  const onChangeKeyword = e => {
    let cUnlockedTalentFilters = {
      ...store.getState().talents.unlockedTalentFilters
    };

    cUnlockedTalentFilters.keyword = e.target.value;

    dispatch(updateUnlockedTalentsFilters(cUnlockedTalentFilters));
  };

  const onSearchKeyword = () => {
    dispatch(updateCurrentTalent({}));

    if (triggerLoadTalents) {
      triggerLoadTalents('unlocked', '', 1);
    }
  };

  const onKeyPress = event => {
    if (event.key == 'Enter') {
      onSearchKeyword();
    }
  };

  return (
    <>
      <OuterWrapper>
        <TitleStyled>Search Talents</TitleStyled>

        <TextFieldWrapper>
          <SharedTextField
            InputProps={textFieldProps(onSearchKeyword)}
            value={unlockedTalentFilters.keyword}
            onChange={onChangeKeyword}
            sx={{
              width: '100%',
              '.MuiInputBase-root': {
                borderRadius: '4px'
              },
              '.MuiInputBase-input': {
                padding: '10px 4px 10px 16px'
              }
            }}
            onKeyPress={onKeyPress}
          />
        </TextFieldWrapper>
      </OuterWrapper>
    </>
  );
};
