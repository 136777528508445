import { styled as styling } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Skeleton } from '@mui/material';

export const LabelStyled = styling(Typography)(props => ({
  fontWeight: 'regular',
  fontSize: '14px',
  lineHeight: '1.5rem',
  fontFamily: 'Inter',
  letterSpacing: '0.03rem',
  ...(props.sort_by == 'true' && {
    fontSize: '14px'
  }),
  ...(props.card_info == 'true' && {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'break-spaces'
  }),
  ...(props.applicant_card_info == 'true' && {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    display: '-webkit-box',
    WebkitLineClamp: 5,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'break-spaces'
  }),
  ...(props.applicant_card_info_time == 'true' && {
    fontSize: '14px',
    color: '#aeaeae'
  })
}));

export const Title = styling(Typography)(props => ({
  fontFamily: 'Inter',
  letterSpacing: '0.03rem',
  fontSize: '16px',
  fontWeight: 'bold',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  whiteSpace: 'break-spaces'
}));

export const TalentSearchChatContainer = styling('div')(props => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '24px',
  maxWidth: '90%',
  background: '#efefef',
  border: '1px solid #d8d8d8',
  borderRadius: '8px',
  margin: 'auto'
}));

export const TalentSearchChatText = styling(Typography)(props => ({
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: 'Inter',
  letterSpacing: '0.1px'
}));

export const JobCardSection = styling(Grid)(props => ({
  width: '100%',
  padding: '20px 0',
  maxHeight: 'calc(100vh - 64px)',
  overflow: 'auto'
}));

export const SortByContainer = styling(Grid)(props => ({
  display: 'flex',
  justifyContent: 'flex-end',
  maxWidth: '90%',
  margin: 'auto',
  alignSelf: 'center',
  alignItems: 'center'
}));

export const CardsContainer = styling(Grid)(props => ({
  margin: '20px auto',
  maxWidth: '90%'
}));

export const PurpleUnreadIndicator = styling(Grid)(props => ({
  position: 'absolute',
  right: '12px',
  top: '12px',
  display: 'flex',
  backgroundColor: '#512ACC',
  color: 'white !important',
  width: '32px',
  height: '20px',
  padding: '4px 6px',
  borderRadius: '100px',
  fontWeight: '500 !important',
  fontSize: '10px',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const CardStyled = styling(Card)(props => ({
  borderRadius: '20px',
  border: '1px solid #aeaeae',
  padding: '25px',
  margin: '20px 0',
  cursor: 'pointer',
  position: 'relative',
  ...(props.active == 'true' && {
    border: '2px solid #512acc'
  })
}));

export const CardContentStyled = styling(CardContent)(props => ({
  minHeight: '130px',
  height: '100%',
  display: 'flex',
  padding: '0 !important',
  ...(props.applicant_card_height == 'true' && {
    height: '250px'
  })
}));

export const TextContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  justifyContent: 'space-between'
}));

// SKELETON
export const JobCardWrapperSkeleton = styling('div')(props => ({
  position: 'relative',
  width: '90%',
  height: '180px',
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: '20px',
  margin: '20px auto'
}));

export const JobTitleSkeleton = styling(Skeleton)(props => ({
  position: 'absolute',
  zIndex: 1,
  top: '10%',
  left: '8%'
}));

export const JobTrackSkeleton = styling(Skeleton)(props => ({
  position: 'absolute',
  zIndex: 1,
  bottom: '25%',
  left: '8%'
}));

export const JobTypeSkeleton = styling(Skeleton)(props => ({
  position: 'absolute',
  zIndex: 1,
  bottom: '10%',
  left: '8%'
}));
