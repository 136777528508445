import { Dialog, Grid, Typography } from '@mui/material';
import { styled as styling } from '@mui/material/styles';
import Slider from 'react-slick';

export const GridStyled = styling(Grid)(props => ({
  height: '720px !important',
  width: '100%',
  padding: '40px 100px 24px 100px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  maxWidth: '1440px',
  margin: 'auto',

  [props.theme.breakpoints.down('lg')]: {
    padding: '40px 24px',
    height: '630px'
  }
}));

export const SliderStyled = styling(Slider)(props => ({
  height: props.logoCarousel ? 'auto' : '550px !important',
  width: '100%',
  borderRadius: props.logoCarousel ? '' : '16px',
  overflow: props.logoCarousel ? 'hidden' : ''
}));

export const SliderWrapper = styling(Grid)(props => ({
  height: '550px',
  width: '100%',
  borderRadius: '16px',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'flex-end'
}));

export const ImageGrid = styling(Grid)(props => ({
  padding: '10px',
  height: '550px',
  backgroundImage: `url(${props.image})`,
  display: 'flex !important',
  alignItems: 'flex-end',
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover'
}));

// For the white container within the slider
export const SliderInnerContainer = styling(Grid)(props => ({
  backgroundColor: 'white',
  height: 'auto',
  width: '500px',
  padding: '16px 18px',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '18px',
  position: 'absolute',
  marginLeft: '10px',
  marginBottom: '10px',
  zIndex: 100
}));

export const TextContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px'
}));

export const SliderBoldTitle = styling(Typography)(props => ({
  fontSize: '42px',
  fontWeight: '700',
  lineHeight: '45px'
}));

export const SliderDescription = styling(Typography)(props => ({
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const ButtonContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  gap: '10px'
}));

// Company Logos
export const OuterLogoContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '14px',
  width: '100%',
  height: 'auto'
}));

export const LogoContainer = styling(Grid)(props => ({
  overflow: 'scroll',
  flexWrap: 'nowrap'
}));

export const LogoTitle = styling(Typography)(props => ({
  textAlign: 'center',
  color: `rgba(0, 0, 0, 0.87)`,
  fontSize: '25px',
  fontWeight: 700,
  lineHeight: '32px'
}));

export const LogoTitlePurple = styling('span')(props => ({
  color: '#512ACC',
  fontSize: '25px',
  fontWeight: 700,
  lineHeight: '32px'
}));
