// import React from 'react';
// import { useMutation } from '@apollo/client';
// import PropTypes from 'prop-types';
// import { Button, PopUpDialog, Spinner } from '../../../../components';
// import FeatureIcon from '../../../../assets/images/jobs/feature-job-icon.png';
// import { useNavigate } from 'react-router-dom';
// import { FEATURE_JOB } from '../../../../apollo/mutations';
// import { GET_ACCOUNT_STATUS, GET_JOBS } from '../../../../apollo/query';
// import styles from './index.module.scss';
// import { ErrorToast, SuccessToast } from '../../../../utils/ToastUtils';
// import { useDispatch, useSelector } from 'react-redux';
// import {
//   featureJob,
//   updateAllJobs,
//   updateFeatureTokensCount
// } from '../../../../redux/actions/job_action';
// import store from '../../../../redux/stores/store';
// import { MUIButtonStyled } from '../../../../components/MUIButton';

// const FeatureJobPopUp = ({
//   open,
//   handleClose,
//   jobId,
//   jobTitle,
//   // setIsDisableFeature,
//   // setIsDisableRefresh,
//   // getCompanyAccountInfo,
//   // replaceJob,
//   featuredTokensCount
// }) => {
//   const history = useNavigate();
//   const dispatch = useDispatch();
//   const loading = useSelector(state => state.jobs.featuringJob);
//   const allJobs = store.getState().jobs.jobs;
//   // const [featureJob, { loading }] = useMutation(FEATURE_JOB);
//   const featuredTokensUsableCount = useSelector(
//     state => state.jobs.subscribedFeaturedTokensPackage?.total_usable_quantity
//   );

//   const onFeatureJob = async () => {
//     try {
//       const params = {
//         jobId: jobId
//       };
//       dispatch(featureJob(params)).then(res => {
//         const currentJobIndex = allJobs.findIndex(item => {
//           return item?.id == jobId;
//         });

//         if (res.type == 'FEATURE_JOB_SUCCEED') {
//           if (allJobs[currentJobIndex]) {
//             allJobs[currentJobIndex] = res.job;
//           }

//           dispatch(updateFeatureTokensCount(featuredTokensUsableCount - 1));

//           dispatch(updateAllJobs(allJobs));
//           SuccessToast('Feature Job Successful!');
//         } else {
//           ErrorToast('Feature Job Failed!');
//         }
//         handleClose();
//       });

//       // if (replaceJob) {
//       //   replaceJob(latestJob);
//       // }

//       // const res = await featureJob({
//       //   variables: {
//       //     input: {
//       //       jobId
//       //     }
//       //   }
//       // });

//       // if (getCompanyAccountInfo) {
//       //   getCompanyAccountInfo();
//       // }

//       // setIsDisableFeature(true);
//       // setIsDisableRefresh(true);
//     } catch (err) {
//       ErrorToast(err.message);
//     }
//   };

//   const onPurchaseButtonClicked = () => {
//     history(`/purchase/choose?product=booster_token`);
//   };

//   const getTitle = () => {
//     if (featuredTokensCount) {
//       return `Feature ${jobTitle}?`;
//     }

//     return 'No Featured Tokens Available';
//   };

//   const renderNoFeatureTokenComponent = () => {
//     return (
//       <span className={styles.noFeatureTokenDescription}>
//         {`Refine your recruitment journey by purchasing Featured Tokens!`}
//       </span>
//     );
//   };

//   const renderPurchaseButton = () => {
//     return (
//       <div className={styles.purchaseButtonContainer}>
//         <Button color="PURPLE" size="AUTO" onClick={onPurchaseButtonClicked}>
//           Purchase Featured Tokens
//         </Button>
//       </div>
//     );
//   };

//   return (
//     <PopUpDialog open={open} handleClose={handleClose} title={getTitle()}>
//       <div style={{ padding: '20px 20px' }}>
//         <div className={styles.contentContainer}>
//           {featuredTokensCount && (
//             <img
//               title="Feature Icon"
//               alt="Feature Icon"
//               src={FeatureIcon}
//               style={{ height: 60, width: 60 }}
//             />
//           )}

//           {featuredTokensCount ? (
//             <p className={styles.featureJobDescription}>
//               {`By featuring this job, one Featured Token will be deducted from your
//             account.`}
//               <span>
//                 <br />
//                 You have
//               </span>
//               <span
//                 style={{
//                   fontWeight: 'bold'
//                 }}>{` (${featuredTokensCount} Featured Tokens)`}</span>
//               <span> left.</span>
//             </p>
//           ) : (
//             renderNoFeatureTokenComponent()
//           )}
//         </div>

//         {loading ? (
//           <div style={{ height: 95 }}>
//             <Spinner />
//           </div>
//         ) : featuredTokensCount ? (
//           <div className={styles.buttonContainer}>
//             <MUIButtonStyled
//               large
//               solidWhite
//               onClick={handleClose}
//               label="Cancel"
//             />
//             <MUIButtonStyled large onClick={onFeatureJob} label="Yes" />
//           </div>
//         ) : (
//           renderPurchaseButton()
//         )}
//       </div>
//     </PopUpDialog>
//   );
// };

// FeatureJobPopUp.propTypes = {
//   open: PropTypes.bool,
//   handleClose: PropTypes.func,
//   jobId: PropTypes.string,
//   jobTitle: PropTypes.string,
//   setIsDisableFeature: PropTypes.func,
//   featuredTokensCount: PropTypes.number
// };

// FeatureJobPopUp.defaultProps = {
//   open: false,
//   handleClose: () => {},
//   jobId: '',
//   jobTitle: '',
//   setIsDisableFeature: () => {},
//   featuredTokensCount: null
// };

// export default FeatureJobPopUp;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HeightSpacer } from '../../../../components/Spacer/styles';
import {
  featureJob,
  updateAllJobs,
  updateFeaturedJobPopup,
  updateFeatureTokensCount
} from '../../../../redux/actions/job_action';
import { ErrorToast, SuccessToast } from '../../../../utils/ToastUtils';
import store from '../../../../redux/stores/store';
import TokensPopup from '../../SharedPopups/TokensPopup';
import {
  ContentDescription,
  ContentInfo,
  ExplainerContainer,
  ExplainerInfo,
  ExplainerInfoWrapper,
  ExplainerNote,
  ExplainerTitle,
  GifContainer
} from './styles';
import FeaturedTokenVisualExplainer from '../../../../assets/images/jobs/featured_token_visual_explainer.gif';
import { mutateCart } from '../../../../redux/actions/order_action';

const FeatureJobConfirmationPopup = ({ jobTitle, jobId, jobData }) => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const isFeaturedJobOpen = useSelector(state => state.jobs.isFeaturedJobOpen);
  const loading = useSelector(state => state.jobs.featuringJob);
  const allJobs = store.getState().jobs.jobs;
  const featuredTokensUsableCount = useSelector(
    state => state.jobs.subscribedFeaturedTokensPackage?.total_usable_quantity
  );

  const onClosePopup = (count, jobData = {}) => {
    try {
      if (count > 0) {
        if (extendDuration(jobData)) {
          window.dataLayer.push({
            event: 'CE_extend-popup-feature-cancel'
          });
        } else {
          window.dataLayer.push({
            event: 'CE_use-popup-feature-cancel'
          });
        }
      } else if (count == 0) {
        window.dataLayer.push({
          event: 'CE_purchase-popup-feature-cancel'
        });
      }
    } catch {}

    dispatch(updateFeaturedJobPopup(false));
  };

  const getPopupTitle = (count, jobData) => {
    if (count > 0) {
      if (extendDuration(jobData)) {
        return `Extend Feature Duration`;
      }
      return `Feature ${jobTitle}?`;
    } else {
      return `No Featured Token Available`;
    }
  };

  const getContentMessage = (count, jobData) => {
    if (count > 0) {
      if (extendDuration(jobData)) {
        return `By extending feature to this job, one Featured Token will be deducted from your account. `;
      }

      return 'By featuring this job, one Featured Token will be deducted from your account.';
    } else {
      return 'Refine your recruitment journey by purchasing Featured Tokens!';
    }
  };

  const getFeaturedTokenCount = (count, jobData) => {
    let tokenCount = featuredTokensUsableCount;

    if (count > 0) {
      return (
        <span>
          You have {''}
          <b>{tokenCount} Featured Tokens</b> {''} left.
        </span>
      );
    } else {
      return ``;
    }
  };

  const extendDuration = job => {
    if (job?.featureJobThreshold > 0 && job?.featureJobThreshold <= 25) {
      return true;
    }
    return false;
  };

  const onSubmitFeaturedToken = async (count, jobId, jobData) => {
    try {
      if (count > 0) {
        if (extendDuration(jobData)) {
          onFeatureJob(jobId, count, jobData);
          window.dataLayer.push({
            event: 'CE_extend-popup-feature-confirm'
          });
        } else {
          onFeatureJob(jobId, count, jobData);

          window.dataLayer.push({
            event: 'CE_use-popup-feature-confirm'
          });
        }
      } else {
        await dispatch(
          mutateCart({
            input: {
              productId: '173',
              quantity: 1
            }
          })
        );
        history('/purchase/choose');
        window.dataLayer.push({
          event: 'CE_purchase-popup-feature-confirm'
        });
      }
    } catch {}
  };

  const onFeatureJob = async jobId => {
    try {
      const params = {
        jobId: jobId
      };
      dispatch(featureJob(params)).then(res => {
        const currentJobIndex = allJobs.findIndex(item => {
          return item?.id == jobId;
        });

        if (res.type == 'FEATURE_JOB_SUCCEED') {
          if (allJobs[currentJobIndex]) {
            allJobs[currentJobIndex] = res.job;
          }

          dispatch(updateFeatureTokensCount(featuredTokensUsableCount - 1));

          dispatch(updateAllJobs(allJobs));
          SuccessToast('Feature Job Successful!');
        } else {
          ErrorToast('Feature Job Failed!');
        }
        onClosePopup();
      });
    } catch (err) {
      ErrorToast(err.message);
    }
  };

  return (
    <TokensPopup
      title={getPopupTitle(featuredTokensUsableCount, jobData)}
      firstButtonText={'Cancel'}
      secondButtonText={
        featuredTokensUsableCount > 0 ? 'Confirm' : 'Purchase Now'
      }
      open={isFeaturedJobOpen}
      onClose={onClosePopup}
      onClickFirstButton={() =>
        onClosePopup(featuredTokensUsableCount, jobData)
      }
      onClickSecondButton={() =>
        onSubmitFeaturedToken(featuredTokensUsableCount, jobId, jobData)
      }
      loadingSecondButton={loading}>
      <>
        <ContentInfo>
          <GifContainer>
            <img
              alt="featured-token-visual-explainer"
              style={{ width: '100%', maxHeight: '231px' }}
              src={FeaturedTokenVisualExplainer}
            />
          </GifContainer>
          <ExplainerContainer>
            <ExplainerTitle>How can Featured Tokens help you?</ExplainerTitle>
            <ExplainerInfoWrapper>
              <ExplainerInfo>
                Once a Featured Token is applied to a job, it will appear first
                on the featured jobs list and mobile app for 7 days.
              </ExplainerInfo>
              <ExplainerInfo margin_top="true">
                Employers using Featured Tokens get 1.9x more job views* on
                average.
              </ExplainerInfo>
            </ExplainerInfoWrapper>
            <ExplainerNote>
              *Actual job views for your role may vary based on different
              factors such as industry and experience level.
            </ExplainerNote>
          </ExplainerContainer>
        </ContentInfo>
        <HeightSpacer height={'24px'} />
        <ContentDescription>
          {getContentMessage(featuredTokensUsableCount, jobData)}
        </ContentDescription>
        {featuredTokensUsableCount > 0 ? (
          <HeightSpacer height={'24px'} />
        ) : null}
        <ContentDescription>
          {getFeaturedTokenCount(featuredTokensUsableCount, jobData)}
        </ContentDescription>
      </>
    </TokensPopup>
  );
};

export default FeatureJobConfirmationPopup;
