import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Welcome from '../WelcomePage/Welcome';
import Company from '../CompanyPage/Company';
import Branding from '../BrandingPage/Branding';
import Register from '../RegisterPage/Register';
import Vision from '../VisionPage/Vision';
import Complete from '../CompletePage/Complete';

import {
  updateCurrentOnboardingPage,
  fetchWaitlistInfo
} from '../../../../redux/actions/company_actions';

import {
  LeftSection,
  Container,
  BackgroundDesign,
  Ball1,
  Ball2,
  BackLeft,
  BackRight
} from './styles';

export default function WaitlistPage(props) {
  const dispatch = useDispatch();
  const currentOnboardingPage = useSelector(
    state => state.companies.currentOnboardingPage
  );

  const backgroundValue = useSelector(state => state.companies.currentBg);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [currentOnboardingPage]);

  useEffect(() => {
    const waitlistInfoString = localStorage.getItem('waitlistInfo');
    const queryParams = new URLSearchParams(location.search);
    const linkMail = queryParams.get('email');

    const isValidEmail = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    const fetchData = async email => {
      try {
        const response = await dispatch(fetchWaitlistInfo({ email }));

        if (
          response?.type === 'FETCH_WAITLIST_INFO_SUCCEED' &&
          response.currentValue
        ) {
          dispatch(updateCurrentOnboardingPage('complete'));
        }
      } catch (error) {}
    };

    if (waitlistInfoString) {
      try {
        const waitlistInfo = JSON.parse(waitlistInfoString);

        if (waitlistInfo?.companyWaitlist?.email) {
          fetchData(waitlistInfo.companyWaitlist.email);
        }
      } catch (error) {}
    } else if (linkMail && isValidEmail(linkMail)) {
      fetchData(linkMail);
    }
  }, [location.search]);

  return (
    <Fragment>
      <BackgroundDesign>
        <BackLeft currentBg={backgroundValue}>
          <Ball1 currentBg={backgroundValue}></Ball1>
          <Ball2 currentBg={backgroundValue}></Ball2>
        </BackLeft>
        <BackRight currentBg={backgroundValue}></BackRight>
      </BackgroundDesign>

      <Container>
        <LeftSection>
          {currentOnboardingPage === 'waitlist' ? (
            <Welcome currentOnboardingPage={currentOnboardingPage} />
          ) : currentOnboardingPage === 'branding' ? (
            <Branding />
          ) : currentOnboardingPage === 'company-canvas' ? (
            <Company />
          ) : currentOnboardingPage === 'register' ? (
            <Register />
          ) : currentOnboardingPage === 'vision' ? (
            <Vision />
          ) : currentOnboardingPage === 'complete' ? (
            <Complete />
          ) : null}
        </LeftSection>
      </Container>
    </Fragment>
  );
}
