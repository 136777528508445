import { styled as styling } from '@mui/material/styles';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { DialogContent, DialogTitle } from '@mui/material';

export const PageContainer = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  alignItems: 'center',

  [props.theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}));

export const SecondContainer = styling('div')(props => ({
  position: 'relative',
  width: '50%',
  background: '#fff',
  height: '100%',
  padding: '50px 100px',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  justifyContent: 'center',
  animation: 'fadeIn 0.8s ease-in both',

  '@keyframes fadeIn': {
    from: {
      opacity: '0'
    },

    to: {
      opacity: '1'
    }
  },

  [props.theme.breakpoints.down('md')]: {
    minHeight: 'unset',
    padding: '40px 0px',
    maxWidth: '500px',
    margin: 'auto',
    width: '100%'
  },

  [props.theme.breakpoints.down('sm')]: {
    padding: '16px 16px 32px 16px'
  }
}));

export const HeightSpacer = styling('div')(props => ({
  height: props.$height ? props.$height : '4px'
}));

export const RightButton = styling('div')(props => ({
  display: 'flex',
  gap: '24px',

  [props.theme.breakpoints.down('sm')]: {
    gap: '16px'
  }
}));

export const NextPageButton = styling(Button)(props => ({
  width: '200px',
  height: '48px',
  backgroundColor: '#2E2E2E',
  borderRadius: '100px',
  color: '#FFF',
  fontSize: '16px',
  textTransform: 'none',
  fontWeight: '700',
  padding: '0 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',

  ':disabled': {
    backgroundColor: '#D8D8D8',
    cursor: 'not-allowed',
    color: '#FFF',

    ':hover': {
      backgroundColor: '#D8D8D8'
    }
  },

  ':hover': {
    backgroundColor: '#222'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '180px',
    height: '36px',
    fontSize: '14px'
  }
}));

export const SkipButton = styling(Button)(props => ({
  width: '100px',
  height: '48px',
  backgroundColor: '#FFF',
  borderRadius: '100px',
  border: '2px solid #222',
  color: '#222',
  fontSize: '16px',
  textTransform: 'none',
  fontWeight: '700',
  padding: '0 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',

  ':disabled': {
    backgroundColor: '#D8D8D8',
    cursor: 'not-allowed',
    color: '#FFF',
    border: 'none',

    ':hover': {
      backgroundColor: '#D8D8D8'
    }
  },

  ':hover': {
    backgroundColor: '#FFF'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '80px',
    height: '36px',
    fontSize: '14px'
  }
}));

export const BackButton = styling(IconButton)(props => ({
  padding: '8px 0',
  ':hover': {
    background: 'none',
  },
  ':disabled': {
    cursor: 'not-allowed'
  }
}));

export const DialogTitleStyled = styling(DialogTitle)(props => ({
  padding: '16px 16px 16px 24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px',

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    padding: '11.521px 11.521px 11.521px 17.281px',
    lineHeight: '22px',
  }
}));

export const DialogContentStyled = styling(DialogContent)(props => ({
  padding: '16px 24px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    padding: '11.521px 17.281px',
    lineHeight: '20px',
    letterSpacing: '0.112px',
  }
}));

export const DialogActionsStyled = styling(DialogTitle)(props => ({
  padding: '0px',
}));

export const DialogButton = styling('div')(props => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '16px 24px',
  gap: '16px',
  width: '100%',

  [props.theme.breakpoints.down('sm')]: {
    padding: '11.521px 17.281px',
    gap:'11.521px'
  }
}));

export const ButtonContinueEdit = styling(Button)(props => ({
  width: '200px',
  height: '48px',
  backgroundColor: '#FFF',
  borderRadius: '100px',
  border: '2px solid #222',
  color: '#222',
  fontSize: '16px',
  textTransform: 'none',
  fontWeight: '700',
  padding: '0 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',

  ':hover': {
    backgroundColor: '#FFF'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '130px',
    height: '30px',
    fontSize: '12px',
    padding: '0 8px'
  }
}));

export const ButtonSkipEdit = styling(Button)(props => ({
  width: '200px',
  height: '48px',
  backgroundColor: '#2E2E2E',
  borderRadius: '100px',
  color: '#FFF',
  fontSize: '16px',
  textTransform: 'none',
  fontWeight: '700',
  padding: '0 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',

  ':hover': {
    backgroundColor: '#2E2E2E'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '130px',
    height: '30px',
    fontSize: '12px',
    padding: '0 10px'
  }
}));


export const FooterContainer = styling(Box)(props => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0',
  alignItems: 'center',
  paddingTop: '16px',
  position: 'sticky',
  bottom: '0',
  background: 'white',

  [props.theme.breakpoints.down('md')]: {
    paddingTop: '6px',
    position: 'relative'
  },

  [props.theme.breakpoints.down('sm')]: {
    paddingTop: '6px',
    position: 'relative'
  }
}));

export const KeyboardBackspaceIconStyled = styling(ArrowLeftIcon)(props => ({
  padding: '0',
  width: '24px',
  height: '24px',
  color: '#000'
}));

export const TextFieldLabel = styling('div')(props => ({
  position: 'relative',
  color: 'rgba(0, 0, 0, 0.60)',
  fontSize: '15px',
  fontWeight: 700
}));

export const AnimatedContainer = styling('div')(props => ({
  position: 'relative',
  width: '50%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  padding: '50px 100px',
  animation: 'slideUp 1s ease-in-out',

  '@keyframes slideUp': {
    '0%': {
      opacity: '1',
      transform: 'translateY(100%)'
    },
    '100%': {
      opacity: '1',
      transform: 'translateY(0%)'
    }
  },

  [props.theme.breakpoints.down('md')]: {
    width: '100%',
    height: '30rem',
    padding: '16px',
    animation: 'none'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '45vh',
    padding: '16px',
    animation: 'none'
  }
}));

export const ContentContainer = styling('div')(props => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  gap: '40px',
  justifyContent: 'space-evenly',

  [props.theme.breakpoints.down('md')]: {
    maxWidth: '500px',
    maxHeight: '250px',
    margin: 'auto',
    width: '100%',

    animation: 'slideUp 0.8s ease-in-out',
    height: '100%',

    '@keyframes slideUp': {
      '0%': {
        opacity: '1',
        transform: 'translateY(100%)'
      },

      '100%': {
        opacity: '1',
        transform: 'translateY(0)'
      }
    }
  },

  [props.theme.breakpoints.down('sm')]: {
    gap: '24px',
    maxHeight: '150px'
  }
}));

export const Title = styling('h1')(props => ({
  fontSize: '42px',
  color: '#40249B',
  fontWeight: 700,
  margin: '0',
  lineHeight: '45px',

  [props.theme.breakpoints.down('md')]: {
    fontSize: '42px'
  },
  [props.theme.breakpoints.down('sm')]: {
    fontSize: '25px',
    lineHeight: '32px'
  }
}));

export const Message = styling('span')(props => ({
  letterSpacing: '0.15px',
  fontSize: '16px',
  color: 'rgba(0, 0, 0, 0.87)',
  margin: '0',
  fontWeight: 400,
  position: 'relative',
  lineHeight: '24px',

  [props.theme.breakpoints.down('md')]: {
    fontSize: '16px',
    letterSpacing: '0.15px'
  },

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    letterSpacing: '0.112px;',
    lineHeight: '20px'
  }
}));

export const MultiForm = styling('form')(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  '& .MuiTextField-root': {
    margin: 0,
    width: '100%'
  },
  maxHeight: '550px',
  overflow: 'auto',
  scrollbarWidth: 'thin',
  '-ms-overflow-style': 'none',
  '&::-webkit-scrollbar': {
    width: '0.5em'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent'
  },
  [props.theme.breakpoints.down('md')]: {
    maxHeight: 'unset'
  },
  [props.theme.breakpoints.down('sm')]: {
    maxHeight: 'unset'
  }
}));

MultiForm;
