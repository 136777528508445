import {
  ButtonContainer,
  GridStyled,
  ImageGrid,
  LogoTitle,
  LogoTitlePurple,
  OuterLogoContainer,
  SliderBoldTitle,
  SliderDescription,
  SliderInnerContainer,
  SliderStyled,
  SliderWrapper,
  TextContainer
} from './styles';
import React, { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { MUIButtonStyled } from '../../../components/MUIButton';
import { useDispatch, useSelector } from 'react-redux';
import { updateContactSalesPopupStatus } from '../../../redux/actions/job_action';
import {
  fetchProductCarousel,
  fetchProductTrustedLogo,
  updateProductBundle
} from '../../../redux/actions/product_action';

export default function CompanyLogo() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProductTrustedLogo());
  }, []);

  const trustedLogo = useSelector(state => state.products.trustedLogo);

  const LogoCarousel = () => {
    const settings = {
      infinite: true,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      speed: 3000,
      autoplaySpeed: 3000,
      cssEase: 'linear',
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 5
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3
          }
        }
      ]
    };

    return (
      <OuterLogoContainer>
        <LogoTitle>
          Trusted by Malaysia's <LogoTitlePurple>Best</LogoTitlePurple>
        </LogoTitle>

        <SliderStyled {...settings} logoCarousel>
          {trustedLogo?.map((logo, index) => {
            const logoUrl = logo?.brandLogo?.data?.attributes?.url;
            return (
              <div
                key={index}
                style={{ overflow: 'hidden' }}
                onClick={() => window.open(`${logo?.clickThroughURL}`)}>
                <img
                  width={100}
                  height={40}
                  style={{ marginRight: '200px', objectFit: 'contain' }}
                  src={logoUrl}
                  alt={`Logo ${index + 1}`}
                />
              </div>
            );
          })}
        </SliderStyled>
      </OuterLogoContainer>
    );
  };

  return <LogoCarousel />;
}
