import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MUIButtonStyled } from '../../../../components/MUIButton';
import {
  unlockResume,
  updateModelState
} from '../../../../redux/actions/talent_action';
import { ErrorToast, InfoToast } from '../../../../utils/ToastUtils';
import { ChevronDoubleUpIcon } from '@heroicons/react/24/solid';
import {
  ButtonStyled,
  ContentBoldStyled,
  ContentStyled,
  DialogContentStyled,
  Wrapper,
  BannerText
} from '../styles';
import { HeightSpacer, Spacer } from '../../../../components/Spacer/styles';

export const UnlockModal = () => {
  const dispatch = useDispatch();

  const talent = useSelector(state => state.talents.talent);
  const myCompanyStatus = useSelector(state => state.companies.myCompanyStatus);

  const unlockHandler = () => {
    const params = {
      userId: talent.id,
      currentPrice: talent.resumePrice,
      user: talent
    };

    dispatch(unlockResume(params)).then(res => {
      if (res.type == 'UNLOCK_RESUME_FAILED' && res.message) {
        ErrorToast(res.message);
      }

      if (res.type == 'UNLOCK_RESUME_SUCCEED' && !res.isPriceChanged) {
        InfoToast('Successfully unlock this profile');
      }

      if (res.isPriceChanged) {
        dispatch(updateModelState(true));
      }
    });

    dispatch(updateModelState(false));

    // TALENT SEARCH TRACKING
    window.dataLayer.push({
      event: talent.isPriceChanged
        ? 'CE_click-unlock-profile-price-change-popup'
        : 'CE_click-unlock-profile-profile-confirmation-popup',
      value: params.currentPrice,
      'user-id': params.userId,
      'remaining-coins': myCompanyStatus.availableCoins
    });
  };

  const cancelHandler = () => {
    dispatch(updateModelState(false));
  };

  return (
    <DialogContentStyled>
      <Wrapper
        container
        direction="column"
        justifyContent="flex-start"
        sx={{ marginTop: '0px' }}>
        {talent.isPriceChanged && (
          <Wrapper
            container
            sx={{
              background: 'rgba(242, 166, 71, 0.2)',
              borderRadius: '4px',
              position: 'absolute',
              top: '0',
              left: '0',
              padding: '10px 20px',
              display: 'flex',
              alignItems: 'center'
            }}>
            <ChevronDoubleUpIcon
              style={{ height: '22px', width: '22px', color: '#F2A647' }}
            />
            <Spacer width={'6px'} />
            <BannerText>The price of this profile has changed.</BannerText>
          </Wrapper>
        )}
        {talent.isPriceChanged && <HeightSpacer height={'40px'} />}
        <ContentStyled>
          Note: Unlocking this profile will use{' '}
          <ContentBoldStyled>{talent.resumePrice}</ContentBoldStyled> Tokens.
        </ContentStyled>
      </Wrapper>

      <Wrapper
        container
        justifyContent="space-between"
        sx={{ marginTop: talent.isPriceChanged ? '20px' : '16px' }}>
        <ButtonStyled
          variant="outlined"
          onClick={cancelHandler}
          sx={{
            ':hover': {
              border: '2px solid #512acc'
            }
          }}>
          No Thanks
        </ButtonStyled>

        <MUIButtonStyled onClick={unlockHandler} label="Unlock Profile" large />
      </Wrapper>
    </DialogContentStyled>
  );
};
