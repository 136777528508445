import React, { useState } from 'react';
import {
  CancelIcon,
  CardContentContainer,
  CardStyled,
  CardSubTitleStyled,
  CardTitleContainer,
  CardTitleStyled,
  DefaultContainer,
  IconContainer,
  SolidInformationIconStyled
} from './styles';
import { Divider, Typography } from '@mui/material';
import IconButton from '../IconButton';

export default function ContentCard(props) {
  const {
    title,
    subTitle,
    children,
    cancelIcon,
    cancelOnClick,
    infoIcon,
    infoOnClick,
    noChanges,
    discardButton,
    discardAction,
    padding
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <CardStyled onClick={() => setIsOpen(false)}>
      {/* title */}
      <CardTitleContainer>
        <DefaultContainer>
          <CardTitleStyled>{title}</CardTitleStyled>
          <CardSubTitleStyled>{subTitle}</CardSubTitleStyled>
        </DefaultContainer>

        <IconContainer>
          {infoIcon && (
            <IconButton tooltipTitle="More information" onClick={infoOnClick}>
              <SolidInformationIconStyled />
            </IconButton>
          )}

          {cancelIcon && (
            <IconButton
              tooltipTitle={isOpen ? 'Discard Changes' : 'Close'}
              onClick={e => {
                e.stopPropagation();
                if (discardButton) {
                  if (!noChanges && !isOpen) {
                    setIsOpen(true);
                  }

                  if (!noChanges && isOpen) {
                    cancelOnClick();
                    discardAction && discardAction();
                  }

                  if (noChanges) {
                    cancelOnClick();
                  }
                } else {
                  cancelOnClick();
                }
              }}
              discard={isOpen}>
              {!isOpen && <CancelIcon />}
              {isOpen && (
                <Typography
                  sx={{
                    pointerEvents: 'none',
                    whiteSpace: 'nowrap'
                  }}>
                  Discard Changes
                </Typography>
              )}
            </IconButton>
          )}
        </IconContainer>
      </CardTitleContainer>
      <Divider />

      {/* content */}
      <CardContentContainer padding={padding}>{children}</CardContentContainer>
    </CardStyled>
  );
}
