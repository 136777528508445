export function formatToDefaultFormData(incomingData, draftJob) {
  const defaultFormData = {
    active: incomingData.active || false,
    title: incomingData.title || '',
    location: incomingData.location || '',
    minSalaryRange: incomingData.minSalaryRange || null,
    maxSalaryRange: incomingData.maxSalaryRange || 0,
    hideSalary: incomingData.hideSalary || false,
    shortDescription: incomingData.shortDescription || '',
    description: incomingData.description || '',
    requirements: incomingData.requirements || '',
    descriptionDouble: '',
    descriptionAshley: '',
    requirementsDouble: '',
    requirementsAshley: '',
    tracks: incomingData.tracks || [],
    jobType: incomingData.jobType || {},
    careerLevel: incomingData.careerLevel || {},
    stateRegion: incomingData.stateRegion || { id: 'placeholder' },
    skills: incomingData.skills || [],
    minYearExperience: incomingData.minYearExperience || 0,
    maxYearExperience: incomingData.maxYearExperience || 0,
    jobBannerUrl: incomingData.jobBannerUrl || '',
    jobBannerUrlSquare: incomingData.jobBannerUrlSquare || '',
    receiveNotification: incomingData.receiveNotification || false,
    usingCustomEmail: incomingData?.usingCustomEmail || false,
    customEmail: incomingData.customEmail || '',
    imagePosition: incomingData.imagePosition || '',
    imageSize: incomingData.imageSize || '',
    localOnly: incomingData.localOnly || false,
    externalJobUrl: incomingData.externalJobUrl || '',
    customInput: incomingData.customInput || '',
    gptRewrites: incomingData.gptRewrites || {},
    keywords: incomingData.keywords || null,
    resourceType: incomingData.resourceType || '',
    enableKeywordHighlight: incomingData.enableKeywordHighlight || false,
    globalHirePreferences: {
      enabled: incomingData.globalHirePreferences?.enabled || false,
      workingArrangementRemote: incomingData?.globalHirePreferences?.workingArrangementRemote|| false,
      workingArrangementOnSite: incomingData?.globalHirePreferences?.workingArrangementOnSite || false,
      workingArrangementHybrid: incomingData?.globalHirePreferences?.workingArrangementHybrid || false,
      rightToWorkOpenToSponsor: incomingData?.globalHirePreferences?.rightToWorkOpenToSponsor || false,
      rightToWorkLocalCitizen: incomingData?.globalHirePreferences?.rightToWorkLocalCitizen || false,
      rightToWorkPermanentCitizen: incomingData?.globalHirePreferences?.rightToWorkPermanentCitizen || false,
      rightToWorkWorkVisaOrEmploymentPass: incomingData?.globalHirePreferences?.rightToWorkWorkVisaOrEmploymentPass || false
    }
  };

  if (!draftJob) {
    defaultFormData.id = incomingData.id || '';
  }

  return defaultFormData;
}
