import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as axios } from '../reducers/axios_reducer';
import { reducer as jobs } from '../reducers/job_reducer';
import { reducer as applications } from '../reducers/application_reducer';
import { reducer as products } from '../reducers/product_reducer';
import { reducer as orders } from '../reducers/order_reducer';
import { reducer as companies } from '../reducers/company_reducer';
import { reducer as talents } from '../reducers/talent_reducer';
import { reducer as chats } from '../reducers/chat_reducer';
import { reducer as logins } from '../reducers/login_reducer';

const combinedReducer = combineReducers({
  axios,
  jobs,
  applications,
  products,
  orders,
  companies,
  talents,
  chats,
  logins
});

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware));
const store = createStore(combinedReducer, composedEnhancer);

export default store;
