import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../../../styles/colors.module.scss';

export const Container = styling('div')((props) => ({
  position: 'absolute',
  top: '40px',
  left: '-50px',
  background: Colors.priWhite,
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
  borderRadius: '20px',
  overflow: 'hidden',
  minWidth: '250px',
  zIndex: 10,
}));

export const TextContainer = styling('div')((props) => ({
  padding: '14px 35px',
  '&:hover': {
    cursor: 'pointer',
    background: Colors.terLighterGrey,
  },
  ...(props.highlight == 'true' && {
    background: Colors.secLightPurple,
  }),
}));

export const Text = styling('div')((props) => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  color: Colors.priBlack,
  fontWeight: 400,
}));
