import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  BookmarkBorderOutlined as BookmarkBorderOutlinedIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
  Bookmark as BookmarkIcon
} from '@mui/icons-material';

import ProfilePopup from '../../SharedPopUps/ProfilePopup';
import { HeightSpacer } from '../../../../components/Spacer/styles';
import { Divider, Grid } from '@mui/material';
import {
  HeaderContainer,
  IconButtonStyled,
  IconsContainer,
  SimpleDescription,
  TabsStyled,
  TabStyled,
  CardTitle,
  CardSubtitle,
  UnlockedAtStyled,
  NationalityTitle
} from './styles';
import ProfilePanel from './ProfilePanel';
import Resume from './Resume';
import NotesPanel from './NotesPanel';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../../../../components';
import { debounce } from 'lodash';
import {
  bookmarkTalent,
  downloadResume,
  unbookmarkTalent,
  updateCurrentTalent,
  updateProfilePopUp,
  updateTalentInfoTabValue
} from '../../../../redux/actions/talent_action';
import { ErrorToast, InfoToast } from '../../../../utils/ToastUtils';
import ChatPanel from './ChatPanel';
import { sortWorkingExperiences } from '../../../../helpers/data_management';
import { SimpleTipsTooltip } from '../../../../components/Tooltips';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { CountryFlag, PricingButton } from '../../TalentCard';
import {
  ArrowsPointingOutIcon,
  LockClosedIcon,
  XMarkIcon
} from '@heroicons/react/24/solid';
import ResumePanel from './ResumePanel';
import BlurredResumeBackground from '../../../../assets/images/talent-search/blurred_resume.png';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import Colors from '../../../../styles/colors.module.scss';
import store from '../../../../redux/stores/store';
import { accData } from '../../../../utils/LocalStorageUtils';

let accountInfo;
try {
  accountInfo = localStorage.getItem('accountInfo');
  accountInfo = accountInfo ? JSON.parse(accountInfo) : accountInfo;
} catch (error) {
  //
}

let overrideTSChatIdleTimestamp = true;

const CandidateProfilePopup = () => {
  const profilePopUp = useSelector(state => state.talents.profilePopUp);

  return (
    profilePopUp && (
      <ProfilePopup resumeChild={<Resume />} bioChild={<Content />} />
    )
  );
};

export default CandidateProfilePopup;

export const Content = () => {
  const talent = useSelector(state => state.talents.talent);

  const contentContainerRef = useRef();
  const contentHeaderRef = useRef();

  return (
    <div ref={contentContainerRef}>
      {Object.keys(talent).length > 0 && (
        <>
          <Grid
            display={'flex'}
            flexDirection={'column'}
            position={'sticky'}
            top={'-1px'}
            backgroundColor={'#fff'}
            zIndex={10}>
            <ContentHeader contentHeaderRef={contentHeaderRef} />
            <ContentTabs />
          </Grid>
          <ContentTabPanels
            contentContainerRef={contentContainerRef}
            contentHeaderRef={contentHeaderRef}
          />
        </>
      )}
    </div>
  );
};

export const ContentHeader = ({ contentHeaderRef }) => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const talent = useSelector(state => state.talents.talent);
  const profilePopUp = useSelector(state => state.talents.profilePopUp);

  // bookmarking status
  const bookmarkingTalentIds = useSelector(
    state => state.talents.bookmarkingTalentIds
  );

  const bookmarking = bookmarkingTalentIds[talent.id];

  // unbookmarking status
  const unbookmarkingTalentIds = useSelector(
    state => state.talents.unbookmarkingTalentIds
  );

  const unbookmarking = unbookmarkingTalentIds[talent.id];

  const fetchingResume = useSelector(state => state.talents.fetchingResume);

  const trackBookmark = (id, saved) => {
    window.dataLayer.push({
      event: 'CE_click-save-resume-profile-popup',
      save: saved,
      'user-id': id
    });
  };

  const onBookmarkTalent = debounce(
    (talent, loading) => {
      if (loading) return;

      if (talent.bookmarked) {
        const params = {
          userId: talent.id
        };

        dispatch(unbookmarkTalent(params)).then(res => {
          if (res.type == 'UNBOOKMARK_TALENT_SUCCEED') {
            trackBookmark(talent?.id, false);
            InfoToast('Successfully unbookmarked!');
          } else {
            ErrorToast('Failed to unbookmark');
          }
        });
      } else {
        const params = {
          userIds: [`${talent.id}`]
        };
        dispatch(bookmarkTalent(params)).then(res => {
          if (res.type == 'BOOKMARK_TALENT_SUCCEED') {
            trackBookmark(talent?.id, true);
            InfoToast('Successfully bookmarked!');
          } else {
            ErrorToast('Failed to bookmark');
          }
        });
      }
    },
    1000,
    { leading: true, trailing: false }
  );

  const debouncedSaveTalent = useCallback(
    (talent, loading) => onBookmarkTalent(talent, loading),
    []
  );

  const onDownloadResume = talent => {
    window.dataLayer.push({
      first_time: 'yes',
      candidate_id: talent.id,
      company_name: accountInfo?.companyName,
      company_industry: accountInfo?.industryName,
      event: 'CE_download_resume_talent_search'
    });

    try {
      if (talent?.resume) {
        const params = {
          userId: talent.id
        };

        dispatch(downloadResume(params)).then(res => {
          if (res.type == 'DOWNLOAD_RESUME_SUCCEED') {
            let link = `data:application/pdf;base64,${res.resume.resume}`;
            const element = document.createElement('a');
            const fileName = res.resume.filename;

            element.href = link;
            element.download = fileName;
            element.click();

            InfoToast('Download successfully');
          } else {
            ErrorToast('Failed to download');
          }
        });
      } else {
        ErrorToast('Failed to download');
      }
    } catch {
      // console.log('error');
    }
  };

  const getWorkingExperiences = cTalent => {
    if (cTalent?.workingExperiences?.edges) {
      const experiences = cTalent.workingExperiences.edges.map(
        item => item.node
      );

      return sortWorkingExperiences(experiences);
    }

    return [];
  };

  const memoizedWorkingExperiences = useMemo(() => {
    return talent => getWorkingExperiences(talent);
  }, [talent?.workingExperiences?.edges]);

  const getCardTitle = talent => {
    let cardTitle = '';

    const experiences = memoizedWorkingExperiences(talent);

    const experience = experiences[0];

    if (talent.unlocked) {
      cardTitle = talent.name;
    } else {
      if (talent?.freshGrad) {
        cardTitle = `${talent.fieldOfStudy} Graduate`;
      } else if (experience?.jobTitle) {
        cardTitle = `${experience.jobTitle}`;
      } else {
        cardTitle = `${talent.fieldOfStudy} Graduate`;
      }
    }

    return cardTitle;
  };

  const getCardSubtitle = talent => {
    let cardSubtitle = '';

    const experiences = memoizedWorkingExperiences(talent);

    if (talent.unlocked) {
      if (talent.freshGrad) {
        cardSubtitle = `${talent.fieldOfStudy} Graduate`;
      } else if (experiences.length > 0) {
        const experience = experiences[0];

        if (experience.jobTitle) {
          cardSubtitle += `${experience.jobTitle}`;

          if (experience.companyName) {
            cardSubtitle += `, ${experience.companyName}`;
          }
        }
      } else {
        cardSubtitle = '';
      }
    } else {
      if (talent.freshGrad) {
        cardSubtitle = '';
        return;
      }

      cardSubtitle = experiences[0]?.companyName;
    }

    return cardSubtitle;
  };

  const onClickArrowPointingOut = () => {
    dispatch(updateProfilePopUp(true));
  };

  const onCloseTalentInfo = () => {
    if (store.getState().chats.isTalentSearchChat) {
      dispatch(updateProfilePopUp(false));
      return;
    }

    if (localStorage.getItem('TSChatIdleTimestamp')) {
      localStorage.setItem('TSChatIdleTimestamp', moment().valueOf());
    }

    history('/talent-search', { replace: true });

    dispatch(updateProfilePopUp(false));

    if (!profilePopUp) {
      dispatch(updateCurrentTalent({}));
    }
  };

  const cardTitle = getCardTitle(talent);
  const cardSubtitle = getCardSubtitle(talent);

  return (
    <HeaderContainer ref={contentHeaderRef}>
      <Grid width={'60%'}>
        <CardTitle>{cardTitle}</CardTitle>
        <HeightSpacer height={'4px'} />
        {cardSubtitle && <CardSubtitle>{cardSubtitle}</CardSubtitle>}

        <NationalityTitle>
          <CountryFlag nationality={talent?.nationality} />
          <span>{talent?.nationality}</span>
        </NationalityTitle>

        {/* last unlock / profile unlocked on */}
        {talent.unlockedAt && (
          <>
            {' '}
            <HeightSpacer height={'8px'} />
            <UnlockedAt talent={talent} />
          </>
        )}
      </Grid>

      <IconsContainer>
        <SimpleTipsTooltip
          top={'45px'}
          left={'-50px'}
          width={'120px'}
          padding={'4px 8px'}
          borderRadius={'8px'}
          border={'1.5px solid #222'}
          shadow={'none'}
          icon={
            <IconButtonStyled
              onClick={() =>
                debouncedSaveTalent(talent, bookmarking || unbookmarking)
              }>
              {bookmarking || unbookmarking ? (
                <Spinner size="XS" noPadding={true} />
              ) : talent.bookmarked ? (
                <BookmarkIcon style={{ color: '#512acc', fontSize: '20px' }} />
              ) : (
                <BookmarkBorderOutlinedIcon
                  style={{ color: '#512acc', fontSize: '22px' }}
                />
              )}
            </IconButtonStyled>
          }
          description={
            <Grid justifyContent={'center'} display={'flex'}>
              <SimpleDescription>
                {talent.bookmarked ? 'Unbookmark Profile' : 'Bookmark Profile'}
              </SimpleDescription>
            </Grid>
          }
        />

        {talent?.unlocked ? (
          <SimpleTipsTooltip
            top={'45px'}
            left={'-20px'}
            width={'130px'}
            padding={'4px 8px'}
            borderRadius={'8px'}
            border={'1.5px solid #222'}
            shadow={'none'}
            icon={
              <IconButtonStyled
                onClick={
                  !fetchingResume ? () => onDownloadResume(talent) : () => {}
                }>
                {fetchingResume ? (
                  <Spinner size="XS" noPadding={true} />
                ) : (
                  <FileDownloadOutlinedIcon
                    style={{ color: '#512acc', fontSize: '20px' }}
                  />
                )}
              </IconButtonStyled>
            }
            description={
              <Grid justifyContent={'center'} display={'flex'}>
                <SimpleDescription>{'Download Resume'}</SimpleDescription>
              </Grid>
            }
          />
        ) : (
          <PricingButton talent={talent} column={'talent-info'} />
        )}

        {talent?.unlocked && !profilePopUp && (
          <SimpleTipsTooltip
            top={'45px'}
            left={'-50px'}
            width={'130px'}
            padding={'4px 8px'}
            borderRadius={'8px'}
            border={'1.5px solid #222'}
            shadow={'none'}
            icon={
              <IconButtonStyled onClick={onClickArrowPointingOut}>
                <ArrowsPointingOutIcon
                  style={{ color: '#512acc', height: '20px', width: '20px' }}
                />
              </IconButtonStyled>
            }
            description={
              <Grid justifyContent={'center'} display={'flex'}>
                <SimpleDescription>{'View in Full Screen'}</SimpleDescription>
              </Grid>
            }
          />
        )}

        {/* close button */}
        <IconButtonStyled onClick={onCloseTalentInfo}>
          <XMarkIcon style={{ height: '20px', width: '20px' }} />
        </IconButtonStyled>
      </IconsContainer>
    </HeaderContainer>
  );
};

export const ContentTabs = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const profilePopUp = useSelector(state => state.talents.profilePopUp);
  const talent = useSelector(state => state.talents.talent);
  const tabValue = useSelector(state => state.talents.tabValue);

  const handleChange = (event, newValue) => {
    dispatch(updateTalentInfoTabValue(newValue));

    if (newValue != 'chat') {
      if (!overrideTSChatIdleTimestamp) return;

      localStorage.setItem('TSChatIdleTimestamp', moment().valueOf()); // set unix timestamp in milliseconds

      overrideTSChatIdleTimestamp = false;

      return;
    }

    overrideTSChatIdleTimestamp = true;
  };

  useEffect(() => {
    const search = location.search;
    const tab = new URLSearchParams(search).get('TStab');
    const id = new URLSearchParams(search).get('user_id');

    if (tab == 'chat' && id == talent.id) {
      dispatch(updateTalentInfoTabValue('chat'));
    }
  }, []);

  return (
    <>
      <TabsStyled
        value={tabValue}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: '#512acc'
          }
        }}
        aria-label="tabs">
        <TabStyled value="profile" label="BIODATA" />
        {!profilePopUp && <TabStyled value="resume" label="RESUME" />}
        <TabStyled value="chat" label="CHAT" />
        <TabStyled value="notes" label="NOTES" />
      </TabsStyled>
      <Divider />
    </>
  );
};

export const TabPanel = React.memo(props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${value}-${index}`}
      aria-labelledby={`${value}-${index}`}
      {...other}>
      {value === index && (
        <div sx={{ p: 3 }}>
          <span>{children}</span>
        </div>
      )}
    </div>
  );
});

export const ContentTabPanels = ({ contentContainerRef, contentHeaderRef }) => {
  const talent = useSelector(state => state.talents.talent);
  const tabValue = useSelector(state => state.talents.tabValue);

  return (
    <>
      <TabPanel value={tabValue == 'profile' && 0} index={0}>
        <ProfilePanel contentContainerRef={contentContainerRef} />
      </TabPanel>

      <TabPanel value={tabValue == 'resume' && 1} index={1}>
        {talent.unlocked ? (
          <ResumePanel contentContainerRef={contentContainerRef} />
        ) : (
          <LockedTab type={'locked-resume'} />
        )}
      </TabPanel>

      <TabPanel value={tabValue == 'chat' && 2} index={2}>
        {talent.unlocked ? (
          <ChatPanel
            contentContainerRef={contentContainerRef}
            contentHeaderRef={contentHeaderRef}
          />
        ) : (
          <LockedTab type={'locked-chat'} />
        )}
      </TabPanel>
      <TabPanel value={tabValue == 'notes' && 3} index={3}>
        {talent.unlocked ? (
          <NotesPanel
            talent={talent}
            contentContainerRef={contentContainerRef}
            contentHeaderRef={contentHeaderRef}
          />
        ) : (
          <LockedTab type={'locked-notes'} />
        )}
      </TabPanel>
    </>
  );
};

export const UnlockedAt = ({ talent }) => {
  return (
    <Grid container gap="4px" alignItems={'center'}>
      <InformationCircleIcon
        style={{ width: '14px', height: '14px', color: Colors.terMediumGrey }}
      />
      <UnlockedAtStyled>
        {talent.unlocked ? `Profile unlocked on:` : `Your last unlock:`}
      </UnlockedAtStyled>
      <UnlockedAtStyled>
        {moment(talent.unlockedAt).format('DD/MM/YYYY')}
      </UnlockedAtStyled>
      <HeightSpacer height={'8px'} />
    </Grid>
  );
};

export const LockedTab = ({ type }) => {
  return (
    <Grid
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      position={'relative'}>
      {type == 'locked-resume' && (
        <div
          style={{
            backgroundImage: `url(${BlurredResumeBackground})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            height: 'calc(100vh - 200px)',
            width: '100%'
          }}
        />
      )}

      <Grid position={'absolute'} top={'calc((100vh - 200px)/3.2)'}>
        <Grid
          display={'flex'}
          flexDirection={'column'}
          gap={'10px'}
          justifyContent={'center'}
          alignItems={'center'}>
          {' '}
          <LockClosedIcon style={{ height: '70px', width: '55px' }} />{' '}
          <span style={{ fontSize: '20px', fontWeight: 700 }}>
            This section is currently hidden
          </span>
          <span
            style={{
              fontSize: '14px',
              fontWeight: 400,
              letterSpacing: '0.15px'
            }}>
            Unlock now to{' '}
            {type == 'locked-resume'
              ? 'view the full resume'
              : 'engage with the Talent'}{' '}
            and get hiring!
          </span>{' '}
        </Grid>
      </Grid>
    </Grid>
  );
};
