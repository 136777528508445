import { styled as styling } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { XMarkIcon as CloseIcon } from '@heroicons/react/24/outline';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export const DialogStyled = styling(Dialog)(props => ({
  '& .MuiDialog-paper': {
    borderRadius: 12,
    width: '600px'
  }
}));

export const DialogTitleStyled = styling(DialogTitle)(props => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
}));

export const TitleStyled = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.87)`,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px'
}));

export const DialogContentStyled = styling(DialogContent)(props => ({
  padding: '0px !important',
  display: 'flex',
  flexDirection: 'column',
  // gap: '24px',
  backgroundColor: '#EFEFEF',
  overflow: 'hidden'
}));

export const GreyContentContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  // gap: '4px',
  backgroundColor: '#EFEFEF',
  height: '40px',
  width: '100%',
  alignItems: 'flex-end',
  padding: '0px 24px 0px 24px'
}));

export const GreyInnerContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  // backgroundColor: "red",
  width: '100%',
  height: '100%',
  alignItems: 'center',
  position: 'relative'
}));

export const ContentContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '576px',
  backgroundColor: 'white',
  padding: '0px 24px 24px 24px',
  height: '336px',
  width: '100%',
  maxHeight: '336px',
  overflowY: 'auto',
  right: '24px',

  '&::-webkit-scrollbar': {
    width: '4px',
    height: '100px',
    paddingBottom: '24px !important'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#D8D8D8' /* Color of the track */,
    paddingBottom: '24px !important'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#512ACC' /* Color of the thumb */,
    borderRadius: '5px' /* Rounded corners */,
    paddingBottom: '24px !important'
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555' /* Color of the thumb on hover */
  }
}));

export const DialogActionsStyled = styling(DialogActions)(props => ({}));

export const IconButtonStyled = styling(IconButton)(props => ({
  height: 'fit-content',
  padding: '0px'
}));

export const CloseIconStyled = styling(CloseIcon)(props => ({
  height: 24,
  width: 24
}));

export const SocialMediaTitle = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.87)`,
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400
}));

export const TitleDescStyled = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.60)`,
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const TypographySpan = styling('span')(props => ({
  color: '#512ACC'
}));

export const ContainerStyled = styling(Grid)(props => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px'
}));

export const TitleContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  justifyContent: 'space-between',
  fontFamily: 'inter'
}));

export const SelectionWrapper = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  position: 'absolute',
  bottom: '0px',
  left: '0px'
}));

export const SocialMediaWrapper = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '6px',
  width: '100%'
}));

export const GuidelineButton = styling(Button)(props => ({
  backgroundColor: props?.selected ? '#40249B' : '#2E2E2E',
  fontSize: '12px',
  padding: '0px 16px',
  borderRadius: '6px',
  height: '30px',
  textTransform: 'none',
  fontWeight: 700,
  color: 'white',
  lineHeight: '20px',

  ':hover': {
    backgroundColor: props?.selected ? '#40249B' : '#2E2E2E'
  }
}));

export const SocialMediaType = styling(Grid)(props => ({
  backgroundColor: props?.selected ? 'white' : 'transparent',
  overflow: 'hidden',
  borderRadius: '8px 8px 0px 0px',
  height: '32px',
  width: props?.selected ? 'auto' : '52px !important',
  maxWidth: props?.selected ? '' : '52px !important',
  // transition: 'max-width 0.3s, width 0.3s',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 16px !important',
  position: 'relative',
  cursor: 'pointer',

  ':hover': {
    backgroundColor: props?.selected ? 'white' : '#EAEAEA'
  }
}));

export const DefaultText = styling(Typography)(props => ({
  fontSize: '12px',
  fontWeight: '400',
  fontFamily: 'inter',
}));

