import { styled as styling } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Email as EmailIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  GetApp as GetAppIcon
} from '@mui/icons-material';

export const MainContainer = styling('div')({
  display: 'flex',
  alignItems: 'center',
});

export const IconContainer = styling('div')((props) => ({
  padding: '0 10px',
  height: '40px',
  width: '40px',
  display: 'flex',
  alignItems: 'center',
  cursor: props.custom_disabled ? 'default' : 'pointer',
}));

export const FontAwesomeIconStyled = styling(FontAwesomeIcon)((props) => ({
  ...(props.icon_name == 'faExpand' && {
    fontSize: '18px',
    color: '#222222',
  }),
}));

export const InterviewLengthContainer = styling('div')((props) => ({
  position: 'absolute',
  top: '10px',
  right: '5px',
}));

export const Text = styling('p')((props) => ({
  ...(props.type == 'interview_length' && {
    fontSize: '11px',
    margin: '0',
    transform: 'translate(0, -16px)',
    fontWeight: 'bold',
    color: '#512acc',
  }),

  ...(props.type == 'tooltip_text' && {
    fontSize: '12px',
    fontFamily: 'Inter',
    margin: '0',
  }),
}));

export const GetAppIconStyled = styling(GetAppIcon)((props) => ({
  fontSize: '20px',
  color: '#222222',
}));

export const ChevronLeftIconStyled = styling(ChevronLeftIcon)((props) => ({
  fontSize: '28px',
  color: '#222222',
  backgroundColor: '#ffffff',
  borderRadius: '50%',
  boxShadow: '0px 4px 17px rgba(0, 0, 0, 0.12',

  ...(props.disabled && {
    background: 'transparent',
    boxShadow: 'none',
    color: '#e1e1e1',
  }),
}));

export const ChevronRightIconStyled = styling(ChevronRightIcon)((props) => ({
  fontSize: '28px',
  color: '#222222',
  backgroundColor: '#ffffff',
  borderRadius: '50%',
  boxShadow: '0px 4px 17px rgba(0, 0, 0, 0.12',

  ...(props.disabled && {
    background: 'transparent',
    boxShadow: 'none',
    color: '#e1e1e1',
  }),
}));

export const EmailIconStyled = styling(EmailIcon)((props) => ({
  fontSize: '20px',
  color: '#222222',
}));