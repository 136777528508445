import React, { useEffect } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import BasicInformation from './BasicInformation.js';
import ChangePassword from './ChangePassword.js';
import { version } from '../../../package.json';
import { fetchAccountInfo } from '../../redux/actions/company_actions';
import { useDispatch, useSelector } from 'react-redux';
import { Container, PageTitle, Paragraph, SubContainer, Tab, TabContainer, TabTitle } from './styles';
import useSearchParams from '../../utils/useSearchParams';
import { useNavigate } from 'react-router-dom';

const MyAccount = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const accInfo = useSelector(state => state.companies.accountInfo);
  const fetchingAccInfo = useSelector(
    state => state.companies.fetchingAccountInfo
  );

  const params = useSearchParams();
  const tab = params.get('tab') || 'basic';

  const handleTabClick = (tab) => {
    history(`/my-account?tab=${tab}`);
  };

  useEffect(() => {
    dispatch(fetchAccountInfo());
  }, []);

  return (
    <DefaultLayout>
      <Container>
        <SubContainer>
          <PageTitle>Edit My Account</PageTitle>
          <TabContainer>
            <Tab onClick={() => handleTabClick('basic')} isSelected={tab === 'basic'}>
              <TabTitle>Basic Information</TabTitle>
            </Tab>
            <Tab onClick={() => handleTabClick('password')} isSelected={tab === 'password'}>
              <TabTitle>Change Password</TabTitle>
            </Tab>
          </TabContainer>
          {tab === 'basic' ? (
            <BasicInformation isDataLoading={fetchingAccInfo} accInfo={accInfo} />
          ) : (
            <ChangePassword />
          )}
          <Paragraph>Version {version}</Paragraph>
        </SubContainer>
      </Container>
    </DefaultLayout>
  );
};

export default MyAccount;
