import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import styles from './index.module.scss';

const SkeletonLoader = ({ customClassName }) => (
  <div className={classNames(styles.skeletonContainer, customClassName)}>
    <div>
      <Skeleton variant="text" className={styles.skeletonTitle} />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
    </div>
    <div className={styles.skeletonFooter}>
      <div className={styles.skeletonSubFooter} style={{ marginRight: 25 }}>
        <Skeleton variant="text" className={styles.skeletonDateRect} />
        <Skeleton variant="text" className={styles.skeletonDateRect} />
      </div>
      <div className={styles.skeletonSubFooter}>
        <Skeleton variant="text" className={styles.skeletonDateRect} />
        <Skeleton variant="text" className={styles.skeletonDateRect} />
      </div>
    </div>
  </div>
);

export default SkeletonLoader;
