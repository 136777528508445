import React, { useState } from 'react';
import IconNext from '../../assets/icons/job-form/icon-next.svg';
import IconDisablePrev from '../../assets/icons/job-form/icon-disable-previous.svg';
import IconDisableNext from '../../assets/icons/job-form/icon-disable-next.svg';
import IconBulb from '../../assets/icons/job-form/icon-bulb.svg';
import { Close as CloseIcon } from '@mui/icons-material';
import { HeightSpacer } from '../Spacer/styles';
import {
  TipsTextContainer,
  TooltipWrapper,
  TipsText,
  CustomPopover,
  TitleContainer,
  Title,
  DescriptionContainer,
  PaginationContainer,
  PaginationText,
  IconPrevStyled,
  IconNextStyled,
  InfoIconStyled
} from './styles';
import Colors from '../../styles/colors.module.scss';
import { Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updatePostJobTooltipStatus } from '../../redux/actions/job_action';

export const TipsTooltip = ({
  title,
  descriptions,
  position,
  top,
  left,
  totalPages,
  minHeight,
  onClick,
  cKey
}) => {
  const dispatch = useDispatch();

  const [index, setIndex] = useState(0);
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const [isSlideStart, setIsSlideStart] = useState(false);

  const tooltipStatus = useSelector(state => state.jobs.tooltipStatus);
  const prevTooltip = useSelector(state => state.jobs.prevTooltip);

  const onClickNext = () => {
    let cIndex = index + 1;
    if (cIndex <= descriptions.length - 1) {
      setIsSlideStart(true);

      setTimeout(() => {
        setIndex(cIndex);
      }, 800);
    }
  };

  const onClickPrev = () => {
    let cIndex = index - 1;

    if (index > 0) {
      setIsSlideStart(false);
      setIndex(cIndex);
    }
  };

  const onMouseEnter = () => {
    setIsMouseEnter(true);
  };

  const onCloseTooltip = key => {
    let status = { ...tooltipStatus };

    status[key] = false;
    dispatch(updatePostJobTooltipStatus(status));

    setIsSlideStart(false);

    setTimeout(() => {
      setIndex(0);

      setIsMouseEnter(false);
    }, 500);
  };

  return (
    <TooltipWrapper>
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              background: `${Colors.priWhite}`,
              border: `2px solid ${Colors.priBlack}`,
              borderRadius: '8px',
              padding: '4px 8px',
              fontWeight: '500',
              fontSize: '10px',
              color: `${Colors.priBlack}`
            }
          }
        }}
        title={
          isMouseEnter ? (
            <span>Please click this button for a simple guide</span>
          ) : (
            ''
          )
        }
        placement="top">
        <TipsTextContainer
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          $isMouseEnter={isMouseEnter}
          $title={title}>
          <img src={IconBulb} />
          <TipsText>TIPS</TipsText>
        </TipsTextContainer>
      </Tooltip>
      {((tooltipStatus.jobTitle && title == 'Job Title') ||
        (tooltipStatus.shortSummary && title == 'Short Summary') ||
        (tooltipStatus.jobDescription && title == 'Job Description') ||
        (tooltipStatus.jobRequirement && title == 'Job Requirements') ||
        (tooltipStatus.keywords && title == 'Key Highlights') ||
        (tooltipStatus.skill && title == 'Skills')) && (
        <CustomPopover
          position={position}
          top={top}
          left={left}
          $minHeight={minHeight}>
          <TitleContainer>
            <Title>
              {index + 1 == descriptions.length && descriptions.length > 1
                ? title + ' (Examples):'
                : title}
            </Title>
            <CloseIcon
              onClick={() => onCloseTooltip(cKey)}
              style={{ color: '#222', fontSize: '20px', cursor: 'pointer' }}
            />
          </TitleContainer>
          <HeightSpacer height={'10px'} />
          <DescriptionContainer $slide={isSlideStart} $index={index}>
            {descriptions[index]}
          </DescriptionContainer>

          {descriptions.length > 1 && (
            <div
              style={{
                position: 'absolute',
                bottom: '8px',
                left: 'calc(50% - 60px)'
              }}>
              <PaginationContainer>
                <IconPrevStyled
                  src={index <= 0 ? IconDisablePrev : IconNext}
                  onClick={onClickPrev}
                  $type={index <= 0 ? 'icon-disable-prev' : 'icon-prev'}
                />
                <PaginationText>
                  {index + 1} / {totalPages}
                </PaginationText>

                <IconNextStyled
                  src={
                    index >= descriptions.length - 1
                      ? IconDisableNext
                      : IconNext
                  }
                  onClick={onClickNext}
                  $type={
                    index >= descriptions.length - 1
                      ? 'icon-disable-next'
                      : 'icon-next'
                  }
                />
              </PaginationContainer>
            </div>
          )}
        </CustomPopover>
      )}
    </TooltipWrapper>
  );
};

export const SimpleTipsTooltip = ({
  description,
  position,
  top,
  left,
  width,
  padding,
  icon,
  zIndex,
  borderRadius,
  fontSize,
  wrapperZIndex,
  border,
  shadow
}) => {
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const [isMouseLeave, setIsMouseLeave] = useState(false);

  const onMouseEnter = () => {
    if (isMouseLeave) {
      setIsMouseLeave(false);
    }
    setIsMouseEnter(true);
  };

  const onMouseLeave = () => {
    if (isMouseEnter) {
      setIsMouseEnter(false);
    }

    setIsMouseLeave(true);
  };
  return (
    <TooltipWrapper
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      $wrapperZIndex={wrapperZIndex}>
      {icon ? icon : <InfoIconStyled />}
      {isMouseEnter && (
        <CustomPopover
          position={position}
          top={top}
          left={left}
          $width={width}
          $padding={padding}
          $zIndex={zIndex}
          $borderRadius={borderRadius}
          $fontSize={fontSize}
          $border={border}
          $shadow={shadow}>
          {description}
        </CustomPopover>
      )}
    </TooltipWrapper>
  );
};
