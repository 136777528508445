import {
  Autocomplete,
  Collapse,
  InputAdornment,
  ListItemButton,
  ListItemText,
  Paper,
  TextField
} from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateApplicationParams } from '../../../redux/actions/application_action';
import store from '../../../redux/stores/store';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import Colors from '../../../styles/colors.module.scss';

const JobListDropdown = ({ onAutocomValueChange }) => {
  const dispatch = useDispatch();
  const jobs = useSelector(state => state.jobs.allJobs);

  const inactiveIndexRef = useRef(null);
  const firstActiveJobIndexRef = useRef(null);
  const firstFilteredInactiveIndexRef = useRef(null);
  const [activeExpand, setActiveExpand] = useState(false);
  const [inactiveExpand, setInactiveExpand] = useState(false);
  const [autocomValue, setAutocomValue] = useState('');
  const [isTextfieldClick, setIsTextfieldClick] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  const sortedOptions = jobs.sort((a, b) => {
    return b.active - a.active;
  });
  const options = sortedOptions.map((option, index) => {
    return {
      label: option.title,
      ...option
    };
  });

  const firstInactiveIndex = options.findIndex(option => {
    return option.active == false;
  });

  const onChangeSelect = (e, values) => {
    let cApplicationParams = {
      ...store.getState().applications.applicationParams
    };

    if (isTextfieldClick) {
      setIsTextfieldClick(false);
    }

    if (values?.id) {
      onAutocomValueChange(values.active ? 'active_jobs' : 'inactive_jobs');
      cApplicationParams.jobId = values.id;
      dispatch(updateApplicationParams(cApplicationParams));
      setSelectedOption(values);
    }
  };

  const onAutoComInputChange = (e, value) => {
    firstFilteredInactiveIndexRef.current = null;
    setSelectedOption(null);

    if (value?.length == 0) {
      setActiveExpand(false);
      setInactiveExpand(false);
    }

    setAutocomValue(value);

    if (isTextfieldClick) {
      setIsTextfieldClick(false);
    }
  };

  const onClickTextfield = () => {
    if (!isTextfieldClick) {
      setIsTextfieldClick(true);
    }
  };

  const onClickStatus = type => {
    if (type == 'active-jobs') {
      setActiveExpand(prev => !prev);
    }

    if (type == 'inactive-jobs') {
      setInactiveExpand(prev => !prev);
    }
  };

  const onClickShowAll = () => {
    let cApplicationParams = {
      ...store.getState().applications.applicationParams
    };

    if (store.getState().applications.applicationParams.jobId?.length > 0) {
      cApplicationParams.jobId = '';
      dispatch(updateApplicationParams(cApplicationParams));
    }

    if (autocomValue?.length > 0 && cApplicationParams.jobId?.length <= 0) {
      setAutocomValue('');
      setSelectedOption(null);
      onAutocomValueChange('show_all_applicants');
    }
  };

  useEffect(() => {
    if (autocomValue?.length > 0) {
      if (firstActiveJobIndexRef.current != null) {
        setActiveExpand(true);
      }

      if (inactiveIndexRef.current != null) {
        setInactiveExpand(true);
      }
    }
  }, [autocomValue]);

  return (
    <Autocomplete
      sx={{
        width: '300px'
      }}
      options={options}
      freeSolo={true}
      inputValue={autocomValue}
      onChange={onChangeSelect}
      onInputChange={onAutoComInputChange}
      renderOption={(props, item) => {
        if (!item.active && autocomValue?.length <= 0) {
          if (!inactiveIndexRef.current) {
            inactiveIndexRef.current = props['data-option-index'];
          } else if (firstInactiveIndex > -1) {
            inactiveIndexRef.current = firstInactiveIndex;
          }
        } else if (autocomValue?.length > 0 && !item.active) {
          if (!firstFilteredInactiveIndexRef.current) {
            firstFilteredInactiveIndexRef.current = props['data-option-index'];
          }

          inactiveIndexRef.current = firstFilteredInactiveIndexRef.current;
        }

        if (item.active) {
          firstActiveJobIndexRef.current = props['data-option-index'];
        }

        return (
          <>
            {props['data-option-index'] == 0 &&
              (autocomValue?.length <= 0 || isTextfieldClick) && (
                <ListItemButton
                  onClick={onClickShowAll}
                  selected={autocomValue?.length <= 0}>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: '14px',
                      letterSpacing: '0.15px'
                    }}
                    primary={'Show All Applicants'}
                  />
                </ListItemButton>
              )}

            {item.active && (
              <SharedListItemButton
                onClick={() => onClickStatus('active-jobs')}
                props={props}
                itemText={'ACTIVE JOBS'}
                expand={activeExpand}
                showListTitle={props['data-option-index'] == 0}
                item={item}
                selected={selectedOption?.id === item.id}
              />
            )}

            {!item.active && (
              <SharedListItemButton
                onClick={() => onClickStatus('inactive-jobs')}
                props={props}
                itemText={'INACTIVE JOBS'}
                expand={inactiveExpand}
                showListTitle={
                  props['data-option-index'] == inactiveIndexRef.current
                }
                item={item}
              />
            )}
          </>
        );
      }}
      ListboxProps={{
        style: {
          maxHeight: 600
        }
      }}
      PaperComponent={CustomPaper}
      renderInput={params => {
        return (
          <TextField
            {...params}
            label={
              params.inputProps.value.length > 0 ? (
                ''
              ) : (
                <span
                  style={{
                    fontSize: '16px',
                    fontFamily: 'Inter',
                    letterSpacing: '0.15px'
                  }}>
                  Show All Applicants
                </span>
              )
            }
            InputLabelProps={{ shrink: false }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{
                    position: 'absolute',
                    right: '20px',

                    ':hover':{
                      cursor: 'pointer'
                    }
                  }}>
                  <ChevronDownIcon width={'20px'} height={'20px'} />
                </InputAdornment>
              )
            }}
            onClick={onClickTextfield}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '70px',
                padding: '8px 20px !important',
                background: Colors.priWhite
              },
              '& .MuiAutocomplete-inputRoot': {
                paddingLeft: '15px !important',
                borderRadius: '50px'
              },
              '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                minWidth: '230px',
                zIndex: '3',
                padding: '7.5px 40px 7.5px 6px'
              },
              '& .MuiInputLabel-outlined': {
                paddingLeft: '5px'
              }
            }}
          />
        );
      }}
    />
  );
};

export default JobListDropdown;

export const CustomPaper = props => {
  return (
    <Paper
      elevation={0}
      {...props}
      sx={{
        boxShadow: '4px 4px 10px 0 rgba(0, 0, 0, 0.16)',
        marginTop: '10px',
        borderRadius: '4px'
      }}
    />
  );
};

export const SharedListItemButton = ({
  onClick,
  itemText,
  expand,
  item,
  props,
  showListTitle,
  selected
}) => {
  return (
    <>
      {showListTitle && (
        <ListItemButton
          sx={{ display: 'flex', justifyContent: 'space-between' }}
          onClick={onClick}>
          <ListItemText
            primaryTypographyProps={{
              fontSize: '12px',
              letterSpacing: '1.5px',
              fontFamily: 'Inter'
            }}
            primary={itemText}
          />
          {expand ? (
            <ChevronUpIcon width={'20px'} height={'20px'} />
          ) : (
            <ChevronDownIcon width={'20px'} height={'20px'} />
          )}
        </ListItemButton>
      )}

      <Collapse in={expand} timeout={'auto'} unmountOnExit>
        <ListItemButton
          selected={selected}
          style={{ padding: '0px', margin: '0px' }}>
          <ListItemText
            {...props}
            key={item.id}
            primary={item.label}
            primaryTypographyProps={{
              fontSize: '14px',
              letterSpacing: '0.15px',
              fontFamily: 'Inter'
            }}
            sx={{
              backgroundColor: 'unset !important'
            }}
          />
        </ListItemButton>
      </Collapse>
    </>
  );
};
