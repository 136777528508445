import React from 'react';
import Announcement from '../../../../components/Announcement';

const AnnouncementBanner = ({
  hasContent,
  $backgroundColor,
  $height,
  $margin,
  $borderRadius,
  $padding,
  $display,
  $justifyContent,
  $alignItems,
  content
}) => {
  return (
    <Announcement
      hasContent={hasContent}
      $backgroundColor={$backgroundColor}
      $height={$height}
      $margin={$margin}
      $borderRadius={$borderRadius}
      $padding={$padding}
      $display={$display}
      $justifyContent={$justifyContent}
      $alignItems={$alignItems}
      content={content}
    />
  );
};

export default AnnouncementBanner;
