import * as types from '../types/job_type';
import api from '../../utils/api';
import { fetchAPI } from '../../axios/fetches';
import { convertArrayToProperString } from '../../helpers/data_management';
import { isNumber } from 'lodash';
const { REACT_APP_API_VERSION } = process.env;

const jobAttributes = `
id
slug
title
customInput
imagePosition
imageSize
boostedExpired
enableRefreshJob
enableFeatureJob
creationOrigin
location
malaysiansOnly
localOnly
viewCount
stateRegion {
  id
  title
}
active
expiredAt
createdAt
description
renewalCount
requirements
minSalaryRange
maxSalaryRange
minYearExperience
maxYearExperience
receiveNotification
externalJobUrl
customEmail
jobBannerUrl
jobBannerUrlSquare
hideSalary
allowEditingTitle
tracks {
  id
  title
}
experiences {
  id
  title
}
jobType {
  id
  title
}
careerLevel {
  id
  name
}
companyTeamMembers {
  id
  name
  email
}
unviewedUndecidedCount
unreadJobApplicationChatCount
unviewedCount
undecidedCount
shortlistedCount
rejectedCount
kivCount
interviewCount
offerCount
totalCount
skills
gptUsage
keywordHighlight
keywordHighlightJobThreshold
keywordHighlightExpired
keywordHighlightText
spotlightExpired
enableSpotlightJob
spotlightJobThreshold
featureJobThreshold
enableRefreshJob
refreshJobThreshold
jobRankdate
resourceType
globalHireEnabled
globalHirePreferences
crossedBorder
crossBorderCountryExpired
crossBorderData
crossBorderJobThreshold
`;

const jobDraftAttributes = `
id
title
customInput
imagePosition
imageSize
boostedExpired
location
malaysiansOnly
localOnly
stateRegion {
  id
  title
}
active
expiredAt
createdAt
description
requirements
minSalaryRange
maxSalaryRange
minYearExperience
maxYearExperience
receiveNotification
externalJobUrl
customEmail
jobBannerUrl
jobBannerUrlSquare
hideSalary
tracks {
  id
  title
}
experiences {
  id
  title
}
jobType {
  id
  title
}
careerLevel {
  id
  name
}
skills
gptUsage
keywordHighlightText
globalHireEnabled
globalHirePreferences
`;

const clearJobInfo = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.CLEAR_JOB_INFO
      })
    );
  });
};

const updateDraftJobState = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_DRAFT_JOB_STATE,
        status: status
      })
    );
  });
};

const updateJobDataForm = jobData => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_JOB_FORM_DATA_STATE,
        jobData: jobData
      })
    );
  });
};

const updateContactSalesPopupStatus = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_CONTACT_SALES_POPUP,
        status: status
      })
    );
  });
};

const updateSubjectType = subjectType => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_SUBJECT_TYPE,
        subjectType: subjectType
      })
    );
  });
};

const updateExpiryDatesPopupStatus = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_EXPIRY_DATES_POPUP,
        status: status
      })
    );
  });
};

const updateAvailableResourcesCardIndex = index => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_AVAILABLE_RESOURCES_CARD_INDEX,
        index: index
      })
    );
  });
};

const viewPackageSubscriptionDetails =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_PACKAGE_SUBSCRIPTION_DETAILS
    });

    const payload = {
      query: `{
        companyPackageSubscriptionDetails
      }    
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.companyPackageSubscriptionDetails) {
            const packages =
              response?.data?.data?.companyPackageSubscriptionDetails;

            return dispatch({
              type: types.FETCH_PACKAGE_SUBSCRIPTION_DETAILS_SUCCEED,
              packages: packages
            });
          }
        }
        return dispatch({
          type: types.FETCH_PACKAGE_SUBSCRIPTION_DETAILS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_PACKAGE_SUBSCRIPTION_DETAILS_FAILED
        });
      });
  };

const resetAvailableResources = () => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.RESET_AVAILABLE_RESOURCES
      })
    );
  });
};

const updateAnnouncementPopup = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_ANNOUNCEMENT_POPUP,
        status: status
      })
    );
  });
};

const updateAnnouncementBanner = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_ANNOUNCEMENT_BANNER,
        status: status
      })
    );
  });
};

const viewAllSortedPackages =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_ALL_SORTED_PACKAGES
    });

    // packageType: "Job Slots"
    // activation: "furthest"
    // expiry: "furthest"
    // daysRemaining: "14"

    let data;
    if (Array.isArray(params?.type)) {
      data = params?.type?.map(type => `"${type}"`);
    } else {
      data = params?.type;
    }

    const packageTypeInput = params.type
      ? `packageType: ${
          Array.isArray(params?.type) ? `[${data}]` : `"${data}"`
        },`
      : '';
    const activationInput = params.activation
      ? `activation: "${params.activation}",`
      : '';
    const expiryInput = params.expiry ? `expiry: "${params.expiry}",` : '';
    const daysInput = params.daysRemaining
      ? `daysRemaining: "${params.daysRemaining}",`
      : '';

    let queryInput =
      packageTypeInput + activationInput + expiryInput + daysInput;

    // Removing trailing ','
    if (queryInput.charAt(queryInput.length - 1) == ',') {
      queryInput = queryInput.substring(0, queryInput.length - 1);
    }

    const payload = {
      query: `{
        companyAllPackageSubscriptions(filterBy: {${queryInput}})
      }    
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.companyAllPackageSubscriptions) {
            const packages =
              response?.data?.data?.companyAllPackageSubscriptions;
            return dispatch({
              type: types.FETCH_ALL_SORTED_PACKAGES_SUCCEED,
              packages: packages
            });
          }
        }
        return dispatch({
          type: types.FETCH_ALL_SORTED_PACKAGES_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_ALL_SORTED_PACKAGES_FAILED
        });
      });
  };

const viewSortedJobSlotsAndPrepJobSlotsPackages =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_SORTED_JOB_SLOTS_AND_PREP_JOB_SLOTS_PACKAGES
    });

    const payload = {
      query: `{
        companyJobSlotsSubscriptions
      }    
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.companyJobSlotsSubscriptions) {
            const packages = response?.data?.data?.companyJobSlotsSubscriptions;
            return dispatch({
              type: types.FETCH_SORTED_JOB_SLOTS_AND_PREP_JOB_SLOTS_PACKAGES_SUCCEED,
              packages: packages
            });
          }
        }
        return dispatch({
          type: types.FETCH_SORTED_JOB_SLOTS_AND_PREP_JOB_SLOTS_PACKAGES_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_SORTED_JOB_SLOTS_AND_PREP_JOB_SLOTS_PACKAGES_FAILED
        });
      });
  };

const updateJobAccessPopup = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_JOB_ACCESS_POPUP,
        status: status
      })
    );
  });
};

const updateVirtualInterviewPopup = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_VIRTUAL_INTERVIEW_POPUP,
        status: status
      })
    );
  });
};

const updateRefresherJobPopup = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_REFRESHER_JOB_POPUP,
        status: status
      })
    );
  });
};

const refreshJob =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.REFRESH_JOB
    });

    const mutationInput = `jobId: ${JSON.stringify(params.jobId)}`;

    const payload = {
      query: `mutation {
        refreshJob(input: {${mutationInput}}) {
          success
          job {
           ${jobAttributes}
          }
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.refreshJob) {
            const { success, job } = response.data.data.refreshJob;

            if (success) {
              return dispatch({
                type: types.REFRESH_JOB_SUCCEED,
                job: job
              });
            }
          }
        }
        return dispatch({
          type: types.REFRESH_JOB_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.REFRESH_JOB_FAILED
        });
      });
  };

const updateFeaturedJobPopup = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_FEATURED_JOB_POPUP,
        status: status
      })
    );
  });
};

const featureJob =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FEATURE_JOB
    });

    const mutationInput = `jobId: ${JSON.stringify(params.jobId)}`;

    const payload = {
      query: `mutation {
        featureJob(input: {${mutationInput}}) {
          success
          job {
            ${jobAttributes}
          }
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.featureJob) {
            const { success, job } = response.data.data.featureJob;

            if (success) {
              return dispatch({
                type: types.FEATURE_JOB_SUCCEED,
                job: job
              });
            }
          }
        }
        return dispatch({
          type: types.FEATURE_JOB_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FEATURE_JOB_FAILED
        });
      });
  };

const updateDeactivateJobPopup = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_DEACTIVATE_JOB_POPUP,
        status: status
      })
    );
  });
};

const updateActivateJobPopup = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_ACTIVATE_JOB_POPUP,
        status: status
      })
    );
  });
};

const updateActivateJobOptionPopup = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_ACTIVATE_JOB_OPTION_POPUP,
        status: status
      })
    );
  });
};

const updateJobOptionPopup = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_JOB_OPTION_POPUP,
        status: status
      })
    );
  });
};

const changeJobStatus =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.CHANGE_JOB_STATUS
    });

    const mutationInput = `active: ${params.active}, jobId: ${JSON.stringify(
      params.jobId
    )}, archiveChat: ${params.archiveChat}, deactivateReason: ${JSON.stringify(
      params.deactivateReason
    )}, otherReason: ${JSON.stringify(params.otherReason)},
    resourceType: "${params.resourceType}",
    enableKeywordHighlight: ${
      params.keywordHighlightToken ? params.keywordHighlightToken : false
    },`;
    const payload = {
      query: `mutation {
        changeJobStatus(input: {${mutationInput}}) {
          job {
            id
            title
            slug
            enableRefreshJob
            enableFeatureJob
            boosted
            boostedExpired
            location
            malaysiansOnly
            localOnly
            stateRegion {
              id
              title
            }
            trackTitle
            jobTypeTitle
            active
            expiredAt
            description
            renewalCount
            unviewedCount
            unviewedUndecidedCount
            totalCount
            shortlistedCount
            rejectedCount
            kivCount
            interviewCount
            offerCount
            keywordHighlight
            resourceType
          }
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.changeJobStatus) {
            const { job } = response.data.data.changeJobStatus;

            if (response.data.data.changeJobStatus.job !== null) {
              return dispatch({
                type: types.CHANGE_JOB_STATUS_SUCCEED,
                job: job
              });
            } else {
              return dispatch({
                type: types.CHANGE_JOB_STATUS_FAILED,
                error: response.data.errors[0].message
              });
            }
          }
        }
        return dispatch({
          type: types.CHANGE_JOB_STATUS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.CHANGE_JOB_STATUS_FAILED
        });
      });
  };

const fetchJobs =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_JOBS
    });

    const queryInput = `first: ${params.first}, offset: ${
      params.offset
    },  isActive: ${params.isActive}, order: ${
      params.orders === null
        ? null
        : `{by: "${params?.orders?.by ?? ''}", direction: "${
            params?.orders?.direction ?? 'desc'
          }"}`
    }, 
    jobTitle: ${JSON.stringify(params.jobTitle)}`;

    const counts = 'allCount activeCount inactiveCount';

    const payload = {
      query: `{
        jobs(${queryInput}) {
          totalCount
          ${counts}
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
            ${jobAttributes}
            }
            cursor
          }
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.jobs) {
            const { jobs } = response.data.data;

            return dispatch({
              type: types.FETCH_JOBS_SUCCEED,
              jobs: jobs,
              allCount: jobs?.allCount,
              activeCount: jobs?.activeCount,
              inactiveCount: jobs?.inactiveCount
            });
          }
        }
        return dispatch({
          type: types.FETCH_JOBS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_JOBS_FAILED
        });
      });
  };

const fetchAllJobs =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_ALL_JOBS
    });

    const payload = {
      query: `{
        jobs {                    
          edges {
            node {
              id
              title
              malaysiansOnly
              localOnly
              active
            }
            cursor
          }
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.jobs) {
            const { jobs } = response.data.data;

            return dispatch({
              type: types.FETCH_ALL_JOBS_SUCCEED,
              jobs: jobs
            });
          }
        }
        return dispatch({
          type: types.FETCH_ALL_JOBS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_ALL_JOBS_FAILED
        });
      });
  };

const updateAllJobs = allJobs => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_ALL_JOBS,
        allJobs: allJobs
      })
    );
  });
};

const updateSelectedFilter = selectedFilter => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_SELECTED_FILTER,
        selectedFilter: selectedFilter
      })
    );
  });
};

const updateSearchJobValue = searchValue => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_SEARCH_JOB_VALUE,
        searchValue: searchValue
      })
    );
  });
};

const updateJobsOffset = jobsPageOffset => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_JOBS_OFFSET,
        jobsPageOffset: jobsPageOffset
      })
    );
  });
};

const updateJobsCurrentPage = jobsCurrentPage => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_JOBS_CURRENT_PAGE,
        jobsCurrentPage: jobsCurrentPage
      })
    );
  });
};

const updateSelectedFilterValue = value => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_SELECTED_FILTER_VALUE,
        selectedFilterValue: value
      })
    );
  });
};

const updateChipStatusIndex = index => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_CHIP_STATUS_INDEX,
        chipStatusIndex: index
      })
    );
  });
};

const updateTotalJobsCount = value => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_TOTAL_JOBS_COUNT,
        totalJobsCount: value
      })
    );
  });
};

const updateExpiryDatesColumnIndex = index => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_EXPIRY_DATES_COLUMN_INDEX,
        expiryDatesColumnIndex: index
      })
    );
  });
};

const updateProductTypeIndex = index => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_PRODUCT_TYPE_INDEX,
        productTypeIndex: index
      })
    );
  });
};

const updateProductActivationDateIndex = index => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_PRODUCT_ACTIVATION_DATE_INDEX,
        productActivationDateIndex: index
      })
    );
  });
};

const updateProductExpiryDateIndex = index => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_PRODUCT_EXPIRY_DATE_INDEX,
        productExpiryDateIndex: index
      })
    );
  });
};

const updateProductDaysRemaining = index => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_PRODUCT_DAYS_REMAINING_INDEX,
        productDaysRemainingIndex: index
      })
    );
  });
};

const updateExpiryDatesPopupContentIndex = index => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_EXPIRY_DATES_POPUP_CONTENT_INDEX,
        index: index
      })
    );
  });
};

const updateSelectedProductName = productName => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_SELECTED_PRODUCT_NAME,
        productName: productName
      })
    );
  });
};

const fetchAllInactiveJobs =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_ALL_INACTIVE_JOBS
    });

    const payload = {
      query: `{
        inactiveJobs {
            id
            title
            trackTitle
            totalCount
            jobTypeTitle
            expiredAt
        }
       }`
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.inactiveJobs) {
            const { inactiveJobs } = response.data.data;

            return dispatch({
              type: types.FETCH_ALL_INACTIVE_JOBS_SUCCEED,
              inactiveJobs: inactiveJobs
            });
          }
        }
        return dispatch({
          type: types.FETCH_ALL_INACTIVE_JOBS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_ALL_INACTIVE_JOBS_FAILED
        });
      });
  };

const fetchFeaturableJobs =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_FEATURABLE_JOBS
    });

    const payload = {
      query: `{
          visibleableJobs(feature: true, refresher: false, spotlight: false, keywordHighlight: false, crossBorder: false) {
            id
            title
            trackTitle
            jobTypeTitle
            expiredAt
            totalCount
          }
        }`
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.visibleableJobs) {
            const { visibleableJobs } = response.data.data;

            return dispatch({
              type: types.FETCH_FEATURABLE_JOBS_SUCCEED,
              featurableJobs: visibleableJobs
            });
          }
        }
        return dispatch({
          type: types.FETCH_FEATURABLE_JOBS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_FEATURABLE_JOBS_FAILED
        });
      });
  };

const fetchRefreshableJobs =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_REFRESHABLE_JOBS
    });

    const payload = {
      query: `{
        visibleableJobs(feature: false, refresher: true, spotlight: false, keywordHighlight: false, crossBorder: false) {
          id
          title
          trackTitle
          totalCount
          jobTypeTitle
          expiredAt
        }
      }`
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.visibleableJobs) {
            const { visibleableJobs } = response.data.data;

            return dispatch({
              type: types.FETCH_REFRESHABLE_JOBS_SUCCEED,
              refreshableJobs: visibleableJobs
            });
          }
        }
        return dispatch({
          type: types.FETCH_REFRESHABLE_JOBS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_REFRESHABLE_JOBS_FAILED
        });
      });
  };

const fetchSpotlightableJobs =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_SPOTLIGHTABLE_JOBS
    });

    const payload = {
      query: `{
        visibleableJobs(feature: false, refresher: false, spotlight: true, keywordHighlight: false, crossBorder: false) {
          id
          title
          trackTitle
          totalCount
          jobTypeTitle
          expiredAt
        }
      }`
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.visibleableJobs) {
            const { visibleableJobs } = response.data.data;
            return dispatch({
              type: types.FETCH_SPOTLIGHTABLE_JOBS_SUCCEED,
              spotlightableJobs: visibleableJobs
            });
          }
        }
        return dispatch({
          type: types.FETCH_SPOTLIGHTABLE_JOBS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_SPOTLIGHTABLE_JOBS_FAILED
        });
      });
  };

const bulkFeatureJobs =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.BULK_JOBS_FEATURING
    });

    const mutationParams = `jobId: ${JSON.stringify(params.jobsId)}`;

    const payload = {
      query: `mutation{
        bulkFeatureJob(input: {${mutationParams}}) {
          success
        }
      }`
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.bulkFeatureJob.success) {
            return dispatch({
              type: types.BULK_FEATURE_JOBS_SUCCEED
            });
          }
        }
        return dispatch({
          type: types.BULK_FEATURE_JOBS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.BULK_FEATURE_JOBS_FAILED
        });
      });
  };

const bulkRefreshJobs =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.BULK_JOBS_REFRESHING
    });

    const mutationParams = `jobId: ${JSON.stringify(params.jobsId)}`;

    const payload = {
      query: `mutation{
        bulkRefreshJob(input: {${mutationParams}}) {
          success
        }
      }`
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.bulkRefreshJob.success) {
            return dispatch({
              type: types.BULK_REFRESH_JOBS_SUCCEED
            });
          }
        }
        return dispatch({
          type: types.BULK_REFRESH_JOBS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.BULK_REFRESH_JOBS_FAILED
        });
      });
  };

const bulkSpotlightJobs =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.BULK_JOBS_SPOTLIGHTING
    });

    const mutationParams = `jobId: ${JSON.stringify(params.jobsId)}`;

    const payload = {
      query: `mutation{
        bulkSpotlightJob(input: {${mutationParams}}) {
          success
        }
      }`
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.bulkSpotlightJob.success) {
            return dispatch({
              type: types.BULK_SPOTLIGHT_JOBS_SUCCEED
            });
          }
        }
        return dispatch({
          type: types.BULK_SPOTLIGHT_JOBS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.BULK_SPOTLIGHT_JOBS_FAILED
        });
      });
  };

const updateFeatureTokensCount = count => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_FEATURE_TOKENS_COUNT,
        count: count
      })
    );
  });
};

const updateCrossBorderTokensCount = count => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_CROSS_BORDER_TOKENS_COUNT,
        count: count
      })
    );
  });
};

const updateRefresherTokensCount = count => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_REFRESHER_TOKENS_COUNT,
        count: count
      })
    );
  });
};

const updateSpotlightTokensCount = count => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_SPOTLIGHT_TOKENS_COUNT,
        count: count
      })
    );
  });
};

const updatePostJobPopup = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_POST_JOB_POPUP,
        status: status
      })
    );
  });
};

const fetchJobTitleSuggestions = searchTitle => async dispatch => {
  dispatch({
    type: types.FETCH_JOB_TITLE_SUGGESTIONS
  });

  const lightCastToken = localStorage.getItem('lightcast-token');

  const headers = {
    Authorization: `Bearer ${lightCastToken}`
  };

  const requestParams = new URLSearchParams();
  requestParams.append('q', searchTitle);
  requestParams.append('limit', 10);
  requestParams.append('page', 1);

  return fetchAPI(
    'GET',
    'https://emsiservices.com/titles/versions/latest/titles',
    undefined,
    headers,
    requestParams
  )
    .then(async response => {
      if (response.status == 200) {
        if (response.data.data) {
          return new Promise(resolve => {
            return resolve(
              dispatch({
                type: types.FETCH_JOB_TITLE_SUGGESTIONS_SUCCEED,
                jobTitleSuggestions: response.data?.data
              })
            );
          });
        }
      }
      return dispatch({
        type: types.FETCH_JOB_TITLE_SUGGESTIONS_FAILED
      });
    })
    .catch(() => {
      return dispatch({
        type: types.FETCH_JOB_TITLE_SUGGESTIONS_FAILED
      });
    });
};

const updateJobTitleSuggestionsDropdownStatus = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_JOB_TITLE_SUGGESTIONS_DROPDOWN_STATUS,
        isJobTitleDropdownSuggestionsOpen: status
      })
    );
  });
};

const updateJobSkills = values => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_JOB_SKILLS,
        selectedJobSkills: values
      })
    );
  });
};

const updateJobKeywords = values => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_JOB_KEYWORDS,
        selectedJobKeywords: values
      })
    );
  });
};

const updateTempJobDraft = values => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_TEMP_JOB,
        tempJobDraft: values
      })
    );
  });
};

const fetchJobSkillsSuggestions = searchSkill => dispatch => {
  dispatch({
    type: types.FETCH_JOB_SKILLS_SUGGESTIONS
  });

  const lightCastToken = localStorage.getItem('lightcast-token');

  const url = 'https://emsiservices.com/skills/versions/latest/skills';

  const requestParams = new URLSearchParams();
  requestParams.append('q', searchSkill);
  requestParams.append('typeIds', 'ST1,ST2');
  requestParams.append('limit', 10);

  const headers = {
    Authorization: `Bearer ${lightCastToken}`
  };

  return fetchAPI('GET', url, undefined, headers, requestParams)
    .then(response => {
      if (response.status == 200) {
        if (response.data.data) {
          return new Promise(resolve => {
            return resolve(
              dispatch({
                type: types.FETCH_JOB_SKILLS_SUGGESTIONS_SUCCEED,
                jobSkillsSuggestions: response.data.data
              })
            );
          });
        }
      }
      return dispatch({
        type: types.FETCH_JOB_SKILLS_SUGGESTIONS_FAILED
      });
    })
    .catch(e => {
      return dispatch({
        type: types.FETCH_JOB_SKILLS_SUGGESTIONS_FAILED
      });
    });
};

const updateJobSkillSearch = values => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_JOB_SKILL_SEARCH,
        jobSkillSearch: values
      })
    );
  });
};

const updateJobSkillDropdownStatus = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_JOB_SKILL_DROPDOWN_STATUS,
        status: status
      })
    );
  });
};

const updateJobTitleSuggestions = value => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_JOB_TITLE_SUGGESTIONS,
        jobTitleSuggestions: value
      })
    );
  });
};

const updatePostJobTooltipStatus = value => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_POST_JOB_TOOLTIPS_STATUS,
        status: value
      })
    );
  });
};

const updatePostJobPrevTooltip = value => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_POST_JOB_PREV_TOOLTIP,
        prevTooltip: value
      })
    );
  });
};

const updateKeywordHighlightPopupStatus = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_KEYWORD_HIGHLIGHT_POPUP_STATUS,
        status: status
      })
    );
  });
};

const updateSpotlightJobConfirmationPopup = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_SPOTLIGHT_JOB_CONFIRMATION_POPUP,
        status: status
      })
    );
  });
};

const updateCrossBorderConfirmationPopup = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_CROSS_BORDER_CONFIRMATION_POPUP,
        status: status
      })
    );
  });
};

const updateKeywordHighlightConfirmationPopup = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_KEYWORD_HIGHLIGHT_CONFIRMATION_POPUP,
        status: status
      })
    );
  });
};

const spotlightJob =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.SPOTLIGHT_JOB
    });

    const mutationInput = `jobId: ${JSON.stringify(params.jobId)}`;

    const payload = {
      query: `mutation {
        spotlightJob(input: {${mutationInput}}) {
          success
          job {
            ${jobAttributes}
          }
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.spotlightJob) {
            const { success, job } = response.data.data.spotlightJob;

            if (success) {
              return dispatch({
                type: types.SPOTLIGHT_JOB_SUCCEED,
                job: job
              });
            }
          }
        }
        return dispatch({
          type: types.SPOTLIGHT_JOB_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.SPOTLIGHT_JOB_FAILED
        });
      });
  };

const keywordingJob =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.KEYWORD_HIGHLIGHT_JOB
    });

    const mutationInput = `jobId: ${JSON.stringify(
      params.jobId
    )}, extendKeywordHighlight: ${
      params.extendKeywordHighlight
    } ,keywords: ${convertArrayToProperString(params.keywords)}`;

    const payload = {
      query: `mutation {
        keywordHighlightJob(input: {${mutationInput}}) {
          success
          job {
            ${jobAttributes}
          }
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.keywordHighlightJob) {
            const { success, job } = response.data.data.keywordHighlightJob;

            if (success) {
              return dispatch({
                type: types.KEYWORD_HIGHLIGHT_JOB_SUCCEED,
                job: job
              });
            }
          }
        }
        return dispatch({
          type: types.KEYWORD_HIGHLIGHT_JOB_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.KEYWORD_HIGHLIGHT_JOB_FAILED
        });
      });
  };

const updateKeywordHighlightTexts = keywords => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_KEYWORD_HIGHLIGHT_TEXTS,
        keywords: keywords
      })
    );
  });
};

const updateJob = job => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_JOB,
        job: job
      })
    );
  });
};

const updateSpecialisationPopoverStatus = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_SPECIALISATION_POPOVER_STATUS,
        status: status
      })
    );
  });
};

const updateSpecialisationSubcategories = subcategories => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_SPECIALISATION_SUB_CATEGORIES,
        subcategories: subcategories
      })
    );
  });
};

const fetchSpecialisation =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_SPECIALISAION
    });

    const payload = {
      query: `{
        specialisations {
          id
          title
          subCategories {
            id
            title
            parentCategory { 
              id
              title
            }
          }
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.specialisations) {
            const specialisation = response?.data?.data?.specialisations;
            return dispatch({
              type: types.FETCH_SPECIALISAION_SUCCEED,
              specialisation: specialisation
            });
          }
        }
        return dispatch({
          type: types.FETCH_SPECIALISAION_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_SPECIALISAION_FAILED
        });
      });
  };

const updateAshleyRewritePopup = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_ASHLEY_REWRITE_POPUP,
        status: status
      })
    );
  });
};

const updateAshleyRewriteOrigin = origin => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_ASHLEY_REWRITE_ORIGIN,
        origin: origin
      })
    );
  });
};

const getAshleyTextRewrite =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_ASHLEY_TEXT_REWRITE
    });

    const mutationInput = `text: ${JSON.stringify(
      params.text
    )}, textType: ${JSON.stringify(params.textType)}`;

    const payload = {
      query: `mutation {
        gptTextRewrite(input: {
          ${mutationInput}
        }) {
          result
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.gptTextRewrite.result) {
            const texts = response?.data?.data?.gptTextRewrite?.result.text;
            return dispatch({
              type: types.FETCH_ASHLEY_TEXT_REWRITE_SUCCEED,
              texts: texts,
              textType: params.textType
            });
          }
        }
        return dispatch({
          type: types.FETCH_ASHLEY_TEXT_REWRITE_FAILED,
          error: response?.data?.data?.gptTextRewrite?.result.message
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_ASHLEY_TEXT_REWRITE_FAILED,
          error: error
        });
      });
  };

const updateJobDataForAshleyRewrite = content => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_JOB_DATA_FOR_ASHLEY_REWRITE,
        content: content
      })
    );
  });
};

const updateTmpEditorState = data => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_TMP_EDITOR_STATE,
        state: data.state,
        editorOrigin: data.editorOrigin
      })
    );
  });
};

const updateOldEditorState = data => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_OLD_EDITOR_STATE,
        state: data.state,
        editorOrigin: data.editorOrigin
      })
    );
  });
};

const updateAshleyRewriteMessage = content => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_ASHLEY_REWRITE_MESSAGE,
        content: content
      })
    );
  });
};

const updateJobData = job => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_JOB_DATA,
        job: job
      })
    );
  });
};

const fetchJob =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_JOB
    });

    const payload = {
      query: `{
        job(id: "${params.id}") {
          ${jobAttributes}
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.job) {
            const { job } = response?.data?.data;

            return dispatch({
              type: types.FETCH_JOB_SUCCEED,
              job: job
            });
          }
        }
        return dispatch({
          type: types.FETCH_JOB_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_JOB_FAILED
        });
      });
  };

const updateAshleyEditFeedback = feedback => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_ASHLEY_EDIT_FEEDBACK,
        feedback: feedback
      })
    );
  });
};

const sendAshleyEditFeedback =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.ASHLEY_EDIT_FEEDBACK_FAILED
    });

    const inputs = `jobId: ${JSON.stringify(
      params.jobId
    )}, text: ${JSON.stringify(params.texts)}`;

    const payload = {
      query: `mutation{
        ashleyEditFeedback(input: {${inputs}}) {
          result
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.ashleyEditFeedback) {
            const { ashleyEditFeedback } = response.data.data;

            return dispatch({
              type: types.ASHLEY_EDIT_FEEDBACK_SUCCEED,
              success: ashleyEditFeedback.result.success
            });
          }
        }
        return dispatch({
          type: types.ASHLEY_EDIT_FEEDBACK_FAILED,
          error: response.data.errors[0].message
        });
      })
      .catch(error => {
        return dispatch({
          type: types.ASHLEY_EDIT_FEEDBACK_FAILED
        });
      });
  };

const fetchDeactivateReasons =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_DEACTIVATE_REASONS
    });

    const payload = {
      query: `{
        deactivateReasons {
          id
          title
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.deactivateReasons) {
            const deactivateReasons = response?.data?.data?.deactivateReasons;
            return dispatch({
              type: types.FETCH_DEACTIVATE_REASONS_SUCCEED,
              deactivateReasons: deactivateReasons
            });
          }
        }
        return dispatch({
          type: types.FETCH_DEACTIVATE_REASONS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_DEACTIVATE_REASONS_FAILED
        });
      });
  };

const fetchPersonalizedQuestions =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_PERSONALIZED_QUESTIONS
    });

    const payload = {
      query: `{
        personalizedQuestions {
          id
          title
          intent
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.personalizedQuestions) {
            const personalizedQuestions =
              response?.data?.data?.personalizedQuestions;
            return dispatch({
              type: types.FETCH_PERSONALIZED_QUESTIONS_SUCCEED,
              personalizedQuestions: personalizedQuestions
            });
          }
        }
        return dispatch({
          type: types.FETCH_PERSONALIZED_QUESTIONS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_PERSONALIZED_QUESTIONS_FAILED
        });
      });
  };

const fetchJobPersonalizedQuestions =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_JOB_PERSONALIZED_QUESTIONS
    });

    const payload = {
      query: `{
        jobPersonalizedQuestions(jobId: "${params.jobId}") {
          id
          defaultQuestion
          customizedQuestion
          job {
            enableChatbot
          }
          intent
          order
          createdAt
          updatedAt
          personalizedQuestionId
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.jobPersonalizedQuestions) {
            const jobPersonalizedQuestions =
              response?.data?.data?.jobPersonalizedQuestions;
            return dispatch({
              type: types.FETCH_JOB_PERSONALIZED_QUESTIONS_SUCCEED,
              jobPersonalizedQuestions: jobPersonalizedQuestions
            });
          }
        }
        return dispatch({
          type: types.FETCH_JOB_PERSONALIZED_QUESTIONS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_JOB_PERSONALIZED_QUESTIONS_FAILED
        });
      });
  };

const fetchDraftJob =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_DRAFT_JOB
    });

    const payload = {
      query: `{
        draftJob {
          id
          title
          customInput
          imagePosition
          imageSize
          jobBannerUrl
          jobBannerUrlSquare
          boostedExpired
          location
          malaysiansOnly
          localOnly
          stateRegion {
            id
            title
          }
          active
          expiredAt
          description
          renewalCount
          requirements
          minSalaryRange
          maxSalaryRange
          minYearExperience
          maxYearExperience
          receiveNotification
          customEmail
          externalJobUrl
          hideSalary
          tracks {
            id
            title
          }
          specialisations {
            id
            title
            subCategories {
              id
              title
              parentCategory {
                id
                title
              }
            }
          }
          experiences {
            id
            title
          }
          jobType {
            id
            title
          }
          careerLevel {
            id
            name
          }
          skills
          gptUsage
          keywordHighlightText
          globalHireEnabled
          globalHirePreferences
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          const draftJob = response?.data?.data?.draftJob;

          if (draftJob != null) {
            return dispatch({
              type: types.FETCH_DRAFT_JOB_SUCCEED,
              draftJob: draftJob
            });
          }
        }
        return dispatch({
          type: types.FETCH_DRAFT_JOB_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_DRAFT_JOB_FAILED
        });
      });
  };

const fetchJobTeamMembers =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_JOB_TEAM_MEMBERS
    });

    const payload = {
      query: `{
        job(id: "${params.jobId}") {
          companyTeamMembers {
            id
            name
            email
            position
            contactNumber
            createdAt
            updatedAt
          }
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.job?.companyTeamMembers) {
            const jobTeamMembers =
              response?.data?.data?.job?.companyTeamMembers;
            return dispatch({
              type: types.FETCH_JOB_TEAM_MEMBERS_SUCCEED,
              jobTeamMembers: jobTeamMembers
            });
          }
        }
        return dispatch({
          type: types.FETCH_JOB_TEAM_MEMBERS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_JOB_TEAM_MEMBERS_FAILED
        });
      });
  };

const fetchTeamMembers =
  (params = {}, loadingState) =>
  async (dispatch, getState) => {
    if (loadingState !== false) {
      dispatch({
        type: types.FETCH_TEAM_MEMBERS
      });
    }

    const payload = {
      query: `{
        companyTeamMembers(name: "${params.name ?? ''}") {
          id
          name
          email
          position
          tracks {
            id
            title
          }
          specialisations {
            id
            title
            subCategories {
              id
              title
              parentCategory {
                id
                title
              }
            }
          }
          accessLevels {
            id
            title
          }
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.companyTeamMembers) {
            const companyTeamMembers = response?.data?.data?.companyTeamMembers;
            return dispatch({
              type: types.FETCH_TEAM_MEMBERS_SUCCEED,
              companyTeamMembers: companyTeamMembers
            });
          }
        }
        return dispatch({
          type: types.FETCH_TEAM_MEMBERS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_TEAM_MEMBERS_FAILED
        });
      });
  };

const mutateCrossBorder =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_CROSS_BORDER
    });

    const data = params.input;

    const input = `
    ${data.jobId ? `jobId: "${data.jobId}",` : ''}
    crossBorderDestinations: ${
      data?.crossBorderDestinations
        ? `["${data.crossBorderDestinations}"]`
        : '[]'
    },
`;

    const payload = {
      query: `mutation {
        crossBorderJob(input: {${input}}) {
        success
        job {
          ${jobAttributes}
        }
      }
     }
    `
    };
    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.crossBorderJob) {
            return dispatch({
              type: types.MUTATE_CROSS_BORDER_SUCCEED,
              success: response?.data
            });
          } else {
            return dispatch({
              type: types.MUTATE_CROSS_BORDER_FAILED
            });
          }
        }
        return dispatch({
          type: types.MUTATE_CROSS_BORDER_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_CROSS_BORDER_FAILED,
          error: error
        });
      });
  };

const mutateGlobalHire =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_GLOBAL_HIRE
    });

    const data = params.input;

    const input = `
  ${data.jobId ? `jobId: "${data.jobId}",` : ''}
  globalHirePreferences: {
    enabled: ${data.enabled || false},
    workingArrangementRemote: ${data.workingArrangementRemote || false},
    workingArrangementOnSite: ${data.workingArrangementOnSite || false},
    workingArrangementHybrid: ${data.workingArrangementHybrid || false},
    rightToWorkOpenToSponsor: ${data.rightToWorkOpenToSponsor || false},
    rightToWorkLocalCitizen: ${data.rightToWorkLocalCitizen || false},
    rightToWorkPermanentCitizen: ${data.rightToWorkPermanentCitizen || false},
    rightToWorkWorkVisaOrEmploymentPass: ${
      data.rightToWorkWorkVisaOrEmploymentPass || false
    },
  }
`;

    const payload = {
      query: `mutation {
        saveGlobalHire(input: {${input}}) {
        success
        job {
          ${jobAttributes}
        }
      }
     }
    `
    };
    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.saveGlobalHire) {
            return dispatch({
              type: types.MUTATE_GLOBAL_HIRE_SUCCEED,
              success: response?.data
            });
          } else {
            return dispatch({
              type: types.MUTATE_GLOBAL_HIRE_FAILED
            });
          }
        }
        return dispatch({
          type: types.MUTATE_GLOBAL_HIRE_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_GLOBAL_HIRE_FAILED,
          error: error
        });
      });
  };

const mutateCrossBorderAndGlobalHire =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_CROSS_BORDER
    });

    const data = params.input;

    const input = `
    ${data.jobId ? `jobId: "${data.jobId}",` : ''}
    globalHirePreferences: {
      enabled: ${data.enabled || false},
      workingArrangementRemote: ${data.workingArrangementRemote || false},
      workingArrangementOnSite: ${data.workingArrangementOnSite || false},
      workingArrangementHybrid: ${data.workingArrangementHybrid || false},
      rightToWorkOpenToSponsor: ${data.rightToWorkOpenToSponsor || false},
      rightToWorkLocalCitizen: ${data.rightToWorkLocalCitizen || false},
      rightToWorkPermanentCitizen: ${data.rightToWorkPermanentCitizen || false},
      rightToWorkWorkVisaOrEmploymentPass: ${
        data.rightToWorkWorkVisaOrEmploymentPass || false
      },
    }
    crossBorderDestinations: ${
      data?.crossBorderDestinations
        ? `["${data.crossBorderDestinations}"]`
        : '[]'
    },
`;

    const payload = {
      query: `mutation {
        globalHireAndCrossBorderJob(input: {${input}}) {
        success
        job {
          ${jobAttributes}
        }
      }
     }
    `
    };
    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data) {
            return dispatch({
              type: types.MUTATE_CROSS_BORDER_SUCCEED,
              success: response?.data
            });
          } else {
            return dispatch({
              type: types.MUTATE_CROSS_BORDER_FAILED
            });
          }
        }
        return dispatch({
          type: types.MUTATE_CROSS_BORDER_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_CROSS_BORDER_FAILED,
          error: error
        });
      });
  };

const mutateVirtualInterviewQuestions =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_VIRTUAL_INTERVIEW_QUESTIONS
    });

    const data = params.input;

    const input = `
    ${data.jobId ? `jobId: "${data.jobId}",` : ''}
    ${`enableChatbot: ${data.enableChatbot},`}
    ${data.question1 ? `question1: ${data.question1},` : ''}
    ${data.question2 ? `question2: ${data.question2},` : ''}
    ${data.question3 ? `question3: ${data.question3},` : ''}
    ${data.question3Custom ? `question3Custom: "${data.question3Custom}",` : ''}
    ${data.action ? `action: "${data.action}",` : ''}
`;

    const payload = {
      query: `mutation {
      jobPersonalizedQuestions(input: {${input}}) {
        success
      }
     }
    `
    };
    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.jobPersonalizedQuestions) {
            return dispatch({
              type: types.MUTATE_VIRTUAL_INTERVIEW_QUESTIONS_SUCCEED,
              success: response?.data?.data?.jobPersonalizedQuestions.success
            });
          }
        }
        return dispatch({
          type: types.MUTATE_VIRTUAL_INTERVIEW_QUESTIONS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_VIRTUAL_INTERVIEW_QUESTIONS_FAILED,
          error: error
        });
      });
  };

const mutateJobAccess =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_JOB_ACCESS
    });

    const data = params.input;

    const input = `
    ${data.jobId ? `jobId: "${data.jobId}",` : ''}
    ${
      data.companyTeamMembers
        ? `companyTeamMembers: [${data.companyTeamMembers
            .map(
              teamMember => `
        {
          ${teamMember.id ? `id: "${teamMember.id}",` : ''}
          ${teamMember.name ? `name: "${teamMember.name}",` : ''}
          ${teamMember.email ? `email: "${teamMember.email}",` : ''}
          ${teamMember.position ? `position: "${teamMember.position}",` : ''}
          ${
            teamMember.contactNumber
              ? `contactNumber: "${teamMember.contactNumber}",`
              : ''
          }
        }
      `
            )
            .join(',')}
      ]`
        : ''
    }
`;

    const payload = {
      query: `mutation {
        saveJobAccess(input: {${input}}) {
          success
        }
     }
    `
    };
    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.saveJobAccess) {
            return dispatch({
              type: types.MUTATE_JOB_ACCESS_SUCCEED,
              success: response?.data?.data?.saveJobAccess?.success
            });
          }
        }
        return dispatch({
          type: types.MUTATE_JOB_ACCESS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_JOB_ACCESS_FAILED,
          error: error
        });
      });
  };

const mutateJobApplicationState =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_JOB_APPLICATION_STATE
    });

    const data = params.input;

    const input = `
      ${data.id ? `id: "${data.id}",` : ''}
      ${data.state ? `state: "${data.state}",` : ''}
      ${
        data.blacklistReason
          ? `blacklistReason: "${data.blacklistReason}",`
          : ''
      }
      ${data.rejectReason ? `rejectReason: "${data.rejectReason}",` : ''}
    `;

    const payload = {
      query: `mutation {
        changeJobApplicationState(input: {${input}}) {
          jobApplication {
            id
            status
            state
            applicationStatus
            appliedAt
            sendbirdChannelUrl
            botContext
            chatStatus
            chatbotStatus
            unread
            lastMessage
            lastMessageAt
            rejectReason
            user {
              blacklist
              id
              name
              shortSummary
              nationality
              gender
              email
              mobileNumber
              currentCompany
              fieldOfStudy
              major
              highestEducationLevel
              educationalInstitution
              graduationYear
              expectedSalary
              age
              profileImage
              profileImageSquare
              trackIds
              experienceIds
              stateRegionNames
              videoCv
              videoCvOptimized
            }
            job {
              id
              title
            }
          }
        }
     }
    `
    };
    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.changeJobApplicationState?.jobApplication) {
            const jobApplication =
              response?.data?.data?.changeJobApplicationState?.jobApplication;

            return dispatch({
              type: types.MUTATE_JOB_APPLICATION_STATE_SUCCEED,
              jobApplication: jobApplication
            });
          }
        }
        return dispatch({
          type: types.MUTATE_JOB_APPLICATION_STATE_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_JOB_APPLICATION_STATE_FAILED,
          error: error
        });
      });
  };

const mutateViewedJobApplication =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_VIEWED_JOB_APPLICATION
    });

    const data = params.input;

    const input = `
      ${data.id ? `id: "${data.id}",` : ''}
    `;

    const payload = {
      query: `mutation {
        viewedJobApplication(input: {${input}}) {
          jobApplication {
            id
            status
            state
            appliedAt
            sendbirdChannelUrl
            botContext
            chatStatus
            chatbotStatus
            unread
            lastMessage
            lastMessageAt
            user {
              id
              name
              shortSummary
              nationality
              gender
              email
              mobileNumber
              currentCompany
              fieldOfStudy
              major
              highestEducationLevel
              educationalInstitution
              graduationYear
              expectedSalary
              age
              profileImage
              profileImageSquare
              trackIds
              experienceIds
              stateRegionNames
              videoCv
              videoCvOptimized
            }
            job {
              title
            }
          }
        }
     }
    `
    };
    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.viewedJobApplication?.jobApplication) {
            const viewedJobApplication =
              response?.data?.data?.viewedJobApplication?.jobApplication;

            return dispatch({
              type: types.MUTATE_VIEWED_JOB_APPLICATION_SUCCEED,
              viewedJobApplication: viewedJobApplication
            });
          }
        }
        return dispatch({
          type: types.MUTATE_VIEWED_JOB_APPLICATION_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_VIEWED_JOB_APPLICATION_FAILED,
          error: error
        });
      });
  };

const mutateCreateJob =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_CREATE_JOB
    });

    const data = params.input;

    const keywordsInput =
      data.keywords && data.keywords.length > 0
        ? `[${data.keywords
            .map(keyword => `{ keyword: "${keyword}" }`)
            .join(',')}]`
        : '[]';
    const input = `
    id: "${data.id ? data.id : ''}",
    active: ${data.active != null ? data.active : false},
    title: "${data.title ? data.title : ''}",
    location: "${data.location ? data.location : ''}",
    minSalaryRange: ${data.minSalaryRange != null ? data.minSalaryRange : 0},
    maxSalaryRange: ${data.maxSalaryRange != null ? data.maxSalaryRange : 0},
    hideSalary: ${data.hideSalary != null ? data.hideSalary : false},
    description: """${data.description ? data.description : ''}""",
    requirements: """${data.requirements ? data.requirements : ''}""",
    tracks: ${
      data.tracks && data.tracks.length > 0
        ? `[${data.tracks
            .map(track => `{ id: ${track.id ? `"${track.id}"` : '""'} }`)
            .join(',')}]`
        : '[]'
    },
    jobType: ${
      data.jobType && data.jobType.id ? `{ id: "${data.jobType.id}" }` : '{ }'
    },
    careerLevel: ${
      data.careerLevel && data.careerLevel.id
        ? `{ id: "${data.careerLevel.id}" }`
        : '{ }'
    },
    stateRegion: ${
      data.stateRegion && data.stateRegion.id
        ? `{ id: "${data.stateRegion.id}" }`
        : '{ }'
    },
    skills: ${
      data.skills && data.skills.length > 0
        ? `[${data.skills
            .map(skill => `{ name: ${skill.name ? `"${skill.name}"` : '""'} }`)
            .join(',')}]`
        : '[]'
    },
    minYearExperience: ${
      isFinite(data.minYearExperience) ? data.minYearExperience : 0
    },
    maxYearExperience: ${
      isFinite(data.maxYearExperience) ? data.maxYearExperience : 0
    },
    jobBannerUrl: "${data.jobBannerUrl ? data.jobBannerUrl : ''}",
    jobBannerUrlSquare: "${
      data.bannerImageUrlSquare ? data.bannerImageUrlSquare : ''
    }",
    receiveNotification: ${
      data.receiveNotification != null ? data.receiveNotification : false
    },
    customEmail: "${data.customEmail ? data.customEmail : ''}",
    imagePosition: "${data.imagePosition ? data.imagePosition : ''}",
    imageSize: "${data.imageSize ? data.imageSize : ''}",
    localOnly: ${data.localOnly != null ? data.localOnly : false},
    externalJobUrl: "${data.externalJobUrl ? data.externalJobUrl : ''}",
    customInput: "${data.customInput ? data.customInput : ''}",
    gptRewrites: """${
      data.gptRewrites ? JSON.stringify(data.gptRewrites) : '{}'
    }""",
    keywords: ${keywordsInput},
    resourceType: "${data.resourceType ? data.resourceType : ''}",
    enableKeywordHighlight: ${
      data.enableKeywordHighlight ? data.enableKeywordHighlight : false
    },
    globalHirePreferences: {
      enabled: ${data.globalHirePreferences.enabled || false},
      workingArrangementRemote: ${
        data.globalHirePreferences.workingArrangementRemote || false
      },
      workingArrangementOnSite: ${
        data.globalHirePreferences.workingArrangementOnSite || false
      },
      workingArrangementHybrid: ${
        data.globalHirePreferences.workingArrangementHybrid || false
      },
      rightToWorkOpenToSponsor: ${
        data.globalHirePreferences.rightToWorkOpenToSponsor || false
      },
      rightToWorkLocalCitizen: ${
        data.globalHirePreferences.rightToWorkLocalCitizen || false
      },
      rightToWorkPermanentCitizen: ${
        data.globalHirePreferences.rightToWorkPermanentCitizen || false
      },
      rightToWorkWorkVisaOrEmploymentPass: ${
        data.globalHirePreferences.rightToWorkWorkVisaOrEmploymentPass || false
      }
    }

  `.trim();

    const payload = {
      query: `mutation {
        createJob(input: {${input}}) {
          job {
            ${jobAttributes}
          }
        }
     }
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.createJob) {
            const job = response?.data?.data?.createJob?.job;

            if (job !== null) {
              return dispatch({
                type: types.MUTATE_CREATE_JOB_SUCCEED,
                createJob: job
              });
            } else {
              return dispatch({
                type: types.MUTATE_CREATE_JOB_FAILED,
                error: response?.data
              });
            }
          }
        }
        let error = response.data?.errors[0].message?.includes('Parse error')
          ? `The backslash ${'\\'} character is not supported. Please use a different character.`
          : response.data?.errors[0].message;
        return dispatch({
          type: types.MUTATE_CREATE_JOB_FAILED,
          error: error ?? 'Error'
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_CREATE_JOB_FAILED,
          error: error
        });
      });
  };

const mutateCreateDraftJob =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_CREATE_DRAFT_JOB
    });

    const data = params.input;

    const keywordsInput =
      data.keywords && data.keywords.length > 0
        ? `[${data.keywords
            .map(keyword => `{ keyword: "${keyword}" }`)
            .join(',')}]`
        : '[]';

    const input = `
    title: "${data.title ? data.title : ''}",
    location: "${data.location ? data.location : ''}",
    minSalaryRange: ${data.minSalaryRange != null ? data.minSalaryRange : 0},
    maxSalaryRange: ${data.maxSalaryRange != null ? data.maxSalaryRange : 0},
    hideSalary: ${data.hideSalary != null ? data.hideSalary : false},
    description: ""${JSON.stringify(data.description || '')}"",
    requirements: ""${JSON.stringify(data.requirements || '')}"",
    tracks: ${
      data.tracks && data.tracks.length > 0
        ? `[${data.tracks
            .map(track => `{ id: ${track.id ? `"${track.id}"` : '""'} }`)
            .join(',')}]`
        : '[]'
    },
    jobType: ${
      data?.jobType?.id ? `{ id: "${data.jobType.id}" }` : '{ id: "0"}'
    },
    careerLevel: ${
      data?.careerLevel?.id ? `{ id: "${data.careerLevel.id}" }` : '{ id: "0"}'
    },
    stateRegion: ${
      data?.stateRegion?.id ? `{ id: "${data.stateRegion.id}" }` : '{ id: "0"}'
    },
    ${
      data?.skills?.length > 0
        ? `skills: [${data.skills
            .map(
              skill => `
        {
          ${skill.name ? `name: "${skill.name}",` : ''}
        }
      `
            )
            .join(',')}
      ],`
        : ''
    }
    minYearExperience: ${
      isFinite(data.minYearExperience) ? data.minYearExperience : 0
    },
    maxYearExperience: ${
      isFinite(data.maxYearExperience) ? data.maxYearExperience : 0
    },
    jobBannerUrl: "${data.jobBannerUrl ? data.jobBannerUrl : ''}",
    jobBannerUrlSquare: "${
      data.bannerImageUrlSquare ? data.bannerImageUrlSquare : ''
    }",
    receiveNotification: ${
      data.receiveNotification != null ? data.receiveNotification : false
    },
    customEmail: "${data.customEmail ? data.customEmail : ''}",
    imagePosition: "${data.imagePosition ? data.imagePosition : ''}",
    imageSize: "${data.imageSize ? data.imageSize : ''}",
    localOnly: ${data.localOnly != null ? data.localOnly : false},
    externalJobUrl: "${data.externalJobUrl ? data.externalJobUrl : ''}",
    customInput: "${data.customInput ? data.customInput : ''}",
    gptRewrites: """${
      data.gptRewrites ? JSON.stringify(data.gptRewrites) : '{}'
    }""",
    keywords: ${keywordsInput},
    globalHirePreferences: {
      enabled: ${data.globalHirePreferences.enabled || false},
      workingArrangementRemote: ${
        data.globalHirePreferences.workingArrangementRemote || false
      },
      workingArrangementOnSite: ${
        data.globalHirePreferences.workingArrangementOnSite || false
      },
      workingArrangementHybrid: ${
        data.globalHirePreferences.workingArrangementHybrid || false
      },
      rightToWorkOpenToSponsor: ${
        data.globalHirePreferences.rightToWorkOpenToSponsor || false
      },
      rightToWorkLocalCitizen: ${
        data.globalHirePreferences.rightToWorkLocalCitizen || false
      },
      rightToWorkPermanentCitizen: ${
        data.globalHirePreferences.rightToWorkPermanentCitizen || false
      },
      rightToWorkWorkVisaOrEmploymentPass: ${
        data.globalHirePreferences.rightToWorkWorkVisaOrEmploymentPass || false
      }
    }
  `.trim();

    const payload = {
      query: `mutation {
        createDraftJob(input: {${input}}) {
          job {
            ${jobDraftAttributes}
          }
        }
     }
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.createDraftJob) {
            const createDraftJob = response?.data?.data?.createDraftJob?.job;

            if (createDraftJob !== null) {
              return dispatch({
                type: types.MUTATE_CREATE_DRAFT_JOB_SUCCEED,
                createDraftJob: createDraftJob
              });
            } else {
              return dispatch({
                type: types.MUTATE_CREATE_DRAFT_JOB_FAILED,
                error: response?.data
              });
            }
          }
        }
        return dispatch({
          type: types.MUTATE_CREATE_DRAFT_JOB_FAILED,
          error: response?.data
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_CREATE_DRAFT_JOB_FAILED,
          error: error
        });
      });
  };

const mutateUpdateJob =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_CREATE_JOB
    });

    const data = params.input;

    const keywordsInput =
      data.keywords && data.keywords.length > 0
        ? `[${data.keywords
            .map(keyword => `{ keyword: "${keyword}" }`)
            .join(',')}]`
        : '[]';
    const input = `
    id: "${data.id ? data.id : ''}",
    active: ${data.active != null ? data.active : false},
    title: "${data.title ? data.title : ''}",
    location: "${data.location ? data.location : ''}",
    minSalaryRange: ${data.minSalaryRange != null ? data.minSalaryRange : 0},
    maxSalaryRange: ${data.maxSalaryRange != null ? data.maxSalaryRange : 0},
    hideSalary: ${data.hideSalary != null ? data.hideSalary : false},
    description: """${data.description ? data.description : ''}""",
    requirements: """${data.requirements ? data.requirements : ''}""",
    tracks: ${
      data.tracks && data.tracks.length > 0
        ? `[${data.tracks
            .map(track => `{ id: ${track.id ? `"${track.id}"` : '""'} }`)
            .join(',')}]`
        : '[]'
    },
    jobType: ${
      data.jobType && data.jobType.id ? `{ id: "${data.jobType.id}" }` : '{ }'
    },
    careerLevel: ${
      data.careerLevel && data.careerLevel.id
        ? `{ id: "${data.careerLevel.id}" }`
        : '{ }'
    },
    stateRegion: ${
      data.stateRegion && data.stateRegion.id
        ? `{ id: "${data.stateRegion.id}" }`
        : '{ }'
    },
    skills: ${
      data.skills && data.skills.length > 0
        ? `[${data.skills
            .map(skill => `{ name: ${skill.name ? `"${skill.name}"` : '""'} }`)
            .join(',')}]`
        : '[]'
    },
    minYearExperience: ${
      isFinite(data.minYearExperience) ? data.minYearExperience : 0
    },
    maxYearExperience: ${
      isFinite(data.maxYearExperience) ? data.maxYearExperience : 0
    },
    jobBannerUrl: "${data.jobBannerUrl ? data.jobBannerUrl : ''}",
    jobBannerUrlSquare: "${
      data.bannerImageUrlSquare ? data.bannerImageUrlSquare : ''
    }",
    receiveNotification: ${
      data.receiveNotification != null ? data.receiveNotification : false
    },
    customEmail: "${data.customEmail ? data.customEmail : ''}",
    imagePosition: "${data.imagePosition ? data.imagePosition : ''}",
    imageSize: "${data.imageSize ? data.imageSize : ''}",
    localOnly: ${data.localOnly != null ? data.localOnly : false},
    externalJobUrl: "${data.externalJobUrl ? data.externalJobUrl : ''}",
    customInput: "${data.customInput ? data.customInput : ''}",
    gptRewrites: """${
      data.gptRewrites ? JSON.stringify(data.gptRewrites) : '{}'
    }""",
    keywords: ${keywordsInput},
    resourceType: "${data.resourceType ? data.resourceType : ''}",
    enableKeywordHighlight: ${
      data.enableKeywordHighlight ? data.enableKeywordHighlight : false
    },
    globalHirePreferences: {
      enabled: ${data.globalHirePreferences.enabled || false},
      workingArrangementRemote: ${
        data.globalHirePreferences.workingArrangementRemote || false
      },
      workingArrangementOnSite: ${
        data.globalHirePreferences.workingArrangementOnSite || false
      },
      workingArrangementHybrid: ${
        data.globalHirePreferences.workingArrangementHybrid || false
      },
      rightToWorkOpenToSponsor: ${
        data.globalHirePreferences.rightToWorkOpenToSponsor || false
      },
      rightToWorkLocalCitizen: ${
        data.globalHirePreferences.rightToWorkLocalCitizen || false
      },
      rightToWorkPermanentCitizen: ${
        data.globalHirePreferences.rightToWorkPermanentCitizen || false
      },
      rightToWorkWorkVisaOrEmploymentPass: ${
        data.globalHirePreferences.rightToWorkWorkVisaOrEmploymentPass || false
      }
    }

  `.trim();

    const payload = {
      query: `mutation {
        updateJob(input: {${input}}) {
          job {
            ${jobAttributes}
          }
        }
     }
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.updateJob) {
            const job = response?.data?.data?.updateJob?.job;

            if (job !== null) {
              return dispatch({
                type: types.MUTATE_UPDATE_JOB_SUCCEED,
                updateJob: job
              });
            } else {
              return dispatch({
                type: types.MUTATE_UPDATE_JOB_FAILED,
                error: response?.data
              });
            }
          }
        }
        let error = response.data?.errors[0].message?.includes('Parse error')
          ? `The backslash ${'\\'} character is not supported. Please use a different character.`
          : response.data?.errors[0].message;
        return dispatch({
          type: types.MUTATE_UPDATE_JOB_FAILED,
          error: error ?? 'Error'
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_UPDATE_JOB_FAILED,
          error: error
        });
      });
  };

const mutatePostJobFeedback =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.POST_JOB_FEEDBACK
    });

    const data = params.input;

    const inputs = `
    rating: ${data.rating ? data.rating : ''},
    feedback: ${JSON.stringify(data.feedback || '')}
    `;

    const payload = {
      query: `mutation{
        companyFeedbackForm(input: {${inputs}}) {
          success
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.companyFeedbackForm) {
            const postJobFeedback = response.data.data.companyFeedbackForm;

            return dispatch({
              type: types.POST_JOB_FEEDBACK_SUCCEED,
              success: postJobFeedback.success
            });
          }
        }
        return dispatch({
          type: types.POST_JOB_FEEDBACK_FAILED,
          error: response.data.errors[0].message
        });
      })
      .catch(error => {
        return dispatch({
          type: types.POST_JOB_FEEDBACK_FAILED
        });
      });
  };

const mutateTalentSearchFeedback =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.TALENT_SEARCH_FEEDBACK
    });

    const data = params.input;

    const inputs = `
    rating: ${data.rating ? data.rating : false},
    feedback: ${JSON.stringify(data.feedback || '')}
    searchTerm: ${JSON.stringify(data.searchTerm || '')}
    `;

    const payload = {
      query: `mutation{
        talentSearchFeedbackForm(input: {${inputs}}) {
          success
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.talentSearchFeedbackForm) {
            const talentFeedback = response.data.data.talentSearchFeedbackForm;

            return dispatch({
              type: types.TALENT_SEARCH_FEEDBACK_SUCCEED,
              success: talentFeedback.success
            });
          }
        }
        return dispatch({
          type: types.TALENT_SEARCH_FEEDBACK_FAILED,
          error: response.data.errors[0].message
        });
      })
      .catch(error => {
        return dispatch({
          type: types.TALENT_SEARCH_FEEDBACK_FAILED
        });
      });
  };

export {
  updateContactSalesPopupStatus,
  updateExpiryDatesPopupStatus,
  updateAvailableResourcesCardIndex,
  viewPackageSubscriptionDetails,
  updateAnnouncementPopup,
  updateAnnouncementBanner,
  viewAllSortedPackages,
  viewSortedJobSlotsAndPrepJobSlotsPackages,
  updateJobAccessPopup,
  updateVirtualInterviewPopup,
  updateRefresherJobPopup,
  refreshJob,
  updateFeaturedJobPopup,
  featureJob,
  updateDeactivateJobPopup,
  updateActivateJobPopup,
  updateActivateJobOptionPopup,
  updateJobOptionPopup,
  changeJobStatus,
  fetchJobs,
  updateAllJobs,
  updateSelectedFilter,
  updateSearchJobValue,
  updateJobsOffset,
  updateJobsCurrentPage,
  updateSelectedFilterValue,
  updateChipStatusIndex,
  updateTotalJobsCount,
  updateExpiryDatesColumnIndex,
  updateProductTypeIndex,
  updateProductActivationDateIndex,
  updateProductExpiryDateIndex,
  updateProductDaysRemaining,
  updateExpiryDatesPopupContentIndex,
  updateSelectedProductName,
  resetAvailableResources,
  updateSubjectType,
  fetchAllInactiveJobs,
  fetchFeaturableJobs,
  fetchRefreshableJobs,
  fetchSpotlightableJobs,
  bulkFeatureJobs,
  bulkRefreshJobs,
  bulkSpotlightJobs,
  updateFeatureTokensCount,
  updateCrossBorderTokensCount,
  updateRefresherTokensCount,
  updatePostJobPopup,
  fetchJobTitleSuggestions,
  updateJobTitleSuggestionsDropdownStatus,
  updateJobSkills,
  fetchJobSkillsSuggestions,
  updateJobTitleSuggestions,
  updateJobSkillSearch,
  updateJobSkillDropdownStatus,
  updatePostJobTooltipStatus,
  updatePostJobPrevTooltip,
  updateSpecialisationPopoverStatus,
  updateSpecialisationSubcategories,
  updateKeywordHighlightPopupStatus,
  updateSpotlightJobConfirmationPopup,
  updateCrossBorderConfirmationPopup,
  updateKeywordHighlightConfirmationPopup,
  spotlightJob,
  updateSpotlightTokensCount,
  keywordingJob,
  updateKeywordHighlightTexts,
  updateJob,
  fetchSpecialisation,
  updateAshleyRewritePopup,
  getAshleyTextRewrite,
  updateAshleyRewriteOrigin,
  updateJobDataForAshleyRewrite,
  updateTmpEditorState,
  updateOldEditorState,
  updateAshleyRewriteMessage,
  updateJobData,
  fetchJob,
  fetchAllJobs,
  updateAshleyEditFeedback,
  sendAshleyEditFeedback,
  fetchDeactivateReasons,
  fetchPersonalizedQuestions,
  fetchJobPersonalizedQuestions,
  fetchDraftJob,
  fetchJobTeamMembers,
  fetchTeamMembers,
  mutateVirtualInterviewQuestions,
  mutateJobAccess,
  mutateJobApplicationState,
  mutateViewedJobApplication,
  mutateCreateJob,
  mutateCreateDraftJob,
  updateDraftJobState,
  updateJobKeywords,
  updateTempJobDraft,
  updateJobDataForm,
  clearJobInfo,
  mutateCrossBorder,
  mutateGlobalHire,
  mutateCrossBorderAndGlobalHire,
  mutateUpdateJob,
  mutatePostJobFeedback,
  mutateTalentSearchFeedback
};
