import React, { Fragment, useEffect, useState } from 'react';
import {
  Text,
  HeightSpacer,
  Spacer,
  AssignTokensContainer,
  CardStyled,
  ConfirmButtonContainer,
  JobsPostingContainer,
  ApplicantCountContainer,
  CardContentContainer,
  RemainingCountContainer,
  CountContainer,
  JobCardDetails,
  TokensContainer
} from './styles';
import { SharedButton } from '../SharedButton';
import { Skeleton } from '@mui/material';
import SharedBackButton from '../SharedBackButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  bulkFeatureJobs,
  bulkRefreshJobs,
  bulkSpotlightJobs,
  fetchFeaturableJobs,
  fetchRefreshableJobs,
  fetchSpotlightableJobs,
  updateExpiryDatesPopupContentIndex,
  fetchJobs,
  updateFeatureTokensCount,
  updateRefresherTokensCount,
  updateSpotlightTokensCount
} from '../../../../../redux/actions/job_action';
import { SuccessToast } from '../../../../../utils/ToastUtils';
import Checkbox from '@mui/material/Checkbox';
import store from '../../../../../redux/stores/store';
import { Spinner } from '../../../../../components';
import moment from 'moment';

const AssignTokenPopup = ({ onCloseModal }) => {
  const dispatch = useDispatch();

  const fetchingFeaturableJobs = useSelector(
    state => state.jobs.fetchingFeaturableJobs
  );
  const fethingRefreshableJobs = useSelector(
    state => state.jobs.fethingRefreshableJobs
  );
  const fetchingSpotlightableJobs = useSelector(
    state => state.jobs.fetchingSpotlightableJobs
  );

  const expiryDatesContentIndex = useSelector(
    state => state.jobs.expiryDatesPopupContentIndex
  );
  const productName = useSelector(state => state.jobs.selectedProduct);
  const featuredTokensUsableCount = useSelector(
    state => state.jobs.subscribedFeaturedTokensPackage?.total_usable_quantity
  );
  const refresherTokensUsableCount = useSelector(
    state => state.jobs.subscribedRefresherTokensPackage?.total_usable_quantity
  );
  const spotlightTokensUsableCount = useSelector(
    state => state.jobs.subscribedSpotlightTokensPackage?.total_usable_quantity
  );

  const featurableJobs = useSelector(state => state.jobs.featurableJobs);
  const refreshableJobs = useSelector(state => state.jobs.refreshableJobs);
  const spotlightableJobs = useSelector(state => state.jobs.spotlightableJobs);

  const bulkJobsFeaturing = useSelector(state => state.jobs.bulkJobsFeaturing);
  const bulkJobsRefreshing = useSelector(
    state => state.jobs.bulkJobsRefreshing
  );
  const bulkJobsSpotlighting = useSelector(state => state.jobs.bulkJobsSpotlighting);

  const selectedFilter = useSelector(state => state.jobs.selectedFilter);
  const searchJobValue = useSelector(state => state.jobs.searchJobValue);
  // const jobsCurrentPage = useSelector(state => state.jobs.jobsCurrentPage);
  const jobsPageOffset = useSelector(state => state.jobs.jobsPageOffset);

  const getJobList = () => {
    if (productName == 'featured tokens') {
      return featurableJobs;
    } else if (productName == 'refresher tokens'){
      return refreshableJobs;
    } else {
      return spotlightableJobs;
    }
  };

  const [checked, setChecked] = useState([]);

  const navigateToExpiryDates = () => {
    dispatch(updateExpiryDatesPopupContentIndex(-1));
    store.getState().jobs.featurableJobs = [];
  };

  const onClearSelection = () => {
    setChecked([]);
  };

  const allowJobSelection = () => {
    if (productName == 'featured tokens') {
      return featuredTokensUsableCount - checked.length > 0;
    } else if (productName == 'refresher tokens'){
      return refresherTokensUsableCount - checked.length > 0;
    } else {
      return spotlightTokensUsableCount - checked.length > 0;
    }
  };

  const handleCheckboxChange = event => {
    const selectedCheckboxes = [...checked];

    const checkboxIndex = selectedCheckboxes.indexOf(event.target.value);

    if (checkboxIndex > -1) {
      selectedCheckboxes.splice(checkboxIndex, 1);
    } else {
      if (allowJobSelection()) {
        selectedCheckboxes.push(event.target.value);
      }
    }

    setChecked(selectedCheckboxes);
  };

  const disableButton = () => {
    //need to cater for fetching also
    if (
      checked?.length <= 0 ||
      fetchingFeaturableJobs ||
      fethingRefreshableJobs ||
      fetchingSpotlightableJobs
    ) {
      return true;
    }
  };

  const sendBulkJobs = () => {
    if (productName == 'featured tokens') {
      dispatch(bulkFeatureJobs({ jobsId: checked })).then(res => {
        if (res.type == 'BULK_FEATURE_JOBS_SUCCEED') {
          getJobs();
          dispatch(
            updateFeatureTokensCount(
              featuredTokensUsableCount - checked?.length
            )
          );
          SuccessToast(
            `Feature ${checked?.length > 1 ? 'Jobs' : 'Job'} Successful!`
          );
          onCloseModal();
        }
      });
    } else if (productName == 'refresher tokens') {
      dispatch(bulkRefreshJobs({ jobsId: checked })).then(res => {
        if (res.type == 'BULK_REFRESH_JOBS_SUCCEED') {
          getJobs();
          dispatch(
            updateRefresherTokensCount(
              refresherTokensUsableCount - checked?.length
            )
          );
          SuccessToast(
            `Refresh ${checked?.length > 1 ? 'Jobs' : 'Job'} Successful!`
          );
          onCloseModal();
        }
      });
    } else if (productName == 'spotlight jobs') {
      dispatch(bulkSpotlightJobs({ jobsId: checked })).then(res => {
        if (res.type == 'BULK_SPOTLIGHT_JOBS_SUCCEED') {
          getJobs();
          dispatch(
            updateSpotlightTokensCount(
              spotlightTokensUsableCount - checked?.length
            )
          );
          SuccessToast(
            `Spotlight ${checked?.length > 1 ? 'Jobs' : 'Job'} Successful!`
          );
          onCloseModal();
        }
      });
    }
  };

  const getJobs = () => {
    const params = {
      first: 5,
      offset: jobsPageOffset,
      jobTitle: searchJobValue,
      ...{ isActive: selectedFilter === 'All' ? null : selectedFilter },
      ...{ getCount: selectedFilter === 'All' ? true : false },
      ...{
        orders: null
      }
    };

    dispatch(fetchJobs(params));
  };

  useEffect(() => {
    if (productName == 'featured tokens') {
      dispatch(fetchFeaturableJobs());
    } else if (productName == 'refresher tokens') {
      dispatch(fetchRefreshableJobs());
    } else if (productName == 'spotlight jobs') {
      dispatch(fetchSpotlightableJobs());
    }
  }, [productName]);

  return (
    <AssignTokensContainer
      expiry_dates_content_index={`${expiryDatesContentIndex}`}>
      <SharedBackButton onClick={navigateToExpiryDates} />

      <HeightSpacer height={'20px'} />

      <div style={{ textAlign: 'center' }}>
        <Text renew_inactive_jobs="true">Assign Tokens</Text>
      </div>

      <HeightSpacer height={'20px'} />

      <TokensContainer>
        <Text subtitle="true">
          {productName == 'refresher tokens' ? (
            <span>
              You initially have{' '}
              <b>{refresherTokensUsableCount} Refresher Tokens</b> to assign.
            </span>
          ) : productName == 'featured tokens' ? (
            <span>
              You initially have{' '}
              <b>{featuredTokensUsableCount} Featured Tokens</b> to assign.
            </span>
          ) : productName == 'spotlight jobs' ? (
            <span>
              You initially have{' '}
              <b>{spotlightTokensUsableCount} Spotlight Tokens</b> to assign.
            </span>
          ) : null}
        </Text>

        <RemainingCountContainer>
          <CountContainer>
            <Text text="true" purple="true" style={{ margin: '0' }}>
              Remaining balance
            </Text>
            <Spacer width={'50px'} />
            <Text count="true" purple="true" style={{ margin: '0' }}>
              {productName === 'refresher tokens'
                ? `${refresherTokensUsableCount - (checked?.length || 0)}`
                : productName === 'spotlight jobs'
                ? `${spotlightTokensUsableCount - (checked?.length || 0)}`
                : `${featuredTokensUsableCount - (checked?.length || 0)}`}
            </Text>
          </CountContainer>
        </RemainingCountContainer>
      </TokensContainer>

      <HeightSpacer height={'20px'} />

      <JobsPostingContainer>
        <HeightSpacer height={'5px'} />

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <Text style={{ margin: '0' }}>
            <b>
              {' '}
              Select the job postings that you would like to{' '}
              {productName == 'refresher tokens' ? 'refresh' : productName == 'spotlight jobs' ? 'spotlight' : 'feature'}{' '}
            </b>
          </Text>
          <Text clear="true" onClick={onClearSelection}>
            Clear
          </Text>
        </div>

        <HeightSpacer height={'15px'} />

        {fetchingFeaturableJobs || fethingRefreshableJobs || fetchingSpotlightableJobs ? (
          <div>
            {Array.from(['0', '1', '2', '3', '4', '5', '6', '7']).map(
              (item, index) => {
                return (
                  <Fragment key={index}>
                    <Skeleton variant="text" width={'100%'} />
                    <HeightSpacer height={'10px'} />
                  </Fragment>
                );
              }
            )}
          </div>
        ) : (
          getJobList().map((job, index) => {
            return (
              <Fragment key={job?.id}>
                <CardStyled key={index}>
                  <Spacer width="8px" />

                  <Checkbox
                    disableRipple
                    checked={checked.includes(job?.id)}
                    onChange={handleCheckboxChange}
                    value={job?.id}
                    style={{
                      color: checked.includes(job?.id) ? '#512acc' : '#aeaeae',
                      padding: '0 10px',
                      transform: 'scale(1.4)',
                      backgroundColor: 'transparent'
                    }}
                  />

                  <Spacer width="20px" />

                  <CardContentContainer>
                    <div>
                      <Text card_title="true">{job?.title}</Text>
                      <HeightSpacer height={'5px'} />
                      <JobCardDetails>
                        <Text card_desc="true">{job?.trackTitle} |</Text>
                        <Spacer width={'5px'} />
                        <Text card_desc="true">{job?.jobTypeTitle} |</Text>
                        <Spacer width={'5px'} />
                        <Text card_desc="true">
                          Expiry: {moment(job?.expiredAt).format('DD MMM YYYY')}
                        </Text>
                      </JobCardDetails>
                    </div>

                    <ApplicantCountContainer>
                      <Text applicant_count="true"> {job?.totalCount} </Text>
                      <HeightSpacer height={'5px'} />
                      <Text applicant_text="true">Applicants</Text>
                    </ApplicantCountContainer>
                  </CardContentContainer>
                </CardStyled>
                <HeightSpacer height={'10px'} />
              </Fragment>
            );
          })
        )}
      </JobsPostingContainer>

      <HeightSpacer height={'40px'} />

      <ConfirmButtonContainer>
        <SharedButton
          type="basic"
          min_width={'240px'}
          font_size={'16px'}
          onClick={sendBulkJobs}
          disabled={disableButton()}>
          {bulkJobsFeaturing || bulkJobsRefreshing || bulkJobsSpotlighting ? (
            <Spinner size="XS" color="white" noPadding={true} />
          ) : (
            'Confirm'
          )}
        </SharedButton>
      </ConfirmButtonContainer>
    </AssignTokensContainer>
  );
};

export default AssignTokenPopup;
