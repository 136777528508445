import { useDispatch, useSelector } from 'react-redux';
import {
  BundleCards,
  ButtonStyled,
  DefaultContainer,
  DefaultSpan,
  DefaultTypography,
  DiscountBanner,
  MaxText,
  PlusText,
  TitleContainer
} from './styles';
import { fetchCart, mutateCart } from '../../redux/actions/order_action';
import { ErrorToast } from '../../utils/ToastUtils';
import { useNavigate } from 'react-router-dom';
import { accData } from '../../utils/LocalStorageUtils';
import { getCurrency, getCurrenyCode } from '../../hooks/useRegionalisation';

export const BundleCard = props => {
  const {
    amount,
    price,
    save,
    annual,
    productCode,
    title,
    id,
    infoDialog,
    isSlot
  } = props.bundle;
  const dispatch = useDispatch();
  const history = useNavigate();

  // This function is specifically made for job slot plus and max
  // It is for the colour text
  // Also change any tokens title to just token
  // e.g. Refresher Token -> Tokens
  const renderTitle = (title, productCode) => {
    if (productCode === 'job_slot_plus') {
      return (
        <TitleContainer>
          {title.replace('Plus', '')} <PlusText>Plus</PlusText>
        </TitleContainer>
      );
    }

    if (productCode === 'job_slot_max') {
      return (
        <TitleContainer>
          {title.replace('Max', '')}
          <MaxText>Max</MaxText>
        </TitleContainer>
      );
    }

    if (!isSlot) {
      return 'Token(s)';
    }

    return title;
  };

  // This is made to identify which annual text to render
  // Per job slot / month OR Per tokens
  // tokens dont need to show "/ month"
  const renderAnnualText = () => {
    if (isSlot) {
      return ' Per Job Slot / month';
    }

    return 'Per Token';
  };

  const addToCartHandler = ({
    id: id,
    amount: amount,
    productCode: productCode
  }) => {
    history(`/purchase/choose/?products=${productCode}-${amount}`);

    if (infoDialog) {
      window.dataLayer.push({
        event: 'CE_add_to_cart_info_popup',
        item: productCode,
        bundle: amount,
        company_name: accData?.companyName,
        industry: accData?.industryName
      });
    } else {
      window.dataLayer.push({
        event: 'CE_add_to_cart_bundles_slide_tab',
        item: productCode,
        bundle: amount,
        company_name: accData?.companyName,
        industry: accData?.industryName
      });
    }
  };

  // Check if there's enough quantity
  const allProducts = useSelector(state => state.products.allProducts);

  const quantityAvailable = allProducts?.find(
    product => product?.productCode === productCode
  )?.quantityAvailable;

  const disableAddToCard = () => {
    if (quantityAvailable === null) {
      return false;
    }

    if (amount > quantityAvailable) {
      return true;
    }

    if (quantityAvailable === 0) {
      return true;
    }

    if (!quantityAvailable) {
      return false;
    }

    return false;
  };

  return (
    <BundleCards infoDialog={infoDialog}>
      <DefaultContainer container>
        <DefaultTypography
          sx={{ fontSize: '24px', fontWeight: '700', lineHeight: '20px' }}>
          {amount}
        </DefaultTypography>
        <DefaultTypography
          sx={{
            fontSize: '12px',
            fontWeight: '400',
            lineHeight: '20px',
            marginLeft: '8px'
          }}>
          {renderTitle(title, productCode)}
        </DefaultTypography>
      </DefaultContainer>

      <DefaultContainer container sx={{ gap: '16px' }}>
        {save && <DiscountBanner>Save {parseInt(save)}%</DiscountBanner>}
        <DefaultContainer container sx={{ textAlign: 'left' }}>
          <DefaultTypography
            sx={{
              color: '#40249B',
              fontSize: '25px',
              fontWeight: '700',
              lineHeight: '32px'
            }}>
            {getCurrency(Math.round(price))}{' '}
            <DefaultSpan
              sx={{
                color: `rgba(0, 0, 0, 0.87)`,
                fontSize: '10px',
                fontWeight: '400'
              }}>
              only
            </DefaultSpan>
            <DefaultTypography
              sx={{
                color: `rgba(0, 0, 0, 0.87)`,
                fontSize: '10px',
                fontWeight: '400',
                lineHeight: '20px',
                letterSpacing: '1px'
              }}>
              {renderAnnualText()} <br></br>
              <DefaultSpan sx={{ fontWeight: '700' }}>
                {' '}
                {getCurrenyCode()} {annual}{' '}
              </DefaultSpan>
              billed {isSlot ? 'annually' : 'only'}
            </DefaultTypography>
          </DefaultTypography>
        </DefaultContainer>

        <ButtonStyled
          disabled={disableAddToCard()}
          onClick={() =>
            addToCartHandler({
              id: id,
              amount: amount,
              productCode: productCode
            })
          }>
          {disableAddToCard() ? 'Sold out' : 'Add to cart'}
        </ButtonStyled>
      </DefaultContainer>
    </BundleCards>
  );
};
