import { styled as styling } from '@mui/material/styles';
import { ClockIcon } from '@heroicons/react/24/solid';

export const ChipContainer = styling('div')((props) => ({
  padding: '6px 12px',
  borderRadius: '30px',
  fontSize: '14px',
  display: 'flex',
  position: 'absolute',
  left: '16px',
  top: '16px',
  gap: '4px',
  alignItems: 'center',
  padding: '2px 8px 2px 4px',
  borderRadius: '4px',
  background: props.disabled ? '#D8D8D8' : 'rgba(241, 201, 60, 0.16)',
  border: props.disabled ? '1px solid #AEAEAE' : '1px solid #F1C93C',
}));


export const ClockIconStyled = styling(ClockIcon)((props) => ({
  height: '16px',
  width: '16px',
  color: props.disabled ? '#707070' :  '#E4BD32' ,
}));

export const ChipText = styling('span')({
  fontFamily: 'Inter',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '1.5px',
  textTransform: 'uppercase',
  color: 'rgba(0, 0, 0, 0.60)',
  textAlign: 'center',
});
