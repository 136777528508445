import React from 'react';
import {
  CandidateCardWrapperSkeleton,
  CandidateNameSkeleton,
  MessageSkeleton,
  DateSkeleton,
  StatusButtonSkeleton
} from './styles';

const SkeletonLoader = () => (
  <CandidateCardWrapperSkeleton>
    <CandidateNameSkeleton variant="text" width="150px" />

    <MessageSkeleton variant="text" width="80%" message_top="true" />
    <MessageSkeleton variant="text" width="80%" message_middle="true" />
    <MessageSkeleton variant="text" width="80%" message_last="true" />

    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <DateSkeleton variant="text" width="100px" />
      <StatusButtonSkeleton variant="text" width="100px" />
    </div>
  </CandidateCardWrapperSkeleton>
);

export default SkeletonLoader;
