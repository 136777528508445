import React, { useEffect, useRef, useState } from 'react';
import {
  BenefitContainer,
  BenefitText,
  ChevronDownIconStyled,
  ChevronUpIconStyled,
  IconListWrapper,
  IconWrapper,
  SetIconButton,
  TextFieldStyled,
  XMarkIconStyled
} from './styles';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '../../../components/IconButton';
import ErrorText from '../../../components/ErrorText';

export default function EditBenefitCards(props) {
  const companyProfile = useSelector(state => state.companies.companyProfile);
  const { benefit, handleChange, handleChangeOrder, removeIcon, index } = props;
  const iconData = useSelector(state => state.companies.icons);
  const [open, setOpen] = useState(false);
  const gridRef = useRef(null);

  const handleClickOutside = event => {
    if (gridRef.current && !gridRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <BenefitContainer>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '8px 12px',
          justifyContent: 'space-between'
        }}>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '4px',
            alignItems: 'center'
          }}>
          <Typography
            sx={{
              width: '16px',
              color: `rgba(0, 0, 0, 0.60)`,
              fontSize: '16px',
              fontWeight: 700
            }}>
            {benefit?.sortOrder}
          </Typography>
          <IconButton
            transparent
            tooltipTitle="Move up"
            disabled={benefit?.sortOrder === 1}
            onClick={() => handleChangeOrder(benefit?.sortOrder, 'up')}>
            <ChevronUpIconStyled disabled={benefit?.sortOrder === 1} />
          </IconButton>
          <IconButton
            transparent
            tooltipTitle="Move down"
            disabled={benefit?.length === index + 1}
            onClick={() => handleChangeOrder(benefit?.sortOrder, 'down')}>
            <ChevronDownIconStyled disabled={benefit?.length === index + 1} />
          </IconButton>
        </Grid>
        <IconButton
          transparent
          tooltipTitle="Remove beneift"
          onClick={() => removeIcon(benefit.sortOrder)}>
          <XMarkIconStyled />
        </IconButton>
      </Grid>
      <Divider />
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          padding: '12px 16px 16px 16px'
        }}>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            position: 'relative'
          }}>
          <BenefitText>
            Benefit Icon<span style={{ color: '#BE4242' }}>*</span>
          </BenefitText>
          <SetIconButton name="icon" onClick={() => setOpen(!open)}>
            {benefit.iconCode ? (
              <FontAwesomeIcon
                style={{ height: '24px', width: '24px', color: 'black' }}
                icon={benefit.iconCode || 'image'}
              />
            ) : (
              <Typography
                sx={{
                  fontWeight: '400',
                  textTransform: 'none',
                  fontSize: '14px',
                  color: "#00000068 !important"
                }}>
                Please select
              </Typography>
            )}

            <ChevronDownIconStyled style={{ height: '14px', width: '14px' }} />
          </SetIconButton>
          {open && (
            <IconListWrapper ref={gridRef}>
              {iconData
                .filter(icon => icon?.iconCode !== 'image')
                ?.map(icon => (
                  <IconWrapper
                    selected={benefit?.iconCode === icon?.iconCode}
                    onClick={() => {
                      handleChange(benefit.id, 'iconCode', icon?.iconCode);
                      handleChange(benefit.id, 'iconId', icon?.id);
                      setOpen(false);
                    }}>
                    <FontAwesomeIcon
                      style={{ height: '24px', width: '24px' }}
                      icon={icon.iconCode}
                    />
                  </IconWrapper>
                ))}
            </IconListWrapper>
          )}
        </Grid>
        <Grid>
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '4px'
            }}>
            <BenefitText>
              Benefit Title<span style={{ color: '#BE4242' }}>*</span>
            </BenefitText>
            <BenefitText error={benefit?.title?.length > 75}>
              {benefit?.title?.length}/75
            </BenefitText>
          </Grid>
          <TextFieldStyled
            error={benefit?.title?.length > 75}
            value={benefit?.title}
            placeholder="Type here"
            name="title"
            onChange={e => {
              handleChange(benefit?.id, 'title', e.target.value);
            }}
          />
          {benefit?.title?.length > 75 && (
            <ErrorText>You have exceeded the character limit.</ErrorText>
          )}
        </Grid>

        <Grid>
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '4px'
            }}>
            <BenefitText>
              Benefit Description<span style={{ color: '#BE4242' }}>*</span>
            </BenefitText>
            <BenefitText error={benefit?.description?.length > 150}>
              {benefit?.description?.length}/150
            </BenefitText>
          </Grid>
          <TextFieldStyled
            error={benefit?.description?.length > 150}
            value={benefit?.description}
            placeholder="Type here"
            multiline
            rows={6}
            name="description"
            inputProps={{
              onKeyDown: e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }
            }}
            onChange={e =>
              handleChange(benefit?.id, 'description', e.target.value)
            }
          />
          {benefit?.description?.length > 150 && (
            <ErrorText>You have exceeded the character limit.</ErrorText>
          )}
        </Grid>
      </Grid>
    </BenefitContainer>
  );
}
