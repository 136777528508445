import React from 'react';
import { InputAdornment } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { TextFieldStyled } from './styles';

export const TextInputFunction = props => {
  const { label, value, handleChange, type, placeholder } = props;
  return (
    <>
      <TextFieldStyled
        hiddenLabel
        disabled={false}
        id={`fill-${label}`}
        onChange={handleChange}
        value={value}
        type={type}
        variant="filled"
        InputProps={{
          placeholder: `${
            placeholder !== undefined ? placeholder : 'Search by keyword'
          }`,
          disableUnderline: true,
          style: {
            background: `#fff`,
            borderRadius: '30px',
            height: '52px',
            width: '425px',
            fontSize: '14px',
            fontFamily: 'Inter',
            boxShadow: '0px 3px 20px #BCBCBD3D',
            border: '0.5px solid #E5E5E5',
            padding: '0 27px'
          },
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
    </>
  );
};
