import React from 'react';
import { TwitterVideoEmbed } from 'react-twitter-embed';
import {
  TikTokEmbed,
  InstagramEmbed,
  FacebookEmbed
} from 'react-social-media-embed';

export default function EmbedType(props) {
  const { url } = props;

  if (url.includes('instagram')) {
    return <InstagramEmbed url={url} width={'auto'}  />;
  }

  if (url.includes('tiktok')) {
    return <TikTokEmbed url={url} width={'auto'} />;
  }

  if (url.includes('x.com') || url.includes('twitter.com')) {
    const parts = url.split('/');
    const videoId = parts[parts.length - 1];

    return <TwitterVideoEmbed id={videoId} width={'auto'} />;
  }

  if (url.includes('facebook')) {
    return <FacebookEmbed url={url} width={'400px'} />;
  }

  return null
}
