import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import DefaultLayout from '../../../layouts/DefaultLayout';
import { SuccessToast } from '../../../utils/ToastUtils';
import styles from './index.module.scss';
import SkeletonLoader from './SkeletonLoader.js';
import { Pagination as MuiPagination } from '@mui/material';
import InfoCard from '../JobList/InfoCard';
import {
  LeftContainer,
  ManageJobsButtonWrapper,
  Text,
  TopSection,
  RightContainer
} from './styles';
import { openInNewTab } from '../../../utils/WindowUtils';
import { ContentState, EditorState } from 'draft-js';
import JobListTable from './JobListTable';
import CustomFilter from './CustomFilter';
import ProductStats from './ProductStats';
import store from '../../../../src/redux/stores/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchJobs,
  updateJobsCurrentPage,
  updateJobsOffset,
  updateSelectedFilterValue,
  updateAllJobs,
  updateContactSalesPopupStatus,
  updateSubjectType,
  updateAnnouncementBanner,
  fetchDraftJob,
  updateAshleyRewriteMessage,
  updateJobDataForAshleyRewrite,
  updateJobData,
  updateTempJobDraft,
  updateTmpEditorState,
  updateOldEditorState,
  updateJobDataForm,
  updateJob
} from '../../../redux/actions/job_action';
import moment from 'moment';
import ManageJobsButton from './ManageJobsButton';
import NewProductsPopup from '../PopUps/NewProductsPopUp';
import {
  updateDiscountedCreditsPopupStatus,
  updateNewInfoPopupStatus
} from '../../../redux/actions/product_action';
import DiscountedJobCreditsPopUp from '../PopUps/DiscountedJobCreditsPopup';
import AnnouncementBanner from './AnnouncementBanner';
import Content from './AnnouncementBanner/Content';
import {
  fetchAccountInfo,
  fetchMyCompanySettings,
  fetchMyCompanyStatus
} from '../../../redux/actions/company_actions';
import htmlToDraft from 'html-to-draftjs';
import { getCountry } from '../../../hooks/useRegionalisation.js';
import HomepageCarousel from './HomepageCarousel/index.js';
import { debounce, isEmpty } from 'lodash';
import { parseJwt } from '../../../utils/parseJWT.js';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

let countDownJobs = {};
let enableRefreshJobTimer = null;

export default function JobList() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();
  const scrollTopRef = useRef();
  const fromPage = new URLSearchParams(location.search).get('page');

  const [orderDirection, setOrderDirection] = useState(null);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [debouncedParams, setDebouncedParams] = useState(null);

  const ashleyRewritePopupOrigin = useSelector(
    state => state.jobs.ashleyRewritePopupOrigin
  );

  const showDiscountedCreditsPopup = useSelector(
    state => state.products.showDiscountedCreditsPopup
  );
  const jobsData = useSelector(state => state.jobs.jobs);
  const loading = useSelector(state => state.jobs.fetchingJobs);
  const selectedFilter = useSelector(state => state.jobs.selectedFilter);
  const searchJobValue = useSelector(state => state.jobs.searchJobValue);
  const jobsCurrentPage = useSelector(state => state.jobs.jobsCurrentPage);
  const jobsPageOffset = useSelector(state => state.jobs.jobsPageOffset);
  const draftData = useSelector(state => state?.jobs?.draftJob);
  const fetchingDraftJob = useSelector(state => state?.jobs?.fetchingDraftJob);
  const accountInfo = useSelector(state => state?.companies?.accountInfo);
  const myCompanyStatus = useSelector(
    state => state?.companies?.myCompanyStatus
  );

  const isAnnouncementBannerOpen = useSelector(
    state => state.jobs.isAnnouncementBannerOpen
  );

  const selectedFilterValue = useSelector(
    state => state?.jobs?.selectedFilterValue
  );
  const totalJobsCount =
    store.getState().jobs.totalJobsCount[selectedFilterValue];

  const pageCount = Math.ceil(totalJobsCount / 5);

  const myAccountInfo = useSelector(state => state.companies.accountInfo);
  const permissions = myAccountInfo?.permissions;

  const jobSlotsLeft = myCompanyStatus?.jobSlotsTotalUsableQuantity;
  const disableFreeJobInternship = myCompanyStatus
    ? !myCompanyStatus?.allowToPostFreeInternshipJob
    : true;
  const disableFreeSeniorJob = myCompanyStatus
    ? !myCompanyStatus.allowToPostSuperSeniorJob
    : true;
  const jobToken = myCompanyStatus?.jobToken;
  const unlimitedToken = myCompanyStatus?.unlimitedToken;

  const companyStatus = [
    {
      icon: 'user-friends',
      number: `${
        myCompanyStatus?.unviewedJobApplicationsCount == undefined
          ? ''
          : myCompanyStatus?.unviewedJobApplicationsCount
      }`,
      title: 'New Applicants'
    },
    {
      icon: 'briefcase',
      number: `${
        myCompanyStatus?.activeJobsCount == undefined
          ? ''
          : myCompanyStatus?.activeJobsCount
      }`,
      title: 'Active Jobs'
    },
    {
      icon: 'check',
      number: `${
        myCompanyStatus?.hiredApplicantsCount == undefined
          ? ''
          : myCompanyStatus?.hiredApplicantsCount
      }`,
      title: 'Hired'
    }
  ];

  // useEffect(() => {
  //   const contentBlock = htmlToDraft(`<p></p>`);
  //   const contentState = ContentState.createFromBlockArray(
  //     contentBlock.contentBlocks
  //   );
  //   const tmpState = EditorState.createWithContent(contentState);
  //   let requirementData = {
  //     editorOrigin: 'job_requirements',
  //     state: tmpState
  //   };

  //   let descriptionData = {
  //     editorOrigin: 'job_description',
  //     state: tmpState
  //   };
  //   dispatch(updateTmpEditorState(requirementData));
  //   dispatch(updateOldEditorState(requirementData));

  //   dispatch(updateTmpEditorState(descriptionData));
  //   dispatch(updateOldEditorState(descriptionData));
  //   dispatch(updateJobDataForm({}));
  //   dispatch(updateJob({}));
  // }, []);

  const scrollToTop = () => {
    // to enable smooth scrolling
    if (scrollTopRef?.current) {
      scrollTopRef?.current?.scrollIntoView({
        behavior: 'smooth'
      });
    }
  };

  const handlePagination = (event, page) => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('page')) {
      history('/jobs', { replace: true });
    }
    // Default first:5
    dispatch(updateJobsCurrentPage(page));
    scrollToTop();
    setTimeout(() => {
      dispatch(updateJobsOffset((page - 1) * 5));
    }, 500);
  };

  // Check to enable refresh job feature
  const getDiffInMin = job => {
    if (!myCompanyStatus) return -1;
    // if (accountStatusData.myCompanyStatus?.refresherTokensCount == 0) return -1;
    if (!job) return -1;
    // if (job.boosted) return -1;

    // const job_rankdate = moment
    //   .utc(job.jobRankdate)
    //   .add(8, 'hours')
    //   .format('yyyy-MM-DD HH:mm:ss');

    const diffInMin = 60 - moment().diff(moment(job?.jobRankdate), 'minute');

    if (diffInMin > 60) return -1;
    if (diffInMin <= 0) return -1;

    return diffInMin;
  };

  const checkAndEnableRefreshJob = () => {
    let currentJobs = [...jobsData];

    currentJobs = currentJobs.map(job => {
      let cJob = { ...job };
      if (countDownJobs[job.id]) {
        const diffInMin = getDiffInMin(job);

        if (diffInMin == -1) {
          // Safe to assume if job id exist as key and diffInMin become -1 mean it can now be refreshed
          // To improve, take boosted expired into consideration
          delete countDownJobs[job.id];

          const hasRefreshToken =
            myCompanyStatus?.refresherTokensCount > 0 ? true : false;

          cJob.jobRankdate = moment()
            .subtract(541, 'minutes')
            .format('YYYY-MM-DD HH:mm:ss');

          cJob.enableRefreshJob = hasRefreshToken; // enable job refresh feature if there is refresh token
        }
      }

      return cJob;
    });

    // Trigger redux to update jobs redux param
    dispatch(updateAllJobs(currentJobs));

    if (Object.keys(countDownJobs).length == 0) {
      // Clear interval once all the affected jobs refresh feature are enabled
      clearInterval(enableRefreshJobTimer);
    }
  };

  const setEnableRefreshJobTimer = jobs => {
    let hasCountDownJobs = false;
    countDownJobs = {};
    jobs.map(job => {
      const diffInMin = getDiffInMin(job);
      if (diffInMin != -1) {
        countDownJobs[job.id] = 1;
        hasCountDownJobs = true;
      }
    });

    if (hasCountDownJobs) {
      if (enableRefreshJobTimer) clearInterval(enableRefreshJobTimer);
      enableRefreshJobTimer = setInterval(checkAndEnableRefreshJob, 300000); // 5mins interval
    }
  };
  // END Check to enable refresh job feature

  const onPromoBannerClicked = (type, product = '') => {
    if (type == 'product-page') {
      // history(`/purchase/choose?product=${product}`);
      openInNewTab(`/purchase/choose?product=${product}`);
    } else {
      dispatch(updateSubjectType(`${type}`)).then(() => {
        dispatch(updateContactSalesPopupStatus(true));
      });
    }
  };

  const getAnnoucementStatus = () => {
    const status = localStorage.getItem('hideAnnouncementBanner');

    if (status) {
      return true;
    }

    return false;
  };

  const setHideAnnouncementBannerToTrue = () => {
    if (!localStorage.getItem('hideAnnoucementBanner')) {
      localStorage.setItem('hideAnnouncementBanner', true);
    }
  };

  useEffect(() => {
    dispatch(fetchAccountInfo());
    dispatch(fetchMyCompanySettings());
    dispatch(fetchDraftJob());
    dispatch(updateJobData({}));
    dispatch(updateJobDataForAshleyRewrite({}));
    dispatch(updateAshleyRewriteMessage({}));
    dispatch(updateTempJobDraft([]));
    // permanently hide announcement banner
    setHideAnnouncementBannerToTrue();
  }, []);

  useEffect(() => {
    try {
      const showSavedJobMessage = localStorage.getItem('showSavedJobMessage');
      localStorage.removeItem('showSavedJobMessage');

      if (showSavedJobMessage) {
        SuccessToast(showSavedJobMessage);
      }
    } catch (error) {
      //
    }
  }, []);

  useEffect(() => {
    if (jobsData?.length > 0) {
      setEnableRefreshJobTimer(jobsData);
    }
  }, [jobsData]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      countDownJobs = {};
      clearInterval(enableRefreshJobTimer);
    };
  }, []);

  useEffect(() => {
    if (debouncedParams) {
      dispatch(fetchJobs(debouncedParams));
    }
  }, [debouncedParams]);

  useEffect(() => {
    const params = {
      first: 5,
      offset: jobsPageOffset,
      jobTitle: searchJobValue,
      ...{ isActive: selectedFilterValue === 'All' ? null : selectedFilter },
      //backend is not using getCount
      // ...{ getCount: selectedFilter === 'All' ? true : false },
      ...{
        orders: orderDirection
          ? { by: 'title', direction: orderDirection }
          : null
      }
    };

    // to prevent race condition - eg. jobsPageOffset and selectedFilter are updating at the same time
    const debouncedParams = debounce(() => {
      setDebouncedParams(params);
    }, 300);

    debouncedParams();

    // Cleanup function to cancel the debounced execution on unmount or when dependencies change
    return () => {
      debouncedParams.cancel();
    };
  }, [searchJobValue, selectedFilter, orderDirection, jobsPageOffset]);

  useEffect(() => {
    if (accountInfo) {
      if (jobsData?.length > 0) {
        setEnableRefreshJobTimer(jobsData);
      }
    }
  }, [accountInfo]);

  const accessToken = localStorage.getItem('accessToken');
  const agreeToTerms = parseJwt(accessToken)?.agree_to_terms;
  const originCountry = parseJwt(accessToken)?.origin_country;
  localStorage.setItem('originCountry', originCountry);

  useEffect(() => {
    let hideNewProductsInfo = localStorage.getItem('hideNewProductsInfo');
    setTimeout(() => {
      if (agreeToTerms && !hideNewProductsInfo) {
        dispatch(updateNewInfoPopupStatus(true));
      }
    }, 500);
  }, []);

  useEffect(() => {
    const initialize = () => {
      if (fromPage) {
        dispatch(updateJobsCurrentPage(fromPage));
        dispatch(updateJobsOffset((fromPage - 1) * 5));
      }
    };

    initialize();

    return () => {
      // Reset to active tab, reset offset and pagination
      store.getState().jobs.selectedFilter = true;
      dispatch(updateJobsOffset(0)).then(() => {
        store.getState().jobs.jobsCurrentPage = 1;
        dispatch(updateSelectedFilterValue('active'));
      });
    };
  }, [fromPage, dispatch]);

  useEffect(() => {
    // Annoucement banner in job list
    const status = getCountry() == 'MY' ? true : false;

    dispatch(updateAnnouncementBanner(status));
    //ASHLEY
    dispatch(updateAshleyRewriteMessage({}));
    dispatch(updateJobDataForAshleyRewrite({}));
  }, []);

  function reloadPage() {
    // The last "domLoading" Time //
    var currentDocumentTimestamp = new Date(
      performance.timing.domLoading
    ).getTime();
    // Current Time //
    var now = Date.now();
    // Ten Seconds //
    var tenSec = 10 * 1000;
    // Plus Ten Seconds //
    var plusTenSec = currentDocumentTimestamp + tenSec;
    if (now > plusTenSec) {
      window.location.reload();
    } else {
    }
  }

  const isAnnouncementPopupOpen = useSelector(
    state => state.jobs.isAnnouncementPopupOpen
  );

  const onClickContactUs = () => {
    dispatch(updateContactSalesPopupStatus(true));
  };

  return (
    <DefaultLayout showBanner={false}>
      {isAnnouncementBannerOpen &&
      !isEmpty(accountInfo) &&
      !getAnnoucementStatus() ? (
        <>
          <AnnouncementBanner
            hasContent={true}
            // $margin={'65px 0px 0px 0px'}
            $borderRadius={'8px'}
            $padding={'20px 0px'}
            $display={'flex'}
            $justifyContent={'center'}
            $alignItems={'center'}
            open={isAnnouncementPopupOpen}
            content={<Content actionButton={false} />}
          />
        </>
      ) : null}
      <div className={styles.container}>
        <TopSection>
          <LeftContainer>
            <HomepageCarousel />

            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '33% 33% 33%',
                gap: '0.5%',
                borderRadius: '20px',
                overflow: 'hidden',
                height: "50%"
              }}>
              {companyStatus.map(item => {
                return (
                  <Fragment key={item.title}>
                    <InfoCard item={item} />
                  </Fragment>
                );
              })}
            </div>
          </LeftContainer>

          <RightContainer>
            <ProductStats data={myCompanyStatus} />
          </RightContainer>
        </TopSection>

        <ManageJobsButtonWrapper ref={scrollTopRef}>
          <Text>Manage Jobs</Text>
          <ManageJobsButton
            permissions={permissions}
            draftData={draftData}
            data={myCompanyStatus}
            getJobLoading={fetchingDraftJob}
            jobSlotsLeft={jobSlotsLeft}
            jobToken={jobToken}
            unlimitedToken={unlimitedToken}
            disableFreeSeniorJob={disableFreeSeniorJob}
            disableFreeJobInternship={disableFreeJobInternship}
          />
        </ManageJobsButtonWrapper>
        <div></div>

        <CustomFilter scrollTopRef={scrollTopRef} />

        <div className={styles.bottomContainer}>
          {loading ? (
            <div style={{ marginTop: 20 }}>
              <SkeletonLoader />
            </div>
          ) : (
            <JobListTable
              jobList={jobsData}
              getDiffInMin={getDiffInMin}
              productData={myCompanyStatus}
              getCompanyAccountInfo={fetchMyCompanyStatus}
            />
          )}
        </div>

        {jobsData?.length > 0 && (
          <Grid item>
            <div
              style={{
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '30px 0px 60px'
              }}>
              <MuiPagination
                count={pageCount}
                page={Number(jobsCurrentPage)}
                onChange={handlePagination}
                siblingCount={0}
                boundaryCount={1}
                showLastButton
                disabled={loading}
                style={{ transform: 'scale(1.5)' }}
              />
            </div>
          </Grid>
        )}
      </div>
      <NewProductsPopup />
      <DiscountedJobCreditsPopUp
        open={showDiscountedCreditsPopup}
        handleClose={() => dispatch(updateDiscountedCreditsPopupStatus(false))}
      />
    </DefaultLayout>
  );
}
