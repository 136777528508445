import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../styles/colors.module.scss';

export const FirstContainer = styling('div')({
  padding: '15px 20px',
});

export const SecondContainer = styling('div')({
  // margin: '48px 0',
  padding: '24px',
  display: 'flex',
  justifyContent: 'center',
  gap: '16px',
  height: 'fit-content'
});

export const ActionContainer = styling('div')({
  display: 'flex',
  padding: '16px 24px',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
  borderTop: '1px solid #D8D8D8',
  background: '#FFF',
  position: 'relative',
  borderRadius: '0px 0px 16px 16px',
});

export const ButtonWrapper = styling('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const ButtonContainer = styling('div')({
});

export const Text = styling('p')((props) => ({
  fontFamily: 'Inter',
  textAlign: 'center',
  margin: 0,
  ...(props.type == 'basic' && {
    fontSize: '16px',
    fontWeight: 400,
  }),
  ...(props.type == 'bold' && {
    fontSize: '20px',
    fontWeight: 700,
  }),
}));

export const TextContainer = styling('div')((props) => ({
  ...(props.type == 'message' && {
    margin: '20px 0 0',
  }),
}));

export const ContainerWrapper = styling('div')({
  padding: '25px',
});

export const CardContainer = styling('div')((props) => ({
  width: '24vw',
  minWidth: '305px',
  padding: '32px 24px',
  borderRadius: '16px',
  background: Colors.secLightPurple,
  border: '1px dashed transparent',
  '&:hover': {
    cursor: 'pointer',
  },
  ...(props.$selected == 'deselected' && {
    border: `1px dashed ${Colors.priBlack}`,
    background: 'none',
  }),
  ...(props.$disabled && {
    '&:hover': {
      cursor: 'not-allowed',
    },
  }),
}));

export const CardTitle = styling('h6')({
  color: 'rgba(0, 0, 0, 0.87)',
  fontWeight: 'bold',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: 'normal',
});

export const CardDescription = styling('span')({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px'

});

export const FontAwesomeIconStyled = styling(FontAwesomeIcon)({
  color: Colors.priPurple,
  fontSize: '40px',
});


export const ModalContainer = styling('div')((props) => ({
  width: '675px',
  margin: '0rem auto',
  background: '#FFF',
  borderRadius: '16px',
  border: '1px solid #D8D8D8',
}));

export const ModalTitle = styling('div')(props => ({
  display: 'flex',
  padding: '16px 16px 16px 24px',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch',
  borderBottom: '1px solid #D8D8D8',
  flexDirection: 'column',
  gap: '4px',
  flex: '1 0 0'
}));


export const ModalHeader = styling('div')(props => ({
  color: 'rgba(0, 0, 0, 0.87)',
  textAlign: 'center',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px',
}));

export const ModalText = styling('div')(props => ({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
}));

export const ModalContent = styling('div')(props => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row'
}));
