import { styled as styling } from '@mui/material/styles'

export const QuestionContainer = styling('div')(props => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '3px',
    alignSelf: 'stretch'
}));

export const QuestionTitle = styling('span')(props => ({
  color: props.disabled ? 'rgba(0, 0, 0, 0.60)' : 'rgba(0, 0, 0, 0.38)',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '20px',
}));