import React from 'react';
import {
  AccordionCategoryTitle,
  AccordionContainer,
  AccordionTitle,
  DefaultContainer,
  DottedLine,
  GridStyled,
  LogoTitle,
  LogoTitlePurple,
  StyledAccordion
} from './styles';
import { AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import { CSEMAIL } from '../../../utils/Constants';
import { getCountry } from '../../../hooks/useRegionalisation';

export default function FrequentlyAskedQuestion() {
  const faqData = [
    {
      category: 'FAQ for Job Slot',
      questions: [
        {
          title: 'What is a Job Slot?',
          description:
            'Our Job Slots are designed to offer you maximum flexibility with a one (1) year subscription period. During this time, you can effortlessly post and swap job postings as needed – think of it as having a reserved parking spot for your job listings!'
        },
        {
          title: "Can I switch out a job ad once it's posted with a Job Slot?",
          description:
            "Absolutely! If you've filled a position from the first job posting, you can seamlessly switch it out for your next vacancy. Please keep in mind that only one job can occupy an active job slot at any given time. To switch, simply deactivate the current job before posting a new one."
        },
        {
          title: "What happens to a job once it's advertised?",
          description:
            "Once a job is advertised, it remains active for as long as the job slot subscription is active or until the job is deactivated. There's no need to repost or reactivate the job – it stays visible to potential candidates throughout the subscription period."
        },
        {
          title:
            'Can I deactivate a job before the end of the subscription period?',
          description:
            'Certainly! You have the flexibility to deactivate a job at any time during the subscription period. This allows you to manage your active job listings based on your hiring needs. Remember, only one job can be active in a slot at a time.'
        },
        {
          title: 'How is the billing structured for Job Slots?',
          description:
            'All job slots are billed annually. This means you only need to pay once a year for each slot you have.'
        },
        {
          title: 'Why are Job Slots more expensive than normal ads?',
          description:
            "Actually it's much cheaper! With most job portals, the price you pay is to advertise a job for 1 month. With Hiredly's Job Slots, the price you pay is for 12 months. Within these 12 months, you're allowed to flexibly change the job advertisement in your Job Slot, any time, for any job."
        }
      ]
    },
    {
      category: 'FAQ for Job Slot Plus',
      prefixColor: `linear-gradient(270deg, #8468D9 0%, #966DD5 45.87%, #B376CD 101.73%)`,
      questions: [
        {
          title: 'What is a Job Slot Plus?',
          description:
            'It is the same job slot you have always known, but better! Flexibly post and swap job postings throughout the 12 months, all while having automated visibility boosts!'
        },
        {
          title:
            'What is the difference between a Job Slot Max and a Job Slot Plus?',
          description:
            'Job Slot Plusprovides automated job positioning to ensure it appears at the front of our jobs list and catches the eye of more job seekers. However, The Job Slot Plus does not include exclusive design or increased real estate that comes with a Job Slot Max.'
        },
        {
          title:
            'How can I maximise the visibility of my job with the Job Slot Plus?',
          description: `Apply any of our existing visibility booster tokens on top of your Job Slot Plus to maximise your jobs’ visibility, or simply upgrade your Job Slot Plus to a Job Slot Max. Contact us at ${
            CSEMAIL[getCountry()]
          } or reach out to your dedicated sales consultant to find out how today!`
        },
        {
          title:
            'Do I need to manually refresh my job ad with the Job Slot Plus?',
          description:
            "No need for manual actions! We will take care of maintaining your job's visibility, allowing you to focus on other aspects of your hiring process."
        },
        {
          title:
            "I've already posted a job using a Job Slot. Can I upgrade it to a Job Slot Plus?",
          description:
            "It's simple! Just go to your posted job, click on the edit option, and choose a Job Slot Plus that is currently active and available. Your job will then benefit from the upgraded features, giving your job an added boost."
        },
        {
          title:
            'Is there an additional cost associated with upgrading my job to a Job Slot Plus?',
          description:
            "If you've already purchased a Job Slot Plus separately, there's no extra cost to upgrade an existing job, edit your job and choose the upgraded slot."
        }
      ]
    },
    {
      category: 'FAQ for Job Slot Max',
      prefixColor: `linear-gradient(90deg, #6EC7CD 18.57%, #B0CD72 96.74%)`,
      questions: [
        {
          title:
            'What is a Job Slot Max, and how does it enhance my job visibility?',
          description:
            'The Job Slot Max is our premium job ad designed to ensure your job stands out effortlessly. With an exclusive job ad design and the convenience of automated visibility boosting, attracting talents has never been easier - Enjoy the same features as our classic job slot, including the flexibility to swap and repost job ads anytime throughout the year.'
        },
        {
          title:
            'How does the automated visibility boosting work for Job Slot Max?',
          description:
            'To ensure optimal visibility on your job ad throughout the year, the Job Slot Max will permanently spotlight and apply key highlights to your job in addition to automatically refreshing the ad every 7 days for you. With this, your job will be prioritised when shown to job seekers - allowing you to reach more talent without the hassle.'
        },
        {
          title:
            'What happens if I deactivate a job posted using my Job Slot Max?',
          description:
            'Deactivating a job posted using a Job Slot Max will immediately turn off all automated visibility boosters, and your job will be removed from the job listing. However, your key highlights will be saved, and you can reactivate the job anytime.'
        },
        {
          title: 'Can I upgrade my existing job slot to a Job Slot Max?',
          description: `Yes, you can seamlessly upgrade your current job slot to a Job Slot Max. Simply contact your sales consultant or email us at ${
            CSEMAIL[getCountry()]
          } to find out how!`
        },
        {
          title:
            'Is there a limit to the number of Job Slot Max available, and how can I check the number of available slots?',
          description:
            'Yes, we are only releasing a limited number of Job Slot Max at the moment! Check our product catalogue to find out how many are available for purchase.'
        },
        {
          title: 'I just purchased a Job Slot Max, can I use it immediately?',
          description:
            'New purchases will take a moment to update! Ensure that you save your current job draft, and thereafter, you can easily go back to your job to complete the posting by selecting your new Job Slot Max or simply edit an existing job ad to switch it to a Job Slot Max!'
        },
        {
          title: 'How can I track my jobs’ performance?',
          description: `Easily keep track of your jobs’ view count via your dashboard or alternatively, contact us at ${
            CSEMAIL[getCountry()]
          } for more in-depth insights or assistance!`
        }
      ]
    }
  ];

  return (
    <GridStyled>
      <LogoTitle>
        Frequently Asked <LogoTitlePurple>Questions</LogoTitlePurple>
      </LogoTitle>

      <DefaultContainer container sx={{ flexDirection: 'column', gap: '60px' }}>
        {faqData.map(faq => {
          return (
            <AccordionContainer>
              <AccordionCategoryTitle>{faq.category}</AccordionCategoryTitle>
              {faq.questions.map(question => {
                return (
                  <Accordion
                    title={question.title}
                    description={question.description}
                  />
                );
              })}
            </AccordionContainer>
          );
        })}
      </DefaultContainer>
    </GridStyled>
  );
}

const Accordion = props => {
  const { title, description } = props;
  return (
    <DefaultContainer container sx={{ flexDirection: 'column', gap: '8px' }}>
      <DottedLine />
      <StyledAccordion>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <AccordionTitle>{title}</AccordionTitle>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{description}</Typography>
        </AccordionDetails>
      </StyledAccordion>
    </DefaultContainer>
  );
};
