import React from 'react';
import { PopUpDialog } from '../../../components';
import styles from './index.module.scss';
import { priPurple } from '../../../styles/colors.module.scss';
import { MUIButtonStyled } from '../../../components/MUIButton';
import { Button, Dialog, Divider, Grid, IconButton } from '@mui/material';
import {
  ContactSalesButton,
  ContactSalesTitle,
  PackageGrid,
  PackageSectionTitle
} from '../styles';
import { Close } from '@mui/icons-material';
import { XMarkIcon } from '@heroicons/react/24/solid';
import {
  getCompanyName,
  getCountry,
  getHiredlyBankDetails
} from '../../../hooks/useRegionalisation';
import { CSEMAIL } from '../../../utils/Constants';

const ManualPaymentPopUp = ({ open, handleClose, invoiceNumber }) => {
  const getEmail = () => {
    if (getCountry() == 'MY') {
      return 'finance@hiredly.com';
    } else if (getCountry() == 'SG') {
      return 'finance@sg.hiredly.com';
    }

    return '';
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: '16px'
        }
      }}
      open={open}
      handleClose={handleClose}>
      <PackageGrid
        container
        justifyContent={'space-between'}
        padding={'16px'}
        alignItems={'center'}
        borderBottom={'1px solid #D8D8D8'}>
        <ContactSalesTitle variant="h1">
          🎉 Thank You for Your Purchase!
        </ContactSalesTitle>
        <IconButton onClick={handleClose} sx={{ padding: 0 }}>
          <XMarkIcon width={'24px'} height={'24px'} color="black" />
        </IconButton>
      </PackageGrid>
      <PackageGrid padding={'24px'} borderBottom={'1px solid #D8D8D8'}>
        <PackageSectionTitle variant="body1">
          Invoice <b>#{invoiceNumber}</b> has been sent via email to
          careers@hiredly.com.
        </PackageSectionTitle>
        <br />
        <PackageSectionTitle variant="body1">
          Your purchase will automatically be credited into your account once we
          receive a confirmation of payment.
        </PackageSectionTitle>
        <br />
        <PackageSectionTitle variant="body1" marginBottom={'16px'}>
          Please email the payment slip to{' '}
          <a href={`mailto:${getEmail()}`} style={{ color: priPurple }}>
            {getEmail()}{' '}
          </a>
          .
        </PackageSectionTitle>
        <PackageGrid
          sx={{
            backgroundColor: '#EFEFEF',
            border: '1px solid #D8D8D8',
            borderRadius: '8px'
          }}
          marginBottom={'16px'}
          padding={'16px'}
          container
          flexDirection={'column'}>
          <ContactSalesTitle variant="body1">
            Payment Information{' '}
          </ContactSalesTitle>

          <Divider style={{ margin: '12px 0' }} />

          <Grid display={'flex'}>
            <Grid width={'200px'}>
              <PackageSectionTitle variant="body1">
                <b>Company:</b>
              </PackageSectionTitle>
            </Grid>

            <PackageSectionTitle variant="body1" style={{width: '300px'}}>
              {getHiredlyBankDetails()['company']}
            </PackageSectionTitle>
          </Grid>

          <Grid display={'flex'}>
            <Grid width={'200px'}>
              <PackageSectionTitle variant="body1">
                <b>Bank Name:</b>
              </PackageSectionTitle>
            </Grid>

            <PackageSectionTitle variant="body1">
              {getHiredlyBankDetails()['name']}
            </PackageSectionTitle>
          </Grid>

          <Grid display={'flex'}>
            <Grid width={'200px'}>
              <PackageSectionTitle variant="body1">
                <b>Bank Account Number:</b>
              </PackageSectionTitle>
            </Grid>

            <PackageSectionTitle variant="body1">
              {getHiredlyBankDetails()['accountNumber']}
            </PackageSectionTitle>
          </Grid>

          <Grid display={'flex'}>
            <Grid width={'200px'}>
              <PackageSectionTitle variant="body1">
                <b>SWIFT Code:</b>
              </PackageSectionTitle>
            </Grid>

            <PackageSectionTitle variant="body1">
              {getHiredlyBankDetails()['swiftCode']}
            </PackageSectionTitle>
          </Grid>

        </PackageGrid>
        <PackageSectionTitle variant="body1">
          For general enquiries, please reach out to{' '}
          <a href={`mailto:${CSEMAIL[getCountry()]}`}>
            {CSEMAIL[getCountry()]}
          </a>
          .
        </PackageSectionTitle>
      </PackageGrid>
      <PackageGrid
        container
        justifyContent={'center'}
        alignItems={'center'}
        padding={'16px'}>
        <ContactSalesButton onClick={handleClose}>Got it</ContactSalesButton>
      </PackageGrid>
    </Dialog>
  );
};
export default ManualPaymentPopUp;
