import React, { useEffect, useState } from 'react';
import { FiberManualRecord as FiberManualRecordIcon } from '@mui/icons-material';
import { PopUpDialog } from '../../../../../components';
import Colors from '../../../../../styles/colors.module.scss';
import AccessLevelSelect from './AccessLevelSelect';
import { ErrorToast, SuccessToast } from '../../../../../utils/ToastUtils';
import SkeletonLoader from './SkeletonLoader';
import styles from './index.module.scss';
import { MUIButtonStyled } from '../../../../../components/MUIButton';
import { useDispatch, useSelector } from 'react-redux';
import { mutateCompanyTeamMember } from '../../../../../redux/actions/company_actions';
import { fetchTeamMembers } from '../../../../../redux/actions/job_action';

const AddEditAccessLevelPopUp = ({
  open,
  handleClose,
  id,
  accessLvlData,
  setStep,
  index,
  tableData,
  setTableData
}) => {
  const dispatch = useDispatch();

  const accessLevelList = useSelector(state => state.companies.accessLevels);

  const fetchingAccessLevels = useSelector(
    state => state.companies.fetchingAccessLevels
  );

  const mutatingCompanyTeamMember = useSelector(
    state => state.companies.mutatingCompanyTeamMember
  );

  const mutatedCompanyTeamMember = useSelector(
    state => state.companies.mutatedCompanyTeamMember
  );

  const [selectedAccessLevel, setSelectedAccessLevel] = useState(
    accessLvlData || []
  );

  const onHandleClose = () => {
    setSelectedAccessLevel(accessLvlData);
    handleClose();
  };

  const onSaveAccessLevel = async () => {
    dispatch(
      mutateCompanyTeamMember({
        input: {
          id: id || mutatedCompanyTeamMember?.id,
          accessLevels: selectedAccessLevel
        }
      })
    )
      .then(res => {
        const tmpArray = [...tableData];
        tmpArray[index] = {
          ...tmpArray[index],
          accessLevels: selectedAccessLevel
        };

        setTableData(tmpArray);
        if (res.type === 'MUTATE_COMPANY_TEAM_MEMBER_SUCCEED') {
          SuccessToast(
            `${id ? 'Updated' : 'Added'} Access Level Successfully!`
          );
        }

        if (id) {
          handleClose();
        } else {
          setStep(3);
        }
      })
      .catch(err => {
        ErrorToast(err.message);
      });
  };

  useEffect(() => {
    setSelectedAccessLevel(accessLvlData);
  }, [accessLvlData]);

  return (
    <PopUpDialog
      open={open}
      handleClose={onHandleClose}
      title={`${id ? 'Edit' : 'Add'} Access Level`}>
      <div style={{ padding: '20px 20px' }}>
        <div className={styles.contentContainer}>
          <div className={styles.row}>
            <p className={styles.rowText}>What can this team member do?</p>
            <div
              tabIndex={0}
              role="button"
              onKeyDown={() => {}}
              onClick={() => setSelectedAccessLevel(accessLevelList)}>
              <p className={styles.selectAll}>Select all</p>
            </div>
          </div>
        </div>
        {fetchingAccessLevels ? (
          <SkeletonLoader />
        ) : (
          <div>
            {accessLevelList &&
              accessLevelList.map(item => (
                <AccessLevelSelect
                  key={`${item?.id}--${item?.title}`}
                  item={item}
                  selectedAccessLevel={selectedAccessLevel}
                  setSelectedAccessLevel={setSelectedAccessLevel}
                  accessLevel={accessLevelList}
                />
              ))}
          </div>
        )}

        {!id && (
          <div className={styles.dotContainer}>
            <FiberManualRecordIcon className={styles.dot} />
            <FiberManualRecordIcon
              className={styles.dot}
              style={{ height: 14, width: 14, color: Colors.priPurple }}
            />
            <FiberManualRecordIcon className={styles.dot} />
          </div>
        )}

        <div className={styles.buttonContainer}>
          <MUIButtonStyled
            label="Cancel"
            large
            outlinedBlack
            onClick={onHandleClose}
          />

          <MUIButtonStyled
            onClick={onSaveAccessLevel}
            large
            disabled={mutatingCompanyTeamMember || fetchingAccessLevels}
            label={id ? 'Save' : 'Next'}
          />
        </div>
      </div>
    </PopUpDialog>
  );
};

export default AddEditAccessLevelPopUp;
