import { styled as styling } from '@mui/material/styles';
import { Typography, Grid } from '@mui/material';

export const TitleStyled = styling(Typography)({
  fontWeight: '700',
  fontSize: '14px',
});

export const OuterWrapper = styling(Grid)({
  padding: '0px',
  display: 'flex',
  flexDirection: 'column',
  width: '95%',
  gap: '8px'
});

export const TextFieldWrapper = styling(Grid)({
  width: '100%'
});
