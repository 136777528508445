import { Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';

const AccessLevelSelect = ({
  item,
  selectedAccessLevel,
  setSelectedAccessLevel
}) => {
  const [isChecked, setIsChecked] = useState(
    selectedAccessLevel.some(val => val.id === item.id)
  );

  const onUntickCheckbox = () => {
    const removedArray = [...selectedAccessLevel].filter(
      select => select.id !== item.id
    );
    setSelectedAccessLevel(removedArray);
  };

  const onTickCheckbox = () => {
    setSelectedAccessLevel([...selectedAccessLevel, item]);
  };

  const onToggleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  const onSelect = () => {
    if (!isChecked) {
      onTickCheckbox();
    } else if (isChecked) {
      onUntickCheckbox();
    }
    onToggleCheckbox();
  };

  useEffect(() => {
    setIsChecked(selectedAccessLevel.some(val => val.id === item.id));
  }, [selectedAccessLevel]);

  return (
    <div
      tabIndex={0}
      role="button"
      onKeyDown={() => {}}
      className={styles.selectionContainer}
      onClick={onSelect}>
      <Checkbox
        className={styles.checkBox}
        checked={isChecked}
        color="default"
        onChange={onSelect}
      />
      <p>{item?.title}</p>
    </div>
  );
};

export default AccessLevelSelect;
