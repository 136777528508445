import React from 'react';
import {
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
  TitleContainer,
  IconButtonStyled,
  CloseIconStyled,
  TitleStyled,
  TitleDescStyled
} from './styles';

export default function PopUpDialogNew(props) {
  return (
    <DialogStyled
      open={props?.open}
      onClose={props?.handleClose}
      PaperProps={{
        style: {
          minWidth: '680px'
        }
      }}>
      <DialogTitleStyled>
        <TitleStyled>{props?.title}</TitleStyled>
        <IconButtonStyled aria-label="close" onClick={props?.handleClose}>
          <CloseIconStyled />{' '}
        </IconButtonStyled>
      </DialogTitleStyled>
      <DialogContentStyled dividers>{props?.children}</DialogContentStyled>
      
      {/* <DialogActionsStyled>{props}</DialogActionsStyled> */}
    </DialogStyled>
  );
}
