import { Button } from '@mui/material';
import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../../styles/colors.module.scss';

export const ButtonStyled = styling(Button)((props) => ({
  ...(props.type == 'basic' && {
    borderRadius: '26.5px',
    background: Colors.priPurple,
    alignSelf: 'center',
    color: Colors.priWhite,
    fontWeight: 700,
    padding: props.padding ? props.padding : '8px 38px',
    textTransform: 'capitalize',
    minWidth: props.min_width ? props.min_width : '140px',
    fontSize: props.font_size ? props.font_size : '14px',

    '&:hover': {
      color: Colors.priWhite,
      background: 'rgb(64, 36, 155)',
    },

    ...(props.disabled && {
      background: Colors.terLightGrey,
      color: Colors.priWhite,
    }),

  }),

  ...(props.type == 'outline' && {
    borderRadius: '26.5px',
    border: `1px solid ${Colors.priPurple}`,
    alignSelf: 'center',
    color: Colors.priPurple,
    fontWeight: 700,
    padding: props.padding ? props.padding : '8px 38px',
    textTransform: 'capitalize',
    minWidth: props.min_width ? props.min_width : '140px',
    fontSize: props.font_size ? props.font_size : '14px',

    '&:hover': {
      color: Colors.priWhite,
      background: Colors.priPurple,
    },

    ...(props.disabled && {
      border: `1px solid ${Colors.terLightGrey}`,
      color: Colors.terLightGrey,
    }),
  }),

  ...(props.type == 'outlined_black' && {
    borderRadius: '26.5px',
    background: 'transparent',
    alignSelf: 'center',
    color: Colors.priBlack,
    fontWeight: 700,
    padding: props.padding ? props.padding : '8px 38px',
    border: `2px solid ${Colors.priBlack}`,
    textTransform: 'capitalize',
    minWidth: props.min_width ? props.min_width : '140px',
    fontSize: props.font_size ? props.font_size : '14px',
  }),
}));
