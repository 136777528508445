import axios from 'axios';
import { ErrorToast } from '../utils/ToastUtils';

const apiService = options =>
  new Promise((resolve, reject) => {
    const instance = axios.create();

    instance({ ...options })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });

export async function fetchAPI(methodType, url, body, header = {}, params) {
  const headers = {
    ...header
  };

  // TODO: Might need in the future (GET: params, others: data)
  // let processedBody;
  // if (body) {
  //   const _body = body instanceof Object ? JSON.stringify(body) : body;
  //   processedBody = methodType === 'GET' ? { params: _body } : { data: _body };
  // }

  const options = {
    method: methodType,
    headers,
    url,
    params,
    timeout: 30000,
    data: body
    // ...(body ? processedBody : {})
  };

  let data = null;
  try {
    data = await apiService(options);
  } catch (error) {
    return { success: false };
  }

  return data;
}
