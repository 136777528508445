import { CardActionArea, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { mutateViewTalent } from '../../../../redux/actions/talent_action';
import Colors from '../../../../styles/colors.module.scss';
import { ErrorToast } from '../../../../utils/ToastUtils';
import styles from './index.module.scss';

const useStyles = makeStyles({
  cardContainer: {
    // margin: '25px 25px 0px 25px',
    display: 'flex',
    // padding: '25px',
    boxShadow: '6px 2px 15px -10px rgba(0,0,0,0.53)',
    minHeight: '250px',
    borderRadius: 21,
    flexDirection: 'column',
    justifyContent: 'space-between',

    '&.MuiCardActionArea-root': {
      borderRadius: 21,
      // margin: '0px 20px 25px 0px',
      marginBottom: '15px',
      padding: '25px',
      width: '92%'
      // boxShadow: 5
    }
  }
});

const TalentCard = ({
  id,
  user,
  index,
  isViewed,
  accData,
  uploadResumeAt,
  lastActiveAt,
  isSelected,
  setIsSelected,
  isLastItem,
  talents,
  setTalents,
  scrollToTop,
  getCurrentTalents,
  setCurrentTalents,
  setIsLoadingHiddenData
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onClickTalentCard = async () => {
    try {
      if (
        !isViewed &&
        accData?.activeCvAccessUsed >= accData?.activeCvAccessLimit
      ) {
        // Prevent user from clicking on candidate once limit reached
        const errorMessage = 'Active CV Access Limit Reached';
        ErrorToast(errorMessage);
        return;
      }

      setIsSelected(index);
      scrollToTop();

      window.dataLayer.push({
        event: 'view-applicant-acv',
        user_id: user?.id
      });

      if (!isViewed) {
        setIsLoadingHiddenData(true);
        const res = await dispatch(mutateViewTalent({ input: { userId: '' } }));

        let currentTalents = [...getCurrentTalents()];
        currentTalents[index] = {
          ...currentTalents[index],
          node: {
            ...res.viewTalent.user
          }
        };

        setCurrentTalents(currentTalents);
        setTalents(currentTalents);
        setIsLoadingHiddenData(false);
      }
    } catch (err) {
      ErrorToast(err.message);
    }
  };

  let backgroundColor = null;
  let border = null;

  if (isSelected) {
    if (isViewed) {
      backgroundColor = '#F7F7F8';
      border = `2.5px solid ${Colors.priPurple}`;
    } else {
      backgroundColor = Colors.priWhite;
      border = `2.5px solid ${Colors.priPurple}`;
    }
  } else if (isViewed) {
    backgroundColor = '#F7F7F8';
    border = null;
  } else if (!isViewed) {
    backgroundColor = Colors.priWhite;
    border = `1px solid ${Colors.priBlack}`;
  }

  return (
    <CardActionArea
      onClick={onClickTalentCard}
      className={classes.cardContainer}
      style={{ backgroundColor, border, marginBottom: isLastItem ? 40 : null }}>
      {!isViewed && (
        <Tooltip title="Unviewed" placement="bottom">
          <div className={styles.viewedStatusWrapper}>
            <div className={styles.indicator} />
          </div>
        </Tooltip>
      )}
      <div className={styles.cardBodyContainer}>
        <div className={styles.titleContainer}>
          <p className={styles.title}>{user?.name}</p>
          {/* {!isViewed && (
            <FiberManualRecordIcon
              style={{ color: Colors.priPurple, height: 15, width: 15 }}
            />
          )} */}
        </div>
        <p className={styles.subTitle}>{user?.shortSummary}</p>
      </div>
      <div className={styles.cardFooterContainer}>
        <div className={styles.leftFooter}>
          <p className={styles.leftFooterTitle}>Resume uploaded on:</p>
          <p className={styles.leftFooterTitle}>Last active on</p>
        </div>
        <div>
          <p>{moment(uploadResumeAt).format('DD MMMM YYYY')}</p>
          <p>{moment(lastActiveAt).format('DD MMMM YYYY')}</p>
        </div>
      </div>
    </CardActionArea>
  );
};
export default TalentCard;
