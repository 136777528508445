import React from 'react';
import { useParams } from 'react-router-dom';
import DefaultLayout from '../../../../layouts/DefaultLayout';
import JobAccessPopUpNew from '../../PopUps/JobAccessPopUpNew';

import {
  RightBox,
  RightBoxInner,
  Title,
  TitleBox,
  Subtitle,
  Container,
  StepsContainer,
  Steps,
  StepsText,
  PurpleLineActive,
  PurpleLine
} from './styles';

const JobAccessNew = () => {
  const params = useParams();

  return (
    <DefaultLayout maxWidth={true}>
      <Container>
        <StepsContainer>
          <PurpleLine />
          <PurpleLineActive />

          <Steps>
            <StepsText>Job posted successfully 🎉</StepsText>
          </Steps>

          <Steps>
            <StepsText>Maximise job visibility</StepsText>
          </Steps>

          <Steps>
            <StepsText>Set up screening questions</StepsText>
          </Steps>

          <Steps>
            <StepsText active>Assign job access</StepsText>
          </Steps>
        </StepsContainer>

        <RightBox>
          <RightBoxInner>
            <TitleBox>
              <Title>Assign job access</Title>
              <Subtitle>
                Which team member has access to manage this job?
              </Subtitle>
            </TitleBox>

            <JobAccessPopUpNew useAsPopUp={false} jobId={params?.id} open />
          </RightBoxInner>
        </RightBox>
      </Container>
    </DefaultLayout>
  );
};

export default JobAccessNew;
