import Slider from 'react-slick';
import { styled as styling } from '@mui/material/styles';
import { Grid } from '@mui/material';

export const SliderStyled = styling(Slider)(props => ({
  height: '100% !important',
  width: '100%',
  borderRadius: '16px',
  overflow: 'hidden'
}));

export const CarouselDots = styling(Grid)(props => ({
  height: '8px',
  width: '8px',
  backgroundColor: props?.highlight ? '#007AFF' : '#AEAEAE',
  borderRadius: '100px',
  transition: "all 0.3s"
}));

export const CarouselDotsContainer = styling(Grid)(props => ({
  width: '100%',
  position: 'absolute',
  bottom: '10px',
  display: 'flex',
  justifyContent: 'center',
  gap: '10px'
}));
