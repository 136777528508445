import { Dialog, Divider, Grid } from '@mui/material';
import React from 'react';
import {
  ButtonSkipEdit,
  ContentText,
  DialogActionsStyled,
  DialogButton,
  DialogContentStyled,
  DialogTitleStyled,
  IconButtonStyled,
  LinkText
} from './styles';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useDispatch, useSelector } from 'react-redux';
import { updateProductInactivePopupStatus } from '../../redux/actions/product_action';
import { CSEMAIL } from '../../utils/Constants';
import { getCountry } from '../../hooks/useRegionalisation';

export default function ProductUnavailablePopUp(props) {
  const dispatch = useDispatch();
  const isProductInactiveOpen = useSelector(
    state => state.products.isProductInactiveOpen
  );

  const handleClose = () => {
    dispatch(updateProductInactivePopupStatus(false));
  };

  const getContent = () => {
    return (
      <Grid>
        {props?.redirect ? (
          <ContentText>
            Some item(s) previously left in your cart are no longer available.
            <br></br>
            <br></br>
            We’ve gone ahead and removed it for you.
          </ContentText>
        ) : (
          <ContentText>
            Explore our other hiring solutions to refine your recruitment
            journey!
            <br></br>
            <br></br>
            Need help? Contact us at{' '}
            <LinkText>{CSEMAIL[getCountry()]}</LinkText> for assistance.
          </ContentText>
        )}
      </Grid>
    );
  };

  return (
    <Dialog
      open={isProductInactiveOpen}
      handleClose={handleClose}
      sx={{ maxWidth: '600px', margin: 'auto' }}>
      <DialogTitleStyled>
        Product not available.
        <IconButtonStyled onClick={handleClose}>
          <XMarkIcon height={24} width={24} />
        </IconButtonStyled>
      </DialogTitleStyled>
      <Divider />

      <DialogContentStyled>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          {getContent()}
          <DialogActionsStyled>
            <DialogButton>
              <ButtonSkipEdit onClick={handleClose}>Got it</ButtonSkipEdit>
            </DialogButton>
          </DialogActionsStyled>
        </div>
      </DialogContentStyled>
    </Dialog>
  );
}
