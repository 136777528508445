import React from 'react';
import { MenuItem, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CANDIDATES_SORT_BY } from '../../../../../utils/Constants';
import styles from './index.module.scss';

const useStyles = makeStyles({
  root: {
    height: 30,
    minWidth: 150,
    textAlign: 'center',
    padding: '0px 10px',
    '& .MuiSelect-select': {
      fontSize: 16,
      fontFamily: 'InterBold'
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent'
    }
  }
});

const CandidateListSort = ({
  sortAnchorEl,
  setSortAnchorEl,
  handleCandidateSort,
  selectedIndex
}) => {
  const classes = useStyles();

  const handleCloseSort = () => {
    setSortAnchorEl(null);
  };

  const options = [
    {
      sortBy: 'Date Applied',
      value: `${CANDIDATES_SORT_BY.DATE_APPLIED}`
    },
    {
      sortBy: 'Newest first',
      value: `${CANDIDATES_SORT_BY.LATEST}`
    },
    {
      sortBy: 'Oldest first',
      value: `${CANDIDATES_SORT_BY.EARLIEST}`
    }
  ];

  return (
    <div>
      <Popover
        MenuProps={{
          PaperProps: {
            className: classes.popover
          }
        }}
        anchorEl={sortAnchorEl}
        open={Boolean(sortAnchorEl)}
        onClose={handleCloseSort}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
        {options.map((option, index) => {
          return (
            <MenuItem
              key={index}
              disabled={index === 0}
              selected={index === selectedIndex}
              onClick={event => handleCandidateSort(event, index)}
              value={option.value}>
              <p
                className={
                  option.value == `${CANDIDATES_SORT_BY.DATE_APPLIED}`
                    ? styles.menuItemBold
                    : styles.menuItem
                }>
                {option.sortBy}
              </p>
            </MenuItem>
          );
        })}
      </Popover>
    </div>
  );
};

export default CandidateListSort;
