import React from 'react';
import { AlertStyled, TimeStyled, TypographyStyled } from './styles';
import moment from 'moment';
import StatusTag from '../../../components/StatusTag';
import { Grid } from '@mui/material';
import {
  fetchCompanyProfile,
  mutateCompanyProfile
} from '../../../../../redux/actions/company_actions';
import { useDispatch } from 'react-redux';

export default function StatusBanner({
  content,
  index,
  id,
  handleChangeBanner
}) {
  const dispatch = useDispatch();
  const states = [
    {
      status: 'In Review',
      backgroundColor: `rgba(241, 201, 60, 0.16)`,
      text: `Your recent changes is currently in review`,
      time: moment(content?.updatedAt).format('MM/DD/YYYY HH:mm'),
      tag: <StatusTag status={content?.status} companyVideo />
    },
    {
      status: 'Rejected',
      backgroundColor: `rgba(190, 66, 66, 0.16)`,
      text: 'Your content has been rejected, please check your email for further details.',
      tag: <StatusTag status={content?.status} companyVideo />
    },
    {
      status: 'Live on Hiredly',
      backgroundColor: `rgba(144, 185, 56, 0.16)`,
      text: 'Your content has been approved and is now live on the Jobseeker site.',
      tag: <StatusTag status={content?.status} companyVideo />
    }
  ];

  const target = states?.find(item => {
    return item?.status === content?.status;
  });

  const closeIcon = () => {
    handleChangeBanner({ index: index - 1, tab: content?.id });

    dispatch(
      mutateCompanyProfile({
        input: {
          videos: [{ id: id, activeBanner: false }]
        }
      })
    );
  };

  return (
    <AlertStyled
      icon={false}
      onClose={target?.status === 'In Review' ? null : () => closeIcon()}
      sx={{ backgroundColor: target?.backgroundColor, borderRadius: '8px' }}>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '12px'
        }}>
        {target?.tag}{' '}
        <TypographyStyled>
          {target?.text}{' '}
          {target?.time && (
            <TimeStyled>Request submitted {target?.time}</TimeStyled>
          )}
        </TypographyStyled>
      </Grid>
    </AlertStyled>
  );
}
