import { alpha, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';

export const MessageContainer = styled(Grid)(({ theme, width, $danger }) => ({
  display: 'flex',
  justifyContent: 'start',
  width: width ?? '100%',
  gap: '8px',
  padding: '16px',
  border: '1px solid',
  borderRadius: '8px',
  borderColor: $danger ? theme.palette.red.primary : theme.palette.green.primary,
  backgroundColor: alpha($danger ? theme.palette.red.primary : theme.palette.green.primary, 0.15),

  fontSize: '14px',
  'p': {
    margin: 0,
    lineHeight: '24px',
  },
}));

export const XCircle = styled(XCircleIcon)(({ theme }) => ({
  width: '24px',
  height: '24px',
  color: theme.palette.red.primary
}));

export const CheckCircle = styled(CheckCircleIcon)(({ theme }) => ({
  width: '24px',
  height: '24px',
  color: theme.palette.green.primary
}));