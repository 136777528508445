import { ShieldExclamationIcon } from '@heroicons/react/24/outline';
import { Grid, Typography } from '@mui/material';
import { styled as styling } from '@mui/material/styles';
// import { Swiper } from 'swiper/react';

// export const SwiperStyled = styling(Swiper)(props => ({
//   margin: '0 0 20px',
//   boxShadow: '0px 28px 23px rgba(0, 0, 0, 0.05)',
//   borderRadius: '34px',
//   background: '#fff',
//   maxHeight: '250px'
// }));

export const ImageStyled = styling('img')(props => ({
  height: 'auto',
  width: '100%',
  maxHeight: '250px',
  display: 'block',
  aspectRatio: '1600 / 500',
  borderRadius: "24px",
  marginRight: "20px"
}));

export const LeftContainer = styling('div')(props => ({
  width: '50%',
  display: "flex",
  height: "100%",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "20px",

  '@media (max-width: 1280px)': {
    width: '100%'
  }
}));

export const RightContainer = styling('div')(props => ({
  width: '50%',
  display: 'flex',

  '@media (max-width: 1280px)': {
    width: '100%'
  }
}));

export const TopSection = styling('div')(props => ({
  // display: 'grid',
  // gridTemplateColumns: '48% auto',
  // gap: '30px',
  // '@media (min-width: 1280px)': {
  //   display: 'grid',
  //   gridTemplateColumns: '50% auto',
  //   gap: '30px'
  // },
  // '@media (min-width: 1600px)': {
  //   display: 'flex'
  // }
  display: 'flex',
  margin: '30px 0px 30px',
  gap: 30,

  '@media (max-width: 1280px)': {
    flexWrap: 'wrap'
  }
}));

export const NoticeWrapper = styling('div')(props => ({
  '@media (min-width: 1280px)': {
    width: '48%',
    margin: 'auto auto auto 52%'
  },
  '@media (min-width: 1440px)': {
    width: '48%',
    margin: 'auto auto auto 52%'
  },
  '@media (min-width: 1600px)': {
    width: '50vw',
    margin: 'auto auto auto 45vw'
  }
}));

export const ManageJobsButtonWrapper = styling('div')(props => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '30px 0'
}));

export const HeightSpacer = styling('div')(props => ({
  height: props.height ? props.height : '5px'
}));

export const Text = styling('p')(props => ({
  margin: '0',
  fontFamily: 'Inter',
  fontSize: '25px',
  fontWeight: 700
}));
