import React from 'react';
import { BannerContainer, ErrorIconStyled } from './../styles';
import { Grid } from '@mui/material';
import usePathKey from '../../helper/usePathKey';
import { useFormContext } from '../../context';

export default function TokenBanner() {
  const { showIntern, showSenior, disableIntern, disableSenior } =
    useFormContext();

  let text;
  let position;

  if (showIntern) {
    position = 'intern';
    text =
      'As such, certain fields are not editable. Internship postings will expire after 1 month. This job posting will be taken down if it is not an internship';
  } else if (showSenior) {
    position = 'senior';
    text =
      'As such, certain fields are not editable. Free senior postings will expire after 2 months.';
  }

  const showBanner = disableIntern || disableSenior;

  if (!showBanner) return null;

  return (
    <BannerContainer>
      <ErrorIconStyled />

      <Grid container sx={{ flexDirection: 'column', gap: '10px' }}>
        <h6 style={{ fontSize: '24px' }}>
          This job posting is strictly for {position} positions only.{' '}
        </h6>
        <span style={{ fontSize: '12px' }}>{text}</span>
      </Grid>
    </BannerContainer>
  );
}
