import { styled as styling } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export const Container = styling('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  padding: '48px 100px',
  flexDirection: 'column',
  minHeight: 'calc(100vh - 120px)',
  height: '100%',
  maxWidth: '1440px',
  margin: 'auto'
});

export const ContainerStyled = styling(Box)(props => ({
  display: 'flex',
  padding: '0px 40px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '120px',
  flex: '1 0 0',
  alignSelf: 'stretch'
}));

export const RightContainer = styling(Box)(props => ({
  display: 'flex',
  height: '640px',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'flex-end'
}));

export const TitleBox = styling(Box)(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '8px',
  width: '100%'
}));

export const OptionRowBox = styling(Box)(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '16px',
  width: '100%',
  alignSelf: 'stretch'
}));

export const OptionRow = styling(Box)(props => ({
  display: 'flex',
  width: '591px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px'
}));

export const OptionBox = styling(Button)(props => ({
  display: 'flex',
  padding: '12px 16px',
  alignItems: 'flex-start',
  gap: '10px',
  borderRadius: '10px',

  ':hover': {
    background: '#EAEAEA',
    opacity: '1 !important',
    '& p': {
      fontSize: '36px',
      fontWeight: 600,
    }
  }
}));

export const OptionContent = styling(Box)(props => ({
  display: 'flex',
  width: '52px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px'
}));

export const ButtonContainer = styling('div')({
  display: 'flex',
  padding: '16px 0px',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '247px',
  alignSelf: 'stretch'
});

export const Row = styling('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '16px'
});

export const TitleText = styling(Typography)(props => ({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: props.type == 'desc' ? '16px' : '21px',
  fontWeight: props.type == 'desc' ? '400' : '600',
  lineHeight: props.type == 'desc' ? '24px' : '31.5px',
  letterSpacing: props.type == 'desc' ? 'unset' : '0.15px',
  alignSelf: 'stretch'
}));

export const OptionImgContainer = styling(Box)(props => ({
  display: 'flex',
  padding: '4px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px'
}));

export const OptionImg = styling(Typography)(props => ({
  display: 'flex',
  width: '32px',
  height: '32px',
  flexDirection: 'column',
  justifyContent: 'center',
  fontSize: props?.selected ? '36px' : '32px',
  alignItems: 'center'
}));

export const OptionText = styling(Typography)(props => ({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px !important',
  fontWeight: props.selected? 600 : 500,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  textTransform: 'none'
}));

export const NormalText = styling(Typography)({
  color: 'rgba(0, 0, 0, 0.60)',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
});

export const TextFieldContainer = styling(Box)(props => ({
  display: 'flex',
  width: '590px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '4px'
}));

export const TextBox = styling('div')({
  display: 'flex',
  padding: '76px 0px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '42px'
});

export const SubmitButton = styling(LoadingButton)({
  display: 'flex',
  width: '186px',
  minWidth: '80px',
  height: '48px',
  padding: '0 24px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '8px',
  background: '#2E2E2E',
  color: 'rgba(255, 255, 255, 0.87)',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px',
  textTransform: 'none',

  '&:hover': {
    cursor: 'pointer',
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    background: '#2E2E2E !important'
  }
});

export const BackButton = styling(Button)({
  display: 'flex',
  width: '200px',
  minWidth: '80px',
  height: '48px',
  padding: '0 24px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '8px',
  background: 'transparent',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  textTransform: 'none',

  '&:hover': {
    cursor: 'pointer'
  }
});

export const ErrorMessage = styling(Box)(props => ({
  display: 'flex',
  padding: '4px 0px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  width: '100%',
  alignSelf: 'stretch',
  borderRadius: '4px',
  background: '#EFE3E3',

  '& p': {
    color: '#BE4242',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '500',
    letterSpacing: '0.15px',
    margin: '0'
  }
}));
