import { styled as styling } from '@mui/material/styles';

export const Tooltip = styling('div')((props) => ({
  ...(props.width && props.height && {
    display: 'none',
    opacity: 0,
    position: 'absolute',
    zIndex: 10,
    minWidth: props.width,
    minHeight: props.height,
    backgroundColor: '#fff',
    filter: 'drop-shadow(0px 4px 17px rgba(0, 0, 0, 0.08))',
    borderRadius: '13px',
    transform: props.tooltip_transform,
    padding: '10px 15px',
    top: 'auto',
    right: 'auto',
    }),

    '&:before': {
      content: '""',
      position: 'absolute',
      // transform: props.front_transform,
      border: `1px solid ${props.border_color}`,
      borderRadius: '5px 0 0 5px',
      width: '50px',
      top: props.top,
      left: props.left,
      display: props.origin == 'banner' ? 'auto' : 'none',
      width: props.origin == 'banner' ? 'auto' : '50px',
      minHeight: props.origin == 'banner' ? props.height : 'auto',
      transform: props.origin == 'banner' ? props.front_transform : 'auto',

      ...(props.origin == 'banner' && {
        width: 'auto',
        minHeight: props.height,
        transform: props.front_transform,
      }),
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      borderRadius: '50%',
      width: '10px',
      height: '10px',
      background: props.thumb_color,
      transform: props.back_transform,
      top: props.top,
      left: props.left,
    },

    ...(props.origin == 'new-job-specialisation' ||
      props.origin == 'new-job-salary' ||
      props.origin == 'expiry-dates-popup' && {

        top: props.top,
        right: props.right,

        '&:before': {
          display: 'none',
        },

        '&:after': {
          display: 'none',
        },
    }),
}));
