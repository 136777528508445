import React from 'react';
import { Grid, Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Skeleton } from '@mui/material';

const useStyles = makeStyles(() => ({
  header: { fontSize: 16, fontFamily: 'Inter' },
  chipWrapper: { display: 'flex', padding: 5 },
  chip: { height: 30, width: 100, borderRadius: 25 },
  skeletonText: { margin: '15px 30px 0px 0px' }
}));

const SkeletonLoader = () => {
  const classes = useStyles();

  const renderChips = count => (
    <div style={{ display: 'flex' }}>
      {count.map(index => (
        <div key={index.toString()} className={classes.chipWrapper}>
          <Skeleton variant="rect" animation="wave" className={classes.chip} />
        </div>
      ))}
    </div>
  );

  const renderHeader = () => (
    <div style={{ display: 'flex' }}>
      <Grid container>
        <Grid item xs={4}>
          <h6
            className={classes.header}
            style={{ marginLeft: 15, fontSize: '20px' }}>
            Name
          </h6>
        </Grid>
        <Grid item xs={4}>
          <h6
            className={classes.header}
            style={{ marginLeft: -45, fontSize: '20px' }}>
            Access Level
          </h6>
        </Grid>
        <Grid item xs={4}>
          <h6 style={{ fontSize: '20px' }} className={classes.header}>
            Job Tracks
          </h6>
        </Grid>
      </Grid>
    </div>
  );

  const renderRow = () => (
    <div>
      <Skeleton height={3} style={{ marginTop: 20 }} />
      <div style={{ display: 'flex', padding: '20px 20px' }}>
        <Grid container>
          {/* Name */}
          <Grid item xs={4}>
            <Skeleton
              variant="text"
              animation="wave"
              width={150}
              className={classes.skeletonText}
            />
            <Skeleton
              variant="text"
              animation="wave"
              width={150}
              className={classes.skeletonText}
            />
            <Skeleton
              variant="text"
              animation="wave"
              width={200}
              className={classes.skeletonText}
            />
          </Grid>

          {/* Access Level */}
          <Grid item xs={4}>
            <div style={{ marginLeft: '-4vw' }}>
              {renderChips([1, 2, 3])}
              {renderChips([1, 2])}
            </div>
          </Grid>

          {/* Job Tracks */}
          <Grid item xs={4}>
            {renderChips([1, 2, 3])}
            {renderChips([1, 2, 3])}
            {renderChips([1, 2])}
          </Grid>
        </Grid>
      </div>
    </div>
  );

  return (
    <div>
      <Card style={{ padding: 30 }}>
        {renderHeader()}
        {renderRow()}
        {renderRow()}
        {renderRow()}
        {renderRow()}
        {renderRow()}
      </Card>
    </div>
  );
};

export default SkeletonLoader;
