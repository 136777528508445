import React from 'react';
import s from './index.module.scss';

export default function PageContainer({ ...props }) {
  return (
    <div className={s.container}>
      <div className={s.containerInner}>{props.children}</div>
    </div>
  );
}
