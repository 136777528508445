//UPDATE_TALENTS_COLUMN_REF
export const UPDATE_TALENTS_COLUMN_REF = 'UPDATE_TALENTS_COLUMN_REF';
export const UPDATE_UNLOCK_PROFILE_STATUS = 'UPDATE_UNLOCK_PROFILE_STATUS';
export const UPDATE_HIDE_PROFILE_STATUS = 'UPDATE_HIDE_PROFILE_STATUS';

export const FETCH_TALENTS = 'FETCH_TALENTS';
export const FETCH_TALENTS_SUCCEED = 'FETCH_TALENTS_SUCCEED';
export const FETCH_TALENTS_FAILED = 'FETCH_TALENTS_FAILED';
export const UPDATE_CURRENT_TALENT = 'UPDATE_CURRENT_TALENT';
export const UPDATE_COLLAPSIBLE_FILTER_STATUS =
  'UPDATE_COLLAPSIBLE_FILTER_STATUS';
export const UPDATE_SEARCH_RESUME_CURRENT_PAGE =
  'UPDATE_SEARCH_RESUME_CURRENT_PAGE';
export const UPDATE_KEYWORD_SEARCHED_STATUS = 'UPDATE_KEYWORD_SEARCHED_STATUS';
export const CLEAR_TALENTS_PARAMS = 'CLEAR_TALENTS_PARAMS';

export const FETCH_BOOKMARKED_TALENTS = 'FETCH_BOOKMARKED_TALENTS';
export const FETCH_BOOKMARKED_TALENTS_SUCCEED =
  'FETCH_BOOKMARKED_TALENTS_SUCCEED';
export const FETCH_BOOKMARKED_TALENTS_FAILED =
  'FETCH_BOOKMARKED_TALENTS_FAILED';
export const UPDATE_BOOKMARKED_TALENTS_FILTER =
  'UPDATE_BOOKMARKED_TALENTS_FILTER';
export const CLEAR_BOOKMARKED_TALENTS_FILTER =
  'CLEAR_BOOKMARKED_TALENTS_FILTER';
export const UPDATE_FETCH_BOOKMARKED_TALENTS_STATUS =
  'UPDATE_FETCH_BOOKMARKED_TALENTS_STATUS';
export const UPDATE_BOOKMARKED_CURRENT_PAGE = 'UPDATE_BOOKMARKED_CURRENT_PAGE';
export const CLEAR_BOOKMARKED_TALENTS_PARAMS =
  'CLEAR_BOOKMARKED_TALENTS_PARAMS';
export const UPDATE_BOOKMARKED_COUNT = 'UPDATE_BOOKMARKED_COUNT';

export const UPDATE_HIDE_NO_RESULT_FILTER_STATUS =
  'UPDATE_HIDE_NO_RESULT_FILTER_STATUS';

export const FETCH_UNLOCKED_TALENTS = 'FETCH_UNLOCKED_TALENTS';
export const FETCH_UNLOCKED_TALENTS_SUCCEED = 'FETCH_UNLOCKED_TALENTS_SUCCEED';
export const FETCH_UNLOCKED_TALENTS_FAILED = 'FETCH_UNLOCKED_TALENTS_FAILED';
export const UPDATE_UNLOCKED_TALENTS_FILTER = 'UPDATE_UNLOCKED_TALENTS_FILTER';
export const CLEAR_UNLOCKED_TALENTS_FILTER = 'CLEAR_UNLOCKED_TALENTS_FILTER';
export const UPDATE_FETCH_UNLOCKED_TALENTS_STATUS =
  'UPDATE_FETCH_UNLOCKED_TALENTS_STATUS';
export const UPDATE_UNLOCKED_CURRENT_PAGE = 'UPDATE_UNLOCKED_CURRENT_PAGE';
export const CLEAR_UNLOCKED_TALENTS_PARAMS = 'CLEAR_UNLOCKED_TALENTS_PARAMS';
export const UPDATE_UNLOCKED_COUNT = 'UPDATE_UNLOCKED_COUNT';

export const BOOKMARK_TALENT = 'BOOKMARK_TALENT';
export const BOOKMARK_TALENT_SUCCEED = 'BOOKMARK_TALENT_SUCCEED';
export const BOOKMARK_TALENT_FAILED = 'BOOKMARK_TALENT_FAILED';

export const UNBOOKMARK_TALENT = 'UNBOOKMARK_TALENT';
export const UNBOOKMARK_TALENT_SUCCEED = 'UNBOOKMARK_TALENT_SUCCEED';
export const UNBOOKMARK_TALENT_FAILED = 'UNBOOKMARK_TALENT_FAILED';

export const UNLOCK_RESUME = 'UNLOCK_RESUME';
export const UNLOCK_RESUME_SUCCEED = 'UNLOCK_RESUME_SUCCEED';
export const UNLOCK_RESUME_FAILED = 'UNLOCK_RESUME_FAILED';

export const HIDE_PROFILE = 'HIDE_PROFILE';
export const HIDE_PROFILE_SUCCEED = 'HIDE_PROFILE_SUCCEED';
export const HIDE_PROFILE_FAILED = 'HIDE_PROFILE_FAILED';

export const UPDATE_SEARCH_TALENT_FILTERS = 'UPDATE_SEARCH_TALENT_FILTERS';
export const UPDATE_MODAL_STATE = 'UPDATE_MODAL_STATE';
export const UPDATE_MODAL_NAME = 'UPDATE_MODAL_NAME';
export const UPDATE_PROFILE_POP_UP = 'UPDATE_PROFILE_POP_UP';

export const UPDATE_NOTES = 'UPDATE_NOTES';
export const RETRIEVE_NOTES = 'RETRIEVE_NOTES';

export const DOWNLOAD_RESUME = 'DOWNLOAD_RESUME';
export const DOWNLOAD_RESUME_SUCCEED = 'DOWNLOAD_RESUME_SUCCEED';
export const DOWNLOAD_RESUME_FAILED = 'DOWNLOAD_RESUME_FAILED';

export const CHECK_INITIATED_CHAT_ROOM = 'CHECK_INITIATED_CHAT_ROOM';
export const CHECK_INITIATED_CHAT_ROOM_SUCCEED =
  'CHECK_INITIATED_CHAT_ROOM_SUCCEED';
export const CHECK_INITIATED_CHAT_ROOM_FAILED =
  'CHECK_INITIATED_CHAT_ROOM_FAILED';

export const CREATE_COMPANY_RESUME_CHAT = 'CREATE_COMPANY_RESUME_CHAT';
export const CREATE_COMPANY_RESUME_CHAT_SUCCEED =
  'CREATE_COMPANY_RESUME_CHAT_SUCCEED';
export const CREATE_COMPANY_RESUME_CHAT_FAILED =
  'CREATE_COMPANY_RESUME_CHAT_FAILED';

export const UPDATE_INSUFFICIENT_COINS_BANNER =
  'UPDATE_INSUFFICIENT_COINS_BANNER';

export const UPDATE_SPECIFIC_TALENT_IN_LIST = 'UPDATE_SPECIFIC_TALENT_IN_LIST';

export const UPDATE_TALENT_SEARCH_FAQ_POPUP = 'UPDATE_TALENT_SEARCH_FAQ_POPUP';

export const UPDATE_TALENT_CHANNELS_UNREAD_MESSAGE_COUNT =
  'UPDATE_TALENT_CHANNELS_UNREAD_MESSAGE_COUNT';

export const REPORT_INFO_ERRORS = 'REPORT_INFO_ERRORS';
export const REPORT_INFO_ERRORS_SUCCEED = 'REPORT_INFO_ERRORS_SUCCEED';
export const REPORT_INFO_ERRORS_FAILED = 'REPORT_INFO_ERRORS_FAILED';

export const UPDATE_ERROR_REPORTING_STATUS = 'UPDATE_ERROR_REPORTING_STATUS';

export const UPDATE_TALENT_INFO_TAB_VALUE = 'UPDATE_TALENT_INFO_TAB_VALUE';

// FETCH_TALENTS_OLD_ACV
export const FETCH_TALENTS_OLD = 'FETCH_TALENTS_OLD';
export const FETCH_TALENTS_OLD_SUCCEED = 'FETCH_TALENTS_OLD_SUCCEED';
export const FETCH_TALENTS_OLD_FAILED = 'FETCH_TALENTS_OLD_FAILED';

// MUTATE_VIEW_TALENT
export const MUTATE_VIEW_TALENT = 'MUTATE_VIEW_TALENT';
export const MUTATE_VIEW_TALENT_SUCCEED = 'MUTATE_VIEW_TALENT_SUCCEED';
export const MUTATE_VIEW_TALENT_FAILED = 'MUTATE_VIEW_TALENT_FAILED';

export const UPDATE_FEEDBACK_CARD_STATUS = 'UPDATE_FEEDBACK_CARD_STATUS';

export const UPDATE_NEW_SEARCH = 'UPDATE_NEW_SEARCH';
export const UPDATE_TALENT_LIST = 'UPDATE_TALENT_LIST';
export const UPDATE_FEEDBACK_PAGE = 'UPDATE_FEEDBACK_PAGE';
export const UPDATE_FEEDBACK_FORM = 'UPDATE_FEEDBACK_FORM';
