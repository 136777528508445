import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PopUpDialog, Spinner } from '../../../../components';
import {
  deleteInterview,
  updateAllApplications,
  updateApplicationCount,
  updateCandidateSnapshotStatus,
  updateInterviews,
  updateNextScheduleInterviewModalStatus
} from '../../../../redux/actions/application_action';
import { updateJobData } from '../../../../redux/actions/job_action';
import store from '../../../../redux/stores/store';
import { ErrorToast, InfoToast } from '../../../../utils/ToastUtils';
import {
  FirstContainer,
  SecondContainer,
  ButtonWrapper,
  ButtonContainer,
  Text,
  TextContainer,
  TextFieldStyled,
  ButtonStyled
} from './styles';

const DeleteInterviewPopUp = ({
  open,
  handleClose,
  getCandidateProps,
  candidates,
  setCandidates,
  setCurrentCandidates,
  isAts,
  loadingShortlistedApplications,
  loadingInterviews,
  jobData,
  setJobData
}) => {
  const dispatch = useDispatch();
  const deletingInterviewStatus = useSelector(
    state => state.applications.deletingInterview
  );

  const singleStatusMessage = () => {
    return (
      <div>
        <p
          style={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 'bold',
            margin: '4px 0'
          }}>
          Interview Deleted
        </p>
        <p style={{ margin: '0', fontFamily: 'Inter', fontSize: '12px' }}>
          You have successfully deleted the interview. The candidate status will
          back to Shortlisted.
        </p>
      </div>
    );
  };

  const getUpdatedApplicantCount = (currentState, newState, jobData) => {
    let cJobData = { ...jobData };

    switch (currentState) {
      case 'interview':
        {
          cJobData.interviewCount -= 1;
        }
        break;
    }

    switch (newState) {
      case 'shortlisted':
        {
          cJobData.interviewCount += 1;
        }
        break;
    }

    return cJobData;
  };

  const triggerDeletion = async () => {
    let application = getCandidateProps?.jobApplication
      ? getCandidateProps.jobApplication
      : getCandidateProps;

    let interviewId;

    if (application?.interviews?.length > 0) {
      interviewId = application?.interviews[0].id;

      const res = await dispatch(deleteInterview({ interviewId: interviewId }));

      if (res.type == 'DELETE_INTERVIEW_SUCCEED') {
        const cCandidates = candidates && [...candidates];

        const index =
          candidates &&
          candidates.findIndex(item => {
            return item?.node?.id == getCandidateProps?.id;
          });

        const applications = [...store.getState().applications.allApplications];

        const candidateSnapshotApplicationIndex = applications.findIndex(
          item => {
            return item?.id == getCandidateProps?.id;
          }
        );

        if (index != -1 || candidateSnapshotApplicationIndex != -1) {
          if (candidates) {
            if (candidates[index]?.node?.jobApplication) {
              cCandidates[index] = {
                ...cCandidates[index],
                node: {
                  ...cCandidates[index]?.node,
                  state: 'shortlisted',
                  jobApplication: {
                    ...cCandidates[index]?.node.jobApplication,
                    interviews: res?.interviews
                  }
                }
              };

              if (
                cCandidates[index]?.node?.jobApplication?.interviews?.length > 0
              ) {
                setCandidates(cCandidates);
                setCurrentCandidates(cCandidates);
              } else {
                let removedArray = [];

                removedArray = [...cCandidates].filter(value => {
                  return value?.node?.id !== getCandidateProps?.id;
                });

                setCandidates(removedArray);
                setCurrentCandidates(removedArray);
              }
            }
          }

          if (applications[candidateSnapshotApplicationIndex]?.jobApplication) {
            applications[candidateSnapshotApplicationIndex] = {
              ...applications[candidateSnapshotApplicationIndex],
              jobApplication: {
                ...applications[candidateSnapshotApplicationIndex]
                  ?.jobApplication,
                interviews: res?.interviews
              }
            };

            let removedArray = [];

            removedArray = [...applications].filter(value => {
              return value?.id !== getCandidateProps?.id;
            });

            let currentApplicationCount = {
              ...store.getState().applications.applicationCount
            };
            currentApplicationCount['interview'] = removedArray?.length;

            dispatch(updateAllApplications(removedArray));
            dispatch(updateInterviews(removedArray));
            dispatch(updateApplicationCount(currentApplicationCount));

            if (jobData) {
              const cJobData = getUpdatedApplicantCount(
                'interview',
                'shortlisted',
                jobData
              );

              if (setJobData) {
                setJobData(cJobData);
                updateJobData(cJobData);
              }
            }

            if (removedArray?.length == 0) {
              dispatch(updateCandidateSnapshotStatus(false));
              loadingInterviews(true);
            }

            if (isAts) {
              loadingShortlistedApplications(true, false);
            }
          }
        }

        handleClose();
        dispatch(updateNextScheduleInterviewModalStatus(false));

        InfoToast(singleStatusMessage());
      } else {
        handleClose();
        dispatch(updateNextScheduleInterviewModalStatus(false));
        ErrorToast('Failed to delete interview.');
      }
    }
  };

  return (
    <PopUpDialog
      open={open}
      handleClose={handleClose}
      title={'Are you sure you want to delete this interview?'}>
      <FirstContainer>
        <TextContainer type="message">
          <Text type="basic">
            This action cannot be undone, and you can’t make changes to this
            user anymore.
          </Text>
        </TextContainer>
      </FirstContainer>
      <SecondContainer>
        <ButtonWrapper>
          <ButtonContainer>
            <ButtonStyled type="cancel" onClick={handleClose}>
              Cancel
            </ButtonStyled>
          </ButtonContainer>
          <ButtonContainer>
            <ButtonStyled type="confirm" onClick={triggerDeletion}>
              {deletingInterviewStatus ? (
                <Spinner size="XS" color="white" noPadding={true} />
              ) : (
                'Delete Interview'
              )}
            </ButtonStyled>
          </ButtonContainer>
        </ButtonWrapper>
      </SecondContainer>
    </PopUpDialog>
  );
};

export default DeleteInterviewPopUp;
