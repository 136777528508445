import React from 'react';
import {
  DraftailEditor,
  BLOCK_TYPE,
  INLINE_STYLE,
  ENTITY_TYPE
} from 'draftail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl, faListOl } from '@fortawesome/free-solid-svg-icons';
// import 'draftail/dist/draftail.css';
import 'draft-js/dist/Draft.css';
import LinkSource from '../RichTextEditor/LinkSource';
import styles from './index.module.scss';
import Link from '../RichTextEditor/Link';
import './draftail.css';
import './index.module.scss';

export const DefaultRichTextEditor = ({
  editorState,
  onChange,
  placeholder
}) => {
  return (
    <DraftailEditor
      editorState={editorState}
      onChange={onChange}
      placeholder={
        <p
          style={{
            fontFamily: 'inter',
            fontSize: '14px',
            fontWeight: '400',
            color: '#00000038',
            margin: '0'
          }}>
          {placeholder}
        </p>
      }
      stripPastedStyles={false}
      enableLineBreak={{
        description: 'Soft line break'
      }}
      showUndoControl={{
        description: 'Undo last change'
      }}
      showRedoControl={{
        description: 'Redo last change'
      }}
      maxListNesting={6}
      entityTypes={[
        {
          type: ENTITY_TYPE.LINK,
          source: LinkSource,
          decorator: Link
        }
      ]}
      blockTypes={[
        { type: BLOCK_TYPE.UNSTYLED },
        { type: BLOCK_TYPE.HEADER_TWO },
        { type: BLOCK_TYPE.HEADER_THREE },
        { type: BLOCK_TYPE.HEADER_FOUR },
        { type: BLOCK_TYPE.HEADER_FIVE },
        {
          type: BLOCK_TYPE.UNORDERED_LIST_ITEM,
          icon: (
            <FontAwesomeIcon icon={faListUl} className={styles.unorderList} />
          )
        },
        {
          type: BLOCK_TYPE.ORDERED_LIST_ITEM,
          icon: <FontAwesomeIcon icon={faListOl} className={styles.orderList} />
        },
        { type: BLOCK_TYPE.CODE }
      ]}
      inlineStyles={[
        { type: INLINE_STYLE.BOLD },
        { type: INLINE_STYLE.ITALIC },
        { type: INLINE_STYLE.STRIKETHROUGH },
        { type: INLINE_STYLE.UNDERLINE }
      ]}
    />
  );
};

export default DefaultRichTextEditor;
