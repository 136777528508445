import { styled } from '@mui/material/styles';

export const StepperContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

export const StepContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexGrow: 1,
  position: 'relative',

  '.stepCircle': {
    backgroundColor: '#fff',
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    fontSize: '20px',
    color: theme.palette.grey[500],
    border: `1px solid ${theme.palette.grey[500]}`,

    '&.active': {
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.common.white,
      border: `1px solid ${theme.palette.grey[900]}`,
    },
  },

  '.stepperLineBefore, .stepperLineAfter': {
    background: theme.palette.grey[500],
    height: '1px',
    position: 'absolute',
    width: '50%',
    top: '15px',
  },

  '.stepperLineBefore': {
    left: 0,
  },

  '.stepperLineAfter': {
    right: 0,
  },

  '.stepLabel': {
    fontWeight: 500,
    marginTop: '8px',
  },
}));
