import React from 'react';
import {
  CenterContainer,
  ClickableLink,
  CloseIconStyled,
  DecoyTypography,
  EmptyContainer,
  IconButtonStyled,
  MainWrapper,
  PurpleContainer,
  TypographyStyled
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { updateInsufficientCoinsBanner } from '../../redux/actions/talent_action';
import { openInNewTab } from '../../utils/WindowUtils';
import { mutateCart } from '../../redux/actions/order_action';

export const InsufficientCoinsAlert = props => {
  const dispatch = useDispatch();

  // const closeHandler = () => {
  //   dispatch(updateInsufficientCoinsBanner(false));
  //};

  const purchaseCoins = async () => {
    // TALENT SEARCH TRACKING
    try {
      window.dataLayer.push({
        event: 'CE_click-purchase-coin-banner'
      });
    } catch (error) {}

    openInNewTab('/purchase/choose/?tab=talent_search&products=coins_x5000-1');
  };

  const isInsufficientCoinsBannerShow = useSelector(
    state => state.talents.isInsufficientCoinsBannerShow
  );

  return (
    isInsufficientCoinsBannerShow && (
      <MainWrapper container>
        <PurpleContainer>
          <DecoyTypography>x</DecoyTypography>
        </PurpleContainer>
        <CenterContainer
          container
          justifyContent="space-between"
          direction="column">
          <EmptyContainer
            container
            justifyContent="space-between"
            alignItems="center"
            direction="row">
            <TypographyStyled>
              You do not have sufficient tokens to unlock more Profiles.{' '}
              <ClickableLink onClick={purchaseCoins}>
                Purchase Tokens
              </ClickableLink>
            </TypographyStyled>
            {/* <IconButtonStyled onClick={closeHandler}>
              <CloseIconStyled />
            </IconButtonStyled> */}
          </EmptyContainer>
        </CenterContainer>
      </MainWrapper>
    )
  );
};
