import { styled as styling } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid } from '@mui/material';
// import { Tooltip } from '../CustomTooltip/styles';
import Colors from '../../../../../styles/colors.module.scss';
import TextField from '@mui/material/TextField';

// export const BannerContentContainer = styling('div')({
//   position: 'relative',
//   margin: '0 5rem',
//   paddingTop: '21%',

//   //   [props.theme.breakpoints.down('lg')]: {
//   //     maxWidth: '1280px',
//   //     margin: '0 auto',
//   //     paddingTop: '21%',
//   //   },

//   //   [props.theme.breakpoints.down('md')]: {
//   //     maxWidth: '1280px',
//   //     margin: '0 auto',
//   //   },
//   '@media (min-width: 1440px)': {
//     maxWidth: '1280px',
//     margin: '0 auto',
//     paddingTop: '27%'
//   },
//   '@media (min-width: 1600px)': {
//     maxWidth: '1280px',
//     margin: '0 auto',
//     paddingTop: '21%'
//   }
// });

export const BannerContentContainer = styling('div')({
    position: 'relative',
    margin: '0 5rem',
    paddingTop: '28%',
    '@media (min-width: 1440px)': {
      maxWidth: '1280px',
      margin: '0 auto',
    },
    '@media (min-width: 1600px)': {
      maxWidth: '1280px',
      margin: '0 auto',
      paddingTop: '21%',
    },
  });

export const FreeJobMessageBoxContainer = styling('div')({
  position: 'absolute',
  top: '39%',
  left: 0,
  display: 'flex',
  flexDirection: 'row',
  padding: '15px 20px',
  borderRadius: '15px',
  background: 'white',
  maxWidth: '630px',
  alignItems: 'center'
});

export const FreeJobMessageDesc = styling('div')({
  paddingLeft: '15px',
  color: 'black',
  fontSize: '12px'
});

export const OfficeStyled = styling('div')(props => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  ...(props.edit_location == 'true' && {
    animation: 'fadeInAnimation ease 1s',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
    '@keyframes fadeInAnimation': {
      '0%': {
        opacity: 0
      },
      '50%': {
        opcaity: 0.5
      },
      '100%': {
        opacity: 1
      }
    }
  })
}));

export const EditOptionContainer = styling('div')({
  position: 'absolute',
  zIndex: 100,
  top: '55px',
  width: '100%',
  backgroundColor: 'white !important',
  borderRadius: '13px',
  padding: '8px 25px 8px 25px',
  minHeight: '90px'
});

export const Wrapper = styling('div')(props => ({
  position: 'absolute',
  display: "flex",
  flexDirection: "column",
  top: '37px',
  right: '50px',
  // &:hover ${Tooltip} {
  '&:hover div': {
    height: '25px',
    display: 'block',
    animation: 'fadeInAnimation ease 1s',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
    '@keyframes fadeInAnimation': {
      '0%': {
        opacity: 0
      },
      '50%': {
        opacity: 0.5
      },
      '100%': {
        opacity: 1
      }
    }
  }
}));

export const BannerWrapper = styling('div')({
  position: 'relative'
  // height: '500px'
});

export const SpinnerContainer = styling('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 20
});

export const Text = styling('p')(props => ({
  ...(props.tag == 'spinner' && {
    color: '#FFF'
  }),

  ...(props.tag == 'job-title' && {
    color: '#fff',
    fontWeight: 'bold',
    fontFamily: 'Inter',
    fontSize: '27px',
    margin: '0 20px 0 0'
  }),

  ...(props.tag == 'company-name' && {
    color: 'rgba(255, 255, 255, 0.8)',
    fontFamily: 'Inter',
    fontSize: '18px',
    margin: '0'
  }),

  ...(props.tag == 'location' && {
    color: '#fff',
    fontFamily: 'Inter',
    fontSize: '18px',
    margin: '10px 20px 10px 0'
  }),

  ...(props.tag == 'button-title' && {
    margin: '0',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'Inter',
    transform: 'translateY(2px)'
  }),

  ...(props.tag == 'edit-option' && {
    padding: '10px 0 10px 0',
    margin: '0',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'Inter',
    cursor: 'pointer',
    width: '100%'
  }),

  ...(props.tag == 'custom-tooltip' && {
    fontSize: '12px',
    fontFamily: 'Inter',
    margin: '0'
  }),

  ...(props.tag == 'dropdown' && {
    margin: '0',
    color: Colors.priBlack,
    fontSize: '16px'
  })
}));

export const BannerContentWrapper = styling('div')({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%'
});

export const ImageContainer = styling('div')(props => ({
  position: 'absolute',
  top: props.is_internship == 'true' ? '15%' : '36%',
  left: 0
}));

export const ImageStyled = styling('img')({
  height: '70px',
  background: 'rgba(255, 255, 255, 0.65)',
  borderRadius: '5px',
  objectFit: 'cover'
});

export const JobTitleWrapper = styling('div')({
  position: 'absolute',
  top: '60%',
  left: 0
});

export const JobTitleContainer = styling('div')({
  position: 'absolute',
  top: '60%',
  left: 0,
  minWidth: '700px'
});

export const FontAwesomeIconStyled = styling(FontAwesomeIcon)(props => ({
  ...(props.awesome_icon == 'faPen' && {
    fontSize: '15px',
    color: '#fff',
    cursor: 'pointer'
  }),

  ...(props.awesome_icon == 'faTimes' && {
    color: '#fff',
    fontSize: '18px',
    cursor: 'pointer',
    marginLeft: '20px'
  }),

  ...(props.awesome_icon == 'faCheck' && {
    color: '#fff',
    fontSize: '18px',
    cursor: 'pointer',
    marginLeft: '20px'
  }),

  ...(props.awesome_icon == 'faMapMarkerAlt' && {
    fontSize: '20px',
    color: '#fff',

    ...(props.edit_location == 'true' && {
      transform: 'translateY(-5px)'
    })
  }),

  ...(props.awesome_icon == 'faCamera' && {
    fontSize: '25px',
    marginRight: '12px'
  }),

  ...(props.awesome_icon == 'faLightbulb' && {
    color: '#512acc',
    fontSize: '20px'
  })
}));

export const CompanyNameContainer = styling('div')({
  position: 'absolute',
  top: '76.5%',
  left: 0
});

export const LocationWrapper = styling('div')({
  position: 'absolute',
  top: '83%',
  left: 0,
  bottom: '10%'
});

export const IconContainer = styling('div')(props => ({
  ...(props.awesome_icon == 'faMapMarkerAlt' && {
    marginRight: '15px'
  }),

  ...(props.awesome_icon == 'tick_n_cross' && {
    display: 'flex',
    alignItems: 'center'
  }),

  ...(props.awesome_icon == 'faCamera' && {
    display: 'flex',
    alignItems: 'center',
    animation: 'none !important'
  })
}));

export const LocationContainer = styling('div')({
  display: 'flex',
  alignItems: 'center'
});

export const EditLocationContainer = styling('div')({
  display: 'flex',
  minWidth: '700px'
});

export const FormSelectFieldContainer = styling('div')({
  marginRight: '10px',
  minWidth: '250px'
});

export const ButtonStyled = styling(Button)({
  background: '#fff !important',
  borderRadius: '30px',
  padding: '10px 20px',
  color: '#512acc',
  height: '45px'
});

export const TextFieldGridStyled = styling(Grid)({
  marginBottom: '150px',
  width: '100%'
});

export const DropdownListContainer = styling('div')({
  maxHeight: '256px',
  width: '700px',
  background: Colors.priWhite,
  borderRadius: '20px',
  position: 'absolute',
  top: '60px',
  zIndex: 20,
  overflow: 'auto',
  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)'
});

export const DropdownItem = styling('div')(props => ({
  width: '100%',
  height: '50px',
  padding: '18px 27px',
  '&:hover': {
    background: Colors.secLightPurple
  },
  ...(props.$highlight && {
    background: Colors.secLightPurple
  })
}));

export const Description = styling('span')({
  color: Colors.priBlack,
  opacity: 0.6,
  fontSize: '14px'
});

export const GuideText = styling('span')({
  opacity: 0.6,
  fontSize: '14px',
  marginLeft: '10px'
});

export const TipsTooltipContainer = styling('span')({
  position: 'absolute',
  top: '-40px',
  right: 0
});

export const UploadContainer = styling('div')(props => ({
  opacity: '0.95',
  width: '100%',
  paddingTop: '28%',
  overflow: 'hidden',
  position: 'relative',

  '@media (min-width: 1600px)': {
    paddingTop: '21%'
  }

  //   [props.theme.breakpoints.down('lg')]: {
  //     paddingTop: '28%',
  //   },
}));

export const UploadBannerImg = styling('img')({
  width: '100%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  '-webkit-transform': 'translate(-50%, -50%)',
  transform: 'translate(-50%, -50%)',
  height: '100%',
  objectFit: 'cover',
  filter: 'brightness(65%)',
  '-webkit-filter': 'brightness(65%)'
});

export const TextFieldStyled = styling(TextField)(
  ({ theme, small, value, select, bold, white, name }) => ({
    width: '100%',
    // Adjust the padding of the input field to change its height
    '& .MuiInputBase-root': {
      fontSize: name == 'title' ? '1rem' : '0.85rem',
      color:
        value === 'placeholder' && select
          ? '#d8d8d8'
          : white
          ? 'white'
          : 'black', // Text color
      fontWeight: name == 'title' && value ? 'bold' : bold && 'bold',
      ...(small && {
        height: '45px',
        '& .MuiOutlinedInput-input': {
          '&::placeholder': {
            color: '#d8d8d8 !important', // Change to desired placeholder color
            opacity: 1 // Ensure the placeholder is fully visible
          },
          paddingTop: '10px', // Reduced top padding
          paddingBottom: '5px', // Increased bottom padding to move text down
          paddingLeft: '14px', // Maintain side paddings
          paddingRight: '14px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          top: 0
        }
      })
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        borderColor: white ? '#AEAEAE' : 'black' // Border color
      },
      '&:hover fieldset': {
        borderColor: white ? 'white' : 'black' // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: white ? 'white' : 'black' // Border color on focus
      }
    },
    '& .MuiSvgIcon-root':{
      fill: '#d8d8d8',
      top: 'calc(50% - 0.4em)'
    },
    ...(small && {
      height: '45px' // Adjust the height of the entire component if small is true
    })
  })
);

export const ErrorStateText = styling('div')(props => ({
  color: '#BE4242',
  fontSize: '0.9rem',
  fontWeight: '400',
  minWidth: '200px',
  position: 'absolute',
  right: props.isTwoError? '-52%' :'-32%',
  top: '10%',
  display: 'flex',
  gap: '20px'
}));
