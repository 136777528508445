import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCurrentOnboardingPage,
  updateCurrentBg,
  updateDirectRegister
} from '../../../../redux/actions/company_actions';
import HiredlyLogo from '../../../../assets/common/hiredly-logo-copyright.png';
import {
  AnimatedContainer,
  VideoAnimatedContainer,
  PageContainer,
  ContentContainer,
  SecContentContainer,
  Title,
  SecTitle,
  MessageContainer,
  Message,
  SecondContainer,
  VideoContainer,
  NextPageButton,
  FooterContainer,
  FooterContainerLink,
  Container1,
  Container2,
  Container3,
  Container4,
  BrandAnimatedContainer,
  SecondContainerBrand,
  TitleBrand,
  FooterContainerBrand,
  BackButtonBrand,
  KeyboardBackspaceIconStyled,
  NextPageButtonBrand,

  // new style
  CommonBox,
  CommonBoxText1,
  CommonBoxText2,
  Checklist,
  ChecklistLine,
  XMarkIconStyled,
  CheckIconStyled,
  IconBox,
  ChecklistText,
  SecTitleContainer,
  TitleGrey,
  MessageBrandLine,
  MessageBrand,
  MessageBrandTextBox,
  MessageBrandTitle,
  MessageBrandContext,
  MessageBrandTitlePurple,
  MessageBrandTitleGrey,
} from './styles';

export default function Welcome({ currentOnboardingPage }) {
  const dispatch = useDispatch();
  const [begin, setBegin] = useState(false);
  const [out, setOut] = useState(false);
  const [register, setRegister] = useState(false);
  const [slide, setSlide] = useState(false);
  const [showContainer4, setShowContainer4] = useState(false);

  const backgroundValue = useSelector(state => state.companies.currentBg);

  const [imageLoaded, setImageLoaded] = useState(false);

  const [randomImages, setRandomImages] = useState({
    image1: null,
    image2: null,
    image3: null
  });

  const [randomGifs, setRandomGifs] = useState({
    gif1: null,
    gif2: null
  });

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const loadImage = async () => {
    return new Promise(resolve => {
      const img = new Image();
      img.src = HiredlyLogo;
      img.onload = resolve;
    });
  };

  const gif = [
    'https://assets.wobbjobs.com/images/waitlist/brandingbites_1.mp4',
    'https://assets.wobbjobs.com/images/waitlist/brandingbites_2.mp4',
    'https://assets.wobbjobs.com/images/waitlist/brandingbites_3.mp4',
    'https://assets.wobbjobs.com/images/waitlist/brandingbites_4.mp4',
    'https://assets.wobbjobs.com/images/waitlist/brandingbites_5.mp4',
    'https://assets.wobbjobs.com/images/waitlist/brandingbites_6.mp4',
    'https://assets.wobbjobs.com/images/waitlist/brandingbites_7.mp4',
    'https://assets.wobbjobs.com/images/waitlist/brandingbites_8.mp4'
  ];

  const images = [
    'https://assets.wobbjobs.com/images/waitlist/companyprofile_1.jpg',
    'https://assets.wobbjobs.com/images/waitlist/companyprofile_2.jpg',
    'https://assets.wobbjobs.com/images/waitlist/companyprofile_3.jpg',
    'https://assets.wobbjobs.com/images/waitlist/companyprofile_4.jpg',
    'https://assets.wobbjobs.com/images/waitlist/companyprofile_5.jpg',
    'https://assets.wobbjobs.com/images/waitlist/companyprofile_6.jpg',
    'https://assets.wobbjobs.com/images/waitlist/companyprofile_7.jpg',
    'https://assets.wobbjobs.com/images/waitlist/companyprofile_8.jpg'
  ];

  const getRandomGif = () => {
    const randomIndex = Math.floor(Math.random() * gif.length);
    return gif[randomIndex];
  };

  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
  };

  const navigateToNextPage = () => {
    dispatch(updateCurrentBg('1'));
    setBegin(true);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    setTimeout(() => {
      setShowContainer4(true);
    }, 1000);
  };

  const navigateToPreviousPage = () => {
    dispatch(updateCurrentBg('0'));
    setBegin(false);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    setTimeout(() => {
      setShowContainer4(false);
    }, 1000);
  };

  const navigateToCompanyPage = () => {
    setOut(true);
    setSlide(true);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    setTimeout(() => {
      dispatch(updateCurrentOnboardingPage('company-canvas'));
    }, 1500);
  };

  const navigateToRegisterPage = () => {
    dispatch(updateCurrentBg('3'));
    dispatch(updateDirectRegister('1'));
    setRegister(true);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    setTimeout(() => {
      dispatch(updateCurrentOnboardingPage('register'));
    }, 1000);
  };

  useEffect(() => {
    const loadContent = async () => {
      await loadImage();
      setImageLoaded(true);
    };

    loadContent();
  }, [HiredlyLogo]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('randomData')) || {};
    const currentDate = new Date();
    const currentTimestamp = currentDate.getTime();

    const isSameDay =
      storedData.timestamp &&
      currentDate.getDate() ===
        new Date(parseInt(storedData.timestamp)).getDate();

    if (isSameDay && storedData.images) {
      setRandomImages(storedData.images);
      setRandomGifs(storedData.gifs);
    } else {
      const newRandomImages = {
        image1: getRandomImage(),
        image2: null,
        image3: null
      };

      newRandomImages.image2 = getRandomImage();
      newRandomImages.image3 = getRandomImage();

      while (newRandomImages.image2 === newRandomImages.image1) {
        newRandomImages.image2 = getRandomImage();
      }

      while (
        newRandomImages.image3 === newRandomImages.image1 ||
        newRandomImages.image3 === newRandomImages.image2
      ) {
        newRandomImages.image3 = getRandomImage();
      }

      const newRandomGifs = {
        gif1: getRandomGif(),
        gif2: null
      };

      newRandomGifs.gif2 = getRandomGif();

      while (newRandomGifs.gif2 === newRandomGifs.gif1) {
        newRandomGifs.gif2 = getRandomGif();
      }

      setRandomImages(newRandomImages);
      setRandomGifs(newRandomGifs);

      localStorage.setItem(
        'randomData',
        JSON.stringify({
          timestamp: currentTimestamp.toString(),
          images: newRandomImages,
          gifs: newRandomGifs
        })
      );
    }
  }, []);

  return (
    <PageContainer>
      <Container1 $begin={begin} register={register}>
        <AnimatedContainer>
          <ContentContainer>
            <CommonBox>
              <CommonBoxText1>
                You’ve just advertised a job for a vacancy, but candidates:
              </CommonBoxText1>

              <Checklist>
                <ChecklistLine>
                  <IconBox>
                    <XMarkIconStyled/>
                  </IconBox>
                  <ChecklistText>
                    Don’t know who you are
                  </ChecklistText>
                </ChecklistLine>

                <ChecklistLine>
                  <IconBox>
                    <XMarkIconStyled/>
                  </IconBox>
                  <ChecklistText>
                    Have incorrect perception of your culture
                  </ChecklistText>
                </ChecklistLine>

                <ChecklistLine>
                  <IconBox>
                    <XMarkIconStyled/>
                  </IconBox>
                  <ChecklistText>
                    Cannot differentiate you from other companies
                  </ChecklistText>
                </ChecklistLine>
              </Checklist>
            </CommonBox>

            <CommonBox>
              <CommonBoxText2>
                If this is you, <br/><b style={{ color: '#512ACC' }}> Hiredly </b> is
                here to help.
              </CommonBoxText2>

              <Checklist>
                <ChecklistLine>
                  <IconBox>
                    <CheckIconStyled/>
                  </IconBox>
                  <ChecklistText>
                    Flexible job advertising solutions
                  </ChecklistText>
                </ChecklistLine>

                <ChecklistLine>
                <IconBox>
                    <CheckIconStyled/>
                  </IconBox>
                  <ChecklistText>
                    AI-assisted candidate management
                  </ChecklistText>
                </ChecklistLine>

                <ChecklistLine>
                <IconBox>
                    <CheckIconStyled/>
                  </IconBox>
                  <ChecklistText>
                    Personalised media-rich company canvas
                  </ChecklistText>
                </ChecklistLine>
              </Checklist>
            </CommonBox>
          </ContentContainer>
        </AnimatedContainer>
      </Container1>

      <Container2 $begin={begin} register={register}>
        <VideoAnimatedContainer slide={slide}>
          <SecContentContainer slide={slide} $begin={begin}>
            {imageLoaded && randomGifs.gif1 && (
              <VideoContainer style={{ marginBottom: '15%' }}>
                <video
                  controls
                  loop
                  width="100%"
                  height="100%"
                  autoPlay
                  playsInline
                  muted
                  onLoad={handleImageLoad}
                  style={{
                    opacity: imageLoaded ? 1 : 0,
                    transition: 'opacity 0.5s',
                    borderRadius: '10px'
                  }}>
                  <source src={randomGifs.gif1} type="video/mp4"></source>
                </video>
              </VideoContainer>
            )}

            {imageLoaded && randomGifs.gif2 && (
              <VideoContainer style={{ marginTop: '15%' }}>
                <video
                  controls
                  loop
                  width="100%"
                  height="100%"
                  autoPlay
                  playsInline
                  muted
                  onLoad={handleImageLoad}
                  style={{
                    opacity: imageLoaded ? 1 : 0,
                    transition: 'opacity 0.5s',
                    borderRadius: '10px'
                  }}>
                  <source src={randomGifs.gif2} type="video/mp4"></source>
                </video>
              </VideoContainer>
            )}
          </SecContentContainer>
        </VideoAnimatedContainer>
      </Container2>

      <Container3 $begin={begin} register={register}>
        <AnimatedContainer>
          <SecondContainer>
            <SecTitleContainer>
              <SecTitle>
                  Find out how you <br/>
                  can start telling your <br/>
                  employer brand story with <br/> 
                  <b>Hiredly Singapore</b>
              </SecTitle>

              <TitleGrey>
                psst.. It’s completely free to start!
              </TitleGrey>
            </SecTitleContainer>

            <FooterContainer>
              <NextPageButton onClick={navigateToNextPage}>
                Start now
              </NextPageButton>

              <FooterContainerLink onClick={navigateToRegisterPage}>
                Skip straight to sign up
              </FooterContainerLink>
            </FooterContainer>
          </SecondContainer>
        </AnimatedContainer>
      </Container3>

      {showContainer4 && backgroundValue == 1 && (
        <Container4 $begin={begin}>
          <BrandAnimatedContainer out={out}>
            <SecondContainerBrand>

              <MessageBrand>
                <MessageBrandTextBox>
                  <MessageBrandTitle>
                    What is Hiredly?
                  </MessageBrandTitle>
                  <MessageBrandContext>
                    Hiredly is modern, visual job platform that helps employers
                    attract quality Gen Y and Z talent.
                  </MessageBrandContext>
                </MessageBrandTextBox>

                <MessageBrandTextBox>
                  <MessageBrandTitle>
                    The Vision
                  </MessageBrandTitle>
                  <MessageBrandContext>
                    To be SEA’s most personalised talent ecosystem, elevating
                    human progress by helping careers and companies grow.
                  </MessageBrandContext>
                </MessageBrandTextBox>

                <MessageBrandTextBox>
                  <MessageBrandTitle>
                    The Mission
                  </MessageBrandTitle>
                  <MessageBrandContext>
                    To help 10 million jobseekers achieve career growth.
                  </MessageBrandContext>
                </MessageBrandTextBox>
              </MessageBrand>

              <MessageBrandLine/>

              <MessageBrand>
                <MessageBrandTitle>
                  Grow and build an adaptable tech-enabled workforce with
                  Hiredly Group’s personalised talent ecosystem.
                </MessageBrandTitle>

                <MessageBrandTitlePurple>
                  Hiredly will bring this unique recruitment experience to
                  Singapore on 26th April 2024. 
                </MessageBrandTitlePurple>

                <MessageBrandTitleGrey>
                  Continue to find out how you can be part of this exclusive
                  launch.
                </MessageBrandTitleGrey>
              </MessageBrand>
        
              <FooterContainerBrand>
                <BackButtonBrand onClick={navigateToPreviousPage}>
                  <KeyboardBackspaceIconStyled />
                </BackButtonBrand>

                <NextPageButtonBrand onClick={navigateToCompanyPage}>
                  Continue
                </NextPageButtonBrand>
              </FooterContainerBrand>
            </SecondContainerBrand>
          </BrandAnimatedContainer>
        </Container4>
      )}
    </PageContainer>
  );
}
