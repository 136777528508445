import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { updateProductDaysRemaining } from '../../../../../../redux/actions/job_action';
import { Container, TextContainer, Text } from './styles';

const DaysRemainingPopover = ({ applyFilter, types }) => {
  const dispatch = useDispatch();

  const productDaysRemainingIndex = useSelector(
    state => state.jobs.productDaysRemainingIndex
  );

  const onClickType = index => {
    dispatch(updateProductDaysRemaining(index)).then(() => {
      applyFilter();
    });
  };

  return (
    <Container>
      {types.map((item, index) => {
        return (
          <TextContainer
            highlight={`${productDaysRemainingIndex == index}`}
            onClick={() => onClickType(index)}>
            <Text>{item}</Text>
          </TextContainer>
        );
      })}
    </Container>
  );
};

DaysRemainingPopover.propTypes = {
  applyFilter: PropTypes.func,
  types: PropTypes.array
};

DaysRemainingPopover.defaultProps = {
  applyFilter: () => {},
  types: []
};

export default DaysRemainingPopover;
