import { styled as styling } from '@mui/material/styles';
import {
  Grid,
  Typography,
  TextField,
  IconButton,
  Button,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

export const SocialMediaGrid = styling(Grid)(props => ({
  backgroundColor: '#EFEFEF',
  border: '1px dashed #707070',
  width: '140px',
  height: '290px',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  transition: "all 0.3s",

  ":hover": {
    backgroundColor: "#E5E5E5"
  }
}));

export const EmptySocialMediaGrid = styling(Grid)(props => ({
  backgroundColor: props?.viewMode ? 'transparent' : 'white',
  border: '1px dashed #707070',
  width: '140px',
  height: '290px',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const FilledSocialMediaGrid = styling(Grid)(props => {
  const backgroundGradient =
    props?.type === 'instagram'
      ? `linear-gradient(45deg, rgba(255,193,7,1) 15%, rgba(244,67,54,1) 35%, rgba(156,39,176,1) 100%)`
      : props?.type === 'facebook'
      ? '#1877F2'
      : props?.type === 'x'
      ? '#000000'
      : props?.type === 'tiktok'
      ? '#222222'
      : 'white';

  const borderStyle = props?.selected
    ? '1px solid #40249B'
    : props?.viewMode
    ? '2px solid black'
    : '1px solid #D8D8D8';

  return {
    background: props?.isDragging ? 'white' : backgroundGradient,
    border: borderStyle,
    width: '140px',
    height: '290px',
    borderRadius: '8px !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  };
});

export const PlusIconStyled = styling(PlusIcon)(props => ({
  height: '30px',
  width: '30px'
}));

export const OuterWrapper = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 16px',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const SocialMediaWrapper = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  margin: 'auto'
}));

export const ReviewContainer = styling(Grid)(props => ({
  width: '596px',
  height: props?.errors ? '110px' : '88px',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end'
  // overflow: 'hidden'
  // border: "1px solid #40249B"
}));

export const WhiteContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  // gap: '16px',
  backgroundColor: 'white',
  width: '100%',
  padding: '8px 8px 8px 0px',
  color: `rgba(0, 0, 0, 0.60)`,
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '48px',
  borderRadius: '0px 0px 8px 8px',
  border: `${props?.errors ? '1px solid #F75443' : '1px solid #40249B'}`,
  borderTop: props?.errors ? '' : 'none'
}));

export const PurpleContainer = styling(Grid)(props => ({
  backgroundColor: '#512ACC1A',
  height: '100%',
  width: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
  paddingLeft: '8px',
  border: `1px solid #40249B`,
  borderBottom: 'none',
  borderRadius: '8px 8px 0px 0px'
}));

export const UrlText = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.60)`,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: '12px'
}));

export const TextFieldStyled = styling(TextField)(({ theme }) => ({
  width: '100%',
  fontSize: '12px',

  '& .MuiOutlinedInput-input': {
    padding: '14px 16px'
  },
  '& .MuiOutlinedInput-root': {
    fontSize: '12px',
    border: 'none',
    width: '100%',
    color: `rgba(0, 0, 0, 0.60)`
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}));

export const SaveDeleteContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const TrashIconStyled = styling(TrashIcon)(props => ({
  height: '20px',
  width: '20px',
  color: props?.disabled ? '#00000061' : '#000000DE'
}));

export const ExclamationTriangleIconStyled = styling(ExclamationTriangleIcon)(
  props => ({
    height: '20px',
    width: '20px'
  })
);

export const StatusContainer = styling(Grid)(props => ({
  backgroundColor: props?.selected ? 'white' : `rgba(81, 42, 204, 0.10)`,
  borderRadius: '8px 8px 0px 0px',
  height: '32px',
  width: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px 8px 0px 8px !important',
  position: 'relative',
  border: `${props?.errors ? '1px solid #F75443' : '1px solid transparent'}`,
  borderBottom: '1px solid transparent',
  bottom: props?.errors ? '-1.2px' : 'none'
  // marginRight: "2px"
}));

export const StatusWrapper = styling(Grid)(props => ({
  position: 'absolute',
  bottom: '0px',
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
  width: '100%'
  // backgroundColor: "teal"
}));

export const DialogTitleStyled = styling(DialogTitle)(props => ({
  padding: '16px 16px 16px 24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px'
}));

export const DialogContentStyled = styling(DialogContent)(props => ({
  padding: '16px 24px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  maxWidth: '464px'
}));

export const DialogNoteStyled = styling(DialogContent)(props => ({
  padding: '0px',
  color: 'rgba(0, 0, 0, 0.60)',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const DialogActionsStyled = styling(DialogTitle)(props => ({
  padding: '0px'
}));

export const DialogButton = styling('div')(props => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '16px',
  width: '100%'
}));

export const ButtonContinueEdit = styling(Button)(props => ({
  width: '200px',
  height: '48px',
  backgroundColor: '#FFF',
  borderRadius: '8px',
  border: '2px solid #222',
  color: '#222',
  fontSize: '16px',
  textTransform: 'none',
  fontWeight: '700',
  padding: '0 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',

  ':hover': {
    backgroundColor: '#FFF'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '130px',
    height: '30px',
    fontSize: '12px',
    padding: '0 8px'
  }
}));

export const ButtonText = styling(Button)(props => ({
  width: '200px',
  height: '48px',
  backgroundColor: 'transparent',
  borderRadius: '8px',
  color: '#222',
  fontSize: '16px',
  textTransform: 'none',
  fontWeight: '700',
  padding: '0 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',

  ':hover': {
    backgroundColor: 'transparent'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '130px',
    height: '30px',
    fontSize: '12px',
    padding: '0 10px'
  }
}));

export const ButtonSkipEdit = styling(Button)(props => ({
  width: '200px',
  height: '48px',
  backgroundColor: '#2E2E2E',
  borderRadius: '8px',
  color: '#FFF',
  fontSize: '16px',
  textTransform: 'none',
  fontWeight: '700',
  padding: '0 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',

  ':hover': {
    backgroundColor: '#2E2E2E'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '130px',
    height: '30px',
    fontSize: '12px',
    padding: '0 10px'
  }
}));

export const IconButtonStyled = styling(IconButton)(props => ({
  padding: '0'
}));

export const ContainerStyled = styling(Grid)(props => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px'
}));

export const TitleWrapper = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  width: '100%',
  justifyContent: 'space-between'
}));

export const Title = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.87)`,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '20px'
}));

export const Desc = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.87)`,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const ViewWrapper = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '32px',
  width: '100%',
  justifyContent: 'space-between',
  padding: '16px',
  backgroundColor: 'white',
  borderRadius: '8px',
  border: `1px solid #AEAEAE`,
  //   maxHeight: "432px",
  overflow: 'hidden'
}));

export const StoryWrapper = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  width: '100%',
  paddingRight: '32px',

  overflowY: 'scroll',
  maxHeight: '400px',
  '&::-webkit-scrollbar': {
    width: '4px' /* Width of the scrollbar */
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#D8D8D8' /* Color of the track */
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#512ACC' /* Color of the thumb */,
    borderRadius: '5px' /* Rounded corners */
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555' /* Color of the thumb on hover */
  }
}));

export const StoryTitle = styling(Grid)(props => ({
  color: '#512ACC',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '20px'
}));

export const CoverTitle = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.87)`,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '20px',
  letterSpacing: '0.1px'
}));

export const CoverDesc = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.87)`,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const CancelButton = styling(Button)(props => ({
  display: 'flex',
  height: '40px',
  padding: '0px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '8px',
  border: '1px solid #222',
  background: 'var(--Primary-White, #FFF)',
  textTransform: 'none',
  color: 'black',

  ':hover': {
    background: '#EFEFEF'
  }
}));

export const SequenceContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderTop: '1px solid #D8D8D8',
  padding: '16px',
  position: 'relative'
}));
