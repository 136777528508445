import { styled as styling } from '@mui/material/styles';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { CheckIcon } from '@heroicons/react/24/solid';

export const PageContainer = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',

  [props.theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}));

export const AnimatedContainer = styling('div')(props => ({
  position: 'relative',
  width: '50%',
  height: '100%',
  overflow: 'hidden',

  [props.theme.breakpoints.down('md')]: {
    width: '100%',
    height: '30rem'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '45vh'
  }
}));

export const Container2 = styling('div')(props => ({
  display: 'flex',
  width: '50%',
  opacity: 1,
  background: 'white',

  [props.theme.breakpoints.down('md')]: {
    width: '100%',
    margin: 'auto',
    height: 'calc(100% - 30rem)'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: 'auto',
    height: 'calc(100% - 45vh)'
  }
}));

export const SecAnimatedContainer = styling('div')(props => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  maxHeight: '1080px',
  margin: 'auto',
  transition: 'opacity 0.8s ease-in-out',
  opacity: 1
}));

export const ContentContainer = styling('div')(props => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  gap: '20px',
  padding: '50px 100px',
  justifyContent: 'space-evenly',
  animation: 'slideUp 0.8s ease-in-out',
  height: '100%',

  '@keyframes slideUp': {
    '0%': {
      opacity: '1',
      transform: 'translateY(100%)'
    },
    '100%': {
      opacity: '1',
      transform: 'translateY(0)'
    }
  },

  transform: props.slide ? 'translateY(-100%)' : 'translateY(0)',
  transition: 'transform 1s ease-in-out',

  [props.theme.breakpoints.down('md')]: {
    padding: '16px'
  }
}));

export const HeightSpacer = styling('div')(props => ({
  height: props.$height ? props.$height : '5px'
}));

export const ImageContainer = styling('div')(props => ({
  height: '100%',
  width: '50%',
  filter: 'drop-shadow(3px 3px black)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  '& :nth-of-type(1)': {
    transform: 'translate(-15%, 10%)',
    border: '2px solid black',
    borderRadius: '10px'
  },

  '& :nth-of-type(2)': {
    transform: 'translate(20%,-10%)',
    border: '2px solid black',
    borderRadius: '10px'
  },

  '& :nth-of-type(3)': {
    transform: 'translate(0%,-25% )',
    border: '2px solid black',
    borderRadius: '10px'
  },

  [props.theme.breakpoints.down('md')]: {
    maxWidth: '230px'
  },

  [props.theme.breakpoints.down(680)]: {
    maxWidth: '190px'
  },

  [props.theme.breakpoints.down('sm')]: {
    maxWidth: '140px'
  }
}));

export const Title = styling('h1')(props => ({
  fontSize: '42px',
  color: '#40249B',
  fontWeight: 700,
  margin: '0',
  lineHeight: '45px',

  transition: 'opacity 0.8s ease-in-out',
  opacity: props.out ? 0 : 1,

  [props.theme.breakpoints.down('md')]: {
    fontSize: '42px',
    textAlign: 'center'
  },

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    textAlign: 'center',
    lineHeight: '30px'
  }
}));

export const SecondContainer = styling('div')(props => ({
  position: 'relative',
  width: '100%',
  background: '#fff',
  height: '100%',
  padding: '50px 100px',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  justifyContent: 'center',
  animation: 'fadeIn ease 2s',

  '@keyframes fadeIn': {
    '0%': {
      opacity: '0',
      transfrom: 'translateY(-100)'
    },

    '100%': {
      opacity: '1',
      transfrom: 'translateY(0)'
    }
  },

  [props.theme.breakpoints.down('md')]: {
    width: '100%',
    height: 'calc(100% - 30rem)',
    padding: '40px 16px',
    maxWidth: '600px',
    margin: 'auto'
  },

  [props.theme.breakpoints.down('sm')]: {
    gap: '16px',
    width: '100%',
    height: 'calc(100% - 45vh)',
    padding: '16px 16px 32px 16px'
  }
}));

export const BackButton = styling(IconButton)(props => ({
  padding: '8px 0',
  ':hover': {
    background: 'none',
  },
  ':disabled': {
    cursor: 'not-allowed'
  }
}));

export const NextPageButton = styling(Button)(props => ({
  width: '200px',
  height: '48px',
  backgroundColor: '#2E2E2E',
  borderRadius: '100px',
  color: '#FFF',
  fontSize: '16px',
  textTransform: 'none',
  fontWeight: '700',
  padding: '0 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',

  ':disabled': {
    backgroundColor: '#D8D8D8',
    cursor: 'not-allowed',
    color: '#FFF',

    ':hover': {
      backgroundColor: '#D8D8D8'
    }
  },

  ':hover': {
    backgroundColor: '#222'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '180px',
    height: '36px',
    fontSize: '14px'
  }
}));

export const FooterContainer = styling(Box)(props => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0',
  alignItems: 'center',

  transition: 'opacity 0.8s ease-in-out',
  opacity: props.out ? 0 : 1
}));

export const KeyboardBackspaceIconStyled = styling(ArrowLeftIcon)(props => ({
  padding: '0',
  width: '24px',
  height: '24px',
  color: '#000'
}));

export const Background = styling('div')(props => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  zIndex: 0,
  position: 'absolute'
}));

export const BackgroundLeft = styling('div')(props => ({
  display: 'flex',
  width: '50%',
  height: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  zIndex: 0,
  position: 'absolute',

  backgroundImage: props.out
    ? `radial-gradient( circle at -15% 25%, #5E2ACC99 0px, transparent 90%)`
    : `radial-gradient( circle at 25% 25%, #5E2ACC99 0px, transparent 30%),
       radial-gradient( circle at 70% 70%, #5e2acc73 0px, transparent 30%)`,

  transform: props.out ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: 'transform 1.5s ease-in-out, background-image 2s ease-in-out',

  [props.theme.breakpoints.down('md')]: {
    width: '100%',
    height: '30rem',
    top: '0',
    left: '0',
    right: '0'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '45vh',
    top: '0',
    left: '0',
    right: '0'
  }
}));

export const BackgroundRight = styling('div')(props => ({
  display: 'flex',
  width: '50%',
  height: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  zIndex: 0,
  position: 'absolute',
  right: '0',
  background: '#fff',

  [props.theme.breakpoints.down('md')]: {
    width: '100%',
    height: 'calc(100% - 30rem)',
    bottom: '0',
    left: '0',
    right: '0'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '100%',
    height: 'calc(100% - 45vh)',
    bottom: '0',
    left: '0',
    right: '0'
  }
}));

export const MessageBrand = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  gap: '24px',
  alignSelf: 'stretch',

  transition: 'opacity 0.8s ease-in-out',
  opacity: props.out ? 0 : 1,

  [props.theme.breakpoints.down('sm')]: {
    gap: '16px'
  }
}));

export const MessageBrandBox = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  gap: '16px',
  alignSelf: 'stretch',

  [props.theme.breakpoints.down('sm')]: {
    gap: '8px'
  }
}));

export const MessageBrandTitle = styling('div')(props => ({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px',
  alignSelf: 'stretch',

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '20px',
  }
}));

export const Checklist = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  gap: '6px',
  alignSelf: 'stretch',

  [props.theme.breakpoints.down('sm')]: {
    gap: '2px'
  }
}));

export const ChecklistLine = styling('div')(props => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch',

  [props.theme.breakpoints.down('sm')]: {
    gap: '6px'
  }
}));

export const IconBox = styling('div')(props => ({
  display: 'flex',
  width: '24px',
  height: '24px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',


  [props.theme.breakpoints.down('sm')]: {
    width: '20px',
    height: '20px',
  }
}));

export const CheckIconStyled = styling(CheckIcon)(props => ({
  color: '#512ACC',
  width: '20px',
  height: '20px',

  [props.theme.breakpoints.down('sm')]: {
    width: '16px',
    height: '16px',
    flexShrink: '0',
  }
}));

export const ChecklistText = styling('div')(props => ({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  width: 'calc(100% - 20px)',

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0px',
  }
}));

export const Message = styling('div')(props => ({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px',
  alignSelf: 'stretch',

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: 'unset',
  }
}));

export const MessageGrey = styling('div')(props => ({
    color: 'rgba(0, 0, 0, 0.60)',
    fontSize: '16px',
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    alignSelf: 'stretch',

    [props.theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.15px',
    }
}));

