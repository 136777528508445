import React from 'react';
import { Avatar, Card, CardMedia, Grid } from '@mui/material';
import SkeletonLoader from './SkeletonLoader';
import styles from './index.module.scss';

const TalentInfoCard = ({ talent, isTalentsEmpty, loading, isSelected }) => (
  <div className={styles.talentInfoWrapper}>
    {isTalentsEmpty ? (
      isSelected === null && loading && <SkeletonLoader />
    ) : (
      <div>
        {!isTalentsEmpty && isSelected !== null ? (
          <Card className={styles.talentInfoContainer}>
            <Grid container>
              <Grid item sm={12} md={12} lg={talent?.videoCvOptimized ? 6 : 12}>
                <div className={styles.talentInfoLeftTop}>
                  <Avatar
                    className={styles.talentAvatar}
                    key={talent?.profileImageSquare}
                    src={talent?.profileImageSquare}
                  />
                  <div>
                    <p>{talent?.age} years old</p>
                    <p>{talent?.nationality}</p>
                  </div>
                </div>
                <div className={styles.talentInfoLeftBottom}>
                  <p className={styles.talentFieldTitle}>
                    Graduation Year:{' '}
                    <span className={styles.talentFieldAnswer}>
                      {talent?.graduationYear}
                    </span>
                  </p>
                  <p className={styles.talentFieldTitle}>
                    Current Company:{' '}
                    <span className={styles.talentFieldAnswer}>
                      {talent?.currentCompany}
                    </span>
                  </p>
                  <p className={styles.talentFieldTitle}>
                    Highest Level of Education:{' '}
                    <span className={styles.talentFieldAnswer}>
                      {talent?.highestEducationLevel}
                    </span>
                  </p>
                  <p className={styles.talentFieldTitle}>
                    Educational Institute:{' '}
                    <span className={styles.talentFieldAnswer}>
                      {talent?.educationalInstitution}
                    </span>
                  </p>
                  <p className={styles.talentFieldTitle}>
                    Field of Study:{' '}
                    <span className={styles.talentFieldAnswer}>
                      {talent?.fieldOfStudy}
                    </span>
                  </p>
                  <p className={styles.talentFieldTitle}>
                    Major:{' '}
                    <span className={styles.talentFieldAnswer}>
                      {talent?.major}
                    </span>
                  </p>
                </div>
              </Grid>
              {talent?.videoCvOptimized && (
                <Grid item sm={12} md={12} lg={6}>
                  <div className={styles.talentInfoRight}>
                    <p className={styles.videoCoverLetter}>
                      Video Cover Letter
                    </p>
                    <CardMedia
                      component="video"
                      controls
                      className={styles.videoBorderRadius}
                      src={talent?.videoCvOptimized}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
          </Card>
        ) : null}
      </div>
    )}
  </div>
);

export default TalentInfoCard;
