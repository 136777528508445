import React, { useEffect, useState } from 'react';
import {
  CloseIconStyled,
  DescriptionStyled,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
  IconButtonStyled,
  TitleContainer,
  TitleStyled
} from './styles';
import { Box, Grid, Typography, LinearProgress } from '@mui/material';
import { ButtonText } from '../../../../../components/VerifyPopUp/styles';
import { VimeoVideo } from '../VimeoVideo';
import { CameraIcon } from '@heroicons/react/24/outline';
import { useSetVimeoThumbnail } from '../../hooks/useSetVimeoThumbnail';
import { SaveButton } from '../../../../Jobs/PopUps/EditBannerPopUp/styles';

export default function ThumbnailPopUp(props) {
  const { open, handleClose, vimeoId, setCapturedThumbnail } = props;
  const [seconds, setSeconds] = useState(0);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [completed, setCompleted] = useState(false);

  const captureFrame = async () => {
    setCapturedThumbnail(false);
    setCompleted(false);
    setLoading(true);
    setProgress(0);
    const data = await useSetVimeoThumbnail(vimeoId, seconds);
    if (data) {
      setCapturedThumbnail(true);
      setCompleted(true);
      setProgress(100);
      setTimeout(() => {
        setProgress(0);
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    let timer;
    let timeout;

    if (open && loading && !completed) {
      timer = setInterval(() => {
        setProgress(oldProgress => {
          if (oldProgress >= 83) {
            clearInterval(timer);
            return 83;
          } else {
            const diff = Math.random() * 10;
            return Math.min(oldProgress + diff, 83);
          }
        });
      }, 500);
    }

    return () => {
      clearInterval(timer);
      clearTimeout(timeout);
    };
  }, [open, loading, completed]);

  return (
    <DialogStyled
      PaperProps={{
        style: {
          width: '688px !important'
        }
      }}
      open={open}
      onClose={handleClose}>
      <DialogTitleStyled>
        <TitleContainer>
          <TitleStyled>Choose Video Thumbnail</TitleStyled>
          <DescriptionStyled>
            Please note that any changes made will be reflected immediately.
          </DescriptionStyled>
        </TitleContainer>
        <IconButtonStyled
          aria-label="close"
          onClick={handleClose}
          sx={{ padding: '0px' }}>
          <CloseIconStyled />{' '}
        </IconButtonStyled>
      </DialogTitleStyled>

      <DialogContentStyled dividers>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}>
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'row',
              height: '325px'
            }}>
            <Grid
              sx={{
                width: '476px',
                padding: '16px 24px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px'
              }}>
              <Typography sx={{ fontSize: '14px' }}>
                Play the video and capture thumbnail:
              </Typography>

              <Grid
                sx={{
                  border: '1px solid #D8D8D8',
                  borderRadius: '8px',
                  overflow: 'hidden'
                }}>
                <VimeoVideo
                  videoId={vimeoId}
                  setSeconds={setSeconds}
                  completed={completed}
                />
              </Grid>
            </Grid>

            <Grid
              sx={{
                backgroundColor: '#FAFAFA',
                width: '240px',
                padding: '20px 24px 24px 24px',
                borderLeft: '1px solid #D8D8D8',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px'
              }}>
              <Grid
                sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                <Typography sx={{ fontSize: '14px' }}>
                  Preview thumbnail:
                </Typography>

                <Grid
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: '#F5F5F5',
                    alignItems: 'center',
                    border: '1px solid #E4E4E4',
                    borderRadius: '8px',
                    height: '159px'
                  }}>
                  {loading ? (
                    <Grid
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '8px',
                        color: `rgba(0, 0, 0, 0.60)`,
                        fontSize: '12px'
                      }}>
                      Loading...
                      <Box sx={{ width: '126px' }}>
                        <LinearProgress
                          variant="determinate"
                          value={progress}
                          sx={{
                            borderRadius: '100px !important',
                            '&.MuiLinearProgress-colorPrimary': {
                              backgroundColor: '#D8D8D8'
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                  ) : (
                    <VimeoVideo
                      videoId={vimeoId}
                      width={172}
                      height={98}
                      noAbsolute
                      border
                    />
                  )}
                </Grid>
              </Grid>

              <Grid
                sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <SaveButton
                  sx={{ fontWeight: 'bold', fontSize: '12px' }}
                  onClick={() => captureFrame()}>
                  Capture frame
                  <CameraIcon width={16} height={16} />
                </SaveButton>
                <ButtonText
                  sx={{ height: '32px', fontSize: '12px' }}
                  onClick={() => {
                    handleClose();
                  }}>
                  Done & Close
                </ButtonText>
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '24px',
              width: '100%',
              justifyContent: 'center',
              padding: '16px 0px'
            }}>
            <CancelButton sx={{ height: '36px' }} onClick={() => handleClose()}>
              Cancel
            </CancelButton>
            <SaveButton
              sx={{ height: '36px' }}
              onClick={() => {
                handleClose();
              }}>
              Save
            </SaveButton>
          </Grid> */}
        </Grid>
      </DialogContentStyled>
    </DialogStyled>
  );
}
