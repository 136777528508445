import * as types from '../types/company_type';

const initialState = {
  errorMessage: null,
  isDeletePhotoPopup: false,
  isShowOfficeGuidePopup: false,
  deletingCompanyImage: false,
  isImageModalOpen: false,
  fetchingCompanySettings: false,
  myCompanySettings: {},
  fetchingAccountInfo: false,
  accountInfo: {},
  fetchingMyCompany: false,
  myCompanyStatus: {},
  fetchingIndustries: false,
  industries: [],
  teamMemberSpecs: [],

  // FETCH COMPANY SIZE
  companySizes: [],
  fetchingCompanySizes: false,

  // FETCH COMPANY PROFILE
  companyProfile: [],
  fetchingCompanyProfile: false,

  // FETCH ICONS
  icons: [],
  fetchingIcons: false,

  // FETCH COMPANY TEAM MEMBERS
  companyTeamMembers: [],
  fetchingCompanyTeamMembers: false,

  // FETCH EXPERIENCES
  experiences: [],
  fetchingExperiences: false,

  // FETCH JOB TYPES
  jobTypes: [],
  fetchingJobTypes: false,

  // FETCH STATE REGIONS
  myStateRegions: [],
  sgStateRegions: [],
  allStateRegions: [],
  allCountryStates: {},
  fetchingStateRegions: false,

  // FETCH TRACKS
  tracks: [],
  fetchingTracks: false,

  // FETCH CAREER LEVELS
  careerLevels: [],
  fetchingCareerLevels: false,

  // FETCH ACCESS LEVELS
  accessLevels: [],
  fetchingAccessLevels: false,

  // FETCH COMPANY NAME
  myCompany: {},
  fetchingMyCompany: false,

  // FETCH COMPANY AFFILIATIONS
  companyAffiliations: [],
  fetchingCompanyAffiliations: false,

  // FETCH COMPANY TYPES
  companyTypes: [],
  fetchingCompanyTypes: false,

  // PREPARE FILE UPLOAD
  prepareFileUpload: [],
  fetchingPrepareFileUpload: false,

  // MUTATE COMPANY DETAILS
  mutatingCompanyDetails: false,
  mutateCompanyDetailsSuccess: false,

  // MUTATE COMPANY TEAM MEMBER
  mutatingCompanyTeamMember: false,
  mutatedCompanyTeamMember: [],

  // MUTATE REMOVE TEAM MEMBER
  mutatingRemoveTeamMember: false,
  removeCompanyTeamMember: null,

  // MUTATE COMPANY AFFILIATION
  companyAffiliate: {},
  mutatingCompanyAffiliate: false,

  // MUTATE DELETE COMPANY AFFILIATION
  deleteCompanyAffiliation: null,

  // MUTATE UPDATE COMPANY AFFILIATION
  changeCompanyAffiliation: '',

  // MUTATE SEND ENQUIRY EMAIL
  enquiryEmailSent: null,
  mutatingEnquiryEmailSent: false,

  // MUTATE SEND ENQUIRY EMAIL
  mutatingEmployerPassword: false,
  resetEmployerPassword: {},

  // MUTATE EMPLOYER LOGIN
  mutatingEmployerLogin: false,
  loginEmployer: {},

  // MUTATE EMPLOYER COMPANY REGISTRATION
  mutatingRegisterCompany: false,
  registerCompany: {},

  // For new onboarding
  currentOnboardingPage: 'waitlist',
  currentBg: 0,

  //MUTATE_SEND_EMAIL_WITHOUT_LOGIN
  mutatingEnquiryEmailSent: false,
  enquiryEmailSent: false,

  directRegister: 0,
  formValue: {
    name: '',
    companyName: '',
    email: '',
    phoneNumber: '',
    inviteCode: '',
    companyMission: '',
    companyVision: '',
    referralCode: '',
    coreValues: '',
    organisationalCulture: '',
    currentWorkingArrangement: ''
  },
  fetchingWaitlist: false,
  termsPopUp: false,
  // Country
  country: '',
  video: '',

  // Company canvas
  videoId: '',
  companyCompletion: {},
  storedVimeoId: '',

  uploadedVimeo: [],
  socialMediaGuideline: 0
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.OPEN_DELETE_PHOTO_POPUP: {
      return {
        ...state,
        isDeletePhotoPopup: action.status
      };
    }
    case types.OPEN_OFFICE_IMAGE_GUIDE_POPUP: {
      return {
        ...state,
        isShowOfficeGuidePopup: action.status
      };
    }
    case types.DELETING_COMPANY_OFFICE_IMAGE: {
      return {
        ...state,
        deletingCompanyImage: true
      };
    }
    case types.DELETE_COMPANY_IMAGE_SUCCEEDED: {
      return {
        ...state,
        deletingCompanyImage: false
      };
    }
    case types.DELETE_COMPANY_IMAGE_FAILED: {
      return {
        ...state,
        deletingCompanyImage: false
      };
    }
    case types.UPDATE_IMAGE_MODAL_STATUS: {
      return {
        ...state,
        isImageModalOpen: action.status
      };
    }
    case types.FETCH_MY_COMPANY_SETTINGS: {
      return {
        ...state,
        fetchingCompanySettings: true
      };
    }
    case types.MUTATE_MY_COMPANY_SETTINGS: {
      return {
        ...state,
        fetchingCompanySettings: true
      };
    }
    case types.FETCH_MY_COMPANY_SETTINGS_SUCCEED: {
      return {
        ...state,
        fetchingCompanySettings: false,
        myCompanySettings: action.companySettings
      };
    }
    case types.FETCH_MY_COMPANY_SETTINGS_FAILED: {
      return {
        ...state,
        fetchingCompanySettings: false
      };
    }
    case types.FETCH_ACCOUNT_INFO: {
      return {
        ...state,
        fetchingAccountInfo: true
      };
    }
    case types.FETCH_ACCOUNT_INFO_SUCCEED: {
      return {
        ...state,
        fetchingAccountInfo: false,
        accountInfo: action.accountInfo
      };
    }
    case types.FETCH_ACCOUNT_INFO_FAILED: {
      return {
        ...state,
        fetchingAccountInfo: false
      };
    }
    case types.FETCH_MY_COMPANY_STATUS: {
      return {
        ...state,
        fetchingMyCompany: true
      };
    }
    case types.FETCH_MY_COMPANY_STATUS_SUCCEED: {
      return {
        ...state,
        fetchingMyCompany: false,
        myCompanyStatus: action.company
      };
    }
    case types.FETCH_MY_COMPANY_STATUS_FAILED: {
      return {
        ...state,
        fetchingMyCompany: false
      };
    }
    case types.FETCH_INDUSTRIES: {
      return {
        ...state,
        fetchingIndustries: true
      };
    }

    case types.FETCH_INDUSTRIES_SUCCEED: {
      return {
        ...state,
        fetchingIndustries: false,
        industries: action.industries
      };
    }
    case types.FETCH_INDUSTRIES_FAILED: {
      return {
        ...state,
        fetchingIndustries: false
      };
    }
    case types.UPDATE_COMPANY_AVAILABLE_COINS: {
      let cStatus = { ...state.myCompanyStatus };

      cStatus.availableCoins = action.coins;

      return {
        ...state,
        myCompanyStatus: cStatus
      };
    }
    case types.UPDATE_TEAM_MEMBER_SPECS_SUCCESS: {
      return {
        ...state,
        teamMemberSpecs: action.specialisations
      };
    }

    // FETCH COMPANY SIZES
    case types.FETCH_COMPANY_SIZES: {
      return {
        ...state,
        fetchingCompanySizes: true
      };
    }
    case types.FETCH_COMPANY_SIZES_SUCCEED: {
      return {
        ...state,
        fetchingCompanySizes: false,
        companySizes: action.companySizes
      };
    }
    case types.FETCH_COMPANY_SIZES_FAILED: {
      return {
        ...state,
        fetchingCompanySizes: false
      };
    }

    // FETCH COMPANY PROFILE
    case types.FETCH_COMPANY_PROFILE: {
      return {
        ...state,
        fetchingCompanyProfile: true
      };
    }

    case types.MUTATE_COMPANY_PROFILE: {
      return {
        ...state,
        fetchingCompanyProfile: true
      };
    }

    case types.FETCH_COMPANY_PROFILE_SUCCEED: {
      return {
        ...state,
        fetchingCompanyProfile: false,
        companyProfile: action.companyProfile,
        errorMessage: null
      };
    }
    case types.FETCH_COMPANY_PROFILE_FAILED: {
      return {
        ...state,
        fetchingCompanyProfile: false,
        errorMessage: action.errorMessage
      };
    }

    // FETCH ICONS
    case types.FETCH_ICONS: {
      return {
        ...state,
        fetchingIcons: true
      };
    }
    case types.FETCH_ICONS_SUCCEED: {
      return {
        ...state,
        fetchingIcons: false,
        icons: action.icons
      };
    }
    case types.FETCH_ICONS_FAILED: {
      return {
        ...state,
        fetchingIcons: false
      };
    }

    // FETCH COMPANY TEAM MEMBERS
    case types.FETCH_COMPANY_TEAM_MEMBERS: {
      return {
        ...state,
        fetchingCompanyTeamMembers: true
      };
    }
    case types.FETCH_COMPANY_TEAM_MEMBERS_SUCCEED: {
      return {
        ...state,
        fetchingCompanyTeamMembers: false,
        companyTeamMembers: action.companyTeamMembers
      };
    }
    case types.FETCH_COMPANY_TEAM_MEMBERS_FAILED: {
      return {
        ...state,
        fetchingCompanyTeamMembers: false
      };
    }

    // FETCH EXPERIENCES
    case types.FETCH_EXPERIENCES: {
      return {
        ...state,
        fetchingExperiences: true
      };
    }
    case types.FETCH_EXPERIENCES_SUCCEED: {
      return {
        ...state,
        fetchingExperiences: false,
        experiences: action.experiences
      };
    }
    case types.FETCH_EXPERIENCES_FAILED: {
      return {
        ...state,
        fetchingExperiences: false
      };
    }

    // FETCH JOB TYPES
    case types.FETCH_JOB_TYPES: {
      return {
        ...state,
        fetchingJobTypes: true
      };
    }
    case types.FETCH_JOB_TYPES_SUCCEED: {
      return {
        ...state,
        fetchingJobTypes: false,
        jobTypes: action.jobTypes
      };
    }
    case types.FETCH_JOB_TYPES_FAILED: {
      return {
        ...state,
        fetchingJobTypes: false
      };
    }

    // FETCH STATE REGIONS
    case types.FETCH_LOCATIONS: {
      return {
        ...state,
        fetchingStateRegions: true
      };
    }
    case types.FETCH_LOCATIONS_SUCCEED: {
      let myStateRegions = action.countryStateRegions['my'];
      let sgStateRegions = action.countryStateRegions['sg'];

      return {
        ...state,
        fetchingStateRegions: false,
        myStateRegions: myStateRegions,
        sgStateRegions: sgStateRegions,
        allStateRegions: [...myStateRegions, ...sgStateRegions],
        allCountryStates: action.countryStateRegions
      };
    }
    case types.FETCH_LOCATIONS_FAILED: {
      return {
        ...state,
        fetchingStateRegions: false
      };
    }

    // FETCH TRACKS
    case types.FETCH_TRACKS: {
      return {
        ...state,
        fetchingTracks: true
      };
    }
    case types.FETCH_TRACKS_SUCCEED: {
      return {
        ...state,
        fetchingTracks: false,
        tracks: action.tracks
      };
    }
    case types.FETCH_TRACKS_FAILED: {
      return {
        ...state,
        fetchingTracks: false
      };
    }

    // FETCH CAREER LEVELS
    case types.FETCH_CAREER_LEVEL: {
      return {
        ...state,
        fetchingCareerLevels: true
      };
    }
    case types.FETCH_CAREER_LEVEL_SUCCEED: {
      return {
        ...state,
        fetchingCareerLevels: false,
        careerLevels: action.careerLevels
      };
    }
    case types.FETCH_CAREER_LEVEL_FAILED: {
      return {
        ...state,
        fetchingCareerLevels: false
      };
    }

    // FETCH ACCESS LEVELS
    case types.FETCH_ACCESS_LEVEL: {
      return {
        ...state,
        fetchingAccessLevels: true
      };
    }
    case types.FETCH_ACCESS_LEVEL_SUCCEED: {
      return {
        ...state,
        fetchingAccessLevels: false,
        accessLevels: action.accessLevels
      };
    }
    case types.FETCH_ACCESS_LEVEL_FAILED: {
      return {
        ...state,
        fetchingAccessLevels: false
      };
    }
    // FETCH COMPANY NAME
    case types.FETCH_COMPANY_NAME: {
      return {
        ...state,
        fetchingMyCompany: true
      };
    }
    case types.FETCH_COMPANY_NAME_SUCCEED: {
      return {
        ...state,
        fetchingMyCompany: false,
        myCompany: action.myCompany
      };
    }
    case types.FETCH_COMPANY_NAME_FAILED: {
      return {
        ...state,
        fetchingMyCompany: false
      };
    }
    // FETCH COMPANY AFFILIATIONS
    case types.FETCH_COMPANY_AFFILIATIONS: {
      return {
        ...state,
        fetchingCompanyAffiliations: true
      };
    }
    case types.FETCH_COMPANY_AFFILIATIONS_SUCCEED: {
      return {
        ...state,
        fetchingCompanyAffiliations: false,
        companyAffiliations: action.companyAffiliations
      };
    }
    case types.FETCH_COMPANY_AFFILIATIONS_FAILED: {
      return {
        ...state,
        fetchingCompanyAffiliations: false
      };
    }
    // FETCH COMPANY AFFILIATIONS
    case types.FETCH_COMPANY_TYPES: {
      return {
        ...state,
        fetchingCompanyTypes: true
      };
    }
    case types.FETCH_COMPANY_TYPES_SUCCEED: {
      return {
        ...state,
        fetchingCompanyTypes: false,
        companyTypes: action.companyTypes
      };
    }
    case types.FETCH_COMPANY_TYPES_FAILED: {
      return {
        ...state,
        fetchingCompanyTypes: false
      };
    }

    // PREPARE FILE UPLOAD
    case types.PREPARE_FILE_UPLOAD: {
      return {
        ...state,
        fetchingPrepareFileUpload: true
      };
    }
    case types.PREPARE_FILE_UPLOAD_SUCCEED: {
      return {
        ...state,
        fetchingPrepareFileUpload: false,
        prepareFileUpload: action.prepareFileUpload
      };
    }
    case types.PREPARE_FILE_UPLOAD_SUCCEED: {
      return {
        ...state,
        fetchingPrepareFileUpload: false
      };
    }

    // MUTATE COMPANY DETAILS
    case types.MUTATE_COMPANY_DETAILS: {
      return {
        ...state,
        mutatingCompanyDetails: true
      };
    }
    case types.MUTATE_COMPANY_DETAILS_SUCCEED: {
      return {
        ...state,
        mutatingCompanyDetails: false,
        mutateCompanyDetailsSuccess: action.mutateCompanyDetailsSuccess
      };
    }
    case types.MUTATE_COMPANY_DETAILS_FAILED: {
      return {
        ...state,
        mutatingCompanyDetails: false,
        success: false
      };
    }

    // MUTATE COMPANY TEAM MEMBER
    case types.MUTATE_COMPANY_TEAM_MEMBER: {
      return {
        ...state,
        mutatingCompanyTeamMember: true
      };
    }
    case types.MUTATE_COMPANY_TEAM_MEMBER_SUCCEED: {
      return {
        ...state,
        mutatingCompanyTeamMember: false,
        mutatedCompanyTeamMember: action.companyTeamMember
      };
    }
    case types.MUTATE_COMPANY_TEAM_MEMBER_FAILED: {
      return {
        ...state,
        mutatingCompanyTeamMember: false
      };
    }

    // MUTATE REMOVE TEAM MEMBER
    case types.MUTATE_REMOVE_TEAM_MEMBER: {
      return {
        ...state,
        mutatingRemoveTeamMember: true
      };
    }
    case types.MUTATE_REMOVE_TEAM_MEMBER_SUCCEED: {
      return {
        ...state,
        mutatingRemoveTeamMember: false,
        removeCompanyTeamMember: action.removeCompanyTeamMember
      };
    }
    case types.MUTATE_REMOVE_TEAM_MEMBER_FAILED: {
      return {
        ...state,
        mutatingRemoveTeamMember: false
      };
    }

    // MUTATE COMPANY AFFILIATION
    case types.MUTATE_COMPANY_AFFILIATION: {
      return {
        ...state,
        mutatingCompanyAffiliate: true
      };
    }
    case types.MUTATE_COMPANY_AFFILIATION_SUCCEED: {
      return {
        ...state,
        mutatingCompanyAffiliate: false,
        companyAffiliate: action.companyAffiliate
      };
    }
    case types.MUTATE_COMPANY_AFFILIATION_FAILED: {
      return {
        ...state,
        mutatingCompanyAffiliate: false
      };
    }

    // MUTATE DELETE COMPANY AFFILIATION
    case types.MUTATE_COMPANY_AFFILIATION: {
      return {
        ...state,
        mutatingCompanyAffiliate: true
      };
    }
    case types.MUTATE_COMPANY_AFFILIATION_SUCCEED: {
      return {
        ...state,
        mutatingCompanyAffiliate: false,
        deleteCompanyAffiliation: action.deleteCompanyAffiliation
      };
    }
    case types.MUTATE_COMPANY_AFFILIATION_FAILED: {
      return {
        ...state,
        mutatingCompanyAffiliate: false
      };
    }

    // MUTATE UPDATE COMPANY AFFILIATION
    case types.MUTATE_UPDATE_COMPANY_AFFILIATION: {
      return {
        ...state,
        mutatingCompanyAffiliate: true
      };
    }
    case types.MUTATE_UPDATE_COMPANY_AFFILIATION_SUCCEED: {
      return {
        ...state,
        mutatingCompanyAffiliate: false,
        changeCompanyAffiliation: action.changeCompanyAffiliation
      };
    }
    case types.MUTATE_UPDATE_COMPANY_AFFILIATION_FAILED: {
      return {
        ...state,
        mutatingCompanyAffiliate: false
      };
    }

    case types.UPDATE_DIRECT_REGISTER: {
      return {
        ...state,
        directRegister: action.value
      };
    }

    case types.UPDATE_CURRENT_ONBOARDING_PAGE: {
      return {
        ...state,
        currentOnboardingPage: action.page
      };
    }

    case types.UPDATE_CURRENT_BACKGROUND: {
      return {
        ...state,
        currentBg: action.value
      };
    }

    case types.UPDATE_ONBOARDING_FIELDS: {
      return {
        ...state,
        onboardingFields: { ...action.payload },
        formValue: { ...action.payload }
      };
    }

    case types.SAVE_COMPANY_WAITLIST: {
      return {
        ...state,
        companyWaitlist: true
      };
    }
    case types.SAVE_COMPANY_WAITLIST_SUCCEEDED: {
      return {
        ...state,
        companyWaitlist: false,
        formValue: action.waitlistValue
      };
    }
    case types.SAVE_COMPANY_WAITLIST_FAILED: {
      return {
        ...state,
        companyWaitlist: false
      };
    }

    case types.FETCH_WAITLIST_INFO: {
      return {
        ...state,
        fetchingWaitlist: true
      };
    }

    case types.FETCH_WAITLIST_INFO_SUCCEED: {
      return {
        ...state,
        fetchingWaitlist: false,
        formValue: action.currentValue
      };
    }
    case types.FETCH_WAITLIST_INFO_FAILED: {
      return {
        ...state,
        fetchingWaitlist: false
      };
    }

    //MUTATE_SEND_EMAIL_WITHOUT_LOGIN
    case types.MUTATE_SEND_EMAIL_WITHOUT_LOGIN: {
      return {
        ...state,
        mutatingEnquiryEmailSent: true
      };
    }
    case types.MUTATE_SEND_EMAIL_WITHOUT_LOGIN_SUCCEED: {
      return {
        ...state,
        mutatingEnquiryEmailSent: false,
        enquiryEmailSent: action.enquiryEmailSent
      };
    }
    case types.MUTATE_SEND_EMAIL_WITHOUT_LOGIN_FAILED: {
      return {
        ...state,
        mutatingEnquiryEmailSent: false
      };
    }

    // MUTATE SEND ENQUIRY EMAIL
    case types.MUTATE_RESET_EMPLOYER_PASSWORD: {
      return {
        ...state,
        mutatingEmployerPassword: true
      };
    }
    case types.MUTATE_RESET_EMPLOYER_PASSWORD_SUCCEED: {
      return {
        ...state,
        mutatingEmployerPassword: false,
        resetEmployerPassword: action.resetEmployerPassword
      };
    }
    case types.MUTATE_RESET_EMPLOYER_PASSWORD_FAILED: {
      return {
        ...state,
        mutatingEmployerPassword: false
      };
    }

    // MUTATE EMPLOYER LOGIN
    case types.MUTATE_EMPLOYER_LOGIN: {
      return {
        ...state,
        mutatingEmployerLogin: true
      };
    }
    case types.MUTATE_EMPLOYER_LOGIN_SUCCEED: {
      return {
        ...state,
        mutatingEmployerLogin: false,
        loginEmployer: action.loginEmployer
      };
    }
    case types.MUTATE_EMPLOYER_LOGIN_FAILED: {
      return {
        ...state,
        mutatingEmployerLogin: false
      };
    }

    // MUTATE EMPLOYER COMPANY REGISTRATION
    case types.MUTATE_EMPLOYER_COMPANY_REGISTRATION: {
      return {
        ...state,
        mutatingRegisterCompany: true
      };
    }
    case types.MUTATE_EMPLOYER_COMPANY_REGISTRATION_SUCCEED: {
      return {
        ...state,
        mutatingRegisterCompany: false,
        registerCompany: action.registerCompany
      };
    }
    case types.MUTATE_EMPLOYER_COMPANY_REGISTRATION_FAILED: {
      return {
        ...state,
        mutatingRegisterCompany: false
      };
    }
    case types.CLEAR_ACCOUNT_INFO: {
      return {
        ...state,
        accountInfo: {},
        myCompanySettings: {},
        myCompanyStatus: {},
        myCompany: {}
      };
    }
    case types.UPDATE_COUNTRY_DROPDOWN: {
      return {
        ...state,
        country: action.country
      };
    }

    case types.UPDATE_TERMS_POPUP: {
      return {
        ...state,
        termsPopUp: action.status
      };
    }

    case types.SELECT_MODAL_VIDEO: {
      return {
        ...state,
        video: action.video
      };
    }

    case types.EXPEND_VIDEO_ACCORDION: {
      return {
        ...state,
        videoId: action.videoId
      };
    }

    case types.COMPANY_SIDEBAR_COMPLETION: {
      return {
        ...state,
        companyCompletion: {
          ...state?.companyCompletion,
          ...action.companyCompletion
        }
      };
    }

    case types.COMPANY_VIDEO_STATUS: {
      return {
        ...state,
        storedVimeoId: action?.storedVimeoId
      };
    }

    case types.COMPANY_VIMEO_VIDEO: {
      return {
        ...state,
        uploadedVimeo: action?.uploadedVimeo
      };
    }

    case types.COMPANY_SOCIAL_MEDIA_GUIDELINE: {
      return {
        ...state,
        socialMediaGuideline: action?.socialMediaGuideline
      };
    }

    default: {
      return state;
    }
  }
};
