import { Button, DialogContent, IconButton, Typography } from '@mui/material';
import { Dialog, DialogTitle } from '@mui/material';
import { styled as styling } from '@mui/material/styles';

export const DialogStyled = styling(Dialog)(props => ({
  borderRadius: '16px',
  '& .MuiPaper-root': {
    minWidth: '600px',
    minHeight: '600px',
    borderRadius: '16px'
  }
}));

export const DialogTitleStyled = styling(DialogTitle)(props => ({
  padding: '16px 16px 16px 24px',
  borderBottom: '1px solid #D8D8D8'
}));

export const DialogContentStyled = styling(DialogContent)(props => ({
  padding: '24px 24px 0px 24px'
}));

export const DialogCloseButton = styling(IconButton)(props => ({
  position: 'absolute',
  top: 8,
  right: 8
}));

export const DialogTitleText = styling(Typography)(props => ({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '24px',
  letterSpacing: '0.1px'
}));

export const SubmitButton = styling(Button)(props => ({
  borderRadius: '8px',
  backgroundColor: '#2E2E2E',
  width: '200px',
  height: '40px',
  padding: '0 16px',
  color: 'white',
  fontWeight: '700',
  textTransform: 'capitalize',
  fontSize: '14px',
  fontFamily: 'Inter'
}));
