import { ShieldExclamationIcon } from '@heroicons/react/24/outline';
import { Grid, Typography } from '@mui/material';
import { styled as styling } from '@mui/material/styles';

export const VerificationBanner = styling(Grid)(props => ({
  backgroundColor: '#FDF3DF',
  padding: '20px 16px',
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: "1px solid #00000010"
}));

export const VerificationText = styling(Typography)(props => ({
  fontSize: '16px',
  fontWeight: 400,
  color: `rgba(0, 0, 0, 0.87)`,
  letterSpacing: '0.15px'
}));

export const VerificationLink = styling('a')(props => ({
  fontSize: '16px',
  fontWeight: 700,
  color: `rgba(0, 0, 0, 0.87)`,
  letterSpacing: '0.15px',
  textDecoration: 'underline',
  cursor: 'pointer'
}));

export const ShieldExclamationIconStyled = styling(ShieldExclamationIcon)({
  color: '#E4BD32',
  height: 24,
  width: 24
});
