import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../styles/colors.module.scss';

export const Wrapper = styling('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '30px',
}));

export const Container = styling('div')(() => ({
  width: '600px',
  backgroundColor: Colors.priWhite,
  padding: '30px',
  borderRadius: '20px',
}));

export const Title = styling('span')(() => ({
  fontFamily: 'Inter',
  fontWeight: 700,
  fontSize: '16px',
  letterSpacing: '0.1px',
}));

export const Text = styling('span')((props) => ({
  ...(props.type == 'question' && {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '0.15px',
  }),
  ...(props.type == 'checkbox' && {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '0.15px',
  }),
}));
