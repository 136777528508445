import { styled as styling } from '@mui/material/styles';
import { Grid, Typography, Alert } from '@mui/material';
import { InformationCircleIcon as SolidInfo } from '@heroicons/react/24/solid';
import {
  InformationCircleIcon as OutlineInfo,
  PlusIcon,
  PencilIcon,
  AdjustmentsHorizontalIcon,
  PlusCircleIcon
} from '@heroicons/react/24/outline';

export const ContainerStyled = styling(Grid)(props => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px'
}));

export const TitleWrapper = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  width: '100%',
  justifyContent: 'space-between'
}));

export const CoverTitle = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.87)`,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px'
}));

export const CoverDesc = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.87)`,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const SolidInformationIcon = styling(SolidInfo)(props => ({
  height: '24px',
  width: '24px',
  color: '#213DB5'
}));

export const OutlineInformationIcon = styling(OutlineInfo)(props => ({
  height: '24px',
  width: '24px'
}));

export const PlusIconStyled = styling(PlusIcon)(props => ({
  height: '24px',
  width: '24px'
}));

export const PencilIconStyled = styling(PencilIcon)(props => ({
  height: '24px',
  width: '24px'
}));

export const PlusCircleIconStyled = styling(PlusCircleIcon)(props => ({
  height: '40px',
  width: '40px',
  strokeWidth: '1px'
}));

export const AdjustmentsHorizontalIconStyled = styling(
  AdjustmentsHorizontalIcon
)(props => ({
  height: '24px',
  width: '24px'
}));

export const AlertStyled = styling(Alert)(props => ({
  backgroundColor: `rgba(33, 61, 181, 0.12)`,
  padding: '0px 16px !important',
  borderRadius: '8px',
  color: '#000000DE',
  cursor: 'pointer'
}));

export const UploadImageContainer = styling(Grid)(props => ({
  borderRadius: '16px',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  gap: '40px',
  padding: '16px',
  border: '1px dashed #AEAEAE',
  alignItems: 'center',
  backgroundColor: '#EFEFEF',
  cursor: 'pointer',
  zIndex: 10
}));

export const UploadImageTextContainer = styling(Grid)(props => ({
  width: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}));

export const ImageInputContainer = styling(Grid)(props => ({
  display: 'flex',
  width: '309px !important',
  height: '206px',
  backgroundColor: '#E5E5E5',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid #AEAEAE',
  borderRadius: '8px',
  transition: "all 0.3s",

  ":hover": {
    backgroundColor: "#D8D8D8"
  }
}));

export const UploadTitle = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.87)`,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const UploadDesc = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.87)`,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const UploadBrowse = styling('span')(props => ({
  color: `#512ACC`,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  cursor: 'pointer',
  transition: '0.3s all',

  ':hover': {
    textDecoration: 'underline'
  }
}));

export const ListText = styling('li')(props => ({
  color: `rgba(0, 0, 0, 0.60)`,
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.1px',
  margin: '0px'
}));

export const List = styling('ul')(props => ({
  margin: '0px',
  paddingLeft: '15px'
}));
