import React, { useEffect, useRef } from 'react';
import { Grid, ImageList } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import TalentCard from './TalentCard/index';
import TalentSort from './TalentSort';
import styles from './index.module.scss';
import SkeletonLoader from './SkeletonLoader';
import { useDispatch } from 'react-redux';
import { updateTalentsColumnRef } from '../../../redux/actions/talent_action';

const useStyles = makeStyles({
  outerWrapper: {
    '&.MuiImageList-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },

  skeletonWrapper: {
    '&.MuiImageList-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '15px'
    }
  }
});

const TalentsColumn = ({
  talents,
  setTalents,
  isSelected,
  setIsSelected,
  talentColumnHeight,
  accData,
  pageInfo,
  getTalents,
  loading,
  isRefresh,
  setIsRefresh,
  sortBy,
  setSortBy,
  scrollToTop,
  getCurrentTalents,
  setCurrentTalents,
  setIsLoadingHiddenData
}) => {
  const scrollToCardRef = useRef(null);
  const dispatch = useDispatch();
  const classes = useStyles();

  const scrollToCard = (index, previous) => {
    if (scrollToCardRef?.current) {
      scrollToCardRef?.current?.scrollTo(
        0,
        !previous
          ? scrollToCardRef?.current?.children[0].children[0].children[0]
              .children[index].offsetTop - 400
          : scrollToCardRef?.current?.children[0].children[0].children[0]
              .children[index].offsetTop - 600
      );
    }
  };

  const onKeyDown = event => {
    if (event.keyCode == 38) {
      if (isSelected > 0) {
        setIsSelected(prevSelected => {
          return prevSelected - 1;
        });
        scrollToCard(isSelected, true);
      }
    } else if (event.keyCode == 40) {
      if (isSelected < talents?.length - 1) {
        setIsSelected(prevSelected => {
          return prevSelected + 1;
        });
        scrollToCard(isSelected + 1, false);
      } else {
        return;
      }
    }
  };

  useEffect(() => {
    dispatch(updateTalentsColumnRef(scrollToCardRef));
  }, [scrollToCardRef]);

  return (
    <div className={styles.talentColumn}>
      <Grid item xs={12}>
        <TalentSort
          sortBy={sortBy}
          setSortBy={setSortBy}
          setIsRefresh={setIsRefresh}
        />
      </Grid>
      <div className={styles.talentContainer}>
        {(talents.length === 0 && loading) || isRefresh ? (
          <ImageList cols={1} className={classes.skeletonWrapper}>
            <SkeletonLoader customClassName={styles.skeletonWrapper} />
            <SkeletonLoader customClassName={styles.skeletonWrapper} />
            <SkeletonLoader customClassName={styles.skeletonWrapper} />
          </ImageList>
        ) : (
          <div
            id="scrollableDiv"
            className={styles.scrollableDivContainer}
            ref={scrollToCardRef}
            onKeyDown={onKeyDown}>
            <InfiniteScroll
              dataLength={talents?.length}
              next={() => {
                getTalents();
              }}
              hasMore={pageInfo?.hasNextPage}
              scrollThreshold={0.9}
              scrollableTarget="scrollableDiv"
              loader={<SkeletonLoader />}
              endMessage={
                <div>
                  {!pageInfo?.hasNextPage && !loading ? (
                    <p style={{ textAlign: 'center' }}>
                      <b>End of Talent List</b>
                    </p>
                  ) : (
                    <SkeletonLoader />
                  )}
                </div>
              }>
              <ImageList cols={1} className={classes.outerWrapper}>
                {talents?.map((item, index) => (
                  <TalentCard
                    accData={accData}
                    id={item?.node?.id}
                    key={item?.node?.id}
                    user={item?.node}
                    index={index}
                    isViewed={item?.node?.viewed}
                    uploadResumeAt={item?.node?.uploadResumeAt}
                    lastActiveAt={item?.node?.lastActiveAt}
                    isSelected={isSelected === index}
                    setIsSelected={setIsSelected}
                    isLastItem={index === talents.length - 1}
                    talents={talents}
                    setTalents={setTalents}
                    scrollToTop={scrollToTop}
                    getCurrentTalents={getCurrentTalents}
                    setCurrentTalents={setCurrentTalents}
                    setIsLoadingHiddenData={setIsLoadingHiddenData}
                  />
                ))}
              </ImageList>
            </InfiniteScroll>
          </div>
        )}
      </div>
    </div>
  );
};

export default TalentsColumn;
