import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { ErrorToast, SuccessToast } from '../../../../utils/ToastUtils';
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon
} from '@mui/icons-material';
import { Checkbox, TextField, Paper, Autocomplete } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchJobTeamMembers,
  mutateJobAccess
} from '../../../../redux/actions/job_action';
import { fetchCompanyTeamMembers } from '../../../../redux/actions/company_actions';
import {
  BottomBox,
  BottomActionBox,
  SkipButton,
  SaveButton,
  MainContainer,
  MainContainerBox,
  FormSelectContainer,
  DialogContentStyled,
  DialogTitleStyled,
  IconButtonStyled,
  DialogNoteStyled
} from './styles';
import { Dialog, Divider } from '@mui/material';
import { XMarkIcon } from '@heroicons/react/24/solid';

const JobAccessPopUpNew = ({ jobId, open, handleClose, useAsPopUp }) => {
  const history = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!open) return;

    dispatch(fetchCompanyTeamMembers());
  }, [open]);

  useEffect(() => {
    if (!open) return;

    dispatch(
      fetchJobTeamMembers({
        jobId: params?.id || jobId
      })
    );
  }, [params?.id, jobId, open]);

  const jobTeamMembers = useSelector(state => state.jobs.jobTeamMembers);

  const mutatingJobAccess = useSelector(state => state.jobs.mutatingJobAccess);

  const fetchingJobTeamMembers = useSelector(
    state => state.jobs.fetchingJobTeamMembers
  );

  const companyTeamMembers = useSelector(
    state => state.companies.companyTeamMembers
  );

  const [selectedJobAccess, setSelectedJobAccess] = useState(
    jobTeamMembers || []
  );

  const availableTeamMember = companyTeamMembers.filter(
    companyTeamMember =>
      !selectedJobAccess.some(
        jobTeamMember => jobTeamMember.id === companyTeamMember.id
      )
  );

  const accountInfo = JSON.parse(localStorage.getItem('accountInfo'));

  useEffect(() => {
    if (jobTeamMembers) {
      setSelectedJobAccess(jobTeamMembers);
    }
  }, [jobTeamMembers]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const FormSelectChips = ({
    options,
    placeholder,
    name,
    label,
    defaultValue,
    onChange,
    value
  }) => {
    const [selected, setSelected] = useState(defaultValue || []);

    return (
      <Autocomplete
        value={value}
        multiple
        classes={
          {
            // input: classes.input,
            // paper: classes.paper
          }
        }
        style={{ width: '100%', background: '#FFF' }}
        PaperComponent={({ children }) => (
          <Paper
            style={{
              background: '#fff',
              boxShadow: '0px 28px 23px rgb(0 0 0 / 5%)',
              borderRadius: '13px'
            }}>
            {children}
          </Paper>
        )}
        name={name}
        label={label}
        options={options}
        defaultValue={defaultValue}
        getOptionLabel={option => option?.title || option?.name}
        getOptionSelected={(option, val) => option?.id === val?.id}
        onChange={onChange}
        renderOption={(props, option, { selected }) => {
          return (
            <>
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  color="default"
                />
                {option?.title || option?.name}
              </li>
            </>
          );
        }}
        renderInput={params => (
          <TextField
            name={name}
            variant="outlined"
            placeholder={placeholder}
            {...params}
          />
        )}
      />
    );
  };

  const onSubmitJobAccess = async () => {
    dispatch(
      mutateJobAccess({
        input: {
          jobId: params?.id || jobId,
          companyTeamMembers: selectedJobAccess
        }
      })
    )
      .then(res => {
        if (res.success) {
          SuccessToast('Saved Job Access Successfully!');
        } else {
          ErrorToast('Saved Job Access Failed');
        }

        handleClose();

        if (!useAsPopUp) {
          if(accountInfo?.feedbackRequired){
            history(`/feedback`);
          }else{
            history(`/jobs`);
          }
        }
      })
      .catch(err => {
        ErrorToast(err.message);
      });
  };

  const onSkip = async () => {
    if (useAsPopUp) {
      handleClose();
    } else {
      // will redirect to feedback page on first job / every 3 months after a job posted 
      if(accountInfo?.feedbackRequired){
        history(`/feedback`);
      }else{
        history(`/jobs`);
      }
    }
  };

  const renderContent = () => (
    <MainContainer isPopUp={useAsPopUp}>
      {fetchingJobTeamMembers ? (
        <>
          {useAsPopUp ? (
            <div>
              <Skeleton
                variant="rectangular"
                width={637}
                height={56}
                animation="wave"
                style={{ borderRadius: '8px' }}
              />
            </div>
          ) : (
            <div>
              <Skeleton
                variant="rectangular"
                width={673}
                height={56}
                animation="wave"
                style={{ borderRadius: '8px' }}
              />
            </div>
          )}
        </>
      ) : (
        <MainContainerBox>
          <FormSelectContainer>
            <FormSelectChips
              name="jobAccess"
              options={availableTeamMember}
              value={selectedJobAccess}
              // placeholder="Would you like to give Job Access to any of your team members?"
              onChange={(e, value) => {
                setSelectedJobAccess(value);
              }}
            />
          </FormSelectContainer>
        </MainContainerBox>
      )}

      <BottomBox isPopUp={useAsPopUp}>
        <BottomActionBox isPopUp={useAsPopUp}>
          <SkipButton
            isPopUp={useAsPopUp}
            onClick={onSkip}>
            {useAsPopUp ? <>Cancel</> : <>Skip</>}
          </SkipButton>
          <SaveButton
            isPopUp={useAsPopUp}
            disabled={mutatingJobAccess}
            onClick={onSubmitJobAccess}>
            {useAsPopUp ? <>Save</> : <>Save & Done</>}
          </SaveButton>
        </BottomActionBox>
      </BottomBox>
    </MainContainer>
  );

  if (useAsPopUp) {
    return (
      <Dialog
        open={open}
        handleClose={() => handleClose()}
        sx={{ maxWidth: '685px', margin: 'auto' }}
        PaperProps={{
          style: {
            boxShadow: 'none',
            borderRadius: 8,
            width: '685px',
            background: '#fff',
            maxWidth: '685px',
            margin: '0'
          }
        }}>
        <DialogTitleStyled>
          Assign Job Access
          <IconButtonStyled onClick={() => handleClose()}>
            <XMarkIcon height={24} width={24} />
          </IconButtonStyled>
        </DialogTitleStyled>
        <Divider />

        <DialogContentStyled>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <DialogNoteStyled>
              Which team member has access to manage this job?
            </DialogNoteStyled>
            {renderContent()}
          </div>
        </DialogContentStyled>
      </Dialog>
    );
  }
  return <div style={{ width: '100%' }}>{renderContent()}</div>;
};

JobAccessPopUpNew.propTypes = {
  jobId: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  useAsPopUp: PropTypes.bool
};

JobAccessPopUpNew.defaultProps = {
  jobId: '',
  open: false,
  handleClose: () => {},
  useAsPopUp: true
};

export default JobAccessPopUpNew;
