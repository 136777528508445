import React from 'react';
import { Button } from '@mui/material';

export default function SaveButton(props) {
  return (
    <Button
      sx={{
        backgroundColor: '#EFEFFD',
        color: '#512ACC',
        border: '1px solid #512ACC',
        width: props?.width ? props?.width : '160px',
        padding: props?.padding ? props?.padding : '8px 16px',
        borderRadius: '8px',
        textTransform: 'none',
        fontSize: props?.fontSize ? props?.fontSize : '',
        height: props?.height ? props?.height : '',

        ':hover': {
          backgroundColor: '#DEDEFF',
          color: '#512ACC',
          border: '1px solid #512ACC'
        },

        ':disabled': {
          backgroundColor: '#EFEFEF',
          color: '#00000061',
          border: '1px solid #EFEFEF'
        }
      }}
      {...props}>
      {props?.children}
    </Button>
  );
}
