import { Grid } from '@mui/material';
import React from 'react';
import { MUIButtonStyled } from '../../../../../components/MUIButton';
import { HeightSpacer } from '../../../../../components/Spacer/styles';
import { Description, Subtitle, Title } from './styles';
import Colors from '../../../../../styles/colors.module.scss';
import { MegaphoneIcon } from '@heroicons/react/24/solid';
import { Hiredly_Phone_Number } from '../../../../../utils/Constants';

const Content = ({ actionButton, onClick, label }) => {
  return (
    <div>
      <Grid
        display={'flex'}
        alignItems={'center'}
        justifyContent="center"
        flexDirection={'column'}>
        <Grid display={'flex'} alignItems={'center'}>
          <MegaphoneIcon
            style={{
              height: '20px',
              width: '20px',
              marginRight: '5px',
              color: Colors.priPurple
            }}
          />
          <Title>Important Announcement</Title>
        </Grid>

        <HeightSpacer height={'10px'} />

        <Subtitle>
          Increase in Sales and Service Tax (SST) from 6% to 8% effective March
          1st, 2024.
        </Subtitle>

        <HeightSpacer height={'10px'} />

        <Description>
          Please note that unpaid invoices will be subject to 8% SST rate
          effective March 1st, 2024.
        </Description>

        <HeightSpacer height={'10px'} />

        <Description>
          Contact us at{' '}
          <a
            style={{ textDecoration: 'none', color: '#512acc' }}
            target="_blank"
            href="mailto:customercare@hiredly.com">
            customercare@hiredly.com
          </a>{' '}
          <>
            or{' '}
            <a
              style={{ textDecoration: 'none', color: '#512acc' }}
              target="_blank"
              href={`tel:${Hiredly_Phone_Number['MYWithCountryCode']}`}>
              {Hiredly_Phone_Number['MYWithSpace']}
            </a>{' '}
          </>
          if you need any assistance.
        </Description>

        <HeightSpacer height={'10px'} />

        {actionButton && (
          <MUIButtonStyled
            $padding={'6px 24px'}
            $backgroundColor={Colors.priBlack}
            $fontSize={'16px'}
            $width={'200px'}
            label={label}
            $newHoverEffect
            $newHoverBackground={Colors.priBlack}
            onClick={onClick}
          />
        )}
      </Grid>
    </div>
  );
};

export default Content;
