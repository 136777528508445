import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { Skeleton, Dialog, Divider } from '@mui/material';
import { Button, PopUpDialog } from '../../../../components';
import { ErrorToast, SuccessToast } from '../../../../utils/ToastUtils';
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Checkbox, TextField, Paper, Autocomplete } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchJobTeamMembers,
  mutateJobAccess,
  mutateGlobalHire,
  mutateCrossBorder,
  mutateCrossBorderAndGlobalHire,
  fetchJob,
  viewPackageSubscriptionDetails
} from '../../../../redux/actions/job_action';
import {
  BottomBox,
  BottomActionBox,
  SkipButton,
  SaveButton,
  MainContainer,
  MainContainerBox,
  FormSelectContainer,
  NoteSection,
  DialogActionsStyled,
  DialogContentStyled,
  DialogNoteStyled,
  DialogTitleStyled,
  ButtonContinueEdit,
  ButtonSkipEdit,
  DialogButton,
  IconButtonStyled,
  Overlay,
  PurpleInfo,
  PurplePosition,
  PurpleBox,
  PurpleArrow,
  PurpleTextBox,
  PurpleText,
  PurpleButtonSection,
  GotItButton
} from './styles';
import SkeletonLoader from './SkeletonLoader';
import { XMarkIcon } from '@heroicons/react/24/solid';
import CrossBorderPopUp from '../CrossBorderPopUp';
import GlobalHirePopUp from '../GlobalHirePopUp';
import { fetchCompanyTeamMembers } from '../../../../redux/actions/company_actions';

const JobVisibilityPopUp = ({ jobId, open, handleClose, useAsPopUp }) => {
  const history = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const crossBorderTokensUsableCount = useSelector(
    state =>
      state.jobs.subscribedCrossBorderTokensPackage?.total_usable_quantity
  );

  const [skipPopUpOpen, setSkipPopUpOpen] = useState(false);
  const [confirmPopUpOpen, setConfirmPopUpOpen] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [errors, setErrors] = useState(false);
  const [errorsCrossBorder, setErrorsCrossBorder] = useState(false);
  const [globalHireDefault, setGlobalHireDefault] = useState(false);

  // const crossBorderTokensUsableCount = 2;

  const fetchingPackageSubscriptionDetails = useSelector(
    state => state.jobs.fetchingPackageSubscriptionDetails
  );

  useEffect(() => {
    dispatch(
      fetchJob({
        id: jobId
      })
    );
    dispatch(viewPackageSubscriptionDetails());
    dispatch(fetchCompanyTeamMembers());
  }, [jobId]);

  const jobData = useSelector(state => state.jobs.jobData);

  const [fields, setFields] = useState({
    enabled: jobData?.globalHirePreferences?.enabled || false,
    workingArrangementRemote:
      jobData?.globalHirePreferences?.workingArrangementRemote || false,
    workingArrangementOnSite:
      jobData?.globalHirePreferences?.workingArrangementOnSite || false,
    workingArrangementHybrid:
      jobData?.globalHirePreferences?.workingArrangementHybrid || false,
    rightToWorkOpenToSponsor:
      jobData?.globalHirePreferences?.rightToWorkOpenToSponsor || false,
    rightToWorkLocalCitizen:
      jobData?.globalHirePreferences?.rightToWorkLocalCitizen || false,
    rightToWorkPermanentCitizen:
      jobData?.globalHirePreferences?.rightToWorkPermanentCitizen || false,
    rightToWorkWorkVisaOrEmploymentPass:
      jobData?.globalHirePreferences?.rightToWorkWorkVisaOrEmploymentPass ||
      false
  });

  useEffect(() => {
    if (jobData) {
      setFields({
        enabled: jobData?.globalHirePreferences?.enabled || false,
        workingArrangementRemote:
          jobData?.globalHirePreferences?.workingArrangementRemote || false,
        workingArrangementOnSite:
          jobData?.globalHirePreferences?.workingArrangementOnSite || false,
        workingArrangementHybrid:
          jobData?.globalHirePreferences?.workingArrangementHybrid || false,
        rightToWorkOpenToSponsor:
          jobData?.globalHirePreferences?.rightToWorkOpenToSponsor || false,
        rightToWorkLocalCitizen:
          jobData?.globalHirePreferences?.rightToWorkLocalCitizen || false,
        rightToWorkPermanentCitizen:
          jobData?.globalHirePreferences?.rightToWorkPermanentCitizen || false,
        rightToWorkWorkVisaOrEmploymentPass:
          jobData?.globalHirePreferences?.rightToWorkWorkVisaOrEmploymentPass ||
          false
      });
    }
  }, [jobData]);

  const [crossBorder, setCrossBorder] = useState({
    crossBorderDestinations: jobData?.crossBorderDestinations
      ? jobData?.crossBorderDestinations
      : []
  });

  const jobTeamMembers = useSelector(state => state.jobs.jobTeamMembers);

  const mutatingJobAccess = useSelector(state => state.jobs.mutatingJobAccess);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const useStyles = makeStyles(() => ({
    input: {
      minWidth: '100 !important'
    },
    inputRoot: {
      // maxWidth: '94% !important',
      fontSize: '14px',
      padding: '5px 10px !important',
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '30px',
        minHeight: '50px',
        width: '100%'
      }
    },
    paper: {
      backgroundColor: 'white'
    }
  }));

  const onRefresh = async () => {
    dispatch(viewPackageSubscriptionDetails());
  };

  const onSubmit = () => {
    if (fields?.enabled && crossBorder.crossBorderDestinations.length !== 0) {
      onSubmitFull();
    } else if (fields?.enabled && crossBorder.crossBorderDestinations == 0) {
      onSubmitGlobalHire();
    } else {
      onSubmitGlobalHire();
    }
  };

  const onHandleButtonClick = ( isSkip ) => {
    if(isSkip){
      history(`/jobs/${jobId}/virtual-interview-new`);
    }else{
      onSubmitGlobalHire();
      history(`/jobs/${jobId}/virtual-interview-new`);
    }
  };

  const onSubmitCrossBorder = async () => {
    dispatch(
      mutateCrossBorder({
        input: {
          jobId: jobId,
          crossBorderDestinations: crossBorder.crossBorderDestinations
        }
      })
    ).then(res => {
      if (res?.success?.data?.crossBorderJob?.success) {
        SuccessToast('Saved Job Visibility Successfully!');
      } else {
        if (res?.success?.errors?.length > 0) {
          ErrorToast(res?.success?.errors[0]?.message);
        } else {
          ErrorToast('Saved Job Visibility Failed');
        }
      }
    });
  };

  const validationSchema = () => {
    if (fields.enabled) {
      const isRightToWorkValid =
        fields.rightToWorkLocalCitizen ||
        fields.rightToWorkOpenToSponsor ||
        fields.rightToWorkPermanentCitizen ||
        fields.rightToWorkWorkVisaOrEmploymentPass;

      const isWorkingArrangementValid =
        fields.workingArrangementHybrid ||
        fields.workingArrangementOnSite ||
        fields.workingArrangementRemote;

      return isRightToWorkValid && isWorkingArrangementValid;
    } else {
      return true;
    }
  };

  const onSubmitGlobalHire = async () => {
    const isValid = validationSchema();

    if (!isValid) {
      setErrors(true);
      return;
    }

    dispatch(
      mutateGlobalHire({
        input: {
          jobId: jobId,
          enabled: fields?.enabled || false,
          rightToWorkLocalCitizen: fields?.rightToWorkLocalCitizen || false,
          rightToWorkOpenToSponsor: fields?.rightToWorkOpenToSponsor || false,
          rightToWorkPermanentCitizen:
            fields?.rightToWorkPermanentCitizen || false,
          rightToWorkWorkVisaOrEmploymentPass:
            fields?.rightToWorkWorkVisaOrEmploymentPass || false,
          workingArrangementHybrid: fields?.workingArrangementHybrid || false,
          workingArrangementOnSite: fields?.workingArrangementOnSite || false,
          workingArrangementRemote: fields?.workingArrangementRemote || false
        }
      })
    )
      .then(res => {
        if (res.success) {
          SuccessToast('Global Hire updated successfully!');
        } else {
          ErrorToast('Global Hire update Failed');
        }

        handleClose();

        if (!useAsPopUp) {
          history(`/jobs/${jobId}/virtual-interview-new`);
        }
      })
      .catch(err => {
        ErrorToast(err.message);
      });
  };

  const onSubmitFull = async () => {
    const isValid = validationSchema();

    if (!isValid) {
      setErrors(true);
      return;
    }

    if (crossBorder?.crossBorderDestinations.length == 0) {
      ErrorToast('Please select at least 1 country.');
      return;
    }

    try {
      const resComplete = await dispatch(
        mutateCrossBorderAndGlobalHire({
          input: {
            jobId: jobId,
            enabled: fields?.enabled || false,
            rightToWorkLocalCitizen: fields?.rightToWorkLocalCitizen || false,
            rightToWorkOpenToSponsor: fields?.rightToWorkOpenToSponsor || false,
            rightToWorkPermanentCitizen:
              fields?.rightToWorkPermanentCitizen || false,
            rightToWorkWorkVisaOrEmploymentPass:
              fields?.rightToWorkWorkVisaOrEmploymentPass || false,
            workingArrangementHybrid: fields?.workingArrangementHybrid || false,
            workingArrangementOnSite: fields?.workingArrangementOnSite || false,
            workingArrangementRemote: fields?.workingArrangementRemote || false,
            crossBorderDestinations: crossBorder?.crossBorderDestinations
          }
        })
      );

      if (
        resComplete?.type == "MUTATE_CROSS_BORDER_SUCCEED"
      ) {
        SuccessToast('1 Cross Border Token applied successfully!');
        SuccessToast('Global Hire updated successfully!');
        setConfirmPopUpOpen(false);

        if (!useAsPopUp) {
          setTimeout(() => {
            history(`/jobs/${jobId}/virtual-interview-new`);
          }, 2000);
        }
      } else {
        const errorMessage =
        resComplete?.success?.errors?.length > 0
            ? resComplete?.success?.errors[0]?.message
            : 'Cross Border Apply Failed';
        ErrorToast(errorMessage);
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle mutation dispatch error here
      ErrorToast('An error occurred while submitting the job.');
    }
  };

  const handleCloseSkip = async () => {
    setSkipPopUpOpen(true);
  };

  const handleConfirm = async () => {
    const isValid = validationSchema();

    if (!isValid) {
      setErrors(true);
      return;
    }

    if (crossBorder?.crossBorderDestinations?.length == 0) {
      setSkipPopUpOpen(true);
    } else {
      setConfirmPopUpOpen(true);
    }
  };

  const getJobTitle = () => {
    if (fields?.enabled) {
      if (crossBorder?.crossBorderDestinations?.length == 0) {
        return 'Continue without applying Cross Border Tokens?';
      } else {
        return 'Not ready yet?';
      }
    } else {
      return 'Not ready yet?';
    }
  };

  const getContent = () => {
    if (fields?.enabled) {
      if (crossBorder?.crossBorderDestinations?.length == 0) {
        return (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px'
              }}>
              <div>
                You can apply this features later to maximise visibility of this
                job ad under Manage Jobs.{' '}
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px'
              }}>
              <div>
                You can apply these features later to maximise visibility of
                this job ad under Manage Jobs.{' '}
              </div>
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px'
            }}>
            <div>
              You can apply these features later to maximise visibility of this
              job ad under Manage Jobs.{' '}
            </div>
          </div>
        </>
      );
    }
  };

  const getSkipButton = () => {
    if (fields?.enabled || fields?.enabled !== jobData?.globalHirePreferences?.enabled ) {
      if (crossBorder?.crossBorderDestinations?.length == 0) {
        return (
          <>
            <ButtonContinueEdit onClick={() => setSkipPopUpOpen(false)}>
              Cancel
            </ButtonContinueEdit>
            <ButtonSkipEdit onClick={() => onHandleButtonClick()}>
              Confirm
            </ButtonSkipEdit>
          </>
        );
      } else {
        return (
          <>
          <ButtonSkipEdit onClick={() => onHandleButtonClick()}>
            Got it
          </ButtonSkipEdit>
          </>
        );
      }
    } else {
      return (
        <>
          <ButtonSkipEdit onClick={() => onHandleButtonClick(true)}>
            Got it
          </ButtonSkipEdit>
        </>
      );
    }
  };

  const countryNames = {
    SG: 'Singapore',
    MY: 'Malaysia'
    // Add more mappings as needed
  };

  const renderCountryNames = destinations => {
    // if(destinations == 'SG'){
    //   return 'Singapore'
    // }else if(destinations == 'MY'){
    //   return 'Malaysia'
    // }else{
    //   return 'this country'
    // }

    if (!destinations || !Array.isArray(destinations)) return '';
    const selectedCountryNames = destinations
      .map(code => countryNames[code])
      .filter(Boolean); // Remove any undefined values

    return selectedCountryNames.join(', ');
  };

  const ConfirmPopUp = ({ confirmPopUpOpen, setConfirmPopUpOpen, jobId }) => (
    <Dialog
      open={confirmPopUpOpen}
      handleClose={() => setConfirmPopUpOpen(false)}
      sx={{ maxWidth: '600px', margin: 'auto' }}>
      <DialogTitleStyled>
        Cross Border Token Confirmation
        <IconButtonStyled onClick={() => setConfirmPopUpOpen(false)}>
          <XMarkIcon height={24} width={24} />
        </IconButtonStyled>
      </DialogTitleStyled>
      <Divider />

      <DialogContentStyled>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <p
            style={{
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '0.15px',
              margin: '0px'
            }}>
            This job will be advertised in{' '}
            {renderCountryNames(crossBorder?.crossBorderDestinations)} for 30
            days and will consume{' '}
            <b>
              {crossBorder?.crossBorderDestinations?.length} Cross Border Token.
            </b>
          </p>
        </div>
      </DialogContentStyled>

      <DialogActionsStyled>
        <DialogButton>
          <ButtonContinueEdit onClick={() => setConfirmPopUpOpen(false)}>
            Cancel
          </ButtonContinueEdit>
          <ButtonSkipEdit onClick={() => onSubmit()}>Confirm</ButtonSkipEdit>
        </DialogButton>
      </DialogActionsStyled>
    </Dialog>
  );

  const SkipPopUp = ({ skipPopUpOpen, setSkipPopUpOpen, jobId }) => (
    <Dialog
      open={skipPopUpOpen}
      handleClose={() => setSkipPopUpOpen(false)}
      sx={{ maxWidth: '600px', margin: 'auto' }}>
      <DialogTitleStyled>
        {getJobTitle()}
        <IconButtonStyled onClick={() => setSkipPopUpOpen(false)}>
          <XMarkIcon height={24} width={24} />
        </IconButtonStyled>
      </DialogTitleStyled>
      <Divider />

      <DialogContentStyled>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          {getContent()}
        </div>
      </DialogContentStyled>

      <DialogActionsStyled>
        <DialogButton>{getSkipButton()}</DialogButton>
      </DialogActionsStyled>
    </Dialog>
  );

  const renderContent = () => (
    <MainContainer>
      {fetchingPackageSubscriptionDetails ? (
        <SkeletonLoader />
      ) : (
        <MainContainerBox>
          <FormSelectContainer>
            <CrossBorderPopUp
              useAsPopUp={false}
              jobId={jobId}
              crossBorder={crossBorder}
              setCrossBorder={setCrossBorder}
              onRefresh={onRefresh}
              tokenCount={crossBorderTokensUsableCount}
              open
              globalHireDefault={globalHireDefault}
              setGlobalHireDefault={setGlobalHireDefault}
              setOverlay={setOverlay}
              setFields={setFields}
              fields={fields}
              setErrorsCrossBorder={setErrorsCrossBorder}
              errorsCrossBorder={errorsCrossBorder}
            />

            {overlay && (
              <>
                <Overlay />
                <PurpleInfo>
                  <PurplePosition>
                    <PurpleBox>
                      <PurpleTextBox>
                        <PurpleText bold={true}>
                          Your job ad will soon reach jobseekers globally!
                        </PurpleText>
                        <PurpleText>
                          Update your Global Hire working arrangement to convey
                          hiring needs clearly to jobseekers.
                        </PurpleText>
                        <PurpleButtonSection>
                          <GotItButton onClick={() => setOverlay(false)}>
                            Got it
                          </GotItButton>
                        </PurpleButtonSection>
                      </PurpleTextBox>

                      <PurpleArrow>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="22"
                          viewBox="0 0 18 22"
                          fill="#EFEFFD">
                          <path
                            d="M18 11L0 21.3923V0.607696L18 11Z"
                            fill="#EFEFFD"
                          />
                        </svg>
                      </PurpleArrow>
                    </PurpleBox>
                  </PurplePosition>
                </PurpleInfo>
              </>
            )}

            <GlobalHirePopUp
              useAsPopUp={false}
              jobId={jobId}
              fields={fields}
              setFields={setFields}
              setErrors={setErrors}
              errors={errors}
              open
              globalHireDefault={globalHireDefault}
              isOverlay={overlay}
            />

            <NoteSection isOverlay={overlay}>
              Note: Cross Border Tokens require Global Hire to be enabled
            </NoteSection>
          </FormSelectContainer>
        </MainContainerBox>
      )}

      <BottomBox>
        <BottomActionBox>
          <SkipButton onClick={() => handleCloseSkip()}>Skip</SkipButton>
          <SaveButton
            disabled={errorsCrossBorder || errors}
            onClick={() => handleConfirm()}>
            {globalHireDefault ? 'Apply 1 Token' : 'Continue'}
          </SaveButton>
        </BottomActionBox>
      </BottomBox>

      <SkipPopUp
        jobId={jobId}
        skipPopUpOpen={skipPopUpOpen}
        setSkipPopUpOpen={setSkipPopUpOpen}
        crossBorder={crossBorder}
        fields={fields}
      />

      <ConfirmPopUp
        jobId={jobId}
        confirmPopUpOpen={confirmPopUpOpen}
        setConfirmPopUpOpen={setConfirmPopUpOpen}
        crossBorder={crossBorder}
        fields={fields}
      />
    </MainContainer>
  );

  if (useAsPopUp) {
    return (
      <PopUpDialog
        open={open}
        handleClose={handleClose}
        title="Assign Job Access">
        {renderContent()}
      </PopUpDialog>
    );
  }
  return <div style={{ width: '100%' }}>{renderContent()}</div>;
};

JobVisibilityPopUp.propTypes = {
  jobId: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  useAsPopUp: PropTypes.bool
};

JobVisibilityPopUp.defaultProps = {
  jobId: '',
  open: false,
  handleClose: () => {},
  useAsPopUp: true
};

export default JobVisibilityPopUp;
