import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import { selectModalVideo } from '../../../../../redux/actions/company_actions';
import { isEmpty } from 'lodash';
import EmbedType from '../EmbedType';

const style = {
  maxHeight: '614px',
  minHeight: "214px",
  width: 'auto',
  overflow: 'auto',
  padding: '0px',
  border: 'none !important',
  outline: 'none !important'
};

export default function VideoModal() {
  const dispatch = useDispatch();
  const video = useSelector(state => state.companies.video);
  const handleClose = () => {
    dispatch(selectModalVideo(''));
  };

  return (
    <div>
      <Dialog open={!!video} onClose={handleClose} maxWidth="md">
        <DialogContent style={style}>
          <Box>
            <EmbedType url={video} />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
