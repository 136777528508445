import React, { useEffect } from 'react';
import DefaultLayout from '../../../../layouts/DefaultLayout';
import { SuccessToast } from '../../../../utils/ToastUtils';
import AshleyEditFeedbackPopup from '../../PopUps/AshleyEditFeedbackPopUp';
import { CheckIconStyled, SuccessContainer, SuccessText } from './styles';

const AshleyEditFeedback = () => {
  useEffect(() => {
    try {
      const showSavedJobMessage = localStorage.getItem('showSavedJobMessage');
      localStorage.removeItem('showSavedJobMessage');

      if (showSavedJobMessage) {
        SuccessToast(showSavedJobMessage);
      }
    } catch (error) {
      //
    }
  }, []);

  return (
    <DefaultLayout>
      <SuccessContainer>
        <CheckIconStyled />
        <SuccessText>Your job has been successfully posted!</SuccessText>
      </SuccessContainer>
      <AshleyEditFeedbackPopup />
    </DefaultLayout>
  );
};

export default AshleyEditFeedback;
