import * as types from '../types/chat_type';

const initialState = {
  isDisconnectingSendbird: false,
  // For talent search chats
  isTalentSearchChat: false,
  isFetchingTalentChats: false,
  talentChatsPageInfo: {},
  talentChatsReachedEnd: false,
  talentSearchChats: [],
  companyUnread: -1,
  jobUnread: -1,
  jobUnreadId: null,

  // FETCH JOB CHATS
  chatRoomListings: [],
  fetchingChatRoomListings: false,
  chatRoomListingsPageInfo: [],
  chatRoomListingsCount: 0,

  // MUTATE SENDBIRD CHANNEL URL
  mutatingSendBirdChannel: false,
  jobApplication: {}
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_TALENT_CHATS: {
      return {
        ...state,
        isFetchingTalentChats: true
      };
    }
    case types.FETCH_TALENT_CHATS_SUCCEED: {
      const chats = action.chats.edges.map(item => item.node);
      const reachedEnd = chats.length < 10;
      const currentChats = action.refresh
        ? chats
        : [...state.talentSearchChats, ...chats];
      return {
        ...state,
        talentSearchChats: currentChats,
        isFetchingTalentChats: false,
        talentChatsPageInfo: action.pageInfo,
        talentChatsReachedEnd: reachedEnd
      };
    }
    case types.FETCH_TALENT_CHATS_FAILED: {
      return {
        ...state,
        isFetchingTalentChats: false
      };
    }
    case types.UPDATE_TALENT_SEARCH_CHAT_STATUS: {
      return {
        ...state,
        isTalentSearchChat: action.status
      };
    }
    case types.UPDATE_TALENT_CHATS: {
      return {
        ...state,
        talentSearchChats: action.chats
      };
    }
    case types.UPDATE_CHAT_READ_STATUS_SUCCESS: {
      return {
        ...state,
        companyUnread: action.companyUnread,
        jobUnread: action.jobUnread,
        jobUnreadId: action.jobUnreadId
      };
    }
    case types.UPDATE_COMPANY_CHAT_UNREAD_COUNT: {
      return {
        ...state,
        companyUnread: action.count
      };
    }
    //FETCH JOB CHATS
    case types.FETCH_JOB_CHATS: {
      return {
        ...state,
        fetchingChatRoomListings: true
      };
    }
    case types.FETCH_JOB_CHATS_SUCCEED: {
      return {
        ...state,
        fetchingChatRoomListings: false,
        chatRoomListings: action.chatRoomListings,
        chatRoomListingsPageInfo: action.pageInfo,
        chatRoomListingsCount: action.totalCount
      };
    }
    case types.FETCH_JOB_CHATS_FAILED: {
      return {
        ...state,
        fetchingChatRoomListings: false
      };
    }

    // MUTATE SENDBIRD CHANNEL URL
    case types.MUTATE_SENDBIRD_CHANNEL_URL: {
      return {
        ...state,
        mutatingSendBirdChannel: true
      };
    }
    case types.MUTATE_SENDBIRD_CHANNEL_URL_SUCCEED: {
      return {
        ...state,
        mutatingSendBirdChannel: false,
        jobApplication: action.jobApplication
      };
    }
    case types.MUTATE_SENDBIRD_CHANNEL_URL_FAILED: {
      return {
        ...state,
        mutatingSendBirdChannel: false
      };
    }

    default: {
      return state;
    }
  }
};
