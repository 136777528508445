import React, { useEffect, useState } from 'react';
import { getCountry } from '../../../../hooks/useRegionalisation';
import { openInNewTab } from '../../../../utils/WindowUtils';
import { ImageStyled } from '../styles';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { CarouselDots, CarouselDotsContainer, SliderStyled } from './styles';
import { fetchHomepageBanner } from '../../../../redux/actions/product_action';
import { useDispatch, useSelector } from 'react-redux';

export default function HomepageCarousel() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const homepageBanner = useSelector(state => state.products.homepageBanner);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    const loadData = async () => {
      if (getCountry() === 'SG') {
        await dispatch(fetchHomepageBanner('singapore'));
      } else {
        await dispatch(fetchHomepageBanner('malaysia'));
      }
    };

    loadData();
  }, [dispatch]);

  const getCountrySlides = () => {
    return homepageBanner.map((banner, index) => ({
      onClick: () => {
        if (isDragging) return;

        switch (banner?.attributes?.useCase) {
          case 'Redirect':
            if (banner?.attributes?.redirectUrl) {
              openInNewTab(banner?.attributes?.redirectUrl);
            }
            break;

          case 'Contact_Us':
            if (banner?.attributes?.contactSubject?.data?.attributes?.subject) {
              dispatch(
                updateSubjectType(
                  `${banner?.attributes?.contactSubject?.data?.attributes?.subject}`
                )
              ).then(() => {
                dispatch(updateContactSalesPopupStatus(true));
              });
            } else {
              dispatch(updateSubjectType('')).then(() => {
                dispatch(updateContactSalesPopupStatus(true));
              });
            }
            break;

          case 'Add_To_Cart':
            let productCode =
              banner?.attributes?.productSku?.data?.attributes?.productCode;

            if (productCode) {
              navigate(`/purchase/choose/?${productCode}`);
            } else {
              navigate('/purchase/choose');
            }
            break;

          case 'Nothing':
            break;

          default:
            break;
        }
      },
      imageSource: banner?.attributes?.bannerImage?.data?.attributes?.url,
      alt: `slide-${index}`
    }));
  };

  const hasCountrySlides = getCountrySlides().length > 0;

  const settings = {
    dots: false, // Disable default dots
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: false,
    prevArrow: false,
    beforeChange: () => setIsDragging(true),
    afterChange: current => {
      setIsDragging(false);
      setActiveSlide(current);
    }
  };

  return (
    <Grid sx={{ height: '50%', borderRadius: '24px', position: 'relative' }}>
      {hasCountrySlides && (
        <SliderStyled
          {...settings}
          onMouseDown={e => setDragStartX(e.clientX)}
          onMouseMove={e => {
            if (Math.abs(e.clientX - dragStartX) > 5) {
              setIsDragging(true);
            }
          }}
          onMouseUp={() => setIsDragging(false)}
          onTouchStart={e => setDragStartX(e.touches[0].clientX)}
          onTouchMove={e => {
            if (Math.abs(e.touches[0].clientX - dragStartX) > 5) {
              setIsDragging(true);
            }
          }}
          onTouchEnd={() => setIsDragging(false)}>
          {getCountrySlides().map((slide, index) => (
            <Grid
              key={index}
              onClick={() => !isDragging && slide.onClick()}
              sx={{ borderRadius: '24px !important' }}>
              <ImageStyled src={slide.imageSource} alt={slide.alt} />
            </Grid>
          ))}
        </SliderStyled>
      )}
      <CarouselDotsContainer>
        {getCountrySlides().map((_, index) => (
          <CarouselDots
            key={index}
            highlight={index === activeSlide}
            onClick={() => {
              setActiveSlide(index);
              SliderStyled.slickGoTo(index);
            }}
          />
        ))}
      </CarouselDotsContainer>
    </Grid>
  );
}
