import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from '../../../../components';
import {
  MainContainer,
  DialogTitleStyled,
  IconButtonStyled,
  DialogContentStyled,
  DialogNoteStyled,
  ExpandableBarTitle,
  Title,
  BottomBox,
  SkipButton,
  SaveButton,
  Loader,
  ErrorMessage
} from './styles';
import { Dialog, Divider, Skeleton } from '@mui/material';
import { XMarkIcon, ChevronDoubleUpIcon } from '@heroicons/react/24/solid';
import { updateContactSalesPopupStatus } from '../../../../redux/actions/job_action';

const CloseLoopPopUp = ({
  isCloseLoopOpen,
  handleCloseLoopPopUpClose,
  closingApplicationLoop,
  candidate,
  item,
  isClosingLoop,
  loopError,
  setLoopError
}) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [percentage, setPercentage] = useState('0%');
  const isValid = percentage == '100%' && !loopError;

  const onClickContactUs = () => {
    dispatch(updateContactSalesPopupStatus(true));
  };

  const onConfirmation = () => {
    // ATS won't pass item as prop
    // Only list view will pass item as prop
    const currentCandidate = item ? item : candidate;
    const application = currentCandidate?.jobApplication
      ? currentCandidate.jobApplication
      : currentCandidate;

    setPage(1);
    setPercentage('0%');
    setLoopError(false);

    let progress = 0;
    const interval = setInterval(() => {
      progress += 10;
      setPercentage(`${progress}%`);
      if (progress >= 100) {
        clearInterval(interval);
      }
    }, 100);

    if (loopError === false)
      setTimeout(() => {
        closingApplicationLoop(
          application,
          currentCandidate.state
            ? currentCandidate.state
            : currentCandidate.offered
            ? 'offer'
            : 'interview'
        );
      }, 3000);
  };

  useEffect(() => {
    if (loopError) {
      setPage(0);
      setPercentage('0%');
    }
  }, [loopError]);

  const resetModal = () => {
    setPage(0);
    setPercentage('0%');
    setLoopError(false);
  };

  const renderContent = () => (
    <MainContainer>
      {loopError && page == '0' && (
        <>
          <ErrorMessage>
            <ChevronDoubleUpIcon width={24} height={24} fill="#F75443" />
            <p>
              Something went wrong, please try again. If the issue continues,{' '}
              <a onClick={onClickContactUs}>contact us</a> for help.
            </p>
          </ErrorMessage>
        </>
      )}
      <ExpandableBarTitle>
        {page == '0' ? (
          <>
            <Title style={{ minHeight: '48px' }}>
              Confirm that you hired{' '}
              <b style={{ wordBreak: 'break-all' }}>{candidate?.user?.name}</b>?
            </Title>

            <DialogNoteStyled>
              Once you click to confirm, we will credit 2 free Refresher Tokens
              worth RM100 to your account.
            </DialogNoteStyled>
          </>
        ) : (
          <>
            <Title style={{ minHeight: '48px' }}>
              You have marked this candidate as Hired.
            </Title>

            <Loader isValid={!isValid}>
              <Skeleton
                animation={!isValid ? 'wave' : false}
                style={{
                  position: 'absolute',
                  zIndex: 2,
                  height: '80px',
                  width: `${percentage}`,
                  padding: '0',
                  top: '-17.5px',
                  left: '0',
                  backgroundColor: `${!isValid ? '#512ACC1A' : '#90B93829'}`,
                  transition: 'width 0.5s ease-in'
                }}></Skeleton>

              {!isValid ? (
                <>
                  Crediting 2 Refresher Tokens to your account... ({percentage})
                </>
              ) : (
                <>
                  2 Refresher Tokens successfully credited.
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12ZM15.6103 10.1859C15.8511 9.84887 15.773 9.38046 15.4359 9.1397C15.0989 8.89894 14.6305 8.97701 14.3897 9.31407L11.1543 13.8436L9.53033 12.2197C9.23744 11.9268 8.76256 11.9268 8.46967 12.2197C8.17678 12.5126 8.17678 12.9874 8.46967 13.2803L10.7197 15.5303C10.8756 15.6862 11.0921 15.7656 11.3119 15.7474C11.5316 15.7293 11.7322 15.6153 11.8603 15.4359L15.6103 10.1859Z"
                      fill="#90B938"
                    />
                  </svg>
                </>
              )}
            </Loader>
          </>
        )}
      </ExpandableBarTitle>

      <BottomBox>
        {page == '0' ? (
          <>
            <SkipButton
              onClick={() => {
                handleCloseLoopPopUpClose();
                resetModal();
              }}>
              Cancel
            </SkipButton>
            <SaveButton disabled={isClosingLoop} onClick={onConfirmation}>
              {isClosingLoop ? (
                <Spinner size="SMALL" color="white" noPadding={true} />
              ) : (
                <>{loopError ? 'Confirm' : 'Confirm'}</>
              )}
            </SaveButton>
          </>
        ) : (
          <SaveButton
            disabled={percentage !== '100%'}
            onClick={() => {
              handleCloseLoopPopUpClose();
              resetModal();
            }}
            width={'125px'}>
            Done & close
          </SaveButton>
        )}
      </BottomBox>
    </MainContainer>
  );

  return (
    <Dialog
      open={isCloseLoopOpen}
      // handleClose={handleCloseLoopPopUpClose}
      sx={{ maxWidth: '464px', margin: 'auto' }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: 8,
          width: '464px',
          background: '#fff',
          maxWidth: '464px',
          margin: '0'
        }
      }}>
      <DialogTitleStyled>
        {page == '0' ? (
          <>Hire Candidate Confirmation</>
        ) : (
          <>Congratulations!🎉</>
        )}
        <IconButtonStyled
          disabled={page == '1' && percentage !== '100%'}
          onClick={() => {
            handleCloseLoopPopUpClose();
            resetModal();
          }}>
          <XMarkIcon height={24} width={24} />
        </IconButtonStyled>
      </DialogTitleStyled>
      <Divider />

      <DialogContentStyled>{renderContent()}</DialogContentStyled>
    </Dialog>
  );
};

export default CloseLoopPopUp;
