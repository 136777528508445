import { styled as styling } from '@mui/material/styles';
import Colors from '../../styles/colors.module.scss';
import { BoltIcon } from '@heroicons/react/24/solid';
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';

export const PromoContainer = styling('div')(props => ({
  background: 'rgba(242, 175, 41, 0.3)',
  borderRadius: '4px',
  padding: '2px 10px',
  gap: '5px',
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content'
}));

export const PromoText = styling('span')(props => ({
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '10px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.87)',
  lineHeight: '20px'
}));

export const LinkText = styling('span')(props => ({
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '10px',
  letterSpacing: '0.15px',
  color: '#512ACC',
  lineHeight: '20px',
  textDecoration: 'underline'
}));

export const Slash = styling('span')(props => ({
  textDecoration: 'line-through',
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '14px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.87)'
}));

export const BoltIconStyled = styling(BoltIcon)(props => ({
  color: '#F1C93C',
  width: '12px',
  height: '12px'
}));

export const OrderAlertContainer = styling('div')(props => ({
  position: 'relative',
  padding: '10px 18px 10px 16px',
  borderRadius: '8px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyItems: 'center',
  gap: '10px',

  ...(props.$background == 'green' && {
    backgroundColor: 'rgba(144, 185, 56, 0.2)'
  }),

  ...(props.$background == 'red' && {
    backgroundColor: 'rgba(190, 66, 66, 0.2)'
  }),

  ...(props.$background == 'orange' && {
    backgroundColor: 'rgba(242, 166, 71, 0.2)'
  })
}));

export const AlertTitle = styling('span')(props => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 700,
  letterSpacing: '0.1px'
}));

export const AlertMessage = styling('span')(props => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  letterSpacing: '0.15px',
  lineHeight: '24px'
}));

export const LinkStyled = styling('a')({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 700,
  letterSpacing: '0.15px',
  color: Colors.priPurple,
  textDecorationLine: 'underline',
  textUnderlineOffset: '4px'
});

export const Sidebar = styling('div')(props => ({
  width: '6px',
  height: '100%',
  position: 'absolute',
  top: '0',
  left: '0',
  bottom: '0',
  ...(props.$background == 'green' && {
    backgroundColor: '#90b938'
  }),
  ...(props.$background == 'red' && {
    backgroundColor: '#be4242'
  }),
  ...(props.$background == 'orange' && {
    backgroundColor: '#f2a647'
  })
}));

export const PackageContainer = styling(Grid)(props => ({
  borderRadius: '8px',
  border: `1px solid #D8D8D8`,
  background: 'white',
  padding: props.$noTitleAndDesc ? '24px' : '0px',
  marginBottom: '24px'
}));

export const PackageGrid = styling(Grid)(props => ({}));

export const PackageItem = styling(Grid)(props => ({
  borderRadius: '8px',
  backgroundColor: '#F5F5F5',
  position: 'relative',
  padding: '16px',
  flex: 1
}));

export const IconButtonStyled = styling(IconButton)(props => ({
  position: 'absolute',
  top: props?.confirm ? '' : props?.jobAds ? '24px' : '10px',
  right: props?.confirm ? '0px' : props?.jobAds ? '0px' : '10px',
  zIndex: 100
}));

export const BackButtonStyled = styling(IconButton)(props => ({
  height: '100%',
  width: 'fit-content'
}));

export const ProductTitle = styling(Typography)(props => ({
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: '700',
  textAlign: 'center',
  color: '#00000099',
  letterSpacing: '0.1px',
  fontFamily: 'Inter',
  opacity: props?.zeroCount && '0.4'
}));

export const ProductPrice = styling(Typography)(props => ({
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: '700',
  textAlign: 'center',
  color: props?.zeroCount ? `rgba(0,0,0,0.87)` : '#40249B',
  letterSpacing: '0.1px',
  fontFamily: 'Inter',
  opacity: props?.zeroCount && '0.4'
  // marginBottom: '8px'
}));

export const ProductDesc = styling(Typography)(props => ({
  fontSize: '12px',
  lineHeight: '20px',
  fontWeight: '400',
  textAlign: 'center',
  color: props?.zeroCount ? `rgba(0, 0, 0, 0.60)` : '#000000DE',
  letterSpacing: '0.1px',
  fontFamily: 'Inter'
}));

export const ProductBundleChip = styling(Box)(props => ({
  borderRadius: '4px',
  padding: '2px 8px',
  gap: '6px',
  backgroundColor: '#512acc1a',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'fit-content'
}));

export const ProductBundleText = styling(Typography)(props => ({
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '10px',
  fontWeight: '400',
  lineHeight: '20px'
}));

export const ProductPromoChip = styling(Box)(props => ({
  display: 'flex',
  padding: '2px 8px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  backgroundColor: '#f2af294d',
  width: 'fit-content',
  borderRadius: '4px'
}));

export const ProductPromoText = styling(Typography)(props => ({
  color: '#000000DE',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '10px',
  fontWeight: '400',
  lineHeight: '20px'
}));

export const ProductAddToCartButton = styling(Button)(props => ({
  minHeight: '28px',
  textTransform: 'none',
  backgroundColor: '#2E2E2E',
  color: 'white',
  borderRadius: '4px',
  border: 'none !important',
  boxShadow: 'none !important',
  padding: '0px 16px',
  fontSize: '12px',
  width: props?.singleCard ? '50%' : '100%',
  lineHeight: '20px',

  '&:hover': {
    backgroundColor: '#000000',
    border: 'none'
  },

  '&.Mui-disabled': {
    backgroundColor: '#D8D8D8',
    color: '#00000038 !important',
    border: 'none'
  }
}));

export const QuantityButton = styling(Button)(props => ({
  width: '22px !important',
  minWidth: '22px !important',
  height: '30px',
  padding: '2px',

  ':hover': {
    backgroundColor: props?.disablePlus ? 'transparent' : '#512acc1a',
    cursor: props?.disablePlus ? 'default' : 'pointer'
  },

  [props.theme.breakpoints.down(1440)]: {
    width: '100%'
  }
}));

export const QuantityText = styling(TextField)(props => ({
  border: 'none',
  backgroundColor: 'white',
  borderRadius: '2px',
  width: props?.singleCard ? '34px' : '28px',
  height: '25px',
  color: 'black',
  padding: 'none',

  '& .MuiInputBase-root, .MuiInputBase-root .MuiInputBase-input': {
    height: '25px',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: '0.15px',

    '& fieldset': {
      border: 'none'
    },

    '& input': {
      padding: '0px'
    }
  }
}));

export const ProductPageTitle = styling(Typography)(props => ({
  fontFamily: 'Inter',
  fontSize: '25px',
  fontWeight: '700',
  lineHeight: '32px',
  marginBottom: '32px'
}));

export const ProductCategoryButton = styling(Button)(props => ({
  padding: '10px',
  border: '1.5px solid transparent',
  fontWeight: '400',

  borderRadius: '8px',
  backgroundImage: props.$selected
    ? 'linear-gradient(#efeffd, #efeffd), linear-gradient(to right, #983EBA, #6D32C5, #512acc)'
    : 'none',
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',

  boxShadow: 'none',
  backgroundColor: props.$selected ? '#512acc1a' : '#E5E5E5',
  height: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: props.$selected ? 'black' : '#00000099',
  textTransform: 'none',
  minWidth: '125px',
  transition: 'background-image 1s ease',

  '&:hover': {
    backgroundImage: props.$selected
      ? 'linear-gradient(#efeffd, #efeffd), linear-gradient(to right, #983EBA, #6D32C5, #512acc)'
      : 'none',
    boxShadow: 'none',
    border: props.$selected ? '1.5px solid transparent' : '1.5px solid black',
    backgroundColor: '#E5E5E5',
    color: props.$selected ? 'black' : '#00000099'
  }
}));

export const TalentSearchCopy = styling(Typography)(props => ({
  fontSize: '16px',
  fontFamily: 'Inter',
  lineHeight: '24px',
  color: '#000000DE'
}));

export const TalentSearchHintCopy = styling(Typography)(props => ({
  fontSize: '12px',
  fontFamily: 'Inter',
  lineHeight: '20px',
  color: '#00000099'
}));

export const ContactSalesTitle = styling(Typography)(props => ({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '24px',
  letterSpacing: '0.1px'
}));

export const ContactSalesDesc = styling(Typography)(props => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.15px',

  a: {
    color: '#512acc'
  }
}));

export const ContactSalesButton = styling(Button)(props => ({
  minHeight: '35px',
  textTransform: 'capitalize',
  backgroundColor: '#2E2E2E',
  color: 'white',
  borderRadius: '8px',
  minWidth: '200px',
  fontWeight: '700',
  border: '2px solid #2E2E2E',
  boxShadow: 'none',

  '&:hover': {
    backgroundColor: '#222222',
    border: '2px solid #222222'
  },

  '&.Mui-disabled': {
    color: 'white !important'
  }
}));

export const PackageSectionTitle = styling(Typography)(props => ({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.6)'
}));

export const PackageSectionInactive = styling(Typography)(props => ({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  fontStyle: 'italic',
  color: `rgba(0, 0, 0, 0.38)`
}));

export const TextButtonStyled = styling(Button)(props => ({
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '20px',
  fontFamily: 'Inter',
  textTransform: 'none',
  color: '#00000099',
  textDecorationLine: 'underline'
}));

export const CartQuantityText = styling(TextField)(props => ({
  border: 'none',
  backgroundColor: 'white',
  borderRadius: '2px',
  width: '35px',
  height: '22px',
  color: 'black',
  padding: 'none',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '22px',
  letterSpacing: '0.15px',
  fontFamily: 'Inter',

  '& .MuiInputBase-root, .MuiInputBase-root .MuiInputBase-input, .MuiInputBase-root fieldset':
    {
      height: '22px',
      border: 'none',
      textAlign: 'center',
      padding: '0px',
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: '0.15px'
    }
}));

export const ProductDiscountPrice = styling('span')(props => ({
  fontFamily: 'Inter',
  fontSize: '10px',
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '0.15px',
  textDecorationLine: 'line-through',
  color: '#00000099'
}));

export const ReviewCartButton = styling(Button)(props => ({
  height: '42px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '6px',
  fontSize: '16px',
  fontWeight: '700',
  fontFamily: 'Inter',
  textTransform: 'none',
  fontSize: '16px',
  fontFamily: 'Inter',
  boxShadow: 'none',

  '&:hover': {
    backgroundColor: '#40249B',
    color: 'white'
  }
}));

export const ReviewCartCard = styling(Card)(props => ({
  width: '100%',
  borderRadius: '8px',
  maxHeight: '345px',
  border: '1px solid #D8D8D8',
  marginBottom: '32px',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'flex-start',
  boxShadow: 'none',
  scrollbarWidth: 'thin',

  '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
    color: '#512acc',
    backgroundColor: '#D8D8D8',
    width: '4px'
  },
  '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
    borderRadius: 8,
    backgroundColor: '#512ACC',
    minHeight: '24px'
  },
  '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
    backgroundColor: '#512ACC'
  },
  '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
    backgroundColor: '#512ACC'
  },
  '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#512ACC'
  },
  '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
    backgroundColor: '#512ACC'
  }
}));

export const AddOnCard = styling(Card)(props => ({
  width: '100%',
  borderRadius: '8px',
  border: '1px solid #D8D8D8',
  // maxWidth: '940px',
  marginBottom: '32px',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'flex-start',
  boxShadow: 'none'
}));

export const CartCard = styling(Card)(props => ({
  border: '1px solid #D8D8D8',
  boxShadow: 'none',
  borderRadius: '8px',
  width: '100%',
  position: 'sticky',
  top: '110px'
}));

export const ApplyDiscountButton = styling(Button)(props => ({
  height: '42px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '6px',
  fontSize: '14px',
  fontWeight: '700',
  fontFamily: 'Inter',
  textTransform: 'none',
  boxShadow: 'none',
  backgroundColor: 'black',

  '&:hover': {
    backgroundColor: 'black',
    color: 'white'
  }
}));

export const ProceedPaymentButton = styling(Button)(props => ({
  height: '42px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '6px',
  fontSize: '16px',
  fontWeight: '700',
  fontFamily: 'Inter',
  textTransform: 'none',
  fontSize: '16px',
  fontFamily: 'Inter',
  boxShadow: 'none',

  '&:hover': {
    backgroundColor: '#40249B',
    color: 'white'
  }
}));

export const OrderSummaryTitle = styling(Typography)({
  fontSize: '20px',
  fontWeight: '700',
  fontFamily: 'Inter',
  lineHeight: '24px'
});

export const OrderTableTitle = styling(Typography)({
  fontSize: '12px',
  fontWeight: '400',
  fontFamily: 'Inter',
  lineHeight: '20px',
  color: '#00000061'
});
