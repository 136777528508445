import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import './style.css';
import styles from './index.module.scss';
import { Button } from '../../../../components';
import {
  ZoomInIconStyled,
  ZoomOutIconStyled,
  ZoomSliderStyled,
  ZoomEditContainer,
  ButtonWrapper,
  ButtonContainer,
  ButtonStyled,
  CancelButton,
  SaveButton
} from './styles.js';
import { Grid, Divider } from '@mui/material';

// const defaultSrc =
//   'https://fengyuanchen.github.io/cropperjs/images/picture.jpg';

let currentDesktopZoom = 0.1;
let currentMobileZoom = 0.1;

const Editor = ({
  onlyDesktop,
  desktopRatio,
  handleClose,
  onUploadMultipleBannerImages,
  selectedDesktopBanner,
  selectedMobileBanner
}) => {
  const ref = useRef(null);
  const ref2 = useRef(null);
  const [cropper, setCropper] = useState(null);
  const [mobileCropper, setMobileCropper] = useState(null);
  const [desktopZoom, setDesktopZoom] = useState(0.1);
  const [mobileZoom, setMobileZoom] = useState(0.1);

  const uploadBanners = (banner, squareBanner) => {
    if (onlyDesktop) {
      if (banner) {
        onUploadMultipleBannerImages(banner);
      }
    } else {
      if (banner && squareBanner) {
        onUploadMultipleBannerImages(banner, squareBanner);
      }
    }
  };

  const getCropData = async () => {
    let banner, squareBanner;
    if (typeof cropper !== 'undefined') {
      // Converting canvas image to file
      cropper.getCroppedCanvas().toBlob(blob => {
        banner = new File([blob], 'fileName.jpg', { type: 'image/jpeg' });
        uploadBanners(banner, squareBanner);
      }, 'image/jpeg');
    }

    if (typeof mobileCropper !== 'undefined') {
      // Converting canvas image to file
      mobileCropper?.getCroppedCanvas()?.toBlob(blob => {
        squareBanner = new File([blob], 'fileName.jpg', { type: 'image/jpeg' });
        uploadBanners(banner, squareBanner);
      }, 'image/jpeg');
    }

    try {
      window.dataLayer.push({
        event: 'adjust-cover-image'
      });
    } catch {}
  };

  // For desktop zoom
  const onDesktopZoomInIconClicked = () => {
    if (currentDesktopZoom >= 1.0) return;

    if (cropper) {
      currentDesktopZoom += 0.1;
      currentDesktopZoom =
        Math.round((currentDesktopZoom + Number.EPSILON) * 100) / 100;

      setDesktopZoom(currentDesktopZoom);

      cropper.zoom(0.1);
    }
  };

  const onDesktopZoomOutIconClicked = () => {
    if (currentDesktopZoom <= 0.1) return;

    if (cropper) {
      currentDesktopZoom -= 0.1;
      currentDesktopZoom =
        Math.round((currentDesktopZoom + Number.EPSILON) * 100) / 100;

      setDesktopZoom(currentDesktopZoom);

      cropper.zoom(-0.1);
    }
  };

  const onDesktopBannerZoomChange = (event, newValue) => {
    if (cropper) {
      if (currentDesktopZoom != newValue) {
        const toZoom =
          Math.round((newValue - currentDesktopZoom + Number.EPSILON) * 100) /
          100;

        currentDesktopZoom =
          Math.round((newValue + Number.EPSILON) * 100) / 100;

        setDesktopZoom(currentDesktopZoom);

        const nums = Math.abs(toZoom) / 0.1;
        for (var i = 0; i < nums; i++) {
          if (toZoom > 0) {
            cropper.zoom(0.1);
          } else {
            cropper.zoom(-0.1);
          }
        }
      }
    }
  };

  // For mobile zoom
  const onMobileZoomInIconClicked = () => {
    if (currentMobileZoom >= 1.0) return;

    if (mobileCropper) {
      currentMobileZoom += 0.1;
      currentMobileZoom =
        Math.round((currentMobileZoom + Number.EPSILON) * 100) / 100;

      setMobileZoom(currentMobileZoom);

      mobileCropper.zoom(0.1);
    }
  };

  const onMobileZoomOutIconClicked = () => {
    if (currentMobileZoom <= 0.1) return;

    if (mobileCropper) {
      currentMobileZoom -= 0.1;
      currentMobileZoom =
        Math.round((currentMobileZoom + Number.EPSILON) * 100) / 100;

      setMobileZoom(currentMobileZoom);

      mobileCropper.zoom(-0.1);
    }
  };

  const onMobileBannerZoomChange = (event, newValue) => {
    if (mobileCropper) {
      if (currentMobileZoom != newValue) {
        const toZoom =
          Math.round((newValue - currentMobileZoom + Number.EPSILON) * 100) /
          100;

        currentMobileZoom = Math.round((newValue + Number.EPSILON) * 100) / 100;

        setMobileZoom(currentMobileZoom);

        const nums = Math.abs(toZoom) / 0.1;
        for (var i = 0; i < nums; i++) {
          if (toZoom > 0) {
            mobileCropper.zoom(0.1);
          } else {
            mobileCropper.zoom(-0.1);
          }
        }
      }
    }
  };

  useEffect(() => {
    return () => {
      // ComponentWillUnmount
      currentDesktopZoom = 0.1;
      currentMobileZoom = 0.1;
    };
  }, []);

  return (
    <>
      <div
        style={{
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          borderRadius: '8px !important',
          padding: onlyDesktop && '0 24px 0px 24px',
          overflow: 'hidden'
        }}>
        {!onlyDesktop && (
          <span style={{ fontFamily: 'InterBold', fontSize: '22px' }}>
            Desktop Banner
          </span>
        )}

        <Cropper
          style={{
            width: '100%',
            paddingTop: '10px',
            minWidth: onlyDesktop ? 620 : 825,
            maxHeight: onlyDesktop ? '280px' : '494px',
            height: onlyDesktop ? '280px' : 'auto',
            borderRadius: '8px'
          }}
          zoomOnWheel={false}
          preview=".img-preview"
          src={selectedDesktopBanner}
          viewMode={1}
          dragMode={'move'}
          autoCropArea={1}
          guides={true}
          center={false}
          highlight={false}
          cropBoxMovable={false}
          toggleDragModeOnDblclick={false}
          background={false}
          responsive={true}
          cropBoxResizable={false}
          aspectRatio={desktopRatio}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={instance => {
            setCropper(instance);
          }}
          ref={ref}
        />

        <ZoomEditContainer>
          <ZoomOutIconStyled onClick={onDesktopZoomOutIconClicked} />
          <ZoomSliderStyled
            value={desktopZoom}
            step={0.1}
            min={0.1}
            max={1.0}
            onChange={onDesktopBannerZoomChange}
          />
          <ZoomInIconStyled onClick={onDesktopZoomInIconClicked} />
        </ZoomEditContainer>
      </div>

      {!onlyDesktop && (
        <div style={{ marginTop: 20, width: '100%', position: 'relative' }}>
          <span style={{ fontFamily: 'InterBold', fontSize: '22px' }}>
            Mobile banner
          </span>
          <Cropper
            style={{ marginTop: 10, maxHeight: 350, width: '100%' }}
            zoomOnWheel={false}
            preview=".img-preview2"
            src={selectedMobileBanner}
            viewMode={1}
            dragMode={'move'}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            autoCropArea={1}
            guides={false}
            center={false}
            highlight={false}
            cropBoxMovable={false}
            toggleDragModeOnDblclick={false}
            background={false}
            responsive={true}
            cropBoxResizable={false}
            aspectRatio={4 / 3}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={instance => {
              setMobileCropper(instance);
            }}
            ref={ref2}
          />
          <ZoomEditContainer>
            <ZoomOutIconStyled onClick={onMobileZoomOutIconClicked} />
            <ZoomSliderStyled
              value={mobileZoom}
              step={0.1}
              min={0.1}
              max={1.0}
              onChange={onMobileBannerZoomChange}
            />
            <ZoomInIconStyled onClick={onMobileZoomInIconClicked} />
          </ZoomEditContainer>
        </div>
      )}

      {onlyDesktop ? (
        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
          <Divider />
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              gap: '24px',
              marginY: '16px'
            }}>
            <CancelButton onClick={handleClose}>Cancel</CancelButton>
            <SaveButton onClick={getCropData}>Save</SaveButton>
          </Grid>
        </Grid>
      ) : (
        <div className={styles.buttonContainer}>
          <Button color="PURPLE" size="BIG" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="PURPLE" size="BIG" onClick={getCropData}>
            Save
          </Button>
        </div>
      )}
    </>
  );
};

Editor.propTypes = {
  handleClose: PropTypes.func,
  onUploadMultipleBannerImages: PropTypes.func,
  selectedDesktopBanner: PropTypes.string,
  selectedMobileBanner: PropTypes.string
};

Editor.defaultProps = {
  handleClose: () => {},
  onUploadMultipleBannerImages: () => {},
  selectedDesktopBanner: '',
  selectedMobileBanner: ''
};

export default Editor;
