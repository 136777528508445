import * as types from '../types/application_type';
import api from '../../utils/api';
import { useParams, useNavigate } from 'react-router-dom';
const { REACT_APP_API_VERSION } = process.env;

const interviewAttributes = `
  id
  date
  startTime
  venue
  contactPerson
  contactNumber
  status
  offered
  createdAt
  updatedAt
  remark
  messageToJobseeker
  isExpired
  fullStartTime
  interviewNotes{
    id
    note
    edited
    createdAt
    company{
      id
      userName
    }
    companyTeamMember{
      id
      name
    }
  }
  jobApplication {
    id
    appliedAt
    state
    status
    notes
    sendbirdChannelUrl    
    chatbotStatus
    employerHiredStatus
    interviews{
      id
      date
      startTime
      venue
      contactPerson
      contactNumber
      status
      offered
      createdAt
      updatedAt
      remark
      isExpired
      messageToJobseeker
      interviewNotes{
        id
        note
        edited
        createdAt
        company{
          id
          userName
        }
        companyTeamMember{
          id
          name
        }
      }
    }
    job {
      id
      title
      boostedExpired
      enableRefreshJob
      enableFeatureJob
      jobBannerUrl
      enableChatbot
      tracks {
        id
        title
      }
      jobType {
        id
        title
      }
      totalCount
      unviewedCount
      undecidedCount
      shortlistedCount
      kivCount
      rejectedCount
      interviewCount
      offerCount
    }
    user {      
      id
      currentLocation
      workLocationPreference
      rightToWork
      name
      shortSummary
      nationality
      gender
      email
      currentCompany
      fieldOfStudy
      major
      highestEducationLevel
      educationalInstitution
      graduationYear
      age
      profileImageSquare
      trackIds
      videoCvOptimized
      resume
      mobileNumber
      educations{
        totalCount
        edges {
          node {
            id
            educationLevel
            educationalInstitution
            createdAt
            description
            fieldOfStudy
            graduationYear
            updatedAt
          }
        }
      }
      workingExperiences {
        totalCount
        edges {
          node {
            id
            companyName
            jobTitle
            description
            specialization
            industry {
              id
              title
            }
            startDateMonth
            startDateYear
            endDateMonth
            endDateYear
            currentWork
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

const interviewNoteAttributes = `
  id
  note
  edited
  createdAt
  company{
    id    
    userName
  }
  companyTeamMember{
    name
  }
  interview {
    ${interviewAttributes}
  }
`;

const interviewDetailsAttributes = `
  id
  date
  startTime
  venue
  contactPerson
  contactNumber
  messageToJobseeker
  isExpired
`;

const applicationAttributes = `
  id
  status
  state
  sendbirdChannelUrl
  appliedAt
  notes
  employerHiredStatus
  chatbotStatus
  interviews{
    ${interviewAttributes}
  }
  gptSummary
  gptChatSummary
  user {
    id
    currentLocation
    workLocationPreference
    rightToWork
    name
    shortSummary
    nationality
    mobileNumber
    gender
    email
    currentCompany
    fieldOfStudy
    major
    highestEducationLevel
    educationalInstitution
    graduationYear
    age
    profileImageSquare
    trackIds
    videoCvOptimized
    resume
    educations{
      totalCount
      edges {
        node {
          id
          educationLevel
          educationalInstitution
          createdAt
          description
          fieldOfStudy
          graduationYear
          updatedAt
        }
      }
    }
    workingExperiences {
      totalCount
      edges {
        node {
          id
          companyName
          jobTitle
          description
          specialization
          industry {
            id
            title
          }
          startDateMonth
          startDateYear
          endDateMonth
          endDateYear
          currentWork
          createdAt
          updatedAt
        }
      }
    }
  }
  job {
    id
    title
    boostedExpired
    enableRefreshJob
    enableFeatureJob
    jobBannerUrl
    enableChatbot
    malaysiansOnly
    localOnly
    tracks {
      id
      title
    }
    jobType {
      id
      title
    }
    totalCount
    unviewedCount
    undecidedCount
    shortlistedCount
    kivCount
    rejectedCount
    interviewCount
    offerCount
  }
`;

/*
 * Params:
 * 1) jobId
 * 2) first
 * 3) search
 * 4) searchType
 * 5) sort
 * 6) showState
 * 7) yearsOfExperience
 * 8) withVideoCoverLetter
 * 9) highestEducationLevels
 * 10) malaysians
 * 11) trackIds
 * 12) offset
 */
// For list view
const getApplications =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.FETCH_APPLICATIONS });
    const specialisationQuery = params.specialisations
      ? `specialisations: ${JSON.stringify(params.specialisations)},`
      : `trackIds: ${JSON.stringify(params.trackIds)},`;

    let queryInput = `jobId: "${params.jobId}",
      first: ${params.first},
      offset: ${params.offset},
      search: "${params.search}",
      searchType: "${params.searchType}",
      sort: {by: "${params.sort.by}", direction: "${params.sort.direction}"},
      showState: "${params.showState}",
      nationalities: ${
        params.nationalities ? `"${params.nationalities}"` : null
      },
      currentLocation: ${
        params.currentLocation ? JSON.stringify(params.currentLocation) : null
      },
      relocate: ${params.relocate ? params.relocate : null},
      remote: ${params.remote ? params.remote : null},
      openToSponsor: ${params.openToSponsor ? params.openToSponsor : null},
      visaOrEmploymentPass: ${
        params.visaOrEmploymentPass ? params.visaOrEmploymentPass : null
      },
      localCitizen: ${params.localCitizen ? params.localCitizen : null},
      yearsOfExperience: ${
        params.yearsOfExperience === 0 ? null : params.yearsOfExperience
      },
      withVideoCoverLetter: ${params.withVideoCoverLetter},
      highestEducationLevels: ${JSON.stringify(params.highestEducationLevels)},
      hideViewed: ${params.hideViewed},
      ${params.chatbotStatus ? `, ${'chatbotStatus: "12"'}` : ''}
      `;

    const inputs =
      specialisationQuery +
      (params.appliedFromDate && params.appliedToDate
        ? (queryInput += `appliedFromDate: ${JSON.stringify(
            params.appliedFromDate
          )}, appliedToDate: ${JSON.stringify(params.appliedToDate)},`)
        : queryInput);

    const payload = {
      query: `{
        jobApplications(${inputs}) {
          pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
          }
          edges {
            node {
              ${applicationAttributes}
            }
          }
        }
      }    
    `
    };

    return api
      .apiCall(
        `${REACT_APP_API_VERSION}/graphql`,
        params,
        payload,
        true,
        params.origin == 'all-applications' ? 'read-only' : ''
      )
      .then(async response => {
        if (response.status === 200) {
          if (response.data.data?.jobApplications) {
            const jobApplicationList = response.data.data.jobApplications;
            return dispatch({
              type: types.FETCH_APPLICATIONS_SUCCEED,
              payload: {
                applications: jobApplicationList,
                refresh: params.refresh,
                hasNext: jobApplicationList?.pageInfo?.hasNextPage
              }
            });
          }
        }
        return dispatch({
          type: types.FETCH_APPLICATIONS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_APPLICATIONS_FAILED
        });
      });
  };

const getUndecidedApplications =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.FETCH_UNDECIDED_APPLICATIONS });

    const queryInput = `jobId: "${params.jobId}",
    first: ${params.first},
    offset: ${params.offset},
    search: "${params.search}",
    searchType: "${params.searchType}",
    sort: {by: "${params.sort.by}", direction: "${params.sort.direction}"},
    showState: "${params.showState}",
    nationalities: ${params.nationalities ? `"${params.nationalities}"` : null},
    currentLocation: ${JSON.stringify(params.currentLocation)},
    relocate: ${params.relocate ? params.relocate : null},
    remote: ${params.remote ? params.remote : null},
    openToSponsor: ${params.openToSponsor ? params.openToSponsor : null},
      visaOrEmploymentPass: ${
        params.visaOrEmploymentPass ? params.visaOrEmploymentPass : null
      },
      localCitizen: ${params.localCitizen ? params.localCitizen : null},
    yearsOfExperience: ${
      params.yearsOfExperience === 0 ? null : params.yearsOfExperience
    },
    withVideoCoverLetter: ${params.withVideoCoverLetter},
    highestEducationLevels: ${JSON.stringify(params.highestEducationLevels)},
    trackIds: ${JSON.stringify(params.trackIds)},
    hideViewed: ${params.hideViewed},
    ${params.chatbotStatus ? `, ${'chatbotStatus: "12"'}` : ''}`;

    const payload = {
      query: `{
        jobApplications(${queryInput}) {
          pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
          }
          edges {
            node {
              ${applicationAttributes}
            }
          }
        }
      }    
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data.data?.jobApplications) {
            const jobApplicationList = response.data.data.jobApplications;
            return dispatch({
              type: types.FETCH_UNDECIDED_APPLICATIONS_SUCCEED,
              payload: {
                applications: jobApplicationList,
                refresh: params.refresh,
                hasNext: jobApplicationList?.pageInfo?.hasNextPage
              }
            });
          }
        }
        return dispatch({
          type: types.FETCH_UNDECIDED_APPLICATIONS_FAILED,
          isCancelled: response.isCancelled
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_UNDECIDED_APPLICATIONS_FAILED
        });
      });
  };

const getKivApplications =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.FETCH_KIV_APPLICATIONS });

    const queryInput = `jobId: "${params.jobId}",
    first: ${params.first},
    offset: ${params.offset},
    search: "${params.search}",
    searchType: "${params.searchType}",
    sort: {by: "${params.sort.by}", direction: "${params.sort.direction}"},
    showState: "${params.showState}",
    nationalities: ${params.nationalities ? `"${params.nationalities}"` : null},
    currentLocation: ${JSON.stringify(params.currentLocation)},
    relocate: ${params.relocate ? params.relocate : null},
    remote: ${params.remote ? params.remote : null},
    openToSponsor: ${params.openToSponsor ? params.openToSponsor : null},
      visaOrEmploymentPass: ${
        params.visaOrEmploymentPass ? params.visaOrEmploymentPass : null
      },
      localCitizen: ${params.localCitizen ? params.localCitizen : null},
    yearsOfExperience: ${
      params.yearsOfExperience === 0 ? null : params.yearsOfExperience
    },
    withVideoCoverLetter: ${params.withVideoCoverLetter},
    highestEducationLevels: ${JSON.stringify(params.highestEducationLevels)},
    trackIds: ${JSON.stringify(params.trackIds)}
    ${params.chatbotStatus ? `, ${'chatbotStatus: "12"'}` : ''}`;

    const payload = {
      query: `{
        jobApplications(${queryInput}) {
          pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
          }
          edges {
            node {
              ${applicationAttributes}
            }
          }
        }
      }    
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data.data?.jobApplications) {
            const jobApplicationList = response.data.data.jobApplications;

            return dispatch({
              type: types.FETCH_KIV_APPLICATIONS_SUCCEED,
              payload: {
                applications: jobApplicationList,
                refresh: params.refresh,
                hasNext: jobApplicationList?.pageInfo?.hasNextPage
              }
            });
          }
        }
        return dispatch({
          type: types.FETCH_KIV_APPLICATIONS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_KIV_APPLICATIONS_FAILED
        });
      });
  };

const getShortlistedApplications =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.FETCH_SHORTLISTED_APPLICATIONS });

    const queryInput = `jobId: "${params.jobId}",
    first: ${params.first},
    offset: ${params.offset},
    search: "${params.search}",
    searchType: "${params.searchType}",
    sort: {by: "${params.sort.by}", direction: "${params.sort.direction}"},
    showState: "${params.showState}",
    nationalities: ${params.nationalities ? `"${params.nationalities}"` : null},
    currentLocation: ${JSON.stringify(params.currentLocation)},
    relocate: ${params.relocate ? params.relocate : null},
    remote: ${params.remote ? params.remote : null},
    openToSponsor: ${params.openToSponsor ? params.openToSponsor : null},
      visaOrEmploymentPass: ${
        params.visaOrEmploymentPass ? params.visaOrEmploymentPass : null
      },
      localCitizen: ${params.localCitizen ? params.localCitizen : null},
    yearsOfExperience: ${
      params.yearsOfExperience === 0 ? null : params.yearsOfExperience
    },
    withVideoCoverLetter: ${params.withVideoCoverLetter},
    highestEducationLevels: ${JSON.stringify(params.highestEducationLevels)},
    trackIds: ${JSON.stringify(params.trackIds)}
    ${params.chatbotStatus ? `, ${'chatbotStatus: "12"'}` : ''}`;

    const payload = {
      query: `{
        jobApplications(${queryInput}) {
          pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
          }
          edges {
            node {
              ${applicationAttributes}
            }
          }
        }
      }    
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data.data?.jobApplications) {
            const jobApplicationList = response.data.data.jobApplications;

            return dispatch({
              type: types.FETCH_SHORTLISTED_APPLICATIONS_SUCCEED,
              payload: {
                applications: jobApplicationList,
                refresh: params.refresh,
                hasNext: jobApplicationList?.pageInfo?.hasNextPage
              }
            });
          }
        }
        return dispatch({
          type: types.FETCH_SHORTLISTED_APPLICATIONS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_SHORTLISTED_APPLICATIONS_FAILED
        });
      });
  };

const getRejectedApplications =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.FETCH_REJECTED_APPLICATIONS });

    const queryInput = `jobId: "${params.jobId}",
    first: ${params.first},
    offset: ${params.offset},
    search: "${params.search}",
    searchType: "${params.searchType}",
    sort: {by: "${params.sort.by}", direction: "${params.sort.direction}"},
    showState: "${params.showState}",
    nationalities: ${params.nationalities ? `"${params.nationalities}"` : null},
    currentLocation: ${JSON.stringify(params.currentLocation)},
    relocate: ${params.relocate ? params.relocate : null},
    remote: ${params.remote ? params.remote : null},
    openToSponsor: ${params.openToSponsor ? params.openToSponsor : null},
      visaOrEmploymentPass: ${
        params.visaOrEmploymentPass ? params.visaOrEmploymentPass : null
      },
      localCitizen: ${params.localCitizen ? params.localCitizen : null},
    yearsOfExperience: ${
      params.yearsOfExperience === 0 ? null : params.yearsOfExperience
    },
    withVideoCoverLetter: ${params.withVideoCoverLetter},
    highestEducationLevels: ${JSON.stringify(params.highestEducationLevels)},
    trackIds: ${JSON.stringify(params.trackIds)},
    hideViewed: ${params.hideViewed}
    ${params.chatbotStatus ? `, ${'chatbotStatus: "12"'}` : ''}`;

    const payload = {
      query: `{
        jobApplications(${queryInput}) {
          pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
          }
          edges {
            node {
              ${applicationAttributes}
            }
          }
        }
      }    
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data.data?.jobApplications) {
            const jobApplicationList = response.data.data.jobApplications;

            return dispatch({
              type: types.FETCH_REJECTED_APPLICATIONS_SUCCEED,
              payload: {
                applications: jobApplicationList,
                refresh: params.refresh,
                hasNext: jobApplicationList?.pageInfo?.hasNextPage
              }
            });
          }
        }
        return dispatch({
          type: types.FETCH_REJECTED_APPLICATIONS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_REJECTED_APPLICATIONS_FAILED
        });
      });
  };

const getInterviews =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.FETCH_INTERVIEWS });

    //after: "${params.endCursor}",
    const queryInput = `jobId: "${params.jobId}",
    offset: ${params.offset},
    first: ${params.first},    
    limit: ${params.limit},    
    status: ${params.status ? `"${params.status}"` : params.status},
    sort: {by: "${params.sort.by}", direction: "${params.sort.direction}"},
    search: "${params.search ?? ''}",
    searchType: "${params.searchType ?? ''}",       
    nationalities: ${params.nationalities ? `"${params.nationalities}"` : null},
    currentLocation: ${JSON.stringify(params.currentLocation ?? [])},
    relocate: ${params.relocate ? params.relocate : null},
    remote: ${params.remote ? params.remote : null},
    openToSponsor: ${params.openToSponsor ? params.openToSponsor : null},
      visaOrEmploymentPass: ${
        params.visaOrEmploymentPass ? params.visaOrEmploymentPass : null
      },
      localCitizen: ${params.localCitizen ? params.localCitizen : null},
    yearsOfExperience: ${
      params.yearsOfExperience === 0 ? null : params.yearsOfExperience
    },
    withVideoCoverLetter: ${params.withVideoCoverLetter},
    highestEducationLevels: ${JSON.stringify(
      params.highestEducationLevels ?? []
    )},
    trackIds: ${JSON.stringify(params.trackIds ?? [])},
    hideViewed: ${params.hideViewed}
    ${params.chatbotStatus ? `, ${'chatbotStatus: "12"'}` : ''}`;

    const payload = {
      query: `{
        jobInterviews(${queryInput}) {
          ${params.refresh ? 'totalCount' : ''}
          pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
          }
          edges {
            node {
              ${interviewAttributes}
            }
          }
        }
      }
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data.data?.jobInterviews) {
            const interviewList = response.data.data.jobInterviews;

            return dispatch({
              type: types.FETCH_INTERVIEWS_SUCCEED,
              payload: {
                interviews: interviewList,
                refresh: params.refresh,
                hasNext: interviewList?.pageInfo?.hasNextPage,
                endCursor: interviewList?.pageInfo?.endCursor,
                count: interviewList?.totalCount
              }
            });
          }
        }
        return dispatch({
          type: types.FETCH_INTERVIEWS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_INTERVIEWS_FAILED
        });
      });
  };

const getOffers =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: types.FETCH_OFFERS });

    const queryInput = `jobId: "${params.jobId}",
    offset: ${params.offset},
    first: ${params.first},    
    limit: ${params.limit},    
    status: ${params.status ? `"${params.status}"` : params.status},
    sort: {by: "${params.sort.by}", direction: "${params.sort.direction}"},
    search: "${params.search}",
    searchType: "${params.searchType}",       
    nationalities: ${params.nationalities ? `"${params.nationalities}"` : null},
    currentLocation: ${JSON.stringify(params.currentLocation)},
    relocate: ${params.relocate ? params.relocate : null},
    remote: ${params.remote ? params.remote : null},
    openToSponsor: ${params.openToSponsor ? params.openToSponsor : null},
      visaOrEmploymentPass: ${
        params.visaOrEmploymentPass ? params.visaOrEmploymentPass : null
      },
      localCitizen: ${params.localCitizen ? params.localCitizen : null},
    yearsOfExperience: ${
      params.yearsOfExperience === 0 ? null : params.yearsOfExperience
    },
    withVideoCoverLetter: ${params.withVideoCoverLetter},
    highestEducationLevels: ${JSON.stringify(params.highestEducationLevels)},
    trackIds: ${JSON.stringify(params.trackIds)},
    hideViewed: ${params.hideViewed}
    ${params.chatbotStatus ? `, ${'chatbotStatus: "12"'}` : ''}`;

    const payload = {
      query: `{
        jobInterviews(${queryInput}) {
          ${params.refresh ? 'totalCount' : ''}
          pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
          }
          edges {
            node {
              ${interviewAttributes}
            }
          }
        }
      }
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data.data?.jobInterviews) {
            const offerList = response.data.data.jobInterviews;

            return dispatch({
              type: types.FETCH_OFFERS_SUCCEED,
              payload: {
                offers: offerList,
                refresh: params.refresh,
                hasNext: offerList?.pageInfo?.hasNextPage,
                endCursor: offerList?.pageInfo?.endCursor,
                count: offerList?.totalCount
              }
            });
          }
        }
        return dispatch({
          type: types.FETCH_OFFERS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_OFFERS_FAILED
        });
      });
  };

const getPublicJobApplicationWithoutRedux = async (params = {}) => {
  const queryInput = `id: "${params.id}",
    companyId: "${params.company_id}"`;

  const payload = {
    query: `{
        publicJobApplication(${queryInput}) {
          ${applicationAttributes}
        }
      }    
    `
  };

  return api
    .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
    .then(async response => {
      if (response.status === 200) {
        if (response.data.data?.publicJobApplication) {
          const jobApplication = response.data.data.publicJobApplication;

          return {
            application: jobApplication
          };
        }
      }
      return {
        application: null
      };
    })
    .catch(error => {
      return {
        application: null
      };
    });
};

const changeJobApplicationState =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_APPLICATION_STATE,
      applicationId: params.applicationId
    });

    let mutationInput = `id: "${params.applicationId}",
      state: "${params.state}",
    `;

    if (params.reason) {
      mutationInput += `, blacklistReason: "${params.reason}"`;
    }

    const payload = {
      query: `mutation {
        changeJobApplicationState(input: {${mutationInput}}) {
          jobApplication {         
            ${applicationAttributes}          
          }    
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data.data?.changeJobApplicationState) {
            return dispatch({
              type: types.UPDATE_APPLICATION_STATE_SUCCEED,
              applicationId: params.applicationId,
              application:
                response.data.data?.changeJobApplicationState.jobApplication
            });
          }
        }
        return dispatch({
          type: types.UPDATE_APPLICATION_STATE_FAILED,
          applicationId: params.applicationId
        });
      })
      .catch(error => {
        return dispatch({
          type: types.UPDATE_APPLICATION_STATE_FAILED,
          applicationId: params.applicationId
        });
      });
  };

const changeMultipleJobApplicationState =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_MULTIPLE_APPLICATION_STATE,
      applicationIds: params.applicationIds
    });

    const mutationInput = `id: ${JSON.stringify(params.applicationIds)},
      state: "${params.state}",
    `;

    const payload = {
      query: `mutation {
        bulkUpdateJobApplicationState(input: {${mutationInput}}) {
          jobApplication {         
            ${applicationAttributes}          
          }   
          status 
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data.data?.bulkUpdateJobApplicationState) {
            return dispatch({
              type: types.UPDATE_MULTIPLE_APPLICATION_STATE_SUCCEED,
              applicationIds: params.applicationIds
            });
          }
        }
        return dispatch({
          type: types.UPDATE_MULTIPLE_APPLICATION_STATE_FAILED,
          applicationIds: params.applicationIds
        });
      })
      .catch(error => {
        return dispatch({
          type: types.UPDATE_MULTIPLE_APPLICATION_STATE_FAILED,
          applicationIds: params.applicationIds
        });
      });
  };

const viewApplication =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.VIEW_APPLICATION,
      applicationId: params.applicationId
    });

    let mutationInput = `id: "${params.applicationId}"`;

    const payload = {
      query: `mutation {
        viewedJobApplication(input: {${mutationInput}}) {
          jobApplication {         
            ${applicationAttributes}          
          }    
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data.data?.viewedJobApplication) {
            return dispatch({
              type: types.VIEW_APPLICATION_SUCCEED,
              applicationId: params.applicationId,
              state: params.state
            });
          }
        }
        return dispatch({
          type: types.VIEW_APPLICATION_FAILED,
          applicationId: params.applicationId
        });
      })
      .catch(error => {
        return dispatch({
          type: types.VIEW_APPLICATION_FAILED,
          applicationId: params.applicationId
        });
      });
  };

const scheduleInterview =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.SCHEDULE_INTERVIEW
    });

    const mutationInput = `id: "${params.id}",
    startTime: "${params.startTime}",
    date: "${params.date}",
    venue: "${params.venue}",
    contactPerson: "${params.contactPerson}",
    contactNumber: "${params.contactNumber}",
    messageToJobseeker: "${params.messageToJobseeker}"`;

    const payload = {
      query: `mutation {
        scheduleInterview(input: {${mutationInput}}) {
          interview {                     
            ${interviewAttributes}
          }
          message    
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data.data?.scheduleInterview?.interview) {
            const interview = response.data.data.scheduleInterview.interview;
            return dispatch({
              type: types.SCHEDULE_INTERVIEW_SUCCEED,
              interview: interview,
              state: params.state,
              applicationId: params.id
            });
          } else {
            return dispatch({
              type: types.SCHEDULE_INTERVIEW_FAILED,
              errorMessage: response.data.data.scheduleInterview.message
            });
          }
        }
        return dispatch({
          type: types.SCHEDULE_INTERVIEW_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.SCHEDULE_INTERVIEW_FAILED
        });
      });
  };

const addInterviewNote =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.ADD_INTERVIEW_NOTE
    });

    const mutationInput = `id: "${params.id}",
    remark: "${params.note}"`;

    const payload = {
      query: `mutation {
        addInterviewNote(input: {${mutationInput}}) {
          interview {                     
            ${interviewAttributes}
          }
          message    
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.addInterviewNote) {
            const interview = response.data.data.addInterviewNote.interview;

            return dispatch({
              type: types.ADD_INTERVIEW_NOTE_SUCCEED,
              interview: interview,
              jobApplication: interview?.jobApplication
            });
          }
        }
        return dispatch({
          type: types.ADD_INTERVIEW_NOTE_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.ADD_INTERVIEW_NOTE_FAILED
        });
      });
  };

const updateInterviewNote =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_INTERVIEW_NOTE
    });

    const mutationInput = `id: "${params.id}",
    remark: "${params.note}"`;

    const payload = {
      query: `mutation {
        updateInterviewNote(input: {${mutationInput}}) {
          interviewNote {                     
            ${interviewNoteAttributes}
          }
          message    
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.updateInterviewNote) {
            const interviewNote =
              response.data.data.updateInterviewNote.interviewNote;

            if (!interviewNote) {
              return dispatch({
                type: types.UPDATE_INTERVIEW_NOTE_FAILED,
                message: response.data.data.updateInterviewNote.message
              });
            }

            return dispatch({
              type: types.UPDATE_INTERVIEW_NOTE_SUCCEED,
              interview: interviewNote?.interview,
              jobApplication: interviewNote?.interview?.jobApplication
            });
          }
        }
        return dispatch({
          type: types.UPDATE_INTERVIEW_NOTE_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.UPDATE_INTERVIEW_NOTE_FAILED
        });
      });
  };

const addApplicationNote =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.ADD_APPLICATION_NOTE
    });

    const mutationInput = `id: "${params.id}",
    note: "${params.note}"`;

    const payload = {
      query: `mutation {
        addJobApplicationNote(input: {${mutationInput}}) {
          jobApplication {                     
            ${applicationAttributes}
          }
          message    
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.addJobApplicationNote) {
            const jobApplication =
              response.data.data.addJobApplicationNote.jobApplication;

            return dispatch({
              type: types.ADD_APPLICATION_NOTE_SUCCEED,
              jobApplication: jobApplication
            });
          }
        }
        return dispatch({
          type: types.ADD_APPLICATION_NOTE_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.ADD_APPLICATION_NOTE_FAILED
        });
      });
  };

const closingLoop =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.CLOSE_LOOP
    });

    const mutationInput = `id: "${params.id}"`;

    // jobApplication {
    //   ${applicationAttributes}
    // }

    const payload = {
      query: `mutation {
        changeEmployerHiredStatus(input: {${mutationInput}}) {
          interview {
            ${interviewAttributes}
          }
          message
          redirectUrl
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.changeEmployerHiredStatus) {
            const interview =
              response.data.data.changeEmployerHiredStatus.interview;
            const jobApplication = interview?.jobApplication
              ? interview.jobApplication
              : {};

            const redirectUrl =
              response.data.data.changeEmployerHiredStatus.redirectUrl;

            if (redirectUrl == null) {
              return dispatch({
                type: types.CLOSE_LOOP_FAILED
              });
            } else {
              return dispatch({
                type: types.CLOSE_LOOP_SUCCEED,
                jobApplication: jobApplication,
                interview: interview,
                redirectUrl: redirectUrl,
                currentState: params.state
              });
            }
          }
        }
        return dispatch({
          type: types.CLOSE_LOOP_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.CLOSE_LOOP_FAILED
        });
      });
  };

const updateInterviewStatus =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_INTERVIEW_STATUS
    });

    const mutationInput = `${
      params.isInterview
        ? `interviewId: "${params.id}"`
        : `jobApplicationId: "${params.id}"`
    },
    status: "${params.status}",
    remark: "${params.remark}"`;

    const payload = {
      query: `mutation {
        updateInterviewStatus(input: {${mutationInput}}) {
          interview {                     
            ${interviewAttributes}
          }
          message          
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.updateInterviewStatus) {
            const interview =
              response.data.data.updateInterviewStatus.interview;

            return dispatch({
              type: types.UPDATE_INTERVIEW_STATUS_SUCCEED,
              previousApplicationId: params.id,
              interview: interview,
              isInterview: params.isInterview,
              currentState: params.currentState,
              status: params.status
            });
          }
        }
        return dispatch({
          type: types.UPDATE_INTERVIEW_STATUS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.UPDATE_INTERVIEW_STATUS_FAILED
        });
      });
  };

const updateUndecidedApplications = applications => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_UNDECIDED_APPLICATIONS,
        applications: applications
      })
    );
  });
};

const updateKivApplications = applications => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_KIV_APPLICATIONS,
        applications: applications
      })
    );
  });
};

const updateShortlistedApplications = applications => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_SHORTLISTED_APPLICATIONS,
        applications: applications
      })
    );
  });
};

const updateRejectedApplications = applications => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_REJECTED_APPLICATIONS,
        applications: applications
      })
    );
  });
};

const updateInterviews = interviews => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_INTERVIEWS,
        interviews: interviews
      })
    );
  });
};

const updateOffers = offers => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_OFFERS,
        offers: offers
      })
    );
  });
};

const updateApplicationCount = count => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_APPLICATION_COUNTS,
        applicationCount: count
      })
    );
  });
};

const updateAllApplications = allApplications => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_ALL_APPLICATIONS,
        allApplications: allApplications
      })
    );
  });
};

const updateCandidateSnapshotStatus = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_CANDIDATE_SNAPSHOT_STATUS,
        status: status
      })
    );
  });
};

const updateCurrentCandidateIndex = index => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_CURRENT_CANDIDATE_INDEX,
        index: index
      })
    );
  });
};

const updateApplicationSelectAllStatus = (state, status) => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_APPLICATION_SELECT_ALL_STATUS,
        state: state,
        status: status
      })
    );
  });
};

const updateFirstScheduleInterviewModalStatus =
  (status, jobApplication) => async dispatch => {
    return new Promise(resolve => {
      return resolve(
        dispatch({
          type: types.UPDATE_FIRST_SCHEDULE_INTERVIEW_MODAL_STATUS,
          status: status,
          jobApplication: jobApplication
        })
      );
    });
  };

const updateNextScheduleInterviewModalStatus = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_NEXT_SCHEDULE_INTERVIEW_MODAL_STATUS,
        status: status
      })
    );
  });
};

const updateChangeStateFailedIds = ids => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_CHANGE_STATE_FAILED_IDS,
        ids: ids
      })
    );
  });
};

const updateDraggingState = state => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_DRAGGING_STATE,
        state: state
      })
    );
  });
};

const updateIsDraggingInterviewStatus = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_IS_DRAGGING_INTERVIEW_STATUS,
        status: status
      })
    );
  });
};

const updateInterviewInfo = interviewInfo => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_INTERVIEW_INFO,
        interviewInfo: interviewInfo
      })
    );
  });
};

const toggleRejectPopup = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.TOGGLE_REJECT_POPUP,
        status: status
      })
    );
  });
};

const updateBlacklistPopup = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_BLACKLIST_POPUP,
        status: status
      })
    );
  });
};

const updateInterviewData = data => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_INTERVIEW_DATA,
        data: data
      })
    );
  });
};

const triggerBulkRejectPopup = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.TRIGGER_BULK_REJECT_POPUP,
        status: status
      })
    );
  });
};

const getCurrentState = currentState => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({ type: types.GET_CURRENT_STATE, currentState: currentState })
    );
  });
};

const updateInterviewDetails =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_INTERVIEW_DETAILS
    });

    const mutationInput = `
      id: "${params?.id}"
      date: "${params?.date}"
      startTime: "${params?.startTime}"
      venue: "${params?.venue}"
      contactPerson: "${params?.contactPerson}"
      contactNumber: "${params?.contactNumber}"
      messageToJobseeker: "${params?.messageToJobseeker}"
    `;

    const payload = {
      query: `mutation {
        updateInterviewDetails(input: {${mutationInput}}) {
          interviews {                     
            ${interviewDetailsAttributes}
          }     
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.updateInterviewDetails) {
            const interviews =
              response?.data?.data?.updateInterviewDetails?.interviews;

            return dispatch({
              type: types.UPDATE_INTERVIEW_DETAILS_SUCCEED,
              interviewInfo: interviews[0],
              interviews: interviews
            });
          }
        }
        return dispatch({
          type: types.UPDATE_INTERVIEW_DETAILS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.UPDATE_INTERVIEW_DETAILS_FAILED
        });
      });
  };

const deleteInterview =
  (params = {}) =>
  (dispatch, getState) => {
    dispatch({
      type: types.DELETE_INTERVIEW
    });

    const mutationInput = `
    id: ${params?.interviewId}
  `;

    const payload = {
      query: `mutation{
        deleteInterview(input: {
         ${mutationInput}
        }) {
          interviews {
           ${interviewDetailsAttributes}
          }
          success
        }
      }`
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status == 200) {
          if (response?.data?.data?.deleteInterview) {
            const interviews =
              response?.data?.data?.deleteInterview?.interviews;
            const success = response?.data?.data?.deleteInterview?.success;

            return dispatch({
              type: types.DELETE_INTERVIEW_SUCCEED,
              interviews: interviews,
              success: success
            });
          }
        }
        return dispatch({
          type: types.DELETE_INTERVIEW_FAILED
        });
      })
      .catch(() => {
        return dispatch({
          type: types.DELETE_INTERVIEW_FAILED
        });
      });
  };

const toggleCloseLoopPopup = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.TOGGLE_CLOSE_LOOP_POPUP,
        status: status
      })
    );
  });
};

const updateApplicationParams = applicationParams => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_APPLICATTION_PARAMS,
        applicationParams: applicationParams
      })
    );
  });
};

const updateJobApplications = applications => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_JOB_APPLICATIONS,
        applications: applications
      })
    );
  });
};

const updateApplicationCurrentPage = page => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({ type: types.UPDATE_APPLICATION_CURRENT_PAGE, page: page })
    );
  });
};

const updateApplicationUserIds = userIds => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_APPLICATION_USER_IDS,
        userIds: userIds
      })
    );
  });
};

const bulkDownloadResumes =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.BULK_DOWNLOAD_RESUME
    });

    const queryInput = `userIds: ${JSON.stringify(params.userIds)}`;

    const payload = {
      query: `{
        bulkDownloadResume(${queryInput})
    }`
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.bulkDownloadResume) {
            const { bulkDownloadResume } = response.data.data;
            return dispatch({
              type: types.BULK_DOWNLOAD_RESUME_SUCCEED,
              resumes: bulkDownloadResume
            });
          }
        }
        return dispatch({
          type: types.BULK_DOWNLOAD_RESUME_FAILED,
          error: response.data.errors[0]
        });
      })
      .catch(error => {
        return dispatch({
          type: types.BULK_DOWNLOAD_RESUME_FAILED
        });
      });
  };

export {
  getApplications,
  getUndecidedApplications,
  getKivApplications,
  getShortlistedApplications,
  getRejectedApplications,
  getInterviews,
  getOffers,
  updateUndecidedApplications,
  updateKivApplications,
  updateShortlistedApplications,
  updateRejectedApplications,
  updateInterviews,
  updateOffers,
  changeJobApplicationState,
  viewApplication,
  updateApplicationCount,
  updateAllApplications,
  updateCandidateSnapshotStatus,
  updateCurrentCandidateIndex,
  updateApplicationSelectAllStatus,
  changeMultipleJobApplicationState,
  scheduleInterview,
  addApplicationNote,
  updateFirstScheduleInterviewModalStatus,
  updateNextScheduleInterviewModalStatus,
  updateChangeStateFailedIds,
  closingLoop,
  updateInterviewStatus,
  updateDraggingState,
  updateIsDraggingInterviewStatus,
  addInterviewNote,
  updateInterviewNote,
  getPublicJobApplicationWithoutRedux,
  updateInterviewInfo,
  toggleRejectPopup,
  updateInterviewDetails,
  deleteInterview,
  updateInterviewData,
  triggerBulkRejectPopup,
  getCurrentState,
  toggleCloseLoopPopup,
  updateApplicationParams,
  updateJobApplications,
  updateBlacklistPopup,
  updateApplicationCurrentPage,
  updateApplicationUserIds,
  bulkDownloadResumes
};
