import { styled as styling } from "@mui/material/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { ClipboardDocumentIcon , EnvelopeIcon } from '@heroicons/react/24/outline';
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import {
  Done as DoneIcon,
  WhatsApp as WhatsAppIcon
} from '@mui/icons-material';

export const PageContainer = styling('div')((props) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  alignItems: 'center',

  [props.theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  }

}));

export const Message = styling('span')((props) => ({
  fontSize: '16px',
  color: 'rgba(0, 0, 0, 0.87)',
  margin: '0',
  fontWeight: 400,
  position: 'relative',
  lineHeight: '24px',
  letterSpacing: '0.15px',

  [props.theme.breakpoints.down('md')]: {
    fontSize: '16px',
    letterSpacing: '0',
  }, 

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '20px',
  }, 
}));

export const MessageLink = styling(Message)((props) => ({
  display: 'inline-flex',
  gap: '5px',
}));

export const MessageBlack = styling(Message)((props) => ({
  color: '#000',
}));

export const FooterContainerLink = styling(Message)((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0',
  alignItems: 'center',
  fontWeight: 700,
  fontSize: '16px',
  color: '#512ACC',
  textDecoration: 'underline',

  '&:hover': {
    cursor: 'pointer',
    opacity: '0.8',
  },

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  }
}));

export const BoldMessage = styling('span')((props) => ({
  fontSize: '20px',
  color: '#000',
  margin: '0',
  fontWeight: 700,
  position: 'relative',
  lineHeight: '24px',

  [props.theme.breakpoints.down('md')]: {
    fontSize: '20px',
  },

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '20px',
  },
}));

export const SecondContainer = styling('div')((props) => ({
  position: 'relative',
  width: '50%',
  height: '100%',
  padding: '50px 100px',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  justifyContent: 'center',
  animation: 'fadeIn 1.5s 0.8s ease-in both',

  '@keyframes fadeIn': {
    'from':{
      opacity: '0',
      transfrom: 'translate3d(0, -10%, 0)',
    },

    'to':{
      opacity: '1',
      transfrom: 'translate3d(0, 0, 0)',
    },
  },

  [props.theme.breakpoints.down('md')]: {
    width: '100%',
    minHeight: '450px',
    padding: '24px 0px 32px 0px',
    maxWidth: '500px',
    margin: 'auto'
  },

  [props.theme.breakpoints.down('sm')]: {
    gap: '24px',
    padding: '16px 16px 32px 16px',
    minHeight: 'unset',
  },  

}));

export const NextPageButton = styling(Button)((props) => ({
  width: '200px',
  height: '48px',
  backgroundColor: '#2E2E2E',
  borderRadius: '100px',
  color: '#FFF',
  fontSize: '16px',
  textTransform: 'none',
  fontWeight: '700',
  padding: '0 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',

  ':disabled': {
    backgroundColor: '#D8D8D8',
    cursor: 'not-allowed',
    color: '#FFF',

    ':hover': {
      backgroundColor: '#D8D8D8'
    }
  },

  ':hover': {
    backgroundColor: '#222'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '180px',
    height: '36px',
    fontSize: '14px',
  },

  
  }));

export const BackButton = styling(IconButton)((props) => ({
  padding: '8px 0',
  ':hover': {
    background: 'none',
  },
    ":disabled": {
        cursor: "not-allowed",
    },
}));

export const FooterContainer = styling(Box)((props) => ({
    display: "flex",
    justifyContent: "center",
    gap: '16px',
    padding: "0",
    alignItems: "center",
}));

export const KeyboardBackspaceIconStyled = styling(ArrowLeftIcon)((props) => ({
    padding: '0',
    width: "24px",
    height: "24px",
    color: '#000',
}));

export const HeightSpacer = styling('div')((props) => ({
  height: props.$height? props.$height : '5px',
}));


export const SocialButton = styling('div')((props) => ({
  display: 'flex',
  width: '48px',
  height:'48px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '16px',
  borderRadius: '50%',
  background: '#EFEFFD',
  color: '#512ACC',
  letterSpacing: '0.15px',
  lineHeight: '24px',
  fontWeight: '400',
  fontSize: '16px',
  boxShadow: '0px 2.5px 5px rgb(0 0 0 / 20%)',

  '&:hover':{
    color: '#512ACC',
    background: '#EFEFFD',
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '48px',
    height:'48px',
  },


}));

export const FontAwesomeIconStyled = styling(FontAwesomeIcon)((props) => ({
  ...(props.icon_name == 'fa-camera' && {
    fontSize: '18px',
  }),

  ...(props.icon_name == 'fa-lightbulb' && {
    fontSize: '18px',
  }),
}));

export const CopySection = styling('div')((props) => ({
  width: '100%',
  border: '1px solid #512ACC',
  padding: '8px 16px',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'space-between',

}));

export const CopyText = styling('input')((props) => ({
  border: 'none',
  textAlign: 'center',
  fontSize: '25px',
  fontWeight: '700',
  color: '#512ACC',
  width: '80%',
  lineHeight: '32px',

  '&:focus-visible':{
    outline: 'none',
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '70%',
  },

  
}));

export const CopyButton = styling('button')((props) => ({
  width: '20%',
  border: 'none',
  padding: '0px',
  borderRadius: '10px',
  background: 'transparent',
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  justifyContent: 'flex-end',
  fontSize: '14px',
  letterSpacing: '0.15px',
  lineHeight: '24px',
  fontWeight: '400',
  color: '#222 !important',

  [props.theme.breakpoints.down('sm')]: {
    width: '30%',
  },

}));

export const WhatsAppIconStyled = styling(WhatsAppIcon)((props) => ({
  fontSize: '20px',
}));

export const EmailIconStyled = styling(EnvelopeIcon)((props) => ({
  fontSize: '19px',
  width: '20px',
  height: '20px',
}));

export const CopyIconStyled = styling(ClipboardDocumentIcon)((props) => ({
  fontSize: '18px',
  width: '20px',
  height: '20px',
}));

export const TickIconStyled = styling(DoneIcon)((props) => ({
  fontSize: '18px',
}));

export const AnimatedContainer = styling('div')((props) => ({
  position: 'relative',
  width: '50%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '50px 100px',

  [props.theme.breakpoints.down('md')]: {
    width: '100%',
    height: '30rem',
    padding: '16px',
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '45vh',
    padding: '16px',
  },

}));

export const ContentContainer = styling('div')((props) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  gap: '40px',
  justifyContent: 'space-evenly',

  animation: 'fadeIn 1.5s 0.8s ease-in both',

  '@keyframes fadeIn': {
    'from':{
      opacity: '0',
      transfrom: 'translate3d(0, -10%, 0)',
    },

    'to':{
      opacity: '1',
      transfrom: 'translate3d(0, 0, 0)',
    },
  },

  [props.theme.breakpoints.down('md')]: {
    maxWidth: '500px',
    width: '100%',
    margin: 'auto',
  },  

  [props.theme.breakpoints.down('sm')]: {
    gap: '24px',
  },  
}));

export const Title = styling('h1')((props) => ({
  fontSize: '42px',
  color: '#40249B',
  fontWeight: 700,
  margin: '0',
  lineHeight: '45px',

  [props.theme.breakpoints.down('md')]: {
    fontSize: '42px',
  },  

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '25px',
    lineHeight: '32px',
  },  
}));

export const BoldTitle = styling('h1')((props) => ({
  fontSize: '25px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontWeight: 700,
  margin: '0',
  lineHeight: '32px',

  [props.theme.breakpoints.down('md')]: {
    fontSize: '25px',
  }, 

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    lineHeight: '24px',
  }, 
}));

export const MessageBox = styling('div')((props) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',

  [props.theme.breakpoints.down('md')]: {
  }, 

  [props.theme.breakpoints.down('sm')]: {
    gap: '8px',
    
  }, 
}));

export const LogoImage = styling('img')((props) => ({
  marginLeft: '5px',
  width: '230px',
  height: '32px',

  [props.theme.breakpoints.down('sm')]: {
    width: '145px',
    height: '20px',
    marginLeft: '2px',
  }, 
}));

export const MessageLine = styling('div')((props) => ({
  fontSize: '14px',
  margin: '0 12px',
  color: 'rgba(0, 0, 0, 0.60)',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.112px'
}));
