import React from 'react';
import { Divider } from '@mui/material';
import Colors from '../../../styles/colors.module.scss';

const SharedFilter = ({ children, buttons, filterTitle }) => {
  return (
    <div
      style={{
        background: `${Colors.terWhite}`,
        borderRadius: '8px',
        position: 'sticky',
        top: '140px',
        minWidth: '22%',
        maxWidth: '22%',
        alignSelf: 'flex-start',
        border: `1px solid ${Colors.terLightGrey}`,
        height: 'calc(100vh - 200px)',
        // overflow: 'auto'
        overflow: 'hidden'
      }}>
      <div
        style={{
          padding: '12px 16px',
          position: 'sticky',
          top: '0',
          background: `${Colors.terWhite}`,
          zIndex: 2
        }}>
        <span
          style={{ fontWeight: '700', fontSize: '16px', fontFamily: 'Inter' }}>
          {filterTitle ? filterTitle : 'Filter'}
        </span>
      </div>
      <Divider />
      <div
        style={{
          overflow: 'auto',
          position: 'relative',
          height: filterTitle ? '90%' : 'auto'
        }}>
        {children}
      </div>

      {buttons}
    </div>
  );
};

export default SharedFilter;
