import { Chip } from '@mui/material';
import { styled } from '@mui/material';
import Colors from '../../../../../styles/colors.module.scss'

export const StyledChip = styled(Chip)(props => ({
  '&.MuiChip-outlined': {
    border: `1px solid ${Colors.priPurple}`,
    borderRadius: '20px',
    ...(props.selected_chip == 'true' && {
      background: Colors.priPurple,
      color: Colors.priWhite
    }),

    '&:hover': {
      color: 'rgba(0, 0, 0, 0.87)',
      background: 'rgba(0, 0, 0, 0.04)'
    }
  }
}));
