import moment from 'moment';

function convertHyphensToUnderscores(id) {
  return id.replace(/-/g, '_');
}

export async function useUploadToVimeo({ file, id }) {
  const accessToken = process.env.REACT_APP_VIMEO_TOKEN;

  const now = moment();

  const dateString = now.format('DDMMMYYYY'); // Format date as 21Jun2024
  const timeString = now.format('HHmmss'); // Format time as 181346

  const url = 'https://api.vimeo.com/me/videos';
  const options = {
    method: 'POST',
    body: JSON.stringify({
      name: `Hiredly_${convertHyphensToUnderscores(
        id
      )}_${dateString}_${timeString}`,
      upload: {
        approach: 'tus',
        size: file.size
      }
    }),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      Accept: 'application/vnd.vimeo.*+json;version=3.4'
    }
  };

  try {
    const response = await fetch(url, options);
    const data = await response.json();

    if (response.ok) {
      const uploadLink = data.upload.upload_link;

      const videoUri = data.uri;
      const videoId = videoUri.split('/').pop();

      const uploadOptions = {
        method: 'PATCH',
        body: file,
        headers: {
          'Tus-Resumable': '1.0.0',
          'Upload-Offset': '0',
          'Content-Type': 'application/offset+octet-stream'
        }
      };

      const uploadResponse = await fetch(uploadLink, uploadOptions);
      if (uploadResponse.ok) {
        return videoId;
      } else {
        return { error: true };
      }
    } else {
      ErrorToast(data?.error);
      return data.error;
    }
  } catch (error) {
    return error;
  }
}
