import { styled as styling } from '@mui/material/styles';

export const PageContainer = styling('div')({
  maxWidth: '80%',
  margin: 'auto',
});

export const ContentContainer = styling('div')({
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  '@media (max-width: 989px)': {
    justifyContent: 'center',
  },
});

export const ResumeContainer = styling('div')({
  '@media (min-width: 990px)': {
    width: '55%',
    paddingRight: '15px',
  },
  '@media (max-width: 989px)': {
    width: '100%',
  },
});

export const CandidateContainer = styling('div')({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '330px',
  '@media (min-width: 990px)': {
    width: '45%',
    maxWidth: '500px',
  },
});
