import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import AdsSlider from './AdsSlider';
import JobsAds from './JobsAds';
import FrequentlyAskedQuestion from './FrequentlyAskedQuestion';
import CompanyLogo from './CompanyLogo';
import { GridStyled } from './AdsSlider/styles';

export default function ProductBundles() {
  return (
    <DefaultLayout white margin={'40px'}>
      <AdsSlider />
      <GridStyled>
        <CompanyLogo />
      </GridStyled>
      <JobsAds />
      <FrequentlyAskedQuestion />
    </DefaultLayout>
  );
}
