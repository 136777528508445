import { Grid, Typography, Chip } from '@mui/material';
import {
  PlusIcon,
  PencilSquareIcon,
  EllipsisVerticalIcon
} from '@heroicons/react/24/outline';
import { styled as styling } from '@mui/material/styles';

export const SocialMediaGrid = styling(Grid)(props => ({
  backgroundColor: '#EFEFEF',
  border: '1px dashed #707070',
  width: '140px',
  height: '290px',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer'
}));

export const PencilSquareIconStyled = styling(PencilSquareIcon)(props => ({
  height: '16px',
  width: '16px'
}));
