import { Skeleton } from '@mui/material';
import React from 'react';
import { Button } from '../../../../components';

const SkeletonLoader = () => (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '16px',
    }}>
      <div style={{
        display: 'flex',
        width: '673px',
        padding: '16px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '16px',
        borderBottom: '1px solid #D8D8D8'
      }}>
        <Skeleton variant="box" width={641} height={72} style={{ borderRadius: '8px', background: '#E5E5E5' }} />
      </div>
  
      <div style={{
        display: 'flex',
        width: '673px',
        padding: '16px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '16px',
        borderBottom: '1px solid #D8D8D8'
      }}>
        <Skeleton variant="box" width={641} height={72} style={{ borderRadius: '8px', background: '#E5E5E5' }} />
      </div>
  
      <Skeleton variant="box" width={363} height={21} style={{ borderRadius: '5px', background: '#E5E5E5' }} />
    </div>
  );
  

export default SkeletonLoader;
