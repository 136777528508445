import { Dialog, Divider, IconButton } from '@mui/material';
import React from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  ContentContainer,
  ContentDescription,
  CTAButtonsContainer,
  CTAContainer,
  Title,
  TitleContainer
} from './styles';
import { SharedButton } from '../../JobList/SharedButton';
import { Spinner } from '../../../../components';

const TokensPopup = ({
  title,
  children,
  firstButtonText,
  secondButtonText,
  open,
  onClose,
  onClickFirstButton,
  onClickSecondButton,
  loadingFirstButton,
  loadingSecondButton,
  disableFirstButton,
  disableSecondButton
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: '685px',
          borderRadius: '8px'
        }
      }}>
      <div style={{ background: '#fff', width: '685px' }}>
        <TitleContainer>
          <Title>{title}</Title>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </TitleContainer>

        <Divider />

        <ContentContainer>{children}</ContentContainer>

        <CTAContainer>
          <CTAButtonsContainer>
            {firstButtonText && (
              <SharedButton
                type={'thin_outlined'}
                font_size={'16px'}
                padding={'6px 32px'}
                width={'50%'}
                onClick={onClickFirstButton}
                disable={disableFirstButton}>
                {loadingFirstButton ? (
                  <Spinner size="XS" noPadding={true} />
                ) : (
                  firstButtonText
                )}
              </SharedButton>
            )}

            {secondButtonText && (
              <SharedButton
                type={'basic'}
                font_size={'16px'}
                padding={'8px 32px'}
                width={'50%'}
                onClick={onClickSecondButton}
                disable={disableSecondButton}>
                {loadingSecondButton ? (
                  <Spinner size="XS" color={'white'} noPadding={true} />
                ) : (
                  secondButtonText
                )}
              </SharedButton>
            )}
          </CTAButtonsContainer>
        </CTAContainer>
      </div>
    </Dialog>
  );
};

export default TokensPopup;
