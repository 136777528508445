import { faInfinity } from '@fortawesome/free-solid-svg-icons';
import { faHandPointer } from '@fortawesome/free-regular-svg-icons';
import { Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateAvailableResourcesCardIndex,
  updateExpiryDatesPopupStatus
} from '../../../../../redux/actions/job_action';
import {
  Text,
  Spacer,
  CardStyled,
  CountWrapper,
  TextContainer,
  FontAwesomeIconStyled,
  PerpJobSlotsCard,
  AnnouncementCard,
  AnnouncementText,
  LearnMoreText
} from './styles';
import store from '../../../../../redux/stores/store';
import moment from 'moment';
import { Spinner } from '../../../../../components';
import { updateDiscountedCreditsPopupStatus } from '../../../../../redux/actions/product_action';

const StatCard = ({
  usableCount,
  totalCount,
  disableCardStats,
  cardTitle,
  cardDesc,
  type,
  index,
  hasActiveJobSlot,
  hasBuyTokenAccess
}) => {
  const dispatch = useDispatch();

  const subscribedPrepJobSlotsPackage =
    store.getState().jobs.subscribedPrepJobSlotsPackage;

  const fetchingPackageSubscriptionDetails = useSelector(
    state => state.jobs.fetchingPackageSubscriptionDetails
  );

  const [prepJobSlotCounts, setPrepJobSlotCounts] = useState('');

  const groupingPrepJobSlots = () => {
    let currentCount = 0;
    let todayDate = moment();

    subscribedPrepJobSlotsPackage?.details?.map(item => {
      if (
        todayDate.diff(moment(item?.created_at), 'days') <= 7 &&
        todayDate.diff(moment(item?.activation_date), 'days') <= 7
      ) {
        currentCount += item?.quantity;
      }
    });
    setPrepJobSlotCounts(currentCount);
  };

  // Not using at the moment as jobTypeIndex is now a string
  // const getProductTypeIndex = () => {
  //   const cCardTitle = cardTitle.toLowerCase();
  //   switch (cCardTitle) {
  //     case 'job slots': {
  //       return 1;
  //     }
  //     case 'job tokens': {
  //       return 4;
  //     }
  //     case 'featured tokens': {
  //       return 2;
  //     }

  //     case 'refresher tokens': {
  //       return 3;
  //     }

  //     case 'free senior posts': {
  //       return 6;
  //     }
  //     case 'free internship tokens': {
  //       return 5;
  //     }

  //     default: {
  //       return 1;
  //     }
  //   }
  // };

  const getProductType = () => {
    // const cCardTitle = cardTitle.toLowerCase();

    if (cardTitle === 'Job Slots') {
      return 'Job Slots Card';
    } else {
      return cardTitle;
    }
  };

  const handleCardClick = index => {
    if (disableCardStats == 'true') {
      return;
    }

    store.getState().jobs.productTypeIndex = getProductType();

    dispatch(updateExpiryDatesPopupStatus(true));
    // dispatch(updateAvailableResourcesCardIndex(index));
  };

  const getCopy = () => {
    return `${prepJobSlotCounts} Job Slots are rescheduled`;
  };

  const showAnnouncementCard = () => {
    // if (type == 'first_row' && hasActiveJobSlot && hasBuyTokenAccess) {
    //   return true;
    // }

    return false;
  };

  const onLearnMoreClicked = () => {
    dispatch(updateDiscountedCreditsPopupStatus(true));
  };

  useEffect(() => {
    if (subscribedPrepJobSlotsPackage && cardTitle == 'Job Slots') {
      groupingPrepJobSlots();
    }
  }, [subscribedPrepJobSlotsPackage]);

  return (
    <>
      <CardStyled
        type={type}
        disabled={disableCardStats == 'true' ? true : false}
        onClick={() => handleCardClick(index, type)}>
        {prepJobSlotCounts > 0 ? (
          <PerpJobSlotsCard className="jobSlotCard">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip
                placement="bottom"
                title={<Text tooltip="true">{getCopy()}</Text>}>
                <Text prep_job_slots={'true'}>{getCopy()}</Text>
              </Tooltip>
            </div>
          </PerpJobSlotsCard>
        ) : null}
        <CountWrapper type={type} className="jobCountCard">
          {fetchingPackageSubscriptionDetails ? (
            <Spinner size="TINY" noPadding={true} />
          ) : usableCount == 'Unlimited' ? (
            <FontAwesomeIconStyled icon={faInfinity} />
          ) : cardTitle == 'Job Slots' ? (
            <Text status_count={'true'}>
              {usableCount}/{totalCount}
            </Text>
          ) : (
            <Text status_count={'true'}>{usableCount}</Text>
          )}
        </CountWrapper>
        <Spacer width={'15px'} />
        <div>
          <Text card_title="true">{cardTitle}</Text>

          <TextContainer>
            <Text
              card_desc="true"
              important={`${cardDesc?.includes('within 7 days')}`}>
              {' '}
              {usableCount == 0
                ? `No ${
                    cardTitle == 'Job Slots'
                      ? cardTitle
                      : cardTitle == 'Job Credits'
                      ? 'Credits'
                      : 'Tokens'
                  } left`
                : cardDesc}{' '}
            </Text>
          </TextContainer>
        </div>
      </CardStyled>
      {showAnnouncementCard() && (
        <AnnouncementCard onClick={onLearnMoreClicked}>
          <AnnouncementText>
            🎉 Need to post more jobs? We have discounted Job Credits for you!
            <LearnMoreText>Click to learn more.</LearnMoreText>
            {/* <FontAwesomeIconStyled
              icon={faHandPointer}
              name="hand_pointer"
              className="hand_pointer"
            /> */}
          </AnnouncementText>
        </AnnouncementCard>
      )}
    </>
  );
};

export default StatCard;
