import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DefaultLayout from '../../layouts/DefaultLayout';
import { ErrorToast } from '../../utils/ToastUtils';
import store from '../../redux/stores/store';
import Header from '../ApplicantSnapshotSummary/Header';
import Resume from '../ApplicantSnapshotSummary/Resume';
import Biodata from '../ApplicantSnapshotSummary/Biodata';
import VCL from '../ApplicantSnapshotSummary/VCL';
import {
  getPublicJobApplicationWithoutRedux,
  viewApplication
} from '../../redux/actions/application_action';
import {
  PageContainer,
  ContentContainer,
  ResumeContainer,
  CandidateContainer
} from './styles';
import Lottie from 'react-lottie';
import hiredlySpinner from '../../assets/lottie/hiredly_spinner.json';
import { countryOptions } from '../../components/SharedSelectCountry/constants';
// import { FETCH_PUBLIC_JOB_APPLICATION_SUCCEED } from '../../redux/types/application_type';

function ApplicantSnapshotSummary(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: hiredlySpinner,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const location = useLocation();
  const [application, setApplication] = useState(null);
  const [showCV, setShowCV] = useState(false);
  const dispatch = useDispatch();

  const checkIfArrayContains = (arr, value) => {
    return arr.includes(value);
  };

  const fetchData = async () => {
    try {
      const geolocationApi = await fetch(
        'https://geolocation-api-test.hiredly-wobb-cloudflare-account.workers.dev/'
      );

      const dataCountry = await geolocationApi.json();
      let cCountry = checkIfArrayContains(countryOptions, dataCountry?.country)
        ? dataCountry?.country
        : 'MY';

      localStorage.setItem('country', cCountry);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem('country')) {
      fetchData();
    }

    const fetchApplicationData = async () => {
      if (
        location.search.includes('applicationId=') &&
        location.search.includes('companyId=')
      ) {
        try {
          const query = new URLSearchParams(location.search);
          const params = {
            id: query.get('applicationId'),
            company_id: query.get('companyId')
          };

          const applicationRes = await getPublicJobApplicationWithoutRedux(
            params
          );
          if (applicationRes?.application) {
            // Update local application
            setApplication(applicationRes.application);

            if (query.get('show') === 'true') {
              window.location.replace(applicationRes.application?.user?.resume);
            } else {
              setShowCV(true);
            }
          }

          const paramId = { applicationId: query.get('applicationId') };
          dispatch(viewApplication(paramId));
        } catch (error) {
          console.error('Error fetching application data:', error);
        }
      } else {
        // Show invalid application message or handle it accordingly
        console.error('Invalid application parameters.');
      }
    };

    fetchApplicationData();
  }, []);

  return (
    <DefaultLayout>
      {application && showCV ? (
        <PageContainer>
          <Header application={application} />
          <ContentContainer>
            <ResumeContainer>
              <Resume application={application} />
            </ResumeContainer>
            <CandidateContainer vcl={application?.user?.videoCvOptimized}>
              <Biodata application={application} />
              {application?.user?.videoCvOptimized && (
                <VCL application={application} />
              )}
            </CandidateContainer>
          </ContentContainer>
        </PageContainer>
      ) : (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Lottie options={defaultOptions} height={75} width={75} />
        </div>
      )}
    </DefaultLayout>
  );
}

export default ApplicantSnapshotSummary;
