import { Grid, IconButton as MuiIconButton } from '@mui/material';
import React, { useRef } from 'react';
import { ArrowPathRoundedSquareIconStyled, XMarkIconStyled } from './styles';
import { ReactComponent as Rearrange } from '../../../../../assets/icons/company-canvas/rearrange-icon.svg';
import { useDrag, useDrop } from 'react-dnd';
import { isEmpty } from 'lodash';
import { PlusCircleIconStyled } from '../../../CompanyBenefits/styles';
import IconButton from '../../../components/IconButton';

export const ItemTypes = {
  VIDEO: 'video',
  IMAGE: 'image',
  TEXT: 'text'
};

const ImageCard = props => {
  const { index, moveImage, onChangeAdd, onChangeRemove, onChangeImage } =
    props;
  const { publicUrl, add, id } = props?.image;

  const ref = useRef(null);

  const [{ isDropping }, drop] = useDrop({
    accept: ItemTypes.VIDEO,
    canDrop: () => publicUrl,
    hover(item, monitor) {
      if (isEmpty(publicUrl)) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      moveImage(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.VIDEO,
    item: { type: ItemTypes.VIDEO, index, publicUrl, add },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: () => publicUrl
  });

  drag(drop(ref));

  return (
    <div ref={ref}>
      {add || isEmpty(publicUrl) ? (
        <EmptyContent
          add={add}
          publicUrl={publicUrl}
          onChangeAdd={onChangeAdd}
        />
      ) : (
        <Content
          publicUrl={publicUrl}
          isDragging={isDragging}
          onChangeRemove={onChangeRemove}
          onChangeImage={onChangeImage}
          id={id}
        />
      )}
    </div>
  );
};

const Content = props => {
  const { publicUrl, isDragging, onChangeRemove, onChangeImage, id } = props;
  return (
    <Grid
      //   onClick={() => onChangeRemove(id)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '8px',
        border: isDragging ? '1px dashed #512ACC' : '1px solid #D8D8D8',
        overflow: 'hidden',
        backgroundColor: isDragging ? '#EFEFFD' : '',
        height: '165px',
        width: '216px'
      }}>
      {!isDragging && (
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: '#F5F5F5',
            height: '36px',
            alignItems: 'center'
          }}>
          <MuiIconButton disabled>
            <Rearrange />
          </MuiIconButton>
          <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
            <IconButton
              tooltipTitle="Reupload image"
              transparent
              sx={{ borderRadius: '0px' }}
              onClick={() => onChangeImage(id)}>
              <ArrowPathRoundedSquareIconStyled />
            </IconButton>

            <IconButton
              tooltipTitle="Remove image"
              transparent
              sx={{ borderRadius: '0px' }}
              onClick={() => onChangeRemove(id, publicUrl)}>
              <XMarkIconStyled />
            </IconButton>
          </Grid>
        </Grid>
      )}

      {!isDragging && (
        <img
          src={publicUrl}
          width={216}
          height={130}
          style={{ borderRadius: '0px 0px 6px 6px', objectFit: 'cover' }}
        />
      )}
    </Grid>
  );
};

const EmptyContent = props => {
  const { add, onChangeAdd } = props;
  const fileInputRef = useRef(null);

  return add ? (
    <>
      <input
        type="file"
        accept="image/*"
        onChange={onChangeAdd}
        style={{
          display: 'none'
        }}
        ref={fileInputRef}
      />
      <Grid
        onClick={() => fileInputRef.current.click()}
        sx={{
          width: '216px',
          height: '165px',
          border: '1px dashed #707070',
          borderRadius: '8px',
          backgroundColor: '#EFEFEF',
          display: 'flex',
          gap: '8px',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          cursor: 'pointer',
          transition: '0.3s all',

          ':hover': {
            backgroundColor: '#E5E5E5'
          }
        }}>
        <PlusCircleIconStyled />
        Add a Photo
      </Grid>
    </>
  ) : (
    <Grid
      sx={{
        width: '216px',
        height: '165px',
        border: '1px dashed #707070',
        borderRadius: '8px',
        backgroundColor: 'white'
      }}></Grid>
  );
};

export default ImageCard;
