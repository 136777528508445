import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { FormSelectInterview } from '../../../../components';
import {
  ErrorToast,
  SuccessToast,
  WarningToast
} from '../../../../utils/ToastUtils';
import SkeletonLoader from './SkeletonLoader';
import {
  SwitchStyled,
  BottomBox,
  BottomActionBox,
  SkipButton,
  SaveButton,
  MainContainer,
  MainContainerBox,
  ExpandableBar,
  ExpandableBarSwitch,
  ExpandableBarTitle,
  Title,
  SubTitle,
  QuestionBox,
  ChevronDownIconStyled,
  ExpandedContent,
  DialogContentStyled,
  DialogTitleStyled,
  IconButtonStyled
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanyTeamMembers } from '../../../../redux/actions/company_actions';
import {
  fetchJobPersonalizedQuestions,
  fetchPersonalizedQuestions,
  mutateVirtualInterviewQuestions
} from '../../../../redux/actions/job_action';
import { Dialog, Divider } from '@mui/material';
import { XMarkIcon } from '@heroicons/react/24/solid';

const VirtualInterviewPopUpNew = ({
  open,
  handleClose,
  useAsPopUp,
  jobId,
  jobAccess
}) => {
  const history = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [jobQuestions, setJobQuestions] = useState([]);

  const [showCustomQuestion, setShowCustomQuestion] = useState(
    jobQuestions?.at(2)?.personalizedQuestionId == '14'
  );
  const [isExpanded, setIsExpanded] = useState(
    useAsPopUp || jobQuestions?.at(0)?.job?.enableChatbot
  );

  useEffect(() => {
    if (!open) return;

    dispatch(fetchCompanyTeamMembers());
    dispatch(fetchPersonalizedQuestions());
    dispatch(
      fetchJobPersonalizedQuestions({
        jobId: jobId
      })
    ).then(res => {
      setJobQuestions(res.jobPersonalizedQuestions);
    });
  }, [open]);

  useEffect(() => {
    if (jobId !== '') {
      dispatch(
        fetchJobPersonalizedQuestions({
          jobId: params?.id || jobId
        })
      ).then(res => {
        setJobQuestions(res.jobPersonalizedQuestions);
      });
    }
  }, [jobId, dispatch, params?.id]);

  const companyTeamMembers = useSelector(
    state => state.companies.companyTeamMembers
  );
  const personalizedQuestions = useSelector(
    state => state.jobs.personalizedQuestions
  );

  const fetchingJobPersonalizedQuestions = useSelector(
    state => state.jobs.fetchingJobPersonalizedQuestions
  );

  const mutatingJobPersonalizedQuestions = useSelector(
    state => state.jobs.mutatingJobPersonalizedQuestions
  );

  const isMasterAccount =
    localStorage.getItem('currentAccountType') === 'master';
  
  const accountInfo = JSON.parse(localStorage.getItem('accountInfo'));

  const [fields, setFields] = useState({
    active: jobQuestions?.at(0)?.job?.enableChatbot || false,
    question1: jobQuestions?.at(0)?.personalizedQuestionId || '',
    question2: jobQuestions?.at(1)?.personalizedQuestionId || '',
    question3: jobQuestions?.at(2)?.personalizedQuestionId || '',
    question3Custom: jobQuestions?.at(2)?.customizedQuestion || ''
  });

  useEffect(() => {
    setFields({
      active: jobQuestions?.at(0)?.job?.enableChatbot || false,
      question1: jobQuestions?.at(0)?.personalizedQuestionId || '',
      question2: jobQuestions?.at(1)?.personalizedQuestionId || '',
      question3: jobQuestions?.at(2)?.personalizedQuestionId || '',
      question3Custom: jobQuestions?.at(2)?.customizedQuestion || ''
    });

    if (jobQuestions?.length > 0) {
      if (useAsPopUp) {
        setIsExpanded(true);
      } else {
        setIsExpanded(jobQuestions[0]?.job?.enableChatbot);
      }
      setShowCustomQuestion(jobQuestions[2]?.personalizedQuestionId == '14');
    }
  }, [jobQuestions]);

  useEffect(() => {
    if (fields?.active) {
      setIsExpanded(true);
    }
  }, [fields?.active]);

  const [errors, setErrors] = useState({
    question1: false,
    question2: false,
    question3: false,
    question3Custom: false
  });

  const validationSchema = (values, hasCustom) => {
    const newErrors = {};
    let isValid = true;

    if (values?.active) {
      if (!values.question1) {
        newErrors.question1 = true;
        isValid = false;
      }
      if (!values.question2) {
        newErrors.question2 = true;
        isValid = false;
      }
      if (!values.question3) {
        newErrors.question3 = true;
        isValid = false;
      }
      if (hasCustom && values?.question3Custom == '') {
        newErrors.question3Custom = true;
        isValid = false;
      }
    }

    setErrors(newErrors);

    return isValid;
  };

  const enableScroll = () => {
    window.onscroll = function () {};
  };

  const disableScroll = () => {
    // Get the current page scroll position
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    // if any scroll is attempted, set this to the previous value
    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop);
    };
  };

  const onClose = () => {
    if (Boolean(open)) {
      disableScroll();
      setTimeout(() => {
        enableScroll();
      }, 500);
    }

    setFields({});
    setErrors({});
    handleClose();
  };

  const onSubmitQuestions = async values => {
    const jobQuesLength = jobQuestions?.length;
    const hasCustom = Number(values?.question3) === 14;

    const isValid = validationSchema(values, hasCustom);

    if (!isValid) {
      return;
    }

    if (
      values.active &&
      (values.question1 === values.question2 ||
        values.question1 === values.question3 ||
        values.question2 == values.question3)
    ) {
      WarningToast('Please select different options for each question.');
      return;
    }

    dispatch(
      mutateVirtualInterviewQuestions({
        input: {
          action: jobQuesLength === 0 ? 'create' : 'update',
          jobId: jobId,
          enableChatbot: values.active,
          question1: Number(values.question1),
          question2: Number(values.question2),
          question3: Number(values.question3),
          question3Custom: hasCustom ? values.question3Custom : ''
        }
      })
    )
      .then(res => {
        if (res.success) {
          SuccessToast('Virtual Interview Questions Saved Successfully!');

          dispatch(
            fetchJobPersonalizedQuestions({
              jobId: params?.id || jobId
            })
          ).then(res => {
            setJobQuestions(res.jobPersonalizedQuestions);
          });

          if (!useAsPopUp) {
            if (jobAccess) {
              history(`/jobs/${jobId}/job-access-new`);
            } else {
              // will redirect to feedback page on first job / every 3 months after a job posted
              if (accountInfo?.feedbackRequired) {
                history(`/feedback`);
              } else {
                history(`/jobs`);
              }
            }
          } else {
            onClose();
          }
        } else {
          ErrorToast('Virtual Interview Questions Failed to Save');
        }
      })
      .catch(err => {
        ErrorToast(errorMessage);
      });
  };

  const handleClosePopup = () => {
    if (useAsPopUp) {
      onClose();
    } else {
      if (isMasterAccount) {
        if (companyTeamMembers?.length > 0) {
          history(`/jobs/${jobId}/job-access-new`);
        } else {
          // will redirect to feedback page on first job / every 3 months after a job posted
          if (accountInfo?.feedbackRequired) {
            history(`/feedback`);
          } else {
            history(`/jobs`);
          }
        }
      } else {
        history('/jobs');
      }
    }
  };

  const renderContent = () => (
    <MainContainer isPopUp={useAsPopUp}>
      <MainContainerBox isPopUp={useAsPopUp}>
        {/* top title bar */}
        <ExpandableBar isExpanded={isExpanded} isPopUp={useAsPopUp}>
          <ExpandableBarTitle>
            <Title>Enable Virtual Interview for this job?</Title>
            <SubTitle>
              Select 3 pre-screening questions to automatically send to all
              applicants for this job.
            </SubTitle>
          </ExpandableBarTitle>

          <ExpandableBarSwitch>
            <SwitchStyled
              name="active"
              checked={fields.active}
              onChange={() =>
                setFields(prevFields => ({
                  ...prevFields,
                  active: !prevFields.active
                }))
              }
              $width={46}
              $height={24}
              $thumbWidth={18}
              $thumbHeight={18}
            />

            {!useAsPopUp && (
              <ChevronDownIconStyled
                onClick={() => setIsExpanded(prevFields => !prevFields)}
                isExpanded={isExpanded}
              />
            )}
          </ExpandableBarSwitch>
        </ExpandableBar>

        {/* Middle message */}
        <ExpandedContent isExpanded={isExpanded}>
          <QuestionBox isPopUp={useAsPopUp}>
            <FormSelectInterview
              questionNumber={1}
              name="question1"
              options={personalizedQuestions.slice(0, -1)}
              fields={fields}
              setFields={setFields}
              disabledText={fields.active}
              required={fields.active}
              errors={errors}
              setErrors={setErrors}
            />

            <FormSelectInterview
              questionNumber={2}
              name="question2"
              options={personalizedQuestions.slice(0, -1)}
              fields={fields}
              setFields={setFields}
              disabledText={fields.active}
              required={fields.active}
              errors={errors}
              setErrors={setErrors}
            />

            <FormSelectInterview
              questionNumber={3}
              name="question3"
              options={personalizedQuestions}
              fields={fields}
              setFields={setFields}
              showCustomQuestion={showCustomQuestion}
              setShowCustomQuestion={setShowCustomQuestion}
              disabledText={fields.active}
              required={fields.active}
              errors={errors}
              setErrors={setErrors}
            />
          </QuestionBox>
        </ExpandedContent>
      </MainContainerBox>

      <BottomBox isPopUp={useAsPopUp}>
        <BottomActionBox isPopUp={useAsPopUp}>
          <SkipButton isPopUp={useAsPopUp} onClick={() => handleClosePopup()}>
            {useAsPopUp ? <>Cancel</> : <>Skip</>}
          </SkipButton>
          <SaveButton
            isPopUp={useAsPopUp}
            disabled={
              fields.question1 === fields.question2 ||
              fields.question1 === fields.question3 ||
              fields.question2 == fields.question3
            }
            onClick={() => onSubmitQuestions(fields)}>
            {useAsPopUp ? (
              <>Save</>
            ) : jobAccess ? (
              <>Save & Continue</>
            ) : (
              <>Save & Done</>
            )}
          </SaveButton>
        </BottomActionBox>
      </BottomBox>
    </MainContainer>
  );

  if (useAsPopUp) {
    return (
      <Dialog
        open={open}
        handleClose={() => handleClosePopup()}
        sx={{ maxWidth: '685px', margin: 'auto' }}
        PaperProps={{
          style: {
            boxShadow: 'none',
            borderRadius: 8,
            width: '685px',
            background: '#fff',
            maxWidth: '685px',
            margin: '0'
          }
        }}>
        <DialogTitleStyled>
          Edit Virtual Interview
          <IconButtonStyled onClick={() => handleClosePopup()}>
            <XMarkIcon height={24} width={24} />
          </IconButtonStyled>
        </DialogTitleStyled>
        <Divider />

        <DialogContentStyled>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            {/* <DialogNoteStyled>
              Which team member has access to manage this job?
            </DialogNoteStyled> */}
            {fetchingJobPersonalizedQuestions ? (
              <SkeletonLoader />
            ) : (
              renderContent()
            )}
          </div>
        </DialogContentStyled>
      </Dialog>
    );
  }

  return <div style={{ width: '100%' }}>{renderContent()}</div>;
};

export default VirtualInterviewPopUpNew;
