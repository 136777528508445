import React, { useEffect, useState } from 'react';
import { PopUpDialog, Spinner } from '../../../../components';
import { TextArea } from '../../../ManageCandidates/Modals/ScheduleInterview/TextArea';
import {
  FirstContainer,
  SecondContainer,
  ButtonWrapper,
  ButtonContainer,
  Text,
  TextContainer,
  TextFieldStyled,
  ButtonStyled
} from './styles';
import {
  CONTACT_CONSULTANTS_SUBJECT,
  JOB_APPLICATION_STATUS
} from '../../../../utils/Constants';
import {
  updateAllApplications,
  updateInterviewStatus
} from '../../../../../src/redux/actions/application_action';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorToast, InfoToast } from '../../../../utils/ToastUtils';
import store from '../../../../redux/stores/store';
import { FlareSharp } from '@mui/icons-material';
import { isTypeNode } from 'graphql';

const RejectPopUp = ({
  open,
  handleClose,
  changeApplicationState,
  onChangeJobApplicationStatus,
  interviewItem,
  setApplicationState,
  isAts,
  setJobData,
  candidateJobApplication,
  changeLoading,
  setCandidates,
  setCurrentCandidates,
  getCurrentCandidates,
  item,
  updateApplicationState
}) => {
  const [rejectReason, setRejectReason] = useState('');

  const dispatch = useDispatch();
  const allApplications = useSelector(
    state => state.applications.allApplications
  );
  const currentCandidateIndex = useSelector(
    state => state.applications.currentCandidateIndex
  );

  const updatingInterviewStatus = useSelector(
    state => state.applications.updatingInterviewStatus
  );

  const rejectedStatusMessage = () => {
    return (
      <div>
        <p
          style={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 'bold',
            margin: '4px 0'
          }}>
          User Rejected
        </p>
        <p style={{ margin: '0', fontFamily: 'Inter', fontSize: '12px' }}>
          You have successfully rejected this user.
        </p>
      </div>
    );
  };

  const getCandidate = () => {
    if (allApplications[currentCandidateIndex]) {
      return allApplications[currentCandidateIndex];
    }

    return null;
  };

  const handleRejection = rejectReason => async () => {
    const currentCandidate = getCandidate() || item;

    if (!currentCandidate) return;

    const applicationRejection = currentCandidate.jobApplication ? false : true;

    if (!isAts) {
      if (applicationRejection) {
        if (updateApplicationState) {
          await updateApplicationState(
            currentCandidate,
            JOB_APPLICATION_STATUS.REJECTED,
            rejectReason
          );
        } else {
          await onChangeJobApplicationStatus({
            value: JOB_APPLICATION_STATUS.REJECTED,
            rejectReason: rejectReason
          });
        }
      } else {
        // Interview rejection
        const updateInterviewParams = {
          id: interviewItem?.id,
          status: JOB_APPLICATION_STATUS.REJECTED,
          remark: rejectReason,
          isInterview: true
        };

        const response = await dispatch(
          updateInterviewStatus(updateInterviewParams)
        );

        if (response.type == 'UPDATE_INTERVIEW_STATUS_SUCCEED') {
          if (response?.interview?.jobApplication?.job) {
            if (setJobData) {
              setJobData(response.interview.jobApplication.job);
            }
          }

          if (getCurrentCandidates) {
            const currentCandidates = getCurrentCandidates();

            const currentIndex = currentCandidates.findIndex(
              item => item.node?.id == response?.interview?.id
            );

            if (currentIndex != -1) {
              let currentInterview = { ...response.interview };

              // Update interview's job application state to rejected locally
              if (currentInterview.jobApplication) {
                currentInterview.jobApplication.state = 'rejected';
              }

              currentCandidates[currentIndex] = {
                ...currentCandidates[currentIndex],
                node: {
                  ...currentInterview
                }
              };

              setCandidates(currentCandidates);
              setCurrentCandidates(currentCandidates);
            }
          }

          setApplicationState(JOB_APPLICATION_STATUS.REJECTED);

          InfoToast(rejectedStatusMessage());
        } else {
          ErrorToast('Failed to update reject status');
        }
      }
    } else {
      // ATS
      if (applicationRejection) {
        await changeApplicationState(
          currentCandidate?.state,
          JOB_APPLICATION_STATUS.REJECTED,
          -1,
          0,
          null,
          true,
          currentCandidate?.id,
          rejectReason,
          false
        );
      } else {
        const updateInterviewParams = {
          id: currentCandidate?.id,
          status: JOB_APPLICATION_STATUS.REJECTED,
          remark: rejectReason,
          isInterview: true
        };

        const response = await dispatch(
          updateInterviewStatus(updateInterviewParams)
        );
        if (response.type == 'UPDATE_INTERVIEW_STATUS_SUCCEED') {
          let snapshotApplications = [
            ...store.getState().applications.allApplications
          ];

          const index = snapshotApplications.findIndex(item => {
            return item?.id == getCandidate().id;
          });

          snapshotApplications[index] = {
            ...response.interview
          };

          dispatch(updateAllApplications(snapshotApplications));

          InfoToast(rejectedStatusMessage());
        } else {
          ErrorToast('Failed to update reject status');
        }
      }
    }
    setRejectReason('');
    handleClose();
  };

  const onRejectPopupClose = () => {
    setRejectReason('');
    handleClose();
  };

  return (
    <PopUpDialog
      open={open}
      handleClose={onRejectPopupClose}
      title={'Reject This Applicant?'}>
      <FirstContainer>
        <TextContainer type="message">
          <Text type="basic">
            This action cannot be undone, and you can’t make changes to this
            user anymore.
          </Text>
        </TextContainer>
        <TextContainer type="message">
          <Text type="basic">
            Please state a reason to reject this user (Optional)
          </Text>
        </TextContainer>
        <div style={{ marginTop: '20px' }}>
          <TextArea
            value={rejectReason}
            handleChange={e => {
              setRejectReason(e.target.value);
            }}
            placeholder={`eg: The candidate did not suit the role.`}
          />
        </div>
      </FirstContainer>
      <SecondContainer>
        <ButtonWrapper>
          <ButtonContainer>
            <ButtonStyled type="cancel" onClick={onRejectPopupClose}>
              Cancel
            </ButtonStyled>
          </ButtonContainer>
          <ButtonContainer>
            <ButtonStyled
              type="confirm"
              onClick={handleRejection(rejectReason)}>
              {updatingInterviewStatus || changeLoading ? (
                <Spinner size="XS" color="white" noPadding={true} />
              ) : (
                'Reject'
              )}
            </ButtonStyled>
          </ButtonContainer>
        </ButtonWrapper>
      </SecondContainer>
    </PopUpDialog>
  );
};

export default RejectPopUp;
