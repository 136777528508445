import React, { useEffect } from 'react';
import ContentCard from '../components/ContentCard';
import {
  CancelButton,
  ExclamationTriangleIconStyled,
  OuterWrapper,
  SequenceContainer,
  SocialMediaWrapper
} from './styles';
import { useState } from 'react';
import { SocialMediaCard } from './components/SocialMediaCard';
import ManagePopUp from './components/ManagePopUp';
import InputUrl from './components/InputUrl';
import { useDispatch, useSelector } from 'react-redux';
import {
  mutateCompanyProfile,
  updateCompanySidebar
} from '../../../redux/actions/company_actions';
import { ErrorToast, SuccessToast } from '../../../utils/ToastUtils';
import firstConnector from '../../../assets/images/products/first_connector.svg';
import SecondConnector from '../../../assets/images/products/second_connector.svg';
import ThirdConnector from '../../../assets/images/products/third_connector.svg';
import FourthConnector from '../../../assets/images/products/fourth_connector.svg';
import SaveButton from '../components/SaveButton';
import { Grid, Typography } from '@mui/material';
import { isEmpty, isEqual, update } from 'lodash';
import { convertStatusText } from '../helper/convertStatusText';

// live
// reject
// in review
// unreviwed

//https://www.tiktok.com/@badqlt/video/7297973686009597216

// live
// reject
// in review

// unreviwed

export default function EditState(props) {
  const { setView, setInfoOpen } = props;
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [videos, setVideos] = useState([]);
  const [videosOri, setVideosOri] = useState([]);
  const [selected, setSelected] = useState();
  const companyCompletion = useSelector(
    state => state.companies.companyCompletion
  );
  const companyProfile = useSelector(state => state.companies.companyProfile);

  useEffect(() => {
    const socialMedias = Array.from({ length: 4 }, (_, index) => {
      const socialMedia = companyProfile?.socialMediaLinks?.[index];
      return {
        ...(socialMedia || {}),

        id: socialMedia?.id ? socialMedia.id : `${index}${Date.now()}`,
        contentIndex: socialMedia?.content?.length > 1 ? 1 : 0,
        sortOrder: index + 1,
        add: false,
        loading: false,
        content:
          socialMedia?.content?.map(item => ({
            ...item,
            oriId: item?.id,
            status: convertStatusText(item.approvalStatus),
            url: item.socialMediaLink,
            inputUrl: item.socialMediaLink
          })) || []
      };
    });

    const socialMediasOriginal = Array.from({ length: 4 }, (_, index) => {
      const socialMedia = companyProfile?.socialMediaLinks?.[index];
      return {
        ...(socialMedia || {}),
        id: socialMedia?.id ? socialMedia.id : `${index}${Date.now()}`,
        contentIndex: 0,
        sortOrder: index + 1,
        add: false,
        loading: false,
        content:
          socialMedia?.content?.map(item => ({
            ...item,
            status: convertStatusText(item.approvalStatus),
            url: item.socialMediaLink,
            oriId: item?.id,
            inputUrl: item.socialMediaLink
          })) || []
      };
    });

    // setVideos(socialMedias);
    setVideos(() => {
      const updatedVideos = socialMedias.map(video => ({
        ...video,
        add: false
      }));

      const videosWithContentLength = updatedVideos.filter(
        video => video.content.length > 0
      ).length;

      if (videosWithContentLength < 4) {
        const addStateVideos = [...updatedVideos];
        addStateVideos[videosWithContentLength] = {
          ...addStateVideos[videosWithContentLength],
          add: true
        };

        setSelected(addStateVideos[0]);
        return addStateVideos;
      } else {
        setSelected(updatedVideos[0]);
        return updatedVideos;
      }
    });
    setVideosOri(socialMediasOriginal);
  }, []);

  const [manageOpen, setManageOpen] = useState(false);
  const [manageLink, setManageLink] = useState();
  const [manageIndex, setManageIndex] = useState();
  const [manageType, setManageType] = useState();

  const videoId = selected?.id;
  const video = selected?.content;
  const selectedContent = selected?.content[selected?.contentIndex];
  const contentUrl = selectedContent?.inputUrl;
  const contentStatus = selectedContent?.status;

  const videoIndex = selected?.sortOrder - 1;
  const contentIndex = selected?.contentIndex;

  useEffect(() => {
    const socialMediasOriginal = Array.from({ length: 4 }, (_, index) => {
      const socialMedia = companyProfile?.socialMediaLinks?.[index];
      return {
        ...(socialMedia || {}),
        id: socialMedia?.id ? socialMedia.id : `${index}${Date.now()}`,
        contentIndex: 0,
        sortOrder: index + 1,
        add: false,
        loading: false,
        content:
          socialMedia?.content?.map(item => ({
            ...item,
            status: convertStatusText(item.approvalStatus),
            url: item.socialMediaLink,
            oriId: item?.id,
            inputUrl: item.socialMediaLink
          })) || []
      };
    });

    //setSelected(videos[videoIndex]);
    setVideosOri(socialMediasOriginal);
    hasSequenceChanges();
  }, [companyProfile?.socialMediaLinks]);

  const hasSequenceChanges = () => {
    const editChanges = videos
      ?.filter(item => {
        return item?.id?.length < 5;
      })
      ?.map((video, index) => {
        const original = videosOri[index];
        return isEqual(video?.id, original?.id);
      });

    return editChanges?.includes(false);
  };

  const hasUrlChanges = () => {
    const hasChanges = videos?.some(video => {
      let content;
      if (video?.content?.length > 1) {
        content = video?.content?.[1];
      } else {
        content = video?.content?.[0];
      }

      return content?.inputUrl !== content?.url;
    });

    return hasChanges;
  };

  const getIdWithIndex = index => {
    return videos[index]?.id;
  };

  const moveVideo = (dragIndex, hoverIndex) => {
    const newVideos = [...videos];
    const [draggedVideo] = newVideos.splice(dragIndex, 1);
    newVideos.splice(hoverIndex, 0, draggedVideo);

    // Reset sortOrder field for all videos
    const resetSortOrderVideos = newVideos.map((video, index) => ({
      ...video,
      sortOrder: index + 1
    }));

    setVideos(resetSortOrderVideos);
    setSelected(resetSortOrderVideos[hoverIndex]);
    hasSequenceChanges();
  };

  const handleContentIndexChange = (videoIndex, contentIndex) => {
    setVideos(prevVideos => {
      const updatedVideos = prevVideos.map((video, index) => {
        if (index === videoIndex) {
          return { ...video, contentIndex };
        }
        return video;
      });

      const selectedVideo = updatedVideos[videoIndex];
      setSelected(selectedVideo);
      return updatedVideos;
    });
  };

  const onChangeLoading = (id, state) => {
    return new Promise(resolve => {
      setVideos(prevVideos => {
        const updatedVideos = prevVideos.map(video => {
          if (video.id === id) {
            return {
              ...video,
              loading: state
            };
          }
          return video;
        });
        resolve(updatedVideos);
        return updatedVideos;
      });
    });
  };

  const onChangeStatus = (videoIndex, contentIndex, status) => {
    setVideos(prevVideos => {
      const updatedVideos = prevVideos.map(video => {
        if (video.id === videoIndex) {
          const updatedContent = [...video.content];

          updatedContent[contentIndex] = {
            ...updatedContent[contentIndex],
            status: status
          };
          return { ...video, content: updatedContent };
        }
        return video;
      });

      // const selectedVideo = updatedVideos.find(
      //   video => video.id === videoIndex
      // );
      //setSelected(selectedVideo);

      return updatedVideos;
    });
  };

  const onResetAdd = () => {
    setVideos(prevVideos => {
      const updatedVideos = prevVideos.map(video => ({
        ...video,
        add: false
      }));

      const videosWithContentLength = updatedVideos.filter(
        video => video.content.length > 0
      ).length;

      if (videosWithContentLength < 4) {
        const addStateVideos = [...updatedVideos];
        addStateVideos[videosWithContentLength] = {
          ...addStateVideos[videosWithContentLength],
          add: true
        };

        return addStateVideos;
      } else {
        return updatedVideos;
      }
    });
  };

  const onResetSortOrder = () => {
    setVideos(prev => {
      const sorted = prev?.map((video, index) => {
        return { ...video, sortOrder: index + 1 };
      });

      return sorted;
    });
  };

  const onChangeRemove = async (videoIndex, contentIndex) => {
    const removedContent = [
      { id: videos[videoIndex].content[contentIndex]?.oriId, _destroy: true }
    ];

    dispatch(
      mutateCompanyProfile({
        input: {
          socialMedia: removedContent
        }
      })
    ).then(res => {
      if (res.type === 'FETCH_COMPANY_PROFILE_SUCCEED') {
        // if deleted first item, index = 0
        // api return second item as [{deleted} , {existing} <- is 0 ]
        const updatedContent =
          res?.companyProfile?.socialMediaLinks?.[videoIndex];
        const OriginalContent = videosOri[videoIndex]?.content;

        if (!isEmpty(OriginalContent) && OriginalContent?.length > 1) {
          setVideos(prev => {
            const newArray = [...prev];
            newArray[videoIndex] = {
              ...updatedContent,
              contentIndex: 0,
              sortOrder: videoIndex + 1,
              add: false,
              loading: false,
              content:
                updatedContent?.content?.map(item => ({
                  ...item,
                  oriId: item?.id,
                  status: convertStatusText(item.approvalStatus),
                  url: item.socialMediaLink,
                  inputUrl: item.socialMediaLink
                })) || []
            };

            const sortedVideos = [...newArray];
            const videosWithContent = sortedVideos.filter(
              video => video.content.length > 0
            );
            const sortedVideosWithContent = videosWithContent.concat(
              sortedVideos.filter(video => video.content.length === 0)
            );
            const newList = sortedVideosWithContent.map((sorted, index) => ({
              ...sorted,
              add: false,
              sortOrder: index + 1
            }));

            // change the add status
            const videosWithContentLength = newList.filter(
              video => video.content.length > 0
            ).length;

            if (videosWithContentLength === 4) {
              setSelected(newList[videoIndex]);
              return newList;
            } else {
              const addStateVideos = [...newList];
              addStateVideos[videosWithContentLength] = {
                ...addStateVideos[videosWithContentLength],
                add: true
              };

              setSelected(addStateVideos[videoIndex]);
              return addStateVideos;
            }
          });
        } else {
          setVideos(prev => {
            const newArray = [...prev];
            newArray[videoIndex] = {
              ...updatedContent,
              id: `${videoIndex}${Date.now()}`,
              contentIndex: 0,
              sortOrder: videoIndex + 1,
              add: false,
              loading: false,
              content: []
            };

            // Sort the videos with content
            const sortedVideos = [...newArray];
            const videosWithContent = sortedVideos.filter(
              video => video.content.length > 0
            );
            const sortedVideosWithContent = videosWithContent.concat(
              sortedVideos.filter(video => video.content.length === 0)
            );
            const newList = sortedVideosWithContent.map((sorted, index) => ({
              ...sorted,
              add: false,
              sortOrder: index + 1
            }));

            // change the add status
            const videosWithContentLength = newList.filter(
              video => video.content.length > 0
            ).length;

            const addStateVideos = [...newList];
            addStateVideos[videosWithContentLength] = {
              ...addStateVideos[videosWithContentLength],
              add: true
            };

            if (videosWithContentLength === 0) {
              setSelected(addStateVideos[0]);
              dispatch(
                updateCompanySidebar({
                  ...companyCompletion,
                  socialMediaHighlights: false
                })
              );
            } else {
              setSelected(addStateVideos[videosWithContentLength - 1]);
            }

            return addStateVideos;
          });
        }

        setVideosOri(res?.companyProfile?.socialMediaLinks);
        SuccessToast('Social Media Links Deleted Successfully!');
      } else {
        ErrorToast('Social Media Links Deleted Failed!');
      }
    });
  };

  const handleLinkChange = (value, videoIndex, contentIndex, type) => {
    setVideos(prevVideos => {
      const updatedVideos = prevVideos.map(video => {
        const oriId = videosOri[videoIndex]?.content[contentIndex]?.id;

        if (video.id === getIdWithIndex(videoIndex || 0)) {
          const updatedContent = [...video.content];
          const contentItem = updatedContent[contentIndex];

          updatedContent[contentIndex] = {
            ...contentItem,
            inputUrl: value,
            oriId: oriId,
            id:
              type === 'EDIT REVIEW' ||
              type === 'EDIT REJECT' ||
              contentItem?.status === 'In Review'
                ? contentItem?.id
                : type === 'EDIT LIVE'
                ? `${Date.now()}-live`
                : `${Date.now()}-new`
          };

          return { ...video, content: updatedContent };
        } else {
          return video;
        }
      });

      return updatedVideos;
    });
  };

  const selectVideo = video => {
    setSelected(video);
  };

  const simulateProgress = ({ videos, index }) => {
    let currentProgress = 0;

    // Reset progress to 0 at the beginning
    const intervalId = setInterval(() => {
      currentProgress += 15;

      if (currentProgress >= 100) {
        clearInterval(intervalId);
        if (selected?.content[0]?.status !== 'Live on Hiredly') {
          onChangeStatus(getIdWithIndex(videoIndex), contentIndex, 'In Review');
        } else {
          //
        }

        onResetAdd();
        onResetSortOrder();
        onChangeLoading(selected?.id, false);
        dispatch(
          updateCompanySidebar({
            ...companyCompletion,
            socialMediaHighlights: true
          })
        );
      } else {
        setProgress(100);
      }
    }, 100);

    // if completely new then no id
    // if change in review then use existing id
    // if change live then use content[0] id

    const contentArray = videos[index]?.content?.map((content, count) => {
      let id;
      if (content.id.includes('new')) {
        id = false;
      } else if (content.id.includes('edit')) {
        id = content.id;
      } else if (content.id.includes('live')) {
        id = content.oriId;
      } else {
        id = content.id;
      }

      return {
        socialMediaLink: content.inputUrl,
        sortOrder: index + 1,
        id
      };
    });

    dispatch(
      mutateCompanyProfile({
        input: {
          socialMedia: contentArray
        }
      })
    )
      .then(
        async res => {
          // When updating, only update the target one
          const updatedContent = res?.companyProfile?.socialMediaLinks?.[index];

          if (res.type === 'FETCH_COMPANY_PROFILE_SUCCEED') {
            setProgress(100); // Set flag to true on API success
            setVideosOri(res?.companyProfile?.socialMediaLinks);

            setVideos((prev, count) => {
              const newArray = [...prev];
              newArray[index] = {
                ...updatedContent,
                contentIndex: updatedContent?.content?.length > 1 ? 1 : 0,
                sortOrder: index + 1,
                add: false,
                loading: false,
                content:
                  updatedContent?.content?.map(item => ({
                    ...item,
                    oriId: item?.id,
                    status: convertStatusText(item.approvalStatus),
                    url: item.socialMediaLink,
                    inputUrl: item.socialMediaLink
                  })) || []
              };

              setSelected(newArray[index]);

              return newArray;
            });
            SuccessToast('Social Media Links Updated Successfully!');
          } else {
            ErrorToast(res.errorMessage);
            onChangeLoading(selected?.id, false);
          }
        }

        // window.dataLayer.push({
        //   event: 'save-cp-benefits'
        // });
      )
      .catch(err => {
        ErrorToast(err.message);
      });
  };

  const getManageType = (contentStatus, video) => {
    let manageType = '';

    if (contentStatus === 'Live on Hiredly') {
      manageType = 'EDIT LIVE';
    } else if (contentStatus === 'In Review') {
      manageType =
        video?.[0]?.status === 'Live on Hiredly'
          ? 'EDIT REVIEW WITH LIVE'
          : 'EDIT REVIEW';
    } else if (contentStatus === 'Rejected') {
      manageType =
        video?.[0]?.status === 'Live on Hiredly'
          ? 'EDIT REJECT WITH LIVE'
          : 'EDIT REJECT';
    }

    return manageType;
  };

  const saveConfirmation = (link, index) => {
    const manageType = getManageType(contentStatus, video);

    if (!manageType) {
      saveUrl({ link: link, index: index });
      return;
    }

    if (!manageType) {
      saveUrl(link);
      return;
    }

    setManageOpen(true);
    setManageType(manageType);
    setManageLink(link);
    setManageIndex(index);
  };

  const deleteConfirmation = () => {
    if (contentStatus === 'Live on Hiredly') {
      setManageOpen(true);
      setManageType('REMOVE LIVE');
      return;
    } else if (contentStatus === 'In Review') {
      setManageOpen(true);
      setManageType('RETRACT REVIEW');
      return;
    } else {
      setManageOpen(true);
      setManageType('REMOVE REJECT');
      return;
    }
  };

  const saveUrl = ({ link, manageType, index }) => {
    setProgress(0);
    handleLinkChange(link, videoIndex, contentIndex, manageType);
    onChangeLoading(getIdWithIndex(videoIndex), true).then(updatedState => {
      simulateProgress({ videos: updatedState, index: index });
    });
  };

  const deleteUrl = () => {
    onChangeRemove(videoIndex, contentIndex);
  };

  const RenderLine = () => {
    if (selected?.sortOrder === 1) {
      return <img src={firstConnector} />;
    } else if (selected?.sortOrder === 2) {
      return <img src={SecondConnector} />;
    } else if (selected?.sortOrder === 3) {
      return <img src={ThirdConnector} />;
    } else if (selected?.sortOrder === 4) {
      return <img src={FourthConnector} />;
    } else {
      return <img src={firstConnector} />;
    }
  };

  const calculateLinePosition = () => {
    if (selected?.sortOrder === 1) {
      return 115;
    } else if (selected?.sortOrder === 2) {
      return 45;
    } else if (selected?.sortOrder === 3) {
      return -45;
    } else if (selected?.sortOrder === 4) {
      return -115;
    } else {
      return 115;
    }
  };

  const onSaveSequence = async () => {
    try {
      const contentArray = videos
        .flatMap(item => item.content)
        .map((content, index) => ({ ...content, sortOrder: index + 1 }));

      const res = await dispatch(
        mutateCompanyProfile({ input: { socialMedia: contentArray } })
      );

      if (res.type === 'FETCH_COMPANY_PROFILE_SUCCEED') {
        // setVideosOri(res?.companyProfile?.socialMediaLinks);
        SuccessToast('Social media sequence update successfully');
      } else {
        throw new Error('Social media links update failed');
      }
    } catch (error) {
      ErrorToast(error.message);
    }
  };

  const onRevertSequence = () => {
    const sortVideosOri = (videosOri, videos) => {
      const sortedVideosOri = [...videosOri];
      const idOrder = videos.map(video => video.id);

      sortedVideosOri.sort((a, b) => {
        const aIndex = idOrder.indexOf(a.id);
        const bIndex = idOrder.indexOf(b.id);

        const bothLongIds = a.id.length > 6 && b.id.length > 6;

        if (bothLongIds) {
          return a.id.localeCompare(b.id);
        }

        if (a.id.length > 6) {
          return 1;
        }
        if (b.id.length > 6) {
          return -1;
        }

        return aIndex - bIndex;
      });

      return sortedVideosOri;
    };

    const sortedVideosOri = sortVideosOri(videos, videosOri);

    onResetAdd();
    setVideos(sortedVideosOri);
    onResetSortOrder();
  };

  return (
    <>
      <ManagePopUp
        open={manageOpen}
        handleClose={() => {
          setManageOpen(false);
        }}
        manageType={manageType}
        manageLink={manageLink}
        manageIndex={manageIndex}
        saveUrl={saveUrl}
        deleteUrl={deleteUrl}
      />

      <ContentCard
        title="Social Media Highlights"
        subTitle="Embed up to 4 social media videos to share your brand story with jobseekers."
        discardButton
        noChanges={!hasSequenceChanges() && !hasUrlChanges()}
        infoIcon
        infoOnClick={() => {
          setInfoOpen(true);
        }}
        cancelIcon={!isEmpty(companyProfile?.socialMediaLinks)}
        cancelOnClick={() => setView(true)}
        padding="0px !important">
        <OuterWrapper>
          <SocialMediaWrapper>
            {videos.map((video, index) => {
              return (
                <SocialMediaCard
                  setSelected={selectVideo}
                  selected={selected?.id}
                  key={video?.id}
                  video={video}
                  selectedStatus={video?.contentIndex}
                  index={index}
                  moveVideo={moveVideo}
                  progress={progress}
                  videos={videos}
                />
              );
            })}
          </SocialMediaWrapper>
          <div
            style={{
              position: 'relative',
              right: calculateLinePosition(),
              height: '30px'
            }}>
            <RenderLine />
          </div>
          <InputUrl
            videoId={videoId}
            videos={videos}
            video={video || []}
            contentUrl={contentUrl}
            contentIndex={contentIndex || 0}
            videoIndex={videoIndex}
            contentStatus={contentStatus}
            saveConfirmation={saveConfirmation}
            deleteConfirmation={deleteConfirmation}
            handleContentIndexChange={handleContentIndexChange}
            handleLinkChange={handleLinkChange}
            getManageType={getManageType}
            hasUrlChanges={hasUrlChanges}
          />
        </OuterWrapper>
        {hasSequenceChanges() && (
          <SequenceContainer>
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                alignItems: 'center'
              }}>
              <ExclamationTriangleIconStyled />{' '}
              <Typography>
                You’ve made changes to the video sequence, would you like to
                save them?
              </Typography>
            </Grid>
            <Grid sx={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
              <CancelButton onClick={() => onRevertSequence()}>
                Revert
              </CancelButton>
              <SaveButton height={'40px'} onClick={() => onSaveSequence()}>
                Save Sequence
              </SaveButton>
            </Grid>
          </SequenceContainer>
        )}
      </ContentCard>
    </>
  );
}

// not auto selecting when uploading / deleting
// svg not rendering correctly
// deleting loading time
