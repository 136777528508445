import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SwitchStyled = styled(Switch)(props => ({
  width: props.width,
  height: props.height,
  padding: 0,
  borderRadius: 30,

  '& .MuiSwitch-switchBase': {
    padding: 4,
    margin: props.margin ? props.margin : 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: props.trackTransform
        ? props.trackTransform
        : 'translateX(74px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        // When is checked
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: '#D3D3D3'
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#33cf4d'
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: '#D3D3D3'
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: props.thumbWidth,
    height: props.thumbHeight
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    opacity: 1,
    backgroundColor: '#D3D3D3',
    '&:before': {
      content: `"${props.switchOnText}"`,
      display: 'flex',
      opacity: props.checked ? 1 : 0,
      color: '#ffffff',
      fontWeight: 'bold',
      transform: 'translate(20px, 12px)'
    },
    '&:after': {
      content: `"${props.switchOffText}"`,
      display: 'flex',
      transform: 'translate(45px, -7px)',
      color: '#ffffff',
      fontWeight: 'bold',
      opacity: props.checked ? 0 : 1
    }
  }
}));
