import React, { useRef, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import DefaultLayout from '../../layouts/DefaultLayout';
import JobCardSectionComponent from './JobCardSectionComponent/JobCardSectionComponent';
import ApplicantSectionComponent from './ApplicantSectionComponent/ApplicantSectionComponent';
import TalentChatSectionComponent from './TalentChatSectionComponent/TalentChatSectionComponent';
import ChatSectionComponent from './ChatSectionComponent/ChatSectionComponent';
import _ from 'lodash';
import CandidateProfilePopup from '../TalentSearch/PopUpsWrapper/CandidateProfilePopup';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAccountInfo,
  fetchMyCompanySettings
} from '../../redux/actions/company_actions';

const Chats = () => {
  const dispatch = useDispatch();
  const jobApplicationSectionRef = useRef();
  const chatSectionRef = useRef();

  // Job application section
  //  Load jobs and job applications functions
  const loadJobApplications = (offset, jobId) => {
    if (jobApplicationSectionRef?.current) {
      const currentText = jobApplicationSectionRef.current.getCurrentSearchText
        ? jobApplicationSectionRef.current.getCurrentSearchText()
        : '';
      const orderDirection = jobApplicationSectionRef.current.getOrderDirection
        ? jobApplicationSectionRef.current.getOrderDirection()
        : 'asc';
      jobApplicationSectionRef.current.updateJobId(jobId);
      jobApplicationSectionRef.current.loadJobApplications(offset == 0, false);
    }
  };

  const triggerReplaceJobApplication = jobApplication => {
    if (jobApplicationSectionRef?.current) {
      jobApplicationSectionRef.current.replaceJobApplication(jobApplication);
    }
  };

  const triggerRefreshJobApplication = () => {
    if (jobApplicationSectionRef?.current) {
      jobApplicationSectionRef.current.refreshJobApplication();
    }
  };

  // Chat room section
  const updateChatChannel = chatChannel => {
    if (chatSectionRef?.current) {
      chatSectionRef.current.updateChatChannel(chatChannel);
    }
  };

  const updateApplication = application => {
    if (chatSectionRef?.current) {
      chatSectionRef.current.updateApplication(application);
    }
  };

  const onMouseActive = () => {
    if (chatSectionRef?.current) {
      chatSectionRef.current.onActive(
        chatSectionRef.current.getSbConnectionStatus()
      );
    }
  };

  const onMouseMove = _.throttle(e => onMouseActive(), 1500);

  // Use effects
  useEffect(() => {
    dispatch(fetchMyCompanySettings());
    dispatch(fetchAccountInfo());

    document.addEventListener('mousemove', onMouseMove, false);

    return () => {
      document.removeEventListener('mousemove', onMouseMove, false);
    };
  }, []);

  return (
    <DefaultLayout minusBetaBanner={true}>
      <Grid container style={{ height: 'calc(100vh - 65px)', maxWidth: '1920px', margin: 'auto' }}>
        <JobCardSectionComponent
          loadJobApplications={loadJobApplications}
          updateChatChannel={updateChatChannel}
        />

        <Grid
          style={{
            width: '100%'
          }}
          item
          lg={4}
          md={6}
          sm={6}
          xs={12}>
          <MiddleSection
            jobApplicationSectionRef={jobApplicationSectionRef}
            updateChatChannel={updateChatChannel}
            updateApplication={updateApplication}
          />
          {/* <ApplicantSectionComponent
            ref={jobApplicationSectionRef}
            updateChatChannel={updateChatChannel}
            updateApplication={updateApplication}
          /> */}
        </Grid>

        <Grid
          item
          lg={5}
          md={12}
          sm={12}
          xs={12}
          style={{
            width: '100%',
            background: 'aeaeae',
            padding: '30px 1.5%',
            maxHeight: 'calc(100vh - 64px)',
            overflow: 'auto'
          }}>
          <ChatSectionComponent
            ref={chatSectionRef}
            triggerReplaceJobApplication={triggerReplaceJobApplication}
            triggerRefreshJobApplication={triggerRefreshJobApplication}
          />
        </Grid>
      </Grid>
      <CandidateProfilePopup />
    </DefaultLayout>
  );
};

export default Chats;

export const MiddleSection = props => {
  const { jobApplicationSectionRef, updateChatChannel, updateApplication } =
    props;

  // Selectors
  const isTalentSearchChat = useSelector(
    state => state.chats.isTalentSearchChat
  );

  return isTalentSearchChat ? (
    <TalentChatSectionComponent
      updateChatChannel={updateChatChannel}
      updateApplication={updateApplication}
    />
  ) : (
    <ApplicantSectionComponent
      ref={jobApplicationSectionRef}
      updateChatChannel={updateChatChannel}
      updateApplication={updateApplication}
    />
  );
};
