import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Text,
  Spacer,
  ChipContainer,
  ContentContainer,
  Wrapper
} from './styles';
import { TextInputFunction } from '../CustomFilter/SharedTextInput';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateChipStatusIndex,
  updateSearchJobValue,
  updateSelectedFilter,
  updateSelectedFilterValue,
  updateJobsOffset
} from '../../../../redux/actions/job_action';
import _ from 'lodash';
import store from '../../../../../src/redux/stores/store';
import SharedTextField from '../../../../components/SharedTextField';
import { Grid, InputAdornment } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

const CustomFilter = ({ scrollTopRef }) => {
  const dispatch = useDispatch();

  const [scrollPosition, setScrollPosition] = useState(0);

  const chipArray = [
    { label: 'Active', key: 'active' },
    { label: 'Inactive', key: 'inactive' },
    { label: 'All', key: 'all' }
  ];

  // const chipIndex = useSelector(state => state.jobs.chipStatusIndex);
  const selectedFilterValue = useSelector(
    state => state.jobs.selectedFilterValue
  );
  const totalJobsCount = useSelector(state => state.jobs.totalJobsCount);

  const handleChipClick = item => {
    let value;
    // const currentChipIndex = store.getState().jobs.chipStatusIndex;

    if (scrollTopRef) {
      scrollToView();
    }

    if (item?.label == 'All') {
      value = null;
    } else if (item?.label == 'Active') {
      value = true;
    } else if (item?.label == 'Inactive') {
      value = false;
    }

    if (selectedFilterValue == 'all' && value == 'All') {
      return;
    } else if (selectedFilterValue == 'active' && value == true) {
      return;
    } else if (selectedFilterValue == 'inactive' && value == false) {
      return;
    }

    store.getState().jobs.selectedFilter = value;
    dispatch(updateJobsOffset(0)).then(() => {
      store.getState().jobs.jobsCurrentPage = 1; // Reset pagination
      //to store filter label
      dispatch(updateSelectedFilterValue(item?.key));
      //to store processed filter label
      //dispatch(updateSelectedFilter(value));
      // dispatch(updateChipStatusIndex(index));
    });
  };

  const showChipColor = selectedChip => {
    if (selectedFilterValue == selectedChip) {
      return 'true';
    }
  };

  const search = _.debounce(value => {
    let searchTerm = value
      ?.toLowerCase()
      .replace(/^\s\s*/, '')
      .replace(/\s\s*$/, '');

    try {
      window.dataLayer.push({
        event: 'search-job',
        search_term: searchTerm
      });
    } catch {}

    // Reset pagination & offset
    store.getState().jobs.jobsPageOffset = 0;
    store.getState().jobs.jobsCurrentPage = 1;
    dispatch(updateSearchJobValue(value));
  }, 500);

  const deboucedSearch = useCallback(value => search(value), []);

  const onSearch = event => {
    deboucedSearch(event.target.value);
  };

  const getCounts = item => {
    if (totalJobsCount[item.key] == undefined) return -1;

    if (totalJobsCount[item.key] > 0) {
      return totalJobsCount[item.key];
    } else {
      return 0;
    }
  };

  const scrollToView = () => {
    setTimeout(() =>
      scrollTopRef?.current?.scrollIntoView(
        {
          behavior: 'smooth'
        },
        1
      )
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      // clear search text for job list page
      dispatch(updateSearchJobValue(''));
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition > 650) {
      if (scrollTopRef) {
        scrollToView();
      }
    }
  }, [selectedFilterValue]);

  return (
    <Wrapper>
      <div style={{ display: 'flex' }}>
        {chipArray.map((item, index) => {
          return (
            <Fragment key={item?.key}>
              <ChipContainer
                id={
                  item?.key == 'all'
                    ? 'all_jobs'
                    : item?.key == 'active'
                    ? 'active_jobs'
                    : 'inactive_jobs'
                }
                onClick={() => handleChipClick(item)}
                show_chip_color={showChipColor(item?.key)}>
                <ContentContainer>
                  <Text
                    chip_text="true"
                    show_chip_color={showChipColor(item?.key)}>
                    {' '}
                    {item?.label}{' '}
                  </Text>
                  <Spacer width={'2px'} />

                  {getCounts(item) != -1 && (
                    <Text
                      chip_count="true"
                      show_chip_color={showChipColor(item?.key)}>
                      {' '}
                      ({getCounts(item)}){' '}
                    </Text>
                  )}
                </ContentContainer>
              </ChipContainer>
              <Spacer width={'2vw'} />
            </Fragment>
          );
        })}
      </div>
      <Grid sx={{ width: '265px' }}>
        <SharedTextField
          placeholder={'Search by job title'}
          onChange={onSearch}
          InputProps={{
            disableUnderline: true,
            style: {
              background: `#fff`,
              // borderRadius: '30px',
              height: '52px',
              fontSize: '14px',
              fontFamily: 'Inter',
              // border: '0.5px solid #E5E5E5',
              padding: '0 27px 0px 10px'
            },
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </Grid>
    </Wrapper>
  );
};

export default CustomFilter;
