import React from 'react';
import { Card, Grid } from '@mui/material';
import { Skeleton } from '@mui/material';
import styles from './index.module.scss';

const SkeletonLoader = () => (
  <Card className={styles.candidateInfoContainer}>
    <Grid container>
      <Grid item sm={12} md={12} lg={12}>
        <div className={styles.candidateInfoLeftTop}>
          <Skeleton variant="circle" className={styles.skeletonAvatar} />
          <div className={styles.skeletonSubContainer}>
            <Skeleton variant="text" width={100} />
            <Skeleton variant="text" width={140} />
          </div>
        </div>
        <div className={styles.candidateInfoLeftBottom}>
          <Skeleton
            variant="text"
            width={200}
            className={styles.skeletonTextMargin}
          />
          <Skeleton
            variant="text"
            width={250}
            className={styles.skeletonTextMargin}
          />
          <Skeleton
            variant="text"
            width={500}
            className={styles.skeletonTextMargin}
          />
          <Skeleton
            variant="text"
            width={250}
            className={styles.skeletonTextMargin}
          />
          <Skeleton
            variant="text"
            width={200}
            className={styles.skeletonTextMargin}
          />
          <Skeleton
            variant="text"
            width={250}
            className={styles.skeletonTextMargin}
          />
        </div>
      </Grid>
    </Grid>
  </Card>
);
export default SkeletonLoader;
