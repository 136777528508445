import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Button
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Chip } from '@mui/material';
import {
  Edit as EditIcon,
  PersonAdd as PersonAddIcon,
  MoreVert as MoreVertIcon,
  Share as ShareIcon,
  Info as InfoIcon,
  PinDropSharp,
  ArrowBack as ArrowBackIcon,
  Comment as CommentIcon
} from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FeatureJobPopUp from '../../Jobs/PopUps/FeatureJobPopUp';
import RefreshJobPopUp from '../../Jobs/PopUps/RefreshJobPopUp';
import styles from './index.module.scss';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faRedo } from '@fortawesome/free-solid-svg-icons';
import { MANAGE_CANDIDATE_MODE } from '../../../utils/Constants';
import { ReactComponent as AiviIcon } from '../../../assets/common/aivi-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAccountInfo,
  fetchCompanyTeamMembers,
  fetchMyCompanyStatus
} from '../../../redux/actions/company_actions';
import VirtualInterviewPopUpNew from '../../Jobs/PopUps/VirtualInterviewPopUpNew';
import JobAccessPopUpNew from '../../Jobs/PopUps/JobAccessPopUpNew';

const JobDetails = ({ jobData, loading, toggleView, isListView, isAts }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [isFeatureJobOpen, setIsFeatureJobOpen] = useState(false);
  const [isRefreshJobOpen, setIsRefreshJobOpen] = useState(false);
  const [isJobAccessOpen, setIsJobAccessOpen] = useState(false);
  const [isDisableRefresh, setIsDisableRefresh] = useState(
    !jobData?.enableRefreshJob
  );
  const [isDisableFeature, setIsDisableFeature] = useState(
    !jobData?.enableFeatureJob
  );
  const [isVirtualInterviewOpen, setIsVirtualInterviewOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    dispatch(fetchMyCompanyStatus());
    dispatch(fetchCompanyTeamMembers());
    dispatch(fetchAccountInfo());
  }, []);

  const myCompanyStatus = useSelector(state => state.companies.myCompanyStatus);
  const isCompanyApproved = myCompanyStatus?.isApproved;
  const accountInfo = useSelector(state => state.companies.accountInfo);
  const companyTeamMembers = useSelector(
    state => state.companies.companyTeamMembers
  );

  const onSwitchChanged = e => {
    if (
      (e.target.innerText == 'List View' && !isListView) ||
      ((e.target.innerText == 'ATS View' ||
        e.target.innerText == 'Pipeline View') &&
        isListView)
    ) {
      const manageModeEvent =
        e.target.innerText == 'List View' ? 'list-view' : 'ats-view';

      localStorage.setItem(MANAGE_CANDIDATE_MODE, e.target.innerText);

      window.dataLayer.push({
        event: manageModeEvent
      });
      toggleView();
    }
  };

  const onEditJob = () => {
    setAnchorEl(null);
    history(`/jobs/${jobData?.id}`);
  };

  const onEditJobAccess = () => {
    setAnchorEl(null);
    setIsJobAccessOpen(true);
  };

  const onEditVirtualInterview = () => {
    setAnchorEl(null);
    setIsVirtualInterviewOpen(true);
  };

  const onEditFeatureJob = () => {
    setAnchorEl(null);
    setIsFeatureJobOpen(true);
  };

  const onEditRefreshJob = () => {
    setAnchorEl(null);
    setIsRefreshJobOpen(true);
  };

  const onEditExtendFeatureJob = () => {
    setAnchorEl(null);
    setIsFeatureJobOpen(true);
  };

  useEffect(() => {
    setIsDisableRefresh(!jobData?.enableRefreshJob);
    setIsDisableFeature(!jobData?.enableFeatureJob);
  }, [jobData]);

  const isMasterAccount =
    localStorage.getItem('currentAccountType') === 'master';

  const extendFeatureDaysLeft = moment(jobData?.boostedExpired).diff(
    moment(),
    'days'
  );
  const isShowExtendFeature =
    extendFeatureDaysLeft === 0 || extendFeatureDaysLeft === 1;

  return (
    <Grid container className={styles.spacing}>
      <FeatureJobPopUp
        open={isFeatureJobOpen}
        handleClose={() => setIsFeatureJobOpen(false)}
        jobId={jobData?.id}
        jobTitle={jobData?.title}
        setIsDisableFeature={setIsDisableFeature}
        setIsDisableRefresh={setIsDisableRefresh}
        featuredTokensCount={myCompanyStatus?.featuredJobTokensCount}
      />
      <RefreshJobPopUp
        open={isRefreshJobOpen}
        handleClose={() => setIsRefreshJobOpen(false)}
        jobId={jobData?.id}
        jobTitle={jobData?.title}
        setIsDisableRefresh={setIsDisableRefresh}
        referesherTokensCount={myCompanyStatus?.refresherTokensCount}
      />
      <JobAccessPopUpNew
        open={isJobAccessOpen}
        useAsPopUp={true}
        handleClose={() => setIsJobAccessOpen(false)}
        jobId={jobData?.id}
      />
      <VirtualInterviewPopUpNew
        useAsPopUp={true}
        open={isVirtualInterviewOpen}
        handleClose={() => setIsVirtualInterviewOpen(false)}
        jobId={jobData?.id}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={onEditJob} id="manage-candidates-edit-job-button">
          <EditIcon className={styles.materialUiIcon} />
          <div className={styles.menuItem} style={{ paddingLeft: '12px' }}>
            Edit Job
          </div>
        </MenuItem>

        {jobData &&
          (isShowExtendFeature ? (
            <Tooltip
              title={
                extendFeatureDaysLeft == 0 ? (
                  <span>
                    <p className={styles.tooltipText}>
                      The job featuring will expire tomorrow, click to extend
                      featured status now
                    </p>
                  </span>
                ) : (
                  <span>
                    <p className={styles.tooltipText}>
                      {`The job featuring will expire on ${moment(
                        jobData?.boostedExpired
                      ).format(
                        'DD MMMM YYYY'
                      )}, click to extend featured status now`}
                    </p>
                  </span>
                )
              }>
              <MenuItem
                // id="extend-feature-job-button"
                onClick={onEditExtendFeatureJob}
                disabled={isDisableFeature || !jobData || loading}>
                <FontAwesomeIcon
                  icon={faRocket}
                  className={styles.fontAwesomeIcon}
                />
                <div className={styles.menuItem}>Extend Feature Job</div>
                <InfoIcon className={styles.menuInfoIcon} />
              </MenuItem>
            </Tooltip>
          ) : !isDisableFeature ? (
            <Tooltip
              title={
                <span className={styles.tooltipText}>
                  Feature this job with one Featured Token.
                </span>
              }
              placement="bottom">
              <MenuItem
                id="manage-candidates-feature-job-button"
                onClick={onEditFeatureJob}
                disabled={isDisableFeature || !jobData || loading}>
                <FontAwesomeIcon
                  icon={faRocket}
                  className={styles.fontAwesomeIcon}
                />
                <div className={styles.menuItem}>Feature Job</div>
                <InfoIcon className={styles.menuInfoIcon} />
              </MenuItem>
            </Tooltip>
          ) : (
            <MenuItem onClick={() => {}} disabled>
              <div className={styles.menuItemContainer}>
                Featured Expiry:{' '}
                {moment(jobData?.boostedExpired).format('DD MMMM YYYY')}
              </div>
            </MenuItem>
          ))}

        <Tooltip
          title={
            <span className={styles.tooltipText}>
              Refresh to bump up this job’s display position with one Refresher
              Token.
            </span>
          }
          placement="bottom">
          <MenuItem
            id="manage-candidates-refresh-job-button"
            onClick={onEditRefreshJob}
            disabled={isDisableRefresh || !jobData || loading}>
            <FontAwesomeIcon icon={faRedo} className={styles.fontAwesomeIcon} />
            <div className={styles.menuItem}>Refresh Job</div>

            <InfoIcon className={styles.menuInfoIcon} />
          </MenuItem>
        </Tooltip>

        {isCompanyApproved &&
          isMasterAccount &&
          companyTeamMembers?.length > 0 && (
            <MenuItem
              id="manage-candidates-assign-job-access-button"
              onClick={onEditJobAccess}>
              <PersonAddIcon className={styles.materialUiIcon} />
              <div className={styles.menuItem}>Assign Job Access</div>
            </MenuItem>
          )}

        <Tooltip
          title={
            <span className={styles.tooltipText}>
              Virtual Interviewer allows you to pre-select 3 questions for
              candidates to answer as soon as they apply to this job.
            </span>
          }
          placement="bottom">
          <MenuItem
            id="manage-candidates-edit-virtual-interviewer-button"
            onClick={onEditVirtualInterview}
            disabled={!jobData || loading}>
            <AiviIcon
              width={18}
              style={{ transform: 'translateX(4px)' }}
              className={styles.materialUiIcon}
            />
            <div className={styles.menuItem} style={{ paddingLeft: '18px' }}>
              Edit Virtual Interview
            </div>
            <InfoIcon className={styles.menuInfoIcon} />
          </MenuItem>
        </Tooltip>
      </Menu>

      <div className={styles.jobTitleContainer}>
        {loading && !jobData && !isAts ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Skeleton variant="text" className={styles.skeletonTitle} />
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}>
              {Array.from(new Array(1)).map(index => (
                <Skeleton
                  key={index}
                  variant="circular"
                  width={150}
                  height={50}
                  style={{ borderRadius: '8px', marginRight: '10px' }}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className={styles.jobTitleStatusContainer}>
            <div className={styles.jobTitleTooltipContainer}>
              <div
                className={styles.iconButtonContainer}
                style={{ display: 'flex' }}>
                <IconButton id="manage-candidates-back-button">
                  <ArrowBackIcon
                    onClick={() => history('/jobs')}
                    className={styles.moreVertiIcon}
                  />
                </IconButton>
              </div>
              {loading && !jobData ? (
                <Skeleton variant="text" className={styles.skeletonTitle} />
              ) : (
                <>
                  <h6
                    style={
                      isAts
                        ? {
                            fontSize: '18px',
                            display: 'flex',
                            alignItems: 'center'
                          }
                        : {
                            fontSize: '18px',
                            display: 'flex',
                            alignItems: 'center'
                          }
                    }
                    className={styles.jobTitle}>
                    {jobData?.title}
                  </h6>
                  <div
                    className={styles.iconButtonContainer}
                    style={{ display: 'flex' }}>
                    <IconButton
                      onClick={event => setAnchorEl(event.currentTarget)}
                      style={{ padding: '8px', marginLeft: '5px' }}>
                      <MoreVertIcon className={styles.moreVertiIcon} />
                    </IconButton>
                  </div>
                </>
              )}
            </div>

            <div className={styles.statusChipVertiDotContainer}>
              <Grid
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '0 0 0 20px'
                }}>
                <div
                  style={{
                    backgroundColor: '#e1e1e1',
                    borderRadius: '25px',
                    padding: '6px',
                    display: 'flex'
                  }}>
                  <Chip
                    label="List View"
                    onClick={onSwitchChanged}
                    style={{
                      minWidth: '90px',
                      cursor: 'pointer',
                      backgroundColor: isListView ? '#ffff' : '#e1e1e1',
                      fontWeight: 'bold',
                      color: isListView ? '#222222' : '#aeaeae'
                    }}
                  />
                  <Chip
                    label="Pipeline View"
                    onClick={onSwitchChanged}
                    style={{
                      minWidth: '90px',
                      cursor: 'pointer',
                      backgroundColor: isListView ? '#e1e1e1' : '#ffff',
                      fontWeight: 'bold',
                      color: isListView ? '#aeaeae' : '#222222'
                    }}
                  />
                </div>
              </Grid>
            </div>
          </div>
        )}
      </div>
    </Grid>
  );
};

export default JobDetails;
