import { getCountry } from './useRegionalisation';

export const getSendbirdToken = () => {
  if (getCountry() == 'SG') {
    return process.env.REACT_APP_SENDBIRD_API_SG_TOKEN;
  } else {
    return process.env.REACT_APP_SENDBIRD_API_TOKEN;
  }
};

export const getSendbirdID = () => {
  if (getCountry() == 'SG') {
    return process.env.REACT_APP_SENDBIRD_APP_SG_ID;
  } else {
    return process.env.REACT_APP_SENDBIRD_APP_ID;
  }
};
