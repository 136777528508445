import { Dialog, Divider, IconButton, TextField } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { HeightSpacer, Spacer } from '../../../../components/Spacer/styles';
import { SharedButton } from '../../JobList/SharedButton';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  ContentContainer,
  ContentDescription,
  CTAButtonsContainer,
  CTAContainer,
  KeywordsCount,
  PopupContainer,
  TextFieldContainer,
  TextFieldsContainer,
  Title,
  TitleContainer
} from './styles';
import { Spinner } from '../../../../components';

const MultipleTextFieldsPopup = ({
  onClosePopup,
  numOfTextField,
  title,
  desc,
  buttonText,
  disabledButton,
  extraButtonText,
  extraButtonTextLoading,
  data,
  onClick,
  onClickExtraButton,
  loading,
  disabled,
  onChange,
  onFocus,
  onBlur,
  indexOfSelectedField,
  open,
  placeholder,
  isTyping,
  textRef
}) => {
  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          borderRadius: '20px'
        }
      }}
      onClose={onClosePopup}>
      <PopupContainer>
        <TitleContainer>
          <Title>{title}</Title>
          <IconButton onClick={onClosePopup}>
            <CloseIcon />
          </IconButton>
        </TitleContainer>

        <Divider />

        <ContentContainer>
          <ContentDescription>{desc}</ContentDescription>
          <HeightSpacer height={'24px'} />
          <TextFieldsContainer>
            {Array.from(
              { length: numOfTextField ? numOfTextField : 5 },
              (_, index) => {
                return (
                  <TextFieldContainer key={`text-field-${index}`}>
                    <span>{index + 1}.</span> <Spacer width={'28px'} />
                    <TextField
                      style={{ width: '100%' }}
                      onChange={e => onChange(e, index)}
                      onFocus={() => onFocus(index)}
                      onBlur={onBlur}
                      sx={{
                        '& fieldset': {
                          border:
                            data[index]?.keyword?.length >= 20 &&
                            index == indexOfSelectedField &&
                            isTyping
                              ? '1px solid #f75443'
                              : disabled && 'none'
                        }
                      }}
                      InputProps={{
                        style: {
                          borderRadius: '8px',
                          background: disabled && '#EFEFEF',
                          opacity: disabled && '0.72'
                        },
                        endAdornment:
                          indexOfSelectedField == index &&
                          !disabled &&
                          isTyping ? (
                            <KeywordsCount
                              $error={data[index]?.keyword?.length >= 20}>
                              {data[index]?.keyword
                                ? data[index]?.keyword?.length
                                : '0'}
                              /20
                            </KeywordsCount>
                          ) : null
                      }}
                      inputProps={{
                        style: {
                          padding: '12px 14px',
                          fontWeight: '400',
                          fontFamily: 'Inter',
                          fontSize: '16px'
                        }
                      }}
                      value={data[index]?.keyword}
                      error={
                        data[index]?.keyword?.length >= 20 &&
                        isTyping &&
                        index == indexOfSelectedField
                      }
                      disabled={disabled}
                      focused={indexOfSelectedField == index && !disabled}
                      placeholder={placeholder}
                      inputRef={index == 0 ? textRef : null}
                    />
                  </TextFieldContainer>
                );
              }
            )}
          </TextFieldsContainer>
        </ContentContainer>

        <Divider />

        <CTAContainer $showExtraButton={extraButtonText.length > 0}>
          {extraButtonText.length > 0 && (
            <CTAButtonsContainer>
              <SharedButton
                type={'thin_outlined'}
                font_size={'16px'}
                width={'220px'}
                padding={'6px 10px'}
                onClick={onClickExtraButton}>
                {extraButtonTextLoading ? (
                  <Spinner size="XS" color={'white'} noPadding={true} />
                ) : (
                  extraButtonText
                )}
              </SharedButton>
            </CTAButtonsContainer>
          )}
          {buttonText.length > 0 && (
            <CTAButtonsContainer>
              <SharedButton
                type={'basic'}
                font_size={'16px'}
                width={'220px'}
                padding={'6px 10px'}
                onClick={onClick}
                disabled={disabledButton}>
                {loading ? (
                  <Spinner size="XS" color={'white'} noPadding={true} />
                ) : (
                  buttonText
                )}
              </SharedButton>
            </CTAButtonsContainer>
          )}
        </CTAContainer>
      </PopupContainer>
    </Dialog>
  );
};

export default MultipleTextFieldsPopup;
