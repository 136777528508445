import React from 'react';
import { MainContainer } from './styles';

const CandidateResume = props => {
  const { candidate } = props;

  const resume =
    candidate?.user?.resume || candidate?.jobApplication?.user?.resume;

  return (
    <MainContainer>
      <iframe
        title="Resume"
        src={
          resume?.includes('pdf')
            ? resume
            : `https://view.officeapps.live.com/op/view.aspx?src=${resume}`
        }
        width="100%"
        height="100%"
      />
    </MainContainer>
  );
};

export default CandidateResume;
