import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { styled as styling } from '@mui/material/styles';

export const SuccessContainer = styling('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '2rem',
});

export const CheckIconStyled = styling(CheckCircleIcon)({
  color: 'rgb(32, 196, 114)',
  height: '35px',
  width: '35px',
  marginRight: '15px',
});

export const SuccessText = styling('h6')({
  fontFamily: 'Inter',
  fontWeight: 700,
  fontSize: '20px',
  color: 'rgba(0, 0, 0, 0.6)',
});
