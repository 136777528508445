import React, { useState } from 'react';
import { RegionDropdownStyled } from './styles';
import Colors from '../../styles/colors.module.scss';

const FormSelectStateRegion = ({
  name,
  label,
  country,
  origin,
  onChange,
  value,
  warning
}) => {
  return (
    <>
      <RegionDropdownStyled
        error={`${warning}`}
        origin={origin}
        country={country}
        value={value}
        disableWhenEmpty={true}
        label={label}
        onChange={onChange}
        defaultOptionLabel="Select State/Region"
      />
      {warning && (
        <p
          style={{
            color: Colors.priRed,
            margin: '3px 14px 0',
            fontSize: '0.75rem',
            fontWeight: '400',
            fontFamily: 'sans-serif'
          }}>
          State/Region is required
        </p>
      )}
    </>
  );
};

export default FormSelectStateRegion;
