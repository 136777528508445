import React, { Fragment, useState } from 'react';
import {
  OuterWrapper,
  TitleStyled,
  CheckboxStyled,
  FormGroupStyled,
  FormControlLabelStyled,
  FormControlStyled
} from './styles';
import { useSelector, useDispatch } from 'react-redux';
import { updateBookmarkedTalentsFilters } from '../../../../redux/actions/talent_action';

export const NameFilter = () => {
  const dispatch = useDispatch();

  // Retriving company team members from redux
  const myCompanySettings = useSelector(
    state => state.companies.myCompanySettings
  );

  // Add in master account to team member list
  const teamMembers = myCompanySettings?.companyTeamMembers
    ? [
        {
          id: myCompanySettings.id,
          name: myCompanySettings.userName,
          position: 'master'
        },
        ...myCompanySettings.companyTeamMembers
      ]
    : [];

  const bookmarkedTalentFilters = useSelector(
    state => state.talents.bookmarkedTalentFilters
  );

  const handleChange = id => event => {
    if (!bookmarkedTalentFilters) return;

    let talentFilters = { ...bookmarkedTalentFilters };

    if (event.target.checked) {
      talentFilters.bookmarkedByIds.push(id);
    } else {
      talentFilters.bookmarkedByIds = talentFilters.bookmarkedByIds.filter(
        memberId => memberId !== id
      );
    }

    dispatch(updateBookmarkedTalentsFilters(talentFilters));
  };

  const isChecked = id => {
    const index = bookmarkedTalentFilters.bookmarkedByIds.findIndex(
      memberId => memberId == id
    );

    return index != -1;
  };

  return (
    <Fragment>
      <OuterWrapper>
        <TitleStyled>Bookmarked by</TitleStyled>
        <FormControlStyled>
          <FormGroupStyled>
            {teamMembers?.map(member => {
              return (
                <FormControlLabelStyled
                  key={member.name}
                  control={
                    <CheckboxStyled
                      checked={isChecked(member.id)}
                      onChange={handleChange(member.id)}
                      name={member.name}
                    />
                  }
                  label={member.name}
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: 14,
                      letterSpacing: '0.1px'
                    },
                    '& .MuiSvgIcon-root': {
                      fontSize: 20
                    }
                  }}
                />
              );
            })}
          </FormGroupStyled>
        </FormControlStyled>
      </OuterWrapper>
    </Fragment>
  );
};
