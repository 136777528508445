import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import KeywordInput from '../../../../../components/KeywordInput';
import {
  updatePostJobPrevTooltip,
  updatePostJobTooltipStatus
} from '../../../../../redux/actions/job_action';
import { useFormContext } from '../../../PostJob/context';
import { HeightSpacer, Spacer } from '../../../../../components/Spacer/styles';
import { Text, TextContainer } from './styles';

const JobKeyword = () => {
  const dispatch = useDispatch();
  const { formState } = useFormContext();

  const selectedJobKeywords = formState?.keywords;

  const tooltipStatus = useSelector(state => state.jobs.tooltipStatus);
  const prevTooltip = useSelector(state => state.jobs.prevTooltip);

  const onClickTips = key => {
    let status = { ...tooltipStatus };

    if (status[key]) {
      status[key] = false;
      dispatch(updatePostJobTooltipStatus(status));
    } else {
      status[key] = true;
      dispatch(updatePostJobTooltipStatus(status));
    }

    if (prevTooltip.length > 0 && prevTooltip !== key) {
      status[prevTooltip] = false;
      dispatch(updatePostJobTooltipStatus(status));
    }

    dispatch(updatePostJobPrevTooltip(key));
  };

  return (
    // <div style={{ margin: '35px 0px' }}>
    <div>
      <div style={{ display: 'flex' }}>
        <TextContainer $container={'title-container'}>
          <Text $type="keywords-title" variant="h4">
            Key Highlights
          </Text>
        </TextContainer>
        <Spacer width={'10px'} />
        {/* <TipsTooltip
          title={'Key Highlights'}
          descriptions={[
            <Description>
              Listing the keywords needed for this position helps candidates to discover your job better. We suggest displaying
              interesting keywords.
            </Description>
          ]}
          top={'-120px'}
          left={'70px'}
          totalPages={1}
          onClick={() => onClickTips('keywords')}
          cKey={'keywords'}
        /> */}
      </div>
      <HeightSpacer height="8px" />
      <TextContainer $container={'description-container'}>
        <Text $type={'keywords-description'} variant="h6">
          You can add a maximum of 5 keywords to attract the right candidates.
          <br />
          Note: This only appears if this job is posted using a Job Slot Max or
          has a Key Highlight Token applied.
        </Text>
        <Text $type={'keywords-length'} variant={'h6'}>{`${
          selectedJobKeywords?.length > 0 ? selectedJobKeywords.length : 0
        }/5`}</Text>
      </TextContainer>

      <HeightSpacer height="16px" />

      <KeywordInput />

      <HeightSpacer height="4px" />
    </div>
  );
};

export default JobKeyword;
