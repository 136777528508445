import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { PopUpDialog } from '../../../../../components';
import { MUIButtonStyled } from '../../../../../components/MUIButton';
import {
  fetchCompanyAffiliations,
  mutateCompanyAffiliation
} from '../../../../../redux/actions/company_actions';
import { AFFILIATION_TYPE } from '../../../../../utils/Constants';
import { ErrorToast, SuccessToast } from '../../../../../utils/ToastUtils';
import styles from './index.module.scss';
import SharedTextField from '../../../../../components/SharedTextField';
import SharedSelectField from '../../../../../components/SharedSelectField';

const AddAffiliatePopUp = ({ open, handleClose, companyId }) => {
  const dispatch = useDispatch();

  const mutatingCompanyAffiliate = useSelector(
    state => state.companies.mutatingCompanyAffiliate
  );

  const [formState, setFormState] = useState({
    affiliatedCompanyId: '',
    affiliateType: ''
  });

  const [errors, setErrors] = useState({});

  const validationSchema = yup.object().shape({
    affiliatedCompanyId: yup
      .string()
      .required(`Affiliated Company ID is required`),
    affiliateType: yup.string().required('Affiliation Type is required')
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value
    });
  };

  const validate = async () => {
    try {
      await validationSchema.validate(formState, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      const newErrors = {};
      err.inner.forEach(error => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const onAddAffiliate = async () => {
    const isValid = await validate();
    if (!isValid) return;

    dispatch(
      mutateCompanyAffiliation({
        input: {
          affiliatedCompanyId: formState.affiliatedCompanyId,
          affiliationType: formState.affiliateType
        }
      })
    )
      .then(async res => {
        if (res.errors) {
          ErrorToast(res.errors[0].message);
        } else {
          SuccessToast('Affiliated Company Added Successfully!');
          await dispatch(fetchCompanyAffiliations());
          handleClose();
        }
      })
      .catch(err => {
        ErrorToast(err.message);
      });
  };

  return (
    <PopUpDialog
      open={open}
      handleClose={handleClose}
      title="Add Affiliated Company">
      <div style={{ padding: '20px 20px' }}>
        <div className={styles.contentContainer}>
          <div className={styles.row}>
            <SharedTextField
              name="affiliatedCompanyId"
              placeholder={`Affiliated Company ID, eg. 0000-1111-2222`}
              value={formState.affiliatedCompanyId}
              onChange={handleChange}
              error={!!errors.affiliatedCompanyId}
              helperText={errors.affiliatedCompanyId}
            />
          </div>
          <div className={styles.row}>
            <SharedSelectField
              name="affiliateType"
              placeholder="Affiliation Type"
              options={[
                {
                  id: AFFILIATION_TYPE.SISTER,
                  title: _.capitalize(AFFILIATION_TYPE.SISTER)
                },
                {
                  id: AFFILIATION_TYPE.SUBSIDIARY,
                  title: _.capitalize(AFFILIATION_TYPE.SUBSIDIARY)
                }
              ]}
              value={formState.affiliateType}
              onChange={handleChange}
              error={!!errors.affiliateType}
              helperText={errors.affiliateType}
            />
          </div>
          <p className={styles.infoText}>
            {`Your affiliates can find their Company ID in this page or their company detail page when they are logged into their Employer Account on Hiredly.`}
          </p>
          <div className={styles.exampleContainer}>
            <span className={styles.exampleText}>Your company id is </span>
            <span className={styles.exampleText} style={{ fontWeight: 'bold' }}>
              {companyId}
            </span>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <MUIButtonStyled
            large
            outlinedBlack
            label="Cancel"
            onClick={handleClose}
          />
          <MUIButtonStyled
            large
            label="Add"
            disabled={mutatingCompanyAffiliate}
            onClick={onAddAffiliate}
          />
        </div>
      </div>
    </PopUpDialog>
  );
};

export default AddAffiliatePopUp;
