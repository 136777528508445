import {
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Grid
} from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { HeightSpacer } from '../../../components/Spacer/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearTalentsParams,
  getTalents,
  updateCurrentTalent,
  updateKeywordSearchedStatus,
  updateNewSearch,
  updateSearchResumeCurrentPage,
  updateSearchTalentFilters,
  updateFeedbackCardStatus,
  updateFeedbackForm,
  updateFeedbackPage,
} from '../../../redux/actions/talent_action';
import {
  keywordSearchTypes,
  talentSortBy,
  TALENTS_SORT_BY
} from '../../../utils/Constants';
import { fetchMyCompanyStatus } from '../../../redux/actions/company_actions';
import SharedTextField from '../../../components/SharedTextField';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import Colors from '../../../styles/colors.module.scss';
import Mark from 'mark.js';
import { updateTalentsList } from '../../../redux/actions/talent_action';

const ResumeSearch = ({ triggerLoadTalents }) => {
  const trackSearch = useCallback(cFilters => {
    let searchTerm = cFilters['keyword'];
    let filterTerm =
      cFilters['filterMode'] == 'or'
        ? 'any'
        : cFilters['filterMode'] == 'and'
        ? 'all'
        : 'exact';
    let sortBy =
      cFilters['sort'] == null
        ? 'relevance'
        : cFilters['sort']?.by == 'upload_resume_at'
        ? 'resume_uploaded_on'
        : 'last_active_on';

    window.dataLayer.push({
      event: 'CE_search-talent',
      'search-term': searchTerm,
      'filter-term': filterTerm,
      'sort-by': sortBy
    });
  }, []);

  return (
    <div
      style={{
        padding: '0px',
        width: '95%',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px'
        }}>
        <Title />
        <KeywordSearch trackSearch={trackSearch} />
      </div>

      <WordType
        triggerLoadTalents={triggerLoadTalents}
        trackSearch={trackSearch}
      />

      <Sortby
        trackSearch={trackSearch}
        triggerLoadTalents={triggerLoadTalents}
      />
    </div>
  );
};

export default ResumeSearch;

export const Title = () => {
  return (
    <span
      style={{
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: '700',
        letterSpacing: '0.1px'
      }}>
      Search Talents
    </span>
  );
};

export const KeywordSearch = ({ trackSearch }) => {
  const dispatch = useDispatch();

  const filters = useSelector(state => state.talents.searchTalentFilters);
  const searchedKeyword = useSelector(state => state.talents.searchedKeyword);
  const talent = useSelector(state => state.talents.talent);

  const talentsFilterNoResult = useSelector(
    state => state.talents.talentsFilterNoResult
  );

  const onChangeKeyword = e => {
    let cFilters = { ...filters };

    if (e.target.value.length >= 0) {
      cFilters['keyword'] = e.target.value;
    }

    dispatch(updateSearchTalentFilters(cFilters));
  };

  const getKeyword = () => {
    if (filters?.keyword) {
      return filters.keyword;
    }

    return '';
  };

  const talentList = useSelector(state => state.talents.talents);
  const defaultValue = useSelector(state => state.talents.defaultFilter);

  // Check values between default value
  function deepEqual(obj1, obj2) {
    if (obj1 === obj2) return true;

    if (
      typeof obj1 !== 'object' ||
      typeof obj2 !== 'object' ||
      obj1 === null ||
      obj2 === null
    ) {
      return false;
    }

    if (Array.isArray(obj1) !== Array.isArray(obj2)) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (!keys2.includes(key)) {
        return false;
      }
      if (!deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  function resetFeedbackForm() {
    dispatch(
      updateFeedbackForm({
        rating: '',
        feedback: ''
      })
    );
    dispatch(updateFeedbackPage(0));
  }

  const checkForDefault = (val) => {
    const isDefault = deepEqual(defaultValue, val);

    if (isDefault) {
      dispatch(updateTalentsList([...talentList]
      ));
    } else {
      dispatch(
        updateTalentsList([
          ...talentList,
          {
            displayFeedback: true
          }
        ])
      );
      resetFeedbackForm();
      dispatch(updateNewSearch(true))
      dispatch(updateFeedbackCardStatus(false))
    }
  }

  const onSubmitKeyword = () => {
    if (talentsFilterNoResult) {
      dispatch(clearTalentsParams());
    }

    let cFilters = { ...filters };

    if (cFilters.before) {
      delete cFilters.before;
    }

    cFilters.after = '';

    trackSearch(cFilters);

    dispatch(updateKeywordSearchedStatus(true, cFilters.keyword));

    dispatch(updateSearchTalentFilters(cFilters)).then(() => {
      checkForDefault(cFilters);
      dispatch(updateSearchResumeCurrentPage(1));
      dispatch(updateCurrentTalent({}));
      dispatch(getTalents(cFilters));
    });
  };

  const onKeyToSearch = e => {
    if (e.keyCode == 13) {
      onSubmitKeyword();
    }
  };

  useEffect(() => {
    dispatch(fetchMyCompanyStatus());
  }, []);

  useEffect(() => {
    if (searchedKeyword.length > 0 && Object.keys(talent)?.length > 0) {
      const markInstance = new Mark(document.querySelectorAll('.searchable'));

      markInstance.unmark({
        done: () => {
          markInstance.mark([searchedKeyword], {
            accuracy:
              filters['filterMode'] == 'or' || filters['filterMode'] == 'and'
                ? 'partially'
                : 'exactly',
            separateWordSearch:
              filters['filterMode'] == 'or' || filters['filterMode'] == 'and'
                ? true
                : false
          });
        }
      });
    }
  }, [searchedKeyword, filters['filterMode'], talent]);

  return (
    <SharedTextField
      InputProps={textFieldProps(onSubmitKeyword)}
      value={getKeyword()}
      onChange={onChangeKeyword}
      sx={{
        width: '100%',
        '.MuiInputBase-root': {
          borderRadius: '4px'
        },
        '.MuiInputBase-input': {
          padding: '10px 4px 10px 16px'
        }
      }}
      onKeyDown={onKeyToSearch}
    />
  );
};

export const WordType = ({ triggerLoadTalents, trackSearch }) => {
  const dispatch = useDispatch();
  const filters = useSelector(state => state.talents.searchTalentFilters);

  const onIncludeWord = e => {
    let cFilters = { ...filters };

    if (e.target.value == 'or') {
      cFilters['filterMode'] = 'or';
      cFilters['wordMatch'] = 'word';
    } else if (e.target.value == 'and') {
      cFilters['filterMode'] = 'and';
      cFilters['wordMatch'] = 'word';
    } else {
      cFilters['filterMode'] = 'nil';
      cFilters['wordMatch'] = 'phrase';
    }

    dispatch(updateSearchTalentFilters(cFilters)).then(() => {
      if (cFilters['keyword'].length <= 0) return;

      trackSearch(cFilters);

      dispatch(updateKeywordSearchedStatus(true, cFilters.keyword));
      triggerLoadTalents('search-resume', '', 1);
    });
  };

  return (
    <Grid display={'flex'} flexDirection={'column'} gap={'8px'}>
      <span
        style={{
          fontSize: '14px',
          fontFamily: 'Inter',
          fontWeight: '700',
          letterSpacing: '0.1px'
        }}>
        Include
      </span>
      <div style={{ margin: '0 8px' }}>
        {keywordSearchTypes.map((item, index) => {
          return (
            <RadioGroup
              value={filters['filterMode'] ? filters['filterMode'] : 'or'}
              onChange={onIncludeWord}>
              <FormControlLabel
                control={
                  <Radio
                    value={item?.id}
                    size="small"
                    sx={{
                      padding: '2px 9px',
                      '&.Mui-checked': {
                        color: '#222'
                      }
                    }}
                  />
                }
                label={item.title}
                key={`${item.title} - ${index}`}
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: 14,
                    letterSpacing: '0.1px'
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: 20
                  }
                }}
              />
            </RadioGroup>
          );
        })}
      </div>
    </Grid>
  );
};

export const Sortby = ({ trackSearch, triggerLoadTalents }) => {
  const dispatch = useDispatch();
  const filters = useSelector(state => state.talents.searchTalentFilters);
  const talentList = useSelector(state => state.talents.talents);
  const defaultValue = useSelector(state => state.talents.defaultFilter);
  
  const onSortTalent = e => {
    let cFilters = { ...filters };

    if (e.target.value == TALENTS_SORT_BY.RELEVANCE) {
      cFilters['sort'] = null;
    } else if (e.target.value == TALENTS_SORT_BY.UPLOAD_RESUME_AT) {
      cFilters['sort'] = { by: 'upload_resume_at', direction: 'desc' };
    } else {
      cFilters['sort'] = { by: 'last_active_at', direction: 'desc' };
    }

    trackSearch(cFilters);

    // Check values between default value
    function deepEqual(obj1, obj2) {
      if (obj1 === obj2) return true;
  
      if (
        typeof obj1 !== 'object' ||
        typeof obj2 !== 'object' ||
        obj1 === null ||
        obj2 === null
      ) {
        return false;
      }
  
      if (Array.isArray(obj1) !== Array.isArray(obj2)) {
        return false;
      }
  
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
  
      if (keys1.length !== keys2.length) {
        return false;
      }
  
      for (let key of keys1) {
        if (!keys2.includes(key)) {
          return false;
        }
        if (!deepEqual(obj1[key], obj2[key])) {
          return false;
        }
      }
  
      return true;
    }
  
    function resetFeedbackForm() {
      dispatch(
        updateFeedbackForm({
          rating: '',
          feedback: ''
        })
      );
      dispatch(updateFeedbackPage(0));
    }
  
    const checkForDefault = (val) => {
      const isDefault = deepEqual(defaultValue, val);

      if (isDefault) {
        dispatch(updateTalentsList([...talentList]
        ));
      } else {
        dispatch(
          updateTalentsList([
            ...talentList,
            {
              displayFeedback: true
            }
          ])
        );
        resetFeedbackForm();
        dispatch(updateNewSearch(true))
        dispatch(updateFeedbackCardStatus(false))
      }
    }

    dispatch(updateSearchTalentFilters(cFilters)).then(() => { 
      // checkForDefault(cFilters);
      dispatch(updateKeywordSearchedStatus(true, cFilters.keyword));
      triggerLoadTalents('search-resume', '', 1);
    });
  };

  return (
    <Grid display={'flex'} flexDirection={'column'} gap={'8px'}>
      <span
        style={{
          letterSpacing: '0.1px',
          fontSize: '14px',
          fontFamily: 'Inter',
          fontWeight: '700'
        }}>
        Sort by
      </span>
      <div style={{ margin: '0 8px' }}>
        {talentSortBy.map((item, index) => {
          return (
            <RadioGroup
              value={
                filters['sort'] ? filters['sort'].by.toUpperCase() : 'RELEVANCE'
              }
              onChange={onSortTalent}>
              <FormControlLabel
                control={
                  <Radio
                    value={item?.id}
                    size="small"
                    sx={{
                      padding: '2px 9px',
                      '&.Mui-checked': {
                        color: '#222'
                      }
                    }}
                  />
                }
                label={item.title}
                key={`${item.title} - ${index}`}
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: 14,
                    letterSpacing: '0.1px'
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: 20
                  }
                }}
              />
            </RadioGroup>
          );
        })}
      </div>
    </Grid>
  );
};

const textFieldProps = onSearchKeyword => {
  return {
    placeholder: 'Search by Keywords',
    style: {
      backgroundColor: Colors.priWhite,
      letterSpacing: '0.15px',
      '& .MuiInputBase-root': {
        color: '#222222'
      },
      '& .MuiFormLabel-root': {
        top: 0
      }
    },
    endAdornment: (
      <>
        <InputAdornment
          position="end"
          onClick={onSearchKeyword}
          sx={{
            '& :hover': {
              cursor: 'pointer'
            }
          }}>
          <MagnifyingGlassIcon style={{ width: '18px', height: '18px' }} />
        </InputAdornment>
      </>
    )
  };
};
