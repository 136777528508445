export default function sortByStatus({ array }) {
  const statusOrder = ['In Review', 'Rejected', 'Live on Hiredly'];

  return array?.map(item => {
    const content = item?.content;

    if (content) {
      content.sort((a, b) => {
        const statusA = statusOrder.indexOf(a.status);
        const statusB = statusOrder.indexOf(b.status);
        return statusA - statusB;
      });
    }

    return item;
  });
}
