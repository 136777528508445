import { styled as styling } from '@mui/material/styles';
import { Button } from '@mui/material';

export const Container = styling('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2rem',
  flexDirection: 'column',
  gap: '16px',
  marginBottom: '32px',
  height: 'calc(100vh - 150px)',
});

export const Row = styling('div')({
  textAlign: 'center'
});

export const ButtonContainer = styling('div')({
  marginTop: '8px'
});

export const TitleText = styling('h2')({
  color: 'rgba(0, 0, 0, 0.87)',
  textAlign: 'center',
  fontSize: '25px',
  fontWeight: 700,
  lineHeight: '32px'
});

export const NormalText = styling('p')({
  color: 'rgba(0, 0, 0, 0.87)',
  textAlign: 'center',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.024px',
  margin: '0'
});

export const ContactText = styling('p')({
  color: 'rgba(0, 0, 0, 0.60)',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  margin: '0',
  display: 'inline-flex',
  alignItems: 'center',
  gap: '5px',
  flexWrap: 'wrap',
  justifyContent: 'center'
});

export const ContactTextLink = styling(ContactText)({
  color: '#512ACC',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',

  '&:hover': {
    cursor: 'pointer',
    opacity: '0.8'
  }
});

export const TextBox = styling('div')({
  display: 'flex',
  gap: '16px',
  maxWidth: '630px',
  flexDirection: 'column'
});

export const BackButton = styling(Button)({
  display: 'flex',
  width: '250px',
  height: '48px',
  padding: '0 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '8px',
  background: '#2E2E2E',
  color: '#ffffff',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: 'normal',
  textTransform: 'none',

  '&:hover': {
    cursor: 'pointer',
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    background: '#2E2E2E !important'
  }
});
