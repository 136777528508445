import React from 'react';
import {
  Text,
  TableCellStyled,
  TableRowStyled,
  Spacer,
  CustomChip,
  InfoIconStyled,
  TooltipWrapper,
  RescheduledDot,
  CellContainer
} from './styles';
import moment from 'moment';
import CustomTooltip from '../../../../../components/CustomTooltip';
import { SharedButton } from '../SharedButton';
import { useDispatch } from 'react-redux';
import {
  updateExpiryDatesPopupContentIndex,
  updateRowProduct,
  updateSelectedProductName
} from '../../../../../redux/actions/job_action';
import store from '../../../../../redux/stores/store';
import { useNavigate } from 'react-router-dom';

const Row = ({
  item,
  productTitle,
  activationDate,
  daysRemaining,
  expiryDate,
  quantity,
  usableQuantity,
  showUseNowButton
}) => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const isNotActivated = moment(activationDate).isAfter(moment());

  const getProductTitle = productTitle => {
    if (productTitle.toLowerCase() === 'job slots +') {
      return 'Job Slot Plus';
    } else if (productTitle.toLowerCase() === 'job slots max') {
      return 'Job Slot Max';
    } else if (productTitle.toLowerCase() === 'job slots + 6 months') {
      return 'Job Slot Plus 6 Months';
    } else if (productTitle.toLowerCase() === 'job slots max 6 months') {
      return 'Job Slot Max 6 Months';
    } else {
      if (productTitle.toLowerCase().includes('job slots')) {
        // Job slots
        let months = moment(expiryDate).diff(moment(activationDate), 'months');

        if (productTitle == 'Prep Job Slots') {
          return 'Job Slots';
        } else if (months == 3) {
          return 'Job Slots (3 months)';
        } else if (months == 6) {
          return 'Job Slots (6 months)';
        } else {
          return 'Job Slots';
        }
      } else if (productTitle == 'Free Super Senior Jobs') {
        return 'Free Senior Posts';
      } else if (productTitle == 'Free Internship Tokens') {
        return 'Internship Tokens';
      } else {
        return productTitle;
      }
    }
  };

  const getDaysRemaining = () => {
    if (isNotActivated) {
      return 'Not activated';
    } else if (daysRemaining > 0) {
      return daysRemaining;
    } else {
      return 'Expired';
    }
  };

  const onClickUseNow = productTitle => {
    let cProductTitle = productTitle?.toLowerCase();

    dispatch(updateSelectedProductName(cProductTitle));

    if (
      cProductTitle == 'job slots' ||
      cProductTitle == 'prep job slots' ||
      cProductTitle == 'free internship tokens' ||
      cProductTitle == 'free super senior jobs' ||
      cProductTitle == 'job tokens' ||
      cProductTitle == 'job credits' ||
      cProductTitle == 'job slots +' ||
      cProductTitle == 'job slots max'
    ) {
      dispatch(updateExpiryDatesPopupContentIndex(0));
    } else if (
      cProductTitle == 'refresher tokens' ||
      cProductTitle == 'featured tokens' ||
      cProductTitle == 'spotlight jobs'
    ) {
      dispatch(updateExpiryDatesPopupContentIndex(3));
      store.getState().jobs.featurableJobs = [];
    } else if (
      cProductTitle == 'talent tokens' ||
      cProductTitle == 'talent search'
    ) {
      history('/talent-search');
    }
  };

  const onRescheduleJobSlot = () => {
    dispatch(updateExpiryDatesPopupContentIndex(2));
  };

  return (
    <>
      <TableRowStyled sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCellStyled
          component="th"
          scope="row"
          align="left"
          name={'job-slots'}>
          <CellContainer>
            <Text
              expired={daysRemaining <= 0}
              table_content={'true'}
              max_width={
                (getProductTitle(productTitle) == 'Job Slots (3 months)' ||
                  getProductTitle(productTitle) == 'Job Slots (6 months)') &&
                isNotActivated
                  ? '90px'
                  : 'auto'
              }>
              {getProductTitle(productTitle)}
            </Text>
            {getProductTitle(productTitle) == 'Job Slots' ? (
              <Spacer width={'50px'} />
            ) : (
              <Spacer width={'30px'} />
            )}
            {isNotActivated ? (
              <TooltipWrapper>
                <CustomChip expired={`${daysRemaining <= 0}`}>
                  <Text reschedule_chip={'true'} expired={daysRemaining <= 0}>
                    Rescheduled
                  </Text>
                  <Spacer width={'4px'} />
                  {/* <RescheduledDot expired={daysRemaining <= 0} /> */}
                  <InfoIconStyled
                    expired={`${daysRemaining <= 0}`}
                    reschedule="true"
                  />
                  <CustomTooltip
                    width={'280px'}
                    height={'auto'}
                    content={
                      <Text custom_tooltip="true">
                        {' '}
                        This job slot has been scheduled to activate on{' '}
                        <b>{moment(activationDate).format('DD MMM YYYY')}</b>
                      </Text>
                    }
                    origin={'expiry-dates-popup'}
                    top={'-80px'}
                    right={'-100px'}
                  />
                </CustomChip>
              </TooltipWrapper>
            ) : null}
          </CellContainer>
        </TableCellStyled>
        <TableCellStyled align="left">
          <Text expired={daysRemaining <= 0} table_content={'true'}>
            {usableQuantity}
          </Text>
        </TableCellStyled>
        <TableCellStyled name={'activation-date'} align="left">
          <Text expired={daysRemaining <= 0} table_content={'true'}>
            {moment(activationDate).format('DD MMM YYYY')}
          </Text>
        </TableCellStyled>
        <TableCellStyled name={'expiry-date'} align="left">
          <Text expired={daysRemaining <= 0} table_content={'true'}>
            {moment(expiryDate).format('DD MMM YYYY')}
          </Text>
        </TableCellStyled>
        <TableCellStyled name={'days-remaining'} align="left">
          <div style={{ display: 'flex' }}>
            <Text
              expiring={`${daysRemaining > 0 && daysRemaining <= 7}`}
              expired={daysRemaining <= 0}
              table_content={'true'}>
              {getDaysRemaining()}
            </Text>
            <TooltipWrapper>
              {isNotActivated && (
                <>
                  <InfoIconStyled not_activated="true" />
                  <CustomTooltip
                    width={'280px'}
                    height={'auto'}
                    content={
                      <Text custom_tooltip="true">
                        {' '}
                        This job slot is not activated as it has not reached its
                        activation date.
                      </Text>
                    }
                    origin={'expiry-dates-popup'}
                    top={'-80px'}
                    right={'-60px'}
                  />
                </>
              )}
            </TooltipWrapper>
          </div>
        </TableCellStyled>
        <TableCellStyled name={'button'} align="left">
          {isNotActivated ? (
            <SharedButton
              type="outline"
              padding={'8px auto'}
              min_width={'160px'}
              onClick={onRescheduleJobSlot}>
              Reschedule
            </SharedButton>
          ) : showUseNowButton ? (
            <SharedButton
              type="outline"
              padding={'8px auto'}
              min_width={'160px'}
              onClick={() => onClickUseNow(productTitle)}>
              Use Now
            </SharedButton>
          ) : null}
        </TableCellStyled>
      </TableRowStyled>
    </>
  );
};

export default Row;
