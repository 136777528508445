import React, { useCallback, useState } from 'react';
import { Grid, ImageList } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import CandidateCard from './CandidateCard/index';
import styles from './index.module.scss';
import SkeletonLoader from './SkeletonLoader';

let previousScrollTop = 0;

const useStyles = makeStyles({
  outerWrapper: {
    '&.MuiImageList-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  skeletonWrapper: {
    '&.MuiImageList-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '15px'
    }
  }
});

const CandidatesColumn = (
  {
    candidates,
    setCandidates,
    isSelected,
    setIsSelected,
    pageInfo,
    getJobApplicants,
    loading,
    isRefresh,
    scrollToTop,
    getCurrentCandidates,
    setCurrentCandidates,
    scrollToCardRef,
    handleScrollToCard,
    applicationStatus,
    windowHeight
  },
  ref
) => {
  const classes = useStyles();

  const handleScroll = e => {
    let scrollTop = e.target.scrollTop;

    if (scrollTop < previousScrollTop) {
      previousScrollTop = scrollTop;
    } else {
      previousScrollTop = scrollTop;
    }
  };

  const handleKeyDown = event => {
    if (event.keyCode == 38) {
      if (isSelected > 0) {
        setIsSelected(isSelected - 1);
        handleScrollToCard(isSelected, true);
      }
    } else if (event.keyCode == 40) {
      if (isSelected < candidates.length - 1) {
        setIsSelected(isSelected + 1);
        handleScrollToCard(isSelected, false);
      }
    } else {
      return;
    }
  };

  return (
    <div className={styles.candidateColumn}>
      <div className={styles.candidateContainer}>
        <div
          id="scrollableDiv"
          className={styles.scrollableDivContainer}
          onScroll={handleScroll}
          ref={scrollToCardRef}
          onKeyDown={handleKeyDown}>
          {(candidates.length === 0 &&
            loading &&
            applicationStatus !== 'interview' &&
            applicationStatus !== 'offer') ||
          isRefresh ? (
            <ImageList cols={1} className={classes.skeletonWrapper}>
              <SkeletonLoader customClassName={styles.skeletonWrapper} />
              <SkeletonLoader customClassName={styles.skeletonWrapper} />
              <SkeletonLoader customClassName={styles.skeletonWrapper} />
            </ImageList>
          ) : (
            <InfiniteScroll
              style={{ overflow: 'hidden' }}
              dataLength={candidates?.length}
              next={() => {
                getJobApplicants();
              }}
              hasMore={pageInfo ? pageInfo.hasNextPage : true}
              scrollThreshold={0.9}
              scrollableTarget="scrollableDiv"
              loader={
                <ImageList cols={1} className={classes.skeletonWrapper}>
                  <SkeletonLoader customClassName={styles.skeletonWrapper} />
                </ImageList>
              }
              endMessage={
                <div>
                  {!pageInfo?.hasNextPage && !loading ? (
                    <p style={{ textAlign: 'center' }}>
                      <b>
                        {candidates.length == 0
                          ? 'No candidate found'
                          : 'End of Candidate List'}{' '}
                      </b>
                    </p>
                  ) : (
                    <ImageList cols={1} className={classes.skeletonWrapper}>
                      <SkeletonLoader
                        customClassName={styles.skeletonWrapper}
                      />
                    </ImageList>
                  )}
                </div>
              }>
              <ImageList cols={1} className={classes.outerWrapper}>
                {candidates?.map((item, index) => (
                  <CandidateCard
                    id={item?.node?.jobApplication?.id || item?.node?.id}
                    key={item?.node?.id}
                    user={item?.node?.user || item?.node?.jobApplication?.user}
                    candidate={item?.node}
                    isInterviewOrOffer={
                      item?.node?.jobApplication ? true : false
                    }
                    chatbotStatus={
                      item?.node?.chatbotStatus ||
                      item?.node?.jobApplication?.chatbotStatus
                    }
                    index={index}
                    status={
                      item?.node?.state || item?.node?.jobApplication?.state
                    }
                    isViewed={
                      item?.node?.status || item?.node?.jobApplication?.status
                    }
                    appliedAt={
                      item?.node?.appliedAt ||
                      item?.node?.jobApplication?.appliedAt
                    }
                    isSelected={isSelected === index}
                    setIsSelected={setIsSelected}
                    isLastItem={index === candidates.length - 1}
                    candidates={candidates}
                    setCandidates={setCandidates}
                    scrollToTop={scrollToTop}
                    getCurrentCandidates={getCurrentCandidates}
                    setCurrentCandidates={setCurrentCandidates}
                    windowHeight={windowHeight}
                    applicationStatus={applicationStatus}
                  />
                ))}
              </ImageList>
            </InfiniteScroll>
          )}
        </div>
        {/* )} */}
      </div>
    </div>
  );
};
export default CandidatesColumn;
