import { RegionDropdown } from 'react-country-region-selector';
import { styled as styling } from '@mui/material/styles';
import Colors from '../../styles/colors.module.scss';

export const RegionDropdownStyled = styling(RegionDropdown)((props) => ({
  width: '100%',
  height: '100%',
  borderRadius: '30px',
  padding: '18.5px 14px',
  fontFamily: 'Inter',
  background: 'none',
  lineHeight: '1.1876',
  letterSpacing: '0.00938em',
  cursor: 'pointer',
  fontSize: '1rem',
  color: 'rgba(0, 0, 0, 0.87)',
  border: '1px solid #e0e0e0',
  // border: '1px solid #AEAEAE',


  ...(props.origin == 'register_company' && props.error == 'true' && {
    padding: '9px 14px',
  }),

  ...(props.error == 'true' && {
    border: `1px solid ${Colors.priRed}`,
  }),
}));
