import React from 'react';
import { ButtonContainer, FormContainer, TitleText } from './styles';
import { MUIButtonStyled } from '../../../components/MUIButton';

export default function ForgotPasswordSuccess({ email, onResend, loading }) {
  return (
    <>
      <FormContainer>
        <div className='description'>
          <TitleText>We have sent you an email!</TitleText>
          <p className='mb-6'>
            The password reset link has been sent to the registered email address <b>{email}</b>.
          </p>
          <p className='note'>
            Note: Can’t find the email? Check your spam folder, or wait for 15 minutes before clicking the button below to resend the email.
          </p>
        </div>
      </FormContainer>
      <ButtonContainer>
        <MUIButtonStyled large label='Resend Email' onClick={onResend} loading={loading} />
      </ButtonContainer>
    </>
  );
}
