import { styled as styling } from '@mui/material/styles';
import { Grid, Typography, TextField } from '@mui/material';

export const ContainerStyled = styling(Grid)(props => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px'
}));

export const TitleWrapper = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  width: '100%',
  justifyContent: 'space-between'
}));

export const Title = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.87)`,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '20px'
}));

export const Desc = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.87)`,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const ViewWrapper = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '32px',
  width: '100%',
  justifyContent: 'space-between',
  padding: '16px',
  backgroundColor: 'white',
  borderRadius: '8px',
  border: `1px solid #AEAEAE`,
  //   maxHeight: "432px",
  overflow: 'hidden'
}));

export const StoryWrapper = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  width: '100%',
  paddingRight: '32px',

  overflowY: 'scroll',
  maxHeight: '400px',
  '&::-webkit-scrollbar': {
    width: '4px' /* Width of the scrollbar */
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#D8D8D8' /* Color of the track */
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#512ACC' /* Color of the thumb */,
    borderRadius: '5px' /* Rounded corners */
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555' /* Color of the thumb on hover */
  }
}));

export const StoryTitle = styling(Grid)(props => ({
  color: '#512ACC',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '20px'
}));

export const CoverTitle = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.87)`,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '20px',
  letterSpacing: '0.1px'
}));

export const CoverDesc = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.87)`,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const TextFieldStyled = styling(TextField)(
  ({ small, value, select, bold, white, error }) => ({
    width: '100%',
    '& .MuiInputBase-root': {
      fontSize: '0.85rem',
      color:
        value === 'placeholder' && select
          ? '#d8d8d8'
          : white
          ? 'white'
          : 'black', // Text color
      fontWeight: bold && 'bold',
      ...(small && {
        height: '45px',
        '& .MuiOutlinedInput-input': {
          '&::placeholder': {
            color: '#d8d8d8 !important',
            opacity: 1
          },
          paddingTop: '10px',
          paddingBottom: '5px',
          paddingLeft: '14px',
          paddingRight: '14px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
          top: 0
        }
      })
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        border: error ? `1px solid #F75443 !important` : `1px solid #D8D8D8`
      },
      '&:hover fieldset': {
        border: '1px solid #AEAEAE'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #512ACC'
      }
    },
    ...(small && {
      height: '45px'
    })
  })
);

export const TextFieldLargeStyled = styling(TextField)(({ small, bold }) => ({
  width: '100%',
  '& .MuiInputBase-root': {
    fontSize: '0.85rem',
    color: '#d8d8d8',
    fontWeight: bold && 'bold',
    ...(small && {
      height: '45px',
      '& .MuiOutlinedInput-input': {
        '&::placeholder': {
          color: '#d8d8d8 !important',
          opacity: 1
        },
        paddingTop: '10px',
        paddingBottom: '5px',
        paddingLeft: '14px',
        paddingRight: '14px'
      },
      '& .MuiOutlinedInput-notchedOutline': {
        top: 0
      }
    })
  },
  '& .MuiOutlinedInput-root': {
    border: '1px solid #D8D8D8',
    borderRadius: '8px',
    '& fieldset': {
      border: 'none'
      // borderRadius: '8px'
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #512ACC'
    }
  }
}));
