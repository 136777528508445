import React from 'react';
import { FilterTitle } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { updateSearchTalentFilters } from '../../../../redux/actions/talent_action';
import { FilterContainerStyle } from '../styles';
import { NATIONALITY_OPTIONS } from '../../../../components/SharedAdvancedFilter/constants';
import SharedFormRadioGroup from '../../../../components/SharedAdvancedFilter/SharedFormRadioGroup';
import { getLocalStorageItem } from '../../../../helpers/local_storage_utils';

const country = getLocalStorageItem('country');

const Nationality = () => {
  const dispatch = useDispatch();

  const filters = useSelector(state => state.talents.searchTalentFilters);

  const onNationality = value => {
    const cFilters = { ...filters };
    cFilters['nationalities'] = [value];
    dispatch(updateSearchTalentFilters(cFilters));
  };

  return (
    <FilterContainerStyle>
      <FilterTitle>Nationality</FilterTitle>

      <SharedFormRadioGroup
        style={{ margin: '0px 10px 0' }}
        value={filters['nationalities']}
        onChange={e => onNationality(e.target.value)}
        options={NATIONALITY_OPTIONS[country || 'MY']}
        radioProps={{
          size: 'small',
          sx: {
            '&.Mui-checked': {
              color: '#222'
            },
            padding: '2px 9px'
          }
        }}
        labelProps={{ style: { fontSize: '14px' } }}
        $type={'talent-search-page'}
      />
    </FilterContainerStyle>
  );
};

export default Nationality;
