import React, { useState, useEffect, useRef } from 'react';
import {
  FormStyle,
  StyledLabel,
  LearnMore,
  PreviewBox,
  LeftPart,
  RightPart,
  TextPart,
  TextBox,
  TextNotAvailable,
  StyledSubLabel,
  KeyHighlightDisplay,
  KeywordTokenToggle,
  SwitchStyled,
  BottomTagsSection,
  BottomTagsRow,
  BottomTag,
  BoltIconStyled,
  BottomTagText
} from './styles';
import { Radio } from '@mui/material';
import PopUpInfo from '../PopUpInfo';
import { accData } from '../../utils/LocalStorageUtils';
import { useDispatch, useSelector } from 'react-redux';
import { updateJobDataForm } from '../../redux/actions/job_action';

const FormRadio = ({
  name,
  options,
  selected,
  setSelected,
  setSelectedTitle,
  setSelectedExpiry,
  setSelectedToken,
  enableKeywordState,
  keywordHighlightStatus,
  keywordHighlightTokensUsableCount,
  onPurchaseButtonClicked,
  setHideKeyword,
  handleBackEdit,
  jobIsActive
}) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    content: '',
    title: '',
    modalTitle: ''
  });

  const handleLearnMoreClick = (id, content, title, modalTitle) => {
    setModalContent({ content, title, modalTitle });
    setModalOpen(true);

    // only pass CE on new job
    if (location.pathname.includes('/jobs/new-job')) {
      window.dataLayer.push({
        event: 'CE_click_info_button_new_job',
        item: id,
        company_name: accData?.companyName,
        industry: accData?.industryName
      });
    }
  };

  const handleOptionChange = (id, title, expiryDisplay, tokenCount) => {
    setSelected(id);
    setSelectedTitle(title);
    setSelectedExpiry(expiryDisplay);
    setSelectedToken(tokenCount);
  };

  // CE for reactivate job preview only
  const triggerCE = id => {
    if (location.pathname.includes('/jobs/new-job/job-type')) {
      window.dataLayer.push({
        event: 'CE_click_preview_new_job',
        item: id,
        company_name: accData?.companyName,
        industry: accData?.industryName
      });
    } else if (jobIsActive == false) {
      window.dataLayer.push({
        event: 'CE_click_preview_reactivate_job',
        item: id,
        company_name: accData?.companyName,
        industry: accData?.industryName
      });
    }
  };

  const getFirstValue = obj => {
    if (obj && Object.keys(obj).length > 0) {
      const firstKey = Object.keys(obj)[0];
      return obj[firstKey];
    }
    return null;
  };

  useEffect(() => {
    const initialSelectedIndex = options.findIndex(
      option => option.isUsing === true
    );

    if (initialSelectedIndex !== -1) {
      const initialSelectedOption = options[initialSelectedIndex];
      handleOptionChange(
        initialSelectedOption.id,
        initialSelectedOption.title,
        initialSelectedOption.expiryDisplay,
        initialSelectedOption.tokens
      );
    }
  }, []);

  const jobDataForm = useSelector(state => state?.jobs?.jobDataForm);

  const handleReduxChange = (key, value) => {
    dispatch(
      updateJobDataForm({
        ...jobDataForm,
        [key]: value
      })
    );
  };
  const showKeyword = jobDataForm?.enableKeywordHighlight;

  const resourceTypeRef = useRef(jobDataForm?.resourceType);
  const resourceType = jobDataForm?.resourceType;

  const handleKeyHighlightCheck = () => {
    if (enableKeywordState) {
      if (resourceType == 'job_slot_max' || resourceType == 'job_slot_max_6m') {
        handleReduxChange('enableKeywordHighlight', true);
      } else if (
        (resourceTypeRef?.current == 'job_slot_max' ||
          resourceTypeRef?.current == 'job_slot_max_6m') &&
        (resourceType !== 'job_slot_max' || resourceType !== 'job_slot_max_6m')
      ) {
        handleReduxChange('enableKeywordHighlight', false);
      }
    }
  };

  useEffect(() => {
    handleKeyHighlightCheck();
  }, [enableKeywordState, resourceType]);


  return (
    <>
      <FormStyle>
        {options.map((option, index) => {
          if (
            (option?.id === 'job_slot_6m' ||
              option?.id === 'job_slot_plus_6m' ||
              option?.id === 'job_slot_max_6m') &&
            option.tokens === 0
          ) {
            return null;
          }

          const target = selected === option.id;
          return (
            <>
              <StyledLabel
                key={option.id}
                optionKey={option.id}
                disabled={option.tokens == 0}
                isSelected={target}>
                <StyledSubLabel
                  isJobSlotMax={
                    (selected == 'job_slot_max' ||
                      selected == 'job_slot_max_6m') &&
                    (option.id == 'job_slot_max' ||
                      option.id == 'job_slot_max_6m') &&
                    keywordHighlightStatus <= 0
                  }>
                  <Radio
                    value={option.id}
                    disabled={option.tokens == 0}
                    checked={target}
                    onChange={() => {
                      handleReduxChange('resourceType', option.id);
                      handleOptionChange(
                        option.id,
                        option.title,
                        option.expiryDisplay,
                        option.tokens
                      );
                    }}
                    name={name}
                    color="default"
                    sx={{
                      color: '#AEAEAE',
                      padding: '4px 0px',
                      '&.Mui-checked': {
                        color: '#222'
                      },
                      '&.Mui-disabled': {
                        color: '#AEAEAE',
                        '& .MuiSvgIcon-root:nth-child(2)  path': {
                          fill: 'transparent'
                        }
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: '16px'
                      }
                    }}
                  />
                  <TextBox>
                    <TextPart>
                      <LeftPart>
                        <p>{option.title}</p>

                        {option.learnMore == 'true' && (
                          <LearnMore
                            onClick={() =>
                              handleLearnMoreClick(
                                option.id,
                                option.content,
                                option.title,
                                option?.modalTitle
                              )
                            }>
                            {/* <PuzzleIconStyled /> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none">
                              <path
                                d="M7.5 8L7.52766 7.98617C7.90974 7.79513 8.33994 8.14023 8.23634 8.55465L7.76366 10.4453C7.66006 10.8598 8.09026 11.2049 8.47234 11.0138L8.5 11M14 8.5C14 11.8137 11.3137 14.5 8 14.5C4.68629 14.5 2 11.8137 2 8.5C2 5.18629 4.68629 2.5 8 2.5C11.3137 2.5 14 5.18629 14 8.5ZM8 6H8.005V6.005H8V6Z"
                                stroke="#512ACC"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </LearnMore>
                        )}
                      </LeftPart>

                      <RightPart>
                        {option.tokens == 0 ? (
                          <PreviewBox
                            onClick={() => {
                              handleOptionChange(
                                option.id,
                                option.title,
                                option.expiryDisplay,
                                option.tokens
                              );

                              triggerCE(option.id);
                            }}>
                            Preview
                            {/* <KeyboardBackspaceIconStyled /> */}
                          </PreviewBox>
                        ) : (
                          <span>Expiring on {option.expiry}</span>
                        )}
                      </RightPart>
                    </TextPart>

                    {option.id == 'job_slot_max' ||
                    option.id == 'job_slot_max_6m' ||
                    option.id == 'job_slot_plus' ||
                    option.id == 'job_slot_plus_6m' ||
                    option.tokens == 0 ||
                    option.isUsing == true ? (
                      <>
                        <BottomTagsSection>
                          {(option.id == 'job_slot_max' ||
                            option.id == 'job_slot_max_6m' ||
                            option.id == 'job_slot_plus' ||
                            option.id == 'job_slot_plus_6m') && (
                            <>
                              <BottomTagsRow>
                                <BottomTag
                                  option={option.id}
                                  isDisabled={option.tokens == 0}>
                                  <BoltIconStyled
                                    isDisabled={
                                      option.tokens == 0
                                    }></BoltIconStyled>

                                  <BottomTagText
                                    isDisabled={option.tokens == 0}>
                                    {option.id == 'job_slot_max' ||
                                    option.id == 'job_slot_max_6m'
                                      ? '2X Performance'
                                      : '1.5X Performance'}
                                  </BottomTagText>
                                </BottomTag>

                                <BottomTag
                                  option={option.id}
                                  isDisabled={option.tokens == 0}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="15"
                                    viewBox="0 0 14 15"
                                    fill="none">
                                    <path
                                      d="M9.34661 5.95322H12.259V5.95218M1.74075 11.9592V9.04683M1.74075 9.04683L4.65313 9.04683M1.74075 9.04683L3.59627 10.9036C4.17408 11.4824 4.90736 11.9216 5.75422 12.1486C8.32152 12.8365 10.9604 11.3129 11.6483 8.7456M2.35127 6.25447C3.03917 3.68716 5.67805 2.16361 8.24535 2.85152C9.09221 3.07843 9.8255 3.51763 10.4033 4.09652L12.259 5.95218M12.259 3.04087V5.95218"
                                      stroke="black"
                                      stroke-opacity={
                                        option.tokens == 0 ? '0.38' : '0.6'
                                      }
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>

                                  <BottomTagText
                                    isDisabled={option.tokens == 0}>
                                    {option.id == 'job_slot_max' ||
                                    option.id == 'job_slot_max_6m'
                                      ? 'Auto-refresh every 7 days'
                                      : 'Auto-refresh every 14 days'}
                                  </BottomTagText>
                                </BottomTag>
                              </BottomTagsRow>
                            </>
                          )}

                          {option.tokens == 0 ? (
                            <TextNotAvailable>
                              You do not have any {option.title} available.
                            </TextNotAvailable>
                          ) : (
                            <></>
                          )}

                          {option.isUsing == true && (
                            <TextNotAvailable>
                              Currently in use.
                            </TextNotAvailable>
                          )}
                        </BottomTagsSection>
                      </>
                    ) : (
                      <></>
                    )}
                  </TextBox>
                </StyledSubLabel>

                {/* Selected job slot max but no highlight text */}
                {target &&
                  keywordHighlightStatus < 1 &&
                  (selected === 'job_slot_max' ||
                    selected === 'job_slot_max_6m') && (
                    <KeyHighlightDisplay
                      disabled={option.tokens == 0}
                      isSelected={selected === option.id}>
                      <KeywordTokenToggle>
                        <div>
                          No keywords have been added to the Key Highlights
                          section. For the full experience of Job Slot Max,
                          return to the{' '}
                          <a
                            onClick={handleBackEdit}
                            style={{
                              color: '#FFF',
                              textDecoration: 'underline',
                              cursor: 'pointer'
                            }}>
                            edit page
                          </a>{' '}
                          to add in your keywords.
                        </div>
                      </KeywordTokenToggle>
                    </KeyHighlightDisplay>
                  )}

                {/* Selected job slot plus with highlight text but no highlight token */}
                {target &&
                  keywordHighlightStatus > 0 &&
                  (selected === 'job_slot_plus' ||
                    selected === 'job_slot_plus_6m') && (
                    <KeyHighlightDisplay
                      disabled={option.tokens == 0}
                      isSelected={selected === option.id}>
                      <KeywordTokenToggle>
                        {keywordHighlightTokensUsableCount === 0 ? (
                          <div>
                            Purchase Key Highlights Token{' '}
                            <a
                              onClick={() => onPurchaseButtonClicked()}
                              style={{
                                color: '#FFF',
                                textDecoration: 'underline',
                                cursor: 'pointer'
                              }}>
                              here
                            </a>{' '}
                            to activate your keywords now!
                            <br />
                            <br />
                            <p
                              style={{
                                color: 'rgba(255, 255, 255, 0.60)',
                                fontStyle: 'italic',
                                margin: '0',
                                fontSize: '12px',
                                fontWeight: '400'
                              }}>
                              Tip: You can activate this later with a token
                              after the job is posted.
                            </p>
                          </div>
                        ) : (
                          <>
                            {showKeyword ? (
                              <div>Apply one (1) Key Highlights Token.</div>
                            ) : (
                              <div>
                                You have added keywords,{' '}
                                <span style={{ fontWeight: 'bold' }}>
                                  toggle this on to activate it!
                                </span>
                              </div>
                            )}

                            <SwitchStyled
                              name="active"
                              checked={
                                jobDataForm?.enableKeywordHighlight === true
                              }
                              onChange={() => {
                                handleReduxChange(
                                  'enableKeywordHighlight',
                                  !jobDataForm?.enableKeywordHighlight
                                );
                                setHideKeyword(
                                  !jobDataForm?.enableKeywordHighlight
                                );
                              }}
                              $width={120}
                              $height={45}
                              $thumbWidth={32}
                              $thumbHeight={32}
                            />
                          </>
                        )}
                      </KeywordTokenToggle>
                    </KeyHighlightDisplay>
                  )}
              </StyledLabel>
            </>
          );
        })}
      </FormStyle>

      {modalOpen && (
        <PopUpInfo
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
          content={modalContent}
        />
      )}
    </>
  );
};

export default FormRadio;
