import React, { useEffect, useMemo, useRef, useState } from 'react';
import AshleyEdit from '../../../../assets/images/jobs/ashley_edit.gif';
import { HeightSpacer } from '../../../../components/Spacer/styles';
import { Container, Title, Wrapper, Text } from './styles';
import Colors from '../../../../styles/colors.module.scss';
import { MUIButtonStyled } from '../../../../components/MUIButton';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField
} from '@mui/material';
import {  useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendAshleyEditFeedback,
  updateAshleyEditFeedback
} from '../../../../redux/actions/job_action';
import store from '../../../../redux/stores/store';
import { debounce } from 'lodash';
import { ErrorToast } from '../../../../utils/ToastUtils';

const options = [
  { id: 'rewrite-more', text: 'Allow rewrite more than once' },
  { id: 'rewrite-result', text: 'Improve rewrite results' },
  { id: 'feature-profile', text: 'Add feature in Company Profile page' }
];

const AshleyEditFeedbackPopup = () => {
  const history = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  //useRef
  const ref = useRef(null);

  //useSelector
  const ashleyEditFeedback = useSelector(
    state => state.jobs.ashleyEditFeedback
  );

  //useState
  const [otherCheckbox, setOtherCheckbox] = useState(false);
  const [blur, setBlur] = useState(false);

  const memoizedOtherCheckbox = useMemo(() => otherCheckbox, [otherCheckbox]);

  const onClickSkip = () => {
    localStorage.setItem('hide-ashley-edit-feedback', true);

    if (params) {
      history(`/jobs/${params.id}/virtual-interview`);
      dispatch(updateAshleyEditFeedback([]));
    }
  };

  const onClickCheckbox = (e, id) => {
    const cAshleyEditFeedback = store.getState().jobs.ashleyEditFeedback;

    if (cAshleyEditFeedback.map(el => el.id).indexOf(id) > -1) {
      const tArray = cAshleyEditFeedback.filter(item => {
        return item.id != id;
      });

      dispatch(updateAshleyEditFeedback(tArray));
      return;
    }

    dispatch(
      updateAshleyEditFeedback([
        ...cAshleyEditFeedback,
        { id: id, title: e.target.name }
      ])
    );
  };

  const onClickOtherFeedback = () => {
    setOtherCheckbox(true);
  };

  const onChangeOtherCheckbox = () => {
    setOtherCheckbox(prev => !prev);
  };

  const onChangeOtherFeedback = debounce(e => {
    const cAshleyEditFeedback = store.getState().jobs.ashleyEditFeedback;

    let index = cAshleyEditFeedback.findIndex(item => {
      return item.id == 'other-feedback';
    });

    if (index > -1) {
      cAshleyEditFeedback[index].title = e.target.value;
      dispatch(updateAshleyEditFeedback(cAshleyEditFeedback));

      return;
    }

    dispatch(
      updateAshleyEditFeedback([
        ...cAshleyEditFeedback,
        { id: 'other-feedback', title: e.target.value }
      ])
    );
  }, 500);

  const getDisable = () => {
    if (ashleyEditFeedback.length <= 0) return true;

    if (
      !memoizedOtherCheckbox &&
      ashleyEditFeedback.map(el => el.id).indexOf('other-feedback') > -1 &&
      ashleyEditFeedback.length < 2
    )
      return true;
  };

  const onSubmit = () => {
    if (store.getState().jobs.sendingAEFeedback) return;

    //keep title value only
    const newAshleyEditFeedback = ashleyEditFeedback.map(({ title }) => title);

    const cParams = {
      jobId: params.id,
      texts: newAshleyEditFeedback
    };

    dispatch(sendAshleyEditFeedback(cParams)).then(res => {
      if (res.success) {
        localStorage.setItem('hide-ashley-edit-feedback', true);
        history(`/jobs/${params.id}/virtual-interview`);
      } else {
        ErrorToast(res.error);
      }

      dispatch(updateAshleyEditFeedback([]));
    });
  };

  useEffect(() => {
    if (memoizedOtherCheckbox) {
      setTimeout(() => {
        ref.current.focus();
      }, 150);
    }
  }, [memoizedOtherCheckbox]);

  useEffect(() => {
    //untick the checkbox when there's no text
    const index = ashleyEditFeedback.findIndex(item => {
      return item.id == 'other-feedback';
    });

    if (index == -1 && blur) {
      setOtherCheckbox(false);
      return;
    }

    if (index > -1 && ashleyEditFeedback[index].title?.length <= 0 && blur) {
      setOtherCheckbox(false);
    }
  }, [blur]);

  return (
    <Wrapper>
      <Container>
        <Grid display={'flex'} justifyContent="center">
          <Title>Your feedback is important to us!</Title>
        </Grid>

        <HeightSpacer height={'24px'} />

        <img
          alt="ashley-edit"
          style={{
            width: '100%',
            height: '239px',
            outline: `1px solid ${Colors.terLightGrey}`,
            borderRadius: '8px'
          }}
          src={AshleyEdit}
        />
        <HeightSpacer height={'24px'} />
        <Text type="question">
          Let us know how we can improve the new{' '}
          <span style={{ fontWeight: '700', color: Colors.priPurple }}>
            Ashley’s Edit
          </span>{' '}
          feature.
        </Text>

        <HeightSpacer height={'16px'} />

        <FormControl component="fieldset">
          {options.map(option => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    color="default"
                    checked={
                      ashleyEditFeedback.map(el => el.id).indexOf(option.id) >
                      -1
                    }
                    sx={{
                      '&.Mui-checked': {
                        color: '#222'
                      }
                    }}
                    onChange={e => onClickCheckbox(e, option.id)}
                    name={option.text}
                  />
                }
                label={<Text type="checkbox">{option.text}</Text>}
              />
            );
          })}

          <FormControlLabel
            sx={{
              '.MuiFormControlLabel-label': {
                width: '100%'
              }
            }}
            control={
              <Checkbox
                size="small"
                color="default"
                checked={memoizedOtherCheckbox}
                sx={{
                  '&.Mui-checked': {
                    color: '#222'
                  }
                }}
                onChange={onChangeOtherCheckbox}
              />
            }
            label={
              <TextField
                sx={{ width: '100%' }}
                inputRef={ref}
                variant="standard"
                placeholder="Other feedback"
                disabled={!memoizedOtherCheckbox}
                onClick={onClickOtherFeedback}
                onChange={onChangeOtherFeedback}
                onFocus={() => {
                  setBlur(false);
                }}
                onBlur={() => {
                  setBlur(true);
                }}
              />
            }
          />
        </FormControl>

        <HeightSpacer height={'32px'} />

        <Grid display="flex" gap="50px" justifyContent={'center'}>
          <MUIButtonStyled
            label={'Skip this step'}
            $padding={'6px 24px'}
            $outline={`2px solid ${Colors.priBlack}`}
            $backgroundColor={Colors.priWhite}
            $fontSize={'16px'}
            $width={'200px'}
            $color={Colors.priBlack}
            onClick={onClickSkip}
          />
          <MUIButtonStyled
            label={'Submit'}
            $padding={'6px 24px'}
            $backgroundColor={
              getDisable() ? Colors.terLightGrey : Colors.priBlack
            }
            $fontSize={'16px'}
            $width={'200px'}
            $newHoverEffect
            disabled={getDisable()}
            onClick={onSubmit}
          />
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default AshleyEditFeedbackPopup;
