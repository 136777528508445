import { styled as styling } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

  export const TextFieldStyled = styling(TextField)(
    ({ theme, small, value, select, bold, white, name, isError }) => ({
      width: "100%",
      // Adjust the padding of the input field to change its height
      '& .MuiInputBase-root': {
      
        fontSize: '1rem',
        color:
          value == 'placeholder' 
            ? '#bdbdbd'
            : white
            ? 'white'
            : 'black', // Text color
        fontWeight: bold && 'bold',

        '& .MuiOutlinedInput-input': {
            '&::placeholder': {
                color: '#d8d8d8 !important', // Change to desired placeholder color
                opacity: 1 // Ensure the placeholder is fully visible
            },
        },
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: '8px',
          borderColor: white ? 'white' : isError ? '#BE4242': '#D8D8D8' // Border color
        },
        '&:hover fieldset': {
          borderColor: white ? 'white' : isError ? '#BE4242': 'black' // Border color on hover
        },
        '&.Mui-focused fieldset': {
          borderColor: white ? 'white' : '#D8D8D8' // Border color on focus
        }
      },
    })
  );
