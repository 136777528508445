import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { debounce } from 'lodash';
import { TextInputFunction } from '../SharedTextInput/SharedTextInput';
import { useDispatch, useSelector } from 'react-redux';
import {
  TalentSearchChatContainer,
  TalentSearchChatText,
  Title,
  LabelStyled,
  JobCardSection,
  SortByContainer,
  CardsContainer,
  CardStyled,
  CardContentStyled,
  TextContainer,
  PurpleUnreadIndicator
} from './styles';
import SkeletonLoader from './SkeletonLoader';
import { SwitchStyled } from '../../Jobs/JobList/Switch/styles';
import {
  fetchTalentChats,
  updateTalentChats,
  updateTalentSearchChatStatus
} from '../../../redux/actions/chat_action';
import { fetchJobs } from '../../../redux/actions/job_action';
import { useNavigate } from 'react-router-dom';

const sortingConstant = {
  UNREAD_DESC: {
    key: 'UNREAD_DESC',
    label: 'Unread Chat First',
    sortBy: 'unread_chat',
    order: 'desc'
  },

  CREATED_DESC: {
    key: 'CREATED_DESC',
    label: 'Most Recent Job Created',
    sortBy: 'created_at',
    order: 'desc'
  },

  CREATED_ASC: {
    key: 'CREATED_ASC',
    label: 'Earliest Job Created',
    sortBy: 'created_at',
    order: 'asc'
  }
};

function getTrackTitle(data) {
  const titles = data?.map(item => item?.title);
  const combinedTitles = titles?.join(' / ');
  return combinedTitles;
}

const JobCardSectionComponent = props => {
  const { loadJobApplications, updateChatChannel } = props;
  const dispatch = useDispatch();
  const history = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [currentJobId, setCurrentJobId] = useState('');
  const [orderDirection, setOrderDirection] = useState(
    sortingConstant.UNREAD_DESC.key
  );
  const [searchJobArrowDrop, setSearchJobsArrowDrop] = useState(null);
  const [orderChanged, setOrderChanged] = useState(false);
  const pushRoute = history.action === 'PUSH';

  // Job settings
  const [jobSettings, setJobSettings] = useState({
    first: 10,
    offset: 0,
    orders: {
      by: sortingConstant.UNREAD_DESC.sortBy,
      direction: sortingConstant.UNREAD_DESC.order,
      label: sortingConstant.UNREAD_DESC.label
    }
  });

  // Selectors
  const jobsList = useSelector(state => state.jobs.jobs);
  const fetchingJobs = useSelector(state => state.jobs.fetchingJobs);
  const totalJobsCount = useSelector(state => state.jobs.totalJobsCount.active);
  const shownAll = totalJobsCount === jobs.length;

  const jobUnreadChatCount = useSelector(state => state.chats.jobUnread);
  const jobUnreadChatId = useSelector(state => state.chats.jobUnreadId);
  const isTalentSearchChat = useSelector(
    state => state.chats.isTalentSearchChat
  );

  // Load job function
  const fetchJobFunction = (currentJobTitle = '') => {
    dispatch(
      fetchJobs({
        first: jobSettings.first,
        offset: jobSettings.offset,
        jobTitle: currentJobTitle,
        isActive: true,
        orders: jobSettings.orders
      })
    ).then(res => {
      const currentJobs = res.jobs.edges.map(item => item?.node);
      setJobs([...jobs, ...currentJobs]);
      if (jobsList) {
        window.dataLayer.push({
          event: 'CE_search-job-title-chats',
          'search-term': currentJobTitle,
          'sort-by': `${jobSettings.orders.by}_${jobSettings.orders.direction}`
        });
      }
    });
  };

  useEffect(() => {
    if (pushRoute) {
      setCurrentJobId(jobs[0]?.id);
      loadJobApplications(0, jobs[0]?.id);
    }
  }, [jobs]);

  useEffect(() => {
    if (jobs[0]?.id === currentJobId) return;
    setCurrentJobId(jobs[0]?.id);
    loadJobApplications(0, jobs[0]?.id);
  }, [jobs]);

  // useEffect(() => {
  //   if (!fetchingJobs) {
  //     setJobs(prev => [...prev, ...jobsList]);
  //   }

  //   if (pushRoute) {
  //     setJobs(jobsList);
  //   }
  // }, [jobsList, fetchingJobs]);

  useEffect(() => {
    if (orderChanged) {
      setJobs([]);
    }

    fetchJobFunction();
    setShowSkeleton(false);
    setOrderChanged(false);
  }, [jobSettings]);

  // Perform Search
  const debouncedFetchJobFunction = useCallback(
    debounce(currentJobTitle => {
      setJobs([]);
      fetchJobFunction(currentJobTitle);
    }, 700),
    []
  );

  const handleChangeSearchJobs = event => {
    const currentJobTitle = event.target.value;
    debouncedFetchJobFunction(currentJobTitle);
  };

  const handleJobClicked = jobId => {
    if (currentJobId === jobId) return;

    setCurrentJobId(jobId);
    loadJobApplications(0, jobId);
  };

  const handleScroll = e => {
    //  Reference: https://stackoverflow.com/questions/45585542/detecting-when-user-scrolls-to-bottom-of-div-with-react-js/49573628
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom && !fetchingJobs && !shownAll) {
      // if joblist has 10 jobs, means there might be more, can load
      // if joblist has less than 10, means no more left
      if (jobsList.length === 10) {
        // offset: always will be 0, 10, 20, if not it wont even hit the function
        let first;
        const minusAmount = totalJobsCount - jobs.length;
        // if minusAmount has remainder means its the last batch
        // if minusAmount has no remainder means that is not the last batch
        if (totalJobsCount % 10 !== 0) {
          first = 10;
        } else {
          first = minusAmount;
        }

        setJobSettings({
          ...jobSettings,
          first: first,
          offset: jobs.length
        });
      }
    }
  };

  const handleSearchJobsArrowDropClick = event => {
    setSearchJobsArrowDrop(event.currentTarget);
  };

  const handleCloseOrderMenu = value => {
    setSearchJobsArrowDrop(null);

    if (value !== '' && value !== orderDirection) {
      setOrderChanged(true);
      setShowSkeleton(true);
      setOrderDirection(value);

      setJobSettings({
        ...jobSettings,
        first: 10,
        offset: 0,
        orders: {
          by: sortingConstant[value].sortBy,
          direction: sortingConstant[value].order,
          label: sortingConstant[value].label
        }
      });
    }
  };

  const onTalentSearchToggle = event => {
    if (event?.target) {
      dispatch(updateTalentSearchChatStatus(event.target.checked));

      if (event.target.checked) {
        const params = {
          keyword: '',
          after: '',
          sort: {
            by: 'unread_chat',
            direction: 'desc'
          },
          refresh: true
        };

        dispatch(updateTalentChats([])).then(() => {
          dispatch(fetchTalentChats(params));
        });
      }
    }
  };

  // Talent search toggle
  useEffect(() => {
    if (!isTalentSearchChat && jobs?.length > 0) {
      loadJobApplications(0, currentJobId);
    } else if (isTalentSearchChat) {
      updateChatChannel('');
    }
  }, [isTalentSearchChat]);

  useEffect(() => {
    if (jobs?.length > 0 && jobUnreadChatCount >= 0) {
      let _jobs = jobs;

      let targetJobIndex = _jobs.findIndex(item => item.id === jobUnreadChatId);

      _jobs[targetJobIndex].unreadJobApplicationChatCount = jobUnreadChatCount;

      setJobs(_jobs);
    }
  }, [jobUnreadChatCount]);

  return (
    <JobCardSection item lg={3} md={6} sm={6} xs={12} onScroll={handleScroll}>
      <TalentSearchChatContainer>
        <TalentSearchChatText>Talent Search Chat</TalentSearchChatText>
        <SwitchStyled
          switchOnText={''}
          switchOffText={''}
          width={46}
          height={24}
          thumbWidth={18}
          thumbHeight={18}
          margin={-1}
          checked={isTalentSearchChat}
          onChange={onTalentSearchToggle}
          trackTransform={'translateX(22px) !important'}
        />
      </TalentSearchChatContainer>
      {!isTalentSearchChat && (
        <>
          <TextInputFunction
            type="text"
            label="jobs-search-bar"
            placeholder="Search by job title"
            handleChange={handleChangeSearchJobs}
          />
          <SortByContainer>
            <LabelStyled sort_by="true">
              Sort by <b>{jobSettings.orders.label}</b>
            </LabelStyled>
            <ArrowDropDownIcon
              fontSize="medium"
              style={{ marginLeft: '5px', cursor: 'pointer' }}
              onClick={handleSearchJobsArrowDropClick}
            />
            <Menu
              id="date-created-menu"
              anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              anchorEl={searchJobArrowDrop}
              keepMounted
              getContentAnchorEl={null}
              open={Boolean(searchJobArrowDrop)}
              onClose={() => handleCloseOrderMenu('')}
              // TransitionComponent={Fade}
            >
              <MenuItem
                onClick={() =>
                  handleCloseOrderMenu(sortingConstant.UNREAD_DESC.key)
                }
                selected={orderDirection === sortingConstant.UNREAD_DESC.key}>
                {sortingConstant.UNREAD_DESC.label}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleCloseOrderMenu(sortingConstant.CREATED_DESC.key)
                }
                selected={orderDirection === sortingConstant.CREATED_DESC.key}>
                {sortingConstant.CREATED_DESC.label}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleCloseOrderMenu(sortingConstant.CREATED_ASC.key)
                }
                selected={orderDirection === sortingConstant.CREATED_ASC.key}>
                {sortingConstant.CREATED_ASC.label}
              </MenuItem>
            </Menu>
          </SortByContainer>
          <CardsContainer>
            {!showSkeleton &&
              jobs?.map(job => {
                return (
                  <CardStyled
                    key={job.id}
                    elevation={0}
                    active={currentJobId === job.id ? 'true' : 'false'}
                    onClick={() => handleJobClicked(job.id)}>
                    {(jobUnreadChatId === job.id
                      ? jobUnreadChatCount
                      : parseInt(job.unreadJobApplicationChatCount)) > 0 && (
                      <PurpleUnreadIndicator>
                        {parseInt(job.unreadJobApplicationChatCount) > 99
                          ? `99+`
                          : parseInt(job.unreadJobApplicationChatCount)}
                      </PurpleUnreadIndicator>
                    )}

                    <CardContentStyled>
                      <TextContainer>
                        <Title>{job.title}</Title>
                        <Grid>
                          <LabelStyled card_info="true">
                            {getTrackTitle(job.tracks)}
                          </LabelStyled>
                          <LabelStyled card_info="true">
                            {job.jobType.title}
                          </LabelStyled>
                        </Grid>
                      </TextContainer>
                    </CardContentStyled>
                  </CardStyled>
                );
              })}
          </CardsContainer>
          {!fetchingJobs && (shownAll || jobs.length < 10) && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              End of list.
            </div>
          )}
          {fetchingJobs &&
            Array.from(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']).map(
              index => <SkeletonLoader key={index} />
            )}
        </>
      )}
    </JobCardSection>
  );
};

export default JobCardSectionComponent;
