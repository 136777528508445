import React from 'react';
import {
  EmptyContainer,
  SkeletonChipStyled,
  SkeletonStyled,
  TopWrapper,
  CenterWrapper,
  BottomWrapper,
  MainWrapper,
  DividerStyled,
  UnstyledContainer
} from './styles';

export const FilterSkeleton = () => {
  return (
    <MainWrapper container direction="column" flexWrap="wrap">
      <TopSkeleton />
      <DividerStyled />
      <CenterSkeleton />
      <DividerStyled />
      <BottomSkeleton />
    </MainWrapper>
  );
};

const TopSkeleton = () => {
  return (
    <TopWrapper container justifyContent="space-between">
      <SkeletonStyled variant="rounded" sx={{ width: '30%', height: '16px' }} />
    </TopWrapper>
  );
};

const CenterSkeleton = () => {
  return (
    <CenterWrapper container direction="row">
      <UnstyledContainer container direction="column" width="93%">
        <SkeletonStyled
          variant="rounded"
          sx={{ width: '45%', height: '16px' }}
        />

        <EmptyContainer container gap="8px" marginTop="18px">
          <SkeletonStyled
            variant="rounded"
            sx={{ width: '100%', height: '12px' }}
          />
          <SkeletonStyled
            variant="rounded"
            sx={{ width: '10%', height: '12px' }}
          />
        </EmptyContainer>

        <EmptyContainer
          container
          direction="column"
          justifyContent="space-between"
          gap="24px"
          marginTop="24px">
          <SkeletonStyled
            variant="rounded"
            sx={{ width: '40%', height: '16px' }}
          />

          <SkeletonStyled
            variant="rounded"
            sx={{ width: '60%', height: '16px' }}
          />
        </EmptyContainer>

        <EmptyContainer
          container
          direction="column"
          justifyContent="space-between"
          gap="10px"
          marginTop="16px">
          <SkeletonStyled
            variant="rounded"
            sx={{ width: '35%', height: '14px' }}
          />
          <SkeletonStyled
            variant="rounded"
            sx={{ width: '30%', height: '14px' }}
          />
          <SkeletonStyled
            variant="rounded"
            sx={{ width: '27%', height: '14px' }}
          />
          <SkeletonStyled
            variant="rounded"
            sx={{ width: '35%', height: '14px' }}
          />
        </EmptyContainer>

        <EmptyContainer container direction="column" gap="8px" marginTop="30px">
          <SkeletonStyled
            variant="rounded"
            sx={{ width: '30%', height: '16px' }}
          />
          <SkeletonStyled
            variant="rounded"
            sx={{ width: '100%', height: '32px' }}
          />
          <SkeletonStyled
            variant="rounded"
            sx={{ width: '30%', height: '14px' }}
          />
        </EmptyContainer>

        <EmptyContainer container direction="column" gap="8px" marginTop="30px">
          <SkeletonStyled
            variant="rounded"
            sx={{ width: '30%', height: '16px' }}
          />
          <SkeletonStyled
            variant="rounded"
            sx={{ width: '100%', height: '32px' }}
          />
          <SkeletonStyled
            variant="rounded"
            sx={{ width: '30%', height: '14px' }}
          />
        </EmptyContainer>
      </UnstyledContainer>

      <UnstyledContainer
        container
        direction="column"
        width="7%"
        alignItems="flex-end">
        <SkeletonStyled
          variant="rounded"
          sx={{ width: '4px', height: '100%' }}
        />
      </UnstyledContainer>
    </CenterWrapper>
  );
};

const BottomSkeleton = () => {
  return (
    <BottomWrapper container direction="row" justifyContent="space-between">
      <SkeletonChipStyled
        variant="rounded"
        sx={{ width: '40%', height: '30px' }}
      />
      <SkeletonChipStyled
        variant="rounded"
        sx={{ width: '40%', height: '30px' }}
      />
    </BottomWrapper>
  );
};
