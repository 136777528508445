import { useDispatch } from 'react-redux';

export const convertAndDownloadResume = (res, user) => {
  if (res?.resume?.resume) {
    const byteCharacters = Buffer.from(res.resume.resume, 'base64').toString(
      'binary'
    );

    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: 'application/pdf' });

    saveAs(
      blob,
      `${user.name ? user.name.replaceAll(' ', '_') : user.id}_cv${
        user.resume.includes('pdf') ? '.pdf' : '.doc'
      }`
    );
  }
};
