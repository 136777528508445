import { Divider, IconButton, Grid, Typography } from '@mui/material';
import { styled as styling } from '@mui/material/styles';

export const PaginationContainer = styling('div')({
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const IconButtonStyled = styling(IconButton)(({ disabled }) => ({
  color: '#222222',
  ...(disabled && {
    color: 'rgba(0, 0, 0, 0.38)',
    cursor: 'default',
  }),
}));

export const PaginationNumbersContainer = styling('div')({
  display: 'flex',
  alignItems: 'center',
});

export const NumberContainer = styling('div')(({ $selected }) => ({
  padding: '5px 5px',
  marginInline: '4px',

  ...($selected && {
    borderRadius: '4px',
    marginInline: '6px'
  }),
}));

export const TypographyStyled = styling(Typography)(({ 
  $selected, 
  $perPageText 
}) => ({
  fontSize: '16px',
  color: 'rgba(0, 0, 0, 0.38)',
  cursor: 'pointer',
  ...($selected && {
    fontWeight: 700,
    color: '#222222',
  }),
  ...($perPageText && {
    fontSize: '12px',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 1)',
  }),
}));

