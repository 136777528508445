import React, { Fragment } from 'react';
import { SwitchStyled } from './styles';

const Switch = ({
  checked,
  onChange,
  switchOnText,
  switchOffText,
  width,
  height,
  thumbWidth,
  thumbHeight
}) => {
  const label = { inputProps: { 'aria-label': 'Size switch demo' } };
  return (
    <Fragment>
      <SwitchStyled
        id="active-button"
        checked={checked}
        onChange={onChange}
        switchOnText={switchOnText}
        switchOffText={switchOffText}
        width={width}
        height={height}
        thumbWidth={thumbWidth}
        thumbHeight={thumbHeight}
      />
      {/* <MUISwitch
        id="active-button"
        classes={classes}
        checked={checked}
        onChange={onChange}
      /> */}
    </Fragment>
  );
};

export default Switch;
