import React, { Fragment, useEffect, useRef, useState } from 'react';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import {
  TableContainerStyled,
  TableRowStyled,
  Text,
  TableCellStyled,
  TableStyled,
  MenuStyled,
  MoreVertIconStyled,
  LinkIconStyled,
  ViewJobIconStyled,
  Spacer,
  IconTextContainer,
  JobStatusCard,
  JobStatusCardWrapper,
  DividerStyled,
  HeightSpacer,
  SocialPopover,
  CustomIconContainer,
  SocialIconsContainer,
  ShareButtonContainer,
  DangerTagCotainer,
  CautiousTagContainer,
  NewApplicantsContainer,
  SuperJobTagCotainer,
  GlobalHireTagCotainer
} from './styles';
import { SharedButton } from '../SharedButton';
import {
  Edit as EditIcon,
  PersonAdd as PersonAddIcon,
  Share as ShareIcon
} from '@mui/icons-material';
import { IconButton, MenuItem, Tooltip } from '@mui/material';
import { ReactComponent as AiviIcon } from '../../../../assets/common/aivi-icon.svg';
import { ReactComponent as JobTrackIcon } from '../../../../assets/icons/job-list/travel-case.svg';
import { ReactComponent as ExpiredAtIcon } from '../../../../assets/icons/job-list/expired.svg';
import { ReactComponent as CreatedAtIcon } from '../../../../assets/icons/job-list/add-to-queue.svg';
import { ReactComponent as RenewalIcon } from '../../../../assets/icons/job-list/auto-renew.svg';
import { ReactComponent as JobTypeIcon } from '../../../../assets/icons/job-list/time.svg';
import { ReactComponent as EmptyContentIcon } from '../../../../assets/icons/job-list/no-posting.svg';
import ViewJobIcon from '../../../../assets/common/view-job-icon.png';
import Switch from '../Switch';
import moment from 'moment';
import { openInNewTab } from '../../../../utils/WindowUtils';
import { useNavigate } from 'react-router-dom';
import JobAccessPopUpNew from '../../PopUps/JobAccessPopUpNew';

import { useSelector, useDispatch } from 'react-redux';
import {
  updateJobAccessPopup,
  updateRefresherJobPopup,
  updateVirtualInterviewPopup,
  updateFeaturedJobPopup,
  updateDeactivateJobPopup,
  updateActivateJobPopup,
  updateActivateJobOptionPopup,
  updateJobOptionPopup,
  updateSelectedFilter,
  updateSelectedFilterValue,
  updateJob,
  updateJobDataForm,
  updatePostJobPopup,
  updateProductTypeIndex
} from '../../../../redux/actions/job_action';
import VirtualInterviewPopUpNew from '../../PopUps/VirtualInterviewPopUpNew';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from 'react-share';
import { ErrorToast, SuccessToast } from '../../../../utils/ToastUtils';
import store from '../../../../../src/redux/stores/store';
import RefreshJobPopUp from '../../PopUps/RefreshJobPopUp';
import FeatureJobPopUp from '../../PopUps/FeatureJobPopUp';
import ActivateJobPopUp from '../../PopUps/ActivateJobPopUp';
import JobTypePopUp from '../../PopUps/JobTypePopUp';
import DeactivateJobPopUpNew from '../../PopUps/DeactivateJobPopUpNew';
import { makeStyles } from '@mui/styles';
import JobView from './JobView';
import VisibilityBooster from './VisualEnhancer';
import KeywordHighlightPopup from '../../PopUps/KeywordHighlightPopUp';
import SpotlightJobConfirmationPopUp from '../../PopUps/SpotlightJobConfirmationPopUp';
import KeywordHighlightConfirmationPopUp from '../../PopUps/KeywordHighlightConfirmationPopUp';
import JobOptionPopUpHome from '../../PopUps/JobOptionPopUpHome';
import { isNumber } from 'lodash';
import { getNationality } from '../../../../hooks/useRegionalisation';
import { JOBSEEKER_URL } from '../../../../utils/Constants';
import CrossBorderJobPopUp from '../../PopUps/CrossBorderJobPopUp';

const useStyles = makeStyles({
  menuItem: {
    width: '100%',

    '&.MuiMenuItem-root': {
      padding: '5px 15px 5px 15px'
    },
    '&.MuiButtonBase-root': {
      justifyContent: 'flex-start'
    }
  }
});

const JobListTable = ({
  jobList,
  getDiffInMin,
  productData,
  getCompanyAccountInfo
}) => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const textRef = useRef();

  const hasRefreshToken = productData?.refresherTokensCount > 0 ? true : false;
  const isJobAccessOpen = useSelector(state => state.jobs.isJobAccessOpen);
  const isVirtualInterviewOpen = useSelector(
    state => state.jobs.isVirtualInterviewOpen
  );
  const isRefreshJobOpen = useSelector(state => state.jobs.isRefreshJobOpen);
  const isFeaturedJobOpen = useSelector(state => state.jobs.isFeaturedJobOpen);
  const isDeactivateJobOpen = useSelector(
    state => state.jobs.isDeactivateJobOpen
  );
  const isActivateJobOpen = useSelector(state => state.jobs.isActivateJobOpen);
  const isActivateJobOptionOpen = useSelector(
    state => state.jobs.isActivateJobOptionOpen
  );
  const isJobOptionOpen = useSelector(state => state.jobs.isJobOptionOpen);
  const totalJobsCount = useSelector(state => state.jobs.totalJobsCount);

  //Visibility booster
  const isKeywordHighlightOpen = useSelector(
    state => state.jobs.isKeywordHighlightOpen
  );
  const myCompanyStatus = store.getState().companies?.myCompanyStatus;
  const changingJobStatus = useSelector(state => state.jobs.changingJobStatus);

  const [anchorEl, setAnchorEl] = useState(null);
  const [jobId, setJobId] = useState('');
  const [job, setJob] = useState({});

  const jobIndex = jobList.findIndex(item => {
    return item?.id == jobId;
  });

  const onHorizDotsOpen = (event, job) => {
    setJobId(job?.id);
    setAnchorEl(event.currentTarget);
  };

  const onHorizDotsClose = () => {
    setAnchorEl(null);
  };

  const onRefreshJob = job => {
    if (!job.enableRefreshJob || !job?.active) {
      return;
    }

    window.dataLayer.push({
      event: 'CE_click-visibility-booster-refresh-job'
    });

    setJobId(job?.id);
    dispatch(updateRefresherJobPopup(true));
  };

  const onFeaturedJob = job => {
    if ((!job?.enableFeatureJob && job?.boostedExpired) || !job?.active) {
      return;
    }

    window.dataLayer.push({
      event: 'CE_click-visibility-booster-feature-job'
    });

    setJobId(job?.id);
    dispatch(updateFeaturedJobPopup(true));
  };

  // TODO: maybe can create a hook
  const isCompulsoryFormDataFilled = job => {
    if (
      job['careerLevel'] == null ||
      Object.keys(job['careerLevel'])?.length <= 0
    ) {
      return false;
    } else if (job['title']?.length <= 0) {
      return false;
    } else if (job['location']?.length <= 0) {
      return false;
    } else if (
      job['stateRegion'] == null ||
      Object.keys(job['stateRegion'])?.length <= 0
    ) {
      return false;
    } else if (job['description']?.length <= 0) {
      return false;
    } else if (job['requirements']?.length <= 0) {
      return false;
    } else if (job['skills']?.length <= 0) {
      return false;
    } else if (job['tracks']?.length <= 0) {
      return false;
    } else if (Object.keys(job['jobType'])?.length <= 0) {
      return false;
    }

    return true;
  };

  const onToggleSwitch = job => {
    if (changingJobStatus) {
      return;
    }

    // to navigate employer to job form if required fields are mepty
    if (!isCompulsoryFormDataFilled(job) && !job?.active) {
      if (job?.id.length > 0) {
        history(`/jobs/${job?.id}`);
      }

      return;
    }

    setJobId(job?.id);
    setJob(job);

    if (job?.active) {
      dispatch(updateDeactivateJobPopup(true));
    } else if (
      (job?.jobType?.title == 'Full-Time' &&
        job?.minYearExperience == 10 &&
        productData?.availableFreeSuperSeniorPostCount > 0) ||
      (job?.jobType?.title == 'Internship' &&
        productData?.availableInternshipsTokenCount > 0)
    ) {
      dispatch(updateJobOptionPopup(true));
    } else {
      dispatch(updateActivateJobOptionPopup(true));
    }
  };

  const showExtendFeature = job => {
    let isShowExtendFeature;

    const extendFeatureDaysLeft = moment(job?.boostedExpired).diff(
      moment(),
      'days'
    );

    isShowExtendFeature =
      extendFeatureDaysLeft === 0 || extendFeatureDaysLeft === 1;

    return isShowExtendFeature;
  };

  const onSelectJobStatus = (status, job, disabled) => {
    if (disabled == true) {
      return;
    }

    localStorage.setItem('applicationStatus', status);

    openApplicantsPage(job);
  };

  const disableCard = count => {
    if (count <= 0) {
      return true;
    } else {
      return false;
    }
  };

  function getTrackTitle(data) {
    const titles = data?.map(item => item?.title);

    const combinedTitles = titles?.join(' / ');

    return combinedTitles;
  }

  const handleActiveTabNavigation = () => {
    // dispatch(updateChipStatusIndex(1));
    dispatch(updateSelectedFilterValue('active'));
    dispatch(updateSelectedFilter(true));
  };

  const handleInactiveTabNavigation = () => {
    // dispatch(updateChipStatusIndex(2));
    dispatch(updateSelectedFilterValue('inactive'));
    dispatch(updateSelectedFilter(false));
  };

  const handlePostNewJobNavigation = () => {
    dispatch(updatePostJobPopup(true));
    dispatch(updateProductTypeIndex(''));
    dispatch(updateJobDataForm({}));
    dispatch(updateJob({}));

    // dispatch(updateProductTypeIndex(''));
    // history('/jobs/add/new-job');
  };

  const getTimer = job => {
    const res = getDiffInMin(job);

    if (res == -1) return '';

    if (res == 60) return '1 Hour';
    return `${res} Minute${res > 1 ? 's' : ''}`;
  };

  const openApplicantsPage = item => {
    openInNewTab(
      `/manage-candidates/${item?.id}/${
        item?.localOnly ? `?${getNationality().toLowerCase()}=true` : ''
      }`
    );

    return;
  };

  const navigateToApplicantsPage = job => {
    if (job?.totalCount <= 0) {
      return;
    }

    openApplicantsPage(job);
  };

  const showExpiringTag = item => {
    const diff = moment(item?.expiredAt).diff(moment(), 'days');

    if (diff > 0 && diff <= 7) {
      return true;
    } else {
      return false;
    }
  };

  let tagName;
  const showPlusMaxTag = item => {
    if (item?.resourceType == 'job_slot_max') {
      tagName = 'Job Slot Max';
      return true;
    } else if (item?.resourceType == 'job_slot_plus') {
      tagName = 'Job Slot Plus';
      return true;
    } else if (item?.resourceType == 'job_slot_max_6m') {
      tagName = 'Job Slot Max';
      return true;
    } else if (item?.resourceType == 'job_slot_plus_6m') {
      tagName = 'Job Slot Plus';
      return true;
    } else {
      tagName = '';
      return false;
    }
  };

  const hasExpired = item => {
    if (item?.expiredAt) {
      if (moment().isAfter(moment(item?.expiredAt))) {
        return true;
      }
    }

    return false;
  };

  const getExpireMessage = item => {
    if (hasExpired(item)) {
      return 'Expired on';
    }

    return 'Expiring on';
  };

  const onViewApplicants = item => {
    const isApplicationStatusExist = localStorage.getItem('applicationStatus');

    if (isApplicationStatusExist) {
      localStorage.setItem('applicationStatus', 'all');
    }

    openApplicantsPage(item);
  };

  useEffect(() => {
    if (jobList.length > 0 && jobIndex > -1) {
      dispatch(updateJob(jobList[jobIndex]));
    }
  }, [jobList, jobIndex]);

  const EmptyState = () => {
    return (
      <div style={{ margin: '50px 0 100px 0' }}>
        <EmptyContentIcon />
        <Text content_title="true">No job postings found </Text>
        <HeightSpacer height={'5px'} />
        <Text table_content={'true'}>
          Start creating job postings to attract great talent into your team!
        </Text>
        <HeightSpacer height={'20px'} />
        <SharedButton
          width={'250px'}
          type={'basic'}
          font_size={'16px'}
          padding={'6px 38px'}
          onClick={handlePostNewJobNavigation}>
          Post New Job
        </SharedButton>
      </div>
    );
  };

  return (
    <>
      <TableContainerStyled component={Paper}>
        <TableStyled aria-label="job-list">
          <TableBody>
            {jobList?.map(item => {
              return (
                <Fragment key={item?.id}>
                  <TableRowStyled
                    row_type="body"
                    sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCellStyled component="th" scope="row">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%'
                        }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap'
                          }}>
                          <Text
                            content_title={'true'}
                            cursor={
                              item?.totalCount <= 0 ? 'default' : 'pointer'
                            }
                            color={item?.totalCount > 0 ? 'hover-purple' : null}
                            onClick={() => navigateToApplicantsPage(item)}>
                            {item?.title}
                          </Text>
                          <Spacer width={'20px'} />
                          {item?.active ? (
                            <>
                              {showPlusMaxTag(item) ? (
                                <SuperJobTagCotainer
                                  tagType={item?.resourceType}>
                                  <>
                                    {item?.resourceType == 'job_slot_plus' ||
                                    item?.resourceType == 'job_slot_plus_6m' ? (
                                      <>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none">
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M13.4997 4.93762C16.8478 6.87062 17.9949 11.1518 16.0619 14.4998C14.1289 17.8479 9.84775 18.995 6.4997 17.062C3.15166 15.129 2.00453 10.8479 3.93753 7.4998C4.10592 7.20813 4.29214 6.93316 4.49401 6.67548C4.69562 6.41812 5.08463 6.45704 5.28714 6.71368C5.56487 7.06565 5.88119 7.38577 6.22971 7.66764C6.56235 7.93667 7.01647 7.61943 7.00304 7.19183C7.00103 7.12812 7.00003 7.06416 7.00003 6.99997C7.00003 6.08143 7.20643 5.2111 7.57539 4.43282C8.10854 3.30822 8.98111 2.37583 10.0608 1.76798C10.3078 1.62893 10.6112 1.7522 10.7378 2.00584C11.3297 3.1927 12.2651 4.2248 13.4997 4.93762ZM14 12C14 14.2091 12.2092 16 10 16C8.08674 16 6.4791 14.6016 6.09017 12.8183C5.9966 12.3894 6.52967 12.1749 6.90396 12.4045C7.38998 12.7025 7.93731 12.8964 8.50538 12.9685C8.80801 13.0068 9.03609 12.7289 9.01482 12.4246C9.00501 12.2844 9.00002 12.1428 9.00002 12C9.00002 10.5731 9.49812 9.26254 10.3299 8.23269C10.4337 8.10417 10.599 8.04108 10.7612 8.07233C12.6063 8.4278 14 10.0511 14 12Z"
                                            fill="url(#paint0_linear_18173_6907)"
                                          />
                                          <defs>
                                            <linearGradient
                                              id="paint0_linear_18173_6907"
                                              x1="1.44272"
                                              y1="9.85546"
                                              x2="20.9023"
                                              y2="9.85546"
                                              gradientUnits="userSpaceOnUse">
                                              <stop
                                                offset="0.213542"
                                                stop-color="#6EC7CD"
                                              />
                                              <stop
                                                offset="0.776042"
                                                stop-color="#B0CD72"
                                              />
                                            </linearGradient>
                                          </defs>
                                        </svg>
                                      </>
                                    ) : (
                                      <>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none">
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M13.4997 4.93762C16.8478 6.87062 17.9949 11.1518 16.0619 14.4998C14.1289 17.8479 9.84775 18.995 6.4997 17.062C3.15166 15.129 2.00453 10.8479 3.93753 7.4998C4.10592 7.20813 4.29214 6.93316 4.49401 6.67548C4.69562 6.41812 5.08463 6.45704 5.28714 6.71368C5.56487 7.06565 5.88119 7.38577 6.22971 7.66764C6.56235 7.93667 7.01647 7.61943 7.00304 7.19183C7.00103 7.12812 7.00003 7.06416 7.00003 6.99997C7.00003 6.08143 7.20643 5.2111 7.57539 4.43282C8.10854 3.30822 8.98111 2.37583 10.0608 1.76798C10.3078 1.62893 10.6112 1.7522 10.7378 2.00584C11.3297 3.1927 12.2651 4.2248 13.4997 4.93762ZM14 12C14 14.2091 12.2092 16 10 16C8.08674 16 6.4791 14.6016 6.09017 12.8183C5.9966 12.3894 6.52967 12.1749 6.90396 12.4045C7.38998 12.7025 7.93731 12.8964 8.50538 12.9685C8.80801 13.0068 9.03609 12.7289 9.01482 12.4246C9.00501 12.2844 9.00002 12.1428 9.00002 12C9.00002 10.5731 9.49812 9.26254 10.3299 8.23269C10.4337 8.10417 10.599 8.04108 10.7612 8.07233C12.6063 8.4278 14 10.0511 14 12Z"
                                            fill="white"
                                            fill-opacity="0.87"
                                          />
                                        </svg>
                                      </>
                                    )}
                                  </>
                                  <Text tagType={item?.resourceType}>
                                    Powered by {tagName}
                                  </Text>
                                </SuperJobTagCotainer>
                              ) : null}

                              {item?.globalHireEnabled && (
                                <>
                                  <Spacer width={'10px'} />
                                  <GlobalHireTagCotainer>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none">
                                      <path
                                        d="M13.1248 6.875C13.47 6.875 13.7498 7.15482 13.7498 7.5C13.7498 8.43344 13.3398 9.27189 12.692 9.84359C12.4332 10.072 12.0383 10.0474 11.8099 9.78858C11.5814 9.52979 11.6061 9.13483 11.8649 8.90641C12.2552 8.56193 12.4998 8.05992 12.4998 7.5C12.4998 7.15482 12.7796 6.875 13.1248 6.875Z"
                                        fill="#707070"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M10 1.875C5.51269 1.875 1.875 5.51269 1.875 10C1.875 14.4873 5.51269 18.125 10 18.125C14.4873 18.125 18.125 14.4873 18.125 10C18.125 5.51269 14.4873 1.875 10 1.875ZM3.81242 13.0002C4.92663 15.2939 7.27867 16.875 10 16.875C10.5519 16.875 11.0886 16.81 11.6028 16.6872C11.3637 16.0503 10.7536 15.625 10.069 15.625C9.66853 15.625 9.32674 15.3355 9.2609 14.9404L9.2001 14.5756C9.12171 14.1053 9.40883 13.651 9.8673 13.5201L10.6912 13.2846C11.0473 13.1829 11.3443 12.9364 11.5099 12.6052L11.5403 12.5444C11.7454 12.1341 12.1647 11.875 12.6234 11.875C12.9446 11.875 13.2526 12.0026 13.4797 12.2297L13.75 12.5H14.2736C14.9724 12.5 15.6033 12.8872 15.9257 13.4881C16.5289 12.4655 16.875 11.2732 16.875 10C16.875 6.41817 14.1359 3.4761 10.638 3.15421C10.675 3.37335 10.789 3.57417 10.9623 3.71861L11.8528 4.4607C12.2209 4.76741 12.2985 5.30229 12.0327 5.70094L11.6069 6.33972C11.3756 6.68665 11.0352 6.94659 10.6397 7.07844L10.5212 7.11795C9.94392 7.31036 9.70765 7.99898 10.0452 8.50525C10.3531 8.96708 10.1865 9.59425 9.69004 9.84248L7.5 10.9375L7.85283 11.8196C8.00667 12.2042 7.84002 12.6425 7.46953 12.8277C7.12525 12.9999 6.70713 12.9012 6.47618 12.5932L5.91014 11.8385C5.49199 11.281 4.63329 11.3584 4.32162 11.9818L3.81242 13.0002Z"
                                        fill="#707070"
                                      />
                                    </svg>
                                    <Text tagType={'global_hire'}>
                                      Open to Global Hire
                                    </Text>
                                  </GlobalHireTagCotainer>
                                </>
                              )}

                              {showExpiringTag(item) ? (
                                <DangerTagCotainer>
                                  <Text count_down="true" color={'danger'}>
                                    Expiring in{' '}
                                    {moment(item?.expiredAt).diff(
                                      moment(),
                                      'days'
                                    )}{' '}
                                    days
                                  </Text>
                                </DangerTagCotainer>
                              ) : null}

                              {/* Only show featured until if boosted expired is not empty */}
                              {!item?.enableFeatureJob &&
                                item?.boostedExpired && (
                                  <>
                                    <Spacer width={'10px'} />
                                    <CautiousTagContainer>
                                      <Text
                                        count_down="true"
                                        color={'cautious'}>
                                        Featured Until{' '}
                                        {moment(item?.boostedExpired).format(
                                          'DD MMM YYYY'
                                        )}
                                      </Text>
                                    </CautiousTagContainer>
                                  </>
                                )}

                              {hasRefreshToken && isNumber(getTimer(item)) && (
                                <>
                                  <Spacer width={'10px'} />
                                  <CautiousTagContainer>
                                    <Text count_down="true" color={'cautious'}>
                                      Next Refresh in {getTimer(item)}
                                    </Text>
                                  </CautiousTagContainer>
                                </>
                              )}
                            </>
                          ) : null}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Tooltip
                            title={
                              item?.totalCount <= 0 &&
                              item?.enableFeatureJob ? (
                                <Text tooltip={'true'}>
                                  Feature your job to attract more applicants!
                                </Text>
                              ) : (
                                ''
                              )
                            }
                            placement="bottom">
                            <span>
                              <SharedButton
                                custom_label={'view-applicants'}
                                type={'basic'}
                                font_size={'16px'}
                                padding={'6px 38px'}
                                id="view-applicant-button"
                                disabled={item?.totalCount <= 0 ? true : false}
                                onClick={() => onViewApplicants(item)}>
                                View Applicants
                              </SharedButton>
                            </span>
                          </Tooltip>
                          <div style={{ width: '8px' }} />
                          <IconButton
                            onClick={event => onHorizDotsOpen(event, item)}>
                            <MoreVertIconStyled id="job-settings-icon" />
                          </IconButton>
                        </div>
                      </div>

                      <HeightSpacer height={'25px'} />

                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <IconTextContainer>
                          <ExpiredAtIcon />
                          <Spacer width={'10px'} />
                          <Text iconText="true">
                            {getExpireMessage(item)}{' '}
                            {moment(item?.expiredAt).format('DD MMM YYYY')}
                          </Text>
                        </IconTextContainer>

                        {/* <Spacer width={'4%'} /> */}

                        <IconTextContainer>
                          <CreatedAtIcon />
                          <Spacer width={'10px'} />
                          <Text iconText="true">
                            Created on{' '}
                            {moment(item?.createdAt).format('DD MMM YYYY')}
                          </Text>
                        </IconTextContainer>

                        {/* <Spacer width={'4%'} /> */}

                        <IconTextContainer>
                          <JobTrackIcon />
                          <Spacer width={'10px'} />
                          <Text iconText="true">
                            {getTrackTitle(item?.tracks)}
                          </Text>
                        </IconTextContainer>

                        {/* <Spacer width={'4%'} /> */}

                        <IconTextContainer>
                          <JobTypeIcon />
                          <Spacer width={'10px'} />
                          <Text iconText="true">{item?.jobType.title}</Text>
                        </IconTextContainer>

                        {/* <Spacer width={'4%'} /> */}

                        <IconTextContainer>
                          <RenewalIcon />
                          <Spacer width={'10px'} />
                          <Text iconText="true">
                            {item?.renewalCount} Renewals
                          </Text>
                        </IconTextContainer>
                      </div>

                      <JobStatusCardWrapper>
                        <JobStatusCard
                          disabled={disableCard(item?.totalCount)}
                          onClick={() =>
                            onSelectJobStatus(
                              'all',
                              item,
                              disableCard(item?.totalCount)
                            )
                          }>
                          {item?.unviewedUndecidedCount > 0 && (
                            <NewApplicantsContainer>
                              <Text new_applicants="true">
                                {item?.unviewedUndecidedCount} new
                              </Text>
                            </NewApplicantsContainer>
                          )}
                          <Text
                            status_count="true"
                            disabled={disableCard(item?.totalCount)}>
                            {item?.totalCount}
                          </Text>
                          <Text
                            job_status="true"
                            disabled={disableCard(item?.totalCount)}>
                            Applicants
                          </Text>
                        </JobStatusCard>
                        <DividerStyled />
                        <JobStatusCard
                          disabled={disableCard(item?.undecidedCount)}
                          onClick={() =>
                            onSelectJobStatus(
                              'undecided',
                              item,
                              disableCard(item?.undecidedCount)
                            )
                          }>
                          <Text
                            status_count="true"
                            disabled={disableCard(item?.undecidedCount)}>
                            {item?.undecidedCount}
                          </Text>
                          <Text
                            job_status="true"
                            disabled={disableCard(item?.undecidedCount)}>
                            Undecided
                          </Text>
                        </JobStatusCard>
                        <DividerStyled />
                        <JobStatusCard
                          disabled={disableCard(item?.shortlistedCount)}
                          onClick={() =>
                            onSelectJobStatus(
                              'shortlisted',
                              item,
                              disableCard(item?.shortlistedCount)
                            )
                          }>
                          <Text
                            status_count="true"
                            disabled={disableCard(item?.shortlistedCount)}>
                            {item?.shortlistedCount}
                          </Text>
                          <Text
                            job_status="true"
                            disabled={disableCard(item?.shortlistedCount)}>
                            Shortlisted
                          </Text>
                        </JobStatusCard>
                        <DividerStyled />
                        <JobStatusCard
                          disabled={disableCard(item?.kivCount)}
                          onClick={() =>
                            onSelectJobStatus(
                              'kiv',
                              item,
                              disableCard(item?.kivCount)
                            )
                          }>
                          <Text
                            status_count="true"
                            disabled={disableCard(item?.kivCount)}>
                            {item?.kivCount}
                          </Text>
                          <Text
                            job_status="true"
                            disabled={disableCard(item?.kivCount)}>
                            KIV
                          </Text>
                        </JobStatusCard>
                        <DividerStyled />
                        <JobStatusCard
                          disabled={disableCard(item?.interviewCount)}
                          onClick={() =>
                            onSelectJobStatus(
                              'interview',
                              item,
                              disableCard(item?.interviewCount)
                            )
                          }>
                          <Text
                            status_count="true"
                            disabled={disableCard(item?.interviewCount)}>
                            {item?.interviewCount}
                          </Text>
                          <Text
                            job_status="true"
                            disabled={disableCard(item?.interviewCount)}>
                            Interview
                          </Text>
                        </JobStatusCard>
                        <DividerStyled />
                        <JobStatusCard
                          disabled={disableCard(item?.offerCount)}
                          onClick={() =>
                            onSelectJobStatus(
                              'offer',
                              item,
                              disableCard(item?.offerCount)
                            )
                          }>
                          <Text
                            status_count="true"
                            disabled={disableCard(item?.offerCount)}>
                            {item?.offerCount}
                          </Text>
                          <Text
                            job_status="true"
                            disabled={disableCard(item?.offerCount)}>
                            Offer
                          </Text>
                        </JobStatusCard>
                      </JobStatusCardWrapper>

                      <div
                        style={{
                          display: 'flex',
                          marginTop: '30px',
                          justifyContent: 'space-between',
                          flexWrap: 'wrap'
                        }}>
                        <div style={{ display: 'flex' }}>
                          <JobView job={item} />
                          <VisibilityBooster
                            onFeaturedJob={onFeaturedJob}
                            onRefreshJob={onRefreshJob}
                            job={item}
                            getDiffInMin={getDiffInMin}
                            setJobId={setJobId}
                            textRef={textRef}
                          />
                        </div>
                        <Spacer width={'50px'} />
                        <Switch
                          jobList
                          key={item?.id}
                          checked={item?.active}
                          onChange={() => onToggleSwitch(item)}
                          name="job_status"
                          origin="job_column"
                          switchOnText="Active"
                          switchOffText="Inactive"
                          width={120}
                          height={45}
                          thumbWidth={32}
                          thumbHeight={32}
                        />
                      </div>
                    </TableCellStyled>
                  </TableRowStyled>
                </Fragment>
              );
            })}
          </TableBody>
        </TableStyled>

        {totalJobsCount['active'] <= 0 &&
          store.getState().jobs.selectedFilterValue == 'active' && (
            <EmptyState />
          )}

        {totalJobsCount['inactive'] <= 0 &&
          store.getState().jobs.selectedFilterValue == 'inactive' && (
            <EmptyState />
          )}

        {totalJobsCount['active'] <= 0 &&
          totalJobsCount['inactive'] <= 0 &&
          store.getState().jobs.selectedFilterValue == 'all' && <EmptyState />}
      </TableContainerStyled>

      <MenuSelection
        anchorEl={anchorEl}
        onHorizDotsClose={onHorizDotsClose}
        job={jobList[jobIndex]}
        setAnchorEl={setAnchorEl}
      />

      <JobAccessPopUpNew
        open={isJobAccessOpen}
        handleClose={() => dispatch(updateJobAccessPopup(false))}
        jobId={jobId}
      />

      <VirtualInterviewPopUpNew
        useAsPopUp={true}
        open={isVirtualInterviewOpen}
        handleClose={() => dispatch(updateVirtualInterviewPopup(false))}
        jobId={jobId}
      />
      <RefreshJobPopUp jobId={jobId} jobTitle={jobList[jobIndex]?.title} />
      <FeatureJobPopUp
        jobId={jobId}
        jobTitle={jobList[jobIndex]?.title}
        jobData={jobList[jobIndex]}
      />

      <CrossBorderJobPopUp
        jobId={jobId}
        jobTitle={jobList[jobIndex]?.title}
        jobData={jobList[jobIndex]}
      />

      <SpotlightJobConfirmationPopUp
        jobId={jobId}
        jobTitle={jobList[jobIndex]?.title}
        jobData={jobList[jobIndex]}
      />
      <KeywordHighlightConfirmationPopUp
        jobId={jobId}
        jobTitle={jobList[jobIndex]?.title}
        jobData={jobList[jobIndex]}
      />
      <ActivateJobPopUp
        open={isActivateJobOpen}
        handleClose={() => dispatch(updateActivateJobPopup(false))}
        jobId={jobId}
        jobData={job}
        productData={productData}
        getCompanyAccountInfo={getCompanyAccountInfo}
      />

      <JobTypePopUp
        open={isActivateJobOptionOpen}
        handleClose={() => {
          dispatch(updateActivateJobOptionPopup(false));
          dispatch(updateJobDataForm({}));
          dispatch(updateJob({}));
        }}
        jobId={jobId}
        jobData={job}
        productData={productData}
        // setSwitchStatus={setSwitchStatus}
        getCompanyAccountInfo={getCompanyAccountInfo}
      />

      <JobOptionPopUpHome
        open={isJobOptionOpen}
        handleClose={() => dispatch(updateJobOptionPopup(false))}
        handleNextPage={() => dispatch(updateActivateJobOptionPopup(true))}
        jobId={jobId}
        jobData={job}
        productData={productData}
        getCompanyAccountInfo={getCompanyAccountInfo}
      />

      <DeactivateJobPopUpNew
        open={isDeactivateJobOpen}
        handleClose={() => dispatch(updateDeactivateJobPopup(false))}
        jobId={jobId}
        jobData={job}
        creationOrigin={
          jobList[jobIndex]?.creationOrigin
            ? jobList[jobIndex]?.creationOrigin
            : ''
        }
        getCompanyAccountInfo={getCompanyAccountInfo}
      />
      {isKeywordHighlightOpen && <KeywordHighlightPopup textRef={textRef} />}
    </>
  );
};

const MenuSelection = ({ anchorEl, onHorizDotsClose, job, setAnchorEl }) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  const isMasterAccount =
    localStorage.getItem('currentAccountType') === 'master';
  const isCompanyApproved =
    store.getState().companies.myCompanyStatus.isApproved;
  const teamMembers =
    store.getState().companies.myCompanySettings?.companyTeamMembers;
  const jobsCurrentPage = useSelector(state => state.jobs.jobsCurrentPage);
  const dropdownCountry = useSelector(state => state.companies.country);

  const shareUrl = `${JOBSEEKER_URL[dropdownCountry]}/jobs/${job?.slug}`;

  const [socialIconsAnchorEl, setSocialIconsAnchorEl] = useState(null);

  const onViewJobseekerJob = () => {
    setAnchorEl(null);
    openInNewTab(`${JOBSEEKER_URL[dropdownCountry]}/jobs/${job?.slug}`);
  };

  const onEditJob = () => {
    setAnchorEl(null);
    history(`/jobs/${job?.id}?page=${jobsCurrentPage}`);
  };

  const onEditJobAccess = () => {
    setAnchorEl(null);
    dispatch(updateJobAccessPopup(true));
  };

  const onEditVirtualInterview = () => {
    setAnchorEl(null);
    dispatch(updateVirtualInterviewPopup(true));
  };

  const triggerSocialIcons = event => {
    setSocialIconsAnchorEl(event.currentTarget);
  };

  const handleSocialIconsClose = () => {
    setSocialIconsAnchorEl(null);
  };

  return (
    <>
      <MenuStyled
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        getContentAnchorEl={null}
        onClose={onHorizDotsClose}>
        <MenuItem onClick={onViewJobseekerJob} className={classes.menuItem}>
          <ViewJobIconStyled link={ViewJobIcon} />
          <Spacer width={'15px'} />
          <div>View Job</div>
        </MenuItem>

        <MenuItem
          onClick={onEditJob}
          id="manage-jobs-edit-job-button"
          className={classes.menuItem}>
          <EditIcon style={{ fontSize: '20px' }} />
          <Spacer width={'15px'} />
          <div>Edit Job</div>
        </MenuItem>

        {isMasterAccount && isCompanyApproved && teamMembers?.length > 0 && (
          <MenuItem
            className={classes.menuItem}
            id="manage-jobs-assign-job-access-button"
            onClick={onEditJobAccess}>
            <PersonAddIcon style={{ fontSize: '20px' }} />
            <Spacer width={'15px'} />
            <div>Assign Job Access</div>
          </MenuItem>
        )}

        <MenuItem
          className={classes.menuItem}
          id="manage-jobs-edit-virtual-interviewer-button"
          onClick={onEditVirtualInterview}>
          <AiviIcon width={18} style={{ transform: 'translateX(3px)' }} />
          <Spacer width={'15px'} />
          <div> Edit Virtual Interviewer</div>
        </MenuItem>

        <MenuItem
          id="share-button"
          onClick={triggerSocialIcons}
          className={classes.menuItem}>
          <ShareIcon
            style={{ fontSize: '20px', transform: 'translateX(3px)' }}
          />
          <Spacer width={'15px'} />
          <div> Share</div>
        </MenuItem>
      </MenuStyled>

      <SocialIcons
        setAnchorEl={setAnchorEl}
        socialIconsAnchorEl={socialIconsAnchorEl}
        handleSocialIconsClose={handleSocialIconsClose}
        job={job}
        shareUrl={shareUrl}
      />
    </>
  );
};

const SocialIcons = ({
  socialIconsAnchorEl,
  handleSocialIconsClose,
  job,
  shareUrl,
  setAnchorEl
}) => {
  const CustomShareIcon = () => {
    // Write social share to clipboard
    const CopyToClipboard = async () => {
      if (!navigator.clipboard) {
        // Clipboard API not available
        return;
      }
      const text = `${job?.title}: ${shareUrl}`;
      try {
        await navigator.clipboard.writeText(text);
        SuccessToast('Link copied successfully.');
      } catch (err) {
        ErrorToast('Failed to copy!');
      }
      handleSocialIconsClose();
      setAnchorEl(null);
    };

    return (
      <>
        <CustomIconContainer id={'copy-job-link-sharing-button'}>
          <LinkIconStyled onClick={CopyToClipboard} />
        </CustomIconContainer>
      </>
    );
  };

  return (
    <div>
      <SocialPopover
        open={Boolean(socialIconsAnchorEl)}
        anchorEl={socialIconsAnchorEl}
        onClose={handleSocialIconsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        style={{ marginTop: '14px' }}>
        <ShareButtonContainer>
          <SocialIconsContainer>
            <CustomShareIcon id="copy-share-job-button" />
          </SocialIconsContainer>

          <SocialIconsContainer>
            <FacebookShareButton
              id="facebook-share-job-button"
              url={shareUrl}
              quote={job?.title}>
              <FacebookIcon size={28} round />
            </FacebookShareButton>
          </SocialIconsContainer>
          <SocialIconsContainer>
            <TwitterShareButton
              id="twitter-share-job-button"
              url={shareUrl}
              title={job?.title}>
              <TwitterIcon size={28} round />
            </TwitterShareButton>
          </SocialIconsContainer>
          <SocialIconsContainer>
            <LinkedinShareButton
              id="linkedin-share-job-button"
              url={shareUrl}
              title={job?.title}>
              <LinkedinIcon size={28} round />
            </LinkedinShareButton>
          </SocialIconsContainer>
          <SocialIconsContainer>
            <WhatsappShareButton
              id="whatsapp-share-job-button"
              url={shareUrl}
              title={job?.title}
              separator=": ">
              <WhatsappIcon size={28} round />
            </WhatsappShareButton>
          </SocialIconsContainer>
          <SocialIconsContainer>
            <EmailShareButton
              id="email-share-job-button"
              url={shareUrl}
              subject={job?.title}>
              <EmailIcon size={28} round />
            </EmailShareButton>
          </SocialIconsContainer>
        </ShareButtonContainer>
      </SocialPopover>
    </div>
  );
};

export default JobListTable;
