import useEditLogic from './useEditLogic';
import useDefaultLogic from './useDefaultLogic';
import useSeniorLogic from './useSeniorLogic';
import useInternLogic from './useInternLogic';

export default function useSubmitLogic(jobId) {
  if (jobId === 'intern-job') {
    return useInternLogic({ confirm: true });
  } else if (jobId === 'senior-job') {
    return useSeniorLogic({ confirm: true });
  } else if (jobId === 'new-job') {
    return useDefaultLogic({ confirm: true });
  } else if (jobId === 'draft-job') {
    return useDefaultLogic({ confirm: true, jobId: jobId });
  } else {
    return useEditLogic({ confirm: true });
  }
}
