import React from 'react';
import { StyledButton } from './styles';

const getHoverStyle = props => {
  if (props.$newHoverEffect) {
    return {
      '&:hover': {
        background: props.$newHoverBackground || '#40249B !important'
      }
    };
  } else if (props.outlinedBlack) {
    return {
      boxShadow: 2,
      '&:hover': {
        backgroundColor: '#EFEFEF !important',
      }
    };
  } else if (props.$outlineWidth) {
    return {
      '&:hover': {
        outlineWidth: props.$outlineWidth
      }
    };
  }
  return {
    boxShadow: 2,
    '&:hover': {
      boxShadow: 4
    }
  };
};

export const MUIButtonStyled = ({ sx, ...props }) => (
  <StyledButton
    {...props}
    sx={{ ...getHoverStyle(props), ...sx }}
    onMouseEnter={props.onMouseIn}
    onMouseLeave={props.onMouseOut}>
    {props.label}
  </StyledButton>
);
