import React from 'react';
import { Done } from '@mui/icons-material';
import s from './index.module.scss';

export default function CheckList({ list, ...props }) {
  return (
    <div className={s.listContainer} {...props}>
      {list.map(text => (
        <div key={text} className={s.listItem}>
          <span className={s.listCheck}>
            <Done />
          </span>
          <span className={s.listText}>{text}</span>
        </div>
      ))}
    </div>
  );
}
