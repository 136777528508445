import { Button, styled } from '@mui/material';

const getBackgroundColor = props => {
  if (props.disabled) return '#D8D8D8 !important';
  if (props.$backgroundColor) return props.$backgroundColor;
  if (props.outlinedBlack || props.outlinedPurple) return '#ffffff !important';
  if (props.solidBlack) return '#222222 !important';
  if (props.solidWhite) return '#ffffff !important';
  return '#512acc !important';
};

const getColor = props => {
  if (props.disabled) return '#ffffff !important';
  if (props.$color) return props.$color;
  if (props.outlinedBlack || props.solidWhite) return '#222222 !important';
  return '#ffffff !important';
};

const getBorder = props => {
  return props.outlinedBlack ? '2px solid !important' : '';
};

const getWidth = props => {
  if (props.$width) return props.$width;
  if (props.large) return '200px';
  if (props.medium) return '150px';
  return 'auto';
};

const getHeight = props => {
  if (props.$height) return props.$height;
  return 'auto';
};

export const StyledButton = styled(Button)(({ theme, ...props }) => ({
  backgroundColor: getBackgroundColor(props),
  color: getColor(props),
  border: getBorder(props),
  borderRadius: props.$borderRadius ? props.$borderRadius : '30px !important',
  textTransform: 'none !important',
  padding: props.$padding ? props.$padding : '6px 18px 6px 18px !important',
  fontWeight: '700 !important',
  fontSize: props.$fontSize ? props.$fontSize : '16px !important',
  width: getWidth(props),
  boxShadow: 'none !important',
  height: getHeight(props),
  outline: props.$outline && props.$outline
}));
