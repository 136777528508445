import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import Grid from '@mui/material/Grid';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import CardContent from '@mui/material/CardContent';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SendIcon from '@mui/icons-material/Send';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Title,
  LabelStyled,
  ChatInfo,
  TextIconContainer,
  MenuStyled,
  StatusIndicator,
  MessageContainer,
  ChatContainer,
  MessageContent,
  CardStyled,
  TextStatusIndicatorContainer,
  StatusTextContainer,
  ChatWrapper,
  TextFieldIconContainer,
  SendTextWrapper,
  NoContentWrapper,
  PoorConnectionWrapper,
  TextFieldStyled
} from './styles';
import { Button } from '../../../components';
import SendBird from '../../../utils/Sendbird';
import { ErrorToast, SuccessToast } from '../../../utils/ToastUtils';
import InterruptAiviPopUp from '../PopUps/InterruptAiviPopUp';
import BlackListPopUp from '../PopUps/BlackListPopUp';
import SkeletonLoader from './SkeletonLoader';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as AiviIcon } from '../../../assets/common/aivi-icon.svg';
import Linkify from 'react-linkify';
import {
  updateCurrentTalent,
  updateProfilePopUp
} from '../../../redux/actions/talent_action';
import { mutateJobApplicationState } from '../../../redux/actions/job_action';
import { getSendbirdID } from '../../../hooks/useENV';

const jobStatus = [
  'Undecided',
  'Shortlisted',
  'KIV',
  'Rejected'
  // 'Blacklisted'
];

let reconnectingSendbird = false;
let connectSendBirdCount = 0;
let idleTimer;
let connectionTimer;
let hidden = null;
let visibilityChange = null;
let currentActiveChannel = '';
const connectSendBirdTries = 3;

const sb = new SendBird(getSendbirdID());
let cDate = '';
let showDate = false;

const ChatSectionComponent = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [jobStatusArrowDrop, setJobStatusArrowDrop] = useState(null);
  const [isConnectingSendbird, setIsConnectingSendbird] = useState(false);
  const [sbConnected, setSbConnected] = useState(false);
  const [sbConnectionFailed, setSbConnectionFailed] = useState(false);
  const [poorNetwork, setPoorNetwork] = useState(false);
  const [isInterruptAiviOpen, setIsInterruptAiviOpen] = useState(false);
  const [isBlacklistOpen, setIsBlacklistOpen] = useState(false);
  const [currentChatChannel, setCurrentChatChannel] = useState('');
  const [currentApplication, setCurrentApplication] = useState('');
  const [currentChannel, setCurrentChannel] = useState(null);
  const [channelNotFound, setChannelNotFound] = useState(false);
  const [chatText, setChatText] = useState('');
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [messages, setMessages] = useState([]);

  const lastMessageRef = useRef();
  const messagesRef = useRef();
  const chatChannelRef = useRef();
  const sbConnectedRef = useRef();

  messagesRef.current = messages;
  chatChannelRef.current = currentChatChannel;
  sbConnectedRef.current = sbConnected;
  const { triggerReplaceJobApplication, triggerRefreshJobApplication } = props;

  // Selectors
  const isTalentSearchChat = useSelector(
    state => state.chats.isTalentSearchChat
  );
  const mutatingJobApplication = useSelector(
    state => state.jobs.mutatingJobApplication
  );

  // Conditional status
  const renderChangeStatusDropDown = () => {
    if (isTalentSearchChat) return false;

    return true;
  };

  const connectSendBird = () => {
    let accountInfo = localStorage.getItem('accountInfo');
    accountInfo = accountInfo ? JSON.parse(accountInfo) : accountInfo;

    if (accountInfo && sb && sb.getConnectionStatus() !== 'OPEN') {
      resetConnectionTimer(); // Trying to connect to sendbird, start poor network timer
      sb.connect(accountInfo.accountId)
        .then(() => {
          clearConnectionTimer(); // Successfully connected to sendbird, clear poor network timer
          setSbConnected(true);
          setSbConnectionFailed(false);
          setIsConnectingSendbird(false);
          reconnectingSendbird = false;
          triggerGetMessages(true);
        })
        .catch(() => {
          if (connectSendBirdCount <= connectSendBirdTries) {
            connectSendBird();
          } else {
            clearConnectionTimer();
            setSbConnectionFailed(true);
            setIsConnectingSendbird(false);
            reconnectingSendbird = false;
          }
          connectSendBirdCount += 1;
        });
    } else {
      clearConnectionTimer(); // Already connected to sendbird, clear poor network timer
      if (!sbConnectedRef.current) {
        setSbConnected(true);
      }
      reconnectingSendbird = false;
      setIsConnectingSendbird(false);
      addChannelHandler();
      triggerGetMessages(true);
    }
  };

  const addChannelHandler = () => {
    const params = {
      uniqueId: 'CHAT_LIST_HANDLER',
      updateMessages
    };

    if (sb) {
      sb.removeChannelHandler('CHAT_LIST_HANDLER');
      sb.createChannelListHandler(params);
    }
  };

  const getMessages = refresh => {
    if (sbConnectedRef.current) {
      let cChatChannel = currentActiveChannel;
      resetConnectionTimer(); // Trying to get channel info, start poor network timer
      sb.getChannelInfo(currentChatChannel)
        .then(channel => {
          setCurrentChannel(channel);
          const params = {
            sb,
            channel,
            refresh
          };

          if (params.channel && params.channel.createdAt) {
            if (sb.getConnectionStatus() === 'OPEN') {
              resetConnectionTimer(); // Done getting channel info, reset poor network timer
              sb.getMessageList(params)
                .then(chatMessages => {
                  if (cChatChannel != currentActiveChannel) return;

                  clearConnectionTimer(); // Done getting messages, clear poor network timer
                  setLoadingMessages(false);
                  setMessages([...chatMessages]);
                  const cParams = {
                    channel
                  };
                  if (sb.getConnectionStatus() === 'OPEN') {
                    sb.markAsRead(cParams);
                  } else {
                    setLoadingMessages(false);
                    setSbConnected(false);
                    connectSendBird();
                  }
                })
                .catch(() => {
                  setLoadingMessages(false);
                });
            } else {
              if (cChatChannel != currentActiveChannel) return;

              setLoadingMessages(false);
              setSbConnected(false);
              connectSendBird();
            }
          }
        })
        .catch(error => {
          if (cChatChannel != currentActiveChannel) return;
          setCurrentChannel(null);
          setChannelNotFound(true);
          setLoadingMessages(false);
        });
    } else {
      connectSendBird();
    }
  };

  const triggerGetMessages = (isSbConnected = false) => {
    // isSbConnected will be true when we trigger this function from sb connection callback where state value sbConnected is yet to be set to true/false
    if (currentChatChannel && currentChatChannel !== 'no-channel') {
      setPoorNetwork(false);
      if (sbConnectedRef.current || isSbConnected) {
        setLoadingMessages(true);
        getMessages(true);
      } else {
        connectSendBird();
      }
    }
  };

  const updateMessages = (channelUrl, message) => {
    if (channelUrl === chatChannelRef.current) {
      setMessages([...messagesRef.current, message]);
    }
  };

  const stopAiviSession = () => {
    return new Promise(resolve => {
      if (sbConnectedRef.current) {
        sb.updateChannelMetaData(currentChannel, {
          cb_status: 12
        })
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      } else {
        resolve(false);
      }
    });
  };

  const sendMessage = () => {
    if (sbConnected && currentChannel && chatText !== '') {
      const cMessage = chatText;
      setChatText('');
      sb.sendMessage(currentChannel, cMessage).then(message => {
        setMessages([...messagesRef.current, message]);
      });
    }
  };

  const sendAdminMessage = updateChannelMetaData => {
    setIsInterruptAiviOpen(false);
    if (sbConnected) {
      sb.adminMessage(
        currentChatChannel,
        'Employer has ended Virtual Interview.'
      ).then(() => {
        if (updateChannelMetaData) {
          stopAiviSession().then(res => {
            if (res) {
              // TO IMPROVE - hacky way to 100% ensure no concurrent update on messages
              setTimeout(() => {
                sendMessage();
              }, 200);
            }
          });
        }
      });
    }
  };

  const reconnectSendbird = () => {
    if (reconnectingSendbird) return;

    setPoorNetwork(false);
    setSbConnectionFailed(false);
    setIsConnectingSendbird(true);

    reconnectingSendbird = true;
    connectSendBirdCount = 0;

    connectSendBird();
  };

  const isAiviSession = chat => {
    let isAivi = false;
    if (chat && chat.members) {
      chat.members.forEach(member => {
        if (member.metaData?.role?.toLowerCase() === 'bot') {
          isAivi = true;
        }
      });
    }

    return isAivi;
  };

  const preSendMessage = () => {
    if (isAiviSession(currentChannel)) {
      setIsInterruptAiviOpen(true);
    } else {
      sendMessage();
    }
  };

  const onKeyDown = event => {
    if (event.key === 'Enter') {
      preSendMessage();
    }
  };

  const updateChatChannel = chatChannel => {
    currentActiveChannel = chatChannel;
    setCurrentChatChannel(chatChannel);
  };

  const updateApplication = application => {
    setCurrentApplication(application);
  };

  const getSbConnectionStatus = () => {
    return sbConnectedRef.current;
  };

  //  To allow parent to call this function
  useImperativeHandle(ref, () => ({
    updateChatChannel,
    updateApplication,
    onActive,
    getSbConnectionStatus
  }));

  const handleChangeChatText = event => {
    setChatText(event.target.value.substring(0, 500));

    onActive(sbConnectedRef.current);
  };

  // Comparison
  const isApplicant = (jobApplication, message) => {
    if (jobApplication.user?.id && message?._sender?.userId) {
      if (jobApplication.user.id === message._sender.userId) {
        return true;
      }
    }

    return false;
  };

  // Extract data
  const getApplicantName = jobApplication => {
    if (jobApplication.user?.name) {
      return jobApplication.user.name;
    }

    return '';
  };

  const getAppliedDate = data => {
    if (data.appliedAt || data.user?.unlockedAt) {
      const date = data.appliedAt || data.user?.unlockedAt;
      return moment(date).format('Do MMM YYYY');
    }

    return '';
  };

  const getApplicationStatus = jobApplication => {
    if (jobApplication.state) {
      return jobApplication.state;
    }
    return '';
  };

  const getMessageType = (jobApplication, message) => {
    let type = 'jobseeker';
    if (message.messageType === 'admin') {
      type = 'employer';
    } else if (message._sender?.metaData?.role) {
      if (message._sender.metaData.role.toLowerCase() === 'bot') {
        type = 'employer';
      }
    } else if (!isApplicant(jobApplication, message)) {
      type = 'employer';
    }

    return type;
  };

  const isStatusActive = (application, status) => {
    const cApplication = getApplicationStatus(application);

    return cApplication.toLowerCase() === status.toLowerCase();
  };

  const updateJobApplicationStatus = async (value, reason) => {
    dispatch(
      mutateJobApplicationState({
        input: {
          id: currentApplication?.id,
          state: value,
          ...(reason ? { blacklistReason: reason } : null)
        }
      })
    )
      .then(res => {
        if (res.type === 'MUTATE_JOB_APPLICATION_STATE_SUCCEED') {
          if (res?.jobApplication) {
            if (value.toLowerCase() !== 'blacklisted') {
              setCurrentApplication(res.jobApplication);
              triggerReplaceJobApplication(res.jobApplication);
            } else {
              triggerRefreshJobApplication();
            }
          }
          SuccessToast('Changed Job Application Status Successful!');
        }
      })
      .catch(err => {
        ErrorToast(err.message);
      });
  };

  // Control component
  const handleJobStatusArrowDropClick = event => {
    if (mutatingJobApplication) return;
    setJobStatusArrowDrop(event.currentTarget);
  };

  const handleCloseJobStatus = () => {
    setJobStatusArrowDrop(null);
  };

  const handleStatusClicked = status => () => {
    window.dataLayer.push({
      event: 'CE_click-change-status-chats',
      status: status
    });
    setJobStatusArrowDrop(null);

    // if status == blacklist then handle different
    if (status.toLowerCase() === 'blacklisted') {
      setIsBlacklistOpen(true);
    } else {
      updateJobApplicationStatus(status.toLowerCase(), null);
    }
  };

  const disconnectSendBird = () => {
    if (sb) {
      if (sb.getConnectionStatus() === 'OPEN') {
        sb.disconnect()
          .then(res => {
            if (res) {
              setSbConnected(false);
            }
          })
          .catch(error => {
            console.log('Failed to disconnect sendbird:', error);
          });
      } else {
        if (sbConnectedRef.current) {
          setSbConnected(false);
        }
      }
    }
  };

  const setConnectionTimer = () => {
    connectionTimer = setTimeout(() => {
      if (!sbConnectedRef.current) {
        setPoorNetwork(true);
      }
    }, 8000); // 8 seconds
  };

  const clearConnectionTimer = () => {
    if (connectionTimer) {
      clearTimeout(connectionTimer);
    }
  };

  const resetConnectionTimer = () => {
    if (connectionTimer) {
      clearTimeout(connectionTimer);
    }

    setConnectionTimer();
  };

  const resetIdleTimer = () => {
    if (idleTimer) {
      clearTimeout(idleTimer);
    }

    idleTimer = setTimeout(() => {
      onIdle(sbConnectedRef.current);
    }, 30000); // 30 seconds
  };

  const onActive = sbConnected => {
    resetIdleTimer();

    if (!sbConnected) {
      connectSendBird();

      // if (sb) {
      //   const params = {
      //     uniqueId: 'CHAT_LIST_HANDLER',
      //     updateMessages
      //   };
      //   sb.createChannelListHandler(params);
      // }
    }
  };

  const onIdle = sbConnected => {
    if (sbConnected) {
      disconnectSendBird();

      if (sb) {
        sb.removeChannelHandler('CHAT_LIST_HANDLER');
      }
    }
  };

  const handleVisibilityChange = () => {
    if (document[hidden]) {
      onIdle(sbConnectedRef.current);
    } else {
      onActive(sbConnectedRef.current);
    }
  };

  const initVisibilityChange = () => {
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    document.addEventListener(visibilityChange, handleVisibilityChange);
  };

  const onViewProfileClicked = () => {
    if (isTalentSearchChat && currentApplication?.user) {
      window.dataLayer.push({
        event: 'CE_click-view-profile-talent-search-chats'
      });

      dispatch(updateCurrentTalent(currentApplication.user));
      dispatch(updateProfilePopUp(true));
    } else if (currentApplication?.job?.id) {
      window.dataLayer.push({
        event: 'CE_click-view-profile-chats'
      });

      history(
        `/manage-candidates/${currentApplication.job.id}${
          currentApplication.user?.name &&
          `?name=${currentApplication.user?.name}`
        }`
      );
    }
  };

  const componentDecorator = type => (href, text, key) =>
    (
      <a
        href={href}
        key={key}
        target="_blank"
        style={{ ...(type !== 'jobseeker' ? { color: 'white' } : null) }}>
        {text}
      </a>
    );

  //grouping
  const groups = messagesRef.current.reduce((groups, item) => {
    const date = moment(item.createdAt).format('DD MMMM YYYY');

    if (!groups[date]) {
      groups[date] = [];
    }

    groups[date].push(item);

    return groups;
  }, {});

  //reform it into multiple objects in an array
  const groupArrays = Object.keys(groups).map(date => {
    return {
      date,
      item: groups[date]
    };
  });

  // Use effects
  useEffect(() => {
    connectSendBird();

    initVisibilityChange();

    // Setting idle timer
    resetIdleTimer();

    return () => {
      reconnectingSendbird = false;
      connectSendBirdCount = 0;
      idleTimer;
      hidden = null;

      if (sb) {
        if (sb.getConnectionStatus() === 'OPEN') {
          sb.disconnect();
        }
        sb.removeChannelHandler('CHAT_LIST_HANDLER');
      }
      document.removeEventListener(visibilityChange, handleVisibilityChange);
      visibilityChange = null;
    };
  }, []);

  useEffect(() => {
    if (sbConnected) {
      addChannelHandler();
    }
  }, [sbConnected]);

  useEffect(() => {
    if (messages.length > 0) {
      setTimeout(() => {
        if (lastMessageRef && lastMessageRef.current) {
          lastMessageRef.current.scrollTo({
            top: lastMessageRef.current?.children[
              lastMessageRef.current?.children?.length - 1
            ]?.offsetTop,
            behavior: 'smooth'
          });
        }
      }, 300); // 300 milliseconds to scroll to bottom after new message is rendered
    }
  }, [messages]);

  useEffect(() => {
    triggerGetMessages();
  }, [currentChatChannel]);

  return currentChatChannel !== '' && currentChatChannel !== 'no-channel' ? (
    <>
      <ChatInfo>
        <Grid style={{ maxWidth: '50%' }}>
          <Title chat_jobseeker_name="true">
            {getApplicantName(currentApplication)}
          </Title>
          <Grid style={{ display: 'flex', padding: '10px 0 ' }}>
            <TextIconContainer>
              <WatchLaterIcon
                fontSize="small"
                style={{ marginRight: '10px', fontSize: '18px' }}
              />
              <LabelStyled chat_section_date="true">
                {getAppliedDate(currentApplication)}
              </LabelStyled>
            </TextIconContainer>
            <Grid style={{ marginLeft: '20px' }} onClick={onViewProfileClicked}>
              <LabelStyled view_profile="true">View Profile</LabelStyled>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Grid>
            {renderChangeStatusDropDown() && (
              <CardStyled
                elevation={5}
                onClick={handleJobStatusArrowDropClick}
                disabled={mutatingJobApplication ? 'true' : 'false'}>
                <CardContent style={{ padding: '0' }}>
                  <TextStatusIndicatorContainer>
                    <Grid style={{ marginRight: '20px' }}>
                      <LabelStyled job_status="true">Change Status</LabelStyled>
                    </Grid>
                    <StatusIndicator
                      status={getApplicationStatus(currentApplication)}
                      margin_right={0}
                    />
                    <Grid style={{ marginLeft: '20px' }}>
                      <LabelStyled job_status="true" capitalize="true">
                        {getApplicationStatus(currentApplication)}
                      </LabelStyled>
                    </Grid>
                    <ArrowDropDownIcon
                      fontSize="small"
                      style={{ marginLeft: '20px' }}
                    />
                  </TextStatusIndicatorContainer>
                </CardContent>
              </CardStyled>
            )}

            <MenuStyled
              id="job-status-menu"
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              anchorEl={jobStatusArrowDrop}
              keepMounted
              MenuProps={{
                PaperProps: {
                  style: {
                    background: '#fff'
                  }
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                getContentAnchorEl: null
              }}
              open={Boolean(jobStatusArrowDrop)}
              onClose={handleCloseJobStatus}
              TransitionComponent={Fade}>
              {jobStatus.map(status => (
                <MenuItem
                  onClick={handleStatusClicked(status)}
                  key={`${status}`}
                  selected={isStatusActive(currentApplication, status)}>
                  <StatusTextContainer>
                    <StatusIndicator status={status} margin_right={20} />
                    <LabelStyled
                      style={{ fontWeight: 'bold', fontSize: '14px' }}>
                      {status}
                    </LabelStyled>
                  </StatusTextContainer>
                </MenuItem>
              ))}
            </MenuStyled>
          </Grid>
        </Grid>
      </ChatInfo>

      {sbConnectionFailed || poorNetwork ? (
        <PoorConnectionWrapper>
          <LabelStyled>
            Poor network connection, please click on the button to retry.
          </LabelStyled>
          <Button
            color="TRANSPARENT"
            size="BIG"
            style={{ marginTop: '15px' }}
            onClick={reconnectSendbird}>
            {isConnectingSendbird ? <CircularProgress size="26px" /> : 'Retry'}
          </Button>
        </PoorConnectionWrapper>
      ) : currentChannel ? (
        <ChatWrapper>
          {!sbConnected || loadingMessages ? (
            <SkeletonLoader />
          ) : (
            <>
              <ChatContainer ref={lastMessageRef}>
                {groupArrays.map(message => {
                  return (
                    <>
                      <div style={{ textAlign: 'center' }}>
                        <p
                          style={{
                            fontFamily: 'Inter',
                            fontSize: '10px',
                            color: 'efefef',
                            margin: '5px 0 10px'
                          }}>
                          {message.date}
                        </p>
                      </div>

                      {message.item.map(message => {
                        return (
                          <MessageContainer
                            key={message.messageId}
                            type={getMessageType(currentApplication, message)}>
                            <MessageContent
                              type={getMessageType(
                                currentApplication,
                                message
                              )}>
                              <LabelStyled
                                type={getMessageType(
                                  currentApplication,
                                  message
                                )}>
                                <Linkify
                                  componentDecorator={componentDecorator(
                                    getMessageType(currentApplication, message)
                                  )}>
                                  {message.message}
                                </Linkify>
                              </LabelStyled>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end'
                                }}>
                                <div style={{ marginRight: '10px' }}>
                                  {message?.sender?.userId == '123rwwer32' && (
                                    // <div style={{ color: '#fff' }}>Ho</div>
                                    <AiviIcon
                                      width={18}
                                      style={{
                                        color: '#fff',
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    />
                                  )}
                                </div>
                                <p
                                  style={{
                                    fontFamily: 'Inter',
                                    fontSize: '10px',
                                    color: 'efefef',
                                    marginTop: '5px',
                                    marginBottom: '0'
                                  }}>
                                  {moment(message.createdAt).format('h:mm A')}
                                </p>
                              </div>
                            </MessageContent>
                          </MessageContainer>
                        );
                      })}
                    </>
                  );
                })}
              </ChatContainer>
              <SendTextWrapper>
                <TextFieldIconContainer>
                  <TextFieldStyled
                    id="chat-text-input"
                    type="text"
                    hiddenLabel
                    variant="outlined"
                    onKeyDown={onKeyDown}
                    onChange={handleChangeChatText}
                    value={chatText}
                    style={{ width: '100%' }}
                    InputProps={{
                      placeholder: 'Start typing....',
                      disableUnderline: true,
                      style: {
                        background: 'transparent',
                        width: '100%',
                        fontSize: '14px'
                      }
                    }}
                  />
                  <SendIcon
                    style={{
                      color: '#512acc',
                      marginLeft: '10px',
                      cursor: 'pointer'
                    }}
                    onClick={preSendMessage}
                  />
                </TextFieldIconContainer>
              </SendTextWrapper>
            </>
          )}
        </ChatWrapper>
      ) : channelNotFound ? (
        <NoContentWrapper>
          <LabelStyled>Chat channel not found.</LabelStyled>
        </NoContentWrapper>
      ) : (
        <SkeletonLoader />
      )}

      <InterruptAiviPopUp
        open={isInterruptAiviOpen}
        handleClose={() => setIsInterruptAiviOpen(false)}
        sendAdminMessage={sendAdminMessage}
      />
      <BlackListPopUp
        open={isBlacklistOpen}
        handleClose={() => setIsBlacklistOpen(false)}
        updateJobApplicationStatus={updateJobApplicationStatus}
        loading={mutatingJobApplication}
      />
    </>
  ) : (
    <NoContentWrapper>
      <LabelStyled>
        {currentChatChannel === 'no-channel'
          ? 'No chat available for this application.'
          : 'Click on an active chat to start communicating with the applicant.'}
      </LabelStyled>
    </NoContentWrapper>
  );
});

export default ChatSectionComponent;
