import React, { useEffect, useState } from 'react';
import ContentCard from '../components/ContentCard';
import { useDispatch, useSelector } from 'react-redux';
import ViewState from './ViewState';
import EditState from './EditState';
import InfoPopUp from './components/InfoPopup';
import { updateCompanySidebar } from '../../../redux/actions/company_actions';

export default function CompanyImages() {
  const dispatch = useDispatch();
  const companyProfile = useSelector(state => state.companies.companyProfile);
  const companyCompletion = useSelector(
    state => state.companies.companyCompletion
  );

  const [view, setView] = useState(false);
  const [info, setInfo] = useState(false);

  useEffect(() => {
    if (companyProfile?.images?.length > 2) {
      setView(true);
      dispatch(
        updateCompanySidebar({
          ...companyCompletion,
          companyImages: true
        })
      );
    } else {
      setView(false);
      dispatch(
        updateCompanySidebar({
          ...companyCompletion,
          companyImages: false
        })
      );
    }
  }, [companyProfile?.images]);

  return (
    <div id="Company Photos">
      {view ? (
        <ViewState setInfo={setInfo} setView={setView} />
      ) : (
        <EditState setInfo={setInfo} setView={setView} />
      )}
      <InfoPopUp open={info} handleClose={() => setInfo(false)} />
    </div>
  );
}
