import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import { ContentWrapper, useStyles } from './styles';
import { strapiCall } from '../../utils/api';

export default function Updates() {
  const classes = useStyles();
  const [content, setContent] = useState('');

  async function fetchUpdatesData() {
    const payload = {
      query: `
          query {
            updatesPageEd {
              data {
                attributes {
                  content
                }
              }
            }
          }
            `
    };

    const response = await strapiCall(payload);
    if (response.status === 200) {
      const { data } = response?.data?.data?.updatesPageEd || {};
      if (data) {
        setContent(data.attributes?.content);
      }
    }
  }

  useEffect(() => {
    fetchUpdatesData();
  }, []);

  return (
    <DefaultLayout>
      <ContentWrapper
        dangerouslySetInnerHTML={{ __html: content }}
        className={classes.root}
      />
    </DefaultLayout>
  );
}
