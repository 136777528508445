import { Box } from "@mui/material";
import { styled as styling } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& img": {
      maxWidth: "100%",
    },
  },
}));

export const ContentWrapper = styling(Box)((props) => ({
  maxWidth: "1380px",
  marginInline: "auto",
  marginBottom: "100px",
  paddingInline: "60px",
  marginTop: "50px",

  [props.theme.breakpoints.down(600)]: {
    paddingInline: "30px",
  },
}));
