import React, { useState } from 'react';
import {
  AlertStyled,
  ContainerStyled,
  CoverDesc,
  CoverTitle,
  OutlineInformationIcon,
  PencilIconStyled,
  SolidInformationIcon,
  TitleWrapper
} from '../CoverImage/styles';
import { Grid } from '@mui/material';
import IconButton from '../components/IconButton';
import { useSelector } from 'react-redux';
import { SupportedFileText } from './styles';
import { PlusCircleIconStyled } from '../CompanyBenefits/styles';

export default function ViewState(props) {
  const { setView, setInfo } = props;
  const companyProfile = useSelector(state => state.companies.companyProfile);

  const emptySlot = 8 - companyProfile?.images?.length;
  function generateList(length) {
    return [
      { add: true },
      ...Array.from({ length: length - 1 }, () => ({ add: false }))
    ];
  }

  const list = generateList(emptySlot);

  return (
    <ContainerStyled>
      <TitleWrapper>
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <CoverTitle>Company Photos</CoverTitle>
          <CoverDesc>
            Upload up to 8 photos that best represent your company office and
            culture.
          </CoverDesc>
        </Grid>

        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            alignItems: 'center'
          }}>
          <IconButton
            onClick={() => setInfo(true)}
            tooltipTitle="More Information">
            <OutlineInformationIcon />
          </IconButton>

          <IconButton
            onClick={() => {
              setView(false);
            }}
            tooltipTitle="Edit">
            <PencilIconStyled />
          </IconButton>
        </Grid>
      </TitleWrapper>

      <AlertStyled
        icon={<SolidInformationIcon />}
        onClick={() => setInfo(true)}>
        <span style={{ letterSpacing: '0.15px' }}>
          Please upload at least 3 photos. Photos are displayed in the same
          order that they appear here.
        </span>
      </AlertStyled>

      <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Grid
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: '16px',
            rowGap: '16px'
          }}>
          {companyProfile?.images
            ?.sort((a, b) => {
              return (a.sortOrder = b.sortOrder);
            })
            .map(image => {
              return (
                <img
                  src={image?.publicUrl}
                  style={{
                    borderRadius: '8px',
                    width: '218px',
                    height: '130px',
                    objectFit: 'cover',
                    border: '1px solid #D8D8D8'
                  }}
                />
              );
            })}

          {companyProfile?.images?.length !== 8 &&
            list?.map(list => {
              return <EmptyContent add={list?.add} setView={setView} />;
            })}
        </Grid>
        <SupportedFileText>
          Supported file type: .png, .jpg, .jpeg | File size: less than 2 MB
        </SupportedFileText>
      </Grid>
    </ContainerStyled>
  );
}

const EmptyContent = props => {
  const { add, setView } = props;

  return add ? (
    <>
      <Grid
        onClick={() => setView(false)}
        sx={{
          width: '216px',
          height: '130px',
          border: '1px dashed #707070',
          borderRadius: '8px',
          backgroundColor: '#EFEFEF',
          display: 'flex',
          gap: '8px',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          cursor: 'pointer'
        }}>
        <PlusCircleIconStyled />
        Add a Photo
      </Grid>
    </>
  ) : (
    <Grid
      sx={{
        width: '216px',
        height: '130px',
        border: '1px dashed #707070',
        borderRadius: '8px',
        backgroundColor: 'white'
      }}></Grid>
  );
};
