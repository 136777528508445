import React from 'react';
import {
  Text,
  HeightSpacer,
  JobSlotsConfirmationContainer,
  Spacer,
  ButtonsContainer,
  AvailableCounts,
  AvailableCountsContainer,
  AvailableCountsWrapper
} from './styles';
import { SharedButton } from '../SharedButton';
import { ReactComponent as JobSlotPopupIcon } from '../../../../../assets/icons/job-list/jobslot-popup-image.svg';
import SharedBackButton from '../SharedBackButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllInactiveJobs,
  updateExpiryDatesPopupContentIndex,
  updateJob,
  updateJobDataForm
} from '../../../../../redux/actions/job_action';
import { FREE_JOB_POST_POSITION } from '../../../../../utils/Constants';
import { useNavigate } from 'react-router-dom';
import store from '../../../../../redux/stores/store';

const ProductConfirmationPopup = ({ onCloseModal }) => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const expiryDatesContentIndex = useSelector(
    state => state.jobs.expiryDatesPopupContentIndex
  );
  const selectedProduct = useSelector(state => state.jobs.selectedProduct);

  const remainingUsableJobSlots = useSelector(
    state => state.jobs.subscribedJobSlotsPackage?.total_usable_quantity
  );
  const jobTokensUsableCount = useSelector(
    state => state.jobs.subscribedJobTokensPackage?.total_usable_quantity
  );
  const freeInternshipTokensUsableCount = useSelector(
    state =>
      state.jobs.subscribedFreeInternshipTokensPackage?.total_usable_quantity
  );
  const freeSuperSeniorTokensCount = useSelector(
    state =>
      state.jobs.subscribedFreeSuperSeniorTokensPackage?.total_usable_quantity
  );
  const jobCreditUsableCount = useSelector(
    state => state.jobs.subscribedJobCreditsPackage?.total_usable_quantity
  );

  const remainingUsableJobSlotPlus = useSelector(
    state => state.jobs.subscribedJobSlotPlusPackage?.total_usable_quantity
  );

  const remainingUsableJobSlotMax = useSelector(
    state => state.jobs.subscribedJobSlotMaxPackage?.total_usable_quantity
  );

  const onClickBackButton = () => {
    dispatch(updateExpiryDatesPopupContentIndex(-1));
  };

  const getProductCount = selectedProduct => {
    switch (selectedProduct) {
      case 'job slots':
        return remainingUsableJobSlots;

      case 'prep job slots':
        return remainingUsableJobSlots;

      case 'free internship tokens':
        return freeInternshipTokensUsableCount;

      case 'free super senior jobs':
        return freeSuperSeniorTokensCount;

      case 'job tokens':
        return jobTokensUsableCount == 'Unlimited'
          ? 'Unlimited'
          : jobTokensUsableCount;

      case 'job credits':
        return jobCreditUsableCount;
      
      case 'job slots +':
        return remainingUsableJobSlotPlus;

      case 'job slots max':
        return remainingUsableJobSlotMax;
  
    }
  };

  const getProductCopy = selectedProduct => {
    switch (selectedProduct) {
      case 'job slots':
        return 'Job Slots';

      case 'prep job slots':
        return 'Job Slots';

      case 'free internship tokens':
        return 'Free Internship Token';

      case 'free super senior jobs':
        return 'Free Senior Posts';

      case 'job tokens':
        return 'Job Tokens';

      case 'job credits':
        return 'Job Credits';

      case 'job slots Plus':
        return 'Job Slot Plus';

      case 'job slots max':
        return 'Job Slot Max';
  
    }
  };

  const onClickPostNewJob = productType => {
    dispatch(updateJobDataForm({}));
    dispatch(updateJob({}));
    onCloseModal();
    if (
      productType == 'job slots' ||
      productType == 'prep job slots' ||
      productType == 'job tokens' ||
      productType == 'job credits' ||
      productType == 'job slots +' ||
      productType == 'job slots max'
    ) {
      history('/jobs/add/new-job');
    } else if (productType == 'free internship tokens') {
      history({
        pathname: '/jobs/add/free-intern',
        state: {
          freeJobPostPosition: FREE_JOB_POST_POSITION.INTERN
        }
      });
    } else if (productType == 'free super senior jobs') {
      history({
        pathname: '/jobs/add/free-senior',
        state: {
          freeJobPostPosition: FREE_JOB_POST_POSITION.SENIOR
        }
      });
    }
  };

  const disableButton = productType => {
    if (
      (productType == 'job slots' || productType == 'prep job slots') &&
      remainingUsableJobSlots <= 0
    ) {
      return true;
    } else if (
      productType == 'free internship tokens' &&
      freeInternshipTokensUsableCount <= 0
    ) {
      return true;
    } else if (
      productType == 'free super senior jobs' &&
      freeSuperSeniorTokensCount <= 0
    ) {
      return true;
    } else if (productType == 'job tokens' && jobTokensUsableCount <= 0) {
      return true;
    } else if (productType == 'job credits' && jobCreditUsableCount <= 0) {
      return true;
    } else if (productType == 'job slots +' && remainingUsableJobSlotPlus <= 0) {
      return true;
    } else if (productType == 'job slots max' && remainingUsableJobSlotMax <= 0) {
      return true;
    }
  };

  const onClickRenewInactiveJobs = () => {
    dispatch(updateExpiryDatesPopupContentIndex(1));
    store.getState().jobs.allInactiveJobs = [];
    dispatch(fetchAllInactiveJobs());
  };

  return (
    <JobSlotsConfirmationContainer
      expiry_dates_content_index={`${expiryDatesContentIndex}`}>
      <SharedBackButton onClick={onClickBackButton} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <JobSlotPopupIcon width={300} />
      </div>
      <HeightSpacer height={'40px'} />
      <Text job_slot_redirect="true">
        {selectedProduct == 'job slots' || selectedProduct == 'prep job slots' || selectedProduct == 'job slots max' || selectedProduct == 'job slots +' 
          ? 'How do you want to use the following '
          : 'Are you sure you want to use the '}
        <b>{getProductCopy(selectedProduct)}</b>?
      </Text>

      <HeightSpacer height="30px" />

      <AvailableCountsWrapper>
        <AvailableCountsContainer>
          <AvailableCounts>
            <Text available_count="true">
              {getProductCount(selectedProduct)}
            </Text>
            <HeightSpacer height={'10px'} />
            <Text available_count_text="true">{`${getProductCopy(
              selectedProduct
            )} available`}</Text>
          </AvailableCounts>
        </AvailableCountsContainer>
      </AvailableCountsWrapper>

      <HeightSpacer height="40px" />

      <ButtonsContainer>
        {(selectedProduct == 'job slots' ||
          selectedProduct == 'prep job slots' ||
          selectedProduct == 'job slots +' ||
          selectedProduct == 'job slots max') && (
          <SharedButton
            type="outline"
            min_width={'240px'}
            font_size={'16px'}
            onClick={onClickRenewInactiveJobs}
            disabled={disableButton(selectedProduct)}>
            Renew Inactive Jobs
          </SharedButton>
        )}
        <Spacer width="20px" />
        <SharedButton
          type="basic"
          min_width={'240px'}
          font_size={'16px'}
          onClick={() => onClickPostNewJob(selectedProduct)}
          disabled={disableButton(selectedProduct)}>
          {selectedProduct == 'job slots' || selectedProduct == 'prep job slots' || selectedProduct == 'job slots +' || selectedProduct == 'job slots max'
            ? 'Post New Job'
            : 'Confirm'}
        </SharedButton>
      </ButtonsContainer>
    </JobSlotsConfirmationContainer>
  );
};

export default ProductConfirmationPopup;
