import React from 'react';
import { ButtonStyled, Container, Text } from './styles';
import { useNavigate } from 'react-router-dom';
import store from '../../../redux/stores/store';
import { getNationality } from '../../../hooks/useRegionalisation';

let accountInfo;

try {
  accountInfo = localStorage.getItem('accountInfo');
  accountInfo = accountInfo ? JSON.parse(accountInfo) : accountInfo;
} catch (error) {
  //
}

export default function Header(props) {
  const history = useNavigate();
  const job = props?.application?.job;
  const user = props?.application?.user;
  const localOnly = job?.localOnly ? true : false;
  const nationality = getNationality().toLowerCase();
  const isAuthenticated = localStorage.getItem('accessToken');

  const viewAllApplicantOnClick = () => {
    if (isAuthenticated) {
      if (job?.id) {
        store.getState().applications.viewAllApllicantsPath = '';
        if (accountInfo) {
          history({
            pathname: `/manage-candidates/${job?.id}`,
            state: { nationality: localOnly }
          });
        } else {
          store.getState().applications.viewAllApllicantsPath =
            '/manage-candidates/' + job?.id;
          history('/login', { replace: true });
        }
      }
    } else {
      history('/login', { replace: true });
    }
  };

  return (
    <Container>
      <div>
        <Text type="title">Applicant For {job ? job.title : '-'}</Text>
        <Text type="name">{user ? user.name : '-'}</Text>
      </div>
      <div style={{ alignSelf: 'center' }}>
        <ButtonStyled type="basic" onClick={viewAllApplicantOnClick}>
          View All Applicants
        </ButtonStyled>
      </div>
    </Container>
  );
}
