import { styled as styling } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

export const SupportedFileText = styling(Typography)(props => ({
  color: 'rgba(0, 0, 0, 0.60)',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  fontFamily: 'inter'
}));
