import { FormControl, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from '../../context';
import {
  TextFieldStyled,
  FormControlLabelStyled,
  RadioStyled,
  RadioGroupStyled,
  TopSection,
  MainContainer,
  Title,
  Description,
  RadioGroupOuterStyled
} from './styles';
import { useSelector } from 'react-redux';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { getNationality } from '../../../../../hooks/useRegionalisation';

export default function Notification() {
  const key = 'customEmail';
  const {
    formState,
    handleFieldChange,
    handleSingleFieldChange,
    handleErrorState,
    errorState
  } = useFormContext();
  const myCompanySettings = useSelector(
    state => state?.companies?.myCompanySettings
  );

  const [radioValue, setRadioValue] = useState('companyEmail');

  const getRadioGroupValue = () => {
    if (!formState.receiveNotification) {
      return 'option1';
    } else if (formState.receiveNotification && !formState.localOnly) {
      return 'option2';
    } else if (formState.receiveNotification && formState.localOnly) {
      return 'option3';
    }
    return 'option1'; // Default value
  };

  useEffect(() => {
    if (formState?.customEmail) {
      setRadioValue('customEmail');
    }
  }, [formState?.customEmail]);

  const handlerRadio = value => {
    setRadioValue(value);
    // handleErrorState(errorState.filter(error => error !== key));
    if (radioValue === 'customEmail') {
      handleSingleFieldChange('usingCustomEmail', false);
      handleSingleFieldChange('customEmail', '');
    } else {
      handleSingleFieldChange('usingCustomEmail', true);
    }
  };

  return (
    <Grid style={{ margin: '70px 0 0 0' }}>
      <div
        style={{
          justifyContent: 'center',
          display: 'flex'
        }}>
        <MainContainer>
          <TopSection>
            <Title>Email Notifications</Title>
            <Description>
              Would you like to receive email notifications for each new
              applicant?
            </Description>
          </TopSection>

          <div>
            <FormControl>
              <RadioGroupOuterStyled
                value={getRadioGroupValue()}
                onChange={e => {
                  if (e.target.value == 'option1') {
                    handleSingleFieldChange('receiveNotification', false);
                    handleSingleFieldChange('localOnly', false);
                  } else if (e.target.value == 'option2') {
                    handleSingleFieldChange('receiveNotification', true);
                    handleSingleFieldChange('localOnly', false);
                  } else if (e.target.value == 'option3') {
                    handleSingleFieldChange('receiveNotification', true);
                    handleSingleFieldChange('localOnly', true);
                  }

                  if (e.target.value == 'option1') {
                    handleSingleFieldChange('usingCustomEmail', false);
                    setRadioValue('companyEmail');
                    handleSingleFieldChange('customEmail', null);
                  }

                  handleErrorState(errorState.filter(error => error !== key));
                }}>
                <FormControlLabelStyled
                  value="option1"
                  control={<RadioStyled />}
                  label={<p>No thanks</p>}
                />
                <FormControlLabelStyled
                  value="option2"
                  control={<RadioStyled />}
                  label={
                    <p>
                      Yes, I’d like to receive email notifications for{' '}
                      <b>all applicants</b>
                    </p>
                  }
                />

                {formState?.receiveNotification && !formState?.localOnly && (
                  <FormControl component="fieldset">
                    <RadioGroupStyled
                      value={radioValue}
                      onChange={e => handlerRadio(e.target.value)}>
                      <FormControlLabelStyled
                        value={'companyEmail'}
                        control={<RadioStyled />}
                        label={
                          <p>
                            at {myCompanySettings?.userEmail} for each
                            applicant.
                          </p>
                        }
                      />

                      <FormControlLabelStyled
                        value={'customEmail'}
                        control={<RadioStyled />}
                        label={
                          <p>at the assigned email below for each applicant</p>
                        }
                      />

                      {radioValue === 'customEmail' && (
                        <>
                          <div style={{ width: '100%', maxWidth: '370px' }}>
                            <TextFieldStyled
                              onChange={e => {
                                handleFieldChange(e);
                                handleErrorState(
                                  errorState.filter(error => error !== key)
                                );
                              }}
                              placeholder="Assign email to receive notifications"
                              name="customEmail"
                              value={
                                formState?.receiveNotification &&
                                radioValue === 'customEmail'
                                  ? formState?.customEmail
                                  : ''
                              }
                              isError={errorState.includes(key)}
                              disabled={radioValue !== 'customEmail'}
                            />
                          </div>

                          {errorState.includes(key) && (
                            <p
                              style={{
                                color: '#BE4242',
                                fontSize: '12px',
                                lineHeight: '20px',
                                display: 'flex',
                                padding: '0px 8px',
                                alignItems: 'center',
                                gap: '8px',
                                margin: '0'
                              }}>
                              <ExclamationCircleIcon
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  color: '#BE4242'
                                }}
                              />
                              Valid email is required.
                            </p>
                          )}
                        </>
                      )}
                    </RadioGroupStyled>
                  </FormControl>
                )}

                <FormControlLabelStyled
                  value="option3"
                  control={<RadioStyled />}
                  label={
                    <p>
                      Yes, I’d like to receive email notifications for{' '}
                      <b>{getNationality()} applicants only</b>
                    </p>
                  }
                />

                {formState?.receiveNotification && formState?.localOnly && (
                  <FormControl component="fieldset">
                    <RadioGroupStyled
                      value={radioValue}
                      onChange={e => handlerRadio(e.target.value)}>
                      <FormControlLabelStyled
                        value={'companyEmail'}
                        control={<RadioStyled />}
                        label={
                          <p>
                            at {myCompanySettings?.userEmail} for each
                            applicant.
                          </p>
                        }
                      />

                      <FormControlLabelStyled
                        value={'customEmail'}
                        control={<RadioStyled />}
                        label={
                          <p>at the assigned email below for each applicant</p>
                        }
                      />

                      {radioValue === 'customEmail' && (
                        <>
                          <div style={{ width: '100%', maxWidth: '370px' }}>
                            <TextFieldStyled
                              onChange={e => {
                                handleFieldChange(e);
                                handleErrorState(
                                  errorState.filter(error => error !== key)
                                );
                              }}
                              placeholder="Assign email to receive notifications"
                              name="customEmail"
                              value={
                                formState?.receiveNotification &&
                                radioValue === 'customEmail'
                                  ? formState?.customEmail
                                  : ''
                              }
                              isError={errorState.includes(key)}
                              disabled={radioValue !== 'customEmail'}
                            />
                          </div>

                          {errorState.includes(key) && (
                            <p
                              style={{
                                color: '#BE4242',
                                fontSize: '12px',
                                lineHeight: '20px',
                                display: 'flex',
                                padding: '0px 8px',
                                alignItems: 'center',
                                gap: '8px',
                                margin: '0'
                              }}>
                              <ExclamationCircleIcon
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  color: '#BE4242'
                                }}
                              />
                              Valid email is required.
                            </p>
                          )}
                        </>
                      )}
                    </RadioGroupStyled>
                  </FormControl>
                )}
              </RadioGroupOuterStyled>
            </FormControl>
          </div>
        </MainContainer>
      </div>
    </Grid>
  );
}
