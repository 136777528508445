import React from 'react';
import {
  ContainerStyled,
  CoverDesc,
  CoverTitle,
  TitleWrapper
} from '../SocialMediaHighlights/styles';
import { Grid } from '@mui/material';
import IconButton from '../components/IconButton';
import { PencilIconStyled } from '../CoverImage/styles';
import { useSelector } from 'react-redux';
import BenefitCards from './components/BenefitCards';
import { EmptyBenefitContainer, EmptyBenefitContainerView, PlusCircleIconStyled } from './styles';

export default function ViewState(props) {
  const { setView } = props;
  const companyProfile = useSelector(state => state.companies.companyProfile);

  return (
    <ContainerStyled>
      <TitleWrapper>
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <CoverTitle>Company Benefits</CoverTitle>
          <CoverDesc>
            Enhance your company's competitive advantage with up to 6 work
            benefits
          </CoverDesc>
        </Grid>

        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            alignItems: 'center'
          }}>
          <IconButton
            onClick={() => {
              setView(false);
            }}
            tooltipTitle="Edit">
            <PencilIconStyled />
          </IconButton>
        </Grid>
      </TitleWrapper>
      <Grid
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '16x',
          rowGap: '16px',
          justifyContent: 'space-between',
          flexWrap: 'wrap'
        }}>
        {companyProfile?.benefits
          ?.sort((a, b) => {
            return a.sortOrder - b.sortOrder;
          })
          ?.map(benefit => {
            return <BenefitCards benefit={benefit} />;
          })}
        {companyProfile?.benefits.length !== 6 && (
          <EmptyBenefitContainerView onClick={() => setView(false)}>
            <PlusCircleIconStyled /> Add a company benefit
          </EmptyBenefitContainerView>
        )}
      </Grid>
    </ContainerStyled>
  );
}
