import React, { useEffect } from 'react';
import { Button, PopUpDialog, Spinner } from '../../../../components';
import {
  FirstContainer,
  SecondContainer,
  ButtonWrapper,
  ButtonContainer,
  Text
} from './styles';

const BulkActionsPopUp = ({
  isBulkActionsOpen,
  handleBulkActionsPopUpClose
}) => {
  return (
    <PopUpDialog
      open={isBulkActionsOpen}
      handleClose={handleBulkActionsPopUpClose}
      title={`Bulk Actions`}>
      <FirstContainer>
        <Text type="title">
          {`Are you sure you want to move the selected candidates(4) from`}
        </Text>
        <Text type="first_state">Undecided</Text>
        <Text type="basic">{`to \n`}</Text>
        <Text type="second_state">KIV</Text>
        <Text type="basic">?</Text>
      </FirstContainer>
      <SecondContainer>
        <ButtonWrapper>
          <ButtonContainer>
            <Button
              color="WHITEBLACK"
              size="BIG"
              onClick={handleBulkActionsPopUpClose}>
              Cancel
            </Button>
          </ButtonContainer>
          <ButtonContainer>
            <Button color="PURPLE" size="BIG" onClick={() => {}}>
              Move
            </Button>
          </ButtonContainer>
        </ButtonWrapper>
      </SecondContainer>
    </PopUpDialog>
  );
};

export default BulkActionsPopUp;
