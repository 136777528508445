import { styled as styling } from '@mui/material/styles';
import {
  Typography,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Radio,
  Grid
} from '@mui/material';

export const TitleStyled = styling(Typography)({
  fontWeight: '700',
  fontSize: '14px'
});

export const RadioGroupStyled = styling(RadioGroup)({});

export const FormControlLabelStyled = styling(FormControlLabel)({});

export const FormControlStyled = styling(FormControl)({
padding: '0 10px 0px',
  margin: '0px 8px',
});

export const RadioStyled = styling(Radio)({
  color: 'black',
  padding: '4px 9px 4px 2px',

  // ":hover":{
  //   '&.MuiRadio-root': {
  //     color: 'red'
  //   }
  // },

  '&.Mui-checked': {
    color: 'black'
  }
});

export const OuterWrapper = styling(Grid)({
  padding: '0px',
  display: 'flex',
  flexDirection: 'column',
  width: '95%',
  gap: '8px'
});
