import { styled as styling } from '@mui/material/styles';
import { Button } from '@mui/material';

export const ButtonStyled = styling(Button)(({ type }) => ({
  borderRadius: type == 'basic' ? '26.5px' : '26.5px',
  background: type == 'basic' ? '#512acc' : 'transparent',
  alignSelf: 'center',
  color: type == 'basic' ? '#fff' : 'rgba(0, 0, 0, 0.26)',
  fontWeight: '700',
  padding: '5px 20px',
  textTransform: 'capitalize',
  letterSpacing: '0.03rem',
  fontSize: '15px',
  fontFamily: 'Inter',
  boxShadow: type == 'disabled' ? '0px 4px 20px rgb(0 0 0 / 15%)' : 'none',
  backgroundColor: type == 'disabled' ? 'rgba(0, 0, 0, 0.12)' : type = 'basic' ? '#512acc' : 'transparent',

  ':hover':{
    backgroundColor: type == 'disabled' ? 'rgba(0, 0, 0, 0.12)' : type = 'basic' ? '#512acc' : 'unset',
  }
}));

export const Container = styling('div')({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '20px auto 15px',
  padding: '10px 0',
});

export const Text = styling('p')(({ type }) => ({
  fontFamily: 'Inter',
  fontWeight: '700',
  fontSize: type == 'title' ? '18px' : type == 'name' ? '16px' : '14px',
}));
