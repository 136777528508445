import { styled as styling } from '@mui/material/styles';
import { Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const MainWrapper = styling(Grid)(props => ({
  width: '100%',
  marginBottom: '20px'
}));

export const PurpleContainer = styling(Grid)({
  width: '0.5%',
  backgroundColor: `rgba(242, 166, 71, 1)`,
  borderRadius: '8px 0px 0px 8px'
});

export const CenterContainer = styling(Grid)({
  padding: '10px 16px 10px 16px',
  backgroundColor: `rgba(242, 166, 71, 0.2)`,
  width: '99.5%',
  borderRadius: '0px 8px 8px 0px'
});

export const IconButtonStyled = styling(IconButton)({});

export const CloseIconStyled = styling(CloseIcon)({});

export const DecoyTypography = styling(Typography)({
  opacity: 0
});

export const TypographyStyled = styling(Typography)({
  fontSize: '14px',
  fontWeight: '400'
});

export const EmptyContainer = styling(Grid)({});

export const ClickableLink = styling('span')({
  fontSize: '14px',
  fontWeight: 'bold',
  textDecoration: 'underline',
  cursor: 'pointer'
});
