import { Button, Dialog, Grid } from '@mui/material';
import React from 'react';
import {
  ButtonContinueEdit,
  ButtonSkipEdit,
  ButtonText,
  DialogActionsStyled,
  DialogContentStyled,
  DialogTitleStyled,
  IconButtonStyled
} from './styles';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

export default function VerifyPopUp(props) {
  const { open, setClose } = props;
  const history = useNavigate();

  const verifyHandler = () => {
    history('/verification');
  };

  return (
    <Dialog open={open}>
      <DialogTitleStyled>
        Verify your account
        <IconButtonStyled onClick={setClose}>
          <XMarkIcon height={24} width={24} />
        </IconButtonStyled>
      </DialogTitleStyled>
      <DialogContentStyled>
        <Grid
          container
          sx={{ flexDirection: 'column', gap: '24px', display: 'flex' }}>
          For security reasons, please verify your email to start using your
          account.
          <DialogActionsStyled>
            <Grid
              container
              sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <ButtonText onClick={() => setClose(false)}>
                I'll do this later
              </ButtonText>
              <ButtonSkipEdit
                onClick={() => {
                  setClose(false);
                  verifyHandler();
                }}>
                Proceed to verify
              </ButtonSkipEdit>
            </Grid>
          </DialogActionsStyled>
        </Grid>
      </DialogContentStyled>
    </Dialog>
  );
}
