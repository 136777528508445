import { Skeleton } from '@mui/material';
import React from 'react';

const SkeletonLoader = () => (
  <div style={{ padding: '60px 24px 24px 24px', height: '460px', display: 'flex', flexDirection: 'column', gap: '12px'}}>
    <Skeleton variant="rectangular" animation="wave" width={465} height={48} style={{borderRadius: '8px'}} />
    <Skeleton variant="rectangular" animation="wave" width={465} height={48} style={{borderRadius: '8px'}} />
    <Skeleton variant="rectangular" animation="wave" width={465} height={48} style={{borderRadius: '8px'}} />
  </div>
);

export default SkeletonLoader;
