import { useSelector } from 'react-redux';
import { countryCodeOptions, countryOptionsFullName } from '../components/SharedSelectCountry/constants';
import { getLocalStorageItem } from '../helpers/local_storage_utils';
import { toMYR, toSGD } from '../utils/CurrencyUtils';
import { NATIONALITY_OPTIONS } from '../components/SharedAdvancedFilter/constants';
import {
  CSEMAIL,
  HIREDLY_BANK_ACCOUNT,
  HIREDLY_NAME
} from '../utils/Constants';

export const getCountryStates = state => {
  const country = getLocalStorageItem('country');

  if (countryCodeOptions[country] == 'MY') {
    return state.companies?.myStateRegions;
  } else if (countryCodeOptions[country] == 'SG') {
    return state.companies?.sgStateRegions;
  }
};

export const getAllCountryStates = () => {
  const allCountryStates = useSelector(
    state => state.companies?.allCountryStates
  );

  return allCountryStates;
};

export const getCurrency = value => {
  const country = getLocalStorageItem('country');

  if (countryCodeOptions[country] == 'MY') {
    return toMYR(value);
  } else if (countryCodeOptions[country] == 'SG') {
    return toSGD(value);
  }
};

export const getCurrenyCode = () => {
  const country = getLocalStorageItem('country');

  if (countryCodeOptions[country] == 'MY') {
    return 'RM';
  } else if (countryCodeOptions[country] == 'SG') {
    return '$';
  }
};

export const getCountry = () => {
  return getLocalStorageItem('country');
};

export const getNationality = () => {
  return NATIONALITY_OPTIONS[getCountry()][0].value;
};

export const getTaxLabel = type => {
  if (countryCodeOptions[getCountry()] == 'MY') {
    return 'Service Tax';
  } else if (countryCodeOptions[getCountry()] == 'SG') {
    if (type == 'remove-incl') {
      return 'GST';
    }
    return 'incl. GST';
  }
};

export const getCSEmail = () => {
  return CSEMAIL[getCountry()];
};

export const getHiredlyBankDetails = () => {
  return HIREDLY_BANK_ACCOUNT[getCountry()];
};

export const getCompanyName = () => {
  if (getCountry() == 'MY') {
    return HIREDLY_NAME.MY.name;
  } else if (getCountry() == 'SG') {
    return HIREDLY_NAME.SG.name;
  }

  return '';
};

export const getCountryFullName = () => {
  const country = getLocalStorageItem('country');

  return countryOptionsFullName[country];
};

export const getOtherCountry = () => {
  if (getCountry() == 'MY') {
    return 'Singapore';
  } else if (getCountry() == 'SG') {
    return 'Malaysia';
  }

  return '';
};
