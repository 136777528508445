import React from 'react';
import { Spacer } from '../Spacer/styles';
import s from './index.module.scss';
import Colors from '../../../src/styles/colors.module.scss';
import moment from 'moment';
import { getCurrency } from '../../hooks/useRegionalisation';

export function JobSlotBundles() {
  return (
    <div className={s.bundleContainer}>
      <div className={s.bundleItem}>
        <div>Single Token</div>
        <div>{getCurrency(3600)} </div>
      </div>
      <div className={s.bundleItem}>
        <div>
          <strong>5 Tokens</strong>
        </div>
        <div>{getCurrency(10000)}</div>
        <div className={s.small}>{getCurrency(2000)} per token</div>
      </div>
      <div className={s.bundleItem}>
        <div>
          <strong>10 Tokens</strong>
        </div>
        <div>{getCurrency(15000)}</div>
        <div className={s.small}>{getCurrency(1500)} per token</div>
      </div>
    </div>
  );
}

export function BoosterBundles() {
  return (
    <div className={s.bundleContainer}>
      <div className={s.bundleItem}>
        <div>Single Token</div>
        <div>{getCurrency(800)} </div>
      </div>
      <div className={s.bundleItem}>
        <div>
          <strong>5 Tokens</strong>
        </div>
        <div>{getCurrency(3800)}</div>
        <div className={s.small}>{getCurrency(760)} per token</div>
      </div>
      <div className={s.bundleItem}>
        <div>
          <strong>10 Tokens</strong>
        </div>
        <div>{getCurrency(7200)} </div>
        <div className={s.small}>{getCurrency(720)} per token</div>
      </div>
    </div>
  );
}

export function RefresherBundles() {
  return (
    <div className={s.bundleContainer}>
      <div className={s.bundleItem}>
        <div>Single Token</div>
        <div>{getCurrency(50)} </div>
      </div>
      <div className={s.bundleItem}>
        <div>
          <strong>15 Tokens</strong>
        </div>
        <div>{getCurrency(238)}</div>
        <div className={s.small}>{getCurrency(47.6)} per token</div>
      </div>
      <div className={s.bundleItem}>
        <div>
          <strong>30 Tokens</strong>
        </div>
        <div> {getCurrency(450)}</div>
        <div className={s.small}>{getCurrency(45)} per token</div>
      </div>
    </div>
  );
}

export function JobCreditBundles() {
  return (
    <div className={s.bundleContainer}>
      <div className={s.bundleItem}>
        <div>Single Token</div>
        <div>{getCurrency(800)}</div>
      </div>
      <div className={s.bundleItem}>
        <div>
          <strong>3 Tokens</strong>
        </div>
        <div>{getCurrency(1800)} </div>
        <div className={s.small}>{getCurrency(600)} per token</div>
      </div>
      <div className={s.bundleItem}>
        <strong>10 Tokens</strong>
        <div>{getCurrency(4500)} </div>
        <div className={s.small}>{getCurrency(450)} per token</div>
      </div>
    </div>
  );
}

export function TalentTokenBundles() {
  return (
    <div className={s.bundleContainer}>
      <div className={s.bundleItem}>
        <strong>5k Tokens</strong>
        <div>{getCurrency(1000)} </div>
        <div className={s.small}>{getCurrency(0.2)} PER Token</div>
      </div>
      <div className={s.bundleItem}>
        <strong>11k Tokens</strong>
        <div>{getCurrency(2000)} </div>
        <div className={s.small}> {getCurrency(0.18)} PER Token</div>
      </div>
      <div className={s.bundleItem}>
        <strong>20k Tokens</strong>
        <div>{getCurrency(3000)} </div>
        <div className={s.small}>{getCurrency(0.15)} PER Token</div>
      </div>
    </div>
  );
}
