import React, { useEffect, useState } from 'react';
import { FiberManualRecord as FiberManualRecordIcon } from '@mui/icons-material';
import * as yup from 'yup';
import { PopUpDialog } from '../../../../../components';
import Colors from '../../../../../styles/colors.module.scss';
import styles from './index.module.scss';
import { ErrorToast, SuccessToast } from '../../../../../utils/ToastUtils';
import { MUIButtonStyled } from '../../../../../components/MUIButton';
import { mutateCompanyTeamMember } from '../../../../../redux/actions/company_actions';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTeamMembers } from '../../../../../redux/actions/job_action';
import SharedTextField from '../../../../../components/SharedTextField';

const AddEditTeamMemberPopUp = ({
  open,
  handleClose,
  id,
  name,
  email,
  position,
  setStep
}) => {
  const dispatch = useDispatch();

  const mutatingCompanyTeamMember = useSelector(
    state => state.companies.mutatingCompanyTeamMember
  );

  const [formState, setFormState] = useState({
    name: '',
    email: '',
    position: ''
  });

  useEffect(() => {
    setFormState({
      name: name,
      email: email,
      position: position
    });
  }, [name, email, position]);

  const [errors, setErrors] = useState({});

  const validationSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    email: yup
      .string()
      .email('Invalid Email Format')
      .required('Email is required'),
    position: yup.string().required('Position is required')
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setErrors({
      ...errors,
      [name]: null
    });
    setFormState({
      ...formState,
      [name]: value
    });
  };

  const validate = async () => {
    try {
      await validationSchema.validate(formState, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      const newErrors = {};
      err.inner.forEach(error => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const onHandleClose = () => {
    handleClose();
  };

  const onSaveTeamMember = async () => {
    const isValid = await validate();
    if (!isValid) return;

    dispatch(
      mutateCompanyTeamMember({
        input: {
          ...(id ? { id } : null),
          name: formState.name,
          email: formState.email,
          position: formState.position
        }
      })
    )
      .then(async res => {
        if (id) {
          onHandleClose();
          await dispatch(fetchTeamMembers({ loadingState: false }));
        } else {
          setStep(2);
        }

        SuccessToast(`Team Member ${id ? 'Edited' : 'Added'} Successfully!`);
      })
      .catch(err => {
        ErrorToast(err.message);
      });
  };

  return (
    <PopUpDialog
      open={open}
      handleClose={onHandleClose}
      title={`${id ? 'Edit' : 'Add'} Team Member`}>
      <div style={{ padding: '20px 20px' }}>
        <div className={styles.contentContainer}>
          <div className={styles.row}>
            <p className={styles.rowText}>Name</p>
            <SharedTextField
              name="name"
              placeholder="Name"
              value={formState.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
            />
          </div>
          <div className={styles.row}>
            <p className={styles.rowText}>Email</p>
            <SharedTextField
              name="email"
              placeholder="Email"
              value={formState.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
            />
          </div>
          <div className={styles.row}>
            <p className={styles.rowText}>Position</p>
            <SharedTextField
              name="position"
              placeholder="Position"
              value={formState.position}
              onChange={handleChange}
              error={!!errors.position}
              helperText={errors.position}
            />
          </div>
        </div>

        {!id && (
          <div className={styles.dotContainer}>
            <FiberManualRecordIcon
              className={styles.dot}
              style={{ height: 14, width: 14, color: Colors.priPurple }}
            />
            <FiberManualRecordIcon className={styles.dot} />
            <FiberManualRecordIcon className={styles.dot} />
          </div>
        )}

        <div className={styles.buttonContainer}>
          <MUIButtonStyled
            label="Cancel"
            large
            outlinedBlack
            onClick={onHandleClose}
          />

          <MUIButtonStyled
            large
            disabled={mutatingCompanyTeamMember}
            onClick={onSaveTeamMember}
            label={id ? 'Save' : 'Next'}
          />
        </div>
      </div>
    </PopUpDialog>
  );
};

export default AddEditTeamMemberPopUp;
