import { Button } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateModelState } from '../../../redux/actions/talent_action';
import { HideAndUnhideModal } from './HideAndUnhide';
import { InsufficientModal } from './Insufficient';
import {
  CloseIconStyled,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
  Wrapper,
  TitleStyled,
  DividerStyled,
  IconButtonStyled
} from './styles';
import { UnlockModal } from './Unlock';

export const PopUpDialogs = props => {
  const dispatch = useDispatch();
  const modelState = useSelector(state => state.talents.modelState);
  const modelName = useSelector(state => state.talents.modelName);
  const talent = useSelector(state => state.talents.talent);

  const handleClose = () => {
    dispatch(updateModelState(false));
  };

  const renderModal = () => {
    if (modelName === 'Insufficient Tokens') {
      return <InsufficientModal handleClose={handleClose} />;
    } else if (modelName === 'Hide Profile Confirmation') {
      return <HideAndUnhideModal />;
    } else if (modelName === 'Unlock Profile Confirmation') {
      return <UnlockModal />;
    }
  };

  return (
    <DialogStyled
      open={modelState}
      height={talent.isPriceChanged ? 'auto' : '244px'}>
      <DialogTitleStyled>
        <Wrapper
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: '16px 24px 8px 24px' }}>
          <TitleStyled>{modelName}</TitleStyled>
          <IconButtonStyled onClick={handleClose}>
            <CloseIconStyled />
          </IconButtonStyled>
        </Wrapper>
        <DividerStyled />
      </DialogTitleStyled>

      {renderModal()}
    </DialogStyled>
  );
};
