import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { ErrorToast, SuccessToast } from '../../../../utils/ToastUtils';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeJobStatus,
  fetchJobs,
  updateJobsCurrentPage,
  resetAvailableResources,
  viewPackageSubscriptionDetails,
  fetchDeactivateReasons
} from '../../../../redux/actions/job_action';
import store from '../../../../redux/stores/store';
import {
  Text,
  MainContainer,
  DialogContentStyled,
  DialogTitleStyled,
  IconButtonStyled,
  DialogNoteStyled,
  ExpandableBarTitle,
  Title,
  ExpandedContent,
  CheckboxSection,
  BottomBox,
  SkipButton,
  SaveButton,
  CheckboxStyled,
  CheckboxText,
  InfoBox,
  ExclamationTriangleIconStyled,
  InfoText
} from './styles';
import { fetchMyCompanyStatus } from '../../../../redux/actions/company_actions';
import { Dialog, Divider } from '@mui/material';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { TextField, MenuItem } from '@mui/material';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

const DeactivateJobPopUpNew = ({
  open,
  handleClose,
  jobId,
  jobData,
  getCompanyAccountInfo,
  creationOrigin
}) => {
  const deactivateReasons = useSelector(state => state.jobs.deactivateReasons);

  const [archiveChat, setArchiveChat] = useState(false);
  const [noRestore, setNoRestore] = useState(false);
  const [validate, setValidate] = useState(false);
  const [errors, setErrors] = useState(false);

  const [value, setValue] = useState({
    deactivateReason: null,
    otherDeactivateReason: ''
  });

  const validationSchema = () => {
    if (value?.deactivateReason == '3' && !value?.otherDeactivateReason) {
      return false;
    } else {
      return true;
    }
  };

  const dispatch = useDispatch();
  const loading = useSelector(state => state.jobs.changingJobStatus);
  const jobsCurrentPage = useSelector(state => state.jobs.jobsCurrentPage);

  const allJobs = store.getState().jobs.jobs;

  const onDeactivateJobStatus = async values => {
    const checkEmptyFields = validationSchema();

    if (!checkEmptyFields) {
      setErrors(true);
      return;
    }

    const totalActiveJobsCount = store.getState().jobs.totalJobsCount['active'];
    const selectedFilter = store.getState().jobs.selectedFilter;
    const searchJobValue = store.getState().jobs.searchJobValue;
    const resourceName = jobData?.resourceType
      ? jobData?.resourceType
      : 'job_slot';

    try {
      const params = {
        active: false,
        jobId: jobId,
        archiveChat: archiveChat,
        deactivateReason: value?.deactivateReason,
        ...(value?.deactivateReason === deactivateReasons?.length - 1
          ? { otherReason: value?.otherDeactivateReason }
          : { otherReason: '' }),
        resourceType: resourceName,
        keywordHighlightToken: false
      };

      dispatch(changeJobStatus(params)).then(res => {
        if (res.type == 'CHANGE_JOB_STATUS_SUCCEED') {
          // current page, total jobs number
          //compute updated current page
          const updatedCount = totalActiveJobsCount - 1;
          const numOfJobsToCurrentPage = (jobsCurrentPage - 1) * 5;
          const nextCurrentPage =
            updatedCount > numOfJobsToCurrentPage
              ? jobsCurrentPage
              : jobsCurrentPage - 1;

          dispatch(updateJobsCurrentPage(nextCurrentPage));

          const offset = nextCurrentPage > 1 ? (nextCurrentPage - 1) * 5 : 0;

          //fetch jobs
          const params = {
            first: 5,
            offset: offset,
            jobTitle: searchJobValue,
            getCount: true,
            ...{ isActive: selectedFilter === 'All' ? null : selectedFilter },
            ...{
              orders: null
            }
          };

          dispatch(fetchJobs(params));
          dispatch(resetAvailableResources()).then(() => {
            dispatch(fetchMyCompanyStatus());
            dispatch(viewPackageSubscriptionDetails());
          });

          if (getCompanyAccountInfo) {
            getCompanyAccountInfo();
          }

          SuccessToast('Job has been deactivated!');
          handleClose();
        } else {
          ErrorToast(res?.error ? res?.error : 'Failed to update job!');
        }
      });
    } catch (err) {
      ErrorToast(err.message);
    }
  };

  const handleQuestionChange = e => {
    setValue({
      ...value,
      otherDeactivateReason: e.target.value
    });

    setErrors(false);
  };

  const internJob = jobData?.resourceType == 'internship';

  const spotlight =
    jobData?.resourceType == 'job_slot_plus' ||
    jobData?.resourceType == 'job_slot_max' ||
    jobData?.resourceType == 'job_slot_plus_6m' ||
    jobData?.resourceType == 'job_slot_max_6m'
      ? false
      : (!jobData?.enableSpotlightJob || false) &&
        (jobData?.spotlightExpired || false);
  const crossBorder =
    (jobData?.crossedBorder || false) &&
    (jobData?.crossBorderCountryExpired || false);
  const keywordHighlight =
    jobData?.resourceType == 'job_slot_max' ||
    jobData?.resourceType == 'job_slot_max_6m'
      ? false
      : (jobData?.keywordHighlight || false) &&
        (jobData?.keywordHighlightExpired || false);
  const feature =
    (!jobData?.enableFeatureJob || false) && (jobData?.boostedExpired || false);

  const checkActiveProductOnJob = () => {
    return spotlight || crossBorder || keywordHighlight || feature;
  };

  const isShowInfo = checkActiveProductOnJob();

  useEffect(() => {
    const isValid = () => {
      const deactivateReasonRequired = internJob || isShowInfo;
      if (deactivateReasonRequired) {
        if (!noRestore || !value?.deactivateReason) {
          setValidate(false);
          return false;
        }
      } else {
        if (!value?.deactivateReason) {
          setValidate(false);
          return false;
        }
      }
      setValidate(true);
      return true;
    };
    isValid();
  }, [value, noRestore, internJob, isShowInfo]);

  useEffect(() => {
    setValue({
      deactivateReason: null,
      otherDeactivateReason: ''
    });
    setNoRestore(false);
    setErrors(false);
  }, [open]);

  useEffect(() => {
    // temp solution: use this to prevent redundant fetch,
    // proper fix should have loading fetching
    if (deactivateReasons.length > 0) return;

    dispatch(fetchDeactivateReasons());
  }, [deactivateReasons]);

  const renderContent = () => (
    <MainContainer>
      <ExpandableBarTitle>
        <Title>Reason for deactivating</Title>
        <TextField
          select
          hideLabel
          variant="outlined"
          className={styles.textField}
          value={value?.deactivateReason || 'placeholder'}
          // className={classNames(classes.textField, styles.textField, className)}
          // style={style}
          sx={{
            width: '100%',
            height: '100%',

            '& .Mui-focused fieldset': {
              borderColor: '#512ACC !important'
            },

            '& .MuiInputBase-root': {
              borderRadius: '8px',
              padding: '0px 0px 0px 5px',
              color: !value?.deactivateReason ? '#bdbdbd' : 'black', // Text color

              '&:focus fieldset': {
                borderColor: '#512ACC'
              }
            },

            '& .MuiFormLabel-root': {
              top: '0px'
            }
          }}
          placeholder={'Please select'}
          name="deactivateReason"
          SelectProps={{
            MenuProps: {
              PaperProps: {
                // className: classNames(classes.dropdownMenu, styles.dropdownMenu, className),
                sx: {
                  '.MuiMenuItem-root': {
                    whiteSpace: 'unset',
                    '&:hover': {
                      background: '#EFEFFD'
                    }
                  }
                }
              }
            }
          }}>
          <MenuItem disabled value="placeholder" style={{ display: 'none' }}>
            {'Please select'}
          </MenuItem>
          {deactivateReasons ? (
            Object.keys(deactivateReasons).map(item => (
              <MenuItem
                sx={{ height: '36px' }}
                key={deactivateReasons[item].id}
                value={deactivateReasons[item].id}
                onClick={() =>
                  setValue({
                    ...value,
                    deactivateReason: deactivateReasons[item].id
                  })
                }>
                {deactivateReasons[item].title || deactivateReasons[item].name}
              </MenuItem>
            ))
          ) : (
            <div></div>
          )}
        </TextField>

        {deactivateReasons.length === Number(value?.deactivateReason) ? (
          <>
            <div style={{ marginTop: 0, width: '100%' }}>
              <TextField
                variant="outlined"
                name="otherDeactivateReason"
                sx={{
                  width: '100%',
                  height: '100%',

                  '& .Mui-focused fieldset': {
                    borderColor: '#512ACC !important'
                  },

                  '& .MuiInputBase-root': {
                    borderRadius: '8px',
                    padding: '0px 0px 0px 5px',

                    '&:focus fieldset': {
                      borderColor: '#512ACC'
                    }
                  },

                  '& .MuiFormLabel-root': {
                    top: '0px'
                  }
                }}
                onChange={handleQuestionChange}
              />
            </div>

            {errors && (
              <>
                <p
                  style={{
                    color: '#BE4242',
                    fontSize: '12px',
                    lineHeight: '20px',
                    display: 'flex',
                    padding: '0px 8px',
                    alignItems: 'center',
                    gap: '8px',
                    margin: '0'
                  }}>
                  <ExclamationCircleIcon
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '#BE4242'
                    }}
                  />
                  Deactivate Reason is required
                </p>
              </>
            )}
          </>
        ) : null}
      </ExpandableBarTitle>

      {/* Middle message */}
      {internJob || isShowInfo ? (
        <ExpandedContent>
          <InfoBox>
            <ExclamationTriangleIconStyled />
            <InfoText>
              <p>
                The following active token(s) will not be restored once this job
                post has been deactivated:
              </p>

              <div>
                <ul>
                  {internJob && (
                    <>
                      <li>Internship Token</li>
                    </>
                  )}

                  {spotlight && (
                    <>
                      <li>Spotlight Jobs</li>
                    </>
                  )}

                  {feature && (
                    <>
                      <li>Featured Token</li>
                    </>
                  )}

                  {keywordHighlight && (
                    <>
                      <li>Key Highlights Token</li>
                    </>
                  )}
                  {crossBorder && (
                    <>
                      <li>Cross Border Token</li>
                    </>
                  )}
                </ul>
              </div>
            </InfoText>
          </InfoBox>
        </ExpandedContent>
      ) : null}

      <CheckboxSection>
        {internJob || isShowInfo ? (
          <div
            style={{ display: 'flex', gap: '12px', alignItems: 'flex-start' }}
            onClick={() => setNoRestore(!noRestore)}>
            <CheckboxStyled
              name={'archive'}
              color="default"
              inputProps={{
                'aria-label': 'checkbox with default color'
              }}
              checked={noRestore}
              onChange={() => setNoRestore(!noRestore)}
            />
            <div>
              <CheckboxText>
                <p style={{ margin: '0' }}>
                  I understand the token(s) will not be restored
                </p>
              </CheckboxText>
            </div>
          </div>
        ) : null}

        <div
          style={{ display: 'flex', gap: '12px', alignItems: 'flex-start' }}
          onClick={() => setArchiveChat(!archiveChat)}>
          <CheckboxStyled
            name={'archive'}
            color="default"
            inputProps={{
              'aria-label': 'checkbox with default color'
            }}
            checked={archiveChat}
            onChange={() => setArchiveChat(!archiveChat)}
          />
          <div>
            <CheckboxText>
              <p style={{ margin: '0' }}>
                I want to archive all related chats linked to this job{' '}
                <i>(optional)</i>
              </p>
            </CheckboxText>
          </div>
        </div>
      </CheckboxSection>

      <BottomBox>
        <SkipButton onClick={handleClose}>Cancel</SkipButton>
        <SaveButton
          disabled={!validate || loading}
          onClick={() => onDeactivateJobStatus()}>
          Deactivate Job
        </SaveButton>
      </BottomBox>
    </MainContainer>
  );

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      sx={{ maxWidth: '541px', margin: 'auto' }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: 8,
          width: '541px',
          background: '#fff',
          maxWidth: '541px',
          margin: '0'
        }
      }}>
      <DialogTitleStyled>
        Deactivate job post?
        <IconButtonStyled onClick={handleClose}>
          <XMarkIcon height={24} width={24} />
        </IconButtonStyled>
      </DialogTitleStyled>
      <Divider />

      <DialogContentStyled>{renderContent()}</DialogContentStyled>
    </Dialog>
  );
};

export default DeactivateJobPopUpNew;
