import React, { useState, useRef, useEffect } from 'react';
import {
  ArrowFilter,
  ArrowFilterChild,
  FirstArrowFilter,
  FilterTextContainer,
  FilterText,
  FilterWrapper,
  SkeletonStyled,
  SkeletonContainer
} from './styles';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import { JOB_APPLICATION_STATUS } from '../../../utils/Constants';

const StatusFilter = ({
  jobData,
  isAts,
  statusFilter,
  loading,
  selectedChip,
  setIsRefresh,
  setIsSelected,
  setSelectedChip,
  applicationStatus,
  setApplicationStatus
}) => {
  const handleSelectedChip = (index, title, count) => {
    if (isAts) return;
    if (selectedChip === index) return;
    if (count == 0) return;

    setSelectedChip(index);
    setIsRefresh(true);

    if (setIsSelected) {
      setIsSelected(null);
    }

    let shortlisted = title.includes('shortlisted');
    let applicants = title.includes('applicants');
    let undecided = title.includes('undecided');
    let kiv = title.includes('kiv');
    let interview = title.includes('interview');
    let offer = title.includes('offered');
    let status = '';

    if (shortlisted) {
      status = JOB_APPLICATION_STATUS.SHORTLISTED;
    } else if (applicants) {
      status = JOB_APPLICATION_STATUS.ALL;
    } else if (undecided) {
      status = JOB_APPLICATION_STATUS.UNDECIDED;
    } else if (kiv) {
      status = JOB_APPLICATION_STATUS.KIV;
    } else if (interview) {
      status = JOB_APPLICATION_STATUS.INTERVIEW;
    } else if (offer) {
      status = JOB_APPLICATION_STATUS.OFFER;
    }
    setApplicationStatus(status);
  };

  const applicationCounts = useSelector(
    state => state.applications.applicationCount
  );
  const firstArrowRef = useRef();
  const arrowRef = useRef();

  const getCountByTitle = item => {
    if (isAts) {
      if (applicationCounts[item?.title]) return applicationCounts[item?.title];
    }

    return item.count;
  };

  const checkFirstArrow = () => {
    const clientWidth =
      firstArrowRef.current?.clientWidth - 50 <=
      firstArrowRef.current?.children[0]?.clientWidth;

    setFirstArrowStatus(clientWidth);
  };

  const checkArrow = () => {
    const clientWidth =
      arrowRef.current?.clientWidth - 50 <=
      arrowRef.current?.children[1]?.clientWidth;

    setArrowStatus(clientWidth);
  };

  useEffect(() => {
    checkFirstArrow();
    checkArrow();
  }, []);

  const [firstArrowStatus, setFirstArrowStatus] = useState(false);
  const [arrowStatus, setArrowStatus] = useState(false);

  const isSelected = key => {
    return applicationStatus == key;
  };

  const getArrowData = statusFilter => {
    const slicedData = statusFilter.slice(1, statusFilter.length);

    let swapData = slicedData[2];
    slicedData[2] = slicedData[1];
    slicedData[1] = swapData;

    return slicedData;
  };

  return (
    <FilterWrapper isAts={isAts}>
      <>
        {statusFilter.slice(0, 1).map((item, index) => {
          return (
            <Tooltip
              key={index}
              placement="bottom"
              title={
                getCountByTitle(item) == 0 ? <span>0 applicants</span> : ''
              }>
              <FirstArrowFilter
                id="applicant-list-all"
                loading={loading}
                isAts={isAts}
                count={getCountByTitle(item)}
                key={index}
                onClick={() =>
                  handleSelectedChip(0, item.title, getCountByTitle(item))
                }
                ref={firstArrowRef}>
                <>
                  <FilterTextContainer type="count">
                    {(isAts && loading) ||
                    getCountByTitle(item) == 'undefined' ? (
                      <SkeletonContainer>
                        <SkeletonStyled variant="text" width={'80px'} />
                      </SkeletonContainer>
                    ) : (
                      <Tooltip
                        title={
                          <span
                            style={{ fontSize: '12px', fontFamily: 'Inter' }}>
                            {getCountByTitle(item)}
                          </span>
                        }
                        interactive
                        disableHoverListener={!firstArrowStatus}>
                        <FilterText
                          type="count"
                          count={getCountByTitle(item)}
                          selected={isSelected(item.key) ? 'true' : 'false'}>
                          {getCountByTitle(item)}
                        </FilterText>
                      </Tooltip>
                    )}
                  </FilterTextContainer>

                  <FilterTextContainer type="status">
                    {(isAts && loading) ||
                    getCountByTitle(item) == 'undefined' ? (
                      <SkeletonContainer>
                        <SkeletonStyled variant="text" width={'80px'} />
                      </SkeletonContainer>
                    ) : (
                      <FilterText
                        type="status"
                        count={getCountByTitle(item)}
                        selected={isSelected(item.key) ? 'true' : 'false'}>
                        {item.title}
                      </FilterText>
                    )}
                  </FilterTextContainer>
                </>
              </FirstArrowFilter>
            </Tooltip>
          );
        })}
        {getArrowData(statusFilter).map((item, index) => {
          return (
            <Tooltip
              key={index}
              placement="bottom"
              title={
                getCountByTitle(item) == 0 ? <span>0 applicants</span> : ''
              }>
              <ArrowFilter
                id={
                  item.title == 'undecided'
                    ? 'applicant-list-undecided'
                    : item.title == 'shortlisted'
                    ? 'applicant-list-shortlisted'
                    : item.title == 'kiv'
                    ? 'applicant-list-kiv'
                    : item.title == 'interview'
                    ? 'applicant-list-interview'
                    : 'applicant-list-offer'
                }
                loading={loading}
                isAts={isAts}
                count={getCountByTitle(item)}
                key={index}
                onClick={() =>
                  handleSelectedChip(
                    index + 1,
                    item.title,
                    getCountByTitle(item)
                  )
                }
                ref={arrowRef}>
                <ArrowFilterChild />
                <FilterTextContainer type="count">
                  {(isAts && loading) ||
                  getCountByTitle(item) == 'undefined' ? (
                    <SkeletonContainer>
                      <SkeletonStyled variant="text" width={'80px'} />
                    </SkeletonContainer>
                  ) : (
                    <Tooltip
                      title={
                        <span style={{ fontSize: '12px', fontFamily: 'Inter' }}>
                          {getCountByTitle(item)}
                        </span>
                      }
                      interactive
                      disableHoverListener={!arrowStatus}>
                      <FilterText
                        type="count"
                        count={getCountByTitle(item)}
                        selectedStatus={handleSelectedChip}
                        selected={isSelected(item.key) ? 'true' : 'false'}>
                        {getCountByTitle(item)}
                      </FilterText>
                    </Tooltip>
                  )}
                </FilterTextContainer>
                <FilterTextContainer type="status">
                  {(isAts && loading) ||
                  getCountByTitle(item) == 'undefined' ? (
                    <SkeletonContainer>
                      <SkeletonStyled variant="text" width={'80px'} />
                    </SkeletonContainer>
                  ) : (
                    <FilterText
                      type="status"
                      count={getCountByTitle(item)}
                      selected={isSelected(item.key) ? 'true' : 'false'}>
                      {item.title === 'kiv' ? 'KIV' : item.title}
                    </FilterText>
                  )}
                </FilterTextContainer>
              </ArrowFilter>
            </Tooltip>
          );
        })}
      </>
    </FilterWrapper>
  );
};

export default StatusFilter;
