import { styled as styling } from '@mui/material/styles';
import { Grid } from '@mui/material';

export const CanvasWrapper = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '40px',
  flexWrap: 'nowrap',
  maxWidth: '1440px',
  margin: 'auto',
  padding: '40px 0px 40px 0px',
  justifyContent: 'center',
  position: "relative"
}));

export const CanvasContent = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  maxWidth: '920px'
}));
