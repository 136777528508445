import { styled as styling } from '@mui/material/styles';
import {
  Close as CloseIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon
} from '@mui/icons-material'
import Colors from '../../../src/styles/colors.module.scss';

export const Text = styling('span')((props) => ({ 
  ...(props.$type == 'skills-tag-text' && {
    fontSize: '14px',
    fontFamily: 'Inter'
  }),

  fontSize: '14px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.87)',
}));

export const SelectFormContainer = styling('div')({
  minHeight: '150px',
  maxHeight: '170px',
  width: '100%',
});

export const InputStyled = styling('input')(({ isRed }) => ({
  display: 'flex',
  width: '100%',
  height: '48px',
  padding: '8px 215px 8px 20px',
  alignItems: 'center',
  gap: '16px',
  borderRadius: '8px',
  border: `1px solid ${isRed ? '#F75443' : '#D8D8D8'}`,
  background: '#fff',

  '&:focus': {
    outline: `1px solid ${isRed ? '#F75443' : Colors.priPurple}`,
    // outlineColor: isRed ? '#F75443' : Colors.priPurple,
  },
  
  '&::placeholder':{
      color: 'rgba(0, 0, 0, 0.38)',
      fontSize: '0.85rem',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
}));

export const TagContainer = styling('div')({
  display: 'flex',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  gap: '16px',
  alignSelf: 'stretch',
  flexWrap: 'wrap',
  position: 'relative',
  top: '-96px',
  minHeight: '96px',
});

export const TagStyled = styling('div')({
  display: 'flex',
  width: 'calc(100% / 3 - 12px)',
  padding: '6px 12px 8px 16px',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '8px',
  border: '1px solid #512ACC',
  background: '#EFEFFD',
  minHeight: '40px',
});

export const CloseIconStyled = styling(CloseIcon)({
  fontSize: '14px',
  color: 'rgba(0, 0, 0, 0.38)',
  '&:hover': {
    cursor: 'pointer',
  },

});

export const OptionsContainerStyled = styling('div')({
  zIndex: '2',
  background: Colors.priWhite,
  position: 'absolute',
  top: '120%',
  width: '100%',
  borderRadius: '10px',
  overflow: 'auto',
  maxHeight: '200px',
  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
});

export const OptionStyled = styling('div')(({ 
  $highlight 
}) => ({
  padding: '12px 24px',

  ...($highlight ? { background: Colors.secLightPurple } : {}),
  '&:hover': {
    cursor: 'pointer',
    background: Colors.secLightPurple,
  },

}));

export const KeywordButton = styling('button')((props) => ({
  border: 'none',
  display: 'flex',
  width: '128px',
  height: '36px',
  padding: '0px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  flexShrink: '0',
  borderRadius: '4px',
  background: props.disabled ? '#D8D8D8' : '#2E2E2E',
  color: '#FFF',
  fontSize: '14px',
  fontWeight: '700',
  lineHeight: 'normal',
  position: 'absolute',
  top: '6px',
  right: '6px',
  cursor: props.disabled ? 'default' : 'pointer',
}));

export const TextLimit = styling('div')((props) => ({
  textAlign: 'right',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '20px',
  width: '34px',
  position: 'absolute',
  top: '0',
  right: '160px',
  padding: '13px',
  color: props.isExceedingLimit ? '#F75443' : 'rgba(0, 0, 0, 0.38)',
}));

export const TagContainerSkeleton = styling('div')({
  display: 'flex',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  gap: '16px',
  alignSelf: 'stretch',
  flexWrap: 'wrap',
});

export const TagStyledSkeleton = styling('div')({
  display: 'flex',
  width: 'calc(100% / 3 - 12px)',
  padding: '8px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '8px',
  border: '1px dashed #AEAEAE',
  background: '#FFF',
  height: '40px',
});

export const ErrorMessage = styling('div')({
  padding: '0px 8px',
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  alignItems: 'center',
  color: '#BE4242',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '20px',
});

export const ClearInput = styling('button')((props) => ({
  position: 'absolute',
  right: '30px',
  top: '0',
  padding: '16px 0',
  border: 'none',
  background: 'transparent',
  cursor: 'pointer',
  display: props.isFill ? 'flex' : 'none',
  alignItems: 'center',
  height: '100%',
}));