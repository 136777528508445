import {
  ButtonContainer,
  GridStyled,
  ImageGrid,
  LogoTitle,
  LogoTitlePurple,
  OuterLogoContainer,
  SliderBoldTitle,
  SliderDescription,
  SliderInnerContainer,
  SliderStyled,
  SliderWrapper,
  TextContainer
} from './styles';
import React, { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { MUIButtonStyled } from '../../../components/MUIButton';
import { useDispatch, useSelector } from 'react-redux';
import { updateContactSalesPopupStatus } from '../../../redux/actions/job_action';
import {
  fetchProductCarousel,
  fetchProductTrustedLogo,
  updateProductBundle
} from '../../../redux/actions/product_action';
import { useNavigate } from 'react-router-dom';
import { getCountry } from '../../../hooks/useRegionalisation';

export default function AdsSlider() {
  const history = useNavigate();
  const [activeSlide, setActiveSlide] = useState(0);
  const dispatch = useDispatch();
  const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: null,
    prevArrow: null,
    appendDots: dots => (
      <div
        style={{
          borderRadius: '10px',
          padding: '10px',
          width: '100%',
          marginBottom: '13px',
          paddingRight: '35px'
        }}>
        <div
          style={{
            width: 'auto',
            display: 'flex',
            gap: '38px',
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}>
          {dots}
        </div>
      </div>
    ),
    customPaging: i => (
      <div
        style={{
          width: '50px',
          height: '8px',
          borderRadius: '100px',
          transition: 'all 0.3s',
          backgroundColor:
            activeSlide === i ? 'white' : 'rgba(255, 255, 255, 0.40)'
        }}></div>
    ),
    afterChange: current => setActiveSlide(current)
  };

  useEffect(() => {
    dispatch(fetchProductCarousel());
    dispatch(fetchProductTrustedLogo());
  }, []);

  const productCarousel = useSelector(state => state.products.productCarousel);
  const trustedLogo = useSelector(state => state.products.trustedLogo);

  const InnerContainer = () => {
    const bundlePopUpHandler = () => {
      if (getCountry() === 'SG') {
        history('/purchase/choose');
      } else {
        dispatch(updateProductBundle(1));
      }
    };

    const contactUsHandler = () => {
      dispatch(updateContactSalesPopupStatus(true));
    };

    return (
      <SliderInnerContainer>
        <TextContainer>
          <SliderBoldTitle>Time to transform your recruitment.</SliderBoldTitle>
          <SliderDescription>{productCarousel?.heroSubtitle}</SliderDescription>
        </TextContainer>
        <ButtonContainer>
          <MUIButtonStyled
            $width="160px"
            $height="56px"
            outlinedBlack
            label="View Bundles"
            onClick={() => bundlePopUpHandler()}
          />
          <MUIButtonStyled
            $width="160px"
            $height="56px"
            solidBlack
            label="Contact Us"
            onClick={() => contactUsHandler()}
          />
        </ButtonContainer>
      </SliderInnerContainer>
    );
  };

  return (
    <div>
      {/* slider */}
      <SliderWrapper>
        <InnerContainer />
        <SliderStyled {...settings}>
          {productCarousel?.heroBanners?.map(image => {
            const imageUrl = image?.desktopBanner?.data?.attributes?.url;
            return <ImageGrid image={imageUrl} />;
          })}
        </SliderStyled>
      </SliderWrapper>
    </div>
  );
}
