import React, { useEffect, useRef, useState } from 'react';
import ContentCard from '../components/ContentCard';
import { Grid, InputAdornment, TextField } from '@mui/material';
import { LocationOn } from '@mui/icons-material';
import GoogleMapReact from 'google-map-react';
import { useDispatch, useSelector } from 'react-redux';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import {
  mutateCompanyProfile,
  updateCompanySidebar
} from '../../../redux/actions/company_actions';
import { ErrorToast, SuccessToast } from '../../../utils/ToastUtils';
import { TextFieldLargeStyled, TextFieldStyled } from './styles';
import SaveButton from '../components/SaveButton';
import SearchIcon from '@mui/icons-material/Search';
import { isEmpty } from 'lodash';

const { REACT_APP_GOOGLE_API_KEY } = process.env;

export default function EditState(props) {
  const { setView } = props;
  const companyProfile = useSelector(state => state.companies.companyProfile);
  const companyCompletion = useSelector(
    state => state.companies.companyCompletion
  );

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState('');
  const [addressOriginal, setAddressOriginal] = useState('');
  const [preformattedAddress, setPreFormattedAddress] = useState('');
  const [formattedAddress, setFormattedAddress] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const ref = useRef(null);
  const [selected, setSelected] = useState(false);

  const hasChanges = () => {
    return addressOriginal !== address;
  };

  const [coordinates, setCoordinates] = useState({
    lat: 3.139003,
    lng: 101.686855
  });

  const onSubmitAddress = async () => {
    let currentCoordinates = coordinates;
    let currentFormattedAddress = formattedAddress;

    if (
      companyProfile?.address != address &&
      address != '' &&
      address != preformattedAddress
    ) {
      try {
        setIsSaving(true);
        const results = await geocodeByAddress(address);
        const latLng = await getLatLng(results[0]);

        if (results && results.length > 0) {
          currentFormattedAddress = results[0].formatted_address;
        }

        if (latLng) {
          currentCoordinates = latLng;
        }
      } catch (err) {
        console.log(err.message);
        // ErrorToast(err.message);
      }
    }

    setLoading(true);
    dispatch(
      mutateCompanyProfile({
        input: {
          address: currentFormattedAddress,
          latitude:
            currentFormattedAddress === '' ? null : currentCoordinates.lat,
          longitude:
            currentFormattedAddress === '' ? null : currentCoordinates.lng
        }
      })
    )
      .then(res => {
        window.dataLayer.push({
          event: 'save-cp-address'
        });

        if (res.type === 'FETCH_COMPANY_PROFILE_SUCCEED') {
          SuccessToast('Our Address Updated Successfully!');
          dispatch(
            updateCompanySidebar({
              ...companyCompletion,
              companyAddress: true
            })
          );
          setView(true);
        } else {
          ErrorToast(res.errorMessage);
        }
      })
      .catch(err => {
        ErrorToast(err.message);
      })
      .finally(() => {
        setLoading(false);
      });

    setIsSaving(false);
  };

  const handleChange = value => {
    setAddress(value);
  };

  const handleSelect = async value => {
    try {
      ref.current.blur();
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      setAddress(value);
      setPreFormattedAddress(value);
      setFormattedAddress(results[0].formatted_address);
      setCoordinates(latLng);
      setSelected(true);
    } catch (err) {
      ErrorToast(err.message);
    }
  };

  useEffect(() => {
    setAddress(companyProfile?.address);
    setAddressOriginal(companyProfile?.address);
    setFormattedAddress(companyProfile?.address);
    if (!companyProfile?.latitude || !companyProfile?.longitude) {
      setCoordinates({
        lat: 3.139003,
        lng: 101.686855
      });
    } else {
      setCoordinates({
        lat: companyProfile?.latitude,
        lng: companyProfile?.longitude
      });
    }
  }, [companyProfile]);

  useEffect(() => {}, [coordinates]);

  return (
    <ContentCard
      title="Company Address"
      subTitle="Let jobseekers know where your company is based."
      discardButton
      noChanges={!hasChanges()}
      cancelIcon={!isEmpty(companyProfile?.address)}
      cancelOnClick={() => setView(true)}>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '16px'
        }}>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            gap: '16px',
            justifyContent: 'space-between'
          }}>
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              width: '233px'
            }}>
            <PlacesAutocomplete
              value={address}
              onChange={handleChange}
              onSelect={handleSelect}>
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <div>
                  <TextFieldStyled
                    {...getInputProps()}
                    ref={ref}
                    placeholder="Search Location"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon sx={{ color: '#AEAEAE' }} />
                        </InputAdornment>
                      )
                    }}
                  />
                  {!isEmpty(suggestions) && (
                    <Grid
                      sx={{
                        width: '305px',
                        overflow: 'hidden',
                        position: 'absolute',
                        backgroundColor: 'white !important',
                        zIndex: 999,
                        marginTop: '4px',
                        padding: '4px 0px',
                        borderRadius: '8px',
                        boxShadow: `0px 2px 4px 0px rgba(0, 0, 0, 0.25)`,
                        border: '1px solid #AEAEAE'
                      }}>
                      {/* {loading && <div>Loading...</div>} */}
                      {suggestions.map(suggestion => {
                        const style = {
                          cursor: 'pointer',
                          padding: '4px 12px',
                          minWidth: '263px',
                          fontSize: '12px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          backgroundColor: 'white',
                          transition: 'background-color 0.3s',
                          lineHeight: '20px'
                          // maxHeight: '60px',
                        };

                        const hoverStyle = {
                          backgroundColor: '#EFEFFD'
                        };

                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              style: {
                                ...style,
                                ...(suggestion.active ? hoverStyle : {})
                              }
                            })}>
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </Grid>
                  )}
                </div>
              )}
            </PlacesAutocomplete>

            <TextFieldLargeStyled
              disabled
              multiline
              value={formattedAddress}
              onChange={event => setFormattedAddress(event.target.value)}
              rows={7}
            />
          </Grid>

          <Grid
            sx={{
              width: '640px',
              height: '240px',
              borderRadius: '16px',
              border: '1px solid #AEAEAE',
              overflow: 'hidden',
              zIndex: 1
            }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: REACT_APP_GOOGLE_API_KEY
              }}
              center={
                coordinates || {
                  lat: 3.139003,
                  lng: 101.686855
                }
              }
              defaultZoom={15}>
              <LocationOn
                sx={{
                  color: '#f75443',
                  fontSize: 40
                }}
                lat={coordinates?.lat || 3.139003}
                lng={coordinates?.lng || 101.686855}
              />
            </GoogleMapReact>
          </Grid>
        </Grid>
        <SaveButton
          disabled={loading || !hasChanges() || isEmpty(address) || !selected}
          onClick={onSubmitAddress}>
          Save
        </SaveButton>
      </Grid>
    </ContentCard>
  );
}
