import React, { useState, useEffect, useRef } from 'react';
import { Grid, Tooltip } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import styles from './index.module.scss';
import SendBird from '../../../../../utils/Sendbird';
import InterruptAiviPopUp from '../InterruptAiviPopUp';
import SkeletonLoader from '../../../../Chats/ChatSectionComponent/SkeletonLoader';
import { Button } from '../../../../../components';
import _ from 'lodash';
import moment from 'moment';
import { ReactComponent as AiviIcon } from '../../../../../assets/common/aivi-icon.svg';
import Linkify from 'react-linkify';
import { HeightSpacer, Text, TextContainer, TextFieldStyled } from './styles';
import { useDispatch } from 'react-redux';
import { mutateSendBirdChannelUrl } from '../../../../../redux/actions/chat_action';
import { getSendbirdID } from '../../../../../hooks/useENV';
import VirtualInterviewPopUpNew from '../../../../Jobs/PopUps/VirtualInterviewPopUpNew';

const sb = new SendBird(getSendbirdID());
let accountInfo;
let idleTimer;
let hidden = null;
let visibilityChange = null;

// TO IMPROVE - make this as shared component which will be used by single candidate page and chat page
const CandidateChat = props => {
  const {
    chatChannel,
    candidate,
    isCandidatesEmpty,
    updateCandidateFromList,
    componentOrigin,
    source
  } = props;

  const [chatText, setChatText] = useState('');
  const [currentChannel, setCurrentChannel] = useState(null);
  const [sbConnected, setSbConnected] = useState(false);
  const [isInterruptAiviOpen, setIsInterruptAiviOpen] = useState(false);
  const [channelNotFound, setChannelNotFound] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [isCreatingChannel, setIsCreatingChannel] = useState(false);
  const [isVirtualInterviewOpen, setIsVirtualInterviewOpen] = useState(false);
  const [messages, setMessages] = useState([]);

  const chatContainerRef = useRef();
  const messagesRef = useRef();
  const chatRef = useRef();
  const sbConnectedRef = useRef();
  const dispatch = useDispatch();

  messagesRef.current = messages;
  chatRef.current = currentChannel;
  sbConnectedRef.current = sbConnected;
  
  // currentChannel is empty
  // console.log(chatRef, currentChannel?.url)


  const user = candidate?.user || candidate?.jobApplication?.user;
  const job = candidate?.job || candidate?.jobApplication?.job;
  const application = candidate?.state ? candidate : candidate?.jobApplication;

  const connectSendBird = async () => {
    accountInfo = localStorage.getItem('accountInfo');
    accountInfo = accountInfo ? JSON.parse(accountInfo) : accountInfo;
    const connectionStatus = sb ? sb.getConnectionStatus() : '';

    if (accountInfo && sb && connectionStatus !== 'OPEN') {
      sb.connect(accountInfo.accountId)
        .then(() => {
          setSbConnected(true);
        })
        .catch(() => {
          console.log('failed to connect to sendbird');
          // Sendbird connection failed
        });
    } else if (connectionStatus === 'OPEN') {
      setSbConnected(true);
    }
  };

  const getMessages = refresh => {
    if (sbConnected) {
      sb.getChannelInfo(chatChannel)
        .then(channel => {
          setCurrentChannel(channel);
          setChannelNotFound(false);
          const params = {
            sb,
            channel,
            refresh
          };

          if (params.channel && params.channel.createdAt) {
            if (sb.getConnectionStatus() === 'OPEN') {
              sb.getMessageList(params)
                .then(chatMessages => {
                  setLoadingMessages(false);
                  setMessages([...chatMessages]);
                  const cParams = {
                    channel
                  };

                  if (sb.getConnectionStatus() === 'OPEN') {
                    sb.markAsRead(cParams);
                  } else {
                    setLoadingMessages(false);
                    setSbConnected(false);
                    connectSendBird();
                  }
                })
                .catch(() => {
                  setLoadingMessages(false);
                });
            } else {
              setLoadingMessages(false);
              setSbConnected(false);
              connectSendBird();
            }
          }
        })
        .catch(error => {
          setMessages([]);
          setCurrentChannel(null);
          setChannelNotFound(true);
          setLoadingMessages(false);
        });
    } else {
      setMessages([]);
      setLoadingMessages(false);
      connectSendBird();
    }
  };

  const updateMessages = (channelUrl, message) => {
    if (channelUrl === chatRef.current?.url) {
      setMessages([...messagesRef.current, message]);
    }
  };

  const stopAiviSession = () => {
    return new Promise(resolve => {
      if (sbConnected) {
        sb.updateChannelMetaData(chatRef.current, {
          cb_status: 12
        })
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      } else {
        resolve(false);
      }
    });
  };

  const sendMessage = () => {
    if (sbConnected && chatRef.current && chatText !== '') {
      const cMessage = chatText;
      setChatText('');
      sb.sendMessage(chatRef.current, cMessage).then(message => {
        setMessages([...messagesRef.current, message]);
      });
    }
  };

  const sendAdminMessage = updateChannelMetaData => {
    setIsInterruptAiviOpen(false);
    if (sbConnected) {
      sb.adminMessage(
        chatChannel,
        'Employer has ended Virtual Interview.'
      ).then(() => {
        if (updateChannelMetaData) {
          stopAiviSession().then(res => {
            if (res) {
              // TO IMPROVE - hacky way to 100% ensure no concurrent update on messages
              setTimeout(() => {
                sendMessage();
              }, 200);
            }
          });
        }
      });
    }
  };

  const isAiviSession = chat => {
    let isAivi = false;
    if (chat && chat.members) {
      chat.members.forEach(member => {
        if (member.metaData?.role?.toLowerCase() === 'bot') {
          isAivi = true;
        }
      });
    }

    return isAivi;
  };

  const isApplicant = (jobApplication, message) => {
    if (jobApplication?.user?.id && message?._sender?.userId) {
      if (jobApplication?.user?.id === message._sender?.userId) {
        return true;
      }
    }

    return false;
  };

  const getMessageType = (jobApplication, message) => {
    let type = 'jobseeker';
    if (message.messageType === 'admin') {
      type = 'employer';
    } else if (message._sender?.metaData?.role) {
      if (message._sender.metaData.role.toLowerCase() === 'bot') {
        type = 'employer';
      }
    } else if (!isApplicant(jobApplication, message)) {
      type = 'employer';
    }

    return type;
  };

  const createChannel = (
    uid,
    jobName,
    jobImg,
    currentUser,
    msg,
    metaData,
    customType,
    jaId
  ) => {
    sb.createGroupChannel(uid, jobName, jobImg, currentUser, customType).then(
      channel => {
        sb.createGroupChannelMetaData(channel, metaData).then(data => {
          if (!data.error) {
            setChannelNotFound(false);
            setCurrentChannel(channel);
            sb.adminMessage(channel.url, msg).then(async () => {
              const res = await dispatch(
                mutateSendBirdChannelUrl({
                  input: {
                    id: jaId,
                    channelUrl: channel.url
                  }
                })
              );

              if (res?.jobApplication) {
                updateCandidateFromList(res?.jobApplication);
              }

              setIsCreatingChannel(false);
              setTimeout(() => {
                sendMessage();
              }, 500);
            });
          }
        });
      }
    );
  };

  const preSendMessage = () => {
    if (isCreatingChannel) return;

    if (channelNotFound) {
      // Chat channel not exist

      if (accountInfo && user?.id && sb?.sb?.currentUser) {
        setIsCreatingChannel(true);

        // For none master account, need to add in master and user into chat room
        const userIds =
          accountInfo.accountType === 'master'
            ? [user.id]
            : [user.id, accountInfo.companyId];

        const currentUserName =
          accountInfo.accountType === 'master'
            ? accountInfo.companyName
            : sb.sb.currentUser.nickname;

        const msg = `${currentUserName} has initiated a chat session with you for the position of ${job.title}`;

        const cMetaData = {
          chat_status: '2',
          cb_session: application.id,
          cb_status: '0',
          cb_last_context: '',
          job_enable_chatbot: '0',
          company_name: accountInfo.companyName
        };

        sb.checkUser(user.id)
          .then(response => {
            if (response.error) {
              // user not found, create user
              sb.createUser(user.id, user.name, user.profileImageSquare).then(
                () => {
                  const data = {
                    metadata: {
                      user_type: 'jobseeker'
                    }
                  };

                  sb.createUserMetadata(data, user.id)
                    .then(() => {
                      createChannel(
                        userIds,
                        job.title,
                        job.jobBannerUrl,
                        currentUserName,
                        msg,
                        cMetaData,
                        '2',
                        application.id
                      );
                    })
                    .catch(() => {
                      setIsCreatingChannel(false);
                    });
                }
              );
            } else {
              // user found
              createChannel(
                userIds,
                job.title,
                job.jobBannerUrl,
                currentUserName,
                msg,
                cMetaData,
                '2',
                application.id
              );
            }
          })
          .catch(() => {
            setIsCreatingChannel(false);
          });
      }
    } else {
      // Chat channel exist
      if (isAiviSession(chatRef.current)) {
        setIsInterruptAiviOpen(true);
      } else {
        sendMessage();
      }
    }
  };

  const onKeyDown = event => {
    if (event.key === 'Enter') {
      preSendMessage();
    }
  };

  const handleChangeChatText = event => {
    setChatText(event.target.value.substring(0, 500));

    onActive(sbConnectedRef.current);
  };

  const disconnectSendBird = () => {
    if (sb) {
      if (sb.getConnectionStatus() === 'OPEN') {
        sb.disconnect()
          .then(res => {
            if (res) {
              setSbConnected(false);
            }
          })
          .catch(error => {
            console.log('Failed to disconnect sendbird:', error);
          });
      } else {
        if (sbConnectedRef.current) {
          setSbConnected(false);
        }
      }
    }
  };

  const resetIdleTimer = () => {
    if (idleTimer) {
      clearTimeout(idleTimer);
    }

    idleTimer = setTimeout(() => {
      onIdle(sbConnected);
    }, 30000); // 30 seconds
  };

  const onActive = sbConnected => {
    resetIdleTimer();

    if (!sbConnected) {
      connectSendBird();

      if (sb) {
        const params = {
          uniqueId: 'CHAT_LIST_HANDLER',
          updateMessages
        };
        sb.createChannelListHandler(params);
      }
    }
  };

  const onIdle = sbConnected => {
    if (sbConnected) {
      disconnectSendBird();

      if (sb) {
        sb.removeChannelHandler('CHAT_LIST_HANDLER');
      }
    }
  };

  const handleVisibilityChange = () => {
    if (document[hidden]) {
      onIdle(sbConnectedRef.current);
    } else {
      onActive(sbConnectedRef.current);
    }
  };

  const initVisibilityChange = () => {
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    document.addEventListener(visibilityChange, handleVisibilityChange, false);
  };

  const onMouseMove = _.throttle(e => onActive(sbConnected), 1000);

  const componentDecorator = type => (href, text, key) =>
    (
      <a
        href={href}
        key={key}
        target="_blank"
        style={{ ...(type !== 'jobseeker' ? { color: 'white' } : null) }}>
        {text}
      </a>
    );

  //grouping
  const groups = messages.reduce((groups, item) => {
    const date = moment(item.createdAt).format('DD MMMM YYYY');

    if (!groups[date]) {
      groups[date] = [];
    }

    groups[date].push(item);

    return groups;
  }, {});

  //reform it into multiple objects in an array
  const groupArrays = Object.keys(groups).map(date => {
    return {
      date,
      item: groups[date]
    };
  });

  // Use effects
  useEffect(() => {
    connectSendBird();

    initVisibilityChange();

    // Setting idle timer
    resetIdleTimer();

    return () => {
      if (sb && sbConnected) {
        if (sb.getConnectionStatus() === 'OPEN') {
          sb.disconnect();
        }
        sb.removeChannelHandler('CHAT_LIST_HANDLER');
      }

      document.removeEventListener(visibilityChange, handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (sbConnected) {
      const params = {
        uniqueId: 'CHAT_LIST_HANDLER',
        updateMessages
      };
      sb.createChannelListHandler(params);

      // Loading messages
      setLoadingMessages(true);
      getMessages(true);
    }
  }, [sbConnected]);

  useEffect(() => {
    if (messages.length > 0) {
      setTimeout(() => {
        if (chatContainerRef?.current) {
          chatContainerRef.current.scrollTo({
            top: chatContainerRef.current.scrollHeight,
            behavior: 'smooth'
          });
        }
      }, 200); // 200 milliseconds to scroll to bottom after new message is rendered
    }
  }, [messages]);

  useEffect(() => {
    setLoadingMessages(true);
    getMessages(true);
  }, [chatChannel]);

  return (
    <>
      <Grid
        className={
          componentOrigin == 'fullscreenModal'
            ? styles.fullScreenChatSection
            : styles.chatSection
        }
        onMouseMove={onMouseMove}>
        {!isCandidatesEmpty && (
          <div
            className={
              !isCreatingChannel && channelNotFound
                ? styles.chatWrapper
                : componentOrigin == 'fullscreenModal'
                ? styles.fullscreenModalChatWrapper
                : source == 'list-view-manage-candidate'
                ? styles.chatWrapperWithContentForListView
                : styles.chatWrapperWithContent
            }
            style={{
              height: !isCreatingChannel && channelNotFound ? 'auto' : '80vh'
            }}>
            {sbConnected && !isCreatingChannel && channelNotFound && (
              <div className={styles.noChatChannelMessageContainer}>
                <img
                  title="No chat channel"
                  alt="No chat channel"
                  src="https://assets.wobbjobs.com/images/employers/default_states/hiredly-employer-chat-message-v2.svg"
                  className={styles.noChatGraphic}
                />
                <div className={styles.noChatDescriptionContainer}>
                  <span>
                    You have not initiated any chat messages with this
                    applicant.
                  </span>
                  <HeightSpacer height={'10px'} />
                  <span>
                    Send a message now to start a conversation with {user?.name}
                    .
                  </span>
                </div>

                {sbConnected && !job?.enableChatbot && (
                  <div className={styles.noAiviContainer}>
                    <span style={{ textAlign: 'center', fontWeight: '600' }}>
                      No time to initiate chat one by one?
                    </span>
                    <div className={styles.textWithInfoIconContainer}>
                      <span>
                        Set up Virtual Interviewer to help you screen candidates
                        faster.
                      </span>
                      <Tooltip
                        title="Virtual Interviewer allows you to pre-select 3 questions for candidate to answer as soon as they applied for this job."
                        placement="top">
                        <InfoIcon className={styles.infoIcon} />
                      </Tooltip>
                    </div>

                    <Button
                      className={styles.purpleButton}
                      onClick={() => setIsVirtualInterviewOpen(true)}>
                      Set up virtual interview now
                    </Button>
                  </div>
                )}
              </div>
            )}
            {!sbConnected ? (
              <div className={styles.emptyNotice}>
                <div className={styles.labelStyled}>
                  {!sbConnected ? 'Connecting...' : 'Chat is not initiated'}
                </div>
              </div>
            ) : (
              <>
                {(!channelNotFound || isCreatingChannel) && (
                  <div ref={chatContainerRef} className={styles.chatContainer}>
                    {isCreatingChannel ? (
                      <div className={styles.emptyNotice}>
                        <div className={styles.labelStyled}>
                          Initialising chat...
                        </div>
                      </div>
                    ) : loadingMessages ? (
                      <SkeletonLoader />
                    ) : (
                      <>
                        {groupArrays.map(message => {
                          return (
                            <>
                              <TextContainer message_date="true">
                                <Text message_date="true">{message.date}</Text>
                              </TextContainer>

                              {message.item.map(message => {
                                return (
                                  <div
                                    key={message.messageId}
                                    className={
                                      getMessageType(application, message) ===
                                      'jobseeker'
                                        ? styles.jobseekerMessageContainer
                                        : styles.employerMessageContainer
                                    }>
                                    <div
                                      className={
                                        getMessageType(application, message) ===
                                        'jobseeker'
                                          ? styles.jobseekerMessageContent
                                          : styles.employerMessageContent
                                      }>
                                      <div className={styles.labelStyled}>
                                        <Linkify
                                          componentDecorator={componentDecorator(
                                            getMessageType(application, message)
                                          )}>
                                          {message.message}
                                        </Linkify>
                                      </div>

                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'flex-end'
                                        }}>
                                        <div style={{ marginRight: '10px' }}>
                                          {message?.sender?.userId ==
                                            '123rwwer32' && (
                                            <AiviIcon
                                              width={18}
                                              style={{
                                                color: '#fff',
                                                display: 'flex',
                                                alignItems: 'center'
                                              }}
                                            />
                                          )}
                                        </div>
                                        <Text message_time="true">
                                          {moment(message.createdAt).format(
                                            'h:mm A'
                                          )}
                                        </Text>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          );
                        })}
                      </>
                    )}
                  </div>
                )}

                <div className={styles.sendTextWrapper}>
                  <div className={styles.textFieldIconContainer}>
                    <TextFieldStyled
                      id="chat-text-input"
                      type="text"
                      hiddenLabel
                      onKeyDown={onKeyDown}
                      onChange={handleChangeChatText}
                      value={chatText}
                      style={{ width: '100%' }}
                      InputProps={{
                        placeholder: 'Type your reply...',
                        disableUnderline: true,
                        style: {
                          background: 'transparent',
                          width: '100%',
                          fontSize: '14px'
                        }
                      }}
                    />
                    <div className={styles.iconWrapper}>
                      <SendIcon
                        className={styles.sendIcon}
                        onClick={preSendMessage}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        <InterruptAiviPopUp
          open={isInterruptAiviOpen}
          handleClose={() => setIsInterruptAiviOpen(false)}
          sendAdminMessage={sendAdminMessage}
        />
        <VirtualInterviewPopUpNew
          useAsPopUp={true}
          open={isVirtualInterviewOpen}
          handleClose={() => setIsVirtualInterviewOpen(false)}
          jobId={job?.id}
        />
      </Grid>
    </>
  );
};

export default CandidateChat;
