import React, { useState } from 'react';
import * as yup from 'yup';
import {
  ButtonContainer,
  FormContainer,
  Row,
  RowLabel,
  TitleText
} from './styles';
import { MUIButtonStyled } from '../../../components/MUIButton';
import SharedTextField from '../../../components/SharedTextField';

const ForgotPassword = ({ onReset, loading }) => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required('Email is required')
      .email('Invalid email format')
  });

  const handleInputChange = e => {
    setErrors({
      [e.target.name]: null
    });
    setEmail(e.target.value);
  };

  const validate = async () => {
    try {
      await validationSchema.validate({ email }, { abortEarly: false });
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach(error => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const isValid = await validate();
    if (isValid) {
      await onReset(email);
      setEmail('');
    }
  };

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit}>
      <FormContainer>
        <div className="description">
          <TitleText>Forgot Password?</TitleText>
          <p className="mb-2">
            Enter your login email address and we’ll send over the password
            reset instructions.
          </p>
        </div>
        <Row>
          <RowLabel>Email</RowLabel>
          <SharedTextField
            name="email"
            placeholder="Email"
            value={email}
            onChange={handleInputChange}
            error={errors.email}
            helperText={errors.email}
          />
        </Row>
      </FormContainer>

      <ButtonContainer>
        <MUIButtonStyled
          large
          label="Reset Password"
          type="submit"
          loading={loading}
        />
      </ButtonContainer>
    </form>
  );
};

export default ForgotPassword;
