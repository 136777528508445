import React, { useState } from 'react';
import {
  CloseIconStyled,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
  IconButtonStyled,
  TitleContainer,
  TitleDescStyled,
  TitleStyled,
  TypographySpan
} from './styles';
import video_1 from '../../../../../assets/images/company/video_1.svg';
import video_2 from '../../../../../assets/images/company/video_2.svg';
import video_3 from '../../../../../assets/images/company/video_3.svg';
import { Grid, Typography } from '@mui/material';
import { TermsAndConditions } from '../../../SocialMediaHighlights/components/InfoPopup';

export default function InfoPopUp(props) {
  const { open, handleClose } = props;
  const [selected, setSelected] = useState(1);

  const list = [
    { id: 1, image: video_1 },
    { id: 2, image: video_2 },
    { id: 3, image: video_3 }
  ];

  return (
    <DialogStyled open={open} onClose={handleClose}>
      <DialogTitleStyled>
        <TitleContainer>
          <TitleStyled>
            More Info: <TypographySpan>Our People Videos</TypographySpan>
          </TitleStyled>
          <TitleDescStyled>
            Get an idea of how your videos would look like!
          </TitleDescStyled>
        </TitleContainer>
        <IconButtonStyled
          aria-label="close"
          onClick={handleClose}
          sx={{ padding: '0px' }}>
          <CloseIconStyled />{' '}
        </IconButtonStyled>
      </DialogTitleStyled>

      <DialogContentStyled dividers>
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
          <Grid sx={{ display: 'flex', gap: '8px', flexDirection: 'row' }}>
            {list?.map(list => {
              return (
                <Grid
                  onClick={() => setSelected(list?.id)}
                  sx={{
                    width: '68px',
                    height: '36px',
                    padding: '8px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border:
                      selected === list?.id
                        ? '2px solid #512ACC'
                        : '1px solid #D8D8D8',
                    backgroundColor:
                      selected === list?.id ? '#EFEFFD' : 'transparent',
                    borderRadius: '8px',
                    display: 'flex',
                    fontSize: '12px'
                  }}>
                  {list?.id}
                </Grid>
              );
            })}
          </Grid>

          <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Typography sx={{ fontSize: '14px' }}>
              Different layouts based on video orientations:
            </Typography>
            <img
              src={
                list?.find(list => {
                  return list?.id === selected;
                }).image
              }
            />
          </Grid>
          <TermsAndConditions />
        </Grid>
      </DialogContentStyled>
    </DialogStyled>
  );
}
