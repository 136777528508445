import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import WaitlistPage from '../../components/Waitlist/WelcomePage/WaitlistPage/WaitlistPage';

export default function Waitlist() {
  const [isPageEnabled, setIsPageEnabled] = useState(false);

  if (!isPageEnabled) {
    useEffect(() => {
      window.location.href = 'https://www.workexcite.com/hiredly-sg-employer-waitlist';
    }, []); 
    return null;
  }

  return (
    <DefaultLayout>
      <WaitlistPage/>
    </DefaultLayout>
  );
  }