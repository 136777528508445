/*
*text* for BOLD
#text# for purple
_text_ for italic
~text~ for custom color
*/

export function colorText(text, color) {
  return text?.replace(
    /\~(.*?)\~/g,
    `<span style="background: ${color}; -webkit-background-clip: text; background-clip: text; color: transparent;">$1</span>`
  );
}

function purpleText(text) {
  return text?.replace(/\#(.*?)\#/g, '<span style="color: #512ACC; letter-spacing: 0.1px;">$1</span>');
}

function boldText(text) {
  return text?.replace(/\*(.*?)\*/g, '<strong style="letter-spacing: 0.1px;">$1</strong>');
}

function italicText(text) {
  return text?.replace(
    /\_(.*?)\_/g,
    '<span style="font-style: italic">$1</span>'
  );
}

export function textFormatter(input) {
  let text = input;
  text = italicText(text);
  text = boldText(text);
  text = purpleText(text);

  return text;
}

export function replaceTextWithVariable(originalString, variable) {
  return originalString.replace(/\[\d+\]/, variable);
}
