import React, { useState, useEffect } from 'react';
import { MenuItem, TextField } from '@mui/material';
import classNames from 'classnames';
import styles from './index.module.scss';
import { QuestionContainer, QuestionTitle } from './styles';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

const FormSelectInterview = ({
  name,
  label,
  options,
  className,
  style,
  onChange,
  disabled,
  fields,
  setFields,
  value,
  questionNumber,
  showCustomQuestion,
  setShowCustomQuestion,
  disabledText,
  required,
  errors,
  setErrors
}) => {
  const onChangeField = e => {
    const selectedValue = e.target.value;
    setFields({ ...fields, [name]: selectedValue });
    setErrors({ ...errors, [name]: false });
  };

  const [wordCount, setWordCount] = useState(0);

  useEffect(() => {
    if (fields.question3Custom) {
      setWordCount(fields.question3Custom.length);
    }
  }, [fields.question3Custom]);

  const handleCustomQuestionChange = e => {
    const text = e.target.value;
    if (text.length <= 100) {
      setFields({ ...fields, question3Custom: text });
      setWordCount(text.length);
    }

    setErrors({ ...errors, question3Custom: false });
  };

  const isCharacterLimitExceeded = wordCount > 100;

  // Function to check if an option is disabled for AIVI questions
  const isAiviQuestionDisabled = id => {
    if (fields) {
      if (
        fields['question1'] === id ||
        fields['question2'] === id ||
        fields['question3'] === id
      ) {
        return true;
      }
    }
    return false;
  };

  // Function to handle click on menu item for AIVI questions
  const handleMenuItemClick = id => {
    if (setFields) {
      let currentSelected = { ...fields };
      let proceed = true;

      currentSelected[name] = id;

      if (name.toLowerCase() === 'question1') {
        if (
          currentSelected[name] === currentSelected['question2'] ||
          currentSelected[name] === currentSelected['question3']
        ) {
          proceed = false;
        }
      } else if (name.toLowerCase() === 'question2') {
        if (
          currentSelected[name] === currentSelected['question1'] ||
          currentSelected[name] === currentSelected['question3']
        ) {
          proceed = false;
        }
      } else if (name.toLowerCase() === 'question3') {
        if (
          currentSelected[name] === currentSelected['question1'] ||
          currentSelected[name] === currentSelected['question2']
        ) {
          proceed = false;
        }

        if (id === '14') {
          setShowCustomQuestion(true);
        } else {
          setShowCustomQuestion(false);
        }
      }

      if (proceed) {
        setFields(currentSelected);
      }
    }
  };

  return (
    <QuestionContainer>
      <QuestionTitle disabled={disabledText}>
        Question {questionNumber}
      </QuestionTitle>
      <TextField
        select
        variant="outlined"
        className={classNames(styles.textField, className)}
        value={fields[name] || ''}
        style={{ width: '100%' }}
        label={label}
        onChange={onChangeField}
        disabled={!required}
        required={required}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                '.MuiMenuItem-root': {
                  whiteSpace: 'unset',
                  '&:hover': {
                    background: '#EFEFFD'
                  }
                }
              }
            }
          },
          sx: {
            width: '100%',
            height: '80%',
            '& .Mui-focused fieldset': {
              borderColor: '#512ACC !important'
            },
            '& .MuiInputBase-root': {
              borderRadius: '8px',
              padding: '0px 0px 0px 5px',
              '&:focus fieldset': {
                borderColor: '#512ACC'
              }
            },
            '& .MuiFormLabel-root': {
              top: '0px'
            }
          }
        }}>
        {options ? (
          Object.keys(options).map(item => (
            <MenuItem
              key={options[item].id}
              value={options[item].id}
              disabled={isAiviQuestionDisabled(options[item].id)}
              onClick={() => handleMenuItemClick(options[item].id)}
              sx={{
                '&.MuiMenuItem-root': {
                  padding: '5px 15px',
                  justifyContent: 'flex-start'
                }
              }}>
              <p
                style={{
                  fontFamily: 'Inter',
                  fontSize: 16,
                  margin: 0,
                  paddingTop: 5,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                {options[item].title || options[item].name}
              </p>
            </MenuItem>
          ))
        ) : (
          <div></div>
        )}
      </TextField>

      {errors[name] && (
        <>
          <p
            style={{
              color: '#BE4242',
              fontSize: '12px',
              lineHeight: '20px',
              display: 'flex',
              padding: '0px 8px',
              alignItems: 'center',
              gap: '8px',
              margin: 0
            }}>
            <ExclamationCircleIcon
              style={{
                width: '20px',
                height: '20px',
                color: '#BE4242'
              }}
            />
            Question {questionNumber} is required.
          </p>
        </>
      )}

      {showCustomQuestion && (
        <>
          <TextField
            className={classNames(styles.textField, className)}
            variant="outlined"
            name="question3Custom"
            placeholder="Please type your custom question here"
            hiddenLabel
            style={{ marginTop: 2 }}
            value={fields.question3Custom || ''}
            onChange={handleCustomQuestionChange}
            required={required && fields.question3 == 14}
            disabled={!required}
            error={isCharacterLimitExceeded}
            sx={{
              width: '100%',
              borderRadius: '8px',
              '& .MuiInputBase-input': {
                color: '#222222',
                padding: '16.5px 14px'
              }
            }}
          />
          <div
            style={{
              display: 'flex',
              padding: '0px 8px',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              alignSelf: 'stretch'
            }}>
            <p
              style={{
                color: 'rgba(0, 0, 0, 0.60)',
                fontSize: '12px',
                display: 'flex',
                alignItems: 'center',
                lineHeight: '20px',
                margin: 0
              }}>
              Max 100 characters
            </p>

            <p
              style={{
                color: isCharacterLimitExceeded ? 'red' : 'rgba(0, 0, 0, 0.60)',
                fontSize: '12px',
                display: 'flex',
                alignItems: 'center',
                lineHeight: '20px',
                margin: 0
              }}>
              {wordCount}/100
            </p>
          </div>

          {errors.question3Custom && (
            <>
              <p
                style={{
                  color: '#BE4242',
                  fontSize: '12px',
                  lineHeight: '20px',
                  display: 'flex',
                  padding: '0px 8px',
                  alignItems: 'center',
                  gap: '8px',
                  margin: 0
                }}>
                <ExclamationCircleIcon
                  style={{
                    width: '20px',
                    height: '20px',
                    color: '#BE4242'
                  }}
                />
                Custom question is required.
              </p>
            </>
          )}
        </>
      )}
    </QuestionContainer>
  );
};

export default FormSelectInterview;
