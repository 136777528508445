import React, { useMemo } from 'react';

export const DOTS = '...';

//totalCount is the total number of elements from BE
//pageSize is number of elements in a page
//siblingCount is the count that shows at the side of selectedPage - eg. 16 and 17 is the sibling count of 15 (sibling = 1)
//selectedPage is the page selected
//useMemo to refresh if state changes
// ceil is to roundup the decimal

export const range = (start, end) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  selectedPage
}) => {
  const paginationRange = useMemo(() => {
    const totalPages = Math.ceil(totalCount / pageSize);

    //sibiling count + first page + last page + 2*DOTS + selected page = 7
    const totalPageCounts = siblingCount + 5;

    if (totalPageCounts >= totalPages) {
      return range(1, totalPages);
    }

    const leftSiblingIndex = Math.max(selectedPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(selectedPage + siblingCount, totalPages);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPages - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPages;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPages];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(totalPages - rightItemCount + 1, totalPages);

      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleItemCount = 2;
      let middleRange = range(
        leftSiblingIndex,
        rightSiblingIndex + middleItemCount
      );
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, selectedPage]);

  return paginationRange;
};
