import { Grid, TextField } from '@mui/material';
import moment from 'moment';
import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../../../../../components';
import {
  addNewNotes,
  filterNotesByComments,
  sortNoteByNewest,
  sortNoteByOldest,
  updateExistingNotes
} from '../../../../../redux/actions/talent_action';
import {
  ProfileContainer,
  Wrapper,
  OuterContainer,
  TitleTypography,
  IconButtonStyled,
  EditIconStyled,
  TimeTypography,
  ContentTypography,
  DividerStyled,
  FormControlStyled,
  SelectStyled,
  MenuItemStyled,
  TextFieldStyled,
  InputAdornmentStyled,
  SendIconStyled,
  useStyles,
  CancelButtonStyled,
  SaveButtonStyled,
  CustomDivider
} from './styles';

let resizeObserver;

const SingleNote = props => {
  const { data, setWithNode, memberId } = props;

  const dispatch = useDispatch();
  const [editActive, setEditActive] = useState(false);

  const date = new Date(data?.createdAt);
  const editedDate = moment(data?.updatedAt).format('DD MMM YYYY');
  const options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'Asia/Kuala_Lumpur'
  };
  const formattedDate = date.toLocaleDateString('en-US', options);

  const masterAccountName = useSelector(
    state => state.companies.myCompanySettings.userName
  );

  const [updatedParams, setUpdatedParams] = useState({
    id: data?.id,
    companyResumeId: data?.companyResumeId,
    notes: data?.notes
  });

  const editHandler = () => {
    setEditActive(!editActive);
  };

  const inputHandler = event => {
    setUpdatedParams(prevState => ({
      ...prevState,
      notes: event.target.value
    }));
  };

  const submitHandler = () => {
    dispatch(updateExistingNotes(updatedParams));
    setEditActive(false);
    setWithNode(false);
  };

  const permission = () => {
    if (memberId == 'master') {
      return null;
    }
    return memberId;
  };

  const isEditedDateSameWithCreatedDate = () => {
    let createdDate = moment(data?.createdAt);
    let editedDate = moment(data?.updatedAt);

    return moment(editedDate).diff(moment(createdDate), 'minute') == 0;
  };

  return (
    <Grid sx={{ width: '100%' }} container>
      <Grid
        width="100%"
        container
        justifyContent="space-between"
        direction="row"
        alignItems="flex-start"
        margin={'20px 14px 14px 0'}>
        <OuterContainer container direction="column">
          <TitleTypography>
            {data?.companyTeamMember?.name
              ? data?.companyTeamMember?.name
              : masterAccountName}
          </TitleTypography>
          <TimeTypography>
            {formattedDate}&nbsp;
            {!isEditedDateSameWithCreatedDate() && `(Edited ${editedDate})`}
          </TimeTypography>{' '}
        </OuterContainer>

        {permission() === data?.companyTeamMemberId &&
          (!editActive ? (
            <IconButtonStyled onClick={editHandler}>
              <EditIconStyled />
            </IconButtonStyled>
          ) : (
            <Wrapper
              container
              direction="row"
              justifyContent="flex-end"
              gap="10px">
              <CancelButtonStyled onClick={editHandler}>
                Cancel
              </CancelButtonStyled>
              <SaveButtonStyled
                onClick={submitHandler}
                disabled={updatedParams.notes.length === 0}>
                Save
              </SaveButtonStyled>
            </Wrapper>
          ))}
      </Grid>

      <Grid
        marginBottom="16px"
        container
        sx={{ width: '100%', marginRight: '16px' }}>
        {editActive ? (
          <Grid container direction="column" sx={{ width: '100%' }}>
            <TextField
              multiline
              value={updatedParams.notes}
              onChange={inputHandler}
              sx={{
                backgroundColor: `rgba(245, 245, 245, 1)`,
                '& .MuiInputBase-root': {
                  borderRadius: '4px',
                  fontSize: '16px',
                  fontFamily: 'Inter',
                  letterSpacing: '0.15px',
                  padding: '8px 10px'
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#707070'
                  }
                }
              }}
            />
          </Grid>
        ) : (
          <ContentTypography>{data?.notes}</ContentTypography>
        )}
      </Grid>
      <DividerStyled fullWidth />
    </Grid>
  );
};

const NotesPanel = ({ talent, contentContainerRef, contentHeaderRef }) => {
  const sorting = ['Newest First', 'Oldest First'];
  const [sort, setSort] = useState('Newest First');
  const [memberId, setMemberId] = useState('1');
  const [withNode, setWithNode] = useState(true);
  const [height, setHeight] = useState(0);
  const [topSectionHeight, setTopSectionHeight] = useState(0);
  const [bottomSectionContainerHeight, setBottomSectionContainerHeight] =
    useState(0);

  const bottomSectionContainerRef = useRef();
  const bottomSectionContainerHeightRef = useRef();

  bottomSectionContainerHeightRef.current = bottomSectionContainerHeight;

  const classes = useStyles();

  const dispatch = useDispatch();
  const companyResumeNotes = useSelector(
    state => state.talents.companyResumeNotes
  );
  const loading = useSelector(state => state.talents.fetchingNotes);
  const accountInfo = useSelector(state => state.companies.accountInfo);

  // Populate data on first load
  useEffect(() => {
    dispatch(sortNoteByNewest({ companyResumeId: talent?.companyResumeId }));
    // dispatch(fetchAccountInfo());
  }, [talent]);

  // To get all team members and append show all to the list
  const teamMembers = useSelector(
    state => state.companies.myCompanySettings.companyTeamMembers
  );

  const [teamMembersAll, setTeamMembersAll] = useState(teamMembers);
  const showAll = { id: '1', name: 'Show All' };

  useEffect(() => {
    if (contentHeaderRef?.current) {
      setTopSectionHeight(contentHeaderRef.current.clientHeight);
    }
  }, [contentHeaderRef]);

  useEffect(() => {
    setTeamMembersAll([showAll, ...teamMembersAll]);

    if (bottomSectionContainerRef.current) {
      resizeObserver = new ResizeObserver(() => {
        if (
          bottomSectionContainerRef.current?.clientHeight !=
          bottomSectionContainerHeightRef.current
        ) {
          setBottomSectionContainerHeight(
            bottomSectionContainerRef.current?.clientHeight
          );
        }
      });
      resizeObserver.observe(bottomSectionContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  // For setting users input
  const [params, setParams] = useState({
    companyResumeId: talent?.companyResumeId,
    companyTeamMemberId: '',
    notes: ''
  });

  // For user to sort notes (MEMBERS)
  const nameHandleChange = event => {
    setMemberId(event.target.value);
    if (event.target.value === '1') {
      setWithNode(true);
      dispatch(sortNoteByNewest({ companyResumeId: talent?.companyResumeId }));
    } else {
      setWithNode(true);
      dispatch(
        filterNotesByComments({
          companyResumeId: talent?.companyResumeId,
          commentById: event.target.value
        })
      );
    }
  };

  // For user to sort notes (NEWEST/OLDEST)
  const sortHandleChange = event => {
    setSort(event.target.value);
    setWithNode(true);
    if (event.target.value === 'Newest First') {
      dispatch(sortNoteByNewest({ companyResumeId: talent?.companyResumeId }));
    } else {
      dispatch(sortNoteByOldest({ companyResumeId: talent?.companyResumeId }));
    }
  };

  // For user to add new notes
  const submitHandler = () => {
    setWithNode(false);
    dispatch(addNewNotes(params));

    setParams(prevState => ({
      ...prevState,
      notes: ''
    }));
  };

  // For user to input notes
  const handleChange = event => {
    setParams(prevState => ({
      ...prevState,
      notes: event.target.value
    }));
  };

  // For enter key
  const handleKeypress = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      submitHandler();
    }
  };

  const getMemberId = () => {
    if (accountInfo.currentAccountType == 'master') {
      return 'master';
    }

    return accountInfo.id;
  };

  const onResize = () => {
    const clientHeight = contentContainerRef.current.offsetParent?.clientHeight;
    if (clientHeight) {
      setHeight(clientHeight);
    }
  };

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <ProfileContainer
      container
      justifyContent="space-between"
      sx={{ overflowX: 'hidden', maxHeight: 'auto' }}>
      {/* Notes */}
      {loading ? (
        <Grid
          sx={{
            width: '100%',
            height: `calc(${height}px - ${topSectionHeight}px - ${bottomSectionContainerHeightRef.current}px - 28px)`
          }}
          justifyContent="center"
          alignItems="center"
          container>
          <Spinner size="SMALL" />
        </Grid>
      ) : (
        <Grid
          sx={{
            width: '100%',
            overflow: 'auto',
            height: `calc(${height}px - ${topSectionHeight}px - ${bottomSectionContainerHeightRef.current}px - 28px)`,
            padding: '0 24px'
          }}
          className={classes.scrollBar}>
          {companyResumeNotes?.map(note => {
            let data = [];
            if (withNode) {
              const { node } = note;
              data = node;
            } else {
              data = note;
            }

            return (
              <>
                <SingleNote
                  data={data}
                  setWithNode={setWithNode}
                  memberId={getMemberId()}
                />
                <DividerStyled />
              </>
            );
          })}
        </Grid>
      )}

      <CustomDivider />

      {/* Input + filter */}
      <Grid
        ref={bottomSectionContainerRef}
        container
        width="100%"
        // minHeight="120px"
        // height="20%"
        justifyContent="space-between"
        sx={{ padding: '0 24px 16px 24px' }}>
        <Grid
          container
          direction="row"
          width="100%"
          justifyContent="space-between"
          paddingY="16px">
          <Wrapper
            width="auto"
            container
            direction="row"
            alignItems="center"
            gap="10px">
            <TitleTypography>Comment by:</TitleTypography>
            <FormControlStyled>
              <SelectStyled
                MenuProps={{
                  PaperProps: {
                    sx: {
                      boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.16)',
                      borderRadius: '4px'
                    }
                  }
                }}
                labelId="select-comment-by"
                id="select-comment-by"
                value={memberId}
                defaultValue={'1'}
                onChange={nameHandleChange}>
                {teamMembersAll?.map(member => {
                  return (
                    <MenuItemStyled value={member.id}>
                      <TitleTypography>{member.name}</TitleTypography>
                    </MenuItemStyled>
                  );
                })}
              </SelectStyled>
            </FormControlStyled>
          </Wrapper>

          <Wrapper
            width="auto"
            container
            direction="row"
            alignItems="center"
            gap="10px">
            <TitleTypography>Sort by:</TitleTypography>
            <FormControlStyled>
              <SelectStyled
                MenuProps={{
                  PaperProps: {
                    sx: {
                      boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.16)',
                      borderRadius: '4px'
                    }
                  }
                }}
                labelId="notes-sort-by"
                id="notes-sort-by"
                value={sort}
                defaultValue={'Newest First'}
                onChange={sortHandleChange}>
                {sorting?.map(name => {
                  return (
                    <MenuItemStyled value={name}>
                      <TitleTypography>{name}</TitleTypography>
                    </MenuItemStyled>
                  );
                })}
              </SelectStyled>
            </FormControlStyled>
          </Wrapper>
        </Grid>
        <TextFieldStyled
          fullWidth
          maxRows={3}
          multiline
          onKeyDown={handleKeypress}
          onChange={handleChange}
          placeholder="Add Notes"
          value={params.notes}
          InputProps={{
            endAdornment: (
              <InputAdornmentStyled>
                <IconButtonStyled
                  onClick={submitHandler}
                  disabled={params.notes.length === 0}>
                  <SendIconStyled disabled={params.notes.length === 0} />
                </IconButtonStyled>
              </InputAdornmentStyled>
            )
          }}
        />
      </Grid>
    </ProfileContainer>
  );
};

export default NotesPanel;
