import React, { useEffect } from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import {
  ContentContainer,
  LeftContainer,
  OuterContainer,
  RightContainer,
  WhiteCardContainer
} from './styles';
import { useDispatch } from 'react-redux';
import Banner from './form/banner';
import {
  fetchCareerLevels,
  fetchExperiences,
  fetchJobTypes,
  fetchMyCompanySettings,
  fetchStateRegions
} from '../../../redux/actions/company_actions';
import { FormProvider } from './context';
import { useFormData } from './helper/useFormData';
import Description from './form/description';
import Requirements from './form/requirements';
import Skills from './form/skills';
import GlobalHire from './form/globalHire';
import api from '../../../utils/api';
import {
  fetchSpecialisation,
  updateJobSkillSearch,
  updateJobTitleSuggestions
} from '../../../redux/actions/job_action';
import CareerType from './form/careerType';
import EmploymentType from './form/employmentType';
import Specialization from './form/specialization';
import ExperienceLevel from './form/experienceLevel';
import Salary from './form/salary';
import MalaysianOnly from './form/malaysianOnly';
import Notification from './form/notifications';
import Ats from './form/ats';
import { useSelector } from 'react-redux';
import TokenBanner from './form/tokenBanner';
import CustomLogic from './form/logic/customLogic';
import AshleyPopUp from './form/ashleyRewrite/ashleyPopUp';
import ActionButton from './form/actionButton';
import { useNavigate, useParams } from 'react-router-dom';
import JobKeyword from './form/jobKeyword';

// HOW IT WORKS

// Key concept
// 1. The idea is to seperate logic and UI so that its easier to understand what is what
// and we will also know which logic is for add/edit/draft/intern/senior job
// 2. Therefore, create custom hooks for each of the logic, keeping the main file as clean as possible

// List of custom hooks
// useFormData - to construct the form data based on the field for createJob mutation
// useFormState - to edit the job data
// useData - to identify which data to get, either draft data or job data
// usePathKey - to identify which route is user currently at, can be used to select which logic to execute
// formatData - to format the data for easier data passing into the mutation api
// checkErrors - to perform validation and add error state when required field is empty
// useDefaultLogic - for standard job posting
// useDraftLogic - for posting draft job
// useSeniorLogic - for posting senior job
// useInternLogic - for posting intern job
// useEditLogic - for editing job posting

// Flow
// 1. Whenever the user land on the post job page, (usePathKey) will check which route it is and (useFormData)
//    will retrieve the correct data based on route e.g draft/edit/add/senior/intern
// 2. Posting a new job will always trigger (useDefaultLogic), unless user selected intern token or senior token
//    it then will run (useInternLogic) or (useSeniorLogic). (useDraftLogic) will also be run if they save as draft
// 3. Editing a draft job will always trigger (useDraftLogic), then it will follow step 2 from above based on what
//    user select
// 4. Editing a posted job will always trigger (useEditLogic), then the rest will follow step 2

export default function PostJob() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const myCompanyStatus = useSelector(
    state => state?.companies?.myCompanyStatus
  );

  const isValidUUID = id => {
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidRegex.test(id);
  };

  // Hook to get data
  const formData = useFormData();

  // Initialize lightcast token
  useEffect(() => {
    if (id && !isValidUUID(id)) {
      navigate('/not-found');
    }

    api.getLightCastToken();

    return () => {
      dispatch(updateJobSkillSearch(''));
      dispatch(updateJobTitleSuggestions([]));
    };
  }, []);

  useEffect(() => {
    window?.scrollTo({
      behavior: 'instant',
      top: 0
    });
    dispatch(fetchMyCompanySettings());
    dispatch(fetchStateRegions());
    dispatch(fetchCareerLevels());
    dispatch(fetchJobTypes());
    dispatch(fetchSpecialisation());
    dispatch(fetchExperiences());
  }, []);

  return formData ? (
    <FormProvider initialValues={formData}>
      <CustomLogic />
      <AshleyPopUp />
      <DefaultLayout margin={'35px'}>
        <Banner />

        <OuterContainer>
          <TokenBanner />
          <ContentContainer>
            <LeftContainer>
              <Description />
              <Requirements />
              <GlobalHire />
              <Skills />
              <JobKeyword />
            </LeftContainer>

            <RightContainer>
              <WhiteCardContainer>
                <EmploymentType />
                <Specialization />
                <CareerType />
                <ExperienceLevel />
                <Salary />
              </WhiteCardContainer>

              {/* <MalaysianOnly /> */}
              <Notification />
              {myCompanyStatus?.allowExternalJobUrl && <Ats />}
            </RightContainer>
          </ContentContainer>
          <ActionButton />
        </OuterContainer>
      </DefaultLayout>
    </FormProvider>
  ) : null;
}
