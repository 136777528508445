import React from 'react';
import { Text, PlaceholderContainer, EmptyPlaceholder } from './styles';
import { SharedButton } from '../SharedButton';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import emptyAvailableResource from '../../../../../assets/icons/products/empty_available_resource.svg';
import { openInNewTab } from '../../../../../utils/WindowUtils';
import store from '../../../../../redux/stores/store';
import { mutateCart } from '../../../../../redux/actions/order_action';


const Placeholder = ({}) => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const remainingUsableJobSlots =
    store.getState().companies.myCompanyStatus.jobSlotsTotalUsableQuantity;

  const jobTokensUsableCount =
    store.getState().companies.myCompanyStatus.jobToken;

  const unlimitedJobToken =
    store.getState().companies.myCompanyStatus.unlimitedToken;

  const availableJobCreditCount =
    store.getState().companies.myCompanyStatus.availableJobCreditCount;
  
  const remainingUsableJobSlotPlus = store.getState().companies.myCompanyStatus.jobSlotPlusTotalUsableQuantity;

  const remainingUsableJobSlotMax = store.getState().companies.myCompanyStatus.jobSlotMaxTotalUsableQuantity;

  const productTypeIndex = useSelector(state => state.jobs.productTypeIndex);

  const getTitle = () => {
    const jobTypeIndex = store.getState().jobs.productTypeIndex;

    switch (jobTypeIndex) {
      case 'All Types': {
        return 'No Products available';
      }
      case 'Job Slots': {
        return 'No Job Slots available';
      }
      case 'Featured Tokens': {
        return 'No Featured Tokens available';
      }
      case 'Refresher Tokens': {
        return 'No Refresher Tokens available';
      }
      case 'Senior Posts': {
        return 'No Senior Posts available';
      }
      case 'Internships': {
        return 'No Internship Tokens available';
      }
      case 'Job Slots Plus': {
        return 'No Job Slot Plus available';
      }
      case 'Job Slots Max': {
        return 'No Job Slot Max available';
      }
    }
    return 'No Products available';
  };

  const getDesc = () => {
    const jobTypeIndex = store.getState().jobs.productTypeIndex;

    switch (jobTypeIndex) {
      case 'All Types': {
        return 'Refine your recruitment journey by purchasing a job slot or token!';
      }
      case 'Job Slots': {
        return 'Refine your recruitment journey by purchasing a Job Slot!';
      }
      case 'Job Slots Plus': {
        return 'Refine your recruitment journey by purchasing a Job Slot Plus!';
      }
      case 'Job Slots Max': {
        return 'Refine your recruitment journey by purchasing a Job Slot Max!';
      }
      case 'Featured Tokens': {
        return 'Refine your recruitment journey by purchasing Featured Tokens!';
      }
      case 'Refresher Tokens': {
        return 'Refine your recruitment journey by purchasing Refreshing Tokens!';
      }
      case 'Senior Posts': {
        return remainingUsableJobSlots > 0
          ? 'Would you like to post a new job with a Job Slot instead?'
          : availableJobCreditCount > 0
          ? 'Would you like to post a new job with a Job Credit instead?'
          : jobTokensUsableCount > 0 || unlimitedJobToken
          ? 'Would you like to post a new job with a Job Token instead?'
          : 'Refine your recruitment journey by purchasing Job Slots!';
      }
      case 'Internships': {
        return remainingUsableJobSlots > 0
          ? 'Would you like to post a new job with a Job Slot instead?'
          : availableJobCreditCount > 0
          ? 'Would you like to post a new job with a Job Credit instead?'
          : jobTokensUsableCount > 0 || unlimitedJobToken
          ? 'Would you like to post a new job with a Job Token instead?'
          : 'Refine your recruitment journey by purchasing Job Slots!';
      }
    }

    return 'Refine your recruitment journey by purchasing a job slot or token!';
  };

  const getButtonText = () => {
    const jobTypeIndex = store.getState().jobs.productTypeIndex;

    switch (jobTypeIndex) {
      case 'All Types': {
        return 'Purchase Products';
      }
      case 'Job Slots': {
        return 'Purchase Job Slot';
      }
      case 'Job Slots Max': {
        return 'Purchase Job Slot Max';
      }
      case 'Job Slots Plus': {
        return 'Purchase Job Slot Plus';
      }
      case 'Featured Tokens': {
        return 'Purchase Featured Tokens';
      }
      case 'Refresher Tokens': {
        return 'Purchase Refresher Tokens';
      }
      case 'Senior Posts': {
        return remainingUsableJobSlots > 0
          ? 'Post with Job Slot'
          : availableJobCreditCount > 0
          ? 'Post with Job Credit'
          : jobTokensUsableCount > 0 || unlimitedJobToken
          ? 'Post with Job Token'
          : 'Purchase Job Slots';
      }
      case 'Internships': {
        return remainingUsableJobSlots > 0
          ? 'Post with Job Slot'
          : availableJobCreditCount > 0
          ? 'Post with Job Credit'
          : jobTokensUsableCount > 0 || unlimitedJobToken
          ? 'Post with Job Token'
          : 'Purchase Job Slots';
      }
    }
    return 'Purchase Products';
  };

  const onPurchaseButtonClicked = async () => {
    const jobTypeIndex = store.getState().jobs.productTypeIndex;
    let url = '/purchase/choose';
    let productCode;

    switch (jobTypeIndex) {
      case 'Job Slots':
        {
          url += '?product=job_slot';
          productCode = '364';
        }
        break;
      case 'Job Slots Plus':
          {
            url += '?product=job_slot_plus';
            productCode = '378';
          }
        break;
      case 'Job Slots Max':
        {
          url += '?product=job_slot_max';
          productCode = '379';
        }
        break;
      case 'Senior Posts':
      case 'Internships':
        {
          if (
            remainingUsableJobSlots > 0 ||
            availableJobCreditCount > 0 ||
            jobTokensUsableCount > 0 ||
            unlimitedJobToken || 
            remainingUsableJobSlotPlus > 0 ||
            remainingUsableJobSlotMax > 0
          ) {
            url = '/jobs/add/new-job';
          } else {
            url += '?product=job_slot';
            productCode = '364';
          }
        }
        break;
      case 'Featured Tokens':
        {
          url += '?product=booster_token';
          productCode = '173';
        }
        break;
      case 'Refresher Tokens':
        {
          url += '?product=refresher_token';
          productCode = '174';
        }
        break;
    }

    await dispatch(
      mutateCart({
        input: {
          productId: productCode,
          quantity: 1
        }
      })
    );
    openInNewTab(url);
  };

  return (
    <PlaceholderContainer>
      <EmptyPlaceholder src={emptyAvailableResource} />
      <Text no_product_title="true">{getTitle()}</Text>
      <Text no_product_desc="true">{getDesc()}</Text>
      <SharedButton
        id="standalone-purchase-more-button"
        type={'basic'}
        font_size={'16px'}
        onClick={onPurchaseButtonClicked}>
        {getButtonText()}
      </SharedButton>
    </PlaceholderContainer>
  );
};

export default Placeholder;
