import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DefaultLayout from '../../../../layouts/DefaultLayout';
import JobVisibilityPopUp from '../../PopUps/JobVisibilityPopUp';

import {
  RightBox,
  RightBoxInner,
  Title,
  TitleBox,
  Subtitle,
  Container,
  StepsContainer,
  Steps,
  StepsText,
  PurpleLineActive,
  PurpleLine,
  ErrorText,
  ErrorBox,
  ErrorSide
} from './styles';

const JobVisibility = () => {
  const params = useParams();

  const isMasterAccount =
    localStorage.getItem('currentAccountType') === 'master';
  const teamMembers = useSelector(state => state.companies.companyTeamMembers);
  const myCompanyStatus = useSelector(
    state => state?.companies?.myCompanyStatus
  );

  const isMasterOrHaveCtm = isMasterAccount || myCompanyStatus?.ctmAccess;

  const crossBorderTokensUsableCount = useSelector(
    state =>
      state.jobs.subscribedCrossBorderTokensPackage?.total_usable_quantity
  );

  const fetchingPackageSubscriptionDetails = useSelector(
    state => state.jobs.fetchingPackageSubscriptionDetails
  );
  // const crossBorderTokensUsableCount = 2;

  return (
    <DefaultLayout maxWidth={true}>
      <Container>
        <StepsContainer>
          <PurpleLine />
          <PurpleLineActive />

          <Steps>
            <StepsText>Job posted successfully 🎉</StepsText>
          </Steps>

          <Steps>
            <StepsText active>Maximise job visibility</StepsText>
          </Steps>

          <Steps>
            <StepsText disabled>Set up screening questions</StepsText>
          </Steps>

          {isMasterAccount && teamMembers?.length > 0 && (
            <Steps>
              <StepsText disabled>Assign job access</StepsText>
            </Steps>
          )}
        </StepsContainer>

        <RightBox>
          <RightBoxInner>
            <TitleBox>
              <Title>Maximise visibility of this job advertisement</Title>
              <Subtitle>
                Open to hiring internationally? Maximise your job’s visibility
                and attract international talents by listing it in other
                countries.
              </Subtitle>
            </TitleBox>

            {fetchingPackageSubscriptionDetails ? (
              <></>
            ) : (
              <>
                {crossBorderTokensUsableCount == 0 && (
                  <>
                    <ErrorBox>
                      <ErrorSide />

                      <ErrorText>
                        Uh oh, you do not have sufficient Cross Border Tokens.
                        Click{' '}
                        <a
                          onClick={() =>
                            window.open(
                              '/purchase/choose/?tab=visibility_boosters&products=cross_border_token-1'
                            )
                          }
                          style={{
                            color: 'rgba(0, 0, 0, 0.87)',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                            textDecorationLine: 'underline'
                          }}>
                          here
                        </a>{' '}
                        to purchase more.
                      </ErrorText>
                    </ErrorBox>
                  </>
                )}
              </>
            )}

            <JobVisibilityPopUp useAsPopUp={false} jobId={params?.id} open />
          </RightBoxInner>
        </RightBox>
      </Container>
    </DefaultLayout>
  );
};

export default JobVisibility;
