import * as types from '../types/product_type';
import api, { strapiCall } from '../../utils/api';
const { REACT_APP_API_VERSION } = process.env;

const clearProductInfo = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.CLEAR_PRODUCT_INFO
      })
    );
  });
};

const updateProductBundle = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_PRODUCT_BUNDLE,
        status: status
      })
    );
  });
};

const updateProductInactivePopupStatus = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_PRODUCT_INACTIVE_POPUP,
        status: status
      })
    );
  });
};

const updateNewInfoPopupStatus = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_NEW_INFO_POPUP,
        status: status
      })
    );
  });
};

const updateDiscountedCreditsPopupStatus = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_DISCOUNTED_CREDITS_POPUP_STATUS,
        status: status
      })
    );
  });
};

const fetchProductsByCategory =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_PRODUCT_BY_CATEGORIES
    });

    const payload = {
      query: `{
        allActiveProducts {
          title
          categoryDescription
          order
          hide
          products {
            active
            createdAt
            id
            imageUrl
            originalPrice
            packageType
            price
            productBundleInfo
            productCode
            productImage
            productDisplayNote
            productDescBody
            title
            productPriceChanged
            updatedAt
            quantityAvailable
            productPageInfo 
            productsInBundle
          }
        }
      }
  `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.allActiveProducts) {
            const allActiveProducts =
              response?.data?.data?.allActiveProducts.filter(item => {
                return !item?.hide;
              });

            const products = allActiveProducts.map(activeProducts => {
              return activeProducts?.products;
            });

            const allProducts = products.flat(Infinity);

            return dispatch({
              type: types.FETCH_PRODUCT_BY_CATEGORIES_SUCCEED,
              allActiveProducts: allActiveProducts,
              allProducts: allProducts
            });
          }
        }
        return dispatch({
          type: types.FETCH_PRODUCT_BY_CATEGORIES_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_PRODUCT_BY_CATEGORIES_FAILED
        });
      });
  };

const fetchPackagePlanDetails =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_PACKAGE_PLAN_DETAILS
    });

    const payload = {
      query: `{
        packagePlanDetails
      }`
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.packagePlanDetails) {
            const packagePlanDetails = response?.data?.data?.packagePlanDetails;
            return dispatch({
              type: types.FETCH_PACKAGE_PLAN_DETAILS_SUCCEED,
              packagePlanDetails: packagePlanDetails
            });
          }
        }
        return dispatch({
          type: types.FETCH_PACKAGE_PLAN_DETAILS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_PACKAGE_PLAN_DETAILS_FAILED
        });
      });
  };

const fetchProductCarousel = () => async dispatch => {
  dispatch({
    type: types.FETCH_PRODUCT_CAROUSEL
  });

  const payload = {
    query: `
    {
      forEmployerPage {
        data {
          attributes {
            heroTitle
            heroSubtitle
            heroBanners {
              desktopBanner {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }`
  };
  const response = await strapiCall(payload);

  if (response.status === 200) {
    const { attributes } = response.data?.data?.forEmployerPage?.data || {};

    return dispatch({
      type: types.FETCH_PRODUCT_CAROUSEL_SUCCEED,
      productCarousel: attributes
    });
  }
  return dispatch({
    type: types.FETCH_PRODUCT_CAROUSEL_FAILED
  });
};

const fetchProductTrustedLogo = () => async dispatch => {
  dispatch({
    type: types.FETCH_PRODUCT_TRUSTED_LOGO
  });

  const payload = {
    query: `
      {
        forEmployerPage {
          data {
            attributes {
              trustedBrandLogos {
                brandLogo {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                clickThroughURL
              }
            }
          }
        }
      }      
      `
  };

  const response = await strapiCall(payload);

  if (response.status === 200) {
    const { trustedBrandLogos } =
      response.data?.data?.forEmployerPage?.data?.attributes || {};

    return dispatch({
      type: types.FETCH_PRODUCT_TRUSTED_LOGO_SUCCEED,
      trustedLogo: trustedBrandLogos
    });
  }
  return dispatch({
    type: types.FETCH_PRODUCT_TRUSTED_LOGO_FAILED
  });
};

const fetchHomepageBanner = countryName => async dispatch => {
  dispatch({
    type: types.FETCH_HOMEPAGE_BANNER
  });

  const payload = {
    query: `
    {
      ${countryName}{
        data{
          attributes{
            edHomepageBanners {
              data {
                attributes {
                  bannerImage {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  useCase
                  redirectUrl
                  contactSubject {
                    data {
                      attributes {
                        subject
                      }
                    }
                  }
                  productSku {
                    data {
                      attributes {
                        title
                        productCode
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`
  };
  const response = await strapiCall(payload);

  if (response.status === 200) {
    const returnData =
      response?.data?.data?.[countryName]?.data?.attributes?.edHomepageBanners
        ?.data || [];
    return dispatch({
      type: types.FETCH_HOMEPAGE_BANNER_SUCCEED,
      homepageBanner: returnData
    });
  }
  return dispatch({
    type: types.FETCH_HOMEPAGE_BANNER_FAILED
  });
};

const updateLimitedProduct = products => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_LIMITED_PRODUCT,
        productsInBundle: products
      })
    );
  });
};

export {
  updateNewInfoPopupStatus,
  updateDiscountedCreditsPopupStatus,
  fetchProductsByCategory,
  fetchPackagePlanDetails,
  updateProductBundle,
  fetchProductCarousel,
  fetchProductTrustedLogo,
  updateLimitedProduct,
  clearProductInfo,
  updateProductInactivePopupStatus,
  fetchHomepageBanner
};
