import { styled as styling } from '@mui/material/styles';
import { Grid, Skeleton, Divider } from '@mui/material';

export const MainWrapper = styling(Grid)({
  marginBottom: '5px',
  width: '100%',
  height: '180px',
  backgroundColor: 'white',
  borderRadius: '8px',
  padding: "18px 16px 18px 16px"
});

export const SkeletonStyled = styling(Skeleton)({
  borderRadius: '8px'
});

export const SkeletonChipStyled = styling(Skeleton)({
  borderRadius: '100px'
  // height: '28px'
});

export const SkeletonAvatarStyled = styling(Skeleton)({});

export const EmptyContainer = styling(Grid)({
  width: '100%'
});

export const UnstyledContainer = styling(Grid)({});

export const DividerStyled = styling(Divider)({
  color: '#D8D8D8'
});
