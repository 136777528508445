import React, { useEffect, useState } from 'react';
import { CardActionArea } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Schedule as ScheduleIcon } from '@mui/icons-material';
import moment from 'moment';
import _ from 'lodash';
import Colors from '../../../../../styles/colors.module.scss';
import styles from './index.module.scss';
import { ErrorToast } from '../../../../../utils/ToastUtils';
import { JOB_APPLICATION_STATUS } from '../../../../../utils/Constants';
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as AiviIcon } from '../../../../../assets/common/aivi-icon.svg';
import Linkify from 'react-linkify';
import {
  FontAwesomeIconStyled,
  InterviewLengthContainer,
  Text
} from './styles';
import { useDispatch } from 'react-redux';
import { mutateViewedJobApplication } from '../../../../../redux/actions/job_action';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const useStyles = makeStyles({
  cardContainer: {
    // margin: '25px 25px 0px 25px',
    display: 'flex',
    // padding: '25px',
    boxShadow: '6px 2px 15px -10px rgba(0,0,0,0.53)',
    minHeight: '220px',
    borderRadius: 21,
    flexDirection: 'column',
    justifyContent: 'space-between',

    '&.MuiCardActionArea-root': {
      borderRadius: 21,
      // margin: '0px 20px 25px 0px',
      marginBottom: '15px',
      padding: '25px',
      width: '92%'
      // boxShadow: 5
    }
  }
});

const CandidateCard = ({
  id,
  user,
  index,
  isViewed,
  appliedAt,
  chatbotStatus,
  status,
  isSelected,
  setIsSelected,
  isLastItem,
  isInterviewOrOffer,
  setCandidates,
  scrollToTop,
  getCurrentCandidates,
  setCurrentCandidates,
  windowHeight,
  candidate,
  applicationStatus
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const viewCandidateTracking = cCandidates => {
    const selectedCandidate = cCandidates[index].node;
    const cApplication = selectedCandidate?.jobApplication
      ? selectedCandidate.jobApplication
      : selectedCandidate;

    if (cApplication) {
      const jobId = cApplication?.job?.id;
      const userId = cApplication?.user?.id;

      try {
        switch (applicationStatus) {
          case 'all':
            window.dataLayer.push({
              event: 'view-applicant-list-all',
              job_id: jobId,
              user_id: userId
            });
            break;
          case 'undecided':
            window.dataLayer.push({
              event: 'view-applicant-list-undecided',
              job_id: jobId,
              user_id: userId
            });
            break;
          case 'shortlisted':
            window.dataLayer.push({
              event: 'view-applicant-list-shortlisted',
              job_id: jobId,
              user_id: userId
            });
            break;
          case 'kiv':
            window.dataLayer.push({
              event: 'view-applicant-list-kiv',
              job_id: jobId,
              user_id: userId
            });
            break;
          case 'interview':
            window.dataLayer.push({
              event: 'view-applicant-list-interview',
              job_id: jobId,
              user_id: userId
            });
            break;
          case 'offer':
            window.dataLayer.push({
              event: 'view-applicant-list-offered',
              job_id: jobId,
              user_id: userId
            });
            break;
        }
      } catch {}

      // if (jobId && userId) {
      //   const eventName = 'view_applicant_list';
      //   try {
      //     dataLayer.push({
      //       event: eventName,
      //       job_id: jobId,
      //       user_id: userId
      //     });
      //   } catch (error) {
      //     //
      //   }
      // }
    }
  };

  const onClickCandidateCard = async () => {
    console.log('clicked')
    try {
      setIsSelected(index);

      if (windowDimensions?.width < 1025) {
        window.scrollTo({
          top: windowHeight,
          behavior: 'smooth'
        });
      }

      scrollToTop();

      let currentCandidates = [...getCurrentCandidates()];

      viewCandidateTracking(currentCandidates);

      if (!isViewed) {
        dispatch(
          mutateViewedJobApplication({
            input: { id: Number(id) }
          })
        ).then(res => {
          currentCandidates[index] = {
            ...currentCandidates[index],
            node: {
              ...currentCandidates[index]?.node,
              status: res?.viewedJobApplication?.status
            }
          };

          if (setCurrentCandidates) {
            setCurrentCandidates(currentCandidates);
          }

          setCandidates(currentCandidates);
        });
      }
    } catch (err) {
      ErrorToast(err.message);
    }
  };

  let backgroundColor = null;
  let border = null;
  let boxShadow = null;

  if (isSelected) {
    if (!isViewed && status == JOB_APPLICATION_STATUS.UNDECIDED) {
      backgroundColor = Colors.priWhite;
      border = `2.5px solid ${Colors.priPurple}`;
      boxShadow = `0px 3px 12px ${Colors.terLightGrey}`;
    } else {
      backgroundColor = '#F7F7F8';
      border = `2.5px solid ${Colors.priPurple}`;
      boxShadow = `0px 3px 12px ${Colors.terLightGrey}`;
    }
  } else if (!isViewed && status == JOB_APPLICATION_STATUS.UNDECIDED) {
    backgroundColor = Colors.priWhite;
    border = `1px solid ${Colors.priBlack}`;
  } else {
    backgroundColor = '#F7F7F8';
    border = null;
  }

  const renderStatus = () => {
    let statusColor = null;
    let statusTextColor = null;

    const application = candidate?.jobApplication
      ? candidate?.jobApplication
      : candidate;

    if (application?.employerHiredStatus != null) {
      statusColor = 'rgba(33, 61, 181, 0.2)';
      statusTextColor = '#213DB5';
    } else {
      switch (status) {
        case JOB_APPLICATION_STATUS.SHORTLISTED:
          statusColor = 'rgba(14, 162, 9, 0.15)';
          statusTextColor = 'rgb(14, 162, 9)';
          break;
        case JOB_APPLICATION_STATUS.REJECTED:
          statusColor = '#CE343C26';
          statusTextColor = '#CE343C';
          break;
        case JOB_APPLICATION_STATUS.KIV:
          statusColor = '#FFA32926';
          statusTextColor = '#FFA329';
          break;
        case JOB_APPLICATION_STATUS.BLACKLISTED:
          statusColor = '#22222226';
          statusTextColor = Colors.priBlack;
          break;

        default:
          return null;
      }
    }

    return (
      <div
        className={styles.statusContainer}
        style={{
          backgroundColor: statusColor,
          padding: '5px 14px',
          borderRadius: '30px',
          margin: '0 0 0 5px'
        }}>
        <span
          className={styles.status}
          style={{
            color: statusTextColor,
            fontWeight: '700',
            fontSize: '11px'
          }}>
          {application?.employerHiredStatus != null
            ? 'Hired'
            : status === JOB_APPLICATION_STATUS.KIV
            ? _.toUpper(status)
            : _.capitalize(status)}
        </span>
      </div>
    );
  };

  const getInterviewLength = interviews => {
    const remainder = interviews.length % 10;

    if (interviews.length > 0) {
      if (remainder == 1) {
        return interviews.length + `st`;
      } else if (remainder == 2) {
        return interviews.length + `nd`;
      } else if (remainder == 3) {
        return interviews.length + `rd`;
      } else {
        return interviews.length + `th`;
      }
    } else {
      return;
    }
  };

  const getInterviewIcon = candidate => {
    let application =
      candidate && candidate.jobApplication
        ? candidate.jobApplication
        : candidate;

    if (application?.interviews?.length > 0) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ position: 'relative' }}>
            {application?.interviews?.length > 0 && (
              <InterviewLengthContainer>
                <Text type="interview_length">
                  {getInterviewLength(application?.interviews)}
                </Text>
              </InterviewLengthContainer>
            )}

            <FontAwesomeIconStyled
              icon={faCalendarAlt}
              awesome_icon={'faCalenderAlt'}
              interview_length={application?.interviews?.length}
            />
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <CardActionArea
      onClick={onClickCandidateCard}
      className={classes.cardContainer}
      style={{
        backgroundColor,
        border,
        marginBottom: isLastItem ? 40 : null,
        boxShadow
      }}>
      {!isViewed && status == JOB_APPLICATION_STATUS.UNDECIDED && (
        <Tooltip title="Unviewed" placement="bottom">
          <div className={styles.viewedStatusWrapper}>
            <div className={styles.indicator} />
          </div>
        </Tooltip>
      )}
      <div
        className={styles.cardBodyContainer}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%'
        }}>
        <div>
          <p className={styles.title}>{user?.name}</p>
          <p className={styles.subTitle}>
            <Linkify>{user?.shortSummary}</Linkify>
          </p>
        </div>

        <div
          style={
            status == JOB_APPLICATION_STATUS.UNDECIDED
              ? {
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center'
                }
              : {
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center'
                }
          }>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              alignSelf: 'center'
            }}>
            <ScheduleIcon style={{ fontSize: '14px' }} />
            <span style={{ paddingLeft: '4px', fontSize: '12px' }}>
              {moment(appliedAt).format('DD MMMM YYYY')}
            </span>
          </div>

          <div style={{ display: 'flex', margin: '0 10px' }}>
            {user?.videoCvOptimized && (
              <div
                style={{
                  marginRight: '8px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                <FontAwesomeIcon
                  icon={faVideo}
                  style={{ fontSize: '11px', color: '#222222' }}
                />
              </div>
            )}
            {chatbotStatus == 'bot_completed' && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '8px'
                }}>
                <AiviIcon width={12} />
              </div>
            )}
            {getInterviewIcon(candidate)}
          </div>

          {(!isInterviewOrOffer ||
            (isInterviewOrOffer && candidate?.offered)) && (
            <div>{renderStatus()}</div>
          )}
        </div>
      </div>
    </CardActionArea>
  );
};
export default CandidateCard;
