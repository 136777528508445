import { Skeleton } from '@mui/material';
import React from 'react';
import styles from './index.module.scss';

const SkeletonLoader = () => (
  <div className={styles.chatSectionSkeleton}>
    <div className={styles.headerSkeleton}>
      <Skeleton variant="text" className={styles.titleSkeleton} />
      <Skeleton
        variant="rect"
        height={50}
        width={200}
        className={styles.buttonSkeleton}
      />
    </div>
    <div>
      <Skeleton
        variant="rect"
        height={400}
        width='100%'
        className={styles.chatWrapperSkeleton}
      />
      <Skeleton
        variant="rect"
        height={50}
        width='100%'
        className={styles.sendTextSkeleton}
      />
    </div>
  </div>
);

export default SkeletonLoader;
