import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Error as ErrorIcon } from '@mui/icons-material';

export const TextFieldStyled = styled(TextField)(
  ({ theme, small, value, select, bold, white, name }) => ({
    width: "100%",
    // Adjust the padding of the input field to change its height
    '& .MuiInputBase-root': {
    
      fontSize: '0.85rem',
      color:
        value === 'placeholder' && select
          ? '#d8d8d8'
          : white
          ? 'white'
          : 'black', // Text color
      fontWeight: bold && 'bold',
      ...(small && {
        height: '45px',
        '& .MuiOutlinedInput-input': {
          '&::placeholder': {
            color: '#d8d8d8 !important', // Change to desired placeholder color
            opacity: 1 // Ensure the placeholder is fully visible
          },
          paddingTop: '10px', // Reduced top padding
          paddingBottom: '5px', // Increased bottom padding to move text down
          paddingLeft: '14px', // Maintain side paddings
          paddingRight: '14px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
          top: 0
        }
      })
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: name == "location" ? '30px' : '8px',
        borderColor: white ? 'white' : 'black' // Border color
      },
      '&:hover fieldset': {
        borderColor: white ? 'white' : 'black' // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: white ? 'white' : 'black' // Border color on focus
      }
    },
    ...(small && {
      height: '45px' // Adjust the height of the entire component if small is true
    })
  })
);

export const ErrorIconStyled = styled(ErrorIcon)({
  fontSize: '36px',
  color: '#512acc'
});

export const BannerContainer = styled(Grid)({
  display: "flex",
  border: '2px solid #512acc',
  width: '1000px',
  padding: '20px 35px',
  background: '#fff',
  boxShadow: `0px 28px 23px rgba(0, 0, 0, 0.08)`,
  borderRadius: '26px',
  margin: 'auto',
  display: 'flex',
  gap: '20px',
  flexDirection: 'row',
  color: 'black',
  flexWrap: 'nowrap',
  marginTop: "50px"
});
