import React from 'react';
import { ButtonStyled } from '../SharedButton/styles';

export const SharedButton = ({
  id,
  onClick,
  type,
  children,
  font_size,
  padding,
  disabled,
  custom_label,
  width,
  text_transform,
  border,
  border_radius,
  text_color 
}) => {
  return (
    <ButtonStyled
      id={id}
      custom_label={custom_label}
      type={type}
      onClick={onClick}
      font_size={font_size}
      padding={padding}
      disabled={disabled}
      width={width}
      text_transform={text_transform}
      border={border}
      border_radius={border_radius}
      text_color={text_color}>
      {children}
    </ButtonStyled>
  );
};
