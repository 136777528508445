export const LOCATION_ALL_LABEL = {
    'MY': 'All States',
    'SG': 'All Regions'
}

export const NATIONALITY_OPTIONS = {
    'MY': [
        { value: "Malaysian", label: "Malaysian" },
        { value: "Foreign", label: "Foreign National" },
        { value: "", label: "Both" },
    ],
    'SG': [
        { value: "Singaporean", label: "Singaporean" },
        { value: "Foreign", label: "Foreign National" },
        { value: "", label: "Both" },
    ],
}

export const WORKPLACE_TYPE = {
    RELOCATE: 'relocate',
    REMOTE: 'remote'
}

export const WORK_RIGHTS = {
    SPONSOR: 'openToSponsor',
    VISA: 'visaOrEmploymentPass',
    LOCAL: 'localCitizen',
}