import { styled as styling } from '@mui/material/styles';
import { Divider, Grid } from '@mui/material';
import { Typography } from '@mui/material';

export const ProfileContainer = styling('div')({
  padding: '16px'
});

export const ContactContainer = styling(Grid)(({ theme }) => ({
  padding: '0.25rem 0',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 50%)'
}));

export const TalentContact = styling('div')(({ theme, $unlocked }) => ({
  display: 'flex',
  flexDirection: 'column',

  ...($unlocked && {
    background: 'linear-gradient(to left, #512acc, #6d32c5, #983eba)',
    '-webkit-text-fill-color': 'transparent',
    '-webkit-background-clip': 'text'
  }),

  h5: {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.15px',
    // marginBottom: '0.25rem'
  }
}));

export const SectionTitle = styling('span')(({ theme }) => ({
  fontSize: '12px',
  fontFamily: 'Inter',
  fontWeight: 600,
  color: theme.palette.black.main
}));

export const SubjectTitle = styling('span')(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'Inter',
  fontWeight: 700,
  color: theme.palette.black.main,
  letterSpacing: '0.1px'
}));

export const SubjectSubtitle = styling('span')(({ theme, $list }) => ({
  fontSize: '12px',
  fontFamily: 'Inter',
  lineHeight: '20px',
  fontWeight: '400',
  color: theme.palette.black.main,

  ...($list && {
    display: 'list-item',
    listStyleType: 'disc',
    listStylePosition: 'inside',
    padding: '1px 0'
  })
}));

export const SubjectYear = styling('span')(({ theme }) => ({
  fontSize: '10px',
  fontFamily: 'Inter',
  color: theme.palette.black.main,
  letterSpacing: '1.5px'
}));

export const SubjectDescription = styling('div')(({ theme }) => ({
  fontSize: '12px',
  fontFamily: 'Inter',
  color: theme.palette.black.main,
  lineHeight: '20px'
}));

export const ProfileChipsContainer = styling(Grid)({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  flexWrap: 'wrap'
});

export const ProfileChip = styling('div')(({ theme }) => ({
  background: theme.palette.white.secondary,
  borderRadius: '4px',
  padding: '2px 8px',
  display: 'flex',
  alignItems: 'center'
}));

export const ProfileChipText = styling('span')({
  fontFamily: 'Inter',
  fontSize: '12px',
  color: 'rgba(0, 0, 0, 0.6)'
});

export const ExclamationCircleIconText = styling('span')({
  fontSize: '14px',
  fontFamily: 'Inter',
  letterSpacing: '0.15px'
});

export const NoResultsWorkExperience = styling('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  alignItems: 'center',
  padding: '20px',
  borderRadius: '4px',
  background: '#be42421a'
});

export const NoResultsWorkExperienceText = styling('span')({
  fontSize: '14px',
  fontFamily: 'Inter',
  letterSpacing: '0.15px'
});

export const ClickHere = styling('span')({
  fontSize: '14px',
  fontFamily: 'Inter',
  letterSpacing: '0.15px',
  fontWeight: 700,
  cursor: 'pointer',
  textDecoration: 'underline',
  textUnderlineOffset: '2px'
});

export const ReportedMessageStyled = styling('span')({
  fontSize: '12px',
  fontFamily: 'Inter',
  fontWeight: 400,
  fontStyle: 'italic',
  color: props => props.$textColor
});

export const DividerStyled = styling(Divider)(({ theme }) => ({
  borderStyle: 'dashed',
  margin: '20px 0',
  borderColor: theme.palette.grey['400']
}));

export const LabelContainer = styling('div')({
  display: 'grid',
  width: '100%'
});

export const SectionLabel = styling(Typography)({
  color: '#40249B',
  fontWeight: '400',
  fontSize: '10px',
  fontFamily: 'Inter',
  lineHeight: '20px',
  letterSpacing: '1.5px',
  textTransform: 'uppercase',
  padding: '0'
});

export const SectionContainer = styling('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
  alignSelf: 'stretch'
});

export const SubSectionContainer = styling('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px',
  alignSelf: 'stretch'
});

export const SubContactContainer = styling('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 50%)',
  width: '100%',
});
