import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MUIButtonStyled } from '../../../components/MUIButton';
import DefaultLayout from '../../../layouts/DefaultLayout';
import {
  fetchCompanyAffiliations,
  fetchCompanyName,
  mutateUpdateCompanyAffiliation
} from '../../../redux/actions/company_actions';
import { ErrorToast, SuccessToast } from '../../../utils/ToastUtils';
import AffiliationRequest from './Components/AffiliationRequest';
import AddAffiliatePopUp from './PopUps/AddAffiliatePopUp';
import SkeletonLoader from './SkeletonLoader';
import styles from './index.module.scss';
import {
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import Company from './TableColumns/Company';
import Relationship from './TableColumns/Relationship';
import Status from './TableColumns/Status';

const ManageAffiliates = () => {
  // NOTE: COMMENT OUT SEARCH FUNCTION FOR COMPANY AFFILIATIONS

  const dispatch = useDispatch();
  const [isAddPopUpOpen, setIsAddPopUpOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    dispatch(fetchCompanyName());
  }, []);

  const company = useSelector(state => state.companies.myCompany);

  const fetchingMyCompany = useSelector(
    state => state.companies.fetchingMyCompany
  );

  const companyAffiliations = useSelector(
    state => state.companies.companyAffiliations
  );

  const fetchingCompanyAffiliations = useSelector(
    state => state.companies.fetchingCompanyAffiliations
  );

  const mutatingCompanyAffiliate = useSelector(
    state => state.companies.mutatingCompanyAffiliate
  );

  useEffect(() => {
    dispatch(
      fetchCompanyAffiliations({
        name: searchText
      })
    );
  }, [searchText]);

  useEffect(() => {
    if (companyAffiliations) {
      setTableData(companyAffiliations);
    }
  }, [companyAffiliations]);

  const updateAffiliationStatus = async (affiliatedCompanyId, status) => {
    dispatch(
      mutateUpdateCompanyAffiliation({
        input: {
          affiliatedCompanyId: affiliatedCompanyId,
          status: status
        }
      })
    )
      .then(res => {
        if (res.type === 'MUTATE_UPDATE_COMPANY_AFFILIATION_SUCCEED') {
          if (res.changeCompanyAffiliation == 'approved') {
            SuccessToast('Successfully approved affiliation');
          } else {
            SuccessToast('Successfully rejected affiliation');
          }
        } else {
          ErrorToast(
            `Failed to ${
              status == 'approved' ? 'approve' : 'reject'
            } affiliation`
          );
        }
      })
      .catch(err => {
        ErrorToast(err.message);
      });
  };

  return (
    <DefaultLayout>
      <div
        style={{
          padding: company?.affiliatedCompany ? '2rem 4rem 4rem 4rem' : '4rem',
          maxWidth: '1920px',
          margin: 'auto'
        }}>
        <AddAffiliatePopUp
          open={isAddPopUpOpen}
          handleClose={() => setIsAddPopUpOpen(false)}
          name={company?.name}
          companyId={company?.id}
        />
        <AffiliationRequest
          company={company}
          updatingCompanyAffiliation={mutatingCompanyAffiliate}
          updateAffiliationStatus={updateAffiliationStatus}
        />
        <Grid container>
          <Grid item xs={12}>
            <div style={{ paddingBottom: 25 }}>
              {fetchingMyCompany ? (
                <Skeleton variant="text" animation="wave" width={300} />
              ) : (
                <h6
                  style={{
                    fontSize: '20px'
                  }}>{`${company?.name}’s Affiliated Companies`}</h6>
              )}
            </div>
          </Grid>

          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <div className={styles.addTeamMemberButton}>
              <MUIButtonStyled
                label="Add Affiliated Company"
                disabled={fetchingCompanyAffiliations}
                onClick={() => setIsAddPopUpOpen(true)}
              />
            </div>
          </Grid>
        </Grid>
        <div className={styles.tableWrapper}>
          {fetchingCompanyAffiliations ? (
            <SkeletonLoader />
          ) : (
            <MyTable tableData={tableData} />
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};

const MyTable = ({ tableData }) => {
  const columns = [
    {
      name: 'company',
      label: 'Company',
      component: Company
    },
    {
      name: 'relationship',
      label: 'Relationship',
      component: Relationship
    },
    {
      name: 'status',
      label: 'Status',
      component: Status
    }
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell key={column.name}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row, index) => (
            <TableRow key={index}>
              {columns.map(column => (
                <TableCell key={column.name}>
                  <column.component index={index} tableData={tableData} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ManageAffiliates;
