import React from 'react';
import { Tooltip } from './styles';

const CustomTooltip = ({
  width,
  height,
  content,
  front_transform,
  back_transform,
  tooltip_transform,
  border_color,
  thumb_color,
  origin,
  top,
  right,
  left
}) => {
  return (
    <Tooltip
      width={width}
      height={height}
      tooltip_transform={tooltip_transform}
      front_transform={front_transform}
      back_transform={back_transform}
      border_color={border_color}
      thumb_color={thumb_color}
      origin={origin}
      top={top}
      right={right}
      left={left}>
      <div>{content}</div>
    </Tooltip>
  );
};

export default CustomTooltip;
