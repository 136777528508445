import { styled as styling } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import { Skeleton } from '@mui/material';

export const LabelStyled = styling(Typography)((props) => ({
  fontWeight: 'regular',
  fontSize: '14px',
  lineHeight: '1.5rem',
  fontFamily: 'Inter',
  letterSpacing: '0.03rem',
  ...(props.sort_by === 'true' && {
    fontSize: '14px',
  }),
  ...(props.card_info === 'true' && {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'break-spaces',
  }),
  ...(props.applicant_card_info === 'true' && {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    display: '-webkit-box',
    '-webkit-line-clamp': '5',
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'break-spaces',
  }),
  ...(props.applicant_card_info_time === 'true' && {
    fontSize: '14px',
    color: '#aeaeae',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'break-spaces',
  }),
}));

export const Title = styling(Typography)((props) => ({
  fontFamily: 'Inter',
  letterSpacing: '0.03rem',
  fontSize: '16px',
  fontWeight: 'bold',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  whiteSpace: 'break-spaces',
}));

export const SortByContainer = styling(Grid)((props) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  maxWidth: '90%',
  margin: 'auto',
  alignSelf: 'center',
  alignItems: 'center',
}));

export const CardsContainer = styling(Grid)((props) => ({
  margin: '20px auto',
  maxWidth: '90%',
}));

export const CardStyled = styling(Card)((props) => ({
  borderRadius: '20px',
  border: '1px solid #aeaeae',
  padding: '25px',
  margin: '20px 0',
  cursor: 'pointer',
  ...(props.active == 'true' && {
    border: '2px solid #512acc',
  }),
}));

export const CardContentStyled = styling(CardContent)((props) => ({
  minHeight: '130px',
  height: '100%',
  display: 'flex',
  padding: '0 !important',
  ...(props.applicant_card_height == 'true' && {
    height: '250px',
  }),
}));

export const TextContainer = styling(Grid)((props) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
  justifyContent: 'space-between',
  maxWidth: '100%',
}));

export const ApplicantCardSection = styling(Grid)((props) => ({
  width: '100%',
  padding: '20px 2%',
  maxHeight: 'calc(100vh - 64px)',
  height: '100%',
  overflow: 'auto',
}));

export const ChipStyled = styling(Chip)((props) => ({
  fontWeight: 'bold',
  ...(props.label.toLowerCase() == 'shortlisted' && {
    background: '#d0f0c0',
    color: 'green',
  }),
  ...(props.label.toLowerCase() == 'kiv' && {
    background: '#ffd580',
    color: 'orange',
  }),
  ...(props.label.toLowerCase() == 'rejected' && {
    background: '#ff7276',
    color: 'red',
  }),
  ...(props.label.toLowerCase() == 'blacklisted' && {
    background: '#000',
    color: '#fff',
  }),
}));

// SKELETON
export const CandidateCardWrapperSkeleton = styling('div')(() => ({
  position: 'relative',
  width: '90%',
  height: '300px',
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: '20px',
  margin: '20px auto',
}));

export const CandidateNameSkeleton = styling(Skeleton)(() => ({
  position: 'absolute',
  zIndex: '1',
  top: '10%',
  left: '8%',
}));

export const MessageSkeleton = styling(Skeleton)(() => ({
  position: 'absolute',
  zIndex: '1',
  ...(props) => ({
    ...(props.message_top == 'true' && {
      bottom: '60%',
      left: '8%',
    }),
    ...(props.message_middle == 'true' && {
      bottom: '52%',
      left: '8%',
    }),
    ...(props.message_last == 'true' && {
      bottom: '44%',
      left: '8%',
    }),
  }),
}));

export const DateSkeleton = styling(Skeleton)(() => ({
  position: 'absolute',
  zIndex: '1',
  bottom: '10%',
  left: '8%',
}));

export const StatusButtonSkeleton = styling(Skeleton)(() => ({
  position: 'absolute',
  zIndex: '1',
  bottom: '10%',
  right: '8%',
}));
