export const RECORD_TYPE = {
  COMPANY_IMAGE: 'CompanyImage',
  COMPANY_LOGO: 'CompanyLogo',
  JOB_IMAGE: 'JobImage'
};

export const SUBMIT_TYPE = {
  SAVE_DRAFT_JOB: 'SAVE_DRAFT_JOB',
  SAVE_JOB: 'SAVE_JOB',
  SAVE_JOB_TEMP: 'SAVE_JOB_TEMP'
};

export const FREE_JOB_POST_POSITION = {
  INTERN: 'internship',
  SENIOR: 'free_super_senior'
};

export const PAID_JOB_POST_POSITION = {
  PAID_INTERN: 'paid_internship'
};

export const CANDIDATES_SORT_BY = {
  DATE_APPLIED: 'DATE_APPLIED',
  EARLIEST: 'EARLIEST',
  LATEST: 'LATEST'
};

export const TALENTS_SORT_BY = {
  LAST_ACTIVE_AT: 'LAST_ACTIVE_AT',
  UPLOAD_RESUME_AT: 'UPLOAD_RESUME_AT',
  RELEVANCE: 'RELEVANCE'
};

export const SEARCH_TYPE = {
  ANY: 'any',
  EXACT: 'exact',
  ALL: 'all'
};

export const JOB_APPLICATION_STATUS = {
  ALL: 'all',
  UNVIEWED: 'unviewed',
  UNDECIDED: 'undecided',
  SHORTLISTED: 'shortlisted',
  KIV: 'kiv',
  REJECTED: 'rejected',
  BLACKLISTED: 'blacklisted',
  INTERVIEW: 'interview',
  OFFER: 'offer'
};

export const FILE_EXTENSION_TYPE = {
  PDF: 'pdf',
  DOC: 'doc',
  DOCX: 'docx'
};

export const AFFILIATION_TYPE = {
  SISTER: 'sister',
  SUBSIDIARY: 'subsidiary'
};

export const AFFILIATION_APPROVAL_STATUS = {
  APPROVED: 'approved',
  PENDING: 'pending',
  REJECTED: 'rejected'
};

export const CONTACT_CONSULTANTS_SUBJECT = {
  OPTION_1: 'I want to create a Company Video.',
  OPTION_2: 'I want to know more about Company Videos.',
  OPTION_3: 'I have a product question.'
};

export const CONTACT_US_SUBJECT = {
  OPTION_1: 'I want to evaluate which plan is best for me.',
  OPTION_2: 'I want to request for a custom package.',
  OPTION_3: 'I have a product question.',
  OPTION_4: 'Homepage Featured Companies',
  OPTION_5: 'Homepage Carousel',
  OPTION_6: 'Advice Page Ad',
  OPTION_7: 'Homepage Carousel & Advice Page Ads',
  OPTION_8: 'I want a product demo/onboarding',
  OPTION_9: 'I’m interested in Job Slot Plus',
  OPTION_10: 'I’m interested in Job Slot Max'
};

export const WOBB_WEBSITE =
  'https://wobbjobs.hiredly.com/v2/employers/sessions/new';

export const ENV = {
  DEV: 'DEV',
  STAGING: 'STAGING',
  PROD: 'PROD'
};

export const MANAGE_CANDIDATE_MODE = 'MANAGE_CANDIDATE_MODE';

export const highestEduLevel = [
  { id: 'Secondary School', title: 'Secondary School' },
  {
    id: 'Pre-University (A-Levels, Foundation, Diploma or equivalent)',
    title: 'Pre-University (A-Levels, Foundation, Diploma or equivalent)'
  },
  {
    id: `Undergraduate (Bachelor's Degree or equivalent)`,
    title: `Undergraduate (Bachelor's Degree or equivalent)`
  },
  {
    id: `Postgraduate (Master's Degree or equivalent or higher)`,
    title: `Postgraduate (Master's Degree or equivalent or higher)`
  },
  { id: 'Other', title: 'Other' }
];

export const languages = [
  'Afrikaans',
  'Arabic',
  'Bangla',
  'Bulgarian',
  'Burmese',
  'Cantonese',
  'Chinese',
  'Croatian',
  'Czech',
  'Danish',
  'Dutch',
  'English',
  'Finnish',
  'French',
  'German',
  'Greek',
  'Hebrew',
  'Hindi',
  'Hokkien',
  'Hungarian',
  'Indonesian',
  'Italian',
  'Japanese',
  'Khmer',
  'Korean',
  'Lao',
  'Malay',
  'Norwegian',
  'Persian',
  'Polish',
  'Portuguese',
  'Romanian',
  'Russian',
  'Spanish',
  'Swedish',
  'Tagalog',
  'Tamil',
  'Thai',
  'Turkish',
  'Ukrainian',
  'Vietnamese'
];

export const experiences = [
  {
    value: 0,
    label: '0'
  },
  {
    value: 10,
    label: '10+'
  }
];

export const keywordSearchTypes = [
  { id: 'or', title: 'Any of the Words' },
  { id: 'and', title: 'All of the Words' },
  { id: 'nil', title: 'Exact Phrase' }
];

export const talentSortBy = [
  { id: 'RELEVANCE', title: 'Relevance' },
  { id: 'UPLOAD_RESUME_AT', title: 'Resume uploaded on' },
  { id: 'LAST_ACTIVE_AT', title: 'Last active on' }
];

export const productCodeForCE = {
  limited_edition_package: 'limited_edition',
  popular_package: 'popular',
  enterprise_package: 'enterprise',
  booster_token: 'featured_token',
  refresher_token: 'refresher_token',
  spotlight_token: 'spotlight_jobs',
  keyword_highlight_token: 'key_highlights',
  job_credit_single: 'job_credit',
  job_slot: 'job_slot',
  job_slot_plus: 'job_slot_plus',
  job_slot_max: 'job_slot_max',
  homepage_featured_company: 'homepage_featured_companies',
  homepage_carousel_adspace: 'homepage_carousel',
  advice_page_ads: 'advice_page_ad',
  coins_x5000: 'talent_token_bundle_5000'
};

export const JOBSEEKER_URL = {
  MY: process.env.REACT_APP_JOBSEEKER_URL || 'https://my.hiredly.com',
  SG: process.env.REACT_APP_JOBSEEKER_SG_URL || 'https://sg.hiredly.com'
};

export const CSEMAIL = {
  MY: 'employer@hiredly.com',
  SG: 'employer@sg.hiredly.com'
};

export const Hiredly_Phone_Number = {
  MYWithCountryCode: '+60327710141',
  MYWithSpace: '+603 2771 0141'
};

export const HIREDLY_BANK_ACCOUNT = {
  MY: {
    name: 'Maybank Islamic Berhad',
    accountNumber: '5648 0165 2823',
    swiftCode: 'MBBEMYKL',
    company: 'Agensi Pekerjaan Wobb Sdn. Bhd. (1252077-A)'
  },
  SG: {
    name: 'Maybank Singapore Limited',
    accountNumber: '0417 4555 107',
    swiftCode: 'MBBESGS2XXX',
    company: 'Hiredly Group Pte. Ltd. (202339113C)'
  }
};

export const HIREDLY_NAME = {
  MY: { name: 'Agensi Pekerjaan Wobb Sdn. Bhd. (1252077-A)' },
  SG: {
    name: 'Hiredly Group Pte Ltd'
  }
};
