import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import usePathKey from './usePathKey';
import {
  fetchDraftJob,
  fetchJob,
  updateJobDataForm
} from '../../../../redux/actions/job_action';
import useData from './useData';
import { isEmpty } from 'lodash';

export const useFormData = jobIdParam => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const jobId = id || jobIdParam;
  const pathKey = usePathKey();
  const jobData = useData();

  const urlParams = new URLSearchParams(window.location.search);
  const editValue = urlParams.get('back');
  const jobDataForm = useSelector(state => state.jobs.jobDataForm);

  useEffect(() => {
    if (editValue !== 'true') {
      dispatch(updateJobDataForm({}));
    }
  }, []);

  const defaultFormData = {
    id: '',
    active: false,
    title: '',
    location: '',
    minSalaryRange: "0",
    maxSalaryRange: "500",
    hideSalary: false,
    description: '<p></p>',
    requirements: '<p></p>',
    descriptionDouble: '',
    descriptionAshley: '',
    requirementsDouble: '',
    requirementsAshley: '',
    tracks: [],
    jobType: {},
    careerLevel: {},
    stateRegion: { id: 'placeholder' },
    skills: [],
    minYearExperience: 0,
    maxYearExperience: 0,
    jobBannerUrl: '',
    jobBannerUrlSquare: '',
    receiveNotification: false,
    usingCustomEmail: false,
    customEmail: null,
    imagePosition: '',
    imageSize: '',
    localOnly: false,
    externalJobUrl: '',
    customInput: '',
    gptRewrites: {},
    keywords: [],
    resourceType: '',
    enableKeywordHighlight: false,
    globalHireEnabled: false,
    globalHirePreferences: {
      enabled: false,
      rightToWorkLocalCitizen: false,
      rightToWorkOpenToSponsor: false,
      rightToWorkPermanentCitizen: false,
      rightToWorkWorkVisaOrEmploymentPass: false,
      workingArrangementHybrid: false,
      workingArrangementOnSite: false,
      workingArrangementRemote: false
    }
  };

  const [job, setJob] = useState();
  const [formData, setFormData] = useState(defaultFormData);

  useEffect(() => {
    if (pathKey === 'draft') {
      dispatch(fetchDraftJob());
    } else if (pathKey === 'edit' && jobId) {
      dispatch(fetchJob({ id: jobId }));
    }
  }, [dispatch, jobId, pathKey]);

  useEffect(() => {
    if (jobData) {
      setJob({ ...jobData, keywords: jobData?.keywordHighlightText });
    }
  }, [jobData]);

  useEffect(() => {
    if (!isEmpty(jobDataForm)) {
      const newFormData = {
        ...defaultFormData,
        ...jobDataForm,
        keywords: jobData?.keywords || jobDataForm?.keywords
      };
      setFormData(newFormData);
    } else if (job) {
      const newFormData = { ...defaultFormData, ...job };
      setFormData(newFormData);
    } else {
      setFormData(defaultFormData);
    }
  }, [job, jobDataForm]);

  return formData;
};
