import { XMarkIcon } from '@heroicons/react/24/solid';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const SelectStyled = styled(Select)({
  '&.MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#512acc'
    }
  }
});

export const GridStyled = styled(Grid)({
  //
});

export const SelectTextStyled = styled(Typography)({
  fontWeight: 400,
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: 'rgba(0,0,0)',
  height: '25px'
});

export const SpanStyled = styled('span')({
  fontWeight: 400,
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: 'rgba(0,0,0, 0.6)',
  height: '25px'
});

export const CategoryPill = styled(Grid)({
  minWidth: '35px',
  // maxWidth: '135px',
  width: 'fit-content',
  gap: '6px',
  borderRadius: '4px',
  background: '#D8D8D8',
  padding: '2px 6px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '25px'
});

export const CategoryPillTitle = styled(Typography)({
  overflow: 'hidden',
  maxWidth: '170px',
  textOverflow: 'ellipsis',
  fontSize: '12px',
  fontFamily: 'Inter',
  lineHeight: '20px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontWeight: '400',
  margin: 0,
  whiteSpace: 'nowrap'
});

export const XMarkStyled = styled(XMarkIcon)({
  color: 'rgba(0,0,0,0.87)'
});

export const IconButtonStyled = styled(IconButton)({
  //
});

export const TextFieldStyled = styled(TextField)({
  margin: '16px 16px 8px 16px',
  display: 'flex',

  '& .Mui-focused fieldset': {
    borderColor: '#512acc !important'
  }
});

export const TextButtonStyled = styled(Button)({
  fontWeight: 400,
  fontFamily: 'Inter',
  fontSize: '12px',
  lineHeight: '20px',
  textTransform: 'none',
  color: '#512ACC',
  '&:hover': {
    background: 'rgba(81, 42, 204, 0.1)'
  }
});

export const CheckboxStyled = styled(Checkbox)({
  '&.Mui-checked': {
    color: '#222'
  },

  '&.MuiButtonBase-root > svg': {
    color: '#222'
  },
  padding: '0 9px'
});

export const MainCategoryText = styled(Typography)({
  fontWeight: 400,
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: 'rgba(0,0,0, 0.87)'
});

export const RowStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 15px',

  '&:hover': {
    background: '#EFEFEF'
  },

  '& .MuiTypography-root': {
    fontSize: 14,
    letterSpacing: '0.15px'
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20
  }
});

export const SelectedCountContainer = styled('span')({
  fontSize: '14px',
  fontFamily: 'Inter',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  width: '24px',
  height: '24px',
  border: '1px solid #D8D8D8',
  borderRadius: '2px',
  background: '#F5F5F5',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

export const MainCategoryFormLabel = styled(FormControlLabel)({
  //
});

export const ActionButtonStyled = styled(Button)({
  fontFamily: 'Inter',
  fontSize: '14px',
  borderRadius: '8px',
  width: '200px',
  height: '36px',
  fontWeight: '700',
  textTransform: 'none'
});
