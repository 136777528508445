import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../styles/colors.module.scss';
import { Menu } from '@mui/material';
import { Skeleton } from '@mui/material';

export const StatsWrapper = styling('div')(props => ({
  background: Colors.priWhite,
  borderRadius: '20px',
  padding: '30px 40px',
  minHeight: '412px',
  flex: 1
}));

export const ContentHeader = styling('div')(props => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

export const ButtonIconContainer = styling('div')(props => ({
  display: 'flex',
  alignItems: 'center'
}));

export const StatCardContainer = styling('div')(props => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap'
}));

export const Text = styling('span')(props => ({
  fontfamily: 'Inter',

  ...(props.content_title == 'true' && {
    fontSize: '18px',
    fontWeight: 700
  }),

  ...(props.iconText == 'true' && {
    fontSize: '14px'
  }),

  ...(props.status_count == 'true' && {
    fontSize: '20px',
    fontfamily: 'Inter',
    fontWeight: 700,
    textAlign: 'center',
    color: Colors.priPurple,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'break-spaces'
  }),

  ...(props.card_title == 'true' && {
    fontSize: '14px',
    color: Colors.priBlack,
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'break-spaces'
  }),

  ...(props.card_desc == 'true' && {
    fontSize: '12px',
    color: Colors.terDarkGrey,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'break-spaces'
  }),

  ...(props.card_desc_bold == 'true' && {
    fontSize: '12px',
    fontWeight: 'bold',
    color: Colors.terDarkGrey
  }),

  ...(props.menu_text == 'true' && {
    fontSize: '14px',
    color: Colors.priBlack
  })
}));

export const Spacer = styling('div')(props => ({
  width: props.width || '5px'
}));

export const HeightSpacer = styling('div')(props => ({
  height: props.height || '5px'
}));

export const MenuStyled = styling(Menu)(props => ({
  '.MuiMenu-paper': {
    boxShadow: '0px 28px 23px rgb(0 0 0 / 5%)',
    borderRadius: '13px',
    background: Colors.priWhite,
    padding: '5px 10px'
  }
}));

export const SkeletonStyled = styling(Skeleton)(props => ({
  ...(props.margin_top == 'true' && {
    marginTop: '10px'
  })
}));
