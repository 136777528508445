import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ResponsiveDrawer from '../../components/ResponsiveDrawer';
import Footer from '../../components/Footer';
import { HeightSpacer } from '../../components/Spacer/styles';
import { useDispatch, useSelector } from 'react-redux';
import { updateContactSalesPopupStatus } from '../../redux/actions/job_action';
import ContactPopUp from '../../pages/PromoPackages/ContactPopUp';
import { LayoutWrapper } from './styles';
import ContactWithoutLogin from '../../pages/PromoPackages/ContactWithoutLogin';
import PostJobPopup from '../../pages/Jobs/PopUps/PostJobPopup';
import AgreeToTermsPopUp from '../../pages/Jobs/PopUps/AgreeToTermsPopUp';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchAccountInfo } from '../../redux/actions/company_actions';
import { isEmpty } from 'lodash';

export default function DefaultLayout({
  white,
  children,
  isResponsive,
  showBanner,
  minHeight,
  minus300,
  noBackgroundColor,
  minusBetaBanner,
  noMarginTop,
  setIsDrawerOpened,
  origin,
  maxWidth,
  margin
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const accountInfo = useSelector(state => state.companies.accountInfo);
  const accessToken = localStorage.getItem('accessToken');
  const accountType = localStorage.getItem('currentAccountType');
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (accessToken !== null) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [accessToken]);

  let currentStyle = {};

  if (minHeight && minHeight !== '') {
    currentStyle.minHeight = minHeight;
  }

  if (!showBanner) {
    currentStyle.marginTop = 0;
  }

  if (minusBetaBanner) {
    let minusHeight = 50;
    currentStyle.minHeight = `calc(100vh - ${minusHeight}px)`;
  }

  if (noBackgroundColor) {
    currentStyle.background = 'transparent';
  }

  if (noMarginTop) {
    currentStyle.marginTop = '0';
  }

  return (
    <div>
      <LayoutWrapper
        margin={margin}
        white={white}
        style={currentStyle}>
        <ResponsiveDrawer
          showBanner={showBanner}
          minus300={minus300}
          setIsDrawerOpened={setIsDrawerOpened}
          origin={origin}>
          <div
            style={{
              paddingTop:
                !accountInfo?.isEmailVerified &&
                accessToken &&
                location?.pathname !== '/login'
                  ? '65px'
                  : '0px',
              position: 'relative',
              minHeight: 'calc(100vh - 140px)',
              maxWidth: maxWidth ? '1920px' : 'unset',
              margin: maxWidth ? 'auto' : 'unset',
              overflow:
                origin == 'ats' && maxWidth
                  ? 'scroll hidden'
                  : maxWidth
                  ? 'auto'
                  : 'unset'
            }}>
            {children}
          </div>
        </ResponsiveDrawer>
        <Footer />
      </LayoutWrapper>
      {loggedIn ? (
        <ContactPopUp
          open={useSelector(state => state.jobs.isContactSalesOpen)}
          handleClose={() => dispatch(updateContactSalesPopupStatus(false))}
        />
      ) : (
        <ContactWithoutLogin
          open={useSelector(state => state.jobs.isContactSalesOpen)}
          handleClose={() => dispatch(updateContactSalesPopupStatus(false))}
        />
      )}
      <PostJobPopup />
      {accountType == 'master' ? <AgreeToTermsPopUp /> : null}
    </div>
  );
}
