import { LoadingButton } from '@mui/lab';
import {
  IconButton,
  Grid,
  Button
} from '@mui/material';
import { styled as styling } from '@mui/material/styles';

export const CardContainer = styling('div')({
  display: 'flex',
  padding: '12px 16px',
  alignItems: 'center',
  gap: '4px',
  borderRadius: '6px',
  border: '1px solid #d8d8d8',
  background: '#FFF'
});

export const CardSection = styling('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '2px',
  flex: '1 0 0'
});

export const CardTitle = styling('span')({
  margin: '0',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px'
});

export const CardSubtitle = styling('span')(props => ({
  margin: '0',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const ChipContainer = styling('div')(props => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px'
}));

export const ButtonThumbsUp = styling(Button)(props => ({
  display: 'flex',
  padding: '12px',
  alignItems: 'flex-start',
  gap: '8px',
  borderRadius: '1000px',
  width: '44px',
  height: '44px',
  minWidth: 'unset'
}));

export const ButtonThumbsDown = styling(Button)(props => ({
  display: 'flex',
  padding: '12px',
  alignItems: 'flex-start',
  gap: '8px',
  borderRadius: '1000px',
  width: '44px',
  height: '44px',
  minWidth: 'unset'
}));

export const SecondCardContainer = styling('div')({
  display: 'flex',
  padding: '12px 16px',
  alignItems: 'flex-end',
  flexDirection: 'column',
  gap: '12px',
  borderRadius: '6px',
  background: '#FFF'
});

export const ErrorMessage = styling('div')(props => ({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',

  '& p': {
    color: '#F75443',
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: '500',
    margin: '0'
  }
}));

export const ButtonContainer = styling('div')({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '8px'
});

export const SubmitButton = styling(LoadingButton)({
  display: 'flex',
  width: '112px',
  minWidth: '112px',
  height: '24px',
  padding: '0 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',
  borderRadius: '8px',
  background: '#2E2E2E',
  color: 'rgba(255, 255, 255, 0.87)',
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '20px',
  textTransform: 'capitalize',
  textAlign: 'center',

  '&:hover': {
    cursor: 'pointer',
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    background: '#2E2E2E !important'
  }
});

export const BackButton = styling(Button)({
  display: 'flex',
  width: '112px',
  minWidth: '112px',
  height: '24px',
  padding: '0 20px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',
  borderRadius: '8px',
  background: 'transparent',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '20px',
  letterSpacing: '0.15px',
  textTransform: 'capitalize',
  textAlign: 'center',

  '&:hover': {
    cursor: 'pointer'
  }
});

export const TopSection = styling('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '2px',
  alignSelf: 'stretch'
});

export const SecondContainer = styling('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch'
  // height: '148px',
});

export const SecondSubContainer = styling('div')({
  display: 'flex',
  alignItems: 'flex-end',
  gap: '4px',
  alignSelf: 'stretch',
  justifyContent: 'space-between'
});

export const ChipText = styling('span')(props => ({
  margin: '0',
  color: props.error ? '#F75443' : 'rgba(0, 0, 0, 0.60)',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.15px'
}));

export const SimpleDescription = styling('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  flex: '1 0 0'
});

export const IconButtonStyled = styling(IconButton)(props => ({
  padding: '0px'
}));

export const BottomSection = styling(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 14px'
});
