import { styled } from '@mui/material/styles';

export const LayoutWrapper = styled('div')(
  ({ theme, white, margin }) => ({
    minHeight: '100vh',
    paddingTop: margin ? margin : '55px',
    backgroundColor: white ? 'white' : theme.palette.grey[100],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  })
);
