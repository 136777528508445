import { CheckIcon } from '@heroicons/react/24/outline';
import {
  HandThumbUpIcon,
  MinusSmallIcon,
  PlusSmallIcon
} from '@heroicons/react/24/solid';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import clevertap from 'clevertap-web-sdk';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCountry,
  getCurrency,
  getCurrenyCode,
  getTaxLabel
} from '../../../hooks/useRegionalisation';
import {
  updateContactSalesPopupStatus,
  updateSubjectType
} from '../../../redux/actions/job_action';
import {
  fetchCart,
  mutateCart,
  mutateCartSummary,
  updateInfoDialogState,
  updateProductInfoDialog
} from '../../../redux/actions/order_action';
import { formatCurrency } from '../../../utils/FormatCurrency';
import { accData } from '../../../utils/LocalStorageUtils';
import { transformArray } from '../ShoppingCart';
import {
  IconButtonStyled,
  ProductAddToCartButton,
  ProductDesc,
  ProductPrice,
  ProductTitle,
  QuantityButton,
  QuantityText,
  BoltIconStyled,
  PromoContainer,
  PromoText
} from '../styles';
import {
  AmountTag,
  ClockIconStyled,
  ContactButton,
  CustomTooltip,
  DarkPurpleTag,
  DarkPurpleTagText,
  DefaultContainer,
  LightPurpleTag,
  LightPurpleTagText,
  ProductCardContainer,
  ProductInnerContainer,
  PurpleChip,
  QuantityContainer,
  YearTypography
} from './styles';
import { countryCodeOptions } from '../../../components/SharedSelectCountry/constants';
import SoldOut from '../../../assets/images/products/soldout-banner.svg';
import { Typography } from '@mui/material';

export default function ProductCard(props) {
  const { product, singleCard, confirm, perYear, companyStatus } = props;
  const dispatch = useDispatch();
  const productsInBundle = useSelector(
    state => state.products.productsInBundle
  );

  const productPageInfo = product?.productPageInfo;
  const purchasable = productPageInfo?.purchasable !== 'false';
  const zeroCount = product?.quantityAvailable === 0;

  // Content (The card)
  const productDescription = productPageInfo?.productDescription;
  const productBundleInfo = product?.productBundleInfo;
  const selectedTab = useSelector(state => state.orders.selectedTab);

  const myCart = useSelector(state => state.orders.myCart);
  const cartList = myCart?.cartLists?.edges;
  const transformedArray = transformArray(cartList);

  const onMoreInfoClicked = (info, jobAdsTab) => {
    if (jobAdsTab) {
      if (info?.productCode == 'job_slot_max') {
        window.dataLayer.push({
          event: 'CE_info-icon-clicked--job-slot-max'
        });
      } else if (info?.productCode == 'job_slot_plus') {
        window.dataLayer.push({
          event: 'CE_info-icon-clicked--job-slot-plus'
        });
      } else if (info?.productCode == 'job_slot') {
        window.dataLayer.push({
          event: 'CE_info-icon-clicked--job-slot-normal'
        });
      }
    }

    if (info?.productPageInfo?.header) {
      dispatch(updateInfoDialogState(true));
      dispatch(updateProductInfoDialog(info));
    }
  };

  const talentToken = 'coins_x5000';
  const jobSlot = 'job_slot';
  const proJob = 'job_slot_plus';
  const superJob = 'job_slot_max';

  // Because when job ads is selected, add ons also will appear, which will take in the styling from job ads
  const jobAdsTab = selectedTab === 'Job Ads' && !props?.addOns;

  const renderTopTag = ({ type, zeroCount }) => {
    if (jobSlot === type) {
      return (
        <LightPurpleTag zeroCount={zeroCount}>
          <LightPurpleTagText zeroCount={zeroCount}>
            Most affordable
          </LightPurpleTagText>
        </LightPurpleTag>
      );
    }

    if (proJob === type) {
      return (
        <DarkPurpleTag zeroCount={zeroCount}>
          <HandThumbUpIcon color="white" width={14} height={14} />
          <DarkPurpleTagText zeroCount={zeroCount}>
            best value
          </DarkPurpleTagText>
        </DarkPurpleTag>
      );
    }

    if (superJob === type) {
      return (
        <LightPurpleTag zeroCount={zeroCount}>
          <LightPurpleTagText zeroCount={zeroCount}>
            maximum performance
          </LightPurpleTagText>
        </LightPurpleTag>
      );
    }
  };

  const renderAmountTag = (amount, confirm) => {
    const soldOut = amount === 0;

    if (amount === null) return null;

    return (
      <AmountTag soldOut={soldOut} confirm={confirm}>
        <ClockIconStyled soldOut={soldOut} />{' '}
        {`${soldOut ? 'SOLD OUT' : `${amount} LEFT`}`}
      </AmountTag>
    );
  };

  // Logic for quantity
  const [quantity, setQuantity] = useState(1);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    setQuantity(1);
  }, [product]);

  const incrementHandler = () => {
    setQuantity(quantity + 1);
  };

  const decrementHandler = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const buttonAction = async ({
    purchasable: purchasable,
    productCode: productCode,
    productId: productId
  }) => {
    if (purchasable) {
      dispatch(
        mutateCart({
          input: {
            productId: productId,
            quantity: quantity
          }
        })
      ).then(res => {
        // refresh cart after adding
        dispatch(fetchCart());
        dispatch(
          mutateCartSummary({
            input: {
              cartId: myCart?.id ?? ''
            }
          })
        );
      });
    } else {
      if (productCode == 'homepage_featured_company') {
        dispatch(updateSubjectType('homepage-featured-company'));
      } else if (productCode == 'homepage_carousel_adspace') {
        dispatch(updateSubjectType('homepage-carousel-adspace'));
      } else if (productCode == 'advice_page_ads') {
        dispatch(updateSubjectType('advice-page-ads'));
      }

      if (
        productCode == 'homepage_featured_company' ||
        productCode == 'homepage_carousel_adspace' ||
        productCode == 'advice_page_ads'
      ) {
        window.dataLayer.push({
          event: 'CE_contact-us-clicked--purchase-premium-ad-space'
        });
      }
      cartUserProperties('premium_ad_space');

      dispatch(updateContactSalesPopupStatus(true));
    }
  };

  function cartUserProperties(value) {
    if (getCountry()) {
      clevertap.profile.push({
        Site: {
          cart_status: value
        }
      });
    }
  }

  const listWithCustomIcons = (input, zeroCount) => {
    // Split the string into parts for the <ul> tag and between <li> tags
    const parts = input
      .split(/<ul>|<\/ul>|<\/?li>/)
      .filter(part => part.trim() !== '');

    const text = input?.trim();
    const startsWithDiv = text?.startsWith('<div>');

    return (
      <div>
        <style>
          {`
          .custom-ul-card {
            list-style: none; 
            padding: 0px !important; 
            margin: 0px !important;
          }
          .custom-li-card {
            display: flex; 
            align-items: center !important; 
            font-weight: 400;
            font-family: Inter;
            letter-spacing: 0.15px;
            color: rgba(0, 0 ,0, 0.87);
            text-align: left;

          }
          .custom-li-card {
            margin-right: 8px; 
          }
        `}
        </style>
        {startsWithDiv && (
          <div dangerouslySetInnerHTML={{ __html: parts?.at(0) }} />
        )}
        {!startsWithDiv && (
          <ul className="custom-ul-card">
            {parts.map((text, index) => (
              <li
                key={index}
                className="custom-li-card"
                style={{ opacity: zeroCount ? 0.4 : 1 }}>
                <span
                  style={{ height: '14px', width: '14px', marginRight: '4px' }}>
                  <CheckIcon
                    className="custom-li-card"
                    color={`rgba(0, 0 ,0, 0.87)`}
                    height="14px"
                    width="14px"
                  />
                </span>
                <div dangerouslySetInnerHTML={{ __html: text }} />
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const showPerYear = () => {
    if (jobAdsTab && !confirm) {
      return true;
    } else if (confirm && perYear) {
      return true;
    }

    return false;
  };

  const fetchingMyCart = useSelector(state => state.orders.fetchingMyCart);
  const mutatingCart = useSelector(state => state.orders.mutatingCart);
  const mutatingCartSummary = useSelector(
    state => state.orders.mutatingCartSummary
  );

  const cartLoading = fetchingMyCart || mutatingCart || mutatingCartSummary;

  const matchedItemQuantity = transformedArray?.find(
    cartItem => cartItem.productId === product.id
  )?.quantity;

  // The number of token added to to cart
  const inCartQuantity = matchedItemQuantity ? matchedItemQuantity : 0;

  // Max token has been added to cart
  let maxCount = inCartQuantity === product?.quantityAvailable;

  // Available tokens after added > 1 token
  let available = product?.quantityAvailable
    ? product?.quantityAvailable - inCartQuantity
    : Infinity;

  // This is for when adding slot to cart with bundles
  // if the product is included in one of the bundles, need to calculate the availability as well
  // check from cart, if it is included inside that item
  // Find the quantity of bundle added to cart

  // Match the bundle
  const matchedBundle = productsInBundle?.find(bundle => {
    return (
      bundle?.inBundleCode === product?.productCode ||
      bundle?.productCode === product?.productCode
    );
  });

  const bundlesFromCart = transformedArray
    ?.filter(product => product?.packageType === 'bundle')
    .map(product => ({
      bundle: product?.productCode,
      products: product.productsInBundle,
      quantity: product?.quantity
    }));

  const findQuantityForProductCode = productCode => {
    const bundle = bundlesFromCart?.find(bundle =>
      bundle?.products?.some(product => product?.product_code === productCode)
    );
    return bundle ? bundle.quantity : null;
  };

  const quantityOfBundle = findQuantityForProductCode(product?.productCode);

  if (quantityOfBundle) {
    available = available - matchedBundle?.quantityPerBundle * quantityOfBundle;
  }

  // This is for when adding bundles to cart
  // Have to check how many bundles and toens has been added then count the availability
  // Find the available quantity for the product

  // Find total added products from the bundle
  // e.g find total added job slot plus for exlusive plus
  const addedMatchedBundleProduct = transformedArray?.find(inCart => {
    return parseInt(inCart?.productId) === parseInt(matchedBundle?.id);
  })?.quantity;

  const addedBundleProduct = addedMatchedBundleProduct
    ? addedMatchedBundleProduct
    : 0;

  if (product?.packageType === 'bundle') {
    available = Math.floor(
      matchedBundle?.quantityAvailable -
        inCartQuantity * matchedBundle?.quantityPerBundle -
        addedBundleProduct
    );

    // total available divide by quantity of products in bundle
    // if matchedBundle?.quantityPerBundle > available, means not enough
    available =
      available < matchedBundle?.quantityPerBundle
        ? 0
        : Math.floor(available / matchedBundle?.quantityPerBundle);

    maxCount = Math.floor(
      matchedBundle?.quantityAvailable / matchedBundle?.quantityPerBundle ===
        inCartQuantity
    );
  }

  // DISABLE WHEN
  // 1. quantity is at 1
  // 2. Available quantity from product API is at 0
  // 3. max token has been added to cart
  // 4. Available token from API is 0
  const disableMinus =
    quantity === 1 ||
    product?.quantityAvailable === 0 ||
    maxCount ||
    available === 0;

  // DISABLE WHEN
  // 1. Available quantity from product API is at 0
  // 2. max token has been added to cart
  // 3. e.g added 2 token, there's only 3 more left (assumming max is 5 token left)
  // means if added quantity is equal to available
  // 4. Available token from API is 0
  const disablePlus =
    product?.quantityAvailable === 0 ||
    maxCount ||
    available === quantity ||
    available === 0;

  // DISABLE WHEN
  // 1. sold out token
  // 2. Available quantity from product API is at 0
  // 3. Max token has been added to cart
  // 4. Fetching cart details
  const disableButton =
    (product?.quantityAvailable <= 0 && product?.quantityAvailable !== null) ||
    maxCount ||
    cartLoading ||
    available === 0;

  const renderButtonText = () => {
    if (
      product?.quantityAvailable <= 0 &&
      product?.quantityAvailable !== null
    ) {
      return 'Sold out';
    }

    if (inCartQuantity > 0 && available <= 0) {
      return 'Added to cart';
    }

    if (!purchasable) {
      return 'Contact Us';
    }

    return 'Add to cart';
  };

  const taxLabel = () => {
    if (countryCodeOptions[getCountry()] == 'MY') {
      return '';
    } else if (countryCodeOptions[getCountry()] == 'SG') {
      return 'incl. GST';
    }

    return '';
  };

  const getPastPrice = () => {
    const country = getCountry();
    const originalPrice = parseInt(product?.originalPrice);

    let price = '';
    if (country === 'MY') {
      price = `RM ${originalPrice}`;
    } else if (country === 'SG') {
      price = `$${originalPrice}`;
    }

    return price;
  };

  const getCurrentPrice = () => {
    const country = getCountry();
    let price = '';

    if (
      product?.productBundleInfo?.length === 1 &&
      product?.productBundleInfo[0]?.token_amount === 1
    ) {
      if (country === 'MY') {
        price = `RM ${parseInt(product?.discounted_amount)}`;
      } else if (country === 'SG') {
        price = `$${parseInt(product?.discounted_amount)}`;
      }
    } else {
      if (country === 'MY') {
        price = `RM ${parseInt(product?.price)}`;
      } else if (country === 'SG') {
        price = `$${parseInt(product?.price)}`;
      }
    }

    return price;
  };

  return (
    <ProductCardContainer
      confirm={confirm}
      singleCard={singleCard}
      jobAds={jobAdsTab}
      zeroCount={zeroCount}
      purpleBorder={proJob === product?.productCode}>
      {!confirm &&
        renderTopTag({ type: product?.productCode, zeroCount: zeroCount })}
      {!singleCard && product?.productPageInfo?.header && (
        <IconButtonStyled
          confirm={confirm}
          jobAds={jobAdsTab}
          onClick={() => onMoreInfoClicked(product, jobAdsTab)}>
          <InformationCircleIcon
            width={'20px'}
            height={'20px'}
            style={{ color: `rgba(0,0,0,0.87)` }}
          />
        </IconButtonStyled>
      )}

      {zeroCount ? (
        <img
          src={SoldOut}
          height={155}
          width={155}
          style={{ position: 'absolute', top: '-7px', left: '-7px' }}
        />
      ) : (
        renderAmountTag(product?.quantityAvailable, confirm)
      )}

      <ProductInnerContainer zeroCount={zeroCount}>
        <DefaultContainer
          container
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            padding: '16px'
          }}>
          <DefaultContainer sx={{ paddingTop: '8px' }}>
            <img
              src={product?.productImage}
              alt={product?.title}
              width={'80px'}
              height={'80px'}
              style={{
                filter: zeroCount && `grayscale(100%)`,
                opacity: zeroCount && '0.4'
              }}
            />
          </DefaultContainer>

          <ProductTitle zeroCount={zeroCount}>{product?.title}</ProductTitle>

          {/* exclusive_plus use discounted_amount (normally it has 1 token_amount and 1 item)*/}
          {/* single product or bundles or packages (basic / premium / advanced) use price field (because price field accounts for single and multiple token) */}
          {/* TODO: refactor */}
          <ProductPrice zeroCount={zeroCount}>
            {getCurrency(
              product?.productBundleInfo?.length === 1 &&
                product?.productBundleInfo[0]?.token_amount === 1
                ? product?.productBundleInfo[0]?.discounted_amount
                : product?.price
            )}{' '}
            {showPerYear() && <YearTypography>/ year </YearTypography>}
            <YearTypography>{taxLabel()}</YearTypography>
          </ProductPrice>

          {/* yellow chip */}
          {product?.productPageInfo?.promoChip == 'true' && !confirm && (
            <PromoContainer sx={{ margin: '4px 0' }}>
              <BoltIconStyled />
              <PromoText>
                was{' '}
                <span style={{ textDecoration: 'line-through' }}>
                  {getPastPrice()}
                </span>{' '}
                NOW <span>{getCurrentPrice()}</span>
              </PromoText>
            </PromoContainer>
          )}

          {talentToken === product?.productCode
            ? // For talent token
              productBundleInfo?.length > 1 && (
                <DefaultContainer
                  container
                  sx={{
                    flexDirection: 'row',
                    gap: '8px',
                    margin: '8px 0px 16px 0px',
                    justifyContent: 'center',
                    flexWrap: 'wrap'
                  }}>
                  {productBundleInfo
                    .sort((a, b) => a?.token_amount - b?.token_amount)
                    .map(bundle => {
                      const freeTokens =
                        bundle?.token_amount === 2 ? 1000 : 5000;

                      return (
                        <PurpleChip key={bundle.id}>
                          {`Buy ${bundle?.token_amount} Sets Free ${freeTokens}`}
                        </PurpleChip>
                      );
                    })}
                </DefaultContainer>
              )
            : // For other cases
              productBundleInfo?.length > 1 && (
                <DefaultContainer
                  container
                  sx={{
                    flexDirection: 'row',
                    gap: '8px',
                    margin: '8px 0px 8px 0px',
                    justifyContent: 'center'
                  }}>
                  {productBundleInfo
                    .sort((a, b) => a?.token_amount - b?.token_amount)
                    .map(bundle => (
                      <PurpleChip key={bundle.id} zeroCount={zeroCount}>
                        {/* Add a unique key */}
                        {`${
                          bundle?.token_amount
                        } for ${getCurrenyCode()} ${formatCurrency(
                          parseInt(bundle?.discounted_amount)
                        )}`}
                      </PurpleChip>
                    ))}
                </DefaultContainer>
              )}
          {/* bundle info (if available) */}

          {!confirm && (
            <DefaultContainer
              marginTop={productBundleInfo?.length < 2 ? '8px' : '0px'}>
              <ProductDesc zeroCount={zeroCount}>
                {listWithCustomIcons(productDescription, zeroCount)}
              </ProductDesc>
            </DefaultContainer>
          )}
        </DefaultContainer>

        {!zeroCount && (
          <DefaultContainer
            container
            sx={{
              padding: '16px',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: singleCard ? '18px' : '8px',
              flexWrap: 'nowrap'
              //marginTop: !confirm && '16px'
            }}>
            {/* Quantity control */}
            {purchasable && (
              <QuantityContainer>
                <QuantityButton
                  fullWidth
                  disabled={disableMinus}
                  onClick={() => decrementHandler()}>
                  <MinusSmallIcon
                    color={disableMinus ? 'rgba(0, 0, 0, 0.38)' : 'black'}
                    width={22}
                    height={22}
                  />
                </QuantityButton>
                <CustomTooltip
                  open={showTooltip}
                  title={disablePlus && 'Maximum quantity reached'}
                  placement="top">
                  <QuantityText
                    disabled={disableButton}
                    onBlur={e => {
                      let value =
                        e.target.value == 0 ? 1 : parseInt(e.target.value, 10);
                      setQuantity(value);
                    }}
                    onChange={e => {
                      let value = e.target.value
                        ? parseInt(e.target.value, 10)
                        : 0;

                      if (available < parseInt(e.target.value, 10)) {
                        setShowTooltip(true);
                        setQuantity(available);

                        setTimeout(() => {
                          setShowTooltip(false);
                        }, 2000);
                        return;
                      }

                      if (value >= 99) {
                        value = 99;
                      }

                      setQuantity(value);
                    }}
                    value={disableButton ? 0 : quantity}
                  />
                </CustomTooltip>
                <CustomTooltip
                  title={disablePlus && 'Maximum quantity reached'}
                  placement="top">
                  <span>
                    <QuantityButton
                      fullWidth
                      disabled={disablePlus}
                      onClick={() => incrementHandler()}>
                      <PlusSmallIcon
                        color={disablePlus ? 'rgba(0, 0, 0, 0.38)' : 'black'}
                        width={22}
                        height={22}
                      />
                    </QuantityButton>
                  </span>
                </CustomTooltip>
              </QuantityContainer>
            )}

            {/* add to button */}
            <ProductAddToCartButton
              disabled={disableButton}
              variant="contained"
              onClick={() => {
                setQuantity(1);
                buttonAction({
                  productId: product?.id,
                  purchasable: purchasable,
                  productCode: product?.productCode
                });

                window.dataLayer.push({
                  event: 'CE_add_to_cart_product_overview',
                  tab: selectedTab,
                  item: product?.productCode,
                  company_name: accData?.companyName,
                  company_industry: accData?.industryName
                });
              }}>
              {renderButtonText()}
            </ProductAddToCartButton>
          </DefaultContainer>
        )}

        {zeroCount && (
          <DefaultContainer>
            <DefaultContainer
              sx={{
                position: 'relative',
                width: '100%',
                backgroundColor: 'white',
                borderRadius: '8px 8px 7px 7px',
                padding: '12px 16px',
                display: 'flex',
                flexDirection: 'row',
                borderTop: `0.5px solid #D8D8D8`,
                boxShadow: `0px -1px 8px 0px rgba(0, 0, 0, 0.10)`
              }}>
              <DefaultContainer sx={{ minWidth: '160px' }}>
                <Typography
                  sx={{
                    color: `rgba(0, 0, 0, 0.87)`,
                    fontSize: '12px',
                    lineHeight: '20px'
                  }}>
                  Interested in this product?
                </Typography>
                <Typography
                  sx={{
                    color: `rgba(0, 0, 0, 0.60)`,
                    fontSize: '10px',
                    lineHeight: '15px'
                  }}>
                  Contact us and our sales team will reach out to you soon!
                </Typography>
              </DefaultContainer>
              <DefaultContainer
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%'
                }}>
                <ContactButton
                  onClick={() => {
                    dispatch(updateSubjectType(product?.productCode));
                    dispatch(updateContactSalesPopupStatus(true));
                  }}>
                  Contact Us
                </ContactButton>
              </DefaultContainer>
            </DefaultContainer>
          </DefaultContainer>
        )}
      </ProductInnerContainer>
    </ProductCardContainer>
  );
}
