import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DefaultLayout from '../../../../layouts/DefaultLayout';
import JobTypePopUp from '../../PopUps/JobTypePopUp';
import { SuccessToast } from '../../../../utils/ToastUtils';
import styles from './index.module.scss';

const JobType = () => {
  const params = useParams();
  window.scrollTo({ top: 0, behavior: 'smooth' });

  useEffect(() => {
    try {
      const showSavedJobMessage = localStorage.getItem('showSavedJobMessage');
      localStorage.removeItem('showSavedJobMessage');

      if (showSavedJobMessage) {
        SuccessToast(showSavedJobMessage);
      }
    } catch (error) {
      //
    }
  }, []);

  return (
    <DefaultLayout>
      <JobTypePopUp useAsPopUp={false} jobId={params?.id} open />
    </DefaultLayout>
  );
};

export default JobType;
