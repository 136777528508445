import React from 'react';
import { TextFieldStyled } from './styles';

export const TextInputFunction = props => {
  const { label, type, value, handleChange, placeholder, minDate } = props;

  const preventDefault = e => {
    e.preventDefault();
  };

  const formatInput = type => e => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum =
        e.key === 'E' ||
        e.key === 'e' ||
        e.key === '.' ||
        e.key === '+' ||
        e.key === '-';
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  const validationCheck = (value, label) => {
    if (label == 'contactPerson') {
      if (value == '') {
        return 'Contact person is required';
      }
    } else if (label == 'date') {
      if (value == '') {
        return 'Interview date is required';
      }
    }
  };

  return (
    <>
      <TextFieldStyled
        error={value == '' ? true : false}
        helperText={validationCheck(value, label)}
        exclude_text_area="true"
        placeholder={placeholder}
        hiddenLabel
        required
        type={type}
        value={value}
        onChange={handleChange}
        {...(type === 'date' ? { onKeyDown: preventDefault } : null)}
        {...(type === 'number' ? { onKeyDown: formatInput(type) } : null)}
        id={`filled-required-${label}`}
        variant="filled"
        InputProps={{
          disableUnderline: true,
          style: {
            borderRadius: '20px',
            width: '100%',
            background: 'transparent',
            border: value == '' ? '0.5px solid #f44336' : '0.5px solid #aeaeae',
            padding: '10px 20px'
          }
        }}
        inputProps={{
          min: type == 'date' && minDate,
          style: {
            fontSize: `14px`,
            letterSpacing: '0.03rem',
            width: '100%',
            padding: '0',
            borderRadius: '0'
          }
        }}
      />
    </>
  );
};
