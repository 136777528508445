import { styled as styling } from '@mui/material/styles';
import { Card } from '@mui/material';

export const MainContainer = styling('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const FirstContainer = styling('div')({

});

export const TextContainer = styling('div')(({ 
  origin, 
  subtitle 
}) => ({
  ...(origin == 'title' && {
    margin: '2px 0',
  }),
  ...(subtitle && {
    margin: '4px 0 0',
  }),
}));

export const Text = styling('p')(({ origin, page }) => ({
  ...(origin == 'title' && {
    margin: 0,
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: 700,
    color: '#512acc',
  }),
  ...(origin == 'subtitle' && {
    margin: 0,
    fontSize: '14px',
    fontFamily: 'Inter',
  }),
  ...(page == 'candidate_snapshot' && origin == 'subtitle' && {
    margin: 0,
    fontSize: '12px',
    fontFamily: 'Inter',
  }),
  ...(origin == 'button_text' && {
    margin: 0,
    color: '#512acc',
    fontSize: '14px',
    fontWeight: 700,
  }),
}));

export const SecondContainer = styling('div')({
  height: '30px',
  width: '70px',
  borderRadius: '22px',
  cursor: 'pointer',
  margin: '0 10px',
});

export const ButtonContainer = styling('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: '9px 18px',
  border: '1px solid #512acc',
  borderRadius: '30px',
});

export const CardStyled = styling('div')({
  borderRadius: '16px',
  width: '100%',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)',
  padding: '15px 24px',
  background: '#FFF',
});
