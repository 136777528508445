import React, { useState } from 'react';
import { CountryDropdownStyled } from './styles';
import { getCountry } from '../../hooks/useRegionalisation';

const FormSelectCountry = ({
  name,
  label,
  origin,
  onChange,
  value,
  warning
}) => {
  return (
    <>
      <CountryDropdownStyled
        label={label}
        onChange={onChange}
        priorityOptions={[getCountry()]}
        error={`${warning}`}
        value={value}
        origin={origin}
        defaultOptionLabel="Select Country"
      />
      {warning && (
        <p
          style={{
            color: '#f44336',
            margin: '3px 14px 0',
            fontSize: '0.75rem',
            fontWeight: '400',
            fontFamily: 'sans-serif',
            borderRadius: '30px'
          }}>
          Country is required
        </p>
      )}
    </>
  );
};

export default FormSelectCountry;
