import * as types from '../types/company_type';
import api from '../../utils/api';
import { resolve } from 'path';
import { isUndefined } from 'lodash';

const { REACT_APP_API_VERSION } = process.env;

const sendVerificationEmail =
  (params = {}) =>
  async (dispatch, getState) => {
    const payload = {
      query: `
      {
        triggerEmailVerificationEmail
      }
    `
    };

    try {
      const response = await api.apiCall(
        `${REACT_APP_API_VERSION}/graphql`,
        params,
        payload
      );

      return response;
    } catch (error) {
      throw error; // Rethrow the error to propagate it further
    }
  };

const selectModalVideo = video => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.SELECT_MODAL_VIDEO,
        video: video
      })
    );
  });
};

const updateSocialMediaGuideline = socialMediaGuideline => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.COMPANY_SOCIAL_MEDIA_GUIDELINE,
        socialMediaGuideline: socialMediaGuideline
      })
    );
  });
};

const updateUploadedVimeoVideo = uploadedVimeo => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.COMPANY_VIMEO_VIDEO,
        uploadedVimeo: uploadedVimeo
      })
    );
  });
};

const updateCompanyVideoStatus = vimeoid => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.COMPANY_VIDEO_STATUS,
        storedVimeoId: vimeoid
      })
    );
  });
};

const updateCompanySidebar = companyCompletion => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.COMPANY_SIDEBAR_COMPLETION,
        companyCompletion: companyCompletion
      })
    );
  });
};

const expandVideoAccordion = id => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.EXPEND_VIDEO_ACCORDION,
        videoId: id
      })
    );
  });
};

const updateTermsPopupStatus = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_TERMS_POPUP,
        status: status
      })
    );
  });
};

const clearAccountInfo = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.CLEAR_ACCOUNT_INFO
      })
    );
  });
};

const openDeletePhotoPopup = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.OPEN_DELETE_PHOTO_POPUP,
        status: status
      })
    );
  });
};

const openOfficeImageGuidePopup = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.OPEN_OFFICE_IMAGE_GUIDE_POPUP,
        status: status
      })
    );
  });
};

const getPresignedUrl = (params = {}) => {
  const mutationInput = `filename: "${params.fileName}", recordType: "${params.recordType}"`;
  const payload = {
    query: `mutation {
      prepareFileUpload(input: {${mutationInput}}) {
        presignedUrl
        publicUrl
      }
     }
    `
  };

  return api
    .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
    .then(async response => {
      if (response.status === 200) {
        return response?.data;
      }
    })
    .catch(error => {
      return { success: false };
    });
};

const deleteCompanyOfficeImage =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.DELETING_COMPANY_OFFICE_IMAGE
    });

    const mutationInput = `id: ${JSON.stringify(params.officeImageIds)}`;

    const payload = {
      query: `mutation {
        deleteCompanyImage(input: {${mutationInput}}) {
          success        
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.deleteCompanyImage) {
            const { success } = response.data.data.deleteCompanyImage;

            if (success) {
              return dispatch({
                type: types.DELETE_COMPANY_IMAGE_SUCCEEDED,
                officeImageIds: params.officeImageIds
              });
            }
          }
        }
        return dispatch({
          type: types.DELETE_COMPANY_IMAGE_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.DELETE_COMPANY_IMAGE_FAILED
        });
      });
  };

const updateImageModalStatus = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_IMAGE_MODAL_STATUS,
        status: status
      })
    );
  });
};

const fetchMyCompanySettings =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_MY_COMPANY_SETTINGS
    });

    const payload = {
      query: `{
        myCompany {
          id
          bookmarkedResumesCount
          unlockedResumesCount
          userName
          phoneNumber
          publicLogoUrl
          userEmail
          name
          registerName
          industryId
          industryName
          sizeId
          sizeName
          typeId
          slug
          typeName
          website
          emailToReceiveApplications
          onBoardComplete
          billingAddress {
            streetAddress
            town
            postcode
            state
            country
          }
          companyTeamMembers {
            id
            name
            email
            position
            contactNumber
            createdAt
            updatedAt
          }
          jobs(first: 3) {
            totalCount
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                title
                boostedExpired
                location
                stateRegion {
                  id
                  title
                }
                trackTitle
                jobTypeTitle
                active
                expiredAt
                unviewedCount
                totalCount
                shortlistedCount
                rejectedCount
                kivCount
              }
              cursor
            }
          }
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.myCompany) {
            const companySettings = response?.data?.data?.myCompany;
            return dispatch({
              type: types.FETCH_MY_COMPANY_SETTINGS_SUCCEED,
              companySettings: companySettings
            });
          }
        }
        return dispatch({
          type: types.FETCH_MY_COMPANY_SETTINGS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_MY_COMPANY_SETTINGS_FAILED
        });
      });
  };

const fetchAccountInfo =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_ACCOUNT_INFO
    });

    const payload = {
      query: `{
        myAccountInfo {
          id
          name
          email
          contactNumber
          companyId
          companyName
          industryId
          industryName
          currentAccountType
          isEmailVerified
          resetPasswordAt
          feedbackRequired
          permissions {
            editCompanyProfile
            postJob
            cva
            buyToken
            editAccountDetail
            allApplicants
          }
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.myAccountInfo) {
            const accountInfo = response?.data?.data?.myAccountInfo;

            const accountInfoLocal = {
              ...(accountInfo?.id && {
                accountId: accountInfo?.id
              }),
              ...(accountInfo?.companyId && {
                companyId: accountInfo?.companyId
              }),
              ...(accountInfo?.companyName && {
                companyName: accountInfo?.companyName
              }),
              ...(accountInfo?.currentAccountType && {
                accountType: accountInfo?.currentAccountType
              }),

              ...(accountInfo?.industryName && {
                industryName: accountInfo?.industryName
              }),

              ...(accountInfo?.permissions && {
                permissions: accountInfo?.permissions
              }),

              ...(accountInfo?.feedbackRequired && {
                feedbackRequired: accountInfo?.feedbackRequired
              })
            };

            localStorage.setItem(
              'accountInfo',
              JSON.stringify(accountInfoLocal)
            );

            return dispatch({
              type: types.FETCH_ACCOUNT_INFO_SUCCEED,
              accountInfo: accountInfo
            });
          }
        }
        return dispatch({
          type: types.FETCH_ACCOUNT_INFO_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_ACCOUNT_INFO_FAILED
        });
      });
  };

const fetchMyCompanyStatus =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_MY_COMPANY_STATUS
    });

    const payload = {
      query: `{
      myCompanyStatus {
      isApproved
      unlimitedToken
      jobSlotsTotal
      jobSlotsUsed
      featuredJobTokensCount
      refresherTokensCount
      discountVouchersCount
      activeCvAccessLimit
      activeCvAccessUsed
      companyProfileExists
      allowToPostSuperSeniorJob
      allowToPostFreeInternshipJob
      allowToPostInternshipJob
      hasCompletedOrdersOrPostedJobs
      ctmAccess
      jobToken
      affiliatedCompanyAccess
      jobSlotsTotalUsableQuantity
      unlimitedTokenExpiredAt
      activeJobsCount
      unviewedJobApplicationsCount
      hiredApplicantsCount
      allowExternalJobUrl
      allowToPostFreeUnlimitedInternshipJobs
      availableFreeSuperSeniorPostCount
      availableFreeInternsipTokenCount
      availableInternshipsTokenCount
      availableJobCreditCount
      availableCoins
      talentSearchAccess
      acvAccess
      talentSearchOrAcvAccess
      allApplicantsAccess
      jobSlotPlusTotalUsableQuantity
      jobSlotMaxTotalUsableQuantity
      jobSlot6mTotalUsableQuantity
      jobSlotPlus6mTotalUsableQuantity
      jobSlotMax6mTotalUsableQuantity
      freeSuperSeniorNearestExpiryDate
      freeInternshipTokenNearestExpiryDate
      paidInternshipTokenNearestExpiryDate
      jobSlotNearestExpiryDate
      jobSlotPlusNearestExpiryDate
      jobSlotMaxNearestExpiryDate
      jobSlot6mNearestExpiryDate
      jobSlotPlus6mNearestExpiryDate
      jobSlotMax6mNearestExpiryDate
      jobCreditNearestExpiryDate
      jobTokenExpiredAt
      unlimitedTokenExpiredAt
      freeSuperSeniorPreviewJobExpiryDate
      internshipPreviewJobExpiryDate
      jobSlotPreviewJobExpiryDate
      jobSlotPlusPreviewJobExpiryDate
      jobSlotMaxPreviewJobExpiryDate  
      jobSlot6mPreviewJobExpiryDate
      jobSlotPlus6mPreviewJobExpiryDate
      jobSlotMax6mPreviewJobExpiryDate
      jobCreditPreviewJobExpiryDate
      jobTokenPreviewJobExpiryDate
      unlimitedTokenPreviewJobExpiryDate
      availableCrossBorderTokenCount
    } 
  }
  `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.myCompanyStatus) {
            const company = response?.data?.data?.myCompanyStatus;
            return dispatch({
              type: types.FETCH_MY_COMPANY_STATUS_SUCCEED,
              company: company
            });
          }
        }
        return dispatch({
          type: types.FETCH_MY_COMPANY_STATUS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_MY_COMPANY_STATUS_FAILED
        });
      });
  };

const fetchIndustries =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_INDUSTRIES
    });

    const payload = {
      query: `{
        industries {
          id
          title
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.industries) {
            const industries = response?.data?.data?.industries;
            return dispatch({
              type: types.FETCH_INDUSTRIES_SUCCEED,
              industries: industries
            });
          }
        }
        return dispatch({
          type: types.FETCH_INDUSTRIES_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_INDUSTRIES_FAILED
        });
      });
  };

const updateCompanyAvailableCoins = coins => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_COMPANY_AVAILABLE_COINS,
        coins: coins
      })
    );
  });
};

const saveCompanyTeamMemberTrack =
  (params = {}) =>
  async dispatch => {
    // Initialize an empty array to store the interpolated strings
    const interpolatedStrings = [];

    // Iterate through the array and build the strings
    for (const obj of params.selectedSpecs) {
      let objectString = '';
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          objectString += `id: "${obj[key]}", `;
        }
      }
      // Remove the trailing comma and space
      objectString = objectString.slice(0, -2);
      interpolatedStrings.push(`{ ${objectString} }`);
    }

    // Join the interpolated strings with a separator (e.g., newline)
    const stringInterpolate = interpolatedStrings.join(',');

    const payload = {
      query: `
      mutation {
        saveCompanyTeamMember(input: {id: "${params.id}", tracks: [${stringInterpolate}]}) {
          companyTeamMember {
            id
          }
        }
      }
      `
    };

    const result = await api.apiCall(
      `${REACT_APP_API_VERSION}/graphql`,
      params,
      payload
    );

    if (result.status === 200) {
      return dispatch({
        type: 'SAVE_TEAM_MEMBER_SUCCESS'
      });
    }

    return dispatch({
      type: 'SAVE_TEAM_MEMBER_FAILED'
    });
  };

const updateTeamMemberSpecs =
  (params = {}) =>
  async dispatch => {
    return new Promise(resolve => {
      return resolve(
        dispatch({
          type: types.UPDATE_TEAM_MEMBER_SPECS_SUCCESS,
          specialisations: params.specialisations
        })
      );
    });
  };

const fetchCompanySizes =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_COMPANY_SIZES
    });

    const payload = {
      query: `{
        companySizes {
          id
          title
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.companySizes) {
            const companySizes = response?.data?.data?.companySizes;
            return dispatch({
              type: types.FETCH_COMPANY_SIZES_SUCCEED,
              companySizes: companySizes
            });
          }
        }
        return dispatch({
          type: types.FETCH_COMPANY_SIZES_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_COMPANY_SIZES_FAILED
        });
      });
  };

const fetchCompanyProfile =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_COMPANY_PROFILE
    });

    const payload = {
      query: `{
        companyProfile {
          address
          slug
          longitude
          latitude
          address
          zoom
          imagePosition
          imageSize
          descriptions {
            id
            title
            body
          }
          benefits {
            id
            iconId
            title
            description
            iconCode
            sortOrder
          }
          coverImage {
            id
            publicUrl
            imageUrlSquare
          }
          images {
            id
            cover
            sortOrder
            publicUrl
          }
          videos {
            id
            sortOrder
            content {
              id
              activeBanner
              approvalStatus
              vimeoId
              quote
              personName
              personPosition
              reviewedAt
              createdAt
              updatedAt
            }
          }
          socialMediaLinks {
            id
            sortOrder
            content {
              id
              approvalStatus
              socialMediaLink
              reviewedAt
              createdAt
              updatedAt
            }
          }
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.companyProfile) {
            const companyProfile = response?.data?.data?.companyProfile;
            return dispatch({
              type: types.FETCH_COMPANY_PROFILE_SUCCEED,
              companyProfile: companyProfile
            });
          }
        }
        return dispatch({
          type: types.FETCH_COMPANY_PROFILE_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_COMPANY_PROFILE_FAILED
        });
      });
  };

const fetchIcons =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_ICONS
    });

    const payload = {
      query: `{
        icons {
          id
          iconCode
          name
          description
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.icons) {
            const icons = response?.data?.data?.icons;
            return dispatch({
              type: types.FETCH_ICONS_SUCCEED,
              icons: icons
            });
          }
        }
        return dispatch({
          type: types.FETCH_ICONS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_ICONS_FAILED
        });
      });
  };

const fetchCompanyTeamMembers =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_COMPANY_TEAM_MEMBERS
    });

    const payload = {
      query: `{
    companyTeamMembers(name: "") {
      id
      name
      email
      position
      tracks {
        id
        title
      }
      specialisations {
        id
        title
        subCategories {
          id
          title
          parentCategory {
            id
            title
          }
        }
      }
      accessLevels {
        id
        title
      }
    }
  }
  `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.companyTeamMembers) {
            const companyTeamMembers = response?.data?.data?.companyTeamMembers;
            return dispatch({
              type: types.FETCH_COMPANY_TEAM_MEMBERS_SUCCEED,
              companyTeamMembers: companyTeamMembers
            });
          }
        }
        return dispatch({
          type: types.FETCH_COMPANY_TEAM_MEMBERS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_COMPANY_TEAM_MEMBERS_FAILED
        });
      });
  };

const fetchExperiences =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_EXPERIENCES
    });

    const payload = {
      query: `{
        experiences {
          id
          title
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.experiences) {
            const experiences = response?.data?.data?.experiences;
            return dispatch({
              type: types.FETCH_EXPERIENCES_SUCCEED,
              experiences: experiences
            });
          }
        }
        return dispatch({
          type: types.FETCH_EXPERIENCES_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_EXPERIENCES_FAILED
        });
      });
  };

const fetchJobTypes =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_JOB_TYPES
    });

    const payload = {
      query: `{
        jobTypes {
          id
          title
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.jobTypes) {
            const jobTypes = response?.data?.data?.jobTypes;
            return dispatch({
              type: types.FETCH_JOB_TYPES_SUCCEED,
              jobTypes: jobTypes
            });
          }
        }
        return dispatch({
          type: types.FETCH_JOB_TYPES_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_JOB_TYPES_FAILED
        });
      });
  };

const fetchStateRegions =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_LOCATIONS
    });

    const payload = {
      query: `{
        countryStateRegions
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.countryStateRegions) {
            const countryStateRegions =
              response?.data?.data?.countryStateRegions;

            return dispatch({
              type: types.FETCH_LOCATIONS_SUCCEED,
              countryStateRegions: countryStateRegions
            });
          }
        }
        return dispatch({
          type: types.FETCH_LOCATIONS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_LOCATIONS_FAILED
        });
      });
  };

const fetchTracks =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_TRACKS
    });

    const payload = {
      query: `{
        tracks {
          id
          title
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.tracks) {
            const tracks = response?.data?.data?.tracks;
            return dispatch({
              type: types.FETCH_TRACKS_SUCCEED,
              tracks: tracks
            });
          }
        }
        return dispatch({
          type: types.FETCH_TRACKS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_TRACKS_FAILED
        });
      });
  };

const fetchCareerLevels =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_CAREER_LEVEL
    });

    const payload = {
      query: `{
        careerLevels {
          id
          name
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.careerLevels) {
            const careerLevels = response?.data?.data?.careerLevels;
            return dispatch({
              type: types.FETCH_CAREER_LEVEL_SUCCEED,
              careerLevels: careerLevels
            });
          }
        }
        return dispatch({
          type: types.FETCH_CAREER_LEVEL_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_CAREER_LEVEL_FAILED
        });
      });
  };

const fetchAccessLevels =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_ACCESS_LEVEL
    });

    const payload = {
      query: `{
        accessLevels {
          id
          title
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.accessLevels) {
            const accessLevels = response?.data?.data?.accessLevels;
            return dispatch({
              type: types.FETCH_ACCESS_LEVEL_SUCCEED,
              accessLevels: accessLevels
            });
          }
        }
        return dispatch({
          type: types.FETCH_ACCESS_LEVEL_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_ACCESS_LEVEL_FAILED
        });
      });
  };

const fetchCompanyName =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_COMPANY_NAME
    });

    const payload = {
      query: `{
        myCompany {
          id
          name
          companyTeamMembersCount
          noOfCtm
          affiliatedCompany {
            id
            affiliationType
            approvalStatus
            company {
              id
              name
            }
          }
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.myCompany) {
            const myCompany = response?.data?.data?.myCompany;
            return dispatch({
              type: types.FETCH_COMPANY_NAME_SUCCEED,
              myCompany: myCompany
            });
          }
        }
        return dispatch({
          type: types.FETCH_COMPANY_NAME_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_COMPANY_NAME_FAILED
        });
      });
  };

const fetchCompanyAffiliations =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_COMPANY_AFFILIATIONS
    });

    let input;
    if (params.name) {
      input = params.name;
    } else {
      input = '';
    }

    const payload = {
      query: `{
        companyAffiliations(name: "${input}") {
          id
          approvalStatus
          affiliationType
          affiliatedCompany {
            id
            name
            publicLogoUrl
          }
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.companyAffiliations) {
            const companyAffiliations =
              response?.data?.data?.companyAffiliations;
            return dispatch({
              type: types.FETCH_COMPANY_AFFILIATIONS_SUCCEED,
              companyAffiliations: companyAffiliations
            });
          }
        }
        return dispatch({
          type: types.FETCH_COMPANY_AFFILIATIONS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_COMPANY_AFFILIATIONS_FAILED
        });
      });
  };

const fetchCompanyTypes =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_COMPANY_TYPES
    });

    const payload = {
      query: `{
        companyTypes {
          id
          title
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.companyTypes) {
            const companyTypes = response?.data?.data?.companyTypes;
            return dispatch({
              type: types.FETCH_COMPANY_TYPES_SUCCEED,
              companyTypes: companyTypes
            });
          }
        }
        return dispatch({
          type: types.FETCH_COMPANY_TYPES_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_COMPANY_TYPES_FAILED
        });
      });
  };

const mutateCompanyDetails =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_MY_COMPANY_SETTINGS
    });

    const data = params.input;

    const input = `
  ${
    !isUndefined(data.agreeToTerms) ? `agreeToTerms: ${data.agreeToTerms},` : ''
  } 
  ${data.publicLogoUrl ? `publicLogoUrl: "${data.publicLogoUrl}",` : ''} 
  ${data.name ? `name: "${data.name}",` : ''}
  ${data.registerName ? `registerName: "${data.registerName}",` : ''}
  ${data.industryId ? `industryId: ${data.industryId},` : ''}
  ${data.sizeId ? `sizeId: ${data.sizeId},` : ''}
  ${data.typeId ? `typeId: ${data.typeId},` : ''}
  ${data.website ? `website: "${data.website}",` : ''}
  ${
    data.emailToReceiveApplications
      ? `emailToReceiveApplications: "${data.emailToReceiveApplications}",`
      : ''
  }
  ${
    data.billingAddress
      ? `billingAddress: {
    ${
      data.billingAddress.streetAddress
        ? `streetAddress: "${data.billingAddress.streetAddress}",`
        : ''
    }
    ${data.billingAddress.town ? `town: "${data.billingAddress.town}",` : ''}
    ${
      data.billingAddress.postcode
        ? `postcode: "${data.billingAddress.postcode}",`
        : ''
    }
    ${data.billingAddress.state ? `state: "${data.billingAddress.state}",` : ''}
    ${
      data.billingAddress.country
        ? `country: "${data.billingAddress.country}",`
        : ''
    }
  }`
      : ''
  } 
`;

    const payload = {
      query: `mutation {
        updateMyCompany(input: {${input}}) {
          myCompany {
            publicLogoUrl
            userName
            phoneNumber
            userEmail
            emailToReceiveApplications
            name
            registerName
            industryId
            sizeId
            typeId
            website
            billingAddress {
              id
              streetAddress
              town
              postcode
              state
              country
            }
          }
          token
        }
       }
      `
    };
    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.updateMyCompany?.myCompany) {
            const companyProfile =
              response?.data?.data?.updateMyCompany?.myCompany;
            return dispatch({
              type: types.FETCH_MY_COMPANY_SETTINGS_SUCCEED,
              companySettings: companyProfile,
              token: response?.data?.data?.updateMyCompany?.token
            });
          }
        }
        return dispatch({
          type: types.FETCH_MY_COMPANY_SETTINGS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_MY_COMPANY_SETTINGS_FAILED,
          error: error
        });
      });
  };

const readyFileUpload =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.PREPARE_FILE_UPLOAD
    });

    const data = params.input;
    const input = `
      filename: "${data.filename}",
      recordType: "${data.recordType}"
    `;

    const payload = {
      query: `mutation {
        prepareFileUpload(input: {${input}}) {
          presignedUrl
          publicUrl
        }
       }
      `
    };
    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.prepareFileUpload) {
            const prepareFileUpload = response?.data?.data?.prepareFileUpload;
            return dispatch({
              type: types.PREPARE_FILE_UPLOAD_SUCCEED,
              prepareFileUpload: prepareFileUpload
            });
          }
        }
        return dispatch({
          type: types.PREPARE_FILE_UPLOAD_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.PREPARE_FILE_UPLOAD_FAILED,
          error: error
        });
      });
  };

const mutateCompanyProfile =
  (params = {}) =>
  async (dispatch, getState) => {
    // dispatch({
    //   type: types.MUTATE_COMPANY_PROFILE
    // });

    const data = params.input;
    const desc = data?.descriptions;
    const video = data?.videos;
    const benefits = data?.benefits;
    const images = data?.images;
    const cover = data?.coverImage;
    const socialMedia = data?.socialMedia;

    const addressPart = data.address ? `address: "${data.address}",` : '';
    const latitudePart = data.latitude ? `latitude: ${data.latitude},` : '';
    const longitudePart = data.longitude ? `longitude: ${data.longitude},` : '';
    const zoomPart = data.zoom ? `zoom: ${data.zoom},` : '';
    const imagePositionPart = data.imagePosition
      ? `imagePosition: ${data.imagePosition},`
      : '';
    const imageSizePart = data.imageSize ? `imageSize: ${data.imageSize},` : '';

    const videosPart = video
      ? `videos: [${video
          .map(
            vid => `
        {
          ${vid.id ? `id: ${vid.id},` : ''}
          ${vid.quote ? `quote: "${vid.quote}",` : ''}
          ${`personName: ${vid.personName ? `"${vid.personName}"` : '""'}`}
          ${`personPosition: ${
            vid.personPosition ? `"${vid.personPosition}"` : '""'
          }`}
          ${vid.sortOrder ? `sortOrder: ${vid.sortOrder},` : ''}
          ${vid.vimeoId ? `vimeoId: "${vid.vimeoId}",` : ''}
          ${`activeBanner: ${vid?.activeBanner}`}
          ${vid._destroy ? `_destroy: true,` : ''}
        }
      `
          )
          .join(',')}
      ]`
      : '';

    const descriptionsPart = desc
      ? `descriptions: [${desc
          .map(
            desc => `
        {
          ${desc.id ? `id: "${desc.id}",` : ''}
          ${desc.title ? `title: "${desc.title}",` : ''}
          ${desc.body ? `body: """${desc.body}"""` : ''}
        }
      `
          )
          .join(',')}
      ]`
      : '';

    const benefitsPart = benefits
      ? `benefits: [${benefits
          .map(
            benefit => `
        {
        ${benefit.id ? `id: ${benefit.id},` : ''}
        ${benefit.iconId ? `iconId: ${benefit.iconId},` : ''}
        ${benefit.title ? `title: "${benefit.title}",` : ''}
        ${benefit.description ? `description: "${benefit.description}",` : ''}
        ${benefit.sortOrder ? `sortOrder: ${benefit.sortOrder},` : ''}
        ${benefit._destroy ? `_destroy: true,` : ''}
        }
      `
          )
          .join(',')}
      ]`
      : '';

    const imagesPart = images
      ? `images: [${images
          .map(
            image => `
        {
          ${image.id ? `id: ${image.id},` : ''}
          ${image.sortOrder ? `sortOrder: ${image.sortOrder},` : ''}
          ${image.publicUrl ? `publicUrl: "${image.publicUrl}",` : ''}
          ${image._destroy ? `_destroy: true,` : ''}
          ${
            image.imageUrlSquare
              ? `imageUrlSquare: "${image.imageUrlSquare}",`
              : ''
          }
         cover: ${image.cover},
        }
      `
          )
          .join(',')}
      ]`
      : '';

    const coverImagePart = cover
      ? `coverImage: {
        ${cover.publicUrl ? `publicUrl: "${cover.publicUrl}",` : ''}
        ${cover.cover ? `cover: ${cover.cover},` : ''}
      },`
      : '';

    const socialMediaPart = socialMedia
      ? `socialMediaLinks: [${socialMedia
          .map(
            social => `
        {
          ${social.id ? `id: ${social.id},` : ''}
          ${social.sortOrder ? `sortOrder: ${social.sortOrder},` : ''}
          ${
            social.socialMediaLink
              ? `socialMediaLink: "${social.socialMediaLink}",`
              : ''
          }
          ${social._destroy ? `_destroy: true,` : ''}
        }
      `
          )
          .join(',')}
      ]`
      : '';

    const input = `
      ${addressPart}
      ${zoomPart}
      ${latitudePart}
      ${longitudePart}
      ${imagePositionPart}
      ${imageSizePart}
      ${videosPart}
      ${descriptionsPart}
      ${benefitsPart}
      ${imagesPart}
      ${coverImagePart}
      ${socialMediaPart}
    `;

    const payload = {
      query: `mutation {
        saveCompanyProfile(input: {${input}}) {
          companyProfile {
            address
            longitude
            latitude
            address
            zoom
            imagePosition
            imageSize
            descriptions {
              id
              title
              body
            }
            benefits {
              id
              iconId
              title
              description
              iconCode
              sortOrder
            }
            coverImage {
              publicUrl
              imageUrlSquare
            }
            images {
              id
              image
              thumbnail
              cover
              sortOrder
              publicUrl
            }
            videos {
              id
              sortOrder
              content {
                id
                activeBanner
                approvalStatus
                vimeoId
                quote
                personName
                personPosition
                reviewedAt
                createdAt
                updatedAt
              }
            }
            socialMediaLinks {
              id
              sortOrder
              content {
                id
                approvalStatus
                socialMediaLink
                reviewedAt
                createdAt
                updatedAt
              }
            }
          }
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.saveCompanyProfile) {
            const companyProfile =
              response?.data?.data?.saveCompanyProfile?.companyProfile;

            if (companyProfile != null) {
              return dispatch({
                type: types.FETCH_COMPANY_PROFILE_SUCCEED,
                companyProfile: companyProfile
              });
            } else {
              return dispatch({
                type: types.FETCH_COMPANY_PROFILE_FAILED,
                errorMessage: response.data?.errors[0].message ?? 'Error'
              });
            }
          }
        }
        let error = response.data?.errors[0].message?.includes('Parse error')
          ? `The backslash ${'\\'} character is not supported. Please use a different character.`
          : response.data?.errors[0].message;
        return dispatch({
          type: types.FETCH_COMPANY_PROFILE_FAILED,
          errorMessage: error ?? 'Error'
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_COMPANY_PROFILE_FAILED,
          error: error
        });
      });
  };

const mutateAccountInfo =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_COMPANY_DETAILS
    });

    const data = params.input;
    const input = `
      ${
        data.verificationCode
          ? `verificationCode: "${data.verificationCode}",`
          : ''
      }
      ${data.name ? `name: "${data.name}",` : ''}
      ${data.email ? `email: "${data.email}",` : ''} 
      ${data.contactNumber ? `contactNumber: "${data.contactNumber}",` : ''}
      ${data.password ? `password: "${data.password}",` : ''}
      ${
        data.passwordConfirmation
          ? `passwordConfirmation: "${data.passwordConfirmation}",`
          : ''
      }
    `;

    const payload = {
      query: `mutation {
        updateAccountInfo(input: {${input}}) {
          success
        }
       }
      `
    };
    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.updateAccountInfo) {
            const success = response?.data?.data?.updateAccountInfo?.success;
            return dispatch({
              type: types.MUTATE_COMPANY_DETAILS_SUCCEED,
              mutateCompanyDetailsSuccess: success
            });
          }
        }
        return dispatch({
          type: types.MUTATE_COMPANY_DETAILS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_COMPANY_DETAILS_FAILED,
          error: error
        });
      });
  };

const mutateCompanyTeamMember =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_COMPANY_TEAM_MEMBER
    });

    const data = params.input;

    const input = `
      ${data.id ? `id: "${data.id}",` : ''}
      ${data.name ? `name: "${data.name}",` : ''}
      ${data.email ? `email: "${data.email}",` : ''} 
      ${data.position ? `position: "${data.position}",` : ''}
      ${
        data.accessLevels
          ? `accessLevels: [${data.accessLevels
              .map(
                accessLevel => `
          {
            ${accessLevel.id ? `id: ${accessLevel.id},` : ''}
            ${accessLevel.title ? `title: "${accessLevel.title}",` : ''}
          }
        `
              )
              .join(',')}
        ]`
          : ''
      }
      ${
        data.tracks
          ? `tracks: [${data.tracks
              .map(
                track => `
          {
            ${track.id ? `id: ${track.id},` : ''}
            ${track.title ? `title: "${track.title}",` : ''}
          }
        `
              )
              .join(',')}
        ]`
          : ''
      }
    `;

    const payload = {
      query: `mutation {
        saveCompanyTeamMember(input: {${input}}) {
          companyTeamMember {
            id
          }
        }
       }
      `
    };
    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.saveCompanyTeamMember) {
            const companyTeamMember =
              response?.data?.data?.saveCompanyTeamMember?.companyTeamMember;
            return dispatch({
              type: types.MUTATE_COMPANY_TEAM_MEMBER_SUCCEED,
              companyTeamMember: companyTeamMember
            });
          }
        }
        return dispatch({
          type: types.MUTATE_COMPANY_TEAM_MEMBER_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_COMPANY_TEAM_MEMBER_FAILED,
          error: error
        });
      });
  };

const mutateRemoveTeamMember =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_REMOVE_TEAM_MEMBER
    });

    const data = params.input;

    const input = `
      ${data.id ? `id: "${data.id}",` : ''}
    `;

    const payload = {
      query: `mutation {
        deleteCompanyTeamMember(input: {${input}}) {
          success
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.deleteCompanyTeamMember) {
            const deleteCompanyTeamMember =
              response?.data?.data?.deleteCompanyTeamMember?.success;
            return dispatch({
              type: types.MUTATE_REMOVE_TEAM_MEMBER_SUCCEED,
              removeCompanyTeamMember: deleteCompanyTeamMember
            });
          }
        }
        return dispatch({
          type: types.MUTATE_REMOVE_TEAM_MEMBER_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_REMOVE_TEAM_MEMBER_FAILED,
          error: error
        });
      });
  };

const mutateCompanyAffiliation =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_COMPANY_AFFILIATION
    });

    const data = params.input;

    const input = `
      ${data.id ? `id: "${data.id}",` : ''}
      ${
        data.affiliatedCompanyId
          ? `affiliatedCompanyId: "${data.affiliatedCompanyId}",`
          : ''
      }
      ${
        data.affiliationType
          ? `affiliationType: "${data.affiliationType}",`
          : ''
      }
    `;

    const payload = {
      query: `mutation {
        saveCompanyAffiliation(input: {${input}}) {
          companyAffiliate {
            id
          }
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.saveCompanyAffiliation) {
            const companyAffiliate =
              response?.data?.data?.saveCompanyAffiliation?.companyAffiliate;
            return dispatch({
              type: types.MUTATE_COMPANY_AFFILIATION_SUCCEED,
              companyAffiliate: companyAffiliate,
              errors: response?.data?.errors
            });
          }
        }
        return dispatch({
          type: types.MUTATE_COMPANY_AFFILIATION_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_COMPANY_AFFILIATION_FAILED,
          error: error
        });
      });
  };

const mutateDeleteCompanyAffiliation =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_DELETE_COMPANY_AFFILIATION
    });

    const data = params.input;

    const input = `
    ${data.id ? `id: "${data.id}",` : ''}
    ${
      data.affiliatedCompanyId
        ? `affiliatedCompanyId: "${data.affiliatedCompanyId}",`
        : ''
    }
    ${data.affiliationType ? `affiliationType: "${data.affiliationType}",` : ''}
  `;

    const payload = {
      query: `mutation {
      deleteCompanyAffiliation(input: {${input}}) {
        success
      }
     }
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.deleteCompanyAffiliation) {
            const success =
              response?.data?.data?.deleteCompanyAffiliation?.success;
            return dispatch({
              type: types.MUTATE_DELETE_COMPANY_AFFILIATION_SUCCEED,
              deleteCompanyAffiliation: success
            });
          }
        }
        return dispatch({
          type: types.MUTATE_DELETE_COMPANY_AFFILIATION_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_DELETE_COMPANY_AFFILIATION_FAILED,
          error: error
        });
      });
  };

const mutateUpdateCompanyAffiliation =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_UPDATE_COMPANY_AFFILIATION
    });

    const data = params.input;

    const input = `
    ${data.id ? `id: "${data.id}",` : ''}
    ${
      data.affiliatedCompanyId
        ? `affiliatedCompanyId: "${data.affiliatedCompanyId}",`
        : ''
    }
    ${data.affiliationType ? `affiliationType: "${data.affiliationType}",` : ''}
    ${data.status ? `status: "${data.status}",` : ''}
  `;

    const payload = {
      query: `mutation {
        changeStatusCompanyAffiliation(input: {${input}}) {
          companyAffiliate {
            approvalStatus
          }
        }
     }
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.changeStatusCompanyAffiliation) {
            const approvalStatus =
              response?.data?.data?.changeStatusCompanyAffiliation
                ?.companyAffiliate?.approvalStatus;
            return dispatch({
              type: types.MUTATE_UPDATE_COMPANY_AFFILIATION_SUCCEED,
              changeCompanyAffiliation: approvalStatus
            });
          }
        }
        return dispatch({
          type: types.MUTATE_UPDATE_COMPANY_AFFILIATION_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_UPDATE_COMPANY_AFFILIATION_FAILED,
          error: error
        });
      });
  };

const mutateSendEnquiryEmail =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_SEND_ENQUIRY_EMAIL
    });

    const data = params.input;

    const input = `
    ${data.subject ? `subject: "${data.subject}",` : ''}
    ${data.message ? `message: "${data.message}",` : ''}
    ${data.origin ? `origin: "${data.origin}",` : ''}
  `;

    const payload = {
      query: `mutation {
        sendEnquiryEmail(input: {${input}}) {
          enquiryEmailSent
        }
     }
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.sendEnquiryEmail) {
            const enquiryEmailSent =
              response?.data?.data?.sendEnquiryEmail?.enquiryEmailSent;
            return dispatch({
              type: types.MUTATE_SEND_ENQUIRY_EMAIL_SUCCEED,
              enquiryEmailSent: enquiryEmailSent
            });
          }
        }
        return dispatch({
          type: types.MUTATE_SEND_ENQUIRY_EMAIL_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_UPDATE_COMPANY_AFFILIATION_FAILED,
          error: error
        });
      });
  };

const mutateResetEmployerPassword =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_RESET_EMPLOYER_PASSWORD
    });

    const data = params.input;

    const input = `
    ${data.email ? `email: "${data.email}",` : ''}
  `;

    const payload = {
      query: `mutation {
        resetEmployerPassword(input: {${input}}) {
          success
          message
        }
     }
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.resetEmployerPassword) {
            const resetEmployerPassword =
              response?.data?.data?.resetEmployerPassword;
            return dispatch({
              type: types.MUTATE_RESET_EMPLOYER_PASSWORD_SUCCEED,
              resetEmployerPassword: resetEmployerPassword
            });
          }
        }
        return dispatch({
          type: types.MUTATE_RESET_EMPLOYER_PASSWORD_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_RESET_EMPLOYER_PASSWORD_FAILED,
          error: error
        });
      });
  };

const mutateEmployerLogin =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_EMPLOYER_LOGIN
    });

    const data = params.input;

    const input = `
    ${data.email ? `email: "${data.email}",` : ''}
    ${data.password ? `password: "${data.password}",` : ''}
  `;

    const payload = {
      query: `mutation {
        loginEmployer(input: {${input}}) {
          token
          user {
            id
            companyId
            companyName
            name
            email
            currentAccountType
            permissions {
              editCompanyProfile
              postJob
              cva
              buyToken
              editAccountDetail
              allApplicants
            }
          }
        }
     }
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.loginEmployer) {
            const loginEmployer = response?.data?.data?.loginEmployer;
            return dispatch({
              type: types.MUTATE_EMPLOYER_LOGIN_SUCCEED,
              loginEmployer: loginEmployer,
              error: response?.data?.errors
            });
          }
        }
        return dispatch({
          type: types.MUTATE_EMPLOYER_LOGIN_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_EMPLOYER_LOGIN_FAILED,
          error: error
        });
      });
  };

const mutateEmployerCompanyResgistration =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_EMPLOYER_COMPANY_REGISTRATION
    });

    const data = params.input;

    const input = `
    ${data.userName ? `userName: "${data.userName}",` : ''}
    ${data.phoneNumber ? `phoneNumber: "${data.phoneNumber}",` : ''}
    ${data.userEmail ? `userEmail: "${data.userEmail}",` : ''}
    ${data.password ? `password: "${data.password}",` : ''}
    ${
      data.passwordConfirmation
        ? `passwordConfirmation: "${data.passwordConfirmation}",`
        : ''
    }
    ${data.referralToken ? `referralToken: "${data.referralToken}",` : ''}
    ${data.parentCompanyId ? `parentCompanyId: "${data.parentCompanyId}",` : ''}
    ${data.name ? `name: "${data.name}",` : ''}
    ${data.industryId ? `industryId: ${data.industryId},` : ''}
    ${data.sizeId ? `sizeId: ${data.sizeId},` : ''}
    ${data.typeId ? `typeId: ${data.typeId},` : ''}
    ${data.leadSource ? `leadSource: "${data.leadSource}",` : ''}
    ${data.website ? `website: "${data.website}",` : ''}
    ${
      data.trackingParameters
        ? `trackingParameters: "${data.trackingParameters}",`
        : ''
    }
    ${`agreeToTerms: ${data.agreeToTerms},`}


  `;

    const payload = {
      query: `mutation {
        registerCompany(input: {${input}}) {
          company {
            id
            userName
            phoneNumber
            userEmail
            name
            industryId
            sizeId
            typeId
            website
            trackingParameters
          }
        }
     }
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.registerCompany) {
            const company = response?.data?.data?.registerCompany.company;
            return dispatch({
              type: types.MUTATE_EMPLOYER_COMPANY_REGISTRATION_SUCCEED,
              registerCompany: company,
              error: response?.data?.errors
            });
          }
        }
        return dispatch({
          type: types.MUTATE_EMPLOYER_COMPANY_REGISTRATION_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_EMPLOYER_COMPANY_REGISTRATION_FAILED,
          error: error
        });
      });
  };

const updateDirectRegister = value => async dispatch => {
  return dispatch({
    type: types.UPDATE_DIRECT_REGISTER,
    value: value
  });
};

const updateCurrentBg = value => async dispatch => {
  return dispatch({
    type: types.UPDATE_CURRENT_BACKGROUND,
    value: value
  });
};

const updateCurrentOnboardingPage = page => async dispatch => {
  return dispatch({
    type: types.UPDATE_CURRENT_ONBOARDING_PAGE,
    page: page
  });
};

// Update onboarding form fields
const updateOnboardingFormFields = params => async (dispatch, getState) => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_ONBOARDING_FIELDS,
        payload: params
      })
    );
  });
};

const saveCompanyWaitlist =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.SAVE_COMPANY_WAITLIST
    });

    const mutationInput = `
      name: ${JSON.stringify(params.name ? params.name : '')}, 
      email: ${JSON.stringify(params.email ? params.email : '')},
      phoneNumber: ${JSON.stringify(
        params.phoneNumber ? params.phoneNumber : ''
      )},
      companyName: ${JSON.stringify(
        params.companyName ? params.companyName : ''
      )},
      inviteCode: ${JSON.stringify(params.inviteCode ? params.inviteCode : '')},
      companyMission: ${JSON.stringify(
        params.companyMission ? params.companyMission : ''
      )},
      companyVision: ${JSON.stringify(
        params.companyVision ? params.companyVision : ''
      )},
      coreValues: ${JSON.stringify(params.coreValues ? params.coreValues : '')},
      organisationalCulture: ${JSON.stringify(
        params.organisationalCulture ? params.organisationalCulture : ''
      )},
      currentWorkingArrangement: ${JSON.stringify(
        params.currentWorkingArrangement ? params.currentWorkingArrangement : ''
      )},
      trackingParameters: ${JSON.stringify(
        params.trackingParameters ? params.trackingParameters : ''
      )},

    `;

    const payload = {
      query: `mutation {
        companyWaitlist(input: {${mutationInput}}) {
          companyWaitlist {
            name
            companyName
            email
            companyMission
            companyVision
            referralCode
            refereeCode
          }      
        }
        }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.companyWaitlist) {
            // const { success, frvalue } = response.data.data.companyWaitlist;
            const frvalue = response.data.data.companyWaitlist;
            if (!response.data.errors) {
              localStorage.setItem('waitlistInfo', JSON.stringify(frvalue));
              return dispatch({
                type: types.SAVE_COMPANY_WAITLIST_SUCCEEDED,
                waitlistValue: frvalue
              });
            } else {
              alert(response.data.errors[0].message);
              return dispatch({
                type: types.SAVE_COMPANY_WAITLIST_FAILED
              });
            }
          }
        }
        return dispatch({
          type: types.SAVE_COMPANY_WAITLIST_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.SAVE_COMPANY_WAITLIST_FAILED
        });
      });
  };

const fetchWaitlistInfo =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_WAITLIST_INFO
    });

    const payload = {
      query: `{
        companyWaitlist(email: "${params.email}") {
          id
          name
          companyName
          email
          companyMission
          companyVision
          referralCode
          refereeCode
          refereeCompanyName
          phoneNumber
      }
    }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.companyWaitlist) {
            const waitlistData = response?.data?.data?.companyWaitlist;

            return dispatch({
              type: types.FETCH_WAITLIST_INFO_SUCCEED,
              currentValue: waitlistData
            });
          }
        }
        return dispatch({
          type: types.FETCH_WAITLIST_INFO_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_WAITLIST_INFO_FAILED
        });
      });
  };

const mutateSendEmailWithoutLogin =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_SEND_EMAIL_WITHOUT_LOGIN
    });
    const data = params.input;
    const input = `
    ${data.name ? `name: "${data.name}",` : ''}
    ${data.companyName ? `companyName: "${data.companyName}",` : ''}
    ${data.email ? `email: "${data.email}",` : ''}
    ${data.phoneNumber ? `phoneNumber: "${data.phoneNumber}",` : ''}
    ${data.message ? `message: "${data.message}",` : ''}
  `;
    const payload = {
      query: `mutation {
        sendEnquiryFormWithoutLogin(input: {${input}}) {
          enquiryEmailSent
        }
     }
    `
    };
    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.sendEnquiryFormWithoutLogin) {
            const enquiryEmailSent =
              response?.data?.data?.sendEnquiryFormWithoutLogin
                ?.enquiryEmailSent;
            return dispatch({
              type: types.MUTATE_SEND_EMAIL_WITHOUT_LOGIN_SUCCEED,
              enquiryEmailSent: enquiryEmailSent
            });
          }
        }
        return dispatch({
          type: types.MUTATE_SEND_EMAIL_WITHOUT_LOGIN_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_SEND_EMAIL_WITHOUT_LOGIN_FAILED,
          error: error
        });
      });
  };

const updateCountryDropdown =
  (params = {}) =>
  async dispatch => {
    return new Promise(resolve => {
      return resolve(
        dispatch({
          type: types.UPDATE_COUNTRY_DROPDOWN,
          country: params
        })
      );
    });
  };

export {
  selectModalVideo,
  expandVideoAccordion,
  openDeletePhotoPopup,
  openOfficeImageGuidePopup,
  deleteCompanyOfficeImage,
  updateImageModalStatus,
  fetchMyCompanySettings,
  fetchAccountInfo,
  fetchMyCompanyStatus,
  getPresignedUrl,
  fetchIndustries,
  updateCompanyAvailableCoins,
  saveCompanyTeamMemberTrack,
  updateTeamMemberSpecs,
  fetchCompanySizes,
  fetchCompanyProfile,
  fetchIcons,
  fetchCompanyTeamMembers,
  fetchJobTypes,
  fetchStateRegions,
  fetchTracks,
  fetchCareerLevels,
  fetchExperiences,
  fetchAccessLevels,
  fetchCompanyName,
  fetchCompanyAffiliations,
  fetchCompanyTypes,
  mutateCompanyDetails,
  readyFileUpload,
  mutateCompanyProfile,
  mutateAccountInfo,
  mutateCompanyTeamMember,
  mutateRemoveTeamMember,
  mutateCompanyAffiliation,
  mutateDeleteCompanyAffiliation,
  mutateUpdateCompanyAffiliation,
  mutateSendEnquiryEmail,
  mutateResetEmployerPassword,
  mutateEmployerLogin,
  mutateEmployerCompanyResgistration,
  updateCurrentOnboardingPage,
  updateOnboardingFormFields,
  saveCompanyWaitlist,
  fetchWaitlistInfo,
  updateCurrentBg,
  updateDirectRegister,
  mutateSendEmailWithoutLogin,
  clearAccountInfo,
  updateTermsPopupStatus,
  updateCountryDropdown,
  sendVerificationEmail,
  updateCompanySidebar,
  updateCompanyVideoStatus,
  updateUploadedVimeoVideo,
  updateSocialMediaGuideline
};
