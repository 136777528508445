import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, MenuItem } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from '../../context';
import { TextFieldStyled } from './styles';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

export default function EmploymentType() {
  const key = 'jobType';
  const {
    formState,
    handleSingleFieldChange,
    disableIntern,
    disableSenior,
    errorState,
    handleErrorState
  } = useFormContext();

  const jobTypes = useSelector(state => state.companies.jobTypes);
  const setValue = disableIntern ? 4 : disableSenior ? 2 : null;

  const onChange = e => {
    handleErrorState(errorState.filter(error => error !== key));
    handleSingleFieldChange('jobType', { id: e.target.value });
    if (e.target.value === '4') {
      handleErrorState(
        errorState.filter(
          error => !error.includes('careerLevel') && error !== key
        )
      );
      handleSingleFieldChange('careerLevel', { id: '8' });
      handleSingleFieldChange('maxYearExperience', 0);
      handleSingleFieldChange('minYearExperience', -1);
    }
  };

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0 0 20px',
        width: '100%'
      }}>
      <Grid
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '15px',
          width: '100%'
        }}>
        <FontAwesomeIcon icon={faClock} sx={{ marginRight: '15px' }} />
        {disableIntern || disableSenior ? (
          <TextFieldStyled
            select
            disabled={true}
            value={
              setValue ? setValue : formState?.jobType?.id || 'placeholder'
            }
            name="jobType"
            displayEmpty
            fullWidth
            SelectProps={{
              IconComponent: null
            }}
            variant="outlined">
            {jobTypes?.map(type => {
              return <MenuItem value={type?.id}>{type?.title}</MenuItem>;
            })}
          </TextFieldStyled>
        ) : (
          <TextFieldStyled
            select
            isError={errorState.includes(key)}
            disabled={disableIntern || disableSenior}
            value={
              setValue ? setValue : formState?.jobType?.id || 'placeholder'
            }
            placeholder={'Select type of employment'}
            name="jobType"
            displayEmpty
            fullWidth
            variant="outlined"
            onChange={onChange}>
            <MenuItem disabled value="placeholder" style={{ display: 'none' }}>
              {'Select type of employment'}
            </MenuItem>
            {jobTypes?.map(type => {
              return <MenuItem value={type?.id}>{type?.title}</MenuItem>;
            })}
          </TextFieldStyled>
        )}
      </Grid>
      {errorState.includes(key) && (
        <p
          style={{
            color: '#BE4242',
            fontSize: '12px',
            lineHeight: '20px',
            display: 'flex',
            padding: '0px 8px',
            alignItems: 'center',
            gap: '8px',
            marginLeft: '30px'
          }}>
          <ExclamationCircleIcon
            style={{
              width: '20px',
              height: '20px',
              color: '#BE4242'
            }}
          />
          Job type is required
        </p>
      )}
    </Grid>
  );
}
