import React from 'react';
import { Button, PopUpDialog } from '../../../../../components';
import styles from './index.module.scss';

const InterruptAiviPopUp = ({ open, handleClose, sendAdminMessage }) => {
  const onYesClicked = () => {
    sendAdminMessage(true);
  };

  return (
    <PopUpDialog open={open} handleClose={handleClose} title="Confirmation">
      <div style={{ padding: '20px 20px' }}>
        <div className={styles.contentContainer}>
          <p className={styles.interruptAiviDescription}>
            Are you sure you want to interrupt the Virtual Interviewer?
          </p>
        </div>

        <div className={styles.buttonContainer}>
          <Button color="TRANSPARENT" size="BIG" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="PURPLE" size="BIG" onClick={onYesClicked}>
            Yes
          </Button>
        </div>
      </div>
    </PopUpDialog>
  );
};

export default InterruptAiviPopUp;
