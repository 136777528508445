import { styled as styling } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { Typography, Tab, Tabs, Avatar, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TabIndicator = styling('div')({
  border: '2px solid #efefef',
  width: '75%',
  transform: 'translateY(-2px)',
});

export const TextFieldStyled = styling(TextField)((props) => ({
  width: '100%',
  border: '1px solid #aeaeae',
  background: 'transparent',
  borderRadius: '8px',

  '.MuiFilledInput-multiline': {
    padding: '27px 12px 10px',
  },

  ...(props.exclude_text_area == 'true' && {
      marginTop: '8px',
      border: 'none',
    }),
}));

export const Title = styling('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const Text = styling(Typography)((props) => ({
  ...(props.type == 'title' && {
    fontFamily: '20px',
    fontSize: '20px',
    margin: '0 0 5px',
    fontWeight: 700,
  }),

  ...(props.type == 'reminder' && {
    color: '#666666',
    fontFamily: 'Inter',
    fontSize: '13px',
    margin: '15px 0',
  }),

  ...(props.type == 'basic' && {
    fontFamily: 'Inter',
    fontSize: '15px',
    margin: '10px 0',
    display: 'flex',
    alignSelf: 'center',
  }),

  ...(props.type == 'add_note' && {
    fontFamily: 'Inter',
    fontSize: '15px',
    display: 'flex',
    alignSelf: 'center',
    fontWeight: 700,
  }),

  ...(props.type == 'note_remark' && {
    color: '#666666',
    fontFamily: 'Inter',
    fontSize: '12px',
    margin: '5px 0',
  }),

  ...(props.type == 'person' ||
  props.type == 'date' ||
  props.type == 'timestamp' && {
    color: '#666666',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Inter',
    margin: '0 0 5px 0',
  }),
  ...(props.type == 'display_note' && {
    fontFamily: 'Inter',
    fontSize: '13px',
  }),
}));

export const ContentContainer = styling('div')(( props ) => ({
  ...(props.type == 'two-column' && {
    display: 'grid',
    gridTemplateColumns: '10% auto',

    ...(props.edit == 'true' && {
      marginTop: '12px',
    }),
  }),

  ...(props.type == 'three-column' && {
    display: 'grid',
    gridTemplateColumns: '10% auto auto',
  }),

  ...(props.type == 'two-column-note' && {
    display: 'grid',
    gridTemplateColumns: '10% auto',
    margin: '30px 0 0',
    cursor: 'pointer',
  }),
}));

export const IconContainer = styling('div')(( props ) => ({
  ...(props.type == 'basic' && {
    display: 'flex',
    alignSelf: 'center',
  }),

  ...(props.type == 'pointer' && {
    display: 'flex',
    alignSelf: 'center',
    cursor: 'pointer',
  }),

  ...(props.type == 'fa-bars' || props.type == 'fa-pen' && {
    margin: '15px 0',
    display: 'flex',
  }),

  ...(props.type == 'fa-plus-circle' && {
    display: 'flex',
    margin: '5px 0',
  }),

  ...(props.cursor == 'pointer' && {
    cursor: 'pointer',
  }),
}));

export const FontAwesomeIconStyled = styling(FontAwesomeIcon)({
  color: '#222',
  margin: '0 1px',
});

export const TabStyled = styling(Tab)((props) => ({
  display: 'flex',
  margin: '0 10px 0 0',
  textTransform: 'none',
  fontWeight: 700,
  fontFamily: 'Inter',
  fontSize: '13px',
  color: '#000',
  minWidth: 'auto',
  padding: '0',
  minHeight: 'auto',
  '& .MuiTab-wrapper': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
  },
}));

export const TabsStyled = styling(Tabs)({
  '&.MuiTabs-root': {
    minHeight: '35px',
  },
});

export const ButtonContainer = styling('div')(( props ) => ({
  ...(props.type == 'save' && {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '15px 0',
  }),

  ...(props.type == 'schedule_interview' && {
    position: 'absolute',
    bottom: '0',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }),
}));

export const ButtonStyled = styling(Button)((props) => ({
  ...(props.type == 'basic' && {
    borderRadius: '30px',
    background: '#512acc',
    alignSelf: 'center',
    color: '#fff',
    fontWeight: 700,
    padding: '5px 20px',
    textTransform: 'capitalize',
    fontFamily: 'Inter',
    fontSize: '15px',
    width: '100%',
    minWidth: '120px',
    marginTop: '10px',
  }),

  ...(props.type == 'outline' && {
    background: '#fff',
    alignSelf: 'center',
    color: '#512acc',
    fontWeight: 700,
    padding: '5px 20px',
    textTransform: 'capitalize',
    fontFamily: 'Inter',
    fontSize: '15px',
    border: '1px solid #512acc',
    borderRadius: '30px',
    width: '100%',
    minWidth: '120px',
    marginTop: '10px',
  }),

  ...(props.type == 'disabled' && {
    borderRadius: '30px',
    background: 'grey',
    alignSelf: 'center',
    color: '#fff',
    fontWeight: 700,
    padding: '5px 20px',
    textTransform: 'capitalize',
    fontFamily: 'Inter',
    fontSize: '15px',
    width: '100%',
    minWidth: '120px',
    marginTop: '10px',
  }),
}));

export const CloseIconContainer = styling('div')({
  position: 'absolute',
  right: '4%',
  top: '3%',
  cursor: 'pointer',
});

export const ProfilePicture = styling(Avatar)({
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  background: 'pink',
});

export const CommentContainer = styling('div')({
  display: 'flex',
});

export const Dot = styling('div')({
  width: '3px',
  height: '3px',
  borderRadius: '50%',
  background: '#666666',
  display: 'flex',
  alignSelf: 'center',
  margin: '0 3px',
  transform: 'translateY(-2px)',
});

export const Divider = styling('div')({
  border: '1px solid #efefef',
  width: '100%',
  margin: 'auto',
  transform: 'translateY(8px)',
});
