import { Grid } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CandidateSnapshot from '../../components/CandidateSnapshot';
import { HeightSpacer } from '../../components/Spacer/styles';
import DefaultLayout from '../../layouts/DefaultLayout';
import {
  changeJobApplicationState,
  getApplications,
  updateAllApplications,
  updateApplicationCurrentPage,
  updateApplicationParams,
  updateJobApplications,
  viewApplication
} from '../../redux/actions/application_action';
import { fetchMyCompanySettings } from '../../redux/actions/company_actions';
import {
  fetchAllJobs,
  fetchJob,
  updateJobData
} from '../../redux/actions/job_action';
import store from '../../redux/stores/store';
import { JOB_APPLICATION_STATUS, SEARCH_TYPE } from '../../utils/Constants';
import { ErrorToast, InfoToast, SuccessToast } from '../../utils/ToastUtils';
import CandidatesTable from './CandidatesTable';
import JobListDropdown from './JobListDropdown';
import Search from './Search';

const AllApplicants = () => {
  const dispatch = useDispatch();

  const jobId = useSelector(
    state => state.applications.applicationParams.jobId
  );
  const companySettings = useSelector(
    state => state.companies.myCompanySettings
  );
  const jobData = useSelector(state => state.jobs.jobData);
  const applications = useSelector(state => state.applications.applications);
  const applicationIndex = useSelector(
    state => state.applications.currentCandidateIndex
  );
  const applicationParams = useSelector(
    state => state.applications.applicationParams
  );

  const [autocomValueNew, setAutocomValueNew] = useState('show_all_applicants');

  const handleAutocomValueChange = (newValue) => {
    setAutocomValueNew(newValue);
  };

  const getJob = () => {
    let params = { id: jobId };
    dispatch(fetchJob(params));
  };

  const getApplicants = () => {
    let params = {
      ...store.getState().applications.applicationParams,
      // origin: 'all-applications'
    };

    if (store.getState().applications.applicationCurrentPage > 1) {
      dispatch(updateApplicationCurrentPage(1));
    }
    dispatch(getApplications(params));
  };

  const getAllJobs = () => {
    dispatch(fetchAllJobs());
  };

  const renderCandidateSnapshot = () => {
    const isCandidateSnapshotOpen = useSelector(
      state => state.applications.isCandidateSnapshotOpen
    );

    if (!isCandidateSnapshotOpen) return null;

    return isCandidateSnapshotOpen;
  };

  const getCompanySettings = () => {
    dispatch(fetchMyCompanySettings());
  };

  const updateApplicationState = (
    application,
    value,
    rejectReason,
    blacklistReason
  ) => {
    return new Promise(async resolve => {
      if (!application || !value) return;

      try {
        const params = {
          applicationId: application.id,
          state: value,
          rejectReason: rejectReason,
          reason: blacklistReason
        };

        return dispatch(changeJobApplicationState(params)).then(res => {
          if (res.application) {
            let cApplications = [...applications];

            let index = applications.findIndex(item => {
              return item.id == res.applicationId;
            });

            if (index > -1) {
              cApplications[index] = res.application;
              dispatch(updateJobApplications(cApplications));
              dispatch(updateAllApplications(cApplications));
            }

            //blacklist
            if (value === JOB_APPLICATION_STATUS.BLACKLISTED) {
              let removedListItem = [];
              let removedSnapshotItem = [];

              //applications array for list view
              removedListItem = [...applications].filter(item => {
                return item?.id !== application.id;
              });

              dispatch(updateJobApplications(removedListItem));

              //all applications array - for candidate snapshot
              removedSnapshotItem = [
                ...store.getState().applications.allApplications
              ].filter(item => {
                return item?.id !== application.id;
              });

              dispatch(updateAllApplications(removedSnapshotItem));

              return;
            }
          }

          if (res?.application?.job) {
            dispatch(updateJobData(res.application.job));
          }

          if (res?.errors?.length > 0) {
            const error = res.errors[0];
            if (error?.message) {
              ErrorToast(error?.message);
            }
          } else {
            showToastMessages(value);
          }

          return resolve(true);
        });
      } catch (err) {
        ErrorToast(err.message);
        return resolve(false);
      }
    });
  };

  // Toast messages
  const rejectedStatusMessage = () => {
    return (
      <div>
        <p
          style={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 'bold',
            margin: '4px 0'
          }}>
          User Rejected
        </p>
        <p style={{ margin: '0', fontFamily: 'Inter', fontSize: '12px' }}>
          You have successfully rejected this user.
        </p>
      </div>
    );
  };

  const showToastMessages = value => {
    if (value == JOB_APPLICATION_STATUS.REJECTED) {
      InfoToast(rejectedStatusMessage());
    } else {
      SuccessToast('Changed Job Application Status Successful!');
    }
  };

  const onViewApplication = application => {
    // If unviewed
    if (!application.status) {
      let params = {
        applicationId: application.id,
        state: application.state
      };

      dispatch(viewApplication(params)).then(res => {
        if (res.type == 'VIEW_APPLICATION_SUCCEED') {
          let index = applications.findIndex(item => {
            return item.id == res.applicationId;
          });

          let cApplications = [...applications];

          cApplications[index].status = true;

          dispatch(updateJobApplications(cApplications));
        }
      });
    }
  };

  const resetParams = () => {
    let params = {
      jobId: store.getState().applications.applicationParams.jobId,
      first: 10,
      search: '',
      searchType: SEARCH_TYPE.ANY,
      sort: { by: 'appliedAt', direction: 'desc' },
      showState: null,
      yearsOfExperience: 0,
      withVideoCoverLetter: false,
      highestEducationLevels: [],
      malaysians: null,
      trackIds: [],
      offset: 0,
      hideViewed: false,
      chatbotStatus: '',
      refresh: true,
      appliedFromDate: moment().subtract(1, 'year').unix(),
      appliedToDate: moment().unix()
    };

    dispatch(updateApplicationParams(params));
  };

  useEffect(() => {
    // for applicants table
    getApplicants();
    //for status filter
    getJob();

    return () => {
      resetParams();
    };
  }, [jobId]);

  useEffect(() => {
    // for job list dropdown
    getAllJobs();
    getCompanySettings();
  }, []);

  return (
    <DefaultLayout>
      <div
        style={{ padding: '22px 20px 15px', width: '1240px', margin: 'auto' }}>
        <JobListDropdown onAutocomValueChange={handleAutocomValueChange} />
        <HeightSpacer height={'48px'} />
        <Search />
        <HeightSpacer height={'15px'} />
      </div>
      <CandidatesTable
        updateApplicationState={updateApplicationState}
        application={applications[applicationIndex]}
        autocomValueNew={autocomValueNew}
      />

      {renderCandidateSnapshot && (
        <CandidateSnapshot
          companyData={companySettings}
          updateApplicationState={updateApplicationState}
          jobData={jobData}
          applicationStatus={applications[applicationIndex]?.state}
          pageName={'new-applicant-page'}
          onViewApplication={onViewApplication}
        />
      )}
    </DefaultLayout>
  );
};

export default AllApplicants;
