import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import anime from 'animejs';
import s from './index.module.scss';

import jobSrc1 from '../../assets/images/promo-page/jobslots/1.svg';
import jobSrc2 from '../../assets/images/promo-page/jobslots/2.svg';
import jobSrc3 from '../../assets/images/promo-page/jobslots/3.svg';
import jobSrc4 from '../../assets/images/promo-page/jobslots/4.svg';
import jobSrc5 from '../../assets/images/promo-page/jobslots/5.svg';
import jobSrc6 from '../../assets/images/promo-page/jobslots/6.svg';

const content = [
  [
    { id: 1, name: 'Digital Marketer', src: jobSrc1 },
    { id: 2, name: 'IT Supervisor', src: jobSrc4 },
    { id: 3, name: 'Database Engineer', src: jobSrc3 }
  ],
  [
    { id: 4, name: 'Graphic Designer', src: jobSrc2 },
    { id: 5, name: 'Video Editor', src: jobSrc5 },
    { id: 6, name: 'Business Analyst', src: jobSrc6 }
  ],
  [
    { id: 7, name: 'Marketing Manager', src: jobSrc5 },
    { id: 8, name: 'Web Developer', src: jobSrc3 },
    { id: 9, name: 'HR Manager', src: jobSrc1 }
  ]
];

// helper to generate random integers within a certain range
// The maximum is exclusive and the minimum is inclusive
function getRandomInt(min, max) {
  const min_ = Math.ceil(min);
  const max_ = Math.floor(max);
  return Math.floor(Math.random() * (max_ - min_) + min_);
}

export default function JobSlotsAnimation({ size, ...props }) {
  const [animationRefs, setAnimationRefs] = useState({
    jobStatus0: 'VACANT',
    jobStatus1: 'VACANT',
    jobStatus2: 'VACANT',
    topText0: '.. ',
    topText1: '.. ',
    topText2: '.. '
  });

  // helper function to update animation refs object without race conditions
  const updateAnimRefs = (fieldName, newValue) => {
    const updatedValues = {};
    updatedValues[fieldName] = newValue;
    setAnimationRefs(prevAnimRefs => ({ ...prevAnimRefs, ...updatedValues }));
  };

  useEffect(() => {
    content.forEach((slot, slotIndex) => {
      const timeline = anime.timeline({
        easing: 'easeOutExpo',
        duration: 1500,
        loop: true,
        loopBegin() {
          // begin() callback workaround (animejs bug)
          for (let i = 0; i < timeline.children.length; i += 1) {
            timeline.children[i].began = false;
          }
        },
        loopComplete: () => {
          // complete() callback workaround (animejs bug)
          for (let i = 0; i < timeline.children.length; i += 1) {
            timeline.children[i].completed = false;
          }
        }
      });

      // add randomized predelay
      const predelay = 5 * getRandomInt(10, 100) + (slotIndex + 1) * 90;
      timeline.add({
        duration: predelay
      });

      slot.forEach(job => {
        // show character
        timeline.add({
          targets: `.${s.character}.character${job.id}`,
          translateY: ['100%', '0%']
        });
        // show toptext
        timeline.add(
          {
            targets: `.${s.topText}.topText${slotIndex}`,
            opacity: ['0%', '100%'],
            duration: 700
          },
          '-=1400'
        );
        // set text to hiring, change bgcolor
        timeline.add(
          {
            targets: `.${s.jobStatus}.jobStatus${slotIndex}`,
            backgroundColor: ['#a0a0a0', '#f2ad0c'],
            begin() {
              updateAnimRefs(`jobStatus${slotIndex}`, 'HIRING');
              updateAnimRefs(`topText${slotIndex}`, job.name);
            },
            endDelay: 1000 + 30 * getRandomInt(1, 100) * (slotIndex + 1)
          },
          '-=1400'
        );
        // set text to hired, change bgcolor
        timeline.add({
          targets: `.${s.jobStatus}.jobStatus${slotIndex}`,
          backgroundColor: ['#f2ad0c', '#26a524'],
          duration: 700,
          begin() {
            updateAnimRefs(`jobStatus${slotIndex}`, 'HIRED');
            updateAnimRefs(`topText${slotIndex}`, job.name);
          }
        });
        // hide character
        timeline.add({
          targets: `.${s.character}.character${job.id}`,
          translateY: ['0%', '100%']
        });
        // hide toptext
        timeline.add(
          {
            targets: `.${s.topText}.topText${slotIndex}`,
            opacity: ['100%', '0%'],
            duration: 1000
          },
          '-=1400'
        );
        // set text to hired, change bgcolor
        timeline.add(
          {
            targets: `.${s.jobStatus}.jobStatus${slotIndex}`,
            backgroundColor: ['#26a524', '#a0a0a0'],
            begin() {
              updateAnimRefs(`jobStatus${slotIndex}`, 'VACANT');
              updateAnimRefs(`topText${slotIndex}`, '..');
            }
          },
          '-=1000'
        );

        // add randomized post animation delay
        const postdelay = 5 * getRandomInt(1, 10) * slotIndex;
        timeline.add({
          duration: postdelay
        });
      });
    });
  }, []);
  return (
    <div className={s.animWrapper} {...props}>
      <div className={s.animWrapper__inner}>
        {content.map((slot, index) => (
          <div key={index} className={[s.card, size ? s[size] : ''].join(' ')}>
            <div className={s.topSection}>
              <div className={classNames(s.topText, `topText${index}`)}>
                {animationRefs[`topText${index}`]}
              </div>
            </div>
            <div className={s.characterMask}>
              {slot.map((job, index2) => (
                <div
                  key={index2}
                  className={classNames(s.character, `character${job.id}`)}>
                  <img src={job.src} alt="character" />
                </div>
              ))}
            </div>
            <div className={classNames(s.jobStatus, `jobStatus${index}`)}>
              {animationRefs[`jobStatus${index}`]}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
