import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect
} from 'react';
// import { ShareIconContainer, ShareIconButton, ShareIconStyled } from './styles';
import { config, animated, useSpring } from 'react-spring';
import { Grid } from '@mui/material';
import CandidateSnapshotResume from '../CandidateSnapshot/CandidateSnapshotResume';
import CandidateSnapshotBasicInfo from './CandidateSnapshotBasicInfo';
import CandidateSnapshotRichInfo from './CandidateSnapshotRichInfo';
import CloseLoop from './CloseLoop';
import {
  updateCandidateSnapshotStatus,
  updateCurrentCandidateIndex,
  closingLoop,
  updateInterviewStatus
} from '../../../src/redux/actions/application_action';
import { SuccessToast, ErrorToast, WarningToast } from '../../utils/ToastUtils';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as types from '../../../src/redux/types/application_type';
import store from '../../../src/redux/stores/store';
import CloseLoopPopUp from '../../pages/Jobs/PopUps/CloseLoopPopUp';
import OfferJobPopUp from '../../pages/Jobs/PopUps/OfferJobPopUp';
import {
  CloseLoopContainer,
  CloseIconContainer,
  CloseIconStyled,
  AnimatedContainer,
  AnimatedResume,
  AnimatedBio,
  AnimatedOverlay,
  InfoContainer
} from './styles';
import { printIntrospectionSchema } from 'graphql';

const CandidateSnapshot = forwardRef((props, ref) => {
  const [isCloseLoopOpen, setIsCloseLoopOpen] = useState(false);

  const [loopError, setLoopError] = useState(false);

  const checkPage = location.pathname.includes('/all-applicants');

  const handleCloseLoopPopUpClose = () => {
    setIsCloseLoopOpen(false);
  };

  const onHandleHide = () => {
    props.updateCandidateSnapshotStatus(false);
  };

  const contentProps = useSpring({
    config: props.isCandidateSnapshotOpen
      ? { ...config.stiff }
      : { duration: 150 },
    opacity: props.isCandidateSnapshotOpen ? '1' : '0'
  });

  const contentProps2 = useSpring({
    config: props.isCandidateSnapshotOpen
      ? { ...config.stiff }
      : { duration: 150 },
    opacity: props.isCandidateSnapshotOpen ? '1' : '0'
  });

  const moveApplicationCountToOffer = state => {
    const currentJobData = { ...props.jobData };
    if (state == 'interview') {
      currentJobData['interviewCount'] -= 1;
    } else if (state == 'kiv') {
      currentJobData['kivCount'] -= 1;
    } else if (state == 'shortlisted') {
      currentJobData['shortlistedCount'] -= 1;
    }

    currentJobData['offerCount'] += 1;

    props.setJobData(currentJobData);
  };

  const closingApplicationLoop = (application, state) => {
    const closingLoopParams = {
      id: application.id,
      state: state
    };

    props.closingLoop(closingLoopParams).then(res => {
      if (res.type === types.CLOSE_LOOP_SUCCEED) {
        // if (res.redirectUrl) {
        //   window.open(res.redirectUrl);
        // }
        SuccessToast('Successfully marked this candidate as Hired.');
        setLoopError(false);

        // From list view
        if (props.setCandidates && props.candidates) {
          let index = -1;
          if (state === 'interview' || state === 'offer') {
            index = props.candidates.findIndex(
              item => item.node?.jobApplication?.id === res.jobApplication.id
            );
          } else {
            index = props.candidates.findIndex(
              item => item.node.id === res.jobApplication.id
            );
          }

          if (index != -1) {
            let currentCandidates = [...props.candidates];

            if (state === 'offer') {
              currentCandidates[index] = {
                ...currentCandidates[index],
                node: {
                  ...currentCandidates[index]?.node,
                  jobApplication: { ...res.jobApplication }
                }
              };
            } else {
              moveApplicationCountToOffer(state);
              currentCandidates.splice(index, 1);
              // currentCandidates[index] = {
              //   ...currentCandidates[index],
              //   node: {
              //     ...res.interview
              //   }
              // };
            }
            props.setCandidates(currentCandidates);

            if (props.setCurrentCandidates) {
              props.setCurrentCandidates(currentCandidates);
            }
          }
        }
      } else {
        setLoopError(true);
        ErrorToast(
          'Failed to hire candidate at the moment, please try again later.'
        );
      }
      // handleCloseLoopPopUpClose();
    });
  };

  const getReachedEndStatus = state => {
    let reachedEnd = true;
    if (state === 'undecided') {
      reachedEnd = store.getState().applications.undecidedReachedEnd;
    } else if (state === 'kiv') {
      reachedEnd = store.getState().applications.kivReachedEnd;
    } else if (state === 'shortlisted') {
      reachedEnd = store.getState().applications.shortlistedReachedEnd;
    } else if (state === 'rejected') {
      reachedEnd = store.getState().applications.rejectedReachedEnd;
    }

    return reachedEnd;
  };

  const getCandidate = props => {
    if (props.allApplications[props.currentCandidateIndex]) {
      return props.allApplications[props.currentCandidateIndex];
    }

    return null;
  };

  const getApplication = candidate => {
    const application = candidate?.jobApplication
      ? candidate.jobApplication
      : candidate;

    return application;
  };

  const hasPrevious = () => {
    let previousIndex = store.getState().applications.currentCandidateIndex - 1;
    if (previousIndex < 0) {
      return { hasPrevious: false };
    }

    return { hasPrevious: true, previousIndex };
  };

  const hasNext = () => {
    let nextIndex = store.getState().applications.currentCandidateIndex + 1;
    if (store.getState().applications.allApplications.length <= nextIndex) {
      return { hasNext: false };
    }

    return { hasNext: true, nextIndex };
  };

  const previousCandidate = () => {
    let result = hasPrevious();

    if (!result.hasPrevious) return;

    if (props.candidateInfoPrevious) {
      props.candidateInfoPrevious();
    }

    if (props.isAts) {
      const candidate = props.allApplications[result?.previousIndex];

      props.updateCurrentCandidateIndex(result.previousIndex);

      viewApplicantTracking(candidate);
    } else {
      props.updateCurrentCandidateIndex(result.previousIndex);
    }

    const eventName = props.isAts
      ? 'ats_previous_applicant'
      : 'list_previous_applicant';

    dataLayer.push({
      event: eventName
    });
  };

  const viewApplicantTracking = candidate => {
    if (candidate) {
      const application = candidate?.jobApplication
        ? candidate?.jobApplication
        : candidate;
      const jobId = application?.job?.id;
      const userId = application?.user?.id;

      try {
        switch (application?.state) {
          case 'undecided':
            window.dataLayer.push({
              event: 'view-applicant-pipeline-undecided',
              job_id: jobId,
              user_id: userId
            });
            break;
          case 'shortlisted':
            window.dataLayer.push({
              event: 'view-applicant-pipeline-shortlisted',
              job_id: jobId,
              user_id: userId
            });
            break;
          case 'kiv':
            window.dataLayer.push({
              event: 'view-applicant-pipeline-kiv',
              job_id: jobId,
              user_id: userId
            });
            break;
          case 'interview':
            window.dataLayer.push({
              event: 'view-applicant-pipeline-interview',
              job_id: jobId,
              user_id: userId
            });
            break;
          case 'offered':
            window.dataLayer.push({
              event: 'view-applicant-pipeline-offered',
              job_id: jobId,
              user_id: userId
            });
            break;
          case 'rejected':
            window.dataLayer.push({
              event: 'view-applicant-pipeline-rejected',
              job_id: jobId,
              user_id: userId
            });
            break;
        }
      } catch {}
    }
  };

  const nextCandidate = () => {
    let result = hasNext();
    // TODO - load next batch of data of the same state if theres still more
    if (!result.hasNext) return;

    if (props.candidateInfoNext) {
      props.candidateInfoNext();
    }

    if (props.isAts) {
      const candidate = props.allApplications[result?.nextIndex];
      // const currentState =
      //   store.getState().applications.allApplications[
      //     store.getState().applications.currentCandidateIndex
      //   ].state;
      // const nextState =
      //   store.getState().applications.allApplications[result.nextIndex].state;

      props.updateCurrentCandidateIndex(result.nextIndex);
      viewApplicantTracking(candidate);

      // if (currentState != nextState) {
      //   // Load pagination of current state if theres still more
      //   if (!getReachedEndStatus(currentState)) {
      //     // Not yet reached end
      //     if (currentState === 'undecided') {
      //       props.loadingUndecidedApplications(false, true);
      //     } else if (currentState === 'kiv') {
      //       props.loadingKivApplications(false, true);
      //     } else if (currentState === 'shortlisted') {
      //       props.loadingShortlistedApplications(false, true);
      //     } else if (currentState === 'rejected') {
      //       props.loadingRejectedApplications(false, true);
      //     }
      //   } else {
      //     props.updateCurrentCandidateIndex(result.nextIndex);
      //   }
      // } else {
      //   props.updateCurrentCandidateIndex(result.nextIndex);
      // }
    } else {
      props.updateCurrentCandidateIndex(result.nextIndex);
    }

    // Update view status
    if (props.onViewApplication) {
      const nextCandidate =
        store.getState().applications.allApplications[result.nextIndex];
      props.onViewApplication(nextCandidate);
    }

    const eventName = props.isAts
      ? 'ats_next_applicant'
      : 'list_next_applicant';

    dataLayer.push({
      event: eventName
    });
  };

  const [isOfferJobOpen, setIsOfferJobOpen] = useState(false);

  const handleOfferJobClose = () => {
    setIsOfferJobOpen(false);
  };

  //  To allow parent to call this function
  useImperativeHandle(ref, () => ({
    nextCandidate,
    setIsOfferJobOpen
  }));

  // Use effects
  useEffect(() => {
    const cCandidate = getCandidate(props);
    const cApplication = cCandidate?.jobApplication
      ? cCandidate.jobApplication
      : cCandidate;

    if (cApplication) {
      const jobId = cApplication?.job?.id;
      const userId = cApplication?.user?.id;

      if (props.isAts) {
        if (
          cApplication?.interviews?.length > 0 &&
          cApplication?.interviews[0]?.offered
        ) {
          window.dataLayer.push({
            event: 'view-applicant-pipeline-offered',

            job_id: jobId,
            user_id: userId
          });
        }

        switch (cApplication?.state) {
          case 'undecided':
            window.dataLayer.push({
              event: 'view-applicant-pipeline-undecided',

              job_id: jobId,
              user_id: userId
            });
            break;
          case 'shortlisted':
            window.dataLayer.push({
              event: 'view-applicant-pipeline-shortlisted',

              job_id: jobId,
              user_id: userId
            });
            break;
          case 'kiv':
            window.dataLayer.push({
              event: 'view-applicant-pipeline-kiv',

              job_id: jobId,
              user_id: userId
            });
            break;
          case 'interview':
            if (
              cApplication?.interviews?.length > 0 &&
              !cApplication?.interviews[0].offered
            ) {
              window.dataLayer.push({
                event: 'view-applicant-pipeline-interview',

                job_id: jobId,
                user_id: userId
              });
            }
            break;
          case 'rejected':
            window.dataLayer.push({
              event: 'view-applicant-pipeline-rejected',
              job_id: jobId,
              user_id: userId
            });
            break;
        }
      } else {
        switch (props.applicationStatus) {
          case 'all':
            window.dataLayer.push({
              event: 'view-applicant-list-all',
              job_id: jobId,
              user_id: userId
            });
            break;
          case 'undecided':
            window.dataLayer.push({
              event: 'view-applicant-list-undecided',
              job_id: jobId,
              user_id: userId
            });
            break;
          case 'shortlisted':
            window.dataLayer.push({
              event: 'view-applicant-list-shortlisted',
              job_id: jobId,
              user_id: userId
            });
            break;
          case 'kiv':
            window.dataLayer.push({
              event: 'view-applicant-list-kiv',
              job_id: jobId,
              user_id: userId
            });
            break;
          case 'interview':
            window.dataLayer.push({
              event: 'view-applicant-list-interview',
              job_id: jobId,
              user_id: userId
            });
            break;
          case 'offer':
            window.dataLayer.push({
              event: 'view-applicant-list-offered',
              job_id: jobId,
              user_id: userId
            });
            break;
        }
      }

      // if (jobId && userId) {
      //   const eventName = props.isAts
      //     ? 'view_applicant_ats'
      //     : 'view_applicant_list';
      //   try {
      //     dataLayer.push({
      //       event: eventName,
      //       job_id: jobId,
      //       user_id: userId
      //     });
      //   } catch (error) {
      //     //
      //   }
      // }
    }
  }, []);

  return (
    <>
      <Grid>
        {props.isCandidateSnapshotOpen && (
          <>
            <AnimatedOverlay>
              <AnimatedContainer>
                {' '}
                {/* isAllAppPage={checkPage} */}
                <AnimatedResume style={contentProps}>
                  <CandidateSnapshotResume candidate={getCandidate(props)} />
                </AnimatedResume>
                <AnimatedBio style={contentProps2}>
                  {/* close button */}
                  <CloseIconContainer>
                    <CloseIconStyled onClick={onHandleHide} />
                  </CloseIconContainer>
                  {/* Photo placeholder and biodata */}
                  <InfoContainer>
                    <CandidateSnapshotBasicInfo
                      candidate={getCandidate(props)}
                      companyData={props.companyData}
                      hasPrevious={hasPrevious}
                      hasNext={hasNext}
                      previousCandidate={previousCandidate}
                      nextCandidate={nextCandidate}
                      loading={
                        props.loading ||
                        props.loadingUndecided ||
                        props.loadingKiv ||
                        props.loadingShortlisted ||
                        props.loadingRejected
                      }
                      changeJobApplicationStatus={
                        props.changeJobApplicationStatus
                      }
                      changeApplicationState={props.changeApplicationState}
                      setBlackListState={props.setBlackListState}
                      setIsOfferJobOpen={setIsOfferJobOpen}
                      setListApplicationState={props.setListApplicationState}
                      blackListState={props.blackListState}
                      isAts={props.isAts}
                      updateApplicationState={props.updateApplicationState}
                      pageName={props.pageName}
                    />
                    <CandidateSnapshotRichInfo
                      isAts={props.isAts}
                      candidate={getCandidate(props)}
                      setCandidates={props.setCandidates}
                      setCurrentCandidates={props.setCurrentCandidates}
                      candidates={props.candidates}
                      updateCandidateList={props.updateCandidateList}
                      hideCloseLoopSection={
                        getApplication(getCandidate(props))?.state !==
                        'shortlisted'
                      }
                      pageName={props.pageName}
                    />
                  </InfoContainer>
                  {/* Close loop */}
                  {props.pageName != 'new-applicant-page' &&
                  getApplication(getCandidate(props))?.state !== 'undecided' &&
                  getApplication(getCandidate(props))?.state !== 'rejected' &&
                  getApplication(getCandidate(props))?.employerHiredStatus ===
                    null ? (
                    <CloseLoopContainer>
                      <CloseLoop
                        setIsCloseLoopOpen={setIsCloseLoopOpen}
                        page={'candidate_snapshot'}
                      />
                    </CloseLoopContainer>
                  ) : null}

                  {/* CloseLoopConfirmationModal */}
                  <CloseLoopPopUp
                    isCloseLoopOpen={isCloseLoopOpen}
                    handleCloseLoopPopUpClose={handleCloseLoopPopUpClose}
                    closingApplicationLoop={closingApplicationLoop}
                    isClosingLoop={props.isClosingLoop}
                    candidate={getCandidate(props)}
                    loopError={loopError}
                    setLoopError={setLoopError}
                  />
                  <OfferJobPopUp
                    isOfferJobOpen={isOfferJobOpen}
                    handleOfferJobClose={handleOfferJobClose}
                    interview={getCandidate(props)}
                    setCandidates={props.setCandidates}
                    setCurrentCandidates={props.setCurrentCandidates}
                    candidates={props.candidates}
                    setIsSelected={props.setIsSelected}
                    moveApplicationCountToOffer={moveApplicationCountToOffer}
                  />
                </AnimatedBio>
              </AnimatedContainer>
            </AnimatedOverlay>
          </>
        )}
      </Grid>
    </>
  );
});

const mapStateToProps = state => {
  return {
    isCandidateSnapshotOpen: state.applications.isCandidateSnapshotOpen,
    currentCandidateIndex: state.applications.currentCandidateIndex,
    allApplications: state.applications.allApplications,
    loadingUndecided: state.applications.fetchingUndecidedApplications,
    loadingKiv: state.applications.fetchingKivApplications,
    loadingShortlisted: state.applications.fetchingShortlistedApplications,
    loadingRejected: state.applications.fetchingRejectedApplications,
    isClosingLoop: state.applications.closingLoop
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateCandidateSnapshotStatus: bindActionCreators(
      updateCandidateSnapshotStatus,
      dispatch
    ),
    updateCurrentCandidateIndex: bindActionCreators(
      updateCurrentCandidateIndex,
      dispatch
    ),
    closingLoop: bindActionCreators(closingLoop, dispatch),
    updateInterviewStatus: bindActionCreators(updateInterviewStatus, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(CandidateSnapshot);
