import { styled as styling } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Colors from '../../../../styles/colors.module.scss';
import {
  DialogTitle,
  DialogContent,
  IconButton,
  Checkbox
} from '@mui/material';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

export const Text = styling('p')(props => ({
  fontFamilly: 'Inter',
  ...(props.deactivate_free_internship == 'true' || props.$deactivate_job_credit
    ? {
        fontSize: '14px',
        margin: '10px auto 15px auto',
        fontWeight: 700,
        textAlign: 'center',
        color: Colors.secOrange
      }
    : {})
}));

export const MainContainer = styling('div')(props => ({
  width: '100%'
}));

export const DialogTitleStyled = styling(DialogTitle)(props => ({
  padding: '16px 16px 16px 24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px'
}));

export const IconButtonStyled = styling(IconButton)(props => ({
  padding: '0'
}));

export const DialogContentStyled = styling(DialogContent)(props => ({
  padding: '0px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  maxWidth: '685px'
}));

export const DialogNoteStyled = styling(DialogContent)(props => ({
  padding: '0px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const ExpandableBarTitle = styling('div')(props => ({
  display: 'flex',
  padding: '16px 24px 0px 24px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px'
  // maxWidth: '597px'
}));

export const Title = styling('div')(props => ({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const ExpandedContent = styling('div')(props => ({
  display: 'flex',
  padding: '16px 24px 0px 24px',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  alignSelf: 'stretch'
}));

export const CheckboxSection = styling('div')(props => ({
  display: 'flex',
  padding: '16px 24px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
  alignSelf: 'stretch'
}));

export const BottomBox = styling('div')(props => ({
  display: 'flex',
  padding: '16px 0px 20px 0px',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '16px',
  alignSelf: 'stretch'
}));

export const SkipButton = styling('div')(props => ({
  display: 'flex',
  width: '200px',
  height: '42px',
  padding: '0px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: 'normal',
  cursor: 'pointer'
}));

export const SaveButton = styling(Button)(props => ({
  display: 'flex',
  width: '200px',
  height: '42px',
  padding: '0px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  background: props.disabled ? '#D8D8D8' : '#353535',

  gap: '10px',
  color: '#FFF',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: 'normal',
  cursor: 'pointer',
  textTransform: 'none',

  ':disabled': {
    cursor: 'not-allowed',
    color: '#FFF',
    ':hover': {
      background: '#D8D8D8'
    },
  },

  ':hover': {
    background: '#222222'
  },
}));

export const CheckboxText = styling('div')(props => ({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: '400px',
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const CheckboxStyled = styling(Checkbox)(props => ({
  padding: '0px',
  margin: '4px 0px',
  color: 'transparent',
  overflow: 'hidden',
  '&.MuiCheckbox-root': {
    borderRadius: '4px',
    border: `1px solid ${props?.isError ? '#F75443' : '#000'}`,
    width: 16,
    height: 16
  },
  '& .MuiSvgIcon-root': {
    fontSize: '18px'
  },

  '&.Mui-checked': {
    color: 'black',
    width: 16,
    height: 16
  },

  '&.Mui-disabled': {
    border: '1px solid #D8D8D8',
    color: '#D8D8D8',
    borderRadius: '4px',
    width: 16,
    height: 16
  },

  '&.MuiCheckbox-indeterminate': {
    color: 'black',
    width: 16,
    height: 16
  },

  '&.MuiCheckbox-root:hover': {
    backgroundColor: 'transparent'
  },
  '& .MuiSvgIcon-root': {
    borderRadius: '4px'
  }
}));

export const InfoBox = styling('div')(props => ({
  display: 'flex',
  padding: '16px',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch',
  borderRadius: '4px',
  background: '#EFEFFD'
}));

export const ExclamationTriangleIconStyled = styling(ExclamationTriangleIcon)(
  props => ({
    display: 'flex',
    width: '20px',
    padding: '6px 2px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '10px'
  })
);

export const InfoText = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '2px',
  flex: '1 0 0',

  '& p': {
    margin: '0',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.15px'
  },

  '& ul': {
    margin: '0',
    paddingLeft: '20px'
  },

  '& li': {
    margin: '0',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    paddingLeft: '5px'
  }
}));
