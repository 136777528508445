import React, { useEffect } from 'react';
import { useFormContext } from '../../context';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSuitcase, faUser } from '@fortawesome/free-solid-svg-icons';
import { useFormData } from '../../helper/useFormData';
import { updateSpecialisationSubcategories } from '../../../../../redux/actions/job_action';
import { SimpleTipsTooltip } from '../../../../../components/Tooltips';
import { SimpleDescription } from './styles';
import { isEmpty } from 'lodash';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import FormSpecialisation from '../../../../../components/FormSpecialisation';

export default function Specialization() {
  const key = 'tracks';
  const dispatch = useDispatch();
  const formData = useFormData();
  const { formState, handleSingleFieldChange, errorState, handleErrorState } =
    useFormContext();
  const specialisation = useSelector(state => state?.jobs?.specialisation);

  useEffect(() => {
    dispatch(updateSpecialisationSubcategories(formState?.tracks));
  }, [formData, formState.tracks]);

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0 0 20px',
        width: '100%'
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          gap: '15px'
        }}>
        <FontAwesomeIcon icon={faSuitcase} sx={{ marginRight: '15px' }} />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%'
          }}>
          <Grid style={{ width: 'calc(100% - 50px)' }}>
            <FormSpecialisation
              formState={formState}
              getSpecializationData={specialisation}
              setFieldValue={handleSingleFieldChange}
              handleErrorState={handleErrorState}
              errorState={errorState}
              key={key}
              isError={errorState.includes(key)}
            />
          </Grid>

          <SimpleTipsTooltip
            top={'-110px'}
            left={'-200px'}
            width={'300px'}
            padding={'10px 16px'}
            description={
              <SimpleDescription>
                Choose the main specialisation first followed by additional
                ones. Make sure they are relevant to the job title to avoid
                confusing jobseekers.
              </SimpleDescription>
            }
          />
        </div>
      </div>

      {errorState.includes(key) && (
        <p
          style={{
            color: '#BE4242',
            fontSize: '12px',
            lineHeight: '20px',
            display: 'flex',
            padding: '0px 8px',
            alignItems: 'center',
            gap: '8px',
            marginLeft: '30px'
          }}>
          <ExclamationCircleIcon
            style={{
              width: '20px',
              height: '20px',
              color: '#BE4242'
            }}
          />
          Specialization is required
        </p>
      )}
    </Grid>
  );
}
