import * as types from '../types/chat_type';
import api from '../../utils/api';
const { REACT_APP_API_VERSION } = process.env;

const fetchTalentChats =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_TALENT_CHATS
    });

    const queryInput = `search: "${
      params.keyword ? params.keyword : '*'
    }", after: ${JSON.stringify(params.after)}, first: 10,
      sort: { by: "${params.sort.by}", direction: "${params.sort.direction}" }`;
    const payload = {
      query: `{
        talentSearchChatRoomListings(${queryInput}) {     
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }    
          edges {
            node {
              id
              employerReadReceipt
              sendbirdChannelUrl
              userId     
              createdAt
              user {
                id
                name
                nationality
                shortSummary                
                email
                mobileNumber                
                currentCompany
                fieldOfStudy
                major
                highestEducationLevel
                educationalInstitution
                graduationYear
                age
                profileImageSquare
                trackIds                
                resume                
                lastActiveAt
                languages
                skills
                bookmarked
                hidden 
                unlocked
                companyResumeId
                resumePrice
                freshGrad
                companyResumeSendbirdChannelUrl
                companyResumeChatStatus
                unlockedAt
                workingExperiences {
                  totalCount
                  edges {
                    node {
                      id
                      companyName
                      jobTitle
                      description
                      specialization
                      industry {
                        id
                        title
                      }
                      startDateMonth
                      startDateYear
                      endDateMonth
                      endDateYear
                      currentWork
                      createdAt
                      updatedAt
                    }
                  }
                }
              }             
            }            
          }
        }
       }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response.data?.data?.talentSearchChatRoomListings) {
            const { talentSearchChatRoomListings } = response.data.data;

            return dispatch({
              type: types.FETCH_TALENT_CHATS_SUCCEED,
              chats: talentSearchChatRoomListings,
              pageInfo: talentSearchChatRoomListings?.pageInfo,
              refresh: params.refresh
            });
          }
        }
        return dispatch({
          type: types.FETCH_TALENT_CHATS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_TALENT_CHATS_FAILED
        });
      });
  };

const updateTalentSearchChatStatus = status => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_TALENT_SEARCH_CHAT_STATUS,
        status: status
      })
    );
  });
};

const updateTalentChats = chats => async dispatch => {
  return new Promise(resolve => {
    return resolve(
      dispatch({
        type: types.UPDATE_TALENT_CHATS,
        chats: chats
      })
    );
  });
};

const updateChatReadStatus =
  (params = {}) =>
  async dispatch => {
    const payload = {
      query: `
      mutation {
        updateChatReadStatus(input: {id: "${params.chatId}", readStatus: true, chatType: "${params.chatType}"}) {
          success
          company {
            totalUnreadChatCount
          }
          job {
            id
            unreadJobApplicationChatCount
          }
        }
      }      
      `
    };

    const response = await api.apiCall(
      `${REACT_APP_API_VERSION}/graphql`,
      params,
      payload
    );

    if (response.status === 200) {
      const { company, job } = response?.data?.data?.updateChatReadStatus;

      return dispatch({
        type: types.UPDATE_CHAT_READ_STATUS_SUCCESS,
        success: true,
        companyUnread: company.totalUnreadChatCount,
        jobUnreadId: job.id,
        jobUnread: job.unreadJobApplicationChatCount
      });
    }

    return dispatch({
      type: types.UPDATE_CHAT_READ_STATUS_FAIL,
      success: false
    });
  };

const checkCompanyUnreadCount =
  (params = {}) =>
  async dispatch => {
    const payload = {
      query: `
        {
          myCompany {
            totalUnreadChatCount
          }
        }    
    `
    };

    const response = await api.apiCall(
      `${REACT_APP_API_VERSION}/graphql`,
      params ?? {},
      payload
    );

    if (response.status === 200) {
      const { totalUnreadChatCount } = response?.data?.data?.myCompany;

      return dispatch({
        type: types.UPDATE_COMPANY_CHAT_UNREAD_COUNT,
        count: totalUnreadChatCount
      });
    }

    return dispatch({
      type: 'UPDATE_COMPANY_CHAT_UNREAD_COUNT_FAIL'
    });
  };

const fetchJobChats =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.FETCH_JOB_CHATS
    });

    const payload = {
      query: `{
        chatRoomListings(
          first: ${params.first}
          jobId: "${params.jobId}"
          after: "${params.after}"
          search: "${params.search}"
          chatStatus: ${params.chatStatus}
          chatbotStatus: ${params.chatBotStatus}
          sort: { by: "${params?.sort?.by}", direction: "${params?.sort?.direction}" }
        ) {
          pageInfo {
            startCursor
            endCursor
          }
          edges {
            node {
              id
              state
              status
              applicationStatus
              appliedAt
              employerReadReceipt
              user {
                id
                name
                shortSummary
              }
              job {
                id
              }
              chatStatus
              chatbotStatus
              sendbirdChannelUrl
              lastMessage
              lastMessageAt
            }
          }
        }
      }
      `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.chatRoomListings) {
            const chatRoomListings = response?.data?.data?.chatRoomListings;
            const pageInfo = response?.data?.data?.chatRoomListings?.pageInfo;
            const totalCount =
              response?.data?.data?.chatRoomListings?.totalCount;

            return dispatch({
              type: types.FETCH_JOB_CHATS_SUCCEED,
              chatRoomListings: chatRoomListings,
              pageInfo: pageInfo,
              totalCount: totalCount
            });
          }
        }
        return dispatch({
          type: types.FETCH_JOB_CHATS_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.FETCH_JOB_CHATS_FAILED
        });
      });
  };

const mutateSendBirdChannelUrl =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.MUTATE_SENDBIRD_CHANNEL_URL
    });

    const data = params.input;

    const input = `
    ${data.id ? `id: "${data.id}",` : ''}
    ${data.channelUrl ? `channelUrl: "${data.channelUrl}",` : ''}
  `;

    const payload = {
      query: `mutation {
        changeSendbirdChannelUrl(input: {${input}}) {
          jobApplication {
            id
            status
            state
            applicationStatus
            appliedAt
            sendbirdChannelUrl
            botContext
            chatStatus
            chatbotStatus
            unread
            lastMessage
            lastMessageAt
            user {
              blacklist
              id
              name
              shortSummary
              nationality
              gender
              email
              mobileNumber
              currentCompany
              fieldOfStudy
              major
              highestEducationLevel
              educationalInstitution
              graduationYear
              expectedSalary
              age
              profileImage
              profileImageSquare
              trackIds
              experienceIds
              stateRegionNames
              videoCv
              videoCvOptimized
            }
            job {
              title
            }
          }
        }
     }
    `
    };

    return api
      .apiCall(`${REACT_APP_API_VERSION}/graphql`, params, payload)
      .then(async response => {
        if (response.status === 200) {
          if (response?.data?.data?.changeSendbirdChannelUrl) {
            const jobApplication =
              response?.data?.data?.changeSendbirdChannelUrl?.jobApplication;
            return dispatch({
              type: types.MUTATE_SENDBIRD_CHANNEL_URL_SUCCEED,
              jobApplication: jobApplication
            });
          }
        }
        return dispatch({
          type: types.MUTATE_SENDBIRD_CHANNEL_URL_FAILED
        });
      })
      .catch(error => {
        return dispatch({
          type: types.MUTATE_SENDBIRD_CHANNEL_URL_FAILED,
          error: error
        });
      });
  };

export {
  updateTalentSearchChatStatus,
  updateTalentChats,
  fetchTalentChats,
  updateChatReadStatus,
  checkCompanyUnreadCount,
  fetchJobChats,
  mutateSendBirdChannelUrl
};
