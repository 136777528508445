import { styled as styling } from '@mui/material/styles';

export const FirstContainer = styling('div')({
  padding: '10px 0 20px',
  textAlign: 'center',
});

export const SecondContainer = styling('div')({
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
});

export const ButtonWrapper = styling('div')({
  display: 'flex',
});

export const ButtonContainer = styling('div')({
  paddingRight: '10px',
});

export const Text = styling('p')((props) => ({
  ...(props.type == 'title' && {
    margin: 'auto 5px auto 0',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Inter',
    display: 'inline',
  }),

  ...(props.type == 'first_state' && {
    margin: 'auto 5px auto 0',
    fontSize: '16px',
    fontWeight: 700,
    fontFamily: 'Inter',
    display: 'inline',
  }),

  ...(props.type == 'basic' && {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Inter',
    display: 'inline',
  }),

  ...(props.type == 'second_state' && {
    margin: 'auto 0 auto 0',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Inter',
    display: 'inline',
    color: '#ffa329',
  }),
}));

export const TextContainer = styling('div')({
  margin: '10px 0 0',
});

