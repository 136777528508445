import React, { useEffect, useState } from 'react';
import { IconButton, Grid } from '@mui/material';
import * as yup from 'yup';
import { Skeleton } from '@mui/material';
import { Card, Spinner } from '../../../components';
import { ReactComponent as EditIcon } from '../../../assets/common/edit-icon.svg';
import styles from './index.module.scss';
import { ErrorToast, SuccessToast } from '../../../utils/ToastUtils';
import { MUIButtonStyled } from '../../../components/MUIButton';
import {
  fetchCompanySizes,
  fetchIndustries,
  fetchCompanyTypes,
  mutateCompanyDetails,
  fetchAccountInfo
} from '../../../redux/actions/company_actions';
import { useDispatch, useSelector } from 'react-redux';
import { useIdFinder } from '../../../utils/ValueFinder';
import SharedTextField from '../../../components/SharedTextField';
import SharedSelectField from '../../../components/SharedSelectField';

const CompanyDetailsCard = ({ data, loading }) => {
  const dispatch = useDispatch();
  const [toggleEdit, setToggleEdit] = useState(false);
  const [mutating, setMutating] = useState(false);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState();

  useEffect(() => {
    dispatch(fetchIndustries());
    dispatch(fetchCompanySizes());
    dispatch(fetchCompanyTypes());
  }, []);

  useEffect(() => {
    if (data) {
      setValues({
        companyDisplayName: data.name || '',
        companyRegisteredName: data.registerName || '',
        industry: data.industryId || '',
        noOfEmployees: data.sizeId || '',
        companyType: data.typeId || '',
        companyWebsite: data.website || '',
        emailToReceiveApplications: data.emailToReceiveApplications || ''
      });
    }
  }, [data]);

  const industries = useSelector(state => state.companies.industries);
  const companySizes = useSelector(state => state.companies.companySizes);
  const companyTypes = useSelector(state => state.companies.companyTypes);
  const fetchingCompanySettings = useSelector(
    state => state.companies.fetchingCompanySettings
  );

  const validationSchema = yup.object().shape({
    companyDisplayName: yup
      .string()
      .required('Company Display Name is required'),
    companyRegisteredName: yup
      .string()
      .required('Registered Company Name is required'),
    industry: yup.string().required('Industry is required'),
    noOfEmployees: yup.string().required('No. of employees is required'),
    companyType: yup.string().required('Company type is required'),
    companyWebsite: yup
      .string()
      .url(
        'Company website must be a valid URL that starts with http://, https:// or ftp://.'
      ),
    emailToReceiveApplications: yup
      .string()
      .required('email to receive applications is required')
  });

  const _onToggleEdit = () => {
    setToggleEdit(!toggleEdit);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: '' });
    setValues({ ...values, [name]: value });
  };

  const onSubmitCompanyDetails = async values => {
    setMutating(true);
    dispatch(
      mutateCompanyDetails({
        input: {
          name: values.companyDisplayName,
          registerName: values.companyRegisteredName,
          industryId: Number(values.industry),
          sizeId: Number(values.noOfEmployees),
          typeId: Number(values.companyType),
          website: values.companyWebsite || '',
          emailToReceiveApplications: values.emailToReceiveApplications
        }
      })
    )
      .then(res => {
        _onToggleEdit();

        if (res.type === 'FETCH_MY_COMPANY_SETTINGS_SUCCEED') {
          SuccessToast('Company Details Updated Successfully!');

          dispatch(fetchAccountInfo());
        } else {
          ErrorToast('Company Details Update Failed');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        ErrorToast('Failed to update company details.');
        setMutating(false);
      })
      .finally(() => {
        setMutating(false);
      });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      await validationSchema.validate(values, { abortEarly: false });
      onSubmitCompanyDetails(values);
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach(err => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    }
  };

  return (
    <Card style={{ width: '30rem', margin: 30 }}>
      <div className={styles.subtitleContainer}>
        <div className={styles.subtitle}>Company Details</div>
        <IconButton
          onClick={() => _onToggleEdit()}
          className={styles.imageContainer}
          disabled={toggleEdit}
          style={{ backgroundColor: 'transparent', width: 17 }}>
          {!toggleEdit && <EditIcon width={17} />}
        </IconButton>
      </div>

      <div>
        {toggleEdit ? (
          <form onSubmit={handleSubmit}>
            <Grid>
              <div className={styles.row}>
                <SharedTextField
                  name="companyDisplayName"
                  placeholder="Company Display Name"
                  value={values.companyDisplayName}
                  error={errors.companyDisplayName}
                  helperText={errors.companyDisplayName}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.row}>
                <SharedTextField
                  name="companyRegisteredName"
                  placeholder="Registered Company Name"
                  value={values.companyRegisteredName}
                  error={errors.companyRegisteredName}
                  helperText={errors.companyRegisteredName}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.row}>
                <SharedSelectField
                  name="industry"
                  placeholder="Industry"
                  options={industries}
                  value={values.industry}
                  error={errors.industry}
                  helperText={errors.industry}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.row}>
                <SharedSelectField
                  name="noOfEmployees"
                  placeholder="No. of Employees"
                  options={companySizes}
                  value={values.noOfEmployees}
                  error={errors.noOfEmployees}
                  helperText={errors.noOfEmployees}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.row}>
                <SharedSelectField
                  name="companyType"
                  placeholder="Company Type"
                  options={companyTypes}
                  value={values.companyType}
                  error={errors.companyType}
                  helperText={errors.companyType}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.row}>
                <SharedTextField
                  name="companyWebsite"
                  placeholder="Company Website"
                  value={values.companyWebsite}
                  error={errors.companyWebsite}
                  helperText={errors.companyWebsite}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.row}>
                <SharedTextField
                  name="emailToReceiveApplications"
                  placeholder="Email to Receive Applications"
                  value={values.emailToReceiveApplications}
                  error={errors.emailToReceiveApplications}
                  helperText={errors.emailToReceiveApplications}
                  onChange={handleChange}
                />
              </div>

              {fetchingCompanySettings && mutating ? (
                <Spinner />
              ) : (
                <div className={styles.buttonContainer}>
                  <MUIButtonStyled
                    solidWhite
                    medium
                    label="Cancel"
                    onClick={() => _onToggleEdit()}
                  />

                  <MUIButtonStyled
                    sx={{ width: '150px' }}
                    label="Save"
                    type="submit"
                  />
                </div>
              )}
            </Grid>
          </form>
        ) : (
          <div>
            <div className={styles.labelWrapper}>
              <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                {loading ? (
                  <div>
                    <Skeleton variant="text" width={200} />
                    <Skeleton variant="text" width={150} />
                  </div>
                ) : (
                  <div>
                    <h6 className={styles.labelTitle}>Company Display Name</h6>
                    <div className={styles.labelValue}>{data?.name}</div>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.labelWrapper}>
              <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                {loading ? (
                  <div style={{ paddingBottom: 10 }}>
                    <Skeleton variant="text" width={200} />
                    <Skeleton variant="text" width={150} />
                  </div>
                ) : (
                  <div>
                    <h6 className={styles.labelTitle}>
                      Registered Company Name
                    </h6>
                    <div className={styles.labelValue}>
                      {data?.registerName}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {loading ? (
              <div className={styles.skeletonContainer}>
                <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={150} />
              </div>
            ) : (
              <div className={styles.labelWrapper}>
                <div className={styles.labelTitle}>Industry</div>
                <div className={styles.labelValue}>
                  {useIdFinder({
                    options: industries,
                    id: data?.industryId
                  })}
                </div>
              </div>
            )}
            {loading ? (
              <div className={styles.skeletonContainer}>
                <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={150} />
              </div>
            ) : (
              <div className={styles.labelWrapper}>
                <div className={styles.labelTitle}>No. of Employees</div>
                <div className={styles.labelValue}>
                  {useIdFinder({
                    options: companySizes,
                    id: data?.sizeId
                  })}
                </div>
              </div>
            )}
            {loading ? (
              <div className={styles.skeletonContainer}>
                <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={150} />
              </div>
            ) : (
              <div className={styles.labelWrapper}>
                <div className={styles.labelTitle}>Company Type</div>
                <div className={styles.labelValue}>
                  {useIdFinder({
                    options: companyTypes,
                    id: data?.typeId
                  })}
                </div>
              </div>
            )}
            {loading ? (
              <div className={styles.skeletonContainer}>
                <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={150} />
              </div>
            ) : (
              <div className={styles.labelWrapper}>
                <div className={styles.labelTitle}>Company Website</div>
                <div className={styles.labelValue}>{data?.website}</div>
              </div>
            )}

            {loading ? (
              <div className={styles.skeletonContainer}>
                <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={150} />
              </div>
            ) : (
              <div className={styles.labelWrapper}>
                <div className={styles.labelTitle}>
                  Email to Receive Applications
                </div>
                <div className={styles.labelValue}>
                  {data?.emailToReceiveApplications}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Card>
  );
};

export default CompanyDetailsCard;
