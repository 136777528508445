import React, { useEffect, useState } from 'react';
import {
  Content,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
  DrawerStyled,
  IconButtonStyled,
  JobTypeContainer,
  TitleStyled,
  TypeContainer
} from './styles';
import { XMarkIcon as CloseIcon } from '@heroicons/react/24/outline';
import { BundlesCardSection } from './BundlesCardSection';
import { TypeSelection } from './TypeSelection';
import {
  fetchProductsByCategory,
  updateProductBundle
} from '../../../../redux/actions/product_action';
import { useDispatch, useSelector } from 'react-redux';
import {
  RectangleGroupIcon,
  HeartIcon,
  FilmIcon
} from '@heroicons/react/24/solid';
import { Drawer } from '@mui/material';
import {
  getCurrency,
  getCurrenyCode
} from '../../../../hooks/useRegionalisation';

export default function BundlesOverlay(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProductsByCategory());
  }, []);

  const selectedBundle = useSelector(
    state => state.products.selectedProductBundle
  );

  const bundles = [
    {
      id: 1,
      productCode: 'job_slot',
      type: 'Job Slots',
      typeDescription: '',
      bundleTitle:
        'For employers who want flexible hiring at an #affordable cost#.',
      bundleSubTitle: 'Save more with our bundles:',
      bundles: [
        {
          amount: 1,
          price: `${getCurrency(300)} `,
          save: null,
          annual: getCurrency(3600)
        },
        {
          amount: 3,
          price: `${getCurrency(278)} `,
          save: '8%',
          annual: getCurrency(10000)
        },
        {
          amount: 5,
          price: `${getCurrency(250)} `,
          save: '20%',
          annual: getCurrency(15000)
        }
      ],
      detailsChip: 'Job Slot Plus',
      detailsTitle:
        'For employers looking to *transform recruitment* and enjoy *premium service*.',
      detailsSubTitle: `Check out these additional features from *only #${getCurrenyCode()} [450]# / month* with *Job Slot Plus*!`,
      detailsList: [
        '#*1.5X job ad performance*# across impressions, views and applications',
        'Enhanced performance with automated job ad'
      ]
    },
    {
      id: 2,
      productCode: 'job_slot_plus',
      type: 'Job Slot Plus',
      prefix: 'Plus',
      typeDescription: '1.5X Job Ad Performance',
      bundleTitle:
        'For employers looking to #transform recruitment# and enjoy #premium service#.',
      bundleSubTitle: 'Save more with our bundles:',
      bundles: [
        {
          amount: 1,
          price: `${getCurrency(400)} `,
          save: null,
          annual: `${getCurrency(4000)}`
        },
        {
          amount: 3,
          price: `${getCurrency(361)} `,
          save: '11%',
          annual: `${getCurrency(13000)}`
        },
        {
          amount: 5,
          price: `${getCurrency(333)} `,
          save: '20%',
          annual: `${getCurrency(20000)} `
        }
      ],
      detailsChip: 'Job Slot Max',
      detailsTitle:
        'For employers seeking *red carpet service* and wish to *stand out* from competitors.',
      detailsSubTitle: `Check out these additional features from *only #${getCurrenyCode()} [750]# / month* with *Job Slot Max*!`,
      detailsList: [
        '#*2.2X*# job ad performance across impressions, views and applications',
        'Dedicated customer support',
        'Exclusive job ad design - _outshine the competition!_'
      ]
    },
    {
      id: 3,
      productCode: 'job_slot_max',
      type: 'Job Slot Max',
      prefix: 'Max',
      typeDescription: '2.2X Job Ad Performances',
      bundleTitle:
        'For employers seeking #red carpet service# and wish to #stand out# from competitors.',
      bundleSubTitle: 'Save more with our bundles:',
      bundles: [
        {
          amount: 1,
          price: `${getCurrency(600)} `,
          save: null,
          annual: `${getCurrency(7200)} `
        },
        {
          amount: 3,
          price: `${getCurrency(556)} `,
          save: '8%',
          annual: `${getCurrency(20000)} `
        },
        {
          amount: 5,
          price: `${getCurrency(500)} `,
          save: '20%',
          annual: `${getCurrency(30000)} `
        }
      ],
      detailsChip: 'Personalised',
      detailsTitle: 'Still unsure on what you need?',
      detailsSubTitle: '*#Personalise# a solution to fit your hiring needs*',
      detailsList: []
    },
    {
      id: 4,
      type: 'Personalised',
      typeDescription: '',
      bundleTitle: '*#Personalise# a solution to fit your hiring needs.*',
      bundleSubTitle: 'What we offer:',
      info: [
        {
          text: '*Mix and match* what you want on your job ad space',
          icon: (
            <RectangleGroupIcon
              style={{ width: '40px', height: '40px', color: '#512ACC' }}
            />
          )
        },
        {
          text: 'Make your company attractive to top talents with *enticing content*',
          icon: (
            <HeartIcon
              style={{ width: '40px', height: '40px', color: '#512ACC' }}
            />
          )
        },
        {
          text: 'Don’t have a photographer/ videographer? Leave all the *creative* stuff to us',
          icon: (
            <FilmIcon
              style={{ width: '40px', height: '40px', color: '#512ACC' }}
            />
          )
        }
      ],

      detailsChip: 'Why personalise with us?',
      detailsSubTitle:
        'Customise our solutions to fit your unique hiring needs to create a *#personalised#* recruitment experience.',
      detailsList: []
    }
  ];

  return (
    <DrawerStyled
      anchor="bottom"
      open={selectedBundle > 0}
      onClose={() => dispatch(updateProductBundle(0))}>
      <DialogTitleStyled>
        <TitleStyled>Job Ads Made Simple</TitleStyled>
      </DialogTitleStyled>
      <IconButtonStyled onClick={() => dispatch(updateProductBundle(0))}>
        <CloseIcon />
      </IconButtonStyled>

      <DialogContentStyled dividers>
        <Content>
          {/* Job type */}
          <JobTypeContainer>
            <TypeContainer>
              {bundles.map(bundle => {
                return (
                  <TypeSelection
                    id={bundle.id}
                    bundle={bundle.type}
                    bundleDescription={bundle.typeDescription}
                  />
                );
              })}
            </TypeContainer>
          </JobTypeContainer>

          {/* Bundle card section */}
          {bundles.map(bundle => {
            return <BundlesCardSection bundle={bundle} />;
          })}
        </Content>
      </DialogContentStyled>
    </DrawerStyled>
  );
}
