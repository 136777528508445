import { styled as styling } from '@mui/material/styles';
import {
  DialogTitle,
  DialogContent,
  IconButton,
  Checkbox
} from '@mui/material';
import Button from '@mui/material/Button';

export const FirstContainer = styling('div')({
  padding: '10px 0 20px',
  textAlign: 'center'
});

export const SecondContainer = styling('div')({
  padding: '20px',
  display: 'flex',
  justifyContent: 'center'
});

export const ButtonWrapper = styling('div')({
  display: 'flex'
});

export const ButtonContainer = styling('div')({
  paddingRight: '10px'
});

export const Text = styling('p')(props => ({
  ...(props.type == 'title' && {
    margin: 'auto 5px auto 0',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Inter',
    display: 'inline'
  }),

  ...(props.type == 'name' && {
    margin: 'auto 5px auto 0',
    fontSize: '16px',
    fontWeight: 700,
    fontFamily: 'Inter',
    display: 'inline'
  }),

  ...(props.type == 'basic' && {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Inter',
    display: 'inline'
  }),

  ...(props.type == 'attention' && {
    margin: 'auto 5px auto 0',
    fontSize: '16px',
    fontWeight: 700,
    fontFamily: 'Inter',
    display: 'inline'
  })
}));

export const TextContainer = styling('div')({
  margin: '10px 0 0'
});

export const MainContainer = styling('div')(props => ({
  width: '100%'
}));

export const DialogTitleStyled = styling(DialogTitle)(props => ({
  padding: '16px 16px 16px 24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px'
}));

export const IconButtonStyled = styling(IconButton)(props => ({
  padding: '0'
}));

export const DialogContentStyled = styling(DialogContent)(props => ({
  padding: '0px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  maxWidth: '685px',
  minHeight: '96px'
}));

export const DialogNoteStyled = styling(DialogContent)(props => ({
  padding: '0',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const ExpandableBarTitle = styling('div')(props => ({
  display: 'flex',
  padding: '16px 24px',
  flexDirection: 'column',
  alignItems: 'flex-start'
  // gap: '16px'
  // maxWidth: '597px'
}));

export const Title = styling('div')(props => ({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  minHeight: '48px'
}));

export const ExpandedContent = styling('div')(props => ({
  display: 'flex',
  padding: '16px 24px 0px 24px',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  alignSelf: 'stretch'
}));

export const BottomBox = styling('div')(props => ({
  display: 'flex',
  padding: '8px 24px 24px 24px',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  gap: '16px',
  alignSelf: 'stretch'
}));

export const SkipButton = styling('div')(props => ({
  display: 'flex',
  width: '80px',
  height: '36px',
  padding: '0px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  cursor: 'pointer',
  borderRadius: '8px',
  border: '1px solid #222'
}));

export const SaveButton = styling(Button)(props => ({
  display: 'flex',
  minWidth: props.width ? props.width : '80px',
  height: '36px',
  padding: '0px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  background: props.disabled ? '#D8D8D8' : '#353535',

  gap: '10px',
  color: '#FFF',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  cursor: 'pointer',
  textTransform: 'none',

  ':disabled': {
    cursor: 'not-allowed',
    color: '#FFF',
    ':hover': {
      background: '#D8D8D8'
    }
  },

  ':hover': {
    background: '#222222'
  }
}));

export const Loader = styling('div')(props => ({
  display: 'flex',
  padding: '12px 16px',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '12px',
  alignSelf: 'stretch',
  borderRadius: '4px',
  border: props?.isValid
    ? '1px solid #EFEFEF'
    : '1px solid rgba(144, 185, 56, 0.4)',
  background: '#F5F5F5',
  color: props?.isValid ? '#40249B' : 'rgba(0, 0, 0, 0.87)',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  position: 'relative',
  justifyContent: 'space-between'
}));

export const ErrorMessage = styling('div')(props => ({
  display: 'flex',
  padding: '8px 24px',
  alignItems: 'center',
  gap: '8px',
  alignSelf: 'stretch',
  borderRadius: '4px',
  background: '#FEE7E5',

  '& p': {
    color: 'rgba(0, 0, 0, 0.60)',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    padding: '0px',
    margin: '0px',
    width: '90%'
  },

  '& a': {
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}));
