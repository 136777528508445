import { styled as styling } from '@mui/material/styles';

export const ContentDescription = styling('span')({
  fontFamily: 'Inter',
  fontSize: '16px',
  letterSpacing: '0.15px',
});

export const ContentInfo = styling('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
  width: '100%',
  height: '263px',
  borderRadius: '8px',
  background: '#F5F5F5',
});

export const GifContainer = styling('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '231px',
  minWidth: '231px',
  height: '231px',
});

export const ExplainerContainer = styling('div')({
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '16px',
  width: '401px',
  maxWidth: '401px',
  height: '231px',
});

export const ExplainerTitle = styling('span')({
  fontWeight: 700,
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.1px',
  width: '100%',
  textAlign: 'left',
});

export const ExplainerInfoWrapper = styling('ul')({
  marginTop: '10px',
  marginBottom: '10px',
  listStyleType: 'disc',
  paddingLeft: '20px',
});

export const ExplainerInfo = styling('li')((props) => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  ...(props.margin_top == 'true' && {
    marginTop: '24px',
  }),
}));

export const ExplainerNote = styling('span')({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '10px',
  lineHeight: '16px',
  color: 'rgba(0, 0, 0, 0.6)',
});