import { styled as styling } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FontAwesomeIconStyled = styling(FontAwesomeIcon)(props => ({
  ...(props.awesome_icon == 'faCalenderAlt' && {
    fontSize: '12px',
    color: '#222',
  }),
}));

export const InterviewLengthContainer = styling('div')(props => ({
  position: 'absolute',
  top: '6px',
  right: '-8px',
}));

export const Text = styling('p')(props => ({
  ...(props.type == 'interview_length' && {
    fontSize: '8px',
    margin: '0',
    transform: 'translate(0, -16px)',
    fontWeight: 'bold',
    color: '#512acc'
  }),
}));
