import { Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Skeleton } from '@mui/material';
import React from 'react';

const useStyles = makeStyles(() => ({
  cardContainer: { height: '100vh', width: '100%', padding: '35px 40px' },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 40
  },
  paragraph: { margin: '40px 0px' }
}));

const SkeletonLoader = () => {
  const classes = useStyles();

  return (
    <Card className={classes.cardContainer}>
      <div style={{ display: 'flex' }}>
        <Skeleton variant="circle" style={{ height: 80, width: 80 }} />
        <div className={classes.titleContainer}>
          <Skeleton variant="text" width={200} height={20} />
          <Skeleton variant="text" width={250} height={20} />
        </div>
      </div>
      <div className={classes.paragraph}>
        <Skeleton variant="text" width="30%" />
        <Skeleton variant="text" width="50%" />
        <Skeleton variant="text" width="70%" />
        <Skeleton variant="text" width="40%" />
      </div>
      <div className={classes.paragraph}>
        <Skeleton variant="text" width="30%" />
        <Skeleton variant="text" width="50%" />
        <Skeleton variant="text" width="70%" />
        <Skeleton variant="text" width="40%" />
      </div>
      <div className={classes.paragraph}>
        <Skeleton variant="text" width="30%" />
        <Skeleton variant="text" width="90%" />
        <Skeleton variant="text" width="70%" />
        <Skeleton variant="text" width="80%" />
      </div>
      <div className={classes.paragraph}>
        <Skeleton variant="text" width="30%" />
        <Skeleton variant="text" width="90%" />
        <Skeleton variant="text" width="70%" />
        <Skeleton variant="text" width="80%" />
      </div>
    </Card>
  );
};

export default SkeletonLoader;
