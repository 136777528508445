import Colors from '../../../../../styles/colors.module.scss';
import { styled as styling } from '@mui/material/styles';
import { TextField } from '@mui/material';

export const Text = styling('p')((props) => ({
  fontFamily: 'Inter',
  ...(props.message_date == 'true' && {
    fontSize: '10px',
    color: Colors.priBlack,
    marginTop: '5px',
    marginBottom: '0',
    fontWeight: 600,
  }),
  ...(props.message_time == 'true' && {
    fontSize: '10px',
    color: Colors.terWhite,
    marginTop: '5px',
    marginBottom: '0',
    fontWeight: 600,
  }),
}));

export const TextContainer = styling('div')((props) => ({
  ...(props.message_date == 'true' && {
    margin: '12px 0',
    textAlign: 'center',
  }),
}));

export const HeightSpacer = styling('div')((props) => ({
  ...(props.height && {
    height: props.height? props.height : '5px',
  }),
}));

export const TextFieldStyled = styling(TextField)({
  '& .MuiInput-underline:after': {
    borderBottomColor: 'transparent'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent'
    },
    '&:hover fieldset': {
      borderColor: 'transparent'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent'
    }
  }
});
