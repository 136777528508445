import { styled as styling } from '@mui/material/styles';

export const ContentDescription = styling('span')({
  fontFamily: 'Inter',
  fontSize: '16px',
  letterSpacing: '0.15px'
});

export const ContentInfo = styling('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
  width: '100%',
  height: '263px',
  borderRadius: '8px',
  background: '#F5F5F5'
});

export const GifContainer = styling('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '231px',
  minWidth: '231px',
  height: '231px'
});

export const ExplainerContainer = styling('div')({
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '16px',
  width: '401px',
  maxWidth: '401px',
  height: '231px'
});

export const ExplainerTitle = styling('span')({
  fontWeight: 700,
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.1px',
  width: '100%',
  textAlign: 'left'
});

export const ExplainerInfoWrapper = styling('ul')({
  marginTop: '10px',
  marginBottom: '10px',
  listStyleType: 'disc',
  paddingLeft: '20px'
});

export const ExplainerInfo = styling('li')(props => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  ...(props.margin_top == 'true' && {
    marginTop: '24px'
  })
}));

export const ExplainerNote = styling('span')({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '10px',
  lineHeight: '16px',
  color: 'rgba(0, 0, 0, 0.6)'
});

export const TextFieldsContainer = styling('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start'
});

export const TextFieldContainer = styling('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px'
});

export const CTAContainer = styling('div')({
  position: 'relative',
  padding: '24px 0',
  margin: '0'
});

export const CTAButtonsContainer = styling('div')({
  padding: '0 110.5px 0 110.5px',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px'
});

export const PopupContainer = styling('div')({
  width: '464px',
  background: '#FFF',
  position: 'relative'
});

export const TitleContainer = styling('div')({
  padding: '10px 24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

export const Title = styling('span')({
  fontFamily: 'Inter',
  fontWeight: 700,
  fontSize: '20px'
});

export const ContentContainer = styling('div')(props => ({
  padding:
    props.currentPage == '2' && !props.errors ? '24px 32px 16px 32px' : '24px 32px 0px 32px',
  minHeight:
    props.currentPage == '1'
      ? '455px'
      : props.currentPage == '2'
      ? '455px'
      : 'unset'
}));
