import React from 'react';
import {
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  Dialog,
  IconButton,
  Tooltip
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Close as CloseIcon, Info as InfoIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { IconGrid, TitleGrid, TitleStyled } from './styles';

const style = theme => ({
  root: {
    margin: 0,
    border: 0,
    fontWeight: '500',
    fontSize: '1.25rem',
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
    padding: '16px 24px',
    flex: '0 0 auto'
  },
  cRoot: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  hideTitle: {
    padding: 0,
    position: 'absolute',
    right: '10px',
    top: '10px',
    maxWidth: '35px'
  },
  hideTitleButton: {
    position: 'absolute',
    color: '#222'
  },
  hideTitleIcon: {
    margin: '0'
  },
  closeButton: {
    // position: 'absolute',
    right: '-40px',
    top: '8px',
    color: '#222',

    display: 'inline-flex',
    '-webkit-box-align': 'center',
    alignItems: 'center',
    '-webkit-box-pack': 'center',
    justifyContent: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    '-webkit-tap-highlight-color': 'transparent',
    backgroundColor: 'transparent',
    outline: '0px',
    border: '0px',
    margin: '0px',

    cursor: 'pointer',
    userSelect: 'none',
    verticalAlign: 'middle',
    appearance: 'none',
    textDecoration: 'none',
    textAlign: 'center',
    flex: '0 0 auto',
    fontSize: '1.5rem',
    padding: '8px',
    borderRadius: '50%',
    overflow: 'visible',
    color: 'rgba(0, 0, 0, 0.54)',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },
  closeButtonLarge: {
    position: 'absolute',
    left: '600px',
    top: '8px',
    color: '#222'
  }
});

const DialogTitle = withStyles(style)(props => {
  const {
    children,
    origin,
    classes,
    onClose,
    toolTipTitle,
    hideCloseIcon,
    largeModal,
    disableCustomPadding,
    hideTitle,
    ...other
  } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={
        disableCustomPadding
          ? classes.cRoot
          : hideTitle
          ? classes.hideTitle
          : classes.root
      }
      {...other}>
      <IconGrid>
        {!hideCloseIcon ? (
          <IconButton
            className={
              hideTitle ? classes.hideTitleButton : classes.closeButton
            }
            onClick={onClose}>
            <CloseIcon className={hideTitle ? classes.hideTitleIcon : ''} />
          </IconButton>
        ) : null}
      </IconGrid>
      <TitleGrid>
        <TitleStyled>{children}</TitleStyled>

        {toolTipTitle && (
          <Tooltip title={toolTipTitle} placement="bottom">
            <InfoIcon className={styles.infoIcon} />
          </Tooltip>
        )}
      </TitleGrid>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    // padding: 30,
    paddingTop: 0,
    borderTop: 0,
    borderBottom: 0
  }
}))(MuiDialogContent);

export default function PopUpDialog({
  title,
  open,
  handleClose,
  hideCloseIcon,
  className,
  children,
  paperStyle,
  toolTipTitle,
  origin,
  dialogWidth,
  maxDialogWidth,
  disableCustomPadding,
  largeModal,
  removeContentPadding,
  hideTitle
}) {
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: 20,
          width: dialogWidth ? dialogWidth : '601px',
          background: '#fff',
          ...(maxDialogWidth ? { maxWidth: maxDialogWidth } : 1000),
          ...paperStyle
        }
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0,0,0,0.5)',
          boxShadow: 'none'
        }
      }}>
      <DialogTitle
        largeModal={largeModal}
        onClose={handleClose}
        origin={origin}
        hideCloseIcon={hideCloseIcon}
        toolTipTitle={toolTipTitle}
        disableCustomPadding={disableCustomPadding}
        hideTitle={hideTitle}>
        {title}
      </DialogTitle>
      <DialogContent sx={{ padding: removeContentPadding && '0px' }}>
        <div className={className}>{children}</div>
      </DialogContent>
    </Dialog>
  );
}

PopUpDialog.propTypes = {
  title: PropTypes.string,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  hideCloseIcon: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.objectOf(Object),
  paperStyle: PropTypes.objectOf(Object),
  toolTipTitle: PropTypes.string
};

PopUpDialog.defaultProps = {
  title: '',
  handleClose: () => {},
  open: false,
  hideCloseIcon: false,
  className: '',
  children: {},
  paperStyle: {},
  toolTipTitle: ''
};
