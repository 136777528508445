import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorToast, SuccessToast } from '../../../utils/ToastUtils';
import {
  TitleContainer,
  DialogCloseButton,
  DialogContentStyled,
  DialogStyled,
  DialogTitleText,
  TextFieldContainer,
  DefaultGrid,
  TextFieldStyled,
  OuterContainer,
  LabelStyled,
  CheckboxContainer,
  FormGroupStyled,
  CheckboxStyled,
  TextAreaContainer,
  TextAreaStyled,
  ButtonStyled,
  ButtonContainer
} from './styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import XMarkIcon from '@heroicons/react/24/solid/XMarkIcon';
import { mutateSendEmailWithoutLogin } from '../../../redux/actions/company_actions';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';
import styles from './index.module.scss';
import { Grid } from '@mui/material';
import { ArrowDropDownIconStyled } from '../../MyAccount/styles';

const ContactWithoutLogin = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const mutatingEnquiryEmailSent = useSelector(
    state => state.companies.mutatingEnquiryEmailSent
  );

  const initialValues = {
    name: {
      value: '',
      error: false,
      helperText: 'Please enter your name',
      required: true,
      focused: false
    },
    companyName: {
      value: '',
      error: false,
      helperText: 'Please enter your company name',
      required: true,
      focused: false
    },
    email: {
      value: '',
      error: false,
      helperText: 'Please enter your email',
      required: true,
      focused: false
    },
    phoneNumber: {
      value: '',
      error: false,
      helperText: 'Please enter your number',
      required: true,
      focused: false
    },
    jobPosting: {
      label: 'Job Posting',
      value: false
    },
    employerBranding: {
      label: 'Employer Branding',
      value: false
    },
    headhunting: {
      label: 'Headhunting',
      value: false
    },
    message: { value: '', error: false, helperText: '', required: false }
  };

  // This stores the form data
  const [forms, setForms] = useState(initialValues);

  // This forces phoneInput to remove unwanted class
  useEffect(() => {
    const elements = document.querySelectorAll(
      '.PhoneInputCountryIcon--border'
    );
    elements.forEach(el =>
      el.classList.remove('PhoneInputCountryIcon--border')
    );
  }, [open, forms]);

  const onHandleClose = () => {
    handleClose();
  };

  const checkboxValue = [
    { value: 'Job Posting', label: 'jobPosting' },
    { value: 'Employer Branding', label: 'employerBranding' },
    { value: 'Headhunting', label: 'headhunting' }
  ];

  const errorState = forms.phoneNumber.error && !forms.phoneNumber.focused;

  // Reset
  useEffect(() => {
    setForms(initialValues);
  }, [handleClose]);

  // Validation
  const validationSchema = Yup.object({
    name: Yup.string()
      .required('Name is required')
      .min(2, 'Name must be at least 2 characters'),
    email: Yup.string()
      .required('Email is required')
      .email('Enter a valid email'),
    companyName: Yup.string()
      .required('Company Name is required')
      .min(2, 'Company Name is required')
  });

  const handlePhoneNumber = number => {
    setForms(prevData => ({
      ...prevData,
      ['phoneNumber']: {
        ...prevData['phoneNumber'],
        value: number
      }
    }));

    if (number) {
      let validPhoneNumber = isValidPhoneNumber(number);

      setForms(prevData => ({
        ...prevData,
        ['phoneNumber']: {
          ...prevData['phoneNumber'],
          error: !validPhoneNumber,
          helperText: validPhoneNumber
            ? 'Please enter contact number'
            : 'Invalid contact number'
        }
      }));
    }
  };

  const onChangeHandler = e => {
    const { name, value, checked, type } = e?.target;

    let isValid = true;
    let errorMessage = '';

    // Determine if the input is a checkbox or not
    const checkbox = type === 'checkbox';
    const inputValue = checkbox ? checked : value;

    // Validate the field using Yup
    if (!checkbox && name !== 'message') {
      validationSchema
        ?.validateAt(name, { [name]: inputValue })
        ?.then(() => {
          errorMessage = '';
          isValid = true;
        })
        .catch(error => {
          errorMessage = error.message;
          isValid = false;
        })
        .finally(() => {
          setForms(prevData => ({
            ...prevData,
            [name]: {
              ...prevData[name],
              value: inputValue,
              error: !isValid,
              helperText: errorMessage
            }
          }));
        });
    } else {
      setForms(prevData => ({
        ...prevData,
        [name]: {
          ...prevData[name],
          value: inputValue
        }
      }));
    }
  };

  const onFocusHandler = e => {
    const name = e.target.name;
    setForms(prevData => ({
      ...prevData,
      [name]: {
        ...prevData[name],
        focused: true
      }
    }));
  };

  const onBlurHandler = e => {
    const name = e.target.name;
    setForms(prevData => ({
      ...prevData,
      [name]: {
        ...prevData[name],
        focused: false
      }
    }));
  };

  const showHelperText = name => {
    if (forms[name].error && !forms[name].focused) {
      return forms[name].helperText;
    }
    return '';
  };

  const showError = name => {
    if (forms[name].error && !forms[name].focused) {
      return true;
    }

    return false;
  };

  const checkAndSetRequiredErrors = values => {
    let updatedValues = { ...values };

    for (let key in updatedValues) {
      const field = updatedValues[key];

      if (field.required && (field.value === '' || field.value === null)) {
        updatedValues = {
          ...updatedValues,
          [key]: {
            ...field,
            error: true
          }
        };
      }
    }

    return updatedValues;
  };

  const checkForErrors = values => {
    for (let key in values) {
      if (values[key].error) {
        return true;
      }
    }

    return false;
  };

  const submitHandler = () => {
    let text = [];

    checkboxValue.map(checkbox => {
      if (forms[checkbox.label].value) {
        text.push(forms[checkbox.label].label);
      }
    });

    const services = 'Service Interested: ' + text.join(', ') + ' | Message: ';

    // 1) Check if required fields are empty
    // 2) Set error to true if empty
    // 3) if there's a single error in the forms, block submission

    const updatedFormData = checkAndSetRequiredErrors(forms);
    setForms(updatedFormData);

    const hasErrors = checkForErrors(updatedFormData);

    if (hasErrors) return;

    dispatch(
      mutateSendEmailWithoutLogin({
        input: {
          name: forms?.name?.value,
          companyName: forms.companyName?.value,
          email: forms?.email?.value,
          phoneNumber: forms?.phoneNumber?.value,
          message: services + forms?.message?.value
        }
      })
    )
      .then(res => {
        if (res.enquiryEmailSent) {
          SuccessToast('Email has been sent. Thank you for your enquiry.');
          handleClose();
        }
      })
      .catch(err => {
        ErrorToast(err.message);
      });
  };

  return (
    <DialogStyled open={open} onClose={onHandleClose}>
      <DialogCloseButton onClick={onHandleClose}>
        <XMarkIcon width={24} height={24} />
      </DialogCloseButton>
      <DialogContentStyled>
        <TitleContainer>
          <DialogTitleText>Contact Us</DialogTitleText>
        </TitleContainer>

        <OuterContainer>
          {/* TextFields */}
          <TextFieldContainer container columnSpacing={4}>
            <DefaultGrid item sm={6}>
              <LabelStyled>Name</LabelStyled>
              <TextFieldStyled
                name="name"
                variant="outlined"
                fullWidth
                placeholder="Type here"
                onChange={onChangeHandler}
                onFocus={onFocusHandler}
                onBlur={onBlurHandler}
                error={showError('name')}
                helperText={showHelperText('name')}
              />
            </DefaultGrid>

            <DefaultGrid item sm={6}>
              <LabelStyled>Company Name</LabelStyled>
              <TextFieldStyled
                name="companyName"
                variant="outlined"
                fullWidth
                placeholder="Type here"
                onChange={onChangeHandler}
                onFocus={onFocusHandler}
                onBlur={onBlurHandler}
                error={showError('companyName')}
                helperText={showHelperText('companyName')}
              />
            </DefaultGrid>

            <DefaultGrid item sm={6}>
              <LabelStyled>Email</LabelStyled>
              <TextFieldStyled
                name="email"
                variant="outlined"
                fullWidth
                placeholder="Type here"
                onChange={onChangeHandler}
                onFocus={onFocusHandler}
                onBlur={onBlurHandler}
                error={showError('email')}
                helperText={showHelperText('email')}
              />
            </DefaultGrid>

            <DefaultGrid item sm={6}>
              <LabelStyled>Contact Number</LabelStyled>
              <div
                className={`${styles.phoneInputContainer} ${
                  errorState ? styles.hasError : ''
                }`}>
                  <div style={{ position: "relative"}}>
                <PhoneInput
                  className={
                    errorState ? styles.PhoneInputError : styles.PhoneInput
                  }
                  name="phoneNumber"
                  style={{
                    height: '50px',
                    border: 'none !important'
                  }}
                  placeholder="+6012 3456789"
                  defaultCountry={'MY'}
                  countryCallingCodeEditable={false}
                  international
                  onChange={value => handlePhoneNumber(value)}
                  onFocus={onFocusHandler}
                  onBlur={onBlurHandler}
                />
                <Grid
                  sx={{
                    zIndex: 999,
                    position: 'absolute',
                    top: '18px',
                    left: '45px'
                  }}>
                  <ArrowDropDownIconStyled />
                </Grid>
                </div>
              </div>
              {errorState && (
                <span
                  style={{
                    marginLeft: '14px',
                    marginRight: '14px',
                    color: '#d32f2f',
                    fontSize: '0.75rem',
                    marginTop: '3px'
                  }}>
                  {forms.phoneNumber.helperText}
                </span>
              )}
            </DefaultGrid>
          </TextFieldContainer>

          {/* Checkbox */}
          <CheckboxContainer>
            <LabelStyled>Which services are you interested in?</LabelStyled>

            <FormGroupStyled>
              {checkboxValue.map(checkbox => {
                return (
                  <FormControlLabel
                    key={checkbox.label}
                    control={
                      <CheckboxStyled
                        checked={forms[checkbox.label].value}
                        onChange={e => onChangeHandler(e)}
                        name={checkbox.label}
                      />
                    }
                    label={checkbox.value}
                  />
                );
              })}
            </FormGroupStyled>
          </CheckboxContainer>

          {/* TextArea */}
          <TextAreaContainer>
            <LabelStyled>
              &#40;Optional&#41; Any additional details you would like us to
              know?
            </LabelStyled>
            <TextAreaStyled
              name="message"
              variant="outlined"
              multiline
              rows={4}
              placeholder="Let us know any details you want to know more about"
              onChange={e => onChangeHandler(e)}
            />
          </TextAreaContainer>

          {/* Button */}
          <ButtonContainer>
            <ButtonStyled
              onClick={submitHandler}
              loading={mutatingEnquiryEmailSent}
              disabled={mutatingEnquiryEmailSent}>
              Submit
            </ButtonStyled>
          </ButtonContainer>
        </OuterContainer>
      </DialogContentStyled>
    </DialogStyled>
  );
};

ContactWithoutLogin.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
};

ContactWithoutLogin.defaultProps = {
  open: false,
  handleClose: () => {}
};

export default ContactWithoutLogin;
