import { IconButton, Button, DialogTitle, DialogContent } from '@mui/material';
import { styled as styling } from '@mui/material/styles';

export const DialogTitleStyled = styling(DialogTitle)(props => ({
  padding: '16px 16px 16px 24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px'
}));

export const DialogContentStyled = styling(DialogContent)(props => ({
  // padding: '16px 24px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  maxWidth: '464px'
}));

export const DialogNoteStyled = styling(DialogContent)(props => ({
  padding: '0px',
  color: 'rgba(0, 0, 0, 0.60)',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const DialogActionsStyled = styling(DialogTitle)(props => ({
  padding: '0px'
}));

export const DialogButton = styling('div')(props => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '16px',
  width: '100%'
}));

export const ButtonContinueEdit = styling(Button)(props => ({
  width: '200px',
  height: '48px',
  backgroundColor: '#FFF',
  borderRadius: '8px',
  border: '2px solid #222',
  color: '#222',
  fontSize: '16px',
  textTransform: 'none',
  fontWeight: '700',
  padding: '0 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',

  ':hover': {
    backgroundColor: '#FFF'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '130px',
    height: '30px',
    fontSize: '12px',
    padding: '0 8px'
  }
}));

export const ButtonText = styling(Button)(props => ({
  width: '200px',
  height: '36px',
  backgroundColor: 'transparent',
  borderRadius: '8px',
  color: '#222',
  fontSize: '14px',
  textTransform: 'none',
  fontWeight: '700',
  padding: '0 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',

  ':hover': {
    backgroundColor: 'transparent'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '130px',
    height: '30px',
    fontSize: '12px',
    padding: '0 10px'
  }
}));

export const ButtonSkipEdit = styling(Button)(props => ({
  width: '200px',
  height: '36px',
  backgroundColor: '#2E2E2E',
  borderRadius: '8px',
  color: '#FFF',
  fontSize: '14px',
  textTransform: 'none',
  fontWeight: '700',
  padding: '0 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',

  ':hover': {
    backgroundColor: '#2E2E2E'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '130px',
    height: '30px',
    fontSize: '12px',
    padding: '0 10px'
  }
}));

export const IconButtonStyled = styling(IconButton)(props => ({
  padding: '0'
}));
