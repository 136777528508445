import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HeightSpacer } from '../../../../components/Spacer/styles';
import {
  keywordingJob,
  updateAllJobs,
  updateKeywordHighlightConfirmationPopup,
  updateKeywordHighlightPopupStatus
} from '../../../../redux/actions/job_action';
import { ErrorToast, SuccessToast } from '../../../../utils/ToastUtils';
import TokensPopup from '../../SharedPopups/TokensPopup';
import {
  ContentDescription,
  ContentInfo,
  ExplainerContainer,
  ExplainerInfo,
  ExplainerInfoWrapper,
  ExplainerTitle,
  GifContainer
} from '../../SharedPopups/TokensPopup/styles';
import store from '../../../../redux/stores/store';
import keywordHighlightVisualExplainer from '../../../../assets/images/jobs/hightlight_token_visual_explainer.gif';
import { mutateCart } from '../../../../redux/actions/order_action';

const KeywordHighlightConfirmationPopUp = ({ jobTitle, jobId, jobData }) => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const isKeywordHighlightConfirmationOpen = useSelector(
    state => state.jobs.isKeywordHighlightConfirmationOpen
  );
  const keywordHighlightTokensUsableCount = useSelector(
    state =>
      state.jobs.subscribedKeywordHighlightTokensPackage?.total_usable_quantity
  );
  const loading = useSelector(state => state.jobs.keywordingJob);
  const keywordHighlightTexts = useSelector(
    state => state.jobs.keywordHighlightTexts
  );
  const allJobs = store.getState().jobs.jobs;

  const extendDuration = job => {
    if (
      job?.keywordHighlightJobThreshold > 0 &&
      job?.keywordHighlightJobThreshold <= 25
    ) {
      return true;
    }

    return false;
  };

  const getPopupTitle = (count, jobData) => {
    if (count > 0) {
      if (extendDuration(jobData)) {
        return `Extend Key Highlights Duration`;
      }
      return `Add Key Highlights to ${jobTitle}?`;
    } else {
      return `No Key Highlights Available`;
    }
  };

  const getContentMessage = (count, jobData) => {
    if (count > 0) {
      if (extendDuration(jobData)) {
        return `By extending Key Highlights to this job, one Key Highlights will be deducted from your account.`;
      }

      return 'By adding Key Highlights to this job, one Key Highlights will be deducted from your account. ';
    } else {
      return 'Refine your recruitment journey by purchasing Key Highlights!';
    }
  };

  const getFeaturedTokenCount = (count, jobData) => {
    let tokenCount = keywordHighlightTokensUsableCount;

    if (count > 0) {
      return (
        <span>
          You have {''}
          <b>{tokenCount} Key Highlights</b> {''} left.
        </span>
      );
    } else {
      return ``;
    }
  };

  const onSubmitKeywordHighlightToken = async (count, jobData) => {
    if (count > 0) {
      if (extendDuration(jobData)) {
        onKeywordJob(jobData, true);
        window.dataLayer.push({
          event: 'CE_extend-popup-keyword-confirm'
        });
      } else {
        onKeywordJob(jobData, false);
        window.dataLayer.push({
          event: 'CE_use-popup-keyword-confirm'
        });
      }
    } else {
      await dispatch(
        mutateCart({
          input: {
            productId: '321',
            quantity: 1
          }
        })
      );
      history('/purchase/choose');
      window.dataLayer.push({
        event: 'CE_purchase-popup-keyword-confirm'
      });
    }
  };

  const onKeywordJob = (job, extendKeyword) => {
    try {
      let cTexts = [...keywordHighlightTexts];

      const filteredTexts = cTexts.filter(item => {
        return item?.keyword != '';
      });

      const params = {
        jobId: job?.id,
        extendKeywordHighlight: extendKeyword,
        keywords: filteredTexts
      };

      dispatch(keywordingJob(params)).then(res => {
        const currentJobIndex = allJobs.findIndex(job => {
          return job.id == res.job.id;
        });

        if (res.type == 'KEYWORD_HIGHLIGHT_JOB_SUCCEED') {
          if (currentJobIndex > -1) {
            allJobs[currentJobIndex] = res.job;
          }

          dispatch(updateAllJobs(allJobs));

          SuccessToast('Keywording Job Successfully!');
          dispatch(updateKeywordHighlightPopupStatus(false));
          onClosePopup();
        } else {
          ErrorToast('Failed to keywording job');
        }
      });
    } catch (err) {
      ErrorToast(err.message);
    }
  };

  const onClosePopup = (count, jobData = {}) => {
    try {
      if (count > 0) {
        if (extendDuration(jobData)) {
          window.dataLayer.push({
            event: 'CE_extend-popup-keyword-cancel'
          });
        } else {
          window.dataLayer.push({
            event: 'CE_use-popup-keyword-cancel'
          });
        }
      } else if (count == 0) {
        window.dataLayer.push({
          event: 'CE_purchase-popup-keyword-cancel'
        });
      }
    } catch {}

    dispatch(updateKeywordHighlightConfirmationPopup(false));
  };

  return (
    <TokensPopup
      title={getPopupTitle(keywordHighlightTokensUsableCount, jobData)}
      firstButtonText={'Cancel'}
      secondButtonText={
        keywordHighlightTokensUsableCount > 0 ? 'Confirm' : 'Purchase Now'
      }
      open={isKeywordHighlightConfirmationOpen}
      onClose={onClosePopup}
      onClickFirstButton={() =>
        onClosePopup(keywordHighlightTokensUsableCount, jobData)
      }
      onClickSecondButton={() =>
        onSubmitKeywordHighlightToken(
          keywordHighlightTokensUsableCount,
          jobData
        )
      }
      loadingSecondButton={loading}>
      <>
        <ContentInfo>
          <GifContainer>
            <img
              alt="keyword-highlight-visual-explainer"
              style={{ width: '100%', maxHeight: '231px' }}
              src={keywordHighlightVisualExplainer}
            />
          </GifContainer>
          <ExplainerContainer>
            <ExplainerTitle>How can Key Highlights help you?</ExplainerTitle>
            <ExplainerInfoWrapper>
              <ExplainerInfo>
                Key Highlights lets you attract job seekers with attractive
                keywords that describe your job, company, work environment,
                benefits, etc.
              </ExplainerInfo>
              <ExplainerInfo margin_top="true">
                This feature allows job posts to include{' '}
                <strong>up to five keywords</strong>, each with a maximum of 20
                characters.
              </ExplainerInfo>
            </ExplainerInfoWrapper>
          </ExplainerContainer>
        </ContentInfo>
        <HeightSpacer height={'24px'} />
        <ContentDescription>
          {getContentMessage(keywordHighlightTokensUsableCount, jobData)}
        </ContentDescription>
        {keywordHighlightTokensUsableCount > 0 ? (
          <HeightSpacer height={'24px'} />
        ) : null}
        <ContentDescription>
          {getFeaturedTokenCount(keywordHighlightTokensUsableCount, jobData)}
        </ContentDescription>
      </>
    </TokensPopup>
  );
};

export default KeywordHighlightConfirmationPopUp;
