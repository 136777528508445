import React from 'react';
import SharedFilter from '../SharedFillter';
import { DateFilter } from './Date';
import { NameFilter } from './Name';
import { SortFilter } from './Sort';
import { MUIButtonStyled } from '../../../components/MUIButton';
import { ButtonStyled, DividerStyled } from './styles';
import { FilterSkeleton } from '../../../components/Skeletons/Filters';
import { useSelector } from 'react-redux';
import { Divider } from '@mui/material';
import { SearchFilter } from './Search';
import { Grid } from '@mui/material';

const BookmarkedFilters = props => {
  const { triggerLoadTalents, triggerClearFilters, type } = props;

  const fetchingCompanySettings = useSelector(
    state => state.companies.fetchingCompanySettings
  );

  const onClearFilters = () => {
    if (triggerClearFilters) {
      triggerClearFilters(type);
    }
  };

  const onApplyFilters = () => {
    if (triggerLoadTalents) {
      triggerLoadTalents(type, '', 1);
      // TALENT SEARCH TRACKING
      window.dataLayer.push({
        event: 'CE_apply-filter-bookmark-page'
      });
    }
  };

  return fetchingCompanySettings ? (
    <FilterSkeleton />
  ) : (
    <SharedFilter
      buttons={
        <Buttons
          onClearFilters={onClearFilters}
          onApplyFilters={onApplyFilters}
        />
      }
      filterTitle={
        <Grid
          display="flex"
          alignItems={'center'}
          justifyContent={'space-between'}
          gap="16px">
          <span
            style={{
              fontFamily: 'Inter',
              fontSize: '16px',
              fontWeight: '700'
            }}>
            Filter
          </span>
        </Grid>
      }>
      <div
        style={{
          display: 'flex',
          padding: '8px 8px 16px 16px',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: '20px',
          flex: '1 0 0',
          alignSelf: 'stretch',
          flexDirection: 'column'
        }}>
        <SearchFilter triggerLoadTalents={triggerLoadTalents} />
        <SortFilter />
        <DateFilter />
        <NameFilter />
      </div>
    </SharedFilter>
  );
};

export default BookmarkedFilters;

const Buttons = ({ onClearFilters, onApplyFilters }) => {
  return (
    <div
      style={{
        background: '#efefef',
        zIndex: '2',
        borderRadius: '0 0 8px 8px',
        position: 'sticky',
        bottom: '0'
      }}>
      <Divider />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          padding: '12px 16px'
        }}>
        <ButtonStyled filter onClick={onClearFilters}>
          Clear Filter
        </ButtonStyled>
        <ButtonStyled onClick={onApplyFilters}>Apply Filter</ButtonStyled>
      </div>
    </div>
  );
};
