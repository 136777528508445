import { styled as styling } from '@mui/material/styles';
import { TableCell } from '@mui/material';
import { TableRow } from '@mui/material';
import { TableContainer } from '@mui/material';
import Colors from '../../../../../styles/colors.module.scss';
import { Info, Info as InfoIcon } from '@mui/icons-material';
import { Tooltip } from '../../../../../components/CustomTooltip/styles';

export const Text = styling('p')(props => ({
  fontFamily: 'Inter',

  ...(props.max_width && {
    maxWidth: props.max_width
  }),

  ...(props.table_content == 'true' && {
    fontSize: '16px',
    margin: '0',
    color: Colors.priBlack
  }),

  ...(props.table_header == 'true' && {
    fontSize: '16px',
    fontWeight: 700,
    margin: '0',
    textTransform: 'capitalize',
    color: Colors.terDarkGrey
  }),

  ...(props.expired && {
    color: `${Colors.terMediumGrey} !important`
  }),
  ...(props.reschedule_chip == 'true' && {
    fontSize: '12px',
    margin: '0',
    color: Colors.priPurple,
    textAlign: 'center'
  }),
  ...(props.expiring == 'true' && {
    color: Colors.secOrange
  }),
  ...(props.custom_tooltip == 'true' && {
    fontSize: '12px',
    margin: '0'
  })
}));

export const TableRowStyled = styling(TableRow)(props => ({
  background: Colors.priWhite,
  boxShadow: 'none',
  height: '80px'
}));

export const TableCellStyled = styling(TableCell)(props => {
  let style = {
    position: 'relative',
    width: '109px'
  };

  switch (props.name) {
    case 'job-slots':
      style = {
        ...style,
        width: '280px !important'
      };
      break;

    case 'activation-date':
    case 'expiry-date':
    case 'days-remaining':
      style = {
        ...style,
        width: '215px'
      };
      break;

    case 'button':
      style = {
        ...style,
        minWidth: '215px'
      };
      break;

    default:
      break;
  }

  return style;
});

export const CellContainer = styling('div')(props => ({
  display: 'flex',
  alignItems: 'center'
}));

export const Spacer = styling('div')(props => ({
  width: props.width ? props.width : '5px'
}));

export const CustomChip = styling('div')(props => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: '100px',
  background: Colors.secLightPurple,
  borderRadius: '20px',
  padding: '6px 10px',

  ...(props.expired == 'true' && {
    background: Colors.terWhite
  })
}));

export const InfoIconStyled = styling(InfoIcon)(props => ({
  fontSize: '20px',

  ...(props.reschedule == 'true' && {
    color: Colors.priPurple
  }),

  ...(props.expired == 'true' &&
    props.reschedule == 'true' && {
      color: `${Colors.terMediumGrey} !important`
    }),

  ...(props.not_activated == 'true' && {
    margin: '0 15px',
    color: `${Colors.terMediumGrey}`
  })
}));

export const TooltipWrapper = styling('div')(props => ({
  cursor: 'default',
  position: 'relative',
  // &:hover ${Tooltip} {
  '&:hover div': {
    display: 'block',
    animation: 'fadeInAnimation ease 1s',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
    '@keyframes fadeInAnimation': {
      '0%': {
        opacity: 0
      },
      '50%': {
        opacity: 0.5
      },
      '100%': {
        opacity: 1
      }
    }
  }
}));

export const RescheduledDot = styling('div')(props => ({
  height: '10px',
  width: '10px',
  borderRadius: '30px',
  background: Colors.priPurple,

  ...(props.expired && {
    background: `${Colors.terMediumGrey} !important`
  })
}));
