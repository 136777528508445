import React, { useRef } from 'react';
import {
  EmptySocialMediaGrid,
  PlusIconStyled,
  SocialMediaGrid,
  UrlText,
  FilledSocialMediaGrid
} from '../../styles';
import { LinearProgress, Grid, IconButton } from '@mui/material';
import { useDrag, useDrop } from 'react-dnd';
import { PencilSquareIconStyled } from './styles';
import { ReactComponent as Rearrange } from '../../../../../assets/icons/company-canvas/rearrange-icon.svg';
import { ReactComponent as Play } from '../../../../../assets/icons/company-canvas/play.svg';
import { isEmpty } from 'lodash';
import StatusTag from '../../../components/StatusTag';
import EmbedType from '../EmbedType';
import { useDispatch } from 'react-redux';
import { selectModalVideo } from '../../../../../redux/actions/company_actions';

export const ItemTypes = {
  VIDEO: 'video',
  IMAGE: 'image',
  TEXT: 'text'
};

export const SocialMediaCard = ({
  viewMode,
  setSelected,
  selected,
  video,
  index,
  moveVideo,
  selectedStatus,
  progress,
  setView,
  videos
}) => {
  const ref = useRef(null);
  const url = video?.content[selectedStatus]?.url;

  const [{ isDropping }, drop] = useDrop({
    accept: ItemTypes.VIDEO,
    canDrop: () => video?.content?.length > 0,
    hover(item, monitor) {
      if (video?.content?.length > 0 === false) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      moveVideo(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.VIDEO,
    item: { type: ItemTypes.VIDEO, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: () => video?.content?.length > 0
  });

  drag(drop(ref));

  const handleClick = () => {
    // resetOrder();
    if (video?.add || url) {
      setSelected(video);
    }
  };

  if (viewMode) {
    const url = video?.content[0]?.url;
    const status = video?.content[0]?.status;
    return (
      <ViewMediaContent
        url={url}
        status={status}
        viewMode={viewMode}
        setView={setView}
      />
    );
  }

  return (
    <div
      onClick={handleClick}
      ref={ref}
      style={{ height: '100%', width: '100%' }}>
      {video?.add && !video.loading && isEmpty(video?.url) ? (
        <AddButton />
      ) : (
        <>
          {video.loading ? (
            <LoadingIndicator progress={progress} />
          ) : (
            <EditMediaContent
              url={url}
              index={index}
              status={video?.content[selectedStatus]?.status}
              videoIndex={selected}
              isDragging={isDragging}
              videos={videos}
            />
          )}
        </>
      )}
    </div>
  );
};

const socialMediaType = url => {
  if (url.includes('instagram')) {
    return 'instagram';
  }

  if (url.includes('tiktok')) {
    return 'tiktok';
  }

  if (url.includes('twitter') || url.includes('x.com')) {
    return 'x';
  }

  if (url.includes('facebook')) {
    return 'facebook';
  }
};

const LoadingIndicator = ({ progress }) => {
  return (
    <SocialMediaGrid>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          textAlign: 'center',
          width: '100%',
          padding: '0px 16px 0px 16px',
          borderRadius: '50px',
          overflow: 'hidden'
        }}>
        <UrlText>Loading ...</UrlText>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            borderRadius: '100px !important',
            '&.MuiLinearProgress-colorPrimary': {
              backgroundColor: '#D8D8D8'
            }
          }}
        />
      </Grid>
    </SocialMediaGrid>
  );
};

const EditMediaContent = ({
  url,
  index,
  status,
  videoIndex,
  isDragging,
  videos
}) => {
  const dispatch = useDispatch();

  const selectedIndex = videos?.find(
    video => video?.id === videoIndex
  )?.sortOrder;

  return url ? (
    <>
      {/* <EmbedType url={url}/> */}
      <FilledSocialMediaGrid
        selected={selectedIndex - 1 === index}
        isDragging={isDragging}
        type={socialMediaType(url)}
        sx={{
          position: 'relative',
          textOverflow: 'clip',
          overflow: 'hidden',
          backgroundColor: isDragging && '#EFEFFD !important',
          border: isDragging && '1px dashed #512ACC'
        }}>
        {!isDragging && (
          <Grid sx={{ position: 'absolute', top: '4px', left: '8px' }}>
            <StatusTag status={status || 'Draft'} onVideo />
          </Grid>
        )}

        {!isDragging && (
          <Grid
            sx={{
              position: 'absolute',
              bottom: '0px',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              height: '36px',
              backgroundColor:
                index === selectedIndex - 1 ? `#E5E1F1` : '#F5F5F5',
              alignItems: 'center',
              padding: '6px 8px'
            }}>
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '8px',
                color: `rgba(0, 0, 0, 0.60)`,
                fontWeight: 700,
                fontSize: '14px'
              }}>
              <Rearrange />
              {index + 1}
            </Grid>

            <IconButton sx={{ padding: '0px' }}>
              <PencilSquareIconStyled />
            </IconButton>
          </Grid>
        )}
        {!isDragging && (
          <Play
            onClick={() => dispatch(selectModalVideo(url))}
            style={{ cursor: 'pointer' }}
          />
        )}
      </FilledSocialMediaGrid>
    </>
  ) : (
    <EmptySocialMediaGrid />
  );
};

const ViewMediaContent = ({ url, status, viewMode }) => {
  const dispatch = useDispatch();

  return url ? (
    <>
      {/* <EmbedType url={url}/> */}
      <FilledSocialMediaGrid
        viewMode
        type={socialMediaType(url)}
        sx={{
          position: 'relative',
          textOverflow: 'clip',
          overflow: 'hidden'
        }}>
        <Grid sx={{ position: 'absolute', top: '4px', left: '8px' }}>
          <StatusTag status={status || 'Draft'} onVideo />
        </Grid>
        <Play
          onClick={() => dispatch(selectModalVideo(url))}
          style={{ cursor: 'pointer' }}
        />
      </FilledSocialMediaGrid>
    </>
  ) : (
    !viewMode && <EmptySocialMediaGrid viewMode />
  );
};

const AddButton = setView => (
  <SocialMediaGrid>
    <PlusIconStyled />
  </SocialMediaGrid>
);
