import React from 'react';
import { ButtonStyled } from '../SharedButton/styles';

export const SharedButton = ({ onClick, type, children }) => {
  return (
    <ButtonStyled type={type} onClick={onClick}>
      {children}
    </ButtonStyled>
  );
};
