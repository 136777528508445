import { styled as styling } from '@mui/material/styles';
import { config, animated, useSpring } from 'react-spring';
import { Close as CloseIcon } from '@mui/icons-material';
import Colors from '../../styles/colors.module.scss';

export const CloseLoopContainer = styling('div')({
  position: 'absolute',
  top: '280px',
  transform: 'translate(-50%, -50%)',
  left: '50%',
  width: '90%',
  zIndex: '9999',
});

export const CloseIconContainer = styling('div')({
  position: 'absolute',
  top: '3%',
  right: '4%',
  cursor: 'pointer',
});

export const CloseIconStyled = styling(CloseIcon)({
  color: '#222',
  fontSize: '22px',
});

export const AnimatedResume = styling(animated('div'))({
  background: '#FFF',
  width: 'calc(89vw / 1.84)',
  borderRadius:'8px',
  height: 'calc(100vh - 15%)',
  overflow: 'clip',
  width: '60%',
});

export const AnimatedBio = styling(animated('div'))({
  background: '#FFF',
  width: 'calc(89vw / 2.18)',
  borderRadius:'8px',
  height: 'calc(100vh - 15%)',
  overflow: 'auto',
  width: '40%',
  position: 'relative',
  borderRadius: '30px',
});

export const AnimatedOverlay = styling(animated('div'))({
    position: 'fixed',
    zIndex: '1250',
    height: '100%',
    width: '100%',
    background: 'rgba(0, 0, 0, 0.4)',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    overflow: 'hidden',
});

export const InfoContainer = styling('div')({
  background: Colors.terLighterGrey,
  height: '100%',
  overflow: 'hidden',
  borderRadius: '30px',
});

export const AnimatedContainer = styling('div')(props => ({
  maxWidth: props.isAllAppPage? '1240px' :'1920px',
  padding: props.isAllAppPage? '0px 20px' : '0px 5%',
  margin: props.isAllAppPage? '5% auto': '3% auto',
  overflow: 'hidden',
  display: 'flex',
  height: '100vh',
  gap: '20px',
}));