import { Divider, IconButton, InputAdornment, TextField } from '@mui/material';
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spinner } from '../../../../../components';
import {
  checkIsChatRoomInitiated,
  createCompanyResumeChat,
  updateChannelsUnreadMessageCount,
  updateSpecificTalentInList
} from '../../../../../redux/actions/talent_action';
import Sendbird from '../../../../../utils/Sendbird';
import _ from 'lodash';
import { ErrorToast } from '../../../../../utils/ToastUtils';
import {
  SendIconStyled,
  TextFieldStyled,
  MessageCotainer,
  Message,
  MessageCotent,
  Time,
  DateCotainer,
  Date,
  AdminMessage,
  Center,
  UnreadMessageDivider,
  UnreadMessageDividerText
} from './styles';
import store from '../../../../../redux/stores/store';
import { getSendbirdID } from '../../../../../hooks/useENV';

const sb = new Sendbird(getSendbirdID());
let idleTimer;
let hidden = null;
let visibilityChange = null;
let resizeObserver;

const ChatPanel = ({ contentContainerRef, contentHeaderRef }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const chatRef = useRef();
  const messagesRef = useRef();
  const sbConnectedRef = useRef();
  const messageInputContainerRef = useRef();
  const inputContainerHeightRef = useRef();
  const lastMessageRef = useRef();
  const unreadMessagesRef = useRef();
  const messagesContainerRef = useRef();
  const unreadMessagesDividerRef = useRef();

  const talent = useSelector(state => state.talents.talent);
  const accountInfo = useSelector(state => state.companies.accountInfo);

  const talentIds =
    accountInfo?.currentAccountType == 'master'
      ? [talent?.id]
      : [talent?.id, accountInfo?.companyId];
  const talentName = talent.name;
  const talentImage = `https:${talent.profileImageSquare}`;

  const [sbConnected, setSbConnected] = useState(false);
  const [messages, setMessages] = useState([]);
  const [chatText, setChatText] = useState('');
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [isCreatingChannel, setIsCreatingChannel] = useState(false);
  const [height, setHeight] = useState(0);
  const [topSectionHeight, setTopSectionHeight] = useState(0);
  const [inputContainerHeight, setInputContainerHeight] = useState(96);

  messagesRef.current = messages;
  sbConnectedRef.current = sbConnected;
  inputContainerHeightRef.current = inputContainerHeight;

  const currentCompanyName =
    accountInfo.currentAccountType === 'master'
      ? accountInfo.companyName
      : sb?.sb?.currentUser?.nickname;

  const msg = `${currentCompanyName} has initiated a chat session with ${talent.name}`;

  const cMetaData = {
    chat_status: '2',
    company_name: accountInfo.companyName
  };

  const customType = '2';

  const createChannel = (
    talentIds,
    talentName,
    talentImage,
    currentCompanyName,
    customType
  ) => {
    setIsCreatingChannel(true);
    sb.createGroupChannel(
      talentIds,
      talentName,
      talentImage,
      currentCompanyName,
      customType
    )
      .then(async channel => {
        chatRef.current = channel;

        // update redux talent channel url
        dispatch(
          updateSpecificTalentInList({
            ...talent,
            companyResumeSendbirdChannelUrl: channel.url
          })
        );

        setIsCreatingChannel(false);

        const params = {
          userId: talent.id,
          channelUrl: channel.url
        };

        dispatch(createCompanyResumeChat(params));

        const connectionStatus = sb ? sb.getConnectionStatus() : '';

        if (connectionStatus != 'OPEN') {
          await connectSendBird();
        }

        sb.createGroupChannelMetaData(channel, cMetaData).then(data => {
          sb.adminMessage(channel.url, msg).then(adminMsgRes => {
            setMessages([...messagesRef.current, adminMsgRes]);
            setTimeout(() => {
              sendMessage();
            }, 250);
          });
        });
      })
      .catch(err => {
        setIsCreatingChannel(false);
      });
  };

  const connectSendBird = () => {
    return new Promise(resolve => {
      const connectionStatus = sb ? sb.getConnectionStatus() : '';

      if (connectionStatus !== 'OPEN' && sb && accountInfo?.companyId) {
        return sb
          .connect(accountInfo.companyId)
          .then(res => {
            setSbConnected(true);
            resolve(res);
          })
          .catch(err => {
            resolve(false);
          });
      } else if (connectionStatus == 'OPEN') {
        setSbConnected(true);
        resolve(false);
      } else {
        resolve(false);
      }
    });
  };

  const updateMessages = (channelUrl, message) => {
    if (channelUrl === talent.companyResumeSendbirdChannelUrl) {
      setMessages([...messagesRef.current, message]);
    }
  };

  const addChannelHandler = () => {
    const params = {
      uniqueId: 'CHAT_LIST_HANDLER',
      updateMessages
    };

    if (sb) {
      sb.removeChannelHandler('CHAT_LIST_HANDLER');
      sb.createChannelListHandler(params);
    }
  };

  const updateUnreadMessageCount = channel => {
    let cChannels = [...store.getState().talents.channelsUnreadMessageCount];

    const index = cChannels.findIndex(item => {
      return item.talentId == talent.id;
    });

    if (index > -1) {
      cChannels[index] = {
        talentId: talent.id,
        unreadCount: channel.unreadMessageCount
      };
    }

    dispatch(updateChannelsUnreadMessageCount(cChannels));
  };

  const getMessages = refresh => {
    if (sb.getConnectionStatus() !== 'OPEN') {
      connectSendBird();
      return;
    }

    sb.getChannelInfo(talent.companyResumeSendbirdChannelUrl)
      .then(channel => {
        chatRef.current = channel;
        const params = {
          sb,
          channel,
          refresh
        };

        if (params.channel && params.channel.createdAt) {
          if (sb.getConnectionStatus() === 'OPEN') {
            sb.getMessageList(params)
              .then(chatMessages => {
                setLoadingMessages(false);
                setMessages([...chatMessages]);

                const cParams = {
                  channel
                };

                if (sb.getConnectionStatus() === 'OPEN') {
                  sb.markAsRead(cParams);

                  setTimeout(() => {
                    updateUnreadMessageCount(channel);
                  }, 500);
                } else {
                  setLoadingMessages(false);
                  setSbConnected(false);
                  connectSendBird();
                }
              })
              .catch(() => {
                setLoadingMessages(false);
              });
          } else {
            setLoadingMessages(true);
            connectSendBird();
          }
        }
      })
      .catch(error => {
        // console.log('failed to get message:', error);
      });
  };

  // For disconnection when indle
  const disconnectSendBird = () => {
    if (sb) {
      if (sb.getConnectionStatus() === 'OPEN') {
        sb.disconnect()
          .then(res => {
            if (res) {
              setSbConnected(false);
            }
          })
          .catch(error => {
            // console.log('Failed to disconnect sendbird:', error);
          });
      } else {
        if (sbConnectedRef.current) {
          setSbConnected(false);
        }
      }
    }
  };

  const resetIdleTimer = () => {
    if (idleTimer) {
      clearTimeout(idleTimer);
    }

    idleTimer = setTimeout(() => {
      onIdle(sbConnected);
    }, 30000); // 30 seconds
  };

  const onActive = sbConnected => {
    resetIdleTimer();

    if (!sbConnected) {
      connectSendBird();

      if (sb) {
        const params = {
          uniqueId: 'CHAT_LIST_HANDLER',
          updateMessages
        };
        sb.createChannelListHandler(params);
      }
    }
  };

  const onMouseMove = _.throttle(e => onActive(sbConnected), 1000);

  const onIdle = sbConnected => {
    if (sbConnected) {
      disconnectSendBird();

      if (sb) {
        sb.removeChannelHandler('CHAT_LIST_HANDLER');
      }
    }
  };

  const handleVisibilityChange = () => {
    if (document[hidden]) {
      onIdle(sbConnectedRef.current);
    } else {
      onActive(sbConnectedRef.current);
    }
  };

  const initVisibilityChange = () => {
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    document.addEventListener(visibilityChange, handleVisibilityChange, false);
  };

  //grouping
  const groups = messagesRef.current.reduce((groups, item) => {
    const date = moment(item.createdAt).format('DD MMMM YYYY');

    if (!groups[date]) {
      groups[date] = [];
    }

    groups[date].push(item);

    return groups;
  }, {});

  //reform it into multiple objects in an array
  const groupArrays = Object.keys(groups).map(date => {
    return {
      date,
      item: groups[date]
    };
  });

  const isTalent = (talent, message) => {
    if (talent?.id && message?._sender?.userId) {
      if (talent?.id === message._sender?.userId) {
        return true;
      }
    }

    return false;
  };

  const getMessageType = message => {
    let type = '';
    if (message?.messageType === 'admin' || message?.type == 'ADMM') {
      type = 'admin';
    } else if (isTalent(talent, message)) {
      type = 'jobseeker';
    } else {
      type = 'employer';
    }

    return type;
  };

  const trackSend = () => {
    window.dataLayer.push({
      event: 'CE_click-send-message'
    });
  };

  const sendMessage = () => {
    if (
      sb.getConnectionStatus() == 'OPEN' &&
      chatText.length > 0 &&
      chatRef.current
    ) {
      const cMessage = chatText;
      setChatText('');
      trackSend();
      sb.sendMessage(chatRef.current, cMessage).then(message => {
        setMessages([...messagesRef.current, message]);
      });
    }
  };

  const onKeyDown = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      preSendMessage();
    }
  };

  const handleChangeChatText = event => {
    setChatText(event.target.value.substring(0, 350));

    onActive(sbConnectedRef.current);
  };

  const checkChannel = () => {
    sb.checkUser(talent.id).then(response => {
      if (response.error) {
        // talent not found, create talent
        sb.createUser(talent.id, talentName, talentImage).then(() => {
          const data = {
            metadata: {
              user_type: 'jobseeker'
            }
          };

          sb.createUserMetadata(data, talent.id)
            .then(() => {
              createChannel(
                talentIds,
                talentName,
                talentImage,
                currentCompanyName,
                customType
              );
            })
            .catch(() => {
              setIsCreatingChannel(false);
            });
        });
      } else {
        // user found
        createChannel(
          talentIds,
          talentName,
          talentImage,
          currentCompanyName,
          customType
        );
      }
    });
  };

  const preSendMessage = async () => {
    if (isCreatingChannel) return;

    // to hide divider if employer stays at chat tab
    if (localStorage.getItem('TSChatIdleTimestamp')) {
      localStorage.removeItem('TSChatIdleTimestamp');
    }

    if (!talent.companyResumeSendbirdChannelUrl) {
      const params = { userId: talent.id };

      try {
        setIsCreatingChannel(true);
        await connectSendBird();
      } catch (error) {
        setIsCreatingChannel(false);
      }

      const errMessage =
        'Failed to send message at the moment, please refresh the page and try again later.';

      if (sb.getConnectionStatus() == 'OPEN') {
        dispatch(checkIsChatRoomInitiated(params))
          .then(async res => {
            if (res.status) {
              if (sb.getConnectionStatus() == 'OPEN') {
                checkChannel();
              }
            } else {
              setIsCreatingChannel(false);
              ErrorToast(res.errors[0]?.message);
            }
          })
          .catch(() => {
            ErrorToast(errMessage);
            setIsCreatingChannel(false);
          });
      } else {
        ErrorToast(errMessage);
        setIsCreatingChannel(false);
      }
    } else {
      sendMessage();
    }
  };

  // useEffect(() => {
  //   if (talent.companyResumeSendbirdChannelUrl) {
  //     if (sb.getConnectionStatus() == 'OPEN') {
  //       if (!chatRef.current) {
  //         setSbConnected(true);
  //         addChannelHandler();
  //         setLoadingMessages(true);
  //         getMessages(true);
  //       }
  //     } else {
  //       connectSendBird();
  //       setLoadingMessages(true);
  //     }
  //   }
  // }, [sbConnected, accountInfo?.companyId]);

  useEffect(() => {
    if (!talent.companyResumeSendbirdChannelUrl) return;
    if (!accountInfo?.companyId) return;

    if (sbConnected) {
      addChannelHandler();

      // Loading messages
      setLoadingMessages(true);
      getMessages(true);
    }
  }, [sbConnected, accountInfo?.companyId]);

  const onResize = () => {
    const clientHeight = contentContainerRef.current.offsetParent?.clientHeight;
    if (clientHeight) {
      setHeight(clientHeight);
    }
  };

  useEffect(() => {
    if (contentHeaderRef?.current) {
      setTopSectionHeight(contentHeaderRef.current.clientHeight);
    }
  }, [contentHeaderRef]);

  useEffect(() => {
    onResize();
    connectSendBird();
    initVisibilityChange();

    // Setting idle timer
    resetIdleTimer();

    window.addEventListener('resize', onResize);

    if (messageInputContainerRef.current) {
      resizeObserver = new ResizeObserver(() => {
        if (
          messageInputContainerRef.current?.clientHeight !=
          inputContainerHeightRef.current
        ) {
          setInputContainerHeight(
            messageInputContainerRef.current?.clientHeight
          );
        }
      });
      resizeObserver.observe(messageInputContainerRef.current);
    }

    return () => {
      if (location?.pathname?.includes('chats') == false) {
        disconnectSendBird();
      }
      window.removeEventListener('resize', onResize);
      document.removeEventListener(visibilityChange, handleVisibilityChange);
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    // stop scrolling if unread messages reaches 25% from the top of the chat message container
    if (unreadMessagesRef.current?.length > 0) {
      setTimeout(() => {
        if (messagesContainerRef && messagesContainerRef.current) {
          messagesContainerRef.current.scrollTo(
            0,
            unreadMessagesDividerRef.current?.getBoundingClientRect().top *
              0.975
          );
        }
      }, 300);
      return;
    }

    if (messages.length > 0 && unreadMessagesRef.current?.length <= 0) {
      setTimeout(() => {
        if (messagesContainerRef && messagesContainerRef.current) {
          messagesContainerRef.current.scrollTo({
            top: messagesContainerRef.current?.children[
              messagesContainerRef.current?.children?.length - 1
            ]?.offsetTop,
            behavior: 'smooth'
          });
        }
      }, 300);
    }
  }, [messages, unreadMessagesRef, unreadMessagesDividerRef]);

  const showUnreadMessageDivider = (messages, message) => {
    let chatDeactivatedTime = localStorage.getItem('TSChatIdleTimestamp')
      ? parseInt(localStorage.getItem('TSChatIdleTimestamp')) // convert string to integer
      : moment().valueOf();

    let unreadMessages = messages?.item?.filter(item => {
      return (
        moment(item.createdAt).isAfter(moment(chatDeactivatedTime)) &&
        getMessageType(item) == 'jobseeker'
      );
    });

    unreadMessagesRef.current = unreadMessages;

    return moment(message.createdAt).isAfter(moment(chatDeactivatedTime));
  };

  return (
    <>
      <div
        style={{
          padding: '16px 24px 24px',
          height: `calc(${height}px - ${inputContainerHeightRef.current}px - ${topSectionHeight}px - 28px)`,
          overflow: 'auto'
        }}
        ref={messagesContainerRef}
        onMouseMove={onMouseMove}>
        {!isCreatingChannel && !talent.companyResumeSendbirdChannelUrl ? (
          <Center>
            <img
              title="No chat channel"
              alt="No chat channel"
              src="https://assets.wobbjobs.com/images/employers/default_states/hiredly-employer-chat-message-v2.svg"
              style={{ height: '200px', width: '200px' }}
            />
          </Center>
        ) : isCreatingChannel ? (
          <Center>
            <span>Initialising chat...</span>
          </Center>
        ) : loadingMessages || !sbConnected ? (
          <Center>
            <Spinner size={'SMALL'} />
          </Center>
        ) : (
          groupArrays?.length > 0 &&
          groupArrays.map(messages => {
            return (
              <>
                {messages.item.map(message => {
                  return (
                    <>
                      {showUnreadMessageDivider(messages, message) &&
                        getMessageType(message) == 'jobseeker' &&
                        unreadMessagesRef?.current[0]?.messageId ==
                          message.messageId && (
                          <>
                            <UnreadMessageDivider
                              ref={unreadMessagesDividerRef}>
                              <UnreadMessageDividerText>
                                {unreadMessagesRef?.current?.length} Unread
                                Messages
                              </UnreadMessageDividerText>
                            </UnreadMessageDivider>
                          </>
                        )}
                    </>
                  );
                })}

                <DateCotainer>
                  <Date>{messages.date}</Date>
                </DateCotainer>
                {messages.item.map(message => {
                  return (
                    <>
                      {getMessageType(message) == 'admin' ? (
                        <AdminMessage $userType={getMessageType(message)}>
                          {message.message}
                        </AdminMessage>
                      ) : null}
                    </>
                  );
                })}

                {messages.item.map(message => {
                  return (
                    <MessageCotainer $userType={getMessageType(message)}>
                      {getMessageType(message) != 'admin' ? (
                        <MessageCotent $userType={getMessageType(message)}>
                          <Message $userType={getMessageType(message)}>
                            {message.message}
                          </Message>

                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              marginTop: '4px'
                            }}>
                            <Time $userType={getMessageType(message)}>
                              {moment(message.createdAt).format('h:mm A')}
                            </Time>
                          </div>
                        </MessageCotent>
                      ) : null}
                    </MessageCotainer>
                  );
                })}
              </>
            );
          })
        )}
      </div>

      <div
        ref={messageInputContainerRef}
        style={{
          padding: '16px 24px 24px',
          width: '100%',
          position: 'absolute',
          bottom: 0
        }}>
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}>
          <Divider />
        </div>
        <TextFieldStyled
          fullWidth
          maxRows={3}
          multiline
          placeholder="Type your reply"
          onKeyDown={onKeyDown}
          onChange={handleChangeChatText}
          value={chatText}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <SendIconStyled onClick={preSendMessage} />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </div>
    </>
  );
};

export default ChatPanel;
