import clevertap from 'clevertap-web-sdk';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import {
  clearAccountInfo,
  fetchAccountInfo,
  fetchCompanyName,
  fetchMyCompanyStatus
} from '../redux/actions/company_actions';
import {
  fetchProductsByCategory,
  updateLimitedProduct,
  updateProductInactivePopupStatus
} from '../redux/actions/product_action';
import store from '../redux/stores/store';
import '../styles/ReactToastify.css';
import useTawkToEffect from './tawkTo.hook';
import moment from 'moment';
import { parseJwt } from '../utils/parseJWT';
import { clearJobInfo } from '../redux/actions/job_action';
import {
  clearOrderInfo,
  fetchCart,
  mutateCart
} from '../redux/actions/order_action';
import { WarningToast } from '../utils/ToastUtils';
import { getCountry } from '../hooks/useRegionalisation';
import { debounce, isEmpty } from 'lodash';

export default function RoutesWrapper({ children }) {
  const isAuthenticated = localStorage.getItem('accessToken');
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();

  useEffect(() => {
    dispatch(fetchAccountInfo());
    dispatch(fetchProductsByCategory());
  }, []);

  const [previousLocation, setPreviousLocation] = useState(null);
  const myCart = useSelector(state => state.orders.myCart);

  useEffect(() => {
    // Check if the user is leaving the '/purchase/choose' page
    if (
      previousLocation &&
      previousLocation.pathname.includes('/purchase/choose') &&
      !location.pathname.includes('/purchase/choose')
    ) {
      if (myCart?.cartLists?.edges?.length !== 0) {
        const productCodes =
          myCart?.cartLists?.edges.map(item => item.node.product.productCode) ||
          [];

        const categories = {
          job_slot: [
            'job_slot',
            'job_slot_plus',
            'job_slot_max'
            // 'exclusive_max',
            // 'exclusive_plus'
          ],
          visibility_booster: [
            'booster_token',
            'refresher_token',
            'spotlight_token',
            'keyword_highlight_token'
          ],
          talent_search: ['coins_x5000'],
          // if non of the categories match , it will fall under others
          other: []
        };

        const counts = {
          job_slot: 0,
          visibility_booster: 0,
          talent_search: 0,
          other: 0
        };

        for (const code of productCodes) {
          let found = false;
          for (const [category, codes] of Object.entries(categories)) {
            if (codes.includes(code)) {
              counts[category]++;
              found = true;
              break;
            }
          }
          if (!found) {
            categories.other.push(code);
            counts.other++;
          }
        }

        if (
          counts.job_slot > 0 &&
          counts.visibility_booster === 0 &&
          counts.talent_search === 0 &&
          counts.other === 0
        ) {
          window.dataLayer.push({
            event: 'CE_cart-abandoned--job-slot'
          });
          cartUserProperties('job_slot');
        } else if (
          counts.job_slot === 0 &&
          counts.visibility_booster > 0 &&
          counts.talent_search === 0 &&
          counts.other === 0
        ) {
          window.dataLayer.push({
            event: 'CE_cart-abandoned--visibility-booster'
          });
          cartUserProperties('visibility_booster');
        } else if (
          counts.job_slot === 0 &&
          counts.visibility_booster === 0 &&
          counts.talent_search > 0 &&
          counts.other === 0
        ) {
          window.dataLayer.push({
            event: 'CE_cart-abandoned--talent-search'
          });
          cartUserProperties('talent_search');
        } else if (
          counts.job_slot === 0 &&
          counts.visibility_booster === 0 &&
          counts.talent_search > 0 &&
          counts.other > 0
        ) {
          window.dataLayer.push({
            event: 'CE_cart-abandoned--generic-mix'
          });
          cartUserProperties('generic_mix');
        } else {
          window.dataLayer.push({ event: 'CE_cart-abandoned--generic-mix' });
          cartUserProperties('generic_mix');
        }
      } else {
        cartUserProperties('_null');
      }
    }

    // Update the previous location
    setPreviousLocation(location);
  }, [location, previousLocation]);

  function cartUserProperties(value) {
    if (getCountry() == 'MY') {
      clevertap.profile.push({
        Site: {
          cart_status: value
        }
      });
    }
  }

  // This is for limited job slot validation checks
  const allProducts = useSelector(state => state.products.allProducts);
  // Find products that have a limited count
  const limitedProducts = allProducts?.filter(products => {
    return products?.quantityAvailable !== null;
  });

  // Find are they in a bundle
  const activeBundles = allProducts?.filter(products => {
    return products?.packageType === 'bundle';
  });

  const productsInBundle = [];

  // If in bundle, find quantity per bundle
  limitedProducts?.forEach(providedProduct => {
    activeBundles?.forEach(bundle => {
      bundle.productsInBundle.forEach(bundleProduct => {
        if (bundleProduct.product_code === providedProduct.productCode) {
          productsInBundle.push({
            id: bundleProduct.id.toString(),
            quantityAvailable: providedProduct.quantityAvailable,
            productCode: bundleProduct.product_code,
            quantityPerBundle: bundleProduct.quantity_per_bundle,
            inBundleId: bundle.id,
            inBundleCode: bundle.productCode
          });
        }
      });
    });
  });

  dispatch(updateLimitedProduct(productsInBundle));

  const [searchParams] = useSearchParams();
  const products = searchParams.get('products');

  // If not logged in and pasted a url with product params
  if (!isAuthenticated && products) {
    const productArray = products.split(',').map(product => {
      const [id, quantity] = product.split('-');
      return { id, quantity: parseInt(quantity, 10) };
    });

    localStorage.setItem('productQuery', JSON.stringify(productArray));
  }

  const debouncedAddToCart = useMemo(
    () =>
      debounce(async items => {
        await Promise.all(
          items.map(async product => {
            await dispatch(
              mutateCart({
                input: {
                  productId: product?.code || product?.id,
                  quantity: product?.quantity
                }
              })
            );
          })
        );
        localStorage.removeItem('productQuery');
        history('/purchase/choose');
        dispatch(fetchCart());
      }, 500),
    []
  );

  useEffect(() => {
    // Redirect to PPP if there's products in localstorage
    const queryStrings = localStorage.getItem('productQuery');
    const isJobsOrWelcomePage =
      location.pathname.includes('jobs') ||
      location.pathname.includes('welcome');

    if (isJobsOrWelcomePage && queryStrings) {
      const productArray = JSON.parse(localStorage.getItem('productQuery'));

      if (!isEmpty(allProducts)) {
        const mappedProduct = productArray?.map(product => {
          const activeProduct = allProducts?.find(
            active => active.productCode === product.id
          );

          if (activeProduct) {
            return { code: activeProduct.id, ...product };
          } else {
            dispatch(updateProductInactivePopupStatus(true));
            return null;
          }
        });

        debouncedAddToCart(mappedProduct);
      }
    }
  }, [allProducts]);

  useTawkToEffect();

  const hasCompletedOrdersOrPostedJobs = localStorage.getItem(
    'hasCompletedOrdersOrPostedJobs'
  );

  const valid = hasCompletedOrdersOrPostedJobs === 'true';

  useEffect(() => {
    if (
      location.pathname.includes('purchase/confirm') &&
      location.search.includes('product=')
    ) {
      store.getState().products.confirmOrderPath =
        location.pathname + location.search;
    }

    if (
      location.pathname.includes('/login') ||
      location.pathname.includes('/register') ||
      location.pathname.includes('/register/login-info')
    ) {
      if (localStorage.getItem('accessToken')) {
        // Redirect user back to job listing page if already logged in
        history('/jobs', { replace: true });
      } else {
        return;
      }
    }

    if (isAuthenticated && location.pathname === '/' && valid) {
      history('/jobs', { replace: true });
    } else if (isAuthenticated && location.pathname === '/' && !valid) {
      history('/welcome', { replace: true });
    }

    if (!isAuthenticated) {
      if (
        location.pathname.includes('/waitlist') ||
        location.pathname == '/promotions' ||
        location.pathname.includes('/reset-password') ||
        location.pathname.includes('/forgot-password') ||
        location.pathname.includes('/register/login-info') ||
        location.pathname.includes('/register/verification') ||
        location.pathname.includes('/applicant-snapshot-summary')
      ) {
        return;
      } else {
        history('/login', { replace: true });
      }
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchMyCompanyStatus()).then(res => {
        if (res.company) {
          localStorage.setItem(
            'hasCompletedOrdersOrPostedJobs',
            res.company.hasCompletedOrdersOrPostedJobs
          );

          if (
            !res.company.hasCompletedOrdersOrPostedJobs &&
            location.pathname.includes('/jobs')
          ) {
            history('/welcome');
          }
        }
      });

      if (
        !document.cookie.includes('gtmCountry') ||
        !document.cookie.includes('companyId')
      ) {
        const companyId = localStorage.getItem('accountInfo')?.companyId;
        const country = localStorage.getItem('country');

        document.cookie = `companyId=${companyId}; path=/; max-age=31540000`;
        document.cookie = `gtmCountry=${country}; path=/; max-age=31540000`;
      }

      const timer = setTimeout(() => {
        dispatch(fetchAccountInfo());
        dispatch(fetchCompanyName());
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [isAuthenticated]);

  const expiry = parseJwt(isAuthenticated)?.expiry_at;
  const loginTime = parseJwt(isAuthenticated)?.login_at;

  // const resetPassword = parseJwt(isAuthenticated)?.reset_password_at;
  const accountInfo = JSON.parse(localStorage.getItem('accountInfo'));
  const resetPassword = accountInfo?.resetPasswordAt;

  const expiryMoment = moment(expiry, 'YYYY-MM-DD HH:mm:ss Z');
  const resetMoment = moment(resetPassword, 'YYYY-MM-DD HH:mm:ss Z');
  const loginMoment = moment(loginTime, 'YYYY-MM-DD HH:mm:ss Z');
  const isExpired = resetMoment.isAfter(expiryMoment);
  const isResetAfterLogin = resetMoment.isAfter(loginMoment);

  const onLogout = async () => {
    dispatch(clearAccountInfo());
    dispatch(clearJobInfo());
    dispatch(clearOrderInfo());

    try {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('accountInfo');
      localStorage.removeItem('currentAccountType');
      localStorage.removeItem('lightcast-token');
      localStorage.removeItem('lightcast-token-timeout');
      localStorage.removeItem('hideNewProductsInfo');

      var expiresInTime = 'expires=' + 'Sun, 27 Mar 2020 10:13:42 GMT';

      document.cookie = 'companyId' + '=' + ';' + expiresInTime + ';path=/'; // Delete cookie for user ID sent to GA when user logs out

      history('/login');
    } catch (error) {
      toast.error(`Logout Failed! ${error.message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  useEffect(() => {
    if (isExpired || isResetAfterLogin) {
      onLogout();
      WarningToast('Your session has expired');
    }
  }, [isExpired, isResetAfterLogin]);

  return children;
}
