import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchWaitlistInfo,
  updateCurrentBg
} from '../../../../redux/actions/company_actions';
import { Box } from '@mui/material';
import { SuccessToast, ErrorToast } from '../../../../utils/ToastUtils';
import HiredlyLogo from '../../../../assets/common/hiredly-logo-copyright.png';
import { EmailShareButton, WhatsappShareButton } from 'react-share';

import {
  PageContainer,
  Message,
  MessageBlack,
  SecondContainer,
  HeightSpacer,
  FooterContainer,
  BoldMessage,
  SocialButton,
  CopySection,
  CopyText,
  CopyButton,
  WhatsAppIconStyled,
  EmailIconStyled,
  CopyIconStyled,
  TickIconStyled,
  AnimatedContainer,
  ContentContainer,
  Title,
  BoldTitle,
  MessageBox,
  FooterContainerLink,
  MessageLink,
  LogoImage,
  MessageLine
} from './styles';
import { CSEMAIL } from '../../../../utils/Constants';
import { getCountry } from '../../../../hooks/useRegionalisation';

export default function Complete(props) {
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState(false);
  const [isCopyIconTick, setIsCopyIconTick] = useState(false);
  const formValue = useSelector(state => state.companies.formValue);
  let shareUrl = window.location.href;

  const waitlistIndex = shareUrl.indexOf('/waitlist');

  if (waitlistIndex !== -1) {
    shareUrl = shareUrl.slice(0, waitlistIndex + '/waitlist'.length);
  }

  const onClickContactUs = () => {
    window.open(`mailto:${CSEMAIL[getCountry()]}`);
  };

  const copyTextToClipboard = async text => {
    try {
      if ('clipboard' in navigator) {
        await navigator.clipboard.writeText(text);
        SuccessToast('Code copied successfully.');
      } else {
        ErrorToast('Failed to copy!');
        document.execCommand('copy', true, text);
      }
      setIsCopied(true); // Set isCopied to true on successful copy
      setIsCopyIconTick(true);
      setTimeout(() => {
        setIsCopied(false); // Reset isCopied after a delay
        setIsCopyIconTick(false);
      }, 1500);
    } catch (err) {
     // console.log(err);
    }
  };

  useEffect(() => {
    dispatch(updateCurrentBg('5'));
    const waitlistInfoString = localStorage.getItem('waitlistInfo');

    if (waitlistInfoString) {
      try {
        const waitlistInfo = JSON.parse(waitlistInfoString);
        if (
          waitlistInfo &&
          waitlistInfo.companyWaitlist &&
          waitlistInfo.companyWaitlist.email
        ) {
          const response = dispatch(
            fetchWaitlistInfo({ email: waitlistInfo.companyWaitlist.email })
          );
        } else {
        }
      } catch (error) {
        // console.log(error);
      }
    }
  }, []);

  return (
    <PageContainer>
      <AnimatedContainer>
        <ContentContainer>
          <Title>
            Welcome to <LogoImage src={HiredlyLogo} alt="hiredly-logo" />
          </Title>

          {formValue.refereeCode && (
            <MessageBox>
              <BoldTitle>
                You were invited by a friend from {formValue.refereeCompanyName}
                .
              </BoldTitle>
              <Message>
                As part of our community, you’ll be prioritised in our next
                available slots for a dedicated Hiredly Company Profile and
                Employer Branding experience!
              </Message>
            </MessageBox>
          )}

          {!formValue.refereeCode && (
            <Message>
              Thank you for registering! Rest assured that our team will be in
              touch directly once you have been selected.
            </Message>
          )}

          <MessageLink>
            Questions?{' '}
            <FooterContainerLink onClick={onClickContactUs}>
              Get in touch with us
            </FooterContainerLink>
          </MessageLink>
        </ContentContainer>
      </AnimatedContainer>

      <SecondContainer>
        <BoldMessage>Refer a Friend Now</BoldMessage>

        <MessageBlack>
          Each successful referral <b> moves you up the list </b> and{' '}
          <b>boosts your ranking!</b> 🎉
        </MessageBlack>

        <Box>
          <MessageBlack>
            Share the Hiredly magic by copying and sharing the referral code
            below:
          </MessageBlack>

          <HeightSpacer $height={'16px'} />

          <CopySection>
            <CopyText type="text" value={formValue.referralCode} readOnly />

            <CopyButton
              onClick={() => copyTextToClipboard(formValue.referralCode)}>
              {isCopyIconTick ? <TickIconStyled /> : <CopyIconStyled />}
              <span>{isCopied ? 'Copied!' : 'Copy'}</span>
            </CopyButton>
          </CopySection>
        </Box>

        <Box>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <hr
              style={{ flex: 1, borderTop: '1px solid rgba(0, 0, 0, 0.60)' }}
            />
            <MessageLine>Or share via</MessageLine>
            <hr
              style={{ flex: 1, borderTop: '1px solid rgba(0, 0, 0, 0.60)' }}
            />
          </div>

          <HeightSpacer $height={'16px'} />

          <FooterContainer>
            <SocialButton>
              <WhatsappShareButton
                id="whatsapp-share-job-button"
                url={shareUrl + '/?refcode=' + formValue.referralCode}
                title={'Join Now'}
                separator=": "
                style={{
                  display: 'inline-flex',
                  gap: '16px',
                  alignItems: 'center',
                  padding: '8px',
                  width: '100%',
                  justifyContent: 'center'
                }}>
                <WhatsAppIconStyled />
              </WhatsappShareButton>
            </SocialButton>

            <SocialButton>
              <EmailShareButton
                id="email-share-waitlist"
                url={shareUrl + '/?refcode=' + formValue.referralCode}
                title={'Join Now'}
                style={{
                  display: 'inline-flex',
                  gap: '16px',
                  alignItems: 'center',
                  padding: '8px',
                  width: '100%',
                  justifyContent: 'center'
                }}>
                <EmailIconStyled />
              </EmailShareButton>
            </SocialButton>
          </FooterContainer>
        </Box>
      </SecondContainer>
    </PageContainer>
  );
}
