import { styled as styling } from '@mui/material/styles';

export const RequestContainer = styling('div')({
  width: '100%',
  padding: '8px',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '25px',
});

export const RequestCard = styling('div')({
  backgroundColor: 'white',
  width: '300px',
  height: '100%',
  padding: '15px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
});

export const SpinnerContainer = styling('div')({
  padding: '15px 15px 2px 15px',
});

export const ButtonContainer = styling('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: '10px',
});

export const Button = styling('div')({
  color: '#512acc',
  padding: '15px 15px 0 15px',
  cursor: 'pointer',
});
