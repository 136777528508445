import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Grid, Tooltip } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
import DefaultLayout from '../../layouts/DefaultLayout';
import ToolBar from './ToolBar/index';
import TalentInformation from './TalentInformation/index';
import TalentsColumn from './TalentsColumn';
import SkeletonLoader from './TalentsColumn/SkeletonLoader';
import TalentSort from './TalentsColumn/TalentSort';
import { TALENTS_SORT_BY, SEARCH_TYPE } from '../../utils/Constants';
import styles from './index.module.scss';
import moment from 'moment';
import { ErrorToast } from '../../utils/ToastUtils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyCompanyStatus } from '../../redux/actions/company_actions';
import { fetchSearchTalentsOld } from '../../redux/actions/talent_action';

let firstApplicationId = '';
let currentTalents = [];

const ActiveCVAccess = () => {
  const dispatch = useDispatch();
  const [isSelected, setIsSelected] = useState(null);
  const [talents, setTalents] = useState([]);
  const [sortBy, setSortBy] = useState(TALENTS_SORT_BY.RELEVANCE);
  const [searchText, setSearchText] = useState('');
  const [searchType, setSearchType] = useState(SEARCH_TYPE.ANY);
  const [advancedFilter, setAdvancedFilter] = useState({
    yearsOfExp: null,
    videoCoverLetter: null,
    malaysians: null,
    highestEduLevel: [],
    trackIds: []
  });
  const [talentColumnHeight, setTalentColumnHeight] = useState(0);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isLoadingHiddenData, setIsLoadingHiddenData] = useState(false);

  useEffect(() => {
    dispatch(fetchMyCompanyStatus());
  }, []);

  const myCompanyStatus = useSelector(state => state.companies.myCompanyStatus);

  const getSortBy = () => {
    switch (sortBy) {
      case TALENTS_SORT_BY.RELEVANCE:
        return null;
      case TALENTS_SORT_BY.LAST_ACTIVE_AT:
        return { by: 'last_active_at', direction: 'desc' };
      case TALENTS_SORT_BY.UPLOAD_RESUME_AT:
        return { by: 'upload_resume_at', direction: 'desc' };
      default:
        return null;
    }
  };

  const getSearchType = () => {
    switch (searchType) {
      case SEARCH_TYPE.ANY:
        return ['or', 'word'];
      case SEARCH_TYPE.ALL:
        return ['and', 'word'];
      case SEARCH_TYPE.EXACT:
        return ['nil', 'phrase'];
      default:
        return null;
    }
  };

  const getGraduationYear = () => {
    if (advancedFilter?.yearsOfExp) {
      const cYearOfExp = advancedFilter.yearsOfExp / 10;
      const max = moment().year() - cYearOfExp;
      const min = max - 100;

      return {
        min,
        max
      };
    }

    return null;
  };

  const isAdvanceFilter = () => {
    if (
      advancedFilter?.yearsOfExp ||
      advancedFilter?.videoCoverLetter ||
      advancedFilter?.malaysians ||
      advancedFilter?.highestEduLevel.length > 0 ||
      advancedFilter?.trackIds.length > 0
    ) {
      return true;
    }

    return false;
  };

  const getTalentsFunction = () => {
    dispatch(
      fetchSearchTalentsOld({
        first: 10,
        after: '',
        genders: [],
        filterMode: getSearchType()[0],
        keyword: searchText,
        wordMatch: getSearchType()[1],
        advancedSearch: isAdvanceFilter(),
        trackIds: advancedFilter?.trackIds,
        nationalities: advancedFilter?.malaysians ? ['Malaysian'] : [],
        withVideoCoverLetter: advancedFilter?.videoCoverLetter
          ? advancedFilter?.videoCoverLetter
          : false,
        graduationYear: getGraduationYear(),
        sort: getSortBy(),
        educationLevels: advancedFilter?.highestEduLevel
      })
    );
  };

  const searchTalentsOld = useSelector(state => state.talents.searchTalentsOld);

  const fetchingSearchTalentsOld = useSelector(
    state => state.talents.fetchingSearchTalentsOld
  );

  const data = searchTalentsOld;

  const getCurrentTalents = useCallback(() => {
    return currentTalents;
  }, []);

  const setCurrentTalents = cTalents => {
    currentTalents = cTalents;
  };

  const updateIsRefresh = status => {
    firstApplicationId = '';
    setIsRefresh(status);
  };

  useEffect(() => {
    getTalentsFunction();

    return () => {
      // ComponentWillUnmount
      firstApplicationId = '';
    };
  }, [searchText, sortBy, advancedFilter]);

  useEffect(() => {
    if (error) {
      const err = error?.graphQLErrors[0]?.message;

      ErrorToast(err);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      if (isRefresh) {
        setTalents(data?.edges);
        currentTalents = data?.edges;
      } else {
        let proceed = true;
        if (data?.edges?.length > 0) {
          if (data.edges[0].node.id == firstApplicationId) {
            proceed = false;
          }
        }

        if (proceed) {
          setTalents([...talents, ...data?.edges]);
          currentTalents = [...talents, ...data?.edges];
        }
      }

      if (data?.edges?.length > 0) {
        firstApplicationId = data.edges[0].node.id;
      }

      if (isRefresh) {
        setIsRefresh(false);
      }
    }
  }, [searchTalentsOld]);

  const ref = useRef(null);

  const scrollToTop = () => {
    if (ref?.current) {
      ref.current.scrollTo(0, 0);
    }
  };

  let image =
    'https://s3.ap-southeast-1.amazonaws.com/assets.wobbjobs.com/images/employers/default_states/hiredly-active-cv.svg';

  return (
    <DefaultLayout minusBetaBanner={true}>
      <div className={styles.infoBar}>
        <h6 style={{ fontSize: '20px' }} className={styles.activeCVText}>
          Active CV Access
        </h6>
        <div className={styles.subContainer}>
          <p className={styles.subText}>
            Please note that as per our Terms & Conditions, contacts that you
            access from Hiredly may only be approached for your own recruiting
            purposes.
          </p>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <p
              className={styles.subText}
              style={{
                marginRight: 10
              }}>{`${myCompanyStatus?.activeCvAccessUsed}/${myCompanyStatus?.activeCvAccessLimit} views this month`}</p>
            <Tooltip title="You may view up to 500 candidate profiles every month. This counter will be reset at the start of your monthly cycle.">
              <InfoIcon className={styles.infoIcon} />
            </Tooltip>
          </div>
        </div>
      </div>
      <ToolBar
        setAdvancedFilter={setAdvancedFilter}
        setSearchText={setSearchText}
        setIsRefresh={updateIsRefresh}
        searchType={searchType}
        setSearchType={setSearchType}
        advancedFilter={advancedFilter}
      />
      <Grid
        container
        className={styles.gridContainer}
        style={{ display: 'flex' }}>
        {/* <Grid item sm={12} md={4} className={styles.talentColumnWrapper}> */}
        <Grid className={styles.talentColumnWrapper}>
          {talents.length === 0 && fetchingSearchTalentsOld ? (
            <Grid container>
              <div className={styles.skeletonContainer}>
                <Grid item xs={12}>
                  <TalentSort
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    setIsRefresh={updateIsRefresh}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div style={{ marginTop: 30 }}>
                    <SkeletonLoader customClassName={styles.skeletonWrapper} />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <SkeletonLoader customClassName={styles.skeletonWrapper} />
                </Grid>
                <Grid item xs={12}>
                  <SkeletonLoader customClassName={styles.skeletonWrapper} />
                </Grid>
              </div>
            </Grid>
          ) : (
            <TalentsColumn
              talents={talents}
              setTalents={setTalents}
              pageInfo={data?.pageInfo}
              accData={myCompanyStatus}
              getTalents={() =>
                dispatch(
                  fetchSearchTalentsOld({
                    first: 10,
                    after: data?.pageInfo?.endCursor,
                    genders: [],
                    filterMode: getSearchType()[0],
                    keyword: searchText,
                    wordMatch: getSearchType()[1],
                    advancedSearch: isAdvanceFilter(),
                    trackIds: advancedFilter?.trackIds,
                    nationalities: advancedFilter?.malaysians
                      ? ['Malaysian']
                      : [],
                    withVideoCoverLetter: advancedFilter?.videoCoverLetter
                      ? advancedFilter?.videoCoverLetter
                      : false,
                    graduationYear: getGraduationYear(),
                    sort: getSortBy(),
                    educationLevels: advancedFilter?.highestEduLevel
                  })
                )
              }
              isSelected={isSelected}
              setIsSelected={setIsSelected}
              talentColumnHeight={talentColumnHeight}
              loading={fetchingSearchTalentsOld}
              isRefresh={isRefresh}
              setIsRefresh={updateIsRefresh}
              sortBy={sortBy}
              setSortBy={setSortBy}
              scrollToTop={scrollToTop}
              getCurrentTalents={getCurrentTalents}
              setCurrentTalents={setCurrentTalents}
              setIsLoadingHiddenData={setIsLoadingHiddenData}
            />
          )}
        </Grid>

        <Grid className={styles.talentInfoWrapper} ref={ref}>
          {isSelected == null && talents.length !== 0 ? (
            <div className={styles.placeholderContainer}>
              <div className={styles.placeholderWrapper}>
                <img className={styles.imageContent} src={image} />
                <div className={styles.textContainer}>
                  <p className={styles.description}>
                    Select a candidate from the list on the left to view their
                    profile.
                  </p>
                  <p className={styles.notice}>
                    {' '}
                    {`${myCompanyStatus?.activeCvAccessUsed}/${myCompanyStatus?.activeCvAccessLimit} views this month`}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <TalentInformation
                talent={talents[isSelected]?.node}
                talents={talents}
                isSelected={isSelected}
                setIsSelected={setIsSelected}
                setTalentColumnHeight={setTalentColumnHeight}
                isFirsItem={talents.indexOf(talents[isSelected]) === 0}
                isLastItem={
                  talents.indexOf(talents[isSelected]) === talents.length - 1
                }
                loading={fetchingSearchTalentsOld}
                getCurrentTalents={getCurrentTalents}
                setCurrentTalents={setCurrentTalents}
                setTalents={setTalents}
                accData={myCompanyStatus}
                isLoadingHiddenData={isLoadingHiddenData}
                setIsLoadingHiddenData={setIsLoadingHiddenData}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </DefaultLayout>
  );
};

export default ActiveCVAccess;
