import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HeightSpacer } from '../../../../components/Spacer/styles';
import {
  featureJob,
  updateAllJobs,
  updateFeaturedJobPopup,
  updateCrossBorderTokensCount,
  updateCrossBorderConfirmationPopup,
  mutateCrossBorder,
  mutateGlobalHire,
  mutateCrossBorderAndGlobalHire
} from '../../../../redux/actions/job_action';
import { ErrorToast, SuccessToast } from '../../../../utils/ToastUtils';
import store from '../../../../redux/stores/store';
import TokensPopup from '../../SharedPopups/TokensPopup';
import {
  ContentDescription,
  ContentInfo,
  ExplainerContainer,
  ExplainerInfo,
  ExplainerInfoWrapper,
  ExplainerNote,
  ExplainerTitle,
  GifContainer,
  ContentContainer,
  CTAButtonsContainer,
  CTAContainer,
  Title,
  TitleContainer
} from './styles';
import { Close as CloseIcon } from '@mui/icons-material';
import { Dialog, Divider, IconButton } from '@mui/material';
import { SharedButton } from '../../JobList/SharedButton';
import { mutateCart } from '../../../../redux/actions/order_action';
import CrossBorderPopUp from '../CrossBorderPopUp';
import GlobalHirePopUp from '../GlobalHirePopUp';

const CrossBorderJobPopUp = ({ jobTitle, jobId, jobData }) => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const isCrossBorderConfirmationOpen = useSelector(
    state => state.jobs.isCrossBorderConfirmationOpen
  );
  const loading = useSelector(state => state.jobs.featuringJob);
  const allJobs = store.getState().jobs.jobs;

  const crossBorderTokensUsableCount = useSelector(
    state =>
      state.jobs.subscribedCrossBorderTokensPackage?.total_usable_quantity
  );

  const CrossBorderTokenVisualExplainer =
    'https://assets.wobbjobs.com/images/employers/cross-border-token-visual-explainer.gif';

  // const crossBorderTokensUsableCount = 2;

  const [globalHireDefault, setGlobalHireDefault] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [currentPage, setCurrentPage] = useState('0');
  const [overlay, setOverlay] = useState(false);

  const [fields, setFields] = useState({
    enabled: jobData?.globalHirePreferences?.enabled || false,
    workingArrangementRemote:
      jobData?.globalHirePreferences?.workingArrangementRemote || false,
    workingArrangementOnSite:
      jobData?.globalHirePreferences?.workingArrangementOnSite || false,
    workingArrangementHybrid:
      jobData?.globalHirePreferences?.workingArrangementHybrid || false,
    rightToWorkOpenToSponsor:
      jobData?.globalHirePreferences?.rightToWorkOpenToSponsor || false,
    rightToWorkLocalCitizen:
      jobData?.globalHirePreferences?.rightToWorkLocalCitizen || false,
    rightToWorkPermanentCitizen:
      jobData?.globalHirePreferences?.rightToWorkPermanentCitizen || false,
    rightToWorkWorkVisaOrEmploymentPass:
      jobData?.globalHirePreferences?.rightToWorkWorkVisaOrEmploymentPass ||
      false
  });

  useEffect(() => {
    if (jobData) {
      setFields({
        enabled: jobData?.globalHirePreferences?.enabled || false,
        workingArrangementRemote:
          jobData?.globalHirePreferences?.workingArrangementRemote || false,
        workingArrangementOnSite:
          jobData?.globalHirePreferences?.workingArrangementOnSite || false,
        workingArrangementHybrid:
          jobData?.globalHirePreferences?.workingArrangementHybrid || false,
        rightToWorkOpenToSponsor:
          jobData?.globalHirePreferences?.rightToWorkOpenToSponsor || false,
        rightToWorkLocalCitizen:
          jobData?.globalHirePreferences?.rightToWorkLocalCitizen || false,
        rightToWorkPermanentCitizen:
          jobData?.globalHirePreferences?.rightToWorkPermanentCitizen || false,
        rightToWorkWorkVisaOrEmploymentPass:
          jobData?.globalHirePreferences?.rightToWorkWorkVisaOrEmploymentPass ||
          false
      });
    }
  }, [jobData]);

  const [crossBorder, setCrossBorder] = useState({
    crossBorderDestinations: jobData?.crossBorderDestinations
      ? jobData?.crossBorderDestinations
      : []
  });

  const [errors, setErrors] = useState(false);

  const [errorsCrossBorder, setErrorsCrossBorder] = useState(false);

  useEffect(() => {
    if (crossBorder?.crossBorderDestinations?.length == 0) {
      setErrorsCrossBorder(true);
    } else {
      setErrorsCrossBorder(false);
    }
  }, [crossBorder]);

  useEffect(() => {
    const isValid = validationSchema();

    if (!isValid) {
      setIsDisabled(true);
      return;
    } else {
      setIsDisabled(false);
    }

    //   if (!isValid) {
    //     setErrors(true);
    //     return;
    //   }
  }, [fields]);

  const onClosePopup = (count, jobData = {}) => {
    dispatch(updateCrossBorderConfirmationPopup(false));
    setCrossBorder([]);
    setCurrentPage('0');
  };

  const getButtonText = (count, jobData, page) => {
    if (count > 0) {
      if (page == '0') {
        return `Continue`;
      } else if (page == '1' && !jobData?.globalHirePreferences?.enabled) {
        return `Continue`;
      } else if (page == '2') {
        return `Apply 1 Token`;
      } else {
        return `Apply 1 Token`;
      }
    } else {
      return `Purchase Now`;
    }
  };

  const getPopupTitle = (count, jobData) => {
    if (count > 0) {
      if (extendDuration(jobData)) {
        return `Extend Cross Border Duration`;
      }
      return `Advertise Job Internationally?`;
    } else {
      return `No Cross Border Tokens Available`;
    }
  };

  const getContentMessage = (count, jobData) => {
    if (count > 0) {
      if (extendDuration(jobData)) {
        return `By extending Cross Border to this job, Each additional country listing will deduct one Cross Border Token from your account. `;
      }

      return 'Each additional country listing will deduct one Cross Border Token from your account.';
    } else {
      return 'Expand your job reach internationally by purchasing Cross Border Tokens!';
    }
  };

  const getCrossBorderTokenCount = (count, jobData) => {
    let tokenCount = crossBorderTokensUsableCount;

    if (count > 0) {
      return (
        <span>
          You have {''}
          <b>{tokenCount} Cross Border Tokens</b> {''} left.
        </span>
      );
    } else {
      return ``;
    }
  };

  const extendDuration = jobData => {
    if (
      jobData?.featureJobThreshold > 0 &&
      jobData?.featureJobThreshold <= 25
    ) {
      return true;
    }
    return false;
  };

  const onSubmitCrossBorder = async () => {
    if (crossBorder?.crossBorderDestinations.length == 0) {
      ErrorToast('Please select at least 1 country.');
      return;
    }

    try {
      const res = await dispatch(
        mutateCrossBorder({
          input: {
            jobId: jobId,
            crossBorderDestinations: crossBorder?.crossBorderDestinations
          }
        })
      );

      if (res?.success?.data?.crossBorderJob?.success) {
        SuccessToast('1 Cross Border Token applied successfully!');

        const currentJobIndex = allJobs.findIndex(item => {
          return item?.id == jobId;
        });

        if (allJobs[currentJobIndex]) {
          allJobs[currentJobIndex] = res.job;
        }
        dispatch(
          updateCrossBorderTokensCount(crossBorderTokensUsableCount - 1)
        );

        dispatch(updateAllJobs(allJobs));

        onClosePopup();
      } else {
        const errorMessage =
          res?.success?.errors?.length > 0
            ? res?.success?.errors[0]?.message
            : 'Cross Border Apply Failed';
        ErrorToast(errorMessage);
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle mutation dispatch error here
      ErrorToast('An error occurred while submitting the job.');
    }
  };

  const validationSchema = () => {
    if (fields.enabled) {
      const isRightToWorkValid =
        fields.rightToWorkLocalCitizen ||
        fields.rightToWorkOpenToSponsor ||
        fields.rightToWorkPermanentCitizen ||
        fields.rightToWorkWorkVisaOrEmploymentPass;

      const isWorkingArrangementValid =
        fields.workingArrangementHybrid ||
        fields.workingArrangementOnSite ||
        fields.workingArrangementRemote;

      return isRightToWorkValid && isWorkingArrangementValid;
    } else {
      return true;
    }
  };

  const onSubmitFull = async (jobData, jobId) => {
    const isValid = validationSchema();

    if (!isValid) {
      setErrors(true);
      return;
    }

    if (crossBorder?.crossBorderDestinations.length == 0) {
      ErrorToast('Please select at least 1 country.');
      return;
    }

    try {
      const resComplete = await dispatch(
        mutateCrossBorderAndGlobalHire({
          input: {
            jobId: jobId,
            enabled: true,
            rightToWorkLocalCitizen: fields?.rightToWorkLocalCitizen || false,
            rightToWorkOpenToSponsor: fields?.rightToWorkOpenToSponsor || false,
            rightToWorkPermanentCitizen:
              fields?.rightToWorkPermanentCitizen || false,
            rightToWorkWorkVisaOrEmploymentPass:
              fields?.rightToWorkWorkVisaOrEmploymentPass || false,
            workingArrangementHybrid: fields?.workingArrangementHybrid || false,
            workingArrangementOnSite: fields?.workingArrangementOnSite || false,
            workingArrangementRemote: fields?.workingArrangementRemote || false,
            crossBorderDestinations: crossBorder?.crossBorderDestinations
          }
        })
      );

      if (resComplete?.type == 'MUTATE_CROSS_BORDER_SUCCEED') {
        SuccessToast('1 Cross Border Token applied successfully!');

        const currentJobIndex = allJobs.findIndex(jobData => {
          return (
            jobData.id ==
            resComplete?.success?.data?.globalHireAndCrossBorderJob?.job?.id
          );
        });

        if (allJobs[currentJobIndex]) {
          allJobs[currentJobIndex] =
            resComplete?.success?.data?.globalHireAndCrossBorderJob?.job;
        }

        dispatch(
          updateCrossBorderTokensCount(crossBorderTokensUsableCount - 1)
        );

        dispatch(updateAllJobs(allJobs));
        onClosePopup();
      } else {
        const errorMessage =
          resComplete?.success?.errors?.length > 0
            ? resComplete?.success?.errors[0]?.message
            : 'Cross Border Apply Failed';
        ErrorToast(errorMessage);
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle mutation dispatch error here
      ErrorToast('An error occurred while submitting the job.');
    }
  };

  const onSubmitFeaturedToken = async (count, jobId, jobData) => {
    try {
      if (count > 0) {
        if (currentPage == '0') {
          setCurrentPage('1');
        } else if (
          currentPage == '1' &&
          !jobData?.globalHirePreferences?.enabled
        ) {
          setCurrentPage('2');
        } else if (
          currentPage == '2' &&
          !jobData?.globalHirePreferences?.enabled
        ) {
          // submit combined logic here
          if (crossBorderTokensUsableCount > 0) {
            await onSubmitFull(jobData, jobId);
          } else {
            ErrorToast('No Cross Border Token Available');
            return;
          }
        } else {
          await onSubmitFull(jobData, jobId);
        }
      } else {
        history(
          '/purchase/choose/?tab=visibility_boosters&products=cross_border_token-1'
        );
      }
    } catch {}
  };

  return (
    <Dialog
      open={isCrossBorderConfirmationOpen}
      onClose={currentPage == '0' && onClosePopup}
      PaperProps={{
        style: {
          maxWidth: '685px',
          borderRadius: '8px'
        }
      }}>
      <div
        style={{
          background: currentPage == '0' ? 'rgb(255, 255, 255)' : '#F5F5F5',
          width: '685px'
        }}>
        {currentPage == '0' && (
          <>
            <TitleContainer>
              <Title>
                {getPopupTitle(crossBorderTokensUsableCount, jobData)}
              </Title>
              <IconButton onClick={onClosePopup}>
                <CloseIcon />
              </IconButton>
            </TitleContainer>

            <Divider />
          </>
        )}

        {(currentPage == '1' || currentPage == '2') && (
          <>
            <IconButton
              onClick={onClosePopup}
              style={{
                position: 'absolute',
                top: '24px',
                right: '32px',
                padding: '0px'
              }}>
              <CloseIcon />
            </IconButton>
          </>
        )}

        <ContentContainer currentPage={currentPage} errors={errors}>
          {currentPage == '0' && (
            <>
              <ContentInfo>
                <GifContainer>
                  <img
                    alt="featured-token-visual-explainer"
                    style={{ width: '100%', maxHeight: '231px' }}
                    src={CrossBorderTokenVisualExplainer}
                  />
                </GifContainer>
                <ExplainerContainer>
                  <ExplainerTitle>
                    How can Cross Border Tokens help you?
                  </ExplainerTitle>
                  <ExplainerInfoWrapper>
                    <ExplainerInfo>
                      Ready to hire globally? Advertise your job across other
                      countries to attract international talents!
                    </ExplainerInfo>
                    <ExplainerInfo margin_top="true">
                      Each token applied activates your job listing in the
                      selected additional countries for 30 days.
                    </ExplainerInfo>
                  </ExplainerInfoWrapper>
                </ExplainerContainer>
              </ContentInfo>
              <HeightSpacer height={'24px'} />
              <ContentDescription>
                {getContentMessage(crossBorderTokensUsableCount, jobData)}
              </ContentDescription>
              {crossBorderTokensUsableCount > 0 && (
                <HeightSpacer height={'24px'} />
              )}
              <ContentDescription>
                {getCrossBorderTokenCount(
                  crossBorderTokensUsableCount,
                  jobData
                )}
              </ContentDescription>
            </>
          )}
          {currentPage == '1' && (
            <CrossBorderPopUp
              useAsPopUp={false}
              isInPopUp={true}
              jobId={jobId}
              crossBorder={crossBorder}
              setCrossBorder={setCrossBorder}
              open
              globalHireDefault={globalHireDefault}
              setGlobalHireDefault={setGlobalHireDefault}
              setFields={setFields}
              fields={fields}
              setOverlay={setOverlay}
              setErrorsCrossBorder={setErrorsCrossBorder}
              errorsCrossBorder={errorsCrossBorder}
            />
          )}
          {currentPage == '2' && (
            <GlobalHirePopUp
              useAsPopUp={false}
              isInPopUp={true}
              fields={fields}
              setFields={setFields}
              jobId={jobId}
              setErrors={setErrors}
              errors={errors}
              open
              globalHireDefault={globalHireDefault}
              isOverlay={false}
            />
          )}
        </ContentContainer>

        <CTAContainer>
          <CTAButtonsContainer>
            <SharedButton
              type={'no_border'}
              font_size={'14px'}
              padding={'8px 32px'}
              width={'200px'}
              onClick={() =>
                onClosePopup(crossBorderTokensUsableCount, jobData)
              }>
              Cancel
            </SharedButton>

            <SharedButton
              type={'black'}
              font_size={'14px'}
              padding={'8px 32px'}
              width={'200px'}
              border_radius={'8px'}
              text_color={'#FFF !important'}
              disabled={
                (currentPage == '1' && errorsCrossBorder) ||
                (currentPage == '2' && (errors || isDisabled))
              }
              onClick={() =>
                onSubmitFeaturedToken(
                  crossBorderTokensUsableCount,
                  jobId,
                  jobData
                )
              }>
              {getButtonText(
                crossBorderTokensUsableCount,
                jobData,
                currentPage
              )}
            </SharedButton>
          </CTAButtonsContainer>
        </CTAContainer>
      </div>
    </Dialog>
  );
};

export default CrossBorderJobPopUp;
