import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Resume from '../Resume';
import { ProfileContainer } from './styles';
import { Grid } from '@mui/material';
import { LockClosedIcon } from '@heroicons/react/24/solid';

const ResumePanel = ({ contentContainerRef }) => {
  const talent = useSelector(state => state.talents.talent);

  const [height, setHeight] = useState(0);

  const onResize = () => {
    const containerHeight =
      contentContainerRef.current.offsetParent?.clientHeight;
    if (containerHeight) {
      setHeight(containerHeight);
    }
  };

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <ProfileContainer $height={height}>
      <Resume />
    </ProfileContainer>
  );
};

export default ResumePanel;
