import { styled as styling } from '@mui/material/styles';
import { Typography, Grid, InputAdornment, IconButton } from '@mui/material';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';

export const TitleStyled = styling(Typography)({
  fontWeight: '700',
  fontSize: '14px',
  // marginBottom: '8px'
});

export const OuterWrapper = styling(Grid)({
  padding: '0px',
  display: 'flex',
  flexDirection: 'column',
  width: '95%',
  gap: '8px'
});

export const DateWrapper = styling(Grid)({
  width: '100%'
});

export const InputAdornmentStyled = styling(InputAdornment)({
  width: '100%'
});

export const IconButtonStyled = styling(IconButton)({
  width: '100%'
});

export const CalenderIcon = styling(CalendarDaysIcon)({
  color: '#AEAEAE'
});
