import { Dialog, Grid } from '@mui/material';
import React from 'react';
import {
  ButtonText,
  DialogActionsStyled,
  DialogContentStyled,
  DialogTitleStyled,
  IconButtonStyled,
  ButtonSkipEdit
} from '../../styles';
import { XMarkIcon } from '@heroicons/react/24/outline';

const popUpType = [
  {
    type: 'EDIT LIVE',
    action: 'save',
    title: 'Submit Request for New Content?',
    content:
      'By continuing, the new content will be submitted to our team for review, which would take 1-3 days. <br></br> Once approved, it will override the existing content. Would you like to continue?'
  },
  {
    type: 'EDIT REVIEW',
    action: 'save',
    title: 'Submit Request for New Content?',
    content:
      'By continuing, the new content will replace the previous content in review and will be submitted to our team as a new submission for review, which would take another 1-3 days. <br></br> Would you like to continue?'
  },
  {
    type: 'EDIT REJECT',
    action: 'save',
    title: 'Submit Request for New Content?',
    content:
      'By continuing, the new content will replace the previous content rejected and will be submitted to our team as a new submission for review, which would take another 1-3 days. <br></br> Would you like to continue?'
  },
  {
    type: 'EDIT REVIEW WITH LIVE',
    action: 'save',
    title: 'Submit Request for New Content?',
    content:
      'By continuing, the new content will replace the previous content in review and will be submitted to our team as a new submission for review, which would take another 1-3 days. <br></br> Once approved, it will override the existing content. Would you like to continue?'
  },
  {
    type: 'EDIT REJECT WITH LIVE',
    action: 'save',
    title: 'Submit Request for New Content?',
    content:
      'By continuing, the new content will replace the previous content rejected and will be submitted to our team as a new submission for review, which would take another 1-3 days. <br></br> Once approved, it will override the existing content. Would you like to continue?'
  },
  {
    type: 'REMOVE LIVE',
    action: 'delete',
    title: 'Remove Content?',
    content:
      'By continuing, this video will be removed and will no longer appear your company profile shown to jobseekers. Would you like to continue?'
  },
  {
    type: 'RETRACT REVIEW',
    action: 'delete',
    title: 'Cancel Review Request?',
    content:
      'This will cancel your review request for this video. Would you like to continue?'
  },
  {
    type: 'REMOVE REJECT',
    action: 'delete',
    title: 'Remove Content?',
    content:
      'By continuing, this video will be removed and this action cannot be undone. Would you like to continue?'
  }
];

export default function ManagePopUp(props) {
  const {
    open,
    handleClose,
    manageType,
    manageIndex,
    manageLink,
    saveUrl,
    deleteUrl
  } = props;
  const type = popUpType.find(type => type?.type === manageType);

  const procceedHandler = () => {
    if (type?.action === 'save') {
      saveUrl({ link: manageLink, manageType: manageType, index: manageIndex });
    } else {
      deleteUrl();
    }

    handleClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitleStyled>
        {type?.title}
        <IconButtonStyled onClick={handleClose}>
          <XMarkIcon height={24} width={24} />
        </IconButtonStyled>
      </DialogTitleStyled>
      <DialogContentStyled dividers>
        <Grid
          container
          sx={{ flexDirection: 'column', gap: '24px', display: 'flex' }}>
          <div
            style={{ margin: '0px !important', padding: '0px !important' }}
            dangerouslySetInnerHTML={{ __html: type?.content }}
          />
          <DialogActionsStyled>
            <Grid
              container
              sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <ButtonText onClick={() => handleClose(false)}>
                No thanks
              </ButtonText>
              <ButtonSkipEdit
                onClick={() => {
                  procceedHandler();
                }}>
                Confirm
              </ButtonSkipEdit>
            </Grid>
          </DialogActionsStyled>
        </Grid>
      </DialogContentStyled>
    </Dialog>
  );
}
