import { styled as styling } from '@mui/material/styles';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { CheckIcon } from '@heroicons/react/24/solid';

export const AnimatedContainer = styling('div')(props => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  maxHeight: '1080px',
  margin: 'auto',

  [props.theme.breakpoints.down('lg')]: {
    width: '100%'
  },

  transition: 'opacity 1s ease-in-out',
  opacity: props.out ? 0 : 1,

  animation: props.slide ? 'fadeOutAnimation ease 2s' : 'none',
  animationIterationCount: '1',
  animationFillMode: 'forwards',

  '@keyframes fadeOutAnimation': {
    '95%': {
      opacity: '100%'
    },

    '100%': {
      opacity: '0%'
    }
  },

  transform: props.slide ? 'translateY(-120%)' : 'translateY(0)',
  transition: 'transform 2s ease-in-out'
}));

export const VideoAnimatedContainer = styling(AnimatedContainer)(props => ({
  justifyContent: 'center',

  [props.theme.breakpoints.down('md')]: {
    maxWidth: '450px',
    margin: 'auto'
  }
}));

export const BrandAnimatedContainer = styling('div')(props => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  margin: 'auto',

  [props.theme.breakpoints.down('lg')]: {
    width: '100%'
  },

  transition: 'opacity 0.2s ease-in-out',
  opacity: props.out ? 0 : 1,

  animation: props.slide ? 'fadeOutAnimation ease 1s' : 'none',
  animationIterationCount: '1',
  animationFillMode: 'forwards',

  '@keyframes fadeOutAnimation': {
    '90%': {
      opacity: '100%'
    },

    '100%': {
      opacity: '0%'
    }
  },

  transform: props.slide ? 'translateY(-100%)' : 'translateY(0)',
  transition: 'transform 1s ease-in-out'
}));

export const PageContainer = styling('div')(props => ({
  display: 'flex',
  width: '100wvs',
  flexDirection: 'row',
  height: '100%',

  [props.theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  },

  animation: 'pageAnimation ease 2s',
  animationIterationCount: '1',
  animationFillMode: 'forwards',

  '@keyframes pageAnimation': {
    '0%': {
      opacity: '0%'
    },

    '100%': {
      opacity: '100%'
    }
  }
}));

export const HiredlyLogo = styling('img')(props => ({
  position: 'absolute',
  top: '20px',
  left: '20px',
  maxWidth: '86px',
  zIndex: '10'
}));

export const ContentContainer = styling('div')(props => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  gap: '24px',
  padding: '10% 0% 10% 25%',
  justifyContent: 'center',
  height: '100%',

  opacity: props.$begin ? 0 : 1,
  transition: 'opacity 0.2s ease-in-out',

  [props.theme.breakpoints.down('md')]: {
    width: '100%',
    padding: '0',
    maxWidth: '566px',
    margin: 'auto',
  },

  [props.theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '5% 10%',
    gap: '24px'
  }
}));

export const SecContentContainer = styling('div')(props => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  gap: '20px',
  padding: '2% 40px',
  justifyContent: 'space-evenly',
  height: '100%',
  width: '100%',
  alignItems: 'center',

  transform: props.slide ? 'translateY(-100%)' : 'translateY(0)',
  transition: 'transform 1s ease-in-out',

  [props.theme.breakpoints.down('md')]: {
    padding: '0px 10px 25px 10px'
  },

  [props.theme.breakpoints.down('sm')]: {
    padding: props.$begin ? '25px 50px' : '0px 50px'
  }
}));

export const Title = styling('h1')(props => ({
  fontSize: '42px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontWeight: 700,
  margin: '0',
  lineHeight: '45px',

  [props.theme.breakpoints.down('md')]: {
    fontSize: '32px',
    lineHeight: '40px'
  },

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    lineHeight: '24px'
  }
}));

export const SecTitle = styling('h1')(props => ({
  color: 'rgba(0, 0, 0, 0.87)',
  textAlign: 'right',
  fontSize: '25px',
  fontWeight: 700,
  lineHeight: '32px',
  alignSelf: 'stretch',

  '& b': {
    background:
      'linear-gradient(270deg, #512ACC 0%, #6D32C5 45.87%, #983EBA 101.73%)',
    backgroundClip: 'text',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '40px'
  },

  [props.theme.breakpoints.down('md')]: {
    fontSize: '32px',
    textAlign: 'center',
    lineHeight: '40px',

    '& b': {
      fontSize: '32px',
      lineHeight: '40px'
    },

    '& br': {
      display: 'none',
    },
  },

  [props.theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    fontSize: '17px',
    lineHeight: '24px',

    '& b': {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '30px'
    },

    '& br': {
      display: 'none',
    },
  },

  '& img': {
    width: '60%',
    marginTop: '10px',
    maxWidth: '250px',

    [props.theme.breakpoints.down('sm')]: {
      maxWidth: '110px'
    }
  }
}));

export const MessageContainer = styling('div')(props => ({
  maxWidth: '420px',
  textAlign: 'justify',

  [props.theme.breakpoints.down('md')]: {
    maxWidth: '600px',
    textAlign: 'center'
  },

  [props.theme.breakpoints.down('sm')]: {
    maxWidth: '600px',
    textAlign: 'center'
  }
}));

export const Message = styling('span')(props => ({
  letterSpacing: '0.15px',
  fontSize: '16px',
  color: 'rgba(0, 0, 0, 0.87)',
  margin: '0',
  fontWeight: 400,
  position: 'relative',
  lineHeight: '24px',

  [props.theme.breakpoints.down('md')]: {
    fontSize: '16px'
  },

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '20px'
  }
}));

export const SecondContainer = styling('div')(props => ({
  display: 'flex',
  position: 'relative',
  width: '100%',
  background: 'unset',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'flex-end',

  gap: '24px',
  textAlign: 'right',
  alignItems: 'flex-end',
  padding: '10% 25% 10% 0%',

  opacity: props.$begin ? 0 : 1,
  transition: 'opacity 0.2s ease-in-out',

  [props.theme.breakpoints.down('md')]: {
    alignItems: 'center',
    padding: '0 0 40px 0',
    width: '520px',
    margin: 'auto',
  },

  [props.theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    padding: '0px 16px 16px',
    width: '328px',
    gap: '16px'
  }
}));

export const NextPageButton = styling(Button)(props => ({
  width: '200px',
  height: '48px',
  backgroundColor: '#512ACC',
  borderRadius: '100px',
  color: '#FFF',
  fontSize: '16px',
  textTransform: 'none',
  fontWeight: '700',
  padding: '0 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',

  ':disabled': {
    backgroundColor: '#D8D8D8',
    cursor: 'not-allowed',
    color: '#FFF',

    ':hover': {
      backgroundColor: '#D8D8D8'
    }
  },

  ':hover': {
    backgroundColor: '#40249B'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '180px',
    height: '36px',
    fontSize: '14px'
  }
}));

export const FooterContainer = styling(Box)(props => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '0',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: '8px',

  cursor: props.$begin ? 'default' : 'pointer',

  [props.theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    alignItems: 'center', 
  },

  [props.theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    alignItems: 'center', 
  }
}));

export const FooterContainerLink = styling(Box)(props => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '0px',
  width: '200px',
  height: '48px',
  alignItems: 'center',
  fontWeight: 700,
  fontSize: '16px',
  color: '#512ACC',
  textDecoration: 'underline',

  '&:hover': {
    cursor: 'pointer'
  },

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    height: '36px',
  }
}));

export const HeightSpacer = styling('div')(props => ({
  height: props.$height ? props.$height : '5px'
}));

export const VideoContainer = styling('div')(props => ({
  flex: '1',
  border: '2px solid black',
  borderRadius: '15px',
  filter: 'drop-shadow(3px 0px black)',
  background: 'black'
}));

export const Grid = styling('div')(props => ({
  display: 'flex'
}));

export const Container1 = styling('div')(props => ({
  display: 'flex', // Always initially set to flex
  width: '25%',

  transition: props.$begin
    ? 'opacity 0.5s ease-in-out, width 1s ease-in-out'
    : props.register
    ? 'opacity 0.5s ease-in-out, width 1s ease-in-out'
    : !props.$begin
    ? 'opacity 2s 1s ease-in-out, width 1s ease-in-out'
    : 'opacity 0.5s ease-in-out, width 1s ease-in-out',
  opacity: props.$begin ? 0 : props.register ? 0 : 1,
  width: props.$begin ? '0%' : props.register ? '0%' : '25%',

  [props.theme.breakpoints.down('md')]: {
    width: '100%',
    height: props.$begin ? '0rem' : '25rem',
    transition: props.$begin
      ? 'opacity 0.5s ease-in-out, width 1s ease-in-out, height 1s ease-in-out'
      : props.register
      ? 'opacity 0.5s ease-in-out, width 1s ease-in-out, height 1s ease-in-out'
      : 'opacity 2s ease-in-out, width 1s ease-in-out, height 1s ease-in-out'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '100%',
    height: props.$begin ? '0rem' : '50vh',
    transition: props.$begin
      ? 'opacity 0.5s ease-in-out, width 1s ease-in-out, height 1s ease-in-out'
      : props.register
      ? 'opacity 0.5s ease-in-out, width 1s ease-in-out, height 1s ease-in-out'
      : 'opacity 2s ease-in-out, width 1s ease-in-out, height 1s ease-in-out'
  }
}));

export const Container2 = styling('div')(props => ({
  display: 'flex',
  width: '50%',
  padding: '15px',

  opacity: props.$begin ? 1 : props.register ? 0 : 1,

  [props.theme.breakpoints.down('md')]: {
    width: props.$begin ? '95%' : props.register ? '95%' : '95%',
    margin: 'auto',
    height: props.$begin ? '30rem' : props.register ? '30rem' : '30rem'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: props.$begin ? '95%' : props.register ? '95%' : '95%',
    margin: 'auto',
    height: props.$begin ? '45vh' : props.register ? '45vh' : '35vh'
  }
}));

export const Container3 = styling('div')(props => ({
  display: 'flex', // Always initially set to flex
  width: '25%',
  height: 'initial',
  pointerEvents: props.$begin ? 'none' : 'auto',
  transition: props.$begin
    ? 'opacity 0.5s ease-in-out, width 1s ease-in-out'
    : props.register
    ? 'opacity 0.5s ease-in-out, width 1s ease-in-out'
    : !props.$begin
    ? 'opacity 2s 1s ease-in-out, width 1s ease-in-out'
    : 'opacity 0.5s ease-in-out, width 1s ease-in-out',
  opacity: props.$begin ? 0 : props.register ? 0 : 1,
  width: props.$begin ? '0%' : props.register ? '0%' : '25%',

  [props.theme.breakpoints.down('md')]: {
    zIndex: '10',
    width: '100%',
    height: props.$begin ? '0rem' : props.register ? '0rem' : '20rem',
    transition: props.$begin
      ? 'opacity 0.5s ease-in-out, width 1s ease-in-out, height 1s ease-in-out'
      : props.register
      ? 'opacity 0.5s ease-in-out, width 1s ease-in-out, height 1s ease-in-out'
      : 'opacity 2s ease-in-out, width 1s ease-in-out, height 1s ease-in-out'
  },

  [props.theme.breakpoints.down('sm')]: {
    zIndex: '10',
    width: '100%',
    height: props.$begin ? '0vh' : props.register ? '0vh' : '15rem',
    transition: props.$begin
      ? 'opacity 0.5s ease-in-out, width 1s ease-in-out, height 1s ease-in-out'
      : props.register
      ? 'opacity 0.5s ease-in-out, width 1s ease-in-out, height 1s ease-in-out'
      : 'opacity 2s ease-in-out, width 1s ease-in-out, height 1s ease-in-out'
  }
}));

export const Container4 = styling('div')(props => ({
  // zIndex: 1,
  display: 'flex',
  transition: props.$begin
    ? 'width 1s ease-in-out, opacity 0.1s ease-in-out'
    : !props.$begin
    ? 'width 1s ease-in-out, opacity 0.5s ease-in-out'
    : 'width 1s ease-in-out, opacity 0.5s 0.5s ease-in-out',
  opacity: props.$begin ? 1 : 0,
  width: '50%',

  [props.theme.breakpoints.down('md')]: {
    width: '100%',
    height: props.$begin ? 'calc(100% - 30rem)' : '0rem',

    transition: props.$begin
      ? 'opacity 0.5s 0.5s ease-in-out, height 1s ease-in-out'
      : !props.$begin
      ? 'opacity 0.5s ease-in-out , height 1s ease-in-out'
      : 'opacity 0.5s 0.5s ease-in-out, height 1s ease-in-out'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '100%',
    height: props.$begin ? 'calc(100% - 45vh)' : '0vh',

    transition: props.$begin
      ? 'opacity 0.5s 0.5s ease-in-out, height 1s ease-in-out'
      : !props.$begin
      ? 'opacity 0.5s ease-in-out , height 1s ease-in-out'
      : 'opacity 0.5s 1s ease-in-out, height 1s ease-in-out'
  }
}));

export const SecondContainerBrand = styling('div')(props => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  padding: '50px 100px',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  justifyContent: 'center',
  animation: 'fadeIn ease-in-out 0.8s',
  maxHeight: '1080px',
  margin: 'auto',

  '@keyframes fadeIn': {
    '0%': {
      opacity: '0',
      transfrom: 'translateY(-100)'
    },

    '90%': {
      opacity: '0',
      transfrom: 'translateY(-60)'
    },

    '100%': {
      opacity: '1',
      transfrom: 'translateY(0)'
    }
  },

  [props.theme.breakpoints.down('md')]: {
    padding: '40px 16px',
    maxWidth: '600px',
    margin: 'auto'
  },

  [props.theme.breakpoints.down('sm')]: {
    padding: '16px 16px 32px 16px',
    gap: '16px',
  }
}));

export const TitleBrand = styling('h1')(props => ({
  fontSize: '42px',
  color: '#40249B',
  fontWeight: 700,
  margin: '0',
  lineHeight: '45px',

  [props.theme.breakpoints.down('md')]: {
    fontSize: '42px',
    textAlign: 'center'
  },
  [props.theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    textAlign: 'center',
    lineHeight: '30px'
  }
}));

export const BackButtonBrand = styling(IconButton)(props => ({
  padding: '8px 0',
  ':hover': {
    background: 'none',
  },
  ':disabled': {
    cursor: 'not-allowed'
  }
}));

export const NextPageButtonBrand = styling(Button)(props => ({
  width: '200px',
  height: '48px',
  backgroundColor: '#2E2E2E',
  borderRadius: '100px',
  color: '#FFF',
  fontSize: '16px',
  textTransform: 'none',
  fontWeight: '700',
  padding: '0 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',

  ':disabled': {
    backgroundColor: '#D8D8D8',
    cursor: 'not-allowed',
    color: '#FFF',

    ':hover': {
      backgroundColor: '#D8D8D8'
    }
  },

  ':hover': {
    backgroundColor: '#222'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '180px',
    height: '36px',
    fontSize: '14px'
  }
}));

export const FooterContainerBrand = styling(Box)(props => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0',
  alignItems: 'center'
}));

export const KeyboardBackspaceIconStyled = styling(ArrowLeftIcon)(props => ({
  padding: '0',
  width: '24px',
  height: '24px',
  color: '#000'
}));

export const CommonBox = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  alignSelf: 'stretch',

  [props.theme.breakpoints.down('sm')]: {
    gap: '8px',
    textAlign: 'left',
    padding: '0 16px',
  },
}));

export const CommonBoxText1 = styling('div')(props => ({
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '24px',
  width: '300px',
  color: 'rgba(0, 0, 0, 0.87)',
  alignSelf: 'stretch',

  [props.theme.breakpoints.down('md')]: {
    width: 'unset',
  },

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.1px'
  },
}));

export const CommonBoxText2 = styling('div')(props => ({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '25px',
  fontWeight: 700,
  lineHeight: '32px',

  '& br': {
    display: 'none',
  },

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    lineHeight: '24px',

    '& br': {
      display: 'block',
    },
  },

}));

export const Checklist = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
  alignSelf: 'stretch'
}));

export const ChecklistLine = styling('div')(props => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch'
}));

export const XMarkIconStyled = styling(XMarkIcon)(props => ({
  color: '#BE4242',
  width: '20px',
  height: '20px',

  [props.theme.breakpoints.down('sm')]: {
    width: '16px',
    height: '16px',
  },
}));

export const CheckIconStyled = styling(CheckIcon)(props => ({
  color: '#512ACC',
  width: '20px',
  height: '20px',
  flexShrink: '0',

  [props.theme.breakpoints.down('sm')]: {
    width: '16px',
    height: '16px',
  },
}));

export const IconBox = styling('div')(props => ({
  display: 'flex',
  width: '24px',
  height: '24px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',

  [props.theme.breakpoints.down('sm')]: {
    width: '20px',
    height: '20px',
  },
}));

export const ChecklistText = styling('div')(props => ({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  width: 'calc(100% - 20px)',
  textAlign: 'left',

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0',
  },
}));

export const SecTitleContainer = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  gap: '12px',
  alignSelf: 'stretch',

  [props.theme.breakpoints.down('md')]: {
    gap: '8px',
  },

  [props.theme.breakpoints.down('sm')]: {
    gap: '2px',
  }
}));

export const TitleGrey = styling('div')(props => ({
  color: 'rgba(0, 0, 0, 0.60)',
  fontSize: '16px',
  fontStyle: 'italic',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',

  [props.theme.breakpoints.down('md')]: {
    width: '100%',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    textAlign: 'center',
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '100%',
    fontSize: '12px',
    lineHeight: '20px',
    textAlign: 'center',
  }
}));

export const MessageBrand = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  gap: '24px',
  alignSelf: 'stretch',

  [props.theme.breakpoints.down('sm')]: {
    gap: '16px',
  } 
}));

export const MessageBrandLine = styling('hr')(props => ({
  height: '1px',
  width: '100%',
  background: '#D8D8D8',
  margin: '0'
}));

export const MessageBrandTextBox = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  gap: '4px',
  alignSelf: 'stretch'
}));

export const MessageBrandTitle = styling('div')(props => ({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px',
  alignSelf: 'stretch',

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '20px',
  }
}));

export const MessageBrandContext = styling('div')(props => ({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  alignSelf: 'stretch',

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '20px',
  }
}));

export const MessageBrandTitlePurple = styling('div')(props => ({
  color: '#512ACC',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px',
  alignSelf: 'stretch',

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '20px'
  } 
}));

export const MessageBrandTitleGrey = styling('div')(props => ({
  color: 'rgba(0, 0, 0, 0.60)',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  fontStyle: 'italic',
  alignSelf: 'stretch',

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.15px',
  } 
}));
