import { useEffect } from 'react';
import { ENV } from '../utils/Constants';
import { tawkTo } from '../utils/TawkToUtils';
import { ErrorToast } from '../utils/ToastUtils';
import { useSelector } from 'react-redux';

const { REACT_APP_ENV, REACT_APP_TAWKTO_PROPERTY_ID, REACT_APP_TAWKTO_KEY } =
  process.env;

function useTawkToEffect() {
  const accData = useSelector(state => state.companies.accountInfo);
  const compData = useSelector(state => state.companies.myCompany);

  useEffect(() => {
    try {
      if (
        REACT_APP_ENV === ENV.PROD &&
        REACT_APP_TAWKTO_PROPERTY_ID &&
        REACT_APP_TAWKTO_KEY &&
        accData &&
        compData
      ) {
        tawkTo(
          REACT_APP_TAWKTO_PROPERTY_ID,
          REACT_APP_TAWKTO_KEY,
          accData?.email,
          accData?.name,
          compData?.name
        );
      }
    } catch (err) {
      ErrorToast(err.message);
    }
  }, [accData, compData]);
}

export default useTawkToEffect;