import { styled as styling } from '@mui/material/styles';
import {
  Typography,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl
} from '@mui/material';

export const TitleStyled = styling(Typography)({
  fontWeight: '700',
  fontSize: '14px',
  // marginBottom: '8px'
});

export const OuterWrapper = styling(Grid)({
  padding: '0px',
  display: 'flex',
  flexDirection: 'column',
  width: '95%',
  gap: '8px'
});

export const CheckboxStyled = styling(Checkbox)({
  '&.Mui-checked': {
    color: 'black'
  },

  padding: '4px 9px'
});

export const FormGroupStyled = styling(FormGroup)({});

export const FormControlStyled = styling(FormControl)({
  paddingLeft: '8px'
});

export const FormControlLabelStyled = styling(FormControlLabel)({});
