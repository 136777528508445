import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import {
  updateCurrentOnboardingPage,
  updateOnboardingFormFields,
  fetchWaitlistInfo,
  updateCurrentBg
} from '../../../../redux/actions/company_actions';
import { Grid, Box, TextField } from '@mui/material';
import HiredlyLogo from '../../../../assets/common/hiredly-logo-copyright.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { SuccessToast, ErrorToast } from '../../../../utils/ToastUtils';
import './WaitlistRegister.css';

import {
  PageContainer,
  SecondContainer,
  HeightSpacer,
  BackButton,
  NextPageButton,
  FooterContainer,
  KeyboardBackspaceIconStyled,
  TextFieldLabel,
  AnimatedContainer,
  ContentContainer,
  Title,
  Message,
  MessageGrey,
  ContentWithLogo,
  ContentLogo,
  ContentMessageBox,
  MessageBox,
  MessageImageBox,
  ImageBox,
  ImageBoxText
} from './styles';

export default function Register(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const formValue = useSelector(state => state.companies.formValue);
  const isDirect = useSelector(state => state.companies.directRegister);

  const queryParams = new URLSearchParams(location.search);
  const refCode = queryParams.get('refcode');

  const [fields, setFields] = useState({
    name: formValue.name,
    companyName: formValue.companyName,
    email: formValue.email,
    phoneNumber: formValue.phoneNumber,
    inviteCode: formValue.inviteCode
    // inviteCode: refCode || formValue.inviteCode
  });

  const [errors, setErrors] = useState({});

  const handleChange = event => {
    const { name, value } = event.target;
    const updatedFields = { ...fields, [name]: value };

    setFields(updatedFields);
    setErrors({ ...errors, [name]: '' });
  };

  const validate = data => {
    const newErrors = {};
    if (!data.name) {
      newErrors.name = 'Name is required.';
    }
    if (!data.companyName) {
      newErrors.companyName = 'Company Name is required.';
    }
    if (!data.email) {
      newErrors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      newErrors.email = 'Invalid email address.';
    }

    if (!data.phoneNumber) {
      newErrors.phoneNumber = 'Phone Number is required.';
    }
    return newErrors;
  };

  function handleNextPage(event) {
    event.preventDefault();
    const newErrors = validate(fields);

    if (Object.keys(newErrors).length === 0) {
      const params = {
        name: fields.name,
        email: fields.email,
        companyName: fields.companyName,
        inviteCode: fields.inviteCode,
        phoneNumber: fields.phoneNumber,
        companyVision: '',
        companyMission: formValue.companyMission,
        coreValues: formValue.coreValues,
        organisationalCulture: formValue.organisationalCulture,
        currentWorkingArrangement: formValue.currentWorkingArrangement
      };

      dispatch(fetchWaitlistInfo({ email: fields.email })).then(response => {
        if (response?.type === 'FETCH_WAITLIST_INFO_SUCCEED') {
          SuccessToast(
            'This email is already registered. Redirecting to your previous history.'
          );

          setTimeout(() => {
            dispatch(updateCurrentOnboardingPage('complete'));
          }, 1500);
        } else {
          dispatch(updateOnboardingFormFields(params));
          dispatch(updateCurrentOnboardingPage('vision'));
        }
      });
    } else {
      setErrors(newErrors);
    }
  }

  const renderTextField = (name, label, placeholder, type = 'text') => (
    <div>
      <TextFieldLabel
        sx={{
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.1px',
          padding: '0px 8px'
        }}>
        {label}
        {/* {name !== 'inviteCode' && '*'} */}
      </TextFieldLabel>
      <HeightSpacer height={'4px'} />
      {name === 'inviteCode' && refCode ? (
        <TextField
          name={name}
          hiddenLabel
          required
          value={fields[name]}
          placeholder={`Enter ${placeholder}`}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          error={!!errors[name]}
          helperText={errors[name]}
          type={type}
          sx={{
            width: '100%',
            '.MuiInputBase-root': {
              borderRadius: '8px',
              padding: '8px 16px'
            },
            '.MuiInputBase-input': {
              padding: '8px 0'
            },
            'fieldset.MuiOutlinedInput-notchedOutline': {
              border: '2px solid rgba(174, 174, 174, 1)'
            }
          }}
        />
      ) : (
        <TextField
          name={name}
          hiddenLabel
          required
          value={fields[name]}
          placeholder={`Enter ${placeholder}`}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          error={!!errors[name]}
          helperText={errors[name]}
          type={type}
          sx={{
            width: '100%',
            '.MuiInputBase-root': {
              borderRadius: '8px',
              padding: '8px 16px'
            },
            '.MuiInputBase-input': {
              padding: '8px 0'
            },
            'fieldset.MuiOutlinedInput-notchedOutline': {
              border: '2px solid rgba(174, 174, 174, 1)'
            }
          }}
        />
      )}
    </div>
  );

  const renderMobileField = (name, label, placeholder, type = 'number') => (
    <div>
      <TextFieldLabel
        sx={{
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.1px',
          padding: '0px 8px'
        }}>
        {label}
        {/* {name !== 'inviteCode' && '*'} */}
      </TextFieldLabel>
      <HeightSpacer height={'4px'} />
      <>
        <PhoneInput
          required
          placeholder={`Enter ${placeholder}`}
          value={fields[name]}
          defaultCountry={'SG'}
          countryCallingCodeEditable={false}
          international
          onChange={value => handleChange({ target: { name, value } })}
          className={errors[name] ? 'error' : ''}
          // countries={['SG', 'MY']}
          countryOptionsOrder={['SG', 'MY', '...']}
        />
        {errors[name] && (
          <span
            style={{
              marginLeft: '14px',
              marginRight: '14px',
              color: '#d32f2f',
              fontSize: '0.75rem',
              marginTop: '3px'
            }}>
            {errors[name]}
          </span>
        )}
      </>
    </div>
  );

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleNextPage(event);
    }
  };

  const handlePreviousPage = () => {
    if (isDirect == '1') {
      dispatch(updateCurrentBg('0'));
      dispatch(updateCurrentOnboardingPage('waitlist'));
    } else {
      dispatch(updateCurrentBg('1'));
      dispatch(updateCurrentOnboardingPage('company-canvas'));
    }
  };

  useEffect(() => {
    setFields({
      ...formValue
    });
  }, []);

  useEffect(() => {
    setFields({
      ...formValue,
      inviteCode: formValue.inviteCode || (refCode ? refCode : '')
    });
  }, [refCode, formValue.inviteCode]);

  return (
    <PageContainer>
      <AnimatedContainer>
        <ContentContainer>
          <ContentWithLogo>
            <ContentLogo
              src={
                'https://assets.wobbjobs.com/images/waitlist/hiredly-logo-sg.png'
              }
              alt="hiredly-logo"
            />
            <Title>
              <b style={{ color: '#40249B' }}>Super-Hiroes</b>, Assemble!
            </Title>
          </ContentWithLogo>

          <ContentMessageBox>
            <MessageBox>
              <Message>
                You're the hero of your story. We want to help you build an
                employer brand that will turn future jobseekers into your future
                applicants.
              </Message>

              <Message>
                Join our waitlist today to receive a free
                <b
                  style={{
                    color: 'rgba(0, 0, 0, 0.87)',
                    fontWeight: '700',
                    letterSpacing: '0.1px'
                  }}>
                  {' '}
                  Hiredly Employer Branding
                </b>{' '}
                experience and be part of over 5,800 companies that have trusted
                us to share their story.
              </Message>

              <MessageGrey>
                Hire talent like the best employers
              </MessageGrey>
            </MessageBox>

            <MessageImageBox>
              <ImageBox
                src={'https://assets.wobbjobs.com/images/waitlist/ocbc.png'}
                alt="ocbc-logo"
                onClick={() => window.open('https://my.hiredly.com/companies/ocbc-bank')}
              />

              <ImageBox
                src={'https://assets.wobbjobs.com/images/waitlist/shopee.png'}
                alt="shopee-logo"
                onClick={() => window.open('https://my.hiredly.com/companies/shopee-malaysia')}

              />

              <ImageBox
                src={'https://assets.wobbjobs.com/images/waitlist/coway.png'}
                alt="hiredly-logo"
                onClick={() => window.open('https://my.hiredly.com/companies/coway-malaysia-sdn-bhd')}

              />

              <ImageBox
                src={
                  'https://assets.wobbjobs.com/images/waitlist/schindler.png'
                }
                alt="hiredly-logo"
                onClick={() => window.open('https://my.hiredly.com/companies/schindler-business-services-centre')}

              />

              <ImageBox
                src={'https://assets.wobbjobs.com/images/waitlist/tapestry.png'}
                alt="hiredly-logo"
                onClick={() => window.open('https://my.hiredly.com/companies/tapestry-malaysia')}
              />

              <ImageBoxText>
                ...and more!
              </ImageBoxText>
            </MessageImageBox>
          </ContentMessageBox>
        </ContentContainer>

        <HeightSpacer $height={'80px'} />
      </AnimatedContainer>
      <SecondContainer>
        <Grid>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={{ xs: '16px', md: '32px' }}
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 0, width: '100%' }
            }}
            noValidate
            autoComplete="off">
            {renderTextField('name', 'Name', 'name')}
            {renderTextField('companyName', 'Company Name', 'company name')}
            {renderTextField('email', 'Email', 'email')}
            {renderMobileField('phoneNumber', 'Phone Number', 'phone number')}
            {renderTextField(
              'inviteCode',
              'Referral Code (Optional)',
              'code',
              'text'
            )}{' '}
            <FooterContainer>
              <BackButton onClick={handlePreviousPage}>
                <KeyboardBackspaceIconStyled />
              </BackButton>
              <NextPageButton type="submit" onClick={handleNextPage}>
                Continue
              </NextPageButton>
            </FooterContainer>
          </Box>
        </Grid>
      </SecondContainer>
    </PageContainer>
  );
}
