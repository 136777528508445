import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  CartQuantityText,
  PackageGrid,
  ProductDiscountPrice,
  QuantityButton,
  ReviewCartButton
} from '../styles';
import { Card } from '@mui/material';
import { CartProductIconSkeleton, NoWidthSkeleton } from '../Skeletons/styles';
import {
  MinusSmallIcon,
  PlusSmallIcon,
  ShoppingCartIcon,
  TrashIcon
} from '@heroicons/react/24/solid';
import styles from './../index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCart,
  mutateCartQuantity,
  mutateCartRemove,
  mutateCartSummary,
  updateSelectedItem
} from '../../../redux/actions/order_action';
import { ErrorToast } from '../../../utils/ToastUtils';
import ShoppingCartImg from '../../../assets/images/background/shoppingCart.png';
import _, { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  BulletPoints,
  BundleContainer,
  BundleDiscountText,
  BundleInnerContainer,
  BundleText,
  BundleTitle,
  ChevronUpIconStyled,
  DefaultContainer,
  ReceiptPercentIconStyled
} from './styles';
import { CustomTooltip } from '../ProductCards/styles';
import {
  getCountry,
  getCurrency,
  getCurrenyCode,
  getTaxLabel
} from '../../../hooks/useRegionalisation';
import { countryCodeOptions } from '../../../components/SharedSelectCountry/constants';
import { getLocalStorageItem } from '../../../helpers/local_storage_utils';
import VerifyPopUp from '../../../components/VerifyPopUp';
import ProductUnavailablePopUp from '../../../components/ProductUnavailablePopup';

export function transformArray(array) {
  return array?.map(({ node }) => {
    return {
      id: node.id,
      quantity: node.quantity,
      totalPriceBeforeDiscount: node.totalPriceBeforeDiscount,
      totalPriceAfterDiscount: node.totalPriceAfterDiscount,
      productId: node.product.id,
      packageType: node.product.packageType,
      productCode: node.product.productCode,
      productTitle: node.product.title,
      productPrice: node.product.price,
      productImage: node.product.productImage,
      isDiscounted:
        node.totalPriceBeforeDiscount > node.totalPriceAfterDiscount,
      productsInBundle: node.product.productsInBundle
    };
  });
}

export default function ShoppingCart() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [open, setOpen] = useState(false);

  const accountInfo = useSelector(state => state.companies.accountInfo);
  const myCart = useSelector(state => state.orders.myCart);
  const cartList = myCart?.cartLists?.edges;
  const cartSummary = useSelector(state => state.orders.cartSummary);
  const selectedItem = useSelector(state => state.orders.selectedItem);

  const fetchingMyCart = useSelector(state => state.orders.fetchingMyCart);
  const mutatingCart = useSelector(state => state.orders.mutatingCart);
  const mutatingCartSummary = useSelector(
    state => state.orders.mutatingCartSummary
  );
  const allProducts = useSelector(state => state.products.allProducts);

  const cartLoading = fetchingMyCart || mutatingCart || mutatingCartSummary;
  const transformedArray = transformArray(cartList);
  const [cartItems, setCartItems] = useState();
  const [turnToBundle, setTurnToBundle] = useState(false);

  useEffect(() => {
    dispatch(fetchCart()).then(res => {
      if (res?.unavailable !== undefined) {
        dispatch(updateProductInactivePopupStatus(true));
      }
      dispatch(
        mutateCartSummary({
          input: {
            cartId: res?.myCart?.id ?? ''
          }
        })
      );
    });
  }, []);

  useEffect(() => {
    setTurnToBundle(cartItems?.length > cartList?.length);
    setCartItems(transformedArray);
  }, [myCart, setCartItems]);

  // just redirects to next page.
  const handleCheckout = async () => {
    if (!accountInfo?.isEmailVerified) {
      setOpen(true);
      return;
    }

    try {
      window.dataLayer.push({
        event: 'choose_product'
      });
    } catch {}

    history('/purchase/confirm');
  };

  const [openStates, setOpenStates] = useState({});
  const toggleItem = id => {
    setOpenStates(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  return (
    <PackageGrid item xs={'auto'} position={'sticky'} top={'110px'}>
      <ProductUnavailablePopUp redirect={true} />
      <Card
        style={{ height: '80vh' }}
        className={[
          styles.shoppingCartCard,
          styles.smallerCart,
          styles.floatingCart
        ].join(' ')}>
        <div
          className={styles.shoppingCartTopSection}
          style={{
            display: 'flex',
            columnGap: '16px',
            alignItems: 'center'
          }}>
          {cartLoading ? (
            <NoWidthSkeleton sx={{ width: '160px' }} />
          ) : (
            <Fragment>
              <ShoppingCartIcon width={'20px'} height={'20px'} />
              <div className={styles.shoppingCartTitle}>Shopping Cart</div>
            </Fragment>
          )}
        </div>

        <div className={styles.shoppingCartItems}>
          {cartList?.length === 0 &&
            (cartLoading ? (
              <div className={styles.shoppingCartEmpty}>Loading cart...</div>
            ) : (
              <div className={styles.shoppingCartEmpty}>
                <img
                  src={ShoppingCartImg}
                  alt="shopping-cart-image"
                  width={'155px'}
                  height={'155px'}
                />
                Your shopping cart is empty
              </div>
            ))}
          {cartLoading ? (
            <Fragment>
              <PackageGrid
                container
                columnGap={'12px'}
                flexWrap={'nowrap'}
                marginBottom={'16px'}>
                <CartProductIconSkeleton />
                <PackageGrid>
                  <NoWidthSkeleton
                    sx={{ width: '100px', marginBottom: '8px' }}
                  />
                  <NoWidthSkeleton sx={{ width: '50px' }} />
                </PackageGrid>
              </PackageGrid>
              <PackageGrid container columnGap={'12px'} flexWrap={'nowrap'}>
                <CartProductIconSkeleton />
                <PackageGrid>
                  <NoWidthSkeleton
                    sx={{ width: '100px', marginBottom: '8px' }}
                  />
                  <NoWidthSkeleton sx={{ width: '50px' }} />
                </PackageGrid>
              </PackageGrid>
            </Fragment>
          ) : (
            cartItems &&
            cartItems
              .sort((a, b) => {
                // Prioritize the selectedItem:
                if (a.productId === selectedItem) return -1;
                if (b.productId === selectedItem) return 1;

                if (turnToBundle) {
                  return parseInt(b.id) - parseInt(a.id);
                } else {
                  return parseInt(a.id) - parseInt(b.id);
                }
                // Sort the rest in ascending order:
              })
              .map(item => {
                const matchedProduct = allProducts.find(
                  product => item?.productId === product?.id
                );

                const bundle = matchedProduct?.packageType === 'bundle';
                const bundleText = matchedProduct?.productPageInfo?.disclaimer;
                const bundleDiscount =
                  item?.totalPriceBeforeDiscount -
                  item?.totalPriceAfterDiscount;

                const bundleItems = item?.productsInBundle;
                const formattedBundleItems = bundleItems?.map(bundle => {
                  return {
                    title: bundle?.title,
                    quantity: bundle?.quantity_per_bundle
                  };
                });

                if (bundle) {
                  return (
                    <BundleWrapper
                      key={item?.id}
                      item={item}
                      bundleDiscount={bundleDiscount}
                      toggleItem={toggleItem}
                      openStates={openStates}
                      bundleText={bundleText}
                      formattedBundleItems={formattedBundleItems}>
                      <CartItemDetails
                        item={item}
                        cartItems={cartItems}
                        allProducts={allProducts}
                        cartLoading={cartLoading}
                        setCartItems={setCartItems}
                      />
                    </BundleWrapper>
                  );
                }

                return (
                  <CartItemDetails
                    item={item}
                    allProducts={allProducts}
                    cartLoading={cartLoading}
                    cartItems={cartItems}
                    setCartItems={setCartItems}
                  />
                );
              })
          )}
        </div>

        {cartLoading ? (
          <div className={styles.shoppingCartBottomSection}>
            <PackageGrid
              container
              justifyContent={'space-between'}
              alignItems={'center'}
              marginBottom={'14px'}>
              <NoWidthSkeleton sx={{ width: '40%' }} />
              <NoWidthSkeleton sx={{ width: '40%' }} />
            </PackageGrid>
            <NoWidthSkeleton sx={{ width: '70%', marginBottom: '8px' }} />
            <NoWidthSkeleton sx={{ width: '30%', marginBottom: '8px' }} />
            <CartProductIconSkeleton sx={{ width: '100%' }} />
          </div>
        ) : cartList?.length !== 0 ? (
          <div className={styles.shoppingCartBottomSection}>
            <div className={styles.subtotalRow}>
              <div>
                Subtotal <span className={styles.excludeTax}>(excl.tax)</span>
              </div>

              <span>
                <strong>
                  {cartSummary?.totalAmount
                    ? getCurrency(cartSummary.totalAmount)
                    : null}
                </strong>
              </span>
            </div>
            {parseInt(cartSummary?.packageDiscountAmount) > 0 ? (
              <div className={styles.discountRow}>
                Savings
                <span>-{getCurrency(cartSummary?.packageDiscountAmount)}</span>
              </div>
            ) : null}

            {cartSummary?.serviceTaxPercentage ? (
              <div className={styles.subtotalRow}>
                {cartSummary?.serviceTaxPercentage}%{' '}
                {getTaxLabel('remove-incl')}
                <span>
                  <strong>
                    {cartSummary?.taxAmount
                      ? getCurrency(cartSummary?.taxAmount)
                      : null}
                  </strong>
                </span>
              </div>
            ) : null}

            {cartSummary?.totalIncludingTax ? (
              <div className={styles.totalRow}>
                <span>
                  <strong> Total </strong>
                  <span className={styles.excludeTax}>(incl. tax)</span>
                </span>

                <span>
                  <strong>{getCurrency(cartSummary?.totalIncludingTax)}</strong>
                </span>
              </div>
            ) : null}

            <div className={styles.shoppingCartCaptionSmall}>
              Discount vouchers can be applied at checkout
            </div>

            <ReviewCartButton
              variant="contained"
              disabled={cartList?.length === 0 || cartLoading}
              onClick={() => handleCheckout()}>
              Review cart
            </ReviewCartButton>
          </div>
        ) : null}
      </Card>
      <VerifyPopUp open={open} setClose={() => setOpen(false)} />
    </PackageGrid>
  );
}

const CartItemDetails = props => {
  const { item, allProducts, cartLoading, setCartItems, cartItems } = props;

  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(item?.quantity);
  const productsInBundle = useSelector(
    state => state.products.productsInBundle
  );

  useEffect(() => {
    setQuantity(item?.quantity);
  }, [item?.quantity]);

  const incrementCart = ({ item, quantity }) => {
    dispatch(
      mutateCartQuantity({
        input: {
          cartListId: item,
          quantity: quantity + 1
        }
      })
    ).then(res => {
      if (!res.updateCartListQuantity?.success) {
        ErrorToast('Failed to change quantity, try refreshing the page.');
      }
      dispatch(fetchCart()).then(res => {
        const data = res?.myCart?.cartLists?.edges;
        setCartItems(transformArray(data));
        dispatch(
          mutateCartSummary({
            input: {
              cartId: res?.myCart?.id ?? ''
            }
          })
        );
      });
    });
  };

  // triggers updateCartQuantity API. decrements product quantity
  const decrementCart = ({ item, quantity }) => {
    if (quantity === 1) {
      return;
    }

    dispatch(
      mutateCartQuantity({
        input: {
          cartListId: item,
          quantity: quantity - 1
        }
      })
    ).then(res => {
      if (!res.updateCartListQuantity?.success) {
        ErrorToast('Failed to change quantity, try refreshing the page.');
      }
      dispatch(fetchCart()).then(res => {
        const data = res?.myCart?.cartLists?.edges;
        setCartItems(transformArray(data));
        dispatch(
          mutateCartSummary({
            input: {
              cartId: res?.myCart?.id ?? ''
            }
          })
        );
      });
    });
  };

  // triggers removeFromCart API. completely removes product from cart.
  const handleCartDelete = async item => {
    dispatch(
      mutateCartRemove({
        input: {
          cartListId: item
        }
      })
    )
      .then(res => {
        if (!res.removeFromCart?.success) {
          ErrorToast('Failed to remove product, try refreshing the page.');
        }
        dispatch(fetchCart()).then(res => {
          const data = res?.myCart?.cartLists?.edges;
          setCartItems(transformArray(data));
          dispatch(
            mutateCartSummary({
              input: {
                cartId: res?.myCart?.id ?? ''
              }
            })
          );
        });
      })
      .catch(error => {
        ErrorToast(error);
      });
  };

  const debouncedOnChangeHandler = debounce((item, value) => {
    if (!value && value !== 0) {
      return;
    }

    dispatch(
      mutateCartQuantity({
        input: {
          cartListId: item,
          quantity: Number(value)
        }
      })
    ).then(res => {
      if (!res.updateCartListQuantity?.success) {
        ErrorToast('Failed to change quantity, try refreshing the page.');
      }
      dispatch(fetchCart()).then(res => {
        const data = res?.myCart?.cartLists?.edges;
        setCartItems(transformArray(data));
      });
    });
  }, 300);

  const onChangeHandler = (itemId, newQuantity) => {
    setCartItems(currentItems =>
      currentItems.map(item =>
        item.id === itemId ? { ...item, quantity: newQuantity } : item
      )
    );

    if (newQuantity === 0) {
      return;
    }

    debouncedOnChangeHandler(itemId, newQuantity);
  };

  useEffect(() => {
    if (item?.quantity !== quantity) {
      const debouncedChangeHandler = _.debounce(() => {
        onChangeHandler(item?.id, quantity);
      }, 2000);

      debouncedChangeHandler();

      return () => {
        debouncedChangeHandler.cancel();
      };
    }
  }, [quantity]);

  // Match the bundle
  const matchedBundle = productsInBundle?.find(bundle => {
    return (
      bundle?.inBundleCode === item?.productCode ||
      bundle?.productCode === item?.productCode
    );
  });

  const matchedProduct = allProducts.find(
    product => item?.productId === product?.id
  );

  // Default available logic
  let quantityAvailable = matchedProduct?.quantityAvailable;

  // This logic is only for limited tokens with bundles
  if (matchedBundle) {
    // When rendering job slots plus
    // Get the cart info from exlusive plus if there's any

    let addedSlotsQuantity;
    let addedBundleQuantity;
    let bundleProductCode;
    const quantityPerBundle = matchedBundle?.quantityPerBundle;

    // Scenario 1 - render slots, find bundle
    // Find slots
    if (item?.packageType !== 'bundle') {
      addedSlotsQuantity = cartItems?.find(items => {
        return items?.productCode === item?.productCode;
      })?.quantity;

      // Find the bundle code thru slots code
      bundleProductCode = productsInBundle?.find(product => {
        return product?.productCode === item?.productCode;
      })?.inBundleCode;

      // Find bundles thru slots
      addedBundleQuantity = cartItems?.find(items => {
        return items?.productCode === bundleProductCode;
      })?.quantity;

      addedBundleQuantity = addedBundleQuantity ? addedBundleQuantity : 0;
    } else {
      // Scenario 2 - render bundle, find slots
      // Find bundle
      addedBundleQuantity = cartItems?.find(items => {
        return items?.productCode === item?.productCode;
      })?.quantity;

      // Find the slot code thru bundle code
      bundleProductCode = productsInBundle?.find(product => {
        return product?.inBundleCode === item?.productCode;
      })?.productCode;

      // Find slots thru bundle
      addedSlotsQuantity = cartItems?.find(items => {
        return items?.productCode === bundleProductCode;
      })?.quantity;

      addedSlotsQuantity = addedSlotsQuantity ? addedSlotsQuantity : 0;
    }

    // Find the available amount by deducting slots added individually and added from bundles
    // Calculation for bundle and slots is separated
    if (item?.packageType !== 'bundle') {
      quantityAvailable =
        quantityAvailable -
        addedSlotsQuantity -
        addedBundleQuantity * quantityPerBundle;
    } else {
      quantityAvailable = Math.floor(
        matchedBundle?.quantityAvailable -
          addedBundleQuantity * matchedBundle?.quantityPerBundle -
          addedSlotsQuantity
      );

      // total available divide by quantity of products in bundle
      // if matchedBundle?.quantityPerBundle > available, means not enough
      quantityAvailable =
        quantityAvailable < matchedBundle?.quantityPerBundle
          ? 0
          : Math.floor(quantityAvailable / matchedBundle?.quantityPerBundle);
    }
  }

  const maxCount = quantityAvailable === null ? Infinity : quantityAvailable;
  const disableMinusButton = item?.quantity <= 1;

  // DISABLE WHEN
  // 1. quantity is at 99
  // 2. quantity in cart is max
  // 3. available tokens reaches 0
  const disablePlusButton = item?.quantity === 99 || quantityAvailable === 0;

  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className={styles.shoppingCartItem}>
      <div className={styles.shoppingCartIconWrapper}>
        <img
          className={styles.shoppingCartIcon}
          src={item?.productImage}
          alt={item?.productTitle}
        />
      </div>
      <div className={styles.shoppingCartContent}>
        <div className={styles.shoppingCartTitle}>{item?.productTitle}</div>
        <div className={styles.shoppingCartPrice}>
          {getCurrency(item?.totalPriceAfterDiscount)}
          {/* shopping cart - show strikethrough if bundle discount applied. packages (basic / premium / advanced) / single product doesn't apply */}
          {item?.totalPriceBeforeDiscount > item?.totalPriceAfterDiscount ? (
            <ProductDiscountPrice>
              {getCurrency(item?.totalPriceBeforeDiscount)}
            </ProductDiscountPrice>
          ) : null}
        </div>
        <div className={styles.shoppingCartActions}>
          <div className={styles.shoppingCartQuantity}>
            <PackageGrid
              container
              flexWrap={'nowrap'}
              alignItems={'center'}
              columnGap={'4px'}
              justifyContent={'center'}
              sx={{
                backgroundColor: '#F5F5F5',
                borderRadius: '4px'
              }}>
              <QuantityButton
                disabled={disableMinusButton}
                variant="text"
                onClick={() => {
                  if (!disableMinusButton) {
                    decrementCart({
                      item: item?.id,
                      quantity: item?.quantity
                    });
                    dispatch(updateSelectedItem(item?.productId));
                  }
                }}
                sx={{
                  fontSize: '24px',
                  color: 'black'
                }}>
                <MinusSmallIcon
                  width={24}
                  height={24}
                  color={disableMinusButton ? '#00000038' : 'black'}
                />
              </QuantityButton>
              <CustomTooltip
                open={showTooltip}
                title={disablePlusButton && 'Maximum quantity reached'}
                placement="top">
                <CartQuantityText
                  onBlur={e => {
                    let value =
                      e.target.value == 0 ? 1 : parseInt(e.target.value, 10);
                    setQuantity(value);
                  }}
                  onChange={e => {
                    let value = e.target.value;

                    if (value > maxCount + item?.quantity) {
                      setShowTooltip(true);

                      setTimeout(() => {
                        setShowTooltip(false);
                      }, 1000);
                    }

                    if (maxCount !== Infinity) {
                      const tempMax = maxCount + item?.quantity;
                      value =
                        value > tempMax ? tempMax : value > 99 ? 99 : value;
                    }

                    setQuantity(Number(value));
                  }}
                  value={quantity}
                />
              </CustomTooltip>
              <CustomTooltip
                title={disablePlusButton && 'Maximum quantity reached'}
                placement="top">
                <span>
                  <QuantityButton
                    disabled={disablePlusButton}
                    variant="text"
                    onClick={() => {
                      incrementCart({
                        item: item?.id,
                        quantity: item?.quantity
                      });
                      dispatch(updateSelectedItem(item?.productId));
                    }}
                    sx={{
                      fontSize: '20px',
                      color: 'black'
                    }}>
                    <PlusSmallIcon
                      width={24}
                      height={24}
                      color={disablePlusButton ? '#00000038' : 'black'}
                    />
                  </QuantityButton>
                </span>
              </CustomTooltip>
            </PackageGrid>
          </div>
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={() => {
              if (!cartLoading) {
                handleCartDelete(item?.id);
              }
            }}
            className={[
              styles.shoppingCartDeleteButton,
              cartLoading ? styles.buttonDisabled : ''
            ].join(' ')}>
            <TrashIcon width={'20px'} height={'20px'} />
          </div>
        </div>
      </div>
    </div>
  );
};

const BundleWrapper = props => {
  const {
    item,
    children,
    bundleDiscount,
    toggleItem,
    openStates,
    bundleText,
    formattedBundleItems
  } = props;

  return (
    <BundleContainer key={item?.id}>
      <BundleInnerContainer>
        {/* header */}
        <DefaultContainer
          container
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'nowrap'
          }}>
          <DefaultContainer
            container
            sx={{ flexDirection: 'row', gap: '10px' }}>
            <ReceiptPercentIconStyled />
            <BundleTitle>Savings Applied!</BundleTitle>
          </DefaultContainer>
          <ChevronUpIconStyled
            open={!openStates[item.productId]}
            onClick={() => toggleItem(item.productId)}
          />
        </DefaultContainer>

        {/* content */}
        <DefaultContainer
          sx={{
            transition: 'all 0.3s ease',
            overflow: 'hidden',
            transition: 'height 0.3s ease-in-out',
            height: !openStates[item.productId] ? 'auto' : '0px',
            maxHeight: !openStates[item.productId] ? '500px' : '0px',
            transitionProperty: 'max-height, height',
            transitionDuration: '0.3s'
          }}>
          <DefaultContainer
            container
            sx={{
              paddingLeft: '30px',
              flexDirection: 'column',
              gap: '4px'
            }}>
            <BundleText>
              <div
                dangerouslySetInnerHTML={{
                  __html: bundleText
                }}
              />
              <BulletPoints>
                {formattedBundleItems?.map(bundle => {
                  return (
                    <li>
                      x{item?.quantity * bundle?.quantity} {bundle?.title}
                    </li>
                  );
                })}
              </BulletPoints>
            </BundleText>

            {bundleDiscount > 0 && (
              <BundleDiscountText>
                You have saved {getCurrenyCode()}{' '}
                {bundleDiscount.toLocaleString()}!
              </BundleDiscountText>
            )}
          </DefaultContainer>
        </DefaultContainer>
      </BundleInnerContainer>
      {children}
    </BundleContainer>
  );
};
