import { styled as styling } from '@mui/material/styles';

export const CardContainer = styling('div')({
  padding: '0 24px',
  display: 'flex',
  gap: '5px',
  flexDirection: 'column'
});

export const MainCard = styling('div')(props => ({
  width: '460px',
  height: '100%',
  boxShadow: 'rgba(0, 0, 0, 0.16) 0.25rem 0.25rem 0.625rem',
  background: 'rgb(255, 255, 255)',
  textTransform: 'capitalize',
  filter: '',
  borderRadius: '5px',
  border: '1px solid transparent',
  position: 'relative',

  ...((props.currentOption == 'job_slot_plus' ||
    props.currentOption == 'job_slot_plus_6m') && {
    border: '1px solid transparent',
    backgroundImage: `linear-gradient(-45deg, rgb(176, 205, 114), rgb(110, 199, 205), rgb(176, 205, 114))`, // Set linear gradient as background image
    borderRadius: '5px'
  }),

  ...((props.currentOption == 'job_slot_max' ||
    props.currentOption == 'job_slot_max_6m') && {
    border: '1px solid transparent',
    backgroundImage: `linear-gradient(-45deg, rgb(176, 205, 114), rgb(110, 199, 205), rgb(176, 205, 114))`, // Set linear gradient as background image
    borderRadius: '5px'
  })
}));

export const ColorIndicator = styling('div')(props => ({
  position: 'absolute',
  height: '100%',
  opacity: '0',
  width: '0.625rem',
  zIndex: 10,
  animationTimeline: 'auto',
  animationRangeStart: 'normal',
  animationRangeEnd: 'normal',
  background:
    'linear-gradient(0deg, rgb(81, 42, 204), rgb(81, 42, 204), rgb(122, 157, 47), rgb(81, 42, 204), rgb(81, 42, 204)) 0% 0% / 100% 600% rgb(81, 42, 204)',
  borderRadius: '0.475rem 0px 0px 0.475rem',
  animation: '1.75s linear 0s infinite normal none running sidebarGradient',

  ...((props.currentOption == 'job_slot_plus' ||
    props.currentOption == 'job_slot_plus_6m') && {
    opacity: 1
  })
}));

export const TopRow = styling('div')(props => ({
  display: 'flex',
  WebkitBoxPack: 'justify',
  justifyContent: 'space-between',
  gap: '0.5rem',
  textTransform: 'uppercase',
  padding: '5px 5px 0px 10px',
  marginBottom: '-20px',
  background: '#FFF',
  borderRadius: '5px 5px 0rem 0rem',

  ...(props.isGlobalHire && {
    marginBottom: '-0.25rem'
  }),

  ...((props.currentOption == 'job_slot_plus' ||
    props.currentOption == 'job_slot_plus_6m') && {
    marginBottom: '-0.25rem'
  }),

  ...((props.currentOption == 'job_slot_max' ||
    props.currentOption == 'job_slot_max_6m') && {
    marginBottom: '-0.25rem'
  })
}));

export const TopLeft = styling('div')(props => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px'
}));

export const HotTag = styling('div')(props => ({
  color: 'rgb(255, 255, 255)',
  borderRadius: '0.35rem',
  padding: '0.2rem 0.6rem',
  fontSize: '0.5rem',
  fontWeight: 700,
  background: 'rgb(81, 42, 204)',
  opacity: 0,

  ...((props.currentOption == 'job_slot_plus' ||
    props.currentOption == 'job_slot_plus_6m') && {
    opacity: 1
  }),

  ...((props.currentOption == 'job_slot_max' ||
    props.currentOption == 'job_slot_max_6m') && {
    opacity: 1
  })
}));

export const SpotlightTag = styling('div')(props => ({
  color: 'rgb(255, 255, 255)',
  borderRadius: '3px',
  padding: '2px 5px',
  animation: '5s ease 0s infinite normal none running gradient',
  backgroundImage:
    'linear-gradient(-45deg, rgb(176, 205, 114), rgb(110, 199, 205), rgb(176, 205, 114))',
  backgroundSize: '300% 300%',
  fontSize: '6px',
  fontWeight: 500,
  letterSpacing: '0.7px',
  display: 'flex',
  WebkitBoxAlign: 'center',
  alignItems: 'center',
  gap: '2px',
  opacity: 0,

  ...((props.currentOption == 'job_slot_plus' ||
    props.currentOption == 'job_slot_plus_6m') && {
    opacity: 1
  }),

  ...((props.currentOption == 'job_slot_max' ||
    props.currentOption == 'job_slot_max_6m') && {
    opacity: 1
  })
}));

export const AiTag = styling('span')({
  display: 'inline-flex',
  WebkitBoxAlign: 'center',
  alignItems: 'center',
  WebkitBoxPack: 'center',
  justifyContent: 'center',
  position: 'relative',
  boxSizing: 'border-box',
  WebkitTapHighlightColor: 'transparent',
  backgroundColor: 'transparent',
  outline: '0px',
  margin: '0px',
  cursor: 'pointer',
  userSelect: 'none',
  verticalAlign: 'middle',
  appearance: 'none',
  textDecoration: 'none',
  fontFamily: 'Inter, sans-serif',
  lineHeight: '1',
  textTransform: 'uppercase',
  minWidth: '50px',
  transition:
    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, ' +
    'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, ' +
    'border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, ' +
    'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  border: '1px solid rgba(81, 42, 204, 0.5)',
  color: 'rgb(81, 42, 204)',
  fontWeight: 400,
  letterSpacing: '0.5px',
  fontSize: '5px',
  borderRadius: '3px',
  padding: '1px 3px',
  whiteSpace: 'nowrap'
});

export const MidRow = styling('div')(props => ({
  display: 'flex',
  padding: '0.325rem 10px',
  background: 'white',
  borderRadius: '5px 5px 0rem 0rem',

  ...((props.currentOption == 'job_slot_max' ||
    props.currentOption == 'job_slot_max_6m') && {
    borderRadius: '0rem'
  }),

  ...((props.currentOption == 'job_slot_plus' ||
    props.currentOption == 'job_slot_plus_6m') && {
    borderRadius: '0rem'
  })
}));

export const MidLeft = styling('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  width: '45%'
});

export const MidRight = styling('div')({
  display: 'flex',
  width: '50%'
});

export const JobTitle = styling('p')({
  margin: '0px',
  fontFamily: 'Inter, sans-serif',
  lineHeight: '1.5',
  display: 'flex',
  WebkitBoxAlign: 'center',
  alignItems: 'center',
  gap: '0.25rem',
  fontSize: '9px',
  fontWeight: 700,
  width: '100%'
});

export const MidSalary = styling('div')({
  display: 'flex',
  margin: '-0.25rem 0px'
});

export const MidSalaryText = styling('p')({
  margin: '0px',
  fontFamily: 'Inter, sans-serif',
  lineHeight: 1.5,
  display: 'flex',
  WebkitBoxAlign: 'center',
  alignItems: 'center',
  gap: '0.25rem',
  fontSize: '7px',
  fontWeight: 400,
  width: '50%',
  color: 'rgb(34, 34, 34)'
});

export const MidText = styling('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '50%'
});

export const MidCompany = styling('p')({
  margin: '0px 0px 5px',
  fontFamily: 'Inter, sans-serif',
  display: 'flex',
  WebkitBoxAlign: 'center',
  alignItems: 'center',
  gap: '0.25rem',
  fontSize: '9px',
  fontWeight: 600,
  width: '92%'
});

export const MidLastActive = styling('p')({
  margin: '0px',
  fontFamily: 'Inter, sans-serif',
  fontWeight: 400,
  lineHeight: 1.5,
  display: 'flex',
  WebkitBoxAlign: 'center',
  alignItems: 'center',
  gap: '0.25rem',
  color: 'rgb(112, 112, 112)',
  fontSize: '0.4rem'
});

export const BottomRow = styling('div')(props => ({
  display: 'flex',
  WebkitBoxAlign: 'center',
  alignItems: 'center',
  backgroundColor: 'rgb(239, 239, 239)',
  borderRadius: '0px 0px 5px 5px',
  padding: '3px 10px',
  border: '1px solid rgb(239, 239, 239)',
  margin: '-1px',
  height: '23px',

  ...((props.currentOption == 'job_slot_plus' ||
    props.currentOption == 'job_slot_plus_6m') && {
    margin: '0'
  }),

  ...((props.currentOption == 'job_slot_max' ||
    props.currentOption == 'job_slot_max_6m') && {
    backgroundImage:
      'linear-gradient(-45deg, rgb(176, 205, 114), rgb(110, 199, 205), rgb(176, 205, 114))',
    border: 'none',
    margin: '0'
  })
}));

export const BottomText = styling('div')({
  display: 'flex',
  WebkitBoxAlign: 'center',
  alignItems: 'center',
  gap: '0px',

  '& hr': {
    margin: '0px',
    flexShrink: 0,
    borderWidth: '0px thin 0px 0px',
    borderStyle: 'solid',
    height: '15px',
    width: '1px',
    borderColor: 'rgb(168, 168, 168)',
    marginInline: '8px'
  }
});

export const JobTypeTile = styling('div')(props => ({
  maxWidth: '100%',
  fontFamily: 'Inter, sans-serif',
  display: 'inline-flex',
  WebkitBoxAlign: 'center',
  alignItems: 'center',
  WebkitBoxPack: 'center',
  justifyContent: 'center',
  height: '13px',
  borderRadius: '16px',
  whiteSpace: 'nowrap',
  transition:
    'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  cursor: 'default',
  outline: '0px',
  textDecoration: 'none',
  border: '0px',
  verticalAlign: 'middle',
  boxSizing: 'border-box',
  color: 'rgb(33, 61, 181)',
  backgroundColor: 'rgba(33, 61, 181, 0.14)',
  fontWeight: '700',
  fontSize: '6px',
  padding: '2px',

  '& span': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingLeft: '5px',
    paddingRight: '5px',
    whiteSpace: 'nowrap'
  },

  ...((props.currentOption == 'job_slot_max' ||
    props.currentOption == 'job_slot_max_6m') && {
    backgroundColor: '#FFF'
  })
}));

export const KeywordBox = styling('div')({
  boxSizing: 'border-box',
  flexFlow: 'wrap',
  display: 'flex',
  WebkitBoxAlign: 'center',
  alignItems: 'center',
  gap: '0.25rem'
});

export const KeywordTile = styling('div')(props => ({
  maxWidth: '100%',
  fontFamily: 'Inter, sans-serif',
  display: 'inline-flex',
  WebkitBoxAlign: 'center',
  alignItems: 'center',
  WebkitBoxPack: 'center',
  justifyContent: 'center',
  transition:
    'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  cursor: 'default',
  outline: '0px',
  textDecoration: 'none',
  verticalAlign: 'middle',
  boxSizing: 'border-box',
  color: 'rgb(34, 34, 34)',
  backgroundColor: 'rgba(81, 42, 204, 0.1)',
  marginLeft: '0px',
  fontWeight: 300,
  fontSize: '6px',
  padding: '5px 2px',
  letterSpacing: '0.025rem',
  whiteSpace: 'nowrap',
  textTransform: 'none',
  border: '1px solid rgb(34, 34, 34)',
  borderRadius: '3px',
  height: '0px',

  '& span': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingLeft: '5px',
    paddingRight: '5px',
    whiteSpace: 'nowrap'
  },

  ...((props.currentOption == 'job_slot_max' ||
    props.currentOption == 'job_slot_max_6m') && {
    backgroundColor: '#FFF'
  })
}));

export const GlobalHireTag = styling('div')(props => ({
  color: 'rgb(255, 255, 255)',
  borderRadius: '15px',
  padding: '2px 5px',
  backgroundColor: 'rgb(249, 202, 16)',
  fontSize: '6px',
  fontWeight: 500,
  letterSpacing: '0.7px',
  display: 'flex',
  WebkitBoxAlign: 'center',
  alignItems: 'center',
  gap: '2px',
  opacity: 1,

  ...((props.currentOption == 'job_slot_plus' ||
    props.currentOption == 'job_slot_plus_6m') && {
    opacity: 1
  }),

  ...((props.currentOption == 'job_slot_max' ||
    props.currentOption == 'job_slot_max_6m') && {
    opacity: 1
  })
}));

export const Text = styling('p')(props => ({
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 700,
  lineHeight: '20px',
  margin: 0
}));

export const Spacer = styling('div')(props => ({
  width: props.width ? props.width : '5px'
}));
