import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  keywordingJob,
  updateAllJobs,
  updateJob,
  updateKeywordHighlightConfirmationPopup,
  updateKeywordHighlightPopupStatus,
  updateKeywordHighlightTexts
} from '../../../../redux/actions/job_action';
import { ErrorToast, SuccessToast } from '../../../../utils/ToastUtils';
import MultipleTextFieldsPopup from '../../SharedPopups/MultipleTextFieldsPopup';
import store from '../../../../redux/stores/store';

let path = '';
let prevValue = [];
let extendKeyword = false;

const KeywordHighlightPopup = ({ textRef }) => {
  const dispatch = useDispatch();

  const keywordHighlightTexts = useSelector(
    state => state.jobs.keywordHighlightTexts
  );
  const job = useSelector(state => state.jobs.job);
  const loading = useSelector(state => state.jobs.keywordingJob);
  const isKeywordHighlightOpen = useSelector(
    state => state.jobs.isKeywordHighlightOpen
  );
  const allJobs = store.getState().jobs.jobs;
  // const allJobs = useSelector(state => state.jobs.jobs);

  const [indexOfSelectedField, setIndexOfSelectedField] = useState(0);
  const [isEditKeyword, setIsEditKeyword] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  const enableKeywordHighlight = job?.keywordHighlight;

  const onClosePopup = () => {
    dispatch(updateKeywordHighlightPopupStatus(false));
  };

  const onTypeText = (e, index) => {
    if (e.target.value.length > 0) {
      setIsTyping(true);
    }

    if (e.target.value.length > 20) return;

    if (prevValue) {
      let textIndex = prevValue.findIndex(item => {
        return item == e.target.value;
      });

      if (textIndex == -1) {
        setEnableSave(true);
      } else {
        setEnableSave(false);
      }
    };

    let temp = keywordHighlightTexts.map(i => i);
    temp[index] = { keyword: e.target.value };

    //replace undefined element with string
    let undefinedValueIndex = temp.findIndex(
      item => item?.keyword == undefined
    );

    if (undefinedValueIndex > -1) {
      temp[undefinedValueIndex] = { keyword: '' };
    }

    dispatch(updateKeywordHighlightTexts(temp));
  };

  const onClickButton = () => {
    extendKeyword = false;

    if (extendDuration(job) && isEditKeyword && path == 'extend') {
      extendKeyword = true;
    }

    let keywords = keywordHighlightTexts.map(item => {
      return item.keyword;
    });

    if (enableKeywordHighlight) {
      if (!isEditKeyword) {
        setIsEditKeyword(true);
        path = 'edit-keyword';

        setTimeout(() => {
          textRef.current.focus();
        }, 500);

        window.dataLayer.push({
          event: 'CE_edit-keyword-highlights-edit'
        });
      } else {
        if (path == 'edit-keyword') {
          let cTexts = [...keywordHighlightTexts];

          let filteredTexts = cTexts.filter(item => {
            return item?.keyword != '';
          });

          if (filteredTexts.length > 0) {
            applyJobAd();

            window.dataLayer.push({
              event: 'CE_edit-keyword-highlights-save',
              keywords: keywords.join('<>').trim().toLowerCase()
            });
          } else {
            ErrorToast('Please fill in at least 1 keyword');
          }
        } else {
          let cTexts = [...keywordHighlightTexts];

          let filteredTexts = cTexts.filter(item => {
            return item?.keyword != '';
          });

          if (filteredTexts.length > 0) {
            window.dataLayer.push({
              event: 'CE_edit-keyword-highlights-apply',
              keywords: keywords.join('<>').trim().toLowerCase()
            });

            dispatch(updateKeywordHighlightConfirmationPopup(true));
            dispatch(updateKeywordHighlightPopupStatus(false));
          } else {
            ErrorToast('Please fill in at least 1 keyword');
          }
        }
      }
    } else {
      let cTexts = [...keywordHighlightTexts];

      let filteredTexts = cTexts.filter(item => {
        return item?.keyword != '';
      });

      if (filteredTexts.length > 0) {
        window.dataLayer.push({
          event: 'CE_edit-keyword-highlights-apply',
          keywords: keywords.join('<>').trim().toLowerCase()
        });

        dispatch(updateKeywordHighlightConfirmationPopup(true));
        dispatch(updateKeywordHighlightPopupStatus(false));
      } else {
        ErrorToast('Please fill in at least 1 keyword');
      }
    }
  };

  const getButtonText = () => {
    if (enableKeywordHighlight) {
      if (isEditKeyword && extendDuration(job) && path == 'extend') {
        return 'Apply To Job Ad';
      } else if (path == 'edit-keyword' && isEditKeyword) {
        return 'Save Changes';
      } else {
        return 'Edit Keywords';
      }
    } else {
      return 'Apply To Job Ad';
    }
  };

  const extendDuration = job => {
    if (
      job?.keywordHighlightJobThreshold > 0 &&
      job?.keywordHighlightJobThreshold <= 25
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getExtraButtonText = job => {
    if (extendDuration(job) && !isEditKeyword) {
      return 'Extend';
    } else if (!isEditKeyword) {
      return ''; // hide button
    } else {
      return 'Cancel';
    }
  };

  const onClickExtraButton = job => {
    try {
      if (extendDuration(job) && !isEditKeyword) {
        path = 'extend';
        setIsEditKeyword(true);

        setTimeout(() => {
          textRef.current.focus();
        }, 500);

        window.dataLayer.push({
          event: 'CE_edit-keyword-highlights-extend'
        });
      } else {
        window.dataLayer.push({
          event: 'CE_edit-keyword-highlights-cancel'
        });
        onClosePopup();
      }
    } catch {}
  };

  const getPopupTitle = () => {
    if (extendDuration(job) && isEditKeyword && path == 'extend') {
      return 'Extend Key Highlights';
    } else if (isEditKeyword) {
      return 'Edit Key Highlights';
    } else if (!enableKeywordHighlight) {
      return 'Add Key Highlights';
    } else {
      return 'Key Highlights';
    }
  };

  const onFocusTextfield = index => {
    setIsTyping(false);
    setIndexOfSelectedField(index);
  };

  const applyJobAd = () => {
    try {
      let cTexts = [...keywordHighlightTexts];

      let filteredTexts = cTexts.filter(item => {
        return item?.keyword != '';
      });

      const params = {
        jobId: job?.id,
        extendKeywordHighlight: extendKeyword,
        keywords: filteredTexts
      };

      if (allJobs) {
        dispatch(keywordingJob(params)).then(res => {
          if (res.type == 'KEYWORD_HIGHLIGHT_JOB_SUCCEED') {
            const currentJobIndex = allJobs.findIndex(job => job.id == res.job.id);
            if (currentJobIndex > -1) {
              allJobs[currentJobIndex] = res.job;
            }
            dispatch(updateAllJobs(allJobs));
            SuccessToast('Keywording Job Successfully!');
            dispatch(updateKeywordHighlightPopupStatus(false));
            onClosePopup();
          } else {
            ErrorToast('Failed to keyword job');
          }
        });
      } else {
        
      } 
    }catch (err) {
      ErrorToast(err.message);
    }
  };

  useEffect(() => {
    if (job) {
      prevValue = job?.keywordHighlightText || []; 
    }
  }, [job?.keywordHighlightText]);

  return (
    <MultipleTextFieldsPopup
      onClosePopup={onClosePopup}
      numOfTextField={5}
      title={getPopupTitle()}
      desc={' Add a maximum of 5 keywords to attract the right candidates.'}
      extraButtonText={getExtraButtonText(job)}
      buttonText={getButtonText()}
      data={keywordHighlightTexts}
      onClick={onClickButton}
      onClickExtraButton={() => onClickExtraButton(job)}
      loading={loading}
      disabled={enableKeywordHighlight && !isEditKeyword}
      disabledButton={path == 'edit-keyword' && isEditKeyword && !enableSave}
      extraButtonTextLoading={false}
      onFocus={onFocusTextfield}
      onBlur={() => setIndexOfSelectedField(-1)}
      onChange={onTypeText}
      indexOfSelectedField={indexOfSelectedField}
      open={isKeywordHighlightOpen}
      placeholder={
        enableKeywordHighlight && !isEditKeyword ? '' : 'Enter a keyword'
      }
      isTyping={isTyping}
      textRef={textRef}
    />
  );
};

export default KeywordHighlightPopup;
