import { Info as InfoIcon } from '@mui/icons-material';
import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../../styles/colors.module.scss';

export const JobViewContainer = styling('div')((props) => ({
  display:'flex',
  alignItems: 'center',
  gap: '8px',
  position: 'relative',
}));

export const InfoIconStyled = styling(InfoIcon)((props) => ({
  color: 'rgba(102, 102, 102)',
  fontSize: '18px',
}));

export const Title = styling('span')(() => ({
  fontSize: '16px',
  fontFamily: 'Inter',
  letterSpacing: '0.15px',
}));

export const JobViewCount = styling('span')(() => ({
  fontSize: '16px',
  fontWeight: 700,
  fontFamily: 'Inter',
  letterSpacing: '0.15px',
  color: Colors.priPurple,
}));

export const TooltipText = styling('span')(() => ({
  fontWeight: 500,
  color: Colors.priBlack,
  fontSize: '12px',
}));
