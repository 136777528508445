import React, { useEffect, useRef, useState } from 'react';
import styles from './EmployerRegistration.module.scss';
import DefaultLayout from '../../layouts/DefaultLayout';
import { Stepper } from '../../components';
import {
  ButtonContainer,
  CardContainer,
  CardText,
  CardTextBold,
  CardTextError,
  CardTextLink,
  CardTextSmall,
  CardTitle,
  DigitContainer,
  DigitErrorContainer,
  ErrorContainer,
  ExclamationCircleIconStyled,
  ProceedButton,
  SkipButton,
  TextFieldDigit
} from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAccountInfo,
  mutateAccountInfo,
  sendVerificationEmail
} from '../../redux/actions/company_actions';
import { ErrorToast, SuccessToast } from '../../utils/ToastUtils';
import useGetQueryString from '../../hooks/useGetQueryString';

// http://localhost:3000/verification/?verification_code=392441&exp_date=1713335998

export default function AccountVerification() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const accountInfo = useSelector(state => state.companies.accountInfo);
  const fromHomepage = location.pathname == '/verification';
  const inputRefs = Array.from({ length: 6 }, () => useRef(null));
  const [inputValues, setInputValues] = useState(Array(6).fill(''));
  const codeFromParams = useGetQueryString('verification_code');

  useEffect(() => {
    if (codeFromParams) {
      const codesArray = codeFromParams.split('');
      setInputValues(codesArray);

      inputRefs.forEach((ref, index) => {
        if (ref.current) {
          ref.current.value = codesArray[index] || '';
        }
      });
    }
  }, [codeFromParams]);

  // Function to focus on the next input
  const focusNextInput = index => {
    if (index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  // Update input values and log them
  const handleInputChange = (value, index) => {
    setError(false);
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);
  };

  const resendHandler = () => {
    if (!loading) {
      dispatch(sendVerificationEmail()).then(res => {
        if (res?.status === 200) {
          SuccessToast('Email verification sent successfully');
        } else {
          ErrorToast('Email verification sent failed');
        }
      });
    }
  };

  const skipHandler = () => {
    if (fromHomepage) {
      history('/');
    } else {
      history('/register/company-details');
    }
  };

  // Print input values
  const proceedHandler = async () => {
    const pin = inputValues.join('');

    if (!pin) {
      setError(true);
      return;
    }

    setLoading(true);
    dispatch(
      mutateAccountInfo({
        input: {
          verificationCode: pin
        }
      })
    ).then(async res => {
      if (res?.mutateCompanyDetailsSuccess) {
        await dispatch(fetchAccountInfo());
        if (fromHomepage) {
          history(-1);
        } else {
          history('/register/company-details');
        }
      } else {
        setError(true);
        setLoading(false);
      }
    });
  };

  const onKeyDown = event => {
    if (event.key === 'Enter') {
      proceedHandler();
    }
  };

  return (
    <DefaultLayout>
      <div className={styles.pageWrapper}>
        {!fromHomepage && (
          <div className={styles.topSection}>
            <h4 className={styles.title} style={{ fontSize: '30px' }}>
              Create your employer account in 2 minutes!
            </h4>
            <div className={styles.flowSection}>
              <Stepper
                list={[
                  'Login Information',
                  'Email Verification',
                  'Company Details (Optional)'
                ]}
                currentStep={2}
              />
            </div>
          </div>
        )}

        {fromHomepage && (
          <Grid sx={{ marginBottom: '32px' }}>
            <h4 className={styles.title} style={{ fontSize: '30px' }}>
              Email Verification
            </h4>
          </Grid>
        )}

        <CardContainer>
          <CardTitle>Please verify your email address. </CardTitle>

          <CardText>
            An email was sent to{' '}
            <CardTextBold> {accountInfo?.email}</CardTextBold> with a 6-digit
            verification code. Didn’t receive it?{' '}
            <CardTextLink onClick={resendHandler}>Resend code</CardTextLink>
          </CardText>

          <DigitErrorContainer>
            <DigitContainer>
              {inputRefs.map((inputRef, index) => (
                <TextFieldDigit
                  autoFocus={index === 0}
                  key={index}
                  maxLength="1"
                  min="0"
                  max="9"
                  step="1"
                  type="number"
                  inputRef={inputRef}
                  error={error}
                  onKeyDown={onKeyDown}
                  onChange={e => {
                    handleInputChange(e.target.value, index);
                    const { value } = e.target;
                    if (value.length === 1) {
                      focusNextInput(index);
                    }
                  }}
                  inputProps={{
                    onKeyDown: e => {
                      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                        e.preventDefault();
                      }
                      if (e.target.value.length > 0 && e.key != 'Backspace') {
                        e.preventDefault();
                      }
                      if (
                        e.key === 'Backspace' &&
                        index > 0 &&
                        !e.target.value
                      ) {
                        inputRefs[index - 1]?.current.focus();
                      }
                    }
                  }}
                />
              ))}
            </DigitContainer>

            {error && (
              <ErrorContainer>
                <ExclamationCircleIconStyled />
                <CardTextError>
                  Your code may be invalid or has expired.
                </CardTextError>
              </ErrorContainer>
            )}
          </DigitErrorContainer>

          <CardTextSmall>
            For security reasons, you must verify your email address before you
            can begin using your account.{' '}
          </CardTextSmall>

          <ButtonContainer>
            <ProceedButton
              onClick={proceedHandler}
              variant="contained"
              disabled={loading}>
              Verify
            </ProceedButton>
            <SkipButton onClick={skipHandler} variant="text" disabled={loading}>
              I'll do this later
            </SkipButton>
          </ButtonContainer>
        </CardContainer>
      </div>
    </DefaultLayout>
  );
}
