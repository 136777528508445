import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { PopUpDialog, Spinner } from '../../../../components';
import FormRadio from '../../../../components/FormRadio';
import PreviewJobTab from '../../../../components/PreviewJobTab';
import store from '../../../../../src/redux/stores/store';
import { hardcodedProductsMap } from '../../../../content/products';
import {
  viewPackageSubscriptionDetails,
  changeJobStatus,
  fetchJob,
  mutateCreateDraftJob,
  mutateCreateJob,
  resetAvailableResources,
  updateJobsCurrentPage,
  updateTempJobDraft,
  updateSelectedFilterValue,
  updateSelectedFilter,
  updateActivateJobOptionPopup,
  updateJobsOffset,
  updateJobDataForm,
  updateJob,
  updateJobData
} from '../../../../redux/actions/job_action';
import {
  fetchStateRegions,
  fetchMyCompanyStatus,
  fetchMyCompanySettings
} from '../../../../redux/actions/company_actions';
import { ErrorToast, SuccessToast } from '../../../../utils/ToastUtils';
import { MUIButtonStyled } from '../../../../components/MUIButton';
import {
  ModalContainer,
  ModalTitle,
  ModalHeader,
  ModalSubHeader,
  ModalContent,
  ModalText,
  ModalTextPurple,
  LeftBox,
  RightBox,
  InfoBox,
  InfoText,
  InfoHeader,
  InfoHeaderRow,
  ModalFooter,
  ModalFooterPopUp,
  BackToEditButton,
  KeyboardBackspaceIconStyled,
  ContinueSection,
  PreviewTitle,
  PreviewBox,
  PreviewExpiry,
  BackgroundView,
  BackgroundTop,
  BackgroundBottom,
  SetToCenter,
  PreviewText,
  PurchaseBox,
  RefreshButton,
  NoJobBox,
  NoJobText
} from './styles';
import SkeletonLoader from './SkeletonLoader';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { formatToDefaultFormData } from '../../PostJob/helper/formatData';
import useSubmitLogic from '../../PostJob/controller/useSubmitLogic';
import useDraftLogic from '../../PostJob/controller/useDraftLogic';
import {
  getCountry,
  getCountryStates
} from '../../../../hooks/useRegionalisation';
import { CSEMAIL } from '../../../../utils/Constants';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const JobTypePopUp = ({
  open,
  handleClose,
  useAsPopUp,
  jobId,
  getCompanyAccountInfo
}) => {
  const history = useNavigate();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const jobData = useSelector(state => state?.jobs?.jobData);
  const jobDataForm = useSelector(state => state?.jobs?.jobDataForm);
  const draftJob = useSelector(state => state?.jobs?.draftJob);
  const jobsCurrentPage = useSelector(state => state.jobs.jobsCurrentPage);
  const formattedJobData = formatToDefaultFormData(jobData);
  const content =
    formattedJobData && Object.keys(formattedJobData).length > 0
      ? formattedJobData
      : jobDataForm;

  useEffect(() => {
    if (useAsPopUp) {
      dispatch(
        updateJobDataForm({
          ...content
        })
      );
    }
  }, [useAsPopUp, jobData]);

  const tempJobDraft = useSelector(state => state?.jobs?.tempJobDraft);

  const myCompanySettings = useSelector(
    state => state?.companies?.myCompanySettings
  );

  const stateRegions = useSelector(state => getCountryStates(state));

  let jobDataPreview;
  if (useAsPopUp) {
    jobDataPreview = useSelector(state => state?.jobs?.jobData);
  } else {
    jobDataPreview = useSelector(state => state?.jobs?.tempJobDraft);
  }

  const handleReduxChange = (key, value) => {
    dispatch(
      updateJobDataForm({
        ...jobDataForm,
        [key]: value
      })
    );
  };

  const isNewOrDraft = location.pathname.includes('/jobs/new-job/job-type');

  const isUpdateJob = !isNewOrDraft && jobData?.active && jobData?.resourceType;

  const locationName = tempJobDraft
    ? tempJobDraft?.stateRegion?.id
    : jobData && jobData?.stateRegion
    ? jobData?.stateRegion?.id
    : '0';

  const careerName =
    jobData && jobData?.jobType ? jobData?.jobType?.title : 'Full-Time';

  const keywordHighlightTokensUsableCount = useSelector(
    state =>
      state.jobs.subscribedKeywordHighlightTokensPackage?.total_usable_quantity
  );

  const enableKeywordState = jobData?.keywordHighlight;

  const myCompanyStatus = useSelector(state => state.companies.myCompanyStatus);

  const [expanded, setExpanded] = useState(false);

  const handleAccordionToggle = () => {
    setExpanded(prevExpanded => !prevExpanded);
  };

  const jobSlotDetail = hardcodedProductsMap.job_slot.dialogContent;
  const jobCreditDetail = hardcodedProductsMap.job_credit.dialogContent;

  let keywordHighlightStatus;
  let keywordDisplay;

  if (useAsPopUp) {
    keywordHighlightStatus = jobData?.keywordHighlightText?.length;
    keywordDisplay = jobData?.keywordHighlightText;
  } else {
    keywordHighlightStatus = jobDataForm?.keywords?.length;
    keywordDisplay = jobDataForm?.keywords;
  }

  function updateJobStatus(isUpdateJob, myCompanyStatus, jobData) {
    let status = {
      jobSlotsUsable: myCompanyStatus?.jobSlotsTotalUsableQuantity,
      jobCreditsUsable: myCompanyStatus?.availableJobCreditCount,
      jobSlotsPlusUseable: myCompanyStatus?.jobSlotPlusTotalUsableQuantity,
      jobSlotsMaxUseable: myCompanyStatus?.jobSlotMaxTotalUsableQuantity,
      jobSlotsSixMonthsUseable: myCompanyStatus?.jobSlot6mTotalUsableQuantity,
      jobSlotsPlusSixMonthsUseable:
        myCompanyStatus?.jobSlotPlus6mTotalUsableQuantity,
      jobSlotsMaxSixMonthsUseable:
        myCompanyStatus?.jobSlotMax6mTotalUsableQuantity,

      jobSlotExpiry: myCompanyStatus?.jobSlotNearestExpiryDate,
      jobSlotPlusExpiry: myCompanyStatus?.jobSlotPlusNearestExpiryDate,
      jobSlotMaxExpiry: myCompanyStatus?.jobSlotMaxNearestExpiryDate,
      jobSlotSixMonthsExpiry: myCompanyStatus?.jobSlot6mNearestExpiryDate,
      jobSlotPlusSixMonthsExpiry:
        myCompanyStatus?.jobSlotPlus6mNearestExpiryDate,
      jobSlotMaxSixMonthsExpiry: myCompanyStatus?.jobSlotMax6mNearestExpiryDate,
      jobCreditExpiry: myCompanyStatus?.jobCreditNearestExpiryDate,
      unlimitedCreditExpiry: myCompanyStatus?.unlimitedTokenExpiredAt,

      jobSlotExpiryDisplay: myCompanyStatus?.jobSlotNearestExpiryDate,
      jobSlotPlusExpiryDisplay: myCompanyStatus?.jobSlotPlusNearestExpiryDate,
      jobSlotMaxExpiryDisplay: myCompanyStatus?.jobSlotMaxNearestExpiryDate,
      jobSlotSixMonthsExpiryDisplay:
        myCompanyStatus?.jobSlot6mNearestExpiryDate,
      jobSlotPlusSixMonthsExpiryDisplay:
        myCompanyStatus?.jobSlotPlus6mNearestExpiryDate,
      jobSlotMaxSixMonthsExpiryDisplay:
        myCompanyStatus?.jobSlotMax6mNearestExpiryDate,
      jobCreditExpiryDisplay: myCompanyStatus?.jobCreditPreviewJobExpiryDate,
      unlimitedCreditExpiryDisplay:
        myCompanyStatus?.unlimitedTokenPreviewJobExpiryDate,
      isUseJobSlot: false,
      isUseJobSlotPlus: false,
      isUseJobSlotMax: false,
      isUseJobSlotSixMonths: false,
      isUseJobSlotPlusSixMonths: false,
      isUseJobSlotMaxSixMonths: false,
      isUseJobCredit: false
    };

    if (isUpdateJob) {
      const updateTypes = {
        job_slot: {
          increment: ['jobSlotsUsable'],
          setToJobDataExpiredAt: ['jobSlotExpiry', 'jobSlotExpiryDisplay'],
          setToTrue: ['isUseJobSlot']
        },
        job_slot_plus: {
          increment: ['jobSlotsPlusUseable'],
          setToJobDataExpiredAt: [
            'jobSlotPlusExpiry',
            'jobSlotPlusExpiryDisplay'
          ],
          setToTrue: ['isUseJobSlotPlus']
        },
        job_slot_max: {
          increment: ['jobSlotsMaxUseable'],
          setToJobDataExpiredAt: [
            'jobSlotMaxExpiry',
            'jobSlotMaxExpiryDisplay'
          ],
          setToTrue: ['isUseJobSlotMax']
        },
        job_slot_6m: {
          increment: ['jobSlotsSixMonthsUseable'],
          setToJobDataExpiredAt: [
            'jobSlotSixMonthsExpiry',
            'jobSlotSixMonthsExpiryDisplay'
          ],
          setToTrue: ['isUseJobSlotSixMonths']
        },
        job_slot_plus_6m: {
          increment: ['jobSlotsPlusSixMonthsUseable'],
          setToJobDataExpiredAt: [
            'jobSlotPlusSixMonthsExpiry',
            'jobSlotPlusSixMonthsExpiryDisplay'
          ],
          setToTrue: ['isUseJobSlotPlusSixMonths']
        },
        job_slot_max_6m: {
          increment: ['jobSlotsMaxSixMonthsUseable'],
          setToJobDataExpiredAt: [
            'jobSlotMaxSixMonthsExpiry',
            'jobSlotMaxSixMonthsExpiryDisplay'
          ],
          setToTrue: ['isUseJobSlotMaxSixMonths']
        },
        job_credit: {
          increment: ['jobCreditsUsable'],
          setToJobDataExpiredAt: ['jobCreditExpiry', 'jobCreditExpiryDisplay'],
          setToTrue: ['isUseJobCredit']
        },
        unlimited_job_token: {
          increment: ['jobCreditsUsable'],
          setToJobDataExpiredAt: ['unlimitedCreditExpiryDisplay'],
          setToTrue: ['isUseJobCredit']
        }
      };

      const updateType = updateTypes[isUpdateJob];
      if (updateType) {
        updateType.increment?.forEach(field => status[field]++);
        updateType.setToJobDataExpiredAt?.forEach(
          field => (status[field] = jobData?.expiredAt)
        );
        updateType.setToTrue?.forEach(field => (status[field] = true));
      }
    }

    return status;
  }

  const status = updateJobStatus(isUpdateJob, myCompanyStatus, jobData);

  const formattedJobSlotExpiry = status.jobSlotExpiry
    ? new Date(status.jobSlotExpiry).toLocaleDateString('en-MY')
    : '';
  const formattedJobSlotPlusExpiry = status.jobSlotPlusExpiry
    ? new Date(status.jobSlotPlusExpiry).toLocaleDateString('en-MY')
    : '';
  const formattedJobSlotMaxExpiry = status.jobSlotMaxExpiry
    ? new Date(status.jobSlotMaxExpiry).toLocaleDateString('en-MY')
    : '';
  const formattedJobSlotSixMonthsExpiry = status.jobSlotSixMonthsExpiry
    ? new Date(status.jobSlotSixMonthsExpiry).toLocaleDateString('en-MY')
    : '';
  const formattedJobSlotPlusSixMonthsExpiry = status.jobSlotPlusSixMonthsExpiry
    ? new Date(status.jobSlotPlusSixMonthsExpiry).toLocaleDateString('en-MY')
    : '';
  const formattedJobSlotMaxSixMonthsExpiry = status.jobSlotMaxSixMonthsExpiry
    ? new Date(status.jobSlotMaxSixMonthsExpiry).toLocaleDateString('en-MY')
    : '';
  const formattedJobCreditExpiry = status.jobCreditExpiry
    ? new Date(status.jobCreditExpiry).toLocaleDateString('en-MY')
    : '';
  const formattedUnlimitedExpiry = status.unlimitedCreditExpiry
    ? new Date(status.unlimitedCreditExpiry).toLocaleDateString('en-MY')
    : '';

  const formattedJobSlotExpiryDisplay = status.jobSlotExpiryDisplay
    ? new Date(status.jobSlotExpiryDisplay).toLocaleDateString('en-MY')
    : '';
  const formattedJobSlotPlusExpiryDisplay = status.jobSlotPlusExpiryDisplay
    ? new Date(status.jobSlotPlusExpiryDisplay).toLocaleDateString('en-MY')
    : '';
  const formattedJobSlotMaxExpiryDisplay = status.jobSlotMaxExpiryDisplay
    ? new Date(status.jobSlotMaxExpiryDisplay).toLocaleDateString('en-MY')
    : '';

  const formattedJobSlotSixMonthsExpiryDisplay =
    status.jobSlotSixMonthsExpiryDisplay
      ? new Date(status.jobSlotSixMonthsExpiryDisplay).toLocaleDateString(
          'en-MY'
        )
      : '';
  const formattedJobSlotPlusSixMonthsExpiryDisplay =
    status.jobSlotPlusSixMonthsExpiryDisplay
      ? new Date(status.jobSlotPlusSixMonthsExpiryDisplay).toLocaleDateString(
          'en-MY'
        )
      : '';
  const formattedJobSlotMaxSixMonthsExpiryDisplay =
    status.jobSlotMaxSixMonthsExpiryDisplay
      ? new Date(status.jobSlotMaxSixMonthsExpiryDisplay).toLocaleDateString(
          'en-MY'
        )
      : '';
  const formattedJobCreditExpiryDisplay = status.jobCreditExpiryDisplay
    ? new Date(status.jobCreditExpiryDisplay).toLocaleDateString('en-MY')
    : '';
  const formattedUnlimitedExpiryDisplay = status.unlimitedCreditExpiryDisplay
    ? new Date(status.unlimitedCreditExpiryDisplay).toLocaleDateString('en-MY')
    : '';

  let optionToken;
  let optionExpiry;
  let optionExpiryDisplay;
  let optionTitle;
  let optionId;
  let optionTypeName;
  let optionLearnMore;

  if (myCompanyStatus?.unlimitedToken == true) {
    optionToken = 100;
    optionExpiry = formattedUnlimitedExpiry;
    optionExpiryDisplay = formattedUnlimitedExpiryDisplay;
    optionTitle = 'Unlimited Job Token';
    optionId = 'unlimited_job_token';
    optionTypeName = 'UnlimitedJobToken';
    optionLearnMore = 'false';
  } else {
    optionToken = status.jobCreditsUsable;
    optionExpiry = formattedJobCreditExpiry;
    optionExpiryDisplay = formattedJobCreditExpiryDisplay;
    optionTitle = 'Job Credit';
    optionId = 'job_credit';
    optionTypeName = 'JobCredit';
    optionLearnMore = 'true';
  }
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [selectedTitle, setSelectedTitle] = useState('');
  const [selectedExpiry, setSelectedExpiry] = useState('');
  const [selectedToken, setSelectedToken] = useState('');
  const [hideKeyword, setHideKeyword] = useState(true);
  const [isSkeleton, setIsSkeleton] = useState(true);

  const enableScroll = () => {
    window.onscroll = function () {};
  };

  const disableScroll = () => {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop);
    };
  };

  const onClose = () => {
    if (Boolean(open)) {
      disableScroll();
      setTimeout(() => {
        enableScroll();
      }, 500);
    }

    setSelectedQuestion('');
    setSelectedTitle('');
    setSelectedExpiry('');
    setSelectedToken('');
    setIsLoading(false);
    setIsSkeleton(false);

    handleClose();
  };

  const onSubmitQuestion = async (values, selectedQuestion) => {
    let keywordHighlightState;

    if (selectedQuestion == 'job_slot_max') {
      keywordHighlightState = true;
    } else {
      if (selectedQuestion !== 'job_slot_max' && jobData?.keywordHighlight) {
        keywordHighlightState = true;
      } else {
        keywordHighlightState = values.active;
      }
    }

    try {
      const params = {
        active: true,
        jobId: jobId,
        archiveChat: false,
        deactivateReason: '',
        otherReason: '',
        resourceType: selectedQuestion,
        keywordHighlightToken: keywordHighlightState
      };

      setIsLoading(true);

      dispatch(changeJobStatus(params)).then(res => {
        const totalInactiveJobsCount =
          store.getState().jobs.totalJobsCount['inactive'];
        const searchJobValue = store.getState().jobs.searchJobValue;
        const selectedFilter = store.getState().jobs.selectedFilter;

        if (res.type == 'CHANGE_JOB_STATUS_SUCCEED') {
          // const updatedCount = totalInactiveJobsCount - 1;
          // const numOfJobsToCurrentPage = (jobsCurrentPage - 1) * 5;
          // const nextCurrentPage =
          //   updatedCount > numOfJobsToCurrentPage
          //     ? jobsCurrentPage - 1
          //     : jobsCurrentPage;

          // dispatch(updateJobsCurrentPage(nextCurrentPage));

          // const offset = nextCurrentPage > 1 ? (nextCurrentPage - 1) * 5 : 0;

          // const params = {
          //   first: 5,
          //   offset: offset,
          //   getCount: true,
          //   jobTitle: searchJobValue,
          //   ...{ isActive: selectedFilter === 'All' ? null : selectedFilter },
          //   orders: null
          // };

          // dispatch(fetchJobs(params));
          dispatch(resetAvailableResources()).then(() => {
            dispatch(viewPackageSubscriptionDetails());
            dispatch(updateSelectedFilterValue('active'));
            dispatch(updateSelectedFilter(true));
            dispatch(updateJobsOffset(0));
            dispatch(updateJobsCurrentPage(1));
          });

          if (getCompanyAccountInfo) {
            dispatch(getCompanyAccountInfo());
          }

          SuccessToast('Job has been activated!');
        } else {
          setIsLoading(false);
          ErrorToast(res?.error ? res?.error : 'Failed to update job!');
        }
        handleClose();
      });

      if (!useAsPopUp) {
        history(`/jobs`);
      } else {
        onClose();
      }
    } catch (err) {
      ErrorToast(err.message);
    }
  };

  const getCreateJobMessage = res => {
    let jobMessage = 'Job Posted Successfully!';

    if (res?.data?.createJob?.job?.freeInternshipJob) {
      jobMessage =
        'Internship posting applied. This posting will expire after 1 month.';
    } else if (res?.data?.createJob?.job?.freeSeniorJob) {
      jobMessage =
        'Free senior job posting applied. This posting will expire after 2 months.';
    }

    return jobMessage;
  };

  const fetchData = async () => {
    try {
      setIsSkeleton(true);

      if (jobId !== 'new-job' && jobId !== '') {
        await dispatch(fetchJob({ id: jobId }));
      }

      if (stateRegions.length == 0) {
        await Promise.all([
          dispatch(fetchStateRegions()),
          dispatch(fetchMyCompanySettings())
        ]);
      }

      await Promise.all([
        dispatch(fetchMyCompanyStatus()),
        dispatch(viewPackageSubscriptionDetails())
      ]);

      // All promises resolved
    } catch (error) {
      // Handle errors if needed
    } finally {
      setIsSkeleton(false);
    }
  };

  const handleRefreshProduct = async () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (jobId == '' || jobId == 'new-job') {
      return;
    } else {
      dispatch(
        fetchJob({
          id: jobId
        })
      );
    }
  }, [jobId]);

  const handleBackEdit = () => {
    window?.scrollTo({
      behavior: 'instant',
      top: 0
    });

    if (jobId === 'new-job') {
      history(`/jobs/add/new-job?back=true`);
    } else if (jobId === 'draft-job') {
      history(`/jobs/edit-job-draft?back=true`);
    } else if (jobId === 'intern-job') {
      history(`/jobs/add/free-intern?back=true`);
    } else if (jobId === 'senior-job') {
      history(`/jobs/add/free-senior?back=true`);
    } else {
      history(`/jobs/${jobDataForm?.id}?back=true`);
    }
  };

  const onPurchaseButtonClicked = async () => {
    window.open('/purchase/choose');
  };

  const { onSaveJobConfirm, loading } = useSubmitLogic(jobId);
  const { onSaveDraftJobConfirm, draftLoading } = useDraftLogic({
    confirm: true
  });

  const onConfirm = async () => {
    onSaveJobConfirm();
  };

  const onDraftSave = async () => {
    onSaveDraftJobConfirm();
  };

  const [activating, setActivating] = useState(false);

  const onActivatePopUp = async () => {
    setActivating(true);

    try {
      const params = {
        active: true,
        jobId: jobDataForm?.id,
        archiveChat: false,
        deactivateReason: '',
        otherReason: '',
        resourceType: jobDataForm?.resourceType,
        keywordHighlightToken: jobDataForm?.enableKeywordHighlight
      };

      const res = await dispatch(changeJobStatus(params));

      if (res?.type === 'CHANGE_JOB_STATUS_SUCCEED') {
        dispatch(updateSelectedFilterValue('active'));
        dispatch(updateSelectedFilter(true));
        dispatch(updateActivateJobOptionPopup(false));

        SuccessToast(`Job reactivated!`);
      } else {
        ErrorToast(res?.error);
      }

      dispatch(updateJob({}));
      dispatch(updateJobData({}));
      dispatch(updateJobDataForm({}));
    } catch (error) {
      ErrorToast('An error occurred while creating the job.');
    } finally {
      setActivating(false);
    }
  };

  const onActivate = async () => {
    setActivating(true);

    const params = {
      active: true,
      jobId: jobDataForm?.id,
      archiveChat: false,
      deactivateReason: '',
      otherReason: '',
      resourceType: jobDataForm?.resourceType,
      keywordHighlightToken: jobDataForm?.enableKeywordHighlight
    };

    try {
      const res = await dispatch(changeJobStatus(params));

      if (res?.type === 'CHANGE_JOB_STATUS_SUCCEED') {
        setActivating(false);
        SuccessToast(`Job reactivated!`);

        dispatch(updateSelectedFilterValue('active'));
        dispatch(updateSelectedFilter(true));
        dispatch(updateActivateJobOptionPopup(false));
      } else {
        ErrorToast(res?.error);
      }

      dispatch(updateJob({}));
      dispatch(updateJobData({}));
      dispatch(updateJobDataForm({}));
    } catch (error) {
      ErrorToast('An error occurred while creating the job.');
    } finally {
      setActivating(false);
    }
  };

  const renderContent = () => (
    <SetToCenter isPopUp={useAsPopUp}>
      <ModalContainer isPopUp={useAsPopUp}>
        <ModalTitle>
          {optionToken +
            status.jobSlotsUsable +
            status.jobSlotsPlusUseable +
            status.jobSlotsMaxUseable +
            status.jobSlotsMaxSixMonthsUseable +
            status.jobSlotsPlusSixMonthsUseable ==
          0 ? (
            <>
              <ModalHeader>No job ad product available.</ModalHeader>
              <ModalSubHeader>
                <ModalText>
                  Purchase a job ad product to continue with your job posting.
                </ModalText>

                <PurchaseBox
                  isPopUp={useAsPopUp}
                  onClick={() => window.open('/purchase/choose?')}>
                  <ModalTextPurple>Purchase Now</ModalTextPurple>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none">
                    <path
                      d="M9 4.5H3.5C2.67157 4.5 2 5.17157 2 6V13C2 13.8284 2.67157 14.5 3.5 14.5H10.5C11.3284 14.5 12 13.8284 12 13V7.5M5 11.5L14 2.5M14 2.5L10.5 2.5M14 2.5V6"
                      stroke="#512ACC"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {/* <KeyboardBackspaceIconStyled /> */}
                </PurchaseBox>
              </ModalSubHeader>
            </>
          ) : (
            <>
              <ModalHeader>
                What would you like to post your job with?
              </ModalHeader>
              <ModalSubHeader>
                <ModalText>
                  Select an option below and preview your job ad on the right.
                </ModalText>

                <PurchaseBox
                  isPopUp={useAsPopUp}
                  onClick={() => window.open('/purchase/choose')}>
                  <ModalTextPurple>Purchase Now</ModalTextPurple>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none">
                    <path
                      d="M9 4.5H3.5C2.67157 4.5 2 5.17157 2 6V13C2 13.8284 2.67157 14.5 3.5 14.5H10.5C11.3284 14.5 12 13.8284 12 13V7.5M5 11.5L14 2.5M14 2.5L10.5 2.5M14 2.5V6"
                      stroke="#512ACC"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {/* <KeyboardBackspaceIconStyled /> */}
                </PurchaseBox>
              </ModalSubHeader>
            </>
          )}
          <IconButton
            sx={{ position: 'absolute', top: '10px', right: '12px' }}
            onClick={onClose}>
            <Close />
          </IconButton>
        </ModalTitle>

        <ModalContent>
          {!isSkeleton && selectedTitle ? (
            <>
              <RightBox>
                <BackgroundView isPopUp={useAsPopUp}>
                  <BackgroundTop isPopUp={useAsPopUp}>
                    <img
                      src="https://assets.wobbjobs.com/images/sample-job.png"
                      style={{
                        width: '100%',
                        height: '100%',
                        filter: 'blur(5px)'
                      }}
                    />

                    <img
                      src="https://assets.wobbjobs.com/images/sample-job.png"
                      style={{
                        width: '100%',
                        height: '100%',
                        filter: 'blur(5px)'
                      }}
                    />

                    <img
                      src="https://assets.wobbjobs.com/images/sample-job.png"
                      style={{
                        width: '100%',
                        height: '100%',
                        filter: 'blur(5px)'
                      }}
                    />
                  </BackgroundTop>

                  <BackgroundBottom isPopUp={useAsPopUp}>
                    <img
                      src="https://assets.wobbjobs.com/images/sample-job.png"
                      style={{
                        width: '100%',
                        height: '100%',
                        filter: 'blur(5px)'
                      }}
                    />

                    <img
                      src="https://assets.wobbjobs.com/images/sample-job.png"
                      style={{
                        width: '100%',
                        height: '100%',
                        filter: 'blur(5px)'
                      }}
                    />

                    <img
                      src="https://assets.wobbjobs.com/images/sample-job.png"
                      style={{
                        width: '100%',
                        height: '100%',
                        filter: 'blur(5px)'
                      }}
                    />
                  </BackgroundBottom>
                </BackgroundView>
                <PreviewBox>
                  <PreviewTitle>
                    Showing preview for{' '}
                    <b style={{ color: '#512ACC' }}>{selectedTitle}</b>:
                  </PreviewTitle>
                </PreviewBox>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 'calc(100% - 80px)'
                  }}>
                  <PreviewJobTab
                    id={selectedQuestion}
                    content={content}
                    location={locationName}
                    career={careerName}
                    keyword={keywordDisplay}
                    isPopUp={useAsPopUp}
                    companyName={myCompanySettings?.name}
                    companyImg={myCompanySettings?.publicLogoUrl}
                    getAllState={stateRegions}
                    hideKeyword={
                      content?.resourceType === 'job_slot_max' ||
                      content?.resourceType === 'job_slot_max_6m'
                        ? false
                        : hideKeyword
                    }></PreviewJobTab>

                  {selectedExpiry !== '' && (
                    <PreviewExpiry>
                      <span>
                        This job ad will be listed until{' '}
                        <p> {selectedExpiry}</p>
                      </span>
                    </PreviewExpiry>
                  )}
                </div>
              </RightBox>
            </>
          ) : (
            <>
              <RightBox>
                <BackgroundView isPopUp={useAsPopUp}>
                  <BackgroundTop isPopUp={useAsPopUp}>
                    <img
                      src="https://assets.wobbjobs.com/images/sample-job.png"
                      style={{
                        width: '100%',
                        height: '100%',
                        filter: 'blur(5px)'
                      }}
                    />

                    <img
                      src="https://assets.wobbjobs.com/images/sample-job.png"
                      style={{
                        width: '100%',
                        height: '100%',
                        filter: 'blur(5px)'
                      }}
                    />

                    <img
                      src="https://assets.wobbjobs.com/images/sample-job.png"
                      style={{
                        width: '100%',
                        height: '100%',
                        filter: 'blur(5px)'
                      }}
                    />
                  </BackgroundTop>

                  <BackgroundBottom isPopUp={useAsPopUp}>
                    <img
                      src="https://assets.wobbjobs.com/images/sample-job.png"
                      style={{
                        width: '100%',
                        height: '100%',
                        filter: 'blur(5px)'
                      }}
                    />

                    <img
                      src="https://assets.wobbjobs.com/images/sample-job.png"
                      style={{
                        width: '100%',
                        height: '100%',
                        filter: 'blur(5px)'
                      }}
                    />

                    <img
                      src="https://assets.wobbjobs.com/images/sample-job.png"
                      style={{
                        width: '100%',
                        height: '100%',
                        filter: 'blur(5px)'
                      }}
                    />
                  </BackgroundBottom>
                </BackgroundView>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 'calc(100% - 80px)'
                  }}>
                  <PreviewText>
                    Select a product on the left to preview the job ad
                  </PreviewText>
                </div>
              </RightBox>
            </>
          )}

          <LeftBox>
            <InfoBox>
              <InfoHeaderRow onClick={handleAccordionToggle}>
                <InfoHeader>
                  <ArrowPathIcon
                    style={{
                      width: '24px',
                      height: '24px',
                      color: '#D2A711'
                    }}
                  />
                  <InfoText>
                    <b>Refresh to see your latest purchase. </b>
                  </InfoText>
                </InfoHeader>

                <RefreshButton onClick={handleRefreshProduct}>
                  <ArrowPathIcon
                    style={{
                      width: '16px',
                      height: '16px',
                      color: '#222'
                    }}
                  />
                  <p>Refresh Products</p>
                </RefreshButton>
              </InfoHeaderRow>
            </InfoBox>

            {isSkeleton ? (
              <>
                <SkeletonLoader />
              </>
            ) : (
              <>
                <div
                  style={{
                    padding: '60px 24px 24px 24px',
                    height: '460px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                    overflow: 'auto'
                  }}>
                  <FormRadio
                    name="question1"
                    options={[
                      {
                        id: optionId,
                        type: optionId,
                        title: optionTitle,
                        __typename: optionTypeName,
                        expiry: optionExpiry,
                        expiryDisplay: optionExpiryDisplay,
                        learnMore: optionLearnMore,
                        tokens: optionToken,
                        isUsing: status.isUseJobCredit,
                        content: [{ content: jobCreditDetail }]
                      },
                      {
                        id: 'job_slot',
                        type: 'job_slot',
                        title: 'Job Slot',
                        __typename: 'JobSlot',
                        expiry: formattedJobSlotExpiry,
                        expiryDisplay: formattedJobSlotExpiryDisplay,
                        learnMore: 'true',
                        tokens: status.jobSlotsUsable,
                        isUsing: status.isUseJobSlot,
                        content: [
                          {
                            questions: 'What is a Job Slot?',
                            answer:
                              'Our Job Slots are designed to offer you maximum flexibility with a one (1) year subscription period. During this time, you can effortlessly post and swap job postings as needed – think of it as having a reserved parking spot for your job listings!'
                          },
                          {
                            questions:
                              "Can I switch out a job ad once it's posted with a Job Slot?",
                            answer:
                              "Absolutely! If you've filled a position from the first job posting, you can seamlessly switch it out for your next vacancy. Please keep in mind that only one job can occupy an active job slot at any given time. To switch, simply deactivate the current job before posting a new one."
                          },
                          {
                            questions:
                              "What happens to a job once it's advertised?",
                            answer:
                              "Once a job is advertised, it remains active for as long as the job slot subscription is active or until the job is deactivated. There's no need to repost or reactivate the job – it stays visible to potential candidates throughout the subscription period."
                          },
                          {
                            questions:
                              'Can I deactivate a job before the end of the subscription period?',
                            answer:
                              'Certainly! You have the flexibility to deactivate a job at any time during the subscription period. This allows you to manage your active job listings based on your hiring needs. Remember, only one job can be active in a slot at a time.'
                          },
                          {
                            questions:
                              'How is the billing structured for Job Slots?',
                            answer:
                              'All job slots are billed annually. This means you only need to pay once a year for each slot you have.'
                          },
                          {
                            questions:
                              'Why are Job Slots more expensive than normal ads?',
                            answer:
                              "Actually, it's much cheaper! With most job portals, the price you pay is to advertise a job for 1 month. With Hiredly's Job Slots, the price you pay is for 12 months. Within these 12 months, you're allowed to flexibly change the job advertisement in your Job Slot, any time, for any job."
                          }
                        ]
                      },
                      {
                        id: 'job_slot_6m',
                        type: 'job_slot_6m',
                        title: 'Job Slot (6 Months)',
                        modalTitle: 'Job Slot',
                        __typename: 'JobSlotSixMonths',
                        expiry: formattedJobSlotSixMonthsExpiry,
                        expiryDisplay: formattedJobSlotSixMonthsExpiryDisplay,
                        learnMore: 'true',
                        tokens: status.jobSlotsSixMonthsUseable,
                        isUsing: status.isUseJobSlotSixMonths,
                        content: [
                          {
                            questions: 'What is a Job Slot?',
                            answer:
                              'Our Job Slots are designed to offer you maximum flexibility with a one (1) year subscription period. During this time, you can effortlessly post and swap job postings as needed – think of it as having a reserved parking spot for your job listings!'
                          },
                          {
                            questions:
                              "Can I switch out a job ad once it's posted with a Job Slot?",
                            answer:
                              "Absolutely! If you've filled a position from the first job posting, you can seamlessly switch it out for your next vacancy. Please keep in mind that only one job can occupy an active job slot at any given time. To switch, simply deactivate the current job before posting a new one."
                          },
                          {
                            questions:
                              "What happens to a job once it's advertised?",
                            answer:
                              "Once a job is advertised, it remains active for as long as the job slot subscription is active or until the job is deactivated. There's no need to repost or reactivate the job – it stays visible to potential candidates throughout the subscription period."
                          },
                          {
                            questions:
                              'Can I deactivate a job before the end of the subscription period?',
                            answer:
                              'Certainly! You have the flexibility to deactivate a job at any time during the subscription period. This allows you to manage your active job listings based on your hiring needs. Remember, only one job can be active in a slot at a time.'
                          },
                          {
                            questions:
                              'How is the billing structured for Job Slots?',
                            answer:
                              'All job slots are billed annually. This means you only need to pay once a year for each slot you have.'
                          },
                          {
                            questions:
                              'Why are Job Slots more expensive than normal ads?',
                            answer:
                              "Actually, it's much cheaper! With most job portals, the price you pay is to advertise a job for 1 month. With Hiredly's Job Slots, the price you pay is for 12 months. Within these 12 months, you're allowed to flexibly change the job advertisement in your Job Slot, any time, for any job."
                          }
                        ]
                      },

                      {
                        id: 'job_slot_plus',
                        type: 'job_slot_plus',
                        title: 'Job Slot Plus ',
                        __typename: 'ProJobSlot',
                        expiry: formattedJobSlotPlusExpiry,
                        expiryDisplay: formattedJobSlotPlusExpiryDisplay,
                        learnMore: 'true',
                        tokens: status.jobSlotsPlusUseable,
                        isUsing: status.isUseJobSlotPlus,
                        content: [
                          {
                            questions: 'What is a Job Slot Plus?',
                            answer:
                              'It is the same job slot you have always known, but better! Flexibly post and swap job postings throughout the 12 months, all while having automated visibility boosts!'
                          },
                          {
                            questions:
                              'What is the difference between a Job Slot Max and a Job Slot Plus?',
                            answer:
                              'Job Slot Plus provides automated job positioning to ensure it appears at the front of our jobs list and catches the eye of more job seekers. However, The Job Slot Plus does not include exclusive design or increased real estate that comes with a Job Slot Max.'
                          },
                          {
                            questions:
                              'How can I maximise the visibility of my job with the Job Slot Plus?',
                            answer: `Apply any of our existing visibility booster tokens on top of your Job Slot Plus to maximise your jobs’ visibility, or simply upgrade your Job Slot Plus to a Job Slot Max. Contact us at ${
                              CSEMAIL[getCountry()]
                            } or reach out to your dedicated sales consultant to find out how today!`
                          },
                          {
                            questions:
                              'Do I need to manually refresh my job ad with the Job Slot Plus?',
                            answer:
                              "No need for manual actions! We will take care of maintaining your job's visibility, allowing you to focus on other aspects of your hiring process."
                          },
                          {
                            questions:
                              "I've already posted a job using a Job Slot. Can I upgrade it to a Job Slot Plus?",
                            answer:
                              "It's simple! Just go to your posted job, click on the edit option, and choose a Job Slot Plus that is currently active and available. Your job will then benefit from the upgraded features, giving your job an added boost."
                          },
                          {
                            questions:
                              'Is there an additional cost associated with upgrading my job to a Job Slot Plus?',
                            answer:
                              "If you've already purchased a Job Slot Plus separately, there's no extra cost to upgrade an existing job. Edit your job and choose the upgraded slot."
                          }
                        ]
                      },
                      {
                        id: 'job_slot_plus_6m',
                        type: 'job_slot_plus_6m',
                        title: 'Job Slot Plus (6 Months)',
                        modalTitle: 'Job Slot Plus',
                        __typename: 'ProJobSlotSixMonths',
                        expiry: formattedJobSlotPlusSixMonthsExpiry,
                        expiryDisplay:
                          formattedJobSlotPlusSixMonthsExpiryDisplay,
                        learnMore: 'true',
                        tokens: status.jobSlotsPlusSixMonthsUseable,
                        isUsing: status.isUseJobSlotPlusSixMonths,
                        content: [
                          {
                            questions: 'What is a Job Slot Plus?',
                            answer:
                              'It is the same job slot you have always known, but better! Flexibly post and swap job postings throughout the 12 months, all while having automated visibility boosts!'
                          },
                          {
                            questions:
                              'What is the difference between a Job Slot Max and a Job Slot Plus?',
                            answer:
                              'Job Slot Plus provides automated job positioning to ensure it appears at the front of our jobs list and catches the eye of more job seekers. However, The Job Slot Plus does not include exclusive design or increased real estate that comes with a Job Slot Max.'
                          },
                          {
                            questions:
                              'How can I maximise the visibility of my job with the Job Slot Plus?',
                            answer: `Apply any of our existing visibility booster tokens on top of your Job Slot Plus to maximise your jobs’ visibility, or simply upgrade your Job Slot Plus to a Job Slot Max. Contact us at ${
                              CSEMAIL[getCountry()]
                            } or reach out to your dedicated sales consultant to find out how today!`
                          },
                          {
                            questions:
                              'Do I need to manually refresh my job ad with the Job Slot Plus?',
                            answer:
                              "No need for manual actions! We will take care of maintaining your job's visibility, allowing you to focus on other aspects of your hiring process."
                          },
                          {
                            questions:
                              "I've already posted a job using a Job Slot. Can I upgrade it to a Job Slot Plus?",
                            answer:
                              "It's simple! Just go to your posted job, click on the edit option, and choose a Job Slot Plus that is currently active and available. Your job will then benefit from the upgraded features, giving your job an added boost."
                          },
                          {
                            questions:
                              'Is there an additional cost associated with upgrading my job to a Job Slot Plus?',
                            answer:
                              "If you've already purchased a Job Slot Plus separately, there's no extra cost to upgrade an existing job. Edit your job and choose the upgraded slot."
                          }
                        ]
                      },
                      {
                        id: 'job_slot_max',
                        type: 'job_slot_max',
                        title: 'Job Slot Max',
                        __typename: 'SuperJobSlot',
                        expiry: formattedJobSlotMaxExpiry,
                        expiryDisplay: formattedJobSlotMaxExpiryDisplay,
                        learnMore: 'true',
                        tokens: status?.jobSlotsMaxUseable,
                        isUsing: status?.isUseJobSlotMax,
                        content: [
                          {
                            questions:
                              'What is a Job Slot Max, and how does it enhance my job visibility?',
                            answer:
                              'The Job Slot Max is our premium job ad designed to ensure your job stands out effortlessly. With an exclusive job ad design and the convenience of automated visibility boosting, attracting talents has never been easier - Enjoy the same features as our classic job slot, including the flexibility to swap and repost job ads anytime throughout the year.'
                          },
                          {
                            questions:
                              'How does the automated visibility boosting work for Job Slot Max?',
                            answer:
                              'To ensure optimal visibility on your job ad throughout the year, the Job Slot Max will permanently spotlight and apply key highlights to your job in addition to automatically refreshing the ad every 7 days for you. With this, your job will be prioritised when shown to job seekers - allowing you to reach more talent without the hassle.'
                          },
                          {
                            questions:
                              'What happens if I deactivate a job posted using my Job Slot Max?',
                            answer:
                              'Deactivating a job posted using a Job Slot Max will immediately turn off all automated visibility boosters, and your job will be removed from the job listing. However, your key highlights will be saved, and you can reactivate the job anytime.'
                          },
                          {
                            questions:
                              'Can I upgrade my existing job slot to a Job Slot Max?',
                            answer: `Yes, you can seamlessly upgrade your current job slot to a Job Slot Max. Simply contact your sales consultant or email us at ${
                              CSEMAIL[getCountry()]
                            } to find out how!`
                          },
                          {
                            questions:
                              'Is there a limit to the number of Job Slot Max available, and how can I check the number of available slots?',
                            answer:
                              'Yes, we are only releasing a limited number of Job Slot Max at the moment! Check our product catalogue to find out how many are available for purchase.'
                          },
                          {
                            questions:
                              'I just purchased a Job Slot Max, can I use it immediately?',
                            answer:
                              'New purchases will take a moment to update! Ensure that you save your current job draft, and thereafter, you can easily go back to your job to complete the posting by selecting your new Job Slot Max or simply edit an existing job ad to switch it to a Job Slot Max!'
                          },
                          {
                            questions: 'How can I track my jobs’ performance?',
                            answer: `Easily keep track of your jobs’ view count via your dashboard or alternatively, contact us at ${
                              CSEMAIL[getCountry()]
                            } for more in-depth insights or assistance!`
                          }
                        ]
                      },
                      {
                        id: 'job_slot_max_6m',
                        type: 'job_slot_max_6m',
                        title: 'Job Slot Max (6 Months)',
                        modalTitle: 'Job Slot Max',
                        __typename: 'SuperJobSlotSixMonths',
                        expiry: formattedJobSlotMaxSixMonthsExpiry,
                        expiryDisplay:
                          formattedJobSlotMaxSixMonthsExpiryDisplay,
                        learnMore: 'true',
                        tokens: status.jobSlotsMaxSixMonthsUseable,
                        isUsing: status.isUseJobSlotMaxSixMonths,
                        content: [
                          {
                            questions:
                              'What is a Job Slot Max, and how does it enhance my job visibility?',
                            answer:
                              'The Job Slot Max is our premium job ad designed to ensure your job stands out effortlessly. With an exclusive job ad design and the convenience of automated visibility boosting, attracting talents has never been easier - Enjoy the same features as our classic job slot, including the flexibility to swap and repost job ads anytime throughout the year.'
                          },
                          {
                            questions:
                              'How does the automated visibility boosting work for Job Slot Max?',
                            answer:
                              'To ensure optimal visibility on your job ad throughout the year, the Job Slot Max will permanently spotlight and apply key highlights to your job in addition to automatically refreshing the ad every 7 days for you. With this, your job will be prioritised when shown to job seekers - allowing you to reach more talent without the hassle.'
                          },
                          {
                            questions:
                              'What happens if I deactivate a job posted using my Job Slot Max?',
                            answer:
                              'Deactivating a job posted using a Job Slot Max will immediately turn off all automated visibility boosters, and your job will be removed from the job listing. However, your key highlights will be saved, and you can reactivate the job anytime.'
                          },
                          {
                            questions:
                              'Can I upgrade my existing job slot to a Job Slot Max?',
                            answer: `Yes, you can seamlessly upgrade your current job slot to a Job Slot Max. Simply contact your sales consultant or email us at ${
                              CSEMAIL[getCountry()]
                            } to find out how!`
                          },
                          {
                            questions:
                              'Is there a limit to the number of Job Slot Max available, and how can I check the number of available slots?',
                            answer:
                              'Yes, we are only releasing a limited number of Job Slot Max at the moment! Check our product catalogue to find out how many are available for purchase.'
                          },
                          {
                            questions:
                              'I just purchased a Job Slot Max, can I use it immediately?',
                            answer:
                              'New purchases will take a moment to update! Ensure that you save your current job draft, and thereafter, you can easily go back to your job to complete the posting by selecting your new Job Slot Max or simply edit an existing job ad to switch it to a Job Slot Max!'
                          },
                          {
                            questions: 'How can I track my jobs’ performance?',
                            answer: `Easily keep track of your jobs’ view count via your dashboard or alternatively, contact us at ${
                              CSEMAIL[getCountry()]
                            } for more in-depth insights or assistance!`
                          }
                        ]
                      }
                    ]}
                    selected={selectedQuestion}
                    setSelected={setSelectedQuestion}
                    selectedTitle={selectedTitle}
                    setSelectedTitle={setSelectedTitle}
                    selectedExpiry={selectedExpiry}
                    setSelectedExpiry={setSelectedExpiry}
                    selectedToken={selectedToken}
                    setSelectedToken={setSelectedToken}
                    selectedQuestion={selectedQuestion}
                    enableKeywordState={enableKeywordState}
                    keywordHighlightStatus={keywordHighlightStatus ?? 0}
                    keywordHighlightTokensUsableCount={
                      keywordHighlightTokensUsableCount ?? 0
                    }
                    setHideKeyword={setHideKeyword}
                    onPurchaseButtonClicked={onPurchaseButtonClicked}
                    handleBackEdit={handleBackEdit}
                  />

                  {optionToken +
                    status.jobSlotsUsable +
                    status.jobSlotsPlusUseable +
                    status.jobSlotsMaxUseable +
                    status.jobSlotsPlusSixMonthsUseable +
                    status.jobSlotsMaxSixMonthsUseable ==
                  0 ? (
                    <>
                      <NoJobBox>
                        <NoJobText>
                          No job ad product available.
                          <br />
                          <p>
                            Purchase{' '}
                            <a onClick={() => onPurchaseButtonClicked()}>
                              here
                            </a>{' '}
                            to continue with your job posting.
                          </p>
                        </NoJobText>
                      </NoJobBox>
                    </>
                  ) : null}
                </div>
              </>
            )}
          </LeftBox>
        </ModalContent>

        {!useAsPopUp && (
          <ModalFooter>
            <BackToEditButton onClick={handleBackEdit}>
              <KeyboardBackspaceIconStyled />
              Back to Editing
            </BackToEditButton>

            <ContinueSection>
              {jobId === 'new-job' || jobId === 'draft-job' ? (
                <MUIButtonStyled
                  large
                  disabled={loading || draftLoading}
                  $borderRadius={'8px'}
                  $backgroundColor={'none'}
                  $fontSize={'16px'}
                  $color={'#512ACC'}
                  $shadow={'none !important'}
                  onClick={() => onDraftSave()}
                  label={
                    draftLoading ? (
                      <Spinner size={'XS'} noPadding={true}></Spinner>
                    ) : (
                      'Save as Draft'
                    )
                  }
                />
              ) : null}

              <MUIButtonStyled
                large
                $borderRadius={'8px'}
                type="submit"
                disabled={
                  ((selectedQuestion == 'job_slot_max' ||
                    selectedQuestion == 'job_slot_max_6m') &&
                    selectedToken > 0 &&
                    keywordHighlightStatus <= 0) ||
                  optionToken +
                    status.jobSlotsUsable +
                    status.jobSlotsPlusUseable +
                    status.jobSlotsMaxUseable +
                    status.jobSlotsPlusSixMonthsUseable +
                    status.jobSlotsMaxSixMonthsUseable ==
                    0 ||
                  selectedToken <= 0 ||
                  loading ||
                  draftLoading
                }
                onClick={() => onConfirm()}
                label={
                  loading ? (
                    <Spinner
                      size={'XS'}
                      color={'white'}
                      noPadding={true}></Spinner>
                  ) : (
                    'Confirm'
                  )
                }
              />
            </ContinueSection>
          </ModalFooter>
        )}

        {useAsPopUp && (
          <ModalFooterPopUp>
            <MUIButtonStyled
              large
              $borderRadius={'8px'}
              type="submit"
              disabled={
                ((selectedQuestion == 'job_slot_max' ||
                  selectedQuestion == 'job_slot_max_6m') &&
                  selectedToken > 0 &&
                  keywordHighlightStatus < 0) ||
                optionToken +
                  status.jobSlotsUsable +
                  status.jobSlotsPlusUseable +
                  status.jobSlotsMaxUseable +
                  status.jobSlotsSixMonthsUseable +
                  status.jobSlotsPlusSixMonthsUseable +
                  status.jobSlotsMaxSixMonthsUseable ==
                  0 ||
                selectedToken <= 0 ||
                loading ||
                activating
              }
              onClick={() => onActivatePopUp()}
              label={
                activating ? (
                  <Spinner size={'XS'} noPadding={true}></Spinner>
                ) : (
                  'Activate Job Now'
                )
              }
            />
          </ModalFooterPopUp>
        )}
      </ModalContainer>
    </SetToCenter>
  );

  if (useAsPopUp) {
    return (
      <PopUpDialog
        open={open}
        // handleClose={onClose}
        hideCloseIcon={true}
        title=""
        paperStyle={{ minHeight: 500 }}
        maxDialogWidth={1020}
        dialogWidth={'auto'}
        removeContentPadding={true}
        hideTitle={true}>
        {renderContent()}
      </PopUpDialog>
    );
  }

  return <div>{renderContent()}</div>;
};

JobTypePopUp.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  useAsPopUp: PropTypes.bool,
  jobId: PropTypes.string
};

JobTypePopUp.defaultProps = {
  open: false,
  handleClose: () => {},
  useAsPopUp: true,
  jobId: null
};

export default JobTypePopUp;
