import React from 'react';
import { useSelector } from 'react-redux';

const Resume = () => {
  const talent = useSelector(state => state.talents.talent);

  return (
    <iframe
      title="resume"
      src={
        talent.resume?.includes('pdf')
          ? `https:${talent.resume}`
          : `https://view.officeapps.live.com/op/view.aspx?src=${talent.resume}`
      }
      width="100%"
      height="100%"
    />
  );
};

export default Resume;
