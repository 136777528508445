import React from 'react';
import { Dialog, Divider, IconButton, TextField } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Title, TitleContainer } from './styles';

const InfoPopup = ({
  zIndex,
  content,
  buttons,
  $dialogMaxWidth,
  $dialogMaxHeight,
  $dialogMinWidth,
  open,
  onClosePopup,
  title,
  titleContainerPadding,
  titleContent,
  $dialogHeight
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClosePopup}
      sx={{ zIndex: zIndex }}
      PaperProps={{
        style: {
          borderRadius: '24px',
          maxWidth: $dialogMaxWidth,
          maxHeight: $dialogMaxHeight ? $dialogMaxHeight : 'calc(100% - 64px)',
          overflowY: 'hidden',
          minWidth: $dialogMinWidth ? $dialogMinWidth : 'auto',
          height: $dialogHeight ? $dialogHeight : 'auto'
        }
      }}>
      <div
        style={{
          background: '#fff'
        }}>
        {titleContent ? (
          titleContent
        ) : (
          <TitleContainer $titleContainerPadding={titleContainerPadding}>
            <Title>{title}</Title>
            <IconButton onClick={onClosePopup}>
              <CloseIcon />
            </IconButton>
          </TitleContainer>
        )}

        <Divider />

        {content}

        <Divider />

        {buttons}
      </div>
    </Dialog>
  );
};

export default InfoPopup;
