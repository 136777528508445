/*
 * Author: Kyle Hin
 * This is a customized Axios that allow http request cancellation and retry on failure
 * 1. In this customized axios, API call can be cancelled using cancel token after a certain amount of time (seconds)
 * 2. API call can be cancelled when component is unmounted as well to prevent unnecessary API call when user go in and out a specific screen
 * 3. canCancel (true/false) value could be provided to control whether this request need to be cancelled with 1.
 * 4. retryOption can be provided for retry behaviour
 * 	  a. retryOption = {retry_time, retry_status_code} //more condition can be added as one see fit
 * 5. TODO next - different handling for post request regarding cancel token behaviour -> post request will not be automatically cancelled when upload progress == 100%
 */
import axios from 'axios';
import moment from 'moment';
import store from '../../src/redux/stores/store';
import { fetchAPI } from '../axios/fetches';
import { appendCountryToUrl, useDecodeToken } from '../hooks/useLocalStorage';
import { getLocalStorageItem } from '../helpers/local_storage_utils';

const { REACT_APP_API_URL, REACT_APP_SG_API_URL, REACT_APP_READONLY_API_URL } =
  process.env;

const timeout = 15000; //15 seconds of connection timeout
// axios.defaults.timeout = 18000; //18 seconds response timeout
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// axios.defaults.headers.common["Access-Control-Allow-Credentials"] = "true";

// For Strapi CMS
const strapiDomain = process.env.REACT_APP_STRAPI_API_URL;
const strapiHeaders = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${process.env.REACT_APP_STRAPI_AUTH_TOKEN}`
};
// To avoid Global Axios instance for normal BE API call

/**
 *  Due to NodeJS V17 and above,
    SSR can't access http://localhost
    Need to use http://127.0.0.1 instead
    Refer to: https://forum.strapi.io/t/strapi-axios-request-on-ssr-fails-with-connect-econnrefused-but-csr-works-well/20799
 * @param {boolean} urlToResolveConnectionError
 */
export async function strapiCall(graphqlQuery) {
  try {
    const response = await axios.post(strapiDomain, graphqlQuery, {
      headers: strapiHeaders
    });

    return response;
  } catch (error) {
    return { status: 400, data: { error } };
  }
}

const apiCall = async (
  url,
  params = {},
  payload = '',
  usetoken = true,
  server = ''
) => {
  const domain = (() => {
    const country = getLocalStorageItem('country');

    switch (country) {
      case 'SG':
        return REACT_APP_SG_API_URL;
      case 'MY':
      default:
        // todo: remove readonly API once confirm with team
        if (server === 'read-only') return REACT_APP_READONLY_API_URL;

        return REACT_APP_API_URL;
    }
  })();

  return new Promise((resolve, reject) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    store.getState().axios.cancelTokens[params.cancelTokenKey] = source; // Assign cancel token source to axios redux state

    url = domain + url; // Full url with domain + sub url

    // Setting Timeout to cancel axios call after predefined seconds
    let mTimeout;
    if (params.canCancel) {
      const currentTimeout = params.apiTimeout ? params.apiTimeout : timeout;
      mTimeout = setTimeout(() => {
        cancelAxiosRequest(params.cancelTokenKey, 'connection timeout');
      }, currentTimeout);
    }

    if (usetoken) {
      const accessToken = localStorage.getItem('accessToken');
      // Need include authorization token
      setAuthorizationToken(
        accessToken ? accessToken : '' // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiMDVmYWJhYjEtMDVlZC00ODc4LWFhNDctMmNmOTFiMjgyZWVkIn0.gcsftbLe4RwIJltNbUT9krLNaDUd3BTxs6acskRFE7Y"
      );
    }

    if (!params.retryOption) {
      params['retryOption'] = { retry_time: 0 }; // default = no retry
    }

    postRequest(
      url,
      payload,
      store.getState().axios.cancelTokens[params.cancelTokenKey],
      params.cancelTokenKey
    ).then(response => {
      useDecodeToken();
      syncDropdownContry();
      appendCountryToUrl();
      clearingTimeOut(mTimeout);
      resolve(response);
    });
  });
};

/*
 * Use axios inteceptor to handle http request retry
 */
const retryWrapper = (cAxios, options) => {
  const max_time = options.retry_time;
  const retry_status_code = options.retry_status_code;
  let counter = 0;

  cAxios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (cAxios.isCancel(error)) {
        // Is cancelled mean connection timeout, don't retry at axios level, make thing complicated
        // shall retry at the component that triggering this API call
      }

      if (
        error &&
        error.response &&
        error.response.status == retry_status_code &&
        counter < max_time
      ) {
        counter++;
        const config = error.config;
        return new Promise(resolve => {
          resolve(cAxios(config));
        });
      }

      return Promise.reject(error);
    }
  );
};

const postRequest = (url, payload, cancelToken, cancelTokenKey) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, payload, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        },
        cancelToken: cancelToken.token,
        onUploadProgress: progressEvent => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
        onDownloadProgress: progressEvent => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        }
      })
      .then(response => {
        // Remove cancel token upon success
        if (store.getState().axios.cancelTokens[cancelTokenKey]) {
          delete store.getState().axios.cancelTokens[cancelTokenKey];
        }

        resolve(response);
      })
      .catch(thrown => {
        // Remove cancel token upon failure
        if (store.getState().axios.cancelTokens[cancelTokenKey]) {
          delete store.getState().axios.cancelTokens[cancelTokenKey];
        }

        if (axios.isCancel(thrown)) {
          resolve({ status: 400, success: false, isCancelled: true });
        } else {
          resolve({
            status: 400,
            isCancelled: false
          });
        }
      });
  });
};

const cancelAxiosRequest = (cancelTokenKey, from) => {
  if (store.getState().axios.cancelTokens[cancelTokenKey]) {
    try {
      store.getState().axios.cancelTokens[cancelTokenKey].cancel(from);
      delete store.getState().axios.cancelTokens[cancelTokenKey];
    } catch (error) {
      // Failed to trigger cancel token
    }
  }
};

const setAuthorizationToken = token => {
  if (token == '') {
    axios.defaults.headers.common['Authorization'] = ``;
  } else {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
};

const getAuthorizationToken = headers => {
  let token = null;
  if (headers && headers.Authorization) {
    if (typeof headers.Authorization == 'string') {
      let headerParams = headers.Authorization.split(' ');
      token = headerParams[1]; // This index varies with backend response
    }
  }

  return token;
};

const clearingTimeOut = timer => {
  if (timer) {
    clearTimeout(timer);
  }
};

const getLightCastToken = async () => {
  const url = 'https://auth.emsicloud.com/connect/token';
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded'
  };

  const postData = {
    client_id: `${process.env.REACT_APP_LIGHTCAST_CLIENT_ID}`,
    client_secret: `${process.env.REACT_APP_LIGHTCAST_CLIENT_SECRET}`,
    grant_type: 'client_credentials',
    scope: 'emsi_open'
  };

  const params = new URLSearchParams();
  for (var item in postData) {
    params.append(item, postData[item]);
  }

  const response = await fetchAPI('POST', url, params, headers);

  const lightCastToken = localStorage.getItem('lightcast-token');
  const lightCastTokenExpiry = localStorage.getItem('lightcast-token-timeout');

  if (!lightCastToken && !lightCastTokenExpiry) {
    if (response?.data?.access_token) {
      localStorage.setItem('lightcast-token', response.data.access_token);

      const timeOut = moment().add(
        parseInt(response.data.expires_in) - 60,
        'seconds'
      );

      localStorage.setItem('lightcast-token-timeout', timeOut);
    } else {
      localStorage.removeItem('lightcast-token');
      localStorage.removeItem('lightcast-token-timeout');
    }
  } else {
    if (moment(lightCastTokenExpiry).isBefore(moment())) {
      localStorage.removeItem('lightcast-token');
      localStorage.removeItem('lightcast-token-timeout');
      getLightCastToken();
    }
  }
};

// to rerender UI
const syncDropdownContry = () => {
  const country = getLocalStorageItem('country');

  if (country != null && country != store.getState().companies.country) {
    store.getState().companies.country = country;
  }
};

export default {
  apiCall,
  cancelAxiosRequest,
  getLightCastToken
};
