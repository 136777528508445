import React, { Fragment, useState } from 'react';
import {
  TitleStyled,
  RadioGroupStyled,
  FormControlLabelStyled,
  RadioStyled,
  FormControlStyled,
  OuterWrapper
} from './styles';
import { useSelector, useDispatch } from 'react-redux';
import { updateUnlockedTalentsFilters } from '../../../../redux/actions/talent_action';

export const SortFilter = () => {
  const dispatch = useDispatch();

  const unlockedTalentFilters = useSelector(
    state => state.talents.unlockedTalentFilters
  );

  const getDirection = order => {
    let cOrder = order.toLowerCase();

    if (cOrder == 'newest first') {
      cOrder = 'desc';
    } else if (cOrder == 'oldest first') {
      cOrder = 'asc';
    }

    return cOrder;
  };

  const handleChange = event => {
    if (!unlockedTalentFilters) return;

    let talentsFilter = { ...unlockedTalentFilters };

    if (talentsFilter?.sort?.direction) {
      talentsFilter.sort.direction = getDirection(event.target.value);
      dispatch(updateUnlockedTalentsFilters(talentsFilter));
    }
  };

  const isChecked = value => {
    if (unlockedTalentFilters?.sort?.direction == value) return true;

    return false;
  };

  return (
    <Fragment>
      <OuterWrapper>
        <TitleStyled>Sort by</TitleStyled>

        <FormControlStyled>
          <RadioGroupStyled
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="Newest first"
            name="radio-buttons-group"
            onChange={handleChange}>
            <FormControlLabelStyled
              checked={isChecked('desc')}
              value="Newest first"
              control={<RadioStyled />}
              label="Newest first"
              sx={{
                '& .MuiTypography-root': {
                  fontSize: 14,
                  letterSpacing: '0.1px'
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 20
                }
              }}
            />
            <FormControlLabelStyled
              checked={isChecked('asc')}
              value="Oldest first"
              control={<RadioStyled />}
              label="Oldest first"
              sx={{
                '& .MuiTypography-root': {
                  fontSize: 14,
                  letterSpacing: '0.1px'
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 20
                }
              }}
            />
          </RadioGroupStyled>
        </FormControlStyled>
      </OuterWrapper>
    </Fragment>
  );
};
