import { styled as styling } from '@mui/material/styles';

import { Button, Paper } from '@mui/material';

export const PaperStyled = styling(Paper)({
  boxShadow: '0px 28px 23px rgba(0, 0, 0, 0.05)',
  borderRadius: '13px',
  background: '#fff',
  padding: '0 20px'
});

export const StatusText = styling('div')({
  fontSize: '14px',
  fontWeight: '400',
  fontFamily: 'Inter'
});

export const ButtonStyled = styling(Button)(({ type }) => ({
  border: 'none',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
  alignSelf: 'center',
  fontWeight: 700,
  fontSize: '12px',
  fontFamily: 'Inter',
  padding: type == 'dropdown' ? '4px 8px' : '5.5px 10px 5.5px 20px',
  color: type == 'disabled' ? 'rgba(0, 0, 0, 0.26)' : 'white',
  background: type == 'disabled' ? 'rgba(0, 0, 0, 0.12)' : 'rgb(211, 85, 91)',
  borderRadius:
    type == 'basic'
      ? '30px 0 0 30px'
      : type == 'dropdown'
      ? '0 30px 30px 0'
      : type == 'disabled'
      ? '30px 0 0 30px'
      : '30px',

  ':hover': {
    background: type == 'disabled' ? 'rgba(0, 0, 0, 0.12)' : 'rgb(211, 85, 91)'
  }
}));
