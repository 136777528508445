import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import HiredlyLogo from '../../assets/common/hiredly-logo-copyright.png';
import HiredlyLogoSG from '../../assets/common/hiredly-logo-sg.png';
import { Spinner } from '../../components';
import { MUIButtonStyled } from '../../components/MUIButton';
import Message from '../../components/Message';
import SharedSelectCountry from '../../components/SharedSelectCountry';
import {
  countryCodeOptions,
  countryOptions
} from '../../components/SharedSelectCountry/constants';
import DefaultLayout from '../../layouts/DefaultLayout';
import {
  fetchMyCompanyStatus,
  mutateEmployerLogin,
  updateCountryDropdown
} from '../../redux/actions/company_actions';
import store from '../../redux/stores/store';
import { ErrorToast } from '../../utils/ToastUtils';
import useSearchParams from '../../utils/useSearchParams';
import {
  ButtonContainer,
  CardContainer,
  Container,
  CreateAccContainer,
  ImageBanner,
  ImageLeft,
  Logo,
  PurpleText,
  Row,
  RowLabel,
  TitleText,
  WelcomeText
} from './styles';
import { getCountry } from '../../hooks/useRegionalisation';
import { setUserToDatadog } from '../../hooks/useDatadog';
import SharedTextField from '../../components/SharedTextField';
import { Grid, Typography } from '@mui/material';

const SgBanner = 'https://assets.wobbjobs.com/images/LoginBanner/asset_bg.png';

export default function SignIn() {
  const location = useLocation();
  const history = useNavigate();
  const params = useSearchParams();
  const isPasswordChanged = params.get('passwordChanged') === 'true';

  // regionalisation
  const query = new URLSearchParams(location.search);
  const queryCountry = query.get('country')?.toUpperCase();
  const queryFromJSW = query.get('origin');
  const dropdownCountry = useSelector(state => state.companies.country);
  // end

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLocation, setIsLocation] = useState('');
  const [isLoadingCountry, setIsLoadingCountry] = useState(false);
  const [errors, setErrors] = useState({});

  const mutatingEmployerLogin = useSelector(
    state => state.companies.mutatingEmployerLogin
  );

  const validationSchema = yup.object().shape({
    email: yup.string().required('Email is required'),
    password: yup.string().required('Password is required')
  });

  const [values, setValues] = useState({
    email: '',
    password: '',
    country: ''
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: '' });
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      await validationSchema.validate(values, { abortEarly: false });
      onLogin(values);
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach(err => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    }
  };

  const onRedirection = async () => {
    dispatch(fetchMyCompanyStatus()).then(res => {
      if (res?.company?.hasCompletedOrdersOrPostedJobs) {
        window?.location?.replace('/jobs');
      } else {
        window?.location?.replace('/welcome');
      }
    });

    if (store.getState().products.confirmOrderPath != '') {
      const nextRedirectPath = store.getState().products.confirmOrderPath;
      store.getState().products.confirmOrderPath = '';

      window?.location?.replace(nextRedirectPath);
    } else if (store.getState().applications.viewAllApllicantsPath) {
      const nextRedirectPath =
        store.getState().applications.viewAllApllicantsPath;
      store.getState().applications.viewAllApllicantsPath = '';

      window?.location?.replace(nextRedirectPath);
    }

    setIsLoading(false);
  };

  const analyticLogin = (companyId, country) => {
    document.cookie = `companyId=${companyId}; path=/; max-age=31540000`;
    document.cookie = `gtmCountry=${country}; path=/; max-age=31540000`;

    try {
      if (window.cleverTap) {
        window?.dataLayer?.push({
          event: 'login'
        });
      } else {
      }
    } catch {}
  };

  const onLogin = async values => {
    setIsLoading(true);

    try {
      const res = await dispatch(
        mutateEmployerLogin({
          input: {
            email: values?.email,
            password: values?.password
          }
        })
      );

      if (res?.error && res.error.length > 0) {
        res.error.forEach(error => {
          ErrorToast(error?.message);
        });
        setIsLoading(false);
        return;
      }

      const loginData = res?.loginEmployer;

      if (loginData) {
        const companyId = res?.loginEmployer?.user?.companyId;
        const email = res?.loginEmployer?.user?.email;
        const companyName = res?.loginEmployer?.user?.companyName;
        const currentCountry = localStorage.getItem('country');
        analyticLogin(companyId, currentCountry);
        if (process.env.REACT_APP_DATADOG_ENV !== 'LOCAL') {
          setUserToDatadog(companyId, email, companyName);
        }
      }

      const accessToken = loginData?.token;
      const currentAccountType = loginData.user?.currentAccountType;
      const accountId = loginData?.user?.id;
      const email = loginData?.user?.email;
      const companyId = loginData.user?.companyId;
      const companyName = loginData.user?.companyName;

      localStorage.setItem('accessToken', accessToken);

      // Info needed for chat
      const accountInfo = {
        ...(accountId && { accountId }),
        ...(companyId && { companyId }),
        ...(companyName && { companyName }),
        ...(email && { email }),
        ...(currentAccountType && { currentAccountType })
      };

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('accountInfo', JSON.stringify(accountInfo));
      localStorage.setItem('currentAccountType', currentAccountType);

      await onRedirection();
    } catch (err) {
      // Handle other types of errors if needed
      localStorage.removeItem('accessToken');
      localStorage.removeItem('currentAccountType');
      setIsLoading(false);
      ErrorToast(err.message);
    }
  };

  const renderMessage = () => {
    if (isPasswordChanged) {
      return (
        <Message width={'460px'}>
          Your password has been changed successfully.
        </Message>
      );
    }

    return <WelcomeText>Welcome Back</WelcomeText>;
  };

  const renderBanner = () => {
    if (isLocation == 'SG') {
      return (
        <ImageBanner onClick={() => history('/waitlist')} background={SgBanner}>
          <ImageLeft>
            <div
              style={{
                width: '140px',
                height: '140px',
                borderRadius: '50%',
                boxShadow: 'rgb(250, 143, 255, 0.3) 0px 0px 8px 5px',
                '-webkit-box-shadow': 'rgb(250, 143, 255, 0.4) 0px 0px 8px 5px',
                '-moz-box-shadow': 'rgb(250, 143, 255, 0.4) 0px 0px 8px 5px',
                position: 'absolute'
              }}></div>
            <div style={{ position: 'absolute' }}>
              <img
                src="https://assets.wobbjobs.com/images/LoginBanner/asset_1.png"
                alt="banner-img"
                style={{ width: '70px', height: '100%' }}></img>
              <img
                src="https://assets.wobbjobs.com/images/LoginBanner/asset_2.png"
                alt="banner-img"
                style={{
                  width: '400px',
                  height: '100%',
                  marginTop: '-50px'
                }}></img>
              <img
                src="https://assets.wobbjobs.com/images/LoginBanner/asset_3.png"
                alt="banner-img"
                style={{
                  width: '185px',
                  height: '100%',
                  marginTop: '-85px'
                }}></img>
            </div>
          </ImageLeft>

          <ImageLeft>
            <img
              src="https://assets.wobbjobs.com/images/LoginBanner/asset_4.png"
              alt="banner-img"
              style={{ width: '320px', height: '100%' }}></img>
            <img
              src="https://assets.wobbjobs.com/images/LoginBanner/asset_5.png"
              alt="banner-img"
              style={{
                width: '350px',
                height: '100%',
                marginTop: '-25px'
              }}></img>
            <img
              src="https://assets.wobbjobs.com/images/LoginBanner/asset_6.png"
              alt="banner-img"
              style={{
                width: '60px',
                height: '60px',
                position: 'absolute',
                right: '-8px',
                top: '3px'
              }}></img>
          </ImageLeft>
        </ImageBanner>
      );
    }
  };

  const handleClick = async () => {
    if (getCountry() == 'SG') {
      window.open('https://hub.hiredly.com/hiredly-sg-employer-waitlist');
    } else {
      history('/register/login-info');
    }
  };

  const checkIfArrayContains = (arr, value) => {
    return arr.includes(value);
  };

  const fetchData = async () => {
    try {
      setIsLoadingCountry(true);
      const geolocationApi = await fetch(
        'https://geolocation-api-test.hiredly-wobb-cloudflare-account.workers.dev/'
      );
      const dataCountry = await geolocationApi.json();

      let cCountry = checkIfArrayContains(countryOptions, dataCountry?.country)
        ? dataCountry?.country
        : 'MY';

      changeCountry(cCountry);

      setIsLocation(dataCountry?.country);
      setIsLoadingCountry(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const buttonText =
    getCountry() == 'SG' ? 'Register interest' : 'Create an account now';

  useEffect(() => {
    // use cloudfare country if not coming from jsw web
    if (queryFromJSW != 'jobseeker') {
      fetchData();
      return;
    }

    // use country from link if coming from jsw web
    if (queryFromJSW == 'jobseeker') {
      changeCountry(queryCountry);
      return;
    }
  }, []);

  const changeCountry = queryCountry => {
    dispatch(updateCountryDropdown(queryCountry));
    localStorage.setItem('country', queryCountry);
  };

  if (isLoadingCountry) return <Spinner />;

  return (
    <DefaultLayout>
      {renderBanner()}
      <form onSubmit={handleSubmit}>
        <Container>
          <Logo
            src={
              countryCodeOptions[dropdownCountry] === 'SG'
                ? HiredlyLogoSG
                : HiredlyLogo
            }
            alt="logo"
          />
          {renderMessage()}
          <CardContainer>
            <div style={{ width: '100%' }}>
              <TitleText>
                Sign in with your email address and password.
              </TitleText>

              <Row>
                <RowLabel>Country</RowLabel>
                <SharedSelectCountry
                  name="country"
                  value={values.country}
                  onChange={handleChange}
                />
              </Row>

              <Row>
                <RowLabel>Email</RowLabel>
                <SharedTextField
                  autoFocus
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                  error={errors.email}
                  helperText={errors.email}
                />
              </Row>

              <Row>
                <RowLabel>Password</RowLabel>
                <SharedTextField
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={values.password}
                  onChange={handleChange}
                  error={errors.password}
                  helperText={errors.password}
                />
              </Row>
            </div>

            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '16px 0px'
              }}>
              <MUIButtonStyled
                large
                label="Sign In"
                type="submit"
                disabled={mutatingEmployerLogin || isLoading}
              />

              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
                style={{ cursor: 'pointer' }}
                onClick={() => history('/forgot-password')}>
                <Typography
                  sx={{ fontSize: '12px', textDecoration: 'underline' }}>
                  Forgot Password?
                </Typography>
              </div>
            </Grid>

            <CreateAccContainer>
              <p style={{ margin: 0 }}>Don't have an employer account?</p>
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
                style={{ cursor: 'pointer', marginLeft: '10px' }}
                onClick={handleClick}>
                <PurpleText $noMargin>{buttonText}</PurpleText>
              </div>
            </CreateAccContainer>
          </CardContainer>
        </Container>
      </form>
    </DefaultLayout>
  );
}
