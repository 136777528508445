import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { updateProductTypeIndex } from '../../../../../../redux/actions/job_action';
import { Container, TextContainer, Text } from './styles';

const TypePopover = ({ applyFilter, types }) => {
  const dispatch = useDispatch();

  const productTypeIndex = useSelector(state => state.jobs.productTypeIndex);

  const remainingUsableJobSlots = useSelector(
    state => state.jobs.subscribedJobSlotsPackage?.total_usable_quantity
  );

  const remainingUsableJobSlotPlus = useSelector(
    state => state.jobs.subscribedJobSlotPlusPackage?.total_usable_quantity
  );

  const remainingUsableJobSlotMax = useSelector(
    state => state.jobs.subscribedJobSlotMaxPackage?.total_usable_quantity
  );

  const remainingUsableJobSlot6m = useSelector(
    state => state.jobs.subscribedJobSlot6mPackage?.total_usable_quantity
  );

  const remainingUsableJobSlotPlus6m = useSelector(
    state => state.jobs.subscribedJobSlotPlus6mPackage?.total_usable_quantity
  );

  const remainingUsableJobSlotMax6m = useSelector(
    state => state.jobs.subscribedJobSlotMax6mPackage?.total_usable_quantity
  );

  const featuredTokensUsableCount = useSelector(
    state => state.jobs.subscribedFeaturedTokensPackage?.total_usable_quantity
  );
  const jobTokensUsableCount = useSelector(
    state => state.jobs.subscribedJobTokensPackage?.total_usable_quantity
  );
  const refresherTokensUsableCount = useSelector(
    state => state.jobs.subscribedRefresherTokensPackage?.total_usable_quantity
  );
  const freeInternshipTokensUsableCount = useSelector(
    state =>
      state.jobs.subscribedFreeInternshipTokensPackage?.total_usable_quantity
  );
  const freeSuperSeniorTokensCount = useSelector(
    state =>
      state.jobs.subscribedFreeSuperSeniorTokensPackage?.total_usable_quantity
  );
  const jobCreditUsableCount = useSelector(
    state => state.jobs.subscribedJobCreditsPackage?.total_usable_quantity
  );
  const crossBorderTokensUsableCount = useSelector(
    state =>
      state.jobs.subscribedCrossBorderTokensPackage?.total_usable_quantity
  );
  const spotlightJobsUsableCount = useSelector(
    state => state.jobs.subscribedSpotlightTokensPackage?.total_usable_quantity
  );
  const keyHighlightsUsableCount = useSelector(
    state =>
      state.jobs.subscribedKeywordHighlightTokensPackage?.total_usable_quantity
  );

  const onClickType = item => {
    dispatch(updateProductTypeIndex(item)).then(res => {
      applyFilter();
    });
  };

  const getProductTitle = item => {
    let cTitle = item?.toLowerCase();

    switch (cTitle) {
      case 'all types':
        return 'All Types';
      case 'job slots':
        return `Job Slots (${remainingUsableJobSlots})`;
      case 'job slots 6 months':
        return `Job Slots 6 Months (${remainingUsableJobSlot6m})`;
      case 'job slots plus':
        return `Job Slot Plus (${remainingUsableJobSlotPlus})`;
      case 'job slots + 6 months':
        return `Job Slot Plus 6 Months (${remainingUsableJobSlotPlus6m})`;
      case 'job slots max':
        return `Job Slot Max (${remainingUsableJobSlotMax})`;
      case 'job slots max 6 months':
        return `Job Slot Max 6 Months (${remainingUsableJobSlotMax6m})`;
      case 'featured tokens':
        return `Featured Tokens (${featuredTokensUsableCount})`;
      case 'refresher tokens':
        return `Refresher Tokens (${refresherTokensUsableCount})`;
      case 'job tokens':
        return `Job Tokens (${jobTokensUsableCount})`;
      case 'internship tokens':
        return `Internship Tokens (${freeInternshipTokensUsableCount})`;
      case 'free senior posts':
        return `Free Senior Posts (${freeSuperSeniorTokensCount})`;
      case 'job credits':
        return `Job Credits (${jobCreditUsableCount})`;
      case 'cross border tokens':
        return `Cross Border Tokens (${crossBorderTokensUsableCount})`;
      case 'spotlight jobs':
        return `Spotlight Jobs (${spotlightJobsUsableCount})`;
      case 'key highlights tokens':
        return `Key Highlights Tokens (${keyHighlightsUsableCount})`;
    }
  };

  const getProductTypes = () => {
    if (remainingUsableJobSlot6m <= 0) {
      let index = types.indexOf('Job Slots 6 Months');
      if (index > -1) {
        types.splice(index, 1);
      }
    }

    if (remainingUsableJobSlotPlus6m <= 0) {
      let index = types.indexOf('Job Slots + 6 Months');
      if (index > -1) {
        types.splice(index, 1);
      }
    }

    if (remainingUsableJobSlotMax6m <= 0) {
      let index = types.indexOf('Job Slots Max 6 Months');
      if (index > -1) {
        types.splice(index, 1);
      }
    }

    if (featuredTokensUsableCount <= 0) {
      let index = types.indexOf('Featured Tokens');
      if (index > -1) {
        types.splice(index, 1);
      }
    }

    if (refresherTokensUsableCount <= 0) {
      let index = types.indexOf('Refresher Tokens');
      if (index > -1) {
        types.splice(index, 1);
      }
    }

    if (crossBorderTokensUsableCount <= 0) {
      let index = types.indexOf('Cross Border Tokens');
      if (index > -1) {
        types.splice(index, 1);
      }
    }

    if (jobCreditUsableCount <= 0) {
      let index = types.indexOf('Job Credits');
      if (index > -1) {
        types.splice(index, 1);
      }
    }

    if (jobTokensUsableCount <= 0) {
      let index = types.indexOf('Job Tokens');
      if (index > -1) {
        types.splice(index, 1);
      }
    }

    if (
      freeInternshipTokensUsableCount <= 0 &&
      freeInternshipTokensUsableCount != 'Unlimited'
    ) {
      let index = types.indexOf('Internship Tokens');
      if (index > -1) {
        types.splice(index, 1);
      }
    }

    if (freeSuperSeniorTokensCount <= 0) {
      let index = types.indexOf('Free Senior Posts');
      if (index > -1) {
        types.splice(index, 1);
      }
    }

    if (spotlightJobsUsableCount <= 0) {
      let index = types.indexOf('Spotlight Jobs');
      if (index > -1) {
        types.splice(index, 1);
      }
    }

    if (keyHighlightsUsableCount <= 0) {
      let index = types.indexOf('Key Highlights Tokens');
      if (index > -1) {
        types.splice(index, 1);
      }
    }

    return types;
  };

  return (
    <Container>
      {getProductTypes().map((item, index) => {
        return (
          <TextContainer
            highlight={`${
              productTypeIndex.toLowerCase() == item.toLowerCase()
            }`}
            onClick={() => onClickType(item)}>
            <Text>{getProductTitle(item)}</Text>
          </TextContainer>
        );
      })}
    </Container>
  );
};

TypePopover.propTypes = {
  applyFilter: PropTypes.func,
  types: PropTypes.array
};

TypePopover.defaultProps = {
  applyFilter: () => {},
  types: []
};

export default TypePopover;
