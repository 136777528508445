import React from 'react';
import {
  RequestContainer,
  RequestCard,
  SpinnerContainer,
  ButtonContainer,
  Button
} from './styles';
import { Spinner } from '../../../../../components';

const AffiliationRequest = props => {
  const { company, updateAffiliationStatus, updatingCompanyAffiliation } =
    props;

  const getRequestCompanyName = company => {
    if (company?.affiliatedCompany?.company) {
      return company.affiliatedCompany.company.name;
    }

    return '';
  };

  const getAffiliationType = company => {
    if (!company) return '';
    if (!company?.affiliatedCompany) return '';

    return company.affiliatedCompany.affiliationType;
  };

  const getAffiliatedCompanyId = company => {
    if (company?.affiliatedCompany?.company) {
      return company.affiliatedCompany.company.id;
    }

    return '';
  };

  const getDescription = company => {
    if (company?.affiliatedCompany?.approvalStatus == 'approved') {
      return `Your parent company: ${getRequestCompanyName(company)}`;
    }

    return `${getRequestCompanyName(
      company
    )} would like to list you as their ${getAffiliationType(
      company
    )}. Would you like to approve this affiliation?`;
  };

  const renderRequestCard = company => {
    return (
      company?.affiliatedCompany &&
      (company?.affiliatedCompany.approvalStatus == 'pending' ||
        company?.affiliatedCompany.approvalStatus == 'approved')
    );
  };

  const renderButtons = company => {
    if (company?.affiliatedCompany) {
      return company?.affiliatedCompany.approvalStatus == 'pending';
    }

    return false;
  };

  // Actions
  const onButtonClicked = (company, status) => () => {
    const affiliatedCompanyId = getAffiliatedCompanyId(company);
    updateAffiliationStatus(affiliatedCompanyId, status);
  };

  return renderRequestCard(company) ? (
    <RequestContainer>
      <RequestCard>
        {getDescription(company)}
        {renderButtons(company) && (
          <ButtonContainer>
            {updatingCompanyAffiliation ? (
              <SpinnerContainer>
                <Spinner size="TINY" noPadding={true} />
              </SpinnerContainer>
            ) : (
              <>
                <Button onClick={onButtonClicked(company, 'rejected')}>
                  Reject
                </Button>
                <Button onClick={onButtonClicked(company, 'approved')}>
                  Approve
                </Button>
              </>
            )}
          </ButtonContainer>
        )}
      </RequestCard>
    </RequestContainer>
  ) : null;
};

export default AffiliationRequest;
