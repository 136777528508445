import { faClock, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { TextFieldStyled } from '../styles';
import { useFormContext } from '../../context';
import styles from './../../index.module.scss';

export default function Ats() {
  const { formState, handleFieldChange } = useFormContext();

  

  return (
    <div style={{ margin: '70px 0 20px' }}>
      <div style={{ display: 'flex' }}>
        <h6
          style={{
            fontSize: '20px',
            fontWeight: '700',
            fontFamily: 'Inter'
          }}
          className={styles.notificationTitle}>
          ATS Integration Link (Optional)
        </h6>
      </div>

      <div style={{ margin: '20px 0 0' }}>
        <TextFieldStyled
          value={formState?.externalJobUrl}
          name="externalJobUrl"
          placeholder="This field is optional"
          onChange={handleFieldChange}
        />
      </div>
    </div>
  );
}
