import {
  Avatar,
  Card,
  Button as CustomButton,
  Grid,
  Skeleton
} from '@mui/material';
import { saveAs } from 'file-saver';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../../../../src/redux/types/application_type';
import CloseLoop from '../../../../components/CandidateSnapshot/CloseLoop';
import FloatingToolbar from '../../../../components/FloatingToolbar/index';
import StatusDropdown from '../../../../components/StatusDropdown/index';
import { closingLoop } from '../../../../redux/actions/application_action';
import { fetchCompanyName } from '../../../../redux/actions/company_actions';
import { mutateJobApplicationState } from '../../../../redux/actions/job_action';
import { JOB_APPLICATION_STATUS } from '../../../../utils/Constants';
import {
  ErrorToast,
  SuccessToast,
  WarningToast
} from '../../../../utils/ToastUtils';
import CloseLoopPopUp from '../../../Jobs/PopUps/CloseLoopPopUp';
import OfferJobPopUp from '../../../Jobs/PopUps/OfferJobPopUp';
import BlackListPopUp from './BlackListPopUp';
import CandidateAiviSummary from './CandidateAiviSummary';
import CandidateApplicationSummary from './CandidateApplicationSummary';
import SkeletonLoader from './CandidateChat/SkeletonLoader';
import CandidateChat from './CandidateChat/index';
import CandidateInfoCard from './CandidateInfoCard';
import CandidateResume from './CandidateResume';
import styles from './index.module.scss';
import { accData } from '../../../../utils/LocalStorageUtils';
import { downloadResume } from '../../../../redux/actions/talent_action';
import { convertAndDownloadResume } from '../../../../hooks/useDownload';

let accountInfo = localStorage.getItem('accountInfo');
accountInfo = accountInfo ? JSON.parse(accountInfo) : accountInfo;

let fetchingData = false;

const CandidateInformation = forwardRef(
  (
    {
      candidate,
      item, //either application or interview
      candidates,
      currentCandidates,
      setCandidates,
      setCurrentCandidates,
      getCurrentCandidates,
      isSelected,
      setIsSelected,
      loading,
      applicationStatus,
      jobData,
      setJobData,
      scrollToTop,
      scrollToSection,
      getJobApplicants,
      pageInfo,
      scrollY,
      direction,
      handleScrollToCard,
      onApplicationClicked,
      updateListJobApplicationStatus,
      updateInterviewStatus,
      updatingInterviewStatus,
      onViewApplication
    },
    ref
  ) => {
    const heightRef = useRef(null);
    const [applicationState, setApplicationState] = useState(candidate?.state);
    const [isBlacklistOpen, setIsBlacklistOpen] = useState(false);

    const isCandidatesEmpty = candidates?.length === 0;
    const isRenderSelected =
      candidates[isSelected]?.node?.id === candidate?.id ||
      candidates[isSelected]?.node?.jobApplication?.id === candidate?.id;

    const [tabValue, setTabValue] = useState(0);

    const chipButtons = ['Profile', 'Resume', 'Chat'];
    const [selectedChip, setSelectedChip] = useState(0);
    const [isOfferJobOpen, setIsOfferJobOpen] = useState(false);

    const [loopError, setLoopError] = useState(false);

    const isClosingLoop = useSelector(state => state.applications.closingLoop);

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(fetchCompanyName());
    }, []);

    const myCompany = useSelector(state => state.companies.myCompany);
    const mutatingJobApplication = useSelector(
      state => state.jobs.mutatingJobApplication
    );

    const handleOfferJobClose = () => {
      setIsOfferJobOpen(false);
    };

    const changeInterviewStatus = (interview, status, remark) => {
      const updateInterviewParams = {
        id: interview.id,
        status: status,
        remark: remark,
        isInterview: true
      };

      updateInterviewStatus(updateInterviewParams).then(res => {
        if (res.type === types.UPDATE_INTERVIEW_STATUS_SUCCEED) {
          SuccessToast(
            `Successfully ${
              status === 'offered'
                ? 'offered this candidate a position.'
                : 'rejected this candidate.'
            }`
          );

          if (setCandidates && candidates) {
            const index = candidates.findIndex(
              item => item.node.id === res.interview.id
            );

            if (index != -1) {
              let currentCandidates = [...candidates];

              currentCandidates.splice(index, 1);
              setCandidates(currentCandidates);
              setCurrentCandidates(currentCandidates);

              if (setIsSelected) {
                setIsSelected(null);
              }
            }
          }
        } else {
          ErrorToast(
            `Failed to ${
              status === 'offered'
                ? 'offer a position to this candidate'
                : 'reject this candidate'
            } at the moment, please try again later.`
          );
        }
        handleOfferJobClose();
      });
    };

    const rejectedStatusMessage = () => {
      return (
        <div>
          <p
            style={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 'bold',
              margin: '4px 0'
            }}>
            User Rejected
          </p>
          <p style={{ margin: '0', fontFamily: 'Inter', fontSize: '12px' }}>
            You have successfully rejected this user.
          </p>
        </div>
      );
    };

    const onChangeJobApplicationStatus = async ({
      value,
      reason,
      rejectReason
    }) => {
      window.dataLayer.push({
        event: 'CE_click-change-status-ats',
        status: value ?? null
      });

      dispatch(
        mutateJobApplicationState({
          input: {
            id: candidate?.id,
            state: value,
            ...(rejectReason
              ? { rejectReason: rejectReason }
              : reason
              ? { blacklistReason: reason }
              : null)
          }
        })
      )
        .then(res => {
          if (res.type === 'MUTATE_JOB_APPLICATION_STATE_SUCCEED') {
            setApplicationState(res?.jobApplication?.state);

            const index = candidates.findIndex(
              item => item?.node?.id === candidate?.id
            );

            if (value !== JOB_APPLICATION_STATUS.BLACKLISTED) {
              const cJobData = getUpdatedApplicantCount(
                candidate.state,
                value,
                jobData
              );
              setJobData(cJobData);
            }

            if (
              value === JOB_APPLICATION_STATUS.BLACKLISTED ||
              applicationStatus !== 'all'
            ) {
              let removedArray = [];
              if (applicationStatus == 'interview') {
                removedArray = [...candidates].filter(value => {
                  return value?.node?.id !== item?.id;
                });
              } else {
                // Remove blacklisted candidate from 'candidates' state
                // Remove candidate from listing if state change when applicationStatus !== 'all'
                removedArray = [...candidates].filter(item => {
                  return item?.node?.id !== candidate?.id;
                });
              }
              setCandidates(removedArray);
              setCurrentCandidates(removedArray);
            } else {
              // Edit current 'candidates' state
              const tmpArray = [...candidates];
              tmpArray[index] = {
                ...tmpArray[index],
                node: {
                  ...tmpArray[index]?.node,
                  state: res?.jobApplication?.state
                }
              };
              setCandidates(tmpArray);
              setCurrentCandidates(tmpArray);
              updateListJobApplicationStatus(tmpArray, candidate?.id);
            }

            SuccessToast('Changed Job Application Status Successful!');
          }
        })
        .catch(err => {
          ErrorToast(err.message);
        });

      // try {
      //   const res = await changeJobApplicationStatus({
      //     variables: {
      //       input: {
      //         id: candidate?.id,
      //         state: value,
      //         ...(rejectReason
      //           ? { rejectReason: rejectReason }
      //           : reason
      //           ? { blacklistReason: reason }
      //           : null)
      //       }
      //     }
      //   });

      //   setApplicationState(
      //     res?.data?.changeJobApplicationState?.jobApplication?.state
      //   );

      //   const index = candidates.findIndex(
      //     item => item?.node?.id === candidate?.id
      //   );

      //   if (value !== JOB_APPLICATION_STATUS.BLACKLISTED) {
      //     const cJobData = getUpdatedApplicantCount(
      //       candidate.state,
      //       value,
      //       jobData
      //     );
      //     setJobData(cJobData);
      //   }

      //   if (
      //     value === JOB_APPLICATION_STATUS.BLACKLISTED ||
      //     applicationStatus !== 'all'
      //   ) {
      //     let removedArray = [];
      //     if (applicationStatus == 'interview') {
      //       removedArray = [...candidates].filter(value => {
      //         return value?.node?.id !== item?.id;
      //       });
      //     } else {
      //       // Remove blacklisted candidate from 'candidates' state
      //       // Remove candidate from listing if state change when applicationStatus !== 'all'
      //       removedArray = [...candidates].filter(item => {
      //         return item?.node?.id !== candidate?.id;
      //       });
      //     }
      //     setCandidates(removedArray);
      //     setCurrentCandidates(removedArray);
      //   } else {
      //     // Edit current 'candidates' state
      //     const tmpArray = [...candidates];
      //     tmpArray[index] = {
      //       ...tmpArray[index],
      //       node: {
      //         ...tmpArray[index]?.node,
      //         state: res?.data?.changeJobApplicationState?.jobApplication?.state
      //       }
      //     };
      //     setCandidates(tmpArray);
      //     setCurrentCandidates(tmpArray);
      //     updateListJobApplicationStatus(tmpArray, candidate?.id);
      //   }

      //   SuccessToast('Changed Job Application Status Successful!');
      // } catch (err) {
      //   ErrorToast(err.message);
      // }
    };

    const updateCandidateFromList = cCandidate => {
      const index = candidates.findIndex(
        item => item?.node?.id === cCandidate?.id
      );

      if (index !== -1) {
        const cCandidates = [...candidates];
        cCandidates[index] = {
          ...cCandidates[index],
          node: {
            ...cCandidates[index]?.node,
            sendbirdChannelUrl: cCandidate.sendbirdChannelUrl
          }
        };

        setCandidates(cCandidates);
        setCurrentCandidates(cCandidates);
      }
    };

    const saveFile = () => {
      window.dataLayer.push({
        event: 'CE_download_resume_applicants',
        first_time: 'yes',
        tab: applicationStatus == 'all' ? 'applicants' : applicationStatus,
        candidate_id: candidate.user.id,
        company_name: accountInfo?.companyName,
        company_industry: accountInfo?.industryName
      });

      const params = {
        userId: candidate.user?.id
      };

      dispatch(downloadResume(params)).then(res => {
        convertAndDownloadResume(res, candidate?.user);
      });
    };

    const getUpdatedApplicantCount = (currentState, newState, jobData) => {
      let cJobData = { ...jobData };

      switch (currentState) {
        case 'undecided':
          {
            cJobData.undecidedCount -= 1;
          }
          break;
        case 'shortlisted':
          {
            cJobData.shortlistedCount -= 1;
          }
          break;
        case 'kiv':
          {
            cJobData.kivCount -= 1;
          }
          break;
        case 'rejected':
          {
            cJobData.rejectedCount -= 1;
          }
          break;
      }

      switch (newState) {
        case 'undecided':
          {
            cJobData.undecidedCount += 1;
          }
          break;
        case 'shortlisted':
          {
            cJobData.shortlistedCount += 1;
          }
          break;
        case 'kiv':
          {
            cJobData.kivCount += 1;
          }
          break;
        case 'rejected':
          {
            cJobData.rejectedCount += 1;
          }
          break;
      }

      return cJobData;
    };

    useEffect(() => {
      setApplicationState(candidate?.state);
      if (!candidate?.state) {
        setApplicationState('undecided');
      }
    }, [candidate, isSelected]);

    const handlePreviousIcon = () => {
      if (isSelected <= 0) {
        return;
      } else {
        handleScrollToCard(isSelected, true);
        setIsSelected(isSelected - 1);
        scrollToTop();
        viewApplicantTracking(candidates, isSelected - 1);
      }

      const eventName = 'list_previous_applicant';

      dataLayer.push({
        event: eventName
      });
    };

    useEffect(() => {
      let lastCandidateCard = isSelected == candidates?.length - 1;
      if (lastCandidateCard) {
        if (pageInfo?.hasNextPage == false && !fetchingData) {
          WarningToast('You have reached the last candidate!');
        }
      }
    }, [isSelected == candidates?.length - 1]);

    const viewApplicantTracking = (candidates, isSelected) => {
      const currentApplication = candidates[isSelected]?.node?.jobApplication
        ? candidates[isSelected]?.node?.jobApplication
        : candidates[isSelected]?.node;

      if (currentApplication) {
        const jobId = currentApplication?.job?.id;
        const userId = currentApplication?.user?.id;

        try {
          switch (applicationStatus) {
            case 'all':
              window.dataLayer.push({
                event: 'view-applicant-list-all',
                job_id: jobId,
                user_id: userId
              });
              break;
            case 'undecided':
              window.dataLayer.push({
                event: 'view-applicant-list-undecided',
                job_id: jobId,
                user_id: userId
              });
              break;
            case 'shortlisted':
              window.dataLayer.push({
                event: 'view-applicant-list-shortlisted',
                job_id: jobId,
                user_id: userId
              });
              break;
            case 'kiv':
              window.dataLayer.push({
                event: 'view-applicant-list-kiv',
                job_id: jobId,
                user_id: userId
              });
              break;
            case 'interview':
              window.dataLayer.push({
                event: 'view-applicant-list-interview',
                job_id: jobId,
                user_id: userId
              });
              break;
            case 'offer':
              window.dataLayer.push({
                event: 'view-applicant-list-offered',
                job_id: jobId,
                user_id: userId
              });
              break;
          }
        } catch {}
      }
    };

    const handleNextIcon = () => {
      if (isSelected == candidates?.length - 1) {
        if (pageInfo?.hasNextPage) {
          fetchingData = true;
          getJobApplicants();
        }
      } else {
        handleScrollToCard(isSelected, false);
        setIsSelected(isSelected + 1);
        scrollToTop();
        viewApplicantTracking(candidates, isSelected + 1);

        try {
          const cCandidate = currentCandidates[isSelected + 1];
          if (cCandidate?.node) {
            onViewApplication(cCandidate.node);
          }
        } catch (error) {
          //
        }
      }

      const eventName = 'list_next_applicant';

      dataLayer.push({
        event: eventName
      });
    };

    const handleSelectedChip = index => {
      setSelectedChip(index);
      scrollToSection(index);
    };

    const [openFullscreenModal, setOpenFullscreenModal] = useState(false);

    const handleFullscreenModal = () => {
      setOpenFullscreenModal(true);
    };

    const triggerCandidateEmail = event => {
      event.preventDefault();
      window.open(
        `mailto:${candidate?.user?.email}?subject=Interview for ${candidate?.job?.title} at ${myCompany?.name} &body=Hi ${candidate?.user?.name},%0A%0A Thanks for applying for the role of ${candidate?.job?.title} using Hiredly!%0A%0AWe have reviewed your application and would like to invite you for an interview at our office. Do let me know if you are available at the following times?%0A%0ADate:%0ATime:%0AVenue:%0A%0ALet me know if you have questions, and I look forward to meeting you in person. Hope to hear from you soon.%0A%0ABest regards,`
      );
    };

    const [isCloseLoopOpen, setIsCloseLoopOpen] = useState(false);

    const handleCloseLoopPopUpClose = () => {
      setIsCloseLoopOpen(false);
    };

    const moveApplicationCountToOffer = state => {
      const currentJobData = { ...jobData };
      if (state == 'interview') {
        currentJobData['interviewCount'] -= 1;
      } else if (state == 'kiv') {
        currentJobData['kivCount'] -= 1;
      } else if (state == 'shortlisted') {
        currentJobData['shortlistedCount'] -= 1;
      }

      currentJobData['offerCount'] += 1;

      setJobData(currentJobData);
    };

    const closingApplicationLoop = (application, state) => {
      const closingLoopParams = {
        id: application.id,
        state: state
      };

      dispatch(closingLoop(closingLoopParams)).then(res => {
        if (res.type === types.CLOSE_LOOP_SUCCEED) {
          // if (res.redirectUrl) {
          //   window.open(res.redirectUrl);
          // }
          SuccessToast('Successfully marked this candidate as Hired.');
          setLoopError(false);

          if (setCandidates && candidates) {
            let index = -1;
            if (state === 'interview' || state === 'offer') {
              index = candidates.findIndex(
                item => item.node?.jobApplication?.id === res.jobApplication.id
              );
            } else {
              index = candidates.findIndex(
                item => item.node.id === res.jobApplication.id
              );
            }

            if (index != -1) {
              let currentCandidates = [...candidates];

              if (state === 'offer') {
                currentCandidates[index] = {
                  ...currentCandidates[index],
                  node: {
                    ...currentCandidates[index]?.node,
                    jobApplication: { ...res.jobApplication }
                  }
                };
              } else {
                if (setIsSelected) {
                  setIsSelected(null);
                }

                moveApplicationCountToOffer(state);
                currentCandidates.splice(index, 1);
              }
              setCandidates(currentCandidates);

              if (setCurrentCandidates) {
                setCurrentCandidates(currentCandidates);
              }
            }
          }
        } else {
          setLoopError(true);
          ErrorToast(
            'Failed to close loop at the moment, please try again later.'
          );
        }
        // handleCloseLoopPopUpClose();
      });
    };

    //  To allow parent to call this function
    useImperativeHandle(ref, () => ({
      handlePreviousIcon,
      handleNextIcon,
      updateCandidateFromList,
      onChangeJobApplicationStatus,
      setIsBlacklistOpen,
      setApplicationState
    }));

    return (
      <div ref={heightRef} style={{ marginTop: '8px' }}>
        <BlackListPopUp
          open={isBlacklistOpen}
          handleClose={() => setIsBlacklistOpen(false)}
          onChangeJobApplicationStatus={onChangeJobApplicationStatus}
          loading={mutatingJobApplication}
        />
        <Grid
          container
          className={styles.candidateToolBarContainer}
          style={
            scrollY > 120 && direction == 'scrollingUp'
              ? {
                  position: 'sticky',
                  top: '-0.4%',
                  zIndex: '999',
                  flexWrap: 'nowrap',
                  overflow: 'auto'
                }
              : {
                  background: '#f7f7f8',
                  borderRadius: '5px',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                  overflow: 'auto'
                }
          }>
          <Grid>
            {isCandidatesEmpty ? (
              isSelected === null &&
              loading && (
                <div>
                  <Skeleton variant="circle" width={40} height={40} />
                </div>
              )
            ) : (
              <Avatar
                className={styles.candidateAvatar}
                key={candidate?.user?.profileImageSquare}
                src={candidate?.user?.profileImageSquare}
              />
            )}
          </Grid>

          {!isCandidatesEmpty && (
            <div className={styles.statusChipsContainer}>
              {chipButtons.map((chipButton, index) => {
                return (
                  <CustomButton
                    id={
                      chipButton == 'Profile'
                        ? 'list-profile-button'
                        : chipButton == 'Resume'
                        ? 'list-resume-button'
                        : 'list-chat-button'
                    }
                    key={index}
                    onClick={() => handleSelectedChip(index)}
                    disableRipple
                    style={{ background: 'transparent' }}>
                    <p
                      className={styles.statusChipText}
                      style={
                        selectedChip == index
                          ? {
                              borderBottom: '2px solid #512acc'
                            }
                          : {}
                      }>
                      {chipButton}
                    </p>
                  </CustomButton>
                );
              })}
            </div>
          )}

          <div
            style={{
              display: 'flex',
              flex: '1',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginRight: '20px'
            }}>
            <Grid>
              {isCandidatesEmpty ? (
                isSelected === null &&
                loading && (
                  <div className={styles.statusContainer}>
                    <Skeleton
                      variant="rect"
                      style={{ borderRadius: 12, height: 40, width: 210 }}
                    />
                  </div>
                )
              ) : (
                <StatusDropdown
                  item={item}
                  isCandidatesEmpty={isCandidatesEmpty}
                  applicationState={applicationState}
                  isSelected={isSelected}
                  loading={loading}
                  changeLoading={mutatingJobApplication}
                  onChangeJobApplicationStatus={onChangeJobApplicationStatus}
                  setIsBlacklistOpen={setIsBlacklistOpen}
                  setIsOfferJobOpen={setIsOfferJobOpen}
                  candidateJobApplication={candidate}
                  applicationStatus={applicationStatus}
                  setApplicationState={setApplicationState}
                  isBlacklistOpen={isBlacklistOpen}
                  setJobData={setJobData}
                  setCandidates={setCandidates}
                  setCurrentCandidates={setCurrentCandidates}
                  getCurrentCandidates={getCurrentCandidates}
                />
              )}
            </Grid>

            {!isCandidatesEmpty && (
              <Grid>
                <FloatingToolbar
                  handlePreviousIcon={handlePreviousIcon}
                  handleNextIcon={handleNextIcon}
                  saveFile={saveFile}
                  handleFullscreenModal={handleFullscreenModal}
                  openFullscreenModal={openFullscreenModal}
                  isSelected={isSelected}
                  candidates={candidates}
                  pageInfo={pageInfo}
                  triggerCandidateEmail={triggerCandidateEmail}
                  onApplicationClicked={onApplicationClicked}
                  candidate={candidate}
                />
              </Grid>
            )}
          </div>
        </Grid>

        {!candidate?.user?.videoCvOptimized &&
          candidate?.state !== 'undecided' &&
          candidate?.state !== 'rejected' &&
          candidate?.employerHiredStatus === null && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '15px'
              }}>
              <Card
                style={{
                  borderRadius: '10px',
                  width: '70%',
                  maxWidth: '750px',
                  boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.1)'
                }}>
                <CloseLoop setIsCloseLoopOpen={setIsCloseLoopOpen} />
              </Card>
            </div>
          )}
        <CloseLoopPopUp
          isCloseLoopOpen={isCloseLoopOpen}
          handleCloseLoopPopUpClose={handleCloseLoopPopUpClose}
          candidate={candidate}
          item={item}
          closingApplicationLoop={closingApplicationLoop}
          isClosingLoop={isClosingLoop}
          loopError={loopError}
          setLoopError={setLoopError}
        />

        {/* {isRenderSelected && (
          <CandidateShortInfoCard
            candidate={candidate}
            isCandidatesEmpty={isCandidatesEmpty}
            loading={loading}
            isSelected={isSelected}
          />
        )} */}

        {isRenderSelected && (
          <CandidateInfoCard
            candidate={candidate}
            item={item}
            isCandidatesEmpty={isCandidatesEmpty}
            loading={loading}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            handleCloseLoopPopUpClose={handleCloseLoopPopUpClose}
            setIsCloseLoopOpen={setIsCloseLoopOpen}
            isCloseLoopOpen={isCloseLoopOpen}
            candidates={candidates}
            setCandidates={setCandidates}
            setCurrentCandidates={setCurrentCandidates}
            jobData={jobData}
            setJobData={setJobData}
          />
        )}

        {candidate?.gptSummary && (
          <CandidateApplicationSummary candidate={candidate} />
        )}

        {isRenderSelected && candidate?.user?.resume && (
          <div
            style={{
              minWidth: '915px',
              width: '70%',
              maxWidth: '1280px',
              margin: '30px auto auto auto'
            }}>
            <CandidateResume
              candidate={candidate}
              loading={loading}
              isSelected={isSelected}
              isCandidatesEmpty={isCandidatesEmpty}
            />
          </div>
        )}

        {candidate?.gptChatSummary && (
          <CandidateAiviSummary candidate={candidate} />
        )}

        {isCandidatesEmpty && loading ? (
          <Grid container>
            <div className={styles.skeletonContainer}>
              <Grid item xs={12}>
                <div style={{ marginTop: 30 }}>
                  <SkeletonLoader />
                </div>
              </Grid>
            </div>
          </Grid>
        ) : (
          <>
            <div className={styles.chatWrapper}>
              {/* <div className={styles.buttonContainer}>
            <Button
              color="TRANSPARENT"
              size="AUTO"
              style={{ fontSize: '14px' }}
              onClick={() => history('/chats')}>
              View All Chats
            </Button>
          </div> */}
              <div className={styles.chatContainer}>
                <p
                  style={{
                    fontFamily: 'InterBold',
                    fontSize: '14px',
                    margin: '0'
                  }}>
                  Chat
                </p>
              </div>

              <CandidateChat
                source={'list-view-manage-candidate'}
                tabValue={tabValue}
                candidate={candidate}
                chatChannel={candidate?.sendbirdChannelUrl}
                isCandidatesEmpty={isCandidatesEmpty}
                updateCandidateFromList={updateCandidateFromList}
              />
            </div>
          </>
        )}
        <OfferJobPopUp
          isOfferJobOpen={isOfferJobOpen}
          handleOfferJobClose={handleOfferJobClose}
          changeInterviewStatus={changeInterviewStatus}
          interview={item}
          updatingInterviewStatus={updatingInterviewStatus}
          setCandidates={setCandidates}
          candidates={candidates}
          setJobData={setJobData}
        />
      </div>
    );
  }
);

export default CandidateInformation;
