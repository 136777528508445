import React, { useEffect, useState } from 'react';
import { Button as MUIButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import DefaultLayout from '../../layouts/DefaultLayout';
import { Stepper, Card } from '../../components';
import s from './EmployerRegistration.module.scss';
import { ErrorToast } from '../../utils/ToastUtils';
import FormSelectCountry from '../../components/FormSelectCountry';
import FormSelectStateRegion from '../../components/FormSelectState';
import countries from 'i18n-iso-countries';
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry
} from 'postcode-validator';
import { MUIButtonStyled } from '../../components/MUIButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchIndustries,
  fetchCompanyTypes,
  mutateCompanyDetails,
  fetchAccountInfo
} from '../../redux/actions/company_actions';
import { getLocalStorageItem } from '../../helpers/local_storage_utils';
import SharedTextField from '../../components/SharedTextField';
import SharedSelectField from '../../components/SharedSelectField';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const INITIAL_STATE = {
  industryId: '',
  typeId: '',
  website: ''
};

const validationSchema = yup.object().shape({
  industryId: yup.string().required('Industry is required'),
  typeId: yup.string().required('Company Type is required'),
  website: yup
    .string()
    .url(
      'Company website must be a valid URL that starts with http://, https:// or ftp://.'
    )
});

export default function CompanyRegisterDetails() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(INITIAL_STATE);
  const [errors, setErrors] = useState({});
  const [addressInfo, setAddressInfo] = useState({
    address: '',
    city: '',
    postcode: '',
    state: '',
    country: ''
  });
  const [addressWarnings, setAddressWarnings] = useState({
    address: false,
    city: false,
    postcode: false,
    state: false,
    country: false
  });
  const [countryCode, setCountryCode] = useState('');

  useEffect(() => {
    dispatch(fetchIndustries());
    dispatch(fetchCompanyTypes());
  }, [dispatch]);

  const industries = useSelector(state => state.companies.industries);
  const companyTypes = useSelector(state => state.companies.companyTypes);
  const fetchingCompanySettings = useSelector(
    state => state.companies.fetchingCompanySettings
  );

  const validateField = async (field, value) => {
    try {
      await validationSchema.validateAt(field, { [field]: value });
      setErrors(prevErrors => ({ ...prevErrors, [field]: '' }));
    } catch (err) {
      setErrors(prevErrors => ({ ...prevErrors, [field]: err.message }));
    }
  };

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    validateField(field, value);
  };

  const handleAddressChange = (field, value) => {
    setAddressInfo({ ...addressInfo, [field]: value });

    if (value === '') {
      setAddressWarnings(prevWarnings => ({ ...prevWarnings, [field]: true }));
    } else {
      setAddressWarnings(prevWarnings => ({ ...prevWarnings, [field]: false }));
    }

    if (field === 'postcode' && (value === '' || !validatePostcode(value))) {
      setAddressWarnings(prevWarnings => ({ ...prevWarnings, postcode: true }));
    }
  };

  const getCountryCode = country => {
    const cCountryCode = countries.getAlpha2Code(country, 'en');
    setCountryCode(cCountryCode);
  };

  const validatePostcode = value => {
    const countryCodeExists = postcodeValidatorExistsForCountry(countryCode);
    if (countryCodeExists) {
      return postcodeValidator(value, countryCode);
    }
    return /^[0-9\b]+$/.test(value);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      await validationSchema.validate(formData, { abortEarly: false });

      if (
        addressInfo.address &&
        addressInfo.city &&
        addressInfo.state &&
        addressInfo.country &&
        addressInfo.postcode &&
        validatePostcode(addressInfo.postcode)
      ) {
        dispatch(
          mutateCompanyDetails({
            input: {
              agreeToTerms: true,
              industryId: parseInt(formData.industryId, 10),
              typeId: parseInt(formData.typeId, 10),
              website: formData.website,
              billingAddress: {
                streetAddress: addressInfo.address,
                town: addressInfo.city,
                postcode: addressInfo.postcode,
                state: addressInfo.state,
                country: addressInfo.country
              }
            }
          })
        )
          .then(res => {
            if (res.type === 'FETCH_MY_COMPANY_SETTINGS_SUCCEED') {
              window.dataLayer.push({
                event: 'complete_onboarding_done'
              });
              dispatch(fetchAccountInfo());

              const queryStrings = localStorage.getItem('productQuery');
              if (queryStrings) {
                history(JSON.parse(queryStrings)?.url, { replace: true });
              } else {
                history('/welcome', { replace: true });
              }
            }
          })
          .catch(error => {
            console.error('Error:', error);
            ErrorToast(error.message);
          });
      } else {
        setAddressWarnings({
          address: !addressInfo.address,
          city: !addressInfo.city,
          postcode:
            !addressInfo.postcode || !validatePostcode(addressInfo.postcode),
          state: !addressInfo.state,
          country: !addressInfo.country
        });
      }
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach(error => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
    }
  };

  const handleSkip = () => {
    window.dataLayer.push({
      event: 'complete_onboarding_skip'
    });
    const queryStrings = localStorage.getItem('productQuery');
    if (queryStrings) {
      history(JSON.parse(queryStrings)?.url, { replace: true });
    } else {
      history('/welcome', { replace: true });
    }
  };

  useEffect(() => {
    const cCountry = getLocalStorageItem('country');

    if (cCountry === 'MY') {
      handleAddressChange('country', 'Malaysia');
    } else if (cCountry === 'SG') {
      handleAddressChange('country', 'Singapore');
    }
  }, []);

  useEffect(() => {
    if (addressInfo.country) {
      getCountryCode(addressInfo.country);
    }
  }, [addressInfo.country]);

  return (
    <DefaultLayout>
      <div className={s.pageWrapper}>
        <div className={s.topSection}>
          <h4 className={s.title} style={{ fontSize: '30px' }}>
            Create your employer account in 2 minutes!
          </h4>
          <div className={s.flowSection}>
            <Stepper
              list={[
                'Login Information',
                'Email Verification',
                'Company Details (Optional)'
              ]}
              currentStep={2}
            />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className={s.cardRow}>
            <Card className={s.card}>
              <h5 className={s.title} style={{ fontSize: '24px' }}>
                Company Details{' '}
              </h5>
              <div className={s.form}>
                <div className={s.row}>
                  <SharedSelectField
                    name="industryId"
                    placeholder="Industry"
                    options={industries}
                    value={formData.industryId}
                    onChange={e => handleChange('industryId', e.target.value)}
                    error={errors.industryId}
                    helperText={errors.industryId}
                  />
                </div>
                <div className={s.row}>
                  <SharedSelectField
                    name="typeId"
                    placeholder="Company Type"
                    options={companyTypes}
                    value={formData.typeId}
                    onChange={e => handleChange('typeId', e.target.value)}
                    error={errors.typeId}
                    helperText={errors.typeId}
                  />
                </div>
                <div className={s.row}>
                  <SharedTextField
                    name="website"
                    placeholder="Company Website"
                    value={formData.website}
                    onChange={e => handleChange('website', e.target.value)}
                    error={errors.website}
                    helperText={errors.website}
                  />
                </div>
              </div>
            </Card>
            <Card className={s.card}>
              <h5 className={s.title} style={{ fontSize: '24px' }}>
                Billing Information
              </h5>
              <div className={s.form}>
                <div className={s.row}>
                  <SharedTextField
                    name="address"
                    placeholder="Street Address*"
                    value={addressInfo.address}
                    onChange={e =>
                      handleAddressChange('address', e.target.value)
                    }
                    error={addressWarnings.address}
                    helperText={
                      addressWarnings.address && 'Address is required'
                    }
                  />
                </div>
                <div className={s.row}>
                  <SharedTextField
                    name="city"
                    placeholder="City*"
                    value={addressInfo.city}
                    onChange={e => handleAddressChange('city', e.target.value)}
                    error={addressWarnings.city}
                    helperText={addressWarnings.city && 'City is required'}
                  />
                </div>
                <div className={s.row}>
                  <SharedTextField
                    name="postcode"
                    placeholder="Postcode*"
                    value={addressInfo.postcode}
                    onChange={e =>
                      handleAddressChange('postcode', e.target.value)
                    }
                    error={addressWarnings.postcode}
                    helperText={
                      addressWarnings.postcode && 'Valid postcode is required'
                    }
                  />
                </div>
                <div className={s.row} style={{ margin: '0 0 20px' }}>
                  <FormSelectCountry
                    name="country"
                    label="Select Country"
                    country={addressInfo.country}
                    onChange={e =>
                      handleAddressChange('country', e.target.value)
                    }
                    value={addressInfo.country}
                    warning={addressWarnings.country}
                  />
                </div>
                <div className={s.row} style={{ margin: '8px 0 20px' }}>
                  <FormSelectStateRegion
                    country={addressInfo.country}
                    name="state"
                    label="Select State/Region"
                    value={addressInfo.state}
                    onChange={e => handleAddressChange('state', e)}
                    warning={addressWarnings.state}
                  />
                </div>
              </div>
            </Card>
          </div>
          <div className={s.buttonContainer}>
            <MUIButtonStyled
              label="Complete Registration"
              disabled={fetchingCompanySettings}
              type="submit"
            />
            <MUIButton onClick={handleSkip} className={s.skipLink}>
              I&apos;ll Do This Later
            </MUIButton>
          </div>
        </form>
      </div>
    </DefaultLayout>
  );
}
