import React, { useEffect, useState } from 'react';
import {
  MainContainer,
  InterviewNoteContainer,
  LeftPanel,
  Line,
  ProfileImage,
  StatusIndicator,
  AddIconStyled,
  CheckIconStyled,
  RightPanel,
  NoteContentContainer,
  NoteCommenterNameTimeContainer,
  NoteCommenterName,
  NoteAddedTime,
  InterviewRemark,
  InterviewNumberDateContainer,
  InterviewNumber,
  InterviewDate,
  AddNoteContainer,
  AddNoteHeader,
  AddNoteMessage,
  TextFieldStyled,
  ButtonsContainer,
  CircularProgressStyled,
  ButtonContainer
} from './styles';
import moment from 'moment';
import { Button } from '../../../components';
import {
  addInterviewNote,
  updateInterviewNote
} from '../../../redux/actions/application_action';
import {
  SuccessToast,
  ErrorToast,
  WarningToast
} from '../../../utils/ToastUtils';
import * as types from '../../../redux/types/application_type';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

let submittingNote = false;

const CandidateSnapshotInterviewNote = props => {
  const { candidate, candidates, setCandidates, isAts, setCurrentCandidates } =
    props;
  const [remark, setRemark] = useState('');
  const [accountType, setAccountType] = useState('');
  const [accountInfo, setAccountInfo] = useState(null);
  const [showRemarkTextField, setShowRemarkTextField] = useState(false);
  const [interviews, setInterviews] = useState([]);

  const noteValueChange = event => {
    setRemark(event.currentTarget.value);
  };

  const onAddNoteClicked = () => {
    setShowRemarkTextField(true);
  };

  const onCancelClicked = () => {
    setShowRemarkTextField(false);
  };

  const getName = interview => {
    if (interview.interviewNotes?.length > 0) {
      const currentNote = interview.interviewNotes[0];
      if (currentNote?.companyTeamMember) {
        return currentNote?.companyTeamMember?.name;
      } else if (currentNote?.company) {
        return currentNote?.companyTeamMember?.userName;
      }
    }

    return '';
  };

  const getDate = interview => {
    if (interview.interviewNotes?.length > 0) {
      const currentNote = interview.interviewNotes[0];

      if (currentNote.createdAt) {
        return moment(currentNote.createdAt).format('DD MMM YYYY • hh:mma');
      }
    }

    return '';
  };

  const allowAddOrEditNote = interview => {
    if (interview.interviewNotes?.length > 0) {
      const currentNote = interview.interviewNotes[0];

      if (
        !currentNote.companyTeamMember &&
        accountType.toLowerCase() != 'master'
      ) {
        //created by master but current account is CTM
        return false;
      } else if (
        currentNote.companyTeamMember &&
        currentNote.companyTeamMember.id != accountInfo.accountId
      ) {
        //created by CTM and id not same
        return false;
      }
    }

    return true;
  };

  const getNote = interview => {
    if (interview.interviewNotes?.length > 0) {
      const currentNote = interview.interviewNotes[0];

      return currentNote.note;
    }

    return '';
  };

  const updateLocalNote = interview => {
    if (isAts) return;
    if (!candidates) return;

    const index = candidates.findIndex(
      item => item?.node?.id === candidate?.id
    );

    let cCandidates = [...candidates];

    if (index !== -1) {
      if (candidates[index].node?.jobApplication) {
        //is interview
        const interviewNote =
          interview &&
          interview.interviewNotes &&
          interview.interviewNotes.length > 0
            ? interview.interviewNotes[0]
            : {};

        const currentJobApplication = {
          ...cCandidates[index].node.jobApplication
        };

        const currentInterviews = [
          ...cCandidates[index].node.jobApplication.interviews
        ];

        const interviewIndex = currentInterviews.findIndex(
          currentInterview => currentInterview.id == interview?.id
        );

        if (interviewIndex != -1) {
          currentInterviews[interviewIndex] = { ...interview };
        } else {
          // Not found then replace the first one
          if (currentInterviews.length > 0) {
            currentInterviews[0] = { ...interview };
          }
        }

        currentJobApplication.interviews = [...currentInterviews];

        cCandidates[index] = {
          ...cCandidates[index],
          node: {
            ...cCandidates[index]?.node,
            interviewNotes: [{ ...interviewNote }],
            jobApplication: currentJobApplication
          }
        };
      } else {
        // is application - rare cases
        const currentInterviews = [...cCandidates[index].node.interviews];

        const interviewIndex = currentInterviews.findIndex(
          currentInterview => currentInterview.id == interview?.id
        );

        if (interviewIndex != -1) {
          currentInterviews[interviewIndex] = { ...interview };
        } else {
          // Not found then replace the first one
          if (currentInterviews.length > 0) {
            currentInterviews[0] = { ...interview };
          }
        }

        cCandidates[index] = {
          ...cCandidates[index],
          node: {
            ...cCandidates[index]?.node,
            interviews: [...currentInterviews]
          }
        };
      }

      setCandidates(cCandidates);

      if (setCurrentCandidates) {
        setCurrentCandidates(cCandidates);
      }
    }
  };

  const onSaveNoteButtonClicked = (interview, latestNote) => event => {
    if (submittingNote) return;

    submittingNote = true;

    if (latestNote === '') {
      submitAddInterviewNote(interview);
    } else {
      submitUpdateInterviewNote(interview);
    }
  };

  const submitAddInterviewNote = interview => {
    if (remark === '') {
      WarningToast('Empty note is not allowed');
      return;
    }

    let addNoteParams = {
      id: interview.id,
      note: remark
    };

    props.addInterviewNote(addNoteParams).then(res => {
      submittingNote = false;
      if (res.type === types.ADD_INTERVIEW_NOTE_SUCCEED) {
        if (res.interview) {
          let currentInterviews = [...interviews];
          if (currentInterviews.length > 0) {
            currentInterviews[0] = { ...res.interview };
            setInterviews(currentInterviews);
          }
          updateLocalNote(res.interview);
        }

        SuccessToast('Added note successfully.');
      }

      setShowRemarkTextField(false);
    });
  };

  const submitUpdateInterviewNote = interview => {
    if (remark === '') {
      WarningToast('Empty note is not allowed');
      return;
    }

    if (
      interview &&
      (!interview.interviewNotes || interview.interviewNotes.length == 0)
    ) {
      submittingNote = false;
      return;
    }

    let updateNoteParams = {
      id: interview.interviewNotes[0].id,
      note: remark
    };

    props.updateInterviewNote(updateNoteParams).then(res => {
      submittingNote = false;
      if (res.type === types.UPDATE_INTERVIEW_NOTE_SUCCEED) {
        if (res.interview) {
          let currentInterviews = [...interviews];
          if (currentInterviews.length > 0) {
            currentInterviews[0] = { ...res.interview };
            setInterviews(currentInterviews);
          }
          updateLocalNote(res.interview);
        }

        SuccessToast('Updated note successfully.');
      } else {
        if (res.message) {
          ErrorToast(res.message);
        }
      }

      setShowRemarkTextField(false);
    });
  };

  useEffect(() => {
    try {
      let cAccountInfo = localStorage.getItem('accountInfo');
      let cAaccountType = localStorage.getItem('currentAccountType');

      setAccountType(cAaccountType);

      if (cAccountInfo) {
        cAccountInfo = JSON.parse(cAccountInfo);
        setAccountInfo(cAccountInfo);
      }
    } catch (error) {
      //
    }
  }, []);

  useEffect(() => {
    //Reset interviews and remark whenever candidate value changed
    const application = candidate?.jobApplication
      ? candidate.jobApplication
      : candidate;
    let currentInterviews = application?.interviews
      ? [...application.interviews]
      : [];

    currentInterviews.sort(function (a, b) {
      return b.id - a.id;
    });

    if (currentInterviews.length > 0) {
      if (currentInterviews[0].interviewNotes?.length > 0) {
        setRemark(currentInterviews[0].interviewNotes[0].note);
      } else {
        setRemark('');
      }
    } else {
      setRemark('');
    }
    setInterviews(currentInterviews);
  }, [candidate]);

  return (
    <MainContainer>
      {interviews.map((interview, index) => (
        <InterviewNoteContainer key={interview.id}>
          <LeftPanel>
            <Line
              notLast={index + 1 === interviews.length ? 'false' : 'true'}
            />
            {index === 0 && (
              <StatusIndicator addNote="true" onClick={onAddNoteClicked}>
                <AddIconStyled />
              </StatusIndicator>
            )}
            {/* {index === 0 && showRemarkTextField && (
              <ProfileImage isAbsolute="true" />
            )} */}
            {/* {index !== 0 && <ProfileImage />} */}
            <StatusIndicator done={index == 0 ? 'false' : 'true'}>
              {index !== 0 && <CheckIconStyled />}
            </StatusIndicator>
          </LeftPanel>
          <RightPanel>
            {index == 0 ? (
              <AddNoteContainer
                withNote={getNote(interview) == '' ? 'false' : 'true'}>
                {allowAddOrEditNote(interview) && (
                  <AddNoteHeader onClick={onAddNoteClicked}>
                    {interview.interviewNotes?.length > 0
                      ? 'Edit Note'
                      : 'Add Note'}
                  </AddNoteHeader>
                )}

                {showRemarkTextField ? (
                  <>
                    <TextFieldStyled
                      id="filled-multiline-flexible"
                      placeholder={`What do you think of the candidate?`}
                      multiline
                      rowsMax={4}
                      value={remark}
                      type={'text'}
                      onChange={noteValueChange}
                      variant="filled"
                      inputProps={{
                        style: {
                          borderRadius: '8px',
                          width: '100%',
                          height: '87px',
                          overflow: 'auto',
                          transform: 'translateY(-10px)'
                        }
                      }}
                      InputProps={{
                        style: {
                          fontSize: '14px',
                          letterSpacing: '0.03rem',
                          borderRadius: '8px',
                          width: '100%',
                          height: '107px',
                          background: 'transparent'
                        },
                        disableUnderline: true
                      }}
                    />
                    <ButtonsContainer>
                      <ButtonContainer onClick={onCancelClicked}>
                        <Button color="PURPLETEXT" size="TINY">
                          Cancel
                        </Button>
                      </ButtonContainer>
                      <div>
                        <Button
                          color="PURPLE"
                          size="TINY"
                          onClick={onSaveNoteButtonClicked(
                            interview,
                            getNote(interview)
                          )}>
                          {props.addingInterviewNote ||
                          props.updatingInterviewNote ? (
                            <CircularProgressStyled />
                          ) : (
                            'Save'
                          )}
                        </Button>
                      </div>
                    </ButtonsContainer>
                  </>
                ) : getName(interview) !== '' ? (
                  <NoteContentContainer>
                    <NoteCommenterNameTimeContainer>
                      <NoteCommenterName>
                        {getName(interview)}
                      </NoteCommenterName>
                      <NoteAddedTime>{getDate(interview)}</NoteAddedTime>
                    </NoteCommenterNameTimeContainer>
                    <InterviewRemark>
                      {getNote(interview) == '' ? '-' : getNote(interview)}
                    </InterviewRemark>
                  </NoteContentContainer>
                ) : (
                  <AddNoteMessage>
                    Remarks on candidate/interview
                  </AddNoteMessage>
                )}
              </AddNoteContainer>
            ) : (
              <NoteContentContainer>
                <NoteCommenterNameTimeContainer>
                  <NoteCommenterName>{getName(interview)}</NoteCommenterName>
                  <NoteAddedTime>{getDate(interview)}</NoteAddedTime>
                </NoteCommenterNameTimeContainer>
                <InterviewRemark>
                  {getNote(interview) == '' ? '-' : getNote(interview)}
                </InterviewRemark>
              </NoteContentContainer>
            )}

            <InterviewNumberDateContainer>
              <InterviewNumber>
                {`Interview ${interviews.length - index}`}
              </InterviewNumber>
              <InterviewDate>
                {moment(interview.date).format('DD MMM YYYY')}
              </InterviewDate>
            </InterviewNumberDateContainer>
          </RightPanel>
        </InterviewNoteContainer>
      ))}
    </MainContainer>
  );
};

const mapStateToProps = state => {
  return {
    addingInterviewNote: state.applications.addingInterviewNote,
    updatingInterviewNote: state.applications.updatingInterviewNote
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addInterviewNote: bindActionCreators(addInterviewNote, dispatch),
    updateInterviewNote: bindActionCreators(updateInterviewNote, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateSnapshotInterviewNote);
