import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowLeftIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { IconButton } from '@mui/material';

export const SwitchStyled = styled(Switch)(props => ({
  width: '52px',
  height: '24px',
  padding: 0,
  borderRadius: 30,
  '& .MuiSwitch-switchBase': {
    padding: 1,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(27px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        // When is checked
        opacity: 1,
        border: 0,
        backgroundColor: '#FFF'
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: '#D8D8D8'
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
      backgroundColor: '#222'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#33cf4d',
      backgroundColor: '#FFF'
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: '#D8D8D8'
    }
  },
  '& .Mui-checked .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18,
    backgroundColor: '#222'
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18,
    backgroundColor: '#FFF'
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    opacity: 1,
    backgroundColor: '#D3D3D3',
    '&:before': {
      content: '"Yes"',
      display: 'flex',
      opacity: props.checked ? 1 : 0,
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '16px',
      transform: 'translate(8px, 4px)'
    },
    '&:after': {
      content: '"No"',
      display: 'flex',
      opacity: props.checked ? 0 : 1,
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '16px',
      transform: 'translate(30px, -12px)'
    }
  }
}));

export const ModalContainer = styled('div')(props => ({
  width: '1020px',
  height: '620px',
  // margin: '40px auto',
  background: '#FFF',
  borderRadius: '16px',
  border: '1px solid #D8D8D8',

  ...(props.isPopUp && {
    margin: '0rem auto'
  })
}));

export const ModalTitle = styled('div')(props => ({
  display: 'flex',
  padding: '16px 16px 16px 24px',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch',
  borderBottom: '1px solid #D8D8D8',
  flexDirection: 'column',
  gap: '4px',
  flex: '1 0 0'
}));

export const ModalHeader = styled('div')(props => ({
  color: 'rgba(0, 0, 0, 0.87)',
  textAlign: 'center',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px'
}));

export const ModalSubHeader = styled('div')(props => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative'
}));

export const ModalText = styled('div')(props => ({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const ModalTextGrey = styled('div')(props => ({
  color: 'rgba(0, 0, 0, 0.60)',
  fontStyle: 'italic',
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const ModalTextPurple = styled('div')(props => ({
  color: '#512ACC',
  fontSize: '12px',
  fontWeight: 700,
  lineHeight: 'normal'
}));

export const ModalContent = styled('div')(props => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row'
}));

export const LeftBox = styled('div')(props => ({
  width: '50%',
  position: 'relative',
  borderLeft: '1px solid #D8D8D8'
}));

export const RightBox = styled('div')(props => ({
  width: '50%',
  background: '#F5F5F5',
  overflow: 'hidden',
  position: 'relative'
}));

export const InfoBox = styled('div')(props => ({
  maxWidth: '509px',
  padding: '8px 24px',
  gap: '10px',
  background: '#FDF6E0',
  position: 'absolute',
  top: '0',

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  borderBottom: '1px solid #FBEEC1',
  width: '100%',
  zIndex: '2'
  // cursor: 'pointer',
}));

export const InfoText = styled('div')(props => ({
  color: 'rgba(0, 0, 0, 0.60)',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '20px',
  width: '100%',

  '& p': {
    margin: '0 0 0 32px'
  }
}));

export const InfoHeader = styled('div')(props => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '10px'
}));

export const InfoHeaderRow = styled('div')(props => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%'
}));

export const ModalFooter = styled('div')(props => ({
  display: 'flex',
  padding: '16px 24px',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  borderTop: '1px solid #D8D8D8',
  borderBottom: '1px solid #D8D8D8',
  background: '#FFF',
  position: 'relative',
  borderRadius: '0px 0px 16px 16px'
}));

export const ModalFooterPopUp = styled('div')(props => ({
  display: 'flex',
  padding: '16px 24px',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
  borderTop: '1px solid #D8D8D8',
  background: '#FFF',
  position: 'relative'
}));

export const BackToEditButton = styled(IconButton)(props => ({
  ':disabled': {
    cursor: 'not-allowed'
  },

  display: 'flex',
  width: '200px',
  height: '42px',
  padding: '0px 24px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '16px',
  borderRadius: '10px',

  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 700,
  whiteSpace: 'nowrap'
}));

export const KeyboardBackspaceIconStyled = styled(ArrowLeftIcon)(props => ({
  padding: '0',
  width: '24px',
  height: '24px',
  color: '#000'
}));

export const ContinueSection = styled('div')(props => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
  gap: '24px'
}));

export const PreviewBox = styled('div')(props => ({
  position: 'relative'
}));

export const PreviewTitle = styled('div')(props => ({
  // position: 'absolute',
  // left: '24px',
  // top: '16px',
  padding: '16px 0px 0px 24px',
  color: 'rgba(0, 0, 0, 0.60)',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px',
  whiteSpace: 'nowrap'
}));

export const PreviewExpiry = styled('div')(props => ({
  position: 'relative',
  textAlign: 'right',
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  marginTop: '10px',
  padding: '0 24px',

  '& span': {
    borderRadius: '4px',
    border: '1px solid #222',
    background: '#FFF',
    display: 'flex',
    padding: '4px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    color: 'rgba(0, 0, 0, 0.87)',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px'
  },

  '& p': {
    color: '#512ACC !important',
    margin: '0px'
  }
}));

export const KeywordTokenToggle = styled('div')(props => ({
  background: '#222',
  color: '#FFF',
  padding: '8px 16px',
  fontSize: '12px',
  fontWeight: 400,
  // whiteSpace: 'nowrap',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

export const ChevronUpIconToggle = styled(ChevronUpIcon)(props => ({
  padding: '0',
  width: '15px',
  height: '15px',
  color: '#222',

  ...(props.expanded && {
    transform: 'rotate(180deg)',
    transition: 'all .5s'
  })
}));

export const BackgroundView = styled('div')(props => ({
  display: 'flex',
  // height: '50%',
  position: 'absolute',
  width: '509px',
  padding: '0 25px',
  alignContent: 'space-between',
  flexDirection: 'column',
  // overflow: 'hidden',

  ...(props.isPopUp && {
    // height: 'calc(100% - 167px)',
    height: 'unset',
    width: 'unset'
  })
}));

export const BackgroundTop = styled('div')(props => ({
  height: '50%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px'
  // marginTop: '-15px'
}));

export const BackgroundBottom = styled('div')(props => ({
  height: '50%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px'
  // marginBottom: '-15px',
}));

export const SetToCenter = styled('div')(props => ({
  ...(!props.isPopUp && {
    position: 'relative',
    minHeight: 'calc(100vh - 140px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 0'
  })
}));

export const PreviewText = styled('p')(props => ({
  position: 'absolute',
  // left: '86px',
  bottom: '218.5px',
  color: 'rgba(0, 0, 0, 0.87)',
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px',
  margin: '0'
}));

export const PurchaseBox = styled('div')(props => ({
  display: 'flex',
  height: '30px',
  padding: '0 16px',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '6px',
  borderRadius: '6px',
  background: '#FFF',

  position: 'absolute',
  right: '0px',
  top: '-30px',

  '&:hover': {
    cursor: `pointer`
  },

  ...(props.isPopUp && {
    position: 'absolute',
    top: '-30px',
    right: '40px'
  })
}));

export const RefreshButton = styled('div')({
  display: 'flex',
  height: '30px',
  padding: '0 16px',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '6px',
  borderRadius: '6px',
  background: '#FFF',
  border: '2px solid #222',

  '& p': {
    color: '#222',
    fontSize: '12px',
    fontWeight: 700,
    margin: '0'
  },

  '&:hover': {
    cursor: `pointer`,
    background: '#EFEFEF'
  }
});

export const NoJobBox = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  flex: '1 0 0',
  alignSelf: 'stretch'
});

export const NoJobText = styled('div')({
  color: 'rgba(0, 0, 0, 0.87)',
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.15px',

  '& p': {
    margin: '0',
    fontWeight: 400
  },

  '& a': {
    color: '#512ACC',
    fontSize: '14px,',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
});
