import XMarkIcon from '@heroicons/react/24/solid/XMarkIcon';
import { Email as EmailIcon, Phone as PhoneIcon } from '@mui/icons-material';
import { Divider, Grid } from '@mui/material';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { mutateSendEnquiryEmail } from '../../../redux/actions/company_actions';
import { updateSubjectType } from '../../../redux/actions/job_action';
import {
  CONTACT_US_SUBJECT,
  CSEMAIL,
  Hiredly_Phone_Number
} from '../../../utils/Constants';
import { ErrorToast, SuccessToast } from '../../../utils/ToastUtils';
import styles from './index.module.scss';
import {
  DialogCloseButton,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
  DialogTitleText,
  SubmitButton
} from './styles';
import { getCountry } from '../../../hooks/useRegionalisation';
import SharedSelectField from '../../../components/SharedSelectField';
import SharedTextField from '../../../components/SharedTextField';

const ContactPopUp = ({ open, handleClose }) => {
  const dispatch = useDispatch();

  const subjectType = useSelector(state => state.jobs.subjectType);

  const mutatingEnquiryEmailSent = useSelector(
    state => state.companies.mutatingEnquiryEmailSent
  );

  const optionConfig = {
    'homepage-featured-company': CONTACT_US_SUBJECT.OPTION_4,
    'homepage-carousel-adspace': CONTACT_US_SUBJECT.OPTION_5,
    'advice-page-ads': CONTACT_US_SUBJECT.OPTION_6,
    'homepage-carousel-advice-ads': CONTACT_US_SUBJECT.OPTION_7,
    job_slot_plus: CONTACT_US_SUBJECT.OPTION_9,
    job_slot_max: CONTACT_US_SUBJECT.OPTION_10,
    OPTION_1: CONTACT_US_SUBJECT.OPTION_1,
    OPTION_2: CONTACT_US_SUBJECT.OPTION_2,
    OPTION_3: CONTACT_US_SUBJECT.OPTION_3,
    OPTION_4: CONTACT_US_SUBJECT.OPTION_4,
    OPTION_5: CONTACT_US_SUBJECT.OPTION_5,
    OPTION_6: CONTACT_US_SUBJECT.OPTION_6,
    OPTION_7: CONTACT_US_SUBJECT.OPTION_7,
    OPTION_8: CONTACT_US_SUBJECT.OPTION_8
  };

  const getSubject = type => {
    return optionConfig[type] || '';
  };

  const getOptions = () => {
    return Object.keys(CONTACT_US_SUBJECT).map(key => ({
      id: CONTACT_US_SUBJECT[key],
      title: CONTACT_US_SUBJECT[key]
    }));
  };

  const disableSelect = type => {
    return !!optionConfig[type];
  };

  const INITIAL_STATE = {
    subject: getSubject(subjectType),
    message: ''
  };

  const validationSchema = yup.object().shape({
    subject: yup.string().required('Subject is required')
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: INITIAL_STATE,
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    reset({
      subject: getSubject(subjectType),
      message: ''
    });
  }, [subjectType, reset]);

  const onSendEmail = async values => {
    dispatch(
      mutateSendEnquiryEmail({
        input: {
          subject: values?.subject,
          message: values?.message,
          origin: 'employer-dashboard'
        }
      })
    )
      .then(res => {
        if (res.type === 'MUTATE_SEND_ENQUIRY_EMAIL_SUCCEED') {
          SuccessToast('Email has been sent. Thank you for your enquiry.');
          handleClose();
          reset(INITIAL_STATE);
        }
      })
      .catch(err => {
        ErrorToast(err.message);
      });
  };

  const onHandleClose = () => {
    dispatch(updateSubjectType(''));
    handleClose();
  };

  return (
    <DialogStyled open={open} onClose={onHandleClose}>
      <DialogTitleStyled>
        <DialogTitleText variant="body1">Contact Us</DialogTitleText>
        <DialogCloseButton onClick={onHandleClose}>
          <XMarkIcon width={24} height={24} />
        </DialogCloseButton>
      </DialogTitleStyled>
      <Grid dividers>
        <form onSubmit={handleSubmit(onSendEmail)}>
          <div>
            <DialogContentStyled className={styles.contentContainer}>
              <div className={styles.row}>
                <p className={styles.rowLabel}>
                  What can our sales consultants help you with?
                </p>
                <Controller
                  name="subject"
                  control={control}
                  render={({ field }) => (
                    <SharedSelectField
                      {...field}
                      variant="outlined"
                      placeholder="Please select a subject"
                      options={getOptions(subjectType)}
                      error={!!errors.subject}
                      helperText={errors.subject?.message}
                    />
                  )}
                />
              </div>
              <div
                className={styles.row}
                style={{
                  marginBottom: '24px'
                }}>
                <p className={styles.rowLabel}>
                  Anything to add on? (optional)
                </p>
                <Controller
                  name="message"
                  control={control}
                  render={({ field }) => (
                    <SharedTextField
                      {...field}
                      type="BIG"
                      multiline
                      rows={6}
                      placeholder="Let us know any details you want to know more about."
                    />
                  )}
                />
              </div>
              <Divider
                sx={{
                  width: '500px',
                  alignSelf: 'center',
                  marginBottom: '24px'
                }}
              />

              <p className={styles.rowLabel}>
                You can also reach out to us via:
              </p>

              <Grid
                sx={{
                  borderRadius: '8px',
                  background: '#F1F1F4',
                  padding: '16px'
                }}
                gap={'8px'}>
                <Grid container columns={12}>
                  <Grid item className={styles.leftContainer} xs={4}>
                    <PhoneIcon />
                    <p className={styles.text}>Phone number:</p>
                  </Grid>
                  <Grid item className={styles.leftContainer} xs={8}>
                    <p className={styles.text}>
                      {' '}
                      {Hiredly_Phone_Number['MYWithSpace']}
                    </p>
                  </Grid>
                </Grid>

                <Grid container columns={12}>
                  <Grid item className={styles.leftContainer} xs={4}>
                    <EmailIcon />
                    <p className={styles.text}>Email address:</p>
                  </Grid>
                  <Grid item className={styles.leftContainer} xs={8}>
                    <p
                      role="button"
                      className={classNames(styles.text, styles.purple)}
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        window.open(`mailto:${CSEMAIL[getCountry()]}`)
                      }>
                      {CSEMAIL[getCountry()]}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContentStyled>

            <Divider />

            <div className={styles.buttonContainer}>
              <SubmitButton
                variant="contained"
                type="submit"
                disabled={mutatingEnquiryEmailSent}>
                Submit
              </SubmitButton>
            </div>
          </div>
        </form>
      </Grid>
    </DialogStyled>
  );
};

export default ContactPopUp;
