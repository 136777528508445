import React from 'react';
import styles from './index.module.scss';

const Company = ({ index, tableData }) => {
  const data = tableData[index]?.affiliatedCompany;
  return (
    <div className={styles.container}>
      <div
        className={styles.companyLogo}
        style={{
          backgroundImage: `url(${data?.publicLogoUrl})`
        }}
      />
      <p className={styles.title}>{data?.name}</p>
    </div>
  );
};

export default Company;
