import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import SkeletonLoader from './SkeletonLoader';
import { FILE_EXTENSION_TYPE } from '../../../../utils/Constants';
import styles from './index.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const TalentResume = ({ talent, onGetHeight, loading, isSelected }) => {
  const [totalPage, setTotalPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [showPageButton, setShowPageButton] = useState(true);

  const fileExtType = talent?.resume.slice(-5).split('.')[1];

  const onDocumentLoadSuccess = value => {
    const { numPages } = value;
    setTotalPages(numPages);
    setPageNumber(1);
    onGetHeight();
  };

  const renderResume = () => {
    return (
      <div>
        <iframe
          title="Resume"
          src={
            fileExtType === FILE_EXTENSION_TYPE.PDF
              ? talent?.resume
              : `https://view.officeapps.live.com/op/view.aspx?src=${talent?.resume}`
          }
          width="100%"
          height="1000"
        />
      </div>
    );

    // if (fileExtType === FILE_EXTENSION_TYPE.PDF) {
    //   return (
    //     <div
    //       className={
    //         totalPage > 1 ? styles.fixedResumeHeight : styles.resumeHeight
    //       }>
    //       <Document
    //         file={`https:${talent?.resume}`}
    //         onLoadProgress={onGetHeight}
    //         onLoadSuccess={onDocumentLoadSuccess}
    //         onLoadError={error => {
    //           console.log('Document onLoadError', JSON.stringify(error));
    //           setShowPageButton(false);
    //         }}
    //         onSourceError={error => {
    //           console.log('Document onSourceError', JSON.stringify(error));
    //         }}
    //         error={
    //           <p className={styles.errorText}>
    //             Failed to Load Talent&apos;s Resume
    //           </p>
    //         }
    //         noData={<p className={styles.errorText}>No PDF found</p>}
    //         loading={<div />}>
    //         {Array.from(new Array(totalPage), (page, index) => (
    //           <Page
    //             key={`page_${index + 1}`}
    //             pageNumber={index + 1}
    //             scale={5.0}
    //             renderTextLayer={false}
    //             onLoadError={error =>
    //               console.log('Page onLoaderError ', JSON.stringify(error))
    //             }
    //             onRenderError={error =>
    //               console.log('Page onRenderError ', JSON.stringify(error))
    //             }
    //             onGetAnnotationsError={error =>
    //               console.log(
    //                 'Page onGetAnnotationsError ',
    //                 JSON.stringify(error)
    //               )
    //             }
    //             onGetTextError={error =>
    //               console.log('Page onGetTextError ', JSON.stringify(error))
    //             }
    //           />
    //         ))}
    //       </Document>
    //       {/* {showPageButton && (
    //         <div className={styles.pdfButtonContainer}>
    //           <div className={styles.pdfButton}>
    //             <IconButton
    //               disabled={pageNumber <= 1}
    //               onClick={() =>
    //                 setPageNumber(prevPageNumber => prevPageNumber - 1)
    //               }>
    //               <ArrowBackIosIcon />
    //             </IconButton>
    //             <p style={{ marginRight: 4 }}>
    //               {pageNumber || (totalPage ? 1 : '--')} of {totalPage || '--'}
    //             </p>
    //             <IconButton
    //               disabled={pageNumber >= totalPage}
    //               onClick={() =>
    //                 setPageNumber(prevPageNumber => prevPageNumber + 1)
    //               }>
    //               <ArrowForwardIosIcon />
    //             </IconButton>
    //           </div>
    //         </div>
    //       )} */}
    //     </div>
    //   );
    // }

    // if (
    //   fileExtType === FILE_EXTENSION_TYPE.DOC ||
    //   fileExtType === FILE_EXTENSION_TYPE.DOCX
    // ) {
    //   return (
    //     <div>
    //       <iframe
    //         title="Resume"
    //         src={`https://view.officeapps.live.com/op/view.aspx?src=${talent?.resume}`}
    //         width="100%"
    //         height="1000"
    //       />
    //     </div>
    //   );
    // }
  };

  return (
    <div className={styles.pdfContainer}>
      {talent == null || talent == undefined
        ? isSelected === null && loading && <SkeletonLoader />
        : renderResume()}
    </div>
  );
};

export default TalentResume;
