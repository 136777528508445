import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useState } from 'react';
import {
  AccordionTitle,
  ListStyled,
  ULTextStyle,
  UnorderlistStyled
} from './styles';
import { CSEMAIL, Hiredly_Phone_Number } from '../../../../utils/Constants';
import { getCountry } from '../../../../hooks/useRegionalisation';

let questions = [
  {
    question:
      'Why do profiles cost different amounts of Talent Tokens to unlock?',
    answer:
      'Talent Token amounts will vary based on demand. Popular profiles with higher unlock count will require a higher amount of Talent Tokens.'
  },
  {
    question: 'How long will I have access to an unlocked profile?',
    answer:
      'You will have access to unlocked profiles for six months from the day you unlock it. After this period, the profiles return from your Unlocked Tab to the main Talent Search list.'
  },
  {
    question: 'What happens to profiles previously viewed in Active CV Access?',
    answer:
      'Profiles previously viewed in Active CV Access will appear in the Unlocked tab in Talent Search, with a six-month access validity from the date they were viewed in Active CV Access.'
  }
];

const Content = () => {
  return (
    <div
      style={{
        padding: '32px 48px',
        maxHeight: 'calc(100vh - 280px)',
        overflow: 'auto'
      }}>
      <AccordionOne />
      <AccordionTwo />
      <AccordionThree />
      {questions.map((item, index) => {
        return (
          <AccordionFour
            title={item.question}
            detail={item.answer}
            key={index}
          />
        );
      })}

      <div
        style={{
          margin: '30px 0 10px',
          display: 'flex',
          justifyContent: 'center'
        }}>
        <ULTextStyle>
          For further enquiries, please email{' '}
          <a
            href={`mailto:${CSEMAIL[getCountry()]}`}
            style={{
              fontFamily: 'Inter',
              fontweight: '400',
              fontSize: '14px',
              letterSpacing: '0.15px',
              color: '#512acc',
              textDecoration: 'none',
              cursor: 'pointer'
            }}>
            {CSEMAIL[getCountry()]}
          </a>{' '}
          <>
            or call{' '}
            <span
              style={{
                fontFamily: 'Inter',
                fontweight: '400',
                fontSize: '14px',
                letterSpacing: '0.15px',
                color: '#512acc',
                cursor: 'default'
              }}>
              {Hiredly_Phone_Number['MYWithSpace']}
            </span>
          </>
          .
        </ULTextStyle>
      </div>
    </div>
  );
};

export default Content;

const AccordionOne = () => {
  const [accordionExpanded, setAccordionExpanded] = useState(true);

  const toggleAcordion = () => {
    setAccordionExpanded(prev => !prev);
  };

  return (
    <Accordion
      disableGutters
      sx={{
        boxShadow: 'none',
        borderBottom: '1px solid #efefef'
      }}
      expanded={accordionExpanded}>
      <AccordionSummary
        expandIcon={<ChevronDownIcon width={'20px'} height={'20px'} />}
        onClick={toggleAcordion}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          minHeight: '30px !important',
          padding: '5px 0',
          '& .Mui-expanded': {
            margin: '0 !important'
          }
        }}>
        <AccordionTitle expanded={accordionExpanded}>
          What is Talent Search?
        </AccordionTitle>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingInline: '0' }}>
        <UnorderlistStyled>
          <ListStyled>
            <ULTextStyle>
              Talent search is our new and improved feature that allows
              employers to connect directly to our pool of talented individuals
              with job opportunities.
            </ULTextStyle>
          </ListStyled>

          <ListStyled>
            <ULTextStyle>
              Jobseeker profiles will appear as a preview that shows their Job
              Title, Specialisation, Industry, Highest Education Level and
              Skills. 
            </ULTextStyle>
          </ListStyled>

          <ListStyled>
            <ULTextStyle>
              Unlocking a profile enables you to reach out to a jobseeker
              directly by gaining access to their full profile, resume, and also
              Talent Search features (Chat, Notes)
            </ULTextStyle>
          </ListStyled>
        </UnorderlistStyled>
      </AccordionDetails>
    </Accordion>
  );
};

const AccordionTwo = () => {
  const [accordionExpanded, setAccordionExpanded] = useState(false);

  const toggleAcordion = () => {
    setAccordionExpanded(prev => !prev);
  };

  return (
    <Accordion
      disableGutters
      sx={{
        boxShadow: 'none',
        borderBottom: '1px solid #efefef'
      }}
      expanded={accordionExpanded}>
      <AccordionSummary
        expandIcon={<ChevronDownIcon width={'20px'} height={'20px'} />}
        onClick={toggleAcordion}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          minHeight: '30px !important',
          padding: '5px 0',
          '& .Mui-expanded': {
            margin: '8px 0'
          }
        }}>
        <AccordionTitle expanded={accordionExpanded}>
          How is Talent Search different from Active CV Access?
        </AccordionTitle>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingInline: '0' }}>
        <ULTextStyle>
          Talent Search offers several upgrades, including:
        </ULTextStyle>
        <UnorderlistStyled>
          <ListStyled $padding={'0'}>
            <ULTextStyle>
              Access to as many jobseeker profiles as you need using Talent
              Tokens instead of restricting it to a certain number of monthly
              views
            </ULTextStyle>
          </ListStyled>

          <ListStyled $padding={'0'}>
            <ULTextStyle>The ability to bookmark profiles</ULTextStyle>
          </ListStyled>

          <ListStyled $padding={'0'}>
            <ULTextStyle>Refined search options and filters</ULTextStyle>
          </ListStyled>

          <ListStyled $padding={'0'}>
            <ULTextStyle>
              New chat and note features to help streamline the recruiting
              process
            </ULTextStyle>
          </ListStyled>
        </UnorderlistStyled>
      </AccordionDetails>
    </Accordion>
  );
};

const AccordionThree = () => {
  const [accordionExpanded, setAccordionExpanded] = useState(false);

  const toggleAcordion = () => {
    setAccordionExpanded(prev => !prev);
  };

  return (
    <Accordion
      disableGutters
      sx={{
        boxShadow: 'none',
        borderBottom: '1px solid #efefef'
      }}
      expanded={accordionExpanded}>
      <AccordionSummary
        expandIcon={<ChevronDownIcon width={'20px'} height={'20px'} />}
        onClick={toggleAcordion}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          minHeight: '30px !important',
          padding: '5px 0',
          '& .Mui-expanded': {
            margin: '8px 0'
          }
        }}>
        <AccordionTitle expanded={accordionExpanded}>
          How do I unlock a profile?
        </AccordionTitle>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingInline: '0' }}>
        <ULTextStyle>
          Profiles can only be unlocked using Talent Tokens, which can be
          purchased from the{' '}
          <a
            style={{
              cursor: 'pointer',
              color: 'rgba(0, 0, 0, 0.6)',
              textDecoration: 'none',
              display: 'inlineBlock',
              borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
              paddingBottom: '2px'
            }}
            href={'/purchase/choose'}
            target="_blank">
            Product Pricing Page
          </a>
          .
        </ULTextStyle>
      </AccordionDetails>
    </Accordion>
  );
};

const AccordionFour = ({ title, detail }) => {
  const [accordionExpanded, setAccordionExpanded] = useState(false);

  const toggleAcordion = () => {
    setAccordionExpanded(prev => !prev);
  };

  return (
    <Accordion
      disableGutters
      sx={{
        boxShadow: 'none',
        borderBottom: '1px solid #efefef'
      }}
      expanded={accordionExpanded}>
      <AccordionSummary
        expandIcon={<ChevronDownIcon width={'20px'} height={'20px'} />}
        onClick={toggleAcordion}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          minHeight: '30px !important',
          padding: '5px 0',
          '& .Mui-expanded': {
            margin: '8px 0'
          }
        }}>
        <AccordionTitle expanded={accordionExpanded}>{title}</AccordionTitle>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingInline: '0' }}>
        <ULTextStyle>{detail}</ULTextStyle>
      </AccordionDetails>
    </Accordion>
  );
};
