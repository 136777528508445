import { FormControl, InputLabel, Select } from '@mui/material';
import React from 'react';

const SharedSelect = ({
  labelId,
  id,
  value,
  label,
  onChange,
  sx,
  children,
  inputLabel,
  MenuProps
}) => {
  return (
    <FormControl>
      {inputLabel}
      <Select
        sx={sx}
        MenuProps={MenuProps}
        labelId={labelId}
        id={id}
        value={value}
        label={label}
        onChange={onChange}>
        {children}
      </Select>
    </FormControl>
  );
};

export default SharedSelect;
