import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, PopUpDialog } from '../../../../components';
import { MUIButtonStyled } from '../../../../components/MUIButton';
import { Dialog, Divider, Grid } from '@mui/material';
import { XMarkIcon } from '@heroicons/react/24/solid';
import {
  updateSelectedFilter,
  updateSelectedFilterValue,
  viewPackageSubscriptionDetails
} from '../../../../redux/actions/job_action';
import store from '../../../../redux/stores/store';
import {
  DialogActionsStyled,
  DialogContentStyled,
  DialogNoteStyled,
  DialogTitleStyled,
  ButtonContinueEdit,
  ButtonSkipEdit,
  DialogButton,
  IconButtonStyled,
  KeywordTokenToggle,
  SwitchStyled
} from './styles';
import { ErrorToast, SuccessToast } from '../../../../utils/ToastUtils';
import {
  mutateCreateJob,
  updateDraftJobState,
  changeJobStatus,
  updateJobsCurrentPage,
  fetchJobs,
  resetAvailableResources,
  updateTempJobDraft
} from '../../../../redux/actions/job_action';
import { mutateCart } from '../../../../redux/actions/order_action';

const JobOptionPopUpHome = ({
  open,
  handleClose,
  handleNextPage,
  jobId,
  jobData,
  productData,
  setSwitchStatus,
  getCompanyAccountInfo
}) => {
  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const jobTitle = jobData?.jobType?.title;
  const keywordActive = jobData?.keywordHighlightText;
  const jobsCurrentPage = useSelector(state => state.jobs.jobsCurrentPage);

  const keywordHighlightTokensUsableCount = useSelector(
    state =>
      state.jobs.subscribedKeywordHighlightTokensPackage?.total_usable_quantity
  );

  const enableKeywordState = jobData?.keywordHighlight;

  const [switchValue, setSwitchValue] = useState(() => {
    if (jobData?.keywordHighlight !== undefined) {
      return jobData.keywordHighlight;
    } else {
      return keywordActive?.length > 0 && keywordHighlightTokensUsableCount > 0;
    }
  });

  const getCreateJobMessage = res => {
    let jobMessage = 'Job Posted Successfully!';

    if (res?.data?.createJob?.job?.freeInternshipJob) {
      jobMessage =
        'Internship posting applied. This posting will expire after 1 month.';
    } else if (res?.data?.createJob?.job?.freeSeniorJob) {
      jobMessage =
        'Free senior job posting applied. This posting will expire after 2 months.';
    }

    return jobMessage;
  };

  const onConfirmButton = async (values, jobTitle, jobType) => {
    try {
      let resource =
        jobData.jobType.id === '4' ? 'internship' : 'free_super_senior';

      let newSwitchValue;
      if (enableKeywordState) {
        if (jobData?.resourceType == 'job_slot_max') {
          newSwitchValue = false;
        } else {
          newSwitchValue = true;
        }
      } else {
        newSwitchValue = values;
      }

      const params = {
        active: true,
        jobId: jobId,
        archiveChat: false,
        deactivateReason: '',
        otherReason: '',
        resourceType: resource,
        keywordHighlightToken: newSwitchValue
      };

      dispatch(changeJobStatus(params)).then(res => {
        const totalInactiveJobsCount =
          store.getState().jobs.totalJobsCount['inactive'];
        const searchJobValue = store.getState().jobs.searchJobValue;
        const selectedFilter = store.getState().jobs.selectedFilter;

        if (res.type == 'CHANGE_JOB_STATUS_SUCCEED') {
          const updatedCount = totalInactiveJobsCount - 1;
          const numOfJobsToCurrentPage = (jobsCurrentPage - 1) * 5;
          const nextCurrentPage =
            updatedCount > numOfJobsToCurrentPage
              ? jobsCurrentPage - 1
              : jobsCurrentPage;

          dispatch(updateJobsCurrentPage(nextCurrentPage));

          const offset = nextCurrentPage > 1 ? (nextCurrentPage - 1) * 5 : 0;

          const params = {
            first: 5,
            offset: offset,
            getCount: true,
            jobTitle: searchJobValue,
            ...{ isActive: selectedFilter === 'All' ? null : selectedFilter },
            orders: null
          };

          dispatch(updateSelectedFilterValue('active'));
          dispatch(updateSelectedFilter(true));

          dispatch(fetchJobs(params));
          dispatch(resetAvailableResources()).then(() => {
            dispatch(viewPackageSubscriptionDetails());
          });

          if (getCompanyAccountInfo) {
            dispatch(getCompanyAccountInfo());
          }
          SuccessToast('Job has been activated!');
        } else {
          ErrorToast(res?.error ? res?.error : 'Failed to update job!');
        }
        handleClose();
      });
    } catch (err) {
      ErrorToast(err.message);
    }
  };

  const handleNext = async () => {
    handleClose();
    handleNextPage();
  };

  const tokenCountIntern = productData?.availableInternshipsTokenCount;
  const tokenCountSenior = productData?.availableFreeSuperSeniorPostCount;

  const getJobType = () => {
    if (jobData?.resourceType?.includes('internship')) {
      return 'internship';
    } else if (jobData?.resourceType?.includes('job_slot')) {
      return 'job_slot';
    } else if (jobData?.resourceType?.includes('job_slot_plus')) {
      return 'job_slot_plus';
    } else if (jobData?.resourceType?.includes('job_slot_max')) {
      return 'job_slot_max';
    } else if (jobData?.resourceType?.includes('job_token')) {
      return 'job_token';
    } else if (jobData?.resourceType?.includes('free_super_senior')) {
      return 'free_super_senior';
    } else if (jobData?.resourceType?.includes('unlimited')) {
      return 'unlimited';
    }

    // return 'normal_job';
    return 'free_super_senior';
  };

  let jobType;
  const getTitle = () => {
    jobType = getJobType();

    if (productData?.unlimitedToken == true) {
      if (jobType == 'internship') {
        return 'Post with Internship Token?';
      } else if (jobType == 'free_super_senior') {
        return 'Post with Senior Post?';
      } else {
        return 'Post Job?';
      }
    }

    switch (jobType) {
      case 'internship':
        {
          if (
            productData?.allowToPostFreeInternshipJob == false &&
            productData?.unlimitedToken == false
          ) {
            if (
              productData?.jobSlotsTotalUsableQuantity > 0 ||
              productData?.jobToken > 0
            ) {
              return 'No Internship Tokens Available';
            }
          } else if (productData?.allowToPostFreeInternshipJob == true) {
            return 'Post with Internship Token?';
          }
        }
        break;
      case 'free_super_senior':
        {
          if (
            productData?.allowToPostSuperSeniorJob == false &&
            productData?.unlimitedToken == false
          ) {
            if (
              productData?.jobSlotsTotalUsableQuantity > 0 ||
              productData?.jobToken > 0
            ) {
              return 'No Free Senior Post Tokens Available';
            }
          } else if (productData?.allowToPostSuperSeniorJob == true) {
            return 'Post with Senior Post?';
          }
        }
        break;
    }

    if (
      productData?.unlimitedToken == false &&
      productData?.jobSlotsTotalUsableQuantity == 0 &&
      productData?.jobToken == 0 &&
      productData?.availableJobCreditCount == 0 &&
      productData?.jobSlotsMaxTotalUsableQuantity == 0 &&
      productData?.jobSlotsPlusTotalUsableQuantity == 0
    ) {
      // Safe to assume the employer no longer has any product to activate this job?
      // To be tested
      return 'No products available';
    }

    return 'Activate Job?';
  };

  const getMessage = () => {
    if (productData?.unlimitedToken == true) {
      return 'You will activate this job with Unlimited Job Token.';
    }

    switch (getJobType()) {
      case 'internship':
        {
          if (
            productData?.allowToPostFreeInternshipJob == false &&
            productData?.unlimitedToken == false
          ) {
            if (productData?.jobSlotsTotalUsableQuantity > 0) {
              return 'Would you like to activate with a Job Slot instead?';
            } else if (productData?.availableJobCreditCount > 0) {
              return `Would you like to activate with a Job Credit instead?`;
            } else if (productData?.jobToken > 0) {
              return 'Would you like to activate with a Job Token instead?';
            }
          } else if (productData?.availableInternshipsTokenCount > 0) {
            return `Activating this internship job post will use up an unused Internship Token. You have ${productData.availableInternshipsTokenCount} unused Internship Token left, do you want to proceed?`;
          }
        }
        break;
      case 'free_super_senior':
        {
          if (
            productData?.allowToPostSuperSeniorJob == false &&
            productData?.unlimitedToken == false
          ) {
            if (productData?.jobSlotsTotalUsableQuantity > 0) {
              return 'Would you like to activate with a Job Slot instead?';
            } else if (productData?.availableJobCreditCount > 0) {
              return `Would you like to activate with a Job Credit instead?`;
            } else if (productData?.jobToken > 0) {
              return 'Would you like to activate with a Job Token instead?';
            }
          } else if (productData?.availableFreeSuperSeniorPostCount > 0) {
            return `Activating this senior job post will use up an unused Free Senior Post. You have ${productData.availableFreeSuperSeniorPostCount} unused Free Senior Post left, do you want to proceed?`;
          }
        }
        break;
    }

    if (
      productData?.unlimitedToken == false &&
      productData?.jobSlotsTotalUsableQuantity == 0 &&
      productData?.jobToken == 0 &&
      productData?.availableJobCreditCount == 0 &&
      productData?.jobSlotsMaxTotalUsableQuantity == 0 &&
      productData?.jobSlotsPlusTotalUsableQuantity == 0
    ) {
      // Safe to assume the employer no longer has any product to activate this job?
      // To be tested
      return 'Refine your recruitment journey by purchasing Job Slots!';
    }

    if (productData?.jobSlotsTotalUsableQuantity > 0) {
      return `Activating a job post will use up an unused Job Slot. You have ${productData?.jobSlotsTotalUsableQuantity} unused Job Slot left, do you want to proceed?`;
    } else if (productData?.availableJobCreditCount > 0) {
      return `Activating a job post will use up an unused Job Credit. You have ${productData?.availableJobCreditCount} unused Job Credit left, do you want to proceed`;
    } else if (productData?.jobToken > 0) {
      return `Activating a job post will use up an unused Job Token. You have ${productData?.jobToken} unused Job Token left, do you want to proceed?`;
    }

    return `-`;
  };

  const onPurchaseButtonClicked = async () => {
    window.open('/purchase/choose/?products=keyword_highlight_token-1');
  };

  useEffect(() => {
    dispatch(viewPackageSubscriptionDetails());
  }, []);

  useEffect(() => {
    if (keywordActive?.length > 0 && keywordHighlightTokensUsableCount > 0) {
      setSwitchValue(true);
    }
  }, [jobData]);

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      sx={{ maxWidth: '600px', margin: 'auto' }}>
      <DialogTitleStyled>
        {/* Post with {getJobTitle()}? */}
        {getTitle()}
        <IconButtonStyled onClick={handleClose}>
          <XMarkIcon height={24} width={24} />
        </IconButtonStyled>
      </DialogTitleStyled>
      <Divider />

      {keywordActive?.length > 0 && enableKeywordState !== true && (
        <>
          <KeywordTokenToggle>
            {keywordHighlightTokensUsableCount > 0 ? (
              <>
                <div>Apply one (1) Key Highlights Token.</div>
                <SwitchStyled
                  name="active"
                  checked={switchValue}
                  onChange={() => setSwitchValue(!switchValue)}
                  $width={120}
                  $height={45}
                  $thumbWidth={32}
                  $thumbHeight={32}
                />
              </>
            ) : (
              <div>
                Purchase Key Highlights Token{' '}
                <a
                  onClick={() => onPurchaseButtonClicked()}
                  style={{
                    color: '#FFF',
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}>
                  here
                </a>{' '}
                to activate your keywords now!
                <br />
                <br />
                <p
                  style={{
                    color: 'rgba(255, 255, 255, 0.60)',
                    fontStyle: 'italic',
                    margin: '0',
                    fontSize: '12px',
                    fontWeight: '400'
                  }}>
                  Tip: You can activate this later with a token after the job is
                  posted.
                </p>
              </div>
            )}
          </KeywordTokenToggle>
        </>
      )}

      <DialogContentStyled>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          {jobTitle == 'Internship' ? (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px'
                }}>
                <div>
                  This job is eligible for an Internship Token.
                  <br />
                  <br />
                  You have <b>{tokenCountIntern} Internship Token(s)</b> left.
                </div>

                <DialogNoteStyled>
                  Note: One (1) token will be deducted from your account if you
                  choose to use an Internship Token.
                </DialogNoteStyled>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px'
                }}>
                <div>
                  This job is eligible for a Free Senior Post.
                  <br />
                  <br />
                  You have <b>{tokenCountSenior} Free Senior Post(s)</b> left.
                </div>
                <DialogNoteStyled>
                  {/* Note: You can also choose to post with other products. */}
                  Note: One (1) Free Senior Post will be deducted from your
                  account if you choose to use a Free Senior Post.
                </DialogNoteStyled>
              </div>
            </>
          )}
          <DialogActionsStyled>
            <DialogButton>
              <ButtonContinueEdit onClick={() => handleNext()}>
                Use Other Products
              </ButtonContinueEdit>
              <ButtonSkipEdit
                onClick={() => onConfirmButton(switchValue, jobTitle, jobType)}>
                Confirm
              </ButtonSkipEdit>
            </DialogButton>
          </DialogActionsStyled>
        </div>
      </DialogContentStyled>
    </Dialog>
  );
};

export default JobOptionPopUpHome;
