export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    // Handle any error during localStorage set operation
    console.error('Error setting item in localStorage:', error);
  }
};

export const getLocalStorageItem = key => {
  try {
    const item = localStorage.getItem(key);

    return item ? item : null;
  } catch (error) {
    // Handle any error during localStorage get operation
    console.error('Error getting item from localStorage:', error);
    return null;
  }
};

export const deleteLocalStorageItem = key => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    // Handle any error during localStorage delete operation
    console.error('Error deleting item from localStorage:', error);
  }
};
