import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment
} from 'react';
import { Close } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  Dialog,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery
} from '@mui/material';
import DefaultLayout from '../../layouts/DefaultLayout';
import { hardcodedAddons } from '../../content/products';
import { toMYR } from '../../utils/CurrencyUtils';
import { ErrorToast } from '../../utils/ToastUtils';
import EmptyCartPopUp from './EmptyCartPopUp';
import styles from './index.module.scss';
import _, { isEmpty } from 'lodash';
import {
  BackButtonStyled,
  ContactSalesButton,
  ContactSalesTitle,
  IconButtonStyled,
  PackageGrid,
  PackageItem,
  PackageSectionTitle,
  ProductAddToCartButton,
  ProductBundleChip,
  ProductBundleText,
  ProductPageTitle,
  ProductPrice,
  ProductTitle,
  QuantityButton,
  QuantityText,
  ReviewCartCard,
  AddOnCard,
  CartCard,
  ApplyDiscountButton,
  ContactSalesDesc,
  ProceedPaymentButton,
  ProductDiscountPrice
} from './styles';
import {
  ArrowLeftIcon,
  GiftTopIcon,
  InformationCircleIcon,
  MinusSmallIcon,
  PlusSmallIcon,
  TrashIcon
} from '@heroicons/react/24/solid';
import Colors from '../../styles/colors.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateContactSalesPopupStatus,
  updateSubjectType
} from '../../redux/actions/job_action';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import { productCodeForCE } from '../../utils/Constants';
import {
  CartProductIconSkeleton,
  CatTitleSkeleton,
  NoWidthSkeleton,
  ProductSkeleton,
  ReviewCartItemSkeleton
} from './Skeletons/styles';
import { fetchProductsByCategory } from '../../redux/actions/product_action';
import {
  fetchCart,
  fetchCartApplicableVouchers,
  mutateCart,
  mutateCartQuantity,
  mutateCartRemove,
  mutateCartSummary,
  mutateCheckoutOrder
} from '../../redux/actions/order_action';
import DiscountVouchersPopUp from './DiscountVouchersPopUp';
import TokenChip from '../../components/TokenChip';
import ProductCard from './ProductCards';
import { ProductCardContainer } from './ProductTabs/styles';
import MoreInfoDialog from './MoreInfoDialog';
import { CustomTooltip } from './ProductCards/styles';
import CompanyInfoPopUp from './CompanyInfoPopUp';
import {
  fetchAccountInfo,
  fetchMyCompanySettings
} from '../../redux/actions/company_actions';
import {
  getCountry,
  getCurrency,
  getTaxLabel
} from '../../hooks/useRegionalisation';
import { countryCodeOptions } from '../../components/SharedSelectCountry/constants';

export default function OrderConfirm() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [addons, setAddons] = useState([]);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [removingItem, setRemovingItem] = useState(false);
  const [cart, setCart] = useState([]);
  const [hasUniversum, setHasUniversum] = useState(false);
  const [subtotalAmount, setSubtotalAmount] = useState(0);
  const [discountCode, setDiscountCode] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [cartLoading, setCartLoading] = useState(false);
  const [isVoucherPopUpOpen, setIsVoucherPopUpOpen] = useState(false);
  const [vouchers, setVouchers] = useState([]);
  const [cartSummary, setCartSummary] = useState(null);
  const [cartProductQuantity, setCartProductQuantity] = useState({});
  const [showCompanyInfo, setShowCompanyInfo] = useState(false);
  const [showAddOn, setShowAddOn] = useState(false);

  useEffect(() => {
    dispatch(fetchProductsByCategory());
    dispatch(fetchCartApplicableVouchers());
    dispatch(fetchCart());
    dispatch(fetchMyCompanySettings());
  }, []);

  const mutatingCart = useSelector(state => state.orders.mutatingCart);
  const allProducts = useSelector(state => state.products.allProducts);
  const fetchingActiveProducts = useSelector(
    state => state.products.fetchingActiveProducts
  );
  const cartApplicableVouchers = useSelector(
    state => state.orders.cartApplicableVouchers
  );
  const myCart = useSelector(state => state.orders.myCart);
  const fetchingMyCart = useSelector(state => state.orders.fetchingMyCart);
  const productsInBundle = useSelector(
    state => state.products.productsInBundle
  );

  const companySettings = useSelector(
    state => state.companies.myCompanySettings
  );

  const cartRef = useRef();
  cartRef.current = cart;

  const refetchCart = () => {
    dispatch(fetchCart());
  };

  useEffect(() => {
    setVouchers(cartApplicableVouchers);
  }, [cartApplicableVouchers]);

  useEffect(() => {
    (async function getCartSummaryData() {
      if (myCart) {
        if (myCart?.cartLists?.edges?.length == 0) {
          history('/purchase/choose', { replace: true });
          // setIsPopUpOpen(true);
        }

        // Decide whether need to show add on panel
        if (myCart?.cartLists?.edges) {
          let hasUniversum = false;
          let hasOther = false;
          myCart.cartLists.edges.map(cartItem => {
            if (cartItem.node?.product?.productCode?.includes('universum')) {
              hasUniversum = true;
            } else {
              hasOther = true;
            }
          });

          setHasUniversum(hasUniversum);

          if (hasUniversum && !hasOther && vouchers) {
            let cVoucher = vouchers.filter(voucher =>
              voucher.promoCode.includes('universum')
            );

            if (cVoucher.length > 0) {
              if (promoCode == '') {
                setPromoCode(cVoucher[0].promoCode);
                return;
              }
            }
          }
        }

        dispatch(
          mutateCartSummary({
            input: {
              cartId: myCart?.id ?? '',
              promoCodeString: promoCode ? promoCode : null
            }
          })
        )
          .then(res => {
            setCartSummary(res?.cartSummary);
            setDiscountCode(promoCode);
          })
          .catch(error => {
            setPromoCode(null);
            setDiscountCode('');
            ErrorToast(error.message);
          });
      }
    })();
  }, [myCart, promoCode]);

  // computed property cartLoading
  useEffect(() => {
    if (mutatingCart || fetchingMyCart) {
      setCartLoading(true);
    } else {
      setCartLoading(false);
    }
  }, [mutatingCart, fetchingMyCart]);

  // manually fetching latest cart on page load. skips Apollo's query cache
  useEffect(() => {
    if (query.get('product')) {
      handleAddToCart({ id: query.get('product') }, 1, query.get('product'));
    } else {
      dispatch(fetchCartApplicableVouchers());
      refetchCart();
    }
  }, []);

  useEffect(() => {
    if (myCart) {
      const newCartData = myCart?.cartLists?.edges.map(item => {
        const {
          product,
          quantity,
          id,
          totalPriceBeforeDiscount,
          totalPriceAfterDiscount
        } = item.node;
        return {
          ...product,
          quantity,
          totalPriceBeforeDiscount,
          totalPriceAfterDiscount,
          cartListId: id // to be used in removeFromCart
        };
      });
      setCart(newCartData);
    }
  }, [myCart]);

  // initialize products list
  useEffect(() => {
    if (allProducts && allProducts?.length > 0) {
      let newProducts = allProducts;

      // Remove added items from recommendation section
      cart?.map(cart => {
        newProducts = newProducts.filter(product => {
          return product?.productCode !== cart?.productCode;
        });
      });

      // Hardcode to remove items from confirm page recommendation section
      const emitProduct = [
        'homepage_featured_company',
        'homepage_carousel_adspace',
        'advice_page_ads',
        'popular_package',
        'enterprise_package'
      ];

      emitProduct?.map(emit => {
        newProducts = newProducts.filter(product => {
          return product?.productCode !== emit;
        });
      });

      // TODO: hardcoded frontend. remove this in the future.
      // combine more hardcoded details like description, etc.
      newProducts = newProducts?.map(product => {
        const hardcodedDetails = hardcodedAddons?.find(
          item => product.productCode === item.productCode
        );
        return {
          ...hardcodedDetails,
          ...product
        };
      });

      newProducts = newProducts?.filter(product => {
        if (product?.quantityAvailable !== 0) {
          return product;
        }
      });

      newProducts.sort((a, b) => a?.id - b?.id); // reorder product sequence

      setAddons(newProducts);
    }
  }, [allProducts, cart]);

  // update subtotal on price change
  useEffect(() => {
    let cCartProductQuantity = {};

    cart?.forEach(item => {
      cCartProductQuantity[item.id] = item.quantity;
    });
    setCartProductQuantity(cCartProductQuantity);
  }, [cart]);

  // triggers updateCartQuantity API. sets product quantity to a specific value
  const setCartQuantity = async (product, value) => {
    let _value = value;

    const cartItem = cartRef.current.find(o => product.id === o.id);

    if (!_value || _value < 0 || cartItem?.quantity == _value) {
      _value = 0;
      return;
    }

    if (cartItem !== undefined) {
      dispatch(
        mutateCartQuantity({
          input: {
            cartListId: cartItem.cartListId,
            quantity: _value
          }
        })
      )
        .then(res => {
          if (!res.updateCartListQuantity?.success) {
            ErrorToast('Failed to change quantity, try refreshing the page.');
          }
          refetchCart();
        })
        .catch(error => {
          ErrorToast(
            'Error adding item to cart: ',
            product.cartListId,
            error.message
          );
        });
    } else {
      ErrorToast('Failed to add item to cart. id: ', product.id);
    }
  };

  // triggers updateCartQuantity API. increments product quantity
  const incrementCart = product => {
    if (product.quantity < 99) {
      setCartQuantity(product, product.quantity + 1);
    }
  };

  // triggers updateCartQuantity API. decrements product quantity
  const decrementCart = product => {
    if (product.quantity > 1) {
      setCartQuantity(product, product.quantity - 1);
    }
  };

  const resetCartQuantity = (item, value) => {
    let cValue = value;

    if (cValue <= 0) {
      cValue = 1;
      settingCartQuantity(item, cValue);
      let cCartProductQuantity = {
        ...cartProductQuantity
      };
      cCartProductQuantity[item.id] = cValue;
      setCartProductQuantity(cCartProductQuantity);
    }
  };

  // triggers addToCart API. adds new product to cart.
  const handleAddToCart = async (product, quantity, product_code) => {
    if (quantity !== 0) {
      const newCart = [...cart];
      const duplicateIndex = newCart.findIndex(o => product.id === o.id);
      if (duplicateIndex >= 0) {
        const newQuantity = newCart[duplicateIndex].quantity + quantity;
        setCartQuantity(product, newQuantity);
      } else {
        dispatch(
          mutateCart({
            input: {
              productId: product.id,
              productCode: product_code,
              quantity: quantity
            }
          })
        )
          .then(res => {
            if (res?.addToCart?.promoCode) {
              setPromoCode(res.addToCart.promoCode);
              setDiscountCode(res.addToCart.promoCode);
            }

            if (!res.addToCart?.success) {
              ErrorToast('Failed to add item to cart.');
            }
            dispatch(fetchCartApplicableVouchers());
            refetchCart();
          })
          .catch(error => {
            ErrorToast(
              'error adding item to cart: ',
              product.cartListId,
              error.message
            );
          });
      }
    }
  };

  // triggers removeFromCart API. completely removes product from cart.
  const handleCartDelete = async item => {
    dispatch(
      mutateCartRemove({
        input: {
          cartListId: item.cartListId
        }
      })
    )
      .then(res => {
        if (!res.removeFromCart?.success) {
          ErrorToast('Failed to remove product, try refreshing the page.');
        }
        refetchCart();
      })
      .catch(error => {
        ErrorToast(error);
      });
  };

  function hasEmptyFields(companySettings) {
    const emptyValues = [null, undefined, ''];

    return emptyValues.some(emptyValue =>
      [
        companySettings?.name,
        companySettings?.registerName,
        companySettings?.industryId,
        companySettings?.typeId,
        companySettings?.sizeId,
        companySettings?.emailToReceiveApplications,
        companySettings?.billingAddress
      ].includes(emptyValue)
    );
  }

  const handleConfirm = async () => {
    const hasEmpty = hasEmptyFields(companySettings);

    if (hasEmpty) {
      setShowCompanyInfo(true);
      return;
    }

    dispatch(
      mutateCheckoutOrder({
        input: {
          promoCodeString: promoCode
        }
      })
    )
      .then(res => {
        if (res?.checkoutOrder?.id) {
          window.dataLayer.push({
            event: 'confirm_order'
          });

          history(
            `/purchase/payment/${res.checkoutOrder.id}/${res.checkoutOrder.cart.id}`
          );
        } else {
          ErrorToast(
            'Failed to check out, please try again or contact an administrator.'
          );
        }
      })
      .catch(error => {
        ErrorToast(error.message);
      });
  };

  const settingCartQuantity = _.debounce((item, value) => {
    setCartQuantity(item, value);
  }, 2000);

  const debouncedSetCartQuantity = useCallback(
    (item, value) => {
      if (value > 0 && value <= 99) {
        settingCartQuantity(item, value);
      }
      let cValue = value;

      if (cValue <= 0) {
        cValue = 0;
      }

      if (cValue <= 99) {
        let cCartProductQuantity = {
          ...cartProductQuantity
        };
        cCartProductQuantity[item.id] = cValue;
        setCartProductQuantity(cCartProductQuantity);
      }
    },
    [cartProductQuantity]
  );

  const below1440 = useMediaQuery('(max-width: 1440px)');

  const [products, setProducts] = useState(addons ?? []);

  const [openStates, setOpenStates] = useState({});
  const toggleItem = (id, state) => {
    setOpenStates(prev => ({
      ...prev,
      [id]: state
    }));
  };

  useEffect(() => {
    let newProducts = addons.map(addon => ({
      ...addon,
      quantity: addon.quantity ?? 1
    }));

    setProducts(newProducts);
  }, [addons]);

  return (
    <DefaultLayout>
      <MoreInfoDialog />
      <DiscountVouchersPopUp
        open={isVoucherPopUpOpen}
        handleClose={() => setIsVoucherPopUpOpen(false)}
        vouchers={vouchers}
        setPromoCode={setPromoCode}
        cart={cart}
        setDiscountCode={setDiscountCode}
      />

      <PackageGrid
        container
        marginX={'auto'}
        flexWrap={'nowrap'}
        padding={'40px 100px'}
        columnGap={'24px'}
        maxWidth={'1440px'}
        columns={12}>
        <PackageGrid item xs={9}>
          <PackageGrid container columnGap={'16px'} alignItems={'center'}>
            <BackButtonStyled
              sx={{ marginBottom: '32px' }}
              onClick={() => {
                history(-1);
              }}>
              <ArrowLeftIcon width={'24px'} height={'24px'} x />
            </BackButtonStyled>
            <ProductPageTitle variant="h1" textAlign="left">
              Review your cart
            </ProductPageTitle>
          </PackageGrid>

          <ReviewCartCard>
            <PackageGrid
              display={'grid'}
              gridTemplateColumns={'3fr 2fr 2fr 2fr 1fr'}
              columnGap={'20px'}
              borderBottom={'1px solid #D8D8D8'}
              justifyItems={'center'}
              padding={'8px 24px'}>
              {cartLoading ? (
                <Fragment>
                  <NoWidthSkeleton
                    sx={{ width: '120px', justifySelf: 'flex-start' }}
                  />
                  <NoWidthSkeleton sx={{ width: '95px' }} />
                  <NoWidthSkeleton sx={{ width: '95px' }} />
                  <NoWidthSkeleton sx={{ width: '95px' }} />
                  <NoWidthSkeleton sx={{ width: '95px' }} />
                </Fragment>
              ) : (
                <Fragment>
                  <PackageGrid justifySelf={'flex-start'}>
                    <ContactSalesTitle>Products</ContactSalesTitle>
                  </PackageGrid>
                  <PackageGrid>
                    <ContactSalesTitle>Price / Unit</ContactSalesTitle>
                  </PackageGrid>
                  <PackageGrid>
                    <ContactSalesTitle>Quantity</ContactSalesTitle>
                  </PackageGrid>
                  <PackageGrid>
                    <ContactSalesTitle>Total</ContactSalesTitle>
                  </PackageGrid>
                  <PackageGrid alignItems={'center'}>
                    <ContactSalesTitle>Action</ContactSalesTitle>
                  </PackageGrid>
                </Fragment>
              )}
            </PackageGrid>
            <PackageGrid sx={{ overflowY: 'auto', padding: '0px 24px' }}>
              {cartLoading ? (
                <PackageGrid>
                  <PackageGrid
                    container
                    padding={'20px 0px'}
                    borderBottom={'1px solid #D8D8D8'}>
                    <ReviewCartItemSkeleton />
                  </PackageGrid>
                  <PackageGrid
                    container
                    padding={'20px 0px'}
                    borderBottom={'1px solid #D8D8D8'}>
                    <ReviewCartItemSkeleton />
                  </PackageGrid>
                  <PackageGrid container padding={'20px 0px'}>
                    <ReviewCartItemSkeleton sx={{ width: '70%' }} />
                  </PackageGrid>
                </PackageGrid>
              ) : (
                cart?.map((item, index) => {
                  // Match the bundle

                  const matchedBundle = productsInBundle?.find(bundle => {
                    return (
                      bundle?.inBundleCode === item?.productCode ||
                      bundle?.productCode === item?.productCode
                    );
                  });

                  const matchedProduct = allProducts.find(
                    product => item?.id === product?.id
                  );

                  // Default available logic
                  let quantityAvailable = matchedProduct?.quantityAvailable;

                  // This logic is only for limited tokens with bundles
                  if (matchedBundle) {
                    // When rendering job slots plus
                    // Get the cart info from exlusive plus if there's any

                    let addedSlotsQuantity;
                    let addedBundleQuantity;
                    let bundleProductCode;
                    const quantityPerBundle = matchedBundle?.quantityPerBundle;

                    // Scenario 1 - render slots, find bundle
                    // Find slots
                    if (item?.packageType !== 'bundle') {
                      addedSlotsQuantity = cart?.find(items => {
                        return items?.productCode === item?.productCode;
                      })?.quantity;

                      // Find the bundle code thru slots code
                      bundleProductCode = productsInBundle?.find(product => {
                        return product?.productCode === item?.productCode;
                      })?.inBundleCode;

                      // Find bundles thru slots
                      addedBundleQuantity = cart?.find(items => {
                        return items?.productCode === bundleProductCode;
                      })?.quantity;

                      addedBundleQuantity = addedBundleQuantity
                        ? addedBundleQuantity
                        : 0;
                    } else {
                      // Scenario 2 - render bundle, find slots
                      // Find bundle
                      addedBundleQuantity = cart?.find(items => {
                        return items?.productCode === item?.productCode;
                      })?.quantity;

                      // Find the slot code thru bundle code
                      bundleProductCode = productsInBundle?.find(product => {
                        return product?.inBundleCode === item?.productCode;
                      })?.productCode;

                      // Find slots thru bundle
                      addedSlotsQuantity = cart?.find(items => {
                        return items?.productCode === bundleProductCode;
                      })?.quantity;

                      addedSlotsQuantity = addedSlotsQuantity
                        ? addedSlotsQuantity
                        : 0;
                    }

                    // Find the available amount by deducting slots added individually and added from bundles
                    // Calculation for bundle and slots is separated
                    if (item?.packageType !== 'bundle') {
                      quantityAvailable =
                        quantityAvailable -
                        addedSlotsQuantity -
                        addedBundleQuantity * quantityPerBundle;
                    } else {
                      quantityAvailable = Math.floor(
                        matchedBundle?.quantityAvailable -
                          addedBundleQuantity *
                            matchedBundle?.quantityPerBundle -
                          addedSlotsQuantity
                      );

                      // total available divide by quantity of products in bundle
                      // if matchedBundle?.quantityPerBundle > available, means not enough
                      quantityAvailable =
                        quantityAvailable < matchedBundle?.quantityPerBundle
                          ? 0
                          : Math.floor(
                              quantityAvailable /
                                matchedBundle?.quantityPerBundle
                            );
                    }
                  }

                  const maxCount =
                    quantityAvailable === null ? Infinity : quantityAvailable;

                  const disableMinusButton = item?.quantity <= 1;
                  // DISABLE WHEN
                  // 1. quantity is at 99
                  // 2. quantity in cart is max
                  // 3. available tokens reaches 0
                  const disablePlusButton =
                    item?.quantity === 99 || quantityAvailable === 0;

                  return (
                    <PackageGrid
                      display={'grid'}
                      gridTemplateColumns={'3fr 2fr 2fr 2fr 1fr'}
                      columnGap={'20px'}
                      padding={'20px 0px'}
                      borderBottom={
                        index === cart.length - 1 ? 'none' : '1px solid #D8D8D8'
                      }
                      justifyItems={'center'}
                      alignItems={'center'}>
                      <PackageGrid justifySelf={'flex-start'}>
                        {item.title}
                      </PackageGrid>

                      {/* Price / Unit */}
                      <PackageGrid
                        container
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}>
                        <PackageSectionTitle variant="body1">
                          {/* review cart - if bundle discount applied, use totalPriceAfterDiscount*/}
                          {/* else - display SKU price (no tax) */}
                          {getCurrency(
                            item?.totalPriceBeforeDiscount >
                              item?.totalPriceAfterDiscount
                              ? item?.totalPriceAfterDiscount /
                                  (cartProductQuantity[item.id]
                                    ? cartProductQuantity[item.id]
                                    : 1) || null
                              : item?.priceWithoutTax
                          )}
                        </PackageSectionTitle>
                        {/* review cart - show strikethrough if bundle discount applied */}
                        {item?.totalPriceBeforeDiscount >
                        item?.totalPriceAfterDiscount ? (
                          <ProductDiscountPrice>
                            {getCurrency(
                              item?.totalPriceBeforeDiscount /
                                (cartProductQuantity[item.id]
                                  ? cartProductQuantity[item.id]
                                  : 1) || null
                            )}
                          </ProductDiscountPrice>
                        ) : null}
                      </PackageGrid>

                      <PackageGrid
                        container
                        flexWrap={'nowrap'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        columnGap={'4px'}>
                        <QuantityButton
                          variant="text"
                          onClick={() => decrementCart(item)}
                          disabled={cartProductQuantity[item.id] <= 1}>
                          <MinusSmallIcon
                            color={disableMinusButton ? '#00000038' : 'black'}
                          />
                        </QuantityButton>
                        <CustomTooltip
                          open={openStates[item?.id]}
                          title={
                            disablePlusButton && 'Maximum quantity reached'
                          }
                          placement="top">
                          <QuantityText
                            onBlur={e => {
                              let value = e.target.value
                                ? parseInt(e.target.value, 10)
                                : 0;
                              resetCartQuantity(item, value);
                            }}
                            onChange={e => {
                              let value = e.target.value
                                ? parseInt(e.target.value, 10)
                                : 0;

                              if (value > maxCount + item?.quantity) {
                                toggleItem(item.id, true);

                                setTimeout(() => {
                                  toggleItem(item.id, false);
                                }, 1000);
                              }

                              if (maxCount !== Infinity) {
                                value =
                                  value > maxCount + item?.quantity
                                    ? maxCount + item?.quantity
                                    : value > 99
                                    ? 99
                                    : value;
                              }

                              debouncedSetCartQuantity(item, value);
                            }}
                            value={
                              cartProductQuantity[item.id]
                                ? cartProductQuantity[item.id]
                                : 0
                            }
                            disabled={cartLoading}
                          />
                        </CustomTooltip>
                        <CustomTooltip
                          title={
                            disablePlusButton && 'Maximum quantity reached'
                          }
                          placement="top">
                          <span>
                            <QuantityButton
                              variant="text"
                              onClick={() => incrementCart(item)}
                              disabled={disablePlusButton}>
                              <PlusSmallIcon
                                color={
                                  disablePlusButton ? '#00000038' : 'black'
                                }
                              />
                            </QuantityButton>
                          </span>
                        </CustomTooltip>
                      </PackageGrid>
                      <PackageGrid
                        container
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}>
                        <PackageSectionTitle variant="body1">
                          {getCurrency(item?.totalPriceAfterDiscount)}
                        </PackageSectionTitle>
                        {item?.totalPriceBeforeDiscount >
                        item?.totalPriceAfterDiscount ? (
                          <ProductDiscountPrice>
                            {getCurrency(item?.totalPriceBeforeDiscount)}
                          </ProductDiscountPrice>
                        ) : null}
                      </PackageGrid>

                      <PackageGrid justifyContent={'center'}>
                        <div
                          role="button"
                          tabIndex={0}
                          onKeyDown={() => {}}
                          onClick={() => {
                            if (cart.length == 1) {
                              setCurrentItem(item);
                              setIsPopUpOpen(true);
                            } else if (cart.length > 1) {
                              handleCartDelete(item);
                            }
                          }}
                          style={{
                            cursor: 'pointer'
                          }}
                          className={[
                            styles.shoppingCartDeleteButton,
                            cart.length == 0 || cartLoading
                              ? styles.buttonDisabled
                              : ''
                          ].join(' ')}>
                          <TrashIcon
                            width={'20px'}
                            height={'20px'}
                            color="grey"
                          />
                        </div>
                      </PackageGrid>
                    </PackageGrid>
                  );
                })
              )}
            </PackageGrid>
          </ReviewCartCard>

          {fetchingActiveProducts ||
          (Array.isArray(addons) && addons.length > 0) ? (
            <AddOnCard>
              <PackageGrid
                container
                borderBottom={'1px solid #D8D8D8'}
                padding={'8px 24px'}>
                {fetchingActiveProducts ? (
                  <CatTitleSkeleton
                    sx={{ padding: 0, margin: 0, width: '330px' }}
                  />
                ) : (
                  <PackageSectionTitle>
                    Recommended based on items in your cart
                  </PackageSectionTitle>
                )}
              </PackageGrid>
              <PackageGrid padding={'24px'}>
                <ProductCardContainer
                  confirm={true}
                  products={products}
                  fetchingAllActiveProducts={fetchingActiveProducts}>
                  {fetchingActiveProducts
                    ? Array.from({ length: 3 }, (_, index) => {
                        return (
                          <ProductSkeleton
                            key={index}
                            product={
                              Array.isArray(products) && products?.length === 3
                            }
                          />
                        );
                      })
                    : products?.map((product, index) => {
                        const perYear = product?.productCode.includes('slot');
                        return (
                          <ProductCard
                            perYear={perYear}
                            product={product}
                            confirm={true}
                          />
                        );
                      })}
                </ProductCardContainer>
              </PackageGrid>
            </AddOnCard>
          ) : null}
        </PackageGrid>
        <PackageGrid item xs={3}>
          <CartCard>
            <div
              className={styles.shoppingCartTopSection}
              style={{
                display: 'flex',
                columnGap: '16px',
                alignItems: 'center'
              }}>
              {fetchingMyCart ? (
                <NoWidthSkeleton sx={{ width: '168px' }} />
              ) : (
                <Fragment>
                  <ShoppingCartIcon width={'20px'} height={'20px'} />
                  <div className={styles.shoppingCartTitle}>Shopping Cart</div>
                </Fragment>
              )}
            </div>

            <PackageGrid padding={'16px'}>
              <PackageGrid
                container
                columnGap={fetchingMyCart ? '8px' : '16px'}
                flexWrap={'nowrap'}
                alignItems={'center'}
                justifyContent={'space-between'}
                marginBottom={'24px'}>
                {fetchingMyCart ? (
                  <Fragment>
                    <CartProductIconSkeleton
                      sx={{ height: '36px', width: '36px' }}
                    />
                    <ReviewCartItemSkeleton sx={{ width: '80%' }} />
                  </Fragment>
                ) : (
                  <Fragment>
                    <IconButton
                      sx={{ padding: '0px', height: '100%' }}
                      disabled={
                        vouchers?.length === 0 ||
                        (hasUniversum && cart.length == 1)
                      }
                      onClick={() => setIsVoucherPopUpOpen(true)}>
                      <Badge
                        badgeContent={
                          hasUniversum && cart.length == 1
                            ? 0
                            : vouchers?.length
                        }
                        color="primary">
                        <GiftTopIcon
                          width={24}
                          height={24}
                          color={
                            vouchers?.length === 0 ||
                            (hasUniversum && cart.length == 1)
                              ? 'grey'
                              : 'black'
                          }
                        />
                      </Badge>
                    </IconButton>

                    <TextField
                      variant="outlined"
                      className={styles.discountField}
                      InputProps={{
                        endAdornment:
                          discountCode?.length > 0 ? (
                            <InputAdornment position="end">
                              <IconButtonStyled
                                onClick={e => {
                                  setDiscountCode('');
                                  setPromoCode('');
                                }}>
                                <Close />
                              </IconButtonStyled>
                            </InputAdornment>
                          ) : null
                      }}
                      fullWidth
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        '& .MuiInputBase-root input': {
                          width: '100%',
                          paddingRight: '30px',
                          textOverflow: 'ellipsis'
                        }
                      }}
                      placeholder="Discount Code"
                      value={discountCode}
                      disabled={promoCode != ''}
                      onChange={e => {
                        setDiscountCode(e.target.value);
                      }}
                    />
                  </Fragment>
                )}
              </PackageGrid>

              {fetchingMyCart ? (
                <ReviewCartItemSkeleton sx={{ marginBottom: '24px' }} />
              ) : (
                <ApplyDiscountButton
                  variant="contained"
                  fullWidth
                  sx={{ marginBottom: '24px' }}
                  onClick={() => {
                    setPromoCode(discountCode);
                  }}
                  disabled={discountCode?.length <= 0}>
                  Apply discount code
                </ApplyDiscountButton>
              )}

              <PackageGrid container rowGap={'8px'}>
                {fetchingMyCart ? (
                  <Fragment>
                    <NoWidthSkeleton sx={{ width: '80%' }} />
                    <NoWidthSkeleton sx={{ width: '30%' }} />
                  </Fragment>
                ) : (
                  <Fragment>
                    <PackageGrid
                      container
                      justifyContent={'space-between'}
                      alignItems={'center'}>
                      <ProductTitle sx={{ color: 'black' }}>
                        Subtotal (excl.tax)
                      </ProductTitle>
                      <ProductTitle sx={{ color: 'black' }}>
                        {getCurrency(cartSummary?.totalAmount)}
                      </ProductTitle>
                    </PackageGrid>
                    <PackageGrid
                      container
                      justifyContent={'space-between'}
                      alignItems={'center'}>
                      <ContactSalesDesc sx={{ color: '#BE4242' }}>
                        Savings
                      </ContactSalesDesc>
                      <ContactSalesDesc sx={{ color: '#BE4242' }}>
                        {/* TODO: add savings from the new API */}
                        {`-${getCurrency(cartSummary?.packageDiscountAmount)}`}
                      </ContactSalesDesc>
                    </PackageGrid>
                    <PackageGrid
                      container
                      justifyContent={'space-between'}
                      alignItems={'center'}>
                      <ContactSalesDesc sx={{ color: '#BE4242' }}>
                        Applied Discount
                      </ContactSalesDesc>
                      <ContactSalesDesc sx={{ color: '#BE4242' }}>
                        {`-${getCurrency(
                          cartSummary?.discountVoucherAmount
                            ? `${cartSummary.discountVoucherAmount}`
                            : null
                        )}`}
                      </ContactSalesDesc>
                    </PackageGrid>

                    {cartSummary?.serviceTaxPercentage ? (
                      <PackageGrid
                        container
                        justifyContent={'space-between'}
                        alignItems={'center'}>
                        <ContactSalesDesc>{`${
                          cartSummary?.serviceTaxPercentage
                        }% ${getTaxLabel('remove-incl')}`}</ContactSalesDesc>

                        <ContactSalesDesc>
                          {getCurrency(cartSummary?.taxAmount)}
                        </ContactSalesDesc>
                      </PackageGrid>
                    ) : null}
                  </Fragment>
                )}
              </PackageGrid>

              <Divider sx={{ marginY: '16px' }} />

              <PackageGrid
                container
                justifyContent={'space-between'}
                alignItems={'center'}
                marginBottom={'24px'}>
                {fetchingMyCart ? (
                  <NoWidthSkeleton width={'100%'} />
                ) : (
                  <Fragment>
                    <ProductPrice sx={{ color: 'black', marginBottom: 0 }}>
                      Total Payable
                    </ProductPrice>
                    <ProductPrice sx={{ color: 'black', marginBottom: 0 }}>
                      {getCurrency(cartSummary?.totalIncludingTax)}
                    </ProductPrice>
                  </Fragment>
                )}
              </PackageGrid>

              {fetchingMyCart ? (
                <ReviewCartItemSkeleton />
              ) : (
                <ProceedPaymentButton
                  variant="contained"
                  fullWidth
                  disabled={fetchingMyCart || cart?.length === 0}
                  onClick={handleConfirm}>
                  Proceed to payment
                </ProceedPaymentButton>
              )}
            </PackageGrid>
          </CartCard>
        </PackageGrid>
      </PackageGrid>

      <EmptyCartPopUp
        open={isPopUpOpen}
        handleClose={() => {
          setCurrentItem(null);
          setIsPopUpOpen(false);
        }}
        onConfirm={() => {
          if (currentItem) {
            setRemovingItem(true);
            handleCartDelete(currentItem);
          }
        }}
        removingItem={removingItem}
      />
      <CompanyInfoPopUp
        open={showCompanyInfo}
        handleClose={() => setShowCompanyInfo(false)}
        data={companySettings}
      />
    </DefaultLayout>
  );
}

// NEW UI COMPONENT FROM HERE ONWARDS - ESON
function NewDialogButton({ buttonLabel, children, productCode }) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const getContent = children => {
    if (!children) return null;

    let cChildren = [...children?.props?.children];

    cChildren.splice(0, 1);

    return cChildren;
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const onMoreInfoClicked = () => {
    if (!children) {
      // mean it's one of the new packages
      window.open('/welcome/?section=packages');
      window.dataLayer.push({
        event: 'CE_click-more-info-cart-review',
        section: null,
        item: productCodeForCE[productCode]
      });

      return;
    }

    window.dataLayer.push({
      event: 'CE_click-more-info-cart-review',
      section: null,
      item: productCodeForCE[productCode]
    });

    setDialogOpen(true);
  };

  return (
    <>
      <IconButtonStyled onClick={onMoreInfoClicked}>
        <InformationCircleIcon width={'20px'} height={'20px'} />
      </IconButtonStyled>
      <Dialog
        className={styles.dialogOuter}
        onClose={handleDialogClose}
        open={dialogOpen}
        PaperProps={{
          style: {
            backgroundColor: `${Colors.priWhite}`
          }
        }}>
        <div
          className={styles.dialog}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}>
          <div className={styles.stickyHeader}>
            <div className={styles.dialogTitleIconContainer}>
              <span className={styles.dialogTitle}>
                {children?.props?.children[0]?.props?.children}
              </span>
              <span onClick={handleDialogClose}>
                <Close className={styles.closeIcon} />
              </span>
            </div>
            <Divider
              light
              variant="fullWidth"
              className={styles.dividerStyled}
            />
          </div>
          <div className={styles.contentContainer}>{getContent(children)}</div>
          <div className={styles.stickyCTA}>
            <Divider
              light
              variant="fullWidth"
              className={styles.dividerStyled}
            />
            <div className={styles.buttonContainer}>
              <ContactSalesButton
                onClick={handleDialogClose}
                large
                sx={{ fontWeight: 700 }}>
                Got it
              </ContactSalesButton>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

// SUPER AND PRO JOB SLOT TOKEN COUNT
export const tokenCount = product => {
  if (
    product?.productCode == 'job_slot_max' &&
    product?.quantityAvailable > 0
  ) {
    return (
      <TokenChip disabled={false}>{product?.quantityAvailable} left</TokenChip>
    );
  } else if (
    product?.productCode == 'job_slot_plus' &&
    product?.quantityAvailable > 0
  ) {
    return (
      <TokenChip disabled={false}>{product?.quantityAvailable} left</TokenChip>
    );
  } else {
    return <TokenChip disabled={true}>Sold Out</TokenChip>;
  }
};

export const renderProductBundle = product => {
  const isSpotlightOrKeyword = product => {
    if (
      product?.productCode == 'spotlight_token' ||
      product?.productCode == 'keyword_highlight_token'
    )
      return true;
  };

  if (product.productCode == 'coins_x5000') {
    return (
      <Fragment key={`${product?.productCode}`}>
        <ProductBundleChip>
          <ProductBundleText variant="body1">
            Buy 2 Sets Free 1,000 Tokens
          </ProductBundleText>
        </ProductBundleChip>
        <ProductBundleChip>
          <ProductBundleText variant="body1">
            Buy 3 Sets Free 5,000 Tokens
          </ProductBundleText>
        </ProductBundleChip>
      </Fragment>
    );
  } else {
    return product?.productBundleInfo.map((item, index) => {
      return (
        <ProductBundleChip key={`${item?.productCode}-${item?.token_amount}`}>
          <ProductBundleText variant="body1">
            {item?.token_amount} for{' '}
            {getCurrency(Math.floor(item?.discounted_amount))}
          </ProductBundleText>
        </ProductBundleChip>
      );
    });
  }
};

function renderPackageSection(
  handleAddToCart,
  activeProductLoading,
  products,
  below1440,
  setProducts
) {
  const dispatch = useDispatch();

  const isSpotlightOrKeyword = product => {
    if (
      product?.productCode == 'spotlight_token' ||
      product?.productCode == 'keyword_highlight_token'
    )
      return true;
  };

  const hideHomepageProductsPricing = productCode => {
    if (
      productCode == 'homepage_featured_company' ||
      productCode == 'homepage_carousel_adspace' ||
      productCode == 'advice_page_ads'
    ) {
      return true;
    }

    return false;
  };

  const hideBundleInfo = productCode => {
    if (
      productCode == 'limited_edition_package' ||
      productCode == 'popular_package' ||
      productCode == 'enterprise_package'
    ) {
      return true;
    }

    return false;
  };

  const incrementProduct = index => {
    const newProducts = [...products];

    if (newProducts[index].quantity < 99) {
      newProducts[index].quantity += 1;
      setProducts(newProducts);
    }
  };

  // decrement product count on product list (left)
  const decrementProduct = index => {
    if (products[index].quantity > 1) {
      const newProducts = [...products];
      newProducts[index].quantity -= 1;
      setProducts(newProducts);
    }
  };

  // set product quantity on product list (left)
  const setQuantity = (index, value) => {
    let _value = value;
    if (!_value || _value < 0) {
      _value = 0;
    }

    if (_value <= 99) {
      const newProducts = [...products];
      newProducts[index].quantity = parseInt(_value, 10);
      setProducts(newProducts);
    }
  };

  const resetQuantity = (index, value) => {
    let cValue = value;

    if (!cValue || cValue <= 0) {
      cValue = 1;

      const cProducts = [...products];
      cProducts[index].quantity = parseInt(cValue, 10);
      setProducts(cProducts);
    }
  };

  const onContactUs = productCode => {
    if (productCode == 'homepage_featured_company') {
      dispatch(updateSubjectType('homepage-featured-company'));
    } else if (productCode == 'homepage_carousel_adspace') {
      dispatch(updateSubjectType('homepage-carousel-adspace'));
    } else if (productCode == 'advice_page_ads') {
      dispatch(updateSubjectType('advice-page-ads'));
    }

    dispatch(updateContactSalesPopupStatus(true));
  };

  return (
    <PackageGrid
      display={'grid'}
      gridTemplateColumns={
        products.length === 4 ? 'repeat(4,1fr)' : 'repeat(3, 1fr)'
      }
      gap={'16px'}
      gridAutoRows={'1fr'}>
      {activeProductLoading
        ? Array.from({ length: 3 }, () => <ProductSkeleton />)
        : products.map((product, index) =>
            product.productCode === 'discounted_job_credit' ? null : (
              <PackageItem
                id={product?.id}
                item
                container
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'space-between'}
                width={'100%'}>
                {product?.productCode !== 'homepage_featured_company' &&
                product?.productCode !== 'homepage_carousel_adspace' &&
                product?.productCode !== 'advice_page_ads' ? (
                  <NewDialogButton
                    buttonLabel="MORE INFO"
                    productCode={product?.productCode}>
                    {product?.dialogContent}
                  </NewDialogButton>
                ) : null}

                {product?.productCode == 'job_slot_max' ||
                product?.productCode == 'job_slot_plus' ? (
                  <>{tokenCount(product)}</>
                ) : null}
                <PackageGrid
                  container
                  alignItems={'center'}
                  flexDirection={'column'}>
                  <img
                    className={styles.productIcon}
                    src={product?.productImage}
                    alt={product?.title}
                    width={'80px'}
                    height={'80px'}
                  />

                  <ProductTitle>{product?.title}</ProductTitle>

                  <ProductPrice>
                    {getCurrency(
                      product?.productBundleInfo?.length === 1 &&
                        product?.productBundleInfo[0]?.token_amount === 1
                        ? product?.productBundleInfo[0]?.discounted_amount
                        : product?.price
                    )}
                  </ProductPrice>

                  {product?.productCode == 'job_slot' ||
                  hideHomepageProductsPricing(product?.productCode) ||
                  hideBundleInfo(product?.productCode) ? null : (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        gap: '8px',
                        marginBottom: '16px'
                      }}>
                      {renderProductBundle(product, isSpotlightOrKeyword)}{' '}
                    </div>
                  )}
                </PackageGrid>
                <PackageGrid
                  display={'grid'}
                  gridTemplateColumns={
                    below1440 || products.length === 4
                      ? '1fr'
                      : 'repeat(2, 1fr)'
                  }
                  rowGap={'8px'}
                  width={'100%'}
                  justifyContent={
                    below1440 || products.length === 4
                      ? 'center'
                      : 'space-between'
                  }
                  columnGap={'12px'}
                  flexWrap={'nowrap'}>
                  <PackageGrid
                    container
                    flexWrap={'nowrap'}
                    alignItems={'center'}
                    columnGap={'4px'}
                    justifyContent={
                      below1440 || products.length === 4
                        ? 'center'
                        : 'space-between'
                    }>
                    <QuantityButton
                      variant="text"
                      fullWidth
                      type="number"
                      InputLabelProps={{
                        shrink: false
                      }}
                      onClick={() => decrementProduct(index)}>
                      <MinusSmallIcon color="black" />
                    </QuantityButton>
                    <QuantityText
                      onBlur={e =>
                        resetQuantity(index, parseInt(e.target.value, 10))
                      }
                      onChange={e => {
                        e.target.value = parseInt(e.target.value, 10);
                        setQuantity(index, parseInt(e.target.value, 10));
                      }}
                      value={product?.quantity}
                    />
                    <QuantityButton
                      variant="text"
                      onClick={() => incrementProduct(index)}>
                      <PlusSmallIcon color="black" />
                    </QuantityButton>
                  </PackageGrid>
                  <ProductAddToCartButton
                    variant="contained"
                    disabled={
                      hideHomepageProductsPricing(product?.productCode)
                        ? false
                        : activeProductLoading
                    }
                    onClick={
                      hideHomepageProductsPricing(product?.productCode)
                        ? () => onContactUs(product?.productCode)
                        : () => {
                            window.dataLayer.push({
                              event: 'CE_click-add-to-cart-review',
                              section: null,
                              item:
                                productCodeForCE[product?.productCode] ?? null
                            });
                            handleAddToCart(product, product?.quantity);
                            setQuantity(index, 1);
                          }
                    }>
                    {hideHomepageProductsPricing(product?.productCode)
                      ? 'Contact Us'
                      : 'Add to cart'}
                  </ProductAddToCartButton>
                </PackageGrid>
              </PackageItem>
            )
          )}
    </PackageGrid>
  );
}
