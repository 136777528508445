import { isEmpty, isNull, isNumber } from 'lodash';

export function checkError(formState, requiredFields) {
  let errors = [];

  if (formState['description'] === '<p></p>\n') {
    errors.push('description');
  } else {
    errors.filter(value => value !== 'description');
  }

  if (formState['requirements'] === '<p></p>\n') {
    errors.push('requirements');
  } else {
    errors.filter(value => value !== 'requirements');
  }

  if (formState['stateRegion']?.id === 'placeholder') {
    errors.push('stateRegion');
  } else {
    errors.filter(value => value !== 'stateRegion');
  }

  if (formState['minSalaryRange']?.toString().length < 1) {
    errors.push('minSalaryRange');
  } else {
    errors.filter(value => value !== 'minSalaryRange');
  }

  if (formState['maxSalaryRange']?.toString().length < 1) {
    errors.push('maxSalaryRange');
  } else {
    errors.filter(value => value !== 'maxSalaryRange');
  }

  if (
    parseInt(formState['minSalaryRange']) >
    parseInt(formState['maxSalaryRange'])
  ) {
    errors.push('range');
  } else {
    errors.filter(value => value !== 'range');
  }

  if (
    formState['receiveNotification'] &&
    formState['usingCustomEmail'] &&
    isEmpty(formState['customEmail'])
  ) {
    errors.push('customEmail');
  } else {
    errors.filter(value => value !== 'customEmail');
  }

  if (formState['globalHirePreferences']?.['enabled']) {
    const rightToWorkValid =
      formState['globalHirePreferences']?.['rightToWorkOpenToSponsor'] ||
      formState['globalHirePreferences']?.['rightToWorkLocalCitizen'] ||
      formState['globalHirePreferences']?.['rightToWorkPermanentCitizen'] ||
      formState['globalHirePreferences']?.['rightToWorkWorkVisaOrEmploymentPass'];
  
    const workingArrangementValid =
      formState['globalHirePreferences']?.['workingArrangementHybrid'] ||
      formState['globalHirePreferences']?.['workingArrangementOnSite'] ||
      formState['globalHirePreferences']?.['workingArrangementRemote'];
  
    if (!rightToWorkValid || !workingArrangementValid) {
      errors.push('globalHire');
    } else {
      errors = errors.filter(value => value !== 'globalHire');
    }
  }
  
  // if (!isNumber(formState['minSalaryRange'])) {
  //   errors.push('minSalaryRange');
  // } else {
  //   errors.filter(value => value !== 'minSalaryRange');
  // }

  // if (!isNumber(formState['maxSalaryRange'])) {
  //   errors.push('maxSalaryRange');
  // } else {
  //   errors.filter(value => value !== 'maxSalaryRange');
  // }

  requiredFields
    .filter(error => error !== 'customEmail')
    .forEach(field => {
      if (isEmpty(formState[field]) || isNull(formState[field])) {
        errors.push(field);
      } else {
        errors.filter(value => value !== field);
      }
    });

  return errors;
}
