import { useDispatch, useSelector } from 'react-redux';
import {
  BundleCardsContainer,
  BundleInnerContainer,
  BundlesContainer,
  ButtonStyled,
  DefaultContainer,
  DefaultTypography,
  DetailsButton,
  DetailsContainer,
  InfoCards,
  PurpleTag,
  TickContainer
} from '../styles';
import DoneIcon from '@mui/icons-material/Done';
import { updateContactSalesPopupStatus } from '../../../../../redux/actions/job_action';
import { updateProductBundle } from '../../../../../redux/actions/product_action';
import {
  textFormatter,
  replaceTextWithVariable
} from '../../../../../utils/textFormat';
import { BundleCard } from '../../../../../components/BundleCard';

export const BundlesCardSection = props => {
  const { bundle } = props;
  const dispatch = useDispatch();
  const selectedBundle = useSelector(
    state => state.products.selectedProductBundle
  );

  // Title and Details
  const id = bundle?.id;
  const productCode = bundle?.productCode;
  const title = bundle?.bundleTitle;
  const subTitle = bundle?.bundleSubTitle;
  const prefix = bundle.prefix;
  const detailsChip = bundle?.detailsChip;
  const detailsTitle = bundle?.detailsTitle;
  const detailsSubTitle = bundle?.detailsSubTitle;
  const detailsList = bundle?.detailsList;

  // Info
  const infos = bundle?.info;

  // Getting data from API
  const allActiveProducts = useSelector(
    state => state.products.allActiveProducts
  );

  const jobAds = allActiveProducts.find(product => {
    return product.title === 'Job Ads';
  });

  const products = jobAds?.products;

  const bundlesInfo = products?.find(product => {
    return product?.productCode === bundle?.productCode;
  });

  const productBundleInfo = bundlesInfo?.productBundleInfo;
  const singleSlotPrice = Math.round(
    products?.at(selectedBundle)?.price / 12
  ).toString();

  const formattedTitle = textFormatter(title);
  const formattedDetailsTitle = textFormatter(detailsTitle);
  const formattedDetailsSubTitle = replaceTextWithVariable(
    textFormatter(detailsSubTitle),
    singleSlotPrice
  );

  const BundleTitle = () => {
    return (
      <BundleInnerContainer>
        <DefaultTypography
          sx={{
            fontSize: '16px',
            fontWeight: '700'
          }}>
          <div dangerouslySetInnerHTML={{ __html: formattedTitle }} />
        </DefaultTypography>
        <DefaultContainer sx={{ fontSize: '16px', fontWeight: '400' }}>
          {subTitle}
        </DefaultContainer>
      </BundleInnerContainer>
    );
  };

  const InfoCard = props => {
    const { text, icon } = props.info;

    const formattedText = textFormatter(text);

    return (
      <InfoCards>
        <DefaultContainer
          container
          sx={{
            height: '72px',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          {icon}
        </DefaultContainer>
        <DefaultContainer sx={{ height: '72px' }}>
          <DefaultTypography sx={{ fontSize: '14px', fontWeight: '400' }}>
            <div dangerouslySetInnerHTML={{ __html: formattedText }} />
          </DefaultTypography>
        </DefaultContainer>
      </InfoCards>
    );
  };

  const BundleDetails = props => {
    const TickComponent = props => {
      const { text } = props;

      const formattedText = textFormatter(text);

      return (
        <TickContainer>
          <DoneIcon sx={{ color: '#512ACC' }} />
          <DefaultTypography sx={{ letterSpacing: '0.15px' }}>
            <div dangerouslySetInnerHTML={{ __html: formattedText }} />
          </DefaultTypography>
        </TickContainer>
      );
    };

    const contactUsHandler = () => {
      dispatch(updateContactSalesPopupStatus(true));
    };

    const viewBundleHandler = () => {
      dispatch(updateProductBundle(selectedBundle + 1));
    };

    return (
      <DefaultContainer>
        {/* tag */}
        <PurpleTag>{detailsChip}</PurpleTag>
        {/* content */}
        <DetailsContainer sx={{ width: '716px' }}>
          {/* top part */}
          <DefaultContainer
            container
            sx={{
              width: '100%',
              flexDirection: 'row',
              gap: '30px',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              flexWrap: 'nowrap'
            }}>
            <DefaultContainer
              container
              sx={{
                flexDirection: 'column',
                width: selectedBundle > 2 ? '478px' : 'auto',
                gap: '2px'
              }}>
              <DefaultTypography
                sx={{
                  fontSize: '14px',
                  fontWeight: '400',
                  letterSpacing: '0.15px'
                }}>
                <div
                  dangerouslySetInnerHTML={{ __html: formattedDetailsTitle }}
                />
              </DefaultTypography>
              <DefaultTypography sx={{ letterSpacing: '0.15px' }}>
                <div
                  dangerouslySetInnerHTML={{ __html: formattedDetailsSubTitle }}
                />
              </DefaultTypography>
            </DefaultContainer>
            {detailsList?.length === 0 && (
              <DetailsButton onClick={() => contactUsHandler()}>
                Contact us
              </DetailsButton>
            )}
          </DefaultContainer>

          {/* bottom part */}
          {detailsList?.length !== 0 && (
            <DefaultContainer
              container
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                gap: '5px',
                flexWrap: 'nowrap'
              }}>
              <DefaultContainer
                container
                sx={{
                  flexDirection: 'column',
                  width: 'auto'
                }}>
                {detailsList?.map(list => {
                  return <TickComponent text={list} />;
                })}
              </DefaultContainer>

              <DefaultContainer sx={{ width: '200px' }}>
                {detailsList?.length > 0 && (
                  <DetailsButton
                    onClick={() =>
                      viewBundleHandler()
                    }>{`View ${detailsChip}`}</DetailsButton>
                )}
              </DefaultContainer>
            </DefaultContainer>
          )}
        </DetailsContainer>
      </DefaultContainer>
    );
  };

  return (
    <BundlesContainer personalized={id === 4} selected={selectedBundle === id}>
      <BundleInnerContainer>
        <BundleTitle />
        <BundleCardsContainer>
          {/* if is personalized section, hide it */}
          {id !== 4 && (
            <BundleCard
              bundle={{
                id: bundlesInfo?.id,
                title: bundlesInfo?.title,
                amount: 1,
                price: `${bundlesInfo?.price / 12}`,
                save: null,
                annual: `${parseInt(bundlesInfo?.price).toLocaleString()}`,
                productCode: bundlesInfo?.productCode,
                infoDialog: false,
                isSlot: true
              }}
            />
          )}

          {productBundleInfo
            ? productBundleInfo
                ?.sort((a, b) => a?.token_amount - b?.token_amount)
                ?.map(bundle => {
                  const price =
                    Number(bundle?.discounted_amount) /
                    Number(bundle?.token_amount);
                  const discountPrice = parseInt(bundle?.discounted_amount);

                  const saved =
                    (bundlesInfo?.price * Number(bundle?.token_amount)) /
                      discountPrice -
                    1;

                  const percentage = saved * 100;
                  const productCode = bundlesInfo?.productCode;

                  return (
                    <BundleCard
                      bundle={{
                        id: bundlesInfo?.id,
                        title: bundlesInfo?.title,
                        amount: bundle?.token_amount,
                        price: `${Math.round(price / 12)}`,
                        save: `${Math.round(percentage)}`,
                        annual: `${parseInt(discountPrice).toLocaleString()}`,
                        productCode: productCode,
                        infoDialog: false,
                        isSlot: true
                      }}
                    />
                  );
                })
            : infos?.map(info => {
                return <InfoCard info={info} />;
              })}
        </BundleCardsContainer>
      </BundleInnerContainer>
      {id === 4 && (
        <DefaultContainer container sx={{ justifyContent: 'center' }}>
          <ButtonStyled
            sx={{ width: '200px' }}
            onClick={() => dispatch(updateContactSalesPopupStatus(true))}>
            Contact us
          </ButtonStyled>
        </DefaultContainer>
      )}
      <BundleDetails />
    </BundlesContainer>
  );
};
