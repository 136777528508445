import { Button } from '@mui/material';
import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../styles/colors.module.scss';

export const ButtonStyled = styling(Button)((props) => ({
  ...(props.type == 'basic' && {
    borderRadius: '26.5px',
    background: Colors.priPurple,
    alignSelf: 'center',
    color: Colors.priWhite,
    fontWeight: 700,
    padding: props.padding? props.padding : '8px 38px',
    textTransform: 'capitalize',
    fontSize: props.font_size ? props.font_size : '14px',
    width: props.width ? props.width : '100%',
    textTransform: props.text_transform ? props.text_transform : 'none',

    '&:hover':{
      background: Colors.priPurple,
    }
  }),

  ...(props.type == 'black' && {
    borderRadius: '8px',
    background: '#353535',
    alignSelf: 'center',
    color: Colors.priWhite,
    fontWeight: 700,
    padding: props.padding? props.padding : '8px 38px',
    textTransform: 'capitalize',
    fontSize: props.font_size ? props.font_size : '14px',
    width: props.width ? props.width : '100%',
    textTransform: props.text_transform ? props.text_transform : 'none',

    '&:hover':{
      background: '#222222',
    }
  }),

  ...(props.type == 'no_border' && {
    borderRadius: '8px',
    background: 'transparent',
    alignSelf: 'center',
    color: Colors.priBlack,
    fontWeight: 700,
    padding: props.padding? props.padding : '8px 38px',
    textTransform: 'capitalize',
    fontSize: props.font_size ? props.font_size : '14px',
    width: props.width ? props.width : '100%',
    textTransform: props.text_transform ? props.text_transform : 'none',

    '&:hover':{
      background: Colors.terLightGrey,
    }
  }),

  ...(props.type == 'outlined_black' && {
    borderRadius: '26.5px',
    background: 'transparent',
    alignSelf: 'center',
    color: Colors.priBlack,
    fontWeight: 700,
    padding: props.padding? props.padding : '4px 25px',
    border: `2px solid ${Colors.priBlack}`,
    textTransform: 'capitalize',
    minWidth: '150px',
    fontSize: props.font_size ? props.font_size : '14px',
    textTransform: props.text_transform ? props.text_transform : 'none',
  }),

  ...(props.type == 'thin_outlined' && {
    borderRadius: '26.5px',
    border: props.border ? props.border : `1px solid ${Colors.priPurple}`,
    background: 'transparent',
    alignSelf: 'center',
    color: Colors.priPurple,
    fontWeight: 700,
    padding: props.padding? props.padding : '8px 38px',
    textTransform: 'capitalize',
    fontSize: props.font_size ? props.font_size : '14px',
    width: props.width ? props.width : '100%',
    textTransform: props.text_transform ? props.text_transform : 'none',
  }),

  ...(props.disabled && {
    borderRadius: props.border_radius ? props.border_radius : '26.5px',
    background: Colors.terLightGrey,
    alignItems: 'center',
    color: props.text_color ? props.text_color : '#FFF',
    fontWeight: 700,
    padding: props.padding ? props.padding : '8px 38px',
    textTransform: 'capitalize',
    fontSize: props.font_size ? props.font_size : '14px',
    textTransform: props.text_transform ? props.text_transform : 'none',

    '&:hover':{
      background: Colors.terLightGrey,
    }
  }),

  ...(props.custom_label == 'view-applicants' && {
    minWidth: '210px',
    color: `${Colors.priWhite} !important`,
  }),
}));
