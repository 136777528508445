import { Switch } from '@mui/material';
import { styled as styling } from '@mui/material/styles';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { Checkbox } from '@mui/material';
import Button from '@mui/material/Button';

export const SwitchStyled = styling(Switch)(props => ({
  width: 46,
  height: 24,
  padding: 0,
  borderRadius: 30,
  '& .MuiSwitch-switchBase': {
    padding: 1,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(21px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        // When is checked
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: 'rgba(81, 42, 204, 0.40);'
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#FFF'
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: '#D3D3D3'
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    opacity: 1,
    backgroundColor: '#D3D3D3',
    '&:before': {
      display: 'flex',
      opacity: props.checked ? 1 : 0,
      color: '#ffffff',
      fontWeight: 'bold',
      transform: 'translate(8px ,4px)'
    },
    '&:after': {
      display: 'flex',
      transform: 'translate(30px, -12px)',
      color: '#ffffff',
      fontWeight: 'bold',
      opacity: props.checked ? 0 : 1
    }
  }
}));

export const MainContainer = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  gap: '24px',
  minHeight: props.isInPopUp ? '300px' : 'unset',
  marginTop: props.isInPopUp ? 'unset' : '16px',

  ...(props.isOverlay && {
    position: 'relative',
    zIndex: 2000,
    background: '#F5F5F5',
    marginTop: '16px',
    borderRadius: '8px 8px 0 0'
  })
}));

export const MainContainerBox = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: '8px'
  // border: '1px solid #D8D8D8',
}));

export const MainBox = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: props.isInPopUp ? '0px' : '16px',
  gap: '16px',
  borderBottom: props.isInPopUp ? 'none' : '1px solid #D8D8D8',
  width: '100%',
  maxWidth: '673px',

  transition: 'background-color 0.3s', // Add transition for smooth effect
  '&:hover': {
    cursor: props.isInPopUp || props.isExpanded ? 'unset' : 'pointer',
    backgroundColor: props.isInPopUp
      ? 'unset'
      : props.isExpanded
      ? 'unset'
      : '#EFEFEF' // Change background color on hover
  }
}));

export const ExpandableBar = styling('div')(props => ({
  display: 'flex',
  // padding: '16px',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  // borderRadius: props.isExpanded ? '8px 8px 0px 0px' : '8px',
  // borderBottom: props.isExpanded ? '1px solid #D8D8D8' : 'none',
  // transition: 'border-bottom 0.3s ease, borderRadius 0.3 ease',
  // background: '#FFF',
  width: '100%'
}));

export const ExpandableBarTitle = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
  flexShrink: '0',
  maxWidth: '597px'
}));

export const Title = styling('div')(props => ({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px'
}));

export const SubTitle = styling('div')(props => ({
  color:
    props.$color == 'black' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.60)',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '20px'
}));

export const ExpandableBarSwitch = styling('div')(props => ({
  display: props.isInPopUp ? 'none' : 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '24px'
}));

export const BottomBox = styling('div')(props => ({
  display: 'flex',
  padding: '16px 0px',
  justifyContent: 'flex-end',
  alignItems: 'center',
  alignSelf: 'stretch'
}));

export const BottomActionBox = styling('div')(props => ({
  display: 'flex',
  width: '328px',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '8px'
}));

export const SkipButton = styling('div')(props => ({
  display: 'flex',
  width: '120px',
  height: '42px',
  padding: '0px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: 'normal',
  cursor: 'pointer'
}));

export const SaveButton = styling(Button)(props => ({
  display: 'flex',
  width: '200px',
  height: '42px',
  padding: '0px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  background: props.disabled ? '#D8D8D8' : '#353535',

  gap: '10px',
  color: '#FFF',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: 'normal',
  cursor: 'pointer',
  textTransform: 'none',

  ':disabled': {
    cursor: 'not-allowed',
    color: '#FFF',
    ':hover': {
      background: '#D8D8D8'
    }
  },
  ':hover': {
    background: '#222222'
  }
}));

export const ChevronDownIconStyled = styling(ChevronDownIcon)(props => ({
  width: '20px',
  height: '20px',
  transition: 'transform 0.3s ease',
  transform: props.isExpanded ? 'rotate(180deg)' : 'none'
}));

export const ArrangementTitle = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '2px',
  alignSelf: 'stretch'
}));

export const ArrangementText = styling('div')(props => ({
  color: props.disabled
    ? 'rgba(0, 0, 0, 0.38)'
    : props.row == '1'
    ? 'rgba(0, 0, 0, 0.87)'
    : 'rgba(0, 0, 0, 0.60)',
  fontSize: props.row == '1' ? '14px' : '12px',
  fontWeight: props.row == '1' ? 700 : 400,
  lineHeight: props.row == '1' ? '24px' : '20px',
  width: '377px',
  letterSpacing: props.row == '1' ? '0.1px' : '0px'
  // height: '23px'
}));

export const CheckboxSection = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px'
}));

export const CheckboxText = styling('div')(props => ({
  color:
    props.type == 'desc'
      ? 'rgba(0, 0, 0, 0.60)'
      : props.type == 'disabled' || props.disabled
      ? 'rgba(0, 0, 0, 0.38)'
      : 'rgba(0, 0, 0, 0.87)',
  fontSize: props.type == 'desc' ? '12px' : '14px',
  fontWeight:
    props.type == 'desc' ? '400' : props.type == 'title2' ? '400' : '500',
  lineHeight: props.type == 'desc' ? '20px' : '24px',
  letterSpacing: props.type == 'desc' ? 'unset' : '0.15px'
}));

export const CheckboxStyled = styling(Checkbox)(props => ({
  padding: '0px',
  margin: '4px 0px',
  color: 'transparent',
  overflow: 'hidden',
  '&.MuiCheckbox-root': {
    borderRadius: '4px',
    border: `1px solid ${props?.isError ? '#F75443' : '#000'}`,
    width: 16,
    height: 16
  },
  '& .MuiSvgIcon-root': {
    fontSize: '18px'
  },

  '&.Mui-checked': {
    color: 'black',
    width: 16,
    height: 16
  },

  '&.Mui-disabled': {
    border: '1px solid #D8D8D8',
    color: '#D8D8D8',
    borderRadius: '4px',
    width: 16,
    height: 16
  },

  '&.MuiCheckbox-indeterminate': {
    color: 'black',
    width: 16,
    height: 16
  },

  '&.MuiCheckbox-root:hover': {
    backgroundColor: 'transparent'
  },
  '& .MuiSvgIcon-root': {
    borderRadius: '4px'
  }
}));

export const BottomAnimationContainer = styling('div')(props => ({
  // maxHeight: props.isExpanded ? '1000px' : '0',
  overflow: 'hidden',
  transition: 'max-height 0.8s ease',
  maxWidth: '640px',
  width: '100%',
  borderRadius: '8px',
  border:
    props.isError && props.isInPopUp
      ? '1px solid #BE4242'
      : props.isInPopUp
      ? '1px solid #D8D8D8'
      : 'none',
  maxHeight: props.isInPopUp ? '313px' : props.isExpanded ? '1000px' : '0',

  '&::-webkit-scrollbar': {
    // width: '8px', // Set scrollbar width
    // backgroundColor: '#FFF',
  },
}));

export const BottomAnimation = styling('div')(props => ({
  // maxHeight: props.isExpanded ? '1000px' : '0',
  overflow: 'hidden',
  transition: 'max-height 0.8s ease',
  maxWidth: '640px',
  width: '100%',
  borderRadius: '8px',
  border:
    props.isError && props.isInPopUp
      ? 'none'
      : props.isInPopUp
      ? 'none'
      : 'none',
  maxHeight: props.isInPopUp ? '313px' : props.isExpanded ? '1000px' : '0',
  overflowY: props.isInPopUp ? 'scroll' : 'auto',

  '&::-webkit-scrollbar': {
    // width: '8px', // Set scrollbar width
    // backgroundColor: '#FFF',
  },
}));

export const BottomSection = styling('div')(props => ({
  display: 'flex',
  width: '100%',
  padding: '24px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '32px',
  borderRadius: '8px',
  border: props.isInPopUp
    ? 'none'
    : props.isError
    ? '1px solid #BE4242'
    : '1px solid #D8D8D8',
  width: '100%',
  maxWidth: '640px',
  background: '#FFF'
}));

export const ArrangementSection = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch',
  borderRadius: '8px'
}));

export const RightsSection = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch'
}));
