import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Dialog, Grid } from '@mui/material';
import {
  ButtonSkipEdit,
  ButtonText,
  DialogActionsStyled,
  DialogContentStyled,
  DialogTitleStyled,
  IconButtonStyled
} from './styles';

const EmptyCartPopUp = ({ open, handleClose, onConfirm, removingItem }) => {
  const onClose = () => {
    handleClose();
  };

  const onConfirmClicked = () => {
    onConfirm();
  };

  return (
    <Dialog open={open}>
      <DialogTitleStyled>
        Remove this product from the Cart?
        <IconButtonStyled onClick={handleClose}>
          <XMarkIcon height={24} width={24} />
        </IconButtonStyled>
      </DialogTitleStyled>
      <DialogContentStyled dividers>
        <Grid>
          <p style={{ marginTop: '0px' }}>
            Removing this item will empty your shopping cart. Clicking 'Confirm
            to Remove' will bring you back to the product selection page.
          </p>
          <DialogActionsStyled>
            <Grid
              container
              sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <ButtonText onClick={() => onClose()}>Close</ButtonText>
              <ButtonSkipEdit onClick={() => onConfirmClicked()}>
                Confirm to Remove
              </ButtonSkipEdit>
            </Grid>
          </DialogActionsStyled>
        </Grid>
      </DialogContentStyled>
    </Dialog>
  );
};

export default EmptyCartPopUp;
