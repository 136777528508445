import { Grid, TextField, Typography } from '@mui/material';
import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../../styles/colors.module.scss'

export const TextFieldStyled = styling(TextField)(
  ({ theme, small, value, select, bold, white, name, isError }) => ({
    width: '100%',

    '& input[type=number]': {
      '-moz-appearance': 'textfield', // Remove arrows in Firefox
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none', // Remove arrows in Chrome, Safari, Edge, Opera
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none', // Remove arrows in Chrome, Safari, Edge, Opera
        margin: 0,
      },
    },

    // Adjust the padding of the input field to change its height
    '& .MuiInputBase-root': {
      fontSize: '0.85rem',
      borderRadius: '8px',

      color: value == 'placeholder' ? '#AEAEAE' : white ? 'white' : 'black', // Text color
      fontWeight: bold && 'bold',

      '& .MuiOutlinedInput-input': {
        padding: '16.5px 14px',
        '&::placeholder': {
          color: '#AEAEAE !important', // Change to desired placeholder color
          opacity: 1 // Ensure the placeholder is fully visible
        }
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: white ? 'white' : isError ? '#BE4242' : '#D8D8D8' // Border color
      },
      '&:hover fieldset': {
        borderColor: white ? 'white' : isError ? '#BE4242' : 'black' // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: white ? 'white' : '#D8D8D8' // Border color on focus
      }
    }
  })
);

export const Text = styling('span')(props => ({
  ...(props.type == 'salary-banner-title' && {
    fontFamily: 'Inter',
    fontSize: '13px',
    fontWeight: 700,
    color: Colors.priWhite
  }),
  ...(props.type == 'salary-banner-description' && {
    fontFamily: 'Inter',
    fontSize: '12px',
    color: Colors.priWhite,
    marginTop: '4px'
  }),
  ...(props.type == 'attention' && {
    fontFamily: 'Inter',
    fontSize: '12px',
    color: Colors.priWhite,
    fontWeight: 700,
    marginTop: '4px'
  }),
  ...(props.type == 'custom-tooltip' && {
    fontSize: '12px',
    fontFamily: 'Inter',
    margin: '0'
  }),
  ...(props.tag == 'spinner' && {
    color: '#FFF'
  }),
  ...(props.tag == 'job-title' && {
    color: '#fff',
    fontWeight: 'bold',
    fontFamily: 'Inter',
    fontSize: '27px',
    margin: '0 20px 0 0'
  }),
  ...(props.tag == 'company-name' && {
    color: 'rgba(255, 255, 255, 0.8)',
    fontFamily: 'Inter',
    fontSize: '18px',
    margin: '0'
  }),
  ...(props.tag == 'location' && {
    color: '#fff',
    fontFamily: 'Inter',
    fontSize: '18px',
    margin: '10px 20px 10px 0'
  }),
  ...(props.tag == 'button-title' && {
    margin: '0',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'Inter',
    transform: 'translateY(1px)'
  }),
  ...(props.tag == 'edit-option' && {
    padding: '10px 0 10px 0',
    margin: '0',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'Inter',
    cursor: 'pointer',
    width: '100%'
  }),
  ...(props.tag == 'dropdown' && {
    margin: '0',
    color: Colors.priBlack,
    fontSize: '16px'
  })
}));

export const TextContainer = styling('div')(props => ({
  ...(props.$container == 'salary-banner-container' && {
    display: 'flex',
    flexDirection: 'column'
  })
}));

export const IconTextContainer = styling('div')(() => ({
  display: 'flex',
  alignItems: 'center'
}));

export const SalaryBanner = styling('div')(() => ({
  width: '100%',
  background: Colors.priPurple,
  borderRadius: '10px',
  marginBottom: '20px',
  padding: '15px 15px',
  position: 'relative',
  '&:before': {
    content: '""',
    position: 'absolute',
    bottom: '-15%',
    borderLeft: '15px solid transparent',
    borderRight: '15px solid transparent',
    borderTop: '20px solid #512acc'
  }
}));

export const SimpleDescription = styling('span')(() => ({
  color: Colors.priBlack,
  opacity: 0.6,
  fontSize: '12px'
}));
