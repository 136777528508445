import React from 'react';
import { Container, Text } from './styles';

export default function Resume(props) {  
  const resume = props?.application?.user?.resume;
  return (
    <Container>
      <div>
        <Text type="normal">Resume</Text>
      </div>
      <div style={{ height: '860px' }}>
        <iframe
          title="Resume"
          src={
            resume?.includes('pdf')
              ? resume
              : `https://view.officeapps.live.com/op/view.aspx?src=${resume}`
          }
          width="100%"
          height="100%"
        />
      </div>
    </Container>
  );
}
