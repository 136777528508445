import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { FilterTitle } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { updateSearchTalentFilters } from '../../../../redux/actions/talent_action';
import { WORKPLACE_TYPE } from '../../../../components/SharedAdvancedFilter/constants';
import { FilterContainerStyle } from '../styles';

const WorkplacePreference = () => {
  const dispatch = useDispatch();

  const filters = useSelector(state => state.talents.searchTalentFilters);

  const onCheckWorkplace = e => {
    const cFilters = { ...filters };

    const value = e.target.value;
    switch (value) {
      case WORKPLACE_TYPE.RELOCATE:
        cFilters.relocate = e.target.checked;
        break;
      case WORKPLACE_TYPE.REMOTE:
        cFilters.remote = e.target.checked;
        break;
    }

    dispatch(updateSearchTalentFilters(cFilters));
  };

  return (
    <FilterContainerStyle>
      <FilterTitle>Availability & Relocation</FilterTitle>

      <FormGroup style={{ margin: '0px 10px 0' }}>
        <CheckboxSelection
          value={WORKPLACE_TYPE.RELOCATE}
          checked={filters['relocate']}
          onClick={onCheckWorkplace}
          label={'Willing to relocate'}
        />
        <CheckboxSelection
          value={WORKPLACE_TYPE.REMOTE}
          checked={filters['remote']}
          onClick={onCheckWorkplace}
          checkboxProps={{ style: { fontSize: 14 } }}
          label={'Remote only'}
        />
      </FormGroup>
    </FilterContainerStyle>
  );
};

export default WorkplacePreference;

export const CheckboxSelection = ({
  value,
  label,
  checked,
  onClick,
  disabled
}) => {
  return (
    <div
      key={label}
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
      <FormControlLabel
        control={
          <Checkbox
            value={value}
            checked={checked}
            onClick={onClick}
            disabled={disabled}
            sx={{
              '&.Mui-checked': {
                color: '#222'
              },
              padding: '2px 9px'
            }}
          />
        }
        label={label}
        sx={{
          '& .MuiTypography-root': {
            fontSize: 14,
            letterSpacing: '0.15px'
          },
          '& .MuiSvgIcon-root': {
            fontSize: 20
          }
        }}
      />
    </div>
  );
};
