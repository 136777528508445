import { styled as styling } from '@mui/material/styles';
import { Button } from '@mui/material';
import { Slider } from '@mui/material';
import {
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon
} from '@heroicons/react/24/outline';

export const ZoomInIconStyled = styling(MagnifyingGlassPlusIcon)({
  height: '24px',
  width: '24px',
  color: '#222222',
  cursor: 'pointer'
});

export const ZoomOutIconStyled = styling(MagnifyingGlassMinusIcon)({
  height: '24px',
  width: '24px',
  color: '#222222',
  cursor: 'pointer',
  marginRight: '8px'
});

export const ZoomSliderStyled = styling(Slider)({
  color: '#D8D8D8',
  width: '291px',
  height: 3,
  '& .MuiSlider-track': {
    border: 'none',
    color: '#512acc'
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: `1px solid #512acc`,
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit'
    },
    '&:before': {
      display: 'none'
    }
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    color: '#222',
    fontWeight: 'bold',
    fontSize: '15px',
    // borderRadius: '50% 50% 50% 0',
    backgroundColor: 'transparent',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, 110%) rotate(-45deg) scale(1)'
    },
    '& > *': {
      transform: 'rotate(45deg)'
    }
  }
});

export const ZoomEditContainer = styling('div')({
  paddingTop: '5px',
  paddingBottom: '5px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '16px'
});

export const ButtonWrapper = styling('div')({
  display: 'flex',
  justifyContent: 'center',
  gap: '100px'
});

export const ButtonContainer = styling('div')({
  // paddingRight: '30px',
});

export const ButtonStyled = styling(Button)(props => ({
  ...(props.type == 'cancel' && {
    borderRadius: '30px',
    border: '1px solid #222',
    alignSelf: 'center',
    color: '#222',
    fontWeight: 550,
    padding: '7px 40px',
    textTransform: 'capitalize',
    minWidth: '200px',
    fontSize: '16px'
  }),

  ...(props.type == 'confirm' && {
    borderRadius: '30px',
    background: '#512acc',
    alignSelf: 'center',
    color: '#fff',
    fontWeight: 550,
    padding: '7.6px 40px',
    textTransform: 'capitalize',
    minWidth: '200px',
    fontSize: '16px'
  }),

  ...(props.type == 'disabled' && {
    borderRadius: '30px',
    alignSelf: 'center',
    fontWeight: 400,
    padding: '5px 20px',
    color: 'rgba(0, 0, 0, 0.26)',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    textTransform: 'capitalize',
    minWidth: '200px',
    fontSize: '16px'
  })
}));

export const CancelButton = styling(Button)(props => ({
  display: 'flex',
  width: '200px',
  height: '36px',
  padding: '0px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '8px',
  border: '1px solid #222',
  background: 'var(--Primary-White, #FFF)',
  textTransform: 'none',
  color: 'black',

  ':hover': {
    background: '#EFEFEF'
  }
}));

export const SaveButton = styling(Button)(props => ({
  display: 'flex',
  width: '200px',
  height: '36px',
  padding: '0px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '8px',
  background: '#353535',
  textTransform: 'none',
  color: 'white',

  ':hover': {
    background: '#222222'
  }
}));
