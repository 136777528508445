import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearUnlockedTalentsParams,
  updateCurrentTalent,
  updateUnlockedCurrentPage
} from '../../../redux/actions/talent_action';
import { SharedPagination } from '../SharedPagination';

let locking = false;

const UnlockedResumePagination = props => {
  const dispatch = useDispatch();
  const { type, triggerLoadTalents } = props;
  const index = useSelector(state => state.talents.unlockedTalentsCurrentPage);
  const loading = useSelector(state => state.talents.fetchingUnlockedTalents);

  const releaseLocking = () => {
    setTimeout(() => {
      // Release locking after 250ms
      locking = false;
    }, 250);
  };

  const onClickLeftArrow = () => {
    if (locking) return;

    if (triggerLoadTalents) {
      locking = true;
      triggerLoadTalents(type, 'prev', index - 1);
      dispatch(updateUnlockedCurrentPage(index - 1));
      releaseLocking();
      dispatch(updateCurrentTalent({}));
    }
  };

  const onClickRightArrow = () => {
    if (locking) return;

    if (triggerLoadTalents) {
      locking = true;
      triggerLoadTalents(type, 'next', index + 1);
      dispatch(updateUnlockedCurrentPage(index + 1));
      releaseLocking();
      dispatch(updateCurrentTalent({}));
    }
  };

  return (
    <SharedPagination
      onClickLeftArrow={onClickLeftArrow}
      onClickRightArrow={onClickRightArrow}
      type={type}
      index={index}
      loading={loading}
    />
  );
};

export default UnlockedResumePagination;
