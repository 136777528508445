import { styled as styling } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Container = styling(Box)(props => ({
  // width: '100vw',
  minHeight: 'calc(100vh - 70px)',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'row',
  margin: 'auto',
  justifyContent: 'center',
  overflow: 'hidden',

  [props.theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    minHeight: 'unset',
    justifyContent: 'unset'
  },

  [props.theme.breakpoints.down('sm')]: {
    marginTop: '-10px'
  }
}));

export const LeftSection = styling(Box)(props => ({
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  maxWidth: '1920px',

  [props.theme.breakpoints.down('md')]: {
    margin: '0 auto'
  }
}));

export const BackgroundDesign = styling('div')(props => ({
  position: 'absolute',
  width: '100%',
  transition: 'width 1s ease-in-out',
  height: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  zIndex: '0',
  overflow: 'hidden',

  [props.theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}));

export const BackLeft = styling('div')(props => ({
  display: 'flex',
  width: props.currentBg == 0 ? '100%' : '50%',
  transition: 'width 1s ease-in-out, background 1s ease-in-out',
  height: '100%',
  background: props.currentBg == 0 ? '#FFF' : 'rgba(215, 174, 229, 0.1)',
  zIndex: '-1',

  [props.theme.breakpoints.down('md')]: {
    width: '100%',
    height: props.currentBg == 0 ? '100%' : '30rem',
    transition: 'height 1s ease-in-out'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '100%',
    height: props.currentBg == 0 ? '100%' : '45vh',
    transition: 'height 1s ease-in-out'
  }
}));

export const BackRight = styling('div')(props => ({
  display: 'flex',
  width: props.currentBg == 0 ? '0%' : '50%',
  transition: 'width 1s ease-in-out',
  background: '#FFF',
  height: '100%',

  [props.theme.breakpoints.down('md')]: {
    width: '100%',
    height: props.currentBg == 0 ? '0rem' : 'calc(100% - 30rem)',
    transform: `translateY(${props.currentBg == 0 ? '100%' : '0%'})`,
    transition: `transform ${
      props.currentBg == 0 ? '2.5s' : '1s'
    } ease-in-out, height 1s ease-in-out`
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '100%',
    height: props.currentBg == 0 ? '0rem' : 'calc(100% - 45vh)',
    transform: `translateY(${props.currentBg == 0 ? '100%' : '0%'})`,
    transition: `transform ${
      props.currentBg == 0 ? '2.5s' : '1s'
    } ease-in-out, height 1s ease-in-out`
  }
}));

export const Ball1 = styling('div')(props => ({
  position: 'absolute',
  transform:
    props.currentBg >= 3
      ? 'translate3d(0, 0, 0)'
      : 'translateX(-25%) translateY(-5%)',
  zIndex: '-1',
  width: props.currentBg >= 3 ? '140%' : '25%',
  height: props.currentBg >= 3 ? '140%' : '30%',
  opacity: props.currentBg >= 3 ? '0.3' : '0.5',
  borderRadius: '50%',
  filter: props.currentBg >= 3 ? 'blur(150px)' : 'blur(50px)',
  background:
    'radial-gradient(circle at 50% 50%,rgba(76, 0, 255, 1), rgba(76, 0, 255, 0))',

  left:
    props.currentBg == 0
      ? '25%'
      : props.currentBg >= 3
      ? '-90%'
      : props.currentBg == 1
      ? '5%'
      : '5%',
  right: 'unset',
  top:
    props.currentBg == 0
      ? '5%'
      : props.currentBg == 1
      ? '70%'
      : props.currentBg >= 3
      ? '-40%'
      : '70%',
  transition:
    'top 1s ease-in-out, left 1s ease-in-out, width 1s ease-in-out, height 1s ease-in-out, filter 1s ease-in-out, transform 1s ease-in-out',
  willChange: 'top, left, width, height, filter, transfrom',

  [props.theme.breakpoints.down('md')]: {
    width: props.currentBg >= 3 ? '150%' : '25%',
    height: props.currentBg >= 3 ? '150%' : '30%',
    filter: props.currentBg >= 3 ? 'blur(150px)' : 'blur(50px)',
    left:
      props.currentBg == 0
        ? '20%'
        : props.currentBg == 1
        ? '20%'
        : props.currentBg >= 3
        ? '-50%'
        : '20%',
    top:
      props.currentBg == 0
        ? '50%'
        : props.currentBg == 1
        ? '0%'
        : props.currentBg >= 3
        ? '-20%'
        : '0%'
  }
}));

export const Ball2 = styling('div')(props => ({
  position: 'absolute',
  bottom: '5%',
  right: '25%',
  transform: 'translateX(25%) translateY(5%)',
  zIndex: '-1',
  width: '25%',
  height: '25%',
  opacity: '0.5',
  borderRadius: '50%',
  filter: 'blur(50px)',
  background:
    'radial-gradient(circle at 50% 50%,rgba(76, 0, 255, 1), rgba(76, 0, 255, 0))',

  right: props.currentBg == 1 ? '50%' : '25%',
  left: 'unset',
  bottom: props.currentBg == 0 ? '5%' : '70%',
  transition: 'bottom 1s ease-in-out, right 1s ease-in-out',
  willChange: 'bottom, right',

  [props.theme.breakpoints.down('md')]: {
    bottom:
      props.currentBg == 0
        ? '50%'
        : props.currentBg == 1
        ? '55%'
        : props.currentBg == 2
        ? '55%'
        : '0%',
    right:
      props.currentBg == 0
        ? '20%'
        : props.currentBg == 1
        ? '20%'
        : props.currentBg == 2
        ? '20%'
        : '20%'
  }
}));
