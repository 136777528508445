import React, { useEffect, useState } from 'react';
import {
  ContainerStyled,
  CoverDesc,
  CoverTitle,
  TitleWrapper
} from '../SocialMediaHighlights/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import IconButton from '../components/IconButton';
import { OutlineInformationIcon, PencilIconStyled } from '../CoverImage/styles';
import VideoCard from './components/VideoCard';
import { useCheckReadyStatus } from './hooks/useCheckReadyStatus';
import { convertStatusText } from '../helper/convertStatusText';
import moment from 'moment';
import { updateUploadedVimeoVideo } from '../../../redux/actions/company_actions';
import sortByStatus from '../helper/sortByStatus';

export default function ViewState(props) {
  const { setView, setInfo } = props;
  const companyProfile = useSelector(state => state.companies.companyProfile);
  const [pending, setPending] = useState([]);
  const [videos, setVideos] = useState();
  const [timing, setTiming] = useState(1000);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateUploadedVimeoVideo([]));
    const companyVideos = companyProfile?.videos?.map((companyVideo, index) => {
      const videoContentWithVimeoIds =
        companyVideo.content?.flatMap(item => {
          if (item.vimeoId) {
            return {
              vimeoId: item.vimeoId,
              id: companyVideo?.id,
              contentId: item?.id
            };
          }
          return [];
        }) || [];

      return {
        ...companyVideo,
        id: companyVideo.id || `${index}${Date.now()}`,
        contentIndex: 0,
        sortOrder: index + 1,
        add: false,
        content:
          companyVideo.content?.map(item => ({
            ...item,
            processing: true,
            status: convertStatusText(item.approvalStatus)
          })) || [],
        videoContentWithVimeoIds
      };
    });

    const checkForPending = companyVideos?.map(video => {
      return { ...video?.videoContentWithVimeoIds };
    });
    const flattenedArray = checkForPending.flatMap(Object.values);

    const showStatusBanner = companyVideos?.map((video, index) => {
      return {
        status: video?.content[0]?.status,
        index: index,
        closed: false
      };
    });

    setPending(flattenedArray);
    setVideos(companyVideos);
  }, []);

  const handleUploadVideo = ({ id, tabId, vimeoId, processing }) => {
    setVideos(prev => {
      return prev.map(video => {
        if (video.id === id) {
          const updatedVideo = {
            ...video,
            content: video.content.map(content => {
              if (content.id === tabId) {
                return {
                  ...content,
                  vimeoId,
                  processing: processing
                };
              }
              return content;
            })
          };
          return updatedVideo;
        }

        return video;
      });
    });
  };

  const handleChangeBanner = ({ index, tab }) => {
    setVideos(prev => {
      const newArray = [...prev];
      const videoIndex = newArray.findIndex(
        video => video.sortOrder === index + 1
      );

      if (videoIndex !== -1) {
        const contentIndex = newArray[videoIndex].content.findIndex(
          content => content?.id === tab
        );

        if (contentIndex !== -1) {
          newArray[videoIndex].content[contentIndex].activeBanner = false;
        }
      }

      return newArray;
    });
  };

  useEffect(() => {
    if (pending?.length === 0) {
      return;
    }

    const oriLength = pending?.length;
    const intervalId = setInterval(async () => {
      const updatedPending = await Promise.all(
        pending.map(async item => {
          const ready = await useCheckReadyStatus(item.vimeoId);
          if (ready || ready === null) {
            handleUploadVideo({
              id: item?.id,
              tabId: item?.contentId,
              vimeoId: item?.vimeoId,
              processing: false
            });
            return null;
          }

          return item;
        })
      );

      const filteredPending = updatedPending.filter(item => item !== null);

      // Update pending array
      setPending(filteredPending);

      if (timing !== 60000) {
        setTiming(60000);
      }

      if (filteredPending.length === 0) {
        clearInterval(intervalId);
      }
    }, timing);

    return () => clearInterval(intervalId);
  }, [pending, handleUploadVideo, setPending]);

  return (
    <ContainerStyled id="Company Videos">
      <TitleWrapper>
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <CoverTitle>Company Videos</CoverTitle>
          <CoverDesc>
            Upload up to 3 videos to give jobseekers more insights to your
            company culture.
          </CoverDesc>
        </Grid>

        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            alignItems: 'center'
          }}>
          <IconButton
            onClick={() => {
              setInfo(true);
            }}
            tooltipTitle="More Information">
            <OutlineInformationIcon />
          </IconButton>

          <IconButton
            onClick={() => {
              setView(false);
            }}
            tooltipTitle="Edit">
            <PencilIconStyled />
          </IconButton>
        </Grid>
      </TitleWrapper>

      <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {sortByStatus({ array: videos })?.map((video, index) => {
          const content = video?.content[0];

          return (
            <VideoCard
              key={video.id}
              content={content}
              index={index + 1}
              handleChangeBanner={handleChangeBanner}
            />
          );
        })}
      </Grid>
    </ContainerStyled>
  );
}
