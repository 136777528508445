import React, { useEffect } from 'react';
import {
  ArrowTopRightIconStyled,
  BannerContainer,
  BannerList,
  BannerListText,
  BannerText,
  CheckBadgeIconStyled,
  CheckList,
  ChecklistContainer,
  ChecklistText,
  DisclaimerText,
  ExclamationCircleIconStyled,
  OutlineCheckStyle,
  SidebarTitle,
  SiderbarContainer,
  SolidCheckStyle,
  ViewCompanyProfile
} from './styles';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { isNull } from 'lodash';
import { openInNewTab } from '../../../../utils/WindowUtils';
import { JOBSEEKER_URL } from '../../../../utils/Constants';

export default function Sidebar() {
  const companyProfile = useSelector(state => state.companies.companyProfile);
  const companyCompletion = useSelector(
    state => state.companies.companyCompletion
  );

  useEffect(() => {
    //
  }, [companyCompletion, companyProfile]);

  const allFields = [
    {
      name: 'Cover Image',
      complete: companyCompletion?.coverImage,
      required: true
    },
    {
      name: 'Company Story',
      complete: companyCompletion?.companyStory,
      required: true
    },
    {
      name: 'Social Media Highlights',
      complete: companyCompletion?.socialMediaHighlights,
      required: false
    },
    {
      name: 'Company Photos',
      complete: companyCompletion?.companyImages,
      required: true
    },
    {
      name: 'Company Videos',
      complete: companyCompletion?.companyVideos,
      required: false
    },
    {
      name: 'Company Benefits',
      complete: companyCompletion?.companyBenefits,
      required: true
    },
    {
      name: 'Company Address',
      complete: companyCompletion?.companyAddress,
      required: false
    }
  ];

  const completeRequired = allFields?.some(item => {
    return item.required && !item.complete;
  });

  const hasIncompleteRequired = completeRequired;
  const dropdownCountry = useSelector(state => state.companies.country);

  const handleCompanyProfileView = () => {
    try {
      openInNewTab(
        `${JOBSEEKER_URL[dropdownCountry]}/companies/${companyProfile.slug}`
      );

      window.dataLayer.push({
        event: 'view-live-company-canvas'
      });
    } catch {}
  };

  return (
    <SiderbarContainer>
      <SidebarTitle>Your Company Profile</SidebarTitle>
      {/* BANNER */}
      <BannerContainer completeRequired={!hasIncompleteRequired}>
        {hasIncompleteRequired ? (
          <ExclamationCircleIconStyled />
        ) : (
          <CheckBadgeIconStyled />
        )}
        <Grid container flexDirection={'column'}>
          <BannerText completeRequired={!hasIncompleteRequired}>
            {hasIncompleteRequired
              ? 'Your company profile is not live yet. Add the following sections to meet the minimum requirement to go live'
              : 'Your company profile is now live. Click the button below to visit your company profile on the Jobseekers’ site.'}
          </BannerText>
          {allFields
            ?.filter(item => {
              return item?.required && !item?.complete;
            })
            .map(item => {
              return (
                <BannerList
                  key={item?.name}
                  onClick={() => {
                    const targetElement = document.getElementById(item?.name);
                    if (targetElement) {
                      const offset = 120;
                      const topPosition =
                        targetElement.getBoundingClientRect().top +
                        window.pageYOffset;
                      window.scrollTo({
                        top: topPosition - offset,
                        behavior: 'smooth'
                      });
                    }
                  }}>
                  <BannerListText>{item?.name}</BannerListText>
                </BannerList>
              );
            })}
        </Grid>
      </BannerContainer>

      <ChecklistContainer>
        {allFields?.map(item => {
          const photos = item?.name === 'Company Photos';
          return (
            <CheckList
              onClick={() => {
                const targetElement = document.getElementById(item?.name);
                if (targetElement) {
                  const offset = 120;
                  const topPosition =
                    targetElement.getBoundingClientRect().top +
                    window.pageYOffset;
                  window.scrollTo({
                    top: topPosition - offset,
                    behavior: 'smooth'
                  });
                }
              }}>
              {item?.complete ? <SolidCheckStyle /> : <OutlineCheckStyle />}
              <ChecklistText complete={item?.complete}>
                {photos ? 'Company Photos x3' : item?.name}
              </ChecklistText>
            </CheckList>
          );
        })}
      </ChecklistContainer>

      <ViewCompanyProfile
        disabled={hasIncompleteRequired || isNull(companyProfile?.slug)}
        onClick={handleCompanyProfileView}>
        View Live Company Profile
        <ArrowTopRightIconStyled />
      </ViewCompanyProfile>

      <DisclaimerText>
        Disclaimer: the company profile will be updated within one hour of the
        latest modifications.
      </DisclaimerText>
    </SiderbarContainer>
  );
}
