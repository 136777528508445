import {
  Divider,
  IconButton,
  Grid,
  Typography,
  ButtonBase
} from '@mui/material';
import { styled as styling } from '@mui/material/styles';
import Colors from '../../../styles/colors.module.scss';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/solid';

export const CardContainer = styling('div')({
  background: Colors.priWhite,
  borderRadius: '4px',
  display: 'flex',
});

export const TalentCardContainer = styling(Grid)(({ 
  $cardSelected 
}) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  cursor: 'pointer',
  borderRadius: '4px',
  '&:hover': {
    outline: $cardSelected
      ? `2px solid ${Colors.priPurple}`
      : `2.5px solid ${Colors.terLightGrey}`,
  },
  ...( $cardSelected && {
    outline: `2px solid ${Colors.priPurple}`,
  }),
}));

export const TopSection = styling('div')({
  padding: '10px 10px 14px 14px',
  width: '100%',
  position: 'relative',
});

export const BottomSection = styling(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 14px',
});

export const CardTitle = styling('span')({
  fontWeight: 700,
  fontSize: '14px',
  letterSpacing: '0.1px',
  fontFamily: 'Inter',
  display: 'flex',
  flexWrap: 'wrap',
});

export const CardSubtitle = styling('span')((props) => ({
  fontSize: '14px',
  letterSpacing: '0.15px',
  fontFamily: 'Inter',
  color: 'rgba(0, 0, 0, 0.87)',
  fontWeight: 400,

  ...(props.type === 'nationality' && {
    fontSize: '12px',
  }),
}));

export const CardSubContentTitle = styling('span')((props) => ({
  fontWeight: 700,
  fontSize: '14px',
  fontFamily: 'Inter',

  ...(props.title == 'highest education level' || props.title === 'educational institute' ? {
    fontSize: '12px',
    minWidth: '170px',
  } : {}),

  ...(props.title == 'educational institute' ? {
    minWidth: '150px',
  } : {}),

  ...(props.title == 'work experience' ? {
    minWidth: '125px',
  } : {}),
}));

export const CardSubContentText = styling('span')({
  fontSize: '12px',
  letterSpacing: '0.15px',
  fontFamily: 'Inter',
  color: 'rgba(0, 0, 0, 0.87)',
});

export const ChipContainer = styling('div')((props) => ({
  padding: '4px 8px',
  background: Colors.terWhite,
  borderRadius: '4px',

  ...(props.$extraCount ? {
    padding: '4px 8px',
  } : {}),
}));

export const ChipText = styling('div')({
  fontSize: '12px',
  color: 'rgba(0, 0, 0, 0.6)',
  fontFamily: 'Inter',
});

export const DividerStyled = styling('div')({
  width: '2px',
  background: Colors.terLighterGrey,
});

export const SecondContainer = styling('div')({
  padding: '16px',
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column',
  flex: 1,
  justifyContent: 'space-around',
  width: '30%',
});

export const SecondContainerTitle = styling('span')({
  fontWeight: 700,
  fontSize: '10px',
  color: 'rgba(0, 0, 0, 0.55)',
  fontFamily: 'Inter',
});

export const SecondContainerSubtitle = styling('span')({
  fontSize: '10px',
  fontFamily: 'Inter',
  color: 'rgba(0, 0, 0, 0.87)',
  fontWeight: 400,
});

export const IconButtonStyled = styling(IconButton)({
  background: 'transparent',
  padding: '4px',

  '&:hover': {
    background: Colors.terLighterGrey,
  },
});

export const DotsIconButtonStyled = styling(IconButton)(({ 
  $backgroundPersist 
}) => ({
  backgroundColor: $backgroundPersist ? Colors.terWhite : 'transparent',

  '&:hover': {
    background: Colors.terWhite,
  },
}));

export const MoreVertIconStyled = styling(MoreVertIcon)((props) => ({
  color: Colors.terDarkerGrey,
  
  '&:hover': {
    cursor: 'pointer',
  },

  ...(props.disabled ? {
    color: Colors.terLightGrey,
  } : {}),
}));

export const ButtonText = styling('span')({
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Inter',
});

export const BadgeStyled = styling('span')(({ 
  type 
}) => ({
  letterSpacing: '1.5px',
  textTransform: 'uppercase',
  background:
    type == 'new' ? 'rgba(81, 42, 204, 0.1)' : 'rgba(242, 175, 41, 0.14',
  borderRadius: '100px',
  padding: '4px 10px',
  fontWeight: 600,
  fontSize: '10px',
  color: type == 'new' ? Colors.priPurple  : '#C0840C',
}));

export const OuterWrapper = styling(Grid)({
  // maxWidth: '1200px',
  width: '100%',
});

export const TypographyStyled = styling(Typography)({
  color: `rgba(0, 0, 0, 0.6)`,
  fontSize: '14px'
});

export const EmptyContainer = styling(Grid)({
  width: '100%',
  marginBottom: '5px'
});

export const UnstyledContainer = styling(Grid)({});

export const SimpleDescription = styling('span')({
  color: `${Colors.priBlack}`,
  fontSize: '12px',
  fontFamily: 'Inter',
  cursor: 'default'
});

// Last refresh
export const LastRefreshedContainer = styling('div')({
  position: 'relative',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: '10px',
  cursor: 'pointer',
  '&::before': {
    content: '""',
    flex: 1,
    borderBottom: `1px solid ${Colors.terLightGrey}`,
  },
  '&::after': {
    content: '""',
    flex: 1,
    borderBottom: `1px solid ${Colors.terLightGrey}`,
  },
});

export const LastRefreshedText = styling(Typography)({
  paddingLeft: '15px',
  color: `rgba(0, 0, 0, 0.38)`,
  fontFamily: 'Inter',
  fontStyle: 'italic',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '20px'
});

export const LastRefreshedIconStyled = styling(ArrowPathIcon)({
  paddingLeft: '9px',
  paddingRight: '15px',
  height: '12px',
  color: 'rgba(0, 0, 0, 0.6)',
});

export const UnreadMessageCountButton = styling(ButtonBase)(({ 
  $count 
}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '4px 8px',
  outline: `1px solid ${Colors.terLightGrey}`,
  background: $count > 0 ? Colors.terLighterGrey : Colors.terLighterGrey,
  borderRadius: '4px',
  cursor: 'pointer',

  ...( $count > 0 ? {
    '&:hover': {
      background: Colors.terLightGrey,
    },
  } : {} ),
}));

export const ChatBubbleLeftRightIconStyled = styling(ChatBubbleLeftRightIcon)(({ 
  $count 
}) => ({
  width: '16px',
  height: '16px',
  color: $count > 0 ? Colors.priPurple : Colors.terMediumGrey,
}));

export const Bubble = styling('span')({
  height: '8px',
  width: '8px',
  background: Colors.secRed,
  borderRadius: '50%',
  position: 'absolute',
  top: '-3px',
  right: '-3px',
});

export const ShortSummaryContainer = styling(Grid)({
  background: '#FAFAFA',
  borderRadius: '4px',
  padding: '10px',
});

export const ShortSummaryTitle = styling('span')({
  fontFamily: 'Inter',
  fontWeight: 700,
  fontSize: '10px',
  letterSpacing: '1.5px',
  color: 'rgba(0, 0, 0, 0.6)',
});

export const ShortSummaryInfo = styling('span')({
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '12px',
  color: 'rgba(0, 0, 0, 0.6)',
  lineHeight: '20px',
});