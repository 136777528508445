import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../../../styles/colors.module.scss';
import { ButtonBase } from '@mui/material';

export const Description = styling('span')({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  letterSpacing: '0.15px',
  color: Colors.priBlack,
});

export const Note = styling('span')({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.6)',
});

export const AshleyRewriteContent = styling('span')({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.87)',
});

export const ButtonBaseStyled = styling(ButtonBase)(( props ) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '2px 8px',
  gap: '6px',
  border: '1px solid #d8d8d8',
  borderRadius: '4px',
  '&:hover': {
    background: Colors.terLighterGrey,
  },
  ...(props.$name == 'copy-button'
    ? {
        minWidth: '141px',
      }
    : {
        minWidth: '118px',
      }),
    ...(props.$copied ||
      (props?.$replacedText && props.$buttonText == 'Replaced!') ||
      (props?.$buttonText == 'Reverted!' && props?.$undo)) && {
      background: 'rgba(144, 185, 56, 0.2',
    },
}));


export const ButtonText = styling('span')((props) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  color: 'rgba(0, 0, 0, 0.6)',
  '&:hover': {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  ...(props.$copied ||
    (props.$replacedText && props.$buttonText == 'Replaced!') ||
    (props.$buttonText == 'Reverted!' && props?.$undo)) && {
    color: 'rgba(0, 0, 0, 0.87)',
  },
}));

export const LottieText = styling('span')({
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.87)',
  marginBottom: '68px',
});

export const TitleContainer = styling('div')({
  padding: '12px 24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const Title = styling('span')({
  fontFamily: 'Inter',
  fontWeight: 700,
  fontSize: '20px',
});

export const ChipContainer = styling('div')((props) => ({
  padding: '2px 8px',
  height: '24px',
  background: 'rgba(33, 61, 181, 0.2)',
  borderRadius: '6px',
  display: 'flex',
  alignItems: 'center',

  ...(props.$name == 'ai' && {
    background: '#fbe7bf',
  }),

  ...(props.$name == 'beta' && {
    background: 'rgba(33, 61, 181, 0.2)',
  }),
}));

export const ChipTag = styling('span')({
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '10px',
  letterSpacing: '1.5px',
  textTransform: 'uppercase',
  color: 'rgba(0, 0, 0, 0.87)',
});
