import jwt from 'jsonwebtoken';
import {
  getLocalStorageItem,
  setLocalStorageItem
} from '../helpers/local_storage_utils';
import store from '../redux/stores/store';
import { getCountry } from './useRegionalisation';

export const useDecodeToken = () => {
  const accessToken = localStorage.getItem('accessToken');
  const country = localStorage.getItem('country');
  const dropdownCountry = store.getState().companies.country;

  // to reduce rerendering
  if (country == dropdownCountry) return;

  if (accessToken != null) {
    const decodedToken = jwt.decode(accessToken);

    if (decodedToken != null) {
      let decodedCountry = decodedToken['origin_country'] ?? '';

      // to reset country field in localstorage and create the field in case it's absent
      if (decodedCountry?.length > 0 && decodedCountry != country) {
        setLocalStorageItem('country', decodedCountry);
        window.location.reload();
      }
    }
  }
};

export const appendCountryToUrl = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const country = getCountry();

  const replaceUrl = searchParams => {
    const newURL = `${window.location.pathname}?${searchParams.toString()}`;

    window.history.replaceState(null, '', newURL);
  };

  const appendSearchParamsToURL = params => {
    for (const [key, value] of Object.entries(params)) {
      searchParams.append(key, value);
    }

    replaceUrl(searchParams);
  };

  const params = {
    gtmCountry: country
  };

  if (searchParams.get('gtmCountry') !== country) {
    searchParams.set('gtmCountry', country);

    replaceUrl(searchParams);
  } else {
    if (searchParams.get('gtmCountry') != country) {
      appendSearchParamsToURL(params);
    }
  }
};
