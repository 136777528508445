import { Collapse, Grid, ListItemButton } from '@mui/material';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HeightSpacer } from '../../../../../components/Spacer/styles';
import { appendBulletPointAndBreakLines } from '../../../../../helpers/data_management';
import { CheckCircleIcon, LockClosedIcon } from '@heroicons/react/24/solid';
import {
  ClickHere,
  ContactContainer,
  TalentContact,
  DividerStyled,
  ExclamationCircleIconText,
  NoResultsWorkExperience,
  NoResultsWorkExperienceText,
  ProfileChip,
  ProfileChipsContainer,
  ProfileChipText,
  ProfileContainer,
  ReportedMessageStyled,
  SectionTitle,
  SubjectDescription,
  SubjectSubtitle,
  SubjectTitle,
  SubjectYear,
  LabelContainer,
  SectionLabel,
  SectionContainer,
  SubSectionContainer,
  SubContactContainer
} from './styles';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationCircleIcon,
  LinkIcon
} from '@heroicons/react/24/outline';
import SharedNotice from '../../../../../components/SharedNotice';
import {
  reportingInformationErrors,
  updateErrorReportingStatus
} from '../../../../../redux/actions/talent_action';
import { ErrorToast, SuccessToast } from '../../../../../utils/ToastUtils';
import store from '../../../../../redux/stores/store';
import { countryOptionsFullName } from '../../../../../components/SharedSelectCountry/constants';
import {
  getCountry,
  getCountryFullName
} from '../../../../../hooks/useRegionalisation';

const ProfilePanel = ({ contentContainerRef }) => {
  const dispatch = useDispatch();
  const talent = useSelector(state => state.talents.talent);
  const isErrorReportingOpen = useSelector(
    state => state.talents.isErrorReportingOpen
  );

  const [height, setHeight] = useState(0);

  const onResize = () => {
    const containerHeight =
      contentContainerRef.current.offsetParent?.clientHeight;
    if (containerHeight) {
      setHeight(containerHeight);
    }
  };

  const onReportError = (talent, section) => {
    let params = {
      userId: talent.id,
      section: section
    };

    const reporting = store.getState().talents.reportingInfoError;

    if (reporting) {
      ErrorToast('reporting, please wait');
      return;
    }

    dispatch(reportingInformationErrors(params)).then(res => {
      if (res.status) {
        SuccessToast('Reported successfully');
      } else if (res.errors?.length > 0) {
        ErrorToast(res.errors[0]?.message);
      } else {
        ErrorToast('error');
      }
    });
  };

  const onCloseErrorReporting = () => {
    dispatch(updateErrorReportingStatus(false));
  };

  const renderEmailMobile = () => {
    // if (!talent?.email && !talent?.mobileNumber) return null;

    return (
      <>
        <SectionContainer>
          <LabelContainer>
            <Grid display="flex" alignItems={'center'}>
              <SectionLabel>Profile</SectionLabel>
            </Grid>
          </LabelContainer>

          <SubSectionContainer>
            {talent?.email && talent?.mobileNumber && (
              <>
                <SubContactContainer>
                  <TalentContact $unlocked>
                    <h5>Email</h5>
                    <SubjectSubtitle>{talent.email}</SubjectSubtitle>
                  </TalentContact>
                  <TalentContact $unlocked>
                    <h5>Phone Number</h5>
                    <SubjectSubtitle>{talent.mobileNumber}</SubjectSubtitle>
                  </TalentContact>
                </SubContactContainer>
              </>
            )}

            <TalentContact>
              <h5>Current Location</h5>
              <SubjectSubtitle>
                {talent.currentLocation || 'Not provided'}
              </SubjectSubtitle>
            </TalentContact>
          </SubSectionContainer>
        </SectionContainer>

        <DividerStyled />
      </>
    );
  };

  const isRelocateAndRemoteTrue = (relocate, remote) => {
    if (relocate && remote) {
      return true;
    }

    return false;
  };

  const getRightToWork = (
    openToSponsor,
    localCitizen,
    visaOrEmploymentPass
  ) => {
    if (openToSponsor || localCitizen || visaOrEmploymentPass) {
      if (openToSponsor) {
        return 'Open to sponsor';
      } else if (localCitizen) {
        return 'Local citizen';
      } else if (visaOrEmploymentPass) {
        return 'Has existing right to work';
      }
    } else {
      return 'Not provided';
    }
  };

  const getDestionationKey = () => {
    const workLocationPreference = talent?.workLocationPreference;

    const keys = Object.keys(workLocationPreference);
    const currentCountry = getCountry();

    // // Filter out the current country
    // const otherCountries = keys.filter(country => country !== currentCountry);

    // if (otherCountries.length > 0) {
    //   return otherCountries[0]; // Return the first country other than the current country
    // }

    if(keys.length > 0){
      return currentCountry;
    }

    return '';
  };

  const renderLocationWorkplace = () => {
    let destinationCountry = getDestionationKey();

    // if (destinationCountry != getCountry() && destinationCountry?.length > 0) {
    //   return;
    // }

    let relocate =
      talent.workLocationPreference[destinationCountry]?.relocate ?? null;
    let remote =
      talent.workLocationPreference[destinationCountry]?.remote ?? null;

    let openToSponsor =
      talent?.rightToWork[destinationCountry]?.openToSponsor ?? null;

    let localCitizen =
      talent?.rightToWork[destinationCountry]?.localCitizen ?? null;

    let visaOrEmploymentPass =
      talent?.rightToWork[destinationCountry]?.visaOrEmploymentPass ?? null;

    const renderWorkLocationPreference = (preference, label, bothTrue) => {
      if (preference) {
        return <SubjectSubtitle $list={bothTrue}>{label}</SubjectSubtitle>;
      }
      return null;
    };

    return (
      <>
        <SectionContainer>
          <LabelContainer>
            <Grid display="flex" alignItems={'center'}>
              <SectionLabel>
                Work Eligibility In {getCountryFullName()}
              </SectionLabel>
            </Grid>
          </LabelContainer>

          <SubSectionContainer>
            {/* {relocate == false && remote == false ? null : (
              <> */}
            <SubContactContainer>
              <TalentContact>
                <h5>Availability & Relocation</h5>

                {relocate == null && remote == null || !relocate && !remote ? (
                  <SubjectSubtitle>Not provided</SubjectSubtitle>
                ) : (
                  <>
                    {renderWorkLocationPreference(
                      relocate,
                      // `Willing to relocate: ${countryOptionsFullName[destinationCountry]}`,
                      `Willing to relocate`,
                      isRelocateAndRemoteTrue(relocate, remote)
                    )}
                    {renderWorkLocationPreference(
                      remote,
                      'Remote work',
                      isRelocateAndRemoteTrue(relocate, remote)
                    )}
                  </>
                )}
              </TalentContact>

              <TalentContact>
                <h5>Right to Work</h5>

                {openToSponsor == null &&
                localCitizen == null &&
                visaOrEmploymentPass == null ? (
                  <SubjectSubtitle>Not provided</SubjectSubtitle>
                ) : (
                  <>
                    <SubjectSubtitle>
                      {getRightToWork(
                        openToSponsor,
                        localCitizen,
                        visaOrEmploymentPass
                      )}
                    </SubjectSubtitle>
                  </>
                )}
              </TalentContact>
            </SubContactContainer>
            {/* </>
            )} */}
          </SubSectionContainer>
        </SectionContainer>

        <DividerStyled />
      </>
    );
  };

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <ProfileContainer $height={height}>
      {renderEmailMobile()}
      {renderLocationWorkplace()}

      {/* report issue */}

      {isErrorReportingOpen && (
        <>
          <SharedNotice
            dividerColor={'#213DB5'}
            contentBackground={'#213DB51A'}
            hasCloseIcon={true}
            onClose={onCloseErrorReporting}
            children={
              <Grid
                display={'flex'}
                flexDirection={'column'}
                gap={'10px'}
                maxWidth={'94%'}>
                <span
                  style={{
                    fontSize: '12px',
                    fontFamily: 'Inter',
                    lineHeight: '20px'
                  }}>
                  The information below is extracted from the talent’s resume
                  with AI technology & may have discrepancies. Unlock their
                  profile for complete & accurate details or{' '}
                  <span
                    style={{
                      fontSize: '12px',
                      fontFamily: 'Inter',
                      fontWeight: 700,
                      cursor:
                        talent?.aiResumeInformationErrors?.length > 0
                          ? 'default'
                          : 'pointer',
                      textDecoration: 'underline',
                      textUnderlineOffset: '2px'
                    }}
                    onClick={
                      talent?.aiResumeInformationErrors?.length > 0
                        ? () => {}
                        : () => onReportError(talent, 'ai_resume')
                    }>
                    Click here
                  </span>{' '}
                  to report any issues.{' '}
                </span>

                {talent?.aiResumeInformationErrors?.length > 0 && (
                  <ReportedMessage
                    iconColor={'#213DB5'}
                    children={
                      <ReportedMessageStyled $textColor={'#213DB5'}>
                        You have reported the issue on{' '}
                        {moment(
                          talent?.aiResumeInformationErrors[0]?.created_at
                        ).format('DD/MM/YYYY')}
                        , thank you!
                      </ReportedMessageStyled>
                    }
                  />
                )}
              </Grid>
            }
          />{' '}
          <HeightSpacer height={'15px'} />
        </>
      )}

      {/* short summary */}
      {talent?.shortSummary && (
        <>
          <ShortSummary /> <DividerStyled />
        </>
      )}

      {/* work experience */}
      {talent?.workingExperiences?.edges?.length > 0 ? (
        <>
          <WorkExperience /> <DividerStyled />
        </>
      ) : (
        <>
          <Grid display="flex" flexDirection={'column'} gap="8px">
            <LabelContainer>
              <Grid display="flex" alignItems={'center'}>
                <SectionLabel>Work Experience</SectionLabel>
              </Grid>
            </LabelContainer>

            <NoResultsWorkExperience>
              <Grid display={'flex'} gap={'10px'} alignItems={'center'}>
                <ExclamationCircleIcon
                  style={{ height: '18px', width: '18px' }}
                />
                <ExclamationCircleIconText>
                  The Talent’s information is unable to be extracted.
                </ExclamationCircleIconText>
              </Grid>

              {(talent?.workingExperiencesInformationErrors?.length == 0 ||
                talent?.workingExperiencesInformationErrors == null) && (
                <NoResultsWorkExperienceText>
                  <ClickHere
                    onClick={() =>
                      onReportError(talent, 'working_experiences')
                    }>
                    Click here
                  </ClickHere>{' '}
                  to report any issues
                </NoResultsWorkExperienceText>
              )}

              {talent?.workingExperiencesInformationErrors?.length > 0 && (
                <ReportedMessage
                  iconColor={'#707070'}
                  children={
                    <ReportedMessageStyled $textColor={'#00000099'}>
                      You have reported the issue on{' '}
                      {moment(
                        talent?.workingExperiencesInformationErrors[0]
                          ?.created_at
                      ).format('DD/MM/YYYY')}
                      , thank you!
                    </ReportedMessageStyled>
                  }
                />
              )}
            </NoResultsWorkExperience>
          </Grid>
          <DividerStyled />
        </>
      )}

      {/* education */}
      {talent?.educations?.edges?.length > 0 && (
        <>
          <Educations /> <DividerStyled />
        </>
      )}

      {/* skills */}
      {talent?.skills?.length > 0 && (
        <>
          <Skills /> <DividerStyled />
        </>
      )}

      {/* language */}
      {talent?.languages?.length > 0 && (
        <>
          {' '}
          <Languages />
          <DividerStyled />
        </>
      )}

      {/* projects */}
      {talent?.projectAndPortfolios?.nodes?.length > 0 && (
        <>
          <Projects />
          <DividerStyled />
        </>
      )}

      {/* certificate */}
      {talent?.certificateAndTrainings?.nodes?.length > 0 && (
        <>
          <CertificatesAndTraining /> <DividerStyled />
        </>
      )}

      {/* Honours and Awards */}
      {talent?.honorAndAwards?.nodes?.length > 0 && (
        <>
          <HonoursAndAwards /> <DividerStyled />
        </>
      )}

      {/* Extracurricular */}
      {talent?.extracurriculars?.nodes?.length > 0 && <Extracurricular />}
    </ProfileContainer>
  );
};

export default ProfilePanel;

export const ShortSummary = () => {
  const talent = useSelector(state => state.talents.talent);
  return (
    <>
      <Grid display={'flex'} gap="8px" flexDirection={'column'}>
        <LabelContainer>
          <Grid display="flex" alignItems={'center'}>
            <SectionLabel>Short Summary</SectionLabel>
          </Grid>
        </LabelContainer>
        <SubjectDescription className="searchable">
          {talent?.shortSummary}
        </SubjectDescription>
      </Grid>
    </>
  );
};

export const WorkExperience = () => {
  const talent = useSelector(state => state.talents.talent);

  const [expOpen, setExpOpen] = useState(() => {
    const initialOpenItems = {};
    talent?.workingExperiences?.edges?.map((_, index) => {
      initialOpenItems[index] = false;
    });
    return initialOpenItems;
  });

  const getExperienceYear = exp => {
    const startYear = `${exp?.node.startDateYear}`;
    const startMonth = `${exp?.node.startDateMonth}`;
    const endMonth = `${exp?.node.endDateMonth}`;

    if (exp?.node?.startDateYear) {
      if (exp.node.endDateYear == null) {
        let year = moment().diff(startYear, 'years');

        return (
          `${exp.node.startDateYear} - PRESENT ` +
          `${year > 0 ? `(${year} years)` : ''}`
        );
      } else if (moment().isSameOrAfter(moment(startYear, 'years'))) {
        return `${moment(startMonth).format('MMM')?.toUpperCase()} ${
          exp.node.startDateYear
        } - ${moment(endMonth).format('MMM')?.toUpperCase()} ${
          exp.node.endDateYear
        }`;
      }
    }
  };

  const onClickExpList = index => {
    setExpOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (
    <Grid display="flex" flexDirection={'column'} gap="8px">
      <LabelContainer>
        <Grid display="flex" alignItems={'center'}>
          <SectionLabel>Work Experience</SectionLabel>
        </Grid>
      </LabelContainer>

      {talent?.workingExperiences?.edges.map((experience, index) => {
        return (
          <>
            {!experience?.node?.jobTitle &&
            !experience?.node?.companyName ? null : (
              <Grid
                display="flex"
                flexDirection={'column'}
                gap={'5px'}
                marginBottom={
                  talent?.workingExperiences.edges.length - 1 == index
                    ? '0'
                    : '10px'
                }
                key={index}>
                <ListItemButton
                  onClick={() => onClickExpList(index)}
                  sx={{
                    padding: '4px 0',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}>
                  <Grid display={'flex'} flexDirection={'column'} gap="5px">
                    {experience?.node.jobTitle ? (
                      <SubjectTitle className="searchable">
                        {experience?.node.jobTitle}
                      </SubjectTitle>
                    ) : (
                      '-'
                    )}
                    {experience?.node.companyName && (
                      <SubjectSubtitle className="searchable">
                        {experience.node.companyName}
                      </SubjectSubtitle>
                    )}
                    {experience?.node.startDateYear && (
                      <SubjectYear>{getExperienceYear(experience)}</SubjectYear>
                    )}
                  </Grid>

                  {experience?.node.description?.length > 0 ? (
                    expOpen[index] ? (
                      <ChevronUpIcon
                        style={{ height: '15px', width: '15px' }}
                      />
                    ) : (
                      <ChevronDownIcon
                        style={{ height: '15px', width: '15px' }}
                      />
                    )
                  ) : null}
                </ListItemButton>
                {experience?.node.description?.length > 0 && (
                  <Collapse in={expOpen[index]} timeout={'auto'} unmountOnExit>
                    {appendBulletPointAndBreakLines(
                      experience?.node.description
                    )?.map(item => {
                      return (
                        <SubjectDescription className="searchable">
                          <ul style={{ margin: '0', padding: '0 15px' }}>
                            {item}
                          </ul>
                        </SubjectDescription>
                      );
                    })}
                  </Collapse>
                )}
              </Grid>
            )}
          </>
        );
      })}
    </Grid>
  );
};

export const Educations = () => {
  const talent = useSelector(state => state.talents.talent);

  return (
    <Grid display="flex" flexDirection={'column'} gap="8px">
      <LabelContainer>
        <Grid display="flex" alignItems={'center'}>
          <SectionLabel>Education</SectionLabel>
        </Grid>
      </LabelContainer>
      {talent?.educations?.edges.map((education, index) => {
        return (
          <>
            {!education?.node.fieldOfStudy &&
            !education?.node.educationalInstitution ? null : (
              <Grid
                display="flex"
                flexDirection={'column'}
                gap="5px"
                marginBottom={
                  talent?.educations?.edges.length - 1 == index ? '0' : '10px'
                }>
                {education?.node.fieldOfStudy ? (
                  <SubjectTitle className="searchable">
                    {education?.node.fieldOfStudy}
                  </SubjectTitle>
                ) : (
                  '-'
                )}
                {education?.node.educationalInstitution && (
                  <SubjectSubtitle className="searchable">
                    {education?.node.educationalInstitution}
                  </SubjectSubtitle>
                )}
                {education?.node.graduationYear && (
                  <SubjectYear>{education?.node.graduationYear}</SubjectYear>
                )}
              </Grid>
            )}
          </>
        );
      })}
    </Grid>
  );
};

export const Skills = () => {
  const talent = useSelector(state => state.talents.talent);

  const [skillsOpen, setSkillsOpen] = useState(false);

  const onClickSkills = () => {
    setSkillsOpen(prev => !prev);
  };

  const showSkill = skills => {
    if (skills?.length > 6) {
      return skills.slice(0, 4);
    } else {
      return skills;
    }
  };

  return (
    <Grid display={'flex'} flexDirection={'column'} gap={'8px'}>
      <LabelContainer>
        <Grid display="flex" alignItems={'center'}>
          <SectionLabel>Skills</SectionLabel>
        </Grid>
      </LabelContainer>
      <ProfileChipsContainer>
        {showSkill(talent.skills).map(skill => {
          return (
            <ProfileChip>
              <ProfileChipText className="searchable">
                {skill?.name}
              </ProfileChipText>
            </ProfileChip>
          );
        })}
      </ProfileChipsContainer>

      {talent?.skills?.length > 6 && (
        <Collapse in={skillsOpen} timeout={'auto'} unmountOnExit>
          <ProfileChipsContainer>
            {talent?.skills.slice(4, talent.skills?.length).map(skill => {
              return (
                <ProfileChip>
                  <ProfileChipText className="searchable">
                    {skill?.name}
                  </ProfileChipText>
                </ProfileChip>
              );
            })}
          </ProfileChipsContainer>
        </Collapse>
      )}

      {talent?.skills?.length > 6 && (
        <ListItemButton
          onClick={onClickSkills}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            fontSize: '10px',
            letterSpacing: '1.5px',
            fontFamily: 'Inter',
            padding: '4px'
          }}>
          {skillsOpen ? 'SHOW LESS' : 'SHOW MORE'}
          {skillsOpen ? (
            <ChevronUpIcon style={{ height: '15px', width: '15px' }} />
          ) : (
            <ChevronDownIcon style={{ height: '15px', width: '15px' }} />
          )}
        </ListItemButton>
      )}
    </Grid>
  );
};

export const Languages = () => {
  const talent = useSelector(state => state.talents.talent);

  const [languagesOpen, setLanguagesOpen] = useState(false);

  const onClickLanguages = () => {
    setLanguagesOpen(prev => !prev);
  };

  const showLanguage = languages => {
    if (languages?.length > 6) {
      return talent?.languages?.slice(0, 4);
    } else {
      return languages;
    }
  };

  return (
    <Grid display={'flex'} flexDirection={'column'} gap={'8px'}>
      <LabelContainer>
        <Grid display="flex" alignItems={'center'}>
          <SectionLabel>Languages</SectionLabel>
        </Grid>
      </LabelContainer>
      <ProfileChipsContainer>
        {showLanguage(talent?.languages).map(language => {
          return (
            <ProfileChip>
              <ProfileChipText className="searchable">
                {language?.language}
              </ProfileChipText>
              {language?.proficiency_level && (
                <>
                  &nbsp;
                  <span> • </span>&nbsp;
                  <ProfileChipText className="searchable">
                    {language?.proficiency_level}
                  </ProfileChipText>
                  <br />
                </>
              )}
            </ProfileChip>
          );
        })}

        {talent?.languages?.length > 6 && (
          <Collapse in={languagesOpen} timeout={'auto'} unmountOnExit>
            <Grid
              container
              sx={{
                flexDirection: 'row',
                gap: '10px',
                width: 'auto',
                backgroundColor: 'red'
              }}>
              {talent?.languages
                .slice(4, talent?.languages?.length)
                .map(language => {
                  return (
                    <ProfileChip>
                      <ProfileChipText className="searchable">
                        {language?.language}
                      </ProfileChipText>
                      {language?.proficiency_level && (
                        <>
                          &nbsp;
                          <span> • </span>&nbsp;
                          <ProfileChipText className="searchable">
                            {language?.proficiency_level}
                          </ProfileChipText>
                          <br />
                        </>
                      )}
                    </ProfileChip>
                  );
                })}
            </Grid>
          </Collapse>
        )}
      </ProfileChipsContainer>

      {talent?.languages?.length > 6 && (
        <ListItemButton
          onClick={onClickLanguages}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            fontSize: '10px',
            letterSpacing: '1.5px',
            fontFamily: 'Inter',
            padding: '4px'
          }}>
          {languagesOpen ? 'SHOW LESS' : 'SHOW MORE'}
          {languagesOpen ? (
            <ChevronUpIcon style={{ height: '15px', width: '15px' }} />
          ) : (
            <ChevronDownIcon style={{ height: '15px', width: '15px' }} />
          )}
        </ListItemButton>
      )}
    </Grid>
  );
};

export const Projects = () => {
  const talent = useSelector(state => state.talents.talent);

  const [projectsOpen, setProjectsOpen] = useState(() => {
    const initialOpenItems = {};
    talent.projectAndPortfolios?.nodes?.map((_, index) => {
      initialOpenItems[index] = false;
    });
    return initialOpenItems;
  });

  const onClickProjects = index => {
    setProjectsOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (
    <Grid display={'flex'} flexDirection={'column'} gap={'8px'}>
      <LabelContainer>
        <Grid display="flex" alignItems={'center'}>
          <SectionLabel>Projects</SectionLabel>
        </Grid>
      </LabelContainer>

      {talent.projectAndPortfolios.nodes.map((project, index) => {
        return (
          <Grid
            display="flex"
            flexDirection={'column'}
            gap="5px"
            marginBottom={
              talent.projectAndPortfolios.nodes.length - 1 == index
                ? '0'
                : '10px'
            }>
            <ListItemButton
              onClick={() => onClickProjects(index)}
              sx={{
                padding: '4px 0',
                display: 'flex',
                justifyContent: 'space-between'
              }}>
              <Grid display={'flex'} flexDirection={'column'} gap={'5px'}>
                <Grid container alignItems={'center'} gap="8px">
                  <SubjectTitle className="searchable">
                    {project.title}
                  </SubjectTitle>
                  {talent.unlocked && project.projectUrl && (
                    <a target="_blank" href={project.projectUrl}>
                      <LinkIcon style={{ height: '12px', width: '12px' }} />
                    </a>
                  )}
                </Grid>

                <SubjectYear>{project.completionYear}</SubjectYear>
              </Grid>

              {projectsOpen[index] ? (
                <ChevronUpIcon style={{ height: '15px', width: '15px' }} />
              ) : (
                <ChevronDownIcon style={{ height: '15px', width: '15px' }} />
              )}
            </ListItemButton>
            <Collapse in={projectsOpen[index]} timeout={'auto'} unmountOnExit>
              {talent.unlocked ? (
                // <SubjectDescription className="searchable">
                //   {project.description}
                // </SubjectDescription>
                <>
                  {appendBulletPointAndBreakLines(project.description)?.map(
                    item => {
                      return (
                        <SubjectDescription className="searchable">
                          <ul style={{ margin: '0', padding: '0 15px' }}>
                            {item}
                          </ul>
                        </SubjectDescription>
                      );
                    }
                  )}
                </>
              ) : project?.description == 'true' ||
                project?.projectUrl == 'true' ? (
                <SharedNotice
                  children={<LockedMessage />}
                  dividerColor={'#F2A647'}
                  contentBackground={'#F2A64733'}
                />
              ) : null}
            </Collapse>
          </Grid>
        );
      })}
    </Grid>
  );
};

export const CertificatesAndTraining = () => {
  const talent = useSelector(state => state.talents.talent);

  const [certsOpen, setCertsOpen] = useState(() => {
    const initialOpenItems = {};
    talent.certificateAndTrainings?.nodes?.map((_, index) => {
      initialOpenItems[index] = false;
    });
    return initialOpenItems;
  });

  const onClickCerts = index => {
    setCertsOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (
    <Grid display={'flex'} flexDirection={'column'} gap={'8px'}>
      <LabelContainer>
        <Grid display="flex" alignItems={'center'}>
          <SectionLabel>Certificates & Training</SectionLabel>
        </Grid>
      </LabelContainer>

      {talent.certificateAndTrainings.nodes.map((cert, index) => {
        return (
          <Grid
            display="flex"
            flexDirection={'column'}
            gap="10px"
            marginBottom={
              talent.certificateAndTrainings.nodes.length - 1 == index
                ? '0'
                : '10px'
            }>
            <Grid display={'flex'} flexDirection={'column'} gap={'5px'}>
              <Grid container alignItems={'center'} gap="8px">
                <SubjectTitle className="searchable">{cert.title}</SubjectTitle>
                {talent.unlocked && cert.credentialUrl && (
                  <a target="_blank" href={cert.credentialUrl}>
                    <LinkIcon style={{ height: '12px', width: '12px' }} />
                  </a>
                )}
              </Grid>
              <Grid display={'flex'} flexDirection={'column'} gap="5px">
                <SubjectSubtitle className="searchable">
                  {cert.issuingOrganization}
                </SubjectSubtitle>
                <SubjectYear>{cert.completionYear}</SubjectYear>
              </Grid>
            </Grid>

            {!talent.unlocked && cert.credentialUrl == 'true' && (
              <SharedNotice
                children={<LockedMessage />}
                dividerColor={'#F2A647'}
                contentBackground={'#F2A64733'}
              />
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export const HonoursAndAwards = () => {
  const talent = useSelector(state => state.talents.talent);

  const [honoursOpen, setHonoursOpen] = useState(() => {
    const initialOpenItems = {};
    talent.honorAndAwards?.nodes?.map((_, index) => {
      initialOpenItems[index] = false;
    });
    return initialOpenItems;
  });

  const onClickHonours = index => {
    setHonoursOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (
    <Grid display={'flex'} flexDirection={'column'} gap={'8px'}>
      <LabelContainer>
        <Grid display="flex" alignItems={'center'}>
          <SectionLabel>Honours and Awards</SectionLabel>
        </Grid>
      </LabelContainer>

      {talent.honorAndAwards.nodes.map((award, index) => {
        return (
          <Grid
            display="flex"
            flexDirection={'column'}
            gap="5px"
            marginBottom={
              talent.honorAndAwards.nodes.length - 1 == index ? '0' : '10px'
            }>
            <ListItemButton
              onClick={() => onClickHonours(index)}
              sx={{
                padding: '4px 0',
                display: 'flex',
                justifyContent: 'space-between'
              }}>
              <Grid display={'flex'} flexDirection={'column'} gap={'5px'}>
                <SubjectTitle className="searchable">
                  {award.title}
                </SubjectTitle>
                <SubjectYear>{award.completionYear}</SubjectYear>
              </Grid>
              {honoursOpen[index] ? (
                <ChevronUpIcon style={{ height: '15px', width: '15px' }} />
              ) : (
                <ChevronDownIcon style={{ height: '15px', width: '15px' }} />
              )}
            </ListItemButton>

            <Collapse in={honoursOpen[index]} timeout={'auto'} unmountOnExit>
              {/* <SubjectDescription className="searchable">
                  {award.description}
                </SubjectDescription> */}

              {appendBulletPointAndBreakLines(award.description)?.map(item => {
                return (
                  <SubjectDescription className="searchable">
                    <ul style={{ margin: '0', padding: '0 15px' }}>{item}</ul>
                  </SubjectDescription>
                );
              })}
            </Collapse>
          </Grid>
        );
      })}
    </Grid>
  );
};

export const Extracurricular = () => {
  const talent = useSelector(state => state.talents.talent);

  const [curriOpen, setCurriOpen] = useState(() => {
    const initialOpenItems = {};
    talent.honorAndAwards?.nodes?.map((_, index) => {
      initialOpenItems[index] = false;
    });
    return initialOpenItems;
  });

  const onClickCurri = index => {
    setCurriOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (
    <Grid display={'flex'} flexDirection={'column'} gap={'8px'}>
      <LabelContainer>
        <Grid display="flex" alignItems={'center'}>
          <SectionLabel>Extracurricular</SectionLabel>
        </Grid>
      </LabelContainer>
      {talent.extracurriculars.nodes.map((curri, index) => {
        return (
          <Grid
            display="flex"
            flexDirection={'column'}
            gap="5px"
            marginBottom={
              talent.extracurriculars.nodes.length - 1 == index ? '0' : '10px'
            }>
            <ListItemButton
              onClick={() => onClickCurri(index)}
              sx={{
                padding: '4px 0',
                display: 'flex',
                justifyContent: 'space-between'
              }}>
              <Grid display={'flex'} flexDirection={'column'} gap={'5px'}>
                <SubjectTitle className="searchable">
                  {curri.title}
                </SubjectTitle>
                <SubjectYear>{curri.completionYear}</SubjectYear>
              </Grid>
              {curriOpen[index] ? (
                <ChevronUpIcon style={{ height: '15px', width: '15px' }} />
              ) : (
                <ChevronDownIcon style={{ height: '15px', width: '15px' }} />
              )}
            </ListItemButton>

            <Collapse in={curriOpen[index]} timeout={'auto'} unmountOnExit>
              {/* <SubjectDescription className="searchable">
                  {curri.description}
                </SubjectDescription> */}

              {appendBulletPointAndBreakLines(curri.description)?.map(item => {
                return (
                  <SubjectDescription className="searchable">
                    <ul style={{ margin: '0', padding: '0 15px' }}>{item}</ul>
                  </SubjectDescription>
                );
              })}
            </Collapse>
          </Grid>
        );
      })}
    </Grid>
  );
};

export const LockedMessage = () => {
  return (
    <>
      <LockClosedIcon style={{ height: '12.8px', width: '12.8px' }} />
      <span style={{ fontSize: '12px', fontFamily: 'inter' }}>
        Unlock profile to see more information.
      </span>
    </>
  );
};

export const ReportedMessage = ({ children, iconColor }) => {
  return (
    <Grid display={'flex'} gap={'6px'} alignItems={'center'}>
      <CheckCircleIcon
        style={{
          color: iconColor,
          height: '16px',
          width: '16px'
        }}
      />
      {children}
    </Grid>
  );
};
