import { styled as styling } from '@mui/material/styles';
import { Divider, Menu, IconButton } from '@mui/material';
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material';
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';

export const ArrowPathRoundedSquareIconStyled = styling(
  ArrowPathRoundedSquareIcon
)(props => ({
  color: 'black',
  width: "20px",
  height: "20px"
}));

export const XMarkIconStyled = styling(XMarkIcon)(props => ({
  color: 'black',
  width: "20px",
  height: "20px"
}));
