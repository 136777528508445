import React from 'react';
import { MUIButtonStyled } from '../../../../components/MUIButton';
import {
  ContentBoldStyled,
  ContentStyled,
  DialogContentStyled,
  Wrapper
} from '../styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openInNewTab } from '../../../../utils/WindowUtils';
import { mutateCart } from '../../../../redux/actions/order_action';

export const InsufficientModal = props => {
  const dispatch = useDispatch();
  const { handleClose } = props;

  const purchaseHandler = async () => {
    // TALENT SEARCH TRACKING
    try {
      window.dataLayer.push({
        event: 'CE_click-purchase-coin-insufficient-coin-popup'
      });
    } catch (error) {}

    if (handleClose) handleClose();

    openInNewTab('/purchase/choose/?tab=talent_search&products=coins_x5000-1');
  };

  return (
    <DialogContentStyled>
      <ContentStyled>
        Unlock this profile to access this Talent’s full resume and engage with
        them via chat by{' '}
        <ContentBoldStyled>purchasing more Talent Tokens.</ContentBoldStyled>
      </ContentStyled>
      <Wrapper container justifyContent="center" sx={{ marginTop: '24px' }}>
        <MUIButtonStyled
          onClick={purchaseHandler}
          label="Purchase Tokens"
          large
        />
      </Wrapper>
    </DialogContentStyled>
  );
};
