import clevertap from 'clevertap-web-sdk';
import { debounce, isEmpty } from 'lodash';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import PricingChip from '../../components/PricingChip';
import ProductUnavailablePopUp from '../../components/ProductUnavailablePopup';
import { Spacer } from '../../components/Spacer/styles';
import DefaultLayout from '../../layouts/DefaultLayout';
import { fetchMyCompanySettings } from '../../redux/actions/company_actions';
import {
  updateContactSalesPopupStatus,
  updateSubjectType
} from '../../redux/actions/job_action';
import {
  fetchCart,
  mutateCart,
  mutateCartSummary,
  updateSelectedProduct
} from '../../redux/actions/order_action';
import Colors from '../../styles/colors.module.scss';
import AddOns from './AddOns';
import MoreInfoDialog from './MoreInfoDialog';
import ProductTabs from './ProductTabs';
import ShoppingCart from './ShoppingCart';
import { NoWidthSkeleton, ProductCatSkeleton } from './Skeletons/styles';
import {
  BoltIconStyled,
  ContactSalesButton,
  ContactSalesDesc,
  ContactSalesTitle,
  PackageContainer,
  PackageGrid,
  ProductPageTitle,
  PromoContainer,
  PromoText,
  Slash
} from './styles';
import { getCountry, getCurrency } from '../../hooks/useRegionalisation';
import { CSEMAIL, Hiredly_Phone_Number } from '../../utils/Constants';
import useGetQueryString from '../../hooks/useGetQueryString';
import {
  fetchProductsByCategory,
  updateProductInactivePopupStatus
} from '../../redux/actions/product_action';

// http://localhost:3000/purchase/choose/?products=job_slot-2,job_slot_max-2

export default function ChooseProducts() {
  const dispatch = useDispatch();
  const selectedTab = useSelector(state => state.orders.selectedTab);
  const myCart = useSelector(state => state.orders.myCart);
  const allProducts = useSelector(state => state.products.allProducts);
  const allActiveProducts = useSelector(
    state => state.products.allActiveProducts
  );
  const fetchingAllActiveProducts = useSelector(
    state => state.products.fetchingAllActiveProducts
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  useEffect(() => {
    dispatch(fetchMyCompanySettings());
    dispatch(fetchProductsByCategory()).then(res => {
      if (isEmpty(tab)) {
        dispatch(updateSelectedProduct(res?.allActiveProducts?.at(0)?.title));
      }

      const products = searchParams.get('products');
      if (products && !isEmpty(res?.allProducts)) {
        const productArray = products.split(',').map(product => {
          const [id, quantity] = product.split('-');
          return { id, quantity: parseInt(quantity, 10) };
        });

        const mappedProduct = productArray
          .map(product => {
            const activeProduct = res?.allProducts?.find(
              active => active.productCode === product.id
            );
            if (activeProduct) {
              return { code: activeProduct.id, ...product };
            } else {
              dispatch(updateProductInactivePopupStatus(true));
              return null;
            }
          })
          .filter(Boolean); // Filter out null values

        // Debounce addToCart dispatch calls
        debouncedAddToCart(mappedProduct);
      }
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {}, [allProducts]);

  // Debounced addToCart function
  const debouncedAddToCart = useMemo(
    () =>
      debounce(async items => {
        await Promise.all(
          items.map(async product => {
            await dispatch(
              mutateCart({
                input: {
                  productId: product.code || product?.id,
                  quantity: product.quantity
                }
              })
            );
          })
        );
        localStorage.removeItem('productQuery');
        dispatch(fetchCart()).then(res => {
          dispatch(
            mutateCartSummary({
              input: {
                cartId: res?.myCart?.id ?? ''
              }
            })
          );
        });
        setSearchParams({})
      }, 500),
    []
  );

  const alertUser = event => {
    if (myCart?.cartLists?.edges?.length !== 0) {
      const productCodes =
        myCart?.cartLists?.edges.map(item => item.node.product.productCode) ||
        [];

      const categories = {
        job_slot: [
          'job_slot',
          'job_slot_plus',
          'job_slot_max'
          // 'exclusive_max',
          // 'exclusive_plus'
        ],
        visibility_booster: [
          'booster_token',
          'refresher_token',
          'spotlight_token',
          'keyword_highlight_token'
        ],
        talent_search: ['coins_x5000'],
        // if non of the categories match , it will fall under others
        other: []
      };

      const counts = {
        job_slot: 0,
        visibility_booster: 0,
        talent_search: 0,
        other: 0
      };

      for (const code of productCodes) {
        let found = false;
        for (const [category, codes] of Object.entries(categories)) {
          if (codes.includes(code)) {
            counts[category]++;
            found = true;
            break;
          }
        }
        if (!found) {
          categories.other.push(code);
          counts.other++;
        }
      }

      if (
        counts.job_slot > 0 &&
        counts.visibility_booster === 0 &&
        counts.talent_search === 0 &&
        counts.other === 0
      ) {
        window.dataLayer.push({
          event: 'CE_cart-abandoned--job-slot'
        });
        cartUserProperties('job_slot');
      } else if (
        counts.job_slot === 0 &&
        counts.visibility_booster > 0 &&
        counts.talent_search === 0 &&
        counts.other === 0
      ) {
        window.dataLayer.push({
          event: 'CE_cart-abandoned--visibility-booster'
        });
        cartUserProperties('visibility_booster');
      } else if (
        counts.job_slot === 0 &&
        counts.visibility_booster === 0 &&
        counts.talent_search > 0 &&
        counts.other === 0
      ) {
        window.dataLayer.push({
          event: 'CE_cart-abandoned--talent-search'
        });
        cartUserProperties('talent_search');
      } else if (
        counts.job_slot === 0 &&
        counts.visibility_booster === 0 &&
        counts.talent_search > 0 &&
        counts.other > 0
      ) {
        window.dataLayer.push({
          event: 'CE_cart-abandoned--generic-mix'
        });
        cartUserProperties('generic_mix');
      } else {
        window.dataLayer.push({
          event: 'CE_cart-abandoned--generic-mix'
        });
        cartUserProperties('generic_mix');
      }
    } else {
      cartUserProperties('_null');
    }

    event.returnValue = '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  const onContactUs = productCode => {
    if (productCode == 'homepage_featured_company') {
      dispatch(updateSubjectType('homepage-featured-company'));
    } else if (productCode == 'homepage_carousel_adspace') {
      dispatch(updateSubjectType('homepage-carousel-adspace'));
    } else if (productCode == 'advice_page_ads') {
      dispatch(updateSubjectType('advice-page-ads'));
    }

    if (
      productCode == 'homepage_featured_company' ||
      productCode == 'homepage_carousel_adspace' ||
      productCode == 'advice_page_ads'
    ) {
      window.dataLayer.push({
        event: 'CE_contact-us-clicked--purchase-premium-ad-space'
      });
      cartUserProperties('premium_ad_space');
    } else {
      window.dataLayer.push({
        event: 'CE_contact-us-clicked--purchase-page'
      });
    }

    dispatch(updateContactSalesPopupStatus(true));
  };

  const tabList = allActiveProducts
    .filter(activeProducts => {
      return activeProducts?.hide === false;
    })
    .sort((a, b) => a?.order - b?.order)
    .map(activeProducts => {
      return activeProducts.title;
    });

  function cartUserProperties(value) {
    if (getCountry()) {
      clevertap.profile.push({
        Site: {
          cart_status: value
        }
      });
    }
  }

  return (
    <DefaultLayout>
      <PackageGrid
        container
        columns={12}
        justifyContent={'center'}
        alignItems={'flex-start'}
        maxWidth={'1440px'}
        marginX={'auto'}
        flexWrap={'nowrap'}
        columnGap={'16px'}
        padding={'40px 70px'}>
        <PackageGrid item xs={9}>
          <MoreInfoDialog />
          <ProductPageTitle>Products for You</ProductPageTitle>
          <ProductTabs tabList={tabList} />
          {selectedTab === 'Job Ads' && <AddOns />}
          {renderContactSalesBanner(onContactUs, fetchingAllActiveProducts)}
        </PackageGrid>

        <ShoppingCart />
      </PackageGrid>
      <ProductUnavailablePopUp />
    </DefaultLayout>
  );
}

export const bundleChips = (product, isSpotlightOrKeyword) => {
  if (product?.productBundleInfo.length > 0 && isSpotlightOrKeyword(product)) {
    return (
      <PromoContainer>
        <BoltIconStyled />
        <PromoText>was</PromoText>
        <Slash>
          {' '}
          {product?.productCode == 'spotlight_token' ? 'RM100' : 'RM200'}
        </Slash>
        <PromoText>
          {product?.productCode == 'spotlight_token' ? 'NOW RM48' : 'NOW RM88'}
        </PromoText>
      </PromoContainer>
    );
  } else if (product.productCode == 'coins_x5000') {
    return (
      <Fragment key={`${product?.productCode}`}>
        <PricingChip
          $backgroundColor={Colors.secLightPurple}
          $marginBottom={'8px'}>
          <span>Buy 2 Sets Free 1,000 Tokens</span>
        </PricingChip>
        <PricingChip
          $backgroundColor={Colors.secLightPurple}
          $marginBottom={'8px'}>
          <span>Buy 3 Sets Free 5,000 Tokens</span>
        </PricingChip>
        <Spacer width={'8px'} />
      </Fragment>
    );
  } else {
    return product?.productBundleInfo.map((item, index) => {
      return (
        <Fragment key={`${item?.productCode}-${item?.token_amount}`}>
          <PricingChip
            $backgroundColor={Colors.secLightPurple}
            $marginBottom={'8px'}>
            <span>
              {item?.token_amount} for{' '}
              {getCurrency(Math.floor(item?.discounted_amount))}
            </span>
          </PricingChip>
          <Spacer width={'8px'} />
        </Fragment>
      );
    });
  }
};

function renderContactSalesBanner(onContactUs, fetchingAllActiveProducts) {
  return (
    <PackageContainer
      $noTitleAndDesc
      container
      justifyContent={'space-between'}
      alignItems={'flex-end'}>
      <PackageGrid flex={2}>
        {fetchingAllActiveProducts ? (
          <NoWidthSkeleton sx={{ width: '300px', marginBottom: '8px' }} />
        ) : (
          <ContactSalesTitle marginBottom={'8px'}>
            Looking for something more specific?
          </ContactSalesTitle>
        )}
        {fetchingAllActiveProducts ? (
          <Fragment>
            <NoWidthSkeleton sx={{ width: '450', marginBottom: '8px' }} />
            <NoWidthSkeleton sx={{ width: '90%' }} />
          </Fragment>
        ) : (
          <ContactSalesDesc>
            Talk to a consultant for a custom package catered to your needs.
            <br />
            For any further enquiries, please email{' '}
            <a href={`mailto:${CSEMAIL[getCountry()]}`}>
              {CSEMAIL[getCountry()]}
            </a>{' '}
            <>
              or call{' '}
              <a href={`tel:${Hiredly_Phone_Number['MYWithCountryCode']}`}>
                {' '}
                {Hiredly_Phone_Number['MYWithSpace']}
              </a>
            </>
            .
          </ContactSalesDesc>
        )}
      </PackageGrid>
      <PackageGrid
        flex={1}
        container
        alignItems={'flex-end'}
        justifyContent={'flex-end'}>
        {fetchingAllActiveProducts ? (
          <ProductCatSkeleton />
        ) : (
          <ContactSalesButton
            onClick={() => {
              onContactUs();
            }}
            variant="contained">
            Contact Sales
          </ContactSalesButton>
        )}
      </PackageGrid>
    </PackageContainer>
  );
}
