import { styled as styling } from '@mui/material/styles';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

export const PageContainer = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',

  [props.theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}));

export const Container2 = styling('div')(props => ({
  display: 'flex',
  width: '50%',
  padding: '15px',
  opacity: 1,

  [props.theme.breakpoints.down('md')]: {
    width: '95%',
    margin: 'auto',
    height: '30rem',
    overflow: 'hidden'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '95%',
    margin: 'auto',
    height: '45vh',
    overflow: 'hidden'
  }
}));

export const Container4 = styling('div')(props => ({
  display: 'flex',
  width: '50%',
  opacity: 1,
  zIndex: 2,
  background: 'white',

  [props.theme.breakpoints.down('md')]: {
    width: '100%',
    height: 'calc(100% - 30rem)'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '100%',
    height: 'calc(100% - 45vh)'
  }
}));

export const Title = styling('h1')(props => ({
  fontSize: '42px',
  color: '#40249B',
  fontWeight: 700,
  margin: '0',
  lineHeight: '45px',

  transition: 'opacity 0.8s ease-in-out',
  opacity: props.out ? 0 : 1,

  [props.theme.breakpoints.down('md')]: {
    fontSize: '42px',
    textAlign: 'center'
  },

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    textAlign: 'center',
    lineHeight: '30px'
  }
}));

export const Message = styling('span')(props => ({
  fontSize: '16px',
  color: '#000',
  margin: '0',
  fontWeight: 400,
  position: 'relative',
  textAlign: 'justify',
  minHeight: '150px',
  lineHeight: '24px',
  letterSpacing: '0.15px',

  transition: 'opacity 0.8s ease-in-out',
  opacity: props.out ? 0 : 1,

  [props.theme.breakpoints.down('md')]: {
    fontSize: '16px'
  },

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: 'unset',
    minHeight: '20vh'
  }
}));

export const SecondContainer = styling('div')(props => ({
  position: 'relative',
  width: '100%',
  background: '#fff',
  height: '100%',
  padding: '50px 100px',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  justifyContent: 'center',

  animation: 'fadeIn ease 0.8s',

  '@keyframes fadeIn': {
    '0%': {
      opacity: '0',
      transfrom: 'translateY(-100)'
    },

    '100%': {
      opacity: '1',
      transfrom: 'translateY(0)'
    }
  },

  [props.theme.breakpoints.down('md')]: {
    padding: '40px 16px',
    maxWidth: '600px',
    margin: 'auto'
  },

  [props.theme.breakpoints.down('sm')]: {
    padding: '16px 16px 32px 16px',
    gap: '16px',
  }
}));

export const BackButton = styling(IconButton)(props => ({
  padding: '8px 0',
  ':hover': {
    background: 'none',
  },
  ':disabled': {
    cursor: 'not-allowed'
  }
}));

export const NextPageButton = styling(Button)(props => ({
  width: '200px',
  height: '48px',
  backgroundColor: '#2E2E2E',
  borderRadius: '100px',
  color: '#FFF',
  fontSize: '16px',
  textTransform: 'none',
  fontWeight: '700',
  padding: '0 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',

  ':disabled': {
    backgroundColor: '#D8D8D8',
    cursor: 'not-allowed',
    color: '#FFF',

    ':hover': {
      backgroundColor: '#D8D8D8'
    }
  },

  ':hover': {
    backgroundColor: '#222'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '180px',
    height: '36px',
    fontSize: '14px'
  }
}));

export const FooterContainer = styling(Box)(props => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0',
  alignItems: 'center',

  transition: 'opacity 0.8s ease-in-out',
  opacity: props.out ? 0 : 1
}));

export const KeyboardBackspaceIconStyled = styling(ArrowLeftIcon)(props => ({
  padding: '0',
  width: '24px',
  height: '24px',
  color: '#000'
}));

export const HeightSpacer = styling('div')(props => ({
  height: props.$height ? props.$height : '5px'
}));

export const AnimatedContainer = styling('div')(props => ({
  justifyContent: 'center',
  position: 'relative',
  width: '50%',
  height: '100%',
  overflow: 'hidden',
  padding: '15px',

  [props.theme.breakpoints.down('md')]: {
    width: '100%',
    maxWidth: '450px',
    height: '30rem',
    margin: 'auto'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '95%',
    height: '45vh',
    margin: 'auto'
  }
}));

export const SecAnimatedContainer = styling('div')(props => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  maxHeight: '1080px',
  margin: 'auto',
  transition: 'opacity 0.8s ease-in-out',
  opacity: 1
}));

export const VideoContainer = styling('div')(props => ({
  flex: '1',
  border: '2px solid black',
  borderRadius: '15px',
  filter: 'drop-shadow(3px 0px black)',
  background: 'black'
}));

export const SecContentContainer = styling('div')(props => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  gap: '20px',
  padding: '2% 40px',
  justifyContent: 'space-evenly',
  height: '100%',
  alignItems: 'center',

  animation: 'slideUp 1s ease-in-out',

  '@keyframes slideUp': {
    '0%': {
      opacity: '1',
      transform: 'translateY(100%)'
    },
    '100%': {
      opacity: '1',
      transform: 'translateY(0%)'
    }
  },

  transform: props.slide ? 'translateY(-120%)' : 'translateY(0)',
  transition: 'transform 1s ease-in-out',

  [props.theme.breakpoints.down('md')]: {
    padding: '0px 10px 25px 10px'
  },

  [props.theme.breakpoints.down('sm')]: {
    padding: '0 50px'
  }
}));

export const MessageBrand = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  gap: '24px',
  alignSelf: 'stretch',

  transition: 'opacity 0.8s ease-in-out',
  opacity: props.out ? 0 : 1,

  [props.theme.breakpoints.down('sm')]: {
    gap: '16px',
  } 
}));

export const MessageBrandLine = styling('hr')(props => ({
  height: '1px',
  width: '100%',
  background: '#D8D8D8',
  margin: '0',

  transition: 'opacity 0.8s ease-in-out',
  opacity: props.out ? 0 : 1,
}));

export const MessageBrandTextBox = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  gap: '4px',
  alignSelf: 'stretch'
}));

export const MessageBrandTitle = styling('div')(props => ({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px',
  alignSelf: 'stretch',

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '20px',
  }
}));

export const MessageBrandContext = styling('div')(props => ({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  alignSelf: 'stretch',

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '20px',
  }
}));

export const MessageBrandTitlePurple = styling('div')(props => ({
  color: '#512ACC',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px',
  alignSelf: 'stretch',

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '20px'
  } 
}));

export const MessageBrandTitleGrey = styling('div')(props => ({
  color: 'rgba(0, 0, 0, 0.60)',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  fontStyle: 'italic',
  alignSelf: 'stretch',

  [props.theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.15px',
  } 
}));

