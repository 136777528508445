import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import _ from 'lodash';
import styles from './index.module.scss';
import DeleteAffiliatePopUp from '../../PopUps/DeleteAffiliatePopUp';
import ChangeRelationshipPopUp from '../../PopUps/ChangeRelationshipPopUp';

const Status = ({ index, tableData }) => {
  const data = tableData[index];
  const [isDeletePopUpOpen, setIsDeletePopUpOpen] = useState(false);
  const [isChangePopUpOpen, setIsChangePopUpOpen] = useState(false);

  return (
    <div className={styles.container}>
      <DeleteAffiliatePopUp
        open={isDeletePopUpOpen}
        handleClose={() => setIsDeletePopUpOpen(false)}
        id={data?.id}
        name={data?.affiliatedCompany?.name}
      />
      <ChangeRelationshipPopUp
        open={isChangePopUpOpen}
        handleClose={() => setIsChangePopUpOpen(false)}
        id={data?.id}
        name={data?.affiliatedCompany?.name}
        affiliationType={data?.affiliationType}
        affiliatedCompanyId={data?.affiliatedCompany?.id}
      />

      <div className={styles.titleContainer}>
        <p className={styles.title}>{_.capitalize(data?.approvalStatus)}</p>
      </div>
      <div className={styles.utilContainer}>
        <div className={styles.editButtonWrapper}>
          <IconButton
            className={styles.editIconButton}
            onClick={() => setIsChangePopUpOpen(true)}>
            <EditIcon />
          </IconButton>
        </div>
        <IconButton
          className={styles.editIconButton}
          onClick={() => setIsDeletePopUpOpen(true)}>
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default Status;
