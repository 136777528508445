import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HiredlyLogo from '../../../assets/common/hiredly-logo.png';
import Message from '../../../components/Message';
import DefaultLayout from '../../../layouts/DefaultLayout';
import { mutateResetEmployerPassword } from '../../../redux/actions/company_actions';
import { ErrorToast, SuccessToast } from '../../../utils/ToastUtils';
import useSearchParams from '../../../utils/useSearchParams';
import ForgotPassword from './ForgotPassword';
import ForgotPasswordSuccess from './ForgotPasswordSuccess';
import { CardContainer, Container, Logo, PurpleText } from './styles';

export default function ForgotPasswordLanding() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const params = useSearchParams();
  const error = params.get('error');

  const [email, setEmail] = useState('');

  const mutatingEmployerPassword = useSelector(
    state => state.companies.mutatingEmployerPassword
  );

  const handleReset = async _email => {
    dispatch(
      mutateResetEmployerPassword({
        input: { email: _email }
      })
    )
      .then(({ resetEmployerPassword }) => {
        const { success, message } = resetEmployerPassword
        if (!success) {
          return ErrorToast(message);
        }

        SuccessToast(message);
        setEmail(_email);
      })
      .catch(err => {
        ErrorToast(err.message);
      });
  };

  const handleResend = async () => {
    dispatch(
      mutateResetEmployerPassword({
        input: { email }
      })
    )
      .then(({ resetEmployerPassword }) => {
        const { success, message } = resetEmployerPassword
        if (!success) {
          return ErrorToast('An email was sent within the last 15 minutes, please try again later.')
        }

        SuccessToast(message);
      })
      .catch(err => {
        ErrorToast(err.message);
      });
  };

  const renderContent = () => {
    if (email) {
      return (
        <ForgotPasswordSuccess
          email={email}
          onResend={handleResend}
          loading={mutatingEmployerPassword}
        />
      );
    }

    return (
      <ForgotPassword
        onReset={handleReset}
        loading={mutatingEmployerPassword}
      />
    );
  };

  return (
    <DefaultLayout>
      <Container>
        <Logo src={HiredlyLogo} alt="logo" />
        {error === 'invalid' && (
          <Message danger width={'460px'}>
            Your reset password link might have expired. Please request for a
            new reset password email below.
          </Message>
        )}
        <CardContainer>
          {renderContent()}
          <PurpleText onClick={() => history('/login')}>
            {' '}
            Back to Log in{' '}
          </PurpleText>
        </CardContainer>
      </Container>
    </DefaultLayout>
  );
}
