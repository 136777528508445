import React, { useEffect } from 'react';
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from '@heroicons/react/24/solid';
import {
  IconButtonStyled,
  NumberContainer,
  PaginationContainer,
  PaginationNumbersContainer,
  TypographyStyled
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  getApplications,
  updateApplicationCurrentPage,
  updateApplicationParams,
  updateApplicationUserIds
} from '../../../../redux/actions/application_action';
import store from '../../../../redux/stores/store';
import { Grid, MenuItem } from '@mui/material';
import Colors from '../../../../styles/colors.module.scss';
import SharedSelect from '../../../../components/SharedSelect';

export const Pagination = () => {
  const dispatch = useDispatch();
  const loadingApplication = useSelector(
    state => state.applications.fetchingApplications
  );
  const index = useSelector(state => state.applications.applicationCurrentPage);
  const hasNextPage = useSelector(
    state => state.applications.applicationHasNext
  );
  const candidatePerPage = useSelector(
    state => state.applications.applicationParams.first
  );

  const onClickLeftArrow = () => {
    if (loadingApplication) return;
    if (store.getState().applications.applicationCurrentPage == 1) return;

    const previousPage =
      store.getState().applications.applicationCurrentPage - 1;
    dispatch(updateApplicationCurrentPage(previousPage)).then(() => {
      let params = { ...store.getState().applications.applicationParams };

      params.offset =
        (store.getState().applications.applicationCurrentPage - 1) *
        candidatePerPage;

      dispatch(getApplications(params));
    });
  };

  const onClickRightArrow = () => {
    if (loadingApplication) return;
    if (store.getState().applications.applicationReachedEnd) return;

    const nextPage = store.getState().applications.applicationCurrentPage + 1;
    dispatch(updateApplicationCurrentPage(nextPage)).then(() => {
      let params = { ...store.getState().applications.applicationParams };

      params.offset =
        (store.getState().applications.applicationCurrentPage - 1) *
        candidatePerPage;

      dispatch(getApplications(params));
    });
  };

  useEffect(() => {
    if (index) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      dispatch(updateApplicationUserIds([]));
    }
  }, [index]);

  return (
    <PaginationContainer>
      <Grid display={'flex'} justifyContent={'center'} gap={'14px'}>
        <Grid display={'flex'} alignItems={'center'}>
          <IconButtonStyled disabled={index <= 1} onClick={onClickLeftArrow}>
            <ChevronLeftIcon style={{ height: '20px', width: '20px' }} />
          </IconButtonStyled>
          <PaginationNumbersContainer>
            {index > 1 && (
              <NumberContainer onClick={onClickLeftArrow}>
                <TypographyStyled>{index - 1}</TypographyStyled>
              </NumberContainer>
            )}

            <NumberContainer $selected={true}>
              <TypographyStyled $selected={true}>{index}</TypographyStyled>
            </NumberContainer>
            {hasNextPage && (
              <NumberContainer onClick={onClickRightArrow}>
                <TypographyStyled>{index + 1}</TypographyStyled>
              </NumberContainer>
            )}
          </PaginationNumbersContainer>

          <IconButtonStyled disabled={!hasNextPage} onClick={onClickRightArrow}>
            <ChevronRightIcon style={{ height: '20px', width: '20px' }} />
          </IconButtonStyled>
        </Grid>

        <CandidatePerPage />
      </Grid>
    </PaginationContainer>
  );
};

export const CandidatePerPage = () => {
  const dispatch = useDispatch();

  const candidatePerPage = useSelector(
    state => state.applications.applicationParams.first
  );

  const onUpdateSelectValue = e => {
    let cApplicationParams = store.getState().applications.applicationParams;

    cApplicationParams.first = e.target.value;

    dispatch(updateApplicationParams(cApplicationParams)).then(() => {
      dispatch(updateApplicationCurrentPage(1));
      dispatch(getApplications(cApplicationParams));
    });
  };

  return (
    <Grid display={'flex'} alignItems={'center'} gap={'12px'}>
      <TypographyStyled $perPageText={true}>Show</TypographyStyled>
      <SharedSelect
        value={candidatePerPage}
        onChange={onUpdateSelectValue}
        sx={{
          borderRadius: '30px',
          fontSize: '14px',
          fontFamily: 'Inter',
          color: 'rgba(0,0,0,0.87)',
          width: 84,
          '.MuiSelect-select': {
            padding: '5px 20px'
          }
        }}
        MenuProps={{
          sx: {
            height: '100%',
            width: '84px'
          },
          PaperProps: {
            sx: {
              backgroundColor: Colors.priWhite,
              borderRadius: '4px',
              boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.16)',
              '& .MuiMenuItem-root': {
                padding: '10px 20px'
              }
            }
          }
        }}>
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={30}>30</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </SharedSelect>
      <TypographyStyled $perPageText={true}>
        candidates per page
      </TypographyStyled>
    </Grid>
  );
};
