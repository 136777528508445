import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { FilterTitle } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { updateSearchTalentFilters } from '../../../../redux/actions/talent_action';
import { WORK_RIGHTS } from '../../../../components/SharedAdvancedFilter/constants';
import { FilterContainerStyle } from '../styles';

const RightsPreference = () => {
  const dispatch = useDispatch();

  const filters = useSelector(state => state.talents.searchTalentFilters);

  const onCheckRights = e => {
    const cFilters = { ...filters };

    const value = e.target.value;
    switch (value) {
      case WORK_RIGHTS.SPONSOR:
        cFilters.openToSponsor = e.target.checked;
        break;

      case WORK_RIGHTS.VISA:
        cFilters.visaOrEmploymentPass = e.target.checked;
        break;

      case WORK_RIGHTS.LOCAL:
        cFilters.localCitizen = e.target.checked;
        break;
    }

    dispatch(updateSearchTalentFilters(cFilters));
  };

  return (
    <FilterContainerStyle>
      <FilterTitle>Right to Work</FilterTitle>

      <FormGroup style={{ margin: '0px 10px 0' }}>
        <CheckboxSelection
          value={WORK_RIGHTS.SPONSOR}
          checked={filters['openToSponsor']}
          onClick={onCheckRights}
          label={'Requires sponsorship'}
        />

        <CheckboxSelection
          value={WORK_RIGHTS.VISA}
          checked={filters['visaOrEmploymentPass']}
          onClick={onCheckRights}
          label={'Has an existing right to work'}
        />

        <CheckboxSelection
          value={WORK_RIGHTS.LOCAL}
          checked={filters['localCitizen']}
          onClick={onCheckRights}
          label={'Local Citizen / PR'}
        />
      </FormGroup>
    </FilterContainerStyle>
  );
};

export default RightsPreference;

export const CheckboxSelection = ({
  value,
  label,
  checked,
  onClick,
  disabled
}) => {
  return (
    <div
      key={label}
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
      <FormControlLabel
        control={
          <Checkbox
            value={value}
            checked={checked}
            onClick={onClick}
            disabled={disabled}
            sx={{
              '&.Mui-checked': {
                color: '#222'
              },
              padding: '2px 9px'
            }}
          />
        }
        label={label}
        sx={{
          '& .MuiTypography-root': {
            fontSize: 14,
            letterSpacing: '0.15px'
          },
          '& .MuiSvgIcon-root': {
            fontSize: 20
          }
        }}
      />
    </div>
  );
};
