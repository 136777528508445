import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../../styles/colors.module.scss';

export const Text = styling('p')((props) => ({
  fontFamily: 'Inter',
  
  ...(props.max_width && {
    maxWidth: props.max_width,
  }),

  ...(props.no_product_title == 'true' && {
    fontSize: '20px',
    fontWeight: 700,
    margin: '0',
    marginTop: '10px',
    color: Colors.terDarkerGrey,
  }),

  ...(props.no_product_desc == 'true' && {
    fontSize: '16px',
    marginTop: '18px',
    marginBottom: '25px',
    color: Colors.terDarkerGrey,
  }),
}));

export const PlaceholderContainer = styling('div')((props) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: '20px',
}));

export const EmptyPlaceholder = styling('img')((props) => ({
  marginTop: '15px',
  height: '230px',
}));
