import { styled as styling } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';

export const DialogContentTypography = styling(Typography)(props => ({
  fontSize: '14px'
}));

export const DialogFooterTypography = styling(Typography)(props => ({
  fontSize: '12px',
  letterSpacing: '0.15px',
  textAlign: 'center',
  color: `rgba(0, 0, 0, 0.60)`,
  lineHeight: '20px',
  marginTop: "24px"
}));

export const DialogTitleTypography = styling(Typography)(props => ({
  fontSize: '16px',
  fontWeight: '700'
}));

export const DialogBundleTypography = styling(Typography)(props => ({
  fontSize: '20px',
  fontWeight: '700'
}));

export const DefaultContainer = styling(Grid)(props => ({}));

export const ButtonContainer = styling(Grid)(props => ({
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const TitleContainer = styling(Grid)(props => ({
  padding: '20px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
}));
