import React, { useMemo } from 'react';
import { Card, CardMedia, Divider, Grid } from '@mui/material';
import SkeletonLoader from './SkeletonLoader';
import styles from './index.module.scss';
import { FiberManualRecord as FiberManualRecordIcon } from '@mui/icons-material';
import {
  BiodataContainer,
  CandidateAvatar,
  CardStyled,
  FirstContainer,
  ProfileContainer,
  ProfileSubContainer,
  SummaryContainer,
  Text,
  VideoContainer,
  SectionContainer,
  LabelContainer,
  SectionLabel,
  SubContainer
} from './styles';
import { connect } from 'react-redux';
import CloseLoop from '../../../../../components/CandidateSnapshot/CloseLoop';
import Linkify from 'react-linkify';
import {
  BriefcaseIcon,
  EnvelopeIcon,
  MapPinIcon,
  PhoneIcon
} from '@heroicons/react/24/solid';
import { sortWorkingExperiences } from '../../../../../helpers/data_management';
import {
  BoldText,
  IconStyle,
  NormalText,
  SubInfoContainer
} from '../../../../../components/CandidateSnapshot/CandidateSnapshotRichInfo/styles';
import {
  countryOptions,
  countryOptionsFullName
} from '../../../../../components/SharedSelectCountry/constants';
import {
  getCountry,
  getCountryFullName
} from '../../../../../hooks/useRegionalisation';

const CandidateInfoCard = props => {
  const {
    candidate,
    isCandidatesEmpty,
    loading,
    isSelected,
    setIsCloseLoopOpen
  } = props;

  const getWorkingExperiences = cCandidate => {
    if (cCandidate?.user?.workingExperiences?.edges) {
      const experiences = cCandidate?.user?.workingExperiences.edges.map(
        item => item.node
      );

      return sortWorkingExperiences(experiences);
    }

    return [];
  };

  const memoizedWorkingExperiences = useMemo(() => {
    return candidate => getWorkingExperiences(candidate);
  }, [candidate?.user?.workingExperiences?.edges]);

  const getEducations = cCandidate => {
    if (cCandidate?.user?.educations?.edges) {
      const educations = cCandidate?.user?.educations.edges.map(
        item => item.node
      );

      return educations.sort((a, b) => {
        try {
          const bId = parseInt(b.id);
          const aId = parseInt(a.id);

          return bId - aId;
        } catch (error) {
          //
        }

        return b.id - a.id;
      });
    }

    return [];
  };

  const memoizedEducations = useMemo(() => {
    return candidate => getEducations(candidate);
  }, [candidate?.user?.educations?.edges]);

  const isRelocateAndRemoteTrue = (relocate, remote) => {
    if (relocate && remote) {
      return true;
    }

    return false;
  };

  const getRightToWork = (
    openToSponsor,
    localCitizen,
    visaOrEmploymentPass
  ) => {
    if (openToSponsor || localCitizen || visaOrEmploymentPass) {
      if (openToSponsor) {
        return 'Open to sponsor';
      } else if (localCitizen) {
        return 'Local citizen';
      } else if (visaOrEmploymentPass) {
        return 'Has existing right to work';
      }
    } else {
      return 'Not provided';
    }
  };

  const getDestionationKey = () => {
    const workLocationPreference = candidate?.user?.workLocationPreference;
    const keys = Object.keys(workLocationPreference);
    const currentCountry = getCountry();

    if(keys.length > 0){
      return currentCountry;
    }
    
    return '';
  };

  const renderLocationWorkplace = () => {
    let destinationCountry = getDestionationKey();

    // if (destinationCountry != getCountry() && destinationCountry?.length > 0) {
    //   return;
    // }

    let relocate =
      candidate?.user?.workLocationPreference[destinationCountry]?.relocate ??
      null;
    let remote =
      candidate?.user?.workLocationPreference[destinationCountry]?.remote ??
      null;

    let openToSponsor =
      candidate?.user?.rightToWork[destinationCountry]?.openToSponsor ?? null;

    let localCitizen =
      candidate?.user?.rightToWork[destinationCountry]?.localCitizen ?? null;

    let visaOrEmploymentPass =
      candidate?.user?.rightToWork[destinationCountry]?.visaOrEmploymentPass ??
      null;

    const renderWorkLocationPreference = (preference, label, bothTrue) => {
      if (preference) {
        return preference ? (
          <NormalText $noPadding $list={bothTrue}>
            {label}
          </NormalText>
        ) : null;
      }
      return null;
    };

    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px',
            alignSelf: 'stretch'
          }}>
          <SectionContainer>
            <LabelContainer>
              <Grid display="flex" alignItems={'center'}>
                <SectionLabel>
                  Work Eligibility In {getCountryFullName()}
                </SectionLabel>
              </Grid>
            </LabelContainer>

            {/* {relocate == false && remote == false ? null : ( */}
            <SubContainer>
              <SubInfoContainer>
                <Grid display="flex" alignItems={'flex-start'}>
                  <BoldText $noPadding>Availability & Relocation</BoldText>
                </Grid>
                {(relocate == null && remote == null) ||
                (!relocate && !remote) ? (
                  <NormalText $noPadding>Not provided</NormalText>
                ) : (
                  <Grid display={'flex'} flexDirection={'column'}>
                    {renderWorkLocationPreference(
                      relocate,
                      // `Willing to relocate: ${countryOptionsFullName[destinationCountry]}`,
                      `Willing to relocate`,
                      isRelocateAndRemoteTrue(relocate, remote)
                    )}
                    {renderWorkLocationPreference(
                      remote,
                      'Remote work',
                      isRelocateAndRemoteTrue(relocate, remote)
                    )}
                  </Grid>
                )}
              </SubInfoContainer>

              <SubInfoContainer>
                <Grid display="flex" alignItems={'center'}>
                  <BoldText $noPadding>Right to Work</BoldText>
                </Grid>
                <NormalText $noPadding>
                  {getRightToWork(
                    openToSponsor,
                    localCitizen,
                    visaOrEmploymentPass
                  )}
                </NormalText>
              </SubInfoContainer>
            </SubContainer>
            {/* )} */}
          </SectionContainer>
          <Divider
            style={{ margin: '0', borderColor: '#D8D8D8', width: '100%' }}
          />
          <div></div>
        </div>
      </>
    );
  };

  return (
    <div className={styles.candidateInfoWrapper}>
      {isCandidatesEmpty ? (
        isSelected === null && loading && <SkeletonLoader />
      ) : (
        <div>
          {!isCandidatesEmpty ? (
            <>
              <BiodataContainer isVideoCV={candidate?.user?.videoCvOptimized}>
                <CardStyled isVideoCV={candidate?.user?.videoCvOptimized}>
                  <FirstContainer isVideoCV={candidate?.user?.videoCvOptimized}>
                    <ProfileContainer>
                      <div style={{ marginRight: '20px' }}>
                        <CandidateAvatar
                          key={candidate?.user?.profileImageSquare}
                          src={candidate?.user?.profileImageSquare}
                        />
                      </div>

                      <ProfileSubContainer>
                        <div>
                          <Text type="name">
                            Hi I'm {candidate?.user?.name}!
                          </Text>
                          <div style={{ display: 'flex' }}>
                            <Text type="age">
                              {candidate?.user?.age} years old
                            </Text>
                            <div style={{ transform: 'translateY(2px)' }}>
                              <FiberManualRecordIcon
                                style={{
                                  width: '0.2em',
                                  height: '0.2em',
                                  color: '#666666'
                                }}
                              />
                            </div>
                            <Text type="nationality">
                              {candidate?.user?.nationality}
                            </Text>
                          </div>
                        </div>
                      </ProfileSubContainer>
                    </ProfileContainer>

                    <SummaryContainer
                      isVideoCV={candidate?.user?.videoCvOptimized}>
                      <Text type="summary">
                        <Linkify>{candidate?.user?.shortSummary}</Linkify>
                      </Text>
                    </SummaryContainer>
                  </FirstContainer>

                  <div className={styles.candidateInfoLeftBottom}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '16px',
                        alignSelf: 'stretch'
                      }}>
                      <SectionContainer>
                        <LabelContainer>
                          <Grid display="flex" alignItems={'center'}>
                            <SectionLabel>Profile</SectionLabel>
                          </Grid>
                        </LabelContainer>

                        <SubContainer>
                          {candidate?.user?.email && (
                            <SubInfoContainer>
                              <Grid display="flex" alignItems={'center'}>
                                <BoldText $noPadding>Email</BoldText>
                              </Grid>
                              <NormalText $noPadding>
                                {candidate?.user?.email
                                  ? candidate?.user?.email
                                  : 'Not provided'}
                              </NormalText>
                            </SubInfoContainer>
                          )}

                          {candidate?.user?.mobileNumber && (
                            <SubInfoContainer>
                              <Grid display="flex" alignItems={'center'}>
                                <BoldText $noPadding>Phone Number</BoldText>
                              </Grid>
                              <NormalText $noPadding>
                                {candidate?.user?.mobileNumber
                                  ? candidate?.user?.mobileNumber
                                  : 'Not provided'}
                              </NormalText>
                            </SubInfoContainer>
                          )}

                          <SubInfoContainer>
                            <Grid display="flex" alignItems={'center'}>
                              <BoldText $noPadding>Current Location</BoldText>
                            </Grid>
                            <NormalText $noPadding>
                              {candidate?.user?.currentLocation ||
                                'Not provided'}
                            </NormalText>
                          </SubInfoContainer>
                        </SubContainer>
                      </SectionContainer>

                      {(candidate?.user?.email ||
                        candidate?.user?.mobileNumber ||
                        candidate?.user?.currentLocation) && (
                        <>
                          <Divider
                            style={{
                              margin: '0',
                              borderColor: '#D8D8D8',
                              width: '100%'
                            }}
                          />
                          <div></div>
                        </>
                      )}
                    </div>

                    {renderLocationWorkplace()}

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '16px',
                        alignSelf: 'stretch'
                      }}>
                      <SectionContainer>
                        <LabelContainer>
                          <Grid display="flex" alignItems={'center'}>
                            <SectionLabel>Background</SectionLabel>
                          </Grid>
                        </LabelContainer>

                        <SubContainer>
                          {memoizedEducations(candidate)?.length > 0 &&
                            memoizedEducations(candidate)[0]
                              ?.graduationYear && (
                              <SubInfoContainer>
                                <BoldText $noPadding>Graduation Year</BoldText>
                                <NormalText $noPadding>
                                  {
                                    memoizedEducations(candidate)[0]
                                      ?.graduationYear
                                  }
                                </NormalText>
                              </SubInfoContainer>
                            )}

                          {memoizedWorkingExperiences(candidate)?.length > 0 &&
                            memoizedWorkingExperiences(candidate)[0]
                              ?.companyName?.length > 0 && (
                              <SubInfoContainer>
                                <BoldText $noPadding>Current Company</BoldText>
                                <NormalText $noPadding>
                                  {
                                    memoizedWorkingExperiences(candidate)[0]
                                      ?.companyName
                                  }
                                </NormalText>
                              </SubInfoContainer>
                            )}

                          {memoizedEducations(candidate)?.length > 0 &&
                            memoizedEducations(candidate)[0]?.educationLevel
                              ?.length > 0 && (
                              <SubInfoContainer>
                                <BoldText $noPadding>
                                  Highest Level of Education
                                </BoldText>
                                <NormalText $noPadding>
                                  {
                                    memoizedEducations(candidate)[0]
                                      ?.educationLevel
                                  }
                                </NormalText>
                              </SubInfoContainer>
                            )}

                          {memoizedEducations(candidate)?.length > 0 &&
                            memoizedEducations(candidate)[0]
                              ?.educationalInstitution?.length > 0 && (
                              <SubInfoContainer>
                                <BoldText $noPadding>
                                  Educational Institute
                                </BoldText>
                                <NormalText $noPadding>
                                  {
                                    memoizedEducations(candidate)[0]
                                      ?.educationalInstitution
                                  }
                                </NormalText>
                              </SubInfoContainer>
                            )}

                          {memoizedEducations(candidate)?.length > 0 &&
                            memoizedEducations(candidate)[0]?.fieldOfStudy
                              ?.length > 0 && (
                              <SubInfoContainer>
                                <BoldText $noPadding>Field of Study</BoldText>
                                <NormalText $noPadding>
                                  {
                                    memoizedEducations(candidate)[0]
                                      ?.fieldOfStudy
                                  }
                                </NormalText>
                              </SubInfoContainer>
                            )}

                          {memoizedEducations(candidate)?.length > 0 &&
                            memoizedEducations(candidate)[0]?.major?.length >
                              0 && (
                              <SubInfoContainer>
                                <BoldText $noPadding>Major</BoldText>
                                <NormalText $noPadding>
                                  {memoizedEducations(user)[0]?.major}
                                </NormalText>
                              </SubInfoContainer>
                            )}
                        </SubContainer>
                      </SectionContainer>
                    </div>
                  </div>
                </CardStyled>

                {candidate?.user?.videoCvOptimized && (
                  <VideoContainer>
                    {candidate?.state !== 'undecided' &&
                      candidate?.state !== 'rejected' &&
                      candidate?.employerHiredStatus === null && (
                        <div
                          style={{
                            position: 'relative',
                            paddingBottom: '15px'
                          }}>
                          <Card
                            style={{
                              borderRadius: '16px',
                              width: '100%',
                              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)'
                            }}>
                            <CloseLoop
                              setIsCloseLoopOpen={setIsCloseLoopOpen}
                            />
                          </Card>
                        </div>
                      )}
                    <div>
                      <CardMedia
                        component="video"
                        controls
                        src={candidate?.user?.videoCvOptimized}
                      />
                    </div>
                  </VideoContainer>
                )}
              </BiodataContainer>
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateInfoCard);
