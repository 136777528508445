import React, { useEffect, useState } from 'react';
import ContentCard from '../components/ContentCard';
import ViewState from './ViewState';
import EditState from './EditState';
import { useDispatch, useSelector } from 'react-redux';
import { updateCompanySidebar } from '../../../redux/actions/company_actions';

export default function CompanyBenefits() {
  const companyProfile = useSelector(state => state.companies.companyProfile);
  const [view, setView] = useState(false);
  const dispatch = useDispatch();
  const companyCompletion = useSelector(
    state => state.companies.companyCompletion
  );

  useEffect(() => {
    if (companyProfile?.benefits?.length > 0) {
      setView(true);
      dispatch(
        updateCompanySidebar({
          ...companyCompletion,
          companyBenefits: true
        })
      );
    } else {
      setView(false);
      dispatch(
        updateCompanySidebar({
          ...companyCompletion,
          companyBenefits: false
        })
      );
    }
  }, [companyProfile]);

  return (
    <div id="Company Benefits">
      {view ? <ViewState setView={setView} /> : <EditState setView={setView} />}
    </div>
  );
}
