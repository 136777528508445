import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import styles from './index.module.scss';

const SkeletonLoader = ({ customClassName }) => (
  <div className={classNames(styles.skeletonContainer, customClassName)}>
    <div>
      <Skeleton variant="text" className={styles.skeletonTitle} />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
    </div>
    <div className={styles.cardFooterContainer}>
      <div className={styles.dateContainer}>
        <Skeleton variant="circle" className={styles.skeletonCircle} />
        <Skeleton variant="text" className={styles.skeletonDateRect} />
      </div>
      <Skeleton
        variant="rect"
        height={35}
        width={110}
        className={styles.skeletonStatus}
      />
    </div>
  </div>
);

export default SkeletonLoader;
