import { PencilSquareIcon } from '@heroicons/react/24/solid';
import {
  Button,
  Card,
  Grid,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { styled as styling } from '@mui/material/styles';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

export const CardStyled = styling(Card)(props => ({
  borderRadius: '8px',
  boxShadow: 'none',
  border: '1px solid #D8D8D8',
  height: props.country == 'SG' ? '94%' : 'auto'
}));

export const EditIconStyled = styling(PencilSquareIcon)({
  width: '20px',
  height: '20px'
});

export const EditButtonStyled = styling(Button)({
  color: '#512acc',
  textTransform: 'capitalize',
  columnGap: '8px',
  padding: '0px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Inter'
});

export const PaymentButtonStyled = styling(Button)({
  textTransform: 'capitalize',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: '700',
  borderRadius: '8px',
  boxShadow: 'none',
  height: '42px',
  padding: '0px 16px',

  '&:hover': {
    boxShadow: 'none'
  }
});

export const GridStyled = styling(Grid)({
  //
});

export const BoldTitleText = styling(Typography)(props => ({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '24px',
  letterSpacing: '0.1px'
}));

export const BillingDetailTitle = styling(Typography)(props => ({
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: '700',
  color: '#00000099',
  letterSpacing: '0.1px',
  fontFamily: 'Inter'
}));

export const TextFieldStyled = styling(TextField)({
  borderRadius: '8px',
  borderColor: '#AEAEAE'
});

export const CountrySelectStyled = styling(CountryDropdown)({
  width: '100%',
  height: '100%',
  borderRadius: '8px',
  padding: '16.5px 14px',
  fontFamily: 'Inter',
  background: 'none',
  lineHeight: 1.1876,
  letterSpacing: '0.00938em',
  cursor: 'pointer',
  fontSize: '1rem',
  color: 'rgba(0, 0, 0, 0.87)',
  border: '1px solid #AEAEAE',

  '::-ms-expand': {
    display: 'none'
  },

  '-moz-appearance': 'none',

  '-webkit-apearance': 'none',
  appearance: 'none',

  backgroundImage: `url(
    'data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox%3D%220%200%20312%20312%22%3E%3Cpath fill%3D%22rgba(0, 0, 0, 0.87)%22 d%3D%22M156%20186.5l-140-140c-4.7-4.7-12.3-4.7-17%200s-4.7%2012.3%200%2017l147%20147c4.7%204.7%2012.3%204.7%2017%200l147-147c4.7-4.7%204.7-12.3%200-17s-12.3-4.7-17%200z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E'
  )`,
  backgroundRepeat: 'no-repeat, repeat',
  backgroundPosition: 'right 16px top 50%, 0 0',
  backgroundSize: '.65em auto, 100%'
});

export const RegionSelectStyled = styling(RegionDropdown)({
  width: '100%',
  height: '100%',
  borderRadius: '8px',
  padding: '16.5px 14px',
  fontFamily: 'Inter',
  background: 'none',
  lineHeight: 1.1876,
  letterSpacing: '0.00938em',
  cursor: 'pointer',
  fontSize: '1rem',
  color: 'rgba(0, 0, 0, 0.87)',
  border: '1px solid #AEAEAE',

  '::-ms-expand': {
    display: 'none'
  },

  '-moz-appearance': 'none',

  '-webkit-apearance': 'none',
  appearance: 'none',

  backgroundImage: `url(
    'data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox%3D%220%200%20312%20312%22%3E%3Cpath fill%3D%22rgba(0, 0, 0, 0.87)%22 d%3D%22M156%20186.5l-140-140c-4.7-4.7-12.3-4.7-17%200s-4.7%2012.3%200%2017l147%20147c4.7%204.7%2012.3%204.7%2017%200l147-147c4.7-4.7%204.7-12.3%200-17s-12.3-4.7-17%200z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E'
  )`,
  backgroundRepeat: 'no-repeat, repeat',
  backgroundPosition: 'right 16px top 50%, 0 0',
  backgroundSize: '.65em auto, 100%'
});
