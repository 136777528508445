import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CardContainer,
  CardSection,
  CardSubtitle,
  CardTitle,
  ChipContainer,
  ButtonThumbsUp,
  ButtonThumbsDown,
  SecondCardContainer,
  ErrorMessage,
  ButtonContainer,
  SubmitButton,
  BackButton,
  ChipText,
  IconButtonStyled,
  SecondContainer,
  SecondSubContainer,
  SimpleDescription,
  TopSection,
  BottomSection
} from './styles';
import {
  CircularProgress,
  FormControl,
  OutlinedInput,
  Skeleton
} from '@mui/material';
import { ErrorToast, InfoToast, SuccessToast } from '../../../utils/ToastUtils';
import {
  HandThumbUpIcon,
  HandThumbDownIcon
} from '@heroicons/react/24/outline';
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  XMarkIcon
} from '@heroicons/react/24/solid';
import {
  updateFeedbackCardStatus,
  updateTalentsList,
  updateFeedbackForm,
  updateFeedbackPage
} from '../../../redux/actions/talent_action';
import { mutateTalentSearchFeedback } from '../../../redux/actions/job_action';

const Feedback = ({ loading }) => {
  const dispatch = useDispatch();
  const searchedKeyword = useSelector(state => state.talents.searchedKeyword);
  const showFeedbackCard = useSelector(
    state => state.talents?.feedbackTalent[10]?.displayFeedback
  );
  const submitFeedback = useSelector(state => state.talents.submitFeedback);
  const makeNewSearch = useSelector(state => state.talents.makeNewSearch);
  const talentList = useSelector(state => state.talents.talents);

  const toShowCard = () => {
    if (!showFeedbackCard) {
      return false;
    } else {
      if (submitFeedback === false && makeNewSearch === false) {
        return false;
      } else if (submitFeedback === true && makeNewSearch === true) {
        return false;
      } else if (submitFeedback === true && makeNewSearch === false) {
        return false;
      } else if (submitFeedback === false && makeNewSearch === true) {
        return true;
      }
    }
  };

  const isShow = toShowCard();

  const CHARACTER_LIMIT = 200;

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const feedbackForm = useSelector(state => state.talents.feedbackForm);
  const feedbackPage = useSelector(state => state.talents.feedbackPage);

  const [exceededCharacterLimit, setExceededCharacterLimit] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');

  const handleFieldChange = e => {
    let { name, value } = e.target || e;

    if (!e.target) {
      ({ name, value } = e);
    }

    dispatch(
      updateFeedbackForm({
        ...feedbackForm,
        [name]: value
      })
    );

    if (name == 'feedback') {
      // const wordCount = value.split(/\s+/).filter(word => word.length > 0).length;
      // setExceededCharacterLimit(wordCount);
      const count = value.length;
      setExceededCharacterLimit(count);

      if (count <= 200) {
        setError(false);
      }
    }
  };

  const handleOptionClick = rating => {
    dispatch(
      updateFeedbackForm({
        ...feedbackForm,
        rating
      })
    );

    dispatch(updateFeedbackPage('1'));
    setError(false);
  };

  const firstSlide = () => {
    return (
      <>
        <CardContainer>
          <CardSection>
            <CardTitle>Are these results relevant?</CardTitle>

            <CardSubtitle>Help us improve your search results.</CardSubtitle>
          </CardSection>

          <ChipContainer>
            {/* button up */}
            <ButtonThumbsUp
              sx={{
                '&.MuiButtonBase-root': {
                  backgroundColor: '#FFF',
                  border: '1px solid rgba(0, 0, 0, 0.60)',
                  ':hover': {
                    backgroundColor: '#E7F5E3',
                    border: '1px solid #7A9D2F'
                  }
                }
              }}
              onMouseEnter={() => setSelectedOption(true)}
              onMouseLeave={() => setSelectedOption('')}
              onClick={() => handleOptionClick(true)}>
              <HandThumbUpIcon
                color={
                  selectedOption == ''
                    ? 'rgba(0, 0, 0, 0.60)'
                    : selectedOption == true
                    ? '#7A9D2F'
                    : 'rgba(0, 0, 0, 0.60)'
                }
                width={20}
                height={20}
              />
            </ButtonThumbsUp>

            {/* button down */}
            <ButtonThumbsDown
              sx={{
                '&.MuiButtonBase-root': {
                  backgroundColor: '#FFF',
                  border: '1px solid rgba(0, 0, 0, 0.60)',
                  ':hover': {
                    backgroundColor: '#F5E1E1',
                    border: '1px solid #BE4242'
                  }
                }
              }}
              onMouseEnter={() => setSelectedOption(false)}
              onMouseLeave={() => setSelectedOption('')}
              onClick={() => handleOptionClick(false)}>
              <HandThumbDownIcon
                color={
                  selectedOption == ''
                    ? 'rgba(0, 0, 0, 0.60)'
                    : selectedOption == false
                    ? '#BE4242'
                    : 'rgba(0, 0, 0, 0.60)'
                }
                width={20}
                height={20}
              />
            </ButtonThumbsDown>
          </ChipContainer>
        </CardContainer>
      </>
    );
  };

  const secondSlide = () => {
    return (
      <>
        <SecondCardContainer>
          {/* Top */}
          <TopSection>
            <CardTitle>Thank you for your feedback!</CardTitle>

            <SecondContainer>
              <SecondSubContainer>
                <CardSubtitle>
                  Would you like to tell us why? <i>(optional)</i>{' '}
                </CardSubtitle>

                <ChipText error={exceededCharacterLimit > 200}>
                  {`${exceededCharacterLimit}/${CHARACTER_LIMIT}`}
                </ChipText>
              </SecondSubContainer>

              <FormControl sx={{ width: '100%' }}>
                <OutlinedInput
                  placeholder="Leave your comment here."
                  multiline={true}
                  variant="outlined"
                  fullWidth
                  rows={4}
                  style={{
                    background: '#FFF',
                    padding: '10px 16px',
                    color: 'rgba(0, 0, 0, 0.87)',
                    fontSize: '14px',
                    lineHeight: '24px',
                    letterSpacing: '0.15px'
                  }}
                  name="feedback"
                  onChange={handleFieldChange}
                  error={error || exceededCharacterLimit > 200}
                />
              </FormControl>

              {(error || exceededCharacterLimit > 200) && (
                <ErrorMessage>
                  <ExclamationCircleIcon
                    style={{
                      width: '16px',
                      height: '16px',
                      color: '#F75443'
                    }}
                  />
                  <p>You have exceeded the character limit.</p>
                </ErrorMessage>
              )}
            </SecondContainer>
          </TopSection>
          <ButtonContainer>
            <BackButton onClick={() => submitForm()} disabled={isLoading}>
              Skip
            </BackButton>

            <SubmitButton
              onClick={() => submitForm()}
              disabled={isLoading}
              loading={isLoading}
              loadingIndicator={
                <CircularProgress
                  sx={{ color: 'white !important' }}
                  size={16}
                />
              }>
              Submit
            </SubmitButton>
          </ButtonContainer>
        </SecondCardContainer>
      </>
    );
  };

  const thirdSlide = () => {
    return (
      <>
        <CardContainer>
          <SimpleDescription>
            <CheckCircleIcon
              style={{
                width: '20px',
                height: '20px',
                color: '#90B938'
              }}
            />

            <CardSubtitle>Thank you for your feedback!</CardSubtitle>
          </SimpleDescription>

          <IconButtonStyled onClick={closeTab}>
            <XMarkIcon
              style={{
                width: '20px',
                height: '20px',
                color: 'rgba(0, 0, 0, 0.60)'
              }}
            />
          </IconButtonStyled>
        </CardContainer>
      </>
    );
  };

  const submitForm = async () => {
    if (exceededCharacterLimit > 200) {
      setError(true);
      return;
    }

    // form submission logic
    setIsLoading(true);
    try {
      const res = await dispatch(
        mutateTalentSearchFeedback({
          input: {
            rating: feedbackForm?.rating,
            feedback: feedbackForm?.feedback,
            searchTerm: searchedKeyword
          }
        })
      );

      if (res?.type == 'TALENT_SEARCH_FEEDBACK_FAILED') {
        ErrorToast(res?.error);
        setIsLoading(false);
        return;
      } else {
        // SuccessToast('Feedback received successfully!');
        dispatch(updateFeedbackPage('2'));
      }
    } catch (err) {
      setIsLoading(false);
      ErrorToast(err.message);
    }
  };

  const closeTab = async () => {
    dispatch(updateFeedbackCardStatus(true));
    dispatch(updateTalentsList([...talentList]));
    return;
  };

  return (
    <>
      {isShow && (
        <>
          <div>
            {feedbackPage == '0' && <>{firstSlide()}</>}

            {feedbackPage == '1' && <>{secondSlide()}</>}

            {feedbackPage == '2' && <>{thirdSlide()}</>}
          </div>
        </>
      )}
    </>
  );
};

export default Feedback;
