import React from 'react';
import {
  CloseIconStyled,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
  IconButtonStyled,
  TitleContainer,
  TitleStyled
} from './styles';
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import {
  ButtonSkipEdit,
  ButtonText
} from '../../../../../components/VerifyPopUp/styles';

export default function SubmitPopUp(props) {
  const {
    open,
    handleClose,
    selected,
    handleSubmitVideo,
    submitType,
    submitContentIndex
  } = props;

  const popUpType = [
    {
      type: 'EDIT LIVE',
      title: 'Submit Request for New Content?',
      content:
        'By continuing, the new content will be submitted to our team for review, which would take 1-3 days. <br></br> Once approved, it will override the existing content. Would you like to continue?'
    },
    {
      type: 'EDIT REVIEW',
      title: 'Submit Request for New Content?',
      content:
        'By continuing, the new content will replace the previous content in review and will be submitted to our team as a new submission for review, which would take another 1-3 days. <br></br> Would you like to continue?'
    },
    {
      type: 'EDIT REJECT',
      title: 'Submit Request for New Content?',
      content:
        'By continuing, the new content will replace the previous content rejected and will be submitted to our team as a new submission for review, which would take another 1-3 days. <br></br> Would you like to continue?'
    },
    {
      type: 'EDIT REVIEW WITH LIVE',
      title: 'Submit Request for New Content?',
      content:
        'By continuing, the new content will replace the previous content in review and will be submitted to our team as a new submission for review, which would take another 1-3 days. <br></br> Once approved, it will override the existing content. Would you like to continue?'
    },
    {
      type: 'EDIT REJECT WITH LIVE',
      title: 'Submit Request for New Content?',
      content:
        'By continuing, the new content will replace the previous content rejected and will be submitted to our team as a new submission for review, which would take another 1-3 days. <br></br> Once approved, it will override the existing content. Would you like to continue?'
    }
  ];

  const type = popUpType?.find(type => type?.type === submitType);

  return (
    <DialogStyled open={open} onClose={handleClose}>
      <DialogTitleStyled>
        <TitleContainer>
          <TitleStyled>{type?.title}</TitleStyled>
        </TitleContainer>
        <IconButtonStyled
          aria-label="close"
          onClick={handleClose}
          sx={{ padding: '0px' }}>
          <CloseIconStyled />{' '}
        </IconButtonStyled>
      </DialogTitleStyled>

      <DialogContentStyled dividers>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px'
            // padding: '16px 24px'
          }}>
          <Typography sx={{ fontSize: '16px', lineHeight: '24px' }}>
            <div
              style={{ margin: '0px !important', padding: '0px !important' }}
              dangerouslySetInnerHTML={{ __html: type?.content }}
            />
          </Typography>
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}>
            <ButtonText sx={{ height: '42px' }} onClick={() => handleClose()}>
              No Thanks
            </ButtonText>
            <ButtonSkipEdit
              sx={{ height: '42px' }}
              onClick={() => {
                handleClose();
                handleSubmitVideo({
                  type: submitType,
                  id: selected?.id,
                  index: submitContentIndex
                });
              }}>
              Confirm
            </ButtonSkipEdit>
          </Grid>
        </Grid>
      </DialogContentStyled>
    </DialogStyled>
  );
}
