import { styled as styling } from '@mui/material/styles';

export const RightBox = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  alignSelf: 'stretch',
  minHeight: '640px'
}));

export const RightBoxInner = styling('div')(props => ({
  display: 'flex',
  width: '673px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px'
}));

export const TitleBox = styling('div')(props => ({
  display: 'flex',
  padding: '10px 0px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
  alignSelf: 'stretch'
}));

export const Title = styling('span')(props => ({
  color: 'rgba(0, 0, 0, 0.87)',
  textAlign: 'center',
  fontSize: '20px',
  lineHeight: '24px',
  fontWeight: '700'
}));

export const Subtitle = styling('span')(props => ({
  alignSelf: 'stretch',
  color: 'rgba(0, 0, 0, 0.60)',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const Container = styling('div')(props => ({
  display: 'flex',
  padding: '48px 40px',
  alignItems: 'flex-start',
  gap: '146px',
  flex: '1 0 0',
  alignSelf: 'stretch',
  justifyContent: 'center'
}));

export const StepsContainer = styling('div')(props => ({
  maxWidth: '250px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '32px',
  borderLeft: '1px solid #D8D8D8',
  position: 'relative'
}));

export const Steps = styling('div')(props => ({
  display: 'flex',
  height: '64px',
  padding: '10px 8px 10px 24px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const StepsText = styling('p')(props => ({
  color: props.disabled? 'rgba(0, 0, 0, 0.60)' : '#40249B',
  fontSize: '16px',
  fontWeight: props.active ? '700' :'400',
  lineHeight: '24px',
  letterSpacing: props.active ? '0.1px' : '0.15px',
  
}));

export const PurpleLine = styling('div')(props => ({
  position: 'absolute',
  top: 0,
  left: '-1px',
  width: '1px',
  height: '257px',
  backgroundColor: '#40249B',
  zIndex: '1'
}));

export const PurpleLineActive = styling('div')(props => ({
  position: 'absolute',
  top: '193px',
  left: '-5px',
  width: '4px',
  height: '64px',
  backgroundColor: '#40249B',
  zIndex: '1'
}));
