import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ProductCatSkeleton = styled(Skeleton)({
  height: '32px',
  width: '124px',
  borderRadius: '8px',
  padding: '10px',
  transform: 'none'
});

export const CatTitleSkeleton = styled(Skeleton)({
  height: '18px',
  width: '121px',
  borderRadius: '8px',
  padding: '10px',
  transform: 'none',
  margin: '8px 24px'
});

export const NoWidthSkeleton = styled(Skeleton)({
  height: '18px',
  borderRadius: '8px',
  padding: '10px',
  transform: 'none'
});

export const ProductSkeleton = styled(Skeleton)(props => ({
  width: 'auto',
  minWidth: "300px",
  height: '300px',
  borderRadius: '8px',
  padding: '10px',
  transform: 'none'
}));

export const CartProductIconSkeleton = styled(Skeleton)({
  transform: 'none',
  width: '70px',
  height: '70px',
  borderRadius: '8px'
});

export const ReviewCartItemSkeleton = styled(Skeleton)({
  transform: 'none',
  width: '100%',
  height: '38px',
  borderRadius: '8px'
});

export const OnlinePaymentSkeleton = styled(Skeleton)({
  transform: 'none',
  width: '100%',
  height: '42px',
  borderRadius: '8px'
});
