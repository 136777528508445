import Colors from '../../../../../styles/colors.module.scss';
import { styled as styling } from '@mui/material/styles';
import {
  Typography,
  IconButton,
  Grid,
  Divider,
  MenuItem,
  FormControl,
  Select,
  TextField,
  InputAdornment,
  Input,
  Button,
  CircularProgress
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import makeStyles from '@mui/styles/makeStyles';

export const TextFieldStyled = styling(TextField)({  
  '& .MuiInputBase-root': {
    borderRadius: '8px',
    fontSize: '14px',
    fontFamily: 'Inter'
  }
});

export const CancelButtonStyled = styling(Button)({
  height: '30px',
  width: '112px',
  backgroundColor: 'white',
  border: '2px solid black',
  color: 'black',
  padding: '0px, 16px, 0px, 16px',
  borderRadius: '100px',
  textTransform: 'none',

  ':hover': {
    backgroundColor: 'white',
    color: 'black'
  }
});

export const SaveButtonStyled = styling(Button)({
  height: '30px',
  width: '112px',
  backgroundColor: 'black',
  color: 'white',
  padding: '0px, 16px, 0px, 16px',
  borderRadius: '100px',
  textTransform: 'none',

  ':disabled': {
    color: Colors.priWhite
  },

  ':hover': {
    backgroundColor: 'black',
    color: 'white'
  }
});

export const LoadingStyled = styling(CircularProgress)({});

export const InputStyled = styling(Input)({
  height: '40px',
  borderRadius: '8px'
});

export const MenuItemStyled = styling(MenuItem)({
  fontSize: '16px',
  fontFamily: 'Inter'
});

export const FormControlStyled = styling(FormControl)({
  width: '150px',
  maxHeight: '28px',
  backgroundColor: `rgba(239, 239, 239, 1)`,
  border: 'none',
  overflow: 'hidden',

  '& .MuiOutlinedInput-root': {
    border: 'none',
    borderRadius: '8px'
  },

  '&.MuiFormControl-root': {
    borderRadius: '8px'
  }
});

export const SelectStyled = styling(Select)({
  maxHeight: '28px',
  overflow: 'hidden',

  '& .MuiSelect-root': {
    borderRadius: '4px'
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
});

export const InputAdornmentStyled = styling(InputAdornment)({});

export const TitleTypography = styling(Typography)({
  fontSize: '14px',
  fontFamily: 'Inter'
});

export const TimeTypography = styling(Typography)({
  fontSize: '13px',
  color: `rgba(0, 0, 0, 0.6)`,
  fontStyle: 'italic',
  fontFamily: 'Inter'
});

export const ContentTypography = styling(Typography)({
  fontSize: '16px',
  fontFamily: 'Inter'
});

export const IconButtonStyled = styling(IconButton)({});

export const EditIconStyled = styling(EditIcon)({
  fontSize: '20px'
});

export const SendIconStyled = styling(SendIcon)({
  fontSize: '20px',
  color: '#512ACC'
});

export const Wrapper = styling(Grid)({
  width: 'auto'
});

export const OuterContainer = styling(Grid)({
  width: 'auto'
});

export const DividerStyled = styling(Divider)({
  height: '1px'
});

export const ProfileContainer = styling(Grid)({
  // padding: '16px 24px',
  // padding: '16px 0',
  width: '100%'
  // backgroundColor: 'lightblue'
});

export const CustomDivider = styling('span')({
  height: '1px',
  width: '100%',
  background: `${Colors.terLightGrey}`
});

export const useStyles = makeStyles(theme => ({
  scrollBar: {
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#d8d8d8',
      width: 4,
      overflow: 'auto'
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: '#512acc',
      minHeight: 24,
      overflow: 'auto'
    },
    '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
      borderRadius: 8,
      backgroundColor: '#512acc',
      minHeight: 24
    }
  }
}));
