import { styled as styling } from '@mui/material/styles';
import { Close as CloseIcon } from '@mui/icons-material';

export const CloseIconStyled = styling(CloseIcon)(props => ({
  color: '#FFF',
  fontSize: '20px',

  '&:hover': {
    cursor: 'pointer',
  },
}));
