import { styled as styling } from '@mui/material/styles';
import { Grid, Typography, TextField, Button } from '@mui/material';
import {
  ChevronUpIcon,
  ChevronDownIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';

export const BenefitContainer = styling(Grid)(props => ({
  backgroundColor: 'white',
  width: 'auto',
  border: '1px solid #D8D8D8',
  // height: '456px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '8px'
}));

export const BenefitContent = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column'
}));

export const BenefitText = styling(Typography)(props => ({
  color: props?.error ? '#f75443' : '#000',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const ChevronUpIconStyled = styling(ChevronUpIcon)(props => ({
  color: props?.disabled ? '#00000038' : '#00000099',
  height: '20px',
  width: '20px'
}));

export const ChevronDownIconStyled = styling(ChevronDownIcon)(props => ({
  color: props?.disabled ? '#00000038' : '#00000099',
  height: '20px',
  width: '20px'
}));

export const XMarkIconStyled = styling(XMarkIcon)(props => ({
  color: '#00000099',
  height: '20px',
  width: '20px'
}));

export const TextFieldStyled = styling(TextField)(
  ({ theme, small, value, select, bold, white, icon, error }) => ({
    width: '100%',
    // Adjust the padding of the input field to change its height
    '& .MuiInputBase-root': {
      fontSize: '0.85rem',
      ...(icon && {
        height: '60px'
      }),
      color:
        value === 'placeholder' && select
          ? '#d8d8d8'
          : white
          ? 'white'
          : 'black', // Text color
      fontWeight: bold && 'bold',
      ...(small && {
        height: '45px',
        '& .MuiOutlinedInput-input': {
          '&::placeholder': {
            color: '#d8d8d8 !important', // Change to desired placeholder color
            opacity: 1 // Ensure the placeholder is fully visible
          },
          paddingTop: '10px', // Reduced top padding
          paddingBottom: '5px', // Increased bottom padding to move text down
          paddingLeft: '14px', // Maintain side paddings
          paddingRight: '14px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
          top: 0
        }
      })
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        //   borderColor: white ? 'white' : '#d8d8d8' // Border color
        border: error ? `1px solid #F75443 !important` : `1px solid #D8D8D8`
      },
      '&:hover fieldset': {
        //   borderColor: white ? 'white' : '#d8d8d8' // Border color on hover
        border: '1px solid #AEAEAE'
      },
      '&.Mui-focused fieldset': {
        //   borderColor: white ? 'white' : '#512ACC' // Border color on focus
        border: '1px solid #512ACC'
      }
    },
    ...(small && {
      height: '45px' // Adjust the height of the entire component if small is true
    })
  })
);

export const SetIconButton = styling(Button)(props => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  width: 'fit-content',
  height: '48px',
  backgroundColor: 'transparent',
  border: '1px solid #D8D8D8',
  borderRadius: '8px',
  gap: '12px',
  padding: '0px 8px 0px 8px',
  color: 'black',
  ':hover': {
    backgroundColor: 'transparent',
    border: "1px solid #AEAEAE"
  }
}));

export const IconListWrapper = styling(Grid)(props => ({
  position: 'absolute',
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '12px',
  rowGap: '8px',
  width: 'auto',
  backgroundColor: 'white !important',
  borderRadius: '8px !important',
  border: '1px solid #D8D8D8',
  boxShadow: '10px !important',
  zIndex: 100,
  padding: '12px',
  top: '85px',
  boxShadow: `2px 4px 10px 0px rgba(0, 0, 0, 0.12)`
}));

export const IconWrapper = styling(Grid)(props => ({
  display: 'flex',
  backgroundColor: props?.selected ? '#EFEFEF' : '',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'fit-content',
  borderRadius: '8px',
  padding: '8px',
  ':hover': { backgroundColor: '#D8D8D8' }
}));
