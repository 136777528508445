import React from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Colors from '../../styles/colors.module.scss';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    '& > * + *': {
      margin: "16px"
    },
    paddingTop: '20px'
  },
  noPadding: {
    paddingTop: '0'
  }
}));

export default function Spinner({ size, color, noPadding }) {
  const classes = useStyles();

  const getSize = () => {
    switch (size) {
      default:
        return '60px';
      case 'SMALL':
        return '30px';
      case 'BIG':
        return '60px';
      case 'TINY':
        return '18px';
      case 'XS':
        return '25px';
    }
  };

  const getColor = () => {
    switch (color) {
      default:
        return Colors.priPurple;
      case 'white':
        return 'white';
    }
  };

  return (
    <div className={`${classes.root} ${noPadding && classes.noPadding}`}>
      <CircularProgress size={getSize()} style={{ color: getColor() }} />
    </div>
  );
}

Spinner.propTypes = {
  size: PropTypes.oneOf(['SMALL', 'BIG', 'TINY', 'XS'])
};

Spinner.defaultProps = {
  size: 'BIG'
};
