import { styled as styling } from '@mui/material/styles';
import { Divider, Grid } from '@mui/material';
import Colors from '../../styles/colors.module.scss';

export const FooterBg = styling(Grid)({
  width: '100vw',
  position: 'relative',
  bottom: '0px',
  backgroundColor: "white",
  whiteSpace: 'nowrap',
  overflow: 'auto'
});

export const FooterContainer = styling(Grid)({
  width: '100%',
  background: Colors.priWhite,
  padding: '20px 40px',
  position: 'relative',
  whiteSpace: 'nowrap',
  overflow: 'auto',
  maxWidth: '1920px',
  margin: 'auto'
});

export const CopyrightText = styling('span')({
  fontSize: '12px',
  fontFamily: 'Inter',
  cursor: 'default',
  fontWeight: 400
});

export const AnchorText = styling('a')({
  fontSize: '12px',
  fontFamily: 'Inter',
  cursor: 'pointer',
  textDecoration: 'none',
  color: Colors.priBlack,
  fontWeight: 400
});

export const DividerStyled = styling(Divider)({
  height: '16px',
  width: '1.5px',
  background: Colors.priBlack
});
