import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../../styles/colors.module.scss';
import {
  SparklesIcon,
  ArrowPathIcon,
  StarIcon
} from '@heroicons/react/24/solid';
import { GlobeAltIcon } from '@heroicons/react/24/outline';

export const VisualBoosterIconsContainer = styling('div')(props => ({
  background: Colors.terLighterGrey,
  width: '42px',
  height: '42px',
  borderRadius: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 3,

  '&:hover': {
    cursor: props.disabled ? 'default' : 'pointer',
    background: props.disabled ? Colors.terLighterGrey : Colors.terLightGrey
  }
}));

export const VisualBoosterContainer = styling('div')(() => ({
  marginLeft: '40px',
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  position: 'relative'
}));

export const Title = styling('span')(() => ({
  fontSize: '16px',
  fontFamily: 'Inter',
  letterSpacing: '0.15px'
}));

export const TooltipText = styling('span')(() => ({
  fontWeight: 500,
  color: Colors.priBlack,
  fontSize: '12px'
}));

export const FontAwesomeIconStyled = styling(FontAwesomeIcon)(props => ({
  color:
    props.progress > 0 && props.progress <= 25
      ? Colors.secLightOrange
      : props.disabled || (props.disabled && props.progress > 25)
      ? Colors.terMediumGrey
      : Colors.priPurple,

  ...(props.jobType == 'job_slot_max' &&
    !props.disabled && {
      color: '#6EC7CD'
    }),

  fontSize: '15px'
}));

export const SparklesIconStyled = styling(SparklesIcon)((props) => ({
  height: '20px',
  width: '20px',
  color:
    props.progress > 0 && props.progress <= 25
      ? Colors.secLightOrange
      : props.disabled || (props.disabled && props.progress > 25)
      ? Colors.terMediumGrey
      : Colors.priPurple
}));

export const GlobeAltIconStyled = styling(GlobeAltIcon)((props) => ({
  height: '20px',
  width: '20px',
  color: props.progress > 0 && props.progress <= 25
    ? Colors.secLightOrange
    : props.disabled || (props.disabled && props.progress > 25)
    ? Colors.terMediumGrey
    : Colors.priPurple,
}));

export const ArrowPathIconStyled = styling(ArrowPathIcon)(props => ({
  height: '20px',
  width: '20px',
  color:
    props.progress > 0 && props.progress <= 25
      ? Colors.secLightOrange
      : props.disabled || (props.disabled && props.progress > 25)
      ? Colors.terMediumGrey
      : Colors.priPurple
}));

export const StarIconStyled = styling(StarIcon)(props => ({
  height: '20px',
  width: '20px',
  color:
    props.progress > 0 && props.progress <= 25
      ? Colors.secLightOrange
      : props.disabled || (props.disabled && props.progress > 25)
      ? Colors.terMediumGrey
      : Colors.priPurple
}));

export const CircularProgressContainer = styling('div')(() => ({
  position: 'absolute',
  top: '-3px',
  zIndex: -1,
  left: '-3px'
}));
