import { styled as styling } from '@mui/material/styles';
import { Info as InfoIcon } from '@mui/icons-material';

export const StrengthBar = styling('progress')({
  width: '100%',
  height: '50px',
  margin: '7px 0',
});

export const StrengthColor = styling('span')((props) => ({
  fontWeight: 700,
  ...(props.password_strength == 'poor' && {
    color: '#ff4758'
  }),

  ...(props.password_strength == 'average' && {
    color: '#fca80b'
  }),

  ...(props.password_strength == 'strong' && {
    color: '#22ad5c'
  }),
}));

export const MessageTooltip = styling('div')({
  padding: '10px 20px',
  background: '#e8e8e8',
  position: 'absolute',
  top: '30px',
  left: '10px',
  zIndex: 10,
  opacity: 0,
  borderRadius: '10px',
});

export const MessageContainer = styling('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  // &:hover ${MessageTooltip} {
  '&:hover div': {
    animation: 'fadeInAnimation ease 1s',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
  },
  '@keyframes fadeInAnimation': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 0.5,
    },
    '100%': {
      opacity: 1,
    },
  },
});

export const InfoIconStyled = styling(InfoIcon)({
  margin: '0 5px',
  color: '#222',
  fontSize: '18px',
});
