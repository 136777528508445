import React, { useEffect, useState } from 'react';
import ViewState from './ViewState';
import EditState from './EditState';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { updateCompanySidebar } from '../../../redux/actions/company_actions';

const validationSchema = yup.object().shape({
  companyDescriptionTitle: yup
    .string()
    .required('Company description title is required'), // Make field required

  companyDescriptionBody: yup
    .string()
    .test('isNotEmpty', 'Company description body is required', value => {
      return value !== '<p></p>';
    }),

  companyMissionTitle: yup
    .string()
    .required('Company mission title is required'),

  companyMissionBody: yup
    .string()
    .test('isNotEmpty', 'Company description body is required', value => {
      return value !== '<p></p>';
    })
});

export default function CompanyStory() {
  const [view, setView] = useState(true);
  const dispatch = useDispatch();
  const companyCompletion = useSelector(
    state => state.companies.companyCompletion
  );
  const companyProfile = useSelector(state => state.companies.companyProfile);

  const form = {
    companyDescriptionId: companyProfile?.descriptions?.at(0)?.id || '',
    companyDescriptionTitle: companyProfile?.descriptions?.at(0)?.title || '',
    companyDescriptionBody:
      companyProfile?.descriptions?.at(0)?.body || '<p></p>',

    companyMissionId: companyProfile?.descriptions?.at(1)?.id || '',
    companyMissionTitle: companyProfile?.descriptions?.at(1)?.title || '',
    companyMissionBody: companyProfile?.descriptions?.at(1)?.body || '<p></p>'
  };

  // Validate on opening edit view
  const fields = [
    'companyDescriptionTitle',
    'companyDescriptionBody',
    'companyMissionTitle',
    'companyMissionBody'
  ];

  const validateField = (name, value) => {
    validationSchema
      .validateAt(name, { [name]: value })
      .then(() => {
        setView(true);
        dispatch(
          updateCompanySidebar({
            ...companyCompletion,
            companyStory: true
          })
        );
      })
      .catch(err => {
        setView(false);
        dispatch(
          updateCompanySidebar({
            ...companyCompletion,
            companyStory: false
          })
        );
      });
  };

  useEffect(() => {
    fields.map(field => {
      return validateField(field, form[field]);
    });
  }, []);

  return (
    <div id="Company Story">
      {view ? (
        <ViewState setView={setView} />
      ) : (
        <EditState view={view} setView={setView} />
      )}
    </div>
  );
}
