import { styled as styling } from '@mui/material/styles';
import { Close as CloseIcon } from '@mui/icons-material';
import Colors from '../../../../styles/colors.module.scss';

export const CloseIconContainer = styling('div')({
  position: 'absolute',
  right: '4%',
  top: '3%',
  cursor: 'pointer'
});

export const CloseIconStyled = styling(CloseIcon)({
  fontSize: '34px',
  color: Colors.priWhite
});

export const AnimatedResume = styling('div')(props => ({
  background: Colors.priWhite,
  width: 'calc(89vw / 1.84)',
  borderRadius: '8px',
  height: 'calc(100vh - 15%)',
  overflow: 'clip',
  width: '60%',
}));

export const AnimatedBio = styling('div')(props => ({
  background: Colors.priWhite,
  width: 'calc(89vw / 2.18)',
  borderRadius: '8px',
  height: 'calc(100vh - 15%)',
  overflow: 'auto',
  width: '40%',
  position: 'relative'
}));

export const AnimatedOverlay = styling('div')({
  position: 'fixed',
  zIndex: '1250',
  background: 'rgba(0, 0, 0, 0.4)',
  width: '100%',
  height: '100%',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  overflow: 'hidden',
});

export const AnimatedContainer = styling('div')({
  maxWidth: '1920px',
  padding: '0px 3% 24px',
  margin: '5% auto',
  overflow: 'hidden',
  display: 'flex',
  height: '100vh',
  gap: '10px'
});
