import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, PopUpDialog, Spinner } from '../../../../components';
import {
  Text,
  TableRowStyled,
  TableContainerStyled,
  ColumnTitleContainer,
  SpinnerContainer,
  TextContainer,
  TableHeadStyled
} from './styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import Row from './Row';
import store from '../../../../redux/stores/store';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import {
  updateExpiryDatesColumnIndex,
  viewAllSortedPackages
} from '../../../../redux/actions/job_action';
import TypePopover from './Popovers/TypePopover';
import ActivationDatePopover from './Popovers/ActivationDatePopover';
import ExpiryDatePopover from './Popovers/ExpiryDatePopover';
import DaysRemainingPopover from './Popovers/DaysRemainingPopover';
import Placeholder from './Placeholder';
import moment from 'moment';
import ContactSalesPopup from './ContactSales';
import ProductConfirmationPopup from './ProductConfirmation';
import InactiveJobsPopup from './InactiveJobs';
import AssignTokenPopup from './AssignTokens';

const useOutsideAlerter = (ref, setPressedColumnTitle) => {
  const dispatch = useDispatch();
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert('You clicked outside of me!');
        dispatch(updateExpiryDatesColumnIndex(-1));
        setPressedColumnTitle(false);
      }
    }
    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref]);
};

const ExpiryDatesPopUp = ({ open, handleClose }) => {
  const dispatch = useDispatch();

  const wrapperRef = useRef(null);

  const subscribedPackages = useSelector(state => state.jobs.allSortedPackages);
  const expiryDatesContentIndex = useSelector(
    state => state.jobs.expiryDatesPopupContentIndex
  );

  const subscribedJobSlotsAndPrepJobSlotsPackages =
    store.getState().jobs.sortedJobSlotsAndPrepJobSlotsPackages;

  const header = [
    { title: 'Product Types', enableSort: false, key: 'type' },
    { title: 'Quantity', enableSort: false, key: 'quantity' },
    { title: 'Activation Date', enableSort: false, key: 'activation-date' },
    { title: 'Expiry Date', enableSort: false, key: 'expiry-date' },
    { title: 'Days Remaining', enableSort: false, key: 'days-remaining' },
    { title: '', enableSort: false, key: 'button' }
  ];

  // Filter types
  const packageTypes = [
    'All Types',
    `Job Slots`,
    `Job Slots 6 Months`,
    `Job Slots Plus`,
    `Job Slots + 6 Months`,
    `Job Slots Max`,
    `Job Slots Max 6 Months`,
    `Featured Tokens`,
    `Refresher Tokens`,
    `Job Tokens`,
    `Internship Tokens`,
    `Free Senior Posts`,
    `Job Credits`,
    `Cross Border Tokens`,
    `Spotlight Jobs`,
    `Key Highlights Tokens`
  ];

  const activationDateTypes = [
    'All Activation Dates',
    'Most Recent',
    'Earliest'
  ];

  const expiryDateTypes = ['All Expiry Dates', 'Most Recent', 'Earliest'];

  const daysRemainingTypes = [
    'All Days Remaining',
    'Expired Within 7 Days',
    'Expired Within 14 Days',
    'Expired Within 21 Days',
    'Expired Within 30 Days',
    'Expired',
    'Not Activated'
  ];

  const columnIndex = useSelector(state => state.jobs.expiryDatesColumnIndex);
  const availableResourceCardIndex = useSelector(
    state => state.jobs.availableResourcesCardIndex
  );
  const fetchingAllSortedPackages = useSelector(
    state => state.jobs.fetchingAllSortedPackages
  );

  const [pressedColumnTitle, setPressedColumnTitle] = useState(false);

  const getPackages = () => {
    const jobTypeIndex = store.getState().jobs.productTypeIndex;

    // if (jobTypeIndex === 'Job Slots')
    //   return subscribedJobSlotsAndPrepJobSlotsPackages;
    return subscribedPackages;
  };

  const getFilteredPackages = () => {
    const { activation, expiry, daysRemaining } = getFilters();
    let packages = getPackages()?.details || getPackages();

    if (store.getState().jobs.availableResourcesCardIndex == 1) return packages;

    // Local filter only for job slots
    if (daysRemaining) {
      const days = daysRemaining == 'expired' ? 0 : parseInt(daysRemaining, 10);
      packages = packages.filter(cPackage => {
        if (daysRemaining == 'not_activated') {
          if (moment(cPackage.activation_date).isAfter(moment())) {
            return true;
          } else {
            return false;
          }
        }

        if (days == 0 && cPackage.days_remaining == 0) {
          return true;
        } else if (
          cPackage.days_remaining <= days &&
          cPackage.days_remaining != 0
        ) {
          return true;
        }

        return false;
      });
    }

    return packages.sort((x, y) => {
      const expiry_x = moment(x.expiry_date);
      const expiry_y = moment(y.expiry_date);
      const activation_x = moment(x.activation_date);
      const activation_y = moment(y.activation_date);
      const days_x = parseInt(x.days_remaining, 10);
      const days_y = parseInt(y.days_remaining, 10);

      const expiry_date_left = expiry == 'furthest' ? expiry_x : expiry_y;
      const expiry_date_right = expiry == 'furthest' ? expiry_y : expiry_x;
      const activation_date_left =
        activation == 'furthest' ? activation_x : activation_y;
      const activation_date_right =
        activation == 'furthest' ? activation_y : activation_x;

      if (expiry && activation) {
        if (expiry_date_right.isAfter(expiry_date_left)) {
          return 1;
        } else if (expiry_date_right.isBefore(expiry_date_left)) {
          return -1;
        } else {
          if (activation_date_right.isAfter(activation_date_left)) {
            return 1;
          } else if (activation_date_right.isBefore(activation_date_left)) {
            return -1;
          }
        }
      } else if (expiry) {
        return expiry_date_right.isAfter(expiry_date_left) ? 1 : -1;
      } else if (activation) {
        return activation_date_right.isAfter(activation_date_left) ? 1 : -1;
      }

      if (days_y == 0 && days_x != 0) {
        return -1;
      } else if (days_x == 0 && days_y != 0) {
        return 1;
      } else if (days_y > days_x) {
        return -1;
      } else if (days_y < days_x) {
        return 1;
      }

      return 0;
    });
  };
  const onClickColumnTitle = (event, index) => {
    event.stopPropagation();

    if (pressedColumnTitle) {
      dispatch(updateExpiryDatesColumnIndex(-1));
      setPressedColumnTitle(false);
    } else {
      dispatch(updateExpiryDatesColumnIndex(index));
      setPressedColumnTitle(true);
    }
  };

  const getDay = currentDay => {
    let day = '';
    currentDay = currentDay.toLowerCase();

    if (currentDay.includes('7')) {
      day = '7';
    } else if (currentDay.includes('14')) {
      day = '14';
    } else if (currentDay.includes('21')) {
      day = '21';
    } else if (currentDay.includes('30')) {
      day = '30';
    } else if (currentDay.includes('expired')) {
      day = 'expired';
    } else if (currentDay.includes('not activated')) {
      day = 'not_activated';
    }

    return day;
  };

  const getSort = sort => {
    let cSort = '';
    sort = sort.toLowerCase();

    if (sort.includes('recent')) {
      cSort = 'nearest';
    } else if (sort.includes('earliest')) {
      cSort = 'furthest';
    }

    return cSort;
  };

  const getFilters = () => {
    const jobTypeIndex = store.getState().jobs.productTypeIndex.toLowerCase();
    const activationIndex = store.getState().jobs.productActivationDateIndex;
    const expiryIndex = store.getState().jobs.productExpiryDateIndex;
    const daysRemainingIndex = store.getState().jobs.productDaysRemainingIndex;

    const typeIndex = packageTypes.findIndex(
      type => type.toLowerCase() == jobTypeIndex
    );
    let currentPackageType =
      typeIndex == -1 ? 'Job Slots Card' : packageTypes[typeIndex];

    if (currentPackageType.toLowerCase() == 'internship tokens') {
      currentPackageType = 'Free Internship Tokens';
    }

    const packageType =
      currentPackageType != 'All Types'
        ? currentPackageType == 'Free Senior Posts'
          ? 'Free Super Senior Jobs'
          : currentPackageType
        : '';

    const activation = getSort(activationDateTypes[activationIndex]);
    const expiry = getSort(expiryDateTypes[expiryIndex]);
    const daysRemaining = getDay(daysRemainingTypes[daysRemainingIndex]);

    return { packageType, activation, expiry, daysRemaining };
  };

  const applyFilter = () => {
    if (store.getState().jobs.availableResourcesCardIndex != -1) return; // not -1 mean using local sorting and filter

    const { packageType, activation, expiry, daysRemaining } = getFilters();

    let type;
    if (packageType === 'Job Slots Card') {
      type = [
        'Job Slots',
        'Job Slots Plus',
        'Job Slots Max',
        'Job Slots 6 Months',
        'Job Slots + 6 Months',
        'Job Slots Max 6 Months'
      ];
    } else {
      type = packageType;
    }

    dispatch(
      viewAllSortedPackages({ type, activation, expiry, daysRemaining })
    );
  };

  useOutsideAlerter(wrapperRef, setPressedColumnTitle);

  const onClose = () => {
    store.getState().jobs.productTypeIndex = 'All Types';
    store.getState().jobs.productActivationDateIndex = 0;
    store.getState().jobs.productExpiryDateIndex = 0;
    store.getState().jobs.productDaysRemainingIndex = 0;
    store.getState().jobs.expiryDatesPopupContentIndex = -1;

    handleClose();
  };

  const getPackagesCount = () => {
    let packages = getPackages()?.details || getPackages();

    return packages?.length;
  };

  const showPlaceHolder = () => {
    // 0 - all types
    // 1 - job slots
    // 2 - featured token
    // 3 - refresher token

    const jobTypeIndex = store.getState().jobs.productTypeIndex;
    const usableJobSlotCount =
      store.getState().jobs.subscribedJobSlotsPackage?.total_usable_quantity;

    const usableJobSlotPlusCount =
      store.getState().jobs.subscribedJobSlotPlusPackage?.total_usable_quantity;

    const usableJobSlotMaxCount =
      store.getState().jobs.subscribedJobSlotMaxPackage?.total_usable_quantity;

    const usableJobSlot6mCount =
      store.getState().jobs.subscribedJobSlot6mPackage?.total_usable_quantity;

    const usableJobSlotPlus6mCount =
      store.getState().jobs.subscribedJobSlotPlus6mPackage
        ?.total_usable_quantity;

    const usableJobSlotMax6mCount =
      store.getState().jobs.subscribedJobSlotMax6mPackage
        ?.total_usable_quantity;

    const featuredTokensUsableCount =
      store.getState().jobs.subscribedFeaturedTokensPackage
        ?.total_usable_quantity;
    const refresherTokensUsableCount =
      store.getState().jobs.subscribedRefresherTokensPackage
        ?.total_usable_quantity;

    if (jobTypeIndex === 'Job Slots Max') {
      if (usableJobSlotMaxCount == 0 || !usableJobSlotMaxCount) return true;
    } else if (jobTypeIndex === 'Job Slots Plus') {
      if (usableJobSlotPlusCount == 0 || !usableJobSlotPlusCount) return true;
    } else if (jobTypeIndex === 'Job Slots') {
      if (usableJobSlotCount == 0 || !usableJobSlotCount) return true;
    } else if (jobTypeIndex === 'Job Slots 6 Months') {
      if (usableJobSlot6mCount == 0 || !usableJobSlot6mCount) return true;
    } else if (jobTypeIndex === 'Job Slots + 6 Months') {
      if (usableJobSlotPlus6mCount == 0 || !usableJobSlotPlus6mCount)
        return true;
    } else if (jobTypeIndex === 'Job Slots Max 6 Months') {
      if (usableJobSlotMax6mCount == 0 || !usableJobSlotMax6mCount) return true;
    } else if (jobTypeIndex == 'Featured Tokens') {
      if (featuredTokensUsableCount == 0 || !featuredTokensUsableCount)
        return true;
    } else if (jobTypeIndex == 'Refresher Tokens') {
      if (refresherTokensUsableCount == 0 || !refresherTokensUsableCount)
        return true;
    }

    return false;
  };

  const disableModalPadding = () => {
    if (
      expiryDatesContentIndex == 0 ||
      expiryDatesContentIndex == 1 ||
      expiryDatesContentIndex == 2 ||
      expiryDatesContentIndex == 3
    ) {
      return true;
    }
  };

  useEffect(() => {
    if (open) {
      applyFilter();
    }
  }, [open]);

  return (
    <>
      <PopUpDialog
        open={open}
        handleClose={onClose}
        title={expiryDatesContentIndex == -1 ? `Product Information` : ''}
        dialogWidth={'100%'}
        maxDialogWidth={'1380px'}
        disableCustomPadding={disableModalPadding()}>
        {expiryDatesContentIndex == -1 ? (
          <TableContainerStyled component={Paper}>
            {fetchingAllSortedPackages ? (
              <SpinnerContainer>
                <Spinner size="BIG" noPadding={true} />
              </SpinnerContainer>
            ) : getFilteredPackages() ? (
              <Table aria-label="expiry-dates">
                <TableHeadStyled>
                  <TableRowStyled>
                    {header.map((column, index) => {
                      return (
                        <Fragment key={column.key}>
                          <TableCell
                            key={index}
                            style={{
                              position: 'relative'
                            }}
                            align="left"
                            onClick={() => onClickColumnTitle(event, index)}>
                            <ColumnTitleContainer
                              ref={wrapperRef}
                              hide_cursor={`${column?.title == 'Quantity'}`}>
                              <Text table_header={'true'}>
                                {' '}
                                {column.title}
                                {''}
                              </Text>
                              {column?.title == 'Quantity' ||
                              column?.title == '' ||
                              (column?.title == 'Product Types' &&
                                availableResourceCardIndex != -1) ? null : (
                                <ArrowDropDownIcon
                                  style={{ fontSize: '22px', color: '#222' }}
                                />
                              )}
                              {columnIndex == 0 &&
                                availableResourceCardIndex == -1 &&
                                column?.title == 'Product Types' && (
                                  <TypePopover
                                    applyFilter={applyFilter}
                                    types={packageTypes}
                                  />
                                )}
                              {columnIndex == 2 &&
                                column?.title == 'Activation Date' && (
                                  <ActivationDatePopover
                                    applyFilter={applyFilter}
                                    types={activationDateTypes}
                                  />
                                )}
                              {columnIndex == 3 &&
                                column?.title == 'Expiry Date' && (
                                  <ExpiryDatePopover
                                    applyFilter={applyFilter}
                                    types={expiryDateTypes}
                                  />
                                )}
                              {columnIndex == 4 &&
                                column?.title == 'Days Remaining' && (
                                  <DaysRemainingPopover
                                    applyFilter={applyFilter}
                                    types={daysRemainingTypes}
                                  />
                                )}
                            </ColumnTitleContainer>
                          </TableCell>
                        </Fragment>
                      );
                    })}
                  </TableRowStyled>
                </TableHeadStyled>
                <TableBody style={{ position: 'relative' }}>
                  {!showPlaceHolder() && (
                    <>
                      {/* {(getPackagesCount() <= 0 ||
                        getFilteredPackages()?.length <= 0) && (
                        <TextContainer empty_result="true">
                          <Text empty_result="true">No result</Text>
                        </TextContainer>
                      )} */}
                      {store.getState().jobs.availableResourcesCardIndex ==
                        -1 ||
                      store.getState().jobs.availableResourcesCardIndex == 0 ? (
                        <>
                          {getFilteredPackages()?.map((item, index) => {
                            return (
                              <Row
                                key={`${item.type}--${index}`}
                                item={item}
                                productTitle={item?.type}
                                activationDate={item?.activation_date}
                                daysRemaining={item?.days_remaining}
                                expiryDate={item?.expiry_date}
                                quantity={item?.quantity}
                                usableQuantity={item?.usable_quantity}
                                showUseNowButton={
                                  item.type == 'Prep Job Slots' ||
                                  item.type == 'Key Highlights Tokens' ||
                                  item.type == 'Cross Border Tokens'
                                    ? false
                                    : item?.near_expiry
                                }
                              />
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {getFilteredPackages().map((item, index) => {
                            return (
                              <Row
                                key={`${item.type}--${index}`}
                                item={item}
                                productTitle={getPackages().package}
                                activationDate={item?.activation_date}
                                daysRemaining={item?.days_remaining}
                                expiryDate={item?.expiry_date}
                                quantity={item?.quantity}
                                usableQuantity={item?.usable_quantity}
                                showUseNowButton={
                                  item.type == 'Prep Job Slots' ||
                                  item.type == 'Key Highlights Tokens' ||
                                  item.type == 'Cross Border Tokens'
                                    ? false
                                    : item?.near_expiry
                                }
                              />
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            ) : null}
            {!fetchingAllSortedPackages && showPlaceHolder() && <Placeholder />}
          </TableContainerStyled>
        ) : expiryDatesContentIndex == 0 ? (
          <ProductConfirmationPopup onCloseModal={onClose} />
        ) : expiryDatesContentIndex == 1 ? (
          <InactiveJobsPopup onCloseModal={onClose} />
        ) : expiryDatesContentIndex == 2 ? (
          <ContactSalesPopup onCloseModal={onClose} />
        ) : expiryDatesContentIndex == 3 ? (
          <AssignTokenPopup onCloseModal={onClose} />
        ) : null}
      </PopUpDialog>
    </>
  );
};

export default ExpiryDatesPopUp;
