import React, { useEffect, useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import { Skeleton } from '@mui/material';
import { Card, Spinner } from '../../../components';
import { ReactComponent as EditIcon } from '../../../assets/common/edit-icon.svg';
import styles from './index.module.scss';
import { ErrorToast, SuccessToast } from '../../../utils/ToastUtils';
import FormSelectCountry from '../../../components/FormSelectCountry';
import FormSelectStateRegion from '../../../components/FormSelectState';
import countries from 'i18n-iso-countries';
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry
} from 'postcode-validator';
import { MUIButtonStyled } from '../../../components/MUIButton';
import { useDispatch, useSelector } from 'react-redux';
import { mutateCompanyDetails } from '../../../redux/actions/company_actions';
import SharedTextField from '../../../components/SharedTextField';
import * as yup from 'yup';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const BillingAddressCard = ({ billingAddress, loading, style = {} }) => {
  const dispatch = useDispatch();
  const [mutating, setMutating] = useState(false);
  const [toggleEdit, setToggleEdit] = useState(false);
  const [countryCode, setCountryCode] = useState('');
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    address: billingAddress ? billingAddress?.streetAddress : '',
    city: billingAddress ? billingAddress?.town : '',
    postcode: billingAddress ? billingAddress?.postcode : '',
    state: billingAddress ? billingAddress?.state : '',
    country: billingAddress ? billingAddress?.country : ''
  });

  const fetchingCompanySettings = useSelector(
    state => state.companies.fetchingCompanySettings
  );

  const _onToggleEdit = () => {
    setToggleEdit(!toggleEdit);
  };

  useEffect(() => {
    setValues({
      ...values,
      address: billingAddress?.streetAddress,
      city: billingAddress?.town,
      postcode: billingAddress?.postcode,
      state: billingAddress?.state,
      country: billingAddress?.country
    });
  }, [toggleEdit]);

  let validPostcodeForNotExistedCountryCode;

  const validationSchema = yup.object().shape({
    address: yup.string().required('Address is required'),
    city: yup.string().required('City is required'),
    postcode: yup.string().required('Postcode is required')
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: '' });
    setValues({ ...values, [name]: value });
  };

  const handleChangeCountry = e => {
    setErrors({ ...errors, ['country']: '' });
    setValues({ ...values, ['country']: e });
  };

  const handleChangeState = e => {
    setErrors({ ...errors, ['state']: '' });
    setValues({ ...values, ['state']: e });
  };

  const getCountryCode = country => {
    let cCountryCode = countries.getAlpha2Code(country, 'en');

    setCountryCode(cCountryCode);
  };

  const validatePostcode = value => {
    let countryCodeExists = postcodeValidatorExistsForCountry(countryCode);

    if (countryCodeExists) {
      let validPostcode = postcodeValidator(value, countryCode);

      return validPostcode;
    } else {
      return 'countryCodeNotExisted';
    }
  };

  const onSubmitBillingInformation = async values => {
    setMutating(true);
    dispatch(
      mutateCompanyDetails({
        input: {
          billingAddress: {
            streetAddress: values.address,
            town: values.city,
            postcode: values.postcode,
            state: values.state,
            country: values.country
          }
        }
      })
    )
      .then(res => {
        _onToggleEdit();

        if (res.type === 'FETCH_MY_COMPANY_SETTINGS_SUCCEED') {
          SuccessToast('Company Billing Information Updated Successfully!');
        } else {
          SuccessToast('Company Billing Information Update Failed');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        ErrorToast(error.message);
      })
      .finally(() => {
        setMutating(false);
      });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      await validationSchema.validate(values, { abortEarly: false });
      onSubmitBillingInformation(values);
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach(err => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    }
  };

  useEffect(() => {
    if (values.country) {
      getCountryCode(values?.country);

      if (values?.country !== billingAddress?.country) {
        setValues({
          ...values,
          state: '' // Reset state to empty string when country changes
        });
      } else {
        setValues({
          ...values,
          state: billingAddress?.state || ''
        });
      }
    }
  }, [values?.country]);

  return (
    <Card style={{ ...{ width: '30rem', margin: 30 }, ...style }}>
      <div className={styles.subtitleContainer}>
        <div className={styles.subtitle}>Billing Address</div>
        <IconButton
          onClick={() => _onToggleEdit()}
          className={styles.imageContainer}
          disabled={toggleEdit}
          style={{ backgroundColor: 'transparent', width: 17 }}>
          {!toggleEdit && <EditIcon width={17} />}
        </IconButton>
      </div>
      <div>
        {toggleEdit ? (
          <Grid>
            <form onSubmit={handleSubmit}>
              <div className={styles.row}>
                <SharedTextField
                  name="address"
                  placeholder="Street Address*"
                  value={values?.address}
                  onChange={handleChange}
                  error={errors?.address}
                  helperText={errors?.address}
                />
              </div>
              <div className={styles.row}>
                <SharedTextField
                  name="city"
                  placeholder="City*"
                  value={values?.city}
                  onChange={handleChange}
                  error={errors?.city}
                  helperText={errors?.city}
                />
              </div>
              <div className={styles.row}>
                <SharedTextField
                  name="postcode"
                  placeholder="Postcode*"
                  value={values?.postcode}
                  onChange={handleChange}
                  validPostcode={validatePostcode(values?.postcode)}
                  error={
                    errors?.postcode || !validatePostcode(values?.postcode)
                  }
                  helperText={
                    errors?.postcode
                      ? 'Postcode is required'
                      : !validatePostcode(values?.postcode)
                      ? 'Postcode invalid'
                      : ''
                  }
                />
              </div>

              <div className={styles.row}>
                <FormSelectCountry
                  name="country"
                  label="Select Country"
                  country={values?.country}
                  onChange={handleChangeCountry}
                  value={values?.country}
                  warning={errors?.country}
                />
              </div>
              <div className={styles.row}>
                <FormSelectStateRegion
                  country={values?.country}
                  name="state"
                  label="Select State/Region"
                  value={values?.state}
                  onChange={handleChangeState}
                  warning={errors?.state}
                />
              </div>
              {fetchingCompanySettings && mutating ? (
                <Spinner />
              ) : (
                <div className={styles.buttonContainer}>
                  <MUIButtonStyled
                    solidWhite
                    medium
                    label="Cancel"
                    onClick={() => _onToggleEdit()}
                  />
                  <MUIButtonStyled medium type="submit" label="Save" />
                </div>
              )}
            </form>
          </Grid>
        ) : (
          <div>
            {loading ? (
              <div className={styles.skeletonContainer}>
                <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={150} />
              </div>
            ) : (
              <div className={styles.labelWrapper}>
                <h6 className={styles.labelTitle}>Street Address</h6>
                <div className={styles.labelValue}>
                  {billingAddress?.streetAddress}
                </div>
              </div>
            )}
            {loading ? (
              <div className={styles.skeletonContainer}>
                <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={150} />
              </div>
            ) : (
              <div className={styles.labelWrapper}>
                <div className={styles.labelTitle}>City</div>
                <div className={styles.labelValue}>{billingAddress?.town}</div>
              </div>
            )}
            {loading ? (
              <div className={styles.skeletonContainer}>
                <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={150} />
              </div>
            ) : (
              <div className={styles.labelWrapper}>
                <div className={styles.labelTitle}>Postcode</div>
                <div className={styles.labelValue}>
                  {billingAddress?.postcode}
                </div>
              </div>
            )}
            {loading ? (
              <div className={styles.skeletonContainer}>
                <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={150} />
              </div>
            ) : (
              <div className={styles.labelWrapper}>
                <div className={styles.labelTitle}>State/Region</div>
                <div className={styles.labelValue}>{billingAddress?.state}</div>
              </div>
            )}
            {loading ? (
              <div className={styles.skeletonContainer}>
                <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={150} />
              </div>
            ) : (
              <div className={styles.labelWrapper}>
                <div className={styles.labelTitle}>Country</div>
                <div className={styles.labelValue}>
                  {billingAddress?.country}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Card>
  );
};

export default BillingAddressCard;
