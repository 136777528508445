import React from 'react';
import PropTypes from 'prop-types';
import {
  StrengthColor,
  InfoIconStyled,
  MessageContainer,
  MessageTooltip
} from '../PasswordStrength/styles';

const ProgressBar = ({ bgcolor, completed }) => {
  const containerStyles = {
    width: '100%',
    backgroundColor: '#e0e0de',
    borderRadius: 20,
    height: 5,
    marginTop: '15px'
  };

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right'
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}></div>
    </div>
  );
};

ProgressBar.propTypes = {
  bgcolor: PropTypes.string.isRequired,
  completed: PropTypes.number.isRequired
};

const getPasswordStrength = password => {
  const poorPasswordRegex = /^.{0,7}$/;
  const averagePasswordRegex =
    /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?]{8,}$/;
  const strongPasswordRegex =
    /^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?]{12,}$/;

  if (strongPasswordRegex.test(password)) {
    return 'strong';
  } else if (averagePasswordRegex.test(password)) {
    return 'average';
  } else if (poorPasswordRegex.test(password)) {
    return 'poor';
  } else {
    return 'average';
  }
};
const PasswordStrength = ({ password }) => {
  const strength = getPasswordStrength(password);

  const getBgColor = () => {
    switch (strength) {
      case 'strong':
        return '#22ad5c';
      case 'average':
        return '#fca80b';
      case 'poor':
        return '#ff4758';
      default:
        return '#ff4758';
    }
  };

  const getCompletion = () => {
    switch (strength) {
      case 'strong':
        return 100;
      case 'average':
        return 66.66;
      case 'poor':
        return 33.33;
      default:
        return 0;
    }
  };

  return (
    <div>
      <ProgressBar
        bgcolor={getBgColor()}
        completed={password ? getCompletion() : 0}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '10px'
        }}>
        {password.length === 0 ? (
          <span style={{ fontFamily: 'Inter', fontSize: '14px' }}>
            Password suggestions
          </span>
        ) : (
          <>
            <span style={{ fontFamily: 'Inter', fontSize: '14px' }}>
              Your password is &nbsp;
            </span>
            <StrengthColor password_strength={strength}>
              {strength}
            </StrengthColor>
          </>
        )}
        <MessageContainer>
          <InfoIconStyled />
          <MessageTooltip>
            <p
              style={{
                fontFamily: 'Inter',
                fontSize: '12px',
                margin: '0',
                minWidth: '200px'
              }}>
              Recommended password:
            </p>
            <p
              style={{
                fontFamily: 'Inter',
                fontSize: '12px',
                margin: '0 0 0 5px',
                minWidth: '200px',
                display: 'list-item',
                listStyleType: 'disc',
                listStylePosition: 'inside'
              }}>
              At least 8 characters.
            </p>
            <p
              style={{
                fontFamily: 'Inter',
                fontSize: '12px',
                margin: '0 0 0 5px',
                minWidth: '200px',
                display: 'list-item',
                listStyleType: 'disc',
                listStylePosition: 'inside'
              }}>
              Include at least 1 symbol and uppercase letter.
            </p>
          </MessageTooltip>
        </MessageContainer>
      </div>
    </div>
  );
};

PasswordStrength.propTypes = {
  password: PropTypes.string.isRequired
};

export default PasswordStrength;
