import { styled as styling } from '@mui/material/styles';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { Typography } from '@mui/material';

export const ExclamationCircleIconStyled = styling(ExclamationCircleIcon)(
  props => ({
    height: '16px',
    width: '16px',
    color: '#F75443'
  })
);

export const ErrorTextStyled = styling(Typography)(props => ({
  fontSize: '12px',
  lineHeight: '20px',
  color: '#F75443'
}));
