import React from 'react';
import { SimpleTipsTooltip } from '../../../../../components/Tooltips';
import {
  JobViewContainer,
  InfoIconStyled,
  Title,
  TooltipText,
  JobViewCount
} from './styles';

const JobView = ({ job }) => {
  return (
    <JobViewContainer>
      <JobViewInfo />
      <Title>Job Views:</Title>
      <JobViewCount>{job?.viewCount}</JobViewCount>
    </JobViewContainer>
  );
};

export default JobView;

export const JobViewInfo = () => {
  return (
    <SimpleTipsTooltip
      icon={
        <div style={{ display: 'flex' }}>
          <InfoIconStyled />
        </div>
      }
      top={'21px'}
      left={'0px'}
      width={'213px'}
      padding={'4px 8px'}
      description={
        <div style={{ textAlign: 'start' }}>
          <TooltipText>
            This shows the number of views for this job post since it was first
            created up to yesterday.
          </TooltipText>
        </div>
      }
      position={'absolute'}
      borderRadius={'8px'}
      fontSize={'10px'}
      border={'1.5px solid #222222'}
      zIndex={'2'}
      wrapperZIndex={'3'}
    />
  );
};
