import React, { Fragment } from 'react';
import {
  TableContainer,
  TableHead,
  Table,
  Tooltip,
  Grid,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import {
  TableBodyStyled,
  TableRowStyled,
  TableCellStyled,
  UnviewedIndicator,
  NameTextWrapper,
  IconWrapper,
  IconButtonStyled,
  EmailIconStyled,
  WhatsAppIconStyled,
  Text,
  GetAppIconStyled,
  TableCellWrapper,
  TableContainerStyled
} from './styles';
import { saveAs } from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import { SkeletonStyled } from './styles';
import { Pagination } from './Pagination';
import moment from 'moment';
import StatusSelect from '../../../components/StatusDropdown/StatusSelect';
import { CSEMAIL, JOB_APPLICATION_STATUS } from '../../../utils/Constants';
import { downloadResume } from '../../../redux/actions/talent_action';
import {
  changeJobApplicationState,
  toggleCloseLoopPopup,
  //   toggleMakeOfferPopup,
  toggleRejectPopup,
  updateAllApplications,
  updateCurrentCandidateIndex,
  updateCandidateSnapshotStatus,
  updateJobApplications,
  updateBlacklistPopup,
  viewApplication,
  updateApplicationParams,
  getApplications,
  updateApplicationCurrentPage,
  updateApplicationUserIds,
  bulkDownloadResumes
} from '../../../redux/actions/application_action';
import RejectPopUp from '../../Jobs/PopUps/RejectPopUp';
import store from '../../../redux/stores/store';
import { ErrorToast, InfoToast, SuccessToast } from '../../../utils/ToastUtils';
// import OfferJobPopUp from '../../../Jobs/PopUps/OfferJobPopUp';
import CloseLoopPopUp from '../../Jobs/PopUps/CloseLoopPopUp';
import { updateJobData } from '../../../redux/actions/job_action';
import BlackListPopUp from '../../ManageCandidates/Board/BlackListPopUp';
import * as types from '../../../../src/redux/types/application_type';
import emptyApplication from '../../../assets/images/all-applications/empty_application.svg';
import {
  ArrowDownTrayIcon,
  ChevronDownIcon,
  ChevronUpIcon
} from '@heroicons/react/24/solid';
import ScheduleInterviewModal from '../../ManageCandidates/Modals/ScheduleInterview/Board';
import ScheduleNextInterviewModal from '../../ManageCandidates/Modals/ScheduleInterview/List';
import Colors from '../../../styles/colors.module.scss';
import { MUIButtonStyled } from '../../../components/MUIButton';
import JSZip from 'jszip';
import { resumeConversion } from '../../../helpers/data_management';
import { accData } from '../../../utils/LocalStorageUtils';
import { getCountry } from '../../../hooks/useRegionalisation';
import { convertAndDownloadResume } from '../../../hooks/useDownload';

let accountInfo;
try {
  accountInfo = localStorage.getItem('accountInfo');
  accountInfo = accountInfo ? JSON.parse(accountInfo) : accountInfo;
} catch (error) {
  //
}

const columns = [
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'job-applied',
    label: 'Job Applied'
  },
  {
    id: 'applied',
    label: 'Applied Date'
  },
  {
    id: 'status',
    label: 'Status'
  },
  {
    id: 'resume',
    label: 'Resume'
  },
  {
    id: 'checkbox',
    label: ''
  }
];

const CandidatesTable = ({
  updateApplicationState,
  application,
  autocomValueNew
}) => {
  const dispatch = useDispatch();
  // Selectors
  const fetchingApplications = useSelector(
    state => state.applications.fetchingApplications
  );
  const applications = useSelector(state => state.applications.applications);
  const downloadingResume = useSelector(state => state.talents.fetchingResume);
  const jobData = useSelector(state => state.jobs.jobData);
  const isRejectPopupStatusOpen = useSelector(
    state => state.applications.rejectPopupStatus
  );
  const isBlacklistPopupOpen = useSelector(
    state => state.applications.blacklistPopupStatus
  );
  const isFirstInterviewModalOpened = useSelector(
    state => state.applications.isFirstInterviewModalOpened
  );
  const isNextInterviewModalOpened = useSelector(
    state => state.applications.isNextInterviewModalOpened
  );
  const applicationUserIds = useSelector(
    state => state.applications.applicationUserIds
  );
  const fetchingResumes = useSelector(
    state => state.applications.fetchingResumes
  );

  const currentTab = autocomValueNew;

  // Get values
  const getName = application => {
    if (application?.user?.name) {
      return application.user.name;
    }

    return '-';
  };

  const getAppliedDate = application => {
    if (application?.appliedAt) {
      return `${moment(application.appliedAt).format('DD MMM YYYY')}`;
    }

    return '-';
  };

  const onClickColumn = column => {
    if (column.id == 'applied') {
      onClickAppliedDate();
      return;
    }

    if (column.id == 'job-applied') {
      onClickJobApplied();
      return;
    }
  };

  const onClickAppliedDate = () => {
    if (store.getState().applications.fetchingApplications) return;

    let cApplicationParams = {
      ...store.getState().applications.applicationParams
    };

    if (cApplicationParams.sort.by == 'jobTitle') {
      cApplicationParams.sort.by = 'appliedAt';
    }

    if (cApplicationParams.sort.direction == 'desc') {
      cApplicationParams.sort.direction = 'asc';
    } else {
      cApplicationParams.sort.direction = 'desc';
    }

    dispatch(updateApplicationParams(cApplicationParams)).then(() => {
      if (store.getState().applications.applicationCurrentPage > 1) {
        dispatch(updateApplicationCurrentPage(1));
      }

      dispatch(getApplications(cApplicationParams));
    });
  };

  const onClickJobApplied = () => {
    if (store.getState().applications.fetchingApplications) return;

    let cApplicationParams = {
      ...store.getState().applications.applicationParams
    };

    if (cApplicationParams.sort.by == 'appliedAt') {
      cApplicationParams.sort.by = 'jobTitle';
    }

    if (cApplicationParams.sort.direction == 'desc') {
      cApplicationParams.sort.direction = 'asc';
    } else {
      cApplicationParams.sort.direction = 'desc';
    }

    dispatch(updateApplicationParams(cApplicationParams)).then(() => {
      if (store.getState().applications.applicationCurrentPage > 1) {
        dispatch(updateApplicationCurrentPage(1));
      }

      dispatch(getApplications(cApplicationParams));
    });
  };

  const getJobApplied = application => {
    if (application.job) {
      return application.job.title;
    }

    return '-';
  };

  const getResumeUrl = application => {
    if (application?.user?.resume) {
      return application.user.resume;
    }
    return '';
  };

  const getStatus = application => {
    if (application?.employerHiredStatus) {
      return 'offer';
    }

    return application?.state;
  };
  // End Get values

  // Toast messages
  const rejectedStatusMessage = () => {
    return (
      <div>
        <p
          style={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 'bold',
            margin: '4px 0'
          }}>
          User Rejected
        </p>
        <p style={{ margin: '0', fontFamily: 'Inter', fontSize: '12px' }}>
          You have successfully rejected this user.
        </p>
      </div>
    );
  };

  const showToastMessages = value => {
    if (value == JOB_APPLICATION_STATUS.REJECTED) {
      InfoToast(rejectedStatusMessage());
    } else {
      SuccessToast('Changed Job Application Status Successful!');
    }
  };

  // On click event
  const preventPropagation = event => {
    if (event) {
      event.stopPropagation();
    }
  };

  const onDownloadIconClicked = application => () => {
    window.dataLayer.push({
      event: 'CE_download_resume_applicants',
      first_time: 'yes',
      tab: currentTab,
      candidate_id: application?.user?.id,
      company_name: accountInfo?.companyName,
      company_industry: accountInfo?.industryName
    });

    if (downloadingResume || fetchingResumes) return;

    const resumeUrl = getResumeUrl(application);
    if (resumeUrl) {
      const params = {
        userId: application?.user?.id
      };

      dispatch(downloadResume(params)).then(res => {
        convertAndDownloadResume(res, application?.user);
      });
    }
  };

  const updatePopupApplication = (application, status) => {
    const joinedApplications = [...store.getState().applications.applications];

    let currentIndex = joinedApplications.findIndex(item => {
      return item.id === application.id;
    });

    if (currentIndex == -1) currentIndex = 0;

    dispatch(updateAllApplications(joinedApplications)).then(() => {
      dispatch(updateCurrentCandidateIndex(currentIndex)).then(() => {
        if (status == JOB_APPLICATION_STATUS.REJECTED) {
          dispatch(toggleRejectPopup(true));
        } else if (status == JOB_APPLICATION_STATUS.OFFER) {
          dispatch(toggleCloseLoopPopup(true));
        } else if (status == JOB_APPLICATION_STATUS.BLACKLISTED) {
          dispatch(updateBlacklistPopup(true));
        }
      });
    });
  };

  // On change
  const onStateChange = application => event => {
    const value = event.target.value;

    if (value === JOB_APPLICATION_STATUS.REJECTED) {
      updatePopupApplication(application, value);
    } else if (value === JOB_APPLICATION_STATUS.OFFER) {
      if (!application?.employerHiredStatus) {
        updatePopupApplication(application, value);
      }
    } else if (value == JOB_APPLICATION_STATUS.BLACKLISTED) {
      updatePopupApplication(application, value);
    } else {
      // Change application status
      updateApplicationState(application, value, '');
    }
  };

  const updateJobDataCount = newState => {
    let cJobData = { ...jobData };

    switch (newState) {
      case 'hired':
        {
          cJobData.hiredCount += 1;
        }
        break;
      case 'contacted':
        {
          cJobData.contactedCount += 1;
        }
        break;
      case 'viewed':
        {
          cJobData.unviewedCount -= 1;
          // cJobData.viewedCount += 1;
        }
        break;
    }

    dispatch(updateJobData(cJobData));
  };

  const onApplicationClicked = application => () => {
    if (!application?.id) return;

    onViewApplication(application);

    const joinedApplications = [...store.getState().applications.applications];

    let currentIndex = joinedApplications.findIndex(item => {
      return item.id === application.id;
    });

    dispatch(updateAllApplications(joinedApplications)).then(() => {
      dispatch(updateCurrentCandidateIndex(currentIndex)).then(() => {
        dispatch(updateCandidateSnapshotStatus(true));
      });
    });
  };

  const onViewApplication = application => {
    // If unviewed
    if (!application.status) {
      let params = {
        applicationId: application.id
      };

      dispatch(viewApplication(params)).then(res => {
        if (res?.type == types.VIEW_APPLICATION_SUCCEED) {
          updateJobDataCount('viewed');
        }
      });
    }
  };

  const showArrow = columnId => {
    let sortBy = store.getState().applications.applicationParams.sort.by;

    if (
      (columnId == 'applied' && sortBy == 'appliedAt') ||
      (columnId == 'job-applied' && sortBy == 'jobTitle')
    ) {
      if (
        store.getState().applications.applicationParams.sort.direction == 'asc'
      ) {
        return (
          <ChevronUpIcon
            style={{ height: '15px', width: '15px', color: Colors.priPurple }}
          />
        );
      } else {
        return (
          <ChevronDownIcon
            style={{ height: '15px', width: '15px', color: Colors.priPurple }}
          />
        );
      }
    } else if (columnId == 'applied' || columnId == 'job-applied') {
      return (
        <ChevronDownIcon
          style={{ height: '15px', width: '15px', color: Colors.priPurple }}
        />
      );
    }
  };

  const getTableTitle = (column, applications) => {
    const disabled =
      applicationUserIds.length <= 0 || fetchingResumes || downloadingResume;

    if (column.id == 'resume')
      return (
        <MUIButtonStyled
          $padding={'2px 10px'}
          $backgroundColor={disabled ? Colors.terLightGrey : Colors.priPurple}
          label={
            <Grid display={'flex'} gap="5px" alignItems={'center'}>
              <ArrowDownTrayIcon style={{ width: '14px', height: '14px' }} />
              <span>Resumes</span>
            </Grid>
          }
          $fontSize={'12px'}
          $shadow={'none'}
          $newHoverEffect
          disabled={disabled}
          onClick={downloadZipResumes}
        />
      );

    if (column.id == 'checkbox')
      return (
        <FormControlLabel
          control={
            <Checkbox
              onClick={() => onSelectAllCheckbox(applications)}
              checked={
                applicationUserIds.length == 0
                  ? false
                  : applicationUserIds.length >= applications.length
              }
              sx={{
                '&.Mui-checked': {
                  color: '#222'
                },
                padding: '0 9px',
                margin: '0'
              }}
            />
          }
          label={''}
          sx={{
            '& .MuiTypography-root': {
              fontSize: 14,
              letterSpacing: '0.15px'
            },
            '& .MuiSvgIcon-root': {
              fontSize: 20
            }
          }}
        />
      );

    return column.label;
  };

  const zipFiles = async resumes => {
    const zip = new JSZip();

    await Promise.all(
      resumes.map(item => {
        const blob = resumeConversion(item.resume);

        return zip.file(item.filename, blob);
      })
    );

    return zip.generateAsync({ type: 'blob' });
  };

  const downloadZipResumes = () => {
    if (store.getState().applications.fetchingResumes || downloadingResume)
      return;

    const params = { userIds: applicationUserIds };

    dispatch(bulkDownloadResumes(params)).then(async res => {
      if (res.error) {
        ErrorToast('Please uncheck the inaccessible resumes');
        return;
      }

      if (res.resumes?.length > 0) {
        const zipBlob = await zipFiles(res.resumes);
        saveAs(zipBlob, `candidates-resumes-${moment().format('YYYY-MM-DD')}`);
      }
    });
  };

  const onSelectAllCheckbox = applications => {
    let cUserIds = store.getState().applications.applicationUserIds;

    const ids = applications.map(item => {
      return item.user.id;
    });

    if (cUserIds.length >= applications.length) {
      dispatch(updateApplicationUserIds([]));
      return;
    }

    cUserIds = ids;

    dispatch(updateApplicationUserIds(cUserIds));
  };

  const onSelectSingleCheckbox = application => {
    const applicationUserIds = store.getState().applications.applicationUserIds;

    const userIndex = applicationUserIds.findIndex(item => {
      return item == application.user.id;
    });

    if (userIndex > -1) {
      const tArray = applicationUserIds.filter(item => {
        return item != application.user.id;
      });

      dispatch(updateApplicationUserIds(tArray));

      return;
    }

    dispatch(
      updateApplicationUserIds([
        ...store.getState().applications.applicationUserIds,
        application.user.id
      ])
    );
  };

  // Rendering
  const skeletonRows = index => {
    return (
      <TableRowStyled key={`${index}`}>
        <TableCellStyled type="name_text">
          <SkeletonStyled variant="text" width="100%" />
        </TableCellStyled>
        <TableCellStyled type="job_applied_text">
          <SkeletonStyled variant="text" width="100%" />
        </TableCellStyled>
        <TableCellStyled type="applied_text">
          <SkeletonStyled variant="text" width="100%" />
        </TableCellStyled>
        <TableCellStyled type="status_text">
          <SkeletonStyled variant="text" width="100%" />
        </TableCellStyled>
        <TableCellStyled type="resume_text">
          <SkeletonStyled variant="text" width="100%" />
        </TableCellStyled>
      </TableRowStyled>
    );
  };
  const renderSkeletonRows = () => {
    // Cannot use array.map for some reason
    return (
      <>
        {skeletonRows(1)}
        {skeletonRows(2)}
        {skeletonRows(3)}
        {skeletonRows(4)}
        {skeletonRows(5)}
        {/* {skeletonRows(6)}
        {skeletonRows(7)}
        {skeletonRows(8)}
        {skeletonRows(9)}
        {skeletonRows(10)} */}
      </>
    );
  };

  const renderRows = () => {
    if (fetchingApplications) {
      return renderSkeletonRows();
    }

    return applications?.map(application => {
      return (
        <TableRowStyled
          key={application.id}
          onClick={onApplicationClicked(application)}>
          <TableCellStyled type="name_text">
            <TableCellWrapper>
              {!application?.status && <UnviewedIndicator />}
              <NameTextWrapper>{getName(application)}</NameTextWrapper>
            </TableCellWrapper>
          </TableCellStyled>
          <TableCellStyled type="job_applied_text">
            {getJobApplied(application)}
          </TableCellStyled>
          <TableCellStyled type="applied_text">
            {getAppliedDate(application)}
          </TableCellStyled>
          <TableCellStyled type="status_text" onClick={preventPropagation}>
            <StatusSelect
              anchorOrigin={{ vertical: 'bottom', horizontal: 65 }}
              disabled={
                application?.employerHiredStatus ||
                application?.state == JOB_APPLICATION_STATUS.BLACKLISTED ||
                application?.state == JOB_APPLICATION_STATUS.REJECTED
              }
              value={getStatus(application)}
              onChange={onStateChange(application)}
            />
          </TableCellStyled>
          <TableCellStyled type="resume_text" onClick={preventPropagation}>
            <IconWrapper>
              <Tooltip title={<Text>Download resume</Text>} placement="bottom">
                <IconButtonStyled
                  disabled={
                    !getResumeUrl(application) ||
                    downloadingResume ||
                    fetchingResumes
                  }
                  onClick={onDownloadIconClicked(application)}>
                  <GetAppIconStyled
                    disabled={
                      !getResumeUrl(application) ||
                      downloadingResume ||
                      fetchingResumes
                    }
                  />
                </IconButtonStyled>
              </Tooltip>
            </IconWrapper>
          </TableCellStyled>
          <TableCellStyled type="checkbox" onClick={preventPropagation}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={() => onSelectSingleCheckbox(application)}
                  checked={applicationUserIds.indexOf(application.user.id) > -1}
                  sx={{
                    '&.Mui-checked': {
                      color: '#222'
                    },
                    padding: '0 9px'
                  }}
                />
              }
              label={''}
              sx={{
                '& .MuiTypography-root': {
                  fontSize: 14,
                  letterSpacing: '0.15px'
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 20
                }
              }}
            />
          </TableCellStyled>
        </TableRowStyled>
      );
    });
  };

  return (
    <div
      style={{
        background: 'white',
        padding: '22px 20px',
        minHeight: 'calc(100vh - 300px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      {applications?.length == 0 && !fetchingApplications ? (
        <>
          <img
            alt="empty-application"
            style={{ height: '400px' }}
            src={emptyApplication}
          />
          <span
            style={{
              fontFamily: 'Inter',
              fontSize: '16px',
              fontWeight: '700'
            }}>
            No applicant found
          </span>
        </>
      ) : (
        <>
          <TableContainerStyled>
            <Table>
              <TableHead>
                <TableRowStyled>
                  {columns.map(column => (
                    <TableCellStyled
                      type={column.id}
                      key={column.id}
                      align={column.id == 'resume' ? 'center' : 'left'}
                      onClick={() => onClickColumn(column)}>
                      <Grid display={'flex'} alignItems={'center'} gap="10px">
                        {getTableTitle(column, applications)}
                        {showArrow(column.id)}
                      </Grid>
                    </TableCellStyled>
                  ))}
                </TableRowStyled>
              </TableHead>
              <TableBodyStyled>{renderRows()}</TableBodyStyled>
            </Table>
          </TableContainerStyled>
          {applications?.length > 0 && <Pagination />}
        </>
      )}

      <Grid padding={'20px 0'}>
        <Text>
          All applicants displayed are from the past one (1) year only. For any
          specific or additional requests, please contact us at&nbsp;
          <a
            style={{ textDecoration: 'none' }}
            target="_blank"
            href={`mailto:${CSEMAIL[getCountry()]}`}>
            {CSEMAIL[getCountry()]}
          </a>
        </Text>
      </Grid>

      {/* check make offer or offer popup later in ats */}

      <RejectPopUp
        handleClose={() => dispatch(toggleRejectPopup(false))}
        updateApplicationState={updateApplicationState}
        open={isRejectPopupStatusOpen}
      />
      <CloseLoopPopUp
        handleClose={() => dispatch(toggleCloseLoopPopup(false))}
      />

      <BlackListPopUp
        open={isBlacklistPopupOpen}
        handleClose={() => dispatch(updateBlacklistPopup(false))}
        updateApplicationState={updateApplicationState}
        application={application}
      />
      {/* for phase 2 */}
      {/* <ScheduleInterviewModal
        open={isFirstInterviewModalOpened}
        jobData={jobData}
      />
      <ScheduleNextInterviewModal
        open={isNextInterviewModalOpened}
        jobData={jobData}
        isAts={false}
      /> */}
    </div>
  );
};

export default CandidatesTable;
