import { styled as styling } from '@mui/material/styles';
import { Card, Avatar } from '@mui/material';
import Typography from '@mui/material/Typography';

export const BiodataContainer = styling('div')(( props ) => ({
  position: 'relative',
  minWidth: '915px',
  width: '70%',
  maxWidth: '1280px',
  margin: 'auto',

  ...(props.isVideoCV && {
    display: 'flex',
  }),

  '@media (max-width: 1024px)': {
    ...(props.isVideoCV && {
      display: 'block',
    }),
  },
}));

export const CardStyled = styling(Card)((props) => ({
  background: '#fff',
  padding: props.isVideoCV ? '40px' : '20px 40px 40px',
  borderRadius: '6px',
  width: '100%',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)',
  display: 'grid',
  gridTemplateColumns: props.isVideoCV ? 'auto' : 'repeat(2, 1fr)',

  '@media (max-width: 1024px)': {
    padding: props.isVideoCV ? '40px' : '20px 40px 40px',
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export const FirstContainer = styling('div')((props) => ({
  ...(!props.isVideoCV && {
    display: 'grid',
    gridTemplateColumns: 'auto',
    padding: '0 40px 0 0',
  }),
}));

export const CandidateAvatar = styling(Avatar)({
  height: '90px',
  width: '90px',
});

export const ProfileContainer = styling('div')({
  display: 'flex',
  alignItems: 'center',
});

export const ProfileSubContainer = styling('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const Text = styling('p')((props) => ({
  ...(props.type == 'name' && {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '0',
  }),

  ...(props.type == 'age' && {
    fontSize: '12px',
    color: '#666666',
    margin: '8px 4px 0 0',
  }),

  ...(props.type == 'nationality' && {
    fontSize: '12px',
    color: '#666666',
    margin: '8px 0 0 4px',
  }),

  ...(props.type == 'summary' && {
    fontSize: '12px',
    margin: '0',
  }),
}));

export const VideoContainer = styling('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  maxWidth: '45%',
  margin: '0 auto',
  flexDirection: 'column',

  '@media (max-width: 1024px)': {
    maxWidth: '100%',
    margin: '40px auto 20px',
  },
});

export const SummaryContainer = styling('div')((props) => ({
  ...(props.isVideoCV && {
    marginTop: '15px',
  }),
}));

export const SectionContainer = styling('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px',
  alignSelf: 'stretch'
});

export const LabelContainer = styling('div')({
  display: 'grid',
  width: '100%'
});

export const SectionLabel = styling(Typography)({
  color: '#40249B',
  fontWeight: '400',
  fontSize: '10px',
  fontFamily: 'Inter',
  lineHeight: '20px',
  letterSpacing: '1.5px',
  textTransform: 'uppercase',
  padding: '0'
});

export const SubContainer = styling('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '6px',
  width: '100%'
});

export const SubInfoContainer = styling('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 50%)',
  width: '100%'
});

export const BoldText = styling(Typography)(
  ({ $smallPadding, $noPadding }) => ({
    fontWeight: '600',
    fontSize: '12px',
    fontFamily: 'Inter',
    padding: $noPadding ? '0' : $smallPadding ? '5px 0' : '0'
  })
);

export const NormalText = styling(Typography)(
  ({ $smallPadding, $noPadding, $list }) => ({
    fontSize: '12px',
    fontFamily: 'Inter',
    padding: $noPadding ? '0' : $smallPadding ? '5px 0' : '0',

    ...($list && {
      display: 'list-item',
      listStyleType: 'disc',
      listStylePosition: 'inside',
      padding: '1px 0'
    })
  })
);
