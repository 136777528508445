import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  updateCurrentOnboardingPage,
  updateCurrentBg
} from '../../../../redux/actions/company_actions';
import {
  PageContainer,
  Title,
  Message,
  SecondContainer,
  BackButton,
  NextPageButton,
  FooterContainer,
  KeyboardBackspaceIconStyled,
  AnimatedContainer,
  VideoContainer,
  SecContentContainer,
  Container4,
  SecAnimatedContainer,

  MessageBrand,
  MessageBrandLine,
  MessageBrandTextBox,
  MessageBrandTitle,
  MessageBrandContext,
  MessageBrandTitlePurple,
  MessageBrandTitleGrey,

} from './styles';

export default function Branding(props) {
  const dispatch = useDispatch();

  const [out, setOut] = useState(false);
  const [slide, setSlide] = useState(false);

  const [randomGifs, setRandomGifs] = useState({
    gif1: null,
    gif2: null
  });

  const handlePreviousPage = () => {
    dispatch(updateCurrentBg('0'));
    dispatch(updateCurrentOnboardingPage('waitlist'));
  };

  const navigateToNextPage = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setOut(true);
    setSlide(true);

    setTimeout(() => {
      dispatch(updateCurrentOnboardingPage('company-canvas'));
    }, 1500);
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('randomData')) || {};
    setRandomGifs(storedData.gifs);
  }, []);

  return (
    <PageContainer>
      <AnimatedContainer>
        <SecContentContainer slide={slide}>
          <VideoContainer style={{ marginBottom: '15%' }}>
            <video
              controls
              loop
              width="100%"
              height="100%"
              autoPlay
              playsInline
              muted
              style={{
                borderRadius: '10px'
              }}>
              <source src={randomGifs.gif1} type="video/mp4"></source>
            </video>
          </VideoContainer>

          <VideoContainer style={{ marginTop: '15%' }}>
            <video
              controls
              loop
              width="100%"
              height="100%"
              autoPlay
              muted
              playsInline
              style={{
                borderRadius: '10px'
              }}>
              <source src={randomGifs.gif2} type="video/mp4"></source>
            </video>
          </VideoContainer>
        </SecContentContainer>
      </AnimatedContainer>

      <Container4>
        <SecAnimatedContainer>
          <SecondContainer>
            <MessageBrand out={out}>
                <MessageBrandTextBox>
                  <MessageBrandTitle>
                    What is Hiredly?
                  </MessageBrandTitle>
                  <MessageBrandContext>
                    Hiredly is modern, visual job platform that helps employers
                    attract quality Gen Y and Z talent.
                  </MessageBrandContext>
                </MessageBrandTextBox>

                <MessageBrandTextBox>
                  <MessageBrandTitle>
                    The Vision
                  </MessageBrandTitle>
                  <MessageBrandContext>
                    To be SEA’s most personalised talent ecosystem, elevating
                    human progress by helping careers and companies grow.
                  </MessageBrandContext>
                </MessageBrandTextBox>

                <MessageBrandTextBox>
                  <MessageBrandTitle>
                    The Mission
                  </MessageBrandTitle>
                  <MessageBrandContext>
                    To help 10 million jobseekers achieve career growth.
                  </MessageBrandContext>
                </MessageBrandTextBox>
              </MessageBrand>

              <MessageBrandLine out={out}/>

              <MessageBrand out={out}>
                <MessageBrandTitle>
                  Grow and build an adaptable tech-enabled workforce with
                  Hiredly Group’s personalised talent ecosystem.
                </MessageBrandTitle>

                <MessageBrandTitlePurple>
                  Hiredly will bring this unique recruitment experience to
                  Singapore on 26th April 2024. 
                </MessageBrandTitlePurple>

                <MessageBrandTitleGrey>
                  Continue to find out how you can be part of this exclusive
                  launch.
                </MessageBrandTitleGrey>
              </MessageBrand>
        
            <FooterContainer out={out}>
              <BackButton onClick={handlePreviousPage}>
                <KeyboardBackspaceIconStyled />
              </BackButton>

              <NextPageButton onClick={navigateToNextPage}>
                Continue
              </NextPageButton>
            </FooterContainer>
          </SecondContainer>
        </SecAnimatedContainer>
      </Container4>
    </PageContainer>
  );
}
