import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getApplications,
  updateApplicationCurrentPage,
  updateApplicationParams
} from '../../../redux/actions/application_action';
import store from '../../../redux/stores/store';
import { SEARCH_TYPE } from '../../../utils/Constants';
import SharedAdvancedFilter from '../../../components/SharedAdvancedFilter';
import moment from 'moment';
import { fetchSpecialisation } from '../../../redux/actions/job_action';
import { fetchStateRegions } from '../../../redux/actions/company_actions';
import {
  WORKPLACE_TYPE,
  WORK_RIGHTS
} from '../../../components/SharedAdvancedFilter/constants';
import {
  getAllCountryStates,
  getCountryStates
} from '../../../hooks/useRegionalisation';
import useGetQueryString from '../../../hooks/useGetQueryString';

const AdvancedFilter = ({ anchorEl, setAnchorEl }) => {
  const dispatch = useDispatch();

  const applicationParams = useSelector(
    state => state.applications.applicationParams
  );
  const allCountryStateRegions = getAllCountryStates();

  useEffect(() => {
    dispatch(fetchSpecialisation());
    dispatch(fetchStateRegions());
  }, []);

  const specialisation = useSelector(state => state.jobs.specialisation);
  const handleClose = isApplied => () => {
    setAnchorEl(null);
  };

  let checkUnviewed;
  checkUnviewed = useGetQueryString('unviewed');

  const open = Boolean(anchorEl);
  const [fromDateAnchorEl, setFromDateAnchorEl] = useState(null);

  const marks = [
    {
      value: 0,
      label: '0'
    },
    {
      value: 100,
      label: '10+'
    }
  ];

  const valueLabelFormat = value => {
    if (value === 0 || value === 1 || value === 100) return '';
    return value / 10;
  };

  const onSliderChange = (event, newValue) => {
    let cApplicationParams = {
      ...store.getState().applications.applicationParams
    };

    cApplicationParams.yearsOfExperience = newValue / 10;

    dispatch(updateApplicationParams(cApplicationParams));
  };

  const onClickHighEduLevel = id => {
    let cApplicationParams = {
      ...store.getState().applications.applicationParams
    };

    if (cApplicationParams.highestEducationLevels?.indexOf(id) > -1) {
      const removedArray = store
        .getState()
        .applications.applicationParams?.highestEducationLevels.filter(
          item => item !== id
        );

      cApplicationParams.highestEducationLevels = removedArray;
    } else {
      cApplicationParams.highestEducationLevels = [
        ...store.getState().applications.applicationParams
          .highestEducationLevels,
        id
      ];
    }

    dispatch(updateApplicationParams(cApplicationParams));
  };

  const onClickJobTracks = track => {
    const intId = Number(track.id);

    let cApplicationParams = {
      ...store.getState().applications.applicationParams
    };

    //update tracks

    if (cApplicationParams.trackIds?.indexOf(intId) > -1) {
      const removedArray = store
        .getState()
        .applications.applicationParams?.trackIds.filter(
          item => item !== intId
        );

      cApplicationParams.trackIds = removedArray;
    } else {
      cApplicationParams.trackIds = [
        ...store.getState().applications.applicationParams.trackIds,
        intId
      ];
    }

    // update specialisations

    if (cApplicationParams.specialisations?.indexOf(track.title) > -1) {
      const removedArray = store
        .getState()
        .applications.applicationParams?.specialisations.filter(
          item => item !== track.title
        );

      cApplicationParams.specialisations = removedArray;
    } else {
      cApplicationParams.specialisations = [
        ...store.getState().applications.applicationParams.specialisations,
        track.title
      ];
    }

    dispatch(updateApplicationParams(cApplicationParams));
  };

  const onApplyAdvancedFilter = () => {
    let params = { ...store.getState().applications.applicationParams };

    if (store.getState().applications.applicationCurrentPage > 1) {
      dispatch(updateApplicationCurrentPage(1));
    }
    dispatch(getApplications(params));

    handleClose(true)();
  };

  const resetAdvanceFilter = () => {
    const params = {
      jobId: applicationParams.jobId,
      first: 10,
      search: '',
      searchType: SEARCH_TYPE.ANY,
      sort: { by: 'createdAt', direction: 'desc' },
      showState: null,
      yearsOfExperience: 0,
      withVideoCoverLetter: false,
      highestEducationLevels: [],
      nationalities: '',
      currentLocation: [],
      relocate: false,
      remote: false,
      openToSponsor: false,
      visaOrEmploymentPass: false,
      localCitizen: false,
      trackIds: [],
      offset: 0,
      hideViewed: false,
      chatbotStatus: '',
      refresh: true,
      appliedFromDate: moment().subtract(1, 'year').unix(),
      appliedToDate: moment().unix(),
      specialisations: []
    };

    dispatch(updateApplicationParams(params));
  };

  const handleCompleteAivi = event => {
    let cApplicationParams = {
      ...store.getState().applications.applicationParams
    };

    if (event.target.checked) {
      cApplicationParams.chatbotStatus = '12';
    } else {
      cApplicationParams.chatbotStatus = '';
    }

    dispatch(updateApplicationParams(cApplicationParams));
  };

  const getVCL = () => {
    let cApplicationParams = {
      ...store.getState().applications.applicationParams
    };

    cApplicationParams.withVideoCoverLetter =
      !cApplicationParams.withVideoCoverLetter;

    dispatch(updateApplicationParams(cApplicationParams));
  };

  const onHandleSearchType = value => {
    let cApplicationParams = {
      ...store.getState().applications.applicationParams
    };

    cApplicationParams.searchType = value;

    dispatch(updateApplicationParams(cApplicationParams));
  };

  const onSelectViewed = () => {
    let cApplicationParams = {
      ...store.getState().applications.applicationParams
    };

    if (cApplicationParams.showState != 'viewed') {
      cApplicationParams.showState = 'viewed';
    } else {
      cApplicationParams.showState = null;
    }

    dispatch(updateApplicationParams(cApplicationParams));
  };

  const onSelectUnviewed = () => {
    let cApplicationParams = {
      ...store.getState().applications.applicationParams
    };

    if (cApplicationParams.showState != 'unviewed') {
      cApplicationParams.showState = 'unviewed';
    } else {
      cApplicationParams.showState = null;
    }

    dispatch(updateApplicationParams(cApplicationParams));
  };

  const onSelectFormDate = e => {
    if (!store.getState().applications.applicationParams) return;

    let cApplicationParams = {
      ...store.getState().applications.applicationParams
    };
    cApplicationParams.appliedFromDate = moment(e.toLocaleString()).unix();

    dispatch(updateApplicationParams(cApplicationParams));
  };

  const onSelectToDate = e => {
    if (!store.getState().applications.applicationParams) return;

    let cApplicationParams = {
      ...store.getState().applications.applicationParams
    };
    cApplicationParams.appliedToDate = moment(e.toLocaleString()).unix();

    dispatch(updateApplicationParams(cApplicationParams));
  };

  const onCurrentLocation = e => {
    const cApplicationParams = {
      ...store.getState().applications.applicationParams
    };

    const value = e.target.value;
    const isChecked = e.target.checked;
    const key = value.startsWith('all-') ? value.split('-')[1] : 'my';

    let titles = allCountryStateRegions[key].map(region => region.title);

    if (!Array.isArray(cApplicationParams.currentLocation)) {
      cApplicationParams.currentLocation = [];
    }

    if (value.startsWith('all-')) {
      if (isChecked) {
        cApplicationParams.currentLocation = [
          ...new Set([...cApplicationParams.currentLocation, ...titles])
        ];
      } else {
        cApplicationParams.currentLocation =
          cApplicationParams.currentLocation.filter(v => !titles.includes(v));
      }
    } else {
      if (isChecked) {
        if (!cApplicationParams.currentLocation.includes(value)) {
          cApplicationParams.currentLocation.push(value);
        }
      } else {
        cApplicationParams.currentLocation =
          cApplicationParams.currentLocation.filter(v => v !== value);
      }
    }

    cApplicationParams.currentLocation.sort();
    dispatch(updateApplicationParams(cApplicationParams));
  };

  const onNationality = value => {
    const cApplicationParams = {
      ...store.getState().applications.applicationParams
    };

    cApplicationParams.nationalities = value;

    dispatch(updateApplicationParams(cApplicationParams));
  };

  const onWorkplacePreference = e => {
    const cApplicationParams = {
      ...store.getState().applications.applicationParams
    };

    const value = e.target.value;
    switch (value) {
      case WORKPLACE_TYPE.RELOCATE:
        cApplicationParams.relocate = e.target.checked;
        break;
      case WORKPLACE_TYPE.REMOTE:
        cApplicationParams.remote = e.target.checked;
        break;
    }

    dispatch(updateApplicationParams(cApplicationParams));
  };

  const onRightsPreference = e => {
    const cApplicationParams = {
      ...store.getState().applications.applicationParams
    };

    const value = e.target.value;
    switch (value) {
      case WORK_RIGHTS.SPONSOR:
        cApplicationParams.openToSponsor = e.target.checked;
        break;
      case WORK_RIGHTS.VISA:
        cApplicationParams.visaOrEmploymentPass = e.target.checked;
        break;
      case WORK_RIGHTS.LOCAL:
        cApplicationParams.localCitizen = e.target.checked;
        break;
    }

    dispatch(updateApplicationParams(cApplicationParams));
  };

  useEffect(() => {
    if (checkUnviewed) {
      onSelectUnviewed();
    }
  }, [checkUnviewed]);

  return (
    <SharedAdvancedFilter
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      onHandleSearchType={onHandleSearchType}
      getVCL={getVCL}
      handleCompleteAivi={handleCompleteAivi}
      resetAdvanceFilter={resetAdvanceFilter}
      onApplyAdvancedFilter={onApplyAdvancedFilter}
      onClickJobTracks={onClickJobTracks}
      onClickHighEduLevel={onClickHighEduLevel}
      onSliderChange={onSliderChange}
      valueLabelFormat={valueLabelFormat}
      marks={marks}
      open={open}
      jobTracksData={specialisation}
      applicationParams={applicationParams}
      handleClose={handleClose}
      onSelectViewed={onSelectViewed}
      onSelectUnviewed={onSelectUnviewed}
      onSelectFormDate={onSelectFormDate}
      onSelectToDate={onSelectToDate}
      fromDateAnchorEl={fromDateAnchorEl}
      getEvent={event => {
        setFromDateAnchorEl(event.currentTarget);
      }}
      allCountryStateRegions={allCountryStateRegions}
      onCurrentLocation={onCurrentLocation}
      onNationality={onNationality}
      onWorkplacePreference={onWorkplacePreference}
      onRightsPreference={onRightsPreference}
      type={'all-applicans-page'}
    />
  );
};

export default AdvancedFilter;
