import Colors from '../../../../../styles/colors.module.scss';
import { styled as styling } from '@mui/material/styles';
import { TextField, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

export const TextFieldStyled = styling(TextField)({
  '& .MuiInputBase-root': {
    borderRadius: '8px'
  },

  '& .MuiInputBase-input': {
    // padding: '5px 15px'
  }
});

export const SendIconStyled = styling(SendIcon)({
  fontSize: '20px',
  color: `${Colors.priPurple}`
});

export const MessageCotainer = styling('div')(props => ({
  display: 'flex',
  alignItems: props.$userType == 'employer' ? 'flex-end' : 'flex-start',
  flexDirection: 'column'
}));

export const MessageCotent = styling('div')(props => ({
  background: props.$userType == 'employer' ? '#222' : '#efefef',
  padding: '8px 16px',
  borderRadius: '8px',
  maxWidth: '75%',
  margin: '4px 0'
}));

export const Message = styling('span')(props => ({
  color: props.$userType == 'employer' ? '#fff' : '#222',
  fontSize: '14px',
  fontFamily: 'Inter'
}));

export const Time = styling('span')(props => ({
  color: props.$userType && props.$userType == 'employer' ? '#fff' : '#222',
  fontSize: '10px',
  fontFamily: 'Inter'
}));

export const DateCotainer = styling('div')(props => ({
  margin: '12px 0',
  textAlign: 'center'
}));

export const Date = styling('span')(props => ({
  color: '#222',
  fontSize: '10px',
  fontFamily: 'Inter',
  fontWeight: '500',
  marginBottom: '16px'
}));

export const AdminMessage = styling('span')(props => ({
  color: '#222',
  fontSize: '12px',
  fontFamily: 'Inter',
  marginBottom: '16px',
  display: 'flex',
  justifyContent: 'center'
}));

export const Center = styling('div')(props => ({
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const UnreadMessageDivider = styling('div')({
  position: 'relative',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px 0',
  cursor: 'default',

  '&::before': {
    content: '""',
    flex: 1,
    borderBottom: `1px solid ${Colors.terMediumGrey}`,
  },

  '&::after': {
    content: '""',
    flex: 1,
    borderBottom: `1px solid ${Colors.terMediumGrey}`,
  },
});

export const UnreadMessageDividerText = styling(Typography)({
  padding: '0 15px',
  color: `rgba(0, 0, 0, 0.6)`,
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '20px'
});
