import React, { useEffect, useState } from 'react';
import ContentCard from '../components/ContentCard';
import { useDispatch, useSelector } from 'react-redux';
import {
  BenefitsWrapper,
  EmptyBenefitContainer,
  PlusCircleIconStyled
} from './styles';
import EditBenefitCards from './components/EditBenefitCards';
import SaveButton from '../components/SaveButton';
import { Grid } from '@mui/material';
import {
  mutateCompanyProfile,
  updateCompanySidebar
} from '../../../redux/actions/company_actions';
import { ErrorToast, SuccessToast } from '../../../utils/ToastUtils';
import { isEmpty, isEqual } from 'lodash';

export default function EditState(props) {
  const { setView } = props;
  const dispatch = useDispatch();
  const companyProfile = useSelector(state => state.companies.companyProfile);
  const companyCompletion = useSelector(
    state => state.companies.companyCompletion
  );
  const [benefitsList, setBenefitsList] = useState([]);
  const [benefitsListOriginal, setBenefitsListOriginal] = useState([]);
  const [loading, setLoading] = useState(false);

  const exceedLimit = () => {
    const exceed = benefitsList?.map((benefits, index) => {
      return (
        benefits?.title?.length > 75 || benefits?.description?.length > 150
      );
    });

    return exceed.includes(true);
  };

  const hasChanges = () => {
    const editChanges = benefitsList?.map((benefits, index) => {
      const original = benefitsListOriginal[index];
      return (
        isEqual(benefits?.id, original?.id) &&
        isEqual(benefits?.title, original?.title) &&
        isEqual(benefits?.description, original?.description) &&
        isEqual(benefits?.sortOrder, original?.sortOrder) &&
        isEqual(benefits?.iconCode, original?.iconCode)
      );
    });

    return (
      editChanges?.includes(false) ||
      benefitsListOriginal?.length > benefitsList?.length
    );
  };

  const missingField = () => {
    const emptyField = benefitsList?.find(benefit => {
      return (
        isEmpty(benefit?.title) ||
        isEmpty(benefit?.description) ||
        isEmpty(benefit?.iconCode)
      );
    });

    return emptyField !== undefined && emptyField !== null;
  };

  useEffect(() => {
    if (companyProfile?.benefits) {
      const dataWithSort = companyProfile?.benefits?.map((item, index) => ({
        ...item,
        sortOrder: index + 1
      }));

      setBenefitsList(dataWithSort);
      setBenefitsListOriginal(JSON.parse(JSON.stringify(dataWithSort)));
      if (dataWithSort.length === 0) {
        addItem();
      }
    }
  }, [companyProfile?.benefits]);

  const addItem = () => {
    setBenefitsList(prev => {
      const newOrder = prev.length + 1;
      const newItem = {
        id: `${Date.now()}`,
        sortOrder: newOrder,
        title: '',
        description: '',
        completed: false
      };
      return [...prev, newItem];
    });
  };

  const removeItem = sortOrder => {
    setBenefitsList(prev => {
      const updatedList = prev.filter(item => item.sortOrder !== sortOrder);
      updatedList.forEach((item, index) => {
        item.sortOrder = index + 1;
      });
      return updatedList;
    });
  };

  const handleChange = (id, field, value) => {
    setBenefitsList(prev => {
      const index = prev.findIndex(item => item.id === id);
      if (index !== -1) {
        const updatedList = [...prev];
        updatedList[index][field] = value;
        return updatedList;
      }
      return prev;
    });
  };

  const handleChangeOrder = (sortOrder, action) => {
    setBenefitsList(prev => {
      const updatedList = [...prev];
      const itemIndex = updatedList.findIndex(
        item => item.sortOrder === sortOrder
      );

      if (itemIndex !== -1) {
        const swapIndex = action === 'up' ? itemIndex - 1 : itemIndex + 1;

        if (
          (action === 'up' && swapIndex >= 0) ||
          (action === 'down' && swapIndex < updatedList.length)
        ) {
          const temp = updatedList[itemIndex];
          updatedList[itemIndex] = updatedList[swapIndex];
          updatedList[swapIndex] = temp;

          return updatedList.map((item, index) => ({
            ...item,
            sortOrder: index + 1
          }));
        }
      }

      return updatedList;
    });
  };

  const onSave = () => {
    // Add _destroy if want to remove
    const itemsInCompanyProfileOnly = (companyProfile?.benefits || []).filter(
      item => !benefitsList.some(benefit => benefit.id === item.id)
    );

    const deleteList = itemsInCompanyProfileOnly?.map(benefit => {
      return { ...benefit, _destroy: true };
    });

    // Remove id if new record
    const addedList = benefitsList?.map(benefit => {
      const { id, ...rest } = benefit;
      if (id?.length > 8) {
        return rest;
      }
      return benefit;
    });

    const restructured = [...addedList, ...deleteList];

    setLoading(true);
    dispatch(
      mutateCompanyProfile({
        input: {
          benefits: restructured
        }
      })
    )
      .then(res => {
        if (res.type === 'FETCH_COMPANY_PROFILE_SUCCEED') {
          SuccessToast('Our Benefits Updated Successfully!');
          dispatch(
            updateCompanySidebar({
              ...companyCompletion,
              companyBenefits: true
            })
          );
          setView(true);
        } else {
          ErrorToast(res.errorMessage);
        }

        window.dataLayer.push({
          event: 'save-cp-benefits'
        });
      })
      .catch(err => {
        ErrorToast(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ContentCard
      title="Company Benefits"
      subTitle="Enhance your company's competitive advantage with up to 6 benefits - the more unique, the better!"
      discardButton
      noChanges={!hasChanges()}
      cancelIcon={!isEmpty(companyProfile?.benefits)}
      cancelOnClick={() => setView(true)}>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}>
        <BenefitsWrapper>
          {benefitsList
            ?.sort((a, b) => {
              return a.sortOrder - b.sortOrder;
            })
            .map((benefit, index) => {
              return (
                <EditBenefitCards
                  index={index}
                  key={benefit?.slot}
                  benefit={benefit}
                  handleChange={handleChange}
                  handleChangeOrder={handleChangeOrder}
                  removeIcon={removeItem}
                />
              );
            })}
          {benefitsList?.length !== 6 && (
            <EmptyBenefitContainer onClick={addItem}>
              <PlusCircleIconStyled /> Add a company benefit
            </EmptyBenefitContainer>
          )}
        </BenefitsWrapper>
        <SaveButton
          disabled={
            loading ||
            !hasChanges() ||
            missingField() ||
            exceedLimit() ||
            benefitsList?.length < 1
          }
          onClick={onSave}>
          Save
        </SaveButton>
      </Grid>
    </ContentCard>
  );
}
