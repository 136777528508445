import { styled as styling } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

export const DialogStyled = styling(Dialog)(props => ({
  '& .MuiDialog-paper': {
    borderRadius: 12,
    width: '680px !important'
  }
}));

export const DialogTitleStyled = styling(DialogTitle)(props => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: "center",
  justifyContent: 'space-between',
  padding: "16px 24px 16px 24px"
}));

export const TitleStyled = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.87)`,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px',
  marginBottom: "0px"
}));

export const DialogContentStyled = styling(DialogContent)(props => ({
  padding: '24px 0px 0px 0px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px'
}));

export const ContentContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px'
}));

export const DialogActionsStyled = styling(DialogActions)(props => ({}));

export const IconButtonStyled = styling(IconButton)(props => ({
  height: 'fit-content',
  padding: "0px"
}));

export const CloseIconStyled = styling(CloseIcon)(props => ({}));

export const TypographyStyled = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.87)`,
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const TitleDescStyled = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.60)`,
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const TypographySpan = styling('span')(props => ({
  color: '#512ACC'
}));

export const ContainerStyled = styling(Grid)(props => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px'
}));

export const TitleContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  // justifyContent: 'space-between'
}));

export const ImageContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
}));
