import React from 'react';
import {
  Text,
  HeightSpacer,
  Spacer,
  ConfirmButtonContainer,
  ContactSalesContainer,
  IconContainer,
  TextContainer,
  InfoContainer,
  InfoContentContainer,
  InfoWrapper
} from './styles';
import { SharedButton } from '../SharedButton';
import { ReactComponent as ContactSalesIcon } from '../../../../../assets/icons/job-list/contact_sales_popup_image.svg';
import { ReactComponent as PhoneIcon } from '../../../../../assets/icons/job-list/phone_icon.svg';
import { ReactComponent as EmailIcon } from '../../../../../assets/icons/job-list/email_icon.svg';
import SharedBackButton from '../SharedBackButton';
import { useDispatch, useSelector } from 'react-redux';
import { updateExpiryDatesPopupContentIndex } from '../../../../../redux/actions/job_action';
import { CSEMAIL } from '../../../../../utils/Constants';
import { getCountry } from '../../../../../hooks/useRegionalisation';

const ContactSalesPopup = ({ onCloseModal }) => {
  const dispatch = useDispatch();

  const expiryDatesContentIndex = useSelector(
    state => state.jobs.expiryDatesPopupContentIndex
  );

  const navigateToExpiryDates = () => {
    dispatch(updateExpiryDatesPopupContentIndex(-1));
  };

  const onCloseExpiryDatesModal = () => {
    onCloseModal();
    dispatch(updateExpiryDatesPopupContentIndex(-1));
  };

  return (
    <ContactSalesContainer expiryDatesContentIndex={expiryDatesContentIndex}>
      <SharedBackButton onClick={navigateToExpiryDates} />

      <IconContainer>
        <ContactSalesIcon width={300} />
      </IconContainer>

      <HeightSpacer height={'20px'} />

      <TextContainer>
        <Text confirmation="true">Want to reschedule your job slots?</Text>

        <HeightSpacer height="20px" />

        <Text message="true">
          Reach out to us through email or give us a call!{' '}
        </Text>
      </TextContainer>

      <HeightSpacer height="50px" />

      <InfoWrapper>
        <InfoContainer>
          <InfoContentContainer>
            <PhoneIcon width={50} />
            <Spacer width={'10px'} />
            <Text info="true">+60327710141</Text>
          </InfoContentContainer>

          <Spacer width={'80px'} />

          <InfoContentContainer>
            <EmailIcon width={50} />
            <Spacer width={'10px'} />
            <Text info="true">{CSEMAIL[getCountry()]}</Text>
          </InfoContentContainer>
        </InfoContainer>
      </InfoWrapper>

      <HeightSpacer height="50px" />

      <ConfirmButtonContainer>
        <SharedButton
          type="basic"
          min_width={'240px'}
          font_size={'16px'}
          onClick={onCloseExpiryDatesModal}>
          Got it
        </SharedButton>
      </ConfirmButtonContainer>
    </ContactSalesContainer>
  );
};

export default ContactSalesPopup;
