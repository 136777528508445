import React, { useEffect, useState } from 'react';
import InfoPopup from '../../SharedPopups/InfoPopup';
import { MUIButtonStyled } from '../../../../components/MUIButton';
import {
  AcceptButton,
  ButtonsContainer,
  ContentContainer,
  LinkButton,
  TextContainer,
  TextStyled,
  TextStyledItalic
} from './styles';
import Security from '../../../../assets/common/security.svg';
import Hiredly from '../../../../assets/common/hiredly.svg';
import { CircularProgress, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateNewInfoPopupStatus } from '../../../../redux/actions/product_action';
import {
  mutateCompanyDetails,
  updateTermsPopupStatus
} from '../../../../redux/actions/company_actions';
import { JOBSEEKER_URL } from '../../../../utils/Constants';

const AgreeToTermsPopUp = () => {
  const dispatch = useDispatch();

  let hideNewProductsInfo = localStorage.getItem('hideNewProductsInfo');
  const termsPopUp = useSelector(state => state.companies.termsPopUp);

  const onClosePopup = () => {
    dispatch(updateTermsPopupStatus(false));
    if (!hideNewProductsInfo) {
      dispatch(updateNewInfoPopupStatus(true));
    }
  };

  return (
    <InfoPopup
      titleContainerPadding="12px 24px"
      content={<Content />}
      buttons={<Buttons />}
      $dialogMaxWidth={'800px'}
      open={termsPopUp}
      onClosePopup={onClosePopup}
      title={
        <Grid sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <img src={Hiredly} /> We’ve updated our Privacy Policy
        </Grid>
      }
      $dialogMaxHeight={'600px'}
    />
  );
};

export default AgreeToTermsPopUp;

export const Content = ({}) => {
  const dropdownCountry = useSelector(state => state.companies.country);

  return (
    <ContentContainer>
      <img src={Security} />

      <TextContainer>
        <TextStyled>
          Your privacy matters to us. We’ve updated our Privacy Policy to ensure
          your experience is secure. Please review and accept our latest
          policies and terms to proceed.
          <br></br>
          <br></br>
          Hiredly’s{' '}
          <TextStyledItalic
            href={JOBSEEKER_URL[dropdownCountry] + '/privacy-policy'}
            target="_blank">
            Privacy Policy
          </TextStyledItalic>{' '}
          and the{' '}
          <TextStyledItalic
            href={JOBSEEKER_URL[dropdownCountry] + '/terms-and-conditions'}
            target="_blank">
            Terms of Conditions.
          </TextStyledItalic>
        </TextStyled>
      </TextContainer>
    </ContentContainer>
  );
};

export const Buttons = () => {
  const dispatch = useDispatch();
  const fetchingCompanySettings = useSelector(
    state => state.companies.fetchingCompanySettings
  );

  const onAccept = () => {
    dispatch(
      mutateCompanyDetails({
        input: {
          agreeToTerms: true
        }
      })
    ).then(res => {
      dispatch(updateTermsPopupStatus(false));

      if (res?.type === 'FETCH_MY_COMPANY_SETTINGS_SUCCEED') {
        dispatch(updateTermsPopupStatus(false));
        localStorage.setItem('accessToken', res?.token);
        dispatch(updateNewInfoPopupStatus(true));
      }
    });
  };

  return (
    <ButtonsContainer>
      <LinkButton
        href={'https://my.hiredly.com/privacy_policy'}
        target="_blank"
        variant="text">
        View Our Policy
      </LinkButton>
      <AcceptButton
        disabled={fetchingCompanySettings}
        type={'black'}
        onClick={onAccept}>
        {fetchingCompanySettings ? (
          <CircularProgress sx={{ color: 'white' }} size={18} />
        ) : (
          'Accept'
        )}
      </AcceptButton>
    </ButtonsContainer>
  );
};
