import React, { useState } from 'react';
import {
  FormControl,
  FormGroup,
  Grid,
  Popover,
  TextField
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as AiviIcon } from '../../assets/common/aivi-icon.svg';
import { MUIButtonStyled } from '../MUIButton';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import store from '../../redux/stores/store';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import {
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryStyled,
  MainCategoryTitle,
  PopoverContainerStyled,
  TitleContainerStyled,
  ClearAllStyled,
  DividerStyled,
  FilterBodyStyled,
  GridContainerStyled,
  GridItemStyled,
  SectionTitleStyled,
  SliderContainerStyled,
  PrettoSliderStyled,
  SubCategoryContainerStyled,
  ButtonContainerStyled,
  DefaultContainer,
  DottedLine,
  AccordionDetailsColumnStyled
} from './styles';
import { useSelector } from 'react-redux';
import { highestEduLevel } from '../../utils/Constants';
import {
  LOCATION_ALL_LABEL,
  NATIONALITY_OPTIONS,
  WORKPLACE_TYPE,
  WORK_RIGHTS
} from './constants';
import SharedFormCheckbox from './SharedFormCheckbox';
import SharedFormRadioGroup from './SharedFormRadioGroup';
import { countryOptionsFullName } from '../SharedSelectCountry/constants';
import { getLocalStorageItem } from '../../helpers/local_storage_utils';
import { keyBy } from 'lodash';

const country = getLocalStorageItem('country');

const SharedAdvancedFilter = ({
  anchorEl,
  onHandleSearchType,
  getVCL,
  handleCompleteAivi,
  resetAdvanceFilter,
  onApplyAdvancedFilter,
  onClickJobTracks,
  onClickHighEduLevel,
  onSliderChange,
  valueLabelFormat,
  marks,
  open,
  jobTracksData,
  applicationParams,
  handleClose,
  onSelectViewed,
  onSelectUnviewed,
  onSelectFormDate,
  onSelectToDate,
  fromDateAnchorEl,
  getEvent,
  allCountryStateRegions,
  onCurrentLocation,
  onNationality,
  onWorkplacePreference,
  onRightsPreference,
  type
}) => {
  const [expandAccordion, setExpandAccordion] = useState(null);

  const subcategories = useSelector(
    state => state.applications.applicationParams.trackIds
  );

  const getSubCategoriesCount = majorCategory => {
    let count = 0;
    if (subcategories.length > 0) {
      subcategories.map(subcat => {
        majorCategory.subCategories.map(mSubs => {
          if (subcat == mSubs.id) {
            count = count + 1;
          }
        });
      });
    }

    return count;
  };

  const getSelectedLocationCount = key => {
    const cApplicationParams = {
      ...store.getState().applications.applicationParams
    };

    let titles = allCountryStateRegions[key].map(region => region.title);

    const locations = titles?.filter(location =>
      cApplicationParams.currentLocation?.includes(location)
    );

    return locations?.length;
  };

  const renderSharedFormCheckboxes = (
    stateRegions,
    start,
    end,
    applicationParams,
    onCurrentLocation
  ) => {
    return stateRegions.slice(start, end).map(stateRegion => {
      return (
        <SharedFormCheckbox
          key={stateRegion.id}
          value={stateRegion.title}
          checked={applicationParams.currentLocation?.includes(
            stateRegion.title
          )}
          onChange={onCurrentLocation}
          label={stateRegion.title}
        />
      );
    });
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: -50
      }}
      transformOrigin={{
        vertical: -20,
        horizontal: 'center'
      }}
      PaperProps={{
        style: {
          borderRadius: '20px'
        }
      }}
      style={{ maxHeight: '70vh' }}>
      <PopoverContainerStyled>
        <TitleContainerStyled>
          <p className="title">Advanced Filter</p>
          <ClearAllStyled onClick={resetAdvanceFilter}>
            Clear All
          </ClearAllStyled>
        </TitleContainerStyled>

        <FilterBodyStyled>
          <GridContainerStyled $marginTop={'0px'}>
            <FormControl component="fieldset">
              <SharedFormCheckbox
                checked={applicationParams.showState === 'viewed'}
                onClick={onSelectViewed}
                disabled={applicationParams.showState === 'unviewed'}
                label={'Viewed profiles only'}
              />

              <FormControl style={{ display: 'grid' }}>
                <SharedFormCheckbox
                  checked={applicationParams.searchType === 'any'}
                  onClick={() => onHandleSearchType('any')}
                  label={'Any of the words'}
                />
                <SharedFormCheckbox
                  checked={applicationParams.searchType === 'all'}
                  onClick={() => onHandleSearchType('all')}
                  label={'All of the words'}
                />
                <SharedFormCheckbox
                  checked={applicationParams.searchType === 'exact'}
                  onClick={() => onHandleSearchType('exact')}
                  label={'Exact phrase'}
                />
              </FormControl>
            </FormControl>

            <FormControl>
              <FormControl>
                <GridItemStyled>
                  <SharedFormCheckbox
                    checked={applicationParams.showState === 'unviewed'}
                    onClick={onSelectUnviewed}
                    label={'Unviewed profiles only'}
                    disabled={applicationParams.showState === 'viewed'}
                  />
                </GridItemStyled>
                <GridItemStyled>
                  <SharedFormCheckbox
                    checked={applicationParams?.withVideoCoverLetter}
                    onClick={getVCL}
                    label={'Video Cover Letter'}
                  />
                  <FontAwesomeIcon icon={faVideo} size="xs" />
                </GridItemStyled>

                <GridItemStyled>
                  <SharedFormCheckbox
                    checked={applicationParams?.chatbotStatus === '12'}
                    onClick={handleCompleteAivi}
                    label={'Virtual Interview Complete'}
                  />
                  <AiviIcon width={15} />
                </GridItemStyled>
              </FormControl>
            </FormControl>
          </GridContainerStyled>

          <DividerStyled />

          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
            <SectionTitleStyled>Current Location</SectionTitleStyled>

            <div>
              {Object.keys(allCountryStateRegions)?.length > 0 &&
                Object.keys(allCountryStateRegions).map(key => {
                  let stateRegion = allCountryStateRegions[key];

                  return (
                    <Accordion
                      column={'two'}
                      type={'curentLocation'}
                      title={
                        <Grid
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                          }}>
                          <SectionTitleStyled type="currentLocation">
                            {key == 'my'
                              ? countryOptionsFullName['MY']
                              : key == 'sg'
                              ? countryOptionsFullName['SG']
                              : ''}
                          </SectionTitleStyled>
                          {getSelectedLocationCount(key) > 0 && (
                            <SubCategoryContainerStyled
                              sx={{ marginRight: '8px' }}>
                              {getSelectedLocationCount(key)}
                            </SubCategoryContainerStyled>
                          )}
                        </Grid>
                      }
                      description={
                        <>
                          <AccordionDetailsColumnStyled>
                            <SharedFormCheckbox
                              value={`all-${key}`}
                              checked={
                                applicationParams.currentLocation &&
                                applicationParams.currentLocation.length > 1 &&
                                stateRegion
                                  .map(r => r.title)
                                  .every(location =>
                                    applicationParams.currentLocation?.includes(
                                      location
                                    )
                                  )
                              }
                              onChange={onCurrentLocation}
                              label={LOCATION_ALL_LABEL[country]}
                            />
                            {renderSharedFormCheckboxes(
                              stateRegion,
                              0,
                              9,
                              applicationParams,
                              onCurrentLocation
                            )}
                          </AccordionDetailsColumnStyled>
                          <AccordionDetailsColumnStyled>
                            {renderSharedFormCheckboxes(
                              stateRegion,
                              9,
                              stateRegion?.length,
                              applicationParams,
                              onCurrentLocation
                            )}
                          </AccordionDetailsColumnStyled>
                        </>
                      }
                    />
                  );
                })}
            </div>
          </div>

          <DividerStyled />

          <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
            <SectionTitleStyled>Nationality</SectionTitleStyled>
            <SharedFormRadioGroup
              style={{ marginLeft: '0.75rem' }}
              value={applicationParams.nationalities}
              onChange={e => onNationality(e.target.value)}
              options={NATIONALITY_OPTIONS[country]}
              $type={type}
            />
          </div>

          <DividerStyled />

          <div  style={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
            <SectionTitleStyled>Availability & Relocation</SectionTitleStyled>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '0.75rem'
              }}>
              <SharedFormCheckbox
                value={WORKPLACE_TYPE.RELOCATE}
                checked={applicationParams.relocate}
                onClick={onWorkplacePreference}
                label={'Willing to relocate'}
              />
              <SharedFormCheckbox
                value={WORKPLACE_TYPE.REMOTE}
                checked={applicationParams.remote}
                onClick={onWorkplacePreference}
                label={'Remote only'}
              />
            </div>
          </div>

          <DividerStyled />

          <div  style={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
            <SectionTitleStyled>Right to Work</SectionTitleStyled>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '0.75rem'
              }}>
              <SharedFormCheckbox
                value={WORK_RIGHTS.SPONSOR}
                checked={applicationParams.openToSponsor}
                onClick={onRightsPreference}
                label={'Requires sponsorship'}
              />
              <SharedFormCheckbox
                value={WORK_RIGHTS.VISA}
                checked={applicationParams.visaOrEmploymentPass}
                onClick={onRightsPreference}
                label={'Has an existing right to work'}
              />

              <SharedFormCheckbox
                value={WORK_RIGHTS.LOCAL}
                checked={applicationParams.localCitizen}
                onClick={onRightsPreference}
                label={'Local Citizen / PR'}
              />
            </div>
          </div>

          <DividerStyled />

          <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
            <SectionTitleStyled>
              Applications Between Date Range
            </SectionTitleStyled>
            <Grid display="flex" alignItems={'center'}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  inputFormat="DD/MM/YYYY"
                  value={moment.unix(applicationParams.appliedFromDate)}
                  onChange={onSelectFormDate}
                  PopperProps={{
                    placement: 'bottom-end',
                    anchorEl: fromDateAnchorEl
                  }}
                  minDate={moment().subtract(1, 'year')}
                  maxDate={moment()}
                  renderInput={params => (
                    <TextField
                      {...params}
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        width: 'auto'
                      }}
                      onClick={getEvent}
                    />
                  )}
                />
                <span style={{ margin: '0 20px' }}>to</span>
                <DatePicker
                  inputFormat="DD/MM/YYYY"
                  value={moment.unix(applicationParams.appliedToDate)}
                  onChange={onSelectToDate}
                  PopperProps={{
                    placement: 'bottom-end',
                    anchorEl: fromDateAnchorEl
                  }}
                  minDate={moment.unix(applicationParams.appliedFromDate)}
                  maxDate={moment()}
                  renderInput={params => (
                    <TextField
                      {...params}
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        width: 'auto'
                      }}
                      onClick={getEvent}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </div>

          <DividerStyled />

          <div  style={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
            <SectionTitleStyled>Years of Experience</SectionTitleStyled>
            <SliderContainerStyled>
              <PrettoSliderStyled
                valueLabelDisplay="on"
                valueLabelFormat={valueLabelFormat}
                defaultValue={10}
                step={10}
                marks={marks}
                value={
                  applicationParams?.yearsOfExperience == null
                    ? 0
                    : applicationParams?.yearsOfExperience * 10
                }
                onChange={onSliderChange}
              />
            </SliderContainerStyled>
          </div>

          <DividerStyled />

          <div  style={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
            <SectionTitleStyled>Highest Education Level</SectionTitleStyled>
            <div style={{ maxWidth: '95%', margin: 'auto' }}>
              <FormControl component="fieldset">
                <FormGroup>
                  {highestEduLevel.map(item => (
                    <SharedFormCheckbox
                      key={item.id}
                      value={item.title}
                      checked={
                        applicationParams?.highestEducationLevels?.indexOf(
                          item.id
                        ) > -1
                      }
                      onClick={() => onClickHighEduLevel(item?.id)}
                      label={item?.title}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </div>
          </div>

          <DividerStyled />

          <div  style={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
            <SectionTitleStyled>
              Job Specialisations (based on work experience)
            </SectionTitleStyled>

            <FormControl component="fieldset" fullWidth>
              <FormGroup>
                {jobTracksData &&
                  jobTracksData?.map(track => {
                    return (
                      <AccordionStyled
                        key={track?.id}
                        id={track?.id}
                        onClick={() => {
                          setExpandAccordion(
                            expandAccordion === track?.title
                              ? null
                              : track?.title
                          );
                        }}
                        disableGutters
                        expanded={expandAccordion === track?.title}>
                        <AccordionSummaryStyled
                          id={track?.title}
                          expandIcon={
                            <ChevronRightIcon width={20} height={20} />
                          }>
                          <Grid
                            container
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            marginRight={'8px'}
                            flexWrap={'nowrap'}>
                            <SharedFormCheckbox
                              controlProps={{ style: { marginLeft: 0 } }}
                              shouldHide={expandAccordion !== track?.title}
                              checked={track?.subCategories.every(subCat =>
                                subcategories.includes(parseInt(subCat?.id))
                              )}
                              onClick={e => {
                                e.stopPropagation();
                                if (expandAccordion !== track?.title) return;
                                // select all sub categories
                                track?.subCategories?.map(subCat =>
                                  subcategories.indexOf(
                                    parseInt(subCat?.id)
                                  ) === -1
                                    ? // triggers onClickJobTracks only if redux array of integer doesn't include target id
                                      onClickJobTracks(subCat)
                                    : // else if redux includes all sub categories (selected all), deselect all
                                    track?.subCategories.every(subCat =>
                                        subcategories.includes(
                                          parseInt(subCat?.id)
                                        )
                                      )
                                    ? onClickJobTracks(subCat)
                                    : // else do nothing, by simply returning an empty callback
                                      {}
                                );
                              }}
                              label={
                                <MainCategoryTitle variant="body1">
                                  {track?.title}
                                </MainCategoryTitle>
                              }
                            />
                            {getSubCategoriesCount(track) > 0 && (
                              <SubCategoryContainerStyled>
                                {getSubCategoriesCount(track)}
                              </SubCategoryContainerStyled>
                            )}
                          </Grid>
                        </AccordionSummaryStyled>
                        <AccordionDetailsStyled>
                          {track?.subCategories &&
                            track?.subCategories.map(subCat => (
                              <SharedFormCheckbox
                                key={subCat.id}
                                controlProps={{
                                  sx: {
                                    display: 'flex',
                                    '&:hover': { backgroundColor: '#EFEFEF' }
                                  }
                                }}
                                checked={
                                  applicationParams?.trackIds?.indexOf(
                                    Number(subCat?.id)
                                  ) > -1
                                }
                                onClick={() => onClickJobTracks(subCat)}
                                label={subCat?.title}
                              />
                            ))}
                        </AccordionDetailsStyled>
                      </AccordionStyled>
                    );
                  })}
              </FormGroup>
            </FormControl>
          </div>
        </FilterBodyStyled>
      </PopoverContainerStyled>

      <ButtonContainerStyled>
        <MUIButtonStyled
          label="Cancel"
          solidWhite
          onClick={handleClose(false)}
          $width={'100px'}
          $shadow={'none'}
        />
        <MUIButtonStyled
          label="Apply"
          solidBlack
          onClick={onApplyAdvancedFilter}
          $width={'100px'}
          $shadow={'4px 4px 10px rgba(0, 0, 0, 0.16)'}
          $borderRadius={'8px'}
        />
      </ButtonContainerStyled>
    </Popover>
  );
};

const Accordion = props => {
  const { title, description, column, type } = props;

  return (
    <AccordionStyled disableGutters>
      <AccordionSummaryStyled
        type={type}
        expandIcon={<ChevronRightIcon width={20} height={20} />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        {title}
      </AccordionSummaryStyled>
      <AccordionDetailsStyled column={column}>
        {description}
      </AccordionDetailsStyled>
    </AccordionStyled>
  );
};

export default SharedAdvancedFilter;
