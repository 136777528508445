import { styled as styling } from '@mui/material/styles';
import { TextField, FormControlLabel, Radio, RadioGroup } from '@mui/material';

export const TextFieldStyled = styling(TextField)(
  ({ theme, small, value, select, bold, white, name, isError }) => ({
    width: '100%',
    // Adjust the padding of the input field to change its height
    '& .MuiInputBase-root': {
      fontSize: '0.85rem',
      color:
        value === 'placeholder' && select
          ? '#d8d8d8'
          : white
          ? 'white'
          : 'black', // Text color
      fontWeight: bold && 'bold',
      ...(small && {
        height: '45px',
        '& .MuiOutlinedInput-input': {
          '&::placeholder': {
            color: '#d8d8d8 !important', // Change to desired placeholder color
            opacity: 1 // Ensure the placeholder is fully visible
          },
          paddingTop: '10px', // Reduced top padding
          paddingBottom: '5px', // Increased bottom padding to move text down
          paddingLeft: '14px', // Maintain side paddings
          paddingRight: '14px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
          top: 0
        }
      })
    },
    '& .MuiOutlinedInput-root': {
      height: '36px',
      padding: '8px 16px',
      '& input': {
        padding: '0px'
      },
      '& fieldset': {
        borderRadius: '8px',
        //   borderColor: white ? 'white' : '#d8d8d8' // Border color
        border: isError ? '1px solid #BE4242' : '1px solid #D8D8D8'
      },
      '&:hover fieldset': {
        //   borderColor: white ? 'white' : '#d8d8d8' // Border color on hover
        border: isError ? '1px solid #BE4242' : '1px solid #D8D8D8'
      },
      '&.Mui-focused fieldset': {
        //   borderColor: white ? 'white' : '#512ACC' // Border color on focus
        border: '1px solid #512ACC'
      },
      '& input:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0 1000px white inset',
        '-webkit-text-fill-color': '#000'
      },
      '& input:-webkit-autofill:hover, & input:-webkit-autofill:focus': {
        '-webkit-box-shadow': '0 0 0 1000px white inset',
        '-webkit-text-fill-color': '#000'
      }
    },
    ...(small && {
      height: '45px' // Adjust the height of the entire component if small is true
    })
  })
);

export const FormControlLabelStyled = styling(FormControlLabel)(props => ({
  display: 'flex',
  gap: '8px',
  margin: '0',
  alignItems: 'flex-start',
  width: '100%',

  '& p': {
    margin: '0',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.15px'
  }
}));

export const RadioStyled = styling(Radio)(props => ({
  padding: '0px',

  '&.Mui-checked': {
    color: '#512acc !important',
    '.MuiSvgIcon-root:nth-child(2) path': {
      fill: '#512acc !important',
    },
    '.MuiSvgIcon-root:nth-child(1) path': {
      fill: '#512acc !important',
    },
  },

  ':hover': {
    color: '#AEAEAE',
    '.MuiSvgIcon-root:nth-child(1) path': {
      fill: 'rgba(0, 0, 0, 0.6)',
    },
    '& .MuiSvgIcon-root': {
      transform: 'scale(1)',
      transition: 'none'
    },
  },
}));

export const RadioGroupOuterStyled = styling(RadioGroup)(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch'
}));

export const RadioGroupStyled = styling(RadioGroup)(props => ({
  padding: '0px 0px 8px 24px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch',
  width: '100%'
}));

export const MainContainer = styling('div')(props => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px'
}));

export const TopSection = styling('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '8px',
  flexDirection: 'column',
  alignSelf: 'stretch'
}));

export const Title = styling('div')(() => ({
  fontSize: '20px',
  fontWeight: '700',
  fontFamily: 'Inter',
  lineHeight: '24px',
  color: 'rgba(0, 0, 0, 0.87)'
}));

export const Description = styling('div')(() => ({
  fontSize: '12px',
  fontWeight: '400',
  fontFamily: 'Inter',
  lineHeight: '16.8px',
  color: 'rgba(0, 0, 0, 0.87)'
}));
