import React, { useEffect, useState } from 'react';
import { ContainerStyled, CoverDesc, CoverTitle, TitleWrapper } from './styles';
import { Grid } from '@mui/material';
import IconButton from '../components/IconButton';
import { PencilIconStyled } from '../CoverImage/styles';
import { LocationOn } from '@mui/icons-material';
import GoogleMapReact from 'google-map-react';
import { useSelector } from 'react-redux';

const { REACT_APP_GOOGLE_API_KEY } = process.env;

export default function ViewState(props) {
  const { setView } = props;

  const companyProfile = useSelector(state => state.companies.companyProfile);
  const fetchingCompanyProfile = useSelector(
    state => state.companies.fetchingCompanyProfile
  );

  const [coordinates, setCoordinates] = useState({
    lat: 3.139003,
    lng: 101.686855
  });

  useEffect(() => {
    if (!companyProfile?.latitude || !companyProfile?.longitude) {
      setCoordinates({
        lat: 3.139003,
        lng: 101.686855
      });
    } else {
      setCoordinates({
        lat: companyProfile?.latitude,
        lng: companyProfile?.longitude
      });
    }
  }, [companyProfile]);

  useEffect(() => {}, [coordinates]);

  return (
    <ContainerStyled>
      <TitleWrapper>
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <CoverTitle>Company Address</CoverTitle>
          <CoverDesc>
            Let jobseekers know where your company is based.
          </CoverDesc>
        </Grid>

        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            alignItems: 'center'
          }}>
          <IconButton
            onClick={() => {
              setView(false);
            }}
            tooltipTitle="Edit">
            <PencilIconStyled />
          </IconButton>
        </Grid>
      </TitleWrapper>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '24px',
          justifyContent: 'space-between'
        }}>
        <Grid
          sx={{
            width: '250px',
            lineHeight: '24px',
            letterSpacing: '0.15px'
          }}>
          {companyProfile?.address}
        </Grid>

        <Grid
          sx={{
            width: '645px',
            height: '240px',
            borderRadius: '16px',
            border: '1px solid #AEAEAE',
            overflow: 'hidden'
          }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: REACT_APP_GOOGLE_API_KEY
            }}
            center={
              coordinates || {
                lat: 3.139003,
                lng: 101.686855
              }
            }
            defaultZoom={15}>
            <LocationOn
              sx={{ color: '#f75443', fontSize: 40 }}
              lat={coordinates?.lat || 3.139003}
              lng={coordinates?.lng || 101.686855}
            />
          </GoogleMapReact>
        </Grid>
      </Grid>
    </ContainerStyled>
  );
}
