import { styled as styling } from '@mui/material/styles';
import { ArrowLongRightIcon } from '@heroicons/react/24/solid';
import { PuzzlePieceIcon } from '@heroicons/react/24/solid';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { BoltIcon } from '@heroicons/react/24/solid';
import { Switch, Typography } from '@mui/material';
import { ArrowPathIcon } from '@heroicons/react/24/solid';

export const FormStyle = styling('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px'
});

export const StyledLabel = styling('label')(
  ({ isSelected, disabled, optionKey, key }) => ({
    overflow: 'hidden',
    display: `${
      optionKey === 'job_credit' && disabled ? 'none' : 'inline-flex'
    }`,
    width: '100%',
    border: `${
      disabled
        ? '1px solid #D8D8D8'
        : isSelected &&
          (optionKey == 'job_slot_max' || optionKey == 'job_slot_max_6m')
        ? '2px solid transparent'
        : isSelected
        ? '2px solid #512ACC'
        : '1px solid #D8D8D8'
    }`,
    background: `${
      disabled
        ? '#F5F5F5'
        : isSelected &&
          (optionKey == 'job_slot_max' || optionKey == 'job_slot_max_6m')
        ? '#FFF'
        : isSelected
        ? '#FFF'
        : '#FFF'
    }`,
    backgroundImage: `${
      disabled
        ? '#F5F5F5'
        : isSelected &&
          (optionKey == 'job_slot_max' || optionKey == 'job_slot_max_6m')
        ? 'linear-gradient(-45deg, rgb(176, 205, 114), rgb(110, 199, 205), rgb(176, 205, 114))'
        : isSelected
        ? '#FFF'
        : '#FFF'
    }`,
    // borderImage: `${
    //   isSelected && optionKey === 'job_slot_max'
    //     ? 'linear-gradient(90deg, #6EC197, #B0CD72) 1'
    //     : 'none'
    // }`,
    // padding: '12px 16px',
    borderRadius: '8px',
    gap: '12px',
    alignItems: 'flex-start',
    flexDirection: 'column',

    '& input': {
      margin: '5px 0px'
    },

    '& p': {
      color: `${disabled ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 0.87)'}`,
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      margin: '0'
    },

    '&:hover': {
      cursor: `${disabled ? 'default' : 'pointer'}`
    }
  })
);

export const LearnMore = styling('div')({
  display: 'flex',
  height: '24px',
  alignItems: 'center',
  gap: '6px',
  borderRadius: '4px',

  '& span': {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    background:
      'linear-gradient(270deg, #512ACC 0%, #6D32C5 45.87%, #983EBA 101.73%)',
    backgroundClip: 'text',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent'
  },

  '&:hover': {
    cursor: 'pointer'
  }
});

export const PuzzleIconStyled = styling(InformationCircleIcon)(props => ({
  padding: '0',
  width: '16px',
  height: '16px',
  color: '#512ACC'
}));

export const PreviewBox = styling('div')({
  color: '#FFF',
  fontSize: '12px',
  fontWeight: 700,
  background: '#2E2E2E',
  borderRadius: '4px',
  gap: '4px',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0px 12px',
  display: 'flex',
  height: '24px',

  '&:hover': {
    cursor: `pointer`
  }
});

export const LeftPart = styling('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px'
});

export const RightPart = styling('div')({
  '& span': {
    color: 'rgba(0, 0, 0, 0.60)',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px'
  }
});

export const TextPart = styling('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'flex-start'
});

export const TextBox = styling('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'flex-start',
  gap: '10px',
  flex: '1 0 0'
});

export const TextNotAvailable = styling('div')({
  display: 'flex',
  width: '100%',
  color: ' rgba(0, 0, 0, 0.38)',
  fontSize: '12px',
  fontStyle: 'italic',
  fontWeight: 400,
  lineHeight: '20px'
});

export const KeyboardBackspaceIconStyled = styling(ArrowLongRightIcon)(
  props => ({
    padding: '0',
    width: '20px',
    height: '20px',
    color: '#FFF'
  })
);

export const HeightSpacer = styling('div')(props => ({
  height: props.$height ? props.$height : '5px'
}));

export const StyledSubLabel = styling('label')(
  ({ isSelected, disabled, optionKey, isJobSlotMax }) => ({
    display: 'inline-flex',
    width: '100%',
    gap: '16px',
    alignItems: 'flex-start',

    padding: '12px 16px',
    borderRadius: isJobSlotMax ? '7px 7px 0 0' : '7px',
    background: `${
      disabled
        ? '#F5F5F5'
        : isSelected &&
          (optionKey == 'job_slot_max' || optionKey == 'job_slot_max_6m')
        ? '#FFF'
        : isSelected
        ? '#FFF'
        : '#FFF'
    }`,

    '& input': {
      margin: '5px 0px'
    },

    '& p': {
      color: `${disabled ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 0.87)'}`,
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      margin: '0'
    },

    '&:hover': {
      cursor: `${disabled ? 'default' : 'pointer'}`
    }
  })
);

export const KeyHighlightDisplay = styling('div')(
  ({ isSelected, disabled }) => ({
    // display: `${isSelected ? 'flex' : 'none'}`,

    // check if selected  -> check selected type -> check check keywords -> check enable keyword
    display: `flex`,
    width: '460px',
    padding: '8px 16px',
    alignItems: 'center',
    gap: '8px',

    // margin: '0px -17px -12px -17px',

    margin: `${
      disabled
        ? '-15px 0px -1px -1px'
        : isSelected
        ? '-12px 0px -2px -2px'
        : '-15px 0px -1px -1px'
    }`,

    borderRadius: '0px 0px 8px 8px',
    minHeight: '40px',

    background: '#222',
    color: '#FFF',
    padding: '8px 16px',
    fontSize: '12px',
    fontWeight: 400,
    // whiteSpace: 'nowrap',
    justifyContent: 'space-between'
  })
);

export const KeywordTokenToggle = styling('div')(props => ({
  background: '#222',
  color: '#FFF',
  fontSize: '12px',
  fontWeight: 400,
  // whiteSpace: 'nowrap',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%'
}));

export const SwitchStyled = styling(Switch)(props => ({
  width: '52px',
  height: '24px',
  padding: 0,
  borderRadius: 30,
  '& .MuiSwitch-switchBase': {
    padding: 1,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(27px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        // When is checked
        opacity: 1,
        border: 0,
        backgroundColor: '#FFF'
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: '#D8D8D8'
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
      backgroundColor: '#222'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#33cf4d',
      backgroundColor: '#FFF'
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: '#D8D8D8'
    }
  },
  '& .Mui-checked .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18,
    backgroundColor: '#222'
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18,
    backgroundColor: '#FFF'
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    opacity: 1,
    backgroundColor: '#D3D3D3',
    '&:before': {
      content: '"Yes"',
      display: 'flex',
      opacity: props.checked ? 1 : 0,
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '16px',
      transform: 'translate(8px, 4px)'
    },
    '&:after': {
      content: '"No"',
      display: 'flex',
      opacity: props.checked ? 0 : 1,
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '16px',
      transform: 'translate(30px, -12px)'
    }
  }
}));

export const BoltIconStyled = styling(BoltIcon)(props => ({
  padding: '0',
  width: '14px',
  height: '14px',
  color: props.isDisabled ? '#00000061' : '#00000099'
}));

export const ArrowPathIconStyled = styling(ArrowPathIcon)(props => ({
  padding: '0',
  width: '14px',
  height: '14px',
  color: props.isDisabled ? '#00000061' : '#00000099'
}));

export const BottomTagsSection = styling('div')(props => ({
  display: 'flex',
  paddingBottom: '4px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch'
}));

export const BottomTagsRow = styling('div')(props => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch'
}));

export const BottomTag = styling('div')(props => ({
  display: 'flex',
  padding: '2px 8px',
  alignItems: 'center',
  gap: '6px',
  borderRadius: '4px',
  border: props.isDisabled
    ? '1px solid #D8D8D8'
    : props.option == 'job_slot_max' || props.option == 'job_slot_max_6m'
    ? '1px solid #6EC197'
    : '1px solid #D8D8D8',
  background: props.isDisabled
    ? '#FAFAFA'
    : props.option == 'job_slot_max' || props.option == 'job_slot_max_6m'
    ? 'linear-gradient(90deg, rgba(110, 193, 151, 0.20) 18.57%, rgba(176, 205, 114, 0.20) 96.74%)'
    : '#EFEFFD'
}));

export const BottomTagText = styling('div')(props => ({
  color: props.isDisabled ? 'rgba(0, 0, 0, 0.60)' : 'rgba(0, 0, 0, 0.87)',
  fontSize: '10px',
  fontWeight: '400',
  lineHeight: '20px'
}));
