import { styled as styling } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

export const TitleGrid = styling(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start'
});

export const IconGrid = styling(Grid)({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '0px 30px'
});

export const TitleStyled = styling(Typography)({
  fontFamily: 'Inter',
  fontSize: '22px',
  fontWeight: '600'
});
