import { styled as styling } from '@mui/material/styles';
import {
  TableCell,
  TableRow,
  TableContainer,
  Table,
  Divider
} from '@mui/material';
import { Popover, Menu, Button, Grid } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { MoreVert } from '@mui/icons-material';
import Colors from '../../../../styles/colors.module.scss';
import { FireIcon } from '@heroicons/react/24/solid';

export const TableContainerStyled = styling(TableContainer)({
  background: 'transparent',
  boxShadow: 'none',
  overflow: 'revert'
});

export const TableStyled = styling(Table)({
  borderCollapse: 'separate',
  borderSpacing: '0px 20px',
  background: 'transparent'
});

export const TableRowStyled = styling(TableRow)(props => ({
  ...(props.row_type == 'body' && {
    boxShadow: '0px 0px 0px 0px #fff'
  }),
  ...(props.row_type == 'body-title' && {
    background: '#f1f1f4',
    boxShadow: '0px 0px 0px 0px #fff'
  })
}));

export const TableCellStyled = styling(TableCell)({
  width: '100vw',
  padding: '20px 60px',
  borderRadius: '20px',
  boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.05)',
  position: 'relative',
  background: Colors.priWhite
});

export const Text = styling('p')(props => ({
  fontFamily: 'Inter',

  ...(props.content_title == 'true' && {
    fontSize: '20px',
    fontWeight: 700,
    margin: '15px 0 10px'
  }),

  ...(props.table_content == 'true' && {
    fontSize: '14px',
    margin: '0'
  }),

  ...(props.iconText == 'true' && {
    fontSize: '14px',
    margin: 0,
    color: Colors.terDarkGrey
  }),
  ...(props.status_count == 'true' && {
    fontSize: '20px',
    fontFamily: 'Inter',
    fontWeight: 700,
    margin: 0,
    textAlign: 'center',
    color: Colors.priPurple
  }),
  ...(props.job_status == 'true' && {
    fontSize: '16px',
    margin: 0,
    fontWeight: 700,
    textAlign: 'center'
  }),
  ...(props.count_down == 'true' && {
    fontSize: '14px',
    margin: 0,
    fontWeight: 600
  }),
  ...(props.color == 'danger' && {
    color: '#f75443'
  }),

  ...(props.color == 'cautious' && {
    color: '#512acc'
  }),

  ...(props.color == 'hover-purple' && {
    '&:hover': {
      color: Colors.priPurple
    }
  }),

  ...(props.tooltip == 'true' && {
    fontSize: '12px',
    margin: 0,
    fontWeight: 600,
    padding: '5px 10px',
    borderRadius: '10px'
  }),

  ...(props.new_applicants == 'true' && {
    fontSize: '12px',
    fontWeight: 600,
    margin: 0
  }),

  ...((props.tagType == 'job_slot_max' ||
    props.tagType == 'job_slot_max_6m') && {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '20px',
    margin: 0
  }),

  ...((props.tagType == 'job_slot_plus' ||
    props.tagType == 'job_slot_plus_6m') && {
    fontSize: '12px',
    fontWeight: 700,
    margin: 0,
    lineHeight: '20px',
    background: 'linear-gradient(90deg, #6EC7CD 18.57%, #B0CD72 96.74%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    WebkitMaskImage:
      '-webkit-linear-gradient(90deg, #6EC7CD 18.57%, #B0CD72 96.74%)', // Webkit mask for Safari
    maskImage: 'linear-gradient(90deg, #6EC7CD 18.57%, #B0CD72 96.74%)'
  }),

  ...(props.tagType == 'global_hire' && {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '20px',
    margin: 0
  }),

  ...(props.disabled == true && {
    color: Colors.priBlack
  }),

  ...(props.cursor && {
    cursor: props.cursor
  })
}));

export const MenuStyled = styling(Menu)({
  '.MuiMenu-paper': {
    boxShadow: '0px 28px 23px rgb(0 0 0 / 5%)',
    borderRadius: '13px',
    background: Colors.priWhite,
    display: 'flex',
    width: 'auto'
  }
});

export const LinkIconStyled = styling(LinkIcon)({
  color: Colors.priWhite,
  fontSize: '20px',
  margin: 0
});

export const MoreVertIconStyled = styling(MoreVert)({
  fontSize: '30px',
  color: Colors.priBlack,
  cursor: 'pointer'
});

export const ViewJobIconStyled = styling('div')(props => ({
  backgroundImage: `url(${props.link})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '18px',
  width: '18px'
}));

export const Spacer = styling('div')(props => ({
  width: props.width ? props.width : '5px'
}));

export const HeightSpacer = styling('div')(props => ({
  height: props.height ? props.height : '5px'
}));

export const IconTextContainer = styling('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',
  paddingRight: '40px'
});

export const NewApplicantsContainer = styling('div')(props => ({
  padding: '6px 13px',
  background: Colors.secLightPurple,
  color: Colors.priPurple,
  borderBottomRightRadius: '20px',
  cursor: 'pointer',
  position: 'absolute',
  top: '0',
  left: '0',
  zIndex: 2
}));

export const JobStatusCard = styling('div')(props => ({
  background: 'transparent',
  padding: '15px 0',
  width: 'calc(100% / 6)',

  ...(props.disabled == false
    ? {
        '&:hover': {
          background: Colors.secLightPurple,
          borderRadius: '20px',
          margin: 'auto',
          cursor: 'pointer'
        }
      }
    : {
        cursor: 'default'
      }),

  '&:hover div': {
    background: Colors.priPurple,
    color: Colors.priWhite
  }
}));

export const JobStatusCardWrapper = styling('div')(props => ({
  background: Colors.priWhite,
  borderRadius: '20px',
  marginTop: '20px',
  boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.05)',
  display: 'flex',
  overflow: 'hidden',
  minHeight: '96px',

  alignItems: 'center',
  padding: '10px 15px',
  position: 'relative',

  '@media (max-width: 1280px)': {
    flexWrap: 'wrap'
  },

  ...(props.disabled == true && {
    color: Colors?.terLightGrey,
    background: Colors.priWhite,
    borderRadius: '20px',
    marginTop: '20px',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    overflow: 'hidden',
    minHeight: '96px',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '10px 15px'
  })
}));

export const DividerStyled = styling(Divider)({
  border: '1px solid #efefef',
  height: '50px',
  margin: '0 20px'
});

export const SocialPopover = styling(Popover)({
  '.MuiPopover-paper': {
    borderRadius: '30px',
    background: '#fff',
    boxShadow: '0px 28px 23px rgb(0 0 0 / 5%)'
  }
});

export const CustomIconContainer = styling('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50px',
  background: '#aeaeae',
  cursor: 'pointer',
  width: '32px',
  height: '32px'
});

export const SocialIconsContainer = styling('div')({
  margin: '0 10px'
});

export const ShareButtonContainer = styling('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '10px 15px'
});

export const DangerTagCotainer = styling('div')({
  background: '#f7544333',
  borderRadius: '10px',
  padding: '10px 20px'
});

export const SuperJobTagCotainer = styling('div')(props => ({
  ...((props.tagType == 'job_slot_max' ||
    props.tagType == 'job_slot_max_6m') && {
    background: 'linear-gradient(90deg, #6EC7CD 18.57%, #B0CD72 96.74%)',
    borderRadius: '8px',
    padding: '4px 10px 4px 8px',
    color: '#FFF',
    display: 'flex',
    alignItems: 'center',
    gap: '6px'
  }),

  ...((props.tagType == 'job_slot_plus' ||
    props.tagType == 'job_slot_plus_6m') && {
    borderRadius: '8px',
    padding: '4px 10px 4px 8px',
    // color: '#FFF',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',

    position: 'relative',
    border: '2px solid transparent',
    backgroundClip: 'padding-box',

    '&:after': {
      content: '""',
      position: 'absolute',
      inset: '0',
      borderRadius: '8px',
      padding: '1px',
      background: 'linear-gradient(90deg, #6EC7CD 18.57%, #B0CD72 96.74%)',
      WebkitMask:
        'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      MaskComposit: 'exclude',
      PointerEvent: 'none'
    }
  })
}));

export const GlobalHireTagCotainer = styling('div')(props => ({
  background: 'transparent',
  borderRadius: '8px',
  padding: '4px 10px 4px 8px',
  color: 'rgba(0, 0, 0, 0.60)',
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  border: '1px solid #707070'
}));

export const CautiousTagContainer = styling('div')({
  background: Colors.secLightPurple,
  borderRadius: '10px',
  padding: '10px 20px'
});

export const FireIconStyled = styling(FireIcon)({
  width: '20px',
  height: '20px',
  color: '#FFF'
});
