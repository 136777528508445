import { Skeleton } from '@mui/material';
import React from 'react';
import { Button } from '../../../../components';

const SkeletonLoader = () => (
  <div style={{ width: '100%' }}>
    <div style={{ minHeight: '400px'}}>
      <Skeleton variant="box" width={637} height={82} style={{borderRadius: '8px', background: '#E5E5E5'}} />
    </div>
  </div>
);

export default SkeletonLoader;
