import { styled as styling } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import { LockClosedIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { InformationCircleIcon as SolidInfo } from '@heroicons/react/24/outline';

export const SolidInformationIconStyled = styling(SolidInfo)(props => ({
  height: '24px',
  width: '24px'
}));

export const CardStyled = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  borderRadius: '16px',
  border: `1px solid var(--Grey-Grey, #D8D8D8)`,
  background: `var(--Primary-White, #FFF)`
  // transition: 'all 1s ease',
  // height: props?.height ? `${props?.height}px` : '500px',
  // overflow: 'hidden'
}));

export const CardTitleContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '12px 16px 16px 16px'
}));

export const DefaultContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}));

export const IconContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px'
}));


export const CardContentContainer = styling(Grid)(props => ({
  padding: props?.padding ? props?.padding : '16px'
}));

export const CardButtonContainer = styling(Grid)(props => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px 16px 16px 16px'
}));

export const CardTitleStyled = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.87)`,
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '24px',
  letterSpacing: '0.1px;',
  fontFamily: 'inter'
}));

export const DefaultTypography = styling(Typography)(props => ({}));

export const CardSubTitleStyled = styling(Typography)(props => ({
  color: `rgba(0, 0, 0, 0.87)`,
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.15px;',
  fontFamily: 'inter'
}));

export const CancelIcon = styling(XMarkIcon)(props => ({
  height: '24px',
  width: '24px'
}));

