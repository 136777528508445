import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCurrentOnboardingPage,
  updateOnboardingFormFields,
  saveCompanyWaitlist
} from '../../../../redux/actions/company_actions';
import Typography from '@mui/material/Typography';
import { Grid, Box, TextField, Dialog, Divider,} from '@mui/material';
import {
  SecondContainer,
  HeightSpacer,
  BackButton,
  NextPageButton,
  FooterContainer,
  KeyboardBackspaceIconStyled,
  TextFieldLabel,
  AnimatedContainer,
  PageContainer,
  ContentContainer,
  Title,
  Message,
  MultiForm,
  RightButton,
  SkipButton,
  DialogActionsStyled,
  DialogContentStyled,
  DialogTitleStyled,
  ButtonContinueEdit,
  ButtonSkipEdit,
  DialogButton
} from './styles';

export default function Vision(props) {
  const dispatch = useDispatch();
  const CHARACTER_LIMIT = 1500;
  const formValue = useSelector(state => state.companies.formValue);
  const [exceededCharacterLimit, setExceededCharacterLimit] = useState({});

  const [isFormFilled, setIsFormFilled] = useState(false);
  const [isSkipModalOpen, setIsSkipModalOpen] = useState(false);

  const fieldsToCheck = [
    'companyMission',
    // 'companyVision',
    'coreValues',
    'organisationalCulture',
    'currentWorkingArrangement'
  ];

  const [fields, setFields] = useState({
    companyVision: formValue.companyVision,
    companyMission: formValue.companyMission,
    coreValues: formValue.coreValues,
    organisationalCulture: formValue.organisationalCulture,
    currentWorkingArrangement: formValue.currentWorkingArrangement
  });

  const [errors, setErrors] = useState({});

  const handleChange = event => {
    const { name, value } = event.target;
    const maxLength = CHARACTER_LIMIT;

    const fieldErrors = validateField(value, maxLength, name);

    if (Object.keys(fieldErrors).length === 0) {
      const updatedFields = { ...fields, [name]: value };
      setFields(updatedFields);
      setErrors({ ...errors, [name]: '' });

      setExceededCharacterLimit({ ...exceededCharacterLimit, [name]: false });
    } else {
      setErrors({ ...errors, ...fieldErrors });
      // Prevent updating fields with invalid value
      const validValue = value.substring(0, maxLength);
      setFields({ ...fields, [name]: validValue });
      setExceededCharacterLimit({ ...exceededCharacterLimit, [name]: true });
    }

    const filledFields = fieldsToCheck.map(field => fields[field]).filter(Boolean);  
    setIsFormFilled(filledFields.length > 0);

    validateAndSetErrors();
  };

  const validate = data => {
    const newErrors = {};
    const fieldsToValidate = [
      'companyMission',
      // 'companyVision',
      'coreValues',
      'organisationalCulture',
      'currentWorkingArrangement'
    ];

    fieldsToValidate.forEach(field => {
      const value = data[field];
      const maxLength = 1500;
      const fieldErrors = validateField(value, maxLength, field);
      Object.assign(newErrors, fieldErrors);
    });

    return newErrors;
  };

  const validateField = (value, maxLength, fieldName) => {
    const errors = {};
  
    const displayName = convertToDisplayName(fieldName);
  
    if (!value) {
      errors[fieldName] = `* Required.`;
    } else if (value.length > maxLength) {
      errors[fieldName] = `Text cannot exceed ${maxLength} characters.`;
      setExceededCharacterLimit({
        ...exceededCharacterLimit,
        [fieldName]: true
      });
    }
  
    return errors;
  };

  const handleSkip = () => {
    const filledFields = fieldsToCheck.map(field => fields[field]).filter(Boolean);  
    setIsFormFilled(filledFields.length > 0);
    setIsSkipModalOpen(true);
  };

  const handleSkipModalClose = () => {
    setIsSkipModalOpen(false);
  };

  const handleSkipSubmit = () => {
    setIsSkipModalOpen(false);
    handleNextPageSkip();
  };

  const handleBlur = name => {
    if (fields[name] && fields[name].length > 0) {
      setExceededCharacterLimit({ ...exceededCharacterLimit, [name]: false });
      setErrors({ ...errors, [name]: '' });
    }

    const filledFields = fieldsToCheck.map(field => fields[field]).filter(Boolean);  
      setIsFormFilled(filledFields.length > 0);
      validateAndSetErrors();
  };

  const validateAndSetErrors = () => {
    const newErrors = validate(fields);
    setErrors(newErrors);
  };

  const convertToDisplayName = fieldName => {
    return fieldName
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, str => str.toUpperCase());
  };

  const handlePreviousPage = () => {
    dispatch(
      updateOnboardingFormFields({
        name: formValue.name,
        email: formValue.email,
        companyName: formValue.companyName,
        inviteCode: formValue.inviteCode,
        phoneNumber: formValue.phoneNumber,
        // companyVision: fields.companyVision,
        companyMission: fields.companyMission,
        coreValues: fields.coreValues,
        organisationalCulture: fields.organisationalCulture,
        currentWorkingArrangement: fields.currentWorkingArrangement
      })
    );

    dispatch(updateCurrentOnboardingPage('register'));
  };

  function handleNextPage(event) {
    if (event) {
      event.preventDefault();
    }
    const newErrors = validate(fields);

    const filledFields = fieldsToCheck.map(field => fields[field]).filter(Boolean);  

    let trackingParameters = document.cookie
    .split('; ')
    .find(row => row.startsWith('params='))
    ?.toString()
    .replace('params=', '');

    if(filledFields.length > 0){
      if (Object.keys(newErrors).length === 0) {
        const params = {
          name: formValue.name,
          email: formValue.email,
          companyName: formValue.companyName,
          inviteCode: formValue.inviteCode,
          phoneNumber: formValue.phoneNumber,
          companyVision: '',
          companyMission: fields.companyMission,
          coreValues: fields.coreValues,
          organisationalCulture: fields.organisationalCulture,
          currentWorkingArrangement: fields.currentWorkingArrangement,
          trackingParameters: trackingParameters,
        };

        dispatch(updateOnboardingFormFields(params));
  
        dispatch(saveCompanyWaitlist(params)).then(response => {
          if (response?.type === 'SAVE_COMPANY_WAITLIST_SUCCEEDED') {
            dispatch(updateCurrentOnboardingPage('complete'));
            document.cookie = `params=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`; // Delete cookie for params
          }
        });
      } else {
        setErrors(newErrors);
      }
    }else{
      handleSkip();
    }
  }

  function handleNextPageSkip(event) {
    // event.preventDefault();

    let trackingParameters = document.cookie
    .split('; ')
    .find(row => row.startsWith('params='))
    ?.toString()
    .replace('params=', '');

      const params = {
        name: formValue.name,
        email: formValue.email,
        companyName: formValue.companyName,
        inviteCode: formValue.inviteCode,
        phoneNumber: formValue.phoneNumber,
        companyVision: '',
        companyMission: '',
        coreValues: '',
        organisationalCulture: '',
        currentWorkingArrangement: '',
        trackingParameters: trackingParameters,
      };

      dispatch(updateOnboardingFormFields(params));

      dispatch(saveCompanyWaitlist(params)).then(response => {
        if (response?.type === 'SAVE_COMPANY_WAITLIST_SUCCEEDED') {
          dispatch(updateCurrentOnboardingPage('complete'));
          document.cookie = `params=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`; // Delete cookie for params
        }
      });
  }

  const renderTextField = (name, label, placeholder, type = 'text') => (
    <div>
      <TextFieldLabel
        sx={{
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.1px',
          padding: '0px 8px'
        }}>
        {label}
      </TextFieldLabel>
      <HeightSpacer height={'4px'} />
      <TextField
        name={name}
        hiddenLabel
        required
        value={fields[name]}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={() => handleBlur(name)}
        error={!!errors[name]}
        helperText={
          <Typography
            variant="caption"
            sx={{
              textAlign: 'right',
              position: 'absolute',
              bottom: '8px',
              right: '16px',
              background: 'white',
              zIndex: '0',
              marginTop: '10px',
              color: exceededCharacterLimit[name]
                ? '#d32f2f'
                : 'rgba(0, 0, 0, 0.87)'
            }}>
            {`${fields[name].length}/${CHARACTER_LIMIT}`}
          </Typography>
        }
        type={type}
        multiline
        rows={8}
        sx={{
          width: '100%',
          '.MuiInputBase-root': {
            borderRadius: '8px',
            padding: '8px 16px 38px 16px'
          },
          'fieldset.MuiOutlinedInput-notchedOutline': {
            border: '2px solid rgba(174, 174, 174, 1)'
          },
          '.MuiInputBase-input': {
            padding: '0',
            scrollbarWidth: 'thin',
            '-ms-overflow-style': 'none',
            '&::-webkit-scrollbar': {
              width: '0em'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'transparent'
            }
          }
        }}
      />

      {errors[name] && (
        <Typography
          variant="caption"
          color="error"
          sx={{
            textAlign: 'left',
            marginTop: '5px'
          }}>
          {errors[name]}
        </Typography>
      )}
    </div>
  );

  useEffect(() => {
    setFields({
      ...formValue
    });

    const filledFields = fieldsToCheck.map(field => fields[field]).filter(Boolean); 
    setIsFormFilled(filledFields.length > 0);
  }, []);

  return (
    <PageContainer>
      <AnimatedContainer>
        <ContentContainer>
          <Title>Share what sets your company apart!</Title>
          <Message>
          Increase your chances of being selected for this exclusive Hiredly launch by 50% when you let us in on what makes your company unique.
          </Message>
        </ContentContainer>
      </AnimatedContainer>
      <SecondContainer>
        <Grid>
          <MultiForm noValidate autoComplete="off" overflow={'scroll'}>
            {renderTextField(
              'companyMission',
              'What is your company’s mission and vision?',
              "Share the driving force behind your company. It's time to let your mission shine!"
            )}
            {/* {renderTextField(
              'companyVision',
              'Company Vision',
              'What is your company’s vision?'
            )} */}
            {renderTextField(
              'coreValues',
              'What are your company core values?',
              'This enables us to find the right jobseeker who will represent your company accurately.'
            )}
            {renderTextField(
              'organisationalCulture',
              'What makes your company unique?',
              'To attract top talent, consider how your company provides an environment that supports, challenges and encourages high performers.'
            )}
            {renderTextField(
              'currentWorkingArrangement',
              "What's the working arrangement?",
              'Tell jobseekers what they can expect. Fun fact: 94% of jobseekers look for flexible working arrangements.'
            )}

            <FooterContainer>
              <BackButton onClick={handlePreviousPage}>
                <KeyboardBackspaceIconStyled />
              </BackButton>

              <RightButton>
                <SkipButton disabled={isFormFilled} onClick={handleSkip}>
                  Skip
                </SkipButton>
                <NextPageButton type="submit" onClick={handleNextPage}>
                  Done
                </NextPageButton>
              </RightButton>
            </FooterContainer>
          </MultiForm>
        </Grid>
      </SecondContainer>


       {/* Skip Modal */}
       <Dialog open={isSkipModalOpen} onClose={handleSkipModalClose} sx={{ maxWidth: '500px', margin: 'auto' }}>
          <DialogTitleStyled>Continue without sharing?</DialogTitleStyled>
           <Divider />

          <DialogContentStyled>
            Completing the form and sharing detailed insights into your company boosts your chances of being selected.
          </DialogContentStyled>

          <DialogActionsStyled>
            <DialogButton>
              <ButtonContinueEdit onClick={handleSkipModalClose}>
                  Continue Editing
                </ButtonContinueEdit>
                <ButtonSkipEdit onClick={handleSkipSubmit}>
                  Skip
                </ButtonSkipEdit>
            </DialogButton>
            
          </DialogActionsStyled>
      </Dialog>
    </PageContainer>
  );
}
