export const tawkTo = (propertyId, key, email, name, companyName) => {
  if (!window) {
    throw new Error('DOM is unavailable');
  }

  window.Tawk_API = window.Tawk_API || {};
  window.Tawk_LoadStart = new Date();

  window.Tawk_API.onLoad = () => {
    window.Tawk_API.setAttributes({
      email,
      name: `${companyName} - ${name}`
    });
  };

  const tawk = document.getElementById('tawkId');
  if (tawk) {
    return window.Tawk_API;
  }

  if (!key) {
    throw new Error(
      'Key not provided. Get key from tawk dashboard - Direct Chat Link'
    );
  }

  const script = document.createElement('script');
  script.id = 'tawkId';
  script.async = true;
  script.src = `https://embed.tawk.to/${propertyId}/${key}`;
  script.charset = 'UTF-8';
  script.setAttribute('crossorigin', '*');

  const firstScriptTag = document.getElementsByTagName('script')[0];
  if (!firstScriptTag || !firstScriptTag.parentNode) {
    throw new Error('DOM is unavailable');
  }

  firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
};
