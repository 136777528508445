// import React, { useEffect } from 'react';
// import { useMutation } from '@apollo/client';
// import { Button, PopUpDialog, Spinner } from '../../../../components';
// import RefreshIcon from '../../../../assets/images/jobs/refresh-job-icon.png';
// import styles from './index.module.scss';
// import { ErrorToast, SuccessToast } from '../../../../utils/ToastUtils';
// import { useNavigate } from 'react-router-dom';
// import { REFRESH_JOB } from '../../../../apollo/mutations';
// import { GET_ACCOUNT_STATUS, GET_JOBS } from '../../../../apollo/query';
// import {
//   refreshJob,
//   updateAllJobs,
//   updateRefresherTokensCount
// } from '../../../../redux/actions/job_action';
// import { useDispatch, useSelector } from 'react-redux';
// import store from '../../../../redux/stores/store';

// const RefreshJobPopUp = ({
//   open,
//   handleClose,
//   jobId,
//   jobTitle,
//   setIsDisableRefresh,
//   getCompanyAccountInfo,
//   // replaceJob,
//   referesherTokensCount
// }) => {
//   // const [refreshJob, { loading }] = useMutation(REFRESH_JOB);
//   const history = useNavigate();
//   const dispatch = useDispatch();

//   const loading = useSelector(state => state.jobs.refreshingJob);
//   const allJobs = store.getState().jobs.jobs;
//   const refresherTokensUsableCount = useSelector(
//     state => state.jobs.subscribedRefresherTokensPackage?.total_usable_quantity
//   );

//   const onRefreshJob = async () => {
//     try {
//       const params = {
//         jobId: jobId
//       };
//       dispatch(refreshJob(params)).then(res => {
//         const currentJobIndex = allJobs.findIndex(item => {
//           return item?.id == jobId;
//         });

//         if (res.type == 'REFRESH_JOB_SUCCEED') {
//           if (allJobs[currentJobIndex]) {
//             allJobs[currentJobIndex] = res.job;
//           }

//           dispatch(updateRefresherTokensCount(refresherTokensUsableCount - 1));

//           dispatch(updateAllJobs(allJobs));
//           SuccessToast('Refresh Job Successful!');
//         } else {
//           ErrorToast('Refresh Job Failed!');
//         }
//         handleClose();
//       });

//       // const res = await refreshJob({
//       //   variables: {
//       //     input: {
//       //       jobId
//       //     }
//       //   }
//       // });

//       // if (replaceJob) {
//       //   replaceJob(latestJob);
//       // }

//       // if (getCompanyAccountInfo) {
//       //   getCompanyAccountInfo();
//       // }

//       // setIsDisableRefresh(true);
//     } catch (err) {
//       ErrorToast(err.message);
//     }
//   };

//   const onPurchaseButtonClicked = () => {
//     history(`/purchase/choose?product=refresher_token`);
//   };

//   const getTitle = () => {
//     if (referesherTokensCount) {
//       return `Refresh ${jobTitle}?`;
//     }

//     return 'No Refresher Tokens Available';
//   };

//   const renderNoRefreshTokenComponent = () => {
//     return (
//       <span className={styles.noRefresherDescription}>
//         {`Refine your recruitment journey by purchasing Refresher Tokens!`}
//       </span>
//     );
//   };

//   const renderPurchaseButton = () => {
//     return (
//       <div className={styles.purchaseButtonContainer}>
//         <Button color="PURPLE" size="AUTO" onClick={onPurchaseButtonClicked}>
//           Purchase Refresher Tokens
//         </Button>
//       </div>
//     );
//   };

//   return (
//     <PopUpDialog open={open} handleClose={handleClose} title={getTitle()}>
//       <div style={{ padding: '20px 20px' }}>
//         <div className={styles.contentContainer}>
//           {referesherTokensCount && (
//             <img
//               title="Refresh Icon"
//               alt="Refresh Icon"
//               src={RefreshIcon}
//               style={{ height: 60, width: 60 }}
//             />
//           )}

//           {referesherTokensCount ? (
//             <p className={styles.featureJobDescription}>
//               {` By refreshing this job, one Refresher Token will be deducted from
//             your account.`}
//               <span>
//                 <br />
//                 You have
//               </span>
//               <span
//                 style={{
//                   fontWeight: 'bold'
//                 }}>{` (${referesherTokensCount} Refresher Tokens)`}</span>
//               <span> left.</span>
//             </p>
//           ) : (
//             renderNoRefreshTokenComponent()
//           )}
//         </div>
//         {loading ? (
//           <div style={{ height: 95 }}>
//             <Spinner />
//           </div>
//         ) : referesherTokensCount ? (
//           <div className={styles.buttonContainer}>
//             <Button color="TRANSPARENT" size="BIG" onClick={handleClose}>
//               Cancel
//             </Button>
//             <Button color="PURPLE" size="BIG" onClick={onRefreshJob}>
//               Yes
//             </Button>
//           </div>
//         ) : (
//           renderPurchaseButton()
//         )}
//       </div>
//     </PopUpDialog>
//   );
// };

// export default RefreshJobPopUp;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HeightSpacer } from '../../../../components/Spacer/styles';
import {
  refreshJob,
  updateAllJobs,
  updateRefresherJobPopup,
  updateRefresherTokensCount
} from '../../../../redux/actions/job_action';
import { ErrorToast, SuccessToast } from '../../../../utils/ToastUtils';
import TokensPopup from '../../SharedPopups/TokensPopup';
import store from '../../../../redux/stores/store';
import {
  ContentDescription,
  ContentInfo,
  ExplainerContainer,
  ExplainerInfo,
  ExplainerInfoWrapper,
  ExplainerNote,
  ExplainerTitle,
  GifContainer
} from './styles';
import RefresherTokenVisualExplainer from '../../../../assets/images/jobs/refresher_token_visual_explainer.gif';
import { mutateCart } from '../../../../redux/actions/order_action';

const RefreshJobConfirmationPopup = ({ jobTitle, jobId }) => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const isRefreshJobOpen = useSelector(state => state.jobs.isRefreshJobOpen);
  const loading = useSelector(state => state.jobs.refreshingJob);
  const allJobs = store.getState().jobs.jobs;
  const refresherTokensUsableCount = useSelector(
    state => state.jobs.subscribedRefresherTokensPackage?.total_usable_quantity
  );

  const getPopupTitle = count => {
    if (count > 0) {
      return `Refresh ${jobTitle}?`;
    } else {
      return `No Refresher Token Available`;
    }
  };

  const getContentMessage = count => {
    if (count > 0) {
      return 'By refreshing this job, one Refresher Token will be deducted from your account.';
    } else {
      return 'Refine your recruitment journey by purchasing Refresher Tokens!';
    }
  };

  const getRefresherTokenCount = count => {
    if (count > 0) {
      return (
        <span>
          You have {''}
          <b>{refresherTokensUsableCount} Refresher Tokens</b> {''} left.
        </span>
      );
    } else {
      return ``;
    }
  };

  const onClosePopup = count => {
    try {
      if (count > 0) {
        window.dataLayer.push({
          event: 'CE_use-popup-refresh-cancel'
        });
      } else if (count == 0) {
        window.dataLayer.push({
          event: 'CE_purchase-popup-refresh-cancel'
        });
      }
    } catch {}

    dispatch(updateRefresherJobPopup(false));
  };

  const onSubmitFeaturedToken = async (count, jobId) => {
    try {
      if (count > 0) {
        onRefreshJob(jobId, count);

        window.dataLayer.push({
          event: 'CE_use-popup-refresh-confirm'
        });
      } else {
        await dispatch(
          mutateCart({
            input: {
              productId: '174',
              quantity: 1
            }
          })
        );
        history('/purchase/choose');

        window.dataLayer.push({
          event: 'CE_purchase-popup-refresh-confirm'
        });
      }
    } catch {}
  };

  const onRefreshJob = async jobId => {
    try {
      const params = {
        jobId: jobId
      };
      dispatch(refreshJob(params)).then(res => {
        const currentJobIndex = allJobs.findIndex(item => {
          return item?.id == jobId;
        });

        if (res.type == 'REFRESH_JOB_SUCCEED') {
          if (allJobs[currentJobIndex]) {
            allJobs[currentJobIndex] = res.job;
          }

          dispatch(updateRefresherTokensCount(refresherTokensUsableCount - 1));

          dispatch(updateAllJobs(allJobs));
          SuccessToast('Refresh Job Successful!');
        } else {
          ErrorToast('Refresh Job Failed!');
        }
        onClosePopup();
      });
    } catch (err) {
      ErrorToast(err.message);
    }
  };

  return (
    <TokensPopup
      title={getPopupTitle(refresherTokensUsableCount)}
      firstButtonText={'Cancel'}
      secondButtonText={
        refresherTokensUsableCount > 0 ? 'Confirm' : 'Purchase Now'
      }
      open={isRefreshJobOpen}
      onClose={onClosePopup}
      onClickFirstButton={() => onClosePopup(refresherTokensUsableCount)}
      onClickSecondButton={() =>
        onSubmitFeaturedToken(refresherTokensUsableCount, jobId)
      }
      loadingSecondButton={loading}>
      <>
        <ContentInfo>
          <GifContainer>
            <img
              alt="refresher-token-visual-explainer"
              style={{ width: '100%', maxHeight: '231px' }}
              src={RefresherTokenVisualExplainer}
            />
          </GifContainer>
          <ExplainerContainer>
            <ExplainerTitle>How can Refresher Tokens help you?</ExplainerTitle>
            <ExplainerInfoWrapper>
              <ExplainerInfo>
                Applying a Refresher Token to a job instantly bumps your job to
                the top of the jobs list!
              </ExplainerInfo>
              <ExplainerInfo margin_top="true">
                Employers using Refresher Tokens get 1.4x more job views* on
                average.
              </ExplainerInfo>
            </ExplainerInfoWrapper>
            <ExplainerNote>
              *Actual job views for your role may vary based on different
              factors such as industry and experience level.
            </ExplainerNote>
          </ExplainerContainer>
        </ContentInfo>
        <HeightSpacer height={'24px'} />
        <ContentDescription>
          {getContentMessage(refresherTokensUsableCount)}
        </ContentDescription>
        {refresherTokensUsableCount > 0 ? (
          <HeightSpacer height={'24px'} />
        ) : null}
        <ContentDescription>
          {getRefresherTokenCount(refresherTokensUsableCount)}
        </ContentDescription>
      </>
    </TokensPopup>
  );
};

export default RefreshJobConfirmationPopup;
