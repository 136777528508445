import { Grid, Skeleton } from '@mui/material';
import React from 'react';
import {
  ArrowTopRightIconStyled,
  DisclaimerText,
  SidebarTitle,
  ViewCompanyProfile
} from './components/Sidebar/styles';

export const SidebarSkeleton = () => {
  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        width: '280px'
      }}>
      <SidebarTitle>Your Company Canvas</SidebarTitle>
      <Skeleton
        variant="rounded"
        width={280}
        height={122}
        sx={{ borderRadius: '10px' }}
      />
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          height: '155px',
          justifyContent: 'flex-start',
          alignItems: 'flex-start'
        }}>
        <Skeleton
          variant="rounded"
          width={188}
          height={16}
          sx={{ borderRadius: '100px' }}
        />
        <Skeleton
          variant="rounded"
          width={260}
          height={16}
          sx={{ borderRadius: '100px' }}
        />
        <Skeleton
          variant="rounded"
          width={155}
          height={16}
          sx={{ borderRadius: '100px' }}
        />
        <Skeleton
          variant="rounded"
          width={94}
          height={16}
          sx={{ borderRadius: '100px' }}
        />
      </Grid>
      <ViewCompanyProfile disabled={true}>
        View Live Company Profile
        <ArrowTopRightIconStyled />
      </ViewCompanyProfile>

      <DisclaimerText>
        Disclaimer: the company profile will be updated within one hour of the
        latest modifications.
      </DisclaimerText>
    </Grid>
  );
};

export const CoverImageSkeleton = () => {
  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        width: '100%'
      }}>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          width: '280px'
        }}>
        <Skeleton
          variant="rounded"
          width={188}
          height={19}
          sx={{ borderRadius: '100px' }}
        />
        <Skeleton
          variant="rounded"
          width={599}
          height={19}
          sx={{ borderRadius: '100px' }}
        />
      </Grid>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '40px',
          padding: '16px',
          backgroundColor: '#EFEFEF',
          width: '920px',
          borderRadius: '16px'
        }}>
        <Skeleton
          variant="rounded"
          width={309}
          height={206}
          sx={{ borderRadius: '8px' }}
        />
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            marginTop: '24px'
          }}>
          <Skeleton
            variant="rounded"
            width={188}
            height={19}
            sx={{ borderRadius: '100px' }}
          />
          <Skeleton
            variant="rounded"
            width={362}
            height={19}
            sx={{ borderRadius: '100px' }}
          />
          <Skeleton
            variant="rounded"
            width={300}
            height={19}
            sx={{ borderRadius: '100px' }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const CompanyStorySkeleton = () => {
  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        padding: '16px',
        backgroundColor: '#EFEFEF',
        width: '920px',
        borderRadius: '16px',
        height: '500px'
      }}>
      <Skeleton
        variant="rounded"
        width={188}
        height={19}
        sx={{ borderRadius: '100px' }}
      />
      <Skeleton
        variant="rounded"
        width={362}
        height={19}
        sx={{ borderRadius: '100px' }}
      />
    </Grid>
  );
};
