import { styled as styling } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Divider,
  Grid,
  DialogContent,
  Typography,
  DialogActions,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const Wrapper = styling(Grid)({});

export const TitleStyled = styling(Typography)({
  fontWeight: 'bold',
  fontSize: '20px',
  fontFamily: 'Inter'
});

export const ContentStyled = styling(Typography)({
  fontSize: '16px',
  fontFamily: 'Inter'
});

export const ContentBoldStyled = styling(`span`)({
  fontWeight: 'bold',
  fontSize: '16px',
  fontFamily: 'Inter'
});

export const DialogStyled = styling(Dialog)(props => ({
  // backgroundColor: `rgb(242, 242, 242, 0.5)`,

  '& .MuiDialog-paper': {
    width: '464px',
    height: props.height
  }
}));

export const DialogContentStyled = styling(DialogContent)({
  '&.MuiDialogContent-root': {
    padding: '16px 24px 24px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative'
  }
});

export const DialogActionStyled = styling(DialogActions)({});

export const DialogTitleStyled = styling(DialogTitle)({
  '&.MuiDialogTitle-root': {
    padding: '0px'
  }
});

export const IconButtonStyled = styling(IconButton)({});

export const DividerStyled = styling(Divider)({});

export const CloseIconStyled = styling(CloseIcon)({});

export const FormControlLabelStyled = styling(FormControlLabel)({
  marginTop: '12px'
});

export const CheckboxStyled = styling(Checkbox)({
  '&.Mui-checked': {
    color: 'black'
  },

  '& .MuiSvgIcon-root': {
    borderRadius: '4px',
    width: '24px',
    height: '24px'
  }

  // borderRadius: "4px",
  // width: "24px",
  // height: "24px"
});

export const ButtonStyled = styling(Button)(props => ({
  fontWeight: '700',
  width: '200px',
  borderRadius: '30px',
  border: '2px solid #512acc',
  textTransform: 'none',
  fontSize: props.smallText ? '14px' : '16px'
}));

export const BannerText = styling('span')(props => ({
  fontFamily: 'Inter',
  fontWeight: '400',
  fontSize: '12px'
}));
