import { XMarkIcon } from '@heroicons/react/24/solid';
import { Grid } from '@mui/material';
import React from 'react';

const SharedNotice = ({
  dividerColor,
  contentBackground,
  children,
  hasCloseIcon,
  onClose
}) => {
  return (
    <div
      style={{ position: 'relative', overflow: 'hidden', borderRadius: '4px' }}>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          background: dividerColor,
          width: '4px',
          height: '100%'
        }}
      />
      <Grid
        container
        padding={'8px 16px'}
        gap={'10px'}
        alignItems={'center'}
        sx={{
          background: contentBackground
        }}>
        {children}
      </Grid>

      {hasCloseIcon && (
        <div
          style={{ position: 'absolute', top: '10px', right: '10px' }}
          onClick={onClose}>
          <XMarkIcon style={{ height: '16px', width: '16px' }} />{' '}
        </div>
      )}
    </div>
  );
};

export default SharedNotice;
