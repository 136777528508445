import { useState } from 'react';
import { useFormContext } from '../context';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDraftJob,
  mutateCreateJob,
  updateJobDataForm
} from '../../../../redux/actions/job_action';
import { ErrorToast, SuccessToast } from '../../../../utils/ToastUtils';
import { formatToDefaultFormData } from '../helper/formatData';
import { checkError } from '../helper/checkError';
import * as yup from 'yup';
import { validationSchema } from '../helper/validation';

export default function useDefaultLogic(props) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const jobDataForm = useSelector(state => state?.jobs?.jobDataForm);
  const myCompanyStatus = useSelector(
    state => state?.companies?.myCompanyStatus
  );

  const availableSeniorToken =
    myCompanyStatus?.availableFreeSuperSeniorPostCount;
  const availableInternToken = myCompanyStatus?.availableInternshipsTokenCount;

  const [loading, setLoading] = useState(false);
  const [openTokenPopUp, setOpenTokenPopUp] = useState(false);

  if (props?.confirm) {
    const onSaveJobConfirm = async () => {
      setLoading(true);

      // Edge cases
      // 1. if user save job as draft, before posting it, always remove the draft job id

      const data = formatToDefaultFormData(
        jobDataForm,
        props?.jobId === 'draft-job'
      );

      try {
        const res = await dispatch(mutateCreateJob({ input: data }));
        if (res?.type === 'MUTATE_CREATE_JOB_SUCCEED') {
          setLoading(false);

          history(`/jobs/${res?.createJob?.id}/job-visibility`);
          SuccessToast('Job created!');
          dispatch(updateJobDataForm());
        } else {
          ErrorToast(res?.error);
        }
      } catch (error) {
        ErrorToast('An error occurred while creating the job.');
      } finally {
        setLoading(false);
      }
    };

    return { onSaveJobConfirm, loading };
  }

  const { formState, showIntern, showSenior, handleErrorState } =
    useFormContext();

  const buttonText = 'Next';

  let jobTitle;
  if (showIntern) {
    jobTitle = 'intern';
  } else if (showSenior) {
    jobTitle = 'senior';
  }

  const requiredFields = [
    'location',
    'stateRegion',
    'title',
    'description',
    'requirements',
    'careerLevel',
    'skills',
    'jobType',
    'tracks',
    'customEmail'
    // 'minSalaryRange',
    // 'maxSalaryRange'
  ];

  const saveJob = async () => {
    try {
      await validationSchema.validate(formState, { abortEarly: false });
      const error = checkError(formState, requiredFields);

      if (error?.length > 0) {
        handleErrorState(error);
        return;
      }
      dispatch(updateJobDataForm(formState));
      if (
        (showIntern && parseInt(availableInternToken) > 0) ||
        (showSenior && parseInt(availableSeniorToken) > 0)
      ) {
        setOpenTokenPopUp(true);
        return;
      }

      history(`/jobs/new-job/job-type`);
    } catch (err) {
      let errors;
      err.inner.forEach(error => {
        errors = error.message;
      });

      ErrorToast(errors);
    }
  };

  return { buttonText, saveJob, openTokenPopUp, setOpenTokenPopUp, jobTitle };
}
