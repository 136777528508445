import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PopUpDialog } from '../../../../../components';
import { MUIButtonStyled } from '../../../../../components/MUIButton';
import {
  fetchCompanyAffiliations,
  mutateDeleteCompanyAffiliation
} from '../../../../../redux/actions/company_actions';
import { ErrorToast, SuccessToast } from '../../../../../utils/ToastUtils';
import styles from './index.module.scss';

const DeleteAffiliatePopUp = ({ open, handleClose, id, name }) => {
  const dispatch = useDispatch();
  const mutatingCompanyAffiliate = useSelector(
    state => state.companies.mutatingCompanyAffiliate
  );

  const onDeleteAffiliation = async () => {
    dispatch(
      mutateDeleteCompanyAffiliation({
        input: { id }
      })
    )
      .then(async res => {
        if (res.type === 'MUTATE_DELETE_COMPANY_AFFILIATION_SUCCEED') {
          SuccessToast(`Affiliated Company  Removed Successfully!`);
          await dispatch(fetchCompanyAffiliations());
          handleClose();
        }
      })
      .catch(err => {
        ErrorToast(err.message);
      });
  };

  return (
    <PopUpDialog
      open={open}
      handleClose={handleClose}
      title={`Delete ${name}?`}>
      <div style={{ padding: '20px 20px' }}>
        <div className={styles.contentContainer}>
          <p className={styles.deleteMemberDescription}>
            {`Are you sure you want to remove ${name} as an affiliated company?`}
          </p>
        </div>

        <div className={styles.buttonContainer}>
          <MUIButtonStyled
            large
            outlinedBlack
            label="Cancel"
            onClick={handleClose}
          />
          <MUIButtonStyled
            large
            label="Delete"
            disabled={mutatingCompanyAffiliate}
            onClick={onDeleteAffiliation}
          />
        </div>
      </div>
    </PopUpDialog>
  );
};

export default DeleteAffiliatePopUp;
