import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DefaultLayout from '../../../../layouts/DefaultLayout';
import VirtualInterviewPopUpNew from '../../PopUps/VirtualInterviewPopUpNew';
import { SuccessToast } from '../../../../utils/ToastUtils';
import {
  RightBox,
  RightBoxInner,
  Title,
  TitleBox,
  Subtitle,
  Container,
  StepsContainer,
  Steps,
  StepsText,
  PurpleLineActive,
  PurpleLine
} from './styles';
import { useSelector } from 'react-redux';

const VirtualInterviewQuestionsNew = () => {
  const params = useParams();

  const isMasterAccount =
    localStorage.getItem('currentAccountType') === 'master';
  const teamMembers = useSelector(state => state.companies.companyTeamMembers);
  const myCompanyStatus = useSelector(
    state => state?.companies?.myCompanyStatus
  );

  const isMasterOrHaveCtm = isMasterAccount || myCompanyStatus?.ctmAccess;

  useEffect(() => {
    try {
      const showSavedJobMessage = localStorage.getItem('showSavedJobMessage');
      localStorage.removeItem('showSavedJobMessage');

      if (showSavedJobMessage) {
        SuccessToast(showSavedJobMessage);
      }
    } catch (error) {
      //
    }
  }, []);

  return (
    <DefaultLayout maxWidth={true}>
      <Container>
        <StepsContainer>
          <PurpleLine />
          <PurpleLineActive />

          <Steps>
            <StepsText>Job posted successfully 🎉</StepsText>
          </Steps>

          <Steps>
            <StepsText>Maximise job visibility</StepsText>
          </Steps>

          <Steps>
            <StepsText active>Set up screening questions</StepsText>
          </Steps>

          {isMasterAccount && teamMembers?.length > 0 && (
            <Steps>
              <StepsText disabled>Assign job access</StepsText>
            </Steps>
          )}
        </StepsContainer>

        <RightBox>
          <RightBoxInner>
            <TitleBox>
              <Title>Set up screening questions</Title>
              <Subtitle>
                Enabling Virtual Interview allows you to collect more
                information to help with your shortlisting decision.
              </Subtitle>
            </TitleBox>

            <VirtualInterviewPopUpNew
              useAsPopUp={false}
              jobId={params?.id}
              jobAccess={isMasterAccount && teamMembers?.length > 0}
              open
            />
            
          </RightBoxInner>
        </RightBox>
      </Container>
    </DefaultLayout>
  );
};

export default VirtualInterviewQuestionsNew;
