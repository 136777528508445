import { styled as styling } from '@mui/material/styles';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import Button from '@mui/material/Button';

export const MainContainer = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  gap: '24px',
  minHeight: '540px'
}));

export const MainContainerBox = styling('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: '8px'
  // border: '1px solid #D8D8D8'
}));

export const BottomBox = styling('div')(props => ({
  display: 'flex',
  padding: '16px 0px',
  justifyContent: 'flex-end',
  alignItems: 'center',
  alignSelf: 'stretch'
}));

export const BottomActionBox = styling('div')(props => ({
  display: 'flex',
  width: '328px',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '8px'
}));

export const SkipButton = styling('div')(props => ({
  display: 'flex',
  width: '120px',
  height: '42px',
  padding: '0px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: 'normal',
  cursor: 'pointer'
}));

export const SaveButton = styling(Button)(props => ({
  display: 'flex',
  width: '200px',
  height: '42px',
  padding: '0px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  background: props.disabled ? '#D8D8D8' : '#353535',

  gap: '10px',
  color: '#FFF',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: 'normal',
  cursor: 'pointer',
  textTransform: 'none',

  ':disabled': {
    cursor: 'not-allowed',
    color: '#FFF',
    ':hover': {
      background: '#D8D8D8'
    },
  },

  ':hover': {
    background: '#222222',
  }
}));

export const ChevronDownIconStyled = styling(ChevronDownIcon)(props => ({
  width: '20px',
  height: '20px',
  transition: 'transform 0.3s ease',
  transform: props.isExpanded ? 'rotate(180deg)' : 'none'
}));

export const ExpandedContent = styling('div')(props => ({
  maxHeight: props.isExpanded ? '1000px' : '0',
  overflow: 'hidden',
  transition: 'max-height 0.8s ease',
  maxWidth: '671px'
}));

export const FormSelectContainer = styling('div')(props => ({
  padding: '0px',
  width: '100%'
}));

export const NoteSection = styling('div')(props => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '10px',
  padding: '16px 0px 0px 16px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '12px',
  fontStyle: 'italic',
  fontWeight: 400,
  lineHeight: '20px',

  ...(props.isOverlay && {
    position: 'relative',
    zIndex: 2000,
    background: '#F5F5F5',
    borderRadius: '0px 0px 8px 8px',
    padding: '16px'
  })
}));

export const DialogTitleStyled = styling(DialogTitle)(props => ({
  padding: '16px 16px 16px 24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.1px'
}));

export const DialogContentStyled = styling(DialogContent)(props => ({
  padding: '16px 24px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  maxWidth: '464px'
}));

export const DialogNoteStyled = styling(DialogContent)(props => ({
  padding: '0px',
  color: 'rgba(0, 0, 0, 0.60)',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}));

export const DialogActionsStyled = styling(DialogTitle)(props => ({
  padding: '0px'
}));

export const DialogButton = styling('div')(props => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '16px 0',
  gap: '16px',
  alignSelf: 'stretch',
  width: '100%'
}));

export const ButtonContinueEdit = styling(Button)(props => ({
  width: '200px',
  height: '36px',
  backgroundColor: '#FFF',
  borderRadius: '8px',
  // border: '2px solid #222',
  color: '#222',
  fontSize: '16px',
  textTransform: 'none',
  fontWeight: '700',
  padding: '0 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',

  ':hover': {
    backgroundColor: '#FFF'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '130px',
    height: '30px',
    fontSize: '12px',
    padding: '0 8px'
  }
}));

export const ButtonSkipEdit = styling(Button)(props => ({
  width: '200px',
  height: '36px',
  backgroundColor: '#2E2E2E',
  borderRadius: '8px',
  color: '#FFF',
  fontSize: '16px',
  textTransform: 'none',
  fontWeight: '700',
  padding: '0 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',

  ':hover': {
    backgroundColor: '#2E2E2E'
  },

  [props.theme.breakpoints.down('sm')]: {
    width: '130px',
    height: '30px',
    fontSize: '12px',
    padding: '0 10px'
  }
}));

export const IconButtonStyled = styling(IconButton)(props => ({
  padding: '0'
}));

export const Overlay = styling('div')(props => ({
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: '2000'
}));

export const PurpleInfo = styling('div')(props => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-start'
}));

export const PurplePosition = styling('div')(props => ({
  position: 'absolute',
  left: '-400px',
  top: '16px',
  zIndex: '2000'
}));

export const PurpleBox = styling('div')(props => ({
  display: 'flex',
  gap: '-8px',
  alignItems: 'center'
}));

export const PurpleArrow = styling('div')(props => ({
  width: '24px',
  height: '24px'
}));

export const PurpleTextBox = styling('div')(props => ({
  borderRadius: '8px',
  background: 'var(--Secondary-Light-Purple, #EFEFFD)',
  boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  width: '360px',
  padding: '16px 24px 8px 24px',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px'
}));

export const PurpleText = styling('div')(props => ({
  color: 'rgba(0, 0, 0, 0.87))',
  fontSize: '16px',
  fontWeight: props.bold ? '700' : '400',
  lineHeight: '24px' /* 150% */,
  letterSpacing: '0.15px',
  alignSelf: 'stretch'
}));

export const PurpleButtonSection = styling('div')(props => ({
  display: 'flex',
  padding: '16px 0px',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '16px',
  alignSelf: 'stretch',
  background: '#EFEFFD'
}));

export const GotItButton = styling(Button)(props => ({
  display: 'flex',
  width: '200px',
  height: '36px',
  padding: '0px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  color: '#FFF',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: 'normal',
  borderRadius: '8px',
  background: 'var(--Primary-Black, #222)',
  textTransform: 'none',

  ':hover': {
    backgroundColor: '#2E2E2E'
  }
}));
