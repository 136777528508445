import { Grid, Card } from '@mui/material';
import { Skeleton } from '@mui/material';
import React from 'react';

const SkeletonLoader = () => {
  const renderHeader = () => (
    <div style={{ display: 'flex' }}>
      <Grid container>
        {/* <Grid item xs={3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              paddingLeft: 110
            }}>
            <h6
              style={{
                fontSize: 16,
                fontFamily: 'InterBold'
              }}>
              Job Title
            </h6>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingLeft: 15
            }}>
            <h6 style={{ fontSize: 16, fontFamily: 'InterBold' }}>
              Applicants
            </h6>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginLeft: 20
            }}>
            <h6 style={{ fontSize: 16, fontFamily: 'InterBold' }}>
              Job Status
            </h6>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginLeft: 20
            }}>
            <h6 style={{ fontSize: 16, fontFamily: 'InterBold' }}>Boost Job</h6>
          </div>
        </Grid> */}
      </Grid>
    </div>
  );
  const renderRow = () => (
    <div>
      <Skeleton height={3} style={{ marginTop: 20 }} />
      <div style={{ display: 'flex', padding: '20px 20px' }}>
        <Grid container>
          {/* Job Title */}
          <Grid item xs={12}>
            <Skeleton
              variant="text"
              animation="wave"
              style={{ margin: '15px 30px 0px 0px' }}
            />
            <Skeleton
              variant="text"
              animation="wave"
              style={{ margin: '15px 30px 0px 0px' }}
            />
            <Skeleton
              variant="text"
              animation="wave"
              style={{ margin: '15px 30px 0px 0px' }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );

  return (
    <div>
      <Card style={{ padding: 30 }}>
        {/* {renderHeader()} */}
        {renderRow()}
        {renderRow()}
        {renderRow()}
        {renderRow()}
        {renderRow()}
      </Card>
    </div>
  );
};

export default SkeletonLoader;
