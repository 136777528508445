import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../../styles/colors.module.scss';

export const Text = styling('p')((props) => ({
  fontFamily: 'Inter',

  ...(props.table_content == 'true' && {
    fontSize: '16px',
    margin: '0',
    color: Colors.priBlack,
  }),

  ...(props.table_header == 'true' && {
    fontSize: '16px',
    fontWeight: 700,
    margin: '0',
    textTransform: 'capitalize',
    color: Colors.terDarkGrey,
  }),

  ...(props.empty_result == 'true' && {
    fontSize: '22px',
    fontWeight: 700,
    margin: '0',
    textTransform: 'capitalize',
  }),

  ...(props.job_slot_redirect == 'true' && {
    fontSize: '25px',
    margin: '0',
    textAlign: 'center',
  }),

  ...(props.back_button == 'true' && {
    fontSize: '20px',
    margin: '0',
    fontWeight: 300,

    '&:hover': {
      borderBottom: `1px solid ${Colors.priBlack}`,
      cursor: 'pointer',
    }
  }),

  ...(props.available_count == 'true' && {
    fontSize: '42px',
    fontWeight: 700,
    color: Colors.priPurple,
    margin: '0',
  }),

  ...(props.available_count_text == 'true' && {
    fontSize: '20px',
    fontWeight: 500,
    color: Colors.priPurple,
    margin: '0',
  }),

  ...(props.renew_inactive_jobs == 'true' && {
    fontSize: '24px',
    fontWeight: 700,
    margin: '0',
  }),

  ...(props.subtitle == 'true' && {
    fontSize: '16px',
  }),

  ...(props.card_title == 'true' && {
    fontSize: '16px',
    fontWeight: 500,
    margin: '0',
  }),

  ...(props.card_desc == 'true' && {
    fontSize: '12px',
    fontWeight: 400,
    margin: '0',
    color: Colors.terDarkGrey,
    maxWidth: '90%',
  }),

  ...(props.applicant_count == 'true' && {
    fontSize: '20px',
    fontWeight: 700,
    margin: '0',
    color: Colors.priPurple,
  }),

  ...(props.applicant_text == 'true' && {
    fontSize: '12px',
    fontWeight: 400,
    margin: '0',
    color: Colors.terDarkGrey,
  }),

  ...(props.clear == 'true' && {
    fontSize: '14px',
    fontWeight: 400,
    margin: '0',
    color: Colors.terDarkGrey,

    '&:hover':{
      cursor: 'pointer',
      textDecoration: 'underline',
    }
  }),

  ...(props.confirmation == 'true' && {
    fontSize: '25px',
    fontWeight: 700,
    margin: '0',
    color: Colors.priBlack,
  }),

  ...(props.message == 'true' && {
    fontSize: '16px',
    fontWeight: 400,
    margin: '0',
    color: Colors.terDarkGrey,
  }),

  ...(props.info == 'true' && {
    fontSize: '16px',
    fontWeight: 400,
    margin: '0',
    color: Colors.priBlack,
  }),
}));

export const BackButtonContainer = styling('div')((props) => ({
  position: 'absolute',
  top: '100px',
  left: '10%',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

export const Spacer = styling('div')((props) => ({
  width: props.width ? props.width : '5px',
}));
