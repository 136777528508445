import React, { useEffect, useRef, useState } from 'react';
import { FiberManualRecord as FiberManualRecordIcon } from '@mui/icons-material';
import { FormControlLabel } from '@mui/material';
import { PopUpDialog } from '../../../../../components';
import Colors from '../../../../../styles/colors.module.scss';
import styles from './index.module.scss';
import { SuccessToast } from '../../../../../utils/ToastUtils';
import {
  ActionButtonStyled,
  CategoryPill,
  CategoryPillTitle,
  CheckboxStyled,
  GridStyled,
  IconButtonStyled,
  MainCategoryFormLabel,
  MainCategoryText,
  RowStyled,
  SelectStyled,
  SelectTextStyled,
  SelectedCountContainer,
  SpanStyled,
  TextButtonStyled,
  TextFieldStyled,
  XMarkStyled
} from './styles';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTeamMembers } from '../../../../../redux/actions/job_action';
import {
  saveCompanyTeamMemberTrack,
  updateTeamMemberSpecs
} from '../../../../../redux/actions/company_actions';

const AddEditJobTrackPopUp = ({
  open,
  handleClose,
  id,
  tableData,
  setTableData,
  editIndex,
  setEditIndex
}) => {
  const dispatch = useDispatch();

  const dispatchRef = useRef(null);
  const reduxMemberSpecs = useSelector(
    state => state?.companies?.teamMemberSpecs
  );

  const mutatedCompanyTeamMember = useSelector(
    state => state.companies.mutatedCompanyTeamMember
  );

  const [loading, setLoading] = useState(false);

  const onHandleClose = () => {
    setEditIndex(null);
    handleClose();
  };

  const onSaveJobTracks = async () => {
    setLoading(true);
    const flatMapSubCat = reduxMemberSpecs.flatMap(mainCat =>
      mainCat.subCategories?.map(subCat => {
        return { id: subCat.id };
      })
    );

    const targetMemberId =
      tableData[editIndex]?.id ?? mutatedCompanyTeamMember.id;

    const response = await dispatch(
      saveCompanyTeamMemberTrack({
        id: targetMemberId,
        selectedSpecs: flatMapSubCat
      })
    );

    setLoading(false);

    if (response.type === 'SAVE_TEAM_MEMBER_SUCCESS') {
      dispatch(fetchTeamMembers());
      const tmpArray = [...tableData];
      tmpArray[editIndex] = {
        ...tmpArray[editIndex],
        specialisations: reduxMemberSpecs
      };
      setTableData(tmpArray);
     
      onHandleClose();
      SuccessToast(`${id ? 'Updated' : 'Added'} Job Tracks Successfully!`);
    } else {
      
    }
  };

  useEffect(() => {
    // this popup actually mounts multiple times due to each team member having theier own popup
    // to ensure only 1 dispatch is fired
    // utilise useRef to make sure that current Ref is either null or not equals to the target edit index.
    if (!dispatchRef.current || dispatchRef.current !== editIndex) {
      dispatchRef.current = editIndex;

      dispatch(
        updateTeamMemberSpecs({
          specialisations: tableData[editIndex]
            ? tableData[editIndex]?.specialisations
            : []
        })
      );
    }
  }, [editIndex]);

  return (
    <PopUpDialog
      open={open}
      handleClose={onHandleClose}
      title={`${id ? 'Edit' : 'Add'} Job Track`}
      removeContentPadding={true}>
      <div>
        <div className={styles.contentContainer}>
          <div className={styles.row} style={{ marginBottom: '0' }}>
            <p style={{ margin: 0 }}>
              Which job specialisation(s) can this team member manage?
            </p>
          </div>
        </div>

        <div style={{ padding: '0px 24px', minHeight: '350px' }}>
          <SelectSpecialisation />
        </div>

        {!id && (
          <div className={styles.dotContainer}>
            <FiberManualRecordIcon className={styles.dot} />
            <FiberManualRecordIcon className={styles.dot} />
            <FiberManualRecordIcon
              className={styles.dot}
              style={{ height: 14, width: 14, color: Colors.priPurple }}
            />
          </div>
        )}

        <GridStyled
          container
          borderTop={'1px solid #D8D8D8'}
          justifyContent={'center'}
          alignItems={'center'}
          columnGap={'10px'}
          padding={'16px'}>
          <ActionButtonStyled
            variant="text"
            onClick={onHandleClose}
            sx={{
              color: '#222',
              '&:hover': {
                background: '#EFEFEF'
              }
            }}>
            Cancel
          </ActionButtonStyled>

          <ActionButtonStyled
            variant="contained"
            disabled={loading}
            onClick={onSaveJobTracks}
            sx={{
              background: '#2E2E2E',
              '&:hover': {
                background: '#222'
              }
            }}>
            {id ? 'Save' : 'Next'}
          </ActionButtonStyled>
        </GridStyled>
      </div>
    </PopUpDialog>
  );
};

export default AddEditJobTrackPopUp;

const SelectSpecialisation = () => {
  const dispatch = useDispatch();

  const [selectedMainCat, setSelectedMainCat] = useState(null);
  const [searchWord, setSearchWord] = useState(null);
  const [matchResult, setMatchResult] = useState([]);

  const reduxSpecialisations = useSelector(
    state => state?.jobs?.specialisation
  );

  const reduxMemberSpecs = useSelector(
    state => state?.companies?.teamMemberSpecs
  );

  const handleSearchInput = event => {
    if (setSelectedMainCat) setSelectedMainCat(null);

    const { value } = event?.target || {};

    // if user clears the input
    if (value?.length <= 0) {
      setSearchWord(null);
      setMatchResult([]);
    } else {
      setSearchWord(value);
      // return the main category, and ONLY the matching sub categories
      let filterMatchResult = reduxSpecialisations.reduce(
        (accumulator, currentSpec) => {
          const matchingSubCat = currentSpec.subCategories.filter(subCat =>
            subCat?.title?.toLowerCase().includes(value)
          );

          if (matchingSubCat.length > 0) {
            accumulator.push({
              id: currentSpec.id,
              title: currentSpec.title,
              subCategories: matchingSubCat
            });
          }

          return accumulator;
        },
        []
      );

      setMatchResult(filterMatchResult);
    }
  };

  const handleSelectAllSub = propCat => {
    const mainCategory = selectedMainCat ?? propCat;

    const targetMainCat = {
      ...reduxMemberSpecs.find(mainCat => mainCat.id === mainCategory)
    };

    const _tempRef = reduxSpecialisations.find(cat => cat.id === mainCategory);

    const reduxMainCat = { ..._tempRef };

    if (Object.keys(targetMainCat).length < 1) {
      // update the original job track
      let _selectedSpecs = [...reduxMemberSpecs];
      _selectedSpecs = [..._selectedSpecs, reduxMainCat];

      dispatch(updateTeamMemberSpecs({ specialisations: _selectedSpecs }));
      return;
    } else if (
      targetMainCat?.subCategories?.length !==
      reduxMainCat?.subCategories?.length
    ) {
      // replace the current state's target subcategories with the redux
      // essentially selecting all subcategories
      targetMainCat['subCategories'] = reduxMainCat?.subCategories;

      const dummy = Array.from(reduxMemberSpecs);

      const _updatedArr = dummy.map(spec => {
        if (spec.id === mainCategory) {
          return targetMainCat;
        } else return spec;
      });

      dispatch(updateTeamMemberSpecs({ specialisations: _updatedArr }));
      return;
    } else if (
      targetMainCat?.subCategories?.length ===
      reduxMainCat?.subCategories?.length
    ) {
      targetMainCat['subCategories'] = [];

      const dummy = Array.from(reduxMemberSpecs);

      const _updatedArr = dummy.map(spec => {
        if (spec.id === mainCategory) {
          return targetMainCat;
        } else return spec;
      });

      dispatch(updateTeamMemberSpecs({ specialisations: _updatedArr }));
      return;
    }
  };

  const handleOptionClick = option => {
    const { id, title, parentCategory } = option || {};

    const targetMainCat = {
      ...reduxMemberSpecs.find(mainCat => mainCat.id === parentCategory?.id)
    };

    // if main categorry is not in redux selected category
    if (Object.keys(targetMainCat).length < 1) {
      let _tempArr = reduxMemberSpecs;
      _tempArr = [
        ..._tempArr,
        {
          id: parentCategory?.id,
          title: parentCategory.title,
          subCategories: [option]
        }
      ];

      dispatch(updateTeamMemberSpecs({ specialisations: _tempArr }));
      return;
    } else if (
      !targetMainCat?.subCategories?.find(subCat => subCat.id === option.id)
    ) {
      const _localSpec = Array.from(reduxMemberSpecs);

      targetMainCat['subCategories'] = [
        ..._localSpec.find(mainCat => mainCat.id === parentCategory.id)
          ?.subCategories,
        option
      ];

      const updatedSpec = _localSpec.map(mainSpec => {
        if (mainSpec.id === parentCategory.id) {
          return targetMainCat;
        }
        return mainSpec;
      });

      dispatch(updateTeamMemberSpecs({ specialisations: updatedSpec }));
      return;
    } else if (
      targetMainCat?.subCategories.find(subCat => subCat.id === option.id)
    ) {
      const _localSpec = Array.from(reduxMemberSpecs);

      targetMainCat['subCategories'] = [
        ..._localSpec
          .find(mainCat => mainCat.id === parentCategory.id)
          ?.subCategories.filter(subCat => subCat.id !== id)
      ];

      const updatedSpec = _localSpec.map(mainSpec => {
        if (mainSpec.id === parentCategory.id) {
          return targetMainCat;
        }
        return mainSpec;
      });

      dispatch(updateTeamMemberSpecs({ specialisations: updatedSpec }));
      return;
    }
  };

  const checkAllSelected = () => {
    // first check if selectedjobtrack is empty
    if (reduxMemberSpecs.length < 1) {
      return false;
    }

    const targetCategoryForCheck = reduxMemberSpecs.find(
      mainSpec => mainSpec.id === selectedMainCat
    );

    // then check if subcategories for the selectedmaincategory is empty in selectedjobtrack
    if (
      !targetCategoryForCheck ||
      targetCategoryForCheck?.subCategories.length < 1
    ) {
      return false;
    }

    const reduxTargetCategory = reduxSpecialisations.find(
      spec => spec.id === selectedMainCat
    );

    // then lastly if not empty, we check if length is same as redux subcategories length
    if (
      !reduxTargetCategory ||
      targetCategoryForCheck?.subCategories?.length !==
        reduxTargetCategory?.subCategories?.length
    ) {
      return false;
    }

    return true;
  };

  const checkOptionSelected = optionTitle => {
    const subCatArr = Array.isArray(reduxMemberSpecs)
      ? reduxMemberSpecs.flatMap(mainSpec =>
          mainSpec?.subCategories?.map(subCat => subCat?.title)
        )
      : [];

    return subCatArr.includes(optionTitle);
  };

  const optionCal =
    Array.isArray(reduxMemberSpecs) && reduxMemberSpecs.length > 0
      ? reduxMemberSpecs.flatMap(category =>
          category?.subCategories
            ? category.subCategories.length > 0
              ? category
              : []
            : []
        )
      : [];

  // FOR CHECKBOX INDETERMINATE PROP - START
  const checkIndeterminate = () => {
    const selectSubCatLength = reduxMemberSpecs?.find(
      spec => spec?.id === selectedMainCat
    )?.subCategories?.length;

    const oriSubCatLength = reduxSpecialisations?.find(
      spec => spec?.id === selectedMainCat
    )?.subCategories?.length;

    return selectSubCatLength !== oriSubCatLength && selectSubCatLength > 0;
  };

  const checkAllSubSelected = () => {
    const allSubCat = reduxSpecialisations?.flatMap(mainCat =>
      mainCat?.subCategories?.map(subCat => subCat.id)
    );

    const selectedSubCat = reduxMemberSpecs?.flatMap(mainCat =>
      mainCat?.subCategories?.map(subCat => subCat.id)
    );

    return allSubCat.every(subCat => selectedSubCat.includes(subCat));
  };

  const handleSelectAllSubCat = () => {
    const allSelected = checkAllSubSelected();

    if (allSelected) {
      dispatch(updateTeamMemberSpecs({ specialisations: [] }));
      return;
    } else {
      dispatch(
        updateTeamMemberSpecs({ specialisations: reduxSpecialisations })
      );
      return;
    }
  };

  const handleRemoveSingleCat = category => {
    const filteredArr = Array.from(reduxMemberSpecs).filter(
      cat => cat.id !== category.id
    );

    dispatch(updateTeamMemberSpecs({ specialisations: filteredArr }));
    return;
  };

  return (
    <SelectStyled
      onClose={() => setSelectedMainCat(null)}
      size="small"
      value={optionCal.length > 0 ? optionCal : ['Select Job Specialisation']}
      placeholder="Select Job Specialisation"
      fullWidth
      multiple
      displayEmpty
      MenuProps={{
        PaperProps: {
          sx: {
            boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.16)',
            borderRadius: '4px',
            width: '300px',
            height: '300px'
          }
        }
      }}
      renderValue={value => {
        return Array.isArray(value) &&
          value.length === 1 &&
          value[0] === 'Select Job Specialisation' ? (
          <SpanStyled>{value}</SpanStyled>
        ) : checkAllSubSelected() ? (
          <CategoryPill>
            <CategoryPillTitle sx={{ maxWidth: '200px' }}>
              All Job Specialisations Selected
            </CategoryPillTitle>

            <IconButtonStyled
              sx={{ padding: 0 }}
              onMouseDown={event => {
                event.stopPropagation();
                handleSelectAllSubCat();
              }}>
              <XMarkStyled width={14} height={14} />
            </IconButtonStyled>
          </CategoryPill>
        ) : Array.isArray(value) && value.length > 2 ? (
          <GridStyled
            position={'relative'}
            container
            flexWrap={'nowrap'}
            columnGap={'6px'}
            height={'25px'}
            width={'fit-content'}>
            {value.slice(0, 2).map((selectedVal, index) => (
              <CategoryPill>
                <CategoryPillTitle>{selectedVal.title}</CategoryPillTitle>
                <CategoryPillTitle>
                  ({selectedVal.subCategories?.length})
                </CategoryPillTitle>

                <IconButtonStyled
                  sx={{ padding: 0 }}
                  onMouseDown={event => {
                    event.stopPropagation();
                    handleRemoveSingleCat(selectedVal);
                  }}>
                  <XMarkStyled width={14} height={14} />
                </IconButtonStyled>
              </CategoryPill>
            ))}
            <CategoryPill
              sx={{
                justifyContent: 'center'
              }}>
              <CategoryPillTitle>{value.length - 2}+</CategoryPillTitle>
            </CategoryPill>
          </GridStyled>
        ) : (
          <GridStyled
            position={'relative'}
            container
            flexWrap={'nowrap'}
            columnGap={'6px'}
            height={'25px'}
            width={'fit-content'}>
            {value.map(selectedVal => (
              <CategoryPill>
                <CategoryPillTitle>{selectedVal.title}</CategoryPillTitle>
                <CategoryPillTitle>
                  ({selectedVal.subCategories?.length})
                </CategoryPillTitle>
                <IconButtonStyled
                  sx={{ padding: 0 }}
                  onMouseDown={event => {
                    event.stopPropagation();
                    handleRemoveSingleCat(selectedVal);
                  }}>
                  <XMarkStyled width={14} height={14} />
                </IconButtonStyled>
              </CategoryPill>
            ))}
          </GridStyled>
        );
      }}>
      <TextFieldStyled
        size="small"
        defaultValue={searchWord}
        onChange={event => handleSearchInput(event)}
        placeholder="Search Specialisation"
        inputProps={{
          style: {
            fontWeight: 400,
            fontFamily: 'Inter',
            fontSize: '12px',
            lineHeight: '20px'
          }
        }}
      />

      <GridStyled
        container
        paddingX={'16px'}
        justifyContent={'space-between'}
        alignItems={'center'}
        paddingBottom={'8px'}
        borderBottom={'1px solid #D8D8D8'}>
        <SelectTextStyled variant="body1" sx={{ fontSize: '12px' }}>
          {reduxMemberSpecs?.flatMap(mainSpec =>
            mainSpec?.subCategories?.map(subCat => subCat.title)
          )?.length ?? 0}{' '}
          selected
        </SelectTextStyled>
        {selectedMainCat || matchResult.length > 0 ? null : (
          <TextButtonStyled
            variant="text"
            onClick={() => handleSelectAllSubCat()}>
            {checkAllSubSelected() ? 'Deselect All' : 'Select All'}
          </TextButtonStyled>
        )}
      </GridStyled>

      {/* option rendering start */}
      {selectedMainCat ? (
        <GridStyled
          columnGap={'8px'}
          padding={'8px 16px'}
          container
          alignItems={'center'}
          flexWrap={'nowrap'}>
          <IconButtonStyled
            onClick={() => setSelectedMainCat(null)}
            sx={{ padding: 0 }}>
            <ChevronLeftIcon width={20} height={20} />
          </IconButtonStyled>
          <FormControlLabel
            control={
              <CheckboxStyled
                // if all sub categories are selected, then this will be selected
                indeterminate={checkIndeterminate()}
                checked={checkAllSelected()}
                onClick={() => handleSelectAllSub()}
              />
            }
            sx={{
              '& .MuiTypography-root': {
                fontSize: 14,
                letterSpacing: '0.15px'
              },
              '& .MuiSvgIcon-root': {
                fontSize: 20
              }
            }}
            label={
              <MainCategoryText variant="body1" sx={{ fontWeight: 700 }}>
                {
                  reduxSpecialisations.find(
                    mainCat => mainCat.id === selectedMainCat
                  )?.title
                }
              </MainCategoryText>
            }
          />
        </GridStyled>
      ) : null}

      {/* IF USER SEARCHES AND THERE IS MATCHING RESULT */}
      {matchResult.length > 0
        ? matchResult.map((item, index) => (
            <GridStyled id={item?.id} borderBottom={'1px solid #EFEFEF'}>
              <SelectTextStyled
                margin={'8px 16px 0px'}
                variant="body1"
                sx={{
                  fontWeight: '700',
                  color: 'rgba(0,0,0,0.87)',
                  height: 'fit-content'
                }}>
                {item.title}
              </SelectTextStyled>

              {Array.isArray(item.subCategories) &&
                item.subCategories.map((subCat, index) => (
                  <RowStyled key={`${subCat.id}`}>
                    <FormControlLabel
                      control={
                        <CheckboxStyled
                          checked={checkOptionSelected(subCat.title)}
                          onClick={() => handleOptionClick(subCat)}
                        />
                      }
                      label={subCat.title}
                    />
                  </RowStyled>
                ))}
            </GridStyled>
          ))
        : // IF A MAIN CATEGORY IS SELECTED
        selectedMainCat
        ? reduxSpecialisations
            .find(spec => spec.id === selectedMainCat)
            ?.subCategories?.map(subCat => (
              <RowStyled key={`${subCat.id}`}>
                <FormControlLabel
                  control={
                    <CheckboxStyled
                      sx={{
                        padding: '0px 9px 0px 0px',
                        marginLeft: '38px'
                      }}
                      checked={checkOptionSelected(subCat.title)}
                      onClick={() => handleOptionClick(subCat)}
                    />
                  }
                  label={subCat.title}
                  sx={{
                    alignItems: 'flex-start'
                  }}
                />
              </RowStyled>
            ))
        : reduxSpecialisations.map((mainCat, index) => (
            <MainCategory
              handleSelectAllSub={handleSelectAllSub}
              specialisation={mainCat}
              setSelectedMainCat={setSelectedMainCat}
            />
          ))}
    </SelectStyled>
  );
};

const MainCategory = ({
  handleSelectAllSub,
  specialisation,
  setSelectedMainCat
}) => {
  const reduxSpecialisations = useSelector(
    state => state?.jobs?.specialisation
  );

  const reduxMemberSpecs = useSelector(
    state => state?.companies?.teamMemberSpecs
  );
  const getSubCategoriesCount = majorCategory => {
    let count = 0;

    if (reduxMemberSpecs.length > 0) {
      count =
        reduxMemberSpecs.find(mainSpec => mainSpec.id === majorCategory)
          ?.subCategories?.length ?? 0;
    }

    return count;
  };

  const checkAllSelected = category => {
    // first check if selectedjobtrack is empty
    if (reduxMemberSpecs.length < 1) {
      return false;
    }

    const targetCategoryForCheck = reduxMemberSpecs.find(
      mainSpec => mainSpec.id === category
    );

    // then check if subcategories for the category is empty in selectedjobtrack
    if (
      !targetCategoryForCheck ||
      targetCategoryForCheck?.subCategories.length < 1
    ) {
      return false;
    }

    const reduxTargetCategory = reduxSpecialisations.find(
      spec => spec.id === category
    );

    // then lastly if not empty, we check if length is same as redux subcategories length
    if (
      !reduxTargetCategory ||
      targetCategoryForCheck?.subCategories?.length !==
        reduxTargetCategory?.subCategories?.length
    ) {
      return false;
    }

    return true;
  };

  return (
    <GridStyled
      padding={'8px 16px'}
      container
      justifyContent={'space-between'}
      alignItems={'center'}
      flexWrap={'nowrap'}
      onClick={() => setSelectedMainCat(specialisation.id)}
      sx={{
        '&:hover': {
          background: '#EFEFEF'
        },
        cursor: 'pointer'
      }}>
      <GridStyled
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        flexWrap={'nowrap'}>
        <MainCategoryFormLabel
          control={
            <CheckboxStyled
              sx={{
                '&.Mui-checked': {
                  color: '#222'
                },
                padding: '0 9px'
              }}
              checked={checkAllSelected(specialisation?.id)}
              onClick={event => {
                event.stopPropagation();
                handleSelectAllSub(specialisation?.id ?? '');
              }}
            />
          }
          label={
            <MainCategoryText variant="body1">
              {specialisation.title}
            </MainCategoryText>
          }
        />
        {/* <MainCategoryText variant="body1">
          {specialisation.title}
        </MainCategoryText> */}
        {getSubCategoriesCount(specialisation?.id) > 0 && (
          <SelectedCountContainer>
            {getSubCategoriesCount(specialisation?.id)}
          </SelectedCountContainer>
        )}
      </GridStyled>
      <IconButtonStyled
        onClick={() => {
          // handleClick(specialisation.title);
        }}
        sx={{ padding: 0 }}>
        <ChevronRightIcon width={20} height={20} color="rgba(0,0,0,0.60)" />
      </IconButtonStyled>
    </GridStyled>
  );
};
