import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/solid';
import { Close, Close as CloseIcon } from '@mui/icons-material';
import { styled as styling } from '@mui/material/styles';
import Colors from '../../../styles/colors.module.scss';

export const SearchWrapper = styling('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
});

export const MagnifyingGlassCircleIconStyled = styling(MagnifyingGlassCircleIcon)({
  height: '28px',
  width: '28px',

  '&:hover': {
    color: Colors.priPurple,
  },
});

export const CloseIconStyled = styling(CloseIcon)({
  height: '20px',
  width: '20px',
});
