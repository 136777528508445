import { styled as styling } from '@mui/material/styles';

export const DisclamierContainer = styling('div')({
  textAlign: 'center',
  fontSize: '12px',
});

export const ContentText = styling('span')(({ 
  $disclamier 
}) => ({
  fontSize: $disclamier ? '12px' : '14px',
  fontFamily: 'Inter',
  fontWeight: 400,
  letterSpacing: '0.15px',
}));
