import React, { useState, useRef, useEffect } from 'react';
import {
  faBars,
  faCalendarPlus,
  faClock,
  faMapMarkerAlt,
  faPhoneAlt,
  faUserAlt,
  faPen
} from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../../../../components';
import {
  Title,
  Text,
  ContentContainer,
  IconContainer,
  ButtonContainer,
  FontAwesomeIconStyled
} from './styles';
import {
  scheduleInterview,
  updateFirstScheduleInterviewModalStatus,
  updateNextScheduleInterviewModalStatus
} from '../../../../../../src/redux/actions/application_action';
import {
  SuccessToast,
  ErrorToast,
  InfoToast
} from '../../../../../utils/ToastUtils';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as types from '../../../../../redux/types/application_type';
import _ from 'lodash';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import { TextInputFunction } from '../TextInput';
import { PlacesAutocompleteComponent } from '../PlacesAutocomplete';
import { TextArea } from '../TextArea';
import { TimeSelect } from '../TimeDropdown';

import PhoneInput, {
  isValidPhoneNumber,
  isPossiblePhoneNumber
} from 'react-phone-number-input';
import './SharedContent.css';
import { JOB_APPLICATION_STATUS } from '../../../../../utils/Constants';
import { updateJobData } from '../../../../../redux/actions/job_action';

const SpecialInput = ({ mobileNumber, handlePhoneNumber }) => {
  return (
    <>
      <PhoneInput
        placeholder="+6012 3456789"
        value={mobileNumber}
        defaultCountry={'MY'}
        countryCallingCodeEditable={false}
        international
        onChange={handlePhoneNumber}
      />
    </>
  );
};

const SharedContent = props => {
  const {
    candidates,
    candidateInfo,
    setCandidates,
    setCurrentCandidates,
    setJobData,
    jobData
  } = props;
  const [values, setValues] = useState({
    contactPerson: null,
    mobileNumber: '',
    messageToCandidate: '',
    validPhoneNumber: false
  });
  // const [inputErrors, setInputErrors] = useState({
  //   location: false,
  //   contactPerson: false,
  //   mobileNumber: false,
  //   messageToCandidate: false
  // });
  const [address, setAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [interviewDate, setInterviewDate] = useState(
    moment().format('YYYY-MM-DD')
  );
  const [defaultTime, setDefaultTime] = useState('Select a time');
  const [timeArray, setTimeArray] = useState([]);
  const today = moment().format('YYYY-MM-DD');

  const { contactPerson, mobileNumber, messageToCandidate, validPhoneNumber } =
    values;
  const addressAutoCompleteRef = useRef();

  const updateCandidateInterviewFromList = interview => {
    if (!candidates) return;

    const index = candidates.findIndex(
      item => item?.node?.id === candidateInfo?.id
    );

    if (index !== -1) {
      const cCandidates = [...candidates];
      const isInterview = cCandidates[index]?.node?.jobApplication
        ? true
        : false;

      const existingInterviews = cCandidates[index]?.node?.interviews
        ? [...cCandidates[index].node.interviews]
        : cCandidates[index]?.node?.jobApplication?.interviews
        ? [...cCandidates[index].node.jobApplication.interviews]
        : [];

      cCandidates[index] = {
        ...cCandidates[index],
        node: {
          ...cCandidates[index]?.node,
          ...(isInterview && {
            jobApplication: {
              ...cCandidates[index].node.jobApplication,
              interviews: [{ ...interview }, ...existingInterviews]
            }
          }),
          ...(!isInterview && {
            interviews: [{ ...interview }, ...existingInterviews]
          })
        }
      };

      setCandidates(cCandidates);

      // Won't pass this from board
      if (setCurrentCandidates) {
        setCurrentCandidates(cCandidates);
      }
    }
  };

  const getCandidate = props => {
    if (!props.isAts) {
      return props.interviewData;
      //return candidateInfo;
    } else {
      if (props.allApplications[props.currentCandidateIndex]) {
        return props.allApplications[props.currentCandidateIndex];
      }

      return null;
    }
  };

  const message = interview => () => {
    return (
      <div>
        <p
          style={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 'bold',
            margin: '4px 0'
          }}>
          Interview
        </p>
        <p style={{ margin: '0', fontFamily: 'Inter', fontSize: '12px' }}>
          An interview with <b>{interview?.jobApplication?.user?.name}</b> has
          been scheduled
        </p>
      </div>
    );
  };

  const updateLocalCandidates = candidates => {
    if (!candidates) return;

    const cCandidates = [...candidates];

    const index = candidates.findIndex(item => {
      return item?.node?.id == getCandidate(props).id;
    });

    if (index != -1) {
      let removedArray = [];
      removedArray = [...cCandidates].filter(value => {
        return value?.node?.id !== getCandidate(props)?.id;
      });

      setCandidates(removedArray);
      setCurrentCandidates(removedArray);
    }
  };

  const getUpdatedApplicantCount = (currentState, newState, jobData) => {
    let cJobData = { ...jobData };

    switch (currentState) {
      case 'undecided':
        {
          cJobData.undecidedCount -= 1;
        }
        break;
      case 'shortlisted':
        {
          cJobData.shortlistedCount -= 1;
        }
        break;
      case 'kiv':
        {
          cJobData.kivCount -= 1;
        }
        break;
      case 'rejected':
        {
          cJobData.rejectedCount -= 1;
        }
        break;
    }

    switch (newState) {
      case 'interview':
        {
          cJobData.interviewCount += 1;
        }
        break;
    }

    return cJobData;
  };

  const submitScheduleInterview = props => () => {
    setLoading(true);

    const currentCandidate = getCandidate(props);

    if (!currentCandidate) return;

    if (defaultTime == 'Select a time') {
      ErrorToast('Please select a time');
      setLoading(false);
      return;
    } else if (address == '') {
      ErrorToast('Please provide interview location.');
      setLoading(false);
      return;
    } else if (values?.contactPerson == '') {
      ErrorToast('Please provide contact person.');
      setLoading(false);
      return;
    } else if (values?.mobileNumber == '') {
      ErrorToast('Please provide contact number.');
      setLoading(false);
      return;
    } else if (!values?.validPhoneNumber) {
      ErrorToast('Please provide a valid phone number.');
      setLoading(false);
      return;
    }

    let startTime = defaultTime;
    // startTime = startTime.replace('AM', '').replace('PM', '');

    const isInterview = currentCandidate.jobApplication ? true : false;
    const application = currentCandidate.jobApplication
      ? currentCandidate.jobApplication
      : currentCandidate;

    let scheduleInterviewParams = {
      id: application.id,
      state: application.state,
      startTime: startTime,
      date: interviewDate,
      venue: address,
      contactPerson: values?.contactPerson,
      contactNumber: values?.mobileNumber,
      messageToJobseeker: values?.messageToCandidate
    };

    props.scheduleInterview(scheduleInterviewParams).then(res => {
      if (res.type === types.SCHEDULE_INTERVIEW_SUCCEED) {
        setLoading(false);

        if (res.interview?.jobApplication?.job) {
          if (jobData) {
            const cJobData = getUpdatedApplicantCount(
              res?.state,
              'interview',
              jobData
            );

            if (setJobData) {
              setJobData(cJobData);
              updateJobData(cJobData);
            }
          }
        }

        updateCandidateInterviewFromList(res.interview);

        if (res?.state !== 'interview') {
          updateLocalCandidates(candidates);
        }

        // SuccessToast('Scheduled interview successfully.');
        InfoToast(message(res.interview));
      } else {
        setLoading(false);
        ErrorToast(res.errorMessage);
      }

      if (props.isFirstInterviewModalOpened) {
        // if (props.setIsFirstInterviewModalOpened) {
        props.updateFirstScheduleInterviewModalStatus(false, null);
        // }
      }

      if (props.isNextInterviewModalOpened) {
        // if (props.setIsNextInterviewModalOpened) {
        // props.updateNextScheduleInterviewModalStatus(false);
        // }

        if (props.handleModalClose) {
          props.handleModalClose();
        }
      }
    });
  };

  const handleChange = name => (e, value) => {
    if (name == 'messageToCandidate') {
      const length = e.target.value.length;
      if (length > 350) {
        e.target.value = e.target.value.substring(0, 350);
      }
    }

    setValues({
      ...values,
      [name]:
        name == 'contactPerson'
          ? e.target.value.replace(/[^a-zA-z\/@ '-]/g, '')
          : e.target.value
    });
  };

  const handleAddressChange = value => {
    setAddress(value);
  };

  const onAddressBlur = () => {
    if (addressAutoCompleteRef?.current) {
      if (addressAutoCompleteRef.current.clearSuggestions) {
        addressAutoCompleteRef.current.clearSuggestions();
      }
    }
  };

  const onAddressFocus = () => {
    if (address) {
      if (addressAutoCompleteRef?.current) {
        if (addressAutoCompleteRef.current.fetchPredictions) {
          addressAutoCompleteRef.current.fetchPredictions();
        }
      }
    }
  };

  const handleDateChange = event => {
    setInterviewDate(event.currentTarget.value);
  };

  const handleDialogTimeChange = event => {
    setDefaultTime(event.currentTarget.value);
  };

  const handlePhoneNumber = number => {
    if (number) {
      let validPhoneNumber = isValidPhoneNumber(number);

      setValues({
        ...values,
        mobileNumber: number,
        validPhoneNumber: validPhoneNumber
      });
    }
  };

  useEffect(() => {
    let timeArray = ['Select a time'];

    for (let i = 8; i <= 22; i++) {
      const limit = i === 22 ? 1 : 2;
      for (let x = 0; x < limit; x++) {
        const time = i + ':' + (x == 0 ? '00' : 30 * x);
        timeArray.push(time);
      }
    }

    setTimeArray(timeArray);
  }, []);

  return (
    <div>
      <Title>
        <Text type="title">Schedule Interview</Text>
      </Title>
      <div>
        <Text type="reminder">
          Please ensure that you have contacted the jobseeker to negotiate and
          confirm the timing before scheduling an interview.
        </Text>
      </div>
      <div>
        <ContentContainer type="two-column">
          <IconContainer type="basic">
            <FontAwesomeIconStyled
              icon={faCalendarPlus}
              awesome_icon={'faCalendarPlus'}
            />
          </IconContainer>
          <TextInputFunction
            type="date"
            minDate={today}
            label={''}
            value={interviewDate}
            handleChange={handleDateChange}
            min="2018-01-01"
          />
        </ContentContainer>

        <ContentContainer type="two-column">
          <IconContainer type="basic">
            <FontAwesomeIconStyled icon={faClock} awesome_icon={'faClock'} />
          </IconContainer>
          <TimeSelect
            onChange={handleDialogTimeChange}
            interviewDate={interviewDate}
          />
        </ContentContainer>

        <ContentContainer type="two-column">
          <IconContainer type="basic">
            <FontAwesomeIconStyled
              icon={faMapMarkerAlt}
              awesome_icon={'faMapMarkerAlt'}
            />
          </IconContainer>
          <PlacesAutocompleteComponent
            addressAutoCompleteRef={addressAutoCompleteRef}
            address={address}
            handleAddressChange={handleAddressChange}
            onAddressBlur={onAddressBlur}
            onAddressFocus={onAddressFocus}
            loading={loading}
          />
        </ContentContainer>

        <ContentContainer type="two-column">
          <IconContainer type="basic">
            <FontAwesomeIconStyled
              icon={faUserAlt}
              awesome_icon={'faUserAlt'}
            />
          </IconContainer>
          <TextInputFunction
            label="contactPerson"
            type={'text'}
            handleChange={handleChange('contactPerson')}
            value={contactPerson}
            placeholder={'Contact person*'}
          />
        </ContentContainer>

        <ContentContainer type="two-column">
          <IconContainer type="basic">
            <FontAwesomeIconStyled
              icon={faPhoneAlt}
              awesome_icon={'faPhoneAlt'}
            />
          </IconContainer>
          {/* <TextInputFunction
            label="mobileNumber"
            type={'number'}
            handleChange={handleChange('mobileNumber')}
            value={mobileNumber}
            placeholder={'Contact number* (e.g. 0123456789)'}
          /> */}

          {/* <IconContainer type="pointer">
            <FontAwesomeIconStyled icon={faPen} awesome_icon={'faPen'} />
          </IconContainer> */}
          <SpecialInput
            contactNumber={mobileNumber}
            handlePhoneNumber={handlePhoneNumber}
          />
        </ContentContainer>

        <ContentContainer type="two-column">
          <IconContainer type="fa-bars">
            <FontAwesomeIconStyled icon={faBars} awesome_icon={'faBars'} />
          </IconContainer>

          <TextArea
            value={values.messageToCandidate}
            handleChange={handleChange('messageToCandidate')}
            placeholder={`(Optional) Message to candidate. \n\n(e.g. please bring along your relevant work samples and portfolio)`}
          />
        </ContentContainer>

        <ButtonContainer>
          <Button
            color="PURPLE"
            size="BIG"
            onClick={submitScheduleInterview(props)}
            disabled={
              loading ||
              defaultTime == 'Select a time' ||
              address == '' ||
              values?.contactPerson == '' ||
              values?.mobileNumber == ''
            }>
            {loading ? (
              <CircularProgress
                style={{ width: '20px', height: '20px', color: '#fff' }}
              />
            ) : (
              'Send Invite'
            )}
          </Button>
        </ButtonContainer>

        <div>
          <Text type="reminder">
            A calendar invite will be sent to you and the candidate via email.
          </Text>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    currentCandidateIndex: state.applications.currentCandidateIndex,
    allApplications: state.applications.allApplications,
    interviewData: state.applications.interviewData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    scheduleInterview: bindActionCreators(scheduleInterview, dispatch),
    updateFirstScheduleInterviewModalStatus: bindActionCreators(
      updateFirstScheduleInterviewModalStatus,
      dispatch
    ),
    updateNextScheduleInterviewModalStatus: bindActionCreators(
      updateNextScheduleInterviewModalStatus,
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(SharedContent);
