import { Radio, FormControlLabel, RadioGroup } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';

const RadioTitleStyled = styled('p')({
  fontFamily: 'Inter',
  fontSize: '12px',
  margin: 0
});

const SharedRadio = ({ $type, ...props }) => {
  return (
    <Radio
      color="default"
      {...props}
      sx={{
        '&.MuiRadio-root': {
          padding: $type == 'talent-search-page' ? '2px 9px' : '4px 9px'
        },
        '&.Mui-checked': {
          color: '#222 !important'
        },
        '&:hover': {
          color: '#aeaeae',
          backgroundColor: 'transparent',

          '.MuiSvgIcon-root': {
            transform: 'scale(1)',
            transition: 'none'
          }
        }
      }}
    />
  );
};

const SharedFormRadioGroup = ({
  label,
  labelProps,
  radioProps,
  options = [],
  $type,
  ...props
}) => {
  return (
    <RadioGroup {...props}>
      {options.map(option => {
        return (
          <FormControlLabel
            value={option.value || ''}
            control={<SharedRadio {...radioProps} $type={$type} />}
            label={
              <RadioTitleStyled {...labelProps}>
                {option.label}
              </RadioTitleStyled>
            }
          />
        );
      })}
    </RadioGroup>
  );
};

export default SharedFormRadioGroup;
