import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../../styles/colors.module.scss';
import { Info as InfoIcon } from '@mui/icons-material';
import { Tooltip } from '../../../../../components/CustomTooltip/styles';

export const Text = styling('span')(props => ({
  ...(props.$type == 'skills-title' && {
    fontSize: '20px',
    fontWeight: '700',
    fontFamily: 'Inter'
  }),
  ...(props.$type == 'skills-description' && {
    fontSize: '12px',
    fontFamily: 'Inter'
  }),
  ...(props.$type == 'skills-length' && {
    fontSize: '13px',
    fontFamily: 'Inter'
  }),
  ...(props.$type == 'keywords-title' && {
    fontSize: '20px',
    fontWeight: '700',
    fontFamily: 'Inter'
  }),
  ...(props.$type == 'keywords-description' && {
    fontSize: '12px',
    fontFamily: 'Inter',
    maxWidth: '91%',
    fontWeight: '400',
    lineHeight: '140%'
  }),
  ...(props.$type == 'keywords-length' && {
    fontSize: '13px',
    fontFamily: 'Inter',
    display: 'flex',
    alignItems: 'flex-end'
  }),
  ...(props.$type == 'custom-tooltip' && {
    fontSize: '12px',
    fontFamily: 'Inter',
    margin: '0'
  })
}));

export const TextContainer = styling('div')(props => ({
  ...(props.$container == 'title-container' && {
    display: 'flex',
    alignItems: 'center'
  }),
  ...(props.$container == 'description-container' && {
    display: 'flex',
    justifyContent: 'space-between'
  })
}));

export const InfoIconStyled = styling(InfoIcon)(props => ({
  margin: '0 15px',
  color: Colors.terMediumGrey,
  fontSize: '20px'
}));

export const TooltipWrapper = styling('div')(props => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  '&:hover': {
    [`${Tooltip}`]: {
      display: 'block',
      animation: 'fadeInAnimation ease 1s',
      animationIterationCount: 1,
      animationFillMode: 'forwards',
      '@keyframes fadeInAnimation': {
        '0%': {
          opacity: 0
        },
        '50%': {
          opacity: 0.5
        },
        '100%': {
          opacity: 1
        }
      }
    }
  }
}));

export const Description = styling('span')(props => ({
  color: Colors.priBlack,
  fontSize: '14px',
  opacity: '0.6'
}));
