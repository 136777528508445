import { styled as styling } from '@mui/material/styles';
import Colors from '../../../src/styles/colors.module.scss';

export const ChipContainer = styling('div')(({ 
  $backgroundColor, 
  $marginBottom 
}) => ({
  padding: '6px 12px',
  borderRadius: '30px',
  fontSize: '14px',

  ...(($backgroundColor) ? {
    background: $backgroundColor,
  } : {
    background: Colors.secLightpurple,
  }),

  ...(($marginBottom) ? {
    marginBottom: $marginBottom,
  } : {}),
}));
