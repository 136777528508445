import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ViewState from './ViewState';
import EditState from './EditState';
import InfoPopUp from './components/InfoPopup';
import { updateCompanySidebar } from '../../../redux/actions/company_actions';

export default function CompanyVideos() {
  const companyProfile = useSelector(state => state.companies.companyProfile);
  const [view, setView] = useState(true);
  const [info, setInfo] = useState(false);
  const dispatch = useDispatch();
  const companyCompletion = useSelector(
    state => state.companies.companyCompletion
  );
  useEffect(() => {
    const updateSidebar = () => {
      if (companyProfile?.videos?.length > 0) {
        setView(true);
        dispatch(
          updateCompanySidebar({
            ...companyCompletion,
            companyVideos: true
          })
        );
      } else {
        setView(false);
        dispatch(
          updateCompanySidebar({
            ...companyCompletion,
            companyVideos: false
          })
        );
      }
    };

    if (companyProfile) {
      updateSidebar();
    }
  }, [companyProfile]);

  return (
    <div id="Company Videos">
      {view ? (
        <ViewState setInfo={setInfo} setView={setView} />
      ) : (
        <EditState setInfo={setInfo} setView={setView} />
      )}
      <InfoPopUp
        open={info}
        handleClose={() => {
          setInfo(false);
        }}
      />
    </div>
  );
}
