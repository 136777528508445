import { styled as styling } from '@mui/material/styles';

export const Content = styling('li')((props) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  letterSpacing: '0.15px',
  margin: '10px 0', 
  lineHeight: '24px',
}));