import { ErrorToast } from '../../../../utils/ToastUtils';

export async function useCheckReadyStatus(vimeoId) {
  const accessToken = process.env.REACT_APP_VIMEO_TOKEN;
  const url = `https://api.vimeo.com/videos/${vimeoId}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/vnd.vimeo.*+json;version=3.4'
      }
    });

    const data = await response.json();
    if (response.ok && data.status === 'available') {
      return true;
    } else if (data?.error) {
      ErrorToast(data?.error);
      return null;
    } else {
      return false
    }
  } catch (error) {
    console.error('Error:', error);
  }
  return false;
}
