import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '30px 0px',
  gap: '12px',
});

export const Logo = styled('img')({
  width: '200px',
  height: '28px',
  marginBottom: '1rem',
});

export const Row = styled('div')({
  marginBottom: '15px'
});

export const RowLabel = styled('p')({
  paddingLeft: '10px',
  margin: '5px 0px'
});

export const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  margin: '15px'
});

export const PurpleText = styled('p')(({ theme }) => ({
  margin: '10px 0',
  color: theme.palette.primary.main,
  cursor: 'pointer'
}));


export const CardContainer = styled(Card)({
  // maxWidth: '460px',
  borderRadius: '32px',
  padding: '40px 50px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#fff',
  width: "32vw"
});

export const TitleText = styled('p')({
  margin: '0px 0px 20px',
  fontSize: '20px',
  fontFamily: 'InterBold',
  textAlign: 'center'
});

export const FormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',

  '.description': {
    padding: '5px 0px 15px'
  },

  '.mb-2': {
    // marginBottom: '8px'
  },

  '.mb-6': {
    marginBottom: '24px'
  },

  'b': {
    color: theme.palette.primary.main
  },

  '.note': {
    color: theme.palette.grey['600']
  }
}));