import React from 'react';
import { ButtonStyled } from './styles';

export const SharedButton = ({
  onClick,
  type,
  children,
  min_width,
  padding,
  font_size,
  disabled
}) => {
  return (
    <ButtonStyled
      type={type}
      onClick={onClick}
      min_width={min_width}
      padding={padding}
      font_size={font_size}
      $disabled={disabled}>
      {children}
    </ButtonStyled>
  );
};
