import { styled as styling } from '@mui/material/styles';
import { TableHead } from '@mui/material';
import { TableRow } from '@mui/material';
import { TableContainer } from '@mui/material';
import Colors from '../../../../styles/colors.module.scss';

export const Text = styling('p')((props) => ({
  fontFamily: 'Inter',

  ...(props.table_content == 'true' && {
    fontSize: '16px',  
    margin: '0',
    color: Colors.priBlack,
  }),

  ...(props.table_header == 'true' && {
    fontSize: '16px',  
    fontWeight: 700,
    margin: '0',
    textTransform: 'capitalize',
    color: Colors.terDarkGrey,
  }),

  ...(props.empty_result == 'true' && {
    fontSize: '22px',  
    fontWeight: 700,
    margin: '0',
    textTransform: 'capitalize',
  }),

  ...(props.job_slot_redirect == 'true' && {
    fontSize: '25px',  
    margin: '0',
    textAlign: 'center',
  }),

  ...(props.back_button == 'true' && {
    fontSize: '20px',  
    margin: '0',
    fontWeight: '300',

    '&:hover':{
      borderBottom: `1px solid ${Colors.priBlack}`,
      cursor: 'pointer',
    }
  }),

  ...(props.available_count == 'true' && {
    fontSize: '42px',  
    fontWeight: 700,
    color: Colors.priPurple,
    margin: '0',
  }),

  ...(props.available_count_text == 'true' && {
    fontSize: '20px',  
    fontWeight: 500,
    color: Colors.priPurple,
    margin: '0',
  }),
  ...(props.renew_inactive_jobs == 'true' && {
    fontSize: '24px',  
    fontWeight: 700,
    margin: '0',
  }),
  ...(props.subtitle == 'true' && {
    fontSize: '16px',  
  }),
  ...(props.card_title == 'true' && {
    fontSize: '16px',  
    fontWeight: 500,
    margin: '0',
  }),
  ...(props.card_desc == 'true' && {
    fontSize: '12px',  
    fontWeight: 400,
    margin: '0',
    color: Colors.terDarkGrey,
    maxWidth: '90%',
  }),
  ...(props.applicant_count == 'true' && {
    fontSize: '20px',  
    fontWeight: 700,
    margin: '0',
    color: Colors.priPurple,
  }),
  ...(props.applicant_text == 'true' && {
    fontSize: '12px',  
    fontWeight: 400,
    margin: '0',
    color: Colors.terDarkGrey,
  }),

  ...(props.clear == 'true' && {
    fontSize: '14px',  
    margin: '0',
    fontWeight: 400,
    color: Colors.terDarkGrey,

    '&:hover':{
      textDecoration: 'underline',
      cursor: 'pointer',
    }
  }),

  ...(props.confirmation == 'true' && {
    fontSize: '25px',  
    fontWeight: 700,
    margin: '0',
    color: Colors.priBlack,
  }),

  ...(props.message == 'true' && {
    fontSize: '16px',  
    fontWeight: 400,
    margin: '0',
    color: Colors.terDarkGrey,
  }),

  ...(props.info == 'true' && {
    fontSize: '16px',  
    fontWeight: 400,
    margin: '0',
    color: Colors.priBlack,
  }),
}));

export const TableRowStyled = styling(TableRow)((props) => ({
  background: Colors.priWhite,
  boxShadow: 'none',
}));

export const TableContainerStyled = styling(TableContainer)((props) => ({
  boxShadow: 'none',
  padding: '20px 40px',
  minHeight: '63vh',
  height: '50vh',

  animation: 'fadeInAnimation ease 1s',
  animationIterationCount: 1,
  animationFillMode: 'forwards',

  '@keyframes fadeInAnimation': {
    '0%':{
      opacity: '0',
    },
    '100%':{
      opacity: '1',
    },
  }
}));

export const ColumnTitleContainer = styling('div')((props) => ({
  display: 'flex',
  position: 'relative',
  '&:hover': {
    cursor: props.hide_cursor == 'true'? 'default' : 'pointer',
  }
}));

export const SpinnerContainer = styling('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  minHeight: 'calc(80vh - 150px)',
});

export const TextContainer = styling('div')((props) => ({
  ...(props.empty_result == 'true' && {
    position: 'absolute',
    top: 'calc(50vh - 140px)',
    left: 'calc(50% - 20px)',
  }),
}));

export const TableHeadStyled = styling(TableHead)({
  position: 'sticky',
  top: '-20px',
  zIndex: 10,
});
