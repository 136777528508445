import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import {
  updateFirstScheduleInterviewModalStatus,
  updateInterviewData,
  updateNextScheduleInterviewModalStatus
} from '../../redux/actions/application_action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  MainContainer,
  IconContainer,
  FontAwesomeIconStyled,
  Text,
  InterviewLengthContainer,
  GetAppIconStyled,
  ChevronLeftIconStyled,
  ChevronRightIconStyled,
  EmailIconStyled
} from './styles';
import { JOB_APPLICATION_STATUS } from '../../utils/Constants';

const FloatingToolbar = ({
  handleNextIcon,
  handlePreviousIcon,
  saveFile,
  handleFullscreenModal,
  openFullscreenModal,
  isSelected,
  candidates,
  pageInfo,
  triggerCandidateEmail,
  onApplicationClicked,
  candidate,
  updateFirstScheduleInterviewModalStatus,
  updateNextScheduleInterviewModalStatus,
  updateInterviewData
}) => {
  const handleScheduleInterviewIcon = () => {
    updateInterviewData(candidate);

    if (candidate?.interviews?.length > 0) {
      updateNextScheduleInterviewModalStatus(true);
    } else {
      if (candidate?.state == JOB_APPLICATION_STATUS.REJECTED) {
        return;
      }

      updateFirstScheduleInterviewModalStatus(true, null);
    }
  };

  const getInterviewLength = interviews => {
    const remainder = interviews.length % 10;

    if (interviews.length > 0) {
      if (remainder == 1) {
        return interviews.length + `st`;
      } else if (remainder == 2) {
        return interviews.length + `nd`;
      } else if (remainder == 3) {
        return interviews.length + `rd`;
      } else {
        return interviews.length + `th`;
      }
    } else {
      return;
    }
  };

  return (
    <MainContainer>
      <div>
        <Tooltip
          title={
            <Text type={"tooltip_text"}>
              View in fullscreen
            </Text>
          }
          placement="bottom">
          <IconContainer id="list-view-fullscreen-button">
            <FontAwesomeIconStyled
              onClick={onApplicationClicked(candidate?.id)}
              icon={faExpand}
              icon_name="faExpand"
            />
          </IconContainer>
        </Tooltip>
      </div>
      <div style={{ padding: '5px 0' }}>
        <Tooltip
          title={
            <Text type={"tooltip_text"}>
              Email applicant
            </Text>
          }
          placement="bottom">
          <IconContainer id="list-email-applicant-button">
            <EmailIconStyled onClick={triggerCandidateEmail} />
          </IconContainer>
        </Tooltip>
      </div>
      <div>
        <Tooltip
          title={
            <Text type={"tooltip_text"}>
              Download resume
            </Text>
          }
          placement="bottom">
          <IconContainer id="list-download-resume-button">
            <GetAppIconStyled onClick={saveFile} />
          </IconContainer>
        </Tooltip>
      </div>
      <div style={{ position: 'relative' }}>
        {candidate?.interviews?.length > 0 && (
          <InterviewLengthContainer>
            <Text type="interview_length">
              {getInterviewLength(candidate?.interviews)}
            </Text>
          </InterviewLengthContainer>
        )}
        <Tooltip
          placement="bottom"
          title={
            <Text type={"tooltip_text"}>
              Schedule interview
            </Text>
          }>
          <IconContainer
            id="list-schedule-interview-button"
            onClick={handleScheduleInterviewIcon}
            custom_disabled={
              candidate?.state == JOB_APPLICATION_STATUS.REJECTED &&
              candidate?.interviews?.length == 0
            }>
            <FontAwesomeIcon
              icon={faCalendarAlt}
              style={
                candidate?.interviews?.length == 0
                  ? { fontSize: '18px', color: '#222222' }
                  : { fontSize: '18px', color: '#512acc' }
              }
            />
          </IconContainer>
        </Tooltip>
      </div>

      <div>
        <Tooltip
          placement="bottom"
          title={
            <Text type={"tooltip_text"}>
              Previous applicant
            </Text>
          }>
          <IconContainer>
            <ChevronLeftIconStyled
              onClick={handlePreviousIcon}
              disabled={isSelected == 0}
            />
          </IconContainer>
        </Tooltip>
      </div>
      <div>
        <Tooltip
          placement="bottom"
          title={
            <Text type={"tooltip_text"}>
              Next applicant
            </Text>
          }>
          <IconContainer>
            <ChevronRightIconStyled
              onClick={handleNextIcon}
              disabled={
                !pageInfo?.hasNextPage && isSelected == candidates?.length - 1
                  ? true
                  : false
              }
            />
          </IconContainer>
        </Tooltip>
      </div>
    </MainContainer>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    updateFirstScheduleInterviewModalStatus: bindActionCreators(
      updateFirstScheduleInterviewModalStatus,
      dispatch
    ),
    updateNextScheduleInterviewModalStatus: bindActionCreators(
      updateNextScheduleInterviewModalStatus,
      dispatch
    ),
    updateInterviewData: bindActionCreators(updateInterviewData, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FloatingToolbar);
