// UPDATE SELECTED JOB
export const UPDATE_SELECTED_JOB = 'UPDATE_SELECTED_JOB';

//UPDATE_CONTACT_SALES_POPUP
export const UPDATE_CONTACT_SALES_POPUP = 'UPDATE_CONTACT_SALES_POPUP';

// UPDATE_SUBJECT_TYPE (CONTACT US)
export const UPDATE_SUBJECT_TYPE = 'UPDATE_SUBJECT_TYPE';

//UPDATE_EXPIRY_DATES_POPUP
export const UPDATE_EXPIRY_DATES_POPUP = 'UPDATE_EXPIRY_DATES_POPUP';

//UPDATE_AVAILABLE_RESOURCES_CARD_INDEX
export const UPDATE_AVAILABLE_RESOURCES_CARD_INDEX =
  'UPDATE_AVAILABLE_RESOURCES_CARD_INDEX';

//FETCH_PACKAGE_SUBSCRIPTION_DETAILS
export const FETCH_PACKAGE_SUBSCRIPTION_DETAILS =
  'FETCH_PACKAGE_SUBSCRIPTION_DETAILS';
export const FETCH_PACKAGE_SUBSCRIPTION_DETAILS_SUCCEED =
  'FETCH_PACKAGE_SUBSCRIPTION_DETAILS_SUCCEED ';
export const FETCH_PACKAGE_SUBSCRIPTION_DETAILS_FAILED =
  'FETCH_PACKAGE_SUBSCRIPTION_DETAILS_FAILED';

// UPDATE_ANNOUNCEMENT_POPUP
export const UPDATE_ANNOUNCEMENT_POPUP = 'UPDATE_ANNOUNCEMENT_POPUP';

// UPDATE_ANNOUNCEMENT_BANNER
export const UPDATE_ANNOUNCEMENT_BANNER = 'UPDATE_ANNOUNCEMENT_BANNER';

// FETCH_ALL_SORTED_PACKAGE
export const FETCH_ALL_SORTED_PACKAGES = 'FETCH_ALL_SORTED_PACKAGES';
export const FETCH_ALL_SORTED_PACKAGES_SUCCEED =
  'FETCH_ALL_SORTED_PACKAGES_SUCCEED';
export const FETCH_ALL_SORTED_PACKAGES_FAILED =
  'FETCH_ALL_SORTED_PACKAGES_FAILED';

//FETCH_SORTED_JOB_SLOTS_AND_PREP_JOB_SLOTS_PACKAGES
export const FETCH_SORTED_JOB_SLOTS_AND_PREP_JOB_SLOTS_PACKAGES =
  'FETCH_SORTED_JOB_SLOTS_AND_PREP_JOB_SLOTS_PACKAGES';
export const FETCH_SORTED_JOB_SLOTS_AND_PREP_JOB_SLOTS_PACKAGES_SUCCEED =
  'FETCH_SORTED_JOB_SLOTS_AND_PREP_JOB_SLOTS_PACKAGES_SUCCEED';
export const FETCH_SORTED_JOB_SLOTS_AND_PREP_JOB_SLOTS_PACKAGES_FAILED =
  'FETCH_SORTED_JOB_SLOTS_AND_PREP_JOB_SLOTS_PACKAGES_FAILED';

//UPDATE_JOB_ACCESS_POPUP
export const UPDATE_JOB_ACCESS_POPUP = 'UPDATE_JOB_ACCESS_POPUP';

//UPDATE_VIRTUAL_INTERVIEW_POPUP
export const UPDATE_VIRTUAL_INTERVIEW_POPUP = 'UPDATE_VIRTUAL_INTERVIEW_POPUP';

//UPDATE_REFRESHER_JOB_POPUP
export const UPDATE_REFRESHER_JOB_POPUP = 'UPDATE_REFRESHER_JOB_POPUP';

//REFRESH_JOB
export const REFRESH_JOB = 'REFRESH_JOB';
export const REFRESH_JOB_SUCCEED = 'REFRESH_JOB_SUCCEED';
export const REFRESH_JOB_FAILED = 'REFRESH_JOB_FAILED';

//UPDATE_FEATURED_JOB_POPUP
export const UPDATE_FEATURED_JOB_POPUP = 'UPDATE_FEATURED_JOB_POPUP';

//FEATURE_JOB
export const FEATURE_JOB = 'FEATURE_JOB';
export const FEATURE_JOB_SUCCEED = 'FEATURE_JOB_SUCCEED';
export const FEATURE_JOB_FAILED = 'FEATURE_JOB_FAILED';

//UPDATE_DEACTIVATE_JOB_POPUP
export const UPDATE_DEACTIVATE_JOB_POPUP = 'UPDATE_DEACTIVATE_JOB_POPUP';

//UPDATE_ACTIVATE_JOB_POPUP
export const UPDATE_ACTIVATE_JOB_POPUP = 'UPDATE_ACTIVATE_JOB_POPUP';
export const UPDATE_ACTIVATE_JOB_OPTION_POPUP = 'UPDATE_ACTIVATE_JOB_OPTION_POPUP';
export const UPDATE_JOB_OPTION_POPUP = 'UPDATE_JOB_OPTION_POPUP';

//CHANGE_JOB_STATUS
export const CHANGE_JOB_STATUS = 'CHANGE_JOB_STATUS';
export const CHANGE_JOB_STATUS_SUCCEED = 'CHANGE_JOB_STATUS_SUCCEED';
export const CHANGE_JOB_STATUS_FAILED = 'CHANGE_JOB_STATUS_FAILED';

//FETCH_JOBS
export const FETCH_JOBS = 'FETCH_JOBS';
export const FETCH_JOBS_SUCCEED = 'FETCH_JOBS_SUCCEED';
export const FETCH_JOBS_FAILED = 'FETCH_JOBS_FAILED';

//UPDATE_ALL_JOBS
export const UPDATE_ALL_JOBS = 'UPDATE_ALL_JOBS';

//UPDATE_SELECTED_FILTER
export const UPDATE_SELECTED_FILTER = 'UPDATE_SELECTED_FILTER';
export const UPDATE_SELECTED_FILTER_VALUE = 'UPDATE_SELECTED_FILTER_VALUE';

//UPDATE_TOTAL_JOBS_COUNT
export const UPDATE_TOTAL_JOBS_COUNT = 'UPDATE_TOTAL_JOBS_COUNT';

//UPDATE_SEARCH_JOB_VALUE
export const UPDATE_SEARCH_JOB_VALUE = 'UPDATE_SEARCH_JOB_VALUE';

//UPDATE_JOBS_OFFSET
export const UPDATE_JOBS_OFFSET = 'UPDATE_JOBS_OFFSET';

//UPDATE_JOBS_CURRENT_PAGE
export const UPDATE_JOBS_CURRENT_PAGE = 'UPDATE_JOBS_CURRENT_PAGE';

//UPDATE_CHIP_STATUS_INDEX
export const UPDATE_CHIP_STATUS_INDEX = 'UPDATE_CHIP_STATUS_INDEX';

//UPDATE_EXPIRY_DATES_COLUMN_INDEX
export const UPDATE_EXPIRY_DATES_COLUMN_INDEX =
  'UPDATE_EXPIRY_DATES_COLUMN_INDEX';

//UPDATE_PRODUCT_TYPE_INDEX
export const UPDATE_PRODUCT_TYPE_INDEX = 'UPDATE_PRODUCT_TYPE_INDEX';

//UPDATE_PRODUCT_ACTIVATION_DATE_INDEX
export const UPDATE_PRODUCT_ACTIVATION_DATE_INDEX =
  'UPDATE_PRODUCT_ACTIVATION_DATE_INDEX';

//UPDATE_PRODUCT_EXPIRY_DATE_INDEX
export const UPDATE_PRODUCT_EXPIRY_DATE_INDEX =
  'UPDATE_PRODUCT_EXPIRY_DATE_INDEX';

//UPDATE_PRODUCT_DAYS_REMAINING_INDEX
export const UPDATE_PRODUCT_DAYS_REMAINING_INDEX =
  'UPDATE_PRODUCT_DAYS_REMAINING_INDEX';

//UPDATE_EXPIRY_DATES_POPUP_CONTENT_INDEX
export const UPDATE_EXPIRY_DATES_POPUP_CONTENT_INDEX =
  'UPDATE_EXPIRY_DATES_POPUP_CONTENT_INDEX';

//UPDATE_PRODUCT_NAME
export const UPDATE_SELECTED_PRODUCT_NAME = 'UPDATE_SELECTED_PRODUCT_NAME';
// RESET AVAILABLE RESOURCES
export const RESET_AVAILABLE_RESOURCES = 'RESET_AVAILABLE_RESOURCES';

//FETCH_ALL_INACTIVE_JOBS
export const FETCH_ALL_INACTIVE_JOBS = 'FETCH_ALL_INACTIVE_JOBS';
export const FETCH_ALL_INACTIVE_JOBS_SUCCEED =
  'FETCH_ALL_INACTIVE_JOBS_SUCCEED';
export const FETCH_ALL_INACTIVE_JOBS_FAILED = 'FETCH_ALL_INACTIVE_JOBS_FAILED';

//FETCH_SPOTLIGHTABLE_JOBS
export const FETCH_SPOTLIGHTABLE_JOBS = 'FETCH_SPOTLIGHTABLE_JOBS';
export const FETCH_SPOTLIGHTABLE_JOBS_SUCCEED = 'FETCH_SPOTLIGHTABLE_JOBS_SUCCEED';
export const FETCH_SPOTLIGHTABLE_JOBS_FAILED = 'FETCH_SPOTLIGHTABLE_JOBS_FAILED';

//FETCH_FEATURABLE_JOBS
export const FETCH_FEATURABLE_JOBS = 'FETCH_FEATURABLE_JOBS';
export const FETCH_FEATURABLE_JOBS_SUCCEED = 'FETCH_FEATURABLE_JOBS_SUCCEED';
export const FETCH_FEATURABLE_JOBS_FAILED = 'FETCH_FEATURABLE_JOBS_FAILED';

// FETCH REFRESHABLE JOBS
export const FETCH_REFRESHABLE_JOBS = 'FETCH_REFRESHABLE_JOBS';
export const FETCH_REFRESHABLE_JOBS_SUCCEED = 'FETCH_REFRESHABLE_JOBS_SUCCEED';
export const FETCH_REFRESHABLE_JOBS_FAILED = 'FETCH_REFRESHABLE_JOBS_FAILED';

// BULK SPOTLIGHTING JOBS
export const BULK_JOBS_SPOTLIGHTING = 'BULK_JOBS_SPOTLIGHTING';
export const BULK_SPOTLIGHT_JOBS_SUCCEED = 'BULK_SPOTLIGHT_JOBS_SUCCEED';
export const BULK_SPOTLIGHT_JOBS_FAILED = 'BULK_SPOTLIGHT_JOBS_FAILED';

//FETCH_BULK_FEATURE_JOBS
export const BULK_JOBS_FEATURING = 'BULK_JOBS_FEATURING';
export const BULK_FEATURE_JOBS_SUCCEED = 'BULK_FEATURE_JOBS_SUCCEED';
export const BULK_FEATURE_JOBS_FAILED = 'BULK_FEATURE_JOBS_FAILED';

// BULK REFRESHING JOBS
export const BULK_JOBS_REFRESHING = 'BULK_JOBS_REFRESHING';
export const BULK_REFRESH_JOBS_SUCCEED = 'BULK_REFRESH_JOBS_SUCCEED';
export const BULK_REFRESH_JOBS_FAILED = 'BULK_REFRESH_JOBS_FAILED';

//UPDATE_FEATURE_TOKENS_COUNT
export const UPDATE_FEATURE_TOKENS_COUNT = 'UPDATE_FEATURE_TOKENS_COUNT';

//UPDATE_CROSS_BORDER_TOKENS_COUNT
export const UPDATE_CROSS_BORDER_TOKENS_COUNT = 'UPDATE_CROSS_BORDER_TOKENS_COUNT';

//UPDATE_REFRESHER_TOKENS_COUNT
export const UPDATE_REFRESHER_TOKENS_COUNT = 'UPDATE_REFRESHER_TOKENS_COUNT';

//UPDATE_SPOTLIGHT_TOKENS_COUNT
export const UPDATE_SPOTLIGHT_TOKENS_COUNT = 'UPDATE_SPOTLIGHT_TOKENS_COUNT';

//UPDATE_POST_JOB_POPUP
export const UPDATE_POST_JOB_POPUP = 'UPDATE_POST_JOB_POPUP';

//FETCH_JOB_TITLE_SUGGESTIONS
export const FETCH_JOB_TITLE_SUGGESTIONS = 'FETCH_JOB_TITLE_SUGGESTIONS';
export const FETCH_JOB_TITLE_SUGGESTIONS_SUCCEED =
  'FETCH_JOB_TITLE_SUGGESTIONS_SUCCEED';
export const FETCH_JOB_TITLE_SUGGESTIONS_FAILED =
  'FETCH_JOB_TITLE_SUGGESTIONS_FAILED';
export const UPDATE_JOB_TITLE_SUGGESTIONS_DROPDOWN_STATUS =
  'UPDATE_JOB_TITLE_SUGGESTIONS_DROPDOWN_STATUS';

//UPDATE_JOB_TITLE_SUGGESTIONS
export const UPDATE_JOB_TITLE_SUGGESTIONS = 'UPDATE_JOB_TITLE_SUGGESTIONS';

//UPDATE_JOB_SKILLS
export const UPDATE_JOB_SKILLS = 'UPDATE_JOB_SKILLS';

//UPDATE_JOB_KEYWORDS
export const UPDATE_JOB_KEYWORDS = 'UPDATE_JOB_KEYWORDS';
export const UPDATE_TEMP_JOB = 'UPDATE_TEMP_JOB';


//FETCH_JOB_SKILLS_SUGGESTIONS
export const FETCH_JOB_SKILLS_SUGGESTIONS = 'FETCH_JOB_SKILLS_SUGGESTIONS';
export const FETCH_JOB_SKILLS_SUGGESTIONS_SUCCEED =
  'FETCH_JOB_SKILLS_SUGGESTIONS_SUCCEED';
export const FETCH_JOB_SKILLS_SUGGESTIONS_FAILED =
  'FETCH_JOB_SKILLS_SUGGESTIONS_FAILED';

//UPDATE JOB SKILL SEARCH
export const UPDATE_JOB_SKILL_SEARCH = 'UPDATE_JOB_SKILL_SEARCH';

// UPDATE_JOB_SKILL_DROPDOWN_STATUS
export const UPDATE_JOB_SKILL_DROPDOWN_STATUS =
  'UPDATE_JOB_SKILL_DROPDOWN_STATUS';

//UPDATE_POST_JOB_TOOLTIPS_STATUS
export const UPDATE_POST_JOB_TOOLTIPS_STATUS =
  'UPDATE_POST_JOB_TOOLTIPS_STATUS';
export const UPDATE_POST_JOB_PREV_TOOLTIP = 'UPDATE_POST_JOB_PREV_TOOLTIP';

//UPDATE_KEYWORD_HIGHLIGHT_POPUP_STATUS
export const UPDATE_KEYWORD_HIGHLIGHT_POPUP_STATUS =
  'UPDATE_KEYWORD_HIGHLIGHT_POPUP_STATUS';

//UPDATE_SPOTLIGHT_JOB_CONFIRMATION_POPUP
export const UPDATE_SPOTLIGHT_JOB_CONFIRMATION_POPUP =
  'UPDATE_SPOTLIGHT_JOB_CONFIRMATION_POPUP';

//UPDATE_SPOTLIGHT_JOB_CONFIRMATION_POPUP
export const UPDATE_CROSS_BORDER_CONFIRMATION_POPUP =
  'UPDATE_CROSS_BORDER_CONFIRMATION_POPUP';

//UPDATE_KEYWORD_HIGHLIGHT_CONFIRMATION_POPUP
export const UPDATE_KEYWORD_HIGHLIGHT_CONFIRMATION_POPUP =
  'UPDATE_KEYWORD_HIGHLIGHT_CONFIRMATION_POPUP';

//SPOTLIGHT_JOB
export const SPOTLIGHT_JOB = 'SPOTLIGHT_JOB';
export const SPOTLIGHT_JOB_SUCCEED = 'SPOTLIGHT_JOB_SUCCEED';
export const SPOTLIGHT_JOB_FAILED = 'SPOTLIGHT_JOB_FAILED';

// KEYWORD HIGHLIGHT JOB
export const KEYWORD_HIGHLIGHT_JOB = 'KEYWORD_HIGHLIGHT_JOB';
export const KEYWORD_HIGHLIGHT_JOB_SUCCEED = 'KEYWORD_HIGHLIGHT_JOB_SUCCEED';
export const KEYWORD_HIGHLIGHT_JOB_FAILED = 'KEYWORD_HIGHLIGHT_JOB_FAILED';
export const UPDATE_KEYWORD_HIGHLIGHT_TEXTS = 'UPDATE_KEYWORD_HIGHLIGHT_TEXTS';

//UPDATE_JOB
export const UPDATE_JOB = 'UPDATE_JOB';

//UPDATE_SPECIALISATION_POPOVER_STATUS
export const UPDATE_SPECIALISATION_POPOVER_STATUS =
  'UPDATE_SPECIALISATION_POPOVER_STATUS';

//UPDATE_SPECIALISATION_SUB_CATEGORIES
export const UPDATE_SPECIALISATION_SUB_CATEGORIES =
  'UPDATE_SPECIALISATION_SUB_CATEGORIES';

//FETCH_SPECIALISAION
export const FETCH_SPECIALISAION = 'FETCH_SPECIALISAION';
export const FETCH_SPECIALISAION_SUCCEED = 'FETCH_SPECIALISAION_SUCCEED';
export const FETCH_SPECIALISAION_FAILED = 'FETCH_SPECIALISAION_FAILED';

//UPDATE_ASHLEY_REWRITE_POPUP
export const UPDATE_ASHLEY_REWRITE_POPUP = 'UPDATE_ASHLEY_REWRITE_POPUP';
export const UPDATE_ASHLEY_REWRITE_ORIGIN = 'UPDATE_ASHLEY_REWRITE_ORIGIN';

// GET_ASHLEY_TEXT_REWRITE
export const FETCH_ASHLEY_TEXT_REWRITE = 'GET_ASHLEY_TEXT_REWRITE';
export const FETCH_ASHLEY_TEXT_REWRITE_SUCCEED =
  'FETCH_ASHLEY_TEXT_REWRITE_SUCCEED';
export const FETCH_ASHLEY_TEXT_REWRITE_FAILED =
  'FETCH_ASHLEY_TEXT_REWRITE_FAILED';

//UPDATE_JOB_DATA_FOR_ASHLEY_REWRITE
export const UPDATE_JOB_DATA_FOR_ASHLEY_REWRITE =
  'UPDATE_JOB_DATA_FOR_ASHLEY_REWRITE';

//UPDATE_TMP_EDITOR_STATE
export const UPDATE_TMP_EDITOR_STATE = 'UPDATE_TMP_EDITOR_STATE';

//UPDATE_OLD_EDITOR_STATE
export const UPDATE_OLD_EDITOR_STATE = 'UPDATE_OLD_EDITOR_STATE';

//UPDATE_ASHLEY_REWRITE_MESSAGE
export const UPDATE_ASHLEY_REWRITE_MESSAGE = 'UPDATE_ASHLEY_REWRITE_MESSAGE';

//UPDATE_JOB_DATA
export const UPDATE_JOB_DATA = 'UPDATE_JOB_DATA';

//FETCH_JOB
export const FETCH_JOB = 'FETCH_JOB';
export const FETCH_JOB_SUCCEED = 'FETCH_JOB_SUCCEED';
export const FETCH_JOB_FAILED = 'FETCH_JOB_FAILED';

//FETCH_ALL_JOBS
export const FETCH_ALL_JOBS = 'FETCH_ALL_JOBS';
export const FETCH_ALL_JOBS_SUCCEED = 'FETCH_ALL_JOBS_SUCCEED';
export const FETCH_ALL_JOBS_FAILED = 'FETCH_ALL_JOBS_FAILED';

//UPDATE_ASHLEY_EDIT_FEEDBACK
export const UPDATE_ASHLEY_EDIT_FEEDBACK = 'UPDATE_ASHLEY_EDIT_FEEDBACK';

//ASHLEY_EDIT_FEEDBACK_FAILED
export const ASHLEY_EDIT_FEEDBACK = 'ASHLEY_EDIT_FEEDBACK';
export const ASHLEY_EDIT_FEEDBACK_SUCCEED = 'ASHLEY_EDIT_FEEDBACK_SUCCEED';
export const ASHLEY_EDIT_FEEDBACK_FAILED = 'ASHLEY_EDIT_FEEDBACK_FAILED';

// FETCH_DEACTIVATE_REASONS
export const FETCH_DEACTIVATE_REASONS = 'FETCH_DEACTIVATE_REASONS';
export const FETCH_DEACTIVATE_REASONS_SUCCEED =
  'FETCH_DEACTIVATE_REASONS_SUCCEED';
export const FETCH_DEACTIVATE_REASONS_FAILED =
  'FETCH_DEACTIVATE_REASONS_FAILED';

// FETCH_PERSONALIZED_QUESTIONS
export const FETCH_PERSONALIZED_QUESTIONS = 'FETCH_PERSONALIZED_QUESTIONS';
export const FETCH_PERSONALIZED_QUESTIONS_SUCCEED =
  'FETCH_PERSONALIZED_QUESTIONS_SUCCEED';
export const FETCH_PERSONALIZED_QUESTIONS_FAILED =
  'FETCH_PERSONALIZED_QUESTIONS_FAILED';

// FETCH_JOB_PERSONALIZED_QUESTIONS
export const FETCH_JOB_PERSONALIZED_QUESTIONS =
  'FETCH_JOB_PERSONALIZED_QUESTIONS';
export const FETCH_JOB_PERSONALIZED_QUESTIONS_SUCCEED =
  'FETCH_JOB_PERSONALIZED_QUESTIONS_SUCCEED';
export const FETCH_JOB_PERSONALIZED_QUESTIONS_FAILED =
  'FETCH_JOB_PERSONALIZED_QUESTIONS_FAILED';

// FETCH_DRAFT_JOB
export const FETCH_DRAFT_JOB = 'FETCH_DRAFT_JOB';
export const FETCH_DRAFT_JOB_SUCCEED = 'FETCH_DRAFT_JOB_SUCCEED';
export const FETCH_DRAFT_JOB_FAILED = 'FETCH_DRAFT_JOB_FAILED';

// FETCH_JOB_TEAM_MEMBERS
export const FETCH_JOB_TEAM_MEMBERS = 'FETCH_JOB_TEAM_MEMBERS';
export const FETCH_JOB_TEAM_MEMBERS_SUCCEED = 'FETCH_JOB_TEAM_MEMBERS_SUCCEED';
export const FETCH_JOB_TEAM_MEMBERS_FAILED = 'FETCH_JOB_TEAM_MEMBERS_FAILED';

// FETCH_TEAM_MEMBERS
export const FETCH_TEAM_MEMBERS = 'FETCH_TEAM_MEMBERS';
export const FETCH_TEAM_MEMBERS_SUCCEED = 'FETCH_TEAM_MEMBERS_SUCCEED';
export const FETCH_TEAM_MEMBERS_FAILED = 'FETCH_TEAM_MEMBERS_FAILED';

// MUTATE_VIRTUAL_INTERVIEW_QUESTIONS
export const MUTATE_VIRTUAL_INTERVIEW_QUESTIONS =
  'MUTATE_VIRTUAL_INTERVIEW_QUESTIONS';
export const MUTATE_VIRTUAL_INTERVIEW_QUESTIONS_SUCCEED =
  'MUTATE_VIRTUAL_INTERVIEW_QUESTIONS_SUCCEED';
export const MUTATE_VIRTUAL_INTERVIEW_QUESTIONS_FAILED =
  'MUTATE_VIRTUAL_INTERVIEW_QUESTIONS_FAILED';

// MUTATE_JOB_ACCESS
export const MUTATE_JOB_ACCESS = 'MUTATE_JOB_ACCESS';
export const MUTATE_JOB_ACCESS_SUCCEED = 'MUTATE_JOB_ACCESS_SUCCEED';
export const MUTATE_JOB_ACCESS_FAILED = 'MUTATE_JOB_ACCESS_FAILED';

// MUTATE_GLOBAL_HIRE
export const MUTATE_GLOBAL_HIRE = 'MUTATE_GLOBAL_HIRE';
export const MUTATE_GLOBAL_HIRE_SUCCEED = 'MUTATE_GLOBAL_HIRE_SUCCEED';
export const MUTATE_GLOBAL_HIRE_FAILED = 'MUTATE_GLOBAL_HIRE_FAILED';

// MUTATE_GLOBAL_HIRE
export const MUTATE_CROSS_BORDER = 'MUTATE_CROSS_BORDER';
export const MUTATE_CROSS_BORDER_SUCCEED = 'MUTATE_CROSS_BORDER_SUCCEED';
export const MUTATE_CROSS_BORDER_FAILED = 'MUTATE_CROSS_BORDER_FAILED';

// MUTATE_JOB_APPLICATION_STATE
export const MUTATE_JOB_APPLICATION_STATE = 'MUTATE_JOB_APPLICATION_STATE';
export const MUTATE_JOB_APPLICATION_STATE_SUCCEED =
  'MUTATE_JOB_APPLICATION_STATE_SUCCEED';
export const MUTATE_JOB_APPLICATION_STATE_FAILED =
  'MUTATE_JOB_APPLICATION_STATE_FAILED';

// MUTATE_VIEWED_JOB_APPLICATION
export const MUTATE_VIEWED_JOB_APPLICATION = 'MUTATE_VIEWED_JOB_APPLICATION';
export const MUTATE_VIEWED_JOB_APPLICATION_SUCCEED =
  'MUTATE_VIEWED_JOB_APPLICATION_SUCCEED';
export const MUTATE_VIEWED_JOB_APPLICATION_FAILED =
  'MUTATE_VIEWED_JOB_APPLICATION_FAILED';

// MUTATE UPDATE JOB
export const MUTATE_UPDATE_JOB = 'MUTATE_UPDATE_JOB';
export const MUTATE_UPDATE_JOB_SUCCEED = 'MUTATE_UPDATE_JOB_SUCCEED';
export const MUTATE_UPDATE_JOB_FAILED = 'MUTATE_UPDATE_JOB_FAILED';

// MUTATE CREATE JOB
export const MUTATE_CREATE_JOB = 'MUTATE_CREATE_JOB';
export const MUTATE_CREATE_JOB_SUCCEED = 'MUTATE_CREATE_JOB_SUCCEED';
export const MUTATE_CREATE_JOB_FAILED = 'MUTATE_CREATE_JOB_FAILED';

// MUTATE CREATE DRAFT JOB
export const MUTATE_CREATE_DRAFT_JOB = 'MUTATE_CREATE_DRAFT_JOB';
export const MUTATE_CREATE_DRAFT_JOB_SUCCEED =
  'MUTATE_CREATE_DRAFT_JOB_SUCCEED';
export const MUTATE_CREATE_DRAFT_JOB_FAILED = 'MUTATE_CREATE_DRAFT_JOB_FAILED';

// UPDATE DRAFT JOB STATE
export const UPDATE_DRAFT_JOB_STATE = 'UPDATE_DRAFT_JOB_STATE';

export const UPDATE_JOB_FORM_DATA_STATE = 'UPDATE_JOB_FORM_DATA_STATE';

// CLEAR JOB INFO
export const CLEAR_JOB_INFO = 'CLEAR_JOB_INFO';

export const POST_JOB_FEEDBACK = 'POST_JOB_FEEDBACK';
export const POST_JOB_FEEDBACK_SUCCEED = 'POST_JOB_FEEDBACK_SUCCEED';
export const POST_JOB_FEEDBACK_FAILED = 'POST_JOB_FEEDBACK_FAILED';

export const TALENT_SEARCH_FEEDBACK = 'TALENT_SEARCH_FEEDBACK';
export const TALENT_SEARCH_FEEDBACK_SUCCEED = 'TALENT_SEARCH_FEEDBACK_SUCCEED';
export const TALENT_SEARCH_FEEDBACK_FAILED = 'TALENT_SEARCH_FEEDBACK_FAILED';




