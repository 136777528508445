import { styled as styling } from '@mui/material/styles';
import BannerBg from '../../../../assets/images/background/bannerbg.png';
import Colors from '../../../../styles/colors.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TitleContainer = styling('div')({
  padding: '16px 24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const Title = styling('span')({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '24px',
  color: 'rgba(0, 0, 0, 0.87)',
});

export const ContentWrapper = styling('div')({
  padding: '0 0 16px 0',
  width: '464px',
  background: 'white',
});

export const ContentContainer = styling('div')({
  padding: '15px',
  margin: '10px 24px 0 24px',
  height: '144px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  backgroundImage: `url(${BannerBg})`,
  backgroundSize: 'cover',
  borderRadius: '8px',
});

export const PreviousPrice = styling('span')({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '20px',
  textDecorationLine: 'line-through',
});

export const CurrentPriceContainer = styling('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const CurrentPrice = styling('span')({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '24px',
  color: 'rgba(0, 0, 0, 0.87)',
});

export const PriceSaved = styling('span')({
  position: 'relative',
  marginLeft: '16px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '20px',
  color: 'rgba(0, 0, 0, 0.87)',
  background: '#fbe7bf',
  borderRadius: '100px',
  padding: '2px 10px',

  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '100px',
    border: '1px solid transparent',
    background: 'linear-gradient(90deg, rgba(242,175,41,1) 0%, rgba(242,175,41,0.01) 50%, rgba(235,176,48,1) 100%)',
    '-webkit-mask': 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
    '-webkit-mask-composite': 'destination-out',
    maskComposite: 'exclude',
  },
});

export const TermConditionTitle = styling('span')({
  marginTop: '8px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '20px',
  color: 'rgba(0, 0, 0, 0.87)',
});

export const TermConditionInfoWrapper = styling('ul')({
  marginTop: '0px',
  listStyleType: 'decimal',
  paddingLeft: '18px',
});

export const TermConditionInfo = styling('li')({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '20px',
  color: 'rgba(0, 0, 0, 0.87)',
});

export const FontAwesomeIconStyled = styling(FontAwesomeIcon)({
  color: '#f2af29',
  fontSize: '12px',
  marginRight: '8px',
});

export const ButtonContainer = styling('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  marginTop: '24px',
});
