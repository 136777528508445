import moment from 'moment';
import * as types from '../types/talent_type';

const initialState = {
  talentsColumnRef: null,
  isProfilePopupOpen: false,
  isUnlockProfilePopupOpen: false,
  fetchingTalents: false,
  feedbackTalent: {},
  talents: [],
  talent: {},
  talentsPageInfo: {},
  bookmarkingTalent: false,
  bookmarkingTalentIds: {},
  unbookmarkingTalentIds: {},
  hidingProfile: false,
  modelState: false,
  modelName: '',
  searchResumeTalentsCurrentPage: 1,
  talentsTotalCount: 0,
  talentsFilterNoResult: false,
  triggeredSearch: false,
  searchedKeyword: '',
  lastRefreshed: '',
  searchTalentFilters: {
    after: '',
    filterMode: 'or',
    keyword: '',
    wordMatch: 'word',
    advancedSearch: true,
    trackIds: [],
    specialisations: [],
    specialisationIds: [],
    industryIds: [],
    nationalities: [],
    currentLocation: [],
    relocate: false,
    remote: false,
    openToSponsor: false,
    visaOrEmploymentPass: false,
    localCitizen: false,
    withVideoCoverLetter: false,
    freshGrad: null,
    minGraduationYear: moment().year() - 100,
    maxGraduationYear: moment().year(),
    sort: null,
    educationLevels: [],
    languages: [],
    resumeLastUpdated: null,
    minYearExperience: 0,
    showHiddenProfile: false
  },
  defaultFilter: {
    advancedSearch: true,
    after: '',
    currentLocation: [],
    educationLevels: [],
    filterMode: 'or',
    freshGrad: null,
    industryIds: [],
    keyword: '',
    languages: [],
    localCitizen: false,
    maxGraduationYear: moment().year(),
    minGraduationYear: moment().year() - 100,
    minYearExperience: 0,
    nationalities: [],
    openToSponsor: false,
    relocate: false,
    remote: false,
    resumeLastUpdated: null,
    retryOption: { retry_time: 0 },
    showHiddenProfile: false,
    sort: null,
    specialisationIds: [],
    specialisations: [],
    trackIds: [],
    visaOrEmploymentPass: false,
    withVideoCoverLetter: false,
    wordMatch: 'word'
  },
  expandFilters: {
    resumeLastUpdated: true,
    jobSpecialisation: true,
    companyIndustry: true,
    language: true,
    highestEducationLevel: true
  },
  // FOR BOOKMARKED TALENTS
  bookmarkedTalents: [],
  bookmarkedTalentsTotalCount: 0,
  bookmarkedTalentsPageInfo: {},
  bookmarkedEndCursors: { 1: '' },
  bookmarkedTalentsCurrentPage: 1,
  fetchingBookmarkedTalents: false,
  doneFetchingBookmarkedTalents: false,
  bookmarkedFilterNoResult: false,
  bookmarkedCount: 0,
  bookmarkedTalentFilters: {
    after: '',
    filterMode: 'or',
    keyword: '',
    wordMatch: 'word',
    bookmarkedDate: null,
    bookmarkedByIds: [],
    sort: {
      by: 'created_at',
      direction: 'desc'
    }
  },
  // DONE FOR BOOKMARKED TALENTS
  // FOR UNLOCKED TALENTS
  unlockedTalents: [],
  unlockedTalentsTotalCount: 0,
  unlockedTalentsPageInfo: {},
  unlockedTalentsCurrentPage: 1,
  fetchingUnlockedTalents: false,
  doneFetchingUnlockedTalents: false,
  unlockedFilterNoResult: false,
  unlockedCount: 0,
  unlockedTalentFilters: {
    after: '',
    filterMode: 'or',
    keyword: '',
    wordMatch: 'word',
    unlockedDate: null,
    unlockedByIds: [],
    sort: {
      by: 'created_at',
      direction: 'desc'
    }
  },
  // DONE FOR UNLOCKED TALENTS
  hideNoResultFilter: false,
  // FOR UNLOCKING TALENT
  unlockingResume: false,
  unlockingTalentIds: {},
  // DONE FOR UNLOCKED TALENT

  // FOR VIEW PROFILE POP UP
  profilePopUp: false,
  individualTalentDetails: {},
  fetchingResume: false,
  resume: {},

  // NOTES
  companyResumeNotes: [],
  fetchingNotes: false,

  //Chat
  checkingChatRoom: false,
  channelsUnreadMessageCount: [],

  //Insufficient coins
  isInsufficientCoinsBannerShow: false,

  //For Talent Search FAQ
  talentSearchFAQPopup: false,

  //Talent search errors reporting
  reportingInfoError: false,
  isErrorReportingOpen: true,

  //Talent search info tab
  tabValue: 'profile',

  //Fetch talents old
  searchTalentsOld: [],
  fetchingSearchTalentsOld: false,
  searchTalentsOldPageInfo: [],
  searchTalentsOldCount: 0,

  //Mutate view talent
  mutatingViewTalent: false,
  viewTalent: {},

  submitFeedback: false,
  makeNewSearch: false,

  feedbackForm: {
    rating: '',
    feedback: ''
  },
  feedbackPage: '0'
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_TALENTS_COLUMN_REF: {
      return {
        ...state,
        talentsColumnRef: action.talentsColumnRef
      };
    }
    case types.UPDATE_UNLOCK_PROFILE_STATUS: {
      return {
        ...state,
        isUnlockProfilePopupOpen: action.status
      };
    }
    case types.UPDATE_HIDE_PROFILE_STATUS: {
      return {
        ...state,
        isProfilePopupOpen: action.status
      };
    }
    case types.FETCH_TALENTS: {
      return {
        ...state,
        fetchingTalents: true
      };
    }
    case types.FETCH_TALENTS_SUCCEED: {
      let cTalents = action.payload.talents.edges.map(item => item.node);
      let talentsPageInfo = { ...action.payload.talents.pageInfo };
      const cTotalCount = action.payload.talents.totalCount;

      const searchTalentsFilter = action.payload.searchTalentsFilter;
      let cTalentsFilterNoResult = state.talentsFilterNoResult;

      if (
        (searchTalentsFilter.educationLevels.length > 0 ||
          searchTalentsFilter.freshGrad != null ||
          searchTalentsFilter.industryIds.length > 0 ||
          searchTalentsFilter.keyword.length > 0 ||
          searchTalentsFilter.languages.length > 0 ||
          searchTalentsFilter.minYearExperience > 0 ||
          searchTalentsFilter.nationalities.length > 0 ||
          searchTalentsFilter.specialisations.length > 0) &&
        state.searchResumeTalentsCurrentPage == 1 &&
        action.payload.talents.edges.length <= 0
      ) {
        // Filter has been applied
        cTalentsFilterNoResult = true;
      }

      return {
        ...state,
        fetchingTalents: false,
        talents: [...cTalents],
        talentsPageInfo: talentsPageInfo,
        talentsTotalCount: cTotalCount,
        talentsFilterNoResult: cTalentsFilterNoResult
      };
    }
    case types.FETCH_TALENTS_FAILED: {
      return {
        ...state,
        fetchingTalents: false
      };
    }
    case types.UPDATE_SEARCH_RESUME_CURRENT_PAGE: {
      return {
        ...state,
        searchResumeTalentsCurrentPage: action.page
      };
    }
    case types.CLEAR_TALENTS_PARAMS: {
      return {
        ...state,
        talents: [],
        talentsTotalCount: 0,
        talentsPageInfo: {},
        searchResumeTalentsCurrentPage: 1,
        fetchingTalents: false,
        talentsFilterNoResult: false,
        triggeredSearch: false,
        searchedKeyword: '',
        hideNoResultFilter: false
      };
    }

    // FOR BOOKMARKED TALENTS
    case types.FETCH_BOOKMARKED_TALENTS: {
      return {
        ...state,
        fetchingBookmarkedTalents: true
      };
    }
    case types.FETCH_BOOKMARKED_TALENTS_SUCCEED: {
      const cBookmarkedTalents = action.payload.bookmarkedTalents.edges.map(
        item => {
          let resume = { ...item.node?.user };
          resume['resumeId'] = item.node?.id;
          return resume;
        }
      );

      const cBookmarkedTalentsPageInfo = {
        ...action.payload.bookmarkedTalents.pageInfo
      };

      const cTotalCount = action.payload.bookmarkedTalents.totalCount;
      const bookmarkedFilter = action.payload.filterParams;
      const pageKey = bookmarkedFilter.page + 1;
      let cEndCursors = { ...state.bookmarkedEndCursors };
      cEndCursors[`${pageKey}`] = cBookmarkedTalentsPageInfo.endCursor;

      let cBookmarkedFilterNoResult = state.bookmarkedFilterNoResult;

      if (
        (bookmarkedFilter.sort.direction != 'desc' ||
          bookmarkedFilter.bookmarkedDate ||
          bookmarkedFilter.bookmarkedByIds?.length > 0) &&
        state.bookmarkedTalentsCurrentPage == 1 &&
        cBookmarkedTalents?.length == 0
      ) {
        // Filter has been applied
        cBookmarkedFilterNoResult = true;
      }

      return {
        ...state,
        fetchingBookmarkedTalents: false,
        doneFetchingBookmarkedTalents: true,
        bookmarkedTalents: [...cBookmarkedTalents],
        bookmarkedTalentsPageInfo: cBookmarkedTalentsPageInfo,
        bookmarkedTalentsTotalCount: cTotalCount,
        bookmarkedFilterNoResult: cBookmarkedFilterNoResult,
        bookmarkedEndCursors: cEndCursors
      };
    }
    case types.FETCH_BOOKMARKED_TALENTS_FAILED: {
      return {
        ...state,
        fetchingBookmarkedTalents: false,
        doneFetchingBookmarkedTalents: true
      };
    }
    case types.UPDATE_FETCH_BOOKMARKED_TALENTS_STATUS: {
      return {
        ...state,
        doneFetchingBookmarkedTalents: action.status
      };
    }
    case types.UPDATE_BOOKMARKED_CURRENT_PAGE: {
      return {
        ...state,
        bookmarkedTalentsCurrentPage: action.page
      };
    }
    case types.UPDATE_BOOKMARKED_TALENTS_FILTER: {
      return {
        ...state,
        bookmarkedTalentFilters: action.filters
      };
    }
    case types.UPDATE_BOOKMARKED_COUNT: {
      return {
        ...state,
        bookmarkedCount: action.count
      };
    }
    case types.CLEAR_BOOKMARKED_TALENTS_FILTER: {
      return {
        ...state,
        bookmarkedTalentFilters: {
          after: '',
          filterMode: 'or',
          keyword: '',
          wordMatch: 'word',
          bookmarkedDate: null,
          bookmarkedByIds: [],
          sort: {
            by: 'created_at',
            direction: 'desc'
          }
        }
      };
    }
    case types.CLEAR_BOOKMARKED_TALENTS_PARAMS: {
      return {
        ...state,
        bookmarkedTalents: [],
        bookmarkedTalentsTotalCount: 0,
        bookmarkedTalentsPageInfo: {},
        bookmarkedTalentsCurrentPage: 1,
        bookmarkedEndCursors: { 1: '' },
        fetchingBookmarkedTalents: false,
        doneFetchingBookmarkedTalents: false,
        bookmarkedFilterNoResult: false,
        bookmarkedTalentFilters: {
          after: '',
          filterMode: 'or',
          keyword: '',
          wordMatch: 'word',
          bookmarkedDate: null,
          bookmarkedByIds: [],
          sort: {
            by: 'created_at',
            direction: 'desc'
          }
        },
        hideNoResultFilter: false
      };
    }
    // DONE FOR BOOKMARKED TALENTS
    // FOR UNLOCKED TALENTS
    case types.FETCH_UNLOCKED_TALENTS: {
      return {
        ...state,
        fetchingUnlockedTalents: true
      };
    }
    case types.FETCH_UNLOCKED_TALENTS_SUCCEED: {
      const cUnlockedTalents = action.payload.unlockedTalents.edges.map(
        item => {
          let resume = { ...item.node?.user };
          resume['resumeId'] = item.node?.bookmarkedResumeId;

          return resume;
        }
      );
      const cUnlockedTalentsPageInfo = {
        ...action.payload.unlockedTalents.pageInfo
      };

      const cTotalCount = action.payload.unlockedTalents.totalCount;
      const unlockedFilter = action.payload.filterParams;
      let cUnlockedFilterNoResult = state.unlockedFilterNoResult;

      if (
        (unlockedFilter.sort.direction != 'desc' ||
          unlockedFilter.unlockedDate ||
          unlockedFilter.unlockedByIds?.length > 0) &&
        state.unlockedTalentsCurrentPage == 1 &&
        cUnlockedTalents?.length == 0
      ) {
        // Filter has been applied
        cUnlockedFilterNoResult = true;
      }

      return {
        ...state,
        fetchingUnlockedTalents: false,
        doneFetchingUnlockedTalents: true,
        unlockedTalents: [...cUnlockedTalents],
        unlockedTalentsPageInfo: cUnlockedTalentsPageInfo,
        unlockedTalentsTotalCount: cTotalCount,
        unlockedFilterNoResult: cUnlockedFilterNoResult
      };
    }
    case types.FETCH_UNLOCKED_TALENTS_FAILED: {
      return {
        ...state,
        doneFetchingUnlockedTalents: true,
        fetchingUnlockedTalents: false
      };
    }
    case types.UPDATE_FETCH_UNLOCKED_TALENTS_STATUS: {
      return {
        ...state,
        doneFetchingUnlockedTalents: action.status
      };
    }
    case types.UPDATE_UNLOCKED_CURRENT_PAGE: {
      return {
        ...state,
        unlockedTalentsCurrentPage: action.page
      };
    }
    case types.UPDATE_UNLOCKED_TALENTS_FILTER: {
      return {
        ...state,
        unlockedTalentFilters: action.filters
      };
    }
    case types.UPDATE_UNLOCKED_COUNT: {
      return {
        ...state,
        unlockedCount: action.count
      };
    }
    case types.CLEAR_UNLOCKED_TALENTS_FILTER: {
      return {
        ...state,
        unlockedTalentFilters: {
          after: '',
          filterMode: 'or',
          keyword: '',
          wordMatch: 'word',
          unlockedDate: null,
          unlockedByIds: [],
          sort: {
            by: 'created_at',
            direction: 'desc'
          }
        }
      };
    }
    case types.CLEAR_UNLOCKED_TALENTS_PARAMS: {
      return {
        ...state,
        unlockedTalents: [],
        unlockedTalentsTotalCount: 0,
        unlockedTalentsPageInfo: {},
        unlockedTalentsCurrentPage: 1,
        fetchingUnlockedTalents: false,
        doneFetchingUnlockedTalents: false,
        unlockedFilterNoResult: false,
        unlockedTalentFilters: {
          after: '',
          filterMode: 'or',
          keyword: '',
          wordMatch: 'word',
          unlockedDate: null,
          unlockedByIds: [],
          sort: {
            by: 'created_at',
            direction: 'desc'
          }
        },
        hideNoResultFilter: false
      };
    }
    // DONE FOR UNLOCKED TALENTS
    case types.UPDATE_HIDE_NO_RESULT_FILTER_STATUS: {
      return {
        ...state,
        hideNoResultFilter: action.status
      };
    }
    case types.BOOKMARK_TALENT: {
      let talentIds = { ...state.bookmarkingTalentIds };
      action.ids.map(id => {
        talentIds[id] = 1;
      });

      return {
        ...state,
        bookmarkingTalent: true,
        bookmarkingTalentIds: talentIds
      };
    }
    case types.BOOKMARK_TALENT_SUCCEED: {
      const resumes = [...action.bookmarkedResumes];
      let talents = [...state.talents];
      let bookmarkedTalents = [...state.bookmarkedTalents];
      let unlockedTalents = [...state.unlockedTalents];
      let talentIds = { ...state.bookmarkingTalentIds };
      let talent = { ...state.talent };

      resumes.map(resume => {
        // search resume
        const talentIndex = talents.findIndex(item => {
          return item.id == resume.userId;
        });

        if (talentIndex > -1) {
          talents[talentIndex] = {
            resumeId: resume.userId,
            ...resume.user
          };
          talent = resume.user;
        }

        // bookmarked
        const bookmarkedTalentIndex = bookmarkedTalents.findIndex(item => {
          return item.id == resume.userId;
        });

        if (bookmarkedTalentIndex > -1) {
          bookmarkedTalents[bookmarkedTalentIndex] = {
            resumeId: resume.userId,
            ...resume.user
          };
          talent = resume.user;
        }

        // unlocked
        const unlockedTalentIndex = unlockedTalents.findIndex(item => {
          return item.id == resume.userId;
        });

        if (unlockedTalentIndex > -1) {
          unlockedTalents[unlockedTalentIndex] = {
            resumeId: resume.userId,
            ...resume.user
          };

          talent = resume.user;
        }

        // Remove id from bookmarkingTalentIds object
        delete talentIds[resume.userId];
      });

      // Plus resumes.length to the bookmarkedCount
      const cBookmarkedCount = state.bookmarkedCount + resumes.length;

      return {
        ...state,
        bookmarkingTalent: false,
        talents: talents,
        bookmarkedTalents: bookmarkedTalents,
        unlockedTalents: unlockedTalents,
        bookmarkingTalentIds: talentIds,
        bookmarkedCount: cBookmarkedCount,
        talent: talent
      };
    }
    case types.BOOKMARK_TALENT_FAILED: {
      let talentIds = { ...state.bookmarkingTalentIds };
      action.ids.map(id => delete talentIds[id]);

      return {
        ...state,
        bookmarkingTalent: false,
        bookmarkingTalentIds: talentIds
      };
    }
    // UNBOOKMARK TALENT
    case types.UNBOOKMARK_TALENT: {
      let talentIds = { ...state.unbookmarkingTalentIds };
      talentIds[action.id] = 1;

      return {
        ...state,
        unbookmarkingTalentIds: talentIds
      };
    }
    case types.UNBOOKMARK_TALENT_SUCCEED: {
      let talents = [...state.talents];
      let bookmarkedTalents = [...state.bookmarkedTalents];
      let unlockedTalents = [...state.unlockedTalents];
      let talent = { ...state.talent };

      // search resume
      const talentIndex = talents.findIndex(item => {
        return item.id == action.user.id;
      });

      if (talentIndex > -1) {
        talents[talentIndex] = action.user;
        talent = action.user;
      }

      // bookmarked
      const bookmarkedTalentIndex = bookmarkedTalents.findIndex(item => {
        return item.id == action.user.id;
      });

      if (bookmarkedTalentIndex > -1) {
        bookmarkedTalents[bookmarkedTalentIndex] = action.user;
        talent = action.user;
      }

      // unlocked
      const unlockedTalentIndex = unlockedTalents.findIndex(item => {
        return item.id == action.user.id;
      });

      if (unlockedTalentIndex > -1) {
        unlockedTalents[unlockedTalentIndex] = action.user;
        talent = action.user;
      }

      // Remove id from unbookmarkingTalentIds object
      let talentIds = { ...state.unbookmarkingTalentIds };
      delete talentIds[action.user.id];

      // Minus one to the bookmarkedCount
      const cBookmarkedCount = state.bookmarkedCount - 1;

      return {
        ...state,
        bookmarkingTalent: false,
        talents: talents,
        bookmarkedTalents: bookmarkedTalents,
        unlockedTalents: unlockedTalents,
        unbookmarkingTalentIds: talentIds,
        bookmarkedCount: cBookmarkedCount,
        talent: talent
      };
    }
    case types.UNBOOKMARK_TALENT_FAILED: {
      let talentIds = { ...state.unbookmarkingTalentIds };
      delete talentIds[action.id];

      return {
        ...state,
        unbookmarkingTalentIds: talentIds
      };
    }
    // END FOR UNBOOKMARK TALENT
    case types.UNLOCK_RESUME: {
      let talentIds = { ...state.unlockingTalentIds };
      talentIds[action.id] = 1;

      return {
        ...state,
        unlockingResume: true,
        unlockingTalentIds: talentIds
      };
    }
    case types.UNLOCK_RESUME_SUCCEED: {
      let cUser = {
        ...action.user,
        isPriceChanged: action.isPriceChanged
      };

      let talents = [...state.talents];
      let bookmarkedTalents = [...state.bookmarkedTalents];
      let unlockedTalents = [...state.unlockedTalents];

      // search resume
      const talentIndex = talents.findIndex(item => {
        return item.id == action.user.id;
      });

      if (talentIndex > -1) {
        talents[talentIndex] = cUser;
      }

      // bookmarked
      const bookmarkedTalentIndex = bookmarkedTalents.findIndex(item => {
        return item.id == action.user.id;
      });

      if (bookmarkedTalentIndex > -1) {
        bookmarkedTalents[bookmarkedTalentIndex] = cUser;
      }

      // unlocked
      const unlockedTalentIndex = unlockedTalents.findIndex(item => {
        return item.id == action.user.id;
      });

      if (unlockedTalentIndex > -1) {
        unlockedTalents[unlockedTalentIndex] = cUser;
      }

      // Remove id from unlockingTalentIds object
      let talentIds = { ...state.unlockingTalentIds };
      delete talentIds[action.user.id];

      // Plus one to the unlockedCount
      const cUnlockedCount = state.unlockedCount + 1;

      return {
        ...state,
        unlockingResume: false,
        talents: talents,
        bookmarkedTalents: bookmarkedTalents,
        unlockedTalents: unlockedTalents,
        unlockingTalentIds: talentIds,
        unlockedCount: cUnlockedCount,
        talent: cUser
      };
    }
    case types.UNLOCK_RESUME_FAILED: {
      let talentIds = { ...state.unlockingTalentIds };
      delete talentIds[action.id];

      return {
        ...state,
        unlockingResume: false,
        unlockingTalentIds: talentIds
      };
    }
    case types.HIDE_PROFILE: {
      return {
        ...state,
        hidingProfile: true
      };
    }
    case types.HIDE_PROFILE_SUCCEED: {
      let talents = [...state.talents];

      const talentIndex = talents.findIndex(item => {
        return item.id == action.userId;
      });

      if (talentIndex > -1) {
        talents[talentIndex].hidden = true;
      }

      return {
        ...state,
        hidingProfile: false,
        talents: talents
      };
    }
    case types.HIDE_PROFILE_FAILED: {
      return {
        ...state,
        hidingProfile: false
      };
    }
    case types.UPDATE_SEARCH_TALENT_FILTERS: {
      return {
        ...state,
        searchTalentFilters: action.filters
      };
    }
    case types.UPDATE_MODAL_STATE: {
      return {
        ...state,
        modelState: action.state
      };
    }
    case types.UPDATE_MODAL_NAME: {
      return {
        ...state,
        modelName: action.name
      };
    }
    case types.UPDATE_PROFILE_POP_UP: {
      return {
        ...state,
        profilePopUp: action.state
      };
    }
    case types.UPDATE_CURRENT_TALENT: {
      return {
        ...state,
        talent: action.talent
      };
    }
    case types.UPDATE_COLLAPSIBLE_FILTER_STATUS: {
      return {
        ...state,
        expandFilters: action.expandFilters
      };
    }
    case types.UPDATE_KEYWORD_SEARCHED_STATUS: {
      return {
        ...state,
        triggeredSearch: action.payload.status,
        searchedKeyword: action.payload.keyword
      };
    }
    case types.UPDATE_NOTES: {
      return {
        ...state,
        companyResumeNotes: action.companyResumeNotes
      };
    }
    case types.RETRIEVE_NOTES: {
      return {
        ...state,
        fetchingNotes: action.state
      };
    }
    case types.DOWNLOAD_RESUME: {
      return {
        ...state,
        fetchingResume: true
      };
    }
    case types.DOWNLOAD_RESUME_SUCCEED: {
      return {
        ...state,
        resume: { ...action.resume },
        fetchingResume: false
      };
    }
    case types.DOWNLOAD_RESUME_FAILED: {
      return {
        ...state,
        fetchingResume: false
      };
    }
    case types.CHECK_INITIATED_CHAT_ROOM: {
      return {
        ...state,
        checkingChatRoom: true
      };
    }
    case types.CHECK_INITIATED_CHAT_ROOM_FAILED: {
      return {
        ...state,
        checkingChatRoom: false
      };
    }
    case types.CREATE_COMPANY_RESUME_CHAT: {
      return {
        ...state,
        creatingCompanyResumeChat: true
      };
    }

    case types.CREATE_COMPANY_RESUME_CHAT_SUCCEED: {
      return {
        ...state,
        talent: { ...action.user },
        creatingCompanyResumeChat: false
      };
    }
    case types.CREATE_COMPANY_RESUME_CHAT_FAILED: {
      return {
        ...state,
        creatingCompanyResumeChat: false
      };
    }
    case types.UPDATE_INSUFFICIENT_COINS_BANNER: {
      return {
        ...state,
        isInsufficientCoinsBannerShow: action.status
      };
    }
    case types.UPDATE_SPECIFIC_TALENT_IN_LIST: {
      const cTalent = { ...action.talent };

      let cTalents = [...state.talents];
      let cBookmarkedTalents = [...state.bookmarkedTalents];
      let cUnlockedTalents = [...state.unlockedTalents];

      // for resume talent
      let index = state.talents.findIndex(talent => talent.id == cTalent.id);

      if (index != -1) {
        cTalents[index] = cTalent;
      }

      // for bookmarked talents
      index = state.bookmarkedTalents.findIndex(
        talent => talent.id == cTalent.id
      );
      if (index != -1) {
        cBookmarkedTalents[index] = cTalent;
      }

      // for unlocked talents
      index = state.unlockedTalents.findIndex(
        talent => talent.id == cTalent.id
      );
      if (index != -1) {
        cUnlockedTalents[index] = cTalent;
      }

      return {
        ...state,
        talent: cTalent ? { ...cTalent } : state.talent,
        talents: cTalents,
        bookmarkedTalents: cBookmarkedTalents,
        unlockedTalents: cUnlockedTalents
      };
    }
    case types.UPDATE_TALENT_SEARCH_FAQ_POPUP: {
      return {
        ...state,
        talentSearchFAQPopup: action.status
      };
    }
    case types.UPDATE_TALENT_CHANNELS_UNREAD_MESSAGE_COUNT: {
      return {
        ...state,
        channelsUnreadMessageCount: action.content
      };
    }
    case types.REPORT_INFO_ERRORS: {
      return {
        ...state,
        reportingInfoError: true
      };
    }

    case types.REPORT_INFO_ERRORS_SUCCEED: {
      let cTalents = [...state.talents];
      let cBookmarkedTalents = [...state.bookmarkedTalents];
      let cUnlockedTalents = [...state.unlockedTalents];

      // search resume
      let talentIndex = cTalents.findIndex(item => {
        return item.id == action.user.id;
      });

      if (talentIndex > -1) {
        cTalents[talentIndex] = action.user;
      }

      // bookmarked
      const bookmarkedTalentIndex = cBookmarkedTalents.findIndex(item => {
        return item.id == action.user.id;
      });

      if (bookmarkedTalentIndex > -1) {
        cBookmarkedTalents[bookmarkedTalentIndex] = action.user;
      }

      // unlocked
      const unlockedTalentIndex = cUnlockedTalents.findIndex(item => {
        return item.id == action.user.id;
      });

      if (unlockedTalentIndex > -1) {
        cUnlockedTalents[unlockedTalentIndex] = action.user;
      }

      return {
        ...state,
        reportingInfoError: false,
        talents: cTalents,
        bookmarkedTalents: cBookmarkedTalents,
        unlockedTalents: cUnlockedTalents,
        talent: action.user
      };
    }
    case types.REPORT_INFO_ERRORS_FAILED: {
      return {
        ...state,
        reportingInfoError: false
      };
    }
    case types.UPDATE_ERROR_REPORTING_STATUS: {
      return {
        ...state,
        isErrorReportingOpen: action.status
      };
    }
    case types.UPDATE_TALENT_INFO_TAB_VALUE: {
      return {
        ...state,
        tabValue: action.tabValue
      };
    }
    // fetching old talents
    case types.FETCH_TALENTS_OLD: {
      return {
        ...state,
        fetchingSearchTalentsOld: true
      };
    }
    case types.FETCH_TALENTS_OLD_SUCCEED: {
      return {
        ...state,
        fetchingSearchTalentsOld: false,
        searchTalentsOld: action.searchTalents,
        searchTalentsOldPageInfo: action.pageInfo,
        searchTalentsOldCount: action.count
      };
    }
    case types.FETCH_TALENTS_OLD_FAILED: {
      return {
        ...state,
        fetchingSearchTalentsOld: false
      };
    }

    // MUTATE_VIEW_TALENT
    case types.MUTATE_VIEW_TALENT: {
      return {
        ...state,
        mutatingViewTalent: true
      };
    }
    case types.MUTATE_VIEW_TALENT_SUCCEED: {
      return {
        ...state,
        mutatingViewTalent: false,
        viewTalent: action.viewTalent
      };
    }
    case types.MUTATE_VIEW_TALENT_FAILED: {
      return {
        ...state,
        mutatingViewTalent: false
      };
    }

    case types.UPDATE_FEEDBACK_CARD_STATUS: {
      return {
        ...state,
        submitFeedback: action.status
      };
    }

    case types.UPDATE_NEW_SEARCH: {
      return {
        ...state,
        makeNewSearch: action.status
      };
    }

    case types.UPDATE_TALENT_LIST: {
      return {
        ...state,
        feedbackTalent: action.list
      };
    }

    case types.UPDATE_FEEDBACK_FORM: {
      return {
        ...state,
        feedbackForm: {
          ...action.payload,
        },
      };
    }

    case types.UPDATE_FEEDBACK_PAGE: {
      return {
        ...state,
        feedbackPage: action.status
      };
    }

    default: {
      return state;
    }
  }
};
