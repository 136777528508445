import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, PopUpDialog } from '../../../../components';
import styles from './index.module.scss';
import { MUIButtonStyled } from '../../../../components/MUIButton';
import { updateTempJobDraft } from '../../../../redux/actions/job_action';

const LeaveJobEditingPopUp = ({
  open,
  handleLeave,
  handleClose,
  setPreventReloadOrLeave
}) => {
  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const fromPage = new URLSearchParams(location.search).get('redirect');

  const onClickLeave = () => {
    dispatch(updateTempJobDraft([]));
    setTimeout(() => {
      if (fromPage) {
        history(`/${fromPage}`);
        return;
      }

      history('/jobs');
    }, 500);
  };

  return (
    <PopUpDialog
      open={open}
      handleClose={handleClose}
      title="Leave Job Editing Page?">
      <div style={{ padding: '20px 20px' }}>
        <div className={styles.contentContainer}>
          <p className={styles.leaveJobDescription}>
            Once you leave this page, unsaved changes may be lost. Make sure to
            save your changes to draft before leaving this page.
          </p>
        </div>
        <div className={styles.buttonContainer}>
          <MUIButtonStyled
            label="Leave"
            onClick={() => onClickLeave()}
            outlinedBlack
            large
          />
          <MUIButtonStyled label="Keep Editing" large onClick={handleClose} />
        </div>
      </div>
    </PopUpDialog>
  );
};

export default LeaveJobEditingPopUp;
