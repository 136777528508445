import React, { useEffect, useState } from 'react';
import ContentCard from '../components/ContentCard';
import { useDispatch, useSelector } from 'react-redux';
import ImageCard from './components/ImageCard';
import {
  deleteCompanyOfficeImage,
  fetchCompanyProfile,
  mutateCompanyProfile,
  readyFileUpload,
  updateCompanySidebar
} from '../../../redux/actions/company_actions';
import { ErrorToast, SuccessToast } from '../../../utils/ToastUtils';
import { Grid, ImageList } from '@mui/material';
import { SupportedFileText } from './styles';
import SaveButton from '../components/SaveButton';
import { isEmpty, isEqual } from 'lodash';
import { RECORD_TYPE } from '../../../utils/Constants';
import moment from 'moment';
import { AlertStyled, SolidInformationIcon } from '../CoverImage/styles';

export default function EditState(props) {
  const { setView, setInfo } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const companyProfile = useSelector(state => state.companies.companyProfile);
  const companyCompletion = useSelector(
    state => state.companies.companyCompletion
  );
  const [imageList, setImageList] = useState();
  const [imageListOriginal, setImageListOriginal] = useState();

  useEffect(() => {
    const restructed =
      companyProfile?.images?.map((image, index) => {
        return { ...image, add: false, sortOrder: index + 1 };
      }) || [];

    const emptySlots = Array(8).fill({ publicUrl: '' });
    const mergedList = [...restructed, ...emptySlots.slice(restructed.length)];

    setImageList(mergedList);
    setImageListOriginal(mergedList);
    onResetAdd();
  }, [companyProfile]);

  const hasChanges = () => {
    const changes = imageList?.some(image => {
      return image?.changes === true;
    });

    const urlList = imageList?.map(image => {
      return image?.publicUrl;
    });

    const urlListOriginal = imageListOriginal?.map(image => {
      return image?.publicUrl;
    });

    const noChanges = isEqual(urlList, urlListOriginal);

    return changes || !noChanges;
  };

  const moveImage = (dragIndex, hoverIndex) => {
    const list = [...imageList];
    const [draggedImage] = list.splice(dragIndex, 1);
    list.splice(hoverIndex, 0, draggedImage);
    setImageList(list);
  };

  const constructFileName = file => {
    const cTimeStamp = moment.utc().valueOf().toString();
    return `company-malaysia-${
      companyProfile?.slug ? companyProfile?.slug.substring(0, 30) + '-' : ''
    }${cTimeStamp}-${
      file?.name?.toLowerCase()?.includes('png') ? 'png' : 'jpeg'
    }`;
  };


  const onResetAdd = () => {
    setImageList(prevVideos => {
      const updatedVideos = prevVideos.map(video => ({
        ...video,
        add: false
      }));

      const videosWithContentLength = updatedVideos.filter(
        video => !isEmpty(video.publicUrl)
      ).length;

      const allSlotsFilledUp = prevVideos.every(imageObj => imageObj.publicUrl);

      if (allSlotsFilledUp) {
        return updatedVideos;
      }

      const addStateVideos = [...updatedVideos];
      addStateVideos[videosWithContentLength] = {
        ...addStateVideos[videosWithContentLength],
        add: true
      };

      return addStateVideos;
    });
  };

  const onChangeRemove = (id, publicUrl) => {
    // if (!publicUrl?.includes('blob')) {
    //   dispatch(deleteCompanyOfficeImage({ officeImageIds: id })).then(res => {
    //     //
    //   });
    // }

    let updatedImageList = imageList
      .map(imageObj => {
        if (imageObj.id === id) {
          return { add: false, id: imageObj?.id, _destroy: true };
        }
        return imageObj;
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);

    // Sort the images with content
    const sortedImage = [...updatedImageList];
    const ImageWithContent = sortedImage.filter(
      image => !isEmpty(image?.publicUrl)
    );
    const sortedImageWithContent = ImageWithContent.concat(
      sortedImage.filter(image => isEmpty(image?.publicUrl))
    );

    const newList = sortedImageWithContent.map(sorted => ({
      ...sorted,
      add: false
    }));

    // change the add status
    const imageWithContentLength = newList.filter(
      image => !isEmpty(image?.publicUrl)
    ).length;

    const addStateImage = [...newList];
    addStateImage[imageWithContentLength] = {
      ...addStateImage[imageWithContentLength],
      add: true
    };

    setImageList(addStateImage);
  };

  const onChangeImage = async id => {
    try {
      // Open the file picker dialog
      const [fileHandle] = await window.showOpenFilePicker();
      const file = await fileHandle.getFile();

      const fileSize = file.size;
      const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
      const maxWidth = 2500;
      const maxHeight = 2500;

      if (fileSize > maxSizeInBytes) {
        ErrorToast(
          'File size too large. Please select a file smaller than 2MB.'
        );
        return; // Reject the image upload
      }

      // Find the index of the first object with add: true
      const emptySlotIndex = imageList.findIndex(
        imageObj => imageObj.id === id
      );

      if (emptySlotIndex !== -1) {
        const newImageObj = {
          id: Date.now().toString(),
          publicUrl: URL.createObjectURL(file),
          file: file,
          add: false,
          changes: true
        };

        // Create a new array with the updated image object
        const updatedImageList = [
          ...imageList.slice(0, emptySlotIndex),
          newImageObj,
          ...imageList.slice(emptySlotIndex + 1)
        ];

        // Update the imageList state with the new array
        setImageList(updatedImageList);
      } else {
        console.error('No empty slot found to add the image.');
      }
    } catch (err) {
      console.error('Error opening file:', err);
    }
  };

  const onChangeAdd = async event => {
    try {
      // Find the index of the first object with add: true
      const emptySlotIndex = imageList.findIndex(imageObj => imageObj.add);

      // Open the file picker dialog
      const file = event.target.files[0];

      const fileSize = file.size;
      const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
      const maxWidth = 2500;
      const maxHeight = 2500;

      if (fileSize > maxSizeInBytes) {
        ErrorToast(
          'File size too large. Please select a file smaller than 2MB.'
        );
        return; // Reject the image upload
      }

      if (emptySlotIndex !== -1) {
        const newImageObj = {
          id: Date.now().toString(),
          publicUrl: URL.createObjectURL(file),
          file: file,
          add: false,
          changes: true
        };

        // Create a new array with the updated image object
        const updatedImageList = [
          ...imageList.slice(0, emptySlotIndex),
          newImageObj,
          ...imageList.slice(emptySlotIndex + 1)
        ];

        // Update the imageList state with the new array
        setImageList(updatedImageList);
        onResetAdd();
      } else {
        console.error('No empty slot found to add the image.');
      }
    } catch (err) {
      console.error('Error opening file:', err);
    }
  };

  const onSendtoAPI = imageList => {
    // Remove the id if its newly added
    const addedList = imageList?.map(image => {
      const { id, ...rest } = image;
      if (id?.length > 8) {
        return rest;
      }
      return image;
    });

    // Filter out object with empty publicUrl
    const filteredList = addedList?.filter(image => {
      return !isEmpty(image?.publicUrl) || image?._destroy;
    });

    // Sort them based on sortOrder
    const sorting = filteredList?.map((image, index) => {
      return { ...image, sortOrder: index + 1, cover: false };
    });

    dispatch(
      mutateCompanyProfile({
        input: {
          images: sorting
        }
      })
    )
      .then(res => {
        if (res.type === 'FETCH_COMPANY_PROFILE_SUCCEED') {
          SuccessToast('Company Images Updated Successfully!');
          dispatch(
            updateCompanySidebar({
              ...companyCompletion,
              companyImages: true
            })
          );
          setView(true);
        } else {
          ErrorToast(res.errorMessage);
        }

        window.dataLayer.push({
          event: 'save-cp-office'
        });
      })
      .catch(err => {
        ErrorToast(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSave = async () => {
    try {
      setLoading(true);
      const uploadPromises = imageList.map(async (image, index) => {
        if (image?.id?.length > 6) {
          const id = image?.id;
          const constructedFileName = constructFileName(image?.file);
          const res = await dispatch(
            readyFileUpload({
              input: {
                filename: constructedFileName,
                recordType: RECORD_TYPE.COMPANY_IMAGE
              }
            })
          );
          const data = res?.prepareFileUpload;

          if (data) {
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', data?.presignedUrl, true);
            xhr.setRequestHeader('Content-Type', image?.file?.type);
            xhr.onload = () => {
              const publicUrl = data?.publicUrl;
              return { ...image, publicUrl };
            };

            return new Promise((resolve, reject) => {
              xhr.onload = () => resolve(xhr.response);
              xhr.onerror = () => reject(xhr.statusText);
              xhr.send(image?.file);
            }).then(response => {
              const publicUrl = data?.publicUrl;
              return { ...image, publicUrl };
            });
          }
        }

        return image;
      });

      const updatedImageList = await Promise.all(uploadPromises);
      setImageList(updatedImageList);
      onSendtoAPI(updatedImageList);
    } catch (error) {
      ErrorToast(error);
    }
  };

  return (
    <ContentCard
      title="Company Photos"
      subTitle="Upload up to 8 photos that best represent your company office and culture."
      infoIcon
      padding={'0px'}
      cancelIcon={!isEmpty(companyProfile?.images)}
      discardButton
      noChanges={!hasChanges()}
      infoOnClick={() => setInfo(true)}
      cancelOnClick={() => setView(true)}>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          alignItems: 'center',
          marginBottom: '16px'
        }}>
        <AlertStyled
          sx={{ width: '100%', borderRadius: '0px', fontFamily: 'inter' }}
          icon={<SolidInformationIcon />}
          onClick={() => setInfo(true)}>
          <span style={{ letterSpacing: '0.15px' }}>
            Please upload at least 3 photos. Photos are displayed in the same
            order that they appear here.
          </span>
        </AlertStyled>
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Grid
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 2fr)',
              gap: '8px',
              rowGap: '8px'
            }}>
            {imageList
              ?.sort((a, b) => {
                return (a.sortOrder = b.sortOrder);
              })
              ?.map((image, index) => {
                return (
                  <ImageCard
                    key={image?.id}
                    index={index}
                    image={image}
                    moveImage={moveImage}
                    onChangeAdd={onChangeAdd}
                    onChangeRemove={onChangeRemove}
                    onChangeImage={onChangeImage}
                  />
                );
              })}
          </Grid>
          <SupportedFileText>
            Supported file type: .png, .jpg, .jpeg | File size: less than 2 MB
          </SupportedFileText>
        </Grid>
        <SaveButton
          disabled={
            loading ||
            !hasChanges() ||
            imageList?.filter(image => {
              return image?.publicUrl;
            })?.length < 3
          }
          onClick={onSave}>
          Save
        </SaveButton>
      </Grid>
    </ContentCard>
  );
}
