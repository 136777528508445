import React from 'react';
import { TitleStyled, Wrapper, TextStyled } from './styles';
import { ReactComponent as Empty } from '../../../assets/images/acv/empty.svg';

export const EmptyStates = props => {
  const { type } = props;

  return (
    <Wrapper
      container
      justifyContent="center"
      alignItems="center"
      direction="row">
      <Wrapper content>
        <Empty />
      </Wrapper>
      <Wrapper content container direction="column">
        <TitleStyled>You have no {type} Profiles </TitleStyled>
        <TextStyled>
          Start your recruitment journey now by searching and get <br /> access
          to our Talent pool
        </TextStyled>
      </Wrapper>
    </Wrapper>
  );
};
