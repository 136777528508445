// DELETE PHOTO POPUP
export const OPEN_DELETE_PHOTO_POPUP = 'OPEN_DELETE_PHOTO_POPUP';

// OFFICE IMAGE GUIDE POPUP
export const OPEN_OFFICE_IMAGE_GUIDE_POPUP = 'OPEN_OFFICE_IMAGE_GUIDE_POPUP';
export const DELETING_COMPANY_OFFICE_IMAGE = 'DELETING_COMPANY_OFFICE_IMAGE';
export const DELETE_COMPANY_IMAGE_SUCCEEDED = 'DELETE_COMPANY_IMAGE_SUCCEEDED';
export const DELETE_COMPANY_IMAGE_FAILED = 'DELETE_COMPANY_IMAGE_FAILED';

//UPDATE_IMAGE_MODAL_STATUS
export const UPDATE_IMAGE_MODAL_STATUS = 'UPDATE_IMAGE_MODAL_STATUS';

//FETCH_MY_COMPANY_SETTINGS
export const FETCH_MY_COMPANY_SETTINGS = 'FETCH_MY_COMPANY_SETTINGS';
export const FETCH_MY_COMPANY_SETTINGS_SUCCEED =
  'FETCH_MY_COMPANY_SETTINGS_SUCCEED';
export const FETCH_MY_COMPANY_SETTINGS_FAILED =
  'FETCH_MY_COMPANY_SETTINGS_FAILED';

//FETCH_ACCOUNT_INFO
export const FETCH_ACCOUNT_INFO = 'FETCH_ACCOUNT_INFO';
export const FETCH_ACCOUNT_INFO_SUCCEED = 'FETCH_ACCOUNT_INFO_SUCCEED';
export const FETCH_ACCOUNT_INFO_FAILED = 'FETCH_ACCOUNT_INFO_FAILED';
export const CLEAR_ACCOUNT_INFO = 'CLEAR_ACCOUNT_INFO';

//FETCH_MY_COMPANY_STATUS
export const FETCH_MY_COMPANY_STATUS = 'FETCH_MY_COMPANY_STATUS';
export const FETCH_MY_COMPANY_STATUS_SUCCEED =
  'FETCH_MY_COMPANY_STATUS_SUCCEED';
export const FETCH_MY_COMPANY_STATUS_FAILED = 'FETCH_MY_COMPANY_STATUS_FAILED';

//FETCH_INDUSTRIES
export const FETCH_INDUSTRIES = 'FETCH_INDUSTRIES';
export const FETCH_INDUSTRIES_SUCCEED = 'FETCH_INDUSTRIES_SUCCEED';
export const FETCH_INDUSTRIES_FAILED = 'FETCH_INDUSTRIES_FAILED';

export const UPDATE_COMPANY_AVAILABLE_COINS = 'UPDATE_COMPANY_AVAILABLE_COINS';

export const UPDATE_TEAM_MEMBER_SPECS_SUCCESS =
  'UPDATE_TEAM_MEMBER_SPECS_SUCCESS';

//FETCH_COMPANY_SIZES
export const FETCH_COMPANY_SIZES = 'FETCH_COMPANY_SIZES';
export const FETCH_COMPANY_SIZES_SUCCEED = 'FETCH_COMPANY_SIZES_SUCCEED';
export const FETCH_COMPANY_SIZES_FAILED = 'FETCH_COMPANY_SIZES_FAILED';

//FETCH_COMPANY_PROFILE
export const FETCH_COMPANY_PROFILE = 'FETCH_COMPANY_PROFILE';
export const FETCH_COMPANY_PROFILE_SUCCEED = 'FETCH_COMPANY_PROFILE_SUCCEED';
export const FETCH_COMPANY_PROFILE_FAILED = 'FETCH_COMPANY_PROFILE_FAILED';

//FETCH_ICONS
export const FETCH_ICONS = 'FETCH_ICONS';
export const FETCH_ICONS_SUCCEED = 'FETCH_ICONS_SUCCEED';
export const FETCH_ICONS_FAILED = 'FETCH_ICONS_FAILED';

//FETCH_COMPANY_TEAM_MEMBERS
export const FETCH_COMPANY_TEAM_MEMBERS = 'FETCH_COMPANY_TEAM_MEMBERS';
export const FETCH_COMPANY_TEAM_MEMBERS_SUCCEED =
  'FETCH_COMPANY_TEAM_MEMBERS_SUCCEED';
export const FETCH_COMPANY_TEAM_MEMBERS_FAILED =
  'FETCH_COMPANY_TEAM_MEMBERS_FAILED';

// FETCH_EXPERIENCES
export const FETCH_EXPERIENCES = 'FETCH_EXPERIENCES';
export const FETCH_EXPERIENCES_SUCCEED = 'FETCH_EXPERIENCES_SUCCEED';
export const FETCH_EXPERIENCES_FAILED = 'FETCH_EXPERIENCES_FAILED';

// FETCH_JOB_TYPES
export const FETCH_JOB_TYPES = 'FETCH_JOB_TYPES';
export const FETCH_JOB_TYPES_SUCCEED = 'FETCH_JOB_TYPES_SUCCEED';
export const FETCH_JOB_TYPES_FAILED = 'FETCH_JOB_TYPES_FAILED';

// FETCH_LOCATIONS
export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const FETCH_LOCATIONS_SUCCEED = 'FETCH_LOCATIONS_SUCCEED';
export const FETCH_LOCATIONS_FAILED = 'FETCH_LOCATIONS_FAILED';

// FETCH_TRACKS
export const FETCH_TRACKS = 'FETCH_TRACKS';
export const FETCH_TRACKS_SUCCEED = 'FETCH_TRACKS_SUCCEED';
export const FETCH_TRACKS_FAILED = 'FETCH_TRACKS_FAILED';

// FETCH_CAREER_LEVEL
export const FETCH_CAREER_LEVEL = 'FETCH_CAREER_LEVEL';
export const FETCH_CAREER_LEVEL_SUCCEED = 'FETCH_CAREER_LEVEL_SUCCEED';
export const FETCH_CAREER_LEVEL_FAILED = 'FETCH_CAREER_LEVEL_FAILED';

// FETCH_ACCESS_LEVEL
export const FETCH_ACCESS_LEVEL = 'FETCH_ACCESS_LEVEL';
export const FETCH_ACCESS_LEVEL_SUCCEED = 'FETCH_ACCESS_LEVEL_SUCCEED';
export const FETCH_ACCESS_LEVEL_FAILED = 'FETCH_ACCESS_LEVEL_FAILED';

// FETCH_COMPANY_NAME
export const FETCH_COMPANY_NAME = 'FETCH_COMPANY_NAME';
export const FETCH_COMPANY_NAME_SUCCEED = 'FETCH_COMPANY_NAME_SUCCEED';
export const FETCH_COMPANY_NAME_FAILED = 'FETCH_COMPANY_NAME_FAILED';

// FETCH_COMPANY_AFFILIATIONS
export const FETCH_COMPANY_AFFILIATIONS = 'FETCH_COMPANY_AFFILIATIONS';
export const FETCH_COMPANY_AFFILIATIONS_SUCCEED =
  'FETCH_COMPANY_AFFILIATIONS_SUCCEED';
export const FETCH_COMPANY_AFFILIATIONS_FAILED =
  'FETCH_COMPANY_AFFILIATIONS_FAILED';

// FETCH_COMPANY_TYPES
export const FETCH_COMPANY_TYPES = 'FETCH_COMPANY_TYPES';
export const FETCH_COMPANY_TYPES_SUCCEED = 'FETCH_COMPANY_TYPES_SUCCEED';
export const FETCH_COMPANY_TYPES_FAILED = 'FETCH_COMPANY_TYPES_FAILED';

// MUTATE_COMPANY_SETTINGS
export const MUTATE_MY_COMPANY_SETTINGS = 'MUTATE_COMPANY_SETTINGS';

// PREAPARE FILE UPLOAD
export const PREPARE_FILE_UPLOAD = 'PREPARE_FILE_UPLOAD';
export const PREPARE_FILE_UPLOAD_SUCCEED = 'PREPARE_FILE_UPLOAD_SUCCEED';
export const PREPARE_FILE_UPLOAD_FAILED = 'PREPARE_FILE_UPLOAD_FAILED';

// MUTATE COMPANY PROFILE
export const MUTATE_COMPANY_PROFILE = 'MUTATE_COMPANY_PROFILE';

// MUTATE COMPANY DETAILS
export const MUTATE_COMPANY_DETAILS = 'MUTATE_COMPANY_PROFILE';
export const MUTATE_COMPANY_DETAILS_SUCCEED = 'MUTATE_COMPANY_DETAILS_SUCCEED';
export const MUTATE_COMPANY_DETAILS_FAILED = 'MUTATE_COMPANY_DETAILS_FAILED';

// MUTATE COMPANY TEAM MEMBER
export const MUTATE_COMPANY_TEAM_MEMBER = 'MUTATE_COMPANY_TEAM_MEMBER';
export const MUTATE_COMPANY_TEAM_MEMBER_SUCCEED =
  'MUTATE_COMPANY_TEAM_MEMBER_SUCCEED';
export const MUTATE_COMPANY_TEAM_MEMBER_FAILED =
  'MUTATE_COMPANY_TEAM_MEMBER_FAILED';

// MUTATE REMOVE TEAM MEMBER
export const MUTATE_REMOVE_TEAM_MEMBER = 'MUTATE_REMOVE_TEAM_MEMBER';
export const MUTATE_REMOVE_TEAM_MEMBER_SUCCEED =
  'MUTATE_REMOVE_TEAM_MEMBER_SUCCEED';
export const MUTATE_REMOVE_TEAM_MEMBER_FAILED =
  'MUTATE_REMOVE_TEAM_MEMBER_FAILED';

// MUTATE COMPANY AFFILIATION
export const MUTATE_COMPANY_AFFILIATION = 'MUTATE_COMPANY_AFFILIATION';
export const MUTATE_COMPANY_AFFILIATION_SUCCEED =
  'MUTATE_COMPANY_AFFILIATION_SUCCEED';
export const MUTATE_COMPANY_AFFILIATION_FAILED =
  'MUTATE_COMPANY_AFFILIATION_FAILED';

// MUTATE DELETE COMPANY AFFILIATION
export const MUTATE_DELETE_COMPANY_AFFILIATION =
  'MUTATE_DELETE_COMPANY_AFFILIATION';
export const MUTATE_DELETE_COMPANY_AFFILIATION_SUCCEED =
  'MUTATE_DELETE_COMPANY_AFFILIATION_SUCCEED';
export const MUTATE_DELETE_COMPANY_AFFILIATION_FAILED =
  'MUTATE_DELETE_COMPANY_AFFILIATION_FAILED';

// MUTATE UPDATE COMPANY AFFILIATION
export const MUTATE_UPDATE_COMPANY_AFFILIATION =
  'MUTATE_UPDATE_COMPANY_AFFILIATION';
export const MUTATE_UPDATE_COMPANY_AFFILIATION_SUCCEED =
  'MUTATE_UPDATE_COMPANY_AFFILIATION_SUCCEED';
export const MUTATE_UPDATE_COMPANY_AFFILIATION_FAILED =
  'MUTATE_UPDATE_COMPANY_AFFILIATION_FAILED';

// MUTATE SEND ENQUIRY EMAIL
export const MUTATE_SEND_ENQUIRY_EMAIL = 'MUTATE_SEND_ENQUIRY_EMAIL';
export const MUTATE_SEND_ENQUIRY_EMAIL_SUCCEED =
  'MUTATE_SEND_ENQUIRY_EMAIL_SUCCEED';
export const MUTATE_SEND_ENQUIRY_EMAIL_FAILED =
  'MUTATE_SEND_ENQUIRY_EMAIL_FAILED';

// MUTATE RESET EMPLOYER PASSWORD
export const MUTATE_RESET_EMPLOYER_PASSWORD = 'MUTATE_RESET_EMPLOYER_PASSWORD';
export const MUTATE_RESET_EMPLOYER_PASSWORD_SUCCEED =
  'MUTATE_RESET_EMPLOYER_PASSWORD_SUCCEED';
export const MUTATE_RESET_EMPLOYER_PASSWORD_FAILED =
  'MUTATE_RESET_EMPLOYER_PASSWORD_FAILED';

// MUTATE EMPLOYER LOGIN
export const MUTATE_EMPLOYER_LOGIN = 'MUTATE_EMPLOYER_LOGIN';
export const MUTATE_EMPLOYER_LOGIN_SUCCEED = 'MUTATE_EMPLOYER_LOGIN_SUCCEED';
export const MUTATE_EMPLOYER_LOGIN_FAILED = 'MUTATE_EMPLOYER_LOGIN_FAILED';

// MUTATE EMPLOYER COMPANY REGISTRATION
export const MUTATE_EMPLOYER_COMPANY_REGISTRATION =
  'MUTATE_EMPLOYER_COMPANY_REGISTRATION';
export const MUTATE_EMPLOYER_COMPANY_REGISTRATION_SUCCEED =
  'MUTATE_EMPLOYER_COMPANY_REGISTRATION_SUCCEED';
export const MUTATE_EMPLOYER_COMPANY_REGISTRATION_FAILED =
  'MUTATE_EMPLOYER_COMPANY_REGISTRATION_FAILED';
// UPDATE_CURRENT_ONBOARDING_PAGE
export const UPDATE_CURRENT_ONBOARDING_PAGE = 'UPDATE_CURRENT_ONBOARDING_PAGE';
export const UPDATE_ONBOARDING_FIELDS = 'UPDATE_ONBOARDING_FIELDS';
export const UPDATE_CURRENT_BACKGROUND = 'UPDATE_CURRENT_BACKGROUND';
export const UPDATE_DIRECT_REGISTER = 'UPDATE_DIRECT_REGISTER';

// SAVE_COMPANY_WAITLIST
export const SAVE_COMPANY_WAITLIST = 'SAVE_COMPANY_WAITLIST';
export const SAVE_COMPANY_WAITLIST_SUCCEEDED =
  'SAVE_COMPANY_WAITLIST_SUCCEEDED';
export const SAVE_COMPANY_WAITLIST_FAILED = 'SAVE_COMPANY_WAITLIST_FAILED';

//FETCH_WAITLIST_INFO
export const FETCH_WAITLIST_INFO = 'FETCH_WAITLIST_INFO';
export const FETCH_WAITLIST_INFO_SUCCEED = 'FETCH_WAITLIST_INFO_SUCCEED';
export const FETCH_WAITLIST_INFO_FAILED = 'FETCH_WAITLIST_INFO_FAILED';

//MUTATE_SEND_EMAIL_WITHOUT_LOGIN
export const MUTATE_SEND_EMAIL_WITHOUT_LOGIN =
  'MUTATE_SEND_EMAIL_WITHOUT_LOGIN';
export const MUTATE_SEND_EMAIL_WITHOUT_LOGIN_SUCCEED =
  'MUTATE_SEND_EMAIL_WITHOUT_LOGIN_SUCCEED';
export const MUTATE_SEND_EMAIL_WITHOUT_LOGIN_FAILED =
  'MUTATE_SEND_EMAIL_WITHOUT_LOGIN_FAILED';

// UPDATE_TERMS_POPUP
export const UPDATE_TERMS_POPUP = 'UPDATE_TERMS_POPUP';
// UPDATE COUNTRY
export const UPDATE_COUNTRY_DROPDOWN = 'UPDATE_COUNTRY_DROPDOWN';

// SELECT_MODAL_VIDEO
export const SELECT_MODAL_VIDEO = 'SELECT_MODAL_VIDEO';

// EXPAND_VIDEO_ACCORDION
export const EXPEND_VIDEO_ACCORDION = 'EXPEND_VIDEO_ACCORDION';

// COMPANY_SIDEBAR_COMPLETION
export const COMPANY_SIDEBAR_COMPLETION = 'COMPANY_SIDEBAR_COMPLETION';

// COMPANY_VIDEO_STATUS
export const COMPANY_VIDEO_STATUS = 'COMPANY_VIDEO_STATUS';

// COMPANY_VIMEO_VIDEO
export const COMPANY_VIMEO_VIDEO = 'COMPANY_VIMEO_VIDEO';

// COMPANY_SOCIAL_MEDIA_GUIDELINE
export const COMPANY_SOCIAL_MEDIA_GUIDELINE = 'COMPANY_SOCIAL_MEDIA_GUIDELINE';

