import React, { useEffect, useState } from 'react';
import {
  CardContainer,
  DefaultContainer,
  DisclaimerTypography,
  Divider,
  ProductCardContainer,
  ProductItemContainer,
  SingleCardContainer,
  TabContainer,
  TitleStyled
} from './styles';
import {
  BoltIconStyled,
  ContactSalesDesc,
  ContactSalesTitle,
  PackageGrid,
  PackageItem,
  ProductCategoryButton,
  PromoContainer,
  PromoText,
  Slash,
  TextButtonStyled
} from './../styles';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedProduct } from './../../../redux/actions/order_action';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { Skeleton } from '@mui/material';
import ProductCard from '../ProductCards';
import { ProductSkeleton } from '../Skeletons/styles';
import ProductInfoBg from '../../../assets/common/product-info-bg.png';
import ProductInfoBg2 from '../../../assets/common/product-info-bg-2.png';
import { updateContactSalesPopupStatus } from '../../../redux/actions/job_action';
import { getCountry } from '../../../hooks/useRegionalisation';
import { isEmpty } from 'lodash';
import { useFetcher, useSearchParams } from 'react-router-dom';
import { convertToTitleCase } from '../../../utils/convertToTitleCase';

const PackageFillerDetails = [
  {
    title: getCountry()
      ? 'Personalise Your Hiring Solution'
      : 'Customise Your Own',
    desc: getCountry()
      ? 'Customise our solutions to fit your unique hiring needs.'
      : 'Build your own job ad package based on your hiring needs',
    bg: `url(${ProductInfoBg})`,
    buttonText: getCountry() ? 'Contact us' : 'Click to proceed!'
  },
  {
    title: 'Value Bundles',
    desc: 'Save more and get greater value from our job ad packages.',
    bg: `url(${ProductInfoBg})`,
    buttonText: 'Click to learn more!'
  },
  {
    title: `Can't find what you need?`,
    desc: 'Talk to a consultant for a custom package catered to your needs.',
    bg: `url(${ProductInfoBg2})`,
    buttonText: 'Click to contact us!'
  },
  {
    title: `Can't find what you need?`,
    desc: 'Talk to a consultant for a custom package catered to your needs.',
    bg: `url(${ProductInfoBg2})`,
    buttonText: 'Click to contact us!'
  },
  {
    title: `Can't find what you need?`,
    desc: 'Talk to a consultant for a custom package catered to your needs.',
    bg: `url(${ProductInfoBg2})`,
    buttonText: 'Click to contact us!'
  }
];

export default function ProductTabs(props) {
  const dispatch = useDispatch();
  const selectedTab = useSelector(state => state.orders.selectedTab);

  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const [initialTabSet, setInitialTabSet] = useState(false);

  const tabHandler = name => {
    dispatch(updateSelectedProduct(name));
    setInitialTabSet(true);
  };

  useEffect(() => {
    if (!initialTabSet) {
      if (isEmpty(tab)) {
        dispatch(updateSelectedProduct(props?.tabList[0]));
      } else {
        console.log('ran tab');
        dispatch(updateSelectedProduct(convertToTitleCase(tab)));
      }
      setInitialTabSet(true); // Mark as set by URL
    }
  }, [tab, selectedTab, props?.tabList, dispatch, initialTabSet]);

  const mappedDetails = props?.tabList.map((category, index) => ({
    category,
    ...PackageFillerDetails[index]
  }));

  const selectedFillerCard = mappedDetails.find(details => {
    return details?.category === selectedTab;
  });

  const companyStatus = useSelector(state => state.companies.myCompanyStatus);
  const allActiveProducts = useSelector(
    state => state.products.allActiveProducts
  );
  const fetchingAllActiveProducts = useSelector(
    state => state.products.fetchingAllActiveProducts
  );

  // Find the targeted products based on selected tab
  const selectedProducts = allActiveProducts.find(products => {
    return products.title === selectedTab;
  });

  const products = selectedProducts?.products;

  // Custom grid (if the tab only have 1 item)
  const singleCard = products?.length === 1;

  let singleProduct;
  if (singleCard) {
    singleProduct = products?.at(0);
  }

  const description = singleProduct?.productPageInfo?.description1;
  const disclaimer = singleProduct?.productPageInfo?.disclaimer;

  const listWithCustomIcons = input => {
    if (!input) return;
    // Split the string into parts for the <ul> tag and between <li> tags
    const parts = input
      ?.split(/<ul>|<\/ul>|<\/?li>/)
      ?.filter(part => part?.trim() !== '');

    return (
      <div>
        <style>
          {`
          .custom-ul-token {
            list-style: none; 
            padding: 0; 
          }
          .custom-li-token {
            display: flex; 
            align-items: flex-start; 
            margin-bottom: 12px; 
            font-weight: 400;
            font-family: Inter;
            letter-spacing: 0.15px;
            color: rgba(0, 0, 0, 0.87);
            line-height: 24px;
            font-size: 16px;
          }
          .custom-li-icon-token {
            margin-right: 8px; 
          }
          .span-token {
            margin-top: 2px;
          }
        `}
        </style>
        {getCountry() == 'MY' && (
          <PromoContainer sx={{ marginBottom: '16px' }}>
            <BoltIconStyled />
            <PromoText>
              Extra Savings: Enjoy up to 20% off Retail Price!
            </PromoText>
          </PromoContainer>
        )}
        <div
          dangerouslySetInnerHTML={{ __html: parts?.at(0) }}
          style={{ marginBottom: '16px' }}
        />
        <ul className="custom-ul-token">
          {parts.slice(1).map((text, index) => (
            <li key={index} className="custom-li-token">
              <span className="span-token">
                <CheckCircleIcon
                  className="custom-li-icon-token"
                  color="#40249B"
                  height="24px"
                  width="24px"
                />
              </span>
              {text}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <DefaultContainer container sx={{ flexDirection: 'column', gap: '24px' }}>
      <TabContainer>
        {props?.tabList
          .filter(tab => {
            return !companyStatus?.talentSearchAccess
              ? tab !== 'Talent Search'
              : tab;
          })
          .map(productTitle => {
            return (
              // This is the tab selection at the top
              <ProductCategoryButton
                variant="contained"
                $selected={selectedTab === productTitle}
                onClick={() => tabHandler(productTitle)}
                title={productTitle.toLowerCase().replace(/\s+/g, '_')}>
                {productTitle} {selectedTab === productTitle}
              </ProductCategoryButton>
            );
          })}
      </TabContainer>

      <DefaultContainer>
        <CardContainer>
          {/* product title */}
          <DefaultContainer sx={{ padding: '8px 24px' }}>
            <TitleStyled>
              {fetchingAllActiveProducts || isEmpty(selectedTab) ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: '2rem', width: '10%', height: '35px' }}
                />
              ) : (
                selectedTab
              )}
            </TitleStyled>
          </DefaultContainer>
          <Divider />
          {/* product item */}
          <ProductItemContainer singleProduct={products?.length === 1}>
            {/* description */}
            {products?.length > 1 && (
              <ContactSalesDesc>
                {fetchingAllActiveProducts || isEmpty(selectedTab) ? (
                  <>
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '2rem', width: '70%', height: '35px' }}
                    />
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '2rem', width: '30%', height: '35px' }}
                    />
                  </>
                ) : (
                  selectedProducts?.categoryDescription
                )}
              </ContactSalesDesc>
            )}

            {/* product card */}
            <DefaultContainer
              sx={{
                display: singleCard && 'flex',
                flexDirection: 'row',
                flexWrap: singleCard && 'nowrap'
              }}>
              <ProductCardContainer
                singleCard={singleCard}
                products={products}
                fetchingAllActiveProducts={fetchingAllActiveProducts}>
                {fetchingAllActiveProducts || isEmpty(selectedTab)
                  ? Array.from({ length: 3 }, (_, index) => {
                      return (
                        <ProductSkeleton
                          key={index}
                          product={
                            Array.isArray(products) && products?.length === 4
                          }
                        />
                      );
                    })
                  : products
                      ?.filter(product => product?.active === true)
                      ?.map((product, index) => {
                        return (
                          <ProductCard
                            companyStatus={companyStatus}
                            product={product}
                            singleCard={singleCard}
                          />
                        );
                      })}

                {products?.length < 3 &&
                  selectedTab !== 'Talent Search' &&
                  // !singleProduct &&
                  !fetchingAllActiveProducts && (
                    <FillerCard
                      selectedFillerCard={selectedFillerCard}
                      selectedTab={selectedTab}
                    />
                  )}
              </ProductCardContainer>

              {selectedTab === 'Talent Search' &&
                !fetchingAllActiveProducts && (
                  <SingleCardContainer>
                    {listWithCustomIcons(description)}

                    {disclaimer && (
                      <DisclaimerTypography>
                        <div dangerouslySetInnerHTML={{ __html: disclaimer }} />
                      </DisclaimerTypography>
                    )}
                  </SingleCardContainer>
                )}
            </DefaultContainer>
          </ProductItemContainer>
        </CardContainer>
      </DefaultContainer>
    </DefaultContainer>
  );
}

const FillerCard = props => {
  const { selectedFillerCard, selectedTab } = props;
  const dispatch = useDispatch();

  const contactUsHandler = () => {
    dispatch(updateContactSalesPopupStatus(true));
  };

  return (
    <PackageItem
      item
      container
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      width={'auto'}
      style={{
        backgroundImage: selectedFillerCard?.bg,
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '105% 105%',
        border: '1px solid #D8D8D8'
      }}>
      <ContactSalesTitle marginBottom={'16px'}>
        {selectedFillerCard?.title}
      </ContactSalesTitle>
      <ContactSalesDesc textAlign={'center'} marginBottom={'16px'}>
        {selectedFillerCard?.desc}
      </ContactSalesDesc>
      <TextButtonStyled
        variant="text"
        onClick={() => {
          if (getCountry() === 'SG') {
            contactUsHandler();
            return;
          }

          if (selectedTab == 'Limited-Time Offers') {
            dispatch(updateSelectedProduct('Job Ads'));
          } else {
            contactUsHandler();
          }
        }}>
        {selectedFillerCard?.buttonText}
      </TextButtonStyled>
    </PackageItem>
  );
};
