import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, MenuItem } from '@mui/material';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import { HeightSpacer } from '../../../../components/Spacer/styles';
import { FilterTitle, Row } from './styles';
import SharedSelect from '../SharedSelect';
import { useDispatch, useSelector } from 'react-redux';
import { fetchIndustries } from '../../../../redux/actions/company_actions';
import {
  updateCollapsibleFilterStatus,
  updateSearchTalentFilters
} from '../../../../redux/actions/talent_action';

let selectedIndustries = [];

const CompanyIndustry = () => {
  const dispatch = useDispatch();

  const [industryTitles, setIndustryTitles] = useState([]);

  const industries = useSelector(state => state.companies.industries);
  const filters = useSelector(state => state.talents.searchTalentFilters);
  const expandFilters = useSelector(state => state.talents.expandFilters);

  const onChooseIndustries = selectedIndustry => {
    let cFilters = { ...filters };

    let index = selectedIndustries.findIndex(item => {
      return item == selectedIndustry.id;
    });

    if (index > -1) {
      selectedIndustries.splice(index, 1);

      const titles = industryTitles.filter(title => {
        return title != selectedIndustry.title;
      });

      setIndustryTitles(titles);
    } else {
      setIndustryTitles([...industryTitles, selectedIndustry.title]);
      selectedIndustries.push(selectedIndustry.id);
    }

    cFilters['industryIds'] = selectedIndustries;

    dispatch(updateSearchTalentFilters(cFilters));
  };

  const onClickFilter = () => {
    let cFilter = { ...expandFilters };

    cFilter['companyIndustry'] = !cFilter['companyIndustry'];

    dispatch(updateCollapsibleFilterStatus(cFilter));
  };

  useEffect(() => {
    if (filters['industryIds']?.length <= 0) {
      setIndustryTitles([]);
      selectedIndustries = [];
    }
  }, [filters]);

  useEffect(() => {
    dispatch(fetchIndustries());
  }, []);

  return (
    <div
      style={{
        padding: '0',
        display: 'flex',
        flexDirection: 'column',
        width: '95%',
        gap: '8px'
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        onClick={onClickFilter}>
        <FilterTitle>Company Industry</FilterTitle>

        {expandFilters['companyIndustry'] ? (
          <ExpandLessIcon />
        ) : (
          <ExpandMoreIcon />
        )}
      </div>

      {expandFilters['companyIndustry'] && (
        <>
          <SharedSelect
            placeholder={'Select Company Industry'}
            multiple={true}
            width={'300px'}
            height={'300px'}
            value={
              industryTitles.length > 0
                ? industryTitles
                : ['Select Company Industry']
            }>
            {industries.map((item, index) => {
              return (
                <IndustrySelection
                  item={item}
                  index={index}
                  key={`${item.title}-${index}`}
                  onClick={() => onChooseIndustries(item)}
                  checked={filters['industryIds']?.indexOf(item.id) > -1}
                />
              );
            })}
          </SharedSelect>
        </>
      )}
    </div>
  );
};

export default CompanyIndustry;

export const IndustrySelection = ({ item, index, onClick, checked }) => {
  return (
    <Row key={`${item.title}-${index}`}>
      <FormControlLabel
        control={
          <Checkbox
            onClick={onClick}
            checked={checked}
            sx={{
              '&.Mui-checked': {
                color: '#222'
              },
              padding: '0 9px'
            }}
          />
        }
        label={item.title}
        sx={{
          '& .MuiTypography-root': {
            fontSize: 14,
            letterSpacing: '0.15px'
          },
          '& .MuiSvgIcon-root': {
            fontSize: 20
          }
        }}
      />
    </Row>
  );
};
