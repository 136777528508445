import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, InputAdornment, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import SharedTextField from '../../../components/SharedTextField';
import { Spacer } from '../../../components/Spacer/styles';
import {
  CloseIconStyled,
  MagnifyingGlassCircleIconStyled,
  SearchWrapper
} from './styles';
import Colors from '../../../styles/colors.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getApplications,
  updateApplicationCurrentPage,
  updateApplicationParams
} from '../../../redux/actions/application_action';
import store from '../../../redux/stores/store';
import AdvancedFilter from '../AdvancedFilter';
import StatusFilter from '../../ManageCandidates/StatusFilter';

const textFieldProps = (
  onClickAdvancedFilterIcon,
  onSubmitKeyword,
  onClearKeyword
) => {
  return {
    style: {
      '& .MuiInputBase-root': {
        borderRadius: '30px',
        color: '#222222'
      },

      '& .MuiFormLabel-root': {
        top: 0
      }
    },

    endAdornment: (
      <>
        {store.getState().applications.applicationParams.search?.length > 0 && (
          <InputAdornment
            position="end"
            onClick={onClearKeyword}
            sx={{
              '& :hover': {
                cursor: 'pointer'
              }
            }}>
            <CloseIconStyled />
          </InputAdornment>
        )}
        <InputAdornment
          position="end"
          onClick={onSubmitKeyword}
          sx={{
            '& :hover': {
              cursor: 'pointer'
            }
          }}>
          <MagnifyingGlassCircleIconStyled />
        </InputAdornment>
        <InputAdornment
          position="end"
          sx={{
            '&:hover': {
              cursor: 'pointer'
            }
          }}>
          <Tooltip
            title={
              <span style={{ fontSize: '12px', fontFamily: 'Inter' }}>
                Advanced Filter
              </span>
            }
            placement="bottom">
            <span>
              <FontAwesomeIcon
                icon={faSlidersH}
                onClick={onClickAdvancedFilterIcon}
                style={{ fontSize: '16px', cursor: 'pointer' }}
              />
            </span>
          </Tooltip>
        </InputAdornment>
      </>
    )
  };
};

const Search = () => {
  const dispatch = useDispatch();

  //useselector
  const searchText = useSelector(
    state => state.applications.applicationParams.search
  );
  const jobData = useSelector(state => state.jobs.jobData);

  //useState
  const [anchorEl, setAnchorEl] = useState(null);

  //data
  const statusFilter = [
    {
      title: `applicants`,
      count: `${jobData?.totalCount}`
    },
    {
      title: `undecided`,
      count: `${jobData?.undecidedCount}`
    },
    {
      title: `shortlisted`,
      count: `${jobData?.shortlistedCount}`
    },
    { title: `kiv`, count: `${jobData?.kivCount}` },
    { title: `interview`, count: `${jobData?.interviewCount}` },
    { title: `offered`, count: `${jobData?.offerCount}` }
  ];

  //methods
  const onChangeKeyword = e => {
    let cApplicationParams = {
      ...store.getState().applications.applicationParams
    };

    cApplicationParams.search = e.target.value;

    dispatch(updateApplicationParams(cApplicationParams));
  };

  const onClickAdvancedFilterIcon = event => {
    setAnchorEl(event.currentTarget);
  };

  const onSubmitKeyword = () => {
    let params = { ...store.getState().applications.applicationParams };

    if (store.getState().applications.applicationCurrentPage > 1) {
      dispatch(updateApplicationCurrentPage(1));
    }
    dispatch(getApplications(params));
  };

  const onClearKeyword = () => {
    let cApplicationParams = {
      ...store.getState().applications.applicationParams
    };

    if (store.getState().applications.applicationParams.search?.length > 0) {
      cApplicationParams.search = '';
    }

    dispatch(updateApplicationParams(cApplicationParams));
  };

  return (
    <SearchWrapper>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end'
        }}>
        <SharedTextField
          InputProps={textFieldProps(
            onClickAdvancedFilterIcon,
            onSubmitKeyword,
            onClearKeyword
          )}
          value={searchText}
          placeholder={'Search candidate by keywords...'}
          variant={'standard'}
          onChange={onChangeKeyword}
          sx={{ width: '300px' }}
        />
      </div>
      <AdvancedFilter anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      {/* for phase 2 */}
      {/* <div>
        <StatusFilter
          loading={false}
          isAts={true}
          statusFilter={statusFilter}
        />
      </div> */}
    </SearchWrapper>
  );
};

export default Search;
