import { styled as styling } from '@mui/material/styles';

export const Container = styling('div')({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '30px 0px',
});

export const Logo = styling('img')({
  width: '200px',
  height: '28px',
});

export const CardContainer = styling('div')({
  width: '32wv',
  borderRadius: '38px',
  padding: '40px 50px',
  background: '#FFF',
});

export const Title = styling('span')({
  fontSize: '18px',
  fontFamily: 'InterBold',
  textAlign: 'center',
  fontWeight: '700',
});

