import React, { useMemo, useState } from 'react';
import { Divider, Grid, Tabs } from '@mui/material';
import {
  BETAText,
  BoldText,
  SectionLabel,
  CardMediaStyled,
  ChatSubtabStyled,
  IconStyle,
  InfoContainer,
  MainContainer,
  NoInterview,
  NormalText,
  NoVCL,
  SubInfoContainer,
  TabIndicator,
  TabPanelStyled,
  TabStyled,
  SectionContainer,
  SubContainer,
  LabelContainer
} from './styles';
import CandidateChat from '../../../pages/ManageCandidates/List/CandidateInformation/CandidateChat';
import CandidateInterviewNote from '../CandidateSnapshotInterviewNote';
import {
  EnvelopeIcon,
  PhoneIcon,
  MapPinIcon,
  BriefcaseIcon
} from '@heroicons/react/24/solid';
import CandidateApplicationSummary from '../../../pages/ManageCandidates/Board/CandidateApplicationSummary';
import CandidateAiviSummary from '../../../pages/ManageCandidates/Board/CandidateAiviSummary';
import { HeightSpacer } from '../../Spacer/styles';
import { sortWorkingExperiences } from '../../../helpers/data_management';
import { countryOptionsFullName } from '../../SharedSelectCountry/constants';
import { getCountry, getCountryFullName } from '../../../hooks/useRegionalisation';

function TabPanel(props) {
  const { children, value, index, show_hired_card, ...other } = props;

  return (
    <TabPanelStyled
      panel_index={value}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      show_hired_card={`${props.show_hired_card}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && <div>{children}</div>}
    </TabPanelStyled>
  );
}

const StyledTabs = props => {
  return (
    <Tabs
      {...props}
      centered={props.label == 'tabs'}
      TabIndicatorProps={{
        style: {
          backgroundColor:
            props.label == 'chat-subtabs' ? 'rgba(0, 0, 0, 0.6)' : '#635ee7',
          height: '2px',
          position: 'absolute',
          zIndex: '999',

          top: props.label == 'chat-subtabs' && '40px'
        }
      }}
    />
  );
};

const StyledTab = props => {
  const application = props.candidate?.jobApplication
    ? props.candidate.jobApplication
    : props.candidate;

  return (
    <TabStyled
      isHired={
        props.pageName == 'new-applicant-page'
          ? false
          : application?.state !== 'undecided' &&
            application?.state !== 'rejected' &&
            application?.employerHiredStatus == null
      }
      value={'one'}
      disableRipple
      {...props}
    />
  );
};

const ChatStyledSubtab = props => {
  return <ChatSubtabStyled value={'one'} disableRipple {...props} />;
};

const CandidateSnapshotRichInfo = props => {
  const {
    candidate,
    isAts,
    updateCandidateList,
    candidates,
    setCandidates,
    setCurrentCandidates,
    pageName
  } = props;
  const [value, setValue] = React.useState(0);
  const [chatSubtabValue, setChatSubtabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isCandidatesEmpty = candidate ? false : true;
  const chatChannel =
    candidate?.sendbirdChannelUrl ||
    candidate?.jobApplication?.sendbirdChannelUrl;
  const user = candidate?.user || candidate?.jobApplication?.user;

  const hasInterview = candidate => {
    const application = candidate?.jobApplication
      ? candidate.jobApplication
      : candidate;

    if (application?.interviews?.length > 0) {
      return true;
    }

    return false;
  };

  const handleChatSubtabChange = (event, newValue) => {
    setChatSubtabValue(newValue);
  };

  return (
    <MainContainer>
      <div>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="tabs"
          label={'tabs'}>
          <StyledTab
            id="ats-biodata-button"
            label={
              <span>
                Biodata{' '}
                {/* <BETAText style={{ position: 'relative', top: '-7px' }}>
                  BETA
                </BETAText> */}
              </span>
            }
            candidate={candidate}
            pageName={pageName}
          />
          <StyledTab
            id="ats-vcl-button"
            label="Video Cover Letter"
            candidate={candidate}
            pageName={pageName}
          />
          <StyledTab
            id="ats-chat-button"
            label={
              <span>
                Chat{' '}
                {/* <BETAText style={{ position: 'relative', top: '-7px' }}>
                  BETA
                </BETAText> */}
              </span>
            }
            candidate={candidate}
            pageName={pageName}
          />
          <StyledTab
            id="ats-note-button"
            label="Note"
            candidate={candidate}
            pageName={pageName}
          />
        </StyledTabs>
        <TabIndicator />
        <TabPanel
          value={value}
          index={0}
          show_hired_card={`${
            candidate?.state !== 'undecided' &&
            candidate?.state !== 'rejected' &&
            candidate?.employerHiredStatus === null
          }`}>
          <BioDataTab user={user} candidate={candidate} />
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          show_hired_card={`${
            candidate?.state !== 'undecided' &&
            candidate?.state !== 'rejected' &&
            candidate?.employerHiredStatus === null
          }`}>
          <VCLTab
            user={user}
            show_hired_card={`${
              candidate?.state !== 'undecided' &&
              candidate?.state !== 'rejected' &&
              candidate?.employerHiredStatus === null
            }`}
          />
        </TabPanel>
        <TabPanel
          value={value}
          index={2}
          show_hired_card={`${
            candidate?.state !== 'undecided' &&
            candidate?.state !== 'rejected' &&
            candidate?.employerHiredStatus === null
          }`}>
          <StyledTabs
            value={chatSubtabValue}
            onChange={handleChatSubtabChange}
            aria-label="sub-tabs"
            label={'chat-subtabs'}>
            <ChatStyledSubtab
              id="ats-chat-history"
              label="CHAT HISTORY"
              candidate={candidate}
              chatSubtabIndex={0}
              chatSubtabValue={chatSubtabValue}
            />
            {candidate?.gptChatSummary && (
              <ChatStyledSubtab
                id="ats-aivi-summary"
                label="SUMMARY"
                candidate={candidate}
                chatSubtabIndex={1}
                chatSubtabValue={chatSubtabValue}
              />
            )}
          </StyledTabs>
          <TabPanel value={chatSubtabValue} index={0} label={'chat-subtab'}>
            <HeightSpacer height={'10px'} />
            <CandidateChat
              candidate={candidate}
              chatChannel={chatChannel}
              isCandidatesEmpty={isCandidatesEmpty}
              updateCandidateFromList={updateCandidateList}
            />
          </TabPanel>

          <TabPanel value={chatSubtabValue} index={1} label={'chat-subtab'}>
            <CandidateAiviSummary candidate={candidate} />
          </TabPanel>

          <div></div>
        </TabPanel>
        <TabPanel
          value={value}
          index={3}
          show_hired_card={`${
            candidate?.state !== 'undecided' &&
            candidate?.state !== 'rejected' &&
            candidate?.employerHiredStatus === null
          }`}>
          <NoteTab
            candidate={candidate}
            setCandidates={setCandidates}
            candidates={candidates}
            setCurrentCandidates={setCurrentCandidates}
            isAts={isAts}
            hasInterview={hasInterview}
          />
        </TabPanel>
      </div>
    </MainContainer>
  );
};

const BioDataTab = ({ user, candidate }) => {
  const getWorkingExperiences = cUser => {
    if (cUser?.workingExperiences?.edges) {
      const experiences = cUser?.workingExperiences.edges.map(
        item => item.node
      );

      return sortWorkingExperiences(experiences);
    }

    return [];
  };

  const memoizedWorkingExperiences = useMemo(() => {
    return user => getWorkingExperiences(user);
  }, [user?.workingExperiences?.edges]);

  const getEducations = cUser => {
    if (cUser?.educations?.edges) {
      const educations = cUser?.educations.edges.map(item => item.node);

      return educations.sort((a, b) => {
        try {
          const bId = parseInt(b.id);
          const aId = parseInt(a.id);

          return bId - aId;
        } catch (error) {
          //
        }

        return b.id - a.id;
      });
    }

    return [];
  };

  const memoizedEducations = useMemo(() => {
    return user => getEducations(user);
  }, [user?.educations?.edges]);

  const renderEmailPhone = () => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px',
            alignSelf: 'stretch'
          }}>
          <SectionContainer>
            <LabelContainer>
              <Grid display="flex" alignItems={'center'}>
                <SectionLabel>Profile</SectionLabel>
              </Grid>
            </LabelContainer>

            <SubContainer>
              <SubInfoContainer>
                <Grid display="flex" alignItems={'center'}>
                  <BoldText $noPadding>Email</BoldText>
                </Grid>
                <NormalText $noPadding>
                  {user?.email ? user?.email : 'Not provided'}
                </NormalText>
              </SubInfoContainer>

              <SubInfoContainer>
                <Grid display="flex" alignItems={'center'}>
                  <BoldText $noPadding>Phone Number</BoldText>
                </Grid>
                <NormalText $noPadding>
                  {user?.mobileNumber ? user?.mobileNumber : 'Not provided'}
                </NormalText>
              </SubInfoContainer>

              <SubInfoContainer>
                <Grid display="flex" alignItems={'center'}>
                  <BoldText $noPadding>Current Location</BoldText>
                </Grid>
                <NormalText $noPadding>
                  {user.currentLocation ? user.currentLocation : 'Not provided'}
                </NormalText>
              </SubInfoContainer>
            </SubContainer>
          </SectionContainer>
          {(user?.email || user?.mobileNumber || user?.currentLocation) && (
            <>
              <Divider
                style={{
                  margin: '0',
                  borderColor: '#D8D8D8',
                  width: '100%'
                }}
              />
              <div></div>
            </>
          )}
        </div>
      </>
    );
  };

  const isRelocateAndRemoteTrue = (relocate, remote) => {
    if (relocate && remote) {
      return true;
    }

    return false;
  };

  const getRightToWork = (
    openToSponsor,
    localCitizen,
    visaOrEmploymentPass
  ) => {
    if (openToSponsor || localCitizen || visaOrEmploymentPass) {
      if (openToSponsor) {
        return 'Open to sponsor';
      } else if (localCitizen) {
        return 'Local citizen';
      } else if (visaOrEmploymentPass) {
        return 'Has existing right to work';
      }
    } else {
      return 'Not provided';
    }
  };

  const getDestionationKey = () => {
    const workLocationPreference = user?.workLocationPreference;
    const keys = Object.keys(workLocationPreference);
    const currentCountry = getCountry();

    if(keys.length > 0){
      return currentCountry;
    }

    return '';
  };

  const renderLocationWorkplace = () => {
    let destinationCountry = getDestionationKey();
    
    // if (destinationCountry != getCountry() && destinationCountry?.length > 0) {
    //   return;
    // }

    let relocate =
      user?.workLocationPreference[destinationCountry]?.relocate ?? null;
    let remote =
      user?.workLocationPreference[destinationCountry]?.remote ?? null;

    let openToSponsor =
      user?.rightToWork[destinationCountry]?.openToSponsor ?? null;

    let localCitizen =
      user?.rightToWork[destinationCountry]?.localCitizen ?? null;

    let visaOrEmploymentPass =
      user?.rightToWork[destinationCountry]?.visaOrEmploymentPass ?? null;

    const renderWorkLocationPreference = (preference, label, bothTrue) => {
      if (preference) {
        return preference ? (
          <NormalText $noPadding $list={bothTrue}>
            {label}
          </NormalText>
        ) : null;
      }
      return null;
    };

    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px',
            alignSelf: 'stretch'
          }}>
          <SectionContainer>
            <LabelContainer>
              <Grid display="flex" alignItems={'center'}>
                <SectionLabel>
                  Work Eligibility In {getCountryFullName()}
                </SectionLabel>
              </Grid>
            </LabelContainer>

            {/* {relocate == false && remote == false ? null : ( */}
            <SubContainer>
              <SubInfoContainer>
                <Grid display="flex" alignItems={'center'}>
                  <BoldText $noPadding>Availability & Relocation</BoldText>
                </Grid>
                {(relocate == null && remote == null) ||
                (!relocate && !remote) ? (
                  <NormalText $noPadding>Not provided</NormalText>
                ) : (
                  <Grid
                    display={'flex'}
                    alignItems={'flex-start'}
                    justifyContent={
                      relocate && remote ? 'flex-start' : 'center'
                    }
                    flexDirection={'column'}>
                    {renderWorkLocationPreference(
                      relocate,
                      // `Willing to relocate: ${countryOptionsFullName[destinationCountry]}`,
                      `Willing to relocate`,
                      isRelocateAndRemoteTrue(relocate, remote)
                    )}
                    {renderWorkLocationPreference(
                      remote,
                      'Remote work',
                      isRelocateAndRemoteTrue(relocate, remote)
                    )}
                  </Grid>
                )}
              </SubInfoContainer>

              <SubInfoContainer>
                <Grid display="flex" alignItems={'center'}>
                  <BoldText $noPadding>Right to Work</BoldText>
                </Grid>
                <NormalText $noPadding>
                  {getRightToWork(
                    openToSponsor,
                    localCitizen,
                    visaOrEmploymentPass
                  )}
                </NormalText>
              </SubInfoContainer>
            </SubContainer>
            {/* )} */}
          </SectionContainer>

          <Divider
            style={{ margin: '0', borderColor: '#D8D8D8', width: '100%' }}
          />
          <div></div>
        </div>
      </>
    );
  };

  return (
    <InfoContainer>
      {renderEmailPhone()}
      {renderLocationWorkplace()}

      <SectionContainer>
        <LabelContainer>
          <Grid display="flex" alignItems={'center'}>
            <SectionLabel>Background</SectionLabel>
          </Grid>
        </LabelContainer>

        <SubContainer>
          {memoizedEducations(user)?.length > 0 &&
            memoizedEducations(user)[0]?.graduationYear && (
              <SubInfoContainer>
                <BoldText $noPadding>Graduation Year</BoldText>
                <NormalText $noPadding>
                  {memoizedEducations(user)[0]?.graduationYear}
                </NormalText>
              </SubInfoContainer>
            )}

          {memoizedWorkingExperiences(user)?.length > 0 &&
            memoizedWorkingExperiences(user)[0]?.companyName?.length > 0 && (
              <SubInfoContainer>
                <BoldText $noPadding>Current Company</BoldText>
                <NormalText $noPadding>
                  {memoizedWorkingExperiences(user)[0]?.companyName}
                </NormalText>
              </SubInfoContainer>
            )}

          {memoizedEducations(user)?.length > 0 &&
            memoizedEducations(user)[0]?.educationLevel?.length > 0 && (
              <SubInfoContainer>
                <BoldText $noPadding>Highest Level of Education</BoldText>
                <NormalText $noPadding>
                  {memoizedEducations(user)[0]?.educationLevel}
                </NormalText>
              </SubInfoContainer>
            )}

          {memoizedEducations(user)?.length > 0 &&
            memoizedEducations(user)[0]?.educationalInstitution?.length > 0 && (
              <SubInfoContainer>
                <BoldText $noPadding>Educational Institute</BoldText>
                <NormalText $noPadding>
                  {memoizedEducations(user)[0]?.educationalInstitution}
                </NormalText>
              </SubInfoContainer>
            )}

          {memoizedEducations(user)?.length > 0 &&
            memoizedEducations(user)[0]?.fieldOfStudy?.length > 0 && (
              <SubInfoContainer>
                <BoldText $noPadding>Field of Study</BoldText>
                <NormalText $noPadding>
                  {memoizedEducations(user)[0]?.fieldOfStudy}
                </NormalText>
              </SubInfoContainer>
            )}

          {memoizedEducations(user)?.length > 0 &&
            memoizedEducations(user)[0]?.major?.length > 0 && (
              <SubInfoContainer>
                <BoldText $noPadding>Major</BoldText>
                <NormalText $noPadding>
                  {memoizedEducations(user)[0]?.major}
                </NormalText>
              </SubInfoContainer>
            )}
        </SubContainer>
      </SectionContainer>

      {candidate?.gptSummary && (
        <CandidateApplicationSummary candidate={candidate} />
      )}
    </InfoContainer>
  );
};

const VCLTab = ({ user, show_hired_card }) => {
  return (
    <div>
      {user?.videoCvOptimized ? (
        <CardMediaStyled
          component="video"
          controls
          src={user?.videoCvOptimized}
          show_hired_card={show_hired_card}
        />
      ) : (
        <NoVCL>This applicant has not uploaded a VCL yet. </NoVCL>
      )}
    </div>
  );
};

const NoteTab = ({
  candidate,
  setCandidates,
  candidates,
  setCurrentCandidates,
  isAts,
  hasInterview
}) => {
  return (
    <div>
      {hasInterview(candidate) ? (
        <div>
          <CandidateInterviewNote
            candidate={candidate}
            candidates={candidates}
            setCandidates={setCandidates}
            setCurrentCandidates={setCurrentCandidates}
            isAts={isAts}
          />
        </div>
      ) : (
        <NoInterview>
          No interview is scheduled for this applicant yet.{' '}
        </NoInterview>
      )}
    </div>
  );
};

export default CandidateSnapshotRichInfo;
