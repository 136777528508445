import React from 'react';

import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import './styles.scss';

const SpecialInput = ({ mobileNumber, handlePhoneNumber }) => {
  return (
    <>
      <PhoneInput
        placeholder="+6012 3456789"
        value={mobileNumber}
        defaultCountry={'MY'}
        countryCallingCodeEditable={false}
        international
        onChange={handlePhoneNumber}
      />
      {!isValidPhoneNumber(mobileNumber) && (
        <span
          style={{
            fontSize: '0.75rem',
            color: '#f44336',
            marginLeft: '50px',
            marginRight: '14px',
            marginTop: '4px',
            width: '200px'
          }}>
          Please enter a valid phone number
        </span>
      )}
    </>
  );
};

export default SpecialInput;
