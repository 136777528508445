import React, { useState, useRef, useEffect } from 'react';
import { Info } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { Skeleton } from '@mui/material';
import DefaultLayout from '../../../layouts/DefaultLayout';
import UploadIcon from '../../../assets/common/company-account/camera-icon.svg';
import styles from './index.module.scss';
import BillingAddressCard from './BillingAddressCard';
import CompanyDetailsCard from './CompanyDetailsCard';
import { RECORD_TYPE } from '../../../utils/Constants';
import { ErrorToast, SuccessToast } from '../../../utils/ToastUtils';
import { checkFileSize } from '../../../utils/UploadUtils';
import moment from 'moment';
import { MUIButtonStyled } from '../../../components/MUIButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchMyCompanySettings,
  mutateCompanyDetails,
  readyFileUpload
} from '../../../redux/actions/company_actions';

const CompanyAccountDetails = () => {
  const dispatch = useDispatch();
  const [logoPublicUrl, setLogoPublicUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    dispatch(fetchMyCompanySettings());
  }, []);

  const myCompanySettings = useSelector(
    state => state.companies.myCompanySettings
  );

  const fetchingCompanySettings = useSelector(
    state => state.companies.fetchingCompanySettings
  );

  const prepareFileUploadData = useSelector(
    state => state.companies.prepareFileUpload
  );

  const fetchingPrepareFileUpload = useSelector(
    state => state.companies.fetchingPrepareFileUpload
  );

  const handleClick = () => {
    hiddenFileInput?.current.click();
  };

  const onUploadCompanyLogo = async files => {
    let data;

    try {
      const response = await dispatch(
        readyFileUpload({
          input: {
            filename: files[0]?.name,
            recordType: RECORD_TYPE.COMPANY_LOGO
          }
        })
      );

      data = response?.prepareFileUpload;

      setIsUploading(true);
      checkFileSize(files[0]?.size, 'company_logo');

      if (files[0]?.name) {
        const extension = files[0].name.substr(
          files[0].name.lastIndexOf('.') + 1,
          files[0].name.length
        );

        const fileName = `Hiredly_jobs_malaysia_${myCompanySettings?.name?.substr(
          30
        )}_${moment().format('x')}_company_logo.${extension}`;

        const uploadData = data;

        const file = files[0];

        const xhr = new XMLHttpRequest();
        xhr.open('PUT', uploadData?.presignedUrl, true);
        xhr.setRequestHeader('Content-Type', file.type);
        xhr.onload = async () => {
          dispatch(
            mutateCompanyDetails({
              input: {
                publicLogoUrl: uploadData?.publicUrl
              }
            })
          )
            .then(res => {
              setLogoPublicUrl(uploadData?.publicUrl);
              setIsUploading(false);

              if (res.type === 'FETCH_MY_COMPANY_SETTINGS_SUCCEED') {
                SuccessToast('Company Logo Updated Successfully!');
              } else {
                SuccessToast('Company Logo Update Failed');
              }
            })
            .catch(error => {
              setIsUploading(false);
              ErrorToast(error.message);
            });
        };
        xhr.onerror = () => {
          setIsUploading(false);
          ErrorToast(
            'Failed to upload. Please refresh your page and try again.'
          );
        };

        xhr.send(file);
      } else {
        setIsUploading(false);
        ErrorToast('Failed to upload. Please refresh your page and try again.');
      }
    } catch (err) {
      setIsUploading(false);
      ErrorToast(err.message);
    }
  };

  const handleImageChange = event => {
    if (event.target.files) {
      onUploadCompanyLogo(event.target.files);
    }
  };

  useEffect(() => {
    if (myCompanySettings?.publicLogoUrl) {
      setLogoPublicUrl(myCompanySettings?.publicLogoUrl);
    }
  }, [myCompanySettings]);

  return (
    <DefaultLayout>
      <div className={styles.container}>
        <p className={styles.title}>Company Details</p>

        {isUploading || fetchingCompanySettings ? (
          <Skeleton variant="circle" height={150} width={150} />
        ) : (
          <div>
            <div>
              {fetchingCompanySettings ? (
                <Skeleton variant="circle" height={150} width={150} />
              ) : (
                <div>
                  {logoPublicUrl ? (
                    <div
                      className={styles.logoContainer}
                      style={
                        logoPublicUrl
                          ? {
                              backgroundImage: `url(${logoPublicUrl})`,
                              backgroundSize: 'contain',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center'
                            }
                          : null
                      }
                    />
                  ) : (
                    <div style={{ padding: 42.5 }}>
                      <img
                        src={UploadIcon}
                        alt="UploadIcon"
                        className={styles.uploadImage}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        <div style={{ padding: 15 }}>
          <input
            type="file"
            id="file"
            ref={hiddenFileInput}
            onChange={handleImageChange}
            style={{ display: 'none' }}
            accept="image/*"
          />
          <MUIButtonStyled
            outlinedBlack
            onClick={handleClick}
            label="Upload Company Logo"
          />
        </div>
        <Tooltip
          title={
            <span style={{ fontSize: '12px', fontFamily: 'Inter' }}>
              With a Company Logo, job seekers will be more likely to recognise
              your company easily.
            </span>
          }
          placement="right">
          <div className={styles.infoContainer}>
            <p className={styles.infoText}>What is this for?</p>
            <Info className={styles.infoIcon} />
          </div>
        </Tooltip>

        <div className={styles.cardContainer}>
          <CompanyDetailsCard
            data={myCompanySettings}
            loading={fetchingCompanySettings}
          />
          <BillingAddressCard
            billingAddress={myCompanySettings?.billingAddress?.at(0)}
            loading={fetchingCompanySettings}
          />
        </div>
      </div>
    </DefaultLayout>
  );
};

export default CompanyAccountDetails;
