import React, { Fragment, useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import { HeightSpacer } from '../../../../components/Spacer/styles';
import {
  CategoryPill,
  CategoryPillTitle,
  CheckboxStyled,
  FilterTitle,
  GridStyled,
  IconButtonStyled,
  MainCategoryText,
  Row,
  SelectStyled,
  SelectTextStyled,
  SelectedCountContainer,
  TextButtonStyled,
  TextFieldStyled,
  XMarkStyled
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSpecialisation } from '../../../../redux/actions/job_action';
import {
  updateCollapsibleFilterStatus,
  updateSearchTalentFilters
} from '../../../../redux/actions/talent_action';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

let selectedSpecialisation = [];
let selectedSpecialisationIds = [];

const JobSpecialisation = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState(null);
  const [matchResult, setMatchResult] = useState([]);
  const [selectedMainCat, setSelectedMainCat] = useState(null);

  const specialisation = useSelector(state => state.jobs.specialisation);
  const filters = useSelector(state => state.talents.searchTalentFilters);
  const expandFilters = useSelector(state => state.talents.expandFilters);

  const onChooseSpecialisation = selectedTrack => {
    let cFilters = { ...filters };

    let index = selectedSpecialisation.findIndex(item => {
      return item == selectedTrack.title;
    });

    let indexOfId = selectedSpecialisationIds.findIndex(item => {
      return item == selectedTrack.id;
    });

    if (index > -1 || indexOfId > -1) {
      selectedSpecialisation.splice(index, 1);
      selectedSpecialisationIds.splice(indexOfId, 1);
    } else {
      selectedSpecialisation.push(selectedTrack.title);
      selectedSpecialisationIds.push(selectedTrack.id);
    }

    cFilters['specialisations'] = selectedSpecialisation;
    cFilters['specialisationIds'] = selectedSpecialisationIds;

    dispatch(updateSearchTalentFilters(cFilters));
  };

  const onClickFilter = () => {
    let cFilter = { ...expandFilters };

    cFilter['jobSpecialisation'] = !cFilter['jobSpecialisation'];

    dispatch(updateCollapsibleFilterStatus(cFilter));
  };

  const handleSearchInput = event => {
    if (setSelectedMainCat) setSelectedMainCat(null);

    const { value } = event?.target || {};
    setSearchText(value);

    if (value?.length <= 0) {
      setMatchResult([]);
    } else {
      // return the main category, and ONLY the matching sub categories
      let filterMatchResult = specialisation.reduce(
        (accumulator, currentSpec) => {
          const matchingSubCat = currentSpec.subCategories.filter(subCat =>
            subCat.title.toLowerCase().includes(value.toLowerCase())
          );

          if (matchingSubCat.length > 0) {
            accumulator.push({
              id: currentSpec.id,
              title: currentSpec.title,
              subCategories: matchingSubCat
            });
          }

          return accumulator;
        },
        []
      );

      setMatchResult(filterMatchResult);
    }
  };

  const selectedAll =
    filters['specialisationIds']?.length ===
    specialisation.flatMap(category =>
      category.subCategories?.map(subCat => subCat.id)
    )?.length;

  const handleSelectAll = () => {
    let reduxFilters = { ...filters };

    if (selectedAll) {
      reduxFilters['specialisations'] = [];
      reduxFilters['specialisationIds'] = [];
    } else {
      const specStrArr = specialisation?.flatMap(category =>
        category.subCategories?.map(subCat => subCat.title)
      );
      const specIdArr = specialisation?.flatMap(category =>
        category.subCategories?.map(subCat => subCat.id)
      );
      reduxFilters['specialisations'] = specStrArr;
      reduxFilters['specialisationIds'] = specIdArr;
    }

    dispatch(updateSearchTalentFilters(reduxFilters));
    return;
  };

  useEffect(() => {
    dispatch(fetchSpecialisation());
  }, []);

  useEffect(() => {
    if (filters['specialisations']?.length <= 0) {
      selectedSpecialisation = [];
      selectedSpecialisationIds = [];
    }
  }, [filters]);

  return (
    <div
      style={{
        padding: '0',
        display: 'flex',
        flexDirection: 'column',
        width: '95%',
        gap: '8px'
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        onClick={onClickFilter}>
        <FilterTitle>Job Specialisation</FilterTitle>

        {expandFilters['jobSpecialisation'] ? (
          <ExpandLessIcon />
        ) : (
          <ExpandMoreIcon />
        )}
      </div>

      {expandFilters['jobSpecialisation'] && (
        <Fragment>
          <FormControl>
            <SelectStyled
              onClose={() => {
                setSelectedMainCat(null);
              }}
              value={
                filters['specialisations'].length > 0
                  ? filters['specialisations']
                  : ['Select Job Specialisation']
              }
              placeholder="Select Job Specialisation"
              multiple
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.16)',
                    borderRadius: '4px',
                    width: '300px',
                    height: '300px'
                  }
                }
              }}
              renderValue={value => {
                // IF THERE IS NO SELECTION
                return Array.isArray(value) &&
                  value[0] === 'Select Job Specialisation' ? (
                  <SelectTextStyled>{value}</SelectTextStyled>
                ) : // IF THERE IS MORE THAN 1 SELECTION
                Array.isArray(value) && value.length > 1 ? (
                  <GridStyled
                    position={'relative'}
                    container
                    flexWrap={'nowrap'}
                    columnGap={'6px'}
                    height={'25px'}
                    width={'fit-content'}>
                    {value.map((selectedVal, index) =>
                      index === 0 ? (
                        <CategoryPill
                          onMouseDown={event => {
                            event.stopPropagation();
                            onChooseSpecialisation({ title: selectedVal });
                          }}>
                          <CategoryPillTitle>{selectedVal}</CategoryPillTitle>
                          <IconButtonStyled sx={{ padding: 0 }}>
                            <XMarkStyled width={14} height={14} />
                          </IconButtonStyled>
                        </CategoryPill>
                      ) : null
                    )}
                    <CategoryPill
                      sx={{
                        minWidth: '35px',
                        display: 'flex',
                        justifyContent: 'center'
                      }}>
                      <CategoryPillTitle>{value.length - 1}+</CategoryPillTitle>
                    </CategoryPill>
                  </GridStyled>
                ) : // IF THERE IS ONLY 1 SELECTION
                Array.isArray(value) && value.length === 1 ? (
                  <CategoryPill
                    position={'relative'}
                    onMouseDown={event => {
                      event.stopPropagation();
                      onChooseSpecialisation({ title: value });
                    }}>
                    <CategoryPillTitle>{value}</CategoryPillTitle>
                    <IconButtonStyled sx={{ padding: 0 }}>
                      <XMarkStyled width={14} height={14} />
                    </IconButtonStyled>
                  </CategoryPill>
                ) : null;
              }}>
              <TextFieldStyled
                size="small"
                defaultValue={searchText}
                value={searchText}
                onChange={event => handleSearchInput(event)}
                placeholder="Search Specialisation"
                sx={{
                  margin: '16px 16px 8px 16px',
                  display: 'flex'
                }}
                inputProps={{
                  style: {
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    lineHeight: '20px'
                  }
                }}
              />

              {/* SEARCH TEXT FIELD SECTION START */}
              <GridStyled
                container
                paddingX={'16px'}
                justifyContent={'space-between'}
                alignItems={'center'}
                paddingBottom={'8px'}
                borderBottom={'1px solid #D8D8D8'}>
                <SelectTextStyled variant="body1" sx={{ fontSize: '12px' }}>
                  {filters?.specialisationIds.length ?? 0} selected
                </SelectTextStyled>
                {selectedMainCat || matchResult.length > 0 ? null : (
                  <TextButtonStyled
                    variant="text"
                    onClick={() => handleSelectAll()}>
                    {selectedAll ? 'Deselect All' : 'Select All'}
                  </TextButtonStyled>
                )}
              </GridStyled>
              {/* SEARCH TEXT FIELD SECTION END */}

              {/* ONLY VISIBLE IF WITHIN SUBCATEGORY SECTION */}
              {selectedMainCat ? (
                <GridStyled
                  columnGap={'8px'}
                  padding={'8px 16px'}
                  container
                  alignItems={'center'}
                  flexWrap={'nowrap'}>
                  <IconButtonStyled
                    onClick={() => setSelectedMainCat(null)}
                    sx={{ padding: 0 }}>
                    <ChevronLeftIcon width={20} height={20} />
                  </IconButtonStyled>
                  <FormControlLabel
                    control={
                      <CheckboxStyled
                        checked={specialisation
                          .find(spec => spec.title === selectedMainCat)
                          ?.subCategories.every(subCat =>
                            filters['specialisations'].includes(subCat.title)
                          )}
                        onClick={() => {
                          const isSelecting = specialisation
                            .find(spec => spec.title === selectedMainCat)
                            ?.subCategories.every(subCat =>
                              filters['specialisations'].includes(subCat.title)
                            );

                          if (isSelecting === true) {
                            // trying to deselect
                            specialisation
                              .find(spec => spec.title === selectedMainCat)
                              ?.subCategories?.map(subCat => {
                                filters['specialisationIds'].indexOf(
                                  subCat?.id
                                ) === -1
                                  ? {}
                                  : onChooseSpecialisation(subCat);
                              });
                          } else {
                            specialisation
                              .find(spec => spec.title === selectedMainCat)
                              ?.subCategories?.map(subCat =>
                                filters['specialisationIds'].indexOf(
                                  subCat?.id
                                ) === -1
                                  ? onChooseSpecialisation(subCat)
                                  : {}
                              );
                          }
                          // select all sub categories
                        }}
                        sx={{
                          '&.Mui-checked': {
                            color: '#222'
                          },
                          padding: '0 9px'
                        }}
                      />
                    }
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: 14,
                        letterSpacing: '0.15px'
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: 20
                      }
                    }}
                    label={
                      <MainCategoryText
                        variant="body1"
                        sx={{ fontWeight: 700, maxWidth: '100%' }}>
                        {selectedMainCat}
                      </MainCategoryText>
                    }
                  />
                </GridStyled>
              ) : null}

              {/* SELECTION OPTIONS */}
              {matchResult.length > 0
                ? matchResult.map((match, index) => (
                    <SearchResultSpecialisation
                      item={match}
                      reduxFilter={filters}
                      onChooseSpecialisation={onChooseSpecialisation}
                    />
                  ))
                : selectedMainCat
                ? specialisation
                    ?.find(spec => spec.title === selectedMainCat)
                    ?.subCategories?.map((item, index) => {
                      return (
                        <SpecialisationSelection
                          item={item}
                          index={index}
                          key={`${item.title}-${index}`}
                          onClick={() => onChooseSpecialisation(item)}
                          checked={
                            filters['specialisations']?.indexOf(item.title) > -1
                          }
                        />
                      );
                    })
                : specialisation?.map((item, index) => (
                    <MainSpecialisation
                      specialisation={item}
                      handleClick={setSelectedMainCat}
                      reduxFilter={filters}
                      spec={specialisation}
                    />
                  ))}
            </SelectStyled>
          </FormControl>
        </Fragment>
      )}
    </div>
  );
};

export default JobSpecialisation;

export const MainSpecialisation = ({
  specialisation,
  handleClick,
  reduxFilter,
  spec
}) => {
  const { specialisationIds: _reduxSpecIds } = reduxFilter;

  const getSubCategoriesCount = majorCategory => {
    let count = 0;

    if (_reduxSpecIds.length > 0) {
      _reduxSpecIds.map(subcat => {
        majorCategory.subCategories.map(mSubs => {
          if (subcat == mSubs.id) {
            count = count + 1;
          }
        });
      });
    }

    return count;
  };

  return (
    <GridStyled
      padding={'8px 16px'}
      container
      justifyContent={'space-between'}
      alignItems={'center'}
      flexWrap={'nowrap'}
      sx={{
        '&:hover': {
          background: '#EFEFEF'
        },
        cursor: 'pointer'
      }}
      onClick={() => {
        handleClick(specialisation.title);
      }}>
      <GridStyled
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        flexWrap={'nowrap'}>
        <MainCategoryText variant="body1">
          {specialisation.title}
        </MainCategoryText>
        {getSubCategoriesCount(specialisation) > 0 && (
          <SelectedCountContainer>
            {getSubCategoriesCount(specialisation)}
          </SelectedCountContainer>
        )}
      </GridStyled>
      <IconButtonStyled
        onClick={() => {
          handleClick(specialisation.title);
        }}
        sx={{ padding: 0 }}>
        <ChevronRightIcon width={20} height={20} color="rgba(0,0,0,0.60)" />
      </IconButtonStyled>
    </GridStyled>
  );
};

export const SearchResultSpecialisation = ({
  item,
  reduxFilter,
  onChooseSpecialisation
}) => {
  return (
    <GridStyled borderBottom={'1px solid #EFEFEF'}>
      <SelectTextStyled
        margin={'8px 16px 0px'}
        variant="body1"
        sx={{
          fontWeight: '700',
          color: 'rgba(0,0,0,0.87)',
          height: 'fit-content'
        }}>
        {item.title}
      </SelectTextStyled>

      {Array.isArray(item.subCategories) &&
        item.subCategories.map((subCat, index) => (
          <SpecialisationSelection
            item={subCat}
            index={index}
            checked={
              reduxFilter['specialisations']?.indexOf(subCat?.title) > -1
            }
            onClick={() => onChooseSpecialisation(subCat)}
            isSearchResult={true}
          />
        ))}
    </GridStyled>
  );
};

export const SpecialisationSelection = ({
  item,
  index,
  checked,
  onClick,
  isSearchResult
}) => {
  return (
    <GridStyled
      key={`${item.title}-${index}`}
      sx={{
        cursor: 'pointer',
        padding: '8px 15px',

        '&:hover': {
          background: '#EFEFEF'
        }
      }}
      container
      flexDirection={'column'}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onClick={onClick}
            sx={{
              '&.Mui-checked': {
                color: '#222'
              },
              padding: '0px 9px 0px 0px',
              marginLeft: isSearchResult ? '9px' : '38px'
            }}
          />
        }
        label={item.title}
        sx={{
          '& .MuiTypography-root': {
            fontSize: 14,
            letterSpacing: '0.15px'
          },
          '& .MuiSvgIcon-root': {
            fontSize: 20
          },
          alignItems: 'flex-start'
        }}
      />
    </GridStyled>
  );
};
