import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../../../../components';
import { MUIButtonStyled } from '../../../../components/MUIButton';
import {
  getTalents,
  hideTalent,
  updateModelState,
  updateSearchTalentFilters
} from '../../../../redux/actions/talent_action';
import store from '../../../../redux/stores/store';
import { ErrorToast, InfoToast } from '../../../../utils/ToastUtils';
import {
  ButtonStyled,
  CheckboxStyled,
  ContentBoldStyled,
  ContentStyled,
  DialogContentStyled,
  FormControlLabelStyled,
  Wrapper
} from '../styles';

export const HideAndUnhideModal = () => {
  const dispatch = useDispatch();

  const filters = useSelector(state => state.talents.searchTalentFilters);
  const loading = useSelector(state => state.talents.hidingProfile);
  const talent = useSelector(state => state.talents.talent);

  const [checked, setChecked] = useState(false);

  const checkBoxHandler = event => {
    setChecked(event.target.checked);
  };

  const cancelHandler = () => {
    dispatch(updateModelState(false));
  };

  const triggerLoadTalents = (direction, page) => {
    if (page == 1 && store.getState().talents.hideNoResultFilter) {
      store.getState().talents.hideNoResultFilter = false;
    }

    if (
      page == 1 &&
      store.getState().talents.searchResumeTalentsCurrentPage != 1
    ) {
      store.getState().talents.searchResumeTalentsCurrentPage = 1;
    }

    if (page == 1) {
      store.getState().talents.talentsFilterNoResult = false;
    }

    let cFilters = { ...store.getState().talents.searchTalentFilters };

    let pageInfo = { ...store.getState().talents.talentsPageInfo };

    if (direction == 'next') {
      delete cFilters.before;

      cFilters.after = pageInfo.endCursor;
    } else {
      if (cFilters.after) {
        delete cFilters.after;
      }

      cFilters.before = pageInfo.startCursor;
    }

    if (page == 1) {
      delete cFilters.before;

      cFilters.after = '';
    }

    dispatch(updateSearchTalentFilters(cFilters)).then(() => {
      if (store.getState().talents.talentsFilterNoResult) {
        const params = {
          after: '',
          filterMode: 'or',
          keyword: '',
          wordMatch: 'word',
          advancedSearch: true,
          trackIds: [],
          specialisations: [],
          specialisationIds: [],
          industryIds: [],
          languages: [],
          nationalities: [],
          withVideoCoverLetter: false,
          freshGrad: null,
          // minGraduationYear: moment().year() - 100,
          // maxGraduationYear: moment().year(),
          sort: null,
          educationLevels: [],
          resumeLastUpdated: null,
          minYearExperience: 0,
          showHiddenProfile: false
        };

        if (direction == 'next') {
          delete params.before;

          params.after = pageInfo.endCursor;
        } else {
          if (params.after) {
            delete params.after;
          }

          params.before = pageInfo.startCursor;
        }

        if (page == 1) {
          delete params.before;

          params.after = '';
        }

        dispatch(getTalents(params));
      } else {
        dispatch(getTalents(cFilters));
      }

      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 200);
    });
  };

  const trackHideProfile = params => {
    window.dataLayer.push({
      event: 'CE_click-hide-profile',
      hide: params.hideProfile,
      'user-id': params.userId
    });
  };

  const hideUnhideHandler = () => {
    if (checked) {
      localStorage.setItem('hideProfileConfirmation', true);
    }

    const params = {
      userId: talent.id,
      hideProfile: true
    };

    dispatch(hideTalent(params)).then(res => {
      if (res.type == 'HIDE_PROFILE_SUCCEED') {
        trackHideProfile(params);
        InfoToast('Hide Successfully');

        setTimeout(() => {
          // dispatch(getTalents(filters));
          triggerLoadTalents('', 1);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 200);
      } else {
        ErrorToast('Failed to hide this candidate');
      }
    });

    dispatch(updateModelState(false));
  };

  return (
    <DialogContentStyled>
      <Wrapper
        container
        direction="column"
        justifyContent="flex-start"
        sx={{ marginTop: '0px' }}>
        <ContentStyled>Are you sure you want hide this profile?</ContentStyled>
        <FormControlLabelStyled
          onChange={checkBoxHandler}
          control={<CheckboxStyled checked={checked} />}
          label="Do not show this message again"
        />
      </Wrapper>

      <Wrapper
        container
        justifyContent="space-between"
        sx={{ marginTop: '16px' }}>
        <ButtonStyled
          variant="outlined"
          smallText
          onClick={cancelHandler}
          sx={{
            ':hover': {
              border: '2px solid #512acc'
            }
          }}>
          No, Cancel
        </ButtonStyled>

        <ButtonStyled variant="contained" onClick={hideUnhideHandler} smallText>
          {loading ? (
            <Spinner size="XS" noPadding={true} color={'white'} />
          ) : (
            '  Yes, Hide This Profile'
          )}
        </ButtonStyled>
      </Wrapper>
    </DialogContentStyled>
  );
};
