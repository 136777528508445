import * as types from '../types/product_type';

const initialState = {
  confirmOrderPath: '',
  isNewInfoPopupOpen: false,
  isProductInactiveOpen: false,
  showDiscountedCreditsPopup: false,

  // FETCH ACTIVE PRODUCTS
  activeProducts: [],
  fetchingActiveProducts: false,

  // FETCH PRODUCT BY CATEGORIES
  allActiveProducts: [],
  allProducts: [],
  fetchingAllActiveProducts: false,

  // FETCH PACKAGE PLAN DETAILS
  packagePlanDetails: [],
  fetchingPackagePlanDetails: false,

  // UPDATE PRODUCT BUNDLE
  selectedProductBundle: 0,

  // GET PRODUCT CAROUSEL
  productCarousel: [],
  fetchingProductCarousel: false,

  // GET PRODUCT TRUSTED LOGO
  trustedLogo: [],
  fetchingTrustedLogo: false,

  // GET HOMEPAGE BANNER
  homepageBanner: [],
  fetchingHomepageBanner: false,

  // MAX COUNT FOR LIMITED PRODUCTS
  productsInBundle: []
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_PRODUCT_BUNDLE: {
      return {
        ...state,
        selectedProductBundle: action.status
      };
    }

    case types.UPDATE_NEW_INFO_POPUP: {
      return {
        ...state,
        isNewInfoPopupOpen: action.status
      };
    }


    case types.UPDATE_PRODUCT_INACTIVE_POPUP: {
      return {
        ...state,
        isProductInactiveOpen: action.status
      };
    }

    case types.UPDATE_DISCOUNTED_CREDITS_POPUP_STATUS: {
      return {
        ...state,
        showDiscountedCreditsPopup: action.status
      };
    }

    // FETCH ACTIVE PRODUCTS
    case types.FETCH_ACTIVE_PRODUCTS: {
      return {
        ...state,
        fetchingActiveProducts: true
      };
    }
    case types.FETCH_ACTIVE_PRODUCTS_SUCCEED: {
      return {
        ...state,
        fetchingActiveProducts: false,
        activeProducts: action.activeProducts
      };
    }
    case types.FETCH_ACTIVE_PRODUCTS_FAILED: {
      return {
        ...state,
        fetchingActiveProducts: false
      };
    }

    // FETCH PRODUCT BY CATEGORIES
    case types.FETCH_PRODUCT_BY_CATEGORIES: {
      return {
        ...state,
        fetchingAllActiveProducts: true
      };
    }
    case types.FETCH_PRODUCT_BY_CATEGORIES_SUCCEED: {
      return {
        ...state,
        fetchingAllActiveProducts: false,
        allActiveProducts: action.allActiveProducts,
        allProducts: action.allProducts
      };
    }
    case types.FETCH_PRODUCT_BY_CATEGORIES_FAILED: {
      return {
        ...state,
        fetchingAllActiveProducts: false
      };
    }

    // FETCH PACKAGE PLAN DETAILS
    case types.FETCH_PACKAGE_PLAN_DETAILS: {
      return {
        ...state,
        fetchingPackagePlanDetails: true
      };
    }
    case types.FETCH_PACKAGE_PLAN_DETAILS_SUCCEED: {
      return {
        ...state,
        fetchingPackagePlanDetails: false,
        packagePlanDetails: action.packagePlanDetails
      };
    }
    case types.FETCH_PACKAGE_PLAN_DETAILS_FAILED: {
      return {
        ...state,
        fetchingPackagePlanDetails: false
      };
    }

    // GET PRODUCT CAROUSEL
    case types.FETCH_PRODUCT_CAROUSEL: {
      return {
        ...state,
        fetchingProductCarousel: true
      };
    }
    case types.FETCH_PRODUCT_CAROUSEL_SUCCEED: {
      return {
        ...state,
        fetchingProductCarousel: false,
        productCarousel: action.productCarousel
      };
    }
    case types.FETCH_PRODUCT_CAROUSEL_FAILED: {
      return {
        ...state,
        fetchingProductCarousel: false
      };
    }

    // GET PRODUCT TRUSTED LOGO
    case types.FETCH_PRODUCT_TRUSTED_LOGO: {
      return {
        ...state,
        fetchingTrustedLogo: true
      };
    }
    case types.FETCH_PRODUCT_TRUSTED_LOGO_SUCCEED: {
      return {
        ...state,
        fetchingTrustedLogo: false,
        trustedLogo: action.trustedLogo
      };
    }
    case types.FETCH_PRODUCT_TRUSTED_LOGO_FAILED: {
      return {
        ...state,
        fetchingTrustedLogo: false
      };
    }
    case types.UPDATE_LIMITED_PRODUCT: {
      return {
        ...state,
        productsInBundle: action.productsInBundle
      };
    }

    // GET HOMEPAGE BANNER
    case types.FETCH_HOMEPAGE_BANNER: {
      return {
        ...state,
        fetchingHomepageBanner: true
      };
    }
    case types.FETCH_HOMEPAGE_BANNER_SUCCEED: {
      return {
        ...state,
        fetchingHomepageBanner: false,
        homepageBanner: action.homepageBanner
      };
    }
    case types.FETCH_HOMEPAGE_BANNER_FAILED: {
      return {
        ...state,
        fetchingHomepageBanner: false
      };
    }

    case types.CLEAR_PRODUCT_INFO: {
      return {};
    }

    default: {
      return state;
    }
  }
};
