import { styled as styling } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export const TextFieldStyled = styling(TextField)(props => ({
  width: '100%',
  border: '1px solid #aeaeae',
  background: 'transparent',
  borderRadius: '8px',

  '& .MuiFilledInput-multiline': {
    padding: '27px 12px 10px',
  },

  ...(props.exclude_text_area == 'true' && {
    border: 'none'
  }),
}));
