import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import Grid from '@mui/material/Grid';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import moment from 'moment';
import { debounce } from 'lodash';
import { TextInputFunction } from '../SharedTextInput/SharedTextInput';
import {
  Title,
  LabelStyled,
  SortByContainer,
  CardsContainer,
  CardStyled,
  CardContentStyled,
  TextContainer,
  ApplicantCardSection,
  ChipStyled,
  UnreadIndicator
} from './styles';
import SkeletonLoader from './SkeletonLoader';
import Linkify from 'react-linkify';
import { useDispatch } from 'react-redux';
import {
  fetchJobChats,
  updateChatReadStatus
} from '../../../redux/actions/chat_action';

let loadingJobApplications = false;
let currentSearchText = '';
let cSearchText = '';
let afterCursor = '';
let cCursor = '';
let cChatBotStatus = [];
let cOrderDirection = 'desc';
let cJobId = '';

const sortingConstant = {
  UNREAD_DESC: {
    key: 'UNREAD_DESC',
    label: 'Unread Chat First',
    sortBy: 'unread_chat',
    order: 'desc'
  },

  APPLIED_DESC: {
    key: 'APPLIED_DESC',
    label: 'Most Recent Application',
    sortBy: 'applied_at',
    order: 'desc'
  },

  APPLIED_ASC: {
    key: 'APPLIED_ASC',
    label: 'Earliest Application',
    sortBy: 'applied_at',
    order: 'asc'
  }
};

const ApplicantSectionComponent = forwardRef((props, ref) => {
  const [jobApplications, setJobApplications] = useState([]);
  const [currentApplicationId, setCurrentApplicationId] = useState('');
  const [reachedEnd, setReachedEnd] = useState(false);
  const [orderDirection, setOrderDirection] = useState(
    sortingConstant.UNREAD_DESC.key
  );
  const [searchApplicantsArrowDrop, setSearchApplicantsArrowDrop] =
    useState(null);
  const [applicantFilter, setApplicantFilter] = useState(null);
  const [chatBotStatus, setChatBotStatus] = useState([]);

  const [currentSort, setCurrentSort] = useState(sortingConstant.UNREAD_DESC);

  const [fetchingChatRoomListings, setFetchingChatRoomListings] =
    useState(false);

  const dispatch = useDispatch();

  const { updateChatChannel, updateApplication } = props;

  const loadJobApplications = async (refresh, isPerformSearch) => {
    if (
      (loadingJobApplications ||
        fetchingChatRoomListings ||
        (reachedEnd && !refresh)) &&
      !isPerformSearch
    )
      return;

    loadingJobApplications = true;

    if (refresh || isPerformSearch) {
      setJobApplications([]);
      setReachedEnd(false);
      updateChatChannel('');
      setCurrentApplicationId('');
      afterCursor = '';
      cCursor = '';
    }

    setFetchingChatRoomListings(true);
    dispatch(
      fetchJobChats({
        first: 10,
        after: cCursor,
        search: cSearchText,
        chatStatus: [2],
        sort: { by: currentSort.sortBy, direction: currentSort.order },
        jobId: cJobId,
        chatBotStatus: cChatBotStatus.length > 0 ? cChatBotStatus : null
      })
    ).then(data => {
      if (data) {
        setFetchingChatRoomListings(false);
        const currentJobApplications = cCursor == '' ? [] : jobApplications;
        if (data.chatRoomListings?.pageInfo?.endCursor) {
          // Cannot update cCursor here as this will trigger the query none-stop
          afterCursor = data.chatRoomListings.pageInfo.endCursor;
        }

        const cJobApplicationDatas = JSON.parse(JSON.stringify(data));
        const cJobApplications =
          cJobApplicationDatas?.chatRoomListings?.edges?.map(item => item.node);

        if (cJobApplications?.length < 10) {
          setReachedEnd(true);
        }

        if (cSearchText) {
          window.dataLayer.push({
            event: 'CE_search-applicant-chats',
            'search-term': cSearchText,
            'sort-by': `${currentSort.sortBy}_${currentSort.order}`
          });
        }

        if (cJobApplications && currentJobApplications) {
          setJobApplications([...currentJobApplications, ...cJobApplications]);
        }
      }
    });
  };

  const updateJobId = jobId => {
    cJobId = jobId;
  };

  const getCurrentSearchText = () => currentSearchText;
  const getOrderDirection = () => orderDirection;

  // Application list management
  const handleApplicationClicked = jobApplication => {
    if (currentApplicationId === jobApplication.id) return;

    let _localJobApplications = jobApplications;

    const targetIndex = _localJobApplications.findIndex(
      item => item.id === jobApplication.id
    );

    _localJobApplications[targetIndex].employerReadReceipt = true;

    dispatch(
      updateChatReadStatus({
        chatId: jobApplication.id,
        chatType: 'job_application_chat'
      })
    );

    setCurrentApplicationId(jobApplication.id); // active indicator
    updateChatChannel(
      jobApplication.sendbirdChannelUrl
        ? jobApplication.sendbirdChannelUrl
        : 'no-channel'
    );
    updateApplication(jobApplication);

    setJobApplications(_localJobApplications);
  };

  const replaceJobApplication = jobApplication => {
    const index = jobApplications.findIndex(
      application => application.id === jobApplication.id
    );
    if (index !== -1) {
      const cApplications = JSON.parse(JSON.stringify(jobApplications));
      cApplications[index] = jobApplication;

      setJobApplications(cApplications);
    }
  };

  const refreshJobApplication = () => {
    loadJobApplications(true, false);
  };

  //  To allow parent to call this function
  useImperativeHandle(ref, () => ({
    updateJobId,
    replaceJobApplication,
    refreshJobApplication,
    getCurrentSearchText,
    getOrderDirection,
    loadJobApplications
  }));

  const performSearch = async text => {
    if (text === currentSearchText) {
      setReachedEnd(false);
      setJobApplications([]);
      if (cJobId !== '') {
        cSearchText = text;
        loadJobApplications(true, true);
      }
    }
  };

  const debouncedSearch = debounce(performSearch, 700);

  const handleScroll = e => {
    //  Reference: https://stackoverflow.com/questions/45585542/detecting-when-user-scrolls-to-bottom-of-div-with-react-js/49573628
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      cCursor = afterCursor;
      loadJobApplications(false, false);
    }
  };

  const handleChangeSearchApplicants = async event => {
    currentSearchText = event.target.value;
    debouncedSearch(event.target.value);
  };

  const handleSearchApplicantArrowDropClick = event => {
    setSearchApplicantsArrowDrop(event.currentTarget);
  };

  const handleCloseOrderMenu = value => {
    setSearchApplicantsArrowDrop(null);

    if (value !== '' && value !== orderDirection) {
      cOrderDirection = value;
      setOrderDirection(value);

      setCurrentSort(sortingConstant[value] ?? {});

      // Reload list on order direction changed
      loadJobApplications(true, false);
    }
  };

  // Extract application information
  const getApplicantName = jobApplication => {
    if (jobApplication.user?.name) {
      return jobApplication.user.name;
    }

    return '';
  };

  const getShortSummary = jobApplication => {
    if (jobApplication.user?.shortSummary) {
      return jobApplication.user.shortSummary;
    }
    return '';
  };

  const getAppliedDate = jobApplication => {
    if (jobApplication.appliedAt) {
      return moment(jobApplication.appliedAt).format('Do MMM YYYY');
    }

    return '';
  };

  const getApplicationStatus = jobApplication => {
    if (jobApplication.state) {
      return jobApplication.state;
    }
    return '';
  };

  const handleApplicantFilter = event => {
    setApplicantFilter(event.currentTarget);
  };

  const handleApplicantFilterClosed = () => {
    setApplicantFilter(null);
  };

  const onChatFilterClicked = botStatus => () => {
    if (chatBotStatus.length == botStatus.length) return;

    cChatBotStatus = botStatus;
    setChatBotStatus(botStatus);
    setApplicantFilter(null);

    // Reload job application/chat rooms
    loadJobApplications(true, false);
  };

  // Use effects
  useEffect(() => {
    return () => {
      loadingJobApplications = false;
      currentSearchText = '';
      cSearchText = '';
      afterCursor = '';
      cCursor = '';
      cOrderDirection = 'desc';
      cJobId = '';
    };
  }, []);

  useEffect(() => {
    if (!fetchingChatRoomListings) {
      loadingJobApplications = false;
    }
  }, [fetchingChatRoomListings]);

  return (
    <ApplicantCardSection onScroll={handleScroll}>
      <>
        <TextInputFunction
          origin={'chat_middle_section'}
          type="text"
          label="applicant-search-bar"
          // value={searchApplicants}
          placeholder="Search applicants by keyword"
          handleChange={handleChangeSearchApplicants}
          onClick={handleApplicantFilter}
        />

        <Menu
          id="applicant-filter"
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          anchorEl={applicantFilter}
          keepMounted
          MenuProps={{
            PaperProps: {
              style: {
                background: '#fff'
              }
            }
          }}
          open={Boolean(applicantFilter)}
          onClose={() => handleApplicantFilterClosed('')}
          getContentAnchorEl={null}>
          <MenuItem
            onClick={onChatFilterClicked([])}
            selected={chatBotStatus.length == 0}>
            All
          </MenuItem>
          <MenuItem
            onClick={onChatFilterClicked([12])}
            selected={chatBotStatus.length > 0}>
            AIVI Completed
          </MenuItem>
        </Menu>
      </>

      <SortByContainer>
        <LabelStyled sort_by="true">
          Sort by <b>{currentSort.label}</b>
        </LabelStyled>
        <ArrowDropDownIcon
          fontSize="small"
          style={{ marginLeft: '5px', cursor: 'pointer' }}
          onClick={handleSearchApplicantArrowDropClick}
        />
        <Menu
          id="date-created-menu"
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          anchorEl={searchApplicantsArrowDrop}
          keepMounted
          MenuProps={{
            PaperProps: {
              style: {
                background: '#fff'
              }
            }
          }}
          open={Boolean(searchApplicantsArrowDrop)}
          onClose={() => handleCloseOrderMenu('')}
          getContentAnchorEl={null}>
          <MenuItem
            onClick={() =>
              handleCloseOrderMenu(sortingConstant.UNREAD_DESC.key)
            }
            selected={orderDirection === sortingConstant.UNREAD_DESC.key}>
            {sortingConstant.UNREAD_DESC.label}
          </MenuItem>
          <MenuItem
            onClick={() =>
              handleCloseOrderMenu(sortingConstant.APPLIED_DESC.key)
            }
            selected={orderDirection === sortingConstant.APPLIED_DESC.key}>
            {sortingConstant.APPLIED_DESC.label}
          </MenuItem>
          <MenuItem
            onClick={() =>
              handleCloseOrderMenu(sortingConstant.APPLIED_ASC.key)
            }
            selected={orderDirection === sortingConstant.APPLIED_ASC.key}>
            {sortingConstant.APPLIED_ASC.label}
          </MenuItem>
        </Menu>
      </SortByContainer>

      <CardsContainer>
        {jobApplications &&
          jobApplications.map(jobApplication => (
            <CardStyled
              key={jobApplication.id}
              elevation={0}
              active={
                currentApplicationId === jobApplication.id ? 'true' : 'false'
              }
              onClick={() => handleApplicationClicked(jobApplication)}>
              {jobApplication.employerReadReceipt === false && (
                <UnreadIndicator></UnreadIndicator>
              )}
              <CardContentStyled applicant_card_height="true">
                <TextContainer>
                  <Title>{getApplicantName(jobApplication)}</Title>
                  <Grid style={{ margin: '10px 0' }}>
                    <LabelStyled applicant_card_info="true">
                      <Linkify>{getShortSummary(jobApplication)}</Linkify>
                    </LabelStyled>
                  </Grid>
                  <Grid
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}>
                    <Grid
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                      <WatchLaterIcon
                        fontSize="small"
                        style={{ marginRight: '10px', color: '#aeaeae' }}
                      />
                      <LabelStyled applicant_card_info_time="true">
                        {getAppliedDate(jobApplication)}
                      </LabelStyled>
                    </Grid>
                    <ChipStyled label={getApplicationStatus(jobApplication)} />
                  </Grid>
                </TextContainer>
              </CardContentStyled>
            </CardStyled>
          ))}
      </CardsContainer>

      {!fetchingChatRoomListings &&
        reachedEnd &&
        jobApplications.length === 0 && (
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            No active chats.
          </div>
        )}
      {!fetchingChatRoomListings &&
        reachedEnd &&
        jobApplications.length !== 0 && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            End of list.
          </div>
        )}
      {fetchingChatRoomListings &&
        Array.from(['0', '1', '2', '3', '4', '5']).map(index => (
          <SkeletonLoader key={index} />
        ))}
    </ApplicantCardSection>
  );
});

export default ApplicantSectionComponent;
