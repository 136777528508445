import { styled as styling } from '@mui/material/styles';
import Colors from '../../styles/colors.module.scss';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';

export const Container = styling('div')(({
  $animation,
  $isOpen,
  $backgroundColor,
  $height,
  $margin,
  $borderRadius,
  $padding,
  $display,
  $justifyContent,
  $alignItems
}) => ({
  background: Colors.secLightPurple,
  width: '100%',
  position: 'relative',

  ...(($animation == 'close') && {
    opacity: 0,
    transition: 'all 1s ease-out',
  }),

  ...(($isOpen) && {
    animation: 'fadeIn 1s ease-in',
    '@keyframes fadeIn': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
  }),

  ...(($backgroundColor) && {
    background: $backgroundColor,
  }),

  ...(($height) && {
    height: $height,
  }),

  ...(($margin) && {
    margin: $margin,
  }),

  ...(($borderRadius) && {
    borderRadius: $borderRadius,
  }),

  ...(($padding) && {
    padding: $padding,
  }),

  ...(($display) && {
    display: $display,
    ...(($justifyContent) && {
      justifyContent: $justifyContent,
    }),
    ...(($alignItems) && {
      alignItems: $alignItems,
    }),
  }),
}));

export const DialogTitleStyled = styling(DialogTitle)({
  padding: '16px 24px',
});

export const DialogContentStyled = styling(DialogContent)({
  padding: '16px 24px',
});

export const DialogActionsStyled = styling(DialogTitle)({
  padding: '0',
});

export const IconButtonStyled = styling(IconButton)({
  padding: '0',
});

