import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TALENTS_SORT_BY } from '../../../../utils/Constants';
import styles from './index.module.scss';

const useStyles = makeStyles({
  root: {
    height: 30,
    minWidth: 150,
    textAlign: 'center',
    padding: '0px 10px',

    '& .MuiSelect-select': {
      fontSize: 16,
      fontFamily: 'InterBold'
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent'
    }
  },
  menu: {
    '& .MuiList-root': {
      display: 'flex',
      flexDirection: 'column'
    }
  },

  menuItem: {
    width: '100%',

    '&.MuiButtonBase-root': {
      justifyContent: 'flex-start'
    }
  }
});

const TalentSort = ({ sortBy, setSortBy, setIsRefresh }) => {
  const classes = useStyles();

  const onSortBy = value => {
    try {
      setIsRefresh(true);
      setSortBy(value);

      window.dataLayer.push({
        event:
          value == 'LAST_ACTIVE_AT'
            ? 'sort-acv-latest-active-date'
            : 'sort-acv-latest-resume-uploaded'
      });
    } catch {
      
    }
  };

  return (
    <div className={styles.container}>
      <p className={styles.sortBy}>Sort by</p>
      <Select
        className={classes.root}
        disableUnderline
        onChange={event => onSortBy(event.target.value)}
        value={sortBy}
        MenuProps={{
          className: classes.menu,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}>
        <MenuItem
          key={1}
          value={TALENTS_SORT_BY.RELEVANCE}
          className={classes.menuItem}>
          <p className={styles.menuItem}>Relevance</p>
        </MenuItem>
        <MenuItem
          key={2}
          value={TALENTS_SORT_BY.LAST_ACTIVE_AT}
          className={classes.menuItem}>
          <p className={styles.menuItem}>Latest Active Date</p>
        </MenuItem>
        <MenuItem
          key={3}
          value={TALENTS_SORT_BY.UPLOAD_RESUME_AT}
          className={classes.menuItem}>
          <p className={styles.menuItem}>Latest Resume Uploaded</p>
        </MenuItem>
      </Select>
    </div>
  );
};

export default TalentSort;
