import { Grid } from '@mui/material';
import React from 'react';
import { ErrorTextStyled, ExclamationCircleIconStyled } from './styles';

export default function ErrorText({ children }) {
  return (
    <Grid sx={{ display: 'flex', flexDirection: 'row', gap: '6px', alignItems: "center", paddingTop: "2px" }}>
      <ExclamationCircleIconStyled />{' '}
      <ErrorTextStyled>{children}</ErrorTextStyled>
    </Grid>
  );
}
