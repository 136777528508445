import {
  IconButton,
  Tabs,
  Tab,
  FormControl,
  Select,
  TextField,
  Grid
} from '@mui/material';
import { styled as styling } from '@mui/material/styles';

export const HeaderContainer = styling('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px',
  alignItems: 'center',
  position: 'relative',
});

export const CardTitle = styling('span')({
  fontSize: '20px',
  fontFamily: 'Inter',
  fontWeight: 700,
  overflow: 'hidden',
  display: 'flex',
  flexWrap: 'wrap',
});

export const CardSubtitle = styling('span')({
  fontSize: '14px',
  fontFamily: 'Inter',
  letterSpacing: '0.15px',
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: '0.25rem',
});

export const NationalityTitle = styling('span')(({ theme }) => ({
  color: theme.palette.grey['200'],
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  fontSize: '14px',
  fontFamily: 'Inter',
}));

export const IconsContainer = styling('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  position: 'absolute',
  top: '16px',
  right: '16px',
});

export const IconButtonStyled = styling(IconButton)(({ theme }) => ({
  background: 'transparent',
  '&:hover': {
    background: theme.palette.grey['300'],
  },
}));

export const TabsStyled = styling(Tabs)({
  minHeight: 0,
  height: '28px',
  margin: '0 16px',
});

export const TabStyled = styling(Tab)({
  padding: '0',
  fontSize: '10px',
  fontFamily: 'Inter',
  color: 'rgba(0, 0, 0, 0.6) !important',
  minHeight: 0,
  height: '28px',
  minWidth: '70px',
  letterSpacing: '1.5px',
});

export const TextFieldStyled = styling(TextField)({
  height: '40px',

  '& .MuiInputBase-root': {
    borderRadius: '8px'
  }
});

export const FormControlStyled = styling(FormControl)({
  width: '150px',
  maxHeight: '28px',
  backgroundColor: `rgba(239, 239, 239, 1)`,
  border: 'none',
  overflow: 'hidden',

  '& .MuiOutlinedInput-root': {
    border: 'none',
    borderRadius: '8px'
  },

  '&.MuiFormControl-root': {
    borderRadius: '8px'
  }
});

export const SelectStyled = styling(Select)({
  maxHeight: '28px',
  overflow: 'hidden',

  '& .MuiSelect-root': {
    borderRadius: '4px'
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
});

export const Wrapper = styling(Grid)({
  width: 'auto'
});

export const SimpleDescription = styling('span')(({ theme }) => ({
  color: theme.palette.black.main,
  fontSize: '12px',
  fontFamily: 'Inter'
}));

export const UnlockedAtStyled = styling('span')({
  color: 'rgba(0,0,0,0.6)',
  fontSize: '10px',
  fontFamily: 'Inter',
  fontStyle: 'italic'
});