import { styled as styling } from '@mui/material/styles';
import { ButtonBase } from '@mui/material';

export const FAQButton = styling(ButtonBase)(({ theme }) => ({
  background: theme.palette.grey['100'],
  outline: '1px solid #D8D8D8',
  borderRadius: '4px',
  padding: '4px 10px',
  cursor: 'pointer',
  gap: '6px',

  '&:hover': {
    background: theme.palette.white.main
  }
}));

export const FAQButtonText = styling('span')({
  fontFamily: 'Inter',
  fontWeight: '500',
  fontSize: '12px',
  background:
    'linear-gradient(270deg, #512ACC 0%, #6D32C5 45.87%, #983EBA 101.73%)',
  '-webkit-background-clip': 'text',
  '-webkit-text-fill-color': 'transparent'
});

export const FilterContainerStyle = styling('div')({
  // padding: '12px 16px',
  padding: '0',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '95%'
});
