import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HeightSpacer, Spacer } from '../Spacer/styles';
import {
  Text,
  SelectFormContainer,
  InputStyled,
  TagContainer,
  TagStyled,
  CloseIconStyled,
  KeywordButton,
  TextLimit,
  TagContainerSkeleton,
  TagStyledSkeleton,
  ErrorMessage,
  ClearInput
} from './styles';
import { ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useFormContext } from '../../pages/Jobs/PostJob/context';

const MAX_KEYWORD_LENGTH = 20;
const MAX_KEYWORDS = 5;

export const KeywordInput = () => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const [todo, setTodo] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { formState, handleSingleFieldChange } = useFormContext();
  const jobDataForm = useSelector(state => state?.jobs?.jobDataForm);

  let selectedJobKeywords = formState?.keywords;
  const [isFocused, setIsFocused] = useState(false);

  const onFocusHandler = () => {
    setIsFocused(true);
  };

  const onBlurHandler = () => {
    setIsFocused(false);
  };

  const transformedKeywords = Array.isArray(selectedJobKeywords)
    ? selectedJobKeywords?.map(keyword => ({ keyword }))
    : [];

  const clearAllInput = () => {
    setTodo('');
  };

  const addTodo = () => {
    if (!Array.isArray(selectedJobKeywords)) {
      selectedJobKeywords = [];
    }

    if (isValidKeyword(todo) && selectedJobKeywords?.length < MAX_KEYWORDS) {
      const updatedKeywords = Array.isArray(selectedJobKeywords)
        ? [...selectedJobKeywords, todo] // Store each keyword as an object
        : [todo];

      handleSingleFieldChange('keywords', updatedKeywords);
      setTodo('');
    }
  };

  const removeTodo = index => {
    const updatedTodos = [...selectedJobKeywords];
    updatedTodos.splice(index, 1);
    handleSingleFieldChange('keywords', updatedTodos);
  };

  const onKeyDownHandlerKeywords = event => {
    if (event.keyCode === 13) {
      addTodo();
    }
  };

  const onAddButtonClick = () => {
    addTodo();
  };

  const isValidKeyword = keyword => {
    return keyword?.trim() !== '' && keyword?.length <= MAX_KEYWORD_LENGTH;
  };

  const isAddButtonDisabled =
    todo?.trim() === '' ||
    todo?.length > 20 ||
    selectedJobKeywords?.length >= 5;

  const getErrorMessage = () => {
    if (selectedJobKeywords?.length >= 5 && todo?.trim() !== '') {
      return 'You have reached the maximum limit, please remove one of the keywords below to add another.';
    }

    if (todo?.length > 20) {
      return 'You have reached the maximum character limit.';
    }

    return null; // No error
  };

  const errorMessage = getErrorMessage();

  useEffect(() => {
    const keywords = selectedJobKeywords?.map(keyword => {
      return {
        keywords: keyword
      };
    });

    //  handleSingleFieldChange('keywords', keywords);
  }, [selectedJobKeywords]);

  return (
    <SelectFormContainer>
      <div style={{ position: 'relative' }}>
        <div style={{ position: 'relative' }}>
          <InputStyled
            type="text"
            placeholder={'Enter keyword'}
            value={todo}
            onChange={e => setTodo(e.target.value)}
            onKeyDown={onKeyDownHandlerKeywords}
            ref={inputRef}
            isRed={errorMessage}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}></InputStyled>

          {(isFocused || errorMessage || todo.length > 0) && (
            <>
              <TextLimit isExceedingLimit={todo.length > 20}>
                {todo.length > 0 && (
                  <ClearInput
                    class="clear-input-button"
                    aria-label="Clear input"
                    title="Clear input"
                    isFill={todo.length > 0}
                    onClick={clearAllInput}>
                    <XMarkIcon
                      style={{
                        width: '16px',
                        height: '16px',
                        color: 'rgba(0, 0, 0, 0.38)'
                      }}
                    />
                  </ClearInput>
                )}
                {todo.length}/{20}
              </TextLimit>
            </>
          )}

          <KeywordButton
            onClick={() => onAddButtonClick()}
            disabled={isAddButtonDisabled}>
            Add keyword
          </KeywordButton>
        </div>
      </div>

      {errorMessage && (
        <>
          <HeightSpacer height="8px" />
          <ErrorMessage isExceedingLimit={todo.length > 20}>
            <ExclamationCircleIcon
              style={{
                width: '20px',
                height: '20px',
                color: '#BE4242'
              }}
            />
            <span>{errorMessage}</span>
          </ErrorMessage>
        </>
      )}

      <HeightSpacer height="16px" />

      <TagContainerSkeleton>
        {[...Array(MAX_KEYWORDS)]?.map((_, index) => (
          <TagStyledSkeleton key={index} />
        ))}
      </TagContainerSkeleton>

      <TagContainer>
        {formState?.keywords?.map((keywordObject, index) => (
          <TagStyled key={index}>
            <Text>{keywordObject}</Text>
            <Spacer width="5px" />
            <CloseIconStyled onClick={() => removeTodo(index)} />
          </TagStyled>
        ))}
      </TagContainer>
    </SelectFormContainer>
  );
};

export default KeywordInput;
