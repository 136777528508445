import { MenuItem, Select } from '@mui/material';
import React, { useCallback } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import styles from '../index.module.scss';
import ownStyles from './index.module.scss';
import { SEARCH_TYPE } from '../../../../utils/Constants';
import SharedTextField from '../../../../components/SharedTextField';

const SearchDropdown = ({
  setSearchText,
  setIsRefresh,
  searchType,
  setSearchType
}) => {
  const search = _.debounce(value => {
    let searchTerm = value
      ?.toLowerCase()
      .replace(/^\s\s*/, '')
      .replace(/\s\s*$/, '');

    setSearchText(value);
    try {
      window.dataLayer.push({
        event: 'search-acv',
        acv_search_term: searchTerm
      });
    } catch {}
  }, 3000);

  const deboucedSearch = useCallback(value => search(value), []);

  const onSearch = value => {
    setIsRefresh(true);
    deboucedSearch(value);
  };

  const onSetSearchType = value => {
    setSearchType(value);
  };

  return (
    <div style={{ display: 'flex', margin: '0px 15px 0px 15px' }}>
      <SharedTextField
        placeholder="Search by Keywords"
        onChange={event => onSearch(event.target.value)}
      />
      <Select
        variant="outlined"
        className={classNames(styles.selectField)}
        value={searchType}
        onChange={event => onSetSearchType(event.target.value)}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}>
        <MenuItem key={SEARCH_TYPE.ANY} value={SEARCH_TYPE.ANY}>
          <p className={ownStyles.iconText}>Any of these words</p>
        </MenuItem>
        <MenuItem key={SEARCH_TYPE.ALL} value={SEARCH_TYPE.ALL}>
          <p className={ownStyles.iconText}>All of these words</p>
        </MenuItem>
        <MenuItem key={SEARCH_TYPE.EXACT} value={SEARCH_TYPE.EXACT}>
          <p className={ownStyles.iconText}>Exact Phrase</p>
        </MenuItem>
      </Select>
    </div>
  );
};

export default SearchDropdown;
