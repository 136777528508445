import React, { useEffect, useState, useRef } from 'react';
import DefaultRichTextEditor from '../../../../../components/DefaultRichTextEditor';
import { Grid, Typography } from '@mui/material';
import { useFormContext } from '../../context';
import {
  EditorState,
  ContentState,
  convertToRaw,
  CompositeDecorator
} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import AshleyButton from '../ashleyRewrite/ashleyButton';
import { HeightSpacer, Spacer } from '../../../../../components/Spacer/styles';
import { TipsTooltip } from '../../../../../components/Tooltips';
import {
  TipsDescription,
  UnorderlistStyled,
  ListStyled,
  Title,
  TitleContainer,
  ErrorBorder,
} from './styles';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import {
  updatePostJobPrevTooltip,
  updatePostJobTooltipStatus
} from '../../../../../redux/actions/job_action';
import { useDispatch, useSelector } from 'react-redux';
import Link from '../../../../../components/RichTextEditor/Link';

export default function Description() {
  const key = 'description';
  const { formState, handleEditorChange, errorState, handleErrorState } =
    useFormContext();
  const dispatch = useDispatch();

  function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    }, callback);
  }

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link
    }
  ]);

  // Use a ref to store the current HTML content for comparison
  const currentHtmlRef = useRef(formState.description);
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(decorator)
  );
  const tooltipStatus = useSelector(state => state.jobs.tooltipStatus);
  const prevTooltip = useSelector(state => state.jobs.prevTooltip);
  
  useEffect(() => {
    // Only update the editor state if the external HTML content has changed
    if (formState.description !== currentHtmlRef.current) {
      const blocksFromHtml = htmlToDraft(formState.description || '');
      if (blocksFromHtml) {
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
        // Update the currentHtmlRef with the new content
        currentHtmlRef.current = formState.description;
      }
    }
  }, [formState?.description]);

  const onChange = newEditorState => {
    handleErrorState(errorState.filter(error => error !== key));
    setEditorState(newEditorState);
    // Convert to HTML and update form state only if content has changed
    const newHtmlContent = draftToHtml(
      convertToRaw(newEditorState.getCurrentContent())
    );
    if (newHtmlContent !== currentHtmlRef.current) {
      handleEditorChange(newHtmlContent, key);
      // Update the ref to prevent unnecessary updates
      currentHtmlRef.current = newHtmlContent;
    }
  };

  const onClickTips = key => {
    let status = { ...tooltipStatus };

    if (status[key]) {
      status[key] = false;
      dispatch(updatePostJobTooltipStatus(status));
    } else {
      status[key] = true;
      dispatch(updatePostJobTooltipStatus(status));
    }

    if (prevTooltip.length > 0 && prevTooltip !== key) {
      status[prevTooltip] = false;
      dispatch(updatePostJobTooltipStatus(status));
    }

    dispatch(updatePostJobPrevTooltip(key));
  };

  return (
    <Grid container flexDirection="column" columnGap="10px">
      <Grid
        container
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '16px',
          alignItems: 'center',
          display: 'flex'
        }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Title>Job Description</Title>
          <Spacer width={'10px'} />
          <TipsTooltip
            title={'Job Description'}
            descriptions={[
              <TipsDescription>
                Our candidates prefer a short & concise summary of the role
                they’re applying to. Keep it to 5 bullet points, & use
                descriptive words to make it more personal.
              </TipsDescription>,
              <UnorderlistStyled>
                <ListStyled>
                  Curate and produce content for both employers and jobseekers.
                </ListStyled>
                <HeightSpacer height={'10px'} />
                <ListStyled>
                  Passionate about the latest news and social issues.
                </ListStyled>
              </UnorderlistStyled>
            ]}
            top={'-215px'}
            left={'-165px'}
            totalPages={2}
            minHeight={'200px'}
            onClick={() => onClickTips('jobDescription')}
            cKey={'jobDescription'}
          />
        </div>
        <AshleyButton origin={'description'} />
      </Grid>
      <ErrorBorder item isError={errorState.includes(key)}>
        <DefaultRichTextEditor
          editorState={editorState}
          onChange={onChange}
          placeholder="What is this job all about?"
        />
      </ErrorBorder>
      {errorState.includes(key) && (
        <p
        style={{
          color: '#BE4242',
          fontSize: '12px',
          lineHeight: '20px',
          display: 'flex',
          padding: '0px 8px',
          alignItems: 'center',
          gap: '8px'
        }}>
        <ExclamationCircleIcon
          style={{
            width: '20px',
            height: '20px',
            color: '#BE4242'
          }}
        />
        Job Description is required
      </p>
      )}
    </Grid>
  );
}
