import { styled as styling } from '@mui/material/styles';
import { Button, Grid } from '@mui/material';

export const CanvasStyled = styling('canvas')(props => ({
  display: 'block',
  width: 300,
  height: 200,
  ':hover': {
    display: 'none !important',
    width: '0px !important',
    height: '0px !important'
  }
}));

export const EmptyVideoContainer = styling(Grid)(props => ({
  backgroundColor: '#EFEFEF',
  width: 'auto',
  border: '1px dashed #707070',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: '8px',
  gap: '16px',
  cursor: 'pointer',
  minHeight: '164px',
  transition: 'all 0.3s',

  ':hover': {
    backgroundColor: '#E5E5E5'
  }
}));

export const CancelButton = styling(Button)(props => ({
  display: 'flex',
  height: '40px',
  padding: '0px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '8px',
  border: '1px solid #222',
  background: 'var(--Primary-White, #FFF)',
  textTransform: 'none',
  color: 'black',

  ':hover': {
    background: '#EFEFEF'
  }
}));

export const SequenceContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderTop: '1px solid #D8D8D8',
  padding: '16px',
  position: 'sticky',
  bottom: '0px',
  backgroundColor: 'white',
  zIndex: '500',
  borderRadius: '0px 0px 16px 16px'
}));
