import React, { useState } from 'react';
import { Chip, IconButton, Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  AddCircle as AddCircleIcon
} from '@mui/icons-material';
import DeleteTeamMemberPopUp from '../../PopUps/DeleteTeamMemberPopUp';
import AddEditAccessLevelPopUp from '../../PopUps/AddEditAccessLevelPopUp';
import AddEditJobTrackPopUp from '../../PopUps/AddEditJobTrackPopUp';
import AddEditTeamMemberPopUp from '../../PopUps/AddEditTeamMemberPopUp';
import styles from './index.module.scss';
import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { GridStyled } from './styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: '4px'
    },
    padding: '10px 0px'
  },
  chip: {
    border: '1.5px solid black',
    color: 'black !important'
  },
  menu: {
    width: '60%',

    '& .MuiList-root': {
      paddingTop: '10px',
      paddingBottom: '10px'
    }
  },
  menuItem: {
    width: '100%',

    '&.MuiMenuItem-root': {
      padding: '5px 15px 5px 15px'
    },

    '&.MuiButtonBase-root': {
      justifyContent: 'flex-start'
    }
  }
}));

const JobTracks = ({
  index,
  tableData,
  setTableData,
  editIndex,
  setEditIndex
}) => {
  const classes = useStyles();
  const { id, name, email, position, tracks, specialisations, accessLevels } =
    tableData[index];

  const reduxSpecialisations = useSelector(
    state => state?.jobs?.specialisation
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteMemberPopUpOpen, setIsDeleteMemberPopUpOpen] = useState(false);
  const [isTeamMemberPopUpOpen, setIsTeamMemberPopUpOpen] = useState(false);
  const [isAccessLevelPopUpOpen, setIsAccessLevelPopUpOpen] = useState(false);
  const [isJobTracksPopUpOpen, setIsJobTracksPopUpOpen] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickMenuItem = ({ setOpen }) => {
    setOpen(true);
    setAnchorEl(null);
  };

  const getAllCatCount = () => {
    const count = specialisations?.flatMap(category =>
      category?.subCategories?.map(subCat => subCat.id)
    )?.length;

    return count ?? 0;
  };

  const getAllSubCatCount = () => {
    const count = reduxSpecialisations.flatMap(category =>
      category?.subCategories?.map(subCat => subCat.id)
    )?.length;

    return count ?? 0;
  };

  return (
    <div className={styles.container}>
      <DeleteTeamMemberPopUp
        open={isDeleteMemberPopUpOpen}
        handleClose={() => setIsDeleteMemberPopUpOpen(false)}
        id={id}
        name={name}
      />
      <AddEditTeamMemberPopUp
        open={isTeamMemberPopUpOpen}
        handleClose={() => setIsTeamMemberPopUpOpen(false)}
        id={id}
        name={name}
        email={email}
        position={position}
      />
      <AddEditAccessLevelPopUp
        open={isAccessLevelPopUpOpen}
        handleClose={() => setIsAccessLevelPopUpOpen(false)}
        id={id}
        accessLvlData={accessLevels}
        index={index}
        tableData={tableData}
        setTableData={setTableData}
      />
      <AddEditJobTrackPopUp
        open={isJobTracksPopUpOpen}
        handleClose={() => setIsJobTracksPopUpOpen(false)}
        id={id}
        jobSpecialisations={specialisations}
        index={index}
        tableData={tableData}
        setTableData={setTableData}
        editIndex={editIndex}
        setEditIndex={setEditIndex}
      />

      <Menu
        id="simple-menu"
        className={classes.menu}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem
          className={classes.menuItem}
          onClick={() =>
            onClickMenuItem({ setOpen: setIsTeamMemberPopUpOpen })
          }>
          Edit Team Member
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() =>
            onClickMenuItem({ setOpen: setIsAccessLevelPopUpOpen })
          }>
          Edit Access Level
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            setEditIndex(index);
            onClickMenuItem({ setOpen: setIsJobTracksPopUpOpen });
          }}>
          Edit Job Tracks
        </MenuItem>
      </Menu>

      <GridStyled container flexWrap={'wrap'} alignItems={'center'} gap={'8px'}>
        {parseInt(getAllCatCount()) && getAllCatCount() > 0 ? (
          <Chip
            key={`team-member-spec-${id}`}
            label={`${
              getAllCatCount() ===
              reduxSpecialisations.flatMap(category =>
                category?.subCategories?.map(subCat => subCat.id)
              )?.length
                ? 'All'
                : getAllCatCount()
            } Job Specialisations Selected`}
            variant="outlined"
            className={classes.chip}
          />
        ) : null}
        {getAllCatCount() < getAllSubCatCount() && (
          <GridStyled
            // container
            // justifyContent={'center'}
            // alignItems={'center'}
            // border={'1px dashed #222'}
            width={24}
            height={24}
            onClick={() => {
              setEditIndex(index);
              setIsJobTracksPopUpOpen(true);
            }}
            sx={{
              cursor: 'pointer'
            }}>
            {/* <PlusIcon width={16} height={16} /> */}
            <AddCircleIcon />
          </GridStyled>
        )}
      </GridStyled>
      <div className={styles.utilContainer}>
        <div className={styles.editButtonWrapper}>
          <IconButton className={styles.editIconButton} onClick={handleClick}>
            <PencilIcon width={20} height={20} />
          </IconButton>
        </div>
        <IconButton
          className={styles.editIconButton}
          onClick={() => setIsDeleteMemberPopUpOpen(true)}>
          <TrashIcon width={20} height={20} />
        </IconButton>
      </div>
    </div>
  );
};
export default JobTracks;
