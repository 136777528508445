import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import { MUIButtonStyled } from '../../../../../components/MUIButton';
import usePathKey from '../../helper/usePathKey';

import useDefaultLogic from '../../controller/useDefaultLogic';
import useEditLogic from '../../controller/useEditLogic';
import useDraftLogic from '../../controller/useDraftLogic';
import useSeniorLogic from '../../controller/useSeniorLogic';
import useInternLogic from '../../controller/useInternLogic';
import LeaveJobEditingPopUp from '../../../PopUps/LeaveJobEditingPopUp';
import JobTokenPopUp from '../../../PopUps/JobTokenPopUp';
import { useFormContext } from '../../context';

export default function ActionButton() {
  const { formState } = useFormContext();
  const pathKey = usePathKey();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  // Initialize saveJob function
  let saveJob;
  let buttonText;

  let openTokenPopUp;
  let setOpenTokenPopUp;
  let jobTitle;

  let saveAsDraft;

  // Assign saveJob based on the pathKey
  if (pathKey === 'new') {
    const defaultLogic = useDefaultLogic();
    saveJob = defaultLogic.saveJob;
    buttonText = defaultLogic.buttonText;
    openTokenPopUp = defaultLogic.openTokenPopUp;
    setOpenTokenPopUp = defaultLogic.setOpenTokenPopUp;
    jobTitle = defaultLogic.jobTitle;
    saveAsDraft = true;
  } else if (pathKey === 'edit') {
    const editLogic = useEditLogic();
    saveJob = editLogic.saveJob;
    buttonText = editLogic.buttonText;
    openTokenPopUp = editLogic.openTokenPopUp;
    setOpenTokenPopUp = editLogic.setOpenTokenPopUp;
    jobTitle = editLogic.jobTitle;
    saveAsDraft = false;
  } else if (pathKey === 'draft') {
    const draftLogic = useDraftLogic();
    saveJob = draftLogic.saveJob;
    buttonText = draftLogic.buttonText;
    openTokenPopUp = draftLogic.openTokenPopUp;
    setOpenTokenPopUp = draftLogic.setOpenTokenPopUp;
    jobTitle = draftLogic.jobTitle;
    saveAsDraft = true;
  } else if (pathKey === 'intern') {
    const internLogic = useInternLogic();
    saveJob = internLogic.saveJob;
    buttonText = internLogic.buttonText;
    openTokenPopUp = internLogic.openTokenPopUp;
    setOpenTokenPopUp = internLogic.setOpenTokenPopUp;
    jobTitle = 'intern';
    saveAsDraft = false;
  } else if (pathKey === 'senior') {
    const seniorLogic = useSeniorLogic();
    saveJob = seniorLogic.saveJob;
    buttonText = seniorLogic.buttonText;
    openTokenPopUp = seniorLogic.openTokenPopUp;
    setOpenTokenPopUp = seniorLogic.setOpenTokenPopUp;
    jobTitle = 'senior';
    saveAsDraft = false;
  }

  const onCancel = () => {
    setOpenConfirmationModal(true);
  };

  const { draftLoading, onSaveDraftJobConfirm } = useDraftLogic({
    confirm: true,
    formState
  });
  const onSaveDraft = () => {
    onSaveDraftJobConfirm();
  };

  return (
    <Grid
      container
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 'auto',
        marginBottom: '100px',
        marginX: 'auto',
        gap: '50px'
      }}>
      <MUIButtonStyled large outlinedBlack label="Cancel" onClick={onCancel} />

      {saveJob && (
        <MUIButtonStyled large label={buttonText} onClick={saveJob} />
      )}

      {saveAsDraft && (
        <Button
          disabled={draftLoading}
          sx={{
            fontFamily: "Inter",
            fontSize: "16px",
            textTransform: "none",
            fontWeight: 'bold',
            ':hover': { backgroundColor: 'transparent' }
          }}
          variant="text"
          onClick={onSaveDraft}>
         Save As Draft
        </Button>
      )}

      <LeaveJobEditingPopUp
        open={openConfirmationModal}
        handleClose={() => setOpenConfirmationModal(false)}
      />
      <JobTokenPopUp
        open={openTokenPopUp}
        handleClose={() => setOpenTokenPopUp(false)}
        jobTitle={jobTitle}
      />
    </Grid>
  );
}
