import { Button, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import { styled as styling } from '@mui/material/styles';

export const GridStyled = styling(Grid)({
  backgroundColor: "white"
});

export const ResponseTitle = styling(Typography)({
  fontFamily: 'Inter',
  fontSize: '25px',
  fontWeight: '700',
  lineHeight: '32px',
  textAlign: 'center'
});

export const ResponseDesc = styling(Typography)({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  textAlign: 'center'
});

export const ContainedButtonStyled = styling(Button)({
  color: 'white',
  backgroundColor: 'black',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: '700',
  gap: '10px',
  padding: '0px 16px',
  textTransform: 'none',
  minHeight: '36px',
  borderRadius: '8px'
});

export const TextButtonStyled = styling(Button)({
  color: 'black',
  textTransform: 'capitalize',
  fontFamily: 'Inter',
  textDecorationLine: 'underline',
  fontSize: '14px',
  fontWeight: '700',
  gap: '10px',
  padding: '0px 16px',
  minHeight: '36px'
});
