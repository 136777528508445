import React from 'react';
import { Text, BackButtonContainer, Spacer } from './styles';
import { ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material';

const SharedBackButton = ({ onClick }) => {
  return (
    <BackButtonContainer onClick={onClick}>
      <ArrowBackIosIcon style={{ fontSize: '24px', color: '#707070' }} />
      <Spacer width="10px" />
      <Text back_button="true">Back</Text>
    </BackButtonContainer>
  );
};

export default SharedBackButton;
