import React, { useEffect, useState } from 'react';
import { DialogStyled } from './styles';
import { Box, Grid, LinearProgress } from '@mui/material';
import { Typography } from '@mui/material';

export default function UploadingPopUp(props) {
  const { open, handleClose, completed, setUploadOpen } = props;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let timer;
    if (open) {
      timer = setInterval(() => {
        setProgress(oldProgress => {
          if (completed) {
            setTimeout(() => {
              setUploadOpen();
              setProgress(0);
            }, 1000);

            return 100;
          } else if (oldProgress === 83) {
            clearInterval(timer);
            return 83;
          } else {
            const diff = Math.random() * 10;
            return Math.min(oldProgress + diff, 83);
          }
        });
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [open, completed, setUploadOpen]);

  return (
    <DialogStyled
      open={open}
      // onClose={handleClose}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '16px',
          height: '100%',
          width: '100%'
        }}>
        <Typography sx={{ fontSize: '25px', fontWeight: 700 }}>
          Uploading Video...
        </Typography>
        <Typography>
          Please keep this window open while your video uploads.
        </Typography>
        <Grid
          sx={{
            width: '381px',
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Box sx={{ width: '381px' }}>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                borderRadius: '100px !important',
                '&.MuiLinearProgress-colorPrimary': {
                  backgroundColor: '#D8D8D8'
                }
              }}
            />
          </Box>
          <Typography sx={{ color: '#512ACC' }}>
            {parseInt(progress)}%
          </Typography>
        </Grid>
      </Grid>
    </DialogStyled>
  );
}
