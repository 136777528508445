import { styled as styling } from '@mui/material/styles';
import { Grid, Skeleton, Divider } from '@mui/material';

export const MainWrapper = styling(Grid)({
  marginBottom: '5px',
  width: '26%',
  height: '658px'
});

export const TopWrapper = styling(Grid)({
  backgroundColor: 'white',
  width: '100%',
  height: '8%',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  padding: '22px 24px 18px 24px'
});

export const CenterWrapper = styling(Grid)({
  backgroundColor: 'white',
  width: '100%',
  height: '81%',
  padding: '12px 18px 18px 24px'
});

export const BottomWrapper = styling(Grid)({
  backgroundColor: 'white',
  width: '100%',
  height: '10%',
  padding: '22px 24px 18px 24px',
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px'
});

export const SkeletonStyled = styling(Skeleton)({
  borderRadius: '8px'
});

export const SkeletonChipStyled = styling(Skeleton)({
  borderRadius: '100px'
  // height: '28px'
});

export const SkeletonAvatarStyled = styling(Skeleton)({});

export const EmptyContainer = styling(Grid)({
  width: '100%'
});

export const UnstyledContainer = styling(Grid)({});

export const DividerStyled = styling(Divider)({
  color: '#D8D8D8'
});
