import {
  FormControl,
  MenuItem,
  TextField,
  Select,
  InputLabel
} from '@mui/material';
import React, { useState } from 'react';
import { PlaceholderText } from './styles';

const SharedSelect = ({
  placeholder,
  children,
  $top,
  onChange,
  value,
  multiple,
  width,
  height
}) => {
  const commaAndSpacing = value => {
    let addComma = value.map(item => {
      if (value[value.length - 1] == item) {
        return item;
      } else {
        return item + ', ';
      }
    });

    return addComma;
  };

  return (
    <FormControl>
      <Select
        value={value}
        onChange={onChange}
        multiple={multiple}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        MenuProps={{
          PaperProps: {
            sx: {
              boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.16)',
              borderRadius: '4px',
              width: width,
              height: height
            }
          }
        }}
        sx={{
          '& .MuiSelect-select': {
            padding: "10px 16px"
          },
        }}
        renderValue={value =>
          value == 'Select Job Specialisation' ||
          value == 'Select Company Industry' ||
          value == 'Select Language' ? (
            <PlaceholderText>{value}</PlaceholderText>
          ) : value ? (
            commaAndSpacing(value)
          ) : (
            <PlaceholderText>{placeholder}</PlaceholderText>
          )
        }>
        {children}
      </Select>
    </FormControl>
  );
};

export default SharedSelect;
