import React from 'react';
import {
  TitleContainer,
  FirstContainer,
  ImageContainer,
  ProfilePicture,
  Name,
  IdentityContainer,
  Age,
  Dot,
  Nationality,
  ShortSummary,
  InfoContainer,
  SubInfoContainer,
  BoldText,
  NormalText,
  SecondContainer,
  SummaryContainer,
  Text,
  MainContainer,
  SectionContainer,
  LabelContainer,
  SectionLabel,
  SubContainer
} from './styles';
import Linkify from 'react-linkify';
import { Divider, Grid } from '@mui/material';
import {
  getCountry,
  getCountryFullName
} from '../../../hooks/useRegionalisation';

export default function Biodata(props) {
  const user = props.application?.user;

  const isRelocateAndRemoteTrue = (relocate, remote) => {
    if (relocate && remote) {
      return true;
    }

    return false;
  };

  const getRightToWork = (
    openToSponsor,
    localCitizen,
    visaOrEmploymentPass
  ) => {
    if (openToSponsor || localCitizen || visaOrEmploymentPass) {
      if (openToSponsor) {
        return 'Open to sponsor';
      } else if (localCitizen) {
        return 'Local citizen';
      } else if (visaOrEmploymentPass) {
        return 'Has existing right to work';
      }
    } else {
      return 'Not provided';
    }
  };

  const getDestionationKey = () => {
    const workLocationPreference = user?.workLocationPreference;
    const keys = Object.keys(workLocationPreference);
    const currentCountry = getCountry();

    if (keys.length > 0) {
      return currentCountry;
    }

    return '';
  };

  const renderLocationWorkplace = () => {
    let destinationCountry = getDestionationKey();

    // if (destinationCountry != getCountry() && destinationCountry?.length > 0) {
    //   return;
    // }

    let relocate =
      user?.workLocationPreference[destinationCountry]?.relocate ?? null;
    let remote =
      user?.workLocationPreference[destinationCountry]?.remote ?? null;

    let openToSponsor =
      user?.rightToWork[destinationCountry]?.openToSponsor ?? null;

    let localCitizen =
      user?.rightToWork[destinationCountry]?.localCitizen ?? null;

    let visaOrEmploymentPass =
      user?.rightToWork[destinationCountry]?.visaOrEmploymentPass ?? null;

    const renderWorkLocationPreference = (preference, label, bothTrue) => {
      if (preference) {
        return preference ? (
          <NormalText $noPadding $list={bothTrue}>
            {label}
          </NormalText>
        ) : null;
      }
      return null;
    };

    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px',
            alignSelf: 'stretch'
          }}>
          <SectionContainer>
            <LabelContainer>
              <Grid display="flex" alignItems={'center'}>
                <SectionLabel>
                  Work Eligibility In {getCountryFullName()}
                </SectionLabel>
              </Grid>
            </LabelContainer>

            {/* {relocate == false && remote == false ? null : ( */}
            <SubContainer>
              <SubInfoContainer>
                <Grid display="flex" alignItems={'center'}>
                  <BoldText $noPadding>Availability & Relocation</BoldText>
                </Grid>
                {(relocate == null && remote == null) ||
                (!relocate && !remote) ? (
                  <NormalText $noPadding>Not provided</NormalText>
                ) : (
                  <Grid
                    display={'flex'}
                    alignItems={'flex-start'}
                    justifyContent={
                      relocate && remote ? 'flex-start' : 'center'
                    }
                    flexDirection={'column'}>
                    {renderWorkLocationPreference(
                      relocate,
                      // `Willing to relocate: ${countryOptionsFullName[destinationCountry]}`,
                      `Willing to relocate`,
                      isRelocateAndRemoteTrue(relocate, remote)
                    )}
                    {renderWorkLocationPreference(
                      remote,
                      'Remote work',
                      isRelocateAndRemoteTrue(relocate, remote)
                    )}
                  </Grid>
                )}
              </SubInfoContainer>

              <SubInfoContainer>
                <Grid display="flex" alignItems={'center'}>
                  <BoldText $noPadding>Right to Work</BoldText>
                </Grid>
                <NormalText $noPadding>
                  {getRightToWork(
                    openToSponsor,
                    localCitizen,
                    visaOrEmploymentPass
                  )}
                </NormalText>
              </SubInfoContainer>
            </SubContainer>
            {/* )} */}
          </SectionContainer>

          <Divider
            style={{ margin: '0', borderColor: '#D8D8D8', width: '100%' }}
          />
          <div></div>
        </div>
      </>
    );
  };

  return (
    <MainContainer vcl={user?.videoCvOptimized}>
      <TitleContainer>
        <Text type="normal">Biodata</Text>
      </TitleContainer>
      <div>
        <FirstContainer>
          <ImageContainer>
            <ProfilePicture src={user?.profileImageSquare} />
          </ImageContainer>
          <InfoContainer type="first-section">
            <div style={{ margin: '0 5px 13px 0' }}>
              <Name>Hi, I’m {user?.name}!</Name>
              <IdentityContainer>
                <div>
                  <Age>{user?.age} years old</Age>
                </div>
                <Dot />
                <Nationality>{user?.nationality}</Nationality>
              </IdentityContainer>
            </div>
            <SummaryContainer>
              <ShortSummary>
                <Linkify>{user?.shortSummary}</Linkify>
              </ShortSummary>
            </SummaryContainer>
          </InfoContainer>
        </FirstContainer>

        <SecondContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '16px',
              alignSelf: 'stretch'
            }}>
            <SectionContainer>
              <LabelContainer>
                <Grid display="flex" alignItems={'center'}>
                  <SectionLabel>Profile</SectionLabel>
                </Grid>
              </LabelContainer>

              <SubContainer>
                <SubInfoContainer>
                  <Grid display="flex" alignItems={'center'}>
                    <BoldText $noPadding>Email</BoldText>
                  </Grid>
                  <NormalText $noPadding>
                    {user?.email ? user?.email : 'Not provided'}
                  </NormalText>
                </SubInfoContainer>

                <SubInfoContainer>
                  <Grid display="flex" alignItems={'center'}>
                    <BoldText $noPadding>Phone Number</BoldText>
                  </Grid>
                  <NormalText $noPadding>
                    {user?.mobileNumber ? user?.mobileNumber : 'Not provided'}
                  </NormalText>
                </SubInfoContainer>

                <SubInfoContainer>
                  <Grid display="flex" alignItems={'center'}>
                    <BoldText $noPadding>Current Location</BoldText>
                  </Grid>
                  <NormalText $noPadding>
                    {user.currentLocation
                      ? user.currentLocation
                      : 'Not provided'}
                  </NormalText>
                </SubInfoContainer>
              </SubContainer>
            </SectionContainer>

            <Divider
              style={{
                margin: '0',
                borderColor: '#D8D8D8',
                width: '100%'
              }}
            />
            <div></div>
          </div>

          {renderLocationWorkplace()}

          <SectionContainer>
            <LabelContainer>
              <Grid display="flex" alignItems={'center'}>
                <SectionLabel>Background</SectionLabel>
              </Grid>
            </LabelContainer>

            <SubContainer>
              {user?.graduationYear && (
                <SubInfoContainer>
                  <BoldText $noPadding>Graduation Year</BoldText>
                  <NormalText $noPadding>{user?.graduationYear}</NormalText>
                </SubInfoContainer>
              )}

              {user?.currentCompany?.length > 0 && (
                <SubInfoContainer>
                  <BoldText $noPadding>Current Company</BoldText>
                  <NormalText $noPadding>{user?.currentCompany}</NormalText>
                </SubInfoContainer>
              )}

              {user?.highestEducationLevel?.length > 0 && (
                <SubInfoContainer>
                  <BoldText $noPadding>Highest Level of Education</BoldText>
                  <NormalText $noPadding>
                    {user?.highestEducationLevel}
                  </NormalText>
                </SubInfoContainer>
              )}

              {user?.educationalInstitution?.length > 0 && (
                <SubInfoContainer>
                  <BoldText $noPadding>Educational Institute</BoldText>
                  <NormalText $noPadding>
                    {user?.educationalInstitution}
                  </NormalText>
                </SubInfoContainer>
              )}

              {user?.fieldOfStudy?.length > 0 && (
                <SubInfoContainer>
                  <BoldText $noPadding>Field of Study</BoldText>
                  <NormalText $noPadding>{user?.fieldOfStudy}</NormalText>
                </SubInfoContainer>
              )}

              {user?.major?.length > 0 && (
                <SubInfoContainer>
                  <BoldText $noPadding>Major</BoldText>
                  <NormalText $noPadding>{user?.major}</NormalText>
                </SubInfoContainer>
              )}
            </SubContainer>
          </SectionContainer>
        </SecondContainer>
      </div>
    </MainContainer>
  );
}
