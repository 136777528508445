import { styled as styling } from '@mui/material/styles';

export const UnorderlistStyled = styling('ul')({
  listStylePosition: 'outside',
  margin: '0',
  paddingLeft: '25px'
});

export const ListStyled = styling('li')(props => ({
  color: 'rgba(0, 0, 0, 0.6)',
  padding: props.$padding ? props.$padding : '10px 0'
}));

export const ULTextStyle = styling('span')({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: '400',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.6)',
  lineHeight: '1.5',
  cursor: 'default'
});

export const AccordionTitle = styling('span')(props => ({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: '700',
  letterSpacing: '0.1px',
  color: props.expanded ? '#40249B' : 'rgba(0, 0, 0, 0.6)',

  '&:hover': {
    color: !props.expanded && '#222'
  }
}));
