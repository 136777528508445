import React from 'react';
import { PopUpDialog } from '../../../../../components';
import { ErrorToast, SuccessToast } from '../../../../../utils/ToastUtils';
import styles from './index.module.scss';
import { MUIButtonStyled } from '../../../../../components/MUIButton';
import { useDispatch, useSelector } from 'react-redux';
import { mutateRemoveTeamMember } from '../../../../../redux/actions/company_actions';
import { fetchTeamMembers } from '../../../../../redux/actions/job_action';

const DeleteTeamMemberPopUp = ({ open, handleClose, id, name }) => {
  const dispatch = useDispatch();

  const mutatingRemoveTeamMember = useSelector(
    state => state.companies.mutatingRemoveTeamMember
  );

  const onDeleteTeamMember = async () => {
    try {
      const res = await dispatch(
        mutateRemoveTeamMember({
          input: {
            id
          }
        })
      );

      if (res.removeCompanyTeamMember) {
        await dispatch(fetchTeamMembers());
        SuccessToast(`Deleted ${name} from Company Team Members`);
        handleClose();
      }
    } catch (err) {
      ErrorToast(err.message);
    }
  };

  return (
    <PopUpDialog
      open={open}
      handleClose={handleClose}
      title={`Delete ${name} as a team member?`}>
      <div style={{ padding: '20px 20px' }}>
        <div className={styles.contentContainer}>
          <p className={styles.deleteMemberDescription}>
            {`Deleting ${name} will permanently remove the member details and
            revoke all accesses held by this team member.`}
          </p>
        </div>

        <div className={styles.buttonContainer}>
          <MUIButtonStyled
            outlinedBlack
            large
            label="Cancel"
            onClick={handleClose}
          />
          <MUIButtonStyled
            large
            label="Delete"
            onClick={onDeleteTeamMember}
            disabled={mutatingRemoveTeamMember}
          />
        </div>
      </div>
    </PopUpDialog>
  );
};

export default DeleteTeamMemberPopUp;
