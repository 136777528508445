import React, { useEffect, useState } from 'react';
import PostJobPopup from '../../PopUps/PostJobPopup';
// import CustomArrowDropdown from './ArrowDropdown';
import PostJobButton from './PostJobButton';
import { fetchDraftJob } from '../../../../redux/actions/job_action';
import { useDispatch } from 'react-redux';
import { fetchMyCompanyStatus } from '../../../../redux/actions/company_actions';
// import CustomArrowButton from './ArrowButton';

const ManageJobsButton = ({
  permissions,
  data,
  jobToken,
  unlimitedToken,
  draftData,
  getJobLoading,
  disableFreeJobInternship,
  disableFreeSeniorJob
}) => {
  const [anchorEl, setAnchorEl] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMyCompanyStatus());
    dispatch(fetchDraftJob());
  }, []);

  return (
    <>
      {permissions?.postJob && (
        <>
          <div style={{ display: 'inline-flex', marginLeft: '10px' }}>
            <PostJobButton
              draftData={draftData}
              getJobLoading={getJobLoading}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ManageJobsButton;
