import React, { useEffect, useState } from 'react';
import InfoPopup from '../../../../SharedPopups/InfoPopup';
import {
  ChipContainer,
  ChipTag,
  TitleContainer,
  Title,
  Description,
  Note,
  AshleyRewriteContent,
  ButtonText,
  ButtonBaseStyled,
  LottieText
} from './styles';
import { Grid, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { Spacer } from '../../../../JobList/JobListTable/styles';
import { Close as CloseIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAshleyTextRewrite,
  updateAshleyRewritePopup
} from '../../../../../../redux/actions/job_action';
import { HeightSpacer } from '../../../../../../components/Spacer/styles';
import { SharedButton } from '../../../../JobList/SharedButton';
import {
  ArrowsRightLeftIcon,
  CheckCircleIcon,
  DocumentDuplicateIcon
} from '@heroicons/react/24/solid';
import Lottie from 'react-lottie';
import ashleyRewriteLoading from '../../../../../../assets/lottie/ashley_rewrite_loading.json';
import { useFormContext } from '../../../context';
import { ErrorToast, SuccessToast } from '../../../../../../utils/ToastUtils';
import { useFormData } from '../../../helper/useFormData';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: ashleyRewriteLoading,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

export default function AshleyPopUp() {
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState(false);
  const { formState, handleSingleFieldChange } = useFormContext();
  const { formData } = useFormData();

  const isAshleyRewritePopupOpen = useSelector(
    state => state.jobs.isAshleyRewritePopupOpen
  );

  const ashleyRewritePopupOrigin = useSelector(
    state => state.jobs.ashleyRewritePopupOrigin
  );

  const gptFromAPI = formState?.gptUsage?.rewrites?.some(rewrite =>
    rewrite?.section?.includes(ashleyRewritePopupOrigin)
  );

  const sectionValue = formState?.gptUsage?.rewrites?.find(
    rewrite => rewrite?.section
  );

  const gptFromLocal = formState[ashleyRewritePopupOrigin + 'Ashley'];

  const onClosePopup = () => {
    dispatch(updateAshleyRewritePopup(false));
  };

  let gptRewrites = [];

  const confirmRewrite = () => {
    setConfirm(true);

    const formAshley = ashleyRewritePopupOrigin + 'Ashley';

    const params = {
      text: formState[ashleyRewritePopupOrigin],
      textType:
        ashleyRewritePopupOrigin === 'description'
          ? 'job_description'
          : 'job_requirements'
    };

    dispatch(getAshleyTextRewrite(params)).then(res => {
      if (res.type == 'FETCH_ASHLEY_TEXT_REWRITE_SUCCEED') {
        if (res?.texts) {
          SuccessToast('Success!');

          gptRewrites['rewrites'] = gptRewrites['rewrites'] || [];

          if (!gptRewrites['rewrites']) {
            gptRewrites['rewrites'] = [];
          }

          let index = gptRewrites['rewrites'].findIndex(item => {
            return item.section == ashleyRewritePopupOrigin;
          });

          if (index <= -1) {
            gptRewrites['rewrites'] = [
              ...gptRewrites['rewrites'],
              sectionValue || {},
              {
                section: ashleyRewritePopupOrigin,
                text: res.texts
              }
            ];
          }

          handleSingleFieldChange(formAshley, res.texts);
          handleSingleFieldChange('gptRewrites', gptRewrites);
          setConfirm(false);
        } else {
          setConfirm(false);
          ErrorToast(
            'Ashley rewrite is currently down, please try again later if needed'
          );
        }
      }
    });
  };

  return (
    <InfoPopup
      titleContent={<TitleContent />}
      content={
        confirm || gptFromLocal || gptFromAPI ? (
          <RewriteContent />
        ) : (
          <ConfirmationContent onConfirm={confirmRewrite} />
        )
      }
      $dialogMaxWidth={'771px'}
      $dialogMinWidth={'650px'}
      open={isAshleyRewritePopupOpen}
      onClosePopup={() => onClosePopup()}
    />
  );
}

const TitleContent = () => {
  const dispatch = useDispatch();

  const onClosePopup = () => {
    dispatch(updateAshleyRewritePopup(false));
  };
  return (
    <TitleContainer>
      <Grid display={'flex'} alignItems="center" gap="16px">
        <Title>{'Ashley’s Edit'}</Title>
        <ChipContainer $name="beta">
          <ChipTag>BETA</ChipTag>
        </ChipContainer>
        <ChipContainer $name="ai">
          <FontAwesomeIcon
            icon={faWandMagicSparkles}
            style={{ width: '12px', height: '12px' }}
          />{' '}
          <Spacer width={'7px'} />
          <ChipTag>AI-powered</ChipTag>
        </ChipContainer>
      </Grid>
      <IconButton onClick={onClosePopup}>
        <CloseIcon />
      </IconButton>
    </TitleContainer>
  );
};

const ConfirmationContent = ({ onConfirm }) => {
  const { formState } = useFormContext();
  const dispatch = useDispatch();

  const onClosePopup = () => {
    dispatch(updateAshleyRewritePopup(false));
  };

  return (
    <div style={{ padding: '16px 24px 24px', cursor: 'default' }}>
      <HeightSpacer height={'5px'} />
      <Description>
        You can now ask Ashley to help you edit your job ad based on what you
        have written.
      </Description>
      <HeightSpacer height={'25px'} />

      <Grid
        display={'grid'}
        gap="10px"
        sx={{
          border: '1px solid #D8D8D8',
          borderRadius: '4px',
          padding: '12px'
        }}>
        <Note>Please note:</Note>
        <Note style={{ lineHeight: '1.5' }}>
          1. This feature is only available to use if you have typed a minimum
          of 200 characters.
        </Note>
        <Note style={{ lineHeight: '1.5' }}>
          {' '}
          2. Ashley only has the capacity to rewrite once for each section per
          job ad so make sure your text is ready for the rewrite before you
          click to generate!{' '}
        </Note>
        <Note style={{ lineHeight: '1.5' }}>
          3. Don’t worry, we will not remove your original text! We’ll show you
          the rewritten passages and you can choose to copy the text or directly
          replace your current one.
        </Note>
      </Grid>

      <HeightSpacer height={'30px'} />

      <Grid display={'flex'} gap="40px" justifyContent={'center'}>
        <SharedButton
          type={'thin_outlined'}
          font_size={'16px'}
          width={'200px'}
          padding={'6px 10px'}
          border={'2px solid #512acc'}
          onClick={onClosePopup}>
          I’m not Ready!
        </SharedButton>
        <SharedButton
          type={'basic'}
          font_size={'16px'}
          width={'200px'}
          padding={'6px 10px'}
          onClick={onConfirm}
          disabled={formState?.description?.length < 200}>
          Confirm Rewrite
        </SharedButton>
      </Grid>
    </div>
  );
};

const RewriteContent = () => {
  const { formState, handleSingleFieldChange } = useFormContext();
  const [copied, setCopied] = useState(false);
  const [replacedText, setReplacedText] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const [undo, setUndo] = useState(false);
  const fetchingAshelyRewrite = useSelector(
    state => state.jobs.fetchingAshelyRewrite
  );
  const ashleyRewritePopupOrigin = useSelector(
    state => state.jobs.ashleyRewritePopupOrigin
  );

  const getAshleyRewriteTexts = () => {
    const sectionValue = formState?.gptUsage?.rewrites?.find(rewrite =>
      rewrite?.section?.includes(ashleyRewritePopupOrigin)
    )?.text;

    if (sectionValue) {
      return sectionValue;
    }

    if (formState[ashleyRewritePopupOrigin + 'Ashley']) {
      return formState[ashleyRewritePopupOrigin + 'Ashley'];
    }
  };

  const getAshleyMessage = () => {
    let list = getAshleyRewriteTexts()
      ?.split(/[\n]/) // split based on break space
      ?.filter(item => {
        return item.length > 0; // remove empty line
      })
      .map(item => {
        return item.replace(/\d+./, ''); // remove dot and digits
      });

    return list;
  };

  const textConversion = () => {
    // return text must be same line as <ul> to prevent white space of the first word

    const text = `
    <ul>${getAshleyMessage().map(item => {
      let removedDash = item.replace('- ', '');
      return `<li>${removedDash}</li>`;
    })}
    </ul>
    `;

    let cText = text.replace(/,/g, '');

    return cText;
  };

  const onCopy = async () => {
    if (copied) return;

    setCopied(true);

    if (!navigator.clipboard) {
      // Clipboard API not available
      return;
    }

    let data;

    const blobHtml = new Blob([textConversion()], { type: 'text/html' });
    data = [
      new ClipboardItem({
        ['text/html']: blobHtml
      })
    ];

    try {
      await navigator.clipboard.write(data);
      trackCopyToClipboard();
      SuccessToast('Copied successfully.');
    } catch (err) {
      // ErrorToast('Failed to copy!');
    }
  };

  const onReplaceText = () => {
    handleSingleFieldChange(
      ashleyRewritePopupOrigin + 'Double',
      formState[ashleyRewritePopupOrigin]
    );

    handleSingleFieldChange(
      ashleyRewritePopupOrigin,
      formState[ashleyRewritePopupOrigin + 'Ashley']
    );

    setReplacedText(true);
    setUndo(false);
  };

  const onRevertToPreviousTexts = () => {
    handleSingleFieldChange(
      ashleyRewritePopupOrigin,
      formState[ashleyRewritePopupOrigin + 'Double']
    );

    setButtonText('Reverted!');
    setUndo(true);
    setReplacedText(false);
  };

  useEffect(() => {
    const interval = setTimeout(() => {
      if (replacedText && !undo) {
        setButtonText('Undo Change');
        return;
      }
    }, 1500);

    if (replacedText) {
      setButtonText('Replaced!');
    } else {
      if (undo && !replacedText) {
        setTimeout(() => {
          setButtonText('Replace Text');
        }, 1500);
        return;
      }

      setButtonText('Replace Text');
    }

    return () => {
      clearTimeout(interval);
    };
  }, [replacedText, undo]);

  useEffect(() => {
    const interval = setTimeout(() => {
      if (copied) {
        setCopied(false);
        return;
      }
    }, 1500);

    return () => {
      clearTimeout(interval);
    };
  }, [copied]);

  if (fetchingAshelyRewrite) {
    return (
      <>
        <Lottie options={defaultOptions} height={'250px'} width={'250px'} />
        <Grid width="100%" display="flex" justifyContent="center">
          <LottieText>Reading and rewriting...</LottieText>
        </Grid>
      </>
    );
  }

  return (
    <div style={{ padding: '16px 24px 24px', cursor: 'default' }}>
      <Grid
        display="flex"
        justifyContent={'space-between'}
        alignItems={'center'}>
        <Description>Here’s the edited version by Ashley:</Description>
        <Grid display={'flex'} gap={'6px'}>
          <ButtonBaseStyled
            $name={'copy-button'}
            $copied={copied}
            onClick={onCopy}>
            {copied ? (
              <CheckCircleIcon
                style={{
                  width: '14px',
                  height: '14px',
                  color: '#707070'
                }}
              />
            ) : (
              <DocumentDuplicateIcon
                style={{
                  width: '14px',
                  height: '14px',
                  color: '#707070'
                }}
              />
            )}
            <ButtonText $copied={copied}>
              {copied ? 'Copied!' : 'Copy to Clipboard'}
            </ButtonText>
          </ButtonBaseStyled>
          <ButtonBaseStyled
            $name={'replace-text-button'}
            onClick={!replacedText ? onReplaceText : onRevertToPreviousTexts}
            $replacedText={replacedText}
            $undo={undo}
            $buttonText={buttonText}>
            {(replacedText && buttonText == 'Replaced!') ||
            (buttonText == 'Reverted!' && undo) ? (
              <CheckCircleIcon
                style={{
                  width: '14px',
                  height: '14px',
                  color: '#707070'
                }}
              />
            ) : (
              <ArrowsRightLeftIcon
                style={{
                  width: '14px',
                  height: '14px',
                  color: '#707070'
                }}
              />
            )}
            <ButtonText
              $replacedText={replacedText}
              $undo={undo}
              $buttonText={buttonText}>
              {buttonText}
            </ButtonText>
          </ButtonBaseStyled>
        </Grid>
      </Grid>
      <HeightSpacer height={'15px'} />
      <Grid
        display={'grid'}
        gap="10px"
        sx={{
          background: '#f5f5f5',
          borderRadius: '4px',
          padding: '12px',
          height: '396px',
          overflow: 'auto'
        }}>
        <ul
          style={{
            listStylePosition: 'outside',
            padding: '0 20px'
          }}>
          {getAshleyMessage()?.map(item => {
            let removedDash = item?.replace('- ', '');
            return (
              <li>
                <AshleyRewriteContent>{removedDash}</AshleyRewriteContent>
              </li>
            );
          })}
        </ul>
      </Grid>
    </div>
  );
};
