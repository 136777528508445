import * as types from '../types/login_type';

const initialState = {
  resettingPassword: false
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_PASSWORD: {
      return {
        ...state,
        resettingPassword: true
      };
    }
    case types.RESET_PASSWORD_SUCCEED: {
      return {
        ...state,
        resettingPassword: false,
        success: action.success
      };
    }
    case types.RESET_PASSWORD_FAILED: {
      return {
        ...state,
        resettingPassword: false
      };
    }

    default: {
      return state;
    }
  }
};
