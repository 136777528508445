import { Typography, useMediaQuery } from '@mui/material';
import { useIntersectionObserver } from '@uidotdev/usehooks';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BoxContainer,
  ButtonDivider,
  ButtonStyled,
  CheckIconStyled,
  ColumnContainer,
  EmptyIconStyled,
  FilmIconStyled,
  GradientText,
  GreyText,
  GroupIconStyled,
  HeartIconStyled,
  ImageStyled,
  InnerColumnContainer,
  OutlineButtonStyled,
  PriceText,
  PurpleStyled,
  RowContainer,
  SectionContainer,
  SparklesIconStyled,
  TextStyled
} from './styles';
import theme from '../../../assets/theme';
import jobSlotImage from '../../../assets/icons/products/job_slot.svg';
import jobSlotPlusImage from '../../../assets/icons/products/job_slot_plus.svg';
import jobSlotMaxImage from '../../../assets/icons/products/job_slot_max.svg';
import personalised from '../../../assets/icons/products/personalised.svg';
import { updateContactSalesPopupStatus } from '../../../redux/actions/job_action';
import { updateProductBundle } from '../../../redux/actions/product_action';
import BundlesOverlay from './BundlesOverlay';
import { DefaultContainer, PurpleDivider } from './BundlesOverlay/styles';
import { textFormatter } from '../../../utils/textFormat';
import { getCountry, getCurrency } from '../../../hooks/useRegionalisation';
import { getLocalStorageItem } from '../../../helpers/local_storage_utils';

function JobAds() {
  const isDesktop = useMediaQuery('(min-width:1080px)');
  const isTablet = useMediaQuery('(min-width:640px)');
  const country = getLocalStorageItem('country');
  const dispatch = useDispatch();
  const allProducts = useSelector(state => state.products.allProducts);

  const comparisonItem = [
    'Free internship posts',
    'Flexibly swap jobs at no additional cost',
    'Media-rich company profile to tell your story',
    'Expand reach across an extensive network of job boards',
    'AI-enabled application screening and resume summarisation',
    'Enhanced performance with automated job ads',
    'Dedicated customer support',
    'Exclusive job ad design - _outshine the competition!_',
    'Onboarding and Product Demo Session'
  ];

  const productItem = [
    {
      id: 1,
      title: 'MOST AFFORDABLE',
      productName: 'Job Slot',
      productType: '',
      imageSrc: jobSlotImage,
      headerColor: '#EFEFEF',
      headerTextColor: 'rgba(0, 0, 0, 0.87)',
      columnColor: '#FFFFFF',
      borderColor: '#EFEFEF',
      borderRadius: '0.625rem 0 0 0.625rem',
      checkAmount: 5,
      price: `${getCurrency(
        allProducts?.find(product => product?.title === 'Job Slot')?.price / 12
      )}/MONTH`,
      onboarding: 'Virtual Group'
    },
    {
      id: 2,
      title: '1.5X PERFORMANCE',
      productName: 'Job Slot',
      productType: 'Plus',
      imageSrc: jobSlotPlusImage,
      headerColor:
        'linear-gradient(270deg, #8468D9 0%, #966DD5 45.87%, #B376CD 101.73%);',
      headerTextColor: 'rgba(255, 255, 255, 0.87)',
      columnColor: 'rgba(247, 247, 254, 1)',
      borderColor: '#512ACC1A',
      borderRadius: 0,
      checkAmount: 6,
      price: `${getCurrency(
        allProducts?.find(product => product?.title === 'Job Slot Plus')
          ?.price / 12
      )}/MONTH`,
      onboarding: 'Virtual 1:1'
    },
    {
      id: 3,
      title: '2.2X PERFORMANCE',
      productName: 'Job Slot',
      productType: 'Max',
      imageSrc: jobSlotMaxImage,
      headerColor: 'linear-gradient(90deg, #6EC7CD 18.57%, #B0CD72 96.74%)',
      headerTextColor: 'rgba(255, 255, 255, 0.87)',
      columnColor: '#F6FBF8',
      borderColor: '#6EC197',
      borderRadius: '0 0.625rem 0.625rem 0',
      checkAmount: 8,
      price: `${getCurrency(
        allProducts?.find(product => product?.title === 'Job Slot Max')?.price /
          12
      )}/MONTH`,
      onboarding: country === 'SG' ? 'Virtual 1:1' : 'Onsite'
    }
  ];

  const personalisedItem = {
    title: 'PERSONALISED',
    productName: 'Not quite what you’re looking for?',
    imageSrc: personalised,
    headerColor: theme.palette.primary.light,
    headerTextColor: 'rgba(0, 0, 0, 0.87)',
    borderColor: '#EFEFEF',
    columnColor: '#FFFFFF',
    text: [
      {
        __html:
          'Let us help you mix and match based on your needs for a personalised solution!'
      },
      {
        icon: <GroupIconStyled />,
        __html: '<b>Mix and match </b> what you want on your job ad space'
      },
      {
        icon: <HeartIconStyled />,
        __html:
          'Make your company attractive to top talents with <b> enticing content</b>'
      },
      {
        icon: <FilmIconStyled />,
        __html:
          'Don’t have a photographer/ videographer? Leave all the<b> creative </b>stuff to us'
      }
    ]
  };

  const [refStart, entryStart] = useIntersectionObserver({
    threshold: isDesktop ? 0.72 : 0.9
  });

  const [refStop, entryStop] = useIntersectionObserver({});

  const isVisible = entryStart?.isIntersecting;
  const isHide = entryStop?.isIntersecting;

  const bundlePopUpHandler = id => {
    dispatch(updateProductBundle(id));
  };

  const contactUsHandler = () => {
    dispatch(updateContactSalesPopupStatus(true));
  };

  const [isHovered, setIsHovered] = useState(false);

  return (
    <SectionContainer>
      <BundlesOverlay />
      <TextStyled
        variant={'h2'}
        fontSize={isDesktop ? '2.625rem' : isTablet ? '1.5625rem' : '1.25rem'}
        fontWeight={'700'}
        lineHeight={'2.8125rem'}
        textAlign={'center'}
        marginBottom={isDesktop ? '3.125rem' : '0.875rem'}>
        Job Ads Made <PurpleStyled>Simple</PurpleStyled>
      </TextStyled>
      {!isDesktop ? (
        <TextStyled
          fontSize={isTablet ? '1rem' : '0.875rem'}
          fontWeight={'700'}
          textAlign={'center'}
          marginBottom={'0.75rem'}>
          Compare our Job Ad Solutions
        </TextStyled>
      ) : null}

      {isDesktop ? (
        <BoxContainer gap={'0.5rem'}>
          <BoxContainer
            flexDirection={'column'}
            paddingRight={'1.25rem'}
            marginTop={'11.75rem'}>
            <InnerColumnContainer>
              <TextStyled
                variant="h5"
                fontSize="1.25rem"
                fontWeight={700}
                marginBottom={'1.5rem'}>
                Compare our Job Ad Solutions
              </TextStyled>
            </InnerColumnContainer>
            {comparisonItem.map((item, index) => {
              const formattedText = textFormatter(item);
              return (
                <InnerColumnContainer key={index}>
                  <TextStyled fontSize="0.875rem">
                    <div dangerouslySetInnerHTML={{ __html: formattedText }} />
                  </TextStyled>
                </InnerColumnContainer>
              );
            })}
          </BoxContainer>

          {productItem?.map((item, index) => (
            <ColumnContainer
              key={index}
              bgcolor={item?.columnColor}
              borderRadius={'10px'}
              border={'1px solid' + item?.borderColor}>
              {/* SCROLLING HEADER */}
              <BoxContainer
                flexDirection={'column'}
                marginBottom={'-93px'}
                position={'sticky'}
                top={'64px'}
                sx={{ opacity: isVisible ? 0 : isHide ? 1 : 0 }}>
                <BoxContainer
                  sx={{ background: item?.headerColor }}
                  color={item?.headerTextColor}
                  alignContent={'center'}
                  justifyContent={'center'}
                  padding={'0.5rem'}>
                  <TextStyled
                    white={item?.id === 2 || item?.id === 3}
                    fontSize={'0.625rem'}
                    fontWeight={600}
                    letterSpacing={'1.5px'}
                    textAlign={'center'}
                    padding={'0.125rem 0'}>
                    {item?.title}
                  </TextStyled>
                </BoxContainer>
                <TextStyled
                  bgcolor={item?.columnColor}
                  fontSize="1rem"
                  fontWeight={700}
                  textAlign={'center'}
                  whiteSpace={'nowrap'}
                  padding={'0.625rem 0 1.5rem'}>
                  {item?.productName + ' '}
                  <GradientText
                    component={'span'}
                    sx={{
                      background: item?.headerColor,
                      backgroundClip: 'text',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent'
                    }}>
                    {item?.productType}
                  </GradientText>
                </TextStyled>
              </BoxContainer>

              <BoxContainer
                sx={{ background: item?.headerColor }}
                color={item?.headerTextColor}
                alignContent={'center'}
                justifyContent={'center'}
                padding={'0.5rem'}>
                <TextStyled
                  white={item?.id === 2 || item?.id === 3}
                  fontSize={'0.625rem'}
                  fontWeight={600}
                  letterSpacing={'1.5px'}
                  textAlign={'center'}
                  padding={'0.125rem 0'}>
                  {item?.title}
                </TextStyled>
              </BoxContainer>
              <BoxContainer
                flexDirection={'column'}
                alignItems={'center'}
                padding={'0.625rem 1.25rem 1.25rem'}>
                <ImageStyled
                  src={item.imageSrc}
                  height={120}
                  width={120}
                  ref={refStart}
                />

                <InnerColumnContainer
                  key={index}
                  flexDirection={'column'}
                  justifyContent={'center'}>
                  <DefaultContainer
                    container
                    sx={{
                      flexDirection: 'column',
                      textAlign: 'center',
                      paddingBottom: '30px'
                    }}>
                    <TextStyled
                      fontSize="1rem"
                      fontWeight={700}
                      textAlign={'center'}
                      whiteSpace={'nowrap'}>
                      {item?.productName + ' '}
                      <GradientText
                        component={'span'}
                        sx={{
                          background: item?.headerColor,
                          backgroundClip: 'text',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent'
                        }}>
                        {item?.productType}
                      </GradientText>
                    </TextStyled>
                    {getCountry() === 'MY' && (
                      <PriceText>{item?.price}</PriceText>
                    )}
                  </DefaultContainer>
                </InnerColumnContainer>

                {Array.from({ length: item?.checkAmount }).map((_, index) =>
                  index === 4 ? (
                    <InnerColumnContainer
                      key={index}
                      justifyContent={'center'}
                      ref={refStop}>
                      <CheckIconStyled />
                    </InnerColumnContainer>
                  ) : (
                    <InnerColumnContainer key={index} justifyContent={'center'}>
                      <CheckIconStyled />
                    </InnerColumnContainer>
                  )
                )}

                {Array.from({ length: 8 - item?.checkAmount }).map(index => (
                  <InnerColumnContainer key={index} justifyContent={'center'}>
                    <EmptyIconStyled />
                  </InnerColumnContainer>
                ))}

                <InnerColumnContainer key={index} justifyContent={'center'}>
                  <GreyText>{item?.onboarding}</GreyText>
                </InnerColumnContainer>

                {getCountry() === 'MY' && (
                  <ButtonStyled
                    onClick={() => bundlePopUpHandler(item.id)}
                    onMouseEnter={() => setIsHovered(item?.id)}
                    onMouseLeave={() => setIsHovered(null)}>
                    View bundles
                    <ButtonDivider
                      isHovered={item?.id === isHovered}></ButtonDivider>
                  </ButtonStyled>
                )}
              </BoxContainer>
            </ColumnContainer>
          ))}

          <ColumnContainer
            borderRadius={'10px'}
            border={'1px solid' + personalisedItem?.borderColor}>
            {/* SCROLL HEADER */}
            <BoxContainer
              flexDirection={'column'}
              marginBottom={'-95px'}
              position={'sticky'}
              top={'64px'}
              sx={{ opacity: isVisible ? 0 : isHide ? 1 : 0 }}>
              <BoxContainer
                bgcolor={personalisedItem?.headerColor}
                color={personalisedItem?.headerTextColor}
                alignContent={'center'}
                justifyContent={'center'}
                padding={'0.5rem'}
                gap={'8px'}>
                <SparklesIconStyled />
                <TextStyled
                  fontSize={'0.625rem'}
                  fontWeight={600}
                  letterSpacing={'1.5px'}
                  textAlign={'center'}
                  padding={'0.125rem 0'}>
                  {personalisedItem?.title}
                </TextStyled>
                <SparklesIconStyled />
              </BoxContainer>
              <TextStyled
                bgcolor={personalisedItem?.columnColor}
                color={theme.palette.primary.dark}
                fontSize="1rem"
                fontWeight={700}
                textAlign={'center'}
                whiteSpace={'wrap'}
                lineHeight={'1.25rem'}
                padding={'0.625rem 0'}>
                {personalisedItem?.productName}
              </TextStyled>
            </BoxContainer>

            <BoxContainer
              bgcolor={personalisedItem?.headerColor}
              color={personalisedItem?.headerTextColor}
              alignContent={'center'}
              justifyContent={'center'}
              padding={'0.5rem'}
              gap={'8px'}>
              <SparklesIconStyled />
              <TextStyled
                fontSize={'0.625rem'}
                fontWeight={600}
                letterSpacing={'1.5px'}
                textAlign={'center'}
                padding={'0.125rem 0'}>
                {personalisedItem?.title}
              </TextStyled>
              <SparklesIconStyled />
            </BoxContainer>
            <BoxContainer
              flexDirection={'column'}
              justifyContent={'space-between'}
              padding={'0.625rem 0.625rem 1.25rem'}
              height={'100%'}>
              <BoxContainer flexDirection={'column'} alignItems={'center'}>
                <ImageStyled src={personalised} height={120} width={120} />
                <TextStyled
                  fontSize="1rem"
                  fontWeight={700}
                  color={theme.palette.primary.dark}
                  textAlign={'center'}
                  margin={'0.3125rem 0 2rem'}>
                  {personalisedItem?.productName}
                </TextStyled>

                <BoxContainer flexDirection={'column'} gap={'1.875rem'}>
                  {personalisedItem?.text.map((item, index) => (
                    <BoxContainer
                      flexDirection={'column'}
                      alignItems={'center'}
                      gap={'0.5rem'}>
                      {item?.icon}
                      <TextStyled
                        key={index}
                        fontSize={'0.75rem'}
                        fontWeight={400}
                        lineHeight={'1.25rem'}
                        textAlign={'center'}
                        dangerouslySetInnerHTML={item}
                      />
                    </BoxContainer>
                  ))}
                </BoxContainer>
              </BoxContainer>
              <ButtonStyled
                onClick={contactUsHandler}
                onMouseEnter={() => setIsHovered(4)}
                onMouseLeave={() => setIsHovered(null)}>
                Contact us
                <ButtonDivider isHovered={isHovered === 4}></ButtonDivider>
              </ButtonStyled>
            </BoxContainer>
          </ColumnContainer>
        </BoxContainer>
      ) : (
        <>
          {/* SCROLLING HEADER */}
          <RowContainer
            position={'sticky'}
            top={'64px'}
            marginBottom={'-5.5rem'}
            sx={{ opacity: isVisible ? 0 : isHide ? 1 : 0 }}>
            {productItem?.map((item, index) => (
              <BoxContainer
                key={index}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'space-between'}
                bgcolor={item?.columnColor}
                border={'1px solid' + item?.borderColor}
                borderRadius={item?.borderRadius}>
                <BoxContainer
                  sx={{ background: item?.headerColor }}
                  color={item?.headerTextColor}
                  justifyContent={'center'}
                  alignItems={'center'}
                  height={'1.75rem'}>
                  <TextStyled
                    fontSize={isTablet ? '0.625rem' : '0.5rem'}
                    fontWeight={600}
                    letterSpacing={'1.5px'}
                    textAlign={'center'}
                    padding={'0.125rem 0.5rem'}
                    whiteSpace={'wrap'}>
                    {item?.title}
                  </TextStyled>
                </BoxContainer>
                <TextStyled
                  fontSize={isTablet ? '1rem' : '0.875rem'}
                  fontWeight={700}
                  textAlign={'center'}
                  whiteSpace={'nowrap'}
                  padding={'0.625rem 0 1.5rem'}>
                  {item?.productName}
                  <GradientText
                    component={'span'}
                    sx={{
                      background: item?.headerColor,
                      backgroundClip: 'text',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent'
                    }}>
                    {' ' + item?.productType}
                  </GradientText>
                </TextStyled>
              </BoxContainer>
            ))}
          </RowContainer>

          <RowContainer marginBottom={'0.625rem'}>
            {productItem?.map((item, index) => (
              <BoxContainer
                key={index}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'space-between'}
                bgcolor={item?.columnColor}
                border={'1px solid' + item?.borderColor}
                borderRadius={item?.borderRadius}
                paddingBottom={'0.5rem'}>
                <BoxContainer
                  sx={{ background: item?.headerColor }}
                  color={item?.headerTextColor}
                  justifyContent={'center'}
                  alignItems={'center'}
                  height={'1.75rem'}>
                  <TextStyled
                    fontSize={isTablet ? '0.625rem' : '0.5rem'}
                    fontWeight={600}
                    letterSpacing={'1.5px'}
                    textAlign={'center'}
                    padding={'0.125rem 0.5rem'}
                    whiteSpace={'wrap'}>
                    {item?.title}
                  </TextStyled>
                </BoxContainer>
                <ImageStyled
                  src={item.imageSrc}
                  style={{ padding: index != 0 ? '0.375rem' : '0' }}
                  ref={refStart}
                />
                <TextStyled
                  fontSize={isTablet ? '1rem' : '0.875rem'}
                  fontWeight={700}
                  textAlign={'center'}
                  whiteSpace={'nowrap'}>
                  {!isTablet && item?.productName === 'BASIC Job Slot'
                    ? 'BASIC'
                    : item?.productName}
                  <GradientText
                    component={'span'}
                    sx={{
                      background: item?.headerColor,
                      backgroundClip: 'text',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent'
                    }}>
                    {' ' + item?.productType}
                  </GradientText>
                </TextStyled>
              </BoxContainer>
            ))}
          </RowContainer>
          {Array.from({ length: 9 }).map((_, mainIndex) => (
            <RowContainer
              key={mainIndex}
              flexDirection={'column'}
              gap={'1.125rem'}
              padding={'1rem 0'}>
              {mainIndex === 6 ? (
                <TextStyled
                  fontSize={isTablet ? '1rem' : '0.75rem'}
                  lineHeight={isTablet ? '1.5rem' : '1.25rem'}
                  ref={refStop}>
                  {comparisonItem[mainIndex]}
                </TextStyled>
              ) : (
                <TextStyled
                  fontSize={isTablet ? '1rem' : '0.75rem'}
                  lineHeight={isTablet ? '1.5rem' : '1.25rem'}>
                  {comparisonItem[mainIndex]}
                </TextStyled>
              )}
              <BoxContainer>
                {productItem?.map((item, index) => (
                  <BoxContainer
                    bgcolor={item?.columnColor}
                    border={'1px solid' + item?.borderColor}
                    borderRadius={item?.borderRadius}
                    alignItems={'center'}
                    justifyContent={'center'}
                    padding={'0.625rem 0'}>
                    {mainIndex + 1 <= item?.checkAmount ? (
                      <CheckIconStyled />
                    ) : mainIndex + 1 === 9 ? (
                      <TextStyled
                        color={'rgba(0, 0, 0, 0.60)'}
                        fontSize={'0.75rem'}
                        fontWeight={400}
                        lineHeight={'1.5rem'}
                        letterSpacing={'0.15px'}>
                        {item?.onboarding}
                      </TextStyled>
                    ) : (
                      <EmptyIconStyled />
                    )}
                  </BoxContainer>
                ))}
              </BoxContainer>
            </RowContainer>
          ))}

          {getCountry() === 'MY' && (
            <BoxContainer
              justifyContent={isTablet ? 'space-around' : 'space-between'}>
              <ButtonStyled onClick={() => bundlePopUpHandler(1)}>
                View bundles
              </ButtonStyled>
              <ButtonStyled onClick={() => bundlePopUpHandler(2)}>
                View bundles
              </ButtonStyled>
              <ButtonStyled onClick={() => bundlePopUpHandler(3)}>
                View bundles
              </ButtonStyled>
            </BoxContainer>
          )}

          <BoxContainer
            flexDirection={'column'}
            justifyContent={'flex-start'}
            marginTop={'2.25rem'}>
            <BoxContainer
              bgcolor={personalisedItem?.headerColor}
              color={personalisedItem?.headerTextColor}
              alignContent={'center'}
              justifyContent={'center'}
              padding={'0.5rem'}
              borderRadius={'0.625rem 0.625rem 0 0'}
              sx={{ width: isTablet ? '25%' : '50%' }}>
              <TextStyled
                fontSize={'0.625rem'}
                fontWeight={600}
                letterSpacing={'1.5px'}
                textAlign={'center'}
                padding={'0.125rem 0'}>
                {personalisedItem?.title}
              </TextStyled>
            </BoxContainer>
            <BoxContainer
              flexDirection={'column'}
              alignItems={'center'}
              gap={'1.5rem'}
              border={'1px solid' + personalisedItem?.borderColor}
              borderRadius={'0 0.625rem 0.625rem 0.625rem'}
              padding={'0.625rem 0.625rem 1rem'}>
              <BoxContainer flexDirection={'column'}>
                <BoxContainer
                  flexDirection={'column'}
                  marginBottom={'1.125rem'}>
                  <TextStyled
                    color={theme.palette.primary.dark}
                    fontSize={isTablet ? '1rem' : '0.875rem'}
                    fontWeight={700}>
                    {personalisedItem?.productName}
                  </TextStyled>
                  <TextStyled
                    fontSize={'0.75rem'}
                    fontWeight={400}
                    lineHeight={'1.25rem'}
                    dangerouslySetInnerHTML={personalisedItem?.text[0]}
                  />
                </BoxContainer>
                <BoxContainer flexDirection={'column'} gap={'0.625rem'}>
                  {personalisedItem?.text?.slice(1)?.map((item, index) => (
                    <BoxContainer
                      key={index}
                      alignItems={'center'}
                      gap={'0.5rem'}>
                      {item?.icon}
                      <TextStyled
                        key={index}
                        fontSize={'0.75rem'}
                        fontWeight={400}
                        lineHeight={'1.25rem'}
                        dangerouslySetInnerHTML={item}
                      />
                    </BoxContainer>
                  ))}
                </BoxContainer>
              </BoxContainer>
              <OutlineButtonStyled onClick={() => contactUsHandler()}>
                Contact Us
              </OutlineButtonStyled>
            </BoxContainer>
          </BoxContainer>
        </>
      )}
    </SectionContainer>
  );
}

export default JobAds;
