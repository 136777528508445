import React from 'react';
import PropTypes from 'prop-types';
import s from './index.module.scss';

const HeroBanner = ({ children, className, src, bgAlpha, ...props }) => (
  <div className={[s.heroWrapper, className].join(' ')} {...props}>
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, ${bgAlpha}), rgba(0, 0, 0, ${bgAlpha})), url("${src}")`
      }}
      className={s.heroBackground}>
      <div className={s.heroContentWrapper}>
        <div className={s.heroContent}>{children}</div>
      </div>
    </div>
  </div>
);

HeroBanner.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  bgAlpha: PropTypes.number
};

HeroBanner.defaultProps = {
  children: '',
  className: '',
  bgAlpha: 0.3
};

export default HeroBanner;
