import { Grid } from '@mui/material';
import React from 'react';
import SearchBar from './SearchBar/index';
import AdvancedFilter from './AdvancedFilter/index';
import styles from './index.module.scss';

const ToolBar = ({
  setAdvancedFilter,
  setSearchText,
  setIsRefresh,
  searchType,
  setSearchType,
  advancedFilter
}) => (
  <div className={styles.toolBarContainer}>
    <Grid container>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <SearchBar
          setSearchText={setSearchText}
          setIsRefresh={setIsRefresh}
          searchType={searchType}
          setSearchType={setSearchType}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <AdvancedFilter
          setAdvancedFilter={setAdvancedFilter}
          setIsRefresh={setIsRefresh}
          advancedFilter={advancedFilter}
        />
      </Grid>
    </Grid>
  </div>
);

export default ToolBar;
