import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Popover
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { withStyles } from '@mui/styles';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import Colors from '../../../../styles/colors.module.scss';
import { Button } from '../../../../components';
import styles from './index.module.scss';
import { useDispatch } from 'react-redux';
import { fetchTracks } from '../../../../redux/actions/company_actions';

const YearOfExpSlider = withStyles({
  color: '#52af77',
  height: 8,
  '&.MuiSlider-track': {
    border: 'none'
  },
  '&.MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit'
    },
    '&:before': {
      display: 'none'
    }
  },
  '&.MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)'
    },
    '& > *': {
      transform: 'rotate(45deg)'
    }
  }
})(Slider);

const PrettoSlider = styled(Slider)({
  color: Colors.priPurple,
  height: 8,
  '& .MuiSlider-track': {
    border: 'none'
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit'
    },
    '&:before': {
      display: 'none'
    }
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    color: Colors.priBlack,
    fontWeight: 'bold',
    fontSize: '15px',
    // borderRadius: '50% 50% 50% 0',
    backgroundColor: 'transparent',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, 110%) rotate(-45deg) scale(1)'
    },
    '& > *': {
      transform: 'rotate(45deg)'
    }
  }
});

const useStyles = makeStyles({
  formControlLabel: {
    '&.MuiFormControlLabel-root': {
      paddingBottom: '0px'
    }
  }
});

const AdvancedFilter = ({
  setAdvancedFilter,
  setIsRefresh,
  advancedFilter
}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState({
    yearsOfExp: 0,
    videoCoverLetter: false,
    malaysians: null,
    highestEduLevel: [],
    trackIds: []
  });

  useEffect(() => {
    dispatch(fetchTracks());
  }, []);

  const tracks = useSelector(state => state.companies.tracks);

  const classes = useStyles();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = isApplied => () => {
    setAnchorEl(null);

    if (!isApplied && advancedFilter) {
      setFilters({
        yearsOfExp:
          advancedFilter.yearsOfExp === null ? 0 : advancedFilter.yearsOfExp,
        videoCoverLetter:
          advancedFilter.videoCoverLetter === null
            ? false
            : advancedFilter.videoCoverLetter,
        malaysians: advancedFilter.malaysians,
        highestEduLevel: advancedFilter.highestEduLevel,
        trackIds: advancedFilter.trackIds
      });
    }
  };

  const open = Boolean(anchorEl);

  const marks = [
    {
      value: 0,
      label: '0'
    },
    {
      value: 100,
      label: '10+'
    }
  ];

  const highestEduLevel = [
    { id: 'Secondary School', title: 'Secondary School' },
    {
      id: 'Pre-University (A-Levels, Foundation, Diploma or equivalent)',
      title: 'Pre-University (A-Levels, Foundation, Diploma or equivalent)'
    },
    {
      id: `Undergraduate (Bachelor's Degree or equivalent)`,
      title: `Undergraduate (Bachelor's Degree or equivalent)`
    },
    {
      id: `Postgraduate (Master's Degree or equivalent or higher)`,
      title: `Postgraduate (Master's Degree or equivalent or higher)`
    },
    { id: 'Other', title: 'Other' }
  ];

  const resetAdvanceFilter = () => {
    setFilters({
      yearsOfExp: 0,
      videoCoverLetter: false,
      malaysians: null,
      highestEduLevel: [],
      trackIds: []
    });
  };

  const valueLabelFormat = value => {
    if (value === 0 || value === 1 || value === 100) return '';
    return value / 10;
  };

  const onSliderChange = (event, newValue) => {
    setFilters({
      ...filters,
      yearsOfExp: newValue
    });
  };

  const onClickHighEduLevel = id => {
    if (!filters?.highestEduLevel?.includes(id)) {
      setFilters({
        ...filters,
        highestEduLevel: [...filters?.highestEduLevel, id]
      });
    } else {
      const removedArray = filters?.highestEduLevel.filter(item => item !== id);
      setFilters({
        ...filters,
        highestEduLevel: removedArray
      });
    }
  };

  const onClickJobTracks = id => {
    const intId = Number(id);
    if (!filters?.trackIds?.includes(intId)) {
      setFilters({
        ...filters,
        trackIds: [...filters?.trackIds, intId]
      });
    } else {
      const removedArray = filters?.trackIds.filter(item => item !== intId);
      setFilters({
        ...filters,
        trackIds: removedArray
      });
    }
  };

  const onApplyAdvancedFilter = () => {
    try {
      setIsRefresh(true);
      setAdvancedFilter(filters);
      handleClose(true)();

      window.dataLayer.push({
        event: 'apply-filter-acv'
      });
    } catch {}
  };

  useEffect(() => {}, [filters]);

  return (
    <div className={styles.advancedFilterContainer}>
      <div
        tabIndex={0}
        onKeyPress={() => {}}
        role="button"
        className={styles.advancedFilterButton}
        onClick={handleClick}>
        <p className={styles.advancedFilterText}>Advanced Filter</p>
        <ArrowDropDownIcon />
      </div>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        className={styles.advancedFilterPopOver}>
        <div className={styles.popOverContainer}>
          <div className={styles.yearOfExpHeaderAndResetContainer}>
            <h6 style={{ fontSize: '20px' }} className={styles.yearOfExpTitle}>
              Years of Experience
            </h6>
            <div className={styles.clearAllButton} onClick={resetAdvanceFilter}>
              CLEAR ALL
            </div>
          </div>
          <div className={styles.yearOfExpContainer}>
            <PrettoSlider
              valueLabelDisplay="on"
              valueLabelFormat={valueLabelFormat}
              defaultValue={10}
              step={10}
              marks={marks}
              style={{ width: 350 }}
              value={filters?.yearsOfExp}
              onChange={onSliderChange}
            />
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      checked={filters?.videoCoverLetter}
                      style={{ color: Colors.priPurple }}
                      onClick={() =>
                        setFilters({
                          ...filters,
                          videoCoverLetter: !filters?.videoCoverLetter
                        })
                      }
                    />
                  }
                  label={
                    <p className={styles.checkBoxTitle}>Video Cover Letter</p>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      checked={filters?.malaysians}
                      style={{ color: Colors.priPurple }}
                      onClick={() =>
                        setFilters({
                          ...filters,
                          malaysians: !filters?.malaysians
                        })
                      }
                    />
                  }
                  label={<p className={styles.checkBoxTitle}>Malaysian only</p>}
                />
              </FormGroup>
            </FormControl>
          </div>
          <div>
            <h6 style={{ fontSize: '20px' }} className={styles.sectionTitle}>
              Highest Education Level
            </h6>
            <FormControl component="fieldset">
              <FormGroup>
                {highestEduLevel &&
                  highestEduLevel.map(item => (
                    <FormControlLabel
                      className={classes.formControlLabel}
                      key={item?.id}
                      control={
                        <Checkbox
                          color="default"
                          style={{ color: Colors.priPurple }}
                          checked={filters?.highestEduLevel?.includes(item.id)}
                          onClick={() => onClickHighEduLevel(item?.id)}
                        />
                      }
                      label={
                        <p className={styles.checkBoxTitle}>{item?.title}</p>
                      }
                    />
                  ))}
              </FormGroup>
            </FormControl>
          </div>
          <div>
            <h6 style={{ fontSize: '20px' }} className={styles.sectionTitle}>
              Job Tracks
            </h6>
            <FormControl component="fieldset">
              <FormGroup>
                {tracks &&
                  tracks?.map(item => (
                    <FormControlLabel
                      className={classes.formControlLabel}
                      key={item?.id}
                      control={
                        <Checkbox
                          color="default"
                          style={{ color: Colors.priPurple }}
                          checked={filters?.trackIds?.includes(Number(item.id))}
                          onClick={() => onClickJobTracks(item?.id)}
                        />
                      }
                      label={
                        <p className={styles.checkBoxTitle}>{item?.title}</p>
                      }
                    />
                  ))}
              </FormGroup>
            </FormControl>
          </div>
        </div>
        <div
          style={{ position: 'sticky', background: '#fff', bottom: '0' }}
          className={styles.applyButtonContainer}>
          <Button
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '2px',
              backgroundColor: Colors.priPurple,
              color: Colors.priWhite,
              fontWeight: 'bold'
            }}
            onClick={onApplyAdvancedFilter}>
            Apply
          </Button>
        </div>
      </Popover>
    </div>
  );
};

export default AdvancedFilter;
