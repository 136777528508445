import React, { Fragment } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Slider } from '@mui/material';
import { FilterTitle } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { updateSearchTalentFilters } from '../../../../redux/actions/talent_action';
import { experiences } from '../../../../utils/Constants';
import moment from 'moment';
import { FilterContainerStyle } from '../styles';

let cExperiences = [...experiences];
let prevValue = 0;

const Experience = () => {
  const dispatch = useDispatch();

  const filters = useSelector(state => state.talents.searchTalentFilters);

  const onCheckFreshGrad = freshGrad => () => {
    let cFilters = { ...filters };

    if (typeof filters['freshGrad'] == 'boolean') {
      cFilters['freshGrad'] = null;
    } else {
      if (freshGrad) {
        cFilters['freshGrad'] = true;
      } else {
        cFilters['freshGrad'] = false;
      }
    }

    dispatch(updateSearchTalentFilters(cFilters));
  };

  const onSlide = (e, value) => {
    let cFilters = { ...filters };

    const prevIndex = cExperiences.findIndex(item => {
      return item.value == prevValue;
    });

    if (prevIndex > -1 && prevValue > 0 && prevValue < 10) {
      cExperiences.splice(prevIndex, 1);
    }

    const max = moment().year() - value;
    const min = max - 100;

    cFilters['minGraduationYear'] = min;
    cFilters['maxGraduationYear'] = max;
    cFilters['minYearExperience'] = value;

    dispatch(updateSearchTalentFilters(cFilters));

    valuetext(prevValue, value);

    prevValue = value;
  };

  const valuetext = (prevValue, value) => {
    if (value == 0 || value == 10) {
      return;
    }

    const newIndex = cExperiences.findIndex(item => {
      return item.value == value;
    });

    if (newIndex == -1 || prevValue == value) {
      cExperiences.push({
        value: value,
        label: `${value}`
      });
    }
  };

  return (
    <FilterContainerStyle>
      <FilterTitle>Minimum Years of Experience</FilterTitle>

      <div style={{ margin: '12px 16px 0' }}>
        <Slider
          aria-label="experiences"
          defaultValue={0}
          step={1}
          valueLabelDisplay={'auto'}
          marks={cExperiences}
          min={0}
          max={10}
          value={filters['minYearExperience']}
          onChange={onSlide}
          sx={{
            color: '#222',
            '& .MuiSlider-thumb': {
              boxShadow: 'none',
              background: '#fff',
              outline: '2px solid #222',
              height: '18px',
              width: '18px'
            },
            '& .MuiSlider-rail': {
              backgroundColor: '#222',
              opacity: 1
            },
            '& .MuiSlider-track': {
              backgroundColor: '#d8d8d8',
              border: 'none',
              opacity: 1
            },
            '& .MuiSlider-markLabel': {
              fontSize: 12
            }
          }}
        />
      </div>

      <FormGroup style={{ margin: '0px 10px 0' }}>
        <CheckboxSelection
          item={'Fresh Grads only'}
          checked={filters['freshGrad']}
          onClick={onCheckFreshGrad(true)}
          disabled={filters['freshGrad'] == false}
        />

        <CheckboxSelection
          item={'Experienced only'}
          checked={filters['freshGrad'] == false}
          onClick={onCheckFreshGrad(false)}
          disabled={filters['freshGrad']}
        />
      </FormGroup>
    </FilterContainerStyle>
  );
};

export default Experience;

export const CheckboxSelection = ({ item, checked, onClick, disabled }) => {
  return (
    <div
      key={`${item}`}
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onClick={onClick}
            disabled={disabled}
            sx={{
              '&.Mui-checked': {
                color: '#222'
              },
              padding: '2px 9px'
            }}
          />
        }
        label={item}
        sx={{
          '& .MuiTypography-root': {
            fontSize: 14,
            letterSpacing: '0.15px'
          },
          '& .MuiSvgIcon-root': {
            fontSize: 20
          }
        }}
      />
    </div>
  );
};
