import { styled as styling } from '@mui/material/styles';
import { TableCell } from '@mui/material';
import { TableRow } from '@mui/material';
import { TableContainer } from '@mui/material';

export const PageTitle = styling('div')((props) => ({
  position: 'relative',
  paddingTop: props.is_banner? '180px' : '0',
}));

export const Text = styling('p')((props) => ({
  fontFamily: 'Inter',

  ...(props.page_title == 'true' && {
    marginBottom: '37px',
    fontSize: '24px',
    fontFamily: 'Inter',
    fontWeight: 700,
  }),

    ...(props.inactive == 'true' && {
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontStyle: "italic",
    color: `rgba(0, 0, 0, 0.38)`,
    margin: '0',
  }),

  ...(props.table_content == 'true' && {
    margin: '0',
    fontSize: '14px',
  }),

  ...(props.table_header == 'true' && {
    fontSize: '12px',
    fontWeight: 700,
    margin: '0',
    textTransform: 'uppercase',
  }),

  ...(props.order_status == 'pending-payment' && {
    fontSize: '14px',
    fontWeight: 700,
    margin: '0',
    color: '#f75442',
  }),

  ...(props.order_status == 'completed-payment' && {
    fontSize: '14px',
    fontWeight: 700,
    margin: '0',
    color: '#222',
  }),

  ...(props.order_status == 'cancelled' && {
    fontSize: '14px',
    fontWeight: 700,
    margin: '0',
    color: '#222',
  }),

  ...(props.tab == 'true' && {
    fontSize: '16px',
    fontWeight: 700,
    fontFamily: 'Inter',
    padding: '0 20px',
  }),

  ...(props.unpaid_count == 'true' && {
    margin: '0',
    color: '#FFF',
    fontSize: '8px',
    fontWeight: 700,
    fontFamily: 'Inter',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  }),
}));

export const Underline = styling('div')((props) => ({
  border: '2px solid #222',
  position: 'absolute',
  zIndex: 2,
  bottom: '7px',
  width: '100%',
}));

export const TabContainer = styling('div')((props) => ({
  cursor: 'pointer',
  position: 'relative',

  '&:hover': {
    backgroundPosition: 'center',
    transition: 'background 0.3s',
    background: '#efefef radial-gradient(circle, transparent 1%, #efefef 1%) center/15000%',
  },
  '&:active': {
    backgroundPosition: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    backgroundSize: '100%',
    transition: 'background 0s',
  },

  ...(props.type == 'unpaid_order' && {
    marginLeft: '10px',
  }),

}));

export const UnpaidCountContainer = styling('div')((props) => ({
  width: '20px',
  height: '20px',
  borderRadius: '50%',
  background: '#f75442',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
}));

export const TableCellStyled = styling(TableCell)((props) => ({
  borderBottom: '1px solid #e0e0e0',
  
  ...(props.cell_type == 'items' && {
    padding: '50px 0 50px 150px',
    width: '400px',
  }),

  ...(props.cell_type == 'quantity' && {
    width: '300px',

    '@media (min-width: 1600px)':{
      width: '30%',
    }
  }),

  ...(props.cell_type == 'created-by' && {
    padding: '27px 0px',

    '@media (min-width: 1600px)':{
      width: '20%',
    }
  }),

  ...(props.cell_type == 'action-buttons' && {
    paddingRight: '40px',
    paddingLeft: '0',

    '@media (min-width: 1600px)':{
      width: '20%',
    }
  }),
}));

export const TableRowStyled = styling(TableRow)((props) => ({
  ...(props.row_type == 'body' && {
    boxShadow: '0px 0px 0px 0px #fff',
  }),

  ...(props.row_type == 'body-title' && {
    background: '#f1f1f4',
    boxShadow: '0px 0px 0px 0px #fff',
  }),

  ...(props.row_type == 'head-title' && {
    background: '#f1f1f4',
    boxShadow: '0px 0px 0px 0px #fff',
  }),
}));

export const TableContainerStyled = styling(TableContainer)({
  boxShadow: '0px 28px 23px rgba(0, 0, 0, 0.05)',
});

export const HeadTitleContainer = styling('div')({
  position: 'absolute',
  top: '30%',
  right: '16%',
});

export const BodyTitleContainer = styling('div')((props) => ({
  ...(props.body_title == 'items' && {
    textAlign: 'left',
  }),

  ...(props.body_title == 'quantity' && {
    textAlign: 'center',
  }),

  ...(props.body_title == 'created-by' && {
    textAlign: 'center',
  }),

  ...(props.body_title == 'none' && {
    textAlign: 'center',
  }),

}));

export const BodyContentContainer = styling('div')((props) => ({
  ...(props.body_content == 'product-title' && {
    marginTop: '20px',
    textAlign: 'left',
  }),

  ...(props.body_content == 'product-quantity' && {
    marginTop: '20px',
    textAlign: 'center',
  }),
  ...(props.body_content == 'product-created-by' && {
    marginTop: '20px',
    textAlign: 'center',
  }),
  ...(props.body_content == 'buttons' && {
    marginBottom: '15px',
  }),
}));

export const ButtonContainer = styling('div')((props) => ({
  ...(props.button_title == 'create-new-order' && {
    display: 'flex',
    justifyContent: 'flex-end',
  }),
}));

export const TabsContainer = styling('div')({
  marginBottom: '28px',
});

export const Tabs = styling('div')({
  display: 'flex',
});

export const UnpaidCountWrapper = styling('div')({
  position: 'absolute',
  top: '-2px',
  right: '-2px',
});

export const PaginationContainer = styling('div')((props) => ({
  ...(props.pagination_position == 'top' && {
    display: 'flex',
    justifyContent: 'end',
  }),

  ...(props.pagination_position == 'bottom' && {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '20px',
  }),
}));

export const SpinnerContainer = styling('div')({
  height: '400px',
});

export const TableWrapper = styling('div')({
  marginTop: '30px',
});

export const NoContentContainer = styling('div')({
  textAlign: 'center',
  padding: '30px 0',
  background: '#fff',
});

