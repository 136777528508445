import { styled as styling } from '@mui/material/styles';

export const ResumeIframe = styling('iframe')({
  height: '680px',

  '@media (max-width: 1024px)': {
    width: '100%',
    height: '680px',
  }

});
