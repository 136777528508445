import React, { useEffect } from 'react';
import { PopUpDialog, Spinner } from '../../../../components';
import {
  FirstContainer,
  SecondContainer,
  ButtonWrapper,
  ButtonContainer,
  Text,
  TextContainer,
  TextFieldStyled,
  ButtonStyled
} from './styles';
import {
  WarningToast,
  SuccessToast,
  ErrorToast
} from '../../../../utils/ToastUtils';
import { updateInterviewStatus } from '../../../../../src/redux/actions/application_action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as types from '../../../../../src/redux/types/application_type';

const OfferJobPopUp = props => {
  const [offerValue, setOfferValue] = React.useState('');

  const offerValueChange = event => {
    setOfferValue(event.currentTarget.value.substring(0, 350));
  };

  const mailtoOffered = response => {
    var accountInfo = localStorage.getItem('accountInfo');
    accountInfo = accountInfo ? JSON.parse(accountInfo) : accountInfo;
    var candidate_name =
      response?.interview?.jobApplication?.user?.name.replace(/ /g, '%20');
    var company_name = accountInfo?.companyName.replace(/ /g, '%20');
    var job_title = response?.interview?.jobApplication?.job?.title
      .replace(/ /g, '%20')
      .replace(/&/g, 'and');
    var candidate_email = response?.interview?.jobApplication?.user.email;

    window.location.href =
      'mailto:' +
      candidate_email +
      '?subject=Job%20Offer%20from%20' +
      company_name +
      '&body=Hi%20' +
      candidate_name +
      '%2C%0A%0AWe%20are%20pleased%20to%20offer%20you%20the%20position%20of%20' +
      job_title +
      '%20at%20' +
      company_name +
      '.%20%0A%0AIf%20you%20accept%20this%20offer%2C%20your%20start%20date%20will%20be%20%5BSTART%20DATE%5D%20or%20we%20can%20discuss%20start%20dates%20based%20on%20what%20is%20possible%20at%20your%20end.%20%0A%0APlease%20let%20me%20know%20if%20you%20have%20any%20questions.%20If%20you%20are%20agreeable%2C%20please%20reply%20yes%20to%20this%20email%20and%20we%20will%20also%20need%20the%20following%20information%20to%20proceed%20with%20your%20offer%20letter%3A%20%0A%0AFull%20name%3A%0AI%2FC%3A%0ANumber%3A%0AAddress%3A%0AStart%20Date%3A%20%0A%0AHope%20to%20hear%20from%20you%20soon.%0A%0ABest%20regards%2C';
  };

  // Update application/interview status to offered/rejected
  const changeInterviewStatus = (interview, status, remark) => {
    const isInterview = interview?.jobApplication ? true : false;
    const currentApplicationState = isInterview ? 'interview' : interview.state;

    const updateInterviewParams = {
      id: interview.id,
      isInterview: isInterview,
      currentState: interview.state,
      status: status,
      remark: remark
    };

    props.updateInterviewStatus(updateInterviewParams).then(res => {
      if (res.type === types.UPDATE_INTERVIEW_STATUS_SUCCEED) {
        SuccessToast(
          `Successfully ${
            status === 'offered'
              ? 'offered this candidate a position.'
              : 'rejected this candidate.'
          }`
        );

        // For list view
        if (props.setCandidates && props.candidates) {
          const index = props.candidates.findIndex(
            item => item.node.id === interview.id
          );

          if (res.interview?.jobApplication?.job) {
            if (props.setJobData) {
              props.setJobData(res.interview.jobApplication.job);
            }
          }

          if (index != -1) {
            let currentCandidates = [...props.candidates];

            // Remove application/interview from current list once offer is made
            currentCandidates.splice(index, 1);
            props.setCandidates(currentCandidates);
            
            if (props.moveApplicationCountToOffer) {
              props.moveApplicationCountToOffer(currentApplicationState);
            }

            if (props.setCurrentCandidates) {
              props.setCurrentCandidates(currentCandidates);
            }

            if (props.setIsSelected) {
              props.setIsSelected(null);
            }
          }
        }

        mailtoOffered(res);
      } else {
        ErrorToast(
          `Failed to ${
            status === 'offered'
              ? 'offer a position to this candidate'
              : 'reject this candidate'
          } at the moment, please try again later.`
        );
      }

      setOfferValue('');
      props.handleOfferJobClose();
    });
  };

  const getCandidate = props => {
    if (props.allApplications[props.currentCandidateIndex]) {
      return props.allApplications[props.currentCandidateIndex];
    }

    return null;
  };

  const getUser = (candidate, interview) => {
    let user;
    if (interview?.jobApplication?.user) {
      user = interview.jobApplication.user;
    } else if (interview?.user) {
      user = interview.user;
    } else if (candidate?.jobApplication?.user) {
      user = candidate.jobApplication.user;
    } else if (candidate?.user) {
      user = candidate.user;
    }

    return user;
  };

  const onOfferConfirmed = interview => () => {
    changeInterviewStatus(interview, 'offered', offerValue);
  };

  const onOfferPopupClose = () => {
    setOfferValue('');
    props.handleOfferJobClose();
  };

  return (
    <PopUpDialog
      open={props.isOfferJobOpen}
      handleClose={onOfferPopupClose}
      origin="OfferPopUp"
      title={`Offer this position to ${
        getUser(getCandidate(props), props.interview)?.name
      }?`}>
      <FirstContainer>
        <TextContainer type="message">
          <Text type="basic">Message to candidate (optional):</Text>
        </TextContainer>
        <div>
          <TextFieldStyled
            id="filled-multiline-flexible"
            placeholder={`Record Remarks*`}
            multiline
            rowsMax={4}
            value={offerValue}
            type={'text'}
            onChange={offerValueChange}
            variant="filled"
            inputProps={{
              style: {
                borderRadius: '5px',
                width: '100%',
                height: '130px',
                overflow: 'auto',
                transform: 'translateY(-10px)',
                fontFamily: 'Inter',
                fontSize: '14px'
              }
            }}
            InputProps={{
              style: {
                fontSize: '15px',
                letterSpacing: '0.03rem',
                borderRadius: '5px',
                width: '100%',
                height: '150px',
                background: '#f5f5f5'
              },
              disableUnderline: true
            }}
          />
          <TextContainer type="count">
            <Text type="basic">{offerValue.length}/350</Text>
          </TextContainer>
        </div>
      </FirstContainer>
      <SecondContainer>
        <ButtonWrapper>
          <ButtonContainer>
            <ButtonStyled type="cancel" onClick={onOfferPopupClose}>
              Cancel
            </ButtonStyled>
          </ButtonContainer>
          <ButtonContainer>
            <ButtonStyled
              type="confirm"
              onClick={onOfferConfirmed(
                getCandidate(props) || props.interview
              )}>
              {props.updatingInterviewStatus ? (
                <Spinner size="XS" color="white" noPadding={true} />
              ) : (
                'Make Offer'
              )}
            </ButtonStyled>
          </ButtonContainer>
        </ButtonWrapper>
      </SecondContainer>
    </PopUpDialog>
  );
};

const mapStateToProps = state => {
  return {
    allApplications: state.applications.allApplications,
    currentCandidateIndex: state.applications.currentCandidateIndex,
    updatingInterviewStatus: state.applications.updatingInterviewStatus
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateInterviewStatus: bindActionCreators(updateInterviewStatus, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferJobPopUp);
