import React, { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import {
  MainContainer,
  FirstContainer,
  SecondContainer,
  ImageContainer,
  ProfilePicture,
  InfoContainer,
  SubInfoContainer,
  Name,
  IdentityContainer,
  Age,
  Dot,
  Nationality,
  ShortSummary,
  SelectStyled,
  MenuItemStyled,
  StatusContainer,
  StatusText,
  FiberManualRecordIconStyled,
  IconsContainer,
  IconWrapper,
  IconButtonStyled,
  FontAwesomeIconStyled,
  GetAppIconStyled,
  EmailIconStyled,
  ArrowsContainer,
  ArrowButtonWrapper,
  ArrowIconButton,
  ChevronRightIconStyled,
  ChevronLeftIconStyled,
  ButtonStyled,
  ShortSummaryContainer,
  InterviewLengthContainer,
  InterviewLength,
  CircularProgressStyled,
  Text
} from './styles';
import { JOB_APPLICATION_STATUS } from '../../../utils/Constants';
import BlackListPopUp from '../../../pages/ManageCandidates/List/CandidateInformation/BlackListPopUp';
import {
  toggleRejectPopup,
  updateCandidateSnapshotStatus,
  updateFirstScheduleInterviewModalStatus,
  updateNextScheduleInterviewModalStatus,
  updateInterviewData
} from '../../../redux/actions/application_action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import store from '../../../../src/redux/stores/store';
import Linkify from 'react-linkify';
import SplitButton from '../../SplitButton';
import RejectPopUp from '../../../pages/Jobs/PopUps/RejectPopUp';
import { useSelector, useDispatch } from 'react-redux';
import StatusSelect from '../../StatusDropdown/StatusSelect';
import { CustomButton } from '../../StatusDropdown/CustomButton';
import { convertAndDownloadResume } from '../../../hooks/useDownload';
import { downloadResume } from '../../../redux/actions/talent_action';

const CandidateSnapshotBasicInfo = props => {
  const {
    candidate,
    companyData,
    hasPrevious,
    hasNext,
    previousCandidate,
    nextCandidate,
    loading,
    changeJobApplicationStatus,
    setBlackListState,
    changeApplicationState,
    setIsOfferJobOpen,
    setListApplicationState,
    blackListState,
    isAts,
    updateApplicationState,
    pageName
  } = props;

  const user = candidate?.user || candidate?.jobApplication?.user;
  const job = candidate?.job || candidate?.jobApplication?.job;
  const application = candidate?.state ? candidate : candidate?.jobApplication;
  const isOffered = candidate?.jobApplication ? candidate.offered : false;
  const isHired = application && application.employerHiredStatus ? true : false;
  const [isBlacklistOpen, setIsBlacklistOpen] = useState(false);

  const rejectPopupStatus = useSelector(
    state => state.applications.rejectPopupStatus
  );

  const dispatch = useDispatch();

  const getOfferButtonMessage = () => {
    if (isHired) return 'Hired';
    if (isOffered) return 'Offered';

    return 'Make Offer';
  };

  const triggerCandidateEmail = event => {
    window.open(
      `mailto:${user?.email}?subject=Interview for ${job?.title} at ${companyData?.name} &body=Hi ${user?.name},%0A%0A Thanks for applying for the role of ${job?.title} using Hiredly!%0A%0AWe have reviewed your application and would like to invite you for an interview at our office. Do let me know if you are available at the following times?%0A%0ADate:%0ATime:%0AVenue:%0A%0ALet me know if you have questions, and I look forward to meeting you in person. Hope to hear from you soon.%0A%0ABest regards,`
    );
  };

  const saveFile = () => {
    if (user?.resume) {
      const params = {
        userId: user?.id
      };

      dispatch(downloadResume(params)).then(res => {
        convertAndDownloadResume(res, user);
      });
    }
  };

  const scheduleInterviewIconClicked = candidate => () => {
    if (!isAts) {
      props.updateInterviewData(candidate);
    }

    const application = candidate?.jobApplication
      ? candidate.jobApplication
      : candidate;

    if (application.interviews?.length > 0) {
      props.updateNextScheduleInterviewModalStatus(true);
    } else {
      props.updateFirstScheduleInterviewModalStatus(true, null);
    }
  };

  const onOfferButtonClicked = () => {
    setIsOfferJobOpen(true);
  };

  const getInterviewLength = interviews => {
    const remainder = interviews.length % 10;

    if (interviews.length > 0) {
      if (remainder == 1) {
        return interviews.length + `st`;
      } else if (remainder == 2) {
        return interviews.length + `nd`;
      } else if (remainder == 3) {
        return interviews.length + `rd`;
      } else {
        return interviews.length + `th`;
      }
    } else {
      return;
    }
  };

  const allowNextInterview = candidate => {
    let application = candidate?.jobApplication
      ? candidate.jobApplication
      : candidate;

    const interviewLength = application?.interviews
      ? application.interviews.length
      : 0;

    if (interviewLength > 0) {
      const lastInterview = application.interviews[0];

      // Disable next interview if last interview already rejected or offered
      if (
        lastInterview.status == 'rejected' ||
        lastInterview.status == 'offered'
      ) {
        return false;
      }
    }

    return true;
  };

  const hideOfferInterviewHired = () => {
    if (pageName == 'new-applicant-page') return true;
  };

  return (
    <MainContainer
      show_hired_card={`${
        candidate?.state !== 'undecided' &&
        candidate?.state !== 'rejected' &&
        candidate?.employerHiredStatus === null
      }`}>
      <FirstContainer>
        <ImageContainer>
          <ProfilePicture src={user?.profileImageSquare} />
        </ImageContainer>
        <InfoContainer>
          <SubInfoContainer>
            <Name>Hi, I’m {user?.name}!</Name>
            <IdentityContainer>
              <div>
                <Age>{user?.age} years old</Age>
              </div>
              <Dot />
              <Nationality>{user?.nationality}</Nationality>
            </IdentityContainer>
          </SubInfoContainer>
          <ShortSummaryContainer>
            <ShortSummary>
              <Linkify>{user?.shortSummary}</Linkify>
            </ShortSummary>
          </ShortSummaryContainer>
        </InfoContainer>
      </FirstContainer>
      {/* action buttons */}

      {/* Note: candidate.state is to check list view latest status while application.interviews[0].status is to check ats latest status */}
      <SecondContainer>
        {application?.state == JOB_APPLICATION_STATUS.REJECTED ||
        application?.state == JOB_APPLICATION_STATUS.BLACKLISTED ||
        (candidate?.interviews?.length > 0 &&
          candidate?.interviews[0]?.status ==
            JOB_APPLICATION_STATUS.REJECTED) ||
        application?.interviews[0]?.status ==
          JOB_APPLICATION_STATUS.REJECTED ? (
          <>
            <StatusSelect
              disabled={
                application?.state == JOB_APPLICATION_STATUS.BLACKLISTED ||
                application?.state == JOB_APPLICATION_STATUS.REJECTED ||
                (candidate?.interviews?.length > 0 &&
                  candidate?.interviews[0]?.status ==
                    JOB_APPLICATION_STATUS.REJECTED) ||
                application?.interviews[0]?.status ==
                  JOB_APPLICATION_STATUS.REJECTED ||
                !allowNextInterview(application)
                  ? true
                  : false
              }
              value={
                candidate?.state ||
                candidate?.status ||
                (candidate?.jobApplication &&
                  candidate?.jobApplication?.state) ||
                (candidate?.jobApplication?.interviews?.length > 0 &&
                  candidate?.jobApplication?.interviews[0].status) ||
                (candidate?.interviews?.length > 0 &&
                  candidate?.interviews[0].status)
              }
              onChange={event => {
                if (event.target.value === JOB_APPLICATION_STATUS.REJECTED) {
                  dispatch(toggleRejectPopup(true));
                } else if (
                  event.target.value === JOB_APPLICATION_STATUS.BLACKLISTED
                ) {
                  if (setBlackListState) {
                    setBlackListState({
                      loading: false,
                      open: true,
                      application: application,
                      currentState: application.state,
                      nextState: event.target.value,
                      currentIndex:
                        store.getState().applications.currentCandidateIndex,
                      updateSnapshot: true
                    });
                  } else {
                    setIsBlacklistOpen(true);
                  }
                } else {
                  if (changeApplicationState) {
                    changeApplicationState(
                      application.state,
                      event.target.value,
                      -1,
                      0,
                      '',
                      true,
                      application.id,
                      true
                    );
                  } else {
                    changeJobApplicationStatus({
                      value: event.target.value
                    });
                  }
                }
              }}
            />
            <div style={{ width: '5px' }} />
            {!hideOfferInterviewHired() &&
              (candidate?.state == JOB_APPLICATION_STATUS.SHORTLISTED ||
                candidate?.state == JOB_APPLICATION_STATUS.KIV) && (
                <CustomButton
                  type={isOffered ? 'disabled' : 'basic'}
                  disabled={isOffered}
                  onClick={onOfferButtonClicked}
                  child={getOfferButtonMessage()}
                />
              )}
          </>
        ) : isOffered ? (
          <ButtonStyled
            type={isOffered ? 'disabled' : 'basic'}
            disabled={isOffered}
            onClick={onOfferButtonClicked}>
            {getOfferButtonMessage()}
          </ButtonStyled>
        ) : !hideOfferInterviewHired() &&
          (application?.state == '' ||
            application?.status == JOB_APPLICATION_STATUS.INTERVIEW ||
            application?.interviews?.length > 0) ? (
          <>
            <ButtonStyled
              type={isOffered ? 'disabled' : 'basic'}
              disabled={isOffered}
              onClick={onOfferButtonClicked}>
              {getOfferButtonMessage()}
            </ButtonStyled>
            <div style={{ width: '5px' }} />
            {!isOffered && (
              <SplitButton
                handleClick={() => dispatch(toggleRejectPopup(true))}
                firstButtonLabel={'Reject'}
                // onChangeJobApplicationStatus={changeJobApplicationStatus}
                blackListState={blackListState}
                setBlackListState={setBlackListState}
                changeApplicationState={changeApplicationState}
                application={application}
                setIsBlacklistOpen={setIsBlacklistOpen}
              />
            )}
          </>
        ) : (
          <>
            <StatusSelect
              disabled={
                application?.state == JOB_APPLICATION_STATUS.BLACKLISTED ||
                application?.state == JOB_APPLICATION_STATUS.REJECTED ||
                !allowNextInterview(application)
                  ? true
                  : false
              }
              value={
                candidate?.state ||
                candidate?.status ||
                (candidate?.jobApplication &&
                  candidate?.jobApplication?.state) ||
                (candidate?.jobApplication?.interviews?.length > 0 &&
                  candidate?.jobApplication?.interviews[0].status) ||
                (candidate?.interviews?.length > 0 &&
                  candidate?.interviews[0].status)
              }
              onChange={event => {
                if (event.target.value === JOB_APPLICATION_STATUS.REJECTED) {
                  dispatch(toggleRejectPopup(true));
                } else if (
                  event.target.value === JOB_APPLICATION_STATUS.BLACKLISTED
                ) {
                  if (setBlackListState) {
                    setBlackListState({
                      loading: false,
                      open: true,
                      application: application,
                      currentState: application.state,
                      nextState: event.target.value,
                      currentIndex:
                        store.getState().applications.currentCandidateIndex,
                      updateSnapshot: true
                    });
                  } else {
                    setIsBlacklistOpen(true);
                  }
                } else {
                  if (changeApplicationState) {
                    changeApplicationState(
                      application.state,
                      event.target.value,
                      -1,
                      0,
                      '',
                      true,
                      application.id,
                      true
                    );
                  } else if (updateApplicationState) {
                    updateApplicationState(application, event.target.value, '');
                  } else {
                    changeJobApplicationStatus({
                      value: event.target.value
                    });
                  }
                }
              }}
            />

            {!hideOfferInterviewHired() &&
              (application?.state == JOB_APPLICATION_STATUS.SHORTLISTED ||
                application?.state == JOB_APPLICATION_STATUS.KIV) && (
                <>
                  <div style={{ width: '5px' }} />
                  <CustomButton
                    type={isOffered ? 'disabled' : 'basic'}
                    disabled={isOffered}
                    onClick={onOfferButtonClicked}
                    child={getOfferButtonMessage()}
                  />
                </>
              )}
          </>
        )}
        <IconsContainer $hideOfferInterviewHired={hideOfferInterviewHired()}>
          <IconWrapper>
            <Tooltip title={<Text>Email applicant</Text>} placement="bottom">
              <IconButtonStyled id="ats-email-applicant-button">
                <EmailIconStyled onClick={triggerCandidateEmail} />
              </IconButtonStyled>
            </Tooltip>
          </IconWrapper>
          <IconWrapper>
            <Tooltip title={<Text>Download resume</Text>} placement="bottom">
              <IconButtonStyled
                id="ats-download-resume-button"
                onClick={saveFile}>
                <GetAppIconStyled />
              </IconButtonStyled>
            </Tooltip>
          </IconWrapper>
          <IconWrapper>
            {!hideOfferInterviewHired() && (
              <>
                {application?.interviews?.length > 0 && (
                  <InterviewLengthContainer>
                    <InterviewLength>
                      {getInterviewLength(application.interviews)}
                    </InterviewLength>
                  </InterviewLengthContainer>
                )}
                <Tooltip
                  placement="bottom"
                  title={<Text>Schedule Interview</Text>}>
                  <IconButtonStyled
                    disabled={
                      application?.state == JOB_APPLICATION_STATUS.REJECTED &&
                      application?.interviews?.length == 0
                    }
                    id="ats-schedule-interview-button"
                    onClick={scheduleInterviewIconClicked(candidate)}>
                    <FontAwesomeIconStyled
                      disabled={
                        application?.state == JOB_APPLICATION_STATUS.REJECTED &&
                        application?.interviews?.length == 0
                      }
                      icon={faCalendarAlt}
                      awesome_icon={'faCalenderAlt'}
                      interview_length={application?.interviews?.length}
                    />
                  </IconButtonStyled>
                </Tooltip>
              </>
            )}
          </IconWrapper>
        </IconsContainer>

        <ArrowsContainer>
          <ArrowButtonWrapper>
            <Tooltip placement="bottom" title={<Text>Previous applicant</Text>}>
              <ArrowIconButton
                component="span"
                onClick={previousCandidate}
                disabled={!hasPrevious().hasPrevious}>
                <ChevronLeftIconStyled disabled={!hasPrevious().hasPrevious} />
              </ArrowIconButton>
            </Tooltip>
          </ArrowButtonWrapper>
          <ArrowButtonWrapper>
            <Tooltip placement="bottom" title={<Text>Next applicant</Text>}>
              <ArrowIconButton
                component="span"
                onClick={nextCandidate}
                disabled={!hasNext().hasNext}>
                {loading ? (
                  <CircularProgressStyled size={12} />
                ) : (
                  <ChevronRightIconStyled disabled={!hasNext().hasNext} />
                )}
              </ArrowIconButton>
            </Tooltip>
          </ArrowButtonWrapper>
        </ArrowsContainer>
      </SecondContainer>

      <BlackListPopUp
        open={isBlacklistOpen}
        handleClose={() => setIsBlacklistOpen(false)}
        onChangeJobApplicationStatus={changeJobApplicationStatus}
        updateCandidateSnapshotStatus={props.updateCandidateSnapshotStatus}
        updateApplicationState={updateApplicationState}
        application={application}
      />
    </MainContainer>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    updateCandidateSnapshotStatus: bindActionCreators(
      updateCandidateSnapshotStatus,
      dispatch
    ),
    updateFirstScheduleInterviewModalStatus: bindActionCreators(
      updateFirstScheduleInterviewModalStatus,
      dispatch
    ),
    updateNextScheduleInterviewModalStatus: bindActionCreators(
      updateNextScheduleInterviewModalStatus,
      dispatch
    ),
    updateInterviewData: bindActionCreators(updateInterviewData, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateSnapshotBasicInfo);
