import {
  Skeleton,
  TableRow,
  TableCell,
  IconButton,
  TableContainer
} from '@mui/material';
import {
  Email,
  Email as EmailIcon,
  GetApp as GetAppIcon,
  WhatsApp as WhatsAppIcon
} from '@mui/icons-material';
import TableBody from '@mui/material/TableBody';
import { styled as styling } from '@mui/material/styles';
import Colors from '../../../styles/colors.module.scss';
import { over } from 'lodash';

export const TableContainerStyled = styling(TableContainer)({
  width: '1240px',
  padding: '0 20px',
});

export const TableBodyStyled = styling(TableBody)({
  borderLeft: '1px solid rgba(224, 224, 224, 1)',
  borderRight: '1px solid rgba(224, 224, 224, 1)',
});

export const TableRowStyled = styling(TableRow)({});

export const TableCellWrapper = styling('div')({
  position: 'relative',
});

export const NameTextWrapper = styling('div')({
  width: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',

  '&:hover': {
    color: '#222',
    textDecoration: 'underline',
    textUnderlineOffset: '4px',
  },
  
});

export const TableCellStyled = styling(TableCell)(({ type }) => ({
  fontSize: '14px',
  padding: '18px 20px',
  color: '#222222',
  fontWeight: 400,
  fontFamily: 'Inter',
  letterSpacing: '0.15px',
  cursor: 'default',
  ...(type == 'name' && {
    padding: '20px 20px 15px',
    width: '55% !important',
  }),
  ...(type == 'applied' && {
    padding: '20px 20px 15px',
    width: '15% !important',
    minWidth: '220px',
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 700,
    },
  }),
  ...(type == 'job-applied' && {
    padding: '20px 20px 15px',
    width: '15% !important',
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 700,
    },
  }),
  ...(type == 'status' && {
    padding: '20px 20px 15px',
    width: '15% !important',
  }),
  ...(type == 'resume' && {
    padding: '20px 20px 15px',
    width: '15% !important',
  }),
  ...(type == 'name_text' && {
    fontWeight: 700,
    width: '50% !important',
    maxWidth: '480px',
    cursor: 'pointer',
  }),
  ...(type == 'job_applied_text' && {
    fontWeight: 700,
    width: '50% !important',
    maxWidth: '480px',
    cursor: 'pointer',
    fontSize: '14px',
    letterSpacing: '0.1px',
  }),
  ...(type == 'applied_text' && {
    fontSize: '12px',
    fontWeight: 400,
    width: '16% !important',
    cursor: 'pointer',
  }),
  ...(type == 'status_text' && {
    width: '14% !important',
    minWidth: '120px',
  }),
  ...(type == 'resume_text' && {
    width: '15% !important',
  }),
}));

export const UnviewedIndicator = styling('div')({
  height: '10px',
  width: '10px',
  position: 'absolute',
  borderRadius: '5px',
  top: '-8px',
  left: '-14px',
  background: Colors.priPurple,
});

export const SkeletonStyled = styling(Skeleton)({
  height: '34px',
});

export const IconWrapper = styling('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

export const IconButtonStyled = styling(IconButton)(({ 
  marginRight, 
  disabled 
}) => ({
  padding: 0,
  ...(marginRight && {
    marginRight: '5px',
  }),
  ...(disabled && {
    cursor: 'default',
  }),
}));

export const EmailIconStyled = styling(EmailIcon)({
  fontSize: '20px',
  color: '#222',
});

export const GetAppIconStyled = styling(GetAppIcon)(({ 
  disabled 
}) => ({
  fontSize: '20px',
  color: '#222',

  ...(disabled && {
    color: 'rgba(0, 0, 0, 0.38)',
  }),
}));

export const WhatsAppIconStyled = styling(WhatsAppIcon)(({ 
  disabled 
}) => ({
  fontSize: '20px',
  color: '#222',

  ...(disabled && {
    color: 'rgba(0, 0, 0, 0.38)',
  }),
}));

export const Text = styling('span')({
  fontSize: '12px',
  fontFamily: 'Inter',
});
