import React from 'react';
import { Button as MUIButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Colors from '../../styles/colors.module.scss';

const useStyles = makeStyles({
  button: {
    background: Colors.priPurple,
    color: Colors.priWhite,
    borderRadius: '25px !important',
    border: 0,
    minHeight: 40,
    width: 150,
    padding: 5,
    fontFamily: 'InterBold',
    fontSize: 16,
    textTransform: 'none'
  },
  label: {
    padding: '0 16px'
  },
  labelNoWrap: {
    whiteSpace: 'nowrap'
  },
  purpleButton: {
    color: Colors.priWhite,
    background: Colors.priPurple,
    '&:hover': {
      backgroundColor: Colors.priPurple
    }
  },
  whiteBlackButton: {
    color: Colors.priBlack,
    border: 'solid 2px',
    borderColor: Colors.priBlack,
    background: Colors.priWhite,
    '&:hover': {
      backgroundColor: Colors.priWhite
    }
  },
  blackButton: {
    color: Colors.priWhite,
    background: Colors.priBlack,
    '&:hover': {
      backgroundColor: Colors.priBlack
    }
  },
  whiteButton: {
    color: Colors.priBlack,
    background: Colors.priWhite,
    '&:hover': {
      backgroundColor: Colors.priWhite
    }
  },
  purpleTextButton: {
    color: Colors.priPurple,
    background: Colors.priWhite,
    '&:hover': {
      backgroundColor: Colors.priWhite
    }
  },
  transparentButton: {
    color: Colors.priBlack,
    border: 'solid 2px',
    borderColor: Colors.priBlack,
    background: 'transparent',
    '&:hover': {
      // backgroundColor: 'transparent'
      background: '#512acc',
      border: '2px solid #512acc',
      color: '#fff'
    }
  },
  shadowOnHoverButton: {
    color: Colors.priPurple,
    background: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: '4px 6px 40px rgb(181 181 181 / 35%)'
    }
  },
  outlineWhiteButton: {
    color: Colors.priWhite,
    background: 'transparent',
    border: 'solid 2px',
    borderColor: Colors.priWhite,
    '&:hover': {
      background: Colors.priWhite,
      color: Colors.priPurple
    }
  },
  disabledButton: {
    borderColor: Colors.terMediumGrey
  },
  bigButton: {
    width: 200
  },
  smallButton: {
    width: 100
  },
  tinyButton: {
    width: 80
  },
  autoSize: {
    width: 'auto'
  },
  defaultFontSize: {
    fontSize: '16px'
  },
  tinyFontSize: {
    fontSize: '12px'
  }
});

const Button = ({
  color,
  size,
  withRipple,
  onClick,
  children,
  className,
  type,
  labelNoWrap,
  disabled,
  id,
  ...props
}) => {
  const classes = useStyles();
  let _onSubmit = null;

  let buttonColor = null;
  let buttonSize = null;
  let fontSize = classes.defaultFontSize;
  let labelTextWrap = null;

  switch (size) {
    default:
    case 'BIG':
      buttonSize = classes.bigButton;
      break;
    case 'SMALL':
      buttonSize = classes.smallButton;
      break;
    case 'TINY':
      buttonSize = classes.tinyButton;
      fontSize = classes.tinyFontSize;
      break;
    case 'AUTO':
      buttonSize = classes.autoSize;
      break;
  }

  switch (color) {
    default:
    case 'PURPLE':
      buttonColor = classes.purpleButton;
      break;
    case 'BLACK':
      buttonColor = classes.blackButton;
      break;
    case 'WHITE':
      buttonColor = classes.whiteButton;
      break;
    case 'TRANSPARENT':
      buttonColor = classes.transparentButton;
      break;
    case 'PURPLETEXT':
      buttonColor = classes.purpleTextButton;
      break;
    case 'SHADOWONHOVER':
      buttonColor = classes.shadowOnHoverButton;
      break;
    case 'WHITEBLACK':
      buttonColor = classes.whiteBlackButton;
    case 'OUTLINEWHITE':
      buttonColor = classes.outlineWhiteButton;
  }
  switch (labelNoWrap) {
    default:
    case false:
      break;
    case true:
      labelTextWrap = classes.labelNoWrap;
      break;
  }

  return (
    <MUIButton
      id={id}
      variant="contained"
      disableRipple={!withRipple}
      className={classNames(
        classes.button,
        buttonColor,
        buttonSize,
        fontSize,
        disabled ? classes.disabledButton : null,
        labelTextWrap,
        className
      )}
      onClick={type === 'submit' ? _onSubmit : onClick}
      classes={{ label: classes.label }}
      disabled={disabled}
      {...props}>
      {children}
    </MUIButton>
  );
};

Button.propTypes = {
  color: PropTypes.oneOf([
    'PURPLE',
    'BLACK',
    'WHITE',
    'TRANSPARENT',
    'PURPLETEXT',
    'SHADOWONHOVER',
    'OUTLINEWHITE',
    'WHITEBLACK'
  ]),
  size: PropTypes.oneOf(['BIG', 'SMALL', 'AUTO']),
  onClick: PropTypes.func,
  children: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  withRipple: PropTypes.bool,
  labelNoWrap: PropTypes.bool,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  color: 'PURPLE',
  size: 'SMALL',
  onClick: () => {},
  children: '',
  className: '',
  type: null,
  withRipple: false,
  labelNoWrap: false,
  disabled: false
};

export default Button;
