
import { styled as styling } from '@mui/material/styles';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import { Switch } from '@mui/material';


export const DialogTitleStyled = styling(DialogTitle)(props => ({
    padding: '16px 16px 16px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.1px',

  }));
  
  export const DialogContentStyled = styling(DialogContent)(props => ({
    padding: '16px 24px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    maxWidth: '464px',
  }));

export const DialogNoteStyled = styling(DialogContent)(props => ({
    padding: '0px',
    color: 'rgba(0, 0, 0, 0.60)',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
  }));
  
  export const DialogActionsStyled = styling(DialogTitle)(props => ({
    padding: '0px',
  }));
  
  export const DialogButton = styling('div')(props => ({
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    width: '100%',
  }));

  export const ButtonContinueEdit = styling(Button)(props => ({
    width: '200px',
    height: '48px',
    backgroundColor: '#FFF',
    borderRadius: '8px',
    border: '2px solid #222',
    color: '#222',
    fontSize: '16px',
    textTransform: 'none',
    fontWeight: '700',
    padding: '0 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
  
    ':hover': {
      backgroundColor: '#FFF'
    },
  
    [props.theme.breakpoints.down('sm')]: {
      width: '130px',
      height: '30px',
      fontSize: '12px',
      padding: '0 8px'
    }
  }));
  
  export const ButtonSkipEdit = styling(Button)(props => ({
    width: '200px',
    height: '48px',
    backgroundColor: '#2E2E2E',
    borderRadius: '8px',
    color: '#FFF',
    fontSize: '16px',
    textTransform: 'none',
    fontWeight: '700',
    padding: '0 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
  
    ':hover': {
      backgroundColor: '#2E2E2E'
    },
  
    [props.theme.breakpoints.down('sm')]: {
      width: '130px',
      height: '30px',
      fontSize: '12px',
      padding: '0 10px'
    }
  }));


  export const IconButtonStyled = styling(IconButton)(props => ({
    padding: '0',
}));


export const KeywordTokenToggle = styling('div')(props => ({
    background: '#222',
    color: '#FFF',
    padding: '8px 16px',
    fontSize: '12px',
    fontWeight: 400,
    // whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '464px'
  }));

  export const SwitchStyled = styling(Switch)(props => ({
    width: '52px',
    height: '24px',
    padding: 0,
    borderRadius: 30,
    '& .MuiSwitch-switchBase': {
      padding: 1,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(27px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          // When is checked
          opacity: 1,
          border: 0,
          backgroundColor: '#FFF',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          backgroundColor: '#D8D8D8'
        }
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
        backgroundColor: '#222',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: '#33cf4d',
        backgroundColor: '#FFF',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: '#D8D8D8'
      }
    },
    '& .Mui-checked .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 18,
      height: 18,
      backgroundColor: '#222',
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 18,
      height: 18,
      backgroundColor: '#FFF',
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      opacity: 1,
      backgroundColor: '#D3D3D3',
      '&:before': {
        content: '"Yes"',
        display: 'flex',
        opacity: props.checked ? 1 : 0,
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '16px',
        transform: 'translate(8px, 4px)'
      },
      '&:after': {
        content: '"No"',
        display: 'flex',
        opacity: props.checked ? 0 : 1,
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '16px',
        transform: 'translate(30px, -12px)'
      }
    }
  }));