import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DefaultLayout from '../layouts/DefaultLayout';
import { Grid } from '@mui/material';
import { Spinner } from '../components';
import {
  fetchAccountInfo,
  fetchMyCompanyStatus,
  updateTermsPopupStatus
} from '../redux/actions/company_actions';
import { isEmpty } from 'lodash';
import { parseJwt } from '../utils/parseJWT';

function PrivateRoute({ children, protectedRoute, ...props }) {
  const location = useLocation();
  const pathname = location.pathname;
  const dispatch = useDispatch();
  const accData = useSelector(state => state.companies.accountInfo);
  const companyStatus = useSelector(state => state.companies.myCompanyStatus);

  const accessToken = localStorage.getItem('accessToken');
  const agreeToTerms = parseJwt(accessToken)?.agree_to_terms;
  const originCountry = parseJwt(accessToken)?.origin_country;
  const notLoadingPath = !pathname.includes('login') || pathname === '/';
  localStorage.setItem('originCountry', originCountry);

  useEffect(() => {
    if (!accData && notLoadingPath) {
      dispatch(fetchAccountInfo());
    }

    if (!companyStatus) {
      dispatch(fetchMyCompanyStatus());
    }

    setTimeout(() => {
      if (!agreeToTerms && accData && accessToken) {
        dispatch(updateTermsPopupStatus(true));
      }
    }, 1200);
  }, [pathname]);

  if (
    (!accData.permissions && isEmpty(accData)) ||
    Object.keys(companyStatus)?.length <= 0
  ) {
    return (
      <DefaultLayout>
        <Grid
          container
          sx={{
            width: '100vw',
            height: `calc(100vh - 200px)`,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Spinner />
        </Grid>
      </DefaultLayout>
    );
  }

  const isAuthenticated = localStorage.getItem('accessToken');
  const getRequiredCompanyStatus = () => {
    return protectedRoute?.find(route => route?.path === location?.pathname)
      ?.myCompanyStatus;
  };

  const hasRequiredCompanyStatus = required => {
    if (!required || !companyStatus) return false;
    return required.every(key => companyStatus[key] === true);
  };

  const getRequiredPermissions = () => {
    return protectedRoute?.find(route => route?.path === location?.pathname)
      ?.permissions;
  };

  const hasRequiredPermissions = required => {
    if (!required || !accData?.permissions) return false;
    return required.some(key => accData.permissions[key] === true);
  };

  const hasUnlimitedTokenPermissions = () => {
    return protectedRoute?.find(route => route?.path === location?.pathname)
      ?.unlimitedToken;
  };

  const hasUnlimitedTokens = () => {
    return companyStatus['unlimitedToken'];
  };

  const checkAccess = () => {
    const requiredCompanyStatus = getRequiredCompanyStatus();
    const requiredPermissions = getRequiredPermissions();

    if (hasUnlimitedTokenPermissions() && hasUnlimitedTokens()) {
      return true;
    }

    // Check if both requiredCompanyStatus and requiredPermissions are present
    if (requiredCompanyStatus && requiredPermissions) {
      const validStatus = hasRequiredCompanyStatus(requiredCompanyStatus);
      const validPermissions = hasRequiredPermissions(requiredPermissions);
      return validStatus && validPermissions;
    }

    // If only requiredCompanyStatus is present, check it
    if (requiredCompanyStatus) {
      return hasRequiredCompanyStatus(requiredCompanyStatus);
    }

    // If only requiredPermissions is present, check it
    if (requiredPermissions) {
      return hasRequiredPermissions(requiredPermissions);
    }

    // If neither is required, return true by default
    return true;
  };

  if (isAuthenticated) {
    if (checkAccess()) {
      return children;
    } else {
      return <Navigate to="/not-found" />;
    }
  } else {
    return <Navigate to="/login" />;
  }
}

export default PrivateRoute;
