import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, MenuItem } from '@mui/material';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import { FilterTitle, Row } from './styles';
import SharedSelect from '../SharedSelect';
import { HeightSpacer } from '../../../../components/Spacer/styles';
import { languages } from '../../../../utils/Constants';
import {
  updateCollapsibleFilterStatus,
  updateSearchTalentFilters
} from '../../../../redux/actions/talent_action';
import { useDispatch, useSelector } from 'react-redux';

let selectedLanguages = [];

const Language = () => {
  const dispatch = useDispatch();

  const filters = useSelector(state => state.talents.searchTalentFilters);
  const expandFilters = useSelector(state => state.talents.expandFilters);

  const onChooseLanguages = selectedLanguage => {
    let cFilters = { ...filters };

    let index = selectedLanguages.findIndex(item => {
      return item == selectedLanguage;
    });

    if (index > -1) {
      selectedLanguages.splice(index, 1);
    } else {
      selectedLanguages.push(selectedLanguage);
    }

    cFilters['languages'] = selectedLanguages;

    dispatch(updateSearchTalentFilters(cFilters));
  };

  const onClickFilter = () => {
    let cFilter = { ...expandFilters };

    cFilter['language'] = !cFilter['language'];

    dispatch(updateCollapsibleFilterStatus(cFilter));
  };

  useEffect(() => {
    if (filters['languages']?.length <= 0) {
      selectedLanguages = [];
    }
  }, [filters]);

  return (
    <div
      style={{
        padding: '0',
        display: 'flex',
        flexDirection: 'column',
        width: '95%',
        gap: '8px'
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        onClick={onClickFilter}>
        <FilterTitle>Language</FilterTitle>

        {expandFilters['language'] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </div>

      {expandFilters['language'] && (
        <>
          <SharedSelect
            placeholder={'Select Language'}
            width={'300px'}
            height={'300px'}
            multiple={true}
            value={
              filters['languages']?.length > 0
                ? filters['languages']
                : ['Select Language']
            }>
            {languages.map((item, index) => {
              return (
                <LanguageSelection
                  item={item}
                  index={index}
                  key={`${item}-${index}`}
                  checked={filters['languages']?.indexOf(item) > -1}
                  onClick={() => onChooseLanguages(item)}
                />
              );
            })}
          </SharedSelect>
        </>
      )}
    </div>
  );
};

export default Language;

export const LanguageSelection = ({ item, index, checked, onClick }) => {
  return (
    <Row key={`${item}-${index}`}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onClick={onClick}
            sx={{
              '&.Mui-checked': {
                color: '#222'
              },
              padding: '0 9px'
            }}
          />
        }
        label={item}
        sx={{
          '& .MuiTypography-root': {
            fontSize: 14,
            letterSpacing: '0.15px'
          },
          '& .MuiSvgIcon-root': {
            fontSize: 20
          }
        }}
      />
    </Row>
  );
};
