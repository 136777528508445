import { Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { VimeoVideo } from '../VimeoVideo';
import StatusBanner from '../StatusBanner';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIconEnd,
  FontAwesomeIconStart,
  StackContainer,
  VideoCardContainer,
  VideoContentContainer,
  VideoNumberTag,
  VideoProcessingContainer
} from './styles';

export default function VideoCard(props) {
  const {
    vimeoId,
    personName,
    personPosition,
    quote,
    processing,
    id,
    activeBanner
  } = props?.content;

  const videoQuote = useMemo(() => {
    const quoteContent = quote;
    const spaces = '\u00A0'.repeat(7);

    return `${spaces}${quoteContent}${spaces}`;
  }, [quote]);

  return (
    <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      {activeBanner && (
        <StatusBanner
          content={props?.content}
          index={props?.index}
          id={id}
          handleChangeBanner={props?.handleChangeBanner}
        />
      )}

      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          height: '315px'
        }}>
        <VideoCardContainer>
          <VideoNumberTag>{props?.index}</VideoNumberTag>

          {processing ? (
            <VideoProcessingContainer>
              <Typography
                sx={{
                  color: `rgba(0, 0, 0, 0.87)`,
                  fontSize: '14px'
                }}>
                Processing Video...
              </Typography>
              <Typography
                sx={{
                  color: `rgba(0, 0, 0, 0.60)`,
                  fontSize: '12px'
                }}>
                This may take about 5 minutes. Thanks for your patience!
              </Typography>
            </VideoProcessingContainer>
          ) : (
            <VimeoVideo videoId={vimeoId} extra={true} />
          )}
        </VideoCardContainer>

        <VideoContentContainer>
          <StackContainer>
            <FontAwesomeIconStart icon={faQuoteLeft} />
            <Typography sx={{ letterSpacing: '0.15px', lineHeight: '24px' }}>
              {videoQuote}
            </Typography>
            <FontAwesomeIconEnd icon={faQuoteRight} />
          </StackContainer>
          <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              sx={{
                fontWeight: 700,
                letterSpacing: '0.15px',
                lineHeight: '24px'
              }}>
              {personName}
            </Typography>
            <Typography sx={{ letterSpacing: '0.15px', lineHeight: '24px' }}>
              {personPosition}
            </Typography>
          </Grid>
        </VideoContentContainer>
      </Grid>
    </Grid>
  );
}
