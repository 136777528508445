import { Grid } from '@mui/material';
import React, { useState } from 'react';
import IconButton from '../components/IconButton';
import {
  ContainerStyled,
  CoverDesc,
  CoverTitle,
  EmptySocialMediaGrid,
  PlusIconStyled,
  SocialMediaGrid,
  SocialMediaWrapper,
  TitleWrapper
} from './styles';
import { OutlineInformationIcon, PencilIconStyled } from '../CoverImage/styles';
import { SocialMediaCard } from './components/SocialMediaCard';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { convertStatusText } from '../helper/convertStatusText';
import sortByStatus from '../helper/sortByStatus';

export default function ViewState(props) {
  const { setView, setInfoOpen } = props;
  const companyProfile = useSelector(state => state.companies.companyProfile);
  const socialMedias = companyProfile?.socialMediaLinks?.map(socialMedia => {
    return {
      ...socialMedia,
      add: false,
      loading: false,
      content:
        socialMedia?.content?.map(item => ({
          status: convertStatusText(item.approvalStatus),
          url: item.socialMediaLink
        })) || []
    };
  });

  const emptySlot =
    4 - socialMedias?.filter(video => isEmpty(video?.content?.length))?.length;
  function generateList(length) {
    if (length === 0) {
      return [];
    }

    return [
      { add: true },
      ...Array.from({ length: length - 1 }, () => ({ add: false }))
    ];
  }

  const list = generateList(emptySlot);

  return (
    <ContainerStyled>
      <TitleWrapper>
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <CoverTitle>Social Media Highlights</CoverTitle>
          <CoverDesc>
            Embed up to 4 social media videos to share your brand story with
            jobseekers.
          </CoverDesc>
        </Grid>

        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            alignItems: 'center'
          }}>
          <IconButton
            onClick={() => setInfoOpen(true)}
            tooltipTitle="More Information">
            <OutlineInformationIcon />
          </IconButton>

          <IconButton
            onClick={() => {
              setView(false);
            }}
            tooltipTitle="Edit">
            <PencilIconStyled />
          </IconButton>
        </Grid>
      </TitleWrapper>

      <SocialMediaWrapper>
        {sortByStatus({ array: socialMedias })?.map((video, index) => (
          <SocialMediaCard
            video={video}
            viewMode={true}
            setView={() => {
              setView(false);
            }}
          />
        ))}
        {socialMedias?.length < 4 &&
          list?.map(list => {
            return <EmptyCard add={list?.add} setView={setView} />;
          })}
      </SocialMediaWrapper>
    </ContainerStyled>
  );
}

const EmptyCard = props => {
  const { add, setView } = props;

  return add ? (
    <SocialMediaGrid onClick={() => setView(false)}>
      <PlusIconStyled />
    </SocialMediaGrid>
  ) : (
    <EmptySocialMediaGrid viewMode />
  );
};
