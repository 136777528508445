import { styled as styling } from '@mui/material/styles';
import { Grid, Typography, TextField, Button } from '@mui/material';
import {
  ChevronUpIcon,
  ChevronDownIcon,
  XMarkIcon,
  TrashIcon,
  PencilSquareIcon
} from '@heroicons/react/24/outline';

export const ChevronUpIconStyled = styling(ChevronUpIcon)(props => ({
  color: props?.disabled ? `#00000038` : '#000000DE',
  height: '20px',
  width: '20px'
}));

export const ChevronDownIconStyled = styling(ChevronDownIcon)(props => ({
  color: props?.disabled ? `#00000038` : '#000000DE',
  height: '20px',
  width: '20px'
}));

export const TrashIconStyled = styling(TrashIcon)(props => ({
  color: props?.disabled ? '#00000061' : '#000000DE',
  height: '20px',
  width: '20px'
}));

export const PencilSquareIconStyled = styling(PencilSquareIcon)(props => ({
  color: props?.disabled ? '#00000061' : '#000000DE',
  height: '20px',
  width: '20px'
}));

export const BrowseFileText = styling('span')(props => ({
  color: '#512ACC',
  cursor: 'pointer',

  ':hover': {
    textDecoration: 'underline'
  }
}));

export const TextFieldStyled = styling(TextField)(
  ({ theme, small, value, select, bold, white, error }) => ({
    width: '100%',
    '& .MuiInputBase-root': {
      fontSize: '0.85rem',
      color:
        value === 'placeholder' && select
          ? '#d8d8d8'
          : white
          ? 'white'
          : 'black', // Text color
      fontWeight: bold && 'bold',
      ...(small && {
        height: '45px',
        '& .MuiOutlinedInput-input': {
          '&::placeholder': {
            color: '#d8d8d8 !important',
            opacity: 1
          },
          paddingTop: '10px',
          paddingBottom: '5px',
          paddingLeft: '14px',
          paddingRight: '14px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
          top: 0
        }
      })
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        border: error ? `1px solid #F75443 !important` : `1px solid #D8D8D8`
      },
      '&:hover fieldset': {
        border: '1px solid #AEAEAE'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #512ACC'
      }
    },
    ...(small && {
      height: '45px'
    })
  })
);

export const OuterContainer = styling(Grid)(props => ({
  boxShadow: `0px 1px 4px 0px rgba(0, 0, 0, 0.25)`,
  borderRadius: '8px',
  border: '1px solid #D8D8D8',
  overflow: 'hidden'
}));

export const RearrangeContainer = styling(Grid)(props => ({
  padding: '8px 8px 8px 16px',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
  backgroundColor: '#EFEFEF',
  height: '44px'
}));

export const RearrangeText = styling(Typography)(props => ({
  width: '16px',
  color: `rgba(0, 0, 0, 0.87)`,
  fontSize: '16px',
  fontWeight: 700
}));

export const ExpandContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '12px',
  height: '71px',
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor:
    props?.moreThanOne && props?.status === 'Live on Hiredly'
      ? '#F8F8F8 !important'
      : 'white'
}));

export const InnerContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  boxShadow: `0px 1px 8px 0px rgba(0, 0, 0, 0.16) inset`,
  height: props?.expand ? '100%' : '0px',
  transition: 'height 0.3s ease-in-out',
  maxHeight: props?.expand ? '1000px' : '0px',
  height: props?.expand ? 'auto' : '0px',
  transitionProperty: 'max-height, height',
  transitionDuration: '0.3s'
}));

export const InnerVideoContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '38px 12px 12px 12px',
  width: '100%'
}));

export const EmptyVideoContainer = styling(Grid)(props => ({
  backgroundColor: '#EFEFEF',
  height: '271px',
  width: '100%',
  borderRadius: '8px',
  border: '1px solid #AEAEAE',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const ProcessingContainer = styling(Grid)(props => ({
  backgroundColor: '#EFEFEF',
  height: '271px',
  width: '100%',
  borderRadius: '8px',
  border: '1px solid #AEAEAE',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
}));

export const VideoContainer = styling(Grid)(props => ({
  width: '483px',
  // height: '271px',
  border: '1px solid #AEAEAE',
  borderRadius: '8px',
  overflow: 'hidden',
  backgroundColor: '#353535'
}));

export const EditThumbnailContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white !important',
  gap: '8px',
  height: '36px',
  transition: 'all 0.3s',
  zIndex: 10,

  ':hover': {
    backgroundColor: props?.editable && '#EFEFEF !important'
  }
}));

export const BrowseContainer = styling(Grid)(props => ({
  height: '24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  alignItems: 'flex-start',
  zIndex: 100,
  width: '100%'
}));

export const BrowseButton = styling(Button)(props => ({
  backgroundColor: '#353535',
  height: '24px !important',
  width: '120px',
  wordWrap: 'none',
  padding: '0px 24px !important',
  borderRadius: '8px',
  color: 'white',
  fontSize: '12px',
  textTransform: 'none',

  ':hover': {
    backgroundColor: '#353535',
    color: 'white'
  },

  ':disabled': {
    backgroundColor: '#D8D8D8',
    color: `rgba(0, 0, 0, 0.38)`
  }
}));

export const ContentOuterContainer = styling(Grid)(props => ({
  padding: '12px 12px 16px 12px',
  width: '381px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  borderLeft: '1px solid #D8D8D8'
}));

export const ContentGapContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px'
}));

export const ContentTextCountContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '4px'
}));

export const SeqeunceContainer = styling(Grid)(props => ({
  height: '72px',
  gap: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const SeqeunceRevertButton = styling(Button)(props => ({
  textTransform: 'none',
  padding: '0px 24px',
  height: '40px',
  color: `rgba(0,0,0,87)`,

  ":hover": {
    backgroundColor: "transparent",
    textDecoration: "underline"
  }
}));

export const AccordianOuterContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  alignItems: 'center'
}));

export const AccordianContainer = styling(Grid)(props => ({
  width: '81px',
  height: '47px',
  outline: '1px solid #D8D8D8',
  borderRadius: '4px',
  overflow: 'hidden',
  pointerEvents: 'none'
}));

export const AccordianExpandContainer = styling(Grid)(props => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  color: `rgba(0, 0, 0, 0.60)`,
  fontSize: '12px !important',
  alignItems: 'center'
}));

export const AccordianFiller = styling(Grid)(props => ({
  width: '100%',
  height: '100%',
  backgroundColor: '#F5F5F5'
}));

export const AccordianTabText = styling(Typography)(props => ({
  fontSize: '12px',
  textDecoration: 'underline',
  cursor: 'pointer'
}));

export const AccordianTabTimeText = styling(Typography)(props => ({
  fontSize: '12px',
  fontStyle: 'italic',
  color: `rgba(0, 0, 0, 0.38)`
}));
