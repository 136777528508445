import { styled as styling } from '@mui/material/styles';
import {
  Close as CloseIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon
} from '@mui/icons-material'
import { Chip, Grid } from '@mui/material';

export const TextFieldStyled = styling('input')({
    borderRadius: '8px',
    padding: '13px 20px',
    border: `1px solid rgb(216, 216, 216)`,
    width: '100%',

    '&::placeholder':{
      color: 'rgba(0, 0, 0, 0.38)',
      fontSize: '0.85rem',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },

    '&:focus': {
      outline: `1px solid #512ACC`,
      // outlineColor: `#512ACC`,
    }
  });

  export const OptionStyled = styling('div')(({ 
    $highlight 
  }) => ({
    padding: '12px 24px',
  
    ...($highlight ? { background: 'rgb(239, 239, 253)' } : {}),
    '&:hover': {
      cursor: 'pointer',
      background: 'rgb(239, 239, 253)',
    },
  
  }));

  export const OptionsContainerStyled = styling('div')({
    zIndex: 2,
    background: '#FFF',
    position: 'absolute',
    top: '120%',
    width: '100%',
    borderRadius: '10px',
    overflow: 'auto',
    maxHeight: '200px',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
  });

  export const ChipStyled = styling(Chip)(({ 
  }) => ({
    borderRadius: '20px',
    padding: '8px 16px',
    background: 'rgb(239, 239, 253)',
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px',
    fontSize: '14px',
    color: '#222',

    '& span':{
        padding: '0px',
        marginRight: '5px'
    },

    '& svg':{
        color: '#222 !important',
        fontSize: '1em !important',
        margin: '0 !important',
    },

    '&.MuiChip-deleteIcon':{
        color: '#222 !important',
        fontSize: '1em !important'
    }
  }));
  
  export const CloseIconStyled = styling(CloseIcon)({
    fontSize: '14px',
    color: '#222',
    '&:hover': {
      cursor: 'pointer',
    },
  });

  export const SkillFormContainer = styling(Grid)((props) => ({
    backgroundColor: 'white',
    padding: '24px',
    minHeight: '200px',
    border: props.isError? '1px solid #BE4242' : '1px solid rgb(216, 216, 216)',
    borderRadius: '8px'
  }));

  export const TextContainer = styling('div')((props) => ({
    ...(props.$container == 'title-container' && {
      display: 'flex',
      alignItems: 'center',
    }),
    ...(props.$container == 'description-container' && {
      display: 'flex',
      justifyContent: 'space-between',
    }),
   }));

   export const Text = styling('span')((props) => ({ 
    ...(props.$type == 'skills-title' && {
      fontSize: '20px',
      fontWeight: '700',
      fontFamily: 'Inter',
    }),
    ...(props.$type == 'skills-description' && {
      fontSize: '12px',
      fontFamily: 'Inter',
    }),
    ...(props.$type == 'skills-length' && {
      fontSize: '13px',
      fontFamily: 'Inter',
    }),
    ...(props.$type == 'custom-tooltip' && {
      fontSize: '12px',
      fontFamily: 'Inter',
      margin: '0',
    }),
  }));

  export const Description = styling('span')(() => ({
    color: '#222',
    opacity: 0.6,
    fontSize: '14px',
  }));

  export const HeightSpacer = styling('div')(({ 
    height 
  }) => ({
    height: height ? height : '5px',
  }));
  
  export const Spacer = styling('div')(({ 
    width 
  }) => ({
    width: width ? width : '5px',
  }));
  