import React, { useEffect, useState } from 'react';
import { useFormContext } from '../context';
import usePathKey from '../helper/usePathKey';
import {
  mutateUpdateJob,
  mutateCreateJob,
  updateJobDataForm,
  updateSelectedFilter,
  updateSelectedFilterValue
} from '../../../../redux/actions/job_action';
import { useDispatch, useSelector } from 'react-redux';
import { SuccessToast } from '../../../../utils/ToastUtils';
import { useNavigate } from 'react-router-dom';
import { checkError } from '../helper/checkError';
import { formatToDefaultFormData } from '../helper/formatData';
import * as yup from 'yup';
import { validationSchema } from '../helper/validation';

export default function useSeniorLogic(props) {
  const dispatch = useDispatch();
  const buttonText = 'Post Job';
  const history = useNavigate();
  const [openTokenPopUp, setOpenTokenPopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const jobDataForm = useSelector(state => state?.jobs?.jobDataForm);

  if (props?.confirm) {
    const onSaveJobConfirm = async () => {
      setLoading(true);

      const formattedData = {
        ...jobDataForm,
        resourceType: 'free_super_senior',
        jobType: {
          id: '2',
          title: 'Full-time'
        },
        maxYearExperience: 20,
        minYearExperience: 10
      };

      // Edge cases
      // 1. if user save the job as draft, then post the job as senior job
      //    and need to remove the draft id
      // 2. if user edit an senior job, always include the id

      const draftJobId = formattedData?.id?.length < 6;
      const data = formatToDefaultFormData(formattedData, draftJobId);

      try {
        if (data?.id) {
          const res = await dispatch(mutateUpdateJob({ input: data }));

          if (res?.type === 'MUTATE_UPDATE_JOB_SUCCEED') {
            setLoading(false);

            if (jobDataForm?.id) {
              history(`/jobs`);
            }

            SuccessToast(`Job updated!`);
            dispatch(updateSelectedFilterValue('active'));
            dispatch(updateSelectedFilter(true));
            dispatch(updateJobDataForm());
          } else {
            ErrorToast(res?.error);
          }
        } else {
          const res = await dispatch(mutateCreateJob({ input: data }));

          if (res?.type === 'MUTATE_CREATE_JOB_SUCCEED') {
            setLoading(false);

            if (jobDataForm?.id) {
              history(`/jobs`);
            } else {
              history(`/jobs/${res?.createJob?.id}/job-visibility`);
            }
            SuccessToast(`Job ${jobDataForm?.id ? 'updated' : 'created'}!`);
            dispatch(updateSelectedFilterValue('active'));
            dispatch(updateSelectedFilter(true));
          } else {
            ErrorToast(res?.error);
          }
        }
      } catch (error) {
        ErrorToast('An error occurred while creating the job.');
      } finally {
        setLoading(false);
      }
    };

    return { onSaveJobConfirm, loading };
  }

  const requiredFields = [
    'location',
    'stateRegion',
    'title',
    'description',
    'requirements',
    'careerLevel',
    'skills',
    // 'jobType',
    'tracks'
    // 'minSalaryRange',
    // 'maxSalaryRange'
  ];

  const { formState, handleErrorState } = useFormContext();

  const saveJob = async () => {
    try {
      await validationSchema.validate(formState, { abortEarly: false });
      const error = checkError(formState, requiredFields);

      if (error?.length > 0) {
        handleErrorState(error);
        return;
      }

      dispatch(updateJobDataForm(formState));
      setOpenTokenPopUp(true);
    } catch (err) {
      let errors;
      err.inner.forEach(error => {
        errors = error.message;
      });

      ErrorToast(errors);
    }
  };

  return { buttonText, saveJob, openTokenPopUp, setOpenTokenPopUp };
}
