export async function useSetVimeoThumbnail(videoId, second) {
  const accessToken = process.env.REACT_APP_VIMEO_TOKEN

  const url = `https://api.vimeo.com/videos/${videoId}/pictures`;
  const options = {
    method: 'POST',
    body: JSON.stringify({
      active: true,
      type: 'custom',
      time: second
    }),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      Accept: 'application/vnd.vimeo.*+json;version=3.4'
    }
  };

  try {
    const response = await fetch(url, options);
    const data = await response.json();

    if (response.ok) {
      return data;
    } else {
      ErrorToast(data?.error);
      return data.error;
    }
  } catch (error) {
    console.error('Error:', error);
  }
}
