import { Grid, Typography } from '@mui/material';
import { styled as styling } from '@mui/material/styles';

export const GridStyled = styling(Grid)(props => ({
  //
}));

export const DialogTitleStyled = styling(Typography)(props => ({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '24px',
  letterSpacing: '0.1px'
}));

export const BulletPoint = styling('li')(props => ({
  fontSize: '12px',
  margin: 0,
  fontFamily: 'Inter',
  lineHeight: '20px'
}));

export const ClearSelectionText = styling(Typography)(props => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: '700',
  color: props?.chooseVoucher && props?.apply ? 'white' : 'grey'
}));

export const VoucherGrid = styling(Grid)(props => ({
  cursor: 'pointer',
  backgroundColor: props.selected ? '#F5F5F5' : 'white',
  border: props.selected ? '1px solid #512acc' : '1px solid #D8D8D8',
  '&:hover': {
    border: props.selected ? `1px solid #512acc` : '1px solid #707070',

    '&.MuiGrid-root .MuiFormGroup-root .MuiButtonBase-root span .MuiSvgIcon-root':
      {
        color: props.selected ? '#512acc' : '#707070',
        transform: 'scale(1)'
      }
  }
}));
