import { styled as styling } from '@mui/material/styles';
import { Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const MainWrapper = styling(Grid)(props => ({
  // height: props.short ? '72px' : '120px',
  width: '100%',
  marginBottom: '20px',
}));

export const PurpleContainer = styling(Grid)({
  width: '0.5%',
  backgroundColor: '#213DB5',
  borderRadius: '8px 0px 0px 8px'
});

export const CenterContainer = styling(Grid)({
  padding: '10px 16px 10px 16px',
  backgroundColor: `rgba(33, 61, 181, 0.1)`,
  width: '99.5%',
  borderRadius: '0px 8px 8px 0px'
});

export const BulletPoints = styling('ul')({
  position: 'relative',
  left: '-15px',
  top: '-15px',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '1.5',
  marginBottom: '0px'
});

export const BulletPointsList = styling('li')({});

export const IconButtonStyled = styling(IconButton)({});

export const CloseIconStyled = styling(CloseIcon)({
  fontSize: '18px'
});

export const DecoyTypography = styling(Typography)({
  opacity: 0
});

export const TypographyStyled = styling(Typography)({
  fontSize: '14px',
  fontWeight: 'bold'
});

export const TypographyNormalStyled = styling(Typography)({
  fontSize: '14px',
  fontWeight: '400'
});

export const EmptyContainer = styling(Grid)({});
