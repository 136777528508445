import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  updateCurrentOnboardingPage,
  updateCurrentBg,
  updateDirectRegister
} from '../../../../redux/actions/company_actions';

import {
  AnimatedContainer,
  ContentContainer,
  HeightSpacer,
  ImageContainer,
  Title,
  SecondContainer,
  BackButton,
  NextPageButton,
  FooterContainer,
  KeyboardBackspaceIconStyled,
  PageContainer,
  Container2,
  SecAnimatedContainer,

  Message,
  MessageBrand,
  MessageBrandBox,
  MessageBrandTitle,
  Checklist,
  ChecklistLine,
  IconBox,
  CheckIconStyled,
  ChecklistText,
  MessageGrey
} from './styles';

export default function Company(props) {
  const dispatch = useDispatch();

  const [out, setOut] = useState(false);
  const [slide, setSlide] = useState(false);

  const [imageLoaded, setImageLoaded] = useState(false);

  const [randomImages, setRandomImages] = useState({
    image1: null,
    image2: null,
    image3: null
  });

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handlePreviousPage = () => {
    dispatch(updateCurrentBg('1'));
    dispatch(updateCurrentOnboardingPage('branding'));
  };

  const navigateToNextPage = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    dispatch(updateCurrentBg('3'));
    setOut(true);
    setSlide(true);
    dispatch(updateDirectRegister('0'));

    setTimeout(() => {
      dispatch(updateCurrentOnboardingPage('register'));
    }, 1500);
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('randomData')) || {};
    setRandomImages(storedData.images);
  }, []);

  return (
    <PageContainer>
      <AnimatedContainer>
        <ContentContainer slide={slide}>
          {randomImages && (
            <ImageContainer>
              <img
                alt="logo"
                src={randomImages.image1}
                onLoad={handleImageLoad}
                style={{
                  opacity: imageLoaded ? 1 : 0,
                  transition: 'opacity 0.5s',
                  width: '100%'
                }}
              />

              <img
                alt="logo"
                src={randomImages.image2}
                onLoad={handleImageLoad}
                style={{
                  opacity: imageLoaded ? 1 : 0,
                  transition: 'opacity 0.5s',
                  width: '100%'
                }}
              />
              <img
                alt="logo"
                src={randomImages.image3}
                onLoad={handleImageLoad}
                style={{
                  opacity: imageLoaded ? 1 : 0,
                  transition: 'opacity 0.5s',
                  width: '100%'
                }}
              />
            </ImageContainer>
          )}
        </ContentContainer>

        <HeightSpacer $height={'10px'} />
      </AnimatedContainer>

      <Container2>
        <SecAnimatedContainer>
          <SecondContainer>
            <MessageBrand out={out}>
              <MessageBrandBox>
                <MessageBrandTitle>
                  As part of our exclusive launch, let us help you tell your
                  employer brand story with a dedicated production crew
                </MessageBrandTitle>

                <Checklist>
                  <ChecklistLine>
                    <IconBox>
                      <CheckIconStyled />
                    </IconBox>
                    
                    <ChecklistText>
                      A personalised company video shoot and interview for you
                      to showcase your culture
                    </ChecklistText>
                  </ChecklistLine>

                  <ChecklistLine>
                    <IconBox>
                      <CheckIconStyled />
                    </IconBox>
                    <ChecklistText>
                      10 edited company photos
                    </ChecklistText>
                  </ChecklistLine>
                </Checklist>
              </MessageBrandBox>

              <MessageBrandBox>
                <MessageBrandTitle>
                  With a complimentary and personalised Company Canvas, you will
                  then unlock -
                </MessageBrandTitle>

                <Checklist>
                  <ChecklistLine>
                    <IconBox>
                      <CheckIconStyled />
                    </IconBox>
                    
                    <ChecklistText>
                    Exclusive early access to talent in Hiredly Singapore
                    </ChecklistText>
                  </ChecklistLine>

                  <ChecklistLine>
                    <IconBox>
                      <CheckIconStyled />
                    </IconBox>
                    <ChecklistText>
                      Access to &gt;1 million jobseekers from Hiredly Malaysia
                    </ChecklistText>
                  </ChecklistLine>

                  <ChecklistLine>
                    <IconBox>
                      <CheckIconStyled />
                    </IconBox>
                    <ChecklistText>
                    Unlimited job advertisements
                    </ChecklistText>
                  </ChecklistLine>

                  <ChecklistLine>
                    <IconBox>
                      <CheckIconStyled />
                    </IconBox>
                    <ChecklistText>
                    5,000 Talent Search tokens to directly access our growing pool of jobseeker profiles and resumes from Hiredly Malaysia
                    </ChecklistText>
                  </ChecklistLine>
                </Checklist>
              </MessageBrandBox>
              <Message>
                Stand a chance to be one of the first employers to receive all
                these exclusive benefits{' '}
                <b style={{ color: '#512ACC' }}>FOR FREE</b> - simply book your
                spot by clicking Join Now.
              </Message>

              <MessageGrey>
                We’ll let you know if you’re chosen!
              </MessageGrey>
            </MessageBrand>

            <FooterContainer out={out}>
              <BackButton onClick={handlePreviousPage}>
                <KeyboardBackspaceIconStyled />
              </BackButton>

              <NextPageButton onClick={navigateToNextPage}>
                Join now
              </NextPageButton>
            </FooterContainer>
          </SecondContainer>
        </SecAnimatedContainer>
      </Container2>
    </PageContainer>
  );
}
