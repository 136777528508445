import React from 'react';
import s from './index.module.scss';

export default function Card({ className, ...props }) {
  return (
    <div className={[s.card, className].join(' ')} {...props}>
      {props.children}
    </div>
  );
}
