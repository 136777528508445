import { InformationCircleIcon } from '@heroicons/react/24/solid';
import React from 'react';
import SharedAIPoweredCard from '../../../../components/SharedAIPoweredCard';
import { SimpleTipsTooltip } from '../../../../components/Tooltips';
import { Content } from './styles';
import Colors from '../../../../styles/colors.module.scss';
import { convertHexAndEntitiesToString } from '../../../../helpers/data_management';

const CandidateApplicationSummary = ({ candidate }) => {
  const processGptSummary = summary => {
    let list = summary
      .split(/[\n]/) // split based on break space
      .filter(item => {
        return item.length > 0; // remove empty line
      });

    return list;
  };

  const content = (candidate, processGptSummary) => {
    return (
      <ul style={{ margin: '0', padding: '0 22px' }}>
        {processGptSummary(candidate.gptSummary).map(item => {
          return (
            <Content>
              {convertHexAndEntitiesToString(item).replace('-', '')}
            </Content>
          );
        })}
      </ul>
    );
  };

  return (
    <SharedAIPoweredCard
      title={'AI-Powered Job Application Summary'}
      content={content(candidate, processGptSummary)}
      notice={
        'The full resume below should always be referred to for complete & accurate information of the candidate’s experience.'
      }
      origin={'ats'}
      tooltip={
        <SimpleTipsTooltip
          top={'-70px'}
          left={'-180px'}
          width={'208px'}
          padding={'4px 8px'}
          position={'absolute'}
          fontSize={'10px'}
          borderRadius={'8px'}
          border={`1.5px solid ${Colors.priBlack}`}
          icon={
            <InformationCircleIcon style={{ width: '16px', height: '16px' }} />
          }
          wrapperZIndex={'999'}
          shadow={'none'}
          description={
            <span>
              This feature analyses the candidates' resume and suggests why they
              would be a good match for the job!
            </span>
          }
        />
      }
    />
  );
};

export default CandidateApplicationSummary;
