import React, { useEffect, useState } from 'react';
import { Chip, Grid, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddEditAccessLevelPopUp from '../../PopUps/AddEditAccessLevelPopUp';
import { ErrorToast, SuccessToast } from '../../../../../utils/ToastUtils';
import styles from './index.module.scss';
import {
  fetchAccessLevels,
  mutateCompanyTeamMember
} from '../../../../../redux/actions/company_actions';
import { useDispatch, useSelector } from 'react-redux';

const AccessLevel = ({ index, tableData, setTableData }) => {
  const { accessLevels, name, id } = tableData[index];
  const [isAccessLevelPopUpOpen, setIsAccessLevelPopUpOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAccessLevels());
  }, []);

  const accessLevelList = useSelector(state => state.companies.accessLevels);
  const mutatingCompanyTeamMember = useSelector(
    state => state.companies.mutatingCompanyTeamMember
  );
  const mutatedCompanyTeamMember = useSelector(
    state => state.companies.mutatedCompanyTeamMember
  );

  const handleDelete = chipToDelete => async () => {
    const tmpArray = [...tableData];
    const removedAccessLevelArray = [...tableData[index]?.accessLevels].filter(
      item => item?.id !== chipToDelete?.id
    );

    tmpArray[index] = {
      ...tmpArray[index],
      accessLevels: removedAccessLevelArray
    };

    dispatch(
      mutateCompanyTeamMember({
        input: {
          id: mutatedCompanyTeamMember.id,
          accessLevels: removedAccessLevelArray
        }
      })
    )
      .then(res => {
        setTableData(tmpArray);

        SuccessToast(`Removed '${chipToDelete?.title}' access for ${name}`);
      })
      .catch(err => {
        ErrorToast(err.message);
      });
  };

  return (
    <div>
      <AddEditAccessLevelPopUp
        open={isAccessLevelPopUpOpen}
        handleClose={() => setIsAccessLevelPopUpOpen(false)}
        id={id}
        accessLvlData={accessLevels}
        index={index}
        tableData={tableData}
        setTableData={setTableData}
      />

      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          flexWrap: 'wrap'
        }}>
        {accessLevels &&
          accessLevels.map(item => (
            <Chip
              key={item?.id}
              label={item?.title}
              variant="outlined"
              onDelete={handleDelete(item)}
              disabled={mutatingCompanyTeamMember}
            />
          ))}
        {accessLevels?.length < accessLevelList?.length && (
          <IconButton
            style={{ padding: 3, color: 'black' }}
            onClick={() => setIsAccessLevelPopUpOpen(true)}>
            <div className={styles.addIconWrapper}>
              <AddCircleIcon />
            </div>
          </IconButton>
        )}
      </Grid>
    </div>
  );
};
export default AccessLevel;
