import React, { Fragment, useEffect } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import { FilterTitle } from './styles';
import { highestEduLevel } from '../../../../utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCollapsibleFilterStatus,
  updateSearchTalentFilters
} from '../../../../redux/actions/talent_action';

let selectedLevels = [];

const HighestEducationLevel = () => {
  const dispatch = useDispatch();

  const filters = useSelector(state => state.talents.searchTalentFilters);
  const expandFilters = useSelector(state => state.talents.expandFilters);

  const onChooseEducation = selectedEducation => {
    let cFilters = { ...filters };

    let index = selectedLevels.findIndex(item => {
      return item == selectedEducation.title;
    });

    if (index > -1) {
      selectedLevels.splice(index, 1);
    } else {
      selectedLevels.push(selectedEducation.title);
    }

    cFilters['educationLevels'] = selectedLevels;

    dispatch(updateSearchTalentFilters(cFilters));
  };

  const onClickFilter = () => {
    let cFilter = { ...expandFilters };

    cFilter['highestEducationLevel'] = !cFilter['highestEducationLevel'];

    dispatch(updateCollapsibleFilterStatus(cFilter));
  };

  useEffect(() => {
    if (filters['educationLevels']?.length <= 0) {
      selectedLevels = [];
    }
  }, [filters]);

  return (
    <div
      style={{
        padding: '0',
        display: 'flex',
        flexDirection: 'column',
        width: '95%',
        gap: '8px'
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        onClick={onClickFilter}>
        <FilterTitle>Highest Education Level</FilterTitle>

        {expandFilters['highestEducationLevel'] ? (
          <ExpandLessIcon />
        ) : (
          <ExpandMoreIcon />
        )}
      </div>

      {expandFilters['highestEducationLevel'] && (
        <FormGroup style={{ margin: '0px 10px 0' }}>
          {highestEduLevel.map((item, index) => {
            return (
              <Fragment key={`${item.title}-${index}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        filters['educationLevels']?.indexOf(item.title) > -1
                      }
                      onClick={() => onChooseEducation(item)}
                      sx={{
                        '&.Mui-checked': {
                          color: '#222'
                        },
                        padding: '0 9px'
                      }}
                    />
                  }
                  label={item.title}
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: 14,
                      letterSpacing: '0.15px'
                    },
                    '& .MuiSvgIcon-root': {
                      fontSize: 20
                    },
                    alignItems: 'flex-start',
                    marginBottom: '4px'
                  }}
                />
              </Fragment>
            );
          })}
        </FormGroup>
      )}
    </div>
  );
};

export default HighestEducationLevel;
