import {
  Checkbox,
  FormControlLabel,
  TextField,
  IconButton,
  Chip
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  ArrowBackIos as ArrowBackIosIcon
} from '@mui/icons-material';
import {
  CategoryPill,
  CategoryPillTitle,
  DropdownTrackTitle,
  MajorCategoriesContainer,
  MaximumReachedContainer,
  MaximumReachedIcon,
  MaximumReachedText,
  SpecialisationContainer,
  SpecialisationList,
  SpecialisationPopover,
  SubCategoriesContainer,
  SubCategoriesItem,
  SubCategoriesItemsContainer,
  SubCategoriesItemText,
  SubCategoriesTitle,
  SubCategoriesTitleContainer
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateSpecialisationPopoverStatus,
  updateSpecialisationSubcategories
} from '../../redux/actions/job_action';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  XMarkIcon
} from '@heroicons/react/24/solid';
import { Spacer } from '../Spacer/styles';
import { useFormContext } from '../../pages/Jobs/PostJob/context';

let highlight = false;

const FormSpecialisation = ({ key, getSpecializationData, isError }) => {
  const dispatch = useDispatch();
  const container = useRef();
  const textFieldRef = useRef();
  const { handleSingleFieldChange, errorState, handleErrorState } =
    useFormContext();

  const [indexOfMajorCategory, setIndexOfMajorCategory] = useState(-1);
  const [subCategoryTitle, setSubCategoryTitle] = useState('');
  const [searchText, setSearchText] = useState('');
  const [subcategoryOnSearch, setSubcategoryOnSearch] = useState([]);

  const subcategories = useSelector(state => state.jobs.subcategories);

  const specialisations = getSpecializationData;
  const isSpecialisationOpen = useSelector(
    state => state.jobs.isSpecialisationOpen
  );

  const onClickMainSpecialisation = (e, index) => {
    highlight = true;
    setIndexOfMajorCategory(index);

    setSubCategoryTitle(specialisations[index].title);
  };

  const goBackMainSpecialisation = () => {
    highlight = true;
    setIndexOfMajorCategory(-1);
  };

  const getSubCategories = index => {
    if (specialisations) {
      return specialisations[index].subCategories;
    }
  };

  const onUpdateSearchText = (e, specialisations) => {
    setSearchText(e.target.value);

    dispatch(updateSpecialisationPopoverStatus(true));

    if (e.target.value.length <= 0) {
      setSubcategoryOnSearch([]);
      setIndexOfMajorCategory(-1);
      setSubCategoryTitle('');
    } else {
      let filteredSubCategories = specialisations.map(item => {
        return item?.subCategories.filter(subCategory => {
          return subCategory.title
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        });
      });

      setSubcategoryOnSearch(filteredSubCategories);
    }
  };

  const onCheckSubcategories = item => {
    // if return -1, means not yet selected.
    let index =
      subcategories?.findIndex(sub => {
        return sub.id == item.id;
      }) ?? -1;

    let cSubcategories = Array.isArray(subcategories) ? [...subcategories] : [];

    if (index > -1) {
      // selected
      cSubcategories.splice(index, 1);
      dispatch(updateSpecialisationSubcategories(cSubcategories));
    } else {
      // not selected
      cSubcategories.push({
        id: item.id,
        title: item.title
      });

      dispatch(updateSpecialisationSubcategories([...cSubcategories]));
    }

    handleSingleFieldChange('tracks', cSubcategories);
    setSearchText('');
    setSubcategoryOnSearch([]);
    handleErrorState(errorState.filter(error => error !== 'tracks'));
  };

  const onClickTextField = () => {
    handleErrorState;
    if (!isSpecialisationOpen) {
      dispatch(updateSpecialisationPopoverStatus(true));
    }
  };

  const onInputRef = input => {
    if (input && highlight) {
      input.focus();
    }
  };

  const onDeleteChip = item => {
    let cSubcategories = [...subcategories];

    let index = cSubcategories.indexOf(item);

    cSubcategories.splice(index, 1);

    dispatch(updateSpecialisationSubcategories(cSubcategories));

    handleSingleFieldChange('tracks', cSubcategories);
    // handleErrorState(errorState.filter(error => error !== key));
  };

  const getSubCategoriesCount = majorCategory => {
    let count = 0;
    if (subcategories?.length > 0) {
      subcategories.map(subcat => {
        majorCategory.subCategories.map(mSubs => {
          if (subcat.id == mSubs.id) {
            count = count + 1;
          }
        });
      });
    }

    return count;
  };

  const [openTab, setOpenTab] = useState(false);
  useEffect(() => {
    const handler = event => {
      // check if text input is in focus. If no, specialisation dropdown will be closed
      if (!container?.current?.contains(event?.target)) {
        if (!highlight) {
          setIndexOfMajorCategory(-1);

          dispatch(updateSpecialisationPopoverStatus(false));

          // if (isSpecialisationOpen) {
          //   dispatch(updateSpecialisationPopoverStatus(false));
          // }
        } else {
          highlight = false;
        }
      }
    };
    document.addEventListener('click', handler);
    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  return (
    <SpecialisationContainer ref={container}>
      <TextField
        ref={textFieldRef}
        fullWidth
        placeholder={
          Array.isArray(subcategories) && subcategories.length > 0
            ? ''
            : 'Select up to 3 specialisations'
        }
        onChange={e => onUpdateSearchText(e, specialisations)}
        onClick={onClickTextField}
        value={searchText}
        inputRef={onInputRef}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: isError ? '1px solid #BE4242' : '1px solid #D8D8D8',
              borderRadius: '6px',
            },
            '&:hover fieldset': {
              border: isError ? '1px solid #BE4242' : `1px solid black`
            },
            '&.Mui-focused fieldset': {
              border: '2px solid #D8D8D8'
            }
          }
        }}
        InputProps={{
          style: {
            borderRadius: '6px',
            fontSize: '16px',
            fontFamily: 'Inter',
            fontWeight: '600',
            color: '#000',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            padding: subcategories?.length >= 1 ? '10px' : '10px'
          },
          startAdornment: subcategories?.map((item, index) => {
            return (
              <CategoryPill key={`${item.majorCategoryName}-${index}`}>
                <CategoryPillTitle variant="body1">
                  {item.title}
                </CategoryPillTitle>
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => onDeleteChip(item)}>
                  <XMarkIcon width={'14px'} height={'14px'} />
                </IconButton>
              </CategoryPill>
            );
          }),
          endAdornment: isSpecialisationOpen ? (
            <ChevronUpIcon
              onClick={() => dispatch(updateSpecialisationPopoverStatus(false))}
              width={'16px'}
              height={'16px'}
              color="#512ACC"
              style={{
                position: 'absolute',
                right: '10px',
                top: 'calc(50%- 0.5rem)'
              }}
            />
          ) : (
            <ChevronDownIcon
              onClick={() => {
                dispatch(updateSpecialisationPopoverStatus(true));
                setOpenTab(true);
              }}
              width={'16px'}
              height={'16px'}
              color="#707070"
              style={{
                pointerEvents: "none",
                position: 'absolute',
                right: '10px',
                top: 'calc(50%- 0.5rem)'
              }}
            />
          )
        }}
        InputLabelProps={{
          style: {}
        }}
        inputProps={{
          style: {
            fontSize: '16px',
            fontFamily: 'Inter',
            color: '#707070',
            fontWeight: '400',
            lineHeight: '24px',
            letterSpacing: '0.15px',
            padding: 0,
            margin: 0,
            //minWidth: isSpecialisationOpen ? '50px' : '0px',
            flex: 1
          }
        }}
      />
      {isSpecialisationOpen && (
        <SpecialisationPopover
          $scroll={setSubcategoryOnSearch.length > 0}
          $height={textFieldRef.clientHeight}>
          {subcategoryOnSearch.length > 0 ? (
            subcategoryOnSearch.map((item, index) => {
              return (
                <FilteredResult
                  specialisations={specialisations}
                  filteredSubcategories={item}
                  index={index}
                  subcategories={subcategories}
                  onCheckSubcategories={onCheckSubcategories}
                />
              );
            })
          ) : indexOfMajorCategory == -1 ? (
            <MajorCategoriesContainer>
              {specialisations?.map((item, index) => {
                return (
                  <SpecialisationList
                    onClick={e => onClickMainSpecialisation(e, index)}
                    key={`${item.title}-${index}`}>
                    <DropdownTrackTitle variant="body1">
                      {item?.title}
                    </DropdownTrackTitle>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '6px'
                      }}>
                      {getSubCategoriesCount(item) > 0 && (
                        <span
                          style={{
                            fontSize: '14px',
                            fontFamily: 'Inter',
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                            width: '24px',
                            height: '24px',
                            border: '1px solid #D8D8D8',
                            borderRadius: '2px',
                            background: '#F5F5F5',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                          {getSubCategoriesCount(item)}
                        </span>
                      )}
                      <ChevronRightIcon
                        width={'14px'}
                        height={'14px'}
                        color="rgba(0,0,0,0.6)"
                      />
                    </div>
                  </SpecialisationList>
                );
              })}
            </MajorCategoriesContainer>
          ) : (
            <SubCategoriesList
              goBackMainSpecialisation={goBackMainSpecialisation}
              subCategoryTitle={subCategoryTitle}
              getSubCategories={getSubCategories}
              indexOfMajorCategory={indexOfMajorCategory}
              onCheckSubcategories={onCheckSubcategories}
              subcategories={subcategories}
            />
          )}
          {Array.isArray(subcategories) && subcategories?.length >= 3 ? (
            <MaximumReachedContainer>
              <MaximumReachedIcon
                color="#B34242"
                width={'40px'}
                height={'40px'}
              />
              <MaximumReachedText variant="body1">
                Select maximum of 3 Job Specialisations only
              </MaximumReachedText>
            </MaximumReachedContainer>
          ) : null}
        </SpecialisationPopover>
      )}
    </SpecialisationContainer>
  );
};

export default FormSpecialisation;

export const FilteredResult = ({
  specialisations,
  filteredSubcategories,
  index,
  subcategories,
  onCheckSubcategories
}) => {
  const getCheckboxStatus = item => {
    let testIndex = subcategories?.findIndex(sub => {
      return sub.id == item.id;
    });

    if(!testIndex){
      return null
    }

    return subcategories[testIndex]?.id == item.id;
  };

  return (
    <>
      {filteredSubcategories.length > 0 && (
        <SubCategoriesContainer>
          <SubCategoriesTitleContainer>
            <SubCategoriesTitle>
              {specialisations[index].title}
            </SubCategoriesTitle>
          </SubCategoriesTitleContainer>

          <SubCategoriesItemsContainer>
            {filteredSubcategories.map((item, index) => {
              return (
                <SubCategoriesItem key={`${item.title}-${index}`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          '&.Mui-checked': {
                            color: '#222'
                          }
                        }}
                        checked={getCheckboxStatus(item)}
                        disabled={
                          !getCheckboxStatus(item) && subcategories?.length >= 3
                        }
                        onChange={() => onCheckSubcategories(item)}
                      />
                    }
                    label={
                      <SubCategoriesItemText>
                        {item.title}
                      </SubCategoriesItemText>
                    }
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: 14,
                        letterSpacing: '0.15px'
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: 20
                      }
                    }}
                  />
                </SubCategoriesItem>
              );
            })}
          </SubCategoriesItemsContainer>
        </SubCategoriesContainer>
      )}
    </>
  );
};

export const SubCategoriesList = ({
  goBackMainSpecialisation,
  subCategoryTitle,
  getSubCategories,
  indexOfMajorCategory,
  onCheckSubcategories,
  subcategories
}) => {
  const getCheckboxStatus = item => {
    let testIndex = subcategories?.findIndex(sub => {
      return sub.id == item.id;
    });

    return Array.isArray(subcategories) && subcategories.length > testIndex
      ? subcategories[testIndex]?.id == item?.id
      : false;
  };

  return (
    <SubCategoriesContainer>
      <SubCategoriesTitleContainer>
        <IconButton onClick={goBackMainSpecialisation}>
          <ArrowBackIosIcon style={{ fontSize: '14px', cursor: 'pointer' }} />
        </IconButton>

        <Spacer width={'10px'} />
        <SubCategoriesTitle>{subCategoryTitle}</SubCategoriesTitle>
      </SubCategoriesTitleContainer>

      <SubCategoriesItemsContainer>
        {getSubCategories(indexOfMajorCategory)?.map((item, index) => {
          return (
            <SubCategoriesItem key={`${item.title}-${index}`}>
              <FormControlLabel
                style={{
                  alignItems: 'flex-start'
                }}
                control={
                  <Checkbox
                    checked={getCheckboxStatus(item)}
                    disabled={
                      !getCheckboxStatus(item) && subcategories?.length >= 3
                    }
                    onChange={() => onCheckSubcategories(item)}
                    sx={{
                      paddingTop: '3px',

                      '&.Mui-checked': {
                        color: '#222'
                      }
                    }}
                  />
                }
                label={
                  <SubCategoriesItemText>{item.title}</SubCategoriesItemText>
                }
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: 14,
                    letterSpacing: '0.15px'
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: 20
                  }
                }}
              />
            </SubCategoriesItem>
          );
        })}
      </SubCategoriesItemsContainer>
    </SubCategoriesContainer>
  );
};
