import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../styles/colors.module.scss';

export const Wrapper = styling('div')((props) => ({
  display: 'flex',
  background: Colors.priWhite,
  padding: '22px 35px',
  borderRadius: '20px',
  justifyContent: 'space-between',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
}));

export const Text = styling('span')((props) => ({
  fontFamily: 'Inter',
  fontWeight: 600,
  ...(props.chip_text && {
    fontSize: '14px',
    color: Colors.terDarkGrey,
  }),
  ...(props.chip_count && {
    fontSize: '14px',
    color: Colors.terDarkGrey,
  }),
  ...(props.show_chip_color && {
    color: Colors.priWhite,
  }),
}));

export const Spacer = styling('div')((props) => ({
  width: props.width ? props.width : '5px',
}));

export const ChipContainer = styling('div')((props) => ({
  borderRadius: '20px',
  padding: '15px 25px',
  display: 'flex',
  alignItems: 'center',
  ...(props.show_chip_color && {
    background: Colors.priPurple,
  }),
  '&:hover': {
    cursor: 'pointer',
  },
}));

export const ContentContainer = styling('div')((props) => ({
  display: 'flex',
}));
