import React from 'react';
import { InputAdornment } from '@mui/material';
import {
  Search as SearchIcon,
  ArrowDropDown as ArrowDropDownIcon
} from '@mui/icons-material';
import { TextFieldStyled } from './styles';

export const TextInputFunction = props => {
  const { label, value, handleChange, type, placeholder, origin, onClick } =
    props;
  return (
    <>
      <TextFieldStyled
        hiddenLabel
        disabled={false}
        id={`fill-${label}`}
        onChange={handleChange}
        value={value}
        type={type}
        variant="filled"
        InputProps={{
          placeholder: `${
            placeholder !== undefined ? placeholder : 'Search by keyword'
          }`,
          disableUnderline: true,
          style: {
            borderRadius: '10px',
            background: `#fff`,
            margin: '10px 0',
            border: '1px solid #bdbdbd',
            height: '50px',
            paddingLeft: '20px',
            fontSize: '14px',
            fontFamily: 'Inter',
            letterSpacing: '1px'
          },
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
              {origin == 'chat_middle_section' && (
                <ArrowDropDownIcon
                  fontSize="small"
                  style={{ marginLeft: '5px', cursor: 'pointer' }}
                  onClick={onClick}
                />
              )}
            </InputAdornment>
          )
        }}
      />
    </>
  );
};
