import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { Switch } from '@mui/material';
import { Checkbox } from '@mui/material';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

export const TextFieldStyled = styled(TextField)(
    ({ theme, small, value, select, bold, white, name }) => ({
      width: "100%",
      // Adjust the padding of the input field to change its height
      '& .MuiInputBase-root': {
      
        fontSize: '0.85rem',
        color:
          value === 'placeholder' && select
            ? '#d8d8d8'
            : white
            ? 'white'
            : 'black', // Text color
        fontWeight: bold && 'bold',
        ...(small && {
          height: '45px',
          '& .MuiOutlinedInput-input': {
            '&::placeholder': {
              color: '#d8d8d8 !important', // Change to desired placeholder color
              opacity: 1 // Ensure the placeholder is fully visible
            },
            paddingTop: '10px', // Reduced top padding
            paddingBottom: '5px', // Increased bottom padding to move text down
            paddingLeft: '14px', // Maintain side paddings
            paddingRight: '14px'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            top: 0
          }
        })
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: '8px',
        //   borderColor: white ? 'white' : '#d8d8d8' // Border color
          border: '1px solid #D8D8D8'
        },
        '&:hover fieldset': {
        //   borderColor: white ? 'white' : '#d8d8d8' // Border color on hover
          border: '1px solid #D8D8D8'

        },
        '&.Mui-focused fieldset': {
        //   borderColor: white ? 'white' : '#512ACC' // Border color on focus
          border: '1px solid #512ACC'

        }
      },
      ...(small && {
        height: '45px' // Adjust the height of the entire component if small is true
      })
    })
  );

  export const MainContainer = styled('div')(props => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: props.isExpanded ? '16px' : '0px',
    transition: 'gap 0.3s ease',
  }));

  export const TopSection = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch'
  }));

  export const TopSectionFirstLine = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    alignSelf: 'stretch',
    height: '31px'
  }));

  export const Title = styled('div')(() => ({
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24px'
  }));

  export const NewTag = styled('div')(() => ({
    display: 'inline-flex',
    height: '21px',
    padding: '0px 10px',
    alignItems: 'center',
    position: 'absolute',
    right: '0px',
    top: '-10px',
    borderRadius: '4px',

    color: '#213DB5',
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: 700,
    lineHeight: '147.023%',
    letterSpacing: '1px',
  }));

  export const ExpandableBarSwitch = styled('div')(props => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px'
  }));

  export const SwitchStyled = styled(Switch)(props => ({
    width: 46,
    height: 24,
    padding: 0,
    borderRadius: 30,
    '& .MuiSwitch-switchBase': {
      padding: 1,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(21px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          // When is checked
          opacity: 1,
          border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          backgroundColor: 'rgba(81, 42, 204, 0.40);'
        }
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff'
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: '#FFF'
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: '#D3D3D3'
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 18,
      height: 18
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      opacity: 1,
      backgroundColor: '#D3D3D3',
      '&:before': {
        display: 'flex',
        opacity: props.checked ? 1 : 0,
        color: '#ffffff',
        fontWeight: 'bold',
        transform: 'translate(8px ,4px)'
      },
      '&:after': {
        display: 'flex',
        transform: 'translate(30px, -12px)',
        color: '#ffffff',
        fontWeight: 'bold',
        opacity: props.checked ? 0 : 1
      }
    }
  }));
 

  export const ChevronDownIconStyled = styled(ChevronDownIcon)(props => ({
    width: '20px',
    height: '20px',
    transition: 'transform 0.3s ease', 
    transform: props.isExpanded ? 'rotate(180deg)' : 'none' 
  }));

  export const SubTitle = styled('div')(props => ({
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16.8px'
  }));

  export const BottomSection = styled('div')(props => ({
    display: 'flex',
    width: '100%',
    padding: '24px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '32px',
    borderRadius: '8px',
    border: props.isError ? '1px solid #BE4242' : '1px solid #D8D8D8',
    maxWidth: '640px'
  }));


  export const ArrangementSection = styled('div')(props => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
    borderRadius: '8px',

  }));

  export const RightsSection = styled('div')(props => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
  
  }));

  export const ArrangementTitle = styled('div')(props => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '2px',
  }));

  export const ArrangementText = styled('div')(props => ({
    color: props.row == '1' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.60)',
    fontSize: props.row == '1' ? '14px' : '12px',
    fontWeight: props.row == '1' ? 700 : 400,
    lineHeight: props.row == '1' ? '24px' : '20px',
    width: '377px',
    height: '23px',
    letterSpacing: '0.1px'
  }));

  export const CheckboxSection = styled('div')(props => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
  }));

  export const CheckboxText = styled('div')(props => ({
    color: props.type == 'desc' ? 'rgba(0, 0, 0, 0.60)' : 'rgba(0, 0, 0, 0.87)',
    fontSize: props.type == 'desc' ? '12px' : '14px',
    fontWeight: props.type == 'desc' ? '400' : props.type == 'title2' ? '400' :  '500',
    lineHeight: props.type == 'desc' ? '20px' : '24px',
    letterSpacing: props.type == 'desc' ? 'unset' :'0.15px'
  }));


  export const CheckboxStyled = styled(Checkbox)(props => ({
    padding: '0px',
    margin: '4px 0px',
    color: 'transparent',
    overflow: 'hidden',
    '&.MuiCheckbox-root': {
      borderRadius: '4px',
      border: `1px solid ${props?.isError ? '#F75443' : '#000'}`,
      width: 16,
      height: 16
    },
    '& .MuiSvgIcon-root': {
      fontSize: '18px'
    },
  
    '&.Mui-checked': {
      color: 'black',
      width: 16,
      height: 16
    },

    '&.MuiCheckbox-indeterminate': {
      color: 'black',
      width: 16,
      height: 16
    },

    '&.MuiCheckbox-root:hover': {
      backgroundColor: 'transparent'
    },
    '& .MuiSvgIcon-root': {
      borderRadius: '4px'
    }
  }));

  export const BottomAnimation = styled('div')(props => ({
    maxHeight: props.isExpanded ? '1000px' : '0',
    overflow: 'hidden',
    transition: 'max-height 0.8s ease',
    maxWidth: '640px'
  }));
