import { Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from './../../index.module.scss';
import { useFormContext } from '../../context';
import {
  MainContainer,
  TextFieldStyled,
  ExpandableBarSwitch,
  NewTag,
  Title,
  TopSectionFirstLine,
  TopSection,
  SwitchStyled,
  ChevronDownIconStyled,
  SubTitle,
  BottomSection,
  ArrangementSection,
  RightsSection,
  ArrangementTitle,
  ArrangementText,
  CheckboxSection,
  CheckboxText,
  CheckboxStyled,
  BottomAnimation
} from './styles';
import { useSelector } from 'react-redux';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export default function GlobalHire() {
  // const key = 'customEmail';
  const key = 'globalHire';
  const originCountry = localStorage.getItem('originCountry');

  const countryName =
    originCountry == 'MY'
      ? 'Malaysia'
      : originCountry == 'SG'
      ? 'Singapore'
      : 'the country';

  const {
    formState,
    handleFieldChange,
    handleSingleFieldChange,
    handleErrorState,
    errorState
  } = useFormContext();

  const [isExpanded, setIsExpanded] = useState(
    formState?.globalHirePreferences?.enabled
  );
  const [isCrossedBorder, setIsCrossedBorder] = useState(
    formState?.crossedBorder
  );

  useEffect(() => {
    setIsExpanded(formState?.globalHirePreferences?.enabled);
  }, [formState?.globalHirePreferences?.enabled]);

  useEffect(() => {
    setIsCrossedBorder(formState?.crossedBorder);
  }, [formState?.crossedBorder]);

  const renderCheckBox = (name, value, title, description, dataName) => (
    <div style={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}>
      <CheckboxStyled
        name={name}
        color="default"
        inputProps={{
          'aria-label': 'checkbox with default color'
        }}
        checked={value}
        dataName={dataName}
        onChange={e => {
          handleSingleFieldChange('globalHirePreferences', {
            ...formState.globalHirePreferences,
            [name]: e.target.checked
          });
          handleErrorState(errorState.filter(error => error !== key));
        }}
      />
      <div>
        <CheckboxText type={'title'}>{title}</CheckboxText>

        <CheckboxText type={'desc'}>{description}</CheckboxText>
      </div>
    </div>
  );

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFF',
      background: '#FFF',
      maxWidth: 350,
      border: '1.5px solid #222',
      borderRadius: '8px',
      display: 'inline-flex',
      // height: '27px',
      // padding: '4px 8px',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }));

  return (
    <div>
      <MainContainer isExpanded={isExpanded}>
        <TopSection>
          <TopSectionFirstLine>
            <div
              style={{ width: '145px', position: 'relative' }}>
              <Title>Global Hire</Title>

              <NewTag>NEW</NewTag>
            </div>

            {isCrossedBorder ? (
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <p
                      style={{
                        color: '#222',
                        textAlign: 'center',
                        fontSize: '10px',
                        fontWeight: '500',
                        lineHeight: '14px',
                        margin: '0'
                      }}>
                      Cross Border Tokens require Global Hire to be enabled.
                    </p>
                  </React.Fragment>
                }
                placement="top">
                <ExpandableBarSwitch>
                  <SwitchStyled
                    name="active"
                    checked={formState?.globalHirePreferences?.enabled}
                    disabled={isCrossedBorder}
                    onChange={e => {
                      handleSingleFieldChange('globalHirePreferences', {
                        ...formState.globalHirePreferences,
                        enabled: e.target.checked
                      });
                      setIsExpanded(prevExpanded => !prevExpanded);
                      handleErrorState(
                        errorState.filter(error => error !== key)
                      );
                    }}
                    $width={46}
                    $height={24}
                    $thumbWidth={18}
                    $thumbHeight={18}
                  />
                  {/* <ChevronDownIconStyled onClick={() => setIsExpanded(prevFields => !prevFields)} isExpanded={isExpanded}/> */}
                </ExpandableBarSwitch>
              </HtmlTooltip>
            ) : (
              <ExpandableBarSwitch>
                <SwitchStyled
                  name="active"
                  checked={formState?.globalHirePreferences?.enabled}
                  disabled={isCrossedBorder}
                  onChange={e => {
                    handleSingleFieldChange('globalHirePreferences', {
                      ...formState.globalHirePreferences,
                      enabled: e.target.checked
                    });
                    setIsExpanded(prevExpanded => !prevExpanded);
                    handleErrorState(errorState.filter(error => error !== key));
                  }}
                  $width={46}
                  $height={24}
                  $thumbWidth={18}
                  $thumbHeight={18}
                />
                {/* <ChevronDownIconStyled onClick={() => setIsExpanded(prevFields => !prevFields)} isExpanded={isExpanded}/> */}
              </ExpandableBarSwitch>
            )}
          </TopSectionFirstLine>

          <SubTitle>
            Turn on to indicate that you are open to hiring international
            talents.
          </SubTitle>
        </TopSection>

        <BottomAnimation isExpanded={isExpanded}>
          <BottomSection isError={errorState.includes(key)}>
            <ArrangementSection>
              <ArrangementTitle>
                <ArrangementText row={'1'}>Working Arrangement</ArrangementText>

                <ArrangementText row={'2'}>
                  Select one or more of the following options
                </ArrangementText>
              </ArrangementTitle>

              <CheckboxSection>
                {renderCheckBox(
                  'workingArrangementRemote',
                  formState?.globalHirePreferences?.workingArrangementRemote
                    ? formState?.globalHirePreferences?.workingArrangementRemote
                    : false,
                  'Remote',
                  'Employees will be fully remote with virtual collaborations',
                  'remote'
                )}

                {renderCheckBox(
                  'workingArrangementOnSite',
                  formState?.globalHirePreferences?.workingArrangementOnSite
                    ? formState?.globalHirePreferences?.workingArrangementOnSite
                    : false,
                  'On site',
                  'Employees will work onsite at your company location',
                  'on_site'
                )}

                {renderCheckBox(
                  'workingArrangementHybrid',
                  formState?.globalHirePreferences?.workingArrangementHybrid
                    ? formState?.globalHirePreferences?.workingArrangementHybrid
                    : false,
                  'Hybrid',
                  'Employees will work both remotely and onsite in a hybrid arrangement',
                  'hybrid'
                )}
              </CheckboxSection>
            </ArrangementSection>

            <RightsSection>
              <ArrangementTitle>
                <ArrangementText row={'1'}>
                  Candidate’s Right to Work
                </ArrangementText>

                <ArrangementText row={'2'}>
                  Select one or more of the following options
                </ArrangementText>
              </ArrangementTitle>

              <CheckboxSection>
                <div
                  style={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'flex-start'
                  }}>
                  <CheckboxStyled
                    name={'rightToWorkOpenToSponsor'}
                    color="default"
                    inputProps={{
                      'aria-label': 'checkbox with default color'
                    }}
                    checked={
                      formState?.globalHirePreferences?.rightToWorkOpenToSponsor
                        ? formState?.globalHirePreferences
                            ?.rightToWorkOpenToSponsor
                        : false
                    }
                    onChange={e => {
                      handleSingleFieldChange('globalHirePreferences', {
                        ...formState.globalHirePreferences,
                        rightToWorkOpenToSponsor: e.target.checked
                      });
                      handleErrorState(
                        errorState.filter(error => error !== key)
                      );
                    }}
                  />
                  <div>
                    <CheckboxText type={'title2'}>
                      I am open to sponsoring a working visa for qualified
                      candidates (subject to T&Cs)
                    </CheckboxText>
                  </div>
                </div>

                <div>
                  <FormControlLabel
                    style={{
                      display: 'flex',
                      gap: '8px',
                      alignItems: 'flex-start',
                      margin: '0px'
                    }}
                    control={
                      <>
                        <CheckboxStyled
                          checked={
                            formState?.globalHirePreferences
                              ?.rightToWorkLocalCitizen &&
                            formState?.globalHirePreferences
                              ?.rightToWorkPermanentCitizen &&
                            formState?.globalHirePreferences
                              ?.rightToWorkWorkVisaOrEmploymentPass
                          }
                          indeterminate={
                            formState?.globalHirePreferences
                              ?.rightToWorkLocalCitizen !==
                              formState?.globalHirePreferences
                                ?.rightToWorkPermanentCitizen ||
                            formState?.globalHirePreferences
                              ?.rightToWorkLocalCitizen !==
                              formState?.globalHirePreferences
                                ?.rightToWorkWorkVisaOrEmploymentPass
                          }
                          onChange={e => {
                            handleSingleFieldChange('globalHirePreferences', {
                              ...formState.globalHirePreferences,
                              rightToWorkLocalCitizen: e.target.checked,
                              rightToWorkPermanentCitizen: e.target.checked,
                              rightToWorkWorkVisaOrEmploymentPass:
                                e.target.checked
                            });
                            handleErrorState(
                              errorState.filter(error => error !== key)
                            );
                          }}
                        />
                        <div>
                          <CheckboxText type={'title2'}>
                            I prefer candidates with an existing right to work
                            in {countryName}
                          </CheckboxText>
                        </div>
                      </>
                    }
                  />
                  {(formState?.globalHirePreferences?.rightToWorkLocalCitizen ||
                    formState?.globalHirePreferences
                      ?.rightToWorkPermanentCitizen ||
                    formState?.globalHirePreferences
                      ?.rightToWorkWorkVisaOrEmploymentPass) && (
                    <div
                      style={{
                        display: 'flex',
                        gap: '2px',
                        alignItems: 'flex-start',
                        margin: '8px 0px 0px 8px',
                        flexDirection: 'column',
                        padding: '0px 8px 0px 16px',
                        borderLeft: '1px solid #D8D8D8'
                      }}>
                      <FormControlLabel
                        style={{
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'flex-start',
                          margin: '0px'
                        }}
                        control={
                          <>
                            <CheckboxStyled
                              checked={
                                formState?.globalHirePreferences
                                  ?.rightToWorkLocalCitizen
                              }
                              onChange={e => {
                                handleSingleFieldChange(
                                  'globalHirePreferences',
                                  {
                                    ...formState.globalHirePreferences,
                                    rightToWorkLocalCitizen: e.target.checked
                                  }
                                );

                                handleErrorState(
                                  errorState.filter(error => error !== key)
                                );
                              }}
                            />
                            <div>
                              <CheckboxText type={'title2'}>
                                Local Citizen
                              </CheckboxText>
                            </div>
                          </>
                        }
                      />

                      <FormControlLabel
                        style={{
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'flex-start',
                          margin: '0px'
                        }}
                        control={
                          <>
                            <CheckboxStyled
                              checked={
                                formState?.globalHirePreferences
                                  ?.rightToWorkPermanentCitizen
                              }
                              onChange={e => {
                                handleSingleFieldChange(
                                  'globalHirePreferences',
                                  {
                                    ...formState.globalHirePreferences,
                                    rightToWorkPermanentCitizen:
                                      e.target.checked
                                  }
                                );

                                handleErrorState(
                                  errorState.filter(error => error !== key)
                                );
                              }}
                            />
                            <div>
                              <CheckboxText type={'title2'}>
                                Permanent Resident (PR)
                              </CheckboxText>
                            </div>
                          </>
                        }
                      />

                      <FormControlLabel
                        style={{
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'flex-start',
                          margin: '0px'
                        }}
                        control={
                          <>
                            <CheckboxStyled
                              checked={
                                formState?.globalHirePreferences
                                  ?.rightToWorkWorkVisaOrEmploymentPass
                              }
                              onChange={e => {
                                handleSingleFieldChange(
                                  'globalHirePreferences',
                                  {
                                    ...formState.globalHirePreferences,
                                    rightToWorkWorkVisaOrEmploymentPass:
                                      e.target.checked
                                  }
                                );

                                handleErrorState(
                                  errorState.filter(error => error !== key)
                                );
                              }}
                            />
                            <div>
                              <CheckboxText type={'title2'}>
                                Work Visa / Employment Pass
                              </CheckboxText>
                            </div>
                          </>
                        }
                      />
                    </div>
                  )}
                </div>
              </CheckboxSection>
            </RightsSection>
          </BottomSection>

          {errorState.includes(key) && (
            <p
              style={{
                color: '#BE4242',
                fontSize: '12px',
                lineHeight: '20px',
                display: 'flex',
                padding: '0px 8px',
                alignItems: 'center',
                gap: '8px'
              }}>
              <ExclamationCircleIcon
                style={{
                  width: '20px',
                  height: '20px',
                  color: '#BE4242'
                }}
              />
              Global Hire is required.
            </p>
          )}
        </BottomAnimation>
      </MainContainer>
    </div>
  );
}
