import React from 'react';
import { Divider, Grid } from '@mui/material';
import ResumeLastUpdated from './ResumeLastUpdated';
import HighestEducationLevel from './HighestEducationLevel';
import JobSpecialisation from './JobSpecialisation';
import CompanyIndustry from './CompanyIndustry';
import Language from './Language';
import { MUIButtonStyled } from '../../../components/MUIButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearTalentsParams,
  getTalents,
  updateCurrentTalent,
  updateFeedbackCardStatus,
  updateFeedbackForm,
  updateFeedbackPage,
  updateKeywordSearchedStatus,
  updateNewSearch,
  updateSearchResumeCurrentPage,
  updateSearchTalentFilters,
  updateTalentSearchFAQPopup,
  updateTalentsList
} from '../../../redux/actions/talent_action';
import Experience from './Experience';
import SharedFilter from '../SharedFillter';
import moment from 'moment';
import { PuzzlePieceIcon } from '@heroicons/react/24/solid';
import { FAQButton, FAQButtonText } from './styles';
import ResumeSearch from '../ResumeSearch';
import Nationality from './Nationality';
import WorkplacePreference from './WorkplacePreference';
import CurrentLocation from './CurrentLocation';
import RightsPreference from './RightsPreference';

const ResumeFilters = ({ triggerLoadTalents }) => {
  const dispatch = useDispatch();

  const filters = useSelector(state => state.talents.searchTalentFilters);
  const talentsFilterNoResult = useSelector(
    state => state.talents.talentsFilterNoResult
  );
  const talentList = useSelector(state => state.talents.talents);
  const defaultValue = useSelector(state => state.talents.defaultFilter);

  const checkResumeLastUpdated = cFilters => {
    return moment().diff(
      moment.unix(cFilters['resumeLastUpdated']),
      'months'
    ) == 1
      ? ['1_month']
      : moment().diff(moment.unix(cFilters['resumeLastUpdated']), 'months') == 3
      ? ['3_months']
      : moment().diff(moment.unix(cFilters['resumeLastUpdated']), 'months') == 6
      ? ['6_months']
      : ['show_all'];
  };

  const checkExperience = cFilters => {
    if (
      cFilters['nationalities']?.length > 0 &&
      typeof cFilters['freshGrad'] == 'boolean'
    ) {
      return [
        `${cFilters['freshGrad'] ? 'fresh_grad' : 'experienced'}`,
        cFilters['nationalities'][0]?.toLowerCase()
      ];
    } else if (
      cFilters['nationalities']?.length > 0 &&
      typeof cFilters['freshGrad'] != 'boolean'
    ) {
      return [cFilters['nationalities'][0]?.toLowerCase()];
    } else if (
      typeof cFilters['freshGrad'] == 'boolean' &&
      cFilters['nationalities']?.length <= 0
    ) {
      return [`${cFilters['freshGrad'] ? 'fresh_grad' : 'experienced'}`];
    }
    return [];
  };

  const trackFilters = cFilters => {
    let params = {
      'min-year-exp': cFilters['minYearExperience'],
      experience: checkExperience(cFilters),
      'resume-last-updated': checkResumeLastUpdated(cFilters),
      'company-industry': cFilters['industryIds'],
      language: cFilters['languages'],
      'highest-education-level': cFilters['educationLevels'],
      'job-specialisation': cFilters['specialisationIds']
    };

    window.dataLayer.push({
      event: 'CE_click-apply-filter-talent-search',
      ...params
    });
  };

  // Check values between default value
  function deepEqual(obj1, obj2) {
    if (obj1 === obj2) return true;

    if (
      typeof obj1 !== 'object' ||
      typeof obj2 !== 'object' ||
      obj1 === null ||
      obj2 === null
    ) {
      return false;
    }

    if (Array.isArray(obj1) !== Array.isArray(obj2)) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (!keys2.includes(key)) {
        return false;
      }
      if (!deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  function resetFeedbackForm() {
    dispatch(
      updateFeedbackForm({
        rating: '',
        feedback: ''
      })
    );
    dispatch(updateFeedbackPage(0));
  }

  const checkForDefault = val => {
    const isDefault = deepEqual(defaultValue, val);

    if (isDefault) {
      dispatch(updateTalentsList([...talentList]));
    } else {
      dispatch(
        updateTalentsList([
          ...talentList,
          {
            displayFeedback: true
          }
        ])
      );
      resetFeedbackForm();
      dispatch(updateNewSearch(true));
      dispatch(updateFeedbackCardStatus(false));
    }
  };

  const onApplyFilters = () => {
    if (talentsFilterNoResult) {
      dispatch(clearTalentsParams());
    }

    let cFilters = { ...filters };

    if (cFilters.before) {
      delete cFilters.before;
    }

    cFilters.after = '';

    dispatch(updateSearchTalentFilters(cFilters)).then(() => {
      trackFilters(cFilters);
      dispatch(updateKeywordSearchedStatus(true, cFilters.keyword));
      dispatch(updateSearchResumeCurrentPage(1));
      dispatch(updateCurrentTalent({}));
      dispatch(getTalents(cFilters));
      checkForDefault(cFilters);
    });
  };

  const onClearFilters = () => {
    const params = {
      after: '',
      filterMode: 'or',
      keyword: '',
      wordMatch: 'word',
      advancedSearch: true,
      trackIds: [],
      specialisations: [],
      specialisationIds: [],
      industryIds: [],
      languages: [],
      nationalities: [],
      currentLocation: [],
      relocate: false,
      remote: false,
      openToSponsor: false,
      visaOrEmploymentPass: false,
      localCitizen: false,
      withVideoCoverLetter: false,
      freshGrad: null,
      minGraduationYear: moment().year() - 100,
      maxGraduationYear: moment().year(),
      sort: null,
      educationLevels: [],
      resumeLastUpdated: null,
      minYearExperience: 0,
      showHiddenProfile: false
    };

    dispatch(updateSearchTalentFilters(params));
  };

  return (
    <SharedFilter
      buttons={
        <Buttons
          onClearFilters={onClearFilters}
          onApplyFilters={onApplyFilters}
        />
      }
      filterTitle={
        <Grid
          display="flex"
          alignItems={'center'}
          justifyContent={'space-between'}
          gap="16px">
          <Title />
          <TalentSearchFAQButton />
        </Grid>
      }>
      <div
        style={{
          display: 'flex',
          padding: '8px 8px 16px 16px',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: '20px',
          flex: '1 0 0',
          alignSelf: 'stretch',
          flexDirection: 'column'
        }}>
        <ResumeSearch triggerLoadTalents={triggerLoadTalents}/>

        <CurrentLocation />
        <Nationality />
        <WorkplacePreference />
        <RightsPreference />
        <Experience />
        <ResumeLastUpdated />
        <JobSpecialisation />
        <CompanyIndustry />
        <Language />
        <HighestEducationLevel />
      </div>
    </SharedFilter>
  );
};

export default ResumeFilters;

export const Title = () => {
  return (
    <span style={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: '700' }}>
      Talent Search
    </span>
  );
};

export const TalentSearchFAQButton = () => {
  const dispatch = useDispatch();

  const onOpenPopup = () => {
    dispatch(updateTalentSearchFAQPopup(true));
  };

  return (
    <FAQButton onClick={onOpenPopup}>
      <PuzzlePieceIcon
        fill="purple"
        style={{
          height: '16px',
          width: '16px'
        }}
      />
      <FAQButtonText>What’s this?</FAQButtonText>
    </FAQButton>
  );
};

const Buttons = ({ onClearFilters, onApplyFilters }) => {
  return (
    <div
      style={{
        background: '#efefef',
        zIndex: '2',
        borderRadius: '0 0 8px 8px',
        position: 'sticky',
        bottom: '0'
      }}>
      <Divider />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          padding: '12px 16px'
        }}>
        <MUIButtonStyled
          label={'Clear Filter'}
          $fontSize={'12px'}
          $padding={'6px 20px'}
          $backgroundColor={'transparent'}
          $color={'#222'}
          $shadow={'none !important'}
          $borderRadius={'8px'}
          onClick={onClearFilters}
        />
        <MUIButtonStyled
          solidBlack
          label={'Apply Filter'}
          $fontSize={'12px'}
          $padding={'6px 20px'}
          onClick={onApplyFilters}
          $shadow={'none !important'}
          $borderRadius={'8px'}
        />
      </div>
    </div>
  );
};
