import React, { createContext, useContext, useEffect } from 'react';
import { useFormState } from '../helper/useFormState';
import usePathKey from '../helper/usePathKey';

const FormContext = createContext(null);

export const FormProvider = ({ children, initialValues }) => {
  const formHooks = useFormState(initialValues);
  const formState = formHooks?.formState;
  const pathKey = usePathKey();

  const internEdit =
    formState?.jobType?.id === '4' &&
    formState?.minYearExperience === -1 &&
    formState?.maxYearExperience === 0;
  const showIntern = pathKey === 'intern' || internEdit;
  const disableIntern =
    pathKey === 'intern' ||
    (internEdit && formState?.resourceType === 'internship');

  const seniorEdit =
    formState?.jobType?.id === '2' &&
    formState?.minYearExperience === 10 &&
    formState?.maxYearExperience === 20;
  const showSenior = pathKey === 'senior' || seniorEdit;
  const disableSenior =
    pathKey === 'senior' ||
    (seniorEdit && formState?.resourceType === 'free_super_senior');

  const contextValue = {
    ...formHooks,
    showIntern,
    showSenior,
    disableIntern,
    disableSenior
  };

  return (
    <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>
  );
};

export const useFormContext = () => useContext(FormContext);
