import { Grid } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  FooterBg,
  FooterContainer,
  CopyrightText,
  AnchorText,
  DividerStyled
} from './styles';
import { CopyrightOutlined } from '@mui/icons-material';
import HiredlyLogo from '../../assets/common/hiredly-logo-copyright.png';
import { useDispatch, useSelector } from 'react-redux';
import { updateContactSalesPopupStatus } from '../../redux/actions/job_action';
import { JOBSEEKER_URL } from '../../utils/Constants';
import { countryCodeOptions } from '../SharedSelectCountry/constants';
import HiredlyLogoSG from '../../assets/common/hiredly-logo-sg.png';

const Footer = () => {
  const year = moment().year();
  const dispatch = useDispatch();

  const onClickContactUs = () => {
    window.dataLayer.push({
      event: 'CE_contact-us-clicked--footer'
    });
    dispatch(updateContactSalesPopupStatus(true));
  };

  const dropdownCountry = useSelector(state => state.companies.country);

  return (
    <FooterBg>
      <FooterContainer
        display="flex"
        justifyContent={'space-between'}
        alignItems="center">
        <CopyrightText>
          <Grid display="flex" alignItems={'center'} gap={'4px'}>
            <img
              src={
                countryCodeOptions[dropdownCountry] == 'SG'
                  ? HiredlyLogoSG
                  : HiredlyLogo
              }
              alt="logo"
              style={{ width: 100, marginRight: '12px' }}
            />
            <CopyrightOutlined style={{ width: '12px', height: '12px' }} />{' '}
            Copyright 2014 - {year} Agensi Pekerjaan Wobb Sdn. Bhd. 1252077-A
          </Grid>
        </CopyrightText>

        <Grid display="flex" alignItems="center" gap="16px">
          <AnchorText
            href={JOBSEEKER_URL[dropdownCountry] + '/terms-and-conditions'}
            target="_blank">
            Terms & Conditions
          </AnchorText>
          <DividerStyled orientation="vertical"></DividerStyled>
          <AnchorText
            href={JOBSEEKER_URL[dropdownCountry] + '/privacy-policy'}
            target="_blank">
            Privacy Policy
          </AnchorText>
          <DividerStyled orientation="vertical"></DividerStyled>
          <AnchorText onClick={onClickContactUs}>Contact Us</AnchorText>
        </Grid>
      </FooterContainer>
    </FooterBg>
  );
};

export default Footer;
