export function isValidSocialMediaUrl(url) {
  // Regular expressions to match different social media URLs
  const instagramRegex =
    /^https?:\/\/(?:www\.)?instagram\.com\/([^/]+(?:\/[^/]+)*)\/?$/;
  const facebookRegex =
    /^https?:\/\/(?:www\.)?facebook\.com\/([^/]+(?:\/[^/]+)*)\/?$/;
  const tiktokRegex =
    /^https?:\/\/(?:www\.)?(?:tiktok\.com|vt\.tiktok\.com)\/@?([^/]+(?:\/[^/]+)*)\/?$/;
  const twitterRegex =
    /^https?:\/\/(?:www\.)?(?:twitter\.com|x\.com)\/([^/]+(?:\/[^/]+)*)\/?$/;

  // Check if the URL matches any of the regular expressions
  if (
    instagramRegex.test(url) ||
    facebookRegex.test(url) ||
    tiktokRegex.test(url) ||
    twitterRegex.test(url)
  ) {
    return true;
  }

  return false;
}
