import { Tooltip } from '@mui/material';
import React from 'react';
import {
  CardContainer,
  IconContainer,
  FontAwesomeIconStyled,
  Text,
  ContentContainer,
  CardContentContainer,
  HeightSpacer,
  MainContainer
} from './styles';

const InfoCard = ({ item }) => {
  return (
    <CardContainer>
      <CardContentContainer>
        <MainContainer>
          <IconContainer>
            <FontAwesomeIconStyled
              icon={item?.icon}
              awesome_icon={'faUserFriends'}
            />
          </IconContainer>
          <ContentContainer>
            <Tooltip
              placement="bottom"
              title={<Text tooltip="true">{item?.title}</Text>}>
              <Text type="label">{item?.title}</Text>
            </Tooltip>
            <HeightSpacer height={'5px'} />
            <Text type="number">{item?.number}</Text>
          </ContentContainer>
        </MainContainer>
      </CardContentContainer>
    </CardContainer>
  );
};

export default InfoCard;
