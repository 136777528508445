import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  externalJobUrl: yup
    .string()
    .url('Please make sure ATS url is in correct format')
    .nullable(),
  customEmail: yup
    .string()
    .email('Please make sure notification email is in correct format')
    .nullable()
});
