import React, { Fragment, useEffect, useState } from 'react';
import { SharedButton } from '../SharedButton';
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import {
  Text,
  Spacer,
  HeightSpacer,
  StatsWrapper,
  ContentHeader,
  ButtonIconContainer,
  StatCardContainer,
  MenuStyled,
  SkeletonStyled
} from './styles';
import StatCard from './StatCard';
import { useNavigate } from 'react-router-dom';
import { openInNewTab } from '../../../../utils/WindowUtils';
import { MenuItem } from '@mui/material';
import ContactPopUp from '../../../PromoPackages/ContactPopUp';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateAvailableResourcesCardIndex,
  updateContactSalesPopupStatus,
  updateExpiryDatesPopupStatus,
  viewAllSortedPackages,
  viewPackageSubscriptionDetails,
  viewSortedJobSlotsAndPrepJobSlotsPackages
} from '../../../../redux/actions/job_action';
import ExpiryDatesPopUp from '../../PopUps/ExpiryDatesPopUp';
import store from '../../../../../src/redux/stores/store';
import moment from 'moment';

const useStyles = makeStyles({
  menuItem: {
    width: '100%',

    '&.MuiMenuItem-root': {
      padding: '5px 15px 5px 15px'
    }
  }
});

const ProductStats = ({ data }) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  const myAccountInfo = useSelector(state => state.companies.accountInfo);
  const permissions = myAccountInfo?.permissions;
  const hasBuyTokenAccess = permissions?.buyToken ? true : false;
  const hasActiveJobSlot = data?.jobSlotsTotal > 0 ? true : false;

  const fetchingPackageSubscriptionDetails = useSelector(
    state => state.jobs.fetchingPackageSubscriptionDetails
  );
  //total_quantity includes tokens/slots that are occupied but not expired and not being used yet
  const jobSlotsTotal = useSelector(
    state => state.jobs.subscribedJobSlotsPackage?.total_quantity
  );
  const jobSlotsPlusTotal = useSelector(
    state => state.jobs.subscribedJobSlotPlusPackage?.total_quantity
  );
  const jobSlotsMaxTotal = useSelector(
    state => state.jobs.subscribedJobSlotMaxPackage?.total_quantity
  );

  const jobSlots6mTotal = useSelector(
    state => state.jobs.subscribedJobSlot6mPackage?.total_quantity
  );

  const jobSlotsPlus6mTotal = useSelector(
    state => state.jobs.subscribedJobSlotPlus6mPackage?.total_quantity
  );
  const jobSlotsMax6mTotal = useSelector(
    state => state.jobs.subscribedJobSlotMax6mPackage?.total_quantity
  );
  //total_usable_quantity means tokens/slots are not being used yet
  const remainingUsableJobSlots = useSelector(
    state => state.jobs.subscribedJobSlotsPackage?.total_usable_quantity
  );
  const remainingUsableJobSlotsPlus = useSelector(
    state => state.jobs.subscribedJobSlotPlusPackage?.total_usable_quantity
  );
  const remainingUsableJobSlotsMax = useSelector(
    state => state.jobs.subscribedJobSlotMaxPackage?.total_usable_quantity
  );
  const remainingUsableJobSlots6m = useSelector(
    state => state.jobs.subscribedJobSlot6mPackage?.total_usable_quantity
  );
  const remainingUsableJobSlotsPlus6m = useSelector(
    state => state.jobs.subscribedJobSlotPlus6mPackage?.total_usable_quantity
  );
  const remainingUsableJobSlotsMax6m = useSelector(
    state => state.jobs.subscribedJobSlotMax6mPackage?.total_usable_quantity
  );

  const featuredTokensUsableCount = useSelector(
    state => state.jobs.subscribedFeaturedTokensPackage?.total_usable_quantity
  );
  const jobTokensUsableCount = useSelector(
    state => state.jobs.subscribedJobTokensPackage?.total_usable_quantity
  );

  const refresherTokensUsableCount = useSelector(
    state => state.jobs.subscribedRefresherTokensPackage?.total_usable_quantity
  );
  const freeInternshipTokensUsableCount = useSelector(
    state =>
      state.jobs.subscribedFreeInternshipTokensPackage?.total_usable_quantity
  );
  const freeSuperSeniorTokensCount = useSelector(
    state =>
      state.jobs.subscribedFreeSuperSeniorTokensPackage?.total_usable_quantity
  );
  const jobCreditUsableCount = useSelector(
    state => state.jobs.subscribedJobCreditsPackage?.total_usable_quantity
  );

  // to disable card stats
  const freeInternshipTokensTotalCount = useSelector(
    state => state?.jobs.subscribedFreeInternshipTokensPackage?.total_quantity
  );
  const superSeniorTokensTotalCount = useSelector(
    state => state?.jobs.subscribedFreeSuperSeniorTokensPackage?.total_quantity
  );

  const subscribedJobSlotsPackage =
    store.getState().jobs.subscribedJobSlotsPackage;
  const subscribedFeaturedTokensPackage =
    store.getState().jobs.subscribedFeaturedTokensPackage;
  const subscribedRefresherTokensPackage =
    store.getState().jobs.subscribedRefresherTokensPackage;
  const subscribedFreeInternshipTokensPackage =
    store.getState().jobs.subscribedFreeInternshipTokensPackage;
  const subscribedFreeSuperSeniorTokensPackage =
    store.getState().jobs.subscribedFreeSuperSeniorTokensPackage;
  const subscribedJobTokensPackage =
    store.getState().jobs.subscribedJobTokensPackage;
  const subscribedJobCreditsPackage =
    store.getState().jobs.subscribedJobCreditsPackage;

  const [jobTokens, setJobTokens] = useState({});
  const [refresherTokens, setRefresherTokens] = useState({});
  const [jobSlots, setJobSlots] = useState({});
  const [featuredTokens, setFeaturedTokens] = useState({});
  const [freeInternshipTokens, setFreeInternshipTokens] = useState({});
  const [freeSuperSeniorTokens, setFreeSuperSeniorTokens] = useState({});
  const [jobCredits, setJobCredits] = useState({});

  const [anchorEl, setAnchorEl] = useState(null);

  const totalCombinedJobSlots =
    jobSlotsTotal +
    jobSlotsPlusTotal +
    jobSlotsMaxTotal +
    jobSlots6mTotal +
    jobSlotsPlus6mTotal +
    jobSlotsMax6mTotal;
  const totalAvailableCombinedJobSlots =
    remainingUsableJobSlots +
    remainingUsableJobSlotsPlus +
    remainingUsableJobSlotsMax +
    remainingUsableJobSlots6m +
    remainingUsableJobSlotsPlus6m +
    remainingUsableJobSlotsMax6m;

  const availableResourcesCardData = [
    {
      title: 'Job Slots',
      usableCount: `${totalAvailableCombinedJobSlots}`,
      totalCount: `${totalCombinedJobSlots}`,
      disableCardStats: 'false',
      type: 'first_row'
    },
    // job token and job credit
    {
      title:
        jobCreditUsableCount > 0 ||
        (jobCreditUsableCount == 0 && jobTokensUsableCount == 0)
          ? 'Job Credits'
          : 'Job Tokens',
      usableCount:
        jobCreditUsableCount > 0 ||
        (jobCreditUsableCount == 0 && jobTokensUsableCount == 0)
          ? `${jobCreditUsableCount}`
          : `${jobTokensUsableCount}`,
      disableCardStats: `${
        jobTokensUsableCount <= 0 &&
        jobTokensUsableCount != 'Unlimited' &&
        jobCreditUsableCount <= 0
      }`
    },
    {
      title: 'Featured Tokens',
      usableCount: `${featuredTokensUsableCount}`,
      disableCardStats: 'false'
    },
    // senior posts and internship
    {
      title:
        freeInternshipTokensUsableCount > 0 ||
        (freeSuperSeniorTokensCount == 0 &&
          freeInternshipTokensUsableCount == 0)
          ? 'Internship Tokens'
          : 'Free Senior Posts',
      usableCount:
        freeInternshipTokensUsableCount > 0 ||
        (freeSuperSeniorTokensCount == 0 &&
          freeInternshipTokensUsableCount == 0)
          ? `${freeInternshipTokensUsableCount}`
          : freeInternshipTokensUsableCount == 'Unlimited'
          ? 'Unlimited'
          : freeSuperSeniorTokensCount > 0
          ? `${freeSuperSeniorTokensCount}`
          : 0,
      //job tokens are not using total count because there's no need to add up the quantity
      disableCardStats: `${
        superSeniorTokensTotalCount <= 0 &&
        freeInternshipTokensUsableCount <= 0 &&
        freeInternshipTokensUsableCount != 'Unlimited'
      }`
    },
    {
      title: 'Refresher Tokens',
      usableCount: `${refresherTokensUsableCount}`,
      disableCardStats: 'false'
    }
  ];

  const getPackage = key => {
    if (key == 'Job Slots') {
      return subscribedJobSlotsPackage;
    } else if (key == 'Featured Tokens') {
      return subscribedFeaturedTokensPackage;
    } else if (key == 'Job Tokens') {
      return subscribedJobTokensPackage;
    } else if (key == 'Refresher Tokens') {
      return subscribedRefresherTokensPackage;
    } else if (key == 'Free Internship Tokens') {
      // Key is from backend so value remain as Free Internship Tokens
      return subscribedFreeInternshipTokensPackage;
    } else if (key == 'Free Super Senior Jobs') {
      return subscribedFreeSuperSeniorTokensPackage;
    } else if (key == 'Job Credits') {
      return subscribedJobCreditsPackage;
    }
  };

  const setDetails = (key, counts, dateAndCount, earliestDate) => {
    if (key == 'Job Slots') {
      setJobSlots({ counts, dateAndCount, earliestDate });
    } else if (key == 'Featured Tokens') {
      setFeaturedTokens({ counts, dateAndCount, earliestDate });
    } else if (key == 'Job Tokens') {
      setJobTokens({ counts, dateAndCount, earliestDate });
    } else if (key == 'Refresher Tokens') {
      setRefresherTokens({ counts, dateAndCount, earliestDate });
    } else if (key == 'Free Internship Tokens') {
      // Key is from backend so value remain as Free Internship Tokens
      setFreeInternshipTokens({ counts, dateAndCount, earliestDate });
    } else if (key == 'Free Super Senior Jobs') {
      setFreeSuperSeniorTokens({ counts, dateAndCount, earliestDate });
    } else if (key == 'Job Credits') {
      setJobCredits({ counts, dateAndCount, earliestDate });
    }
  };

  const grouping = key => {
    if (!key) return;

    let counts = { 7: 0, 14: 0, 21: 0, 30: 0 };
    let dateAndCount = {};
    let earliestDate = '';

    getPackage(key)?.details?.map(item => {
      if (item?.days_remaining > 0 && item.days_remaining <= 7) {
        counts['7'] += item?.usable_quantity;
      } else if (item?.days_remaining > 7 && item.days_remaining <= 14) {
        counts['14'] += item?.usable_quantity;
      } else if (item?.days_remaining > 14 && item.days_remaining <= 21) {
        counts['21'] += item?.usable_quantity;
      } else if (item?.days_remaining > 21 && item.days_remaining <= 30) {
        counts['30'] += item?.usable_quantity;
      } else if (item?.days_remaining > 30) {
        if (!dateAndCount[item?.expiry_date]) {
          dateAndCount[item?.expiry_date] = item?.usable_quantity;
        } else {
          if (!dateAndCount[item?.expiry_date]) {
            dateAndCount[item?.expiry_date] = item?.usable_quantity;
          } else if (dateAndCount[item?.expiry_date] != 'Unlimited') {
            // If date and count not unlimited yet
            let usable_quantity = item?.usable_quantity;

            if (typeof usable_quantity === 'string') {
              if (usable_quantity.toLowerCase() == 'unlimited') {
                // unlimited
                dateAndCount[item?.expiry_date] = item?.usable_quantity;
              } else {
                dateAndCount[item?.expiry_date] += item?.usable_quantity;
              }
            } else {
              dateAndCount[item?.expiry_date] += item?.usable_quantity;
            }
          }
        }

        if (earliestDate == '') {
          earliestDate = item?.expiry_date;
        } else {
          if (moment(earliestDate).isAfter(moment(item?.expiry_date))) {
            earliestDate = item?.expiry_date;
          }
        }
      }
    });

    setDetails(key, counts, dateAndCount, earliestDate);
  };

  const getDetails = key => {
    if (key == 'Job Slots') {
      return jobSlots;
    } else if (key == 'Featured Tokens') {
      return featuredTokens;
    } else if (key == 'Job Tokens') {
      return jobTokens;
    } else if (key == 'Refresher Tokens') {
      return refresherTokens;
    } else if (key == 'Internship Tokens') {
      // This is from product type so value changed to Internship Tokens
      return freeInternshipTokens;
    } else if (key == 'Free Senior Posts') {
      return freeSuperSeniorTokens;
    } else if (key == 'Job Credits') {
      return jobCredits;
    }
  };

  const getPredefinedMessage = (category, count, key) => {
    const currentCount = count.toString().toLowerCase().includes('unlimited')
      ? 'Unlimited'
      : count;

    if (category == '7') {
      return `${currentCount} ${
        key == 'Job Slots' ? key : 'Tokens'
      } expiring within 7 days`;
    } else if (category == '14') {
      return `${currentCount} ${
        key == 'Job Slots' ? key : 'Tokens'
      } expiring within 14 days`;
    } else if (category == '21') {
      return `${currentCount} ${
        key == 'Job Slots' ? key : 'Tokens'
      } expiring within 21 days`;
    } else if (category == '30') {
      return `${currentCount} ${
        key == 'Job Slots' ? key : 'Tokens'
      } expiring within 30 days`;
    }
  };

  const getCopy = key => {
    if (key == 'Job Tokens' && jobTokensUsableCount <= 0) {
      key = 'Free Senior Posts';
    }

    let copyMessage = '';
    const details = getDetails(key);

    const categories = ['7', '14', '21', '30'];

    if (details?.counts) {
      categories.forEach(category => {
        if (details?.counts[category]) {
          const nextMessage = getPredefinedMessage(
            category,
            details?.counts[category],
            key
          );

          if (copyMessage == '' || nextMessage.includes('Unlimited')) {
            copyMessage = nextMessage;
          }
        }
      });
    }
    if (copyMessage == '') {
      if (details?.earliestDate) {
        copyMessage = `${details?.dateAndCount[details.earliestDate]} ${
          key == 'Job Slots +'
            ? key
            : key == 'Job Credits'
            ? 'Credits'
            : 'Tokens'
        } expiring on ${moment(details?.earliestDate).format('DD MMM YYYY')}`;
      }
    }

    return copyMessage;
  };

  const onHorizDotsClose = () => {
    setAnchorEl(null);
  };

  const triggerContactSalesPopup = () => {
    window.dataLayer.push({
      event: 'CE_contact-us-clicked--jobs-page'
    });
    setAnchorEl(null);
    dispatch(updateContactSalesPopupStatus(true));
  };

  const triggerExpiryDatesPopup = () => {
    dispatch(updateAvailableResourcesCardIndex(-1));
    setAnchorEl(null);
    dispatch(updateExpiryDatesPopupStatus(true));
  };

  useEffect(() => {
    dispatch(viewPackageSubscriptionDetails());
  }, []);

  useEffect(() => {
    if (subscribedJobSlotsPackage?.package) {
      if (jobSlots?.counts == undefined) {
        grouping(subscribedJobSlotsPackage.package);
      }
    }

    if (subscribedFeaturedTokensPackage?.package) {
      if (featuredTokens?.counts == undefined) {
        grouping(subscribedFeaturedTokensPackage.package);
      }
    }

    if (subscribedJobTokensPackage?.package) {
      if (jobTokens?.counts == undefined) {
        grouping(subscribedJobTokensPackage.package);
      }
    }

    if (subscribedRefresherTokensPackage?.package) {
      if (refresherTokens?.counts == undefined) {
        grouping(subscribedRefresherTokensPackage.package);
      }
    }

    if (subscribedFreeInternshipTokensPackage?.package) {
      if (freeInternshipTokens?.counts == undefined) {
        grouping(subscribedFreeInternshipTokensPackage?.package);
      }
    }

    if (subscribedFreeSuperSeniorTokensPackage?.package) {
      if (freeSuperSeniorTokens?.counts == undefined) {
        grouping(subscribedFreeSuperSeniorTokensPackage.package);
      }
    }

    if (subscribedJobCreditsPackage?.package) {
      if (jobCredits?.counts == undefined) {
        grouping(subscribedJobCreditsPackage.package);
      }
    }

    // if (
    //   subscribedJobSlotsPackage.package &&
    //   subscribedFeaturedTokensPackage.package &&
    //   subscribedJobTokensPackage.package &&
    //   subscribedRefresherTokensPackage.package &&
    //   subscribedFreeInternshipTokensPackage?.package &&
    //   subscribedFreeSuperSeniorTokensPackage?.package
    // ) {
    //   grouping(subscribedJobSlotsPackage.package);
    //   grouping(subscribedFeaturedTokensPackage.package);
    //   grouping(subscribedJobTokensPackage.package);
    //   grouping(subscribedRefresherTokensPackage.package);
    //   grouping(subscribedFreeInternshipTokensPackage?.package);
    //   grouping(subscribedFreeSuperSeniorTokensPackage.package);
    // }
  }, [
    subscribedJobSlotsPackage,
    subscribedFeaturedTokensPackage,
    subscribedJobTokensPackage,
    subscribedRefresherTokensPackage,
    subscribedFreeInternshipTokensPackage,
    subscribedFreeSuperSeniorTokensPackage,
    subscribedJobCreditsPackage
  ]);

  useEffect(() => {
    dispatch(viewSortedJobSlotsAndPrepJobSlotsPackages());
  }, []);

  return (
    <>
      <StatsWrapper>
        <ContentHeader>
          <Text content_title="true">Available Resources</Text>
          <ButtonIconContainer>
            {store.getState().companies.accountInfo?.permissions?.buyToken && (
              <SharedButton
                id="standalone-purchase-more-button"
                type={'basic'}
                onClick={() => {
                  history('/purchase/choose');
                }}>
                Purchase More
              </SharedButton>
            )}
            <Spacer width={'30px'} />
            <MoreHorizIcon
              id="job-settings-icon"
              style={{
                fontSize: '30px',
                color: '#222',
                cursor: 'pointer'
              }}
              onClick={event => setAnchorEl(event.currentTarget)}
            />
          </ButtonIconContainer>
        </ContentHeader>
        <HeightSpacer height={'5px'} />
        <StatCardContainer>
          {availableResourcesCardData.map((item, index) => {
            if (fetchingPackageSubscriptionDetails) {
              return (
                <Fragment key={item?.title}>
                  <SkeletonStyled
                    variant="text"
                    width="100%"
                    margin_top="true"
                  />
                  <SkeletonStyled variant="text" width="100%" />
                </Fragment>
              );
            }

            return (
              <Fragment key={item?.title}>
                <StatCard
                  key={index}
                  usableCount={item?.usableCount}
                  totalCount={item?.totalCount}
                  disableCardStats={item?.disableCardStats}
                  cardTitle={item?.title}
                  cardDesc={getCopy(item?.title)}
                  index={index}
                  type={item?.type ? item?.type : null}
                  hasBuyTokenAccess={hasBuyTokenAccess}
                  hasActiveJobSlot={hasActiveJobSlot}
                />
              </Fragment>
            );
          })}
        </StatCardContainer>
      </StatsWrapper>

      <MenuStyled
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        getContentAnchorEl={null}
        onClose={onHorizDotsClose}>
        <MenuItem
          onClick={triggerExpiryDatesPopup}
          className={classes.menuItem}>
          <Text menu_text="true">Show All Expiry Dates</Text>
        </MenuItem>
        <HeightSpacer height={'5px'} />
        <MenuItem
          className={classes.menuItem}
          onClick={triggerContactSalesPopup}
          id="speak-with-sales-button">
          <Text menu_text="true">Speak With Sales</Text>
        </MenuItem>
      </MenuStyled>
      <ExpiryDatesPopUp
        open={useSelector(state => state.jobs.isExpiryDatesPopupOpen)}
        handleClose={() => dispatch(updateExpiryDatesPopupStatus(false))}
      />
    </>
  );
};

export default ProductStats;
