import React from 'react';
import {
  MainContainer,
  FirstContainer,
  TextContainer,
  Text,
  SecondContainer,
  ButtonContainer,
  CardStyled
} from './styles';
import { getCountry } from '../../../hooks/useRegionalisation';

const CloseLoop = props => {
  const handleClick = () => {
    props.setIsCloseLoopOpen(true);
  };

  return (
    <CardStyled>
      <MainContainer>
        <FirstContainer>
          <TextContainer origin="subtitle">
            <Text origin="subtitle" page={props.page}>
              Get 2 free Refresher Tokens worth{' '}
              {getCountry() === 'SG' ? '$40' : 'RM100'} by letting us know
              whether you have hired this candidate.
            </Text>
          </TextContainer>
        </FirstContainer>
        <SecondContainer>
          <ButtonContainer id="close-loop-button" onClick={handleClick}>
            <Text origin="button_text">Hired!</Text>
          </ButtonContainer>
        </SecondContainer>
      </MainContainer>
    </CardStyled>
  );
};

export default CloseLoop;
