import React, { useEffect, useState } from 'react';
import { faHighlighter } from '@fortawesome/free-solid-svg-icons';
import { SimpleTipsTooltip } from '../../../../../components/Tooltips';
import {
  VisualBoosterContainer,
  VisualBoosterIconsContainer,
  GradientBorder,
  Title,
  TooltipText,
  FontAwesomeIconStyled,
  SparklesIconStyled,
  GlobeAltIconStyled,
  ArrowPathIconStyled,
  StarIconStyled,
  CircularProgressContainer
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateKeywordHighlightPopupStatus,
  updateSpotlightJobConfirmationPopup,
  updateCrossBorderConfirmationPopup,
  updateKeywordHighlightConfirmationPopup,
  updateKeywordHighlightTexts
} from '../../../../../redux/actions/job_action';
import moment from 'moment';
import { CircularProgress, Grid, Tooltip } from '@mui/material';

const VisibilityBooster = ({
  onRefreshJob,
  onFeaturedJob,
  job,
  getDiffInMin,
  setJobId,
  textRef
}) => {
  return (
    <VisualBoosterContainer>
      <Title>Visibility Booster:</Title>

      <Grid display={'flex'} flexWrap={'wrap'} gap={2}>
        <KeywordHighlightBooster
          job={job}
          setJobId={setJobId}
          textRef={textRef}
        />

        <SpotlightBooster job={job} setJobId={setJobId} />

        <RefresherBooster
          onRefreshJob={onRefreshJob}
          job={job}
          getDiffInMin={getDiffInMin}
        />

        <FeatureBooster onFeaturedJob={onFeaturedJob} job={job} />

        <CrossBorderBooster setJobId={setJobId} job={job} />

      </Grid>
    </VisualBoosterContainer>
  );
};

export default VisibilityBooster;

export const RefresherBooster = ({ onRefreshJob, job, getDiffInMin }) => {
  const [progress, setProgress] = useState(100);

  const getTimer = job => {
    const res = getDiffInMin(job);

    if (res == -1) return '';

    // if (res == 75) return '1 Hour';
    return res;
  };

  const getProgressValue = job => {
    // const remainingMins = getDiffInMin(job);

    // const totalMins = 60;

    // if (remainingMins > 0) {
    //   const progressPercentage = (remainingMins / totalMins) * 100;

    //   setProgress(progressPercentage);
    // } else {
    //   setProgress(0);
    // }

    if (job?.refreshJobThreshold > 0) {
      setProgress(job?.refreshJobThreshold);
    } else {
      setProgress(0);
    }
  };

  useEffect(() => {
    if (job) {
      getProgressValue(job);
    }
  }, [job]);

  return (
    <SimpleTipsTooltip
      icon={
        <div style={{ position: 'relative' }}>
          <VisualBoosterIconsContainer
            onClick={() => onRefreshJob(job)}
            disabled={!job?.enableRefreshJob || !job.active}>
            <ArrowPathIconStyled
              disabled={!job?.enableRefreshJob || !job.active}
              progress={progress}
            />
            <CircularProgressContainer>
              <CircularProgress
                value={progress}
                size={48}
                thickness={2}
                variant="determinate"
                sx={{
                  '& .MuiCircularProgress-circle': {
                    stroke:
                      progress <= 25 //percentage
                        ? 'rgba(242, 166, 71, 1)'
                        : 'linear-gradient(rgba(81, 42, 204, 1),rgba(109, 50, 197, 1),rgba(152, 62, 186, 1))'
                  }
                }}
              />
            </CircularProgressContainer>
          </VisualBoosterIconsContainer>
        </div>
      }
      top={'45px'}
      left={getTimer(job) ? '-40px' : '-25px'}
      width={getTimer(job) ? '130px' : '95px'}
      padding={'4px 8px'}
      description={
        job.refreshJobThreshold !== 0 && job.refreshJobThreshold !== null ? (
          <div style={{ textAlign: 'center' }}>
            <TooltipText>Next Refresh in {getTimer(job)} minutes</TooltipText>
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <TooltipText>Refresh Job</TooltipText>
          </div>
        )
      }
      position={'absolute'}
      borderRadius={'8px'}
      fontSize={'10px'}
      border={'1.5px solid #222222'}
      zIndex={'2'}
      wrapperZIndex={'3'}
    />
  );
};

export const FeatureBooster = ({ onFeaturedJob, job }) => {
  const [progress, setProgress] = useState(100);

  const getProgressValue = job => {
    // const remainingMinutes = moment(job?.boostedExpired).diff(
    //   moment(),
    //   'minutes'
    // );

    // const totalMinutes = moment(job?.boostedExpired).diff(
    //   moment(job?.boostedAt),
    //   'minutes'
    // );

    // if (remainingMinutes > 0) {
    //   const progressPercentage = (remainingMinutes / totalMinutes) * 100;

    //   setProgress(progressPercentage);

    if (job?.featureJobThreshold > 0) {
      setProgress(job?.featureJobThreshold);
    } else {
      setProgress(0);
    }
  };

  const isExpired = job => {
    const expiry = moment(job?.boostedExpired);
    const remainingTime = expiry.diff(moment(), 'mins');

    if (remainingTime > 0 && job.boostedExpired) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (job) {
      getProgressValue(job);
    }
  }, [job]);

  return (
    <SimpleTipsTooltip
      icon={
        <div style={{ position: 'relative' }}>
          <VisualBoosterIconsContainer
            onClick={() => onFeaturedJob(job)}
            disabled={
              (!job?.enableFeatureJob && job?.boostedExpired) || !job.active
            }>
            <StarIconStyled
              disabled={
                (!job?.enableFeatureJob && job?.boostedExpired) || !job.active
              } // boostedExpired is to allow featuring when the job created (default value - null)
              progress={progress}
            />
          </VisualBoosterIconsContainer>
          <CircularProgressContainer>
            <CircularProgress
              value={progress}
              size={48}
              thickness={2}
              variant="determinate"
              sx={{
                '& .MuiCircularProgress-circle': {
                  stroke:
                    progress <= 25 // percentage
                      ? 'rgba(242, 166, 71, 1)'
                      : 'linear-gradient(rgba(81, 42, 204, 1),rgba(109, 50, 197, 1),rgba(152, 62, 186, 1))'
                }
              }}
            />
          </CircularProgressContainer>
        </div>
      }
      top={'45px'}
      left={
        (progress > 0 && progress <= 25) ||
        (!isExpired(job) && job?.boostedExpired)
          ? '-40px'
          : '-25px'
      }
      width={
        progress > 0 && progress <= 25
          ? '170px'
          : !isExpired(job) && job?.boostedExpired
          ? '130px'
          : '95px'
      }
      padding={'4px 8px'}
      description={
        progress > 0 && progress <= 25 ? (
          <div style={{ textAlign: 'center' }}>
            <TooltipText>
              Click to Extend Feature <br />
              <br />
              Spotlight applied until{' '}
              {moment(job?.spotlightExpired).format('DD/MM/YYYY')}
            </TooltipText>
          </div>
        ) : !isExpired(job) && job?.boostedExpired ? (
          <div style={{ textAlign: 'center' }}>
            <TooltipText>
              Featured until {moment(job?.boostedExpired).format('DD/MM/YYYY')}
            </TooltipText>
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <TooltipText>Feature Job</TooltipText>
          </div>
        )
      }
      position={'absolute'}
      borderRadius={'8px'}
      fontSize={'10px'}
      border={'1.5px solid #222222'}
      zIndex={'2'}
      wrapperZIndex={'3'}
    />
  );
};

export const CrossBorderBooster = ({ job, setJobId }) => {
  const dispatch = useDispatch();

  const [progress, setProgress] = useState(100);

  const onClickCrossBorderIcon = job => {
    if ((job?.crossedBorder && job?.crossBorderCountryExpired) || !job?.active) {
      return;
    }

    setJobId(job.id);
    dispatch(updateCrossBorderConfirmationPopup(true));
  };

  const getProgressValue = job => {
    // const remainingMinutes = moment(job?.spotlightExpired).diff(
    //   moment(),
    //   'minutes'
    // );

    // const totalMinutes = moment(job?.spotlightExpired).diff(
    //   moment(job?.spotlightAt),
    //   'minutes'
    // );

    // if (remainingMinutes > 0) {
    //   const progressPercentage = (remainingMinutes / totalMinutes) * 100;

    //   setProgress(progressPercentage);

    if (job?.crossBorderJobThreshold > 0) {
      setProgress(job?.crossBorderJobThreshold);
    } else {
      setProgress(0);
    }
  };

  const isExpired = job => {
    const expiry = moment(job?.crossBorderCountryExpired);
    const remainingTime = expiry.diff(moment(), 'mins');

    if (remainingTime > 0 && job.crossBorderCountryExpired) {
      return false;
    }

    return true;
  };

  const getCountry = job => {
    if(job == 'SG'){
      return 'Singapore'
    }else if(job == 'MY'){
      return 'Malaysia'
    }else{
      return 'country'
    }
  };

  useEffect(() => {
    if (job) {
      getProgressValue(job);
    }
  }, [job]);

  return (
    <SimpleTipsTooltip
      icon={
        <div style={{ position: 'relative' }}>
          <VisualBoosterIconsContainer
            onClick={() => onClickCrossBorderIcon(job)}
            disabled={
              (job?.crossedBorder && job?.crossBorderCountryExpired) || !job.active
            }>
            <GlobeAltIconStyled
              disabled={
                (!job?.crossedBorder && job?.crossBorderCountryExpired) ||
                !job.active
              }
              progress={progress}
            />
          </VisualBoosterIconsContainer>
          <CircularProgressContainer>
            <CircularProgress
              value={progress}
              size={48}
              thickness={2}
              variant="determinate"
              sx={{
                '& .MuiCircularProgress-circle': {
                  stroke:
                    progress <= 25 //percentage
                      ? 'rgba(242, 166, 71, 1)'
                      : 'linear-gradient(rgba(81, 42, 204, 1),rgba(109, 50, 197, 1),rgba(152, 62, 186, 1))'
                }
              }}
            />
          </CircularProgressContainer>
        </div>
      }
      top={'45px'}
      left={
        (!isExpired(job) && job?.crossBorderCountryExpired) ||
        (progress > 0 && progress <= 25)
          ? '-60px'
          : '-35px'
      }
      width={
        (!isExpired(job) && job?.crossBorderCountryExpired) ||
        (progress > 0 && progress <= 25)
          ? '170px'
          : '120px'
      }
      padding={'4px 8px'}
      description={
        progress > 0 && progress <= 25 ? (
          <div style={{ textAlign: 'center' }}>
            <TooltipText>
              Click to Extend Cross Border <br />
              <br />
              Advertise in{' '}{getCountry(job?.crossBorderData?.aggregated_countries)}{' '}until{' '}
              {moment(job?.crossBorderCountryExpired).format('DD/MM/YYYY')}
            </TooltipText>
          </div>
        ) : 
        !isExpired(job) && job?.crossBorderCountryExpired ? (
          <div style={{ textAlign: 'center' }}>
            <TooltipText>
              Advertise in{' '}{getCountry(job?.crossBorderData?.aggregated_countries)}{' '}until{' '}
              {moment(job?.crossBorderCountryExpired).format('DD/MM/YYYY')}
            </TooltipText>
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <TooltipText>Advertise Job Globally</TooltipText>
          </div>
        )
      }
      position={'absolute'}
      borderRadius={'8px'}
      fontSize={'10px'}
      border={'1.5px solid #222222'}
      zIndex={'2'}
      wrapperZIndex={'3'}
    />
  );
};

export const SpotlightBooster = ({ job, setJobId }) => {
  const dispatch = useDispatch();

  const [progress, setProgress] = useState(100);

  const onClickSpotlightIcon = job => {
    if ((!job?.enableSpotlightJob && job?.spotlightExpired) || !job?.active) {
      return;
    }

    window.dataLayer.push({
      event: 'CE_click-visibility-booster-spotlight-job'
    });

    setJobId(job.id);
    dispatch(updateSpotlightJobConfirmationPopup(true));
  };

  const getProgressValue = job => {
    // const remainingMinutes = moment(job?.spotlightExpired).diff(
    //   moment(),
    //   'minutes'
    // );

    // const totalMinutes = moment(job?.spotlightExpired).diff(
    //   moment(job?.spotlightAt),
    //   'minutes'
    // );

    // if (remainingMinutes > 0) {
    //   const progressPercentage = (remainingMinutes / totalMinutes) * 100;

    //   setProgress(progressPercentage);

    if (job?.spotlightJobThreshold > 0) {
      setProgress(job?.spotlightJobThreshold);
    } else {
      setProgress(0);
    }
  };

  const isExpired = job => {
    const expiry = moment(job?.spotlightExpired);
    const remainingTime = expiry.diff(moment(), 'mins');

    if (remainingTime > 0 && job.spotlightExpired) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (job) {
      getProgressValue(job);
    }
  }, [job]);

  return (
    <SimpleTipsTooltip
      icon={
        <div style={{ position: 'relative' }}>
          <VisualBoosterIconsContainer
            onClick={() => onClickSpotlightIcon(job)}
            disabled={
              (!job?.enableSpotlightJob && job?.spotlightExpired) || !job.active
            }>
            <SparklesIconStyled
              disabled={
                (!job?.enableSpotlightJob && job?.spotlightExpired) ||
                !job.active
              }
              progress={progress}
            />
          </VisualBoosterIconsContainer>
          <CircularProgressContainer>
            <CircularProgress
              value={progress}
              size={48}
              thickness={2}
              variant="determinate"
              sx={{
                '& .MuiCircularProgress-circle': {
                  stroke:
                    job?.resourceType == 'job_slot_max' && job?.active
                      ? '#6EC7CD'
                      : job?.resourceType == 'job_slot_plus' && job?.active
                      ? '#6EC7CD'
                      : progress <= 25 // percentage
                      ? 'rgba(242, 166, 71, 1)'
                      : 'linear-gradient(rgba(81, 42, 204, 1),rgba(109, 50, 197, 1),rgba(152, 62, 186, 1))'
                }
              }}
            />
          </CircularProgressContainer>
        </div>
      }
      top={'45px'}
      left={
        (!isExpired(job) && job?.spotlightExpired) ||
        (progress > 0 && progress <= 25)
          ? '-60px'
          : '-35px'
      }
      width={
        (!isExpired(job) && job?.spotlightExpired) ||
        (progress > 0 && progress <= 25)
          ? '170px'
          : '120px'
      }
      padding={'4px 8px'}
      description={
        job?.resourceType == 'job_slot_max' && job?.active ? (
          <div style={{ textAlign: 'center' }}>
            <TooltipText>
              Powered by Job Slot Max until{' '}
              {moment(job?.spotlightExpired).format('DD/MM/YYYY')}
            </TooltipText>
          </div>
        ) : job?.resourceType == 'job_slot_plus' && job?.active ? (
          <div style={{ textAlign: 'center' }}>
            <TooltipText>
              Powered by Job Slot Plus until{' '}
              {moment(job?.spotlightExpired).format('DD/MM/YYYY')}
            </TooltipText>
          </div>
        ) : progress > 0 && progress <= 25 ? (
          <div style={{ textAlign: 'center' }}>
            <TooltipText>
              Click to Extend Spotlight <br />
              <br />
              Spotlight applied until{' '}
              {moment(job?.spotlightExpired).format('DD/MM/YYYY')}
            </TooltipText>
          </div>
        ) : !isExpired(job) && job?.spotlightExpired ? (
          <div style={{ textAlign: 'center' }}>
            <TooltipText>
              Spotlight applied until{' '}
              {moment(job?.spotlightExpired).format('DD/MM/YYYY')}
            </TooltipText>
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <TooltipText>Spotlight Job</TooltipText>
          </div>
        )
      }
      position={'absolute'}
      borderRadius={'8px'}
      fontSize={'10px'}
      border={'1.5px solid #222222'}
      zIndex={'2'}
      wrapperZIndex={'3'}
    />
  );
};

export const KeywordHighlightBooster = ({ job, setJobId, textRef }) => {
  const dispatch = useDispatch();

  const keywordHighlightTokensUsableCount = useSelector(
    state =>
      state.jobs.subscribedKeywordHighlightTokensPackage?.total_usable_quantity
  );

  const [progress, setProgress] = useState(100);

  const onClickKeywordHighlight = job => {
    if (!job?.active) return;

    setJobId(job.id);

    window.dataLayer.push({
      event: 'CE_click-visibility-booster-keyword-highlight'
    });

    if (keywordHighlightTokensUsableCount <= 0 && !job?.keywordHighlight) {
      dispatch(updateKeywordHighlightConfirmationPopup(true));
    } else {
      let addKeywordKey = [];

      if (job?.keywordHighlightText && job.keywordHighlightText.length > 0) {
        addKeywordKey = job.keywordHighlightText.map(item => ({
          keyword: item
        }));
      }

      setTimeout(() => {
        textRef.current.focus();
      }, 500);

      dispatch(updateKeywordHighlightTexts(addKeywordKey));
      dispatch(updateKeywordHighlightPopupStatus(true));
    }
  };

  const getProgressValue = job => {
    // const remainingMinutes = moment(job?.keywordHighlightExpired).diff(
    //   moment(),
    //   'minutes'
    // );

    // const totalMinutes = moment(job?.keywordHighlightExpired).diff(
    //   moment(job?.keywordHighlightAt),
    //   'minutes'
    // );

    // if (remainingMinutes > 0) {
    //   const progressPercentage = (remainingMinutes / totalMinutes) * 100;

    //   setProgress(progressPercentage);
    // } else {
    //   setProgress(0);
    // }

    if (job?.keywordHighlightJobThreshold > 0) {
      setProgress(job?.keywordHighlightJobThreshold);
    } else {
      setProgress(0);
    }
  };

  const isExpired = job => {
    const expiry = moment(job?.keywordHighlightExpired);
    const remainingTime = expiry.diff(moment(), 'mins');

    if (remainingTime > 0 && job.keywordHighlightExpired) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (job) {
      getProgressValue(job);
    }
  }, [job]);

  return (
    <SimpleTipsTooltip
      icon={
        <div style={{ position: 'relative' }}>
          <VisualBoosterIconsContainer
            disabled={!job.active}
            onClick={() => onClickKeywordHighlight(job)}>
            <FontAwesomeIconStyled
              icon={faHighlighter}
              progress={progress}
              disabled={!job.active}
              jobType={job?.resourceType}
            />
          </VisualBoosterIconsContainer>
          <CircularProgressContainer>
            <CircularProgress
              value={progress}
              size={48}
              thickness={2}
              variant="determinate"
              jobType={job?.resourceType}
              sx={{
                '& .MuiCircularProgress-circle': {
                  stroke:
                    job?.resourceType == 'job_slot_max' && job?.active
                      ? '#6EC7CD'
                      : progress <= 25 // percentage
                      ? 'rgba(242, 166, 71, 1)'
                      : 'linear-gradient(rgba(81, 42, 204, 1),rgba(109, 50, 197, 1),rgba(152, 62, 186, 1))'
                }
              }}
            />
          </CircularProgressContainer>
        </div>
      }
      top={'45px'}
      left={
        (!isExpired(job) && job?.keywordHighlightExpired) ||
        (progress > 0 && progress <= 25)
          ? '-70px'
          : '-60px'
      }
      width={
        (!isExpired(job) && job?.keywordHighlightExpired) ||
        (progress > 0 && progress <= 25)
          ? '200px'
          : '170px'
      }
      padding={'4px 8px'}
      description={
        job?.resourceType == 'job_slot_max' && job?.active ? (
          <div style={{ textAlign: 'center' }}>
            <span
              style={{
                display: 'flex',
                fontWeight: '500',
                fontSize: '12px'
              }}>
              Click to edit Key Highlights added.{' '}
            </span>{' '}
            <br />
            <TooltipText>
              Powered by Job Slot Max until{' '}
              {moment(job?.keywordHighlightExpired).format('DD/MM/YYYY')}
            </TooltipText>
          </div>
        ) : progress > 0 && progress <= 25 ? (
          <div style={{ textAlign: 'center' }}>
            <TooltipText>
              Click to <b>Extend </b> or <b>Edit</b> Keyword Highlights added.{' '}
              <br />
              <br />
              Key Highlights applied until{' '}
              {moment(job?.keywordHighlightExpired).format('DD/MM/YYYY')}
            </TooltipText>
          </div>
        ) : !isExpired(job) && job?.keywordHighlightExpired ? (
          <div style={{ textAlign: 'center' }}>
            <span
              style={{
                display: 'flex',
                fontWeight: '500',
                fontSize: '12px'
              }}>
              Click to edit Key Highlights added.{' '}
            </span>{' '}
            <br />
            <TooltipText>
              (Key Highlights applied until{' '}
              {moment(job?.keywordHighlightExpired).format('DD/MM/YYYY')})
            </TooltipText>
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <TooltipText>Add Key Highlights</TooltipText>
          </div>
        )
      }
      position={'absolute'}
      borderRadius={'8px'}
      fontSize={'10px'}
      border={'1.5px solid #222222'}
      zIndex={'2'}
      wrapperZIndex={'3'}
    />
  );
};
