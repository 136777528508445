// CANCEL ORDER
export const CANCELING_ORDER = 'CANCELING_ORDER';
export const CANCEL_ORDER_SUCCEED = 'CANCEL_ORDER_SUCCEED';
export const CANCEL_ORDER_FAILED = 'CANCEL_ORDER_FAILED';

// FETCH CART
export const FETCH_CART = 'FETCH_CART';
export const FETCH_CART_SUCCEED = 'FETCH_CART_SUCCEED';
export const FETCH_CART_FAILED = 'FETCH_CART_FAILED';

// FETCH MY ORDERS
export const FETCH_MY_ORDERS = 'FETCH_MY_ORDERS';
export const FETCH_MY_ORDERS_SUCCEED = 'FETCH_MY_ORDERS_SUCCEED';
export const FETCH_MY_ORDERS_FAILED = 'FETCH_MY_ORDERS_FAILED';

// FETCH MY ORDER
export const FETCH_MY_ORDER = 'FETCH_MY_ORDER';
export const FETCH_MY_ORDER_SUCCEED = 'FETCH_MY_ORDER_SUCCEED';
export const FETCH_MY_ORDER_FAILED = 'FETCH_MY_ORDER_FAILED';

// FETCH CART APPLICABLE VOUCHERS
export const FETCH_CART_APPLICABLE_VOUCHERS = 'FETCH_CART_APPLICABLE_VOUCHERS';
export const FETCH_CART_APPLICABLE_VOUCHERS_SUCCEED =
  'FETCH_CART_APPLICABLE_VOUCHERS_SUCCEED';
export const FETCH_CART_APPLICABLE_VOUCHERS_FAILED =
  'FETCH_CART_APPLICABLE_VOUCHERS_FAILED';

// MUTATE CART
export const MUTATE_CART = 'MUTATE_CART';
export const MUTATE_CART_SUCCEED = 'MUTATE_CART_SUCCEED';
export const MUTATE_CART_FAILED = 'MUTATE_CART_FAILED';

// MUTATE CART SUMMARY
export const MUTATE_CART_SUMMARY = 'MUTATE_CART_SUMMARY';
export const MUTATE_CART_SUMMARY_SUCCEED = 'MUTATE_CART_SUMMARY_SUCCEED';
export const MUTATE_CART_SUMMARY_FAILED = 'MUTATE_CART_SUMMARY_FAILED';

// MUTATE CHECKOUT ORDER
export const MUTATE_CHECKOUT_ORDER = 'MUTATE_CHECKOUT_ORDER';
export const MUTATE_CHECKOUT_ORDER_SUCCEED = 'MUTATE_CHECKOUT_ORDER_SUCCEED';
export const MUTATE_CHECKOUT_ORDER_FAILED = 'MUTATE_CHECKOUT_ORDER_FAILED';

// MUTATE ORDER SUMMARY
export const MUTATE_ORDER_SUMMARY = 'MUTATE_ORDER_SUMMARY';
export const MUTATE_ORDER_SUMMARY_SUCCEED = 'MUTATE_ORDER_SUMMARY_SUCCEED';
export const MUTATE_ORDER_SUMMARY_FAILED = 'MUTATE_ORDER_SUMMARY_FAILED';

// MUTATE PAYMENT LINK
export const MUTATE_PAYMENT_LINK = 'MUTATE_PAYMENT_LINK';
export const MUTATE_PAYMENT_LINK_SUCCEED = 'MUTATE_PAYMENT_LINK_SUCCEED';
export const MUTATE_PAYMENT_LINK_FAILED = 'MUTATE_PAYMENT_LINK_FAILED';

// UDPATE SELECTED PRODUCT TAB
export const UPDATE_SELECTED_PRODUCT_TAB = 'UPDATE_SELECTED_PRODUCT_TAB';

// UDPATE PRODUCT INFO DIALOG
export const UDPATE_PRODUCT_INFO_DIALOG = 'UDPATE_PRODUCT_INFO_DIALOG';

// UPDATE INFO DIALOG STATE
export const UDPATE_INFO_DIALOG_STATE = 'UDPATE_INFO_DIALOG_STATE';

// CLEAR ORDER INFO
export const CLEAR_ORDER_INFO = 'CLEAR_ORDER_INFO';

// UPDATE SELECTED ITEM
export const UPDATE_SELECTED_ITEM = 'UPDATE_SELECTED_ITEM';
