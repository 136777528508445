import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled as styling } from '@mui/material/styles';
import Colors from '../../../../../styles/colors.module.scss';

export const Text = styling('span')((props) => ({
  fontFamily: 'Inter',
  
  ...(props.content_title == 'true' && {
    fontWeight: 700,
    fontSize: '18px',
  }),

  ...(props.iconText == 'true' && {
    fontSize: '14px',
  }),

  ...(props.status_count == 'true' && {
    fontSize: '20px',
    fontFamily: 'Inter',
    fontWeight: 700,
    textAlign: 'center',
    color: Colors.priPurple,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'break-spaces',
  }),

  ...(props.card_title == 'true' && {
    fontSize: '14px',
    color: Colors.priBlack,
    fontWeight: 500,
  }),

  ...(props.card_desc == 'true' && {
    fontSize: '12px',
    color: Colors.terDarkGrey,
    display: 'inline',
  }),

  ...(props.prep_job_slots == 'true' && {
    fontSize: '12px',
    color: Colors.priPurple,
    fontWeight: 'bold',
  }),

  ...(props.tooltip == 'true' && {
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: 'Inter',
    padding: '5px 5px 0 5px',
  }),

  ...(props.important == 'true' && {
    fontSize: '12px',
    color: Colors.secOrange,
    display: 'inline',
  }),
}));

export const Spacer = styling('div')((props) => ({
  width: props.width ? props.width : '5px',
}));

export const HeightSpacer = styling('div')((props) => ({
  height: props.height ? props.height : '5px',
}));

export const CountWrapper = styling('div')((props) => ({
  ...(props.type != 'first_row'
  ? {
      background: Colors.secLightPurple,
      borderRadius: '10px',
      padding: '20px 10px',
      justifyContent: 'center',
      display: 'flex',
      minWidth: '100px',
      maxWidth: '100px',
      minHeight: '60px',
    }
  : {
    background: Colors.secLightPurple,
    borderRadius: '10px',
    padding: '20px 10px',
    justifyContent: 'center',
    display: 'flex',
    minWidth: '100px',
    maxWidth: '100px',
    minHeight: '60px',
  }),
}));

export const PerpJobSlotsCard = styling('div')({
  position: 'absolute',
  width: '38%',
  padding: '10px 5px',
  background: Colors.secLightPurple,
  borderRadius: '0 0 0 40px',
  top: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  '@media (max-width: 1440px)': {
    width: '220px',
  },
  '@media (max-width: 1280px)': {
    maxWidth: '130px',
    padding: '10px 5px 10px 30px',
  },
});

export const CardContainer = styling('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const CardStyled = styling('div')((props) => ({
  position: 'relative',
  cursor: 'pointer',
  background: Colors.priWhite,
  borderRadius: '20px',
  padding: '12px',
  display: 'flex',
  alignItems: 'center',
  boxShadow: '0px 4px 10px 1px rgba(81, 42, 204, 0.12)',
  width: '48%',
  marginTop: '10px',
  overflow: 'hidden',

  '&:hover': {
    backgroundPosition: 'center',
    transition: 'background 0.3s',
    background:  `${Colors.secLightPurple}
    radial-gradient(circle, transparent 1%, ${Colors.secLightPurple} 1%)
    center/15000%`,
  },

  // &:hover ${CountWrapper} {
  // &:hover ${PerpJobSlotsCard} {
  '&:hover .jobSlotCard': {
    backgroundPosition: 'center',
    transition: 'background 0.3s',
    background: Colors.priWhite,
  },

  '&:hover .jobCountCard': {
    backgroundPosition: 'center',
    transition: 'background 0.3s',
    background: Colors.priWhite,
  },

  ...(props.disabled == true && {
    cursor: 'not-allowed',

    '&:hover': {
      backgroundPosition: 'center',
      transition: 'background 0.3s',
      background: Colors.priWhite,
    },

    '&:hover .jobCountCard': {
      backgroundPosition: 'center',
      transition: 'background 0.3s',
      background: Colors.secLightPurple,
    },
  }),

  ...(props.type == 'first_row' && {
    zIndex: 6,
    minWidth: '100%',
  }),
}));


export const AnnouncementCard = styling('div')({
  cursor: 'pointer',
  position: 'relative',
  width: '100%',
  marginTop: '-16px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '28px 16px 12px 24px',
  height: '60px',
  background: 'rgba(242, 175, 41, 0.15)',
  borderRadius: '0px 0px 16px 16px',
  '&:hover': {
    background: 'rgba(242, 175, 41, 0.25)',
  },
  '&:hover .hand_pointer': {
    display: 'block',
  },
});

export const AnnouncementText = styling('span')({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '20px',
});

export const LearnMoreText = styling('strong')({
  marginLeft: '8px',
  fontWeight: 'bold',
});

export const TextContainer = styling('div')({
  marginTop: '4px',
});

export const FontAwesomeIconStyled = styling(FontAwesomeIcon)((props) => ({
  color: Colors.priPurple,
  fontSize: '24px',

  ...(props.name == 'fa_check_circle' && {
    color: Colors.priPurple,
    fontSize: '12px',
  }),

  ...(props.name == 'hand_pointer' && {
    position: 'absolute',
    right: '-5px',
    bottom: '-30px',
    color: Colors.priBlack,
    fontSize: '40px',
    zIndex: 5,
    display: 'none',
  }),
}));
