import React, { Fragment, useEffect, useState } from 'react';
import { Divider } from '@mui/material';
import { Spinner } from '../../../components';
import styles from './index.module.scss';
import { ErrorToast, SuccessToast } from '../../../utils/ToastUtils';
import countries from 'i18n-iso-countries';
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry
} from 'postcode-validator';
import {
  BillingDetailTitle,
  BoldTitleText,
  CardStyled,
  CountrySelectStyled,
  EditButtonStyled,
  EditIconStyled,
  GridStyled,
  PaymentButtonStyled,
  RegionSelectStyled,
  TextFieldStyled
} from './styles';
import {
  NoWidthSkeleton,
  OnlinePaymentSkeleton
} from '../../PackageOrderFlow/Skeletons/styles';
import { ArrowDownIcon } from '@heroicons/react/24/solid';
import { useDispatch, useSelector } from 'react-redux';
import { mutateCompanyDetails } from '../../../redux/actions/company_actions';
import { countryCodeOptions } from '../../../components/SharedSelectCountry/constants';
import { getCountry } from '../../../hooks/useRegionalisation';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const BillingAddressAndPayment = ({
  billingAddress,
  loading,
  handleOnlinePayment,
  triggerManualPayment,
  disablePayment
}) => {
  if (billingAddress) {
    billingAddress = billingAddress[0];
  }

  const dispatch = useDispatch();
  const [toggleEdit, setToggleEdit] = useState(false);

  const fetchingCompanySettings = useSelector(
    state => state.companies.fetchingCompanySettings
  );

  const [values, setValues] = useState({
    address: billingAddress ? billingAddress?.streetAddress : '',
    city: billingAddress ? billingAddress?.town : '',
    postcode: billingAddress ? billingAddress?.postcode : '',
    state: billingAddress ? billingAddress?.state : '',
    country: billingAddress ? billingAddress?.country : ''
  });

  const { address, city, postcode, country, state } = values;

  const _onToggleEdit = () => {
    setToggleEdit(!toggleEdit);
  };

  useEffect(() => {
    setValues({
      ...values,
      address: billingAddress?.streetAddress,
      city: billingAddress?.town,
      postcode: billingAddress?.postcode,
      state: billingAddress?.state,
      country: billingAddress?.country
    });

    setCountryWarning(false);
    setStateWarning(false);
    setAddressWarning(false);
    setCityWarning(false);
    setPostcodeWarning(false);
  }, [toggleEdit]);

  const [addressWarning, setAddressWarning] = useState(false);
  const [cityWarning, setCityWarning] = useState(false);
  const [postcodeWarning, setPostcodeWarning] = useState(false);
  const [stateWarning, setStateWarning] = useState(false);
  const [countryWarning, setCountryWarning] = useState(false);
  const [countryCode, setCountryCode] = useState('');

  let validPostcodeForNotExistedCountryCode;

  const handleValues = name => e => {
    if (name == 'country') {
      getCountryCode(e);
      if (e == '') {
        setCountryWarning(true);
      } else {
        setCountryWarning(false);
      }
    } else if (name == 'state') {
      if (e == '') {
        setStateWarning(true);
      } else {
        setStateWarning(false);
      }
    } else if (name == 'address') {
      if (e.target.value == '') {
        setAddressWarning(true);
      } else {
        setAddressWarning(false);
      }
    } else if (name == 'city') {
      if (e.target.value == '') {
        setCityWarning(true);
      } else {
        setCityWarning(false);
      }
    } else if (name == 'postcode') {
      const postcodeRegex = /^[a-zA-Z0-9\b ]+$/;

      validPostcodeForNotExistedCountryCode = postcodeRegex.test(
        e.target.value
      );

      if (e.target.value == '') {
        setPostcodeWarning(true);
      } else if (
        !validatePostcode(e.target.value) ||
        (validatePostcode(e.target.value) == 'countryCodeNotExisted' &&
          !validPostcodeForNotExistedCountryCode)
      ) {
        setPostcodeWarning(true);
      } else {
        setPostcodeWarning(false);
      }
    }

    setValues({
      ...values,
      [name]: name == 'country' || name == 'state' ? e : e.target.value
    });
  };

  const getCountryCode = country => {
    let cCountryCode = countries.getAlpha2Code(country, 'en');

    setCountryCode(cCountryCode);
  };

  const validatePostcode = value => {
    let countryCodeExists = postcodeValidatorExistsForCountry(countryCode);

    if (countryCodeExists) {
      let validPostcode = postcodeValidator(value, countryCode);

      return validPostcode;
    } else {
      return 'countryCodeNotExisted';
    }
  };

  const onSubmitBillingInformation = async e => {
    if (
      address &&
      city &&
      state &&
      country &&
      postcode &&
      (validatePostcode(postcode) ||
        (validatePostcode(postcode) == 'countryCodeNotExisted' &&
          validPostcodeForNotExistedCountryCode))
    ) {
      dispatch(
        mutateCompanyDetails({
          input: {
            billingAddress: {
              streetAddress: address,
              town: city,
              postcode: postcode,
              state: state,
              country: country
            }
          }
        })
      )
        .then(res => {
          _onToggleEdit();
          if (res.type === 'FETCH_MY_COMPANY_SETTINGS_SUCCEED') {
            SuccessToast('Company Billing Information Updated Successfully!');
          } else {
            SuccessToast('Company Billing Information Update Failed');
          }
        })
        .catch(error => {
          console.error('Error:', error);
          ErrorToast(error.message);
        });
    } else if (country == '') {
      setCountryWarning(true);
      return;
    } else if (state == '') {
      setStateWarning(true);
      return;
    } else if (address == '') {
      setAddressWarning(true);
      return;
    } else if (city == '') {
      setCityWarning(true);
      return;
    } else if (postcode == '') {
      setPostcodeWarning(true);
    } else if (
      !validatePostcode(postcode) ||
      (validatePostcode(postcode) == 'countryCodeNotExisted' &&
        !validPostcodeForNotExistedCountryCode)
    ) {
      setPostcodeWarning(true);
    } else {
      setCountryWarning(true);
      setStateWarning(true);
      setAddressWarning(true);
      setCityWarning(true);
      setPostcodeWarning(true);
    }
  };

  useEffect(() => {
    if (country) {
      getCountryCode(country);

      if (country !== billingAddress?.country) {
        setValues({
          ...values,
          state: '' // Reset state to empty string when country changes
        });
      } else {
        setValues({
          ...values,
          state: billingAddress?.state || ''
        });
      }
    }
  }, [country]);

  return (
    <CardStyled country={getCountry()}>
      <GridStyled
        borderBottom={'1px solid #D8D8D8'}
        padding={'16px'}
        container
        flexWrap={'nowrap'}
        justifyContent={'space-between'}
        alignItems={'center'}>
        {loading ? (
          <NoWidthSkeleton width={'120px'} />
        ) : (
          <BoldTitleText variant="h2">Billing Address</BoldTitleText>
        )}
        {loading ? (
          <NoWidthSkeleton width={'80px'} />
        ) : !toggleEdit ? (
          <EditButtonStyled
            variant="text"
            onClick={() => _onToggleEdit()}
            disabled={toggleEdit}>
            Edit <EditIconStyled />
          </EditButtonStyled>
        ) : null}
      </GridStyled>
      {toggleEdit ? (
        <form>
          <GridStyled
            container
            flexDirection={'column'}
            rowGap={'12px'}
            marginBottom={'12px'}
            padding={'16px'}>
            <GridStyled container flexDirection={'column'}>
              <BillingDetailTitle variant="body1">
                Street Address
              </BillingDetailTitle>
              <TextFieldStyled
                value={address}
                onChange={handleValues('address')}
                name="address"
                error={addressWarning}
                helperText={addressWarning && 'Address is required'}
              />
            </GridStyled>
            <GridStyled container flexDirection={'column'}>
              <BillingDetailTitle variant="body1">City</BillingDetailTitle>
              <TextFieldStyled
                value={city}
                onChange={handleValues('city')}
                name="city"
                error={cityWarning}
                helperText={cityWarning && 'City is required'}
              />
            </GridStyled>
            <GridStyled container flexDirection={'column'}>
              <BillingDetailTitle variant="body1">Postcode</BillingDetailTitle>
              <TextFieldStyled
                value={postcode}
                onChange={handleValues('postcode')}
                name="postcode"
                error={postcodeWarning}
                helperText={postcodeWarning && 'Postcode is required'}
              />
            </GridStyled>
            <GridStyled container flexDirection={'column'}>
              <BillingDetailTitle variant="body1">Country</BillingDetailTitle>
              <CountrySelectStyled
                onChange={handleValues('country')}
                name="country"
                label="Select Country"
                value={country}
                priorityOptions={[getCountry()]}
              />
              {countryWarning && (
                <p
                  style={{
                    color: '#f44336',
                    margin: '3px 14px 0',
                    fontSize: '0.75rem',
                    fontWeight: '400',
                    fontFamily: 'sans-serif',
                    borderRadius: '30px'
                  }}>
                  Country is required
                </p>
              )}
            </GridStyled>

            <GridStyled
              container
              flexDirection={'column'}
              marginBottom={'12px'}>
              <BillingDetailTitle variant="body1">
                State/Region
              </BillingDetailTitle>
              <RegionSelectStyled
                onChange={handleValues('state')}
                name="state"
                label="Select State/Region"
                value={state}
                defaultOptionLabel="Select State/Region"
                country={country}
              />
              {stateWarning && (
                <p
                  style={{
                    color: '#f44336',
                    margin: '3px 14px 0',
                    fontSize: '0.75rem',
                    fontWeight: '400',
                    fontFamily: 'sans-serif',
                    borderRadius: '30px'
                  }}>
                  State/Region is required
                </p>
              )}
            </GridStyled>

            {fetchingCompanySettings ? (
              <Spinner size={'XS'} />
            ) : (
              <GridStyled
                container
                flexWrap={'nowrap'}
                flexDirection={'row'}
                columnGap={'16px'}>
                <PaymentButtonStyled
                  sx={{
                    flex: 1,
                    fontSize: '14px',
                    color: 'black',
                    height: '36px',
                    '&:hover': {
                      textDecoration: 'underline',
                      backgroundColor: 'transparent'
                    }
                  }}
                  variant="text"
                  onClick={() => _onToggleEdit()}>
                  Cancel
                </PaymentButtonStyled>
                <PaymentButtonStyled
                  sx={{
                    flex: 1,
                    fontSize: '14px',
                    height: '36px',
                    background: '#222',
                    '&:hover': {
                      backgroundColor: '#222222'
                    }
                  }}
                  variant="contained"
                  onClick={onSubmitBillingInformation}>
                  Save
                </PaymentButtonStyled>
              </GridStyled>
            )}
          </GridStyled>
        </form>
      ) : (
        <GridStyled
          container
          flexWrap={'column'}
          rowGap={'12px'}
          padding={'16px'}
          marginBottom={'12px'}
          flexDirection={'column'}>
          {loading ? (
            <div className={styles.skeletonContainer}>
              <NoWidthSkeleton width={110} sx={{ marginBottom: '6px' }} />
              <NoWidthSkeleton width={170} />
            </div>
          ) : (
            <GridStyled container flexDirection={'column'}>
              <BillingDetailTitle variant="body1">
                Street Address
              </BillingDetailTitle>
              <div className={styles.labelValue}>
                {billingAddress?.streetAddress}
              </div>
            </GridStyled>
          )}
          {loading ? (
            <div className={styles.skeletonContainer}>
              <NoWidthSkeleton width={110} sx={{ marginBottom: '6px' }} />
              <NoWidthSkeleton width={170} />
            </div>
          ) : (
            <GridStyled container flexDirection={'column'}>
              <BillingDetailTitle variant="body1">City</BillingDetailTitle>
              <div className={styles.labelValue}>{billingAddress?.town}</div>
            </GridStyled>
          )}
          {loading ? (
            <div className={styles.skeletonContainer}>
              <NoWidthSkeleton width={110} sx={{ marginBottom: '6px' }} />
              <NoWidthSkeleton width={170} />
            </div>
          ) : (
            <GridStyled container flexDirection={'column'}>
              <BillingDetailTitle variant="body1">Postcode</BillingDetailTitle>
              <div className={styles.labelValue}>
                {billingAddress?.postcode}
              </div>
            </GridStyled>
          )}
          {loading ? (
            <div className={styles.skeletonContainer}>
              <NoWidthSkeleton width={110} sx={{ marginBottom: '6px' }} />
              <NoWidthSkeleton width={170} />
            </div>
          ) : (
            <GridStyled container flexDirection={'column'}>
              <BillingDetailTitle variant="body1">
                State/Region
              </BillingDetailTitle>
              <div className={styles.labelValue}>{billingAddress?.state}</div>
            </GridStyled>
          )}
          {loading ? (
            <div className={styles.skeletonContainer}>
              <NoWidthSkeleton width={110} sx={{ marginBottom: '6px' }} />
              <NoWidthSkeleton width={170} />
            </div>
          ) : (
            <GridStyled container flexDirection={'column'}>
              <BillingDetailTitle variant="body1">Country</BillingDetailTitle>
              <div className={styles.labelValue}>{billingAddress?.country}</div>
            </GridStyled>
          )}
        </GridStyled>
      )}
      <Divider sx={{ marginBottom: '24px', marginX: '16px' }} />
      <GridStyled
        padding={'0px 16px 16px 16px'}
        container
        flexDirection={'column'}>
        {loading ? (
          <Fragment>
            <NoWidthSkeleton width={184} sx={{ marginBottom: '16px' }} />
            <OnlinePaymentSkeleton sx={{ marginBottom: '16px' }} />
            <GridStyled container justifyContent={'center'}>
              <NoWidthSkeleton width={188} sx={{ marginBottom: '16px' }} />
            </GridStyled>
          </Fragment>
        ) : (
          <Fragment>
            {countryCodeOptions[getCountry()] == 'MY' && (
              <BoldTitleText variant="h2" marginBottom={'16px'}>
                Select Payment Option
              </BoldTitleText>
            )}

            {countryCodeOptions[getCountry()] == 'MY' && (
              <PaymentButtonStyled
                fullWidth
                variant="contained"
                onClick={handleOnlinePayment}
                sx={{
                  marginBottom: '10px'
                }}
                disabled={disablePayment}>
                Make Online Payment
              </PaymentButtonStyled>
            )}
            <PaymentButtonStyled
              fullWidth
              variant={getCountry() == 'SG' ? 'contained' : 'text'}
              onClick={triggerManualPayment}
              disabled={disablePayment}>
              Make Manual Payment
            </PaymentButtonStyled>
          </Fragment>
        )}
      </GridStyled>
    </CardStyled>
  );
};

export default BillingAddressAndPayment;
