import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, MenuItem } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
// import { TextFieldStyled } from '../styles';
import { useFormContext } from '../../context';
import { TextFieldStyled } from './styles.js';
import usePathKey from '../../helper/usePathKey.js';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

export default function CareerType() {
  const key = 'careerLevel';
  const pathKey = usePathKey();
  const { formState, handleSingleFieldChange, errorState, handleErrorState } =
    useFormContext();
  const careerLevels = useSelector(state => state.companies.careerLevels);

  const onChange = e => {
    handleErrorState(errorState.filter(error => error !== key));
    handleSingleFieldChange('careerLevel', { id: e.target.value });
    if (e.target.value === '8' && pathKey !== 'senior') {
      handleErrorState(
        errorState.filter(error => !error.includes('jobType') && error !== key)
      );
      handleSingleFieldChange('jobType', { id: '4' });
      handleSingleFieldChange('maxYearExperience', 0);
      handleSingleFieldChange('minYearExperience', -1);
    }
  };

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0 0 20px',
        width: '100%'
      }}>
      <Grid
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '15px',
          width: '100%'
        }}>
        <FontAwesomeIcon icon={faUser} sx={{ marginRight: '15px' }} />
        <TextFieldStyled
          select
          name="careerLevel"
          isError={errorState.includes(key)}
          value={formState?.careerLevel?.id || 'placeholder'}
          displayEmpty
          fullWidth
          variant="outlined"
          onChange={onChange}>
          <MenuItem disabled value="placeholder" style={{ display: 'none' }}>
            {'Select career level'}
          </MenuItem>
          {careerLevels?.map(type => {
            return <MenuItem value={type?.id}>{type?.name}</MenuItem>;
          })}
        </TextFieldStyled>
      </Grid>

      {errorState.includes(key) && (
        <p
          style={{
            color: '#BE4242',
            fontSize: '12px',
            lineHeight: '20px',
            display: 'flex',
            padding: '0px 8px',
            alignItems: 'center',
            gap: '8px',
            marginLeft: '30px'
          }}>
          <ExclamationCircleIcon
            style={{
              width: '20px',
              height: '20px',
              color: '#BE4242'
            }}
          />
          Career level is required
        </p>
      )}
    </Grid>
  );
}
