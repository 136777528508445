import React, { Fragment, useState, useEffect, memo } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import { HeightSpacer } from '../../components/Spacer/styles';
import {
  TabsStyled,
  TabStyled,
  ButtonText,
  InfoPlaceholder,
  TalentInfoContainer
} from './styles';
import ResumeFilters from './ResumeFilters';
import TalentCard from './TalentCard';
import Feedback from './Feedback';
import { MUIButtonStyled } from '../../components/MUIButton';
import {
  clearBookmarkedTalentsFilters,
  clearBookmarkedTalentsParams,
  clearUnlockedTalentsFilters,
  clearUnlockedTalentsParams,
  getBookmarkedTalents,
  getTalents,
  getUnlockedTalents,
  updateBookmarkedCount,
  updateBookmarkedCurrentPage,
  updateChannelsUnreadMessageCount,
  updateCurrentTalent,
  updateDoneFetchingBookmarkedTalentStatus,
  updateDoneFetchingUnlockedTalentStatus,
  updateInsufficientCoinsBanner,
  updateSearchResumeCurrentPage,
  updateSearchTalentFilters,
  updateUnlockedCount,
  updateUnlockedCurrentPage,
  updateFeedbackForm,
  updateFeedbackCardStatus,
  updateFeedbackPage,
  updateNewSearch,
  updateTalentsList
} from '../../redux/actions/talent_action';
import {
  fetchAccountInfo,
  fetchMyCompanySettings
} from '../../redux/actions/company_actions';
import { useSelector, useDispatch } from 'react-redux';
import store from '../../redux/stores/store';
import BookmarkedFilters from './BookmarkedFilters';
import UnlockedFilters from './UnlockedFilters';
import moment from 'moment';
import { Grid } from '@mui/material';
import { EmptyStates } from './EmptyStates';
import { NoResultsAlert } from '../../components/NoResults';
import { InsufficientCoinsAlert } from '../../components/InsufficientCoins';
import CandidateProfilePopup, {
  Content
} from './PopUpsWrapper/CandidateProfilePopup';
import { PopUpDialogs } from './PopUpDialogs';
import { openInNewTab } from '../../utils/WindowUtils';
import ResumeSearchPagination from './ResumeSearchPagination';
import BookmarkPagination from './BookmarkPagination';
import UnlockedResumePagination from './UnlockedResumePagination';
import TalentSearchCoinBlack from '../../assets/icons/talent-search/talent_search_coin_black.svg';
import FAQPopup from './FAQPopup';
import talentInfoPlaceholder from '../../assets/images/talent-search/talent_info_placeholder.svg';
import { TalentInfoCardSkeleton } from '../../components/Skeletons/TalentInfoCard';
import { mutateCart } from '../../redux/actions/order_action';
import { isEmpty } from 'lodash';

const TalentSearch = () => {
  const profilePopUp = useSelector(state => state.talents.profilePopUp);

  useEffect(() => {
    if (profilePopUp) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [profilePopUp]);

  return (
    <DefaultLayout>
      <div
        style={{
          background: '#f5f5f5',
          maxWidth: '1920px',
          padding: '0 3% 24px',
          margin: 'auto'
        }}>
        <ACVTabs />
        <CandidateProfilePopup />
        {/* {profilePopUp ? <CandidateProfilePopup /> : null} */}
        <PopUpDialogs />
        <FAQPopup />
      </div>
    </DefaultLayout>
  );
};

export default TalentSearch;

export const TabPanel = React.memo(props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${value}-${index}`}
      aria-labelledby={`${value}-${index}`}
      {...other}>
      {value === index && (
        <div sx={{ p: 3 }}>
          <span>{children}</span>
        </div>
      )}
    </div>
  );
});

export const ACVTabs = memo(() => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('search-resume');

  const myCompanySettings = useSelector(
    state => state.companies.myCompanySettings
  );
  const coins = useSelector(
    state => state.companies.myCompanyStatus.availableCoins
  );

  const bookmarkedNumber = useSelector(state => state.talents.bookmarkedCount);
  const unlockedNumber = useSelector(state => state.talents.unlockedCount);

  useEffect(() => {
    triggerLoadTalents('search-resume', '', 1);
    dispatch(fetchAccountInfo());
  }, []);

  useEffect(() => {
    if (coins < 100) {
      dispatch(updateInsufficientCoinsBanner(true));
    }
  }, [coins]);

  const triggerLoadTalents = (type, direction, page) => {
    if (page == 1 && store.getState().talents.hideNoResultFilter) {
      store.getState().talents.hideNoResultFilter = false;
    }

    switch (type) {
      case 'search-resume':
        {
          if (
            page == 1 &&
            store.getState().talents.searchResumeTalentsCurrentPage != 1
          ) {
            store.getState().talents.searchResumeTalentsCurrentPage = 1;
          }

          if (page == 1) {
            store.getState().talents.talentsFilterNoResult = false;
          }

          let cFilters = { ...store.getState().talents.searchTalentFilters };

          let pageInfo = { ...store.getState().talents.talentsPageInfo };

          if (direction == 'next') {
            delete cFilters.before;

            cFilters.after = pageInfo.endCursor;
          } else {
            if (cFilters.after) {
              delete cFilters.after;
            }

            cFilters.before = pageInfo.startCursor;
          }

          if (page == 1) {
            delete cFilters.before;

            cFilters.after = '';
          }

          dispatch(updateSearchTalentFilters(cFilters)).then(() => {
            if (store.getState().talents.talentsFilterNoResult) {
              const params = {
                after: '',
                filterMode: 'or',
                keyword: '',
                wordMatch: 'word',
                advancedSearch: true,
                trackIds: [],
                specialisations: [],
                specialisationIds: [],
                industryIds: [],
                languages: [],
                nationalities: [],
                withVideoCoverLetter: false,
                freshGrad: null,
                // minGraduationYear: moment().year() - 100,
                // maxGraduationYear: moment().year(),
                sort: null,
                educationLevels: [],
                resumeLastUpdated: null,
                minYearExperience: 0,
                showHiddenProfile: store.getState().talents.searchTalentFilters[
                  'showHiddenProfile'
                ]
                  ? true
                  : false
              };

              if (direction == 'next') {
                delete params.before;

                params.after = pageInfo.endCursor;
              } else {
                if (params.after) {
                  delete params.after;
                }

                params.before = pageInfo.startCursor;
              }

              if (page == 1) {
                delete params.before;

                params.after = '';
              }

              dispatch(getTalents(params));
            } else {
              dispatch(getTalents(cFilters));
            }

            store.getState().talents.lastRefreshed = moment().format(
              'DD/MM/YYYY HH:mm:ss'
            );

            setTimeout(() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }, 200);
          });
        }
        break;
      case 'bookmarked':
        {
          if (
            page == 1 &&
            store.getState().talents.bookmarkedTalentsCurrentPage != 1
          ) {
            store.getState().talents.bookmarkedTalentsCurrentPage = 1;
          }

          if (page == 1) {
            store.getState().talents.bookmarkedFilterNoResult = false;
          }

          let checkInsideFilterNoResult = true;
          let params = {
            ...store.getState().talents.bookmarkedTalentFilters,
            page: page
          };

          const pageInfo = {
            ...store.getState().talents.bookmarkedTalentsPageInfo
          };

          if (params.bookmarkedDate) {
            params.bookmarkedDate = moment(
              params.bookmarkedDate.toLocaleString()
            ).unix();
          }

          if (store.getState().talents.bookmarkedFilterNoResult) {
            checkInsideFilterNoResult = false;
            params = {
              page: page,
              after: '',
              filterMode: 'or',
              keyword: '',
              wordMatch: 'word',
              bookmarkedDate: null,
              bookmarkedByIds: [],
              sort: {
                by: 'created_at',
                direction: 'desc'
              }
            };
          }

          if (pageInfo?.endCursor != undefined && direction) {
            if (direction == 'next') {
              delete params.before;
              params.after = pageInfo.endCursor;
            } else {
              delete params.after;
              params.before = pageInfo.startCursor;
            }
          }

          if (page == 1) {
            params.after = '';
            delete params.before;
          }

          dispatch(getBookmarkedTalents(params)).then(res => {
            if (
              store.getState().talents.bookmarkedFilterNoResult &&
              checkInsideFilterNoResult
            ) {
              // trigger the default search without filter applied
              params = {
                page: 1,
                after: '',
                filterMode: 'or',
                keyword: '',
                wordMatch: 'word',
                bookmarkedDate: null,
                bookmarkedByIds: [],
                sort: {
                  by: 'created_at',
                  direction: 'desc'
                }
              };

              dispatch(getBookmarkedTalents(params));
            } else {
              if (
                res?.payload?.filterParams?.page > 1 &&
                res?.payload?.bookmarkedTalents?.edges?.length == 0
              ) {
                // trigger search with prevEndCursor
                dispatch(
                  updateBookmarkedCurrentPage(res.payload.filterParams.page - 1)
                );

                const currentPage = res.payload.filterParams.page - 1;
                params = {
                  ...res.payload.filterParams,
                  page: res.payload.filterParams.page - 1,
                  after:
                    store.getState().talents.bookmarkedEndCursors[
                      `${currentPage}`
                    ] ?? ''
                };

                dispatch(getBookmarkedTalents(params));
              }
            }
          });

          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }, 200);
        }
        break;
      case 'unlocked':
        {
          if (
            page == 1 &&
            store.getState().talents.unlockedTalentsCurrentPage != 1
          ) {
            store.getState().talents.unlockedTalentsCurrentPage = 1;
          }

          if (page == 1) {
            store.getState().talents.unlockedFilterNoResult = false;
          }

          let checkInsideFilterNoResult = true;
          let params = {
            ...store.getState().talents.unlockedTalentFilters
          };

          const pageInfo = {
            ...store.getState().talents.unlockedTalentsPageInfo
          };

          if (params.unlockedDate) {
            params.unlockedDate = moment(
              params.unlockedDate.toLocaleString()
            ).unix();
          }

          if (store.getState().talents.unlockedFilterNoResult) {
            checkInsideFilterNoResult = false;
            params = {
              after: '',
              filterMode: 'or',
              keyword: '',
              wordMatch: 'word',
              unlockedDate: null,
              unlockedByIds: [],
              sort: {
                by: 'created_at',
                direction: 'desc'
              }
            };
          }

          if (pageInfo?.endCursor != undefined) {
            if (direction == 'next') {
              delete params.before;
              params.after = pageInfo.endCursor;
            } else {
              delete params.after;
              params.before = pageInfo.startCursor;
            }
          }

          if (page == 1) {
            params.after = '';
            delete params.before;
          }

          dispatch(getUnlockedTalents(params)).then(() => {
            if (
              store.getState().talents.unlockedFilterNoResult &&
              checkInsideFilterNoResult
            ) {
              // trigger the default search without filter applied
              params = {
                after: '',
                filterMode: 'or',
                keyword: '',
                wordMatch: 'word',
                unlockedDate: null,
                unlockedByIds: [],
                sort: {
                  by: 'created_at',
                  direction: 'desc'
                }
              };

              dispatch(getUnlockedTalents(params));
            }
          });

          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }, 200);
        }
        break;
    }
  };

  const triggerClearFilters = type => {
    switch (type) {
      case 'search-resume':
        {
          //
        }
        break;
      case 'bookmarked':
        {
          dispatch(clearBookmarkedTalentsFilters());
        }
        break;
      case 'unlocked':
        {
          dispatch(clearUnlockedTalentsFilters());
        }
        break;
    }
  };

  const clearParamsByType = type => {
    switch (type) {
      case 'search-resume':
        {
          dispatch(updateSearchResumeCurrentPage(1));
        }
        break;
      case 'bookmarked':
        {
          dispatch(clearBookmarkedTalentsParams());
          dispatch(updateBookmarkedCurrentPage(1));
        }
        break;
      case 'unlocked':
        {
          dispatch(clearUnlockedTalentsParams());
          dispatch(updateUnlockedCurrentPage(1));
        }
        break;
    }
  };

  const handleChange = (event, newValue) => {
    clearParamsByType(newValue);
    setValue(newValue);
    triggerLoadTalents(newValue, '', 1);
    dispatch(updateChannelsUnreadMessageCount([]));
    dispatch(updateCurrentTalent({}));
  };

  const onNavigate = async () => {
    openInNewTab('/purchase/choose/?tab=talent_search&products=coins_x5000-1');
    // TALENT SEARCH TRACKING
    window.dataLayer.push({
      event: 'CE_click-purchase-coin-button'
    });
  };

  useEffect(() => {
    // Getting company setting for company team members
    dispatch(fetchMyCompanySettings()).then(res => {
      if (res?.companySettings) {
        dispatch(
          updateBookmarkedCount(res.companySettings.bookmarkedResumesCount)
        );
        dispatch(updateUnlockedCount(res.companySettings.unlockedResumesCount));
      }
    });

    return () => {
      // ComponentWillUnmount
      dispatch(updateDoneFetchingBookmarkedTalentStatus(false));
      dispatch(updateDoneFetchingUnlockedTalentStatus(false));
    };
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          position: 'sticky',
          top: '64px',
          zIndex: 10,
          background: 'rgb(245, 245, 245)',
          padding: '16px 0'
        }}>
        <TabsStyled
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: '#512acc'
            }
          }}
          aria-label="tabs">
          <TabStyled
            value="search-resume"
            label="Talent Search"
            $bold={value == 'search-resume' && true}
          />
          <TabStyled
            value="bookmarked"
            label={`Bookmarked ${
              myCompanySettings.bookmarkedResumesCount
                ? `(${bookmarkedNumber})`
                : ''
            }`}
            $bold={value == 'bookmarked' && true}
          />
          <TabStyled
            value="unlocked"
            label={`Unlocked ${
              myCompanySettings.unlockedResumesCount
                ? `(${unlockedNumber})`
                : ''
            }`}
            $bold={value == 'unlocked' && true}
          />
        </TabsStyled>

        <Grid display={'flex'} alignItems={'center'}>
          <TokensCount />
          <MUIButtonStyled
            $backgroundColor={'transparent'}
            label={
              <ButtonText>
                Purchase Tokens &nbsp;
                <img
                  src={TalentSearchCoinBlack}
                  style={{ width: '25px', height: '25px' }}
                />
              </ButtonText>
            }
            $padding={'6px 0'}
            $shadow={'none !important'}
            $width={'188px'}
            $outline={'1px solid #222'}
            $outlineWidth={'2px'}
            onClick={onNavigate}
          />
        </Grid>
      </div>

      <TabPanel value={value == 'search-resume' && 0} index={0}>
        <SearchResumePanel
          type={value}
          triggerLoadTalents={triggerLoadTalents}
        />
      </TabPanel>
      <TabPanel value={value == 'bookmarked' && 1} index={1}>
        <BookmarkedPanel
          type={value}
          triggerLoadTalents={triggerLoadTalents}
          triggerClearFilters={triggerClearFilters}
          bookmarkedNumber={bookmarkedNumber}
        />
      </TabPanel>
      <TabPanel value={value == 'unlocked' && 2} index={2}>
        <UnlockedPanel
          type={value}
          triggerLoadTalents={triggerLoadTalents}
          triggerClearFilters={triggerClearFilters}
          unlockedNumber={unlockedNumber}
        />
      </TabPanel>
    </>
  );
});

export const SearchResumePanel = props => {
  const { type, triggerLoadTalents } = props;

  const getTalentsByType = (type, state) => {
    switch (type) {
      case 'search-resume': {
        return state.talents.talents;
      }
      case 'bookmarked': {
        return state.talents.bookmarkedTalents;
      }
      case 'unlocked': {
        return state.talents.unlockedTalents;
      }
      default: {
        return state.talents.talents;
      }
    }
  };

  const getLoadingTalentsByType = (type, state) => {
    switch (type) {
      case 'search-resume': {
        return state.talents.fetchingTalents;
      }
      case 'bookmarked': {
        return state.talents.fetchingBookmarkedTalents;
      }
      case 'unlocked': {
        return state.talents.fetchingUnlockedTalents;
      }
      default: {
        return state.talents.fetchingTalents;
      }
    }
  };

  const talents = useSelector(state => getTalentsByType(type, state));

  const talent = useSelector(state => state.talents.talent);

  const loadingTalents = useSelector(state =>
    getLoadingTalentsByType(type, state)
  );

  return (
    <>
      <Grid display={'flex'} gap={'15px'}>
        <ResumeFilters triggerLoadTalents={triggerLoadTalents} />

        <Grid display={'flex'} flexDirection={'column'} width="100%">
          <InsufficientCoinsAlert />
          <Grid display={'flex'} gap={'15px'}>
            <div
              style={{
                width: isEmpty(talents?.length) ? '100%' : '50% !important'
              }}>
              {/* <ResumeSearch triggerLoadTalents={triggerLoadTalents} /> */}
              {/* <HeightSpacer height={'16px'} /> */}
              <KeywordAndHideProfile type={type} />

              <Fragment>
                <TalentCard
                  type={type}
                  triggerLoadTalents={triggerLoadTalents}
                />

                <Feedback loading={loadingTalents} />

                <HeightSpacer height={'16px'} />
              </Fragment>
              <ResumeSearchPagination
                type={type}
                triggerLoadTalents={triggerLoadTalents}
              />
            </div>

            {talents?.length > 0 && (
              <Grid sx={{ width: '100%' }}>
                <TalentInfoContainer>
                  {/* {loadingTalents && <TalentInfoCardSkeleton />} */}
                  {loadingTalents ||
                  (talents.length > 0 && Object.keys(talent)?.length == 0) ? (
                    <InfoPlaceholder>
                      <img
                        alt="talent-info-placeholder"
                        style={{ height: '100%', width: '100%' }}
                        src={talentInfoPlaceholder}
                      />
                    </InfoPlaceholder>
                  ) : (
                    <Fragment>
                      <Content />
                    </Fragment>
                  )}
                </TalentInfoContainer>
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* <ScrollTopButton /> */}
      </Grid>
    </>
  );
};

export const BookmarkedPanel = props => {
  const { type, triggerLoadTalents, triggerClearFilters, bookmarkedNumber } =
    props;

  const getTalentsByType = (type, state) => {
    switch (type) {
      case 'search-resume': {
        return state.talents.talents;
      }
      case 'bookmarked': {
        return state.talents.bookmarkedTalents;
      }
      case 'unlocked': {
        return state.talents.unlockedTalents;
      }
      default: {
        return state.talents.talents;
      }
    }
  };

  const getLoadingTalentsByType = (type, state) => {
    switch (type) {
      case 'search-resume': {
        return state.talents.fetchingTalents;
      }
      case 'bookmarked': {
        return state.talents.fetchingBookmarkedTalents;
      }
      case 'unlocked': {
        return state.talents.fetchingUnlockedTalents;
      }
      default: {
        return state.talents.fetchingTalents;
      }
    }
  };

  const talents = useSelector(state => getTalentsByType(type, state));
  // read bookmarked individual from talent object after talent card is clicked
  const talent = useSelector(state => state.talents.talent);
  const loadingTalents = useSelector(state =>
    getLoadingTalentsByType(type, state)
  );
  const currentPage = useSelector(
    state => state.talents.bookmarkedTalentsCurrentPage
  );
  const myCompanySettings = useSelector(
    state => state.companies.myCompanySettings
  );

  const showNoResultAlert = (settings, talents, isLoading, currentPage) => {
    if (isLoading) return false;

    if (
      settings.bookmarkedResumesCount > 0 &&
      talents.length == 0 &&
      currentPage == 1
    )
      return true;
  };

  return (
    <Fragment>
      <>
        <Grid display={'flex'} gap={'15px'}>
          <BookmarkedFilters
            triggerLoadTalents={triggerLoadTalents}
            triggerClearFilters={triggerClearFilters}
            type={type}
          />
          <Grid
            display={'flex'}
            flexDirection={'column'}
            width={'100%'}
            gap={'16px'}>
            <InsufficientCoinsAlert />
            <Grid display={'flex'} gap={'15px'} height={'100%'}>
              <div
                style={
                  talents?.length > 0
                    ? { width: '50%' }
                    : {
                        width: '100%',
                        display: 'flex',
                        justifyContent: showNoResultAlert(
                          myCompanySettings,
                          talents,
                          loadingTalents,
                          currentPage
                        )
                          ? 'flex-start'
                          : 'center',
                        flexDirection: 'column'
                      }
                }>
                <Grid display={'flex'} flexDirection={'column'} gap={'10px'}>
                  <KeywordAndHideProfile
                    type={type}
                    number={bookmarkedNumber}
                  />

                  <Fragment>
                    {bookmarkedNumber === 0 ? (
                      <EmptyStates type={type} />
                    ) : (
                      <Fragment>
                        <TalentCard type={type} />
                        <HeightSpacer height={'16px'} />
                      </Fragment>
                    )}
                  </Fragment>
                </Grid>

                {bookmarkedNumber > 0 && (
                  <BookmarkPagination
                    type={type}
                    triggerLoadTalents={triggerLoadTalents}
                  />
                )}
              </div>

              {talents.length > 0 ? (
                <Grid sx={{ width: '50%' }}>
                  <TalentInfoContainer>
                    {/* {loadingTalents && <TalentInfoCardSkeleton />} */}
                    {loadingTalents || Object.keys(talent)?.length == 0 ? (
                      <InfoPlaceholder>
                        <img
                          alt="talent-info-placeholder"
                          style={{ height: '100%', width: '100%' }}
                          src={talentInfoPlaceholder}
                        />
                      </InfoPlaceholder>
                    ) : (
                      <Fragment>
                        <Content />
                      </Fragment>
                    )}
                  </TalentInfoContainer>
                </Grid>
              ) : null}
            </Grid>

            {/* <ScrollTopButton /> */}
          </Grid>
        </Grid>
      </>
    </Fragment>
  );
};

export const UnlockedPanel = props => {
  const { type, triggerLoadTalents, triggerClearFilters, unlockedNumber } =
    props;

  const getTalentsByType = (type, state) => {
    switch (type) {
      case 'search-resume': {
        return state.talents.talents;
      }
      case 'bookmarked': {
        return state.talents.bookmarkedTalents;
      }
      case 'unlocked': {
        return state.talents.unlockedTalents;
      }
      default: {
        return state.talents.talents;
      }
    }
  };

  const getLoadingTalentsByType = (type, state) => {
    switch (type) {
      case 'search-resume': {
        return state.talents.fetchingTalents;
      }
      case 'bookmarked': {
        return state.talents.fetchingBookmarkedTalents;
      }
      case 'unlocked': {
        return state.talents.fetchingUnlockedTalents;
      }
      default: {
        return state.talents.fetchingTalents;
      }
    }
  };

  const talents = useSelector(state => getTalentsByType(type, state));
  // read unlocked individual from talent object after talent card is clicked
  const talent = useSelector(state => state.talents.talent);
  const loadingTalents = useSelector(state =>
    getLoadingTalentsByType(type, state)
  );
  const currentPage = useSelector(
    state => state.talents.unlockedTalentsCurrentPage
  );
  const myCompanySettings = useSelector(
    state => state.companies.myCompanySettings
  );

  const showNoResultAlert = (settings, talents, isLoading, currentPage) => {
    if (isLoading) return false;

    if (
      settings.unlockedResumesCount > 0 &&
      talents.length == 0 &&
      currentPage == 1
    )
      return true;
  };

  return (
    <Fragment>
      <Grid display={'flex'} gap={'20px'}>
        <UnlockedFilters
          triggerLoadTalents={triggerLoadTalents}
          triggerClearFilters={triggerClearFilters}
          type={type}
        />
        <Grid display={'flex'} flexDirection={'column'} width={'100%'}>
          <Grid display={'flex'} gap={'15px'} height={'100%'}>
            <div
              style={
                talents?.length > 0
                  ? { width: '50%' }
                  : {
                      width: '100%',
                      display: 'flex',
                      justifyContent: showNoResultAlert(
                        myCompanySettings,
                        talents,
                        loadingTalents,
                        currentPage
                      )
                        ? 'flex-start'
                        : 'center',
                      flexDirection: 'column'
                    }
              }>
              <Grid display={'flex'} flexDirection={'column'} gap={'8px'}>
                <KeywordAndHideProfile type={type} number={unlockedNumber} />

                <Fragment>
                  {unlockedNumber === 0 ? (
                    <EmptyStates type={type} />
                  ) : (
                    <Fragment>
                      <TalentCard type={type} />
                      <HeightSpacer height={'16px'} />
                    </Fragment>
                  )}
                </Fragment>
              </Grid>

              {unlockedNumber > 0 && (
                <UnlockedResumePagination
                  type={type}
                  triggerLoadTalents={triggerLoadTalents}
                />
              )}
            </div>
            {talents?.length > 0 ? (
              <Grid sx={{ width: '50%' }}>
                <TalentInfoContainer>
                  {/* {loadingTalents && <TalentInfoCardSkeleton />} */}
                  {loadingTalents || Object.keys(talent)?.length == 0 ? (
                    <InfoPlaceholder>
                      <img
                        alt="talent-info-placeholder"
                        style={{ height: '100%', width: '100%' }}
                        src={talentInfoPlaceholder}
                      />
                    </InfoPlaceholder>
                  ) : (
                    <Fragment>
                      <Content />
                    </Fragment>
                  )}
                </TalentInfoContainer>
              </Grid>
            ) : null}
          </Grid>
        </Grid>

        {/* <ScrollTopButton /> */}
      </Grid>
    </Fragment>
  );
};

export const KeywordAndHideProfile = props => {
  const { type, number } = props;

  const searchedKeyword = useSelector(state => state.talents.searchedKeyword);

  const getTalentsByType = (type, state) => {
    switch (type) {
      case 'search-resume': {
        return state.talents.talents;
      }
      case 'bookmarked': {
        return state.talents.bookmarkedTalents;
      }
      case 'unlocked': {
        return state.talents.unlockedTalents;
      }
      default: {
        return state.talents.talents;
      }
    }
  };

  const getLoadingTalentsByType = (type, state) => {
    switch (type) {
      case 'search-resume': {
        return state.talents.fetchingTalents;
      }
      case 'bookmarked': {
        return state.talents.fetchingBookmarkedTalents;
      }
      case 'unlocked': {
        return state.talents.fetchingUnlockedTalents;
      }
      default: {
        return state.talents.fetchingTalents;
      }
    }
  };

  const getTotalCountByType = (type, state) => {
    switch (type) {
      case 'search-resume': {
        return 0;
      }
      case 'bookmarked': {
        return state.talents.bookmarkedTalentsTotalCount;
      }
      case 'unlocked': {
        return state.talents.unlockedTalentsTotalCount;
      }
      default: {
        return 0;
      }
    }
  };

  const getCurrentPageByType = (type, state) => {
    switch (type) {
      case 'search-resume': {
        return 1;
      }
      case 'bookmarked': {
        return state.talents.bookmarkedTalentsCurrentPage;
      }
      case 'unlocked': {
        return state.talents.unlockedTalentsCurrentPage;
      }
      default: {
        return 1;
      }
    }
  };

  const totalCount = useSelector(state => getTotalCountByType(type, state));
  const talents = useSelector(state => getTalentsByType(type, state));
  const loadingTalents = useSelector(state =>
    getLoadingTalentsByType(type, state)
  );
  const currentPage = useSelector(state => getCurrentPageByType(type, state));
  const startFrom = (currentPage - 1) * 10 + 1;
  const endAt = (currentPage - 1) * 10 + talents.length;

  const getText = (type, talents) => {
    if (type == 'bookmarked') {
      return `Showing ${
        loadingTalents
          ? '...'
          : talents.length == 0
          ? '0 result'
          : `${startFrom} - ${endAt} of ${totalCount}`
      }`;
    } else if (type == 'unlocked') {
      return `Showing ${
        loadingTalents
          ? '...'
          : talents.length == 0
          ? '0 result'
          : `${startFrom} - ${endAt} of ${totalCount}`
      }`;
    }

    return '';
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
      }}>
      <NoResultsAlert search={searchedKeyword} section={type} />
      <span
        style={{
          paddingLeft: '10px',
          fontSize: '14px',
          letterSpacing: '0.15px',
          fontFamily: 'Inter',
          color: 'rgba(0, 0, 0, 0.6)'
        }}>
        {(type === 'bookmarked' || type === 'unlocked') && number === 0
          ? ``
          : getText(type, talents)}
      </span>
    </div>
  );
};

export const TokensCount = () => {
  const myCompanyStatus = useSelector(state => state.companies.myCompanyStatus);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingRight: '24px',
        gap: '4px'
      }}>
      <span
        style={{
          fontFamily: 'Inter',
          fontSize: '20px',
          fontWeight: '700',
          color: '#512acc'
        }}>
        {myCompanyStatus.availableCoins}
      </span>
      <span
        style={{
          paddingLeft: '8px',
          fontFamily: 'Inter',
          fontSize: '14px',
          color: 'rgba(0, 0, 0, 0.6)'
        }}>
        tokens available
      </span>
    </div>
  );
};
