import { styled as styling } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Tab, CardMedia } from '@mui/material';
import Colors from '../../../styles/colors.module.scss';

export const MainContainer = styling('div')({
  height: '100%'
});

export const TabIndicator = styling('div')({
  border: '2px solid #efefef',
  width: '100%',
  margin: 'auto',
  transform: 'translateY(-2px)'
});

export const InfoContainer = styling('div')({
  // padding: '15px 10px 25px 10px',
  padding: '20px 20px 24px 20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export const SubInfoContainer = styling('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 50%)',
  width: '100%'
});

export const LabelContainer = styling('div')({
  display: 'grid',
  width: '100%'
});

export const BoldText = styling(Typography)(
  ({ $smallPadding, $noPadding }) => ({
    fontWeight: '600',
    fontSize: '12px',
    fontFamily: 'Inter',
    padding: $noPadding ? '0' : $smallPadding ? '5px 0' : '10px 0'
  })
);

export const SectionLabel = styling(Typography)({
  color: '#40249B',
  fontWeight: '400',
  fontSize: '10px',
  fontFamily: 'Inter',
  lineHeight: '20px',
  letterSpacing: '1.5px',
  textTransform: 'uppercase',
  padding: '0'
});

export const NormalText = styling(Typography)(
  ({ $smallPadding, $noPadding, $list }) => ({
    fontSize: '12px',
    fontFamily: 'Inter',
    padding: $noPadding ? '0' : $smallPadding ? '5px 0' : '10px 0',

    ...($list && {
      display: 'list-item',
      listStyleType: 'disc',
      listStylePosition: 'inside',
      padding: '1px 0'
    })
  })
);

export const TabPanelStyled = styling('div')(
  ({ panel_index, label, show_hired_card }) => ({
    padding: '0 20px',

    ...(panel_index == '0' &&
      label !== 'chat-subtab' && {
        overflow: 'auto',
        maxHeight: 'calc(79vh - 265px)'
      }),

    ...(panel_index == '1' &&
      label !== 'chat-subtab' && {
        padding: '20px 20px 25px'
      }),

    ...(panel_index == '2' &&
      label !== 'chat-subtab' && {
        padding: 0,
        maxHeight: 'calc(79vh - 265px)',
        overflow: 'auto',
        ...(show_hired_card == 'true' && {
          maxHeight: 'calc(79vh - 320px)'
        })
      }),

    ...(panel_index == '3' &&
      label !== 'chat-subtab' && {
        padding: 0
      })
  })
);

export const TabStyled = styling(Tab)(props => ({
  ...(props.isHired && {
    margin: '40px 20px 0',
    textTransform: 'none',
    fontWeight: 700,
    fontFamily: 'Inter',
    fontSize: '13px',
    color: '#000',
    minWidth: 'auto',
    padding: 0,

    '&.Mui-selected': {
      color: '#000'
    },

    '&.mui-selected': {
      color: '#fff'
    },
    '&.mui-focusvisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)'
    },

    '@media (max-width: 480px)': {
      margin: '40px 15px 0'
    }
  }),
  ...(!props.isHired && {
    margin: '0 20px',
    textTransform: 'none',
    fontWeight: 700,
    fontFamily: 'Inter',
    fontSize: '13px',
    color: '#000',
    minWidth: 'auto',
    padding: 0,

    '&.Mui-selected': {
      color: '#000'
    },

    '&.mui-selected': {
      color: '#fff'
    },
    '&.mui-focusvisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)'
    },
    '@media (max-width: 480px)': {
      margin: '0 15px'
    }
  })
}));

export const ChatSubtabStyled = styling(Tab)(props => ({
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '10px',
  letterSpacing: '1.5px',

  ...(props.chatSubtabIndex == 0 && {
    marginLeft: '30px'
  }),

  ...((props.id == 'ats-chat-history' || props.id == 'ats-aivi-summary') &&
  props.chatSubtabIndex == props.chatSubtabValue
    ? {
        '&.Mui-selected': {
          color: Colors.priBlack
        }
      }
    : {
        color: 'rgba(0, 0, 0, 0.38)'
      })
}));

export const CardMediaStyled = styling(CardMedia)(({ show_hired_card }) => ({
  maxHeight: 'calc(75vh - 282px)',
  ...(show_hired_card && {
    maxHeight: 'calc(75vh - 300px)'
  })
}));

export const NoVCL = styling('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

export const NoInterview = styling('div')({
  paddingTop: '20px',
  display: 'flex',
  justifyContent: 'center'
});

export const BETAText = styling('span')({
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: '8px',
  letterSpacing: '1.5px',
  color: '#213db5'
});

export const IconStyle = {
  width: '12.25px',
  height: '12.25px',
  marginRight: '8px'
};

export const SectionContainer = styling('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px',
  alignSelf: 'stretch'
});

export const SubContainer = styling('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  width: '100%'
});

