import { styled as styling } from '@mui/material/styles';
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { XMarkIcon } from '@heroicons/react/24/solid';

export const FilterTitle = styling('span')({
 fontSize: '14px',
 fontWeight: 700,
});

// SPECIALISATION REVAMP STYLES
export const TextFieldStyled = styling(TextField)({
  //
});

export const SelectTextStyled = styling(Typography)({
  fontWeight: 400,
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: 'rgba(0,0,0, 0.6)',
  height: '25px'
});

export const GridStyled = styling(Grid)({
  //
});

export const TextButtonStyled = styling(Button)({
  fontWeight: 400,
  fontFamily: 'Inter',
  fontSize: '12px',
  lineHeight: '20px',
  textTransform: 'none'
});

export const CheckboxStyled = styling(Checkbox)({
  //
});

export const IconButtonStyled = styling(IconButton)({
  //
});

export const CategoryPill = styling(Grid)({
  minWidth: '35px',
  maxWidth: '130px',
  width: 'fit-content',
  gap: '6px',
  borderRadius: '4px',
  background: '#D8D8D8',
  padding: '2px 6px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '25px'
});

export const CategoryPillTitle = styling(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '12px',
  fontFamily: 'Inter',
  lineHeight: '20px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontWeight: '400',
  margin: 0,
  whiteSpace: 'nowrap'
});

export const XMarkStyled = styling(XMarkIcon)({
  color: 'rgba(0,0,0,0.87)'
});

export const SelectStyled = styling(Select)({
  '& .MuiSelect-select': {
    padding: "10px 16px"
  },
});
