import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon
} from '@mui/icons-material';
import { Grid, IconButton, Skeleton } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MUIButtonStyled } from '../../../components/MUIButton';
import { ErrorToast } from '../../../utils/ToastUtils';
import TalentInfoCard from './TalentInfoCard';
import TalentResume from './TalentResume';
import styles from './index.module.scss';
import { downloadResume } from '../../../redux/actions/talent_action';
import { convertAndDownloadResume } from '../../../hooks/useDownload';

const TalentInformation = ({
  talent,
  talents,
  isSelected,
  setIsSelected,
  setTalentColumnHeight,
  isFirsItem,
  isLastItem,
  loading,
  getCurrentTalents,
  setCurrentTalents,
  setTalents,
  accData,
  isLoadingHiddenData,
  setIsLoadingHiddenData
}) => {
  const heightRef = useRef(null);

  const isTalentsEmpty = talents.length === 0;
  const isRenderSelected = talents[isSelected]?.node?.id === talent?.id;

  const talentsColumnRef = useSelector(state => state.talents.talentsColumnRef);

  const onGetHeight = () => {
    setTalentColumnHeight(heightRef?.current?.clientHeight);
  };

  const saveFile = () => {
    try {
      window.dataLayer.push({
        event: 'CE_click-download-resume-acv'
      });

      if (talent?.resume) {
        const params = {
          userId: talent?.id
        };

        dispatch(downloadResume(params)).then(res => {
          convertAndDownloadResume(res, talent?.user);
        });
      }
    } catch {}
  };

  const updateTalentViewedStatus = async (
    talents,
    isSelected,
    getCurrentTalents,
    setCurrentTalents,
    setTalents,
    next
  ) => {
    const dispatch = useDispatch();
    let latestSelectedIndex = next ? isSelected + 1 : isSelected - 1;

    if (!talents[latestSelectedIndex]?.node?.viewed) {
      setIsLoadingHiddenData(true);

      const res = await dispatch(
        mutateViewTalent({
          input: { userId: talents[latestSelectedIndex]?.node?.id }
        })
      );

      let currentTalents = [...getCurrentTalents()];
      currentTalents[latestSelectedIndex] = {
        ...currentTalents[latestSelectedIndex],
        node: {
          ...res.viewTalent.user
        }
      };

      setCurrentTalents(currentTalents);
      setTalents(currentTalents);
      setIsLoadingHiddenData(false);
    }
  };

  const scrollToCard = (index, previous) => {
    if (talentsColumnRef?.current) {
      talentsColumnRef?.current.scrollTo(
        0,
        previous
          ? talentsColumnRef?.current?.children[0].children[0].children[0]
              .children[isSelected].offsetTop - 650
          : talentsColumnRef?.current?.children[0].children[0].children[0]
              .children[isSelected].offsetTop - 100
      );
    }
  };

  const reachedACVLimit = () => {
    if (accData?.activeCvAccessUsed >= accData?.activeCvAccessLimit) {
      return true;
    }

    return false;
  };

  const handleBackArrow = isSelected => {
    try {
      if (isSelected > 0) {
        const previousIndex = isSelected - 1;

        if (!talents[previousIndex]?.node?.viewed) {
          if (reachedACVLimit()) {
            const errorMessage = 'Active CV Access Limit Reached';
            ErrorToast(errorMessage);
            return;
          }
        }

        setIsSelected(isSelected - 1);
        scrollToCard(isSelected, true);

        window.dataLayer.push({
          event: 'acv-previous-applicant'
        });

        updateTalentViewedStatus(
          talents,
          isSelected,
          getCurrentTalents,
          setCurrentTalents,
          setTalents,
          false
        );
      }
    } catch {}
  };

  const handleNextArrow = isSelected => {
    try {
      if (isSelected < talents?.length - 1) {
        const nextIndex = isSelected + 1;

        if (!talents[nextIndex]?.node?.viewed) {
          if (reachedACVLimit()) {
            const errorMessage = 'Active CV Access Limit Reached';
            ErrorToast(errorMessage);
            return;
          }
        }

        setIsSelected(isSelected + 1);
        scrollToCard(isSelected, false);

        window.dataLayer.push({
          event: 'acv-next-applicant'
        });

        updateTalentViewedStatus(
          talents,
          isSelected,
          getCurrentTalents,
          setCurrentTalents,
          setTalents,
          true
        );
      }
    } catch {}
  };

  useEffect(() => {
    setTimeout(() => {
      onGetHeight();
    }, 1000);
  }, [heightRef?.current?.clientHeight, isSelected]);

  return (
    <div ref={heightRef}>
      <Grid container className={styles.talentToolBarContainer}>
        <Grid item xs={5}>
          {isLoadingHiddenData || isTalentsEmpty ? (
            isSelected === null &&
            loading && (
              <div>
                <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={250} />
              </div>
            )
          ) : (
            <div className={styles.nameContainer}>{talent?.name}</div>
          )}
        </Grid>

        <Grid item xs={5} />
        <Grid item xs={2}>
          {isLoadingHiddenData || isTalentsEmpty || loading ? null : (
            <div className={styles.iconContainer}>
              <IconButton
                disabled={loading || isFirsItem}
                onClick={() => handleBackArrow(isSelected)}>
                <ArrowBackIosIcon className={styles.icon} />
              </IconButton>
              <IconButton
                disabled={loading || isLastItem}
                onClick={() => handleNextArrow(isSelected)}>
                <ArrowForwardIosIcon className={styles.icon} />
              </IconButton>
            </div>
          )}
        </Grid>
      </Grid>
      {isRenderSelected && !isLoadingHiddenData && talent && (
        <TalentInfoCard
          talent={talent}
          isTalentsEmpty={isTalentsEmpty}
          loading={loading}
          isSelected={isSelected}
        />
      )}

      {talent && talent?.resume && !isLoadingHiddenData && (
        <div className={styles.alignRight}>
          <MUIButtonStyled
            label="Download resume"
            onClick={saveFile}
            large
            outlinedBlack
          />
          {/* <Button
            id="acv-download-resume-button"
            color="TRANSPARENT"
            size="BIG"
            onClick={saveFile}>
            Download resume
          </Button> */}
        </div>
      )}

      {isRenderSelected && talent && talent.resume && !isLoadingHiddenData && (
        <TalentResume
          talent={talent}
          onGetHeight={() => onGetHeight}
          loading={loading}
          isSelected={isSelected}
        />
      )}
    </div>
  );
};

export default TalentInformation;
