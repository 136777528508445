import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as AiviIcon } from '../../../../../assets/common/aivi-icon.svg';
import { MUIButtonStyled } from '../../../../../components/MUIButton';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import store from '../../../../../redux/stores/store';
import { updateApplicationParams } from '../../../../../redux/actions/application_action';
import { fetchSpecialisation } from '../../../../../redux/actions/job_action';
import { highestEduLevel } from '../../../../../utils/Constants';
import {
  AccordionDetailsColumnStyled,
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryStyled,
  ButtonContainerStyled,
  ClearAllStyled,
  DividerStyled,
  FilterBodyStyled,
  GridContainerStyled,
  GridItemStyled,
  MainCategoryTitle,
  PopoverContainerStyled,
  PrettoSliderStyled,
  SectionTitleStyled,
  SliderContainerStyled,
  SubCategoryContainerStyled,
  TitleContainerStyled
} from '../../../../../components/SharedAdvancedFilter/styles';
import SharedFormCheckbox from '../../../../../components/SharedAdvancedFilter/SharedFormCheckbox';
import {
  LOCATION_ALL_LABEL,
  NATIONALITY_OPTIONS,
  WORKPLACE_TYPE,
  WORK_RIGHTS
} from '../../../../../components/SharedAdvancedFilter/constants';
import SharedFormRadioGroup from '../../../../../components/SharedAdvancedFilter/SharedFormRadioGroup';
import { FormControl, FormGroup, Grid, Popover } from '@mui/material';
import { getLocalStorageItem } from '../../../../../helpers/local_storage_utils';
import { countryOptionsFullName } from '../../../../../components/SharedSelectCountry/constants';

const country = getLocalStorageItem('country');

const AdvancedFilter = ({
  setAdvancedFilter,
  setIsRefresh,
  advancedFilter,
  anchorEl,
  setAnchorEl,
  searchType,
  setSearchType,
  setIsSelected,
  applicationStatus,
  isAts,
  allCountryStateRegions
}) => {
  const [expandAccordion, setExpandAccordion] = useState(null);
  const [filters, setFilters] = useState({
    yearsOfExp: 0,
    videoCoverLetter: false,
    nationalities: advancedFilter.nationalities || '',
    currentLocation: [],
    relocate: false,
    remote: false,
    openToSponsor: false,
    visaOrEmploymentPass: false,
    localCitizen: false,
    highestEduLevel: [],
    trackIds: [],
    hideViewed: false,
    chatbotStatus: ''
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSpecialisation());
  }, []);

  useEffect(() => {
    if (!advancedFilter) return;

    setFilters({ ...advancedFilter });
  }, [advancedFilter]);

  const specialisation = useSelector(state => state.jobs.specialisation);

  const subcategories = useSelector(
    state => state.applications.applicationParams.trackIds
  );

  const getSubCategoriesCount = majorCategory => {
    let count = 0;
    if (subcategories.length > 0) {
      subcategories.map(subcat => {
        majorCategory.subCategories.map(mSubs => {
          if (subcat === mSubs.id) {
            count = count + 1;
          }
        });
      });
    }

    return count;
  };

  const handleClose = isApplied => () => {
    setAnchorEl(null);

    if (!isApplied) {
      setFilters({
        yearsOfExp:
          advancedFilter.yearsOfExp === null ? 0 : advancedFilter.yearsOfExp,
        videoCoverLetter:
          advancedFilter.videoCoverLetter === null
            ? false
            : advancedFilter.videoCoverLetter,
        nationalities: advancedFilter.nationalities || '',
        highestEduLevel: advancedFilter.highestEduLevel,
        trackIds: advancedFilter.trackIds,
        hideViewed:
          advancedFilter.hideViewed == null ? false : advancedFilter.hideViewed,
        chatbotStatus: advancedFilter.chatbotStatus
          ? ''
          : advancedFilter.chatbotStatus
      });
    }
  };

  const open = Boolean(anchorEl);

  const marks = [
    {
      value: 0,
      label: '0'
    },
    {
      value: 100,
      label: '10+'
    }
  ];

  const valueLabelFormat = value => {
    if (value === 0 || value === 1 || value === 100) return '';
    return value / 10;
  };

  const onSliderChange = (event, newValue) => {
    setFilters({
      ...filters,
      yearsOfExp: newValue
    });
  };

  const onClickHighEduLevel = id => {
    if (!filters?.highestEduLevel?.includes(id)) {
      setFilters({
        ...filters,
        highestEduLevel: [...filters?.highestEduLevel, id]
      });
    } else {
      const removedArray = filters?.highestEduLevel.filter(item => item !== id);
      setFilters({
        ...filters,
        highestEduLevel: removedArray
      });
    }
  };

  const onClickJobTracks = id => {
    let cApplicationParams = {
      ...store.getState().applications.applicationParams
    };

    const intId = Number(id);

    if (cApplicationParams?.trackIds?.indexOf(intId) > -1) {
      const removedArray = cApplicationParams?.trackIds.filter(
        item => item !== intId
      );

      cApplicationParams.trackIds = removedArray;

      setFilters({
        ...filters,
        trackIds: [...new Set(removedArray)]
      });
    } else {
      cApplicationParams.trackIds = [
        ...store.getState().applications.applicationParams.trackIds,
        intId
      ];

      const newArr = [...cApplicationParams?.trackIds, intId];

      setFilters({
        ...filters,
        trackIds: [...new Set(newArr)]
      });
    }

    dispatch(updateApplicationParams(cApplicationParams));
  };

  const onApplyAdvancedFilter = () => {
    if (setIsRefresh) {
      setIsRefresh(true);
    }

    setAdvancedFilter({
      ...filters,
      ...(isAts
        ? {
            searchText: advancedFilter.searchText,
            searchType: searchType,
            sortDirection: advancedFilter.sortDirection
          }
        : null),
      doneGettingJob: advancedFilter.doneGettingJob
    });
    handleClose(true)();

    if (setIsSelected) {
      setIsSelected(null);
    }
  };

  const resetAdvanceFilter = () => {
    const applicationParams = {
      ...store.getState().applications.applicationParams,
      trackIds: []
    };

    dispatch(updateApplicationParams(applicationParams));
    setFilters({
      yearsOfExp: 0,
      videoCoverLetter: false,
      highestEduLevel: [],
      trackIds: [],
      hideViewed: false,
      chatbotStatus: ''
    });
  };

  const onSetSearchType = value => {
    setSearchType(value);
  };

  const handleCompleteAivi = event => {
    if (event.target.checked) {
      setFilters({
        ...filters,
        chatbotStatus: '12'
      });
    } else {
      setFilters({
        ...filters,
        chatbotStatus: ''
      });
    }
  };

  const onCurrentLocation = e => {
    const cFilters = {
      ...filters
    };

    const value = e.target.value;
    const isChecked = e.target.checked;

    const key = value == 'all-my' ? 'my' : value == 'all-sg' ? 'sg' : 'my';

    let titles = allCountryStateRegions[key].map(region => region.title);

    if (value == 'all-my' || value == 'all-sg') {
      if (isChecked) {
        const newLocations = titles?.filter(
          location => !cFilters.currentLocation?.includes(location)
        );

        cFilters.currentLocation?.push(...newLocations);
      } else {
        cFilters.currentLocation = cFilters.currentLocation?.filter(
          v => !titles?.includes(v)
        );
      }
    } else {
      if (isChecked) {
        cFilters.currentLocation?.push(value);
      } else {
        cFilters.currentLocation = cFilters.currentLocation?.filter(
          v => v !== value
        );
      }
    }

    setFilters({ ...cFilters });
  };

  const onNationality = value => {
    filters.nationalities = value;
    setFilters({ ...filters });
  };

  const onWorkplacePreference = e => {
    const value = e.target.value;
    switch (value) {
      case WORKPLACE_TYPE.RELOCATE:
        filters.relocate = e.target.checked;
        break;
      case WORKPLACE_TYPE.REMOTE:
        filters.remote = e.target.checked;
        break;
    }
    setFilters({ ...filters });
  };

  const onRightsPreference = e => {
    const value = e.target.value;
    switch (value) {
      case WORK_RIGHTS.SPONSOR:
        filters.openToSponsor = e.target.checked;
        break;
      case WORK_RIGHTS.VISA:
        filters.visaOrEmploymentPass = e.target.checked;
        break;
      case WORK_RIGHTS.LOCAL:
        filters.localCitizen = e.target.checked;
        break;
    }

    setFilters({ ...filters });
  };

  const getSelectedLocationCount = key => {
    const cFilters = {
      ...filters
    };

    let titles = allCountryStateRegions[key].map(region => region.title);

    const locations = titles?.filter(location =>
      cFilters.currentLocation?.includes(location)
    );

    return locations?.length;
  };

  const renderSharedFormCheckboxes = (
    stateRegions,
    start,
    end,
    applicationParams,
    onCurrentLocation
  ) => {
    return stateRegions.slice(start, end).map(stateRegion => {
      return (
        <SharedFormCheckbox
          key={stateRegion.id}
          value={stateRegion.title}
          checked={applicationParams.currentLocation?.includes(
            stateRegion.title
          )}
          onChange={onCurrentLocation}
          label={stateRegion.title}
        />
      );
    });
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      PaperProps={{
        style: {
          borderRadius: '20px'
        }
      }}
      style={{ maxHeight: '70vh' }}>
      <PopoverContainerStyled>
        <TitleContainerStyled>
          <p className="title">Advanced Filter</p>
          <ClearAllStyled onClick={resetAdvanceFilter}>
            Clear All
          </ClearAllStyled>
        </TitleContainerStyled>

        <FilterBodyStyled>
          <GridContainerStyled $marginTop={'0px'}>
            <FormControl component="fieldset">
              <FormControl style={{ display: 'grid' }}>
                <SharedFormCheckbox
                  checked={searchType === 'any'}
                  onClick={() => onSetSearchType('any')}
                  label={'Any of the words'}
                />
                <SharedFormCheckbox
                  checked={searchType === 'all'}
                  onClick={() => onSetSearchType('all')}
                  label={'All of the words'}
                />
                <SharedFormCheckbox
                  checked={searchType === 'exact'}
                  onClick={() => onSetSearchType('exact')}
                  label={'Exact phrase'}
                />
              </FormControl>
            </FormControl>

            <FormControl>
              <FormControl>
                <GridItemStyled>
                  <SharedFormCheckbox
                    checked={filters?.videoCoverLetter}
                    onClick={() =>
                      setFilters({
                        ...filters,
                        videoCoverLetter: !filters?.videoCoverLetter
                      })
                    }
                    label={'Video Cover Letter'}
                  />
                  <FontAwesomeIcon icon={faVideo} size="xs" />
                </GridItemStyled>

                {applicationStatus === 'undecided' && (
                  <SharedFormCheckbox
                    controlProps={{ style: { margin: '0' } }}
                    checked={
                      applicationStatus !== 'undecided'
                        ? false
                        : filters?.hideViewed
                    }
                    disabled={applicationStatus !== 'undecided'}
                    onClick={() =>
                      setFilters({
                        ...filters,
                        hideViewed: !filters?.hideViewed
                      })
                    }
                    label={'Hide viewed on undecided list'}
                  />
                )}

                <GridItemStyled>
                  <SharedFormCheckbox
                    checked={filters?.chatbotStatus === '12'}
                    onClick={handleCompleteAivi}
                    label={'Virtual Interview Complete'}
                  />
                  <AiviIcon width={15} />
                </GridItemStyled>
              </FormControl>
            </FormControl>
          </GridContainerStyled>

          <DividerStyled />

          <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
            <SectionTitleStyled>Current Location</SectionTitleStyled>

            <div>
            {Object.keys(allCountryStateRegions)?.length > 0 &&
              Object.keys(allCountryStateRegions).map(key => {
                let stateRegion = allCountryStateRegions[key];

                return (
                  <Accordion
                    column={'two'}
                    type={'curentLocation'}
                    title={
                      <Grid
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%'
                        }}>
                        <SectionTitleStyled type="currentLocation">
                          {key == 'my'
                            ? countryOptionsFullName['MY']
                            : key == 'sg'
                            ? countryOptionsFullName['SG']
                            : ''}
                        </SectionTitleStyled>
                        {getSelectedLocationCount(key) > 0 && (
                          <SubCategoryContainerStyled
                            sx={{ marginRight: '8px' }}>
                            {getSelectedLocationCount(key)}
                          </SubCategoryContainerStyled>
                        )}
                      </Grid>
                    }
                    description={
                      <>
                        <AccordionDetailsColumnStyled>
                          <SharedFormCheckbox
                            value={`all-${key}`}
                            checked={
                              filters.currentLocation &&
                              filters.currentLocation.length > 1 &&
                              stateRegion
                                .map(r => r.title)
                                .every(location =>
                                  filters.currentLocation?.includes(location)
                                )
                            }
                            onChange={onCurrentLocation}
                            label={LOCATION_ALL_LABEL[country]}
                          />
                          {renderSharedFormCheckboxes(
                            stateRegion,
                            0,
                            9,
                            filters,
                            onCurrentLocation
                          )}
                        </AccordionDetailsColumnStyled>
                        <AccordionDetailsColumnStyled>
                          {renderSharedFormCheckboxes(
                            stateRegion,
                            9,
                            stateRegion?.length,
                            filters,
                            onCurrentLocation
                          )}
                        </AccordionDetailsColumnStyled>
                      </>
                    }></Accordion>
                );
              })}
            </div>
          </div>

          <DividerStyled />

          <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
            <SectionTitleStyled>Nationality</SectionTitleStyled>
            <SharedFormRadioGroup
              style={{ marginLeft: '0.75rem' }}
              value={filters.nationalities}
              onChange={e => onNationality(e.target.value)}
              options={NATIONALITY_OPTIONS[country]}
              $type={'list-ats-views'}
            />
          </div>

          <DividerStyled />

          <div  style={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
            <SectionTitleStyled>Availability & Relocation</SectionTitleStyled>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '0.75rem'
              }}>
              <SharedFormCheckbox
                value={WORKPLACE_TYPE.RELOCATE}
                checked={filters.relocate}
                onClick={onWorkplacePreference}
                label={'Willing to relocate'}
              />
              <SharedFormCheckbox
                value={WORKPLACE_TYPE.REMOTE}
                checked={filters.remote}
                onClick={onWorkplacePreference}
                label={'Remote only'}
              />
            </div>
          </div>

          <DividerStyled />

          <div  style={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
            <SectionTitleStyled>Right to Work</SectionTitleStyled>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '0.75rem'
              }}>
              <SharedFormCheckbox
                value={WORK_RIGHTS.SPONSOR}
                checked={filters.openToSponsor}
                onClick={onRightsPreference}
                label={'Requires sponsorship'}
              />
              <SharedFormCheckbox
                value={WORK_RIGHTS.VISA}
                checked={filters.visaOrEmploymentPass}
                onClick={onRightsPreference}
                label={'Has an existing right to work'}
              />

              <SharedFormCheckbox
                value={WORK_RIGHTS.LOCAL}
                checked={filters.localCitizen}
                onClick={onRightsPreference}
                label={'Local Citizen / PR'}
              />
            </div>
          </div>

          <DividerStyled />


          <div  style={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
            <SectionTitleStyled>Years of Experience</SectionTitleStyled>
            <SliderContainerStyled>
              <PrettoSliderStyled
                valueLabelDisplay="on"
                valueLabelFormat={valueLabelFormat}
                defaultValue={10}
                step={10}
                marks={marks}
                value={filters?.yearsOfExp}
                onChange={onSliderChange}
              />
            </SliderContainerStyled>
          </div>

          <DividerStyled />

          <div  style={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
            <SectionTitleStyled>Highest Education Level</SectionTitleStyled>
            <div style={{ maxWidth: '95%', margin: 'auto' }}>
              <FormControl component="fieldset">
                <FormGroup>
                  {highestEduLevel.map(item => (
                    <SharedFormCheckbox
                      key={item.id}
                      value={item.title}
                      checked={filters?.highestEduLevel?.includes(item.id)}
                      onClick={() => onClickHighEduLevel(item?.id)}
                      label={item?.title}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </div>
          </div>

          <DividerStyled />

          <div  style={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
            <SectionTitleStyled>
              Job Specialisations (based on work experience)
            </SectionTitleStyled>

            <FormControl fullWidth>
              <FormGroup>
                {specialisation &&
                  specialisation?.map(track => {
                    return (
                      <AccordionStyled
                        TransitionProps={{ unmountOnExit: true }}
                        disableGutters
                        key={track?.id}
                        id={track?.id}
                        onClick={() => {
                          setExpandAccordion(
                            expandAccordion === track?.title
                              ? null
                              : track?.title
                          );
                        }}
                        expanded={expandAccordion === track?.title}>
                        <AccordionSummaryStyled
                          id={track?.title}
                          expandIcon={
                            <ChevronRightIcon width={20} height={20} />
                          }>
                          <Grid
                            container
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            marginRight={'8px'}
                            flexWrap={'nowrap'}>
                            <SharedFormCheckbox
                              controlProps={{ style: { marginLeft: 0 } }}
                              shouldHide={expandAccordion !== track?.title}
                              checked={track?.subCategories.every(subCat =>
                                subcategories.includes(parseInt(subCat?.id))
                              )}
                              onClick={e => {
                                e.stopPropagation();
                                if (expandAccordion !== track?.title) return;
                                // select all sub categories
                                track?.subCategories?.map(subCat =>
                                  subcategories.indexOf(
                                    parseInt(subCat?.id)
                                  ) === -1
                                    ? // triggers onClickJobTracks only if redux array of integer doesn't include target id
                                      onClickJobTracks(subCat?.id)
                                    : // else if redux includes all sub categories (selected all), deselect all
                                    track?.subCategories.every(subCat =>
                                        subcategories.includes(
                                          parseInt(subCat?.id)
                                        )
                                      )
                                    ? onClickJobTracks(subCat?.id)
                                    : // else do nothing, by simply returning an empty callback
                                      {}
                                );
                              }}
                              label={
                                <MainCategoryTitle variant="body1">
                                  {track?.title}
                                </MainCategoryTitle>
                              }
                            />
                            {getSubCategoriesCount(track) > 0 && (
                              <SubCategoryContainerStyled>
                                {getSubCategoriesCount(track)}
                              </SubCategoryContainerStyled>
                            )}
                          </Grid>
                        </AccordionSummaryStyled>
                        <AccordionDetailsStyled>
                          {track?.subCategories &&
                            track?.subCategories.map(subCat => (
                              <SharedFormCheckbox
                                key={subCat.id}
                                controlProps={{
                                  sx: {
                                    display: 'flex',
                                    '&:hover': { backgroundColor: '#EFEFEF' }
                                  }
                                }}
                                checked={
                                  subcategories?.indexOf(Number(subCat?.id)) >
                                  -1
                                }
                                onClick={() => onClickJobTracks(subCat?.id)}
                                label={subCat?.title}
                              />
                            ))}
                        </AccordionDetailsStyled>
                      </AccordionStyled>
                    );
                  })}
              </FormGroup>
            </FormControl>
          </div>
        </FilterBodyStyled>
      </PopoverContainerStyled>

      <ButtonContainerStyled>
        <MUIButtonStyled
          label="Cancel"
          solidWhite
          onClick={handleClose(false)}
          $width={'100px'}
          $shadow={'none'}
        />
        <MUIButtonStyled
          label="Apply"
          solidBlack
          onClick={onApplyAdvancedFilter}
          $width={'100px'}
          $shadow={'4px 4px 10px rgba(0, 0, 0, 0.16)'}
          $borderRadius={'8px'}
        />
      </ButtonContainerStyled>
    </Popover>
  );
};

const Accordion = props => {
  const { title, description, column, type } = props;

  return (
    <AccordionStyled disableGutters>
      <AccordionSummaryStyled
        type={type}
        expandIcon={<ChevronRightIcon width={20} height={20} />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        {title}
      </AccordionSummaryStyled>
      <AccordionDetailsStyled column={column}>
        {description}
      </AccordionDetailsStyled>
    </AccordionStyled>
  );
};

export default AdvancedFilter;
