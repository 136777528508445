export async function useUploadToFolder({ vimeoId }) {
  const accessToken = process.env.REACT_APP_VIMEO_TOKEN;

  const { REACT_APP_API_URL, REACT_APP_SG_API_URL } = process.env;

  const isStaging =
    REACT_APP_API_URL && REACT_APP_SG_API_URL
      ? REACT_APP_API_URL.includes('staging-my-api.hiredly.com/api') ||
        REACT_APP_SG_API_URL.includes('staging-sg-api.hiredly.com/api')
      : false;

  const folderId = isStaging ? 21224029 : 21226407;

  const url = `https://api.vimeo.com/me/projects/${folderId}/videos/${vimeoId}`;
  const options = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      Accept: 'application/vnd.vimeo.*+json;version=3.4'
    }
  };

  try {
    const response = await fetch(url, options);
    const data = await response.json();

    if (data?.error) {
      ErrorToast(data?.error);
    }
    return data;
  } catch (error) {
    return error;
  }
}
