import React from 'react';
import {
  BulletPoints,
  BulletPointsList,
  CenterContainer,
  CloseIconStyled,
  DecoyTypography,
  EmptyContainer,
  IconButtonStyled,
  MainWrapper,
  PurpleContainer,
  TypographyNormalStyled,
  TypographyStyled
} from './styles';
import { useSelector, useDispatch } from 'react-redux';
import store from '../../redux/stores/store';
import { updateHideNoResultFilterStatus } from '../../redux/actions/talent_action';

export const NoResultsAlert = props => {
  const { search, section } = props;
  const dispatch = useDispatch();

  const hideNoResultFilter = useSelector(
    state => state.talents.hideNoResultFilter
  );

  const myCompanySettings = useSelector(
    state => state.companies.myCompanySettings
  );

  //const triggeredSearch = useSelector(state => state.talents.triggeredSearch);

  const getTalentsByType = (type, state) => {
    switch (type) {
      case 'search-resume': {
        return state.talents.talents;
      }
      case 'bookmarked': {
        return state.talents.bookmarkedTalents;
      }
      case 'unlocked': {
        return state.talents.unlockedTalents;
      }
      default: {
        return state.talents.talents;
      }
    }
  };

  const getNoFilterResultByType = (type, state) => {
    switch (type) {
      case 'search-resume': {
        return state.talents.talentsFilterNoResult;
      }
      case 'bookmarked': {
        return state.talents.bookmarkedFilterNoResult;
      }
      case 'unlocked': {
        return state.talents.unlockedFilterNoResult;
      }
      default: {
        return state.talents.talentsFilterNoResult;
      }
    }
  };

  const getLoadingTalentsByType = (type, state) => {
    switch (type) {
      case 'search-resume': {
        return state.talents.fetchingTalents;
      }
      case 'bookmarked': {
        return state.talents.fetchingBookmarkedTalents;
      }
      case 'unlocked': {
        return state.talents.fetchingUnlockedTalents;
      }
      default: {
        return state.talents.fetchingTalents;
      }
    }
  };

  const getCurrentPageByType = (type, state) => {
    switch (type) {
      case 'search-resume': {
        return state.talents.searchResumeTalentsCurrentPage;
      }
      case 'bookmarked': {
        return state.talents.bookmarkedTalentsCurrentPage;
      }
      case 'unlocked': {
        return state.talents.unlockedTalentsCurrentPage;
      }
      default: {
        return state.talents.searchResumeTalentsCurrentPage;
      }
    }
  };

  const getNoFilterMessageBasedOnType = type => {
    switch (type) {
      case 'search-resume': {
        return 'Please try to adjust your filters to widen your search.';
      }
      case 'bookmarked': {
        return 'Please try to adjust your filters to widen your search or browse all your bookmarked profiles below.';
      }
      case 'unlocked': {
        return 'Please try to adjust your filters to widen your search or browse all your unlocked profiles below.';
      }
    }
  };

  const loading = useSelector(state => getLoadingTalentsByType(section, state));
  const talents = useSelector(state => getTalentsByType(section, state));
  const currentPage = useSelector(state =>
    getCurrentPageByType(section, state)
  );
  const filterNoResult = useSelector(state =>
    getNoFilterResultByType(section, state)
  );

  const showNoResultAlertByType = (
    type,
    settings,
    talents,
    isLoading,
    currentPage
  ) => {
    if (isLoading) return false;

    switch (type) {
      case 'search-resume': {
        if (filterNoResult) return true;
        return false;
      }
      case 'bookmarked': {
        if (filterNoResult) return true;
        return (
          settings.bookmarkedResumesCount > 0 &&
          talents.length == 0 &&
          currentPage == 1
        );
      }
      case 'unlocked': {
        if (filterNoResult) return true;
        return (
          settings.unlockedResumesCount > 0 &&
          talents.length == 0 &&
          currentPage == 1
        );
      }
      default: {
        return (
          settings.bookmarkedResumesCount > 0 &&
          talents.length == 0 &&
          currentPage == 1
        );
      }
    }
  };

  const show = showNoResultAlertByType(
    section,
    myCompanySettings,
    talents,
    loading,
    currentPage
  );

  return !show ? null : section === 'search-resume' &&
    search &&
    !hideNoResultFilter ? (
    <SearchResult search={search} dispatch={dispatch} />
  ) : (
    !hideNoResultFilter && (
      <Filters
        type={section}
        dispatch={dispatch}
        getNoFilterMessageBasedOnType={getNoFilterMessageBasedOnType}
      />
    )
  );
};

const SearchResult = props => {
  const { search, dispatch } = props;

  const closeHandler = () => {
    dispatch(updateHideNoResultFilterStatus(true));
  };

  return (
    <MainWrapper container>
      <PurpleContainer>
        <DecoyTypography>x</DecoyTypography>
      </PurpleContainer>
      <CenterContainer
        container
        justifyContent="space-between"
        direction="column">
        <EmptyContainer
          container
          justifyContent="space-between"
          direction="row">
          <TypographyStyled>
            No results found for this "{search}"
          </TypographyStyled>
          <IconButtonStyled onClick={closeHandler}>
            <CloseIconStyled />
          </IconButtonStyled>
        </EmptyContainer>

        <BulletPoints>
          <BulletPointsList>
            Ensure that the search terms are spelled correctly
          </BulletPointsList>
          <BulletPointsList>Try different search terms</BulletPointsList>
          <BulletPointsList>
            Adjust your filters to widen your search
          </BulletPointsList>
        </BulletPoints>
      </CenterContainer>
    </MainWrapper>
  );
};

const Filters = props => {
  const { type, getNoFilterMessageBasedOnType, dispatch } = props;
  const closeHandler = () => {
    dispatch(updateHideNoResultFilterStatus(true));
  };

  return (
    <MainWrapper container>
      <PurpleContainer>
        <DecoyTypography>x</DecoyTypography>
      </PurpleContainer>
      <CenterContainer
        container
        justifyContent="space-between"
        direction="column">
        <EmptyContainer
          container
          justifyContent="space-between"
          direction="row">
          <TypographyStyled>
            No results found for this set of filters
          </TypographyStyled>
          <IconButtonStyled onClick={closeHandler}>
            <CloseIconStyled />
          </IconButtonStyled>
        </EmptyContainer>
        <TypographyNormalStyled>
          {getNoFilterMessageBasedOnType(type)}
        </TypographyNormalStyled>
      </CenterContainer>
    </MainWrapper>
  );
};
