import { styled as styling } from '@mui/material/styles';
import { TextField, Button } from '@mui/material';

export const FirstContainer = styling('div')({
  padding: '10px 40px 20px',
});

export const SecondContainer = styling('div')({
  padding: '0 20px 15px',
  display: 'flex',
  justifyContent: 'center',
});

export const ButtonWrapper = styling('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const ButtonContainer = styling('div')({
  padding: '0 30px',
});

export const Text = styling('p')((props) => ({
  ...(props.type == 'basic' && {
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Inter',
    display: 'inline',
    margin: '5px 0',
  }),
}));

export const TextContainer = styling('div')((props) => ({
  ...(props.type == 'message' && {
    margin: '10px 0 0',
    display: 'flex',
    justifyContent: 'flex-start',
  }),
  ...(props.type == 'count' && {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 'auto',
  }),
}));

export const TextFieldStyled = styling(TextField)((props) => ({
  width: '100%',
  marginTop: '12px',
  background: 'transparent',
  borderRadius: '5px',
  '& .MuiFilledInput-multiline': {
    padding: '27px 12px 10px',
  },
  ...(props.exclude_text_area == 'true' && {
    marginTop: '8px',
    border: 'none',
  }),
}));

export const ButtonStyled = styling(Button)((props) => ({
  ...(props.type == 'cancel' && {
    borderRadius: '26.5px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    border: '1px solid #222',
    alignSelf: 'center',
    color: '#222',
    fontWeight: 550,
    padding: '7px 40px',
  }),
  ...(props.type == 'confirm' && {
    borderRadius: '26.5px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    background: '#512acc',
    alignSelf: 'center',
    color: '#fff',
    fontWeight: 550,
    padding: '7.6px 40px',
    minWidth: '145px',
  }),
  ...(props.type == 'disabled' && {
    borderRadius: '26.5px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    alignSelf: 'center',
    fontWeight: 400,
    padding: '5px 20px',
    color: 'rgba(0, 0, 0, 0.26)',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  }),
}));
