import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { Grid } from '@mui/material';
import React from 'react';
import SharedAIPoweredCard from '../../../../../components/SharedAIPoweredCard';
import { SimpleTipsTooltip } from '../../../../../components/Tooltips';
import { Content } from './styles';
import Colors from '../../../../../styles/colors.module.scss';
import { convertHexAndEntitiesToString } from '../../../../../helpers/data_management';

const CandidateAiviSummary = ({ candidate }) => {
  const processGptSummary = summary => {
    let list = summary
      .split(/[\n]/) // split based on break space
      .filter(item => {
        return item.length > 0; // remove empty line
      });

    return list;
  };

  const content = (candidate, processGptSummary) => {
    return (
      <ul style={{ margin: '0', padding: '0 22px' }}>
        {processGptSummary(candidate.gptChatSummary).map(item => {
          return (
            <Content>
              {' '}
              {convertHexAndEntitiesToString(item).replace('-', '')}
            </Content>
          );
        })}
      </ul>
    );
  };
  return (
    <div
      style={{
        minWidth: '915px',
        width: '70%',
        maxWidth: '1280px',
        margin: '30px auto auto'
      }}>
      <SharedAIPoweredCard
        title={'AI-Powered Virtual Interviewer Summary'}
        content={content(candidate, processGptSummary)}
        notice={
          'The full chat history below should always be referred to for complete & accurate information of the candidate’s experience.'
        }
        origin={'list'}
        tooltip={
          <SimpleTipsTooltip
            top={'-10px'}
            left={'30px'}
            width={'352px'}
            padding={'4px 8px'}
            position={'absolute'}
            fontSize={'10px'}
            borderRadius={'8px'}
            border={`1.5px solid ${Colors.priBlack}`}
            icon={
              <InformationCircleIcon
                style={{ width: '16px', height: '16px' }}
              />
            }
            shadow={'none'}
            description={
              <span>
                This feature helps you summarise the candidates' responses as a
                quick overview.
              </span>
            }
          />
        }
      />
    </div>
  );
};

export default CandidateAiviSummary;
