import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const ArrowDropDownIconStyled = styled(ArrowDropDownIcon)({
  fontSize: '18px',
  color: "#AEAEAE"
});

export const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '2rem',
  flexDirection: 'column'
});

export const SubContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
});

export const PageTitle = styled('h6')({
  textAlign: 'center',
  fontFamily: 'InterBold',
  fontSize: '22px'
});

export const TabContainer = styled('div')({
  display: 'flex',
  padding: '30px',
  justifyContent: 'center'
});

export const Tab = styled('div')(({ isSelected }) => ({
  height: '50px',
  width: '200px',
  borderRadius: '30px',
  backgroundColor: isSelected ? 'rgba(81, 42, 204, 0.07)' : 'transparent',
  padding: '0px 20px',
  cursor: 'pointer'
}));

export const TabTitle = styled('p')({
  textAlign: 'center',
  fontFamily: 'InterBold',
  fontSize: '16px'
});

export const CardContainer = styled('div')({
  backgroundColor: 'white',
  padding: '40px',
  borderRadius: '35px',

  '> div': {
    marginBottom: '15px'
  }
});

export const RowLabel = styled('p')({
  paddingLeft: '10px',
  margin: '5px 0px'
});

export const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '20px'
});

export const Paragraph = styled('p')({
  textAlign: 'center',
  fontSize: '12px'
});
