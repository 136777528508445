import { Grid, TextField, Typography } from '@mui/material';
import { styled as styling } from '@mui/material/styles';
import Colors from '../../styles/colors.module.scss';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

export const SpecialisationContainer = styling('div')((props) => ({
  position: 'relative'
}));

export const SpecialisationList = styling('div')((props) => ({
  padding: '13px 16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&:hover': {
    background: '#fff',
    cursor: 'pointer',
  },
}));

export const SpecialisationPopover = styling('div')((props) => ({
  position: 'absolute',
  top: props.$height,
  zIndex: 4,
  width: '100%',
  boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.25)',
  ...(props.$scroll && {
    maxHeight: '300px',
    overflow: 'auto',
  }),
}));

export const TextFieldStyled = styling(TextField)((props) => ({
  width: '100%',
}));

export const MajorCategoriesContainer = styling('div')((props) => ({
  background: Colors.priWhite,
  height: '200px',
  overflow: 'auto',
}));

export const SubCategoriesContainer = styling('div')((props) => ({
  background: Colors.priWhite,
  borderBottom: '1px solid #efefef',
  paddingBottom: '5px',
}));

export const SubCategoriesTitleContainer = styling('div')((props) => ({
  padding: '9px 16px 6px',
  display: 'flex',
  alignItems: 'center',
}));

export const SubCategoriesTitle = styling('span')((props) => ({
  fontSize: '16px',
  fontFamily: 'Inter',
  letterSpacing: '0.1px',
  fontWeight: 700,
}));

export const SubCategoriesItemsContainer = styling('div')((props) => ({
  maxHeight: '250px',
  overflow: 'auto',
}));

export const SubCategoriesItem = styling('div')((props) => ({
  padding: '2px 24px',
  display: 'flex',
  '&:hover': {
    background: Colors.terWhite,
    cursor: 'pointer',
  },
}));

export const SubCategoriesItemText = styling('span')((props) => ({
  fontSize: '16px',
  fontFamily: 'Inter',
  letterSpacing: '0.15px',
}));

export const CategoryPill = styling(Grid)({
  maxWidth: '130px',
  borderRadius: '4px',
  background: '#EFEFEF',
  padding: '2px 6px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '4px'
});

export const CategoryPillTitle = styling(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '12px',
  fontFamily: 'Inter',
  lineHeight: '20px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontWeight: '400',
  margin: 0,
  whiteSpace: 'nowrap'
});

export const DropdownTrackTitle = styling(Typography)({
  fontFamily: 'Inter',
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontWeight: '400',
  margin: 0
});

export const MaximumReachedText = styling(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: '#BE4242',
  fontWeight: '400',
  margin: 0
});

export const MaximumReachedIcon = styling(ExclamationCircleIcon)({
  //
});

export const MaximumReachedContainer = styling(Grid)({
  position: 'sticky',
  bottom: '0px',
  width: '100%',
  padding: '8px 16px',
  columnGap: '10px',
  display: 'flex',
  flexWrap: 'nowrap',
  background: '#FFF',
  borderTop: '1px solid #D8D8D8',
  alignItems: 'flex-start'
});
