import React, { useEffect } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import { FilterTitle } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCollapsibleFilterStatus,
  updateSearchTalentFilters
} from '../../../../redux/actions/talent_action';
import moment from 'moment';
import { FilterContainerStyle } from '../styles';

let period = [
  { id: 'show-all', title: 'Show all' },
  { id: `${moment().subtract(1, 'months').unix()}`, title: '1 month' },
  { id: `${moment().subtract(3, 'months').unix()}`, title: '3 months' },
  { id: `${moment().subtract(6, 'months').unix()}`, title: '6 months' }
];
let duration;

const ResumeLastUpdated = () => {
  const dispatch = useDispatch();

  const filters = useSelector(state => state.talents.searchTalentFilters);
  const expandFilters = useSelector(state => state.talents.expandFilters);

  const onSelectRadio = e => {
    let cFilters = { ...filters };

    if (e.target.value == 'show-all') {
      duration = null;
    } else {
      duration = e.target.value;
    }

    cFilters['resumeLastUpdated'] = duration;

    dispatch(updateSearchTalentFilters(cFilters));
  };

  const onClickFilter = () => {
    let cFilter = { ...expandFilters };

    cFilter['resumeLastUpdated'] = !cFilter['resumeLastUpdated'];

    dispatch(updateCollapsibleFilterStatus(cFilter));
  };

  return (
    <FilterContainerStyle>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        onClick={onClickFilter}>
        <FilterTitle>Resume last updated</FilterTitle>
        {expandFilters['resumeLastUpdated'] ? (
          <ExpandLessIcon />
        ) : (
          <ExpandMoreIcon />
        )}
      </div>

      {expandFilters['resumeLastUpdated'] && (
        <RadioGroup
          aria-labelledby="radio-buttons-group"
          name="radio-buttons-group"
          value={
            filters['resumeLastUpdated'] == null
              ? 'show-all'
              : filters['resumeLastUpdated']
          }
          onChange={onSelectRadio}
          style={{ margin: '0px 10px 0' }}>
          {period.map((item, index) => {
            return (
              <FormControlLabel
                control={
                  <Radio
                    value={item.id}
                    size="small"
                    sx={{
                      '&.Mui-checked': {
                        color: '#222'
                      },
                      padding: '2px 9px'
                    }}
                  />
                }
                label={item.title}
                key={`${item.title} - ${index}`}
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: 14,
                    letterSpacing: '0.1px'
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: 20
                  }
                }}
              />
            );
          })}
        </RadioGroup>
      )}
    </FilterContainerStyle>
  );
};

export default ResumeLastUpdated;
