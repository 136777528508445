import React, { useState, useRef, useEffect } from 'react';
import {
  CardContainer,
  MainCard,
  ColorIndicator,
  TopRow,
  TopLeft,
  HotTag,
  SpotlightTag,
  AiTag,
  MidRow,
  MidLeft,
  MidRight,
  JobTitle,
  MidSalary,
  MidSalaryText,
  MidText,
  MidCompany,
  MidLastActive,
  BottomRow,
  BottomText,
  JobTypeTile,
  KeywordBox,
  KeywordTile,
  GlobalHireTag,
  Text,
  Spacer
} from './styles';
import { KeywordButton } from '../KeywordInput/styles';
import { useSelector } from 'react-redux';
import { getCurrenyCode } from '../../hooks/useRegionalisation';

const PreviewJobTab = ({
  id,
  content,
  location,
  career,
  keyword,
  isPopUp,
  companyName,
  companyImg,
  hideKeyword,
  getAllState
}) => {
  let hideSalary = content?.hideSalary;
  const jobDataForm = useSelector(state => state?.jobs?.jobDataForm);
  const showKeyword = true;

  return (
    <CardContainer>
      <MainCard currentOption={id}>
        {/* <ColorIndicator currentOption={id}/> */}
        <TopRow
          currentOption={id}
          isGlobalHire={jobDataForm?.globalHirePreferences?.enabled}>
          <TopLeft>
            {/* <HotTag currentOption={id}>Hot</HotTag> */}

            {id == 'job_slot_plus' ||
            id == 'job_slot_max' ||
            id == 'job_slot_plus_6m' ||
            id == 'job_slot_max_6m' ? (
              <SpotlightTag currentOption={id}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                  class="css-13htjwu"
                  style={{
                    width: '9px',
                    height: '9px',
                    color: '#FFF',
                    cursor: 'pointer',
                    zIndex: '10'
                  }}>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"></path>
                </svg>{' '}
                {/* Spotlight */}
              </SpotlightTag>
            ) : null}

            {jobDataForm?.globalHirePreferences?.enabled ? (
              <>
                <GlobalHireTag>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                    data-slot="icon"
                    class="css-iz2ltj"
                    style={{
                      width: '9px',
                      height: '9px',
                      color: '#FFF',
                      cursor: 'pointer',
                      zIndex: '10'
                    }}>
                    <path d="M15.75 8.25a.75.75 0 0 1 .75.75c0 1.12-.492 2.126-1.27 2.812a.75.75 0 1 1-.992-1.124A2.243 2.243 0 0 0 15 9a.75.75 0 0 1 .75-.75Z"></path>
                    <path
                      fill-rule="evenodd"
                      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM4.575 15.6a8.25 8.25 0 0 0 9.348 4.425 1.966 1.966 0 0 0-1.84-1.275.983.983 0 0 1-.97-.822l-.073-.437c-.094-.565.25-1.11.8-1.267l.99-.282c.427-.123.783-.418.982-.816l.036-.073a1.453 1.453 0 0 1 2.328-.377L16.5 15h.628a2.25 2.25 0 0 1 1.983 1.186 8.25 8.25 0 0 0-6.345-12.4c.044.262.18.503.389.676l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 0 1-1.161.886l-.143.048a1.107 1.107 0 0 0-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 0 1-1.652.928l-.679-.906a1.125 1.125 0 0 0-1.906.172L4.575 15.6Z"
                      clip-rule="evenodd"></path>
                  </svg>{' '}
                  Global Hire
                </GlobalHireTag>
              </>
            ) : (
              <>
                <div></div>
              </>
            )}
          </TopLeft>

          <TopLeft>
            <AiTag>
              AI Summary
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
                class="css-13htjwu"
                style={{
                  width: '8px',
                  height: '8px',
                  color: 'rgb(81, 42, 204)',
                  cursor: 'pointer',
                  zIndex: '10'
                }}>
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"></path>
              </svg>
            </AiTag>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
              class="css-8dkns2"
              style={{
                width: '13px',
                height: '13px',
                color: 'rgb(81, 42, 204)',
                cursor: 'pointer',
                zIndex: '10'
              }}>
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z"></path>
            </svg>
          </TopLeft>
        </TopRow>

        <MidRow currentOption={id}>
          <MidLeft>
            <div style={{ width: '100%' }}>
              <JobTitle>
                {jobDataForm?.title ? jobDataForm?.title : 'Job Title'}
              </JobTitle>
            </div>
            <MidSalary>
              {jobDataForm?.hideSalary == true ? (
                <>
                  <MidSalaryText>
                    <svg
                      style={{
                        userSelect: 'none',
                        display: 'inline-block',
                        fill: 'currentcolor',
                        flexShrink: 0,
                        transition:
                          'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        fontSize: '1.5rem',
                        width: '0.8rem',
                        height: '0.8rem',
                        color: 'rgba(0, 0, 0, 0.6)'
                      }}
                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1jm8qha"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="AttachMoneyIcon">
                      <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z"></path>
                    </svg>
                    Undisclosed
                  </MidSalaryText>
                </>
              ) : (
                <>
                  <MidSalaryText>
                    {/* Your salary range content */}
                    <svg
                      style={{
                        userSelect: 'none',
                        display: 'inline-block',
                        fill: 'currentcolor',
                        flexShrink: 0,
                        transition:
                          'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        fontSize: '10px',
                        width: '10px',
                        height: '10px',
                        color: 'rgba(0, 0, 0, 0.6)'
                      }}
                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1jm8qha"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="AttachMoneyIcon">
                      <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z"></path>
                    </svg>
                    {getCurrenyCode()} {jobDataForm?.minSalaryRange} -{' '}
                    {getCurrenyCode()}
                    {jobDataForm?.maxSalaryRange}
                  </MidSalaryText>
                </>
              )}
              <MidSalaryText>
                {/* Your location content */}
                <svg
                  style={{
                    userSelect: 'none',
                    display: 'inline-block',
                    fill: 'currentcolor',
                    flexShrink: 0,
                    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    fontSize: '10px',
                    width: '10px',
                    height: '10px',
                    color: 'rgba(0, 0, 0, 0.6)'
                  }}
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1jm8qha"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="LocationOnIcon">
                  <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path>
                </svg>
                {/* {content?.stateRegion?.title} */}
                {/* {location} */}

                <select
                  id=""
                  value={location}
                  disabled
                  style={{
                    background: 'white',
                    border: 'none',
                    fontSize: '7px',
                    color: 'rgb(34, 34, 34)',
                    appearance: 'none',
                    '-webkit-appearance': 'none',
                    '-moz-appearance': 'none'
                  }}>
                  {getAllState.map(state => (
                    <option key={state.id} value={state.id}>
                      {state.title}
                    </option>
                  ))}
                </select>
              </MidSalaryText>
            </MidSalary>
          </MidLeft>

          <MidRight>
            <img
              style={{
                borderRadius: '0.625rem',
                marginRight: '8px',
                objectFit: 'contain'
              }}
              height="30"
              width="30"
              alt="logo"
              src={companyImg}
              class="css-qch7ev"></img>
            <MidText>
              <MidCompany>{companyName}</MidCompany>
              <MidLastActive>
                <svg
                  style={{
                    userSelect: 'none',
                    display: 'inline-block',
                    fill: 'currentcolor',
                    flexShrink: 0,
                    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    fontSize: '1.5rem',
                    width: '10px',
                    height: '10px',
                    marginRight: '2px',
                    color: 'rgba(0, 0, 0, 0.38)'
                  }}
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1xavf4g"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="GroupIcon">
                  <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"></path>
                </svg>
                Last Active - Few Hours Ago
              </MidLastActive>
            </MidText>
          </MidRight>
        </MidRow>

        <BottomRow currentOption={id}>
          <BottomText>
            <JobTypeTile currentOption={id}>
              {/* <span>{content?.jobType?.title}</span> */}
              <span>{career}</span>
            </JobTypeTile>

            {showKeyword && (
              <>
                {keyword && keyword.length > 0 && (
                  <hr
                    style={{
                      borderColor:
                        id == 'job_slot_max' || id == 'job_slot_max_6m'
                          ? '#FFF'
                          : 'rgb(168, 168, 168)'
                    }}
                  />
                )}

                <KeywordBox>
                  {keyword &&
                    showKeyword &&
                    keyword?.map((keyword, index) => (
                      <KeywordTile key={index} currentOption={id}>
                        <span>{keyword}</span>
                      </KeywordTile>
                    ))}
                </KeywordBox>
              </>
            )}
          </BottomText>
        </BottomRow>
      </MainCard>
    </CardContainer>
  );
};

export default PreviewJobTab;
