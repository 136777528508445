import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearBookmarkedTalentsParams,
  updateBookmarkedCurrentPage,
  updateCurrentTalent
} from '../../../redux/actions/talent_action';
import { SharedPagination } from '../SharedPagination';

let locking = false;

const BookmarkPagination = props => {
  const dispatch = useDispatch();
  const { type, triggerLoadTalents } = props;
  const index = useSelector(
    state => state.talents.bookmarkedTalentsCurrentPage
  );
  const loading = useSelector(state => state.talents.fetchingBookmarkedTalents);

  const releaseLocking = () => {
    setTimeout(() => {
      // Release locking after 250ms
      locking = false;
    }, 250);
  };

  const onClickLeftArrow = () => {
    if (locking) return;

    if (triggerLoadTalents) {
      locking = true;
      triggerLoadTalents(type, 'prev', index - 1);
      dispatch(updateBookmarkedCurrentPage(index - 1));
      releaseLocking();
      dispatch(updateCurrentTalent({}));
    }
  };

  const onClickRightArrow = () => {
    if (locking) return;

    if (triggerLoadTalents) {
      locking = true;
      triggerLoadTalents(type, 'next', index + 1);
      dispatch(updateBookmarkedCurrentPage(index + 1));
      releaseLocking();
      dispatch(updateCurrentTalent({}));
    }
  };

  return (
    <SharedPagination
      onClickLeftArrow={onClickLeftArrow}
      onClickRightArrow={onClickRightArrow}
      type={type}
      index={index}
      loading={loading}
    />
  );
};

export default BookmarkPagination;
