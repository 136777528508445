import {  IconButton, Typography } from '@mui/material';
import { styled as styling } from '@mui/material/styles';

export const PaginationContainer = styling('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const IconButtonStyled = styling(IconButton)({
  borderRadius: '4px',
  background: '#d8d8d8',
});

export const PaginationNumbersContainer = styling('div')({
  display: 'flex',
  alignItems: 'center',
});

export const NumberContainer = styling('div')((props) => ({
  padding: '5px 10px',
  marginInline: '4px',

  ...(props.$selected && {
    background: '#512acc',
    borderRadius: '4px',
    padding: '5px 10px',
    marginInline: '6px',
  }),
}));


export const TypographyStyled = styling(Typography)((props) => ({
  color: props.$selected ? '#FFF' : 'rgba(0, 0, 0, 0.25)',
}));