import React, { useState, useRef } from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { ButtonStyled, PaperStyled, StatusText } from './styles';
import BlackListPopUp from '../../pages/ManageCandidates/Board/BlackListPopUp';
import { updateCandidateSnapshotStatus } from '../../redux/actions/application_action';
import { useDispatch } from 'react-redux';
import store from '../../../src/redux/stores/store';
import { FiberManualRecord as FiberManualRecordIcon } from '@mui/icons-material';

const options = ['Blacklist'];

export default function SplitButton({
  handleClick,
  firstButtonLabel,
  blackListState,
  setBlackListState,
  changeApplicationState,
  application,
  setIsBlacklistOpen
}) {
  const [openDropdown, setDropdownOpen] = useState(false);
  const anchorRef = useRef(null);
  const [isBlacklistPopoverOpen, setIsBlacklistPopoverOpen] = useState(false);

  const dispatch = useDispatch();

  const handleMenuItemClick = (event, index) => {
    if (index == 0) {
      setIsBlacklistPopoverOpen(true);

      if (setBlackListState) {
        setBlackListState({
          loading: false,
          open: true,
          application: application,
          currentState: application.state,
          nextState: 'blacklisted',
          currentIndex: store.getState().applications.currentCandidateIndex,
          updateSnapshot: true
        });
      } else {
        setIsBlacklistOpen(true);
      }
    }

    setDropdownOpen(false);
  };

  const handleToggle = () => {
    setDropdownOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setDropdownOpen(false);
  };

  return (
    <>
      <ButtonGroup variant="" ref={anchorRef} aria-label="split button">
        <ButtonStyled type={'basic'} onClick={handleClick}>
          {firstButtonLabel}
        </ButtonStyled>
        <ButtonStyled
          type={'dropdown'}
          aria-controls={openDropdown ? 'split-button-menu' : undefined}
          aria-expanded={openDropdown ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}>
          <ArrowDropDownIcon />
        </ButtonStyled>
      </ButtonGroup>
      <Popper
        style={{ zIndex: '10000' }}
        open={openDropdown}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}>
            <PaperStyled>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      //   disabled={index === 2}
                      onClick={event => handleMenuItemClick(event, index)}>
                      <FiberManualRecordIcon
                        style={{
                          width: '10px',
                          height: '10px',
                          marginRight: '10px'
                        }}
                      />
                      <StatusText>{option}</StatusText>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </PaperStyled>
          </Grow>
        )}
      </Popper>

      <BlackListPopUp
        open={blackListState ? blackListState.open : false}
        handleClose={() => setBlackListState({ open: false, loading: false })}
        changeApplicationState={changeApplicationState}
        blackListState={blackListState}
      />
    </>
  );
}
