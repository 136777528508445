import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Grid, InputAdornment } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import DefaultLayout from '../../../layouts/DefaultLayout';
import JobDetails from '../JobDetails/index';
import CandidateInformation from './CandidateInformation/index';
import CandidatesColumn from './CandidatesColumn';
import SkeletonLoader from './CandidatesColumn/SkeletonLoader';
import CandidateSort from './CandidatesColumn/CandidateSort';
import * as types from '../../../redux/types/application_type';
import SearchIcon from '@mui/icons-material/Search';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import TuneIcon from '@mui/icons-material/Tune';
import {
  CANDIDATES_SORT_BY,
  JOB_APPLICATION_STATUS,
  SEARCH_TYPE
} from '../../../utils/Constants';
import {
  getApplications,
  getInterviews,
  getOffers,
  viewApplication
} from '../../../redux/actions/application_action';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  updateAllApplications,
  updateCandidateSnapshotStatus,
  updateCurrentCandidateIndex,
  updateInterviewStatus
} from '../../../redux/actions/application_action';
import styles from './index.module.scss';
import CandidateSnapshot from '../../../components/CandidateSnapshot';
import store from '../../../redux/stores/store';
import ScheduleInterviewModal from '../Modals/ScheduleInterview/Board';
import ScheduleNextInterviewModal from '../Modals/ScheduleInterview/List';
import AdvancedFilter from './ToolBar/AdvancedFilter';
import StatusFilter from '../StatusFilter';
import { SearchWrapper } from './styles';
import InfoBanner from './InfoBanner';
import { useNavigate } from 'react-router-dom';
import { fetchJob } from '../../../redux/actions/job_action';
import { fetchStateRegions } from '../../../redux/actions/company_actions';
import {
  getAllCountryStates,
  getCountry,
  getNationality
} from '../../../hooks/useRegionalisation';
import { NATIONALITY_OPTIONS } from '../../../components/SharedAdvancedFilter/constants';
import SharedTextField from '../../../components/SharedTextField';

let currentCandidates = [];

let previousScrollTop = 0;

let loadingApplications = {
  application: false,
  interviewOrOffer: false
};

let reachedEnds = {
  application: false,
  interviewOrOffer: false
};

const List = props => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isLocalOnly = location.search.includes(
    `?${getNationality().toLowerCase()}=true`
  );

  const storedApplicationStatus = localStorage.getItem('applicationStatus');

  const candidateInformationRef = useRef();
  const candidatesColumn = useRef();
  const windowRef = useRef();

  const [isSelected, setIsSelected] = useState(null);
  const [candidates, setCandidates] = useState([]);
  const [hasMoreApplication, setHasMoreApplication] = useState(true);
  const [hasMoreInterviewOrOffer, setHasMoreInterviewOrOffer] = useState(true);
  const [sortBy, setSortBy] = useState(CANDIDATES_SORT_BY.DATE_APPLIED);
  const [applicationStatus, setApplicationStatus] = useState(
    JOB_APPLICATION_STATUS.ALL
  );
  const [searchText, setSearchText] = useState(
    query.get('name') ? query.get('name') : ''
  );
  const [jobData, setJobData] = useState(null);
  const [searchType, setSearchType] = useState(SEARCH_TYPE.ANY);
  const [advancedFilter, setAdvancedFilter] = useState({
    yearsOfExp: null,
    videoCoverLetter: null,
    nationalities: '',
    currentLocation: [],
    relocate: false,
    remote: false,
    openToSponsor: false,
    visaOrEmploymentPass: false,
    localCitizen: false,
    highestEduLevel: [],
    trackIds: [],
    doneGettingJob: false,
    hideViewed: null,
    chatbotStatus: ''
  });
  const [isRefresh, setIsRefresh] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [direction, setDirection] = useState('');
  const ref = useRef(null);

  const statusFilter = [
    {
      title: `applicants`,
      count: `${jobData?.totalCount}`,
      key: JOB_APPLICATION_STATUS.ALL
    },
    {
      title: `undecided`,
      count: `${jobData?.undecidedCount}`,
      key: JOB_APPLICATION_STATUS.UNDECIDED
    },
    {
      title: `shortlisted`,
      count: `${jobData?.shortlistedCount}`,
      key: JOB_APPLICATION_STATUS.SHORTLISTED
    },
    {
      title: `kiv`,
      count: `${jobData?.kivCount}`,
      key: JOB_APPLICATION_STATUS.KIV
    },
    {
      title: `interview`,
      count: `${jobData?.interviewCount}`,
      key: JOB_APPLICATION_STATUS.INTERVIEW
    },
    {
      title: `offered`,
      count: `${jobData?.offerCount}`,
      key: JOB_APPLICATION_STATUS.OFFER
    }
  ];

  useEffect(() => {
    dispatch(fetchJob({ id })).then(res => {
      setJobData(res?.job);
    });
    dispatch(fetchStateRegions());
  }, []);

  const job = useSelector(state => state.jobs.jobData);
  const allCountryStateRegions = getAllCountryStates();

  const getCurrentCandidates = () => {
    return currentCandidates;
  };

  const setCurrentCandidates = cCandidates => {
    currentCandidates = cCandidates;
  };

  const getSortBy = () => {
    switch (sortBy) {
      case CANDIDATES_SORT_BY.DATE_APPLIED:
        return { by: 'appliedAt', direction: 'desc' };
      case CANDIDATES_SORT_BY.EARLIEST:
        return { by: 'createdAt', direction: 'asc' };
      case CANDIDATES_SORT_BY.LATEST:
        return { by: 'createdAt', direction: 'desc' };
      default:
        return null;
    }
  };

  const getInterviewSortBy = () => {
    switch (sortBy) {
      case CANDIDATES_SORT_BY.DATE_APPLIED:
        return { by: 'created_at', direction: 'desc' };
      case CANDIDATES_SORT_BY.EARLIEST:
        return { by: 'created_at', direction: 'asc' };
      case CANDIDATES_SORT_BY.LATEST:
        return { by: 'created_at', direction: 'desc' };
      default:
        return null;
    }
  };

  const loadingJobApplications = refresh => {
    if (
      loadingApplications.application ||
      (!refresh && reachedEnds.application)
    )
      return;

    loadingApplications.application = true;

    if (refresh) {
      reachedEnds.application = false;
    }

    const applicationParams = {
      jobId: id,
      first: 10,
      search: searchText,
      searchType,
      sort: getSortBy(),
      showState:
        applicationStatus === JOB_APPLICATION_STATUS.ALL
          ? null
          : applicationStatus,
      yearsOfExperience:
        advancedFilter && advancedFilter.yearsOfExp
          ? advancedFilter.yearsOfExp / 10
          : null,
      withVideoCoverLetter: advancedFilter?.videoCoverLetter,
      highestEducationLevels: advancedFilter?.highestEduLevel,
      // malaysians: advancedFilter?.malaysians ? 'on' : null,
      trackIds: advancedFilter?.trackIds,
      offset: refresh ? 0 : store.getState().applications.applications.length,
      hideViewed:
        applicationStatus != 'undecided' ? false : advancedFilter?.hideViewed,
      chatbotStatus: advancedFilter?.chatbotStatus,
      refresh: refresh,
      currentLocation: advancedFilter.currentLocation,
      nationalities: advancedFilter.nationalities,
      relocate: advancedFilter.relocate,
      remote: advancedFilter.remote,
      openToSponsor: advancedFilter.openToSponsor,
      visaOrEmploymentPass: advancedFilter.visaOrEmploymentPass,
      localCitizen: advancedFilter.localCitizen
    };

    props.getApplications(applicationParams).then(res => {
      loadingApplications.application = false;

      // if (location) {
      //   if (advancedFilter?.malaysians) {
      //     history({
      //       pathname: location.pathname,
      //       search: '?malaysian=true'
      //     });
      //   } else {
      //     history({
      //       pathname: location.pathname,
      //       search: ''
      //     });
      //   }
      // }

      if (res?.payload) {
        reachedEnds.application = !res.payload.hasNext;
        setHasMoreApplication(res.payload.hasNext);
      }

      if (applicationStatus == 'interview' || applicationStatus == 'offer')
        return;

      if (refresh) {
        setCandidates(res?.payload?.applications?.edges);
        currentCandidates = res?.payload?.applications?.edges;
      } else {
        setCandidates([...candidates, ...res?.payload?.applications?.edges]);
        currentCandidates = [
          ...candidates,
          ...res?.payload?.applications?.edges
        ];

        // Update redux allApplications if candidate snapshot is opened
        const isCandidateSnapshotOpen =
          store.getState().applications.isCandidateSnapshotOpen;

        if (isCandidateSnapshotOpen) {
          let joinedApplications = currentCandidates.map(item => item.node);
          props.updateAllApplications(joinedApplications);
        }
      }

      if (isRefresh) {
        setIsRefresh(false);
      }
    });
  };

  const loadingInterviews = (refresh, isInterview) => {
    if (
      loadingApplications.interviewOrOffer ||
      (!refresh && reachedEnds.interviewOrOffer)
    )
      return;

    loadingApplications.interviewOrOffer = true;

    if (refresh) {
      reachedEnds.interviewOrOffer = false;
    }

    const interviewParams = {
      jobId: id,
      first: 10,
      limit: 11,
      status: isInterview ? null : 'offered',
      sort: getInterviewSortBy(),
      search: searchText,
      searchType,
      yearsOfExperience:
        advancedFilter && advancedFilter.yearsOfExp
          ? advancedFilter.yearsOfExp / 10
          : null,
      withVideoCoverLetter: advancedFilter?.videoCoverLetter,
      highestEducationLevels: advancedFilter?.highestEduLevel,
      malaysians: advancedFilter?.malaysians ? 'on' : null,
      trackIds: advancedFilter?.trackIds,
      offset: refresh ? 0 : store.getState().applications.interviews.length,
      hideViewed:
        applicationStatus != 'undecided' ? false : advancedFilter?.hideViewed,
      chatbotStatus: advancedFilter?.chatbotStatus,
      refresh: refresh
    };

    props.getInterviews(interviewParams).then(res => {
      loadingApplications.interviewOrOffer = false;

      if (res?.payload) {
        reachedEnds.interviewOrOffer = !res.payload.hasNext;

        setHasMoreInterviewOrOffer(res.payload.hasNext);
      }

      if (applicationStatus !== 'interview' && applicationStatus !== 'offer')
        return;

      if (refresh) {
        setCandidates(res?.payload?.interviews?.edges);
        currentCandidates = res?.payload?.interviews?.edges;
      } else {
        setCandidates([...candidates, ...res?.payload?.interviews?.edges]);
        currentCandidates = [...candidates, ...res?.payload?.interviews?.edges];

        const isCandidateSnapshotOpen =
          store.getState().applications.isCandidateSnapshotOpen;

        if (isCandidateSnapshotOpen) {
          let joinedApplications = currentCandidates.map(item => item.node);
          props.updateAllApplications(joinedApplications);
        }
      }

      if (isRefresh) {
        setIsRefresh(false);
      }
    });
  };

  useEffect(() => {
    (async function getAllJobApplicants() {
      if (advancedFilter?.doneGettingJob) {
        if (
          applicationStatus === 'interview' ||
          applicationStatus === 'offer'
        ) {
          loadingInterviews(true, applicationStatus === 'interview');
        } else {
          loadingJobApplications(true);
          // await getJobApplicants();
        }
      }
    })();

    return () => {
      // ComponentWillUnmount
    };
  }, [searchText, applicationStatus, sortBy, advancedFilter]);

  useEffect(() => {
    if (storedApplicationStatus) {
      switch (storedApplicationStatus) {
        case 'all':
          setApplicationStatus(JOB_APPLICATION_STATUS.ALL);
          break;
        case 'undecided':
          setApplicationStatus(JOB_APPLICATION_STATUS.UNDECIDED);
          break;
        case 'shortlisted':
          setApplicationStatus(JOB_APPLICATION_STATUS.SHORTLISTED);
          break;
        case 'kiv':
          setApplicationStatus(JOB_APPLICATION_STATUS.KIV);
          break;
        case 'interview':
          setApplicationStatus(JOB_APPLICATION_STATUS.INTERVIEW);
          break;
        case 'offer':
          setApplicationStatus(JOB_APPLICATION_STATUS.OFFER);
          break;
      }
    }
  }, [storedApplicationStatus]);

  useEffect(() => {
    const updateJobData = async () => {
      if (job) {
        if (!jobData) {
          setJobData(job);
        }

        setAdvancedFilter(prevFilter => ({
          ...prevFilter,
          nationalities: isLocalOnly || job?.localOnly ? getNationality() : '',
          doneGettingJob: true
        }));
      }
    };

    updateJobData();
  }, [job]);

  const scrollToSection = index => {
    if (ref?.current) {
      if (index == 0) {
        ref?.current?.scrollTo({
          top:
            ref?.current?.children[0].children[0].children[1]?.offsetTop - 250,
          behavior: 'smooth'
        });
      } else if (index == 1) {
        ref?.current?.scrollTo({
          top:
            ref?.current?.children[0].children[0].children[2]?.offsetTop - 250,
          behavior: 'smooth'
        });
      } else if (index == 2) {
        ref?.current?.scrollTo({
          top:
            ref?.current?.children[0].children[0].children[3]?.offsetTop - 250,
          behavior: 'smooth'
        });
      }
    }
  };

  const scrollToTop = () => {
    if (ref?.current) {
      ref.current.scrollTo(0, 0);
    }
  };

  const handleScroll = e => {
    setScrollY(e.target.scrollTop);

    let scrollTop = e.target.scrollTop;

    if (scrollTop < previousScrollTop) {
      previousScrollTop = scrollTop;
      setDirection('scrollingUp');
    } else {
      previousScrollTop = scrollTop;
      setDirection('scrollingDown');
    }
  };

  const scrollToCardRef = useRef(null);

  const handleScrollToCard = (index, previous) => {
    if (scrollToCardRef?.current) {
      scrollToCardRef?.current?.scrollTo(
        0,
        !previous
          ? scrollToCardRef?.current?.children[0].children[0].children[0]
              .children[index].offsetTop - 100
          : scrollToCardRef?.current?.children[0].children[0].children[0]
              .children[index].offsetTop - 500
      );
    }
  };

  const onApplicationClicked = applicationId => () => {
    let joinedApplications = candidates.map(item => item.node);

    let currentIndex = joinedApplications.findIndex(item => {
      if (applicationStatus === 'interview' || applicationStatus === 'offer') {
        return item.jobApplication.id === applicationId;
      } else {
        return item.id === applicationId;
      }
    });

    props.updateAllApplications(joinedApplications).then(() => {
      props.updateCurrentCandidateIndex(currentIndex).then(() => {
        props.updateCandidateSnapshotStatus(true);
      });
    });
  };

  const onViewApplication = item => {
    // Not interview and offer
    if (item && item.state && !item.status) {
      let viewApplicationParams = {
        applicationId: item.id,
        state: item.state
      };

      props.viewApplication(viewApplicationParams).then(res => {
        if (res.type == types.VIEW_APPLICATION_SUCCEED) {
          const applicationId = res.applicationId;

          const cCandidates = [...currentCandidates];
          const cIndex = cCandidates.findIndex(
            application => application.node?.id == applicationId
          );

          if (cIndex != -1) {
            const cCandidate = {
              node: { ...cCandidates[cIndex].node, status: true }
            };
            cCandidates[cIndex] = cCandidate;

            setCandidates(cCandidates);
            setCurrentCandidates(cCandidates);
          }
        }
      });
    }
  };

  const updateListJobApplicationStatus = (tmpArray, applicationId) => {
    const joinedApplications = tmpArray.map(item => item.node);

    const currentIndex = joinedApplications.findIndex(
      item => item?.id === applicationId
    );

    props.updateAllApplications(joinedApplications).then(() => {
      props.updateCurrentCandidateIndex(currentIndex);
    });
  };

  const candidateInfoPrevious = () => {
    candidateInformationRef?.current?.handlePreviousIcon();
  };

  const candidateInfoNext = () => {
    candidateInformationRef?.current?.handleNextIcon();
  };

  const updateCandidateList = () => {
    candidateInformationRef?.current?.updateCandidateFromList();
  };

  const changeJobApplicationStatus = status => {
    candidateInformationRef?.current?.onChangeJobApplicationStatus(status);
  };

  const updateApplicationState = () => {
    candidateInformationRef?.current?.setApplicationState();
  };

  const renderCandidateSnapshot = companyData => {
    const isCandidateSnapshotOpen = useSelector(
      state => state.applications.isCandidateSnapshotOpen
    );

    if (!isCandidateSnapshotOpen) return null;

    return (
      <CandidateSnapshot
        companyData={companyData}
        candidateInfoPrevious={candidateInfoPrevious}
        candidateInfoNext={candidateInfoNext}
        updateCandidateList={updateCandidateList}
        loading={props.fetchingApplications}
        changeJobApplicationStatus={changeJobApplicationStatus}
        setCandidates={setCandidates}
        setCurrentCandidates={setCurrentCandidates}
        setIsSelected={setIsSelected}
        candidates={candidates}
        setListApplicationState={updateApplicationState}
        jobData={jobData}
        setJobData={setJobData}
        applicationStatus={applicationStatus}
        onViewApplication={onViewApplication}
      />
    );
  };

  const renderFirstScheduleInterview = () => {
    const isFirstInterviewModalOpened = useSelector(
      state => state.applications.isFirstInterviewModalOpened
    );
    return (
      <ScheduleInterviewModal
        open={isFirstInterviewModalOpened}
        candidateInfo={candidates?.[isSelected]?.node}
        isAts={false}
        candidates={candidates}
        setCandidates={setCandidates}
        setCurrentCandidates={setCurrentCandidates}
        setJobData={setJobData}
      />
    );
  };

  const renderNextInterviewScheduleInterview = () => {
    const isNextInterviewModalOpened = useSelector(
      state => state.applications.isNextInterviewModalOpened
    );

    return (
      <ScheduleNextInterviewModal
        open={isNextInterviewModalOpened}
        candidateInfo={candidates?.[isSelected]?.node}
        isAts={false}
        candidates={candidates}
        setCandidates={setCandidates}
        setCurrentCandidates={setCurrentCandidates}
      />
    );
  };

  const search = _.debounce(value => {
    let searchTerm = value
      ?.toLowerCase()
      .replace(/^\s\s*/, '')
      .replace(/\s\s*$/, '');

    try {
      window.dataLayer.push({
        event: 'search-candidate',
        search_term: searchTerm
      });
    } catch {}
    setSearchText(value);
  }, 500);

  const deboucedSearch = useCallback(value => search(value), []);

  const onSearch = value => {
    setIsRefresh(true);
    deboucedSearch(value);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleAdvancedFilterClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const [sortAnchorEl, setSortAnchorEl] = useState(null);

  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleCandidateSortClick = event => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleCandidateSort = (event, index) => {
    setSortAnchorEl(null);
    if (selectedIndex == index) return;

    setSelectedIndex(index);
    setIsRefresh(true);

    let status;
    let eventName = 'list_sort_applicants_';

    eventName +=
      applicationStatus === JOB_APPLICATION_STATUS.ALL ? 'all' : 'status';

    switch (index) {
      case 1:
        status = CANDIDATES_SORT_BY.LATEST;
        eventName += '_newest';
        break;
      case 2:
        status = CANDIDATES_SORT_BY.EARLIEST;
        eventName += '_oldest';
        break;
      default:
        status = CANDIDATES_SORT_BY.LATEST;
        eventName += '_newest';
    }

    dataLayer.push({
      event: eventName
    });

    setSortBy(status);
  };

  const [selectedChip, setSelectedChip] = useState(0);
  const [showBanner, setShowBanner] = useState(true);

  const getPageInfo = () => {
    return applicationStatus === 'interview' || applicationStatus === 'offer'
      ? { hasNextPage: hasMoreInterviewOrOffer }
      : { hasNextPage: hasMoreApplication };
  };

  const getWindowHeight = () => {
    return windowRef?.current?.scrollHeight;
  };

  const closeBanner = () => {
    setShowBanner(false);
  };

  let image =
    'https://s3.ap-southeast-1.amazonaws.com/assets.wobbjobs.com/images/employers/default_states/hiredly-active-cv.svg';

  return (
    <DefaultLayout minusBetaBanner={true} maxWidth={true}>
      <div className={styles.infoBar}>
        <JobDetails
          jobData={jobData}
          loading={props.fetchingApplications}
          toggleView={props.toggleView}
          isListView={props.isListView}
        />
      </div>

      {showBanner && <InfoBanner closeBanner={closeBanner} />}

      <SearchWrapper>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SharedTextField
            placeholder="Search by keywords..."
            defaultValue={searchText}
            onChange={event => onSearch(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Grid
                    sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
                    <TuneIcon onClick={handleAdvancedFilterClick} />
                    <SwapVertIcon onClick={handleCandidateSortClick} />
                  </Grid>
                </InputAdornment>
              )
            }}
          />
        </div>
        <AdvancedFilter
          setAdvancedFilter={setAdvancedFilter}
          setIsRefresh={setIsRefresh}
          jobData={jobData}
          advancedFilter={advancedFilter}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          searchType={searchType}
          setSearchType={setSearchType}
          setIsSelected={setIsSelected}
          applicationStatus={applicationStatus}
          allCountryStateRegions={allCountryStateRegions}
        />
        <CandidateSort
          sortBy={sortBy}
          sortAnchorEl={sortAnchorEl}
          setSortAnchorEl={setSortAnchorEl}
          handleCandidateSort={handleCandidateSort}
          selectedIndex={selectedIndex}
        />
        <div>
          <StatusFilter
            jobData={jobData}
            isAts={false}
            statusFilter={statusFilter}
            setIsRefresh={setIsRefresh}
            setApplicationStatus={setApplicationStatus}
            applicationStatus={applicationStatus}
            selectedChip={selectedChip}
            setSelectedChip={setSelectedChip}
            setIsSelected={setIsSelected}
            loading={props.fetchingApplications}
          />
        </div>
      </SearchWrapper>

      <Grid container className={styles.gridContainer} ref={windowRef}>
        <Grid className={styles.candidateColumnWrapper}>
          {candidates?.length === 0 &&
          props.fetchingApplications &&
          applicationStatus !== 'interview' &&
          applicationStatus !== 'offer' ? (
            <Grid container>
              <div className={styles.skeletonContainer}>
                <Grid item xs={12}>
                  <CandidateSort
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    setIsRefresh={setIsRefresh}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div style={{ marginTop: 30 }}>
                    <SkeletonLoader customClassName={styles.skeletonWrapper} />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <SkeletonLoader customClassName={styles.skeletonWrapper} />
                </Grid>
                <Grid item xs={12}>
                  <SkeletonLoader customClassName={styles.skeletonWrapper} />
                </Grid>
              </div>
            </Grid>
          ) : (
            <CandidatesColumn
              ref={candidatesColumn}
              candidates={candidates || []}
              setCandidates={setCandidates}
              isSelected={isSelected}
              setIsSelected={setIsSelected}
              pageInfo={
                applicationStatus === 'interview' ||
                applicationStatus === 'offer'
                  ? { hasNextPage: hasMoreInterviewOrOffer }
                  : { hasNextPage: hasMoreApplication }
              }
              getJobApplicants={() => {
                if (
                  applicationStatus === 'interview' ||
                  applicationStatus === 'offer'
                ) {
                  loadingInterviews(false, applicationStatus === 'interview');
                } else {
                  loadingJobApplications(false);
                }
              }}
              loading={props.fetchingApplications}
              isRefresh={isRefresh}
              scrollToTop={scrollToTop}
              getCurrentCandidates={getCurrentCandidates}
              setCurrentCandidates={setCurrentCandidates}
              scrollToCardRef={scrollToCardRef}
              handleScrollToCard={handleScrollToCard}
              applicationStatus={applicationStatus}
              windowHeight={getWindowHeight()}
            />
          )}
        </Grid>

        <Grid
          className={styles.candidateInfoWrapper}
          ref={ref}
          onScroll={handleScroll}>
          {(isSelected === null && candidates?.length !== 0) ||
          (!getPageInfo()?.hasNextPage && candidates?.length === 0) ? (
            <div className={styles.placeholderContainer}>
              <div className={styles.placeholderWrapper}>
                <img className={styles.imageContent} src={image} />
                <div className={styles.textContainer}>
                  <p className={styles.description}>
                    {!getPageInfo()?.hasNextPage && candidates?.length === 0
                      ? 'Kindly edit your filter for more results.'
                      : 'Select a candidate from the list on the left to view their profile.'}
                  </p>
                  {!(
                    !getPageInfo()?.hasNextPage && candidates?.length === 0
                  ) && (
                    <p className={styles.notice}>
                      {' '}
                      {jobData?.unviewedCount} Unviewed{' '}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <CandidateInformation
                ref={candidateInformationRef}
                candidate={
                  candidates[isSelected]?.node?.jobApplication
                    ? candidates[isSelected].node.jobApplication
                    : candidates[isSelected]?.node
                }
                item={candidates[isSelected]?.node}
                candidates={candidates}
                currentCandidates={currentCandidates}
                setCandidates={setCandidates}
                setCurrentCandidates={setCurrentCandidates}
                getCurrentCandidates={getCurrentCandidates}
                isSelected={isSelected}
                setIsSelected={setIsSelected}
                isFirsItem={candidates.indexOf(candidates[isSelected]) === 0}
                isLastItem={
                  candidates.indexOf(candidates[isSelected]) ===
                  candidates.length - 1
                }
                loading={props.fetchingApplications}
                applicationStatus={applicationStatus}
                jobData={jobData}
                setJobData={setJobData}
                scrollToTop={scrollToTop}
                scrollToSection={scrollToSection}
                pageInfo={
                  applicationStatus === 'interview' ||
                  applicationStatus === 'offer'
                    ? { hasNextPage: hasMoreInterviewOrOffer }
                    : { hasNextPage: hasMoreApplication }
                }
                onViewApplication={onViewApplication}
                getJobApplicants={() => {
                  if (
                    applicationStatus === 'interview' ||
                    applicationStatus === 'offer'
                  ) {
                    loadingInterviews(false, applicationStatus === 'interview');
                  } else {
                    loadingJobApplications(false);
                  }
                }}
                scrollY={scrollY}
                direction={direction}
                handleScrollToCard={handleScrollToCard}
                onApplicationClicked={onApplicationClicked}
                updateListJobApplicationStatus={updateListJobApplicationStatus}
                updateInterviewStatus={props.updateInterviewStatus}
                updatingInterviewStatus={props.updatingInterviewStatus}
              />
            </div>
          )}
        </Grid>
      </Grid>

      {renderCandidateSnapshot(props.companyData)}
      {renderFirstScheduleInterview()}
      {renderNextInterviewScheduleInterview()}
    </DefaultLayout>
  );
};

const mapStateToProps = state => {
  return {
    updatingInterviewStatus: state.applications.updatingInterviewStatus,
    fetchingApplications: state.applications.fetchingApplications
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateAllApplications: bindActionCreators(updateAllApplications, dispatch),
    updateCandidateSnapshotStatus: bindActionCreators(
      updateCandidateSnapshotStatus,
      dispatch
    ),
    updateCurrentCandidateIndex: bindActionCreators(
      updateCurrentCandidateIndex,
      dispatch
    ),
    getApplications: bindActionCreators(getApplications, dispatch),
    getInterviews: bindActionCreators(getInterviews, dispatch),
    getOffers: bindActionCreators(getOffers, dispatch),
    updateInterviewStatus: bindActionCreators(updateInterviewStatus, dispatch),
    viewApplication: bindActionCreators(viewApplication, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
