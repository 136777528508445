import { makeStyles } from '@mui/styles';
import { Skeleton } from '@mui/material';
import React from 'react';

const useStyles = makeStyles({
  container: {
    margin: '5px 0px 40px 10px'
  },
  wrapper: {
    display: 'flex',
    margin: '32px 0px'
  },
  checkbox: {
    width: 20,
    height: 20,
    borderRadius: 5,
    marginRight: 15
  }
});

const SkeletonLoader = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div style={{ display: 'flex', margin: '20px 0px 30px' }}>
        <Skeleton variant="rect" className={classes.checkbox} />
        <Skeleton width={150} />
      </div>
      <div className={classes.wrapper}>
        <Skeleton variant="rect" className={classes.checkbox} />
        <Skeleton width={150} />
      </div>
      <div className={classes.wrapper}>
        <Skeleton variant="rect" className={classes.checkbox} />
        <Skeleton width={150} />
      </div>
      <div className={classes.wrapper}>
        <Skeleton variant="rect" className={classes.checkbox} />
        <Skeleton width={150} />
      </div>
    </div>
  );
};

export default SkeletonLoader;
