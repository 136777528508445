import { XMarkIcon } from '@heroicons/react/24/solid';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateAnnouncementBanner,
  updateAnnouncementPopup
} from '../../redux/actions/job_action';
import {
  Container,
  DialogActionsStyled,
  DialogContentStyled,
  DialogTitleStyled,
  IconButtonStyled
} from './styles';
import { MUIButtonStyled } from '../MUIButton';
import Colors from '../../styles/colors.module.scss';

const Announcement = ({
  $backgroundColor,
  hasContent,
  content,
  $height,
  $margin,
  $borderRadius,
  $padding,
  $display,
  $justifyContent,
  $alignItems
}) => {
  const dispatch = useDispatch();

  const isOpen = useSelector(state => state.jobs.isAnnouncementBannerOpen);
  const isAnnouncementPopupOpen = useSelector(
    state => state.jobs.isAnnouncementPopupOpen
  );

  const [animation, setAnimation] = useState('open');

  const onCloseBanner = async () => {
    dispatch(updateAnnouncementPopup(true));
  };

  const onCloseModal = () => {
    dispatch(updateAnnouncementPopup(false));
  };

  const onCancelHide = () => {
    dispatch(updateAnnouncementPopup(false));
  };

  const onConfirmHide = async () => {
    // setAnimation('close');

    // await new Promise(r => setTimeout(r, 900));

    dispatch(updateAnnouncementPopup(false));
    dispatch(updateAnnouncementBanner(false));
    localStorage.setItem('hideAnnouncementBanner', true);
  };

  return (
    <>
      <Container
        $backgroundColor={$backgroundColor}
        $height={$height}
        $margin={$margin}
        $borderRadius={$borderRadius}
        $padding={$padding}
        $display={$display}
        $justifyContent={$justifyContent}
        $alignItems={$alignItems}
        // $animation={animation}
        $isOpen={isOpen}>
        <IconButton
          style={{ position: 'absolute', top: '14px', right: '22px' }}
          onClick={onCloseBanner}>
          <XMarkIcon style={{ width: '20px', height: '20px', color: '#222' }} />
        </IconButton>
        {hasContent && content}
      </Container>
      <Dialog
        open={isAnnouncementPopupOpen}
        maxWidth={'464px'}
        onClose={() => {}}
        PaperProps={{
          style: {
            boxShadow: 'none',
            borderRadius: 8,
            width: '100%',
            background: '#fff',
            maxWidth: '460px'
          }
        }}>
        <DialogTitleStyled>
          <Grid
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <span
              style={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 700
              }}>
              Permanently hide this?
            </span>
            <IconButtonStyled onClick={onCloseModal}>
              <XMarkIcon height={24} width={24} />
            </IconButtonStyled>
          </Grid>
        </DialogTitleStyled>
        <Divider />
        <DialogContentStyled>
          <span
            style={{
              fontFamily: 'Inter',
              fontSize: '16px',
              fontWeight: 400,
              letterSpacing: '0.15px'
            }}>
            {' '}
            This will permanently hide the announcement banner. Would you like
            to continue?
          </span>
        </DialogContentStyled>
        <DialogActionsStyled>
          <Grid
            display={'flex'}
            justifyContent={'space-between'}
            padding={'16px 24px'}
            gap={'16px'}
            width={'100%'}>
            <MUIButtonStyled
              $color={Colors.priBlack}
              $width={'200px'}
              $padding={'10px 20px'}
              $backgroundColor={Colors.priWhite}
              $fontSize={'14px'}
              $borderRadius={'8px'}
              onClick={onCancelHide}
              sx={{ boxShadow: 'none', '&:hover': { background: 'white' } }}
              label="Cancel"
            />
            <MUIButtonStyled
              $width={'200px'}
              $padding={'10px 20px'}
              $color={Colors.priWhite}
              $backgroundColor={Colors.priBlack}
              $fontSize={'14px'}
              $borderRadius={'8px'}
              onClick={onConfirmHide}
              sx={{ boxShadow: 'none', '&:hover': { background: 'black' } }}
              label="Confirm"
            />
          </Grid>
        </DialogActionsStyled>
      </Dialog>
    </>
  );
};

export default Announcement;
